import React, { useEffect, useRef, useState } from "react";

import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { Textfit } from "react-textfit";
import { MalaysiaStateData } from "../../data";

const CustomDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: x;
    pointer-event: none;
  }

  .MuiDrawer-paperAnchorBottom {
    left: 8vw;
    right: 8vw;
    width: 650px;
    border-radius: 32px 32px 0px 0px;
    padding: 8px 32px;
    min-height: 350px;
    max-height: 75vh;

    @media only screen and (max-width: 600px) {
      width: 100vw;
      left: 0vw;
    }
  }

  @media only screen and (max-width: 600px) {
    body {
      background-color: lightblue;
    }
  }
`;

const StateTitle = styled(Grid)`
  font-family: "Staatliches", cursive;
`;

const SpecialLayer = styled.div`
  padding: 0.5rem;
  background: red;
  color: white;
  border-radius: 10px;
`;

const InfoDrawer = ({ open, close, properties, translate }) => {
  const ref = useRef();
  const [stateData, setStateData] = useState("");

  const ConfirmedCase = ({ data }) => {
    return (
      <SpecialLayer>
        {translate("drawer.confirmed")}: {data ? data.confirmed : ""}
      </SpecialLayer>
    );
  };

  useEffect(() => {
    MalaysiaStateData.filter((e) => {
      if (e.id === properties.gid) {
        setStateData(e);
      }
    });
  });

  return (
    <CustomDrawer anchor="bottom" open={open} onClose={close} ref={ref}>
      <Textfit mode="single">
        <StateTitle>{stateData.name}</StateTitle>{" "}
      </Textfit>
      <ConfirmedCase data={stateData.data ? stateData.data.cases : ""} />
    </CustomDrawer>
  );
};

export default InfoDrawer;
