export const johor = {
  type: "Feature",
  properties: {
    gid: 1,
    id: 2939653,
    country: "MYS",
    name: "Johor",
    enname: null,
    locname: "Johor",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q183032",
    wikimedia: "en:Johor",
    timestamp: "2019-12-27 23:00:01",
    note: null,
    path: "0,2108121,2939653",
    rpath: "2939653,2108121,0",
    iso3166_2: "MY-01",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [104.2974213, 1.3617775],
          [104.298125, 1.36229],
          [104.2986373, 1.3622819],
          [104.2984749, 1.3619952],
          [104.2982142, 1.3616086],
          [104.2976804, 1.361586],
          [104.2974213, 1.3617775],
        ],
      ],
      [
        [
          [104.3045149, 1.3654202],
          [104.3048645, 1.3659323],
          [104.3051165, 1.3656641],
          [104.3051084, 1.3655543],
          [104.3045149, 1.3654202],
        ],
      ],
      [
        [
          [104.2994716, 1.3739491],
          [104.2999619, 1.3742709],
          [104.3001845, 1.3744033],
          [104.3004854, 1.3746377],
          [104.301207, 1.3743368],
          [104.300545, 1.3739716],
          [104.3001561, 1.3737657],
          [104.3001019, 1.373526],
          [104.2996878, 1.3734756],
          [104.2994716, 1.3739491],
        ],
      ],
      [
        [
          [104.3030979, 1.3867722],
          [104.3035346, 1.387071],
          [104.3035255, 1.3868007],
          [104.3032975, 1.3866215],
          [104.3030979, 1.3867722],
        ],
      ],
      [
        [
          [104.0071059, 1.5370367],
          [104.0074236, 1.5371994],
          [104.0079893, 1.5372226],
          [104.0087332, 1.5371762],
          [104.0093531, 1.5373543],
          [104.0097561, 1.5376797],
          [104.0102907, 1.5381754],
          [104.0104435, 1.5382486],
          [104.0107394, 1.5366931],
          [104.0112325, 1.5358057],
          [104.0118475, 1.535126],
          [104.0119328, 1.5349815],
          [104.0120258, 1.5346523],
          [104.0121187, 1.5346329],
          [104.0123861, 1.5347878],
          [104.0125476, 1.5347103],
          [104.0130955, 1.5341077],
          [104.0146816, 1.5311123],
          [104.0150516, 1.5304653],
          [104.0153201, 1.5300326],
          [104.0154312, 1.5294347],
          [104.0153149, 1.5290628],
          [104.014788, 1.5282262],
          [104.0145804, 1.5279621],
          [104.014701, 1.5278416],
          [104.0148051, 1.5278416],
          [104.0148267, 1.5276453],
          [104.0151212, 1.5264988],
          [104.0151229, 1.5245862],
          [104.0144393, 1.5238186],
          [104.0124866, 1.5224785],
          [104.010712, 1.5213321],
          [104.0100689, 1.5205652],
          [104.0089928, 1.5197371],
          [104.0089298, 1.5196512],
          [104.0090367, 1.5194851],
          [104.0092503, 1.5194468],
          [104.0092997, 1.5192441],
          [104.0091627, 1.5190414],
          [104.0086093, 1.5184718],
          [104.0071449, 1.5174318],
          [104.0068668, 1.5174859],
          [104.0063901, 1.5173434],
          [104.0060175, 1.517568],
          [104.0054258, 1.5178528],
          [104.0050038, 1.5179624],
          [104.0044931, 1.5180762],
          [104.0043025, 1.5178693],
          [104.004445, 1.517694],
          [104.0048121, 1.5173106],
          [104.0049217, 1.5170586],
          [104.0047847, 1.5168012],
          [104.0049819, 1.5167081],
          [104.0050751, 1.5165985],
          [104.0049436, 1.516248],
          [104.0044491, 1.5158543],
          [104.0035932, 1.515789],
          [104.0031139, 1.5158579],
          [104.0027729, 1.5161135],
          [104.002122, 1.516679],
          [104.0011457, 1.518143],
          [104.0005549, 1.5188588],
          [104.00050017139624, 1.518952275622847],
          [104.0004234, 1.5190834],
          [104.0004343, 1.5192039],
          [104.0000391, 1.5196503],
          [103.9999522, 1.5201022],
          [104.0000124, 1.5205185],
          [104.000248, 1.5212086],
          [104.00050017139624, 1.521739495723737],
          [104.0008672, 1.5225122],
          [104.0017772, 1.5249506],
          [104.0019874, 1.5254075],
          [104.0021269, 1.5255431],
          [104.0022741, 1.5255586],
          [104.0023361, 1.5257368],
          [104.0021772, 1.5257987],
          [104.0021462, 1.5258762],
          [104.0023555, 1.5271156],
          [104.0022639, 1.527858],
          [104.0024174, 1.5279895],
          [104.0023626, 1.5282524],
          [104.002348, 1.5283784],
          [104.0022816, 1.5283681],
          [104.0022627, 1.5284972],
          [104.0023268, 1.5285075],
          [104.0023078, 1.5287618],
          [104.0021927, 1.528833],
          [104.0021324, 1.5291343],
          [104.0020941, 1.5293205],
          [104.0022256, 1.5295232],
          [104.0022475, 1.5298628],
          [104.0023516, 1.5299887],
          [104.0025927, 1.5300326],
          [104.0031516, 1.5300161],
          [104.0036995, 1.5298902],
          [104.004116, 1.5297642],
          [104.0043516, 1.5296875],
          [104.0043899, 1.5298628],
          [104.0047571, 1.5299614],
          [104.0052228, 1.530038],
          [104.0056666, 1.5302133],
          [104.0061652, 1.5304981],
          [104.0064228, 1.5307063],
          [104.0067187, 1.5310678],
          [104.0069762, 1.5313745],
          [104.0071461, 1.5316374],
          [104.007305, 1.5317196],
          [104.0075186, 1.5317525],
          [104.0076282, 1.5318237],
          [104.0075844, 1.5318894],
          [104.0074419, 1.5319004],
          [104.0072721, 1.5320044],
          [104.0072228, 1.5322564],
          [104.0072228, 1.5325193],
          [104.0073104, 1.5328151],
          [104.0073652, 1.5330944],
          [104.0074529, 1.5333464],
          [104.0076721, 1.53356],
          [104.0076063, 1.5337079],
          [104.0073762, 1.5335874],
          [104.0072666, 1.5336805],
          [104.0072063, 1.5339544],
          [104.0070474, 1.5345624],
          [104.0069214, 1.5351046],
          [104.0067899, 1.5355593],
          [104.006757, 1.5358769],
          [104.0067844, 1.5361344],
          [104.0069433, 1.5362439],
          [104.0069324, 1.5363316],
          [104.0068173, 1.5364411],
          [104.0068173, 1.5366273],
          [104.0069433, 1.5368136],
          [104.0071059, 1.5370367],
        ],
      ],
      [
        [
          [104.1136309, 2.1729909],
          [104.1136739, 2.172881],
          [104.1138187, 2.1727738],
          [104.1136819, 2.1725754],
          [104.1138777, 2.1725031],
          [104.113749, 2.1723422],
          [104.113816, 2.1722243],
          [104.113698, 2.172093],
          [104.1137812, 2.1720581],
          [104.1138616, 2.1721225],
          [104.1139367, 2.1720421],
          [104.1140306, 2.1720689],
          [104.1141003, 2.1719643],
          [104.114272, 2.1719616],
          [104.1143471, 2.1718089],
          [104.1144276, 2.1717633],
          [104.1143846, 2.1714846],
          [104.1142157, 2.1713103],
          [104.1140789, 2.1712219],
          [104.1139904, 2.1712862],
          [104.1138294, 2.1712272],
          [104.1136417, 2.171104],
          [104.113403, 2.1711415],
          [104.1132501, 2.1712674],
          [104.1129953, 2.1714041],
          [104.1126895, 2.1715516],
          [104.1124642, 2.1716722],
          [104.1122711, 2.1716561],
          [104.1120538, 2.1716025],
          [104.1121396, 2.1718062],
          [104.1120592, 2.1720045],
          [104.1120565, 2.1722458],
          [104.1121128, 2.1724575],
          [104.1122013, 2.1726371],
          [104.1123328, 2.1727711],
          [104.1123247, 2.1729936],
          [104.1124803, 2.1730579],
          [104.1124937, 2.1729105],
          [104.1128531, 2.1729131],
          [104.1132179, 2.1729775],
          [104.1136309, 2.1729909],
        ],
      ],
      [
        [
          [104.1096854, 2.1818224],
          [104.1097632, 2.181683],
          [104.1096479, 2.1813721],
          [104.1096452, 2.1811496],
          [104.1097471, 2.1810692],
          [104.109849, 2.1812113],
          [104.1099778, 2.1814418],
          [104.1100153, 2.1814418],
          [104.1100261, 2.181356],
          [104.1100958, 2.1813721],
          [104.1101763, 2.1814954],
          [104.1103855, 2.1815007],
          [104.110423, 2.1813587],
          [104.110364, 2.1812836],
          [104.1104954, 2.18123],
          [104.1103238, 2.1810987],
          [104.1104498, 2.181096],
          [104.1105893, 2.1810853],
          [104.110592, 2.1810317],
          [104.1106537, 2.180962],
          [104.1105089, 2.1807663],
          [104.1103345, 2.1806216],
          [104.1102138, 2.1805064],
          [104.110254, 2.180442],
          [104.1103721, 2.180509],
          [104.1105142, 2.1805975],
          [104.110592, 2.1805251],
          [104.1106564, 2.1805305],
          [104.1107556, 2.1804822],
          [104.1107771, 2.180367],
          [104.1109058, 2.1803616],
          [104.1109863, 2.1802437],
          [104.1110587, 2.1802303],
          [104.1110587, 2.1799408],
          [104.1109863, 2.1798577],
          [104.1110024, 2.1797264],
          [104.1112116, 2.1796835],
          [104.1112035, 2.1795147],
          [104.1111231, 2.1793083],
          [104.1110963, 2.1791153],
          [104.1111928, 2.1790992],
          [104.1113055, 2.1789893],
          [104.1115549, 2.1788955],
          [104.1116247, 2.1786865],
          [104.1117319, 2.1786087],
          [104.1118017, 2.1784291],
          [104.1118929, 2.1783193],
          [104.1118097, 2.1782094],
          [104.1116971, 2.1780995],
          [104.1115227, 2.1781343],
          [104.111402, 2.1783139],
          [104.1114423, 2.1783702],
          [104.1112787, 2.1785042],
          [104.1112009, 2.1787106],
          [104.1110694, 2.1787588],
          [104.1109675, 2.1789062],
          [104.110828, 2.1790965],
          [104.110541, 2.1791394],
          [104.1103452, 2.1791528],
          [104.1102299, 2.1792815],
          [104.1101655, 2.1793351],
          [104.1100931, 2.1794208],
          [104.1097873, 2.1793378],
          [104.1097364, 2.1793914],
          [104.1098061, 2.1795173],
          [104.1098168, 2.1796969],
          [104.1097257, 2.1798604],
          [104.1097578, 2.1800159],
          [104.1098651, 2.1801928],
          [104.1100019, 2.1803831],
          [104.1098893, 2.180568],
          [104.1097793, 2.1808762],
          [104.1095513, 2.1810505],
          [104.1092992, 2.1812568],
          [104.1091758, 2.1814123],
          [104.1093448, 2.1816079],
          [104.1093931, 2.18175],
          [104.1096854, 2.1818224],
        ],
      ],
      [
        [
          [104.1037145, 2.1862288],
          [104.1037505, 2.1854706],
          [104.1029799, 2.1853166],
          [104.1024212, 2.184585],
          [104.1016046, 2.1847049],
          [104.101095, 2.1841684],
          [104.1006487, 2.1834299],
          [104.100445, 2.1827608],
          [104.1004529, 2.1824534],
          [104.1004594, 2.1821993],
          [104.0996503, 2.1821345],
          [104.0985487, 2.1813699],
          [104.0974626, 2.1808608],
          [104.0955023, 2.1810598],
          [104.0955915, 2.18145],
          [104.0967155, 2.1813559],
          [104.097417, 2.181593],
          [104.0976817, 2.18214],
          [104.0977007, 2.1825496],
          [104.0972462, 2.1828734],
          [104.0969991, 2.1829129],
          [104.0965636, 2.1826402],
          [104.0961368, 2.1828014],
          [104.0958709, 2.1834484],
          [104.0961543, 2.1836137],
          [104.0962131, 2.1837658],
          [104.0962822, 2.1839442],
          [104.0962726, 2.1844094],
          [104.0962697, 2.1845493],
          [104.0963708, 2.1852221],
          [104.0969828, 2.1855908],
          [104.0971007, 2.1856618],
          [104.0973483, 2.1858621],
          [104.0974665, 2.1859577],
          [104.0980645, 2.1861376],
          [104.0988774, 2.187092],
          [104.0991677, 2.1876345],
          [104.0988221, 2.1881416],
          [104.0999058, 2.1887392],
          [104.1020893, 2.1877296],
          [104.1037145, 2.1862288],
        ],
      ],
      [
        [
          [104.0941903, 2.1895254],
          [104.094252, 2.1895147],
          [104.0942869, 2.1894102],
          [104.0943888, 2.1893378],
          [104.0945631, 2.1892172],
          [104.0947133, 2.189051],
          [104.0946811, 2.1889251],
          [104.0947482, 2.1887348],
          [104.0947321, 2.1885418],
          [104.0946704, 2.1885498],
          [104.0945739, 2.1884667],
          [104.0944022, 2.1883166],
          [104.0941903, 2.188322],
          [104.0941393, 2.188263],
          [104.0940401, 2.1882041],
          [104.0939569, 2.1882872],
          [104.093855, 2.1882335],
          [104.0937531, 2.1883006],
          [104.0936512, 2.1884238],
          [104.0936083, 2.1886624],
          [104.093619, 2.1888741],
          [104.0938041, 2.1890832],
          [104.0940481, 2.1892252],
          [104.0941903, 2.1895254],
        ],
      ],
      [
        [
          [103.6095434, 1.3163206],
          [103.6095283, 1.3161292],
          [103.6096592, 1.3160789],
          [103.6099413, 1.3160033],
          [103.610173, 1.3159077],
          [103.6104147, 1.3157465],
          [103.6107018, 1.3154696],
          [103.6109235, 1.3151826],
          [103.6110141, 1.3150164],
          [103.611019, 1.3149365],
          [103.6110242, 1.3148502],
          [103.6109436, 1.3146589],
          [103.6107522, 1.3144172],
          [103.6105356, 1.3143517],
          [103.6102737, 1.3142762],
          [103.6101377, 1.3147344],
          [103.6099514, 1.3148855],
          [103.6096744, 1.3149258],
          [103.609483, 1.3149358],
          [103.6094376, 1.3151977],
          [103.6093621, 1.3154092],
          [103.6092765, 1.3155703],
          [103.6091304, 1.3157063],
          [103.6090548, 1.3159429],
          [103.6091153, 1.3160235],
          [103.6092362, 1.3159933],
          [103.6092613, 1.3161796],
          [103.6092765, 1.3163155],
          [103.609352, 1.3163508],
          [103.6094527, 1.3163911],
          [103.6095434, 1.3163206],
        ],
      ],
      [
        [
          [103.4205212, 1.3315799],
          [103.4206921, 1.3317507],
          [103.4206736, 1.332108],
          [103.4208462, 1.3322867],
          [103.4210742, 1.3324962],
          [103.4210111, 1.3329246],
          [103.4209817, 1.3338083],
          [103.4205935, 1.3358105],
          [103.4204078, 1.3368612],
          [103.4202854, 1.337061],
          [103.419546, 1.3373013],
          [103.4189847, 1.3375171],
          [103.4190961, 1.3380467],
          [103.4193118, 1.3383609],
          [103.4196479, 1.3383704],
          [103.4206099, 1.3383388],
          [103.4239801, 1.3365826],
          [103.42739, 1.3339713],
          [103.4290109, 1.3325393],
          [103.4297319, 1.3320341],
          [103.4313873, 1.3310145],
          [103.4335462, 1.3292866],
          [103.4348033, 1.3284672],
          [103.4358778, 1.3277368],
          [103.4361047, 1.3275545],
          [103.4362325, 1.32745],
          [103.4363127, 1.327417],
          [103.436389, 1.3273857],
          [103.436809, 1.327213],
          [103.4382447, 1.3252709],
          [103.4387044, 1.3240486],
          [103.4388243, 1.3230015],
          [103.438554, 1.3216954],
          [103.4382378, 1.3198797],
          [103.4377938, 1.3194925],
          [103.4376149, 1.3189885],
          [103.4375864, 1.3182691],
          [103.4377268, 1.3177205],
          [103.4374157, 1.3173546],
          [103.4373382, 1.3155612],
          [103.4370945, 1.3154565],
          [103.4368872, 1.3152614],
          [103.4366351, 1.3146071],
          [103.436188, 1.3143891],
          [103.435513, 1.3138267],
          [103.4347853, 1.3134406],
          [103.4338096, 1.3131805],
          [103.4328583, 1.3127415],
          [103.4319686, 1.3123609],
          [103.4307768, 1.3121278],
          [103.4285945, 1.3112496],
          [103.4271437, 1.3100203],
          [103.4267063, 1.309222],
          [103.4252315, 1.309051],
          [103.4247314, 1.3091039],
          [103.4240566, 1.3090632],
          [103.423276, 1.3088031],
          [103.4226296, 1.3087787],
          [103.4221214, 1.3086202],
          [103.4215685, 1.3084129],
          [103.4212652, 1.3082896],
          [103.4211538, 1.3083072],
          [103.420609, 1.308482],
          [103.4203245, 1.3090388],
          [103.4200521, 1.3093559],
          [103.419444, 1.3106327],
          [103.4193731, 1.3113881],
          [103.4189259, 1.3131439],
          [103.4186088, 1.3140624],
          [103.4185118, 1.3150736],
          [103.4187023, 1.315741],
          [103.419304, 1.3165051],
          [103.4193962, 1.3169846],
          [103.4193782, 1.3187584],
          [103.4195845, 1.3193949],
          [103.4194422, 1.3209231],
          [103.4195133, 1.3216817],
          [103.4198725, 1.3229223],
          [103.4197924, 1.323699],
          [103.4198541, 1.3240132],
          [103.420193, 1.3243028],
          [103.4201683, 1.3254856],
          [103.4203347, 1.3260585],
          [103.4205011, 1.3266068],
          [103.4203717, 1.3272167],
          [103.4203532, 1.3273892],
          [103.4205072, 1.3277157],
          [103.4204271, 1.3278327],
          [103.4201284, 1.3281449],
          [103.4202238, 1.3283748],
          [103.420384, 1.3284611],
          [103.4205689, 1.3285412],
          [103.4210249, 1.3288492],
          [103.4211273, 1.3291973],
          [103.4210125, 1.3295576],
          [103.4210865, 1.3302045],
          [103.4209201, 1.3304324],
          [103.4207229, 1.3305125],
          [103.4207352, 1.3306111],
          [103.4210557, 1.3307959],
          [103.4210803, 1.3310053],
          [103.4208277, 1.3312086],
          [103.4205212, 1.3315799],
        ],
      ],
      [
        [
          [104.2833504, 1.3697248],
          [104.2835816, 1.3697033],
          [104.2837216, 1.3695065],
          [104.2835194, 1.3693606],
          [104.28321, 1.3693791],
          [104.2831943, 1.3696481],
          [104.2833504, 1.3697248],
        ],
      ],
      [
        [
          [104.2928288, 1.366876],
          [104.2930895, 1.3664673],
          [104.2930112, 1.3661407],
          [104.2928401, 1.3659488],
          [104.2930922, 1.3656484],
          [104.2934757, 1.365709],
          [104.2937778, 1.3655921],
          [104.293627, 1.3650982],
          [104.2932633, 1.3647721],
          [104.2929688, 1.3646718],
          [104.2927966, 1.3644707],
          [104.292383, 1.3643581],
          [104.2917828, 1.3646],
          [104.292169, 1.3656742],
          [104.2925852, 1.3666529],
          [104.2928288, 1.366876],
        ],
      ],
      [
        [
          [104.2942181, 1.3676097],
          [104.294371, 1.3676901],
          [104.2944246, 1.3677384],
          [104.2945346, 1.3677786],
          [104.2946499, 1.3678537],
          [104.2946901, 1.3678027],
          [104.2946875, 1.3676847],
          [104.2948269, 1.3677303],
          [104.2949664, 1.3676392],
          [104.295063, 1.3676097],
          [104.2951649, 1.3675694],
          [104.2951473, 1.3674643],
          [104.2952024, 1.3674059],
          [104.2951622, 1.3672504],
          [104.2951756, 1.3671377],
          [104.2952293, 1.3669849],
          [104.2951666, 1.3668556],
          [104.2950549, 1.3668428],
          [104.2949327, 1.3667248],
          [104.2946767, 1.3668267],
          [104.2945185, 1.3668186],
          [104.2943871, 1.3669393],
          [104.2943844, 1.3670144],
          [104.2941886, 1.3670519],
          [104.2940679, 1.3670895],
          [104.2939418, 1.3671297],
          [104.2938721, 1.3672021],
          [104.2939606, 1.3672611],
          [104.2939659, 1.3673388],
          [104.2940786, 1.3674246],
          [104.2941269, 1.367497],
          [104.2941993, 1.3675105],
          [104.2942181, 1.3676097],
        ],
      ],
      [
        [
          [103.9772752, 1.4291781],
          [103.9784416, 1.4290158],
          [103.9786737, 1.4289452],
          [103.9788483, 1.4288092],
          [103.9790569, 1.4285655],
          [103.9791154, 1.4282932],
          [103.9790614, 1.427998],
          [103.9791693, 1.427731],
          [103.979098, 1.4273199],
          [103.9789305, 1.4268197],
          [103.9786863, 1.4265966],
          [103.9782808, 1.4264628],
          [103.9775584, 1.4262332],
          [103.9772973, 1.4260957],
          [103.9769994, 1.426029],
          [103.9766237, 1.4260633],
          [103.9763446, 1.4261471],
          [103.9758659, 1.4264673],
          [103.9755567, 1.4270624],
          [103.9754447, 1.4277743],
          [103.9754631, 1.4283446],
          [103.9756102, 1.4285935],
          [103.9758442, 1.4288271],
          [103.9763688, 1.4290082],
          [103.9765038, 1.4291121],
          [103.9768556, 1.4291968],
          [103.9772752, 1.4291781],
        ],
      ],
      [
        [
          [103.7693929, 1.4526693],
          [103.7693125, 1.4526465],
          [103.7692542, 1.4526275],
          [103.7691622, 1.4526014],
          [103.7691079, 1.4525846],
          [103.7690553, 1.452566],
          [103.768972431237401, 1.452546307423124],
          [103.7688386, 1.4529877],
          [103.7683639, 1.4533798],
          [103.768291, 1.4536118],
          [103.768488, 1.4541515],
          [103.7689269, 1.4542722],
          [103.7693929, 1.4526693],
        ],
      ],
      [
        [
          [103.2402366, 1.4684667],
          [103.2403493, 1.4680645],
          [103.2403788, 1.4678178],
          [103.2404914, 1.4675872],
          [103.24047, 1.4675416],
          [103.2405451, 1.4673754],
          [103.2404727, 1.467193],
          [103.2404727, 1.467008],
          [103.2403171, 1.4667989],
          [103.2402635, 1.4666112],
          [103.2401535, 1.4666487],
          [103.2400113, 1.4664583],
          [103.2397833, 1.4662787],
          [103.2394776, 1.4661098],
          [103.2393193, 1.4661714],
          [103.239094, 1.4661232],
          [103.238748, 1.4661688],
          [103.2383162, 1.4661285],
          [103.2380185, 1.4661393],
          [103.2377878, 1.4661285],
          [103.2375759, 1.4662009],
          [103.2374659, 1.4663591],
          [103.2373747, 1.4665924],
          [103.2374337, 1.4666273],
          [103.2374042, 1.4668123],
          [103.2373157, 1.4670402],
          [103.2374445, 1.467252],
          [103.2376456, 1.4674665],
          [103.2379112, 1.4676462],
          [103.2381338, 1.4677642],
          [103.2383779, 1.4679384],
          [103.2383054, 1.4680377],
          [103.2383805, 1.4682173],
          [103.2385361, 1.468094],
          [103.2388285, 1.4681261],
          [103.2389358, 1.4683594],
          [103.2390887, 1.4685712],
          [103.2394561, 1.4685096],
          [103.2397404, 1.4684693],
          [103.2400811, 1.4685686],
          [103.2402366, 1.4684667],
        ],
      ],
      [
        [
          [103.2654038, 1.4699789],
          [103.2654574, 1.4698583],
          [103.2653823, 1.4697403],
          [103.2653287, 1.4695231],
          [103.2652026, 1.4693435],
          [103.2650524, 1.4693032],
          [103.2646608, 1.4693515],
          [103.264516, 1.4693032],
          [103.2643685, 1.4693595],
          [103.2643256, 1.46944],
          [103.2640144, 1.4695043],
          [103.263765, 1.4694748],
          [103.2635853, 1.469558],
          [103.2633841, 1.4695365],
          [103.2632634, 1.4696867],
          [103.263014, 1.4696599],
          [103.2628503, 1.469692],
          [103.262558, 1.4695767],
          [103.2623729, 1.4694453],
          [103.2622763, 1.4695258],
          [103.262161, 1.4694132],
          [103.2619706, 1.4694212],
          [103.2616058, 1.4693461],
          [103.2613295, 1.4693649],
          [103.261013, 1.4693837],
          [103.2611015, 1.4692898],
          [103.2610372, 1.4690941],
          [103.261182, 1.4688715],
          [103.2610989, 1.4686624],
          [103.2609084, 1.4684345],
          [103.2608038, 1.4684962],
          [103.2607421, 1.4682334],
          [103.260549, 1.4679921],
          [103.2604846, 1.4677642],
          [103.2603076, 1.4676918],
          [103.2602593, 1.467429],
          [103.2601306, 1.4674156],
          [103.2600743, 1.4672869],
          [103.2599911, 1.4674344],
          [103.2599375, 1.4674317],
          [103.2598543, 1.4671019],
          [103.2599401, 1.4669329],
          [103.259857, 1.466823],
          [103.2595807, 1.4666192],
          [103.25946, 1.4664637],
          [103.2592481, 1.4662546],
          [103.2594708, 1.4661098],
          [103.2595566, 1.4659891],
          [103.25957, 1.4658336],
          [103.2596585, 1.4655816],
          [103.2597578, 1.4652812],
          [103.2598972, 1.4649139],
          [103.2599804, 1.4645761],
          [103.2600984, 1.4643455],
          [103.2601735, 1.4642436],
          [103.2600796, 1.4641444],
          [103.259865, 1.4638869],
          [103.2597658, 1.4636912],
          [103.2595646, 1.4635035],
          [103.2593957, 1.4632917],
          [103.2592374, 1.4631925],
          [103.2592159, 1.4630691],
          [103.2590014, 1.4628788],
          [103.2588673, 1.4626348],
          [103.2587653, 1.4627313],
          [103.258532, 1.4625409],
          [103.2584167, 1.4623452],
          [103.2582879, 1.4620851],
          [103.2580224, 1.4620797],
          [103.2576817, 1.4619457],
          [103.2573223, 1.4620127],
          [103.256928, 1.4619832],
          [103.2567349, 1.4620368],
          [103.256574, 1.4622004],
          [103.2563728, 1.4623371],
          [103.2561797, 1.4624685],
          [103.2559168, 1.462616],
          [103.2556835, 1.4627983],
          [103.2557344, 1.4628868],
          [103.2555708, 1.4630262],
          [103.2555547, 1.4632354],
          [103.2556057, 1.4633748],
          [103.2554474, 1.4636],
          [103.2554045, 1.4637153],
          [103.2551953, 1.4637931],
          [103.2550237, 1.4637046],
          [103.2548761, 1.4636617],
          [103.2546401, 1.4636993],
          [103.2544228, 1.4636027],
          [103.2542807, 1.4636483],
          [103.2542136, 1.4635706],
          [103.2540581, 1.463415],
          [103.2538488, 1.4634472],
          [103.2536799, 1.4635706],
          [103.2535538, 1.4635464],
          [103.2535109, 1.4634713],
          [103.2533151, 1.4633614],
          [103.2530308, 1.46334],
          [103.2528457, 1.4633748],
          [103.2525721, 1.4635062],
          [103.252497, 1.4637368],
          [103.2524756, 1.4638735],
          [103.2525882, 1.464072],
          [103.2525668, 1.4641444],
          [103.2523388, 1.4640344],
          [103.2521644, 1.4639647],
          [103.2519445, 1.4637958],
          [103.2516843, 1.4636081],
          [103.2515368, 1.4635571],
          [103.251341, 1.4636429],
          [103.2510674, 1.4636564],
          [103.2508662, 1.4637448],
          [103.2508662, 1.4635786],
          [103.2507455, 1.4635625],
          [103.2505846, 1.4636778],
          [103.2503647, 1.4636135],
          [103.2502815, 1.4635035],
          [103.2500616, 1.4636295],
          [103.2499436, 1.4638548],
          [103.2497102, 1.463828],
          [103.2496753, 1.4639486],
          [103.2494661, 1.4639513],
          [103.2490933, 1.4639164],
          [103.2490772, 1.464072],
          [103.248978, 1.4641497],
          [103.248817, 1.4641873],
          [103.2485354, 1.464619],
          [103.2484174, 1.4646699],
          [103.2483879, 1.4647691],
          [103.248066, 1.464694],
          [103.2480016, 1.4648442],
          [103.2480633, 1.4649273],
          [103.2480419, 1.4650721],
          [103.2478756, 1.4651365],
          [103.2476288, 1.4651794],
          [103.2476529, 1.4653456],
          [103.2479909, 1.4653912],
          [103.2480177, 1.465485],
          [103.2481465, 1.4654823],
          [103.2481196, 1.4655896],
          [103.2482511, 1.4657827],
          [103.2483664, 1.465973],
          [103.2482189, 1.4660267],
          [103.248243, 1.4663109],
          [103.2482859, 1.4664905],
          [103.2483745, 1.466638],
          [103.248294, 1.4668069],
          [103.248404, 1.4669383],
          [103.2482564, 1.4671421],
          [103.2483396, 1.4672279],
          [103.2483718, 1.4673566],
          [103.2482699, 1.4674478],
          [103.2483664, 1.4676623],
          [103.2483101, 1.4679492],
          [103.2483637, 1.4681556],
          [103.2484335, 1.4684962],
          [103.2484657, 1.4686678],
          [103.2484496, 1.4689359],
          [103.248648, 1.4690941],
          [103.2487848, 1.4692764],
          [103.2488331, 1.4695043],
          [103.2488787, 1.4697805],
          [103.2490075, 1.4700084],
          [103.2491121, 1.4702283],
          [103.2494178, 1.4703141],
          [103.2496351, 1.4703141],
          [103.2498577, 1.470357],
          [103.2499167, 1.4705527],
          [103.2500669, 1.4706868],
          [103.2503566, 1.4708235],
          [103.2506409, 1.470971],
          [103.2509387, 1.4711239],
          [103.2510352, 1.4712284],
          [103.2512766, 1.471274],
          [103.2515717, 1.4712713],
          [103.2518935, 1.4711158],
          [103.2521135, 1.4710863],
          [103.2522798, 1.471156],
          [103.2524702, 1.4710917],
          [103.2525372, 1.4709764],
          [103.2523602, 1.4709362],
          [103.2525238, 1.4708638],
          [103.2528967, 1.4709817],
          [103.2530093, 1.4709094],
          [103.2532024, 1.4709067],
          [103.2532802, 1.4710112],
          [103.2534224, 1.4712526],
          [103.2535967, 1.4714832],
          [103.2538033, 1.4716038],
          [103.2539883, 1.4716977],
          [103.254219, 1.4718612],
          [103.2542405, 1.4719551],
          [103.2542968, 1.4720972],
          [103.25427, 1.4724377],
          [103.2544121, 1.4725584],
          [103.2547206, 1.4725718],
          [103.2548869, 1.4726361],
          [103.2550371, 1.4726415],
          [103.2553214, 1.4726468],
          [103.2553938, 1.4727756],
          [103.2555467, 1.4727219],
          [103.2556647, 1.4727434],
          [103.2557961, 1.4728882],
          [103.2560322, 1.4729203],
          [103.256287, 1.4729069],
          [103.2565203, 1.4729177],
          [103.2568395, 1.4729391],
          [103.2570273, 1.4730115],
          [103.257156, 1.4729874],
          [103.2571936, 1.4730276],
          [103.2573304, 1.4729659],
          [103.2575664, 1.4729257],
          [103.2576522, 1.4729793],
          [103.2575664, 1.4731322],
          [103.2576576, 1.4732072],
          [103.2577863, 1.473108],
          [103.2578051, 1.4731751],
          [103.257958, 1.473167],
          [103.2579929, 1.4730732],
          [103.2581431, 1.4729874],
          [103.2582986, 1.4728989],
          [103.2584193, 1.4728533],
          [103.2586634, 1.4728185],
          [103.2587439, 1.4727112],
          [103.2588565, 1.4728989],
          [103.2589531, 1.4728614],
          [103.258929, 1.4726308],
          [103.2592186, 1.4725932],
          [103.2592642, 1.4724457],
          [103.2595271, 1.4722232],
          [103.2594842, 1.4720409],
          [103.2597417, 1.4720114],
          [103.2597738, 1.4719309],
          [103.2598811, 1.471888],
          [103.2599241, 1.4717861],
          [103.2597899, 1.4716789],
          [103.2598838, 1.4716333],
          [103.2601735, 1.4717057],
          [103.2602727, 1.4718425],
          [103.2603693, 1.4718317],
          [103.260498, 1.4719095],
          [103.2605651, 1.4720462],
          [103.260726, 1.4720596],
          [103.2608977, 1.472191],
          [103.2610184, 1.4722876],
          [103.2611927, 1.472435],
          [103.2612866, 1.4726576],
          [103.2613429, 1.4727782],
          [103.2615119, 1.4728533],
          [103.2617882, 1.4729686],
          [103.2620189, 1.4729847],
          [103.2622549, 1.4729927],
          [103.2623541, 1.4729069],
          [103.2623568, 1.4727702],
          [103.2625043, 1.4725771],
          [103.2626009, 1.4725262],
          [103.262668, 1.4725959],
          [103.262794, 1.4723948],
          [103.2629549, 1.4722312],
          [103.2630488, 1.4721025],
          [103.2631078, 1.4721133],
          [103.2633063, 1.4719846],
          [103.2634109, 1.4718344],
          [103.2634431, 1.4716708],
          [103.2635397, 1.4715153],
          [103.2634968, 1.4713464],
          [103.2637596, 1.4712552],
          [103.2637623, 1.4711775],
          [103.2639152, 1.4710649],
          [103.2638374, 1.4709603],
          [103.2638749, 1.470853],
          [103.2637194, 1.4707726],
          [103.263934, 1.4707109],
          [103.2641056, 1.4706332],
          [103.2641888, 1.4707083],
          [103.2645294, 1.4706224],
          [103.2648057, 1.4704857],
          [103.2648727, 1.470357],
          [103.2652912, 1.4701908],
          [103.2654038, 1.4699789],
        ],
      ],
      [
        [
          [103.2379648, 1.46896],
          [103.2379997, 1.4688903],
          [103.2379514, 1.4688367],
          [103.2379916, 1.4687375],
          [103.238056, 1.468649],
          [103.237997, 1.4685632],
          [103.2379541, 1.4684318],
          [103.2378495, 1.4684318],
          [103.2378307, 1.4682253],
          [103.237761, 1.4681664],
          [103.2376483, 1.46811],
          [103.2376295, 1.4679492],
          [103.2374606, 1.467858],
          [103.2372647, 1.4677481],
          [103.2372004, 1.4677561],
          [103.2370797, 1.4676569],
          [103.2369965, 1.4676649],
          [103.2369027, 1.4676435],
          [103.2368222, 1.4675148],
          [103.2366559, 1.4673914],
          [103.2365352, 1.4673244],
          [103.2364225, 1.4673298],
          [103.2363394, 1.4674585],
          [103.2362992, 1.4675631],
          [103.2360417, 1.4676247],
          [103.2358941, 1.4676086],
          [103.2358807, 1.4675791],
          [103.2357225, 1.4676033],
          [103.2356152, 1.4675899],
          [103.2355428, 1.4676247],
          [103.2353792, 1.4676462],
          [103.2353228, 1.4677105],
          [103.2352263, 1.4677481],
          [103.2351002, 1.467791],
          [103.2350627, 1.4679947],
          [103.2349822, 1.4681208],
          [103.2350412, 1.4681744],
          [103.2349554, 1.4682387],
          [103.2349125, 1.4683594],
          [103.2347542, 1.468523],
          [103.2347837, 1.4685659],
          [103.2347435, 1.4686249],
          [103.2348213, 1.4687187],
          [103.23495, 1.4688286],
          [103.2350332, 1.4689815],
          [103.2352343, 1.469011],
          [103.2352504, 1.4691129],
          [103.2353738, 1.4691182],
          [103.2353953, 1.4691611],
          [103.2355723, 1.4692121],
          [103.2357064, 1.4693086],
          [103.2357895, 1.4692791],
          [103.2359773, 1.4693488],
          [103.2361865, 1.469448],
          [103.2363394, 1.4693569],
          [103.2363608, 1.4693998],
          [103.2365406, 1.469373],
          [103.2366264, 1.4694266],
          [103.2367015, 1.4693435],
          [103.2368275, 1.4693301],
          [103.2371119, 1.4693006],
          [103.2372701, 1.4692684],
          [103.2375035, 1.4691504],
          [103.237769, 1.4690029],
          [103.2379648, 1.46896],
        ],
      ],
      [
        [
          [104.0745708, 1.4713899],
          [104.0752624, 1.4711891],
          [104.0759094, 1.4708769],
          [104.0768464, 1.4701632],
          [104.0771365, 1.4689589],
          [104.0770249, 1.4676653],
          [104.0765787, 1.4662156],
          [104.0760656, 1.4657026],
          [104.0751509, 1.4652343],
          [104.0738346, 1.465435],
          [104.0726298, 1.4657473],
          [104.0725852, 1.4663717],
          [104.0724959, 1.4669293],
          [104.0717151, 1.4672638],
          [104.0707334, 1.4676653],
          [104.0695733, 1.4686243],
          [104.0689932, 1.4695164],
          [104.0683686, 1.4707208],
          [104.0680116, 1.472282],
          [104.0681901, 1.4730849],
          [104.0685694, 1.4735086],
          [104.0696849, 1.4734863],
          [104.070555, 1.4729511],
          [104.0711573, 1.4723712],
          [104.0719031, 1.4713636],
          [104.0723682, 1.4710799],
          [104.0731194, 1.4707439],
          [104.0738413, 1.470846],
          [104.0745708, 1.4713899],
        ],
      ],
      [
        [
          [104.0674418, 1.4717459],
          [104.0676153, 1.4706892],
          [104.0679781, 1.4695538],
          [104.0688616, 1.4683079],
          [104.0699659, 1.4673301],
          [104.0710544, 1.4665574],
          [104.071575, 1.4661158],
          [104.0715119, 1.4657373],
          [104.0707073, 1.4655323],
          [104.0696819, 1.4652169],
          [104.0688931, 1.4649961],
          [104.0679624, 1.4647753],
          [104.0672569, 1.4641779],
          [104.0657053, 1.4619958],
          [104.0653061, 1.4606],
          [104.0652805, 1.4593187],
          [104.0649334, 1.4585302],
          [104.0643971, 1.4574262],
          [104.0635767, 1.4563381],
          [104.0627722, 1.4554391],
          [104.0617941, 1.4548399],
          [104.0610211, 1.4549029],
          [104.0602796, 1.4551868],
          [104.0595066, 1.4552183],
          [104.0586232, 1.4549345],
          [104.0574873, 1.4544771],
          [104.0562253, 1.453799],
          [104.0546792, 1.4529158],
          [104.0531806, 1.453452],
          [104.0513506, 1.4541617],
          [104.0489842, 1.4550449],
          [104.0472331, 1.4559123],
          [104.0458291, 1.4567166],
          [104.0449457, 1.4574578],
          [104.0438256, 1.4587194],
          [104.0428948, 1.460123],
          [104.0422322, 1.4612427],
          [104.0416959, 1.4613216],
          [104.0414119, 1.4616685],
          [104.0416643, 1.462599],
          [104.0412226, 1.463561],
          [104.04056, 1.4646965],
          [104.0400867, 1.4655638],
          [104.040213, 1.4660685],
          [104.04056, 1.4662893],
          [104.041333, 1.4666047],
          [104.042248, 1.4667939],
          [104.0433208, 1.4671251],
          [104.0442042, 1.4675667],
          [104.0449614, 1.468229],
          [104.0459237, 1.4692699],
          [104.0467914, 1.4700742],
          [104.0474698, 1.4708312],
          [104.0479746, 1.4716512],
          [104.0483532, 1.4726606],
          [104.0485425, 1.4734806],
          [104.0488738, 1.4740799],
          [104.0493313, 1.4743322],
          [104.0499465, 1.4746634],
          [104.0504829, 1.4746003],
          [104.0513348, 1.4750261],
          [104.052234, 1.4753888],
          [104.0521867, 1.4756254],
          [104.0519501, 1.4761301],
          [104.051887, 1.4767766],
          [104.0517765, 1.4774232],
          [104.0516188, 1.4779279],
          [104.0518396, 1.4784168],
          [104.0525969, 1.4791737],
          [104.0534961, 1.4803565],
          [104.0542849, 1.4807981],
          [104.0551841, 1.4811924],
          [104.0552156, 1.4817759],
          [104.0554049, 1.4822805],
          [104.0557362, 1.4828325],
          [104.0561779, 1.483416],
          [104.0567459, 1.4840468],
          [104.0569825, 1.4846776],
          [104.0568247, 1.485135],
          [104.0566354, 1.4856081],
          [104.0568405, 1.4857658],
          [104.0573453, 1.4856554],
          [104.0578344, 1.4855134],
          [104.0588598, 1.4856869],
          [104.0594751, 1.4858446],
          [104.0602007, 1.4864439],
          [104.0606267, 1.4872166],
          [104.0611788, 1.4877371],
          [104.0613997, 1.4883048],
          [104.0619361, 1.4886991],
          [104.0625986, 1.4891091],
          [104.063135, 1.4892195],
          [104.0636556, 1.4892353],
          [104.0643182, 1.4889672],
          [104.0646179, 1.4885887],
          [104.0647441, 1.4880525],
          [104.0647915, 1.4869012],
          [104.0649965, 1.4863177],
          [104.0653752, 1.4858446],
          [104.0659431, 1.4854188],
          [104.0667476, 1.4853715],
          [104.067142, 1.4856238],
          [104.0674891, 1.4863966],
          [104.0675522, 1.487122],
          [104.0675049, 1.487879],
          [104.0673787, 1.489046],
          [104.0675364, 1.4895349],
          [104.0677415, 1.4897557],
          [104.0681832, 1.4899134],
          [104.0686565, 1.4898976],
          [104.0694295, 1.4896926],
          [104.0701079, 1.4893456],
          [104.0713068, 1.4887148],
          [104.0719694, 1.4883206],
          [104.0724269, 1.4876267],
          [104.0719694, 1.4871851],
          [104.0709282, 1.4866174],
          [104.0703129, 1.4861443],
          [104.0700132, 1.4856712],
          [104.0697766, 1.4848984],
          [104.0698239, 1.4840783],
          [104.0700448, 1.4833371],
          [104.0707389, 1.482659],
          [104.0715119, 1.4824067],
          [104.0725215, 1.4825802],
          [104.0734365, 1.4829744],
          [104.0741622, 1.4834002],
          [104.0746828, 1.4834948],
          [104.0751088, 1.4832898],
          [104.0753138, 1.4828009],
          [104.0753138, 1.4819336],
          [104.0749194, 1.4812712],
          [104.0744304, 1.480877],
          [104.0735785, 1.4804669],
          [104.0727897, 1.48012],
          [104.072348, 1.4796626],
          [104.0719063, 1.4787953],
          [104.0716223, 1.4780383],
          [104.0716539, 1.4769817],
          [104.0718432, 1.476067],
          [104.072064, 1.4749473],
          [104.0726951, 1.4738276],
          [104.0734523, 1.4726448],
          [104.0738782, 1.4716355],
          [104.0735943, 1.4711781],
          [104.0728213, 1.4714147],
          [104.072206, 1.4720455],
          [104.0717485, 1.4725659],
          [104.0712279, 1.4731021],
          [104.0707389, 1.4734964],
          [104.0698239, 1.473938],
          [104.0688616, 1.4740641],
          [104.0682779, 1.474001],
          [104.067915, 1.4737329],
          [104.0676311, 1.4733229],
          [104.0674102, 1.4726606],
          [104.0674418, 1.4717459],
        ],
      ],
      [
        [
          [103.2694647, 1.4737381],
          [103.2696471, 1.4737462],
          [103.2698348, 1.4736899],
          [103.269926, 1.4735424],
          [103.2699555, 1.4733976],
          [103.2700762, 1.4732662],
          [103.2701728, 1.4730061],
          [103.2701835, 1.472738],
          [103.2702479, 1.4723546],
          [103.2704356, 1.4723385],
          [103.2705671, 1.4722152],
          [103.270677, 1.4721803],
          [103.2708514, 1.4720677],
          [103.2709131, 1.4719122],
          [103.2708165, 1.4717432],
          [103.2707307, 1.4717325],
          [103.270551, 1.4716172],
          [103.2704946, 1.4715609],
          [103.2706261, 1.4714215],
          [103.2706207, 1.4713196],
          [103.2705644, 1.4712981],
          [103.2705644, 1.4711668],
          [103.2704759, 1.4711855],
          [103.2704356, 1.4711212],
          [103.27061, 1.4709925],
          [103.2706234, 1.4707833],
          [103.2708058, 1.470609],
          [103.2708943, 1.4704482],
          [103.2709935, 1.4702497],
          [103.2711115, 1.4701854],
          [103.2710955, 1.4700862],
          [103.2712269, 1.4699199],
          [103.2712483, 1.4697403],
          [103.27142, 1.4696491],
          [103.2715622, 1.4695472],
          [103.2714656, 1.4695231],
          [103.2712832, 1.469625],
          [103.2709774, 1.4696759],
          [103.2708782, 1.4696384],
          [103.270787, 1.4698181],
          [103.2706797, 1.4697966],
          [103.2705778, 1.4697269],
          [103.2703364, 1.4699494],
          [103.270103, 1.4701532],
          [103.2698214, 1.4702873],
          [103.2695022, 1.4704535],
          [103.2692876, 1.4705366],
          [103.2691294, 1.4706922],
          [103.2688451, 1.4708959],
          [103.2687163, 1.4710595],
          [103.2684052, 1.4712472],
          [103.268137, 1.471341],
          [103.2679787, 1.4714429],
          [103.2676595, 1.4714724],
          [103.2674745, 1.4714563],
          [103.267225, 1.4715877],
          [103.2671151, 1.4718532],
          [103.2670024, 1.4719336],
          [103.2670104, 1.4721294],
          [103.2669005, 1.472309],
          [103.2670024, 1.4724404],
          [103.2672465, 1.4727729],
          [103.2675523, 1.4728908],
          [103.2677695, 1.4729847],
          [103.2678151, 1.4731295],
          [103.2680136, 1.4732904],
          [103.2682845, 1.4734432],
          [103.2687592, 1.4738454],
          [103.2688397, 1.4737462],
          [103.2689336, 1.4738722],
          [103.2690597, 1.4737167],
          [103.2693547, 1.4736443],
          [103.2694647, 1.4737381],
        ],
      ],
      [
        [
          [103.7070162, 1.4761518],
          [103.7067829, 1.4765962],
          [103.706731, 1.4768051],
          [103.7068913, 1.477498],
          [103.708268, 1.4786722],
          [103.7098229, 1.4794216],
          [103.7114186, 1.4795664],
          [103.7123633, 1.479195],
          [103.7125607, 1.4782413],
          [103.7123142, 1.4775222],
          [103.7110613, 1.4765515],
          [103.7092675, 1.4758174],
          [103.7083653, 1.4756923],
          [103.7078219, 1.4756937],
          [103.7070162, 1.4761518],
        ],
      ],
      [
        [
          [104.5508149, 2.4389843],
          [104.5508632, 2.4388342],
          [104.5508122, 2.4385984],
          [104.5508525, 2.438577],
          [104.5508981, 2.4386413],
          [104.5509678, 2.4386225],
          [104.5509812, 2.4384108],
          [104.5509034, 2.4384055],
          [104.5508927, 2.4382876],
          [104.5509866, 2.438242],
          [104.5510831, 2.4380625],
          [104.5512468, 2.4378856],
          [104.5513058, 2.4376712],
          [104.5513755, 2.4376766],
          [104.5514318, 2.4374622],
          [104.5513111, 2.437288],
          [104.5513701, 2.4372022],
          [104.5513245, 2.4369557],
          [104.5512092, 2.43687],
          [104.551177, 2.4365966],
          [104.5511878, 2.4365189],
          [104.5512146, 2.4363233],
          [104.5511287, 2.4361812],
          [104.551008, 2.4360231],
          [104.5510456, 2.4358436],
          [104.5511341, 2.4356292],
          [104.5511261, 2.435522],
          [104.551169, 2.4354684],
          [104.551177, 2.4353505],
          [104.5510778, 2.4351656],
          [104.5510805, 2.4350155],
          [104.5510295, 2.4348976],
          [104.5508793, 2.4348521],
          [104.551, 2.434769],
          [104.5508927, 2.4343938],
          [104.550772, 2.4340642],
          [104.5506594, 2.4338391],
          [104.5507693, 2.4337239],
          [104.5508632, 2.433614],
          [104.5508391, 2.4334907],
          [104.5508498, 2.4334264],
          [104.5507184, 2.4333085],
          [104.5505655, 2.4332094],
          [104.5506594, 2.4331745],
          [104.5506674, 2.4330486],
          [104.5504475, 2.4329709],
          [104.5503536, 2.4329253],
          [104.5502463, 2.4330405],
          [104.55008, 2.4329735],
          [104.5499217, 2.4329039],
          [104.5498171, 2.432936],
          [104.5496348, 2.4329575],
          [104.5495945, 2.4331558],
          [104.5495141, 2.4331424],
          [104.5494577, 2.4332094],
          [104.5494819, 2.4333246],
          [104.5493585, 2.4333353],
          [104.5493397, 2.4334586],
          [104.5493075, 2.4336086],
          [104.5492002, 2.4337882],
          [104.5490956, 2.4339972],
          [104.5490769, 2.4341714],
          [104.5489722, 2.4342518],
          [104.5489401, 2.4344474],
          [104.5488811, 2.4346511],
          [104.5488006, 2.4346698],
          [104.5487604, 2.4348146],
          [104.548645, 2.4350825],
          [104.5485699, 2.4353398],
          [104.548637, 2.4355676],
          [104.5487496, 2.4357471],
          [104.5487443, 2.4360741],
          [104.5487496, 2.4363688],
          [104.548814, 2.4365752],
          [104.5488408, 2.436744],
          [104.5488918, 2.4370227],
          [104.5489722, 2.4373336],
          [104.54905, 2.437497],
          [104.5490178, 2.4376524],
          [104.5491439, 2.4377784],
          [104.5491734, 2.4378749],
          [104.5493317, 2.4381991],
          [104.5495865, 2.4384189],
          [104.549734, 2.4383974],
          [104.5498708, 2.4387217],
          [104.5500264, 2.4388878],
          [104.5500988, 2.4389655],
          [104.5503402, 2.4390272],
          [104.5506433, 2.4390942],
          [104.5508149, 2.4389843],
        ],
      ],
      [
        [
          [104.5011519, 2.4804138],
          [104.5016683, 2.480692],
          [104.5023107, 2.4806239],
          [104.5026539, 2.4798779],
          [104.5032119, 2.4764692],
          [104.5067095, 2.4763191],
          [104.5085548, 2.4769408],
          [104.5103358, 2.4782057],
          [104.511634, 2.4786666],
          [104.5126317, 2.4782486],
          [104.5129536, 2.4776912],
          [104.5129322, 2.4768552],
          [104.5114516, 2.4756117],
          [104.5083617, 2.4738967],
          [104.5042204, 2.4710241],
          [104.5002078, 2.4706811],
          [104.4961737, 2.4706382],
          [104.4940923, 2.470874],
          [104.4929686, 2.4723113],
          [104.4935774, 2.473275],
          [104.5011519, 2.4804138],
        ],
      ],
      [
        [
          [104.5097564, 2.4690518],
          [104.5119791, 2.4704015],
          [104.512289, 2.4708031],
          [104.511752, 2.4711742],
          [104.5117949, 2.4718387],
          [104.5131691, 2.4725505],
          [104.5143483, 2.4718601],
          [104.5147775, 2.4709169],
          [104.5176286, 2.4700227],
          [104.5191763, 2.4691376],
          [104.5222663, 2.4660076],
          [104.5253715, 2.4630839],
          [104.5253396, 2.4622392],
          [104.5249485, 2.4607125],
          [104.5250129, 2.4595763],
          [104.5253562, 2.4588045],
          [104.5262953, 2.4582016],
          [104.5271916, 2.4582846],
          [104.527223, 2.4589758],
          [104.5287465, 2.4592756],
          [104.5316751, 2.4588037],
          [104.5339607, 2.4578398],
          [104.5391749, 2.4548814],
          [104.5399903, 2.4549456],
          [104.5415138, 2.4530163],
          [104.5402907, 2.4452557],
          [104.5399474, 2.4366804],
          [104.5375441, 2.4320497],
          [104.5346259, 2.4293056],
          [104.5305275, 2.4275262],
          [104.5253347, 2.4258112],
          [104.5219229, 2.4255325],
          [104.519863, 2.426047],
          [104.519863, 2.4286196],
          [104.5182108, 2.4331645],
          [104.5178031, 2.4341078],
          [104.516065, 2.4380096],
          [104.5131253, 2.4415683],
          [104.5096496, 2.4430187],
          [104.5075799, 2.4430707],
          [104.5059968, 2.4427417],
          [104.5059197, 2.4432319],
          [104.5053772, 2.4430002],
          [104.5041989, 2.4413325],
          [104.5029115, 2.4412468],
          [104.5030973, 2.4427695],
          [104.5037054, 2.4443338],
          [104.5022034, 2.4447626],
          [104.5023423, 2.4495704],
          [104.5034534, 2.4503742],
          [104.5037268, 2.4516014],
          [104.5023536, 2.4544097],
          [104.5012789, 2.4542755],
          [104.5009778, 2.4549301],
          [104.4999009, 2.4553972],
          [104.4994738, 2.4563693],
          [104.4995426, 2.4582257],
          [104.4997758, 2.460938],
          [104.5008515, 2.4620845],
          [104.5011948, 2.4628992],
          [104.5025898, 2.4633114],
          [104.5059664, 2.467],
          [104.5084072, 2.4687186],
          [104.5097564, 2.4690518],
        ],
      ],
      [
        [
          [104.3348777, 2.5851227],
          [104.3346963, 2.5849097],
          [104.3343958, 2.584331],
          [104.3342593, 2.5835032],
          [104.3346702, 2.5822387],
          [104.3354835, 2.5809279],
          [104.336436, 2.5808753],
          [104.3380239, 2.5808325],
          [104.3381097, 2.5812612],
          [104.3388393, 2.5813255],
          [104.3403505, 2.5806311],
          [104.340864, 2.5806747],
          [104.3414433, 2.5804818],
          [104.3419111, 2.580504],
          [104.3417008, 2.5800531],
          [104.3412502, 2.5798816],
          [104.3414219, 2.5792814],
          [104.3420433, 2.5787691],
          [104.3428587, 2.5780832],
          [104.3433939, 2.5773242],
          [104.3445847, 2.5770777],
          [104.3447926, 2.5755366],
          [104.3459187, 2.5742855],
          [104.3462806, 2.5724461],
          [104.3462218, 2.5705207],
          [104.3454173, 2.569451],
          [104.3447968, 2.5686478],
          [104.3427948, 2.5680919],
          [104.342741, 2.5667017],
          [104.3418064, 2.5660825],
          [104.3418978, 2.5652112],
          [104.3418129, 2.5640192],
          [104.3411048, 2.5637191],
          [104.3409117, 2.5641049],
          [104.3402465, 2.5640406],
          [104.340227, 2.5631954],
          [104.339755, 2.5629167],
          [104.3396048, 2.5635598],
          [104.3387465, 2.5636027],
          [104.3377809, 2.5625309],
          [104.337129, 2.5626487],
          [104.3359273, 2.56252],
          [104.3348544, 2.5624557],
          [104.3337601, 2.5628416],
          [104.3330305, 2.5635061],
          [104.3324475, 2.563837],
          [104.3313102, 2.564523],
          [104.3304734, 2.5651232],
          [104.3300664, 2.5657842],
          [104.3293153, 2.5666416],
          [104.3283933, 2.5676495],
          [104.3276577, 2.5686268],
          [104.3269496, 2.5694628],
          [104.3260754, 2.5700319],
          [104.3241378, 2.5708791],
          [104.3226069, 2.5714002],
          [104.3210944, 2.571843],
          [104.3199799, 2.5722181],
          [104.3194435, 2.5728612],
          [104.3190358, 2.5733971],
          [104.3193465, 2.5738439],
          [104.3193572, 2.5763057],
          [104.3190998, 2.5772703],
          [104.318575, 2.578661],
          [104.3179957, 2.5791969],
          [104.3178567, 2.5801591],
          [104.3175777, 2.5803734],
          [104.3169984, 2.5803305],
          [104.3167843, 2.5816042],
          [104.316162, 2.5821616],
          [104.3152178, 2.5826546],
          [104.3145458, 2.5827824],
          [104.3131387, 2.5827413],
          [104.3124031, 2.5835898],
          [104.3118239, 2.5856016],
          [104.3116364, 2.5873424],
          [104.3115185, 2.5885405],
          [104.3107246, 2.5891836],
          [104.3104944, 2.5897999],
          [104.3104328, 2.5919637],
          [104.3104427, 2.5923603],
          [104.3103356, 2.5928733],
          [104.3105502, 2.5934092],
          [104.310915, 2.5940094],
          [104.3115257, 2.5943936],
          [104.3118047, 2.594608],
          [104.3123196, 2.5947794],
          [104.3127898, 2.5954427],
          [104.3143347, 2.5953355],
          [104.3147424, 2.5956785],
          [104.3153647, 2.5955499],
          [104.3163714, 2.5961593],
          [104.3173685, 2.5968232],
          [104.3188528, 2.5973427],
          [104.3189601, 2.5975678],
          [104.3192497, 2.5976536],
          [104.3199692, 2.5976101],
          [104.3205915, 2.5975458],
          [104.3207463, 2.5973323],
          [104.3214651, 2.5972144],
          [104.3218393, 2.5972036],
          [104.3225903, 2.5971929],
          [104.3238393, 2.5970216],
          [104.3245895, 2.5962726],
          [104.3249221, 2.5958867],
          [104.325522, 2.5958926],
          [104.3260584, 2.5956783],
          [104.3264768, 2.5955068],
          [104.3264279, 2.5948918],
          [104.3265358, 2.5943082],
          [104.3267603, 2.5941993],
          [104.3268473, 2.593745],
          [104.3267722, 2.593327],
          [104.3269761, 2.5935735],
          [104.3271368, 2.5938889],
          [104.3272314, 2.5942318],
          [104.3274672, 2.5944527],
          [104.3274779, 2.594099],
          [104.3278685, 2.5941094],
          [104.3285336, 2.5943238],
          [104.329761, 2.5949072],
          [104.3313767, 2.5946728],
          [104.3322561, 2.5940091],
          [104.3331906, 2.5933814],
          [104.3334688, 2.5926583],
          [104.33437, 2.5927333],
          [104.3348314, 2.5925832],
          [104.3348846, 2.5922684],
          [104.3350885, 2.5918397],
          [104.3353024, 2.5906726],
          [104.3359032, 2.5907262],
          [104.3358925, 2.5904583],
          [104.3356146, 2.5903417],
          [104.3350404, 2.5904395],
          [104.3338949, 2.5888563],
          [104.3331396, 2.5867269],
          [104.3348777, 2.5851227],
        ],
      ],
      [
        [
          [103.732467505934849, 1.4581186408209],
          [103.731842612036601, 1.458424007233168],
          [103.7309575, 1.4591008],
          [103.7301153, 1.4599373],
          [103.730126, 1.4601894],
          [103.7293911, 1.4608999],
          [103.728514, 1.4608758],
          [103.7265236, 1.4628056],
          [103.72646, 1.4630729],
          [103.726041, 1.4635518],
          [103.7257782, 1.4640103],
          [103.725628, 1.4642275],
          [103.7254858, 1.4643374],
          [103.7253678, 1.4646887],
          [103.7249225, 1.4647637],
          [103.7246141, 1.4649032],
          [103.7244148, 1.4651488],
          [103.724193, 1.4655655],
          [103.7241885, 1.4658137],
          [103.7241995, 1.4660945],
          [103.723684, 1.4670947],
          [103.7233928, 1.4704457],
          [103.7233052, 1.4710461],
          [103.7231886, 1.4721306],
          [103.7230323, 1.4727385],
          [103.7230409, 1.4728378],
          [103.7236066, 1.4730813],
          [103.7234362, 1.4735478],
          [103.722972, 1.4746016],
          [103.7224297, 1.4754012],
          [103.7218596, 1.475068],
          [103.721681, 1.475095],
          [103.7211557, 1.4760823],
          [103.7203672, 1.4765499],
          [103.7201241, 1.4767895],
          [103.7199635, 1.4770717],
          [103.7198801, 1.4774543],
          [103.7199631, 1.4776234],
          [103.7197566, 1.4777226],
          [103.719652, 1.4777092],
          [103.7184557, 1.4779746],
          [103.7178093, 1.478106],
          [103.7171495, 1.4784063],
          [103.7168679, 1.4786316],
          [103.7166131, 1.4789238],
          [103.7164789, 1.4792402],
          [103.7164843, 1.4795995],
          [103.7166023, 1.4799642],
          [103.7167954, 1.4801894],
          [103.7170342, 1.4803825],
          [103.7171951, 1.4804441],
          [103.7169464, 1.480537],
          [103.7165567, 1.4801867],
          [103.7164146, 1.4799454],
          [103.7163582, 1.4796263],
          [103.7159103, 1.4792617],
          [103.7154302, 1.4789667],
          [103.7152746, 1.4789372],
          [103.7150118, 1.4790069],
          [103.7147972, 1.4791437],
          [103.7143332, 1.4797336],
          [103.7138155, 1.4801492],
          [103.712123, 1.4810716],
          [103.711938, 1.4812834],
          [103.7119004, 1.481404],
          [103.7118441, 1.4814523],
          [103.7117931, 1.4814496],
          [103.7117127, 1.4814201],
          [103.7116215, 1.4813879],
          [103.7115041, 1.4814287],
          [103.7111903, 1.4813093],
          [103.7110175, 1.4812474],
          [103.7112023, 1.4810042],
          [103.7113526, 1.4808062],
          [103.7113464, 1.4807503],
          [103.7102824, 1.4803225],
          [103.7068304, 1.4789725],
          [103.7059077, 1.4779858],
          [103.7052643, 1.4773515],
          [103.7049829, 1.4770741],
          [103.7047043, 1.4770579],
          [103.7043417, 1.4772814],
          [103.704288, 1.4775607],
          [103.7037968, 1.4781191],
          [103.7035817, 1.478541],
          [103.7024948, 1.4782753],
          [103.7017311, 1.4780567],
          [103.7024635, 1.4771578],
          [103.7033819, 1.4762767],
          [103.7038951, 1.4758223],
          [103.7042681, 1.4756233],
          [103.7060204, 1.4748205],
          [103.7062455, 1.4746293],
          [103.7067819, 1.4744164],
          [103.7070952, 1.474368],
          [103.7075337, 1.4743907],
          [103.70771, 1.4743075],
          [103.7084798, 1.4742591],
          [103.7091091, 1.4742809],
          [103.7095787, 1.4742857],
          [103.7099057, 1.4743323],
          [103.7100072, 1.4742712],
          [103.7100774, 1.4742809],
          [103.7102032, 1.4743535],
          [103.7113619, 1.4744167],
          [103.7126595, 1.4745764],
          [103.7127976, 1.4747732],
          [103.7138329, 1.4749563],
          [103.7149024, 1.475561],
          [103.7167877, 1.4756261],
          [103.717795, 1.4749088],
          [103.7191494, 1.4741912],
          [103.7202913, 1.4740327],
          [103.7209987, 1.4733217],
          [103.7213952, 1.4729811],
          [103.7215302, 1.4698741],
          [103.7214493, 1.46975],
          [103.7198173, 1.4673731],
          [103.7181706, 1.4649749],
          [103.7176711, 1.4639474],
          [103.7146457, 1.4624855],
          [103.7119542, 1.4625622],
          [103.7099469, 1.462116],
          [103.7094589, 1.4613663],
          [103.7098658, 1.4608654],
          [103.7103529, 1.4601789],
          [103.708146, 1.459066],
          [103.708395, 1.4597262],
          [103.7081831, 1.4610619],
          [103.7072924, 1.4614387],
          [103.7069471, 1.4607283],
          [103.706654, 1.4604109],
          [103.7065074, 1.4613382],
          [103.7060757, 1.4610833],
          [103.7059042, 1.4610509],
          [103.7058515, 1.4611319],
          [103.7057408, 1.4611899],
          [103.7056938, 1.4611652],
          [103.705659, 1.4611469],
          [103.7056099, 1.4611211],
          [103.7055289, 1.4610482],
          [103.7054573, 1.4611414],
          [103.7053223, 1.4611602],
          [103.705271, 1.4611562],
          [103.7052872, 1.4611211],
          [103.705352, 1.4610914],
          [103.7054317, 1.4610212],
          [103.7054731, 1.460988],
          [103.7054789, 1.4609619],
          [103.7054765, 1.4609309],
          [103.7054598, 1.4608989],
          [103.7054349, 1.4608765],
          [103.7053967, 1.4608691],
          [103.705384, 1.460861],
          [103.7053754, 1.4608558],
          [103.705366, 1.4608501],
          [103.7053366, 1.4608316],
          [103.7052373, 1.4607085],
          [103.7051275, 1.4606255],
          [103.7050226, 1.4605956],
          [103.7050111, 1.4605555],
          [103.705055, 1.4604906],
          [103.7050531, 1.4604581],
          [103.7049963, 1.4603867],
          [103.7049748, 1.4603855],
          [103.704958, 1.4603912],
          [103.7049158, 1.4604055],
          [103.7048832, 1.4604391],
          [103.7040692, 1.45984],
          [103.7037086, 1.4595914],
          [103.7034971, 1.4594752],
          [103.7033444, 1.4593473],
          [103.7031444, 1.4590566],
          [103.7025319, 1.4587608],
          [103.7020517, 1.4588409],
          [103.7015714, 1.4591209],
          [103.7008431, 1.4588409],
          [103.7008111, 1.4583928],
          [103.700595, 1.4583448],
          [103.7004749, 1.4586968],
          [103.7001868, 1.4584568],
          [103.6995821, 1.458668],
          [103.6990182, 1.4588649],
          [103.6980735, 1.4587246],
          [103.6965609, 1.4581664],
          [103.6958296, 1.4578525],
          [103.6956101, 1.4579526],
          [103.6952824, 1.457658],
          [103.694576, 1.4571766],
          [103.6940113, 1.4568285],
          [103.6938531, 1.4570728],
          [103.6936817, 1.4569677],
          [103.6935435, 1.4566725],
          [103.6928552, 1.4565765],
          [103.6918509, 1.45677],
          [103.6915377, 1.455853],
          [103.6912773, 1.4550906],
          [103.6915038, 1.4549159],
          [103.6917165, 1.4548274],
          [103.692035, 1.4547034],
          [103.6923649, 1.4546611],
          [103.6925256, 1.4546174],
          [103.6925876, 1.4544892],
          [103.6926256, 1.4544075],
          [103.6925171, 1.4541313],
          [103.6925143, 1.4540481],
          [103.6925763, 1.4539932],
          [103.6926311, 1.4540794],
          [103.6927384, 1.4542482],
          [103.6928004, 1.4544328],
          [103.6927215, 1.4546146],
          [103.6927954, 1.4546181],
          [103.6937851, 1.4545208],
          [103.6943061, 1.4546165],
          [103.6944406, 1.4544985],
          [103.6945135, 1.4544908],
          [103.694531, 1.454594],
          [103.694884, 1.4546045],
          [103.695149, 1.4546445],
          [103.69553, 1.4546137],
          [103.6959185, 1.4546747],
          [103.6962561, 1.4546958],
          [103.6965741, 1.4546681],
          [103.6969409, 1.4544585],
          [103.6972573, 1.454037],
          [103.6972872, 1.4536885],
          [103.6973136, 1.4527553],
          [103.6972406, 1.4524287],
          [103.6971694, 1.4522482],
          [103.6969404, 1.4519666],
          [103.696822, 1.4518664],
          [103.6966248, 1.4515907],
          [103.6962655, 1.451179],
          [103.6955098, 1.4506695],
          [103.6950784, 1.4504405],
          [103.694843, 1.450246],
          [103.6947162, 1.4500995],
          [103.6946403, 1.4500465],
          [103.694408, 1.4499594],
          [103.6943035, 1.4499554],
          [103.6940066, 1.4498057],
          [103.6939236, 1.4496929],
          [103.6937356, 1.4496394],
          [103.6933755, 1.4494233],
          [103.6932886, 1.4492393],
          [103.692487, 1.4482711],
          [103.6922869, 1.4483192],
          [103.6921697, 1.4485097],
          [103.6920711, 1.4486027],
          [103.6918314, 1.4485182],
          [103.6915298, 1.4485012],
          [103.691028, 1.4483293],
          [103.6911323, 1.4480785],
          [103.6916144, 1.4481321],
          [103.6918343, 1.4482335],
          [103.6918906, 1.4482082],
          [103.6918418, 1.4481267],
          [103.6905446, 1.4467344],
          [103.690239, 1.4467004],
          [103.6900579, 1.4468928],
          [103.6888926, 1.4459994],
          [103.6890336, 1.4440581],
          [103.6890036, 1.4437339],
          [103.6891055, 1.4431753],
          [103.6891224, 1.4430541],
          [103.6890801, 1.4429019],
          [103.6890448, 1.4428836],
          [103.6887038, 1.4429921],
          [103.6884853, 1.4430231],
          [103.6880568, 1.4430188],
          [103.6877206, 1.4430621],
          [103.6872717, 1.4431992],
          [103.6868672, 1.4432809],
          [103.6866092, 1.4432964],
          [103.6862642, 1.4432729],
          [103.6858594, 1.4433598],
          [103.6855088, 1.4434312],
          [103.6853392, 1.4435388],
          [103.6851776, 1.4436907],
          [103.6851941, 1.4437304],
          [103.6852913, 1.4436966],
          [103.6853449, 1.4437318],
          [103.6853294, 1.4438079],
          [103.6852357, 1.4439746],
          [103.6850334, 1.4441067],
          [103.6848876, 1.4441978],
          [103.6848171, 1.4443128],
          [103.6847613, 1.4444984],
          [103.6844879, 1.4445406],
          [103.6843417, 1.4444599],
          [103.6840984, 1.4440182],
          [103.6838762, 1.4439629],
          [103.6836903, 1.4436846],
          [103.6832532, 1.4432556],
          [103.6830276, 1.4431288],
          [103.6828247, 1.4430893],
          [103.6828162, 1.4429146],
          [103.6828472, 1.4428244],
          [103.6827457, 1.4427286],
          [103.6825306, 1.4427626],
          [103.6821571, 1.4426494],
          [103.6818634, 1.4423622],
          [103.6817097, 1.4421176],
          [103.6817295, 1.4416845],
          [103.6814778, 1.4409038],
          [103.6814319, 1.4406726],
          [103.6813761, 1.4405112],
          [103.6813343, 1.4404295],
          [103.681271, 1.4403678],
          [103.681113, 1.4403697],
          [103.6804893, 1.4403358],
          [103.6802094, 1.4407308],
          [103.6798731, 1.440844],
          [103.6795977, 1.4408656],
          [103.679317, 1.4408241],
          [103.6791715, 1.4407743],
          [103.6790937, 1.4408121],
          [103.6790378, 1.4406375],
          [103.6791117, 1.4404833],
          [103.6790542, 1.4401804],
          [103.6787736, 1.439848],
          [103.6784942, 1.4397126],
          [103.6781537, 1.4395695],
          [103.6779066, 1.439609],
          [103.6778583, 1.4397278],
          [103.6779152, 1.4400322],
          [103.6779354, 1.4402396],
          [103.6778456, 1.4405058],
          [103.6771231, 1.4417023],
          [103.6770194, 1.4418722],
          [103.6769964, 1.442022],
          [103.677006, 1.4421093],
          [103.6770689, 1.4421664],
          [103.6770651, 1.4421913],
          [103.6770355, 1.4422157],
          [103.6770381, 1.442234],
          [103.6770177, 1.4422488],
          [103.6769949, 1.4422653],
          [103.6768941, 1.4421616],
          [103.6768502, 1.4421378],
          [103.6768093, 1.442156],
          [103.6764577, 1.4424837],
          [103.676403, 1.4425686],
          [103.6763915, 1.4426665],
          [103.6764764, 1.4428263],
          [103.6764462, 1.4428638],
          [103.6765139, 1.4429833],
          [103.6763411, 1.4430581],
          [103.6762351, 1.4430755],
          [103.6760631, 1.4430624],
          [103.6759508, 1.4430351],
          [103.6759076, 1.4430999],
          [103.6759392, 1.4432179],
          [103.675909, 1.4432654],
          [103.6758298, 1.4432453],
          [103.6757981, 1.4432079],
          [103.6758211, 1.4428969],
          [103.6757952, 1.4428623],
          [103.6755994, 1.4427831],
          [103.6752583, 1.4427438],
          [103.6749757, 1.4428551],
          [103.6746833, 1.4428818],
          [103.6742436, 1.4420071],
          [103.6741434, 1.4417313],
          [103.6743129, 1.4411813],
          [103.6742412, 1.4400682],
          [103.6742407, 1.4396872],
          [103.6738996, 1.4385293],
          [103.673096, 1.4374291],
          [103.6728131, 1.4366539],
          [103.6728537, 1.4364494],
          [103.672527, 1.4357042],
          [103.6721796, 1.4355823],
          [103.6720181, 1.4359548],
          [103.6718107, 1.4360813],
          [103.6708234, 1.4355016],
          [103.6708601, 1.4351425],
          [103.6706283, 1.434203],
          [103.670553, 1.4341573],
          [103.6704575, 1.4341484],
          [103.6703131, 1.4343534],
          [103.6702029, 1.434372],
          [103.6701808, 1.4342999],
          [103.6702441, 1.4341617],
          [103.6702529, 1.4340896],
          [103.670356, 1.4339513],
          [103.6703102, 1.4335009],
          [103.6704935, 1.4337135],
          [103.6707868, 1.4337061],
          [103.6711606, 1.4335156],
          [103.6712779, 1.4332004],
          [103.6711609, 1.4327479],
          [103.6712266, 1.4319399],
          [103.6710825, 1.4316834],
          [103.6704157, 1.4312351],
          [103.6692033, 1.4311191],
          [103.6688147, 1.4306867],
          [103.6686681, 1.4305878],
          [103.668664, 1.4304193],
          [103.6689844, 1.4303662],
          [103.6690829, 1.4303129],
          [103.6689697, 1.4302647],
          [103.6689727, 1.4301676],
          [103.6690153, 1.4300131],
          [103.669001, 1.4298558],
          [103.6689145, 1.4298477],
          [103.6687117, 1.4295367],
          [103.6685109, 1.4292889],
          [103.6683346, 1.4290781],
          [103.6682252, 1.428945],
          [103.6682246, 1.4287996],
          [103.6681412, 1.4285199],
          [103.6682246, 1.4283232],
          [103.668178, 1.4282213],
          [103.668047, 1.4281713],
          [103.6680059, 1.4279085],
          [103.6679314, 1.4270334],
          [103.6678646, 1.4269826],
          [103.6674066, 1.4264526],
          [103.667134, 1.4264514],
          [103.6668988, 1.4261569],
          [103.6671349, 1.4258094],
          [103.6673136, 1.4256021],
          [103.6674519, 1.4253743],
          [103.6674599, 1.4251155],
          [103.6676479, 1.4247017],
          [103.6677805, 1.4245047],
          [103.6679362, 1.4237839],
          [103.6679824, 1.4235705],
          [103.6680979, 1.4232335],
          [103.6680986, 1.4231873],
          [103.6680313, 1.422935],
          [103.6679803, 1.4226198],
          [103.6679137, 1.4224388],
          [103.6676941, 1.4220008],
          [103.6675464, 1.4217898],
          [103.6664336, 1.4200523],
          [103.6645579, 1.417552],
          [103.6639782, 1.4173488],
          [103.6631177, 1.4162626],
          [103.6630477, 1.4162527],
          [103.6629132, 1.4161402],
          [103.662838, 1.4161082],
          [103.6627519, 1.4160896],
          [103.6625837, 1.4160926],
          [103.6625144, 1.4161183],
          [103.6624558, 1.4161628],
          [103.6623707, 1.4162551],
          [103.662339, 1.4163308],
          [103.6623233, 1.4164253],
          [103.6623253, 1.4165169],
          [103.6623408, 1.4166086],
          [103.6624282, 1.4167569],
          [103.6625428, 1.4168488],
          [103.6627326, 1.4169313],
          [103.6627901, 1.4169811],
          [103.6628341, 1.4170389],
          [103.66289, 1.4171815],
          [103.6628794, 1.4173347],
          [103.6628606, 1.4174134],
          [103.6628257, 1.4174759],
          [103.6627521, 1.4175538],
          [103.6626435, 1.417629],
          [103.6625631, 1.4176577],
          [103.6623778, 1.4176756],
          [103.6623189, 1.417711],
          [103.6622573, 1.4178001],
          [103.6622423, 1.4179145],
          [103.6622514, 1.4180315],
          [103.6622444, 1.4181083],
          [103.6622106, 1.4181769],
          [103.6621686, 1.4182429],
          [103.6621052, 1.4183063],
          [103.6619933, 1.4183743],
          [103.6618464, 1.418394],
          [103.6617095, 1.4183513],
          [103.6615967, 1.4182736],
          [103.6615211, 1.4181333],
          [103.6615101, 1.4180119],
          [103.6615257, 1.4178825],
          [103.6615897, 1.4177665],
          [103.6616404, 1.4175367],
          [103.6616145, 1.4173983],
          [103.6615267, 1.4172519],
          [103.6614203, 1.4171658],
          [103.6612656, 1.4171213],
          [103.6610871, 1.4171234],
          [103.6609408, 1.4171793],
          [103.6608622, 1.4172455],
          [103.6608105, 1.4173547],
          [103.6607553, 1.4175307],
          [103.6606276, 1.4176853],
          [103.6605454, 1.4177205],
          [103.6603315, 1.4177692],
          [103.6602254, 1.4178394],
          [103.6601623, 1.4179097],
          [103.6600808, 1.4180277],
          [103.6600289, 1.4182371],
          [103.6597761, 1.4184737],
          [103.6596685, 1.4186404],
          [103.6596124, 1.4187567],
          [103.6596181, 1.4188999],
          [103.6596821, 1.4191031],
          [103.6596977, 1.4192955],
          [103.6596405, 1.419551],
          [103.6593334, 1.4199261],
          [103.6590947, 1.4202996],
          [103.6590008, 1.42077],
          [103.6589863, 1.4209425],
          [103.6590278, 1.4210899],
          [103.6590554, 1.4211152],
          [103.6590831, 1.4211222],
          [103.6591794, 1.421349],
          [103.6592905, 1.4215261],
          [103.6594704, 1.421707],
          [103.6596599, 1.4218322],
          [103.6604219, 1.422172],
          [103.6615454, 1.4229571],
          [103.6614326, 1.42312],
          [103.6605454, 1.4226589],
          [103.6601879, 1.4225881],
          [103.6597438, 1.4224604],
          [103.6594252, 1.4223383],
          [103.6591493, 1.422208],
          [103.6588611, 1.4220424],
          [103.6585691, 1.4218279],
          [103.6583042, 1.4215854],
          [103.6580955, 1.4213782],
          [103.6578999, 1.4211263],
          [103.6575758, 1.4206638],
          [103.6573175, 1.4205369],
          [103.6570173, 1.4206037],
          [103.6568942, 1.4202849],
          [103.6571077, 1.4201471],
          [103.657266, 1.4199015],
          [103.6572091, 1.4195976],
          [103.6571292, 1.4187345],
          [103.6572351, 1.4178652],
          [103.657594, 1.416983],
          [103.6576802, 1.4169572],
          [103.6577391, 1.4169396],
          [103.6593852, 1.4176247],
          [103.6596746, 1.417563],
          [103.6599525, 1.4172839],
          [103.6600791, 1.4170171],
          [103.6597215, 1.4155882],
          [103.660106449171323, 1.415234720800072],
          [103.6611013, 1.4143212],
          [103.6597532, 1.4127794],
          [103.658908, 1.4124289],
          [103.6589295, 1.4130617],
          [103.6564994, 1.4138648],
          [103.6555687, 1.4128418],
          [103.6556169, 1.4127292],
          [103.6556089, 1.4126246],
          [103.6556089, 1.4125335],
          [103.6556545, 1.4123619],
          [103.6557537, 1.4122573],
          [103.6558905, 1.4121876],
          [103.6563519, 1.4122251],
          [103.6565074, 1.412201],
          [103.6567676, 1.4120079],
          [103.6570224, 1.4117934],
          [103.6573631, 1.4116003],
          [103.6576071, 1.4115199],
          [103.6579853, 1.411485],
          [103.6592433, 1.4117022],
          [103.6593828, 1.4116942],
          [103.6594605, 1.4116432],
          [103.659474, 1.4115601],
          [103.6594123, 1.4113295],
          [103.6592398, 1.4110845],
          [103.658425, 1.4104581],
          [103.6572818, 1.4103713],
          [103.6569169, 1.4098923],
          [103.6565109, 1.4096333],
          [103.6559208, 1.4096062],
          [103.6541933, 1.4087057],
          [103.6534515, 1.4087586],
          [103.651936, 1.4080488],
          [103.6510919, 1.4072954],
          [103.65041, 1.4063855],
          [103.6502934, 1.405379],
          [103.6505053, 1.4047221],
          [103.650037, 1.403751],
          [103.6501793, 1.4036749],
          [103.6501899, 1.4035981],
          [103.6501643, 1.403516],
          [103.6500788, 1.4035092],
          [103.6499854, 1.4035066],
          [103.6499543, 1.4033237],
          [103.6500814, 1.4024761],
          [103.6496257, 1.4004526],
          [103.6492972, 1.4003784],
          [103.6487461, 1.3993295],
          [103.6483116, 1.3987363],
          [103.6481527, 1.3977933],
          [103.6480255, 1.3974437],
          [103.6481315, 1.3965114],
          [103.6476758, 1.3958757],
          [103.6472095, 1.3951659],
          [103.6469237, 1.3951951],
          [103.6468571, 1.3955949],
          [103.6469515, 1.3957785],
          [103.6469709, 1.3962072],
          [103.6467522, 1.3958796],
          [103.6464899, 1.3956848],
          [103.6460815, 1.3954638],
          [103.646029, 1.3950368],
          [103.6460396, 1.3945916],
          [103.6459687, 1.3943367],
          [103.6458205, 1.3941595],
          [103.6461623, 1.3943611],
          [103.6463325, 1.3948682],
          [103.6464599, 1.3950368],
          [103.6467609, 1.3950437],
          [103.6469537, 1.3947007],
          [103.6471565, 1.3941594],
          [103.6471459, 1.3931423],
          [103.6468043, 1.3912598],
          [103.6466453, 1.3904758],
          [103.6468314, 1.3901536],
          [103.6470993, 1.3889629],
          [103.6467884, 1.3884425],
          [103.6462844, 1.387599],
          [103.6455799, 1.387217],
          [103.6445608, 1.3860108],
          [103.6440707, 1.385553],
          [103.6436466, 1.3851568],
          [103.6424776, 1.3830966],
          [103.6416308, 1.3826022],
          [103.641331, 1.3826322],
          [103.6409638, 1.3816433],
          [103.6410687, 1.381059],
          [103.6410877, 1.3807402],
          [103.6409633, 1.3804083],
          [103.6402611, 1.3800039],
          [103.6393474, 1.3796933],
          [103.6387685, 1.3795177],
          [103.638356, 1.3794567],
          [103.638036, 1.3794521],
          [103.6377915, 1.378557],
          [103.6376474, 1.3781968],
          [103.637494, 1.3777744],
          [103.637657, 1.377706],
          [103.6379473, 1.3775984],
          [103.6380973, 1.3775169],
          [103.6381951, 1.3774712],
          [103.6383843, 1.3773669],
          [103.6384495, 1.3772723],
          [103.6384626, 1.3771224],
          [103.638518, 1.377168],
          [103.6385995, 1.3771713],
          [103.638655, 1.3770995],
          [103.6388767, 1.3767279],
          [103.6388688, 1.3766359],
          [103.638942, 1.3765844],
          [103.6389876, 1.3765094],
          [103.639017, 1.3764279],
          [103.63903, 1.3763432],
          [103.6390286, 1.3763042],
          [103.6390256, 1.3762224],
          [103.639092, 1.3761508],
          [103.6391148, 1.3760791],
          [103.6390862, 1.3760251],
          [103.6391077, 1.3759549],
          [103.6391572, 1.3758313],
          [103.6391539, 1.3757335],
          [103.6390852, 1.3756986],
          [103.6391302, 1.375408],
          [103.6389429, 1.3747562],
          [103.6385757, 1.3742768],
          [103.6377813, 1.3735501],
          [103.6374638, 1.373275],
          [103.6367697, 1.3726736],
          [103.6359154, 1.3716997],
          [103.6349637, 1.3702838],
          [103.6346097, 1.3697882],
          [103.6341585, 1.3692634],
          [103.633434, 1.3685424],
          [103.6329832, 1.3680939],
          [103.6324677, 1.3676324],
          [103.631536, 1.3668262],
          [103.630656, 1.3660953],
          [103.6298013, 1.3654785],
          [103.629026, 1.3648977],
          [103.6282668, 1.3643777],
          [103.6278388, 1.3640068],
          [103.6275657, 1.363729],
          [103.6272487, 1.3634826],
          [103.6272102, 1.3634176],
          [103.6271137, 1.3633781],
          [103.626989, 1.3632179],
          [103.6268138, 1.3630938],
          [103.6266342, 1.3630676],
          [103.6264402, 1.3629909],
          [103.6264133, 1.3629309],
          [103.6263998, 1.3628756],
          [103.6263065, 1.3628444],
          [103.6262057, 1.3627927],
          [103.6258756, 1.3625648],
          [103.6255455, 1.3623081],
          [103.6254611, 1.3622306],
          [103.6254202, 1.3621746],
          [103.6253767, 1.3621423],
          [103.6252946, 1.3621282],
          [103.6252259, 1.3621],
          [103.6251885, 1.3620461],
          [103.6251578, 1.361987],
          [103.6251023, 1.3619382],
          [103.6250261, 1.3619155],
          [103.6249136, 1.3618713],
          [103.6247008, 1.3617128],
          [103.6245145, 1.3615144],
          [103.6244211, 1.3614639],
          [103.6243398, 1.3614195],
          [103.6242756, 1.3613293],
          [103.6242235, 1.3612086],
          [103.6240332, 1.3609986],
          [103.6239354, 1.3609281],
          [103.6237831, 1.360788],
          [103.6235753, 1.3605743],
          [103.6233837, 1.3604495],
          [103.6233153, 1.3603697],
          [103.6231242, 1.3602209],
          [103.6229178, 1.3599499],
          [103.6226803, 1.3597517],
          [103.6226291, 1.3596893],
          [103.622565, 1.3596527],
          [103.6225405, 1.3595602],
          [103.6225451, 1.3595126],
          [103.6225204, 1.3594723],
          [103.6224642, 1.3594778],
          [103.6223685, 1.3593949],
          [103.6222147, 1.3593735],
          [103.6216554, 1.3589165],
          [103.6210656, 1.3582727],
          [103.6203677, 1.3576938],
          [103.6196345, 1.3572241],
          [103.6194161, 1.3570587],
          [103.6192078, 1.3569742],
          [103.6190238, 1.3568051],
          [103.6188881, 1.3567803],
          [103.6183196, 1.356328],
          [103.6178588, 1.3560297],
          [103.6176609, 1.3557416],
          [103.6171862, 1.3554581],
          [103.6163341, 1.3548316],
          [103.6155793, 1.3541727],
          [103.6152898, 1.353969],
          [103.615022, 1.3538734],
          [103.614627, 1.3535524],
          [103.6143834, 1.3533342],
          [103.6142399, 1.3531278],
          [103.6136165, 1.352792],
          [103.6135157, 1.3527485],
          [103.6134599, 1.3526874],
          [103.6134383, 1.352597],
          [103.6133292, 1.352525],
          [103.6132103, 1.3524725],
          [103.6132137, 1.3524036],
          [103.6131828, 1.3523346],
          [103.6130844, 1.3523057],
          [103.6130006, 1.3522964],
          [103.6129477, 1.3521372],
          [103.6127862, 1.3520958],
          [103.6123959, 1.3517448],
          [103.6122127, 1.3516151],
          [103.6121705, 1.3514955],
          [103.612056, 1.3514477],
          [103.6119176, 1.3514529],
          [103.6118223, 1.3514129],
          [103.6116822, 1.3513228],
          [103.611553, 1.3510319],
          [103.6114275, 1.3510834],
          [103.6113431, 1.351068],
          [103.611354, 1.3509963],
          [103.611399, 1.3508866],
          [103.6114181, 1.3507727],
          [103.611448, 1.350637],
          [103.6114066, 1.3506082],
          [103.6113642, 1.3506328],
          [103.6112655, 1.3505577],
          [103.6111725, 1.3503485],
          [103.610938, 1.3502012],
          [103.609899, 1.3494497],
          [103.6098483, 1.3493659],
          [103.6097338, 1.3493263],
          [103.6096194, 1.3491679],
          [103.6091697, 1.3487284],
          [103.6080189, 1.3477097],
          [103.60677, 1.3465188],
          [103.6062761, 1.3461512],
          [103.6060846, 1.3460437],
          [103.6059208, 1.3459084],
          [103.6055658, 1.3457023],
          [103.6055255, 1.3456626],
          [103.6054691, 1.3456487],
          [103.6054305, 1.3456434],
          [103.6054145, 1.3456265],
          [103.6053889, 1.345628],
          [103.6053575, 1.3456092],
          [103.6053433, 1.3455785],
          [103.6052594, 1.3455309],
          [103.604662, 1.3453691],
          [103.604334, 1.3452674],
          [103.6039922, 1.3452072],
          [103.6034203, 1.3450618],
          [103.6030977, 1.3449088],
          [103.6029027, 1.3448441],
          [103.602658, 1.3448467],
          [103.6025765, 1.3448143],
          [103.60249, 1.3448114],
          [103.6023956, 1.3448743],
          [103.6023344, 1.3449215],
          [103.6022072, 1.344913],
          [103.6017684, 1.3449622],
          [103.6014935, 1.3449528],
          [103.6014487, 1.3449153],
          [103.6013872, 1.3449013],
          [103.6013253, 1.3449296],
          [103.6012954, 1.3449674],
          [103.6013014, 1.3450196],
          [103.6012571, 1.3450254],
          [103.6012248, 1.3449947],
          [103.6012024, 1.3449522],
          [103.6010685, 1.3449421],
          [103.6007926, 1.3450577],
          [103.6006204, 1.3450761],
          [103.6004188, 1.3450415],
          [103.6002045, 1.3450714],
          [103.5999475, 1.3450661],
          [103.5997571, 1.3451676],
          [103.5996001, 1.3453208],
          [103.599361, 1.3454531],
          [103.5990818, 1.3454315],
          [103.5988779, 1.3454004],
          [103.5987256, 1.3454387],
          [103.5987086, 1.3454801],
          [103.5986101, 1.3454612],
          [103.5985864, 1.3453586],
          [103.5985766, 1.3452545],
          [103.5985586, 1.34516],
          [103.5984966, 1.3451393],
          [103.5983842, 1.3451374],
          [103.5980478, 1.3451945],
          [103.5978761, 1.3451731],
          [103.5977045, 1.3452221],
          [103.5975732, 1.3453799],
          [103.5973853, 1.3454345],
          [103.597109, 1.3454067],
          [103.5966934, 1.3452889],
          [103.5959742, 1.3453644],
          [103.5957996, 1.3454013],
          [103.5950354, 1.3454237],
          [103.5951136, 1.3452697],
          [103.5952817, 1.3453103],
          [103.595389, 1.3451991],
          [103.5953633, 1.3450048],
          [103.5949503, 1.3448186],
          [103.5946717, 1.3447406],
          [103.5945069, 1.3447234],
          [103.5944568, 1.3448361],
          [103.5943687, 1.3449184],
          [103.594405, 1.3451514],
          [103.5945458, 1.3452837],
          [103.5943444, 1.3454547],
          [103.594136, 1.3454008],
          [103.5939083, 1.3453663],
          [103.5938822, 1.3454371],
          [103.5938063, 1.3454692],
          [103.593722, 1.3454669],
          [103.5936996, 1.3454341],
          [103.593673, 1.3453959],
          [103.5936088, 1.3454008],
          [103.5935831, 1.3454534],
          [103.5935402, 1.3454473],
          [103.5934927, 1.3453774],
          [103.5934129, 1.3453663],
          [103.5933642, 1.3454162],
          [103.5933776, 1.3454771],
          [103.5933122, 1.3454673],
          [103.5932035, 1.3454324],
          [103.5930697, 1.3453475],
          [103.5929535, 1.3453584],
          [103.5928582, 1.3454069],
          [103.5928209, 1.3454705],
          [103.5927962, 1.3455382],
          [103.5926627, 1.3455452],
          [103.5925603, 1.3455506],
          [103.5924442, 1.3454689],
          [103.5923095, 1.3452754],
          [103.5920992, 1.3451469],
          [103.5918572, 1.3450659],
          [103.5917489, 1.3450306],
          [103.5916296, 1.3450342],
          [103.591384, 1.3451155],
          [103.5912536, 1.3451143],
          [103.5911232, 1.3451187],
          [103.5908624, 1.3450997],
          [103.5906, 1.3451306],
          [103.5903377, 1.3451393],
          [103.5900049, 1.3451402],
          [103.5898793, 1.3450824],
          [103.5897741, 1.3450897],
          [103.5896983, 1.345119],
          [103.5896464, 1.3450917],
          [103.5895907, 1.3450418],
          [103.5895398, 1.3450116],
          [103.5894852, 1.3450154],
          [103.5894099, 1.3450684],
          [103.5893211, 1.3451479],
          [103.5892296, 1.3444691],
          [103.58931, 1.3443511],
          [103.5896909, 1.3443243],
          [103.590458, 1.3442331],
          [103.5914397, 1.3443082],
          [103.5924268, 1.3443189],
          [103.5929954, 1.3444423],
          [103.5934679, 1.344387],
          [103.5938136, 1.344195],
          [103.5943465, 1.3441134],
          [103.5946682, 1.3441998],
          [103.5947018, 1.3443438],
          [103.5947066, 1.3445262],
          [103.5954604, 1.3448622],
          [103.5957101, 1.3447518],
          [103.5957533, 1.3444782],
          [103.5956429, 1.344243],
          [103.5962863, 1.3441326],
          [103.5969248, 1.3441086],
          [103.5983364, 1.3441086],
          [103.5995463, 1.344051],
          [103.6001753, 1.3441614],
          [103.6006026, 1.344219],
          [103.601198, 1.344219],
          [103.6017597, 1.3440702],
          [103.6027008, 1.3439166],
          [103.6029168, 1.343715],
          [103.6030177, 1.3433502],
          [103.6028736, 1.342875],
          [103.6026768, 1.3421647],
          [103.6024559, 1.3416847],
          [103.601971, 1.3412047],
          [103.6012268, 1.3406911],
          [103.6008331, 1.3401727],
          [103.6001993, 1.3396063],
          [103.5994407, 1.3386943],
          [103.5988357, 1.3380799],
          [103.5981588, 1.3378735],
          [103.5979811, 1.3376719],
          [103.5978851, 1.3374607],
          [103.5979283, 1.3372783],
          [103.5980099, 1.3372783],
          [103.5981492, 1.3373503],
          [103.5982164, 1.3372399],
          [103.5980915, 1.3369711],
          [103.598394, 1.3361695],
          [103.5991238, 1.3350175],
          [103.6001657, 1.3335247],
          [103.6011212, 1.3323439],
          [103.602139, 1.3311151],
          [103.6023599, 1.3305583],
          [103.6030945, 1.3299727],
          [103.6051927, 1.3293151],
          [103.6055383, 1.3293007],
          [103.6058456, 1.3294207],
          [103.6059705, 1.3296127],
          [103.6062585, 1.3297855],
          [103.6065274, 1.3298911],
          [103.6067147, 1.3297615],
          [103.6067675, 1.3295023],
          [103.6067243, 1.3293151],
          [103.606537, 1.3291567],
          [103.6063162, 1.3290031],
          [103.6062729, 1.3285471],
          [103.6063402, 1.3281343],
          [103.6064458, 1.3279183],
          [103.6067147, 1.3277791],
          [103.607166, 1.3276543],
          [103.6072428, 1.3275391],
          [103.6071756, 1.3273759],
          [103.6070219, 1.3273375],
          [103.6065514, 1.3274815],
          [103.6060809, 1.3277935],
          [103.6056296, 1.3282063],
          [103.6052503, 1.3286287],
          [103.6049574, 1.3289407],
          [103.6041508, 1.3293007],
          [103.6029264, 1.3297855],
          [103.602139, 1.3303663],
          [103.6016925, 1.3309519],
          [103.6011452, 1.3317151],
          [103.6006314, 1.3323583],
          [103.6001657, 1.3328383],
          [103.59982, 1.3333039],
          [103.5991958, 1.3342927],
          [103.598394, 1.3355215],
          [103.5978995, 1.3363231],
          [103.5977362, 1.3368847],
          [103.5975547, 1.3371849],
          [103.5973165, 1.337287],
          [103.596982, 1.3371396],
          [103.5968119, 1.3368788],
          [103.5963129, 1.3364196],
          [103.5959432, 1.3360622],
          [103.595992, 1.3358752],
          [103.596114, 1.3357248],
          [103.5954872, 1.3349568],
          [103.594939, 1.3342779],
          [103.5946301, 1.3340666],
          [103.5941788, 1.3340341],
          [103.5937153, 1.3339284],
          [103.5934063, 1.3337414],
          [103.593134, 1.3333513],
          [103.5931124, 1.3330993],
          [103.5925186, 1.3327627],
          [103.5919582, 1.3322398],
          [103.5917816, 1.3318233],
          [103.5914632, 1.3310726],
          [103.5913138, 1.3308392],
          [103.5914258, 1.3303349],
          [103.5912577, 1.3301762],
          [103.5907814, 1.3300641],
          [103.5904078, 1.3301575],
          [103.5902677, 1.330391],
          [103.5899718, 1.3306402],
          [103.5899128, 1.3306898],
          [103.5891282, 1.3309699],
          [103.588409, 1.3315488],
          [103.5878953, 1.3319783],
          [103.5879327, 1.3322585],
          [103.587443, 1.3326694],
          [103.5867282, 1.333113],
          [103.5861736, 1.3334949],
          [103.5860134, 1.3339508],
          [103.5860134, 1.3343574],
          [103.5856437, 1.334382],
          [103.585656, 1.3341233],
          [103.5853849, 1.3337536],
          [103.5854835, 1.3335688],
          [103.5854219, 1.3331253],
          [103.5851877, 1.3327433],
          [103.5849166, 1.3326817],
          [103.5846701, 1.3324353],
          [103.5842634, 1.3322135],
          [103.5835979, 1.3321519],
          [103.5832775, 1.3321396],
          [103.5823531, 1.3325955],
          [103.581589, 1.3332362],
          [103.5808496, 1.3342465],
          [103.5807633, 1.3345668],
          [103.5810345, 1.334382],
          [103.58117, 1.3345915],
          [103.5810961, 1.335035],
          [103.5807387, 1.335417],
          [103.5806771, 1.3359098],
          [103.5805168, 1.3366614],
          [103.5800362, 1.3372528],
          [103.5792351, 1.3375361],
          [103.5780027, 1.3376224],
          [103.5775344, 1.3374376],
          [103.5772016, 1.3372281],
          [103.5767949, 1.3372035],
          [103.5765238, 1.3370803],
          [103.5761171, 1.3371665],
          [103.5758213, 1.3375361],
          [103.5756858, 1.338029],
          [103.5758706, 1.3383124],
          [103.575772, 1.3387682],
          [103.5757227, 1.3392364],
          [103.5758706, 1.3394705],
          [103.5758089, 1.3421711],
          [103.577982, 1.3421176],
          [103.5789601, 1.3411687],
          [103.579383, 1.3408997],
          [103.5801225, 1.3408874],
          [103.5805292, 1.3406903],
          [103.5810468, 1.3406287],
          [103.5815151, 1.3404192],
          [103.5816137, 1.3402344],
          [103.5822183, 1.3401119],
          [103.5831782, 1.3394337],
          [103.5834089, 1.3393961],
          [103.5835269, 1.3395141],
          [103.5837898, 1.3395731],
          [103.5837683, 1.3396857],
          [103.5835055, 1.3399056],
          [103.5833553, 1.3404044],
          [103.5833767, 1.340753],
          [103.5831192, 1.3412517],
          [103.5831514, 1.3416325],
          [103.583366, 1.3421259],
          [103.5834947, 1.3424262],
          [103.5838059, 1.3424798],
          [103.5840312, 1.3424369],
          [103.584573, 1.3425656],
          [103.5850558, 1.3426461],
          [103.5853455, 1.3425764],
          [103.5856673, 1.3424155],
          [103.5862842, 1.3423994],
          [103.5864076, 1.3430161],
          [103.5864367, 1.3431892],
          [103.5865095, 1.3436221],
          [103.5866222, 1.3439868],
          [103.5867992, 1.3440941],
          [103.5869655, 1.3442711],
          [103.5872641, 1.3443664],
          [103.5874442, 1.3445366],
          [103.5874669, 1.3451154],
          [103.5876485, 1.3454149],
          [103.5875093, 1.345479],
          [103.5873972, 1.3455899],
          [103.5873274, 1.3456611],
          [103.5872649, 1.3457431],
          [103.5872456, 1.345779],
          [103.5872175, 1.3457929],
          [103.5871779, 1.3457797],
          [103.5871271, 1.3457684],
          [103.587096, 1.3457329],
          [103.5870949, 1.3456941],
          [103.5870476, 1.3456312],
          [103.5869795, 1.3456195],
          [103.5867143, 1.3454341],
          [103.5863659, 1.3453997],
          [103.5860903, 1.3454818],
          [103.5854663, 1.3453402],
          [103.5850302, 1.3452021],
          [103.5848905, 1.3451191],
          [103.5847421, 1.3451363],
          [103.5846183, 1.3453087],
          [103.5844445, 1.3454504],
          [103.5841844, 1.345457],
          [103.5840925, 1.3454423],
          [103.5839973, 1.3453918],
          [103.5839715, 1.3452773],
          [103.5838647, 1.3452541],
          [103.5835019, 1.3453017],
          [103.5832871, 1.3453901],
          [103.5831806, 1.3453703],
          [103.5830424, 1.3454305],
          [103.582866, 1.3455344],
          [103.582494, 1.3455426],
          [103.5821053, 1.3456007],
          [103.5815245, 1.3458081],
          [103.581075, 1.3458172],
          [103.5806084, 1.3458446],
          [103.580144, 1.3460211],
          [103.5796931, 1.3463103],
          [103.5791916, 1.3464654],
          [103.5785307, 1.3465066],
          [103.5777957, 1.3465402],
          [103.5768145, 1.3466075],
          [103.5760635, 1.3466989],
          [103.5753488, 1.3468023],
          [103.5747408, 1.3467667],
          [103.5745899, 1.3467156],
          [103.5743543, 1.3467372],
          [103.5739315, 1.346865],
          [103.5735227, 1.347037],
          [103.5732196, 1.3470316],
          [103.57288, 1.347075],
          [103.5727384, 1.3472352],
          [103.5724552, 1.347409],
          [103.5721577, 1.3473655],
          [103.5719783, 1.3474496],
          [103.5717172, 1.3477889],
          [103.5712683, 1.3480765],
          [103.571137, 1.3481732],
          [103.5709516, 1.3481616],
          [103.570884, 1.3480409],
          [103.570702, 1.3479456],
          [103.570553844794631, 1.347903996949631],
          [103.5704691, 1.3478802],
          [103.570269, 1.3479269],
          [103.5702198, 1.3480101],
          [103.570168, 1.3480593],
          [103.5700864, 1.3480787],
          [103.569965, 1.3480576],
          [103.5698896, 1.3480036],
          [103.5697534, 1.3479784],
          [103.5696464, 1.3479091],
          [103.569578, 1.3478953],
          [103.5695766, 1.347987],
          [103.5695551, 1.3481076],
          [103.5694935, 1.3482306],
          [103.5694537, 1.3482025],
          [103.5694129, 1.3481406],
          [103.5693657, 1.3481599],
          [103.5693297, 1.3481904],
          [103.5692645, 1.3482879],
          [103.5685911, 1.3484739],
          [103.5685386, 1.3485308],
          [103.568424, 1.3485669],
          [103.568307, 1.3485618],
          [103.5681918, 1.348593],
          [103.5681008, 1.3485417],
          [103.5680481, 1.3485784],
          [103.5680151, 1.3487088],
          [103.5680111, 1.3487991],
          [103.5678738, 1.348861],
          [103.567718, 1.3488089],
          [103.5675357, 1.3487514],
          [103.5671656, 1.3484833],
          [103.5664183, 1.3480021],
          [103.5660007, 1.3475971],
          [103.5658279, 1.347377],
          [103.5654937, 1.3472376],
          [103.5650923, 1.3472003],
          [103.5649135, 1.3469711],
          [103.5645933, 1.3468631],
          [103.5640942, 1.3464854],
          [103.5636637, 1.3463399],
          [103.5633384, 1.3461943],
          [103.5630666, 1.34606],
          [103.5629614, 1.3460521],
          [103.5628562, 1.3460704],
          [103.5627381, 1.346173],
          [103.5626287, 1.3463019],
          [103.5625476, 1.3464016],
          [103.5625366, 1.3464926],
          [103.5624357, 1.3465869],
          [103.562234, 1.3468719],
          [103.5620811, 1.3472809],
          [103.561937, 1.3477074],
          [103.561806, 1.347683],
          [103.5617066, 1.3478421],
          [103.5617212, 1.3479399],
          [103.5616711, 1.3480077],
          [103.561551, 1.3480667],
          [103.561323, 1.3481742],
          [103.561207, 1.3482781],
          [103.5611172, 1.3484084],
          [103.5604775, 1.3499256],
          [103.5602539, 1.3500332],
          [103.5598901, 1.3501145],
          [103.559294, 1.3501632],
          [103.5592376, 1.3499856],
          [103.5591655, 1.349853],
          [103.5590467, 1.3498724],
          [103.558963, 1.3499532],
          [103.5588574, 1.3498762],
          [103.5584935, 1.3495749],
          [103.5582303, 1.3492561],
          [103.5583759, 1.3486484],
          [103.5583769, 1.3481721],
          [103.5582245, 1.34765],
          [103.5579539, 1.3472943],
          [103.5577753, 1.347044],
          [103.5579244, 1.3468512],
          [103.5580666, 1.3467389],
          [103.5581059, 1.3465951],
          [103.5580897, 1.346368],
          [103.5581652, 1.3460952],
          [103.5582268, 1.3458394],
          [103.5583373, 1.3458031],
          [103.5584284, 1.3458048],
          [103.5585018, 1.3457619],
          [103.5585902, 1.3456051],
          [103.5586703, 1.3453834],
          [103.5587103, 1.3451929],
          [103.5586628, 1.3450343],
          [103.5585312, 1.3448037],
          [103.5584747, 1.3446554],
          [103.5584898, 1.3445151],
          [103.5586792, 1.344123],
          [103.5588209, 1.3437667],
          [103.5590343, 1.3433189],
          [103.5592694, 1.3428544],
          [103.5593272, 1.3424669],
          [103.559368, 1.3422652],
          [103.559476, 1.3421445],
          [103.5595778, 1.3421637],
          [103.5596964, 1.3421574],
          [103.5597995, 1.3420707],
          [103.5599625, 1.3417407],
          [103.5600778, 1.3412555],
          [103.5602264, 1.3408462],
          [103.5602555, 1.3405165],
          [103.5603869, 1.3401818],
          [103.5605651, 1.3400111],
          [103.5607342, 1.339693],
          [103.5609085, 1.3389397],
          [103.5609526, 1.3385334],
          [103.561111, 1.3385401],
          [103.561381, 1.3382961],
          [103.5613826, 1.3381123],
          [103.5614325, 1.3375575],
          [103.5616727, 1.3367991],
          [103.5619148, 1.3359808],
          [103.5622738, 1.3351625],
          [103.5623476, 1.3349248],
          [103.5623864, 1.3352253],
          [103.5623186, 1.3357253],
          [103.5622349, 1.3359899],
          [103.5621861, 1.3361103],
          [103.5621382, 1.3361947],
          [103.5621887, 1.3362307],
          [103.5622764, 1.3362109],
          [103.5623978, 1.3360012],
          [103.5624925, 1.3356466],
          [103.5625237, 1.3352887],
          [103.5625431, 1.3349303],
          [103.5625792, 1.3342392],
          [103.5626637, 1.3339378],
          [103.5627778, 1.3337706],
          [103.5629675, 1.3338196],
          [103.5630237, 1.3338773],
          [103.5630629, 1.3339615],
          [103.5631378, 1.3338322],
          [103.5631531, 1.3336293],
          [103.5631573, 1.3333932],
          [103.5633467, 1.3329177],
          [103.5633577, 1.3326302],
          [103.5632097, 1.3324581],
          [103.5590056, 1.3307442],
          [103.5587678, 1.3306897],
          [103.5586178, 1.3305814],
          [103.5584927, 1.3305604],
          [103.5584284, 1.3306632],
          [103.558113, 1.3305697],
          [103.5578248, 1.3305945],
          [103.5571347, 1.3306331],
          [103.5561809, 1.3306865],
          [103.5542926, 1.331373],
          [103.5524901, 1.332231],
          [103.5519066, 1.3324644],
          [103.5503444, 1.3330891],
          [103.5479111, 1.3334581],
          [103.5464662, 1.333035],
          [103.5460916, 1.3328386],
          [103.545804, 1.3325553],
          [103.5455273, 1.3323455],
          [103.5452672, 1.3322651],
          [103.5444462, 1.3317365],
          [103.5433468, 1.3310079],
          [103.5426056, 1.3303814],
          [103.5418034, 1.3297984],
          [103.5403892, 1.329056],
          [103.5393231, 1.3284527],
          [103.5377826, 1.3271245],
          [103.5365984, 1.3269414],
          [103.5359038, 1.3265471],
          [103.5353599, 1.3262803],
          [103.5347011, 1.3259322],
          [103.5331865, 1.3248535],
          [103.5308072, 1.3233976],
          [103.5301045, 1.3229676],
          [103.5303315, 1.3221033],
          [103.5294769, 1.3214435],
          [103.528389, 1.3218494],
          [103.5273445, 1.3212016],
          [103.5271413, 1.3209026],
          [103.5247964, 1.319117],
          [103.5250228, 1.3177001],
          [103.5232461, 1.3171488],
          [103.5221357, 1.3156868],
          [103.521551, 1.314285],
          [103.520344, 1.3137937],
          [103.5192142, 1.312867],
          [103.5191044, 1.3121201],
          [103.5184847, 1.3114769],
          [103.5187486, 1.3103523],
          [103.5177218, 1.309632],
          [103.517717, 1.3089021],
          [103.5146271, 1.3056006],
          [103.5148261, 1.3047935],
          [103.5143825, 1.3044781],
          [103.5141819, 1.303146],
          [103.5116845, 1.297602],
          [103.5114133, 1.2956044],
          [103.5113134, 1.294549],
          [103.5109424, 1.291053],
          [103.5106621, 1.2886609],
          [103.5102581, 1.2852272],
          [103.5101043, 1.2839998],
          [103.5100831, 1.2838301],
          [103.51009, 1.2835359],
          [103.5101523, 1.2830356],
          [103.510108, 1.2827133],
          [103.5101063, 1.2826933],
          [103.510109, 1.2824629],
          [103.5101094, 1.2824334],
          [103.5101095, 1.2824215],
          [103.5101498, 1.2813685],
          [103.5103081, 1.2788582],
          [103.5103529, 1.2781015],
          [103.5106417, 1.2776285],
          [103.51105, 1.2770859],
          [103.5111745, 1.2767324],
          [103.5111247, 1.2761898],
          [103.5111098, 1.2757666],
          [103.5111944, 1.2753335],
          [103.5110799, 1.2748456],
          [103.5109604, 1.274298],
          [103.510821, 1.2737056],
          [103.5107861, 1.2730385],
          [103.5106417, 1.2724062],
          [103.5106407, 1.2718889],
          [103.510618, 1.2698191],
          [103.5106603, 1.2695064],
          [103.5108047, 1.2686019],
          [103.5109052, 1.2681908],
          [103.5113301, 1.2674195],
          [103.5114537, 1.2671413],
          [103.5114575, 1.2671327],
          [103.511462, 1.2671229],
          [103.5117459, 1.2664985],
          [103.5117605, 1.2664091],
          [103.5117443, 1.2663939],
          [103.5117325, 1.2663829],
          [103.5116993, 1.2662684],
          [103.5113333, 1.2660211],
          [103.5108226, 1.2659043],
          [103.5101382, 1.2659287],
          [103.5097839, 1.266205],
          [103.5095869, 1.2662656],
          [103.5091087, 1.2661834],
          [103.508836, 1.2663911],
          [103.5083578, 1.2665187],
          [103.5082257, 1.2666204],
          [103.5081089, 1.2668108],
          [103.5078107, 1.2669367],
          [103.507797, 1.2669452],
          [103.5077838, 1.2669569],
          [103.5072781, 1.2672184],
          [103.5070054, 1.2675127],
          [103.5067241, 1.2677766],
          [103.50543, 1.2690271],
          [103.5049193, 1.2696805],
          [103.5028981, 1.271593],
          [103.5008438, 1.2733807],
          [103.5001368, 1.2737955],
          [103.4985397, 1.2753099],
          [103.4968154, 1.2764056],
          [103.4933876, 1.2797796],
          [103.4890898, 1.2849914],
          [103.4843596, 1.2909915],
          [103.484831, 1.2918486],
          [103.4854366, 1.292549],
          [103.4852961, 1.2927328],
          [103.4851942, 1.2928261],
          [103.4851554, 1.2928307],
          [103.4850449, 1.2927494],
          [103.4850947, 1.2926987],
          [103.4849155, 1.292523],
          [103.4848471, 1.2925928],
          [103.4845869, 1.2923335],
          [103.4845697, 1.2923526],
          [103.4851527, 1.292937],
          [103.4851058, 1.2929794],
          [103.485082, 1.2929794],
          [103.4850733, 1.2929859],
          [103.4850647, 1.2930032],
          [103.4850712, 1.2930276],
          [103.4850272, 1.2930923],
          [103.4850004, 1.2931007],
          [103.4849339, 1.293051],
          [103.4848987, 1.2930204],
          [103.4849163, 1.2929967],
          [103.4844679, 1.2925875],
          [103.4844381, 1.2926196],
          [103.4849339, 1.2930717],
          [103.4849132, 1.2931306],
          [103.484904, 1.2931948],
          [103.4848765, 1.2932652],
          [103.4848102, 1.2933247],
          [103.4846844, 1.2933233],
          [103.4844289, 1.2931872],
          [103.4843233, 1.2931107],
          [103.4842483, 1.2928139],
          [103.4842422, 1.2926425],
          [103.483927, 1.2923534],
          [103.4833514, 1.2918668],
          [103.4775431, 1.2993308],
          [103.4733134, 1.3037804],
          [103.4655025, 1.3107677],
          [103.4663158, 1.3119335],
          [103.4661537, 1.3120922],
          [103.4661894, 1.3121409],
          [103.4661767, 1.3121521],
          [103.4661656, 1.312162],
          [103.4661196, 1.312129],
          [103.4659529, 1.3120662],
          [103.4653795, 1.3113826],
          [103.4651, 1.311128],
          [103.460538, 1.3150229],
          [103.457792, 1.3168629],
          [103.454321, 1.3189711],
          [103.4543255, 1.3190841],
          [103.4546772, 1.3195059],
          [103.454503, 1.319612],
          [103.4545208, 1.3196417],
          [103.4545073, 1.3196536],
          [103.4544932, 1.319666],
          [103.4544619, 1.3196428],
          [103.454353, 1.3197213],
          [103.4542229, 1.3197155],
          [103.4540692, 1.3196192],
          [103.453868, 1.319471],
          [103.4537403, 1.3193228],
          [103.4536817, 1.3190743],
          [103.4534956, 1.3189488],
          [103.4499673, 1.320806],
          [103.4469029, 1.321587],
          [103.4454604, 1.3238934],
          [103.4451574, 1.3240678],
          [103.4444994, 1.3239148],
          [103.4436164, 1.3237069],
          [103.4434716, 1.324198],
          [103.4435755, 1.32425],
          [103.4433916, 1.3246762],
          [103.443054, 1.3250158],
          [103.4429544, 1.3250526],
          [103.4426629, 1.3249382],
          [103.4426381, 1.3249284],
          [103.4425711, 1.3251183],
          [103.4419503, 1.3248947],
          [103.4418703, 1.3251456],
          [103.4415132, 1.3249942],
          [103.44151, 1.3250041],
          [103.4414571, 1.3251853],
          [103.4427553, 1.3256519],
          [103.4434954, 1.3259375],
          [103.4433808, 1.3262101],
          [103.4437941, 1.3263788],
          [103.44374, 1.3265389],
          [103.4439672, 1.3266817],
          [103.4443048, 1.3267574],
          [103.4441641, 1.3272161],
          [103.4441122, 1.3279257],
          [103.4439622, 1.3306413],
          [103.4438374, 1.3316187],
          [103.4437844, 1.3318686],
          [103.4436859, 1.3323326],
          [103.4430184, 1.334209],
          [103.442274, 1.3366118],
          [103.4416538, 1.3387083],
          [103.4416041, 1.339141],
          [103.4416668, 1.3392665],
          [103.4416029, 1.3395508],
          [103.4415711, 1.3396923],
          [103.4415593, 1.3397321],
          [103.4413812, 1.3403309],
          [103.4413295, 1.340389],
          [103.4412001, 1.3404626],
          [103.4409837, 1.3405621],
          [103.4405379, 1.3412306],
          [103.440426, 1.341348],
          [103.44031, 1.3414148],
          [103.4398188, 1.3423112],
          [103.4383435, 1.3444519],
          [103.4383297, 1.3446463],
          [103.4368475, 1.3467909],
          [103.4367267, 1.3469956],
          [103.4366474, 1.3472814],
          [103.4349864, 1.3580103],
          [103.4305318, 1.3654905],
          [103.4261166, 1.3733396],
          [103.4255943, 1.3742681],
          [103.4254726, 1.3745333],
          [103.4234108, 1.3790247],
          [103.4223929, 1.3812423],
          [103.4199961, 1.3865944],
          [103.4186267, 1.3915042],
          [103.4151982, 1.4037962],
          [103.4148013, 1.407427],
          [103.4147738, 1.4076785],
          [103.4142326, 1.4126298],
          [103.4130696, 1.4165542],
          [103.4114506, 1.4229622],
          [103.4107424, 1.4274443],
          [103.4108628, 1.4278152],
          [103.4108057, 1.4282783],
          [103.4108892, 1.4283273],
          [103.4109687, 1.4283],
          [103.4111476, 1.42799],
          [103.4112995, 1.4280181],
          [103.411161, 1.428363],
          [103.4112073, 1.4285385],
          [103.4113113, 1.4286094],
          [103.4114095, 1.4288289],
          [103.4115615, 1.4289964],
          [103.4112503, 1.4295969],
          [103.4112261, 1.4297014],
          [103.4111442, 1.429826],
          [103.4110336, 1.4298079],
          [103.4110319, 1.4296407],
          [103.410939, 1.4296182],
          [103.4107914, 1.4300095],
          [103.4100912, 1.4308755],
          [103.4085747, 1.4365729],
          [103.4052101, 1.4429819],
          [103.4057903, 1.4435913],
          [103.4049108, 1.4435519],
          [103.4039801, 1.4458219],
          [103.4041314, 1.4463679],
          [103.4036496, 1.44847],
          [103.4024132, 1.4491039],
          [103.4016396, 1.4503245],
          [103.4010297, 1.4536021],
          [103.4001295, 1.4553037],
          [103.399719, 1.4554862],
          [103.3996113, 1.4559655],
          [103.3997709, 1.4561783],
          [103.3992471, 1.4589723],
          [103.3984291, 1.4590689],
          [103.3983196, 1.4594577],
          [103.3989395, 1.4595538],
          [103.3992104, 1.4597181],
          [103.3986369, 1.4598192],
          [103.3981344, 1.4608105],
          [103.3966398, 1.463356],
          [103.3959882, 1.4641466],
          [103.3964338, 1.4649245],
          [103.3962789, 1.4654343],
          [103.3955353, 1.4653572],
          [103.3941916, 1.4675011],
          [103.393027, 1.4705388],
          [103.3926228, 1.4713607],
          [103.3924146, 1.4717842],
          [103.3922806, 1.4720566],
          [103.3921106, 1.4724023],
          [103.3919359, 1.4727576],
          [103.3920022, 1.4741624],
          [103.3915376, 1.4742739],
          [103.3911974, 1.4757227],
          [103.3906144, 1.477226],
          [103.3901662, 1.4772182],
          [103.3900892, 1.4773853],
          [103.3880434, 1.4770658],
          [103.3876806, 1.4770091],
          [103.3868319, 1.4793877],
          [103.385494653135538, 1.479831235416095],
          [103.3845161, 1.4801558],
          [103.3841468, 1.4802782],
          [103.383646, 1.4804443],
          [103.3834953, 1.4804943],
          [103.3832435, 1.4806916],
          [103.3830044, 1.4809723],
          [103.3827883, 1.4813004],
          [103.3826642, 1.4816199],
          [103.3826422, 1.4817086],
          [103.382586, 1.4819352],
          [103.3825808, 1.4821168],
          [103.3825777, 1.4822272],
          [103.3825905, 1.4825319],
          [103.3825808, 1.4827379],
          [103.3826323, 1.4829198],
          [103.3826403, 1.4829481],
          [103.3827828, 1.4831069],
          [103.3828881, 1.4831173],
          [103.3829344, 1.4831219],
          [103.3829859, 1.483063],
          [103.3830264, 1.4830168],
          [103.3830367, 1.4829732],
          [103.3830616, 1.4828677],
          [103.3830331, 1.4828017],
          [103.3829955, 1.4827146],
          [103.3829484, 1.482578],
          [103.3829186, 1.4824914],
          [103.3828412, 1.4822194],
          [103.3828271, 1.4821304],
          [103.3828043, 1.4819863],
          [103.3828485, 1.4817133],
          [103.3829452, 1.4814713],
          [103.3830121, 1.4813281],
          [103.3830843, 1.4812101],
          [103.383178, 1.4810572],
          [103.3832362, 1.4809936],
          [103.3834185, 1.4807943],
          [103.3837548, 1.4805747],
          [103.3838459, 1.4805153],
          [103.3843821, 1.4803619],
          [103.3843997, 1.4803788],
          [103.3845598, 1.4805329],
          [103.384754, 1.482576],
          [103.3850885, 1.4833164],
          [103.3852717, 1.48344],
          [103.3853868, 1.4834121],
          [103.385494653135538, 1.483395048240577],
          [103.3857429, 1.4833558],
          [103.3861539, 1.4837135],
          [103.3872163, 1.4839744],
          [103.3878979, 1.4840582],
          [103.3880513, 1.4840862],
          [103.388085, 1.484378],
          [103.3886015, 1.4841871],
          [103.3885605, 1.4843343],
          [103.3883729, 1.4848184],
          [103.3883136, 1.4849713],
          [103.3876283, 1.4848377],
          [103.3874317, 1.4850201],
          [103.3871793, 1.4850008],
          [103.3860418, 1.4882425],
          [103.3855348, 1.4896684],
          [103.385494653135538, 1.489667981803495],
          [103.3853908, 1.4896669],
          [103.3848761, 1.4896614],
          [103.3839447, 1.4921747],
          [103.3838339, 1.4924738],
          [103.3837875, 1.4925991],
          [103.3837116, 1.4928038],
          [103.3827406, 1.4954241],
          [103.3817649, 1.498057],
          [103.3808119, 1.5002251],
          [103.3804575, 1.5002857],
          [103.3802499, 1.5017716],
          [103.3797662, 1.5036931],
          [103.3797994, 1.5042479],
          [103.3792659, 1.5060181],
          [103.3789812, 1.5069764],
          [103.3786793, 1.5078014],
          [103.3784857, 1.5080481],
          [103.3786546, 1.5083012],
          [103.3789429, 1.5080572],
          [103.3792972, 1.5079104],
          [103.3795794, 1.5080162],
          [103.3794503, 1.5083517],
          [103.3793363, 1.5087144],
          [103.3792436, 1.5092451],
          [103.3790078, 1.5101046],
          [103.3781503, 1.5114984],
          [103.3773637, 1.5125773],
          [103.3763153, 1.5133727],
          [103.3746934, 1.5160244],
          [103.3718592, 1.5210419],
          [103.370058, 1.5240993],
          [103.3687507, 1.5268824],
          [103.3680848, 1.5277657],
          [103.367675, 1.5286489],
          [103.3660505, 1.5301959],
          [103.3635699, 1.533674],
          [103.3596339, 1.5379329],
          [103.3562893, 1.5408963],
          [103.3472827, 1.5438999],
          [103.3411231, 1.5483476],
          [103.336784, 1.5496449],
          [103.3315928, 1.5510563],
          [103.3279625, 1.5540421],
          [103.3244883, 1.5564743],
          [103.3211949, 1.5593676],
          [103.3206482, 1.562883],
          [103.3199382, 1.5630398],
          [103.3165231, 1.5614873],
          [103.310138, 1.564647],
          [103.3057998, 1.5662689],
          [103.3011004, 1.5682436],
          [103.2958412, 1.5699245],
          [103.2957167, 1.5709991],
          [103.2919283, 1.5758527],
          [103.2904574, 1.5794193],
          [103.2865482, 1.5794442],
          [103.2827249, 1.5824291],
          [103.2775931, 1.5877513],
          [103.2738802, 1.5890063],
          [103.2724782, 1.5905379],
          [103.271086, 1.5917509],
          [103.2670187, 1.591064],
          [103.2477281, 1.5977232],
          [103.1979878, 1.6187175],
          [103.1958288, 1.6190404],
          [103.1672666, 1.6329175],
          [103.1547398, 1.6406282],
          [103.1466609, 1.6455779],
          [103.1412514, 1.6500916],
          [103.1385158, 1.6536498],
          [103.1332843, 1.6560461],
          [103.1178451, 1.6707847],
          [103.1131749, 1.67327],
          [103.1081248, 1.678101],
          [103.1024291, 1.6807179],
          [103.0881546, 1.6930164],
          [103.0751711, 1.7026924],
          [103.0699414, 1.7054054],
          [103.0652258, 1.707733],
          [103.0604626, 1.7124175],
          [103.0571963, 1.71314],
          [103.0282755, 1.72746],
          [103.015376269817352, 1.733293533965061],
          [103.0142442, 1.7338055],
          [103.0127718, 1.7340237],
          [103.0125825, 1.7335524],
          [103.0056411, 1.7362036],
          [103.0061172, 1.7372417],
          [103.0010916, 1.7394213],
          [103.0005079, 1.7394954],
          [102.9995604, 1.7393487],
          [102.99949982860376, 1.739392183594086],
          [102.9990808, 1.739693],
          [102.9950061, 1.7393362],
          [102.993849, 1.7390838],
          [102.9937347, 1.73827],
          [102.9939403, 1.7375728],
          [102.9934897, 1.7366246],
          [102.9932292, 1.7366519],
          [102.9931655, 1.7370861],
          [102.9927961, 1.7374702],
          [102.9927001, 1.7396632],
          [102.9920211, 1.7401891],
          [102.9912097, 1.740493],
          [102.9898, 1.7408313],
          [102.9900851, 1.7404395],
          [102.9900178, 1.7401156],
          [102.9892412, 1.7393661],
          [102.9888507, 1.7394015],
          [102.9887374, 1.7399821],
          [102.9887986, 1.7405364],
          [102.9854512, 1.741875],
          [102.9828543, 1.7424766],
          [102.9574274, 1.7440038],
          [102.9539186, 1.7421412],
          [102.9421358, 1.7406237],
          [102.9371259, 1.7458543],
          [102.9282656, 1.7520147],
          [102.9197216, 1.7579735],
          [102.9130241, 1.7629815],
          [102.91253, 1.7637766],
          [102.911515, 1.7642096],
          [102.9097793, 1.7654353],
          [102.9057963, 1.7691114],
          [102.9026845, 1.7724915],
          [102.8989012, 1.7771165],
          [102.8961117, 1.7802666],
          [102.8944239, 1.7822072],
          [102.893445, 1.7842646],
          [102.8920861, 1.7872943],
          [102.8918803, 1.7877485],
          [102.8914455, 1.7887647],
          [102.8907856, 1.7891911],
          [102.8905089, 1.7888175],
          [102.8898322, 1.7884255],
          [102.8894874, 1.7883082],
          [102.8891526, 1.7883513],
          [102.8885036, 1.7887112],
          [102.8885293, 1.7890415],
          [102.8884534, 1.7892491],
          [102.8877301, 1.7899757],
          [102.8869762, 1.79145],
          [102.8867791, 1.7914357],
          [102.8863635, 1.7921215],
          [102.8865427, 1.7928048],
          [102.8865993, 1.7930205],
          [102.8866873, 1.7931291],
          [102.8870876, 1.7932213],
          [102.8872598, 1.7934733],
          [102.8874092, 1.7942217],
          [102.8877529, 1.7945088],
          [102.8878123, 1.7950876],
          [102.887913, 1.795178],
          [102.8880272, 1.7951261],
          [102.8885418, 1.7951766],
          [102.8887052, 1.7954986],
          [102.8888573, 1.7954899],
          [102.8889183, 1.7958447],
          [102.8890267, 1.7959107],
          [102.8891095, 1.7958725],
          [102.889106, 1.7957906],
          [102.889292, 1.7958521],
          [102.8894383, 1.796258],
          [102.8896524, 1.7961833],
          [102.8900268, 1.7957973],
          [102.8900289, 1.7961573],
          [102.8904108, 1.7965456],
          [102.8914794, 1.7971274],
          [102.8920435, 1.7971422],
          [102.892069, 1.7974082],
          [102.8925905, 1.7975062],
          [102.8931436, 1.7974851],
          [102.8939134, 1.7990035],
          [102.895091, 1.8010213],
          [102.8952779, 1.804165],
          [102.8950458, 1.8043065],
          [102.8948773, 1.8043784],
          [102.8946277, 1.8046381],
          [102.8945223, 1.8048533],
          [102.8945584, 1.8051358],
          [102.8949678, 1.8053218],
          [102.8953657, 1.8056421],
          [102.8956312, 1.8064339],
          [102.8958823, 1.8091978],
          [102.8965439, 1.8103453],
          [102.8963428, 1.8105788],
          [102.8958723, 1.8108807],
          [102.895387, 1.8111019],
          [102.893686, 1.8194299],
          [102.8920797, 1.8197158],
          [102.8904997, 1.819997],
          [102.8883019, 1.8161366],
          [102.8854821, 1.815187],
          [102.8759073, 1.8215669],
          [102.8622933, 1.8315312],
          [102.8480603, 1.8419844],
          [102.8305968, 1.8517246],
          [102.8040983, 1.8609504],
          [102.7820237, 1.8657856],
          [102.7704175, 1.8671983],
          [102.7601748, 1.866586],
          [102.7463699, 1.8679544],
          [102.7436967, 1.8687235],
          [102.7435375, 1.8687693],
          [102.7434732, 1.868276],
          [102.7318002, 1.8667963],
          [102.7316071, 1.8659384],
          [102.7290321, 1.8655309],
          [102.7269722, 1.8648661],
          [102.7129633, 1.8539003],
          [102.7077688, 1.8535235],
          [102.6892437, 1.8681216],
          [102.6880516, 1.8690408],
          [102.6873107, 1.8694067],
          [102.6841259, 1.8725303],
          [102.6759739, 1.883384],
          [102.6757454, 1.8836883],
          [102.6746799, 1.8850865],
          [102.6725577, 1.8883965],
          [102.6721386, 1.8885316],
          [102.6716876, 1.8896525],
          [102.6716876, 1.8905717],
          [102.6709553, 1.8917037],
          [102.6704614, 1.8913632],
          [102.6702911, 1.8916186],
          [102.6708361, 1.891959],
          [102.670257, 1.8930824],
          [102.6696695, 1.8939846],
          [102.6688349, 1.8954825],
          [102.6685114, 1.8952952],
          [102.6688349, 1.8945548],
          [102.6686646, 1.8944612],
          [102.6650115, 1.8999677],
          [102.6620481, 1.904189],
          [102.6605409, 1.9065805],
          [102.6594679, 1.9088614],
          [102.6591869, 1.9090231],
          [102.6579181, 1.9104955],
          [102.6468836, 1.929554],
          [102.6343632, 1.9418938],
          [102.6323892, 1.9454688],
          [102.6324619, 1.9468675],
          [102.6327437, 1.9477421],
          [102.632914, 1.9477761],
          [102.6331933, 1.9492016],
          [102.6329481, 1.949691],
          [102.6325393, 1.9499974],
          [102.63179, 1.9503719],
          [102.6305297, 1.9494187],
          [102.6302402, 1.9493251],
          [102.6292183, 1.94844],
          [102.6283923, 1.9481251],
          [102.6275227, 1.9491763],
          [102.6195227, 1.9574467],
          [102.6194172, 1.9586256],
          [102.6193955, 1.9588684],
          [102.6171896, 1.959571],
          [102.6158242, 1.9611229],
          [102.602494, 1.9762742],
          [102.5928068, 1.9853705],
          [102.5827116, 1.9948499],
          [102.5800357, 1.9973625],
          [102.5778548, 1.9991329],
          [102.5777205, 1.9992419],
          [102.5776463, 1.9993021],
          [102.577402516266304, 1.9995],
          [102.57199, 2.0038938],
          [102.5718109, 2.0040392],
          [102.5715941, 2.0042152],
          [102.5706392, 2.004258],
          [102.5699794, 2.004628],
          [102.569502, 2.0050354],
          [102.5691158, 2.0053249],
          [102.5686759, 2.0058449],
          [102.568177, 2.0063435],
          [102.5680804, 2.0067242],
          [102.5676352, 2.0073407],
          [102.567029, 2.0080055],
          [102.5669163, 2.0083003],
          [102.5665569, 2.0083218],
          [102.5618937, 2.0119334],
          [102.560906, 2.0133112],
          [102.5600625, 2.0134787],
          [102.5579427, 2.0150893],
          [102.5566083, 2.0168017],
          [102.5554985, 2.017376],
          [102.5546463, 2.0183791],
          [102.5543533, 2.0193645],
          [102.5523324, 2.0200136],
          [102.5496724, 2.0226873],
          [102.5491875, 2.0240614],
          [102.547536, 2.0246765],
          [102.5464056, 2.0267749],
          [102.5458209, 2.0286017],
          [102.5453512, 2.0292928],
          [102.5449405, 2.0294946],
          [102.5441214, 2.0307883],
          [102.5431289, 2.0344321],
          [102.5432334, 2.0363974],
          [102.5445058, 2.0401401],
          [102.5464422, 2.0429407],
          [102.5433506, 2.0506295],
          [102.5403449, 2.0581046],
          [102.5360066, 2.0630298],
          [102.5286506, 2.0719387],
          [102.5216712, 2.0783647],
          [102.509153, 2.0872428],
          [102.4970884, 2.0934866],
          [102.4939324, 2.0941536],
          [102.4911748, 2.0958692],
          [102.4900529, 2.0978549],
          [102.4893989, 2.0990036],
          [102.4895567, 2.0998642],
          [102.489453, 2.1009488],
          [102.4892976, 2.1023747],
          [102.4882988, 2.1047943],
          [102.4882961, 2.1052213],
          [102.4883415, 2.1053804],
          [102.488456, 2.1057823],
          [102.4887282, 2.1062671],
          [102.4887429, 2.1063296],
          [102.4888459, 2.1067681],
          [102.4888212, 2.1095374],
          [102.488753, 2.1119351],
          [102.488689, 2.1129476],
          [102.4887357, 2.1136155],
          [102.4889465, 2.1145202],
          [102.4893112, 2.1161934],
          [102.4895983, 2.1178862],
          [102.4898113, 2.1185121],
          [102.4901505, 2.118941],
          [102.4910588, 2.1197233],
          [102.4914824, 2.1198573],
          [102.4917326, 2.1198118],
          [102.4921982, 2.1195144],
          [102.4925534, 2.1195255],
          [102.492807, 2.1196503],
          [102.493054, 2.1199081],
          [102.4932228, 2.1199688],
          [102.493479, 2.1199356],
          [102.4936569, 2.1197273],
          [102.4938633, 2.1190802],
          [102.4940006, 2.1189712],
          [102.4941542, 2.1189231],
          [102.4943394, 2.1189326],
          [102.4947505, 2.1191709],
          [102.4949621, 2.1192224],
          [102.4951535, 2.1192089],
          [102.4953248, 2.119117],
          [102.4955617, 2.1188762],
          [102.4957103, 2.118809],
          [102.4958693, 2.1187803],
          [102.495992, 2.1188365],
          [102.4960912, 2.1189816],
          [102.496192, 2.1194204],
          [102.496252, 2.1195708],
          [102.496356, 2.1196928],
          [102.496515, 2.1197743],
          [102.4967274, 2.1197993],
          [102.4969767, 2.119722],
          [102.4975593, 2.1194782],
          [102.4979766, 2.1193887],
          [102.4984203, 2.1193836],
          [102.4990876, 2.1194842],
          [102.4994546, 2.1195228],
          [102.4997333, 2.11947],
          [102.4999981, 2.1193297],
          [102.500332, 2.1190511],
          [102.5008777, 2.1184535],
          [102.5011725, 2.1183713],
          [102.5013632, 2.1184651],
          [102.5017451, 2.1188107],
          [102.502029, 2.1189251],
          [102.5027428, 2.1194881],
          [102.5029484, 2.1197423],
          [102.502996, 2.1199599],
          [102.5030396, 2.1209048],
          [102.5031267, 2.1212182],
          [102.5031678, 2.1218263],
          [102.5031171, 2.1220863],
          [102.5029773, 2.1224047],
          [102.5027458, 2.122663],
          [102.5022443, 2.1233451],
          [102.5016652, 2.1240862],
          [102.5013994, 2.1242028],
          [102.5011269, 2.1243186],
          [102.5010368, 2.1244398],
          [102.5009937, 2.1246942],
          [102.5010235, 2.1250665],
          [102.5010929, 2.1252218],
          [102.5016515, 2.1259055],
          [102.5020533, 2.1263062],
          [102.5021927, 2.1265164],
          [102.5024197, 2.1267015],
          [102.5027999, 2.1268187],
          [102.5033835, 2.1268989],
          [102.5043184, 2.1266497],
          [102.5048959, 2.1263617],
          [102.5053277, 2.1259747],
          [102.5059837, 2.125454],
          [102.5061619, 2.1250819],
          [102.5064008, 2.1231623],
          [102.5064919, 2.122008],
          [102.5066259, 2.1214733],
          [102.5067529, 2.1212569],
          [102.5070691, 2.1210158],
          [102.5073924, 2.120941],
          [102.508905, 2.1209812],
          [102.5098984, 2.1211466],
          [102.5139268, 2.1210915],
          [102.5155823, 2.1207606],
          [102.5164101, 2.1194544],
          [102.5153265, 2.117353],
          [102.5145755, 2.1158198],
          [102.5146291, 2.1154445],
          [102.5144467, 2.114694],
          [102.5145433, 2.1139006],
          [102.5148974, 2.113193],
          [102.5153158, 2.1129357],
          [102.5159917, 2.1130108],
          [102.5168929, 2.1137398],
          [102.517998, 2.1144689],
          [102.5188027, 2.1154338],
          [102.5200472, 2.1169027],
          [102.520618, 2.1179665],
          [102.524499, 2.1182912],
          [102.5261317, 2.1190112],
          [102.5265306, 2.1193651],
          [102.5275267, 2.1202489],
          [102.5276567, 2.1203933],
          [102.5296399, 2.1225948],
          [102.5307467, 2.1238233],
          [102.5320211, 2.1254694],
          [102.5328519, 2.1268061],
          [102.5355038, 2.1310729],
          [102.5364358, 2.1333247],
          [102.5368344, 2.1353314],
          [102.5372927, 2.1411358],
          [102.5369903, 2.1436294],
          [102.5362212, 2.1459071],
          [102.5357798, 2.1475063],
          [102.5339035, 2.1511459],
          [102.5321636, 2.1554259],
          [102.5313006, 2.1582751],
          [102.5306813, 2.1615623],
          [102.5300513, 2.1629103],
          [102.5283973, 2.1647867],
          [102.5266329, 2.1660336],
          [102.5256678, 2.1664724],
          [102.5226477, 2.1668598],
          [102.5204415, 2.1670139],
          [102.5158583, 2.1672483],
          [102.5123816, 2.1670277],
          [102.5092361, 2.1662005],
          [102.507084, 2.1664763],
          [102.505594, 2.167524],
          [102.5044903, 2.1687923],
          [102.5039385, 2.171329],
          [102.5029759, 2.1735868],
          [102.502444, 2.1742822],
          [102.5014692, 2.1748512],
          [102.500577, 2.1749854],
          [102.4990209, 2.1748996],
          [102.4974957, 2.1744431],
          [102.496599, 2.1745825],
          [102.4931775, 2.1760163],
          [102.4921606, 2.1764726],
          [102.4904735, 2.1772295],
          [102.4882907, 2.1775112],
          [102.4878907, 2.1775225],
          [102.4851451, 2.1776001],
          [102.4829685, 2.1783875],
          [102.4813958, 2.1803605],
          [102.4802093, 2.1826336],
          [102.4787745, 2.1862731],
          [102.4782993, 2.1880929],
          [102.4780005, 2.1895896],
          [102.4777862, 2.1903399],
          [102.4774626, 2.1910903],
          [102.4770012, 2.1919241],
          [102.4763805, 2.1931295],
          [102.4759293, 2.1941436],
          [102.4755958, 2.1948491],
          [102.4751067, 2.195624],
          [102.4747489, 2.1959945],
          [102.474602, 2.1961234],
          [102.4742645, 2.1964794],
          [102.4741255, 2.1967981],
          [102.4738694, 2.197195],
          [102.471159, 2.2009414],
          [102.4708831, 2.2025957],
          [102.4712142, 2.2034228],
          [102.4715453, 2.2040294],
          [102.4720972, 2.2052425],
          [102.4737527, 2.2060697],
          [102.4750219, 2.206566],
          [102.4760704, 2.207338],
          [102.4778915, 2.2079997],
          [102.4793159, 2.2084103],
          [102.4809184, 2.2089122],
          [102.482213, 2.2093134],
          [102.4831484, 2.2099665],
          [102.4834062, 2.2108189],
          [102.4838578, 2.2113342],
          [102.4845593, 2.2123857],
          [102.4860794, 2.2137878],
          [102.4874826, 2.2150731],
          [102.4885349, 2.2156573],
          [102.4899782, 2.2166849],
          [102.4907566, 2.2176436],
          [102.4915166, 2.2180526],
          [102.4927577, 2.2193005],
          [102.4941193, 2.2199649],
          [102.494966, 2.2205062],
          [102.495588, 2.2215233],
          [102.4959015, 2.2226678],
          [102.4965446, 2.2244788],
          [102.4974138, 2.2261413],
          [102.4969441, 2.2292532],
          [102.4966615, 2.230204],
          [102.4966388, 2.2317604],
          [102.4966938, 2.2332433],
          [102.4966938, 2.2348635],
          [102.4973259, 2.2362915],
          [102.4976546, 2.2372997],
          [102.4975177, 2.2376948],
          [102.494025, 2.2477723],
          [102.4931322, 2.2518464],
          [102.4930358, 2.2528146],
          [102.4927091, 2.2536247],
          [102.4926134, 2.2542833],
          [102.492653, 2.2549727],
          [102.4924109, 2.2565603],
          [102.4924501, 2.2568188],
          [102.4925049, 2.2572339],
          [102.4924966, 2.2577234],
          [102.4931219, 2.2588954],
          [102.4935475, 2.2596232],
          [102.4937556, 2.2601052],
          [102.4937989, 2.2608728],
          [102.4941314, 2.2626079],
          [102.4942607, 2.263494],
          [102.4948614, 2.2651245],
          [102.4955186, 2.2666099],
          [102.4962379, 2.2676522],
          [102.4966139, 2.268019],
          [102.4969441, 2.2684333],
          [102.497179, 2.2688334],
          [102.4973388, 2.2692549],
          [102.4973742, 2.2696223],
          [102.4974182, 2.2704507],
          [102.4973764, 2.2713268],
          [102.4973189, 2.2717138],
          [102.4973562, 2.2724845],
          [102.4974396, 2.2729745],
          [102.4975383, 2.273286],
          [102.4977334, 2.2739953],
          [102.4983018, 2.2762529],
          [102.4991152, 2.2790483],
          [102.500066, 2.2821011],
          [102.5005533, 2.2837291],
          [102.5009433, 2.284794],
          [102.5013622, 2.2857723],
          [102.5015706, 2.2861912],
          [102.5018382, 2.2870353],
          [102.5019067, 2.2873593],
          [102.5019035, 2.28782],
          [102.5020078, 2.2885233],
          [102.5021908, 2.2890655],
          [102.5023568, 2.2893749],
          [102.5025736, 2.2895638],
          [102.502711, 2.2897199],
          [102.5031231, 2.2909254],
          [102.5032197, 2.291384],
          [102.5032866, 2.2919446],
          [102.5037028, 2.2933483],
          [102.5039259, 2.2938044],
          [102.5043373, 2.294335],
          [102.5049627, 2.2948454],
          [102.5053025, 2.2950142],
          [102.5058399, 2.2952604],
          [102.5062481, 2.29563],
          [102.5066188, 2.2960182],
          [102.5068179, 2.2963371],
          [102.506916, 2.2967188],
          [102.5070448, 2.2972114],
          [102.5070578, 2.2976737],
          [102.5068537, 2.299173],
          [102.5068838, 2.2996995],
          [102.5053294, 2.3025397],
          [102.5063851, 2.3038949],
          [102.5084744, 2.3041655],
          [102.5100308, 2.3052158],
          [102.5104685, 2.307102],
          [102.509511, 2.3082501],
          [102.5084167, 2.3133072],
          [102.51066, 2.3158768],
          [102.5111525, 2.3212893],
          [102.5118638, 2.3215899],
          [102.5133411, 2.3210979],
          [102.5155165, 2.3240546],
          [102.5162958, 2.3258816],
          [102.5156392, 2.3277951],
          [102.5158034, 2.3293533],
          [102.5145175, 2.3319228],
          [102.511326, 2.3364331],
          [102.5095577, 2.3423214],
          [102.5123922, 2.3484146],
          [102.5137363, 2.354228],
          [102.5153197, 2.3560638],
          [102.5154368, 2.3559653],
          [102.5156648, 2.3558915],
          [102.5159975, 2.3558176],
          [102.5164104, 2.3558484],
          [102.5170944, 2.3559038],
          [102.5180742, 2.3563347],
          [102.5185363, 2.3566056],
          [102.5190354, 2.356698],
          [102.51959, 2.3567657],
          [102.5199043, 2.3567657],
          [102.5201446, 2.3567473],
          [102.5204404, 2.3568335],
          [102.5212723, 2.3571844],
          [102.5216851, 2.3574245],
          [102.5219131, 2.3575969],
          [102.5222089, 2.3577816],
          [102.5228621, 2.358071],
          [102.5235646, 2.358348],
          [102.5240144, 2.3585635],
          [102.5243903, 2.3588468],
          [102.5244827, 2.3590191],
          [102.524797, 2.3595363],
          [102.5249819, 2.3598626],
          [102.5251174, 2.3599057],
          [102.5252407, 2.3599858],
          [102.5253331, 2.3601274],
          [102.5253824, 2.3604168],
          [102.52529, 2.3607492],
          [102.5252961, 2.3609832],
          [102.5252407, 2.3611248],
          [102.5251482, 2.3618144],
          [102.5251298, 2.363095],
          [102.5251852, 2.3638892],
          [102.525216, 2.3640247],
          [102.5256597, 2.3650713],
          [102.5258507, 2.3651822],
          [102.5262574, 2.3655085],
          [102.5271448, 2.366118],
          [102.5276254, 2.3665921],
          [102.52818, 2.3670846],
          [102.5291598, 2.3681067],
          [102.5298191, 2.3684822],
          [102.5307249, 2.3689071],
          [102.531754, 2.3692149],
          [102.5329002, 2.3694242],
          [102.5334609, 2.3695781],
          [102.5342779, 2.3700033],
          [102.5345086, 2.3700837],
          [102.5347446, 2.3702392],
          [102.5350665, 2.3704053],
          [102.5351738, 2.3704375],
          [102.5352381, 2.3705661],
          [102.5353508, 2.3712093],
          [102.5354259, 2.3715202],
          [102.5354742, 2.3716917],
          [102.5354742, 2.3717935],
          [102.535383, 2.3720293],
          [102.5352006, 2.3725385],
          [102.5350879, 2.3726511],
          [102.5349431, 2.3731763],
          [102.5348787, 2.3732567],
          [102.5348358, 2.3733961],
          [102.5348841, 2.3735247],
          [102.5351899, 2.3740822],
          [102.5355171, 2.3747736],
          [102.5356298, 2.3749772],
          [102.5356834, 2.3750844],
          [102.5356727, 2.3752345],
          [102.535619, 2.3754757],
          [102.535619, 2.3755722],
          [102.5356619, 2.3756847],
          [102.5357424, 2.375733],
          [102.5358497, 2.3757276],
          [102.5360053, 2.3757115],
          [102.5360643, 2.3757491],
          [102.5361394, 2.3759634],
          [102.5362198, 2.376151],
          [102.536252, 2.3765852],
          [102.5362574, 2.376746],
          [102.5363218, 2.3768907],
          [102.5365149, 2.3769336],
          [102.5366919, 2.3768693],
          [102.5369923, 2.3767513],
          [102.5370996, 2.3767406],
          [102.5372337, 2.3768157],
          [102.5376951, 2.3771801],
          [102.5377487, 2.377282],
          [102.5378506, 2.3776679],
          [102.537518, 2.3781074],
          [102.5374215, 2.3782306],
          [102.5373946, 2.378311],
          [102.5374483, 2.3784236],
          [102.5376092, 2.3786541],
          [102.5376468, 2.3788202],
          [102.5375931, 2.3789274],
          [102.5375341, 2.3789703],
          [102.5373893, 2.3789703],
          [102.5372552, 2.3790132],
          [102.5370138, 2.3793026],
          [102.536944, 2.3794527],
          [102.536885, 2.3795009],
          [102.5367938, 2.3795224],
          [102.5364291, 2.3793884],
          [102.5363486, 2.3793991],
          [102.5362467, 2.3794688],
          [102.5359945, 2.3801709],
          [102.5359999, 2.3802781],
          [102.5360106, 2.3805354],
          [102.5359784, 2.3806586],
          [102.5358926, 2.3807765],
          [102.5357102, 2.3810177],
          [102.5356727, 2.3811517],
          [102.5356083, 2.382154],
          [102.5355386, 2.3823577],
          [102.5354581, 2.3824488],
          [102.535383, 2.382497],
          [102.5353454, 2.3825399],
          [102.5353454, 2.3826149],
          [102.535383, 2.3826632],
          [102.5355278, 2.3826953],
          [102.5355868, 2.382765],
          [102.53556, 2.3829097],
          [102.5357478, 2.3832099],
          [102.5359838, 2.3834243],
          [102.5360428, 2.3835475],
          [102.5360321, 2.3836601],
          [102.5360643, 2.3837727],
          [102.5362265, 2.3839978],
          [102.5376701, 2.3855219],
          [102.5389866, 2.3886961],
          [102.5400246, 2.3906731],
          [102.5492407, 2.3941626],
          [102.5550865, 2.3960914],
          [102.556354, 2.3965045],
          [102.5589014, 2.3969652],
          [102.5665781, 2.3959321],
          [102.5871284, 2.4062454],
          [102.5931212, 2.4089841],
          [102.5943828, 2.4095606],
          [102.5951117, 2.4122006],
          [102.5951804, 2.4153362],
          [102.5958905, 2.4162518],
          [102.5965778, 2.4168011],
          [102.5959593, 2.4179683],
          [102.5959364, 2.4197993],
          [102.5943786, 2.4198909],
          [102.5946306, 2.4225916],
          [102.5933611, 2.4249287],
          [102.5925689, 2.4275125],
          [102.5937372, 2.429229],
          [102.5941724, 2.4317466],
          [102.5960099, 2.4360989],
          [102.5946993, 2.4381093],
          [102.5949284, 2.4390477],
          [102.5938905, 2.4399458],
          [102.5964536, 2.4447082],
          [102.5968443, 2.446776],
          [102.5962231, 2.4507565],
          [102.5972963, 2.454171],
          [102.5963607, 2.454786],
          [102.5964614, 2.4553563],
          [102.5983754, 2.4555911],
          [102.6002222, 2.457302],
          [102.6000207, 2.4582078],
          [102.6010952, 2.4592142],
          [102.6060298, 2.459563],
          [102.6061483, 2.4614605],
          [102.6052626, 2.4629938],
          [102.6060399, 2.4641243],
          [102.6098926, 2.4649843],
          [102.6117359, 2.4673875],
          [102.614727, 2.466717],
          [102.6184782, 2.4697293],
          [102.6183207, 2.4722639],
          [102.6187572, 2.4738742],
          [102.6180453, 2.4754802],
          [102.6159563, 2.4835226],
          [102.6162388, 2.4858167],
          [102.613987, 2.487454],
          [102.6125848, 2.4911133],
          [102.6106762, 2.4905282],
          [102.6081243, 2.5006377],
          [102.6066801, 2.5016794],
          [102.6094925, 2.50564],
          [102.6080261, 2.5070061],
          [102.6088237, 2.5091756],
          [102.6136666, 2.5121386],
          [102.6145474, 2.5135845],
          [102.6191916, 2.5179807],
          [102.6172551, 2.5248499],
          [102.6128191, 2.529503],
          [102.612624, 2.531364],
          [102.6100065, 2.5358501],
          [102.6106802, 2.5391766],
          [102.611984, 2.5456905],
          [102.6116792, 2.5513167],
          [102.6151727, 2.5550662],
          [102.6131123, 2.5583365],
          [102.6125719, 2.5617828],
          [102.6089136, 2.5637725],
          [102.6081848, 2.5661855],
          [102.608839, 2.567666],
          [102.6114752, 2.5681497],
          [102.6132048, 2.570503],
          [102.6138181, 2.5718894],
          [102.6130776, 2.573047],
          [102.6135374, 2.5732154],
          [102.6139754, 2.5740923],
          [102.614104, 2.574344],
          [102.6140366, 2.574481],
          [102.6140855, 2.5746078],
          [102.6142452, 2.5746182],
          [102.6143363, 2.5741881],
          [102.6147482, 2.5740416],
          [102.6149341, 2.5743164],
          [102.6147664, 2.574817],
          [102.6147829, 2.5751975],
          [102.614539, 2.575623],
          [102.6148693, 2.5758926],
          [102.6150992, 2.575416],
          [102.6154886, 2.575434],
          [102.616019, 2.5758303],
          [102.6159302, 2.5760819],
          [102.6169503, 2.5767955],
          [102.6169277, 2.5769619],
          [102.6167854, 2.5778038],
          [102.615899, 2.5791212],
          [102.6159107, 2.5794246],
          [102.616131, 2.5794627],
          [102.616443, 2.5792296],
          [102.6167646, 2.5792196],
          [102.6175959, 2.5813288],
          [102.6183432, 2.5815435],
          [102.6183033, 2.5826709],
          [102.6234964, 2.5833309],
          [102.623902, 2.5863212],
          [102.6244816, 2.5869104],
          [102.6254294, 2.5895591],
          [102.6271977, 2.5896715],
          [102.6408998, 2.5951004],
          [102.6435003, 2.6022409],
          [102.6427984, 2.6054613],
          [102.6403961, 2.607761],
          [102.6373968, 2.6122509],
          [102.6351997, 2.6163898],
          [102.6330026, 2.620531],
          [102.6312021, 2.6251303],
          [102.6302038, 2.6299712],
          [102.6306004, 2.6328497],
          [102.6324989, 2.6387208],
          [102.6450187, 2.6813636],
          [102.7001786, 2.8320519],
          [102.7871998, 2.8039193],
          [102.802074, 2.7993649],
          [102.8077259, 2.7940587],
          [102.8283369, 2.7645365],
          [102.8499296, 2.7361863],
          [102.879414, 2.7004238],
          [102.8956374, 2.6815335],
          [102.959235, 2.6066811],
          [102.9663395, 2.5994688],
          [102.9731066, 2.5946505],
          [103.1403994, 2.5272673],
          [103.1515781, 2.5236058],
          [103.2691896, 2.5693434],
          [103.2888419, 2.5775028],
          [103.2900138, 2.5777513],
          [103.3454083, 2.5894956],
          [103.3536313, 2.5911675],
          [103.3747543, 2.5794959],
          [103.4157934, 2.5578192],
          [103.4211632, 2.559386],
          [103.4263774, 2.5593574],
          [103.4358598, 2.5570825],
          [103.4416644, 2.5555321],
          [103.446876, 2.5528011],
          [103.4516077, 2.5489759],
          [103.4596295, 2.5416576],
          [103.4722038, 2.52604],
          [103.5389894, 2.4916056],
          [103.6094479, 2.457941],
          [103.6085327, 2.4630907],
          [103.6079087, 2.4662614],
          [103.608459, 2.4692162],
          [103.6094894, 2.4707096],
          [103.6105581, 2.4706562],
          [103.6112527, 2.4689747],
          [103.6123213, 2.4659585],
          [103.6140848, 2.4641171],
          [103.6167856, 2.4627474],
          [103.6188846, 2.4625172],
          [103.6238838, 2.462664],
          [103.6260275, 2.4634429],
          [103.6276297, 2.4649426],
          [103.6289265, 2.4671329],
          [103.629601, 2.4691999],
          [103.6312623, 2.4730314],
          [103.6351707, 2.4791905],
          [103.6381176, 2.4849607],
          [103.6385046, 2.4874595],
          [103.6378085, 2.4881389],
          [103.6345021, 2.4880603],
          [103.6318913, 2.4888209],
          [103.6296868, 2.4910005],
          [103.6288189, 2.4938398],
          [103.6289996, 2.4960252],
          [103.6300746, 2.4987927],
          [103.6303977, 2.5024061],
          [103.629708, 2.5053429],
          [103.6262228, 2.5100487],
          [103.6229316, 2.5131616],
          [103.6182023, 2.5155813],
          [103.6165719, 2.517252],
          [103.6165362, 2.5195657],
          [103.618293, 2.5253261],
          [103.6211058, 2.5288021],
          [103.6241995, 2.5315072],
          [103.6238639, 2.5331429],
          [103.6222478, 2.533794],
          [103.6207279, 2.5337091],
          [103.6180832, 2.5315198],
          [103.6158262, 2.5303304],
          [103.6133681, 2.5303554],
          [103.6099997, 2.5315808],
          [103.6065591, 2.5320491],
          [103.6030652, 2.5318477],
          [103.5994576, 2.530089],
          [103.5956831, 2.5265206],
          [103.5935902, 2.5263128],
          [103.5914528, 2.5279218],
          [103.590298, 2.5308253],
          [103.5901594, 2.5338821],
          [103.5908386, 2.5364927],
          [103.5949032, 2.5411098],
          [103.5960404, 2.5431261],
          [103.5955558, 2.5455931],
          [103.5931499, 2.5482926],
          [103.5923662, 2.5497428],
          [103.5930076, 2.5516612],
          [103.5970186, 2.5553614],
          [103.607227, 2.5651472],
          [103.6105198, 2.5657714],
          [103.6121408, 2.564435],
          [103.612567, 2.5628859],
          [103.6128692, 2.5593002],
          [103.6140104, 2.5566033],
          [103.6158162, 2.5565071],
          [103.6174131, 2.5571845],
          [103.6178187, 2.5589605],
          [103.6178187, 2.5600173],
          [103.6163074, 2.563754],
          [103.6159606, 2.5680924],
          [103.6165341, 2.5725107],
          [103.6164158, 2.5755776],
          [103.6138965, 2.5779226],
          [103.6109946, 2.5780007],
          [103.6081207, 2.5754027],
          [103.6048373, 2.5693324],
          [103.6021516, 2.5684154],
          [103.5995992, 2.5694116],
          [103.5985986, 2.5721716],
          [103.5982021, 2.5743527],
          [103.5997511, 2.5793672],
          [103.6018349, 2.5833101],
          [103.6037357, 2.5859607],
          [103.6065344, 2.5878],
          [103.609188, 2.5888415],
          [103.6119896, 2.5888038],
          [103.6165259, 2.5863808],
          [103.6191852, 2.5856766],
          [103.6221846, 2.5857079],
          [103.6246195, 2.5872309],
          [103.6248909, 2.5890746],
          [103.6249973, 2.5897975],
          [103.6238165, 2.5922726],
          [103.6203442, 2.5934049],
          [103.6164495, 2.5934574],
          [103.6138311, 2.5939189],
          [103.6124331, 2.5954598],
          [103.6124141, 2.5977027],
          [103.6133797, 2.5999923],
          [103.6156961, 2.6018716],
          [103.6208178, 2.6031539],
          [103.6245023, 2.6037535],
          [103.6267817, 2.6059665],
          [103.6267448, 2.6084851],
          [103.6255392, 2.6105569],
          [103.6210243, 2.6145473],
          [103.6168907, 2.6166697],
          [103.6146956, 2.6190825],
          [103.6146993, 2.6215703],
          [103.6152589, 2.6237779],
          [103.616741, 2.6251681],
          [103.6212651, 2.6269609],
          [103.623192, 2.6285084],
          [103.6234611, 2.6310608],
          [103.6209836, 2.6327453],
          [103.6156316, 2.6340509],
          [103.6135098, 2.6352222],
          [103.612505, 2.6368514],
          [103.6126025, 2.6390603],
          [103.6155058, 2.6428396],
          [103.6172531, 2.6472367],
          [103.6173383, 2.6488856],
          [103.616668, 2.6510853],
          [103.6142496, 2.6525908],
          [103.6116821, 2.653384],
          [103.6089103, 2.6531163],
          [103.6069303, 2.6527715],
          [103.6053588, 2.6520595],
          [103.6030918, 2.6502102],
          [103.6023453, 2.6489953],
          [103.6022988, 2.6473882],
          [103.6027629, 2.6457199],
          [103.6066817, 2.6399212],
          [103.6070824, 2.638768],
          [103.6069804, 2.6374337],
          [103.6066843, 2.636208],
          [103.6057791, 2.6347119],
          [103.6040517, 2.6336556],
          [103.6032092, 2.6332914],
          [103.6018072, 2.6331272],
          [103.5995536, 2.6336981],
          [103.5968557, 2.6354317],
          [103.5951657, 2.6379652],
          [103.5937043, 2.6409705],
          [103.5927038, 2.6477691],
          [103.5906258, 2.6517895],
          [103.5906258, 2.6539798],
          [103.5913278, 2.65617],
          [103.5926246, 2.6584697],
          [103.5940967, 2.6603746],
          [103.5961683, 2.6637501],
          [103.6000585, 2.6654429],
          [103.6028248, 2.665113],
          [103.6060124, 2.6647328],
          [103.6100022, 2.6632264],
          [103.618507, 2.6567772],
          [103.6201358, 2.6567081],
          [103.6232303, 2.6568177],
          [103.6259675, 2.6582536],
          [103.6284855, 2.6595103],
          [103.6316689, 2.6614211],
          [103.6350893, 2.6654762],
          [103.6396493, 2.663167],
          [103.6421245, 2.6624278],
          [103.6450335, 2.6607709],
          [103.6466794, 2.6600954],
          [103.648657, 2.6594837],
          [103.6498181, 2.6593053],
          [103.651196, 2.6594454],
          [103.6516936, 2.6595729],
          [103.6517446, 2.659968],
          [103.6525357, 2.6602484],
          [103.6537172, 2.6605343],
          [103.6552085, 2.6611988],
          [103.6554123, 2.6616703],
          [103.6553909, 2.6621955],
          [103.655938, 2.6626778],
          [103.6574401, 2.662592],
          [103.6592431, 2.6632963],
          [103.6602749, 2.6640086],
          [103.6608211, 2.6645998],
          [103.6609178, 2.6651898],
          [103.6605714, 2.6660454],
          [103.660092, 2.6664046],
          [103.6598213, 2.6669543],
          [103.6611927, 2.6669724],
          [103.6615626, 2.6667471],
          [103.6617249, 2.6665668],
          [103.6627444, 2.6660351],
          [103.6645037, 2.6649627],
          [103.6665336, 2.6635928],
          [103.6689111, 2.6613795],
          [103.6772612, 2.6553308],
          [103.6784605, 2.6544408],
          [103.6788543, 2.6541486],
          [103.6914202, 2.6473867],
          [103.7035115, 2.6429094],
          [103.7090529, 2.6408574],
          [103.7102497, 2.6400811],
          [103.7114981, 2.6392713],
          [103.7194325, 2.6340697],
          [103.7199429, 2.6332413],
          [103.719911, 2.6328717],
          [103.7198919, 2.6324319],
          [103.7200705, 2.6323682],
          [103.7201725, 2.6322471],
          [103.7206319, 2.6326104],
          [103.720887, 2.6325721],
          [103.7216015, 2.632929],
          [103.7222905, 2.6330246],
          [103.7229348, 2.6331775],
          [103.7240831, 2.633305],
          [103.7249571, 2.6332285],
          [103.7250974, 2.6334133],
          [103.7254356, 2.6335535],
          [103.7283765, 2.6329354],
          [103.7315364, 2.6324733],
          [103.7331152, 2.6325364],
          [103.7348203, 2.6324102],
          [103.7362277, 2.6324373],
          [103.7376532, 2.6328698],
          [103.7384922, 2.6328428],
          [103.7390516, 2.6325184],
          [103.7400079, 2.6329059],
          [103.7441128, 2.6347773],
          [103.7449094, 2.636385],
          [103.7471203, 2.6369859],
          [103.7489736, 2.6406723],
          [103.7490386, 2.6421338],
          [103.7486484, 2.6435791],
          [103.7486484, 2.6470868],
          [103.7480307, 2.6480124],
          [103.7491849, 2.6484022],
          [103.7518022, 2.6477851],
          [103.7545659, 2.6485159],
          [103.7544195, 2.6503022],
          [103.7548097, 2.6507894],
          [103.7557851, 2.6507406],
          [103.7574595, 2.6493116],
          [103.7573132, 2.6485808],
          [103.7599143, 2.6461449],
          [103.7611823, 2.6441962],
          [103.7624016, 2.6439364],
          [103.7640923, 2.6448133],
          [103.76458, 2.6445535],
          [103.764645, 2.6437577],
          [103.7634095, 2.6428971],
          [103.7628568, 2.6413868],
          [103.7634095, 2.6399902],
          [103.7642711, 2.6396979],
          [103.7643524, 2.6388372],
          [103.7637996, 2.6387722],
          [103.7636046, 2.6382688],
          [103.7639711, 2.6372539],
          [103.7629868, 2.6355893],
          [103.7635265, 2.6345527],
          [103.7645637, 2.6332183],
          [103.7660612, 2.6319186],
          [103.7663507, 2.6310153],
          [103.7657329, 2.6299519],
          [103.7635991, 2.6298398],
          [103.761907, 2.6297643],
          [103.7611211, 2.6291702],
          [103.7606968, 2.6283325],
          [103.7605606, 2.6268342],
          [103.7608577, 2.6263637],
          [103.762245, 2.6257452],
          [103.7588407, 2.6250486],
          [103.7579998, 2.6250649],
          [103.7578606, 2.6244157],
          [103.7580803, 2.6237311],
          [103.75848, 2.6227199],
          [103.7588976, 2.6220477],
          [103.7592508, 2.62134],
          [103.7595207, 2.6197297],
          [103.7598825, 2.6181186],
          [103.7606397, 2.6169005],
          [103.761517, 2.6157245],
          [103.765666, 2.6125757],
          [103.7711302, 2.6110132],
          [103.7733306, 2.6102765],
          [103.7737726, 2.609425],
          [103.7751951, 2.6091474],
          [103.7765526, 2.6088825],
          [103.777974, 2.6086051],
          [103.7782057, 2.6084823],
          [103.778813, 2.6090295],
          [103.7789444, 2.6097149],
          [103.7786789, 2.6102218],
          [103.7786011, 2.6107947],
          [103.7792931, 2.6115626],
          [103.7796708, 2.6124758],
          [103.7799256, 2.614242],
          [103.7805741, 2.6156793],
          [103.779924, 2.6158256],
          [103.7793237, 2.6170592],
          [103.7793959, 2.6178993],
          [103.7806369, 2.6195794],
          [103.7814716, 2.6189117],
          [103.7832134, 2.6191218],
          [103.7834419, 2.6187692],
          [103.7834999, 2.6184514],
          [103.784297, 2.6181175],
          [103.7845744, 2.6177638],
          [103.7853029, 2.617878],
          [103.7854901, 2.6181379],
          [103.785813, 2.6181224],
          [103.7860909, 2.6175967],
          [103.7857862, 2.6171792],
          [103.7862325, 2.6162859],
          [103.7866461, 2.6163652],
          [103.7866499, 2.6168378],
          [103.7870801, 2.6168116],
          [103.7872501, 2.6164429],
          [103.7872507, 2.6159456],
          [103.7875146, 2.6156669],
          [103.787852, 2.6157516],
          [103.7883756, 2.6153572],
          [103.7889399, 2.6148181],
          [103.7889812, 2.6145394],
          [103.7884561, 2.6145625],
          [103.7879191, 2.6143503],
          [103.7871075, 2.6149478],
          [103.7859557, 2.6150174],
          [103.7855126, 2.6157805],
          [103.7833499, 2.6170987],
          [103.7820933, 2.6167617],
          [103.7809738, 2.6154697],
          [103.7802807, 2.6138074],
          [103.7798456, 2.611202],
          [103.7797979, 2.6092465],
          [103.7802335, 2.6064915],
          [103.7808287, 2.6047319],
          [103.7817843, 2.6028303],
          [103.7831473, 2.6009681],
          [103.7925663, 2.5921378],
          [103.7960339, 2.5892075],
          [103.7972457, 2.5881834],
          [103.7990281, 2.5866772],
          [103.8004552, 2.5854712],
          [103.8009584, 2.5852397],
          [103.800909, 2.5854958],
          [103.8010882, 2.585685],
          [103.8015495, 2.5854996],
          [103.8016102, 2.585148],
          [103.8013553, 2.5849015],
          [103.802222, 2.584513],
          [103.8035947, 2.5834313],
          [103.8089307, 2.5800843],
          [103.8116813, 2.579333],
          [103.8126195, 2.579153],
          [103.8127006, 2.5794879],
          [103.8126582, 2.5800843],
          [103.8128652, 2.5809214],
          [103.8138204, 2.5819193],
          [103.8144099, 2.5810407],
          [103.8145108, 2.5805602],
          [103.8172313, 2.5812419],
          [103.8171281, 2.5815471],
          [103.8176597, 2.5818416],
          [103.8177659, 2.5822328],
          [103.8169532, 2.5833287],
          [103.8168558, 2.5845039],
          [103.8170897, 2.5849524],
          [103.8167566, 2.5861668],
          [103.8168531, 2.5868742],
          [103.8171726, 2.5872284],
          [103.8174998, 2.5870789],
          [103.8180532, 2.5854717],
          [103.8188653, 2.5847287],
          [103.8194737, 2.5844916],
          [103.8198553, 2.5847515],
          [103.8201292, 2.5841807],
          [103.8210751, 2.5840544],
          [103.821561, 2.5831867],
          [103.8218356, 2.5833455],
          [103.8222677, 2.5834565],
          [103.8225754, 2.5829627],
          [103.8229265, 2.5830479],
          [103.8228447, 2.58333],
          [103.823081, 2.5835052],
          [103.8234581, 2.5831023],
          [103.8232888, 2.5828247],
          [103.8229691, 2.5826205],
          [103.8228262, 2.5821033],
          [103.8230383, 2.5817196],
          [103.82346, 2.581509],
          [103.8238588, 2.5808812],
          [103.8240098, 2.5799581],
          [103.8237263, 2.5789973],
          [103.8232569, 2.5784898],
          [103.8225832, 2.5782569],
          [103.8222143, 2.5786388],
          [103.8217294, 2.5788065],
          [103.8211533, 2.5782076],
          [103.8208129, 2.5774753],
          [103.820965, 2.5772778],
          [103.8211353, 2.5772661],
          [103.8211728, 2.5769705],
          [103.8209207, 2.5766825],
          [103.8204736, 2.5768306],
          [103.8201115, 2.5769978],
          [103.8198913, 2.5775383],
          [103.8197194, 2.5776214],
          [103.8198543, 2.5778116],
          [103.8202266, 2.5773113],
          [103.8203212, 2.5773609],
          [103.8201721, 2.5778068],
          [103.8196308, 2.5784852],
          [103.8191657, 2.578564],
          [103.818239, 2.578336],
          [103.8172195, 2.5776163],
          [103.8164138, 2.5767712],
          [103.8153267, 2.5742516],
          [103.8150402, 2.572947],
          [103.8149941, 2.57119],
          [103.8152328, 2.56935],
          [103.8151475, 2.5691046],
          [103.8148222, 2.5681005],
          [103.8155021, 2.5678556],
          [103.8159715, 2.5681632],
          [103.8162148, 2.568645],
          [103.8164567, 2.5687487],
          [103.8168177, 2.5677342],
          [103.8171053, 2.567567],
          [103.8171554, 2.5670697],
          [103.817282, 2.5667053],
          [103.8174765, 2.5666399],
          [103.8176914, 2.5666664],
          [103.8178584, 2.5665092],
          [103.8176672, 2.5659928],
          [103.8175106, 2.5651547],
          [103.8175433, 2.5643715],
          [103.8180668, 2.5630295],
          [103.8185663, 2.5625762],
          [103.8189855, 2.5625657],
          [103.8195407, 2.5619559],
          [103.8195171, 2.561309],
          [103.8192572, 2.5611399],
          [103.819029, 2.5601231],
          [103.8190756, 2.5594141],
          [103.8193873, 2.5590933],
          [103.8196574, 2.5579183],
          [103.8198382, 2.5571662],
          [103.8202126, 2.5565858],
          [103.8211745, 2.5562774],
          [103.8219818, 2.5541514],
          [103.8239202, 2.5517157],
          [103.8263101, 2.5488202],
          [103.8265715, 2.548709],
          [103.8273787, 2.547937],
          [103.8282627, 2.547328],
          [103.828005, 2.5468162],
          [103.8281672, 2.546515],
          [103.8284443, 2.5465477],
          [103.8285084, 2.5462492],
          [103.8275997, 2.5464668],
          [103.8261481, 2.5464367],
          [103.8241496, 2.5471267],
          [103.8235246, 2.5472974],
          [103.823298, 2.5475742],
          [103.8227438, 2.5473084],
          [103.8227191, 2.5469421],
          [103.8228068, 2.5466332],
          [103.8220593, 2.5449172],
          [103.8217417, 2.5435144],
          [103.8217428, 2.5420136],
          [103.8221883, 2.5397207],
          [103.8234082, 2.5369663],
          [103.8244315, 2.5343047],
          [103.8256462, 2.533224],
          [103.826243, 2.5325067],
          [103.8265558, 2.5324041],
          [103.8268504, 2.5327228],
          [103.8272381, 2.5322438],
          [103.8281715, 2.5303467],
          [103.827957, 2.5293258],
          [103.8279773, 2.5281692],
          [103.8283432, 2.5262951],
          [103.8297562, 2.5238117],
          [103.8362466, 2.5147471],
          [103.8378245, 2.5127617],
          [103.8385182, 2.5125468],
          [103.8390951, 2.5124265],
          [103.8398386, 2.5122845],
          [103.8413275, 2.510991],
          [103.8413782, 2.5106585],
          [103.8416534, 2.5104187],
          [103.8421241, 2.5102268],
          [103.8424154, 2.5102381],
          [103.8434292, 2.5095736],
          [103.8432477, 2.5091791],
          [103.8433389, 2.5085997],
          [103.8435393, 2.5081755],
          [103.8439217, 2.5078122],
          [103.8442157, 2.5078007],
          [103.8445478, 2.5076613],
          [103.8443809, 2.5075421],
          [103.8449887, 2.5070292],
          [103.8450823, 2.5072503],
          [103.84525, 2.5072666],
          [103.8455922, 2.5069558],
          [103.8455756, 2.5065123],
          [103.8459205, 2.5063349],
          [103.8460758, 2.506441],
          [103.8462089, 2.5062829],
          [103.8461667, 2.5060782],
          [103.8455313, 2.5061618],
          [103.8450483, 2.5061449],
          [103.8447841, 2.5057483],
          [103.8446789, 2.5054753],
          [103.8444786, 2.5055007],
          [103.8444094, 2.5053265],
          [103.8449976, 2.5047298],
          [103.844978, 2.5041346],
          [103.8447803, 2.5040615],
          [103.8441808, 2.5042048],
          [103.8438842, 2.5037587],
          [103.8438472, 2.5034771],
          [103.8432287, 2.5028999],
          [103.8422209, 2.5033763],
          [103.8412685, 2.5038439],
          [103.8408935, 2.5037188],
          [103.8404091, 2.5031581],
          [103.8402551, 2.5028162],
          [103.8401502, 2.5026851],
          [103.8400041, 2.5027133],
          [103.8395293, 2.502795],
          [103.8393073, 2.5028798],
          [103.8392611, 2.5030563],
          [103.8393174, 2.5032224],
          [103.8392249, 2.5033979],
          [103.8392276, 2.5036766],
          [103.8392963, 2.5038337],
          [103.8391846, 2.5039097],
          [103.838454, 2.5044101],
          [103.8377626, 2.5047113],
          [103.8361511, 2.5048689],
          [103.8353489, 2.5053268],
          [103.8352488, 2.5050489],
          [103.8342258, 2.5045409],
          [103.8340365, 2.5049632],
          [103.833779, 2.5049417],
          [103.8330419, 2.504356],
          [103.8324025, 2.5044476],
          [103.8321632, 2.5041255],
          [103.8311423, 2.5035885],
          [103.8301065, 2.5034465],
          [103.8290862, 2.502395],
          [103.8282971, 2.5018762],
          [103.8275509, 2.5002958],
          [103.8205197, 2.4974296],
          [103.8198476, 2.4968122],
          [103.8203894, 2.4961943],
          [103.8203744, 2.4959386],
          [103.8191341, 2.4960474],
          [103.8190863, 2.4959188],
          [103.8187956, 2.4951369],
          [103.8184649, 2.4942121],
          [103.8174384, 2.4930845],
          [103.8173072, 2.4921901],
          [103.8174483, 2.4906675],
          [103.8178252, 2.4886982],
          [103.818279, 2.4865783],
          [103.8188881, 2.484661],
          [103.8199291, 2.4820807],
          [103.8212278, 2.4798257],
          [103.8236888, 2.4765176],
          [103.8257023, 2.4741514],
          [103.8277158, 2.4722239],
          [103.8278912, 2.4717327],
          [103.827888, 2.4713193],
          [103.8281367, 2.4714104],
          [103.8286956, 2.4712314],
          [103.8291492, 2.470693],
          [103.8294864, 2.4691031],
          [103.8292133, 2.4629758],
          [103.8293826, 2.4606684],
          [103.829379298792915, 2.46054844930768],
          [103.8293665, 2.4600834],
          [103.829142, 2.4596528],
          [103.8293491, 2.4591252],
          [103.8301741, 2.4565146],
          [103.8312832, 2.4531702],
          [103.8316426, 2.4521525],
          [103.8315476, 2.4517602],
          [103.8321133, 2.4515206],
          [103.8326156, 2.4498461],
          [103.8343917, 2.446359],
          [103.8364411, 2.4428736],
          [103.8372976, 2.4414521],
          [103.8384324, 2.4394247],
          [103.8386411, 2.4387852],
          [103.8388702, 2.4383204],
          [103.8392796, 2.4373257],
          [103.8393548, 2.437044],
          [103.8397249, 2.4362053],
          [103.8397622, 2.4360186],
          [103.8397219, 2.4358715],
          [103.8398141, 2.4356649],
          [103.8399623, 2.4353552],
          [103.8403996, 2.435178],
          [103.8411257, 2.4348405],
          [103.8416538, 2.4347675],
          [103.8422547, 2.4348624],
          [103.8426886, 2.4347968],
          [103.8433099, 2.4345308],
          [103.8440986, 2.4339504],
          [103.8451636, 2.4325705],
          [103.8459553, 2.4314779],
          [103.8473017, 2.4298117],
          [103.8476957, 2.4295359],
          [103.8481299, 2.4294875],
          [103.8495642, 2.4294151],
          [103.8498014, 2.4298441],
          [103.8500654, 2.4301124],
          [103.8514993, 2.430568],
          [103.8529884, 2.4301146],
          [103.8549362, 2.4266635],
          [103.8569565, 2.4215719],
          [103.8565341, 2.4201342],
          [103.8576864, 2.4177462],
          [103.8586887, 2.417117],
          [103.8592857, 2.4156683],
          [103.8608296, 2.4142683],
          [103.8609047, 2.4121148],
          [103.8623499, 2.4108269],
          [103.8636312, 2.4093283],
          [103.8637216, 2.4090022],
          [103.8643104, 2.4085246],
          [103.8647155, 2.4083789],
          [103.8644532, 2.4078897],
          [103.8645348, 2.407214],
          [103.8650362, 2.4067364],
          [103.8655696, 2.4065559],
          [103.865386, 2.4060579],
          [103.8671477, 2.403171],
          [103.8679348, 2.4022887],
          [103.8684705, 2.4011891],
          [103.867835, 2.3999997],
          [103.8680426, 2.3992414],
          [103.8690222, 2.397948],
          [103.8703926, 2.3963306],
          [103.8730683, 2.393864],
          [103.8740249, 2.3919525],
          [103.8778575, 2.3878844],
          [103.8801856, 2.3847971],
          [103.8810693, 2.3841209],
          [103.8811941, 2.3840253],
          [103.881398, 2.3844112],
          [103.8816662, 2.3852688],
          [103.8815482, 2.3860835],
          [103.8816018, 2.386255],
          [103.8820525, 2.3861757],
          [103.8916226, 2.3762429],
          [103.9092071, 2.3520228],
          [103.9121898, 2.3488283],
          [103.9155157, 2.347692],
          [103.9158019, 2.3488464],
          [103.9160379, 2.3492752],
          [103.9163598, 2.3498862],
          [103.9164563, 2.3501542],
          [103.916231, 2.350315],
          [103.9163061, 2.350583],
          [103.9167246, 2.3509475],
          [103.9175185, 2.3514728],
          [103.9183553, 2.35173],
          [103.9186987, 2.3510547],
          [103.9185592, 2.3506795],
          [103.918248, 2.3502293],
          [103.917776, 2.3500577],
          [103.9180871, 2.3498862],
          [103.9183017, 2.3495968],
          [103.9181193, 2.3492859],
          [103.9177867, 2.3492216],
          [103.9175078, 2.3492002],
          [103.9173146, 2.3489643],
          [103.9170464, 2.3489107],
          [103.9166709, 2.3489858],
          [103.9161667, 2.3485248],
          [103.9163705, 2.3480746],
          [103.9170679, 2.3478173],
          [103.9171644, 2.3479631],
          [103.9175078, 2.3485634],
          [103.9181515, 2.3484991],
          [103.9184734, 2.3475772],
          [103.9191385, 2.3471269],
          [103.9188381, 2.3464409],
          [103.9196106, 2.3450473],
          [103.9215633, 2.3425603],
          [103.9223143, 2.3422816],
          [103.9227107, 2.3423554],
          [103.9228278, 2.3426395],
          [103.9234301, 2.3432464],
          [103.9242025, 2.3433536],
          [103.9291807, 2.3403949],
          [103.931348, 2.3399446],
          [103.9331719, 2.3406521],
          [103.9333435, 2.3410166],
          [103.9331289, 2.341767],
          [103.9338585, 2.342646],
          [103.9345666, 2.3426675],
          [103.9348456, 2.3429676],
          [103.9353391, 2.3434608],
          [103.9357468, 2.3436108],
          [103.9362188, 2.3436751],
          [103.9363905, 2.3433321],
          [103.9364549, 2.3429676],
          [103.9366265, 2.3428176],
          [103.9365193, 2.3419385],
          [103.9365622, 2.3414669],
          [103.9372917, 2.3409309],
          [103.9374848, 2.3411238],
          [103.9379355, 2.3410381],
          [103.938944, 2.3409094],
          [103.9391585, 2.3399661],
          [103.9390083, 2.3391299],
          [103.9387938, 2.3381223],
          [103.9410906, 2.3337798],
          [103.9484077, 2.324297],
          [103.949427, 2.3238253],
          [103.9499741, 2.324029],
          [103.9506608, 2.3237395],
          [103.95168, 2.3232678],
          [103.9517229, 2.3224102],
          [103.9548236, 2.3182937],
          [103.955596, 2.3176077],
          [103.9558213, 2.3179721],
          [103.9561217, 2.3180365],
          [103.9564543, 2.3178649],
          [103.9566582, 2.3175326],
          [103.9567977, 2.3172067],
          [103.9573126, 2.3169066],
          [103.958171, 2.3169709],
          [103.9586645, 2.3166278],
          [103.9585786, 2.3163706],
          [103.9581924, 2.3158989],
          [103.9588791, 2.3147411],
          [103.9596515, 2.3140979],
          [103.9601236, 2.3138406],
          [103.9609175, 2.3137334],
          [103.9610034, 2.3131974],
          [103.9601236, 2.3116537],
          [103.9610034, 2.3104959],
          [103.9612823, 2.3105817],
          [103.9616256, 2.3104959],
          [103.9617973, 2.3101743],
          [103.9617544, 2.309574],
          [103.9614754, 2.3091667],
          [103.9609819, 2.3089522],
          [103.9599305, 2.3089951],
          [103.9596301, 2.3093596],
          [103.9595442, 2.3101743],
          [103.958922, 2.3102387],
          [103.9582353, 2.3103244],
          [103.957656, 2.3106675],
          [103.9570337, 2.3105388],
          [103.9565831, 2.3104959],
          [103.9561968, 2.3111392],
          [103.9553171, 2.3106246],
          [103.9541584, 2.3095097],
          [103.9533859, 2.3077302],
          [103.9530211, 2.3059077],
          [103.953064, 2.3038066],
          [103.9531499, 2.3031419],
          [103.9537507, 2.3034207],
          [103.9537507, 2.3024987],
          [103.9529782, 2.30222],
          [103.9527851, 2.3024344],
          [103.9524203, 2.3026274],
          [103.9518624, 2.3022629],
          [103.9511972, 2.301684],
          [103.9509183, 2.3003547],
          [103.9516907, 2.2986609],
          [103.952313, 2.297546],
          [103.9523988, 2.2964311],
          [103.9521696, 2.295058],
          [103.9528777, 2.2937715],
          [103.9597442, 2.282944],
          [103.9639499, 2.2777125],
          [103.9687778, 2.2686216],
          [103.9732196, 2.2621465],
          [103.9739921, 2.2616533],
          [103.9794208, 2.2578368],
          [103.980086, 2.257408],
          [103.9802792, 2.2569578],
          [103.9799573, 2.2561216],
          [103.980365, 2.2557785],
          [103.9813949, 2.2531563],
          [103.9814593, 2.2520413],
          [103.9812447, 2.2503903],
          [103.9812555, 2.2493505],
          [103.981513, 2.2475065],
          [103.9808692, 2.2468311],
          [103.9802259, 2.2470289],
          [103.9789859, 2.2470719],
          [103.9783991, 2.2482116],
          [103.9785485, 2.2486626],
          [103.9757423, 2.2517083],
          [103.9740508, 2.2511404],
          [103.970573, 2.2521057],
          [103.9650665, 2.250513],
          [103.9617336, 2.2487754],
          [103.9589803, 2.245976],
          [103.9566618, 2.2407633],
          [103.9589803, 2.2296621],
          [103.9591736, 2.2252699],
          [103.9569516, 2.2195262],
          [103.9575796, 2.2161958],
          [103.9587388, 2.210983],
          [103.9606709, 2.2063493],
          [103.9642453, 2.1999298],
          [103.9677232, 2.1957305],
          [103.9713942, 2.1903246],
          [103.9744855, 2.185353],
          [103.9777701, 2.1812985],
          [103.9825038, 2.1767131],
          [103.9834699, 2.1744928],
          [103.9865129, 2.1702934],
          [103.9898458, 2.1668664],
          [103.9936618, 2.1619913],
          [103.99949952365742, 2.156084763053261],
          [103.99949952365742, 2.15608476305326],
          [104.0013902, 2.1541718],
          [104.0022129, 2.1530002],
          [104.0044946, 2.1497507],
          [104.0048434, 2.1503859],
          [104.004646, 2.1514365],
          [104.0051762, 2.1522074],
          [104.0053997, 2.1525323],
          [104.0065375, 2.1532602],
          [104.0073154, 2.1524663],
          [104.0078577, 2.1515336],
          [104.007318, 2.1503714],
          [104.0062784, 2.1492934],
          [104.0058294, 2.1485214],
          [104.0077516, 2.1451123],
          [104.0101425, 2.140607],
          [104.0130798, 2.1358968],
          [104.0173834, 2.1299579],
          [104.019501, 2.1249064],
          [104.0221651, 2.1172609],
          [104.0259222, 2.1077722],
          [104.0292694, 2.0982152],
          [104.0343244, 2.0876341],
          [104.038628, 2.0797153],
          [104.0429998, 2.0713869],
          [104.0466203, 2.0656526],
          [104.0495576, 2.0636728],
          [104.0547492, 2.0594403],
          [104.0566619, 2.0578702],
          [104.0563887, 2.0564366],
          [104.0551591, 2.0560953],
          [104.0551591, 2.0522724],
          [104.0576183, 2.048859],
          [104.0592351, 2.0458364],
          [104.0593679, 2.0455973],
          [104.0595685, 2.0446741],
          [104.0599558, 2.0438602],
          [104.0608388, 2.0422207],
          [104.0615518, 2.0409997],
          [104.0616898, 2.0403639],
          [104.0618488, 2.0402281],
          [104.0620414, 2.0400637],
          [104.0632825, 2.0387522],
          [104.0645574, 2.037294],
          [104.0693677, 2.0309047],
          [104.0733297, 2.0285154],
          [104.0764037, 2.0259212],
          [104.0788628, 2.0212107],
          [104.0809122, 2.016705],
          [104.0834396, 2.013906],
          [104.0850108, 2.0120628],
          [104.0868552, 2.0078984],
          [104.0891094, 2.004485],
          [104.0921834, 2.0005254],
          [104.0949841, 1.9996379],
          [104.09506762034539, 1.9995],
          [104.0965553, 1.9970437],
          [104.0972384, 1.9917869],
          [104.0974433, 1.98851],
          [104.1001074, 1.9847552],
          [104.1029081, 1.9813417],
          [104.1074849, 1.9750609],
          [104.1125399, 1.9698041],
          [104.1154089, 1.964479],
          [104.1175949, 1.9572424],
          [104.1195076, 1.9521903],
          [104.118278, 1.9498691],
          [104.1177994, 1.9494857],
          [104.1174201, 1.948409],
          [104.1173897, 1.9469381],
          [104.1178753, 1.9455733],
          [104.118467, 1.9450274],
          [104.1190284, 1.9450274],
          [104.1188463, 1.9441024],
          [104.1190132, 1.9436778],
          [104.1194988, 1.943511],
          [104.1199084, 1.943602],
          [104.1199539, 1.9434352],
          [104.1197112, 1.9432532],
          [104.1196505, 1.9429196],
          [104.1199388, 1.9424192],
          [104.1209857, 1.9416307],
          [104.1206519, 1.9411606],
          [104.1211171, 1.940094],
          [104.1216763, 1.9397438],
          [104.1220958, 1.9384544],
          [104.1225017, 1.9382064],
          [104.1231251, 1.9381732],
          [104.1227406, 1.9377359],
          [104.1228468, 1.9370308],
          [104.1235803, 1.9362625],
          [104.1245538, 1.9346652],
          [104.1247055, 1.9342861],
          [104.1248952, 1.9341799],
          [104.1249483, 1.9340434],
          [104.1253124, 1.9338236],
          [104.1257373, 1.9325725],
          [104.1265187, 1.9323905],
          [104.1296596, 1.9266473],
          [104.1296274, 1.9215861],
          [104.1288979, 1.9204066],
          [104.1281927, 1.9197789],
          [104.1257083, 1.920639],
          [104.1253573, 1.9217983],
          [104.1262033, 1.9226456],
          [104.126077, 1.9236735],
          [104.1249583, 1.924467],
          [104.1242185, 1.9250892],
          [104.1232622, 1.9252695],
          [104.1220713, 1.9253056],
          [104.1212571, 1.9251499],
          [104.1183272, 1.9248918],
          [104.1179954, 1.9252616],
          [104.1175361, 1.9255548],
          [104.1167578, 1.9249173],
          [104.1147788, 1.9246813],
          [104.1121819, 1.9230911],
          [104.1091071, 1.9205605],
          [104.106696, 1.9133326],
          [104.1050258, 1.9059021],
          [104.1043592, 1.9001536],
          [104.1047518, 1.8940498],
          [104.1058423, 1.8857226],
          [104.1087214, 1.8776569],
          [104.1119934, 1.8713557],
          [104.117263171685892, 1.863226318731904],
          [104.118278, 1.8616608],
          [104.1248358, 1.8533313],
          [104.12573141977191, 1.852462480759467],
          [104.1294809, 1.8488252],
          [104.1343993, 1.8458211],
          [104.1396832, 1.8443117],
          [104.1445541, 1.8434324],
          [104.1463376, 1.8436349],
          [104.1473865, 1.8437541],
          [104.1485131, 1.844065],
          [104.1487169, 1.8446655],
          [104.1485774, 1.8450087],
          [104.1503839, 1.8456845],
          [104.1511845, 1.8463598],
          [104.1516888, 1.8473571],
          [104.1514957, 1.8517858],
          [104.1507554, 1.8537374],
          [104.1503155, 1.8541556],
          [104.1500902, 1.8556032],
          [104.1507554, 1.8550993],
          [104.1520536, 1.8546382],
          [104.1526866, 1.8548955],
          [104.153341, 1.8544237],
          [104.1554389, 1.8534678],
          [104.156034, 1.8524828],
          [104.1566884, 1.8518287],
          [104.1573965, 1.8514319],
          [104.1576111, 1.8507349],
          [104.1581476, 1.850381],
          [104.1583514, 1.8498234],
          [104.1588235, 1.8495017],
          [104.1592204, 1.8495661],
          [104.1590595, 1.8491157],
          [104.1592634, 1.8486331],
          [104.1609263, 1.8464778],
          [104.1608727, 1.8460488],
          [104.1606581, 1.8454912],
          [104.1603792, 1.844998],
          [104.1593921, 1.8451588],
          [104.1587269, 1.8451588],
          [104.1583514, 1.8449443],
          [104.1582474, 1.8447718],
          [104.1578793, 1.8441615],
          [104.1575789, 1.8424029],
          [104.1575038, 1.8404406],
          [104.1576111, 1.8391752],
          [104.1585445, 1.8365266],
          [104.1587269, 1.8358724],
          [104.158963, 1.8355078],
          [104.1586411, 1.8345213],
          [104.1586733, 1.8330093],
          [104.1581261, 1.8329879],
          [104.1577506, 1.8325053],
          [104.1573, 1.8312828],
          [104.1570961, 1.8295778],
          [104.157536, 1.8281194],
          [104.1587806, 1.8261892],
          [104.1600466, 1.82457],
          [104.1606581, 1.8240553],
          [104.1607976, 1.8235942],
          [104.1610765, 1.8234441],
          [104.161495, 1.8234119],
          [104.1616666, 1.8227256],
          [104.1625678, 1.8214709],
          [104.1627824, 1.8205487],
          [104.1631365, 1.8200983],
          [104.1634154, 1.8190474],
          [104.1634262, 1.8177285],
          [104.1637802, 1.8167741],
          [104.1643948, 1.8153377],
          [104.1664718, 1.812272],
          [104.1677035, 1.8103891],
          [104.1709881, 1.8053923],
          [104.1731376, 1.8027128],
          [104.1752146, 1.800154],
          [104.1773883, 1.7981746],
          [104.1784509, 1.7967745],
          [104.1804072, 1.7939984],
          [104.1829914, 1.7909086],
          [104.1838608, 1.7902568],
          [104.1853824, 1.7883256],
          [104.1864692, 1.7871186],
          [104.1870005, 1.7852357],
          [104.187556, 1.7842943],
          [104.1875318, 1.783208],
          [104.1887153, 1.780794],
          [104.189633, 1.779225],
          [104.1904783, 1.7780904],
          [104.190744, 1.7775593],
          [104.1903576, 1.777511],
          [104.1906232, 1.7765213],
          [104.1911787, 1.7755557],
          [104.1916376, 1.7740108],
          [104.1917825, 1.7728762],
          [104.1924829, 1.7718623],
          [104.1929901, 1.7708243],
          [104.1936663, 1.7705105],
          [104.1933765, 1.7693759],
          [104.1935697, 1.7687965],
          [104.1945599, 1.7682413],
          [104.1954777, 1.7656825],
          [104.1960573, 1.7639927],
          [104.1970717, 1.7624719],
          [104.1982792, 1.7594785],
          [104.1990279, 1.7559058],
          [104.1985207, 1.7542643],
          [104.1978928, 1.751295],
          [104.197603, 1.7498225],
          [104.1977962, 1.7483499],
          [104.1984966, 1.7461773],
          [104.1994868, 1.7448738],
          [104.199994, 1.7439564],
          [104.1997283, 1.7429667],
          [104.2001389, 1.7421701],
          [104.2005978, 1.7407699],
          [104.2001872, 1.7394905],
          [104.2005736, 1.7382352],
          [104.2027472, 1.7350969],
          [104.2049209, 1.7318621],
          [104.206539, 1.7293515],
          [104.20765, 1.7280238],
          [104.2107655, 1.724958],
          [104.21149, 1.7235578],
          [104.2120455, 1.7230509],
          [104.2126976, 1.7219163],
          [104.2135671, 1.7210714],
          [104.2139293, 1.7200092],
          [104.2148229, 1.7189953],
          [104.2169483, 1.7158329],
          [104.2185181, 1.7138051],
          [104.2195566, 1.712936],
          [104.2203295, 1.7116807],
          [104.2203536, 1.7106909],
          [104.2203778, 1.7098943],
          [104.2194842, 1.7099667],
          [104.2184939, 1.7096529],
          [104.2176486, 1.7085907],
          [104.217383, 1.7076009],
          [104.2157165, 1.7066111],
          [104.2149437, 1.7048489],
          [104.2142433, 1.703159],
          [104.2141521, 1.7019841],
          [104.2140259, 1.7003587],
          [104.214509, 1.6975584],
          [104.2150403, 1.6959651],
          [104.216103, 1.6936717],
          [104.2177694, 1.6909196],
          [104.2195325, 1.6881434],
          [104.2220442, 1.684643],
          [104.2240246, 1.6813115],
          [104.2279371, 1.6758798],
          [104.2297727, 1.6731519],
          [104.2325742, 1.6693376],
          [104.236366, 1.6646783],
          [104.2385155, 1.6618538],
          [104.2404234, 1.6594397],
          [104.2422831, 1.6572428],
          [104.2437805, 1.6558426],
          [104.2447707, 1.6553598],
          [104.2459782, 1.6546597],
          [104.2477171, 1.6539596],
          [104.2475964, 1.6532353],
          [104.2476447, 1.6518834],
          [104.2480311, 1.6511109],
          [104.2487074, 1.6506281],
          [104.2487315, 1.6492762],
          [104.2491662, 1.6484312],
          [104.2488281, 1.6477311],
          [104.2479587, 1.6477311],
          [104.2470409, 1.6460171],
          [104.2465096, 1.6446651],
          [104.2459058, 1.642927],
          [104.2446016, 1.6432891],
          [104.2444726, 1.6429317],
          [104.2439795, 1.6420444],
          [104.2434636, 1.640505],
          [104.2433216, 1.6389677],
          [104.2435873, 1.6363122],
          [104.2441388, 1.6344763],
          [104.2448914, 1.6328841],
          [104.2453754, 1.6314732],
          [104.2458913, 1.6307073],
          [104.2478941, 1.6275906],
          [104.250398, 1.6244104],
          [104.2522942, 1.6221381],
          [104.2532237, 1.6212719],
          [104.2550109, 1.6190509],
          [104.2562212, 1.6176333],
          [104.2574849, 1.6163609],
          [104.2590429, 1.615179],
          [104.2596652, 1.6151951],
          [104.2598691, 1.6155007],
          [104.2602553, 1.6156723],
          [104.2605611, 1.6155115],
          [104.2605879, 1.6152594],
          [104.2599549, 1.6145462],
          [104.2596706, 1.6141387],
          [104.2596759, 1.613758],
          [104.2602285, 1.6138438],
          [104.2602446, 1.6136454],
          [104.2598033, 1.6133769],
          [104.2597577, 1.6131463],
          [104.2597845, 1.6128809],
          [104.2598757, 1.6125591],
          [104.2600501, 1.6122696],
          [104.2602888, 1.6118298],
          [104.2605811, 1.6115912],
          [104.2608574, 1.6114947],
          [104.2616284, 1.6116394],
          [104.2619423, 1.6109151],
          [104.2622253, 1.6107413],
          [104.2625391, 1.6105536],
          [104.262853, 1.6106046],
          [104.2632633, 1.6106019],
          [104.2633358, 1.6104812],
          [104.2635047, 1.6104303],
          [104.263722, 1.6104705],
          [104.2638668, 1.6105751],
          [104.2637891, 1.6104088],
          [104.2635423, 1.6102962],
          [104.2636442, 1.6101032],
          [104.2636362, 1.6099959],
          [104.2634752, 1.6099852],
          [104.2632768, 1.6100093],
          [104.2631024, 1.609937],
          [104.2630756, 1.6097654],
          [104.2632553, 1.6093337],
          [104.2633197, 1.609138],
          [104.2632472, 1.6090173],
          [104.2629576, 1.6090093],
          [104.2627645, 1.6090254],
          [104.2625418, 1.6088833],
          [104.2624104, 1.6086715],
          [104.2624828, 1.6083578],
          [104.2623943, 1.6081165],
          [104.2622843, 1.6079958],
          [104.2619356, 1.6077357],
          [104.2607635, 1.6068885],
          [104.2606857, 1.6067169],
          [104.2606992, 1.6066016],
          [104.260608, 1.6065239],
          [104.2602834, 1.6065667],
          [104.2600661, 1.6066472],
          [104.2598864, 1.6067705],
          [104.2598328, 1.6069073],
          [104.2598248, 1.6069716],
          [104.2597362, 1.6070011],
          [104.2595512, 1.6069716],
          [104.25902, 1.6067144],
          [104.2584404, 1.605966],
          [104.2575771, 1.6048615],
          [104.2573518, 1.6042502],
          [104.2571694, 1.6034083],
          [104.2571425, 1.6029043],
          [104.2571264, 1.6027381],
          [104.2570326, 1.6025477],
          [104.2568743, 1.6024217],
          [104.2568529, 1.602293],
          [104.2567939, 1.6022823],
          [104.256629, 1.6024654],
          [104.256315, 1.6033828],
          [104.2559012, 1.6032834],
          [104.2556512, 1.6032233],
          [104.2550907, 1.6028989],
          [104.2548063, 1.6026389],
          [104.2543262, 1.6019525],
          [104.2539695, 1.6012205],
          [104.2537925, 1.6005395],
          [104.2536047, 1.5996386],
          [104.2535296, 1.5988852],
          [104.2535698, 1.5984133],
          [104.2536557, 1.5981131],
          [104.2536208, 1.5978664],
          [104.2535886, 1.597609],
          [104.2531943, 1.5965901],
          [104.2530254, 1.5958877],
          [104.252961, 1.5953059],
          [104.2530093, 1.5948903],
          [104.2533927, 1.5934846],
          [104.2542622, 1.591336],
          [104.2562184, 1.5878836],
          [104.2582472, 1.5844555],
          [104.2599136, 1.5827655],
          [104.2611041, 1.5826311],
          [104.261411, 1.5825965],
          [104.2618432, 1.5831599],
          [104.2624713, 1.5832316],
          [104.2627345, 1.5830244],
          [104.2628962, 1.5832965],
          [104.2630695, 1.5829828],
          [104.2630616, 1.5821647],
          [104.2619242, 1.5804468],
          [104.2628038, 1.5790936],
          [104.2624188, 1.5791642],
          [104.2621733, 1.5781196],
          [104.2617385, 1.5790449],
          [104.2617705, 1.5793883],
          [104.2615021, 1.5796995],
          [104.2608032, 1.5798994],
          [104.2604967, 1.5802139],
          [104.2604691, 1.5805927],
          [104.2597929, 1.5804478],
          [104.2585611, 1.5796029],
          [104.2573053, 1.5785165],
          [104.2565566, 1.5770921],
          [104.2566049, 1.575885],
          [104.256146, 1.5751124],
          [104.255832, 1.5733259],
          [104.2559286, 1.5714186],
          [104.2555664, 1.5702839],
          [104.2549395, 1.5682772],
          [104.2545551, 1.5670466],
          [104.2543177, 1.5664495],
          [104.2541704, 1.5658197],
          [104.2541138, 1.5652507],
          [104.2541417, 1.5647303],
          [104.2542471, 1.5638806],
          [104.2544427, 1.5629913],
          [104.2549384, 1.5614237],
          [104.2556709, 1.5597055],
          [104.2566483, 1.5578503],
          [104.2579573, 1.5556295],
          [104.2606865, 1.5515977],
          [104.2626894, 1.5484728],
          [104.2648786, 1.5454263],
          [104.2667174, 1.5427981],
          [104.2689186, 1.5399936],
          [104.2698713, 1.539126],
          [104.2709723, 1.5381589],
          [104.2719254, 1.5374724],
          [104.2731503, 1.5370448],
          [104.2735777, 1.5367359],
          [104.2739481, 1.5359538],
          [104.2744559, 1.5351508],
          [104.2751968, 1.5341481],
          [104.2759543, 1.5335947],
          [104.2773694, 1.5330621],
          [104.2773664, 1.5328444],
          [104.2774898, 1.5324449],
          [104.2776266, 1.532343],
          [104.2777285, 1.5323108],
          [104.2778867, 1.5323082],
          [104.2778626, 1.5318845],
          [104.2779297, 1.5311204],
          [104.2780557, 1.5307262],
          [104.2782301, 1.5302972],
          [104.2783722, 1.5293159],
          [104.2785055, 1.528738],
          [104.2788658, 1.5276133],
          [104.2792734, 1.5270636],
          [104.2795765, 1.5267901],
          [104.2799386, 1.5260689],
          [104.2803597, 1.5256694],
          [104.2808345, 1.5253342],
          [104.281269, 1.5251975],
          [104.2814675, 1.5252055],
          [104.2815249, 1.5251866],
          [104.2814621, 1.5248891],
          [104.2814541, 1.5246076],
          [104.2815801, 1.5242617],
          [104.2816526, 1.5240258],
          [104.2818886, 1.5237871],
          [104.2820066, 1.5236155],
          [104.2820281, 1.5232267],
          [104.2822078, 1.5226851],
          [104.2824358, 1.5223339],
          [104.2826128, 1.5222561],
          [104.2828327, 1.5223285],
          [104.2828917, 1.522291],
          [104.2827818, 1.5220818],
          [104.2825645, 1.5218513],
          [104.2824867, 1.5216502],
          [104.2825377, 1.5213338],
          [104.2826182, 1.5211166],
          [104.2827335, 1.521012],
          [104.2827469, 1.5206903],
          [104.2828783, 1.5202774],
          [104.2830634, 1.5197867],
          [104.283286, 1.5193148],
          [104.2831118, 1.5189671],
          [104.2831231, 1.5186429],
          [104.2834854, 1.5177979],
          [104.2840124, 1.5171725],
          [104.2839947, 1.516384],
          [104.2841831, 1.515625],
          [104.2846187, 1.5148777],
          [104.2849586, 1.5144661],
          [104.2851131, 1.5141422],
          [104.2850483, 1.5136656],
          [104.2845951, 1.5134891],
          [104.2844421, 1.5132832],
          [104.2846069, 1.5124535],
          [104.2847069, 1.5115121],
          [104.2844656, 1.5111061],
          [104.2846245, 1.5098998],
          [104.2847423, 1.5093114],
          [104.284548, 1.5090525],
          [104.2840418, 1.509082],
          [104.2836416, 1.5088701],
          [104.283099, 1.5074647],
          [104.2826642, 1.5067887],
          [104.2824761, 1.5064165],
          [104.2824114, 1.505781],
          [104.2821995, 1.5055692],
          [104.2819111, 1.5056163],
          [104.2812635, 1.5065231],
          [104.2811263, 1.5079168],
          [104.2809182, 1.5083662],
          [104.2799834, 1.5085752],
          [104.2788242, 1.5083821],
          [104.2773751, 1.5075854],
          [104.2757569, 1.5060644],
          [104.2743042, 1.5035845],
          [104.2741871, 1.5033845],
          [104.2735734, 1.5016231],
          [104.2729353, 1.4988299],
          [104.272581, 1.4949413],
          [104.2728937, 1.4918983],
          [104.2736679, 1.4876877],
          [104.2751881, 1.482366],
          [104.276084, 1.4799796],
          [104.277012, 1.477604],
          [104.2784876, 1.4743818],
          [104.2798284, 1.4720644],
          [104.2808315, 1.4699515],
          [104.2815021, 1.4687663],
          [104.2815405, 1.4686723],
          [104.2821511, 1.467179],
          [104.2834386, 1.4644494],
          [104.2846563, 1.4622668],
          [104.2858901, 1.4602075],
          [104.2872259, 1.4582877],
          [104.2915843, 1.4514074],
          [104.2941579, 1.4476819],
          [104.294748, 1.4469016],
          [104.2948411, 1.4469053],
          [104.2950833, 1.446915],
          [104.2952683, 1.4467595],
          [104.2951633, 1.4466934],
          [104.2950819, 1.4464918],
          [104.2949546, 1.44604],
          [104.2950323, 1.4456843],
          [104.2951987, 1.4453844],
          [104.2950784, 1.445027],
          [104.2947988, 1.4446272],
          [104.294595, 1.4442584],
          [104.2944484, 1.4438276],
          [104.2942892, 1.4434066],
          [104.2941582, 1.4426459],
          [104.2940697, 1.4417897],
          [104.2940945, 1.4412307],
          [104.294229, 1.4404381],
          [104.2945086, 1.4390512],
          [104.294912, 1.4376431],
          [104.2952467, 1.4366148],
          [104.2956057, 1.4354494],
          [104.2956801, 1.4352301],
          [104.2958482, 1.435207],
          [104.2961397, 1.4351123],
          [104.2963095, 1.4349016],
          [104.2963977, 1.4343471],
          [104.2959012, 1.4338671],
          [104.2944634, 1.4337353],
          [104.2939067, 1.4339396],
          [104.2927552, 1.4355223],
          [104.2914367, 1.4370875],
          [104.2901417, 1.4381466],
          [104.2890587, 1.4387233],
          [104.2878697, 1.4387233],
          [104.2867514, 1.4382408],
          [104.2794668, 1.433678],
          [104.2774357, 1.4312148],
          [104.2761705, 1.4284521],
          [104.2746218, 1.4213447],
          [104.2741148, 1.4147279],
          [104.2742926, 1.4090899],
          [104.2755165, 1.402633],
          [104.2771409, 1.3943835],
          [104.2771821, 1.3941435],
          [104.2789464, 1.3838689],
          [104.2796045, 1.3817837],
          [104.2801637, 1.3808481],
          [104.2809465, 1.379936],
          [104.2815705, 1.3794594],
          [104.2817294, 1.3800302],
          [104.2820121, 1.3802755],
          [104.2826653, 1.3805539],
          [104.2832186, 1.3803303],
          [104.2835717, 1.3797006],
          [104.2833363, 1.3792652],
          [104.2830537, 1.3788945],
          [104.2831362, 1.3787591],
          [104.2833951, 1.3787827],
          [104.2835541, 1.3786238],
          [104.283454, 1.378259],
          [104.283095, 1.3781472],
          [104.282836, 1.3779177],
          [104.2826476, 1.3775175],
          [104.2823945, 1.3758817],
          [104.2824769, 1.3735985],
          [104.2826888, 1.3723569],
          [104.2828831, 1.3719097],
          [104.2830891, 1.371845],
          [104.2831715, 1.371439],
          [104.2830655, 1.3712095],
          [104.2827477, 1.3711742],
          [104.2825063, 1.3710329],
          [104.2826123, 1.3706387],
          [104.2828889, 1.3704033],
          [104.2829213, 1.3701267],
          [104.2831067, 1.3700296],
          [104.2831067, 1.3699796],
          [104.2829037, 1.3699149],
          [104.2825887, 1.3699973],
          [104.2822944, 1.3699061],
          [104.2818721, 1.3698914],
          [104.2818013, 1.3697791],
          [104.2813144, 1.3697832],
          [104.2812954, 1.3698998],
          [104.2814161, 1.3700263],
          [104.2814279, 1.3701735],
          [104.2813661, 1.3702853],
          [104.2811689, 1.3704412],
          [104.280904, 1.3705912],
          [104.2806538, 1.3705236],
          [104.28074, 1.3703117],
          [104.2802447, 1.3702243],
          [104.2791277, 1.3695076],
          [104.2783744, 1.3687711],
          [104.2770217, 1.3668987],
          [104.2765348, 1.3661581],
          [104.276052, 1.3651137],
          [104.2754776, 1.365172],
          [104.2751113, 1.3648724],
          [104.2750447, 1.3645936],
          [104.2748075, 1.364527],
          [104.2743164, 1.3643855],
          [104.2740893, 1.363945],
          [104.2735483, 1.3641031],
          [104.2731763, 1.3640061],
          [104.2729291, 1.3635471],
          [104.2719638, 1.3634765],
          [104.2713987, 1.3637471],
          [104.2715635, 1.3643121],
          [104.2712692, 1.3648652],
          [104.2695976, 1.3648652],
          [104.2686911, 1.3644062],
          [104.266582, 1.3625854],
          [104.2662896, 1.3621819],
          [104.2662259, 1.3618381],
          [104.265805, 1.3616351],
          [104.2653518, 1.3616498],
          [104.2642423, 1.361029],
          [104.2623856, 1.3601336],
          [104.2613617, 1.3589624],
          [104.2524018, 1.3592194],
          [104.2524435, 1.3605342],
          [104.2526516, 1.3607922],
          [104.2528019, 1.3623889],
          [104.2529058, 1.3639741],
          [104.2529108, 1.3649199],
          [104.2529158, 1.3658382],
          [104.252843, 1.3659517],
          [104.2526033, 1.3661042],
          [104.252518, 1.3665049],
          [104.2523809, 1.3668796],
          [104.2525291, 1.3671817],
          [104.2525994, 1.3674319],
          [104.2527, 1.3677555],
          [104.2527287, 1.3679909],
          [104.2526795, 1.368209],
          [104.252496, 1.3684596],
          [104.2522433, 1.3686237],
          [104.2519202, 1.3688072],
          [104.2514531, 1.3690338],
          [104.2509481, 1.3692148],
          [104.2504507, 1.3693959],
          [104.2504004, 1.3691437],
          [104.2502743, 1.3689826],
          [104.2491931, 1.3692799],
          [104.2480892, 1.3692978],
          [104.2454671, 1.3690398],
          [104.2431447, 1.3683325],
          [104.2401795, 1.3669391],
          [104.2387021, 1.3659228],
          [104.2374674, 1.3648662],
          [104.2360098, 1.3634946],
          [104.2361386, 1.3632774],
          [104.2347609, 1.3616877],
          [104.2338784, 1.3604287],
          [104.2326639, 1.3586073],
          [104.2319312, 1.3579573],
          [104.2315476, 1.3572553],
          [104.2307086, 1.3551514],
          [104.2302907, 1.3547433],
          [104.2288456, 1.3542106],
          [104.2274887, 1.3533706],
          [104.2260053, 1.3518623],
          [104.2251866, 1.3505134],
          [104.2250852, 1.3496389],
          [104.2250821, 1.3491879],
          [104.225332, 1.3489221],
          [104.2257456, 1.3490233],
          [104.2262914, 1.3488541],
          [104.2265098, 1.3482522],
          [104.2263117, 1.3479277],
          [104.2255526, 1.3476716],
          [104.2247599, 1.3475956],
          [104.2234887, 1.3472436],
          [104.2228459, 1.3470888],
          [104.2224035, 1.3479468],
          [104.2220991, 1.3478749],
          [104.2219035, 1.3480549],
          [104.2220102, 1.3484774],
          [104.2221256, 1.3488568],
          [104.2219518, 1.3492237],
          [104.221359, 1.3495006],
          [104.2207356, 1.3496201],
          [104.2202329, 1.3495848],
          [104.2198058, 1.3495232],
          [104.2197331, 1.3495989],
          [104.2197517, 1.3498828],
          [104.2198666, 1.3501859],
          [104.2196922, 1.3503114],
          [104.2195565, 1.3503553],
          [104.2193322, 1.3504279],
          [104.2186901, 1.3506087],
          [104.2175922, 1.3505561],
          [104.2156713, 1.3498878],
          [104.2143732, 1.3491248],
          [104.2036783, 1.3389577],
          [104.202489, 1.3380371],
          [104.2014769, 1.3375496],
          [104.2010894, 1.3370612],
          [104.2007271, 1.337103],
          [104.2004027, 1.3373635],
          [104.199767, 1.3377201],
          [104.2000219, 1.3384844],
          [104.2004261, 1.339038],
          [104.2004654, 1.3397011],
          [104.1994264, 1.3406216],
          [104.197299, 1.3415554],
          [104.1969085, 1.3420868],
          [104.1972982, 1.3425067],
          [104.1978539, 1.3429709],
          [104.1977775, 1.3436493],
          [104.1975842, 1.344447],
          [104.1966364, 1.3452695],
          [104.1952618, 1.3459483],
          [104.1943593, 1.3461583],
          [104.193463, 1.3462373],
          [104.1923839, 1.3462763],
          [104.1911782, 1.346069],
          [104.1900799, 1.3458505],
          [104.1899404, 1.3456899],
          [104.1894493, 1.3454947],
          [104.1890775, 1.3454147],
          [104.1888522, 1.3454091],
          [104.1885587, 1.3458824],
          [104.1885409, 1.3460823],
          [104.1886537, 1.3461792],
          [104.1891996, 1.3463565],
          [104.1893752, 1.3466557],
          [104.1893877, 1.3471027],
          [104.1891572, 1.3475361],
          [104.1889289, 1.347812],
          [104.1887742, 1.3478612],
          [104.1883788, 1.3479871],
          [104.1873799, 1.3486889],
          [104.1865723, 1.3489701],
          [104.1851693, 1.3492012],
          [104.1834527, 1.3490763],
          [104.1820032, 1.3488694],
          [104.1819411, 1.348632],
          [104.1853195, 1.3481111],
          [104.1884146, 1.3469352],
          [104.1874649, 1.3406482],
          [104.1878431, 1.3405789],
          [104.1875984, 1.3385566],
          [104.1794913, 1.3395635],
          [104.1732314, 1.3403409],
          [104.1680745, 1.3409814],
          [104.1653843, 1.3413134],
          [104.1649755, 1.3413639],
          [104.1623677, 1.3416857],
          [104.1621236, 1.3420229],
          [104.1615334, 1.3421345],
          [104.1610495, 1.342193],
          [104.1606773, 1.3425598],
          [104.1607305, 1.3430063],
          [104.1605709, 1.3434422],
          [104.1601379, 1.3436778],
          [104.1600871, 1.3441386],
          [104.1599009, 1.3445851],
          [104.1593625, 1.3449876],
          [104.1592722, 1.3458972],
          [104.1583548, 1.3468595],
          [104.1572569, 1.34707],
          [104.154404, 1.3468601],
          [104.1496722, 1.3457423],
          [104.1434484, 1.3430419],
          [104.1412138, 1.3415012],
          [104.13956, 1.3413179],
          [104.1385587, 1.3412211],
          [104.1382736, 1.3405255],
          [104.1385488, 1.3398672],
          [104.1384643, 1.3396432],
          [104.1383418, 1.339676],
          [104.137563, 1.33777],
          [104.1371857, 1.3379322],
          [104.1377949, 1.3395266],
          [104.1375562, 1.3396154],
          [104.137658, 1.3399143],
          [104.1375791, 1.339952],
          [104.1380566, 1.3413254],
          [104.1369194, 1.3417377],
          [104.136361, 1.3404785],
          [104.1362883, 1.3404985],
          [104.1361645, 1.3401986],
          [104.1358844, 1.3402816],
          [104.1352266, 1.3387242],
          [104.1349193, 1.3388773],
          [104.1357031, 1.3407604],
          [104.1356121, 1.3407873],
          [104.1356577, 1.3409714],
          [104.1362367, 1.3412256],
          [104.1366142, 1.3421242],
          [104.1359118, 1.3424552],
          [104.1351769, 1.3421279],
          [104.1346828, 1.3429038],
          [104.1351844, 1.343397],
          [104.1355884, 1.3435814],
          [104.1350787, 1.3446567],
          [104.1360701, 1.3452418],
          [104.1357479, 1.3461662],
          [104.1331062, 1.3495407],
          [104.1306166, 1.3511383],
          [104.1304524, 1.3515144],
          [104.1307287, 1.3520425],
          [104.1311761, 1.352685],
          [104.1311917, 1.3532765],
          [104.1308146, 1.3539174],
          [104.1298828, 1.3547444],
          [104.1279816, 1.3556437],
          [104.1267843, 1.3562867],
          [104.12573141977191, 1.356922214346731],
          [104.1257109, 1.3569346],
          [104.1241883, 1.3575122],
          [104.1229613, 1.3579151],
          [104.1216152, 1.3583063],
          [104.1208167, 1.3586051],
          [104.1194047, 1.3591399],
          [104.1186394, 1.3594838],
          [104.1185372, 1.3595062],
          [104.1183378, 1.3594838],
          [104.118243, 1.3595112],
          [104.116078, 1.3604868],
          [104.1133597, 1.3617042],
          [104.1131899, 1.3617574],
          [104.1127341, 1.3618837],
          [104.1123826, 1.3620132],
          [104.111141, 1.3625051],
          [104.1110016, 1.3625241],
          [104.1107523, 1.3625366],
          [104.1104632, 1.3625241],
          [104.1102837, 1.3624967],
          [104.110169, 1.3624718],
          [104.1100643, 1.3625191],
          [104.1100242, 1.3625693],
          [104.1099995, 1.3626387],
          [104.1100244, 1.3627235],
          [104.1100843, 1.3627609],
          [104.110184, 1.3629552],
          [104.110169, 1.3630873],
          [104.1100967, 1.3631496],
          [104.1100917, 1.3632917],
          [104.1100045, 1.3634262],
          [104.1097328, 1.3635957],
          [104.1095433, 1.3637876],
          [104.1093881, 1.3638554],
          [104.1091719, 1.3639745],
          [104.1089451, 1.3641788],
          [104.1079288, 1.3646299],
          [104.1058759, 1.3652358],
          [104.1053929, 1.3654423],
          [104.1051735, 1.3655071],
          [104.1049891, 1.365542],
          [104.104832, 1.3655445],
          [104.104675, 1.3655445],
          [104.1045553, 1.365547],
          [104.1040967, 1.3656716],
          [104.1029076, 1.3660603],
          [104.1011551, 1.3664875],
          [104.1003376, 1.3667357],
          [104.0996546, 1.3669176],
          [104.0985976, 1.3671568],
          [104.0968612, 1.3677584],
          [104.0964725, 1.3678436],
          [104.0961887, 1.3679141],
          [104.0959578, 1.3679529],
          [104.0956881, 1.3680339],
          [104.0950962, 1.3682293],
          [104.0948473, 1.3683036],
          [104.0946428, 1.3683529],
          [104.0944965, 1.3683635],
          [104.094389, 1.368337],
          [104.0940717, 1.3683494],
          [104.0939924, 1.3683724],
          [104.093661, 1.3684992],
          [104.0934813, 1.3685963],
          [104.0932056, 1.3687099],
          [104.0928749, 1.3687644],
          [104.0920671, 1.3687799],
          [104.0911869, 1.368741],
          [104.0907684, 1.3686404],
          [104.0904169, 1.3684696],
          [104.09021, 1.3683089],
          [104.0900106, 1.3681519],
          [104.089876, 1.368132],
          [104.0896192, 1.3681307],
          [104.0893234, 1.3682049],
          [104.0890358, 1.3683797],
          [104.088923, 1.3684752],
          [104.0888438, 1.368426],
          [104.0886845, 1.3686504],
          [104.0885433, 1.3686765],
          [104.0885479, 1.3687536],
          [104.0882032, 1.3688287],
          [104.0880226, 1.3690815],
          [104.0885054, 1.3694149],
          [104.0885263, 1.3694808],
          [104.0891318, 1.3699043],
          [104.0892043, 1.3699816],
          [104.0892584, 1.369987],
          [104.0895098, 1.3701538],
          [104.0895413, 1.3701745],
          [104.0895827, 1.3702014],
          [104.0896377, 1.3701361],
          [104.0896963, 1.3701474],
          [104.0897263, 1.3702249],
          [104.0901875, 1.3705534],
          [104.0903106, 1.3708241],
          [104.0903772, 1.3710329],
          [104.0903406, 1.3710928],
          [104.0903326, 1.3711606],
          [104.0903696, 1.3712681],
          [104.0905609, 1.3716161],
          [104.0905823, 1.3716964],
          [104.0906076, 1.3717236],
          [104.0906728, 1.3721184],
          [104.0906843, 1.3723483],
          [104.0906519, 1.3724749],
          [104.0906772, 1.3725466],
          [104.0909504, 1.373008],
          [104.0914503, 1.3732207],
          [104.0918142, 1.3735358],
          [104.0919898, 1.3738001],
          [104.0920908, 1.3741404],
          [104.0922904, 1.3743352],
          [104.0924236, 1.3745533],
          [104.0926385, 1.3748982],
          [104.0926804, 1.3751827],
          [104.092621, 1.3757122],
          [104.0924911, 1.3760623],
          [104.0924264, 1.3761171],
          [104.0924354, 1.3763051],
          [104.0921289, 1.3765252],
          [104.0923928, 1.3769872],
          [104.0924233, 1.3771515],
          [104.0924914, 1.377459],
          [104.0925877, 1.3774285],
          [104.0927221, 1.3774452],
          [104.0932458, 1.3771196],
          [104.0940242, 1.3771071],
          [104.0942472, 1.3771428],
          [104.0946325, 1.3772789],
          [104.0949372, 1.3775046],
          [104.095122, 1.3776634],
          [104.095313, 1.377867],
          [104.0954475, 1.3779991],
          [104.0955282, 1.3781501],
          [104.0955438, 1.3784859],
          [104.0954475, 1.3786785],
          [104.0953798, 1.3788243],
          [104.0954163, 1.3789388],
          [104.0955541, 1.3790328],
          [104.0957287, 1.3791627],
          [104.0958511, 1.3792929],
          [104.09595, 1.3795297],
          [104.0960099, 1.379764],
          [104.0960932, 1.3799072],
          [104.0965245, 1.3806744],
          [104.0967728, 1.3810369],
          [104.0970972, 1.3813415],
          [104.0972233, 1.3814508],
          [104.0973404, 1.381495],
          [104.0975149, 1.3814247],
          [104.0976998, 1.3813909],
          [104.0984068, 1.3814567],
          [104.0985564, 1.3814872],
          [104.0989131, 1.3817007],
          [104.0991813, 1.3819349],
          [104.0994888, 1.382307],
          [104.0996995, 1.3826013],
          [104.0998505, 1.38301],
          [104.1000312, 1.3836505],
          [104.1001161, 1.3837102],
          [104.100629, 1.3837388],
          [104.1007748, 1.3836789],
          [104.1011482, 1.3837478],
          [104.1014716, 1.3838855],
          [104.1022007, 1.3845261],
          [104.1028562, 1.3851592],
          [104.1036369, 1.3857479],
          [104.1044211, 1.386871],
          [104.1048925, 1.3873348],
          [104.1051576, 1.3877214],
          [104.1064221, 1.3890057],
          [104.1070393, 1.3895325],
          [104.1079502, 1.3899476],
          [104.1090756, 1.3898307],
          [104.1095727, 1.3898233],
          [104.1101051, 1.3900113],
          [104.1109793, 1.3904197],
          [104.1121795, 1.3911646],
          [104.1121402, 1.3920251],
          [104.1122079, 1.3927591],
          [104.1123797, 1.393212],
          [104.1126422, 1.3939199],
          [104.1090914, 1.4005772],
          [104.1065422, 1.4053565],
          [104.1063624, 1.405463],
          [104.1060454, 1.4059961],
          [104.1058497, 1.4065228],
          [104.1059335, 1.4066116],
          [104.1059588, 1.4067147],
          [104.1059157, 1.4068559],
          [104.1057273, 1.4072314],
          [104.105541, 1.4077342],
          [104.1054665, 1.407854],
          [104.1052896, 1.4083168],
          [104.1051751, 1.4086161],
          [104.1052066, 1.40883],
          [104.1049968, 1.4092227],
          [104.1047408, 1.4096107],
          [104.1043129, 1.4099676],
          [104.1037501, 1.410122],
          [104.1030684, 1.4115906],
          [104.102998, 1.4117421],
          [104.1028329, 1.4120976],
          [104.1031468, 1.4136187],
          [104.1030985, 1.4153329],
          [104.1026132, 1.4170973],
          [104.1025672, 1.4172644],
          [104.1017944, 1.4183026],
          [104.1009657, 1.4188675],
          [104.1003501, 1.4192871],
          [104.1003333, 1.4192958],
          [104.1003073, 1.4193162],
          [104.1002004, 1.4193891],
          [104.0989445, 1.4208619],
          [104.0969685, 1.4221097],
          [104.0949382, 1.4233141],
          [104.0945143, 1.4249422],
          [104.0935104, 1.4267488],
          [104.0919041, 1.4289345],
          [104.0909224, 1.4298266],
          [104.0866349, 1.4324963],
          [104.0853039, 1.4334204],
          [104.0851314, 1.4336544],
          [104.0851067, 1.4339378],
          [104.0852423, 1.4341349],
          [104.0855011, 1.4342951],
          [104.0859694, 1.4343937],
          [104.0863638, 1.4344306],
          [104.0871649, 1.434443],
          [104.0874483, 1.4350097],
          [104.0866965, 1.434985],
          [104.086068, 1.4349604],
          [104.0857229, 1.4349727],
          [104.0855257, 1.4348865],
          [104.0852916, 1.4348372],
          [104.0850698, 1.4348372],
          [104.0847, 1.4347633],
          [104.0845521, 1.434714],
          [104.0844412, 1.434714],
          [104.0841178, 1.4349787],
          [104.0804812, 1.4371867],
          [104.0764208, 1.4397515],
          [104.0725165, 1.4422718],
          [104.0678537, 1.4437215],
          [104.0638155, 1.4442345],
          [104.0626108, 1.4442791],
          [104.0619855, 1.4444655],
          [104.0613391, 1.4446582],
          [104.0599559, 1.4453942],
          [104.0589519, 1.446264],
          [104.0587734, 1.4476022],
          [104.0592196, 1.4490519],
          [104.0600451, 1.4507916],
          [104.0622315, 1.4525535],
          [104.0637263, 1.4535794],
          [104.0639924, 1.4539009],
          [104.0652291, 1.4553948],
          [104.0653326, 1.4555198],
          [104.0664705, 1.4576163],
          [104.0672959, 1.4603149],
          [104.0683891, 1.4626121],
          [104.0694154, 1.4635042],
          [104.0708879, 1.4640172],
          [104.0727173, 1.4639503],
          [104.0742344, 1.463705],
          [104.0757515, 1.4637719],
          [104.0774024, 1.4646194],
          [104.0779379, 1.4658237],
          [104.078161, 1.4676749],
          [104.0783618, 1.469526],
          [104.0776032, 1.4710872],
          [104.0761531, 1.4720908],
          [104.0746806, 1.4734067],
          [104.0738328, 1.4748118],
          [104.0731635, 1.4760384],
          [104.073052, 1.4773989],
          [104.0736543, 1.4790047],
          [104.075283, 1.4798968],
          [104.0761308, 1.4806328],
          [104.0761754, 1.4819264],
          [104.0758407, 1.4835322],
          [104.075283, 1.4843797],
          [104.0743683, 1.4845135],
          [104.0731412, 1.4839336],
          [104.0718918, 1.4833314],
          [104.0710217, 1.4835322],
          [104.0706871, 1.4842235],
          [104.0705086, 1.4849149],
          [104.0711779, 1.4856955],
          [104.0721372, 1.4862531],
          [104.0729181, 1.4869445],
          [104.073342, 1.487792],
          [104.0723603, 1.4891524],
          [104.071111, 1.4899107],
          [104.0697054, 1.490669],
          [104.068523, 1.4909366],
          [104.0675413, 1.4907582],
          [104.0669836, 1.489933],
          [104.066939, 1.4887956],
          [104.0671398, 1.4869668],
          [104.066939, 1.4860524],
          [104.0660242, 1.4861416],
          [104.065578, 1.4865876],
          [104.0655557, 1.4872567],
          [104.0653773, 1.4885057],
          [104.0648641, 1.4895539],
          [104.0640386, 1.4899999],
          [104.0628785, 1.4900891],
          [104.0610045, 1.4899776],
          [104.058829, 1.494171],
          [104.0594294, 1.4962202],
          [104.0591985, 1.4987368],
          [104.0570603, 1.4999206],
          [104.0564998, 1.5008253],
          [104.0553333, 1.5006112],
          [104.0503941, 1.4988821],
          [104.0451817, 1.4970574],
          [104.0450541, 1.496558],
          [104.0445388, 1.4962702],
          [104.0442882, 1.495895],
          [104.0439905, 1.4957687],
          [104.0432307, 1.4956485],
          [104.0427211, 1.4956868],
          [104.0417403, 1.4959114],
          [104.0414061, 1.495769],
          [104.0408636, 1.4957033],
          [104.0404526, 1.4958512],
          [104.040094, 1.4964412],
          [104.0396515, 1.4989758],
          [104.039481, 1.5000758],
          [104.0389385, 1.5008504],
          [104.0387678, 1.5013874],
          [104.0388453, 1.502224],
          [104.0390158, 1.5030606],
          [104.0389383, 1.5035563],
          [104.0384733, 1.5044549],
          [104.0380394, 1.5047648],
          [104.0378224, 1.5058028],
          [104.0378089, 1.5066857],
          [104.0378147, 1.5073288],
          [104.0379697, 1.5076541],
          [104.0381634, 1.507871],
          [104.0381193, 1.5081379],
          [104.0380728, 1.5083461],
          [104.0379659, 1.5085816],
          [104.037881, 1.5086117],
          [104.037681, 1.5086008],
          [104.0376015, 1.508535],
          [104.0376508, 1.5083817],
          [104.0376481, 1.5082584],
          [104.0375604, 1.5082064],
          [104.0371046, 1.5082221],
          [104.0366911, 1.508452],
          [104.0361564, 1.5085372],
          [104.0356992, 1.5084288],
          [104.035583, 1.5087231],
          [104.0356062, 1.5090872],
          [104.0351878, 1.5097689],
          [104.0349785, 1.5100555],
          [104.0346221, 1.5102801],
          [104.0345834, 1.5106597],
          [104.0346966, 1.5109171],
          [104.0346608, 1.5111864],
          [104.0344129, 1.5114886],
          [104.0345911, 1.5118836],
          [104.0347151, 1.512573],
          [104.0348468, 1.5134871],
          [104.0348236, 1.5156715],
          [104.0345291, 1.5166011],
          [104.0349011, 1.5173912],
          [104.035087, 1.5184137],
          [104.0344206, 1.5210939],
          [104.0337542, 1.5215432],
          [104.0339402, 1.5225347],
          [104.0340332, 1.5232784],
          [104.0337697, 1.5241304],
          [104.0330665, 1.52476],
          [104.0330103, 1.5258346],
          [104.031561, 1.5283621],
          [104.0297091, 1.5318253],
          [104.0293751, 1.5317539],
          [104.029348, 1.5318604],
          [104.0296967, 1.5319495],
          [104.0297083, 1.531996],
          [104.0298885, 1.532089],
          [104.0294836, 1.5334213],
          [104.0293868, 1.5334426],
          [104.0293151, 1.5339287],
          [104.0294371, 1.5340681],
          [104.0294952, 1.534192],
          [104.0294797, 1.5343838],
          [104.0293403, 1.5346394],
          [104.0292395, 1.5346936],
          [104.0292647, 1.5349124],
          [104.0294701, 1.534864],
          [104.0295398, 1.534955],
          [104.0296018, 1.5351506],
          [104.0296018, 1.5353578],
          [104.0295785, 1.5354857],
          [104.0293035, 1.5358962],
          [104.0292415, 1.5360531],
          [104.0294099, 1.5360647],
          [104.0295209, 1.5360798],
          [104.0295058, 1.5362373],
          [104.0293757, 1.5362345],
          [104.0292565, 1.5362715],
          [104.0292044, 1.5363879],
          [104.0292493, 1.5365465],
          [104.0290904, 1.5369687],
          [104.0292054, 1.5375313],
          [104.0302074, 1.5387382],
          [104.030928, 1.5398769],
          [104.0310288, 1.5402874],
          [104.0310133, 1.5406863],
          [104.0307033, 1.5414338],
          [104.0308002, 1.5419993],
          [104.0331115, 1.5474655],
          [104.0333032, 1.5548588],
          [104.0296986, 1.561181],
          [104.0286159, 1.5620077],
          [104.025881757432529, 1.564565253444016],
          [104.0225326, 1.5676981],
          [104.0203652, 1.5767112],
          [104.0183723, 1.5782053],
          [104.0181041, 1.5790526],
          [104.0174711, 1.5793421],
          [104.0165913, 1.5797389],
          [104.0164626, 1.5804039],
          [104.0163124, 1.5812297],
          [104.015615, 1.5820233],
          [104.0149176, 1.5826561],
          [104.0136194, 1.5834283],
          [104.0117955, 1.5855196],
          [104.0118277, 1.5861202],
          [104.0120423, 1.5867315],
          [104.0125036, 1.5875895],
          [104.0126967, 1.5888121],
          [104.0122648, 1.588956],
          [104.012053, 1.5890266],
          [104.011699, 1.5891016],
          [104.0098536, 1.5901419],
          [104.0087378, 1.5911286],
          [104.0076542, 1.5925014],
          [104.0055942, 1.5929733],
          [104.0054548, 1.5933057],
          [104.0042961, 1.5935631],
          [104.0029549, 1.5942817],
          [104.002912, 1.5945927],
          [104.0032017, 1.5948286],
          [104.0030193, 1.5948715],
          [104.0027726, 1.594775],
          [104.0023649, 1.5944962],
          [104.0015924, 1.5946249],
          [104.00050017139624, 1.594798149633973],
          [104.00050017139624, 1.594798149633973],
          [103.998009, 1.5951933],
          [103.9966571, 1.5954721],
          [103.9966464, 1.5957402],
          [103.9970326, 1.5965124],
          [103.9976549, 1.5969521],
          [103.9977086, 1.5971237],
          [103.9976227, 1.5973168],
          [103.997556, 1.5973616],
          [103.9970004, 1.597735],
          [103.9967859, 1.5989147],
          [103.996464, 1.5991507],
          [103.9961717, 1.5994107],
          [103.9958256, 1.600073],
          [103.9957836, 1.6000482],
          [103.9957345, 1.6000194],
          [103.9960885, 1.5989898],
          [103.9960778, 1.5985823],
          [103.9956701, 1.5985608],
          [103.9935565, 1.5993652],
          [103.9931917, 1.6003733],
          [103.9932239, 1.6011133],
          [103.9928806, 1.6010811],
          [103.9920759, 1.6010489],
          [103.9906382, 1.6025075],
          [103.9894366, 1.6051243],
          [103.9873338, 1.6071834],
          [103.9854884, 1.6095214],
          [103.9833212, 1.6117307],
          [103.9812291, 1.6141222],
          [103.9799732, 1.6161375],
          [103.9794027, 1.6164634],
          [103.9786635, 1.6168856],
          [103.9765123, 1.6195424],
          [103.9753282, 1.6233239],
          [103.9724629, 1.6234674],
          [103.9677322, 1.6237529],
          [103.9664018, 1.6223909],
          [103.9659834, 1.6221764],
          [103.9655328, 1.6221549],
          [103.9657044, 1.6215222],
          [103.9654105, 1.6210468],
          [103.9646035, 1.6182997],
          [103.9638168, 1.6153855],
          [103.9640264, 1.6122079],
          [103.9640737, 1.6095375],
          [103.9643419, 1.6043092],
          [103.9642131, 1.604084],
          [103.9640415, 1.6039446],
          [103.9638054, 1.6040197],
          [103.9636338, 1.604202],
          [103.9634567, 1.6047382],
          [103.9629847, 1.603891],
          [103.9629149, 1.6035424],
          [103.9627486, 1.6033386],
          [103.9625233, 1.603344],
          [103.9622176, 1.6035317],
          [103.9621639, 1.6032582],
          [103.9628398, 1.6031241],
          [103.963033, 1.6030276],
          [103.963033, 1.6028185],
          [103.9630383, 1.6027112],
          [103.9633441, 1.6028989],
          [103.963623, 1.6029311],
          [103.963784, 1.6028024],
          [103.9641166, 1.6024539],
          [103.9651951, 1.6009575],
          [103.9660348, 1.5993768],
          [103.9660639, 1.598786],
          [103.966198, 1.5987699],
          [103.9662409, 1.5986788],
          [103.966155, 1.5984536],
          [103.9660424, 1.5981318],
          [103.9661014, 1.5977779],
          [103.9662409, 1.5975152],
          [103.9665842, 1.5972095],
          [103.9666003, 1.5968341],
          [103.9664608, 1.5965124],
          [103.9661819, 1.5964641],
          [103.9660853, 1.5954292],
          [103.9663213, 1.5952522],
          [103.9665949, 1.5949573],
          [103.966788, 1.5944586],
          [103.9666184, 1.5936237],
          [103.9688321, 1.5911349],
          [103.9705434, 1.5892109],
          [103.9707159, 1.5889555],
          [103.9718752, 1.5859962],
          [103.9733911, 1.5842045],
          [103.9749547, 1.5837375],
          [103.9790192, 1.580895],
          [103.9794973, 1.5797696],
          [103.9792659, 1.5790142],
          [103.979373, 1.5785056],
          [103.9801759, 1.5778733],
          [103.980546, 1.5766554],
          [103.981641, 1.5767017],
          [103.9817027, 1.5778425],
          [103.9829982, 1.5787984],
          [103.9834917, 1.5784746],
          [103.9834414, 1.5768713],
          [103.9846471, 1.5768458],
          [103.9885461, 1.5753847],
          [103.9891613, 1.5758698],
          [103.9901389, 1.5753181],
          [103.989898, 1.5747841],
          [103.9893937, 1.574516],
          [103.9889574, 1.5739085],
          [103.9902627, 1.5740012],
          [103.9906036, 1.5743295],
          [103.9918077, 1.5741728],
          [103.9924986, 1.5736337],
          [103.9950588, 1.5717683],
          [103.9989214, 1.5674027],
          [103.9993309, 1.5668503],
          [103.9988682, 1.5664648],
          [103.9979737, 1.5662021],
          [103.9978092, 1.5646922],
          [103.997795372748769, 1.564565253444016],
          [103.9976909, 1.5636061],
          [103.9989309, 1.562572],
          [103.99949982860376, 1.562097541757914],
          [104.0007135, 1.5610854],
          [104.0027793, 1.5594739],
          [104.0027245, 1.5588602],
          [104.0031192, 1.5582793],
          [104.0035029, 1.5578518],
          [104.0035687, 1.5576326],
          [104.0031301, 1.5573367],
          [104.0036783, 1.5571723],
          [104.0038647, 1.5571614],
          [104.004402, 1.556723],
          [104.0043567, 1.5564761],
          [104.0041854, 1.5559667],
          [104.0041827, 1.5555283],
          [104.0044568, 1.5544761],
          [104.004994, 1.5530513],
          [104.005301, 1.553139],
          [104.0054655, 1.5538733],
          [104.0054216, 1.5542898],
          [104.0054655, 1.5545967],
          [104.0056848, 1.5548049],
          [104.0059698, 1.5547392],
          [104.0066759, 1.5540349],
          [104.0080421, 1.5530623],
          [104.0085135, 1.5522293],
          [104.0087876, 1.551232],
          [104.0092481, 1.5503551],
          [104.0093578, 1.5498948],
          [104.0090508, 1.5498071],
          [104.0087438, 1.5500483],
          [104.0084697, 1.5500811],
          [104.0083819, 1.5498619],
          [104.0080859, 1.5492482],
          [104.0077899, 1.547933],
          [104.0079324, 1.5472534],
          [104.0080311, 1.5466835],
          [104.0078776, 1.5461355],
          [104.0076254, 1.5459163],
          [104.0074061, 1.5459492],
          [104.0073294, 1.5460807],
          [104.0066825, 1.5455656],
          [104.0065071, 1.5442832],
          [104.0062439, 1.5435818],
          [104.0061891, 1.5432859],
          [104.0061124, 1.542431],
          [104.0066283, 1.5417792],
          [104.0086506, 1.5399679],
          [104.0087952, 1.5392986],
          [104.0088262, 1.5388803],
          [104.0085782, 1.5386479],
          [104.0082528, 1.5387641],
          [104.0078498, 1.5385008],
          [104.0069974, 1.5381134],
          [104.006548, 1.5377958],
          [104.0063735, 1.5375749],
          [104.0063187, 1.5373777],
          [104.006105, 1.5372518],
          [104.0060173, 1.5370272],
          [104.0059022, 1.5353895],
          [104.0058091, 1.5352854],
          [104.0055625, 1.5352963],
          [104.0052721, 1.5353128],
          [104.0049981, 1.5354716],
          [104.0047242, 1.5356743],
          [104.0046091, 1.5355319],
          [104.0048283, 1.5353621],
          [104.0051187, 1.5351101],
          [104.005431, 1.5350279],
          [104.0056831, 1.5348582],
          [104.0059022, 1.5345898],
          [104.0061105, 1.5343214],
          [104.0062803, 1.5339818],
          [104.006357, 1.5335107],
          [104.006357, 1.5330232],
          [104.0063735, 1.5325193],
          [104.0063077, 1.5323933],
          [104.006105, 1.5323933],
          [104.00622, 1.5322509],
          [104.0062255, 1.5320647],
          [104.0061981, 1.5318511],
          [104.0060721, 1.5316922],
          [104.0058639, 1.531506],
          [104.0055187, 1.5313252],
          [104.0051735, 1.5312321],
          [104.0047297, 1.5312485],
          [104.0043461, 1.5313033],
          [104.0040119, 1.5314457],
          [104.0038091, 1.5314786],
          [104.003442, 1.5315608],
          [104.003179, 1.5315881],
          [104.0031023, 1.5317032],
          [104.0031735, 1.5319223],
          [104.0030311, 1.5319661],
          [104.002905, 1.5317415],
          [104.0027023, 1.5317196],
          [104.0020119, 1.5315388],
          [104.0014146, 1.5312102],
          [104.0011516, 1.5312102],
          [104.0003571, 1.5313581],
          [104.000116, 1.5315443],
          [103.9998311, 1.5316155],
          [103.9995571, 1.531621],
          [103.9992887, 1.5316374],
          [103.9989928, 1.5316374],
          [103.9987133, 1.5317032],
          [103.9986804, 1.5318894],
          [103.9986092, 1.5320154],
          [103.9984887, 1.5320866],
          [103.99839, 1.5321742],
          [103.9983298, 1.5326124],
          [103.9982914, 1.5329082],
          [103.998583, 1.5357151],
          [103.9984913, 1.5363463],
          [103.9983089, 1.5369254],
          [103.9977076, 1.5375605],
          [103.9975472, 1.5377298],
          [103.996485, 1.5384055],
          [103.9954551, 1.5386092],
          [103.9942212, 1.5381373],
          [103.993438, 1.537333],
          [103.9927836, 1.5360996],
          [103.9924161, 1.5356331],
          [103.9917, 1.5354025],
          [103.9909919, 1.5355848],
          [103.989758, 1.5366466],
          [103.9885859, 1.5376386],
          [103.9874701, 1.5377888],
          [103.9871697, 1.5379926],
          [103.9866896, 1.5386521],
          [103.986365, 1.5392903],
          [103.9856596, 1.5405505],
          [103.9846082, 1.5413334],
          [103.9826663, 1.5420734],
          [103.98228, 1.5421807],
          [103.9814024, 1.5421801],
          [103.9784391, 1.5412583],
          [103.9780958, 1.5410653],
          [103.9775701, 1.5402931],
          [103.9773662, 1.5394994],
          [103.9764328, 1.5389846],
          [103.9759044, 1.5384966],
          [103.9754109, 1.5376172],
          [103.9749603, 1.5370917],
          [103.9746197, 1.5368182],
          [103.9744695, 1.536979],
          [103.9744158, 1.5373115],
          [103.974169, 1.5377191],
          [103.9740296, 1.5369576],
          [103.974212, 1.536668],
          [103.9745767, 1.5365822],
          [103.9748986, 1.5367109],
          [103.9752741, 1.5371077],
          [103.9758106, 1.5378478],
          [103.9763255, 1.5383197],
          [103.9771409, 1.5388666],
          [103.977613, 1.5393707],
          [103.9780422, 1.5404754],
          [103.9783426, 1.540765],
          [103.9810033, 1.5412476],
          [103.9826341, 1.5416873],
          [103.9835353, 1.5412261],
          [103.9844902, 1.540647],
          [103.9851876, 1.5402073],
          [103.9855424, 1.5397065],
          [103.9857347, 1.5394351],
          [103.9861639, 1.5380944],
          [103.9870758, 1.5370112],
          [103.9884572, 1.5367967],
          [103.9890499, 1.5362605],
          [103.9902194, 1.5351665],
          [103.991539, 1.5345874],
          [103.9930089, 1.5347053],
          [103.9937384, 1.5349949],
          [103.9944251, 1.5357028],
          [103.9948324, 1.5366437],
          [103.995219, 1.5375367],
          [103.9958949, 1.5377941],
          [103.996485, 1.537526],
          [103.9971073, 1.5366466],
          [103.9975043, 1.5349735],
          [103.9973362, 1.5334946],
          [103.9972503, 1.5327384],
          [103.9972284, 1.5324426],
          [103.9971627, 1.532229],
          [103.9973599, 1.5320921],
          [103.9976229, 1.531621],
          [103.9978092, 1.5313033],
          [103.9981709, 1.5307939],
          [103.9983572, 1.5306022],
          [103.9987352, 1.5302407],
          [103.999075, 1.5299066],
          [103.9994476, 1.5295451],
          [103.9997654, 1.5291945],
          [103.9999517, 1.5288275],
          [104.0000788, 1.5284542],
          [104.000127, 1.5283127],
          [104.0002037, 1.5278745],
          [104.0003106, 1.5274938],
          [104.0004027, 1.5274177],
          [104.0003988, 1.5268909],
          [104.0001625, 1.5268077],
          [104.0003407, 1.5267128],
          [104.0003756, 1.5264998],
          [104.0003252, 1.5262712],
          [103.9994108, 1.5255625],
          [103.9990776, 1.5254656],
          [103.9987018, 1.5256399],
          [103.9986437, 1.5255005],
          [103.9985275, 1.5254075],
          [103.9981042, 1.5245168],
          [103.9976183, 1.5229711],
          [103.9975138, 1.5223917],
          [103.9974997, 1.5201131],
          [103.9974533, 1.5199272],
          [103.997337, 1.5198265],
          [103.9972053, 1.519749],
          [103.9973816, 1.5194685],
          [103.9973926, 1.5193134],
          [103.9974798, 1.5189318],
          [103.9975935, 1.5189455],
          [103.9976908, 1.5189291],
          [103.9977661, 1.5188442],
          [103.9978702, 1.5186826],
          [103.9979086, 1.5185443],
          [103.9980538, 1.5185895],
          [103.9980784, 1.5185196],
          [103.9978374, 1.5184361],
          [103.9980565, 1.5177514],
          [103.9981442, 1.5170695],
          [103.9984371, 1.5164692],
          [103.99949982860376, 1.515217765212308],
          [103.9995685, 1.5151369],
          [103.9998793, 1.5146024],
          [104.00050017139624, 1.514045081853368],
          [104.0008279, 1.5137509],
          [104.0012851, 1.5133248],
          [104.0018586, 1.5127748],
          [104.0021364, 1.5123961],
          [104.0016947, 1.5117841],
          [104.0024541, 1.5110482],
          [104.0028028, 1.5114743],
          [104.003229, 1.5117532],
          [104.0035699, 1.5118616],
          [104.0039109, 1.5117532],
          [104.0041519, 1.5115902],
          [104.0058283, 1.507846],
          [104.0059135, 1.5074509],
          [104.0058515, 1.5071101],
          [104.005743, 1.5068389],
          [104.0055958, 1.5066453],
          [104.0054137, 1.5066259],
          [104.0052936, 1.5067615],
          [104.0050999, 1.5069474],
          [104.005003, 1.507021],
          [104.0049333, 1.5069009],
          [104.004817, 1.5068157],
          [104.0047357, 1.5066724],
          [104.0048558, 1.5066143],
          [104.0050185, 1.5065988],
          [104.0051502, 1.5065291],
          [104.0053866, 1.5063083],
          [104.0055416, 1.5062386],
          [104.0055609, 1.506134],
          [104.0056927, 1.506103],
          [104.0057934, 1.5061766],
          [104.0059213, 1.5060992],
          [104.0061382, 1.5061418],
          [104.0064947, 1.5062618],
          [104.0069364, 1.5063122],
          [104.0072928, 1.5061882],
          [104.0076958, 1.5057235],
          [104.0079592, 1.5051038],
          [104.008546, 1.503277],
          [104.0087097, 1.5009303],
          [104.0085315, 1.4997141],
          [104.0083455, 1.4990866],
          [104.0083377, 1.498374],
          [104.008516, 1.4983275],
          [104.0084617, 1.4978317],
          [104.007833, 1.4961275],
          [104.007128, 1.4935933],
          [104.0065393, 1.4907438],
          [104.0057657, 1.4888913],
          [104.0057228, 1.488774],
          [104.0055456, 1.4887096],
          [104.0053482, 1.4887528],
          [104.0052058, 1.4888521],
          [104.005049, 1.4889986],
          [104.0047741, 1.4888719],
          [104.0050464, 1.4882538],
          [104.0051866, 1.4878445],
          [104.0052365, 1.4874922],
          [104.0050242, 1.4872497],
          [104.004827, 1.4864008],
          [104.0047664, 1.4855519],
          [104.0047209, 1.4842179],
          [104.004567, 1.4838612],
          [104.0043204, 1.4835021],
          [104.0036878, 1.4829822],
          [104.0033404, 1.4826943],
          [104.0030923, 1.4825509],
          [104.0028354, 1.4818941],
          [104.0035135, 1.4817696],
          [104.0041354, 1.4817737],
          [104.0043169, 1.4815951],
          [104.0042375, 1.4801819],
          [104.0042451, 1.479981],
          [104.0038452, 1.4790412],
          [104.0036518, 1.4788649],
          [104.0038111, 1.4785788],
          [104.0039248, 1.4786375],
          [104.004211, 1.4788195],
          [104.0046773, 1.4795755],
          [104.0049123, 1.4799583],
          [104.005136, 1.4802501],
          [104.0054052, 1.4804396],
          [104.0056781, 1.4805608],
          [104.00597, 1.4805911],
          [104.006243, 1.4805078],
          [104.0065573, 1.4802674],
          [104.0066663, 1.4799194],
          [104.0066823, 1.4792415],
          [104.0066244, 1.4788274],
          [104.0066995, 1.4784429],
          [104.0071994, 1.4761035],
          [104.0078535, 1.474426],
          [104.0080466, 1.4741567],
          [104.0080867, 1.4738311],
          [104.0083131, 1.473433],
          [104.0089576, 1.4728039],
          [104.0094753, 1.4726868],
          [104.0100017, 1.47247],
          [104.0105395, 1.4725368],
          [104.0107566, 1.47251],
          [104.0111909, 1.4722768],
          [104.0114482, 1.4718159],
          [104.011462, 1.4716778],
          [104.01088, 1.4706597],
          [104.0107888, 1.4706168],
          [104.010687, 1.4702658],
          [104.0106763, 1.4700728],
          [104.0107218, 1.4699951],
          [104.0105878, 1.4695637],
          [104.0106173, 1.4694404],
          [104.0104859, 1.469293],
          [104.0102206, 1.468816],
          [104.0100624, 1.4688562],
          [104.0100383, 1.4688133],
          [104.0101857, 1.4687329],
          [104.0102715, 1.4686311],
          [104.0101388, 1.4681913],
          [104.0097461, 1.4670045],
          [104.0097059, 1.4667151],
          [104.0097166, 1.4664551],
          [104.0094941, 1.4658334],
          [104.0094995, 1.4656378],
          [104.0092972, 1.4649549],
          [104.0090622, 1.4624385],
          [104.0088878, 1.4621201],
          [104.0088076, 1.4615223],
          [104.0085433, 1.4595525],
          [104.0081978, 1.4585919],
          [104.0080159, 1.4583758],
          [104.0081751, 1.4582659],
          [104.0082054, 1.4581522],
          [104.008174, 1.4579854],
          [104.0091928, 1.457252],
          [104.0089447, 1.4561588],
          [104.0107037, 1.4557836],
          [104.0107985, 1.4562384],
          [104.011261, 1.4561437],
          [104.0118258, 1.4587434],
          [104.0120571, 1.4586866],
          [104.0112761, 1.4549347],
          [104.0112081, 1.4549469],
          [104.0105558, 1.4550636],
          [104.0106468, 1.4555335],
          [104.0089143, 1.4559163],
          [104.0085501, 1.4540022],
          [104.0082258, 1.4537881],
          [104.0080358, 1.4534516],
          [104.008415, 1.4532501],
          [104.008343, 1.4529536],
          [104.0076667, 1.4529515],
          [104.0073328, 1.4522891],
          [104.0068072, 1.4506427],
          [104.0062932, 1.4492298],
          [104.0058727, 1.4491598],
          [104.0062465, 1.4485993],
          [104.0055239, 1.4472565],
          [104.0012292, 1.4429786],
          [104.0005635, 1.4425886],
          [104.0002997, 1.4425256],
          [103.9999424, 1.442712],
          [103.9998174, 1.442747],
          [103.999686, 1.4427128],
          [103.9996371, 1.4424174],
          [103.9995176, 1.4421813],
          [103.9989023, 1.441563],
          [103.9989015, 1.4413448],
          [103.996758, 1.4392746],
          [103.9965964, 1.4392423],
          [103.9965318, 1.4391211],
          [103.9964799, 1.4386834],
          [103.9965889, 1.438433],
          [103.9966132, 1.4377707],
          [103.9968717, 1.4370922],
          [103.9970414, 1.4370195],
          [103.9972838, 1.4360421],
          [103.9978031, 1.435716],
          [103.9978031, 1.4354494],
          [103.9977384, 1.4351344],
          [103.9980616, 1.4348436],
          [103.9978435, 1.4345124],
          [103.9975445, 1.4347467],
          [103.996987, 1.4346901],
          [103.9962194, 1.4350294],
          [103.9957346, 1.435611],
          [103.995544, 1.4366083],
          [103.9951902, 1.4367417],
          [103.9949188, 1.4367478],
          [103.9946015, 1.4367254],
          [103.9922776, 1.4354927],
          [103.991835, 1.4347973],
          [103.9913016, 1.4345656],
          [103.9911891, 1.4345167],
          [103.9903647, 1.4341586],
          [103.9900802, 1.4336436],
          [103.9893372, 1.4332643],
          [103.9884677, 1.432158],
          [103.9875508, 1.4323951],
          [103.9868236, 1.4331853],
          [103.9857169, 1.433691],
          [103.9841044, 1.4329482],
          [103.984057, 1.4324267],
          [103.9838898, 1.4321934],
          [103.9835511, 1.4321106],
          [103.9829296, 1.4320271],
          [103.9824287, 1.4318103],
          [103.9817173, 1.431842],
          [103.9807194, 1.4318984],
          [103.9804842, 1.4317313],
          [103.9792281, 1.430724],
          [103.9790779, 1.4306382],
          [103.9787614, 1.4306006],
          [103.9772755, 1.4306382],
          [103.9766961, 1.4306489],
          [103.9757734, 1.430462],
          [103.9755779, 1.4304625],
          [103.9752248, 1.4306665],
          [103.9746233, 1.4306009],
          [103.9739144, 1.430893],
          [103.9730389, 1.4303528],
          [103.97213, 1.4297919],
          [103.9724033, 1.4293109],
          [103.9725018, 1.4288408],
          [103.9723815, 1.4285784],
          [103.9718709, 1.4281466],
          [103.9715285, 1.4278569],
          [103.9706814, 1.4274915],
          [103.9687727, 1.4273868],
          [103.968029, 1.4271681],
          [103.9652003, 1.4268897],
          [103.9639808, 1.427027],
          [103.963604, 1.4271884],
          [103.9629606, 1.4270608],
          [103.9622025, 1.427014],
          [103.9617716, 1.4269019],
          [103.9616828, 1.4269188],
          [103.9616336, 1.4270596],
          [103.9616227, 1.4270907],
          [103.961236, 1.4268539],
          [103.9611711, 1.4266666],
          [103.9609236, 1.4265969],
          [103.9604766, 1.4265488],
          [103.9600197, 1.4263957],
          [103.9594321, 1.4264527],
          [103.958833, 1.4265777],
          [103.9583211, 1.4267626],
          [103.9575281, 1.427123],
          [103.9564503, 1.4276551],
          [103.9564306, 1.4276145],
          [103.956369, 1.4276447],
          [103.9563363, 1.4276607],
          [103.9561656, 1.4275362],
          [103.9560848, 1.4275038],
          [103.9559882, 1.4275333],
          [103.9559302, 1.4276731],
          [103.9559734, 1.4278365],
          [103.9560712, 1.42798],
          [103.9552251, 1.428911],
          [103.9541647, 1.429763],
          [103.954235, 1.4298872],
          [103.9543967, 1.4300325],
          [103.9541764, 1.4301895],
          [103.9541717, 1.429995],
          [103.9540129, 1.4298951],
          [103.9533076, 1.4298696],
          [103.9518793, 1.429895],
          [103.9498537, 1.4302442],
          [103.948576, 1.4309559],
          [103.9476525, 1.4310968],
          [103.9468891, 1.431356],
          [103.9466684, 1.4315996],
          [103.9460896, 1.4318054],
          [103.9456815, 1.4316929],
          [103.9436073, 1.4319497],
          [103.9426026, 1.431826],
          [103.9418596, 1.4316169],
          [103.9410764, 1.4313595],
          [103.940672228112817, 1.431167123782331],
          [103.9405207, 1.431095],
          [103.9401618, 1.4309546],
          [103.9398614, 1.430917],
          [103.9389843, 1.4311825],
          [103.9387026, 1.4313863],
          [103.9384934, 1.4317161],
          [103.9383084, 1.4323006],
          [103.9381582, 1.4326036],
          [103.9378309, 1.4329093],
          [103.93732, 1.4331305],
          [103.9368989, 1.4332753],
          [103.936754, 1.4334053],
          [103.9366937, 1.4335273],
          [103.9367567, 1.4337311],
          [103.9359695, 1.433774],
          [103.9354934, 1.4337807],
          [103.9350709, 1.4337137],
          [103.9344272, 1.4337807],
          [103.932952, 1.4339282],
          [103.9324568, 1.4338902],
          [103.9324172, 1.4335629],
          [103.9323109, 1.4333906],
          [103.9322264, 1.4334027],
          [103.9322171, 1.4334831],
          [103.9318237, 1.4336553],
          [103.9319831, 1.4341053],
          [103.9316127, 1.4342131],
          [103.9313267, 1.4340303],
          [103.9310078, 1.4342741],
          [103.9287915, 1.4346662],
          [103.9269109, 1.4354746],
          [103.926233, 1.4358605],
          [103.9256362, 1.4360421],
          [103.9249074, 1.4359338],
          [103.9245496, 1.4359513],
          [103.9242804, 1.4358929],
          [103.9240695, 1.4360551],
          [103.9244069, 1.43683],
          [103.9240598, 1.4370862],
          [103.9238814, 1.4370408],
          [103.9238295, 1.4365609],
          [103.9236349, 1.4365641],
          [103.9236252, 1.4367101],
          [103.9235376, 1.4367068],
          [103.9235041, 1.4368308],
          [103.9232518, 1.4365694],
          [103.9232564, 1.4364823],
          [103.9230041, 1.436253],
          [103.9230036, 1.4361416],
          [103.9223986, 1.436198],
          [103.9219078, 1.4361934],
          [103.9219032, 1.4363264],
          [103.9217059, 1.4362851],
          [103.9214261, 1.4363172],
          [103.9214123, 1.435932],
          [103.9210913, 1.4359412],
          [103.9211096, 1.4363264],
          [103.9207656, 1.4363035],
          [103.9207197, 1.4362209],
          [103.9204536, 1.4362072],
          [103.9204169, 1.4360879],
          [103.9202151, 1.43612],
          [103.9201922, 1.4362484],
          [103.9199113, 1.4362804],
          [103.9199613, 1.434963],
          [103.9200735, 1.4345403],
          [103.9210807, 1.4343807],
          [103.9210768, 1.434358],
          [103.9210692, 1.4343082],
          [103.9209063, 1.4332806],
          [103.9205709, 1.4311695],
          [103.915519, 1.4320066],
          [103.9155063, 1.4317591],
          [103.9121491, 1.4323238],
          [103.9121365, 1.4325078],
          [103.9109089, 1.4327163],
          [103.9064883, 1.4334784],
          [103.904851, 1.4336878],
          [103.9034168, 1.4341509],
          [103.9034612, 1.4342905],
          [103.9039562, 1.4341255],
          [103.9040196, 1.4344428],
          [103.9034993, 1.434576],
          [103.9031375, 1.4348361],
          [103.9026869, 1.4348171],
          [103.9015801, 1.4349406],
          [103.901052, 1.4353299],
          [103.9008764, 1.4353036],
          [103.9005076, 1.4354704],
          [103.9000862, 1.435602],
          [103.8996208, 1.435703],
          [103.8979878, 1.436076],
          [103.8971229, 1.4362164],
          [103.8963197, 1.4363556],
          [103.8963504, 1.4365173],
          [103.8961638, 1.4365485],
          [103.8959672, 1.436598],
          [103.8959012, 1.4364243],
          [103.8956479, 1.4364227],
          [103.8950289, 1.4364841],
          [103.8948401, 1.4365719],
          [103.8937295, 1.4367387],
          [103.89351, 1.4369098],
          [103.8935231, 1.436708],
          [103.8933162, 1.4367039],
          [103.893308, 1.4368967],
          [103.8927549, 1.436845],
          [103.8924827, 1.4362208],
          [103.8929832, 1.4359926],
          [103.8927704, 1.4354913],
          [103.8924816, 1.4352825],
          [103.8912046, 1.4358674],
          [103.8864963, 1.4382662],
          [103.8866791, 1.4386521],
          [103.8871093, 1.438459],
          [103.8875922, 1.4395649],
          [103.8874254, 1.4396264],
          [103.8875922, 1.4400565],
          [103.8874433, 1.4401254],
          [103.8869952, 1.4399862],
          [103.8865474, 1.4402057],
          [103.8859855, 1.4406533],
          [103.8847047, 1.4418135],
          [103.8826043, 1.4446875],
          [103.8817783, 1.4463269],
          [103.8816639, 1.4467116],
          [103.8817151, 1.4468857],
          [103.8814625, 1.4470798],
          [103.8812473, 1.4471281],
          [103.8810492, 1.44707],
          [103.8808673, 1.4471648],
          [103.8799182, 1.4482235],
          [103.8798632, 1.4482254],
          [103.8798095, 1.4482548],
          [103.879783, 1.4483079],
          [103.8797781, 1.4483707],
          [103.8798264, 1.4484455],
          [103.8802055, 1.4487618],
          [103.8801307, 1.4488438],
          [103.8797564, 1.4485421],
          [103.8796574, 1.4484914],
          [103.8784388, 1.4497594],
          [103.8787786, 1.4502251],
          [103.8783886, 1.4502663],
          [103.8783198, 1.4502893],
          [103.8781271, 1.4505576],
          [103.8781065, 1.4505484],
          [103.8781363, 1.4505048],
          [103.8780904, 1.4504727],
          [103.8779482, 1.4506699],
          [103.8778759, 1.4506161],
          [103.8761549, 1.4530786],
          [103.8765089, 1.4533255],
          [103.8765105, 1.453361],
          [103.8763755, 1.4535479],
          [103.8763912, 1.4535607],
          [103.8759584, 1.454185],
          [103.8759393, 1.4541709],
          [103.8758311, 1.454331],
          [103.8758571, 1.4543497],
          [103.8756067, 1.4547096],
          [103.8755838, 1.4546913],
          [103.8754356, 1.4548962],
          [103.8755694, 1.4549975],
          [103.8755581, 1.4550153],
          [103.8756359, 1.4550753],
          [103.8755175, 1.4552439],
          [103.8754364, 1.455184],
          [103.875074, 1.4557063],
          [103.8750513, 1.4556922],
          [103.8737214, 1.4575958],
          [103.8737441, 1.4576217],
          [103.8726906, 1.4591245],
          [103.8724077, 1.4595071],
          [103.8722859, 1.4596057],
          [103.8717482, 1.4597464],
          [103.8713337, 1.4602956],
          [103.8713458, 1.4605298],
          [103.8714989, 1.4607527],
          [103.8710563, 1.4613915],
          [103.868853, 1.4598344],
          [103.8687132, 1.4600307],
          [103.8693422, 1.4604733],
          [103.8693622, 1.4605531],
          [103.8702774, 1.4612086],
          [103.8703374, 1.4614115],
          [103.8704296, 1.4615782],
          [103.8706553, 1.4617552],
          [103.8707143, 1.4618015],
          [103.8711184, 1.461956],
          [103.871772, 1.4622173],
          [103.8715343, 1.4624074],
          [103.8718433, 1.4626806],
          [103.8721404, 1.4626569],
          [103.8723427, 1.4628209],
          [103.872505, 1.4628209],
          [103.8725201, 1.4628873],
          [103.8725325, 1.462942],
          [103.8719146, 1.4631677],
          [103.8716412, 1.462978],
          [103.8713638, 1.4628831],
          [103.871059, 1.4628707],
          [103.870874, 1.462967],
          [103.8706906, 1.4631202],
          [103.8703578, 1.4637884],
          [103.8698799, 1.4643798],
          [103.8697283, 1.4643795],
          [103.8695973, 1.4645338],
          [103.869632, 1.4646252],
          [103.8698945, 1.4648767],
          [103.8699865, 1.4651006],
          [103.8699811, 1.4653474],
          [103.869816, 1.4655882],
          [103.8697808, 1.4660041],
          [103.869857, 1.4668024],
          [103.8699723, 1.4671429],
          [103.8694437, 1.4673883],
          [103.8688768, 1.4676515],
          [103.8686267, 1.4673774],
          [103.868407, 1.4672659],
          [103.8681023, 1.4672747],
          [103.8675552, 1.4674073],
          [103.8672685, 1.4675423],
          [103.8668252, 1.4679278],
          [103.8666067, 1.4683462],
          [103.8665204, 1.4685176],
          [103.866016, 1.4689185],
          [103.8659254, 1.4688928],
          [103.8658331, 1.468945],
          [103.8655419, 1.4691406],
          [103.865345, 1.4692201],
          [103.8652898, 1.4693264],
          [103.8653488, 1.4695536],
          [103.8655525, 1.4698295],
          [103.8658169, 1.4699675],
          [103.8659251, 1.4701893],
          [103.8657926, 1.4704896],
          [103.8656689, 1.4707458],
          [103.8656624, 1.4710036],
          [103.8656841, 1.4712768],
          [103.8646467, 1.4711991],
          [103.8647894, 1.4708958],
          [103.8648804, 1.4706675],
          [103.8648712, 1.4704297],
          [103.8647772, 1.4701737],
          [103.8646518, 1.4697976],
          [103.8644825, 1.469278],
          [103.864079, 1.4686179],
          [103.8635941, 1.4676921],
          [103.8555132, 1.4704529],
          [103.8558258, 1.4710627],
          [103.854151, 1.4713665],
          [103.8538667, 1.4716507],
          [103.853753, 1.4714574],
          [103.8533322, 1.4713437],
          [103.8520584, 1.4714461],
          [103.851477, 1.4717023],
          [103.8513657, 1.4718858],
          [103.851388, 1.4721583],
          [103.8515493, 1.4721305],
          [103.8515437, 1.4725198],
          [103.8516161, 1.4727366],
          [103.8512044, 1.4729257],
          [103.8510709, 1.4727922],
          [103.8508539, 1.4729146],
          [103.850598, 1.4729702],
          [103.8499972, 1.473087],
          [103.8497024, 1.4733929],
          [103.8492685, 1.4734429],
          [103.8491592, 1.4733807],
          [103.8490704, 1.4733759],
          [103.8489629, 1.4734152],
          [103.8485564, 1.4736932],
          [103.8483049, 1.4739531],
          [103.8482738, 1.4740195],
          [103.8482758, 1.4740966],
          [103.8483061, 1.4742103],
          [103.8483007, 1.474308],
          [103.8477442, 1.4745218],
          [103.8470864, 1.4750217],
          [103.8463054, 1.4756765],
          [103.8457775, 1.4761008],
          [103.845334, 1.4763485],
          [103.8446787, 1.4767659],
          [103.8444727, 1.4764325],
          [103.8443048, 1.4764371],
          [103.844151, 1.4765296],
          [103.8440543, 1.4766635],
          [103.8440589, 1.4767173],
          [103.8442697, 1.4770472],
          [103.8440182, 1.4773265],
          [103.8436337, 1.4774664],
          [103.8435912, 1.4775178],
          [103.8435948, 1.4775568],
          [103.8436339, 1.4775934],
          [103.843666, 1.4777003],
          [103.8436115, 1.47782],
          [103.8432687, 1.4779288],
          [103.8429639, 1.4777873],
          [103.842257, 1.4778936],
          [103.8416179, 1.4779826],
          [103.8409562, 1.4784238],
          [103.8407877, 1.4786049],
          [103.8407902, 1.4786277],
          [103.8408648, 1.4786943],
          [103.8408425, 1.4787342],
          [103.8405616, 1.4786492],
          [103.8404952, 1.4786567],
          [103.8404356, 1.4787171],
          [103.8403276, 1.4788397],
          [103.8402572, 1.47905],
          [103.8402599, 1.479103],
          [103.8402985, 1.4791213],
          [103.8402728, 1.4791945],
          [103.8399894, 1.4797458],
          [103.8399146, 1.480166],
          [103.8397944, 1.4807067],
          [103.839652, 1.4807401],
          [103.8395104, 1.4807754],
          [103.8394837, 1.4806063],
          [103.8394371, 1.4804597],
          [103.8393348, 1.4802325],
          [103.8392013, 1.4800336],
          [103.8390297, 1.4797858],
          [103.8387324, 1.4795109],
          [103.8384746, 1.4793225],
          [103.8381382, 1.479132],
          [103.8376563, 1.4789179],
          [103.8370303, 1.4787677],
          [103.8366322, 1.4787624],
          [103.8358604, 1.4788249],
          [103.8351226, 1.4789505],
          [103.8345027, 1.4791223],
          [103.8332676, 1.4796487],
          [103.832368, 1.4802152],
          [103.831844, 1.480657],
          [103.8316366, 1.4809703],
          [103.8315655, 1.4812693],
          [103.8315811, 1.4816102],
          [103.831724, 1.4819749],
          [103.8319281, 1.4822386],
          [103.8309575, 1.4829499],
          [103.8288397, 1.4814042],
          [103.8284837, 1.4813023],
          [103.8280917, 1.4813603],
          [103.8274817, 1.4815724],
          [103.8273463, 1.4814837],
          [103.8263491, 1.4814967],
          [103.8261309, 1.4817149],
          [103.8259179, 1.4818905],
          [103.8255665, 1.4818905],
          [103.8252151, 1.4816617],
          [103.8250128, 1.4813477],
          [103.8248152, 1.4813129],
          [103.8226317, 1.4829838],
          [103.8221175, 1.4833009],
          [103.8212174, 1.483644],
          [103.8203975, 1.4840272],
          [103.8198864, 1.484421],
          [103.8197959, 1.4846233],
          [103.819929, 1.4849213],
          [103.8198119, 1.485049],
          [103.8193167, 1.4848574],
          [103.8188748, 1.4848255],
          [103.8187684, 1.4847084],
          [103.8180248, 1.4846347],
          [103.8165874, 1.4851031],
          [103.8166726, 1.4855182],
          [103.816236, 1.4856672],
          [103.8161508, 1.4853585],
          [103.8153522, 1.4852414],
          [103.8151499, 1.4851243],
          [103.8142449, 1.4851563],
          [103.8136699, 1.4852308],
          [103.813205, 1.4852676],
          [103.8126641, 1.4855846],
          [103.8119698, 1.4857586],
          [103.8119918, 1.4854158],
          [103.812293, 1.4850366],
          [103.8126086, 1.4848745],
          [103.8126289, 1.4845873],
          [103.8123741, 1.4842725],
          [103.8120978, 1.4835085],
          [103.812069, 1.4828544],
          [103.8115961, 1.4831796],
          [103.8110629, 1.4835002],
          [103.810685, 1.4831107],
          [103.8099802, 1.4827684],
          [103.808814, 1.4830398],
          [103.8077813, 1.4829211],
          [103.807038, 1.4825087],
          [103.8065811, 1.4829242],
          [103.8065517, 1.4835228],
          [103.8065898, 1.4842154],
          [103.806621, 1.4851669],
          [103.8055349, 1.4851456],
          [103.8052004, 1.4850762],
          [103.8049706, 1.4850285],
          [103.804534, 1.4849434],
          [103.8045021, 1.4842196],
          [103.8037014, 1.4839844],
          [103.801909, 1.484192],
          [103.8017423, 1.4845565],
          [103.8013985, 1.4845044],
          [103.8004298, 1.4849522],
          [103.7987215, 1.4860872],
          [103.7982544, 1.4868159],
          [103.7975965, 1.4878783],
          [103.797321, 1.4890488],
          [103.7973048, 1.4891174],
          [103.7975444, 1.4896381],
          [103.7973256, 1.4899921],
          [103.7975861, 1.4917415],
          [103.798045, 1.4931885],
          [103.7981485, 1.4935149],
          [103.7982631, 1.4938762],
          [103.7985027, 1.4944073],
          [103.7988361, 1.4949904],
          [103.799034, 1.4950425],
          [103.7995618, 1.495787],
          [103.8000508, 1.4960333],
          [103.8007019, 1.4962893],
          [103.8010628, 1.4963868],
          [103.8012233, 1.4964699],
          [103.8014787, 1.4966008],
          [103.8016386, 1.4967069],
          [103.8021052, 1.4968051],
          [103.8019674, 1.4979103],
          [103.8011191, 1.4975539],
          [103.8001067, 1.4971286],
          [103.7995773, 1.4969307],
          [103.7989715, 1.4965732],
          [103.7985221, 1.4962114],
          [103.7984043, 1.4960735],
          [103.7983426, 1.4959241],
          [103.7980473, 1.4954635],
          [103.7978252, 1.4956668],
          [103.7978184, 1.4957521],
          [103.797881, 1.4960144],
          [103.7979694, 1.4962279],
          [103.7979841, 1.4970158],
          [103.7981241, 1.4971336],
          [103.7979694, 1.4976637],
          [103.7958554, 1.4974723],
          [103.7955166, 1.4968906],
          [103.7961206, 1.4966844],
          [103.7968106, 1.4964825],
          [103.7968288, 1.4961849],
          [103.7967592, 1.4960624],
          [103.7966229, 1.4960272],
          [103.7965574, 1.4959821],
          [103.7954356, 1.4964635],
          [103.7951483, 1.4963531],
          [103.7947683, 1.4962288],
          [103.7945783, 1.495801],
          [103.7947448, 1.4955801],
          [103.7948882, 1.4952863],
          [103.7949327, 1.4951896],
          [103.7949235, 1.4951005],
          [103.7948897, 1.494907],
          [103.794799, 1.4948581],
          [103.7947006, 1.4948707],
          [103.7946137, 1.4948257],
          [103.7945435, 1.4947457],
          [103.7944808, 1.4946542],
          [103.7944686, 1.4945573],
          [103.7944717, 1.4944604],
          [103.7945776, 1.4942667],
          [103.7945399, 1.4941732],
          [103.7944944, 1.4940874],
          [103.7943959, 1.4939081],
          [103.7943639, 1.4938185],
          [103.7943472, 1.4937211],
          [103.7943536, 1.4936123],
          [103.7943944, 1.4935457],
          [103.7944186, 1.4934391],
          [103.7943501, 1.4933859],
          [103.7942851, 1.493156],
          [103.7941742, 1.4929492],
          [103.7941687, 1.4923843],
          [103.7941928, 1.4911896],
          [103.7940631, 1.4906637],
          [103.7939694, 1.4889277],
          [103.7940428, 1.4888231],
          [103.7941706, 1.4886614],
          [103.7942859, 1.4886474],
          [103.7943735, 1.4884666],
          [103.7943741, 1.4882369],
          [103.7943441, 1.4875735],
          [103.7944882, 1.487343],
          [103.7945098, 1.4870404],
          [103.7948989, 1.4869684],
          [103.7949862, 1.4859447],
          [103.7952265, 1.4857509],
          [103.7951654, 1.4851906],
          [103.7947883, 1.4841719],
          [103.7948597, 1.4838357],
          [103.7947434, 1.4834714],
          [103.7947068, 1.4833569],
          [103.7943196, 1.4824605],
          [103.7938294, 1.4816904],
          [103.7928493, 1.4808728],
          [103.7920617, 1.4809127],
          [103.7916031, 1.4810622],
          [103.7911446, 1.4811419],
          [103.791005, 1.4807432],
          [103.791314, 1.480524],
          [103.7911645, 1.4803446],
          [103.7902972, 1.4804642],
          [103.7899881, 1.4799559],
          [103.7895395, 1.4798563],
          [103.7894797, 1.4796071],
          [103.7896592, 1.4793381],
          [103.7904467, 1.4788497],
          [103.7903713, 1.4785223],
          [103.7900329, 1.4782474],
          [103.7894972, 1.4776696],
          [103.7892716, 1.4776978],
          [103.7892222, 1.4778739],
          [103.7893773, 1.4782827],
          [103.7894972, 1.478783],
          [103.789335, 1.4789239],
          [103.7892081, 1.478776],
          [103.7891658, 1.47848],
          [103.7890178, 1.4784166],
          [103.7888486, 1.4785998],
          [103.7887922, 1.4784095],
          [103.7887852, 1.4781276],
          [103.7889332, 1.4776555],
          [103.7889403, 1.4771763],
          [103.7886794, 1.4770001],
          [103.7885385, 1.476683],
          [103.7886386, 1.4764275],
          [103.7885796, 1.4763103],
          [103.7885836, 1.4761679],
          [103.788614, 1.4760767],
          [103.7886108, 1.4759855],
          [103.7885237, 1.4759205],
          [103.7883556, 1.4757073],
          [103.7882364, 1.4753962],
          [103.7880041, 1.4752114],
          [103.787734, 1.4751022],
          [103.7876995, 1.4748204],
          [103.7875478, 1.4746756],
          [103.7877823, 1.4744688],
          [103.7869272, 1.4733451],
          [103.7864379, 1.473192],
          [103.7861341, 1.4730969],
          [103.7857917, 1.4728485],
          [103.785279, 1.4724765],
          [103.7848178, 1.4723229],
          [103.7846055, 1.4722969],
          [103.7844135, 1.4722547],
          [103.7843489, 1.4721385],
          [103.7841712, 1.4721847],
          [103.7840801, 1.4718746],
          [103.7844661, 1.4717837],
          [103.7849483, 1.4711363],
          [103.7848879, 1.4710883],
          [103.7819785, 1.4687766],
          [103.7817468, 1.4691199],
          [103.7814292, 1.4691799],
          [103.7789487, 1.4670434],
          [103.7789094, 1.4668757],
          [103.7789762, 1.4666945],
          [103.7791957, 1.4663607],
          [103.779189, 1.4661768],
          [103.7790478, 1.4659697],
          [103.7789094, 1.4659458],
          [103.778752, 1.4661223],
          [103.77869, 1.4660603],
          [103.778814, 1.4658743],
          [103.7788188, 1.4657503],
          [103.7787084, 1.465662],
          [103.7781819, 1.4662822],
          [103.7780487, 1.4661557],
          [103.7778994, 1.466056],
          [103.7783358, 1.465433],
          [103.7782805, 1.4653721],
          [103.7778479, 1.4658724],
          [103.7775408, 1.4658272],
          [103.7748803, 1.4634912],
          [103.774743, 1.462985],
          [103.7745175, 1.4627708],
          [103.7756254, 1.4595761],
          [103.7722165, 1.4573517],
          [103.7703818, 1.4561544],
          [103.7695347, 1.4562952],
          [103.7695646, 1.4564801],
          [103.7688653, 1.4575787],
          [103.7687926, 1.457552],
          [103.7687784, 1.4573524],
          [103.7687721, 1.457264],
          [103.7687108, 1.4571557],
          [103.7684511, 1.4570262],
          [103.768237, 1.4567814],
          [103.7685944, 1.4553515],
          [103.7681769, 1.4552627],
          [103.7675128, 1.4556408],
          [103.7672511, 1.4558557],
          [103.7669657, 1.4562337],
          [103.7665855, 1.456586],
          [103.7663549, 1.4563739],
          [103.7662818, 1.4563366],
          [103.7663102, 1.4559887],
          [103.7660718, 1.4557126],
          [103.7658404, 1.4547838],
          [103.7658589, 1.4539576],
          [103.7616067, 1.4521277],
          [103.7615265, 1.4522608],
          [103.7613786, 1.4530561],
          [103.7613744, 1.4531668],
          [103.7614521, 1.4532222],
          [103.7617727, 1.4532832],
          [103.7615856, 1.4538997],
          [103.7615752, 1.4539972],
          [103.7616514, 1.4540395],
          [103.7630004, 1.4543584],
          [103.76276, 1.4550574],
          [103.7627228, 1.4551657],
          [103.759807, 1.4544236],
          [103.7599844, 1.453293],
          [103.7578205, 1.4529644],
          [103.7576105, 1.4540232],
          [103.7571134, 1.45401],
          [103.7563941, 1.4540257],
          [103.755613036453724, 1.454098400649143],
          [103.7555604, 1.4541033],
          [103.7555485, 1.4542225],
          [103.755343, 1.4542538],
          [103.7553538, 1.4543961],
          [103.7539482, 1.4545757],
          [103.7528464, 1.4549637],
          [103.7522212, 1.4551788],
          [103.7518367, 1.4550184],
          [103.751021, 1.4553458],
          [103.7503457, 1.4556864],
          [103.7487157, 1.4563524],
          [103.7475531, 1.4567553],
          [103.7460839, 1.4571807],
          [103.742875, 1.4577219],
          [103.7427577, 1.4572334],
          [103.7427826, 1.4571384],
          [103.7427549, 1.456963],
          [103.7426428, 1.456911],
          [103.7425233, 1.4569219],
          [103.7423769, 1.4569753],
          [103.7420935, 1.4569489],
          [103.7419133, 1.4570479],
          [103.7417463, 1.4571632],
          [103.7414537, 1.457366],
          [103.7414069, 1.457537],
          [103.741275, 1.4580046],
          [103.7392689, 1.458868],
          [103.736672, 1.4595213],
          [103.736054, 1.4572214],
          [103.732467505934849, 1.4581186408209],
        ],
      ],
      [
        [
          [104.0869465, 2.2029831],
          [104.0866845, 2.2019834],
          [104.0871962, 2.2011111],
          [104.0869855, 2.2003891],
          [104.0873769, 2.1990355],
          [104.0885509, 2.1989151],
          [104.0891831, 2.1986143],
          [104.0899959, 2.1979525],
          [104.0901163, 2.1971103],
          [104.0896948, 2.1961477],
          [104.0900561, 2.1954558],
          [104.0895744, 2.1952452],
          [104.0881595, 2.1965989],
          [104.0882499, 2.1973208],
          [104.0872564, 2.1974111],
          [104.0854803, 2.1986444],
          [104.0840655, 2.1986444],
          [104.0837043, 2.1991558],
          [104.0818378, 2.1991558],
          [104.0806036, 2.1998176],
          [104.0794597, 2.20072],
          [104.0784663, 2.2002688],
          [104.07663, 2.2000281],
          [104.0751549, 2.1990656],
          [104.0741916, 2.1982534],
          [104.0727767, 2.1977721],
          [104.0720844, 2.1967192],
          [104.0713017, 2.1959371],
          [104.0710307, 2.1968997],
          [104.0714221, 2.1981631],
          [104.0725961, 2.1990355],
          [104.0731982, 2.2000281],
          [104.0732584, 2.2012615],
          [104.0738002, 2.2018932],
          [104.0745227, 2.2039688],
          [104.0748539, 2.2054127],
          [104.074884, 2.2080598],
          [104.0748238, 2.211098],
          [104.0736798, 2.2124516],
          [104.0739508, 2.2130833],
          [104.0738797, 2.2138883],
          [104.0738605, 2.2141061],
          [104.0732075, 2.2151864],
          [104.0718614, 2.2161595],
          [104.0702822, 2.216726],
          [104.0693357, 2.2167709],
          [104.0684419, 2.2168133],
          [104.0676467, 2.2173564],
          [104.0672076, 2.2177458],
          [104.0653713, 2.2182873],
          [104.0642274, 2.2194003],
          [104.0640855, 2.219392],
          [104.0632039, 2.2193401],
          [104.0626242, 2.2180026],
          [104.0624147, 2.2179434],
          [104.0621725, 2.2178632],
          [104.0617608, 2.2181364],
          [104.0613794, 2.2184842],
          [104.0610087, 2.2201411],
          [104.0602838, 2.2214458],
          [104.0577251, 2.2225588],
          [104.0567919, 2.2239124],
          [104.055076, 2.224153],
          [104.0557382, 2.2252059],
          [104.0547448, 2.2248449],
          [104.0528182, 2.2253864],
          [104.0517345, 2.2270107],
          [104.0520957, 2.2285147],
          [104.0532698, 2.23047],
          [104.0543234, 2.2310114],
          [104.0560694, 2.231222],
          [104.0580562, 2.231222],
          [104.0599527, 2.2311017],
          [104.0606805, 2.2314179],
          [104.0613375, 2.2317033],
          [104.0619696, 2.2322146],
          [104.0615482, 2.2330268],
          [104.0618793, 2.2334479],
          [104.0616385, 2.2338992],
          [104.0615181, 2.2347113],
          [104.0622707, 2.2350121],
          [104.0626921, 2.2339894],
          [104.0639565, 2.2332675],
          [104.065582, 2.231974],
          [104.0666959, 2.2310415],
          [104.0660035, 2.2303497],
          [104.0657928, 2.2292066],
          [104.065947, 2.2274692],
          [104.0664373, 2.226254],
          [104.0666309, 2.2258024],
          [104.067243, 2.2244717],
          [104.0677628, 2.2238282],
          [104.0681416, 2.2235478],
          [104.0691643, 2.2234612],
          [104.0697965, 2.2226791],
          [104.0697363, 2.2216563],
          [104.0709103, 2.2201222],
          [104.0715726, 2.220032],
          [104.0720543, 2.220423],
          [104.0722951, 2.2197613],
          [104.0727165, 2.2195507],
          [104.0738605, 2.219671],
          [104.0745227, 2.2193401],
          [104.0740411, 2.2187385],
          [104.0739027, 2.2183847],
          [104.0739157, 2.218203],
          [104.073937, 2.2179052],
          [104.0741615, 2.2171456],
          [104.0746336, 2.2161877],
          [104.0752103, 2.2152671],
          [104.0762212, 2.2141267],
          [104.0775933, 2.213324],
          [104.0786168, 2.2135646],
          [104.0797005, 2.2139256],
          [104.0801822, 2.2138053],
          [104.0802123, 2.2119102],
          [104.0812057, 2.2109777],
          [104.0812358, 2.2097744],
          [104.0818077, 2.2095638],
          [104.0829517, 2.2073679],
          [104.0840655, 2.207037],
          [104.0869465, 2.2029831],
        ],
      ],
      [
        [
          [104.1159556, 2.2727042],
          [104.1162774, 2.2727042],
          [104.1165564, 2.2724898],
          [104.1166207, 2.2722325],
          [104.1163203, 2.2721468],
          [104.1157732, 2.2722325],
          [104.1157732, 2.2724255],
          [104.1156899, 2.272514],
          [104.1156015, 2.2726078],
          [104.1153226, 2.2727793],
          [104.1153977, 2.2729722],
          [104.1155157, 2.2729722],
          [104.1156015, 2.2728114],
          [104.115783, 2.2726848],
          [104.1159556, 2.2727042],
        ],
      ],
      [
        [
          [104.0922761, 2.3155868],
          [104.0977693, 2.3159299],
          [104.1036058, 2.3155868],
          [104.1103005, 2.3152438],
          [104.1183686, 2.3161014],
          [104.1290116, 2.313014],
          [104.1348481, 2.3088975],
          [104.1393113, 2.3020366],
          [104.1420579, 2.2977485],
          [104.1430879, 2.2905445],
          [104.1401696, 2.2888293],
          [104.1357064, 2.2888293],
          [104.1357064, 2.2845411],
          [104.1291833, 2.2814537],
          [104.1200852, 2.2831689],
          [104.1120172, 2.2859133],
          [104.106791, 2.2916117],
          [104.1016939, 2.2934557],
          [104.0989117, 2.2944622],
          [104.0976275, 2.2950915],
          [104.0960248, 2.2984272],
          [104.0946794, 2.3022081],
          [104.0945077, 2.3114703],
          [104.0922761, 2.3155868],
        ],
      ],
      [
        [
          [104.1502379, 2.3031956],
          [104.1498149, 2.3032373],
          [104.149729, 2.3037197],
          [104.1492033, 2.3037412],
          [104.1491717, 2.3043134],
          [104.1485817, 2.304442],
          [104.1483242, 2.3047529],
          [104.1479487, 2.3048922],
          [104.147895, 2.3044634],
          [104.1474337, 2.3043991],
          [104.1473264, 2.3048708],
          [104.1470909, 2.3053786],
          [104.1466832, 2.3057002],
          [104.146555, 2.3064332],
          [104.1462224, 2.306594],
          [104.1459971, 2.3069049],
          [104.1462011, 2.3071452],
          [104.1464138, 2.3072461],
          [104.1467678, 2.3069942],
          [104.1469811, 2.3069731],
          [104.1474005, 2.3071491],
          [104.1475713, 2.3073739],
          [104.1480497, 2.307373],
          [104.1483394, 2.3070835],
          [104.1486023, 2.3070567],
          [104.1487096, 2.3072443],
          [104.1495323, 2.307263],
          [104.1498653, 2.3071265],
          [104.1500432, 2.3067533],
          [104.1501767, 2.3065398],
          [104.1503591, 2.3063683],
          [104.1502518, 2.3060038],
          [104.1500265, 2.305843],
          [104.1497375, 2.305485],
          [104.1496087, 2.3051741],
          [104.1497482, 2.3048847],
          [104.1499735, 2.3046766],
          [104.1501559, 2.3046981],
          [104.1498984, 2.3044408],
          [104.1499306, 2.3042585],
          [104.1498985, 2.304029],
          [104.1501879, 2.304042],
          [104.1502952, 2.3038169],
          [104.1502523, 2.303506],
          [104.1502379, 2.3031956],
        ],
      ],
      [
        [
          [103.9751425, 2.4524654],
          [103.9751368, 2.4534435],
          [103.9749695, 2.4540061],
          [103.9746081, 2.4544574],
          [103.9742629, 2.4546547],
          [103.9738294, 2.4546004],
          [103.9736127, 2.4546073],
          [103.9735441, 2.4547353],
          [103.9735891, 2.454907],
          [103.9738044, 2.4550211],
          [103.9739234, 2.4552713],
          [103.9737505, 2.455527],
          [103.9735333, 2.4555309],
          [103.9734182, 2.4554215],
          [103.9732674, 2.4554294],
          [103.9732039, 2.4555697],
          [103.9731523, 2.4560846],
          [103.9729079, 2.4567694],
          [103.9726124, 2.4569613],
          [103.9725044, 2.4571026],
          [103.9725071, 2.4573885],
          [103.9726162, 2.4576222],
          [103.972918, 2.4578218],
          [103.9733312, 2.4579835],
          [103.9734367, 2.4581354],
          [103.9737009, 2.458174],
          [103.9738779, 2.4583759],
          [103.9737431, 2.4586741],
          [103.9737163, 2.4588431],
          [103.9738709, 2.4588648],
          [103.9739746, 2.4587732],
          [103.9741463, 2.4587496],
          [103.9744839, 2.4590309],
          [103.9748909, 2.4591206],
          [103.9753716, 2.4591763],
          [103.9756599, 2.4591036],
          [103.9758574, 2.4592348],
          [103.9760936, 2.4592707],
          [103.976396, 2.4590819],
          [103.9764886, 2.4588535],
          [103.9763875, 2.458541],
          [103.9762405, 2.4582544],
          [103.9762578, 2.4581338],
          [103.9763798, 2.4580225],
          [103.9765882, 2.4578989],
          [103.976842, 2.4579509],
          [103.9774805, 2.4582323],
          [103.9777603, 2.4583462],
          [103.9780363, 2.4583572],
          [103.9786861, 2.4580296],
          [103.9787916, 2.4578777],
          [103.9786767, 2.4574426],
          [103.9784447, 2.4571819],
          [103.9783975, 2.4569098],
          [103.978416, 2.4566267],
          [103.9782494, 2.4564011],
          [103.9779909, 2.4558644],
          [103.9782596, 2.4553616],
          [103.978892, 2.4544519],
          [103.9795711, 2.4537252],
          [103.979922, 2.4535204],
          [103.9801883, 2.4537327],
          [103.9802591, 2.4535782],
          [103.9801371, 2.4533331],
          [103.9805906, 2.4526899],
          [103.9812198, 2.4518881],
          [103.9818844, 2.4513881],
          [103.982093, 2.451619],
          [103.9823284, 2.4515837],
          [103.9825202, 2.4516047],
          [103.9827234, 2.4513764],
          [103.9829369, 2.451407],
          [103.9830577, 2.4515022],
          [103.9832312, 2.4514773],
          [103.9835102, 2.4512916],
          [103.9840342, 2.4510562],
          [103.9840876, 2.4509113],
          [103.9840122, 2.4507086],
          [103.9839936, 2.4504266],
          [103.9853846, 2.4487775],
          [103.9855867, 2.4483343],
          [103.9855545, 2.4478777],
          [103.9854567, 2.447583],
          [103.9854949, 2.4473587],
          [103.9856335, 2.4470474],
          [103.9856583, 2.4466525],
          [103.9857634, 2.4460167],
          [103.9857758, 2.4458226],
          [103.9856944, 2.4457088],
          [103.9856657, 2.4454878],
          [103.9869079, 2.4431241],
          [103.9876429, 2.4427552],
          [103.9878564, 2.4424904],
          [103.9879364, 2.442144],
          [103.988184, 2.4419038],
          [103.9890811, 2.4419112],
          [103.9893849, 2.4417956],
          [103.9896573, 2.441798],
          [103.9898896, 2.4418405],
          [103.9899547, 2.4417229],
          [103.9899109, 2.4415146],
          [103.9899573, 2.441312],
          [103.9903446, 2.4411342],
          [103.9904227, 2.440859],
          [103.9901237, 2.4404155],
          [103.9898448, 2.4395439],
          [103.989683, 2.4388393],
          [103.9897494, 2.4386877],
          [103.989856, 2.438536],
          [103.9899074, 2.4379831],
          [103.9902046, 2.4377957],
          [103.9907696, 2.4370729],
          [103.9911869, 2.4370719],
          [103.9913228, 2.4369103],
          [103.9914609, 2.4365603],
          [103.9916298, 2.4358871],
          [103.991993, 2.435405],
          [103.9920473, 2.435077],
          [103.9921817, 2.4348528],
          [103.9926374, 2.4346554],
          [103.9939288, 2.4327784],
          [103.9946349, 2.4314387],
          [103.9950782, 2.4312063],
          [103.9955751, 2.4306112],
          [103.9957715, 2.4307114],
          [103.9962591, 2.430422],
          [103.9966913, 2.4293346],
          [103.9971942, 2.428928],
          [103.9973332, 2.4285372],
          [103.997722, 2.4282456],
          [103.997519, 2.4277572],
          [103.9967674, 2.4274251],
          [103.996107, 2.4268244],
          [103.995747, 2.4259868],
          [103.9956032, 2.4258989],
          [103.9953644, 2.4259058],
          [103.9950293, 2.4261567],
          [103.9946121, 2.425647],
          [103.9940467, 2.4246435],
          [103.9934872, 2.4243492],
          [103.9928169, 2.4242287],
          [103.9919352, 2.4241932],
          [103.9911329, 2.4251214],
          [103.9904729, 2.4255438],
          [103.9874018, 2.4266998],
          [103.9863264, 2.4271074],
          [103.985438, 2.4276114],
          [103.9845686, 2.4280528],
          [103.9839633, 2.4283144],
          [103.9835198, 2.4283682],
          [103.9832559, 2.4284003],
          [103.9826009, 2.4283455],
          [103.9818239, 2.4284059],
          [103.980973, 2.4287419],
          [103.9804397, 2.429089],
          [103.9800765, 2.4294304],
          [103.979384, 2.4305437],
          [103.9790131, 2.430974],
          [103.9782261, 2.4314967],
          [103.9775, 2.4320002],
          [103.9768874, 2.4327901],
          [103.9763259, 2.4335177],
          [103.9759717, 2.4344692],
          [103.9757271, 2.4354179],
          [103.9755642, 2.4369401],
          [103.9755606, 2.4377846],
          [103.9762068, 2.44111],
          [103.9761645, 2.4414916],
          [103.9759987, 2.4416093],
          [103.9759155, 2.4417286],
          [103.9759608, 2.4419733],
          [103.9759198, 2.4421626],
          [103.9755808, 2.4424493],
          [103.9755553, 2.44259],
          [103.975659, 2.4430239],
          [103.9752697, 2.4439754],
          [103.9750212, 2.444308],
          [103.9749872, 2.4447485],
          [103.9748625, 2.4450758],
          [103.9746929, 2.4458652],
          [103.9747031, 2.4465092],
          [103.9748613, 2.4471995],
          [103.9748639, 2.4473975],
          [103.9747646, 2.4475389],
          [103.9744701, 2.4475556],
          [103.974356, 2.4476065],
          [103.9742329, 2.4479899],
          [103.9741892, 2.448328],
          [103.9741811, 2.4492762],
          [103.974604, 2.4510345],
          [103.9751425, 2.4524654],
        ],
      ],
      [
        [
          [103.9567375, 2.4758029],
          [103.9566986, 2.4761676],
          [103.9566676, 2.4766332],
          [103.9565744, 2.4769902],
          [103.9563604, 2.477496],
          [103.9563796, 2.4777834],
          [103.9567632, 2.4780517],
          [103.9570125, 2.4784157],
          [103.9575728, 2.4788382],
          [103.9579976, 2.4794893],
          [103.9581496, 2.4799293],
          [103.9584692, 2.4803275],
          [103.958509, 2.481086],
          [103.9577419, 2.4815756],
          [103.9580189, 2.4819589],
          [103.9589778, 2.4815969],
          [103.9593188, 2.4811286],
          [103.9593401, 2.4803621],
          [103.9596227, 2.4801187],
          [103.9602792, 2.4800352],
          [103.9605299, 2.4801068],
          [103.9609358, 2.4798563],
          [103.9613536, 2.4797609],
          [103.961812, 2.4798299],
          [103.9621529, 2.4807454],
          [103.9626856, 2.4812137],
          [103.9633676, 2.4811924],
          [103.9630053, 2.4806389],
          [103.9629201, 2.4802131],
          [103.962366, 2.4799364],
          [103.9624086, 2.4795319],
          [103.9625791, 2.4794893],
          [103.9627496, 2.4797235],
          [103.9630692, 2.4797447],
          [103.9629578, 2.4794312],
          [103.9626893, 2.4791247],
          [103.9627469, 2.478569],
          [103.9629195, 2.4780708],
          [103.9634181, 2.4776685],
          [103.964433, 2.4769984],
          [103.9648166, 2.4770197],
          [103.9649018, 2.4763597],
          [103.9646674, 2.4762107],
          [103.964377, 2.4750626],
          [103.9645497, 2.4747369],
          [103.9646455, 2.474277],
          [103.9640921, 2.4741456],
          [103.9634102, 2.4742733],
          [103.9630053, 2.474103],
          [103.9626643, 2.4742095],
          [103.9624299, 2.4744224],
          [103.9618546, 2.4742946],
          [103.9612509, 2.4738363],
          [103.9611973, 2.4734745],
          [103.9610376, 2.4732451],
          [103.9608743, 2.4732089],
          [103.9606186, 2.4735708],
          [103.9603987, 2.4737737],
          [103.9600892, 2.4738734],
          [103.9597797, 2.4737936],
          [103.9594803, 2.4738834],
          [103.959081, 2.4739233],
          [103.9588623, 2.4740417],
          [103.9582729, 2.4744768],
          [103.9581045, 2.4748174],
          [103.957607, 2.4750818],
          [103.9571569, 2.4752519],
          [103.9568928, 2.4755856],
          [103.9567375, 2.4758029],
        ],
      ],
      [
        [
          [103.8542834, 2.4928138],
          [103.8543142, 2.4928031],
          [103.8543611, 2.4927575],
          [103.8543169, 2.4927495],
          [103.854274, 2.4927254],
          [103.8542847, 2.4927053],
          [103.8543799, 2.4927133],
          [103.8544832, 2.4927374],
          [103.8545328, 2.4927388],
          [103.8545838, 2.4927267],
          [103.8546441, 2.4926705],
          [103.8547058, 2.492645],
          [103.8547031, 2.49257],
          [103.8546642, 2.4925365],
          [103.8547152, 2.4925271],
          [103.8547501, 2.4925043],
          [103.8547031, 2.4924708],
          [103.8547031, 2.4924454],
          [103.8547943, 2.4924212],
          [103.8548493, 2.4924092],
          [103.8548922, 2.4923757],
          [103.8549083, 2.4922966],
          [103.8548654, 2.4922605],
          [103.8548319, 2.4922109],
          [103.8547983, 2.4921546],
          [103.8547407, 2.4921024],
          [103.8547058, 2.4920528],
          [103.8546978, 2.4919938],
          [103.8546253, 2.4919014],
          [103.8545918, 2.4918585],
          [103.854632, 2.4917888],
          [103.8546709, 2.4917915],
          [103.8547299, 2.4918438],
          [103.8548345, 2.4918532],
          [103.8548734, 2.491817],
          [103.8548895, 2.4917393],
          [103.8548748, 2.4917031],
          [103.8548305, 2.4916683],
          [103.854848, 2.4916482],
          [103.8548949, 2.4916549],
          [103.85497, 2.4916723],
          [103.8550075, 2.4916415],
          [103.8550572, 2.4916013],
          [103.8550746, 2.4915155],
          [103.8550558, 2.491474],
          [103.8550464, 2.4914298],
          [103.8550625, 2.4914164],
          [103.8551457, 2.4913708],
          [103.8551966, 2.4913346],
          [103.8552704, 2.4913212],
          [103.8553401, 2.4913253],
          [103.8553951, 2.4913614],
          [103.85543, 2.4914418],
          [103.8554447, 2.4915048],
          [103.8554407, 2.4915289],
          [103.8554206, 2.491612],
          [103.8554327, 2.491683],
          [103.8555024, 2.4917084],
          [103.855552, 2.4917111],
          [103.8556043, 2.4916817],
          [103.8556338, 2.491624],
          [103.8556151, 2.4915812],
          [103.8556043, 2.4915383],
          [103.8556084, 2.4914914],
          [103.8556446, 2.491474],
          [103.8556929, 2.4915035],
          [103.8557425, 2.4915302],
          [103.8557465, 2.4914847],
          [103.8557264, 2.4914123],
          [103.8556888, 2.4913601],
          [103.8556607, 2.4913534],
          [103.8555748, 2.4913467],
          [103.8555534, 2.4913306],
          [103.8555386, 2.4912931],
          [103.8554997, 2.4912663],
          [103.8555145, 2.4912221],
          [103.85554, 2.491202],
          [103.8555131, 2.4911873],
          [103.8554582, 2.4911913],
          [103.8554287, 2.491135],
          [103.8554434, 2.4910988],
          [103.8554984, 2.4910975],
          [103.8555091, 2.4910613],
          [103.8555011, 2.491005],
          [103.8553965, 2.4910385],
          [103.8553576, 2.4910372],
          [103.8553777, 2.4909916],
          [103.8554005, 2.4909702],
          [103.8553603, 2.4909327],
          [103.8552825, 2.4909126],
          [103.8552489, 2.4908858],
          [103.8552557, 2.4908697],
          [103.8553214, 2.4908603],
          [103.855367, 2.4908335],
          [103.8553294, 2.4907813],
          [103.8552141, 2.4907545],
          [103.8551698, 2.4907719],
          [103.8550759, 2.4907438],
          [103.8550196, 2.4906513],
          [103.8549298, 2.4906379],
          [103.8548292, 2.4906674],
          [103.8547085, 2.4906513],
          [103.8546025, 2.4907156],
          [103.8545824, 2.4908054],
          [103.8545636, 2.4909139],
          [103.8545368, 2.4910024],
          [103.8544832, 2.4910948],
          [103.8544268, 2.4911672],
          [103.8543598, 2.4912502],
          [103.8542512, 2.4913708],
          [103.854164, 2.4914887],
          [103.8540969, 2.4915624],
          [103.854046, 2.4916642],
          [103.8540326, 2.4917567],
          [103.8540661, 2.491884],
          [103.8540875, 2.491963],
          [103.8540741, 2.4920903],
          [103.8540433, 2.4921854],
          [103.8540071, 2.4923154],
          [103.8539615, 2.492444],
          [103.8539065, 2.4925405],
          [103.8538368, 2.4926128],
          [103.8538488, 2.4926745],
          [103.853885, 2.4927187],
          [103.8539481, 2.4927374],
          [103.8540339, 2.4927281],
          [103.8541452, 2.4927562],
          [103.8541989, 2.4927857],
          [103.8542834, 2.4928138],
        ],
      ],
      [
        [
          [103.8427505, 2.4871001],
          [103.8427639, 2.4870545],
          [103.8427908, 2.4869795],
          [103.8429597, 2.486883],
          [103.8430536, 2.4868937],
          [103.8431502, 2.4869447],
          [103.8431931, 2.4869125],
          [103.8432521, 2.4868321],
          [103.8433594, 2.4867678],
          [103.8434506, 2.486816],
          [103.8435713, 2.4867839],
          [103.8436732, 2.4867464],
          [103.8438261, 2.4867249],
          [103.8439495, 2.4866445],
          [103.8439924, 2.4865883],
          [103.8441024, 2.4866258],
          [103.8441694, 2.4865936],
          [103.8442043, 2.4864864],
          [103.844266, 2.4864221],
          [103.8443384, 2.4864141],
          [103.8443813, 2.4864302],
          [103.8444081, 2.4865668],
          [103.8444752, 2.4864918],
          [103.8445315, 2.4864302],
          [103.8445556, 2.4864837],
          [103.84462, 2.4864864],
          [103.8446576, 2.4864302],
          [103.8447488, 2.4864677],
          [103.8448453, 2.4864945],
          [103.8448936, 2.4864141],
          [103.8448078, 2.4863337],
          [103.8447246, 2.4862908],
          [103.8447568, 2.4861327],
          [103.8448346, 2.4860094],
          [103.844958, 2.4856986],
          [103.8451377, 2.4854574],
          [103.8452289, 2.4852806],
          [103.8453228, 2.4851064],
          [103.8453818, 2.484967],
          [103.8454354, 2.4848867],
          [103.8455615, 2.4846803],
          [103.8456393, 2.4845704],
          [103.8457492, 2.4844311],
          [103.8457921, 2.4844016],
          [103.8458914, 2.4840908],
          [103.8459316, 2.4839702],
          [103.8460148, 2.4836996],
          [103.8461221, 2.483319],
          [103.8461998, 2.4832681],
          [103.8462615, 2.4831449],
          [103.8463822, 2.4830377],
          [103.8464761, 2.4829305],
          [103.8465163, 2.4828662],
          [103.8466451, 2.4826866],
          [103.8467658, 2.4825714],
          [103.8469509, 2.4824723],
          [103.8469616, 2.4823945],
          [103.8470689, 2.4822793],
          [103.8471011, 2.4822364],
          [103.8471762, 2.4821051],
          [103.8472057, 2.4819872],
          [103.8472781, 2.4818881],
          [103.8473773, 2.4818345],
          [103.8473344, 2.4817032],
          [103.8473666, 2.4816951],
          [103.8474015, 2.4817889],
          [103.8474337, 2.4817836],
          [103.8474605, 2.4817139],
          [103.8474176, 2.4816415],
          [103.8474256, 2.4815531],
          [103.8474578, 2.4814861],
          [103.8475356, 2.4813655],
          [103.8475436, 2.4812718],
          [103.8475061, 2.4811753],
          [103.847431, 2.4810627],
          [103.8474337, 2.4809421],
          [103.84749, 2.4808082],
          [103.8476026, 2.4806983],
          [103.8477475, 2.4806099],
          [103.8479513, 2.4805536],
          [103.8480559, 2.4805402],
          [103.8481444, 2.4805482],
          [103.848292, 2.4805616],
          [103.8484288, 2.4806233],
          [103.8485441, 2.4807519],
          [103.8486648, 2.4808108],
          [103.8488016, 2.4808108],
          [103.8489008, 2.4808108],
          [103.848984, 2.4808778],
          [103.8490939, 2.480918],
          [103.8491422, 2.480977],
          [103.8491932, 2.4810333],
          [103.849279, 2.4810413],
          [103.8492737, 2.4809984],
          [103.8492656, 2.4809555],
          [103.8493353, 2.4809823],
          [103.8494292, 2.4810252],
          [103.8494668, 2.4810413],
          [103.8495392, 2.4810601],
          [103.8496626, 2.4810976],
          [103.8497511, 2.481127],
          [103.8496814, 2.4810199],
          [103.8497082, 2.4809663],
          [103.8497618, 2.480918],
          [103.8498396, 2.4808671],
          [103.8499254, 2.480851],
          [103.8500944, 2.48091],
          [103.8502151, 2.4809636],
          [103.8503063, 2.4810118],
          [103.850419, 2.4810413],
          [103.8505182, 2.4810091],
          [103.8505611, 2.4809529],
          [103.8505397, 2.4808805],
          [103.8505853, 2.4808564],
          [103.8506469, 2.4808644],
          [103.8507086, 2.4807519],
          [103.8507703, 2.4807224],
          [103.8508749, 2.4807224],
          [103.8507998, 2.4806501],
          [103.8507864, 2.4806045],
          [103.8508106, 2.4805188],
          [103.8508293, 2.4804464],
          [103.850891, 2.4803741],
          [103.850942, 2.4803124],
          [103.8509366, 2.4802133],
          [103.8509849, 2.4801758],
          [103.8510144, 2.4801409],
          [103.8510734, 2.48009],
          [103.8511753, 2.4801195],
          [103.8512639, 2.4801624],
          [103.8513229, 2.48009],
          [103.8513148, 2.4800578],
          [103.8512773, 2.4799801],
          [103.8512934, 2.4799346],
          [103.8514006, 2.4799507],
          [103.8514462, 2.4799105],
          [103.8514811, 2.4798462],
          [103.8514918, 2.4797148],
          [103.851516, 2.4796345],
          [103.8515804, 2.4795969],
          [103.8516072, 2.4795433],
          [103.8516608, 2.4795648],
          [103.8517413, 2.4795675],
          [103.8517708, 2.4796479],
          [103.851803, 2.479613],
          [103.8517896, 2.4794951],
          [103.85187, 2.4795407],
          [103.8519022, 2.4794951],
          [103.8518754, 2.4793638],
          [103.8519317, 2.479329],
          [103.8520015, 2.4793531],
          [103.8520497, 2.4793772],
          [103.8520658, 2.4792968],
          [103.8520122, 2.4792271],
          [103.8520766, 2.4791709],
          [103.8521114, 2.479203],
          [103.8522214, 2.4792647],
          [103.8522885, 2.4793075],
          [103.8522992, 2.4792057],
          [103.8522509, 2.4791655],
          [103.8523662, 2.4791468],
          [103.8524628, 2.479002],
          [103.8524735, 2.4789538],
          [103.8524467, 2.4788841],
          [103.8524494, 2.4788386],
          [103.8525245, 2.4788279],
          [103.8525996, 2.4788064],
          [103.8526452, 2.4787368],
          [103.8526854, 2.4786617],
          [103.8527069, 2.4786189],
          [103.8527793, 2.4785679],
          [103.8528678, 2.4785036],
          [103.8529617, 2.4784393],
          [103.8530502, 2.4783777],
          [103.8531253, 2.4783455],
          [103.8532031, 2.4783187],
          [103.8532272, 2.4782357],
          [103.8532111, 2.4781472],
          [103.8532621, 2.4781526],
          [103.853356, 2.4782089],
          [103.8534016, 2.4781445],
          [103.8533962, 2.478032],
          [103.8533426, 2.4779248],
          [103.8533801, 2.4778498],
          [103.8534364, 2.4779489],
          [103.8534955, 2.4779945],
          [103.8535679, 2.47804],
          [103.8536537, 2.4779972],
          [103.8536296, 2.4779355],
          [103.8535115, 2.4778364],
          [103.853525, 2.4777078],
          [103.8536001, 2.4776381],
          [103.8536939, 2.4775496],
          [103.8537154, 2.4774344],
          [103.85371, 2.4773085],
          [103.8537342, 2.4771316],
          [103.8538736, 2.4769735],
          [103.853997, 2.4768556],
          [103.8540534, 2.476735],
          [103.8541258, 2.4765689],
          [103.8542089, 2.4763518],
          [103.8541928, 2.4762286],
          [103.8541177, 2.4760571],
          [103.854056, 2.4758722],
          [103.8540346, 2.4756765],
          [103.8540507, 2.4755292],
          [103.8540265, 2.4753871],
          [103.8540024, 2.4753094],
          [103.8539246, 2.4752451],
          [103.8536859, 2.4751245],
          [103.8533479, 2.4750629],
          [103.8530636, 2.4750575],
          [103.8529349, 2.4750709],
          [103.8527578, 2.4751004],
          [103.8526157, 2.4751192],
          [103.8524279, 2.4751728],
          [103.852275, 2.4752505],
          [103.8520578, 2.4753148],
          [103.8517735, 2.4753925],
          [103.8515267, 2.4756256],
          [103.8513846, 2.4757784],
          [103.8512424, 2.4758963],
          [103.8510305, 2.4760597],
          [103.8508937, 2.4761401],
          [103.8506952, 2.4761508],
          [103.8505584, 2.4762205],
          [103.8503787, 2.4763036],
          [103.8502607, 2.4763036],
          [103.8501373, 2.4763599],
          [103.8499388, 2.4763518],
          [103.849676, 2.476317],
          [103.8493085, 2.4763116],
          [103.8488284, 2.4762929],
          [103.8486085, 2.4762875],
          [103.8483778, 2.4762527],
          [103.8480291, 2.4762312],
          [103.8477475, 2.4762178],
          [103.847439, 2.4762955],
          [103.8471869, 2.4764161],
          [103.8471547, 2.4765206],
          [103.8470018, 2.4766734],
          [103.8468302, 2.476877],
          [103.8467738, 2.4769869],
          [103.8467497, 2.477145],
          [103.846739, 2.4772897],
          [103.846688, 2.477421],
          [103.846637, 2.477547],
          [103.84657, 2.4777372],
          [103.8465029, 2.4778873],
          [103.8464386, 2.4781981],
          [103.8462884, 2.478375],
          [103.8460631, 2.4785465],
          [103.8459853, 2.4786135],
          [103.8457573, 2.4787073],
          [103.845591, 2.478785],
          [103.8453952, 2.4788573],
          [103.8452611, 2.4789109],
          [103.8450492, 2.4789619],
          [103.8449419, 2.4788332],
          [103.8447327, 2.4788064],
          [103.8446093, 2.4787796],
          [103.8445154, 2.4788547],
          [103.8444108, 2.4788466],
          [103.8442687, 2.4788386],
          [103.8440997, 2.4789324],
          [103.844038, 2.4789217],
          [103.8440407, 2.478793],
          [103.8439977, 2.4786296],
          [103.8439361, 2.4785358],
          [103.8438261, 2.4784875],
          [103.8437376, 2.4784179],
          [103.8436276, 2.4783187],
          [103.8435042, 2.4782758],
          [103.8433513, 2.4783026],
          [103.8430509, 2.4783911],
          [103.8428739, 2.4783857],
          [103.8427183, 2.4783482],
          [103.8425976, 2.4783375],
          [103.8424152, 2.4783562],
          [103.8423375, 2.478367],
          [103.8423267, 2.4782732],
          [103.8422382, 2.4781981],
          [103.8421148, 2.4781606],
          [103.8420102, 2.4781365],
          [103.8418198, 2.4781258],
          [103.8416508, 2.4781472],
          [103.8414711, 2.4782062],
          [103.8412941, 2.4781847],
          [103.8411412, 2.4781713],
          [103.8410741, 2.478166],
          [103.8409213, 2.4782357],
          [103.840814, 2.4783053],
          [103.8407281, 2.4783536],
          [103.8406262, 2.4783294],
          [103.8405833, 2.4782812],
          [103.8405565, 2.4781579],
          [103.8404465, 2.4781338],
          [103.8402695, 2.4781285],
          [103.8401461, 2.4780883],
          [103.8399637, 2.4780106],
          [103.8397116, 2.4779302],
          [103.8394514, 2.47789],
          [103.8391617, 2.4778739],
          [103.8389606, 2.4778605],
          [103.8387004, 2.4778632],
          [103.8384375, 2.4779141],
          [103.8382686, 2.47804],
          [103.8381103, 2.4780829],
          [103.8379789, 2.4782169],
          [103.8378582, 2.478375],
          [103.8377616, 2.4785224],
          [103.837708, 2.4786671],
          [103.8376972, 2.4787636],
          [103.837716, 2.4789243],
          [103.8377697, 2.4791414],
          [103.8378206, 2.4792888],
          [103.8379065, 2.4794978],
          [103.8379923, 2.4797041],
          [103.8381103, 2.4799775],
          [103.8381908, 2.4801463],
          [103.8384161, 2.4804625],
          [103.8385126, 2.4806099],
          [103.8385851, 2.4807599],
          [103.8386816, 2.4809314],
          [103.8388157, 2.4811646],
          [103.8389176, 2.4814834],
          [103.8390088, 2.4816255],
          [103.8390947, 2.4817782],
          [103.8391859, 2.4819202],
          [103.8392932, 2.4820783],
          [103.8393736, 2.4821587],
          [103.8394916, 2.4822659],
          [103.8395802, 2.4823677],
          [103.8397169, 2.4825392],
          [103.8397813, 2.4826679],
          [103.8398725, 2.4828715],
          [103.8399422, 2.4830564],
          [103.8400522, 2.4832145],
          [103.8401407, 2.4832628],
          [103.8402373, 2.4832922],
          [103.8403902, 2.483386],
          [103.8404733, 2.4834477],
          [103.8406021, 2.4835629],
          [103.840696, 2.483654],
          [103.8407362, 2.4838228],
          [103.8407389, 2.48393],
          [103.8407818, 2.4841363],
          [103.8408435, 2.4843105],
          [103.8408086, 2.4843936],
          [103.8407335, 2.484549],
          [103.8407979, 2.4846106],
          [103.840881, 2.4846535],
          [103.8408569, 2.484758],
          [103.8408113, 2.4847929],
          [103.8407389, 2.4849295],
          [103.840755, 2.4850126],
          [103.8407362, 2.4851359],
          [103.8406584, 2.4852484],
          [103.8405672, 2.4853717],
          [103.8404304, 2.4855432],
          [103.8403285, 2.4856262],
          [103.8401836, 2.4857468],
          [103.8400656, 2.4858808],
          [103.8400576, 2.4860148],
          [103.8401676, 2.4861113],
          [103.8402239, 2.4862024],
          [103.8402695, 2.4863337],
          [103.8403419, 2.4864489],
          [103.8404304, 2.4865936],
          [103.8404921, 2.4867464],
          [103.8406369, 2.4868964],
          [103.8408381, 2.487076],
          [103.8409669, 2.4871563],
          [103.8411063, 2.4872448],
          [103.8412378, 2.4873332],
          [103.8414175, 2.4875476],
          [103.841565, 2.4876601],
          [103.8417313, 2.4877325],
          [103.8418332, 2.4877914],
          [103.8419432, 2.4878611],
          [103.842088, 2.4879201],
          [103.84208, 2.4878557],
          [103.8421148, 2.4877861],
          [103.8422007, 2.4878155],
          [103.8422034, 2.4877459],
          [103.842257, 2.4876494],
          [103.8422865, 2.4876414],
          [103.8423589, 2.4877325],
          [103.842375, 2.4877673],
          [103.8423938, 2.4877995],
          [103.8425547, 2.4877995],
          [103.8426432, 2.4877298],
          [103.8426298, 2.4876012],
          [103.8425896, 2.4874967],
          [103.8425467, 2.4874082],
          [103.8426218, 2.4872421],
          [103.8427505, 2.4871001],
        ],
      ],
      [
        [
          [103.976564, 2.5170585],
          [103.9763645, 2.5171196],
          [103.9761553, 2.5173382],
          [103.9759595, 2.5177268],
          [103.9758463, 2.5179626],
          [103.9757603, 2.5181211],
          [103.9755829, 2.5184478],
          [103.9754783, 2.5188712],
          [103.9753281, 2.5193669],
          [103.975261, 2.5194955],
          [103.9749027, 2.5197183],
          [103.9747038, 2.5202187],
          [103.9746159, 2.520377],
          [103.9744376, 2.5202496],
          [103.9743596, 2.5202684],
          [103.9742929, 2.5203754],
          [103.9743417, 2.5207147],
          [103.9742511, 2.52083],
          [103.9743615, 2.5209231],
          [103.9743659, 2.5212239],
          [103.9742558, 2.5214193],
          [103.9742559, 2.5214781],
          [103.974154, 2.521521],
          [103.9740628, 2.5216175],
          [103.973993, 2.5216871],
          [103.9739501, 2.5217999],
          [103.9738321, 2.5218747],
          [103.9737356, 2.521939],
          [103.9736819, 2.5220944],
          [103.9736658, 2.5222338],
          [103.9736122, 2.5223731],
          [103.9736068, 2.5225178],
          [103.9736497, 2.5226732],
          [103.973639, 2.5227965],
          [103.9736763, 2.5230912],
          [103.973572, 2.5234556],
          [103.9735375, 2.5236974],
          [103.9736148, 2.5237953],
          [103.973553, 2.5238858],
          [103.9734796, 2.5238739],
          [103.9734651, 2.5239691],
          [103.973533, 2.5240742],
          [103.9736779, 2.5241289],
          [103.9737118, 2.5240805],
          [103.9737307, 2.5239919],
          [103.9738222, 2.5239602],
          [103.9738224, 2.5239198],
          [103.9737592, 2.5237557],
          [103.9737568, 2.5236348],
          [103.9739126, 2.5236754],
          [103.9739794, 2.5238629],
          [103.9740335, 2.5238965],
          [103.9740414, 2.524056],
          [103.974152, 2.5241237],
          [103.9741814, 2.5241935],
          [103.9742622, 2.5242246],
          [103.9743612, 2.5241689],
          [103.9744314, 2.5241241],
          [103.9743605, 2.5240801],
          [103.9743394, 2.5240337],
          [103.9744238, 2.5240168],
          [103.9746593, 2.5240648],
          [103.9748071, 2.5240097],
          [103.9748604, 2.5239325],
          [103.9749046, 2.5237516],
          [103.9749978, 2.5237569],
          [103.9752499, 2.5234862],
          [103.9752899, 2.5235295],
          [103.975252, 2.5235815],
          [103.9752046, 2.5236122],
          [103.9752175, 2.5236452],
          [103.9752576, 2.5236591],
          [103.9753307, 2.5237278],
          [103.9754229, 2.5237013],
          [103.9755329, 2.5236016],
          [103.9756078, 2.5236272],
          [103.975962, 2.5234384],
          [103.9759993, 2.5232198],
          [103.9759583, 2.5232042],
          [103.975859, 2.5231853],
          [103.9757739, 2.5232281],
          [103.9757677, 2.5231921],
          [103.9758417, 2.5231306],
          [103.9757808, 2.5230309],
          [103.9759215, 2.5230903],
          [103.976028, 2.5230363],
          [103.9761785, 2.5229836],
          [103.976184, 2.522866],
          [103.9761082, 2.522811],
          [103.9758877, 2.522817],
          [103.9761956, 2.5226821],
          [103.9762015, 2.5226055],
          [103.9762516, 2.5226029],
          [103.9762599, 2.5225011],
          [103.9762219, 2.5224667],
          [103.9762188, 2.5224176],
          [103.9762944, 2.5224401],
          [103.9763259, 2.5224813],
          [103.9764017, 2.5224803],
          [103.9763455, 2.5222812],
          [103.976247, 2.5222354],
          [103.9761507, 2.5222245],
          [103.9762089, 2.5221836],
          [103.9763538, 2.5221621],
          [103.9763419, 2.5221186],
          [103.9763944, 2.5220591],
          [103.9764618, 2.522133],
          [103.9767329, 2.5222343],
          [103.9769343, 2.5223686],
          [103.9771944, 2.5222923],
          [103.9772951, 2.5222222],
          [103.9772992, 2.5221789],
          [103.9772478, 2.5221427],
          [103.9772217, 2.5220582],
          [103.9770853, 2.522022],
          [103.977003, 2.5220035],
          [103.9769558, 2.5218517],
          [103.9768405, 2.5218195],
          [103.9768093, 2.5215504],
          [103.976878, 2.5213211],
          [103.9769773, 2.5211174],
          [103.977157, 2.5211577],
          [103.9772069, 2.5211131],
          [103.9772482, 2.521029],
          [103.9772723, 2.5206994],
          [103.9773635, 2.520745],
          [103.9775056, 2.5207718],
          [103.9775057, 2.5207102],
          [103.9775403, 2.5206874],
          [103.9773789, 2.5205708],
          [103.9773796, 2.5202626],
          [103.9773189, 2.5201959],
          [103.9774102, 2.5201893],
          [103.9777257, 2.5203377],
          [103.977849, 2.520327],
          [103.9778045, 2.5202803],
          [103.9778544, 2.5202599],
          [103.9779404, 2.5202997],
          [103.9780096, 2.5201813],
          [103.9779966, 2.5200256],
          [103.9778999, 2.5199571],
          [103.9777524, 2.5199491],
          [103.97757, 2.5199277],
          [103.9773877, 2.519917],
          [103.9776237, 2.5198312],
          [103.9777524, 2.5198098],
          [103.9778168, 2.5196705],
          [103.9777202, 2.5195097],
          [103.9775381, 2.5191835],
          [103.9775657, 2.5189136],
          [103.9776754, 2.5185768],
          [103.9778368, 2.5184894],
          [103.977948, 2.5183901],
          [103.9781396, 2.5180581],
          [103.9781266, 2.5180135],
          [103.9780354, 2.5180211],
          [103.9779847, 2.517969],
          [103.9783294, 2.5179483],
          [103.9783585, 2.5179058],
          [103.9783133, 2.5178426],
          [103.9781088, 2.5178283],
          [103.9783156, 2.5177281],
          [103.9783215, 2.5176047],
          [103.9782577, 2.5175609],
          [103.9783068, 2.5174475],
          [103.9782898, 2.5173176],
          [103.9783133, 2.5171512],
          [103.9782596, 2.5168377],
          [103.9782156, 2.5167386],
          [103.9781481, 2.5167027],
          [103.9781613, 2.5166069],
          [103.9781005, 2.5164926],
          [103.9780095, 2.5164654],
          [103.9780328, 2.5164213],
          [103.9779144, 2.5163625],
          [103.9779072, 2.5162685],
          [103.9780725, 2.5164062],
          [103.9782071, 2.5163569],
          [103.978208, 2.5163234],
          [103.9781411, 2.5162772],
          [103.9781013, 2.5162872],
          [103.977934, 2.5162066],
          [103.9779262, 2.5161532],
          [103.9781417, 2.5162077],
          [103.978156, 2.5161543],
          [103.9780481, 2.5160996],
          [103.9779224, 2.5161022],
          [103.9778035, 2.5161079],
          [103.9778295, 2.5160464],
          [103.977921, 2.5160489],
          [103.9779211, 2.5159906],
          [103.9780204, 2.5159879],
          [103.9780282, 2.5158082],
          [103.9779973, 2.5156828],
          [103.9779076, 2.515545],
          [103.9778249, 2.5155575],
          [103.9777989, 2.515649],
          [103.9776958, 2.5157296],
          [103.9777015, 2.5159967],
          [103.977472, 2.5161789],
          [103.9773592, 2.5163976],
          [103.9773961, 2.5166566],
          [103.9772516, 2.5167838],
          [103.9772147, 2.5168163],
          [103.9768376, 2.5169964],
          [103.976564, 2.5170585],
        ],
      ],
      [
        [
          [103.9730972, 2.5246283],
          [103.9731669, 2.5247086],
          [103.9732822, 2.5247408],
          [103.9733412, 2.5246792],
          [103.9733815, 2.5246015],
          [103.9733842, 2.5244889],
          [103.973293, 2.5244996],
          [103.973293, 2.5244192],
          [103.9733707, 2.5244005],
          [103.9733627, 2.5243415],
          [103.9733144, 2.5242879],
          [103.9732608, 2.5242826],
          [103.973183, 2.5243469],
          [103.9731293, 2.5244219],
          [103.9731562, 2.5244755],
          [103.9732044, 2.524505],
          [103.9731186, 2.5245318],
          [103.9730972, 2.5246283],
        ],
      ],
      [
        [
          [103.9691483, 2.5376312],
          [103.9692878, 2.5377813],
          [103.9695023, 2.5379528],
          [103.9698671, 2.5378992],
          [103.9700924, 2.5372239],
          [103.970189, 2.5369345],
          [103.9704787, 2.5363021],
          [103.9706074, 2.536002],
          [103.9704357, 2.5352732],
          [103.9702426, 2.5350052],
          [103.9698349, 2.5346194],
          [103.9696633, 2.5339655],
          [103.9692127, 2.5338691],
          [103.9689981, 2.5343407],
          [103.9688801, 2.5349945],
          [103.9688371, 2.535659],
          [103.9688371, 2.5364736],
          [103.9691483, 2.5376312],
        ],
      ],
      [
        [
          [103.9473939, 2.4944719],
          [103.9515138, 2.4951578],
          [103.9537454, 2.4934429],
          [103.955462, 2.4900129],
          [103.9540887, 2.4874404],
          [103.9504838, 2.4888124],
          [103.9475656, 2.4920709],
          [103.9473939, 2.4944719],
        ],
      ],
      [
        [
          [103.8539856, 2.4933779],
          [103.8540312, 2.4933792],
          [103.8540715, 2.4933618],
          [103.8541036, 2.4933404],
          [103.8541238, 2.4932895],
          [103.8541425, 2.4932519],
          [103.8541399, 2.4933109],
          [103.8541667, 2.4933096],
          [103.8542096, 2.493272],
          [103.8542404, 2.4932479],
          [103.8542552, 2.4932131],
          [103.854227, 2.4931809],
          [103.8541707, 2.4932198],
          [103.8541814, 2.4931903],
          [103.8541922, 2.4931394],
          [103.8541694, 2.4930992],
          [103.8541184, 2.4930764],
          [103.8540567, 2.4930563],
          [103.853991, 2.493],
          [103.8539776, 2.4929692],
          [103.8538998, 2.4929156],
          [103.8538462, 2.4929612],
          [103.8538073, 2.4930027],
          [103.8537576, 2.493055],
          [103.853771, 2.4931287],
          [103.8538166, 2.4931876],
          [103.8538555, 2.4932573],
          [103.8539078, 2.4933256],
          [103.8539856, 2.4933779],
        ],
      ],
      [
        [
          [103.9593153, 2.5533817],
          [103.959444, 2.5533924],
          [103.9598196, 2.5532852],
          [103.9601414, 2.5530173],
          [103.9604526, 2.5525778],
          [103.9607637, 2.5518597],
          [103.9609139, 2.5512166],
          [103.9610426, 2.5506271],
          [103.9612787, 2.5499304],
          [103.9615362, 2.5495982],
          [103.961901, 2.5490087],
          [103.9624267, 2.5486121],
          [103.9625018, 2.5481726],
          [103.9624052, 2.5479904],
          [103.961858, 2.5482905],
          [103.9614825, 2.5486764],
          [103.960871, 2.5490301],
          [103.9597659, 2.5494052],
          [103.9591329, 2.5495982],
          [103.9589076, 2.5499304],
          [103.9587789, 2.5504663],
          [103.9589076, 2.5508736],
          [103.9591973, 2.5512488],
          [103.9594655, 2.5519347],
          [103.9592617, 2.5523634],
          [103.9589827, 2.5526314],
          [103.9586179, 2.5529851],
          [103.9586072, 2.5533066],
          [103.9588754, 2.5535317],
          [103.9593153, 2.5533817],
        ],
      ],
      [
        [
          [103.9452122, 2.5619401],
          [103.9455448, 2.5620044],
          [103.9457487, 2.5619616],
          [103.9461564, 2.56164],
          [103.9461564, 2.5613828],
          [103.945974, 2.5609862],
          [103.9458452, 2.560386],
          [103.9461671, 2.5600109],
          [103.9467143, 2.5598286],
          [103.9474331, 2.5599251],
          [103.9477872, 2.5596893],
          [103.9475189, 2.5589283],
          [103.9468323, 2.558521],
          [103.9456521, 2.5582852],
          [103.9446758, 2.5584139],
          [103.9438497, 2.5582531],
          [103.9430558, 2.5584567],
          [103.9427983, 2.5585961],
          [103.9425515, 2.5587997],
          [103.941543, 2.5592284],
          [103.9412318, 2.5598608],
          [103.9413177, 2.5605039],
          [103.9417897, 2.5609969],
          [103.9422082, 2.5612006],
          [103.9428841, 2.561222],
          [103.9435493, 2.5609755],
          [103.9438604, 2.5607826],
          [103.9444827, 2.5605039],
          [103.9447724, 2.5605789],
          [103.944944, 2.5611041],
          [103.9447402, 2.5611791],
          [103.9445363, 2.5615435],
          [103.9452122, 2.5619401],
        ],
      ],
      [
        [
          [103.7119304, 2.6537448],
          [103.7119197, 2.653927],
          [103.7120163, 2.6542164],
          [103.7122952, 2.6540235],
          [103.7124562, 2.6533483],
          [103.7126064, 2.6524695],
          [103.7128746, 2.6512477],
          [103.712939, 2.6505403],
          [103.7127673, 2.6497794],
          [103.712542, 2.6495758],
          [103.7121021, 2.6496294],
          [103.7119304, 2.6502295],
          [103.7115013, 2.6510976],
          [103.7114262, 2.65173],
          [103.71163, 2.6522873],
          [103.7118661, 2.652866],
          [103.7119304, 2.6537448],
        ],
      ],
      [
        [
          [103.7503543, 2.6526432],
          [103.7495231, 2.6518884],
          [103.7483141, 2.6516997],
          [103.7477096, 2.6529452],
          [103.7471806, 2.6542661],
          [103.7472562, 2.6546813],
          [103.7477851, 2.6542661],
          [103.7486919, 2.6542661],
          [103.7491453, 2.6536245],
          [103.7503543, 2.6526432],
        ],
      ],
      [
        [
          [103.8807407, 2.6541762],
          [103.880923, 2.6536832],
          [103.8810625, 2.6531259],
          [103.8809982, 2.6526972],
          [103.8804939, 2.6529223],
          [103.8799145, 2.6533617],
          [103.8792172, 2.6541762],
          [103.8788524, 2.6550014],
          [103.8790562, 2.6555587],
          [103.8794747, 2.6554194],
          [103.8800755, 2.6549907],
          [103.8807407, 2.6541762],
        ],
      ],
    ],
  },
};
