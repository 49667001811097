import React from "react";
import { AppBar as MAppBar, Grid, Tooltip, Menu } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import styled from "styled-components";
import Icon from "../icon";
import ThemeDialog from "./theme-dialog";
import LanguageDialog from "./language-dialog";
import { Link } from "react-router-dom";
import { AppHistory } from "../../utils/history";
import MenuButton from "./menu";

const CustomAppBar = styled(MAppBar)`
  background: ${({ theme, scrolled }) => {
    return scrolled ? "transparent" : theme.body;
  }};
  box-shadow: ${({ scrolled }) => {
    return scrolled
      ? "none"
      : "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
  }};
`;

const AppBarContent = styled(Grid)`
  height: 50px;
  padding: 0px 1rem;
`;

const AppBarTitle = styled(Grid)`
  font-family: "Staatliches", cursive;
  font-size: 24px;
`;

const CustomMenuItem = styled.div`
  display: flex;
  min-width: 100px;
  max-width: 250px;

  height: 35px;
  font-weight: bold;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const AppBar = (props) => {
  const scroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [themeDialog, setThemeDialog] = React.useState(false);
  const performOpenThemeDialog = () => {
    setThemeDialog(true);
    handleClose();
  };
  const performCloseThemeDialog = () => {
    setThemeDialog(false);
  };

  const [languageDialog, setLanguageDialog] = React.useState(false);
  const performOpenLanguageDialog = () => {
    setLanguageDialog(true);
    handleClose();
  };
  const performCloseLanguageDialog = () => {
    setLanguageDialog(false);
  };

  return (
    <React.Fragment>
      <CustomAppBar
        //temporary put as false
        scrolled={!scroll}
      >
        <AppBarContent container justify="space-between" alignItems="center">
          <AppBarTitle component={Link} to="/">
            bolehtak
          </AppBarTitle>

          <MenuButton
            open={anchorEl}
            close={handleClose}
            translate={props.translate}
            changelanguage={props.switchLanguage}
            changetheme={props.switchTheme}
          />
        </AppBarContent>
      </CustomAppBar>
    </React.Fragment>
  );
};

export default AppBar;
