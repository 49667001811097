import React from "react";
import { Tooltip, Menu } from "@material-ui/core";

import styled from "styled-components";
import Icon from "../../icon";
import ThemeDialog from "../theme-dialog";
import LanguageDialog from "../language-dialog";

import { AppHistory } from "../../../utils/history";

const CustomMenuItem = styled.div`
  display: flex;
  min-width: 100px;
  max-width: 250px;

  height: 35px;
  font-weight: bold;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const CustomMenu = styled(Menu)`
  .MuiPopover-paper {
    border-radius: 10px;
  }
`;

const MenuButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [themeDialog, setThemeDialog] = React.useState(false);
  const performOpenThemeDialog = () => {
    setThemeDialog(true);
    handleClose();
  };
  const performCloseThemeDialog = () => {
    setThemeDialog(false);
  };

  const [languageDialog, setLanguageDialog] = React.useState(false);
  const performOpenLanguageDialog = () => {
    setLanguageDialog(true);
    handleClose();
  };
  const performCloseLanguageDialog = () => {
    setLanguageDialog(false);
  };

  return (
    <div>
      <Tooltip title={props.translate("general.menu")} placement="bottom">
        <div onClick={handleClick}>
          <Icon name="menu" size="sm" />
        </div>
      </Tooltip>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <CustomMenuItem onClick={performOpenLanguageDialog}>
          {props.translate("general.change-language")}
        </CustomMenuItem>
        <CustomMenuItem onClick={performOpenThemeDialog}>
          {props.translate("general.change-theme")}
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() => {
            AppHistory.push("/about");
          }}
        >
          {props.translate("about.title")}
        </CustomMenuItem>
      </CustomMenu>
      <LanguageDialog
        open={languageDialog}
        close={performCloseLanguageDialog}
        translate={props.translate}
        changelanguage={props.changelanguage}
      />
      <ThemeDialog
        open={themeDialog}
        close={performCloseThemeDialog}
        translate={props.translate}
        changetheme={props.changetheme}
      />
    </div>
  );
};

export default MenuButton;
