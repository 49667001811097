export const perak = {
  type: "Feature",
  properties: {
    gid: 10,
    id: 4445076,
    country: "MYS",
    name: "Perak",
    enname: "Perak",
    locname: "Perak / ????",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q188953",
    wikimedia: "en:Perak",
    timestamp: "2019-12-15 22:59:02",
    note: null,
    path: "0,2108121,4445076",
    rpath: "4445076,2108121,0",
    iso3166_2: "MY-08",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [100.574594748184737, 4.420927459485173],
          [100.57459, 4.4209573],
          [100.574555510287354, 4.420949609033354],
          [100.574541, 4.4209578],
          [100.5744635, 4.4210339],
          [100.5744287, 4.4211682],
          [100.574412, 4.4213559],
          [100.5743611, 4.4215078],
          [100.5742839, 4.4216862],
          [100.574269, 4.4218417],
          [100.5742569, 4.422058],
          [100.5742828, 4.422213],
          [100.5744587, 4.4223542],
          [100.5746994, 4.4224564],
          [100.5748582, 4.4225394],
          [100.5749708, 4.4225856],
          [100.5750832, 4.4226422],
          [100.5751915, 4.4226769],
          [100.575300289178955, 4.422680305574298],
          [100.5753169, 4.4226433],
          [100.5755047, 4.4226605],
          [100.5756436, 4.4226237],
          [100.5759052, 4.4224206],
          [100.5759322, 4.4223393],
          [100.5761748, 4.422193],
          [100.5762896, 4.4221255],
          [100.5763548, 4.4220156],
          [100.57638, 4.421918],
          [100.5764945, 4.4216668],
          [100.5764952, 4.4212824],
          [100.5765452, 4.4212213],
          [100.5766854, 4.4211365],
          [100.576707, 4.4209702],
          [100.5768609, 4.4208505],
          [100.5769121, 4.4207498],
          [100.5771111, 4.4205642],
          [100.5771013, 4.4203562],
          [100.5771474, 4.4202155],
          [100.57709, 4.4200051],
          [100.5769649, 4.4198223],
          [100.5769398, 4.4196929],
          [100.5770887, 4.4194562],
          [100.5771942, 4.4193192],
          [100.5772665, 4.4192942],
          [100.577435, 4.4192479],
          [100.5776039, 4.4192857],
          [100.5777306, 4.4192145],
          [100.5777633, 4.4190963],
          [100.5778654, 4.4190046],
          [100.577966033701614, 4.418947105437666],
          [100.5779956, 4.4189099],
          [100.578028503042816, 4.41888366312079],
          [100.5780948, 4.4187722],
          [100.5781434, 4.418681],
          [100.5782664, 4.4186086],
          [100.5783528, 4.4184366],
          [100.5783692, 4.4182934],
          [100.578318, 4.418216],
          [100.5782114, 4.4181009],
          [100.5781403, 4.4181232],
          [100.5780565, 4.4180815],
          [100.5778616, 4.4179737],
          [100.5777643, 4.4181435],
          [100.5776753, 4.4183321],
          [100.5775485, 4.4185307],
          [100.5774096, 4.4187057],
          [100.5772255, 4.4187837],
          [100.577155778398918, 4.418770693946406],
          [100.5771024, 4.4187956],
          [100.5769554, 4.4190227],
          [100.5769067, 4.4189352],
          [100.5767168, 4.418732],
          [100.5765267, 4.4186752],
          [100.5763848, 4.4186916],
          [100.5762853, 4.4187134],
          [100.5761972, 4.4187901],
          [100.5761196, 4.418908],
          [100.5760785, 4.4190779],
          [100.5759688, 4.4191448],
          [100.5758718, 4.419187],
          [100.5757845, 4.4192942],
          [100.5756595, 4.4193664],
          [100.575611, 4.4194872],
          [100.5755506, 4.4196403],
          [100.5755342, 4.4198159],
          [100.5754767, 4.4199704],
          [100.5753878, 4.4201521],
          [100.5752153, 4.4202617],
          [100.5750368, 4.4203768],
          [100.574878, 4.4205547],
          [100.5747583, 4.4207081],
          [100.5746736, 4.4208744],
          [100.5746131, 4.4209171],
          [100.574594748184737, 4.420927459485173],
        ],
      ],
      [
        [
          [100.5385206, 4.1944125],
          [100.5379747, 4.1948954],
          [100.5379822, 4.1950614],
          [100.538066, 4.195169],
          [100.5381005, 4.1952889],
          [100.5384613, 4.1954824],
          [100.5386413, 4.1954534],
          [100.5388034, 4.195367],
          [100.5390279, 4.1953467],
          [100.5392929, 4.1952718],
          [100.5395616, 4.1952212],
          [100.5399019, 4.19523],
          [100.5401418, 4.1951362],
          [100.5404084, 4.1950474],
          [100.5407085, 4.1949973],
          [100.5409333, 4.1949548],
          [100.5411844, 4.1949438],
          [100.5414279, 4.1949393],
          [100.5415042, 4.1950889],
          [100.5416926, 4.1949875],
          [100.5418045, 4.1949245],
          [100.5418763, 4.1948448],
          [100.5421337, 4.1949292],
          [100.5423636, 4.1952233],
          [100.5425419, 4.1955764],
          [100.5425953, 4.1959408],
          [100.5425832, 4.1963458],
          [100.5425167, 4.1966367],
          [100.5424016, 4.1966356],
          [100.5423639, 4.196876],
          [100.5422271, 4.1969436],
          [100.5420301, 4.1969721],
          [100.5416713, 4.1971743],
          [100.5414113, 4.1973628],
          [100.5410874, 4.197368],
          [100.5407495, 4.1976271],
          [100.540405, 4.1977953],
          [100.5401143, 4.1979712],
          [100.5398638, 4.1980606],
          [100.5397406, 4.1982401],
          [100.5394993, 4.1984337],
          [100.53933, 4.1986457],
          [100.5390782, 4.1987628],
          [100.5390233, 4.1989495],
          [100.5390865, 4.1991138],
          [100.5391509, 4.199214],
          [100.5392739, 4.1992309],
          [100.5393939, 4.1993204],
          [100.5393982, 4.1994347],
          [100.5391618, 4.1995825],
          [100.5389668, 4.1999216],
          [100.5390008, 4.2002383],
          [100.5391264, 4.2004327],
          [100.539357, 4.2004997],
          [100.5395654, 4.2006866],
          [100.5398138, 4.2006181],
          [100.5400531, 4.200682],
          [100.5401539, 4.2008353],
          [100.5400856, 4.2010156],
          [100.5399078, 4.2014194],
          [100.5400758, 4.2018486],
          [100.5401495, 4.2018205],
          [100.5401131, 4.2014999],
          [100.5403114, 4.2014594],
          [100.5405426, 4.2014803],
          [100.5406636, 4.2016467],
          [100.5407786, 4.2017234],
          [100.5409219, 4.2018024],
          [100.540926089746037, 4.201885749203071],
          [100.540945709258736, 4.201894226196315],
          [100.5410088, 4.2018887],
          [100.5411219, 4.2018815],
          [100.5412813, 4.2018005],
          [100.5413649, 4.2016374],
          [100.5413982, 4.2013759],
          [100.5413281, 4.201257],
          [100.541405, 4.2009114],
          [100.5415874, 4.2008302],
          [100.5418504, 4.2007473],
          [100.542054, 4.2007462],
          [100.5423951, 4.200777],
          [100.5426216, 4.2008307],
          [100.5427782, 4.2008613],
          [100.5429887, 4.2009503],
          [100.5431384, 4.2010898],
          [100.5431172, 4.2012989],
          [100.5430419, 4.201514],
          [100.5430095, 4.201802],
          [100.542998907397887, 4.201844936989467],
          [100.5430411, 4.2020559],
          [100.5432014, 4.2022884],
          [100.5431949, 4.2023491],
          [100.5432995, 4.2026173],
          [100.5435553, 4.2028757],
          [100.5438083, 4.2030283],
          [100.5441728, 4.2030806],
          [100.5442421, 4.2030646],
          [100.5442647, 4.2030332],
          [100.5443506, 4.2030355],
          [100.544380956495544, 4.203082956040506],
          [100.544468363150514, 4.203059439894564],
          [100.5445354, 4.2029335],
          [100.5446155, 4.2029544],
          [100.5447117, 4.2028799],
          [100.544638, 4.2027927],
          [100.544727, 4.202817],
          [100.54484819417226, 4.202801370307593],
          [100.5448623, 4.202713],
          [100.544832, 4.2025606],
          [100.5447486, 4.2023511],
          [100.5446489, 4.2022272],
          [100.5447283, 4.2020018],
          [100.5448188, 4.2017509],
          [100.5449125, 4.2016565],
          [100.5451335, 4.2015396],
          [100.5452872, 4.2015053],
          [100.5454581, 4.2014569],
          [100.5456075, 4.201711],
          [100.5459594, 4.2015481],
          [100.5459302, 4.2015045],
          [100.5461944, 4.2013801],
          [100.5461335, 4.2012175],
          [100.5463156, 4.2011041],
          [100.5464787, 4.2011355],
          [100.546521, 4.2011437],
          [100.5467968, 4.2009714],
          [100.546962, 4.2008754],
          [100.5470213, 4.2007483],
          [100.5471839, 4.2007583],
          [100.5472746, 4.2005184],
          [100.5471631, 4.2004117],
          [100.5473092, 4.2002196],
          [100.5472501, 4.2000775],
          [100.5470858, 4.1997225],
          [100.546813, 4.1997659],
          [100.5468105, 4.1995873],
          [100.5467819, 4.1993956],
          [100.5467929, 4.1991952],
          [100.5468718, 4.1987292],
          [100.5469158, 4.1984686],
          [100.5469876, 4.198254],
          [100.5470377, 4.1980373],
          [100.5471258, 4.1978898],
          [100.547157, 4.1976872],
          [100.5472445, 4.1974718],
          [100.5473323, 4.1973028],
          [100.5473942, 4.1971838],
          [100.547493, 4.1970492],
          [100.5476193, 4.1969027],
          [100.5476884, 4.1968151],
          [100.5479937, 4.1967374],
          [100.5481318, 4.1966748],
          [100.5482836, 4.1967519],
          [100.5483938, 4.1967561],
          [100.5485465, 4.1966058],
          [100.5485789, 4.1964469],
          [100.548604, 4.1962606],
          [100.5486561, 4.1961573],
          [100.5487829, 4.1961379],
          [100.5488595, 4.1960242],
          [100.548885, 4.1958959],
          [100.5488631, 4.1957559],
          [100.5488891, 4.1955916],
          [100.5490014, 4.195539],
          [100.5490909, 4.1955694],
          [100.5490965, 4.1954278],
          [100.5489528, 4.1950773],
          [100.5490788, 4.1949887],
          [100.5491286, 4.1948816],
          [100.5491306, 4.1947931],
          [100.5490866, 4.194733],
          [100.5490008, 4.1947045],
          [100.5488837, 4.1947414],
          [100.5488569, 4.1945845],
          [100.5487957, 4.1945265],
          [100.5485502, 4.1944696],
          [100.5484078, 4.194331],
          [100.548355, 4.1942867],
          [100.5482833, 4.1942266],
          [100.5479547, 4.1937366],
          [100.5477633, 4.1934972],
          [100.5475852, 4.1932618],
          [100.5474966, 4.1931051],
          [100.5473644, 4.1928801],
          [100.5473584, 4.1927678],
          [100.5473535, 4.1926743],
          [100.5473808, 4.1924178],
          [100.5473738, 4.1921642],
          [100.547234, 4.192011],
          [100.5471818, 4.1919388],
          [100.5471092, 4.1918387],
          [100.5470363, 4.1916553],
          [100.5469328, 4.1914621],
          [100.5469031, 4.191347],
          [100.5468845, 4.1912003],
          [100.5468223, 4.1910571],
          [100.5468032, 4.1909855],
          [100.5468151, 4.1909169],
          [100.5468796, 4.1908197],
          [100.5468185, 4.1906096],
          [100.5467757, 4.1904236],
          [100.5467382, 4.1902606],
          [100.5466885, 4.1901652],
          [100.5466611, 4.1899291],
          [100.546557, 4.1897278],
          [100.5465254, 4.1894624],
          [100.5466208, 4.1893475],
          [100.5467638, 4.1892983],
          [100.5469282, 4.189236],
          [100.5469961, 4.1891141],
          [100.547009, 4.1889463],
          [100.5469599, 4.1887907],
          [100.5468503, 4.1887169],
          [100.5468216, 4.1885362],
          [100.5468107, 4.1883682],
          [100.5469311, 4.188229],
          [100.5470603, 4.1881646],
          [100.5471896, 4.1881152],
          [100.5472739, 4.1880386],
          [100.5473228, 4.1879638],
          [100.5473686, 4.1877781],
          [100.5473549, 4.1876452],
          [100.5472999, 4.1875272],
          [100.5472707, 4.1874349],
          [100.5470669, 4.1873244],
          [100.5466468, 4.1872608],
          [100.5463904, 4.1873782],
          [100.5462549, 4.1875598],
          [100.5461455, 4.187796],
          [100.5460152, 4.1879351],
          [100.5459024, 4.1879956],
          [100.5457984, 4.1880515],
          [100.5455139, 4.1881304],
          [100.5452953, 4.1881911],
          [100.5450898, 4.1881804],
          [100.5449509, 4.1881168],
          [100.5449305, 4.1878736],
          [100.5448707, 4.1876877],
          [100.544736, 4.1876775],
          [100.5446314, 4.1879127],
          [100.5444463, 4.1880293],
          [100.5442814, 4.1879878],
          [100.5441038, 4.1879581],
          [100.5440468, 4.1878268],
          [100.5440379, 4.1877055],
          [100.5439712, 4.1876395],
          [100.5438478, 4.1875029],
          [100.5437569, 4.1874515],
          [100.5436558, 4.1874353],
          [100.5435221, 4.1874217],
          [100.5434082, 4.1873661],
          [100.5426287, 4.1876051],
          [100.542272, 4.1876332],
          [100.5421009, 4.1875988],
          [100.5413728, 4.1875488],
          [100.5409156, 4.1873312],
          [100.5402539, 4.1871904],
          [100.5401488, 4.1871687],
          [100.5400197, 4.1871622],
          [100.5398854, 4.1871643],
          [100.5397661, 4.1871767],
          [100.5397183, 4.1872187],
          [100.5396964, 4.187304],
          [100.5396535, 4.1873701],
          [100.5395453, 4.1874188],
          [100.5393269, 4.1876323],
          [100.5395034, 4.1879868],
          [100.5392958, 4.1882317],
          [100.5393831, 4.1884709],
          [100.5392978, 4.188747],
          [100.5396384, 4.1888722],
          [100.5393954, 4.1892731],
          [100.539103, 4.1900551],
          [100.5387223, 4.1903977],
          [100.5386188, 4.1907157],
          [100.538633, 4.1910847],
          [100.5387524, 4.1913495],
          [100.5389037, 4.1915062],
          [100.5391124, 4.1917181],
          [100.5392229, 4.1920211],
          [100.5392549, 4.1923183],
          [100.5392357, 4.1925712],
          [100.5390014, 4.1928759],
          [100.5388428, 4.1930562],
          [100.5387779, 4.1932095],
          [100.5386351, 4.1939787],
          [100.5385206, 4.1944125],
        ],
      ],
      [
        [
          [100.5965721, 4.206108],
          [100.5965104, 4.2075338],
          [100.5965659, 4.2076229],
          [100.5968309, 4.2077335],
          [100.5968956, 4.2078963],
          [100.596988, 4.2080438],
          [100.5971113, 4.2081667],
          [100.5972992, 4.208345],
          [100.5974933, 4.2084433],
          [100.5976597, 4.2084617],
          [100.5981342, 4.2084617],
          [100.5984145, 4.2085232],
          [100.598621, 4.2085693],
          [100.5988736, 4.2085877],
          [100.5988997, 4.2085852],
          [100.5990447, 4.2085716],
          [100.5991567, 4.2085491],
          [100.5992094, 4.2085385],
          [100.5997856, 4.2084064],
          [100.6005004, 4.2082098],
          [100.6011259, 4.2079178],
          [100.6015387, 4.2075952],
          [100.6018807, 4.2072019],
          [100.6020533, 4.2069653],
          [100.6023429, 4.2064153],
          [100.6023614, 4.2062493],
          [100.60246, 4.2058376],
          [100.6024754, 4.205392],
          [100.6024415, 4.2048543],
          [100.6024014, 4.2043903],
          [100.6023583, 4.2041752],
          [100.6023675, 4.2040831],
          [100.6023213, 4.2038649],
          [100.6022628, 4.2038004],
          [100.6022196, 4.2036959],
          [100.6022597, 4.2036191],
          [100.6021519, 4.2031305],
          [100.6021272, 4.2031151],
          [100.6020409, 4.2031336],
          [100.6019762, 4.2031121],
          [100.6017082, 4.2027525],
          [100.6015233, 4.202519],
          [100.6014494, 4.2024699],
          [100.6012922, 4.2024207],
          [100.6011197, 4.2024023],
          [100.6009318, 4.2023807],
          [100.6006976, 4.2023685],
          [100.6004542, 4.2024084],
          [100.6002971, 4.2024514],
          [100.5999427, 4.2024729],
          [100.5998195, 4.2024944],
          [100.5996993, 4.2024668],
          [100.5995668, 4.2023869],
          [100.5994128, 4.2023346],
          [100.5991571, 4.2022947],
          [100.5985902, 4.2023008],
          [100.5981588, 4.2023285],
          [100.5979247, 4.20239],
          [100.5977829, 4.2024668],
          [100.5976689, 4.2025528],
          [100.5975703, 4.2027187],
          [100.5974717, 4.2029615],
          [100.597367, 4.2037973],
          [100.5973177, 4.2042736],
          [100.5972253, 4.2048328],
          [100.5971421, 4.2052446],
          [100.5970127, 4.2059574],
          [100.5969788, 4.2063354],
          [100.596951, 4.2067348],
          [100.5968863, 4.206953],
          [100.5968155, 4.2070544],
          [100.59672, 4.2070329],
          [100.5966183, 4.2069499],
          [100.5966614, 4.206234],
          [100.5966583, 4.2061141],
          [100.596606, 4.2060834],
          [100.5965721, 4.206108],
        ],
      ],
      [
        [
          [100.5966973, 4.2097679],
          [100.5966832, 4.2098491],
          [100.5967133, 4.2100644],
          [100.5967929, 4.2103679],
          [100.5968778, 4.2106115],
          [100.5969592, 4.2107386],
          [100.5971097, 4.2108709],
          [100.5972618, 4.2109892],
          [100.5972689, 4.2110103],
          [100.5972618, 4.2110315],
          [100.5972778, 4.2110562],
          [100.5973096, 4.2110509],
          [100.5973468, 4.2110368],
          [100.5974494, 4.211058],
          [100.5978741, 4.2111445],
          [100.5980723, 4.2112009],
          [100.5983431, 4.2112398],
          [100.5986421, 4.211268],
          [100.5989359, 4.2112557],
          [100.5992084, 4.2112627],
          [100.5994172, 4.2112698],
          [100.5997959, 4.2112592],
          [100.6000295, 4.2112433],
          [100.6001781, 4.2112221],
          [100.6002967, 4.2111868],
          [100.6005267, 4.2111039],
          [100.6006329, 4.2110545],
          [100.6007957, 4.2109503],
          [100.6010329, 4.2107527],
          [100.601231, 4.2105974],
          [100.601316, 4.2105709],
          [100.601385, 4.2105462],
          [100.6014629, 4.2105233],
          [100.6015053, 4.210495],
          [100.6015832, 4.2103732],
          [100.601654, 4.2102426],
          [100.601746, 4.2102691],
          [100.6017318, 4.2104068],
          [100.6017212, 4.210518],
          [100.601769, 4.2105268],
          [100.6018062, 4.210525],
          [100.601861, 4.210458],
          [100.6019495, 4.2103521],
          [100.6020451, 4.2100415],
          [100.6021229, 4.2100626],
          [100.6021176, 4.210082],
          [100.602038, 4.2103821],
          [100.6020681, 4.2103962],
          [100.6020716, 4.2104297],
          [100.6020734, 4.2104985],
          [100.6021035, 4.2105709],
          [100.6021654, 4.2106486],
          [100.6023176, 4.2107633],
          [100.6024344, 4.2108674],
          [100.6026149, 4.2110033],
          [100.6026697, 4.2110792],
          [100.602691, 4.2111392],
          [100.6026839, 4.2112221],
          [100.6026662, 4.2112768],
          [100.6026166, 4.2113333],
          [100.6025175, 4.2114198],
          [100.6022433, 4.2115574],
          [100.6022174, 4.211567],
          [100.6021477, 4.2115927],
          [100.6020654, 4.2116869],
          [100.6018823, 4.211833],
          [100.6018319, 4.2118732],
          [100.6017595, 4.2118755],
          [100.6017199, 4.2119407],
          [100.6017269, 4.2119733],
          [100.6016732, 4.2120339],
          [100.601491, 4.2122574],
          [100.6014373, 4.2123343],
          [100.6014023, 4.2124344],
          [100.601393, 4.2125462],
          [100.6012661, 4.2125303],
          [100.6012593, 4.2125685],
          [100.6012324, 4.2125647],
          [100.6012067, 4.2125612],
          [100.6011428, 4.2125523],
          [100.6011461, 4.2125849],
          [100.6011955, 4.2126671],
          [100.6012548, 4.2127747],
          [100.601318, 4.2129144],
          [100.6014431, 4.2131012],
          [100.6015739, 4.2131245],
          [100.6016766, 4.2134319],
          [100.6016532, 4.2135157],
          [100.6017093, 4.2136275],
          [100.6019288, 4.2136554],
          [100.6020549, 4.2135623],
          [100.6024565, 4.2137206],
          [100.6032831, 4.2139675],
          [100.6040489, 4.2140839],
          [100.6047775, 4.2141258],
          [100.6052538, 4.2141072],
          [100.6052725, 4.2142329],
          [100.6053005, 4.2143913],
          [100.6053659, 4.2143959],
          [100.6053799, 4.2143121],
          [100.6053565, 4.2140792],
          [100.6054187, 4.2140478],
          [100.6056102, 4.2139687],
          [100.605755, 4.2138709],
          [100.6060071, 4.2136636],
          [100.6061449, 4.2134214],
          [100.6063597, 4.2129254],
          [100.6065092, 4.2125272],
          [100.6065909, 4.21215],
          [100.6065979, 4.2116702],
          [100.6066469, 4.2114723],
          [100.6067286, 4.2113885],
          [100.6068365, 4.2113283],
          [100.6073, 4.2113656],
          [100.6076427, 4.2114255],
          [100.607927, 4.2115448],
          [100.6080945, 4.2116423],
          [100.6082009, 4.2116735],
          [100.6082989, 4.2116878],
          [100.6084358, 4.2116827],
          [100.6085745, 4.2115882],
          [100.608587, 4.2115096],
          [100.6085632, 4.2109961],
          [100.6085176, 4.2109399],
          [100.6084596, 4.210923],
          [100.6081533, 4.2108948],
          [100.6077277, 4.2109018],
          [100.6073938, 4.2109205],
          [100.6071238, 4.2109056],
          [100.6069344, 4.2108559],
          [100.6067994, 4.2107519],
          [100.6067363, 4.2106533],
          [100.6067216, 4.2105548],
          [100.6067567, 4.2104873],
          [100.6067637, 4.210443],
          [100.6067403, 4.2104197],
          [100.606703, 4.2104267],
          [100.6066749, 4.2104011],
          [100.606724, 4.2097351],
          [100.6067403, 4.2095255],
          [100.6067964, 4.2089177],
          [100.6067964, 4.2086359],
          [100.6068174, 4.2080538],
          [100.6068337, 4.207786],
          [100.6068057, 4.2067241],
          [100.606832, 4.206192],
          [100.6068146, 4.205989],
          [100.6067742, 4.2058491],
          [100.606687, 4.20565],
          [100.6064419, 4.205275],
          [100.6053965, 4.2038931],
          [100.6050886, 4.2034602],
          [100.6048288, 4.2031968],
          [100.6043933, 4.2029397],
          [100.6037585, 4.2028631],
          [100.6032635, 4.2028072],
          [100.6028712, 4.2027723],
          [100.6026844, 4.2028375],
          [100.6025513, 4.2029329],
          [100.6023949, 4.2030936],
          [100.6022805, 4.2031705],
          [100.6022875, 4.2032683],
          [100.6024042, 4.2036828],
          [100.6024906, 4.2037829],
          [100.6024766, 4.2038714],
          [100.6024369, 4.2039343],
          [100.6025046, 4.2041555],
          [100.6025957, 4.20423],
          [100.6026751, 4.2043465],
          [100.6027265, 4.2044326],
          [100.6030253, 4.2046003],
          [100.6034083, 4.2047447],
          [100.6037002, 4.2049333],
          [100.60395, 4.2051312],
          [100.6042162, 4.2054433],
          [100.6044077, 4.2056948],
          [100.6045361, 4.2059067],
          [100.6046248, 4.206079],
          [100.6045875, 4.2062514],
          [100.6045104, 4.2064004],
          [100.6042582, 4.2066426],
          [100.6039967, 4.2068894],
          [100.6038052, 4.2070757],
          [100.6036721, 4.2072527],
          [100.6036348, 4.2074227],
          [100.6036488, 4.2075601],
          [100.6037165, 4.2077534],
          [100.603915, 4.2079141],
          [100.6040457, 4.207949],
          [100.6041905, 4.2080375],
          [100.6043166, 4.208105],
          [100.6044147, 4.2081702],
          [100.6044567, 4.2082168],
          [100.6044987, 4.2082028],
          [100.6045851, 4.2082564],
          [100.6047579, 4.2084101],
          [100.6047976, 4.2085149],
          [100.6048536, 4.2085498],
          [100.604905, 4.208764],
          [100.6049144, 4.2088711],
          [100.6049003, 4.2090528],
          [100.604849, 4.2092763],
          [100.6047602, 4.2095185],
          [100.6046832, 4.2096629],
          [100.6045314, 4.2098399],
          [100.604403, 4.2099284],
          [100.6043073, 4.209954],
          [100.6042068, 4.209933],
          [100.6041485, 4.2098857],
          [100.6041064, 4.2098515],
          [100.6040228, 4.2097398],
          [100.6038436, 4.2094927],
          [100.6037177, 4.2093215],
          [100.603517, 4.2091577],
          [100.6034036, 4.2090761],
          [100.6032798, 4.208969],
          [100.6031444, 4.2088874],
          [100.6030814, 4.2088572],
          [100.6030137, 4.2087594],
          [100.6029506, 4.208594],
          [100.6027802, 4.2085218],
          [100.6027078, 4.2085591],
          [100.6026167, 4.2086359],
          [100.6024976, 4.208757],
          [100.6023342, 4.2087943],
          [100.6021007, 4.208757],
          [100.6018193, 4.2087343],
          [100.6015637, 4.2087223],
          [100.6012033, 4.2087253],
          [100.6009136, 4.2087806],
          [100.6006363, 4.2089373],
          [100.6003282, 4.2091002],
          [100.6000171, 4.2090234],
          [100.5996917, 4.2089649],
          [100.5993947, 4.2089158],
          [100.5991574, 4.2089128],
          [100.599125, 4.2089147],
          [100.5990143, 4.2089211],
          [100.5989811, 4.2089231],
          [100.5988679, 4.2089297],
          [100.5988085, 4.2089331],
          [100.5986899, 4.2089631],
          [100.5984545, 4.2090232],
          [100.5982174, 4.2090567],
          [100.5980104, 4.2090814],
          [100.5977149, 4.2090973],
          [100.5974742, 4.2091396],
          [100.5973185, 4.209189],
          [100.5970831, 4.2093179],
          [100.5969079, 4.2094661],
          [100.5967664, 4.2096303],
          [100.5966973, 4.2097679],
        ],
      ],
      [
        [
          [100.5357866, 4.245176],
          [100.535727, 4.2453856],
          [100.5357807, 4.2455567],
          [100.5359161, 4.2457072],
          [100.5360379, 4.2458563],
          [100.5362156, 4.2459162],
          [100.5363481, 4.2458384],
          [100.5364445, 4.2457105],
          [100.5365745, 4.2456623],
          [100.5367231, 4.2457352],
          [100.5368748, 4.245871],
          [100.5370352, 4.2459634],
          [100.5372464, 4.2460203],
          [100.5375427, 4.2460204],
          [100.5378617, 4.2459907],
          [100.5380783, 4.2461247],
          [100.5381865, 4.2462901],
          [100.5383202, 4.2464412],
          [100.5383362, 4.2466743],
          [100.538439, 4.2468318],
          [100.5386762, 4.2468706],
          [100.5389232, 4.2467571],
          [100.5391256, 4.2467968],
          [100.5392506, 4.2470248],
          [100.5395791, 4.247133],
          [100.5399267, 4.2470072],
          [100.5400981, 4.2467568],
          [100.5403944, 4.2467093],
          [100.5407417, 4.2468547],
          [100.5410788, 4.2468986],
          [100.5414391, 4.2469301],
          [100.5418454, 4.2467832],
          [100.5422184, 4.2468502],
          [100.5428128, 4.2469079],
          [100.5436947, 4.2469135],
          [100.5442347, 4.2469553],
          [100.5446738, 4.2469379],
          [100.5449895, 4.2471329],
          [100.5451719, 4.2475594],
          [100.5453508, 4.248315],
          [100.5455835, 4.249094],
          [100.54555, 4.2499321],
          [100.545819, 4.250926],
          [100.5459772, 4.2517509],
          [100.546016, 4.2526785],
          [100.5459673, 4.2530752],
          [100.5459077, 4.2534718],
          [100.5457829, 4.2541553],
          [100.5456938, 4.254668],
          [100.5454384, 4.2551808],
          [100.5453425, 4.2552897],
          [100.5452819, 4.2553086],
          [100.5451868, 4.2552932],
          [100.5451317, 4.2553419],
          [100.5451322, 4.2554638],
          [100.5450702, 4.2554696],
          [100.5450426, 4.2555336],
          [100.5449333, 4.2555454],
          [100.544814, 4.2555058],
          [100.5445111, 4.2555089],
          [100.5442265, 4.2556931],
          [100.5438333, 4.2556989],
          [100.5433642, 4.2558507],
          [100.5430413, 4.2561531],
          [100.542586, 4.2564678],
          [100.5422538, 4.2562563],
          [100.541831, 4.2561369],
          [100.5413497, 4.2561543],
          [100.5408944, 4.2561957],
          [100.5406047, 4.2563203],
          [100.5401791, 4.2564304],
          [100.539751, 4.256436],
          [100.5390852, 4.2565376],
          [100.5387987, 4.2563857],
          [100.5386318, 4.2565744],
          [100.5382427, 4.2566021],
          [100.5378887, 4.2567359],
          [100.5378206, 4.2570805],
          [100.5378562, 4.2572477],
          [100.537855, 4.2574406],
          [100.5375987, 4.2576497],
          [100.5375022, 4.2579181],
          [100.5374474, 4.258161],
          [100.5375042, 4.2585045],
          [100.5376842, 4.2587516],
          [100.5377194, 4.2590185],
          [100.5377908, 4.2591517],
          [100.5377218, 4.2593627],
          [100.53758, 4.2594956],
          [100.5375172, 4.2597358],
          [100.5374652, 4.2599092],
          [100.537428, 4.260022],
          [100.5373097, 4.2601803],
          [100.5369717, 4.2602037],
          [100.5367328, 4.2602006],
          [100.5364502, 4.2603099],
          [100.5363677, 4.2604801],
          [100.5362078, 4.2606828],
          [100.5360985, 4.2608945],
          [100.5361462, 4.2610813],
          [100.5360846, 4.2613132],
          [100.5360508, 4.2614531],
          [100.5360377, 4.2616149],
          [100.5360237, 4.261862],
          [100.5359304, 4.2621336],
          [100.5357842, 4.2623507],
          [100.535801, 4.2625921],
          [100.5359409, 4.2627431],
          [100.5360776, 4.2628202],
          [100.5361694, 4.2629675],
          [100.5362246, 4.2631542],
          [100.5363249, 4.2633896],
          [100.5364167, 4.2636087],
          [100.5364644, 4.2638005],
          [100.5365245, 4.2639837],
          [100.5367002, 4.2640546],
          [100.5368987, 4.2640498],
          [100.5371851, 4.2640436],
          [100.5374348, 4.2639912],
          [100.5376301, 4.2639524],
          [100.5379343, 4.2639203],
          [100.5380587, 4.2640227],
          [100.5381719, 4.2641487],
          [100.538309, 4.2643049],
          [100.5384831, 4.2644651],
          [100.5385882, 4.2645743],
          [100.5387097, 4.2648097],
          [100.5388663, 4.2650485],
          [100.5390187, 4.2652922],
          [100.5393201, 4.2654159],
          [100.5393894, 4.2655722],
          [100.5396294, 4.2656683],
          [100.5399516, 4.2657239],
          [100.5402083, 4.2658419],
          [100.5402898, 4.266315],
          [100.5405459, 4.2664231],
          [100.5404883, 4.2661765],
          [100.5405905, 4.2659216],
          [100.5408745, 4.265943],
          [100.5411564, 4.266082],
          [100.5416459, 4.2659843],
          [100.5422329, 4.2659646],
          [100.5428707, 4.2659474],
          [100.5431262, 4.2657822],
          [100.5434708, 4.2656877],
          [100.5437143, 4.265655],
          [100.5442304, 4.2658244],
          [100.544695, 4.265887],
          [100.5453087, 4.2658081],
          [100.5459781, 4.265887],
          [100.5464798, 4.2660315],
          [100.5471174, 4.2660303],
          [100.54743, 4.2659114],
          [100.5475135, 4.2655147],
          [100.5477113, 4.265243],
          [100.5481283, 4.2650399],
          [100.5484652, 4.2648866],
          [100.5485475, 4.2646882],
          [100.5483745, 4.264391],
          [100.5483697, 4.263965],
          [100.548522, 4.2636352],
          [100.5492192, 4.2635109],
          [100.5500223, 4.2635514],
          [100.5505486, 4.2633161],
          [100.5510454, 4.262977],
          [100.5513215, 4.2625506],
          [100.5513564, 4.2621066],
          [100.5511165, 4.2617425],
          [100.5510314, 4.261461],
          [100.5511375, 4.2611788],
          [100.5511122, 4.2608734],
          [100.5509592, 4.2607461],
          [100.5509402, 4.2605562],
          [100.5511686, 4.2603243],
          [100.5512583, 4.2601312],
          [100.5511579, 4.2599399],
          [100.5512399, 4.2597533],
          [100.5512417, 4.2596238],
          [100.5513348, 4.2593423],
          [100.5513462, 4.2592287],
          [100.5513462, 4.2591132],
          [100.5515231, 4.2588282],
          [100.5516995, 4.2586643],
          [100.5518674, 4.2584833],
          [100.5519963, 4.2583668],
          [100.5521158, 4.2582773],
          [100.5521853, 4.2581963],
          [100.55218, 4.2580714],
          [100.5521287, 4.2579407],
          [100.55203, 4.2577911],
          [100.5521477, 4.2577703],
          [100.55218, 4.2577153],
          [100.552187, 4.257596],
          [100.5521762, 4.2574805],
          [100.5521116, 4.2574256],
          [100.5521496, 4.2573365],
          [100.5521006, 4.2572982],
          [100.5519985, 4.257305],
          [100.5519255, 4.2573555],
          [100.5518381, 4.2573839],
          [100.5517204, 4.2573801],
          [100.5516121, 4.257363],
          [100.5514963, 4.2572361],
          [100.5514476, 4.2570275],
          [100.5514431, 4.2567778],
          [100.5515592, 4.2563448],
          [100.551633, 4.2561546],
          [100.5517388, 4.2559891],
          [100.5518552, 4.2558952],
          [100.5519654, 4.2558327],
          [100.5520601, 4.2557385],
          [100.5522632, 4.2553233],
          [100.5524687, 4.2550571],
          [100.5527883, 4.2547146],
          [100.5528543, 4.2546442],
          [100.5529665, 4.2545064],
          [100.5530419, 4.2544231],
          [100.5530705, 4.2543783],
          [100.5530776, 4.2543236],
          [100.5530874, 4.2542469],
          [100.5531209, 4.2541541],
          [100.553185, 4.2540646],
          [100.5532744, 4.2540216],
          [100.5533389, 4.2540226],
          [100.553399, 4.2540026],
          [100.5534441, 4.2539901],
          [100.5534786, 4.253981],
          [100.5534907, 4.2539478],
          [100.5534547, 4.2538937],
          [100.553434, 4.2538121],
          [100.5534334, 4.2537969],
          [100.5534328, 4.2537812],
          [100.5534106, 4.2536554],
          [100.5533805, 4.2536262],
          [100.5532818, 4.253597],
          [100.5531827, 4.253551],
          [100.553048, 4.2535882],
          [100.552918, 4.2535279],
          [100.5528372, 4.2534783],
          [100.5527741, 4.2534387],
          [100.5526135, 4.2534517],
          [100.5525995, 4.2533833],
          [100.5525608, 4.2533216],
          [100.5525146, 4.2532793],
          [100.5524428, 4.2532927],
          [100.5523515, 4.2532687],
          [100.5522289, 4.2531908],
          [100.5521339, 4.2531111],
          [100.5520656, 4.253027],
          [100.5520032, 4.2529308],
          [100.5519752, 4.2528704],
          [100.5519103, 4.2528811],
          [100.5518373, 4.2528744],
          [100.551736, 4.2528246],
          [100.5515972, 4.2526837],
          [100.5515193, 4.2525748],
          [100.5514863, 4.2523748],
          [100.5515957, 4.2521314],
          [100.5516751, 4.2518009],
          [100.5518106, 4.2514601],
          [100.5519882, 4.2511227],
          [100.5521613, 4.2508417],
          [100.5524841, 4.2504384],
          [100.5526911, 4.250178],
          [100.55297, 4.2499042],
          [100.553355, 4.2495838],
          [100.5536048, 4.2494133],
          [100.5537239, 4.2493265],
          [100.5540547, 4.249153],
          [100.554269, 4.2490516],
          [100.5544591, 4.2489334],
          [100.5545126, 4.2489074],
          [100.5545648, 4.2488821],
          [100.5546665, 4.2488326],
          [100.5547602, 4.2487871],
          [100.5550404, 4.2486509],
          [100.5553025, 4.2485464],
          [100.5554335, 4.2484893],
          [100.5554981, 4.24846],
          [100.5555275, 4.2484618],
          [100.5555496, 4.2484762],
          [100.5555742, 4.2485057],
          [100.5555999, 4.248522],
          [100.5556278, 4.2485182],
          [100.5556552, 4.2484969],
          [100.5556689, 4.2484504],
          [100.5556728, 4.2483897],
          [100.5557017, 4.2483609],
          [100.5559341, 4.2482904],
          [100.5562034, 4.2482582],
          [100.5564254, 4.2482283],
          [100.5567841, 4.2482103],
          [100.5570622, 4.2482047],
          [100.5573438, 4.248209],
          [100.5576957, 4.2482255],
          [100.5580089, 4.2482466],
          [100.5583734, 4.2482775],
          [100.5586553, 4.2483226],
          [100.5589699, 4.2484165],
          [100.5592036, 4.2485225],
          [100.5593833, 4.2486199],
          [100.5596038, 4.2488488],
          [100.5597024, 4.248967],
          [100.5597746, 4.2491318],
          [100.5597705, 4.2493955],
          [100.5596644, 4.2495656],
          [100.5594609, 4.249773],
          [100.5593264, 4.2499671],
          [100.5593257, 4.2503506],
          [100.5594764, 4.2505599],
          [100.5596399, 4.2510602],
          [100.559761, 4.2512242],
          [100.559959, 4.2514294],
          [100.5603329, 4.2515089],
          [100.5605115, 4.2515998],
          [100.560643, 4.2516514],
          [100.5607261, 4.2518441],
          [100.5608901, 4.2519298],
          [100.5611839, 4.2519672],
          [100.5615276, 4.2520291],
          [100.561971, 4.2521446],
          [100.5622938, 4.252141],
          [100.5624521, 4.2523535],
          [100.5627058, 4.2526659],
          [100.5626754, 4.2528114],
          [100.562804, 4.2529294],
          [100.5629472, 4.2528414],
          [100.56338, 4.2529006],
          [100.5636893, 4.2530233],
          [100.5638086, 4.2535035],
          [100.5640057, 4.253658],
          [100.5641923, 4.2535109],
          [100.5643443, 4.2534503],
          [100.5644823, 4.2532776],
          [100.5645893, 4.253151],
          [100.5646483, 4.2530983],
          [100.564633, 4.2528347],
          [100.5644943, 4.2527116],
          [100.5644487, 4.2525658],
          [100.5644753, 4.2524995],
          [100.5644165, 4.2524502],
          [100.5643975, 4.2523934],
          [100.5643989, 4.2523297],
          [100.5643785, 4.2522381],
          [100.5644487, 4.252151],
          [100.5645247, 4.2521244],
          [100.5645855, 4.2520904],
          [100.5646159, 4.2520582],
          [100.564633, 4.2520278],
          [100.5645969, 4.2519616],
          [100.5647146, 4.2519294],
          [100.5648115, 4.2519066],
          [100.5648476, 4.2518271],
          [100.5649369, 4.2517343],
          [100.5649957, 4.2517778],
          [100.5650508, 4.2517229],
          [100.5651992, 4.2515907],
          [100.5655983, 4.2514622],
          [100.5659848, 4.251223],
          [100.566312, 4.2509994],
          [100.5666918, 4.2506944],
          [100.5668855, 4.2506452],
          [100.5671989, 4.2505713],
          [100.5673946, 4.2504248],
          [100.5676035, 4.2502683],
          [100.5677174, 4.2501982],
          [100.5679776, 4.250272],
          [100.5680707, 4.2501148],
          [100.5681998, 4.2499993],
          [100.5682739, 4.2498232],
          [100.568443, 4.2497853],
          [100.5686863, 4.2496181],
          [100.5688721, 4.2495374],
          [100.5689668, 4.2493552],
          [100.5690678, 4.2492044],
          [100.5691882, 4.24903],
          [100.56929, 4.249022],
          [100.569362, 4.2489486],
          [100.5693375, 4.2488174],
          [100.5693347, 4.2487001],
          [100.5693736, 4.2485579],
          [100.5692805, 4.2484329],
          [100.5692952, 4.2482671],
          [100.5693744, 4.2480415],
          [100.5695463, 4.2479724],
          [100.5695957, 4.2478973],
          [100.56948, 4.2477794],
          [100.5693791, 4.2476946],
          [100.5691999, 4.2475753],
          [100.5691343, 4.2474499],
          [100.569186, 4.2472879],
          [100.569199, 4.2470758],
          [100.5693661, 4.2469679],
          [100.5692103, 4.2467566],
          [100.5693195, 4.2465264],
          [100.5692892, 4.2462862],
          [100.5693704, 4.2461704],
          [100.5693925, 4.2461195],
          [100.5692086, 4.2459364],
          [100.5690447, 4.2457585],
          [100.5688148, 4.2454004],
          [100.5687018, 4.2450104],
          [100.5685221, 4.2449427],
          [100.5683446, 4.2448221],
          [100.5681751, 4.2447308],
          [100.5680464, 4.2446801],
          [100.567939, 4.2445382],
          [100.5678606, 4.2441443],
          [100.5679397, 4.2437828],
          [100.5680478, 4.2435136],
          [100.5681428, 4.2432532],
          [100.5682701, 4.2429792],
          [100.5684316, 4.2427335],
          [100.5686893, 4.2425098],
          [100.5687836, 4.2423841],
          [100.5687639, 4.242157],
          [100.5687294, 4.2419318],
          [100.5687804, 4.241739],
          [100.5690517, 4.2418595],
          [100.5692263, 4.2419881],
          [100.5694322, 4.2421774],
          [100.5695117, 4.2421123],
          [100.5700106, 4.2416693],
          [100.5700748, 4.241596],
          [100.5697374, 4.2408608],
          [100.5699708, 4.2406294],
          [100.5702565, 4.2404296],
          [100.5705379, 4.2402949],
          [100.5709499, 4.2400018],
          [100.5713206, 4.2396834],
          [100.5714579, 4.2397593],
          [100.571699, 4.2396539],
          [100.5719903, 4.2395082],
          [100.5721752, 4.239139],
          [100.5722395, 4.2390467],
          [100.5722959, 4.2389878],
          [100.5722425, 4.2388091],
          [100.5721289, 4.2385267],
          [100.5722237, 4.238335],
          [100.5724476, 4.2381821],
          [100.5726938, 4.2380119],
          [100.5727734, 4.2379591],
          [100.5728612, 4.2378183],
          [100.5730541, 4.2376157],
          [100.5731737, 4.2373928],
          [100.57318, 4.2371475],
          [100.5732864, 4.2368708],
          [100.573379, 4.2366707],
          [100.5733569, 4.2364639],
          [100.5733632, 4.2363623],
          [100.5733967, 4.2362229],
          [100.573453, 4.2360676],
          [100.5736055, 4.2357589],
          [100.5737386, 4.2355664],
          [100.5737434, 4.2355177],
          [100.5737508, 4.2354415],
          [100.5737762, 4.2352473],
          [100.5738598, 4.2349808],
          [100.5739764, 4.2346913],
          [100.5738964, 4.2343643],
          [100.5739511, 4.2341669],
          [100.5739918, 4.2340299],
          [100.5738699, 4.233684],
          [100.5738081, 4.2335171],
          [100.5737997, 4.2333991],
          [100.5738341, 4.2333025],
          [100.5738352, 4.233277],
          [100.5738344, 4.2332278],
          [100.5738304, 4.2332028],
          [100.5737941, 4.2331854],
          [100.5737789, 4.2330368],
          [100.5737188, 4.232931],
          [100.5735807, 4.2328961],
          [100.573582, 4.2327408],
          [100.573731, 4.2322075],
          [100.5737632, 4.2320923],
          [100.5736839, 4.231961],
          [100.5736994, 4.2315266],
          [100.5737933, 4.2312282],
          [100.5739175, 4.230982],
          [100.5739186, 4.2309446],
          [100.5739204, 4.2308845],
          [100.5739339, 4.2304297],
          [100.5739423, 4.2303816],
          [100.573967, 4.2302407],
          [100.5739952, 4.2300801],
          [100.57401, 4.2299955],
          [100.5740154, 4.2299647],
          [100.5741395, 4.2296748],
          [100.5742092, 4.229512],
          [100.57423, 4.2294633],
          [100.5744002, 4.2294928],
          [100.574532, 4.2294179],
          [100.5745284, 4.2292638],
          [100.5742362, 4.2290953],
          [100.5742763, 4.2290331],
          [100.5743519, 4.2289159],
          [100.5744288, 4.2287967],
          [100.5745065, 4.2286761],
          [100.574559, 4.2285947],
          [100.5746818, 4.2284043],
          [100.5747275, 4.2283988],
          [100.5748447, 4.2283846],
          [100.575039, 4.2282244],
          [100.5752311, 4.2278965],
          [100.5752475, 4.2278404],
          [100.575339, 4.2275264],
          [100.5753527, 4.2274795],
          [100.5753742, 4.2272486],
          [100.5754144, 4.2270499],
          [100.57545, 4.2268127],
          [100.5754865, 4.226562],
          [100.5755323, 4.2265244],
          [100.5755298, 4.2264782],
          [100.5755281, 4.2264264],
          [100.5755263, 4.2263728],
          [100.5755252, 4.2263552],
          [100.5754561, 4.2263124],
          [100.5753482, 4.2261011],
          [100.5752567, 4.2259218],
          [100.5752472, 4.2259032],
          [100.5752308, 4.2258703],
          [100.575122, 4.2256519],
          [100.5750595, 4.2255264],
          [100.5750539, 4.2255127],
          [100.5749704, 4.2253066],
          [100.5749413, 4.2252348],
          [100.5748964, 4.2250955],
          [100.574875, 4.2249716],
          [100.5748829, 4.2248519],
          [100.5749214, 4.2245473],
          [100.575013, 4.2243662],
          [100.5750208, 4.2241851],
          [100.575018, 4.2238469],
          [100.5749486, 4.2237176],
          [100.5749906, 4.2234808],
          [100.5750874, 4.2231798],
          [100.5750967, 4.2231506],
          [100.5752468, 4.2229444],
          [100.5752692, 4.2228422],
          [100.5753054, 4.2226765],
          [100.5753935, 4.2226179],
          [100.5756123, 4.2226143],
          [100.5756914, 4.2223819],
          [100.5757279, 4.2223835],
          [100.5757341, 4.2222621],
          [100.5757103, 4.2222625],
          [100.5756854, 4.2220763],
          [100.5756385, 4.2220356],
          [100.5756366, 4.2220008],
          [100.5756337, 4.2219474],
          [100.5755214, 4.2219505],
          [100.5755229, 4.2218192],
          [100.5753234, 4.2218202],
          [100.5752052, 4.2214127],
          [100.5752367, 4.2210098],
          [100.5752717, 4.2205623],
          [100.5752725, 4.2205249],
          [100.5752747, 4.22041],
          [100.5752768, 4.2203003],
          [100.5753241, 4.2201695],
          [100.5753315, 4.2201274],
          [100.575368, 4.2199186],
          [100.5753602, 4.2198877],
          [100.5752964, 4.2196366],
          [100.5752835, 4.2194411],
          [100.5753004, 4.2193002],
          [100.5752609, 4.2191706],
          [100.5752237, 4.2190789],
          [100.5752458, 4.2190041],
          [100.5753704, 4.2190366],
          [100.5754371, 4.2189705],
          [100.576411, 4.2193304],
          [100.5764554, 4.2192416],
          [100.5762035, 4.2191285],
          [100.5762332, 4.2189889],
          [100.5758852, 4.2188421],
          [100.5758238, 4.2187764],
          [100.575632, 4.2186936],
          [100.5755532, 4.2186983],
          [100.5754527, 4.218635],
          [100.5754093, 4.2184217],
          [100.5754727, 4.2182707],
          [100.5756199, 4.2179426],
          [100.5756879, 4.2177807],
          [100.575768, 4.2176836],
          [100.5759555, 4.2174175],
          [100.576231, 4.2168576],
          [100.5763966, 4.2168734],
          [100.5764475, 4.2167833],
          [100.5763299, 4.2166093],
          [100.5763891, 4.2162914],
          [100.5765196, 4.2163571],
          [100.577022, 4.2156627],
          [100.5772748, 4.2156139],
          [100.577495, 4.2154942],
          [100.5776809, 4.2154214],
          [100.5778099, 4.2152675],
          [100.5777936, 4.2151258],
          [100.5775826, 4.2149775],
          [100.5775138, 4.214819],
          [100.5774203, 4.2147893],
          [100.5773984, 4.214881],
          [100.5770716, 4.214851],
          [100.5767224, 4.2145163],
          [100.5763787, 4.214065],
          [100.5761448, 4.2141429],
          [100.5760793, 4.2140999],
          [100.5760527, 4.2139838],
          [100.5760464, 4.2139041],
          [100.5760866, 4.2138421],
          [100.5763907, 4.2137667],
          [100.5764784, 4.2137417],
          [100.5764747, 4.2136763],
          [100.5765966, 4.2136464],
          [100.5766568, 4.2136317],
          [100.5766427, 4.2135279],
          [100.5764681, 4.2135661],
          [100.5764256, 4.213448],
          [100.5764156, 4.2134202],
          [100.5764062, 4.213394],
          [100.5763211, 4.213157],
          [100.5762207, 4.213187],
          [100.5760464, 4.213239],
          [100.5760337, 4.2131788],
          [100.5760166, 4.2130979],
          [100.5760034, 4.2130351],
          [100.5758984, 4.212966],
          [100.575804, 4.2129162],
          [100.5757725, 4.2124089],
          [100.5756713, 4.2118777],
          [100.5755366, 4.2111852],
          [100.5754509, 4.2107844],
          [100.5752327, 4.2105995],
          [100.5752274, 4.2105374],
          [100.5751939, 4.2101416],
          [100.5751369, 4.2096304],
          [100.5752009, 4.2094394],
          [100.5752365, 4.209333],
          [100.5753649, 4.2091413],
          [100.5756941, 4.2089491],
          [100.576006, 4.2083967],
          [100.5765039, 4.2077706],
          [100.576762, 4.2075106],
          [100.5768796, 4.2069369],
          [100.5769059, 4.2068553],
          [100.576961, 4.206858],
          [100.5769679, 4.2068167],
          [100.5770972, 4.2067667],
          [100.5770446, 4.2066315],
          [100.5769815, 4.2066459],
          [100.5770194, 4.2065748],
          [100.5770604, 4.2065258],
          [100.5772254, 4.2063061],
          [100.5773222, 4.2061771],
          [100.5776218, 4.2058927],
          [100.5778832, 4.2056921],
          [100.5782569, 4.205471],
          [100.578597, 4.2052178],
          [100.5787983, 4.2050735],
          [100.5790016, 4.2050878],
          [100.5791384, 4.2051745],
          [100.5793481, 4.2052144],
          [100.5794896, 4.2051468],
          [100.5795772, 4.2049533],
          [100.5796172, 4.2046689],
          [100.5796784, 4.204318],
          [100.579624, 4.2041083],
          [100.5795126, 4.2037738],
          [100.5793781, 4.2034959],
          [100.5792172, 4.2031349],
          [100.579201, 4.2029779],
          [100.579251, 4.202843],
          [100.5794451, 4.2025654],
          [100.5795733, 4.2024034],
          [100.5795949, 4.202352],
          [100.5795457, 4.2022742],
          [100.5794332, 4.2021171],
          [100.5792519, 4.2017794],
          [100.5790825, 4.2015941],
          [100.5788863, 4.2013713],
          [100.5788235, 4.201253],
          [100.5787995, 4.2011594],
          [100.5787119, 4.2011643],
          [100.5785414, 4.201191],
          [100.578424, 4.2013786],
          [100.5784056, 4.2016904],
          [100.5784011, 4.2018294],
          [100.5782774, 4.2019641],
          [100.5781257, 4.202011],
          [100.5779178, 4.20198],
          [100.5777152, 4.2018987],
          [100.577343, 4.2015563],
          [100.5771896, 4.2013484],
          [100.5770789, 4.2011541],
          [100.5768557, 4.2007914],
          [100.5765698, 4.2003315],
          [100.5762977, 4.1996139],
          [100.5762049, 4.1991431],
          [100.5761725, 4.1986531],
          [100.5761972, 4.1980017],
          [100.5763687, 4.1975963],
          [100.5767562, 4.1971089],
          [100.5769443, 4.1970005],
          [100.5771864, 4.1969268],
          [100.5773229, 4.196795],
          [100.5774983, 4.1965415],
          [100.5776968, 4.1962921],
          [100.5778128, 4.1961395],
          [100.5780405, 4.1960059],
          [100.5781797, 4.1958858],
          [100.5783402, 4.1958058],
          [100.5784881, 4.1957092],
          [100.5786121, 4.195539],
          [100.5786112, 4.1954238],
          [100.5785508, 4.1952173],
          [100.5785388, 4.1950777],
          [100.5786887, 4.1949224],
          [100.5789872, 4.1947005],
          [100.5791336, 4.1946082],
          [100.5791533, 4.1945958],
          [100.5791774, 4.1945806],
          [100.5792932, 4.1945076],
          [100.579376, 4.194393],
          [100.5794703, 4.1942093],
          [100.5796322, 4.1939162],
          [100.5797503, 4.1937157],
          [100.5798821, 4.1935739],
          [100.5801338, 4.1934347],
          [100.5804218, 4.193315],
          [100.5808485, 4.1931208],
          [100.5811024, 4.1930119],
          [100.5811824, 4.1930218],
          [100.5813111, 4.1931639],
          [100.5813955, 4.1930649],
          [100.5813139, 4.1929855],
          [100.5812978, 4.1928928],
          [100.5815246, 4.192767],
          [100.5817209, 4.1927046],
          [100.581861, 4.1926536],
          [100.5819692, 4.1926301],
          [100.5820427, 4.1926777],
          [100.5821542, 4.1924972],
          [100.5821428, 4.1923567],
          [100.5820975, 4.1922297],
          [100.5821042, 4.1921832],
          [100.5820544, 4.1917508],
          [100.5820552, 4.1911686],
          [100.5822857, 4.1907736],
          [100.582475, 4.1901697],
          [100.5826943, 4.1899145],
          [100.5826155, 4.1897894],
          [100.5825236, 4.1896161],
          [100.5825439, 4.1893513],
          [100.5824694, 4.1890063],
          [100.5823199, 4.1888587],
          [100.5821984, 4.1886603],
          [100.5819374, 4.1885622],
          [100.58182, 4.1884385],
          [100.5817471, 4.1882093],
          [100.5815512, 4.1881601],
          [100.5812217, 4.1882093],
          [100.5810008, 4.1882944],
          [100.5808082, 4.1883857],
          [100.5805806, 4.188421],
          [100.5803313, 4.1883258],
          [100.5799942, 4.1882913],
          [100.5797457, 4.1884478],
          [100.579639, 4.1886755],
          [100.5796425, 4.1889289],
          [100.5795385, 4.1891126],
          [100.5793639, 4.1892028],
          [100.5791731, 4.1893644],
          [100.5790464, 4.1896074],
          [100.5787308, 4.1898352],
          [100.5783714, 4.1899706],
          [100.5780122, 4.1899924],
          [100.5776498, 4.1900006],
          [100.5774513, 4.19007],
          [100.577304, 4.1902099],
          [100.577098, 4.1902478],
          [100.5768825, 4.190121],
          [100.5768031, 4.1899328],
          [100.5766159, 4.1897699],
          [100.5764037, 4.1895726],
          [100.5761917, 4.1895048],
          [100.575937, 4.1895285],
          [100.5757614, 4.1895966],
          [100.575594, 4.1898064],
          [100.575519, 4.1899631],
          [100.5753039, 4.189952],
          [100.5751116, 4.1899739],
          [100.5749666, 4.1900855],
          [100.5748564, 4.1902123],
          [100.5747676, 4.1903962],
          [100.5747105, 4.1906369],
          [100.5745283, 4.1907561],
          [100.5742784, 4.1907946],
          [100.5739999, 4.1908529],
          [100.5737282, 4.1909644],
          [100.5733819, 4.1910475],
          [100.5730379, 4.1910564],
          [100.5725455, 4.1911068],
          [100.5720881, 4.1911495],
          [100.5718158, 4.1911443],
          [100.5716072, 4.1911052],
          [100.5713965, 4.1909473],
          [100.5713657, 4.1905961],
          [100.5713311, 4.1903692],
          [100.5712018, 4.1901934],
          [100.5710064, 4.1901336],
          [100.5709193, 4.1899604],
          [100.5708905, 4.1897458],
          [100.570744, 4.1895998],
          [100.570523, 4.1895222],
          [100.5703757, 4.1893749],
          [100.5703466, 4.1891507],
          [100.5702175, 4.1889848],
          [100.5699585, 4.1888942],
          [100.5697644, 4.1888932],
          [100.5694763, 4.1889513],
          [100.569376, 4.1890334],
          [100.569202, 4.1890786],
          [100.5689567, 4.1890232],
          [100.5688125, 4.1890048],
          [100.5686473, 4.189056],
          [100.5685082, 4.1891484],
          [100.5684098, 4.1892318],
          [100.5683664, 4.1893322],
          [100.5684012, 4.1894963],
          [100.5684696, 4.1896221],
          [100.5685302, 4.189717],
          [100.5685212, 4.1898401],
          [100.5684241, 4.1899295],
          [100.5682838, 4.1899924],
          [100.5681793, 4.1901388],
          [100.5679553, 4.1902282],
          [100.5677167, 4.1902548],
          [100.5674769, 4.1902427],
          [100.5672878, 4.1901995],
          [100.567186, 4.1901],
          [100.5671392, 4.1899886],
          [100.5671044, 4.1898728],
          [100.5669843, 4.1897917],
          [100.5668137, 4.1898283],
          [100.5666037, 4.1899025],
          [100.566445, 4.1899631],
          [100.5663621, 4.1900616],
          [100.5663538, 4.1902006],
          [100.5663314, 4.1904002],
          [100.5662609, 4.1905499],
          [100.5661341, 4.1906406],
          [100.5659693, 4.1907016],
          [100.5656693, 4.1907705],
          [100.5654479, 4.1906885],
          [100.565274, 4.1903479],
          [100.5651683, 4.190076],
          [100.5650136, 4.1899532],
          [100.564829, 4.1899524],
          [100.5646381, 4.1901207],
          [100.5643723, 4.1902085],
          [100.5638997, 4.1904476],
          [100.5635842, 4.1907319],
          [100.5633892, 4.1910249],
          [100.5632964, 4.1913666],
          [100.5632348, 4.191718],
          [100.5631406, 4.1920931],
          [100.5629228, 4.1922874],
          [100.5627317, 4.1923914],
          [100.5624635, 4.1923959],
          [100.5622946, 4.1921927],
          [100.5620432, 4.1921827],
          [100.5617542, 4.1923564],
          [100.5616841, 4.1926377],
          [100.5617036, 4.192946],
          [100.5615811, 4.1932422],
          [100.5614341, 4.1932968],
          [100.5613629, 4.1935195],
          [100.5613957, 4.1938036],
          [100.5613929, 4.1941888],
          [100.561365, 4.1944903],
          [100.5613182, 4.1947271],
          [100.5611915, 4.1948512],
          [100.5609869, 4.1949588],
          [100.5608381, 4.1951232],
          [100.5608179, 4.1953404],
          [100.5609361, 4.1954787],
          [100.5610825, 4.1955825],
          [100.561144, 4.1957197],
          [100.5611121, 4.1959433],
          [100.5611136, 4.1961727],
          [100.5610242, 4.1963607],
          [100.5608613, 4.1964875],
          [100.5607766, 4.1966955],
          [100.5607846, 4.1969261],
          [100.5609039, 4.1971101],
          [100.5610566, 4.1972096],
          [100.5612043, 4.197324],
          [100.5613092, 4.1974522],
          [100.5614685, 4.1974911],
          [100.5616159, 4.1975667],
          [100.5616555, 4.1977448],
          [100.5615379, 4.1979084],
          [100.5613925, 4.198085],
          [100.5613378, 4.1983221],
          [100.561323, 4.1986259],
          [100.5613905, 4.1989614],
          [100.5614218, 4.1993556],
          [100.5613222, 4.1997071],
          [100.5610814, 4.1999094],
          [100.5610069, 4.2002365],
          [100.5610472, 4.2006054],
          [100.560992, 4.2010159],
          [100.560819, 4.2011275],
          [100.5606157, 4.2011973],
          [100.560511, 4.201433],
          [100.5605304, 4.2019828],
          [100.5605474, 4.2022622],
          [100.5603625, 4.2025438],
          [100.5603, 4.2028394],
          [100.5604185, 4.2030717],
          [100.5604527, 4.2032765],
          [100.56036, 4.2037217],
          [100.5602493, 4.2040657],
          [100.5600982, 4.20444],
          [100.56001, 4.2047543],
          [100.5598153, 4.20512],
          [100.5595854, 4.2055886],
          [100.5594282, 4.205889],
          [100.5593045, 4.2061065],
          [100.5591196, 4.2063408],
          [100.5589487, 4.206461],
          [100.5587338, 4.2065601],
          [100.5585763, 4.2065741],
          [100.5584189, 4.2065229],
          [100.5582789, 4.2064777],
          [100.5582528, 4.2066182],
          [100.558318, 4.2066823],
          [100.5585976, 4.206762],
          [100.5586612, 4.2068941],
          [100.5586941, 4.2070518],
          [100.5586786, 4.2073993],
          [100.5586374, 4.2076397],
          [100.5585799, 4.2078833],
          [100.5584966, 4.2081858],
          [100.5583986, 4.2084395],
          [100.5581821, 4.2088487],
          [100.5580155, 4.2091604],
          [100.5578083, 4.2095111],
          [100.5575682, 4.2098195],
          [100.5571944, 4.2102275],
          [100.5569738, 4.210424],
          [100.556737, 4.2106141],
          [100.5560379, 4.2111058],
          [100.5552509, 4.2116212],
          [100.5548427, 4.2118871],
          [100.5544098, 4.2121214],
          [100.5538466, 4.2124071],
          [100.5532393, 4.2126764],
          [100.5526753, 4.2129018],
          [100.5521085, 4.2130847],
          [100.5514743, 4.2132657],
          [100.5504174, 4.2134738],
          [100.5491393, 4.213831],
          [100.548707, 4.2138926],
          [100.5481755, 4.2139432],
          [100.547883, 4.2139475],
          [100.5475982, 4.2139174],
          [100.5471151, 4.213848],
          [100.5468757, 4.2138009],
          [100.5466671, 4.2137078],
          [100.5463752, 4.2135095],
          [100.5461879, 4.2132268],
          [100.5458182, 4.2129108],
          [100.5459251, 4.2125911],
          [100.5459399, 4.2122385],
          [100.5459362, 4.2118319],
          [100.5456139, 4.2117768],
          [100.5451451, 4.2119685],
          [100.5449787, 4.2123861],
          [100.544796, 4.2126759],
          [100.5446952, 4.2130548],
          [100.5444912, 4.2133655],
          [100.5444279, 4.2138004],
          [100.5444043, 4.2141361],
          [100.5441322, 4.2143436],
          [100.5437716, 4.2144558],
          [100.5434583, 4.214666],
          [100.5433016, 4.2147669],
          [100.543185, 4.215223],
          [100.5431552, 4.2155172],
          [100.5432003, 4.2158173],
          [100.5433191, 4.2159845],
          [100.5433912, 4.2162333],
          [100.5434371, 4.2165077],
          [100.5435803, 4.2167319],
          [100.5436633, 4.2168818],
          [100.5434651, 4.2170276],
          [100.5433361, 4.2171962],
          [100.5433644, 4.2175031],
          [100.5435098, 4.2177548],
          [100.5437072, 4.2179485],
          [100.5439803, 4.2180389],
          [100.5444, 4.2181975],
          [100.5444437, 4.218445],
          [100.5444287, 4.218801],
          [100.5443815, 4.2197819],
          [100.544262, 4.2205724],
          [100.5440158, 4.2214137],
          [100.5437178, 4.2220465],
          [100.5433499, 4.2223276],
          [100.5430439, 4.2226272],
          [100.5429634, 4.2230501],
          [100.542582, 4.2236557],
          [100.5423912, 4.2237825],
          [100.5421369, 4.223932],
          [100.5419782, 4.2239973],
          [100.5418082, 4.2240195],
          [100.5417203, 4.2240264],
          [100.5416491, 4.2239935],
          [100.5415961, 4.22392],
          [100.5414664, 4.2236663],
          [100.5413721, 4.2235598],
          [100.5412777, 4.2234941],
          [100.541198, 4.2235346],
          [100.5411273, 4.2235932],
          [100.5409724, 4.2236469],
          [100.5409021, 4.2237385],
          [100.5409191, 4.2238364],
          [100.5409521, 4.2239229],
          [100.5409709, 4.2240377],
          [100.5409671, 4.2241886],
          [100.5407386, 4.2243357],
          [100.5405864, 4.2245982],
          [100.540529, 4.2248802],
          [100.5404572, 4.2251407],
          [100.540245, 4.2254204],
          [100.5399664, 4.2255103],
          [100.5397567, 4.2254729],
          [100.5395851, 4.2253605],
          [100.5393472, 4.2254458],
          [100.5392188, 4.2256243],
          [100.5391744, 4.2259219],
          [100.5392295, 4.2261175],
          [100.5392971, 4.2262073],
          [100.5393974, 4.2263635],
          [100.5395122, 4.2264981],
          [100.5396986, 4.2266225],
          [100.5399054, 4.2267867],
          [100.5402761, 4.2268176],
          [100.5405993, 4.2268255],
          [100.5407144, 4.226501],
          [100.5409007, 4.226468],
          [100.5410871, 4.226467],
          [100.5413792, 4.2265401],
          [100.54169, 4.2266861],
          [100.5420701, 4.2268659],
          [100.542428, 4.2270649],
          [100.5425632, 4.2271625],
          [100.5426984, 4.2272843],
          [100.542707057835429, 4.227291584014893],
          [100.5428862, 4.2274423],
          [100.5431096, 4.2276373],
          [100.5432757, 4.2278135],
          [100.5434937, 4.2280715],
          [100.5436015, 4.2281619],
          [100.5438419, 4.2284238],
          [100.5441405, 4.2288115],
          [100.5442748, 4.2289564],
          [100.5443715, 4.229112],
          [100.5444765, 4.229343],
          [100.5446845, 4.2298347],
          [100.5448094, 4.2301587],
          [100.5449665, 4.2305147],
          [100.5452243, 4.2311462],
          [100.5453025, 4.2313897],
          [100.5453585, 4.2315941],
          [100.5454138, 4.2319691],
          [100.545436, 4.2323442],
          [100.5454422, 4.2328208],
          [100.5454243, 4.2332547],
          [100.5453763, 4.2336231],
          [100.5453326, 4.2338016],
          [100.5452729, 4.2339641],
          [100.5451917, 4.2340704],
          [100.545089, 4.2341874],
          [100.5447937, 4.2343726],
          [100.5445269, 4.2345135],
          [100.5445286, 4.2345655],
          [100.5445575, 4.2346175],
          [100.5446986, 4.2346364],
          [100.54476, 4.2346798],
          [100.5447988, 4.2347368],
          [100.5448275, 4.2349622],
          [100.5448412, 4.2351495],
          [100.5448765, 4.2353688],
          [100.5449408, 4.2355865],
          [100.5449863, 4.2357637],
          [100.5450465, 4.2359644],
          [100.5451378, 4.2362299],
          [100.5452396, 4.2365136],
          [100.5453201, 4.2367579],
          [100.5454038, 4.2370145],
          [100.5454549, 4.2372273],
          [100.5454787, 4.2373953],
          [100.5454897, 4.2375839],
          [100.5454848, 4.2377475],
          [100.5454544, 4.2379274],
          [100.5454171, 4.238105],
          [100.545336, 4.2382967],
          [100.5452071, 4.2385473],
          [100.5450814, 4.2387939],
          [100.5449943, 4.2389693],
          [100.5449048, 4.2390662],
          [100.5447701, 4.2391504],
          [100.5445814, 4.2392157],
          [100.5442891, 4.2390998],
          [100.5440084, 4.2390335],
          [100.5437669, 4.2390176],
          [100.5436224, 4.2389454],
          [100.5434761, 4.2388249],
          [100.5432202, 4.2387886],
          [100.5430707, 4.2388812],
          [100.5429187, 4.2389948],
          [100.5427422, 4.2390565],
          [100.5424835, 4.2390545],
          [100.5423315, 4.2392495],
          [100.5423296, 4.239482],
          [100.5424664, 4.2396782],
          [100.5427155, 4.239712],
          [100.5429041, 4.2397874],
          [100.5429936, 4.239993],
          [100.5430343, 4.2402764],
          [100.5430039, 4.2406235],
          [100.542879, 4.24096],
          [100.5426506, 4.2412812],
          [100.542373, 4.2415022],
          [100.5419837, 4.2415886],
          [100.541523, 4.2416202],
          [100.5413776, 4.241699],
          [100.5412799, 4.24188],
          [100.5411885, 4.2420578],
          [100.5410371, 4.2422361],
          [100.5408632, 4.2423741],
          [100.5405294, 4.2424584],
          [100.5402538, 4.242444],
          [100.5398521, 4.2424072],
          [100.5395177, 4.2424796],
          [100.5390611, 4.2424369],
          [100.5385948, 4.2424618],
          [100.5384153, 4.2425807],
          [100.5381902, 4.2426336],
          [100.5379215, 4.2425993],
          [100.5376027, 4.2427235],
          [100.5373986, 4.2429616],
          [100.5371584, 4.2430743],
          [100.5370381, 4.2433106],
          [100.5371016, 4.2435559],
          [100.5372607, 4.2436724],
          [100.5372525, 4.2438699],
          [100.5371287, 4.2440224],
          [100.5369723, 4.2441865],
          [100.5369094, 4.2443541],
          [100.5369258, 4.2445348],
          [100.5368153, 4.2446427],
          [100.536633, 4.2446776],
          [100.5364356, 4.2446637],
          [100.5362691, 4.2446603],
          [100.5361399, 4.2447501],
          [100.5360246, 4.2448984],
          [100.5358969, 4.2449961],
          [100.5357866, 4.245176],
        ],
      ],
      [
        [
          [100.5834828, 4.0725403],
          [100.5834219, 4.0725444],
          [100.5834422, 4.072662],
          [100.5835154, 4.0728323],
          [100.5835601, 4.0731446],
          [100.5836577, 4.073469],
          [100.5836902, 4.0735501],
          [100.5838121, 4.0735379],
          [100.5838813, 4.073542],
          [100.58393, 4.0737082],
          [100.5840235, 4.0736515],
          [100.5839788, 4.0739029],
          [100.5839992, 4.074057],
          [100.5840398, 4.0741381],
          [100.5840723, 4.074207],
          [100.5840886, 4.0743449],
          [100.5840683, 4.074576],
          [100.5840764, 4.0746328],
          [100.5840967, 4.0746653],
          [100.5841699, 4.0746653],
          [100.584239, 4.0746572],
          [100.5842837, 4.0746572],
          [100.5843041, 4.0746977],
          [100.5843813, 4.0747666],
          [100.5844707, 4.0748194],
          [100.5845724, 4.0748559],
          [100.5846578, 4.0748599],
          [100.5848082, 4.0748477],
          [100.5849342, 4.0748234],
          [100.5850562, 4.0747829],
          [100.5851009, 4.0747464],
          [100.5851334, 4.0746612],
          [100.5851334, 4.0745436],
          [100.5850887, 4.0744301],
          [100.5850887, 4.074349],
          [100.5850724, 4.0742719],
          [100.5850237, 4.0741949],
          [100.5849993, 4.0740651],
          [100.5849505, 4.0740205],
          [100.5848895, 4.073984],
          [100.5848651, 4.0739434],
          [100.5848651, 4.0738867],
          [100.5848692, 4.0737772],
          [100.5848488, 4.0735704],
          [100.5848285, 4.0734771],
          [100.5847757, 4.0734406],
          [100.584674, 4.0733757],
          [100.584609, 4.0732662],
          [100.584548, 4.0730189],
          [100.5845033, 4.072954],
          [100.5844098, 4.0727958],
          [100.5843366, 4.0727066],
          [100.5842715, 4.0726863],
          [100.5841984, 4.0726985],
          [100.5841577, 4.0727472],
          [100.5840886, 4.0727715],
          [100.5840032, 4.0728242],
          [100.5839463, 4.0727634],
          [100.5838894, 4.0726823],
          [100.5838081, 4.0726742],
          [100.5837186, 4.0726539],
          [100.5836089, 4.0726012],
          [100.5834828, 4.0725403],
        ],
      ],
      [
        [
          [100.560786403096103, 4.126281432498483],
          [100.5607849, 4.1263038],
          [100.5607271, 4.1263636],
          [100.5606929478392, 4.126344680479106],
          [100.560577, 4.126467],
          [100.5604307, 4.1266455],
          [100.5603738, 4.1267712],
          [100.5603412, 4.1268685],
          [100.5603372, 4.126978],
          [100.5603372, 4.1271442],
          [100.5603778, 4.1272618],
          [100.560414854743271, 4.127286462665197],
          [100.5604147, 4.127273],
          [100.5604984, 4.1272796],
          [100.560527879484269, 4.127246119728583],
          [100.5605282, 4.1272456],
          [100.560539198534883, 4.127179946169089],
          [100.5605202, 4.1271843],
          [100.5604719, 4.1271417],
          [100.5605229, 4.1270806],
          [100.5605499, 4.1271242],
          [100.560577228038426, 4.127124737600756],
          [100.5606177, 4.1270956],
          [100.5606624, 4.1270469],
          [100.560638, 4.1270185],
          [100.5605526, 4.1270064],
          [100.5605079, 4.1269861],
          [100.5605039, 4.126836],
          [100.5605648, 4.1267387],
          [100.5606502, 4.1267063],
          [100.560695577372258, 4.126694964376645],
          [100.5607357, 4.1266594],
          [100.5607729, 4.1266713],
          [100.56079375422479, 4.126666015769943],
          [100.560870895114945, 4.126621384254921],
          [100.5608741, 4.1265822],
          [100.560978281877354, 4.126529114794445],
          [100.560996487718072, 4.126510958696461],
          [100.5610096, 4.1264426],
          [100.5610151, 4.1263909],
          [100.5611172, 4.1262777],
          [100.561153778590622, 4.126270751032914],
          [100.561155150958925, 4.126268835341766],
          [100.56115530159741, 4.126263914484471],
          [100.5610933, 4.1262562],
          [100.5610161, 4.1262724],
          [100.5609632, 4.1262724],
          [100.5609429, 4.1262238],
          [100.5608535, 4.126244],
          [100.560786403096103, 4.126281432498483],
        ],
      ],
      [
        [
          [100.6365503, 4.1564825],
          [100.6365411, 4.1571595],
          [100.6365501, 4.1661471],
          [100.6368162, 4.1664651],
          [100.6368754, 4.1684695],
          [100.6369659, 4.1690904],
          [100.6372212, 4.1694707],
          [100.6378412, 4.1695202],
          [100.64028, 4.1695743],
          [100.6444124, 4.1695406],
          [100.6479237, 4.1687229],
          [100.6526838, 4.1675621],
          [100.6532188, 4.1674256],
          [100.6558251, 4.1674638],
          [100.6563457, 4.1671391],
          [100.6566802, 4.1665523],
          [100.6567195, 4.1654726],
          [100.6566931, 4.1562378],
          [100.6565565, 4.1558232],
          [100.656289, 4.1554931],
          [100.6558479, 4.15523],
          [100.6372489, 4.155333],
          [100.6367631, 4.1558038],
          [100.6365503, 4.1564825],
        ],
      ],
      [
        [
          [100.530304823882659, 3.997202070747133],
          [100.5303048, 3.9972124],
          [100.530299443306163, 3.997211906329454],
          [100.5302274, 3.9973436],
          [100.5302589, 3.9974152],
          [100.5303435, 3.9974441],
          [100.5303968, 3.9974511],
          [100.530404, 3.9975082],
          [100.5303308, 3.9975306],
          [100.530318, 3.9976172],
          [100.5303752, 3.9976619],
          [100.5304473, 3.9976722],
          [100.5304962, 3.9976402],
          [100.5305379, 3.9976119],
          [100.5306685, 3.9976526],
          [100.5308678, 3.9979551],
          [100.5309585, 3.997982],
          [100.531047, 3.9979575],
          [100.5310575, 3.9980121],
          [100.531137, 3.9979839],
          [100.5312054, 3.997937],
          [100.5312229, 3.9981588],
          [100.5312791, 3.9981904],
          [100.5313461, 3.9982268],
          [100.5314881, 3.9982426],
          [100.5315246, 3.9983925],
          [100.5316071, 3.9984433],
          [100.5316857, 3.9985639],
          [100.5318576, 3.9986469],
          [100.5319462, 3.9986935],
          [100.5320556, 3.9985785],
          [100.532165, 3.9986467],
          [100.5322002, 3.9986096],
          [100.5323801, 3.9986248],
          [100.5324197, 3.99853],
          [100.5325385, 3.9984846],
          [100.5325887, 3.9983983],
          [100.5326173, 3.9981624],
          [100.5329674, 3.997839],
          [100.5332274, 3.9978037],
          [100.5333038, 3.9976575],
          [100.533437, 3.9975692],
          [100.5339329, 3.9974092],
          [100.5340523, 3.9973175],
          [100.5341171, 3.9971813],
          [100.5341676, 3.9970351],
          [100.5341736, 3.9968059],
          [100.5341699, 3.9966314],
          [100.5341467, 3.9964877],
          [100.5341002, 3.9963579],
          [100.5341337, 3.9961931],
          [100.5341533, 3.9960687],
          [100.5342084, 3.995946],
          [100.5342237, 3.995811],
          [100.5341909, 3.9956516],
          [100.5340937, 3.9955852],
          [100.5339942, 3.9956101],
          [100.533964055190921, 3.995527295143936],
          [100.5339474, 3.9955224],
          [100.5337995, 3.9956341],
          [100.5336981, 3.9950311],
          [100.5336353, 3.9947657],
          [100.5336694, 3.9946412],
          [100.5336466, 3.9943694],
          [100.5334018, 3.9942536],
          [100.5336844, 3.9937067],
          [100.5342182, 3.9932965],
          [100.5342692, 3.9931547],
          [100.5345617, 3.9928876],
          [100.5346153, 3.9927673],
          [100.5345952, 3.9926805],
          [100.5345417, 3.9926404],
          [100.5344747, 3.9926204],
          [100.5345015, 3.99244],
          [100.5344479, 3.9923264],
          [100.5342403, 3.9922262],
          [100.5339791, 3.9920993],
          [100.5337782, 3.9920191],
          [100.5336443, 3.9918722],
          [100.5335706, 3.9917653],
          [100.5335238, 3.9916183],
          [100.5335506, 3.9914714],
          [100.5335907, 3.9912442],
          [100.533584, 3.9910839],
          [100.533538347879698, 3.991030743421564],
          [100.5334264, 3.9909856],
          [100.5333731, 3.990845],
          [100.5332524, 3.9907564],
          [100.5330987, 3.9905483],
          [100.532885, 3.9904269],
          [100.532655392729382, 3.990395977658602],
          [100.53256139228408, 3.990417780539663],
          [100.5323577, 3.990498],
          [100.532343805578506, 3.990492729388833],
          [100.5322246, 3.9905561],
          [100.5321108, 3.9906697],
          [100.5319702, 3.9910104],
          [100.5318429, 3.9910639],
          [100.531709, 3.9911975],
          [100.5316153, 3.9914045],
          [100.5315416, 3.9916384],
          [100.5315952, 3.991812],
          [100.5316554, 3.9919457],
          [100.5316554, 3.9922463],
          [100.5315014, 3.9929009],
          [100.5313742, 3.9932416],
          [100.5313072, 3.993442],
          [100.5312068, 3.9933886],
          [100.5311599, 3.9934354],
          [100.5311398, 3.9936157],
          [100.531140965609893, 3.993642439438731],
          [100.5312383, 3.9936489],
          [100.531240217451113, 3.993769355262811],
          [100.5312603, 3.9937493],
          [100.531334, 3.9937493],
          [100.5313675, 3.9938161],
          [100.531357941114052, 3.994063917685029],
          [100.5313695, 3.9941235],
          [100.531354413561672, 3.994155370796846],
          [100.5313541, 3.9941635],
          [100.5314144, 3.9942838],
          [100.5315026, 3.9944114],
          [100.5315127, 3.9945809],
          [100.5315327, 3.9947395],
          [100.531519, 3.9949657],
          [100.5312985, 3.9953051],
          [100.5311237, 3.9955402],
          [100.5310362, 3.9958708],
          [100.531001, 3.9961027],
          [100.5309735, 3.9962837],
          [100.5308251, 3.9965612],
          [100.530768, 3.9967056],
          [100.5307847, 3.9968288],
          [100.5307874, 3.9969299],
          [100.530726, 3.9969964],
          [100.5305565, 3.9970586],
          [100.5304062, 3.9971016],
          [100.5303239, 3.9971672],
          [100.530304823882659, 3.997202070747133],
        ],
      ],
      [
        [
          [100.5438111, 4.0044417],
          [100.5437307, 4.0044684],
          [100.5435231, 4.0046956],
          [100.5434963, 4.0048826],
          [100.5435097, 4.0051097],
          [100.5435834, 4.0052233],
          [100.543597120655136, 4.005235607632438],
          [100.5436504, 4.0052414],
          [100.5436667, 4.0051785],
          [100.5439607, 4.0051508],
          [100.5441179, 4.0051846],
          [100.5441995, 4.0053636],
          [100.5441861, 4.0054638],
          [100.5440789, 4.0056709],
          [100.5438847, 4.0058846],
          [100.5437374, 4.0060316],
          [100.5437173, 4.0062788],
          [100.5437508, 4.0067932],
          [100.5439517, 4.0070403],
          [100.54382334757085, 4.007097910014878],
          [100.5438248, 4.0071144],
          [100.5439721, 4.0071997],
          [100.5440923, 4.0073142],
          [100.5441191, 4.0073944],
          [100.5442397, 4.007528],
          [100.54432, 4.0076683],
          [100.5443937, 4.0076883],
          [100.5444874, 4.0077751],
          [100.544829, 4.0081359],
          [100.5450031, 4.0082294],
          [100.5452308, 4.0082962],
          [100.545358, 4.0083296],
          [100.5454989, 4.0082505],
          [100.5456645, 4.0080415],
          [100.5458661, 4.0075634],
          [100.5460804, 4.0072977],
          [100.5467056, 4.0069195],
          [100.5468052, 4.0069408],
          [100.5467998, 4.0070114],
          [100.546929820258299, 4.007074176652317],
          [100.5469384, 4.0070737],
          [100.54697459278654, 4.007069470087337],
          [100.5470418, 4.0069519],
          [100.5468856, 4.0069156],
          [100.546884, 4.0068377],
          [100.5471208, 4.0065919],
          [100.547497, 4.0062562],
          [100.547695, 4.0057273],
          [100.5480208, 4.0051074],
          [100.5483536, 4.0048059],
          [100.5485038, 4.004758],
          [100.5486164, 4.0044839],
          [100.5487177, 4.0040834],
          [100.5487618, 4.0037284],
          [100.5487706, 4.0034359],
          [100.548788, 4.0032521],
          [100.5488368, 4.0031118],
          [100.5489253, 4.0029998],
          [100.549021, 4.0029428],
          [100.5491476, 4.0028773],
          [100.5492803, 4.0027319],
          [100.5493536, 4.0025719],
          [100.5493383, 4.0024059],
          [100.5493323, 4.002278],
          [100.5492523, 4.0021688],
          [100.5491616, 4.0021103],
          [100.5490076, 4.0020101],
          [100.548867, 4.0017964],
          [100.5488201, 4.0016895],
          [100.5487063, 4.001616],
          [100.5485723, 4.001636],
          [100.5483313, 4.0017162],
          [100.5481705, 4.001783],
          [100.54805, 4.0018231],
          [100.5479697, 4.0019634],
          [100.5479027, 4.002097],
          [100.5477821, 4.0021437],
          [100.5478089, 4.0023642],
          [100.5478692, 4.0025045],
          [100.5479362, 4.0027984],
          [100.547963, 4.0030522],
          [100.547829, 4.0032994],
          [100.5473201, 4.0037603],
          [100.5472301, 4.003797],
          [100.5471728, 4.0038205],
          [100.5469786, 4.0038672],
          [100.5467241, 4.0038539],
          [100.5464696, 4.0039073],
          [100.5463022, 4.0039741],
          [100.5459942, 4.0042012],
          [100.5456259, 4.004395],
          [100.5451303, 4.0045219],
          [100.5449562, 4.0045352],
          [100.5446749, 4.0045152],
          [100.5445209, 4.0045018],
          [100.5442999, 4.0044684],
          [100.5441124, 4.0045286],
          [100.5439383, 4.0045018],
          [100.5438111, 4.0044417],
        ],
      ],
      [
        [
          [100.534338761361397, 4.010772737749987],
          [100.5343539, 4.0108019],
          [100.5343275, 4.0108432],
          [100.5342948, 4.0109283],
          [100.534276473323558, 4.010919059658939],
          [100.534246, 4.0109545],
          [100.5341553, 4.011045],
          [100.53415799482228, 4.011086829365388],
          [100.5341927, 4.0110834],
          [100.5341666, 4.0111428],
          [100.5341899, 4.0111905],
          [100.5342284, 4.011134],
          [100.5343067, 4.0111245],
          [100.5343075, 4.0110995],
          [100.5343365, 4.0111021],
          [100.5343699, 4.0111572],
          [100.534423, 4.0111576],
          [100.5343798, 4.0112299],
          [100.5344546, 4.0112435],
          [100.5345037, 4.0111784],
          [100.5345075, 4.0112602],
          [100.5345388, 4.0112371],
          [100.5346287, 4.011219],
          [100.5346794, 4.0111479],
          [100.534686, 4.0111177],
          [100.5347301, 4.0111115],
          [100.5347278, 4.0111371],
          [100.5347883, 4.0111018],
          [100.5348508, 4.0110761],
          [100.5348605, 4.0110472],
          [100.5348869, 4.0110702],
          [100.5349295, 4.0110736],
          [100.5349616, 4.0110245],
          [100.5349615, 4.0109384],
          [100.534983, 4.0109168],
          [100.5349937, 4.0108899],
          [100.5350205, 4.0108899],
          [100.5350178, 4.0109969],
          [100.5350795, 4.0110156],
          [100.5350957, 4.0109648],
          [100.5351494, 4.0109634],
          [100.5351789, 4.0109307],
          [100.5351615, 4.0108715],
          [100.5352762, 4.0107964],
          [100.535304, 4.0107642],
          [100.5353635, 4.0107347],
          [100.5353962, 4.0107641],
          [100.5354613, 4.0107391],
          [100.5354428, 4.0106891],
          [100.5354685, 4.0106588],
          [100.5355081, 4.0106918],
          [100.5355505, 4.0106526],
          [100.5356168, 4.0106584],
          [100.5356319, 4.01059],
          [100.5356636, 4.0105556],
          [100.5357036, 4.0105973],
          [100.5357523, 4.010593],
          [100.5357221, 4.0106443],
          [100.5358187, 4.0106626],
          [100.5358323, 4.0106361],
          [100.5358806, 4.0106119],
          [100.5359321, 4.0105028],
          [100.5359517, 4.0105907],
          [100.5359828, 4.0106286],
          [100.5360279, 4.0106164],
          [100.5360315, 4.0105834],
          [100.5360716, 4.0105725],
          [100.536029, 4.0104859],
          [100.5360117, 4.0104292],
          [100.5360531, 4.0103773],
          [100.5360647, 4.0102756],
          [100.5360368, 4.0102587],
          [100.5359652, 4.0102751],
          [100.5359324, 4.0102651],
          [100.5359039, 4.0102147],
          [100.5358632, 4.01024],
          [100.5358393, 4.0101769],
          [100.5356512, 4.0102241],
          [100.5355966, 4.0102871],
          [100.5355147, 4.0102337],
          [100.535419541644885, 4.010232253335817],
          [100.53535533680774, 4.010246175051848],
          [100.53531348324185, 4.010260760385416],
          [100.535193803183034, 4.010353209486595],
          [100.5351924, 4.0103594],
          [100.5351233, 4.0104222],
          [100.5351267, 4.0104468],
          [100.5350889, 4.0104772],
          [100.5350366, 4.010447],
          [100.5350133, 4.010493],
          [100.5349292, 4.0104999],
          [100.534909088983198, 4.010500125018369],
          [100.534839683748558, 4.01053249081263],
          [100.5348165, 4.0105734],
          [100.5347995, 4.0106219],
          [100.5347789, 4.0105655],
          [100.534767021634806, 4.010566375389726],
          [100.534711, 4.0105925],
          [100.5345919, 4.0106321],
          [100.5345665, 4.0106556],
          [100.5344922, 4.010668],
          [100.534474080187195, 4.010650135395817],
          [100.5343481, 4.0107339],
          [100.534338761361397, 4.010772737749987],
        ],
      ],
      [
        [
          [100.535372517761331, 4.008603445790935],
          [100.5353987, 4.0085825],
          [100.5354553, 4.0085581],
          [100.5354694, 4.0085186],
          [100.5353959, 4.0085162],
          [100.535378, 4.0084936],
          [100.5354468, 4.0084689],
          [100.5355156, 4.0084095],
          [100.5354853, 4.0083687],
          [100.535514, 4.0083011],
          [100.535569, 4.0082905],
          [100.5355544, 4.0082545],
          [100.535592, 4.0082252],
          [100.5355422, 4.0081626],
          [100.5354935, 4.0081121],
          [100.5355746, 4.0081026],
          [100.5354111, 4.0080327],
          [100.5353857, 4.0078318],
          [100.5353649, 4.0076993],
          [100.5353814, 4.0076464],
          [100.5353334, 4.0075213],
          [100.5352532, 4.007504],
          [100.5351232, 4.0074411],
          [100.5350269, 4.0073879],
          [100.5350001, 4.0073318],
          [100.5349056, 4.0072863],
          [100.5348273, 4.0071928],
          [100.5345672, 4.0071459],
          [100.5344788, 4.007027],
          [100.5344897, 4.0069403],
          [100.5344358, 4.0068855],
          [100.5343872, 4.0068896],
          [100.5343695, 4.0068362],
          [100.5343941, 4.0068242],
          [100.5343582, 4.006786],
          [100.5343751, 4.0066947],
          [100.5343518, 4.0066382],
          [100.5342714, 4.0066303],
          [100.5342741, 4.0065836],
          [100.5342017, 4.0065448],
          [100.5340545, 4.0065475],
          [100.5340331, 4.0065648],
          [100.5340271, 4.0066068],
          [100.5340616, 4.0066439],
          [100.533985, 4.0066758],
          [100.533983627891089, 4.00677102435874],
          [100.534005438749503, 4.006877026758334],
          [100.5340613, 4.006904],
          [100.5340672, 4.0069687],
          [100.534105, 4.0070358],
          [100.5341481, 4.0070426],
          [100.5341964, 4.0070968],
          [100.5341696, 4.0071106],
          [100.5341884, 4.0071656],
          [100.5342354, 4.0071664],
          [100.5342454, 4.0072283],
          [100.5342072, 4.0072474],
          [100.5342669, 4.0072714],
          [100.5343262, 4.0072111],
          [100.5343719, 4.0072104],
          [100.5343948, 4.0072904],
          [100.5343017, 4.007341],
          [100.534252597298305, 4.007380081742172],
          [100.534265314031643, 4.007408256793148],
          [100.5342964, 4.0074271],
          [100.534259751359329, 4.007427850804419],
          [100.534226282927307, 4.007491563436899],
          [100.5342348, 4.007506],
          [100.534218458801718, 4.007506457939185],
          [100.5341938, 4.0075534],
          [100.5341269, 4.0076336],
          [100.5341202, 4.0077338],
          [100.5340934, 4.0079208],
          [100.534067005479116, 4.007997554216216],
          [100.5341065, 4.0079879],
          [100.5340651, 4.0080806],
          [100.53403362807876, 4.008094614368003],
          [100.534013, 4.0081546],
          [100.534009685287387, 4.008158672905498],
          [100.5340038, 4.0082561],
          [100.5340481, 4.0083764],
          [100.5341325, 4.0084552],
          [100.5341323, 4.0084833],
          [100.5341657, 4.0084893],
          [100.5342608, 4.0084016],
          [100.5342205, 4.0084872],
          [100.5342888, 4.0085594],
          [100.5344242, 4.0086396],
          [100.5344737, 4.008687],
          [100.5344931, 4.0086545],
          [100.5345527, 4.0086862],
          [100.5346538, 4.0086969],
          [100.534691, 4.0087317],
          [100.5347337, 4.0087464],
          [100.534771, 4.0087371],
          [100.5348326, 4.0087637],
          [100.5348023, 4.008709],
          [100.5348516, 4.0086468],
          [100.5349728, 4.0086639],
          [100.5350188, 4.0086863],
          [100.5350087, 4.0087211],
          [100.535079, 4.0087051],
          [100.535102512826427, 4.008727409414061],
          [100.535104086357535, 4.008726300475617],
          [100.5350856, 4.0085554],
          [100.5351661, 4.0085313],
          [100.5353469, 4.0085193],
          [100.535356777155428, 4.008602862483083],
          [100.535372517761331, 4.008603445790935],
        ],
      ],
      [
        [
          [100.5429107, 4.0276307],
          [100.5427677, 4.0278638],
          [100.5427682, 4.0281072],
          [100.5429296, 4.0282291],
          [100.5431946, 4.0283175],
          [100.5435651, 4.0283836],
          [100.5438369, 4.0283906],
          [100.5440702, 4.0283575],
          [100.5443878, 4.0282651],
          [100.5445666, 4.028357],
          [100.5447247, 4.0285273],
          [100.5447917, 4.0286531],
          [100.544782, 4.0287831],
          [100.5448107, 4.0289418],
          [100.544843, 4.0291804],
          [100.5448561, 4.0293423],
          [100.5448342, 4.0294362],
          [100.5448197, 4.029602],
          [100.5447524, 4.0297198],
          [100.544637, 4.0298118],
          [100.5445311, 4.0298616],
          [100.5444096, 4.0300064],
          [100.5443649, 4.0301224],
          [100.5444003, 4.0302587],
          [100.5444135, 4.0303397],
          [100.5443919, 4.0304635],
          [100.5442751, 4.0306603],
          [100.544115, 4.0308044],
          [100.5439221, 4.0309564],
          [100.5437435, 4.0311036],
          [100.5436178, 4.0312662],
          [100.5435114, 4.0313138],
          [100.5434744, 4.0314143],
          [100.5435047, 4.0315482],
          [100.5436125, 4.0316649],
          [100.5437333, 4.03179],
          [100.5438385, 4.0318957],
          [100.5439179, 4.0320134],
          [100.5439732, 4.0321216],
          [100.5439132, 4.0322824],
          [100.5438129, 4.0323956],
          [100.5437203, 4.0325528],
          [100.5436218, 4.0327805],
          [100.5435206, 4.03297],
          [100.5435221, 4.0332481],
          [100.5435332, 4.0335694],
          [100.543613, 4.0336564],
          [100.5437149, 4.0336962],
          [100.5438339, 4.0337196],
          [100.5439481, 4.0337191],
          [100.5440998, 4.0337182],
          [100.5441822, 4.033697],
          [100.5442894, 4.033744],
          [100.5443984, 4.0337716],
          [100.5445123, 4.0337417],
          [100.544644, 4.0337202],
          [100.5447248, 4.0336197],
          [100.5447877, 4.0335393],
          [100.5449466, 4.0335068],
          [100.5451142, 4.0334889],
          [100.5453509, 4.033449],
          [100.5455685, 4.0333874],
          [100.5456818, 4.0334071],
          [100.5457422, 4.0334699],
          [100.545815, 4.0334997],
          [100.5460335, 4.0334885],
          [100.546252, 4.0334725],
          [100.5464394, 4.0334596],
          [100.5465843, 4.0334952],
          [100.5467628, 4.0335884],
          [100.5468835, 4.0335469],
          [100.5469657, 4.0334623],
          [100.5470053, 4.0333314],
          [100.5470557, 4.033242],
          [100.5472189, 4.0331448],
          [100.5475234, 4.0330296],
          [100.5478759, 4.0329194],
          [100.5481389, 4.0327853],
          [100.5483538, 4.0326512],
          [100.5485425, 4.0324268],
          [100.5487504, 4.0322502],
          [100.5488536, 4.0321882],
          [100.5489568, 4.0321598],
          [100.5490576, 4.0322275],
          [100.5491631, 4.0322192],
          [100.5493726, 4.0320803],
          [100.5495725, 4.0320084],
          [100.5497499, 4.0318767],
          [100.5498328, 4.0317908],
          [100.5500021, 4.0316044],
          [100.5502506, 4.0313941],
          [100.5505593, 4.0312116],
          [100.5508135, 4.0311016],
          [100.5509188, 4.0311066],
          [100.5510207, 4.0311751],
          [100.5511227, 4.0311861],
          [100.5512785, 4.0311793],
          [100.5514654, 4.0311563],
          [100.5516706, 4.0311466],
          [100.5519718, 4.0312519],
          [100.5520945, 4.0313196],
          [100.5521184, 4.0314852],
          [100.5521972, 4.0316454],
          [100.5523144, 4.0317146],
          [100.5524222, 4.03172],
          [100.5524928, 4.0316998],
          [100.552549, 4.0316603],
          [100.5526326, 4.0315911],
          [100.5527326, 4.0315963],
          [100.5528992, 4.0316143],
          [100.5530624, 4.0316502],
          [100.5531846, 4.0317561],
          [100.5532399, 4.0319333],
          [100.553354, 4.0321138],
          [100.5536016, 4.0321083],
          [100.5538008, 4.0318365],
          [100.553838, 4.0315317],
          [100.5537929, 4.0313589],
          [100.5537421, 4.031254],
          [100.5536632, 4.0311913],
          [100.5535167, 4.0312242],
          [100.5533519, 4.0312316],
          [100.5531815, 4.0311486],
          [100.5530461, 4.0309802],
          [100.5528897, 4.0307316],
          [100.5528024, 4.0305596],
          [100.5527245, 4.0303695],
          [100.5526397, 4.0301062],
          [100.5525889, 4.0298389],
          [100.5525586, 4.0296076],
          [100.5527022, 4.0293366],
          [100.552843, 4.0292556],
          [100.5530599, 4.02918],
          [100.5532028, 4.0291161],
          [100.5533265, 4.029036],
          [100.5533595, 4.028922],
          [100.5533145, 4.0286219],
          [100.5532473, 4.0283105],
          [100.5532582, 4.0279771],
          [100.5532177, 4.0276234],
          [100.5531912, 4.0274687],
          [100.5531556, 4.0273231],
          [100.5531932, 4.0272344],
          [100.5532521, 4.0271865],
          [100.553191, 4.0271639],
          [100.5531096, 4.027216],
          [100.5529991, 4.0272123],
          [100.5529239, 4.0271745],
          [100.5528872, 4.0270935],
          [100.5528329, 4.0270467],
          [100.5526955, 4.027087],
          [100.5525647, 4.0270719],
          [100.5523743, 4.0269756],
          [100.5522921, 4.0268225],
          [100.5523057, 4.0266445],
          [100.5523019, 4.026436],
          [100.5522405, 4.0262945],
          [100.5521242, 4.0261301],
          [100.5520175, 4.0260376],
          [100.5518905, 4.0259962],
          [100.5517421, 4.025923],
          [100.5515895, 4.0259395],
          [100.5513994, 4.0260108],
          [100.5512306, 4.026048],
          [100.5510921, 4.0259888],
          [100.5509631, 4.0258865],
          [100.5508419, 4.0257778],
          [100.5507899, 4.025679],
          [100.5507859, 4.0255801],
          [100.550874, 4.0254304],
          [100.5508515, 4.0251356],
          [100.5508246, 4.0249238],
          [100.5508601, 4.0247647],
          [100.550835, 4.0247051],
          [100.5507657, 4.0247009],
          [100.5507134, 4.0247403],
          [100.5506185, 4.0247809],
          [100.5505151, 4.0248237],
          [100.5503179, 4.0248615],
          [100.5501644, 4.0249548],
          [100.5500397, 4.0250145],
          [100.5499343, 4.0249903],
          [100.5497042, 4.0248558],
          [100.5496033, 4.0247404],
          [100.5495664, 4.0245981],
          [100.5495696, 4.0242709],
          [100.549534, 4.024021],
          [100.5494695, 4.0238764],
          [100.5493411, 4.0237849],
          [100.5491838, 4.0236933],
          [100.5490857, 4.0235447],
          [100.5490596, 4.0234439],
          [100.5489883, 4.0234339],
          [100.5488739, 4.0234795],
          [100.5487273, 4.0235788],
          [100.5485728, 4.023544],
          [100.5484644, 4.023423],
          [100.548361, 4.0232924],
          [100.5482365, 4.0231085],
          [100.5480567, 4.0229955],
          [100.5477765, 4.0228984],
          [100.5475015, 4.0228257],
          [100.5472406, 4.0227618],
          [100.5469693, 4.022704],
          [100.5467204, 4.0226155],
          [100.5464449, 4.0225938],
          [100.5461913, 4.022608],
          [100.5459289, 4.0225902],
          [100.5456833, 4.0225304],
          [100.5455625, 4.0225611],
          [100.5453845, 4.0226969],
          [100.5450347, 4.0229533],
          [100.5447889, 4.0230641],
          [100.5445924, 4.0231935],
          [100.5444198, 4.0235179],
          [100.5441444, 4.0238103],
          [100.5440995, 4.0239207],
          [100.5440325, 4.0240397],
          [100.54398, 4.024106],
          [100.5439902, 4.0241621],
          [100.5440453, 4.0242145],
          [100.5440381, 4.024286],
          [100.5440332, 4.0243812],
          [100.543985, 4.0244949],
          [100.5439195, 4.0245588],
          [100.5438396, 4.024661],
          [100.5438719, 4.02476],
          [100.5439942, 4.0248526],
          [100.544054, 4.0250055],
          [100.5440351, 4.0251395],
          [100.5439298, 4.0252735],
          [100.5438244, 4.0254202],
          [100.5437284, 4.0255632],
          [100.5435987, 4.0256152],
          [100.5434783, 4.0256521],
          [100.5434155, 4.0257465],
          [100.543392, 4.025853],
          [100.5433344, 4.0259192],
          [100.5432767, 4.0260044],
          [100.5432191, 4.0261175],
          [100.5432287, 4.0262575],
          [100.5432672, 4.0263603],
          [100.5432337, 4.0265111],
          [100.5432628, 4.0265923],
          [100.5433392, 4.0266725],
          [100.5433484, 4.0268198],
          [100.5433113, 4.0269076],
          [100.5433127, 4.0270529],
          [100.543373, 4.0271807],
          [100.5434094, 4.0272815],
          [100.5433017, 4.0274665],
          [100.5431052, 4.0275106],
          [100.5429107, 4.0276307],
        ],
      ],
      [
        [
          [100.538584563832174, 4.058820167817988],
          [100.5385905, 4.0588498],
          [100.538554930215412, 4.058877282559943],
          [100.5385034, 4.0589766],
          [100.5384766, 4.0591235],
          [100.5385034, 4.059197],
          [100.5385704, 4.0592905],
          [100.5387043, 4.0593306],
          [100.5387873, 4.0593099],
          [100.5388114, 4.0592624],
          [100.5388595, 4.0592812],
          [100.5390492, 4.0591917],
          [100.5392066, 4.0591476],
          [100.5393942, 4.0591208],
          [100.5394744, 4.0591369],
          [100.5397958, 4.0590567],
          [100.5401575, 4.0589231],
          [100.5405459, 4.0587829],
          [100.5408874, 4.0586827],
          [100.5411686, 4.0586092],
          [100.5413227, 4.0585424],
          [100.5414968, 4.0584288],
          [100.5416173, 4.0582685],
          [100.5416843, 4.058175],
          [100.5417646, 4.0580948],
          [100.5418517, 4.0580948],
          [100.5419856, 4.0580614],
          [100.5420888, 4.0579705],
          [100.542287, 4.0578611],
          [100.5424235, 4.0577354],
          [100.5425032, 4.057514],
          [100.542599, 4.0574746],
          [100.5426465, 4.0574727],
          [100.5427101, 4.0574306],
          [100.5427609, 4.0574147],
          [100.5428145, 4.0573667],
          [100.542927, 4.057334],
          [100.5430503, 4.0572775],
          [100.5432379, 4.0572291],
          [100.5433101, 4.0571609],
          [100.5434259, 4.057179],
          [100.5434918, 4.0570704],
          [100.5435516, 4.0571257],
          [100.5436217, 4.0570384],
          [100.5436462, 4.0570928],
          [100.543685, 4.057113],
          [100.5437848, 4.0570527],
          [100.5438374, 4.0569558],
          [100.5438825, 4.0570196],
          [100.5440053, 4.057038],
          [100.5440722, 4.0569986],
          [100.5440816, 4.0570404],
          [100.5441606, 4.0570849],
          [100.5442068, 4.0570414],
          [100.5442459, 4.0570651],
          [100.544295, 4.0570502],
          [100.5443575, 4.0569845],
          [100.5444056, 4.05702],
          [100.5445236, 4.0569842],
          [100.5445718, 4.0569805],
          [100.5446492, 4.0570264],
          [100.5447534, 4.0569973],
          [100.5447848, 4.0570087],
          [100.5448758, 4.0569418],
          [100.5448684, 4.0568475],
          [100.5448449, 4.0568175],
          [100.5448342, 4.0567708],
          [100.5447457, 4.0567481],
          [100.5447084, 4.0567683],
          [100.5446523, 4.0567456],
          [100.5445719, 4.0567216],
          [100.5444432, 4.056628],
          [100.5443696, 4.0566307],
          [100.5441954, 4.0566535],
          [100.544138199708982, 4.05661772478881],
          [100.5441285, 4.0566186],
          [100.5439477, 4.056652],
          [100.5437535, 4.0566654],
          [100.543566, 4.0566854],
          [100.5434187, 4.0567322],
          [100.5432312, 4.0567589],
          [100.5430839, 4.0568056],
          [100.5429419, 4.0569033],
          [100.5429091, 4.0569392],
          [100.5428521, 4.0569216],
          [100.542847456182102, 4.056922138824673],
          [100.542772300380136, 4.056963470645735],
          [100.5428115, 4.0569694],
          [100.5427536, 4.0569928],
          [100.542670258882325, 4.057001015937326],
          [100.5425347, 4.0570461],
          [100.5423338, 4.0571797],
          [100.5421865, 4.0572933],
          [100.5421062, 4.05732],
          [100.5419454, 4.0573467],
          [100.541845, 4.0573267],
          [100.541735402058976, 4.057290230016195],
          [100.5416106, 4.0572732],
          [100.5414767, 4.0572933],
          [100.541418734292847, 4.057312551958847],
          [100.5413821, 4.0573339],
          [100.541374161216339, 4.057327355867516],
          [100.5412557, 4.0573667],
          [100.5411496397017, 4.057275328296992],
          [100.5411565, 4.0573266],
          [100.5410655, 4.0572692],
          [100.5410348, 4.0572386],
          [100.54091013893914, 4.057253159484738],
          [100.5408137, 4.0572866],
          [100.540737272802247, 4.057329693690507],
          [100.5407386, 4.0573374],
          [100.540714842671491, 4.057342340982853],
          [100.540382421593279, 4.057529777543183],
          [100.5403668, 4.0575573],
          [100.540346272607508, 4.057553591269424],
          [100.5402512, 4.0576673],
          [100.5402445, 4.0577943],
          [100.5402445, 4.0578811],
          [100.5402177, 4.0579546],
          [100.5401173, 4.0580414],
          [100.5399633, 4.0581416],
          [100.5396619, 4.0583019],
          [100.5394744, 4.0584422],
          [100.5394141, 4.0585157],
          [100.5393539, 4.0585691],
          [100.539277290033226, 4.0585691],
          [100.5392783, 4.0585786],
          [100.539244949968435, 4.058571423410929],
          [100.539086, 4.0585958],
          [100.539061768089908, 4.058585466562862],
          [100.5390619, 4.0585904],
          [100.5390123, 4.0585999],
          [100.538928859369705, 4.058553631972776],
          [100.538927104174306, 4.058554170017753],
          [100.538916, 4.0585691],
          [100.538905627330749, 4.058560753619623],
          [100.5388784, 4.0585691],
          [100.5386842, 4.058696],
          [100.5386039, 4.0587829],
          [100.538584563832174, 4.058820167817988],
        ],
      ],
      [
        [
          [100.5747706, 4.0597541],
          [100.5747543, 4.0598555],
          [100.5747787, 4.0599123],
          [100.5748234, 4.0599366],
          [100.5748682, 4.0599163],
          [100.5749129, 4.0598798],
          [100.5749779, 4.0598677],
          [100.5750186, 4.0598839],
          [100.5750308, 4.0599366],
          [100.5750104, 4.0600177],
          [100.5749698, 4.0600501],
          [100.574921, 4.0600785],
          [100.5749169, 4.0601596],
          [100.5749129, 4.0602407],
          [100.5748641, 4.060261],
          [100.5748234, 4.0602935],
          [100.5748112, 4.0603989],
          [100.5748112, 4.0604841],
          [100.5748438, 4.0605206],
          [100.5753398, 4.0606422],
          [100.5755837, 4.0606828],
          [100.5757748, 4.0606625],
          [100.5759658, 4.060553],
          [100.5761081, 4.0604476],
          [100.5761366, 4.0603786],
          [100.5761447, 4.0602407],
          [100.5761244, 4.0601069],
          [100.5760106, 4.0600826],
          [100.5759292, 4.0600907],
          [100.5758601, 4.0600866],
          [100.575787, 4.0600623],
          [100.5757138, 4.0600177],
          [100.5756406, 4.0599569],
          [100.5755959, 4.0598839],
          [100.5755634, 4.0598068],
          [100.5755349, 4.0597379],
          [100.5754495, 4.0597338],
          [100.5753601, 4.0597703],
          [100.5752137, 4.059819],
          [100.5751121, 4.059819],
          [100.5750592, 4.0597987],
          [100.5749942, 4.0597095],
          [100.574921, 4.0597014],
          [100.5748031, 4.0597136],
          [100.5747706, 4.0597541],
        ],
      ],
      [
        [
          [100.5778528, 4.0610518],
          [100.5778158, 4.0611331],
          [100.5778306, 4.0611897],
          [100.5778898, 4.0612635],
          [100.5779342, 4.0613005],
          [100.5779984, 4.0613005],
          [100.5780453, 4.0612955],
          [100.5780996, 4.0613202],
          [100.5781613, 4.0613497],
          [100.5782205, 4.0613546],
          [100.5782353, 4.06133],
          [100.5782699, 4.0613226],
          [100.5782995, 4.0613152],
          [100.5783094, 4.0612685],
          [100.578302, 4.0612315],
          [100.5782699, 4.0612069],
          [100.5782353, 4.0611823],
          [100.5781601, 4.0611306],
          [100.5780897, 4.0611134],
          [100.5780132, 4.061101],
          [100.577949, 4.0610715],
          [100.5778972, 4.061042],
          [100.5778528, 4.0610518],
        ],
      ],
      [
        [
          [100.5779115, 4.0666211],
          [100.5779012, 4.0666795],
          [100.5779012, 4.0667344],
          [100.5779219, 4.0668134],
          [100.5780079, 4.0668615],
          [100.5780664, 4.0668958],
          [100.5781009, 4.0669233],
          [100.5781387, 4.0669954],
          [100.5781904, 4.0670847],
          [100.5782523, 4.0671156],
          [100.5783109, 4.0671396],
          [100.578335, 4.0671636],
          [100.5783763, 4.0672014],
          [100.5784313, 4.0672186],
          [100.5784968, 4.0672117],
          [100.5785312, 4.0671774],
          [100.5785553, 4.0671396],
          [100.5785725, 4.0670984],
          [100.5785656, 4.0670641],
          [100.5785518, 4.0670091],
          [100.5785553, 4.066913],
          [100.5785415, 4.0667962],
          [100.5785312, 4.0667379],
          [100.5785036, 4.0666692],
          [100.5785071, 4.066549],
          [100.5785002, 4.0664838],
          [100.5784554, 4.0664254],
          [100.5784176, 4.0664082],
          [100.5783763, 4.0664117],
          [100.5782661, 4.0664254],
          [100.5781869, 4.0664494],
          [100.5781732, 4.0664735],
          [100.5781387, 4.0665078],
          [100.5780905, 4.0665078],
          [100.578032, 4.0664975],
          [100.5779873, 4.0664975],
          [100.5779563, 4.0665318],
          [100.5779253, 4.066573],
          [100.5779115, 4.0666211],
        ],
      ],
      [
        [
          [100.582674865579804, 4.072109225046516],
          [100.5825793, 4.0721565],
          [100.5824943, 4.0721693],
          [100.5824943, 4.0722598],
          [100.5825567, 4.0723616],
          [100.5826474, 4.0723955],
          [100.5828005, 4.0724238],
          [100.5829025, 4.0724295],
          [100.5829706, 4.0723955],
          [100.5829706, 4.0723277],
          [100.582842, 4.0722333],
          [100.5827938, 4.0722424],
          [100.582683620922481, 4.072106313950206],
          [100.582674865579804, 4.072109225046516],
        ],
      ],
      [
        [
          [100.5412784, 4.0119414],
          [100.541213, 4.0123607],
          [100.5412597, 4.0126961],
          [100.5415212, 4.0132365],
          [100.54168, 4.013367],
          [100.5419976, 4.0135626],
          [100.5420629, 4.013721],
          [100.5420162, 4.0140192],
          [100.5419322, 4.0141216],
          [100.5418855, 4.0142987],
          [100.5419228, 4.0146248],
          [100.5422124, 4.0149415],
          [100.5422871, 4.0149881],
          [100.5421657, 4.0150999],
          [100.5422217, 4.0151558],
          [100.5424646, 4.0151093],
          [100.5427821, 4.0149043],
          [100.5430436, 4.0146248],
          [100.5432304, 4.0141403],
          [100.5433892, 4.0140657],
          [100.5434172, 4.0138887],
          [100.54352, 4.0137303],
          [100.5436694, 4.0136465],
          [100.5441551, 4.0129197],
          [100.5444166, 4.0128452],
          [100.5443886, 4.0126775],
          [100.5444633, 4.0125098],
          [100.5446221, 4.0121744],
          [100.5449116, 4.011988],
          [100.5451825, 4.0117924],
          [100.5456402, 4.0113638],
          [100.5458923, 4.0109445],
          [100.545911, 4.0107302],
          [100.5458737, 4.0105625],
          [100.5458456, 4.0104787],
          [100.5458363, 4.0103016],
          [100.5458643, 4.0101339],
          [100.5460231, 4.0100687],
          [100.5460044, 4.0099289],
          [100.5458923, 4.0096774],
          [100.5457429, 4.0093606],
          [100.5453226, 4.0092302],
          [100.5449397, 4.0092395],
          [100.5446688, 4.0093233],
          [100.5444446, 4.0092581],
          [100.5440991, 4.0092674],
          [100.5438002, 4.0093047],
          [100.5435854, 4.0093979],
          [100.5433705, 4.0095097],
          [100.5432585, 4.009696],
          [100.5430623, 4.0097892],
          [100.5418668, 4.0101898],
          [100.541736, 4.0102271],
          [100.541652, 4.0103296],
          [100.541624, 4.0107768],
          [100.5415586, 4.0110563],
          [100.5414278, 4.0111868],
          [100.5413998, 4.0114756],
          [100.5412784, 4.0119414],
        ],
      ],
      [
        [
          [100.3614169, 5.0894155],
          [100.3612948, 5.0895529],
          [100.361394, 5.0897163],
          [100.3614006, 5.089967],
          [100.3614221, 5.0903128],
          [100.3617481, 5.0909925],
          [100.3617298, 5.0922525],
          [100.3619444, 5.0930579],
          [100.3620994, 5.0936114],
          [100.3622008, 5.0939063],
          [100.3624515, 5.0941273],
          [100.3622482, 5.0947295],
          [100.3624058, 5.094966],
          [100.3624846, 5.0952637],
          [100.3625221, 5.0955966],
          [100.362711, 5.0957731],
          [100.3627636, 5.096002],
          [100.3628941, 5.0963234],
          [100.3629175, 5.0965604],
          [100.3630808, 5.0969052],
          [100.3632719, 5.0973993],
          [100.3633793, 5.0978219],
          [100.3635538, 5.0982919],
          [100.3638244, 5.0986935],
          [100.3636311, 5.0991862],
          [100.3638369, 5.0995574],
          [100.3640705, 5.0998881],
          [100.3643746, 5.1001889],
          [100.364545, 5.1004239],
          [100.364643, 5.1006839],
          [100.3647119, 5.1009471],
          [100.3650002, 5.1010087],
          [100.3651584, 5.1012191],
          [100.3652239, 5.1015453],
          [100.3653716, 5.1016332],
          [100.3656241, 5.1019352],
          [100.3660769, 5.1024888],
          [100.366715, 5.1032063],
          [100.3675075, 5.1038009],
          [100.3680838, 5.1045287],
          [100.3684338, 5.1047952],
          [100.3697995, 5.1047058],
          [100.3701067, 5.1042376],
          [100.3702688, 5.1039904],
          [100.3710369, 5.1048191],
          [100.3726155, 5.1057824],
          [100.3729557, 5.1061905],
          [100.3735298, 5.1063726],
          [100.3741121, 5.1066811],
          [100.37456, 5.1073135],
          [100.3757367, 5.1082037],
          [100.3762878, 5.1085746],
          [100.3770824, 5.1089025],
          [100.3776935, 5.1093123],
          [100.378377, 5.1096837],
          [100.3795856, 5.1100784],
          [100.3806425, 5.1106441],
          [100.3805866, 5.1113483],
          [100.3809311, 5.1121487],
          [100.3815375, 5.1127717],
          [100.382089, 5.1133121],
          [100.3826552, 5.1138096],
          [100.3832056, 5.1142712],
          [100.3836828, 5.1148856],
          [100.3844073, 5.1156141],
          [100.3846494, 5.116018],
          [100.3849454, 5.1163431],
          [100.3853202, 5.1165379],
          [100.3851806, 5.1168019],
          [100.3854205, 5.117359],
          [100.3857064, 5.1173528],
          [100.3858301, 5.117554],
          [100.3859429, 5.117862],
          [100.3856591, 5.1182478],
          [100.3855533, 5.1186752],
          [100.3857317, 5.1192939],
          [100.385909, 5.1199257],
          [100.386165, 5.1203868],
          [100.3863299, 5.1207411],
          [100.3864762, 5.1211411],
          [100.3865483, 5.1214368],
          [100.3866911, 5.1217609],
          [100.3868211, 5.1221087],
          [100.386865, 5.1222888],
          [100.3870172, 5.122394],
          [100.3871709, 5.1228274],
          [100.3872975, 5.1232946],
          [100.3874832, 5.1237062],
          [100.3875035, 5.1237512],
          [100.3875797, 5.1245324],
          [100.387842, 5.1254803],
          [100.387911, 5.1261621],
          [100.3883206, 5.1266922],
          [100.3885988, 5.1277014],
          [100.3888445, 5.1282265],
          [100.3888626, 5.1284809],
          [100.3888569, 5.1285836],
          [100.3895697, 5.1285059],
          [100.3903073, 5.128725],
          [100.3917503, 5.1289547],
          [100.3921661, 5.12892],
          [100.3924396, 5.1288025],
          [100.3929493, 5.1282815],
          [100.3935045, 5.1278701],
          [100.3938317, 5.1277686],
          [100.3941643, 5.1278113],
          [100.3944352, 5.1277659],
          [100.3948375, 5.1273465],
          [100.3951674, 5.127074],
          [100.3955564, 5.1270046],
          [100.3972006, 5.1268229],
          [100.3990888, 5.1264569],
          [100.3994509, 5.1262031],
          [100.4021358, 5.1236465],
          [100.4023611, 5.1234996],
          [100.4024523, 5.1234488],
          [100.4029137, 5.123195],
          [100.4034367, 5.1229573],
          [100.4062584, 5.1221531],
          [100.4066098, 5.1220035],
          [100.406717, 5.1220196],
          [100.4073876, 5.1221638],
          [100.4097721, 5.1224336],
          [100.4112419, 5.122642],
          [100.4183283, 5.1234862],
          [100.4192054, 5.1235797],
          [100.4217615, 5.1239083],
          [100.4227169, 5.124027],
          [100.4263213, 5.1244747],
          [100.4308381, 5.1250197],
          [100.4332468, 5.1253215],
          [100.4390447, 5.1260219],
          [100.4417958, 5.1263531],
          [100.4469636, 5.1269752],
          [100.4509574, 5.1274961],
          [100.4554936, 5.1280539],
          [100.4579345, 5.1283723],
          [100.4719856, 5.1302218],
          [100.475102, 5.130652],
          [100.4921998, 5.1328951],
          [100.4921661, 5.1325328],
          [100.4921839, 5.131957],
          [100.4922731, 5.1315072],
          [100.4924516, 5.1310573],
          [100.4926301, 5.1307334],
          [100.4935763, 5.1299775],
          [100.4945225, 5.1292576],
          [100.4954865, 5.1284117],
          [100.4959149, 5.1278898],
          [100.4966111, 5.127008],
          [100.4970039, 5.1264321],
          [100.497343, 5.1256404],
          [100.4975226, 5.1248076],
          [100.4975225, 5.1242319],
          [100.4971297, 5.1225587],
          [100.4969868, 5.1214433],
          [100.4970225, 5.1209755],
          [100.4971474, 5.1206336],
          [100.4974698, 5.1196389],
          [100.4979696, 5.1189191],
          [100.4986836, 5.1181992],
          [100.4993263, 5.1176952],
          [100.5002368, 5.1174431],
          [100.5009509, 5.1173889],
          [100.50154, 5.117408],
          [100.502162, 5.1176007],
          [100.5029037, 5.1180345],
          [100.5033104, 5.1185889],
          [100.5034301, 5.1189264],
          [100.5035498, 5.1198667],
          [100.5035498, 5.12059],
          [100.503502, 5.1212893],
          [100.5036696, 5.1221813],
          [100.5038371, 5.1230493],
          [100.5041003, 5.123869],
          [100.5044353, 5.1243511],
          [100.5050813, 5.1251466],
          [100.5056316, 5.1255081],
          [100.5060622, 5.1257732],
          [100.5066603, 5.12599],
          [100.5073781, 5.1261586],
          [100.5079044, 5.1262066],
          [100.5084786, 5.12611],
          [100.5093637, 5.1256999],
          [100.5097226, 5.1254828],
          [100.5102728, 5.1249039],
          [100.5107512, 5.1242528],
          [100.5115631, 5.1232226],
          [100.5124243, 5.1222579],
          [100.5130702, 5.1216308],
          [100.5139075, 5.1209555],
          [100.5149122, 5.1201113],
          [100.5159409, 5.1193876],
          [100.5169217, 5.1185435],
          [100.5177589, 5.1176752],
          [100.5180206, 5.1172961],
          [100.5186186, 5.1161868],
          [100.5188338, 5.1155117],
          [100.5188338, 5.1147883],
          [100.5186902, 5.1141374],
          [100.5183551, 5.1132454],
          [100.5179962, 5.1127874],
          [100.5173263, 5.1121366],
          [100.516776, 5.1118233],
          [100.5161779, 5.1116547],
          [100.5156277, 5.1116308],
          [100.5145033, 5.1116552],
          [100.5137138, 5.1115349],
          [100.5132353, 5.1115351],
          [100.5121587, 5.1112702],
          [100.5112975, 5.1110052],
          [100.5109147, 5.1106678],
          [100.5105558, 5.1101374],
          [100.5105318, 5.1096311],
          [100.5106274, 5.1084738],
          [100.5110817, 5.1063037],
          [100.5111963, 5.1054385],
          [100.5113468, 5.1048568],
          [100.5115976, 5.1038453],
          [100.511723, 5.1032131],
          [100.5118233, 5.102505],
          [100.5118734, 5.1017717],
          [100.5118232, 5.1007856],
          [100.5116976, 5.09985],
          [100.5115972, 5.0988892],
          [100.5114214, 5.0974226],
          [100.5114214, 5.0967146],
          [100.5115217, 5.0959307],
          [100.5119983, 5.0951467],
          [100.5123997, 5.0946155],
          [100.5128261, 5.0943372],
          [100.5132778, 5.094236],
          [100.5139803, 5.094261],
          [100.5151845, 5.0944377],
          [100.5163346, 5.0947153],
          [100.517293, 5.0949345],
          [100.518491, 5.0953513],
          [100.5198851, 5.0958777],
          [100.5206039, 5.0959653],
          [100.5210831, 5.096009],
          [100.5214752, 5.0959211],
          [100.5220197, 5.0957453],
          [100.5224335, 5.0954378],
          [100.5229344, 5.0949547],
          [100.5235442, 5.0941423],
          [100.524154, 5.0931762],
          [100.5246113, 5.0926053],
          [100.5253082, 5.0917709],
          [100.5264071, 5.0905641],
          [100.5276029, 5.0891303],
          [100.5286694, 5.0878431],
          [100.5289926, 5.0875987],
          [100.5294774, 5.0873867],
          [100.5300431, 5.0872237],
          [100.5305441, 5.0872072],
          [100.5314653, 5.0873046],
          [100.5322087, 5.0873695],
          [100.5328552, 5.0874996],
          [100.5337926, 5.0875482],
          [100.5344229, 5.0877109],
          [100.5350856, 5.0880038],
          [100.5356513, 5.0884109],
          [100.5360878, 5.0888668],
          [100.5363626, 5.089388],
          [100.5365243, 5.0901698],
          [100.5365729, 5.0909028],
          [100.5365244, 5.0913263],
          [100.5364922, 5.0917172],
          [100.5364114, 5.0922385],
          [100.5364276, 5.092548],
          [100.5364438, 5.0928086],
          [100.5364924, 5.0932158],
          [100.536751, 5.0937532],
          [100.5368749, 5.0939165],
          [100.5371941, 5.0942549],
          [100.5376141, 5.0946442],
          [100.5382525, 5.0950164],
          [100.5385885, 5.0951518],
          [100.53921, 5.0953716],
          [100.5397476, 5.0955238],
          [100.5405372, 5.0957437],
          [100.5410915, 5.0957604],
          [100.541629, 5.0957263],
          [100.5420994, 5.0955569],
          [100.5424689, 5.0953197],
          [100.5429392, 5.0950487],
          [100.5434095, 5.0945406],
          [100.5437118, 5.0940834],
          [100.5439973, 5.0935077],
          [100.5441316, 5.0929489],
          [100.5442827, 5.0922886],
          [100.5444338, 5.0917468],
          [100.5445009, 5.091188],
          [100.5445512, 5.0908325],
          [100.5445176, 5.0904262],
          [100.5444839, 5.0899691],
          [100.5443495, 5.0895967],
          [100.5441142, 5.0889873],
          [100.5435851, 5.0878097],
          [100.5436177, 5.0876288],
          [100.5437972, 5.0874477],
          [100.5443685, 5.087283],
          [100.5448093, 5.0871677],
          [100.5451358, 5.0872992],
          [100.5456255, 5.0876116],
          [100.5459846, 5.0877431],
          [100.5463111, 5.0878581],
          [100.546654, 5.088039],
          [100.5470947, 5.0883514],
          [100.5473723, 5.0886639],
          [100.5477314, 5.0887789],
          [100.5480579, 5.0889598],
          [100.5485477, 5.0893545],
          [100.5490538, 5.0897162],
          [100.5493966, 5.0897654],
          [100.5500332, 5.089831],
          [100.550376, 5.0898967],
          [100.5506209, 5.0900447],
          [100.5508005, 5.0903079],
          [100.5510944, 5.0906697],
          [100.5513556, 5.0908835],
          [100.5516168, 5.0909985],
          [100.5517964, 5.090982],
          [100.5520928, 5.0909057],
          [100.5526932, 5.0907525],
          [100.5530557, 5.0904621],
          [100.553413, 5.0896872],
          [100.5534867, 5.0892741],
          [100.5532312, 5.0884373],
          [100.552995, 5.0879838],
          [100.5527212, 5.0875972],
          [100.5521087, 5.0872542],
          [100.5518344, 5.0871912],
          [100.5516654, 5.0871806],
          [100.5509784, 5.0872341],
          [100.5504711, 5.0873088],
          [100.5502492, 5.0872663],
          [100.5500378, 5.0871705],
          [100.5499321, 5.0870534],
          [100.5496996, 5.0870322],
          [100.5495516, 5.0870535],
          [100.5490973, 5.0874052],
          [100.5489916, 5.0875011],
          [100.5488225, 5.0875864],
          [100.5486428, 5.0876503],
          [100.5483469, 5.0876611],
          [100.5478185, 5.0875015],
          [100.5472583, 5.0871289],
          [100.5467721, 5.0867456],
          [100.5465712, 5.0865114],
          [100.5463499, 5.0861037],
          [100.5460433, 5.085486],
          [100.545705, 5.0849642],
          [100.545409, 5.0843678],
          [100.5451976, 5.0838353],
          [100.5450178, 5.0832176],
          [100.5449823, 5.0828956],
          [100.5449988, 5.082742],
          [100.545091, 5.0824803],
          [100.5451525, 5.0821878],
          [100.5452293, 5.0818799],
          [100.545337, 5.0816182],
          [100.5455061, 5.0814026],
          [100.5457368, 5.0811871],
          [100.5460751, 5.0809869],
          [100.5466903, 5.0806789],
          [100.5468749, 5.0805095],
          [100.5471825, 5.0803709],
          [100.5474901, 5.0804016],
          [100.5477362, 5.0805247],
          [100.5478285, 5.0807556],
          [100.547967, 5.0810635],
          [100.5481978, 5.0813559],
          [100.548444, 5.0815868],
          [100.5485671, 5.0821255],
          [100.5487364, 5.0826643],
          [100.548798, 5.0829875],
          [100.5488833, 5.0832564],
          [100.549014, 5.0837828],
          [100.54921, 5.0844573],
          [100.549308, 5.0847698],
          [100.5497161, 5.085181],
          [100.5501406, 5.0855757],
          [100.5505324, 5.0857072],
          [100.5508886, 5.0858045],
          [100.5509346, 5.0857375],
          [100.5510874, 5.0855589],
          [100.5514954, 5.0852462],
          [100.5518382, 5.0851637],
          [100.5521176, 5.0852165],
          [100.5528028, 5.0857003],
          [100.5529784, 5.0857889],
          [100.5532649, 5.0859084],
          [100.553334, 5.0859221],
          [100.5537144, 5.085855],
          [100.5538485, 5.0855464],
          [100.5538449, 5.0852596],
          [100.5538195, 5.0851327],
          [100.5536365, 5.0845261],
          [100.5535943, 5.0844343],
          [100.5533328, 5.0840231],
          [100.5531292, 5.0836713],
          [100.552804, 5.0835234],
          [100.5526553, 5.0834357],
          [100.5524613, 5.0833558],
          [100.5523344, 5.0832687],
          [100.552127, 5.0830713],
          [100.5519203, 5.0829407],
          [100.5513819, 5.0825713],
          [100.5510743, 5.0824329],
          [100.5508589, 5.0822482],
          [100.5506128, 5.0820327],
          [100.5503666, 5.0818481],
          [100.5501359, 5.0817249],
          [100.5500128, 5.0816172],
          [100.5499205, 5.0813247],
          [100.5497974, 5.0810477],
          [100.549582, 5.0807706],
          [100.5492436, 5.0804782],
          [100.5489821, 5.0803397],
          [100.5487205, 5.0802166],
          [100.5486128, 5.0800165],
          [100.5485359, 5.0797702],
          [100.5484743, 5.0793084],
          [100.5485512, 5.0791083],
          [100.5486896, 5.0789081],
          [100.5488895, 5.0786464],
          [100.5491509, 5.0782923],
          [100.5493508, 5.0779844],
          [100.5495353, 5.077815],
          [100.5498429, 5.0775994],
          [100.5500121, 5.0773993],
          [100.5501043, 5.0771221],
          [100.5501965, 5.0767373],
          [100.5503349, 5.0764909],
          [100.5505194, 5.0763677],
          [100.5507501, 5.0763369],
          [100.5509963, 5.0763523],
          [100.5512885, 5.0764907],
          [100.5516115, 5.07666],
          [100.5518577, 5.0767677],
          [100.5521535, 5.0769799],
          [100.5522816, 5.0771203],
          [100.5523955, 5.0773529],
          [100.5524121, 5.0774413],
          [100.5523893, 5.0776425],
          [100.5523258, 5.0777395],
          [100.5521499, 5.0778145],
          [100.5517656, 5.0779838],
          [100.5515811, 5.0781378],
          [100.5515503, 5.0782918],
          [100.5515196, 5.0784611],
          [100.5515196, 5.0785689],
          [100.5515812, 5.0787536],
          [100.5517043, 5.0789075],
          [100.5517658, 5.0791076],
          [100.5518274, 5.0792769],
          [100.5518428, 5.0794924],
          [100.5517967, 5.0797234],
          [100.5517045, 5.0800467],
          [100.5516737, 5.080216],
          [100.5516737, 5.0802776],
          [100.5517661, 5.0804007],
          [100.5518737, 5.0804469],
          [100.5519968, 5.0804314],
          [100.5521387, 5.0803746],
          [100.5523961, 5.080243],
          [100.5524798, 5.0801919],
          [100.5527538, 5.079925],
          [100.5530279, 5.0797022],
          [100.5531566, 5.0796416],
          [100.5532057, 5.0796254],
          [100.5533531, 5.0796049],
          [100.5534752, 5.0796041],
          [100.5536328, 5.0796138],
          [100.5540453, 5.079667],
          [100.5542196, 5.0796828],
          [100.5545597, 5.0796921],
          [100.5549258, 5.0797216],
          [100.5555393, 5.0798038],
          [100.5557036, 5.0798384],
          [100.5563218, 5.0798833],
          [100.5566568, 5.0799236],
          [100.5569589, 5.0799363],
          [100.557301, 5.079974],
          [100.5575168, 5.0800139],
          [100.5575812, 5.0800185],
          [100.557879, 5.0799999],
          [100.5579128, 5.0799924],
          [100.55813, 5.0799038],
          [100.5583671, 5.0797287],
          [100.5586065, 5.0795938],
          [100.5587788, 5.0795651],
          [100.5591756, 5.079691],
          [100.5593495, 5.0797895],
          [100.5597318, 5.0801344],
          [100.559886, 5.0802643],
          [100.56004, 5.0803326],
          [100.5602066, 5.080378],
          [100.5606778, 5.080576],
          [100.5607531, 5.0806134],
          [100.5609571, 5.0807551],
          [100.5611118, 5.0809428],
          [100.5613044, 5.0810696],
          [100.5614588, 5.0811042],
          [100.5615844, 5.0810999],
          [100.5616668, 5.0810421],
          [100.5617253, 5.080931],
          [100.5617405, 5.0807664],
          [100.5617791, 5.0805152],
          [100.5618633, 5.0801115],
          [100.5621431, 5.0795854],
          [100.5623498, 5.0794069],
          [100.562425, 5.0793634],
          [100.5625375, 5.07934],
          [100.562644, 5.0793558],
          [100.5628716, 5.0794687],
          [100.5629227, 5.0798728],
          [100.5628943, 5.0801859],
          [100.5628774, 5.0802834],
          [100.5627061, 5.0808771],
          [100.5626135, 5.0810656],
          [100.5626129, 5.0812061],
          [100.5626278, 5.0813729],
          [100.5629665, 5.0816448],
          [100.5632619, 5.081766],
          [100.5636067, 5.0818401],
          [100.5641546, 5.0818527],
          [100.5643443, 5.0818486],
          [100.5647198, 5.0818242],
          [100.5648901, 5.0818367],
          [100.5649242, 5.0818453],
          [100.5651118, 5.0819395],
          [100.5656396, 5.0821706],
          [100.5659106, 5.082341],
          [100.5661074, 5.0826221],
          [100.5661178, 5.0826912],
          [100.5661274, 5.0827956],
          [100.5661007, 5.0828651],
          [100.5660372, 5.0829805],
          [100.5658582, 5.083199],
          [100.5654498, 5.0834867],
          [100.5651836, 5.0837276],
          [100.5650989, 5.0839015],
          [100.5650452, 5.084093],
          [100.565026, 5.084664],
          [100.5649905, 5.0849169],
          [100.5649651, 5.0849931],
          [100.5648227, 5.085278],
          [100.5646974, 5.0856291],
          [100.5646622, 5.0857829],
          [100.5646595, 5.0858439],
          [100.5646813, 5.0860233],
          [100.5647949, 5.0863238],
          [100.5649971, 5.086607],
          [100.5652384, 5.0868584],
          [100.5653923, 5.0870995],
          [100.5655078, 5.0872284],
          [100.5656161, 5.0873686],
          [100.5658005, 5.087558],
          [100.5660314, 5.0877476],
          [100.5662513, 5.0879692],
          [100.5664231, 5.0881786],
          [100.5665668, 5.0883809],
          [100.5668224, 5.0886713],
          [100.5671932, 5.0888237],
          [100.5673214, 5.0888503],
          [100.5676977, 5.0888705],
          [100.5678412, 5.0888863],
          [100.5683461, 5.089059],
          [100.5683913, 5.0890847],
          [100.5685953, 5.0892576],
          [100.5688253, 5.0895659],
          [100.5689233, 5.0898041],
          [100.5689898, 5.0899442],
          [100.5690489, 5.0901214],
          [100.5691174, 5.0904203],
          [100.5691921, 5.0906932],
          [100.5692884, 5.0909471],
          [100.5693924, 5.0911372],
          [100.569504, 5.091391],
          [100.5695378, 5.0914516],
          [100.5696347, 5.0915812],
          [100.5697381, 5.0917366],
          [100.5699333, 5.0921012],
          [100.5704001, 5.0926142],
          [100.5710686, 5.0929606],
          [100.5715686, 5.0931204],
          [100.5718166, 5.0931808],
          [100.5720827, 5.0932179],
          [100.5725485, 5.0932436],
          [100.5728167, 5.0932742],
          [100.5734763, 5.0932613],
          [100.573691, 5.0935815],
          [100.5737152, 5.0937878],
          [100.5736164, 5.094043],
          [100.5734192, 5.094251],
          [100.5732314, 5.0945061],
          [100.5731681, 5.0946458],
          [100.5731504, 5.0947446],
          [100.5731559, 5.0948436],
          [100.5731779, 5.0949826],
          [100.5733231, 5.0950942],
          [100.573498, 5.0950658],
          [100.573623, 5.0950024],
          [100.5737196, 5.0949432],
          [100.5741089, 5.0946331],
          [100.5744181, 5.0945433],
          [100.5746585, 5.0944898],
          [100.57478, 5.0944707],
          [100.5750602, 5.0944507],
          [100.5757614, 5.0944428],
          [100.5761418, 5.0944042],
          [100.5764114, 5.0944083],
          [100.5765806, 5.0944819],
          [100.5769281, 5.0946756],
          [100.5769936, 5.0948275],
          [100.5770239, 5.0949928],
          [100.5770245, 5.0952115],
          [100.5770149, 5.0952957],
          [100.5769354, 5.0955671],
          [100.5767793, 5.0957435],
          [100.5765901, 5.0959315],
          [100.5764429, 5.096233],
          [100.5764375, 5.0963982],
          [100.5764889, 5.0964881],
          [100.5765687, 5.0965356],
          [100.5766723, 5.0965314],
          [100.5768042, 5.0965178],
          [100.5769221, 5.0964569],
          [100.5771772, 5.0962692],
          [100.5773471, 5.0961661],
          [100.5774744, 5.0961383],
          [100.5775968, 5.0961388],
          [100.5776766, 5.0961911],
          [100.5778071, 5.0965849],
          [100.5778129, 5.0966629],
          [100.5778542, 5.0968273],
          [100.577982, 5.0970417],
          [100.5783105, 5.0971126],
          [100.5786533, 5.0971435],
          [100.578974, 5.0971887],
          [100.5790715, 5.0972225],
          [100.5793695, 5.0973858],
          [100.5795065, 5.0975249],
          [100.5795884, 5.0976235],
          [100.5795966, 5.0976949],
          [100.5796699, 5.0978608],
          [100.5797748, 5.0979889],
          [100.5799481, 5.0981142],
          [100.5800951, 5.0981528],
          [100.5802355, 5.0981601],
          [100.5804094, 5.0981538],
          [100.5809451, 5.0979234],
          [100.5813064, 5.097951],
          [100.5818373, 5.0979244],
          [100.5819552, 5.0979319],
          [100.5821379, 5.0979641],
          [100.5823588, 5.0980637],
          [100.5826097, 5.0981232],
          [100.58267, 5.0981083],
          [100.5828891, 5.0979942],
          [100.583018, 5.0974375],
          [100.5831345, 5.0971635],
          [100.5835212, 5.097011],
          [100.5837799, 5.096942],
          [100.5839978, 5.0968951],
          [100.584205, 5.0968345],
          [100.5843379, 5.0967693],
          [100.584737, 5.096444],
          [100.5848971, 5.0963654],
          [100.5849436, 5.0963553],
          [100.5851651, 5.0963356],
          [100.5853782, 5.0963908],
          [100.5856121, 5.0965044],
          [100.5857487, 5.0968131],
          [100.585756, 5.0968462],
          [100.5857705, 5.0971443],
          [100.5856261, 5.0975185],
          [100.585603, 5.0975598],
          [100.585177, 5.0981181],
          [100.5849709, 5.0985137],
          [100.5849306, 5.0987351],
          [100.5849035, 5.0989407],
          [100.5849033, 5.0993528],
          [100.5848661, 5.099633],
          [100.5848434, 5.0998756],
          [100.5847306, 5.1002094],
          [100.5846324, 5.1003995],
          [100.5845137, 5.1005681],
          [100.584342, 5.1008858],
          [100.5842778, 5.1011262],
          [100.5842606, 5.1012398],
          [100.5841816, 5.1014454],
          [100.5840717, 5.1016514],
          [100.5840036, 5.1017915],
          [100.5839739, 5.1018386],
          [100.5835065, 5.1020942],
          [100.5833324, 5.1021572],
          [100.583241, 5.1022732],
          [100.5832355, 5.1024045],
          [100.5832513, 5.1024724],
          [100.5833071, 5.1025848],
          [100.5833806, 5.1026656],
          [100.5834628, 5.1027464],
          [100.5835105, 5.1028274],
          [100.5835325, 5.1029198],
          [100.5835327, 5.1030716],
          [100.5835137, 5.1031623],
          [100.5834924, 5.1032256],
          [100.5834061, 5.1033992],
          [100.5832388, 5.1035042],
          [100.582668, 5.1034929],
          [100.5825534, 5.1035805],
          [100.5825086, 5.1036971],
          [100.5825232, 5.1037785],
          [100.5825919, 5.1039497],
          [100.5826668, 5.1042027],
          [100.5826678, 5.104251],
          [100.5826332, 5.1044867],
          [100.5825136, 5.1047552],
          [100.5824184, 5.1049052],
          [100.5822277, 5.1051456],
          [100.5820332, 5.1053574],
          [100.5819735, 5.1054698],
          [100.5819486, 5.1055451],
          [100.5819535, 5.1055975],
          [100.5819792, 5.1057628],
          [100.5821008, 5.1058776],
          [100.582288, 5.1058825],
          [100.5827853, 5.1055923],
          [100.5829776, 5.1055638],
          [100.5832677, 5.105597],
          [100.5836197, 5.1056721],
          [100.5840265, 5.1057105],
          [100.5842466, 5.1057145],
          [100.5848953, 5.1056675],
          [100.585134, 5.1056572],
          [100.5853393, 5.1056822],
          [100.5854671, 5.1057062],
          [100.5855065, 5.1057211],
          [100.5857355, 5.1058948],
          [100.5857651, 5.105935],
          [100.5858252, 5.1060811],
          [100.5858445, 5.1061748],
          [100.5858862, 5.1065431],
          [100.5859093, 5.1066421],
          [100.5859916, 5.1068818],
          [100.5862345, 5.1070541],
          [100.5863872, 5.1071381],
          [100.5865039, 5.1071652],
          [100.5866047, 5.1071694],
          [100.58671, 5.1071453],
          [100.5867931, 5.1071042],
          [100.5869542, 5.1069701],
          [100.5870825, 5.1067829],
          [100.5871731, 5.1066989],
          [100.5873119, 5.106597],
          [100.5874625, 5.1065433],
          [100.587619, 5.1065139],
          [100.5877693, 5.1065386],
          [100.5879855, 5.1066119],
          [100.5880692, 5.106745],
          [100.5881709, 5.1070008],
          [100.5881763, 5.1072313],
          [100.5880901, 5.1074554],
          [100.5880056, 5.1075917],
          [100.5877746, 5.1079122],
          [100.5877333, 5.1079908],
          [100.5876625, 5.1081451],
          [100.5875742, 5.108432],
          [100.5874993, 5.1086095],
          [100.5874466, 5.108715],
          [100.5869826, 5.1091248],
          [100.5869706, 5.1093085],
          [100.5871458, 5.109448],
          [100.5872993, 5.1094656],
          [100.5874002, 5.1094885],
          [100.5874968, 5.1095236],
          [100.5876784, 5.109579],
          [100.5879186, 5.1097018],
          [100.5880826, 5.1098417],
          [100.5882587, 5.110009],
          [100.5883648, 5.1100574],
          [100.588493, 5.1101843],
          [100.5885461, 5.1102654],
          [100.5886343, 5.1104553],
          [100.5886833, 5.1106039],
          [100.5887113, 5.1111132],
          [100.588765, 5.1113624],
          [100.5887843, 5.1114234],
          [100.5889223, 5.1116469],
          [100.5896923, 5.1120779],
          [100.5898119, 5.112301],
          [100.5898783, 5.1124735],
          [100.5899029, 5.112604],
          [100.5899019, 5.1126657],
          [100.5898647, 5.1129143],
          [100.5898205, 5.113012],
          [100.5896111, 5.1133328],
          [100.5895435, 5.1135152],
          [100.5895389, 5.1135551],
          [100.5895594, 5.1136852],
          [100.5896507, 5.1139219],
          [100.589896, 5.1139663],
          [100.5901619, 5.1138904],
          [100.5903791, 5.1137875],
          [100.5905837, 5.1136791],
          [100.5907257, 5.1136581],
          [100.5907966, 5.1136553],
          [100.5909351, 5.1136858],
          [100.5910127, 5.1137194],
          [100.5912107, 5.1137706],
          [100.5912977, 5.1137812],
          [100.5916835, 5.1138925],
          [100.591833, 5.1142044],
          [100.5919201, 5.1144195],
          [100.5919415, 5.114523],
          [100.5919208, 5.1147356],
          [100.591875, 5.1148692],
          [100.591747, 5.1150035],
          [100.5915144, 5.1153181],
          [100.5914552, 5.1154221],
          [100.5914158, 5.1155218],
          [100.5913966, 5.1155926],
          [100.5913709, 5.1157832],
          [100.5914099, 5.1160146],
          [100.5914481, 5.1161419],
          [100.591467, 5.1162361],
          [100.5914786, 5.116524],
          [100.5914154, 5.1167625],
          [100.5910836, 5.1170279],
          [100.5906416, 5.1172639],
          [100.5906045, 5.1173244],
          [100.5905607, 5.1175959],
          [100.5907153, 5.1180895],
          [100.5907348, 5.1183757],
          [100.5907726, 5.1186079],
          [100.5907952, 5.1188163],
          [100.590837, 5.1189314],
          [100.5909014, 5.1190417],
          [100.5910356, 5.1192276],
          [100.5911132, 5.1195274],
          [100.5910924, 5.119625],
          [100.5909979, 5.1197939],
          [100.5907873, 5.1198625],
          [100.5907225, 5.1198536],
          [100.5904534, 5.1197622],
          [100.5902271, 5.1196705],
          [100.5900479, 5.1195778],
          [100.5899004, 5.1195568],
          [100.5895925, 5.1196434],
          [100.5894215, 5.1199673],
          [100.5893664, 5.1200969],
          [100.5893591, 5.1201894],
          [100.5893575, 5.1204191],
          [100.5894059, 5.1207537],
          [100.5894404, 5.1209126],
          [100.5895582, 5.1211527],
          [100.589598, 5.1212498],
          [100.5898621, 5.1217457],
          [100.5901094, 5.122003],
          [100.5902335, 5.1220391],
          [100.5903152, 5.1220217],
          [100.5902555, 5.1218684],
          [100.5901567, 5.121747],
          [100.5900509, 5.1215935],
          [100.5901689, 5.121384],
          [100.5904148, 5.1213128],
          [100.5905281, 5.1213311],
          [100.5907573, 5.121455],
          [100.5910865, 5.1216066],
          [100.5911459, 5.121625],
          [100.5913813, 5.121659],
          [100.5914595, 5.1216591],
          [100.5917348, 5.1216187],
          [100.5920291, 5.1214839],
          [100.59209, 5.1214683],
          [100.5922354, 5.1214781],
          [100.5924647, 5.1215989],
          [100.5924994, 5.1218352],
          [100.5924583, 5.1219686],
          [100.5923946, 5.1220695],
          [100.5922095, 5.1221544],
          [100.5920611, 5.1222676],
          [100.5917401, 5.1224718],
          [100.5916583, 5.1226802],
          [100.5916348, 5.1227644],
          [100.591639, 5.1228158],
          [100.5917314, 5.1230387],
          [100.5919993, 5.1231677],
          [100.5921188, 5.1231889],
          [100.5922384, 5.1232228],
          [100.5924761, 5.1232425],
          [100.592537, 5.1232365],
          [100.5927468, 5.1231866],
          [100.5933594, 5.1230232],
          [100.5934916, 5.12301],
          [100.5936359, 5.1230175],
          [100.5938009, 5.1230542],
          [100.5938651, 5.123176],
          [100.5938267, 5.1233499],
          [100.5936484, 5.1234762],
          [100.5935671, 5.1235248],
          [100.5933892, 5.1236035],
          [100.5932966, 5.1236367],
          [100.5931603, 5.123709],
          [100.5930516, 5.12379],
          [100.5929033, 5.123923],
          [100.5928572, 5.1239884],
          [100.5928169, 5.1240953],
          [100.592795, 5.1242475],
          [100.5928094, 5.1243954],
          [100.5928409, 5.1244793],
          [100.5928968, 5.1245722],
          [100.5930822, 5.1248206],
          [100.5933572, 5.1249291],
          [100.5935115, 5.1249508],
          [100.5935959, 5.1249215],
          [100.5936845, 5.12488],
          [100.5937533, 5.1248286],
          [100.593956, 5.1246517],
          [100.5940249, 5.1245404],
          [100.5941575, 5.1243898],
          [100.5943462, 5.1243226],
          [100.5945234, 5.1242932],
          [100.594689, 5.124343],
          [100.5947601, 5.1244566],
          [100.5947931, 5.1246646],
          [100.5947903, 5.1249155],
          [100.594793, 5.1249528],
          [100.5947323, 5.1253304],
          [100.5947453, 5.1256009],
          [100.5948026, 5.1257816],
          [100.5948436, 5.1258675],
          [100.5950218, 5.1260617],
          [100.5952388, 5.126256],
          [100.5953786, 5.1264724],
          [100.5956544, 5.1271744],
          [100.5958163, 5.1272417],
          [100.5958926, 5.1272518],
          [100.5961914, 5.1271884],
          [100.5963881, 5.1267647],
          [100.5965716, 5.126201],
          [100.5966623, 5.1258553],
          [100.5966547, 5.125469],
          [100.5966448, 5.1253771],
          [100.5965728, 5.1251368],
          [100.5965075, 5.1250062],
          [100.5964224, 5.1248759],
          [100.5963758, 5.1247213],
          [100.5963483, 5.1246157],
          [100.5963439, 5.1245165],
          [100.5963923, 5.1243803],
          [100.5965444, 5.1243493],
          [100.5969015, 5.1245189],
          [100.5970302, 5.1247182],
          [100.5971779, 5.1249203],
          [100.5973775, 5.1251204],
          [100.5976048, 5.1253207],
          [100.5977613, 5.125504],
          [100.5977812, 5.1255463],
          [100.5978308, 5.1257991],
          [100.5978224, 5.1259431],
          [100.5977798, 5.1260126],
          [100.597643, 5.1261392],
          [100.5975393, 5.1262659],
          [100.5974871, 5.1264003],
          [100.5974838, 5.1264376],
          [100.5975412, 5.1267345],
          [100.5977164, 5.1267709],
          [100.5977795, 5.1267639],
          [100.5979376, 5.126713],
          [100.5980826, 5.1266252],
          [100.5986258, 5.1264074],
          [100.599007, 5.1263971],
          [100.5992179, 5.1263547],
          [100.5992732, 5.1263254],
          [100.5993758, 5.1262488],
          [100.5998532, 5.1259395],
          [100.6001035, 5.1258211],
          [100.6003014, 5.1257936],
          [100.6004973, 5.1257927],
          [100.6006845, 5.1257697],
          [100.6010013, 5.1257026],
          [100.6012231, 5.1256218],
          [100.6014342, 5.1255251],
          [100.6015417, 5.1254836],
          [100.6017111, 5.1254513],
          [100.6019115, 5.125433],
          [100.6020013, 5.1254519],
          [100.6021201, 5.1254918],
          [100.6021737, 5.125562],
          [100.602227, 5.1256625],
          [100.6023991, 5.1257884],
          [100.6026919, 5.1259719],
          [100.602764, 5.1260308],
          [100.6028698, 5.1260887],
          [100.603018, 5.1261855],
          [100.6031798, 5.1263848],
          [100.6032163, 5.1264418],
          [100.603343, 5.1268139],
          [100.6033508, 5.1268892],
          [100.6033555, 5.1273201],
          [100.6033502, 5.1273767],
          [100.6032761, 5.1277334],
          [100.6032613, 5.1277786],
          [100.6031172, 5.1280755],
          [100.6028668, 5.1284656],
          [100.6027768, 5.1286481],
          [100.6027461, 5.1287802],
          [100.602875, 5.1290694],
          [100.6032201, 5.1290344],
          [100.6034884, 5.1289218],
          [100.6035308, 5.1288996],
          [100.604037, 5.1285237],
          [100.6041896, 5.1282937],
          [100.604247, 5.1281785],
          [100.6042659, 5.1278322],
          [100.6042463, 5.1272956],
          [100.6042534, 5.1271872],
          [100.6043088, 5.1270433],
          [100.6044055, 5.1268694],
          [100.6046221, 5.1268342],
          [100.604874, 5.126992],
          [100.6050895, 5.1272361],
          [100.6051893, 5.1274003],
          [100.6052101, 5.1276126],
          [100.605146, 5.1277804],
          [100.6048765, 5.1283222],
          [100.6047754, 5.1286068],
          [100.6047484, 5.1287586],
          [100.6047426, 5.1289939],
          [100.6047736, 5.1291625],
          [100.6048065, 5.1292491],
          [100.604969, 5.1294738],
          [100.6052525, 5.1298439],
          [100.605539, 5.1302505],
          [100.6056987, 5.1304434],
          [100.6057222, 5.1304904],
          [100.6057567, 5.1306123],
          [100.6057404, 5.1308045],
          [100.6053594, 5.1308998],
          [100.6048942, 5.1309086],
          [100.6046251, 5.1309636],
          [100.6045395, 5.1311084],
          [100.6044994, 5.131209],
          [100.6044674, 5.1313432],
          [100.6044678, 5.1313763],
          [100.6045196, 5.131612],
          [100.6047639, 5.1319917],
          [100.6052146, 5.1322269],
          [100.6055956, 5.1323697],
          [100.6058107, 5.1324247],
          [100.6059116, 5.1324369],
          [100.6060981, 5.1324897],
          [100.6062831, 5.1325047],
          [100.6069037, 5.1324939],
          [100.6071353, 5.1325316],
          [100.6073639, 5.1326193],
          [100.6074626, 5.1329625],
          [100.6074177, 5.1332034],
          [100.6071915, 5.1334084],
          [100.6071101, 5.1334698],
          [100.6067124, 5.1336768],
          [100.6065788, 5.1339249],
          [100.6065679, 5.1339647],
          [100.6065553, 5.1342134],
          [100.6066959, 5.1344995],
          [100.6069109, 5.1346159],
          [100.6070777, 5.1346289],
          [100.6071951, 5.1346127],
          [100.6074126, 5.1345159],
          [100.6077049, 5.1341533],
          [100.6079759, 5.1337784],
          [100.6082599, 5.1334236],
          [100.6090422, 5.1329457],
          [100.6093068, 5.1328523],
          [100.6098546, 5.132722],
          [100.6101976, 5.132675],
          [100.6105066, 5.1326631],
          [100.6106275, 5.132665],
          [100.6108622, 5.132702],
          [100.6109984, 5.1327378],
          [100.6111587, 5.1328131],
          [100.611381, 5.1329657],
          [100.611556, 5.1331743],
          [100.6117312, 5.1333558],
          [100.6118893, 5.1334442],
          [100.6119317, 5.1334612],
          [100.6120392, 5.1334757],
          [100.6121274, 5.1334783],
          [100.6123795, 5.1334411],
          [100.6125238, 5.1333784],
          [100.6127464, 5.1333431],
          [100.6129644, 5.1333843],
          [100.6131147, 5.1334348],
          [100.6134153, 5.1336822],
          [100.6137158, 5.1339497],
          [100.613841, 5.1339989],
          [100.6138924, 5.1340106],
          [100.6140272, 5.1339886],
          [100.6140979, 5.1339635],
          [100.6142476, 5.133872],
          [100.6144379, 5.1336939],
          [100.6147015, 5.1334642],
          [100.6148313, 5.1333607],
          [100.6148921, 5.1333308],
          [100.6150811, 5.1333128],
          [100.6151345, 5.1333144],
          [100.6154104, 5.1333922],
          [100.6156987, 5.1337514],
          [100.6158983, 5.1339128],
          [100.616067, 5.1339499],
          [100.6160996, 5.1339475],
          [100.6163197, 5.1338669],
          [100.6163609, 5.133843],
          [100.6165517, 5.1336913],
          [100.616757, 5.1335074],
          [100.6168179, 5.1334687],
          [100.6170066, 5.1333936],
          [100.6173758, 5.1332964],
          [100.6177577, 5.1332279],
          [100.6179321, 5.133217],
          [100.6192443, 5.1332959],
          [100.6193538, 5.133476],
          [100.6194001, 5.133631],
          [100.6193835, 5.1336939],
          [100.6192909, 5.1338425],
          [100.6189624, 5.1340962],
          [100.6186095, 5.13435],
          [100.6185809, 5.1343908],
          [100.6184338, 5.1347029],
          [100.6184047, 5.1349738],
          [100.61841, 5.1350286],
          [100.6185172, 5.1352707],
          [100.6188792, 5.1358623],
          [100.6189898, 5.1359649],
          [100.61906, 5.1360976],
          [100.6191292, 5.1363746],
          [100.6191095, 5.1366008],
          [100.6190983, 5.1366419],
          [100.6189935, 5.1368678],
          [100.618889, 5.1370756],
          [100.6186337, 5.1374179],
          [100.6186103, 5.1374749],
          [100.618642, 5.1377555],
          [100.6187706, 5.1378639],
          [100.6188814, 5.1378847],
          [100.6189574, 5.1379083],
          [100.6190661, 5.1379053],
          [100.6191279, 5.1378939],
          [100.6192533, 5.1378446],
          [100.6198254, 5.1375065],
          [100.6200174, 5.1374927],
          [100.6201312, 5.137502],
          [100.6202451, 5.1375215],
          [100.6204133, 5.1375785],
          [100.6207106, 5.1377662],
          [100.6208827, 5.1381005],
          [100.6209224, 5.1381615],
          [100.6210585, 5.1383293],
          [100.6213238, 5.1385224],
          [100.6216127, 5.1386133],
          [100.6219041, 5.1385431],
          [100.6221362, 5.1383497],
          [100.6221895, 5.138281],
          [100.6223001, 5.1381089],
          [100.6225963, 5.1377912],
          [100.6228091, 5.1377903],
          [100.623129, 5.1380081],
          [100.6231551, 5.1380688],
          [100.6231878, 5.1382071],
          [100.6231992, 5.1382838],
          [100.6232014, 5.1383939],
          [100.6231495, 5.1386641],
          [100.6230341, 5.1391301],
          [100.6230063, 5.1392095],
          [100.6228484, 5.1395085],
          [100.6228206, 5.139552],
          [100.6225709, 5.1398506],
          [100.6223104, 5.1401805],
          [100.6220779, 5.14044],
          [100.6220326, 5.1405982],
          [100.6220299, 5.1407559],
          [100.6220418, 5.1408374],
          [100.6220922, 5.1410062],
          [100.622175, 5.1412105],
          [100.6222553, 5.1413395],
          [100.6224468, 5.1415486],
          [100.6227783, 5.1416956],
          [100.6229435, 5.1417361],
          [100.6230843, 5.1417374],
          [100.6231979, 5.1417248],
          [100.6233895, 5.1416698],
          [100.62351, 5.141611],
          [100.6236107, 5.1415458],
          [100.6238255, 5.1413773],
          [100.6239935, 5.1411998],
          [100.6243568, 5.1406847],
          [100.6244332, 5.1406182],
          [100.624554, 5.1405615],
          [100.6246257, 5.1405215],
          [100.6246558, 5.1405047],
          [100.6247826, 5.1405116],
          [100.6249251, 5.1405631],
          [100.6249659, 5.140665],
          [100.6250067, 5.1407829],
          [100.625114, 5.140901],
          [100.6252281, 5.1410492],
          [100.6252804, 5.1411459],
          [100.6253956, 5.1413214],
          [100.6254903, 5.1415049],
          [100.6255174, 5.1416017],
          [100.6255604, 5.1418845],
          [100.6255706, 5.1420351],
          [100.6255629, 5.1422503],
          [100.6255533, 5.142297],
          [100.6254736, 5.1425077],
          [100.6252818, 5.1428511],
          [100.6245562, 5.143191],
          [100.6244858, 5.1432728],
          [100.6244018, 5.1434287],
          [100.6243928, 5.1435792],
          [100.6244247, 5.1438554],
          [100.6244391, 5.1439157],
          [100.624526, 5.144139],
          [100.6250815, 5.1445631],
          [100.625299, 5.1448823],
          [100.6254611, 5.1452518],
          [100.6255841, 5.1456032],
          [100.6256069, 5.1456897],
          [100.6256278, 5.1458642],
          [100.6256344, 5.1460815],
          [100.62562, 5.1463134],
          [100.6255896, 5.1464506],
          [100.6255392, 5.1466145],
          [100.6253779, 5.1470274],
          [100.6253625, 5.147087],
          [100.6253644, 5.1471485],
          [100.6253887, 5.14727],
          [100.6254853, 5.1473481],
          [100.6256665, 5.1473036],
          [100.6259234, 5.1471125],
          [100.6262794, 5.1469154],
          [100.6263971, 5.1468628],
          [100.6264329, 5.1468562],
          [100.6265394, 5.1468579],
          [100.6267178, 5.1469104],
          [100.626791, 5.1473533],
          [100.6268394, 5.1477518],
          [100.626838, 5.1481654],
          [100.6268146, 5.1483246],
          [100.6267863, 5.1484323],
          [100.6266604, 5.148676],
          [100.6265851, 5.1488109],
          [100.6264804, 5.1489244],
          [100.6263354, 5.1489704],
          [100.6261701, 5.1489179],
          [100.6260462, 5.1488086],
          [100.6259261, 5.1486674],
          [100.6256927, 5.1484584],
          [100.6255708, 5.1484188],
          [100.6253133, 5.1484662],
          [100.6252119, 5.1486047],
          [100.6251514, 5.1490002],
          [100.6251494, 5.1491013],
          [100.6251784, 5.149251],
          [100.6252236, 5.1493966],
          [100.625312, 5.1496336],
          [100.6253612, 5.1498875],
          [100.6253601, 5.1501602],
          [100.6253189, 5.1504174],
          [100.6252739, 5.1505911],
          [100.6252915, 5.1507643],
          [100.6253877, 5.1509026],
          [100.625458, 5.1510553],
          [100.6255019, 5.151237],
          [100.6256066, 5.1515174],
          [100.6257655, 5.1515082],
          [100.6259131, 5.1514144],
          [100.6262039, 5.1510898],
          [100.6264773, 5.1507379],
          [100.6267779, 5.1505035],
          [100.6268914, 5.150451],
          [100.626946, 5.1504364],
          [100.6270716, 5.1504595],
          [100.627182, 5.1506041],
          [100.6272716, 5.1509691],
          [100.6272598, 5.1511015],
          [100.6272071, 5.1513208],
          [100.627172, 5.1514502],
          [100.6270694, 5.1516788],
          [100.6270106, 5.1519084],
          [100.6269694, 5.152237],
          [100.6269736, 5.1523141],
          [100.6269951, 5.1523785],
          [100.6270303, 5.1524461],
          [100.6271917, 5.1526439],
          [100.6273096, 5.152822],
          [100.6274807, 5.1531948],
          [100.6275925, 5.153477],
          [100.6277441, 5.1537862],
          [100.6279286, 5.1538448],
          [100.6281676, 5.1538873],
          [100.6283111, 5.1539363],
          [100.6285567, 5.1540855],
          [100.6286366, 5.154426],
          [100.6286537, 5.1545789],
          [100.6286631, 5.1549514],
          [100.6286118, 5.1551448],
          [100.6285388, 5.1552788],
          [100.6282594, 5.155511],
          [100.6277519, 5.1554822],
          [100.6274867, 5.1553931],
          [100.6273608, 5.1553741],
          [100.6272209, 5.1553783],
          [100.6271027, 5.155422],
          [100.6270295, 5.1554643],
          [100.6268826, 5.1557267],
          [100.6269904, 5.1559362],
          [100.6270975, 5.1559796],
          [100.6274327, 5.1559198],
          [100.6275856, 5.1559438],
          [100.6276249, 5.1559642],
          [100.6278936, 5.1562056],
          [100.6279395, 5.1564956],
          [100.6279414, 5.1566034],
          [100.6279258, 5.1569658],
          [100.6279009, 5.1571994],
          [100.6279034, 5.1574173],
          [100.6279136, 5.1577256],
          [100.628011, 5.1586379],
          [100.6282444, 5.1587032],
          [100.6283359, 5.1586884],
          [100.6287834, 5.1585916],
          [100.6288449, 5.1585891],
          [100.6289287, 5.1585988],
          [100.6289542, 5.1586102],
          [100.629086, 5.1587259],
          [100.6291754, 5.1588805],
          [100.6291899, 5.1589835],
          [100.6291858, 5.1591011],
          [100.6291352, 5.1592812],
          [100.6290893, 5.1594037],
          [100.6290398, 5.1594924],
          [100.6288619, 5.1597884],
          [100.628698, 5.1600267],
          [100.6285634, 5.1602319],
          [100.6285123, 5.1603439],
          [100.6284706, 5.160497],
          [100.6284654, 5.1605866],
          [100.6285322, 5.1617006],
          [100.6285197, 5.1620347],
          [100.6285234, 5.1622541],
          [100.6285093, 5.162962],
          [100.628521, 5.1631523],
          [100.6285486, 5.1633385],
          [100.6285807, 5.1634516],
          [100.6288345, 5.1638658],
          [100.6291689, 5.1641772],
          [100.629458, 5.1643234],
          [100.6295641, 5.1641393],
          [100.6294992, 5.1639016],
          [100.6293309, 5.163522],
          [100.6292705, 5.163331],
          [100.6292664, 5.163292],
          [100.629279, 5.1631185],
          [100.6293475, 5.1629978],
          [100.629808, 5.1627457],
          [100.6300306, 5.1627895],
          [100.6301549, 5.1629813],
          [100.6302085, 5.1631756],
          [100.6301974, 5.1633701],
          [100.630156, 5.1635368],
          [100.630061, 5.163859],
          [100.6299102, 5.1643358],
          [100.6298286, 5.1646516],
          [100.6298614, 5.1649635],
          [100.6301322, 5.1650613],
          [100.6302623, 5.1650737],
          [100.6305677, 5.1650038],
          [100.6307916, 5.1649763],
          [100.630882, 5.1649743],
          [100.631008, 5.1649966],
          [100.6312641, 5.1650945],
          [100.6313575, 5.165117],
          [100.6315059, 5.1651299],
          [100.6316867, 5.1650928],
          [100.6319782, 5.1649947],
          [100.6323013, 5.164987],
          [100.6324609, 5.1649583],
          [100.6326918, 5.1648913],
          [100.6332129, 5.1646495],
          [100.6334121, 5.164609],
          [100.6335108, 5.1648679],
          [100.6335248, 5.1649948],
          [100.6335558, 5.1651478],
          [100.6335967, 5.1652705],
          [100.6336836, 5.1654397],
          [100.6337398, 5.1655314],
          [100.6338183, 5.1656366],
          [100.6344554, 5.1661401],
          [100.6347558, 5.166452],
          [100.63483, 5.1667258],
          [100.634927, 5.1669664],
          [100.6349666, 5.1670828],
          [100.6350535, 5.1675272],
          [100.635085, 5.1676317],
          [100.635234, 5.1678653],
          [100.6353936, 5.1678135],
          [100.6355347, 5.1677249],
          [100.6356543, 5.1676069],
          [100.6357367, 5.1675477],
          [100.6361452, 5.1673642],
          [100.6363498, 5.1673068],
          [100.6365577, 5.1672686],
          [100.6366742, 5.1672621],
          [100.6367423, 5.1672719],
          [100.6368588, 5.167315],
          [100.6369653, 5.1673749],
          [100.6372766, 5.1676032],
          [100.6375981, 5.1678018],
          [100.6377834, 5.167879],
          [100.6379718, 5.1679287],
          [100.6383497, 5.167974],
          [100.6386218, 5.1680733],
          [100.6387867, 5.1682866],
          [100.6389376, 5.1685426],
          [100.6390749, 5.1687348],
          [100.6394307, 5.1690342],
          [100.639817, 5.1693337],
          [100.6399927, 5.1695544],
          [100.6405357, 5.1699393],
          [100.6409948, 5.1702976],
          [100.6410675, 5.1704074],
          [100.6411216, 5.1705758],
          [100.641153, 5.17078],
          [100.6411555, 5.1709328],
          [100.6411501, 5.1711499],
          [100.6411557, 5.1713084],
          [100.6412593, 5.1716517],
          [100.6414205, 5.1717839],
          [100.6415033, 5.1718397],
          [100.6416728, 5.1719358],
          [100.6418504, 5.1720233],
          [100.6421015, 5.172124],
          [100.6424674, 5.1723581],
          [100.6425212, 5.1725332],
          [100.6424951, 5.1726454],
          [100.6423532, 5.1729553],
          [100.6422195, 5.1732746],
          [100.6422854, 5.1735416],
          [100.642607, 5.1735083],
          [100.6427157, 5.1734695],
          [100.6430832, 5.173312],
          [100.6433244, 5.1731936],
          [100.6433836, 5.1731557],
          [100.6436615, 5.1727015],
          [100.6438159, 5.1726121],
          [100.6439818, 5.172681],
          [100.6441506, 5.1728293],
          [100.6442975, 5.172948],
          [100.6443417, 5.172975],
          [100.6445158, 5.1730633],
          [100.644858, 5.1731444],
          [100.6449653, 5.1731493],
          [100.6451376, 5.1731318],
          [100.6454185, 5.1730433],
          [100.6457769, 5.1728594],
          [100.6459992, 5.1727969],
          [100.646044, 5.1728001],
          [100.6463623, 5.1728716],
          [100.6464149, 5.173163],
          [100.6464063, 5.1733433],
          [100.6462918, 5.1737031],
          [100.6462844, 5.1737584],
          [100.6462924, 5.1738751],
          [100.6463125, 5.1739929],
          [100.646414, 5.1742673],
          [100.6466137, 5.1744057],
          [100.6466811, 5.1744385],
          [100.6467345, 5.1744473],
          [100.6468428, 5.1744342],
          [100.6470464, 5.174392],
          [100.6471963, 5.1743469],
          [100.6475915, 5.1741868],
          [100.6477461, 5.1742774],
          [100.6477584, 5.1745718],
          [100.6477346, 5.174613],
          [100.647533, 5.1748759],
          [100.6475258, 5.1749035],
          [100.6475231, 5.1751485],
          [100.6478505, 5.1751523],
          [100.6480043, 5.1751261],
          [100.6480812, 5.1751059],
          [100.6483625, 5.1750009],
          [100.6485072, 5.1749643],
          [100.6487466, 5.1749092],
          [100.6489745, 5.174844],
          [100.6494943, 5.1746566],
          [100.649672, 5.1745632],
          [100.6500559, 5.1745118],
          [100.6501684, 5.1745308],
          [100.6503566, 5.174623],
          [100.6504671, 5.1746975],
          [100.6505257, 5.1747464],
          [100.6506606, 5.1750326],
          [100.6505313, 5.1752105],
          [100.6504315, 5.1752406],
          [100.6502558, 5.1752413],
          [100.6500575, 5.1752457],
          [100.6496702, 5.1752811],
          [100.649394, 5.1753483],
          [100.6491009, 5.1754726],
          [100.6489224, 5.1758512],
          [100.6488772, 5.1759816],
          [100.6488525, 5.1761351],
          [100.6488909, 5.1762727],
          [100.6489896, 5.1764369],
          [100.6491369, 5.1766468],
          [100.6494957, 5.1770641],
          [100.6496105, 5.1772154],
          [100.6497284, 5.1774074],
          [100.6498529, 5.1777079],
          [100.6499769, 5.1780341],
          [100.6500643, 5.1782177],
          [100.6502457, 5.1786358],
          [100.6503663, 5.1788364],
          [100.6506295, 5.1790019],
          [100.6508133, 5.1790733],
          [100.6509932, 5.1791323],
          [100.6511547, 5.179205],
          [100.6512799, 5.1792746],
          [100.6519071, 5.1797324],
          [100.6520127, 5.179957],
          [100.6520379, 5.1800388],
          [100.6520447, 5.1801191],
          [100.6520391, 5.1802155],
          [100.6520025, 5.1803047],
          [100.6519727, 5.1803547],
          [100.6518447, 5.1805245],
          [100.6517501, 5.1806268],
          [100.6516499, 5.1807927],
          [100.6516094, 5.1808911],
          [100.6515601, 5.1810801],
          [100.6515311, 5.1812695],
          [100.6515283, 5.1814869],
          [100.6514932, 5.1816808],
          [100.6514013, 5.1819064],
          [100.6510671, 5.1824036],
          [100.6510212, 5.1825523],
          [100.651016, 5.1826497],
          [100.6510341, 5.1828058],
          [100.6510625, 5.1828993],
          [100.6511425, 5.1831023],
          [100.6512116, 5.183265],
          [100.651319, 5.1834806],
          [100.6515303, 5.1836245],
          [100.651593, 5.1836401],
          [100.6516273, 5.1836327],
          [100.6518522, 5.1835055],
          [100.6521534, 5.1833002],
          [100.6522949, 5.1832706],
          [100.6525253, 5.1832384],
          [100.6526094, 5.1832427],
          [100.6528309, 5.1832761],
          [100.6531449, 5.1833828],
          [100.6532882, 5.1834524],
          [100.6535107, 5.1835937],
          [100.6536134, 5.18364],
          [100.6536631, 5.1836386],
          [100.6538404, 5.1835764],
          [100.6539897, 5.1830767],
          [100.6540414, 5.1829586],
          [100.6540704, 5.1828695],
          [100.6541624, 5.1827177],
          [100.6543378, 5.182475],
          [100.6545572, 5.1822865],
          [100.654719, 5.1820978],
          [100.6548754, 5.181761],
          [100.6549158, 5.1816121],
          [100.6549712, 5.181151],
          [100.6552289, 5.1812139],
          [100.655292, 5.1812401],
          [100.6554872, 5.1813996],
          [100.6556658, 5.1815197],
          [100.6557375, 5.1815553],
          [100.6558631, 5.181601],
          [100.6559301, 5.1816162],
          [100.6561056, 5.1816268],
          [100.6564615, 5.1816146],
          [100.6565627, 5.1816016],
          [100.6568836, 5.1815306],
          [100.6572644, 5.1813733],
          [100.6573452, 5.1813284],
          [100.6575735, 5.1811753],
          [100.6577095, 5.1811277],
          [100.6579672, 5.1810746],
          [100.6580574, 5.1810678],
          [100.6582691, 5.1810811],
          [100.6585426, 5.1811921],
          [100.6585821, 5.1812165],
          [100.6586317, 5.1812621],
          [100.6587381, 5.1813715],
          [100.6588765, 5.1814755],
          [100.6593687, 5.1819029],
          [100.6596559, 5.1821201],
          [100.6601773, 5.1824662],
          [100.6603959, 5.1827414],
          [100.6604711, 5.1828689],
          [100.6605586, 5.1830918],
          [100.6605886, 5.1832624],
          [100.660604, 5.1833591],
          [100.6606064, 5.183446],
          [100.6605644, 5.1836808],
          [100.6605098, 5.1838382],
          [100.6603958, 5.184111],
          [100.6603232, 5.1843324],
          [100.6603141, 5.1843995],
          [100.6603126, 5.1845125],
          [100.6603429, 5.1846218],
          [100.6604148, 5.184772],
          [100.6605825, 5.1849647],
          [100.6608835, 5.1852072],
          [100.6613425, 5.1855887],
          [100.6615829, 5.1858943],
          [100.6616859, 5.1860588],
          [100.6617909, 5.186336],
          [100.6618422, 5.1865993],
          [100.6618308, 5.1868075],
          [100.6618167, 5.1868872],
          [100.6617971, 5.1871313],
          [100.6618054, 5.1874081],
          [100.661851, 5.1878926],
          [100.6618476, 5.1880058],
          [100.6618127, 5.1884024],
          [100.6618096, 5.1887178],
          [100.6618106, 5.1888163],
          [100.6617958, 5.1890999],
          [100.6618023, 5.1893033],
          [100.6618366, 5.1895112],
          [100.661869, 5.1896475],
          [100.661962, 5.1898512],
          [100.662155, 5.1899872],
          [100.6623287, 5.1900666],
          [100.6624815, 5.1900848],
          [100.66256, 5.1900837],
          [100.6627769, 5.1900533],
          [100.6630297, 5.1900222],
          [100.663168, 5.1900207],
          [100.6634392, 5.1900401],
          [100.6636136, 5.1900973],
          [100.6639092, 5.1902424],
          [100.6639922, 5.1905493],
          [100.6640139, 5.1906983],
          [100.6639964, 5.1908976],
          [100.6638612, 5.1912663],
          [100.6637757, 5.1914683],
          [100.6637361, 5.191671],
          [100.6637968, 5.1919525],
          [100.6640424, 5.1920945],
          [100.6641359, 5.1921226],
          [100.664259, 5.1921011],
          [100.6643167, 5.1921033],
          [100.6645309, 5.1921055],
          [100.6646997, 5.1923957],
          [100.6647061, 5.192424],
          [100.6647045, 5.1926907],
          [100.6646313, 5.1929922],
          [100.6645617, 5.1932085],
          [100.664463, 5.1934421],
          [100.6644173, 5.1936089],
          [100.6644046, 5.193852],
          [100.6644314, 5.1940423],
          [100.6645069, 5.1942525],
          [100.6645792, 5.1943707],
          [100.6647132, 5.1945159],
          [100.6648777, 5.1946612],
          [100.6651343, 5.1949848],
          [100.6655273, 5.1954439],
          [100.6656011, 5.1955445],
          [100.6657174, 5.195682],
          [100.6658746, 5.1958928],
          [100.6660724, 5.1962301],
          [100.6661441, 5.1963807],
          [100.6662248, 5.1966444],
          [100.6662503, 5.1968503],
          [100.6662599, 5.1969564],
          [100.6663053, 5.197206],
          [100.6664755, 5.1976267],
          [100.6667807, 5.1977403],
          [100.6669423, 5.1977802],
          [100.6670472, 5.1978192],
          [100.6672412, 5.1978796],
          [100.6675875, 5.1979983],
          [100.6678115, 5.1979795],
          [100.6678077, 5.1976782],
          [100.667741, 5.1974064],
          [100.6677052, 5.1972542],
          [100.6677331, 5.1970452],
          [100.6679248, 5.1968955],
          [100.6680022, 5.1968551],
          [100.6682234, 5.1968248],
          [100.6684581, 5.1968122],
          [100.6687552, 5.1968125],
          [100.6689806, 5.1968502],
          [100.6691823, 5.1968942],
          [100.6694156, 5.1969681],
          [100.6695946, 5.1970349],
          [100.6700802, 5.1974048],
          [100.6703641, 5.1977258],
          [100.6705211, 5.1979317],
          [100.6707113, 5.1983245],
          [100.670203, 5.1982417],
          [100.6700857, 5.1982543],
          [100.6698377, 5.1983852],
          [100.6698005, 5.1985761],
          [100.6698058, 5.1987254],
          [100.6698777, 5.1989933],
          [100.6699153, 5.1991657],
          [100.6699298, 5.1992746],
          [100.6699389, 5.199537],
          [100.6699236, 5.1996552],
          [100.669871, 5.1998488],
          [100.6697407, 5.2001856],
          [100.6697295, 5.2002942],
          [100.6697396, 5.2005873],
          [100.6699585, 5.2005405],
          [100.6700876, 5.200453],
          [100.6703331, 5.2003187],
          [100.670409, 5.2002882],
          [100.6705858, 5.2002408],
          [100.6707035, 5.2002498],
          [100.6709422, 5.2003857],
          [100.6710874, 5.20065],
          [100.6711416, 5.2007821],
          [100.6711809, 5.2009582],
          [100.6712464, 5.2011449],
          [100.6712911, 5.201236],
          [100.6713574, 5.2013415],
          [100.6717187, 5.2015884],
          [100.6720344, 5.2018351],
          [100.6720461, 5.201859],
          [100.6720631, 5.2019397],
          [100.6720377, 5.2020671],
          [100.6720225, 5.202106],
          [100.671835, 5.2024511],
          [100.6717998, 5.2026003],
          [100.6717913, 5.2027005],
          [100.6717994, 5.2028224],
          [100.6718689, 5.2030269],
          [100.6720539, 5.2034325],
          [100.6720797, 5.2035237],
          [100.6721239, 5.2037553],
          [100.6721246, 5.2038403],
          [100.6721148, 5.2039405],
          [100.6720358, 5.2042371],
          [100.6720158, 5.2043819],
          [100.6720616, 5.2045765],
          [100.6723771, 5.2047228],
          [100.67276, 5.2048402],
          [100.6730105, 5.2049314],
          [100.6734259, 5.2054432],
          [100.6735899, 5.2055851],
          [100.6737244, 5.2056585],
          [100.6739355, 5.2057379],
          [100.6742075, 5.2059212],
          [100.6742735, 5.2060994],
          [100.6743232, 5.2064071],
          [100.6743343, 5.2065529],
          [100.6743134, 5.2067191],
          [100.6742002, 5.2072109],
          [100.6741388, 5.2073589],
          [100.6738018, 5.2080803],
          [100.6739387, 5.208071],
          [100.6741363, 5.2081095],
          [100.6743398, 5.2081307],
          [100.6745763, 5.2082367],
          [100.6748836, 5.2086008],
          [100.6751319, 5.2089527],
          [100.675407, 5.2092978],
          [100.6757243, 5.2094732],
          [100.6760309, 5.209534],
          [100.6765065, 5.2097273],
          [100.6762078, 5.2103031],
          [100.6761869, 5.2104745],
          [100.6761929, 5.2105093],
          [100.6762277, 5.2106565],
          [100.6762437, 5.2107153],
          [100.6763345, 5.2108983],
          [100.6764462, 5.2110533],
          [100.6765802, 5.2111776],
          [100.6766815, 5.2113036],
          [100.676734, 5.2114106],
          [100.6767369, 5.2114837],
          [100.6767289, 5.2115277],
          [100.6766918, 5.2116354],
          [100.6765925, 5.211815],
          [100.6765194, 5.2119219],
          [100.6764394, 5.2120811],
          [100.6763739, 5.2122473],
          [100.6763638, 5.212309],
          [100.6763686, 5.2124126],
          [100.6763821, 5.2124902],
          [100.6764736, 5.212702],
          [100.676567, 5.2128689],
          [100.676632, 5.2130333],
          [100.6768515, 5.2136924],
          [100.6769639, 5.2139061],
          [100.677017, 5.2139851],
          [100.6772156, 5.2142572],
          [100.6774414, 5.2143926],
          [100.6777912, 5.2145682],
          [100.6782339, 5.214812],
          [100.6785254, 5.2149984],
          [100.6787112, 5.2152633],
          [100.6787897, 5.2153912],
          [100.6788153, 5.2154456],
          [100.6788608, 5.2156049],
          [100.6788787, 5.2158039],
          [100.6788723, 5.2158585],
          [100.6788295, 5.215962],
          [100.678754, 5.2160459],
          [100.6783102, 5.2163699],
          [100.6782695, 5.2164187],
          [100.6782426, 5.2164637],
          [100.6781995, 5.216585],
          [100.6781812, 5.216734],
          [100.6781988, 5.2168596],
          [100.6782407, 5.2169236],
          [100.6783928, 5.2173891],
          [100.6786021, 5.2181234],
          [100.6790256, 5.218291],
          [100.6790611, 5.218304],
          [100.6795398, 5.2183512],
          [100.6797727, 5.2183715],
          [100.6802521, 5.2183902],
          [100.6806709, 5.2184211],
          [100.6811233, 5.218469],
          [100.6812455, 5.2184987],
          [100.6816496, 5.2186662],
          [100.681991, 5.219157],
          [100.6820459, 5.2193403],
          [100.6820436, 5.2196492],
          [100.6819635, 5.2198052],
          [100.6815773, 5.2202023],
          [100.6814679, 5.2203929],
          [100.6814263, 5.2205819],
          [100.6814211, 5.2208629],
          [100.6817514, 5.2209659],
          [100.6819256, 5.220951],
          [100.6819659, 5.2209321],
          [100.682225, 5.220712],
          [100.6823488, 5.2205582],
          [100.6824728, 5.2203826],
          [100.6826207, 5.220235],
          [100.6827281, 5.2201777],
          [100.6829372, 5.2201295],
          [100.6830221, 5.2201327],
          [100.6833623, 5.2202597],
          [100.6837408, 5.2210919],
          [100.6840734, 5.2216027],
          [100.6842793, 5.2216875],
          [100.6845016, 5.221681],
          [100.684741, 5.2216014],
          [100.6849469, 5.2215392],
          [100.685229, 5.2215312],
          [100.6854266, 5.2215688],
          [100.6856962, 5.2219588],
          [100.6857198, 5.2220147],
          [100.6860653, 5.2227984],
          [100.6866298, 5.223467],
          [100.6864366, 5.2237263],
          [100.6862519, 5.2238346],
          [100.6855395, 5.2246552],
          [100.6852145, 5.2251486],
          [100.6852749, 5.2257845],
          [100.685233, 5.2262099],
          [100.6851913, 5.2265011],
          [100.6851326, 5.2270754],
          [100.6851458, 5.2273603],
          [100.6852146, 5.2276072],
          [100.6853211, 5.2278173],
          [100.6855683, 5.2279816],
          [100.6858642, 5.2281116],
          [100.6860317, 5.2281618],
          [100.6864461, 5.2282316],
          [100.6868149, 5.2282794],
          [100.6872463, 5.2283266],
          [100.6875883, 5.2284903],
          [100.6879692, 5.2288419],
          [100.6880563, 5.2292145],
          [100.6879159, 5.2296583],
          [100.6876592, 5.2299382],
          [100.6871915, 5.2301096],
          [100.6870782, 5.2305575],
          [100.6873601, 5.2312219],
          [100.6880387, 5.2323192],
          [100.6882706, 5.232931],
          [100.6889392, 5.2337013],
          [100.689058, 5.2343007],
          [100.6892213, 5.2344894],
          [100.6900171, 5.2347433],
          [100.6902662, 5.234948],
          [100.690098, 5.2353306],
          [100.68974, 5.235401],
          [100.68921, 5.2355606],
          [100.6890675, 5.2357731],
          [100.689004, 5.2360078],
          [100.6889814, 5.2362915],
          [100.6889614, 5.2373644],
          [100.6890388, 5.237537],
          [100.6892572, 5.2376241],
          [100.6895849, 5.2377038],
          [100.689819, 5.2377831],
          [100.6899992, 5.2381546],
          [100.6896359, 5.2390999],
          [100.6896425, 5.2393819],
          [100.6898055, 5.2396332],
          [100.6899918, 5.2399159],
          [100.6900926, 5.2401121],
          [100.6902007, 5.2404102],
          [100.6901445, 5.2407107],
          [100.6899495, 5.2411941],
          [100.6900859, 5.2417006],
          [100.690568, 5.2427709],
          [100.6908401, 5.2431576],
          [100.6906054, 5.2436422],
          [100.6907454, 5.2437995],
          [100.6913867, 5.2435957],
          [100.6917429, 5.2435116],
          [100.6919314, 5.2436616],
          [100.6920242, 5.2438891],
          [100.6920482, 5.2442591],
          [100.6922194, 5.2444243],
          [100.6926561, 5.2446063],
          [100.6929756, 5.2447956],
          [100.6930917, 5.2450702],
          [100.6929737, 5.2452655],
          [100.6927686, 5.2453813],
          [100.6922102, 5.2452079],
          [100.6918248, 5.2453703],
          [100.6916996, 5.2457705],
          [100.6917794, 5.2461879],
          [100.6921035, 5.2464226],
          [100.6923449, 5.2465193],
          [100.6925949, 5.2466739],
          [100.6931492, 5.2473043],
          [100.6934695, 5.2472847],
          [100.6939302, 5.2469865],
          [100.6944317, 5.2469328],
          [100.6946743, 5.247192],
          [100.6946588, 5.2475618],
          [100.6940494, 5.2483954],
          [100.6940926, 5.2486022],
          [100.6946346, 5.2488805],
          [100.6950427, 5.248735],
          [100.6952893, 5.2487632],
          [100.6957342, 5.2491421],
          [100.6962223, 5.249015],
          [100.6966191, 5.2487718],
          [100.6967796, 5.2485081],
          [100.6965099, 5.2482158],
          [100.6963321, 5.2480832],
          [100.6963664, 5.2479806],
          [100.6964345, 5.2479125],
          [100.6967166, 5.2479185],
          [100.6969091, 5.2480161],
          [100.6972264, 5.2481911],
          [100.6976428, 5.2481367],
          [100.6983251, 5.2478245],
          [100.6984225, 5.2478514],
          [100.6988842, 5.2484397],
          [100.6991379, 5.2484218],
          [100.6994855, 5.2483664],
          [100.6998962, 5.2484457],
          [100.6999732, 5.2485107],
          [100.7001039, 5.2487009],
          [100.7000855, 5.2488231],
          [100.7000655, 5.2489],
          [100.6999105, 5.2491034],
          [100.6996319, 5.2493061],
          [100.6995669, 5.2497129],
          [100.6996829, 5.2500522],
          [100.6999958, 5.2504017],
          [100.7001574, 5.2503247],
          [100.7002857, 5.2498816],
          [100.7004425, 5.2497542],
          [100.7006767, 5.2497403],
          [100.7007778, 5.2498159],
          [100.7009231, 5.2500439],
          [100.7011166, 5.250806],
          [100.7011186, 5.2508585],
          [100.7013677, 5.2508711],
          [100.7016098, 5.2502831],
          [100.7016615, 5.2501617],
          [100.7017399, 5.2500935],
          [100.7019057, 5.2501153],
          [100.7019921, 5.2501992],
          [100.702045, 5.2504104],
          [100.7020203, 5.2509186],
          [100.7025062, 5.2514753],
          [100.7025648, 5.2517433],
          [100.7029735, 5.2519809],
          [100.7033165, 5.2521671],
          [100.7034699, 5.2522343],
          [100.7034905, 5.2522497],
          [100.7038082, 5.2525623],
          [100.703938, 5.2528711],
          [100.7046706, 5.2526531],
          [100.7049265, 5.252782],
          [100.7053368, 5.253268],
          [100.7059553, 5.2536334],
          [100.7061272, 5.2539843],
          [100.7063947, 5.2543458],
          [100.7068454, 5.2544876],
          [100.7070548, 5.2545691],
          [100.7071418, 5.2550579],
          [100.7070632, 5.2555129],
          [100.7070908, 5.2557939],
          [100.7071572, 5.2559686],
          [100.7074821, 5.2562411],
          [100.7077021, 5.2563874],
          [100.7078128, 5.2567541],
          [100.7077769, 5.2574022],
          [100.7078089, 5.2576435],
          [100.7080211, 5.2578939],
          [100.7084547, 5.2580045],
          [100.7085486, 5.2580265],
          [100.7086713, 5.2579969],
          [100.7087244, 5.2578269],
          [100.7089949, 5.257422],
          [100.7091935, 5.2572982],
          [100.7094168, 5.2573966],
          [100.7096538, 5.2578319],
          [100.7098098, 5.2580296],
          [100.7100551, 5.2581173],
          [100.7102471, 5.2580606],
          [100.7106282, 5.2576577],
          [100.7107454, 5.257456],
          [100.7109025, 5.256781],
          [100.711102, 5.2564162],
          [100.7114463, 5.2563522],
          [100.7116669, 5.256438],
          [100.7117129, 5.2567721],
          [100.7118914, 5.2570408],
          [100.7120078, 5.2574952],
          [100.7125357, 5.2573618],
          [100.7133523, 5.2572772],
          [100.7135697, 5.2571362],
          [100.713929, 5.2566895],
          [100.7142479, 5.2565735],
          [100.7144071, 5.25711],
          [100.7144527, 5.25725],
          [100.7145447, 5.2573203],
          [100.71468, 5.2573246],
          [100.7147884, 5.2572007],
          [100.7151205, 5.2568098],
          [100.7154005, 5.2567413],
          [100.7155958, 5.2568792],
          [100.7156368, 5.2571292],
          [100.7156594, 5.2573017],
          [100.716027, 5.2576664],
          [100.716454, 5.2579665],
          [100.7166008, 5.2580839],
          [100.716816, 5.2582214],
          [100.7173052, 5.2588347],
          [100.717671, 5.2590036],
          [100.7182834, 5.2593258],
          [100.7189509, 5.2599567],
          [100.7195631, 5.2600053],
          [100.7199244, 5.2601634],
          [100.7205725, 5.2607438],
          [100.7208367, 5.2610606],
          [100.7210087, 5.2611969],
          [100.7212239, 5.2613843],
          [100.7216637, 5.2615378],
          [100.7222805, 5.2616163],
          [100.7228998, 5.2616694],
          [100.7231851, 5.2616659],
          [100.7235387, 5.2615948],
          [100.7240031, 5.2617181],
          [100.7244715, 5.2622141],
          [100.7245789, 5.2628186],
          [100.7246079, 5.2629912],
          [100.7248396, 5.2630438],
          [100.7254025, 5.2630042],
          [100.7257902, 5.2629462],
          [100.7262039, 5.2630623],
          [100.726631, 5.2634484],
          [100.7268372, 5.2638521],
          [100.7264219, 5.2643572],
          [100.7264031, 5.264436],
          [100.7266374, 5.2647609],
          [100.726857, 5.2649749],
          [100.72746, 5.2652583],
          [100.7276791, 5.2655577],
          [100.7276877, 5.2660364],
          [100.7278778, 5.2662675],
          [100.7280594, 5.2664232],
          [100.728183, 5.2666756],
          [100.7282351, 5.2668674],
          [100.7285312, 5.2669387],
          [100.7294243, 5.2670196],
          [100.7297404, 5.2671678],
          [100.7302579, 5.267322],
          [100.7304004, 5.2675166],
          [100.7305329, 5.2677075],
          [100.7306157, 5.2678302],
          [100.7306468, 5.2679889],
          [100.730502, 5.2682208],
          [100.7301785, 5.2683482],
          [100.7300888, 5.2686849],
          [100.729907, 5.2688736],
          [100.7297607, 5.2689249],
          [100.7295719, 5.2685988],
          [100.7294538, 5.2684539],
          [100.7293489, 5.2684402],
          [100.7292935, 5.2685555],
          [100.7293589, 5.2689427],
          [100.7293538, 5.2693178],
          [100.7290848, 5.2699164],
          [100.7290447, 5.2704257],
          [100.7289787, 5.2706967],
          [100.7289908, 5.270946],
          [100.7292503, 5.2712562],
          [100.7293836, 5.2714798],
          [100.7295287, 5.2717041],
          [100.7298525, 5.2720096],
          [100.7299002, 5.272366],
          [100.7298242, 5.2726797],
          [100.7296746, 5.2729326],
          [100.729109, 5.2729012],
          [100.7287324, 5.2729168],
          [100.7286583, 5.2731622],
          [100.7286215, 5.2738005],
          [100.7284215, 5.274136],
          [100.7277766, 5.2744821],
          [100.727689, 5.2745954],
          [100.7278234, 5.274717],
          [100.7280509, 5.2747676],
          [100.728338, 5.2748992],
          [100.72833, 5.2751737],
          [100.7280069, 5.2754813],
          [100.7279579, 5.2757488],
          [100.7281215, 5.2759074],
          [100.728361, 5.2759633],
          [100.729135, 5.2759184],
          [100.729374, 5.2760841],
          [100.7296126, 5.2763391],
          [100.7297279, 5.2766141],
          [100.72955, 5.2769983],
          [100.7294957, 5.2772079],
          [100.7295112, 5.2774064],
          [100.7296499, 5.2777],
          [100.7298121, 5.2779465],
          [100.7302588, 5.2782602],
          [100.7306128, 5.2787558],
          [100.7308096, 5.279196],
          [100.7308018, 5.2794362],
          [100.7305538, 5.2797802],
          [100.7305878, 5.2799332],
          [100.7310391, 5.2800069],
          [100.731205, 5.2800573],
          [100.7312651, 5.2804419],
          [100.7311342, 5.2806267],
          [100.7307955, 5.2807406],
          [100.7309093, 5.2809396],
          [100.7312198, 5.2811063],
          [100.7314485, 5.2812395],
          [100.7316096, 5.2815828],
          [100.7316514, 5.2818454],
          [100.7316305, 5.2819483],
          [100.7315137, 5.2820439],
          [100.7311848, 5.2820906],
          [100.7308364, 5.282109],
          [100.7305111, 5.2822117],
          [100.7304983, 5.2824319],
          [100.7308657, 5.2830778],
          [100.7311237, 5.2837543],
          [100.7311069, 5.2840978],
          [100.7308953, 5.2843472],
          [100.7305839, 5.2844286],
          [100.7304733, 5.2845451],
          [100.7304416, 5.2846834],
          [100.7305379, 5.2849079],
          [100.73073, 5.2850244],
          [100.731087, 5.2850759],
          [100.7311742, 5.2851553],
          [100.7312361, 5.2852537],
          [100.7312541, 5.285291],
          [100.7311901, 5.2854567],
          [100.731082, 5.2855493],
          [100.7308181, 5.2856714],
          [100.730759, 5.2857737],
          [100.7307074, 5.2859904],
          [100.7307568, 5.2862331],
          [100.7308375, 5.2863521],
          [100.7309509, 5.286515],
          [100.7310493, 5.2866214],
          [100.7311318, 5.2867281],
          [100.731195, 5.2868586],
          [100.7312746, 5.2870496],
          [100.7313206, 5.2872187],
          [100.7314581, 5.2875384],
          [100.7315707, 5.2877559],
          [100.7316131, 5.287917],
          [100.7316289, 5.2880493],
          [100.7315987, 5.2882114],
          [100.7315606, 5.2882647],
          [100.7315337, 5.2883182],
          [100.7315299, 5.2883684],
          [100.7315544, 5.2885344],
          [100.7316798, 5.2887265],
          [100.7318306, 5.2889059],
          [100.731931, 5.2890468],
          [100.7320058, 5.2892386],
          [100.7320177, 5.2894046],
          [100.7319787, 5.2896214],
          [100.7319146, 5.2898253],
          [100.7319642, 5.290017],
          [100.7320895, 5.2902346],
          [100.7321265, 5.2904262],
          [100.732113, 5.2905921],
          [100.7320239, 5.290732],
          [100.7318844, 5.2908335],
          [100.7316943, 5.2909602],
          [100.7315294, 5.2910998],
          [100.7314022, 5.2912778],
          [100.7313378, 5.29152],
          [100.731324, 5.2917625],
          [100.7313237, 5.2920266],
          [100.7313352, 5.2922944],
          [100.7313847, 5.2925368],
          [100.7313587, 5.2927279],
          [100.7313199, 5.2929572],
          [100.7313064, 5.2931739],
          [100.7313048, 5.2932957],
          [100.7313693, 5.2934818],
          [100.7315475, 5.2937964],
          [100.7318109, 5.2939381],
          [100.7319563, 5.2939962],
          [100.731997, 5.2940181],
          [100.7322366, 5.2940701],
          [100.732375, 5.2941854],
          [100.73245, 5.2943641],
          [100.7324492, 5.2945554],
          [100.73236, 5.2947717],
          [100.7322202, 5.2950006],
          [100.7320679, 5.295204],
          [100.731941, 5.2953819],
          [100.7318266, 5.2955886],
          [100.7317752, 5.2957923],
          [100.731787, 5.2960091],
          [100.7319502, 5.2962265],
          [100.7320502, 5.2964563],
          [100.7320241, 5.2966475],
          [100.7319604, 5.2968129],
          [100.7319091, 5.2970167],
          [100.7319081, 5.2972462],
          [100.7319825, 5.2975779],
          [100.7320445, 5.2978331],
          [100.7321827, 5.2979994],
          [100.7324217, 5.2981916],
          [100.7326988, 5.298333],
          [100.732976, 5.2984489],
          [100.7331354, 5.298497],
          [100.7333532, 5.2987946],
          [100.7334657, 5.2990628],
          [100.7334745, 5.2992054],
          [100.7333022, 5.299379],
          [100.7331991, 5.2994441],
          [100.7329519, 5.2995912],
          [100.7328765, 5.2997026],
          [100.7328798, 5.2998412],
          [100.7329216, 5.2999492],
          [100.7330707, 5.299979],
          [100.7332346, 5.3000052],
          [100.7333861, 5.3000058],
          [100.7335753, 5.3000321],
          [100.7336252, 5.3001598],
          [100.7336496, 5.3003638],
          [100.733611, 5.3005549],
          [100.733522, 5.3007203],
          [100.7333701, 5.3008344],
          [100.7331678, 5.30091],
          [100.7330161, 5.3009859],
          [100.7329145, 5.3011257],
          [100.7328877, 5.3011926],
          [100.7328797, 5.3012811],
          [100.7329732, 5.3014085],
          [100.7330612, 5.3014619],
          [100.7331023, 5.3015089],
          [100.7331773, 5.301675],
          [100.7331893, 5.3018407],
          [100.7331395, 5.3020374],
          [100.7330822, 5.3022963],
          [100.7331083, 5.3025182],
          [100.7332759, 5.3026371],
          [100.7335026, 5.30274],
          [100.7338052, 5.3028177],
          [100.7341205, 5.3028828],
          [100.7344233, 5.3028967],
          [100.7346379, 5.3028849],
          [100.7348529, 5.3027838],
          [100.7350554, 5.3026316],
          [100.7352074, 5.302492],
          [100.735334, 5.3023906],
          [100.7354731, 5.3023274],
          [100.7355868, 5.3023024],
          [100.7357633, 5.3023668],
          [100.7359393, 5.3025333],
          [100.7359916, 5.3027277],
          [100.7359663, 5.3028548],
          [100.7359378, 5.3028902],
          [100.735836, 5.3031065],
          [100.7358098, 5.3033359],
          [100.7358969, 5.3036294],
          [100.7359739, 5.3036894],
          [100.7362869, 5.3040084],
          [100.736387, 5.3041541],
          [100.736474, 5.3044859],
          [100.736599, 5.3047797],
          [100.736661, 5.3050349],
          [100.7367233, 5.3052391],
          [100.7369039, 5.3055221],
          [100.7370925, 5.3055511],
          [100.7371384, 5.3055722],
          [100.7373278, 5.305573],
          [100.7375426, 5.3055102],
          [100.7377196, 5.3054344],
          [100.738017, 5.3053807],
          [100.738035, 5.3054612],
          [100.7380847, 5.3056399],
          [100.7381092, 5.3058312],
          [100.7381967, 5.3060355],
          [100.7382968, 5.3062399],
          [100.7383086, 5.3064439],
          [100.7382824, 5.3066733],
          [100.7381805, 5.3069023],
          [100.7379908, 5.3070035],
          [100.7378141, 5.3070156],
          [100.7376501, 5.3070021],
          [100.737423, 5.3069757],
          [100.7372967, 5.3070007],
          [100.7371575, 5.3070766],
          [100.7369959, 5.307202],
          [100.7369249, 5.3073371],
          [100.7368533, 5.30741],
          [100.7367639, 5.3076646],
          [100.7366993, 5.3080212],
          [100.7366475, 5.3083525],
          [100.7366214, 5.3085691],
          [100.7366205, 5.3087858],
          [100.7366071, 5.3089642],
          [100.7364537, 5.309448],
          [100.7363265, 5.3097025],
          [100.7361739, 5.3099823],
          [100.7359833, 5.3102875],
          [100.7358183, 5.310529],
          [100.7357542, 5.310771],
          [100.7357034, 5.3109825],
          [100.73569, 5.3110384],
          [100.7356135, 5.3112166],
          [100.7355243, 5.3114329],
          [100.7353469, 5.3116107],
          [100.7351201, 5.311782],
          [100.7349441, 5.3118289],
          [100.7347557, 5.3118365],
          [100.7345888, 5.3118115],
          [100.7341897, 5.3117125],
          [100.734104, 5.3118562],
          [100.7340492, 5.3119553],
          [100.734049, 5.311993],
          [100.7340778, 5.3121274],
          [100.7341151, 5.3122073],
          [100.734183, 5.3122815],
          [100.7343248, 5.3124473],
          [100.7346339, 5.3126164],
          [100.7349003, 5.3127944],
          [100.7351521, 5.3129484],
          [100.7353661, 5.3130895],
          [100.7355673, 5.3132561],
          [100.7356933, 5.3133203],
          [100.7359329, 5.3133596],
          [100.7362105, 5.3133862],
          [100.7364126, 5.3133488],
          [100.7365898, 5.313222],
          [100.7367922, 5.3130954],
          [100.7369945, 5.3130197],
          [100.737184, 5.3129568],
          [100.7373862, 5.3129066],
          [100.7378286, 5.3127299],
          [100.7380812, 5.31268],
          [100.7383462, 5.3127066],
          [100.7384325, 5.3127372],
          [100.738556, 5.3128065],
          [100.7385853, 5.3128733],
          [100.738698, 5.3130904],
          [100.7388104, 5.3133841],
          [100.7389484, 5.3135886],
          [100.7390992, 5.313755],
          [100.7391971, 5.3138935],
          [100.7393899, 5.3139481],
          [100.7395723, 5.3139813],
          [100.7396841, 5.3139928],
          [100.7401606, 5.3140036],
          [100.7404, 5.3140938],
          [100.7406265, 5.3142732],
          [100.7408405, 5.3143888],
          [100.7410421, 5.3144916],
          [100.7412938, 5.3146456],
          [100.7414194, 5.3148119],
          [100.7416077, 5.3150549],
          [100.7417712, 5.3151958],
          [100.7419602, 5.315273],
          [100.7421996, 5.3153632],
          [100.7423758, 5.3154914],
          [100.742577, 5.3156835],
          [100.7427403, 5.3158754],
          [100.7429542, 5.3160165],
          [100.7431634, 5.3160744],
          [100.7433415, 5.3161652],
          [100.7434197, 5.3163753],
          [100.7434191, 5.3165283],
          [100.7434055, 5.3167705],
          [100.7434932, 5.3169365],
          [100.7436726, 5.317018],
          [100.743845, 5.3170239],
          [100.744061, 5.3169644],
          [100.7442126, 5.3169267],
          [100.7442539, 5.3169254],
          [100.7443363, 5.3169272],
          [100.7444091, 5.3169923],
          [100.7444766, 5.3171955],
          [100.7446272, 5.3173874],
          [100.7447783, 5.3174772],
          [100.7449675, 5.3175035],
          [100.7451822, 5.3174661],
          [100.7453591, 5.3174286],
          [100.7455611, 5.3174039],
          [100.7457126, 5.3174046],
          [100.7459267, 5.3175202],
          [100.7464049, 5.3178663],
          [100.7465685, 5.3179817],
          [100.7467195, 5.3180843],
          [100.7468848, 5.3181543],
          [100.7470866, 5.3181934],
          [100.7473639, 5.3182992],
          [100.7474375, 5.3185298],
          [100.7474761, 5.3186284],
          [100.7475045, 5.3187327],
          [100.7477193, 5.3188366],
          [100.7478159, 5.3188593],
          [100.7480178, 5.3188729],
          [100.7481821, 5.3188225],
          [100.7483841, 5.3187979],
          [100.7485984, 5.3188497],
          [100.7488003, 5.3188633],
          [100.7490024, 5.3188386],
          [100.7491506, 5.3187888],
          [100.7492888, 5.3187882],
          [100.7493916, 5.3188333],
          [100.7494805, 5.3189287],
          [100.749582, 5.3190705],
          [100.7496952, 5.3191602],
          [100.7498715, 5.3192501],
          [100.7500859, 5.319302],
          [100.7503381, 5.3193413],
          [100.7506157, 5.3193679],
          [100.7508682, 5.3193562],
          [100.7510955, 5.3193189],
          [100.751323, 5.3192433],
          [100.7515255, 5.3191039],
          [100.7516649, 5.3189642],
          [100.751716, 5.3188115],
          [100.7517926, 5.3185951],
          [100.7518565, 5.3183914],
          [100.7519834, 5.3182389],
          [100.7521605, 5.3181249],
          [100.7523877, 5.3181258],
          [100.7526524, 5.3182034],
          [100.7528668, 5.3182552],
          [100.7530687, 5.3182688],
          [100.7533085, 5.3182698],
          [100.7535226, 5.3183471],
          [100.7536986, 5.3185136],
          [100.7538493, 5.3187054],
          [100.7539494, 5.3189226],
          [100.7541002, 5.3190761],
          [100.7541265, 5.3191099],
          [100.7542097, 5.3191448],
          [100.7543524, 5.3191409],
          [100.7546047, 5.3191547],
          [100.7548446, 5.3191302],
          [100.7550342, 5.3190672],
          [100.7552489, 5.3190298],
          [100.7554759, 5.319069],
          [100.7556773, 5.31921],
          [100.7558281, 5.3193636],
          [100.7559791, 5.319479],
          [100.7560244, 5.3195066],
          [100.756192, 5.3195781],
          [100.7562596, 5.3195874],
          [100.7564012, 5.319586],
          [100.7564584, 5.3195702],
          [100.7566985, 5.3194819],
          [100.7569386, 5.3194064],
          [100.7571915, 5.3192799],
          [100.7573816, 5.3191022],
          [100.7575339, 5.3188989],
          [100.757735, 5.318572],
          [100.7578543, 5.3184829],
          [100.758024, 5.3184608],
          [100.7582168, 5.3185575],
          [100.7584181, 5.3187113],
          [100.758645, 5.3187887],
          [100.7587838, 5.3187765],
          [100.7589104, 5.3186878],
          [100.7589615, 5.3185478],
          [100.7589118, 5.3183563],
          [100.7588495, 5.3181394],
          [100.7587366, 5.3179732],
          [100.7585982, 5.3178579],
          [100.7584094, 5.3177296],
          [100.7582585, 5.3176015],
          [100.7582086, 5.3174611],
          [100.7582343, 5.3173465],
          [100.7583734, 5.3172833],
          [100.7585754, 5.3172586],
          [100.7588151, 5.3172851],
          [100.7590043, 5.3173114],
          [100.7591937, 5.3172994],
          [100.7593705, 5.3172746],
          [100.7596326, 5.31722],
          [100.7598644, 5.3171259],
          [100.7600537, 5.3170362],
          [100.7602176, 5.3168956],
          [100.7603195, 5.3166793],
          [100.7603331, 5.3164371],
          [100.7603719, 5.3161951],
          [100.760436, 5.3159531],
          [100.7604997, 5.3158004],
          [100.7606013, 5.3156606],
          [100.7607658, 5.3155338],
          [100.7609177, 5.3154324],
          [100.7610568, 5.3153692],
          [100.7613219, 5.3153193],
          [100.7615237, 5.3153584],
          [100.7617504, 5.315474],
          [100.761977, 5.3156279],
          [100.7622163, 5.3157564],
          [100.762481, 5.3158339],
          [100.7628214, 5.3159246],
          [100.7630609, 5.316002],
          [100.7632747, 5.3161814],
          [100.7635135, 5.3164373],
          [100.7636387, 5.31668],
          [100.7637136, 5.3168715],
          [100.7637253, 5.3171138],
          [100.7637642, 5.3175368],
          [100.7638497, 5.3175732],
          [100.7640386, 5.3176632],
          [100.7641901, 5.3176511],
          [100.7643672, 5.3175626],
          [100.7645316, 5.3174613],
          [100.7646709, 5.3173471],
          [100.7647848, 5.3172711],
          [100.764949, 5.317259],
          [100.7650621, 5.3173614],
          [100.7651877, 5.3175277],
          [100.7653387, 5.3176303],
          [100.7655783, 5.3176822],
          [100.7658307, 5.317696],
          [100.765995, 5.3176202],
          [100.7663745, 5.3174178],
          [100.7666399, 5.3173296],
          [100.7668798, 5.3173051],
          [100.7671319, 5.3173698],
          [100.7673458, 5.3175364],
          [100.7673956, 5.3177024],
          [100.7674074, 5.3179064],
          [100.7674066, 5.3181231],
          [100.7673803, 5.3183652],
          [100.7674048, 5.3185565],
          [100.7674677, 5.3186912],
          [100.767543, 5.3187228],
          [100.7677697, 5.3188257],
          [100.7679968, 5.3188521],
          [100.7681988, 5.3188402],
          [100.7684135, 5.3188028],
          [100.7685906, 5.3187143],
          [100.7687424, 5.3186257],
          [100.7689069, 5.3185116],
          [100.7691345, 5.3183978],
          [100.7693241, 5.3183221],
          [100.7695766, 5.3182976],
          [100.7699046, 5.3183372],
          [100.7702073, 5.3183766],
          [100.770636, 5.3184804],
          [100.7709257, 5.3186218],
          [100.7710888, 5.3188774],
          [100.7711004, 5.3191196],
          [100.7709983, 5.3194124],
          [100.7709593, 5.3196927],
          [100.7709582, 5.3199604],
          [100.7710584, 5.3201648],
          [100.7712222, 5.3202292],
          [100.7713611, 5.3202043],
          [100.7714374, 5.3200644],
          [100.7715141, 5.3198225],
          [100.771553, 5.3195677],
          [100.7716675, 5.3193387],
          [100.771807, 5.3191735],
          [100.7719462, 5.3190848],
          [100.7721862, 5.3190221],
          [100.7724136, 5.318972],
          [100.7726535, 5.3189602],
          [100.7729816, 5.3189743],
          [100.7732338, 5.3190135],
          [100.7734608, 5.3190654],
          [100.7736625, 5.3191172],
          [100.7739023, 5.3191006],
          [100.7739154, 5.3190035],
          [100.7739163, 5.3187868],
          [100.7738667, 5.3185699],
          [100.7738044, 5.3183529],
          [100.7737548, 5.318136],
          [100.7737428, 5.3179957],
          [100.7737814, 5.3178047],
          [100.7738018, 5.3177384],
          [100.7740598, 5.3176273],
          [100.7742872, 5.3175517],
          [100.7745273, 5.3175017],
          [100.7747294, 5.3174515],
          [100.774944, 5.3174397],
          [100.7751713, 5.3174151],
          [100.7753608, 5.3173649],
          [100.7755803, 5.3172547],
          [100.7756273, 5.3169962],
          [100.7756912, 5.3168053],
          [100.7757804, 5.3166017],
          [100.7758567, 5.316449],
          [100.7759835, 5.3163093],
          [100.7760975, 5.3162078],
          [100.7762367, 5.3161063],
          [100.7763633, 5.3160176],
          [100.7765276, 5.3159418],
          [100.7767551, 5.3158662],
          [100.7769082, 5.3158469],
          [100.7770594, 5.3159113],
          [100.7771342, 5.3161283],
          [100.7772848, 5.3163329],
          [100.7774229, 5.3165247],
          [100.7775616, 5.3165635],
          [100.7777006, 5.3165258],
          [100.7777768, 5.3164113],
          [100.7778405, 5.3162459],
          [100.7779547, 5.3160934],
          [100.7781065, 5.3160047],
          [100.7782834, 5.3159545],
          [100.7784477, 5.3159169],
          [100.7786496, 5.3159177],
          [100.7788261, 5.3159694],
          [100.7790025, 5.3160466],
          [100.7791537, 5.3161109],
          [100.7793051, 5.3161115],
          [100.7794316, 5.3160483],
          [100.7795709, 5.3159214],
          [100.7797105, 5.3157307],
          [100.7798624, 5.3156166],
          [100.7800395, 5.3155281],
          [100.7803047, 5.3154782],
          [100.7805445, 5.3154791],
          [100.7808221, 5.3155058],
          [100.7810743, 5.3155578],
          [100.7813138, 5.3156352],
          [100.781591, 5.3157383],
          [100.7818685, 5.3157904],
          [100.7821337, 5.3157405],
          [100.7823108, 5.3156392],
          [100.7824505, 5.3154358],
          [100.7824767, 5.315181],
          [100.7824523, 5.3149641],
          [100.7823524, 5.314696],
          [100.7822522, 5.3145172],
          [100.7820888, 5.314338],
          [100.781875, 5.3141587],
          [100.7818405, 5.3141323],
          [100.7813349, 5.3137924],
          [100.7812029, 5.3136193],
          [100.7811872, 5.3135776],
          [100.7812088, 5.3134931],
          [100.7812724, 5.3133531],
          [100.7814241, 5.31329],
          [100.7816514, 5.3132654],
          [100.7819166, 5.3132282],
          [100.7821311, 5.3132418],
          [100.78232, 5.3133446],
          [100.782559, 5.3135495],
          [100.7827851, 5.3138181],
          [100.7828977, 5.3140481],
          [100.7830983, 5.3143931],
          [100.7832234, 5.3146613],
          [100.783386, 5.3150316],
          [100.7835739, 5.3154021],
          [100.7837659, 5.3157191],
          [100.7839069, 5.3159729],
          [100.7840982, 5.3162134],
          [100.7843421, 5.3165101],
          [100.7845793, 5.3168522],
          [100.7848178, 5.3171846],
          [100.7850942, 5.3175298],
          [100.785358, 5.3178367],
          [100.7856599, 5.3181056],
          [100.7859489, 5.3184254],
          [100.7862507, 5.3187324],
          [100.786439, 5.3190009],
          [100.786665, 5.3193076],
          [100.7868279, 5.3196142],
          [100.7869401, 5.3199716],
          [100.7869769, 5.3202394],
          [100.786976, 5.3204943],
          [100.7869121, 5.3207108],
          [100.7867977, 5.3209143],
          [100.7866707, 5.3211305],
          [100.7865438, 5.3213213],
          [100.786442, 5.3215376],
          [100.7864286, 5.3217287],
          [100.7865921, 5.3220827],
          [100.7866165, 5.3223123],
          [100.7865019, 5.3225923],
          [100.7865134, 5.3228855],
          [100.7866843, 5.3231297],
          [100.7869967, 5.3233381],
          [100.7872339, 5.3234154],
          [100.7879748, 5.3235539],
          [100.7886805, 5.3235736],
          [100.7891807, 5.3235567],
          [100.7905383, 5.3234825],
          [100.7906938, 5.3234893],
          [100.7909094, 5.3235234],
          [100.7909634, 5.323538],
          [100.791095, 5.3236002],
          [100.7912313, 5.3236915],
          [100.7914469, 5.3237926],
          [100.7916555, 5.3238259],
          [100.7917808, 5.3237271],
          [100.7918557, 5.3236917],
          [100.7920401, 5.3234852],
          [100.7921385, 5.3231911],
          [100.7922273, 5.3226133],
          [100.7923408, 5.3219162],
          [100.7925447, 5.3215783],
          [100.7928373, 5.3213286],
          [100.7932702, 5.3211012],
          [100.7939084, 5.3208639],
          [100.7943039, 5.3206639],
          [100.7949259, 5.320279],
          [100.7956363, 5.3199405],
          [100.7957631, 5.3199715],
          [100.7959402, 5.3200408],
          [100.7959758, 5.3200716],
          [100.7960783, 5.3202167],
          [100.7962211, 5.3205891],
          [100.7966497, 5.3216238],
          [100.7968755, 5.321852],
          [100.7972795, 5.3222233],
          [100.7974341, 5.3222804],
          [100.7974714, 5.3223089],
          [100.7982337, 5.322374],
          [100.7987078, 5.322376],
          [100.7990971, 5.3223565],
          [100.7994017, 5.3223577],
          [100.7999268, 5.322275],
          [100.8000159, 5.3222501],
          [100.8008444, 5.3220421],
          [100.8016775, 5.3217252],
          [100.8021315, 5.3212321],
          [100.8022856, 5.3207916],
          [100.8022876, 5.3203165],
          [100.8023074, 5.319604],
          [100.8023427, 5.3194991],
          [100.8024467, 5.3192245],
          [100.8026207, 5.3189576],
          [100.8028262, 5.3188016],
          [100.8030762, 5.3186616],
          [100.8032404, 5.3186167],
          [100.8032931, 5.318607],
          [100.8034962, 5.3186248],
          [100.8038003, 5.3187618],
          [100.8040701, 5.3190343],
          [100.8042722, 5.3192727],
          [100.8044909, 5.3195959],
          [100.8046598, 5.3196984],
          [100.8049304, 5.3197504],
          [100.8053707, 5.3197013],
          [100.8058894, 5.3195995],
          [100.8063575, 5.3193337],
          [100.8067754, 5.3189657],
          [100.8072687, 5.3187127],
          [100.8076599, 5.3187143],
          [100.8082178, 5.3188645],
          [100.8085406, 5.3192059],
          [100.8087303, 5.3195054],
          [100.8087416, 5.3198242],
          [100.8085001, 5.3202566],
          [100.8082272, 5.3207139],
          [100.8081425, 5.3211129],
          [100.8081567, 5.3214262],
          [100.8083286, 5.3218912],
          [100.8085598, 5.3221913],
          [100.8088457, 5.3225451],
          [100.8093119, 5.322725],
          [100.8094743, 5.3227461],
          [100.8097141, 5.3227342],
          [100.8097951, 5.3227221],
          [100.8099438, 5.3226479],
          [100.8102066, 5.322461],
          [100.8102577, 5.3224175],
          [100.8109944, 5.3217707],
          [100.8112308, 5.3215069],
          [100.8114066, 5.3213311],
          [100.8116428, 5.3211939],
          [100.8117512, 5.3210975],
          [100.8122821, 5.3208063],
          [100.8128375, 5.3207445],
          [100.8131157, 5.3207802],
          [100.8132575, 5.320833],
          [100.8133523, 5.3211965],
          [100.8133789, 5.3212781],
          [100.8134646, 5.3220048],
          [100.813551, 5.3225404],
          [100.8136503, 5.3229995],
          [100.8138133, 5.3233059],
          [100.8140904, 5.3234344],
          [100.8142449, 5.3234111],
          [100.8145119, 5.3233452],
          [100.8150018, 5.3231241],
          [100.8156477, 5.3227699],
          [100.8163408, 5.3223556],
          [100.8169729, 5.3219883],
          [100.8174532, 5.3217351],
          [100.8181341, 5.3218649],
          [100.818675, 5.3223129],
          [100.8189639, 5.3226707],
          [100.8190499, 5.3228043],
          [100.8190791, 5.3229206],
          [100.8190796, 5.3230468],
          [100.8190407, 5.3234534],
          [100.8190308, 5.3234803],
          [100.8188564, 5.3237484],
          [100.8188232, 5.3238062],
          [100.8187562, 5.3238951],
          [100.8184134, 5.3244193],
          [100.8181058, 5.3246693],
          [100.8176854, 5.3249495],
          [100.8173323, 5.3251482],
          [100.8172837, 5.3253606],
          [100.8173715, 5.3256729],
          [100.8176371, 5.3260081],
          [100.8180136, 5.3264105],
          [100.8181019, 5.3265891],
          [100.8182345, 5.3268124],
          [100.8184553, 5.3272589],
          [100.8191628, 5.3283533],
          [100.8196051, 5.3290233],
          [100.8201136, 5.3298051],
          [100.8204237, 5.3301404],
          [100.8208176, 5.3305458],
          [100.8211066, 5.3308782],
          [100.8213097, 5.3310572],
          [100.8217341, 5.3314091],
          [100.8224746, 5.3317565],
          [100.8225975, 5.3319648],
          [100.8227471, 5.3322009],
          [100.8229486, 5.3326316],
          [100.8233564, 5.3328648],
          [100.8236322, 5.3330976],
          [100.8237098, 5.3332059],
          [100.8239778, 5.3335035],
          [100.824085, 5.3336623],
          [100.8243092, 5.3338387],
          [100.8246007, 5.3339902],
          [100.8254622, 5.3341267],
          [100.8254953, 5.3341292],
          [100.8261726, 5.3342345],
          [100.8264763, 5.3345254],
          [100.8267946, 5.3350046],
          [100.8269766, 5.3351854],
          [100.8270108, 5.3352086],
          [100.827227, 5.3354709],
          [100.8275572, 5.335788],
          [100.8281256, 5.3363787],
          [100.8282742, 5.3365528],
          [100.8285247, 5.3368078],
          [100.8288555, 5.3369592],
          [100.8294198, 5.3371388],
          [100.8296142, 5.337406],
          [100.8296876, 5.3377846],
          [100.829739, 5.3383036],
          [100.8297801, 5.3383546],
          [100.8298814, 5.3384748],
          [100.8301187, 5.3385656],
          [100.830587, 5.3386115],
          [100.8306474, 5.3386098],
          [100.8307223, 5.3386209],
          [100.8308997, 5.3386767],
          [100.8312088, 5.3389668],
          [100.8314686, 5.3393351],
          [100.8317213, 5.3396687],
          [100.8320332, 5.3400373],
          [100.8322287, 5.3403905],
          [100.8323511, 5.3406492],
          [100.8324252, 5.3408677],
          [100.8324644, 5.3409747],
          [100.8324949, 5.3410728],
          [100.8327009, 5.3413541],
          [100.8327712, 5.3414212],
          [100.8328724, 5.3414705],
          [100.8331585, 5.3415919],
          [100.8333741, 5.3416729],
          [100.8336295, 5.3417452],
          [100.833995, 5.3418356],
          [100.8341492, 5.3418674],
          [100.8344108, 5.3419323],
          [100.8345839, 5.3420085],
          [100.8347367, 5.3420956],
          [100.8352351, 5.342493],
          [100.8358069, 5.3428541],
          [100.835962, 5.3429637],
          [100.8361244, 5.3431116],
          [100.8365029, 5.343409],
          [100.8368081, 5.3435557],
          [100.8368591, 5.3435883],
          [100.8371968, 5.3439212],
          [100.8373122, 5.344104],
          [100.8373756, 5.3441882],
          [100.8375558, 5.3445153],
          [100.837816, 5.3453533],
          [100.8381232, 5.345783],
          [100.8383026, 5.3461023],
          [100.8383436, 5.3461292],
          [100.8386688, 5.3464087],
          [100.8387363, 5.3464807],
          [100.8392396, 5.3467503],
          [100.8395607, 5.3467928],
          [100.8398722, 5.3468321],
          [100.8403762, 5.3469133],
          [100.8412552, 5.3469102],
          [100.8417171, 5.3470744],
          [100.8418158, 5.3471343],
          [100.8420627, 5.3472245],
          [100.8423979, 5.3473195],
          [100.8430883, 5.3478278],
          [100.8435829, 5.3483324],
          [100.8441145, 5.3489398],
          [100.844397, 5.34915],
          [100.8450982, 5.3494514],
          [100.8455685, 5.3496851],
          [100.8458962, 5.3497536],
          [100.8462031, 5.3498725],
          [100.8466934, 5.3503877],
          [100.8470038, 5.3505922],
          [100.8471247, 5.3506497],
          [100.8479318, 5.3507942],
          [100.8479613, 5.3508026],
          [100.8483952, 5.3509031],
          [100.8490528, 5.3513739],
          [100.8494953, 5.3517326],
          [100.8494841, 5.3521734],
          [100.8492858, 5.352392],
          [100.8487815, 5.3526559],
          [100.8486592, 5.3528536],
          [100.8487199, 5.353214],
          [100.8487407, 5.3536365],
          [100.8489926, 5.3544711],
          [100.849133, 5.3550186],
          [100.8491712, 5.3556938],
          [100.8490223, 5.3564659],
          [100.8489664, 5.3566699],
          [100.8488737, 5.3568737],
          [100.8488264, 5.3570007],
          [100.8486597, 5.357245],
          [100.8485774, 5.3574479],
          [100.8485216, 5.3576148],
          [100.8484474, 5.3578001],
          [100.8487222, 5.3583539],
          [100.8488483, 5.3586741],
          [100.8491292, 5.3590158],
          [100.8495975, 5.3597721],
          [100.8501989, 5.3603596],
          [100.8507184, 5.3610823],
          [100.8509324, 5.3614851],
          [100.8510297, 5.3618976],
          [100.851001, 5.3620522],
          [100.851003, 5.3622278],
          [100.8509842, 5.3623577],
          [100.8509713, 5.3625484],
          [100.8505651, 5.3630294],
          [100.8503254, 5.3632326],
          [100.8499015, 5.3635465],
          [100.8497477, 5.3636707],
          [100.8495818, 5.3638],
          [100.8496392, 5.3641392],
          [100.8500601, 5.3650387],
          [100.8503662, 5.3653066],
          [100.8507944, 5.3657134],
          [100.8509688, 5.3658746],
          [100.8509967, 5.3658916],
          [100.8510041, 5.3659218],
          [100.8512132, 5.366503],
          [100.8512674, 5.3667445],
          [100.8512355, 5.3670836],
          [100.8510503, 5.3674541],
          [100.8508655, 5.3677318],
          [100.8507543, 5.3679727],
          [100.8506802, 5.3681209],
          [100.8501225, 5.3685611],
          [100.8499557, 5.368828],
          [100.8499405, 5.3692874],
          [100.84992, 5.3698256],
          [100.8501002, 5.3701966],
          [100.8501609, 5.3705491],
          [100.8501919, 5.3707919],
          [100.850173, 5.3709217],
          [100.8500108, 5.3714944],
          [100.8499875, 5.3716489],
          [100.8499655, 5.3723131],
          [100.849891, 5.3725541],
          [100.8498213, 5.3727244],
          [100.8497834, 5.3728246],
          [100.8497516, 5.3729374],
          [100.8497825, 5.3730442],
          [100.8500486, 5.3738326],
          [100.8500899, 5.3742532],
          [100.8500839, 5.374323],
          [100.850083, 5.3749945],
          [100.8500128, 5.3752561],
          [100.8498386, 5.3756046],
          [100.8496295, 5.3760054],
          [100.8495601, 5.3760924],
          [100.849369, 5.3763186],
          [100.8488147, 5.3769679],
          [100.8486412, 5.3772146],
          [100.8485525, 5.3773456],
          [100.8484299, 5.3777467],
          [100.8484716, 5.3779279],
          [100.8485511, 5.3786184],
          [100.8486345, 5.3789145],
          [100.8487293, 5.3791132],
          [100.8487699, 5.3792147],
          [100.8488041, 5.3793196],
          [100.8488727, 5.3794595],
          [100.8489822, 5.3797517],
          [100.8491699, 5.3806237],
          [100.8496801, 5.3809391],
          [100.850393, 5.381439],
          [100.8508804, 5.3821295],
          [100.8513156, 5.3828022],
          [100.8517157, 5.3833207],
          [100.8518124, 5.3835102],
          [100.8518972, 5.3836397],
          [100.8520243, 5.3838123],
          [100.8520609, 5.3843254],
          [100.8520003, 5.3852038],
          [100.8521473, 5.3860635],
          [100.8524121, 5.3864389],
          [100.8529852, 5.3869733],
          [100.8532055, 5.3873477],
          [100.8533724, 5.3874635],
          [100.853603, 5.3876211],
          [100.8538538, 5.38789],
          [100.8539012, 5.3880203],
          [100.8539179, 5.3881417],
          [100.8539386, 5.388275],
          [100.8539533, 5.3883467],
          [100.8539693, 5.3883832],
          [100.854401, 5.3891654],
          [100.8548896, 5.3896909],
          [100.8555991, 5.3904617],
          [100.856028, 5.3909103],
          [100.8565453, 5.3915159],
          [100.8573769, 5.392089],
          [100.8578771, 5.3926989],
          [100.858445, 5.3933005],
          [100.858784, 5.3940186],
          [100.8591618, 5.3944917],
          [100.8595311, 5.3951798],
          [100.8594367, 5.3958455],
          [100.8594187, 5.3963038],
          [100.8598377, 5.3969275],
          [100.8601281, 5.3972888],
          [100.8602884, 5.3977478],
          [100.8605779, 5.3983219],
          [100.8604475, 5.3985178],
          [100.8601873, 5.3987296],
          [100.8597644, 5.3991208],
          [100.8595852, 5.3993493],
          [100.859438, 5.3996762],
          [100.8593057, 5.4003469],
          [100.8590603, 5.4009516],
          [100.8586525, 5.4015885],
          [100.8582133, 5.401996],
          [100.8579854, 5.4022407],
          [100.8577422, 5.4022725],
          [100.8575624, 5.4023675],
          [100.8570581, 5.402612],
          [100.8567681, 5.4026571],
          [100.8564786, 5.4025789],
          [100.8562957, 5.4025474],
          [100.8559755, 5.4025153],
          [100.8554116, 5.4024053],
          [100.854923, 5.4025266],
          [100.8546018, 5.402741],
          [100.8543763, 5.4031385],
          [100.8542198, 5.4036092],
          [100.854108, 5.404218],
          [100.8539839, 5.4047566],
          [100.8537822, 5.4056185],
          [100.8536396, 5.4061594],
          [100.8535909, 5.4069171],
          [100.8536531, 5.4072719],
          [100.8538058, 5.407612],
          [100.8542595, 5.4079611],
          [100.8546069, 5.4085345],
          [100.8546966, 5.4088564],
          [100.8547559, 5.4091936],
          [100.8548902, 5.4097606],
          [100.8548591, 5.4099596],
          [100.8546459, 5.4105837],
          [100.8546424, 5.4111072],
          [100.8547628, 5.411322],
          [100.8550797, 5.4117061],
          [100.8554108, 5.4119638],
          [100.8558602, 5.4123996],
          [100.8561784, 5.4127405],
          [100.8564703, 5.4130925],
          [100.8570702, 5.4138511],
          [100.8573943, 5.4142245],
          [100.8576519, 5.4146669],
          [100.8580641, 5.4152747],
          [100.8582578, 5.4154882],
          [100.858516, 5.4158003],
          [100.8590969, 5.4164737],
          [100.8594678, 5.4169663],
          [100.8598894, 5.417336],
          [100.8601403, 5.4175936],
          [100.8604042, 5.4179522],
          [100.8607756, 5.4182974],
          [100.8611899, 5.4187365],
          [100.8613349, 5.4189499],
          [100.8615286, 5.4191634],
          [100.8616261, 5.4192901],
          [100.8621433, 5.4197375],
          [100.8625815, 5.4201732],
          [100.8629373, 5.4205181],
          [100.8629733, 5.4205622],
          [100.8631058, 5.4206923],
          [100.8632445, 5.4208061],
          [100.8635043, 5.4209595],
          [100.8636891, 5.4211048],
          [100.8636143, 5.4211045],
          [100.8639773, 5.4214386],
          [100.8642869, 5.4217832],
          [100.8647606, 5.4223934],
          [100.8647262, 5.4226264],
          [100.8647861, 5.4234123],
          [100.8648988, 5.4238145],
          [100.8652393, 5.4245047],
          [100.8656372, 5.4251377],
          [100.8659777, 5.4258279],
          [100.8662042, 5.4264028],
          [100.8663732, 5.427035],
          [100.866314, 5.427494],
          [100.8661398, 5.4280675],
          [100.8661653, 5.4285855],
          [100.866398, 5.4290832],
          [100.866649, 5.4294472],
          [100.866945, 5.4301212],
          [100.8669225, 5.4304822],
          [100.8667674, 5.4309007],
          [100.8664712, 5.4315987],
          [100.8665301, 5.4322062],
          [100.8667768, 5.432633],
          [100.8670142, 5.4331424],
          [100.8670181, 5.4336847],
          [100.8670385, 5.4343328],
          [100.8668808, 5.4363327],
          [100.8668921, 5.4377901],
          [100.8670188, 5.4383876],
          [100.8668029, 5.4390195],
          [100.8662643, 5.4398141],
          [100.866101, 5.4402588],
          [100.8659892, 5.4405803],
          [100.8659384, 5.4410424],
          [100.8659727, 5.4417472],
          [100.8661344, 5.4428312],
          [100.8662309, 5.4432549],
          [100.8664489, 5.4436134],
          [100.8666791, 5.4437499],
          [100.8671913, 5.4442349],
          [100.8676036, 5.4446501],
          [100.8677348, 5.4448449],
          [100.8677832, 5.4448685],
          [100.8678015, 5.4450759],
          [100.8677978, 5.4452528],
          [100.8677436, 5.4455153],
          [100.8677051, 5.4457404],
          [100.8675101, 5.446271],
          [100.8673823, 5.4466374],
          [100.8673404, 5.4470281],
          [100.8674188, 5.4474176],
          [100.8674682, 5.4477537],
          [100.8674589, 5.4482053],
          [100.8673814, 5.4485012],
          [100.8671709, 5.4497808],
          [100.8670626, 5.4504789],
          [100.8670186, 5.450975],
          [100.8671071, 5.4514174],
          [100.8673156, 5.452058],
          [100.8676339, 5.4526558],
          [100.8678675, 5.452992],
          [100.8677144, 5.4544008],
          [100.8677054, 5.4564791],
          [100.8678672, 5.4571978],
          [100.8678853, 5.4575935],
          [100.8682146, 5.4580258],
          [100.8687011, 5.4586685],
          [100.8689591, 5.4590918],
          [100.8689409, 5.4592458],
          [100.8687278, 5.4593769],
          [100.8682653, 5.4595744],
          [100.8672954, 5.4597803],
          [100.8665207, 5.4601822],
          [100.8661328, 5.4604076],
          [100.865982, 5.4606116],
          [100.8657393, 5.4609002],
          [100.8656404, 5.4613274],
          [100.865637, 5.4616738],
          [100.865887, 5.4621067],
          [100.8661741, 5.4625923],
          [100.8667513, 5.4633912],
          [100.8672397, 5.4643088],
          [100.8673441, 5.4648946],
          [100.8674082, 5.4665225],
          [100.8672977, 5.4682372],
          [100.8674288, 5.4691662],
          [100.8674934, 5.4694988],
          [100.86769, 5.4698041],
          [100.8682139, 5.4704513],
          [100.8688332, 5.470852],
          [100.8693826, 5.4713566],
          [100.8698844, 5.4723535],
          [100.8702971, 5.4732921],
          [100.8706057, 5.4738143],
          [100.8712641, 5.4743212],
          [100.8717645, 5.4746554],
          [100.8722257, 5.4750678],
          [100.8726218, 5.4753584],
          [100.8727383, 5.4757185],
          [100.8735483, 5.4776217],
          [100.8743711, 5.4790846],
          [100.8746749, 5.4796594],
          [100.8750165, 5.4800393],
          [100.875216, 5.4802053],
          [100.8758292, 5.4803498],
          [100.8774287, 5.4811397],
          [100.8809147, 5.4828309],
          [100.8813614, 5.4830321],
          [100.8818798, 5.4830391],
          [100.8822109, 5.4830196],
          [100.8826216, 5.4831448],
          [100.8831416, 5.483438],
          [100.8837322, 5.4839547],
          [100.8842659, 5.4848619],
          [100.8845692, 5.4852786],
          [100.8845912, 5.4856706],
          [100.8845261, 5.4859103],
          [100.883915, 5.4871214],
          [100.8833634, 5.4879948],
          [100.8830393, 5.4884098],
          [100.8829145, 5.4891312],
          [100.882841, 5.4897472],
          [100.8828308, 5.4911663],
          [100.8834319, 5.4931285],
          [100.8839527, 5.4938936],
          [100.8853224, 5.4946264],
          [100.8866727, 5.4957431],
          [100.8876186, 5.4961212],
          [100.8887413, 5.4964325],
          [100.890297, 5.4972163],
          [100.8908141, 5.4972727],
          [100.8917906, 5.4973193],
          [100.8928324, 5.4977453],
          [100.8940442, 5.4974197],
          [100.8949915, 5.4963984],
          [100.8952486, 5.495944],
          [100.8951918, 5.494909],
          [100.8950019, 5.4938089],
          [100.8956747, 5.4924685],
          [100.8964598, 5.4918749],
          [100.8972348, 5.4916654],
          [100.8976938, 5.4918743],
          [100.8981281, 5.4923499],
          [100.8988805, 5.4927692],
          [100.8998555, 5.4926461],
          [100.9008159, 5.4923038],
          [100.9020338, 5.4920199],
          [100.9030434, 5.4916681],
          [100.9034112, 5.4907502],
          [100.9040816, 5.4913588],
          [100.9051541, 5.4920799],
          [100.9066567, 5.4926657],
          [100.9071919, 5.4932656],
          [100.9079042, 5.4939624],
          [100.9084818, 5.4942207],
          [100.9089419, 5.4941884],
          [100.9092282, 5.4939688],
          [100.9100485, 5.4923962],
          [100.910748, 5.4921953],
          [100.9112946, 5.4924789],
          [100.9119653, 5.4931817],
          [100.9125979, 5.4932687],
          [100.9135644, 5.4931754],
          [100.9151722, 5.4944964],
          [100.9172874, 5.4953079],
          [100.9184751, 5.4965949],
          [100.9193572, 5.4983231],
          [100.9198108, 5.4989955],
          [100.9205034, 5.5009733],
          [100.9220601, 5.5018325],
          [100.9230924, 5.503257],
          [100.9233737, 5.5040136],
          [100.9256817, 5.5057406],
          [100.9282132, 5.5072193],
          [100.9284448, 5.5078911],
          [100.9290436, 5.5099673],
          [100.9292191, 5.5113647],
          [100.9294074, 5.5127211],
          [100.9295631, 5.5133073],
          [100.9291288, 5.514087],
          [100.9289469, 5.5145404],
          [100.9291054, 5.5149457],
          [100.9301901, 5.5163797],
          [100.9306655, 5.5166532],
          [100.9317209, 5.5168082],
          [100.9339424, 5.5185395],
          [100.9344758, 5.5188754],
          [100.934544, 5.5194924],
          [100.934477, 5.5202025],
          [100.9334853, 5.5211016],
          [100.9324684, 5.5219697],
          [100.9322757, 5.5223058],
          [100.9322328, 5.5228734],
          [100.9324687, 5.523847],
          [100.933027, 5.5249567],
          [100.9333536, 5.5255],
          [100.9334772, 5.5261416],
          [100.9343758, 5.5274143],
          [100.9337761, 5.5297601],
          [100.934159, 5.5317719],
          [100.9343285, 5.533335],
          [100.9351555, 5.5361809],
          [100.9356294, 5.5366051],
          [100.9374476, 5.537206],
          [100.9382391, 5.5377963],
          [100.9402661, 5.5420087],
          [100.9402999, 5.5425339],
          [100.9399756, 5.5445428],
          [100.940351, 5.5449862],
          [100.9412359, 5.5462207],
          [100.9413538, 5.5469186],
          [100.9411924, 5.5485828],
          [100.9411824, 5.5501167],
          [100.941081, 5.5510555],
          [100.9406966, 5.5524327],
          [100.9406696, 5.5541282],
          [100.9405621, 5.5546068],
          [100.9401611, 5.5553991],
          [100.9382872, 5.5570558],
          [100.9380912, 5.5572046],
          [100.9379887, 5.5575713],
          [100.9377513, 5.558001],
          [100.9374916, 5.5583856],
          [100.9373248, 5.5589131],
          [100.9372358, 5.5596019],
          [100.9370177, 5.5598856],
          [100.9362301, 5.5604702],
          [100.9352012, 5.5614995],
          [100.9338129, 5.5634974],
          [100.9336765, 5.5638976],
          [100.9338957, 5.5659138],
          [100.933875, 5.5672285],
          [100.9340429, 5.5681395],
          [100.9343417, 5.5690623],
          [100.9344716, 5.5701304],
          [100.9344881, 5.5714864],
          [100.93458, 5.571839],
          [100.935257, 5.5726023],
          [100.9355989, 5.5730683],
          [100.9361158, 5.5737074],
          [100.9364991, 5.5741136],
          [100.9369839, 5.5743892],
          [100.9379056, 5.5747791],
          [100.9386033, 5.575123],
          [100.9395699, 5.5754944],
          [100.940066, 5.5757663],
          [100.9406096, 5.5762818],
          [100.9411669, 5.5770822],
          [100.9423396, 5.5790576],
          [100.9428273, 5.5795317],
          [100.9432864, 5.5797061],
          [100.9437871, 5.5797719],
          [100.9450506, 5.5798075],
          [100.9453939, 5.5799551],
          [100.9455537, 5.580173],
          [100.9456416, 5.5805929],
          [100.9455761, 5.5810346],
          [100.9453652, 5.5813896],
          [100.9448734, 5.5818631],
          [100.9443899, 5.5821195],
          [100.943839, 5.5824204],
          [100.9436248, 5.582678],
          [100.9434319, 5.5831791],
          [100.9433569, 5.5849393],
          [100.943213, 5.5853544],
          [100.9428783, 5.5858137],
          [100.9421638, 5.5867395],
          [100.9420125, 5.5871583],
          [100.9421318, 5.5875842],
          [100.9423045, 5.5879238],
          [100.9424598, 5.5881855],
          [100.9424855, 5.5884265],
          [100.9423076, 5.5889164],
          [100.9417988, 5.5898394],
          [100.9415534, 5.5903927],
          [100.9412604, 5.590706],
          [100.9407325, 5.5908795],
          [100.9388482, 5.5908602],
          [100.9382581, 5.5907115],
          [100.9369341, 5.589979],
          [100.936426, 5.5898869],
          [100.9352628, 5.5899904],
          [100.9347946, 5.5901981],
          [100.9344155, 5.590526],
          [100.9341522, 5.5908807],
          [100.9340944, 5.5912775],
          [100.9341681, 5.5915363],
          [100.9344267, 5.5922417],
          [100.9344317, 5.5928073],
          [100.9343068, 5.5931476],
          [100.9340065, 5.5934197],
          [100.9337257, 5.5935009],
          [100.9331464, 5.5934496],
          [100.9325975, 5.5933086],
          [100.9322767, 5.5931312],
          [100.9316716, 5.5930011],
          [100.930991, 5.5930431],
          [100.930549, 5.5932172],
          [100.9300463, 5.5936157],
          [100.9298579, 5.5939296],
          [100.9297584, 5.594461],
          [100.9296858, 5.5965134],
          [100.9297012, 5.5972701],
          [100.9296271, 5.5979515],
          [100.9293781, 5.5984711],
          [100.9287993, 5.5991503],
          [100.9275411, 5.6004332],
          [100.9268848, 5.6008873],
          [100.9261404, 5.6018242],
          [100.9257771, 5.6019837],
          [100.9254032, 5.6019708],
          [100.9243535, 5.6017826],
          [100.9237849, 5.60184],
          [100.9232085, 5.601976],
          [100.9225499, 5.6023283],
          [100.9221027, 5.6027764],
          [100.9220244, 5.6029717],
          [100.9226603, 5.6038738],
          [100.9227473, 5.6056032],
          [100.9228814, 5.606867],
          [100.9227625, 5.6081396],
          [100.923287, 5.6095953],
          [100.9235668, 5.610593],
          [100.924056, 5.6115578],
          [100.9244528, 5.6123125],
          [100.9245421, 5.6124065],
          [100.9247604, 5.6129169],
          [100.9249416, 5.613371],
          [100.9249344, 5.6141613],
          [100.924696, 5.6148083],
          [100.9243549, 5.6158368],
          [100.9242089, 5.6167277],
          [100.924284, 5.6175071],
          [100.9244877, 5.6179463],
          [100.9246854, 5.6180633],
          [100.9253913, 5.6182275],
          [100.9260031, 5.6185411],
          [100.9267493, 5.6189003],
          [100.9288017, 5.6198121],
          [100.930952, 5.6214136],
          [100.9334609, 5.6214022],
          [100.9338043, 5.6215311],
          [100.9349296, 5.6224051],
          [100.9353361, 5.6226503],
          [100.93585, 5.6231208],
          [100.9364582, 5.6234007],
          [100.93706, 5.6234258],
          [100.9384434, 5.6234282],
          [100.9389811, 5.6236066],
          [100.9395258, 5.6238787],
          [100.9406843, 5.6248502],
          [100.9411696, 5.6250321],
          [100.9416913, 5.6249958],
          [100.9422249, 5.6248969],
          [100.9426917, 5.6248314],
          [100.9432914, 5.6249072],
          [100.9437306, 5.6253737],
          [100.9441208, 5.6259123],
          [100.9445026, 5.6268775],
          [100.9446912, 5.6272585],
          [100.9449652, 5.6277437],
          [100.9454943, 5.6280889],
          [100.9460621, 5.6283263],
          [100.9468564, 5.6282594],
          [100.9472645, 5.6284083],
          [100.9473882, 5.6286498],
          [100.9474401, 5.6290126],
          [100.947336, 5.6293471],
          [100.9471303, 5.6296839],
          [100.9464845, 5.630153],
          [100.945859, 5.6305831],
          [100.9456705, 5.630768],
          [100.9452979, 5.6312092],
          [100.9451259, 5.6316044],
          [100.9446784, 5.6324274],
          [100.9444078, 5.6328737],
          [100.9442275, 5.6333826],
          [100.9441048, 5.6341595],
          [100.9439927, 5.6346114],
          [100.9437983, 5.6355738],
          [100.9437201, 5.636465],
          [100.9437468, 5.6370972],
          [100.9435647, 5.6375118],
          [100.9432625, 5.6376847],
          [100.9428047, 5.6378368],
          [100.9423642, 5.6380256],
          [100.9420608, 5.6382401],
          [100.9418951, 5.6385519],
          [100.9417837, 5.6388906],
          [100.941888, 5.6397482],
          [100.9420922, 5.6399509],
          [100.9421811, 5.6402397],
          [100.9421269, 5.6405901],
          [100.9419241, 5.6415378],
          [100.9416926, 5.6420724],
          [100.9415386, 5.6425155],
          [100.9413452, 5.6428063],
          [100.9410818, 5.6430599],
          [100.940743, 5.6433746],
          [100.9403859, 5.6436979],
          [100.9401165, 5.6439141],
          [100.9398943, 5.6442897],
          [100.9397269, 5.6446997],
          [100.939573, 5.6449579],
          [100.9393538, 5.6451334],
          [100.9390187, 5.6453837],
          [100.9387503, 5.6455546],
          [100.9385833, 5.6457193],
          [100.9383835, 5.6460294],
          [100.9383855, 5.6463122],
          [100.9385245, 5.6466207],
          [100.9387409, 5.6468378],
          [100.9392331, 5.6471325],
          [100.9400307, 5.6475596],
          [100.9406599, 5.6477518],
          [100.9410681, 5.6479648],
          [100.9411662, 5.6483436],
          [100.9412963, 5.648879],
          [100.9414414, 5.6492889],
          [100.9417242, 5.6499207],
          [100.941917, 5.6501203],
          [100.9422737, 5.6502912],
          [100.9425049, 5.6501905],
          [100.9425301, 5.6499211],
          [100.9422408, 5.6493689],
          [100.9421732, 5.6489958],
          [100.9422282, 5.6487209],
          [100.9424758, 5.6485814],
          [100.9429861, 5.6484261],
          [100.9432591, 5.648519],
          [100.9437494, 5.6485951],
          [100.943739, 5.6485467],
          [100.9448276, 5.6482395],
          [100.9452421, 5.6479316],
          [100.9460783, 5.6476849],
          [100.9465574, 5.6476371],
          [100.9469542, 5.6477226],
          [100.9473906, 5.6480923],
          [100.947805, 5.6482823],
          [100.9482586, 5.648436],
          [100.9486849, 5.6483235],
          [100.949704, 5.6473],
          [100.9503648, 5.6466414],
          [100.9509081, 5.6460956],
          [100.9512241, 5.6455899],
          [100.9514076, 5.6450733],
          [100.9518454, 5.6439001],
          [100.9521912, 5.6433888],
          [100.9524585, 5.6429501],
          [100.9528812, 5.642653],
          [100.9535601, 5.6424837],
          [100.9542352, 5.6422506],
          [100.9547216, 5.6421986],
          [100.9552236, 5.6421569],
          [100.955927, 5.6421569],
          [100.956611, 5.6423531],
          [100.9568847, 5.6426014],
          [100.9578809, 5.6434903],
          [100.9583389, 5.6448599],
          [100.9589917, 5.6464271],
          [100.9596695, 5.647692],
          [100.9599377, 5.6480364],
          [100.9601465, 5.6481379],
          [100.960468, 5.6482195],
          [100.9619896, 5.6470684],
          [100.9624206, 5.6466189],
          [100.9637824, 5.6464312],
          [100.9645665, 5.6466339],
          [100.9651876, 5.6474186],
          [100.9659132, 5.6484829],
          [100.9663069, 5.6504175],
          [100.9664387, 5.6526829],
          [100.9664481, 5.6537704],
          [100.9661864, 5.6551486],
          [100.9664818, 5.6566573],
          [100.9664471, 5.6573141],
          [100.9661421, 5.658379],
          [100.9661302, 5.6586913],
          [100.966118, 5.6590897],
          [100.9662556, 5.6596717],
          [100.9662293, 5.6601228],
          [100.9662169, 5.6603701],
          [100.9661993, 5.6604678],
          [100.9659456, 5.6607373],
          [100.9657064, 5.660941],
          [100.9656168, 5.6610193],
          [100.9655733, 5.6610598],
          [100.9654733, 5.6611209],
          [100.9652639, 5.6612567],
          [100.9650761, 5.6615164],
          [100.9650513, 5.6616383],
          [100.965227, 5.6617258],
          [100.9654411, 5.661675],
          [100.9658071, 5.6615516],
          [100.9659291, 5.6614924],
          [100.9662275, 5.6613552],
          [100.9665744, 5.6612805],
          [100.9666909, 5.6613337],
          [100.966826, 5.6614047],
          [100.9671878, 5.6617044],
          [100.9673912, 5.6623454],
          [100.9674978, 5.6627852],
          [100.9675738, 5.6629343],
          [100.967719, 5.6632061],
          [100.9678265, 5.6634018],
          [100.9680743, 5.6637662],
          [100.9681969, 5.6642983],
          [100.9683888, 5.6651073],
          [100.9685917, 5.6654844],
          [100.9689411, 5.6658264],
          [100.9693267, 5.665984],
          [100.9695493, 5.6661623],
          [100.9696796, 5.666358],
          [100.9696461, 5.666798],
          [100.9696698, 5.6672326],
          [100.9696115, 5.6676441],
          [100.9695853, 5.6680831],
          [100.969431, 5.668622],
          [100.9692202, 5.6690933],
          [100.9691804, 5.6693167],
          [100.9692233, 5.6696065],
          [100.9692396, 5.6696947],
          [100.9697312, 5.6708997],
          [100.9698152, 5.6711662],
          [100.9697128, 5.6714028],
          [100.9696354, 5.6715483],
          [100.9693098, 5.6721102],
          [100.9689847, 5.6725981],
          [100.9686624, 5.6730081],
          [100.9683347, 5.6733816],
          [100.9679133, 5.6736069],
          [100.9671834, 5.6741348],
          [100.9670984, 5.6742781],
          [100.9675163, 5.6750048],
          [100.9680652, 5.6764479],
          [100.9689321, 5.6764284],
          [100.9697447, 5.6766515],
          [100.9703208, 5.6770529],
          [100.9707422, 5.6774619],
          [100.9709122, 5.6776988],
          [100.970992, 5.6781228],
          [100.9710146, 5.6785874],
          [100.9709631, 5.6793206],
          [100.9708384, 5.6800535],
          [100.9708129, 5.6807055],
          [100.9708374, 5.6814117],
          [100.971034, 5.6820281],
          [100.9715192, 5.6827994],
          [100.9721134, 5.6833992],
          [100.9729612, 5.6838731],
          [100.9736648, 5.683967],
          [100.9742258, 5.6839691],
          [100.9746427, 5.6838854],
          [100.9759099, 5.6837624],
          [100.9765134, 5.6837732],
          [100.9757777, 5.6850223],
          [100.9756044, 5.6858988],
          [100.9754156, 5.686375],
          [100.9752435, 5.6869534],
          [100.9751911, 5.687328],
          [100.9753007, 5.6878516],
          [100.9754937, 5.6885251],
          [100.9754418, 5.6887633],
          [100.9750391, 5.6896134],
          [100.9747402, 5.6899785],
          [100.9744578, 5.6904969],
          [100.9742611, 5.6907943],
          [100.9735448, 5.6913792],
          [100.9732732, 5.69167],
          [100.9731363, 5.6921018],
          [100.9731213, 5.6926703],
          [100.9733804, 5.6928522],
          [100.972915, 5.695655],
          [100.9736821, 5.6964102],
          [100.9740395, 5.6967932],
          [100.9747101, 5.6978974],
          [100.9748172, 5.6982496],
          [100.9753549, 5.6995956],
          [100.9754527, 5.699854],
          [100.9767089, 5.7010525],
          [100.9767613, 5.7012419],
          [100.9767865, 5.7018705],
          [100.9767394, 5.7022921],
          [100.9767725, 5.7029279],
          [100.9769237, 5.7043642],
          [100.9770265, 5.7045771],
          [100.9773661, 5.704912],
          [100.977725, 5.7055779],
          [100.9786091, 5.7076998],
          [100.9785417, 5.7083565],
          [100.9785094, 5.7087735],
          [100.9783813, 5.7090979],
          [100.9769755, 5.7101231],
          [100.9766874, 5.7103512],
          [100.9765174, 5.7109271],
          [100.976594, 5.712247],
          [100.9766764, 5.7124988],
          [100.9771474, 5.7129544],
          [100.9781472, 5.7136961],
          [100.9775055, 5.7156843],
          [100.9775116, 5.7160499],
          [100.9779035, 5.7168308],
          [100.9805107, 5.7198221],
          [100.9811325, 5.7221184],
          [100.9811092, 5.7222327],
          [100.9810557, 5.7222859],
          [100.9809414, 5.7222931],
          [100.9799794, 5.7223248],
          [100.9787147, 5.7224056],
          [100.9754334, 5.7224637],
          [100.9741701, 5.7248219],
          [100.9737389, 5.7254916],
          [100.9734102, 5.7258073],
          [100.9730736, 5.7259718],
          [100.972073, 5.726328],
          [100.9706309, 5.7268128],
          [100.9688532, 5.7273104],
          [100.9666726, 5.7282312],
          [100.9665493, 5.7283138],
          [100.965422, 5.7287403],
          [100.9647785, 5.72885],
          [100.9643311, 5.7288343],
          [100.9638525, 5.7287765],
          [100.9629946, 5.7286642],
          [100.9626117, 5.7286865],
          [100.9622041, 5.7288687],
          [100.9618622, 5.7292093],
          [100.9612777, 5.7298471],
          [100.9605491, 5.7305347],
          [100.960117, 5.7306844],
          [100.9594973, 5.7306525],
          [100.9593575, 5.7307097],
          [100.9591985, 5.731085],
          [100.9591222, 5.732462],
          [100.9589259, 5.7343082],
          [100.9586374, 5.7353098],
          [100.9577295, 5.7371927],
          [100.9555619, 5.7400459],
          [100.9549956, 5.7406686],
          [100.9544492, 5.741322],
          [100.9541146, 5.741564],
          [100.9535158, 5.7417225],
          [100.952586, 5.7420064],
          [100.9516532, 5.7423881],
          [100.9506864, 5.7427725],
          [100.9494727, 5.7432434],
          [100.9485767, 5.7436489],
          [100.9484011, 5.7438581],
          [100.9482497, 5.7442601],
          [100.9482128, 5.7447259],
          [100.9482324, 5.7455549],
          [100.9484322, 5.7470071],
          [100.9487902, 5.7485695],
          [100.9491016, 5.7495695],
          [100.9492751, 5.7505275],
          [100.9496469, 5.7516423],
          [100.9500097, 5.752561],
          [100.9502382, 5.7535659],
          [100.9502009, 5.7539225],
          [100.9500638, 5.7542233],
          [100.949855, 5.7546574],
          [100.9493309, 5.7554396],
          [100.9481253, 5.757112],
          [100.9475029, 5.7581585],
          [100.9464654, 5.7599905],
          [100.9463012, 5.7601925],
          [100.9457064, 5.7605811],
          [100.945436, 5.7607206],
          [100.9443742, 5.7613714],
          [100.9435988, 5.7618113],
          [100.942735, 5.7623765],
          [100.941872, 5.7627273],
          [100.9416675, 5.7628806],
          [100.941711, 5.763309],
          [100.9419791, 5.7636741],
          [100.9423413, 5.7640529],
          [100.9429709, 5.7644731],
          [100.943918, 5.7653028],
          [100.9441168, 5.7655522],
          [100.9443306, 5.7660685],
          [100.9446432, 5.766655],
          [100.9451537, 5.7671634],
          [100.9460331, 5.7677156],
          [100.9467986, 5.7681866],
          [100.9476635, 5.7685094],
          [100.9486294, 5.768797],
          [100.9496933, 5.7691095],
          [100.9509866, 5.7697048],
          [100.9516391, 5.7701586],
          [100.9524935, 5.7708052],
          [100.9530875, 5.7713689],
          [100.9533786, 5.7714965],
          [100.9544829, 5.772008],
          [100.9550498, 5.7725156],
          [100.9554357, 5.7729556],
          [100.955439, 5.7729839],
          [100.9558766, 5.7730601],
          [100.9562962, 5.7730841],
          [100.956626, 5.7731709],
          [100.957023, 5.7732206],
          [100.9573791, 5.7732815],
          [100.9578492, 5.7734837],
          [100.9584161, 5.7738503],
          [100.9587166, 5.7741441],
          [100.9590024, 5.7743999],
          [100.9592738, 5.7747456],
          [100.9593512, 5.7749112],
          [100.9595538, 5.7752819],
          [100.9597158, 5.7756245],
          [100.9598042, 5.7758032],
          [100.960052, 5.77624],
          [100.9603334, 5.7764431],
          [100.9608254, 5.7766361],
          [100.9612284, 5.7768479],
          [100.9616737, 5.7768583],
          [100.9619261, 5.7768295],
          [100.9622683, 5.7766226],
          [100.9624917, 5.7764302],
          [100.9629236, 5.7759939],
          [100.9632763, 5.7755179],
          [100.9637468, 5.7751416],
          [100.963919, 5.7750752],
          [100.9643672, 5.7750842],
          [100.9648443, 5.7750985],
          [100.9655407, 5.7751906],
          [100.9662387, 5.7752301],
          [100.9666746, 5.7752046],
          [100.9671725, 5.7751838],
          [100.9676888, 5.7751417],
          [100.9677872, 5.7751448],
          [100.9683607, 5.7750247],
          [100.9686282, 5.7749365],
          [100.9688902, 5.7748361],
          [100.9692348, 5.7747579],
          [100.9695694, 5.774741],
          [100.9698833, 5.7749716],
          [100.970106, 5.7751949],
          [100.9703112, 5.7754801],
          [100.9706567, 5.7759955],
          [100.9710336, 5.7762962],
          [100.9714867, 5.7761949],
          [100.97194, 5.7760317],
          [100.9730324, 5.7755411],
          [100.9733418, 5.7753362],
          [100.9736879, 5.7750892],
          [100.9742889, 5.7746036],
          [100.9747418, 5.7741078],
          [100.9750937, 5.7737566],
          [100.9754455, 5.7734509],
          [100.9757436, 5.7731799],
          [100.976195, 5.7730699],
          [100.9765915, 5.7730251],
          [100.9769505, 5.7730289],
          [100.9774353, 5.7732262],
          [100.9779349, 5.7735546],
          [100.97836, 5.7740293],
          [100.9787079, 5.7744386],
          [100.978918, 5.7749616],
          [100.9790009, 5.7753631],
          [100.9791693, 5.7757043],
          [100.9796404, 5.7756494],
          [100.9798978, 5.7755218],
          [100.9800981, 5.7752553],
          [100.9808831, 5.7750264],
          [100.9814864, 5.7749263],
          [100.9822551, 5.775043],
          [100.9830489, 5.7754562],
          [100.9836363, 5.7761101],
          [100.9839958, 5.7766583],
          [100.9845311, 5.7773657],
          [100.9850285, 5.7777132],
          [100.9856144, 5.7783482],
          [100.9860322, 5.7790504],
          [100.9866332, 5.779786],
          [100.9872793, 5.7804087],
          [100.9876988, 5.7809972],
          [100.9882722, 5.7820228],
          [100.9887791, 5.7827515],
          [100.9894814, 5.7839133],
          [100.989724, 5.783754],
          [100.990622, 5.78356],
          [100.991616, 5.783242],
          [100.992266, 5.782942],
          [100.992514, 5.782907],
          [100.992725, 5.78303],
          [100.992935, 5.78326],
          [100.993164, 5.783736],
          [100.993432, 5.784566],
          [100.99368, 5.784972],
          [100.993986, 5.785219],
          [100.994579, 5.785413],
          [100.995095, 5.785466],
          [100.995554, 5.785395],
          [100.99584, 5.785201],
          [100.996414, 5.78326],
          [100.997465, 5.780878],
          [100.998, 5.779978],
          [100.998402, 5.779607],
          [100.998975, 5.779201],
          [100.999376, 5.779042],
          [100.999739, 5.77906],
          [101.000007, 5.779219],
          [101.000619, 5.779766],
          [101.001211, 5.780172],
          [101.001498, 5.78026],
          [101.002014, 5.78026],
          [101.002606, 5.780189],
          [101.002931, 5.780101],
          [101.003122, 5.779995],
          [101.00318, 5.779871],
          [101.003256, 5.778742],
          [101.00339, 5.778089],
          [101.004231, 5.77636],
          [101.005091, 5.775195],
          [101.005473, 5.774789],
          [101.005817, 5.774613],
          [101.0062, 5.774648],
          [101.006983, 5.774842],
          [101.007193, 5.774825],
          [101.007365, 5.774666],
          [101.007385, 5.774472],
          [101.007289, 5.77419],
          [101.006811, 5.773184],
          [101.006544, 5.772196],
          [101.006505, 5.77179],
          [101.006601, 5.771084],
          [101.00683, 5.770519],
          [101.007136, 5.770078],
          [101.007537, 5.769848],
          [101.008321, 5.769636],
          [101.008665, 5.769619],
          [101.009124, 5.769707],
          [101.009793, 5.769672],
          [101.010729, 5.769583],
          [101.011494, 5.769442],
          [101.011895, 5.769283],
          [101.012583, 5.768825],
          [101.013138, 5.768595],
          [101.013596, 5.768525],
          [101.013979, 5.768401],
          [101.014323, 5.768189],
          [101.014628, 5.767907],
          [101.014858, 5.767607],
          [101.015164, 5.767395],
          [101.01545, 5.767272],
          [101.015775, 5.767236],
          [101.016158, 5.767342],
          [101.016368, 5.767501],
          [101.016693, 5.767607],
          [101.017018, 5.767589],
          [101.017706, 5.767307],
          [101.017935, 5.766989],
          [101.018432, 5.766125],
          [101.018623, 5.765595],
          [101.018795, 5.764713],
          [101.018948, 5.764395],
          [101.019158, 5.764166],
          [101.01935, 5.763707],
          [101.019464, 5.763284],
          [101.019235, 5.762155],
          [101.019101, 5.761113],
          [101.019197, 5.759384],
          [101.019445, 5.758696],
          [101.019751, 5.758255],
          [101.019904, 5.757866],
          [101.020248, 5.757302],
          [101.020535, 5.757019],
          [101.021146, 5.756543],
          [101.021643, 5.756066],
          [101.021777, 5.755784],
          [101.021815, 5.754549],
          [101.021968, 5.75409],
          [101.022293, 5.753755],
          [101.023019, 5.753579],
          [101.023841, 5.753014],
          [101.024051, 5.752785],
          [101.024434, 5.752061],
          [101.024606, 5.751937],
          [101.02495, 5.751832],
          [101.025447, 5.751849],
          [101.025695, 5.751779],
          [101.025905, 5.751585],
          [101.02667, 5.750808],
          [101.027282, 5.749996],
          [101.027397, 5.749643],
          [101.027339, 5.749237],
          [101.027473, 5.748884],
          [101.027683, 5.74869],
          [101.028047, 5.748231],
          [101.0286796, 5.7477127],
          [101.0289213, 5.7474071],
          [101.0290874, 5.7471517],
          [101.0292012, 5.746767],
          [101.0291858, 5.7464147],
          [101.0291104, 5.7462842],
          [101.0289539, 5.745981],
          [101.0290258, 5.7453337],
          [101.0289747, 5.7449143],
          [101.0292157, 5.7446548],
          [101.0294895, 5.7445678],
          [101.0299679, 5.7448074],
          [101.0301357, 5.7446839],
          [101.0300702, 5.7437503],
          [101.0302225, 5.7432997],
          [101.0304466, 5.7428595],
          [101.0307232, 5.7425964],
          [101.0320772, 5.741977],
          [101.032819, 5.7415557],
          [101.0328485, 5.7412018],
          [101.0326562, 5.7409292],
          [101.0324085, 5.7407312],
          [101.0321551, 5.7403606],
          [101.0320976, 5.7401896],
          [101.032806, 5.7397575],
          [101.0329323, 5.7395523],
          [101.0337501, 5.7385596],
          [101.0339222, 5.7379501],
          [101.0344597, 5.7377671],
          [101.0346165, 5.7377745],
          [101.0347828, 5.737831],
          [101.0349473, 5.7377287],
          [101.0355558, 5.7375579],
          [101.0358225, 5.7373914],
          [101.0360744, 5.7370499],
          [101.0364937, 5.7368616],
          [101.0368809, 5.7368666],
          [101.0373853, 5.7366587],
          [101.0374553, 5.7365328],
          [101.0375552, 5.7366226],
          [101.0379837, 5.7374213],
          [101.0395017, 5.7387711],
          [101.0402236, 5.7398461],
          [101.0498686, 5.7398768],
          [101.0515775, 5.7406657],
          [101.0521419, 5.7415159],
          [101.0525565, 5.7423166],
          [101.0527148, 5.7425762],
          [101.0530191, 5.7427741],
          [101.0538695, 5.7438623],
          [101.0543335, 5.7440256],
          [101.0544806, 5.7438552],
          [101.0544871, 5.7434557],
          [101.0545066, 5.7428387],
          [101.0546083, 5.742479],
          [101.0547522, 5.742361],
          [101.0549788, 5.7423497],
          [101.0551273, 5.7424811],
          [101.0553043, 5.7425426],
          [101.0555856, 5.7427409],
          [101.0558534, 5.7432817],
          [101.0560777, 5.7436009],
          [101.0565322, 5.7440276],
          [101.0568457, 5.7441089],
          [101.0570771, 5.744299],
          [101.0575041, 5.74397],
          [101.0583254, 5.7440259],
          [101.059265, 5.743789],
          [101.0601956, 5.7443273],
          [101.0602248, 5.7441555],
          [101.0606604, 5.7435146],
          [101.0608806, 5.7433312],
          [101.0612346, 5.7433434],
          [101.0614384, 5.7432137],
          [101.0616248, 5.7432732],
          [101.0616302, 5.7430918],
          [101.0619814, 5.7422212],
          [101.0615976, 5.7416414],
          [101.0611316, 5.7412086],
          [101.0607038, 5.7409594],
          [101.0605514, 5.7407835],
          [101.0604778, 5.7405617],
          [101.0602884, 5.7402009],
          [101.0603087, 5.73976],
          [101.0605129, 5.7391696],
          [101.0606871, 5.7389898],
          [101.0608747, 5.738654],
          [101.0605514, 5.7378633],
          [101.0605941, 5.7376717],
          [101.0608515, 5.7371971],
          [101.0608695, 5.7368407],
          [101.0606013, 5.7368205],
          [101.0604743, 5.7366865],
          [101.0602699, 5.7361209],
          [101.0602742, 5.7355839],
          [101.0602734, 5.735301],
          [101.0594571, 5.7347359],
          [101.0591591, 5.734061],
          [101.0595983, 5.7332273],
          [101.0597632, 5.7329043],
          [101.0602938, 5.733036],
          [101.0603792, 5.7327638],
          [101.0605466, 5.7326146],
          [101.0609034, 5.7325944],
          [101.0610119, 5.7320557],
          [101.0611698, 5.7317596],
          [101.0615481, 5.7316329],
          [101.0622804, 5.7319018],
          [101.0626964, 5.7315674],
          [101.063365, 5.7312496],
          [101.0633012, 5.7308571],
          [101.0625425, 5.7304768],
          [101.0618875, 5.7299105],
          [101.0619091, 5.7291759],
          [101.0622543, 5.7288115],
          [101.0623733, 5.7284839],
          [101.0625338, 5.7282119],
          [101.0626923, 5.728089],
          [101.0624512, 5.7276796],
          [101.0624204, 5.727407],
          [101.0622731, 5.7269943],
          [101.0622492, 5.7266703],
          [101.0627095, 5.7264167],
          [101.0635938, 5.7262006],
          [101.0640023, 5.7258782],
          [101.0645272, 5.7266695],
          [101.0645651, 5.7269195],
          [101.0650811, 5.7268056],
          [101.0655655, 5.7260911],
          [101.0656294, 5.7259489],
          [101.0658576, 5.7258066],
          [101.0661658, 5.725769],
          [101.0668136, 5.7264194],
          [101.0670874, 5.7273116],
          [101.0676982, 5.7282622],
          [101.0680074, 5.7283063],
          [101.0682609, 5.7284165],
          [101.0690551, 5.7276244],
          [101.0697846, 5.7272595],
          [101.070018, 5.7272401],
          [101.0703965, 5.7270647],
          [101.0705099, 5.726863],
          [101.0711211, 5.7268076],
          [101.0714794, 5.7264369],
          [101.0721517, 5.7264768],
          [101.0723101, 5.7265177],
          [101.0727088, 5.7262263],
          [101.0727921, 5.7259516],
          [101.0732239, 5.725534],
          [101.0737103, 5.725538],
          [101.0741064, 5.7257434],
          [101.0744446, 5.725877],
          [101.0747233, 5.7255268],
          [101.0751843, 5.7254063],
          [101.0753367, 5.7252347],
          [101.0755723, 5.7250799],
          [101.0758275, 5.7249877],
          [101.0761747, 5.7249383],
          [101.0763418, 5.7246382],
          [101.0767237, 5.7240157],
          [101.0770616, 5.7237713],
          [101.0775541, 5.7236035],
          [101.0778315, 5.7233538],
          [101.0784014, 5.722558],
          [101.078718, 5.7219411],
          [101.0799119, 5.7213769],
          [101.08058, 5.7211535],
          [101.0813141, 5.7211031],
          [101.0817272, 5.7210393],
          [101.0823938, 5.7210864],
          [101.0826368, 5.7206842],
          [101.0829491, 5.7204303],
          [101.0831175, 5.7202022],
          [101.0831176, 5.7200052],
          [101.0827457, 5.719112],
          [101.0825336, 5.7186663],
          [101.0822524, 5.7183424],
          [101.0816859, 5.7179981],
          [101.0814447, 5.7178002],
          [101.0809137, 5.717111],
          [101.0808026, 5.717001],
          [101.08049, 5.7168141],
          [101.08032, 5.7164553],
          [101.0800618, 5.7161223],
          [101.080059, 5.7158151],
          [101.0803393, 5.715726],
          [101.0808317, 5.7159025],
          [101.0810951, 5.7159599],
          [101.0813495, 5.7155958],
          [101.0811752, 5.7152668],
          [101.0812098, 5.7148339],
          [101.0810659, 5.7145724],
          [101.0807153, 5.7142453],
          [101.0801888, 5.713684],
          [101.0801806, 5.7133089],
          [101.0804177, 5.7128723],
          [101.0808079, 5.7124725],
          [101.0809607, 5.7117835],
          [101.0802361, 5.7112525],
          [101.080151, 5.7109233],
          [101.08084, 5.7098476],
          [101.0813507, 5.7096453],
          [101.0816718, 5.7095843],
          [101.0820179, 5.7093862],
          [101.0824773, 5.7093852],
          [101.0828151, 5.7098623],
          [101.0832501, 5.7106432],
          [101.0832996, 5.711028],
          [101.083656, 5.7112105],
          [101.083741, 5.7116523],
          [101.0838449, 5.7117694],
          [101.0843225, 5.7118246],
          [101.0846796, 5.711987],
          [101.0850936, 5.7119974],
          [101.085322, 5.7118967],
          [101.0860174, 5.7118629],
          [101.0861496, 5.7117616],
          [101.0863109, 5.7113126],
          [101.0862218, 5.7108577],
          [101.0860073, 5.710399],
          [101.0858379, 5.7097285],
          [101.0851076, 5.7083428],
          [101.0854232, 5.7082252],
          [101.0857095, 5.707643],
          [101.085865, 5.707381],
          [101.086152, 5.707151],
          [101.086668, 5.706781],
          [101.087891, 5.706181],
          [101.088771, 5.705352],
          [101.089153, 5.704734],
          [101.089382, 5.703905],
          [101.08944, 5.703746],
          [101.089535, 5.70364],
          [101.089784, 5.703675],
          [101.091045, 5.704275],
          [101.091733, 5.704416],
          [101.092058, 5.704381],
          [101.092307, 5.704293],
          [101.093262, 5.704099],
          [101.093492, 5.704011],
          [101.093988, 5.703622],
          [101.094504, 5.703499],
          [101.095116, 5.703499],
          [101.095345, 5.703428],
          [101.095498, 5.703269],
          [101.095747, 5.702811],
          [101.096244, 5.701611],
          [101.096569, 5.701028],
          [101.096779, 5.700234],
          [101.096779, 5.699881],
          [101.096569, 5.699352],
          [101.095842, 5.698628],
          [101.095326, 5.698258],
          [101.095097, 5.697922],
          [101.094963, 5.697305],
          [101.095078, 5.696564],
          [101.095173, 5.69607],
          [101.095441, 5.695735],
          [101.096301, 5.695029],
          [101.096951, 5.694729],
          [101.09718, 5.694535],
          [101.097257, 5.694376],
          [101.097314, 5.693864],
          [101.097582, 5.693229],
          [101.097582, 5.69307],
          [101.097486, 5.692823],
          [101.097199, 5.692435],
          [101.097123, 5.692241],
          [101.09718, 5.692064],
          [101.097639, 5.691217],
          [101.097926, 5.690847],
          [101.098423, 5.690406],
          [101.098958, 5.689823],
          [101.099379, 5.689541],
          [101.100124, 5.689294],
          [101.100602, 5.6888],
          [101.100984, 5.688553],
          [101.101577, 5.6885],
          [101.102246, 5.688588],
          [101.102704, 5.688711],
          [101.102934, 5.688676],
          [101.103067, 5.68857],
          [101.103507, 5.687653],
          [101.103717, 5.686206],
          [101.103775, 5.685235],
          [101.103985, 5.684776],
          [101.104138, 5.684141],
          [101.104233, 5.683947],
          [101.104444, 5.683859],
          [101.106221, 5.683453],
          [101.108801, 5.682147],
          [101.109547, 5.681847],
          [101.110044, 5.681583],
          [101.110579, 5.681424],
          [101.110999, 5.68123],
          [101.111363, 5.680947],
          [101.11184, 5.680242],
          [101.112223, 5.679359],
          [101.112662, 5.678601],
          [101.113599, 5.677771],
          [101.113924, 5.677542],
          [101.114134, 5.677348],
          [101.114229, 5.677171],
          [101.114325, 5.676748],
          [101.114344, 5.676324],
          [101.114229, 5.675989],
          [101.114019, 5.675689],
          [101.113981, 5.675512],
          [101.114058, 5.675318],
          [101.11423, 5.675142],
          [101.115013, 5.674789],
          [101.115396, 5.67456],
          [101.115931, 5.673977],
          [101.11616, 5.673607],
          [101.116313, 5.673254],
          [101.116504, 5.673042],
          [101.116772, 5.672777],
          [101.117154, 5.672513],
          [101.117651, 5.672089],
          [101.117957, 5.671736],
          [101.118186, 5.671365],
          [101.118358, 5.671171],
          [101.118913, 5.670801],
          [101.119046, 5.670642],
          [101.119199, 5.670307],
          [101.119505, 5.669142],
          [101.119582, 5.667448],
          [101.119524, 5.66699],
          [101.11941, 5.666725],
          [101.119046, 5.66616],
          [101.11897, 5.665878],
          [101.119008, 5.665684],
          [101.119123, 5.665507],
          [101.119639, 5.665225],
          [101.119773, 5.665084],
          [101.119849, 5.664925],
          [101.120002, 5.664378],
          [101.119945, 5.663231],
          [101.119983, 5.663019],
          [101.120652, 5.662454],
          [101.121378, 5.662031],
          [101.122009, 5.661784],
          [101.123118, 5.661713],
          [101.123328, 5.661607],
          [101.123462, 5.661484],
          [101.123557, 5.66106],
          [101.123576, 5.660584],
          [101.123557, 5.660196],
          [101.123462, 5.659684],
          [101.123519, 5.659402],
          [101.123481, 5.659014],
          [101.123213, 5.65822],
          [101.123232, 5.65762],
          [101.123596, 5.656296],
          [101.123825, 5.65589],
          [101.124532, 5.655008],
          [101.125029, 5.653949],
          [101.125947, 5.651196],
          [101.126195, 5.649696],
          [101.126329, 5.649202],
          [101.126367, 5.648849],
          [101.12631, 5.648408],
          [101.126157, 5.648019],
          [101.125889, 5.647561],
          [101.125316, 5.64682],
          [101.125239, 5.646661],
          [101.125258, 5.646538],
          [101.125488, 5.646149],
          [101.126119, 5.644738],
          [101.126386, 5.644297],
          [101.126788, 5.643908],
          [101.127533, 5.643326],
          [101.127801, 5.642973],
          [101.128775, 5.641438],
          [101.128871, 5.641191],
          [101.128947, 5.640573],
          [101.129081, 5.640203],
          [101.129654, 5.639409],
          [101.129769, 5.639161],
          [101.129845, 5.638809],
          [101.129845, 5.638261],
          [101.129693, 5.637256],
          [101.12931, 5.636709],
          [101.128852, 5.636409],
          [101.128794, 5.636232],
          [101.128852, 5.635985],
          [101.129253, 5.635667],
          [101.129425, 5.635438],
          [101.129387, 5.635279],
          [101.129253, 5.635067],
          [101.128565, 5.634238],
          [101.12845, 5.634044],
          [101.128584, 5.633268],
          [101.128565, 5.633003],
          [101.128278, 5.632121],
          [101.128278, 5.631927],
          [101.128355, 5.631697],
          [101.128584, 5.631415],
          [101.12866, 5.631203],
          [101.128775, 5.630586],
          [101.128852, 5.629456],
          [101.128928, 5.629156],
          [101.129922, 5.627604],
          [101.130018, 5.627092],
          [101.12996, 5.625874],
          [101.130018, 5.625662],
          [101.130381, 5.624921],
          [101.130572, 5.624621],
          [101.130667, 5.624339],
          [101.130763, 5.623527],
          [101.130878, 5.623333],
          [101.131126, 5.623086],
          [101.131375, 5.622751],
          [101.1317, 5.622239],
          [101.131757, 5.621992],
          [101.131757, 5.620881],
          [101.131852, 5.620422],
          [101.131967, 5.620228],
          [101.132483, 5.619839],
          [101.13277, 5.619557],
          [101.133267, 5.618887],
          [101.133362, 5.61834],
          [101.133267, 5.616769],
          [101.133114, 5.61571],
          [101.132846, 5.61474],
          [101.133095, 5.614192],
          [101.133916, 5.613469],
          [101.134643, 5.61324],
          [101.135102, 5.613045],
          [101.135866, 5.612851],
          [101.136803, 5.612993],
          [101.137548, 5.613257],
          [101.138255, 5.613593],
          [101.138485, 5.61384],
          [101.138638, 5.614087],
          [101.138867, 5.614298],
          [101.139307, 5.614475],
          [101.139746, 5.61451],
          [101.140606, 5.614316],
          [101.141103, 5.614069],
          [101.141696, 5.613575],
          [101.141963, 5.613522],
          [101.142307, 5.613522],
          [101.142575, 5.613645],
          [101.14311, 5.614157],
          [101.143512, 5.614369],
          [101.143856, 5.61444],
          [101.144754, 5.614845],
          [101.144945, 5.614987],
          [101.144983, 5.615128],
          [101.144907, 5.615516],
          [101.144525, 5.616698],
          [101.144563, 5.61691],
          [101.144773, 5.617192],
          [101.144888, 5.617475],
          [101.144869, 5.619574],
          [101.144926, 5.619945],
          [101.14506, 5.620333],
          [101.144964, 5.620792],
          [101.144353, 5.62201],
          [101.144353, 5.62231],
          [101.144429, 5.622645],
          [101.144582, 5.622927],
          [101.145003, 5.623227],
          [101.145423, 5.623404],
          [101.147812, 5.623721],
          [101.148309, 5.623969],
          [101.148596, 5.624057],
          [101.148902, 5.624074],
          [101.149265, 5.624004],
          [101.149724, 5.623827],
          [101.150545, 5.623051],
          [101.150928, 5.622804],
          [101.151922, 5.622574],
          [101.152285, 5.62261],
          [101.15261, 5.622733],
          [101.153279, 5.623121],
          [101.155018, 5.623986],
          [101.155247, 5.624427],
          [101.155305, 5.624727],
          [101.155438, 5.624974],
          [101.155668, 5.625186],
          [101.156165, 5.625433],
          [101.156776, 5.625998],
          [101.157044, 5.626651],
          [101.157331, 5.627039],
          [101.158516, 5.627692],
          [101.159586, 5.628221],
          [101.160064, 5.628345],
          [101.160332, 5.628521],
          [101.160733, 5.629298],
          [101.160867, 5.629368],
          [101.161249, 5.629509],
          [101.161937, 5.629651],
          [101.162415, 5.62988],
          [101.163753, 5.630145],
          [101.164059, 5.630303],
          [101.164346, 5.630498],
          [101.164976, 5.63108],
          [101.165875, 5.632033],
          [101.166926, 5.633303],
          [101.167079, 5.633374],
          [101.167461, 5.633444],
          [101.167614, 5.63355],
          [101.168378, 5.634732],
          [101.168799, 5.635209],
          [101.169162, 5.635703],
          [101.1692, 5.635915],
          [101.168378, 5.636779],
          [101.167843, 5.637521],
          [101.167327, 5.638421],
          [101.167289, 5.638668],
          [101.167308, 5.638932],
          [101.16769, 5.640238],
          [101.167652, 5.640503],
          [101.167232, 5.641526],
          [101.167327, 5.641791],
          [101.168073, 5.642408],
          [101.168283, 5.642708],
          [101.169047, 5.643485],
          [101.169908, 5.643679],
          [101.170194, 5.643891],
          [101.170481, 5.644244],
          [101.171093, 5.64562],
          [101.171456, 5.646114],
          [101.171953, 5.646643],
          [101.172163, 5.647437],
          [101.172335, 5.647579],
          [101.173463, 5.647561],
          [101.174151, 5.647314],
          [101.174457, 5.647279],
          [101.175508, 5.647402],
          [101.176349, 5.647367],
          [101.176827, 5.64749],
          [101.177955, 5.647914],
          [101.178662, 5.648249],
          [101.179675, 5.648849],
          [101.180363, 5.649184],
          [101.180764, 5.649326],
          [101.181127, 5.649308],
          [101.181491, 5.649184],
          [101.181758, 5.649149],
          [101.18214, 5.64929],
          [101.182924, 5.649379],
          [101.183555, 5.649573],
          [101.183822, 5.649731],
          [101.184128, 5.650173],
          [101.184262, 5.650279],
          [101.184396, 5.650314],
          [101.184644, 5.650296],
          [101.184874, 5.65019],
          [101.185218, 5.649943],
          [101.185332, 5.649802],
          [101.185581, 5.649696],
          [101.185906, 5.649661],
          [101.186326, 5.649679],
          [101.187014, 5.650031],
          [101.187435, 5.650367],
          [101.187855, 5.65079],
          [101.188257, 5.651002],
          [101.188773, 5.651126],
          [101.189136, 5.651161],
          [101.189538, 5.65109],
          [101.189996, 5.650173],
          [101.190455, 5.649961],
          [101.190665, 5.649749],
          [101.191009, 5.649555],
          [101.191353, 5.649449],
          [101.192061, 5.649449],
          [101.192882, 5.64989],
          [101.193398, 5.649996],
          [101.193991, 5.649908],
          [101.194602, 5.649731],
          [101.195845, 5.649502],
          [101.196437, 5.649537],
          [101.196801, 5.649749],
          [101.197737, 5.650614],
          [101.198024, 5.650808],
          [101.199591, 5.651355],
          [101.20005, 5.651743],
          [101.200107, 5.652043],
          [101.200107, 5.652361],
          [101.199897, 5.653278],
          [101.199897, 5.65349],
          [101.199954, 5.653666],
          [101.200107, 5.653772],
          [101.200356, 5.653808],
          [101.200757, 5.653772],
          [101.20133, 5.65379],
          [101.201885, 5.653878],
          [101.20307, 5.654213],
          [101.203433, 5.654425],
          [101.203834, 5.655254],
          [101.204198, 5.657602],
          [101.204312, 5.657849],
          [101.204484, 5.658008],
          [101.204675, 5.658555],
          [101.204733, 5.658837],
          [101.204675, 5.659049],
          [101.204637, 5.659649],
          [101.204695, 5.659896],
          [101.204847, 5.660072],
          [101.205153, 5.660249],
          [101.206128, 5.660372],
          [101.206357, 5.660319],
          [101.207657, 5.659472],
          [101.208154, 5.659349],
          [101.209014, 5.659296],
          [101.209473, 5.659384],
          [101.210046, 5.659561],
          [101.2106, 5.659825],
          [101.211709, 5.660513],
          [101.212034, 5.660831],
          [101.212263, 5.660972],
          [101.212607, 5.661096],
          [101.213123, 5.661166],
          [101.213716, 5.661131],
          [101.21448, 5.661272],
          [101.214729, 5.661272],
          [101.214977, 5.661413],
          [101.215035, 5.661502],
          [101.215035, 5.661749],
          [101.214977, 5.662154],
          [101.214824, 5.662666],
          [101.214786, 5.663054],
          [101.214824, 5.663619],
          [101.21492, 5.663848],
          [101.215188, 5.664095],
          [101.216086, 5.664502],
          [101.216927, 5.66526],
          [101.217157, 5.665366],
          [101.217864, 5.66556],
          [101.218112, 5.665701],
          [101.218303, 5.665913],
          [101.218284, 5.668136],
          [101.218189, 5.668701],
          [101.218227, 5.669089],
          [101.218361, 5.669425],
          [101.218609, 5.669672],
          [101.218724, 5.669866],
          [101.218781, 5.670289],
          [101.218666, 5.671878],
          [101.218724, 5.672195],
          [101.218896, 5.672495],
          [101.219527, 5.673042],
          [101.220157, 5.673713],
          [101.220578, 5.673995],
          [101.220941, 5.674154],
          [101.2214, 5.67426],
          [101.22161, 5.674383],
          [101.221877, 5.674719],
          [101.22226, 5.675336],
          [101.222413, 5.675671],
          [101.222451, 5.675971],
          [101.222375, 5.676254],
          [101.221686, 5.677595],
          [101.220712, 5.678901],
          [101.219928, 5.680118],
          [101.219737, 5.680488],
          [101.219278, 5.681036],
          [101.219221, 5.681318],
          [101.219183, 5.682747],
          [101.219106, 5.683718],
          [101.218915, 5.684194],
          [101.218915, 5.684388],
          [101.218991, 5.684565],
          [101.219011, 5.684865],
          [101.218819, 5.685412],
          [101.218456, 5.685994],
          [101.21838, 5.686347],
          [101.218322, 5.687071],
          [101.21838, 5.687353],
          [101.218514, 5.68753],
          [101.218858, 5.687653],
          [101.219278, 5.687741],
          [101.22096, 5.687882],
          [101.2214, 5.687812],
          [101.221686, 5.687706],
          [101.222317, 5.687688],
          [101.222795, 5.687565],
          [101.223177, 5.687582],
          [101.223808, 5.687988],
          [101.224171, 5.688112],
          [101.224515, 5.688112],
          [101.225911, 5.687918],
          [101.226713, 5.687882],
          [101.227554, 5.687953],
          [101.227994, 5.688059],
          [101.228548, 5.688112],
          [101.229103, 5.688077],
          [101.229332, 5.687918],
          [101.229695, 5.687794],
          [101.230039, 5.687759],
          [101.230766, 5.687971],
          [101.231587, 5.688341],
          [101.232027, 5.688341],
          [101.232428, 5.688147],
          [101.232696, 5.687953],
          [101.233002, 5.687653],
          [101.233786, 5.687829],
          [101.2339, 5.6879],
          [101.234034, 5.688182],
          [101.234072, 5.688747],
          [101.23413, 5.688941],
          [101.234302, 5.689135],
          [101.235124, 5.689382],
          [101.235869, 5.689982],
          [101.236156, 5.690035],
          [101.236691, 5.690053],
          [101.237207, 5.690388],
          [101.23778, 5.690564],
          [101.238029, 5.6906],
          [101.238182, 5.690564],
          [101.23843, 5.690353],
          [101.238659, 5.690229],
          [101.238927, 5.690264],
          [101.239252, 5.69037],
          [101.240112, 5.690476],
          [101.240934, 5.690847],
          [101.241412, 5.690988],
          [101.241909, 5.691253],
          [101.242157, 5.691535],
          [101.24252, 5.691676],
          [101.242883, 5.691888],
          [101.243075, 5.692206],
          [101.244107, 5.694447],
          [101.244164, 5.694835],
          [101.244336, 5.695241],
          [101.244565, 5.695611],
          [101.245349, 5.695858],
          [101.245865, 5.695929],
          [101.246076, 5.696017],
          [101.246305, 5.696264],
          [101.246381, 5.696458],
          [101.246764, 5.696723],
          [101.246974, 5.696723],
          [101.247108, 5.696652],
          [101.247222, 5.69644],
          [101.247452, 5.696211],
          [101.247758, 5.696052],
          [101.248121, 5.695929],
          [101.248407, 5.695876],
          [101.248579, 5.695893],
          [101.248732, 5.695964],
          [101.249076, 5.696282],
          [101.24944, 5.696776],
          [101.249535, 5.697393],
          [101.249669, 5.697764],
          [101.249898, 5.698081],
          [101.2503, 5.698364],
          [101.251102, 5.698576],
          [101.251236, 5.698699],
          [101.251351, 5.698999],
          [101.251217, 5.699917],
          [101.25093, 5.700181],
          [101.250414, 5.700446],
          [101.250242, 5.700675],
          [101.250089, 5.701275],
          [101.249936, 5.702387],
          [101.249841, 5.702687],
          [101.249631, 5.702916],
          [101.249229, 5.703163],
          [101.249153, 5.703358],
          [101.249191, 5.70364],
          [101.249382, 5.704028],
          [101.249764, 5.704575],
          [101.250204, 5.704999],
          [101.250586, 5.705246],
          [101.250892, 5.705369],
          [101.25137, 5.705899],
          [101.251695, 5.706622],
          [101.251638, 5.707416],
          [101.251752, 5.70784],
          [101.251943, 5.708052],
          [101.252192, 5.708175],
          [101.252899, 5.708299],
          [101.253377, 5.708493],
          [101.254256, 5.709057],
          [101.254543, 5.709622],
          [101.255059, 5.709675],
          [101.256034, 5.709604],
          [101.256378, 5.709639],
          [101.256588, 5.709745],
          [101.256683, 5.709869],
          [101.256722, 5.710116],
          [101.256817, 5.710275],
          [101.257104, 5.710486],
          [101.258098, 5.711051],
          [101.258499, 5.711228],
          [101.258901, 5.711316],
          [101.259665, 5.711369],
          [101.259894, 5.711439],
          [101.25999, 5.711545],
          [101.260219, 5.71241],
          [101.26064, 5.712886],
          [101.260754, 5.713204],
          [101.260812, 5.713698],
          [101.260812, 5.714933],
          [101.260984, 5.71608],
          [101.261137, 5.716539],
          [101.261194, 5.717086],
          [101.261194, 5.717598],
          [101.261137, 5.717863],
          [101.260888, 5.718163],
          [101.260028, 5.718145],
          [101.259837, 5.718198],
          [101.259589, 5.718392],
          [101.258614, 5.719416],
          [101.258518, 5.719574],
          [101.25848, 5.719751],
          [101.258633, 5.720104],
          [101.258671, 5.720298],
          [101.258614, 5.720668],
          [101.258232, 5.721727],
          [101.258289, 5.72201],
          [101.258499, 5.722363],
          [101.258805, 5.722769],
          [101.259053, 5.722945],
          [101.259168, 5.723086],
          [101.259245, 5.723351],
          [101.259264, 5.723615],
          [101.259168, 5.723986],
          [101.258767, 5.72471],
          [101.258671, 5.724957],
          [101.258595, 5.725274],
          [101.258614, 5.725504],
          [101.25869, 5.725733],
          [101.258881, 5.725856],
          [101.259073, 5.726051],
          [101.259168, 5.726298],
          [101.259225, 5.726845],
          [101.259245, 5.728239],
          [101.25934, 5.72928],
          [101.259608, 5.730903],
          [101.25978, 5.731203],
          [101.260047, 5.731468],
          [101.260219, 5.731574],
          [101.260277, 5.73175],
          [101.260258, 5.731909],
          [101.259914, 5.732544],
          [101.259493, 5.733162],
          [101.25934, 5.733797],
          [101.259302, 5.73468],
          [101.259206, 5.735015],
          [101.259015, 5.735368],
          [101.258098, 5.736091],
          [101.257658, 5.736497],
          [101.257257, 5.737009],
          [101.257123, 5.737273],
          [101.257085, 5.737485],
          [101.257123, 5.737838],
          [101.257372, 5.738456],
          [101.257505, 5.739267],
          [101.257391, 5.739797],
          [101.257219, 5.740167],
          [101.257027, 5.740326],
          [101.256913, 5.740503],
          [101.256855, 5.740714],
          [101.256664, 5.741014],
          [101.256129, 5.741385],
          [101.255747, 5.741738],
          [101.255326, 5.742161],
          [101.255116, 5.742479],
          [101.254963, 5.742991],
          [101.255002, 5.74352],
          [101.254925, 5.74389],
          [101.254428, 5.745108],
          [101.254275, 5.745673],
          [101.254122, 5.746767],
          [101.254122, 5.747314],
          [101.254237, 5.747702],
          [101.254485, 5.748214],
          [101.254829, 5.748673],
          [101.25546, 5.750031],
          [101.255575, 5.750366],
          [101.255785, 5.751337],
          [101.255728, 5.751496],
          [101.255518, 5.751707],
          [101.254696, 5.752307],
          [101.254409, 5.752678],
          [101.253721, 5.753772],
          [101.253453, 5.754072],
          [101.253186, 5.754301],
          [101.252708, 5.754513],
          [101.252517, 5.754654],
          [101.251963, 5.755219],
          [101.251657, 5.75566],
          [101.251026, 5.755925],
          [101.250682, 5.75626],
          [101.250453, 5.756719],
          [101.250376, 5.757125],
          [101.250242, 5.757443],
          [101.249287, 5.758925],
          [101.249191, 5.75919],
          [101.249191, 5.760125],
          [101.249306, 5.760654],
          [101.249363, 5.761201],
          [101.249573, 5.762066],
          [101.249612, 5.762401],
          [101.249707, 5.762666],
          [101.250321, 5.76341],
          [101.250486, 5.763714],
          [101.250541, 5.764398],
          [101.250651, 5.764727],
          [101.251392, 5.765285],
          [101.251666, 5.765614],
          [101.252352, 5.766982],
          [101.252654, 5.767666],
          [101.252819, 5.768224],
          [101.253176, 5.76868],
          [101.25334, 5.768959],
          [101.253697, 5.769871],
          [101.253697, 5.770479],
          [101.253807, 5.770884],
          [101.254603, 5.772278],
          [101.25485, 5.77248],
          [101.255673, 5.772835],
          [101.255975, 5.773038],
          [101.256277, 5.773165],
          [101.257018, 5.773367],
          [101.257347, 5.77357],
          [101.257786, 5.774735],
          [101.257896, 5.775318],
          [101.258061, 5.775572],
          [101.2585, 5.77585],
          [101.258774, 5.776484],
          [101.258912, 5.776661],
          [101.259653, 5.777041],
          [101.25979, 5.777193],
          [101.259845, 5.777548],
          [101.25979, 5.778612],
          [101.259625, 5.779296],
          [101.259543, 5.779955],
          [101.259351, 5.780741],
          [101.259296, 5.781273],
          [101.259186, 5.78178],
          [101.259076, 5.782058],
          [101.258719, 5.782413],
          [101.258418, 5.783933],
          [101.25839, 5.784465],
          [101.258857, 5.785327],
          [101.258912, 5.785656],
          [101.258884, 5.785859],
          [101.258692, 5.786137],
          [101.258609, 5.786441],
          [101.258664, 5.786822],
          [101.258939, 5.787202],
          [101.259158, 5.787632],
          [101.259433, 5.787962],
          [101.259597, 5.788367],
          [101.260009, 5.789102],
          [101.260064, 5.789431],
          [101.260256, 5.789811],
          [101.260228, 5.790217],
          [101.260366, 5.791053],
          [101.260475, 5.791357],
          [101.260695, 5.791611],
          [101.261793, 5.792548],
          [101.262397, 5.792979],
          [101.262699, 5.793283],
          [101.262808, 5.793536],
          [101.262891, 5.793967],
          [101.262891, 5.794524],
          [101.263, 5.795006],
          [101.263138, 5.795386],
          [101.263467, 5.795867],
          [101.263659, 5.796374],
          [101.263934, 5.797388],
          [101.264071, 5.798097],
          [101.264098, 5.798477],
          [101.264043, 5.798756],
          [101.263879, 5.799111],
          [101.263687, 5.799364],
          [101.263632, 5.79977],
          [101.263769, 5.800732],
          [101.264098, 5.801011],
          [101.26462, 5.80129],
          [101.264839, 5.801518],
          [101.265031, 5.802024],
          [101.265361, 5.802202],
          [101.266321, 5.802556],
          [101.26709, 5.802506],
          [101.267721, 5.802531],
          [101.26838, 5.802683],
          [101.269615, 5.80319],
          [101.270219, 5.804051],
          [101.27063, 5.804558],
          [101.271042, 5.805242],
          [101.271563, 5.805926],
          [101.272661, 5.807573],
          [101.273155, 5.808029],
          [101.273732, 5.80846],
          [101.274335, 5.808688],
          [101.275214, 5.808789],
          [101.275378, 5.808941],
          [101.2759, 5.810436],
          [101.276147, 5.811551],
          [101.276339, 5.811805],
          [101.276778, 5.812058],
          [101.278562, 5.812033],
          [101.279825, 5.812362],
          [101.280154, 5.812362],
          [101.280648, 5.812438],
          [101.283338, 5.813224],
          [101.283914, 5.813578],
          [101.284491, 5.813654],
          [101.285286, 5.813578],
          [101.285616, 5.81368],
          [101.286576, 5.814136],
          [101.289431, 5.815022],
          [101.289815, 5.815327],
          [101.290309, 5.815935],
          [101.290556, 5.816011],
          [101.290693, 5.816011],
          [101.29094, 5.815833],
          [101.29212, 5.8152],
          [101.29234, 5.815022],
          [101.292477, 5.814693],
          [101.293328, 5.813832],
          [101.293767, 5.813249],
          [101.294206, 5.81259],
          [101.294398, 5.812083],
          [101.294673, 5.811805],
          [101.295029, 5.811627],
          [101.295716, 5.811627],
          [101.29621, 5.811754],
          [101.296731, 5.811805],
          [101.297225, 5.812007],
          [101.297472, 5.811982],
          [101.297664, 5.811805],
          [101.297993, 5.811805],
          [101.298103, 5.811906],
          [101.29868, 5.812058],
          [101.298927, 5.812286],
          [101.299036, 5.812489],
          [101.299503, 5.812742],
          [101.29975, 5.812767],
          [101.299942, 5.812666],
          [101.300326, 5.812286],
          [101.300546, 5.812235],
          [101.301012, 5.812235],
          [101.301726, 5.812337],
          [101.302385, 5.812539],
          [101.303098, 5.812843],
          [101.303702, 5.812919],
          [101.304471, 5.813401],
          [101.304745, 5.813477],
          [101.304992, 5.813401],
          [101.305157, 5.813249],
          [101.305678, 5.811425],
          [101.305733, 5.810588],
          [101.30587, 5.810082],
          [101.306227, 5.808283],
          [101.306556, 5.806002],
          [101.306749, 5.805293],
          [101.307188, 5.804432],
          [101.307764, 5.803899],
          [101.308423, 5.803367],
          [101.309164, 5.802582],
          [101.310673, 5.802278],
          [101.311332, 5.80205],
          [101.313472, 5.801872],
          [101.313774, 5.801872],
          [101.314049, 5.802024],
          [101.314406, 5.802481],
          [101.314762, 5.802658],
          [101.315147, 5.802759],
          [101.315586, 5.803114],
          [101.315668, 5.803443],
          [101.316354, 5.804254],
          [101.316519, 5.804558],
          [101.317507, 5.805648],
          [101.317781, 5.806053],
          [101.31833, 5.8077],
          [101.31855, 5.808105],
          [101.318715, 5.808967],
          [101.318907, 5.809271],
          [101.319263, 5.809626],
          [101.319757, 5.809955],
          [101.320087, 5.810234],
          [101.320499, 5.810994],
          [101.320581, 5.811678],
          [101.320828, 5.811931],
          [101.323847, 5.812945],
          [101.324506, 5.813224],
          [101.325137, 5.813401],
          [101.325576, 5.81368],
          [101.325933, 5.813705],
          [101.326838, 5.813578],
          [101.327579, 5.813426],
          [101.327854, 5.8133],
          [101.328046, 5.813148],
          [101.328156, 5.812514],
          [101.328348, 5.812261],
          [101.328732, 5.811957],
          [101.329885, 5.811627],
          [101.331477, 5.811577],
          [101.331696, 5.81145],
          [101.332821, 5.810994],
          [101.333535, 5.810487],
          [101.333809, 5.810234],
          [101.333947, 5.809955],
          [101.334029, 5.809575],
          [101.334194, 5.809296],
          [101.334688, 5.80884],
          [101.335209, 5.808435],
          [101.335429, 5.808333],
          [101.335703, 5.808308],
          [101.33595, 5.808207],
          [101.336472, 5.807447],
          [101.336773, 5.806813],
          [101.336938, 5.806636],
          [101.337514, 5.806611],
          [101.337844, 5.806712],
          [101.338393, 5.806788],
          [101.339298, 5.806763],
          [101.339408, 5.806813],
          [101.339518, 5.806965],
          [101.339573, 5.807219],
          [101.340012, 5.8077],
          [101.340149, 5.808207],
          [101.340341, 5.808359],
          [101.341741, 5.809195],
          [101.342537, 5.809296],
          [101.343168, 5.809322],
          [101.343827, 5.80922],
          [101.345227, 5.80879],
          [101.345858, 5.808409],
          [101.346132, 5.808435],
          [101.346132, 5.808612],
          [101.346324, 5.809271],
          [101.346407, 5.810158],
          [101.346023, 5.810994],
          [101.345995, 5.811247],
          [101.346023, 5.811703],
          [101.346132, 5.812185],
          [101.346379, 5.812691],
          [101.347944, 5.814718],
          [101.348081, 5.814946],
          [101.348218, 5.815377],
          [101.34841, 5.816315],
          [101.348437, 5.816695],
          [101.348684, 5.817151],
          [101.348712, 5.817455],
          [101.348657, 5.817911],
          [101.348465, 5.818266],
          [101.348135, 5.819279],
          [101.347834, 5.820394],
          [101.347751, 5.820774],
          [101.347696, 5.821357],
          [101.347559, 5.821889],
          [101.347312, 5.822472],
          [101.34723, 5.822903],
          [101.347257, 5.823409],
          [101.34734, 5.823713],
          [101.348026, 5.824651],
          [101.348273, 5.825056],
          [101.34852, 5.825183],
          [101.349453, 5.825867],
          [101.349755, 5.826399],
          [101.350111, 5.826881],
          [101.350715, 5.827185],
          [101.350935, 5.827236],
          [101.352582, 5.827185],
          [101.35313, 5.827312],
          [101.35335, 5.827641],
          [101.354173, 5.827818],
          [101.35442, 5.82792],
          [101.354914, 5.828528],
          [101.355244, 5.828604],
          [101.356259, 5.829795],
          [101.357001, 5.831112],
          [101.357522, 5.831644],
          [101.358181, 5.832151],
          [101.358483, 5.832683],
          [101.358812, 5.833063],
          [101.359059, 5.833164],
          [101.360404, 5.832303],
          [101.360569, 5.832125],
          [101.360623, 5.831948],
          [101.36098, 5.831416],
          [101.361419, 5.830985],
          [101.361749, 5.830732],
          [101.362078, 5.830605],
          [101.362407, 5.830732],
          [101.362846, 5.831315],
          [101.363066, 5.831492],
          [101.363313, 5.831543],
          [101.363999, 5.831517],
          [101.364932, 5.831745],
          [101.365454, 5.83172],
          [101.366112, 5.831416],
          [101.366469, 5.831391],
          [101.367375, 5.831619],
          [101.367896, 5.831619],
          [101.36861, 5.831543],
          [101.368967, 5.831593],
          [101.369406, 5.83172],
          [101.370256, 5.832252],
          [101.370531, 5.832353],
          [101.370805, 5.832303],
          [101.370943, 5.832176],
          [101.371244, 5.831163],
          [101.371491, 5.830884],
          [101.371656, 5.830859],
          [101.371931, 5.830909],
          [101.372699, 5.831188],
          [101.37344, 5.831543],
          [101.374895, 5.83281],
          [101.375389, 5.833494],
          [101.375471, 5.833798],
          [101.375471, 5.834026],
          [101.375251, 5.834305],
          [101.375196, 5.83471],
          [101.375032, 5.834913],
          [101.374867, 5.834989],
          [101.374401, 5.835369],
          [101.374126, 5.835774],
          [101.374016, 5.836382],
          [101.374071, 5.83661],
          [101.374428, 5.836838],
          [101.37473, 5.837168],
          [101.375142, 5.837852],
          [101.375416, 5.838967],
          [101.375526, 5.839246],
          [101.375773, 5.839575],
          [101.376157, 5.839879],
          [101.376322, 5.840183],
          [101.376541, 5.841019],
          [101.376596, 5.842236],
          [101.376706, 5.842717],
          [101.377008, 5.84335],
          [101.377557, 5.844288],
          [101.377886, 5.844643],
          [101.378298, 5.84482],
          [101.379478, 5.845099],
          [101.37967, 5.845276],
          [101.379835, 5.845606],
          [101.379889, 5.846214],
          [101.379615, 5.847379],
          [101.379615, 5.847708],
          [101.379753, 5.848189],
          [101.379835, 5.849152],
          [101.380137, 5.850216],
          [101.380411, 5.850672],
          [101.380933, 5.851306],
          [101.381646, 5.851863],
          [101.382552, 5.852193],
          [101.383101, 5.852598],
          [101.383705, 5.852471],
          [101.384007, 5.852471],
          [101.384528, 5.852649],
          [101.386669, 5.853561],
          [101.38719, 5.853662],
          [101.387657, 5.854726],
          [101.388069, 5.855461],
          [101.389688, 5.857868],
          [101.390237, 5.858451],
          [101.390429, 5.858882],
          [101.390511, 5.859186],
          [101.390292, 5.859313],
          [101.389935, 5.859668],
          [101.389359, 5.860402],
          [101.389084, 5.861163],
          [101.389139, 5.861543],
          [101.389523, 5.862201],
          [101.38966, 5.862683],
          [101.389688, 5.863012],
          [101.389633, 5.863392],
          [101.389413, 5.864127],
          [101.389386, 5.864837],
          [101.389496, 5.865293],
          [101.389743, 5.865901],
          [101.390127, 5.866382],
          [101.390347, 5.866737],
          [101.390539, 5.867193],
          [101.390868, 5.86917],
          [101.390978, 5.870538],
          [101.39117, 5.870943],
          [101.391225, 5.871374],
          [101.391362, 5.871779],
          [101.391664, 5.872083],
          [101.391609, 5.872438],
          [101.391307, 5.873097],
          [101.391225, 5.875301],
          [101.391362, 5.875681],
          [101.391499, 5.875732],
          [101.391664, 5.875706],
          [101.39224, 5.875301],
          [101.393557, 5.873755],
          [101.393997, 5.87335],
          [101.394353, 5.873147],
          [101.394738, 5.873147],
          [101.394902, 5.873223],
          [101.395286, 5.873274],
          [101.395698, 5.873249],
          [101.396055, 5.873071],
          [101.396467, 5.872666],
          [101.396906, 5.872412],
          [101.397208, 5.872412],
          [101.397372, 5.872564],
          [101.397702, 5.872641],
          [101.398278, 5.872641],
          [101.398552, 5.872869],
          [101.398799, 5.872995],
          [101.399952, 5.872995],
          [101.400446, 5.873223],
          [101.400885, 5.87335],
          [101.401599, 5.873249],
          [101.401873, 5.873274],
          [101.402697, 5.873578],
          [101.402944, 5.873578],
          [101.403438, 5.873325],
          [101.40374, 5.873249],
          [101.404783, 5.873147],
          [101.405112, 5.87297],
          [101.405551, 5.872615],
          [101.405798, 5.872058],
          [101.406923, 5.871906],
          [101.407225, 5.871931],
          [101.407527, 5.872058],
          [101.407719, 5.87226],
          [101.407939, 5.872336],
          [101.40879, 5.872311],
          [101.409037, 5.872438],
          [101.409311, 5.872514],
          [101.40964, 5.872539],
          [101.409997, 5.872362],
          [101.410162, 5.872412],
          [101.410491, 5.873071],
          [101.410656, 5.873299],
          [101.411068, 5.873451],
          [101.411315, 5.873401],
          [101.411671, 5.873223],
          [101.412001, 5.87297],
          [101.412248, 5.872843],
          [101.412659, 5.872818],
          [101.413647, 5.872945],
          [101.414361, 5.872793],
          [101.414498, 5.872869],
          [101.414635, 5.873097],
          [101.415129, 5.87411],
          [101.415322, 5.874642],
          [101.415596, 5.874972],
          [101.415898, 5.875174],
          [101.41609, 5.875428],
          [101.416145, 5.875732],
          [101.41631, 5.876036],
          [101.416529, 5.876162],
          [101.41738, 5.876137],
          [101.417572, 5.876188],
          [101.417901, 5.876365],
          [101.418313, 5.87639],
          [101.418807, 5.87639],
          [101.419109, 5.876314],
          [101.420097, 5.875757],
          [101.420618, 5.875605],
          [101.421085, 5.875377],
          [101.421387, 5.875149],
          [101.421771, 5.875048],
          [101.422046, 5.875149],
          [101.422293, 5.875124],
          [101.422512, 5.874997],
          [101.422787, 5.874718],
          [101.423308, 5.874591],
          [101.423884, 5.874693],
          [101.424159, 5.874566],
          [101.424323, 5.874262],
          [101.424516, 5.873755],
          [101.424708, 5.873679],
          [101.424927, 5.873705],
          [101.425421, 5.873958],
          [101.425723, 5.874363],
          [101.42619, 5.8752],
          [101.426409, 5.875402],
          [101.426848, 5.875453],
          [101.42748, 5.875174],
          [101.427836, 5.874946],
          [101.428193, 5.874921],
          [101.428687, 5.875098],
          [101.429044, 5.875098],
          [101.429154, 5.874896],
          [101.42962, 5.872894],
          [101.429895, 5.872615],
          [101.430197, 5.872083],
          [101.430444, 5.87183],
          [101.431212, 5.871247],
          [101.431404, 5.870968],
          [101.431487, 5.870766],
          [101.431651, 5.870715],
          [101.431953, 5.870664],
          [101.43242, 5.870994],
          [101.433353, 5.871222],
          [101.433874, 5.871171],
          [101.434368, 5.870867],
          [101.43467, 5.870791],
          [101.435164, 5.87074],
          [101.435823, 5.871146],
          [101.436207, 5.871551],
          [101.436646, 5.87226],
          [101.436921, 5.872438],
          [101.437305, 5.872539],
          [101.437525, 5.872336],
          [101.437634, 5.871779],
          [101.437909, 5.871146],
          [101.438128, 5.870816],
          [101.438321, 5.870639],
          [101.43854, 5.870563],
          [101.439117, 5.870512],
          [101.440489, 5.870487],
          [101.440818, 5.870588],
          [101.441038, 5.870766],
          [101.441175, 5.870994],
          [101.441477, 5.871146],
          [101.441751, 5.871196],
          [101.442492, 5.871146],
          [101.442849, 5.870867],
          [101.443096, 5.870791],
          [101.443508, 5.87074],
          [101.444249, 5.870436],
          [101.444496, 5.870233],
          [101.445813, 5.870233],
          [101.446939, 5.870487],
          [101.447213, 5.870436],
          [101.447899, 5.869752],
          [101.449244, 5.868789],
          [101.449546, 5.868663],
          [101.450012, 5.868587],
          [101.450397, 5.86846],
          [101.451138, 5.868156],
          [101.451796, 5.867826],
          [101.452181, 5.8677],
          [101.452345, 5.867548],
          [101.452373, 5.86737],
          [101.452647, 5.867269],
          [101.453059, 5.867573],
          [101.453416, 5.867548],
          [101.454212, 5.86737],
          [101.454898, 5.867269],
          [101.455282, 5.867269],
          [101.455529, 5.86737],
          [101.455913, 5.8677],
          [101.456078, 5.86808],
          [101.456325, 5.868409],
          [101.456984, 5.868941],
          [101.457176, 5.869296],
          [101.457725, 5.870588],
          [101.458273, 5.871399],
          [101.458466, 5.871779],
          [101.458603, 5.872311],
          [101.458877, 5.872666],
          [101.461045, 5.873629],
          [101.461539, 5.87373],
          [101.46228, 5.87373],
          [101.462665, 5.873831],
          [101.463049, 5.874211],
          [101.463955, 5.875276],
          [101.464009, 5.87558],
          [101.464009, 5.876238],
          [101.464174, 5.87639],
          [101.464366, 5.876365],
          [101.465244, 5.875478],
          [101.46604, 5.874921],
          [101.466425, 5.874794],
          [101.466946, 5.874769],
          [101.467166, 5.874617],
          [101.468071, 5.874515],
          [101.469114, 5.874034],
          [101.469636, 5.873907],
          [101.470349, 5.873603],
          [101.470788, 5.873325],
          [101.471392, 5.872793],
          [101.471749, 5.872311],
          [101.472078, 5.871602],
          [101.472462, 5.871146],
          [101.472956, 5.870715],
          [101.473505, 5.870538],
          [101.473862, 5.870436],
          [101.474493, 5.870512],
          [101.474905, 5.870259],
          [101.475289, 5.870233],
          [101.476469, 5.870588],
          [101.476963, 5.870943],
          [101.477183, 5.871171],
          [101.477567, 5.871779],
          [101.478226, 5.872514],
          [101.47839, 5.872767],
          [101.479022, 5.872945],
          [101.479269, 5.872767],
          [101.47968, 5.872286],
          [101.480202, 5.872108],
          [101.480476, 5.871956],
          [101.480998, 5.87145],
          [101.481409, 5.871374],
          [101.482315, 5.871323],
          [101.482781, 5.871196],
          [101.484538, 5.869929],
          [101.484867, 5.869777],
          [101.485389, 5.869727],
          [101.485938, 5.869828],
          [101.486267, 5.86998],
          [101.486569, 5.870183],
          [101.486761, 5.870436],
          [101.487008, 5.870892],
          [101.487118, 5.871576],
          [101.487584, 5.872717],
          [101.487721, 5.87335],
          [101.487749, 5.873781],
          [101.487557, 5.874769],
          [101.487392, 5.875048],
          [101.487118, 5.875276],
          [101.487035, 5.875402],
          [101.486953, 5.87563],
          [101.486926, 5.875934],
          [101.487447, 5.877987],
          [101.487502, 5.878848],
          [101.487639, 5.879786],
          [101.48742, 5.880774],
          [101.487201, 5.881255],
          [101.487063, 5.88199],
          [101.486926, 5.883156],
          [101.487146, 5.88346],
          [101.487338, 5.883587],
          [101.487942, 5.883358],
          [101.488298, 5.883282],
          [101.48871, 5.883257],
          [101.489039, 5.883333],
          [101.489286, 5.883333],
          [101.489451, 5.88313],
          [101.489863, 5.882877],
          [101.490549, 5.882776],
          [101.491262, 5.882902],
          [101.491592, 5.882852],
          [101.493348, 5.881838],
          [101.493403, 5.881585],
          [101.493568, 5.881484],
          [101.493787, 5.881509],
          [101.495022, 5.882548],
          [101.495489, 5.88308],
          [101.49645, 5.883434],
          [101.496834, 5.883713],
          [101.496999, 5.884068],
          [101.497465, 5.884651],
          [101.498124, 5.88607],
          [101.49859, 5.886906],
          [101.498892, 5.88759],
          [101.49892, 5.887868],
          [101.499139, 5.888046],
          [101.5001, 5.888477],
          [101.500402, 5.888882],
          [101.500676, 5.889009],
          [101.501637, 5.889237],
          [101.501966, 5.88944],
          [101.502268, 5.889718],
          [101.5027796, 5.8897614],
          [101.503119, 5.889921],
          [101.50364, 5.890225],
          [101.504491, 5.890529],
          [101.505122, 5.891061],
          [101.505424, 5.891467],
          [101.505561, 5.891821],
          [101.506192, 5.892581],
          [101.506604, 5.892708],
          [101.507235, 5.892784],
          [101.507565, 5.89319],
          [101.508141, 5.893671],
          [101.508333, 5.893975],
          [101.508415, 5.894304],
          [101.508278, 5.895115],
          [101.508388, 5.89623],
          [101.508525, 5.896559],
          [101.508745, 5.896889],
          [101.509321, 5.897294],
          [101.50965, 5.8977],
          [101.510035, 5.897801],
          [101.510364, 5.89775],
          [101.510556, 5.897826],
          [101.510776, 5.898029],
          [101.510831, 5.898384],
          [101.511489, 5.898865],
          [101.511956, 5.898916],
          [101.512093, 5.899017],
          [101.512175, 5.899296],
          [101.512203, 5.8996],
          [101.512395, 5.899955],
          [101.512779, 5.900208],
          [101.51352, 5.900867],
          [101.513575, 5.901044],
          [101.513493, 5.901348],
          [101.513383, 5.902565],
          [101.513465, 5.902919],
          [101.51374, 5.903198],
          [101.514124, 5.903325],
          [101.514481, 5.903325],
          [101.514783, 5.903375],
          [101.51503, 5.903527],
          [101.515277, 5.903578],
          [101.515908, 5.903578],
          [101.516265, 5.902666],
          [101.516567, 5.902337],
          [101.517253, 5.90183],
          [101.517445, 5.901576],
          [101.517555, 5.901222],
          [101.517555, 5.900867],
          [101.517774, 5.900487],
          [101.518021, 5.900259],
          [101.518378, 5.900183],
          [101.518899, 5.900538],
          [101.519311, 5.900664],
          [101.519723, 5.900664],
          [101.520107, 5.90074],
          [101.520327, 5.900867],
          [101.520629, 5.900918],
          [101.520958, 5.900842],
          [101.52126, 5.900512],
          [101.521864, 5.899549],
          [101.522412, 5.898891],
          [101.522687, 5.898764],
          [101.523236, 5.898688],
          [101.52362, 5.898688],
          [101.523977, 5.898764],
          [101.524059, 5.899043],
          [101.524087, 5.899372],
          [101.524471, 5.899879],
          [101.52491, 5.900791],
          [101.52513, 5.901095],
          [101.525459, 5.901323],
          [101.525651, 5.901728],
          [101.525733, 5.902235],
          [101.525596, 5.903578],
          [101.525184, 5.904364],
          [101.524773, 5.905605],
          [101.524855, 5.905909],
          [101.525514, 5.906593],
          [101.525953, 5.906923],
          [101.526118, 5.906948],
          [101.526282, 5.907176],
          [101.526227, 5.908037],
          [101.526419, 5.908595],
          [101.526749, 5.909076],
          [101.527298, 5.909634],
          [101.527655, 5.90976],
          [101.527792, 5.90976],
          [101.527902, 5.90971],
          [101.528203, 5.909811],
          [101.528533, 5.91009],
          [101.52878, 5.910419],
          [101.529164, 5.910799],
          [101.529438, 5.911179],
          [101.529686, 5.911737],
          [101.529933, 5.911914],
          [101.530399, 5.912041],
          [101.530756, 5.912041],
          [101.531003, 5.912092],
          [101.53125, 5.912269],
          [101.531826, 5.912877],
          [101.532073, 5.912953],
          [101.532512, 5.913308],
          [101.533336, 5.914144],
          [101.533747, 5.914372],
          [101.534159, 5.914448],
          [101.534873, 5.914727],
          [101.535257, 5.914752],
          [101.535614, 5.914625],
          [101.535916, 5.9146],
          [101.536163, 5.914651],
          [101.536492, 5.914828],
          [101.536739, 5.914853],
          [101.537013, 5.914955],
          [101.537425, 5.915234],
          [101.537754, 5.91569],
          [101.538248, 5.916526],
          [101.538687, 5.916982],
          [101.539209, 5.917058],
          [101.539511, 5.91721],
          [101.539621, 5.917894],
          [101.539758, 5.918325],
          [101.539922, 5.918603],
          [101.540471, 5.919009],
          [101.540801, 5.918958],
          [101.541459, 5.918705],
          [101.54242, 5.918527],
          [101.542941, 5.918578],
          [101.543737, 5.918882],
          [101.544368, 5.919059],
          [101.544835, 5.919313],
          [101.547442, 5.919668],
          [101.549363, 5.919769],
          [101.550159, 5.919896],
          [101.550955, 5.919946],
          [101.551614, 5.920047],
          [101.552245, 5.919971],
          [101.552986, 5.920377],
          [101.553617, 5.920605],
          [101.554166, 5.920706],
          [101.55455, 5.920706],
          [101.55488, 5.92063],
          [101.555154, 5.920478],
          [101.556526, 5.919389],
          [101.556993, 5.919161],
          [101.55735, 5.91911],
          [101.557899, 5.919211],
          [101.558201, 5.919135],
          [101.558393, 5.919034],
          [101.558722, 5.918983],
          [101.559134, 5.919034],
          [101.560287, 5.919642],
          [101.560808, 5.919769],
          [101.561192, 5.919971],
          [101.561631, 5.920276],
          [101.562098, 5.920706],
          [101.562537, 5.921162],
          [101.562702, 5.921441],
          [101.562949, 5.921644],
          [101.563854, 5.921593],
          [101.564239, 5.921644],
          [101.565309, 5.921542],
          [101.565776, 5.921669],
          [101.566023, 5.922125],
          [101.566462, 5.922683],
          [101.566736, 5.922936],
          [101.567038, 5.923063],
          [101.567395, 5.923113],
          [101.567999, 5.923316],
          [101.568328, 5.923519],
          [101.568657, 5.923797],
          [101.569124, 5.924329],
          [101.569453, 5.924482],
          [101.569837, 5.924583],
          [101.570359, 5.924862],
          [101.571374, 5.924836],
          [101.571896, 5.924912],
          [101.572253, 5.925166],
          [101.572417, 5.925343],
          [101.573021, 5.925596],
          [101.573516, 5.925647],
          [101.573927, 5.9259],
          [101.57401, 5.926154],
          [101.574037, 5.926432],
          [101.574174, 5.926737],
          [101.574559, 5.927243],
          [101.574833, 5.927725],
          [101.575189, 5.929194],
          [101.575299, 5.929878],
          [101.575491, 5.930284],
          [101.575848, 5.930639],
          [101.576479, 5.931019],
          [101.576946, 5.931399],
          [101.578291, 5.932184],
          [101.579004, 5.932438],
          [101.579498, 5.932767],
          [101.58002, 5.933198],
          [101.580459, 5.933679],
          [101.581008, 5.934084],
          [101.581309, 5.934363],
          [101.581776, 5.934389],
          [101.582133, 5.934211],
          [101.58227, 5.933932],
          [101.582325, 5.933147],
          [101.582545, 5.932919],
          [101.582874, 5.932742],
          [101.583286, 5.932666],
          [101.584219, 5.932792],
          [101.584795, 5.932742],
          [101.585344, 5.93259],
          [101.585536, 5.932463],
          [101.585673, 5.932235],
          [101.585893, 5.932083],
          [101.58625, 5.931956],
          [101.586661, 5.931703],
          [101.586963, 5.931221],
          [101.587046, 5.93074],
          [101.587183, 5.930639],
          [101.587704, 5.930461],
          [101.587787, 5.930284],
          [101.587787, 5.929955],
          [101.587677, 5.929093],
          [101.587704, 5.92889],
          [101.588061, 5.928561],
          [101.588198, 5.928358],
          [101.588143, 5.928105],
          [101.587869, 5.927421],
          [101.587787, 5.927117],
          [101.587787, 5.926914],
          [101.587896, 5.92661],
          [101.588116, 5.926281],
          [101.588583, 5.925673],
          [101.588939, 5.925292],
          [101.589077, 5.925039],
          [101.589131, 5.924735],
          [101.588939, 5.924026],
          [101.588994, 5.923646],
          [101.589186, 5.923012],
          [101.589406, 5.92172],
          [101.589626, 5.920884],
          [101.5899, 5.9202],
          [101.59012, 5.919794],
          [101.59012, 5.919465],
          [101.589846, 5.918705],
          [101.589818, 5.918452],
          [101.589983, 5.9183],
          [101.591273, 5.917894],
          [101.591712, 5.917894],
          [101.592041, 5.917742],
          [101.593249, 5.916551],
          [101.593633, 5.916374],
          [101.594017, 5.916095],
          [101.5941, 5.91574],
          [101.594264, 5.915462],
          [101.594676, 5.915031],
          [101.595197, 5.9146],
          [101.595691, 5.914372],
          [101.597009, 5.914144],
          [101.597393, 5.914195],
          [101.597668, 5.914068],
          [101.597942, 5.913511],
          [101.597805, 5.912725],
          [101.598079, 5.912143],
          [101.598162, 5.911864],
          [101.598436, 5.911763],
          [101.6, 5.911788],
          [101.600138, 5.912016],
          [101.60022, 5.912269],
          [101.600192, 5.912953],
          [101.600385, 5.913308],
          [101.600687, 5.913536],
          [101.600988, 5.913587],
          [101.60162, 5.913435],
          [101.602333, 5.913561],
          [101.60269, 5.913561],
          [101.603541, 5.913384],
          [101.60387, 5.913384],
          [101.604282, 5.913485],
          [101.604529, 5.913663],
          [101.604721, 5.913891],
          [101.605023, 5.914068],
          [101.605846, 5.914144],
          [101.60634, 5.914043],
          [101.606862, 5.914018],
          [101.607328, 5.914144],
          [101.607575, 5.914271],
          [101.60785, 5.914499],
          [101.608618, 5.91498],
          [101.609085, 5.91493],
          [101.609469, 5.914828],
          [101.609743, 5.914626],
          [101.610265, 5.913739],
          [101.610539, 5.913333],
          [101.610732, 5.913131],
          [101.610896, 5.913055],
          [101.611253, 5.91308],
          [101.611335, 5.913207],
          [101.611527, 5.913156],
          [101.612158, 5.912725],
          [101.612817, 5.912142],
          [101.613366, 5.911788],
          [101.613778, 5.911737],
          [101.614244, 5.91161],
          [101.615287, 5.911078],
          [101.615424, 5.910901],
          [101.615754, 5.910166],
          [101.615699, 5.909786],
          [101.615617, 5.909634],
          [101.615644, 5.909076],
          [101.615809, 5.908443],
          [101.615864, 5.907784],
          [101.615864, 5.906872],
          [101.615699, 5.906416],
          [101.615287, 5.905605],
          [101.615095, 5.90444],
          [101.61515, 5.904034],
          [101.61537, 5.903198],
          [101.615562, 5.901425],
          [101.615891, 5.900411],
          [101.616632, 5.898891],
          [101.617126, 5.898105],
          [101.617318, 5.897852],
          [101.61773, 5.897497],
          [101.618032, 5.897345],
          [101.618224, 5.897016],
          [101.618306, 5.896661],
          [101.618388, 5.895774],
          [101.618635, 5.895369],
          [101.61902, 5.895217],
          [101.619514, 5.895217],
          [101.619898, 5.895343],
          [101.620474, 5.895419],
          [101.621023, 5.895369],
          [101.62149, 5.895445],
          [101.621901, 5.895673],
          [101.622121, 5.895622],
          [101.622231, 5.895521],
          [101.623054, 5.89319],
          [101.623329, 5.892531],
          [101.623466, 5.890884],
          [101.623411, 5.890048],
          [101.623246, 5.889262],
          [101.623109, 5.888983],
          [101.622642, 5.888477],
          [101.622395, 5.887945],
          [101.622258, 5.887412],
          [101.622231, 5.886146],
          [101.621874, 5.885233],
          [101.621901, 5.885056],
          [101.622148, 5.884752],
          [101.622368, 5.88422],
          [101.623081, 5.883511],
          [101.623822, 5.88199],
          [101.623987, 5.881484],
          [101.623877, 5.880698],
          [101.623438, 5.878848],
          [101.623136, 5.878063],
          [101.623246, 5.877328],
          [101.623328, 5.877125],
          [101.623658, 5.877075],
          [101.62396, 5.877125],
          [101.624261, 5.876973],
          [101.624838, 5.876847],
          [101.625277, 5.876644],
          [101.62599, 5.876391],
          [101.626567, 5.876289],
          [101.627829, 5.87634],
          [101.628214, 5.876264],
          [101.628845, 5.875935],
          [101.629174, 5.875859],
          [101.629531, 5.875884],
          [101.630135, 5.875808],
          [101.631287, 5.875504],
          [101.631727, 5.875428],
          [101.632056, 5.875301],
          [101.63266, 5.874972],
          [101.633071, 5.87482],
          [101.633428, 5.874592],
          [101.63373, 5.874338],
          [101.633812, 5.874059],
          [101.633922, 5.873857],
          [101.634279, 5.87373],
          [101.634636, 5.873705],
          [101.634965, 5.873831],
          [101.635459, 5.873857],
          [101.635706, 5.873705],
          [101.635788, 5.873553],
          [101.635734, 5.873147],
          [101.635487, 5.872564],
          [101.635569, 5.87226],
          [101.635843, 5.872134],
          [101.636639, 5.872032],
          [101.637874, 5.871247],
          [101.638588, 5.870715],
          [101.639082, 5.870436],
          [101.640042, 5.870309],
          [101.640427, 5.870385],
          [101.640701, 5.870512],
          [101.641031, 5.870791],
          [101.64125, 5.870765],
          [101.641497, 5.870563],
          [101.641964, 5.870335],
          [101.64254, 5.870208],
          [101.64276, 5.870284],
          [101.643281, 5.870867],
          [101.644242, 5.872159],
          [101.644516, 5.872463],
          [101.645092, 5.872717],
          [101.646163, 5.873451],
          [101.647123, 5.873984],
          [101.648111, 5.87444],
          [101.648578, 5.875022],
          [101.649291, 5.875352],
          [101.649813, 5.875377],
          [101.650444, 5.875504],
          [101.651158, 5.875656],
          [101.651679, 5.875833],
          [101.652201, 5.876112],
          [101.653161, 5.876898],
          [101.653518, 5.876974],
          [101.653847, 5.877176],
          [101.654122, 5.877455],
          [101.654588, 5.87748],
          [101.655055, 5.877379],
          [101.655549, 5.877202],
          [101.656016, 5.876974],
          [101.65629, 5.876669],
          [101.657388, 5.876087],
          [101.658705, 5.875251],
          [101.659089, 5.874972],
          [101.659336, 5.874668],
          [101.659474, 5.874389],
          [101.659446, 5.87373],
          [101.659529, 5.873223],
          [101.659638, 5.873021],
          [101.659556, 5.872641],
          [101.659336, 5.872337],
          [101.659199, 5.871931],
          [101.659172, 5.871602],
          [101.659282, 5.871323],
          [101.659556, 5.870994],
          [101.659913, 5.870715],
          [101.660297, 5.87069],
          [101.660462, 5.87074],
          [101.660736, 5.870639],
          [101.661093, 5.869803],
          [101.661313, 5.869398],
          [101.661999, 5.869094],
          [101.662355, 5.868714],
          [101.663426, 5.866484],
          [101.663426, 5.86623],
          [101.663289, 5.865926],
          [101.66274, 5.865495],
          [101.662218, 5.865318],
          [101.661642, 5.864963],
          [101.661011, 5.864811],
          [101.660544, 5.864634],
          [101.660133, 5.864153],
          [101.658815, 5.862176],
          [101.658513, 5.861264],
          [101.658294, 5.861036],
          [101.658129, 5.860479],
          [101.656894, 5.85944],
          [101.656736, 5.858854],
          [101.656428, 5.857996],
          [101.656373, 5.857337],
          [101.656455, 5.856805],
          [101.656894, 5.855969],
          [101.657333, 5.855411],
          [101.657608, 5.854372],
          [101.657992, 5.853916],
          [101.658568, 5.85346],
          [101.659062, 5.853131],
          [101.659748, 5.852852],
          [101.659995, 5.852573],
          [101.660078, 5.852295],
          [101.660078, 5.851535],
          [101.660242, 5.850901],
          [101.661532, 5.849128],
          [101.661697, 5.848621],
          [101.661807, 5.847861],
          [101.661724, 5.847075],
          [101.660434, 5.843629],
          [101.660434, 5.842514],
          [101.66027, 5.842058],
          [101.659968, 5.841501],
          [101.659227, 5.840867],
          [101.658513, 5.839955],
          [101.658074, 5.839043],
          [101.657553, 5.838207],
          [101.657416, 5.837903],
          [101.65747, 5.837624],
          [101.657827, 5.837244],
          [101.658321, 5.836839],
          [101.658788, 5.836256],
          [101.659007, 5.835673],
          [101.65876, 5.834964],
          [101.658815, 5.834685],
          [101.659062, 5.833975],
          [101.659145, 5.833063],
          [101.659392, 5.8321],
          [101.659501, 5.831492],
          [101.659364, 5.830099],
          [101.659392, 5.829085],
          [101.659666, 5.828299],
          [101.659748, 5.827919],
          [101.659639, 5.827463],
          [101.659364, 5.82683],
          [101.659172, 5.82607],
          [101.659062, 5.824677],
          [101.65887, 5.824195],
          [101.658513, 5.82379],
          [101.658102, 5.822523],
          [101.658102, 5.822194],
          [101.658321, 5.821611],
          [101.658431, 5.821129],
          [101.658129, 5.820344],
          [101.658047, 5.819533],
          [101.658102, 5.81857],
          [101.657772, 5.817227],
          [101.657827, 5.816873],
          [101.658541, 5.815935],
          [101.658925, 5.814668],
          [101.658952, 5.814162],
          [101.658651, 5.813858],
          [101.658349, 5.813402],
          [101.658239, 5.812895],
          [101.658266, 5.812515],
          [101.658815, 5.812008],
          [101.659172, 5.811907],
          [101.659584, 5.812109],
          [101.659913, 5.812185],
          [101.66038, 5.812109],
          [101.660489, 5.812337],
          [101.660544, 5.812692],
          [101.661258, 5.813173],
          [101.661944, 5.813756],
          [101.662493, 5.814288],
          [101.662685, 5.814744],
          [101.663097, 5.814896],
          [101.663536, 5.814947],
          [101.664057, 5.814694],
          [101.664524, 5.81439],
          [101.664853, 5.813959],
          [101.664935, 5.813604],
          [101.66488, 5.813097],
          [101.66521, 5.81254],
          [101.665567, 5.812059],
          [101.665649, 5.81145],
          [101.66628, 5.810386],
          [101.666911, 5.809195],
          [101.667295, 5.808866],
          [101.667597, 5.808511],
          [101.668036, 5.808283],
          [101.668174, 5.807979],
          [101.668064, 5.807574],
          [101.667295, 5.806915],
          [101.666939, 5.805876],
          [101.666582, 5.805319],
          [101.666267, 5.804963],
          [101.666307, 5.804482],
          [101.665813, 5.802532],
          [101.665402, 5.802101],
          [101.664798, 5.801645],
          [101.66477, 5.801341],
          [101.665429, 5.799618],
          [101.66617, 5.799415],
          [101.666692, 5.798655],
          [101.666966, 5.798528],
          [101.66757, 5.798427],
          [101.668091, 5.798224],
          [101.669656, 5.796501],
          [101.670589, 5.795614],
          [101.670753, 5.79531],
          [101.670589, 5.794753],
          [101.669656, 5.792497],
          [101.669601, 5.792041],
          [101.669711, 5.791611],
          [101.669546, 5.790926],
          [101.669958, 5.790673],
          [101.670342, 5.790318],
          [101.670644, 5.789913],
          [101.670918, 5.789634],
          [101.672153, 5.789001],
          [101.67229, 5.788519],
          [101.672345, 5.787835],
          [101.672537, 5.787227],
          [101.67273, 5.786948],
          [101.673443, 5.78705],
          [101.673992, 5.78705],
          [101.674596, 5.786847],
          [101.675008, 5.786365],
          [101.676215, 5.785301],
          [101.676517, 5.784769],
          [101.676819, 5.783325],
          [101.677533, 5.78221],
          [101.677917, 5.781881],
          [101.678384, 5.781678],
          [101.678933, 5.781526],
          [101.679427, 5.781501],
          [101.680113, 5.781754],
          [101.680744, 5.781881],
          [101.681567, 5.781931],
          [101.682336, 5.78183],
          [101.682802, 5.781931],
          [101.683159, 5.782311],
          [101.683488, 5.782565],
          [101.683598, 5.782945],
          [101.684065, 5.783173],
          [101.684998, 5.783401],
          [101.685574, 5.783376],
          [101.686123, 5.783198],
          [101.686809, 5.782894],
          [101.687413, 5.782413],
          [101.687852, 5.781855],
          [101.688181, 5.781172],
          [101.688346, 5.780538],
          [101.688401, 5.779702],
          [101.689152, 5.777873],
          [101.689553, 5.77656],
          [101.689636, 5.776104],
          [101.689444, 5.775724],
          [101.689224, 5.774989],
          [101.689279, 5.774558],
          [101.689498, 5.773874],
          [101.6898, 5.77352],
          [101.690514, 5.773089],
          [101.690706, 5.772607],
          [101.690761, 5.771721],
          [101.690569, 5.770074],
          [101.690843, 5.769567],
          [101.690871, 5.769187],
          [101.690541, 5.76835],
          [101.69013, 5.767033],
          [101.689773, 5.766222],
          [101.68884, 5.764981],
          [101.688071, 5.764727],
          [101.688044, 5.764474],
          [101.688209, 5.764246],
          [101.689032, 5.763866],
          [101.6898, 5.76341],
          [101.690177, 5.762673],
          [101.690294, 5.762244],
          [101.689855, 5.760927],
          [101.689883, 5.760547],
          [101.690102, 5.760217],
          [101.690514, 5.76004],
          [101.691118, 5.759635],
          [101.691401, 5.759292],
          [101.691612, 5.758418],
          [101.691557, 5.757658],
          [101.691365, 5.756619],
          [101.690816, 5.755758],
          [101.690953, 5.755505],
          [101.69131, 5.755099],
          [101.691667, 5.754973],
          [101.6910341, 5.7522572],
          [101.6905311, 5.7498454],
          [101.6901244, 5.7480909],
          [101.690722, 5.7469803],
          [101.6908686, 5.7461297],
          [101.6905213, 5.7452855],
          [101.690747, 5.7434571],
          [101.6909281, 5.7426184],
          [101.691252, 5.7412977],
          [101.6916996, 5.7391261],
          [101.6925752, 5.7344603],
          [101.6931272, 5.7321862],
          [101.6934254, 5.7311185],
          [101.6930278, 5.7296174],
          [101.6925397, 5.7279773],
          [101.6918557, 5.727475],
          [101.6904936, 5.7270463],
          [101.6894194, 5.7265284],
          [101.6886908, 5.725899],
          [101.6881632, 5.724742],
          [101.688529, 5.7238016],
          [101.6889276, 5.7230113],
          [101.6895147, 5.721797],
          [101.6904649, 5.7209893],
          [101.6909733, 5.7206838],
          [101.691426, 5.7202395],
          [101.6915817, 5.7196193],
          [101.6914175, 5.7188346],
          [101.6911966, 5.7179918],
          [101.6911601, 5.7170817],
          [101.691203, 5.7162877],
          [101.6911861, 5.7157002],
          [101.6912986, 5.7148148],
          [101.6912604, 5.7140775],
          [101.6912398, 5.7138469],
          [101.6917384, 5.7134031],
          [101.6922174, 5.7126366],
          [101.6923636, 5.7118091],
          [101.6920853, 5.7109886],
          [101.69176, 5.7102368],
          [101.6915019, 5.7096469],
          [101.6911756, 5.7089986],
          [101.6913982, 5.7085979],
          [101.6918485, 5.7083608],
          [101.6921839, 5.7081226],
          [101.6927254, 5.707967],
          [101.6930253, 5.7078435],
          [101.6932733, 5.7071898],
          [101.6933027, 5.7065568],
          [101.6934141, 5.705798],
          [101.6933753, 5.7051183],
          [101.6936134, 5.7042916],
          [101.6939552, 5.7034662],
          [101.6942636, 5.7025367],
          [101.6940485, 5.7022351],
          [101.6935604, 5.7016889],
          [101.6927983, 5.7009785],
          [101.6918751, 5.7002895],
          [101.6912674, 5.6991086],
          [101.6908763, 5.6980682],
          [101.6904392, 5.6970389],
          [101.68997, 5.6961532],
          [101.6892117, 5.6951071],
          [101.6888222, 5.694233],
          [101.6885011, 5.6936924],
          [101.6880074, 5.6932551],
          [101.6874788, 5.6932317],
          [101.6869382, 5.6934841],
          [101.6865014, 5.6935526],
          [101.6860193, 5.6931958],
          [101.6856867, 5.6927011],
          [101.6851471, 5.6921259],
          [101.6847341, 5.6916196],
          [101.6843326, 5.6911364],
          [101.6840459, 5.6906418],
          [101.6836439, 5.6903425],
          [101.6829547, 5.6901005],
          [101.6823343, 5.6900769],
          [101.68176, 5.6897775],
          [101.6814389, 5.6892139],
          [101.6809223, 5.6886616],
          [101.6803944, 5.6882012],
          [101.6796481, 5.6876258],
          [101.6790163, 5.6874182],
          [101.6782806, 5.6874406],
          [101.6776829, 5.6874055],
          [101.6772812, 5.6870603],
          [101.6769371, 5.6866116],
          [101.6765356, 5.6860824],
          [101.6760993, 5.6855991],
          [101.6757443, 5.6847136],
          [101.6755844, 5.6840582],
          [101.6753668, 5.6833911],
          [101.6749082, 5.6825286],
          [101.6744608, 5.6819993],
          [101.6737605, 5.6813548],
          [101.6729797, 5.6808253],
          [101.6724404, 5.680319],
          [101.6720155, 5.6798702],
          [101.6715566, 5.6793869],
          [101.6706048, 5.6777306],
          [101.6705369, 5.6769603],
          [101.6702967, 5.6762703],
          [101.6697227, 5.6756719],
          [101.6689875, 5.6754873],
          [101.6684479, 5.674958],
          [101.668288, 5.6744061],
          [101.667944, 5.6737504],
          [101.6674163, 5.6731521],
          [101.6671182, 5.672531],
          [101.6669353, 5.6719331],
          [101.6666948, 5.6711626],
          [101.6664197, 5.670576],
          [101.6661334, 5.6700469],
          [101.66572, 5.6696786],
          [101.6653645, 5.669046],
          [101.6650549, 5.6685859],
          [101.6646416, 5.66839],
          [101.6641357, 5.6684471],
          [101.6635839, 5.6686765],
          [101.6629514, 5.6689517],
          [101.662411, 5.6690547],
          [101.6617098, 5.6690195],
          [101.6609397, 5.6690763],
          [101.6605145, 5.6690874],
          [101.6600088, 5.6692134],
          [101.6598141, 5.6686268],
          [101.6598155, 5.6677532],
          [101.6596445, 5.6666838],
          [101.6593583, 5.6659708],
          [101.6592442, 5.6652235],
          [101.6590155, 5.664407],
          [101.6587863, 5.6638435],
          [101.6585, 5.6633489],
          [101.6584435, 5.662659],
          [101.6585477, 5.6621074],
          [101.6589504, 5.6615674],
          [101.6592843, 5.6612918],
          [101.6596754, 5.6609473],
          [101.6598827, 5.6605796],
          [101.6596768, 5.6599931],
          [101.6595739, 5.6594872],
          [101.6595863, 5.6588664],
          [101.6595296, 5.6583375],
          [101.6592544, 5.6578774],
          [101.6591749, 5.6573141],
          [101.6593708, 5.6568199],
          [101.6597159, 5.6565099],
          [101.6598548, 5.6560386],
          [101.6598438, 5.6555098],
          [101.6598216, 5.654889],
          [101.6595349, 5.6544748],
          [101.6593405, 5.6538884],
          [101.6593184, 5.6532561],
          [101.6590894, 5.6525201],
          [101.6589411, 5.6517957],
          [101.6588272, 5.6510713],
          [101.6586555, 5.6504504],
          [101.6586568, 5.6497146],
          [101.6586228, 5.6491513],
          [101.6584628, 5.6486108],
          [101.6581875, 5.6482886],
          [101.6580961, 5.6477482],
          [101.6579476, 5.6472882],
          [101.6577993, 5.6465409],
          [101.6576506, 5.645805],
          [101.6573531, 5.6450229],
          [101.6570895, 5.6444019],
          [101.6567919, 5.6436084],
          [101.656988, 5.6429993],
          [101.6572188, 5.6424592],
          [101.6574148, 5.6419075],
          [101.6574501, 5.6412638],
          [101.6574854, 5.6407235],
          [101.6575092, 5.6400797],
          [101.6576707, 5.639597],
          [101.6580161, 5.6392985],
          [101.6584299, 5.6391954],
          [101.6591427, 5.6390237],
          [101.6597288, 5.6388748],
          [101.6601889, 5.6386453],
          [101.6606722, 5.6383008],
          [101.6608912, 5.6377492],
          [101.6610526, 5.6373241],
          [101.6614671, 5.6368072],
          [101.6619045, 5.6363477],
          [101.6622382, 5.6359111],
          [101.662434, 5.6355895],
          [101.6626761, 5.6352333],
          [101.6628031, 5.6347735],
          [101.6627462, 5.6342562],
          [101.6625627, 5.6339226],
          [101.6624485, 5.6335317],
          [101.6623571, 5.6330718],
          [101.6625301, 5.6326121],
          [101.6628755, 5.6321985],
          [101.6631977, 5.6318769],
          [101.6633821, 5.6314057],
          [101.6632105, 5.6310147],
          [101.6627283, 5.6305659],
          [101.6622693, 5.6301516],
          [101.6618676, 5.6296454],
          [101.6615923, 5.6292427],
          [101.661271, 5.628978],
          [101.6605133, 5.6284715],
          [101.6600889, 5.6279423],
          [101.6597678, 5.6274131],
          [101.6594468, 5.6268381],
          [101.658965, 5.6262398],
          [101.658414, 5.6256415],
          [101.6579435, 5.6253766],
          [101.6576103, 5.6251923],
          [101.6573236, 5.6247285],
          [101.6573242, 5.6244333],
          [101.6571981, 5.6241803],
          [101.656992, 5.6235133],
          [101.6567173, 5.6228923],
          [101.6566028, 5.6225473],
          [101.6563735, 5.6220987],
          [101.656213, 5.6218112],
          [101.6560181, 5.621489],
          [101.6558576, 5.621121],
          [101.6555363, 5.6209252],
          [101.6551458, 5.6206375],
          [101.6551463, 5.6203731],
          [101.6554457, 5.6198446],
          [101.6555841, 5.6195228],
          [101.6556536, 5.6191895],
          [101.6557457, 5.6188561],
          [101.6557807, 5.6186032],
          [101.6557579, 5.6183963],
          [101.6556552, 5.6180859],
          [101.6555635, 5.6178328],
          [101.6555293, 5.6175684],
          [101.655541, 5.6173615],
          [101.6555186, 5.61712],
          [101.6555189, 5.6167407],
          [101.6556457, 5.6164763],
          [101.6558415, 5.6162581],
          [101.6559453, 5.6160168],
          [101.6560948, 5.6157755],
          [101.656279, 5.6155688],
          [101.6563714, 5.6153274],
          [101.6564865, 5.6150286],
          [101.6565216, 5.6146838],
          [101.6564299, 5.6144998],
          [101.6563846, 5.6141433],
          [101.6562931, 5.6137179],
          [101.6562245, 5.6133499],
          [101.656225, 5.613143],
          [101.6561908, 5.61289],
          [101.6561913, 5.6126602],
          [101.65618, 5.6124532],
          [101.6562266, 5.6120394],
          [101.6562499, 5.6117405],
          [101.656273, 5.6115336],
          [101.6562735, 5.6113497],
          [101.6562048, 5.6110622],
          [101.6561018, 5.6107632],
          [101.6559756, 5.6106021],
          [101.655838, 5.6103721],
          [101.6557003, 5.610234],
          [101.6555168, 5.6100269],
          [101.6552758, 5.6098428],
          [101.6551152, 5.6096816],
          [101.6549542, 5.609578],
          [101.6547935, 5.6094974],
          [101.6545754, 5.6094282],
          [101.6543914, 5.6094165],
          [101.6541273, 5.6093588],
          [101.6537597, 5.6092664],
          [101.6536105, 5.6091743],
          [101.6535417, 5.6089903],
          [101.6536337, 5.608841],
          [101.653841, 5.6085193],
          [101.6542667, 5.6082553],
          [101.6545772, 5.6081636],
          [101.6548069, 5.6080489],
          [101.6550715, 5.6078882],
          [101.6552097, 5.6077733],
          [101.6553591, 5.60767],
          [101.6556007, 5.6074978],
          [101.6558307, 5.6074176],
          [101.6560259, 5.6074178],
          [101.6562559, 5.6074065],
          [101.6564396, 5.6074296],
          [101.6566696, 5.6074069],
          [101.6569225, 5.6074071],
          [101.6571407, 5.6074189],
          [101.6573592, 5.6073961],
          [101.6575429, 5.6073733],
          [101.6577269, 5.6073849],
          [101.6578992, 5.6073621],
          [101.6581292, 5.6073738],
          [101.6584855, 5.6073511],
          [101.6587726, 5.6072365],
          [101.6589453, 5.6071446],
          [101.6590949, 5.6070298],
          [101.6593248, 5.6068116],
          [101.6595435, 5.6065359],
          [101.6596934, 5.6062372],
          [101.6597972, 5.6060648],
          [101.6600044, 5.6057776],
          [101.6601309, 5.6056168],
          [101.6602576, 5.6055019],
          [101.6604419, 5.6052377],
          [101.6606143, 5.6050884],
          [101.6608678, 5.6047898],
          [101.6609713, 5.6046059],
          [101.6611901, 5.6041692],
          [101.6612021, 5.6039853],
          [101.6611794, 5.6036979],
          [101.6611681, 5.603422],
          [101.6610649, 5.603238],
          [101.6610999, 5.6030655],
          [101.6612837, 5.6028932],
          [101.6614792, 5.6029394],
          [101.6617432, 5.6030087],
          [101.6619273, 5.6030318],
          [101.6621798, 5.6031241],
          [101.6628344, 5.6034235],
          [101.6632365, 5.6035389],
          [101.6634435, 5.6034816],
          [101.6634782, 5.6032632],
          [101.6637313, 5.6029761],
          [101.6639958, 5.6029189],
          [101.6643176, 5.6029191],
          [101.664605, 5.6028964],
          [101.6648003, 5.6028391],
          [101.6649729, 5.6027933],
          [101.6651337, 5.6027015],
          [101.665249, 5.6025176],
          [101.6653067, 5.6022073],
          [101.6653532, 5.6019314],
          [101.665434, 5.6017246],
          [101.6655146, 5.6015407],
          [101.6656299, 5.6013569],
          [101.6657793, 5.6012306],
          [101.6660207, 5.6012308],
          [101.6661009, 5.6014033],
          [101.6661697, 5.6015759],
          [101.6663074, 5.6017139],
          [101.6665024, 5.601944],
          [101.6667779, 5.6020133],
          [101.6669848, 5.6020595],
          [101.6672724, 5.6018988],
          [101.6674792, 5.601761],
          [101.6677667, 5.6015773],
          [101.6682383, 5.6013364],
          [101.6689167, 5.6010151],
          [101.6692616, 5.600912],
          [101.6694341, 5.6009351],
          [101.6696179, 5.6009123],
          [101.6697904, 5.6009124],
          [101.6700545, 5.6008897],
          [101.670296, 5.6009244],
          [101.6706293, 5.6009018],
          [101.670859, 5.600833],
          [101.6710431, 5.6007642],
          [101.6712961, 5.6007069],
          [101.6714684, 5.6006036],
          [101.6716066, 5.6004773],
          [101.6717101, 5.600282],
          [101.6719289, 5.5998913],
          [101.6722513, 5.5996501],
          [101.672447, 5.5993629],
          [101.6725163, 5.5989952],
          [101.6725972, 5.5986618],
          [101.6726667, 5.598386],
          [101.6727473, 5.5980297],
          [101.6729774, 5.5979379],
          [101.6732074, 5.5978807],
          [101.6734945, 5.5978349],
          [101.6739888, 5.5978929],
          [101.6747468, 5.5981465],
          [101.6750456, 5.5982272],
          [101.6751946, 5.5984918],
          [101.6753208, 5.5986988],
          [101.6754354, 5.5988714],
          [101.6756994, 5.5990096],
          [101.6758949, 5.5989983],
          [101.6760672, 5.5990099],
          [101.6763431, 5.5989297],
          [101.6765388, 5.5986999],
          [101.6767921, 5.5982863],
          [101.6770683, 5.5979187],
          [101.6772412, 5.5975739],
          [101.6776206, 5.5973329],
          [101.6778743, 5.5968158],
          [101.6780243, 5.5962871],
          [101.6781053, 5.5959078],
          [101.6780138, 5.5954709],
          [101.6780949, 5.5949881],
          [101.6782334, 5.5945743],
          [101.6782913, 5.594379],
          [101.6782572, 5.5939766],
          [101.6783044, 5.5931604],
          [101.6782144, 5.5917693],
          [101.6783294, 5.591528],
          [101.6784447, 5.5913557],
          [101.6785944, 5.5912293],
          [101.6789617, 5.5913331],
          [101.6791802, 5.5913218],
          [101.6793983, 5.5913795],
          [101.6796742, 5.5914027],
          [101.6798465, 5.5913684],
          [101.6802716, 5.5914148],
          [101.6806508, 5.5914612],
          [101.6809265, 5.5916339],
          [101.6810412, 5.5917604],
          [101.681248, 5.5916686],
          [101.6815584, 5.5916114],
          [101.6817654, 5.5915542],
          [101.6820184, 5.5914739],
          [101.6822023, 5.5913361],
          [101.6823405, 5.5911178],
          [101.6825365, 5.5905546],
          [101.682629, 5.5902329],
          [101.6828936, 5.5899802],
          [101.6832385, 5.5897965],
          [101.6836412, 5.5896129],
          [101.6841582, 5.589602],
          [101.6846869, 5.589407],
          [101.6850092, 5.5891429],
          [101.6852165, 5.5888672],
          [101.685573, 5.5884881],
          [101.6858723, 5.5880055],
          [101.6863905, 5.5872702],
          [101.6866787, 5.5867532],
          [101.6867365, 5.5861784],
          [101.6867028, 5.585753],
          [101.6866115, 5.5853621],
          [101.6864281, 5.58504],
          [101.6863135, 5.584741],
          [101.6858772, 5.5843497],
          [101.6856485, 5.5835793],
          [101.6853733, 5.5831191],
          [101.685213, 5.5826017],
          [101.6850184, 5.5822221],
          [101.6848579, 5.5818887],
          [101.6846511, 5.581693],
          [101.6843988, 5.5813364],
          [101.6841809, 5.5809913],
          [101.6839178, 5.5801978],
          [101.6837803, 5.5798298],
          [101.6837464, 5.5795309],
          [101.6834592, 5.5793467],
          [101.6832871, 5.5791741],
          [101.6829772, 5.5790588],
          [101.6826096, 5.5789436],
          [101.6820811, 5.5788741],
          [101.6816905, 5.5787702],
          [101.6818519, 5.5783795],
          [101.6815195, 5.5776665],
          [101.6812099, 5.5771834],
          [101.6810499, 5.5766314],
          [101.6808436, 5.5762174],
          [101.6806373, 5.5757573],
          [101.6805456, 5.5755158],
          [101.6805349, 5.5750559],
          [101.6803636, 5.574274],
          [101.680284, 5.5737336],
          [101.6800084, 5.573354],
          [101.6795727, 5.5726409],
          [101.6793778, 5.5723188],
          [101.6790452, 5.5718127],
          [101.6784486, 5.5713063],
          [101.678081, 5.571168],
          [101.6778172, 5.5708229],
          [101.6776797, 5.5704778],
          [101.6775885, 5.5700409],
          [101.6776117, 5.569742],
          [101.6778075, 5.5694318],
          [101.6781875, 5.5688688],
          [101.6786133, 5.5684554],
          [101.6788893, 5.5683061],
          [101.6791768, 5.5681109],
          [101.6793496, 5.5677778],
          [101.6794303, 5.56741],
          [101.679362, 5.567111],
          [101.6793393, 5.5668005],
          [101.679271, 5.5664211],
          [101.6792137, 5.5662601],
          [101.6792604, 5.5657314],
          [101.6793533, 5.5648692],
          [101.6794343, 5.5644784],
          [101.6793771, 5.5642254],
          [101.6792859, 5.563915],
          [101.6792861, 5.5636851],
          [101.6793207, 5.5634322],
          [101.6794481, 5.5629264],
          [101.6792648, 5.5624435],
          [101.6791502, 5.5621214],
          [101.6787489, 5.5614313],
          [101.6784737, 5.5610517],
          [101.6782559, 5.5605802],
          [101.678061, 5.5602926],
          [101.6777626, 5.5601198],
          [101.6772226, 5.5600964],
          [101.6766715, 5.559774],
          [101.6760631, 5.5591871],
          [101.6758229, 5.5585201],
          [101.675582, 5.5582439],
          [101.675444, 5.5581174],
          [101.6752718, 5.5580943],
          [101.6748006, 5.5582087],
          [101.6744672, 5.5583694],
          [101.6740307, 5.5581851],
          [101.6732267, 5.5578624],
          [101.6729627, 5.5577127],
          [101.6727562, 5.5575746],
          [101.6725841, 5.5573675],
          [101.6725154, 5.557149],
          [101.6725041, 5.5569421],
          [101.6724813, 5.5567581],
          [101.6725852, 5.5564249],
          [101.6726661, 5.5562294],
          [101.6727358, 5.5555512],
          [101.6727943, 5.5547235],
          [101.6726804, 5.5539647],
          [101.6725896, 5.5531254],
          [101.6725444, 5.552631],
          [101.6724414, 5.552194],
          [101.6724428, 5.5513433],
          [101.6723862, 5.5506765],
          [101.672421, 5.5502741],
          [101.6725942, 5.5498835],
          [101.6728244, 5.5495848],
          [101.6730771, 5.5494585],
          [101.6734908, 5.5494244],
          [101.6742377, 5.5494367],
          [101.674617, 5.5493106],
          [101.6748128, 5.5489888],
          [101.6748248, 5.5487819],
          [101.6749053, 5.5485405],
          [101.6748366, 5.548345],
          [101.6746765, 5.5480115],
          [101.6745618, 5.5478275],
          [101.6743894, 5.5476894],
          [101.6741369, 5.5475282],
          [101.6738846, 5.5472521],
          [101.6737241, 5.546953],
          [101.6736553, 5.5467575],
          [101.6734031, 5.5462974],
          [101.6730239, 5.5462855],
          [101.672806, 5.5460899],
          [101.6726109, 5.5459288],
          [101.6725192, 5.5457218],
          [101.6723587, 5.5455147],
          [101.6723593, 5.5450778],
          [101.6723366, 5.5448249],
          [101.6722449, 5.5445948],
          [101.6719812, 5.5442153],
          [101.6718666, 5.5439622],
          [101.6715684, 5.5435366],
          [101.6714539, 5.5432261],
          [101.67142, 5.5428928],
          [101.671225, 5.5426971],
          [101.6709952, 5.5425474],
          [101.6707312, 5.5424322],
          [101.6703291, 5.5424088],
          [101.6698351, 5.5423739],
          [101.6691347, 5.5419594],
          [101.6685951, 5.5416485],
          [101.6681816, 5.5413952],
          [101.6679062, 5.54128],
          [101.6672168, 5.5411874],
          [101.6667457, 5.54121],
          [101.6662403, 5.541106],
          [101.6658613, 5.5408758],
          [101.6656891, 5.5407606],
          [101.6651385, 5.5402313],
          [101.6647596, 5.539886],
          [101.664657, 5.5394376],
          [101.664554, 5.5390121],
          [101.6645317, 5.5385407],
          [101.6646243, 5.5380465],
          [101.6647629, 5.5375293],
          [101.665097, 5.5368513],
          [101.6653852, 5.5362078],
          [101.6653166, 5.5359203],
          [101.6652135, 5.5356214],
          [101.665375, 5.5350697],
          [101.6654329, 5.5348973],
          [101.6655943, 5.5345181],
          [101.6657442, 5.5340583],
          [101.6656181, 5.5338973],
          [101.6652504, 5.5338165],
          [101.6648253, 5.5338276],
          [101.6645379, 5.5338733],
          [101.6641587, 5.5339419],
          [101.663745, 5.533999],
          [101.6634232, 5.5340332],
          [101.6632395, 5.5339986],
          [101.6629981, 5.5339294],
          [101.6628721, 5.5336763],
          [101.6629415, 5.5333544],
          [101.6628958, 5.53309],
          [101.6630341, 5.5328372],
          [101.6632529, 5.5324351],
          [101.6634028, 5.5320788],
          [101.6635643, 5.5315732],
          [101.6636796, 5.5313318],
          [101.6637491, 5.5310675],
          [101.6637378, 5.530872],
          [101.6637838, 5.5306881],
          [101.663681, 5.5304696],
          [101.6634857, 5.5302166],
          [101.6633252, 5.529929],
          [101.6629695, 5.5296757],
          [101.6627515, 5.5295491],
          [101.662166, 5.5291116],
          [101.6617297, 5.5287779],
          [101.6612365, 5.5282486],
          [101.6608924, 5.5278689],
          [101.660663, 5.5275353],
          [101.6604107, 5.5272821],
          [101.6600661, 5.5270634],
          [101.6593888, 5.5265684],
          [101.6588721, 5.5263495],
          [101.6584819, 5.5261191],
          [101.6580455, 5.5258429],
          [101.6578619, 5.5257507],
          [101.6577243, 5.5255782],
          [101.6575638, 5.5252676],
          [101.6572774, 5.5246006],
          [101.6568073, 5.5238988],
          [101.6563828, 5.5234501],
          [101.6560154, 5.5231278],
          [101.6556942, 5.5228861],
          [101.6554993, 5.5225756],
          [101.6552124, 5.5223224],
          [101.655133, 5.521598],
          [101.6551679, 5.5211267],
          [101.6552948, 5.5208049],
          [101.6553643, 5.5203797],
          [101.6556061, 5.5199315],
          [101.6558826, 5.5195754],
          [101.6560435, 5.5193571],
          [101.6564002, 5.519185],
          [101.6568372, 5.5187715],
          [101.6571366, 5.5184385],
          [101.6574816, 5.5181169],
          [101.6578729, 5.5177379],
          [101.6581836, 5.5173013],
          [101.6585635, 5.5167959],
          [101.6587478, 5.5164971],
          [101.659012, 5.5163939],
          [101.6592878, 5.5163597],
          [101.6594721, 5.516061],
          [101.6600588, 5.5154292],
          [101.660301, 5.5148892],
          [101.6606695, 5.5143147],
          [101.661015, 5.5137747],
          [101.6612338, 5.5133725],
          [101.6614757, 5.5128209],
          [101.661971, 5.5120396],
          [101.6621209, 5.5116144],
          [101.6623284, 5.5110742],
          [101.6625701, 5.5106376],
          [101.6628582, 5.5102125],
          [101.6631343, 5.509822],
          [101.6632612, 5.5095692],
          [101.663733, 5.5089373],
          [101.6641588, 5.5085583],
          [101.6643775, 5.5082711],
          [101.6646761, 5.5080989],
          [101.6649292, 5.5079498],
          [101.665228, 5.5079385],
          [101.6655383, 5.5079503],
          [101.6658136, 5.5081575],
          [101.6662155, 5.5084223],
          [101.6667552, 5.5087332],
          [101.6670879, 5.5090324],
          [101.6672255, 5.509182],
          [101.6676962, 5.5095388],
          [101.6681209, 5.5097346],
          [101.6684655, 5.5099074],
          [101.6687757, 5.5099536],
          [101.6692008, 5.51],
          [101.6697063, 5.5099775],
          [101.6700509, 5.5100928],
          [101.6703263, 5.510277],
          [101.6708769, 5.5107948],
          [101.6714165, 5.5111747],
          [101.67183, 5.5113015],
          [101.6721747, 5.5113593],
          [101.6725879, 5.5115436],
          [101.6728866, 5.5116359],
          [101.673128, 5.511682],
          [101.6734494, 5.5117169],
          [101.6744144, 5.5118327],
          [101.6747018, 5.5117525],
          [101.6749547, 5.5116607],
          [101.675288, 5.5116036],
          [101.675564, 5.5114544],
          [101.6758285, 5.5113512],
          [101.6760815, 5.5112134],
          [101.6766444, 5.5111679],
          [101.6770694, 5.5112833],
          [101.6782413, 5.5113763],
          [101.678632, 5.5113537],
          [101.6789305, 5.5113079],
          [101.6791606, 5.5111587],
          [101.6795288, 5.5109291],
          [101.680379, 5.5109069],
          [101.6806318, 5.5107346],
          [101.6808623, 5.5103785],
          [101.6815414, 5.5094594],
          [101.6816798, 5.5090342],
          [101.6817493, 5.5086433],
          [101.681853, 5.5082296],
          [101.6820837, 5.5076435],
          [101.6822795, 5.5072988],
          [101.6825211, 5.5070921],
          [101.6828315, 5.5068854],
          [101.6830961, 5.5066328],
          [101.6834755, 5.5063457],
          [101.6837629, 5.5062425],
          [101.6840503, 5.5062197],
          [101.6842572, 5.5062314],
          [101.6845786, 5.5063122],
          [101.684889, 5.506232],
          [101.685142, 5.5060598],
          [101.6853491, 5.5058875],
          [101.6857974, 5.5056465],
          [101.6863839, 5.5051642],
          [101.6869014, 5.5049002],
          [101.6871429, 5.504797],
          [101.6874649, 5.5045674],
          [101.6878557, 5.5043377],
          [101.6881201, 5.504292],
          [101.6884993, 5.5042464],
          [101.6891659, 5.5041205],
          [101.6904182, 5.5041561],
          [101.6908549, 5.5040415],
          [101.6911193, 5.5039728],
          [101.6914069, 5.5036741],
          [101.6913727, 5.5034212],
          [101.6913731, 5.5032487],
          [101.6914307, 5.5030073],
          [101.6913509, 5.5027544],
          [101.6913626, 5.5025244],
          [101.6914549, 5.5022831],
          [101.6916044, 5.5020074],
          [101.6917541, 5.5018235],
          [101.6920417, 5.5015018],
          [101.6923642, 5.5010078],
          [101.692514, 5.5007206],
          [101.6926175, 5.5004332],
          [101.6925836, 5.5001573],
          [101.6925953, 5.4998354],
          [101.6926533, 5.499594],
          [101.6927912, 5.4993413],
          [101.6929409, 5.4992265],
          [101.6931708, 5.4992152],
          [101.6934808, 5.4992154],
          [101.694193, 5.499423],
          [101.6945029, 5.4995727],
          [101.6947557, 5.4996649],
          [101.6951578, 5.4997917],
          [101.6955021, 5.4999299],
          [101.6958238, 5.4999302],
          [101.696226, 5.4998501],
          [101.6965249, 5.4998043],
          [101.6966971, 5.4998045],
          [101.6970073, 5.4998853],
          [101.6972368, 5.5000694],
          [101.6976961, 5.5003227],
          [101.6981554, 5.5007026],
          [101.6982237, 5.501013],
          [101.6982579, 5.5013349],
          [101.6982229, 5.5016223],
          [101.6983719, 5.5019213],
          [101.6984865, 5.5022203],
          [101.6985896, 5.5024618],
          [101.6988535, 5.5026804],
          [101.6990255, 5.5028646],
          [101.6999324, 5.5034747],
          [101.7004491, 5.503774],
          [101.7007247, 5.5039927],
          [101.700977, 5.5043493],
          [101.701137, 5.5049587],
          [101.701147, 5.5059704],
          [101.7011118, 5.5065912],
          [101.701582, 5.5072468],
          [101.7020643, 5.5074428],
          [101.7026389, 5.5075122],
          [101.7030182, 5.5074435],
          [101.7033051, 5.5075013],
          [101.7038333, 5.5078236],
          [101.7047515, 5.5085372],
          [101.7050613, 5.5087904],
          [101.7055435, 5.5091702],
          [101.7064623, 5.5096308],
          [101.7075299, 5.510172],
          [101.7081388, 5.510391],
          [101.7090578, 5.5105182],
          [101.7097698, 5.5107143],
          [101.7102636, 5.5109561],
          [101.710711, 5.5114163],
          [101.7113195, 5.5117388],
          [101.7118594, 5.5117967],
          [101.7122042, 5.5117281],
          [101.712526, 5.5116363],
          [101.7128708, 5.5115907],
          [101.7131008, 5.5114874],
          [101.7136752, 5.5114073],
          [101.7147094, 5.5114197],
          [101.7152838, 5.5114662],
          [101.7161224, 5.5115589],
          [101.7170299, 5.5117321],
          [101.7178226, 5.5118592],
          [101.7185924, 5.5119978],
          [101.7192814, 5.5121594],
          [101.7196374, 5.5123895],
          [101.7202225, 5.5129764],
          [101.72113, 5.5131381],
          [101.7213945, 5.5130463],
          [101.7216934, 5.5129546],
          [101.7219808, 5.5128169],
          [101.7221301, 5.5127021],
          [101.7222569, 5.5124838],
          [101.7222919, 5.5121273],
          [101.7222347, 5.5118974],
          [101.7221315, 5.5116559],
          [101.7219252, 5.5112993],
          [101.7216393, 5.5101725],
          [101.7216055, 5.5098046],
          [101.721537, 5.5092757],
          [101.7213536, 5.5088616],
          [101.7212278, 5.5086202],
          [101.7210096, 5.5083095],
          [101.7209986, 5.5080796],
          [101.7211025, 5.5077463],
          [101.7211716, 5.5074934],
          [101.7216775, 5.5072639],
          [101.7220684, 5.5070228],
          [101.7224705, 5.5070577],
          [101.7228267, 5.5069775],
          [101.7230338, 5.5068052],
          [101.7232639, 5.5065985],
          [101.723333, 5.5063457],
          [101.7234025, 5.5059548],
          [101.7234145, 5.5056674],
          [101.7234377, 5.5053226],
          [101.7237153, 5.5038627],
          [101.7237618, 5.5034604],
          [101.7238659, 5.5029202],
          [101.7238437, 5.5022764],
          [101.7239133, 5.5018051],
          [101.7240172, 5.5014373],
          [101.7240862, 5.5012418],
          [101.7243162, 5.5011731],
          [101.7246038, 5.5008744],
          [101.725029, 5.5006678],
          [101.7253628, 5.5003347],
          [101.72557, 5.5000705],
          [101.7259609, 5.4997489],
          [101.7261795, 5.4995307],
          [101.7265358, 5.499393],
          [101.7268232, 5.4993358],
          [101.727996, 5.4986584],
          [101.7285019, 5.4983944],
          [101.7288579, 5.4983832],
          [101.7291337, 5.4984409],
          [101.7293637, 5.4983031],
          [101.7295818, 5.4983034],
          [101.7298347, 5.4982806],
          [101.7300646, 5.4983152],
          [101.7303745, 5.4983844],
          [101.7306274, 5.4984881],
          [101.7308911, 5.4989022],
          [101.7316135, 5.5000295],
          [101.7325074, 5.5017776],
          [101.7328391, 5.503031],
          [101.7330908, 5.5038244],
          [101.733343, 5.5043075],
          [101.7336188, 5.5044687],
          [101.7338019, 5.5047677],
          [101.7341809, 5.5050209],
          [101.7344333, 5.5053086],
          [101.7349728, 5.5057803],
          [101.7355011, 5.5059532],
          [101.7358454, 5.506149],
          [101.736029, 5.506333],
          [101.7362473, 5.5065057],
          [101.736362, 5.5066782],
          [101.7365685, 5.5068393],
          [101.7369476, 5.5070235],
          [101.7376022, 5.5071391],
          [101.7382338, 5.507381],
          [101.7387047, 5.5074618],
          [101.7390952, 5.5077265],
          [101.7392559, 5.5078532],
          [101.7394738, 5.5080602],
          [101.7396804, 5.5082213],
          [101.7398639, 5.5085089],
          [101.7399556, 5.5087044],
          [101.7401506, 5.508923],
          [101.7403225, 5.5092795],
          [101.7405638, 5.5094981],
          [101.7408851, 5.5097629],
          [101.7412298, 5.5097631],
          [101.7416115, 5.5096433],
          [101.741977, 5.5092464],
          [101.742058, 5.508821],
          [101.7420931, 5.5084072],
          [101.7422199, 5.5080624],
          [101.7424729, 5.5079247],
          [101.7428178, 5.5077755],
          [101.7432315, 5.5076954],
          [101.743737, 5.5075578],
          [101.7439785, 5.5074431],
          [101.7443234, 5.5072594],
          [101.744519, 5.5071216],
          [101.744772, 5.5069149],
          [101.7450595, 5.5066621],
          [101.7452437, 5.5063634],
          [101.7453017, 5.5060645],
          [101.7454973, 5.5058232],
          [101.7456354, 5.505444],
          [101.7458544, 5.5050993],
          [101.7460153, 5.504766],
          [101.7463492, 5.5044444],
          [101.7465909, 5.5040192],
          [101.7467522, 5.503548],
          [101.7467757, 5.5032376],
          [101.7468338, 5.5027548],
          [101.7471214, 5.5024446],
          [101.7478112, 5.5019508],
          [101.7482023, 5.5017787],
          [101.7483861, 5.5015374],
          [101.7483636, 5.501319],
          [101.7480765, 5.5001817],
          [101.7479861, 5.4998242],
          [101.7479753, 5.4993758],
          [101.7479534, 5.498318],
          [101.7480458, 5.4980078],
          [101.7480233, 5.4977318],
          [101.7478631, 5.4971109],
          [101.7478293, 5.4966051],
          [101.7478414, 5.4962142],
          [101.7480023, 5.4959499],
          [101.7480718, 5.4955016],
          [101.7484295, 5.4943177],
          [101.7487522, 5.4933638],
          [101.7489484, 5.4928581],
          [101.7489835, 5.4923293],
          [101.748915, 5.4918579],
          [101.7489618, 5.4910647],
          [101.7490891, 5.490513],
          [101.7491357, 5.4899496],
          [101.7491365, 5.4893173],
          [101.7492748, 5.4889381],
          [101.7493554, 5.4886508],
          [101.7492641, 5.4882483],
          [101.7492764, 5.4875126],
          [101.7492886, 5.4869837],
          [101.7494156, 5.4863746],
          [101.7496344, 5.4859149],
          [101.7498764, 5.4854667],
          [101.7499685, 5.4851334],
          [101.7496941, 5.484018],
          [101.7493735, 5.4832935],
          [101.7492246, 5.482707],
          [101.7492137, 5.4824197],
          [101.7492372, 5.4820288],
          [101.7492604, 5.4816839],
          [101.7491233, 5.4812355],
          [101.7487908, 5.4805569],
          [101.7486764, 5.479982],
          [101.7485393, 5.479545],
          [101.7485396, 5.4791311],
          [101.7486435, 5.4787289],
          [101.748713, 5.4783381],
          [101.7489086, 5.4782002],
          [101.7488973, 5.4779243],
          [101.7491283, 5.4768783],
          [101.7490715, 5.4763494],
          [101.7488543, 5.4755905],
          [101.7487282, 5.475234],
          [101.7486481, 5.4749351],
          [101.7486256, 5.4746592],
          [101.7485455, 5.4743142],
          [101.7484425, 5.4739233],
          [101.7482939, 5.4734173],
          [101.7482367, 5.4730608],
          [101.7480308, 5.4724743],
          [101.7476404, 5.4721866],
          [101.7473306, 5.471991],
          [101.7469055, 5.4719791],
          [101.7466298, 5.4718525],
          [101.7464461, 5.4717719],
          [101.7461019, 5.4715531],
          [101.7449193, 5.470851],
          [101.7446785, 5.4705403],
          [101.7443569, 5.4702182],
          [101.7441962, 5.4701605],
          [101.7438976, 5.4700224],
          [101.7437369, 5.4699303],
          [101.7435533, 5.4697692],
          [101.7433582, 5.4696656],
          [101.7430482, 5.4693435],
          [101.742773, 5.4689293],
          [101.742234, 5.4681472],
          [101.7420623, 5.4677791],
          [101.7420051, 5.4675722],
          [101.7419594, 5.4672732],
          [101.7419943, 5.4670433],
          [101.7419485, 5.4668364],
          [101.7418339, 5.4666523],
          [101.7416389, 5.4663418],
          [101.740687, 5.4650879],
          [101.7403311, 5.4646393],
          [101.7401362, 5.4643747],
          [101.7398953, 5.4640756],
          [101.7397462, 5.4639375],
          [101.7395282, 5.4637763],
          [101.7392525, 5.4635807],
          [101.7390689, 5.4633737],
          [101.7387363, 5.463017],
          [101.7384043, 5.462189],
          [101.7381746, 5.4618094],
          [101.7380259, 5.461499],
          [101.7380031, 5.4612919],
          [101.7378541, 5.4609815],
          [101.7377051, 5.460602],
          [101.7374758, 5.4602453],
          [101.7372235, 5.4599578],
          [101.7370514, 5.4597966],
          [101.7365231, 5.4595664],
          [101.7361675, 5.4591981],
          [101.7359607, 5.4590371],
          [101.7357891, 5.4586346],
          [101.7357549, 5.4583931],
          [101.735744, 5.4579562],
          [101.7357558, 5.4575194],
          [101.7358367, 5.457186],
          [101.7359634, 5.4569792],
          [101.7360784, 5.4567379],
          [101.7362053, 5.4563586],
          [101.7364935, 5.4556116],
          [101.7366547, 5.4552783],
          [101.7367815, 5.455083],
          [101.7371034, 5.4547958],
          [101.7373794, 5.4545661],
          [101.7376439, 5.4543939],
          [101.7378853, 5.4542791],
          [101.738069, 5.4542678],
          [101.7382531, 5.4541644],
          [101.7384368, 5.4541646],
          [101.7397704, 5.4536023],
          [101.7402301, 5.4532808],
          [101.7402078, 5.4527865],
          [101.740208, 5.4525106],
          [101.7400709, 5.4520966],
          [101.7400138, 5.4516596],
          [101.739991, 5.4514412],
          [101.7400375, 5.4511309],
          [101.7402217, 5.4507631],
          [101.7404176, 5.4502114],
          [101.7405329, 5.4499241],
          [101.7407516, 5.449522],
          [101.7408212, 5.4490391],
          [101.7407414, 5.4485677],
          [101.7407072, 5.4483032],
          [101.7405582, 5.4479238],
          [101.7405128, 5.4477283],
          [101.7404326, 5.4474523],
          [101.7403983, 5.4472223],
          [101.7404907, 5.446935],
          [101.7406408, 5.4461649],
          [101.7406529, 5.4457855],
          [101.7405956, 5.4455901],
          [101.7404465, 5.4453715],
          [101.7403667, 5.4450265],
          [101.7403096, 5.4446701],
          [101.7405281, 5.4444749],
          [101.7407125, 5.4440266],
          [101.7408163, 5.4436933],
          [101.7406215, 5.4432333],
          [101.7404388, 5.4423825],
          [101.7403931, 5.442026],
          [101.7404051, 5.4417501],
          [101.7405204, 5.4414398],
          [101.7406009, 5.4411984],
          [101.7407506, 5.4409801],
          [101.7408426, 5.4408307],
          [101.7408545, 5.4406238],
          [101.7408204, 5.4402099],
          [101.7407057, 5.4400144],
          [101.7405456, 5.4396119],
          [101.7404654, 5.4393704],
          [101.7404886, 5.4391405],
          [101.7404661, 5.4389106],
          [101.7404203, 5.4386806],
          [101.7403172, 5.4383816],
          [101.7402485, 5.4381286],
          [101.7402605, 5.4378527],
          [101.7403066, 5.4376343],
          [101.7404105, 5.4372436],
          [101.7404455, 5.4369562],
          [101.7404686, 5.4366457],
          [101.7404806, 5.4364159],
          [101.7404809, 5.4360364],
          [101.7404011, 5.4355996],
          [101.7403783, 5.4354041],
          [101.7404711, 5.4345534],
          [101.7404946, 5.4341855],
          [101.7403342, 5.4337601],
          [101.7403232, 5.4335531],
          [101.740243, 5.4332656],
          [101.7401973, 5.4330012],
          [101.7401978, 5.4327943],
          [101.7401521, 5.4324953],
          [101.7400949, 5.4321734],
          [101.7401529, 5.4317135],
          [101.7402001, 5.4308629],
          [101.7402118, 5.4305525],
          [101.7403158, 5.4299893],
          [101.7403853, 5.4295984],
          [101.7402596, 5.4291845],
          [101.7401566, 5.4288395],
          [101.7401338, 5.4285636],
          [101.7403639, 5.4282878],
          [101.740525, 5.42815],
          [101.7412722, 5.4276562],
          [101.7413531, 5.4272309],
          [101.7415028, 5.4269896],
          [101.7415952, 5.4266908],
          [101.7418139, 5.4263461],
          [101.7419288, 5.4261507],
          [101.7422167, 5.4257945],
          [101.7424234, 5.4256338],
          [101.742596, 5.4254385],
          [101.7426999, 5.4250707],
          [101.742746, 5.4248753],
          [101.7428154, 5.424588],
          [101.7428847, 5.4243465],
          [101.7428734, 5.4241281],
          [101.7430334, 5.4238526],
          [101.7431155, 5.4236002],
          [101.7432675, 5.4232216],
          [101.7433388, 5.4228656],
          [101.7434097, 5.422567],
          [101.743504, 5.4222342],
          [101.7436097, 5.4219013],
          [101.7438184, 5.4215922],
          [101.7439582, 5.4213056],
          [101.744179, 5.4209506],
          [101.7443769, 5.4205724],
          [101.7445164, 5.4203089],
          [101.7445529, 5.4200331],
          [101.7446938, 5.4195739],
          [101.7446277, 5.4191594],
          [101.7444802, 5.4188824],
          [101.7444019, 5.4185713],
          [101.7443699, 5.418226],
          [101.7443146, 5.4178922],
          [101.7442363, 5.4176155],
          [101.7441115, 5.4173616],
          [101.7440565, 5.4170392],
          [101.7440248, 5.4166019],
          [101.743981, 5.4162796],
          [101.7440412, 5.4159235],
          [101.7442164, 5.4154875],
          [101.7443347, 5.4149824],
          [101.7442683, 5.4145908],
          [101.7440644, 5.4141984],
          [101.7437782, 5.4140353],
          [101.7435037, 5.413861],
          [101.7432529, 5.4135602],
          [101.7431287, 5.4132833],
          [101.74312, 5.4128692],
          [101.743134, 5.4125012],
          [101.7432519, 5.412042],
          [101.7433809, 5.4116863],
          [101.743337, 5.411364],
          [101.7430751, 5.4110171],
          [101.7428362, 5.4107049],
          [101.7426883, 5.4104623],
          [101.7424602, 5.4102538],
          [101.7423238, 5.4100113],
          [101.7423144, 5.4097007],
          [101.7422591, 5.4094012],
          [101.7424114, 5.4089768],
          [101.7423905, 5.4086891],
          [101.7425085, 5.4082183],
          [101.7425349, 5.4077124],
          [101.7425842, 5.4072297],
          [101.7423456, 5.40686],
          [101.7421762, 5.4064103],
          [101.742076, 5.405961],
          [101.7418364, 5.4057293],
          [101.7416771, 5.4054982],
          [101.7414722, 5.4052207],
          [101.7414631, 5.4048756],
          [101.741442, 5.404588],
          [101.7414437, 5.4043695],
          [101.7413205, 5.4038856],
          [101.7413129, 5.4033104],
          [101.7412009, 5.402884],
          [101.7410088, 5.4024456],
          [101.7408276, 5.4020649],
          [101.7406235, 5.4016724],
          [101.740373, 5.4013371],
          [101.7402026, 5.40106],
          [101.7399866, 5.4007364],
          [101.7397817, 5.400482],
          [101.7394965, 5.4001925],
          [101.7392916, 5.399892],
          [101.7391215, 5.3995918],
          [101.7389967, 5.3993608],
          [101.7388377, 5.3991183],
          [101.7387013, 5.3988988],
          [101.738589, 5.3985184],
          [101.7385104, 5.3982303],
          [101.7385125, 5.3979658],
          [101.7385594, 5.3977821],
          [101.7385065, 5.3971492],
          [101.73859, 5.3967012],
          [101.7385462, 5.3963674],
          [101.7385375, 5.3959877],
          [101.7385065, 5.3954585],
          [101.7385208, 5.395056],
          [101.7385468, 5.3946191],
          [101.738503, 5.3942623],
          [101.7384136, 5.3939396],
          [101.7383924, 5.3936864],
          [101.7385426, 5.3935149],
          [101.738808, 5.3933443],
          [101.7390271, 5.3932193],
          [101.7392459, 5.3931402],
          [101.7395335, 5.3930732],
          [101.7397064, 5.3929938],
          [101.7400758, 5.3927089],
          [101.7402841, 5.3925033],
          [101.7405623, 5.3921371],
          [101.7407022, 5.3918161],
          [101.7407968, 5.3914257],
          [101.7407999, 5.3909771],
          [101.740756, 5.3907008],
          [101.7406089, 5.3903433],
          [101.7405306, 5.3900437],
          [101.7404746, 5.3898247],
          [101.740523, 5.3894915],
          [101.7404569, 5.389054],
          [101.740379, 5.3886969],
          [101.7403125, 5.3883284],
          [101.7402927, 5.3879027],
          [101.7404568, 5.3874092],
          [101.7407347, 5.3870776],
          [101.7409667, 5.3867226],
          [101.7411291, 5.3865053],
          [101.7412912, 5.3863338],
          [101.7416599, 5.3861408],
          [101.7418678, 5.3859698],
          [101.7420988, 5.3857874],
          [101.7422838, 5.3856046],
          [101.7425958, 5.3853192],
          [101.7428497, 5.3851829],
          [101.743161, 5.384978],
          [101.743634, 5.3846822],
          [101.7439226, 5.3844657],
          [101.7440739, 5.3841907],
          [101.7442958, 5.3836516],
          [101.7441612, 5.3831792],
          [101.7440367, 5.3828792],
          [101.7439017, 5.3824642],
          [101.7437313, 5.3821985],
          [101.7436304, 5.3818528],
          [101.7435765, 5.3813464],
          [101.743614, 5.3808981],
          [101.7438349, 5.3804855],
          [101.7440901, 5.3801307],
          [101.744448, 5.3798457],
          [101.7447819, 5.3797099],
          [101.7452542, 5.3795407],
          [101.7455648, 5.3794738],
          [101.7459443, 5.3794074],
          [101.7463126, 5.3792834],
          [101.7466235, 5.3791705],
          [101.7468197, 5.3790683],
          [101.7471309, 5.3788979],
          [101.7474888, 5.3786128],
          [101.7478808, 5.3783856],
          [101.7482849, 5.3780893],
          [101.7484581, 5.3779295],
          [101.7486432, 5.3777582],
          [101.7488404, 5.3774605],
          [101.7489913, 5.377266],
          [101.7491993, 5.3770374],
          [101.7495112, 5.3767865],
          [101.749778, 5.3764088],
          [101.7499644, 5.3760075],
          [101.7502308, 5.3757103],
          [101.7505316, 5.3754018],
          [101.7507285, 5.3751616],
          [101.7510519, 5.3748993],
          [101.7511796, 5.3746817],
          [101.7514908, 5.3744997],
          [101.7517444, 5.374398],
          [101.7521127, 5.374274],
          [101.752424, 5.3740922],
          [101.752666, 5.3740017],
          [101.7528841, 5.3739918],
          [101.753332, 5.3740293],
          [101.7530753, 5.3729119],
          [101.7528023, 5.3725075],
          [101.7526565, 5.3719889],
          [101.7524176, 5.3716767],
          [101.7522353, 5.3714339],
          [101.7519386, 5.3711789],
          [101.751322, 5.370634],
          [101.7505689, 5.3699273],
          [101.7502726, 5.3695572],
          [101.7499655, 5.3691295],
          [101.7495794, 5.3684943],
          [101.74893, 5.3676847],
          [101.7484744, 5.367141],
          [101.7480869, 5.3666898],
          [101.7477099, 5.3664226],
          [101.7472407, 5.3661203],
          [101.7468181, 5.3657954],
          [101.7462679, 5.3656191],
          [101.745558, 5.3652922],
          [101.7451118, 5.3650361],
          [101.7447574, 5.3648036],
          [101.7442892, 5.3644208],
          [101.7441431, 5.3639368],
          [101.7441584, 5.3633618],
          [101.7441956, 5.3629595],
          [101.7442335, 5.3624767],
          [101.7442818, 5.362132],
          [101.7443309, 5.3616607],
          [101.7441267, 5.3612913],
          [101.7437399, 5.3607366],
          [101.7433873, 5.3602511],
          [101.7429191, 5.3598223],
          [101.742372, 5.3592319],
          [101.7419964, 5.3587118],
          [101.7416208, 5.3582261],
          [101.7413477, 5.3578677],
          [101.7411317, 5.3575327],
          [101.7410309, 5.3571639],
          [101.7406667, 5.3566553],
          [101.7400615, 5.3561221],
          [101.7396626, 5.3556938],
          [101.73924, 5.3553114],
          [101.7389208, 5.3549871],
          [101.7388091, 5.3545263],
          [101.7386296, 5.3539154],
          [101.7384842, 5.3533049],
          [101.7380401, 5.3527497],
          [101.7374801, 5.3523433],
          [101.7369995, 5.3520755],
          [101.7363898, 5.3522323],
          [101.7359648, 5.3522409],
          [101.7356424, 5.3523422],
          [101.7349427, 5.3522223],
          [101.734174, 5.3521135],
          [101.7333693, 5.3522345],
          [101.7329554, 5.3522546],
          [101.7324727, 5.3523433],
          [101.7319329, 5.3523281],
          [101.7315542, 5.3522565],
          [101.7309355, 5.3520222],
          [101.7303511, 5.3518456],
          [101.7296636, 5.3516338],
          [101.7289423, 5.3512953],
          [101.728255, 5.35098],
          [101.7275445, 5.3507796],
          [101.7269592, 5.350695],
          [101.7263512, 5.3505988],
          [101.7256146, 5.3508237],
          [101.7250877, 5.3506016],
          [101.7245949, 5.3504487],
          [101.7241261, 5.3501694],
          [101.7236107, 5.3499243],
          [101.7233258, 5.3496003],
          [101.722904, 5.3491719],
          [101.7224946, 5.3485479],
          [101.7223148, 5.3479716],
          [101.7220654, 5.3475098],
          [101.7217131, 5.3469553],
          [101.7214057, 5.3465391],
          [101.7210398, 5.3463295],
          [101.7205943, 5.3459815],
          [101.7201478, 5.3457368],
          [101.7198163, 5.345539],
          [101.7192651, 5.3455122],
          [101.7189542, 5.345625],
          [101.718437, 5.345702],
          [101.717827, 5.3458702],
          [101.717161, 5.3458426],
          [101.716392, 5.3457454],
          [101.7156586, 5.3455217],
          [101.7150631, 5.3452646],
          [101.7143429, 5.3447535],
          [101.7138393, 5.34452],
          [101.713335, 5.3443785],
          [101.7128304, 5.344283],
          [101.7123713, 5.3442338],
          [101.7117404, 5.3440914],
          [101.7110059, 5.3439943],
          [101.7106623, 5.3438769],
          [101.7100812, 5.3432173],
          [101.7094308, 5.3425687],
          [101.7090215, 5.3419678],
          [101.7084623, 5.3414694],
          [101.7077417, 5.3410157],
          [101.7070552, 5.3406199],
          [101.7065505, 5.3405129],
          [101.7059544, 5.3403593],
          [101.7051859, 5.340216],
          [101.7045897, 5.3400853],
          [101.7042809, 5.3398762],
          [101.7041571, 5.3395072],
          [101.7041032, 5.3390238],
          [101.7039575, 5.3385052],
          [101.7038115, 5.3380096],
          [101.7034014, 5.3375007],
          [101.7032804, 5.3367177],
          [101.7032512, 5.3359699],
          [101.7031403, 5.335394],
          [101.7028799, 5.3348517],
          [101.7023669, 5.3343191],
          [101.7018984, 5.3339593],
          [101.7014874, 5.3335884],
          [101.700973, 5.3332282],
          [101.7004597, 5.3327302],
          [101.6999214, 5.3325078],
          [101.6992675, 5.3324113],
          [101.6987045, 5.3324074],
          [101.6979119, 5.3324479],
          [101.6973496, 5.3323864],
          [101.6968571, 5.3321645],
          [101.6962047, 5.3318724],
          [101.6956208, 5.3316038],
          [101.6953099, 5.3317167],
          [101.6950667, 5.3320141],
          [101.6947781, 5.3321961],
          [101.694398, 5.3323889],
          [101.6940519, 5.3325936],
          [101.6936951, 5.3327175],
          [101.6929251, 5.3327583],
          [101.6923047, 5.3327999],
          [101.6919013, 5.3329927],
          [101.6914512, 5.3333115],
          [101.6909789, 5.3335153],
          [101.690484, 5.3336614],
          [101.6897015, 5.3339205],
          [101.6888164, 5.3340293],
          [101.6880694, 5.3340816],
          [101.6874723, 5.334043],
          [101.6868873, 5.3339584],
          [101.6863357, 5.333966],
          [101.6858419, 5.3339971],
          [101.6854162, 5.3340746],
          [101.6847495, 5.3341965],
          [101.6840014, 5.3343868],
          [101.6833225, 5.3345777],
          [101.6827469, 5.3347807],
          [101.6823449, 5.3348009],
          [101.6817825, 5.3347395],
          [101.6812435, 5.3345977],
          [101.6808888, 5.3344112],
          [101.6805334, 5.3343282],
          [101.6799254, 5.3342319],
          [101.6794779, 5.3341713],
          [101.6790296, 5.3342142],
          [101.6788574, 5.3342244],
          [101.6784794, 5.3340608],
          [101.6780673, 5.3338739],
          [101.67785, 5.3337114],
          [101.6778169, 5.3335387],
          [101.677738, 5.3333425],
          [101.6776709, 5.333043],
          [101.6776069, 5.3323295],
          [101.6773712, 5.3315687],
          [101.6769605, 5.3311518],
          [101.6766865, 5.3309199],
          [101.6764149, 5.3303544],
          [101.6762796, 5.3299509],
          [101.6758589, 5.3293498],
          [101.675612, 5.328497],
          [101.6751912, 5.3279305],
          [101.6747927, 5.3274331],
          [101.6743803, 5.3272462],
          [101.6738517, 5.3273001],
          [101.6734385, 5.3272627],
          [101.6731989, 5.3270425],
          [101.6727434, 5.3264758],
          [101.672276, 5.3259549],
          [101.6716587, 5.3255595],
          [101.671176, 5.3256136],
          [101.6706686, 5.3259321],
          [101.6700367, 5.3259622],
          [101.6692326, 5.3259336],
          [101.6686814, 5.3259297],
          [101.6682668, 5.3260994],
          [101.6679312, 5.3264651],
          [101.6680195, 5.3269833],
          [101.6681652, 5.3275364],
          [101.6679523, 5.328386],
          [101.6676604, 5.3290741],
          [101.6672997, 5.3297501],
          [101.6670541, 5.3303924],
          [101.6668426, 5.3310811],
          [101.6665063, 5.3315733],
          [101.6659623, 5.3321446],
          [101.6653273, 5.3326231],
          [101.6649099, 5.3331953],
          [101.6646764, 5.3337458],
          [101.664353, 5.3339851],
          [101.6638244, 5.3340619],
          [101.6634001, 5.3339784],
          [101.6630791, 5.3338841],
          [101.6623314, 5.3340399],
          [101.6619745, 5.3341409],
          [101.6616413, 5.3341845],
          [101.6611934, 5.33417],
          [101.6607096, 5.3343966],
          [101.6602248, 5.3347383],
          [101.6599577, 5.3351619],
          [101.6596217, 5.3355621],
          [101.6593208, 5.3359166],
          [101.6588707, 5.3362239],
          [101.6582367, 5.3365186],
          [101.6575578, 5.3367208],
          [101.6570041, 5.3370735],
          [101.6565541, 5.3373923],
          [101.6561155, 5.3376998],
          [101.6559642, 5.3379518],
          [101.6558129, 5.3382497],
          [101.6557752, 5.3387096],
          [101.6558277, 5.3394345],
          [101.6556047, 5.3401116],
          [101.6551536, 5.3405915],
          [101.6544847, 5.3409778],
          [101.6541032, 5.3413086],
          [101.6534691, 5.3416837],
          [101.6528573, 5.342128],
          [101.6522577, 5.3424459],
          [101.6516004, 5.3428323],
          [101.6511378, 5.3432891],
          [101.6505608, 5.3436761],
          [101.6498356, 5.3439125],
          [101.6490764, 5.3440567],
          [101.6484215, 5.3441327],
          [101.6478807, 5.3442439],
          [101.6473979, 5.3442864],
          [101.6468019, 5.3441212],
          [101.6464367, 5.3437966],
          [101.6457972, 5.3432745],
          [101.6452036, 5.3427413],
          [101.6445637, 5.3422767],
          [101.643991, 5.3420426],
          [101.6433712, 5.3419808],
          [101.6428767, 5.3421039],
          [101.6425334, 5.3419289],
          [101.6421339, 5.341558],
          [101.641918, 5.341223],
          [101.6416899, 5.3409799],
          [101.6414976, 5.3405875],
          [101.641363, 5.340115],
          [101.641297, 5.3396659],
          [101.6410362, 5.3391925],
          [101.640486, 5.3390621],
          [101.6401863, 5.3391865],
          [101.6395644, 5.3394582],
          [101.6388611, 5.3398328],
          [101.6383551, 5.3399327],
          [101.63792, 5.3397341],
          [101.6373351, 5.3396035],
          [101.6368193, 5.3394733],
          [101.6362229, 5.3393312],
          [101.6358553, 5.3393516],
          [101.6355444, 5.3394643],
          [101.6350025, 5.3397941],
          [101.6345987, 5.3400443],
          [101.6345826, 5.3406767],
          [101.6344761, 5.341136],
          [101.6341426, 5.3412028],
          [101.6335451, 5.341233],
          [101.6326944, 5.3413305],
          [101.6318899, 5.3414284],
          [101.631153, 5.3416762],
          [101.6305791, 5.3416376],
          [101.6301764, 5.3417498],
          [101.6296811, 5.3419188],
          [101.6293125, 5.3420773],
          [101.6288394, 5.342419],
          [101.6285034, 5.3428076],
          [101.6281218, 5.3431959],
          [101.6275782, 5.3437442],
          [101.6271951, 5.3443281],
          [101.6268577, 5.3449123],
          [101.6267164, 5.3454173],
          [101.626426, 5.3458983],
          [101.6262965, 5.346369],
          [101.6260745, 5.3468965],
          [101.6258286, 5.3475389],
          [101.6254581, 5.3479733],
          [101.6250202, 5.3482002],
          [101.6245361, 5.3484153],
          [101.6241201, 5.3487804],
          [101.623704, 5.3491685],
          [101.6232765, 5.3495336],
          [101.6228491, 5.3498871],
          [101.6223284, 5.3504125],
          [101.6218664, 5.3507657],
          [101.6213016, 5.3510838],
          [101.6208066, 5.3512298],
          [101.6204161, 5.3512385],
          [101.6199455, 5.3511777],
          [101.6195553, 5.351129],
          [101.619235, 5.3509656],
          [101.6189144, 5.3508483],
          [101.6185485, 5.3505928],
          [101.6182516, 5.3503491],
          [101.6179206, 5.3500362],
          [101.6176013, 5.3497349],
          [101.6171555, 5.3494212],
          [101.6168585, 5.3491891],
          [101.616195, 5.3487933],
          [101.6155427, 5.3484552],
          [101.6147177, 5.3481963],
          [101.6140277, 5.3482949],
          [101.6133029, 5.3484968],
          [101.612728, 5.3486077],
          [101.6121527, 5.3487072],
          [101.6115782, 5.3487721],
          [101.6111417, 5.3487575],
          [101.6106597, 5.3487196],
          [101.6102379, 5.348268],
          [101.6097354, 5.3478504],
          [101.6093226, 5.3477555],
          [101.6088169, 5.3477864],
          [101.608289, 5.3477482],
          [101.6078995, 5.3475959],
          [101.6074192, 5.3473164],
          [101.6070997, 5.3470037],
          [101.6067116, 5.3466559],
          [101.606382, 5.3461935],
          [101.6059039, 5.3455689],
          [101.6056321, 5.3450379],
          [101.6053842, 5.3443577],
          [101.6051486, 5.3435739],
          [101.6048082, 5.3429619],
          [101.6046622, 5.3424662],
          [101.6044015, 5.3420158],
          [101.6041992, 5.3413588],
          [101.6041923, 5.3407262],
          [101.6043455, 5.3401867],
          [101.6042591, 5.3393696],
          [101.6040327, 5.3389309],
          [101.6036669, 5.3386523],
          [101.6031386, 5.3386485],
          [101.6026351, 5.3384264],
          [101.6022356, 5.338044],
          [101.6020548, 5.3376172],
          [101.6016918, 5.3369706],
          [101.6012489, 5.3362543],
          [101.6009863, 5.3360569],
          [101.6007016, 5.3356983],
          [101.6001988, 5.3353497],
          [101.5998226, 5.334933],
          [101.5995263, 5.3345973],
          [101.5994026, 5.3342284],
          [101.5994861, 5.3338035],
          [101.599626, 5.3335169],
          [101.5998584, 5.333116],
          [101.6000327, 5.3328527],
          [101.6002647, 5.3324978],
          [101.6003468, 5.3322799],
          [101.6002339, 5.332003],
          [101.5996489, 5.3318953],
          [101.5989926, 5.3321438],
          [101.5985891, 5.3323479],
          [101.5979332, 5.3325042],
          [101.5974738, 5.3325355],
          [101.5966579, 5.3325757],
          [101.5958205, 5.3324432],
          [101.59512, 5.3324268],
          [101.5941675, 5.3323279],
          [101.5937217, 5.3320257],
          [101.5934362, 5.3317822],
          [101.5932092, 5.331401],
          [101.5930277, 5.3310777],
          [101.592663, 5.3306956],
          [101.5922635, 5.3303131],
          [101.5916692, 5.3299294],
          [101.5909021, 5.3295789],
          [101.5904316, 5.329518],
          [101.5899152, 5.3294568],
          [101.5893521, 5.3294988],
          [101.5890071, 5.3295998],
          [101.5884196, 5.3298028],
          [101.5878903, 5.3299714],
          [101.5874631, 5.3302675],
          [101.5871052, 5.3305295],
          [101.586724, 5.3308717],
          [101.58601, 5.3311312],
          [101.5851596, 5.3311711],
          [101.5843104, 5.331096],
          [101.5831036, 5.3312369],
          [101.5822661, 5.331093],
          [101.581692, 5.3310544],
          [101.5808546, 5.3309219],
          [101.5802008, 5.3307908],
          [101.5796732, 5.3306949],
          [101.579146, 5.3305186],
          [101.5785507, 5.3302959],
          [101.5781153, 5.3301203],
          [101.577658, 5.3298294],
          [101.5772471, 5.3294584],
          [101.5767665, 5.3292135],
          [101.5763559, 5.3287965],
          [101.5759578, 5.3282762],
          [101.5756626, 5.3278025],
          [101.5754704, 5.327364],
          [101.575186, 5.3269824],
          [101.5749357, 5.3266127],
          [101.574709, 5.32622],
          [101.5743436, 5.3258953],
          [101.573967, 5.3255821],
          [101.573843, 5.3252592],
          [101.5737648, 5.3249251],
          [101.5738362, 5.3245805],
          [101.573918, 5.3243741],
          [101.574069, 5.3241336],
          [101.5742199, 5.3239392],
          [101.5744387, 5.3238373],
          [101.5747274, 5.3236323],
          [101.5748432, 5.3234721],
          [101.575029, 5.3231744],
          [101.5750774, 5.3228642],
          [101.5751868, 5.3219909],
          [101.5752156, 5.321163],
          [101.5751514, 5.3205069],
          [101.5748907, 5.3200105],
          [101.5745951, 5.3195944],
          [101.5743108, 5.3191898],
          [101.574003, 5.3188771],
          [101.5737642, 5.3185188],
          [101.5735369, 5.3181836],
          [101.5734253, 5.3177573],
          [101.573383, 5.3172164],
          [101.5734666, 5.316757],
          [101.5736197, 5.316252],
          [101.5736807, 5.3157349],
          [101.5737065, 5.3153555],
          [101.573652, 5.3149411],
          [101.5735165, 5.3146181],
          [101.5732314, 5.314294],
          [101.5729828, 5.3137516],
          [101.572848, 5.3133136],
          [101.5728634, 5.3127732],
          [101.5729488, 5.3120607],
          [101.5729517, 5.3116467],
          [101.5729894, 5.3111869],
          [101.5730614, 5.3107618],
          [101.5729732, 5.3102322],
          [101.5728962, 5.3097486],
          [101.5728994, 5.3093115],
          [101.572639, 5.3087921],
          [101.5723328, 5.3082033],
          [101.5721298, 5.3076728],
          [101.5721101, 5.3072471],
          [101.5721585, 5.3068794],
          [101.5722428, 5.3063509],
          [101.5722923, 5.3058568],
          [101.5721571, 5.3054763],
          [101.5720804, 5.3049696],
          [101.5718196, 5.3044847],
          [101.5716045, 5.3040461],
          [101.5712513, 5.3036411],
          [101.5710816, 5.3032948],
          [101.5706259, 5.3027625],
          [101.5701589, 5.3022301],
          [101.5698064, 5.301733],
          [101.5695443, 5.3014091],
          [101.5695145, 5.3007878],
          [101.5696577, 5.3000067],
          [101.5699145, 5.2994105],
          [101.570159, 5.2989522],
          [101.5703107, 5.2986082],
          [101.5704613, 5.2984368],
          [101.570693, 5.2981394],
          [101.5712489, 5.2974878],
          [101.571459, 5.2970292],
          [101.5717603, 5.2966634],
          [101.5718772, 5.2963306],
          [101.572064, 5.2959409],
          [101.5724008, 5.2953798],
          [101.5725425, 5.2948517],
          [101.5726382, 5.2943003],
          [101.5728154, 5.2936],
          [101.5730506, 5.2928311],
          [101.5731478, 5.2920842],
          [101.5732321, 5.2915098],
          [101.5729137, 5.2910935],
          [101.5724217, 5.2908023],
          [101.5720093, 5.2906615],
          [101.5715625, 5.2905202],
          [101.5711034, 5.290471],
          [101.5707125, 5.2905371],
          [101.5703231, 5.2904193],
          [101.5700495, 5.2900954],
          [101.5699379, 5.289669],
          [101.5698612, 5.2891164],
          [101.569796, 5.2885869],
          [101.5696848, 5.2881145],
          [101.5695493, 5.287757],
          [101.5692882, 5.2873641],
          [101.5687144, 5.2873025],
          [101.568373, 5.2868285],
          [101.5681909, 5.2865972],
          [101.5675715, 5.2864892],
          [101.56693, 5.2862546],
          [101.5663558, 5.2862736],
          [101.5659872, 5.2864088],
          [101.5656175, 5.2867283],
          [101.5652348, 5.2872545],
          [101.5647252, 5.2878605],
          [101.5641923, 5.2885007],
          [101.563602, 5.2891636],
          [101.5631401, 5.2894939],
          [101.5627252, 5.2897439],
          [101.562161, 5.289947],
          [101.5616547, 5.2900468],
          [101.561115, 5.2900659],
          [101.5605197, 5.2898086],
          [101.560339, 5.2893473],
          [101.5600661, 5.2889774],
          [101.559701, 5.2886526],
          [101.5593589, 5.2882821],
          [101.5590523, 5.2878083],
          [101.5590097, 5.2873365],
          [101.5591054, 5.2867967],
          [101.5591194, 5.2864517],
          [101.558905, 5.2859327],
          [101.5586321, 5.2855511],
          [101.5584385, 5.2853082],
          [101.5581315, 5.284892],
          [101.5577553, 5.2844982],
          [101.5574829, 5.2840248],
          [101.5571977, 5.2837696],
          [101.5565462, 5.2833164],
          [101.5559853, 5.2830594],
          [101.5554815, 5.2828487],
          [101.5549436, 5.2826148],
          [101.5543124, 5.2825297],
          [101.5537254, 5.2827097],
          [101.5532294, 5.2830281],
          [101.552793, 5.2830364],
          [101.5524039, 5.2828151],
          [101.5520952, 5.2826289],
          [101.5520052, 5.2823752],
          [101.5518467, 5.282052],
          [101.5517122, 5.2815796],
          [101.5515408, 5.2814518],
          [101.5514293, 5.281014],
          [101.5512472, 5.2807481],
          [101.5506741, 5.2806174],
          [101.5502933, 5.2808678],
          [101.5499246, 5.2810491],
          [101.5494186, 5.2811375],
          [101.5488914, 5.2809842],
          [101.5483865, 5.2809116],
          [101.5479526, 5.2805634],
          [101.5476439, 5.2804002],
          [101.5472789, 5.2800295],
          [101.546869, 5.279555],
          [101.5468034, 5.279083],
          [101.5467019, 5.2788177],
          [101.5467385, 5.2784959],
          [101.5468669, 5.2782209],
          [101.5470071, 5.2778998],
          [101.5470097, 5.2775089],
          [101.5469322, 5.2771171],
          [101.5467963, 5.2768402],
          [101.5465457, 5.2765624],
          [101.5464109, 5.2761243],
          [101.5463915, 5.2756181],
          [101.5465558, 5.2751593],
          [101.5467774, 5.2746663],
          [101.5470461, 5.2740127],
          [101.5471645, 5.2735075],
          [101.5469378, 5.2730802],
          [101.5465846, 5.2726867],
          [101.5464272, 5.2722371],
          [101.5464882, 5.2717428],
          [101.5466521, 5.2713185],
          [101.5468734, 5.2708486],
          [101.5471624, 5.2705862],
          [101.54743, 5.2701165],
          [101.5474902, 5.2697259],
          [101.5475394, 5.2692547],
          [101.5476341, 5.2688413],
          [101.5477173, 5.2684853],
          [101.5477543, 5.2681175],
          [101.5477801, 5.2677152],
          [101.5476568, 5.2672772],
          [101.5473169, 5.2666308],
          [101.5469404, 5.266283],
          [101.5466327, 5.2659818],
          [101.5465208, 5.2655669],
          [101.5465122, 5.2651298],
          [101.5465384, 5.2646815],
          [101.5464854, 5.2640485],
          [101.5465005, 5.263554],
          [101.5464001, 5.2631278],
          [101.5462072, 5.2628043],
          [101.5458056, 5.2627669],
          [101.5454717, 5.2629141],
          [101.5452633, 5.2631426],
          [101.5451357, 5.2632912],
          [101.5448463, 5.2636227],
          [101.5444295, 5.2641027],
          [101.5440839, 5.2642613],
          [101.5434961, 5.2645561],
          [101.5429927, 5.2642879],
          [101.5424666, 5.2639966],
          [101.542169, 5.2638679],
          [101.5417333, 5.2637269],
          [101.5413213, 5.2635398],
          [101.5410011, 5.263365],
          [101.5406823, 5.2629602],
          [101.5403384, 5.2628887],
          [101.5399156, 5.2626095],
          [101.5396184, 5.2624004],
          [101.5391493, 5.262144],
          [101.5383812, 5.2619775],
          [101.538047, 5.2621131],
          [101.5376092, 5.2623286],
          [101.5372527, 5.2623949],
          [101.5368045, 5.2624492],
          [101.536334, 5.2623883],
          [101.5356229, 5.2623026],
          [101.535094, 5.2623564],
          [101.5346794, 5.2625144],
          [101.5343455, 5.2626385],
          [101.5342182, 5.2627871],
          [101.5338933, 5.2632678],
          [101.5335458, 5.2637024],
          [101.5328648, 5.2641806],
          [101.5324835, 5.2644999],
          [101.532377, 5.2649247],
          [101.5322934, 5.2653726],
          [101.5321525, 5.2658201],
          [101.5316776, 5.2663687],
          [101.5317555, 5.2667143],
          [101.5319928, 5.2672682],
          [101.532162, 5.2676719],
          [101.5322864, 5.2679488],
          [101.5323176, 5.2684091],
          [101.5321189, 5.2688907],
          [101.5318972, 5.2694067],
          [101.531687, 5.2698882],
          [101.5313847, 5.2703806],
          [101.5311649, 5.2706321],
          [101.5308636, 5.2710095],
          [101.530401, 5.2714432],
          [101.5299624, 5.2717504],
          [101.5295005, 5.2721152],
          [101.5291992, 5.2724811],
          [101.5288639, 5.2728122],
          [101.5283335, 5.2731074],
          [101.5278975, 5.2730468],
          [101.5276461, 5.2728839],
          [101.5274065, 5.2726751],
          [101.5270412, 5.2723505],
          [101.5267228, 5.2719111],
          [101.5263584, 5.2715059],
          [101.5259738, 5.2706751],
          [101.5255861, 5.2702812],
          [101.5253362, 5.2698884],
          [101.5248769, 5.2698851],
          [101.524326, 5.2698582],
          [101.5237515, 5.2699],
          [101.5231964, 5.2704136],
          [101.522736, 5.2705827],
          [101.5223336, 5.2706143],
          [101.5218638, 5.2705074],
          [101.5215099, 5.2701828],
          [101.5209964, 5.2697536],
          [101.5207465, 5.2693492],
          [101.5207509, 5.2687512],
          [101.5208463, 5.2682688],
          [101.5210784, 5.2679254],
          [101.5212652, 5.2674897],
          [101.5214414, 5.2669275],
          [101.5216046, 5.2666067],
          [101.5219084, 5.2658497],
          [101.5219472, 5.2652634],
          [101.5220078, 5.2647923],
          [101.5219429, 5.2642628],
          [101.521773, 5.263905],
          [101.5216623, 5.2633406],
          [101.5215267, 5.2630292],
          [101.5215311, 5.2624311],
          [101.5215236, 5.2618676],
          [101.5214921, 5.2614647],
          [101.5214065, 5.2606016],
          [101.5214334, 5.2600611],
          [101.5212534, 5.2595192],
          [101.521176, 5.2591162],
          [101.5211789, 5.2586907],
          [101.5210438, 5.2583101],
          [101.5210585, 5.2578848],
          [101.5209686, 5.2575851],
          [101.5209478, 5.2572743],
          [101.5206656, 5.2565823],
          [101.520362, 5.2556944],
          [101.5200321, 5.2552435],
          [101.5197826, 5.2548277],
          [101.5196958, 5.2541254],
          [101.5194564, 5.2538822],
          [101.5192746, 5.2535933],
          [101.5194959, 5.2531809],
          [101.519659, 5.2528601],
          [101.5200962, 5.2527482],
          [101.5204067, 5.2526814],
          [101.5208658, 5.2526847],
          [101.5212562, 5.252722],
          [101.5216801, 5.2528286],
          [101.52235, 5.252316],
          [101.5227563, 5.2516748],
          [101.5230612, 5.2508374],
          [101.5233535, 5.2500804],
          [101.5233231, 5.2495281],
          [101.5232346, 5.2490445],
          [101.5227917, 5.2483512],
          [101.5223945, 5.2477042],
          [101.5221812, 5.2470356],
          [101.5221511, 5.2463913],
          [101.522134, 5.2455746],
          [101.5221032, 5.2450683],
          [101.5220176, 5.2442051],
          [101.5217125, 5.2435129],
          [101.5210757, 5.2426457],
          [101.5206673, 5.2419181],
          [101.5202131, 5.2412247],
          [101.5195501, 5.2407944],
          [101.5189785, 5.2404337],
          [101.518236, 5.2398878],
          [101.517758, 5.2392748],
          [101.5168412, 5.2390036],
          [101.5163248, 5.238977],
          [101.5158443, 5.2387089],
          [101.5152727, 5.2383828],
          [101.5147938, 5.2378848],
          [101.5143384, 5.2373524],
          [101.5140451, 5.2366142],
          [101.5138422, 5.2360952],
          [101.5133525, 5.235482],
          [101.5130549, 5.2353534],
          [101.5124945, 5.2350388],
          [101.5115908, 5.2345722],
          [101.5107225, 5.2339449],
          [101.5103245, 5.2333899],
          [101.5098924, 5.2328117],
          [101.509449, 5.232176],
          [101.5092113, 5.2317027],
          [101.5094326, 5.2312557],
          [101.5099637, 5.23088],
          [101.5103456, 5.2304688],
          [101.5104869, 5.2299522],
          [101.5106545, 5.2290103],
          [101.5107291, 5.2282173],
          [101.5107927, 5.2273667],
          [101.5108437, 5.2266655],
          [101.5109298, 5.225838],
          [101.5107614, 5.2253077],
          [101.5108127, 5.224595],
          [101.5108189, 5.2237324],
          [101.5109387, 5.2229972],
          [101.5113899, 5.2225289],
          [101.5119679, 5.2220155],
          [101.512167, 5.2214765],
          [101.5127454, 5.220871],
          [101.5130492, 5.2201257],
          [101.5136628, 5.21944],
          [101.5141039, 5.2187876],
          [101.5144676, 5.2176977],
          [101.5147018, 5.2170552],
          [101.5149493, 5.2161944],
          [101.5151147, 5.2155515],
          [101.5149928, 5.2149412],
          [101.5144223, 5.2144194],
          [101.5138279, 5.2140356],
          [101.5132471, 5.2133413],
          [101.512724, 5.2126475],
          [101.512269, 5.2120576],
          [101.5119055, 5.2114915],
          [101.5116122, 5.2107762],
          [101.5114567, 5.2100391],
          [101.5112538, 5.2094739],
          [101.5110065, 5.2087476],
          [101.5107355, 5.2081016],
          [101.5097725, 5.2063004],
          [101.5094438, 5.2056885],
          [101.5090244, 5.2049378],
          [101.5086852, 5.2041763],
          [101.5089905, 5.203247],
          [101.5093968, 5.2026288],
          [101.5097217, 5.2021596],
          [101.5099885, 5.2018166],
          [101.5102553, 5.2014274],
          [101.5101783, 5.2009438],
          [101.5100475, 5.1999767],
          [101.5096625, 5.1992149],
          [101.5090802, 5.1987506],
          [101.5084283, 5.1983894],
          [101.5080164, 5.1981563],
          [101.5076274, 5.1979696],
          [101.5069422, 5.19747],
          [101.5065203, 5.1970414],
          [101.5062951, 5.1964187],
          [101.5061511, 5.1956701],
          [101.5060755, 5.1950139],
          [101.5058285, 5.1942531],
          [101.5055002, 5.1935721],
          [101.5047713, 5.1927387],
          [101.5038596, 5.1917661],
          [101.5034713, 5.1914872],
          [101.5030371, 5.1911736],
          [101.5022376, 5.1906272],
          [101.5014237, 5.1904029],
          [101.5005087, 5.1899131],
          [101.5002933, 5.1895205],
          [101.5000545, 5.1891852],
          [101.4994378, 5.1887438],
          [101.4991984, 5.1884775],
          [101.4989878, 5.1884779],
          [101.4965636, 5.1883553],
          [101.4959897, 5.1882588],
          [101.4947593, 5.1868463],
          [101.4944076, 5.1862571],
          [101.4940248, 5.1852307],
          [101.4929974, 5.1843372],
          [101.4924966, 5.183678],
          [101.491956, 5.1822709],
          [101.491561, 5.1813249],
          [101.491266, 5.1808282],
          [101.4913517, 5.1801619],
          [101.492557, 5.1788606],
          [101.4928843, 5.1781503],
          [101.4929349, 5.1775757],
          [101.4928625, 5.1765173],
          [101.4923345, 5.1749379],
          [101.4914545, 5.174287],
          [101.4901234, 5.1725173],
          [101.4893311, 5.1724421],
          [101.486736, 5.173606],
          [101.4860448, 5.1738419],
          [101.4854357, 5.1738372],
          [101.4852737, 5.1724791],
          [101.4858592, 5.171104],
          [101.4861618, 5.1705888],
          [101.4863275, 5.1699693],
          [101.4856622, 5.1683312],
          [101.4856827, 5.1671471],
          [101.4865385, 5.1649921],
          [101.4867282, 5.1642232],
          [101.4873749, 5.1638604],
          [101.488749, 5.1630318],
          [101.4895832, 5.1621185],
          [101.4896811, 5.1613719],
          [101.4888778, 5.1597443],
          [101.4881494, 5.1588187],
          [101.4875214, 5.1582733],
          [101.4865879, 5.1571391],
          [101.4855828, 5.1563263],
          [101.4848623, 5.1558493],
          [101.4837069, 5.1551847],
          [101.4831061, 5.1540761],
          [101.4829158, 5.1534422],
          [101.4826011, 5.1524969],
          [101.4821459, 5.1519184],
          [101.4821217, 5.151569],
          [101.4820425, 5.1511661],
          [101.4819523, 5.150556],
          [101.4817588, 5.1499802],
          [101.4815658, 5.1492318],
          [101.4813265, 5.1485638],
          [101.4815697, 5.1479086],
          [101.4817671, 5.1471497],
          [101.4816315, 5.146367],
          [101.4814496, 5.1456876],
          [101.4809461, 5.1450419],
          [101.4807416, 5.1443049],
          [101.4809041, 5.1436841],
          [101.4811708, 5.1428679],
          [101.4816779, 5.1422134],
          [101.4821514, 5.1414093],
          [101.4826469, 5.1408354],
          [101.48312, 5.1401118],
          [101.4832586, 5.1398245],
          [101.4835817, 5.1393536],
          [101.4839511, 5.1386758],
          [101.4845046, 5.1379179],
          [101.4849542, 5.1373322],
          [101.4850246, 5.1367917],
          [101.4852099, 5.1362974],
          [101.4856133, 5.1358037],
          [101.4862804, 5.1355064],
          [101.4865685, 5.1351505],
          [101.4868233, 5.1344378],
          [101.4868935, 5.1340008],
          [101.4869404, 5.1336442],
          [101.4867458, 5.1334941],
          [101.4864014, 5.1333781],
          [101.4861492, 5.1332163],
          [101.4859432, 5.1329396],
          [101.4857611, 5.1324675],
          [101.485648, 5.1317767],
          [101.4853059, 5.1310049],
          [101.4848711, 5.1304745],
          [101.4846887, 5.1299562],
          [101.4848626, 5.1294044],
          [101.4850362, 5.1288756],
          [101.4850955, 5.1282659],
          [101.4850511, 5.127725],
          [101.485042, 5.1269426],
          [101.4848376, 5.1261596],
          [101.4848166, 5.1254692],
          [101.485002, 5.1249175],
          [101.4849467, 5.1241464],
          [101.4848115, 5.1233061],
          [101.4846534, 5.1223967],
          [101.4845517, 5.1217981],
          [101.4842674, 5.1208424],
          [101.4837526, 5.1200701],
          [101.4832953, 5.1193784],
          [101.4826551, 5.1183873],
          [101.4825075, 5.1178231],
          [101.4819361, 5.116809],
          [101.481262, 5.1156566],
          [101.4806902, 5.1147345],
          [101.4799119, 5.1138234],
          [101.4794551, 5.1129939],
          [101.4788374, 5.1121062],
          [101.4783678, 5.1116332],
          [101.4777849, 5.1107226],
          [101.4776029, 5.1101238],
          [101.4776174, 5.1090883],
          [101.4778487, 5.1085711],
          [101.4784135, 5.1078823],
          [101.4791159, 5.1072399],
          [101.4795766, 5.1068039],
          [101.4796712, 5.1058722],
          [101.4796846, 5.1052164],
          [101.4797898, 5.1046068],
          [101.4798033, 5.1038935],
          [101.4794499, 5.1029261],
          [101.4790165, 5.1019354],
          [101.4787773, 5.1011983],
          [101.478871, 5.1006003],
          [101.4793325, 5.0999112],
          [101.4799198, 5.0993144],
          [101.4808306, 5.0981547],
          [101.4815565, 5.0973053],
          [101.4820754, 5.0965819],
          [101.4827095, 5.0957781],
          [101.4832743, 5.0950663],
          [101.4837364, 5.094193],
          [101.4840369, 5.093515],
          [101.4844995, 5.0924117],
          [101.4849841, 5.0916076],
          [101.4853888, 5.0906882],
          [101.4855628, 5.0901019],
          [101.4852657, 5.0895833],
          [101.4846359, 5.0889717],
          [101.4841778, 5.0885218],
          [101.4836165, 5.0880026],
          [101.4832156, 5.0876218],
          [101.482769, 5.0871718],
          [101.4821622, 5.0865373],
          [101.4815439, 5.0859489],
          [101.4810857, 5.085522],
          [101.4804327, 5.0849218],
          [101.4798144, 5.0843564],
          [101.4794027, 5.0836994],
          [101.4790831, 5.0830773],
          [101.478408, 5.082247],
          [101.47771, 5.0813592],
          [101.4771607, 5.0806098],
          [101.4767255, 5.0802059],
          [101.4759917, 5.0798242],
          [101.4752225, 5.0797186],
          [101.4744416, 5.0797855],
          [101.4736371, 5.0798984],
          [101.4729943, 5.0798852],
          [101.4721569, 5.0795262],
          [101.4713657, 5.0790869],
          [101.4707242, 5.0785213],
          [101.4701169, 5.0781055],
          [101.4691877, 5.0776541],
          [101.4683737, 5.0772033],
          [101.4673759, 5.0768439],
          [101.4670894, 5.07659],
          [101.4666088, 5.0759904],
          [101.4662657, 5.0755752],
          [101.4660728, 5.0747463],
          [101.466178, 5.0740447],
          [101.4662259, 5.0733774],
          [101.466136, 5.0727444],
          [101.4655638, 5.0720524],
          [101.4651401, 5.0716141],
          [101.4645677, 5.0710603],
          [101.4640976, 5.0707253],
          [101.4638349, 5.0702529],
          [101.4634125, 5.0694693],
          [101.4633239, 5.0683185],
          [101.4631877, 5.0677888],
          [101.4628571, 5.066971],
          [101.4626529, 5.0660384],
          [101.4625859, 5.0654284],
          [101.4628288, 5.0648423],
          [101.4628308, 5.0641174],
          [101.4628208, 5.0635997],
          [101.4631213, 5.0630022],
          [101.4635705, 5.0625662],
          [101.4638357, 5.0621412],
          [101.4641009, 5.0617852],
          [101.4644588, 5.0611879],
          [101.4646671, 5.0606706],
          [101.4646806, 5.0599804],
          [101.4645105, 5.059232],
          [101.464374, 5.0587253],
          [101.4641118, 5.0580688],
          [101.4634143, 5.0570659],
          [101.4631286, 5.0566049],
          [101.4630847, 5.0559259],
          [101.463155, 5.0554314],
          [101.4629506, 5.0546714],
          [101.4625049, 5.0539799],
          [101.4622078, 5.0534382],
          [101.4617617, 5.0527468],
          [101.4613962, 5.0521129],
          [101.4609625, 5.0512718],
          [101.4605164, 5.0505457],
          [101.459979, 5.0498194],
          [101.4597511, 5.0492204],
          [101.4597646, 5.0485071],
          [101.4599729, 5.0480359],
          [101.4601124, 5.0474495],
          [101.4603444, 5.0465986],
          [101.4606448, 5.0460242],
          [101.460773, 5.0453342],
          [101.4608781, 5.0447821],
          [101.4606618, 5.0441488],
          [101.4601931, 5.0433996],
          [101.4598967, 5.0426163],
          [101.4594973, 5.0417753],
          [101.4593272, 5.0410616],
          [101.4592713, 5.0405666],
          [101.4591013, 5.0397953],
          [101.4591034, 5.0390474],
          [101.4589792, 5.0383567],
          [101.4589582, 5.0376778],
          [101.4591441, 5.036965],
          [101.4595944, 5.0360802],
          [101.4599298, 5.0352757],
          [101.4602308, 5.0345171],
          [101.4604159, 5.0339768],
          [101.4605902, 5.0333099],
          [101.4608904, 5.032747],
          [101.4607886, 5.0322059],
          [101.4604561, 5.0320325],
          [101.4599517, 5.0317089],
          [101.4594013, 5.0314428],
          [101.4591959, 5.0310279],
          [101.4590025, 5.0304406],
          [101.4587628, 5.0298647],
          [101.4582264, 5.0287702],
          [101.4576664, 5.0278942],
          [101.457175, 5.027007],
          [101.4569821, 5.0262585],
          [101.4569272, 5.0253954],
          [101.4568494, 5.0245552],
          [101.4563813, 5.023622],
          [101.456153, 5.0231726],
          [101.4549393, 5.0219152],
          [101.454195, 5.0211768],
          [101.4530729, 5.0200693],
          [101.4522723, 5.0189855],
          [101.4520679, 5.0182485],
          [101.451657, 5.017419],
          [101.4513147, 5.0165781],
          [101.4506622, 5.0158744],
          [101.4499414, 5.015021],
          [101.4490935, 5.0143974],
          [101.4483362, 5.0141998],
          [101.4478203, 5.0139568],
          [101.4479488, 5.0132553],
          [101.4481343, 5.0125769],
          [101.4485152, 5.0119451],
          [101.4490221, 5.0113827],
          [101.4496442, 5.0106709],
          [101.4503804, 5.0102358],
          [101.4509213, 5.0098576],
          [101.4513822, 5.0093641],
          [101.4518658, 5.0088822],
          [101.4523384, 5.0082621],
          [101.4526629, 5.0072275],
          [101.4528946, 5.0066067],
          [101.4529879, 5.0060662],
          [101.4527147, 5.0052601],
          [101.4525208, 5.0047878],
          [101.4520168, 5.0043722],
          [101.4511226, 5.0038175],
          [101.4502287, 5.0033548],
          [101.4494836, 5.0028581],
          [101.4491984, 5.0022705],
          [101.4489821, 5.0015565],
          [101.4486851, 5.000992],
          [101.4482972, 5.0002199],
          [101.4480933, 4.9992759],
          [101.4480838, 4.9985855],
          [101.4482813, 4.9977462],
          [101.4482034, 4.9969751],
          [101.4476316, 4.9961681],
          [101.4467724, 4.9954523],
          [101.4458551, 4.9950241],
          [101.4451788, 4.9945851],
          [101.4445567, 4.9952507],
          [101.4434765, 4.9955354],
          [101.4427867, 4.9958211],
          [101.4422344, 4.9961879],
          [101.4415215, 4.9965311],
          [101.4409827, 4.996265],
          [101.4406852, 4.9958499],
          [101.4403537, 4.9953887],
          [101.4399759, 4.994962],
          [101.4395526, 4.9944777],
          [101.4386354, 4.9940149],
          [101.4380617, 4.9938407],
          [101.4372247, 4.9934703],
          [101.4363759, 4.9931572],
          [101.43556, 4.9932701],
          [101.4349738, 4.993464],
          [101.4343078, 4.9935428],
          [101.4336767, 4.9933915],
          [101.4329541, 4.9930213],
          [101.432646, 4.9924337],
          [101.432326, 4.991892],
          [101.4318794, 4.9914995],
          [101.4312499, 4.9907615],
          [101.4305749, 4.9899542],
          [101.4299917, 4.9891356],
          [101.4293282, 4.9883284],
          [101.4286409, 4.9877513],
          [101.4279655, 4.987105],
          [101.427175, 4.98647],
          [101.4262474, 4.9855816],
          [101.4255944, 4.9850735],
          [101.424896, 4.9844618],
          [101.4241733, 4.9841606],
          [101.4235996, 4.9840094],
          [101.4236714, 4.9830546],
          [101.4239142, 4.982457],
          [101.4242836, 4.9818482],
          [101.4244799, 4.981446],
          [101.4243553, 4.9808934],
          [101.4242421, 4.9803638],
          [101.4246116, 4.979686],
          [101.4249929, 4.9788587],
          [101.425465, 4.9783997],
          [101.426098, 4.9779527],
          [101.4265929, 4.9775859],
          [101.4264568, 4.9770217],
          [101.4262869, 4.9761468],
          [101.4260363, 4.9754903],
          [101.4259236, 4.9747536],
          [101.425777, 4.9739593],
          [101.4255951, 4.9732914],
          [101.4257123, 4.9725094],
          [101.4263563, 4.9721429],
          [101.4271268, 4.9717539],
          [101.4278855, 4.9714338],
          [101.4285983, 4.9711711],
          [101.4294726, 4.9706328],
          [101.4303582, 4.9701289],
          [101.4310945, 4.9696822],
          [101.432027, 4.9688793],
          [101.4329939, 4.9680881],
          [101.4335702, 4.9673303],
          [101.4340307, 4.9669289],
          [101.4346404, 4.9665048],
          [101.4352964, 4.9659659],
          [101.4358487, 4.9655992],
          [101.4362528, 4.9649214],
          [101.436336, 4.9639437],
          [101.4366371, 4.96307],
          [101.4371674, 4.9623697],
          [101.4377083, 4.9618878],
          [101.438283, 4.9616477],
          [101.4390076, 4.9613046],
          [101.4399272, 4.9609734],
          [101.4404676, 4.9607448],
          [101.4414665, 4.9607475],
          [101.4424428, 4.9606812],
          [101.4430175, 4.9604411],
          [101.4440064, 4.959972],
          [101.4447651, 4.9596059],
          [101.4453632, 4.9592968],
          [101.445732, 4.9588261],
          [101.445906, 4.9582513],
          [101.4459653, 4.9575956],
          [101.4456338, 4.9571229],
          [101.445567, 4.9563979],
          [101.4459248, 4.9557546],
          [101.4465463, 4.955319],
          [101.4472944, 4.9546997],
          [101.448077, 4.9540345],
          [101.4486182, 4.9535528],
          [101.4485629, 4.9528277],
          [101.4484972, 4.951677],
          [101.4486029, 4.9508718],
          [101.4488474, 4.949791],
          [101.449011, 4.9487443],
          [101.449013, 4.9480884],
          [101.4489118, 4.9473519],
          [101.4485464, 4.946672],
          [101.4479966, 4.9462217],
          [101.4473436, 4.9457827],
          [101.44692, 4.9453213],
          [101.4464399, 4.9446412],
          [101.4460392, 4.9441684],
          [101.4453275, 4.9440859],
          [101.4445819, 4.9438077],
          [101.4441926, 4.943473],
          [101.4441711, 4.9430242],
          [101.4442648, 4.9423687],
          [101.4445549, 4.9413108],
          [101.4447069, 4.9403908],
          [101.4449849, 4.9396092],
          [101.4453203, 4.9387702],
          [101.4458503, 4.9380927],
          [101.4467718, 4.9370942],
          [101.4473246, 4.9364974],
          [101.448141, 4.9360854],
          [101.4485804, 4.9351316],
          [101.4485276, 4.9334861],
          [101.4486009, 4.9320021],
          [101.4491073, 4.9315777],
          [101.4499694, 4.9312924],
          [101.4502466, 4.9306833],
          [101.45056, 4.9295565],
          [101.4508501, 4.9284758],
          [101.4514625, 4.9272348],
          [101.4519016, 4.9262465],
          [101.4520782, 4.9252265],
          [101.4524558, 4.9251434],
          [101.4529154, 4.9250526],
          [101.4534442, 4.9248009],
          [101.4538248, 4.9242957],
          [101.4541029, 4.9233989],
          [101.4542545, 4.9225249],
          [101.4539239, 4.9218106],
          [101.4537306, 4.9211313],
          [101.4539169, 4.9202573],
          [101.4542184, 4.9192341],
          [101.4543245, 4.9183369],
          [101.454327, 4.9174395],
          [101.4545487, 4.9162089],
          [101.4545862, 4.9151389],
          [101.4547624, 4.9138162],
          [101.4548461, 4.9126658],
          [101.4551707, 4.9115392],
          [101.4551392, 4.910561],
          [101.4548092, 4.9095592],
          [101.4543646, 4.9084764],
          [101.4539668, 4.9070601],
          [101.4541083, 4.9058178],
          [101.4541227, 4.9048054],
          [101.4534579, 4.9044008],
          [101.452644, 4.9039729],
          [101.4515325, 4.9032565],
          [101.4511556, 4.9026227],
          [101.4510888, 4.9018401],
          [101.4511949, 4.9008969],
          [101.4514043, 4.8999425],
          [101.4516485, 4.8988731],
          [101.4519958, 4.897873],
          [101.4524811, 4.8968848],
          [101.4529544, 4.8960232],
          [101.453289, 4.8954258],
          [101.453728, 4.8944719],
          [101.4539936, 4.8939088],
          [101.4540084, 4.8928388],
          [101.4537124, 4.8920096],
          [101.4530147, 4.8910297],
          [101.4525227, 4.8904876],
          [101.4518011, 4.8898413],
          [101.450758, 4.8892517],
          [101.4498872, 4.888697],
          [101.4492578, 4.8879475],
          [101.448699, 4.8867033],
          [101.4482997, 4.8858968],
          [101.4477958, 4.8854237],
          [101.4468329, 4.8849149],
          [101.4462838, 4.8842345],
          [101.4460334, 4.883509],
          [101.4460145, 4.8821167],
          [101.4458524, 4.8803934],
          [101.4459351, 4.8798989],
          [101.4458112, 4.8794493],
          [101.4458248, 4.8789889],
          [101.4456203, 4.8785619],
          [101.4452194, 4.8783641],
          [101.4442995, 4.8785547],
          [101.4435285, 4.8788613],
          [101.4429177, 4.8792494],
          [101.4424579, 4.8793505],
          [101.4417234, 4.8792084],
          [101.4411387, 4.878975],
          [101.4406927, 4.8785926],
          [101.4400392, 4.8783705],
          [101.4394186, 4.8784362],
          [101.4387974, 4.8786285],
          [101.4381644, 4.8789127],
          [101.4373347, 4.8794262],
          [101.4368397, 4.8796538],
          [101.4365535, 4.879445],
          [101.4365894, 4.879169],
          [101.4367646, 4.8786174],
          [101.4369393, 4.8781579],
          [101.437046, 4.8774678],
          [101.436901, 4.8766383],
          [101.43664, 4.8760038],
          [101.4364028, 4.8752313],
          [101.4362794, 4.8746435],
          [101.4366724, 4.8741507],
          [101.4370314, 4.8735425],
          [101.4369322, 4.8727132],
          [101.4370168, 4.8719309],
          [101.4373869, 4.8713805],
          [101.4381467, 4.8710162],
          [101.4386539, 4.8706621],
          [101.4387495, 4.869972],
          [101.4385574, 4.8693378],
          [101.4382151, 4.868841],
          [101.4373678, 4.868376],
          [101.4369338, 4.8678787],
          [101.4369705, 4.867453],
          [101.4372492, 4.8668328],
          [101.4376873, 4.86649],
          [101.4382743, 4.866286],
          [101.4390912, 4.8659796],
          [101.4398277, 4.8657189],
          [101.440646, 4.8651708],
          [101.4412106, 4.8647595],
          [101.4418451, 4.8642103],
          [101.44248, 4.8636152],
          [101.4432869, 4.863021],
          [101.4438285, 4.8626326],
          [101.4443495, 4.8618642],
          [101.4445701, 4.8613359],
          [101.4449753, 4.860728],
          [101.4454378, 4.8601435],
          [101.4458541, 4.8595357],
          [101.4460411, 4.8589381],
          [101.4456307, 4.8583373],
          [101.4450342, 4.8581845],
          [101.4444369, 4.8581466],
          [101.4435307, 4.8579461],
          [101.442544, 4.8577566],
          [101.4420743, 4.8575238],
          [101.441409, 4.857313],
          [101.4406736, 4.8573895],
          [101.4399264, 4.8575351],
          [101.4394315, 4.8577626],
          [101.439016, 4.8581403],
          [101.4386345, 4.8586332],
          [101.4379537, 4.8592625],
          [101.4374117, 4.8596855],
          [101.4366745, 4.8600959],
          [101.4355485, 4.8602164],
          [101.4346642, 4.8601655],
          [101.4340221, 4.8599548],
          [101.4336913, 4.8595157],
          [101.4333718, 4.859111],
          [101.4330855, 4.8589598],
          [101.4325337, 4.8590374],
          [101.4319004, 4.8593677],
          [101.4314521, 4.8593999],
          [101.4310413, 4.8589142],
          [101.4305736, 4.858267],
          [101.4303471, 4.8576326],
          [101.4299241, 4.8572274],
          [101.4293505, 4.8571092],
          [101.4287994, 4.8571063],
          [101.4282622, 4.8565508],
          [101.4273833, 4.855487],
          [101.4267441, 4.8547008],
          [101.4260941, 4.8537878],
          [101.4252371, 4.8528968],
          [101.4244595, 4.8522595],
          [101.4237479, 4.8521866],
          [101.4232173, 4.852621],
          [101.4227651, 4.8534359],
          [101.42221, 4.8541811],
          [101.4217368, 4.8546619],
          [101.4211632, 4.8545092],
          [101.4205576, 4.8538958],
          [101.419802, 4.8534427],
          [101.4190569, 4.8531393],
          [101.4182417, 4.8531119],
          [101.4171838, 4.8533133],
          [101.4163303, 4.8540454],
          [101.4157186, 4.854652],
          [101.4150837, 4.8552472],
          [101.4140689, 4.8560589],
          [101.4134814, 4.8564355],
          [101.4128955, 4.8564668],
          [101.4122417, 4.8562561],
          [101.4115194, 4.8560104],
          [101.4106833, 4.8555339],
          [101.4103536, 4.8549104],
          [101.4100595, 4.854011],
          [101.4102244, 4.8532292],
          [101.4104003, 4.852528],
          [101.4104608, 4.8519067],
          [101.4102232, 4.8512148],
          [101.409928, 4.8505225],
          [101.4100102, 4.8501546],
          [101.4103463, 4.8495695],
          [101.410682, 4.8490303],
          [101.4110994, 4.8482843],
          [101.411505, 4.8475729],
          [101.4117948, 4.847045],
          [101.412074, 4.8463673],
          [101.4121918, 4.8457924],
          [101.4121377, 4.8451245],
          [101.4118756, 4.8446856],
          [101.4113379, 4.8443374],
          [101.4108794, 4.8441047],
          [101.4102251, 4.8440781],
          [101.4095696, 4.8442011],
          [101.408811, 4.8443121],
          [101.408099, 4.8443427],
          [101.4073862, 4.8444424],
          [101.4064565, 4.8443337],
          [101.4057215, 4.8443413],
          [101.4047221, 4.8443702],
          [101.404045, 4.8442514],
          [101.4032657, 4.8439594],
          [101.402957, 4.8436469],
          [101.4025108, 4.8433222],
          [101.4022048, 4.8425031],
          [101.4024369, 4.8420556],
          [101.4027729, 4.8415049],
          [101.402971, 4.8409305],
          [101.4031343, 4.8404593],
          [101.4032525, 4.8397463],
          [101.4030374, 4.8391811],
          [101.4026952, 4.8386958],
          [101.402216, 4.8380946],
          [101.4016215, 4.8375158],
          [101.4011661, 4.8367882],
          [101.4008258, 4.835946],
          [101.4003479, 4.8350801],
          [101.3998344, 4.8343866],
          [101.3994122, 4.8339123],
          [101.3995166, 4.8336597],
          [101.4001041, 4.8333291],
          [101.4009564, 4.8328733],
          [101.4014636, 4.8324617],
          [101.4019253, 4.8320383],
          [101.4026062, 4.8313744],
          [101.4029303, 4.8308698],
          [101.4033008, 4.8302962],
          [101.4037281, 4.8298612],
          [101.4043401, 4.8292084],
          [101.4051252, 4.8284414],
          [101.4057028, 4.8277309],
          [101.4062562, 4.8273426],
          [101.4066622, 4.8265507],
          [101.4069647, 4.8257926],
          [101.4072435, 4.8251265],
          [101.4074981, 4.824748],
          [101.4081705, 4.8235661],
          [101.4086331, 4.822878],
          [101.4092348, 4.822018],
          [101.4094213, 4.821478],
          [101.4095965, 4.8209264],
          [101.4095807, 4.819522],
          [101.4098136, 4.8188901],
          [101.4101603, 4.8184661],
          [101.4102437, 4.8178796],
          [101.4102362, 4.8170392],
          [101.410422, 4.8166604],
          [101.4108035, 4.816179],
          [101.4112075, 4.8157668],
          [101.4115657, 4.8153544],
          [101.4122567, 4.8149438],
          [101.4129473, 4.8146828],
          [101.4136391, 4.8141111],
          [101.4139506, 4.8138711],
          [101.4144586, 4.8133558],
          [101.4148638, 4.8126904],
          [101.4150168, 4.811989],
          [101.4149414, 4.8109642],
          [101.4146132, 4.810007],
          [101.4145931, 4.8094774],
          [101.4145172, 4.8085676],
          [101.414246, 4.8077259],
          [101.4139409, 4.8067572],
          [101.4136582, 4.8058809],
          [101.4135357, 4.805132],
          [101.4130226, 4.8043695],
          [101.4126811, 4.8037691],
          [101.4121349, 4.8027647],
          [101.4115889, 4.8017833],
          [101.4114996, 4.8012418],
          [101.411353, 4.8007115],
          [101.4110814, 4.7999617],
          [101.4106248, 4.7993722],
          [101.4102375, 4.7987255],
          [101.4100003, 4.7980105],
          [101.4099462, 4.797308],
          [101.4099727, 4.796629],
          [101.409928, 4.7963756],
          [101.4097568, 4.796179],
          [101.4090473, 4.7956686],
          [101.4082464, 4.7950772],
          [101.4075709, 4.7946821],
          [101.406804, 4.7941599],
          [101.4064032, 4.7939506],
          [101.4060625, 4.7932235],
          [101.4057455, 4.7923239],
          [101.4057716, 4.7916565],
          [101.4057866, 4.7909773],
          [101.4058357, 4.7903561],
          [101.4058376, 4.7899992],
          [101.405899, 4.7892169],
          [101.4056606, 4.788686],
          [101.4053301, 4.7881778],
          [101.404738, 4.7872192],
          [101.4040075, 4.7863173],
          [101.4034936, 4.7857505],
          [101.4030936, 4.7853569],
          [101.4025901, 4.7849972],
          [101.4021789, 4.7845922],
          [101.4018256, 4.7840608],
          [101.401645, 4.7834152],
          [101.4012943, 4.7823888],
          [101.4007259, 4.7812461],
          [101.3999505, 4.7801483],
          [101.399369, 4.7793163],
          [101.398686, 4.7781615],
          [101.3979334, 4.7771214],
          [101.3972389, 4.775932],
          [101.3966112, 4.7751343],
          [101.3961559, 4.7743721],
          [101.3959761, 4.7736114],
          [101.3957504, 4.772793],
          [101.3956516, 4.7719176],
          [101.3958039, 4.7712853],
          [101.3960706, 4.7708263],
          [101.3963023, 4.7704247],
          [101.3966264, 4.76992],
          [101.396951, 4.7693463],
          [101.3970336, 4.7688747],
          [101.3969685, 4.7681147],
          [101.3969602, 4.7675275],
          [101.3972404, 4.7666198],
          [101.3976915, 4.7660122],
          [101.3979931, 4.7653577],
          [101.3979501, 4.7647934],
          [101.39792, 4.7639415],
          [101.3974181, 4.7632596],
          [101.3969149, 4.7628885],
          [101.3963188, 4.762701],
          [101.3955381, 4.7626162],
          [101.3949408, 4.7626705],
          [101.3943321, 4.7626902],
          [101.3936083, 4.7627553],
          [101.3929415, 4.7628667],
          [101.3920573, 4.7628618],
          [101.3911142, 4.763133],
          [101.390367, 4.7633245],
          [101.3892291, 4.7634909],
          [101.388493, 4.7637632],
          [101.387791, 4.7639896],
          [101.3875032, 4.7641952],
          [101.387136, 4.7641125],
          [101.386587, 4.7636836],
          [101.3861985, 4.7632671],
          [101.3858787, 4.7629315],
          [101.3853063, 4.7625831],
          [101.3846403, 4.7625565],
          [101.3841233, 4.7626227],
          [101.3834802, 4.7626191],
          [101.3829635, 4.7625933],
          [101.3824832, 4.7622223],
          [101.3821983, 4.7618063],
          [101.3814433, 4.7612382],
          [101.3809164, 4.760959],
          [101.3803318, 4.7607717],
          [101.3794251, 4.760663],
          [101.3787591, 4.7606133],
          [101.3778744, 4.7607005],
          [101.3770249, 4.7606729],
          [101.3762194, 4.7609792],
          [101.3755177, 4.7611941],
          [101.3748173, 4.7612017],
          [101.3741176, 4.7610366],
          [101.3736702, 4.7609536],
          [101.3739379, 4.7602529],
          [101.3743078, 4.7597945],
          [101.3747573, 4.7594977],
          [101.3753459, 4.7588794],
          [101.3756918, 4.7586281],
          [101.3761314, 4.758009],
          [101.3763634, 4.7575267],
          [101.3765845, 4.7569639],
          [101.376737, 4.7563317],
          [101.3767742, 4.7557909],
          [101.3769166, 4.7548939],
          [101.377138, 4.754285],
          [101.3772444, 4.7536755],
          [101.3776504, 4.7528605],
          [101.3779515, 4.7524247],
          [101.3783556, 4.7519435],
          [101.378737, 4.7514852],
          [101.3791647, 4.7509465],
          [101.3793972, 4.7504183],
          [101.3794221, 4.7500271],
          [101.3794941, 4.7493943],
          [101.3795305, 4.7490147],
          [101.3796823, 4.7485436],
          [101.3799384, 4.7478773],
          [101.3801361, 4.7474065],
          [101.3802531, 4.7469582],
          [101.3803583, 4.7465905],
          [101.3803611, 4.746038],
          [101.3801232, 4.7453805],
          [101.3798865, 4.7445389],
          [101.3796502, 4.7435708],
          [101.3795866, 4.7425689],
          [101.3796822, 4.7418559],
          [101.3800519, 4.7414089],
          [101.3807776, 4.7409409],
          [101.3816179, 4.7405657],
          [101.3822512, 4.7402008],
          [101.3826453, 4.7395355],
          [101.3827742, 4.7389835],
          [101.3829489, 4.7385126],
          [101.3831932, 4.7379038],
          [101.3834265, 4.7371685],
          [101.3837748, 4.7364337],
          [101.3842265, 4.7357225],
          [101.3845396, 4.7351257],
          [101.3846677, 4.7347235],
          [101.3847421, 4.7336649],
          [101.384778, 4.7333889],
          [101.3848251, 4.7331934],
          [101.3851042, 4.7325043],
          [101.3854753, 4.7317697],
          [101.3857762, 4.7313224],
          [101.3873073, 4.7260358],
          [101.3874239, 4.7257142],
          [101.3875868, 4.7252661],
          [101.3875322, 4.7247019],
          [101.3872935, 4.72424],
          [101.3868706, 4.7238809],
          [101.3865738, 4.7235224],
          [101.3864612, 4.7230614],
          [101.3865331, 4.7225093],
          [101.3867873, 4.7221769],
          [101.3871445, 4.7219716],
          [101.3875137, 4.7216167],
          [101.3876185, 4.7213411],
          [101.3877683, 4.7211923],
          [101.387862, 4.7208475],
          [101.3877497, 4.7203865],
          [101.3874644, 4.7199705],
          [101.3869031, 4.7197372],
          [101.3866284, 4.7195631],
          [101.3860221, 4.7191224],
          [101.3855874, 4.7187631],
          [101.385119, 4.7183462],
          [101.3849254, 4.7179767],
          [101.3847451, 4.7173542],
          [101.3847135, 4.7168014],
          [101.3844519, 4.7162821],
          [101.3841318, 4.7160271],
          [101.3839029, 4.7158761],
          [101.3833417, 4.7155393],
          [101.382918, 4.7153412],
          [101.3824832, 4.7150511],
          [101.3820951, 4.7146116],
          [101.3818679, 4.7140923],
          [101.381905, 4.7135745],
          [101.3819884, 4.7129879],
          [101.3819908, 4.712539],
          [101.3817628, 4.7121694],
          [101.3814435, 4.7117648],
          [101.3811356, 4.7113603],
          [101.3807822, 4.7108633],
          [101.380555, 4.7103786],
          [101.3802819, 4.7099052],
          [101.3800428, 4.7094895],
          [101.379655, 4.7089693],
          [101.3792784, 4.7085069],
          [101.3789464, 4.7083438],
          [101.3784654, 4.708065],
          [101.3777662, 4.7077965],
          [101.3771351, 4.7077009],
          [101.3766766, 4.7075717],
          [101.376275, 4.7074774],
          [101.3758174, 4.7071411],
          [101.3753257, 4.7067586],
          [101.3747288, 4.7067438],
          [101.3742917, 4.7068564],
          [101.3735098, 4.7070824],
          [101.3728771, 4.7072746],
          [101.3719087, 4.708029],
          [101.3716311, 4.7084649],
          [101.371179, 4.7092566],
          [101.3708745, 4.7104291],
          [101.3706393, 4.7114868],
          [101.370544, 4.7121768],
          [101.3701847, 4.712808],
          [101.3694819, 4.7132646],
          [101.3686657, 4.7134442],
          [101.3677341, 4.7137614],
          [101.3669297, 4.7138721],
          [101.3661827, 4.7139831],
          [101.3652866, 4.7141047],
          [101.3649305, 4.7141488],
          [101.3645752, 4.7139397],
          [101.3642215, 4.7135233],
          [101.3638223, 4.7130262],
          [101.3635267, 4.712426],
          [101.3631734, 4.7119292],
          [101.3626706, 4.7114429],
          [101.3622703, 4.7111414],
          [101.3616292, 4.710735],
          [101.361104, 4.710145],
          [101.3609677, 4.7098565],
          [101.3605697, 4.7091061],
          [101.3601938, 4.708517],
          [101.3598401, 4.7080892],
          [101.3593824, 4.7077873],
          [101.3587066, 4.7074153],
          [101.3582149, 4.7070442],
          [101.35793, 4.7066052],
          [101.3575194, 4.706085],
          [101.3570384, 4.7058061],
          [101.3562352, 4.7056981],
          [101.3555697, 4.7055677],
          [101.3548697, 4.7055063],
          [101.3541227, 4.7056403],
          [101.3534444, 4.7057862],
          [101.3529729, 4.7059333],
          [101.3522725, 4.7059409],
          [101.3519515, 4.705847],
          [101.3516425, 4.7056381],
          [101.3514251, 4.7054988],
          [101.3510232, 4.705462],
          [101.3505398, 4.7056896],
          [101.3501014, 4.70609],
          [101.3498345, 4.7066411],
          [101.3496254, 4.7071004],
          [101.3493697, 4.707686],
          [101.3489878, 4.7082364],
          [101.3486632, 4.7088562],
          [101.3483268, 4.7095334],
          [101.3480944, 4.7100616],
          [101.3478508, 4.7105667],
          [101.3476516, 4.7113023],
          [101.347431, 4.7117846],
          [101.3470029, 4.7124038],
          [101.346623, 4.7125628],
          [101.345968, 4.7126859],
          [101.3450839, 4.7126925],
          [101.3440736, 4.7126639],
          [101.3435112, 4.7125456],
          [101.3430069, 4.7123932],
          [101.3427097, 4.7121037],
          [101.342437, 4.7115727],
          [101.3422896, 4.7112497],
          [101.3420746, 4.7106153],
          [101.3418249, 4.7100614],
          [101.3415408, 4.7094383],
          [101.3412223, 4.7088495],
          [101.3409958, 4.7082497],
          [101.3406434, 4.7075685],
          [101.3402331, 4.7069678],
          [101.3398803, 4.7063327],
          [101.3396428, 4.7056293],
          [101.3396923, 4.7049274],
          [101.3397773, 4.704099],
          [101.3398967, 4.7032019],
          [101.3400395, 4.7022242],
          [101.3402388, 4.7014426],
          [101.3403448, 4.7009138],
          [101.3401761, 4.7002452],
          [101.33973, 4.6998745],
          [101.3392497, 4.6994919],
          [101.3387348, 4.6991783],
          [101.338059, 4.6987946],
          [101.3374064, 4.6984573],
          [101.3367646, 4.6981775],
          [101.3363868, 4.6980142],
          [101.33532, 4.697778],
          [101.334977, 4.6974884],
          [101.33483, 4.6970271],
          [101.3345458, 4.6965191],
          [101.3340309, 4.6961479],
          [101.3335848, 4.6958001],
          [101.3331623, 4.6953604],
          [101.3330141, 4.6951293],
          [101.3329138, 4.6945532],
          [101.3329624, 4.6940586],
          [101.3331261, 4.6934839],
          [101.3332562, 4.692771],
          [101.3335013, 4.6919896],
          [101.3336769, 4.6913115],
          [101.3338181, 4.6906447],
          [101.3340873, 4.6897022],
          [101.3343202, 4.6890473],
          [101.3343231, 4.6884833],
          [101.3342907, 4.6881149],
          [101.3344097, 4.6872752],
          [101.3343774, 4.6868492],
          [101.3339794, 4.6861102],
          [101.3338562, 4.685488],
          [101.3338705, 4.6849817],
          [101.3339998, 4.6843954],
          [101.3340955, 4.6836361],
          [101.3343632, 4.6829355],
          [101.3347213, 4.6825001],
          [101.3351837, 4.6819617],
          [101.3358873, 4.6813325],
          [101.3360739, 4.6807925],
          [101.3364206, 4.6803224],
          [101.3367775, 4.6801633],
          [101.337628, 4.6800068],
          [101.3383063, 4.6798494],
          [101.3390877, 4.6797041],
          [101.3396861, 4.6794428],
          [101.3404121, 4.6789748],
          [101.3410348, 4.6784373],
          [101.3414502, 4.678025],
          [101.3421308, 4.6774304],
          [101.342466, 4.6769603],
          [101.3430325, 4.6762497],
          [101.3432894, 4.6754455],
          [101.3434997, 4.6746984],
          [101.3436871, 4.6740203],
          [101.3441611, 4.6733668],
          [101.3444844, 4.6729887],
          [101.3449235, 4.6724963],
          [101.3455235, 4.6719586],
          [101.3462137, 4.6716746],
          [101.3466721, 4.6718383],
          [101.3471535, 4.6720251],
          [101.3476132, 4.671924],
          [101.34827, 4.6715248],
          [101.3489605, 4.6711833],
          [101.3494905, 4.6708409],
          [101.3499059, 4.6704402],
          [101.3501506, 4.669751],
          [101.3500733, 4.6691175],
          [101.3500765, 4.6685189],
          [101.3503662, 4.6680256],
          [101.3506432, 4.6677394],
          [101.3510006, 4.667465],
          [101.3513595, 4.6668915],
          [101.3514773, 4.6662476],
          [101.3512738, 4.6656825],
          [101.3511849, 4.6650949],
          [101.3513139, 4.6645431],
          [101.3516845, 4.6639696],
          [101.3519961, 4.6636259],
          [101.3524696, 4.6630877],
          [101.3530237, 4.6625151],
          [101.3534858, 4.6619881],
          [101.3538086, 4.6617481],
          [101.354258, 4.66144],
          [101.3548102, 4.6612357],
          [101.3557987, 4.6609994],
          [101.3565808, 4.6607506],
          [101.3569369, 4.6607064],
          [101.3575926, 4.660526],
          [101.3581325, 4.6604138],
          [101.3583076, 4.6598738],
          [101.3583096, 4.6594938],
          [101.3586009, 4.6586667],
          [101.3588456, 4.6579659],
          [101.3591239, 4.657438],
          [101.3594369, 4.6568526],
          [101.3597041, 4.6562556],
          [101.3599141, 4.6555775],
          [101.3600548, 4.6550144],
          [101.3603102, 4.6544517],
          [101.3608532, 4.6538102],
          [101.3611429, 4.6533167],
          [101.3616761, 4.6523528],
          [101.3619433, 4.6516981],
          [101.3620955, 4.6511464],
          [101.3618916, 4.6505697],
          [101.3618016, 4.6502124],
          [101.3617478, 4.649556],
          [101.3617502, 4.649061],
          [101.3618, 4.6483132],
          [101.361861, 4.6475997],
          [101.3620483, 4.6469103],
          [101.3622361, 4.646117],
          [101.3624349, 4.6453814],
          [101.3624844, 4.6447026],
          [101.3625674, 4.6441505],
          [101.3626513, 4.6434717],
          [101.3627125, 4.6427699],
          [101.3627975, 4.6418726],
          [101.3629157, 4.6411826],
          [101.3631604, 4.6405163],
          [101.3635655, 4.6398624],
          [101.363775, 4.6393225],
          [101.3639284, 4.6385177],
          [101.3638861, 4.6378037],
          [101.3637858, 4.6371587],
          [101.3639143, 4.636745],
          [101.3643408, 4.6364021],
          [101.3647905, 4.6360476],
          [101.3650913, 4.6356234],
          [101.3654043, 4.6350496],
          [101.3656126, 4.6347284],
          [101.3659576, 4.6345692],
          [101.3664524, 4.6344106],
          [101.3669689, 4.6344251],
          [101.3676685, 4.6345554],
          [101.3681273, 4.6346847],
          [101.3686304, 4.6350787],
          [101.3690881, 4.6353576],
          [101.3695702, 4.6354062],
          [101.3700416, 4.6353053],
          [101.3707882, 4.6352287],
          [101.3712716, 4.6349782],
          [101.3716865, 4.6346811],
          [101.3721702, 4.634419],
          [101.3726311, 4.6340993],
          [101.3729887, 4.6337673],
          [101.3732891, 4.6333776],
          [101.3736124, 4.6330341],
          [101.3741203, 4.6324613],
          [101.374593, 4.6321071],
          [101.3750534, 4.6319025],
          [101.3755245, 4.6318129],
          [101.3758462, 4.6317457],
          [101.3759964, 4.6315738],
          [101.3765497, 4.6311855],
          [101.3768717, 4.6310836],
          [101.3770682, 4.6307854],
          [101.3771516, 4.6301642],
          [101.3771662, 4.6296004],
          [101.3770189, 4.6291967],
          [101.3766655, 4.6287343],
          [101.3763004, 4.6282603],
          [101.3759352, 4.6278325],
          [101.3756957, 4.6275088],
          [101.3752611, 4.6271726],
          [101.3749405, 4.6269868],
          [101.3743793, 4.6267418],
          [101.3739336, 4.6263481],
          [101.3735221, 4.6259889],
          [101.3730073, 4.6256064],
          [101.372471, 4.6249588],
          [101.3720378, 4.6243464],
          [101.3717079, 4.623769],
          [101.3712515, 4.6231449],
          [101.3709104, 4.6224986],
          [101.370993, 4.6221075],
          [101.3712254, 4.6215679],
          [101.3711693, 4.6213143],
          [101.3709757, 4.6209793],
          [101.3707714, 4.6204834],
          [101.3706366, 4.6199185],
          [101.3705706, 4.6193657],
          [101.370677, 4.6187907],
          [101.3709663, 4.6183204],
          [101.37129, 4.6178387],
          [101.3715797, 4.6173683],
          [101.3718014, 4.6166329],
          [101.3718406, 4.6157122],
          [101.3718671, 4.6149987],
          [101.3721453, 4.6145052],
          [101.3726982, 4.6141169],
          [101.3730088, 4.6140379],
          [101.3734108, 4.6139942],
          [101.3738696, 4.6140658],
          [101.3739276, 4.614083],
          [101.368673, 4.6072283],
          [101.3711551, 4.6012199],
          [101.3598085, 4.5895564],
          [101.3509441, 4.5715306],
          [101.3576811, 4.5344173],
          [101.3775375, 4.5223992],
          [101.3853382, 4.5022509],
          [101.3587448, 4.4842229],
          [101.3583902, 4.4669015],
          [101.3346334, 4.4329646],
          [101.3537807, 4.4131673],
          [101.3580356, 4.3972583],
          [101.3672547, 4.3947836],
          [101.3867565, 4.4089249],
          [101.4074119, 4.3964435],
          [101.4090355, 4.3954624],
          [101.4101588, 4.3947836],
          [101.4356885, 4.3873593],
          [101.44278, 4.3735711],
          [101.4243419, 4.3413977],
          [101.4484533, 4.3141729],
          [101.4519991, 4.2685605],
          [101.4622819, 4.2519413],
          [101.462991, 4.2473445],
          [101.4512899, 4.2370898],
          [101.4502262, 4.2278959],
          [101.4615727, 4.2133976],
          [101.5037676, 4.2070324],
          [101.5066043, 4.1971309],
          [101.4924211, 4.1603527],
          [101.5019947, 4.1476214],
          [101.5023493, 4.1313533],
          [101.5140504, 4.1168532],
          [101.5310702, 4.1253411],
          [101.5466717, 4.1101335],
          [101.5562453, 4.1062432],
          [101.5576637, 4.0966941],
          [101.5718468, 4.0821934],
          [101.572556, 4.0691071],
          [101.5576637, 4.0376285],
          [101.5587274, 4.0139303],
          [101.5693648, 3.998367],
          [101.5672373, 3.9842182],
          [101.5714922, 3.9750214],
          [101.5729106, 3.9361106],
          [101.5718468, 3.8964905],
          [101.5973765, 3.8752646],
          [101.5941853, 3.8437787],
          [101.5870937, 3.833519],
          [101.5938307, 3.8214902],
          [101.5941853, 3.8101688],
          [101.5881575, 3.804508],
          [101.5895758, 3.7949554],
          [101.6097868, 3.7931864],
          [101.6247931, 3.7724706],
          [101.6194409, 3.7724706],
          [101.610046, 3.7738909],
          [101.6050924, 3.7728683],
          [101.5995705, 3.7713769],
          [101.5811802, 3.7700345],
          [101.5796126, 3.7667784],
          [101.5791861, 3.763565],
          [101.5795495, 3.7614048],
          [101.577878, 3.7580625],
          [101.578004, 3.7556276],
          [101.5767697, 3.754042],
          [101.5763675, 3.7519709],
          [101.5767295, 3.7502818],
          [101.5777047, 3.7461195],
          [101.5790218, 3.7434149],
          [101.5808636, 3.7421518],
          [101.5819299, 3.7385496],
          [101.5815169, 3.7379908],
          [101.5816727, 3.7370257],
          [101.5814314, 3.7361258],
          [101.5815923, 3.7353617],
          [101.5811348, 3.734105],
          [101.5811663, 3.73277],
          [101.5812454, 3.7310385],
          [101.5809941, 3.7303348],
          [101.5813359, 3.7287312],
          [101.5817029, 3.7282385],
          [101.5818687, 3.7276855],
          [101.5816753, 3.726488],
          [101.5812253, 3.7260468],
          [101.5799937, 3.7251972],
          [101.5798078, 3.7243821],
          [101.578933, 3.7235182],
          [101.5789138, 3.721],
          [101.5781136, 3.7201049],
          [101.5773897, 3.7201551],
          [101.5771106, 3.7208159],
          [101.5767412, 3.7209896],
          [101.5752716, 3.7200986],
          [101.57369, 3.7199609],
          [101.5728821, 3.7202449],
          [101.5721985, 3.7203002],
          [101.5716181, 3.7197474],
          [101.5713814, 3.7183057],
          [101.5703737, 3.7173594],
          [101.5695744, 3.7164646],
          [101.569435, 3.7155303],
          [101.5694839, 3.7151275],
          [101.5696297, 3.7148057],
          [101.5699688, 3.7143541],
          [101.5698609, 3.7141975],
          [101.5687573, 3.7136016],
          [101.5684685, 3.7133328],
          [101.5686444, 3.7124129],
          [101.5684517, 3.7114496],
          [101.5682265, 3.7112685],
          [101.5672383, 3.7112046],
          [101.5653899, 3.7103443],
          [101.5649677, 3.7098215],
          [101.5646609, 3.7097958],
          [101.5638214, 3.7100974],
          [101.5629165, 3.7098913],
          [101.5625395, 3.7094238],
          [101.5616631, 3.7091853],
          [101.5598783, 3.7070884],
          [101.559845, 3.7065685],
          [101.5603678, 3.7060055],
          [101.5604834, 3.7050654],
          [101.5602183, 3.704464],
          [101.5594976, 3.7036072],
          [101.5588524, 3.7033747],
          [101.5582213, 3.7033613],
          [101.5569924, 3.7034808],
          [101.5552837, 3.7041218],
          [101.5546368, 3.7042896],
          [101.5539587, 3.7043073],
          [101.553499, 3.7042149],
          [101.5533331, 3.7040836],
          [101.5532065, 3.7038634],
          [101.5531373, 3.7037125],
          [101.5528007, 3.7035521],
          [101.5521204, 3.7032172],
          [101.5509652, 3.702406],
          [101.5508332, 3.702196],
          [101.5507926, 3.7019141],
          [101.5508506, 3.7015141],
          [101.550874, 3.7012026],
          [101.5507772, 3.7010168],
          [101.5506379, 3.7008311],
          [101.5502902, 3.7007859],
          [101.5497124, 3.7006241],
          [101.5492647, 3.7003429],
          [101.5490945, 3.7002316],
          [101.5489525, 3.7003675],
          [101.5488952, 3.7005546],
          [101.5488866, 3.7013984],
          [101.548957, 3.701752],
          [101.5488504, 3.7018796],
          [101.5482692, 3.7020712],
          [101.547673, 3.7022424],
          [101.5474657, 3.7022192],
          [101.5473302, 3.7021158],
          [101.5472585, 3.7018994],
          [101.5471802, 3.7015881],
          [101.5469386, 3.7011634],
          [101.5466679, 3.7008791],
          [101.5463743, 3.7007695],
          [101.5462188, 3.7006351],
          [101.5461131, 3.7002746],
          [101.5459408, 3.6993672],
          [101.5458712, 3.6986152],
          [101.5459219, 3.6983612],
          [101.5460576, 3.6982202],
          [101.5462341, 3.6978],
          [101.5459598, 3.6974119],
          [101.5457943, 3.6971861],
          [101.5457788, 3.6970273],
          [101.5458949, 3.6968666],
          [101.5461618, 3.6968428],
          [101.546503, 3.6967978],
          [101.5466722, 3.6967342],
          [101.5468203, 3.6966635],
          [101.546835, 3.6964697],
          [101.546676, 3.6962916],
          [101.5464231, 3.6961584],
          [101.5463143, 3.6959823],
          [101.5462268, 3.6958062],
          [101.545587, 3.6947075],
          [101.5449679, 3.693911],
          [101.544658, 3.6935461],
          [101.5443258, 3.6933982],
          [101.543726, 3.6934686],
          [101.5432776, 3.693429],
          [101.542861, 3.6932843],
          [101.5427792, 3.6930199],
          [101.5430622, 3.692096],
          [101.5429574, 3.6918481],
          [101.5425084, 3.6914766],
          [101.5421947, 3.6910841],
          [101.5417401, 3.6898986],
          [101.5414528, 3.6890503],
          [101.5410691, 3.688202],
          [101.5408637, 3.6879484],
          [101.5397834, 3.68754],
          [101.5394015, 3.6873495],
          [101.5392554, 3.6870996],
          [101.5390541, 3.6864375],
          [101.5385988, 3.6850976],
          [101.538536, 3.6843692],
          [101.5387664, 3.6838108],
          [101.5391226, 3.6837171],
          [101.5394108, 3.683519],
          [101.5394379, 3.6832277],
          [101.5393275, 3.6830545],
          [101.5391002, 3.6829885],
          [101.5388145, 3.6831479],
          [101.538527, 3.683365],
          [101.5383075, 3.6834804],
          [101.5380463, 3.6835244],
          [101.5377987, 3.6834158],
          [101.5372342, 3.6831211],
          [101.5366904, 3.6829157],
          [101.5363815, 3.6826922],
          [101.5359723, 3.6822232],
          [101.5357127, 3.681975],
          [101.535211, 3.6817134],
          [101.534675, 3.6815068],
          [101.534388, 3.6812703],
          [101.5343409, 3.6808085],
          [101.5342348, 3.680399],
          [101.5340371, 3.680256],
          [101.5338281, 3.6801914],
          [101.5335446, 3.680324],
          [101.5333822, 3.6806926],
          [101.5331496, 3.6811793],
          [101.5328955, 3.6814981],
          [101.5325069, 3.6817469],
          [101.5322231, 3.6818232],
          [101.5318746, 3.681748],
          [101.5314938, 3.6815798],
          [101.5311406, 3.6815078],
          [101.5300578, 3.6813431],
          [101.529515, 3.6811755],
          [101.5288473, 3.6810158],
          [101.5284515, 3.6810115],
          [101.5281572, 3.6812361],
          [101.5279179, 3.6814745],
          [101.5275825, 3.6816386],
          [101.5271119, 3.6816451],
          [101.5268559, 3.6816277],
          [101.5266384, 3.681406],
          [101.5264884, 3.6813364],
          [101.5260416, 3.6813359],
          [101.5257355, 3.6813563],
          [101.5253469, 3.6811912],
          [101.5238209, 3.6800572],
          [101.5225083, 3.6786209],
          [101.5221717, 3.6784384],
          [101.5220472, 3.6784022],
          [101.5201541, 3.67795],
          [101.5195782, 3.6778568],
          [101.5191328, 3.6779165],
          [101.5186791, 3.6782094],
          [101.5174625, 3.6796219],
          [101.5169198, 3.6802858],
          [101.516712, 3.6806223],
          [101.5166315, 3.6809588],
          [101.5164465, 3.6818028],
          [101.5156355, 3.6829592],
          [101.5152926, 3.6832722],
          [101.5151896, 3.6833378],
          [101.5149186, 3.6834295],
          [101.514741, 3.6835456],
          [101.5146461, 3.6836642],
          [101.5145702, 3.6838203],
          [101.514537, 3.6839744],
          [101.5144714, 3.6847127],
          [101.5144051, 3.6849478],
          [101.5142857, 3.6852042],
          [101.5139386, 3.6856107],
          [101.5135615, 3.6859205],
          [101.5128397, 3.6867417],
          [101.512675, 3.6868813],
          [101.5124066, 3.6870143],
          [101.5117886, 3.6872324],
          [101.5111005, 3.6875929],
          [101.5110487, 3.6876201],
          [101.5108969, 3.687687],
          [101.5089752, 3.6885334],
          [101.5077745, 3.6894387],
          [101.5067607, 3.6907496],
          [101.5053799, 3.692368],
          [101.5051572, 3.6925417],
          [101.5048706, 3.6926187],
          [101.5044677, 3.6925988],
          [101.5040928, 3.6927234],
          [101.503511, 3.6931311],
          [101.5031509, 3.6931727],
          [101.5023974, 3.6930245],
          [101.5021063, 3.6931373],
          [101.5010571, 3.6939967],
          [101.5005621, 3.6945294],
          [101.5003071, 3.6949463],
          [101.5001969, 3.6954703],
          [101.5002567, 3.696304],
          [101.5002922, 3.6967317],
          [101.5002278, 3.6969764],
          [101.5000918, 3.6971646],
          [101.4998391, 3.6973155],
          [101.4995485, 3.6974402],
          [101.4992382, 3.6975031],
          [101.4989445, 3.6974519],
          [101.4984696, 3.6973097],
          [101.4982374, 3.6973399],
          [101.4978573, 3.6975495],
          [101.4974892, 3.6978755],
          [101.4973882, 3.6981079],
          [101.497373, 3.6983398],
          [101.4975387, 3.6985985],
          [101.4976366, 3.698799],
          [101.4975426, 3.6990837],
          [101.4974205, 3.6992007],
          [101.4971858, 3.6992668],
          [101.4965201, 3.6993617],
          [101.4959505, 3.6992369],
          [101.4956097, 3.698921],
          [101.4953485, 3.6987553],
          [101.4951435, 3.6987688],
          [101.4948999, 3.6988891],
          [101.4946096, 3.6990839],
          [101.4944212, 3.6993897],
          [101.4941939, 3.6996192],
          [101.4939151, 3.6997808],
          [101.4936145, 3.6998332],
          [101.4933467, 3.6998111],
          [101.4930632, 3.699711],
          [101.4928418, 3.6995201],
          [101.4926792, 3.6991755],
          [101.4924848, 3.6989617],
          [101.4922031, 3.6987493],
          [101.4918805, 3.6986927],
          [101.4916369, 3.698794],
          [101.4912858, 3.6991097],
          [101.4910695, 3.6991912],
          [101.4908982, 3.6992051],
          [101.490752, 3.6991303],
          [101.4906368, 3.6989061],
          [101.4905747, 3.6982434],
          [101.4904317, 3.6980737],
          [101.4902299, 3.697986],
          [101.4899943, 3.6980716],
          [101.4897007, 3.6981775],
          [101.4891881, 3.6981191],
          [101.4888239, 3.6982545],
          [101.4886092, 3.6984551],
          [101.4885347, 3.6987445],
          [101.488563, 3.699081],
          [101.4885921, 3.6993896],
          [101.4885463, 3.6995351],
          [101.4883709, 3.6996677],
          [101.4881693, 3.6997183],
          [101.4876822, 3.6997824],
          [101.4873619, 3.6998919],
          [101.4868612, 3.7002508],
          [101.4867378, 3.7003161],
          [101.4865844, 3.7002695],
          [101.486483, 3.7001764],
          [101.4862429, 3.6998132],
          [101.4860492, 3.6997266],
          [101.4857248, 3.6998124],
          [101.4855148, 3.6999469],
          [101.4851986, 3.7002578],
          [101.4849199, 3.7005454],
          [101.4848013, 3.7009248],
          [101.4846574, 3.7011711],
          [101.4844352, 3.7013281],
          [101.4841121, 3.7014184],
          [101.4837276, 3.7016084],
          [101.4831833, 3.7018352],
          [101.4825222, 3.7020852],
          [101.4818073, 3.7017372],
          [101.48116, 3.7015452],
          [101.4803628, 3.7015355],
          [101.4801624, 3.7016649],
          [101.4798416, 3.7019779],
          [101.4794514, 3.7027002],
          [101.4791077, 3.7029483],
          [101.4784454, 3.7041712],
          [101.478148, 3.70529],
          [101.477971, 3.7055893],
          [101.4777078, 3.7057761],
          [101.4770765, 3.7063867],
          [101.4769909, 3.7067168],
          [101.4768911, 3.7072658],
          [101.4766137, 3.7077987],
          [101.4763217, 3.7085655],
          [101.4761571, 3.7088238],
          [101.4754174, 3.7095381],
          [101.4754132, 3.7099479],
          [101.4755101, 3.7101174],
          [101.4757002, 3.7104141],
          [101.4756991, 3.7107602],
          [101.4755565, 3.7111788],
          [101.475655, 3.7121721],
          [101.475343, 3.7129041],
          [101.4749928, 3.7132879],
          [101.4746916, 3.7135133],
          [101.4743322, 3.7135914],
          [101.4740057, 3.7134866],
          [101.4736889, 3.7136871],
          [101.4734662, 3.7139762],
          [101.4729137, 3.7144544],
          [101.4725481, 3.7145877],
          [101.4722278, 3.7146493],
          [101.4718487, 3.7143439],
          [101.4715574, 3.7143668],
          [101.4713485, 3.714543],
          [101.4711922, 3.7150073],
          [101.4710291, 3.7152463],
          [101.4707137, 3.715367],
          [101.4700248, 3.7154038],
          [101.4696078, 3.7155654],
          [101.469415, 3.7158203],
          [101.4694029, 3.7160504],
          [101.4696232, 3.7164002],
          [101.4696008, 3.7166289],
          [101.469369, 3.7166947],
          [101.4691297, 3.7167145],
          [101.4685389, 3.7164746],
          [101.4680298, 3.7164793],
          [101.4676566, 3.7164733],
          [101.4673674, 3.7162995],
          [101.4667462, 3.7156857],
          [101.4664146, 3.7154634],
          [101.4660688, 3.7154475],
          [101.465284, 3.7156581],
          [101.4651152, 3.7158369],
          [101.4649271, 3.7161386],
          [101.4647564, 3.71622],
          [101.4646199, 3.7161691],
          [101.4644087, 3.7159784],
          [101.4643576, 3.7157544],
          [101.4645493, 3.7150831],
          [101.4646152, 3.7147937],
          [101.4644668, 3.7145132],
          [101.4642652, 3.7143199],
          [101.464017, 3.7143223],
          [101.4635625, 3.7145948],
          [101.4630126, 3.7147666],
          [101.4623227, 3.7150037],
          [101.4618767, 3.7149931],
          [101.4614706, 3.7151024],
          [101.4611813, 3.7153396],
          [101.4609763, 3.7157209],
          [101.4608278, 3.7158683],
          [101.4604749, 3.7160514],
          [101.4600491, 3.7161005],
          [101.4597819, 3.7161889],
          [101.4595054, 3.7164078],
          [101.4593445, 3.7169946],
          [101.4594712, 3.7173295],
          [101.459818, 3.7176638],
          [101.4599511, 3.7178663],
          [101.4599184, 3.7181594],
          [101.4597596, 3.718466],
          [101.4590681, 3.7192668],
          [101.4582636, 3.7198957],
          [101.4581596, 3.7201611],
          [101.4580263, 3.7204682],
          [101.4577472, 3.7206561],
          [101.4571225, 3.7205365],
          [101.456938, 3.7205302],
          [101.4567183, 3.7206946],
          [101.4567135, 3.720925],
          [101.4568847, 3.7217197],
          [101.4567146, 3.7223952],
          [101.4567312, 3.7226607],
          [101.4569513, 3.7228934],
          [101.4573354, 3.7229113],
          [101.4579355, 3.7227981],
          [101.4581643, 3.7230563],
          [101.4581214, 3.7232256],
          [101.4579572, 3.7234507],
          [101.4574605, 3.7236028],
          [101.4572891, 3.7237951],
          [101.4572639, 3.7241796],
          [101.4573345, 3.7244941],
          [101.4572557, 3.7246874],
          [101.4570794, 3.7248132],
          [101.4568592, 3.7247898],
          [101.4565169, 3.7246531],
          [101.4561873, 3.724678],
          [101.4560325, 3.7248769],
          [101.4561751, 3.725194],
          [101.4567294, 3.7259617],
          [101.4569288, 3.7260955],
          [101.4568698, 3.7262515],
          [101.4567333, 3.7264515],
          [101.4564788, 3.7265159],
          [101.4562315, 3.726328],
          [101.4559236, 3.726064],
          [101.4557374, 3.7258156],
          [101.4555145, 3.7257288],
          [101.4552799, 3.7256418],
          [101.455064, 3.7258064],
          [101.4549685, 3.7260424],
          [101.4550203, 3.7262721],
          [101.4552359, 3.7264892],
          [101.4557314, 3.7266273],
          [101.4559748, 3.726812],
          [101.4560506, 3.7270882],
          [101.4560062, 3.7272753],
          [101.4556775, 3.7276307],
          [101.4553674, 3.7281166],
          [101.4552515, 3.7285105],
          [101.4553518, 3.7288083],
          [101.4552933, 3.7290687],
          [101.4551577, 3.7291795],
          [101.4548117, 3.7291775],
          [101.4544508, 3.729357],
          [101.4543327, 3.7295272],
          [101.4543048, 3.729923],
          [101.4540621, 3.7301045],
          [101.4537527, 3.7301204],
          [101.4535221, 3.7301989],
          [101.4533382, 3.7305197],
          [101.453288, 3.7309563],
          [101.453449, 3.7311958],
          [101.4537525, 3.7312646],
          [101.4540232, 3.7314209],
          [101.4540976, 3.7317895],
          [101.4537981, 3.7323775],
          [101.4538012, 3.7326803],
          [101.453739, 3.7331509],
          [101.4532783, 3.7336821],
          [101.4528059, 3.733849],
          [101.4523335, 3.733988],
          [101.4519491, 3.7341729],
          [101.451722, 3.7343747],
          [101.4517161, 3.7346853],
          [101.4518537, 3.7350454],
          [101.451856, 3.7352484],
          [101.4517368, 3.7354421],
          [101.4515172, 3.7355313],
          [101.4510502, 3.7355921],
          [101.4507092, 3.7356786],
          [101.4505458, 3.7359234],
          [101.4504643, 3.7363226],
          [101.4502895, 3.7367032],
          [101.4501825, 3.737073],
          [101.4499957, 3.7372785],
          [101.4497622, 3.7373163],
          [101.449426, 3.7372054],
          [101.4492205, 3.7373565],
          [101.4492765, 3.7375654],
          [101.4494445, 3.7378154],
          [101.4492644, 3.7385015],
          [101.4493532, 3.7390812],
          [101.4492925, 3.7392601],
          [101.4491337, 3.7393383],
          [101.448536, 3.7392151],
          [101.4476489, 3.7390525],
          [101.4470608, 3.7390349],
          [101.4467438, 3.7389812],
          [101.4462876, 3.738669],
          [101.4460743, 3.7386457],
          [101.4456119, 3.7391608],
          [101.4453924, 3.7396508],
          [101.4451771, 3.7407393],
          [101.4452607, 3.7412592],
          [101.4454279, 3.7417212],
          [101.4454073, 3.7419369],
          [101.4451794, 3.7421447],
          [101.445041, 3.7424111],
          [101.4451006, 3.7427761],
          [101.4453317, 3.7433944],
          [101.4452337, 3.7437362],
          [101.445243, 3.7443113],
          [101.4452243, 3.7453989],
          [101.4451084, 3.7456061],
          [101.4446937, 3.745776],
          [101.4442564, 3.745687],
          [101.4440545, 3.7455463],
          [101.4437065, 3.74547],
          [101.4435337, 3.7455225],
          [101.4434308, 3.7456647],
          [101.443939, 3.7464726],
          [101.4440828, 3.7467119],
          [101.4440343, 3.7469482],
          [101.4439, 3.7470853],
          [101.4435709, 3.7470106],
          [101.4431857, 3.7467122],
          [101.4428449, 3.7467492],
          [101.4425931, 3.7470096],
          [101.4425751, 3.7475489],
          [101.4424831, 3.7478449],
          [101.4420189, 3.7481384],
          [101.4419014, 3.7483387],
          [101.4418213, 3.7487533],
          [101.4420907, 3.7492843],
          [101.4421065, 3.749638],
          [101.4422314, 3.7499166],
          [101.4426116, 3.7501372],
          [101.4427117, 3.7503578],
          [101.4425381, 3.7505753],
          [101.4420272, 3.7510791],
          [101.4418345, 3.7516141],
          [101.4416325, 3.7522873],
          [101.4413688, 3.7525346],
          [101.4411069, 3.7525204],
          [101.4410329, 3.7522211],
          [101.4411178, 3.7519498],
          [101.4411196, 3.7517006],
          [101.4409986, 3.7513244],
          [101.4407998, 3.7512986],
          [101.4404972, 3.7514399],
          [101.4401019, 3.7513888],
          [101.4395684, 3.7510051],
          [101.4391139, 3.7511575],
          [101.4387652, 3.7515398],
          [101.4378265, 3.752096],
          [101.4373165, 3.7527717],
          [101.4367252, 3.7529525],
          [101.4363855, 3.7532563],
          [101.4361837, 3.7536637],
          [101.4359168, 3.7535844],
          [101.4352205, 3.7534095],
          [101.4345573, 3.7530926],
          [101.434382, 3.7528569],
          [101.4340689, 3.7523601],
          [101.4336526, 3.7521361],
          [101.4329729, 3.7517644],
          [101.4326005, 3.7517652],
          [101.4322281, 3.7520094],
          [101.4319821, 3.7524931],
          [101.4315745, 3.7524996],
          [101.4306872, 3.7524493],
          [101.4305162, 3.752297],
          [101.4301949, 3.7518639],
          [101.4297415, 3.7518988],
          [101.4287017, 3.7517459],
          [101.4283933, 3.7518398],
          [101.4280195, 3.7523736],
          [101.4277921, 3.7525585],
          [101.4273139, 3.7533022],
          [101.4271609, 3.753332],
          [101.4269468, 3.753176],
          [101.4259521, 3.7525494],
          [101.424792, 3.7512882],
          [101.4242056, 3.7507928],
          [101.4239124, 3.7507642],
          [101.4237008, 3.7508878],
          [101.4235868, 3.7512011],
          [101.4236844, 3.7515444],
          [101.4240746, 3.7518471],
          [101.4244434, 3.7519869],
          [101.4247742, 3.7520568],
          [101.4248908, 3.7524081],
          [101.4246076, 3.7524297],
          [101.4237309, 3.7523457],
          [101.4233326, 3.7521185],
          [101.4231417, 3.7518751],
          [101.4230137, 3.7514694],
          [101.4227057, 3.7513396],
          [101.422503, 3.7514207],
          [101.4222552, 3.7517615],
          [101.4222624, 3.7524755],
          [101.4221558, 3.752654],
          [101.4219099, 3.7527189],
          [101.4212932, 3.7524604],
          [101.4208716, 3.7523642],
          [101.4204415, 3.7521235],
          [101.4203536, 3.7520743],
          [101.4201343, 3.7523146],
          [101.4200368, 3.7526782],
          [101.4196141, 3.7534637],
          [101.4196173, 3.7539568],
          [101.4193831, 3.7546497],
          [101.4188676, 3.7555721],
          [101.41887, 3.7561875],
          [101.4190513, 3.756738],
          [101.4189926, 3.7569787],
          [101.4187455, 3.7571577],
          [101.4186911, 3.7573973],
          [101.4192708, 3.7585456],
          [101.4192456, 3.7587972],
          [101.4189521, 3.758992],
          [101.4187228, 3.7591057],
          [101.4184593, 3.7590574],
          [101.4177372, 3.7585875],
          [101.4170736, 3.7583292],
          [101.4151699, 3.7580615],
          [101.4147761, 3.7599033],
          [101.4144525, 3.7604542],
          [101.4140875, 3.7608003],
          [101.4136936, 3.7608923],
          [101.4131226, 3.7604108],
          [101.4130079, 3.7600484],
          [101.4129826, 3.7590856],
          [101.412806, 3.7588647],
          [101.4125724, 3.7588223],
          [101.412272, 3.7589242],
          [101.411886, 3.7593808],
          [101.4116442, 3.759244],
          [101.4111829, 3.7586934],
          [101.4107017, 3.7585253],
          [101.410079, 3.7586685],
          [101.4095562, 3.7588131],
          [101.4093761, 3.7590557],
          [101.4093918, 3.7593799],
          [101.4096595, 3.7596716],
          [101.4094946, 3.7598988],
          [101.4084039, 3.7598015],
          [101.4077773, 3.7599151],
          [101.4074152, 3.7601828],
          [101.4073886, 3.7605763],
          [101.4075217, 3.7610976],
          [101.4072645, 3.7613025],
          [101.4068362, 3.7613561],
          [101.4065652, 3.7611477],
          [101.4061694, 3.7612382],
          [101.4059038, 3.7614747],
          [101.4057448, 3.7618308],
          [101.4053238, 3.7617249],
          [101.405186, 3.7614956],
          [101.4049506, 3.7614447],
          [101.4047722, 3.7616536],
          [101.4047724, 3.7620257],
          [101.4044474, 3.7624628],
          [101.4041691, 3.7629415],
          [101.4038348, 3.7629294],
          [101.4035376, 3.7628341],
          [101.4032751, 3.7629568],
          [101.4032984, 3.7632616],
          [101.4035783, 3.7635519],
          [101.4035801, 3.7642406],
          [101.4033867, 3.7648645],
          [101.4034735, 3.7651358],
          [101.4036226, 3.7652796],
          [101.4037947, 3.7651973],
          [101.4040028, 3.7648722],
          [101.4042125, 3.764515],
          [101.4046344, 3.7642714],
          [101.404943, 3.7642389],
          [101.405081, 3.7644417],
          [101.4050158, 3.76475],
          [101.4045621, 3.7653395],
          [101.4042784, 3.7655951],
          [101.4040471, 3.7656337],
          [101.4036632, 3.7655799],
          [101.4028401, 3.7659481],
          [101.4025047, 3.7658917],
          [101.4024589, 3.7656607],
          [101.4024279, 3.7652288],
          [101.4020059, 3.7649561],
          [101.4014289, 3.7649738],
          [101.4012787, 3.7651775],
          [101.4012179, 3.765665],
          [101.4010479, 3.7658623],
          [101.4006584, 3.766019],
          [101.4002534, 3.7657482],
          [101.3999474, 3.7657746],
          [101.3998559, 3.7659733],
          [101.399855, 3.766169],
          [101.3991711, 3.7675167],
          [101.3990211, 3.7680189],
          [101.3984971, 3.7685697],
          [101.3983643, 3.7690466],
          [101.3983128, 3.7696777],
          [101.3977996, 3.7699928],
          [101.3971238, 3.7701213],
          [101.3964459, 3.7705701],
          [101.3959037, 3.770689],
          [101.3951013, 3.7705483],
          [101.394763, 3.7707722],
          [101.3944221, 3.771034],
          [101.394229, 3.7711823],
          [101.3938121, 3.7711253],
          [101.3936067, 3.7712144],
          [101.3926613, 3.7723582],
          [101.3926022, 3.7727555],
          [101.3926134, 3.7733974],
          [101.3924076, 3.7735561],
          [101.3919957, 3.7735218],
          [101.3909578, 3.7729845],
          [101.3902824, 3.7728231],
          [101.3894569, 3.7720853],
          [101.3883874, 3.7717125],
          [101.3876863, 3.7712861],
          [101.3872641, 3.7712426],
          [101.386923, 3.7714805],
          [101.3868662, 3.7718753],
          [101.3869192, 3.772308],
          [101.3868339, 3.7725541],
          [101.3866193, 3.772679],
          [101.3861461, 3.7727657],
          [101.3859339, 3.7729795],
          [101.385836, 3.7732405],
          [101.3859984, 3.7734847],
          [101.3863162, 3.7735224],
          [101.3870265, 3.7733367],
          [101.387241, 3.7735393],
          [101.3872966, 3.7738489],
          [101.3870558, 3.7745835],
          [101.3871405, 3.774987],
          [101.3872725, 3.7753495],
          [101.3870243, 3.7755436],
          [101.3862922, 3.7755348],
          [101.38538, 3.7760295],
          [101.3851809, 3.7762354],
          [101.3850999, 3.7764097],
          [101.3853937, 3.7765973],
          [101.3858518, 3.7765161],
          [101.386465, 3.7765995],
          [101.3865015, 3.7768785],
          [101.3861559, 3.7771981],
          [101.385837, 3.7772671],
          [101.3855262, 3.7775655],
          [101.3851817, 3.7786471],
          [101.3852834, 3.7791224],
          [101.3857089, 3.779623],
          [101.3860093, 3.7798799],
          [101.3862775, 3.780109],
          [101.3860736, 3.7805009],
          [101.3858805, 3.7809933],
          [101.3855994, 3.7813701],
          [101.3853226, 3.7815714],
          [101.3851424, 3.7819579],
          [101.3852797, 3.782278],
          [101.3853226, 3.782642],
          [101.3851724, 3.7830273],
          [101.3850008, 3.7833913],
          [101.3847765, 3.7837018],
          [101.3842497, 3.7840122],
          [101.3840781, 3.7842906],
          [101.3842927, 3.7845903],
          [101.3844643, 3.7849329],
          [101.3845727, 3.7852808],
          [101.3844643, 3.7855538],
          [101.3842068, 3.7859392],
          [101.384018, 3.7861801],
          [101.3840995, 3.7865816],
          [101.3842068, 3.7869027],
          [101.3842562, 3.7871789],
          [101.384121, 3.7873738],
          [101.3839493, 3.7876521],
          [101.3840137, 3.7879518],
          [101.3843141, 3.7884015],
          [101.3844214, 3.7886798],
          [101.3843141, 3.7888939],
          [101.3839922, 3.7890224],
          [101.3836489, 3.7893435],
          [101.3835416, 3.7902856],
          [101.3835202, 3.7906924],
          [101.3834783, 3.7909579],
          [101.3831554, 3.7911206],
          [101.3827906, 3.7913133],
          [101.3824473, 3.7910778],
          [101.3821254, 3.7906496],
          [101.3818926, 3.7902342],
          [101.381589, 3.7905211],
          [101.3814388, 3.7908423],
          [101.3810955, 3.7909708],
          [101.3807521, 3.791035],
          [101.3806727, 3.791248],
          [101.3808326, 3.7915842],
          [101.38131, 3.791763],
          [101.3816351, 3.7918572],
          [101.3815707, 3.7920499],
          [101.3810622, 3.7925412],
          [101.3807521, 3.7929191],
          [101.3807307, 3.7932617],
          [101.380838, 3.793797],
          [101.3806448, 3.7941395],
          [101.3801299, 3.7944179],
          [101.379808, 3.7946962],
          [101.3795505, 3.7950816],
          [101.3793359, 3.7950388],
          [101.3792286, 3.7946534],
          [101.3791364, 3.7943194],
          [101.378778, 3.7946534],
          [101.3785066, 3.7950441],
          [101.3778554, 3.7951458],
          [101.377276, 3.7952743],
          [101.3769756, 3.7954242],
          [101.3772116, 3.7957667],
          [101.3773178, 3.7959991],
          [101.3769756, 3.7965589],
          [101.376525, 3.7967945],
          [101.3764735, 3.7970482],
          [101.3765893, 3.7973725],
          [101.3766966, 3.7975866],
          [101.3769541, 3.7977793],
          [101.3770292, 3.7978907],
          [101.3768468, 3.7980577],
          [101.3764391, 3.7981219],
          [101.3759381, 3.7982932],
          [101.3759456, 3.7986358],
          [101.3759724, 3.798958],
          [101.3756452, 3.7991282],
          [101.3754092, 3.7989569],
          [101.374995, 3.7980769],
          [101.3747483, 3.798685],
          [101.3749157, 3.7999418],
          [101.3747998, 3.8004653],
          [101.3745369, 3.8004995],
          [101.3739865, 3.8003079],
          [101.3737183, 3.8006794],
          [101.3739865, 3.8012907],
          [101.3739865, 3.8017125],
          [101.3736964, 3.8020075],
          [101.3722663, 3.8000267],
          [101.3710511, 3.7995473],
          [101.3699207, 3.7998575],
          [101.3694968, 3.8005907],
          [101.3704294, 3.8016904],
          [101.3703446, 3.8021698],
          [101.3692707, 3.8028748],
          [101.3683381, 3.8023954],
          [101.3674338, 3.8011546],
          [101.3664446, 3.8007317],
          [101.3660207, 3.8014366],
          [101.3647259, 3.8026624],
          [101.364131, 3.8032391],
          [101.3638207, 3.8039233],
          [101.3632175, 3.8044059],
          [101.3625066, 3.8044816],
          [101.3607002, 3.8039271],
          [101.3593796, 3.8034751],
          [101.3586439, 3.8030514],
          [101.3580563, 3.8018616],
          [101.3575611, 3.8011784],
          [101.3570743, 3.8008632],
          [101.3565413, 3.8009472],
          [101.3562825, 3.8015062],
          [101.3563852, 3.8023722],
          [101.3563924, 3.8026658],
          [101.3562458, 3.8029594],
          [101.3560303, 3.8031106],
          [101.3557379, 3.803139],
          [101.3552685, 3.8028426],
          [101.3550374, 3.8023114],
          [101.3550828, 3.8017248],
          [101.3552508, 3.8010147],
          [101.3550342, 3.8002431],
          [101.3546745, 3.7999082],
          [101.3540597, 3.7998872],
          [101.353638, 3.8001937],
          [101.3529542, 3.80064],
          [101.352455, 3.8004877],
          [101.3521259, 3.7999833],
          [101.3519487, 3.7987991],
          [101.3514234, 3.7979481],
          [101.3505441, 3.7971277],
          [101.3499595, 3.7966392],
          [101.3494978, 3.7960968],
          [101.349436, 3.7956158],
          [101.3495742, 3.7952756],
          [101.3493807, 3.7951577],
          [101.3493376, 3.7950315],
          [101.3492811, 3.7947342],
          [101.3492362, 3.7944995],
          [101.3489596, 3.7943626],
          [101.3483514, 3.7945448],
          [101.3478344, 3.7954694],
          [101.3475486, 3.7960189],
          [101.3474725, 3.7968047],
          [101.346673, 3.7972086],
          [101.345716, 3.7971964],
          [101.3446255, 3.796248],
          [101.343321, 3.7961595],
          [101.3427681, 3.7955923],
          [101.3419802, 3.7953334],
          [101.3412279, 3.795496],
          [101.3405142, 3.7948196],
          [101.3402133, 3.794387],
          [101.3397393, 3.7941115],
          [101.339237, 3.7941751],
          [101.3387785, 3.7948758],
          [101.3381145, 3.7952374],
          [101.3372639, 3.795017],
          [101.3364276, 3.7941409],
          [101.3362087, 3.792851],
          [101.336273, 3.7919851],
          [101.3367057, 3.7914418],
          [101.3368702, 3.7909178],
          [101.3363335, 3.7901232],
          [101.3354089, 3.7896454],
          [101.334737, 3.7901123],
          [101.334249, 3.7911866],
          [101.3335306, 3.7907304],
          [101.3331309, 3.7895731],
          [101.3334354, 3.7883651],
          [101.3333654, 3.7879159],
          [101.3327417, 3.7877279],
          [101.3316354, 3.7885098],
          [101.3307229, 3.7880388],
          [101.3303522, 3.7876155],
          [101.3290322, 3.7874716],
          [101.328198, 3.7870077],
          [101.3274252, 3.7864714],
          [101.3263385, 3.7864682],
          [101.3253485, 3.7857096],
          [101.3266014, 3.7837102],
          [101.3267256, 3.7827175],
          [101.3267865, 3.779504],
          [101.3274658, 3.7784414],
          [101.3287144, 3.7743375],
          [101.329275, 3.7716922],
          [101.3293003, 3.7708221],
          [101.3291601, 3.7651927],
          [101.3290135, 3.7637627],
          [101.3290909, 3.7631359],
          [101.329376, 3.7624876],
          [101.3299231, 3.7612788],
          [101.3339877, 3.7571102],
          [101.3349304, 3.7557457],
          [101.3355213, 3.7541861],
          [101.3357576, 3.7532742],
          [101.3362624, 3.7505771],
          [101.3369561, 3.7487919],
          [101.3372016, 3.7478448],
          [101.3374349, 3.7469086],
          [101.3376535, 3.746406],
          [101.3374678, 3.7458083],
          [101.3370282, 3.745339],
          [101.3371991, 3.7449535],
          [101.337424, 3.7440726],
          [101.3373574, 3.7435],
          [101.3372234, 3.7428936],
          [101.3377057, 3.7419351],
          [101.3381481, 3.7407342],
          [101.338357, 3.7400179],
          [101.3384115, 3.7394914],
          [101.3374852, 3.7377999],
          [101.33738, 3.7372728],
          [101.3374582, 3.7368135],
          [101.3383022, 3.7360466],
          [101.338626, 3.7355133],
          [101.3387536, 3.7348868],
          [101.3385092, 3.7338977],
          [101.3385543, 3.7334771],
          [101.3391956, 3.7324591],
          [101.339364, 3.7318346],
          [101.339327, 3.7309864],
          [101.3394191, 3.7302774],
          [101.3399601, 3.7291958],
          [101.3401463, 3.728571],
          [101.3400624, 3.7279523],
          [101.3398991, 3.7273447],
          [101.3402564, 3.7270875],
          [101.3406977, 3.7271006],
          [101.3410851, 3.7271915],
          [101.3412507, 3.7270412],
          [101.3413043, 3.7267046],
          [101.3409434, 3.7264081],
          [101.3405668, 3.7261387],
          [101.3404251, 3.7254447],
          [101.3406852, 3.7247042],
          [101.3411701, 3.7244025],
          [101.3412534, 3.7241194],
          [101.3410589, 3.7237726],
          [101.3404012, 3.7233476],
          [101.3404067, 3.7230624],
          [101.3414929, 3.7217211],
          [101.3417382, 3.7212481],
          [101.3419636, 3.7204803],
          [101.3421277, 3.7200778],
          [101.3428055, 3.7194795],
          [101.34304, 3.7185397],
          [101.3433155, 3.7184415],
          [101.3438586, 3.718642],
          [101.3441706, 3.7185668],
          [101.3441838, 3.7181189],
          [101.3438062, 3.7174589],
          [101.3439164, 3.717045],
          [101.3442844, 3.7168381],
          [101.3446523, 3.7166311],
          [101.3448296, 3.716346],
          [101.3448342, 3.7155417],
          [101.345035, 3.7145137],
          [101.3449487, 3.7141083],
          [101.3445132, 3.7131506],
          [101.3445851, 3.7128582],
          [101.344769, 3.7127149],
          [101.3450564, 3.7128006],
          [101.3456054, 3.7131752],
          [101.3459844, 3.7131543],
          [101.3463448, 3.7129097],
          [101.3466648, 3.7124936],
          [101.3469959, 3.7116637],
          [101.3470682, 3.711215],
          [101.34697, 3.710903],
          [101.3464898, 3.7103204],
          [101.3462811, 3.7099916],
          [101.3463198, 3.7096537],
          [101.3468403, 3.7093893],
          [101.3470281, 3.7091373],
          [101.3469358, 3.7087177],
          [101.3464491, 3.7080522],
          [101.3463751, 3.7076682],
          [101.3466478, 3.7067981],
          [101.3465138, 3.7065737],
          [101.3461349, 3.7063217],
          [101.3457961, 3.7060881],
          [101.3455674, 3.7057515],
          [101.3454742, 3.7054149],
          [101.3455712, 3.7047417],
          [101.3454696, 3.7043792],
          [101.3450784, 3.7040719],
          [101.3447365, 3.7033895],
          [101.3440465, 3.7025072],
          [101.3435967, 3.7010408],
          [101.3430669, 3.7005185],
          [101.3421967, 3.6999282],
          [101.3418625, 3.6994364],
          [101.3417734, 3.6990184],
          [101.3429502, 3.6986542],
          [101.342987, 3.6984007],
          [101.3425289, 3.6977488],
          [101.3420643, 3.6964966],
          [101.3419105, 3.6960822],
          [101.3415396, 3.6953435],
          [101.3408296, 3.6942271],
          [101.3407834, 3.6941402],
          [101.339894, 3.6924346],
          [101.3388256, 3.691388],
          [101.3372931, 3.6903235],
          [101.3370046, 3.6902011],
          [101.3366972, 3.6900708],
          [101.3362732, 3.6900124],
          [101.33527, 3.690286],
          [101.3347961, 3.6903125],
          [101.3345124, 3.6899623],
          [101.3345013, 3.6891907],
          [101.3344211, 3.6888421],
          [101.3342101, 3.6885029],
          [101.3338412, 3.6884466],
          [101.3329844, 3.6886514],
          [101.3327801, 3.6888937],
          [101.3326966, 3.6891266],
          [101.3328884, 3.6894575],
          [101.3332645, 3.6898373],
          [101.3336686, 3.6900959],
          [101.3339033, 3.6901765],
          [101.3340451, 3.6904961],
          [101.3339666, 3.6906745],
          [101.3334043, 3.6909501],
          [101.3327869, 3.6909574],
          [101.3324876, 3.6910636],
          [101.3322965, 3.6914468],
          [101.3321975, 3.6916451],
          [101.3311681, 3.6918305],
          [101.3307644, 3.6917736],
          [101.3301516, 3.6921018],
          [101.3297892, 3.6918931],
          [101.3290383, 3.6912108],
          [101.3288029, 3.6909256],
          [101.3287413, 3.6902984],
          [101.3287263, 3.6899041],
          [101.3284555, 3.6896498],
          [101.3281053, 3.6896066],
          [101.3273604, 3.6898086],
          [101.3269693, 3.6896207],
          [101.3266803, 3.6889396],
          [101.3261112, 3.6883423],
          [101.3258053, 3.6884878],
          [101.3257235, 3.6888034],
          [101.3256933, 3.6891325],
          [101.3254422, 3.6894041],
          [101.324866, 3.6896898],
          [101.3238055, 3.6904429],
          [101.3234518, 3.6907604],
          [101.3233243, 3.6908748],
          [101.3229881, 3.6907815],
          [101.3225016, 3.6904525],
          [101.3218543, 3.6902735],
          [101.3216057, 3.6900341],
          [101.3215458, 3.6896895],
          [101.3215695, 3.6891318],
          [101.3212069, 3.6885634],
          [101.3207163, 3.6883863],
          [101.3200096, 3.6884262],
          [101.3196777, 3.6881356],
          [101.319426, 3.6874765],
          [101.3190133, 3.6872777],
          [101.3185256, 3.6871386],
          [101.3177775, 3.6859984],
          [101.3172854, 3.6855461],
          [101.3169214, 3.6854163],
          [101.316278, 3.685437],
          [101.3158169, 3.6855545],
          [101.3153073, 3.6854633],
          [101.3151491, 3.685225],
          [101.3151666, 3.6849667],
          [101.3153684, 3.6846555],
          [101.3153728, 3.6842965],
          [101.3152377, 3.6840766],
          [101.3148902, 3.6840642],
          [101.3145662, 3.6842508],
          [101.3141028, 3.6847371],
          [101.3138066, 3.6851769],
          [101.313401, 3.6850756],
          [101.3128013, 3.6845646],
          [101.3124907, 3.6845553],
          [101.3117131, 3.6848076],
          [101.3111286, 3.6848244],
          [101.3106699, 3.6845232],
          [101.310344, 3.6838052],
          [101.3104171, 3.6832534],
          [101.3108228, 3.6824982],
          [101.3110869, 3.6823007],
          [101.3115731, 3.6822876],
          [101.3117947, 3.6821098],
          [101.3117124, 3.6819245],
          [101.3111348, 3.6815428],
          [101.3103524, 3.6814161],
          [101.31009, 3.6811387],
          [101.3100446, 3.6804967],
          [101.310407, 3.6799512],
          [101.3104074, 3.6795865],
          [101.3102038, 3.6792114],
          [101.3097908, 3.6790239],
          [101.3090908, 3.6790372],
          [101.3090519, 3.678637],
          [101.3093779, 3.6779905],
          [101.3092569, 3.6770209],
          [101.3089283, 3.6767823],
          [101.3084742, 3.6768344],
          [101.3077108, 3.6773208],
          [101.3075222, 3.6771357],
          [101.3078249, 3.6766684],
          [101.3078367, 3.6761136],
          [101.3075589, 3.6754089],
          [101.3074291, 3.6746267],
          [101.3071487, 3.6744504],
          [101.306866, 3.674449],
          [101.3064974, 3.6745547],
          [101.305925, 3.6745855],
          [101.3055315, 3.6744617],
          [101.3051524, 3.6741428],
          [101.3045372, 3.6732672],
          [101.3028452, 3.6719722],
          [101.301448, 3.6720655],
          [101.3007483, 3.6722016],
          [101.3002327, 3.6725895],
          [101.3001358, 3.6731582],
          [101.3001356, 3.673903],
          [101.2998727, 3.6743931],
          [101.2985318, 3.6754934],
          [101.2984899, 3.6758057],
          [101.2988085, 3.6764454],
          [101.2987872, 3.6768835],
          [101.2982403, 3.6770971],
          [101.2979326, 3.6774818],
          [101.2973677, 3.6779828],
          [101.2970157, 3.6778302],
          [101.2965234, 3.6769988],
          [101.2961153, 3.6768572],
          [101.2958444, 3.6771212],
          [101.2956965, 3.677473],
          [101.2957889, 3.6782754],
          [101.2954031, 3.6783889],
          [101.294687, 3.6774489],
          [101.2942506, 3.6772531],
          [101.2938294, 3.6774158],
          [101.2935366, 3.677937],
          [101.2930074, 3.6780799],
          [101.2926223, 3.6779504],
          [101.2923615, 3.6777455],
          [101.2924569, 3.6775252],
          [101.2928122, 3.6774591],
          [101.2930533, 3.6770206],
          [101.2930463, 3.6765729],
          [101.2926268, 3.6762641],
          [101.2917439, 3.6765238],
          [101.290667, 3.6772237],
          [101.2904073, 3.6770837],
          [101.2905467, 3.6765497],
          [101.2905103, 3.676117],
          [101.2904093, 3.6757464],
          [101.2896732, 3.6752268],
          [101.2892037, 3.6752671],
          [101.2887902, 3.6757921],
          [101.2880156, 3.6762473],
          [101.2876095, 3.6761952],
          [101.2874065, 3.6757777],
          [101.287449, 3.675437],
          [101.2874918, 3.6751402],
          [101.2874597, 3.6748214],
          [101.2872595, 3.6745772],
          [101.2867084, 3.6748196],
          [101.2864562, 3.6750433],
          [101.2860547, 3.6749798],
          [101.2850881, 3.6744447],
          [101.2844765, 3.6735181],
          [101.2841001, 3.67343],
          [101.2832767, 3.6736656],
          [101.2816322, 3.6735597],
          [101.28137, 3.6733233],
          [101.2813314, 3.6730685],
          [101.2814616, 3.6727547],
          [101.2819003, 3.6723555],
          [101.282157, 3.6719881],
          [101.2821733, 3.6715807],
          [101.2819467, 3.6707072],
          [101.2819819, 3.6703093],
          [101.2822424, 3.6697002],
          [101.2821671, 3.6692092],
          [101.2817743, 3.6682709],
          [101.2813803, 3.6680959],
          [101.281202, 3.6683626],
          [101.2811315, 3.6693161],
          [101.2809095, 3.6696065],
          [101.2805556, 3.6694907],
          [101.28036, 3.6690786],
          [101.2806171, 3.6683133],
          [101.2805776, 3.6677815],
          [101.2803523, 3.6677021],
          [101.2799408, 3.6678301],
          [101.2798098, 3.6681738],
          [101.279875, 3.6688303],
          [101.2796835, 3.6692517],
          [101.2793635, 3.6692947],
          [101.2791102, 3.6690365],
          [101.2790022, 3.6687397],
          [101.2791723, 3.6682744],
          [101.2790519, 3.6678739],
          [101.2787862, 3.6679533],
          [101.278203, 3.6681819],
          [101.2772053, 3.6681143],
          [101.276912, 3.6683788],
          [101.2764505, 3.668662],
          [101.2755774, 3.6690278],
          [101.2746589, 3.6699949],
          [101.2741441, 3.6698808],
          [101.2740074, 3.6693657],
          [101.2737798, 3.6691508],
          [101.2733434, 3.6704135],
          [101.2727248, 3.6704259],
          [101.2725807, 3.6701091],
          [101.2729298, 3.6696341],
          [101.2729471, 3.6692085],
          [101.2726771, 3.6689459],
          [101.272204, 3.6687207],
          [101.2720053, 3.668339],
          [101.271827, 3.6680498],
          [101.2714592, 3.667616],
          [101.2710579, 3.6676827],
          [101.2710133, 3.6680275],
          [101.2709241, 3.6684614],
          [101.2707012, 3.6687951],
          [101.2699433, 3.6689619],
          [101.2696535, 3.6688618],
          [101.269542, 3.668339],
          [101.2693748, 3.6678051],
          [101.2689958, 3.6678162],
          [101.2687395, 3.6682166],
          [101.2686392, 3.6687394],
          [101.2684385, 3.6691288],
          [101.268015, 3.6693179],
          [101.2674911, 3.6692066],
          [101.2670007, 3.6694513],
          [101.2662761, 3.6696071],
          [101.2657746, 3.6692734],
          [101.2655405, 3.6689063],
          [101.2653287, 3.6688729],
          [101.2652507, 3.6691176],
          [101.2651727, 3.6695515],
          [101.264816, 3.6698073],
          [101.2643924, 3.6697517],
          [101.2644147, 3.6691844],
          [101.2645039, 3.6685726],
          [101.2643384, 3.6683365],
          [101.2640267, 3.6682776],
          [101.2635676, 3.6686171],
          [101.2627093, 3.6689063],
          [101.2624976, 3.669329],
          [101.2623749, 3.6698184],
          [101.2621966, 3.6700965],
          [101.262013, 3.6700592],
          [101.2619431, 3.6697234],
          [101.2616018, 3.6694427],
          [101.2613049, 3.6695515],
          [101.2609148, 3.669874],
          [101.260324, 3.6699074],
          [101.259867, 3.6701187],
          [101.2598336, 3.6704302],
          [101.2598782, 3.6712645],
          [101.2597333, 3.671854],
          [101.2590756, 3.6724435],
          [101.2581839, 3.6731999],
          [101.257157, 3.6733124],
          [101.2569968, 3.673136],
          [101.2573814, 3.6726215],
          [101.2573814, 3.6723546],
          [101.2570582, 3.6722878],
          [101.2566457, 3.672666],
          [101.2560884, 3.6727995],
          [101.255598, 3.6725993],
          [101.2552636, 3.6721321],
          [101.2549961, 3.6715314],
          [101.2545837, 3.6713312],
          [101.2541267, 3.6716204],
          [101.2537923, 3.672121],
          [101.2533353, 3.6723323],
          [101.2529786, 3.6725437],
          [101.2520423, 3.6731777],
          [101.2518528, 3.6735114],
          [101.251786, 3.6740231],
          [101.2515742, 3.6742567],
          [101.2510503, 3.6744458],
          [101.2505822, 3.6745904],
          [101.2503035, 3.6748351],
          [101.2503035, 3.6753801],
          [101.2501697, 3.6757694],
          [101.2497462, 3.6761699],
          [101.2493003, 3.6764702],
          [101.2492112, 3.676815],
          [101.2494452, 3.6770931],
          [101.2499914, 3.677171],
          [101.2504707, 3.6774824],
          [101.250649, 3.6778495],
          [101.2504373, 3.6781498],
          [101.2499134, 3.6781387],
          [101.2493338, 3.6780497],
          [101.2490997, 3.6782722],
          [101.2486204, 3.6787727],
          [101.2484421, 3.6791287],
          [101.2485758, 3.6795625],
          [101.2485647, 3.6800185],
          [101.2481188, 3.6802633],
          [101.247294, 3.6803411],
          [101.2466475, 3.6800853],
          [101.2461348, 3.680152],
          [101.2459119, 3.6805191],
          [101.2461794, 3.6809752],
          [101.2466698, 3.6813089],
          [101.2468259, 3.6815981],
          [101.2467367, 3.6821987],
          [101.2464023, 3.6829662],
          [101.245767, 3.6832109],
          [101.2452096, 3.6831776],
          [101.2449979, 3.6833333],
          [101.2450313, 3.683667],
          [101.2457781, 3.6839006],
          [101.2461014, 3.6842565],
          [101.2459676, 3.6849684],
          [101.2461236, 3.6852576],
          [101.2463912, 3.6857915],
          [101.2463354, 3.6863254],
          [101.2463354, 3.6865702],
          [101.2466587, 3.6872932],
          [101.2467367, 3.6877603],
          [101.2464469, 3.6886724],
          [101.2465806, 3.6890951],
          [101.2475058, 3.6899405],
          [101.2484086, 3.690352],
          [101.2488322, 3.6911752],
          [101.2491554, 3.6919538],
          [101.2491443, 3.6925322],
          [101.2487096, 3.6927213],
          [101.2483529, 3.6923765],
          [101.2481523, 3.6915756],
          [101.2477844, 3.6912085],
          [101.2475058, 3.6913086],
          [101.247372, 3.6917869],
          [101.2475727, 3.6924654],
          [101.2480788, 3.6932766],
          [101.2481543, 3.6937813],
          [101.2481014, 3.6946399],
          [101.2475052, 3.6964022],
          [101.2470901, 3.6974039],
          [101.2472562, 3.6979989],
          [101.2471882, 3.6985336],
          [101.2470151, 3.6988228],
          [101.2465316, 3.698255],
          [101.2460939, 3.6977654],
          [101.2456712, 3.6978709],
          [101.2456561, 3.6983303],
          [101.2461618, 3.6987897],
          [101.2469241, 3.6990823],
          [101.2471279, 3.6993169],
          [101.2469165, 3.6996558],
          [101.2461543, 3.6999571],
          [101.2451973, 3.7002445],
          [101.2447597, 3.7007664],
          [101.2445996, 3.7015333],
          [101.2446957, 3.7020445],
          [101.2450906, 3.7023002],
          [101.2456776, 3.7022256],
          [101.2462753, 3.7021723],
          [101.2463073, 3.7024173],
          [101.2461045, 3.7026729],
          [101.245763, 3.7029712],
          [101.245731, 3.7033759],
          [101.2459551, 3.7039191],
          [101.246286, 3.7044197],
          [101.2462433, 3.7048138],
          [101.2461899, 3.7052292],
          [101.2462433, 3.7055913],
          [101.2464781, 3.7059961],
          [101.2466596, 3.7064328],
          [101.2466275, 3.7068162],
          [101.2462753, 3.7072422],
          [101.2460939, 3.7077109],
          [101.2460618, 3.708073],
          [101.2462326, 3.7085843],
          [101.2465635, 3.7092553],
          [101.2467129, 3.7100222],
          [101.2469371, 3.7105334],
          [101.2475561, 3.711034],
          [101.2478977, 3.711492],
          [101.247951, 3.7118861],
          [101.2477269, 3.712195],
          [101.2472573, 3.7123654],
          [101.2470865, 3.7125571],
          [101.2472466, 3.7127382],
          [101.247919, 3.7128553],
          [101.2483673, 3.7131216],
          [101.2487409, 3.7134198],
          [101.248965, 3.7139311],
          [101.2489543, 3.7147725],
          [101.248997, 3.7150707],
          [101.2490931, 3.7159654],
          [101.2488476, 3.7166044],
          [101.2486982, 3.7170411],
          [101.2491144, 3.7172968],
          [101.2496801, 3.7172328],
          [101.2499043, 3.7174885],
          [101.2499896, 3.7178506],
          [101.2498565, 3.7181358],
          [101.2496738, 3.7181879],
          [101.249486, 3.7181728],
          [101.248933, 3.7181595],
          [101.2483246, 3.7182766],
          [101.2477482, 3.7181062],
          [101.2470011, 3.7176589],
          [101.2466702, 3.7177441],
          [101.2464141, 3.7181062],
          [101.2464674, 3.7185429],
          [101.2464247, 3.7187879],
          [101.2460832, 3.7186707],
          [101.2456883, 3.7185216],
          [101.2453787, 3.7189263],
          [101.2448984, 3.7196399],
          [101.2443007, 3.7198636],
          [101.2437884, 3.719821],
          [101.2436283, 3.7190542],
          [101.2431907, 3.7190861],
          [101.242529, 3.7193843],
          [101.2419953, 3.7193737],
          [101.240992, 3.719363],
          [101.2406291, 3.7196399],
          [101.2406718, 3.7201192],
          [101.2403196, 3.7203323],
          [101.2399994, 3.7202364],
          [101.2398286, 3.7196613],
          [101.2399674, 3.7191181],
          [101.2399353, 3.7185642],
          [101.2394444, 3.7177334],
          [101.2389747, 3.7171902],
          [101.2383237, 3.7171263],
          [101.237822, 3.7172755],
          [101.2374378, 3.7176695],
          [101.2370962, 3.7172861],
          [101.237267, 3.7167962],
          [101.2370962, 3.7165086],
          [101.2367227, 3.7162636],
          [101.2352924, 3.7158056],
          [101.2341077, 3.7150814],
          [101.23256, 3.7145275],
          [101.2316955, 3.7145595],
          [101.2312366, 3.7148257],
          [101.2307456, 3.7151027],
          [101.230308, 3.7150068],
          [101.2296356, 3.7140056],
          [101.2290592, 3.7132814],
          [101.2286643, 3.7133027],
          [101.2280772, 3.7139417],
          [101.2274155, 3.7140269],
          [101.2266257, 3.7135796],
          [101.2258061, 3.713461],
          [101.2245657, 3.7132814],
          [101.2229733, 3.7131115],
          [101.2228686, 3.7131003],
          [101.2217535, 3.712936],
          [101.2211567, 3.7123509],
          [101.2204814, 3.7115412],
          [101.2193821, 3.7109457],
          [101.2182043, 3.7103658],
          [101.2175761, 3.7096658],
          [101.2160056, 3.7083651],
          [101.2149953, 3.7079315],
          [101.2145189, 3.7079838],
          [101.2131212, 3.7076129],
          [101.2126135, 3.7077173],
          [101.2124145, 3.7080726],
          [101.2126449, 3.7084539],
          [101.2132469, 3.7089188],
          [101.2139588, 3.7096658],
          [101.2143043, 3.7103293],
          [101.2144771, 3.710977],
          [101.2144456, 3.7112173],
          [101.2143231, 3.7113701],
          [101.2131061, 3.7109152],
          [101.2127705, 3.7110188],
          [101.2125454, 3.7114942],
          [101.2127391, 3.7121733],
          [101.2126768, 3.7124957],
          [101.2123322, 3.7130986],
          [101.2121508, 3.7139468],
          [101.2124826, 3.7144091],
          [101.2129752, 3.7144825],
          [101.2138552, 3.7137395],
          [101.2144367, 3.7134391],
          [101.2145946, 3.7136009],
          [101.2146661, 3.7139496],
          [101.214508, 3.714459],
          [101.2133945, 3.7150102],
          [101.2130752, 3.7154147],
          [101.2133254, 3.7158509],
          [101.2132521, 3.7163785],
          [101.2129121, 3.7167614],
          [101.2123702, 3.7164187],
          [101.2119015, 3.7160651],
          [101.2118127, 3.7156833],
          [101.2115237, 3.7152875],
          [101.2110134, 3.7150606],
          [101.2105016, 3.7153545],
          [101.210017, 3.7151979],
          [101.2100466, 3.714681],
          [101.2106826, 3.7139934],
          [101.2107685, 3.7137202],
          [101.2104945, 3.7134691],
          [101.2096728, 3.7134247],
          [101.2093619, 3.7137719],
          [101.2089547, 3.7141044],
          [101.20871, 3.7141993],
          [101.2083356, 3.7140932],
          [101.2077978, 3.7138237],
          [101.2073926, 3.7141118],
          [101.2072614, 3.7147781],
          [101.2075111, 3.7154711],
          [101.2074286, 3.7159123],
          [101.2072149, 3.7161138],
          [101.2066079, 3.7169043],
          [101.2064746, 3.7175618],
          [101.2066375, 3.718138],
          [101.2067856, 3.7187734],
          [101.2064672, 3.7191132],
          [101.2060893, 3.719277],
          [101.2057402, 3.7195177],
          [101.2051865, 3.7196155],
          [101.204587, 3.7194494],
          [101.2038835, 3.7189137],
          [101.2035504, 3.7189802],
          [101.2034911, 3.7194013],
          [101.2039473, 3.7205009],
          [101.2038391, 3.7209158],
          [101.2030457, 3.7210987],
          [101.2025212, 3.7215921],
          [101.2024557, 3.72196],
          [101.2025071, 3.7224016],
          [101.2024251, 3.7226149],
          [101.2019357, 3.7227556],
          [101.2015797, 3.7227791],
          [101.2011138, 3.7218989],
          [101.2009334, 3.7217357],
          [101.2006165, 3.7216403],
          [101.2003835, 3.7218153],
          [101.2004937, 3.7221249],
          [101.200627, 3.7224735],
          [101.2005801, 3.7228614],
          [101.200428, 3.723022],
          [101.1993499, 3.7236084],
          [101.1991141, 3.7238996],
          [101.1991352, 3.724311],
          [101.1993106, 3.7246636],
          [101.1997004, 3.7250332],
          [101.2001927, 3.725523],
          [101.2006874, 3.7257554],
          [101.2015745, 3.7267152],
          [101.2016244, 3.7270509],
          [101.201386, 3.7273421],
          [101.2006688, 3.7273003],
          [101.2002817, 3.7271266],
          [101.1994884, 3.7272141],
          [101.1975515, 3.7278906],
          [101.1967951, 3.7271279],
          [101.1965869, 3.726195],
          [101.196324, 3.7256113],
          [101.1963049, 3.7251713],
          [101.1963796, 3.7237054],
          [101.196672, 3.72266],
          [101.1976274, 3.7205094],
          [101.1978935, 3.7191103],
          [101.1978343, 3.7182459],
          [101.1980305, 3.7175841],
          [101.1977568, 3.717028],
          [101.197416, 3.717027],
          [101.1971196, 3.7172758],
          [101.1967682, 3.7178839],
          [101.1954642, 3.7182905],
          [101.1948952, 3.7179578],
          [101.1940846, 3.7179948],
          [101.1933296, 3.7185505],
          [101.1926595, 3.719334],
          [101.1923337, 3.7195388],
          [101.1920822, 3.7200243],
          [101.1920931, 3.7204659],
          [101.1923875, 3.7205722],
          [101.193285, 3.7210089],
          [101.1935076, 3.7215071],
          [101.1934029, 3.72204],
          [101.1927642, 3.7222489],
          [101.1913535, 3.7223037],
          [101.1904086, 3.7219459],
          [101.1900351, 3.7216258],
          [101.1894852, 3.7197549],
          [101.189037, 3.7191669],
          [101.1874142, 3.7187594],
          [101.185069, 3.7181117],
          [101.1834881, 3.7171505],
          [101.1820119, 3.7159594],
          [101.1759637, 3.70984],
          [101.1756462, 3.7097276],
          [101.1753307, 3.7096923],
          [101.1748495, 3.7100949],
          [101.1730468, 3.7128726],
          [101.1716661, 3.713556],
          [101.1713108, 3.7138441],
          [101.1707407, 3.7150926],
          [101.1704668, 3.7156208],
          [101.170352, 3.7161417],
          [101.1704335, 3.7171648],
          [101.1703663, 3.7192877],
          [101.1702916, 3.7194944],
          [101.1703663, 3.7197753],
          [101.170465, 3.7201718],
          [101.1706377, 3.72029],
          [101.170961, 3.7203294],
          [101.1712843, 3.7201767],
          [101.1722592, 3.7192729],
          [101.1732168, 3.7179405],
          [101.1737079, 3.7164186],
          [101.1738017, 3.7162043],
          [101.1743742, 3.7154433],
          [101.1750134, 3.7150665],
          [101.1754651, 3.7149015],
          [101.1759014, 3.714942],
          [101.1761413, 3.7150493],
          [101.1764621, 3.7157487],
          [101.1765756, 3.7166008],
          [101.1768718, 3.7171229],
          [101.1789054, 3.719694],
          [101.1811562, 3.7221124],
          [101.1828738, 3.7248067],
          [101.1845471, 3.7264518],
          [101.1860032, 3.7275847],
          [101.1904159, 3.7297371],
          [101.1913142, 3.7303872],
          [101.1914278, 3.7311605],
          [101.1910378, 3.7317122],
          [101.1902826, 3.7316432],
          [101.1871205, 3.7304939],
          [101.1800703, 3.7295953],
          [101.1782065, 3.7293028],
          [101.173027, 3.7278609],
          [101.1716728, 3.7271295],
          [101.1708073, 3.7270877],
          [101.1703605, 3.7277146],
          [101.1702349, 3.7288012],
          [101.1705769, 3.7296232],
          [101.1714006, 3.7311138],
          [101.1715542, 3.7332244],
          [101.171254, 3.7339697],
          [101.1692646, 3.7369232],
          [101.1683851, 3.7391939],
          [101.1681652, 3.7404059],
          [101.1681023, 3.7415761],
          [101.1679802, 3.7420881],
          [101.1677812, 3.7443066],
          [101.1677289, 3.7453933],
          [101.1677917, 3.7459679],
          [101.16768, 3.746654],
          [101.1672123, 3.7481307],
          [101.166989, 3.7490223],
          [101.1661932, 3.7493636],
          [101.1654742, 3.7495586],
          [101.1647622, 3.7494054],
          [101.164141, 3.7491825],
          [101.1637361, 3.7488064],
          [101.1635057, 3.7481446],
          [101.1636802, 3.7470023],
          [101.1640293, 3.7457206],
          [101.1644551, 3.745031],
          [101.1651043, 3.7435335],
          [101.1652718, 3.7423284],
          [101.1649088, 3.7400855],
          [101.1646994, 3.7389989],
          [101.1642457, 3.7374595],
          [101.1638408, 3.7348404],
          [101.163785, 3.7336075],
          [101.1637989, 3.7315875],
          [101.1641689, 3.7268578],
          [101.1640293, 3.7257224],
          [101.1636802, 3.7249492],
          [101.1624677, 3.7252653],
          [101.1620309, 3.7256605],
          [101.1618158, 3.7263525],
          [101.1617829, 3.7279691],
          [101.1613942, 3.729129],
          [101.1601616, 3.7316555],
          [101.1572813, 3.7358418],
          [101.1565821, 3.7377095],
          [101.1564004, 3.7396389],
          [101.1562897, 3.7431319],
          [101.1557674, 3.7442819],
          [101.1550937, 3.7447436],
          [101.1542242, 3.7449529],
          [101.1534095, 3.7449467],
          [101.1516845, 3.7435838],
          [101.1507447, 3.7420902],
          [101.1506148, 3.7409649],
          [101.1507262, 3.7399036],
          [101.1519441, 3.7375174],
          [101.1527621, 3.7348432],
          [101.1535431, 3.7325051],
          [101.1543353, 3.7310239],
          [101.15452, 3.7302494],
          [101.1543057, 3.7291401],
          [101.1540725, 3.7287633],
          [101.1536357, 3.72849],
          [101.1524582, 3.7284395],
          [101.150352, 3.7293999],
          [101.1480167, 3.7306545],
          [101.1454514, 3.7317446],
          [101.1392648, 3.7342092],
          [101.135076, 3.7344453],
          [101.1339714, 3.735046],
          [101.1337285, 3.7359328],
          [101.1338324, 3.7367134],
          [101.1351911, 3.7376109],
          [101.1363899, 3.7385041],
          [101.1368545, 3.7388308],
          [101.1396983, 3.7422913],
          [101.1429458, 3.7440304],
          [101.1444568, 3.7450598],
          [101.1448232, 3.7460158],
          [101.1448546, 3.7468411],
          [101.1441657, 3.7473923],
          [101.135899, 3.7519192],
          [101.1352173, 3.7528013],
          [101.134813, 3.754167],
          [101.1334459, 3.7635704],
          [101.1330606, 3.7640006],
          [101.1325723, 3.7640137],
          [101.1319089, 3.7636559],
          [101.1311259, 3.7625942],
          [101.1294441, 3.7571275],
          [101.1256698, 3.7507849],
          [101.1227077, 3.7472642],
          [101.1221549, 3.745231],
          [101.1225781, 3.7431815],
          [101.1237094, 3.7399236],
          [101.1238651, 3.7388711],
          [101.1235733, 3.737967],
          [101.1230476, 3.7372176],
          [101.1220999, 3.7363227],
          [101.1211566, 3.7358975],
          [101.1195426, 3.7362224],
          [101.1177707, 3.7390092],
          [101.1160044, 3.7418316],
          [101.1128786, 3.7458195],
          [101.1119001, 3.7467646],
          [101.1107491, 3.7473737],
          [101.1094417, 3.7471659],
          [101.1084405, 3.746505],
          [101.1075421, 3.745723],
          [101.1064888, 3.7441067],
          [101.1060621, 3.7423346],
          [101.1060401, 3.7411834],
          [101.1064159, 3.739141],
          [101.1073127, 3.7359271],
          [101.1073007, 3.7349889],
          [101.107074, 3.7339414],
          [101.1056099, 3.7327692],
          [101.1035723, 3.7323286],
          [101.1027901, 3.7323433],
          [101.1017494, 3.7326362],
          [101.1010792, 3.7332824],
          [101.1002906, 3.7341027],
          [101.0999697, 3.7355787],
          [101.0996835, 3.7386163],
          [101.0998052, 3.7417531],
          [101.0993944, 3.7450615],
          [101.0985484, 3.7451416],
          [101.0967518, 3.7450037],
          [101.0960756, 3.7447131],
          [101.0948663, 3.7438955],
          [101.0931486, 3.7422799],
          [101.0918011, 3.7417234],
          [101.090187, 3.7414919],
          [101.0890814, 3.7415855],
          [101.0879757, 3.7420238],
          [101.0864259, 3.7428956],
          [101.085567, 3.7429153],
          [101.0847378, 3.7427676],
          [101.0840319, 3.7423489],
          [101.0833459, 3.7415904],
          [101.0828325, 3.7402556],
          [101.0824574, 3.7383889],
          [101.0819342, 3.735739],
          [101.0814998, 3.734813],
          [101.0812826, 3.7333305],
          [101.0812481, 3.7322715],
          [101.0808631, 3.7309614],
          [101.0805916, 3.7296019],
          [101.0802165, 3.7289518],
          [101.079022, 3.7283312],
          [101.0782767, 3.7281982],
          [101.077867, 3.7283213],
          [101.077482, 3.7286956],
          [101.076786, 3.7288139],
          [101.0765825, 3.7287884],
          [101.0760799, 3.7285028],
          [101.0756555, 3.7286429],
          [101.0759184, 3.7296801],
          [101.0748709, 3.7299847],
          [101.0745222, 3.7299776],
          [101.0743332, 3.7300848],
          [101.0735287, 3.7311143],
          [101.0730333, 3.7322688],
          [101.0729006, 3.7334599],
          [101.073138, 3.7343933],
          [101.0735917, 3.7354521],
          [101.0762792, 3.7395687],
          [101.0759449, 3.7397945],
          [101.0766977, 3.7409489],
          [101.078886, 3.7436261],
          [101.0799225, 3.7455017],
          [101.079734, 3.7465535],
          [101.0783519, 3.7490193],
          [101.0757482, 3.7535678],
          [101.0741357, 3.7570365],
          [101.0738775, 3.7580326],
          [101.0742265, 3.7589451],
          [101.0745266, 3.7592933],
          [101.0752456, 3.7595023],
          [101.0767255, 3.7591331],
          [101.0803344, 3.7580326],
          [101.0847599, 3.7576565],
          [101.0862817, 3.7573361],
          [101.0933738, 3.7551071],
          [101.0944069, 3.7543827],
          [101.0953797, 3.7525263],
          [101.0964854, 3.7513442],
          [101.0980945, 3.7495711],
          [101.100059, 3.7494135],
          [101.1017346, 3.7523766],
          [101.1004574, 3.7535386],
          [101.098663, 3.7550017],
          [101.0973499, 3.7567184],
          [101.0941772, 3.761148],
          [101.0923438, 3.76265],
          [101.0883523, 3.7650364],
          [101.0876418, 3.7662006],
          [101.0873703, 3.7673318],
          [101.087542, 3.7699011],
          [101.0892897, 3.7729608],
          [101.092064, 3.7771169],
          [101.0925242, 3.7784454],
          [101.0926965, 3.7794212],
          [101.0925434, 3.780699],
          [101.0905336, 3.7826978],
          [101.0897296, 3.7828846],
          [101.0883968, 3.7825868],
          [101.0864571, 3.7808136],
          [101.0843129, 3.7785851],
          [101.0829847, 3.7772385],
          [101.0816255, 3.7746381],
          [101.0802498, 3.7714908],
          [101.0796136, 3.770045],
          [101.0784171, 3.7685659],
          [101.0769457, 3.767555],
          [101.0748348, 3.766599],
          [101.0714016, 3.7657426],
          [101.06814, 3.7654],
          [101.0657171, 3.7641735],
          [101.0649595, 3.7630928],
          [101.0641501, 3.7612325],
          [101.0640952, 3.7603844],
          [101.0642969, 3.7578608],
          [101.0639536, 3.7558052],
          [101.0633274, 3.754513],
          [101.061612, 3.7530163],
          [101.0602387, 3.7523312],
          [101.0587261, 3.7526074],
          [101.0573546, 3.7540691],
          [101.0571829, 3.7562959],
          [101.0573546, 3.758694],
          [101.0582631, 3.762304],
          [101.0583774, 3.764182],
          [101.0586113, 3.7664007],
          [101.0585737, 3.7685818],
          [101.0583875, 3.7701733],
          [101.05793, 3.771638],
          [101.0556362, 3.7748752],
          [101.0540166, 3.7775089],
          [101.0540588, 3.7796003],
          [101.0544269, 3.7822861],
          [101.0557754, 3.7850815],
          [101.0582207, 3.7875538],
          [101.0598421, 3.788737],
          [101.060864, 3.7905918],
          [101.0609619, 3.7923367],
          [101.0604078, 3.7938693],
          [101.0589647, 3.7955564],
          [101.0581564, 3.7959617],
          [101.0565851, 3.7959251],
          [101.0530476, 3.7943824],
          [101.0506888, 3.7930049],
          [101.0479641, 3.7902255],
          [101.0459041, 3.7878275],
          [101.0441501, 3.7864274],
          [101.043008, 3.7858991],
          [101.0418772, 3.7855156],
          [101.0404876, 3.7854049],
          [101.0364819, 3.7855024],
          [101.0349048, 3.7850773],
          [101.0330971, 3.7841198],
          [101.0304837, 3.7823863],
          [101.0279239, 3.7822164],
          [101.0263432, 3.7831354],
          [101.0254716, 3.7851304],
          [101.0258786, 3.7866626],
          [101.0267784, 3.7881775],
          [101.0303678, 3.7923128],
          [101.031021, 3.7948063],
          [101.0308645, 3.7969445],
          [101.0302303, 3.7981702],
          [101.0285137, 3.8015959],
          [101.0261105, 3.804679],
          [101.0235203, 3.8060202],
          [101.0197025, 3.8057927],
          [101.015228, 3.803924],
          [101.0098026, 3.8005682],
          [101.0057154, 3.7986433],
          [101.0030973, 3.7987061],
          [100.998988, 3.8003969],
          [100.9966205, 3.8011945],
          [100.9948679, 3.801462],
          [100.991939, 3.8004982],
          [100.9896838, 3.7983421],
          [100.9882502, 3.7969518],
          [100.9861903, 3.7942113],
          [100.9856803, 3.7926585],
          [100.9851028, 3.7909002],
          [100.9848368, 3.788153],
          [100.9846798, 3.7796628],
          [100.9849433, 3.7762107],
          [100.984736, 3.7746666],
          [100.9843234, 3.7732978],
          [100.9833655, 3.7716763],
          [100.9785115, 3.7698402],
          [100.9740757, 3.7693337],
          [100.9703705, 3.7699828],
          [100.9692905, 3.770593],
          [100.9658573, 3.7724772],
          [100.9631686, 3.7754653],
          [100.960851, 3.7786532],
          [100.9591362, 3.7809423],
          [100.9581242, 3.7840795],
          [100.9579253, 3.7891976],
          [100.9587977, 3.7931136],
          [100.9603641, 3.7959435],
          [100.964484, 3.7990266],
          [100.9691124, 3.801892],
          [100.9757828, 3.8044774],
          [100.9775598, 3.8061073],
          [100.9789036, 3.808961],
          [100.9793843, 3.8130692],
          [100.978705, 3.8156383],
          [100.9784868, 3.8160964],
          [100.9777548, 3.8176331],
          [100.9739254, 3.8219784],
          [100.968779, 3.8266068],
          [100.9608791, 3.8290008],
          [100.9522961, 3.8296859],
          [100.9452579, 3.8298572],
          [100.9423512, 3.8310154],
          [100.9401349, 3.8322824],
          [100.9391347, 3.8337427],
          [100.9386432, 3.8352211],
          [100.9383654, 3.8375201],
          [100.938172, 3.8393041],
          [100.9383601, 3.8410699],
          [100.9390399, 3.8441995],
          [100.9404383, 3.8485385],
          [100.9429026, 3.8557315],
          [100.9450217, 3.8616738],
          [100.9456072, 3.8642056],
          [100.9454296, 3.8663386],
          [100.9443743, 3.8685911],
          [100.9436835, 3.8694242],
          [100.9424112, 3.8701848],
          [100.9405624, 3.8705676],
          [100.9385478, 3.8706503],
          [100.9357338, 3.8701891],
          [100.9306116, 3.8688824],
          [100.8945336, 3.8564755],
          [100.8857749, 3.8534635],
          [100.8582288, 3.8546684],
          [100.8463315, 3.8506015],
          [100.8294804, 3.8497019],
          [100.8295953, 3.8543],
          [100.8288814, 3.854322],
          [100.828307, 3.8544225],
          [100.8278043, 3.8545105],
          [100.8267522, 3.8546306],
          [100.8265951, 3.8548328],
          [100.8260289, 3.8554241],
          [100.8251691, 3.8559826],
          [100.825097, 3.8560295],
          [100.8241735, 3.8566395],
          [100.8235775, 3.85691],
          [100.8228549, 3.857201],
          [100.8220905, 3.8573046],
          [100.820852, 3.8572694],
          [100.8203357, 3.8573069],
          [100.8196281, 3.8573583],
          [100.8188987, 3.8575616],
          [100.8187149, 3.8577996],
          [100.8184788, 3.8581052],
          [100.8179296, 3.8584403],
          [100.8167049, 3.8586792],
          [100.8155405, 3.8588681],
          [100.8141588, 3.8590156],
          [100.8122984, 3.8591098],
          [100.8110851, 3.8592275],
          [100.8102165, 3.8595282],
          [100.8094837, 3.8603039],
          [100.8086199, 3.8605741],
          [100.8078744, 3.8608574],
          [100.8072218, 3.8609129],
          [100.8066919, 3.8605216],
          [100.8059837, 3.8599828],
          [100.8055022, 3.8596838],
          [100.8051831, 3.8596582],
          [100.8050184, 3.8598274],
          [100.8046733, 3.8598986],
          [100.8043167, 3.8600013],
          [100.8041515, 3.8603033],
          [100.8040231, 3.8606786],
          [100.8039048, 3.8608343],
          [100.803702, 3.8607884],
          [100.8035094, 3.8604909],
          [100.8033993, 3.8599692],
          [100.8033489, 3.8597949],
          [100.8032573, 3.859684],
          [100.8031377, 3.859593],
          [100.803039, 3.8595727],
          [100.8029236, 3.8595747],
          [100.802861, 3.8596311],
          [100.8027929, 3.8595984],
          [100.8027552, 3.8595726],
          [100.8027549, 3.8595076],
          [100.8026794, 3.8594076],
          [100.801761, 3.8588416],
          [100.8014461, 3.8586768],
          [100.8008177, 3.8586507],
          [100.8000749, 3.8586079],
          [100.7976125, 3.8580479],
          [100.7968079, 3.8578271],
          [100.7964637, 3.8576137],
          [100.7963454, 3.8574518],
          [100.7962526, 3.8573879],
          [100.7961762, 3.8573907],
          [100.7960742, 3.8574981],
          [100.7959978, 3.8575946],
          [100.7958631, 3.8576911],
          [100.7956942, 3.8576523],
          [100.7955472, 3.8575917],
          [100.7948568, 3.8575496],
          [100.794665969908237, 3.857511986705035],
          [100.794042, 3.857389],
          [100.7929166, 3.8572169],
          [100.7925692, 3.8569586],
          [100.7915903, 3.8567968],
          [100.7903493, 3.8561992],
          [100.7896449, 3.8558129],
          [100.7888615, 3.8554689],
          [100.7873775, 3.854894],
          [100.7869983, 3.8545577],
          [100.78632, 3.8539635],
          [100.7850275, 3.8532002],
          [100.7841084, 3.8526666],
          [100.7832718, 3.8521416],
          [100.7820662, 3.8514264],
          [100.7810644, 3.8507862],
          [100.7802498, 3.8500229],
          [100.7789871, 3.8488217],
          [100.7780945, 3.8480855],
          [100.7773006, 3.8476245],
          [100.7765506, 3.8472133],
          [100.7751488, 3.8466272],
          [100.7741816, 3.846344],
          [100.7734163, 3.8462397],
          [100.7726782, 3.8461557],
          [100.77203, 3.8461194],
          [100.7709557, 3.8464277],
          [100.76998, 3.8468959],
          [100.7696722, 3.8471275],
          [100.7690088, 3.847627],
          [100.7678265, 3.848517],
          [100.7664967, 3.8486725],
          [100.7659506, 3.8485632],
          [100.7647288, 3.8486559],
          [100.7640719, 3.8496082],
          [100.7636866, 3.8494892],
          [100.763157, 3.8498578],
          [100.7615946, 3.8496289],
          [100.7599565, 3.8498765],
          [100.7593647, 3.8498525],
          [100.7588799, 3.8494617],
          [100.7585293, 3.8493415],
          [100.7580996, 3.8494569],
          [100.7576189, 3.8497655],
          [100.7571156, 3.8501408],
          [100.756434, 3.8503829],
          [100.7555094, 3.8505807],
          [100.7539481, 3.8512043],
          [100.7536795, 3.8513478],
          [100.7530406, 3.8516891],
          [100.7524987, 3.8519014],
          [100.7520892, 3.8520738],
          [100.7518085, 3.8521474],
          [100.7517441, 3.8523106],
          [100.7517119, 3.852683],
          [100.7516578, 3.852945],
          [100.7515421, 3.8532024],
          [100.7511675, 3.8535082],
          [100.7507394, 3.8539265],
          [100.750272, 3.8542241],
          [100.7500002, 3.8544615],
          [100.7497883, 3.8546433],
          [100.7493529, 3.8548607],
          [100.7488669, 3.8551033],
          [100.7480431, 3.8554865],
          [100.7468218, 3.8560273],
          [100.7458994, 3.8565496],
          [100.7454226, 3.8573546],
          [100.7450636, 3.8579946],
          [100.7445365, 3.8578891],
          [100.7437931, 3.8579684],
          [100.7430523, 3.8584204],
          [100.7423997, 3.8589165],
          [100.740874, 3.8601068],
          [100.7399762, 3.8613596],
          [100.7381089, 3.8622609],
          [100.7364062, 3.8631405],
          [100.7349214, 3.864081],
          [100.7339219, 3.8652835],
          [100.7332227, 3.8666271],
          [100.7320308, 3.8677782],
          [100.7307641, 3.8690527],
          [100.7288555, 3.8705831],
          [100.727228, 3.8713862],
          [100.725669, 3.8723631],
          [100.724069, 3.8729738],
          [100.7210344, 3.8736493],
          [100.7197977, 3.8737356],
          [100.7189281, 3.8739644],
          [100.7184288, 3.8751004],
          [100.7182691, 3.8757462],
          [100.7179868, 3.8765587],
          [100.7177368, 3.877248],
          [100.7171272, 3.8787025],
          [100.7165794, 3.8794526],
          [100.7160995, 3.8802155],
          [100.7153282, 3.8815779],
          [100.7145842, 3.8830661],
          [100.7135246, 3.8849015],
          [100.7129858, 3.8858549],
          [100.7129251, 3.886055],
          [100.7123744, 3.88657],
          [100.7117681, 3.8871369],
          [100.7116561, 3.8873574],
          [100.7116456, 3.8874689],
          [100.7119271, 3.8879564],
          [100.7119234, 3.8881453],
          [100.7117554, 3.888495],
          [100.7112466, 3.8896171],
          [100.7108826, 3.8905323],
          [100.7106081, 3.8908976],
          [100.7104193, 3.8911183],
          [100.7099903, 3.8914397],
          [100.708808, 3.8923389],
          [100.708375, 3.892654],
          [100.7082514, 3.8929287],
          [100.7082914, 3.8943634],
          [100.708269, 3.8950375],
          [100.7077678, 3.8948359],
          [100.7075774, 3.8948999],
          [100.7073871, 3.8947192],
          [100.7070385, 3.8947009],
          [100.706777, 3.8947878],
          [100.7068188, 3.895269],
          [100.707066, 3.8955337],
          [100.7073249, 3.8955229],
          [100.7075334, 3.8956427],
          [100.7077889, 3.8960434],
          [100.7079131, 3.8960955],
          [100.7078824, 3.8965035],
          [100.7077021, 3.8970905],
          [100.7075614, 3.8974512],
          [100.7069858, 3.8975447],
          [100.7069124, 3.8974008],
          [100.7068114, 3.8974008],
          [100.7067725, 3.8975724],
          [100.7066188, 3.8976342],
          [100.7065844, 3.8972681],
          [100.7064812, 3.8971629],
          [100.7064968, 3.8984769],
          [100.7065274, 3.8996098],
          [100.7064963, 3.8999809],
          [100.7063335, 3.9001753],
          [100.7058243, 3.9007176],
          [100.705861, 3.9007977],
          [100.7063472, 3.9004064],
          [100.7066362, 3.9002463],
          [100.7066408, 3.9001067],
          [100.7066883, 3.9000217],
          [100.7071683, 3.9002737],
          [100.7073836, 3.9004932],
          [100.7075398, 3.9008938],
          [100.707393, 3.9008641],
          [100.7073576, 3.901001],
          [100.7076201, 3.9012347],
          [100.7076843, 3.9014887],
          [100.7078925, 3.9016334],
          [100.7078307, 3.9017055],
          [100.7077049, 3.9018525],
          [100.7072394, 3.9016855],
          [100.7069183, 3.9016329],
          [100.7067142, 3.9016283],
          [100.7065422, 3.9015413],
          [100.7062853, 3.9014956],
          [100.7064848, 3.9017473],
          [100.7066637, 3.901777],
          [100.7068793, 3.9016878],
          [100.7072394, 3.9017221],
          [100.7076384, 3.9019212],
          [100.7071594, 3.9023861],
          [100.7071595, 3.90248],
          [100.7078791, 3.9031978],
          [100.7079563, 3.9033659],
          [100.7080265, 3.903853],
          [100.7081882, 3.9042482],
          [100.707887, 3.904604],
          [100.7077518, 3.9047637],
          [100.7071086, 3.9054239],
          [100.7069862, 3.9054693],
          [100.7049249, 3.9053761],
          [100.7044047, 3.9053213],
          [100.7033403, 3.9051279],
          [100.7031715, 3.9050972],
          [100.7031089, 3.9051497],
          [100.7030814, 3.9052663],
          [100.7031525, 3.9054974],
          [100.7032573, 3.9056515],
          [100.7034667, 3.905891],
          [100.7035782, 3.9061642],
          [100.7037534, 3.9067055],
          [100.7040209, 3.9072817],
          [100.7042571, 3.9077812],
          [100.704459, 3.9083352],
          [100.7044782, 3.9096952],
          [100.7043861, 3.9123087],
          [100.7042374, 3.9159271],
          [100.7039152, 3.9195115],
          [100.7039483, 3.9197132],
          [100.7037097, 3.9210896],
          [100.7035615, 3.9229353],
          [100.7032557, 3.9262135],
          [100.703012, 3.9294],
          [100.7026281, 3.9325756],
          [100.7025308, 3.9338701],
          [100.7024839, 3.9344942],
          [100.7021824, 3.938507],
          [100.7018708, 3.9401094],
          [100.7015269, 3.9419617],
          [100.7012753, 3.9442146],
          [100.7010774, 3.9454256],
          [100.7010315, 3.9457063],
          [100.700997, 3.9475337],
          [100.7010824, 3.9490584],
          [100.7009867, 3.9498167],
          [100.7009563, 3.9503074],
          [100.7009306, 3.950549],
          [100.7009491, 3.9506615],
          [100.7010157, 3.9507836],
          [100.7010856, 3.9508928],
          [100.7010754, 3.9509607],
          [100.70106, 3.9510627],
          [100.7009704, 3.9512339],
          [100.7009, 3.9513955],
          [100.7008121, 3.9517633],
          [100.7008257, 3.9519616],
          [100.7008586, 3.9522126],
          [100.7008665, 3.9525559],
          [100.7004454, 3.9551989],
          [100.700496, 3.956221],
          [100.7005507, 3.9573259],
          [100.7006104, 3.9588301],
          [100.7006754, 3.9604712],
          [100.7007996, 3.9630931],
          [100.7009197, 3.965175],
          [100.7010147, 3.9666883],
          [100.7009453, 3.9681685],
          [100.7013248, 3.9693176],
          [100.7013553, 3.9707957],
          [100.7015486, 3.9729367],
          [100.7017994, 3.9741363],
          [100.7020661, 3.9748109],
          [100.702131, 3.9753134],
          [100.7020416, 3.975629],
          [100.7021052, 3.9758518],
          [100.7022956, 3.9760477],
          [100.7022212, 3.9765504],
          [100.7024015, 3.9767353],
          [100.7027436, 3.976717],
          [100.7032016, 3.9766829],
          [100.7032291, 3.9769779],
          [100.7032538, 3.9772422],
          [100.7031467, 3.9773044],
          [100.7029666, 3.9774199],
          [100.7027794, 3.9775937],
          [100.7027304, 3.9778005],
          [100.7027273, 3.9781512],
          [100.7025753, 3.9782399],
          [100.7025153, 3.9785299],
          [100.702578, 3.9799241],
          [100.7027721, 3.9815622],
          [100.7032176, 3.9842268],
          [100.7035275, 3.9863471],
          [100.7040323, 3.9885801],
          [100.7051453, 3.9922386],
          [100.7059942, 3.9946481],
          [100.7074475, 3.9972311],
          [100.7081313, 3.9981668],
          [100.7088729, 3.9993619],
          [100.7096987, 4.0002914],
          [100.7100334, 4.0005196],
          [100.7103344, 4.000859],
          [100.7109783, 4.0013477],
          [100.7116088, 4.0018696],
          [100.7118108, 4.0019975],
          [100.7120883, 4.0021165],
          [100.7123704, 4.0023521],
          [100.7126915, 4.0025122],
          [100.71329, 4.0029263],
          [100.7134574, 4.0031078],
          [100.7136157, 4.0031689],
          [100.7137281, 4.0031231],
          [100.7142533, 4.0036516],
          [100.7146383, 4.003878],
          [100.7150009, 4.003965],
          [100.7151018, 4.0039032],
          [100.7159249, 4.0043571],
          [100.7167012, 4.0045461],
          [100.7179661, 4.0045096],
          [100.719836, 4.004094],
          [100.721285, 4.0041914],
          [100.7225499, 4.0043775],
          [100.7239059, 4.0045867],
          [100.7255692, 4.0047963],
          [100.7270446, 4.0049709],
          [100.7284918, 4.0050837],
          [100.7286272, 4.0055315],
          [100.7290028, 4.0055855],
          [100.7294704, 4.0052034],
          [100.7301747, 4.0051803],
          [100.7308699, 4.0050288],
          [100.7315526, 4.0046825],
          [100.7323116, 4.0040419],
          [100.7330552, 4.0038463],
          [100.7401677, 4.0095796],
          [100.7605447, 4.0173858],
          [100.7608829, 4.021461],
          [100.7619432, 4.0271592],
          [100.7626744, 4.0314518],
          [100.7636906, 4.0347186],
          [100.765066, 4.0374749],
          [100.7660757, 4.0395873],
          [100.7666649, 4.041056],
          [100.7672631, 4.0437311],
          [100.7675367, 4.047438],
          [100.767478, 4.0510567],
          [100.7670235, 4.0531138],
          [100.7666123, 4.0555069],
          [100.7666069, 4.0575555],
          [100.7665543, 4.0624555],
          [100.7661444, 4.0648958],
          [100.7661074, 4.0651158],
          [100.7657296, 4.0674295],
          [100.7652126, 4.0692052],
          [100.7648194, 4.0705532],
          [100.764512, 4.0721299],
          [100.7641975, 4.0728873],
          [100.7640951, 4.0736976],
          [100.7639667, 4.0750726],
          [100.7638993, 4.0758043],
          [100.7638378, 4.0764813],
          [100.7637276, 4.0773238],
          [100.7634141, 4.0780011],
          [100.7633625, 4.0782592],
          [100.7633817, 4.0785349],
          [100.7635543, 4.0786515],
          [100.7638262, 4.0786834],
          [100.764098, 4.0788034],
          [100.7642136, 4.0790719],
          [100.7643563, 4.0798205],
          [100.7642502, 4.0807899],
          [100.7640735, 4.0813451],
          [100.7638291, 4.0816441],
          [100.7635228, 4.0819343],
          [100.7634226, 4.0819859],
          [100.7632578, 4.081948],
          [100.763034, 4.0818899],
          [100.7629113, 4.0820523],
          [100.76293, 4.0823999],
          [100.7632855, 4.0830069],
          [100.7632207, 4.0839919],
          [100.76311, 4.085196],
          [100.7627415, 4.0862476],
          [100.7621899, 4.0868435],
          [100.7616958, 4.0874055],
          [100.7617065, 4.0877414],
          [100.7616261, 4.087902],
          [100.7616475, 4.088009],
          [100.7617199, 4.0881454],
          [100.7622778, 4.0884959],
          [100.7624468, 4.0886056],
          [100.762383, 4.0887684],
          [100.7623556, 4.0888383],
          [100.7620874, 4.0886564],
          [100.761956, 4.0886457],
          [100.7619292, 4.0886404],
          [100.7617629, 4.0885628],
          [100.7613674, 4.0883421],
          [100.7612072, 4.0885196],
          [100.760985, 4.089313],
          [100.7607295, 4.0896356],
          [100.760324, 4.0897815],
          [100.7601672, 4.0899735],
          [100.7600024, 4.0904968],
          [100.759717, 4.0911789],
          [100.7594261, 4.091465],
          [100.7589336, 4.0917649],
          [100.7587545, 4.0917659],
          [100.7586598, 4.091668],
          [100.7585429, 4.0915268],
          [100.758391, 4.0914889],
          [100.7581038, 4.0917475],
          [100.7578368, 4.0920985],
          [100.7575135, 4.0926447],
          [100.7572114, 4.093205],
          [100.7567489, 4.0940044],
          [100.756506, 4.0945227],
          [100.7559349, 4.0951674],
          [100.7555029, 4.0961233],
          [100.7549597, 4.0968519],
          [100.7545297, 4.09766],
          [100.75403, 4.098344],
          [100.7537516, 4.0988328],
          [100.7536557, 4.099355],
          [100.7534472, 4.0997319],
          [100.7530765, 4.1001811],
          [100.7526429, 4.1004885],
          [100.7521827, 4.1008263],
          [100.7519244, 4.1012285],
          [100.7518321, 4.1016253],
          [100.7518474, 4.1019152],
          [100.7517372, 4.1021924],
          [100.751423, 4.1025004],
          [100.7511534, 4.1028927],
          [100.7508573, 4.1029982],
          [100.750446, 4.103037],
          [100.7502703, 4.1033069],
          [100.7501348, 4.1036869],
          [100.7500802, 4.1039734],
          [100.7502608, 4.1042477],
          [100.7501987, 4.1044834],
          [100.7499921, 4.104537],
          [100.7497866, 4.1045182],
          [100.7496308, 4.1045039],
          [100.7491968, 4.1045353],
          [100.7488677, 4.1046373],
          [100.7486629, 4.1049307],
          [100.748582, 4.1051989],
          [100.7483842, 4.1053395],
          [100.7480434, 4.1054372],
          [100.7477784, 4.1054999],
          [100.7474997, 4.1055011],
          [100.7472595, 4.105598],
          [100.7471862, 4.1058347],
          [100.7471194, 4.1060672],
          [100.7469282, 4.1061305],
          [100.7468881, 4.1062784],
          [100.7467226, 4.1063102],
          [100.7465933, 4.1065149],
          [100.7465618, 4.1067478],
          [100.746392, 4.1068623],
          [100.7460726, 4.1068859],
          [100.7457729, 4.1069836],
          [100.7455544, 4.1072115],
          [100.7452583, 4.1073894],
          [100.7451486, 4.1076106],
          [100.7449369, 4.1076735],
          [100.7447485, 4.1077823],
          [100.7447119, 4.108014],
          [100.7447503, 4.1082822],
          [100.7445966, 4.1084313],
          [100.7443464, 4.108382],
          [100.744003, 4.1082581],
          [100.7437398, 4.1085918],
          [100.7437067, 4.1091072],
          [100.7432568, 4.1095124],
          [100.7426157, 4.1096547],
          [100.7418718, 4.1097341],
          [100.7408576, 4.1099738],
          [100.7393462, 4.1106762],
          [100.7381248, 4.1113912],
          [100.7370949, 4.1121025],
          [100.7360362, 4.1128467],
          [100.7352418, 4.1131885],
          [100.7346763, 4.1137054],
          [100.734348, 4.1140336],
          [100.7339089, 4.1141151],
          [100.7335012, 4.1138919],
          [100.7330469, 4.1139362],
          [100.732774, 4.1143079],
          [100.7322959, 4.1145462],
          [100.7316086, 4.1148521],
          [100.7311742, 4.1151771],
          [100.7306878, 4.1152706],
          [100.7302129, 4.1154882],
          [100.7298027, 4.1157858],
          [100.7293785, 4.1160547],
          [100.7290444, 4.1159869],
          [100.7287829, 4.1158258],
          [100.7287091, 4.1154914],
          [100.7283765, 4.1152717],
          [100.7279557, 4.115355],
          [100.7276387, 4.1157117],
          [100.7272046, 4.1158896],
          [100.7266713, 4.1160128],
          [100.7261142, 4.1162628],
          [100.7258492, 4.1166895],
          [100.7259255, 4.1172263],
          [100.7259817, 4.1179233],
          [100.7258959, 4.1187254],
          [100.7256184, 4.119081],
          [100.7254295, 4.1197141],
          [100.7252567, 4.1205141],
          [100.7248645, 4.1211983],
          [100.724661, 4.121575],
          [100.7248538, 4.1220704],
          [100.7242449, 4.123799],
          [100.7237236, 4.1251148],
          [100.7233892, 4.1260954],
          [100.7230613, 4.1266357],
          [100.7228621, 4.1273232],
          [100.7227611, 4.1278962],
          [100.7222578, 4.1287982],
          [100.7220826, 4.1295271],
          [100.721717, 4.1300502],
          [100.7216144, 4.1305609],
          [100.7216516, 4.1309958],
          [100.7212694, 4.1316681],
          [100.720838, 4.1322138],
          [100.7204388, 4.1327228],
          [100.7202526, 4.1330836],
          [100.7200444, 4.1339057],
          [100.719823, 4.1343082],
          [100.7194743, 4.1348529],
          [100.7193239, 4.1352764],
          [100.7190592, 4.1356001],
          [100.7186921, 4.1358217],
          [100.7183584, 4.1362634],
          [100.7182332, 4.1367745],
          [100.7180282, 4.1372182],
          [100.7176204, 4.1375108],
          [100.7170814, 4.1379861],
          [100.716798, 4.1383557],
          [100.7165566, 4.1387013],
          [100.7161234, 4.1388193],
          [100.7160911, 4.1389522],
          [100.716021, 4.1392399],
          [100.7157385, 4.1396026],
          [100.7152661, 4.1396978],
          [100.7148478, 4.140019],
          [100.714477, 4.1404999],
          [100.7143068, 4.1410292],
          [100.7139529, 4.1414337],
          [100.7136109, 4.1419564],
          [100.7134172, 4.1424363],
          [100.7129373, 4.1427919],
          [100.7122447, 4.1434262],
          [100.7117514, 4.1441154],
          [100.7114126, 4.144662],
          [100.7109897, 4.145149],
          [100.7104751, 4.1454857],
          [100.7102146, 4.1458528],
          [100.7099505, 4.1462663],
          [100.7095812, 4.1465101],
          [100.7095341, 4.1468297],
          [100.7094365, 4.1470913],
          [100.7091125, 4.147372],
          [100.7085283, 4.1477266],
          [100.7081386, 4.1482129],
          [100.7078635, 4.1488041],
          [100.7075328, 4.149281],
          [100.7069055, 4.1497647],
          [100.7064025, 4.1501666],
          [100.7057957, 4.1505708],
          [100.7052804, 4.1507637],
          [100.7048519, 4.1512038],
          [100.7044594, 4.1516605],
          [100.7042537, 4.1519709],
          [100.7038293, 4.1521885],
          [100.703486, 4.1524513],
          [100.7033336, 4.152845],
          [100.703285, 4.1530338],
          [100.7029625, 4.1530266],
          [100.7027843, 4.1527891],
          [100.7024128, 4.1527271],
          [100.7021098, 4.1528184],
          [100.7018705, 4.1530326],
          [100.7016196, 4.1531877],
          [100.7014326, 4.1534029],
          [100.7011646, 4.153514],
          [100.7008664, 4.153479],
          [100.7006991, 4.1532678],
          [100.7004573, 4.1531187],
          [100.700241, 4.1532514],
          [100.7002477, 4.1535759],
          [100.7000791, 4.1538508],
          [100.6999043, 4.154048],
          [100.699687, 4.1542424],
          [100.6994551, 4.1544994],
          [100.6993416, 4.1547784],
          [100.6990329, 4.1548184],
          [100.6989827, 4.155157],
          [100.699144, 4.1555914],
          [100.6990916, 4.1559036],
          [100.6989036, 4.156164],
          [100.6986527, 4.1562438],
          [100.6983006, 4.1562368],
          [100.6979909, 4.1563771],
          [100.6975499, 4.1564957],
          [100.69685, 4.1568402],
          [100.6962544, 4.1571859],
          [100.6959017, 4.1577177],
          [100.695543, 4.1582921],
          [100.6953418, 4.1586674],
          [100.6949994, 4.1588818],
          [100.6947925, 4.1591684],
          [100.6945719, 4.1594596],
          [100.6943219, 4.1594576],
          [100.6941145, 4.1593198],
          [100.6939202, 4.1592329],
          [100.6937069, 4.1591334],
          [100.6934634, 4.1590131],
          [100.6932121, 4.1590305],
          [100.6930645, 4.1591286],
          [100.6930093, 4.1593844],
          [100.6930201, 4.1596276],
          [100.692974, 4.1598489],
          [100.6928065, 4.159979],
          [100.692609, 4.1599877],
          [100.6924797, 4.1599101],
          [100.692342, 4.1597583],
          [100.6920596, 4.1597325],
          [100.6917356, 4.1598518],
          [100.6916204, 4.160044],
          [100.6915022, 4.1603433],
          [100.6912962, 4.1604977],
          [100.6909555, 4.1604544],
          [100.6907446, 4.160613],
          [100.6907533, 4.1608061],
          [100.6909874, 4.1609786],
          [100.6908396, 4.161273],
          [100.6907229, 4.1614897],
          [100.6904045, 4.1614742],
          [100.6901956, 4.1612316],
          [100.6898603, 4.1612854],
          [100.6894805, 4.1615099],
          [100.6893723, 4.1617248],
          [100.6890621, 4.1619144],
          [100.6886568, 4.1621708],
          [100.6883832, 4.1623101],
          [100.6882519, 4.162545],
          [100.6882136, 4.1628443],
          [100.688188, 4.1630003],
          [100.6880366, 4.1632571],
          [100.6879109, 4.1633888],
          [100.6876945, 4.1634781],
          [100.6874962, 4.1634739],
          [100.6872391, 4.1632972],
          [100.6871192, 4.1630598],
          [100.6871081, 4.1628299],
          [100.6868957, 4.1626934],
          [100.6866479, 4.1627389],
          [100.6864009, 4.162731],
          [100.6861136, 4.1626816],
          [100.6859244, 4.1628991],
          [100.6856532, 4.1628458],
          [100.6856969, 4.1625702],
          [100.6855747, 4.162175],
          [100.685282, 4.1620582],
          [100.6849929, 4.1621794],
          [100.6849159, 4.1625179],
          [100.6849588, 4.1629888],
          [100.6846721, 4.1630693],
          [100.6844004, 4.1629981],
          [100.6842868, 4.162738],
          [100.6840547, 4.1626454],
          [100.6838054, 4.1628548],
          [100.6838202, 4.1631805],
          [100.6838596, 4.1637335],
          [100.6836128, 4.1640679],
          [100.6832344, 4.1641882],
          [100.6823056, 4.1643934],
          [100.6817276, 4.1644688],
          [100.681129, 4.1645669],
          [100.680684, 4.1647965],
          [100.6805289, 4.165091],
          [100.6803927, 4.1655667],
          [100.680123, 4.1657008],
          [100.6798329, 4.1653471],
          [100.679416, 4.1652374],
          [100.6790612, 4.165356],
          [100.6788557, 4.1655749],
          [100.6786412, 4.1658113],
          [100.6783828, 4.1656014],
          [100.6779808, 4.1656211],
          [100.67751, 4.1658561],
          [100.6770659, 4.1661299],
          [100.6766776, 4.1663522],
          [100.6759704, 4.1663818],
          [100.6754715, 4.1663621],
          [100.6750868, 4.1666633],
          [100.6747553, 4.1667645],
          [100.6742213, 4.1667353],
          [100.6736844, 4.1667761],
          [100.6731345, 4.1669251],
          [100.6724713, 4.1671643],
          [100.6718625, 4.167326],
          [100.6713493, 4.1676125],
          [100.670768, 4.167848],
          [100.6702122, 4.1680927],
          [100.6697778, 4.1682626],
          [100.6694229, 4.1683319],
          [100.6691856, 4.1683155],
          [100.6687728, 4.1682857],
          [100.6685384, 4.1684623],
          [100.6684662, 4.1687284],
          [100.6683578, 4.1689474],
          [100.6681469, 4.1689996],
          [100.6678538, 4.1688418],
          [100.6674674, 4.1688536],
          [100.6672111, 4.1691024],
          [100.666815, 4.1693361],
          [100.6662894, 4.1695003],
          [100.6659299, 4.1696237],
          [100.6654563, 4.1696851],
          [100.6649921, 4.169732],
          [100.6647918, 4.1698913],
          [100.6647513, 4.1701551],
          [100.6647672, 4.1704375],
          [100.6645796, 4.1704555],
          [100.6643918, 4.1702899],
          [100.6641859, 4.1702025],
          [100.6639281, 4.1702941],
          [100.6637324, 4.1702271],
          [100.6634591, 4.1701088],
          [100.6632035, 4.1701615],
          [100.6629521, 4.1702555],
          [100.6629151, 4.1705541],
          [100.6627286, 4.1706975],
          [100.6624616, 4.1706705],
          [100.6623241, 4.1704743],
          [100.6622437, 4.1702836],
          [100.6620162, 4.1701577],
          [100.6615548, 4.1701553],
          [100.6610357, 4.170208],
          [100.6606869, 4.1701566],
          [100.6604583, 4.1699675],
          [100.6601366, 4.1697268],
          [100.6597126, 4.1695422],
          [100.659157, 4.1694984],
          [100.6586946, 4.1695629],
          [100.6583378, 4.1695317],
          [100.6580674, 4.1694241],
          [100.6577256, 4.1693021],
          [100.6574627, 4.169338],
          [100.6573062, 4.1694448],
          [100.657118, 4.169521],
          [100.6568189, 4.1695669],
          [100.6563405, 4.1694596],
          [100.6558156, 4.1694619],
          [100.6554272, 4.1694063],
          [100.6550992, 4.1693779],
          [100.6548601, 4.169245],
          [100.6545507, 4.1689528],
          [100.6541518, 4.1687911],
          [100.6535535, 4.1686453],
          [100.6528531, 4.1687127],
          [100.6520555, 4.1687935],
          [100.6512823, 4.168949],
          [100.651124, 4.1693191],
          [100.6511209, 4.1696259],
          [100.650899, 4.1698316],
          [100.6507375, 4.1698525],
          [100.6503873, 4.1699913],
          [100.6497635, 4.1701405],
          [100.6485868, 4.1701736],
          [100.6476415, 4.1702485],
          [100.6469084, 4.1705643],
          [100.6463583, 4.1706622],
          [100.6458522, 4.1707653],
          [100.6454002, 4.17086],
          [100.6450377, 4.1710012],
          [100.644664, 4.171098],
          [100.6441764, 4.1710454],
          [100.6437001, 4.1711761],
          [100.6432533, 4.1712508],
          [100.6428491, 4.1711803],
          [100.6421693, 4.1709591],
          [100.6413566, 4.1708086],
          [100.6401182, 4.1707766],
          [100.6391698, 4.170756],
          [100.6383772, 4.1707448],
          [100.6375283, 4.1706227],
          [100.6371058, 4.1705071],
          [100.6365466, 4.1702841],
          [100.6358752, 4.1699979],
          [100.6354274, 4.1696269],
          [100.6350909, 4.1689017],
          [100.6347659, 4.1680716],
          [100.6351094, 4.167215],
          [100.6353203, 4.1669007],
          [100.6352914, 4.1665298],
          [100.6351316, 4.1663549],
          [100.6349288, 4.1663695],
          [100.6346579, 4.1660437],
          [100.6343743, 4.1654981],
          [100.6341073, 4.1652224],
          [100.6342058, 4.1648306],
          [100.6340213, 4.164374],
          [100.6339195, 4.1639885],
          [100.6337921, 4.1634609],
          [100.633506, 4.16315],
          [100.6331454, 4.1631294],
          [100.6329465, 4.1630308],
          [100.632761, 4.1627065],
          [100.6320579, 4.1620312],
          [100.6315684, 4.1618433],
          [100.6313622, 4.1617625],
          [100.6310986, 4.1617929],
          [100.6306918, 4.1616662],
          [100.6306263, 4.1615524],
          [100.6304854, 4.161465],
          [100.6296559, 4.161095],
          [100.6293018, 4.1607038],
          [100.6292858, 4.1604259],
          [100.6297291, 4.159783],
          [100.6299333, 4.1596381],
          [100.6299981, 4.1589433],
          [100.6299455, 4.1583806],
          [100.6295133, 4.158528],
          [100.6289873, 4.1586988],
          [100.6284709, 4.1588047],
          [100.6276564, 4.1586782],
          [100.627488, 4.1584066],
          [100.6274864, 4.1579819],
          [100.6273543, 4.1573015],
          [100.6268883, 4.1570368],
          [100.6263046, 4.1569419],
          [100.6257999, 4.156898],
          [100.6256561, 4.1571703],
          [100.6259181, 4.1575916],
          [100.6260251, 4.1581158],
          [100.6259904, 4.1584832],
          [100.6258766, 4.158621],
          [100.6253958, 4.1593151],
          [100.6250174, 4.1597283],
          [100.6246992, 4.1599446],
          [100.6244779, 4.1599086],
          [100.6242292, 4.1602291],
          [100.6241739, 4.1607694],
          [100.6242845, 4.1610082],
          [100.6244384, 4.1610962],
          [100.6245682, 4.1614519],
          [100.6243998, 4.1620373],
          [100.623893, 4.1628623],
          [100.6235546, 4.1633908],
          [100.6232419, 4.1638905],
          [100.6229022, 4.1644419],
          [100.6224318, 4.1649157],
          [100.6218125, 4.165479],
          [100.6211812, 4.1659003],
          [100.6204004, 4.1663106],
          [100.6195197, 4.1666634],
          [100.618646, 4.1668648],
          [100.6178936, 4.1668951],
          [100.6173725, 4.1667395],
          [100.6170585, 4.1664689],
          [100.6170079, 4.1663118],
          [100.6172847, 4.1660425],
          [100.6172859, 4.1656076],
          [100.6171082, 4.1652829],
          [100.6166777, 4.1648784],
          [100.6167173, 4.1646437],
          [100.6168992, 4.1643513],
          [100.6167115, 4.1641245],
          [100.6163249, 4.1643293],
          [100.6160586, 4.1644167],
          [100.6158665, 4.1646223],
          [100.6157927, 4.1649326],
          [100.6157361, 4.1652712],
          [100.6157012, 4.1655706],
          [100.6153475, 4.1657944],
          [100.6150911, 4.1661242],
          [100.6145512, 4.1666468],
          [100.6146403, 4.1667494],
          [100.6146829, 4.1669856],
          [100.6144043, 4.167196],
          [100.6139396, 4.1673398],
          [100.6136624, 4.1676076],
          [100.6135714, 4.1678888],
          [100.6135673, 4.1681419],
          [100.6137348, 4.168063],
          [100.6140033, 4.1680708],
          [100.6141511, 4.1684015],
          [100.6142122, 4.1686401],
          [100.6142417, 4.1689362],
          [100.6141828, 4.1692341],
          [100.6141004, 4.1695585],
          [100.6140771, 4.1700285],
          [100.6139241, 4.170397],
          [100.6139499, 4.1706843],
          [100.613561, 4.1710469],
          [100.6132307, 4.1713214],
          [100.6131721, 4.1715004],
          [100.6134563, 4.1723682],
          [100.6136149, 4.1729465],
          [100.6137642, 4.1733437],
          [100.6138459, 4.1736126],
          [100.613798, 4.1739212],
          [100.6136575, 4.1742574],
          [100.6134203, 4.1743953],
          [100.6130757, 4.1745569],
          [100.6130592, 4.1749639],
          [100.6130742, 4.175246],
          [100.6131662, 4.1754934],
          [100.6133444, 4.175674],
          [100.613302, 4.175789],
          [100.6130953, 4.1762141],
          [100.6127825, 4.1769357],
          [100.6124252, 4.1771371],
          [100.6122555, 4.1776676],
          [100.612275, 4.1782107],
          [100.6123072, 4.1787217],
          [100.612363, 4.1791092],
          [100.6123743, 4.1793797],
          [100.6122428, 4.1796312],
          [100.6120953, 4.1799469],
          [100.6118138, 4.1803404],
          [100.6115535, 4.1807121],
          [100.6113362, 4.1809152],
          [100.6111646, 4.1810838],
          [100.6110761, 4.1810436],
          [100.6110063, 4.1809714],
          [100.610894, 4.181033],
          [100.6108309, 4.1811992],
          [100.6110975, 4.1814208],
          [100.61095, 4.1817713],
          [100.6106754, 4.1818417],
          [100.6105939, 4.1819897],
          [100.6105707, 4.1822287],
          [100.610537, 4.1825363],
          [100.6103304, 4.1827878],
          [100.6099722, 4.1834447],
          [100.6097695, 4.1842148],
          [100.6096438, 4.1845988],
          [100.6093846, 4.185095],
          [100.6090407, 4.1855319],
          [100.6086741, 4.1859912],
          [100.6084438, 4.1862981],
          [100.6083157, 4.1864329],
          [100.6080969, 4.1866424],
          [100.6078852, 4.1868756],
          [100.6076119, 4.1871265],
          [100.607057, 4.187641],
          [100.6062872, 4.1882076],
          [100.6056281, 4.1886133],
          [100.6053292, 4.1887388],
          [100.605044, 4.1887938],
          [100.6045119, 4.1887948],
          [100.6045262, 4.1886615],
          [100.6045557, 4.1885146],
          [100.6045586, 4.1883579],
          [100.6044594, 4.1882784],
          [100.604159, 4.1881978],
          [100.6039297, 4.1882719],
          [100.6038449, 4.1884644],
          [100.6037322, 4.1886836],
          [100.60351, 4.1887358],
          [100.6034188, 4.1888722],
          [100.603518, 4.1890622],
          [100.6034644, 4.1892146],
          [100.60345, 4.1893864],
          [100.6033308, 4.1895031],
          [100.6031398, 4.1896908],
          [100.6030463, 4.1899263],
          [100.6029412, 4.19018],
          [100.6027608, 4.1903244],
          [100.6027037, 4.1905283],
          [100.6027948, 4.190694],
          [100.602877, 4.1907287],
          [100.6028689, 4.1908892],
          [100.6028931, 4.1911781],
          [100.6031211, 4.1914429],
          [100.6031452, 4.1917907],
          [100.6029896, 4.1920716],
          [100.6027055, 4.1924046],
          [100.6026816, 4.1926754],
          [100.6027813, 4.1928836],
          [100.6028475, 4.1929704],
          [100.6027885, 4.1931416],
          [100.6028984, 4.1932941],
          [100.6029011, 4.1934011],
          [100.6028515, 4.1935473],
          [100.6028609, 4.1936579],
          [100.6028716, 4.1937515],
          [100.6028689, 4.1938558],
          [100.6028287, 4.1940217],
          [100.6027911, 4.1941073],
          [100.6028502, 4.1942276],
          [100.6029896, 4.194126],
          [100.6030814, 4.194165],
          [100.6032027, 4.1944199],
          [100.6030732, 4.1945948],
          [100.6031586, 4.1947407],
          [100.603305, 4.1947407],
          [100.603305, 4.1949151],
          [100.6032968, 4.1951016],
          [100.6030326, 4.1950773],
          [100.6029431, 4.1951584],
          [100.6025406, 4.1951665],
          [100.6025813, 4.1956976],
          [100.6028984, 4.195576],
          [100.6030285, 4.1956936],
          [100.6029316, 4.1959808],
          [100.6028008, 4.1963058],
          [100.6026951, 4.1963626],
          [100.6026841, 4.1964372],
          [100.6027805, 4.1965166],
          [100.6028984, 4.1965329],
          [100.6030244, 4.1965856],
          [100.603061, 4.1964072],
          [100.6031911, 4.1964964],
          [100.6033212, 4.1966707],
          [100.6033293, 4.1968816],
          [100.6031958, 4.1971782],
          [100.6031098, 4.1974411],
          [100.6030163, 4.1976398],
          [100.6030285, 4.1978425],
          [100.6031583, 4.1980187],
          [100.6032521, 4.1981587],
          [100.6033903, 4.1982804],
          [100.6034664, 4.1984831],
          [100.6035905, 4.1987271],
          [100.6037847, 4.1987426],
          [100.6039229, 4.1986047],
          [100.6042522, 4.1986372],
          [100.6045896, 4.1988602],
          [100.6048254, 4.1990913],
          [100.6049762, 4.1992346],
          [100.6051575, 4.1994359],
          [100.6052457, 4.1997579],
          [100.6052635, 4.1999552],
          [100.6052645, 4.2000534],
          [100.6053412, 4.2001517],
          [100.6054671, 4.2002384],
          [100.6056101, 4.2002793],
          [100.6057158, 4.2002631],
          [100.6058059, 4.2002341],
          [100.6058852, 4.20026],
          [100.6059721, 4.2003161],
          [100.6060809, 4.2004942],
          [100.6061473, 4.200678],
          [100.6062353, 4.2008276],
          [100.6066755, 4.2006005],
          [100.6070127, 4.2004617],
          [100.607033, 4.2006361],
          [100.6071183, 4.2008388],
          [100.6073745, 4.2010861],
          [100.6074843, 4.2013092],
          [100.60753, 4.201607],
          [100.607366, 4.2017973],
          [100.6072896, 4.2017729],
          [100.6071995, 4.2017976],
          [100.6071347, 4.2018641],
          [100.6071147, 4.2019392],
          [100.6071343, 4.2020063],
          [100.6071935, 4.2020743],
          [100.6072601, 4.2021034],
          [100.6073375, 4.2020865],
          [100.6074124, 4.2020058],
          [100.6074238, 4.2019313],
          [100.6074049, 4.2018571],
          [100.6076253, 4.20165],
          [100.6077701, 4.2017321],
          [100.6079434, 4.2017603],
          [100.6082594, 4.2019659],
          [100.6085403, 4.2022389],
          [100.6087673, 4.2024113],
          [100.6088708, 4.2024014],
          [100.6089397, 4.2023719],
          [100.6092122, 4.2021707],
          [100.609329, 4.202113],
          [100.609588, 4.2024226],
          [100.6097824, 4.2027349],
          [100.6099291, 4.2031732],
          [100.6101051, 4.2036259],
          [100.6101211, 4.2040044],
          [100.6101647, 4.2043045],
          [100.610261, 4.2045553],
          [100.610336, 4.2048611],
          [100.6104872, 4.2052307],
          [100.6106753, 4.2055135],
          [100.6106429, 4.2058357],
          [100.6105543, 4.2061976],
          [100.6104634, 4.2066229],
          [100.6103502, 4.2072459],
          [100.6103766, 4.2076706],
          [100.6103236, 4.2081578],
          [100.6104126, 4.2087424],
          [100.61044, 4.2089313],
          [100.6103979, 4.2091345],
          [100.610358, 4.2093723],
          [100.610379, 4.2096602],
          [100.6103949, 4.210116],
          [100.6104153, 4.2106672],
          [100.6103834, 4.2109869],
          [100.6103608, 4.2111936],
          [100.6102981, 4.2112367],
          [100.6102022, 4.2112633],
          [100.6097802, 4.211175],
          [100.6094176, 4.2110731],
          [100.6090653, 4.2109974],
          [100.6089372, 4.2109964],
          [100.6088539, 4.2110124],
          [100.60882, 4.2110253],
          [100.6087929, 4.2110616],
          [100.6087871, 4.2111218],
          [100.6087445, 4.2115663],
          [100.608753, 4.2116159],
          [100.6087706, 4.211669],
          [100.6088213, 4.2116972],
          [100.6088971, 4.2117064],
          [100.6089596, 4.2117022],
          [100.6090511, 4.2116804],
          [100.60933, 4.2116861],
          [100.6096448, 4.2117386],
          [100.6101977, 4.2117794],
          [100.6100532, 4.2124046],
          [100.6100694, 4.2125727],
          [100.61014, 4.2126778],
          [100.6100224, 4.2131762],
          [100.6098878, 4.2135369],
          [100.6097901, 4.2137609],
          [100.6097386, 4.2139911],
          [100.6096354, 4.214086],
          [100.6094969, 4.2142132],
          [100.6092655, 4.2144721],
          [100.6091329, 4.2148122],
          [100.6090193, 4.215004],
          [100.6087836, 4.215364],
          [100.608557, 4.2156415],
          [100.6082801, 4.2159372],
          [100.6079742, 4.2162676],
          [100.607797, 4.2164781],
          [100.6076636, 4.2166542],
          [100.6075182, 4.2168583],
          [100.6073573, 4.2170562],
          [100.6071511, 4.2171812],
          [100.6067443, 4.2175597],
          [100.606305, 4.2179053],
          [100.6055596, 4.2185599],
          [100.6047849, 4.219371],
          [100.6044197, 4.2196461],
          [100.6042692, 4.2195609],
          [100.6041289, 4.2194631],
          [100.6038913, 4.2195477],
          [100.6034723, 4.2197598],
          [100.6031232, 4.2199852],
          [100.6029017, 4.2202065],
          [100.602684, 4.2204189],
          [100.6024463, 4.2206722],
          [100.602074, 4.2211153],
          [100.601753, 4.2214623],
          [100.6013144, 4.2217044],
          [100.6005872, 4.2220804],
          [100.6000026, 4.2223657],
          [100.5991081, 4.2228469],
          [100.5988853, 4.2228497],
          [100.5986081, 4.2229101],
          [100.5983606, 4.2230807],
          [100.5981841, 4.2232961],
          [100.5976745, 4.2234768],
          [100.5972613, 4.2238489],
          [100.5967937, 4.2242598],
          [100.5965642, 4.2245489],
          [100.5963142, 4.224857],
          [100.5961272, 4.2251119],
          [100.5958889, 4.2254391],
          [100.5956761, 4.225828],
          [100.5955623, 4.2260651],
          [100.5954273, 4.2264627],
          [100.5952057, 4.22671],
          [100.5949699, 4.2270585],
          [100.594711, 4.2274717],
          [100.5946152, 4.2277939],
          [100.5944503, 4.228133],
          [100.5942145, 4.2283877],
          [100.5939468, 4.2286285],
          [100.5936517, 4.2289816],
          [100.5932649, 4.2294898],
          [100.5929996, 4.2299226],
          [100.5927306, 4.2305389],
          [100.5924668, 4.2310466],
          [100.5923611, 4.2315876],
          [100.5923441, 4.2318744],
          [100.5922613, 4.2323083],
          [100.5921714, 4.232732],
          [100.5921936, 4.2331363],
          [100.5922328, 4.2333547],
          [100.592223, 4.2334778],
          [100.5922058, 4.2335998],
          [100.5921805, 4.2337073],
          [100.5920523, 4.2338961],
          [100.5918722, 4.2340464],
          [100.5915328, 4.2342868],
          [100.5915227, 4.2343311],
          [100.591666, 4.2347622],
          [100.5917875, 4.2352257],
          [100.5919565, 4.2354432],
          [100.5921026, 4.2355882],
          [100.592314, 4.2358507],
          [100.5925056, 4.2361581],
          [100.5926334, 4.2364642],
          [100.5927809, 4.236839],
          [100.5929427, 4.2372304],
          [100.5929773, 4.2376572],
          [100.5930158, 4.2382592],
          [100.5929971, 4.238619],
          [100.5929583, 4.2388229],
          [100.5928177, 4.239025],
          [100.5926802, 4.2391349],
          [100.5925631, 4.239185],
          [100.5927681, 4.2393533],
          [100.5925436, 4.2396398],
          [100.5923316, 4.2398312],
          [100.5921271, 4.2400064],
          [100.5919182, 4.240149],
          [100.5917777, 4.2402694],
          [100.5916051, 4.2403967],
          [100.5914233, 4.2406283],
          [100.591326, 4.2408353],
          [100.5912879, 4.2410372],
          [100.5912573, 4.2413499],
          [100.5911879, 4.2416467],
          [100.5910381, 4.2418095],
          [100.5909673, 4.2419201],
          [100.5909717, 4.2420177],
          [100.5910277, 4.2421606],
          [100.5910664, 4.242274],
          [100.5911191, 4.2423874],
          [100.5912596, 4.2424801],
          [100.5914376, 4.2425826],
          [100.5915498, 4.2426273],
          [100.5918395, 4.2425695],
          [100.5920914, 4.2425167],
          [100.5925351, 4.2425075],
          [100.5931421, 4.2425457],
          [100.594186487785379, 4.252514841035008],
          [100.5943998, 4.254551],
          [100.5942664, 4.2545844],
          [100.5941753, 4.2547151],
          [100.5939697, 4.2549221],
          [100.5934879, 4.2556272],
          [100.5932666, 4.2558266],
          [100.5931227, 4.2558085],
          [100.5929296, 4.2559357],
          [100.5924073, 4.2563562],
          [100.5918531, 4.2566107],
          [100.5912602, 4.2567674],
          [100.5908781, 4.2567484],
          [100.5906435, 4.2569353],
          [100.5905097, 4.257336],
          [100.5904054, 4.2577],
          [100.5904513, 4.258268],
          [100.5905014, 4.2588734],
          [100.5905372, 4.2602416],
          [100.5905706, 4.2623704],
          [100.5913477, 4.2661437],
          [100.5918722, 4.2683753],
          [100.5920415, 4.270292],
          [100.5921141, 4.2713432],
          [100.5921939, 4.2727829],
          [100.592127, 4.2734833],
          [100.5919918, 4.2737257],
          [100.5917502, 4.2739517],
          [100.5915691, 4.2741284],
          [100.5910408, 4.2742955],
          [100.590627, 4.2743197],
          [100.5903043, 4.2743117],
          [100.5897038, 4.2742663],
          [100.5893928, 4.2747183],
          [100.5892173, 4.2748131],
          [100.5889783, 4.2747062],
          [100.5886719, 4.2747062],
          [100.5883674, 4.2747754],
          [100.5878429, 4.2749393],
          [100.5873443, 4.2752992],
          [100.5869072, 4.2758452],
          [100.5867582, 4.2763486],
          [100.5864654, 4.2770296],
          [100.5861472, 4.2774099],
          [100.5852955, 4.2779237],
          [100.5849177, 4.2783262],
          [100.5848898, 4.2787556],
          [100.5850221, 4.2791656],
          [100.5851499, 4.2794871],
          [100.5852683, 4.2797275],
          [100.585493, 4.2799248],
          [100.5856867, 4.2800081],
          [100.5861382, 4.2800664],
          [100.5863858, 4.2800406],
          [100.5865828, 4.2801792],
          [100.5867858, 4.2804587],
          [100.5868209, 4.2806769],
          [100.586857, 4.2812112],
          [100.5868393, 4.2829994],
          [100.5868649, 4.2839252],
          [100.5868577, 4.2847328],
          [100.5868628, 4.2855883],
          [100.5868152, 4.2867768],
          [100.5867944, 4.2881455],
          [100.5867367, 4.2888836],
          [100.5866344, 4.2897446],
          [100.5865242, 4.2905045],
          [100.5864449, 4.2913421],
          [100.586183, 4.2928291],
          [100.5860029, 4.293627],
          [100.5858314, 4.2943503],
          [100.5857023, 4.2947742],
          [100.5852641, 4.2953958],
          [100.5842238, 4.2963485],
          [100.5829557, 4.2974546],
          [100.5824906, 4.2976469],
          [100.5821996, 4.2977143],
          [100.581853, 4.2977027],
          [100.5816036, 4.297561],
          [100.581519, 4.2974131],
          [100.5814061, 4.2971253],
          [100.5813342, 4.2969127],
          [100.5813309, 4.2966719],
          [100.5812795, 4.296478],
          [100.5811472, 4.2964479],
          [100.5808108, 4.2965643],
          [100.5805107, 4.2966801],
          [100.5803154, 4.2966467],
          [100.5801193, 4.2965052],
          [100.5798834, 4.2962779],
          [100.5797373, 4.2960656],
          [100.5795638, 4.2958334],
          [100.5791387, 4.2958691],
          [100.5789247, 4.2961128],
          [100.579042, 4.2965282],
          [100.5790849, 4.2968928],
          [100.5792799, 4.297414],
          [100.579383, 4.2978525],
          [100.5793272, 4.2983444],
          [100.5792416, 4.2990996],
          [100.5790044, 4.3002409],
          [100.5787092, 4.3017994],
          [100.578399, 4.3033887],
          [100.5781382, 4.3043617],
          [100.577862, 4.3052328],
          [100.5771469, 4.3072854],
          [100.576654, 4.3081994],
          [100.5761459, 4.3091216],
          [100.5751529, 4.3105127],
          [100.5745704, 4.3110744],
          [100.5735012, 4.3117191],
          [100.5731059, 4.3119072],
          [100.5727209, 4.3120326],
          [100.5726511, 4.3120509],
          [100.5723964, 4.3121179],
          [100.5720245, 4.3121783],
          [100.571605, 4.3121895],
          [100.5712068, 4.3122126],
          [100.5710568, 4.3120571],
          [100.570803, 4.3117747],
          [100.5706731, 4.3114453],
          [100.5705611, 4.3111638],
          [100.5701689, 4.3110395],
          [100.569959, 4.3107076],
          [100.5698147, 4.3103579],
          [100.5697935, 4.3101217],
          [100.5695889, 4.310032],
          [100.5691984, 4.3100662],
          [100.5687317, 4.3103632],
          [100.5676852, 4.3111873],
          [100.5673036, 4.3114912],
          [100.5669134, 4.3117836],
          [100.5665604, 4.3119906],
          [100.5659907, 4.3124666],
          [100.5656518, 4.3126292],
          [100.5650911, 4.3127581],
          [100.5644979, 4.312836],
          [100.5640577, 4.3128003],
          [100.5636765, 4.3127232],
          [100.5634533, 4.31249],
          [100.563319, 4.312127],
          [100.5632163, 4.311664],
          [100.5629023, 4.3112144],
          [100.5623644, 4.3108233],
          [100.5618277, 4.3105974],
          [100.5617235, 4.3104537],
          [100.5617264, 4.3103318],
          [100.5616647, 4.3102309],
          [100.5612932, 4.3101543],
          [100.5611648, 4.3100573],
          [100.561085, 4.3098294],
          [100.5610749, 4.3086954],
          [100.5609621, 4.308662],
          [100.5605375, 4.3089189],
          [100.5602311, 4.3089902],
          [100.5597606, 4.3089976],
          [100.5595665, 4.3089613],
          [100.5594685, 4.3088889],
          [100.5594237, 4.3085683],
          [100.5593263, 4.3084438],
          [100.5591398, 4.3083579],
          [100.5589369, 4.3083755],
          [100.5588373, 4.3085073],
          [100.5587531, 4.3087324],
          [100.5585747, 4.3087899],
          [100.5582524, 4.3087837],
          [100.5580796, 4.3089215],
          [100.5580949, 4.3091423],
          [100.5582293, 4.309323],
          [100.5584826, 4.3095314],
          [100.5586557, 4.3098778],
          [100.5586934, 4.3102781],
          [100.5586313, 4.3107049],
          [100.5582858, 4.3116322],
          [100.5582076, 4.3119765],
          [100.5582638, 4.3123877],
          [100.5581481, 4.3127093],
          [100.558348, 4.313379],
          [100.5585933, 4.3136361],
          [100.558922, 4.3138303],
          [100.5590681, 4.3143132],
          [100.5590371, 4.3148205],
          [100.5589563, 4.3151799],
          [100.5590374, 4.3155072],
          [100.5592234, 4.3157422],
          [100.5596098, 4.3159809],
          [100.5596733, 4.3161337],
          [100.5595193, 4.3164261],
          [100.5594721, 4.3169386],
          [100.5595334, 4.3172865],
          [100.5597657, 4.3176974],
          [100.559798, 4.3179831],
          [100.5596906, 4.3182529],
          [100.5593235, 4.3186133],
          [100.5591055, 4.3189274],
          [100.5592272, 4.319591],
          [100.5594508, 4.319998],
          [100.5598608, 4.3204181],
          [100.5602883, 4.3207861],
          [100.5609494, 4.3212277],
          [100.5616995, 4.3219021],
          [100.562329, 4.3226619],
          [100.5631665, 4.3234926],
          [100.5637248, 4.3242711],
          [100.5640384, 4.3250358],
          [100.5641776, 4.3259602],
          [100.564058, 4.3265522],
          [100.5640716, 4.3270587],
          [100.5643611, 4.3276216],
          [100.5648638, 4.3281346],
          [100.5651976, 4.3288186],
          [100.565474, 4.3295057],
          [100.5657543, 4.3298575],
          [100.5661698, 4.3299238],
          [100.5666164, 4.3301738],
          [100.5677917, 4.3328515],
          [100.5693415, 4.3365755],
          [100.5705965, 4.3397975],
          [100.5719652, 4.3433179],
          [100.5733041, 4.3467319],
          [100.5740458, 4.3485584],
          [100.5747427, 4.3501849],
          [100.5757917, 4.3524228],
          [100.5766575, 4.3543348],
          [100.5779024, 4.3568296],
          [100.5792174, 4.359397],
          [100.5799136, 4.3608797],
          [100.5811606, 4.3633082],
          [100.5824749, 4.3660198],
          [100.5833093, 4.3680359],
          [100.5844657, 4.370977],
          [100.5853362, 4.373537],
          [100.5856862, 4.3748723],
          [100.5858713, 4.3761966],
          [100.5857216, 4.3767946],
          [100.5851343, 4.377334],
          [100.5845858, 4.3778137],
          [100.5839959, 4.3783374],
          [100.5836797, 4.3790558],
          [100.5838827, 4.3800778],
          [100.584616, 4.3825571],
          [100.5856761, 4.3857216],
          [100.5863245, 4.3876199],
          [100.5869075, 4.3890754],
          [100.5869487, 4.3901853],
          [100.587117, 4.391318],
          [100.5872401, 4.3924345],
          [100.5873139, 4.3938833],
          [100.5874391, 4.3945382],
          [100.5874523, 4.3950856],
          [100.5875408, 4.3953741],
          [100.5875348, 4.3957304],
          [100.5874253, 4.3960256],
          [100.5872154, 4.3962641],
          [100.5870477, 4.3963789],
          [100.586889, 4.3964568],
          [100.5867063, 4.3966692],
          [100.586617, 4.3968093],
          [100.5865332, 4.3970248],
          [100.5865589, 4.3972477],
          [100.5866771, 4.3974026],
          [100.5868852, 4.3975242],
          [100.5874078, 4.3980042],
          [100.588084, 4.398418],
          [100.5887922, 4.3978685],
          [100.5893789, 4.3980186],
          [100.5900347, 4.3982063],
          [100.590315045670565, 4.398447772918335],
          [100.5904272, 4.3985087],
          [100.5906068, 4.3984641],
          [100.5907523, 4.3984378],
          [100.5908468, 4.3982367],
          [100.5911603, 4.3981954],
          [100.5912925, 4.3982319],
          [100.591436, 4.3981613],
          [100.5915651, 4.3979127],
          [100.5917097, 4.3977782],
          [100.5923158, 4.3975323],
          [100.5927217, 4.397345],
          [100.5932337, 4.3974364],
          [100.5936983, 4.397513],
          [100.5939903, 4.3976729],
          [100.5942472, 4.3978555],
          [100.5944268, 4.3981575],
          [100.5945425, 4.3984186],
          [100.5946599, 4.3988014],
          [100.5946758, 4.3992068],
          [100.5945854, 4.3995533],
          [100.5944269, 4.3997538],
          [100.5941291, 4.3999016],
          [100.5936805, 4.3999701],
          [100.593346, 4.4001428],
          [100.592965, 4.4003411],
          [100.592802, 4.4005482],
          [100.5927499, 4.4008342],
          [100.5927247, 4.4010765],
          [100.5925966, 4.401312],
          [100.5923245, 4.4014629],
          [100.592005, 4.4015166],
          [100.5916415, 4.4014914],
          [100.5912136, 4.4014017],
          [100.5905157, 4.4015493],
          [100.590480941799257, 4.401567490490542],
          [100.5904324, 4.4017186],
          [100.5903148, 4.4018317],
          [100.5902278, 4.4020405],
          [100.5901107, 4.4021675],
          [100.5899542, 4.4021817],
          [100.5895215, 4.4030458],
          [100.5893291, 4.4036042],
          [100.5890113, 4.4040705],
          [100.5888801, 4.4042629],
          [100.5887336, 4.4043968],
          [100.5885823, 4.4043224],
          [100.5884084, 4.4043152],
          [100.588259270698629, 4.404314276766889],
          [100.5882582, 4.4043473],
          [100.5883088, 4.4046346],
          [100.5881765, 4.404922],
          [100.5879321, 4.4051293],
          [100.5876828, 4.4055651],
          [100.5876387, 4.4059225],
          [100.5877708, 4.4062917],
          [100.5879552, 4.4066087],
          [100.5880401, 4.4068525],
          [100.5879167, 4.4071297],
          [100.5876369, 4.407239],
          [100.5873626, 4.4073284],
          [100.5870888, 4.4075762],
          [100.5869851, 4.4077864],
          [100.5869105, 4.4080892],
          [100.5869599, 4.4084528],
          [100.5870897, 4.4085951],
          [100.5873071, 4.4086126],
          [100.5875241, 4.4086155],
          [100.5875736, 4.4088697],
          [100.5874288, 4.4090948],
          [100.5871583, 4.4091557],
          [100.5869592, 4.409277],
          [100.5869122, 4.4095638],
          [100.5870047, 4.4097432],
          [100.5872665, 4.4098338],
          [100.5874915, 4.4100132],
          [100.5876228, 4.4101879],
          [100.5876327, 4.4104136],
          [100.5874454, 4.4106265],
          [100.5873248, 4.4107957],
          [100.587175, 4.41102],
          [100.587099, 4.4114263],
          [100.5872894, 4.4118751],
          [100.5872894, 4.4123894],
          [100.587174, 4.4128582],
          [100.5869003, 4.413167],
          [100.5865619, 4.4133368],
          [100.5861746, 4.4133537],
          [100.5856841, 4.4133112],
          [100.5854033, 4.4133999],
          [100.5851792, 4.4136866],
          [100.5850325, 4.4139629],
          [100.5848541, 4.4146387],
          [100.5845679, 4.4150897],
          [100.5841559, 4.4154278],
          [100.5835937, 4.415754],
          [100.58304, 4.4161063],
          [100.5826617, 4.4166768],
          [100.5826141, 4.4172416],
          [100.5827777, 4.4177074],
          [100.5831461, 4.4181304],
          [100.5835196, 4.4184709],
          [100.5840881, 4.4188398],
          [100.5846551, 4.4191306],
          [100.5850438, 4.4194598],
          [100.5852093, 4.4199069],
          [100.5853792, 4.420336],
          [100.5856592, 4.4207684],
          [100.5859506, 4.4212168],
          [100.5858419, 4.4215213],
          [100.5854478, 4.4216919],
          [100.5851815, 4.4220722],
          [100.585099, 4.4224533],
          [100.5851238, 4.4226998],
          [100.5853667, 4.4229037],
          [100.5853983, 4.4231527],
          [100.585336, 4.4233971],
          [100.5853543, 4.4238079],
          [100.5854896, 4.424211],
          [100.5856718, 4.424495],
          [100.5858801, 4.4249512],
          [100.5862218, 4.4254657],
          [100.5866542, 4.4258665],
          [100.5872141, 4.4261099],
          [100.5877546, 4.4262428],
          [100.5887481, 4.4268263],
          [100.5894748, 4.4274288],
          [100.5903535, 4.4281739],
          [100.5905641, 4.4287396],
          [100.590856, 4.4290861],
          [100.5911516, 4.4292555],
          [100.5914659, 4.4292783],
          [100.591632, 4.4294254],
          [100.591737, 4.4296879],
          [100.5920164, 4.4299163],
          [100.5923731, 4.429953],
          [100.5926625, 4.4299512],
          [100.5929323, 4.4298625],
          [100.5931503, 4.4297056],
          [100.5932507, 4.4295013],
          [100.5933002, 4.4293008],
          [100.5932388, 4.4290984],
          [100.5930477, 4.4289314],
          [100.5929339, 4.4287644],
          [100.5929926, 4.4285225],
          [100.5931619, 4.4284304],
          [100.5934318, 4.428429],
          [100.5937381, 4.4284034],
          [100.593916, 4.4283002],
          [100.5940113, 4.4280757],
          [100.59395, 4.4278029],
          [100.5937915, 4.4275436],
          [100.5936831, 4.4271748],
          [100.5937263, 4.4267676],
          [100.5939539, 4.4264897],
          [100.5942777, 4.426329],
          [100.5943033, 4.4259931],
          [100.5940519, 4.4255896],
          [100.5936845, 4.4249444],
          [100.5935382, 4.4243638],
          [100.593715, 4.4237381],
          [100.593995, 4.4232838],
          [100.5944037, 4.4229412],
          [100.5947926, 4.4226783],
          [100.595326, 4.422658],
          [100.5957448, 4.4226962],
          [100.5962331, 4.422354],
          [100.5968197, 4.4220411],
          [100.5973962, 4.4219729],
          [100.598065, 4.422008],
          [100.5986836, 4.4220476],
          [100.599305, 4.4221265],
          [100.5997065, 4.4219423],
          [100.6002482, 4.4217792],
          [100.6008203, 4.4219401],
          [100.6015345, 4.4222628],
          [100.6034447, 4.4233572],
          [100.6044096, 4.4234124],
          [100.6062369, 4.4244022],
          [100.6064664, 4.4243888],
          [100.6068848, 4.4243643],
          [100.6077621, 4.4255134],
          [100.6090841, 4.4279147],
          [100.6108031, 4.4321256],
          [100.6108366, 4.4322269],
          [100.6109077, 4.4324421],
          [100.6109699, 4.433572],
          [100.6109919, 4.4339708],
          [100.6109825, 4.4357096],
          [100.6110084, 4.4360593],
          [100.6110492, 4.4366103],
          [100.6121238, 4.443072],
          [100.6140131, 4.4438069],
          [100.6142502, 4.4435342],
          [100.6146022, 4.443701],
          [100.6147277, 4.4438124],
          [100.6153796, 4.4442484],
          [100.6156248, 4.444722],
          [100.6158432, 4.4448436],
          [100.6160706, 4.4447166],
          [100.6164293, 4.444947],
          [100.6175936, 4.446285],
          [100.6179585, 4.4467043],
          [100.6175568, 4.447174],
          [100.6170496, 4.4476616],
          [100.6165657, 4.4482617],
          [100.6161658, 4.449033],
          [100.6166891, 4.4494479],
          [100.6177094, 4.4534077],
          [100.6181089, 4.4561852],
          [100.6184319, 4.4596695],
          [100.6184696, 4.4630789],
          [100.6184456, 4.4642382],
          [100.6186525, 4.4662732],
          [100.6188482, 4.4753245],
          [100.6186918, 4.4895851],
          [100.6184524, 4.4975712],
          [100.6185132, 4.5021567],
          [100.6185132, 4.50869],
          [100.6185741, 4.5108002],
          [100.6190421, 4.5148037],
          [100.6193857, 4.5165219],
          [100.619893, 4.5181451],
          [100.6204408, 4.5194843],
          [100.6212727, 4.5218176],
          [100.6221857, 4.5241306],
          [100.6229973, 4.5260379],
          [100.6236263, 4.5277422],
          [100.6240981, 4.5294669],
          [100.6244759, 4.5305267],
          [100.6253941, 4.531918],
          [100.6257567, 4.5323683],
          [100.6261505, 4.5326966],
          [100.6263154, 4.5330187],
          [100.6263856, 4.5334307],
          [100.6265502, 4.533779],
          [100.6275667, 4.535536],
          [100.6280884, 4.536154],
          [100.6284756, 4.5364985],
          [100.6289017, 4.5366495],
          [100.629364, 4.53653],
          [100.6297073, 4.5362488],
          [100.6298587, 4.53585],
          [100.6298312, 4.5354358],
          [100.630182, 4.5350145],
          [100.6304297, 4.5344932],
          [100.6304345, 4.5341965],
          [100.6303717, 4.5339754],
          [100.6310113, 4.5334322],
          [100.6312769, 4.5336195],
          [100.6315714, 4.533581],
          [100.6317466, 4.5333367],
          [100.6325282, 4.5327543],
          [100.632747, 4.5323418],
          [100.6327299, 4.5315076],
          [100.6326955, 4.5311058],
          [100.6326266, 4.5308418],
          [100.6331547, 4.530704],
          [100.633281, 4.5309107],
          [100.6334532, 4.5310714],
          [100.6338206, 4.530991],
          [100.6339583, 4.5308647],
          [100.6343828, 4.5313296],
          [100.6340401, 4.5316995],
          [100.634025, 4.5318731],
          [100.6341238, 4.5320446],
          [100.63411, 4.53224],
          [100.6340287, 4.5324837],
          [100.634107, 4.5331803],
          [100.6345429, 4.5337244],
          [100.6348986, 4.5342656],
          [100.6350112, 4.5350523],
          [100.6351438, 4.5353554],
          [100.6354282, 4.5355559],
          [100.6367804, 4.5360945],
          [100.637505, 4.536443],
          [100.6381539, 4.5368512],
          [100.6397204, 4.5376929],
          [100.6401188, 4.5380306],
          [100.6404967, 4.5381459],
          [100.6413428, 4.5379468],
          [100.6417324, 4.5381035],
          [100.6421532, 4.5381896],
          [100.6426365, 4.5381128],
          [100.6429785, 4.5379703],
          [100.6432257, 4.5381572],
          [100.6434167, 4.5386399],
          [100.6436037, 4.539902],
          [100.6438581, 4.5404354],
          [100.6442746, 4.5406782],
          [100.6446706, 4.5415502],
          [100.6449558, 4.5419123],
          [100.6453946, 4.5420392],
          [100.6459454, 4.5426305],
          [100.6463572, 4.5427316],
          [100.6467377, 4.5426316],
          [100.6470058, 4.5429514],
          [100.6469612, 4.5433484],
          [100.6470185, 4.5436576],
          [100.6471812, 4.543865],
          [100.6474336, 4.5440839],
          [100.6475495, 4.544429],
          [100.6476054, 4.5449776],
          [100.6477606, 4.5454513],
          [100.6480809, 4.545775],
          [100.6481882, 4.5461682],
          [100.6482752, 4.5467536],
          [100.6484374, 4.5473068],
          [100.6486688, 4.5476953],
          [100.6488879, 4.5485241],
          [100.6497529, 4.5505745],
          [100.6503681, 4.5509831],
          [100.65049573271871, 4.551175906322896],
          [100.6508618, 4.5517289],
          [100.6516136, 4.5541795],
          [100.6519357, 4.5554711],
          [100.6526554, 4.5575896],
          [100.6535249, 4.5589707],
          [100.6549653, 4.5608186],
          [100.6560021, 4.5622816],
          [100.6568537, 4.5635862],
          [100.6574561, 4.5648405],
          [100.6553846, 4.5659697],
          [100.6533891, 4.5671971],
          [100.6528804, 4.5666249],
          [100.6522234, 4.566249],
          [100.6513333, 4.5659707],
          [100.6502524, 4.5659043],
          [100.6484189, 4.5659909],
          [100.6466127, 4.565845],
          [100.6439002, 4.5650856],
          [100.6428172, 4.5650548],
          [100.6422875, 4.5649449],
          [100.6417096, 4.5649834],
          [100.6414449, 4.5651725],
          [100.641272, 4.5653745],
          [100.6410119, 4.5653685],
          [100.6406689, 4.5651896],
          [100.6404058, 4.5649727],
          [100.6400628, 4.5647823],
          [100.6396556, 4.5646539],
          [100.6391881, 4.564648],
          [100.6388503, 4.5645479],
          [100.638278, 4.5641453],
          [100.6365825, 4.5633399],
          [100.6359679, 4.5631492],
          [100.63519, 4.5631505],
          [100.6345917, 4.5629472],
          [100.6340231, 4.5625047],
          [100.6328948, 4.5614325],
          [100.6323775, 4.5612169],
          [100.6317807, 4.5611216],
          [100.6307696, 4.5605709],
          [100.629637, 4.5603279],
          [100.6281933, 4.5598797],
          [100.6279884, 4.5599428],
          [100.6278287, 4.5601468],
          [100.627811, 4.5605993],
          [100.6277311, 4.5607945],
          [100.6276158, 4.5608122],
          [100.6275182, 4.5607501],
          [100.6274561, 4.5605727],
          [100.6273668, 4.5598083],
          [100.6271863, 4.5593949],
          [100.6269335, 4.5589689],
          [100.6258671, 4.5579191],
          [100.6251903, 4.557009],
          [100.6248912, 4.5568816],
          [100.6244302, 4.5568567],
          [100.6239737, 4.556654],
          [100.6235358, 4.5563412],
          [100.6230944, 4.5556054],
          [100.6228823, 4.5554494],
          [100.6225889, 4.5554405],
          [100.622232, 4.5556165],
          [100.620861, 4.5549303],
          [100.6198825, 4.5546936],
          [100.6190958, 4.5546173],
          [100.6178525, 4.5546866],
          [100.6173911, 4.5546571],
          [100.6172015, 4.554724],
          [100.6168971, 4.5551672],
          [100.6163241, 4.5552368],
          [100.6160387, 4.5554329],
          [100.6157357, 4.5554273],
          [100.6154219, 4.555508],
          [100.6152433, 4.5556601],
          [100.6150682, 4.5558915],
          [100.6145344, 4.5562351],
          [100.6139054, 4.5563858],
          [100.6132315, 4.5567513],
          [100.6127569, 4.5568706],
          [100.6123795, 4.5570205],
          [100.6119668, 4.5572595],
          [100.6117801, 4.5575078],
          [100.6116226, 4.5581096],
          [100.6114752, 4.5582217],
          [100.6106206, 4.5583376],
          [100.6101772, 4.558507],
          [100.609982, 4.558644],
          [100.6099462, 4.558856],
          [100.6098181, 4.5590323],
          [100.6095569, 4.5590912],
          [100.6092747, 4.5590793],
          [100.6087937, 4.5591057],
          [100.6084003, 4.5591449],
          [100.6081891, 4.5593752],
          [100.6079029, 4.55958],
          [100.6073541, 4.5597635],
          [100.6068194, 4.559893],
          [100.6063864, 4.5601674],
          [100.6058882, 4.5603973],
          [100.6052545, 4.5604023],
          [100.6046597, 4.5605809],
          [100.6043219, 4.5609007],
          [100.6039016, 4.5609649],
          [100.6034219, 4.5609899],
          [100.603216, 4.5610649],
          [100.6030642, 4.5612104],
          [100.6030329, 4.5614993],
          [100.6029716, 4.5617886],
          [100.6028378, 4.5619941],
          [100.6025937, 4.561997],
          [100.6024699, 4.5618564],
          [100.6021586, 4.5618389],
          [100.6018751, 4.5618795],
          [100.6014904, 4.5619551],
          [100.6011058, 4.562199],
          [100.6008226, 4.5624399],
          [100.600505, 4.5626103],
          [100.599984, 4.5626641],
          [100.599521, 4.5627152],
          [100.5990993, 4.56286],
          [100.5988494, 4.562959],
          [100.5985858, 4.5629811],
          [100.5982568, 4.5629938],
          [100.597863, 4.563004],
          [100.5976576, 4.5630913],
          [100.5975238, 4.5632811],
          [100.5975364, 4.5634856],
          [100.5976576, 4.5637494],
          [100.5978457, 4.5638608],
          [100.5979302, 4.5639683],
          [100.597972, 4.5641582],
          [100.5979675, 4.5643559],
          [100.597928, 4.5645042],
          [100.5977676, 4.5646205],
          [100.5974347, 4.5645871],
          [100.5972607, 4.5643898],
          [100.5969664, 4.5642943],
          [100.5966263, 4.5641655],
          [100.5962434, 4.5640727],
          [100.5959726, 4.564181],
          [100.5957019, 4.5645871],
          [100.5954195, 4.5648462],
          [100.5951531, 4.5648388],
          [100.5948261, 4.5647735],
          [100.59449, 4.5648515],
          [100.5941004, 4.5650963],
          [100.5938115, 4.5652134],
          [100.5934555, 4.5652422],
          [100.5931175, 4.5652592],
          [100.5927704, 4.5654088],
          [100.5925606, 4.5655414],
          [100.5924276, 4.5657811],
          [100.5922255, 4.5659268],
          [100.591977, 4.5660182],
          [100.5916508, 4.5661668],
          [100.5914007, 4.566347],
          [100.5911312, 4.5665204],
          [100.5909157, 4.566716],
          [100.5908229, 4.5669111],
          [100.5908104, 4.5671616],
          [100.5909226, 4.5674826],
          [100.5904069, 4.5681218],
          [100.5900095, 4.5678961],
          [100.5895805, 4.5677379],
          [100.5890865, 4.5675383],
          [100.5884065, 4.5673938],
          [100.5877867, 4.5672884],
          [100.5869624, 4.5670327],
          [100.5866149, 4.5669662],
          [100.5861093, 4.5671413],
          [100.5859219, 4.5669576],
          [100.5855537, 4.5668003],
          [100.5850787, 4.5667117],
          [100.5847367, 4.5669048],
          [100.5844484, 4.5671623],
          [100.5847576, 4.5681915],
          [100.5839877, 4.5688812],
          [100.5847383, 4.5700809],
          [100.5851944, 4.5710665],
          [100.5862919, 4.571322],
          [100.5861461, 4.5743517],
          [100.5860289, 4.5779703],
          [100.5864364, 4.5785681],
          [100.5871944, 4.5788999],
          [100.5876622, 4.5792281],
          [100.5875927, 4.5795715],
          [100.5872079, 4.579826],
          [100.5873303, 4.5802814],
          [100.5875877, 4.5803611],
          [100.5878053, 4.5806235],
          [100.5877946, 4.5810623],
          [100.5876135, 4.5815384],
          [100.5882076, 4.5822449],
          [100.5883734, 4.5831468],
          [100.5883805, 4.5897425],
          [100.5884278, 4.5905363],
          [100.5885169, 4.5913146],
          [100.5886887, 4.5922492],
          [100.5890216, 4.5924889],
          [100.5885202, 4.5942388],
          [100.5883871, 4.5955261],
          [100.5881534, 4.596128],
          [100.5881231, 4.5965487],
          [100.5879523, 4.5972492],
          [100.5878925, 4.597959],
          [100.5881597, 4.5990032],
          [100.5880988, 4.5996277],
          [100.5874902, 4.6000632],
          [100.5869986, 4.6004901],
          [100.5870141, 4.6008882],
          [100.5870858, 4.6013518],
          [100.5873211, 4.6018056],
          [100.5872941, 4.6022732],
          [100.586927, 4.6025683],
          [100.5871314, 4.6028144],
          [100.587865, 4.6037032],
          [100.5894709, 4.605242],
          [100.5896963, 4.605265],
          [100.5900872, 4.6057197],
          [100.5904683, 4.6059206],
          [100.5906572, 4.6062107],
          [100.5906908, 4.6067884],
          [100.590708, 4.6083605],
          [100.590573, 4.6095777],
          [100.59054, 4.6100739],
          [100.5906483, 4.6111652],
          [100.590683, 4.6119961],
          [100.5910031, 4.6125421],
          [100.5911636, 4.6134686],
          [100.5913194, 4.6138751],
          [100.5915429, 4.6141004],
          [100.5916838, 4.6146423],
          [100.5918061, 4.6147364],
          [100.591811, 4.6148998],
          [100.591728, 4.6152246],
          [100.5918688, 4.6164619],
          [100.5922034, 4.616877],
          [100.5926304, 4.6175931],
          [100.5932582, 4.6180637],
          [100.5936645, 4.6184456],
          [100.594326, 4.6187649],
          [100.5951691, 4.6190163],
          [100.5955055, 4.6193947],
          [100.5953745, 4.6198192],
          [100.5946033, 4.6208528],
          [100.5950403, 4.6215817],
          [100.5952966, 4.6220926],
          [100.5952588, 4.6224099],
          [100.5954112, 4.6227776],
          [100.5956108, 4.6232691],
          [100.5959723, 4.6238752],
          [100.5964607, 4.6238992],
          [100.5967096, 4.6242204],
          [100.5967228, 4.6248455],
          [100.5965659, 4.6254705],
          [100.5969993, 4.6259944],
          [100.5973619, 4.6264952],
          [100.5973663, 4.6269031],
          [100.5979066, 4.6279833],
          [100.5987975, 4.6286725],
          [100.5985959, 4.629128],
          [100.598504, 4.6296997],
          [100.5984848, 4.6303061],
          [100.598598, 4.6305542],
          [100.5990217, 4.6306791],
          [100.5992443, 4.6316016],
          [100.6002142, 4.6320142],
          [100.6020582, 4.6360742],
          [100.6025431, 4.6358824],
          [100.6029144, 4.6367564],
          [100.6036195, 4.6369499],
          [100.604114, 4.6379668],
          [100.6038926, 4.6378528],
          [100.6039012, 4.6381839],
          [100.6041204, 4.6387198],
          [100.6044136, 4.6390763],
          [100.6051445, 4.6391651],
          [100.6059272, 4.6410213],
          [100.6074467, 4.6421802],
          [100.6080138, 4.6426756],
          [100.6090651, 4.6435857],
          [100.6111321, 4.644406],
          [100.6125273, 4.6454003],
          [100.6137057, 4.6462062],
          [100.6137237, 4.6465414],
          [100.613902, 4.6466744],
          [100.6143695, 4.64634],
          [100.6146802, 4.6468096],
          [100.6147241, 4.647128],
          [100.6147517, 4.6474161],
          [100.6153007, 4.6479152],
          [100.6164912, 4.6475774],
          [100.6167025, 4.647413],
          [100.6167441, 4.6465868],
          [100.6176703, 4.6466927],
          [100.6188166, 4.6462902],
          [100.6211464, 4.6454684],
          [100.6225591, 4.6452535],
          [100.6231102, 4.6464174],
          [100.6233748, 4.6470774],
          [100.6230949, 4.6474502],
          [100.622964, 4.6479792],
          [100.6230113, 4.6484282],
          [100.623173, 4.6487627],
          [100.623524, 4.6490554],
          [100.6239093, 4.6490899],
          [100.6243475, 4.6491935],
          [100.6246267, 4.6494943],
          [100.6248164, 4.6498661],
          [100.6252041, 4.6502733],
          [100.6259181, 4.650697],
          [100.626901, 4.6514845],
          [100.6279033, 4.652304],
          [100.6286265, 4.6529162],
          [100.6292093, 4.6532461],
          [100.6299567, 4.6534005],
          [100.6307281, 4.6534919],
          [100.6317791, 4.6536274],
          [100.6327909, 4.6537055],
          [100.6340046, 4.653763],
          [100.6351075, 4.65379],
          [100.6352108, 4.6542661],
          [100.6352972, 4.654776],
          [100.6349323, 4.6551375],
          [100.6347408, 4.6555205],
          [100.6346575, 4.6559471],
          [100.6346798, 4.6563852],
          [100.6348295, 4.6568095],
          [100.6350482, 4.6570644],
          [100.6354156, 4.6572084],
          [100.6359268, 4.6577765],
          [100.6363002, 4.6585324],
          [100.6368157, 4.6591615],
          [100.6373, 4.659778],
          [100.6379992, 4.6608738],
          [100.6384867, 4.6618248],
          [100.6394555, 4.6631446],
          [100.6412385, 4.6645907],
          [100.6429752, 4.6655315],
          [100.6445147, 4.6659339],
          [100.6458532, 4.6661632],
          [100.6470981, 4.6660977],
          [100.6483594, 4.6658939],
          [100.6486492, 4.6660164],
          [100.6489685, 4.666268],
          [100.6488191, 4.6671223],
          [100.6490166, 4.6678698],
          [100.6493631, 4.6684191],
          [100.6500627, 4.6690128],
          [100.6519841, 4.6701711],
          [100.6538302, 4.6711799],
          [100.655622, 4.6720165],
          [100.6575251, 4.6730729],
          [100.65691, 4.6739714],
          [100.6563427, 4.6748001],
          [100.6558955, 4.6742818],
          [100.6553015, 4.6741194],
          [100.6547094, 4.6741596],
          [100.6540877, 4.6742671],
          [100.6532939, 4.6745008],
          [100.6527037, 4.6748954],
          [100.652019, 4.6755958],
          [100.6518213, 4.6762622],
          [100.651799, 4.6768925],
          [100.6518724, 4.6776186],
          [100.6519914, 4.6781622],
          [100.6522155, 4.6786917],
          [100.6523846, 4.6791128],
          [100.652877, 4.6795947],
          [100.6534731, 4.6797719],
          [100.6539556, 4.6798369],
          [100.6542973, 4.6801382],
          [100.6546167, 4.6804521],
          [100.6552398, 4.6807042],
          [100.6559061, 4.681037],
          [100.65524, 4.6824045],
          [100.6546063, 4.6837057],
          [100.653762, 4.683319],
          [100.6526846, 4.6831319],
          [100.6518417, 4.6830512],
          [100.6509626, 4.683065],
          [100.650293, 4.6830873],
          [100.6497802, 4.6831798],
          [100.6492885, 4.6833775],
          [100.649046, 4.6836285],
          [100.6489229, 4.6839565],
          [100.6489745, 4.6842945],
          [100.6486116, 4.6845365],
          [100.6482796, 4.6847579],
          [100.6480696, 4.6844196],
          [100.6477761, 4.6840482],
          [100.6474564, 4.6837911],
          [100.6471006, 4.6836954],
          [100.6467095, 4.6835108],
          [100.6464194, 4.6831117],
          [100.6459305, 4.6826533],
          [100.6455162, 4.6821498],
          [100.6450678, 4.6815116],
          [100.6444438, 4.6807978],
          [100.643993, 4.6803205],
          [100.64382, 4.6799182],
          [100.6436469, 4.679466],
          [100.6432531, 4.6789598],
          [100.6429376, 4.6786781],
          [100.6424359, 4.6782659],
          [100.6419225, 4.6775962],
          [100.6414538, 4.6770159],
          [100.6408957, 4.6766141],
          [100.6404288, 4.6765885],
          [100.6400101, 4.6763772],
          [100.639771, 4.6760515],
          [100.6394672, 4.6757436],
          [100.6391406, 4.675422],
          [100.6387529, 4.6752748],
          [100.6379716, 4.6752079],
          [100.6377484, 4.6750293],
          [100.6374154, 4.6749013],
          [100.6371011, 4.6750293],
          [100.6367015, 4.675393],
          [100.6364427, 4.6754875],
          [100.6359194, 4.6755094],
          [100.6352248, 4.6755698],
          [100.6341778, 4.6757676],
          [100.6331305, 4.6760928],
          [100.632165, 4.676383],
          [100.6312818, 4.6766898],
          [100.6296229, 4.6777931],
          [100.6265725, 4.6781859],
          [100.6229947, 4.6786467],
          [100.622135, 4.6786498],
          [100.621647, 4.67845],
          [100.6215688, 4.6780285],
          [100.6212335, 4.6779072],
          [100.6210891, 4.6781039],
          [100.6202304, 4.6774704],
          [100.6198851, 4.6772728],
          [100.6193294, 4.6772628],
          [100.619011, 4.6770036],
          [100.61859, 4.6769242],
          [100.6178405, 4.6771803],
          [100.6170922, 4.6771723],
          [100.6164237, 4.6768783],
          [100.6152946, 4.6767255],
          [100.6138756, 4.6764589],
          [100.6127174, 4.6760961],
          [100.6114967, 4.6757136],
          [100.6107239, 4.6755275],
          [100.6100074, 4.6748178],
          [100.6093965, 4.6748011],
          [100.6087169, 4.6743902],
          [100.6077386, 4.674316],
          [100.6065166, 4.6740902],
          [100.6060477, 4.6737786],
          [100.6050918, 4.673466],
          [100.6048591, 4.673188],
          [100.6045767, 4.6728507],
          [100.6035465, 4.6724771],
          [100.6017937, 4.6713713],
          [100.5987831, 4.6705479],
          [100.5962715, 4.6701198],
          [100.5963567, 4.67072],
          [100.5959214, 4.6708161],
          [100.5958945, 4.6714946],
          [100.5964209, 4.6724557],
          [100.5971627, 4.6731906],
          [100.5962444, 4.6752746],
          [100.5948259, 4.6762185],
          [100.5944014, 4.6771824],
          [100.594893, 4.6775194],
          [100.5942915, 4.6782432],
          [100.5935812, 4.6806503],
          [100.5927752, 4.6817256],
          [100.5909301, 4.6855432],
          [100.5888386, 4.6901228],
          [100.5880683, 4.6909557],
          [100.5875075, 4.695206],
          [100.5872226, 4.696148],
          [100.5864083, 4.6965505],
          [100.5866843, 4.6974804],
          [100.5854033, 4.6989352],
          [100.5853662, 4.7008842],
          [100.5856904, 4.7013589],
          [100.5855782, 4.7017084],
          [100.5853192, 4.7025155],
          [100.5851411, 4.7038195],
          [100.58506, 4.7046047],
          [100.5849306, 4.7058581],
          [100.5850247, 4.7073506],
          [100.5849036, 4.7076576],
          [100.584332, 4.7078286],
          [100.5842207, 4.7085614],
          [100.5838966, 4.7090185],
          [100.583867, 4.7106594],
          [100.5832187, 4.7120388],
          [100.5834737, 4.7125804],
          [100.5821422, 4.7157147],
          [100.5824763, 4.7171361],
          [100.5820333, 4.71836],
          [100.5814591, 4.7190185],
          [100.5815234, 4.7196781],
          [100.5819242, 4.7205298],
          [100.5812367, 4.7252187],
          [100.5804918, 4.7302991],
          [100.5781959, 4.7377073],
          [100.5778956, 4.7403033],
          [100.5768765, 4.7439546],
          [100.5768848, 4.7446812],
          [100.5669587, 4.7701082],
          [100.5591684, 4.7903176],
          [100.5586307, 4.7915929],
          [100.5586079, 4.7926314],
          [100.5591562, 4.7934457],
          [100.5577581, 4.7938117],
          [100.5573773, 4.794307],
          [100.562263, 4.7969237],
          [100.5610707, 4.7988506],
          [100.5594769, 4.8018933],
          [100.5584337, 4.8041826],
          [100.5571931, 4.8069593],
          [100.5561155, 4.8099203],
          [100.5555359, 4.8122096],
          [100.5556228, 4.8138324],
          [100.5554779, 4.8148756],
          [100.5557098, 4.8158029],
          [100.5559126, 4.8171359],
          [100.5555359, 4.818382],
          [100.5556518, 4.8194252],
          [100.5556518, 4.8204394],
          [100.5557387, 4.8213088],
          [100.5558836, 4.8221781],
          [100.5563707, 4.8225343],
          [100.5562374, 4.8240275],
          [100.5561412, 4.825105],
          [100.5554526, 4.825105],
          [100.5538459, 4.8254264],
          [100.552997753737287, 4.825604936635902],
          [100.552892746854027, 4.8257718710336],
          [100.5527441, 4.8260691],
          [100.5525605, 4.8268495],
          [100.5521014, 4.8276758],
          [100.5509079, 4.8298334],
          [100.550648, 4.8304026],
          [100.5499438, 4.8319451],
          [100.5495766, 4.8327714],
          [100.5492093, 4.8336436],
          [100.5493011, 4.8341945],
          [100.549852, 4.8348372],
          [100.550357, 4.8355258],
          [100.5504947, 4.8360767],
          [100.5505865, 4.8373621],
          [100.5505613, 4.8378275],
          [100.5504947, 4.8390606],
          [100.5505059, 4.8391702],
          [100.5507242, 4.84131],
          [100.5504029, 4.8425036],
          [100.5502652, 4.8439726],
          [100.5497602, 4.8450744],
          [100.5495307, 4.8464516],
          [100.5498061, 4.8476911],
          [100.550862, 4.8485633],
          [100.5513669, 4.8494814],
          [100.5519178, 4.8504455],
          [100.5522851, 4.8515013],
          [100.5531573, 4.8520522],
          [100.5541672, 4.8521899],
          [100.555269, 4.8523276],
          [100.5558658, 4.8523735],
          [100.5563811, 4.8525914],
          [100.5574208, 4.8527244],
          [100.5575873, 4.8523694],
          [100.5577954, 4.8523559],
          [100.5583121, 4.8527015],
          [100.5600679, 4.8530807],
          [100.5606571, 4.8528242],
          [100.5610393, 4.8530006],
          [100.5609178, 4.8531647],
          [100.5609757, 4.8532954],
          [100.5609365, 4.8534542],
          [100.5610158, 4.8535169],
          [100.5611546, 4.8534607],
          [100.5617381, 4.8538061],
          [100.5635909, 4.8544291],
          [100.5637073, 4.854456],
          [100.5648093, 4.854678],
          [100.5648014, 4.8551668],
          [100.565175, 4.8554693],
          [100.5675889, 4.8567037],
          [100.5713892, 4.8599018],
          [100.5715815, 4.8601436],
          [100.572261, 4.8612175],
          [100.5725357, 4.8618447],
          [100.5725892, 4.8635073],
          [100.571781, 4.8636504],
          [100.5706755, 4.8635073],
          [100.5692266, 4.8632696],
          [100.5671895, 4.8627917],
          [100.5659979, 4.8622705],
          [100.5633992, 4.861274],
          [100.5618509, 4.8605015],
          [100.5612475, 4.8604745],
          [100.5602496, 4.8595027],
          [100.5591172, 4.8593512],
          [100.5582602, 4.85878],
          [100.5579957, 4.8591011],
          [100.5551607, 4.8608541],
          [100.5529462, 4.8620341],
          [100.5502678, 4.8633794],
          [100.5492326, 4.8646093],
          [100.5486075, 4.8660315],
          [100.5480831, 4.8679141],
          [100.5477778, 4.8700434],
          [100.5472843, 4.8756085],
          [100.5469321, 4.8772702],
          [100.5472397, 4.8779476],
          [100.5480722, 4.8783232],
          [100.5489882, 4.8786271],
          [100.5502438, 4.8788967],
          [100.5495083, 4.8805009],
          [100.5489985, 4.881613],
          [100.5481803, 4.8822528],
          [100.5468463, 4.8829546],
          [100.5458226, 4.8831755],
          [100.5446792, 4.8831016],
          [100.5436541, 4.8830039],
          [100.5426491, 4.8827024],
          [100.5414765, 4.8822669],
          [100.5402705, 4.8817979],
          [100.539165, 4.8812619],
          [100.5381935, 4.8808264],
          [100.536887, 4.8804579],
          [100.535815, 4.8798214],
          [100.534609, 4.8796874],
          [100.533671, 4.8792519],
          [100.532867, 4.8786489],
          [100.5320965, 4.8782804],
          [100.531326, 4.8781464],
          [100.530254, 4.8779454],
          [100.5292825, 4.8769739],
          [100.5290145, 4.8760359],
          [100.5290815, 4.8751649],
          [100.5288805, 4.8735234],
          [100.52878, 4.8725519],
          [100.528378, 4.8715804],
          [100.5272724, 4.8706759],
          [100.5253629, 4.8693023],
          [100.5249944, 4.8684648],
          [100.5255974, 4.8676943],
          [100.5252289, 4.8673258],
          [100.5241234, 4.8667563],
          [100.5233194, 4.8651818],
          [100.5226829, 4.8641098],
          [100.5215104, 4.8630378],
          [100.5200699, 4.8620998],
          [100.5181604, 4.8619323],
          [100.5174569, 4.8616643],
          [100.5167534, 4.8609273],
          [100.5142074, 4.8609943],
          [100.5108238, 4.8616308],
          [100.5093163, 4.8621668],
          [100.5068373, 4.8631048],
          [100.5050283, 4.8640428],
          [100.5048608, 4.8643778],
          [100.5051288, 4.8647463],
          [100.5056313, 4.8651483],
          [100.5051288, 4.8658853],
          [100.5040568, 4.8659523],
          [100.5019798, 4.8657513],
          [100.5001373, 4.8659188],
          [100.4993668, 4.8665218],
          [100.4986632, 4.8669573],
          [100.4968877, 4.8675603],
          [100.4957822, 4.8679288],
          [100.4951792, 4.8674933],
          [100.4942412, 4.8671918],
          [100.4933367, 4.8670243],
          [100.4925131, 4.8669299],
          [100.4920164, 4.8667147],
          [100.4918177, 4.8663339],
          [100.4909567, 4.8661186],
          [100.490013, 4.8660358],
          [100.4892679, 4.8657875],
          [100.4889533, 4.8654067],
          [100.4887215, 4.8652577],
          [100.487695, 4.8653239],
          [100.4870989, 4.8657213],
          [100.4863704, 4.8660027],
          [100.4857082, 4.8664332],
          [100.4853108, 4.8670293],
          [100.4852114, 4.8707877],
          [100.4849962, 4.8714665],
          [100.4848306, 4.8720957],
          [100.4844995, 4.8725924],
          [100.484367, 4.8732381],
          [100.4843174, 4.8741322],
          [100.4842346, 4.8748111],
          [100.4843008, 4.8754568],
          [100.4843008, 4.8761853],
          [100.4841352, 4.8770463],
          [100.4841849, 4.8775264],
          [100.4842346, 4.8780231],
          [100.4840525, 4.8783543],
          [100.4839034, 4.8785198],
          [100.48392, 4.8787847],
          [100.4841021, 4.8790165],
          [100.4843008, 4.8793477],
          [100.4843008, 4.879596],
          [100.4841187, 4.8798113],
          [100.4838041, 4.8802583],
          [100.4836551, 4.880606],
          [100.4836054, 4.8814339],
          [100.4833902, 4.8821458],
          [100.4834895, 4.8827087],
          [100.483622, 4.8831227],
          [100.4839531, 4.8835366],
          [100.4843505, 4.8836691],
          [100.484632, 4.8839009],
          [100.4846651, 4.8841823],
          [100.4844498, 4.8843976],
          [100.4841518, 4.884679],
          [100.4838207, 4.8849274],
          [100.483771, 4.885391],
          [100.4837048, 4.8858215],
          [100.4831749, 4.886136],
          [100.4827941, 4.8858049],
          [100.4823636, 4.8856393],
          [100.4820159, 4.8857056],
          [100.4817841, 4.8860864],
          [100.4817676, 4.8864837],
          [100.4806011, 4.887755],
          [100.4806334, 4.8882077],
          [100.4809568, 4.8887898],
          [100.4807628, 4.88976],
          [100.4806981, 4.8910858],
          [100.4804394, 4.8924763],
          [100.4803424, 4.8935758],
          [100.4807628, 4.8943843],
          [100.4812155, 4.8956778],
          [100.4815389, 4.897133],
          [100.4815066, 4.8988469],
          [100.4819593, 4.9009165],
          [100.4830264, 4.9033419],
          [100.484902, 4.9055732],
          [100.4860015, 4.9070931],
          [100.4882005, 4.9092597],
          [100.4902701, 4.9107149],
          [100.4923398, 4.9118468],
          [100.4944741, 4.9129139],
          [100.4961233, 4.9132373],
          [100.4973521, 4.9132696],
          [100.4985163, 4.9131726],
          [100.4999068, 4.9127846],
          [100.5008446, 4.9124288],
          [100.5022675, 4.9120408],
          [100.5030746, 4.9118276],
          [100.5031826, 4.9136578],
          [100.5032897, 4.9154741],
          [100.5024806, 4.9154741],
          [100.5011445, 4.9159446],
          [100.4993004, 4.9166972],
          [100.4969106, 4.9176758],
          [100.4948971, 4.9189553],
          [100.4930154, 4.9202914],
          [100.4911713, 4.9213452],
          [100.4889132, 4.9220602],
          [100.487389, 4.9228506],
          [100.4849427, 4.9238855],
          [100.4823679, 4.9246277],
          [100.4806888, 4.9250312],
          [100.4788814, 4.9256118],
          [100.4779332, 4.9258184],
          [100.476669, 4.9265171],
          [100.4749603, 4.926891],
          [100.4735183, 4.9273732],
          [100.4720565, 4.928121],
          [100.4707923, 4.9293314],
          [100.4695544, 4.9301569],
          [100.4683653, 4.9306725],
          [100.4676307, 4.9305286],
          [100.46757, 4.9294729],
          [100.4678072, 4.9285222],
          [100.4679288, 4.9275562],
          [100.4677073, 4.9265107],
          [100.4673674, 4.9255208],
          [100.4670979, 4.9247492],
          [100.4665118, 4.9242364],
          [100.4654129, 4.9236869],
          [100.4641675, 4.9232291],
          [100.4635264, 4.9231558],
          [100.462977, 4.9233573],
          [100.4625191, 4.9233939],
          [100.4618964, 4.9229726],
          [100.4611454, 4.9230093],
          [100.4602114, 4.9231741],
          [100.4593505, 4.9224964],
          [100.4591674, 4.921892],
          [100.4591674, 4.9214891],
          [100.4589476, 4.9212144],
          [100.4573359, 4.9205733],
          [100.4563285, 4.9202254],
          [100.4553395, 4.9200605],
          [100.4546801, 4.9198407],
          [100.4533734, 4.9189618],
          [100.4527247, 4.9185726],
          [100.4520241, 4.9181314],
          [100.4516868, 4.9176903],
          [100.4512197, 4.9176903],
          [100.4511159, 4.9174308],
          [100.4507267, 4.9169638],
          [100.4492736, 4.9160815],
          [100.4483913, 4.9156664],
          [100.4466788, 4.9155885],
          [100.4449662, 4.9157961],
          [100.4429422, 4.9159777],
          [100.4424492, 4.9162891],
          [100.4420081, 4.9171454],
          [100.4413075, 4.9178979],
          [100.4412037, 4.9188061],
          [100.4409183, 4.9193769],
          [100.4404771, 4.9203889],
          [100.4399582, 4.9209857],
          [100.4393873, 4.9216344],
          [100.4392576, 4.9225167],
          [100.4387905, 4.9233211],
          [100.4362735, 4.9264867],
          [100.4343274, 4.9289778],
          [100.4338085, 4.9296784],
          [100.4331503, 4.9302187],
          [100.4327646, 4.9308707],
          [100.432047, 4.9315698],
          [100.430542, 4.9341906],
          [100.4297117, 4.9362924],
          [100.4286738, 4.9385499],
          [100.4275648, 4.9397931],
          [100.4261557, 4.9436625],
          [100.424547586590052, 4.94833351373319],
          [100.4245164, 4.9484241],
          [100.4235407, 4.9526003],
          [100.4224305, 4.9568043],
          [100.4221963, 4.9593802],
          [100.4220272, 4.961947],
          [100.4217591, 4.9683421],
          [100.4211276, 4.9750515],
          [100.4212042, 4.9794169],
          [100.4215871, 4.9830931],
          [100.4218169, 4.986463],
          [100.4215506, 4.9890011],
          [100.4208584, 4.9916541],
          [100.4202368, 4.9932962],
          [100.4195597, 4.9945225],
          [100.4190896, 4.9952029],
          [100.4187541, 4.9956963],
          [100.4185447, 4.9959824],
          [100.4152816, 4.9955774],
          [100.4117456, 4.9950798],
          [100.4107332, 4.9955958],
          [100.408797, 4.9967919],
          [100.4073157, 4.9973778],
          [100.4055417, 4.9980145],
          [100.4054843, 4.9989789],
          [100.4054757, 4.9999638],
          [100.4051286, 5.0004926],
          [100.4048968, 5.0004833],
          [100.4047659, 5.0004781],
          [100.4045026, 5.0003803],
          [100.404112913894068, 5.0005],
          [100.4032795, 5.000756],
          [100.4028756, 5.0011587],
          [100.4022708, 5.002467],
          [100.4011907, 5.0041476],
          [100.4019705, 5.0063373],
          [100.4019271, 5.0071506],
          [100.4020875, 5.0077863],
          [100.4022288, 5.0082788],
          [100.4023297, 5.0087875],
          [100.4023081, 5.0090217],
          [100.4019264, 5.0091905],
          [100.4017753, 5.0093539],
          [100.4009564, 5.009714],
          [100.4009922, 5.0098716],
          [100.4012156, 5.0103518],
          [100.4012474, 5.0109602],
          [100.4010567, 5.0113265],
          [100.4008577, 5.0115771],
          [100.400577, 5.011869],
          [100.3997875, 5.0123025],
          [100.399924, 5.0131879],
          [100.399781, 5.0134966],
          [100.3997353, 5.0136823],
          [100.3999507, 5.0144566],
          [100.3997245, 5.0146834],
          [100.3988462, 5.0154754],
          [100.3992368, 5.015854],
          [100.3992005, 5.0159907],
          [100.3991004, 5.0161942],
          [100.3988887, 5.0161407],
          [100.3989605, 5.0162479],
          [100.3997036, 5.0169058],
          [100.3997068, 5.017027],
          [100.3997449, 5.0179352],
          [100.3996702, 5.0180806],
          [100.3965374, 5.0214181],
          [100.3936317, 5.0243512],
          [100.3935222, 5.0245496],
          [100.3932789, 5.0249904],
          [100.3930946, 5.0253313],
          [100.3930097, 5.0254457],
          [100.393149, 5.0256845],
          [100.3932087, 5.0258826],
          [100.3923762, 5.0293004],
          [100.3923113, 5.0292527],
          [100.3919731, 5.0305355],
          [100.3899601, 5.0343883],
          [100.3891564, 5.0342274],
          [100.389042, 5.0345253],
          [100.3889668, 5.0347213],
          [100.3893269, 5.0349181],
          [100.3897769, 5.0350203],
          [100.3894449, 5.0356927],
          [100.3881712, 5.0381956],
          [100.3856019, 5.0425539],
          [100.3846243, 5.0441201],
          [100.3827478, 5.0441932],
          [100.3826836, 5.0446281],
          [100.3829027, 5.0483912],
          [100.3829057, 5.0484434],
          [100.3826914, 5.0488478],
          [100.3824026, 5.0496641],
          [100.3817763, 5.052174],
          [100.3816456, 5.0526032],
          [100.3816214, 5.0526092],
          [100.3815691, 5.0527167],
          [100.3813861, 5.0527067],
          [100.3813706, 5.0527708],
          [100.3814236, 5.0527955],
          [100.3814397, 5.052841],
          [100.3815, 5.0528623],
          [100.3815047, 5.0529151],
          [100.3815798, 5.0529365],
          [100.3809413, 5.0556913],
          [100.3804627, 5.0574785],
          [100.3801328, 5.0589186],
          [100.379757, 5.0614702],
          [100.3795015, 5.0619841],
          [100.3783616, 5.0624539],
          [100.3780373, 5.0629336],
          [100.377826, 5.0632028],
          [100.3775803, 5.0633202],
          [100.3774442, 5.0634864],
          [100.3772609, 5.0649354],
          [100.3769563, 5.0668784],
          [100.3767155, 5.0685278],
          [100.3765453, 5.0694532],
          [100.3762557, 5.0701332],
          [100.3757541, 5.0713208],
          [100.3746718, 5.0729345],
          [100.3745136, 5.0734568],
          [100.3741287, 5.0739471],
          [100.3740764, 5.0744427],
          [100.3739905, 5.0746096],
          [100.3740361, 5.074957],
          [100.3743178, 5.0750905],
          [100.374649, 5.0751079],
          [100.3749642, 5.0751293],
          [100.3751533, 5.0750692],
          [100.3751653, 5.0749089],
          [100.3755234, 5.0746003],
          [100.3755167, 5.074253],
          [100.3757165, 5.0742329],
          [100.3758185, 5.0749102],
          [100.3754657, 5.0752375],
          [100.3754711, 5.0754739],
          [100.3756267, 5.0755741],
          [100.3775927, 5.0757224],
          [100.3776187, 5.0759208],
          [100.378608, 5.0761272],
          [100.38, 5.0762941],
          [100.3799477, 5.0766783],
          [100.3798905, 5.077086],
          [100.3795767, 5.0770652],
          [100.3788276, 5.0771694],
          [100.377835, 5.0776278],
          [100.3773307, 5.0778431],
          [100.3766309, 5.0777098],
          [100.3760134, 5.0775151],
          [100.3743667, 5.0779969],
          [100.3744181, 5.0785094],
          [100.3734404, 5.0786632],
          [100.3733066, 5.0784069],
          [100.3720407, 5.07894],
          [100.371979, 5.0794423],
          [100.3711453, 5.0799959],
          [100.3711351, 5.0801804],
          [100.3708057, 5.0805085],
          [100.3708057, 5.0808775],
          [100.3704352, 5.0810825],
          [100.3702603, 5.080898],
          [100.3698589, 5.0814106],
          [100.3699412, 5.0823435],
          [100.3698477, 5.0825194],
          [100.369756, 5.082692],
          [100.3688968, 5.0826893],
          [100.3685199, 5.0824768],
          [100.3681004, 5.0828522],
          [100.367415, 5.0835711],
          [100.3669193, 5.0841925],
          [100.3663915, 5.0845842],
          [100.3659494, 5.0848406],
          [100.3657905, 5.0855167],
          [100.365541, 5.0857157],
          [100.365369, 5.0858789],
          [100.3648107, 5.0861858],
          [100.3643962, 5.0865291],
          [100.3642477, 5.0869799],
          [100.3640921, 5.0870091],
          [100.3640337, 5.0868729],
          [100.3640629, 5.0866395],
          [100.364131, 5.0864742],
          [100.3641699, 5.0863381],
          [100.3640539, 5.0862331],
          [100.3640938, 5.0861328],
          [100.3641709, 5.0860859],
          [100.3641458, 5.085959],
          [100.3640123, 5.0858032],
          [100.3638492, 5.0856413],
          [100.363634, 5.0856599],
          [100.3633999, 5.0856413],
          [100.363142, 5.0856952],
          [100.3628969, 5.0859528],
          [100.3626757, 5.0860987],
          [100.3624494, 5.0861503],
          [100.3620233, 5.0863516],
          [100.3618273, 5.0867125],
          [100.3615506, 5.087072],
          [100.3614168, 5.0875148],
          [100.3613269, 5.08791],
          [100.3613691, 5.0883743],
          [100.3613853, 5.0887071],
          [100.361394, 5.0891178],
          [100.3614169, 5.0894155],
        ],
      ],
    ],
  },
};
