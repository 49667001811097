import { Container, Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import AppBar from "../../components/appbar";
import NHK96 from "../../assets/nhk96.svg";

const CustomContainer = styled(Grid)`
  min-height: 100vh;
`;

const Title = styled.div`
  font-family: "Staatliches", cursive;
  font-size: 48px;
  font-weight: bold;
  padding-top: 10vw;
`;

const Contents = styled.div`
  padding: 50px 1rem 50px 1rem;
`;

const Paragraph = styled.div`
  padding: 0.5rem 0px;
`;

const Subtitle = styled.div`
  opacity: 0.8;
  padding-bottom: 0.6rem;
`;

const About = (props) => {
  return (
    <CustomContainer container justify="center">
      <AppBar {...props} />
      <Contents>
        <Container>
          <Grid
            container
            justify="space-between"
            direction="column"
            style={{ minHeight: "calc(100vh - 100px)" }}
          >
            <div>
              <div>
                <Title>Credits</Title>
                <Subtitle>
                  This project would not be possible without open source data
                  from Carto and IGISMap
                </Subtitle>
                <Paragraph>
                  © Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap,
                  under Open Data Commons Open Database License (ODbL) via{" "}
                  <a href="https://carto.com/help/building-maps/basemap-list/">
                    Carto
                  </a>
                </Paragraph>
                <Paragraph>
                  © Malaysia data provided here is license under Open Data
                  Commons Open Database License (ODbL) via{" "}
                  <a href="https://www.igismap.com/">igismap.com</a>
                </Paragraph>
              </div>
              <div>
                <Title>Library used</Title>
                <Subtitle>
                  Below are components that play a very important roles to make
                  everything works.
                </Subtitle>
                <Paragraph>
                  material-ui <br /> styled-components
                  <br />
                  leaflet <br /> react-leaflet
                  <br />
                  i18next <br /> react-i18next
                </Paragraph>
              </div>
            </div>
          </Grid>
        </Container>
      </Contents>
    </CustomContainer>
  );
};

export default About;
