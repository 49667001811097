export const add = (amount) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_PLUS",
      payload: amount,
    });
  };
};

export const minus = (amount) => {
  return (dispatch) => {
    dispatch({
      type: "ACTION_MINUS",
      payload: amount,
    });
  };
};
