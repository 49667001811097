export const kualalumpur = {
  type: "Feature",
  properties: {
    gid: 4,
    id: 2939672,
    country: "MYS",
    name: "Kuala Lumpur",
    enname: "Kuala Lumpur",
    locname: "Kuala Lumpur",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q1865",
    wikimedia: "en:Kuala Lumpur",
    timestamp: "2019-11-14 22:59:02",
    note: null,
    path: "0,2108121,2939672",
    rpath: "2939672,2108121,0",
    iso3166_2: "MY-14",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [101.615081, 3.1534803],
          [101.6152677, 3.158678],
          [101.6156169, 3.1607388],
          [101.6163601, 3.161347],
          [101.6163936, 3.1616919],
          [101.6165536, 3.1633401],
          [101.6160501, 3.1644449],
          [101.6161834, 3.1657988],
          [101.6165338, 3.1675584],
          [101.6166459, 3.168471],
          [101.6167563, 3.1701078],
          [101.6167597, 3.1701578],
          [101.6168932, 3.1702644],
          [101.6178152, 3.1710006],
          [101.6179704, 3.1722066],
          [101.618942, 3.1733769],
          [101.6190405, 3.1740359],
          [101.619304, 3.178065],
          [101.6191681, 3.1821202],
          [101.619557, 3.1857576],
          [101.6198848, 3.1883939],
          [101.6196138, 3.1901749],
          [101.6190204, 3.1938579],
          [101.6188589, 3.195483],
          [101.6188486, 3.1962285],
          [101.6191165, 3.1979053],
          [101.6202478, 3.1974894],
          [101.6218987, 3.1975594],
          [101.6230345, 3.197846],
          [101.6239211, 3.198324],
          [101.6251656, 3.1995411],
          [101.6262049, 3.2011251],
          [101.6276245, 3.2036909],
          [101.6293308, 3.2083997],
          [101.6294006, 3.2085925],
          [101.6303256, 3.2086088],
          [101.6309129, 3.210555],
          [101.6312656, 3.2116072],
          [101.6315713, 3.2119985],
          [101.6331713, 3.2132934],
          [101.6339902, 3.2141463],
          [101.6341991, 3.2142665],
          [101.6341764, 3.2150052],
          [101.6341665, 3.215325],
          [101.6341649, 3.2153778],
          [101.6339432, 3.2153934],
          [101.6339978, 3.2166154],
          [101.634035, 3.2174496],
          [101.6346685, 3.2166742],
          [101.6353854, 3.2166964],
          [101.6355412, 3.216616],
          [101.6364259, 3.2161596],
          [101.6392202, 3.2215598],
          [101.6357122, 3.2268134],
          [101.6355498, 3.2281106],
          [101.6369564, 3.2280566],
          [101.6380833, 3.2280133],
          [101.6379534, 3.2299915],
          [101.6374012, 3.2320994],
          [101.6460737, 3.2321642],
          [101.6462036, 3.2335911],
          [101.6504911, 3.2337208],
          [101.654064, 3.234337],
          [101.6540965, 3.2368665],
          [101.6573446, 3.2360233],
          [101.6572471, 3.2372556],
          [101.6577993, 3.2413093],
          [101.659261, 3.2414066],
          [101.6611449, 3.2405958],
          [101.6611124, 3.2425416],
          [101.6635809, 3.2443252],
          [101.6659845, 3.2431901],
          [101.6682907, 3.2406931],
          [101.6715388, 3.236153],
          [101.6734227, 3.2321642],
          [101.6720585, 3.2307049],
          [101.672091, 3.2277214],
          [101.696809, 3.2279484],
          [101.6971338, 3.2232785],
          [101.7014863, 3.2229867],
          [101.7018436, 3.2172466],
          [101.7032403, 3.2152684],
          [101.7038574, 3.2159819],
          [101.7072354, 3.2158846],
          [101.7085672, 3.2147495],
          [101.7103367, 3.2160767],
          [101.711169, 3.2153721],
          [101.7127506, 3.2149999],
          [101.7147728, 3.2128792],
          [101.7173291, 3.2153596],
          [101.7157054, 3.2153411],
          [101.715701, 3.2145513],
          [101.7148997, 3.2145469],
          [101.7149411, 3.2168423],
          [101.7156188, 3.2168432],
          [101.7156301, 3.2182535],
          [101.7184035, 3.2182084],
          [101.7184391, 3.2210858],
          [101.7193265, 3.2226199],
          [101.7237578, 3.2270852],
          [101.7237751, 3.2292792],
          [101.7248124, 3.2292747],
          [101.7250218, 3.228731],
          [101.7299654, 3.2329006],
          [101.7319305, 3.2315872],
          [101.7394905, 3.2314332],
          [101.7395717, 3.2323331],
          [101.7389708, 3.2326087],
          [101.7389951, 3.2333141],
          [101.7403025, 3.2332249],
          [101.7439911, 3.2285904],
          [101.743845, 3.2276013],
          [101.742562, 3.2265149],
          [101.7424077, 3.2253555],
          [101.7416119, 3.2250475],
          [101.7412952, 3.2236611],
          [101.7398823, 3.2231422],
          [101.7395489, 3.2174151],
          [101.7391592, 3.2152099],
          [101.7406533, 3.2148207],
          [101.7424397, 3.2130046],
          [101.7436415, 3.2127776],
          [101.7447134, 3.2100535],
          [101.7453539, 3.2098476],
          [101.7463884, 3.2095151],
          [101.747117, 3.2044107],
          [101.7482863, 3.2023676],
          [101.7505275, 3.2022703],
          [101.7510147, 3.2000002],
          [101.7534508, 3.1999353],
          [101.7568938, 3.197049],
          [101.7569263, 3.1937411],
          [101.7580306, 3.1896224],
          [101.7591999, 3.187936],
          [101.7581605, 3.1855361],
          [101.7564391, 3.185828],
          [101.755627, 3.1847253],
          [101.7568288, 3.1844335],
          [101.7566989, 3.1835578],
          [101.7561467, 3.1807363],
          [101.7503651, 3.1763581],
          [101.7499428, 3.1751582],
          [101.7478316, 3.1750933],
          [101.7463699, 3.1756447],
          [101.7434791, 3.1755474],
          [101.7378274, 3.1774284],
          [101.73773, 3.1781418],
          [101.7338647, 3.1782391],
          [101.7335075, 3.1761311],
          [101.7315586, 3.1739907],
          [101.7314936, 3.172888],
          [101.7334479, 3.172949],
          [101.7335724, 3.1729529],
          [101.7349041, 3.1717205],
          [101.7352245, 3.1683143],
          [101.7355538, 3.1648126],
          [101.7378924, 3.1647477],
          [101.7407061, 3.1622637],
          [101.7424073, 3.1625748],
          [101.7482214, 3.1612451],
          [101.7490009, 3.1619262],
          [101.7507224, 3.1607587],
          [101.7486436, 3.1585533],
          [101.749683, 3.1579047],
          [101.7505925, 3.1571912],
          [101.7470845, 3.1532994],
          [101.7467597, 3.1497318],
          [101.7414328, 3.1511589],
          [101.7408482, 3.1507048],
          [101.7422124, 3.1479157],
          [101.7401985, 3.1460995],
          [101.7390942, 3.1450616],
          [101.7404584, 3.1432454],
          [101.7388086, 3.1413125],
          [101.7355862, 3.1375374],
          [101.7352996, 3.1364998],
          [101.7350665, 3.1356563],
          [101.7354563, 3.1345536],
          [101.7380548, 3.1320239],
          [101.7390942, 3.1283265],
          [101.741173, 3.1243049],
          [101.7408715, 3.1235079],
          [101.7404395, 3.122366],
          [101.7399782, 3.1211468],
          [101.7389643, 3.118467],
          [101.7384446, 3.1161318],
          [101.7390292, 3.1154831],
          [101.739354, 3.1121101],
          [101.7413029, 3.1101641],
          [101.7441612, 3.1102939],
          [101.7453955, 3.1117209],
          [101.7478641, 3.1098398],
          [101.7517618, 3.1084776],
          [101.753061, 3.1067911],
          [101.7536457, 3.1036126],
          [101.7505275, 3.101472],
          [101.7522815, 3.0993962],
          [101.7525736, 3.0976666],
          [101.751676, 3.0962642],
          [101.7512956, 3.0924531],
          [101.7452969, 3.0863139],
          [101.7452656, 3.0846065],
          [101.7475393, 3.0787035],
          [101.7476042, 3.0767574],
          [101.7498129, 3.0768872],
          [101.7498779, 3.0757195],
          [101.7489035, 3.0742924],
          [101.7500728, 3.0738383],
          [101.7496982, 3.0659233],
          [101.7495919, 3.0549719],
          [101.7466776, 3.0548692],
          [101.7366427, 3.0549666],
          [101.7304618, 3.0550269],
          [101.7306302, 3.035264],
          [101.7289028, 3.0376322],
          [101.7242257, 3.0337398],
          [101.7236392, 3.033413],
          [101.7222225, 3.0333968],
          [101.7222163, 3.035743],
          [101.7229595, 3.0362864],
          [101.724121, 3.0362455],
          [101.7243478, 3.0401061],
          [101.7215544, 3.04069],
          [101.7199304, 3.0473068],
          [101.7166823, 3.0475662],
          [101.7150582, 3.0518287],
          [101.7154155, 3.0535153],
          [101.7140838, 3.054164],
          [101.7104459, 3.0531909],
          [101.7086784, 3.0478256],
          [101.7082337, 3.0478368],
          [101.7081832, 3.0459738],
          [101.7069671, 3.0459361],
          [101.7065807, 3.0495907],
          [101.7015137, 3.0490393],
          [101.6985904, 3.048423],
          [101.6985904, 3.0477743],
          [101.6991101, 3.0473526],
          [101.6992725, 3.0456336],
          [101.6972262, 3.0454714],
          [101.6951149, 3.0444335],
          [101.6927705, 3.0444548],
          [101.6927487, 3.0442365],
          [101.6912342, 3.0442943],
          [101.6901453, 3.0436875],
          [101.6893658, 3.0450822],
          [101.6882289, 3.044401],
          [101.6867673, 3.0456011],
          [101.680401, 3.0423576],
          [101.6720721, 3.0421216],
          [101.6712413, 3.0420981],
          [101.6685105, 3.0405502],
          [101.6686965, 3.0453827],
          [101.6657662, 3.0458161],
          [101.6628977, 3.0462403],
          [101.6592993, 3.0509139],
          [101.6593713, 3.0541451],
          [101.6593304, 3.0560661],
          [101.6593112, 3.0569725],
          [101.659277, 3.0585131],
          [101.6591857, 3.0586967],
          [101.6570918, 3.0581678],
          [101.6559671, 3.0588024],
          [101.6531397, 3.0573621],
          [101.6524511, 3.0589968],
          [101.6517234, 3.0586806],
          [101.6505927, 3.0581893],
          [101.6489106, 3.057521],
          [101.6479765, 3.0590096],
          [101.6495834, 3.0600205],
          [101.6490665, 3.0605033],
          [101.6485786, 3.0613413],
          [101.6493963, 3.0618149],
          [101.6484426, 3.0634034],
          [101.6485939, 3.0635833],
          [101.6500159, 3.0654362],
          [101.6514225, 3.06645],
          [101.6505723, 3.067358],
          [101.6503309, 3.0680643],
          [101.6506622, 3.0701461],
          [101.6485206, 3.0704929],
          [101.6465681, 3.0713969],
          [101.646425, 3.0716645],
          [101.6466491, 3.071786],
          [101.6474545, 3.0722224],
          [101.6505603, 3.0741876],
          [101.6510831, 3.0746493],
          [101.6513633, 3.0749177],
          [101.6515034, 3.0750706],
          [101.6516167, 3.0752235],
          [101.652048, 3.0763907],
          [101.6541042, 3.0769934],
          [101.6540135, 3.0776378],
          [101.6546138, 3.0783374],
          [101.6554244, 3.078617],
          [101.6570745, 3.0786298],
          [101.6573126, 3.0787017],
          [101.6574377, 3.0786884],
          [101.6575435, 3.0784848],
          [101.6590814, 3.0790415],
          [101.6589014, 3.0795659],
          [101.659276, 3.0798196],
          [101.6592471, 3.0799778],
          [101.6591694, 3.0801685],
          [101.6589734, 3.0806229],
          [101.6601638, 3.0811182],
          [101.6594713, 3.082867],
          [101.6603731, 3.0832446],
          [101.6598479, 3.0844879],
          [101.6638084, 3.085849],
          [101.6636166, 3.0863646],
          [101.6631881, 3.087721],
          [101.6607337, 3.0909607],
          [101.6600266, 3.0919161],
          [101.6597923, 3.0957109],
          [101.6599531, 3.0973736],
          [101.6595741, 3.0988413],
          [101.6603895, 3.0999536],
          [101.6599186, 3.1040359],
          [101.6598268, 3.1073154],
          [101.659689, 3.1135419],
          [101.6596906, 3.1145888],
          [101.6585004, 3.1137155],
          [101.6531881, 3.1104568],
          [101.6526728, 3.1101407],
          [101.6508811, 3.1149401],
          [101.6505271, 3.1157864],
          [101.6501408, 3.1165042],
          [101.6498404, 3.1170184],
          [101.6494864, 3.1174684],
          [101.6492074, 3.1177469],
          [101.6488641, 3.1179933],
          [101.6484564, 3.1181754],
          [101.6478878, 3.1183575],
          [101.6473941, 3.1184553],
          [101.6453069, 3.1185693],
          [101.6439726, 3.118612],
          [101.6438385, 3.1186575],
          [101.6437116, 3.1187534],
          [101.6435863, 3.1188731],
          [101.6434643, 3.1190632],
          [101.643412, 3.1192253],
          [101.6434241, 3.1196016],
          [101.6434442, 3.1199364],
          [101.6434965, 3.1201506],
          [101.6436373, 3.1203716],
          [101.6441065, 3.1208351],
          [101.6481066, 3.1250671],
          [101.6483615, 3.1260753],
          [101.6481373, 3.126654],
          [101.647883, 3.1272514],
          [101.6470491, 3.1282235],
          [101.6464341, 3.1277216],
          [101.6457262, 3.1275397],
          [101.6446912, 3.1275108],
          [101.6437713, 3.1277758],
          [101.6403895, 3.1295851],
          [101.6385446, 3.1303833],
          [101.6371602, 3.131462],
          [101.6366216, 3.1318186],
          [101.6361197, 3.1320682],
          [101.6348734, 3.1323485],
          [101.6317081, 3.1316348],
          [101.630369, 3.1306962],
          [101.6300031, 3.1306056],
          [101.6294992, 3.1305895],
          [101.6292746, 3.1326296],
          [101.6292225, 3.133422],
          [101.6293781, 3.1339877],
          [101.6296214, 3.1351764],
          [101.6296007, 3.136808],
          [101.629104, 3.1373507],
          [101.628069, 3.138012],
          [101.6271544, 3.1394086],
          [101.6261565, 3.1409386],
          [101.6253218, 3.1415494],
          [101.6248359, 3.1415503],
          [101.6233233, 3.1413027],
          [101.6213231, 3.1433364],
          [101.6200255, 3.144747],
          [101.6193005, 3.1460884],
          [101.6187211, 3.1469526],
          [101.6173808, 3.1492228],
          [101.6164905, 3.1506222],
          [101.6151785, 3.152866],
          [101.615081, 3.1534803],
        ],
      ],
    ],
  },
};
