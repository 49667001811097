export const sabah = {
  type: "Feature",
  properties: {
    gid: 13,
    id: 3879783,
    country: "MYS",
    name: "Sabah",
    enname: null,
    locname: "Sabah",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q179029",
    wikimedia: "en:Sabah",
    timestamp: "2019-12-25 22:58:02",
    note: null,
    path: "0,2108121,3879783",
    rpath: "3879783,2108121,0",
    iso3166_2: "MY-12",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [115.6296606, 5.7214438],
          [115.6288023, 5.7219562],
          [115.628974, 5.7224686],
          [115.6294369, 5.7222043],
          [115.6298997, 5.7219176],
          [115.6296606, 5.7214438],
        ],
      ],
      [
        [
          [115.6284828, 5.7304168],
          [115.6292581, 5.7293547],
          [115.628876, 5.7281808],
          [115.6282019, 5.728941],
          [115.6284828, 5.7304168],
        ],
      ],
      [
        [
          [115.4300513, 5.2568263],
          [115.4296489, 5.256829],
          [115.4293137, 5.2570827],
          [115.4284795, 5.2589176],
          [115.4294075, 5.2610917],
          [115.43132, 5.2615645],
          [115.4334416, 5.2614683],
          [115.4334094, 5.2609368],
          [115.4300513, 5.2568263],
        ],
      ],
      [
        [
          [115.427699, 5.2585303],
          [115.426787, 5.2592942],
          [115.4268165, 5.2604294],
          [115.4281657, 5.2611959],
          [115.4290937, 5.261228],
          [115.4292144, 5.2611131],
          [115.427699, 5.2585303],
        ],
      ],
      [
        [
          [115.3535654, 5.2856517],
          [115.3536586, 5.2854462],
          [115.3535854, 5.2852673],
          [115.3537185, 5.285148],
          [115.3540513, 5.2851149],
          [115.3543175, 5.2849227],
          [115.3545504, 5.2844919],
          [115.3545105, 5.2841341],
          [115.3549098, 5.283869],
          [115.3551095, 5.2838822],
          [115.3552626, 5.284081],
          [115.3554755, 5.2841341],
          [115.3556153, 5.2840413],
          [115.3557218, 5.2838027],
          [115.3557155, 5.2836103],
          [115.3557085, 5.2833918],
          [115.3554422, 5.2827954],
          [115.3546769, 5.2827424],
          [115.3544772, 5.2829544],
          [115.3543441, 5.2827622],
          [115.3542776, 5.2833256],
          [115.3542177, 5.283193],
          [115.3540646, 5.283193],
          [115.3539115, 5.2835708],
          [115.3537717, 5.2837696],
          [115.353652, 5.2836105],
          [115.3532859, 5.2838491],
          [115.3525338, 5.2846178],
          [115.3519548, 5.2851745],
          [115.3513408, 5.2858286],
          [115.3510963, 5.286089],
          [115.3507569, 5.2863276],
          [115.3503376, 5.2870765],
          [115.3501512, 5.2877524],
          [115.3499116, 5.2883688],
          [115.3498185, 5.2887995],
          [115.3498899, 5.289461],
          [115.3499316, 5.2898466],
          [115.3500847, 5.2900719],
          [115.3511429, 5.2903171],
          [115.3514224, 5.2902906],
          [115.3520413, 5.2899261],
          [115.352667, 5.2895749],
          [115.3529598, 5.2892038],
          [115.3530729, 5.288488],
          [115.3534257, 5.2880109],
          [115.3535388, 5.287779],
          [115.3534257, 5.2871825],
          [115.3533961, 5.2869738],
          [115.3533525, 5.2866656],
          [115.3535105, 5.2862871],
          [115.3535987, 5.2860758],
          [115.3535654, 5.2856517],
        ],
      ],
      [
        [
          [119.1081954, 5.439113],
          [119.1044569, 5.4393988],
          [119.1039095, 5.4396902],
          [119.1036093, 5.440296],
          [119.1019142, 5.4479218],
          [119.1014737, 5.4484439],
          [119.1013885, 5.4488404],
          [119.1016567, 5.4492248],
          [119.1019893, 5.4494278],
          [119.1054111, 5.4564649],
          [119.1061414, 5.4572137],
          [119.1114199, 5.4605245],
          [119.1123742, 5.4608224],
          [119.1168058, 5.460909],
          [119.1175568, 5.4606954],
          [119.1208399, 5.4592109],
          [119.1216338, 5.4590934],
          [119.122417, 5.4591468],
          [119.1235435, 5.4599158],
          [119.1242087, 5.4601828],
          [119.1252166, 5.460267],
          [119.1294229, 5.4614964],
          [119.1306454, 5.4616234],
          [119.1399908, 5.4617848],
          [119.1410739, 5.4615273],
          [119.1467709, 5.4579815],
          [119.1472113, 5.4572778],
          [119.1472006, 5.4562098],
          [119.1472543, 5.4558146],
          [119.1475761, 5.4556651],
          [119.1495288, 5.4564554],
          [119.1498614, 5.4563272],
          [119.1498614, 5.4560068],
          [119.1490041, 5.454098],
          [119.1479302, 5.4525998],
          [119.1473192, 5.4523516],
          [119.1464281, 5.4523542],
          [119.1460741, 5.4522047],
          [119.1458166, 5.4517774],
          [119.1457629, 5.4510298],
          [119.1454625, 5.4508376],
          [119.1451785, 5.4507906],
          [119.1448724, 5.450923],
          [119.1446901, 5.4513289],
          [119.1443145, 5.4514998],
          [119.1438639, 5.451425],
          [119.1435528, 5.4511473],
          [119.1433704, 5.4506026],
          [119.1429466, 5.4502739],
          [119.1422761, 5.4502288],
          [119.1415572, 5.4505278],
          [119.139626, 5.4505065],
          [119.139272, 5.4502181],
          [119.1392827, 5.4498443],
          [119.1397548, 5.4494812],
          [119.1397968, 5.449101],
          [119.1393471, 5.4472276],
          [119.1388838, 5.446698],
          [119.1344118, 5.4448352],
          [119.1332995, 5.4446734],
          [119.132761, 5.4448853],
          [119.1315794, 5.4455508],
          [119.1309447, 5.4455282],
          [119.1298875, 5.444914],
          [119.1279531, 5.4440235],
          [119.125589, 5.4422632],
          [119.1210527, 5.4405872],
          [119.1175881, 5.4401753],
          [119.1147479, 5.4388053],
          [119.1122475, 5.4383037],
          [119.1110577, 5.4383472],
          [119.1101754, 5.4385978],
          [119.1096604, 5.439228],
          [119.1092259, 5.4393454],
          [119.1087377, 5.4393561],
          [119.1081954, 5.439113],
        ],
      ],
      [
        [
          [115.5675173, 5.1623001],
          [115.5672081, 5.1624801],
          [115.5678887, 5.1647895],
          [115.5685496, 5.1694853],
          [115.5695511, 5.1736289],
          [115.570085, 5.1736824],
          [115.5706315, 5.1725466],
          [115.5710343, 5.1703222],
          [115.5710011, 5.169586],
          [115.5707421, 5.1689158],
          [115.5702587, 5.1696992],
          [115.5698354, 5.1721407],
          [115.5694464, 5.1715563],
          [115.5688272, 5.169442],
          [115.5690989, 5.1669942],
          [115.5688573, 5.1651536],
          [115.5675173, 5.1623001],
        ],
      ],
      [
        [
          [115.5833198, 5.1949015],
          [115.5825211, 5.195264],
          [115.5787779, 5.1943496],
          [115.5796689, 5.1964002],
          [115.5811195, 5.1984616],
          [115.5826081, 5.2002038],
          [115.5840315, 5.2013454],
          [115.5847866, 5.1990081],
          [115.5846508, 5.1969846],
          [115.5833198, 5.1949015],
        ],
      ],
      [
        [
          [115.5856233, 5.1986835],
          [115.5851343, 5.2008693],
          [115.585482, 5.2023572],
          [115.5862644, 5.2040507],
          [115.5869272, 5.2019081],
          [115.5863893, 5.1991975],
          [115.5856233, 5.1986835],
        ],
      ],
      [
        [
          [118.2215208, 4.9349877],
          [118.2204475, 4.9351203],
          [118.2201851, 4.9355575],
          [118.2201851, 4.9364319],
          [118.2200103, 4.9376561],
          [118.2185688, 4.9375324],
          [118.2173084, 4.9383971],
          [118.217221, 4.939359],
          [118.2180954, 4.940146],
          [118.2194944, 4.9394464],
          [118.2211711, 4.9396112],
          [118.2218433, 4.9393543],
          [118.2219362, 4.9388297],
          [118.2218651, 4.9378514],
          [118.2221274, 4.9372503],
          [118.222428, 4.9372339],
          [118.2226849, 4.9374142],
          [118.2231877, 4.9375836],
          [118.2235866, 4.9373869],
          [118.2238763, 4.9366491],
          [118.2238544, 4.9357255],
          [118.2235921, 4.9349877],
          [118.223286, 4.934086],
          [118.2234554, 4.9339057],
          [118.2234882, 4.9336434],
          [118.223674, 4.9334794],
          [118.2239364, 4.9333209],
          [118.2241495, 4.9331078],
          [118.2240129, 4.9329657],
          [118.223603, 4.9330258],
          [118.2234664, 4.9334903],
          [118.2232041, 4.933627],
          [118.222827, 4.9338073],
          [118.2225646, 4.9341953],
          [118.2222149, 4.9346161],
          [118.2215208, 4.9349877],
        ],
      ],
      [
        [
          [118.2092235, 4.9407142],
          [118.2092961, 4.9410579],
          [118.2097504, 4.9412548],
          [118.2099549, 4.9415577],
          [118.2104168, 4.9417773],
          [118.2109545, 4.9416788],
          [118.2111513, 4.9416712],
          [118.2116057, 4.9413153],
          [118.2120449, 4.9408383],
          [118.2124992, 4.9402476],
          [118.2124689, 4.9394677],
          [118.2122266, 4.9391421],
          [118.2114694, 4.9385817],
          [118.2107986, 4.9385485],
          [118.21042, 4.9395329],
          [118.2096779, 4.9401539],
          [118.2092235, 4.9407142],
        ],
      ],
      [
        [
          [118.2522657, 4.947934],
          [118.2532548, 4.9476514],
          [118.2537611, 4.9476632],
          [118.2545029, 4.947675],
          [118.2552801, 4.9479458],
          [118.2554214, 4.947357],
          [118.2556333, 4.9471333],
          [118.2561514, 4.9470509],
          [118.257164, 4.9470038],
          [118.2578234, 4.9472864],
          [118.2584239, 4.9474041],
          [118.2587065, 4.9468507],
          [118.2591186, 4.9456615],
          [118.2596956, 4.9440366],
          [118.2596367, 4.9436009],
          [118.2590833, 4.9433065],
          [118.2584475, 4.9435303],
          [118.2573995, 4.9438599],
          [118.2559394, 4.944696],
          [118.2548444, 4.9454142],
          [118.2536316, 4.9459912],
          [118.2524777, 4.9467448],
          [118.2519243, 4.9476985],
          [118.2522657, 4.947934],
        ],
      ],
      [
        [
          [118.2861889, 4.970082],
          [118.2862222, 4.9702568],
          [118.2862888, 4.970415],
          [118.2866634, 4.9705066],
          [118.2870215, 4.9705149],
          [118.2872379, 4.9704483],
          [118.2874794, 4.970107],
          [118.2878874, 4.9695158],
          [118.2884702, 4.9691911],
          [118.2883952, 4.9689163],
          [118.2880955, 4.9687498],
          [118.2871463, 4.9685],
          [118.2868966, 4.9686582],
          [118.2866301, 4.9690412],
          [118.2863804, 4.969316],
          [118.2863387, 4.9695075],
          [118.2861889, 4.970082],
        ],
      ],
      [
        [
          [118.283768, 4.9660923],
          [118.2836674, 4.9661684],
          [118.283606, 4.9662672],
          [118.2836245, 4.966417],
          [118.2840217, 4.9670555],
          [118.2842717, 4.9672607],
          [118.2843635, 4.9672734],
          [118.2844627, 4.96726],
          [118.2845579, 4.9672707],
          [118.2846961, 4.9673041],
          [118.2847913, 4.9673789],
          [118.2851976, 4.967574],
          [118.2853304, 4.9676061],
          [118.285486, 4.9676074],
          [118.2856067, 4.9676208],
          [118.2856831, 4.9676689],
          [118.2859179, 4.9679594],
          [118.2860338, 4.9680062],
          [118.2861619, 4.9679574],
          [118.2862343, 4.967892],
          [118.2863148, 4.9677236],
          [118.2864194, 4.9676608],
          [118.2865494, 4.9676269],
          [118.2867037, 4.9675246],
          [118.2868686, 4.9674137],
          [118.2870604, 4.9673696],
          [118.2872334, 4.9673014],
          [118.2872938, 4.967228],
          [118.2873152, 4.9671344],
          [118.2873126, 4.9668939],
          [118.287345, 4.9668225],
          [118.2879388, 4.9663368],
          [118.2886228, 4.965964],
          [118.2888733, 4.9658942],
          [118.2891485, 4.9658892],
          [118.2896581, 4.9659453],
          [118.2898016, 4.9659253],
          [118.2898861, 4.9658358],
          [118.2900388, 4.965496],
          [118.2902724, 4.9650154],
          [118.290385, 4.9646306],
          [118.2903931, 4.9644409],
          [118.2903156, 4.9642789],
          [118.2901812, 4.9641336],
          [118.2899854, 4.9640321],
          [118.2895876, 4.9639562],
          [118.2894342, 4.9638945],
          [118.2892558, 4.9638771],
          [118.2889617, 4.9639615],
          [118.2881282, 4.9643316],
          [118.287574, 4.964483],
          [118.2870443, 4.9645171],
          [118.2869303, 4.9645518],
          [118.2868405, 4.9646093],
          [118.2867128, 4.9648112],
          [118.2865454, 4.9650194],
          [118.2863054, 4.965157],
          [118.28606, 4.9652198],
          [118.2858329, 4.9652301],
          [118.2856067, 4.9652653],
          [118.2853891, 4.9653352],
          [118.2850005, 4.9656928],
          [118.2845083, 4.96594],
          [118.2841367, 4.9660342],
          [118.283768, 4.9660923],
        ],
      ],
      [
        [
          [118.3116485, 4.9676538],
          [118.3111657, 4.9679422],
          [118.3105472, 4.9693659],
          [118.310534, 4.9699031],
          [118.3113582, 4.970068],
          [118.3116499, 4.9702057],
          [118.3119711, 4.9702113],
          [118.3121189, 4.9702737],
          [118.312248, 4.9704298],
          [118.3123125, 4.9705256],
          [118.3125311, 4.9706276],
          [118.3125436, 4.9707587],
          [118.3126075, 4.9708244],
          [118.3126664, 4.9708023],
          [118.3127414, 4.9707478],
          [118.3128135, 4.9707287],
          [118.3128386, 4.9707699],
          [118.3128077, 4.9708803],
          [118.3129651, 4.9709716],
          [118.3130211, 4.9710246],
          [118.3130447, 4.9711774],
          [118.3132404, 4.9711878],
          [118.313434, 4.9712107],
          [118.3131197, 4.9712669],
          [118.3130468, 4.9715021],
          [118.3129945, 4.9721819],
          [118.3131564, 4.972753],
          [118.3133154, 4.9730679],
          [118.3132329, 4.9732887],
          [118.3127884, 4.9736832],
          [118.3125677, 4.9738333],
          [118.3125971, 4.9740894],
          [118.3128355, 4.9742483],
          [118.3130858, 4.9742071],
          [118.313389, 4.9741924],
          [118.3137599, 4.9743278],
          [118.3140652, 4.9742707],
          [118.3145429, 4.9743455],
          [118.3151139, 4.9741394],
          [118.3151728, 4.9737685],
          [118.315008, 4.9734447],
          [118.3149256, 4.9730679],
          [118.3150551, 4.9730562],
          [118.3151022, 4.9732399],
          [118.315123, 4.9733822],
          [118.3150938, 4.973427],
          [118.3151074, 4.9735113],
          [118.3151938, 4.9735852],
          [118.3152624, 4.9736632],
          [118.3153384, 4.9736195],
          [118.3154269, 4.9735893],
          [118.3155101, 4.973581],
          [118.3155018, 4.9736164],
          [118.3154498, 4.9736799],
          [118.3154029, 4.9737361],
          [118.3154154, 4.9738558],
          [118.3158785, 4.9740588],
          [118.3160663, 4.9739007],
          [118.3162494, 4.9735135],
          [118.3163327, 4.9731763],
          [118.3163452, 4.9728974],
          [118.3164055, 4.9727547],
          [118.3166262, 4.9729253],
          [118.3168489, 4.9730481],
          [118.3171299, 4.9732646],
          [118.3173297, 4.9732084],
          [118.3175046, 4.9730502],
          [118.3175129, 4.9728879],
          [118.3170671, 4.9720621],
          [118.3168506, 4.9713211],
          [118.3171973, 4.9712093],
          [118.3170588, 4.9710463],
          [118.3169505, 4.9702887],
          [118.3169172, 4.9698724],
          [118.3174334, 4.9695227],
          [118.3170921, 4.9690897],
          [118.3171652, 4.968054],
          [118.3164658, 4.9679541],
          [118.3161908, 4.9678628],
          [118.3160579, 4.9676877],
          [118.3158376, 4.9674094],
          [118.3155903, 4.9669443],
          [118.3153313, 4.9667913],
          [118.3146189, 4.9665617],
          [118.3141389, 4.9664056],
          [118.3136208, 4.9664292],
          [118.3130203, 4.9666529],
          [118.3124315, 4.9670297],
          [118.3118781, 4.9672593],
          [118.3116485, 4.9676538],
        ],
      ],
      [
        [
          [118.3148695, 4.9765934],
          [118.314937, 4.9768384],
          [118.3150185, 4.9769224],
          [118.3151274, 4.9769222],
          [118.3155281, 4.9766245],
          [118.3161343, 4.9762357],
          [118.3162791, 4.9761956],
          [118.3164373, 4.9761956],
          [118.3165634, 4.9762357],
          [118.3166796, 4.976343],
          [118.3167549, 4.9763795],
          [118.3168424, 4.9763773],
          [118.3171857, 4.9761021],
          [118.3172179, 4.9760086],
          [118.3172406, 4.9756847],
          [118.3172849, 4.9755677],
          [118.3174152, 4.975395],
          [118.3176148, 4.9752577],
          [118.3178053, 4.9752283],
          [118.3186233, 4.9752791],
          [118.3187456, 4.9752828],
          [118.3188755, 4.975239],
          [118.3189945, 4.9750836],
          [118.319082, 4.9750306],
          [118.3192027, 4.9750172],
          [118.319546, 4.9751321],
          [118.3196302, 4.9751045],
          [118.3196962, 4.9750626],
          [118.3199579, 4.9744599],
          [118.3201656, 4.9742637],
          [118.3205262, 4.974132],
          [118.3208871, 4.9740793],
          [118.3211205, 4.9741247],
          [118.3215309, 4.9745734],
          [118.3216436, 4.9746087],
          [118.3217481, 4.9745603],
          [118.3220646, 4.9741902],
          [118.3221156, 4.97407],
          [118.3223221, 4.9735957],
          [118.3223275, 4.9735342],
          [118.3222953, 4.9734834],
          [118.3220485, 4.9733391],
          [118.3218581, 4.9730372],
          [118.3217817, 4.9729557],
          [118.3216652, 4.9729254],
          [118.3199642, 4.9729445],
          [118.3188381, 4.9729906],
          [118.3186689, 4.9730318],
          [118.3185372, 4.9731547],
          [118.3182407, 4.9736552],
          [118.3182186, 4.973755],
          [118.3182693, 4.9740446],
          [118.3182371, 4.9741007],
          [118.3181791, 4.9741203],
          [118.3176956, 4.9741819],
          [118.3175612, 4.9742156],
          [118.3174646, 4.974261],
          [118.3172071, 4.9744801],
          [118.3171148, 4.9745303],
          [118.3166047, 4.9746542],
          [118.3165071, 4.974726],
          [118.3164526, 4.9748542],
          [118.3165285, 4.9753352],
          [118.3164937, 4.9753886],
          [118.3164159, 4.9754234],
          [118.315866, 4.9754514],
          [118.3157828, 4.9754828],
          [118.3150718, 4.9759884],
          [118.3150077, 4.97605],
          [118.3149058, 4.9763199],
          [118.3148695, 4.9765934],
        ],
      ],
      [
        [
          [118.3234276, 4.9805436],
          [118.3235297, 4.9806252],
          [118.3237163, 4.9806777],
          [118.3235617, 4.9808876],
          [118.3237716, 4.9809488],
          [118.3240165, 4.9808497],
          [118.3243081, 4.9807652],
          [118.3246929, 4.9807535],
          [118.325139, 4.9806806],
          [118.3254743, 4.9806311],
          [118.3254714, 4.9804794],
          [118.3254364, 4.980255],
          [118.3254626, 4.9800625],
          [118.3251886, 4.9798497],
          [118.3250311, 4.9797914],
          [118.3246201, 4.9797593],
          [118.3242906, 4.9797506],
          [118.3239903, 4.9798235],
          [118.3237921, 4.9799022],
          [118.3235151, 4.9800042],
          [118.3232731, 4.98008],
          [118.3231623, 4.9801704],
          [118.3232119, 4.9803366],
          [118.3232789, 4.9804999],
          [118.3234276, 4.9805436],
        ],
      ],
      [
        [
          [118.3654189, 4.9793518],
          [118.3644769, 4.9792458],
          [118.3640413, 4.9794048],
          [118.3638529, 4.9797698],
          [118.3636939, 4.9802408],
          [118.363376, 4.9804822],
          [118.3629227, 4.9806823],
          [118.3626106, 4.9808649],
          [118.3625635, 4.9812122],
          [118.3626872, 4.9815596],
          [118.3628991, 4.981642],
          [118.3631464, 4.9816538],
          [118.3634172, 4.9814948],
          [118.363794, 4.9815419],
          [118.3640825, 4.9816655],
          [118.3642532, 4.9815301],
          [118.3644357, 4.9812946],
          [118.3644887, 4.9811239],
          [118.3647065, 4.9810533],
          [118.3649597, 4.9811298],
          [118.3651599, 4.9814889],
          [118.3655543, 4.9815478],
          [118.365778, 4.9816184],
          [118.3661548, 4.9815596],
          [118.3663197, 4.9814771],
          [118.3663373, 4.9811651],
          [118.3662078, 4.980912],
          [118.366408, 4.980441],
          [118.3665576, 4.9801241],
          [118.3665399, 4.979759],
          [118.3663692, 4.9794882],
          [118.3660925, 4.9791644],
          [118.3658403, 4.9791043],
          [118.3656254, 4.9792191],
          [118.3654189, 4.9793518],
        ],
      ],
      [
        [
          [118.3208469, 4.947736],
          [118.3207887, 4.9479942],
          [118.3210135, 4.9484105],
          [118.3212799, 4.948577],
          [118.3217461, 4.9488767],
          [118.3223123, 4.9490349],
          [118.3227786, 4.9492097],
          [118.3230367, 4.949626],
          [118.3231616, 4.9499841],
          [118.3233947, 4.9497676],
          [118.3232865, 4.9491015],
          [118.3226787, 4.9485603],
          [118.3221791, 4.9482939],
          [118.3217295, 4.9482939],
          [118.3212632, 4.9481523],
          [118.3208469, 4.947736],
        ],
      ],
      [
        [
          [118.3090974, 4.9474717],
          [118.3092211, 4.9478662],
          [118.3092328, 4.948084],
          [118.3092623, 4.9485373],
          [118.3091327, 4.9486963],
          [118.3092093, 4.9488729],
          [118.3092682, 4.9491908],
          [118.3091327, 4.9494204],
          [118.3091092, 4.9496442],
          [118.3092269, 4.9496971],
          [118.3095272, 4.9495676],
          [118.3097862, 4.9492615],
          [118.3099982, 4.9488494],
          [118.3100276, 4.9484961],
          [118.3098569, 4.9482724],
          [118.3098451, 4.9480251],
          [118.3096626, 4.9477425],
          [118.3097156, 4.94756],
          [118.3101218, 4.9472186],
          [118.3100571, 4.9468712],
          [118.3097804, 4.9469007],
          [118.3095154, 4.946777],
          [118.3092446, 4.946777],
          [118.309015, 4.9468006],
          [118.3088737, 4.9469949],
          [118.3090974, 4.9474717],
        ],
      ],
      [
        [
          [118.3066453, 4.9499352],
          [118.306351, 4.949994],
          [118.3061273, 4.9501294],
          [118.3058447, 4.9503767],
          [118.3057505, 4.9503119],
          [118.3054679, 4.9501824],
          [118.3050852, 4.9501883],
          [118.3049321, 4.9501824],
          [118.3047143, 4.9501294],
          [118.3045553, 4.9502177],
          [118.3048026, 4.9503944],
          [118.3050616, 4.9504238],
          [118.3050911, 4.9506122],
          [118.3053501, 4.9507064],
          [118.305568, 4.9506534],
          [118.3059389, 4.9506475],
          [118.3060448, 4.9508595],
          [118.3061449, 4.9509831],
          [118.3063274, 4.9509125],
          [118.3064452, 4.9506828],
          [118.3066571, 4.9503826],
          [118.3068749, 4.9502295],
          [118.3070633, 4.9501824],
          [118.3072694, 4.9502648],
          [118.3074048, 4.9502119],
          [118.3075343, 4.9499764],
          [118.3077168, 4.9496879],
          [118.3080524, 4.9493759],
          [118.3084057, 4.949105],
          [118.3085823, 4.9490344],
          [118.3086647, 4.9487341],
          [118.3086883, 4.9483456],
          [118.3085882, 4.9480806],
          [118.3082997, 4.9480689],
          [118.3082231, 4.948269],
          [118.3081878, 4.948322],
          [118.3079347, 4.9485045],
          [118.307711, 4.9488224],
          [118.3074107, 4.9487165],
          [118.3071281, 4.9486988],
          [118.3067925, 4.94874],
          [118.3066277, 4.9487577],
          [118.3065099, 4.9489049],
          [118.3066277, 4.9491168],
          [118.3067631, 4.9492758],
          [118.3067866, 4.9495996],
          [118.3068749, 4.9499469],
          [118.3066453, 4.9499352],
        ],
      ],
      [
        [
          [118.2949827, 4.951393],
          [118.2949407, 4.9511628],
          [118.2955832, 4.9507987],
          [118.2962258, 4.9503917],
          [118.2958482, 4.950183],
          [118.295286, 4.950356],
          [118.2950206, 4.9502912],
          [118.2946798, 4.9503556],
          [118.2943542, 4.9507531],
          [118.2943391, 4.9511469],
          [118.2946306, 4.9515369],
          [118.2944072, 4.9518852],
          [118.2946003, 4.9520859],
          [118.2948313, 4.9520215],
          [118.2951077, 4.9521465],
          [118.295297, 4.9521692],
          [118.2951872, 4.9518398],
          [118.2949827, 4.951393],
        ],
      ],
      [
        [
          [118.3114378, 4.9671295],
          [118.3117286, 4.9671058],
          [118.3119867, 4.9669892],
          [118.3123864, 4.9666812],
          [118.3130358, 4.9663315],
          [118.3133438, 4.9658569],
          [118.3138934, 4.9658319],
          [118.3143846, 4.9658819],
          [118.3149175, 4.9658985],
          [118.3155586, 4.9657903],
          [118.3161328, 4.9659559],
          [118.3166324, 4.9659392],
          [118.3171153, 4.9657144],
          [118.3176315, 4.9657227],
          [118.3180977, 4.9657561],
          [118.3183558, 4.964349],
          [118.3183142, 4.9639077],
          [118.318922, 4.9633998],
          [118.3206035, 4.9632549],
          [118.3212616, 4.9632166],
          [118.321936, 4.9634581],
          [118.3222441, 4.9632333],
          [118.3223187, 4.9636213],
          [118.322685, 4.9655529],
          [118.323501, 4.9664521],
          [118.3248997, 4.9675511],
          [118.3263751, 4.9666261],
          [118.3266915, 4.9661432],
          [118.3271078, 4.9664596],
          [118.327957, 4.9667766],
          [118.3282436, 4.9669236],
          [118.3286481, 4.9668426],
          [118.3290478, 4.9662265],
          [118.3294474, 4.9657186],
          [118.3295889, 4.9654938],
          [118.3297555, 4.9654522],
          [118.3297971, 4.9657103],
          [118.3299303, 4.9661849],
          [118.329922, 4.9665845],
          [118.3301135, 4.9670591],
          [118.3305316, 4.9671761],
          [118.3311322, 4.9670289],
          [118.331556, 4.9665226],
          [118.331874, 4.9660692],
          [118.332445, 4.9657749],
          [118.3331986, 4.9655511],
          [118.3336107, 4.965557],
          [118.3341002, 4.9658233],
          [118.3341076, 4.9661],
          [118.3341576, 4.9663635],
          [118.3342828, 4.9664709],
          [118.3343357, 4.9665371],
          [118.334845, 4.9664164],
          [118.3351938, 4.9665254],
          [118.3354632, 4.9665901],
          [118.3356516, 4.9667815],
          [118.3359607, 4.9668668],
          [118.3361034, 4.9671818],
          [118.3360166, 4.967223],
          [118.3359047, 4.9675056],
          [118.3358974, 4.9679486],
          [118.3359974, 4.9683813],
          [118.3360504, 4.9685859],
          [118.3362315, 4.9688921],
          [118.3363875, 4.968998],
          [118.336517, 4.9691305],
          [118.3363219, 4.9692671],
          [118.3364337, 4.9692891],
          [118.3365505, 4.9693398],
          [118.3366021, 4.9694713],
          [118.3366123, 4.9695719],
          [118.336554, 4.9695865],
          [118.3364345, 4.9694786],
          [118.3363728, 4.9694558],
          [118.3362756, 4.9694888],
          [118.3361969, 4.9696054],
          [118.3361829, 4.9697222],
          [118.3360972, 4.9699009],
          [118.336071, 4.9701954],
          [118.3359573, 4.9703266],
          [118.3357649, 4.970519],
          [118.3355054, 4.9706968],
          [118.3351351, 4.9709534],
          [118.3347445, 4.9709767],
          [118.3342745, 4.9710938],
          [118.3328924, 4.9707877],
          [118.3326723, 4.9706791],
          [118.332526, 4.9706853],
          [118.3324146, 4.9707595],
          [118.3324208, 4.9709513],
          [118.3324744, 4.9711533],
          [118.3325074, 4.97131],
          [118.3324559, 4.9715429],
          [118.3323281, 4.971578],
          [118.3323054, 4.9715182],
          [118.3323404, 4.9713429],
          [118.3323314, 4.9713217],
          [118.332225, 4.9710708],
          [118.332159, 4.9709389],
          [118.3318993, 4.9710049],
          [118.3316395, 4.9710255],
          [118.3312561, 4.9711739],
          [118.33072, 4.9712976],
          [118.3300274, 4.9712028],
          [118.3296563, 4.9711904],
          [118.3287739, 4.9713759],
          [118.3286246, 4.971462],
          [118.3283215, 4.9715609],
          [118.3281941, 4.9719843],
          [118.3282229, 4.9723471],
          [118.3285445, 4.9725161],
          [118.3290269, 4.9726893],
          [118.3291836, 4.9728666],
          [118.329332, 4.9733408],
          [118.3290393, 4.9737489],
          [118.3286476, 4.9739757],
          [118.3271977, 4.9743451],
          [118.3263783, 4.9742532],
          [118.3256494, 4.9743873],
          [118.3256028, 4.9748188],
          [118.3256131, 4.9748793],
          [118.3256786, 4.9752619],
          [118.3258535, 4.975606],
          [118.3258768, 4.9759791],
          [118.325562, 4.9762357],
          [118.3252121, 4.9764398],
          [118.3245765, 4.9765389],
          [118.3245449, 4.9768345],
          [118.3247315, 4.9769511],
          [118.3255886, 4.9775984],
          [118.3258394, 4.9779774],
          [118.3263058, 4.9783797],
          [118.3267432, 4.978648],
          [118.327023, 4.9787704],
          [118.3271805, 4.9790036],
          [118.3272213, 4.9794993],
          [118.3271572, 4.9798316],
          [118.3272679, 4.9799832],
          [118.3270989, 4.9803797],
          [118.3271105, 4.9806188],
          [118.3273029, 4.9809162],
          [118.3275537, 4.9811378],
          [118.327058, 4.9808346],
          [118.3268714, 4.9805838],
          [118.3267315, 4.9805197],
          [118.3265982, 4.9806063],
          [118.3264463, 4.9806751],
          [118.3263472, 4.9811007],
          [118.3266154, 4.981264],
          [118.3268661, 4.9813806],
          [118.3272451, 4.9814972],
          [118.3275425, 4.9816022],
          [118.3281956, 4.9817363],
          [118.3288253, 4.981783],
          [118.3293676, 4.9817946],
          [118.329595, 4.9817072],
          [118.3298632, 4.9814739],
          [118.3301664, 4.9812407],
          [118.3304988, 4.9809725],
          [118.3308195, 4.9809141],
          [118.3310294, 4.9808558],
          [118.3312393, 4.980955],
          [118.3314667, 4.9810949],
          [118.331595, 4.9814564],
          [118.3314959, 4.9814564],
          [118.3313618, 4.981299],
          [118.331216, 4.9810483],
          [118.3309245, 4.9809666],
          [118.3306212, 4.9810366],
          [118.3303647, 4.9812057],
          [118.3300207, 4.9815905],
          [118.3297116, 4.9818471],
          [118.3293793, 4.9819054],
          [118.3287728, 4.9819171],
          [118.3279973, 4.9818996],
          [118.3273676, 4.9817363],
          [118.3269886, 4.9815964],
          [118.3265356, 4.9814412],
          [118.3261609, 4.9812414],
          [118.325753, 4.9812039],
          [118.3253949, 4.9812372],
          [118.3251576, 4.9810957],
          [118.324783, 4.9809708],
          [118.3245374, 4.9809916],
          [118.3242543, 4.9809916],
          [118.3240545, 4.9811373],
          [118.3237922, 4.9812123],
          [118.3233729, 4.981303],
          [118.323101, 4.9815233],
          [118.3231114, 4.9818605],
          [118.3229678, 4.982129],
          [118.3228367, 4.9823788],
          [118.3227493, 4.9827389],
          [118.3228513, 4.9828367],
          [118.3229553, 4.9829137],
          [118.323126, 4.9830303],
          [118.323382, 4.9832322],
          [118.3234549, 4.9834112],
          [118.3234611, 4.9836027],
          [118.3236381, 4.9837672],
          [118.3238358, 4.9837609],
          [118.3240814, 4.9838171],
          [118.3241626, 4.9839358],
          [118.3241938, 4.9841085],
          [118.3244644, 4.9846518],
          [118.3243874, 4.9848079],
          [118.324402, 4.985041],
          [118.3243541, 4.9852408],
          [118.3244165, 4.9853262],
          [118.3242812, 4.9854927],
          [118.324275, 4.985705],
          [118.3243791, 4.985832],
          [118.3245414, 4.9858674],
          [118.3248994, 4.9858029],
          [118.3251763, 4.9855718],
          [118.3254573, 4.9851846],
          [118.3257383, 4.9852138],
          [118.3262337, 4.9852846],
          [118.3268665, 4.985603],
          [118.3270309, 4.985857],
          [118.3272557, 4.9860422],
          [118.3273077, 4.986215],
          [118.3274972, 4.9866459],
          [118.3277032, 4.986931],
          [118.3276762, 4.9871392],
          [118.3275804, 4.9874576],
          [118.3273868, 4.9876262],
          [118.3269081, 4.9880529],
          [118.3266125, 4.9882278],
          [118.3261879, 4.9884963],
          [118.3260235, 4.9886545],
          [118.3254698, 4.9891707],
          [118.3256571, 4.9892623],
          [118.3258174, 4.9891436],
          [118.3261121, 4.9890994],
          [118.3264784, 4.9893326],
          [118.3270862, 4.9894824],
          [118.3274776, 4.9890162],
          [118.3280271, 4.988858],
          [118.3285631, 4.9889489],
          [118.3305031, 4.9901229],
          [118.3317854, 4.9900979],
          [118.3321671, 4.9897982],
          [118.332412, 4.9896233],
          [118.3326248, 4.9893463],
          [118.3327706, 4.9891947],
          [118.3326306, 4.9888915],
          [118.3324382, 4.988769],
          [118.3322954, 4.9887078],
          [118.3323595, 4.9885504],
          [118.3326773, 4.9884425],
          [118.3328318, 4.9884483],
          [118.332861, 4.9886233],
          [118.3328427, 4.9887006],
          [118.3329892, 4.9887924],
          [118.3331788, 4.988976],
          [118.3332779, 4.9892443],
          [118.3332866, 4.9897778],
          [118.333272, 4.9900169],
          [118.3333274, 4.9902501],
          [118.3331846, 4.9904454],
          [118.3331292, 4.9907749],
          [118.3329423, 4.9911149],
          [118.3336466, 4.9913229],
          [118.3341272, 4.991235],
          [118.334433, 4.9898635],
          [118.3344549, 4.9897652],
          [118.3361377, 4.9893691],
          [118.3368546, 4.9897691],
          [118.3377074, 4.9914015],
          [118.3380703, 4.9914015],
          [118.3387794, 4.9915458],
          [118.3398927, 4.9919086],
          [118.3402473, 4.9918509],
          [118.3409111, 4.9917891],
          [118.3416079, 4.9918303],
          [118.3424366, 4.9917643],
          [118.3433155, 4.9917602],
          [118.3439044, 4.9914046],
          [118.3445109, 4.9912413],
          [118.3454321, 4.991253],
          [118.3459394, 4.9910897],
          [118.3467208, 4.9909206],
          [118.3467258, 4.9902307],
          [118.3459278, 4.9904133],
          [118.3452899, 4.9903253],
          [118.344474, 4.9903586],
          [118.3436581, 4.9903919],
          [118.3424591, 4.9907416],
          [118.3411603, 4.9905917],
          [118.3402611, 4.9905418],
          [118.3400113, 4.990292],
          [118.3400113, 4.9898924],
          [118.3404442, 4.9898257],
          [118.3410104, 4.9897258],
          [118.34151, 4.9894761],
          [118.3416931, 4.9888433],
          [118.3419929, 4.9887101],
          [118.3426589, 4.989143],
          [118.3431918, 4.9890598],
          [118.3431418, 4.9884436],
          [118.3435248, 4.9881439],
          [118.3438579, 4.9885269],
          [118.3444573, 4.9885935],
          [118.344907, 4.9883104],
          [118.3452566, 4.9878941],
          [118.3457729, 4.9881439],
          [118.34603, 4.987884],
          [118.3462034, 4.9877639],
          [118.3461975, 4.9877232],
          [118.3459124, 4.987719],
          [118.3457354, 4.9876274],
          [118.3456064, 4.9874672],
          [118.3452255, 4.9871841],
          [118.3449716, 4.9870632],
          [118.3446552, 4.9870299],
          [118.3443887, 4.986905],
          [118.3441723, 4.986497],
          [118.3441286, 4.9864404],
          [118.3440307, 4.9863138],
          [118.343706, 4.9861973],
          [118.3436977, 4.9858642],
          [118.3437227, 4.9855145],
          [118.3434895, 4.9853647],
          [118.3431898, 4.9853813],
          [118.343015, 4.9851981],
          [118.3431759, 4.9849832],
          [118.343228, 4.9847542],
          [118.3432176, 4.9846023],
          [118.3431197, 4.9844899],
          [118.3431093, 4.9844191],
          [118.343024, 4.9842984],
          [118.3429261, 4.9842234],
          [118.3427659, 4.9841714],
          [118.3426951, 4.9840007],
          [118.3424703, 4.9839632],
          [118.3423454, 4.9839528],
          [118.3421248, 4.9838508],
          [118.341952, 4.9838488],
          [118.3419145, 4.9836677],
          [118.3418063, 4.9835823],
          [118.3417355, 4.983549],
          [118.3417439, 4.9834991],
          [118.3417876, 4.98342],
          [118.3417418, 4.9833013],
          [118.3415773, 4.983114],
          [118.3414712, 4.9829038],
          [118.3415503, 4.9828038],
          [118.3417272, 4.9827435],
          [118.3418021, 4.982629],
          [118.3419978, 4.9825457],
          [118.3420644, 4.9824666],
          [118.3421768, 4.9824625],
          [118.3422413, 4.9825332],
          [118.3423184, 4.9825312],
          [118.3424058, 4.9824833],
          [118.3424828, 4.9823688],
          [118.3424266, 4.9822314],
          [118.3425785, 4.9822169],
          [118.3426701, 4.9821732],
          [118.3427013, 4.9820503],
          [118.3428887, 4.9820462],
          [118.3429719, 4.982092],
          [118.3430635, 4.982246],
          [118.3430927, 4.9823688],
          [118.3431052, 4.9825499],
          [118.3431239, 4.9826082],
          [118.343079, 4.9827366],
          [118.3431872, 4.9829614],
          [118.3434037, 4.9832861],
          [118.3436368, 4.9834942],
          [118.3436451, 4.9838439],
          [118.3442008, 4.9843479],
          [118.3444216, 4.9843862],
          [118.3446306, 4.9845481],
          [118.3448779, 4.9844568],
          [118.3452017, 4.9843833],
          [118.3455267, 4.9842862],
          [118.3451286, 4.9840454],
          [118.3448618, 4.9838268],
          [118.3447735, 4.9837032],
          [118.3446969, 4.9836223],
          [118.3445777, 4.9835266],
          [118.3444143, 4.9832999],
          [118.3442701, 4.9831689],
          [118.3443069, 4.9831189],
          [118.344329, 4.9830468],
          [118.344282, 4.9828973],
          [118.3443262, 4.9827501],
          [118.3443174, 4.9826147],
          [118.3444675, 4.9825029],
          [118.344703, 4.9824499],
          [118.3446971, 4.9822144],
          [118.3446058, 4.9820849],
          [118.3445852, 4.9820113],
          [118.3446794, 4.9819141],
          [118.3447295, 4.9818435],
          [118.3451357, 4.9817199],
          [118.3449238, 4.981555],
          [118.3447648, 4.9814638],
          [118.3447501, 4.981296],
          [118.3447589, 4.9811252],
          [118.3448914, 4.9810987],
          [118.3449974, 4.981084],
          [118.3449915, 4.9808368],
          [118.3451298, 4.9808162],
          [118.3452593, 4.9809839],
          [118.3454448, 4.9811164],
          [118.3456244, 4.9810899],
          [118.3456891, 4.9807867],
          [118.346063, 4.9805718],
          [118.3463161, 4.9803422],
          [118.3463793, 4.9802305],
          [118.3462998, 4.9800627],
          [118.346265, 4.9799977],
          [118.3462086, 4.979892],
          [118.3462881, 4.9797624],
          [118.3465059, 4.9796211],
          [118.3466046, 4.979412],
          [118.3464119, 4.979328],
          [118.3460997, 4.9792448],
          [118.3458541, 4.9791823],
          [118.3455169, 4.9791116],
          [118.3451838, 4.9788951],
          [118.3450381, 4.9786203],
          [118.3452171, 4.9783497],
          [118.3452005, 4.9779418],
          [118.3451297, 4.9776712],
          [118.3453587, 4.9776295],
          [118.3454586, 4.9774131],
          [118.3455294, 4.9772091],
          [118.3454336, 4.9768885],
          [118.3455585, 4.9767095],
          [118.3455086, 4.9764722],
          [118.3455543, 4.976339],
          [118.3452463, 4.9760101],
          [118.3451714, 4.9757104],
          [118.3453056, 4.9758301],
          [118.3453321, 4.9759451],
          [118.3453389, 4.9759893],
          [118.3454211, 4.9760611],
          [118.3455548, 4.9760342],
          [118.3456873, 4.9759783],
          [118.3457683, 4.9759032],
          [118.3457918, 4.9759798],
          [118.345755, 4.9761137],
          [118.3458374, 4.9763168],
          [118.3458728, 4.9763772],
          [118.3461244, 4.9762859],
          [118.3462878, 4.9763095],
          [118.3463578, 4.9765305],
          [118.3471321, 4.9756646],
          [118.3470405, 4.9770301],
          [118.3471821, 4.9777544],
          [118.3477233, 4.9780625],
          [118.3479564, 4.9788868],
          [118.34764, 4.9789867],
          [118.3478731, 4.9795861],
          [118.3483054, 4.9798322],
          [118.3483477, 4.9798775],
          [118.3485309, 4.9804437],
          [118.3484143, 4.9810349],
          [118.3483823, 4.9810396],
          [118.3478482, 4.9811181],
          [118.3474943, 4.981447],
          [118.3475673, 4.9816455],
          [118.3478578, 4.9817403],
          [118.3481923, 4.9816769],
          [118.3486221, 4.9817829],
          [118.3488399, 4.981724],
          [118.3489805, 4.9816237],
          [118.3490615, 4.9815655],
          [118.3493029, 4.9815571],
          [118.3494819, 4.9816612],
          [118.3496256, 4.9817715],
          [118.3496547, 4.9818819],
          [118.349817, 4.9818153],
          [118.3499877, 4.9818819],
          [118.3500856, 4.981961],
          [118.3501522, 4.9820401],
          [118.3501334, 4.9821712],
          [118.3501954, 4.9822784],
          [118.3506314, 4.9823618],
          [118.3509358, 4.98247],
          [118.3511513, 4.9824647],
          [118.3514633, 4.9825832],
          [118.351583, 4.9829015],
          [118.3512875, 4.9829495],
          [118.3509878, 4.9832076],
          [118.3506048, 4.9833991],
          [118.35038, 4.9835823],
          [118.3503467, 4.9837987],
          [118.3503467, 4.9840818],
          [118.3505465, 4.9842816],
          [118.3508879, 4.9844565],
          [118.3510961, 4.9845647],
          [118.3510794, 4.9847978],
          [118.3511543, 4.9850227],
          [118.3515623, 4.9850476],
          [118.3517372, 4.9851475],
          [118.3517455, 4.9854056],
          [118.3516872, 4.9858219],
          [118.3515956, 4.986105],
          [118.3516539, 4.9863798],
          [118.3519432, 4.9864104],
          [118.352168, 4.9863146],
          [118.3523096, 4.9861439],
          [118.3523221, 4.985915],
          [118.3521597, 4.9857068],
          [118.3520182, 4.9855694],
          [118.3520598, 4.9853363],
          [118.3522388, 4.9850824],
          [118.3524761, 4.984895],
          [118.3527342, 4.984895],
          [118.3529465, 4.9847535],
          [118.352959, 4.984587],
          [118.3532546, 4.9845079],
          [118.3534877, 4.9842747],
          [118.3535751, 4.9839917],
          [118.3536709, 4.9836961],
          [118.3535543, 4.9834088],
          [118.3534128, 4.9832382],
          [118.352934, 4.9831507],
          [118.3527675, 4.9829301],
          [118.3525968, 4.9827553],
          [118.3525302, 4.9823764],
          [118.3525011, 4.9820392],
          [118.3526176, 4.9818269],
          [118.3527842, 4.981548],
          [118.3528091, 4.981344],
          [118.3529965, 4.981523],
          [118.3532171, 4.9813315],
          [118.3535127, 4.9808944],
          [118.3537999, 4.9806613],
          [118.353929, 4.9804531],
          [118.3538749, 4.9800451],
          [118.3538166, 4.979862],
          [118.35375, 4.9796247],
          [118.3536625, 4.9794415],
          [118.3537208, 4.9792542],
          [118.3539623, 4.979225],
          [118.353954, 4.9794582],
          [118.3540913, 4.9796164],
          [118.3542703, 4.9799494],
          [118.3545326, 4.980195],
          [118.3545368, 4.9802242],
          [118.3545326, 4.9805572],
          [118.3544202, 4.9809444],
          [118.3541954, 4.9811109],
          [118.3539623, 4.9813315],
          [118.3539231, 4.9813653],
          [118.3537208, 4.9815397],
          [118.3538249, 4.9817853],
          [118.3536792, 4.9821266],
          [118.3537458, 4.9824014],
          [118.3539581, 4.9824722],
          [118.354262, 4.9824888],
          [118.3543203, 4.9826762],
          [118.3543952, 4.9828718],
          [118.354436, 4.9829272],
          [118.3546284, 4.9831882],
          [118.3547907, 4.9834088],
          [118.3547324, 4.9838335],
          [118.3545867, 4.9841623],
          [118.3546408, 4.9845662],
          [118.3546727, 4.9846057],
          [118.3548823, 4.9848659],
          [118.3551404, 4.9850949],
          [118.3551555, 4.985179],
          [118.3550318, 4.9853747],
          [118.3549965, 4.9855116],
          [118.3550775, 4.985622],
          [118.3551776, 4.9856441],
          [118.3552938, 4.9856426],
          [118.3554955, 4.9855116],
          [118.3557516, 4.9855101],
          [118.3560106, 4.9854763],
          [118.3561004, 4.9854248],
          [118.3561107, 4.9851834],
          [118.3560062, 4.9849847],
          [118.3559385, 4.9848743],
          [118.3559297, 4.9846285],
          [118.3557766, 4.9843224],
          [118.3555764, 4.983978],
          [118.3553601, 4.9836718],
          [118.3553292, 4.9834731],
          [118.3553056, 4.9832008],
          [118.3551937, 4.9828903],
          [118.3551614, 4.982668],
          [118.3555161, 4.9825871],
          [118.3557545, 4.9824649],
          [118.3560048, 4.981945],
          [118.3562129, 4.9822323],
          [118.3560714, 4.9824821],
          [118.3559839, 4.9826278],
          [118.3558299, 4.9826944],
          [118.3560173, 4.9832813],
          [118.3562712, 4.9834687],
          [118.3564585, 4.9836602],
          [118.3563961, 4.9838434],
          [118.3567666, 4.9841556],
          [118.356904, 4.9843887],
          [118.3568956, 4.98478],
          [118.3570164, 4.9850423],
          [118.3573161, 4.9852255],
          [118.3575076, 4.9854294],
          [118.3577366, 4.9853462],
          [118.3579197, 4.9851838],
          [118.3583277, 4.9852796],
          [118.3587149, 4.9854086],
          [118.3589022, 4.9856168],
          [118.3590437, 4.9857209],
          [118.3591978, 4.9853961],
          [118.3590854, 4.9850673],
          [118.3588689, 4.9848341],
          [118.3585234, 4.9846926],
          [118.3581487, 4.9846593],
          [118.3578656, 4.9845927],
          [118.3576247, 4.9845265],
          [118.3573185, 4.9844265],
          [118.3571449, 4.984344],
          [118.3570271, 4.9842027],
          [118.3570654, 4.9839614],
          [118.3573038, 4.9837376],
          [118.3574186, 4.983564],
          [118.35761, 4.983355],
          [118.3579632, 4.9834286],
          [118.3581369, 4.9834639],
          [118.3584931, 4.9831106],
          [118.3586785, 4.9831048],
          [118.3589493, 4.9827515],
          [118.3591525, 4.9823423],
          [118.3591377, 4.982101],
          [118.3589611, 4.9819567],
          [118.3587209, 4.981912],
          [118.358403, 4.9819826],
          [118.3582087, 4.9820827],
          [118.3580674, 4.9820592],
          [118.357826, 4.9821828],
          [118.3574551, 4.9821416],
          [118.3573138, 4.9819355],
          [118.357096, 4.981859],
          [118.3569959, 4.9816824],
          [118.3572255, 4.9812703],
          [118.3575611, 4.9811408],
          [118.3579379, 4.98097],
          [118.358191, 4.9807816],
          [118.3584619, 4.9806227],
          [118.3584206, 4.9802753],
          [118.3582264, 4.9799751],
          [118.3579732, 4.9798514],
          [118.3577671, 4.9795924],
          [118.3575905, 4.979457],
          [118.3576965, 4.9791744],
          [118.3578496, 4.9789271],
          [118.3575964, 4.978674],
          [118.3575199, 4.9784385],
          [118.3572137, 4.9782383],
          [118.3575081, 4.9782324],
          [118.3578142, 4.9782972],
          [118.3581086, 4.9781853],
          [118.358456, 4.9783737],
          [118.3588251, 4.9783863],
          [118.3591212, 4.9783148],
          [118.3594333, 4.9781794],
          [118.3597041, 4.9778379],
          [118.3599672, 4.9777622],
          [118.360128, 4.9777673],
          [118.360281, 4.9780263],
          [118.3602869, 4.9782795],
          [118.3603223, 4.978409],
          [118.359969, 4.9785503],
          [118.3599908, 4.9789515],
          [118.3605224, 4.9795512],
          [118.360752, 4.9796984],
          [118.3610935, 4.9795865],
          [118.36118, 4.9795167],
          [118.3610817, 4.9793569],
          [118.3608992, 4.9791508],
          [118.3607815, 4.9788035],
          [118.3607461, 4.9786033],
          [118.3610405, 4.9783796],
          [118.3613938, 4.9780793],
          [118.3617293, 4.978097],
          [118.3616999, 4.9777791],
          [118.3616528, 4.9773964],
          [118.3614173, 4.9770549],
          [118.3612583, 4.9768901],
          [118.3612819, 4.9765957],
          [118.3616999, 4.976266],
          [118.3617941, 4.9759658],
          [118.3617234, 4.9757185],
          [118.3616705, 4.975536],
          [118.3622474, 4.9755772],
          [118.3628126, 4.9757126],
          [118.36326, 4.9758009],
          [118.364202, 4.9765015],
          [118.3642061, 4.9764906],
          [118.3644316, 4.9765663],
          [118.3647024, 4.976896],
          [118.3646671, 4.9770432],
          [118.3648908, 4.9771903],
          [118.3652088, 4.9774788],
          [118.3653158, 4.977587],
          [118.3657092, 4.9779851],
          [118.366086, 4.9781559],
          [118.3665917, 4.9782138],
          [118.3670156, 4.9780489],
          [118.3673453, 4.9779429],
          [118.3676632, 4.9782491],
          [118.3681578, 4.9784964],
          [118.3684521, 4.9784022],
          [118.3698298, 4.9784846],
          [118.3702248, 4.9786151],
          [118.37033, 4.9787552],
          [118.3707671, 4.9786262],
          [118.3711855, 4.9784951],
          [118.371429, 4.9784201],
          [118.3717163, 4.9783785],
          [118.3718869, 4.978416],
          [118.3719586, 4.9785097],
          [118.3720086, 4.9787569],
          [118.3722736, 4.9788364],
          [118.372328, 4.9790101],
          [118.3725326, 4.9792691],
          [118.3725551, 4.9795063],
          [118.3728965, 4.9796728],
          [118.3730783, 4.9795938],
          [118.3733406, 4.979319],
          [118.3734197, 4.9790651],
          [118.3734696, 4.9788861],
          [118.3738526, 4.9789111],
          [118.3741191, 4.9790984],
          [118.3745478, 4.979498],
          [118.3748601, 4.9799227],
          [118.3751057, 4.980085],
          [118.3754179, 4.9800226],
          [118.3758509, 4.9798269],
          [118.3760091, 4.9795646],
          [118.3760465, 4.9792774],
          [118.3761714, 4.9791983],
          [118.3763546, 4.9791941],
          [118.3765627, 4.9792899],
          [118.3766169, 4.979523],
          [118.3767043, 4.9798436],
          [118.3770789, 4.9798228],
          [118.3774536, 4.9798727],
          [118.3778657, 4.9801475],
          [118.3780448, 4.9804639],
          [118.3780031, 4.9808011],
          [118.3777492, 4.9809468],
          [118.3777325, 4.9812049],
          [118.3775119, 4.9815213],
          [118.3776285, 4.9816919],
          [118.3778949, 4.9817585],
          [118.3782321, 4.9816794],
          [118.3788399, 4.9812548],
          [118.3790855, 4.981005],
          [118.3794144, 4.9809551],
          [118.3800222, 4.9812007],
          [118.3803885, 4.9813214],
          [118.38063, 4.9811591],
          [118.3809297, 4.9810925],
          [118.381396, 4.9812174],
          [118.3816624, 4.9814463],
          [118.3822119, 4.9814796],
          [118.3829071, 4.9815213],
          [118.3834483, 4.9814089],
          [118.3839312, 4.9812423],
          [118.3843142, 4.980647],
          [118.3849262, 4.9800892],
          [118.3853217, 4.9796271],
          [118.385892, 4.9793815],
          [118.385892, 4.9789485],
          [118.3854549, 4.9785072],
          [118.3852592, 4.9780452],
          [118.3850302, 4.977683],
          [118.384589, 4.9771168],
          [118.3837348, 4.9760383],
          [118.3837584, 4.975685],
          [118.3833463, 4.9752023],
          [118.3821335, 4.9746842],
          [118.3813681, 4.9743545],
          [118.3802333, 4.9741509],
          [118.3790441, 4.9731972],
          [118.3781374, 4.9724789],
          [118.3771837, 4.9722317],
          [118.3762417, 4.9721257],
          [118.3750053, 4.9714074],
          [118.3741222, 4.9709011],
          [118.3731214, 4.9710189],
          [118.3724502, 4.9713015],
          [118.3715907, 4.9709953],
          [118.3707076, 4.9703242],
          [118.3699069, 4.9700887],
          [118.3692357, 4.969865],
          [118.3685175, 4.9693704],
          [118.3666806, 4.9688288],
          [118.3653501, 4.9684402],
          [118.3626655, 4.9675218],
          [118.3611347, 4.9670273],
          [118.3609346, 4.9670626],
          [118.3596865, 4.9663325],
          [118.3589447, 4.9658027],
          [118.3577201, 4.9655319],
          [118.3563895, 4.9651197],
          [118.3549177, 4.9644604],
          [118.3541288, 4.963695],
          [118.3531044, 4.9628237],
          [118.3519269, 4.9624822],
          [118.3512675, 4.9625175],
          [118.3504786, 4.9621054],
          [118.3495955, 4.9612459],
          [118.3488302, 4.9606571],
          [118.3478058, 4.9600684],
          [118.3471346, 4.9593737],
          [118.3459336, 4.9589263],
          [118.3447326, 4.9582904],
          [118.343614, 4.9578077],
          [118.3430253, 4.9578077],
          [118.3423659, 4.957478],
          [118.3416712, 4.9571836],
          [118.341471, 4.9569834],
          [118.3407998, 4.9568775],
          [118.3403877, 4.9568304],
          [118.3391161, 4.9558884],
          [118.3378326, 4.9557942],
          [118.3368789, 4.955335],
          [118.335984, 4.9552525],
          [118.3353835, 4.9551466],
          [118.3337468, 4.9545343],
          [118.3329814, 4.9543812],
          [118.3320512, 4.9539456],
          [118.3315685, 4.9535805],
          [118.3304028, 4.9529918],
          [118.3292606, 4.9524855],
          [118.3278948, 4.9517319],
          [118.3270352, 4.951202],
          [118.3260461, 4.9508724],
          [118.3250453, 4.9509548],
          [118.3244448, 4.9508959],
          [118.3236676, 4.9515435],
          [118.3235382, 4.9519449],
          [118.3228435, 4.9525807],
          [118.3223843, 4.952722],
          [118.3219604, 4.9525925],
          [118.3214776, 4.9522392],
          [118.3208653, 4.9514857],
          [118.3203237, 4.9516976],
          [118.3206355, 4.9522223],
          [118.3212242, 4.9530466],
          [118.3211182, 4.9534116],
          [118.3207885, 4.9535058],
          [118.3205531, 4.9534116],
          [118.3199996, 4.9534822],
          [118.3196817, 4.9535529],
          [118.3191048, 4.9531879],
          [118.3192814, 4.9527993],
          [118.3181226, 4.9522836],
          [118.3180696, 4.9520011],
          [118.3180696, 4.9513593],
          [118.3181285, 4.9508177],
          [118.3179391, 4.9506563],
          [118.317681, 4.9508678],
          [118.3174396, 4.951121],
          [118.3170334, 4.9512505],
          [118.3165624, 4.9511857],
          [118.3162033, 4.9511327],
          [118.3160855, 4.950962],
          [118.3162268, 4.9507265],
          [118.3164623, 4.9504792],
          [118.3164741, 4.9502967],
          [118.3162916, 4.9501142],
          [118.3160031, 4.9501378],
          [118.3156322, 4.9503203],
          [118.3154556, 4.950491],
          [118.3150964, 4.9506971],
          [118.3148256, 4.9507913],
          [118.3144194, 4.9506088],
          [118.3140897, 4.950232],
          [118.3138601, 4.9499023],
          [118.3136482, 4.9496256],
          [118.3140897, 4.9492605],
          [118.3142487, 4.9488543],
          [118.3143193, 4.9487425],
          [118.3137659, 4.9485776],
          [118.3132007, 4.9489838],
          [118.3126591, 4.9488484],
          [118.3117878, 4.9490427],
          [118.311358, 4.9493312],
          [118.310781, 4.9497315],
          [118.3103336, 4.9501731],
          [118.3100039, 4.9503438],
          [118.3095436, 4.9504449],
          [118.3093854, 4.9508612],
          [118.3094437, 4.9513025],
          [118.3092772, 4.9513857],
          [118.3086694, 4.9513857],
          [118.3080949, 4.9515606],
          [118.3079617, 4.951902],
          [118.3073872, 4.9521517],
          [118.3067044, 4.952593],
          [118.3061716, 4.952568],
          [118.3051475, 4.9523599],
          [118.3044314, 4.9523516],
          [118.3038153, 4.9525764],
          [118.3037404, 4.9527845],
          [118.3041234, 4.9531009],
          [118.3045314, 4.9536338],
          [118.3045147, 4.9539418],
          [118.3040734, 4.9544414],
          [118.3040068, 4.9549326],
          [118.3042982, 4.955199],
          [118.3049643, 4.954458],
          [118.3051308, 4.9542166],
          [118.3054722, 4.9540251],
          [118.3057386, 4.9538919],
          [118.3062881, 4.9539418],
          [118.3059634, 4.9545912],
          [118.3057106, 4.9547991],
          [118.305647, 4.9549742],
          [118.3057607, 4.9550287],
          [118.3059255, 4.9553643],
          [118.3056606, 4.955538],
          [118.3053868, 4.9559943],
          [118.3052455, 4.9566183],
          [118.3049099, 4.9567802],
          [118.3046244, 4.9570216],
          [118.3044566, 4.9575162],
          [118.3042741, 4.9577634],
          [118.3038797, 4.9580284],
          [118.3035534, 4.9584755],
          [118.3037233, 4.9587312],
          [118.3042895, 4.9592141],
          [118.3049639, 4.9597137],
          [118.305147, 4.9601966],
          [118.3051541, 4.9607136],
          [118.3053131, 4.9611757],
          [118.3054867, 4.9617704],
          [118.3051894, 4.9621619],
          [118.3049569, 4.9624062],
          [118.305057, 4.9627535],
          [118.3052807, 4.9630597],
          [118.3058317, 4.9633745],
          [118.3066601, 4.9640989],
          [118.3072834, 4.9646034],
          [118.3077383, 4.964765],
          [118.308163, 4.965148],
          [118.3090663, 4.9657807],
          [118.3095367, 4.9660472],
          [118.3100143, 4.9664684],
          [118.3107051, 4.9668298],
          [118.3114378, 4.9671295],
        ],
      ],
      [
        [
          [118.3256736, 4.9804859],
          [118.325729, 4.9805967],
          [118.3258106, 4.9806434],
          [118.3259039, 4.9807833],
          [118.3260905, 4.9808912],
          [118.3262829, 4.9810311],
          [118.3263325, 4.9808562],
          [118.3263996, 4.9806171],
          [118.32642, 4.9804684],
          [118.3262421, 4.9803547],
          [118.3261255, 4.9803081],
          [118.3260643, 4.980244],
          [118.3259856, 4.9801273],
          [118.3259447, 4.9802177],
          [118.3258456, 4.9802469],
          [118.3257319, 4.9802993],
          [118.3256736, 4.9804859],
        ],
      ],
      [
        [
          [118.3678597, 4.9803985],
          [118.3679421, 4.9807576],
          [118.3679892, 4.9808283],
          [118.3680952, 4.9809401],
          [118.3684072, 4.9811403],
          [118.3686309, 4.9812816],
          [118.368625, 4.9815936],
          [118.3687015, 4.9818586],
          [118.3691019, 4.981888],
          [118.3692137, 4.9820764],
          [118.3694021, 4.9819881],
          [118.3697436, 4.9817997],
          [118.3698672, 4.9815348],
          [118.3697083, 4.9811285],
          [118.3691667, 4.9806576],
          [118.3687192, 4.9804044],
          [118.3681187, 4.9803279],
          [118.3680657, 4.98011],
          [118.3680068, 4.9801395],
          [118.3678597, 4.9803985],
        ],
      ],
      [
        [
          [118.3174484, 4.9844804],
          [118.3174072, 4.9845805],
          [118.3175838, 4.9848748],
          [118.317831, 4.9849867],
          [118.3181019, 4.9852516],
          [118.3184433, 4.985334],
          [118.3187142, 4.985334],
          [118.3187495, 4.9848159],
          [118.3184669, 4.9845628],
          [118.3177722, 4.9842272],
          [118.3174484, 4.9844804],
        ],
      ],
      [
        [
          [118.3129575, 4.9883822],
          [118.3128044, 4.9886177],
          [118.3128986, 4.9888532],
          [118.3131223, 4.9889591],
          [118.313452, 4.9891004],
          [118.3140172, 4.9894301],
          [118.3147944, 4.9897716],
          [118.3154302, 4.989595],
          [118.3157481, 4.9890062],
          [118.315595, 4.9884411],
          [118.314971, 4.987711],
          [118.3142409, 4.9873813],
          [118.3132754, 4.9875109],
          [118.3129575, 4.9883822],
        ],
      ],
      [
        [
          [118.3291711, 4.9916134],
          [118.32923, 4.99179],
          [118.3295597, 4.9919549],
          [118.3300306, 4.992208],
          [118.3307489, 4.992055],
          [118.3311159, 4.9918629],
          [118.3312107, 4.9917725],
          [118.331422, 4.9917171],
          [118.3316436, 4.991739],
          [118.3316766, 4.991663],
          [118.3317262, 4.9915074],
          [118.3315496, 4.9914132],
          [118.3309609, 4.9913014],
          [118.3304781, 4.9913897],
          [118.3300365, 4.9913426],
          [118.3298305, 4.9910718],
          [118.3294537, 4.9910011],
          [118.3291946, 4.9912013],
          [118.3291711, 4.9916134],
        ],
      ],
      [
        [
          [115.6343192, 5.7262052],
          [115.6319078, 5.7289685],
          [115.6305351, 5.7288057],
          [115.6297486, 5.7308443],
          [115.6302458, 5.732086],
          [115.6317093, 5.7351389],
          [115.6315576, 5.7358783],
          [115.6326026, 5.7372297],
          [115.6336622, 5.7374577],
          [115.6343061, 5.738513],
          [115.6371394, 5.7394645],
          [115.6378648, 5.7393132],
          [115.638388, 5.7395643],
          [115.6390299, 5.7394406],
          [115.6394597, 5.7389471],
          [115.6403867, 5.7387204],
          [115.6418816, 5.7377739],
          [115.6433211, 5.7369981],
          [115.6434909, 5.73639],
          [115.6439369, 5.7350865],
          [115.6445584, 5.7346243],
          [115.6452473, 5.734341],
          [115.6462028, 5.7342355],
          [115.6479454, 5.7342025],
          [115.6490977, 5.7345702],
          [115.6518605, 5.7351435],
          [115.6541963, 5.7354708],
          [115.6575771, 5.7346454],
          [115.6581595, 5.7341576],
          [115.6584723, 5.732932],
          [115.6595921, 5.7320237],
          [115.660182, 5.7307791],
          [115.6602414, 5.7299145],
          [115.6598296, 5.729382],
          [115.6597101, 5.7279775],
          [115.6598642, 5.7270803],
          [115.6595881, 5.7264486],
          [115.6597775, 5.7256772],
          [115.6603621, 5.7247987],
          [115.6624411, 5.722791],
          [115.6646196, 5.7204829],
          [115.6671814, 5.7181668],
          [115.6690859, 5.7160419],
          [115.6705267, 5.7135836],
          [115.6703294, 5.7120208],
          [115.6701047, 5.7103686],
          [115.6689898, 5.7099617],
          [115.6681172, 5.7094096],
          [115.6677854, 5.7084299],
          [115.6676221, 5.7077074],
          [115.6678799, 5.7059045],
          [115.6676838, 5.7054335],
          [115.6674059, 5.7052987],
          [115.6669187, 5.7052872],
          [115.6668211, 5.7055721],
          [115.6671881, 5.7055916],
          [115.6673078, 5.7057452],
          [115.6669605, 5.7057872],
          [115.6662049, 5.7056083],
          [115.6653031, 5.7055635],
          [115.6644102, 5.705657],
          [115.663506, 5.705348],
          [115.6626758, 5.7050359],
          [115.6620703, 5.7051709],
          [115.6617994, 5.7055126],
          [115.6607141, 5.7058519],
          [115.6602581, 5.706124],
          [115.6597699, 5.7060879],
          [115.6592618, 5.7061369],
          [115.6588885, 5.7060293],
          [115.6584605, 5.7056184],
          [115.6580305, 5.7056293],
          [115.6572064, 5.7063313],
          [115.6563599, 5.707627],
          [115.6555584, 5.7090568],
          [115.6549167, 5.7109624],
          [115.6546833, 5.712072],
          [115.6545172, 5.7131146],
          [115.6546408, 5.7146307],
          [115.6538418, 5.7162978],
          [115.6534698, 5.717209],
          [115.6528312, 5.7181475],
          [115.6522375, 5.7191084],
          [115.6515814, 5.7199706],
          [115.6506106, 5.7212353],
          [115.6501357, 5.7216976],
          [115.6482974, 5.7228965],
          [115.6439347, 5.7233916],
          [115.6417138, 5.7234171],
          [115.6388124, 5.7238717],
          [115.6359647, 5.7251335],
          [115.6343192, 5.7262052],
        ],
      ],
      [
        [
          [117.9596182, 5.7371546],
          [117.9608462, 5.7374954],
          [117.9621508, 5.7370929],
          [117.9631133, 5.7363174],
          [117.9635017, 5.7354158],
          [117.9629191, 5.7343854],
          [117.9609127, 5.7334193],
          [117.9591651, 5.7333549],
          [117.9576764, 5.7340633],
          [117.9571586, 5.7347718],
          [117.9577411, 5.7353514],
          [117.9578706, 5.736253],
          [117.9583884, 5.7366394],
          [117.9596182, 5.7371546],
        ],
      ],
      [
        [
          [115.5737964, 5.7437345],
          [115.5763713, 5.7444177],
          [115.5786029, 5.7418557],
          [115.5737964, 5.7437345],
        ],
      ],
      [
        [
          [118.141992, 5.7495356],
          [118.1401613, 5.7468944],
          [118.1396121, 5.7450729],
          [118.1370491, 5.7433425],
          [118.1342116, 5.7423407],
          [118.1325639, 5.7413388],
          [118.1322893, 5.7428871],
          [118.1309163, 5.74398],
          [118.1302756, 5.7457104],
          [118.130184, 5.7478962],
          [118.1310079, 5.7493534],
          [118.1318317, 5.7504463],
          [118.1320147, 5.7518124],
          [118.1328385, 5.751266],
          [118.1338454, 5.7508106],
          [118.1351269, 5.7506284],
          [118.1363169, 5.751357],
          [118.1383306, 5.7510838],
          [118.141992, 5.7495356],
        ],
      ],
      [
        [
          [115.6754994, 5.7474648],
          [115.6758824, 5.7473985],
          [115.6760708, 5.747305],
          [115.6762049, 5.7471791],
          [115.6763256, 5.7469699],
          [115.676311, 5.7467194],
          [115.6762644, 5.7466176],
          [115.6761695, 5.74656],
          [115.6760322, 5.7466366],
          [115.6758491, 5.7468188],
          [115.6755182, 5.7469596],
          [115.6752717, 5.7470648],
          [115.6751332, 5.7471335],
          [115.674717, 5.7473157],
          [115.6743507, 5.7476304],
          [115.6742508, 5.7480611],
          [115.6739678, 5.7485581],
          [115.6736349, 5.7488893],
          [115.6734184, 5.7492041],
          [115.6729523, 5.7503967],
          [115.6729066, 5.7508356],
          [115.6727937, 5.7513613],
          [115.6727771, 5.7515794],
          [115.6728563, 5.7517441],
          [115.6729537, 5.7518312],
          [115.6731648, 5.7521067],
          [115.6734717, 5.7522236],
          [115.6738189, 5.7525222],
          [115.6738484, 5.7524461],
          [115.6735423, 5.7521299],
          [115.6733245, 5.7519542],
          [115.6732146, 5.7517726],
          [115.6730873, 5.7512584],
          [115.6731008, 5.7507185],
          [115.6731959, 5.7503189],
          [115.6733363, 5.7498869],
          [115.673629, 5.7492974],
          [115.6742342, 5.7485415],
          [115.674809, 5.7479238],
          [115.6751492, 5.747638],
          [115.6754994, 5.7474648],
        ],
      ],
      [
        [
          [117.9404406, 5.7688536],
          [117.940829, 5.7696907],
          [117.9418646, 5.7698195],
          [117.9436769, 5.7688536],
          [117.9449714, 5.767308],
          [117.9454244, 5.7647965],
          [117.944583, 5.7620918],
          [117.943418, 5.7604175],
          [117.9421882, 5.7599023],
          [117.9412821, 5.7608039],
          [117.9392756, 5.7610615],
          [117.9383047, 5.7620918],
          [117.9373986, 5.7640238],
          [117.9366866, 5.7649897],
          [117.9358452, 5.7653117],
          [117.9354568, 5.7666641],
          [117.9359746, 5.7678232],
          [117.9366219, 5.7687892],
          [117.9383047, 5.7691112],
          [117.9397934, 5.7691112],
          [117.9404406, 5.7688536],
        ],
      ],
      [
        [
          [118.0840171, 5.7728405],
          [118.0843384, 5.773272],
          [118.0848364, 5.7735187],
          [118.0854548, 5.7731641],
          [118.0856827, 5.772355],
          [118.0856697, 5.7721652],
          [118.0854106, 5.771523],
          [118.085253, 5.7715719],
          [118.0851918, 5.7714291],
          [118.0850291, 5.7713122],
          [118.0846065, 5.77152],
          [118.0841406, 5.7719824],
          [118.0840171, 5.7728405],
        ],
      ],
      [
        [
          [118.3450915, 5.7986804],
          [118.3457776, 5.7985708],
          [118.3464806, 5.7979557],
          [118.3472683, 5.797172],
          [118.3477595, 5.79654],
          [118.3478273, 5.7958828],
          [118.3478442, 5.7950485],
          [118.3478273, 5.794248],
          [118.347768, 5.7932958],
          [118.3478696, 5.7923689],
          [118.3479712, 5.7919644],
          [118.3479797, 5.7915684],
          [118.3480983, 5.7910544],
          [118.3485303, 5.7905488],
          [118.3487589, 5.7903213],
          [118.348742, 5.7900011],
          [118.3483609, 5.7897061],
          [118.3473445, 5.7893354],
          [118.3465483, 5.7892342],
          [118.3458792, 5.7893522],
          [118.3456675, 5.7898662],
          [118.3456082, 5.7903634],
          [118.3453541, 5.7909785],
          [118.3448713, 5.7923436],
          [118.3444309, 5.7935823],
          [118.3439904, 5.7947958],
          [118.3436855, 5.7960344],
          [118.3436008, 5.7969529],
          [118.343567, 5.7977534],
          [118.3435754, 5.7985455],
          [118.3433383, 5.7991017],
          [118.3433722, 5.7997758],
          [118.3435331, 5.8010313],
          [118.3437194, 5.8018066],
          [118.3439142, 5.8022532],
          [118.3440836, 5.8022447],
          [118.3442784, 5.8019498],
          [118.3443885, 5.8011324],
          [118.3447273, 5.8001634],
          [118.3450068, 5.799641],
          [118.3448205, 5.7991775],
          [118.3448628, 5.7987646],
          [118.3450915, 5.7986804],
        ],
      ],
      [
        [
          [116.0068036, 5.8349826],
          [116.0070704, 5.8350416],
          [116.007278, 5.8346287],
          [116.0070408, 5.8341862],
          [116.006507, 5.8343337],
          [116.0064477, 5.8346582],
          [116.0068036, 5.8349826],
        ],
      ],
      [
        [
          [115.9887919, 5.8424493],
          [115.9881162, 5.8429705],
          [115.9884486, 5.8439931],
          [115.9895379, 5.8434621],
          [115.990212, 5.8435756],
          [115.9899935, 5.8426627],
          [115.9887919, 5.8424493],
        ],
      ],
      [
        [
          [116.0128245, 5.8363442],
          [116.01164, 5.8370971],
          [116.0113669, 5.8378143],
          [116.0119662, 5.8394056],
          [116.0127684, 5.839373],
          [116.0129775, 5.8399894],
          [116.0133176, 5.8399258],
          [116.0135392, 5.8388297],
          [116.0139247, 5.8378812],
          [116.0144514, 5.8376871],
          [116.0148891, 5.8369621],
          [116.014012, 5.8362262],
          [116.0128245, 5.8363442],
        ],
      ],
      [
        [
          [118.1543605, 5.8100626],
          [118.1535512, 5.8131225],
          [118.1536808, 5.8173901],
          [118.1543947, 5.8177046],
          [118.1553641, 5.8165524],
          [118.1561087, 5.8143784],
          [118.1570476, 5.8133476],
          [118.1574377, 5.8116274],
          [118.1571125, 5.8102237],
          [118.1558196, 5.809599],
          [118.1543605, 5.8100626],
        ],
      ],
      [
        [
          [118.1885669, 5.8158994],
          [118.1863046, 5.816629],
          [118.1840698, 5.8162367],
          [118.1815391, 5.8145037],
          [118.1792714, 5.813294],
          [118.1772337, 5.8123785],
          [118.1762742, 5.8116331],
          [118.1724024, 5.8135882],
          [118.1706934, 5.8138825],
          [118.16859, 5.8138171],
          [118.1670125, 5.8148307],
          [118.1653692, 5.8142749],
          [118.1623784, 5.8151904],
          [118.1594205, 5.8168906],
          [118.1578758, 5.8172175],
          [118.1552137, 5.8191793],
          [118.1526173, 5.8212719],
          [118.1520699, 5.8234167],
          [118.1540305, 5.8256205],
          [118.1551151, 5.8272553],
          [118.1564297, 5.8289555],
          [118.1572842, 5.8325193],
          [118.1561011, 5.8330424],
          [118.1569928, 5.8353087],
          [118.1584674, 5.8383391],
          [118.1610309, 5.8412162],
          [118.166373, 5.8462387],
          [118.170792, 5.8494553],
          [118.1754918, 5.8516459],
          [118.1792385, 5.8522017],
          [118.182742, 5.8523856],
          [118.1889996, 5.8523978],
          [118.192812, 5.8517766],
          [118.196, 5.8511881],
          [118.2020144, 5.8500438],
          [118.2066029, 5.8488103],
          [118.209475, 5.8483437],
          [118.2110525, 5.8470032],
          [118.2126629, 5.847134],
          [118.2161796, 5.8463493],
          [118.2211094, 5.8448454],
          [118.2248544, 5.8428848],
          [118.2282547, 5.8394437],
          [118.2300489, 5.8373582],
          [118.2307391, 5.8345464],
          [118.227703, 5.8310494],
          [118.2223734, 5.8258358],
          [118.2200665, 5.8238206],
          [118.2164091, 5.8213576],
          [118.2116263, 5.8195663],
          [118.2078001, 5.8191185],
          [118.2063934, 5.8192304],
          [118.2056619, 5.8197342],
          [118.2049305, 5.8213576],
          [118.204424, 5.8233728],
          [118.2040302, 5.8249401],
          [118.2036363, 5.8254999],
          [118.202736, 5.8238206],
          [118.2023984, 5.8222532],
          [118.2022859, 5.8207978],
          [118.2022296, 5.820238],
          [118.1973343, 5.820294],
          [118.1944084, 5.8186147],
          [118.193058, 5.8183348],
          [118.1919326, 5.8175511],
          [118.1910323, 5.8170473],
          [118.1895131, 5.8162636],
          [118.1885669, 5.8158994],
        ],
      ],
      [
        [
          [118.0933461, 5.7664166],
          [118.0917919, 5.7680089],
          [118.0927798, 5.7688619],
          [118.093069, 5.7693354],
          [118.0919947, 5.7704262],
          [118.0907177, 5.7709256],
          [118.0900731, 5.7709336],
          [118.089286, 5.7706439],
          [118.0890149, 5.7709376],
          [118.0892328, 5.7711823],
          [118.0897247, 5.7712333],
          [118.0901424, 5.771499],
          [118.0903422, 5.7727016],
          [118.0908221, 5.773317],
          [118.0909275, 5.7737754],
          [118.090555, 5.7751609],
          [118.0901012, 5.7752208],
          [118.0898934, 5.7753786],
          [118.0899828, 5.7755285],
          [118.0903261, 5.7755704],
          [118.0912377, 5.7755085],
          [118.0917959, 5.7762776],
          [118.0922096, 5.7784092],
          [118.0922588, 5.7791274],
          [118.0924716, 5.7796508],
          [118.0924294, 5.7800614],
          [118.0930148, 5.7806657],
          [118.0932818, 5.781252],
          [118.0936272, 5.7824727],
          [118.0939505, 5.7829441],
          [118.0940348, 5.7833217],
          [118.0945288, 5.78308],
          [118.0949544, 5.7831519],
          [118.0954604, 5.7825665],
          [118.0960508, 5.7829941],
          [118.0969684, 5.7826884],
          [118.0973901, 5.7829791],
          [118.0978118, 5.7832697],
          [118.0980587, 5.7837932],
          [118.0979242, 5.7839819],
          [118.0982465, 5.7840519],
          [118.0985617, 5.7842786],
          [118.0992565, 5.7841807],
          [118.0997545, 5.7842227],
          [118.0997936, 5.7838031],
          [118.1007333, 5.7831619],
          [118.1026118, 5.7821111],
          [118.1034441, 5.7817135],
          [118.1044942, 5.7810363],
          [118.1060062, 5.7805708],
          [118.1067702, 5.7806577],
          [118.1075513, 5.7806108],
          [118.1079851, 5.7810083],
          [118.1086878, 5.7804679],
          [118.1091246, 5.780415],
          [118.109486, 5.7809783],
          [118.1097189, 5.7806687],
          [118.1102711, 5.780397],
          [118.1128553, 5.7798376],
          [118.1136706, 5.7802691],
          [118.1146223, 5.779512],
          [118.1149296, 5.7794581],
          [118.1157267, 5.7795899],
          [118.1177487, 5.7802132],
          [118.1181102, 5.7805328],
          [118.1193009, 5.7810483],
          [118.12059, 5.7802252],
          [118.1208711, 5.7806048],
          [118.1211783, 5.7806767],
          [118.1213189, 5.7805069],
          [118.1209514, 5.7798037],
          [118.1206703, 5.77775],
          [118.1193631, 5.7753567],
          [118.1180961, 5.7738663],
          [118.1164857, 5.7740082],
          [118.1138513, 5.7735247],
          [118.1084519, 5.7704242],
          [118.1024983, 5.7700366],
          [118.1001932, 5.7708137],
          [118.0974664, 5.7701385],
          [118.0969483, 5.7693773],
          [118.0967588, 5.7690216],
          [118.0966311, 5.768782],
          [118.0950087, 5.7672038],
          [118.0933461, 5.7664166],
        ],
      ],
      [
        [
          [117.999497687452674, 5.742424423974797],
          [117.9993034, 5.7424975],
          [117.9921363, 5.7433459],
          [117.9813273, 5.7435391],
          [117.9740781, 5.7416715],
          [117.9671906, 5.744513],
          [117.9639456, 5.7475526],
          [117.9618097, 5.7510301],
          [117.9581204, 5.7582428],
          [117.9522561, 5.7721818],
          [117.9438447, 5.7776471],
          [117.9270219, 5.7771347],
          [117.9236112, 5.7775989],
          [117.9203749, 5.7794664],
          [117.9153603, 5.7830899],
          [117.9144585, 5.7850777],
          [117.9155879, 5.786848],
          [117.9176851, 5.7881513],
          [117.9195157, 5.7896311],
          [117.9201107, 5.7909288],
          [117.9198361, 5.7923176],
          [117.9193784, 5.795596],
          [117.9199734, 5.7970986],
          [117.9264097, 5.7945309],
          [117.9328798, 5.7909971],
          [117.9409349, 5.7888115],
          [117.9445882, 5.7896663],
          [117.9451067, 5.790038],
          [117.9458348, 5.7900835],
          [117.9461655, 5.7899626],
          [117.9466106, 5.7900925],
          [117.9471996, 5.7901001],
          [117.9480382, 5.7899107],
          [117.9481736, 5.7898801],
          [117.9486749, 5.7896435],
          [117.9499939, 5.7895753],
          [117.9500871, 5.7897677],
          [117.9522436, 5.789655],
          [117.9542892, 5.7893479],
          [117.9630708, 5.789773],
          [117.9678767, 5.7894204],
          [117.9728282, 5.7916098],
          [117.9766469, 5.7912557],
          [117.9811178, 5.7925572],
          [117.9825425, 5.7907545],
          [117.9823137, 5.7894341],
          [117.9782404, 5.7892064],
          [117.9807118, 5.788068],
          [117.9809407, 5.7856547],
          [117.984419, 5.784061],
          [117.9872774, 5.7813734],
          [117.9892246, 5.7846074],
          [117.992173, 5.7863833],
          [117.9919706, 5.7828771],
          [117.9947021, 5.7796054],
          [117.9948997, 5.7775041],
          [117.9970915, 5.7749611],
          [117.999497687452674, 5.775233477195946],
          [118.000292035967746, 5.775323396383621],
          [118.000502312547326, 5.775347199410711],
          [118.0047347, 5.7758263],
          [118.00565, 5.7766459],
          [118.007618, 5.7747789],
          [118.0108217, 5.7757352],
          [118.005966, 5.7709207],
          [118.0029784, 5.7660428],
          [118.0066754, 5.7705565],
          [118.007934, 5.7717404],
          [118.0091468, 5.7722868],
          [118.0106114, 5.7724689],
          [118.0131057, 5.7720591],
          [118.0147567, 5.7730536],
          [118.0164575, 5.7739124],
          [118.0178231, 5.7720291],
          [118.0180748, 5.7703898],
          [118.0180472, 5.7686124],
          [118.0170335, 5.7612708],
          [118.0154043, 5.7544534],
          [118.0115992, 5.7526516],
          [118.0077712, 5.7498534],
          [118.0050087, 5.7466407],
          [118.004368, 5.7440451],
          [118.0034984, 5.7424968],
          [118.0014846, 5.7416771],
          [117.999497687452674, 5.742424423974797],
        ],
      ],
      [
        [
          [115.8891383, 5.7753315],
          [115.8889667, 5.776527],
          [115.8896533, 5.7758439],
          [115.8891383, 5.7753315],
        ],
      ],
      [
        [
          [117.9185377, 5.7973085],
          [117.9187665, 5.794622],
          [117.9192242, 5.7928917],
          [117.9192242, 5.7911614],
          [117.9179427, 5.7895222],
          [117.9166155, 5.7885205],
          [117.9146475, 5.7879285],
          [117.9134118, 5.7886571],
          [117.9119472, 5.7894312],
          [117.9107573, 5.7907061],
          [117.9112149, 5.7909793],
          [117.9134118, 5.7928917],
          [117.913961, 5.7939845],
          [117.9136864, 5.795305],
          [117.9141898, 5.796671],
          [117.9159747, 5.7977638],
          [117.9185377, 5.7973085],
        ],
      ],
      [
        [
          [118.1389449, 5.8779666],
          [118.1391636, 5.8782038],
          [118.1393236, 5.8785076],
          [118.139698, 5.8792186],
          [118.1406578, 5.8805858],
          [118.141117, 5.8813304],
          [118.1412827, 5.8815992],
          [118.1415693, 5.8821155],
          [118.1420766, 5.8827517],
          [118.1422537, 5.8831657],
          [118.1423592, 5.8835948],
          [118.1423481, 5.8839626],
          [118.1422107, 5.8842512],
          [118.1419792, 5.8848976],
          [118.141684, 5.8852104],
          [118.1415261, 5.8854206],
          [118.1408434, 5.8863294],
          [118.1403921, 5.8867142],
          [118.1403162, 5.8867789],
          [118.1402371, 5.8869419],
          [118.14007, 5.887286],
          [118.1400909, 5.8874657],
          [118.1400485, 5.8876981],
          [118.1393219, 5.8887107],
          [118.1391477, 5.8890435],
          [118.1391047, 5.8891258],
          [118.1391834, 5.8891799],
          [118.1394635, 5.888934],
          [118.1398351, 5.8885734],
          [118.140678, 5.8881454],
          [118.1412076, 5.8877677],
          [118.1415917, 5.8874155],
          [118.141762, 5.887277],
          [118.1423799, 5.8867744],
          [118.1431193, 5.885864],
          [118.144087, 5.8849753],
          [118.1442628, 5.884425],
          [118.1443294, 5.8842166],
          [118.1452721, 5.8832983],
          [118.1454345, 5.8830381],
          [118.1454577, 5.8829767],
          [118.1455849, 5.8826397],
          [118.1456444, 5.8824016],
          [118.1455015, 5.8819519],
          [118.1450806, 5.8816683],
          [118.144635, 5.8814025],
          [118.14449, 5.8812491],
          [118.1444645, 5.8808435],
          [118.1445578, 5.8803721],
          [118.1447275, 5.8798186],
          [118.1451247, 5.8790776],
          [118.1460573, 5.8774372],
          [118.1467197, 5.8764409],
          [118.1473175, 5.875526],
          [118.1478416, 5.8749436],
          [118.1483067, 5.8743164],
          [118.1484094, 5.8738309],
          [118.1485218, 5.8730003],
          [118.1487062, 5.8721044],
          [118.1489878, 5.8711891],
          [118.1491555, 5.8711528],
          [118.1492249, 5.8711162],
          [118.1495372, 5.8702837],
          [118.1497485, 5.8699759],
          [118.1497851, 5.8697817],
          [118.1497625, 5.8694077],
          [118.1498083, 5.8691673],
          [118.149911, 5.8690993],
          [118.1499019, 5.8687293],
          [118.1498589, 5.8686411],
          [118.1497747, 5.8686193],
          [118.1498212, 5.8685187],
          [118.1498942, 5.8684586],
          [118.1497883, 5.868202],
          [118.1498281, 5.8677699],
          [118.150206, 5.8665549],
          [118.1502714, 5.8665341],
          [118.1503558, 5.8665071],
          [118.1503795, 5.8664869],
          [118.1504433, 5.8664326],
          [118.1504844, 5.8662476],
          [118.1503855, 5.8661164],
          [118.1503467, 5.865991],
          [118.1502972, 5.8659533],
          [118.1502868, 5.8660001],
          [118.1502567, 5.8661349],
          [118.1500139, 5.8660481],
          [118.149835, 5.8658857],
          [118.1498446, 5.8653401],
          [118.1497158, 5.865355],
          [118.1496397, 5.8654413],
          [118.1496051, 5.8654804],
          [118.1494459, 5.8654367],
          [118.1492633, 5.8652491],
          [118.1491979, 5.8650262],
          [118.1492066, 5.8648606],
          [118.1494443, 5.8647565],
          [118.149484, 5.8647391],
          [118.149497, 5.8641605],
          [118.149414, 5.8640936],
          [118.1492183, 5.8635382],
          [118.149272, 5.8631855],
          [118.1490712, 5.8628685],
          [118.1489285, 5.8628367],
          [118.1488414, 5.8626713],
          [118.148863, 5.8624821],
          [118.1487743, 5.8623069],
          [118.1487576, 5.8622993],
          [118.1485909, 5.8622231],
          [118.1485062, 5.8625885],
          [118.1482737, 5.8629699],
          [118.1479116, 5.8629682],
          [118.1474717, 5.8627859],
          [118.1471687, 5.8624361],
          [118.147111, 5.8623324],
          [118.1469238, 5.8619961],
          [118.1467587, 5.8616332],
          [118.1466233, 5.8611674],
          [118.146567, 5.8605901],
          [118.1465556, 5.8600302],
          [118.146606, 5.8593337],
          [118.146763, 5.8591407],
          [118.146777, 5.8589834],
          [118.1468819, 5.8588759],
          [118.1467994, 5.8586438],
          [118.1465483, 5.8582501],
          [118.146051, 5.8578121],
          [118.1459676, 5.8578977],
          [118.1453299, 5.8575884],
          [118.1448012, 5.8563657],
          [118.1445987, 5.8561608],
          [118.1440197, 5.8560536],
          [118.143571, 5.8556289],
          [118.143294, 5.8555666],
          [118.1429508, 5.8553547],
          [118.1425128, 5.8567327],
          [118.142493, 5.8572401],
          [118.1421096, 5.8581588],
          [118.142168, 5.8586709],
          [118.1414701, 5.8596339],
          [118.1412038, 5.8604741],
          [118.1410212, 5.8618235],
          [118.1407481, 5.8626127],
          [118.1405006, 5.8633563],
          [118.1404204, 5.8646172],
          [118.1405796, 5.8649076],
          [118.1405028, 5.8650781],
          [118.1407352, 5.8653221],
          [118.1407808, 5.8655591],
          [118.1408059, 5.8656895],
          [118.1408596, 5.8656869],
          [118.1414882, 5.8668508],
          [118.1417316, 5.8668603],
          [118.1420571, 5.866873],
          [118.1421907, 5.8670237],
          [118.1421845, 5.8674748],
          [118.14218, 5.8677958],
          [118.1421203, 5.8679964],
          [118.1419895, 5.8688963],
          [118.14155, 5.8698048],
          [118.1413604, 5.8705172],
          [118.1411861, 5.8707986],
          [118.1401562, 5.8731892],
          [118.1396789, 5.8739521],
          [118.1392867, 5.8751527],
          [118.1390842, 5.8752421],
          [118.1387578, 5.8757995],
          [118.1381574, 5.8769028],
          [118.1381685, 5.877133],
          [118.1380822, 5.8773209],
          [118.1381834, 5.8775972],
          [118.1380631, 5.8776791],
          [118.1380584, 5.8779273],
          [118.1381911, 5.8779829],
          [118.1387599, 5.8777659],
          [118.1389449, 5.8779666],
        ],
      ],
      [
        [
          [118.1039356, 5.9134872],
          [118.1051372, 5.9158777],
          [118.1051372, 5.9175851],
          [118.1030773, 5.9198048],
          [118.102219, 5.9215123],
          [118.1035923, 5.9225368],
          [118.1046223, 5.9228783],
          [118.1049656, 5.9245857],
          [118.1058239, 5.9259517],
          [118.1075405, 5.9268054],
          [118.1097721, 5.9269761],
          [118.1114887, 5.9250979],
          [118.1135487, 5.9233905],
          [118.1156086, 5.9208293],
          [118.1156086, 5.9184389],
          [118.1142353, 5.9163899],
          [118.1114887, 5.9145117],
          [118.1099438, 5.9150239],
          [118.1039356, 5.9134872],
        ],
      ],
      [
        [
          [116.046342, 5.8779161],
          [116.0464261, 5.8780464],
          [116.046587, 5.8780784],
          [116.0468123, 5.8780464],
          [116.0470913, 5.8778329],
          [116.0482071, 5.8767657],
          [116.04825, 5.8763922],
          [116.0483466, 5.8762321],
          [116.0485504, 5.87604],
          [116.0485611, 5.8757732],
          [116.0483251, 5.8756451],
          [116.0480354, 5.8756451],
          [116.0477672, 5.8757091],
          [116.0474024, 5.8756771],
          [116.0472522, 5.8754743],
          [116.0474024, 5.8752716],
          [116.0476814, 5.8751825],
          [116.0478542, 5.8741838],
          [116.0483212, 5.8735984],
          [116.0486387, 5.8736821],
          [116.0489189, 5.8736728],
          [116.0490123, 5.8734869],
          [116.0484799, 5.8731525],
          [116.048097, 5.8729202],
          [116.0477608, 5.8728737],
          [116.0473124, 5.872883],
          [116.0472284, 5.8725857],
          [116.0473124, 5.8721305],
          [116.0472377, 5.8717217],
          [116.0467053, 5.8712014],
          [116.0459862, 5.8700307],
          [116.0460982, 5.8699099],
          [116.0463971, 5.8696033],
          [116.0463504, 5.8692131],
          [116.0462197, 5.8689715],
          [116.0459862, 5.8690366],
          [116.0456686, 5.8693989],
          [116.0455659, 5.8696498],
          [116.0454044, 5.8700667],
          [116.0461867, 5.8719761],
          [116.046688, 5.8731515],
          [116.047155, 5.8743593],
          [116.0471363, 5.8750654],
          [116.0470149, 5.875595],
          [116.0466973, 5.8765984],
          [116.046342, 5.8779161],
        ],
      ],
      [
        [
          [117.6508063, 5.9319708],
          [117.6527117, 5.9312495],
          [117.6532317, 5.9297729],
          [117.6526389, 5.9282718],
          [117.6513453, 5.9268512],
          [117.6505099, 5.9265295],
          [117.649917, 5.9263955],
          [117.6502943, 5.9253769],
          [117.6505638, 5.9248676],
          [117.6512106, 5.9241975],
          [117.6518034, 5.9236614],
          [117.652585, 5.9232593],
          [117.6534473, 5.9229109],
          [117.6539054, 5.9219995],
          [117.6528006, 5.9206056],
          [117.6518034, 5.9203376],
          [117.6501596, 5.9200427],
          [117.6486774, 5.9193994],
          [117.6470605, 5.9194262],
          [117.6433146, 5.9191314],
          [117.6423983, 5.9188365],
          [117.639973, 5.9188901],
          [117.6383021, 5.919185],
          [117.6370894, 5.9195334],
          [117.6363079, 5.9203644],
          [117.6367661, 5.9214098],
          [117.637332, 5.9222407],
          [117.6381943, 5.9236078],
          [117.638922, 5.9246532],
          [117.6400269, 5.9266367],
          [117.6421558, 5.9292904],
          [117.6428026, 5.9297729],
          [117.6439883, 5.9302822],
          [117.6466293, 5.9313811],
          [117.6476803, 5.9315688],
          [117.6486774, 5.9317564],
          [117.6508063, 5.9319708],
        ],
      ],
      [
        [
          [115.9902694, 5.8443788],
          [115.9901997, 5.8443061],
          [115.9901327, 5.8443148],
          [115.9900998, 5.8443708],
          [115.9901139, 5.844675],
          [115.9899637, 5.8450245],
          [115.9896981, 5.8452247],
          [115.9894755, 5.8453207],
          [115.9892113, 5.8452807],
          [115.9887513, 5.8450806],
          [115.9885904, 5.8450939],
          [115.9885323, 5.8451808],
          [115.9885582, 5.8453287],
          [115.9886628, 5.8455022],
          [115.9887315, 5.8466451],
          [115.9887781, 5.846847],
          [115.9890798, 5.8477989],
          [115.989497, 5.8482718],
          [115.9899619, 5.8484915],
          [115.9900629, 5.8486614],
          [115.9900924, 5.8488962],
          [115.9899865, 5.849195],
          [115.989843, 5.8494405],
          [115.9898242, 5.8495232],
          [115.9898732, 5.8496226],
          [115.9901018, 5.8498354],
          [115.9906664, 5.8506743],
          [115.9909722, 5.8511535],
          [115.9911514, 5.8518688],
          [115.9912183, 5.851984],
          [115.9912934, 5.852012],
          [115.9913913, 5.8519787],
          [115.9916213, 5.8519927],
          [115.9917722, 5.8519575],
          [115.9919009, 5.8518372],
          [115.991976, 5.851484],
          [115.9920806, 5.8513996],
          [115.992208, 5.8513836],
          [115.9925326, 5.851433],
          [115.9926164, 5.851389],
          [115.9926553, 5.8512909],
          [115.9926244, 5.8511608],
          [115.9926466, 5.8511115],
          [115.9927042, 5.8510868],
          [115.9928357, 5.8511282],
          [115.9928585, 5.8511128],
          [115.9928665, 5.8510801],
          [115.992841, 5.8509921],
          [115.9928196, 5.8509641],
          [115.9926687, 5.8508813],
          [115.9924105, 5.8506652],
          [115.992212, 5.8505491],
          [115.9921638, 5.8504904],
          [115.9921168, 5.8503904],
          [115.9914825, 5.8499808],
          [115.9913269, 5.8499555],
          [115.9912531, 5.8498834],
          [115.9912203, 5.8497767],
          [115.9912203, 5.8495145],
          [115.9912827, 5.8491497],
          [115.9917034, 5.8482448],
          [115.9918654, 5.848045],
          [115.9928155, 5.8472206],
          [115.9930666, 5.8468871],
          [115.9932628, 5.846356],
          [115.9932656, 5.8459707],
          [115.9932201, 5.8458829],
          [115.9926942, 5.8454568],
          [115.992552, 5.8453874],
          [115.9913814, 5.8450866],
          [115.9911143, 5.8449845],
          [115.9905578, 5.8446456],
          [115.9902694, 5.8443788],
        ],
      ],
      [
        [
          [118.630191, 4.1122085],
          [118.6298705, 4.1120192],
          [118.6292816, 4.1119627],
          [118.6289737, 4.1120328],
          [118.6282991, 4.1126694],
          [118.6276506, 4.1134765],
          [118.6272316, 4.1149112],
          [118.6271636, 4.1154422],
          [118.6271637, 4.1165061],
          [118.6273399, 4.1169854],
          [118.6277564, 4.1173049],
          [118.6282991, 4.1174635],
          [118.6287334, 4.1174327],
          [118.6290218, 4.1172889],
          [118.6295122, 4.1166189],
          [118.6300868, 4.1158243],
          [118.6303993, 4.1147487],
          [118.6305307, 4.1133543],
          [118.6304368, 4.1127535],
          [118.630191, 4.1122085],
        ],
      ],
      [
        [
          [118.8805359, 4.1640817],
          [118.880475, 4.1644668],
          [118.8804428, 4.1648145],
          [118.8804482, 4.1650312],
          [118.8804643, 4.1651543],
          [118.8805045, 4.165296],
          [118.8805662, 4.165403],
          [118.880648, 4.1654846],
          [118.8807352, 4.1655395],
          [118.8808396, 4.1655839],
          [118.8809806, 4.1656077],
          [118.8811147, 4.1656077],
          [118.8821514, 4.1655288],
          [118.8822815, 4.1655234],
          [118.8823941, 4.1655355],
          [118.8824726, 4.1655589],
          [118.8825477, 4.165607],
          [118.8826194, 4.1656906],
          [118.8827602, 4.1658846],
          [118.8829044, 4.1660671],
          [118.8832068, 4.1664129],
          [118.8834509, 4.1666724],
          [118.883518, 4.1667339],
          [118.883581, 4.16677],
          [118.8836427, 4.1667888],
          [118.8836977, 4.1667954],
          [118.8837848, 4.1667914],
          [118.8838492, 4.1667754],
          [118.8839176, 4.166746],
          [118.8839753, 4.1667098],
          [118.8840343, 4.1666563],
          [118.8840692, 4.1666028],
          [118.8840893, 4.166552],
          [118.884104, 4.1664771],
          [118.8841027, 4.1664196],
          [118.8840879, 4.16635],
          [118.8840625, 4.1662711],
          [118.8840209, 4.1662056],
          [118.8839578, 4.1661481],
          [118.8838774, 4.1661106],
          [118.8837996, 4.1660892],
          [118.883758, 4.1660852],
          [118.8837299, 4.1660785],
          [118.8837004, 4.1660571],
          [118.8833155, 4.1657749],
          [118.8832699, 4.1657321],
          [118.8832417, 4.1656826],
          [118.8832269, 4.1656425],
          [118.8832243, 4.165601],
          [118.8832296, 4.1655395],
          [118.8832484, 4.1654632],
          [118.8832739, 4.1653763],
          [118.8832833, 4.1653255],
          [118.8832833, 4.1652867],
          [118.8832564, 4.1649871],
          [118.8832256, 4.1648894],
          [118.883176, 4.1648105],
          [118.8831009, 4.1647249],
          [118.883015, 4.1646527],
          [118.8829507, 4.1646246],
          [118.8828219, 4.1645898],
          [118.8826395, 4.1645577],
          [118.8818912, 4.1644012],
          [118.8817115, 4.1643597],
          [118.8815827, 4.1643089],
          [118.8814567, 4.1642233],
          [118.8813494, 4.1641069],
          [118.8812636, 4.1639585],
          [118.8811885, 4.1637659],
          [118.8811777, 4.1635973],
          [118.8811858, 4.1634556],
          [118.8812126, 4.1632563],
          [118.881277, 4.1630596],
          [118.8813548, 4.1628911],
          [118.8814513, 4.1627587],
          [118.8816257, 4.1626049],
          [118.8817946, 4.1625059],
          [118.8819878, 4.1624243],
          [118.8823472, 4.1622919],
          [118.8830043, 4.1620859],
          [118.8840289, 4.1618264],
          [118.8841872, 4.1617996],
          [118.8848416, 4.1616525],
          [118.8849677, 4.1616137],
          [118.8850428, 4.1615509],
          [118.8850911, 4.1614733],
          [118.8851018, 4.1613663],
          [118.8850813, 4.161257],
          [118.8850052, 4.1611482],
          [118.8849114, 4.1610747],
          [118.8847638, 4.1610265],
          [118.884611, 4.1610118],
          [118.8844661, 4.1610185],
          [118.884265, 4.1610533],
          [118.8840745, 4.1610881],
          [118.8828514, 4.1613101],
          [118.8827924, 4.1613074],
          [118.8827522, 4.1612927],
          [118.8827361, 4.1612432],
          [118.8827053, 4.1610279],
          [118.8826825, 4.1609677],
          [118.8826449, 4.1609195],
          [118.8825805, 4.1608714],
          [118.8825108, 4.1608419],
          [118.8824062, 4.1608205],
          [118.8817249, 4.1607376],
          [118.8816257, 4.1607349],
          [118.8815184, 4.160767],
          [118.8814406, 4.1608232],
          [118.8813789, 4.1608981],
          [118.881336, 4.1609864],
          [118.8812046, 4.1614305],
          [118.8806613, 4.1634619],
          [118.8805359, 4.1640817],
        ],
      ],
      [
        [
          [118.6921676, 4.2209799],
          [118.6927019, 4.2189427],
          [118.691438, 4.2176844],
          [118.6903639, 4.2193436],
          [118.6898785, 4.2193716],
          [118.6893674, 4.2191234],
          [118.6883879, 4.2182325],
          [118.6879257, 4.2182651],
          [118.6869525, 4.2203528],
          [118.6865219, 4.220283],
          [118.6860736, 4.220338],
          [118.6859939, 4.2207053],
          [118.6856903, 4.2213983],
          [118.6850238, 4.2218339],
          [118.6844428, 4.2218787],
          [118.6841394, 4.2219857],
          [118.6835652, 4.2218471],
          [118.6830438, 4.2216227],
          [118.6827271, 4.2216425],
          [118.682308, 4.221834],
          [118.6817041, 4.2219131],
          [118.6813933, 4.2225294],
          [118.6813327, 4.223402],
          [118.6820514, 4.2235747],
          [118.6825694, 4.2236027],
          [118.6826627, 4.2237474],
          [118.6831947, 4.2236447],
          [118.6836427, 4.223696],
          [118.6844827, 4.22357],
          [118.685136, 4.2232854],
          [118.6855094, 4.2231827],
          [118.6869748, 4.2221783],
          [118.6879745, 4.221677],
          [118.6888892, 4.2215743],
          [118.6898225, 4.221537],
          [118.6902705, 4.221789],
          [118.6907652, 4.221257],
          [118.6921676, 4.2209799],
        ],
      ],
      [
        [
          [118.6313646, 4.2431038],
          [118.6311666, 4.2432881],
          [118.6307296, 4.2435612],
          [118.6301014, 4.244285],
          [118.62976, 4.2447562],
          [118.629166, 4.2456028],
          [118.6285173, 4.2466544],
          [118.6281008, 4.247385],
          [118.6279196, 4.2477121],
          [118.6278627, 4.2480029],
          [118.6278918, 4.2482946],
          [118.6279397, 4.2484303],
          [118.6280077, 4.2485741],
          [118.6281381, 4.2487299],
          [118.6282648, 4.2488642],
          [118.6284837, 4.2489974],
          [118.6286727, 4.2490274],
          [118.6288398, 4.249061],
          [118.6289494, 4.2490318],
          [118.6293093, 4.2488108],
          [118.6299816, 4.2483642],
          [118.630381, 4.2481116],
          [118.6308575, 4.2478684],
          [118.6311062, 4.2479192],
          [118.6313508, 4.2478547],
          [118.6318776, 4.2477835],
          [118.6324461, 4.2477823],
          [118.632618, 4.2477144],
          [118.6328257, 4.2475288],
          [118.6329737, 4.2474303],
          [118.633085, 4.2469235],
          [118.6333452, 4.2462949],
          [118.6333991, 4.2461337],
          [118.6334662, 4.2459597],
          [118.6336701, 4.245767],
          [118.6337318, 4.2456065],
          [118.6337667, 4.2453549],
          [118.6337748, 4.2448276],
          [118.6335896, 4.2445466],
          [118.6333937, 4.2440114],
          [118.6332863, 4.2437384],
          [118.6326992, 4.2431544],
          [118.6326301, 4.2431465],
          [118.632327, 4.24297],
          [118.6316579, 4.2429359],
          [118.6313646, 4.2431038],
        ],
      ],
      [
        [
          [118.7529682, 4.1756327],
          [118.7512281, 4.1767556],
          [118.7522005, 4.1791037],
          [118.7535824, 4.1797672],
          [118.7562437, 4.179512],
          [118.7583421, 4.1785932],
          [118.7603893, 4.1794099],
          [118.763409, 4.1793078],
          [118.764279, 4.1777255],
          [118.7631531, 4.1768577],
          [118.7594169, 4.1768067],
          [118.7580862, 4.1762962],
          [118.755169, 4.1768577],
          [118.7529682, 4.1756327],
        ],
      ],
      [
        [
          [117.6291927, 4.2015567],
          [117.6309093, 4.2013855],
          [117.6334842, 4.2003583],
          [117.6362308, 4.1986463],
          [117.6389774, 4.1965919],
          [117.6413806, 4.1943662],
          [117.6430972, 4.1923118],
          [117.6441272, 4.1909422],
          [117.6441272, 4.1894014],
          [117.6425823, 4.1885454],
          [117.6405223, 4.1871757],
          [117.6388057, 4.1856349],
          [117.6374324, 4.1842653],
          [117.6355441, 4.1844365],
          [117.6338275, 4.1883742],
          [117.6327976, 4.1926542],
          [117.6315959, 4.1952222],
          [117.6297077, 4.1977903],
          [117.629021, 4.2003583],
          [117.6291927, 4.2015567],
        ],
      ],
      [
        [
          [117.899684113285574, 4.166053763900158],
          [117.8991973, 4.1658511],
          [117.770906, 4.165599],
          [117.729065, 4.165599],
          [117.700642, 4.16553],
          [117.684080291534926, 4.16553],
          [117.6828646, 4.1662575],
          [117.6820278, 4.1675201],
          [117.6815557, 4.1691038],
          [117.6807833, 4.1704521],
          [117.6799464, 4.1709871],
          [117.678895, 4.1708801],
          [117.6778435, 4.1703022],
          [117.6759982, 4.170131],
          [117.6730095, 4.1708214],
          [117.6695331, 4.1727945],
          [117.6665722, 4.1758349],
          [117.6667865, 4.1782731],
          [117.6662372, 4.1793688],
          [117.6657565, 4.1803276],
          [117.6618083, 4.18067],
          [117.6578601, 4.1822108],
          [117.64207, 4.1974166],
          [117.6329692, 4.2030975],
          [117.6279937, 4.2052918],
          [117.6259338, 4.2135093],
          [117.6259311, 4.2164509],
          [117.6271327, 4.2203884],
          [117.629367, 4.2230963],
          [117.6322826, 4.2267227],
          [117.6364024, 4.2304889],
          [117.6430999, 4.2350799],
          [117.6561462, 4.2432971],
          [117.6564388, 4.2435365],
          [117.6565002, 4.2435671],
          [117.6606067, 4.2469234],
          [117.6688304, 4.2580435],
          [117.6692294, 4.2582627],
          [117.6696203, 4.258482],
          [117.6701665, 4.2587853],
          [117.6706089, 4.2590294],
          [117.6712636, 4.2594026],
          [117.6716102, 4.2595853],
          [117.6727639, 4.2602272],
          [117.6738074, 4.2608142],
          [117.6742856, 4.2610743],
          [117.6754125, 4.2617131],
          [117.6765743, 4.2623566],
          [117.6768056, 4.2624888],
          [117.6805221, 4.2645245],
          [117.6891025, 4.2647269],
          [117.6930507, 4.2636998],
          [117.7014621, 4.2618167],
          [117.7352794, 4.2595913],
          [117.7531322, 4.2577082],
          [117.7557098, 4.2552804],
          [117.760173, 4.2501447],
          [117.7666934, 4.246581],
          [117.7739059, 4.2439819],
          [117.7759631, 4.2434995],
          [117.7793991, 4.2412428],
          [117.7793991, 4.2354223],
          [117.7797424, 4.2251507],
          [117.783519, 4.2196724],
          [117.7969085, 4.2148789],
          [117.8065216, 4.2090582],
          [117.8111537, 4.2024127],
          [117.8164779, 4.1991286],
          [117.8271209, 4.194335],
          [117.8307231, 4.1943662],
          [117.834328, 4.1957358],
          [117.8360446, 4.1948798],
          [117.8384506, 4.1936502],
          [117.8676303, 4.1852925],
          [117.8727802, 4.1847789],
          [117.8762134, 4.1849501],
          [117.879475, 4.1858061],
          [117.8809019, 4.1857929],
          [117.8815198, 4.1855827],
          [117.8822337, 4.1848145],
          [117.8853923, 4.1811632],
          [117.8868039, 4.1788251],
          [117.8876864, 4.1769074],
          [117.888701, 4.1750514],
          [117.8898482, 4.1736929],
          [117.8907635, 4.1727568],
          [117.8918209, 4.1719375],
          [117.8935356, 4.1712504],
          [117.8949733, 4.169731],
          [117.8974943, 4.1682323],
          [117.8989353, 4.1665943],
          [117.899684113285574, 4.166053763900158],
        ],
      ],
      [
        [
          [118.3337794, 4.332903],
          [118.3341825, 4.3331652],
          [118.3357963, 4.3343651],
          [118.3371695, 4.3357343],
          [118.3377075, 4.3364331],
          [118.3380685, 4.3373718],
          [118.3382595, 4.3382731],
          [118.3384582, 4.3393432],
          [118.3386925, 4.3405072],
          [118.3391108, 4.3414158],
          [118.3395536, 4.3421053],
          [118.3402878, 4.3425687],
          [118.3411334, 4.3429054],
          [118.3415652, 4.3428489],
          [118.3417351, 4.3426866],
          [118.3436746, 4.3415432],
          [118.3457273, 4.3406609],
          [118.3458972, 4.3404915],
          [118.3465679, 4.3404793],
          [118.3476824, 4.3400615],
          [118.3488368, 4.3397285],
          [118.3495426, 4.3397434],
          [118.3497832, 4.3399904],
          [118.349939, 4.3398986],
          [118.3501301, 4.3396798],
          [118.3504203, 4.3394681],
          [118.3508238, 4.3386705],
          [118.3511281, 4.3373577],
          [118.3511918, 4.3369695],
          [118.3513688, 4.336539],
          [118.3511777, 4.3360096],
          [118.3511848, 4.335325],
          [118.3514113, 4.3348521],
          [118.3516519, 4.3347321],
          [118.3521757, 4.3346474],
          [118.3522961, 4.3344992],
          [118.3519068, 4.3334475],
          [118.3517793, 4.3326076],
          [118.3518784, 4.33217],
          [118.3522253, 4.3313301],
          [118.3518997, 4.3307019],
          [118.3517723, 4.3292973],
          [118.3520342, 4.3282668],
          [118.3524235, 4.3278715],
          [118.3533932, 4.3270528],
          [118.3542002, 4.3262482],
          [118.3548301, 4.3255],
          [118.3551487, 4.3254082],
          [118.3551062, 4.3252812],
          [118.3548231, 4.32514],
          [118.3548584, 4.3248789],
          [118.3549434, 4.3248224],
          [118.3550566, 4.3249353],
          [118.3552194, 4.3249353],
          [118.3554743, 4.32478],
          [118.3555521, 4.3244271],
          [118.3558069, 4.3242154],
          [118.3563661, 4.3239613],
          [118.3570103, 4.3233402],
          [118.3578668, 4.323199],
          [118.3580083, 4.3231072],
          [118.358065, 4.3225779],
          [118.3582844, 4.3220132],
          [118.3584401, 4.3217732],
          [118.3588153, 4.3215968],
          [118.3589568, 4.321138],
          [118.3593249, 4.3209051],
          [118.3593745, 4.3205451],
          [118.3595868, 4.3203475],
          [118.3601956, 4.3201075],
          [118.360323, 4.319924],
          [118.3603937, 4.3196628],
          [118.3603159, 4.3195216],
          [118.359785, 4.3192111],
          [118.3593674, 4.3182441],
          [118.3585463, 4.3183076],
          [118.3576473, 4.3181876],
          [118.3571094, 4.3179618],
          [118.3567908, 4.3174747],
          [118.3565573, 4.3172348],
          [118.3553398, 4.3167195],
          [118.3548514, 4.3157666],
          [118.3546107, 4.3151173],
          [118.3545258, 4.314962],
          [118.3542639, 4.314849],
          [118.353733, 4.3145102],
          [118.3533366, 4.3143973],
          [118.3529827, 4.3144749],
          [118.3521474, 4.3144608],
          [118.3514113, 4.3144185],
          [118.351114, 4.3145173],
          [118.3510573, 4.3147996],
          [118.3512546, 4.3150441],
          [118.3514335, 4.3153036],
          [118.351336, 4.3161468],
          [118.3510432, 4.316763],
          [118.3506367, 4.3171197],
          [118.350653, 4.3174765],
          [118.3508806, 4.3179467],
          [118.3508481, 4.3183846],
          [118.350344, 4.3189521],
          [118.3494008, 4.3193089],
          [118.3489455, 4.3195845],
          [118.3488479, 4.3200386],
          [118.3490756, 4.3205088],
          [118.348978, 4.3208656],
          [118.3486527, 4.323233],
          [118.3476933, 4.3242871],
          [118.3464086, 4.3248384],
          [118.3459045, 4.3245627],
          [118.3451402, 4.3239465],
          [118.3444897, 4.3234925],
          [118.3427659, 4.3232168],
          [118.3401966, 4.323833],
          [118.3385053, 4.3245789],
          [118.3370906, 4.3260383],
          [118.3362124, 4.3274004],
          [118.3351879, 4.3278383],
          [118.3343098, 4.3277085],
          [118.3334479, 4.3273031],
          [118.3326673, 4.3275464],
          [118.3321795, 4.3283247],
          [118.3321307, 4.328795],
          [118.3322283, 4.3300111],
          [118.3323909, 4.3306273],
          [118.332895, 4.3320056],
          [118.3337794, 4.332903],
        ],
      ],
      [
        [
          [118.5914031, 4.3641843],
          [118.5922281, 4.3652144],
          [118.5931766, 4.3653129],
          [118.5942098, 4.3649352],
          [118.5945574, 4.3644528],
          [118.5948084, 4.3638676],
          [118.5949854, 4.3627283],
          [118.5955959, 4.3619367],
          [118.5962772, 4.3612253],
          [118.5968394, 4.359903],
          [118.5960905, 4.3583989],
          [118.5933117, 4.3570135],
          [118.5922035, 4.3569344],
          [118.5899525, 4.3566156],
          [118.5892262, 4.3563588],
          [118.5885138, 4.3557416],
          [118.5874914, 4.3553832],
          [118.5832484, 4.3547303],
          [118.5825539, 4.3551318],
          [118.5821473, 4.3558475],
          [118.5822364, 4.3565086],
          [118.5826913, 4.3570595],
          [118.5833528, 4.3573494],
          [118.5834225, 4.3573864],
          [118.5839798, 4.3576821],
          [118.584483, 4.3583882],
          [118.5848478, 4.3592119],
          [118.5862023, 4.3597998],
          [118.5865687, 4.3602742],
          [118.5872939, 4.3606914],
          [118.5879448, 4.360556],
          [118.588708, 4.3604411],
          [118.589164, 4.3606315],
          [118.5898206, 4.3613247],
          [118.590636, 4.3632418],
          [118.5914031, 4.3641843],
        ],
      ],
      [
        [
          [118.570669, 4.3461364],
          [118.5711381, 4.3460009],
          [118.5713595, 4.3459369],
          [118.5709684, 4.3454432],
          [118.570985, 4.345295],
          [118.571396, 4.3448072],
          [118.5714678, 4.3443836],
          [118.5713664, 4.3438973],
          [118.5711347, 4.343678],
          [118.5710736, 4.3429891],
          [118.5708756, 4.3425034],
          [118.5705714, 4.3418695],
          [118.5665733, 4.3387243],
          [118.5662445, 4.3387002],
          [118.56595, 4.3383381],
          [118.5651758, 4.3380915],
          [118.5646786, 4.3378208],
          [118.564346, 4.3380492],
          [118.5641744, 4.3381439],
          [118.5639217, 4.3380423],
          [118.5637447, 4.3381701],
          [118.5637892, 4.3383868],
          [118.5639882, 4.3386804],
          [118.5641427, 4.3388778],
          [118.5644625, 4.3397304],
          [118.5647382, 4.3406954],
          [118.5654938, 4.341405],
          [118.5660256, 4.3422686],
          [118.5672766, 4.3434389],
          [118.5680941, 4.3439112],
          [118.56909, 4.3453473],
          [118.5691198, 4.3457738],
          [118.5694143, 4.3461835],
          [118.5695501, 4.3460107],
          [118.5704115, 4.3461075],
          [118.570669, 4.3461364],
        ],
      ],
      [
        [
          [118.6000226, 4.3669346],
          [118.5985785, 4.3670791],
          [118.5982889, 4.3669881],
          [118.5981097, 4.3676749],
          [118.5980035, 4.36855],
          [118.5973383, 4.3693833],
          [118.5974402, 4.3700198],
          [118.5977492, 4.370774],
          [118.5977835, 4.371556],
          [118.5981387, 4.3723733],
          [118.5988669, 4.3726224],
          [118.5997587, 4.3729135],
          [118.5994934, 4.3732391],
          [118.599793, 4.373534],
          [118.6009678, 4.373012],
          [118.6025804, 4.3732398],
          [118.6036007, 4.37338],
          [118.6042305, 4.3729221],
          [118.604904, 4.3723153],
          [118.6054257, 4.3715614],
          [118.6054355, 4.3710745],
          [118.6052605, 4.3703001],
          [118.6036715, 4.3690977],
          [118.602487, 4.3685553],
          [118.601692, 4.3681948],
          [118.6006404, 4.3673599],
          [118.6000226, 4.3669346],
        ],
      ],
      [
        [
          [118.6531555, 4.3903359],
          [118.6550438, 4.3908493],
          [118.659507, 4.3911917],
          [118.6634552, 4.391534],
          [118.6650001, 4.3884531],
          [118.6601936, 4.3848588],
          [118.6534988, 4.3826337],
          [118.6502882, 4.3821827],
          [118.6479794, 4.3833124],
          [118.6531555, 4.3903359],
        ],
      ],
      [
        [
          [118.7525061, 4.4279919],
          [118.7565292, 4.4279561],
          [118.7613022, 4.4269627],
          [118.7661087, 4.4255935],
          [118.7682084, 4.4244491],
          [118.7689185, 4.4219208],
          [118.7661536, 4.4213626],
          [118.7642189, 4.4199611],
          [118.7627278, 4.4174273],
          [118.7637054, 4.4158379],
          [118.7573539, 4.4154956],
          [118.7594139, 4.4166937],
          [118.7610532, 4.4185857],
          [118.7614738, 4.4206302],
          [118.7561711, 4.4216089],
          [118.754042, 4.4263608],
          [118.752462, 4.4275708],
          [118.7525061, 4.4279919],
        ],
      ],
      [
        [
          [118.8592587, 4.3102076],
          [118.8593282, 4.3100106],
          [118.8593757, 4.3094802],
          [118.8595268, 4.3088822],
          [118.8599183, 4.3081793],
          [118.8603253, 4.3075658],
          [118.8608308, 4.307165],
          [118.8613549, 4.3068999],
          [118.861916, 4.3067334],
          [118.8621287, 4.3066625],
          [118.8623754, 4.3064683],
          [118.8624401, 4.3063604],
          [118.8626158, 4.3060305],
          [118.8627391, 4.3058086],
          [118.8627484, 4.3055835],
          [118.8624987, 4.3054664],
          [118.8620887, 4.3054787],
          [118.8615276, 4.3054879],
          [118.8609295, 4.3056143],
          [118.8603777, 4.3055743],
          [118.8601834, 4.3055126],
          [118.8598289, 4.3053245],
          [118.8595299, 4.3050255],
          [118.8591075, 4.3048375],
          [118.8585434, 4.3041037],
          [118.8581827, 4.303555],
          [118.8580254, 4.3030463],
          [118.8577172, 4.3024328],
          [118.8575075, 4.3020845],
          [118.8572486, 4.3018717],
          [118.8569464, 4.3018872],
          [118.8567738, 4.3021122],
          [118.8564593, 4.3024575],
          [118.8560709, 4.3031111],
          [118.8556023, 4.3035735],
          [118.8552817, 4.3044182],
          [118.8551031, 4.3047835],
          [118.854739, 4.3053241],
          [118.854436, 4.3056359],
          [118.8540545, 4.3060195],
          [118.8536905, 4.306268],
          [118.8535532, 4.3066037],
          [118.8536883, 4.3068937],
          [118.8540763, 4.3071988],
          [118.8546409, 4.3075585],
          [118.8551946, 4.3078027],
          [118.8557135, 4.3080207],
          [118.8563631, 4.3084152],
          [118.856895, 4.3088905],
          [118.8570977, 4.3090583],
          [118.8573964, 4.309115],
          [118.8575272, 4.3092501],
          [118.8576972, 4.3095728],
          [118.857852, 4.3098474],
          [118.8580787, 4.3101635],
          [118.8583076, 4.3103663],
          [118.858417, 4.3105067],
          [118.8584417, 4.3110092],
          [118.8585897, 4.3113236],
          [118.8587222, 4.3114747],
          [118.8588178, 4.31182],
          [118.8588918, 4.31223],
          [118.8589719, 4.3124304],
          [118.8591076, 4.3126709],
          [118.8592494, 4.3129452],
          [118.8594251, 4.3129976],
          [118.8595423, 4.3129791],
          [118.8595546, 4.3127479],
          [118.8594344, 4.3123872],
          [118.8593573, 4.3117522],
          [118.8593234, 4.3112959],
          [118.8593111, 4.3108551],
          [118.8592587, 4.3102076],
        ],
      ],
      [
        [
          [118.8685935, 4.3140323],
          [118.8683846, 4.3147015],
          [118.8683966, 4.3150045],
          [118.8685507, 4.3154466],
          [118.8685712, 4.3155824],
          [118.8685147, 4.3156711],
          [118.8683897, 4.3157701],
          [118.8683679, 4.315893],
          [118.8684171, 4.315998],
          [118.8688211, 4.3165691],
          [118.869119, 4.3169011],
          [118.8691601, 4.3170786],
          [118.8691811, 4.317424],
          [118.8692731, 4.3177563],
          [118.8695154, 4.317968],
          [118.8698483, 4.3180334],
          [118.8702044, 4.3179714],
          [118.8708012, 4.3176536],
          [118.8718926, 4.3166229],
          [118.8721012, 4.3165477],
          [118.8723544, 4.3166284],
          [118.8726525, 4.3166706],
          [118.8730394, 4.3165986],
          [118.8753235, 4.3154742],
          [118.8756373, 4.3152138],
          [118.8758709, 4.3146699],
          [118.8759217, 4.3142679],
          [118.8758833, 4.3137578],
          [118.8757203, 4.3133555],
          [118.8753916, 4.312915],
          [118.8753608, 4.3127836],
          [118.8753848, 4.3123534],
          [118.875496, 4.3116671],
          [118.8754549, 4.3114512],
          [118.8752399, 4.3111785],
          [118.8749927, 4.3110346],
          [118.8747804, 4.3110184],
          [118.8745373, 4.3111038],
          [118.874284, 4.3111106],
          [118.8738628, 4.3109493],
          [118.8731036, 4.3107849],
          [118.8725309, 4.3107683],
          [118.8719078, 4.3108784],
          [118.8714605, 4.3108455],
          [118.8710156, 4.3107097],
          [118.8705742, 4.3107521],
          [118.8702027, 4.3109425],
          [118.8699929, 4.3112555],
          [118.8699237, 4.3117497],
          [118.8698843, 4.3119642],
          [118.869737, 4.3120521],
          [118.8695333, 4.3120973],
          [118.8687441, 4.3120666],
          [118.8679341, 4.3121056],
          [118.8675783, 4.3122399],
          [118.8673677, 4.3124063],
          [118.8672656, 4.3125912],
          [118.8672684, 4.3127759],
          [118.8673746, 4.3129637],
          [118.8679583, 4.3132411],
          [118.8685433, 4.3136881],
          [118.8686106, 4.3138172],
          [118.8685935, 4.3140323],
        ],
      ],
      [
        [
          [118.9324227, 4.4729951],
          [118.9323181, 4.4730566],
          [118.9322644, 4.4731342],
          [118.9322242, 4.4732598],
          [118.9321947, 4.4733401],
          [118.9321223, 4.4734203],
          [118.9319989, 4.4735139],
          [118.9319238, 4.4735673],
          [118.9318031, 4.4735861],
          [118.9317602, 4.4736556],
          [118.9318192, 4.4737572],
          [118.9319184, 4.4738668],
          [118.9319801, 4.4739925],
          [118.9320123, 4.474161],
          [118.9320123, 4.4743241],
          [118.932023, 4.4744551],
          [118.9319694, 4.4747011],
          [118.931905, 4.4748455],
          [118.9318299, 4.4749498],
          [118.9317226, 4.4750728],
          [118.9317226, 4.4752092],
          [118.9317575, 4.4753483],
          [118.931779, 4.475514],
          [118.9318112, 4.4757253],
          [118.9318272, 4.4758884],
          [118.9318085, 4.4760462],
          [118.9317495, 4.4762334],
          [118.931787, 4.4764045],
          [118.9318675, 4.4765034],
          [118.9319774, 4.4765569],
          [118.9321437, 4.4765516],
          [118.9322805, 4.4764981],
          [118.9324495, 4.4764152],
          [118.9326534, 4.4762628],
          [118.9328572, 4.4760943],
          [118.9330557, 4.4759365],
          [118.9332998, 4.4757547],
          [118.9335734, 4.475498],
          [118.9337692, 4.475252],
          [118.9339274, 4.4750621],
          [118.9340508, 4.4749097],
          [118.9342064, 4.4747626],
          [118.9343941, 4.474661],
          [118.9346302, 4.4744765],
          [118.9348581, 4.4743321],
          [118.935129, 4.4740754],
          [118.9353812, 4.4738615],
          [118.9355153, 4.4737412],
          [118.9357513, 4.4735406],
          [118.9358962, 4.473431],
          [118.936151, 4.4732197],
          [118.9363146, 4.4730833],
          [118.9364648, 4.472963],
          [118.9366177, 4.472832],
          [118.9367384, 4.4726822],
          [118.9368108, 4.4725913],
          [118.9368591, 4.4724924],
          [118.9369476, 4.472348],
          [118.9371139, 4.4721608],
          [118.9371541, 4.4720405],
          [118.9372078, 4.4718907],
          [118.9372909, 4.4717597],
          [118.9374223, 4.4715859],
          [118.9375618, 4.471372],
          [118.9376879, 4.4711955],
          [118.9377898, 4.471011],
          [118.9378756, 4.4708559],
          [118.9379749, 4.4707355],
          [118.9380875, 4.4705885],
          [118.9382029, 4.4704334],
          [118.9382967, 4.4702676],
          [118.9384094, 4.4700911],
          [118.9385247, 4.4699199],
          [118.9386374, 4.4697114],
          [118.9387232, 4.4695402],
          [118.9387903, 4.4694199],
          [118.9389378, 4.4691766],
          [118.9390182, 4.4689894],
          [118.9390504, 4.468845],
          [118.9390826, 4.4687086],
          [118.9391175, 4.4686123],
          [118.9391711, 4.4685],
          [118.9392462, 4.4683556],
          [118.9393589, 4.4681337],
          [118.9394394, 4.4679786],
          [118.9395225, 4.4678395],
          [118.9396083, 4.467639],
          [118.9397022, 4.4674438],
          [118.9397559, 4.4673047],
          [118.9398444, 4.4672085],
          [118.9399195, 4.4671389],
          [118.9400804, 4.4670748],
          [118.9401743, 4.4669972],
          [118.9402601, 4.4669304],
          [118.9403298, 4.4668234],
          [118.9403674, 4.4667271],
          [118.9404076, 4.4666523],
          [118.940464, 4.4665827],
          [118.9405417, 4.4665292],
          [118.9406383, 4.4665212],
          [118.9407295, 4.4664838],
          [118.9407697, 4.4664062],
          [118.9407027, 4.4663822],
          [118.9405364, 4.4664303],
          [118.9403647, 4.4665346],
          [118.9402467, 4.4666362],
          [118.9401528, 4.4667699],
          [118.9401045, 4.4668073],
          [118.9400348, 4.4668421],
          [118.9398578, 4.4669009],
          [118.9396754, 4.4669598],
          [118.9394581, 4.4670774],
          [118.9393294, 4.4671817],
          [118.9391148, 4.4673903],
          [118.9389726, 4.4675561],
          [118.9387634, 4.4678048],
          [118.9385274, 4.4681123],
          [118.9383236, 4.4683743],
          [118.9380848, 4.4686926],
          [118.937881, 4.4689172],
          [118.9376825, 4.4691659],
          [118.9374867, 4.4693584],
          [118.9373177, 4.4694894],
          [118.9371461, 4.46969],
          [118.9369127, 4.4699761],
          [118.9366901, 4.4702275],
          [118.936336, 4.4706152],
          [118.9361, 4.4708077],
          [118.9358264, 4.4710751],
          [118.9355957, 4.4712998],
          [118.9353678, 4.4715083],
          [118.9352015, 4.4716608],
          [118.9349601, 4.4718212],
          [118.9348072, 4.4719255],
          [118.9347026, 4.4719977],
          [118.9345309, 4.4720966],
          [118.9343539, 4.4722089],
          [118.934142, 4.4723319],
          [118.933922, 4.4724362],
          [118.9337209, 4.4725191],
          [118.9335036, 4.4726154],
          [118.9332569, 4.4727437],
          [118.9331362, 4.4727785],
          [118.9330289, 4.4727999],
          [118.9328733, 4.4728052],
          [118.9328036, 4.4728373],
          [118.9327043, 4.4728587],
          [118.9325944, 4.4728828],
          [118.9324227, 4.4729951],
        ],
      ],
      [
        [
          [118.8408805, 4.533371],
          [118.8410611, 4.5333983],
          [118.8416469, 4.5331932],
          [118.8429662, 4.5328578],
          [118.8441072, 4.5325066],
          [118.8451165, 4.5320876],
          [118.845956, 4.5318238],
          [118.8468197, 4.5313951],
          [118.8498918, 4.5295648],
          [118.8509537, 4.5288494],
          [118.8546982, 4.5259642],
          [118.8549, 4.5256435],
          [118.8549532, 4.5251041],
          [118.8548371, 4.5249779],
          [118.8544323, 4.5249572],
          [118.8541803, 4.5249115],
          [118.853849, 4.5247758],
          [118.8535843, 4.5245668],
          [118.8533458, 4.5243847],
          [118.8531345, 4.5243148],
          [118.8529297, 4.5243151],
          [118.8526221, 4.5243294],
          [118.85244, 4.5243577],
          [118.8521861, 4.5242378],
          [118.8510894, 4.5244389],
          [118.8506581, 4.5244919],
          [118.8505471, 4.524608],
          [118.8498557, 4.5250064],
          [118.8492657, 4.5252811],
          [118.8480538, 4.526029],
          [118.8466555, 4.5272709],
          [118.8461379, 4.5279554],
          [118.8455038, 4.5282024],
          [118.8434611, 4.5298623],
          [118.8414276, 4.5318778],
          [118.8409452, 4.5326561],
          [118.8408704, 4.5331335],
          [118.8408805, 4.533371],
        ],
      ],
      [
        [
          [118.9543546, 4.5653482],
          [118.9540964, 4.5657182],
          [118.95429, 4.5661847],
          [118.9553229, 4.5658469],
          [118.9561783, 4.5659434],
          [118.9566624, 4.5656538],
          [118.9565495, 4.565139],
          [118.9558071, 4.5645599],
          [118.9554197, 4.5645599],
          [118.9543546, 4.5653482],
        ],
      ],
      [
        [
          [118.9475907, 4.5815804],
          [118.9477794, 4.5819021],
          [118.949651, 4.5791493],
          [118.9510661, 4.5760096],
          [118.950988, 4.5741362],
          [118.9512715, 4.5709134],
          [118.9496147, 4.5731096],
          [118.9478023, 4.5777842],
          [118.9475907, 4.5815804],
        ],
      ],
      [
        [
          [118.6598675, 4.5552274],
          [118.6593082, 4.5563424],
          [118.659681, 4.5579529],
          [118.6616074, 4.5614837],
          [118.6613588, 4.5626606],
          [118.6619802, 4.563342],
          [118.6639687, 4.5627226],
          [118.6676971, 4.5631562],
          [118.6706798, 4.5614218],
          [118.6703691, 4.5596254],
          [118.6667029, 4.5576432],
          [118.661421, 4.554608],
          [118.6598675, 4.5552274],
        ],
      ],
      [
        [
          [118.5929073, 4.5533261],
          [118.5928658, 4.5534114],
          [118.5928859, 4.5535387],
          [118.5929677, 4.5536069],
          [118.5931098, 4.553665],
          [118.5958671, 4.5540694],
          [118.5960777, 4.5541617],
          [118.5962333, 4.5542686],
          [118.5971291, 4.5553274],
          [118.5972337, 4.555421],
          [118.5978527, 4.5556282],
          [118.5983784, 4.5559865],
          [118.5984354, 4.5560921],
          [118.5985185, 4.5564878],
          [118.5986633, 4.5567499],
          [118.5990087, 4.5570527],
          [118.5991032, 4.5573327],
          [118.5992159, 4.5584076],
          [118.5994687, 4.5592759],
          [118.5995807, 4.5595466],
          [118.6002888, 4.560354],
          [118.6008044, 4.5607892],
          [118.6008789, 4.5609743],
          [118.6009023, 4.5612096],
          [118.601133, 4.5617711],
          [118.6011933, 4.5621715],
          [118.601249, 4.5623099],
          [118.6016701, 4.562616],
          [118.6017298, 4.5626782],
          [118.6017378, 4.5627871],
          [118.6015172, 4.563235],
          [118.6014911, 4.5633265],
          [118.6015085, 4.5634221],
          [118.6016996, 4.5636521],
          [118.6018693, 4.5637396],
          [118.6031983, 4.5640718],
          [118.6033626, 4.5642189],
          [118.6034457, 4.5644141],
          [118.6034504, 4.565029],
          [118.6035363, 4.5652108],
          [118.6037139, 4.5653071],
          [118.605098, 4.565414],
          [118.6053172, 4.565529],
          [118.6055425, 4.565775],
          [118.6058302, 4.5664327],
          [118.6059932, 4.5666185],
          [118.6062641, 4.5666987],
          [118.6086888, 4.5662803],
          [118.6091676, 4.566293],
          [118.6101137, 4.5666707],
          [118.6103497, 4.5666693],
          [118.6106183, 4.5664521],
          [118.6135007, 4.5621949],
          [118.613726, 4.5613975],
          [118.6136294, 4.5604516],
          [118.6133397, 4.5591836],
          [118.6126648, 4.5580193],
          [118.6107279, 4.5561483],
          [118.6084427, 4.5548435],
          [118.608147, 4.554778],
          [118.6067402, 4.5551075],
          [118.6057598, 4.55503],
          [118.6056317, 4.555052],
          [118.6054862, 4.5550995],
          [118.6046829, 4.5555901],
          [118.6040653, 4.5557953],
          [118.6030944, 4.5559103],
          [118.6024023, 4.5557953],
          [118.5999622, 4.5550581],
          [118.5981215, 4.554298],
          [118.5960086, 4.5537426],
          [118.5957015, 4.5535567],
          [118.5955419, 4.5535019],
          [118.5953059, 4.5535046],
          [118.5949297, 4.553524],
          [118.5948405, 4.5534785],
          [118.5946695, 4.5533141],
          [118.5944188, 4.5531894],
          [118.5938059, 4.5531523],
          [118.5931219, 4.5532185],
          [118.5929073, 4.5533261],
        ],
      ],
      [
        [
          [118.480175, 4.5109633],
          [118.4784584, 4.5130168],
          [118.4784584, 4.5147281],
          [118.4817199, 4.5155838],
          [118.4839172, 4.5161228],
          [118.4854965, 4.5140436],
          [118.4844665, 4.5116478],
          [118.4823723, 4.5109889],
          [118.480175, 4.5109633],
        ],
      ],
      [
        [
          [118.47966, 4.5246535],
          [118.4797973, 4.5296418],
          [118.4842949, 4.5373168],
          [118.489067, 4.5460697],
          [118.4918136, 4.5532567],
          [118.4974785, 4.5558235],
          [118.5019417, 4.5541123],
          [118.5048599, 4.5517166],
          [118.5062332, 4.5440162],
          [118.5038643, 4.533552],
          [118.5038643, 4.5246535],
          [118.5045166, 4.515096],
          [118.4973411, 4.5150704],
          [118.4902687, 4.515096],
          [118.4848098, 4.5178084],
          [118.48069, 4.519862],
          [118.47966, 4.5246535],
        ],
      ],
      [
        [
          [118.6424871, 4.4173015],
          [118.6425538, 4.4176456],
          [118.6427303, 4.4177766],
          [118.6427244, 4.4179643],
          [118.6428293, 4.4182606],
          [118.642871, 4.4184674],
          [118.6427342, 4.4185979],
          [118.642847, 4.4186331],
          [118.6428843, 4.4187113],
          [118.6428195, 4.4188462],
          [118.6428784, 4.4189225],
          [118.6431304, 4.4186174],
          [118.6432706, 4.418591],
          [118.6433079, 4.4187651],
          [118.643099, 4.418856],
          [118.6430588, 4.4189635],
          [118.6433402, 4.4193654],
          [118.6437717, 4.4196127],
          [118.6437354, 4.4198522],
          [118.6430323, 4.4198503],
          [118.6428823, 4.4198483],
          [118.6428205, 4.4200214],
          [118.6428656, 4.4204027],
          [118.6430559, 4.4204907],
          [118.643047, 4.4208016],
          [118.6432334, 4.4209844],
          [118.6432912, 4.4212097],
          [118.6432726, 4.4215402],
          [118.6431691, 4.4216223],
          [118.6432486, 4.4217465],
          [118.6433672, 4.4216546],
          [118.6434403, 4.4216678],
          [118.6437531, 4.4219225],
          [118.6437771, 4.4219968],
          [118.6441605, 4.4219342],
          [118.6444606, 4.4222021],
          [118.6445165, 4.4224025],
          [118.6446165, 4.4225672],
          [118.644591, 4.4226767],
          [118.6446101, 4.4228679],
          [118.6449504, 4.4232526],
          [118.6450548, 4.4232008],
          [118.6454318, 4.4232848],
          [118.645727, 4.4230434],
          [118.6461879, 4.4231783],
          [118.6464644, 4.4231167],
          [118.6467998, 4.4232829],
          [118.6470351, 4.4236397],
          [118.6469194, 4.4240318],
          [118.6468919, 4.4242586],
          [118.6466566, 4.4243202],
          [118.6467046, 4.4245372],
          [118.6468811, 4.4245206],
          [118.6471871, 4.4242371],
          [118.6473548, 4.4242185],
          [118.6475352, 4.4242879],
          [118.6479078, 4.4240357],
          [118.6479618, 4.4238001],
          [118.6482501, 4.4237668],
          [118.6485884, 4.4240249],
          [118.648656, 4.424199],
          [118.6484795, 4.4243319],
          [118.6483138, 4.4246027],
          [118.6484266, 4.4248032],
          [118.6488384, 4.4250231],
          [118.6493356, 4.4250388],
          [118.6486854, 4.4253986],
          [118.6484697, 4.425685],
          [118.6485217, 4.4259695],
          [118.6484158, 4.4261533],
          [118.648505, 4.4264447],
          [118.6486962, 4.4264368],
          [118.6488865, 4.4265767],
          [118.6490031, 4.4267145],
          [118.6490787, 4.4266275],
          [118.6490492, 4.4262961],
          [118.6494787, 4.4261054],
          [118.649717, 4.4263019],
          [118.6491669, 4.4271447],
          [118.649262, 4.4272845],
          [118.6495758, 4.4273324],
          [118.6497406, 4.4276052],
          [118.6500239, 4.4276237],
          [118.6510202, 4.427348],
          [118.6517988, 4.4275709],
          [118.6519086, 4.4277254],
          [118.6517008, 4.4281575],
          [118.6514585, 4.4282817],
          [118.6513821, 4.4286972],
          [118.6512183, 4.4289328],
          [118.6508722, 4.4287979],
          [118.6504691, 4.4293298],
          [118.6504878, 4.4296299],
          [118.6512212, 4.4303837],
          [118.6516007, 4.4304296],
          [118.652094, 4.4303319],
          [118.6525333, 4.4303524],
          [118.6523323, 4.4306789],
          [118.6522214, 4.4310719],
          [118.6526068, 4.4311169],
          [118.6525294, 4.4312997],
          [118.65285, 4.4313476],
          [118.6530971, 4.4310915],
          [118.6533658, 4.4312391],
          [118.6534678, 4.4314073],
          [118.6532952, 4.4315236],
          [118.653152, 4.4318717],
          [118.6531658, 4.4321796],
          [118.6535354, 4.4323752],
          [118.6536227, 4.432337],
          [118.653556, 4.4322011],
          [118.6536757, 4.4320369],
          [118.6539247, 4.4321259],
          [118.6534786, 4.4327985],
          [118.6535129, 4.4328826],
          [118.6536708, 4.4329119],
          [118.6539404, 4.4327203],
          [118.654921, 4.4330361],
          [118.6541179, 4.4334017],
          [118.6531265, 4.4334261],
          [118.6530373, 4.4336637],
          [118.6533727, 4.4340303],
          [118.6532805, 4.4342405],
          [118.6539208, 4.4342904],
          [118.6538571, 4.4347352],
          [118.6541748, 4.4348633],
          [118.6544278, 4.4348398],
          [118.6545092, 4.4344576],
          [118.6551564, 4.4342386],
          [118.6556064, 4.4354734],
          [118.6553672, 4.4357236],
          [118.6558408, 4.4358263],
          [118.6561458, 4.4361587],
          [118.6562429, 4.4364021],
          [118.6565919, 4.4363513],
          [118.6572587, 4.4363914],
          [118.6575353, 4.4365361],
          [118.6583217, 4.4365214],
          [118.6583864, 4.4371715],
          [118.6572293, 4.4374971],
          [118.6564262, 4.4376604],
          [118.6561546, 4.4383115],
          [118.6556937, 4.4381609],
          [118.6551358, 4.4384972],
          [118.6548867, 4.4385178],
          [118.6546415, 4.4382968],
          [118.6543827, 4.4382274],
          [118.6543993, 4.4383887],
          [118.6546278, 4.4384132],
          [118.6546759, 4.4388208],
          [118.6545494, 4.4392305],
          [118.6547739, 4.4393409],
          [118.6549455, 4.4396372],
          [118.6554545, 4.4400556],
          [118.6556055, 4.4400566],
          [118.6557653, 4.439821],
          [118.6560516, 4.4398513],
          [118.6558418, 4.440345],
          [118.6557163, 4.4409365],
          [118.6554054, 4.4412796],
          [118.6558339, 4.4412425],
          [118.6560016, 4.4411398],
          [118.6559418, 4.4407898],
          [118.6563007, 4.4409052],
          [118.6568018, 4.441306],
          [118.6568989, 4.4412219],
          [118.6570361, 4.4417225],
          [118.6570107, 4.4419053],
          [118.6571577, 4.4420373],
          [118.6571921, 4.4423462],
          [118.6570979, 4.4424675],
          [118.6568567, 4.4423902],
          [118.6568528, 4.4426825],
          [118.6569852, 4.4428458],
          [118.6569273, 4.4432173],
          [118.656639, 4.4430198],
          [118.6564223, 4.4430707],
          [118.6563831, 4.4433209],
          [118.6572323, 4.4435096],
          [118.6578844, 4.4439505],
          [118.6569342, 4.4439857],
          [118.6567792, 4.4438997],
          [118.6565213, 4.4438596],
          [118.6563978, 4.4439877],
          [118.6564272, 4.4440962],
          [118.6565792, 4.4442898],
          [118.6564458, 4.4446105],
          [118.6560227, 4.4442189],
          [118.6560389, 4.4446329],
          [118.6551897, 4.4445039],
          [118.6548985, 4.4443133],
          [118.6548406, 4.4443739],
          [118.6549289, 4.4445782],
          [118.6550485, 4.444718],
          [118.6549681, 4.4447972],
          [118.6547435, 4.4448343],
          [118.6543003, 4.4447454],
          [118.6542817, 4.4448617],
          [118.6544788, 4.4450435],
          [118.6544003, 4.4452127],
          [118.6542905, 4.4452537],
          [118.6542493, 4.4453896],
          [118.65452, 4.4454776],
          [118.6545709, 4.4455832],
          [118.6545513, 4.44581],
          [118.6546072, 4.4459782],
          [118.6547455, 4.4461287],
          [118.6548053, 4.4461111],
          [118.6549043, 4.4456966],
          [118.6551152, 4.4458061],
          [118.6551544, 4.4459488],
          [118.6552946, 4.4459674],
          [118.6553966, 4.4461532],
          [118.6552515, 4.4461502],
          [118.6552054, 4.4464396],
          [118.6550308, 4.4466723],
          [118.6539502, 4.4468659],
          [118.6538973, 4.4472589],
          [118.654166, 4.4478161],
          [118.6543503, 4.4485503],
          [118.6552887, 4.4489463],
          [118.6560693, 4.4488837],
          [118.6566684, 4.4494263],
          [118.656436, 4.4498174],
          [118.656236, 4.450008],
          [118.6558967, 4.4500207],
          [118.6556442, 4.4498794],
          [118.6555667, 4.4499464],
          [118.6555623, 4.4500207],
          [118.6556329, 4.4501458],
          [118.6558153, 4.4502544],
          [118.6558594, 4.4505213],
          [118.6556849, 4.4506835],
          [118.6555525, 4.4508517],
          [118.6553172, 4.4508302],
          [118.655072, 4.4509094],
          [118.6548406, 4.4508243],
          [118.6546778, 4.4508546],
          [118.6545347, 4.4505662],
          [118.6542336, 4.4504606],
          [118.6540777, 4.4503404],
          [118.6540287, 4.4500461],
          [118.6539179, 4.449964],
          [118.6538208, 4.4499992],
          [118.6538433, 4.4500989],
          [118.6538051, 4.4502583],
          [118.6534354, 4.4501889],
          [118.6528284, 4.4497186],
          [118.6525951, 4.4497186],
          [118.6520312, 4.4494693],
          [118.6512909, 4.4492142],
          [118.6512487, 4.4492865],
          [118.6519881, 4.4496111],
          [118.6528039, 4.4498633],
          [118.6532751, 4.4501732],
          [118.6533001, 4.4502329],
          [118.6539434, 4.4505682],
          [118.6540248, 4.4508145],
          [118.6543278, 4.4509759],
          [118.6545415, 4.4512515],
          [118.6545847, 4.4514324],
          [118.6544572, 4.4516983],
          [118.6543052, 4.4517472],
          [118.6545415, 4.4518831],
          [118.6548769, 4.4518645],
          [118.6554613, 4.4520649],
          [118.6558388, 4.4520053],
          [118.6561203, 4.4521686],
          [118.655983, 4.4525684],
          [118.6556967, 4.452676],
          [118.6550563, 4.4524169],
          [118.6549573, 4.4527346],
          [118.6544739, 4.4525459],
          [118.6542405, 4.4527239],
          [118.6538169, 4.4527219],
          [118.6536521, 4.4530817],
          [118.6534099, 4.4529497],
          [118.653609, 4.4523191],
          [118.6534521, 4.4516885],
          [118.6528324, 4.4515008],
          [118.6527882, 4.4517814],
          [118.6524225, 4.452148],
          [118.6525901, 4.4528588],
          [118.6523019, 4.4530181],
          [118.6524529, 4.4538696],
          [118.652243, 4.4537758],
          [118.6520459, 4.4540935],
          [118.6517419, 4.4541053],
          [118.6514929, 4.453856],
          [118.6513664, 4.4539293],
          [118.6514929, 4.4544455],
          [118.6510143, 4.4546273],
          [118.6510075, 4.4548864],
          [118.6508388, 4.4552403],
          [118.6504279, 4.455513],
          [118.650578, 4.4557281],
          [118.6504544, 4.4562003],
          [118.6502965, 4.4562766],
          [118.6499926, 4.4559706],
          [118.6494297, 4.4561671],
          [118.6493581, 4.4562599],
          [118.6494944, 4.4564887],
          [118.6492924, 4.4566041],
          [118.6486717, 4.4565503],
          [118.6487256, 4.4568837],
          [118.6485707, 4.4575201],
          [118.648494639894835, 4.457583904266357],
          [118.6484472, 4.4576237],
          [118.648338075084723, 4.457583904266357],
          [118.6483079, 4.4575729],
          [118.648304303927432, 4.457583904266357],
          [118.6481814, 4.45796],
          [118.6479647, 4.4578857],
          [118.6479559, 4.4581849],
          [118.6471028, 4.4586375],
          [118.6471351, 4.4590384],
          [118.6464546, 4.4598703],
          [118.6459319, 4.4599642],
          [118.6458574, 4.4601411],
          [118.645173, 4.4605605],
          [118.6443659, 4.4602574],
          [118.6439266, 4.4605928],
          [118.6436128, 4.4605996],
          [118.6433844, 4.4603748],
          [118.6432951, 4.4606045],
          [118.6433383, 4.4610884],
          [118.6427362, 4.4614775],
          [118.6427087, 4.4618295],
          [118.6412614, 4.4621579],
          [118.6408338, 4.4618138],
          [118.6396767, 4.4627083],
          [118.6388746, 4.4627035],
          [118.6384245, 4.4632499],
          [118.6380794, 4.4633467],
          [118.638047, 4.4634807],
          [118.6385393, 4.4636517],
          [118.6385746, 4.4638922],
          [118.6387089, 4.4639352],
          [118.6387589, 4.4646773],
          [118.638452, 4.4647682],
          [118.6383059, 4.4650292],
          [118.6384285, 4.4654017],
          [118.6386324, 4.465473],
          [118.6386942, 4.4665337],
          [118.6381715, 4.4668886],
          [118.6379921, 4.4667596],
          [118.6377494, 4.4667606],
          [118.6376214, 4.4669727],
          [118.6373037, 4.4669639],
          [118.6371213, 4.4676052],
          [118.6374518, 4.4677802],
          [118.6373527, 4.4687989],
          [118.6368968, 4.4689934],
          [118.6364673, 4.4688282],
          [118.6362888, 4.4693473],
          [118.6365918, 4.4694988],
          [118.636027, 4.4701597],
          [118.635873, 4.4701636],
          [118.635267, 4.4705459],
          [118.6349699, 4.4703523],
          [118.634711, 4.4705947],
          [118.6344306, 4.4706729],
          [118.6341678, 4.4711197],
          [118.6341315, 4.4717943],
          [118.6339727, 4.4719448],
          [118.6336814, 4.4719419],
          [118.6332911, 4.4712849],
          [118.6332794, 4.4714805],
          [118.6329827, 4.4717131],
          [118.6326057, 4.4714746],
          [118.6323076, 4.4716095],
          [118.6323233, 4.4718319],
          [118.6322743, 4.4718925],
          [118.6320855, 4.471893],
          [118.6320551, 4.4719355],
          [118.6321566, 4.4720108],
          [118.6322145, 4.4722185],
          [118.6321389, 4.47233],
          [118.6317663, 4.4724229],
          [118.6319732, 4.4726516],
          [118.6324567, 4.4729097],
          [118.6325273, 4.4732343],
          [118.6327734, 4.4737847],
          [118.632492, 4.4741171],
          [118.632136, 4.4741249],
          [118.6318565, 4.4740652],
          [118.6315516, 4.4740887],
          [118.6314869, 4.4741825],
          [118.6315359, 4.4743683],
          [118.6316555, 4.4744475],
          [118.6316712, 4.4742334],
          [118.6319124, 4.4741562],
          [118.6320723, 4.4743732],
          [118.6319914, 4.4745404],
          [118.6318595, 4.4749666],
          [118.6315712, 4.4751914],
          [118.6314388, 4.4754202],
          [118.631481, 4.4756362],
          [118.6309299, 4.4760263],
          [118.6308681, 4.4764701],
          [118.6304376, 4.4767136],
          [118.630165, 4.4766842],
          [118.6299101, 4.4772327],
          [118.6295835, 4.4775074],
          [118.6294335, 4.4774331],
          [118.6292207, 4.477609],
          [118.6288765, 4.4775142],
          [118.6281244, 4.4776853],
          [118.6279656, 4.4779268],
          [118.6278489, 4.4778984],
          [118.6277547, 4.4775572],
          [118.6277479, 4.476738],
          [118.6274262, 4.4770068],
          [118.6270722, 4.477828],
          [118.6270232, 4.4782914],
          [118.6275459, 4.4790295],
          [118.627692, 4.4795066],
          [118.6278695, 4.4796004],
          [118.6279165, 4.4802456],
          [118.6276125, 4.480577],
          [118.627338, 4.481713],
          [118.6271615, 4.4817765],
          [118.6271281, 4.4821617],
          [118.6269644, 4.4826515],
          [118.6267428, 4.4829496],
          [118.6266388, 4.4833544],
          [118.6263054, 4.4840592],
          [118.6260004, 4.4844414],
          [118.6257014, 4.484767],
          [118.6256925, 4.4851697],
          [118.625974, 4.4852304],
          [118.6260397, 4.4854161],
          [118.6266172, 4.4852773],
          [118.62683, 4.4851453],
          [118.6269899, 4.4849684],
          [118.6274066, 4.4847122],
          [118.6275861, 4.4844864],
          [118.6277939, 4.4845372],
          [118.6279803, 4.4839702],
          [118.6284647, 4.4832879],
          [118.6287667, 4.4834013],
          [118.6291472, 4.483151],
          [118.6291432, 4.4826759],
          [118.6295139, 4.482627],
          [118.6306181, 4.4838041],
          [118.6318595, 4.4824648],
          [118.6321674, 4.4824472],
          [118.6322576, 4.4826896],
          [118.6319458, 4.4831784],
          [118.6317908, 4.484025],
          [118.6319418, 4.4840934],
          [118.632591, 4.4835773],
          [118.6330107, 4.4841188],
          [118.6327852, 4.4850299],
          [118.6334971, 4.4847562],
          [118.6333186, 4.4840778],
          [118.6336481, 4.4838119],
          [118.6338383, 4.483894],
          [118.6339609, 4.4844933],
          [118.6338952, 4.4848031],
          [118.6341599, 4.4851062],
          [118.6346914, 4.4850358],
          [118.6349199, 4.4848471],
          [118.6352778, 4.4849205],
          [118.63532, 4.485334],
          [118.6357966, 4.4852558],
          [118.6362702, 4.4846897],
          [118.6369703, 4.4845783],
          [118.6376881, 4.4835206],
          [118.6379705, 4.4838588],
          [118.6384098, 4.4838803],
          [118.6394002, 4.4833563],
          [118.6394708, 4.484025],
          [118.6390001, 4.4844884],
          [118.6389825, 4.4850114],
          [118.6396218, 4.4854757],
          [118.6396473, 4.4857749],
          [118.63931, 4.4859997],
          [118.6385981, 4.4859518],
          [118.6380676, 4.486466],
          [118.6382029, 4.486945],
          [118.6383981, 4.4871494],
          [118.6390227, 4.487036],
          [118.6393492, 4.4872813],
          [118.6394875, 4.4878728],
          [118.6401612, 4.4882384],
          [118.6402416, 4.4885571],
          [118.6393767, 4.4889618],
          [118.6394649, 4.4898964],
          [118.6387334, 4.490481],
          [118.6381549, 4.4902287],
          [118.6378019, 4.4903304],
          [118.6381078, 4.4915661],
          [118.6369605, 4.4930168],
          [118.6356053, 4.4940198],
          [118.6339638, 4.4955839],
          [118.6328597, 4.4961509],
          [118.6322027, 4.4952281],
          [118.631379, 4.4933971],
          [118.6301836, 4.492878],
          [118.6299267, 4.4926434],
          [118.629862, 4.4924156],
          [118.6299444, 4.492093],
          [118.6297973, 4.4919903],
          [118.6296051, 4.4920158],
          [118.6294325, 4.4918662],
          [118.6292972, 4.4918398],
          [118.6292246, 4.4919493],
          [118.6292374, 4.4920402],
          [118.6291678, 4.492135],
          [118.6291158, 4.4925652],
          [118.6289452, 4.4926922],
          [118.6288853, 4.4928271],
          [118.6288814, 4.4931722],
          [118.6289393, 4.4932817],
          [118.6290481, 4.493356],
          [118.6289981, 4.4937676],
          [118.6288804, 4.4942349],
          [118.6286775, 4.4944489],
          [118.6284147, 4.4944846],
          [118.6283347, 4.4944059],
          [118.6282891, 4.494425],
          [118.628244, 4.4945066],
          [118.6284264, 4.4951821],
          [118.6285108, 4.4954637],
          [118.6285363, 4.495711],
          [118.6286167, 4.4957364],
          [118.6287412, 4.4956123],
          [118.6287471, 4.4951029],
          [118.6288314, 4.4947178],
          [118.6292854, 4.4944294],
          [118.6294374, 4.4933883],
          [118.6297296, 4.4936268],
          [118.6297434, 4.4945848],
          [118.6302729, 4.4959143],
          [118.6296002, 4.4964637],
          [118.629107, 4.4968548],
          [118.6287608, 4.4969183],
          [118.6284921, 4.4971539],
          [118.6284941, 4.4975977],
          [118.6282352, 4.498238],
          [118.6285755, 4.4983817],
          [118.6285843, 4.4985733],
          [118.629005, 4.4989448],
          [118.6290315, 4.499155],
          [118.6294541, 4.499457],
          [118.6308191, 4.4996359],
          [118.6312927, 4.4998432],
          [118.6340638, 4.4990015],
          [118.6347738, 4.4984668],
          [118.6355338, 4.4982146],
          [118.6365124, 4.4980142],
          [118.6369497, 4.4979614],
          [118.6383069, 4.4975195],
          [118.6385265, 4.4975977],
          [118.6387001, 4.4973543],
          [118.6388785, 4.4974951],
          [118.6393061, 4.4973308],
          [118.6400283, 4.4968557],
          [118.6401989, 4.4969686],
          [118.6404029, 4.4968885],
          [118.6405333, 4.4967042],
          [118.6407735, 4.4967008],
          [118.6411467, 4.4965165],
          [118.6411927, 4.4963782],
          [118.6413045, 4.4962609],
          [118.641432, 4.4962252],
          [118.641485, 4.4960678],
          [118.6414908, 4.4957374],
          [118.6416124, 4.4955663],
          [118.641886, 4.4953454],
          [118.6418654, 4.4951899],
          [118.6420664, 4.4946581],
          [118.6427372, 4.4941097],
          [118.6429441, 4.4940892],
          [118.643049, 4.494141],
          [118.6432638, 4.4940051],
          [118.6435805, 4.4934411],
          [118.6443551, 4.4926258],
          [118.6447493, 4.4924342],
          [118.6450729, 4.4927773],
          [118.6455848, 4.4922709],
          [118.6467419, 4.4917606],
          [118.6477176, 4.4912728],
          [118.6484275, 4.4909697],
          [118.6487551, 4.4909414],
          [118.6488492, 4.491001],
          [118.6488355, 4.4911174],
          [118.6488972, 4.491135],
          [118.6489394, 4.4910313],
          [118.6492238, 4.491003],
          [118.6493905, 4.490784],
          [118.6504054, 4.4906569],
          [118.6505584, 4.4910704],
          [118.651282, 4.4910665],
          [118.6521312, 4.4913285],
          [118.6522126, 4.491701],
          [118.6524676, 4.4919845],
          [118.6528941, 4.4919151],
          [118.6531314, 4.4920353],
          [118.6534795, 4.4919444],
          [118.6541405, 4.4922171],
          [118.6544415, 4.4921966],
          [118.6544307, 4.4916912],
          [118.6548367, 4.491438],
          [118.6556672, 4.4913344],
          [118.6557447, 4.491549],
          [118.6559246, 4.4915055],
          [118.656058, 4.4916296],
          [118.6564498, 4.4915822],
          [118.6568449, 4.4917313],
          [118.6570028, 4.492051],
          [118.6572411, 4.4921409],
          [118.6576794, 4.4921037],
          [118.6580717, 4.492311],
          [118.6587453, 4.4922504],
          [118.6590513, 4.4923599],
          [118.6594513, 4.4924449],
          [118.6594994, 4.4927411],
          [118.6600475, 4.4930383],
          [118.6604398, 4.4927216],
          [118.6609683, 4.4927724],
          [118.6611811, 4.4928702],
          [118.6612605, 4.4927998],
          [118.6620803, 4.4928037],
          [118.6621539, 4.4929748],
          [118.6623353, 4.4931253],
          [118.662602, 4.4931449],
          [118.66265, 4.4932915],
          [118.6627422, 4.4933805],
          [118.6628334, 4.4933062],
          [118.662856, 4.4931615],
          [118.662651, 4.4931282],
          [118.6626736, 4.4929581],
          [118.6628805, 4.4928037],
          [118.6631815, 4.4927607],
          [118.6634198, 4.4930051],
          [118.6633355, 4.4932886],
          [118.6636306, 4.4934323],
          [118.6640523, 4.4932925],
          [118.6645641, 4.4934284],
          [118.6652957, 4.493312],
          [118.6652868, 4.4930755],
          [118.6652045, 4.493008],
          [118.6652123, 4.4926531],
          [118.6654192, 4.4930715],
          [118.6656653, 4.4932387],
          [118.666086, 4.4930979],
          [118.6663233, 4.4932759],
          [118.6665734, 4.4930363],
          [118.6667773, 4.4930383],
          [118.6669529, 4.4933374],
          [118.6672814, 4.4930813],
          [118.6676736, 4.4931664],
          [118.6677687, 4.4935163],
          [118.6680609, 4.4935564],
          [118.6681864, 4.4934596],
          [118.668208, 4.4932475],
          [118.6684718, 4.4932465],
          [118.6685983, 4.4939103],
          [118.6688219, 4.4941029],
          [118.6691612, 4.4941205],
          [118.6693867, 4.4939631],
          [118.6699084, 4.4938771],
          [118.6705261, 4.4940716],
          [118.6710508, 4.4948429],
          [118.6714753, 4.4948586],
          [118.6719048, 4.4938595],
          [118.6721265, 4.4938771],
          [118.672501, 4.4940648],
          [118.6724932, 4.4943268],
          [118.6723677, 4.4946689],
          [118.6720774, 4.4948019],
          [118.6722245, 4.4951069],
          [118.6727619, 4.4952418],
          [118.6729521, 4.4950111],
          [118.6728384, 4.4947627],
          [118.6729678, 4.4945125],
          [118.6731404, 4.4944753],
          [118.673211, 4.4942016],
          [118.6726776, 4.4932534],
          [118.6722137, 4.4929953],
          [118.6719156, 4.4932592],
          [118.6715332, 4.4933081],
          [118.6710772, 4.4929943],
          [118.6709429, 4.4927284],
          [118.6706154, 4.4926473],
          [118.6700476, 4.4923706],
          [118.670177, 4.4922386],
          [118.6709821, 4.4924488],
          [118.6709674, 4.4925622],
          [118.6712822, 4.4928721],
          [118.671596, 4.4930021],
          [118.6719715, 4.4926688],
          [118.6721794, 4.491919],
          [118.6721412, 4.4914654],
          [118.6724579, 4.49144],
          [118.6724932, 4.4915749],
          [118.6727099, 4.4915719],
          [118.673111, 4.4910343],
          [118.6729286, 4.4906061],
          [118.6726952, 4.4905142],
          [118.6720931, 4.4907508],
          [118.6714145, 4.4909189],
          [118.6717048, 4.4906041],
          [118.6720872, 4.4905474],
          [118.6725285, 4.4902248],
          [118.672704, 4.489781],
          [118.67301, 4.4899443],
          [118.6734336, 4.489738],
          [118.6737758, 4.4898133],
          [118.6740749, 4.489649],
          [118.6741867, 4.4894574],
          [118.6746515, 4.4892683],
          [118.6748858, 4.4892091],
          [118.6749182, 4.4894574],
          [118.6746397, 4.490041],
          [118.6746515, 4.4906149],
          [118.6748888, 4.4908544],
          [118.675078, 4.4908476],
          [118.6752918, 4.4906765],
          [118.6755438, 4.4904067],
          [118.6756223, 4.4897957],
          [118.6756644, 4.4894848],
          [118.6759008, 4.4892042],
          [118.6760518, 4.4891211],
          [118.6762508, 4.4892981],
          [118.6761547, 4.4895474],
          [118.676444, 4.4898797],
          [118.6766146, 4.4897057],
          [118.6768774, 4.4896686],
          [118.6771638, 4.489739],
          [118.6775158, 4.4895454],
          [118.6776001, 4.489605],
          [118.6775609, 4.4899404],
          [118.6778443, 4.4904223],
          [118.6778521, 4.4910695],
          [118.6776962, 4.4912875],
          [118.6778639, 4.4915191],
          [118.6780894, 4.4913754],
          [118.678364, 4.4913158],
          [118.6784287, 4.4909482],
          [118.6783885, 4.4907478],
          [118.6788445, 4.4900684],
          [118.6787964, 4.489826],
          [118.6789602, 4.4895728],
          [118.6792024, 4.4895268],
          [118.6795182, 4.4892287],
          [118.6799918, 4.4893421],
          [118.6804615, 4.4890253],
          [118.6804899, 4.4894037],
          [118.6801673, 4.4894965],
          [118.6800938, 4.4897663],
          [118.6798535, 4.4897546],
          [118.6797094, 4.489956],
          [118.6797829, 4.4901329],
          [118.6800741, 4.4901026],
          [118.6800388, 4.4902532],
          [118.6797349, 4.490306],
          [118.6796633, 4.490524],
          [118.6798064, 4.4909551],
          [118.6797349, 4.4912669],
          [118.6795074, 4.4915758],
          [118.6795721, 4.4917596],
          [118.6798212, 4.4917196],
          [118.6799378, 4.4915094],
          [118.6801791, 4.4913451],
          [118.6804399, 4.491481],
          [118.6806556, 4.4912953],
          [118.6806468, 4.4910616],
          [118.6809557, 4.4906208],
          [118.6813018, 4.4905083],
          [118.6817206, 4.4897497],
          [118.6819059, 4.4895826],
          [118.6817431, 4.4892932],
          [118.6813685, 4.4890869],
          [118.6814352, 4.488952],
          [118.6813322, 4.4885835],
          [118.6814234, 4.4884593],
          [118.6816706, 4.4884974],
          [118.6818392, 4.4881709],
          [118.6818069, 4.4878884],
          [118.6814146, 4.4877985],
          [118.6814028, 4.4876206],
          [118.6817098, 4.4877711],
          [118.6819539, 4.4876293],
          [118.6819804, 4.4874377],
          [118.6822452, 4.4872647],
          [118.6823177, 4.4876313],
          [118.6824913, 4.4878493],
          [118.6826609, 4.4878483],
          [118.6827865, 4.4873517],
          [118.6825413, 4.4871865],
          [118.68276, 4.4868463],
          [118.6827659, 4.4867182],
          [118.6828767, 4.4865706],
          [118.6830669, 4.4866156],
          [118.6832052, 4.4867847],
          [118.6833199, 4.486816],
          [118.6837975, 4.4864758],
          [118.6840249, 4.4863399],
          [118.6840985, 4.4859547],
          [118.6840877, 4.4858296],
          [118.6839504, 4.48577],
          [118.6840289, 4.4854865],
          [118.6842103, 4.4852343],
          [118.6840299, 4.4850299],
          [118.6838396, 4.4846067],
          [118.6835631, 4.4845118],
          [118.6834954, 4.4843261],
          [118.6840181, 4.4842713],
          [118.6840789, 4.4840152],
          [118.6839847, 4.4839321],
          [118.6834797, 4.4839214],
          [118.6831954, 4.483327],
          [118.6831973, 4.4830982],
          [118.6827227, 4.4822223],
          [118.6820187, 4.4820346],
          [118.6814774, 4.4824276],
          [118.6809106, 4.4820151],
          [118.6814735, 4.4819975],
          [118.6814813, 4.4816103],
          [118.6819157, 4.4816612],
          [118.6823805, 4.4811802],
          [118.6825472, 4.4807061],
          [118.6822824, 4.4802828],
          [118.6822452, 4.479968],
          [118.6825501, 4.479706],
          [118.6827031, 4.4801039],
          [118.6831954, 4.4805096],
          [118.6829208, 4.4812506],
          [118.6832758, 4.4815947],
          [118.6837896, 4.4814168],
          [118.6837484, 4.4810394],
          [118.684125, 4.4808869],
          [118.6842034, 4.4814187],
          [118.6844682, 4.4814715],
          [118.6847133, 4.4812017],
          [118.6845574, 4.4807774],
          [118.6841436, 4.4807012],
          [118.6841171, 4.4804509],
          [118.6839171, 4.4803668],
          [118.6841005, 4.4800716],
          [118.6840397, 4.4795926],
          [118.6836425, 4.4792387],
          [118.6832071, 4.4781829],
          [118.6835582, 4.4782631],
          [118.6837141, 4.4786795],
          [118.684022, 4.478657],
          [118.6843662, 4.4782826],
          [118.6845172, 4.4783784],
          [118.6849045, 4.4778808],
          [118.6849879, 4.4780519],
          [118.6849349, 4.4786433],
          [118.6850654, 4.4788447],
          [118.6853487, 4.478965],
          [118.68559, 4.4786873],
          [118.6856812, 4.4790647],
          [118.6854831, 4.4794694],
          [118.685536, 4.4796405],
          [118.6860548, 4.4794596],
          [118.6862754, 4.4790705],
          [118.6862175, 4.4788545],
          [118.6857626, 4.4785016],
          [118.6858841, 4.4782415],
          [118.6861175, 4.4782357],
          [118.6865921, 4.4777117],
          [118.6887337, 4.4780793],
          [118.6892083, 4.4785876],
          [118.6893849, 4.4782904],
          [118.6888906, 4.4771799],
          [118.688365, 4.4764721],
          [118.6882827, 4.4756001],
          [118.6887887, 4.4756705],
          [118.6892123, 4.4760576],
          [118.6894398, 4.4759911],
          [118.6897692, 4.4756666],
          [118.689479, 4.4733985],
          [118.689971923828125, 4.473662226641756],
          [118.6903341, 4.473856],
          [118.6923305, 4.4737074],
          [118.692158, 4.4713495],
          [118.692913, 4.4714081],
          [118.6932543, 4.4706788],
          [118.6938034, 4.4705654],
          [118.6943839, 4.4705283],
          [118.6973394, 4.4710405],
          [118.6982278, 4.4719243],
          [118.6995889, 4.4720025],
          [118.7015873, 4.4729762],
          [118.7029621, 4.4727122],
          [118.7036858, 4.4725832],
          [118.7048056, 4.4722254],
          [118.7049135, 4.4716994],
          [118.705239, 4.4713143],
          [118.7066354, 4.4710542],
          [118.7074807, 4.4702702],
          [118.7079219, 4.4687803],
          [118.7086299, 4.468182],
          [118.709081, 4.4679532],
          [118.7096517, 4.4678125],
          [118.7112971, 4.4680002],
          [118.7118757, 4.4679943],
          [118.7127092, 4.4686297],
          [118.7135211, 4.4673784],
          [118.7128347, 4.4666784],
          [118.714031, 4.4661486],
          [118.7145978, 4.4666452],
          [118.7144723, 4.4693903],
          [118.7136368, 4.4701411],
          [118.7136172, 4.4707394],
          [118.7155784, 4.4719438],
          [118.7168139, 4.4720885],
          [118.7181828, 4.4706065],
          [118.7185437, 4.4663519],
          [118.7191801, 4.4639929],
          [118.7194458, 4.463727],
          [118.7200244, 4.4634963],
          [118.7213011, 4.4629703],
          [118.723331, 4.4623838],
          [118.7254078, 4.4619458],
          [118.7259629, 4.4618823],
          [118.7263355, 4.4620152],
          [118.7267356, 4.4617688],
          [118.7275553, 4.4613895],
          [118.7272612, 4.4612038],
          [118.7274455, 4.4608264],
          [118.7277475, 4.4608245],
          [118.7280005, 4.4605683],
          [118.7292027, 4.4614325],
          [118.7292478, 4.4609183],
          [118.7296165, 4.460756],
          [118.7301539, 4.4608636],
          [118.7302735, 4.4607189],
          [118.7300205, 4.4593443],
          [118.7301225, 4.4588106],
          [118.7303873, 4.4587187],
          [118.7300088, 4.457743],
          [118.7301735, 4.457612],
          [118.7309384, 4.4584723],
          [118.7308952, 4.4585877],
          [118.7312953, 4.4589337],
          [118.7309737, 4.4595281],
          [118.7311561, 4.4597921],
          [118.7313679, 4.4597941],
          [118.7312816, 4.4609887],
          [118.7308805, 4.461764],
          [118.7310374, 4.4621491],
          [118.7312904, 4.4618089],
          [118.7314797, 4.4618207],
          [118.731515, 4.4620465],
          [118.7315591, 4.4621315],
          [118.7321268, 4.4617141],
          [118.73237, 4.4608147],
          [118.7335546, 4.4596611],
          [118.7332761, 4.4587617],
          [118.7335585, 4.4585231],
          [118.7332761, 4.4579913],
          [118.7333585, 4.4569277],
          [118.7341861, 4.4576707],
          [118.7343194, 4.4573265],
          [118.7341273, 4.4564467],
          [118.7340135, 4.4558445],
          [118.7341586, 4.455516],
          [118.7342332, 4.4544288],
          [118.7335664, 4.4528764],
          [118.7322288, 4.4513591],
          [118.7322171, 4.4509798],
          [118.7332486, 4.45097],
          [118.7341861, 4.4514627],
          [118.7353216, 4.4495563],
          [118.7358149, 4.4495945],
          [118.7359119, 4.4504949],
          [118.736465, 4.4503228],
          [118.7377005, 4.4541199],
          [118.7379751, 4.4541981],
          [118.7383006, 4.4531853],
          [118.7381202, 4.4523993],
          [118.7391361, 4.4509055],
          [118.7387635, 4.4501468],
          [118.738988, 4.4497],
          [118.7397294, 4.4501214],
          [118.7400471, 4.4504773],
          [118.7403373, 4.450662],
          [118.7401932, 4.450882],
          [118.7399853, 4.4514373],
          [118.7401344, 4.4517365],
          [118.7397382, 4.4520806],
          [118.7397519, 4.4526867],
          [118.7399441, 4.4529429],
          [118.7398853, 4.4530621],
          [118.7400245, 4.4532068],
          [118.7405344, 4.4528901],
          [118.7407404, 4.453157],
          [118.7402383, 4.4535988],
          [118.7402354, 4.4536898],
          [118.7403668, 4.4537875],
          [118.7402756, 4.454031],
          [118.7399981, 4.4541307],
          [118.7398539, 4.4544367],
          [118.7399078, 4.4545931],
          [118.740454, 4.4548942],
          [118.7402461, 4.4552618],
          [118.7403148, 4.4564369],
          [118.7402197, 4.4569247],
          [118.7409169, 4.457263],
          [118.7409463, 4.4574096],
          [118.7406109, 4.4585505],
          [118.7411189, 4.4586463],
          [118.7414013, 4.4589201],
          [118.7411875, 4.4598566],
          [118.7409894, 4.4604412],
          [118.7401873, 4.4611921],
          [118.7397853, 4.4617297],
          [118.7403873, 4.4624004],
          [118.7408698, 4.4637827],
          [118.7405835, 4.4652316],
          [118.7404893, 4.4660273],
          [118.7408423, 4.4661935],
          [118.7409051, 4.466655],
          [118.7407247, 4.466477],
          [118.7403834, 4.4666374],
          [118.7404187, 4.4671027],
          [118.7400873, 4.467441],
          [118.7391714, 4.4677479],
          [118.7392675, 4.468225],
          [118.7388282, 4.468704],
          [118.7375593, 4.4685867],
          [118.7367552, 4.4681507],
          [118.7358884, 4.4689543],
          [118.735149, 4.4694294],
          [118.7346685, 4.4694255],
          [118.7331604, 4.470059],
          [118.7323642, 4.4708998],
          [118.7327662, 4.4709037],
          [118.7334781, 4.4705185],
          [118.7345842, 4.4705459],
          [118.7359453, 4.4709389],
          [118.7370161, 4.4703816],
          [118.7374005, 4.4701705],
          [118.7385634, 4.4699006],
          [118.7394597, 4.4697286],
          [118.7399618, 4.4698791],
          [118.7403246, 4.4695683],
          [118.7406678, 4.469447],
          [118.7404619, 4.4691498],
          [118.7407266, 4.4689348],
          [118.7401697, 4.4685281],
          [118.740909, 4.4675348],
          [118.7417445, 4.4673667],
          [118.7422191, 4.467396],
          [118.7423171, 4.4671262],
          [118.7430761, 4.4668349],
          [118.7430447, 4.4665435],
          [118.7421759, 4.4663284],
          [118.7420877, 4.4657477],
          [118.7422152, 4.4653274],
          [118.7425682, 4.4647858],
          [118.7425093, 4.4644553],
          [118.7427172, 4.463599],
          [118.7430938, 4.4630867],
          [118.7434272, 4.4627895],
          [118.7434115, 4.4614013],
          [118.7436939, 4.4609789],
          [118.743941, 4.4593913],
          [118.7427682, 4.4581732],
          [118.7417778, 4.4564819],
          [118.7415111, 4.4557467],
          [118.7421681, 4.4553615],
          [118.7419386, 4.4561436],
          [118.742478, 4.4568651],
          [118.7427015, 4.4568905],
          [118.7432722, 4.4564134],
          [118.743337, 4.4569316],
          [118.7435429, 4.4573011],
          [118.7442979, 4.4575494],
          [118.7447863, 4.4573148],
          [118.7452962, 4.4572737],
          [118.7455492, 4.4569707],
          [118.7459728, 4.4568964],
          [118.7466572, 4.4570704],
          [118.747728, 4.4569374],
          [118.7480301, 4.4569707],
          [118.7487243, 4.4573226],
          [118.7504561, 4.4564486],
          [118.751117, 4.4563176],
          [118.7531311, 4.456212],
          [118.7532311, 4.4560771],
          [118.7534606, 4.4561632],
          [118.7536077, 4.4563802],
          [118.7538744, 4.4564428],
          [118.7541352, 4.4563821],
          [118.7542568, 4.4562101],
          [118.7541352, 4.4560439],
          [118.7532998, 4.4557291],
          [118.7525761, 4.4546674],
          [118.7521878, 4.4541219],
          [118.7520642, 4.4542646],
          [118.7520897, 4.4545501],
          [118.7519093, 4.4548629],
          [118.7514425, 4.4550076],
          [118.7507718, 4.454642],
          [118.7508306, 4.45438],
          [118.751013, 4.4543409],
          [118.7512464, 4.4539244],
          [118.7510366, 4.4538384],
          [118.7517916, 4.4534845],
          [118.7512131, 4.4531296],
          [118.7509522, 4.4533974],
          [118.7508228, 4.4533691],
          [118.7507296, 4.4532596],
          [118.7503639, 4.4521334],
          [118.7505404, 4.4514158],
          [118.7503854, 4.4511323],
          [118.7500815, 4.4512652],
          [118.7496912, 4.4509446],
          [118.7494304, 4.4509524],
          [118.7486204, 4.4504949],
          [118.746469, 4.4489267],
          [118.744749, 4.4475072],
          [118.7427976, 4.4457905],
          [118.7417268, 4.4447483],
          [118.740504, 4.4432975],
          [118.7404246, 4.4430433],
          [118.7405305, 4.4426972],
          [118.7404128, 4.4424733],
          [118.740557, 4.4422035],
          [118.7403668, 4.4420647],
          [118.7402275, 4.4415846],
          [118.740152, 4.4415622],
          [118.7401098, 4.4419102],
          [118.7398412, 4.4419141],
          [118.739544, 4.442661],
          [118.7392538, 4.4429074],
          [118.7384085, 4.4429074],
          [118.7379623, 4.4426913],
          [118.7379143, 4.4419865],
          [118.7363228, 4.441656],
          [118.735455, 4.4414136],
          [118.7348431, 4.440826],
          [118.7344146, 4.4404379],
          [118.7337438, 4.4403284],
          [118.733536, 4.4401397],
          [118.733432, 4.4403284],
          [118.7331736, 4.4403328],
          [118.7329152, 4.440212],
          [118.732373, 4.4407057],
          [118.7303902, 4.4409022],
          [118.7292135, 4.4409169],
          [118.7275495, 4.4407009],
          [118.7268797, 4.4404486],
          [118.726662, 4.4397877],
          [118.7265208, 4.4398718],
          [118.72646, 4.4404721],
          [118.7255824, 4.4406109],
          [118.7253647, 4.4406207],
          [118.7250391, 4.4403313],
          [118.724845, 4.4404173],
          [118.7244586, 4.4403763],
          [118.7244331, 4.4398757],
          [118.724288, 4.4398132],
          [118.7240546, 4.4400009],
          [118.7227661, 4.4390349],
          [118.722621, 4.4385451],
          [118.7222729, 4.438465],
          [118.722065, 4.4387524],
          [118.7221278, 4.4389137],
          [118.721963, 4.4390408],
          [118.721814, 4.4388805],
          [118.7215374, 4.4389509],
          [118.7218228, 4.4386253],
          [118.7216836, 4.4383858],
          [118.7218228, 4.4380632],
          [118.7217787, 4.4371725],
          [118.7219611, 4.4368137],
          [118.721813, 4.4366661],
          [118.721813, 4.4364422],
          [118.722063, 4.4363171],
          [118.7221376, 4.4360023],
          [118.7218699, 4.4360023],
          [118.7219091, 4.4362623],
          [118.7216992, 4.4362936],
          [118.7215786, 4.4358869],
          [118.7213286, 4.435752],
          [118.7198793, 4.4363552],
          [118.7202156, 4.4369007],
          [118.7199999, 4.4372273],
          [118.7196253, 4.4368049],
          [118.7194576, 4.4371979],
          [118.7197724, 4.4378559],
          [118.7190428, 4.437809],
          [118.7191174, 4.4375558],
          [118.7190203, 4.4373016],
          [118.7185643, 4.4374756],
          [118.7182917, 4.4369819],
          [118.7183623, 4.4366671],
          [118.7181691, 4.436451],
          [118.7180054, 4.4367912],
          [118.7176729, 4.4367942],
          [118.7177455, 4.4365067],
          [118.7176543, 4.4364403],
          [118.717617, 4.4360336],
          [118.7177671, 4.4358928],
          [118.7174131, 4.4356601],
          [118.7173523, 4.4357872],
          [118.7169022, 4.4360042],
          [118.7162177, 4.4360404],
          [118.7154862, 4.4359495],
          [118.715247, 4.4358204],
          [118.7150979, 4.4358146],
          [118.7150155, 4.4358937],
          [118.7147125, 4.4359241],
          [118.7145576, 4.4357041],
          [118.7142997, 4.4356454],
          [118.7141134, 4.4357598],
          [118.7142742, 4.4359847],
          [118.7139379, 4.436277],
          [118.7136221, 4.436364],
          [118.7130642, 4.4369291],
          [118.7124405, 4.4367336],
          [118.7122954, 4.436364],
          [118.712009, 4.4362623],
          [118.7117953, 4.4364305],
          [118.7115442, 4.4364051],
          [118.7114756, 4.4365693],
          [118.7118178, 4.4375196],
          [118.7122101, 4.4375294],
          [118.7122356, 4.4376809],
          [118.711454, 4.4386517],
          [118.7109637, 4.4386302],
          [118.7103734, 4.4378412],
          [118.7101204, 4.4381316],
          [118.7102812, 4.438597],
          [118.7097203, 4.4389567],
          [118.7093693, 4.4388159],
          [118.7087476, 4.4390232],
          [118.708779, 4.4386615],
          [118.7081475, 4.4382685],
          [118.7078729, 4.4386009],
          [118.7074964, 4.4385696],
          [118.7073993, 4.4382655],
          [118.7072012, 4.4382714],
          [118.7070855, 4.4384034],
          [118.7071875, 4.4387133],
          [118.7062961, 4.4391747],
          [118.7059103, 4.4396206],
          [118.7050978, 4.4397467],
          [118.7051106, 4.4395375],
          [118.70529, 4.4394104],
          [118.7052665, 4.439295],
          [118.7051135, 4.4393126],
          [118.7049939, 4.4392011],
          [118.7050135, 4.4389958],
          [118.7047036, 4.4389665],
          [118.7046017, 4.4388159],
          [118.7043251, 4.438945],
          [118.7043134, 4.439207],
          [118.7044997, 4.439469],
          [118.7037584, 4.4397604],
          [118.7031965, 4.439733],
          [118.7028337, 4.4395208],
          [118.7026689, 4.4396039],
          [118.7026719, 4.4399911],
          [118.7024522, 4.4400976],
          [118.7021973, 4.4398347],
          [118.702061, 4.4398092],
          [118.7019688, 4.4399246],
          [118.7019639, 4.4400673],
          [118.7014246, 4.440478],
          [118.7012677, 4.4411154],
          [118.7010961, 4.4407761],
          [118.7008538, 4.4407155],
          [118.7007499, 4.4403958],
          [118.7005479, 4.4402599],
          [118.7004126, 4.4403098],
          [118.7001959, 4.440257],
          [118.7000311, 4.4404799],
          [118.6991908, 4.4408299],
          [118.6988809, 4.4408983],
          [118.6982945, 4.440911],
          [118.6981347, 4.440824],
          [118.6980739, 4.4405884],
          [118.6980798, 4.4400106],
          [118.6976042, 4.4399011],
          [118.6973463, 4.4400087],
          [118.6971394, 4.4403821],
          [118.6964559, 4.4406529],
          [118.6961117, 4.4407282],
          [118.6951184, 4.4414038],
          [118.6945849, 4.4417714],
          [118.693573, 4.4419395],
          [118.6933229, 4.442269],
          [118.69266, 4.4425525],
          [118.6906753, 4.4426366],
          [118.6904772, 4.4423687],
          [118.6903733, 4.4425858],
          [118.689971923828125, 4.442645229648222],
          [118.689885, 4.4426581],
          [118.6892084, 4.4425506],
          [118.6888024, 4.4423648],
          [118.6882954, 4.4426561],
          [118.6881346, 4.443013],
          [118.6878443, 4.4430169],
          [118.6873139, 4.4433943],
          [118.6869167, 4.4425877],
          [118.6869098, 4.4422886],
          [118.6867981, 4.4421595],
          [118.6865058, 4.4421458],
          [118.6862264, 4.4422993],
          [118.6859646, 4.4422661],
          [118.6858508, 4.4423257],
          [118.6853713, 4.4422045],
          [118.685033, 4.4420891],
          [118.684631, 4.4422377],
          [118.6842309, 4.442531],
          [118.6837425, 4.442621],
          [118.6823442, 4.4426913],
          [118.6821893, 4.4420129],
          [118.6815813, 4.4417704],
          [118.6814244, 4.4414106],
          [118.6819284, 4.441],
          [118.6828149, 4.4410626],
          [118.6847506, 4.4404525],
          [118.6850369, 4.4400028],
          [118.685185, 4.4396968],
          [118.6850261, 4.4394475],
          [118.6850124, 4.4392745],
          [118.6850673, 4.4390525],
          [118.6849967, 4.4390037],
          [118.6848281, 4.4390721],
          [118.6847937, 4.4392002],
          [118.6846535, 4.4392794],
          [118.6844554, 4.4392422],
          [118.6844191, 4.4390506],
          [118.6843015, 4.4390701],
          [118.6840328, 4.4393341],
          [118.6832601, 4.4389978],
          [118.6829836, 4.4386576],
          [118.6829424, 4.4382039],
          [118.6827561, 4.438288],
          [118.6822913, 4.4393771],
          [118.6811263, 4.4385823],
          [118.682758, 4.4377738],
          [118.6818284, 4.4361548],
          [118.6805439, 4.435531],
          [118.6798094, 4.434919],
          [118.6791053, 4.4341672],
          [118.6790514, 4.4340059],
          [118.6782042, 4.4331006],
          [118.6780875, 4.4327848],
          [118.6782434, 4.4326342],
          [118.678059, 4.431724],
          [118.6781669, 4.4314787],
          [118.677958, 4.4313105],
          [118.6776746, 4.4313193],
          [118.6767548, 4.430631],
          [118.676699, 4.4304345],
          [118.6756183, 4.4297912],
          [118.675128, 4.4293806],
          [118.6738503, 4.4285946],
          [118.6733659, 4.4284841],
          [118.6730492, 4.4280666],
          [118.6728227, 4.4276257],
          [118.6728344, 4.427395],
          [118.6731943, 4.4272248],
          [118.6731796, 4.4269628],
          [118.6724324, 4.4258796],
          [118.6717323, 4.4256371],
          [118.6708399, 4.4259597],
          [118.6698397, 4.426779],
          [118.6700515, 4.4273715],
          [118.6702653, 4.4273109],
          [118.6705301, 4.4276912],
          [118.6702526, 4.428004],
          [118.6694985, 4.4279864],
          [118.6693269, 4.4281272],
          [118.6691925, 4.4280676],
          [118.6691651, 4.4278701],
          [118.6687679, 4.4275983],
          [118.6695691, 4.4276022],
          [118.6697848, 4.4271261],
          [118.6683296, 4.4263352],
          [118.6679864, 4.4263841],
          [118.6677569, 4.4268025],
          [118.668106, 4.4274419],
          [118.6676883, 4.427348],
          [118.6675294, 4.4270254],
          [118.6672882, 4.4273617],
          [118.666794, 4.4273813],
          [118.6667724, 4.4267067],
          [118.6670293, 4.4263449],
          [118.667302, 4.4262746],
          [118.6677932, 4.425204],
          [118.6675491, 4.4248452],
          [118.6674412, 4.4243534],
          [118.6675716, 4.4242048],
          [118.667706, 4.4237707],
          [118.6676785, 4.4236564],
          [118.6674932, 4.4235987],
          [118.6670392, 4.4237747],
          [118.6671303, 4.4242009],
          [118.6669107, 4.4243368],
          [118.6665616, 4.4243847],
          [118.6657056, 4.4242478],
          [118.6654643, 4.4241931],
          [118.6652246, 4.4244918],
          [118.6656546, 4.4252676],
          [118.6661301, 4.4262814],
          [118.6656771, 4.4272102],
          [118.6653045, 4.4271339],
          [118.6650642, 4.4269531],
          [118.6649005, 4.4267037],
          [118.664874, 4.4263762],
          [118.6642817, 4.4260086],
          [118.6634669, 4.4258571],
          [118.6630325, 4.4256958],
          [118.6625834, 4.4253849],
          [118.6622411, 4.4253155],
          [118.661945, 4.4250241],
          [118.6617636, 4.4245411],
          [118.6616307, 4.4243808],
          [118.6615959, 4.4244111],
          [118.661517, 4.4243138],
          [118.6616047, 4.4242278],
          [118.6615969, 4.4241188],
          [118.6608477, 4.4231245],
          [118.6600358, 4.4227432],
          [118.6599358, 4.4224695],
          [118.6601887, 4.4219259],
          [118.6593474, 4.4216697],
          [118.6591689, 4.4214243],
          [118.6591974, 4.4211183],
          [118.6596975, 4.4211897],
          [118.65977, 4.4210518],
          [118.6597328, 4.4208363],
          [118.6594754, 4.4207747],
          [118.6593111, 4.4206207],
          [118.6592856, 4.4204486],
          [118.6589655, 4.4203645],
          [118.6584158, 4.4203988],
          [118.6578569, 4.4202541],
          [118.6568449, 4.4196567],
          [118.6558565, 4.4195003],
          [118.6557986, 4.4193086],
          [118.6558683, 4.4191014],
          [118.6561575, 4.4188716],
          [118.6564056, 4.4184532],
          [118.6566586, 4.4183642],
          [118.6566233, 4.4177659],
          [118.6562517, 4.4180553],
          [118.6559693, 4.4181032],
          [118.6560987, 4.4175747],
          [118.6559104, 4.4175982],
          [118.6558545, 4.4178832],
          [118.6551936, 4.4179301],
          [118.6547984, 4.4174657],
          [118.6542248, 4.4174872],
          [118.6532334, 4.4171705],
          [118.6529392, 4.4173435],
          [118.6525333, 4.4173201],
          [118.6520003, 4.4170375],
          [118.6514512, 4.4165135],
          [118.6509163, 4.4165966],
          [118.6500612, 4.4162642],
          [118.6497479, 4.4163248],
          [118.649236, 4.4159855],
          [118.6491708, 4.415614],
          [118.6490728, 4.4155392],
          [118.6489482, 4.4155671],
          [118.6487453, 4.415397],
          [118.6482932, 4.4157118],
          [118.6473234, 4.4160735],
          [118.6454583, 4.4158887],
          [118.6451484, 4.4163346],
          [118.6448425, 4.4162339],
          [118.6446444, 4.4158574],
          [118.6446385, 4.4154791],
          [118.6442291, 4.4153245],
          [118.6440463, 4.4158154],
          [118.6439129, 4.4159102],
          [118.6437266, 4.4155827],
          [118.6431382, 4.4156228],
          [118.643203, 4.4159777],
          [118.6427901, 4.41627],
          [118.6425517, 4.416259],
          [118.6428244, 4.4166171],
          [118.6424871, 4.4173015],
        ],
      ],
      [
        [
          [118.501241, 4.485564],
          [118.5010216, 4.4853584],
          [118.5006031, 4.4853718],
          [118.5002652, 4.484714],
          [118.5003108, 4.4838864],
          [118.4991494, 4.4826136],
          [118.4968641, 4.4830254],
          [118.4956893, 4.4834024],
          [118.4922373, 4.483619],
          [118.4919128, 4.4853999],
          [118.492071, 4.4856459],
          [118.4917036, 4.4864548],
          [118.4917089, 4.4868465],
          [118.4911913, 4.4868572],
          [118.4909016, 4.4872837],
          [118.4926343, 4.4878359],
          [118.4981436, 4.4870176],
          [118.4993988, 4.4873706],
          [118.4999675, 4.4872717],
          [118.5006514, 4.4865898],
          [118.5008499, 4.4859694],
          [118.501241, 4.485564],
        ],
      ],
      [
        [
          [118.6317813, 4.4841284],
          [118.6315821, 4.48423],
          [118.6314292, 4.4846324],
          [118.6312897, 4.4848423],
          [118.6307285, 4.4852047],
          [118.6302544, 4.4853684],
          [118.6300613, 4.4855189],
          [118.630068, 4.4856439],
          [118.629958, 4.4857929],
          [118.6296677, 4.4857782],
          [118.6292258, 4.4861098],
          [118.6284674, 4.4868371],
          [118.6283145, 4.4869367],
          [118.6277459, 4.4871928],
          [118.6274837, 4.4875524],
          [118.6273677, 4.487646],
          [118.6273885, 4.4877329],
          [118.6275326, 4.4877115],
          [118.6276265, 4.4875979],
          [118.6279625, 4.4874949],
          [118.6284446, 4.4872048],
          [118.6284372, 4.4871092],
          [118.6287356, 4.4869581],
          [118.6288711, 4.4870531],
          [118.6291185, 4.4868732],
          [118.6293458, 4.4868813],
          [118.6294779, 4.4868238],
          [118.6296328, 4.4866627],
          [118.6297026, 4.4866941],
          [118.6297937, 4.4865991],
          [118.6297072, 4.4861325],
          [118.6300419, 4.486073],
          [118.6302068, 4.4866085],
          [118.6302973, 4.4866239],
          [118.6304502, 4.4868722],
          [118.6303406, 4.4870574],
          [118.6303768, 4.4871737],
          [118.6304469, 4.4871914],
          [118.6305106, 4.4871009],
          [118.6305133, 4.4869481],
          [118.6307184, 4.4866553],
          [118.6316176, 4.4854975],
          [118.6319362, 4.4858518],
          [118.6320662, 4.4858444],
          [118.6321662, 4.4854126],
          [118.6321232, 4.4852227],
          [118.6324015, 4.484978],
          [118.6322875, 4.4848169],
          [118.6321655, 4.4847902],
          [118.6319509, 4.4842795],
          [118.6317813, 4.4841284],
        ],
      ],
      [
        [
          [118.6922979, 4.4851372],
          [118.6922803, 4.485296],
          [118.6913628, 4.4857318],
          [118.6910456, 4.4856655],
          [118.6908172, 4.4860701],
          [118.6908994, 4.486277],
          [118.6908159, 4.4865581],
          [118.6906084, 4.4867558],
          [118.6906267, 4.4869601],
          [118.690872, 4.4868938],
          [118.6909908, 4.4872295],
          [118.6909503, 4.4874533],
          [118.6905614, 4.487681],
          [118.6903173, 4.4876719],
          [118.6901568, 4.4883576],
          [118.6895421, 4.48861],
          [118.6892249, 4.4885319],
          [118.6891244, 4.4886516],
          [118.6893163, 4.4889756],
          [118.6894311, 4.488934],
          [118.6895669, 4.4890302],
          [118.6898436, 4.4895156],
          [118.6900798, 4.4895533],
          [118.6902299, 4.4897225],
          [118.690333, 4.4895182],
          [118.690778, 4.4893829],
          [118.6907624, 4.4890498],
          [118.6908681, 4.4886061],
          [118.6915259, 4.4882548],
          [118.6918574, 4.4882431],
          [118.692121, 4.4883719],
          [118.6922359, 4.4889183],
          [118.6926653, 4.488649],
          [118.6928415, 4.4887765],
          [118.6945251, 4.4880492],
          [118.6953226, 4.4875495],
          [118.69532, 4.4874468],
          [118.6950994, 4.4874051],
          [118.6948305, 4.4875482],
          [118.6939156, 4.4874311],
          [118.6935998, 4.4870902],
          [118.6934014, 4.4863369],
          [118.6935006, 4.4854586],
          [118.6934275, 4.4852842],
          [118.6930784, 4.485212],
          [118.6927762, 4.4853025],
          [118.6925061, 4.4852914],
          [118.6922979, 4.4851372],
        ],
      ],
      [
        [
          [118.6011804, 4.4920379],
          [118.6010961, 4.4919685],
          [118.6010499, 4.4918925],
          [118.6009003, 4.4918709],
          [118.6007942, 4.4919332],
          [118.6007398, 4.4920552],
          [118.600631, 4.4922125],
          [118.6006202, 4.4923724],
          [118.6006909, 4.4924917],
          [118.6006501, 4.492668],
          [118.6005685, 4.4928198],
          [118.6005495, 4.4929635],
          [118.6004298, 4.4930286],
          [118.6002857, 4.4931099],
          [118.6001959, 4.4931831],
          [118.6000219, 4.4931099],
          [118.5999049, 4.4930204],
          [118.5997635, 4.4929282],
          [118.5996058, 4.4929255],
          [118.5994943, 4.4930123],
          [118.5994807, 4.4931506],
          [118.5994145, 4.4932319],
          [118.5994309, 4.4933675],
          [118.599526, 4.4935302],
          [118.5996511, 4.4935356],
          [118.5997436, 4.4934461],
          [118.599847, 4.4935058],
          [118.5999095, 4.4936061],
          [118.5998605, 4.4936956],
          [118.5999204, 4.4937688],
          [118.5999476, 4.4938257],
          [118.5999231, 4.4939477],
          [118.5998388, 4.494029],
          [118.5998415, 4.4940995],
          [118.5999231, 4.494151],
          [118.5998905, 4.4942188],
          [118.5999313, 4.4942785],
          [118.6000346, 4.4943625],
          [118.6000591, 4.4944818],
          [118.6001461, 4.4945496],
          [118.6002712, 4.4946065],
          [118.6003691, 4.494574],
          [118.6004126, 4.4944547],
          [118.6004643, 4.4943544],
          [118.6004861, 4.4941727],
          [118.6005649, 4.4940263],
          [118.6006275, 4.4938121],
          [118.6007988, 4.4937037],
          [118.6008016, 4.493541],
          [118.6008689, 4.4933588],
          [118.6010247, 4.4932572],
          [118.6011381, 4.4932208],
          [118.6012285, 4.4931192],
          [118.6012401, 4.4929869],
          [118.6012978, 4.4928336],
          [118.6013093, 4.4926323],
          [118.6013035, 4.4924118],
          [118.6012651, 4.4922488],
          [118.6011804, 4.4920379],
        ],
      ],
      [
        [
          [118.599272, 4.4975952],
          [118.5992049, 4.4975744],
          [118.5991123, 4.4975901],
          [118.5990179, 4.4976451],
          [118.5989037, 4.4977432],
          [118.59888, 4.4978452],
          [118.5988387, 4.4979767],
          [118.5988882, 4.498182],
          [118.5988952, 4.4982473],
          [118.5988674, 4.4983666],
          [118.5988019, 4.4984971],
          [118.5986975, 4.4985553],
          [118.5986822, 4.4986252],
          [118.5987073, 4.4986973],
          [118.5986975, 4.498739],
          [118.5985328, 4.498862],
          [118.5985132, 4.499023],
          [118.598462, 4.4991172],
          [118.5983754, 4.4991643],
          [118.598336, 4.4992192],
          [118.5983242, 4.4993998],
          [118.598273, 4.499439],
          [118.5982474, 4.4995325],
          [118.5984172, 4.4995908],
          [118.5985203, 4.4995603],
          [118.5985537, 4.4994076],
          [118.59864, 4.4993244],
          [118.5988126, 4.4992439],
          [118.5989601, 4.4991301],
          [118.5989796, 4.498958],
          [118.5990687, 4.4988303],
          [118.5990576, 4.4987221],
          [118.5989963, 4.4986416],
          [118.599052, 4.4985389],
          [118.599091, 4.498464],
          [118.5990826, 4.4983696],
          [118.5992385, 4.4983196],
          [118.5993221, 4.4982336],
          [118.5993861, 4.4980921],
          [118.5993889, 4.4979783],
          [118.5995643, 4.4978811],
          [118.5996311, 4.4977812],
          [118.599606, 4.4977285],
          [118.5997091, 4.4976285],
          [118.599748, 4.4975536],
          [118.5996951, 4.4975203],
          [118.5996478, 4.4975564],
          [118.5995197, 4.4975508],
          [118.5994529, 4.4975425],
          [118.5994195, 4.4975758],
          [118.599272, 4.4975952],
        ],
      ],
      [
        [
          [118.6028757, 4.4888962],
          [118.6027941, 4.4888718],
          [118.6026608, 4.4888718],
          [118.6025983, 4.4890074],
          [118.6025031, 4.4890806],
          [118.6024922, 4.4891538],
          [118.6024786, 4.4893246],
          [118.6024242, 4.4894737],
          [118.6024242, 4.4895822],
          [118.6023127, 4.4897503],
          [118.6022855, 4.4899428],
          [118.6021876, 4.4900946],
          [118.6019782, 4.4901624],
          [118.6018504, 4.4901868],
          [118.6018341, 4.4902654],
          [118.6016491, 4.490325],
          [118.6016301, 4.490382],
          [118.6016845, 4.4904416],
          [118.6017144, 4.490523],
          [118.6017607, 4.4906423],
          [118.6017987, 4.4908022],
          [118.601796, 4.490889],
          [118.6018776, 4.4909513],
          [118.602, 4.4911384],
          [118.6021305, 4.491152],
          [118.6023834, 4.4911113],
          [118.6024645, 4.4910439],
          [118.6025466, 4.4909757],
          [118.6026636, 4.4908212],
          [118.6027397, 4.4906206],
          [118.6027207, 4.4903738],
          [118.6028023, 4.4901515],
          [118.6029246, 4.4899672],
          [118.6031558, 4.4898912],
          [118.6033407, 4.4897828],
          [118.6033978, 4.4897069],
          [118.6035148, 4.4895659],
          [118.6035071, 4.4894469],
          [118.6034985, 4.4893978],
          [118.6034686, 4.4894005],
          [118.6033163, 4.4894574],
          [118.6031014, 4.4893897],
          [118.6029709, 4.4892975],
          [118.602911, 4.4891673],
          [118.6028757, 4.4888962],
        ],
      ],
      [
        [
          [118.8633566, 4.5994816],
          [118.863873, 4.599472],
          [118.864165, 4.5994415],
          [118.8646655, 4.5992454],
          [118.8649674, 4.598926],
          [118.8655294, 4.598412],
          [118.8659124, 4.5979872],
          [118.8663446, 4.5974603],
          [118.8666663, 4.5969264],
          [118.8667784, 4.596708],
          [118.8669636, 4.5964169],
          [118.8671671, 4.596001],
          [118.8673862, 4.5955333],
          [118.8676678, 4.5948163],
          [118.867751, 4.5943809],
          [118.867813, 4.5941134],
          [118.8678118, 4.5937136],
          [118.8677782, 4.5934654],
          [118.8678361, 4.5931978],
          [118.8677263, 4.5930229],
          [118.8676531, 4.5929313],
          [118.8674753, 4.5928623],
          [118.8673076, 4.5928388],
          [118.867181, 4.5928126],
          [118.8669903, 4.5927707],
          [118.8668488, 4.5926557],
          [118.8666495, 4.5926128],
          [118.8664595, 4.5925686],
          [118.8663195, 4.5925114],
          [118.8661126, 4.5924074],
          [118.8658531, 4.5924878],
          [118.8657012, 4.5925546],
          [118.8654574, 4.5925814],
          [118.8652312, 4.5925792],
          [118.8648416, 4.5927133],
          [118.8640258, 4.5930352],
          [118.862824, 4.5934612],
          [118.8625172, 4.5935643],
          [118.8621895, 4.5936779],
          [118.8618641, 4.5938719],
          [118.861697, 4.5940916],
          [118.8615402, 4.5943478],
          [118.8615059, 4.5945402],
          [118.8615499, 4.5949614],
          [118.8616377, 4.5955022],
          [118.8617196, 4.596407],
          [118.8618102, 4.5969684],
          [118.8619706, 4.5973318],
          [118.8620931, 4.5976009],
          [118.8622025, 4.5977957],
          [118.8623587, 4.5983311],
          [118.8625041, 4.598653],
          [118.8627113, 4.5989951],
          [118.8629896, 4.5992395],
          [118.8633566, 4.5994816],
        ],
      ],
      [
        [
          [118.6863542, 4.6013002],
          [118.6863434, 4.6014713],
          [118.6864293, 4.601621],
          [118.6865419, 4.6020756],
          [118.6868638, 4.6032038],
          [118.6869657, 4.6041449],
          [118.6871374, 4.6055886],
          [118.6870623, 4.6065939],
          [118.6869764, 4.6069788],
          [118.686896, 4.6073104],
          [118.6869496, 4.607706],
          [118.6872125, 4.6079894],
          [118.6875236, 4.6080322],
          [118.6877865, 4.6078985],
          [118.6888754, 4.6071606],
          [118.6898249, 4.6064121],
          [118.6900932, 4.6061928],
          [118.6901522, 4.6059629],
          [118.6900556, 4.6058185],
          [118.6897391, 4.605626],
          [118.6892348, 4.6050806],
          [118.6889934, 4.6047277],
          [118.6889076, 4.6043374],
          [118.6889452, 4.6034284],
          [118.6888432, 4.6027921],
          [118.6886769, 4.602236],
          [118.6885107, 4.6019686],
          [118.6881995, 4.6017761],
          [118.6874163, 4.6014767],
          [118.6866814, 4.6011345],
          [118.6865151, 4.6011612],
          [118.6864132, 4.60122],
          [118.6863542, 4.6013002],
        ],
      ],
      [
        [
          [118.7919018, 4.6365329],
          [118.7919474, 4.6365476],
          [118.7920721, 4.6366024],
          [118.7921445, 4.6365971],
          [118.7922089, 4.6365717],
          [118.792284, 4.6365409],
          [118.792343, 4.6365142],
          [118.792406, 4.6364821],
          [118.7925026, 4.6364286],
          [118.7926005, 4.6363565],
          [118.792685, 4.636299],
          [118.7927896, 4.6362388],
          [118.7929264, 4.6361653],
          [118.7930551, 4.6360971],
          [118.7932134, 4.6360129],
          [118.7933193, 4.6359675],
          [118.7935084, 4.6358605],
          [118.7936385, 4.6358017],
          [118.7937619, 4.6357376],
          [118.7938692, 4.6356961],
          [118.7939402, 4.6356587],
          [118.7940221, 4.6356159],
          [118.7940717, 4.6355905],
          [118.794136, 4.6355518],
          [118.7942165, 4.6355143],
          [118.7943466, 4.6354381],
          [118.7944163, 4.6353927],
          [118.7944646, 4.6353553],
          [118.7945209, 4.6352964],
          [118.794584, 4.6352122],
          [118.7946215, 4.6351815],
          [118.7946604, 4.635128],
          [118.7946779, 4.6350906],
          [118.7946779, 4.6350291],
          [118.7946631, 4.6349502],
          [118.7946363, 4.6348981],
          [118.7946014, 4.6348286],
          [118.7945612, 4.6347711],
          [118.794474, 4.6346829],
          [118.7944083, 4.6346334],
          [118.7943359, 4.63458],
          [118.7942635, 4.6345305],
          [118.7941253, 4.6344303],
          [118.7940395, 4.6343621],
          [118.7939657, 4.6343166],
          [118.7938665, 4.6342592],
          [118.7938061, 4.6342097],
          [118.7936962, 4.6341054],
          [118.7936009, 4.6340172],
          [118.7935473, 4.633953],
          [118.7934548, 4.6338996],
          [118.7933622, 4.633822],
          [118.7932952, 4.6337606],
          [118.7932321, 4.6337231],
          [118.7931544, 4.633687],
          [118.7930685, 4.633655],
          [118.7930122, 4.6336242],
          [118.792972, 4.6336095],
          [118.7929103, 4.6335908],
          [118.7927775, 4.6336068],
          [118.7927252, 4.6336336],
          [118.7926756, 4.6336723],
          [118.792626, 4.6337378],
          [118.7925643, 4.6338234],
          [118.7924784, 4.6339129],
          [118.7924208, 4.6339758],
          [118.7923658, 4.6340065],
          [118.7922947, 4.6340319],
          [118.792229, 4.6340533],
          [118.7921686, 4.6340774],
          [118.7920976, 4.6341134],
          [118.7920332, 4.6341536],
          [118.7920077, 4.6341843],
          [118.7919836, 4.6342338],
          [118.7919635, 4.6342805],
          [118.791934, 4.6343327],
          [118.7919058, 4.6343795],
          [118.7918749, 4.6344383],
          [118.7918334, 4.6344984],
          [118.7917931, 4.6345599],
          [118.7917529, 4.6346134],
          [118.791718, 4.6346789],
          [118.7917006, 4.6347257],
          [118.7916952, 4.6347805],
          [118.7916765, 4.6348794],
          [118.7916563, 4.634985],
          [118.7916349, 4.6351187],
          [118.7916295, 4.6352443],
          [118.7916121, 4.6353726],
          [118.7915893, 4.6355063],
          [118.7915826, 4.6355558],
          [118.7915853, 4.6356066],
          [118.7916107, 4.6357095],
          [118.7916322, 4.6357937],
          [118.7916563, 4.6358739],
          [118.7917033, 4.6360129],
          [118.7917167, 4.6360945],
          [118.7917247, 4.6361773],
          [118.7917274, 4.6362682],
          [118.7917395, 4.6363364],
          [118.7917703, 4.6364046],
          [118.7918079, 4.6364554],
          [118.7918709, 4.6364968],
          [118.7919018, 4.6365329],
        ],
      ],
      [
        [
          [118.7470204, 4.6241321],
          [118.7467736, 4.6241054],
          [118.7465751, 4.6241161],
          [118.7463069, 4.6241588],
          [118.7461406, 4.6241428],
          [118.7460011, 4.6240786],
          [118.7458938, 4.623977],
          [118.7457973, 4.6238647],
          [118.7457597, 4.6237578],
          [118.7457758, 4.6236134],
          [118.7458885, 4.6233728],
          [118.7459421, 4.6232498],
          [118.7459475, 4.6230306],
          [118.7459636, 4.6228809],
          [118.7459528, 4.6227419],
          [118.745926, 4.6225975],
          [118.7458456, 4.622405],
          [118.7457651, 4.6222393],
          [118.7457544, 4.622143],
          [118.7457651, 4.6220093],
          [118.7457544, 4.6218222],
          [118.7457275, 4.621619],
          [118.7457436, 4.6214479],
          [118.7457758, 4.6212447],
          [118.7457758, 4.6209988],
          [118.7457597, 4.6207528],
          [118.745749, 4.6205764],
          [118.7457383, 4.6204373],
          [118.7457168, 4.620325],
          [118.7457007, 4.6201272],
          [118.7457275, 4.6199668],
          [118.74569, 4.6197583],
          [118.7456739, 4.6195604],
          [118.7456471, 4.6193252],
          [118.745572, 4.6190845],
          [118.7455559, 4.6189936],
          [118.7455451, 4.6188439],
          [118.7455344, 4.6187263],
          [118.7454379, 4.6186782],
          [118.7453359, 4.618614],
          [118.7452286, 4.6185392],
          [118.7451428, 4.6184536],
          [118.7450409, 4.6182932],
          [118.7449872, 4.6181381],
          [118.7449497, 4.6180312],
          [118.7449121, 4.6178066],
          [118.744896, 4.6176729],
          [118.7448156, 4.6175232],
          [118.7447244, 4.6173735],
          [118.7447351, 4.6172986],
          [118.7446922, 4.6171596],
          [118.7445849, 4.617042],
          [118.7444347, 4.6169404],
          [118.7442845, 4.6168174],
          [118.7441772, 4.6167051],
          [118.7440914, 4.6165928],
          [118.7440163, 4.6164592],
          [118.7439519, 4.6163362],
          [118.7438822, 4.6162132],
          [118.7438285, 4.6160688],
          [118.7437695, 4.6159993],
          [118.7436837, 4.6159298],
          [118.7435442, 4.6158389],
          [118.7434208, 4.6157587],
          [118.7433189, 4.6156945],
          [118.7432224, 4.6156143],
          [118.7430721, 4.6155448],
          [118.742879, 4.6154486],
          [118.7426591, 4.6153363],
          [118.7425464, 4.6152507],
          [118.7424177, 4.6151812],
          [118.7422568, 4.615101],
          [118.7420636, 4.614962],
          [118.7418812, 4.6148925],
          [118.741672, 4.6147374],
          [118.7414896, 4.6146519],
          [118.7413126, 4.6145931],
          [118.7411678, 4.6145022],
          [118.7409908, 4.6143792],
          [118.7407869, 4.6142936],
          [118.7407118, 4.6142669],
          [118.7404919, 4.6141867],
          [118.7403202, 4.6141011],
          [118.7400466, 4.6140209],
          [118.7399232, 4.6139835],
          [118.7397247, 4.6139086],
          [118.7394726, 4.6138017],
          [118.7392634, 4.6137215],
          [118.7390166, 4.6135771],
          [118.738786, 4.613406],
          [118.738668, 4.6133258],
          [118.738507, 4.6131707],
          [118.7384212, 4.6130103],
          [118.7383354, 4.6128071],
          [118.7382388, 4.6126253],
          [118.7381262, 4.6124115],
          [118.7380671, 4.6122564],
          [118.7379277, 4.6119837],
          [118.7376863, 4.6117003],
          [118.7375414, 4.6115025],
          [118.7375092, 4.6113581],
          [118.7373537, 4.61108],
          [118.7372571, 4.6109303],
          [118.7370425, 4.6107539],
          [118.7368655, 4.6106309],
          [118.7367207, 4.6104972],
          [118.7366348, 4.6103368],
          [118.7365651, 4.6101978],
          [118.7364203, 4.6100962],
          [118.7363344, 4.6100374],
          [118.7361842, 4.6099625],
          [118.7360823, 4.6098663],
          [118.7360287, 4.6097754],
          [118.7358892, 4.6097219],
          [118.7354171, 4.6096631],
          [118.7352991, 4.609647],
          [118.7351167, 4.6096096],
          [118.7349182, 4.6095401],
          [118.734768, 4.6094011],
          [118.7347037, 4.6093155],
          [118.734591, 4.6092246],
          [118.734473, 4.6091391],
          [118.7342745, 4.6090107],
          [118.7342101, 4.6089733],
          [118.7340653, 4.608845],
          [118.7339151, 4.6087273],
          [118.7337971, 4.6086578],
          [118.7333465, 4.6086578],
          [118.7332231, 4.608583],
          [118.7330192, 4.608476],
          [118.7328583, 4.6083905],
          [118.7326491, 4.6082889],
          [118.7323755, 4.6081392],
          [118.7323058, 4.6081017],
          [118.7321985, 4.6080376],
          [118.7320805, 4.6079841],
          [118.7318659, 4.6079306],
          [118.7316513, 4.6078932],
          [118.7314689, 4.6078451],
          [118.7312812, 4.6077595],
          [118.7309325, 4.6076205],
          [118.7308466, 4.6075991],
          [118.7306857, 4.6075029],
          [118.7305087, 4.6073318],
          [118.730455, 4.6072622],
          [118.7303746, 4.6071767],
          [118.7302887, 4.6071179],
          [118.7301385, 4.6070911],
          [118.7299883, 4.6070911],
          [118.7297738, 4.6070751],
          [118.7295431, 4.6069628],
          [118.7294894, 4.6068933],
          [118.7293231, 4.6067596],
          [118.7291515, 4.6067061],
          [118.7288886, 4.6066366],
          [118.7286526, 4.6065136],
          [118.7285399, 4.6064388],
          [118.7283629, 4.6062891],
          [118.7281376, 4.6061126],
          [118.7279391, 4.6060057],
          [118.7276924, 4.6058934],
          [118.7275153, 4.605733],
          [118.7273276, 4.6055458],
          [118.727231, 4.6054228],
          [118.7271613, 4.6052731],
          [118.7271291, 4.6051394],
          [118.7270272, 4.6049576],
          [118.7268877, 4.6046956],
          [118.7267375, 4.6044657],
          [118.7266463, 4.6043267],
          [118.7265551, 4.6042091],
          [118.7263888, 4.6040861],
          [118.7262547, 4.6039684],
          [118.7260348, 4.6037866],
          [118.7257987, 4.6036262],
          [118.7255734, 4.6034123],
          [118.7254983, 4.6033268],
          [118.7253427, 4.6032412],
          [118.7251389, 4.6031824],
          [118.7248009, 4.6030648],
          [118.7245542, 4.6029525],
          [118.7243825, 4.6028616],
          [118.7242377, 4.6027814],
          [118.7240499, 4.6027867],
          [118.7238997, 4.6028455],
          [118.7237173, 4.6029525],
          [118.7235403, 4.603038],
          [118.7234169, 4.6030915],
          [118.7232131, 4.6031022],
          [118.7229556, 4.6030541],
          [118.7226927, 4.6029792],
          [118.7225174, 4.6029578],
          [118.7223387, 4.6029578],
          [118.722285, 4.6030113],
          [118.7222153, 4.6030915],
          [118.7221938, 4.6031984],
          [118.7222046, 4.6033856],
          [118.7222099, 4.603546],
          [118.7222046, 4.6037652],
          [118.7221777, 4.6039203],
          [118.7221187, 4.6040914],
          [118.7221241, 4.6042625],
          [118.7221777, 4.6044229],
          [118.7222475, 4.6045406],
          [118.7223011, 4.6047277],
          [118.7222904, 4.604979],
          [118.7222904, 4.6052999],
          [118.7222949, 4.6053421],
          [118.7223065, 4.6054496],
          [118.7223709, 4.6056046],
          [118.7224728, 4.6057864],
          [118.7225479, 4.6059201],
          [118.7226498, 4.6060805],
          [118.7227732, 4.6061982],
          [118.7229502, 4.6063158],
          [118.7230682, 4.6064067],
          [118.7231755, 4.6065243],
          [118.7233204, 4.6066313],
          [118.7234437, 4.6066955],
          [118.7235403, 4.6068131],
          [118.7236315, 4.6069735],
          [118.7237442, 4.6072141],
          [118.7237763, 4.6072997],
          [118.7237951, 4.6073825],
          [118.7238219, 4.6075109],
          [118.7238675, 4.6076392],
          [118.7239239, 4.6077996],
          [118.7240285, 4.6080295],
          [118.724066, 4.6081766],
          [118.7240848, 4.6082996],
          [118.7240767, 4.6084439],
          [118.7240365, 4.6084947],
          [118.7239614, 4.6085215],
          [118.723838, 4.6086204],
          [118.7237549, 4.6087113],
          [118.7237227, 4.6087648],
          [118.7236556, 4.6088423],
          [118.72361, 4.6089038],
          [118.7235805, 4.609],
          [118.7235162, 4.6091016],
          [118.7234625, 4.6091417],
          [118.7233579, 4.6091471],
          [118.7232587, 4.609254],
          [118.7232533, 4.6093369],
          [118.7232801, 4.6094198],
          [118.7233043, 4.6095027],
          [118.7233204, 4.6095722],
          [118.7233713, 4.6096791],
          [118.7234357, 4.6097861],
          [118.7235135, 4.609893],
          [118.7235752, 4.6099812],
          [118.7236744, 4.6100641],
          [118.7237683, 4.6101069],
          [118.7238246, 4.6101844],
          [118.7238112, 4.6102673],
          [118.7237951, 4.6103555],
          [118.7238112, 4.6104678],
          [118.7238353, 4.6105988],
          [118.7238353, 4.6107164],
          [118.7238434, 4.6108688],
          [118.7238568, 4.610949],
          [118.7238863, 4.6110907],
          [118.723889, 4.6111977],
          [118.723889, 4.6113314],
          [118.7239078, 4.6114169],
          [118.7240177, 4.6115212],
          [118.7241036, 4.6116201],
          [118.7241518, 4.611727],
          [118.7242028, 4.6118046],
          [118.7242779, 4.611858],
          [118.7243798, 4.6119222],
          [118.7244683, 4.6120078],
          [118.7244979, 4.6120853],
          [118.7245247, 4.6121655],
          [118.7245998, 4.6122243],
          [118.7246883, 4.6122992],
          [118.7247607, 4.6124248],
          [118.7248331, 4.6126307],
          [118.7248546, 4.6126975],
          [118.7248975, 4.6128285],
          [118.724986, 4.613013],
          [118.7251228, 4.6132349],
          [118.7251684, 4.613406],
          [118.7252167, 4.6136119],
          [118.7252516, 4.6137268],
          [118.7252864, 4.6138632],
          [118.7253213, 4.6139835],
          [118.7254044, 4.6140931],
          [118.7254178, 4.6142],
          [118.7254071, 4.6143043],
          [118.7254313, 4.614438],
          [118.7254795, 4.6145209],
          [118.7255681, 4.6145984],
          [118.7257451, 4.6146519],
          [118.725855, 4.6146813],
          [118.7259221, 4.6146893],
          [118.7259516, 4.6147722],
          [118.7259918, 4.6148952],
          [118.7260616, 4.6149887],
          [118.7261635, 4.6150983],
          [118.7262064, 4.615208],
          [118.7262574, 4.6154299],
          [118.7262815, 4.6155422],
          [118.7262949, 4.6156892],
          [118.7263352, 4.6159191],
          [118.7263861, 4.616018],
          [118.7265283, 4.6161865],
          [118.7265927, 4.616256],
          [118.7266597, 4.6163469],
          [118.726708, 4.6165073],
          [118.7267268, 4.6166249],
          [118.7267858, 4.6166918],
          [118.7269065, 4.6168067],
          [118.7270138, 4.6169564],
          [118.7270567, 4.6170901],
          [118.7270567, 4.6171569],
          [118.7270755, 4.617288],
          [118.7271291, 4.6174296],
          [118.7271881, 4.6175633],
          [118.727282, 4.6176756],
          [118.7274161, 4.6177799],
          [118.7274805, 4.6178467],
          [118.727577, 4.6179884],
          [118.7276146, 4.6181461],
          [118.7276226, 4.618237],
          [118.727687, 4.6183253],
          [118.7277862, 4.6184483],
          [118.7279284, 4.6185632],
          [118.7280491, 4.61863],
          [118.7281859, 4.6186889],
          [118.7282449, 4.6187423],
          [118.7283388, 4.6188012],
          [118.7284327, 4.6188493],
          [118.7284997, 4.6189054],
          [118.7285507, 4.619007],
          [118.7286016, 4.6190712],
          [118.728666, 4.6191353],
          [118.7287062, 4.6192316],
          [118.7287706, 4.6193091],
          [118.7289208, 4.6194134],
          [118.7290201, 4.6194749],
          [118.7291327, 4.6195123],
          [118.7292159, 4.6195604],
          [118.7293446, 4.61967],
          [118.7294304, 4.6197342],
          [118.7295109, 4.6197957],
          [118.7295619, 4.6198946],
          [118.7296209, 4.6199775],
          [118.7297416, 4.6199989],
          [118.729881, 4.6199962],
          [118.7299883, 4.6199989],
          [118.7300822, 4.6200363],
          [118.7301519, 4.6201085],
          [118.7301922, 4.6202074],
          [118.7303263, 4.620325],
          [118.7304255, 4.620432],
          [118.7304684, 4.6205042],
          [118.7304872, 4.6206111],
          [118.7304497, 4.6207421],
          [118.7303638, 4.6208651],
          [118.7303558, 4.6209533],
          [118.7303638, 4.6210576],
          [118.7304041, 4.6211538],
          [118.7304309, 4.6212822],
          [118.7304255, 4.6214292],
          [118.7304524, 4.621595],
          [118.730514, 4.6217046],
          [118.7305999, 4.6217874],
          [118.7306616, 4.621857],
          [118.730793, 4.6219131],
          [118.7309298, 4.6219826],
          [118.7310317, 4.6220388],
          [118.7311766, 4.622119],
          [118.7313428, 4.6222633],
          [118.7315467, 4.6223435],
          [118.7316915, 4.6223836],
          [118.7318203, 4.6224451],
          [118.7318712, 4.6225253],
          [118.7319517, 4.6226537],
          [118.732067, 4.622774],
          [118.7321851, 4.6228381],
          [118.7323326, 4.6229424],
          [118.7324452, 4.6230386],
          [118.7325472, 4.6231215],
          [118.7326866, 4.6232097],
          [118.7327725, 4.6232953],
          [118.7328717, 4.6234236],
          [118.7329388, 4.6235065],
          [118.7330219, 4.6236108],
          [118.7330836, 4.6237043],
          [118.733156, 4.6237631],
          [118.7332499, 4.623854],
          [118.7333384, 4.6240519],
          [118.7334752, 4.6242069],
          [118.7335852, 4.6242872],
          [118.73362, 4.6243513],
          [118.7337139, 4.6244288],
          [118.7338454, 4.624509],
          [118.7338936, 4.6245572],
          [118.7339446, 4.6246267],
          [118.7340867, 4.6247069],
          [118.7341914, 4.6247443],
          [118.7342826, 4.6248272],
          [118.7344167, 4.6249208],
          [118.7345508, 4.625009],
          [118.7346822, 4.6250999],
          [118.7348083, 4.6251346],
          [118.7348968, 4.6251587],
          [118.7350014, 4.6251908],
          [118.7350658, 4.6252443],
          [118.7350953, 4.6253271],
          [118.7351784, 4.6254367],
          [118.7353367, 4.6254982],
          [118.7354815, 4.6255811],
          [118.7355593, 4.6256774],
          [118.735629, 4.6257789],
          [118.7356532, 4.6258244],
          [118.7357524, 4.6258592],
          [118.7358221, 4.6258805],
          [118.7358731, 4.6259501],
          [118.7359267, 4.626057],
          [118.7359616, 4.6261586],
          [118.7359804, 4.6262522],
          [118.7360018, 4.6264393],
          [118.7361172, 4.6265676],
          [118.7362137, 4.6266024],
          [118.736313, 4.6266051],
          [118.7363666, 4.6266505],
          [118.7364981, 4.626712],
          [118.736608, 4.6267147],
          [118.7366939, 4.6267601],
          [118.7367743, 4.6268163],
          [118.7368709, 4.6268296],
          [118.7369674, 4.6268163],
          [118.7371069, 4.6267708],
          [118.7372169, 4.626704],
          [118.7373134, 4.6266558],
          [118.7374717, 4.6266799],
          [118.7376031, 4.626728],
          [118.737705, 4.6267895],
          [118.7377909, 4.6268671],
          [118.7379169, 4.6269366],
          [118.7380296, 4.6270141],
          [118.7381154, 4.627097],
          [118.7381852, 4.6272306],
          [118.738279, 4.6273937],
          [118.7383649, 4.6275087],
          [118.7384587, 4.6276023],
          [118.7385714, 4.6277065],
          [118.7387243, 4.6277787],
          [118.7388718, 4.6277974],
          [118.739014, 4.6278242],
          [118.7391615, 4.6278856],
          [118.7392554, 4.6279231],
          [118.7394243, 4.6279632],
          [118.7395316, 4.6280166],
          [118.739647, 4.6280808],
          [118.7397543, 4.6281236],
          [118.739883, 4.6281557],
          [118.7399769, 4.6282011],
          [118.7400842, 4.6282359],
          [118.7402076, 4.6282653],
          [118.7403738, 4.6282679],
          [118.7405026, 4.6282332],
          [118.7406099, 4.6281771],
          [118.7406823, 4.6281129],
          [118.7407225, 4.6280434],
          [118.7407923, 4.6279979],
          [118.7409613, 4.6280407],
          [118.7411302, 4.628038],
          [118.741377, 4.6280059],
          [118.7415218, 4.6279391],
          [118.7415674, 4.6279097],
          [118.7417123, 4.6278856],
          [118.7418464, 4.6278402],
          [118.7419751, 4.6278509],
          [118.7421066, 4.6279097],
          [118.7422246, 4.6279471],
          [118.7423184, 4.6278856],
          [118.7424445, 4.6278669],
          [118.7425357, 4.6278803],
          [118.7426484, 4.6279284],
          [118.7427556, 4.6279017],
          [118.7428415, 4.6278669],
          [118.7429354, 4.6278696],
          [118.7430426, 4.6278589],
          [118.7430936, 4.6277921],
          [118.7431419, 4.6276958],
          [118.7431982, 4.6276156],
          [118.7432653, 4.6275354],
          [118.7433323, 4.6274392],
          [118.743453, 4.6273322],
          [118.7435201, 4.6272948],
          [118.7435415, 4.6272066],
          [118.7435496, 4.6271237],
          [118.7435791, 4.6270168],
          [118.743622, 4.626958],
          [118.743799, 4.6269366],
          [118.7438822, 4.6269633],
          [118.7439734, 4.6269713],
          [118.7442309, 4.626966],
          [118.744373, 4.6269392],
          [118.7445179, 4.6269339],
          [118.7445956, 4.6269927],
          [118.7447244, 4.6270542],
          [118.7448183, 4.6271237],
          [118.744888, 4.6271932],
          [118.7450007, 4.627252],
          [118.7450516, 4.6272975],
          [118.745167, 4.627383],
          [118.7452474, 4.6274579],
          [118.7453225, 4.6275541],
          [118.7454432, 4.6276878],
          [118.7455264, 4.6277653],
          [118.7455988, 4.6278402],
          [118.7457463, 4.6279605],
          [118.7458214, 4.62803],
          [118.7458804, 4.6281209],
          [118.7460092, 4.6282278],
          [118.7461191, 4.6282573],
          [118.7462023, 4.6282867],
          [118.7462881, 4.6283107],
          [118.7464142, 4.6283508],
          [118.7465081, 4.6284123],
          [118.7465912, 4.6284738],
          [118.7466395, 4.628538],
          [118.7467039, 4.6286235],
          [118.7468326, 4.6287171],
          [118.746956, 4.6288133],
          [118.7470767, 4.6289203],
          [118.7471706, 4.6289898],
          [118.7472349, 4.629078],
          [118.74731, 4.6291796],
          [118.7473959, 4.6292785],
          [118.747471, 4.6293801],
          [118.7475407, 4.629463],
          [118.7476721, 4.6294977],
          [118.7478358, 4.6294817],
          [118.7478733, 4.6294817],
          [118.7479538, 4.629479],
          [118.7480503, 4.629463],
          [118.7481442, 4.6294496],
          [118.748289, 4.6294363],
          [118.7483561, 4.6294523],
          [118.7484822, 4.6294897],
          [118.7485868, 4.6295191],
          [118.7486994, 4.6295699],
          [118.7488764, 4.6296421],
          [118.748965, 4.6296822],
          [118.7490025, 4.629733],
          [118.7490508, 4.6297785],
          [118.7491366, 4.6297972],
          [118.7492707, 4.6298105],
          [118.7493539, 4.629848],
          [118.7494585, 4.6298988],
          [118.7496328, 4.6299923],
          [118.7497643, 4.6300725],
          [118.7499386, 4.6301447],
          [118.7501022, 4.6302169],
          [118.7502363, 4.6303158],
          [118.7503704, 4.6304201],
          [118.7505609, 4.6305404],
          [118.750754, 4.6306206],
          [118.7509069, 4.6306313],
          [118.751159, 4.630658],
          [118.7514058, 4.6307382],
          [118.7515426, 4.6308077],
          [118.7517035, 4.6308559],
          [118.751851, 4.6308826],
          [118.7519932, 4.6308906],
          [118.7521487, 4.6308692],
          [118.7522963, 4.6308398],
          [118.7523918, 4.6308424],
          [118.753109, 4.6305912],
          [118.753463, 4.6301955],
          [118.7536454, 4.6292545],
          [118.7539405, 4.6285808],
          [118.7544035, 4.6282331],
          [118.7563276, 4.6275221],
          [118.7606155, 4.6273509],
          [118.7625718, 4.6274365],
          [118.7661552, 4.628399],
          [118.7671637, 4.628014],
          [118.7693524, 4.6276504],
          [118.7714982, 4.6265596],
          [118.7730646, 4.6249128],
          [118.7748885, 4.6227526],
          [118.7756825, 4.6203144],
          [118.7759828, 4.6175339],
          [118.77658, 4.61655],
          [118.7791371, 4.6148604],
          [118.7797379, 4.6147535],
          [118.7811112, 4.614946],
          [118.7815189, 4.6148818],
          [118.7818837, 4.6145396],
          [118.7820768, 4.6139193],
          [118.781476, 4.6129355],
          [118.780725, 4.6117378],
          [118.7767982, 4.6093209],
          [118.7748885, 4.6084654],
          [118.7730002, 4.6085081],
          [118.7719666, 4.6088289],
          [118.7708115, 4.6111389],
          [118.770318, 4.6132777],
          [118.7695455, 4.6148818],
          [118.7681686, 4.6169136],
          [118.766284, 4.6179831],
          [118.7640095, 4.6181114],
          [118.7613702, 4.6175767],
          [118.7578689, 4.6186247],
          [118.7517196, 4.6229985],
          [118.7516177, 4.6230948],
          [118.7514728, 4.6232445],
          [118.7513012, 4.6233728],
          [118.7511402, 4.6234851],
          [118.7509578, 4.6236562],
          [118.7507003, 4.623838],
          [118.7505394, 4.6239075],
          [118.7502766, 4.6239984],
          [118.7501156, 4.6241374],
          [118.7499815, 4.6241802],
          [118.7497991, 4.6242551],
          [118.7496221, 4.6243139],
          [118.7493861, 4.6243727],
          [118.7491822, 4.6244315],
          [118.7490052, 4.6244903],
          [118.7487477, 4.6245545],
          [118.7484688, 4.6245866],
          [118.7482488, 4.6245705],
          [118.7480128, 4.6245278],
          [118.7478036, 4.6244796],
          [118.747648, 4.6244048],
          [118.7474763, 4.6243139],
          [118.7472188, 4.6241963],
          [118.7470204, 4.6241321],
        ],
      ],
      [
        [
          [118.7205094, 4.5999795],
          [118.7205738, 4.6001078],
          [118.7206596, 4.6002201],
          [118.7207723, 4.6003538],
          [118.7208635, 4.6004233],
          [118.7209976, 4.6005516],
          [118.7211049, 4.600712],
          [118.7212604, 4.6009259],
          [118.7214106, 4.601097],
          [118.7215072, 4.6012895],
          [118.7216788, 4.6015676],
          [118.7217969, 4.6016906],
          [118.7217325, 4.6014874],
          [118.7216574, 4.6013751],
          [118.7216306, 4.6012307],
          [118.7215984, 4.6010917],
          [118.7215555, 4.6009687],
          [118.7215662, 4.6008618],
          [118.7217057, 4.6007976],
          [118.7217915, 4.6006425],
          [118.7218612, 4.6005142],
          [118.721872, 4.6003377],
          [118.7218505, 4.6001559],
          [118.7218344, 4.5999314],
          [118.7218344, 4.5996266],
          [118.7218237, 4.5994287],
          [118.7218344, 4.5992148],
          [118.7218881, 4.5990063],
          [118.7218559, 4.5988138],
          [118.7218988, 4.5986641],
          [118.7219793, 4.5985144],
          [118.7220865, 4.5983539],
          [118.7221938, 4.5981401],
          [118.7222421, 4.5979262],
          [118.7222689, 4.5976856],
          [118.7222475, 4.5974717],
          [118.7222153, 4.5973433],
          [118.7221724, 4.5971615],
          [118.7221295, 4.5969904],
          [118.7221509, 4.5968567],
          [118.7221241, 4.5967444],
          [118.7220114, 4.5965787],
          [118.7219042, 4.5964343],
          [118.7217647, 4.5963113],
          [118.7216252, 4.5961937],
          [118.7214214, 4.5959584],
          [118.7213731, 4.5958889],
          [118.7212443, 4.5957445],
          [118.7210995, 4.5956109],
          [118.7209117, 4.5955093],
          [118.7206596, 4.5954237],
          [118.7204236, 4.5953916],
          [118.7201983, 4.5954184],
          [118.7200159, 4.5954665],
          [118.7197638, 4.5954932],
          [118.7195545, 4.5955681],
          [118.7193936, 4.595659],
          [118.7193024, 4.5957392],
          [118.7191951, 4.5958943],
          [118.7190288, 4.5960226],
          [118.7188679, 4.596183],
          [118.7186801, 4.5964878],
          [118.7185728, 4.5966749],
          [118.7185192, 4.5968514],
          [118.7184387, 4.5970332],
          [118.7183958, 4.5972845],
          [118.7183958, 4.5974449],
          [118.7184763, 4.5976749],
          [118.7185138, 4.5979048],
          [118.7185085, 4.598124],
          [118.718546, 4.5983005],
          [118.718664, 4.5984823],
          [118.7188733, 4.598648],
          [118.7189698, 4.5988085],
          [118.7192166, 4.5989207],
          [118.7194312, 4.5991025],
          [118.7195975, 4.5992362],
          [118.7199247, 4.5994448],
          [118.7200963, 4.5996159],
          [118.7203646, 4.599803],
          [118.7205094, 4.5999795],
        ],
      ],
      [
        [
          [118.7822726, 4.6122511],
          [118.7824014, 4.6122698],
          [118.7825784, 4.6122457],
          [118.7827125, 4.6122751],
          [118.7828788, 4.6122297],
          [118.7830317, 4.6121441],
          [118.7830076, 4.6120559],
          [118.7831041, 4.611973],
          [118.7831444, 4.6118313],
          [118.7830397, 4.6116762],
          [118.783206, 4.6115506],
          [118.7833455, 4.6114624],
          [118.7833482, 4.6113688],
          [118.7834179, 4.6111977],
          [118.7835252, 4.6110881],
          [118.7835011, 4.6109116],
          [118.7834984, 4.610802],
          [118.783493, 4.6106282],
          [118.783544, 4.6106336],
          [118.7836325, 4.6108448],
          [118.7837908, 4.6107833],
          [118.7839329, 4.6106523],
          [118.784, 4.6105159],
          [118.7840375, 4.6103154],
          [118.7841073, 4.6102513],
          [118.784228, 4.610409],
          [118.7844184, 4.6104331],
          [118.7845767, 4.6104304],
          [118.7847134, 4.6103716],
          [118.7847081, 4.6102299],
          [118.7847885, 4.6100748],
          [118.78481, 4.6098529],
          [118.7848958, 4.6098369],
          [118.785089, 4.6097834],
          [118.7852418, 4.6097406],
          [118.7852901, 4.6099144],
          [118.7853625, 4.6100695],
          [118.7854913, 4.6101684],
          [118.7857488, 4.6101684],
          [118.7858748, 4.6102085],
          [118.7858695, 4.6100775],
          [118.7861001, 4.6101256],
          [118.786245, 4.6101176],
          [118.7863576, 4.6100347],
          [118.7864435, 4.6100695],
          [118.7864622, 4.6099304],
          [118.7864676, 4.6097861],
          [118.786599, 4.6096684],
          [118.78676, 4.6096203],
          [118.7868699, 4.6096283],
          [118.786996, 4.6097994],
          [118.787334, 4.6098636],
          [118.7874895, 4.6098422],
          [118.7875995, 4.6098262],
          [118.7876961, 4.6098556],
          [118.7878248, 4.6098315],
          [118.7879267, 4.6098315],
          [118.788026, 4.6096871],
          [118.7880447, 4.6095829],
          [118.7879267, 4.6095053],
          [118.7880474, 4.6094118],
          [118.7881842, 4.6093128],
          [118.7882861, 4.6091845],
          [118.7883183, 4.6092968],
          [118.7883881, 4.6094572],
          [118.7885115, 4.6093797],
          [118.788608, 4.6092888],
          [118.7886482, 4.6093423],
          [118.7887368, 4.6092701],
          [118.7887233, 4.6091016],
          [118.7886429, 4.6089867],
          [118.7884524, 4.6089706],
          [118.7885195, 4.6087621],
          [118.7886724, 4.6085295],
          [118.7887555, 4.6085161],
          [118.7889084, 4.6086418],
          [118.7891471, 4.608706],
          [118.789233, 4.6086658],
          [118.7893805, 4.6085268],
          [118.7895012, 4.6084921],
          [118.7896809, 4.6084199],
          [118.789705, 4.6082835],
          [118.7897721, 4.6083397],
          [118.7899357, 4.6082568],
          [118.7899464, 4.6081285],
          [118.7901127, 4.6080269],
          [118.790094, 4.6079119],
          [118.7902066, 4.6079039],
          [118.7901986, 4.6076847],
          [118.7901744, 4.6075724],
          [118.790381, 4.6074414],
          [118.7904963, 4.6073531],
          [118.7904775, 4.6072729],
          [118.7906787, 4.6072114],
          [118.7907565, 4.6071393],
          [118.7907323, 4.6069468],
          [118.7908074, 4.6069414],
          [118.7910166, 4.6071018],
          [118.7911051, 4.6069762],
          [118.7912902, 4.6068799],
          [118.7914619, 4.606765],
          [118.791537, 4.6066072],
          [118.7916791, 4.6065457],
          [118.7918052, 4.6064789],
          [118.7917194, 4.6063479],
          [118.7917033, 4.6062516],
          [118.791773, 4.6061688],
          [118.7918964, 4.6061661],
          [118.7920627, 4.6061474],
          [118.7921378, 4.6060725],
          [118.7922049, 4.6060672],
          [118.7923416, 4.6059789],
          [118.7924999, 4.6058479],
          [118.7926099, 4.605725],
          [118.7925938, 4.6056742],
          [118.7924731, 4.6056715],
          [118.7924275, 4.6055512],
          [118.7924302, 4.6054282],
          [118.7925804, 4.60534],
          [118.7927225, 4.6052758],
          [118.7928674, 4.6053453],
          [118.7928432, 4.605479],
          [118.7929022, 4.6055592],
          [118.7930283, 4.6053908],
          [118.7929934, 4.6052892],
          [118.7928915, 4.6052036],
          [118.7930068, 4.6051261],
          [118.7931678, 4.6050352],
          [118.793208, 4.6049068],
          [118.7933099, 4.6047946],
          [118.7934092, 4.6047411],
          [118.7935835, 4.6046395],
          [118.7936425, 4.6045058],
          [118.7937444, 4.6043935],
          [118.7939215, 4.604316],
          [118.7939724, 4.6042144],
          [118.7939751, 4.6040406],
          [118.7940422, 4.6040166],
          [118.7940744, 4.6039337],
          [118.7940448, 4.6038454],
          [118.79401, 4.6037251],
          [118.7939858, 4.603538],
          [118.7941414, 4.6034177],
          [118.7942219, 4.6034043],
          [118.7941951, 4.6031744],
          [118.794136, 4.6030755],
          [118.79412, 4.6029391],
          [118.794077, 4.6028455],
          [118.7938142, 4.6028455],
          [118.7936318, 4.6028108],
          [118.7935781, 4.6026477],
          [118.7934548, 4.6024953],
          [118.793385, 4.6023162],
          [118.793385, 4.6022199],
          [118.7932563, 4.6021584],
          [118.7931007, 4.602105],
          [118.7928995, 4.6020515],
          [118.7927628, 4.6019472],
          [118.7925804, 4.601859],
          [118.792398, 4.6017681],
          [118.7922612, 4.6016638],
          [118.7923175, 4.6015248],
          [118.7923819, 4.601335],
          [118.7922263, 4.6012922],
          [118.7922558, 4.6011398],
          [118.7921458, 4.601105],
          [118.7918669, 4.6010382],
          [118.791765, 4.6010195],
          [118.7916845, 4.6008323],
          [118.7915879, 4.6005784],
          [118.7915209, 4.6003431],
          [118.7914512, 4.6001666],
          [118.7914136, 4.5999661],
          [118.7913975, 4.5997415],
          [118.7913761, 4.5995651],
          [118.7913412, 4.5992656],
          [118.7913358, 4.5991373],
          [118.7912554, 4.5989715],
          [118.7912124, 4.5987657],
          [118.7912795, 4.5986988],
          [118.7912366, 4.5985251],
          [118.7911481, 4.5985438],
          [118.7911293, 4.5984743],
          [118.7911105, 4.5983219],
          [118.7911105, 4.5981614],
          [118.7910917, 4.5979101],
          [118.7910756, 4.5977604],
          [118.7911159, 4.5976535],
          [118.7910703, 4.5975465],
          [118.791022, 4.5972899],
          [118.791014, 4.597092],
          [118.7910113, 4.5969022],
          [118.7909925, 4.596683],
          [118.7909925, 4.5964637],
          [118.7910166, 4.5962766],
          [118.791022, 4.5960627],
          [118.7909844, 4.5958328],
          [118.7909737, 4.5957098],
          [118.7909147, 4.5954478],
          [118.7908745, 4.5952259],
          [118.7908047, 4.595036],
          [118.7908047, 4.5948703],
          [118.7908128, 4.5946831],
          [118.7908584, 4.594512],
          [118.7908906, 4.5943757],
          [118.7908879, 4.5941484],
          [118.7909281, 4.5940147],
          [118.7910113, 4.5938008],
          [118.7910086, 4.593627],
          [118.7909764, 4.5934345],
          [118.7909549, 4.5932795],
          [118.7909469, 4.5931779],
          [118.7909844, 4.5930469],
          [118.7910086, 4.5928544],
          [118.7910488, 4.5927742],
          [118.7909952, 4.5926057],
          [118.7909952, 4.5923758],
          [118.791014, 4.5922582],
          [118.7910086, 4.5919828],
          [118.790963, 4.5916486],
          [118.7909389, 4.5915657],
          [118.7908423, 4.5915229],
          [118.7907404, 4.5914374],
          [118.7907323, 4.5912983],
          [118.7906975, 4.5911941],
          [118.7905553, 4.5911433],
          [118.7904212, 4.5909347],
          [118.79033, 4.5907021],
          [118.7901744, 4.5903786],
          [118.7900725, 4.5901701],
          [118.7899545, 4.5899054],
          [118.789748, 4.589622],
          [118.7895656, 4.5893413],
          [118.7894797, 4.5891381],
          [118.789292, 4.5888039],
          [118.7891579, 4.5885659],
          [118.7890264, 4.5884082],
          [118.7888414, 4.5882077],
          [118.7886751, 4.5879777],
          [118.7885168, 4.5877719],
          [118.7884015, 4.5876007],
          [118.7882888, 4.5874804],
          [118.7881252, 4.5873441],
          [118.7880474, 4.5871944],
          [118.7879214, 4.5870473],
          [118.7876907, 4.5868735],
          [118.7874734, 4.5867532],
          [118.7873152, 4.5865714],
          [118.7871382, 4.586558],
          [118.7868673, 4.5865259],
          [118.786642, 4.5865447],
          [118.7863094, 4.5864885],
          [118.7859446, 4.5864778],
          [118.7855422, 4.5864805],
          [118.7853464, 4.5864538],
          [118.7850514, 4.5864163],
          [118.7847295, 4.5863575],
          [118.7845176, 4.5863441],
          [118.7842467, 4.5863415],
          [118.7840483, 4.5863629],
          [118.7838739, 4.5863842],
          [118.7835923, 4.5864618],
          [118.7834099, 4.5864217],
          [118.7831953, 4.586435],
          [118.7830022, 4.5864591],
          [118.782793, 4.5865233],
          [118.7825596, 4.5865447],
          [118.7823504, 4.5865741],
          [118.7821653, 4.5866168],
          [118.7819481, 4.5867078],
          [118.7817255, 4.5867131],
          [118.7816101, 4.5867719],
          [118.7813687, 4.5868307],
          [118.7812024, 4.5868281],
          [118.7810039, 4.5868628],
          [118.7807947, 4.5869163],
          [118.7805721, 4.5869992],
          [118.7803709, 4.5870286],
          [118.780151, 4.5871783],
          [118.7800652, 4.5873869],
          [118.7800652, 4.5875633],
          [118.7801322, 4.5877211],
          [118.7802342, 4.5878253],
          [118.7802047, 4.5881034],
          [118.7801966, 4.588352],
          [118.7801751, 4.5885205],
          [118.7801349, 4.5887023],
          [118.7800893, 4.5888787],
          [118.780084, 4.5890819],
          [118.7800169, 4.5892851],
          [118.7799498, 4.5894616],
          [118.779982, 4.5895658],
          [118.7799686, 4.5896915],
          [118.7799069, 4.5899134],
          [118.7798077, 4.5900738],
          [118.7798077, 4.5902369],
          [118.779797, 4.5903786],
          [118.7798747, 4.5906326],
          [118.7798935, 4.5908732],
          [118.7799042, 4.591154],
          [118.7798801, 4.5913358],
          [118.7798452, 4.5915256],
          [118.7798023, 4.591825],
          [118.7797514, 4.5921379],
          [118.7797567, 4.5923517],
          [118.7797809, 4.5925736],
          [118.7798882, 4.5927501],
          [118.7800088, 4.5929747],
          [118.7801135, 4.59321],
          [118.7803039, 4.5935254],
          [118.7804326, 4.5936992],
          [118.7805748, 4.5938891],
          [118.7806928, 4.594127],
          [118.7808296, 4.5943997],
          [118.7809423, 4.5946163],
          [118.7810495, 4.5948917],
          [118.7810308, 4.5950521],
          [118.7811246, 4.5952018],
          [118.7812266, 4.5953996],
          [118.7813097, 4.5956242],
          [118.781417, 4.5956376],
          [118.7815618, 4.5957739],
          [118.7817201, 4.595945],
          [118.7818032, 4.5961349],
          [118.7818113, 4.5965092],
          [118.7818381, 4.5967257],
          [118.7818918, 4.596961],
          [118.7818783, 4.5971802],
          [118.7819079, 4.5974663],
          [118.7819105, 4.5977845],
          [118.7818703, 4.5980518],
          [118.7818596, 4.5983245],
          [118.7818649, 4.5985865],
          [118.7818515, 4.5988298],
          [118.7818059, 4.5990838],
          [118.7818328, 4.5992817],
          [118.7817603, 4.5995437],
          [118.7816879, 4.5998084],
          [118.7816021, 4.5998378],
          [118.7815806, 4.6000677],
          [118.7815672, 4.600327],
          [118.7814814, 4.600295],
          [118.781409, 4.6005382],
          [118.7815297, 4.6005596],
          [118.7814733, 4.6007842],
          [118.7813285, 4.6008778],
          [118.7812695, 4.6010355],
          [118.7813956, 4.6011318],
          [118.7814733, 4.6010703],
          [118.7815484, 4.6011291],
          [118.7816101, 4.6012681],
          [118.7815887, 4.6014874],
          [118.7817013, 4.6016371],
          [118.7816772, 4.601851],
          [118.781653, 4.6021237],
          [118.7817067, 4.6023001],
          [118.7815082, 4.602506],
          [118.7815082, 4.6027119],
          [118.7815484, 4.6029097],
          [118.7815645, 4.6031744],
          [118.7815565, 4.6033829],
          [118.7816718, 4.6034685],
          [118.7818193, 4.6036984],
          [118.7817737, 4.603915],
          [118.7818408, 4.6040219],
          [118.7817818, 4.6042866],
          [118.781645, 4.6042411],
          [118.781468, 4.6046234],
          [118.7816101, 4.604717],
          [118.781586, 4.6049015],
          [118.7815404, 4.6051662],
          [118.7815431, 4.6054736],
          [118.7816209, 4.6056608],
          [118.7817577, 4.6058666],
          [118.7817121, 4.6059041],
          [118.7817281, 4.60615],
          [118.7816906, 4.6063185],
          [118.781814, 4.6065404],
          [118.7817979, 4.6066874],
          [118.7815082, 4.606781],
          [118.78135, 4.6070831],
          [118.7814116, 4.607289],
          [118.7813768, 4.6075964],
          [118.7814546, 4.6079066],
          [118.7813634, 4.6079841],
          [118.7815833, 4.6082862],
          [118.7815216, 4.6084252],
          [118.7816262, 4.6086819],
          [118.781637, 4.6090054],
          [118.7817067, 4.6092727],
          [118.7818488, 4.6095802],
          [118.7819642, 4.609877],
          [118.7820366, 4.6102245],
          [118.7820607, 4.6104972],
          [118.7820822, 4.6108287],
          [118.7819615, 4.6110319],
          [118.78205, 4.6112271],
          [118.7820071, 4.6115132],
          [118.7820581, 4.6117645],
          [118.7822726, 4.6122511],
        ],
      ],
      [
        [
          [118.6563884, 4.6473909],
          [118.6563375, 4.6474336],
          [118.6563026, 4.6474737],
          [118.6562677, 4.6475914],
          [118.6562892, 4.6476956],
          [118.6563428, 4.6478186],
          [118.6563777, 4.6479336],
          [118.6565038, 4.6481394],
          [118.6566486, 4.648372],
          [118.6567559, 4.6485404],
          [118.6568015, 4.6486874],
          [118.6569195, 4.6491927],
          [118.6569785, 4.649444],
          [118.6570268, 4.6495296],
          [118.6570483, 4.6496098],
          [118.6570751, 4.6498504],
          [118.6572012, 4.6502434],
          [118.6573755, 4.6507834],
          [118.6574533, 4.6509946],
          [118.657456, 4.6510828],
          [118.6576947, 4.651564],
          [118.6578127, 4.6519115],
          [118.6579602, 4.6522885],
          [118.6580085, 4.6524783],
          [118.6580112, 4.6527029],
          [118.6580568, 4.6527938],
          [118.6581185, 4.6528419],
          [118.6581667, 4.6528713],
          [118.6582284, 4.6528686],
          [118.6582794, 4.6528472],
          [118.6583545, 4.6528793],
          [118.658325, 4.6528098],
          [118.6584216, 4.6526815],
          [118.6585771, 4.6525986],
          [118.6586737, 4.6525906],
          [118.6587729, 4.6526307],
          [118.658781, 4.6526039],
          [118.6589875, 4.652644],
          [118.6590642, 4.6525727],
          [118.6590036, 4.6524783],
          [118.6590841, 4.6524382],
          [118.6592101, 4.6524863],
          [118.6592638, 4.652505],
          [118.6593416, 4.6525692],
          [118.6593979, 4.6525478],
          [118.6594059, 4.6524649],
          [118.6594032, 4.6522671],
          [118.6595722, 4.6517966],
          [118.6596339, 4.6517164],
          [118.6596742, 4.6517592],
          [118.6597466, 4.6516923],
          [118.6596983, 4.6516255],
          [118.659768, 4.6515533],
          [118.6596071, 4.6514063],
          [118.6596205, 4.6513555],
          [118.6595588, 4.6512298],
          [118.6593362, 4.6512298],
          [118.6591297, 4.6509999],
          [118.6590036, 4.6509224],
          [118.6589285, 4.6507139],
          [118.6587676, 4.6504251],
          [118.6587434, 4.6503342],
          [118.6586147, 4.6501979],
          [118.6584725, 4.6500455],
          [118.6583572, 4.6498557],
          [118.6582059, 4.6496774],
          [118.6580621, 4.6494975],
          [118.6578422, 4.6492863],
          [118.6576223, 4.6490377],
          [118.6575461, 4.6488834],
          [118.657515, 4.6488104],
          [118.6573648, 4.6486741],
          [118.6572307, 4.6485458],
          [118.6570724, 4.648372],
          [118.656823, 4.6481581],
          [118.6566433, 4.647963],
          [118.6565306, 4.6478159],
          [118.6564287, 4.6476582],
          [118.6563884, 4.6475566],
          [118.6563938, 4.6474898],
          [118.656426, 4.647431],
          [118.6563884, 4.6473909],
        ],
      ],
      [
        [
          [118.3237373, 4.6547267],
          [118.3230507, 4.6600306],
          [118.3282005, 4.6637947],
          [118.3381569, 4.6653345],
          [118.3532631, 4.6673876],
          [118.3757507, 4.6608861],
          [118.388797, 4.66619],
          [118.3920585, 4.6653345],
          [118.39738, 4.6632814],
          [118.4025299, 4.6648212],
          [118.4033882, 4.6593462],
          [118.4091987, 4.6597369],
          [118.4130012, 4.662597],
          [118.4233009, 4.6641368],
          [118.4329139, 4.6639658],
          [118.4342872, 4.6709805],
          [118.4277641, 4.6726914],
          [118.4255325, 4.6834701],
          [118.4389221, 4.6843255],
          [118.4382354, 4.6901425],
          [118.4250175, 4.6894581],
          [118.4167778, 4.6880894],
          [118.4075081, 4.6921955],
          [118.408538, 4.6956172],
          [118.4112846, 4.6986968],
          [118.4099113, 4.7005787],
          [118.4064781, 4.7005787],
          [118.4042465, 4.6928799],
          [118.3960067, 4.6952751],
          [118.39738, 4.6998944],
          [118.3944618, 4.6998944],
          [118.3894836, 4.6990389],
          [118.3889686, 4.7028028],
          [118.3948051, 4.7043425],
          [118.3970367, 4.7087907],
          [118.4123146, 4.711528],
          [118.4123146, 4.707422],
          [118.4142029, 4.7072509],
          [118.4179794, 4.7050269],
          [118.421241, 4.6983546],
          [118.4258758, 4.6981835],
          [118.4423553, 4.6963016],
          [118.4564316, 4.7010919],
          [118.4641563, 4.6993811],
          [118.4672462, 4.6831279],
          [118.474971, 4.6764554],
          [118.4868156, 4.6764554],
          [118.4890472, 4.6779952],
          [118.4923088, 4.6747445],
          [118.4876739, 4.6735469],
          [118.484584, 4.6673876],
          [118.4887039, 4.6629392],
          [118.4959137, 4.6584908],
          [118.4945404, 4.6545556],
          [118.495227, 4.6478828],
          [118.4976303, 4.6410389],
          [118.5034668, 4.6410389],
          [118.5046282, 4.6475987],
          [118.5055267, 4.6526735],
          [118.5077583, 4.6613994],
          [118.516513, 4.6615705],
          [118.5220062, 4.6572931],
          [118.5202896, 4.6487383],
          [118.5329925, 4.6454875],
          [118.5475837, 4.6555822],
          [118.5525619, 4.6478828],
          [118.5783111, 4.6376169],
          [118.5831176, 4.6364193],
          [118.5858642, 4.6340239],
          [118.5868942, 4.6311151],
          [118.5863792, 4.6249555],
          [118.5789978, 4.6177691],
          [118.5724746, 4.6206779],
          [118.5661232, 4.6196513],
          [118.5614883, 4.6095561],
          [118.5529052, 4.5866275],
          [118.549987, 4.5898786],
          [118.5379707, 4.6194802],
          [118.5274994, 4.6054495],
          [118.5299026, 4.6040807],
          [118.5335075, 4.6033962],
          [118.5336792, 4.5970652],
          [118.5299026, 4.5958675],
          [118.5147964, 4.5953541],
          [118.5084449, 4.5999741],
          [118.4787475, 4.6170847],
          [118.4756576, 4.6169136],
          [118.471117, 4.6125049],
          [118.4681045, 4.6057918],
          [118.4627399, 4.6060285],
          [118.4550151, 4.6112643],
          [118.4520883, 4.6089031],
          [118.4486207, 4.6096046],
          [118.449111, 4.6109424],
          [118.449831, 4.6136769],
          [118.4460802, 4.6193661],
          [118.4423553, 4.6256399],
          [118.4411106, 4.6282635],
          [118.4280471, 4.6290762],
          [118.419181, 4.6323128],
          [118.4135162, 4.6304307],
          [118.4114563, 4.6295752],
          [118.4097397, 4.6321417],
          [118.3685409, 4.648225],
          [118.3524048, 4.6436054],
          [118.3237373, 4.6547267],
        ],
      ],
      [
        [
          [118.6438622, 4.6698911],
          [118.6436607, 4.6706723],
          [118.6441757, 4.6721899],
          [118.6447579, 4.6721453],
          [118.6455193, 4.6711186],
          [118.6455417, 4.6701143],
          [118.6447132, 4.6695563],
          [118.6438622, 4.6698911],
        ],
      ],
      [
        [
          [118.4607231, 4.7046847],
          [118.4571182, 4.7045136],
          [118.4548866, 4.7065666],
          [118.4499084, 4.7051979],
          [118.4478485, 4.707422],
          [118.4468185, 4.7130677],
          [118.4500801, 4.7151207],
          [118.4533416, 4.7212795],
          [118.4595215, 4.7209374],
          [118.4602081, 4.726583],
          [118.4631263, 4.720082],
          [118.4682762, 4.7188844],
          [118.4705078, 4.7195688],
          [118.4780609, 4.7192266],
          [118.4761726, 4.7163182],
          [118.4713661, 4.715805],
          [118.4607231, 4.7046847],
        ],
      ],
      [
        [
          [118.2290736, 4.7273232],
          [118.2292159, 4.7277146],
          [118.2294412, 4.7278272],
          [118.2296072, 4.7281178],
          [118.2294709, 4.7285447],
          [118.229714, 4.7289302],
          [118.2299808, 4.7292207],
          [118.2303781, 4.7293334],
          [118.2304908, 4.7289776],
          [118.2308762, 4.7284499],
          [118.2307695, 4.7281415],
          [118.2307695, 4.7272817],
          [118.2301706, 4.7263448],
          [118.2295776, 4.7258052],
          [118.2290439, 4.725823],
          [118.2289668, 4.7261017],
          [118.2287889, 4.7266769],
          [118.2290736, 4.7273232],
        ],
      ],
      [
        [
          [118.3918869, 4.7578895],
          [118.3916, 4.7573969],
          [118.3907517, 4.7566633],
          [118.390041, 4.7565716],
          [118.3888718, 4.7568009],
          [118.3874275, 4.7566633],
          [118.386648, 4.7567321],
          [118.3870836, 4.7576033],
          [118.3874504, 4.7587037],
          [118.3863729, 4.7598729],
          [118.3853637, 4.7604555],
          [118.3827888, 4.7633637],
          [118.3823783, 4.763723],
          [118.3818271, 4.7638202],
          [118.3812273, 4.7633501],
          [118.3808707, 4.7628638],
          [118.3805062, 4.7630898],
          [118.3800454, 4.7637244],
          [118.3807103, 4.7646415],
          [118.3807103, 4.7653751],
          [118.3814155, 4.7654165],
          [118.382659, 4.7667277],
          [118.384195, 4.7685618],
          [118.3851808, 4.7699144],
          [118.3850891, 4.7716109],
          [118.3840574, 4.7722069],
          [118.382636, 4.7742015],
          [118.381719, 4.7752331],
          [118.3813751, 4.7759897],
          [118.3819712, 4.7772506],
          [118.3839905, 4.7765358],
          [118.3850891, 4.7750039],
          [118.386222, 4.774312],
          [118.3859144, 4.7730781],
          [118.3856622, 4.771886],
          [118.386737, 4.7702064],
          [118.3880465, 4.7698914],
          [118.3884362, 4.7695246],
          [118.3879777, 4.7686764],
          [118.3878172, 4.7676906],
          [118.3883216, 4.7667735],
          [118.389312, 4.7657586],
          [118.390041, 4.7657877],
          [118.3904996, 4.7645268],
          [118.3914395, 4.7639308],
          [118.3914854, 4.7626469],
          [118.3912103, 4.7609275],
          [118.3918869, 4.7578895],
        ],
      ],
      [
        [
          [118.3027747, 4.772527],
          [118.3040815, 4.773146],
          [118.305457, 4.7735586],
          [118.3062365, 4.7744527],
          [118.3073828, 4.7748196],
          [118.3090793, 4.7755532],
          [118.3106841, 4.776218],
          [118.3115324, 4.77592],
          [118.3126786, 4.7744069],
          [118.3120138, 4.7736045],
          [118.3104319, 4.7731689],
          [118.3101339, 4.7724582],
          [118.308873, 4.772206],
          [118.3072911, 4.7713348],
          [118.3071765, 4.7700051],
          [118.3063741, 4.7689276],
          [118.3045859, 4.7687901],
          [118.3026372, 4.7693403],
          [118.3019494, 4.7706471],
          [118.3023391, 4.7721602],
          [118.3027747, 4.772527],
        ],
      ],
      [
        [
          [118.3073293, 4.7657217],
          [118.3074428, 4.765819],
          [118.30738, 4.7660236],
          [118.3077123, 4.7662364],
          [118.3080913, 4.766127],
          [118.3083749, 4.7660378],
          [118.308229, 4.7656974],
          [118.3078076, 4.765203],
          [118.307457, 4.7650834],
          [118.3071125, 4.7655292],
          [118.3073293, 4.7657217],
        ],
      ],
      [
        [
          [118.309551, 4.7853576],
          [118.3093639, 4.7853665],
          [118.3090052, 4.7854739],
          [118.3087438, 4.7858002],
          [118.3087337, 4.7861913],
          [118.3088918, 4.7864608],
          [118.3092423, 4.7866533],
          [118.3094207, 4.786629],
          [118.3096861, 4.786554],
          [118.3101279, 4.7866168],
          [118.3105595, 4.7868053],
          [118.3109607, 4.7872896],
          [118.3111978, 4.7876138],
          [118.3112809, 4.7880008],
          [118.3113173, 4.7884831],
          [118.3112646, 4.7888255],
          [118.3109789, 4.789245],
          [118.3107844, 4.7896766],
          [118.3109303, 4.7899401],
          [118.3111755, 4.7901528],
          [118.3115747, 4.7904325],
          [118.3121096, 4.7905865],
          [118.3123042, 4.7908033],
          [118.3124501, 4.7909411],
          [118.3124865, 4.790862],
          [118.312493, 4.7908357],
          [118.3125798, 4.7907911],
          [118.3127257, 4.7904912],
          [118.3127338, 4.7895672],
          [118.3125392, 4.7888296],
          [118.3123528, 4.7877354],
          [118.3126608, 4.7867384],
          [118.3130661, 4.7859035],
          [118.3133822, 4.7855145],
          [118.3136335, 4.7849228],
          [118.313828, 4.7846796],
          [118.3135848, 4.7843959],
          [118.3129931, 4.7843149],
          [118.3123528, 4.7840068],
          [118.3119556, 4.7840312],
          [118.3113883, 4.7842743],
          [118.3109343, 4.7838772],
          [118.310789, 4.783237],
          [118.310491, 4.7832255],
          [118.3105025, 4.7839477],
          [118.3102617, 4.7844176],
          [118.310021, 4.7847271],
          [118.309551, 4.7853576],
        ],
      ],
      [
        [
          [118.3692276, 4.8001424],
          [118.3596145, 4.801853],
          [118.3594429, 4.8071558],
          [118.3601295, 4.807669],
          [118.3628761, 4.8061295],
          [118.3657943, 4.8039057],
          [118.3685409, 4.8025372],
          [118.3738624, 4.8033925],
          [118.376609, 4.8040768],
          [118.3778106, 4.8057874],
          [118.3992683, 4.7998003],
          [118.4064781, 4.7927868],
          [118.4078514, 4.7914183],
          [118.4097397, 4.7910762],
          [118.4152328, 4.7895367],
          [118.4178077, 4.7867997],
          [118.4176361, 4.7844048],
          [118.4159195, 4.7811546],
          [118.4128296, 4.782352],
          [118.4104263, 4.7867997],
          [118.4057914, 4.7862865],
          [118.4057914, 4.790392],
          [118.4028732, 4.7891945],
          [118.3990967, 4.7873129],
          [118.3934318, 4.7893656],
          [118.387767, 4.787655],
          [118.387767, 4.7844048],
          [118.3853637, 4.785089],
          [118.3838188, 4.7830363],
          [118.3798706, 4.7806414],
          [118.3800422, 4.7825231],
          [118.3803856, 4.7854312],
          [118.3822738, 4.787826],
          [118.3831321, 4.7907341],
          [118.3814155, 4.7938132],
          [118.3793556, 4.7926158],
          [118.3786689, 4.7888524],
          [118.3757507, 4.7871418],
          [118.3730041, 4.7845758],
          [118.3721458, 4.7869707],
          [118.3693992, 4.7881682],
          [118.3718025, 4.7902209],
          [118.3724891, 4.7946685],
          [118.3721458, 4.7977476],
          [118.3692276, 4.8001424],
        ],
      ],
      [
        [
          [118.2918083, 4.8148534],
          [118.2906067, 4.8129717],
          [118.2880317, 4.8139981],
          [118.2818519, 4.8177613],
          [118.280307, 4.8189587],
          [118.2773887, 4.8193008],
          [118.2748138, 4.8210113],
          [118.2758438, 4.823064],
          [118.2801353, 4.8218666],
          [118.2839119, 4.8187876],
          [118.2866585, 4.8187876],
          [118.2918083, 4.8148534],
        ],
      ],
      [
        [
          [118.3144102, 4.7980422],
          [118.3127137, 4.7978588],
          [118.3119342, 4.7970335],
          [118.3115216, 4.796254],
          [118.3094812, 4.7967125],
          [118.3078305, 4.7973773],
          [118.3061111, 4.7978359],
          [118.3051024, 4.7980193],
          [118.3041166, 4.7977671],
          [118.3030391, 4.7982027],
          [118.3017781, 4.7982944],
          [118.3020991, 4.7989592],
          [118.3028786, 4.7992802],
          [118.3036122, 4.7992573],
          [118.3040707, 4.7987529],
          [118.305217, 4.79873],
          [118.3059736, 4.799028],
          [118.3068906, 4.7985695],
          [118.3075096, 4.7982944],
          [118.3089768, 4.7980651],
          [118.3096611, 4.7982608],
          [118.3097104, 4.7984319],
          [118.3095041, 4.7993719],
          [118.3086329, 4.7999221],
          [118.3082891, 4.8005411],
          [118.3091461, 4.8011688],
          [118.3097589, 4.8012852],
          [118.3104467, 4.8016979],
          [118.3111459, 4.8022424],
          [118.3113465, 4.802569],
          [118.3113694, 4.8029473],
          [118.3110657, 4.803423],
          [118.3106931, 4.8039446],
          [118.3102289, 4.804512],
          [118.3095812, 4.8047986],
          [118.3092202, 4.8049648],
          [118.3091571, 4.8051826],
          [118.3095297, 4.8058302],
          [118.309799, 4.8062314],
          [118.3097876, 4.8067129],
          [118.3099079, 4.8071542],
          [118.3101486, 4.8074293],
          [118.3104868, 4.8076929],
          [118.3110542, 4.8082776],
          [118.3110428, 4.8087418],
          [118.3108536, 4.8091946],
          [118.3104352, 4.8094983],
          [118.3101429, 4.8095958],
          [118.3098965, 4.8096302],
          [118.3095468, 4.8095729],
          [118.3093634, 4.809762],
          [118.309587, 4.8100371],
          [118.3099309, 4.8101173],
          [118.3103836, 4.8101059],
          [118.310888, 4.8098021],
          [118.311186, 4.8096359],
          [118.3114038, 4.8094926],
          [118.3117821, 4.8093149],
          [118.3132608, 4.809464],
          [118.3145561, 4.8094296],
          [118.3155878, 4.8085699],
          [118.3168143, 4.8081228],
          [118.3183733, 4.8072631],
          [118.3189349, 4.8073548],
          [118.3196174, 4.8066426],
          [118.3210556, 4.8058073],
          [118.3214568, 4.8053832],
          [118.3220903, 4.8047594],
          [118.3225489, 4.8039341],
          [118.3229844, 4.8034068],
          [118.3235117, 4.8030171],
          [118.323443, 4.802123],
          [118.3239015, 4.8018937],
          [118.3253916, 4.8010225],
          [118.3257972, 4.8009977],
          [118.3268589, 4.8001972],
          [118.3270652, 4.7995782],
          [118.3267672, 4.7981568],
          [118.3263545, 4.7975837],
          [118.3257355, 4.7980193],
          [118.3248873, 4.7986612],
          [118.323443, 4.7991197],
          [118.3227073, 4.7987739],
          [118.3216089, 4.7996928],
          [118.3206231, 4.7995782],
          [118.3190412, 4.7991656],
          [118.3174823, 4.7982715],
          [118.3158545, 4.7980193],
          [118.3144102, 4.7980422],
        ],
      ],
      [
        [
          [118.2667733, 4.9229352],
          [118.2665056, 4.9232351],
          [118.2662379, 4.9236527],
          [118.2666341, 4.9235992],
          [118.2669661, 4.9232672],
          [118.2672445, 4.9232672],
          [118.2678978, 4.9233529],
          [118.2678978, 4.9230744],
          [118.2676086, 4.9227424],
          [118.2674908, 4.9222391],
          [118.2668269, 4.9221963],
          [118.2667733, 4.9229352],
        ],
      ],
      [
        [
          [118.2392493, 4.923334],
          [118.2389038, 4.9235755],
          [118.2391785, 4.9234423],
          [118.2394907, 4.9234298],
          [118.2398321, 4.9233007],
          [118.2401984, 4.9231925],
          [118.2404274, 4.9230468],
          [118.2404233, 4.922822],
          [118.2402526, 4.9224723],
          [118.2400319, 4.9223224],
          [118.2398779, 4.9223641],
          [118.2396947, 4.9226846],
          [118.2395407, 4.9229552],
          [118.2392493, 4.923334],
        ],
      ],
      [
        [
          [118.2364934, 4.9250893],
          [118.2365309, 4.9251434],
          [118.2366724, 4.9251518],
          [118.2368847, 4.9250185],
          [118.2371928, 4.9247896],
          [118.2374259, 4.9245148],
          [118.2375903, 4.9242255],
          [118.2372844, 4.9240611],
          [118.236943, 4.9243108],
          [118.2366932, 4.9247271],
          [118.2364934, 4.9250893],
        ],
      ],
      [
        [
          [118.2022876, 4.9286448],
          [118.2023296, 4.9280613],
          [118.202078, 4.9278686],
          [118.2021797, 4.9277401],
          [118.2025866, 4.9275045],
          [118.2029561, 4.9272046],
          [118.2034862, 4.9268245],
          [118.2044928, 4.9260962],
          [118.2043322, 4.9252877],
          [118.2045142, 4.9246398],
          [118.2050176, 4.924249],
          [118.2042918, 4.9235409],
          [118.2028408, 4.9233642],
          [118.2020751, 4.9231661],
          [118.2016253, 4.9235462],
          [118.2015718, 4.9240817],
          [118.2011139, 4.9243929],
          [118.2003945, 4.9249419],
          [118.1999743, 4.9252448],
          [118.1999478, 4.9257749],
          [118.2002431, 4.9258771],
          [118.2005914, 4.9258392],
          [118.2008564, 4.9261724],
          [118.2009738, 4.9264412],
          [118.2013524, 4.9268198],
          [118.2016364, 4.9268577],
          [118.2019923, 4.9272742],
          [118.2018181, 4.9277588],
          [118.201485, 4.9284328],
          [118.2014017, 4.9291294],
          [118.2008564, 4.9299624],
          [118.2006974, 4.9301744],
          [118.200705, 4.9304016],
          [118.2005536, 4.930765],
          [118.2008337, 4.9309241],
          [118.2015228, 4.9309468],
          [118.2021135, 4.9310831],
          [118.2025829, 4.9312875],
          [118.202742, 4.9315071],
          [118.2029843, 4.9314768],
          [118.2031433, 4.9311058],
          [118.2031433, 4.9303031],
          [118.2032342, 4.9300154],
          [118.2029464, 4.9299169],
          [118.2024466, 4.929243],
          [118.2022876, 4.9286448],
        ],
      ],
      [
        [
          [118.5218345, 4.5412815],
          [118.5326465, 4.5461041],
          [118.5419162, 4.5505533],
          [118.5565101, 4.5565112],
          [118.5664665, 4.5621581],
          [118.5673221, 4.5644139],
          [118.5697254, 4.5640716],
          [118.570927, 4.5650983],
          [118.5726436, 4.5637294],
          [118.5745319, 4.5618471],
          [118.5743602, 4.5635583],
          [118.5735046, 4.5662649],
          [118.5759052, 4.5697185],
          [118.5748779, 4.5720829],
          [118.5741885, 4.5748519],
          [118.5760768, 4.5746808],
          [118.5776218, 4.5758786],
          [118.5776218, 4.5777609],
          [118.580025, 4.579472],
          [118.5826, 4.5775898],
          [118.5824283, 4.5736541],
          [118.5803684, 4.5704029],
          [118.57951, 4.5668095],
          [118.5793384, 4.5640716],
          [118.5796817, 4.5613338],
          [118.5798534, 4.5584248],
          [118.5789951, 4.5562002],
          [118.5793411, 4.5524044],
          [118.5762485, 4.5490132],
          [118.5712703, 4.5481576],
          [118.5664638, 4.550211],
          [118.5630306, 4.5498688],
          [118.5604583, 4.5481264],
          [118.5604583, 4.5438483],
          [118.5589134, 4.5393991],
          [118.549126, 4.5377191],
          [118.5312732, 4.5329277],
          [118.5266383, 4.5312164],
          [118.5232051, 4.5319009],
          [118.5209735, 4.5339544],
          [118.5204612, 4.5361478],
          [118.5206302, 4.5387459],
          [118.5218345, 4.5412815],
        ],
      ],
      [
        [
          [118.4981107, 4.5690599],
          [118.4957075, 4.5712844],
          [118.4946775, 4.5743644],
          [118.4941368, 4.5772477],
          [118.4962224, 4.5788134],
          [118.4986, 4.5787877],
          [118.5015182, 4.5781033],
          [118.5058097, 4.5767344],
          [118.5080413, 4.574681],
          [118.5102729, 4.5716009],
          [118.5107879, 4.5693764],
          [118.5102729, 4.567323],
          [118.5077238, 4.5668354],
          [118.5035781, 4.5674942],
          [118.5006599, 4.5683497],
          [118.4981107, 4.5690599],
        ],
      ],
      [
        [
          [118.6943553, 4.5728193],
          [118.6924911, 4.5728812],
          [118.6918075, 4.5736864],
          [118.6932367, 4.5746775],
          [118.6947902, 4.577341],
          [118.696468, 4.5801904],
          [118.6983322, 4.5811195],
          [118.7020607, 4.5800665],
          [118.7026821, 4.5783321],
          [118.6998236, 4.5736864],
          [118.6978973, 4.5727573],
          [118.6943553, 4.5728193],
        ],
      ],
      [
        [
          [118.914763, 4.5827178],
          [118.9150213, 4.5833291],
          [118.91557, 4.5836186],
          [118.9160541, 4.5828786],
          [118.9159573, 4.580916],
          [118.9163769, 4.5797577],
          [118.9179263, 4.5769907],
          [118.9174744, 4.5766046],
          [118.9164092, 4.5768298],
          [118.9152472, 4.5787281],
          [118.9148921, 4.5798864],
          [118.914763, 4.5827178],
        ],
      ],
      [
        [
          [118.234731, 4.9258137],
          [118.2347869, 4.9260658],
          [118.2347013, 4.9263436],
          [118.2345428, 4.9265307],
          [118.2342838, 4.9266462],
          [118.2339625, 4.9266165],
          [118.2332835, 4.9264272],
          [118.232607, 4.9263196],
          [118.2322683, 4.9262983],
          [118.2320741, 4.9264017],
          [118.231933, 4.9266256],
          [118.2317978, 4.9277072],
          [118.2317801, 4.9281194],
          [118.2317224, 4.9283406],
          [118.2315998, 4.92857],
          [118.2313, 4.9288556],
          [118.2309539, 4.9290954],
          [118.2308916, 4.9293494],
          [118.2309218, 4.9295397],
          [118.2311224, 4.9296919],
          [118.2315504, 4.9296829],
          [118.2320295, 4.9297696],
          [118.2323568, 4.929962],
          [118.2328423, 4.9303949],
          [118.2331893, 4.9309626],
          [118.2332151, 4.9315573],
          [118.2331549, 4.9321116],
          [118.2330696, 4.9332352],
          [118.2331614, 4.9334252],
          [118.23336, 4.9335297],
          [118.233655, 4.9335108],
          [118.2339299, 4.9334513],
          [118.2342467, 4.9333355],
          [118.2343644, 4.9331039],
          [118.2343705, 4.9328609],
          [118.2342839, 4.9323256],
          [118.2341264, 4.9317798],
          [118.2339098, 4.930494],
          [118.2339299, 4.9300882],
          [118.2340258, 4.9297569],
          [118.2341632, 4.9293574],
          [118.2343188, 4.9289392],
          [118.2347707, 4.9280735],
          [118.2348512, 4.9277947],
          [118.2348767, 4.9275015],
          [118.2348784, 4.9268912],
          [118.2349196, 4.9266223],
          [118.2350549, 4.9263572],
          [118.2353005, 4.9260557],
          [118.2357012, 4.9257847],
          [118.235806, 4.9255997],
          [118.23579, 4.9253903],
          [118.2357162, 4.9252541],
          [118.2353281, 4.9250333],
          [118.2348329, 4.9248515],
          [118.2345004, 4.9248211],
          [118.2342491, 4.9247597],
          [118.23411, 4.9246483],
          [118.2340734, 4.9245446],
          [118.2340716, 4.9244494],
          [118.2341056, 4.9243],
          [118.234072, 4.9242553],
          [118.2340112, 4.9242482],
          [118.2337807, 4.924566],
          [118.2337676, 4.9246428],
          [118.2338025, 4.9247303],
          [118.2341834, 4.9251518],
          [118.2344677, 4.9254752],
          [118.234731, 4.9258137],
        ],
      ],
      [
        [
          [118.2262426, 4.9320126],
          [118.2261718, 4.9321042],
          [118.2262884, 4.9320958],
          [118.226509, 4.9321125],
          [118.2265756, 4.9321791],
          [118.2267921, 4.9322082],
          [118.2269877, 4.9323248],
          [118.2272958, 4.9324039],
          [118.2274582, 4.9323331],
          [118.2278412, 4.9321957],
          [118.2285489, 4.9321957],
          [118.228961, 4.9320667],
          [118.2294189, 4.9319918],
          [118.2295521, 4.9318544],
          [118.2293815, 4.9316462],
          [118.2293565, 4.9314672],
          [118.2291067, 4.9309177],
          [118.2288694, 4.9308136],
          [118.2281742, 4.9310218],
          [118.2275789, 4.9312507],
          [118.2272125, 4.9314964],
          [118.2265381, 4.9317295],
          [118.2262426, 4.9320126],
        ],
      ],
      [
        [
          [116.7458015, 7.0524926],
          [116.7458316, 7.0524311],
          [116.7458652, 7.0522538],
          [116.7458387, 7.0521484],
          [116.74589, 7.0520694],
          [116.74589, 7.0519622],
          [116.7458139, 7.0518182],
          [116.7457767, 7.0516865],
          [116.7456051, 7.0515934],
          [116.7454317, 7.0515934],
          [116.7453396, 7.0516443],
          [116.7453467, 7.0517339],
          [116.7454334, 7.0519025],
          [116.7456157, 7.052122],
          [116.7457307, 7.0521695],
          [116.7457378, 7.0522555],
          [116.7457803, 7.0523082],
          [116.7457449, 7.0524241],
          [116.7458015, 7.0524926],
        ],
      ],
      [
        [
          [117.0191145, 7.0806345],
          [117.0191896, 7.0806638],
          [117.0193586, 7.0804162],
          [117.019498, 7.0801953],
          [117.0196509, 7.0800755],
          [117.0196751, 7.0805839],
          [117.0197636, 7.0806212],
          [117.0201391, 7.0804083],
          [117.0204395, 7.0801022],
          [117.0205978, 7.0798599],
          [117.0206782, 7.0796204],
          [117.0207399, 7.0792158],
          [117.0209491, 7.0790721],
          [117.0211101, 7.078939],
          [117.0209438, 7.078923],
          [117.0206621, 7.0790055],
          [117.0205039, 7.0789017],
          [117.0203751, 7.0790082],
          [117.0203054, 7.0791226],
          [117.020241, 7.0793755],
          [117.0200184, 7.0795778],
          [117.0197877, 7.0794846],
          [117.0195785, 7.0796204],
          [117.0194739, 7.0797934],
          [117.0194042, 7.079804],
          [117.0191681, 7.0799025],
          [117.0188838, 7.0798999],
          [117.0188302, 7.0801101],
          [117.0186907, 7.0802805],
          [117.0186532, 7.0803843],
          [117.0190072, 7.0804642],
          [117.0191145, 7.0806345],
        ],
      ],
      [
        [
          [117.260693, 7.0744832],
          [117.2607923, 7.0744645],
          [117.2609585, 7.0745098],
          [117.2611597, 7.0745204],
          [117.2613314, 7.0744512],
          [117.261436, 7.0744406],
          [117.2615674, 7.0743847],
          [117.2615057, 7.0743022],
          [117.2615567, 7.0742569],
          [117.2616398, 7.0740759],
          [117.2615835, 7.0739242],
          [117.261503, 7.0738443],
          [117.2615272, 7.0737698],
          [117.2614762, 7.073674],
          [117.2615272, 7.0735143],
          [117.2617149, 7.0735489],
          [117.261782, 7.0733865],
          [117.2618598, 7.073288],
          [117.2620127, 7.0732667],
          [117.262187, 7.0733093],
          [117.262238, 7.0731762],
          [117.2623131, 7.0732933],
          [117.2624391, 7.0732348],
          [117.2624955, 7.0732747],
          [117.2625679, 7.073115],
          [117.2625947, 7.0731656],
          [117.2626832, 7.0731336],
          [117.2628656, 7.0730138],
          [117.2629461, 7.0728541],
          [117.2628871, 7.0727104],
          [117.2626859, 7.0726359],
          [117.2626162, 7.0725693],
          [117.2624069, 7.0725081],
          [117.2621736, 7.0723511],
          [117.2620583, 7.0723777],
          [117.2619081, 7.0722073],
          [117.2618008, 7.0722339],
          [117.2617578, 7.0721834],
          [117.2616452, 7.0721967],
          [117.261562, 7.0724149],
          [117.2614387, 7.0722712],
          [117.2612992, 7.0723191],
          [117.2611892, 7.0721355],
          [117.2612214, 7.0720556],
          [117.261149, 7.0719651],
          [117.260929, 7.0719411],
          [117.2608164, 7.0720849],
          [117.2606072, 7.0720556],
          [117.2603819, 7.0719837],
          [117.2604007, 7.0720689],
          [117.2601539, 7.0720556],
          [117.2599876, 7.0720024],
          [117.2596657, 7.0719545],
          [117.2593358, 7.0717947],
          [117.2589147, 7.0717388],
          [117.2588584, 7.0718187],
          [117.258676, 7.0717149],
          [117.2582763, 7.0716058],
          [117.2579625, 7.0715499],
          [117.2577962, 7.0715019],
          [117.2577265, 7.0715632],
          [117.2574019, 7.0713981],
          [117.2572196, 7.071305],
          [117.2569782, 7.0711666],
          [117.2567072, 7.0710441],
          [117.2564471, 7.0710122],
          [117.2561386, 7.0709776],
          [117.2561279, 7.0710947],
          [117.2560769, 7.0713103],
          [117.2559428, 7.0713742],
          [117.2560179, 7.0715445],
          [117.255975, 7.0716084],
          [117.2560769, 7.071832],
          [117.2562164, 7.0720423],
          [117.2564766, 7.0721062],
          [117.2567314, 7.0721993],
          [117.2570184, 7.0723697],
          [117.2573939, 7.0725108],
          [117.2577238, 7.0727131],
          [117.258051, 7.0728728],
          [117.2585043, 7.0730671],
          [117.2586706, 7.0733013],
          [117.2589844, 7.0735196],
          [117.2593278, 7.0736979],
          [117.2596979, 7.0739215],
          [117.260119, 7.0741238],
          [117.2604865, 7.0740626],
          [117.2606367, 7.0743474],
          [117.260693, 7.0744832],
        ],
      ],
      [
        [
          [117.0275715, 7.0771396],
          [117.0279255, 7.0769613],
          [117.0282018, 7.0766738],
          [117.0284647, 7.0764582],
          [117.0288938, 7.0763917],
          [117.0295268, 7.0763118],
          [117.029618, 7.0763917],
          [117.0298165, 7.0763837],
          [117.0299265, 7.0762639],
          [117.0300123, 7.0760589],
          [117.0302403, 7.076011],
          [117.0301974, 7.0758912],
          [117.029905, 7.0757555],
          [117.0296663, 7.0755905],
          [117.0294115, 7.0754361],
          [117.0291325, 7.0752897],
          [117.0288724, 7.075106],
          [117.028462, 7.0749703],
          [117.028065, 7.0748478],
          [117.0275769, 7.0746562],
          [117.0271102, 7.0744459],
          [117.0266274, 7.0740546],
          [117.0262545, 7.0736287],
          [117.0260802, 7.073501],
          [117.0255008, 7.0733279],
          [117.0250931, 7.0732401],
          [117.0246747, 7.073272],
          [117.0245057, 7.0734291],
          [117.0245084, 7.0736367],
          [117.0246559, 7.0738709],
          [117.0248947, 7.0741371],
          [117.0249027, 7.0745577],
          [117.0249912, 7.0749543],
          [117.0251173, 7.0753855],
          [117.0252272, 7.0756863],
          [117.0252541, 7.0759791],
          [117.0255196, 7.0761068],
          [117.0259702, 7.0762053],
          [117.0263511, 7.0762719],
          [117.0266944, 7.0764156],
          [117.0270029, 7.0766073],
          [117.0271102, 7.0769453],
          [117.0273033, 7.0770864],
          [117.0275715, 7.0771396],
        ],
      ],
      [
        [
          [117.4780973, 7.0903855],
          [117.4786689, 7.0901608],
          [117.4788964, 7.0901102],
          [117.4791503, 7.0901354],
          [117.4795998, 7.090352],
          [117.4797118, 7.0903391],
          [117.4798037, 7.0902859],
          [117.4798634, 7.0901422],
          [117.479807, 7.0897669],
          [117.4796293, 7.089472],
          [117.4792452, 7.0893458],
          [117.4782761, 7.0893158],
          [117.477898, 7.0894626],
          [117.4777243, 7.089703],
          [117.4776249, 7.0899686],
          [117.4776197, 7.0900969],
          [117.4776722, 7.0902451],
          [117.4778343, 7.0903657],
          [117.4779684, 7.090403],
          [117.4780973, 7.0903855],
        ],
      ],
      [
        [
          [117.2813848, 7.0896499],
          [117.2819798, 7.0894266],
          [117.2825666, 7.0887028],
          [117.2825079, 7.0875486],
          [117.2813929, 7.0870009],
          [117.281256, 7.0855533],
          [117.2816668, 7.0845165],
          [117.2816863, 7.0833819],
          [117.2810016, 7.0820321],
          [117.2799844, 7.0801541],
          [117.2787715, 7.0789217],
          [117.2770892, 7.0796846],
          [117.2759155, 7.0796259],
          [117.2748004, 7.0783935],
          [117.2737049, 7.076809],
          [117.2719248, 7.0756744],
          [117.2707901, 7.0751266],
          [117.2701055, 7.075557],
          [117.2699604, 7.0757216],
          [117.2703125, 7.0772083],
          [117.2709777, 7.0798297],
          [117.2721905, 7.0818641],
          [117.2731686, 7.0817468],
          [117.2745771, 7.0818641],
          [117.2752031, 7.0829987],
          [117.2747727, 7.0840942],
          [117.2753205, 7.0849941],
          [117.2769637, 7.0873416],
          [117.2785287, 7.0892978],
          [117.2813848, 7.0896499],
        ],
      ],
      [
        [
          [117.0536211, 7.0912308],
          [117.0540556, 7.0912042],
          [117.0543185, 7.0908795],
          [117.0544258, 7.0907384],
          [117.0544901, 7.0905947],
          [117.0546377, 7.0902806],
          [117.0547825, 7.089908],
          [117.0548388, 7.0897083],
          [117.0550051, 7.0895114],
          [117.0551392, 7.0891893],
          [117.0553672, 7.0889231],
          [117.0554665, 7.0887554],
          [117.0557025, 7.0886277],
          [117.0560458, 7.0884281],
          [117.0563328, 7.0883109],
          [117.0566359, 7.0882231],
          [117.05699, 7.0881539],
          [117.0571724, 7.0878159],
          [117.0573172, 7.0876056],
          [117.0575854, 7.0872622],
          [117.057851, 7.0870333],
          [117.0580468, 7.0869455],
          [117.0582855, 7.086839],
          [117.0585376, 7.0866314],
          [117.058602, 7.0865276],
          [117.0587763, 7.0864025],
          [117.0588836, 7.0862801],
          [117.0589507, 7.0861603],
          [117.0588782, 7.0860458],
          [117.0589265, 7.085745],
          [117.0589855, 7.0855374],
          [117.0589292, 7.0853964],
          [117.0588139, 7.085061],
          [117.0586529, 7.0848161],
          [117.058543, 7.0848401],
          [117.0583874, 7.0846857],
          [117.0581728, 7.0845739],
          [117.0579717, 7.084502],
          [117.0578214, 7.0842944],
          [117.05769, 7.0842119],
          [117.0576364, 7.0840176],
          [117.0574164, 7.0839191],
          [117.057285, 7.0838233],
          [117.0573333, 7.0836982],
          [117.0570865, 7.0835332],
          [117.0570356, 7.0835731],
          [117.0567781, 7.0834826],
          [117.0566279, 7.0832803],
          [117.0565286, 7.0832776],
          [117.0563409, 7.083078],
          [117.0560244, 7.0829609],
          [117.0557857, 7.0829795],
          [117.0556328, 7.0829902],
          [117.0555469, 7.0828677],
          [117.0553565, 7.0827453],
          [117.0552278, 7.0826734],
          [117.0550588, 7.0825749],
          [117.0549461, 7.0823726],
          [117.0547369, 7.0822076],
          [117.054576, 7.0820479],
          [117.0543829, 7.0818589],
          [117.0542031, 7.0818722],
          [117.0540395, 7.0817178],
          [117.0537794, 7.0816566],
          [117.0536345, 7.081614],
          [117.0532295, 7.0815741],
          [117.0529693, 7.0815661],
          [117.0527333, 7.0814969],
          [117.0525214, 7.0815235],
          [117.0521888, 7.0813878],
          [117.0519769, 7.0813851],
          [117.0516443, 7.0813239],
          [117.0513439, 7.081252],
          [117.0512581, 7.0812946],
          [117.0510516, 7.0812733],
          [117.0508289, 7.0812574],
          [117.0506841, 7.0813026],
          [117.0505044, 7.0812041],
          [117.0505446, 7.0811189],
          [117.0503032, 7.0810817],
          [117.0500886, 7.0811296],
          [117.0497641, 7.0809513],
          [117.049748, 7.0806824],
          [117.0496622, 7.0804908],
          [117.0494127, 7.0803151],
          [117.0491016, 7.0803657],
          [117.0488253, 7.0802406],
          [117.048423, 7.080166],
          [117.048203, 7.0800968],
          [117.0480421, 7.0799504],
          [117.0477819, 7.0798227],
          [117.047511, 7.0797375],
          [117.0470631, 7.0797082],
          [117.0467547, 7.0798466],
          [117.0465749, 7.0799664],
          [117.0464328, 7.0799478],
          [117.0461243, 7.0800409],
          [117.0459178, 7.0801767],
          [117.0459366, 7.0803337],
          [117.0458508, 7.0804429],
          [117.0458052, 7.0806052],
          [117.0455664, 7.0807862],
          [117.0453814, 7.0809459],
          [117.0452446, 7.0811243],
          [117.0450515, 7.0813771],
          [117.0448717, 7.0817125],
          [117.0447993, 7.081819],
          [117.0447242, 7.0818935],
          [117.0447403, 7.0819601],
          [117.0445338, 7.082197],
          [117.0442387, 7.0824818],
          [117.0440215, 7.0827187],
          [117.0438471, 7.0829609],
          [117.0436165, 7.0831552],
          [117.0433938, 7.0833788],
          [117.0432329, 7.0834879],
          [117.0430317, 7.0836503],
          [117.042852, 7.0838259],
          [117.0427018, 7.0840043],
          [117.0425141, 7.0841826],
          [117.0423505, 7.084313],
          [117.0420957, 7.0845153],
          [117.0419535, 7.0845952],
          [117.0417577, 7.0847682],
          [117.0415485, 7.0848986],
          [117.0412883, 7.0851541],
          [117.0410335, 7.085423],
          [117.040894, 7.0854842],
          [117.0408779, 7.0856066],
          [117.040666, 7.0858941],
          [117.040379, 7.0862162],
          [117.0400035, 7.0866021],
          [117.0397809, 7.0867725],
          [117.0394134, 7.0869508],
          [117.0383781, 7.0874299],
          [117.0377102, 7.0877014],
          [117.0374098, 7.087736],
          [117.0374635, 7.0878691],
          [117.0374474, 7.0880315],
          [117.0374957, 7.0881246],
          [117.0374501, 7.0882178],
          [117.0374823, 7.0883801],
          [117.0376137, 7.088436],
          [117.0377183, 7.0883775],
          [117.0378551, 7.0883908],
          [117.0380455, 7.0884813],
          [117.0381018, 7.0886117],
          [117.0383137, 7.0886862],
          [117.0384505, 7.088814],
          [117.038649, 7.0890615],
          [117.038708, 7.0891946],
          [117.0388502, 7.0893117],
          [117.0389977, 7.0895513],
          [117.0392096, 7.0898281],
          [117.0394376, 7.090049],
          [117.0396683, 7.0903631],
          [117.0399606, 7.0906639],
          [117.0402771, 7.0910605],
          [117.0405453, 7.0912814],
          [117.0408297, 7.091513],
          [117.0412427, 7.091827],
          [117.0415458, 7.09204],
          [117.0418006, 7.0922183],
          [117.0418516, 7.0924073],
          [117.0421439, 7.092575],
          [117.0422512, 7.0925564],
          [117.0422432, 7.0926628],
          [117.0425114, 7.0926335],
          [117.0425892, 7.0925217],
          [117.0428655, 7.092559],
          [117.0431551, 7.0924978],
          [117.0432302, 7.0924073],
          [117.0434475, 7.0923408],
          [117.0435145, 7.092229],
          [117.0436513, 7.0919734],
          [117.0438793, 7.0919575],
          [117.0440108, 7.0919042],
          [117.0440885, 7.0919947],
          [117.0441851, 7.0918963],
          [117.0443299, 7.0918989],
          [117.0442843, 7.0917073],
          [117.0443433, 7.0915422],
          [117.0443112, 7.0913559],
          [117.0441449, 7.0913],
          [117.0441905, 7.0911669],
          [117.0443943, 7.0910871],
          [117.0445204, 7.0909939],
          [117.0447966, 7.0909939],
          [117.0450836, 7.0909673],
          [117.0451185, 7.0910685],
          [117.0452875, 7.0910179],
          [117.0454511, 7.0909407],
          [117.0457676, 7.0909753],
          [117.0460948, 7.0909753],
          [117.0463523, 7.0910072],
          [117.046524, 7.0909886],
          [117.0466688, 7.0910285],
          [117.0469022, 7.0910552],
          [117.0470604, 7.0911004],
          [117.0473099, 7.090978],
          [117.0474681, 7.0908289],
          [117.0477632, 7.090624],
          [117.0479429, 7.090427],
          [117.0480931, 7.0903312],
          [117.0482326, 7.0903365],
          [117.0484284, 7.0901874],
          [117.0487341, 7.0901741],
          [117.049107, 7.0901715],
          [117.0495361, 7.090238],
          [117.0499974, 7.0903338],
          [117.0503998, 7.090443],
          [117.0507243, 7.0904776],
          [117.0509121, 7.0905814],
          [117.0510837, 7.0907065],
          [117.0513251, 7.0908529],
          [117.0517811, 7.0910685],
          [117.0520762, 7.0911483],
          [117.0522559, 7.0910578],
          [117.0526099, 7.090938],
          [117.0528701, 7.0908742],
          [117.0531142, 7.0909354],
          [117.0534173, 7.0910685],
          [117.0536211, 7.0912308],
        ],
      ],
      [
        [
          [116.843464, 7.2068432],
          [116.843504, 7.2068194],
          [116.8435306, 7.2067929],
          [116.8435626, 7.2067718],
          [116.8436106, 7.2067374],
          [116.8436346, 7.2066924],
          [116.8436773, 7.2066422],
          [116.8437252, 7.206621],
          [116.8437679, 7.2066052],
          [116.8438212, 7.2065946],
          [116.8439038, 7.2065681],
          [116.8440265, 7.2065496],
          [116.8440958, 7.2065549],
          [116.8441837, 7.2065549],
          [116.844237, 7.2065549],
          [116.8442824, 7.2065523],
          [116.844389, 7.2065338],
          [116.8444476, 7.2065153],
          [116.8444929, 7.2064941],
          [116.8445782, 7.2064491],
          [116.8446476, 7.2064227],
          [116.8447462, 7.2064068],
          [116.8448395, 7.2064068],
          [116.8449115, 7.2064068],
          [116.8450127, 7.2064042],
          [116.845122, 7.2064015],
          [116.845194, 7.2064015],
          [116.845282, 7.2064042],
          [116.8453566, 7.2064042],
          [116.8453886, 7.2064042],
          [116.8455219, 7.2063883],
          [116.8455992, 7.2063724],
          [116.8456445, 7.2063486],
          [116.8456952, 7.2063248],
          [116.8457671, 7.2062746],
          [116.8458178, 7.2062296],
          [116.8458604, 7.206182],
          [116.8458871, 7.2061424],
          [116.8459057, 7.206063],
          [116.8459191, 7.2060048],
          [116.8459324, 7.2059467],
          [116.8459511, 7.2058647],
          [116.8459591, 7.2058039],
          [116.8459751, 7.2057139],
          [116.8459724, 7.2056214],
          [116.8459724, 7.2055949],
          [116.8459511, 7.2054944],
          [116.8459431, 7.2054548],
          [116.8459004, 7.2053648],
          [116.8458818, 7.2053305],
          [116.8458551, 7.2052247],
          [116.8458444, 7.2051771],
          [116.8458418, 7.2051215],
          [116.8458418, 7.2049972],
          [116.8458204, 7.2048968],
          [116.8457725, 7.2047487],
          [116.8457725, 7.2047116],
          [116.8457831, 7.2046164],
          [116.8458018, 7.2045397],
          [116.8458045, 7.2044577],
          [116.8458045, 7.2044022],
          [116.8458018, 7.2043282],
          [116.8457378, 7.2042568],
          [116.8456898, 7.2041774],
          [116.8456125, 7.2041351],
          [116.8455485, 7.2041034],
          [116.8454819, 7.2040478],
          [116.8454659, 7.2039923],
          [116.8453806, 7.20395],
          [116.845298, 7.2039288],
          [116.8452287, 7.203905],
          [116.8451807, 7.2038706],
          [116.8451327, 7.2038151],
          [116.8450927, 7.2037569],
          [116.8450474, 7.203704],
          [116.8449781, 7.2036538],
          [116.8449168, 7.2036141],
          [116.8448768, 7.2035506],
          [116.8448741, 7.2034739],
          [116.8448528, 7.2034211],
          [116.8448208, 7.2033893],
          [116.8447808, 7.2033391],
          [116.8447782, 7.2033047],
          [116.8447568, 7.2032862],
          [116.8446769, 7.2032597],
          [116.8446156, 7.2032227],
          [116.8445276, 7.2031434],
          [116.844461, 7.2030931],
          [116.844381, 7.2030349],
          [116.844341, 7.2029926],
          [116.844309, 7.202953],
          [116.8442504, 7.2029424],
          [116.8442077, 7.202908],
          [116.8442104, 7.2028816],
          [116.8441731, 7.2028287],
          [116.8441251, 7.202863],
          [116.8441091, 7.2028921],
          [116.8440771, 7.2028895],
          [116.8440265, 7.2028234],
          [116.8439838, 7.2028154],
          [116.8439278, 7.2028392],
          [116.8439065, 7.2028128],
          [116.8438265, 7.2027678],
          [116.8437252, 7.2027678],
          [116.8436932, 7.2028075],
          [116.8436746, 7.2028657],
          [116.8436746, 7.2029106],
          [116.8436986, 7.2029635],
          [116.8437066, 7.2030482],
          [116.8436906, 7.2030852],
          [116.8436453, 7.2031883],
          [116.8436159, 7.203302],
          [116.8435866, 7.2033655],
          [116.843472, 7.203466],
          [116.8434347, 7.2034951],
          [116.843392, 7.2035401],
          [116.8433814, 7.2035639],
          [116.84336, 7.2036115],
          [116.8433174, 7.2037331],
          [116.8432961, 7.2037887],
          [116.8433014, 7.2038521],
          [116.8432987, 7.2038918],
          [116.8433067, 7.2039209],
          [116.8433201, 7.2039526],
          [116.8433707, 7.204032],
          [116.8433787, 7.2040716],
          [116.8433467, 7.2041615],
          [116.8432721, 7.2042224],
          [116.8432214, 7.2042647],
          [116.8431841, 7.2043044],
          [116.8431441, 7.2043361],
          [116.8430748, 7.2043705],
          [116.8429788, 7.2044049],
          [116.8429629, 7.2044683],
          [116.8429948, 7.2045635],
          [116.8430108, 7.2045979],
          [116.8430402, 7.2046746],
          [116.8430482, 7.2047354],
          [116.8430375, 7.2048544],
          [116.8430055, 7.2049047],
          [116.8429602, 7.2049523],
          [116.8429202, 7.2049867],
          [116.8428776, 7.2049734],
          [116.8428536, 7.2049338],
          [116.8428082, 7.2049391],
          [116.8427309, 7.2049787],
          [116.8426776, 7.2049999],
          [116.8426403, 7.2050369],
          [116.8426083, 7.2050792],
          [116.8426083, 7.2051083],
          [116.8426536, 7.2051268],
          [116.8426536, 7.2051903],
          [116.8426403, 7.20523],
          [116.8426137, 7.2052855],
          [116.842595, 7.2053569],
          [116.842587, 7.2054072],
          [116.8425897, 7.2054495],
          [116.8426057, 7.2054918],
          [116.842635, 7.2055341],
          [116.8426643, 7.205542],
          [116.8427283, 7.2055394],
          [116.8427576, 7.2055129],
          [116.8427736, 7.2054733],
          [116.8428189, 7.2054363],
          [116.8428669, 7.2054627],
          [116.8429042, 7.2055209],
          [116.8429629, 7.2056082],
          [116.8429815, 7.2056372],
          [116.8430135, 7.2056584],
          [116.8430562, 7.2056584],
          [116.8430935, 7.2056981],
          [116.8431228, 7.205743],
          [116.8431335, 7.2058012],
          [116.8431281, 7.2058594],
          [116.8431201, 7.2059123],
          [116.8431255, 7.2059652],
          [116.8431574, 7.2060128],
          [116.8431894, 7.206071],
          [116.8432214, 7.2061635],
          [116.8432268, 7.2062349],
          [116.8432268, 7.206309],
          [116.8432001, 7.2063777],
          [116.8431441, 7.20642],
          [116.8430828, 7.2064465],
          [116.8430322, 7.2064888],
          [116.8430135, 7.2065311],
          [116.8430082, 7.2065787],
          [116.8430268, 7.2066422],
          [116.8430428, 7.2066686],
          [116.8430535, 7.2067136],
          [116.8431041, 7.2067057],
          [116.8431335, 7.2066924],
          [116.8431734, 7.2067162],
          [116.8431521, 7.2067638],
          [116.8431521, 7.2067956],
          [116.8431841, 7.20683],
          [116.8432241, 7.2068405],
          [116.8433014, 7.2068167],
          [116.8433121, 7.2068432],
          [116.8432881, 7.2068802],
          [116.8433174, 7.2069172],
          [116.8433814, 7.2069014],
          [116.8434134, 7.2069648],
          [116.843456, 7.2069516],
          [116.8434613, 7.2069067],
          [116.843464, 7.2068432],
        ],
      ],
      [
        [
          [116.8918856, 7.2900726],
          [116.8919045, 7.2900913],
          [116.891929, 7.2901006],
          [116.8919375, 7.2901231],
          [116.8919478, 7.2901343],
          [116.8919752, 7.290138],
          [116.8920015, 7.2901502],
          [116.8920251, 7.2901427],
          [116.8920355, 7.2901586],
          [116.8920506, 7.2901707],
          [116.892092, 7.2901717],
          [116.8921259, 7.290167],
          [116.8921457, 7.290182],
          [116.8921288, 7.2902063],
          [116.8921269, 7.2902268],
          [116.8921514, 7.2902165],
          [116.8921665, 7.2901988],
          [116.8921891, 7.2901707],
          [116.892207, 7.2901474],
          [116.8922221, 7.2901268],
          [116.8922324, 7.290096],
          [116.8922315, 7.2900595],
          [116.8922258, 7.2899632],
          [116.8922061, 7.2899267],
          [116.8921844, 7.2898791],
          [116.8921778, 7.289822],
          [116.8921684, 7.2897744],
          [116.8921514, 7.2897239],
          [116.8921288, 7.2896706],
          [116.8921165, 7.289637],
          [116.8921193, 7.2895827],
          [116.8921175, 7.2895425],
          [116.8920958, 7.2894864],
          [116.8920637, 7.2894388],
          [116.8920279, 7.2894014],
          [116.8919836, 7.2893658],
          [116.8919544, 7.28935],
          [116.8919271, 7.2893247],
          [116.8919139, 7.2892929],
          [116.891896, 7.2892387],
          [116.8918856, 7.28922],
          [116.8918602, 7.2892116],
          [116.8918046, 7.2892303],
          [116.8917782, 7.2892415],
          [116.8917103, 7.2893182],
          [116.8916641, 7.2893658],
          [116.891666, 7.2893958],
          [116.8916689, 7.2894238],
          [116.8916811, 7.2894462],
          [116.8916962, 7.2894584],
          [116.8917103, 7.2894659],
          [116.8916962, 7.2894874],
          [116.8916745, 7.2895079],
          [116.8916736, 7.2895379],
          [116.8916802, 7.2895687],
          [116.8916962, 7.2896435],
          [116.8917037, 7.2896912],
          [116.891715, 7.2897267],
          [116.8917197, 7.2897809],
          [116.8917414, 7.289852],
          [116.8917527, 7.2898772],
          [116.8917754, 7.2899305],
          [116.891781, 7.2899389],
          [116.8918065, 7.2899557],
          [116.8918272, 7.2899567],
          [116.8918262, 7.2899791],
          [116.8918451, 7.2899922],
          [116.891863, 7.289995],
          [116.8918724, 7.290009],
          [116.8918724, 7.2900408],
          [116.8918856, 7.2900726],
        ],
      ],
      [
        [
          [117.3203233, 7.2296415],
          [117.320859, 7.2304503],
          [117.3218289, 7.2312406],
          [117.3234569, 7.2319966],
          [117.3241497, 7.2320997],
          [117.3244268, 7.2314812],
          [117.3248078, 7.2302097],
          [117.3255699, 7.2295912],
          [117.3271633, 7.2300723],
          [117.3287913, 7.2299004],
          [117.330073, 7.2295568],
          [117.3311122, 7.2301754],
          [117.332567, 7.2313437],
          [117.3337447, 7.2319966],
          [117.3354074, 7.2319279],
          [117.3365159, 7.231275],
          [117.3380054, 7.2296599],
          [117.3385163, 7.2281049],
          [117.3382219, 7.2266273],
          [117.3370701, 7.2252957],
          [117.3369835, 7.2243936],
          [117.336767, 7.2234142],
          [117.336074, 7.2229302],
          [117.3353901, 7.2221685],
          [117.334368, 7.221255],
          [117.3332944, 7.220519],
          [117.3323245, 7.220519],
          [117.3312765, 7.2208254],
          [117.3308695, 7.2212807],
          [117.3302548, 7.2216788],
          [117.3296484, 7.2220969],
          [117.3288346, 7.2224005],
          [117.3283495, 7.2218993],
          [117.3276223, 7.221507],
          [117.326713, 7.2213781],
          [117.325033, 7.2208799],
          [117.324574, 7.2208541],
          [117.3242016, 7.2214641],
          [117.3244526, 7.2217103],
          [117.324574, 7.2222802],
          [117.3244612, 7.2225522],
          [117.3237858, 7.2227155],
          [117.3236041, 7.2224864],
          [117.3231796, 7.2223976],
          [117.3227119, 7.2220453],
          [117.3221146, 7.2217905],
          [117.3219982, 7.2210446],
          [117.3215574, 7.2207719],
          [117.3207897, 7.2208283],
          [117.3197081, 7.2214888],
          [117.3187806, 7.2225465],
          [117.318312, 7.2233234],
          [117.3181918, 7.2240586],
          [117.3188562, 7.2251804],
          [117.3189243, 7.2256318],
          [117.3186427, 7.2259355],
          [117.3186421, 7.2267046],
          [117.3190223, 7.2279645],
          [117.3197449, 7.2289486],
          [117.3203233, 7.2296415],
        ],
      ],
      [
        [
          [117.3335487, 7.2369082],
          [117.334063, 7.2369933],
          [117.3347978, 7.2370905],
          [117.3353, 7.2368718],
          [117.3366471, 7.236726],
          [117.3376269, 7.2366288],
          [117.3387413, 7.2366045],
          [117.3392312, 7.2368353],
          [117.3395129, 7.2363858],
          [117.3393047, 7.235669],
          [117.3385699, 7.2352559],
          [117.3372839, 7.2348914],
          [117.3360348, 7.2348307],
          [117.3351775, 7.2348671],
          [117.3349938, 7.2354017],
          [117.335202, 7.2359727],
          [117.3349938, 7.2361793],
          [117.3338426, 7.2363615],
          [117.3333405, 7.2365923],
          [117.3335487, 7.2369082],
        ],
      ],
      [
        [
          [117.2016467, 7.185037],
          [117.2017003, 7.1850476],
          [117.2017593, 7.1850796],
          [117.201872, 7.1851008],
          [117.2020651, 7.1851221],
          [117.2022529, 7.1851487],
          [117.2024245, 7.1851966],
          [117.2025908, 7.1850583],
          [117.2026605, 7.1851434],
          [117.2028805, 7.1852499],
          [117.203079, 7.1853191],
          [117.2032828, 7.1853936],
          [117.2033901, 7.1853616],
          [117.2035081, 7.1851647],
          [117.2037066, 7.185005],
          [117.2038783, 7.1847975],
          [117.2040017, 7.1845899],
          [117.2040768, 7.1844568],
          [117.2041411, 7.1842706],
          [117.2041036, 7.1840311],
          [117.2040231, 7.1838448],
          [117.2039105, 7.1836],
          [117.2038246, 7.1833764],
          [117.2037924, 7.1833179],
          [117.2036422, 7.1832274],
          [117.2034759, 7.183105],
          [117.2032882, 7.1831156],
          [117.2030897, 7.1830464],
          [117.20302, 7.1828761],
          [117.2029288, 7.1828442],
          [117.2028, 7.1828814],
          [117.2027625, 7.182759],
          [117.2026445, 7.1825674],
          [117.2025747, 7.1823918],
          [117.2025425, 7.1821736],
          [117.2024513, 7.181966],
          [117.202328, 7.1818063],
          [117.2020866, 7.181801],
          [117.2020275, 7.1817425],
          [117.2018988, 7.1816733],
          [117.201652, 7.1817797],
          [117.2015126, 7.1820458],
          [117.2013999, 7.1822268],
          [117.2011907, 7.1823226],
          [117.2010298, 7.1823492],
          [117.2008313, 7.1824131],
          [117.200842, 7.1824929],
          [117.2009386, 7.1825568],
          [117.2007615, 7.1826526],
          [117.2006435, 7.1827218],
          [117.2006757, 7.1828495],
          [117.2006543, 7.1829613],
          [117.2003646, 7.1829666],
          [117.2001983, 7.1829772],
          [117.2001768, 7.1831529],
          [117.2002573, 7.1832327],
          [117.2002573, 7.1833817],
          [117.2002144, 7.1835254],
          [117.2001285, 7.183584],
          [117.1999515, 7.1836425],
          [117.1998442, 7.1837649],
          [117.1999515, 7.1839565],
          [117.1999301, 7.1840736],
          [117.1998442, 7.184212],
          [117.1999515, 7.1842919],
          [117.2000159, 7.1842919],
          [117.2001232, 7.1843717],
          [117.2002466, 7.1845367],
          [117.2003002, 7.1845793],
          [117.2003699, 7.1846218],
          [117.2006757, 7.1847549],
          [117.200901, 7.184856],
          [117.2011371, 7.184872],
          [117.2013355, 7.1849412],
          [117.2016467, 7.185037],
        ],
      ],
      [
        [
          [117.2265825, 7.1857309],
          [117.2266388, 7.1857708],
          [117.226781, 7.1857974],
          [117.2268722, 7.1857947],
          [117.2269178, 7.1857522],
          [117.2269956, 7.1856697],
          [117.2270117, 7.1855499],
          [117.2270519, 7.1854621],
          [117.2270626, 7.1853956],
          [117.2270707, 7.1852811],
          [117.2271082, 7.1851321],
          [117.2270707, 7.1849804],
          [117.2270492, 7.1849006],
          [117.2270626, 7.1848208],
          [117.2271002, 7.184725],
          [117.2270733, 7.1846025],
          [117.227076, 7.1845493],
          [117.2270814, 7.1844163],
          [117.2269661, 7.1842859],
          [117.2268668, 7.1842326],
          [117.2267407, 7.1841368],
          [117.226671, 7.1840597],
          [117.2264618, 7.1840836],
          [117.2263867, 7.1841422],
          [117.2262982, 7.1841528],
          [117.2262016, 7.1841129],
          [117.2261185, 7.1841794],
          [117.2260407, 7.1842699],
          [117.2260675, 7.184371],
          [117.2259951, 7.1844242],
          [117.2259173, 7.1844429],
          [117.2258315, 7.1845094],
          [117.2257108, 7.1845121],
          [117.2255633, 7.1843976],
          [117.2254506, 7.1843577],
          [117.2253728, 7.1844509],
          [117.2253272, 7.1845866],
          [117.2253916, 7.1846691],
          [117.2254828, 7.184693],
          [117.2255981, 7.1847968],
          [117.2256679, 7.1848474],
          [117.2256813, 7.1849033],
          [117.2256732, 7.1849911],
          [117.2257001, 7.1850949],
          [117.2258234, 7.185188],
          [117.2258503, 7.1852146],
          [117.2261453, 7.1853929],
          [117.2262499, 7.1855153],
          [117.2263894, 7.1856111],
          [117.2265825, 7.1857309],
        ],
      ],
      [
        [
          [116.8613687, 7.1905735],
          [116.8614033, 7.1905756],
          [116.8614423, 7.1905692],
          [116.8614813, 7.1905541],
          [116.8615051, 7.1905412],
          [116.8615788, 7.1905219],
          [116.8616048, 7.1905155],
          [116.8616654, 7.1905133],
          [116.8617131, 7.1905112],
          [116.8617456, 7.1905047],
          [116.8617868, 7.1904983],
          [116.8618301, 7.1905026],
          [116.8618886, 7.190509],
          [116.8619297, 7.190524],
          [116.8619731, 7.1905283],
          [116.8620012, 7.1905283],
          [116.8620467, 7.1905112],
          [116.8620814, 7.1905004],
          [116.8621247, 7.1905047],
          [116.8621485, 7.1905219],
          [116.8621918, 7.1905412],
          [116.8622655, 7.190552],
          [116.8622937, 7.1905498],
          [116.8623457, 7.1905391],
          [116.8623911, 7.1905133],
          [116.8624388, 7.1904961],
          [116.8624756, 7.1904854],
          [116.8625211, 7.1904725],
          [116.8625493, 7.1904617],
          [116.8625948, 7.1904166],
          [116.8625991, 7.1903693],
          [116.8625861, 7.1903199],
          [116.8625579, 7.1902554],
          [116.8625298, 7.1902124],
          [116.8624865, 7.1901135],
          [116.8624778, 7.1900276],
          [116.8624236, 7.1899051],
          [116.8624063, 7.1898621],
          [116.8623695, 7.1897998],
          [116.862337, 7.1897611],
          [116.8623002, 7.1897052],
          [116.8622568, 7.1896515],
          [116.8622287, 7.1896214],
          [116.8621983, 7.1895934],
          [116.8620922, 7.1895311],
          [116.862025, 7.1895032],
          [116.8619709, 7.1894731],
          [116.8619319, 7.1894279],
          [116.8618929, 7.1893914],
          [116.8618128, 7.1893936],
          [116.8617434, 7.1893807],
          [116.8616741, 7.1893678],
          [116.8615853, 7.1893721],
          [116.861529, 7.1893721],
          [116.8614055, 7.1893807],
          [116.8612842, 7.1894258],
          [116.8612257, 7.1894752],
          [116.8611715, 7.1894967],
          [116.8611477, 7.1894731],
          [116.8610871, 7.1894322],
          [116.8610481, 7.189458],
          [116.8610437, 7.1895096],
          [116.8609961, 7.1895268],
          [116.8609549, 7.1894989],
          [116.8609073, 7.1894602],
          [116.8608596, 7.1894537],
          [116.8608574, 7.1895053],
          [116.8608769, 7.1895784],
          [116.8609116, 7.1896536],
          [116.8609462, 7.1896923],
          [116.8609722, 7.1897095],
          [116.8610329, 7.1897417],
          [116.8610892, 7.1897847],
          [116.8611195, 7.1898127],
          [116.8611499, 7.1898384],
          [116.8611715, 7.1898642],
          [116.861178, 7.1898943],
          [116.8611759, 7.1899223],
          [116.8611585, 7.1899652],
          [116.861126, 7.1899803],
          [116.8610632, 7.1899738],
          [116.8610091, 7.1899524],
          [116.8609332, 7.1899072],
          [116.8608834, 7.1899158],
          [116.8608466, 7.1899416],
          [116.8608509, 7.1899889],
          [116.8608704, 7.1900362],
          [116.8609008, 7.1900856],
          [116.8609441, 7.1901372],
          [116.8609722, 7.1901608],
          [116.8609961, 7.1901823],
          [116.8610524, 7.1902146],
          [116.8610784, 7.1902382],
          [116.8611087, 7.1902661],
          [116.861113, 7.1902898],
          [116.8611347, 7.1903801],
          [116.861152, 7.190408],
          [116.8612539, 7.1904639],
          [116.8612734, 7.1904811],
          [116.8613687, 7.1905735],
        ],
      ],
      [
        [
          [116.8896325, 7.2006437],
          [116.8896509, 7.2006696],
          [116.8896677, 7.2006817],
          [116.8896953, 7.2007],
          [116.8897198, 7.2007],
          [116.8897351, 7.2006787],
          [116.8897535, 7.2006589],
          [116.8897703, 7.2006605],
          [116.889801, 7.2006574],
          [116.8898102, 7.2006361],
          [116.8898576, 7.200586],
          [116.8898607, 7.2005419],
          [116.8898791, 7.2005146],
          [116.8899204, 7.2004918],
          [116.8899603, 7.2004705],
          [116.8899848, 7.2004614],
          [116.8900169, 7.2004431],
          [116.8900445, 7.200434],
          [116.890115, 7.200431],
          [116.8901441, 7.2004295],
          [116.8901747, 7.2004295],
          [116.8902115, 7.2004188],
          [116.8902329, 7.2004021],
          [116.8902651, 7.2003778],
          [116.8902942, 7.2003672],
          [116.8903984, 7.2003459],
          [116.8904413, 7.2003368],
          [116.8904949, 7.2003155],
          [116.8905071, 7.2002669],
          [116.8905102, 7.2002425],
          [116.890527, 7.2001818],
          [116.8905439, 7.2001438],
          [116.8905515, 7.2001134],
          [116.8905515, 7.2000708],
          [116.8905515, 7.2000283],
          [116.89055, 7.1999903],
          [116.8905469, 7.1999188],
          [116.8905423, 7.1998611],
          [116.8905408, 7.1998155],
          [116.890524, 7.1997714],
          [116.8905041, 7.1997182],
          [116.8904795, 7.1996651],
          [116.8904581, 7.1995967],
          [116.8904183, 7.1995192],
          [116.8903003, 7.1994812],
          [116.890236, 7.1994827],
          [116.8902054, 7.1994903],
          [116.8901946, 7.1995587],
          [116.89019, 7.19958],
          [116.8901824, 7.1996301],
          [116.8901563, 7.199624],
          [116.8901226, 7.1996848],
          [116.8901119, 7.1997243],
          [116.8901058, 7.1997593],
          [116.8900951, 7.1998033],
          [116.8900813, 7.1998748],
          [116.8900736, 7.1999386],
          [116.890069, 7.1999751],
          [116.890066, 7.2000222],
          [116.8900445, 7.2000815],
          [116.8900338, 7.200124],
          [116.8900185, 7.2001711],
          [116.8900062, 7.2001985],
          [116.8899802, 7.2002365],
          [116.8899541, 7.2002957],
          [116.8899358, 7.2003261],
          [116.8898944, 7.2003626],
          [116.8898408, 7.2003672],
          [116.8897964, 7.2003824],
          [116.889755, 7.2004052],
          [116.8897183, 7.200431],
          [116.8896953, 7.2004431],
          [116.88968, 7.2004675],
          [116.8896723, 7.2005009],
          [116.8896478, 7.2005419],
          [116.8896294, 7.2005814],
          [116.8896294, 7.2006042],
          [116.8896325, 7.2006437],
        ],
      ],
      [
        [
          [117.3335294, 7.2017864],
          [117.3337119, 7.2016848],
          [117.3338863, 7.2015944],
          [117.3340445, 7.2015145],
          [117.334243, 7.2014028],
          [117.3346588, 7.2010834],
          [117.3347821, 7.2009956],
          [117.3351764, 7.2007455],
          [117.3355814, 7.2004421],
          [117.335686, 7.2003304],
          [117.3360347, 7.2000962],
          [117.3361662, 7.1999472],
          [117.3364639, 7.1996571],
          [117.3366087, 7.1995107],
          [117.336767, 7.1993511],
          [117.3370164, 7.1991089],
          [117.3374885, 7.1988242],
          [117.3377004, 7.1986459],
          [117.337931, 7.1984623],
          [117.3381054, 7.1983479],
          [117.3383173, 7.1982414],
          [117.3385399, 7.1981642],
          [117.3387625, 7.1980791],
          [117.3389127, 7.1979753],
          [117.3391005, 7.1978795],
          [117.3392561, 7.1978715],
          [117.3392426, 7.1979381],
          [117.339189, 7.1979939],
          [117.3391032, 7.1980631],
          [117.3390737, 7.1982095],
          [117.3391166, 7.1982654],
          [117.3392722, 7.1983665],
          [117.3394545, 7.1983159],
          [117.3396691, 7.1982148],
          [117.3397013, 7.1981217],
          [117.3397094, 7.1980312],
          [117.3397496, 7.1979673],
          [117.3398435, 7.1978369],
          [117.3398193, 7.1977332],
          [117.3399105, 7.1976773],
          [117.3401197, 7.197632],
          [117.3402941, 7.197632],
          [117.3404228, 7.1976267],
          [117.3405757, 7.1975655],
          [117.3407849, 7.1975203],
          [117.3408788, 7.197491],
          [117.3410612, 7.1973553],
          [117.3409298, 7.1973233],
          [117.3406072, 7.1974943],
          [117.3400171, 7.1976061],
          [117.3393627, 7.1976008],
          [117.3386707, 7.1976327],
          [117.3384856, 7.1976034],
          [117.3383729, 7.1976141],
          [117.3379813, 7.1977844],
          [117.3375146, 7.197944],
          [117.3372518, 7.1981622],
          [117.3370962, 7.1984869],
          [117.33671, 7.1988754],
          [117.3363398, 7.1992905],
          [117.3358892, 7.1997323],
          [117.3355888, 7.200025],
          [117.3352455, 7.200323],
          [117.3348592, 7.2006477],
          [117.3345696, 7.2009457],
          [117.334237, 7.2012491],
          [117.3338132, 7.2014513],
          [117.3330997, 7.2016642],
          [117.3327832, 7.2017068],
          [117.3322736, 7.2017919],
          [117.3319571, 7.2018771],
          [117.3316299, 7.2020048],
          [117.3314636, 7.2022071],
          [117.3312329, 7.2025849],
          [117.3310666, 7.2029255],
          [117.330954, 7.2032821],
          [117.3307608, 7.2035429],
          [117.3305141, 7.2037292],
          [117.330337, 7.2039261],
          [117.3303531, 7.2041337],
          [117.3304121, 7.2042028],
          [117.3306321, 7.2040538],
          [117.3307561, 7.2040053],
          [117.330901, 7.2038988],
          [117.331078, 7.2037498],
          [117.3312148, 7.2036513],
          [117.3314669, 7.2034065],
          [117.331711, 7.2031617],
          [117.3320972, 7.2027812],
          [117.3323494, 7.2025869],
          [117.3326766, 7.2023607],
          [117.3330172, 7.2021399],
          [117.333382, 7.2018684],
          [117.3335294, 7.2017864],
        ],
      ],
      [
        [
          [116.8925516, 7.2016828],
          [116.8925603, 7.2016947],
          [116.892569, 7.2017054],
          [116.8925852, 7.201714],
          [116.8926134, 7.2017032],
          [116.8926166, 7.2016882],
          [116.892622, 7.201656],
          [116.8926415, 7.2016538],
          [116.8926881, 7.2016742],
          [116.8927119, 7.2016936],
          [116.8927347, 7.2017172],
          [116.8927542, 7.2017344],
          [116.8927953, 7.2017462],
          [116.8928463, 7.2017624],
          [116.8928885, 7.2017849],
          [116.8929286, 7.2018053],
          [116.8929567, 7.2018215],
          [116.8929968, 7.2018129],
          [116.8929957, 7.2018021],
          [116.89296, 7.2017871],
          [116.8929524, 7.2017645],
          [116.8929697, 7.2017516],
          [116.893012, 7.2017538],
          [116.8930261, 7.2017645],
          [116.8930629, 7.2017914],
          [116.8930975, 7.2017881],
          [116.8930921, 7.201772],
          [116.893077, 7.2017656],
          [116.8930553, 7.2017462],
          [116.893064, 7.2017333],
          [116.893091, 7.2017301],
          [116.8931311, 7.2017301],
          [116.8931842, 7.2017204],
          [116.8931929, 7.2017054],
          [116.8931842, 7.2016721],
          [116.8931669, 7.2016291],
          [116.8931549, 7.2015915],
          [116.8931279, 7.2015507],
          [116.8931073, 7.2015227],
          [116.8930921, 7.2014948],
          [116.893103, 7.201455],
          [116.8930986, 7.201426],
          [116.8930845, 7.2014034],
          [116.8930607, 7.2013809],
          [116.8930445, 7.2013583],
          [116.893038, 7.2012917],
          [116.8930304, 7.2012756],
          [116.8929903, 7.2012627],
          [116.892973, 7.2012616],
          [116.8929632, 7.2012541],
          [116.8929437, 7.2012304],
          [116.8929156, 7.2012089],
          [116.8928766, 7.2011928],
          [116.8928365, 7.2011928],
          [116.8927867, 7.2012003],
          [116.8927488, 7.2012057],
          [116.8927065, 7.2012068],
          [116.8926784, 7.2012197],
          [116.8926556, 7.2012261],
          [116.892621, 7.2012143],
          [116.8926058, 7.2011724],
          [116.8925755, 7.2011584],
          [116.8925473, 7.2011692],
          [116.8925061, 7.2011874],
          [116.8924964, 7.2012003],
          [116.8924769, 7.2012337],
          [116.8924758, 7.2012562],
          [116.8924834, 7.2012799],
          [116.8924823, 7.2013003],
          [116.8924693, 7.2013003],
          [116.8924607, 7.2012895],
          [116.8924325, 7.2012788],
          [116.8924152, 7.2013003],
          [116.8924173, 7.2013228],
          [116.8924227, 7.2013486],
          [116.892419, 7.2013664],
          [116.8924012, 7.2013632],
          [116.8923947, 7.2013812],
          [116.8924053, 7.2013981],
          [116.8924208, 7.2014056],
          [116.8924282, 7.2014271],
          [116.8924433, 7.2014572],
          [116.8924311, 7.2014652],
          [116.8924138, 7.2014855],
          [116.8924111, 7.2015021],
          [116.8924022, 7.2015177],
          [116.8924065, 7.2015322],
          [116.8924314, 7.201532],
          [116.892452, 7.2015175],
          [116.8924682, 7.2015034],
          [116.8924834, 7.2015206],
          [116.8924769, 7.2015507],
          [116.8924357, 7.2015951],
          [116.8924496, 7.2016056],
          [116.8924644, 7.2016064],
          [116.8924769, 7.2015948],
          [116.8924837, 7.2015749],
          [116.8924931, 7.2015571],
          [116.8924964, 7.2015818],
          [116.8925007, 7.2016044],
          [116.8925311, 7.201628],
          [116.8925376, 7.2016484],
          [116.8925462, 7.2016721],
          [116.8925516, 7.2016828],
        ],
      ],
      [
        [
          [117.3293899, 7.2040619],
          [117.329645, 7.2039367],
          [117.3298596, 7.2037664],
          [117.330042, 7.2034577],
          [117.3301171, 7.2032874],
          [117.3299133, 7.202851],
          [117.3297631, 7.2024519],
          [117.3293714, 7.2020687],
          [117.3289691, 7.2018505],
          [117.328481, 7.2018611],
          [117.3281537, 7.2019356],
          [117.3278265, 7.2020846],
          [117.3271077, 7.2021538],
          [117.3268341, 7.2017866],
          [117.3264586, 7.2015205],
          [117.3261528, 7.2015312],
          [117.3258041, 7.2014779],
          [117.3255949, 7.2012917],
          [117.3253803, 7.2011213],
          [117.325096, 7.2008393],
          [117.3248922, 7.2005785],
          [117.3246669, 7.2000835],
          [117.3245167, 7.2000463],
          [117.324109, 7.2000356],
          [117.3237817, 7.200174],
          [117.3234384, 7.2003337],
          [117.3230736, 7.2005679],
          [117.3229181, 7.2008073],
          [117.3227088, 7.2010947],
          [117.3225747, 7.2012331],
          [117.3224192, 7.2014939],
          [117.3223333, 7.2017653],
          [117.3222146, 7.2021705],
          [117.322049, 7.2027339],
          [117.3219632, 7.2030692],
          [117.3219954, 7.2034897],
          [117.3221456, 7.2037398],
          [117.322269, 7.2037984],
          [117.3226123, 7.2036813],
          [117.3228751, 7.2034205],
          [117.3231595, 7.2030107],
          [117.3235135, 7.2028297],
          [117.3238193, 7.2028989],
          [117.3240124, 7.2032023],
          [117.324066, 7.2034737],
          [117.3239963, 7.2037185],
          [117.323771, 7.2040591],
          [117.3237388, 7.2043998],
          [117.3239158, 7.2045754],
          [117.3242913, 7.2046659],
          [117.3246669, 7.204602],
          [117.3248707, 7.2044689],
          [117.3251926, 7.2042454],
          [117.3254501, 7.2040379],
          [117.3263781, 7.2038782],
          [117.3269467, 7.2038037],
          [117.3272418, 7.2037292],
          [117.3276709, 7.2036493],
          [117.327907, 7.2036281],
          [117.3283737, 7.2036014],
          [117.328599, 7.2036813],
          [117.3288404, 7.2037824],
          [117.3291408, 7.2039474],
          [117.3293899, 7.2040619],
        ],
      ],
      [
        [
          [117.3184553, 7.2440088],
          [117.3201401, 7.2435836],
          [117.321103, 7.2425475],
          [117.3215726, 7.2401709],
          [117.3221408, 7.23997],
          [117.3216338, 7.237735],
          [117.3205152, 7.2343771],
          [117.3201397, 7.2340598],
          [117.3198458, 7.2342664],
          [117.3193621, 7.2342664],
          [117.3184702, 7.2345438],
          [117.3181864, 7.2340781],
          [117.3185476, 7.2337014],
          [117.3182843, 7.2331608],
          [117.3177278, 7.2327074],
          [117.317418, 7.2320345],
          [117.3174792, 7.231099],
          [117.3172543, 7.2307989],
          [117.317302, 7.2303396],
          [117.3174883, 7.2303725],
          [117.3176996, 7.2303215],
          [117.3178335, 7.2297243],
          [117.3155001, 7.2271725],
          [117.3146551, 7.2233333],
          [117.3130102, 7.2213943],
          [117.3102952, 7.2189594],
          [117.3095478, 7.2133702],
          [117.3090564, 7.2121273],
          [117.3085468, 7.2117069],
          [117.3081552, 7.2116005],
          [117.3095499, 7.2105893],
          [117.3099308, 7.2103019],
          [117.3100381, 7.2094238],
          [117.3097055, 7.2093386],
          [117.3095821, 7.2089235],
          [117.308418, 7.208833],
          [117.308638, 7.2084977],
          [117.3099147, 7.2080294],
          [117.309193, 7.2068824],
          [117.3079928, 7.2057646],
          [117.3080256, 7.2038817],
          [117.3062538, 7.2036991],
          [117.305372, 7.2044767],
          [117.3038578, 7.2044909],
          [117.3029471, 7.2056917],
          [117.3038289, 7.2111349],
          [117.3057214, 7.2185525],
          [117.305372, 7.2194211],
          [117.3034615, 7.2199071],
          [117.3022858, 7.2195183],
          [117.3011346, 7.2197613],
          [117.2938588, 7.2210435],
          [117.2925594, 7.2234308],
          [117.2931497, 7.2252529],
          [117.2921945, 7.2261277],
          [117.2923904, 7.2278772],
          [117.2951092, 7.2281445],
          [117.2950357, 7.2299426],
          [117.295648, 7.2304529],
          [117.2974606, 7.2305258],
          [117.2981464, 7.2315463],
          [117.2994935, 7.2347052],
          [117.3023593, 7.2361145],
          [117.3017714, 7.2384471],
          [117.3053107, 7.2413813],
          [117.3064987, 7.2404396],
          [117.3097809, 7.2419461],
          [117.312965, 7.2413143],
          [117.3153164, 7.2421405],
          [117.316933, 7.2432582],
          [117.3184553, 7.2440088],
        ],
      ],
      [
        [
          [117.000186086175972, 7.280596348960122],
          [116.9993882, 7.2811213],
          [116.9984575, 7.2831233],
          [116.9982588, 7.2843992],
          [116.9977464, 7.2846274],
          [116.9958536, 7.2842436],
          [116.9945806, 7.2855142],
          [116.9934275, 7.2870754],
          [116.9924236, 7.288901],
          [116.99257, 7.2926249],
          [116.9934514, 7.2952882],
          [116.9948602, 7.2968569],
          [116.9970249, 7.2977697],
          [116.9981438, 7.2989418],
          [116.9984366, 7.3003318],
          [116.9970274, 7.3021924],
          [116.9951007, 7.3027797],
          [116.9924968, 7.3028108],
          [116.9911583, 7.3037236],
          [116.9881047, 7.3040659],
          [116.9854486, 7.3060159],
          [116.981672, 7.3079037],
          [116.979582, 7.3078622],
          [116.9784003, 7.3069391],
          [116.9770095, 7.3052069],
          [116.9767243, 7.3012222],
          [116.9677909, 7.2918999],
          [116.9665416, 7.2874903],
          [116.965956, 7.2819097],
          [116.9646384, 7.2787044],
          [116.9648998, 7.2741195],
          [116.965841, 7.2726361],
          [116.9658739, 7.2710796],
          [116.9693547, 7.2673768],
          [116.971352, 7.2664536],
          [116.9733382, 7.2673127],
          [116.9743324, 7.2676154],
          [116.9776516, 7.2651411],
          [116.9813074, 7.2587772],
          [116.984525, 7.2540805],
          [116.9863688, 7.2470446],
          [116.9865989, 7.2451565],
          [116.9873936, 7.2437975],
          [116.9835313, 7.241434],
          [116.9782961, 7.2412594],
          [116.969846, 7.2457354],
          [116.9627038, 7.247916],
          [116.9538751, 7.2486261],
          [116.9443562, 7.2438631],
          [116.9393838, 7.2383201],
          [116.938625335693359, 7.237703911459345],
          [116.9355146, 7.2351767],
          [116.930564679501842, 7.233969211578369],
          [116.9295648, 7.2337253],
          [116.928860992309723, 7.233969211578369],
          [116.9249907, 7.2353105],
          [116.9207279, 7.2383097],
          [116.9166517, 7.2397607],
          [116.9116404, 7.2393056],
          [116.9098052, 7.2384601],
          [116.9094418, 7.2378507],
          [116.9091359, 7.2376639],
          [116.9082548, 7.2376929],
          [116.9081028, 7.2377389],
          [116.90804, 7.2377495],
          [116.9079941, 7.2377602],
          [116.9079512, 7.2377754],
          [116.90787, 7.2377967],
          [116.9077505, 7.2378179],
          [116.9076647, 7.2378392],
          [116.9076218, 7.2378422],
          [116.9075698, 7.2378529],
          [116.9075115, 7.2378817],
          [116.9074809, 7.237903],
          [116.9074487, 7.2379334],
          [116.9073997, 7.2379425],
          [116.9073002, 7.2379532],
          [116.9072328, 7.2379684],
          [116.9072113, 7.2379866],
          [116.9071608, 7.2380231],
          [116.9071026, 7.2380215],
          [116.9070571, 7.2380184],
          [116.9069807, 7.2380213],
          [116.906866, 7.2380155],
          [116.9066603, 7.238033],
          [116.9066162, 7.238033],
          [116.9064692, 7.2380417],
          [116.9063869, 7.2380622],
          [116.9063222, 7.2381351],
          [116.9063016, 7.2382138],
          [116.906281, 7.2383275],
          [116.9062458, 7.2383742],
          [116.9061958, 7.2384063],
          [116.9060782, 7.23852],
          [116.9060223, 7.23859],
          [116.9059606, 7.2386688],
          [116.9059253, 7.2387183],
          [116.905893, 7.2387796],
          [116.9058695, 7.2388262],
          [116.9058518, 7.2388758],
          [116.905846, 7.2389137],
          [116.9058166, 7.2390245],
          [116.9058136, 7.2390654],
          [116.9058224, 7.2391237],
          [116.9058577, 7.2391674],
          [116.90589, 7.2391995],
          [116.9059136, 7.2392433],
          [116.9059283, 7.2393074],
          [116.9059283, 7.2393512],
          [116.9058695, 7.239427],
          [116.9058107, 7.239462],
          [116.905746, 7.2395028],
          [116.9055402, 7.2396136],
          [116.9054491, 7.2396545],
          [116.9054167, 7.2396603],
          [116.9053668, 7.239672],
          [116.905308, 7.2396836],
          [116.9052345, 7.2396865],
          [116.9051492, 7.2396953],
          [116.905061, 7.2396778],
          [116.9050169, 7.2396545],
          [116.9049728, 7.2396516],
          [116.9049317, 7.2396545],
          [116.9048905, 7.2396865],
          [116.9048523, 7.2397245],
          [116.9048082, 7.2397653],
          [116.9047524, 7.2398294],
          [116.9046877, 7.2399024],
          [116.9046377, 7.2399607],
          [116.9046113, 7.2400073],
          [116.9045672, 7.2400948],
          [116.9045642, 7.240124],
          [116.9045613, 7.240229],
          [116.9045495, 7.2402756],
          [116.9045289, 7.2403398],
          [116.9045142, 7.240404],
          [116.9045025, 7.2404506],
          [116.9044643, 7.2405352],
          [116.9044319, 7.2406023],
          [116.9043996, 7.2406723],
          [116.9043357, 7.2407457],
          [116.9042484, 7.2407539],
          [116.9041861, 7.2407704],
          [116.9041154, 7.2407952],
          [116.9040447, 7.2408117],
          [116.9039782, 7.2408199],
          [116.9038576, 7.240824],
          [116.9037163, 7.240824],
          [116.9036165, 7.240824],
          [116.9035209, 7.2408405],
          [116.9034585, 7.2408776],
          [116.9033795, 7.2408983],
          [116.9033712, 7.2409436],
          [116.9034419, 7.2409725],
          [116.9035126, 7.2409807],
          [116.9035708, 7.2409931],
          [116.9036414, 7.2410137],
          [116.9037287, 7.2410344],
          [116.9037703, 7.2410385],
          [116.9036789, 7.2411292],
          [116.9036789, 7.2412117],
          [116.9037163, 7.2412777],
          [116.903762, 7.2413107],
          [116.9038327, 7.2413519],
          [116.9038784, 7.2413726],
          [116.9040073, 7.2413932],
          [116.9041154, 7.2414014],
          [116.9042567, 7.2414138],
          [116.9043648, 7.2414262],
          [116.9044355, 7.2414427],
          [116.9045228, 7.2414592],
          [116.9046309, 7.2414633],
          [116.9047307, 7.2414633],
          [116.9048762, 7.2414509],
          [116.9050757, 7.2414344],
          [116.9052254, 7.2414344],
          [116.9053127, 7.2414262],
          [116.9054333, 7.241422],
          [116.9054956, 7.2414303],
          [116.9056245, 7.2414674],
          [116.905716, 7.241488],
          [116.9058282, 7.2415128],
          [116.9059155, 7.241554],
          [116.9059904, 7.2415705],
          [116.9060694, 7.2415746],
          [116.9061983, 7.2415746],
          [116.906298, 7.2415623],
          [116.9063729, 7.2415499],
          [116.9065017, 7.2415128],
          [116.9065932, 7.2414715],
          [116.9066847, 7.2414138],
          [116.9067637, 7.2413602],
          [116.906851, 7.2412901],
          [116.9069424, 7.2412365],
          [116.9070297, 7.2411993],
          [116.9071046, 7.2411787],
          [116.9072168, 7.241154],
          [116.9073291, 7.2411416],
          [116.9074413, 7.2411416],
          [116.9076035, 7.2411333],
          [116.9076658, 7.2411292],
          [116.9079319, 7.2410839],
          [116.9079943, 7.2410467],
          [116.9081231, 7.2410344],
          [116.9082603, 7.2410715],
          [116.9084266, 7.2411127],
          [116.9087149, 7.2411884],
          [116.9088563, 7.2412956],
          [116.9089311, 7.2413698],
          [116.9091223, 7.2415183],
          [116.9092388, 7.2416091],
          [116.9093468, 7.2417328],
          [116.90943, 7.2418318],
          [116.9094882, 7.2418978],
          [116.9095713, 7.2420215],
          [116.9096878, 7.2421617],
          [116.9097958, 7.2422772],
          [116.9098707, 7.2423514],
          [116.9100453, 7.2424999],
          [116.9101284, 7.2425411],
          [116.9102615, 7.2426071],
          [116.9103862, 7.2426401],
          [116.910486, 7.2426401],
          [116.9105525, 7.2424999],
          [116.9104943, 7.2424174],
          [116.9104278, 7.2423102],
          [116.9103613, 7.2422112],
          [116.9102864, 7.2421205],
          [116.9102282, 7.242038],
          [116.9101866, 7.2418565],
          [116.9101866, 7.2417575],
          [116.9102365, 7.2416173],
          [116.9102532, 7.2415266],
          [116.9102947, 7.2414193],
          [116.9103862, 7.2412956],
          [116.9104777, 7.2411966],
          [116.9105691, 7.2411059],
          [116.9107022, 7.2410234],
          [116.9108601, 7.2409657],
          [116.9110015, 7.2408914],
          [116.9112343, 7.2408172],
          [116.9114838, 7.240776],
          [116.9117498, 7.2407347],
          [116.9119577, 7.2407265],
          [116.9121656, 7.2407265],
          [116.9123402, 7.2407677],
          [116.91244, 7.2407924],
          [116.9126146, 7.2408089],
          [116.9128058, 7.2408667],
          [116.9129721, 7.2409739],
          [116.9130054, 7.2410564],
          [116.9130054, 7.2411471],
          [116.9130137, 7.2412544],
          [116.9130719, 7.2413121],
          [116.9132798, 7.2413533],
          [116.9134544, 7.2413781],
          [116.9137787, 7.2414688],
          [116.9139283, 7.2415761],
          [116.9140364, 7.2416173],
          [116.9141528, 7.2415513],
          [116.9142277, 7.2414193],
          [116.9142776, 7.2413286],
          [116.9144438, 7.2412296],
          [116.9146101, 7.2411636],
          [116.9147515, 7.2411224],
          [116.9150009, 7.2411059],
          [116.9153003, 7.2411059],
          [116.9154499, 7.2411471],
          [116.9157077, 7.2412214],
          [116.9160819, 7.2412626],
          [116.9162897, 7.2414276],
          [116.9163646, 7.2415018],
          [116.9165475, 7.2415596],
          [116.9167886, 7.2415926],
          [116.9170131, 7.2415761],
          [116.9171379, 7.2415596],
          [116.9174039, 7.2415018],
          [116.9177033, 7.2415018],
          [116.9179361, 7.2415761],
          [116.918144, 7.2417328],
          [116.9182354, 7.2418648],
          [116.91841, 7.2420792],
          [116.9185181, 7.2421864],
          [116.9185431, 7.2423184],
          [116.918568, 7.2425081],
          [116.9185846, 7.2426649],
          [116.9185846, 7.2429206],
          [116.9184766, 7.2430773],
          [116.9181855, 7.243102],
          [116.9180109, 7.2430278],
          [116.9178613, 7.2429783],
          [116.9176368, 7.2429701],
          [116.9174289, 7.2429948],
          [116.9172875, 7.2430608],
          [116.9171961, 7.2431268],
          [116.9171462, 7.2432423],
          [116.917063, 7.243432],
          [116.9170215, 7.2435969],
          [116.9169466, 7.2437867],
          [116.9168718, 7.2438691],
          [116.9167055, 7.2440011],
          [116.9165392, 7.2440259],
          [116.9163646, 7.2439764],
          [116.9162149, 7.2439186],
          [116.9160652, 7.2438856],
          [116.9158158, 7.2438691],
          [116.9156329, 7.2438691],
          [116.9155165, 7.2438691],
          [116.9152504, 7.2439186],
          [116.9151257, 7.2439269],
          [116.9149843, 7.2439269],
          [116.9148596, 7.2438774],
          [116.9147349, 7.2437949],
          [116.9146351, 7.2436712],
          [116.9145852, 7.2435722],
          [116.9144854, 7.243465],
          [116.914369, 7.2433825],
          [116.9141279, 7.2433082],
          [116.9139699, 7.2433082],
          [116.9138535, 7.2433495],
          [116.9136706, 7.2435392],
          [116.9134793, 7.2437784],
          [116.9133795, 7.2439269],
          [116.9132798, 7.2440506],
          [116.9131052, 7.2442073],
          [116.913022, 7.2442651],
          [116.9128058, 7.2444053],
          [116.9126811, 7.2445125],
          [116.912598, 7.2446198],
          [116.9124649, 7.24476],
          [116.9123069, 7.2449002],
          [116.9122321, 7.2449909],
          [116.9121157, 7.2450899],
          [116.9119245, 7.2451971],
          [116.9117997, 7.2452549],
          [116.9117249, 7.2455106],
          [116.9117498, 7.2456508],
          [116.9117748, 7.2457993],
          [116.9117748, 7.245956],
          [116.9117249, 7.246121],
          [116.9116501, 7.2462282],
          [116.9114146, 7.2463982],
          [116.9112703, 7.2465581],
          [116.9112788, 7.246676],
          [116.9113127, 7.2468192],
          [116.9113297, 7.2469623],
          [116.9113127, 7.2471307],
          [116.9112448, 7.2472739],
          [116.9111514, 7.2473918],
          [116.911075, 7.2474675],
          [116.9109562, 7.2475433],
          [116.9108204, 7.2475854],
          [116.9106591, 7.2476023],
          [116.910413, 7.2474844],
          [116.9102262, 7.2473833],
          [116.9100819, 7.2473918],
          [116.9099461, 7.2474254],
          [116.9097254, 7.2475349],
          [116.909649, 7.2476023],
          [116.9094199, 7.247678],
          [116.909301, 7.2476359],
          [116.9091397, 7.247577],
          [116.90897, 7.2475012],
          [116.9088427, 7.2474423],
          [116.9087153, 7.2473581],
          [116.9086135, 7.2472739],
          [116.9085116, 7.2471728],
          [116.9084692, 7.246996],
          [116.9085116, 7.2469034],
          [116.9085626, 7.2468276],
          [116.9086474, 7.2467097],
          [116.9087153, 7.2466255],
          [116.9088342, 7.246415],
          [116.9089191, 7.2463055],
          [116.9090634, 7.2461455],
          [116.9092077, 7.2459435],
          [116.9092925, 7.2458677],
          [116.909335, 7.2457582],
          [116.9093435, 7.2456403],
          [116.9093519, 7.2455393],
          [116.909318, 7.2454214],
          [116.9092246, 7.2452951],
          [116.9091228, 7.2452951],
          [116.9090209, 7.2453625],
          [116.908953, 7.2454635],
          [116.9089106, 7.2455393],
          [116.9088596, 7.2456487],
          [116.9087832, 7.2457414],
          [116.9086559, 7.2458087],
          [116.9085626, 7.2458256],
          [116.9084098, 7.2458677],
          [116.908274, 7.245935],
          [116.9080787, 7.2460277],
          [116.9079599, 7.2461119],
          [116.9078326, 7.2462129],
          [116.9077647, 7.2463224],
          [116.9077053, 7.246415],
          [116.9076883, 7.2465918],
          [116.9076968, 7.2467434],
          [116.9076968, 7.2468444],
          [116.9076968, 7.246996],
          [116.9076713, 7.2471307],
          [116.9076289, 7.2472065],
          [116.9075355, 7.2473076],
          [116.9074336, 7.2473833],
          [116.9073233, 7.2474086],
          [116.907162, 7.2474086],
          [116.907022, 7.2473412],
          [116.9069159, 7.2472865],
          [116.906831, 7.2472402],
          [116.9067673, 7.247236],
          [116.9066188, 7.2472739],
          [116.9064617, 7.247337],
          [116.9063769, 7.2473707],
          [116.906241, 7.2474002],
          [116.9061859, 7.2474044],
          [116.9060586, 7.2473665],
          [116.9060119, 7.247316],
          [116.9059482, 7.2472612],
          [116.9058294, 7.2472486],
          [116.9057275, 7.2472823],
          [116.9056044, 7.2473076],
          [116.905371, 7.2472781],
          [116.905231, 7.2471897],
          [116.9051631, 7.2471265],
          [116.9050654, 7.247076],
          [116.9050145, 7.2470297],
          [116.9050103, 7.2469749],
          [116.9050442, 7.2468907],
          [116.9051843, 7.2467602],
          [116.9052649, 7.2467265],
          [116.9054432, 7.2466339],
          [116.9055068, 7.2465876],
          [116.9056384, 7.2464908],
          [116.9057318, 7.2464403],
          [116.9058548, 7.2463561],
          [116.90591, 7.2462887],
          [116.9060034, 7.2461624],
          [116.9060586, 7.2460698],
          [116.9060925, 7.2459645],
          [116.9061307, 7.2458382],
          [116.9061519, 7.245775],
          [116.9061774, 7.2456951],
          [116.9062113, 7.2456066],
          [116.9063047, 7.245493],
          [116.9064278, 7.2454214],
          [116.9065551, 7.2453625],
          [116.9066994, 7.2452867],
          [116.9068055, 7.2452277],
          [116.9068904, 7.2451688],
          [116.9069795, 7.2450888],
          [116.9070389, 7.2450046],
          [116.9070814, 7.2449414],
          [116.9070984, 7.2448825],
          [116.907145, 7.2447604],
          [116.9071663, 7.244693],
          [116.9071832, 7.244613],
          [116.9072299, 7.2444699],
          [116.9072766, 7.2443688],
          [116.9073106, 7.2443057],
          [116.9073572, 7.2442257],
          [116.907493, 7.2440868],
          [116.9076161, 7.2439899],
          [116.907735, 7.2439352],
          [116.9078793, 7.2438889],
          [116.9080151, 7.2438763],
          [116.9081127, 7.2438636],
          [116.9083249, 7.2438342],
          [116.9084183, 7.2437963],
          [116.9085328, 7.2437247],
          [116.9086814, 7.2436068],
          [116.9087366, 7.2435521],
          [116.9087705, 7.2434763],
          [116.9087748, 7.2434258],
          [116.908796, 7.2433542],
          [116.9088469, 7.2432995],
          [116.9089063, 7.2432405],
          [116.9089657, 7.2431479],
          [116.9089912, 7.2430679],
          [116.9089912, 7.2429542],
          [116.9089488, 7.2428742],
          [116.9088936, 7.2427816],
          [116.9088214, 7.24271],
          [116.9087238, 7.2426511],
          [116.9086262, 7.2426132],
          [116.9085413, 7.2425753],
          [116.908431, 7.2425374],
          [116.9083206, 7.2425164],
          [116.9081806, 7.2425079],
          [116.908049, 7.2425037],
          [116.9078453, 7.2425627],
          [116.9077647, 7.2426006],
          [116.9076543, 7.2426848],
          [116.9076161, 7.2428153],
          [116.9076119, 7.2429163],
          [116.9076246, 7.24303],
          [116.9075992, 7.2430974],
          [116.907544, 7.2431479],
          [116.9073785, 7.2431311],
          [116.9073318, 7.2430932],
          [116.9072257, 7.2430553],
          [116.9071153, 7.2430216],
          [116.9069413, 7.2429795],
          [116.9067418, 7.2429669],
          [116.906606, 7.2429669],
          [116.9064363, 7.2429963],
          [116.9063259, 7.2430258],
          [116.9062029, 7.2430721],
          [116.9059779, 7.2431732],
          [116.9058166, 7.2431437],
          [116.9056596, 7.2430721],
          [116.9055408, 7.2430216],
          [116.9053625, 7.2429963],
          [116.905231, 7.2429248],
          [116.9051885, 7.2428111],
          [116.90518, 7.2427311],
          [116.9051758, 7.2425964],
          [116.9051546, 7.2424827],
          [116.9051121, 7.2423943],
          [116.9050654, 7.2423101],
          [116.9049211, 7.2422343],
          [116.9048108, 7.2422048],
          [116.9046707, 7.2421838],
          [116.904484, 7.2421585],
          [116.9043991, 7.2421543],
          [116.9042463, 7.2421627],
          [116.9041445, 7.2421627],
          [116.9040044, 7.2421248],
          [116.9037031, 7.2420532],
          [116.9035333, 7.2419775],
          [116.9033424, 7.2419396],
          [116.9031132, 7.2419354],
          [116.9029774, 7.2419564],
          [116.902884, 7.2419732],
          [116.9027736, 7.2419901],
          [116.9026591, 7.242028],
          [116.9025148, 7.2421206],
          [116.9024002, 7.2422174],
          [116.9022813, 7.2423227],
          [116.902171, 7.2424322],
          [116.9020564, 7.2425416],
          [116.9019418, 7.2426553],
          [116.9018527, 7.2427648],
          [116.9018187, 7.2428153],
          [116.901823, 7.2428869],
          [116.9018781, 7.2429205],
          [116.9019842, 7.2428658],
          [116.9021667, 7.2428532],
          [116.9022559, 7.2428532],
          [116.9023959, 7.2428616],
          [116.9025699, 7.2430005],
          [116.9026506, 7.2430384],
          [116.9027949, 7.2430426],
          [116.9029646, 7.2430637],
          [116.9031556, 7.2430932],
          [116.9032108, 7.2431563],
          [116.9032235, 7.2432279],
          [116.9032362, 7.2433247],
          [116.9032447, 7.2434258],
          [116.9032447, 7.2435647],
          [116.903232, 7.2436742],
          [116.9032108, 7.2437584],
          [116.9031896, 7.2438468],
          [116.9031514, 7.2439689],
          [116.903092, 7.2440783],
          [116.9029774, 7.2442047],
          [116.9029222, 7.2442468],
          [116.9028585, 7.2442636],
          [116.9026378, 7.2442678],
          [116.9025445, 7.2442257],
          [116.9024256, 7.2441457],
          [116.9023492, 7.2441162],
          [116.9022177, 7.2441162],
          [116.9021455, 7.2441668],
          [116.9020988, 7.2442931],
          [116.9020819, 7.2444699],
          [116.9021031, 7.2446383],
          [116.9022219, 7.244832],
          [116.9022813, 7.2449288],
          [116.9023153, 7.244992],
          [116.9024002, 7.2450888],
          [116.9024681, 7.2451393],
          [116.9025869, 7.2451772],
          [116.9027694, 7.2452067],
          [116.902884, 7.2452235],
          [116.903024, 7.2452782],
          [116.9030495, 7.2453246],
          [116.9030665, 7.2454424],
          [116.9030792, 7.2456824],
          [116.9030538, 7.2457329],
          [116.9030198, 7.2457919],
          [116.9027779, 7.2460613],
          [116.9027227, 7.2461413],
          [116.9026421, 7.2462087],
          [116.9025869, 7.246255],
          [116.9024681, 7.2463308],
          [116.9023874, 7.2463813],
          [116.9022389, 7.2464655],
          [116.9020988, 7.2465245],
          [116.9019758, 7.246575],
          [116.9018315, 7.2466002],
          [116.9016277, 7.2466045],
          [116.9015174, 7.246596],
          [116.9013646, 7.2465624],
          [116.90125, 7.2464992],
          [116.9011779, 7.2464487],
          [116.9011142, 7.2463813],
          [116.9010124, 7.2462045],
          [116.9009445, 7.2461498],
          [116.9008214, 7.2461245],
          [116.9006516, 7.2461455],
          [116.9005158, 7.2462003],
          [116.9004309, 7.2462971],
          [116.9003927, 7.2463518],
          [116.9001296, 7.2466676],
          [116.9000829, 7.246836],
          [116.9000829, 7.2469413],
          [116.9000956, 7.247076],
          [116.9001381, 7.247156],
          [116.900189, 7.247236],
          [116.9002328, 7.2474154],
          [116.9003238, 7.2475358],
          [116.9004625, 7.2476046],
          [116.9005881, 7.2476647],
          [116.9006964, 7.2477292],
          [116.9007831, 7.2478151],
          [116.9008307, 7.2479484],
          [116.9008221, 7.2480601],
          [116.9007571, 7.2481461],
          [116.9006228, 7.2482621],
          [116.9005144, 7.2483094],
          [116.9003931, 7.2483352],
          [116.9002372, 7.2483696],
          [116.8999642, 7.2484426],
          [116.8998516, 7.2484598],
          [116.8997086, 7.2484641],
          [116.899518, 7.2484426],
          [116.8994313, 7.2483739],
          [116.8993837, 7.2483223],
          [116.8992797, 7.2484426],
          [116.8992364, 7.2485458],
          [116.8991064, 7.2485415],
          [116.8989417, 7.2484598],
          [116.8988508, 7.2483481],
          [116.8988464, 7.248232],
          [116.8988334, 7.2481074],
          [116.8987944, 7.2480171],
          [116.8987554, 7.2479742],
          [116.8986731, 7.2480043],
          [116.8985302, 7.2481547],
          [116.8983395, 7.2483223],
          [116.8982832, 7.248361],
          [116.8980622, 7.2484598],
          [116.8976593, 7.248563],
          [116.8975857, 7.2485759],
          [116.8974817, 7.2485802],
          [116.89733, 7.2485802],
          [116.8971871, 7.2485544],
          [116.8970874, 7.2484985],
          [116.8969488, 7.2484083],
          [116.8969098, 7.2483094],
          [116.8968318, 7.2481719],
          [116.8968101, 7.2480816],
          [116.8968058, 7.2479742],
          [116.8968101, 7.2478109],
          [116.8968535, 7.2476389],
          [116.8970311, 7.2474369],
          [116.8972391, 7.2474111],
          [116.8973777, 7.2473983],
          [116.897525, 7.2473295],
          [116.897655, 7.2472435],
          [116.897746, 7.2471189],
          [116.8978369, 7.2468997],
          [116.8978933, 7.2467622],
          [116.8979279, 7.2466461],
          [116.8979669, 7.2465602],
          [116.8980709, 7.2463667],
          [116.8981576, 7.2463023],
          [116.8983005, 7.2462249],
          [116.8984262, 7.2461476],
          [116.8984782, 7.2460659],
          [116.8984738, 7.2460014],
          [116.8984522, 7.2459069],
          [116.8984825, 7.2457951],
          [116.8985128, 7.2456576],
          [116.8985128, 7.2455501],
          [116.8984998, 7.2454728],
          [116.8983915, 7.2453267],
          [116.8983525, 7.2452837],
          [116.8982182, 7.2452407],
          [116.8980752, 7.2452493],
          [116.8979279, 7.2452966],
          [116.8978239, 7.2453438],
          [116.8976983, 7.2454728],
          [116.897603, 7.2456189],
          [116.8974124, 7.245894],
          [116.8971177, 7.2460229],
          [116.8969574, 7.2460229],
          [116.8969098, 7.2459198],
          [116.8969184, 7.2458037],
          [116.8969878, 7.2456275],
          [116.8970354, 7.2455501],
          [116.8970918, 7.24549],
          [116.8971177, 7.2454556],
          [116.8972304, 7.2453438],
          [116.8973214, 7.2452579],
          [116.8974514, 7.2451418],
          [116.8974557, 7.2450903],
          [116.8974124, 7.244914],
          [116.8974037, 7.2448668],
          [116.8975423, 7.244639],
          [116.897707, 7.2445874],
          [116.8977936, 7.2444327],
          [116.8978326, 7.2443037],
          [116.8978543, 7.244149],
          [116.8979496, 7.2440502],
          [116.8980146, 7.2439728],
          [116.8980276, 7.2438267],
          [116.8980016, 7.2436548],
          [116.8979626, 7.2435989],
          [116.8978976, 7.243543],
          [116.8978153, 7.243457],
          [116.8976723, 7.2433883],
          [116.8976247, 7.2433496],
          [116.8975423, 7.2431992],
          [116.8975163, 7.2430745],
          [116.8975683, 7.2429198],
          [116.8975857, 7.2428553],
          [116.897447, 7.2426146],
          [116.8974037, 7.242533],
          [116.897395, 7.2421934],
          [116.897317, 7.242129],
          [116.8972391, 7.2420645],
          [116.8971827, 7.2420043],
          [116.8970614, 7.2419055],
          [116.8969964, 7.2418367],
          [116.8968751, 7.2417078],
          [116.8968405, 7.2416519],
          [116.8968145, 7.2416046],
          [116.8967798, 7.2415487],
          [116.8967495, 7.2414757],
          [116.8967365, 7.2413811],
          [116.8967278, 7.2412737],
          [116.8966834, 7.2410614],
          [116.8966834, 7.2408852],
          [116.8967202, 7.2407514],
          [116.8967631, 7.2406542],
          [116.8967815, 7.2405752],
          [116.8968489, 7.2404171],
          [116.8968856, 7.240326],
          [116.8969347, 7.2401862],
          [116.8969714, 7.2400828],
          [116.8970082, 7.2399917],
          [116.8970327, 7.2399005],
          [116.8970694, 7.2397546],
          [116.897094, 7.2396877],
          [116.8971185, 7.2395966],
          [116.897143, 7.2395358],
          [116.8971981, 7.2394021],
          [116.8972471, 7.2392926],
          [116.89729, 7.2391832],
          [116.8973145, 7.2391225],
          [116.8973268, 7.2390434],
          [116.8973589, 7.2389565],
          [116.8973631, 7.2388802],
          [116.8973859, 7.2388184],
          [116.8974005, 7.2387606],
          [116.8974109, 7.2387153],
          [116.8974358, 7.238639],
          [116.8974629, 7.2385647],
          [116.8975003, 7.2385008],
          [116.8975065, 7.2384369],
          [116.8975231, 7.238373],
          [116.8975282, 7.2382779],
          [116.8975723, 7.2382167],
          [116.8976458, 7.2381671],
          [116.8977193, 7.2381438],
          [116.8978016, 7.2381321],
          [116.897881, 7.2381263],
          [116.8979633, 7.2381088],
          [116.8980426, 7.2380854],
          [116.8981338, 7.2380563],
          [116.8981985, 7.2380329],
          [116.8982984, 7.2380125],
          [116.8983984, 7.2380009],
          [116.8984895, 7.2379834],
          [116.8986218, 7.2379571],
          [116.8987247, 7.2379396],
          [116.8988129, 7.2379163],
          [116.8989187, 7.237893],
          [116.8990186, 7.2378784],
          [116.8990804, 7.2378638],
          [116.8991597, 7.2378463],
          [116.8992979, 7.2378259],
          [116.899389, 7.2378084],
          [116.8994567, 7.2377909],
          [116.8995037, 7.237753],
          [116.899489, 7.2376771],
          [116.8994273, 7.2376742],
          [116.8993773, 7.2377005],
          [116.899295, 7.2377384],
          [116.8992127, 7.2377705],
          [116.8991568, 7.2377792],
          [116.8990598, 7.2377938],
          [116.8989804, 7.2378084],
          [116.8988981, 7.2378142],
          [116.8988217, 7.2378171],
          [116.8987217, 7.2378346],
          [116.8986541, 7.2378434],
          [116.8986071, 7.2378609],
          [116.89856, 7.23789],
          [116.8985189, 7.2379105],
          [116.8984513, 7.2379309],
          [116.8983513, 7.2379542],
          [116.8983072, 7.23796],
          [116.8982278, 7.2379688],
          [116.8981455, 7.2379863],
          [116.8980867, 7.237995],
          [116.8980485, 7.2379979],
          [116.8979662, 7.2380038],
          [116.8979221, 7.2380067],
          [116.8978898, 7.237995],
          [116.8977751, 7.2379834],
          [116.8976546, 7.2379892],
          [116.8976046, 7.2379659],
          [116.8975458, 7.2379513],
          [116.8974753, 7.237928],
          [116.8974282, 7.2379017],
          [116.8973871, 7.2378521],
          [116.89734, 7.2377996],
          [116.8973077, 7.2377471],
          [116.8972871, 7.2376976],
          [116.897243, 7.2376276],
          [116.8972254, 7.2375867],
          [116.8972283, 7.2374555],
          [116.8971842, 7.2373826],
          [116.8971519, 7.2373009],
          [116.8971225, 7.2372397],
          [116.897096, 7.2371639],
          [116.8970637, 7.2370851],
          [116.8970343, 7.2370151],
          [116.8969873, 7.2369481],
          [116.8968961, 7.2368985],
          [116.896852, 7.2368577],
          [116.896805, 7.2367848],
          [116.8967815, 7.2367148],
          [116.8967756, 7.2366419],
          [116.8967874, 7.2365835],
          [116.896805, 7.2365281],
          [116.896808, 7.236464],
          [116.896808, 7.2364056],
          [116.8968168, 7.2362715],
          [116.8968991, 7.2361402],
          [116.8969373, 7.2360965],
          [116.8970402, 7.2360003],
          [116.8971078, 7.2359449],
          [116.8971578, 7.2359069],
          [116.8971872, 7.235869],
          [116.8972313, 7.2358224],
          [116.8972724, 7.2357728],
          [116.8973283, 7.2357349],
          [116.8973753, 7.2357174],
          [116.8974282, 7.2357115],
          [116.897537, 7.2357115],
          [116.897637, 7.2357786],
          [116.8977046, 7.2358049],
          [116.8977545, 7.2357699],
          [116.8977692, 7.2357232],
          [116.8977692, 7.2356386],
          [116.8977545, 7.2355832],
          [116.8977281, 7.2355395],
          [116.8977134, 7.2355132],
          [116.8976693, 7.2354695],
          [116.8976458, 7.2354432],
          [116.897584, 7.235382],
          [116.8975458, 7.2353441],
          [116.8974812, 7.2352653],
          [116.8974371, 7.2352041],
          [116.8973841, 7.2351429],
          [116.89734, 7.2350904],
          [116.8973018, 7.2350525],
          [116.8972666, 7.2350175],
          [116.8971931, 7.2349445],
          [116.8971431, 7.2348862],
          [116.8970931, 7.2348308],
          [116.8970637, 7.2348016],
          [116.8970079, 7.2347375],
          [116.8969608, 7.2347025],
          [116.8969314, 7.2346733],
          [116.8968932, 7.2346383],
          [116.8968197, 7.2345654],
          [116.8967727, 7.2345421],
          [116.8967315, 7.2345333],
          [116.8966257, 7.23451],
          [116.8965493, 7.23451],
          [116.8964258, 7.2345479],
          [116.8963582, 7.2345538],
          [116.8962406, 7.234545],
          [116.8961612, 7.2345275],
          [116.8961083, 7.2345042],
          [116.8960701, 7.2344721],
          [116.8960642, 7.2344371],
          [116.8960699, 7.2343438],
          [116.8960595, 7.2342366],
          [116.8960762, 7.2341149],
          [116.8960845, 7.2340675],
          [116.8960886, 7.2340242],
          [116.896098867137979, 7.233969211578369],
          [116.896099, 7.2339685],
          [116.8961261, 7.2338984],
          [116.896151, 7.2338654],
          [116.8961718, 7.2337953],
          [116.8962154, 7.2336901],
          [116.8962009, 7.2335891],
          [116.8961967, 7.233521],
          [116.8962134, 7.2334344],
          [116.896282, 7.2333292],
          [116.8963339, 7.2332529],
          [116.8964025, 7.2331498],
          [116.8964649, 7.2330653],
          [116.8965148, 7.2330158],
          [116.8965688, 7.2329704],
          [116.896652, 7.2328982],
          [116.8967517, 7.232791],
          [116.8968266, 7.2327291],
          [116.896891, 7.2326941],
          [116.8969638, 7.2326528],
          [116.8970636, 7.2326013],
          [116.8971529, 7.23256],
          [116.8972299, 7.232527],
          [116.8973442, 7.2324879],
          [116.8974772, 7.2324363],
          [116.8976123, 7.2324054],
          [116.8976664, 7.2323456],
          [116.8976664, 7.2323126],
          [116.897735, 7.2322466],
          [116.8978285, 7.232195],
          [116.897893, 7.2321497],
          [116.8980073, 7.2320775],
          [116.8981175, 7.2319909],
          [116.898157, 7.2319641],
          [116.8982172, 7.2319043],
          [116.8982422, 7.2318754],
          [116.8983172, 7.2317784],
          [116.898329, 7.2316151],
          [116.898329, 7.2314868],
          [116.8981879, 7.2312768],
          [116.8980468, 7.2311602],
          [116.8978351, 7.2311368],
          [116.8977293, 7.2313001],
          [116.8976587, 7.2315451],
          [116.8975646, 7.2317551],
          [116.8974118, 7.2318018],
          [116.8972354, 7.2317901],
          [116.8970237, 7.2318134],
          [116.8968238, 7.2317901],
          [116.8966239, 7.2316384],
          [116.8966239, 7.2315218],
          [116.8966474, 7.2313701],
          [116.896718, 7.2312068],
          [116.8968003, 7.2310902],
          [116.8968826, 7.2310085],
          [116.8969297, 7.2308802],
          [116.8970002, 7.2307752],
          [116.8970355, 7.2306469],
          [116.8970002, 7.2304952],
          [116.8969061, 7.2303552],
          [116.8968709, 7.2301861],
          [116.8968532, 7.2300694],
          [116.8968473, 7.2299295],
          [116.8968591, 7.229842],
          [116.8968944, 7.2297661],
          [116.8969297, 7.229702],
          [116.8969826, 7.2296611],
          [116.8970531, 7.229597],
          [116.8970355, 7.2294395],
          [116.8970355, 7.2293695],
          [116.8970355, 7.229247],
          [116.8970884, 7.2291654],
          [116.8971825, 7.2291362],
          [116.8972413, 7.2291362],
          [116.8973177, 7.229142],
          [116.8974235, 7.2292645],
          [116.8974706, 7.2293928],
          [116.8975058, 7.2294745],
          [116.8976293, 7.2295445],
          [116.8977822, 7.2295678],
          [116.8979644, 7.229597],
          [116.8980526, 7.2296262],
          [116.898182, 7.229702],
          [116.8983231, 7.2297778],
          [116.8984818, 7.229877],
          [116.8985994, 7.2299411],
          [116.8987347, 7.2299936],
          [116.8988875, 7.2300519],
          [116.8990463, 7.2301044],
          [116.8991697, 7.2301744],
          [116.8992638, 7.2302561],
          [116.8993873, 7.2303844],
          [116.8995049, 7.2305827],
          [116.8995695, 7.2307169],
          [116.8996342, 7.2308685],
          [116.8996813, 7.2310027],
          [116.8997107, 7.2311368],
          [116.8997577, 7.2312535],
          [116.8998047, 7.2313585],
          [116.8998753, 7.2314285],
          [116.9000281, 7.231481],
          [116.9000869, 7.2315043],
          [116.9001399, 7.2315918],
          [116.9001222, 7.2316618],
          [116.9000458, 7.2319067],
          [116.9000399, 7.2320292],
          [116.8999988, 7.23228],
          [116.899987, 7.2325367],
          [116.9000105, 7.2326125],
          [116.9000634, 7.2326417],
          [116.9001281, 7.2326417],
          [116.9002751, 7.2326417],
          [116.9003692, 7.2326825],
          [116.9004221, 7.232735],
          [116.9004221, 7.23284],
          [116.9004221, 7.2329333],
          [116.9004103, 7.2331258],
          [116.9004103, 7.2332424],
          [116.9003515, 7.2333883],
          [116.9003221, 7.2334524],
          [116.9002516, 7.2335632],
          [116.9001928, 7.2336099],
          [116.9001399, 7.2336799],
          [116.9000634, 7.2337674],
          [116.8998459, 7.2338432],
          [116.8996754, 7.2339015],
          [116.899585867024328, 7.233969211578369],
          [116.8995519, 7.2339949],
          [116.8994755, 7.2341057],
          [116.8994578, 7.2341932],
          [116.8994167, 7.2342748],
          [116.8993461, 7.2343506],
          [116.8992932, 7.234409],
          [116.8992638, 7.2344906],
          [116.8992521, 7.2345723],
          [116.8992638, 7.2346714],
          [116.8993638, 7.2347006],
          [116.8994931, 7.2347006],
          [116.8995519, 7.2346831],
          [116.899646, 7.2346248],
          [116.8998047, 7.2345198],
          [116.899887, 7.2344498],
          [116.8999694, 7.2343623],
          [116.9000752, 7.2342457],
          [116.9001163, 7.2341523],
          [116.9001575, 7.2340648],
          [116.900195042831001, 7.233969211578369],
          [116.9001987, 7.2339599],
          [116.9002339, 7.233849],
          [116.9002868, 7.2337324],
          [116.9003221, 7.2336507],
          [116.9003692, 7.2335574],
          [116.900428, 7.2334582],
          [116.9004691, 7.2333766],
          [116.9005044, 7.2332774],
          [116.9005279, 7.2331783],
          [116.9005455, 7.2330091],
          [116.9005749, 7.2329158],
          [116.9005985, 7.2327992],
          [116.900622, 7.232665],
          [116.9007219, 7.2325017],
          [116.9007513, 7.2323384],
          [116.9007278, 7.2321692],
          [116.9007278, 7.2320409],
          [116.9007219, 7.2319009],
          [116.900716, 7.2317318],
          [116.9006867, 7.2316443],
          [116.9006573, 7.2315043],
          [116.900622, 7.2313643],
          [116.9005926, 7.2312243],
          [116.9005867, 7.2310902],
          [116.9005867, 7.2309677],
          [116.9005985, 7.2308277],
          [116.9005985, 7.2306702],
          [116.9005985, 7.2305186],
          [116.9005632, 7.2304019],
          [116.900475, 7.2301628],
          [116.9004221, 7.2300111],
          [116.9003809, 7.2299003],
          [116.9003339, 7.2297895],
          [116.900281, 7.2296903],
          [116.9002045, 7.2295853],
          [116.9001222, 7.2294745],
          [116.9000575, 7.229387],
          [116.8999282, 7.2292237],
          [116.8998753, 7.2291712],
          [116.8997518, 7.2290895],
          [116.899646, 7.2289845],
          [116.8995166, 7.2287804],
          [116.8993344, 7.2286346],
          [116.8992579, 7.2285471],
          [116.8991991, 7.2284713],
          [116.8991227, 7.2283663],
          [116.8991168, 7.2282613],
          [116.8991168, 7.2281855],
          [116.8990816, 7.2280688],
          [116.8990345, 7.2279696],
          [116.8989816, 7.2278938],
          [116.898911, 7.2278122],
          [116.8988405, 7.2277247],
          [116.8987523, 7.2276605],
          [116.8986582, 7.2276022],
          [116.8985289, 7.227573],
          [116.898329, 7.2275497],
          [116.8981526, 7.2275555],
          [116.8980879, 7.2275555],
          [116.8979644, 7.2274972],
          [116.8978469, 7.227398],
          [116.8977881, 7.2273397],
          [116.8977058, 7.2272697],
          [116.8975823, 7.2271764],
          [116.8974529, 7.2270772],
          [116.897353, 7.2270072],
          [116.8972765, 7.2269314],
          [116.8972648, 7.2268264],
          [116.8972119, 7.2267273],
          [116.897159, 7.2266864],
          [116.8970825, 7.2266281],
          [116.8969826, 7.2265814],
          [116.896912, 7.226529],
          [116.8968297, 7.2264648],
          [116.896765, 7.2264123],
          [116.8967062, 7.2263423],
          [116.8966651, 7.2262548],
          [116.8966357, 7.2261732],
          [116.896571, 7.2260623],
          [116.8965063, 7.2259573],
          [116.8964769, 7.2258407],
          [116.8964475, 7.2257824],
          [116.8964123, 7.2257124],
          [116.896377, 7.2256249],
          [116.8963123, 7.2255024],
          [116.896277, 7.2253916],
          [116.8962653, 7.2253157],
          [116.8962594, 7.2252224],
          [116.8962594, 7.2251291],
          [116.8962712, 7.2250124],
          [116.8962888, 7.2249249],
          [116.8962829, 7.2248608],
          [116.8962124, 7.2248141],
          [116.8960889, 7.2248491],
          [116.8960419, 7.2249016],
          [116.8959066, 7.2249016],
          [116.8958243, 7.2247908],
          [116.895822525024414, 7.224789409575973],
          [116.8957126, 7.2247033],
          [116.8956185, 7.2246333],
          [116.8955362, 7.2245691],
          [116.8954363, 7.2244933],
          [116.895301, 7.2244466],
          [116.8951305, 7.2244583],
          [116.8950541, 7.22454],
          [116.8950129, 7.2246158],
          [116.8949365, 7.2247383],
          [116.8948895, 7.2248433],
          [116.8948424, 7.2249891],
          [116.894813, 7.2251058],
          [116.8947836, 7.2252341],
          [116.8947484, 7.2253391],
          [116.8947131, 7.2255024],
          [116.8947072, 7.2256307],
          [116.8947072, 7.2257532],
          [116.8947484, 7.2258932],
          [116.8948013, 7.2260507],
          [116.8948542, 7.226214],
          [116.894913, 7.2263248],
          [116.89496, 7.2264531],
          [116.8949953, 7.2265406],
          [116.8950541, 7.2266689],
          [116.8951011, 7.2267914],
          [116.8951482, 7.2268789],
          [116.8953598, 7.2270597],
          [116.8954304, 7.2270714],
          [116.8955421, 7.2270772],
          [116.8956421, 7.2271181],
          [116.8957302, 7.2272114],
          [116.8958126, 7.2273105],
          [116.895822525024414, 7.227330227138309],
          [116.8958772, 7.2274389],
          [116.8959889, 7.2276663],
          [116.8960477, 7.2277713],
          [116.89613, 7.2278822],
          [116.8962594, 7.2279172],
          [116.8963417, 7.2279055],
          [116.8963946, 7.227853],
          [116.8964123, 7.227783],
          [116.8964123, 7.2277188],
          [116.8964064, 7.227643],
          [116.8964005, 7.2274972],
          [116.8964005, 7.2273572],
          [116.8964005, 7.2272464],
          [116.8964064, 7.2271764],
          [116.8964358, 7.2270772],
          [116.8965357, 7.2271297],
          [116.8965651, 7.2272289],
          [116.8965769, 7.2273397],
          [116.8965828, 7.227468],
          [116.8965886, 7.227573],
          [116.8966063, 7.2277072],
          [116.8966298, 7.2278238],
          [116.8966357, 7.2279463],
          [116.8966357, 7.2280746],
          [116.8966357, 7.2281738],
          [116.8966416, 7.2283663],
          [116.896571, 7.2284654],
          [116.8964652, 7.2285413],
          [116.8963535, 7.2286171],
          [116.8962888, 7.2286696],
          [116.8961947, 7.2287512],
          [116.8960536, 7.2288329],
          [116.8959478, 7.2289146],
          [116.8958478, 7.2290195],
          [116.895822525024414, 7.229036556310054],
          [116.8957008, 7.2291187],
          [116.8956068, 7.2291654],
          [116.8954128, 7.2291712],
          [116.8953304, 7.2291595],
          [116.895107, 7.2291537],
          [116.8948836, 7.2291187],
          [116.8947249, 7.2291012],
          [116.8944897, 7.2291362],
          [116.8944074, 7.2291362],
          [116.8942369, 7.2291654],
          [116.894084, 7.229177],
          [116.8939017, 7.2291595],
          [116.8936489, 7.229107],
          [116.8934784, 7.2290429],
          [116.8933197, 7.2289962],
          [116.8932256, 7.2289729],
          [116.8931315, 7.2289321],
          [116.8930492, 7.2288971],
          [116.8929257, 7.2288679],
          [116.8927317, 7.2289204],
          [116.89262, 7.229002],
          [116.8925494, 7.2290604],
          [116.8923731, 7.2290837],
          [116.8922319, 7.2290545],
          [116.8920673, 7.2290604],
          [116.8918733, 7.229107],
          [116.8918086, 7.229142],
          [116.8916264, 7.2292587],
          [116.8915147, 7.2292995],
          [116.8914206, 7.2293403],
          [116.8912736, 7.2294103],
          [116.8912148, 7.2294628],
          [116.8911619, 7.2295445],
          [116.8911207, 7.2296495],
          [116.8910913, 7.229702],
          [116.8909385, 7.2297778],
          [116.8908268, 7.2297836],
          [116.8907444, 7.2297661],
          [116.8906562, 7.2297136],
          [116.8905975, 7.2296786],
          [116.8904446, 7.2295853],
          [116.8903211, 7.229562],
          [116.8902682, 7.2296028],
          [116.8901976, 7.229667],
          [116.8900859, 7.2297428],
          [116.8900095, 7.229807],
          [116.8898272, 7.229877],
          [116.8897508, 7.2298886],
          [116.8896273, 7.2298945],
          [116.8895274, 7.2299178],
          [116.8894274, 7.2299586],
          [116.8892393, 7.2300403],
          [116.8891217, 7.2300753],
          [116.8889571, 7.2300986],
          [116.8888277, 7.2300694],
          [116.8885984, 7.2299819],
          [116.888522, 7.2299353],
          [116.888422, 7.2298886],
          [116.8883456, 7.2298886],
          [116.8882104, 7.2298886],
          [116.8880575, 7.2299353],
          [116.8879164, 7.2299878],
          [116.8878576, 7.2300403],
          [116.8877694, 7.2300986],
          [116.887693, 7.2301744],
          [116.8876518, 7.2302444],
          [116.8876283, 7.2303377],
          [116.8875636, 7.2304486],
          [116.8874754, 7.2305652],
          [116.8874167, 7.2307344],
          [116.8873579, 7.2307985],
          [116.8872932, 7.2308977],
          [116.8872344, 7.2309793],
          [116.8871462, 7.2310493],
          [116.8870639, 7.2310493],
          [116.8868934, 7.2310027],
          [116.8868405, 7.2309677],
          [116.8867758, 7.2309268],
          [116.8866817, 7.2308452],
          [116.8866053, 7.2307869],
          [116.8864877, 7.2307344],
          [116.8863231, 7.2307344],
          [116.8861584, 7.2307344],
          [116.8860585, 7.2307344],
          [116.885888, 7.2307752],
          [116.885741, 7.230816],
          [116.8855881, 7.2308627],
          [116.8854764, 7.2309268],
          [116.8854588, 7.2310202],
          [116.885447, 7.231096],
          [116.8854529, 7.2312068],
          [116.8854294, 7.2313468],
          [116.8853118, 7.2314168],
          [116.8851295, 7.2314518],
          [116.8850355, 7.2314635],
          [116.884865, 7.2314518],
          [116.8847356, 7.2314401],
          [116.8845886, 7.231446],
          [116.8844828, 7.2314985],
          [116.8843946, 7.2315743],
          [116.8843182, 7.2316501],
          [116.8841477, 7.2317843],
          [116.8840712, 7.2317901],
          [116.883983, 7.2317901],
          [116.883889, 7.2317901],
          [116.8837772, 7.2318076],
          [116.8835715, 7.2318718],
          [116.8834598, 7.2319301],
          [116.8833598, 7.2319709],
          [116.8830893, 7.2320934],
          [116.8829365, 7.2321634],
          [116.8826954, 7.23221],
          [116.8825661, 7.2322217],
          [116.8823309, 7.2322917],
          [116.8822486, 7.23235],
          [116.8821957, 7.2323967],
          [116.8821016, 7.23249],
          [116.8820134, 7.2325425],
          [116.8818429, 7.2325833],
          [116.8816665, 7.2326183],
          [116.8815136, 7.2326767],
          [116.8814078, 7.2327175],
          [116.8811903, 7.2329391],
          [116.8810256, 7.2330033],
          [116.8809433, 7.2330325],
          [116.8808551, 7.2330675],
          [116.8807199, 7.2331025],
          [116.8806552, 7.2331258],
          [116.8805083, 7.2331491],
          [116.8804024, 7.2331491],
          [116.8803319, 7.2331258],
          [116.880132, 7.2330791],
          [116.8800144, 7.2330733],
          [116.8799203, 7.2330733],
          [116.8797616, 7.2330908],
          [116.8796205, 7.2331258],
          [116.8794676, 7.2331899],
          [116.87935, 7.2332658],
          [116.8792677, 7.2333299],
          [116.8791442, 7.2334349],
          [116.8790972, 7.2334757],
          [116.8789326, 7.2335866],
          [116.8789032, 7.2336391],
          [116.8788267, 7.2337265],
          [116.8787797, 7.2337849],
          [116.8787326, 7.2338549],
          [116.878668, 7.2339657],
          [116.878665244247074, 7.233969211578369],
          [116.8786268, 7.2340182],
          [116.8784172, 7.2342461],
          [116.8782841, 7.2343451],
          [116.8782176, 7.2344028],
          [116.8781428, 7.2345018],
          [116.8780181, 7.2346585],
          [116.8779931, 7.234741],
          [116.8779848, 7.2348235],
          [116.8779641, 7.2349094],
          [116.8779682, 7.2350049],
          [116.8780596, 7.2352772],
          [116.8781345, 7.2353431],
          [116.8783008, 7.2354091],
          [116.8784837, 7.2355164],
          [116.8785419, 7.2355741],
          [116.8786749, 7.2357391],
          [116.8787415, 7.2357886],
          [116.8788662, 7.2359041],
          [116.8789327, 7.2359783],
          [116.8790075, 7.2361928],
          [116.8789992, 7.2362835],
          [116.878941, 7.2363907],
          [116.8788163, 7.2365309],
          [116.8787082, 7.2365474],
          [116.8786167, 7.2365474],
          [116.8783673, 7.2364897],
          [116.8782758, 7.236399],
          [116.8782093, 7.2363412],
          [116.8781345, 7.2362752],
          [116.8780596, 7.2361845],
          [116.8780014, 7.2360855],
          [116.8779599, 7.2359948],
          [116.877885, 7.2358298],
          [116.8778351, 7.2357226],
          [116.8778019, 7.2356236],
          [116.877752, 7.2354586],
          [116.8776938, 7.2353101],
          [116.8776688, 7.2352194],
          [116.8776522, 7.2351204],
          [116.8776023, 7.2349802],
          [116.8776023, 7.23484],
          [116.8775358, 7.2346915],
          [116.8774859, 7.2345513],
          [116.8774527, 7.234411],
          [116.8775774, 7.2342956],
          [116.8776647, 7.234143],
          [116.8777187, 7.2340151],
          [116.877750046316194, 7.233969211578369],
          [116.877806, 7.2338873],
          [116.8778185, 7.2338295],
          [116.8778351, 7.2337264],
          [116.8778393, 7.2336109],
          [116.8778435, 7.2334625],
          [116.8778435, 7.2333758],
          [116.8778143, 7.2332191],
          [116.8777769, 7.2331119],
          [116.8777146, 7.2329469],
          [116.8776855, 7.2328768],
          [116.8776189, 7.2327077],
          [116.8775982, 7.2326417],
          [116.8775774, 7.2325386],
          [116.8775774, 7.2324066],
          [116.8775774, 7.232254],
          [116.8776023, 7.2321757],
          [116.8775898, 7.2318127],
          [116.8776106, 7.2317096],
          [116.8776772, 7.2316024],
          [116.8778268, 7.2314951],
          [116.8779017, 7.2314456],
          [116.8779765, 7.2313838],
          [116.8780056, 7.2313054],
          [116.8778809, 7.2312188],
          [116.8777852, 7.2311734],
          [116.8777395, 7.2310992],
          [116.8777437, 7.2309878],
          [116.877752, 7.2308146],
          [116.8777354, 7.2306826],
          [116.8777104, 7.2305465],
          [116.8775898, 7.2304022],
          [116.8774901, 7.2303279],
          [116.8774069, 7.2302867],
          [116.8772406, 7.2302413],
          [116.8771325, 7.2302001],
          [116.8770037, 7.230163],
          [116.8769454, 7.2301465],
          [116.8767999, 7.2301052],
          [116.8767168, 7.2300764],
          [116.8766295, 7.2300516],
          [116.8763676, 7.2300351],
          [116.8762844, 7.2300392],
          [116.8761639, 7.2301588],
          [116.8761347, 7.2302413],
          [116.8760641, 7.2303444],
          [116.8760308, 7.2303981],
          [116.8759809, 7.2305094],
          [116.8758479, 7.2305424],
          [116.8757938, 7.2305342],
          [116.8756608, 7.2304764],
          [116.8756192, 7.2304599],
          [116.8755652, 7.2304311],
          [116.8754862, 7.2303651],
          [116.8754321, 7.230262],
          [116.8754446, 7.2301753],
          [116.8755153, 7.2300351],
          [116.8755361, 7.2298495],
          [116.8755693, 7.2296928],
          [116.8755693, 7.2295814],
          [116.8755569, 7.2295072],
          [116.8755153, 7.2294206],
          [116.8754488, 7.2293257],
          [116.8753781, 7.2292309],
          [116.8752991, 7.2291566],
          [116.8752118, 7.2290865],
          [116.8751328, 7.2290164],
          [116.8750206, 7.2289215],
          [116.8748459, 7.2288638],
          [116.8747711, 7.2288514],
          [116.8745632, 7.228872],
          [116.8744468, 7.2289009],
          [116.874421119689941, 7.228917430238594],
          [116.874293, 7.2289999],
          [116.874214, 7.2290659],
          [116.8741309, 7.2291731],
          [116.8740768, 7.2292556],
          [116.8739022, 7.2293835],
          [116.873765, 7.2294618],
          [116.8736029, 7.2295484],
          [116.8734823, 7.2295567],
          [116.8733742, 7.2295608],
          [116.8732162, 7.2295526],
          [116.8729834, 7.2295154],
          [116.8728296, 7.2294659],
          [116.8727714, 7.2294123],
          [116.8726758, 7.2293175],
          [116.8726009, 7.2292267],
          [116.8725303, 7.2291195],
          [116.8724845, 7.2290783],
          [116.872364, 7.2290329],
          [116.87226, 7.2289999],
          [116.8721104, 7.2289257],
          [116.8720189, 7.228938],
          [116.8718942, 7.2290329],
          [116.8718401, 7.2290948],
          [116.8717112, 7.2291525],
          [116.8715865, 7.2291113],
          [116.8714867, 7.2290288],
          [116.8713496, 7.2288968],
          [116.8712415, 7.2287896],
          [116.8710918, 7.2287029],
          [116.8710544, 7.2286493],
          [116.8709879, 7.2285545],
          [116.8709712, 7.228472],
          [116.8709754, 7.2283029],
          [116.8710377, 7.2282121],
          [116.871204, 7.228043],
          [116.8712955, 7.227977],
          [116.8714701, 7.2278822],
          [116.8715242, 7.2278286],
          [116.8716323, 7.2276925],
          [116.8716447, 7.2276182],
          [116.871545, 7.2274739],
          [116.871441, 7.2273831],
          [116.8712415, 7.2272759],
          [116.8711625, 7.2272635],
          [116.8709629, 7.2272635],
          [116.8708922, 7.2272718],
          [116.8706885, 7.2272924],
          [116.8705596, 7.2273089],
          [116.8704308, 7.227313],
          [116.8703351, 7.2273006],
          [116.8700982, 7.2271769],
          [116.8700109, 7.2271192],
          [116.8698612, 7.2270161],
          [116.8697157, 7.2269295],
          [116.8695411, 7.2268222],
          [116.8694995, 7.2267645],
          [116.869433, 7.2266902],
          [116.8693789, 7.2266201],
          [116.8693748, 7.2265706],
          [116.8693748, 7.2264881],
          [116.8694496, 7.2263685],
          [116.869591, 7.2262613],
          [116.8697032, 7.2261417],
          [116.8697365, 7.2259767],
          [116.8697365, 7.2258324],
          [116.8697406, 7.2257293],
          [116.8697406, 7.2256674],
          [116.8697323, 7.2255354],
          [116.8697282, 7.2254323],
          [116.8697157, 7.2252219],
          [116.8696575, 7.22509],
          [116.8696076, 7.2249497],
          [116.8695702, 7.2247971],
          [116.8695411, 7.22476],
          [116.8694662, 7.2246858],
          [116.8694122, 7.2245909],
          [116.8693831, 7.2245084],
          [116.8693706, 7.2244548],
          [116.8693124, 7.2243063],
          [116.8692958, 7.2242115],
          [116.8692958, 7.2240712],
          [116.8692958, 7.2239021],
          [116.8693041, 7.2237207],
          [116.8693041, 7.2235722],
          [116.8693041, 7.2234979],
          [116.869275, 7.223366],
          [116.8692584, 7.2231886],
          [116.8692334, 7.2230566],
          [116.869221, 7.2229865],
          [116.8691918, 7.2228793],
          [116.8691295, 7.2226937],
          [116.8690463, 7.2225452],
          [116.8689881, 7.2224833],
          [116.8689341, 7.2224256],
          [116.868826, 7.2223472],
          [116.8687803, 7.2223101],
          [116.8686971, 7.2222276],
          [116.8685267, 7.2221616],
          [116.8683812, 7.2221039],
          [116.8683354, 7.2220503],
          [116.8682772, 7.2220049],
          [116.8682315, 7.221976],
          [116.8681774, 7.2219389],
          [116.8680818, 7.2218853],
          [116.8679696, 7.2218275],
          [116.8678864, 7.2217533],
          [116.8678407, 7.2217203],
          [116.86777, 7.2216626],
          [116.8677035, 7.2216007],
          [116.8676578, 7.2215636],
          [116.8675954, 7.2215058],
          [116.8675455, 7.2214481],
          [116.8675206, 7.221378],
          [116.8674748, 7.2213202],
          [116.8674333, 7.2212295],
          [116.8673875, 7.2211676],
          [116.867346, 7.2211058],
          [116.8672794, 7.2210068],
          [116.8672004, 7.2209078],
          [116.8671672, 7.2208707],
          [116.8671339, 7.2208336],
          [116.8670508, 7.2207593],
          [116.8669926, 7.2207098],
          [116.866951, 7.2206686],
          [116.8668762, 7.2206067],
          [116.8668055, 7.2205448],
          [116.8667057, 7.2204541],
          [116.8665519, 7.2203262],
          [116.8664313, 7.2202314],
          [116.866369, 7.220186],
          [116.8662983, 7.220153],
          [116.8662193, 7.22012],
          [116.8661445, 7.2200829],
          [116.8660696, 7.2200375],
          [116.8659532, 7.2199385],
          [116.8659116, 7.2198973],
          [116.8658576, 7.2198396],
          [116.865737, 7.21972],
          [116.8657079, 7.2196746],
          [116.8656705, 7.2196127],
          [116.8656373, 7.2195715],
          [116.8655957, 7.219522],
          [116.8655583, 7.2194807],
          [116.865525, 7.2194395],
          [116.8654668, 7.2193735],
          [116.8654294, 7.2193364],
          [116.8653712, 7.2192786],
          [116.8653088, 7.2192168],
          [116.8652548, 7.2191549],
          [116.8652257, 7.219093],
          [116.8652007, 7.2190435],
          [116.8651841, 7.2189775],
          [116.8651758, 7.2189198],
          [116.8651758, 7.2188621],
          [116.8651966, 7.2187919],
          [116.8652714, 7.2187466],
          [116.8653421, 7.2187425],
          [116.8654335, 7.2187631],
          [116.865579, 7.2187466],
          [116.8656206, 7.2186971],
          [116.8656705, 7.2185981],
          [116.8656788, 7.218528],
          [116.8656705, 7.218462],
          [116.865604, 7.2182846],
          [116.8655416, 7.2181774],
          [116.8654917, 7.2180743],
          [116.8654044, 7.2179753],
          [116.8653629, 7.2179258],
          [116.8653338, 7.2178681],
          [116.8653171, 7.2178144],
          [116.8652963, 7.2177567],
          [116.8652672, 7.2176577],
          [116.8652381, 7.2175793],
          [116.865155, 7.2174391],
          [116.8651134, 7.2173319],
          [116.8650718, 7.217237],
          [116.8650303, 7.2171545],
          [116.8649596, 7.2170102],
          [116.8649305, 7.2169153],
          [116.8648931, 7.2168328],
          [116.8648681, 7.2167916],
          [116.8648016, 7.2167091],
          [116.8647559, 7.2166513],
          [116.864681, 7.2165647],
          [116.8645896, 7.2164657],
          [116.8645646, 7.2163626],
          [116.8645605, 7.2162678],
          [116.8645605, 7.2161482],
          [116.8645605, 7.2160739],
          [116.8645605, 7.2160327],
          [116.8645522, 7.2159667],
          [116.864548, 7.2159213],
          [116.8645231, 7.2158677],
          [116.8644399, 7.2158347],
          [116.8643734, 7.2158759],
          [116.8642196, 7.2158471],
          [116.8641946, 7.2158017],
          [116.8641115, 7.2157563],
          [116.864045, 7.2157233],
          [116.8639743, 7.2156821],
          [116.8638994, 7.2156243],
          [116.8638496, 7.2155831],
          [116.8637872, 7.2155377],
          [116.8637415, 7.2154965],
          [116.86365, 7.215447],
          [116.8636084, 7.2154305],
          [116.8635211, 7.2153934],
          [116.8634338, 7.2153521],
          [116.8633922, 7.2153274],
          [116.8633673, 7.2152861],
          [116.8632426, 7.2152078],
          [116.8632093, 7.2151212],
          [116.8632093, 7.2150799],
          [116.8632093, 7.214985],
          [116.8632093, 7.2149273],
          [116.8632259, 7.2148861],
          [116.8633382, 7.2147293],
          [116.8634255, 7.214651],
          [116.8634546, 7.2145643],
          [116.8635087, 7.2145025],
          [116.8635502, 7.2144324],
          [116.8635585, 7.2143829],
          [116.8635294, 7.2143045],
          [116.8634671, 7.214255],
          [116.8633964, 7.214255],
          [116.863334, 7.214255],
          [116.8632883, 7.2142633],
          [116.8632301, 7.2143045],
          [116.8631844, 7.2143416],
          [116.863147, 7.2143994],
          [116.8630929, 7.2144984],
          [116.8630638, 7.2145396],
          [116.8630389, 7.214618],
          [116.8630014, 7.214684],
          [116.8629432, 7.2146922],
          [116.8628809, 7.2146633],
          [116.8627936, 7.2146221],
          [116.8626481, 7.2145726],
          [116.8625815, 7.2145437],
          [116.8624735, 7.214486],
          [116.8624111, 7.2144447],
          [116.8623695, 7.2143911],
          [116.8623654, 7.2143292],
          [116.8623945, 7.2142055],
          [116.8624402, 7.2140694],
          [116.8624942, 7.2139869],
          [116.8625441, 7.2139003],
          [116.8625732, 7.2138467],
          [116.8626065, 7.2137931],
          [116.8627021, 7.2136941],
          [116.8627853, 7.2136446],
          [116.8628809, 7.2135786],
          [116.8629225, 7.2135497],
          [116.8629765, 7.2135332],
          [116.8631677, 7.2135043],
          [116.8632883, 7.2134961],
          [116.8633756, 7.2135002],
          [116.8634754, 7.2135167],
          [116.8636043, 7.2135538],
          [116.8636791, 7.2135703],
          [116.8637623, 7.2135951],
          [116.8638371, 7.2136157],
          [116.8639577, 7.2136281],
          [116.864099, 7.2136569],
          [116.8642528, 7.2136734],
          [116.8643609, 7.2136569],
          [116.8645272, 7.213624],
          [116.8646104, 7.2135745],
          [116.8647476, 7.2134672],
          [116.8648141, 7.2134301],
          [116.8649596, 7.2134054],
          [116.8650635, 7.2134095],
          [116.8651675, 7.2134012],
          [116.865361074840735, 7.213323354721069],
          [116.8654751, 7.2132775],
          [116.8655624, 7.2132486],
          [116.8656373, 7.2132362],
          [116.8657453, 7.2132074],
          [116.8658368, 7.2131538],
          [116.8659283, 7.2130919],
          [116.8661361, 7.2129599],
          [116.8662775, 7.2129475],
          [116.8663523, 7.2130176],
          [116.8664188, 7.2131084],
          [116.8665269, 7.2132074],
          [116.8666018, 7.2132362],
          [116.8667556, 7.2131538],
          [116.8669094, 7.2131703],
          [116.8669801, 7.2131703],
          [116.8670258, 7.2132198],
          [116.8670716, 7.2133229],
          [116.8672503, 7.2132857],
          [116.8673169, 7.2132404],
          [116.8673709, 7.2132033],
          [116.8674831, 7.2131991],
          [116.867533, 7.2132445],
          [116.8675954, 7.2132569],
          [116.8677326, 7.2132074],
          [116.8678407, 7.2131868],
          [116.8679072, 7.2133105],
          [116.867914224650605, 7.213323354721069],
          [116.8679613, 7.2134095],
          [116.8680361, 7.2135167],
          [116.8680569, 7.2135827],
          [116.8680943, 7.2137229],
          [116.8681442, 7.2138384],
          [116.8682481, 7.2140529],
          [116.868298, 7.2141148],
          [116.8684103, 7.2142509],
          [116.8684851, 7.2143334],
          [116.8685433, 7.2143251],
          [116.8686098, 7.2142468],
          [116.8687262, 7.2141601],
          [116.8688218, 7.2141354],
          [116.8689549, 7.2141065],
          [116.8690339, 7.2140777],
          [116.8691129, 7.2140323],
          [116.8692043, 7.2139828],
          [116.8693041, 7.2137807],
          [116.8693498, 7.2136899],
          [116.8695037, 7.2135868],
          [116.8696242, 7.2135332],
          [116.8698404, 7.2135002],
          [116.8699693, 7.2135043],
          [116.8701065, 7.2135703],
          [116.8702437, 7.2136363],
          [116.8703559, 7.2136982],
          [116.8704474, 7.2137477],
          [116.8706012, 7.2138426],
          [116.8706386, 7.2138755],
          [116.8707592, 7.2139539],
          [116.8710086, 7.2140777],
          [116.8711126, 7.2141065],
          [116.8712165, 7.2141395],
          [116.8713662, 7.2141601],
          [116.8715907, 7.2141436],
          [116.8717404, 7.214255],
          [116.8718193, 7.2143292],
          [116.8719607, 7.2144035],
          [116.8721228, 7.2144654],
          [116.8722185, 7.2144901],
          [116.872339, 7.2145066],
          [116.8725427, 7.2145107],
          [116.8727173, 7.2145396],
          [116.8728462, 7.2145313],
          [116.8729834, 7.2144117],
          [116.873158, 7.2143705],
          [116.8732412, 7.214354],
          [116.8733576, 7.2142468],
          [116.8733908, 7.214189],
          [116.8734241, 7.2140859],
          [116.8735031, 7.213991],
          [116.8736652, 7.213925],
          [116.8738399, 7.2138879],
          [116.8739272, 7.2138302],
          [116.873977, 7.2137312],
          [116.8740311, 7.2135951],
          [116.8741184, 7.2134136],
          [116.874183686205512, 7.213323354721069],
          [116.8742348, 7.2132527],
          [116.8743554, 7.213162],
          [116.874421119689941, 7.213139444953777],
          [116.8744635, 7.2131249],
          [116.874663, 7.2131455],
          [116.8747919, 7.2131909],
          [116.8749249, 7.2132115],
          [116.8750829, 7.2132734],
          [116.8752451, 7.2133435],
          [116.8753532, 7.2134177],
          [116.8754238, 7.2135002],
          [116.8754903, 7.2136487],
          [116.875586, 7.2137023],
          [116.8756899, 7.2137229],
          [116.8758229, 7.2137188],
          [116.8759144, 7.2137436],
          [116.8760142, 7.2138219],
          [116.8761223, 7.213925],
          [116.8762013, 7.2139993],
          [116.8762719, 7.2140859],
          [116.8763676, 7.2141973],
          [116.8764632, 7.2143045],
          [116.8765089, 7.2144324],
          [116.8764341, 7.2145891],
          [116.8764632, 7.2147211],
          [116.8765339, 7.2148324],
          [116.8766586, 7.2149438],
          [116.8768041, 7.2150593],
          [116.8769413, 7.2150923],
          [116.8771367, 7.215117],
          [116.877199, 7.2151212],
          [116.8773778, 7.2151748],
          [116.8774485, 7.2151995],
          [116.8775483, 7.2152531],
          [116.8776148, 7.2152861],
          [116.8777728, 7.2153604],
          [116.8779599, 7.2154511],
          [116.8780763, 7.2155254],
          [116.878255, 7.215645],
          [116.8783756, 7.2157027],
          [116.8785003, 7.2157728],
          [116.8786541, 7.2158388],
          [116.8789119, 7.2159584],
          [116.879099, 7.2160574],
          [116.8792071, 7.2161193],
          [116.8793277, 7.2161976],
          [116.8794233, 7.2162306],
          [116.8795272, 7.2162678],
          [116.87976, 7.2163626],
          [116.8799762, 7.2164369],
          [116.8801217, 7.2164699],
          [116.880313, 7.2165524],
          [116.8804003, 7.2165895],
          [116.8805499, 7.2166348],
          [116.8806206, 7.216672],
          [116.8806663, 7.2166967],
          [116.8808493, 7.2168081],
          [116.880974, 7.2169277],
          [116.881107, 7.2170555],
          [116.8812151, 7.2171834],
          [116.8812525, 7.2172329],
          [116.8812983, 7.2172989],
          [116.881398, 7.2174597],
          [116.8814937, 7.2176577],
          [116.8815186, 7.2178186],
          [116.8815103, 7.2178763],
          [116.8814895, 7.2179753],
          [116.8814521, 7.2181362],
          [116.8814438, 7.218264],
          [116.8814521, 7.2183754],
          [116.8814687, 7.2184537],
          [116.8814854, 7.2185115],
          [116.8814937, 7.2187218],
          [116.8814563, 7.2187548],
          [116.8814242, 7.218804],
          [116.8814657, 7.2188414],
          [116.8814808, 7.2190321],
          [116.8814921, 7.2192191],
          [116.8814959, 7.2194659],
          [116.8815185, 7.2196006],
          [116.8815637, 7.2196604],
          [116.8816617, 7.2197576],
          [116.881707, 7.2198773],
          [116.8817899, 7.2200681],
          [116.881854, 7.2201802],
          [116.8819709, 7.2203336],
          [116.8820425, 7.2204159],
          [116.882148, 7.2205281],
          [116.8822762, 7.2205916],
          [116.8823629, 7.220629],
          [116.8824873, 7.2207076],
          [116.8825665, 7.2207861],
          [116.8827022, 7.2208198],
          [116.882672, 7.2208534],
          [116.8825212, 7.2208572],
          [116.8823968, 7.2208534],
          [116.8822385, 7.2208721],
          [116.8821141, 7.2208796],
          [116.8820085, 7.220917],
          [116.881903, 7.2209582],
          [116.8818314, 7.221018],
          [116.8817786, 7.2210666],
          [116.8817484, 7.2211863],
          [116.8817522, 7.2212274],
          [116.8817673, 7.2212835],
          [116.8818238, 7.2213583],
          [116.8819294, 7.2214369],
          [116.8820236, 7.2215042],
          [116.8821593, 7.2215902],
          [116.8823554, 7.2216426],
          [116.8825212, 7.2216463],
          [116.8826381, 7.2216201],
          [116.8827474, 7.2215977],
          [116.8828945, 7.2216164],
          [116.8830076, 7.2216201],
          [116.8831169, 7.2215902],
          [116.883181, 7.2216276],
          [116.8831546, 7.2217099],
          [116.8832036, 7.2218408],
          [116.8832564, 7.2219156],
          [116.8833506, 7.221938],
          [116.8834373, 7.2219866],
          [116.8834825, 7.2220839],
          [116.883524, 7.2221998],
          [116.8836107, 7.2223457],
          [116.883705, 7.2224392],
          [116.8837577, 7.2224691],
          [116.8838972, 7.2225925],
          [116.8839425, 7.2226561],
          [116.8840179, 7.2226748],
          [116.8841272, 7.2226748],
          [116.8842629, 7.2227458],
          [116.8844212, 7.2227683],
          [116.8844024, 7.2228805],
          [116.8843986, 7.2229889],
          [116.8844589, 7.2230712],
          [116.884523, 7.223131],
          [116.8846437, 7.223131],
          [116.8847454, 7.2231086],
          [116.8848661, 7.2231348],
          [116.8849528, 7.2231759],
          [116.8851073, 7.2232732],
          [116.8852468, 7.2232881],
          [116.8853637, 7.2232171],
          [116.8854919, 7.223161],
          [116.8855974, 7.2231011],
          [116.8856879, 7.222959],
          [116.8858425, 7.2228318],
          [116.8859405, 7.2227907],
          [116.8860988, 7.2227084],
          [116.886144, 7.2227197],
          [116.8863099, 7.222701],
          [116.8864079, 7.2226449],
          [116.886472, 7.2225551],
          [116.8865436, 7.2224167],
          [116.8865851, 7.2223457],
          [116.8866228, 7.2221699],
          [116.8866756, 7.2220726],
          [116.8867095, 7.2220203],
          [116.8867548, 7.2219193],
          [116.8868792, 7.2218221],
          [116.8869734, 7.2217735],
          [116.8871091, 7.2216987],
          [116.8871958, 7.2216126],
          [116.8871883, 7.2215117],
          [116.8871581, 7.2214107],
          [116.8871167, 7.2212723],
          [116.887079, 7.2212012],
          [116.8870526, 7.2210928],
          [116.8871197, 7.2208312],
          [116.8870896, 7.2207994],
          [116.8870443, 7.2207265],
          [116.8870481, 7.2206909],
          [116.8869935, 7.2206685],
          [116.8869558, 7.2206965],
          [116.8869049, 7.2206947],
          [116.8868879, 7.2206274],
          [116.8869218, 7.22059],
          [116.8869765, 7.2205339],
          [116.8869897, 7.2204609],
          [116.8869444, 7.2203955],
          [116.8868841, 7.2203506],
          [116.8868653, 7.2203039],
          [116.8868313, 7.2201748],
          [116.8868238, 7.2201075],
          [116.88682, 7.2200084],
          [116.8867484, 7.2198775],
          [116.8867164, 7.2198233],
          [116.88669, 7.2197447],
          [116.8866824, 7.2196662],
          [116.8866919, 7.2195166],
          [116.8866447, 7.2193689],
          [116.8865957, 7.2192772],
          [116.8865392, 7.2191987],
          [116.8865147, 7.2191015],
          [116.886509, 7.2190211],
          [116.8864996, 7.2188789],
          [116.886494, 7.2188135],
          [116.8864336, 7.2187911],
          [116.8864129, 7.2187518],
          [116.8863865, 7.2186882],
          [116.8863695, 7.2186452],
          [116.886362, 7.2185797],
          [116.8863564, 7.2185442],
          [116.8863318, 7.21849],
          [116.8863281, 7.2184283],
          [116.88633, 7.2183778],
          [116.8863394, 7.2182787],
          [116.8863394, 7.2182506],
          [116.8863243, 7.2182301],
          [116.8863092, 7.2181908],
          [116.8863432, 7.2181796],
          [116.8864355, 7.2181534],
          [116.8864732, 7.218131],
          [116.8864958, 7.218101],
          [116.8865562, 7.2180393],
          [116.8865731, 7.218015],
          [116.8866674, 7.2179365],
          [116.8867183, 7.2178841],
          [116.8867258, 7.2178411],
          [116.8866938, 7.2178336],
          [116.8866429, 7.2178187],
          [116.8866353, 7.2177887],
          [116.886673, 7.2177495],
          [116.8867805, 7.217684],
          [116.8868125, 7.217684],
          [116.8869218, 7.217742],
          [116.887018, 7.2177794],
          [116.88705, 7.2178],
          [116.8870915, 7.2178187],
          [116.8871838, 7.2178467],
          [116.887263, 7.2178598],
          [116.8873252, 7.2178766],
          [116.8873704, 7.2178935],
          [116.8874194, 7.2179253],
          [116.8874515, 7.2179477],
          [116.8875061, 7.2179458],
          [116.8874779, 7.2179253],
          [116.88741, 7.2178935],
          [116.8873591, 7.2178673],
          [116.8873761, 7.2178467],
          [116.8873365, 7.2178187],
          [116.8873139, 7.2178],
          [116.8872781, 7.2177644],
          [116.8872234, 7.2177364],
          [116.8871895, 7.2177345],
          [116.8871216, 7.2177177],
          [116.8870029, 7.2176784],
          [116.8869671, 7.2176653],
          [116.8869256, 7.217641],
          [116.8868351, 7.2176092],
          [116.8868257, 7.2175737],
          [116.8868483, 7.2175438],
          [116.8868841, 7.217512],
          [116.8868653, 7.217454],
          [116.8868464, 7.2174054],
          [116.8868408, 7.217368],
          [116.8868351, 7.2173138],
          [116.8868295, 7.2172876],
          [116.8867974, 7.217282],
          [116.8867691, 7.2173063],
          [116.8867164, 7.2173194],
          [116.886673, 7.2173362],
          [116.8866692, 7.21731],
          [116.8866749, 7.2172595],
          [116.8866655, 7.2171978],
          [116.8866485, 7.2171885],
          [116.8866447, 7.2171567],
          [116.88669, 7.2171399],
          [116.8867258, 7.2171305],
          [116.8867842, 7.217123],
          [116.8868389, 7.2171249],
          [116.8868841, 7.217138],
          [116.886952, 7.2171343],
          [116.8869878, 7.2170875],
          [116.8869859, 7.2170314],
          [116.8869727, 7.217009],
          [116.8869821, 7.216979],
          [116.8870387, 7.2169828],
          [116.8870915, 7.2169529],
          [116.8871329, 7.2169416],
          [116.8872064, 7.2169379],
          [116.8872498, 7.2169155],
          [116.8872913, 7.2169136],
          [116.8873459, 7.2169099],
          [116.8873799, 7.2169061],
          [116.8874044, 7.2169099],
          [116.8874609, 7.2169173],
          [116.8875118, 7.2169211],
          [116.8875665, 7.2169248],
          [116.8876211, 7.2169229],
          [116.8877078, 7.2168968],
          [116.8877267, 7.2168855],
          [116.8878153, 7.2168014],
          [116.8878511, 7.2167827],
          [116.8878718, 7.216764],
          [116.8879133, 7.2166948],
          [116.8878944, 7.2166724],
          [116.8878756, 7.2166238],
          [116.8878794, 7.2165751],
          [116.8878756, 7.216519],
          [116.8878718, 7.216476],
          [116.8879698, 7.2164891],
          [116.8880264, 7.2164667],
          [116.8880603, 7.2164143],
          [116.8881169, 7.2163713],
          [116.8881772, 7.2163339],
          [116.8882469, 7.2162965],
          [116.8882922, 7.216304],
          [116.8883789, 7.2163152],
          [116.8884675, 7.2162853],
          [116.8884844, 7.2162404],
          [116.8884976, 7.2162049],
          [116.8885636, 7.2161787],
          [116.8886088, 7.2161581],
          [116.8886691, 7.2160964],
          [116.8887049, 7.2160328],
          [116.8887464, 7.2159936],
          [116.8888444, 7.2159393],
          [116.8889161, 7.2158832],
          [116.8889933, 7.2157673],
          [116.889016, 7.2156981],
          [116.8890895, 7.2156757],
          [116.8892478, 7.2156645],
          [116.8893722, 7.215657],
          [116.8895079, 7.2156458],
          [116.8895664, 7.2155878],
          [116.889572, 7.2155074],
          [116.8895909, 7.2154887],
          [116.8896776, 7.2155074],
          [116.8897297, 7.2155226],
          [116.8898524, 7.2155596],
          [116.8900176, 7.2155755],
          [116.8901296, 7.2155755],
          [116.8902682, 7.2155755],
          [116.8904441, 7.2155438],
          [116.8905828, 7.2155279],
          [116.8906787, 7.2155067],
          [116.8908013, 7.2154644],
          [116.8909506, 7.2154062],
          [116.8910892, 7.2153428],
          [116.8912172, 7.2152581],
          [116.8912972, 7.2152158],
          [116.8914731, 7.2152053],
          [116.8915797, 7.2150783],
          [116.8916543, 7.2149408],
          [116.891697, 7.2148667],
          [116.8917556, 7.2148086],
          [116.8918676, 7.2147504],
          [116.8919422, 7.2146816],
          [116.8919796, 7.2146076],
          [116.8920169, 7.21456],
          [116.8921555, 7.2145282],
          [116.8922408, 7.2145018],
          [116.8923368, 7.2143907],
          [116.8923847, 7.2143114],
          [116.892518, 7.2142268],
          [116.8926247, 7.2141686],
          [116.8927526, 7.2141051],
          [116.8928965, 7.2140364],
          [116.8929125, 7.2139412],
          [116.8929445, 7.2138883],
          [116.8930672, 7.2137931],
          [116.8931471, 7.2137719],
          [116.8932804, 7.2138036],
          [116.8933284, 7.2138565],
          [116.8934563, 7.2138671],
          [116.8936216, 7.2138248],
          [116.8938349, 7.2137983],
          [116.8939362, 7.2137455],
          [116.8939575, 7.2136503],
          [116.8938615, 7.2136555],
          [116.8938295, 7.2136079],
          [116.8939895, 7.2135339],
          [116.8941014, 7.2135868],
          [116.8942347, 7.2135603],
          [116.8943307, 7.2135022],
          [116.8944586, 7.2134017],
          [116.8946346, 7.2133012],
          [116.8947892, 7.2133012],
          [116.8948851, 7.213206],
          [116.8950664, 7.2130103],
          [116.8951624, 7.2129045],
          [116.8952317, 7.2128093],
          [116.8952797, 7.2126453],
          [116.8953063, 7.2125184],
          [116.895349, 7.2123861],
          [116.8953756, 7.2122751],
          [116.8954396, 7.2121693],
          [116.8954982, 7.2120106],
          [116.8955462, 7.2118308],
          [116.8955995, 7.211651],
          [116.8956209, 7.2115029],
          [116.8955995, 7.2114024],
          [116.8955142, 7.211286],
          [116.8955142, 7.2112067],
          [116.8954982, 7.2110374],
          [116.8955142, 7.210884],
          [116.8954609, 7.2108047],
          [116.8953596, 7.2107412],
          [116.8952797, 7.2105508],
          [116.8952157, 7.2104027],
          [116.895157, 7.2103022],
          [116.8950824, 7.2101277],
          [116.8950397, 7.2099584],
          [116.8949864, 7.2098738],
          [116.8948531, 7.2097627],
          [116.8947252, 7.2096781],
          [116.8945226, 7.2095776],
          [116.8943147, 7.209456],
          [116.8941174, 7.2093449],
          [116.8939628, 7.2092285],
          [116.8937016, 7.2090222],
          [116.8935576, 7.2088318],
          [116.893403, 7.2086943],
          [116.8931791, 7.2086361],
          [116.8930298, 7.2085674],
          [116.8928379, 7.2085991],
          [116.8927473, 7.2086943],
          [116.89263, 7.2087313],
          [116.892518, 7.208726],
          [116.8923847, 7.208726],
          [116.8922408, 7.2087208],
          [116.8921235, 7.2086361],
          [116.8920329, 7.2086308],
          [116.8918623, 7.2086097],
          [116.8916617, 7.2085095],
          [116.8915791, 7.2084513],
          [116.8915285, 7.2083719],
          [116.8914538, 7.2083481],
          [116.8913365, 7.2083164],
          [116.8912059, 7.2082371],
          [116.8910087, 7.2081048],
          [116.890958, 7.2080837],
          [116.8908967, 7.2080546],
          [116.8907954, 7.208044],
          [116.8907368, 7.2080916],
          [116.8906808, 7.2081313],
          [116.8906088, 7.2081154],
          [116.8905102, 7.2080705],
          [116.8904595, 7.2080387],
          [116.8904169, 7.2079832],
          [116.8903662, 7.2079488],
          [116.8902943, 7.207925],
          [116.8901636, 7.2079065],
          [116.890097, 7.2078774],
          [116.8899744, 7.2078377],
          [116.8899157, 7.2078007],
          [116.8898304, 7.2077478],
          [116.8897451, 7.2077531],
          [116.8897238, 7.207769],
          [116.8895532, 7.2076896],
          [116.8895159, 7.2076738],
          [116.8893853, 7.2075865],
          [116.8893053, 7.2075257],
          [116.8891987, 7.2074675],
          [116.8891, 7.2074199],
          [116.8890467, 7.2073802],
          [116.8889508, 7.2073511],
          [116.8889028, 7.2073458],
          [116.8888015, 7.2073511],
          [116.8887215, 7.2073696],
          [116.8886415, 7.2073882],
          [116.8885349, 7.207404],
          [116.8885003, 7.2073908],
          [116.888359, 7.2073115],
          [116.888295, 7.207285],
          [116.8881617, 7.2072612],
          [116.8881004, 7.2072612],
          [116.8879991, 7.207248],
          [116.8879085, 7.2072559],
          [116.8878578, 7.2072427],
          [116.8877326, 7.2071713],
          [116.8876339, 7.2071475],
          [116.8875699, 7.2071078],
          [116.8874767, 7.2070153],
          [116.88737, 7.2068354],
          [116.887346, 7.2067905],
          [116.8873567, 7.2067138],
          [116.8873567, 7.206645],
          [116.8872549, 7.2066594],
          [116.8872179, 7.2066296],
          [116.8871601, 7.2065884],
          [116.8870215, 7.2065448],
          [116.8868852, 7.2066067],
          [116.8868875, 7.2066411],
          [116.886936, 7.2067396],
          [116.887003, 7.2068222],
          [116.8870654, 7.2069184],
          [116.8870908, 7.2069734],
          [116.8871578, 7.207111],
          [116.8871509, 7.2071775],
          [116.8870261, 7.2071683],
          [116.8869868, 7.2071477],
          [116.8868852, 7.2071522],
          [116.8868136, 7.2071545],
          [116.886765, 7.2071431],
          [116.8866819, 7.2071293],
          [116.8866149, 7.2071247],
          [116.8865502, 7.2071247],
          [116.8864554, 7.2071752],
          [116.8863746, 7.2071958],
          [116.8862544, 7.2071568],
          [116.8861112, 7.2071247],
          [116.885961, 7.207127],
          [116.8858247, 7.2071201],
          [116.8857277, 7.2071499],
          [116.8856445, 7.2072095],
          [116.8855244, 7.2072325],
          [116.8854135, 7.2072302],
          [116.885224, 7.2072302],
          [116.8850738, 7.207221],
          [116.8849722, 7.2072027],
          [116.8848405, 7.2071431],
          [116.8847481, 7.2070858],
          [116.8846348, 7.2070101],
          [116.8844867, 7.2068805],
          [116.8844128, 7.20683],
          [116.8842742, 7.206775],
          [116.8842002, 7.2067063],
          [116.8840477, 7.2066146],
          [116.883886, 7.2065229],
          [116.8837566, 7.2064954],
          [116.883618, 7.2064679],
          [116.8834979, 7.2063991],
          [116.8834147, 7.2063533],
          [116.88335, 7.2062249],
          [116.8833777, 7.2061332],
          [116.8833962, 7.2060324],
          [116.8832622, 7.2059453],
          [116.8829896, 7.2058902],
          [116.8828371, 7.2057986],
          [116.8827585, 7.2057206],
          [116.8826291, 7.205606],
          [116.8824951, 7.2055235],
          [116.8823149, 7.2054777],
          [116.8822087, 7.2054639],
          [116.8820608, 7.2054547],
          [116.8817835, 7.2055235],
          [116.8817281, 7.2056427],
          [116.8816172, 7.2057069],
          [116.8814739, 7.2057115],
          [116.8814139, 7.2056335],
          [116.881363, 7.2055006],
          [116.8813122, 7.2054318],
          [116.881229, 7.2053814],
          [116.881035, 7.2053493],
          [116.8808963, 7.2053035],
          [116.8807947, 7.2052622],
          [116.8806468, 7.2052347],
          [116.8804805, 7.2052255],
          [116.8803465, 7.2052163],
          [116.880157, 7.2052163],
          [116.8799676, 7.2052209],
          [116.8798567, 7.205253],
          [116.8797642, 7.205331],
          [116.8797273, 7.2054135],
          [116.8798197, 7.2055235],
          [116.8799121, 7.2055281],
          [116.8799907, 7.2054731],
          [116.8800877, 7.2054501],
          [116.8802956, 7.2055968],
          [116.8804111, 7.2057481],
          [116.8804851, 7.205849],
          [116.8804989, 7.2059728],
          [116.8804989, 7.2060828],
          [116.880462, 7.2061882],
          [116.8803603, 7.2062295],
          [116.8801893, 7.2061791],
          [116.8800415, 7.2060553],
          [116.8799768, 7.2059728],
          [116.8798474, 7.2059544],
          [116.8796949, 7.2060324],
          [116.8795655, 7.2061332],
          [116.8793761, 7.2062937],
          [116.8793114, 7.2063624],
          [116.8791358, 7.2064495],
          [116.8787708, 7.2063945],
          [116.8786645, 7.2063533],
          [116.8784704, 7.2062982],
          [116.8782486, 7.2062799],
          [116.8782116, 7.2061057],
          [116.8782394, 7.2059728],
          [116.8783041, 7.2058811],
          [116.8783456, 7.2058261],
          [116.8783965, 7.2057573],
          [116.8784242, 7.2056977],
          [116.8784658, 7.2055648],
          [116.8784843, 7.205386],
          [116.878415, 7.2053172],
          [116.8783318, 7.2052622],
          [116.8782532, 7.2052072],
          [116.8781654, 7.2050651],
          [116.8781562, 7.204955],
          [116.8781978, 7.2048588],
          [116.8783133, 7.2047671],
          [116.8785259, 7.2047167],
          [116.8786691, 7.2046983],
          [116.8788724, 7.2046571],
          [116.8790896, 7.2046616],
          [116.879279, 7.2046754],
          [116.879487, 7.2046387],
          [116.8796487, 7.20457],
          [116.8798982, 7.2044691],
          [116.8800923, 7.2044003],
          [116.8801524, 7.2043499],
          [116.880254, 7.2042491],
          [116.8803187, 7.2041849],
          [116.880388, 7.2041207],
          [116.8805128, 7.2040473],
          [116.8806792, 7.2039786],
          [116.8807438, 7.2039877],
          [116.8807993, 7.2040886],
          [116.8808501, 7.2041344],
          [116.8809518, 7.204084],
          [116.8811366, 7.2039465],
          [116.8812891, 7.2039052],
          [116.8814647, 7.2039648],
          [116.881534, 7.2040473],
          [116.8815433, 7.2041024],
          [116.881631, 7.2041528],
          [116.8816726, 7.2040978],
          [116.8817096, 7.204029],
          [116.8818066, 7.2040153],
          [116.8819869, 7.2040473],
          [116.8822133, 7.2040153],
          [116.8824073, 7.2039694],
          [116.8826014, 7.2038961],
          [116.8827493, 7.2038548],
          [116.8828879, 7.203841],
          [116.8830081, 7.203841],
          [116.8831328, 7.2038365],
          [116.8832067, 7.2038365],
          [116.8834517, 7.2038731],
          [116.8837797, 7.203919],
          [116.8839137, 7.2039511],
          [116.8840616, 7.2039877],
          [116.8841864, 7.2040382],
          [116.8844313, 7.2041482],
          [116.884593, 7.2041757],
          [116.8847501, 7.2041665],
          [116.8848749, 7.2041619],
          [116.8849349, 7.2041619],
          [116.8849719, 7.2041986],
          [116.8849719, 7.2043178],
          [116.884958, 7.2044141],
          [116.8850181, 7.2045058],
          [116.8850689, 7.2045654],
          [116.8850874, 7.204625],
          [116.8851013, 7.2047029],
          [116.8851752, 7.2048038],
          [116.8852445, 7.2048542],
          [116.8852907, 7.2048404],
          [116.8853, 7.2047717],
          [116.8853046, 7.2047167],
          [116.8853389, 7.2046301],
          [116.8852909, 7.2046169],
          [116.8852536, 7.2045561],
          [116.8852429, 7.2044873],
          [116.8852429, 7.2044291],
          [116.8852696, 7.2043445],
          [116.8852989, 7.2042757],
          [116.8853122, 7.2042414],
          [116.8853522, 7.2041885],
          [116.8854002, 7.20417],
          [116.8854535, 7.2041488],
          [116.8855761, 7.2041065],
          [116.8856881, 7.2040853],
          [116.8857707, 7.2040695],
          [116.885848, 7.2040377],
          [116.8859226, 7.204006],
          [116.8860266, 7.2039452],
          [116.8860639, 7.2039214],
          [116.8861066, 7.2039187],
          [116.8861945, 7.2038791],
          [116.8862532, 7.2038341],
          [116.8862852, 7.203805],
          [116.8863465, 7.2037521],
          [116.8864051, 7.2036992],
          [116.8864398, 7.2036622],
          [116.8865118, 7.2036119],
          [116.8865437, 7.2035749],
          [116.8865784, 7.2035141],
          [116.8866344, 7.2034586],
          [116.8866877, 7.2034057],
          [116.8867517, 7.2033422],
          [116.8868236, 7.2032787],
          [116.8868796, 7.2031915],
          [116.8869223, 7.2031544],
          [116.8869862, 7.2030989],
          [116.8870182, 7.2030592],
          [116.8870636, 7.203001],
          [116.8871409, 7.2029349],
          [116.8871728, 7.2028582],
          [116.8872102, 7.2028106],
          [116.8872715, 7.2027339],
          [116.8873221, 7.202689],
          [116.8873648, 7.2026281],
          [116.8874367, 7.2025515],
          [116.8874794, 7.2025329],
          [116.8875594, 7.2025277],
          [116.8875834, 7.2025488],
          [116.8875887, 7.2025911],
          [116.88761, 7.2026572],
          [116.8876393, 7.2026916],
          [116.887674, 7.2027286],
          [116.8877113, 7.2027657],
          [116.8877779, 7.2028027],
          [116.8878473, 7.2028371],
          [116.8879139, 7.2028556],
          [116.8879832, 7.2028529],
          [116.8881298, 7.2027842],
          [116.8881965, 7.202681],
          [116.8881991, 7.2026176],
          [116.8881965, 7.202525],
          [116.8882151, 7.2024774],
          [116.8882284, 7.2024324],
          [116.8882471, 7.2023901],
          [116.8882711, 7.2023452],
          [116.8882978, 7.2023055],
          [116.8883511, 7.2022579],
          [116.888415, 7.2022235],
          [116.8885057, 7.2021865],
          [116.8885243, 7.2021415],
          [116.8885457, 7.2020807],
          [116.888559, 7.2020146],
          [116.8885697, 7.2019432],
          [116.888599, 7.2018824],
          [116.8886336, 7.2018242],
          [116.8886763, 7.2017845],
          [116.8887429, 7.2017263],
          [116.8887962, 7.2016972],
          [116.8888229, 7.2016602],
          [116.8888522, 7.2016047],
          [116.8888949, 7.2015491],
          [116.8889348, 7.2015015],
          [116.8889988, 7.2014248],
          [116.8890388, 7.2013693],
          [116.8890841, 7.2013085],
          [116.8891801, 7.2012159],
          [116.8892147, 7.2011736],
          [116.8892521, 7.2011207],
          [116.8893107, 7.2010731],
          [116.889348, 7.2010387],
          [116.88938, 7.2009805],
          [116.889444, 7.2009382],
          [116.889468, 7.2009197],
          [116.8894733, 7.200888],
          [116.8894973, 7.2008245],
          [116.8895106, 7.2007637],
          [116.8894893, 7.2007293],
          [116.8894253, 7.2007478],
          [116.8893907, 7.2007901],
          [116.8893374, 7.2008298],
          [116.8893, 7.2008562],
          [116.8892494, 7.2008986],
          [116.8892227, 7.2008668],
          [116.8892361, 7.2008166],
          [116.8892094, 7.2007822],
          [116.8891294, 7.2008245],
          [116.8890921, 7.2008589],
          [116.8890761, 7.2009065],
          [116.8890175, 7.2009647],
          [116.8889535, 7.2010281],
          [116.8889029, 7.2010546],
          [116.8888469, 7.2010943],
          [116.8887989, 7.2011339],
          [116.8887323, 7.2011974],
          [116.8887029, 7.2012397],
          [116.8886576, 7.2012953],
          [116.8885803, 7.2013825],
          [116.8884817, 7.2014645],
          [116.8883804, 7.2015306],
          [116.8882951, 7.2015809],
          [116.8881831, 7.2016311],
          [116.8879805, 7.2017184],
          [116.8878179, 7.2017872],
          [116.8876553, 7.2018903],
          [116.8874501, 7.2019749],
          [116.8873594, 7.202004],
          [116.8872688, 7.2020358],
          [116.8872048, 7.2020463],
          [116.8871142, 7.2020543],
          [116.8870209, 7.2020675],
          [116.8869143, 7.2020675],
          [116.8868076, 7.2020622],
          [116.8867197, 7.2020569],
          [116.8866157, 7.2020384],
          [116.8865198, 7.202049],
          [116.8863918, 7.2020939],
          [116.8863305, 7.2021204],
          [116.8862958, 7.2021124],
          [116.8862212, 7.2020966],
          [116.8861839, 7.2021072],
          [116.8861492, 7.2021733],
          [116.8860906, 7.2022129],
          [116.8860426, 7.2022103],
          [116.88596, 7.2021918],
          [116.8859067, 7.2022235],
          [116.885896, 7.2023002],
          [116.8858773, 7.2023372],
          [116.8857574, 7.2023928],
          [116.8856747, 7.2023981],
          [116.8855894, 7.2023875],
          [116.8855121, 7.2023505],
          [116.8854482, 7.2023769],
          [116.8854508, 7.2024139],
          [116.8854642, 7.2024562],
          [116.8853389, 7.2025409],
          [116.8852136, 7.2026202],
          [116.8851976, 7.2026625],
          [116.8849897, 7.2026758],
          [116.884867, 7.2026731],
          [116.8847711, 7.2026943],
          [116.8847071, 7.2027392],
          [116.8846218, 7.202771],
          [116.8844592, 7.2027948],
          [116.8843952, 7.2028344],
          [116.8843126, 7.2029058],
          [116.8842993, 7.2029614],
          [116.884206, 7.2029825],
          [116.8840567, 7.2030407],
          [116.883811, 7.2031429],
          [116.8836324, 7.203135],
          [116.8834485, 7.2031429],
          [116.8833072, 7.2031059],
          [116.8831632, 7.2030689],
          [116.8830273, 7.203053],
          [116.882854, 7.2030266],
          [116.8827794, 7.2030107],
          [116.8825794, 7.202971],
          [116.8823102, 7.2029367],
          [116.882057, 7.2028943],
          [116.881929, 7.2028441],
          [116.8817531, 7.2028071],
          [116.8816411, 7.2027833],
          [116.8815185, 7.2027674],
          [116.8814385, 7.2027674],
          [116.8812759, 7.2027436],
          [116.8810867, 7.2027383],
          [116.880884, 7.2026824],
          [116.8806616, 7.2026449],
          [116.8803261, 7.2026375],
          [116.8799378, 7.2026038],
          [116.8797305, 7.2025627],
          [116.8794402, 7.202529],
          [116.8793158, 7.2024953],
          [116.8789878, 7.2024131],
          [116.8788295, 7.2024018],
          [116.8787013, 7.2023831],
          [116.8785543, 7.2023831],
          [116.8783733, 7.2023944],
          [116.8781321, 7.2024467],
          [116.8779473, 7.2025066],
          [116.8777664, 7.2025926],
          [116.8776005, 7.2026824],
          [116.877461, 7.2027721],
          [116.8773555, 7.2028507],
          [116.8772486, 7.2029393],
          [116.877134, 7.2030979],
          [116.8770223, 7.2032602],
          [116.8769961, 7.2033953],
          [116.8770343, 7.2035213],
          [116.8770899, 7.2036505],
          [116.8770607, 7.2037739],
          [116.8770279, 7.2039117],
          [116.8770313, 7.20404],
          [116.876999, 7.2040899],
          [116.8769286, 7.2041987],
          [116.8768708, 7.2042514],
          [116.8768847, 7.2043362],
          [116.8769384, 7.2043764],
          [116.8770487, 7.2043431],
          [116.8771658, 7.2043773],
          [116.8773487, 7.2044357],
          [116.8774664, 7.2045459],
          [116.8775252, 7.2046756],
          [116.8775513, 7.2047858],
          [116.8775905, 7.2049154],
          [116.8776297, 7.2050905],
          [116.8776363, 7.2052137],
          [116.8776297, 7.2053563],
          [116.8776036, 7.2054795],
          [116.877584, 7.2055832],
          [116.8775513, 7.2057453],
          [116.877388, 7.2062251],
          [116.8772507, 7.2064649],
          [116.8771723, 7.206627],
          [116.8771266, 7.2067696],
          [116.8770547, 7.2069188],
          [116.8770089, 7.2070484],
          [116.8770089, 7.2071457],
          [116.8769632, 7.2074115],
          [116.8769436, 7.2076384],
          [116.8769697, 7.2077227],
          [116.8770743, 7.2077551],
          [116.8772115, 7.2076838],
          [116.8772899, 7.207606],
          [116.8773945, 7.20758],
          [116.8774664, 7.2075865],
          [116.8774925, 7.2077292],
          [116.877486, 7.2078264],
          [116.8774729, 7.2079172],
          [116.877388, 7.2081052],
          [116.8772769, 7.208384],
          [116.8771658, 7.2085979],
          [116.8770939, 7.2086887],
          [116.8769828, 7.2088054],
          [116.8768913, 7.2088443],
          [116.8766561, 7.2088832],
          [116.8764535, 7.2088443],
          [116.8762966, 7.2087665],
          [116.8762051, 7.2086822],
          [116.8761006, 7.2085849],
          [116.8758784, 7.2084553],
          [116.8757281, 7.2083645],
          [116.8756235, 7.2082737],
          [116.8755647, 7.20817],
          [116.8754994, 7.2080339],
          [116.8753883, 7.2078264],
          [116.8753164, 7.2077097],
          [116.8751923, 7.2075671],
          [116.8751008, 7.2074828],
          [116.8750158, 7.207405],
          [116.8748328, 7.2073078],
          [116.8747348, 7.2072753],
          [116.8746237, 7.2072753],
          [116.874421119689941, 7.207319960663425],
          [116.8742708, 7.2073531],
          [116.8741009, 7.2074958],
          [116.8740029, 7.2076643],
          [116.8738918, 7.2078394],
          [116.8738657, 7.2080728],
          [116.8738657, 7.2082802],
          [116.8738395, 7.2084423],
          [116.8738003, 7.2086303],
          [116.8737938, 7.2088767],
          [116.8738003, 7.2090712],
          [116.873833, 7.2092203],
          [116.8738918, 7.2093175],
          [116.8739637, 7.2093953],
          [116.874114, 7.2093953],
          [116.8742904, 7.2093759],
          [116.874421119689941, 7.209391419963188],
          [116.8744538, 7.2093953],
          [116.8745649, 7.2094602],
          [116.8746956, 7.2095509],
          [116.8748524, 7.2096741],
          [116.8749766, 7.2097973],
          [116.8750616, 7.209901],
          [116.8751008, 7.2100242],
          [116.8751008, 7.2102381],
          [116.8750485, 7.2105104],
          [116.8750158, 7.2106855],
          [116.874957, 7.21088],
          [116.8749113, 7.2110421],
          [116.8748786, 7.2111847],
          [116.8748198, 7.2113662],
          [116.8747675, 7.2114764],
          [116.8746564, 7.2115737],
          [116.8745322, 7.2116126],
          [116.874421119689941, 7.211619098848024],
          [116.8744211, 7.2116191],
          [116.874212, 7.2116061],
          [116.8739964, 7.2114635],
          [116.8739376, 7.2113921],
          [116.8737546, 7.211256],
          [116.8735716, 7.2111458],
          [116.8733625, 7.2110615],
          [116.8732122, 7.2110615],
          [116.8730096, 7.2111263],
          [116.8728724, 7.2111717],
          [116.8726633, 7.2112041],
          [116.8725587, 7.2112236],
          [116.8723496, 7.2111393],
          [116.872232, 7.2110291],
          [116.8721143, 7.2109448],
          [116.8719118, 7.210854],
          [116.8717288, 7.2107827],
          [116.871585, 7.2107309],
          [116.871402, 7.210666],
          [116.871304, 7.2106012],
          [116.8712583, 7.2105234],
          [116.8710884, 7.2103808],
          [116.8709838, 7.2102122],
          [116.8709707, 7.2101344],
          [116.8709315, 7.2100177],
          [116.8708204, 7.2098427],
          [116.8707094, 7.2096741],
          [116.8706701, 7.2095704],
          [116.8705591, 7.2094083],
          [116.8704349, 7.2092333],
          [116.8704284, 7.2090582],
          [116.8704153, 7.2088832],
          [116.8703238, 7.2087341],
          [116.8701996, 7.2086498],
          [116.869886, 7.2084553],
          [116.8696965, 7.2084358],
          [116.8694939, 7.2084812],
          [116.8692848, 7.2085396],
          [116.8690756, 7.2085979],
          [116.86886, 7.2084747],
          [116.8687293, 7.2083904],
          [116.8685725, 7.2082737],
          [116.8684744, 7.2081765],
          [116.8683372, 7.2080728],
          [116.8681216, 7.2078329],
          [116.868017, 7.2077551],
          [116.8678079, 7.2077421],
          [116.867553, 7.2077356],
          [116.8673504, 7.2077162],
          [116.8671087, 7.2076514],
          [116.8668473, 7.2075865],
          [116.8666578, 7.2075606],
          [116.8664486, 7.2075411],
          [116.866233, 7.2074698],
          [116.866135, 7.207418],
          [116.865769, 7.20723],
          [116.8655926, 7.2071522],
          [116.8654031, 7.2071522],
          [116.8652985, 7.2071911],
          [116.8651547, 7.207204],
          [116.8648476, 7.2072235],
          [116.8646908, 7.2071975],
          [116.8644882, 7.2071846],
          [116.8642007, 7.2071457],
          [116.8640961, 7.2071327],
          [116.8639458, 7.2071911],
          [116.863720417022705, 7.207384418566959],
          [116.863704, 7.2073985],
          [116.8633773, 7.2074633],
          [116.8631943, 7.2074504],
          [116.8629394, 7.2074633],
          [116.8627238, 7.2075217],
          [116.8625212, 7.2075476],
          [116.8623121, 7.2075736],
          [116.8621226, 7.2076514],
          [116.86192, 7.2077421],
          [116.8617044, 7.2077421],
          [116.8615344, 7.2077421],
          [116.861456, 7.2077616],
          [116.8612665, 7.2077162],
          [116.8611031, 7.2078264],
          [116.8610247, 7.2079301],
          [116.860979, 7.2080598],
          [116.8609267, 7.208183],
          [116.8608025, 7.2083256],
          [116.8606915, 7.2084618],
          [116.8606196, 7.2085331],
          [116.8605215, 7.2086563],
          [116.8604693, 7.2087276],
          [116.8602275, 7.2088054],
          [116.8600837, 7.2087146],
          [116.8600053, 7.2085914],
          [116.859953, 7.2084358],
          [116.8599334, 7.2082673],
          [116.8598615, 7.2081181],
          [116.8597308, 7.2079496],
          [116.859489, 7.2077421],
          [116.8594041, 7.2076838],
          [116.8591427, 7.2076773],
          [116.8589532, 7.2076903],
          [116.8586657, 7.2076903],
          [116.8583912, 7.2075671],
          [116.8582801, 7.2075282],
          [116.8580775, 7.2074958],
          [116.8579338, 7.2074893],
          [116.8578292, 7.2074698],
          [116.8576985, 7.207418],
          [116.8576397, 7.2073791],
          [116.8573914, 7.2072235],
          [116.8573195, 7.2071586],
          [116.8572737, 7.2070614],
          [116.8572541, 7.2069641],
          [116.8572215, 7.2067826],
          [116.8571888, 7.2066335],
          [116.8571234, 7.206452],
          [116.8570319, 7.2062899],
          [116.8569731, 7.2062056],
          [116.8568947, 7.2060241],
          [116.8567706, 7.2058166],
          [116.856666, 7.2056934],
          [116.8566006, 7.2055184],
          [116.8565549, 7.2054341],
          [116.8564503, 7.2053239],
          [116.8563523, 7.2052007],
          [116.8562935, 7.2051099],
          [116.8561824, 7.2049868],
          [116.8561171, 7.2048701],
          [116.8560583, 7.2047404],
          [116.8560256, 7.2045589],
          [116.8559145, 7.2041439],
          [116.8558491, 7.2039559],
          [116.8557969, 7.2037938],
          [116.8557577, 7.2036447],
          [116.8557446, 7.2034762],
          [116.8557838, 7.2033206],
          [116.8558361, 7.2032363],
          [116.8560321, 7.2031585],
          [116.8562151, 7.2031585],
          [116.8563523, 7.2031196],
          [116.8563719, 7.2029899],
          [116.8563523, 7.2029056],
          [116.8562739, 7.2028084],
          [116.856202, 7.2027176],
          [116.8561693, 7.2026398],
          [116.8561367, 7.2025491],
          [116.856104, 7.202387],
          [116.856104, 7.2022832],
          [116.8561236, 7.2021341],
          [116.8561367, 7.2020045],
          [116.8561955, 7.2018294],
          [116.8561628, 7.2016803],
          [116.8561367, 7.201583],
          [116.8560909, 7.2014599],
          [116.8560452, 7.2013561],
          [116.8560648, 7.2012589],
          [116.8561236, 7.2010968],
          [116.8563327, 7.2009996],
          [116.8564765, 7.2009607],
          [116.8565484, 7.2009347],
          [116.8567313, 7.2009801],
          [116.8568359, 7.2010709],
          [116.8569078, 7.201207],
          [116.8571626, 7.2013108],
          [116.8572541, 7.2013108],
          [116.8574175, 7.2013497],
          [116.8575613, 7.2013108],
          [116.857692, 7.201233],
          [116.8577965, 7.2011552],
          [116.8579141, 7.2011033],
          [116.8579926, 7.2010903],
          [116.8580514, 7.201032],
          [116.8580775, 7.2008699],
          [116.8581233, 7.2007597],
          [116.8582017, 7.2007078],
          [116.8585807, 7.2007208],
          [116.8587441, 7.2008569],
          [116.858816, 7.2009153],
          [116.8589663, 7.201019],
          [116.8590381, 7.2009996],
          [116.8594041, 7.2008699],
          [116.8596524, 7.2007986],
          [116.8597831, 7.2007597],
          [116.8598811, 7.2007078],
          [116.8600772, 7.2006235],
          [116.860332, 7.2004225],
          [116.8604562, 7.2003383],
          [116.8605673, 7.2002994],
          [116.8606457, 7.2003123],
          [116.8606326, 7.2004874],
          [116.8606392, 7.2005976],
          [116.8607176, 7.2006819],
          [116.8608744, 7.2007467],
          [116.8613319, 7.2007273],
          [116.8614626, 7.2007273],
          [116.8616586, 7.2007402],
          [116.8618416, 7.2007143],
          [116.8620246, 7.2006884],
          [116.8621291, 7.2006754],
          [116.8624559, 7.2006559],
          [116.8625931, 7.200617],
          [116.8626976, 7.2004874],
          [116.862763, 7.2003772],
          [116.8628545, 7.2002864],
          [116.8629656, 7.2002734],
          [116.8630701, 7.2002734],
          [116.8632727, 7.2003577],
          [116.8633838, 7.2004809],
          [116.8634753, 7.2005522],
          [116.8635929, 7.2006495],
          [116.863720417022705, 7.200896983137209],
          [116.8637432, 7.2009412],
          [116.8637955, 7.2010579],
          [116.8638608, 7.2011746],
          [116.8639393, 7.2012265],
          [116.8640242, 7.2012524],
          [116.8640504, 7.2011616],
          [116.8640242, 7.2009996],
          [116.8639654, 7.2008764],
          [116.8639327, 7.2007532],
          [116.8639197, 7.200643],
          [116.8639393, 7.2005392],
          [116.8640308, 7.2004161],
          [116.8642595, 7.2004031],
          [116.8644621, 7.2004939],
          [116.8645405, 7.2005911],
          [116.8646189, 7.2007791],
          [116.8647038, 7.2009347],
          [116.8648607, 7.2010514],
          [116.8653247, 7.2013172],
          [116.8654815, 7.201421],
          [116.8655991, 7.2015052],
          [116.8658344, 7.2016025],
          [116.86605, 7.2016544],
          [116.8664094, 7.201784],
          [116.8665336, 7.2018294],
          [116.8668146, 7.2019526],
          [116.866991, 7.2020239],
          [116.8671217, 7.2020434],
          [116.8673243, 7.2021082],
          [116.8674093, 7.2021341],
          [116.86754, 7.2021795],
          [116.8677229, 7.2022573],
          [116.8681281, 7.202387],
          [116.8681738, 7.202575],
          [116.8682457, 7.2026139],
          [116.8683633, 7.202588],
          [116.8685855, 7.202588],
          [116.8688077, 7.2026593],
          [116.8690495, 7.2027306],
          [116.8695396, 7.202763],
          [116.869703, 7.2028538],
          [116.8699252, 7.2029575],
          [116.8701016, 7.2029964],
          [116.8702519, 7.2030807],
          [116.8703761, 7.2031066],
          [116.8705656, 7.2030807],
          [116.8707486, 7.203139],
          [116.8708401, 7.2031844],
          [116.8710361, 7.2030872],
          [116.8710688, 7.2029121],
          [116.8710818, 7.2028214],
          [116.8711537, 7.2027371],
          [116.8712779, 7.2027176],
          [116.8713498, 7.2027565],
          [116.871402, 7.2030094],
          [116.871402, 7.2031844],
          [116.8714674, 7.2032946],
          [116.8717745, 7.2033595],
          [116.8720294, 7.2033724],
          [116.8722842, 7.2033724],
          [116.872526, 7.2033789],
          [116.8727025, 7.2034048],
          [116.8728136, 7.2034048],
          [116.8729377, 7.2034048],
          [116.8731534, 7.2034956],
          [116.8734474, 7.2035021],
          [116.8736435, 7.2036318],
          [116.8737023, 7.2037549],
          [116.8737219, 7.2039624],
          [116.8738199, 7.2041245],
          [116.8739702, 7.2042866],
          [116.8739898, 7.2043773],
          [116.873931, 7.2046367],
          [116.873931, 7.2048571],
          [116.8739898, 7.2049349],
          [116.8741467, 7.2051294],
          [116.8742708, 7.2052461],
          [116.8743623, 7.2053563],
          [116.874421119689941, 7.205434055089037],
          [116.8744996, 7.2055378],
          [116.8746041, 7.2056286],
          [116.8747152, 7.2056805],
          [116.8749047, 7.205674],
          [116.8750877, 7.2055767],
          [116.8752053, 7.2054795],
          [116.8753622, 7.2053693],
          [116.875519, 7.205298],
          [116.8756693, 7.205285],
          [116.8758457, 7.2052591],
          [116.8760026, 7.2052072],
          [116.8760745, 7.2051164],
          [116.8761071, 7.2050321],
          [116.8761855, 7.2049608],
          [116.8763424, 7.204896],
          [116.87646, 7.204883],
          [116.87656, 7.2047618],
          [116.8764884, 7.2047319],
          [116.8764243, 7.2046421],
          [116.8763414, 7.2044813],
          [116.8763226, 7.2043579],
          [116.8763686, 7.2042617],
          [116.8764411, 7.2041574],
          [116.8764807, 7.2040335],
          [116.8764987, 7.2039412],
          [116.8765026, 7.2038423],
          [116.8764487, 7.2038109],
          [116.8763741, 7.2037654],
          [116.8762735, 7.203737],
          [116.8761114, 7.2036585],
          [116.8760099, 7.2035907],
          [116.8758426, 7.203501],
          [116.8756779, 7.203393],
          [116.875595, 7.2033144],
          [116.8754178, 7.203176],
          [116.8753235, 7.20309],
          [116.8752798, 7.2029793],
          [116.8751593, 7.2028965],
          [116.8749957, 7.2027763],
          [116.8748595, 7.2027],
          [116.8746978, 7.2025851],
          [116.8745922, 7.2024654],
          [116.8744866, 7.2023083],
          [116.8744263, 7.2021475],
          [116.874421119689941, 7.202122407335341],
          [116.8743962, 7.2020017],
          [116.8744037, 7.2019306],
          [116.874415, 7.201867],
          [116.8744113, 7.2018147],
          [116.874421119689941, 7.201781098701096],
          [116.8744452, 7.2016987],
          [116.8745168, 7.2016613],
          [116.8745206, 7.201579],
          [116.8744414, 7.2015828],
          [116.874421119689941, 7.201573223186919],
          [116.8743622, 7.2015454],
          [116.8742906, 7.2013584],
          [116.8742303, 7.2012275],
          [116.8742303, 7.2011452],
          [116.8742718, 7.2009731],
          [116.874317, 7.2008422],
          [116.874219, 7.2007225],
          [116.874121, 7.2006103],
          [116.8740267, 7.2004794],
          [116.8739136, 7.2003261],
          [116.8738609, 7.2002363],
          [116.8738194, 7.2001802],
          [116.8737704, 7.2000456],
          [116.8737742, 7.1999184],
          [116.8737742, 7.1997277],
          [116.8736724, 7.1995369],
          [116.8736121, 7.1994173],
          [116.8735027, 7.1992527],
          [116.8734499, 7.1991555],
          [116.8733708, 7.1990432],
          [116.8732615, 7.1989834],
          [116.873088, 7.1989871],
          [116.872434, 7.1991648],
          [116.8722738, 7.1991985],
          [116.8721135, 7.1992359],
          [116.8720664, 7.199262],
          [116.8718836, 7.199363],
          [116.8718157, 7.1993855],
          [116.8717092, 7.1994364],
          [116.8716863, 7.1994567],
          [116.8716913, 7.1994898],
          [116.871733, 7.199506],
          [116.8717676, 7.1995174],
          [116.8718034, 7.1995197],
          [116.8718577, 7.1995197],
          [116.8718785, 7.199522],
          [116.8719432, 7.1995174],
          [116.8719732, 7.199522],
          [116.8719963, 7.1995793],
          [116.8720056, 7.1996149],
          [116.8720137, 7.1996641],
          [116.8720137, 7.1997043],
          [116.8720091, 7.1997547],
          [116.8719917, 7.1998131],
          [116.8719883, 7.1998601],
          [116.8720198, 7.1999163],
          [116.8720383, 7.2000126],
          [116.8720383, 7.200118],
          [116.8719967, 7.2002372],
          [116.8719644, 7.2003106],
          [116.8719136, 7.2004114],
          [116.8718581, 7.2005169],
          [116.8717426, 7.2006865],
          [116.8716548, 7.2008011],
          [116.8715485, 7.2009203],
          [116.8714838, 7.2009891],
          [116.871396, 7.2010487],
          [116.871262, 7.2011266],
          [116.8711095, 7.2012137],
          [116.8709524, 7.2012779],
          [116.8708415, 7.2013054],
          [116.8706937, 7.2013329],
          [116.8704904, 7.2013833],
          [116.8703979, 7.2013833],
          [116.8703194, 7.2013833],
          [116.8702085, 7.2013604],
          [116.8701068, 7.2013054],
          [116.8699774, 7.2012366],
          [116.8698573, 7.2011633],
          [116.8697787, 7.2010991],
          [116.8696863, 7.2010028],
          [116.8695847, 7.2009386],
          [116.8694276, 7.2008103],
          [116.869252, 7.2007461],
          [116.869118, 7.2006957],
          [116.8689655, 7.2006636],
          [116.8688823, 7.2006452],
          [116.8688084, 7.2005994],
          [116.8687668, 7.200549],
          [116.8687206, 7.2004435],
          [116.8687206, 7.2003427],
          [116.8687391, 7.2002831],
          [116.8688038, 7.2001685],
          [116.8688592, 7.2001226],
          [116.8689609, 7.2000447],
          [116.8690671, 7.1999897],
          [116.8691965, 7.1999667],
          [116.8693536, 7.1999392],
          [116.8694368, 7.1999484],
          [116.8695847, 7.1999805],
          [116.8696447, 7.2000172],
          [116.8697094, 7.2000768],
          [116.8697556, 7.2001043],
          [116.8698665, 7.2001685],
          [116.870019, 7.2002647],
          [116.8701299, 7.2003427],
          [116.8701992, 7.2004068],
          [116.8702593, 7.2004664],
          [116.8704164, 7.2005994],
          [116.8705366, 7.2006957],
          [116.8706382, 7.2007919],
          [116.8707214, 7.2008332],
          [116.8708092, 7.2008378],
          [116.8708924, 7.2008286],
          [116.8709617, 7.2007278],
          [116.8709709, 7.2006361],
          [116.8710079, 7.2005352],
          [116.8710402, 7.2004894],
          [116.8710818, 7.2004298],
          [116.8711558, 7.2004023],
          [116.8712389, 7.2004023],
          [116.8713082, 7.2003518],
          [116.8713313, 7.2002464],
          [116.8713729, 7.200173],
          [116.8714515, 7.200118],
          [116.8715439, 7.2000584],
          [116.8715947, 7.2000126],
          [116.8716456, 7.1999117],
          [116.8716779, 7.1998384],
          [116.8717233, 7.199724],
          [116.8717182, 7.199665],
          [116.8716904, 7.1996186],
          [116.871631, 7.1995247],
          [116.8715696, 7.1995534],
          [116.8715058, 7.1995945],
          [116.8714425, 7.199565],
          [116.8713916, 7.1995126],
          [116.8713577, 7.1994565],
          [116.8713464, 7.1993929],
          [116.871352, 7.1993481],
          [116.8713841, 7.1993144],
          [116.871435, 7.1992938],
          [116.8714821, 7.1992957],
          [116.8715387, 7.199335],
          [116.8715839, 7.1993537],
          [116.8716235, 7.1993238],
          [116.8716329, 7.1992733],
          [116.8715895, 7.199249],
          [116.8714406, 7.1992228],
          [116.8712993, 7.1992078],
          [116.8711805, 7.1991816],
          [116.8710637, 7.1991555],
          [116.8709147, 7.1991199],
          [116.8707432, 7.1990395],
          [116.8706226, 7.1989946],
          [116.8705208, 7.1989497],
          [116.8703757, 7.1989105],
          [116.8702531, 7.1988731],
          [116.8701438, 7.1988188],
          [116.8700533, 7.1987927],
          [116.8698837, 7.1987141],
          [116.8697838, 7.1986412],
          [116.8696858, 7.1985851],
          [116.8695067, 7.1985234],
          [116.8694087, 7.1984579],
          [116.8693126, 7.1983775],
          [116.8692259, 7.1982896],
          [116.8691335, 7.1982279],
          [116.8691063, 7.1982177],
          [116.8690891, 7.1982113],
          [116.8690487, 7.1981961],
          [116.8689243, 7.1981456],
          [116.8687094, 7.1980671],
          [116.8685398, 7.197996],
          [116.8684172, 7.1979362],
          [116.8682627, 7.1978483],
          [116.8680044, 7.1976987],
          [116.8678593, 7.197607],
          [116.8677142, 7.1975061],
          [116.8675049, 7.1973789],
          [116.8673698, 7.1973023],
          [116.8673527, 7.1972929],
          [116.8672712, 7.197248],
          [116.8671544, 7.1971769],
          [116.8670111, 7.1970385],
          [116.8669169, 7.1969656],
          [116.8667491, 7.1968216],
          [116.8665832, 7.1966776],
          [116.8662929, 7.1964177],
          [116.8661346, 7.1962812],
          [116.8660385, 7.1961989],
          [116.8659593, 7.196111],
          [116.8658896, 7.1960362],
          [116.8657803, 7.1959277],
          [116.8656182, 7.1957314],
          [116.8655503, 7.1956771],
          [116.8654768, 7.1956435],
          [116.8653335, 7.1956304],
          [116.8652167, 7.1955836],
          [116.865179, 7.1955687],
          [116.8650734, 7.1955163],
          [116.8649697, 7.1954658],
          [116.8648604, 7.195406],
          [116.864785, 7.1953181],
          [116.864736, 7.1952115],
          [116.8647247, 7.1951778],
          [116.8647134, 7.1950675],
          [116.8647134, 7.1950151],
          [116.8647341, 7.1949385],
          [116.8647605, 7.194888],
          [116.8647944, 7.1948356],
          [116.864802, 7.1947926],
          [116.8647681, 7.1947571],
          [116.8646795, 7.1947178],
          [116.8646041, 7.1946972],
          [116.8645136, 7.1946673],
          [116.8644401, 7.1946299],
          [116.8643722, 7.1945832],
          [116.8643402, 7.1945458],
          [116.8643081, 7.1944373],
          [116.864278, 7.1942989],
          [116.864278, 7.194226],
          [116.8642912, 7.194153],
          [116.8643232, 7.1940633],
          [116.8643157, 7.1939716],
          [116.8642686, 7.1938707],
          [116.8642686, 7.1937921],
          [116.8642082, 7.1936874],
          [116.8641536, 7.1936519],
          [116.864048, 7.1935378],
          [116.8639481, 7.1934349],
          [116.8638501, 7.1934069],
          [116.8637936, 7.1933826],
          [116.863720417022705, 7.193353215925781],
          [116.8637144, 7.1933508],
          [116.8635749, 7.193362],
          [116.8634599, 7.1933994],
          [116.8633789, 7.1933676],
          [116.8632959, 7.1933452],
          [116.8632601, 7.193347],
          [116.8632187, 7.1933489],
          [116.8631338, 7.1933321],
          [116.8630471, 7.1933134],
          [116.8629962, 7.1932853],
          [116.8629435, 7.1932217],
          [116.862919, 7.1931843],
          [116.8628191, 7.1931357],
          [116.8627286, 7.1931282],
          [116.8626645, 7.1930684],
          [116.8625985, 7.1929637],
          [116.8625627, 7.1928627],
          [116.8625269, 7.1927636],
          [116.8624515, 7.1927206],
          [116.8623686, 7.1926869],
          [116.8622102, 7.1927112],
          [116.8621405, 7.1927112],
          [116.8620123, 7.1927056],
          [116.86192, 7.1927131],
          [116.8618276, 7.192728],
          [116.8617202, 7.1927337],
          [116.8616259, 7.1927636],
          [116.8615562, 7.1927243],
          [116.8614864, 7.1926776],
          [116.8614261, 7.1927075],
          [116.8613903, 7.1927617],
          [116.8614167, 7.1928309],
          [116.8613922, 7.1929057],
          [116.8612546, 7.1929618],
          [116.8610925, 7.1930366],
          [116.8609096, 7.1930403],
          [116.8608154, 7.1930029],
          [116.8606665, 7.1928982],
          [116.8606024, 7.1928384],
          [116.860544, 7.1927337],
          [116.8604799, 7.192685],
          [116.8603932, 7.1927355],
          [116.8602933, 7.1927561],
          [116.8602631, 7.1927673],
          [116.8601651, 7.1927916],
          [116.8601029, 7.1928309],
          [116.8600596, 7.1929038],
          [116.8600596, 7.1929581],
          [116.8600784, 7.1930216],
          [116.8601293, 7.1930834],
          [116.8601858, 7.1931245],
          [116.8602122, 7.1931731],
          [116.8602311, 7.1932311],
          [116.8602348, 7.1933508],
          [116.8602198, 7.1934218],
          [116.8601613, 7.1934518],
          [116.8601331, 7.1935565],
          [116.8600916, 7.19365],
          [116.8600577, 7.1937715],
          [116.8600784, 7.1938856],
          [116.8601387, 7.1939698],
          [116.8602179, 7.1940464],
          [116.8602876, 7.1941474],
          [116.8603291, 7.1942297],
          [116.8603854, 7.1943991],
          [116.8603921, 7.1944573],
          [116.8603814, 7.1945961],
          [116.8603708, 7.1947046],
          [116.8603561, 7.1948447],
          [116.8603348, 7.1949862],
          [116.8603188, 7.1950642],
          [116.8602841, 7.1952189],
          [116.8602561, 7.1953208],
          [116.8602108, 7.1954556],
          [116.8601722, 7.1955469],
          [116.8601562, 7.1955879],
          [116.8600882, 7.195736],
          [116.8600562, 7.195818],
          [116.8599856, 7.1959462],
          [116.8599123, 7.1960732],
          [116.8598643, 7.196138],
          [116.8597923, 7.1962001],
          [116.8597203, 7.1962424],
          [116.8595711, 7.1963178],
          [116.8595111, 7.1963482],
          [116.8594724, 7.1963297],
          [116.8594538, 7.1962768],
          [116.8594258, 7.1962133],
          [116.8593751, 7.1961671],
          [116.8593232, 7.1961247],
          [116.8592818, 7.1960692],
          [116.8592139, 7.1960322],
          [116.8591379, 7.1960441],
          [116.8591152, 7.1960864],
          [116.8591152, 7.1961234],
          [116.8591246, 7.1961684],
          [116.8591525, 7.1962094],
          [116.8591819, 7.1962424],
          [116.8592192, 7.1962728],
          [116.8592752, 7.1963059],
          [116.8593018, 7.1963456],
          [116.8593391, 7.1963971],
          [116.8593045, 7.1964566],
          [116.8592805, 7.1965333],
          [116.8592632, 7.1966021],
          [116.8592285, 7.1966497],
          [116.8591472, 7.1967317],
          [116.8590966, 7.1967687],
          [116.8590313, 7.1968097],
          [116.858922, 7.1968494],
          [116.858842, 7.1968639],
          [116.858794, 7.1968705],
          [116.8586887, 7.1968401],
          [116.8586287, 7.1967727],
          [116.8585861, 7.1967251],
          [116.8585754, 7.1966854],
          [116.8585421, 7.1966206],
          [116.8584848, 7.1966246],
          [116.8584088, 7.1966285],
          [116.8583715, 7.1966193],
          [116.8583142, 7.1965928],
          [116.8582755, 7.1965717],
          [116.8582529, 7.1965413],
          [116.8582262, 7.1964831],
          [116.8581889, 7.1965016],
          [116.8581676, 7.1965532],
          [116.8581636, 7.1965743],
          [116.8581409, 7.1965889],
          [116.8580929, 7.1965717],
          [116.858037, 7.1965783],
          [116.858021, 7.1966166],
          [116.858033, 7.196659],
          [116.8579583, 7.1967383],
          [116.8579157, 7.1968005],
          [116.857889, 7.1968401],
          [116.8577891, 7.1969168],
          [116.8577171, 7.1969724],
          [116.8575811, 7.1970596],
          [116.8575105, 7.1970834],
          [116.8573546, 7.197139],
          [116.8572106, 7.1971469],
          [116.8571613, 7.1971443],
          [116.857076, 7.1971165],
          [116.8570054, 7.1970768],
          [116.8569627, 7.1970305],
          [116.8569081, 7.1969724],
          [116.8568534, 7.1969195],
          [116.8567774, 7.1968639],
          [116.8566735, 7.1968243],
          [116.8565908, 7.1968216],
          [116.8565522, 7.1968124],
          [116.8564949, 7.1967872],
          [116.8564962, 7.1967661],
          [116.8565082, 7.1967185],
          [116.8564576, 7.1967066],
          [116.8564349, 7.1967409],
          [116.8564162, 7.1967793],
          [116.8563589, 7.196815],
          [116.8563149, 7.1968137],
          [116.8562976, 7.1967899],
          [116.8563109, 7.1967423],
          [116.8563149, 7.1967277],
          [116.8563083, 7.1966762],
          [116.8562496, 7.1966722],
          [116.8562283, 7.1967066],
          [116.856243, 7.1967555],
          [116.856251, 7.1967899],
          [116.856187, 7.1968044],
          [116.8561497, 7.1968018],
          [116.8560964, 7.1967608],
          [116.8560417, 7.1966947],
          [116.8560031, 7.1966748],
          [116.8559431, 7.1966431],
          [116.8558991, 7.1966404],
          [116.8558538, 7.1966418],
          [116.8558045, 7.1966021],
          [116.8557845, 7.1965757],
          [116.8557458, 7.1965611],
          [116.8557378, 7.1965809],
          [116.8557232, 7.1966233],
          [116.8556805, 7.1966272],
          [116.8556285, 7.1966365],
          [116.8555712, 7.1966854],
          [116.8555352, 7.1967119],
          [116.8554833, 7.1967383],
          [116.855406, 7.1967555],
          [116.855382, 7.1967436],
          [116.8553353, 7.1967211],
          [116.8552967, 7.1967264],
          [116.85525, 7.1967515],
          [116.855234, 7.1967753],
          [116.855218, 7.1968229],
          [116.8551967, 7.1968441],
          [116.8551567, 7.1968705],
          [116.8551087, 7.1968904],
          [116.8550528, 7.1969036],
          [116.8550101, 7.1969062],
          [116.8549421, 7.1969062],
          [116.8548835, 7.196897],
          [116.8548582, 7.1968666],
          [116.8548448, 7.1968375],
          [116.8548035, 7.1968282],
          [116.8547942, 7.1968613],
          [116.8547969, 7.1968864],
          [116.8548035, 7.1969049],
          [116.8548195, 7.1969261],
          [116.8548275, 7.1969552],
          [116.8547982, 7.1969724],
          [116.8547689, 7.1969657],
          [116.8547582, 7.1969512],
          [116.8547329, 7.1969168],
          [116.8546929, 7.1969195],
          [116.8546716, 7.1969605],
          [116.8546796, 7.1969869],
          [116.8546809, 7.1970292],
          [116.8546622, 7.197061],
          [116.8546036, 7.1970768],
          [116.8545769, 7.1970742],
          [116.8545503, 7.1970464],
          [116.8545449, 7.1970266],
          [116.8545476, 7.1969724],
          [116.854489, 7.1969763],
          [116.8544636, 7.1970226],
          [116.854465, 7.1970557],
          [116.8544863, 7.1970927],
          [116.8544756, 7.197135],
          [116.8544317, 7.1971099],
          [116.854417, 7.1971535],
          [116.8544143, 7.1971985],
          [116.8543997, 7.1972408],
          [116.854393, 7.1973148],
          [116.854393, 7.1973334],
          [116.8543584, 7.1973691],
          [116.854273, 7.197451],
          [116.8542304, 7.1974854],
          [116.8541984, 7.1975158],
          [116.8541518, 7.1975674],
          [116.8541464, 7.1976058],
          [116.8541318, 7.1976269],
          [116.8540905, 7.1976177],
          [116.8540371, 7.1976229],
          [116.8540171, 7.1976639],
          [116.8540025, 7.1977062],
          [116.8540051, 7.1977459],
          [116.8540038, 7.1978081],
          [116.8539638, 7.1978782],
          [116.8539172, 7.1979548],
          [116.8538785, 7.1979853],
          [116.8538146, 7.1980342],
          [116.8537772, 7.1980329],
          [116.8537373, 7.1980315],
          [116.8537093, 7.1979853],
          [116.8536706, 7.1979443],
          [116.8536, 7.1979456],
          [116.8535866, 7.1979853],
          [116.85358, 7.1980421],
          [116.853588, 7.1980606],
          [116.8536186, 7.1980858],
          [116.8536413, 7.1981069],
          [116.8536773, 7.1981228],
          [116.8537679, 7.1981188],
          [116.8537519, 7.198181],
          [116.8537266, 7.1982365],
          [116.8537079, 7.1982709],
          [116.8536679, 7.1983277],
          [116.8536733, 7.1983714],
          [116.8536559, 7.1984296],
          [116.8536106, 7.1984891],
          [116.85358, 7.1985155],
          [116.853532, 7.1985393],
          [116.853472, 7.198579],
          [116.8533894, 7.1986464],
          [116.8533467, 7.1986927],
          [116.8533294, 7.1987522],
          [116.8533641, 7.1987549],
          [116.8533894, 7.1987734],
          [116.8533907, 7.1988077],
          [116.8533814, 7.1988593],
          [116.8533681, 7.1988858],
          [116.8533321, 7.1989215],
          [116.8532788, 7.198932],
          [116.8532228, 7.1989625],
          [116.8532414, 7.1989929],
          [116.8532748, 7.1990193],
          [116.8533241, 7.1990497],
          [116.8533241, 7.1990881],
          [116.8533187, 7.1991291],
          [116.8533067, 7.1991595],
          [116.8532921, 7.1992031],
          [116.8532947, 7.199252],
          [116.8532934, 7.199301],
          [116.8532894, 7.1993512],
          [116.8532748, 7.1994319],
          [116.8532841, 7.1994676],
          [116.8532588, 7.199502],
          [116.8532108, 7.199539],
          [116.8531615, 7.1995549],
          [116.8531108, 7.1995588],
          [116.8530708, 7.1995879],
          [116.8530655, 7.1996091],
          [116.8530762, 7.1996249],
          [116.8531228, 7.199621],
          [116.8531481, 7.1996117],
          [116.8531735, 7.1995879],
          [116.8532055, 7.1995959],
          [116.8531828, 7.1996276],
          [116.8532108, 7.1996474],
          [116.8532574, 7.1996408],
          [116.8532614, 7.1996633],
          [116.8532441, 7.1996858],
          [116.8532468, 7.1997043],
          [116.8532641, 7.1997202],
          [116.8533094, 7.19974],
          [116.8533014, 7.1997611],
          [116.8532188, 7.1997863],
          [116.8531561, 7.1997902],
          [116.8530642, 7.1997929],
          [116.8530255, 7.199822],
          [116.8530269, 7.1998616],
          [116.8530588, 7.1998973],
          [116.8530668, 7.1999383],
          [116.8530455, 7.2000045],
          [116.8530708, 7.2000335],
          [116.8530855, 7.2000494],
          [116.8531668, 7.2000388],
          [116.8532148, 7.2000388],
          [116.8532708, 7.2000203],
          [116.8533241, 7.1999886],
          [116.8533694, 7.1999767],
          [116.8534147, 7.1999846],
          [116.853428, 7.2000137],
          [116.8534227, 7.2000335],
          [116.8533867, 7.2000494],
          [116.8533294, 7.2000613],
          [116.8532788, 7.2000878],
          [116.8532254, 7.2000864],
          [116.8531241, 7.200097],
          [116.8530722, 7.2001116],
          [116.8530482, 7.200138],
          [116.8530415, 7.2001697],
          [116.8530442, 7.2001975],
          [116.8530628, 7.2002279],
          [116.8530842, 7.2002689],
          [116.8530948, 7.2003033],
          [116.8531308, 7.2003456],
          [116.8531855, 7.2003906],
          [116.8532654, 7.2003773],
          [116.8532961, 7.2003668],
          [116.8533494, 7.2003668],
          [116.8533827, 7.2003588],
          [116.8533867, 7.2003337],
          [116.8534107, 7.2003324],
          [116.8534507, 7.2003337],
          [116.8535013, 7.2003549],
          [116.853512, 7.2003866],
          [116.8534813, 7.2003985],
          [116.8534067, 7.2003945],
          [116.8533587, 7.2004064],
          [116.8533081, 7.2004276],
          [116.8532947, 7.2004607],
          [116.8533254, 7.2004752],
          [116.8533667, 7.2004778],
          [116.853384, 7.2004964],
          [116.8533507, 7.2005202],
          [116.8533067, 7.2005519],
          [116.8532921, 7.2005823],
          [116.8533107, 7.2006088],
          [116.8533374, 7.2006021],
          [116.8533454, 7.2005744],
          [116.8533587, 7.200581],
          [116.8534, 7.2005863],
          [116.8534227, 7.2006035],
          [116.8533907, 7.2006445],
          [116.8533427, 7.2006537],
          [116.8532881, 7.2006326],
          [116.8532361, 7.2006273],
          [116.8531961, 7.2006339],
          [116.8531508, 7.2006762],
          [116.8531495, 7.2006973],
          [116.8531628, 7.2007198],
          [116.8531655, 7.2007555],
          [116.8531881, 7.2007846],
          [116.8532281, 7.2007978],
          [116.8532721, 7.2007899],
          [116.8533214, 7.2007793],
          [116.853396, 7.2007542],
          [116.8534187, 7.2007569],
          [116.853428, 7.2007873],
          [116.853396, 7.2008256],
          [116.8533454, 7.2008613],
          [116.8533121, 7.2008666],
          [116.8532841, 7.2008521],
          [116.8532574, 7.2008626],
          [116.8532414, 7.200897],
          [116.8532534, 7.2009407],
          [116.8532628, 7.2009764],
          [116.8532734, 7.2010054],
          [116.8532934, 7.2010412],
          [116.8533147, 7.2010663],
          [116.8533401, 7.2010623],
          [116.8533707, 7.2010385],
          [116.8533987, 7.2010623],
          [116.853428, 7.2011033],
          [116.8534627, 7.2011549],
          [116.8534853, 7.2011985],
          [116.8534813, 7.2012461],
          [116.853452, 7.2012527],
          [116.8534294, 7.2012871],
          [116.8534414, 7.2013056],
          [116.8534587, 7.201336],
          [116.853436, 7.2013268],
          [116.8533934, 7.2013254],
          [116.8533614, 7.2013506],
          [116.8533787, 7.2013757],
          [116.853408, 7.2013969],
          [116.8534374, 7.2014008],
          [116.8534813, 7.2014246],
          [116.8535133, 7.2014445],
          [116.853528, 7.2014431],
          [116.8535547, 7.2014207],
          [116.8535946, 7.2014365],
          [116.8535826, 7.2014802],
          [116.8535666, 7.2015013],
          [116.8535906, 7.2015212],
          [116.8536186, 7.2015132],
          [116.8536226, 7.2015489],
          [116.8536426, 7.2015926],
          [116.8536599, 7.2016203],
          [116.8537053, 7.2016402],
          [116.8537053, 7.2016904],
          [116.8537253, 7.2017288],
          [116.8537359, 7.2017459],
          [116.8537452, 7.2017975],
          [116.8538239, 7.2018028],
          [116.8538265, 7.2018279],
          [116.8538345, 7.2019192],
          [116.8538545, 7.2019813],
          [116.8538772, 7.2020488],
          [116.8538745, 7.202095],
          [116.8538665, 7.202136],
          [116.8538679, 7.202181],
          [116.8538799, 7.2022154],
          [116.8538785, 7.2022656],
          [116.8538785, 7.2023251],
          [116.8538705, 7.2023859],
          [116.8538679, 7.2024944],
          [116.8538599, 7.2025671],
          [116.8538652, 7.2026081],
          [116.8538865, 7.2026438],
          [116.8538945, 7.2027152],
          [116.8538879, 7.2027787],
          [116.8538639, 7.202821],
          [116.8538399, 7.2028408],
          [116.8538319, 7.2028686],
          [116.8538146, 7.2029003],
          [116.8537666, 7.202936],
          [116.8537226, 7.2029493],
          [116.8537106, 7.2029215],
          [116.8536853, 7.2029136],
          [116.853616, 7.2029122],
          [116.8536, 7.2029466],
          [116.8536013, 7.2029612],
          [116.8536506, 7.2029757],
          [116.8536799, 7.2029942],
          [116.8536799, 7.2030273],
          [116.8536639, 7.2030511],
          [116.8536106, 7.2030616],
          [116.8535693, 7.2030736],
          [116.8535706, 7.2030907],
          [116.853616, 7.2030921],
          [116.8536666, 7.2030828],
          [116.8536933, 7.2030934],
          [116.853644, 7.2031079],
          [116.8536114, 7.2031172],
          [116.8536397, 7.2031527],
          [116.8536284, 7.2031995],
          [116.8536812, 7.2032256],
          [116.8537075, 7.2032107],
          [116.8537565, 7.2031995],
          [116.8537754, 7.2032705],
          [116.8537792, 7.2033229],
          [116.8537528, 7.2034014],
          [116.8537151, 7.2034257],
          [116.8536604, 7.2034127],
          [116.853634, 7.2034351],
          [116.8536416, 7.203465],
          [116.8536943, 7.2034669],
          [116.8537226, 7.2035099],
          [116.853732, 7.2035436],
          [116.8537452, 7.2035978],
          [116.853749, 7.203624],
          [116.8537622, 7.2036389],
          [116.8537924, 7.2036483],
          [116.8537697, 7.2036688],
          [116.8538074, 7.2036969],
          [116.8538565, 7.2036875],
          [116.8539318, 7.2036969],
          [116.8539601, 7.2037324],
          [116.8539809, 7.2037997],
          [116.8539677, 7.2038521],
          [116.8539677, 7.203897],
          [116.8539714, 7.2039381],
          [116.8539695, 7.2039886],
          [116.8539601, 7.2040372],
          [116.8539526, 7.2040896],
          [116.8539903, 7.2041326],
          [116.8540016, 7.204213],
          [116.8540091, 7.2042429],
          [116.8540129, 7.2042878],
          [116.854028, 7.2043533],
          [116.8540412, 7.2044299],
          [116.8540449, 7.2045085],
          [116.8540525, 7.2045852],
          [116.8540525, 7.2046226],
          [116.8540223, 7.2046861],
          [116.8539922, 7.2047123],
          [116.8539903, 7.204731],
          [116.8539846, 7.204789],
          [116.8539714, 7.204804],
          [116.8539695, 7.2048227],
          [116.8539827, 7.2048713],
          [116.8539846, 7.2049236],
          [116.8539809, 7.2049666],
          [116.8539865, 7.205034],
          [116.8540091, 7.2050527],
          [116.8540317, 7.2050639],
          [116.8540713, 7.2050639],
          [116.8540996, 7.2050414],
          [116.8541166, 7.2050975],
          [116.8541166, 7.205163],
          [116.8541316, 7.2052004],
          [116.8541863, 7.2052546],
          [116.8541656, 7.2053163],
          [116.8541938, 7.2053238],
          [116.8542391, 7.2053294],
          [116.8542862, 7.2053182],
          [116.8543748, 7.2053949],
          [116.8543993, 7.2054734],
          [116.8544445, 7.2055127],
          [116.854503, 7.2055763],
          [116.8545633, 7.2056193],
          [116.8545539, 7.2056885],
          [116.8545181, 7.2057427],
          [116.8544408, 7.2057577],
          [116.8544031, 7.2057857],
          [116.8543654, 7.2058175],
          [116.8543201, 7.205868],
          [116.8543088, 7.2058979],
          [116.8543428, 7.2059316],
          [116.8543955, 7.2059559],
          [116.8544785, 7.2060064],
          [116.8544841, 7.2060456],
          [116.8544992, 7.2060756],
          [116.854503, 7.2060999],
          [116.8544935, 7.2061373],
          [116.8545067, 7.2061597],
          [116.8545388, 7.2061691],
          [116.854552, 7.2061504],
          [116.8545463, 7.2061167],
          [116.8545746, 7.2061018],
          [116.8545784, 7.2061354],
          [116.854618, 7.206156],
          [116.8546462, 7.2061579],
          [116.8546877, 7.2061541],
          [116.8547292, 7.2061915],
          [116.8547725, 7.2062027],
          [116.8548027, 7.206199],
          [116.8548196, 7.2062364],
          [116.8548404, 7.2062644],
          [116.8548837, 7.2062476],
          [116.8549045, 7.2062233],
          [116.8549365, 7.2062813],
          [116.8549855, 7.2063205],
          [116.8550628, 7.2063673],
          [116.8550948, 7.2064028],
          [116.8551815, 7.2064701],
          [116.8552287, 7.206515],
          [116.8552871, 7.2065393],
          [116.8553191, 7.2065749],
          [116.8553361, 7.2066403],
          [116.8553399, 7.2066927],
          [116.8553719, 7.2067151],
          [116.8554172, 7.2067189],
          [116.8554888, 7.206745],
          [116.8554944, 7.2067955],
          [116.8555284, 7.206803],
          [116.8555755, 7.206803],
          [116.8555887, 7.2068311],
          [116.8556377, 7.2068797],
          [116.8556697, 7.2068984],
          [116.8556603, 7.2069283],
          [116.8556603, 7.2069507],
          [116.8557018, 7.2069807],
          [116.8557395, 7.2069732],
          [116.8557923, 7.206962],
          [116.8558243, 7.2069676],
          [116.8558337, 7.2070031],
          [116.8558186, 7.2070218],
          [116.8557734, 7.2070555],
          [116.8557941, 7.2070686],
          [116.8558545, 7.2070629],
          [116.8559204, 7.2070742],
          [116.8559638, 7.2070985],
          [116.8560052, 7.2071284],
          [116.8560147, 7.2071733],
          [116.8559845, 7.2071845],
          [116.8559619, 7.2072069],
          [116.8559299, 7.2072312],
          [116.8559619, 7.2072387],
          [116.8560128, 7.2072238],
          [116.8560429, 7.2072369],
          [116.8560844, 7.2072873],
          [116.8561259, 7.2073509],
          [116.8561843, 7.2074126],
          [116.856222, 7.2074874],
          [116.8562559, 7.2075304],
          [116.8563389, 7.2075884],
          [116.8564312, 7.2076277],
          [116.8564727, 7.207667],
          [116.8564878, 7.2077025],
          [116.8565726, 7.2077511],
          [116.8566423, 7.2077997],
          [116.8566838, 7.2078857],
          [116.8567064, 7.2079867],
          [116.8567536, 7.2080634],
          [116.8568629, 7.2081812],
          [116.8569929, 7.2082915],
          [116.8571984, 7.2084524],
          [116.8573247, 7.2085683],
          [116.8574981, 7.2087291],
          [116.8577299, 7.2089442],
          [116.857828, 7.2089741],
          [116.8579316, 7.2089947],
          [116.8580391, 7.2089928],
          [116.8581446, 7.2089891],
          [116.8582483, 7.2089704],
          [116.8583256, 7.2089236],
          [116.8584236, 7.2088656],
          [116.8584952, 7.2087703],
          [116.858531, 7.2086786],
          [116.8585348, 7.208544],
          [116.8585141, 7.208501],
          [116.8585084, 7.2084393],
          [116.8585159, 7.208372],
          [116.8585348, 7.2083196],
          [116.8586083, 7.2082672],
          [116.8586988, 7.208228],
          [116.8588025, 7.2082149],
          [116.8589118, 7.2082261],
          [116.85904, 7.2082616],
          [116.8591455, 7.2083046],
          [116.8592398, 7.208357],
          [116.8593547, 7.2084486],
          [116.8594414, 7.2085702],
          [116.8595036, 7.2087104],
          [116.8595187, 7.2088039],
          [116.8595263, 7.2088694],
          [116.8595036, 7.208946],
          [116.8594527, 7.2090265],
          [116.8594056, 7.2090358],
          [116.8593378, 7.2090377],
          [116.8593001, 7.2090339],
          [116.8592266, 7.2090208],
          [116.8591606, 7.209019],
          [116.8591078, 7.2090246],
          [116.8590569, 7.2090564],
          [116.8590475, 7.2091013],
          [116.8590079, 7.2091125],
          [116.8589721, 7.209133],
          [116.8589457, 7.2091723],
          [116.8589401, 7.2092097],
          [116.8589495, 7.2092359],
          [116.8590041, 7.2092378],
          [116.8590437, 7.2092097],
          [116.8590833, 7.2092097],
          [116.859104, 7.2092546],
          [116.859153, 7.2092452],
          [116.8591832, 7.2092415],
          [116.8592058, 7.2092602],
          [116.8591964, 7.2093144],
          [116.8591813, 7.20935],
          [116.8592134, 7.2093668],
          [116.859236, 7.209378],
          [116.8592812, 7.2093743],
          [116.859302, 7.2093369],
          [116.8593246, 7.2093406],
          [116.8593189, 7.2093874],
          [116.8593566, 7.2094379],
          [116.8594603, 7.2094453],
          [116.8595018, 7.2094322],
          [116.8595545, 7.2094135],
          [116.8595885, 7.2094135],
          [116.8596073, 7.209436],
          [116.8596431, 7.2094285],
          [116.8596544, 7.2093743],
          [116.8597317, 7.2093612],
          [116.8597958, 7.2093799],
          [116.8598618, 7.2094379],
          [116.8599428, 7.209565],
          [116.8600578, 7.2097632],
          [116.8601219, 7.2098792],
          [116.860235, 7.2100045],
          [116.8603707, 7.2101821],
          [116.8604593, 7.2103616],
          [116.8605799, 7.2105673],
          [116.8606892, 7.210788],
          [116.8607778, 7.2109544],
          [116.8608985, 7.2111695],
          [116.8610021, 7.2113733],
          [116.8610794, 7.2115154],
          [116.8611605, 7.2116819],
          [116.8612359, 7.211852],
          [116.8613, 7.2120035],
          [116.8613377, 7.2121101],
          [116.8613603, 7.2122055],
          [116.8613452, 7.2122746],
          [116.8613207, 7.2123438],
          [116.8613659, 7.2124186],
          [116.8614508, 7.2125308],
          [116.8615488, 7.212686],
          [116.8616769, 7.212888],
          [116.8617338, 7.2130507],
          [116.8617654, 7.2131664],
          [116.861767, 7.2131996],
          [116.8617712, 7.2132863],
          [116.861772716150625, 7.213323354721069],
          [116.8617787, 7.2134696],
          [116.8617712, 7.2135855],
          [116.8617467, 7.2137594],
          [116.8616901, 7.2139146],
          [116.8616015, 7.214025],
          [116.8615167, 7.214096],
          [116.8614833, 7.2141211],
          [116.861447, 7.2141484],
          [116.8613735, 7.2141615],
          [116.8613226, 7.2141353],
          [116.8612547, 7.2140493],
          [116.8611963, 7.2139745],
          [116.8611548, 7.2138997],
          [116.8611096, 7.2138529],
          [116.8611077, 7.2138941],
          [116.861102, 7.2139333],
          [116.8610851, 7.2139745],
          [116.8611039, 7.213995],
          [116.8611529, 7.2139838],
          [116.8611661, 7.21401],
          [116.8611586, 7.214053],
          [116.861185, 7.2140998],
          [116.8612057, 7.2141484],
          [116.8611303, 7.2141577],
          [116.8610888, 7.2141839],
          [116.8610549, 7.21424],
          [116.8610021, 7.2142755],
          [116.8609833, 7.214341],
          [116.8610266, 7.214427],
          [116.8611699, 7.2145542],
          [116.8612378, 7.2146009],
          [116.8613414, 7.2146888],
          [116.8614583, 7.2148085],
          [116.8616807, 7.2149543],
          [116.8617448, 7.2150011],
          [116.8618221, 7.2150535],
          [116.8618673, 7.2150478],
          [116.8618051, 7.2149843],
          [116.8617259, 7.2149188],
          [116.8616901, 7.2148758],
          [116.8617523, 7.2148291],
          [116.8618654, 7.2147842],
          [116.8619898, 7.2147823],
          [116.862167, 7.2148216],
          [116.8623122, 7.2149319],
          [116.8624384, 7.2150703],
          [116.8625478, 7.2153022],
          [116.8626045, 7.2154318],
          [116.862659, 7.2155565],
          [116.8626862, 7.2156429],
          [116.8627495, 7.2158445],
          [116.8628079, 7.2160857],
          [116.8628607, 7.2163232],
          [116.8629304, 7.2165887],
          [116.8630152, 7.2168973],
          [116.8630699, 7.2171684],
          [116.8631114, 7.2173891],
          [116.8631792, 7.2176004],
          [116.8632094, 7.2178304],
          [116.8632621, 7.2181744],
          [116.8633206, 7.2184624],
          [116.8633602, 7.2187897],
          [116.8634092, 7.2189467],
          [116.8634676, 7.2192497],
          [116.8635411, 7.2194647],
          [116.8636165, 7.2197246],
          [116.8636523, 7.2199696],
          [116.8637164, 7.2202482],
          [116.8637579, 7.2205269],
          [116.8637975, 7.2207905],
          [116.8638578, 7.2209906],
          [116.8638823, 7.2212],
          [116.8639011, 7.2214263],
          [116.8639501, 7.2217311],
          [116.864001, 7.2219873],
          [116.8640161, 7.2222117],
          [116.8640482, 7.2224604],
          [116.8640727, 7.2226941],
          [116.8641367, 7.2229672],
          [116.8641895, 7.2232645],
          [116.8642291, 7.2235412],
          [116.8642819, 7.223818],
          [116.8643328, 7.224149],
          [116.8643648, 7.2243453],
          [116.8643742, 7.2245585],
          [116.864378, 7.2247586],
          [116.8643931, 7.2249156],
          [116.8644421, 7.2251419],
          [116.8644515, 7.2252915],
          [116.8644534, 7.225486],
          [116.8645213, 7.2255776],
          [116.8646136, 7.2257216],
          [116.8647437, 7.225903],
          [116.8647682, 7.2259273],
          [116.8649228, 7.2261797],
          [116.8650792, 7.2264471],
          [116.8652545, 7.2268005],
          [116.8653337, 7.2270081],
          [116.8654091, 7.2272026],
          [116.8654939, 7.2273896],
          [116.8655674, 7.2276046],
          [116.8656824, 7.2278963],
          [116.8657691, 7.2281132],
          [116.8658388, 7.2282666],
          [116.8659349, 7.2285227],
          [116.865999, 7.2287116],
          [116.8660443, 7.2289005],
          [116.8660895, 7.2290725],
          [116.8661385, 7.2292539],
          [116.8661649, 7.2293997],
          [116.8662573, 7.2295849],
          [116.8663025, 7.2297457],
          [116.8663496, 7.2299215],
          [116.8663798, 7.2300168],
          [116.8664307, 7.2302319],
          [116.8665023, 7.2305404],
          [116.8665696, 7.2307669],
          [116.8665913, 7.230909],
          [116.8666328, 7.2311081],
          [116.8666318, 7.2312418],
          [116.8666639, 7.2314466],
          [116.8666714, 7.2315345],
          [116.8667223, 7.2316383],
          [116.8667316, 7.2317745],
          [116.8667663, 7.2319675],
          [116.8667769, 7.2320627],
          [116.8667929, 7.2322743],
          [116.8668049, 7.2324025],
          [116.8668223, 7.2325162],
          [116.8668303, 7.2326564],
          [116.8668383, 7.2327793],
          [116.8668409, 7.232901],
          [116.8668489, 7.2330134],
          [116.8668516, 7.2331099],
          [116.8668489, 7.2332302],
          [116.8668476, 7.2333519],
          [116.8668529, 7.2335238],
          [116.8668596, 7.2336957],
          [116.8668583, 7.233779],
          [116.8668489, 7.2338861],
          [116.866853751919166, 7.233969211578369],
          [116.8668543, 7.2339786],
          [116.8668596, 7.2341003],
          [116.8668756, 7.2342483],
          [116.8668796, 7.2344189],
          [116.8668862, 7.2345723],
          [116.8668876, 7.2347957],
          [116.8668862, 7.2349147],
          [116.8668916, 7.2351263],
          [116.8669022, 7.2353603],
          [116.8669089, 7.2355058],
          [116.8669182, 7.2357067],
          [116.8669169, 7.2358839],
          [116.8669249, 7.2360637],
          [116.8669169, 7.2361814],
          [116.8669142, 7.2362965],
          [116.8669156, 7.2364459],
          [116.8668982, 7.2366468],
          [116.8668849, 7.2368531],
          [116.8668836, 7.2370633],
          [116.8668836, 7.2372379],
          [116.8668647, 7.2376749],
          [116.8668543, 7.2378198],
          [116.8668581, 7.2380068],
          [116.8668241, 7.2381957],
          [116.8668128, 7.2383602],
          [116.8667695, 7.2385453],
          [116.8667355, 7.2387697],
          [116.866728, 7.238895],
          [116.8667129, 7.2390558],
          [116.8667073, 7.2391456],
          [116.8666809, 7.2393064],
          [116.8666639, 7.2394279],
          [116.8666545, 7.239585],
          [116.8666601, 7.239671],
          [116.866711, 7.2397271],
          [116.8667469, 7.2397365],
          [116.8667977, 7.2396916],
          [116.8668147, 7.2397252],
          [116.8668336, 7.2398113],
          [116.8668373, 7.2398935],
          [116.8668939, 7.2399347],
          [116.8668543, 7.240088],
          [116.866794, 7.2401235],
          [116.8667242, 7.2401254],
          [116.8667016, 7.2400861],
          [116.8667073, 7.2400394],
          [116.866728, 7.2399422],
          [116.8667469, 7.2398954],
          [116.866745, 7.2398412],
          [116.8666978, 7.2398524],
          [116.8666865, 7.2399235],
          [116.8666507, 7.2399945],
          [116.8666055, 7.2400805],
          [116.8665904, 7.2401684],
          [116.8665452, 7.2403629],
          [116.8664886, 7.2405723],
          [116.8663812, 7.2409444],
          [116.8663039, 7.241195],
          [116.8662323, 7.24141],
          [116.8661437, 7.2416269],
          [116.8660362, 7.2418569],
          [116.8659458, 7.2420196],
          [116.865844, 7.2422066],
          [116.8656856, 7.242401],
          [116.8655876, 7.2425319],
          [116.8655122, 7.2426797],
          [116.8654067, 7.242919],
          [116.8653332, 7.2431153],
          [116.8652823, 7.2432855],
          [116.8652974, 7.2434444],
          [116.8653294, 7.2435155],
          [116.865401, 7.2435884],
          [116.8654858, 7.2436295],
          [116.8656065, 7.2436595],
          [116.8657497, 7.243723],
          [116.8658591, 7.243781],
          [116.8660777, 7.2438708],
          [116.8662191, 7.2439362],
          [116.8664151, 7.2440203],
          [116.8666319, 7.2441101],
          [116.8668166, 7.2441999],
          [116.8670371, 7.2443345],
          [116.8671841, 7.2444317],
          [116.8673274, 7.2445177],
          [116.8674424, 7.2446262],
          [116.8675988, 7.2447945],
          [116.8676893, 7.2449571],
          [116.8678043, 7.2450675],
          [116.8678816, 7.245204],
          [116.8679513, 7.2453255],
          [116.8680097, 7.2454657],
          [116.8681247, 7.2456658],
          [116.8682567, 7.2458696],
          [116.8683547, 7.246051],
          [116.8684621, 7.2461931],
          [116.8685469, 7.2463857],
          [116.8686563, 7.2465503],
          [116.8687769, 7.2467223],
          [116.8688881, 7.2469579],
          [116.8689748, 7.2471168],
          [116.8690634, 7.2473001],
          [116.869135, 7.2474852],
          [116.8692029, 7.2476161],
          [116.8692858, 7.2477544],
          [116.8693593, 7.2479096],
          [116.86948, 7.2481172],
          [116.869578, 7.2482911],
          [116.8696421, 7.248407],
          [116.8697382, 7.248523],
          [116.8698381, 7.248652],
          [116.8699606, 7.2487866],
          [116.8700982, 7.2489381],
          [116.8702358, 7.2490895],
          [116.8703489, 7.2492653],
          [116.8704884, 7.2494616],
          [116.8706335, 7.2496823],
          [116.8707278, 7.2498169],
          [116.870839, 7.2499964],
          [116.8709087, 7.2501161],
          [116.8710181, 7.2502413],
          [116.8711293, 7.2504358],
          [116.8712065, 7.2506078],
          [116.8712819, 7.2507537],
          [116.8713555, 7.2509351],
          [116.8714459, 7.2511725],
          [116.8715364, 7.2513969],
          [116.8716118, 7.2515932],
          [116.8716891, 7.2517858],
          [116.8717569, 7.2520139],
          [116.8717965, 7.2521467],
          [116.8718474, 7.2523356],
          [116.8718663, 7.2524739],
          [116.8718663, 7.2526272],
          [116.8718436, 7.2527656],
          [116.8718003, 7.2529694],
          [116.8717758, 7.2532331],
          [116.8717984, 7.2533714],
          [116.8718229, 7.253536],
          [116.8718832, 7.2536538],
          [116.8719605, 7.2537865],
          [116.872051, 7.2539118],
          [116.8721396, 7.2540221],
          [116.8721924, 7.2541979],
          [116.8722112, 7.2543213],
          [116.8722263, 7.254398],
          [116.8722998, 7.2544896],
          [116.8723846, 7.2546766],
          [116.8722998, 7.2548692],
          [116.8722678, 7.2548262],
          [116.8722357, 7.2548262],
          [116.8722546, 7.2549159],
          [116.8722828, 7.2550337],
          [116.8723149, 7.2551796],
          [116.8723582, 7.2553104],
          [116.8724016, 7.2554301],
          [116.8724638, 7.2556508],
          [116.8725128, 7.2558658],
          [116.8725467, 7.2560228],
          [116.872607, 7.2561836],
          [116.8726768, 7.2563893],
          [116.872722, 7.2565277],
          [116.8727918, 7.2567091],
          [116.8728596, 7.2568624],
          [116.8729067, 7.2569914],
          [116.8729708, 7.2571186],
          [116.87305, 7.2572625],
          [116.8731423, 7.2574028],
          [116.8732064, 7.2575673],
          [116.8732781, 7.2576795],
          [116.8733553, 7.2578179],
          [116.8734307, 7.2579712],
          [116.8734628, 7.258089],
          [116.8735156, 7.2582199],
          [116.8735589, 7.258347],
          [116.8736475, 7.2585564],
          [116.8736682, 7.2586163],
          [116.8737606, 7.2587902],
          [116.8738153, 7.2588837],
          [116.8738925, 7.2590258],
          [116.8739359, 7.2591529],
          [116.8740019, 7.2592744],
          [116.8740923, 7.2594016],
          [116.8742375, 7.2595586],
          [116.8743638, 7.259697],
          [116.8744693, 7.2598073],
          [116.874556, 7.2598952],
          [116.8746804, 7.2600093],
          [116.8747577, 7.2601196],
          [116.8748576, 7.2602467],
          [116.8749594, 7.2603664],
          [116.8750612, 7.2605216],
          [116.8751385, 7.2606413],
          [116.875244, 7.2607721],
          [116.8753552, 7.2609012],
          [116.8754853, 7.2610283],
          [116.8756474, 7.2611498],
          [116.8757793, 7.2612265],
          [116.875932, 7.2613275],
          [116.8760847, 7.2614434],
          [116.8762543, 7.2615182],
          [116.8763957, 7.2615724],
          [116.8765088, 7.2616173],
          [116.8765842, 7.2616659],
          [116.8766502, 7.261737],
          [116.8767557, 7.2618192],
          [116.8768292, 7.2618922],
          [116.8769272, 7.2619875],
          [116.8770008, 7.2620436],
          [116.877078, 7.2620698],
          [116.8771629, 7.2620586],
          [116.87721, 7.2620286],
          [116.8772967, 7.2620043],
          [116.8774475, 7.2623484],
          [116.8774456, 7.2624063],
          [116.8774004, 7.262526],
          [116.8773909, 7.2626588],
          [116.877374, 7.2627018],
          [116.8773363, 7.2626999],
          [116.8773023, 7.262655],
          [116.877242, 7.2625802],
          [116.8772062, 7.2625185],
          [116.8771742, 7.2624587],
          [116.877112, 7.2624456],
          [116.8769932, 7.2624232],
          [116.8769367, 7.2624063],
          [116.8768443, 7.2623484],
          [116.876767, 7.2622624],
          [116.8766747, 7.2621521],
          [116.8766012, 7.2620754],
          [116.8764918, 7.2619875],
          [116.8764862, 7.2620305],
          [116.8765333, 7.2621072],
          [116.8766275, 7.2622381],
          [116.8767444, 7.2623914],
          [116.8767915, 7.262468],
          [116.8768556, 7.2625653],
          [116.8769555, 7.2626756],
          [116.8770366, 7.2627859],
          [116.8771195, 7.2629018],
          [116.8772364, 7.2630421],
          [116.8773589, 7.2631673],
          [116.877472, 7.263302],
          [116.8775926, 7.2634254],
          [116.8777396, 7.2635881],
          [116.8778923, 7.2637619],
          [116.8779941, 7.2638704],
          [116.8781223, 7.2640125],
          [116.8782486, 7.2641733],
          [116.8783654, 7.2643023],
          [116.8785351, 7.2644912],
          [116.8786632, 7.2646576],
          [116.8787839, 7.2647997],
          [116.8788951, 7.2648894],
          [116.879012, 7.2650072],
          [116.8791156, 7.2651082],
          [116.8791986, 7.2651886],
          [116.8793041, 7.2653064],
          [116.8794606, 7.2654466],
          [116.8795708, 7.2655503],
          [116.8797121, 7.2656521],
          [116.8798187, 7.2657248],
          [116.8799, 7.2658094],
          [116.8800093, 7.2659442],
          [116.8801253, 7.2660646],
          [116.8802506, 7.266206],
          [116.8803599, 7.2663409],
          [116.8804452, 7.2664757],
          [116.8805598, 7.2666278],
          [116.8806224, 7.2667296],
          [116.8807104, 7.2668499],
          [116.8807757, 7.2669596],
          [116.880857, 7.2670733],
          [116.8809237, 7.2672346],
          [116.8809783, 7.2673708],
          [116.8810316, 7.2675255],
          [116.8810916, 7.2676815],
          [116.8811236, 7.2678124],
          [116.8811396, 7.2678865],
          [116.8811782, 7.2680557],
          [116.8812302, 7.268209],
          [116.8812595, 7.2683373],
          [116.8812888, 7.2684682],
          [116.8813222, 7.2685964],
          [116.8813542, 7.2687657],
          [116.8813795, 7.2688556],
          [116.8813995, 7.2689746],
          [116.8814115, 7.2691359],
          [116.8814155, 7.2692853],
          [116.8814115, 7.2694479],
          [116.8814155, 7.2696171],
          [116.8814221, 7.2698048],
          [116.8814301, 7.2699609],
          [116.8814315, 7.2700521],
          [116.8814315, 7.2701526],
          [116.8814341, 7.2702332],
          [116.8814421, 7.2703205],
          [116.8814435, 7.2704223],
          [116.8814381, 7.2705122],
          [116.8814341, 7.2706021],
          [116.8814155, 7.2707198],
          [116.8814088, 7.2708242],
          [116.8814075, 7.2708877],
          [116.8813795, 7.2709868],
          [116.8813302, 7.271049],
          [116.8812742, 7.2710807],
          [116.8812222, 7.2711137],
          [116.8811942, 7.2711574],
          [116.8811502, 7.2712446],
          [116.8810996, 7.2713319],
          [116.8810623, 7.2714059],
          [116.8810116, 7.2714786],
          [116.880905, 7.2715408],
          [116.880869, 7.2715738],
          [116.880825, 7.2716201],
          [116.8808024, 7.271669],
          [116.8807637, 7.2716902],
          [116.8807411, 7.2717722],
          [116.8807211, 7.2718541],
          [116.8807517, 7.2718951],
          [116.8807797, 7.2719321],
          [116.880789, 7.2720247],
          [116.8807677, 7.2721622],
          [116.880753, 7.2722164],
          [116.8807317, 7.2722666],
          [116.8807464, 7.2723208],
          [116.8808184, 7.2723988],
          [116.8808597, 7.2724385],
          [116.8808877, 7.2724464],
          [116.880929, 7.2724636],
          [116.8810196, 7.2724504],
          [116.8810609, 7.27242],
          [116.8811116, 7.2723869],
          [116.8811622, 7.2723777],
          [116.8812342, 7.2723935],
          [116.8812755, 7.2724279],
          [116.8812809, 7.2724768],
          [116.8812235, 7.2725231],
          [116.8811689, 7.2725443],
          [116.8811782, 7.2726738],
          [116.8811862, 7.2727373],
          [116.8812275, 7.2728669],
          [116.8812582, 7.2730057],
          [116.8812835, 7.2731022],
          [116.8812968, 7.2731987],
          [116.8813128, 7.2733322],
          [116.8813195, 7.2734459],
          [116.8813222, 7.2735795],
          [116.8813142, 7.2736628],
          [116.8813008, 7.273791],
          [116.8812942, 7.2738373],
          [116.8812809, 7.2739179],
          [116.8812662, 7.2739907],
          [116.8812475, 7.2740687],
          [116.8812035, 7.2741757],
          [116.8811276, 7.2743106],
          [116.8810236, 7.2744653],
          [116.8809623, 7.2745208],
          [116.880869, 7.2745737],
          [116.880753, 7.2746134],
          [116.8807171, 7.2745949],
          [116.8806784, 7.2745631],
          [116.8806384, 7.2745539],
          [116.8805518, 7.2745552],
          [116.8804772, 7.2745393],
          [116.8803692, 7.2745155],
          [116.8803172, 7.2744997],
          [116.8802572, 7.2744851],
          [116.8801346, 7.2744653],
          [116.8800826, 7.2744468],
          [116.8799813, 7.2744084],
          [116.8798401, 7.2744124],
          [116.8797881, 7.2744032],
          [116.8796801, 7.2743741],
          [116.8795575, 7.2743569],
          [116.8794495, 7.2743463],
          [116.8793069, 7.274341],
          [116.8791816, 7.2743648],
          [116.879111, 7.2743741],
          [116.879055, 7.274382],
          [116.879027, 7.2743979],
          [116.8789924, 7.2744415],
          [116.8789484, 7.27446],
          [116.8789484, 7.2744997],
          [116.8789844, 7.2745935],
          [116.879015, 7.2746345],
          [116.8790444, 7.2746702],
          [116.8790617, 7.2747138],
          [116.8791097, 7.2747495],
          [116.8791337, 7.2747641],
          [116.8792096, 7.2747694],
          [116.8792469, 7.2747866],
          [116.8792896, 7.2748236],
          [116.8793562, 7.2748712],
          [116.8793962, 7.274887],
          [116.8794495, 7.2749188],
          [116.8795002, 7.2749412],
          [116.8795255, 7.2749783],
          [116.8795548, 7.2750034],
          [116.8795948, 7.2750616],
          [116.8796441, 7.2750959],
          [116.8796961, 7.2751171],
          [116.8797561, 7.2751184],
          [116.8798054, 7.2750999],
          [116.8798361, 7.2750986],
          [116.8798387, 7.2751488],
          [116.8798094, 7.2751805],
          [116.8798214, 7.2752506],
          [116.879823276340005, 7.275260925292969],
          [116.8798387, 7.2753458],
          [116.8798641, 7.2753564],
          [116.8798747, 7.2753947],
          [116.8799, 7.2754159],
          [116.8799933, 7.2754212],
          [116.880084, 7.2753947],
          [116.8800986, 7.2753842],
          [116.8801626, 7.2753749],
          [116.8802199, 7.2753643],
          [116.8802599, 7.2753379],
          [116.8802772, 7.2753048],
          [116.8803212, 7.2752771],
          [116.8803692, 7.2752771],
          [116.8804225, 7.2752876],
          [116.8804945, 7.2752929],
          [116.8805638, 7.2752916],
          [116.8806211, 7.2752956],
          [116.8806478, 7.2753035],
          [116.8807131, 7.2753524],
          [116.8807424, 7.2754013],
          [116.8807757, 7.2754714],
          [116.8807984, 7.2755018],
          [116.8808024, 7.2755375],
          [116.880785, 7.2756129],
          [116.880769, 7.2756512],
          [116.8807544, 7.2756935],
          [116.880753, 7.2757451],
          [116.8807544, 7.2757781],
          [116.880781, 7.2758138],
          [116.8807797, 7.2758495],
          [116.8807744, 7.2758971],
          [116.880789, 7.2759196],
          [116.8808264, 7.2759328],
          [116.8808703, 7.2759381],
          [116.8808997, 7.27595],
          [116.8809277, 7.2759712],
          [116.880965, 7.2760069],
          [116.8810183, 7.2760452],
          [116.8811076, 7.276065],
          [116.8811636, 7.2760743],
          [116.8812209, 7.2760664],
          [116.8812502, 7.2760518],
          [116.8812782, 7.2760452],
          [116.8813435, 7.2760426],
          [116.8814155, 7.2760412],
          [116.8814581, 7.2760227],
          [116.8814954, 7.2760082],
          [116.8815741, 7.2759791],
          [116.8816154, 7.2759646],
          [116.8816674, 7.2759381],
          [116.8817074, 7.2759328],
          [116.88175, 7.2759474],
          [116.8817993, 7.2759765],
          [116.8818326, 7.2760148],
          [116.8818713, 7.2760492],
          [116.8819286, 7.2761007],
          [116.8819646, 7.2761311],
          [116.8820099, 7.2761655],
          [116.8820472, 7.2761893],
          [116.8821152, 7.276229],
          [116.8821712, 7.2762832],
          [116.8821792, 7.2763361],
          [116.8821645, 7.2763718],
          [116.8821659, 7.2763863],
          [116.8821978, 7.2764246],
          [116.8822285, 7.2764722],
          [116.8822605, 7.2765079],
          [116.8822911, 7.2765516],
          [116.8823191, 7.2765926],
          [116.8823724, 7.2766666],
          [116.8824178, 7.2767168],
          [116.8824444, 7.2767856],
          [116.8824897, 7.2768623],
          [116.8825497, 7.2769456],
          [116.8825857, 7.2769945],
          [116.8826337, 7.2770711],
          [116.882671, 7.2771373],
          [116.8827136, 7.2771994],
          [116.8827656, 7.2773038],
          [116.8828163, 7.2774202],
          [116.8828723, 7.2775061],
          [116.8829096, 7.2775828],
          [116.8829322, 7.2776304],
          [116.8829602, 7.2776925],
          [116.8829815, 7.2777269],
          [116.8829989, 7.2777666],
          [116.8830109, 7.2778102],
          [116.8830242, 7.2778618],
          [116.8830389, 7.2779041],
          [116.8830748, 7.2779583],
          [116.8831108, 7.2780429],
          [116.8831402, 7.2781407],
          [116.8831628, 7.27822],
          [116.8831908, 7.2783007],
          [116.8832188, 7.2783866],
          [116.8832454, 7.278466],
          [116.8832614, 7.2785307],
          [116.8832761, 7.2786074],
          [116.8832908, 7.2786603],
          [116.8833054, 7.2787198],
          [116.8833054, 7.2787833],
          [116.8833041, 7.2788837],
          [116.8832961, 7.2789684],
          [116.8832561, 7.2790464],
          [116.8832321, 7.2790794],
          [116.8832055, 7.2791336],
          [116.8831775, 7.2791786],
          [116.8831508, 7.2792275],
          [116.8831415, 7.2792857],
          [116.8831388, 7.2793306],
          [116.8831681, 7.2793875],
          [116.8832028, 7.2794364],
          [116.8832321, 7.2795104],
          [116.8832641, 7.2795646],
          [116.8833041, 7.2796135],
          [116.8833294, 7.2796638],
          [116.8833454, 7.2797286],
          [116.8833721, 7.279788],
          [116.8833947, 7.2798581],
          [116.8834027, 7.2799189],
          [116.8834014, 7.2800049],
          [116.8833947, 7.2800564],
          [116.8833627, 7.2801001],
          [116.8833627, 7.2801305],
          [116.8833747, 7.2801463],
          [116.8833694, 7.2801926],
          [116.8833534, 7.2802481],
          [116.8833307, 7.2802984],
          [116.8833081, 7.2803539],
          [116.8832881, 7.2804147],
          [116.8832894, 7.2804835],
          [116.8832961, 7.2805324],
          [116.8833121, 7.2805853],
          [116.8833281, 7.2806183],
          [116.8833587, 7.2806699],
          [116.8834054, 7.2807307],
          [116.8834507, 7.2807717],
          [116.883512, 7.2808325],
          [116.8835627, 7.2808722],
          [116.8836546, 7.2809356],
          [116.8837319, 7.2809872],
          [116.8837439, 7.2810149],
          [116.8837466, 7.2810678],
          [116.8837613, 7.2811194],
          [116.8837599, 7.2811921],
          [116.8837746, 7.2812397],
          [116.8838052, 7.2812913],
          [116.8838812, 7.281397],
          [116.8839092, 7.281479],
          [116.8839465, 7.281557],
          [116.8839918, 7.2816139],
          [116.8840265, 7.2816614],
          [116.8840518, 7.2817183],
          [116.8840678, 7.2817725],
          [116.8840745, 7.2818082],
          [116.8841038, 7.2818822],
          [116.8841491, 7.2819787],
          [116.8841638, 7.2820435],
          [116.8841811, 7.2821334],
          [116.8841944, 7.2821942],
          [116.8842091, 7.2822656],
          [116.8842424, 7.282345],
          [116.8842957, 7.2824864],
          [116.884337, 7.282571],
          [116.884369, 7.2826319],
          [116.8844423, 7.2827627],
          [116.8844916, 7.2829029],
          [116.8845183, 7.2829716],
          [116.8845396, 7.2830443],
          [116.8845663, 7.2831197],
          [116.8845929, 7.2831898],
          [116.8846223, 7.2832731],
          [116.8846463, 7.2833801],
          [116.8846966, 7.2835847],
          [116.884723, 7.2836856],
          [116.884756, 7.2837894],
          [116.8847805, 7.2838717],
          [116.8848134, 7.2839689],
          [116.8848521, 7.28411],
          [116.8848964, 7.2842213],
          [116.8849501, 7.2843671],
          [116.8849765, 7.2844494],
          [116.8850076, 7.2845158],
          [116.885033, 7.2845653],
          [116.8850726, 7.284627],
          [116.8851188, 7.2847055],
          [116.8851518, 7.284785],
          [116.8851923, 7.2848514],
          [116.8852734, 7.2849467],
          [116.8853619, 7.2850337],
          [116.8854194, 7.2850879],
          [116.8854618, 7.2851281],
          [116.885557, 7.2852066],
          [116.8856183, 7.2852702],
          [116.8856796, 7.2853431],
          [116.8857578, 7.2854216],
          [116.8858143, 7.2854684],
          [116.8858869, 7.2855132],
          [116.885934, 7.2855656],
          [116.886, 7.2856254],
          [116.8860848, 7.2856899],
          [116.8861395, 7.2857227],
          [116.8862234, 7.2857853],
          [116.8862742, 7.2858451],
          [116.8863308, 7.2859124],
          [116.8863845, 7.285948],
          [116.8864335, 7.2859769],
          [116.8864703, 7.2860068],
          [116.8865089, 7.2860368],
          [116.8865655, 7.2860685],
          [116.8866352, 7.2861078],
          [116.8866635, 7.2861331],
          [116.8866842, 7.286163],
          [116.8867313, 7.2862106],
          [116.8867907, 7.2862471],
          [116.8868473, 7.2862817],
          [116.8869349, 7.2863256],
          [116.8870075, 7.286363],
          [116.8870744, 7.2863948],
          [116.8871215, 7.2864275],
          [116.8871564, 7.2864472],
          [116.8871894, 7.2864808],
          [116.8872271, 7.2865219],
          [116.887261, 7.2865752],
          [116.8872761, 7.2866117],
          [116.8873025, 7.2866715],
          [116.887327, 7.2867426],
          [116.8873534, 7.2867996],
          [116.8873769, 7.2868538],
          [116.8873967, 7.2869351],
          [116.8873882, 7.2869968],
          [116.8873845, 7.2870969],
          [116.8873854, 7.287138],
          [116.8873863, 7.2872025],
          [116.8873826, 7.2872726],
          [116.8873666, 7.287353],
          [116.8873468, 7.2874175],
          [116.8873185, 7.2874736],
          [116.8872893, 7.2875278],
          [116.8872544, 7.2875942],
          [116.8872167, 7.2876718],
          [116.8872092, 7.2877625],
          [116.8872139, 7.2878064],
          [116.8872252, 7.2878606],
          [116.8872393, 7.2879139],
          [116.8872478, 7.2879476],
          [116.8872563, 7.2879971],
          [116.8872855, 7.2880327],
          [116.8873185, 7.2880691],
          [116.8873411, 7.2881149],
          [116.8873666, 7.2881701],
          [116.8873797, 7.2882336],
          [116.8873995, 7.2882972],
          [116.887424, 7.288357],
          [116.8874363, 7.2884038],
          [116.8874523, 7.2884627],
          [116.8874627, 7.2885038],
          [116.8874683, 7.2885552],
          [116.8874674, 7.2885982],
          [116.8874617, 7.2886562],
          [116.8874608, 7.2886683],
          [116.8874363, 7.2887618],
          [116.8874316, 7.2888067],
          [116.8874174, 7.2888637],
          [116.8874156, 7.2889021],
          [116.8874222, 7.2889338],
          [116.8874382, 7.2889563],
          [116.8874636, 7.2889768],
          [116.8874759, 7.2889815],
          [116.8875287, 7.2889918],
          [116.8875682, 7.2889909],
          [116.8876135, 7.2889862],
          [116.8876766, 7.2889694],
          [116.8877416, 7.2889479],
          [116.8877596, 7.2889282],
          [116.8878029, 7.2888273],
          [116.8878095, 7.2887815],
          [116.8878001, 7.2886721],
          [116.8877539, 7.2885898],
          [116.8877256, 7.2885562],
          [116.8876955, 7.2885085],
          [116.8876644, 7.288429],
          [116.8876587, 7.2883935],
          [116.8876549, 7.2883449],
          [116.8876191, 7.2882177],
          [116.8875965, 7.2881532],
          [116.887588, 7.2881018],
          [116.887588, 7.2880654],
          [116.8875965, 7.2880046],
          [116.8876154, 7.2879336],
          [116.8876295, 7.2878859],
          [116.8876568, 7.2878289],
          [116.8876757, 7.2878036],
          [116.8876974, 7.2877718],
          [116.8877266, 7.2877363],
          [116.8877624, 7.2876998],
          [116.8877907, 7.2876709],
          [116.8878378, 7.2876307],
          [116.8878783, 7.2876036],
          [116.887932, 7.2875634],
          [116.887965, 7.2875391],
          [116.8880357, 7.2875073],
          [116.8880819, 7.2874895],
          [116.8881469, 7.2874839],
          [116.8881695, 7.2874867],
          [116.8882138, 7.2875035],
          [116.8882534, 7.2875297],
          [116.888276, 7.2875465],
          [116.8883345, 7.287597],
          [116.8883853, 7.2876353],
          [116.8884268, 7.2876578],
          [116.8884852, 7.2876858],
          [116.8885107, 7.2877176],
          [116.8885305, 7.2877522],
          [116.8885569, 7.2877961],
          [116.888571, 7.2878074],
          [116.8886049, 7.287841],
          [116.8886238, 7.2878634],
          [116.8886407, 7.2878793],
          [116.8886643, 7.287898],
          [116.8886888, 7.2879279],
          [116.888702, 7.287942],
          [116.8887265, 7.2879663],
          [116.8887538, 7.2879953],
          [116.8887783, 7.2880252],
          [116.8887972, 7.2880551],
          [116.8888085, 7.2880925],
          [116.888817, 7.288128],
          [116.8888208, 7.2881673],
          [116.8888283, 7.2881981],
          [116.8888368, 7.2882346],
          [116.8888274, 7.2882458],
          [116.8888236, 7.2882748],
          [116.8888066, 7.2883028],
          [116.8888217, 7.2883178],
          [116.8888415, 7.2883253],
          [116.8888886, 7.2883225],
          [116.8889037, 7.2883243],
          [116.8889122, 7.2883411],
          [116.8889282, 7.2883636],
          [116.8889508, 7.2883673],
          [116.8889763, 7.2883561],
          [116.8889876, 7.2883505],
          [116.8890036, 7.2883533],
          [116.8890338, 7.2883823],
          [116.8890781, 7.2883787],
          [116.8891648, 7.2883488],
          [116.889244, 7.2883002],
          [116.8893081, 7.2882553],
          [116.8893533, 7.2882216],
          [116.8894023, 7.2881581],
          [116.8894589, 7.2880796],
          [116.8894702, 7.2879674],
          [116.8894551, 7.2878253],
          [116.889425, 7.2877505],
          [116.8894551, 7.2876832],
          [116.8895343, 7.2875934],
          [116.8895343, 7.2875112],
          [116.8895871, 7.2874476],
          [116.8896662, 7.2874065],
          [116.8898924, 7.2873392],
          [116.8900432, 7.2873242],
          [116.8901563, 7.2873242],
          [116.8903297, 7.2873317],
          [116.8904956, 7.2873317],
          [116.8906275, 7.2873616],
          [116.8907896, 7.2874252],
          [116.8909027, 7.2874813],
          [116.8909668, 7.2875074],
          [116.8910271, 7.2875373],
          [116.8910837, 7.2875897],
          [116.8911365, 7.2876495],
          [116.8911742, 7.2877094],
          [116.8912006, 7.2877729],
          [116.8912307, 7.2878627],
          [116.8912759, 7.2879823],
          [116.8914079, 7.2881132],
          [116.8914456, 7.2881282],
          [116.8915549, 7.2881057],
          [116.8915851, 7.2880347],
          [116.8916039, 7.2879786],
          [116.8916793, 7.2879225],
          [116.8917547, 7.2879524],
          [116.8917698, 7.2879973],
          [116.8918, 7.2880459],
          [116.891849, 7.2880646],
          [116.8919357, 7.2880272],
          [116.8919998, 7.2879449],
          [116.8920261, 7.2878515],
          [116.8920525, 7.287743],
          [116.8921355, 7.287657],
          [116.8922033, 7.2876383],
          [116.892339, 7.2876383],
          [116.8924484, 7.2876495],
          [116.8925954, 7.2877131],
          [116.8927047, 7.2877692],
          [116.8927499, 7.2878215],
          [116.892799, 7.2878926],
          [116.8928065, 7.2879636],
          [116.8928216, 7.2880459],
          [116.892863, 7.2880272],
          [116.8929234, 7.2879075],
          [116.892995, 7.2879337],
          [116.8930553, 7.2879861],
          [116.8931609, 7.288102],
          [116.8932249, 7.2882291],
          [116.8933305, 7.2882777],
          [116.8934021, 7.2882964],
          [116.8935454, 7.2882478],
          [116.8936698, 7.2882104],
          [116.8938394, 7.2881169],
          [116.8939299, 7.2880571],
          [116.8941184, 7.2879562],
          [116.894239, 7.2878739],
          [116.894386, 7.2877841],
          [116.8945142, 7.2877056],
          [116.8946424, 7.2876308],
          [116.8947743, 7.2875598],
          [116.8949478, 7.2874925],
          [116.8951099, 7.2874139],
          [116.8952757, 7.2873766],
          [116.8955434, 7.2873205],
          [116.8957771, 7.2872606],
          [116.8959731, 7.2872457],
          [116.8962634, 7.2872045],
          [116.8965348, 7.2871933],
          [116.8968289, 7.2872158],
          [116.8971682, 7.2872494],
          [116.8973868, 7.2872569],
          [116.8976092, 7.2872868],
          [116.8979259, 7.2873354],
          [116.8982275, 7.2873878],
          [116.8985065, 7.2874364],
          [116.8989362, 7.2875336],
          [116.8991134, 7.2875785],
          [116.8993509, 7.287657],
          [116.8996299, 7.2877767],
          [116.8997995, 7.2878776],
          [116.9000445, 7.2879935],
          [116.9003537, 7.2881356],
          [116.9006364, 7.2882964],
          [116.900889, 7.2884535],
          [116.9011793, 7.288633],
          [116.9014658, 7.2888125],
          [116.9016467, 7.2889583],
          [116.9020463, 7.2892238],
          [116.90228, 7.2893921],
          [116.9024308, 7.2895304],
          [116.9026231, 7.2897099],
          [116.9027927, 7.289867],
          [116.902887, 7.2899567],
          [116.9030378, 7.2900801],
          [116.9031886, 7.2902372],
          [116.9033771, 7.2904017],
          [116.9035995, 7.2905774],
          [116.9037842, 7.2907121],
          [116.9040368, 7.2908841],
          [116.904361, 7.2910823],
          [116.9044741, 7.2911907],
          [116.9046249, 7.2913365],
          [116.904787, 7.2914749],
          [116.9049529, 7.291617],
          [116.9051376, 7.2918114],
          [116.9052582, 7.2919273],
          [116.9053638, 7.292062],
          [116.9054995, 7.2922003],
          [116.905639, 7.2923274],
          [116.9057784, 7.2925406],
          [116.9059669, 7.2928584],
          [116.9060295, 7.2929644],
          [116.9061275, 7.2931514],
          [116.906233, 7.2933383],
          [116.9063687, 7.2935777],
          [116.9066251, 7.2938619],
          [116.9067306, 7.2940189],
          [116.9068588, 7.2942208],
          [116.906987, 7.2944377],
          [116.9071152, 7.2946471],
          [116.9072358, 7.2948416],
          [116.9073866, 7.2950809],
          [116.9075148, 7.2952903],
          [116.9076656, 7.2956118],
          [116.907741, 7.2958212],
          [116.907839, 7.2961428],
          [116.9079898, 7.2966663],
          [116.9080501, 7.2968383],
          [116.9081406, 7.29713],
          [116.9082461, 7.2974591],
          [116.908314, 7.2977133],
          [116.9083366, 7.2978704],
          [116.9083743, 7.2980947],
          [116.908412, 7.2983116],
          [116.90851, 7.2985659],
          [116.9086306, 7.2988202],
          [116.9087814, 7.2990445],
          [116.9089775, 7.2992838],
          [116.9092338, 7.2995007],
          [116.9095052, 7.2997475],
          [116.9096786, 7.2999195],
          [116.9098747, 7.3001439],
          [116.9100028, 7.3003383],
          [116.9100632, 7.3005178],
          [116.9100933, 7.300787],
          [116.9101159, 7.3009515],
          [116.910229, 7.3010562],
          [116.9104024, 7.3010338],
          [116.9105005, 7.3008693],
          [116.9106362, 7.30063],
          [116.9107517, 7.300542],
          [116.9108535, 7.3004934],
          [116.910959, 7.3004709],
          [116.9112191, 7.3004522],
          [116.9113247, 7.3004036],
          [116.9114303, 7.3002989],
          [116.911532, 7.3001419],
          [116.9117054, 7.3000334],
          [116.9119166, 7.2999661],
          [116.912139, 7.2999661],
          [116.9122257, 7.2999699],
          [116.9123275, 7.2999848],
          [116.9125951, 7.3000783],
          [116.9128213, 7.3001531],
          [116.9130927, 7.3002615],
          [116.9132699, 7.3003288],
          [116.9133717, 7.30037],
          [116.9134622, 7.3004261],
          [116.9135602, 7.3004784],
          [116.9136356, 7.3005158],
          [116.9137864, 7.3005981],
          [116.9139146, 7.3006542],
          [116.9140616, 7.3006766],
          [116.9143104, 7.3007327],
          [116.9145894, 7.3007663],
          [116.9147741, 7.300815],
          [116.9148646, 7.3008374],
          [116.9150191, 7.3008823],
          [116.9151511, 7.300972],
          [116.9152265, 7.3009944],
          [116.9153584, 7.3010131],
          [116.9156072, 7.3010206],
          [116.9158598, 7.3010281],
          [116.915954, 7.3010281],
          [116.9161199, 7.3010131],
          [116.9162519, 7.300987],
          [116.916414, 7.3009645],
          [116.9165082, 7.3009458],
          [116.916561, 7.3009458],
          [116.9167306, 7.3008897],
          [116.9169342, 7.3008374],
          [116.9171076, 7.3008037],
          [116.9172584, 7.3007888],
          [116.9174016, 7.3008112],
          [116.9174808, 7.300886],
          [116.9176128, 7.3009234],
          [116.9178276, 7.300901],
          [116.9179709, 7.3008411],
          [116.9181707, 7.3007589],
          [116.9183064, 7.3006392],
          [116.9183667, 7.3005682],
          [116.9184572, 7.3004261],
          [116.9184949, 7.3003438],
          [116.9185741, 7.3002428],
          [116.9186268, 7.3001718],
          [116.9187211, 7.300097],
          [116.9187286, 7.2999886],
          [116.9187324, 7.2998839],
          [116.9187286, 7.2998091],
          [116.9187286, 7.2997268],
          [116.9187286, 7.2995548],
          [116.9187625, 7.2994501],
          [116.9187852, 7.2993791],
          [116.9188002, 7.2992258],
          [116.918804, 7.2991136],
          [116.918804, 7.2990089],
          [116.918804, 7.2989117],
          [116.9187512, 7.2988631],
          [116.9186796, 7.2988967],
          [116.9186155, 7.2988444],
          [116.9185703, 7.2987883],
          [116.9185627, 7.2987471],
          [116.9185409, 7.2986703],
          [116.9184851, 7.2985351],
          [116.9184771, 7.2984832],
          [116.9184569, 7.2984193],
          [116.9184147, 7.2983734],
          [116.9183845, 7.2983734],
          [116.9183403, 7.2984034],
          [116.9183362, 7.2984473],
          [116.9183523, 7.2984712],
          [116.9183644, 7.2984952],
          [116.9183282, 7.2985271],
          [116.9182558, 7.2985231],
          [116.9181954, 7.2985111],
          [116.9181391, 7.2984952],
          [116.9181029, 7.2984593],
          [116.9180787, 7.2984034],
          [116.9180787, 7.2983435],
          [116.9180787, 7.2982397],
          [116.9180908, 7.2981799],
          [116.9181189, 7.2980721],
          [116.9181391, 7.2979683],
          [116.9181632, 7.2978686],
          [116.9181874, 7.2977009],
          [116.9182115, 7.2975453],
          [116.9182276, 7.2974255],
          [116.9182638, 7.2973058],
          [116.9182799, 7.29721],
          [116.9183121, 7.2970703],
          [116.9183362, 7.2969386],
          [116.9183604, 7.2968069],
          [116.9183684, 7.2967271],
          [116.9183724, 7.2966472],
          [116.9183926, 7.2964916],
          [116.9184207, 7.296312],
          [116.9184409, 7.2962002],
          [116.918465, 7.2960007],
          [116.9184569, 7.295861],
          [116.918465, 7.2955976],
          [116.918469, 7.2954738],
          [116.9184891, 7.2953182],
          [116.9185093, 7.2951905],
          [116.9185455, 7.2950547],
          [116.9185656, 7.294943],
          [116.9186139, 7.2948272],
          [116.9186259, 7.2947794],
          [116.9186944, 7.2947075],
          [116.918807, 7.2945997],
          [116.9188513, 7.2944521],
          [116.9188593, 7.2943563],
          [116.9188835, 7.2941966],
          [116.9189157, 7.2941128],
          [116.9189961, 7.2939891],
          [116.9190927, 7.2938813],
          [116.9191812, 7.2938015],
          [116.9193543, 7.2936818],
          [116.9194468, 7.2936099],
          [116.9195353, 7.293562],
          [116.9196198, 7.2935061],
          [116.9196158, 7.2934303],
          [116.9197607, 7.2933305],
          [116.9199739, 7.2932308],
          [116.9200303, 7.2932028],
          [116.9201389, 7.2930671],
          [116.9201912, 7.2929633],
          [116.9202556, 7.2929075],
          [116.9203924, 7.2928236],
          [116.9205051, 7.2927398],
          [116.9205855, 7.2926999],
          [116.9207908, 7.2925842],
          [116.9210362, 7.2924644],
          [116.9212736, 7.2923287],
          [116.921507, 7.292217],
          [116.9215714, 7.292189],
          [116.921684, 7.2921411],
          [116.9218893, 7.2920533],
          [116.922022, 7.2919895],
          [116.9222514, 7.2919057],
          [116.9224405, 7.2918338],
          [116.9225974, 7.2918099],
          [116.92269, 7.2917939],
          [116.9228952, 7.291746],
          [116.9231246, 7.2916782],
          [116.923197, 7.2916622],
          [116.9234505, 7.2916303],
          [116.9238488, 7.2915225],
          [116.9239333, 7.2914985],
          [116.9241506, 7.2914786],
          [116.9244122, 7.2914467],
          [116.9246576, 7.2914107],
          [116.9248105, 7.2913948],
          [116.9251244, 7.2913748],
          [116.9254745, 7.2913509],
          [116.9256354, 7.2913469],
          [116.925897, 7.2913389],
          [116.9259895, 7.2913349],
          [116.9261625, 7.2913269],
          [116.9263034, 7.2913429],
          [116.9265166, 7.2913469],
          [116.9267299, 7.2913828],
          [116.9268305, 7.2913948],
          [116.9269753, 7.2914107],
          [116.9272369, 7.2914227],
          [116.9274099, 7.2914507],
          [116.9276513, 7.2914985],
          [116.9279773, 7.2915424],
          [116.9281825, 7.2916023],
          [116.9283394, 7.2916422],
          [116.9285728, 7.2916462],
          [116.9289872, 7.2917141],
          [116.9291401, 7.291738],
          [116.9294178, 7.2917779],
          [116.9295506, 7.2918338],
          [116.9297598, 7.2918657],
          [116.9299167, 7.2918657],
          [116.9300696, 7.2918578],
          [116.9302105, 7.2917779],
          [116.930299, 7.2917101],
          [116.9303634, 7.2915624],
          [116.9303835, 7.2914028],
          [116.9304197, 7.2913269],
          [116.9305042, 7.2912072],
          [116.9305766, 7.2910914],
          [116.9307416, 7.2910475],
          [116.9307859, 7.2910396],
          [116.9309388, 7.2910116],
          [116.9310635, 7.2909996],
          [116.93114, 7.2909956],
          [116.9312567, 7.2909917],
          [116.9313291, 7.2910116],
          [116.9314216, 7.2910475],
          [116.931482, 7.2910914],
          [116.9316067, 7.2911832],
          [116.9316711, 7.2912351],
          [116.9317838, 7.291299],
          [116.9319488, 7.2914147],
          [116.9319649, 7.2917021],
          [116.9319568, 7.291746],
          [116.9319045, 7.2917779],
          [116.9318482, 7.2917779],
          [116.9317516, 7.2917739],
          [116.9316751, 7.2917739],
          [116.9315665, 7.291754],
          [116.9314337, 7.2917221],
          [116.9313291, 7.2917021],
          [116.9312044, 7.2916662],
          [116.9310957, 7.2916382],
          [116.930979, 7.2916063],
          [116.9308543, 7.2915664],
          [116.9307295, 7.2915664],
          [116.9306611, 7.2915824],
          [116.9306652, 7.2916342],
          [116.9306813, 7.2916782],
          [116.9307336, 7.291738],
          [116.9308342, 7.2917979],
          [116.930971, 7.2918618],
          [116.9310997, 7.2919456],
          [116.9311883, 7.2920174],
          [116.9313492, 7.2921491],
          [116.9315504, 7.2922968],
          [116.9316108, 7.2923287],
          [116.9317476, 7.2923806],
          [116.9318562, 7.2924205],
          [116.9320172, 7.2924724],
          [116.932154, 7.2925123],
          [116.9322586, 7.2925363],
          [116.9324195, 7.2925642],
          [116.9325201, 7.2925922],
          [116.932673, 7.292644],
          [116.932826, 7.29268],
          [116.9329386, 7.2927079],
          [116.9331076, 7.2927478],
          [116.9332163, 7.2927598],
          [116.9333048, 7.2927797],
          [116.9334738, 7.2928276],
          [116.9335824, 7.2928795],
          [116.9337595, 7.2929673],
          [116.9339808, 7.2930711],
          [116.9341015, 7.293139],
          [116.9342303, 7.2932068],
          [116.934355, 7.2932906],
          [116.9345602, 7.2934183],
          [116.9347815, 7.293582],
          [116.9349505, 7.2936897],
          [116.9350229, 7.2937616],
          [116.9351638, 7.2938973],
          [116.9352805, 7.2939971],
          [116.9353972, 7.2940889],
          [116.9355018, 7.2941567],
          [116.9355782, 7.2942166],
          [116.935703, 7.2943044],
          [116.9358961, 7.2944201],
          [116.936222, 7.2946237],
          [116.9364393, 7.2947993],
          [116.9365681, 7.2948911],
          [116.9366727, 7.2949909],
          [116.9370027, 7.2953541],
          [116.9371556, 7.2954738],
          [116.9372521, 7.2955457],
          [116.9373608, 7.2956175],
          [116.9374413, 7.2956933],
          [116.9376183, 7.2958011],
          [116.9377028, 7.295849],
          [116.9377994, 7.2958969],
          [116.9378638, 7.2959368],
          [116.9380207, 7.2960007],
          [116.9380287, 7.2959568],
          [116.9379845, 7.2959208],
          [116.9378316, 7.295833],
          [116.9377068, 7.2957213],
          [116.9378074, 7.2957412],
          [116.937912, 7.2957851],
          [116.9380086, 7.2958251],
          [116.9381454, 7.295857],
          [116.9382903, 7.2959009],
          [116.9384472, 7.2959528],
          [116.9385317, 7.2959927],
          [116.938625335693359, 7.296056344693554],
          [116.938749, 7.2961404],
          [116.9388456, 7.2961843],
          [116.9389623, 7.2961843],
          [116.9390709, 7.2961763],
          [116.9391634, 7.2961603],
          [116.9392681, 7.2961284],
          [116.9393767, 7.2960965],
          [116.9394572, 7.2960965],
          [116.9395698, 7.2960965],
          [116.9396463, 7.2961244],
          [116.9397509, 7.2961843],
          [116.9398676, 7.2962601],
          [116.9400004, 7.2963918],
          [116.9400889, 7.2965515],
          [116.9401131, 7.2966632],
          [116.9401171, 7.2967909],
          [116.940109, 7.2968628],
          [116.9400487, 7.2969626],
          [116.9400165, 7.2970384],
          [116.9400044, 7.2970943],
          [116.9400004, 7.2971861],
          [116.9400004, 7.2972858],
          [116.9400084, 7.2973776],
          [116.9401068, 7.2975261],
          [116.94038, 7.2977857],
          [116.9405962, 7.2979889],
          [116.9407442, 7.2981244],
          [116.940858, 7.2982937],
          [116.9409946, 7.2984631],
          [116.9411767, 7.2987001],
          [116.9414157, 7.2990388],
          [116.9415181, 7.2992759],
          [116.9416091, 7.2994678],
          [116.9417002, 7.2996032],
          [116.9419164, 7.2997839],
          [116.9421099, 7.2999983],
          [116.9422351, 7.3001112],
          [116.9424969, 7.3002128],
          [116.9430432, 7.3004273],
          [116.9432253, 7.3005063],
          [116.9434415, 7.3005967],
          [116.9436236, 7.3006757],
          [116.9438512, 7.3007434],
          [116.9442723, 7.3008224],
          [116.944443, 7.3008563],
          [116.9449893, 7.3009466],
          [116.9454104, 7.3009579],
          [116.9456266, 7.3009579],
          [116.945786, 7.3009579],
          [116.9459795, 7.3009692],
          [116.9461047, 7.3009692],
          [116.9462981, 7.3009692],
          [116.9466851, 7.3009918],
          [116.9469696, 7.301003],
          [116.9475614, 7.3010708],
          [116.9477435, 7.3011385],
          [116.9479142, 7.3011837],
          [116.9482784, 7.3012514],
          [116.9485402, 7.3013191],
          [116.9488702, 7.3013869],
          [116.949132, 7.3014094],
          [116.949519, 7.301511],
          [116.9500197, 7.3016917],
          [116.950236, 7.301782],
          [116.9505888, 7.3018949],
          [116.950805, 7.3019965],
          [116.9510668, 7.3021432],
          [116.9515562, 7.3023803],
          [116.9519317, 7.3025609],
          [116.9520683, 7.3026512],
          [116.9522618, 7.3027754],
          [116.9525349, 7.3029221],
          [116.9527398, 7.3031028],
          [116.9528878, 7.3032044],
          [116.9530471, 7.3033398],
          [116.9532178, 7.3034866],
          [116.9533658, 7.303622],
          [116.9535365, 7.3038139],
          [116.9536503, 7.3039833],
          [116.9537755, 7.3041752],
          [116.9539689, 7.3043558],
          [116.9541283, 7.3044913],
          [116.9543559, 7.3047396],
          [116.9544583, 7.3048751],
          [116.9545721, 7.3049654],
          [116.9547998, 7.3051686],
          [116.954925, 7.3053153],
          [116.9551981, 7.3055637],
          [116.9570321, 7.3074793],
          [116.9584697, 7.3091181],
          [116.959634, 7.3105591],
          [116.9605672, 7.3113795],
          [116.9624984, 7.3123798],
          [116.9644081, 7.3132418],
          [116.9652507, 7.3136612],
          [116.9662535, 7.3142793],
          [116.9671762, 7.3149497],
          [116.968005, 7.3154233],
          [116.9689062, 7.3156999],
          [116.9695097, 7.3155031],
          [116.970132, 7.315833],
          [116.9712102, 7.3161256],
          [116.9718915, 7.3163278],
          [116.9733185, 7.3162107],
          [116.9749492, 7.3164661],
          [116.9767351, 7.3169231],
          [116.9792783, 7.3179772],
          [116.9806999, 7.3186902],
          [116.9819857, 7.3194379],
          [116.9850407, 7.3218337],
          [116.9869282, 7.3245739],
          [116.988889, 7.3287622],
          [116.9896782, 7.3333895],
          [116.9895165, 7.3401919],
          [116.9915766, 7.345357],
          [116.9931045, 7.3472501],
          [116.9957909, 7.3501382],
          [116.997966, 7.3528348],
          [116.9990824, 7.3552736],
          [116.999495685519705, 7.356399328969368],
          [117.0000261, 7.3578441],
          [117.0015947, 7.3571389],
          [117.0031271, 7.3568548],
          [117.0063215, 7.3573567],
          [117.0099482, 7.3533392],
          [117.0122822, 7.3492774],
          [117.0133593, 7.3475246],
          [117.0136672, 7.3448647],
          [117.0155344, 7.3418306],
          [117.0176991, 7.3349645],
          [117.01772, 7.3309403],
          [117.0195605, 7.3264492],
          [117.0195919, 7.3254846],
          [117.0220703, 7.3213565],
          [117.0224572, 7.3204127],
          [117.0221684, 7.3189198],
          [117.0208258, 7.3146561],
          [117.0200206, 7.3087335],
          [117.0192529, 7.3056218],
          [117.0173331, 7.3012861],
          [117.0177305, 7.2962553],
          [117.016622, 7.2946476],
          [117.0165383, 7.2929361],
          [117.0176991, 7.2911934],
          [117.0179194, 7.2893726],
          [117.0201357, 7.285146],
          [117.0223213, 7.282335],
          [117.0222062, 7.2804782],
          [117.0242245, 7.2777812],
          [117.021652, 7.274773],
          [117.0213905, 7.2724079],
          [117.0205017, 7.2716921],
          [117.0186193, 7.2717544],
          [117.0133116, 7.2740062],
          [117.0089061, 7.2761672],
          [117.0065515, 7.2781339],
          [117.0035189, 7.2784036],
          [117.000186086175972, 7.280596348960122],
        ],
      ],
      [
        [
          [117.3252114, 7.3712936],
          [117.3254603, 7.371321],
          [117.326207, 7.3711748],
          [117.3270919, 7.3707908],
          [117.3280044, 7.3700961],
          [117.3282256, 7.3694562],
          [117.3272854, 7.3694013],
          [117.3268338, 7.3696298],
          [117.325617, 7.3695019],
          [117.3254327, 7.3696755],
          [117.3253405, 7.3704252],
          [117.3249994, 7.3710559],
          [117.3252114, 7.3712936],
        ],
      ],
      [
        [
          [117.2331455, 7.3785285],
          [117.2330542, 7.3774297],
          [117.2326632, 7.3768996],
          [117.2325458, 7.3758525],
          [117.2323894, 7.3755293],
          [117.2317637, 7.3755939],
          [117.2315551, 7.3757749],
          [117.2318549, 7.3764859],
          [117.2326892, 7.3776882],
          [117.2328978, 7.378438],
          [117.2331455, 7.3785285],
        ],
      ],
      [
        [
          [117.2928988, 7.3051764],
          [117.2940419, 7.3055544],
          [117.295185, 7.3065164],
          [117.2970209, 7.3059323],
          [117.2973327, 7.3047298],
          [117.2966052, 7.304077],
          [117.2972287, 7.3026683],
          [117.2987875, 7.3022904],
          [117.2992032, 7.3005724],
          [117.298649, 7.2991981],
          [117.2976444, 7.2987858],
          [117.2976791, 7.298133],
          [117.2976098, 7.2975833],
          [117.2968131, 7.2978925],
          [117.2948733, 7.2973084],
          [117.2934531, 7.2962776],
          [117.2932106, 7.293632],
          [117.2937302, 7.2892684],
          [117.2921368, 7.2862791],
          [117.2910283, 7.2854889],
          [117.2888807, 7.2862791],
          [117.287218, 7.2881346],
          [117.2871834, 7.2904023],
          [117.2861788, 7.2925325],
          [117.2865252, 7.2935633],
          [117.2886382, 7.2943879],
          [117.2891578, 7.2955905],
          [117.2884997, 7.2982017],
          [117.2887421, 7.2996791],
          [117.2895735, 7.3020155],
          [117.2909937, 7.3041457],
          [117.2928988, 7.3051764],
        ],
      ],
      [
        [
          [117.3133035, 7.3174721],
          [117.3136815, 7.3178665],
          [117.3139488, 7.3179893],
          [117.3146136, 7.3178471],
          [117.3150047, 7.3176855],
          [117.3154088, 7.3173364],
          [117.3155652, 7.3167869],
          [117.315474, 7.3160305],
          [117.3153045, 7.3157719],
          [117.3150438, 7.3156103],
          [117.314757, 7.3154163],
          [117.3144637, 7.3155973],
          [117.3142095, 7.3157396],
          [117.3138771, 7.3159335],
          [117.3137337, 7.3163279],
          [117.3136098, 7.3168709],
          [117.3134078, 7.3173105],
          [117.3133035, 7.3174721],
        ],
      ],
      [
        [
          [117.3205936, 7.3238239],
          [117.3208159, 7.3237459],
          [117.3211016, 7.3234625],
          [117.3216777, 7.3231616],
          [117.3220095, 7.3229688],
          [117.3221455, 7.3226549],
          [117.3221755, 7.3220683],
          [117.3220556, 7.3214976],
          [117.3217837, 7.3210169],
          [117.3213541, 7.3205946],
          [117.3208112, 7.3203639],
          [117.3198157, 7.3203997],
          [117.319, 7.3203777],
          [117.3188986, 7.3206603],
          [117.3191567, 7.3211266],
          [117.3193088, 7.3217079],
          [117.3193318, 7.3222831],
          [117.3194608, 7.3225482],
          [117.3195669, 7.3230564],
          [117.320037, 7.3235036],
          [117.3205936, 7.3238239],
        ],
      ],
      [
        [
          [117.3285242, 7.3397667],
          [117.3286223, 7.340153],
          [117.3288635, 7.3402694],
          [117.3291914, 7.3400941],
          [117.3301648, 7.3390726],
          [117.33108, 7.3378086],
          [117.3315363, 7.336826],
          [117.3316749, 7.3353185],
          [117.3313929, 7.3341497],
          [117.3311252, 7.333682],
          [117.3307276, 7.3331654],
          [117.3300582, 7.3327646],
          [117.3293984, 7.3324883],
          [117.3289612, 7.3322146],
          [117.328722, 7.331955],
          [117.3286354, 7.3320206],
          [117.3287201, 7.3325895],
          [117.3287005, 7.332861],
          [117.3283942, 7.3339665],
          [117.3284398, 7.3354792],
          [117.3287201, 7.3372699],
          [117.3287592, 7.3382137],
          [117.3285376, 7.3391316],
          [117.3285242, 7.3397667],
        ],
      ],
      [
        [
          [117.0473314, 7.3442245],
          [117.0475922, 7.3451554],
          [117.0479702, 7.3456079],
          [117.0481657, 7.345595],
          [117.0481136, 7.3447675],
          [117.0481527, 7.3436039],
          [117.0483873, 7.3419232],
          [117.048622, 7.3411087],
          [117.0491956, 7.3396735],
          [117.0492216, 7.3391952],
          [117.0487002, 7.3386004],
          [117.0479963, 7.3380703],
          [117.0475009, 7.3381091],
          [117.0469013, 7.3386909],
          [117.0468491, 7.3396735],
          [117.0470968, 7.3415095],
          [117.047175, 7.3428929],
          [117.0473314, 7.3442245],
        ],
      ],
      [
        [
          [117.0496732, 7.1025835],
          [117.0500093, 7.102818],
          [117.0504851, 7.1027123],
          [117.0511274, 7.1025472],
          [117.051154, 7.1022731],
          [117.0512571, 7.1020387],
          [117.0511373, 7.1019066],
          [117.0507979, 7.1019198],
          [117.0504319, 7.1015632],
          [117.0501258, 7.1013321],
          [117.0498562, 7.1013519],
          [117.0497165, 7.1016953],
          [117.0495701, 7.1022566],
          [117.0496732, 7.1025835],
        ],
      ],
      [
        [
          [117.0884263, 7.1059878],
          [117.0892435, 7.1066055],
          [117.0895305, 7.1067586],
          [117.0898079, 7.1067936],
          [117.090067, 7.1069822],
          [117.0902413, 7.1072363],
          [117.0903566, 7.1074882],
          [117.0904519, 7.107609],
          [117.090527, 7.1076662],
          [117.090653, 7.1077291],
          [117.0917796, 7.107782],
          [117.09245, 7.1079001],
          [117.0927344, 7.1080578],
          [117.0929785, 7.1081243],
          [117.0935856, 7.1081966],
          [117.0942382, 7.1080991],
          [117.0948587, 7.107918],
          [117.0954341, 7.1075903],
          [117.0955601, 7.1074197],
          [117.0956191, 7.1072067],
          [117.0955682, 7.1069901],
          [117.0947662, 7.1059445],
          [117.0945877, 7.1053983],
          [117.0943169, 7.1050568],
          [117.0939669, 7.1047837],
          [117.0937416, 7.1046559],
          [117.0935073, 7.1045853],
          [117.093004, 7.1044882],
          [117.0927988, 7.104415],
          [117.0917876, 7.1032622],
          [117.0907979, 7.1023443],
          [117.0904831, 7.1020481],
          [117.0898645, 7.1016489],
          [117.088982, 7.1012321],
          [117.0888358, 7.101113],
          [117.0887782, 7.1009786],
          [117.0887782, 7.1008275],
          [117.0887554, 7.1007803],
          [117.0886789, 7.1007363],
          [117.0886226, 7.1007495],
          [117.0877053, 7.1014503],
          [117.0869127, 7.1021051],
          [117.0865008, 7.1026338],
          [117.0862391, 7.1033304],
          [117.0862314, 7.1034439],
          [117.0862663, 7.1035437],
          [117.0864567, 7.1038045],
          [117.0864862, 7.103887],
          [117.0864795, 7.1040837],
          [117.0864983, 7.1042221],
          [117.0865693, 7.1043849],
          [117.0874402, 7.1052459],
          [117.0884263, 7.1059878],
        ],
      ],
      [
        [
          [117.0983992, 7.1296155],
          [117.0993886, 7.1301839],
          [117.0996143, 7.1313552],
          [117.1011071, 7.1309762],
          [117.1030339, 7.1316652],
          [117.105603, 7.1332843],
          [117.1065924, 7.1326126],
          [117.1087623, 7.1335254],
          [117.1103246, 7.1334393],
          [117.1110189, 7.1352995],
          [117.1120083, 7.1339733],
          [117.1131193, 7.1339561],
          [117.1129631, 7.1330604],
          [117.113154, 7.1323197],
          [117.1114529, 7.1316652],
          [117.1090053, 7.1305284],
          [117.1072174, 7.1285648],
          [117.1057419, 7.1282892],
          [117.1037803, 7.1267907],
          [117.1018882, 7.1271696],
          [117.0998226, 7.1264807],
          [117.0987637, 7.1265496],
          [117.0981909, 7.1277208],
          [117.0977569, 7.1287715],
          [117.0983992, 7.1296155],
        ],
      ],
      [
        [
          [117.1706495, 7.1709745],
          [117.1720524, 7.1711635],
          [117.1743559, 7.1711291],
          [117.1754124, 7.1711291],
          [117.176642, 7.1722117],
          [117.1781488, 7.1724351],
          [117.1793785, 7.1727273],
          [117.1800194, 7.1724523],
          [117.180106, 7.1728991],
          [117.1819592, 7.1733802],
          [117.1823402, 7.1726757],
          [117.1822536, 7.1717649],
          [117.1826866, 7.170648],
          [117.1832754, 7.1706824],
          [117.1833274, 7.1721602],
          [117.1829983, 7.1733974],
          [117.1835179, 7.1736896],
          [117.1868606, 7.1751158],
          [117.1876227, 7.1748753],
          [117.1875361, 7.1754595],
          [117.1879171, 7.1758719],
          [117.1908614, 7.17747],
          [117.1917967, 7.1775216],
          [117.1929051, 7.1780027],
          [117.193113, 7.1776247],
          [117.1936499, 7.1777106],
          [117.193546, 7.1781574],
          [117.1947757, 7.1789307],
          [117.1961612, 7.1795665],
          [117.1967847, 7.1787416],
          [117.1976507, 7.1772295],
          [117.1983262, 7.1762156],
          [117.1982915, 7.175408],
          [117.1986899, 7.1750127],
          [117.1993307, 7.1750986],
          [117.2021884, 7.174755],
          [117.2040243, 7.1752361],
          [117.2044919, 7.174944],
          [117.203626, 7.1743769],
          [117.2019979, 7.1739301],
          [117.2011666, 7.1730881],
          [117.2006297, 7.1722977],
          [117.2012012, 7.1721086],
          [117.2029332, 7.1718681],
          [117.2034701, 7.1709745],
          [117.2029505, 7.1692732],
          [117.202275, 7.1677782],
          [117.2009414, 7.1669877],
          [117.2009934, 7.1659395],
          [117.2016515, 7.1644273],
          [117.2011493, 7.1631041],
          [117.1999888, 7.1618668],
          [117.1986552, 7.1617981],
          [117.1975987, 7.160767],
          [117.1961266, 7.160767],
          [117.1945505, 7.1607842],
          [117.1924548, 7.1604921],
          [117.1915542, 7.1598734],
          [117.1909134, 7.1585674],
          [117.1896837, 7.1583096],
          [117.1890082, 7.1588939],
          [117.18932, 7.1603889],
          [117.1888177, 7.1613513],
          [117.187259, 7.1619527],
          [117.1864103, 7.1615575],
          [117.1861159, 7.160956],
          [117.1839509, 7.1608873],
          [117.1827039, 7.1611966],
          [117.1816647, 7.1607154],
          [117.1818552, 7.1598219],
          [117.180002, 7.1591345],
          [117.1779237, 7.1580003],
          [117.1768499, 7.1576566],
          [117.1761571, 7.1562646],
          [117.1748408, 7.1557319],
          [117.1730569, 7.1539103],
          [117.1721563, 7.1524668],
          [117.1723121, 7.1508171],
          [117.1714462, 7.1494938],
          [117.1697315, 7.1494938],
          [117.1686058, 7.1491673],
          [117.1669777, 7.1490127],
          [117.1653324, 7.148669],
          [117.1645703, 7.14793],
          [117.1634099, 7.1481362],
          [117.1626651, 7.148205],
          [117.1624573, 7.1496313],
          [117.1619724, 7.1501125],
          [117.1611237, 7.1505249],
          [117.1612103, 7.1513842],
          [117.1619897, 7.1520028],
          [117.1625266, 7.152673],
          [117.1630462, 7.1531714],
          [117.163202, 7.1539791],
          [117.163306, 7.1550617],
          [117.1642239, 7.1570036],
          [117.1651938, 7.1575019],
          [117.1661464, 7.1580347],
          [117.1665101, 7.159461],
          [117.1674107, 7.160114],
          [117.167428, 7.1611107],
          [117.1660598, 7.1616434],
          [117.1656095, 7.162073],
          [117.1658346, 7.1625198],
          [117.1658346, 7.1642211],
          [117.1660251, 7.1664207],
          [117.1676012, 7.1672971],
          [117.1683979, 7.1681391],
          [117.1696623, 7.1692389],
          [117.1706495, 7.1709745],
        ],
      ],
      [
        [
          [117.1572689, 7.1675762],
          [117.1574942, 7.1676401],
          [117.1577838, 7.1676614],
          [117.157977, 7.1676401],
          [117.1582344, 7.1675123],
          [117.1584919, 7.1673633],
          [117.1587816, 7.1672356],
          [117.1591035, 7.1670014],
          [117.1593932, 7.1667885],
          [117.1593717, 7.1666075],
          [117.1592215, 7.1664798],
          [117.1591786, 7.1663094],
          [117.159479, 7.1664159],
          [117.1595863, 7.1663094],
          [117.1597472, 7.1662775],
          [117.1599296, 7.1661923],
          [117.1598545, 7.1659475],
          [117.1598867, 7.1658198],
          [117.1600476, 7.1657665],
          [117.1601013, 7.1656282],
          [117.1600584, 7.1652024],
          [117.1600798, 7.1648298],
          [117.1599618, 7.1646062],
          [117.1599081, 7.1643401],
          [117.1600154, 7.1639249],
          [117.1601013, 7.1636695],
          [117.1601442, 7.1634885],
          [117.1601656, 7.1631585],
          [117.1598974, 7.1631053],
          [117.1597043, 7.1631159],
          [117.1591893, 7.1631479],
          [117.1587709, 7.1631904],
          [117.1585349, 7.1632117],
          [117.1581915, 7.1630414],
          [117.1578589, 7.1628391],
          [117.1576014, 7.162903],
          [117.157183, 7.1628391],
          [117.1568397, 7.1627646],
          [117.1565715, 7.1628711],
          [117.1564642, 7.1631798],
          [117.1563033, 7.1634566],
          [117.1561316, 7.1637653],
          [117.1559599, 7.1641911],
          [117.1556381, 7.1642762],
          [117.1553913, 7.1641591],
          [117.1555415, 7.1637333],
          [117.1556381, 7.1636801],
          [117.155917, 7.1635737],
          [117.156078, 7.1634566],
          [117.1563247, 7.1631159],
          [117.1562925, 7.1628391],
          [117.1561531, 7.1624559],
          [117.156035, 7.1623601],
          [117.1558741, 7.1620408],
          [117.1553913, 7.1617427],
          [117.1548334, 7.1618279],
          [117.1546725, 7.1616469],
          [117.1542433, 7.1614766],
          [117.1535781, 7.1615617],
          [117.153267, 7.1617959],
          [117.1528378, 7.1613914],
          [117.1524838, 7.1609337],
          [117.1521727, 7.1604972],
          [117.1515075, 7.1601885],
          [117.1510569, 7.1604546],
          [117.1509818, 7.1607846],
          [117.1509067, 7.1610933],
          [117.1507779, 7.1611359],
          [117.1502737, 7.1613488],
          [117.1499303, 7.1616256],
          [117.1496836, 7.162275],
          [117.149587, 7.1628285],
          [117.1494797, 7.1632756],
          [117.1493939, 7.163744],
          [117.149351, 7.1640846],
          [117.1494046, 7.1645317],
          [117.149587, 7.1648936],
          [117.1496085, 7.1652662],
          [117.1496407, 7.1656388],
          [117.1497479, 7.1660114],
          [117.1499625, 7.1663946],
          [117.1501664, 7.1667033],
          [117.1506813, 7.1671504],
          [117.1509496, 7.1674591],
          [117.1511856, 7.1676614],
          [117.1515718, 7.1675336],
          [117.1517006, 7.1673207],
          [117.1518293, 7.1673633],
          [117.1517328, 7.1677465],
          [117.151722, 7.1679594],
          [117.1518508, 7.1681084],
          [117.1523229, 7.1683426],
          [117.152516, 7.1684917],
          [117.1529022, 7.1686726],
          [117.1533743, 7.1687684],
          [117.1537391, 7.1687578],
          [117.1540395, 7.1687578],
          [117.1541682, 7.1689068],
          [117.1544043, 7.1689387],
          [117.1548119, 7.1686194],
          [117.1550158, 7.1683533],
          [117.1553698, 7.1680765],
          [117.1558205, 7.1678849],
          [117.156196, 7.1676826],
          [117.1565178, 7.1675549],
          [117.1572689, 7.1675762],
        ],
      ],
      [
        [
          [117.1348748, 7.1392741],
          [117.1336075, 7.1369333],
          [117.1331929, 7.136726],
          [117.132383, 7.1366933],
          [117.1314395, 7.1364986],
          [117.130202, 7.1366964],
          [117.1297282, 7.1364891],
          [117.1291505, 7.1366596],
          [117.1282778, 7.1366773],
          [117.1278131, 7.1371282],
          [117.1265166, 7.136991],
          [117.1257171, 7.1364243],
          [117.1256387, 7.1356293],
          [117.1247573, 7.1354119],
          [117.1245638, 7.1348925],
          [117.1235003, 7.1347936],
          [117.1231491, 7.1356629],
          [117.1222056, 7.1353161],
          [117.1222976, 7.1343624],
          [117.1206264, 7.1334886],
          [117.1199714, 7.1341945],
          [117.1174586, 7.1353672],
          [117.1169736, 7.1358696],
          [117.116823, 7.1374088],
          [117.117627, 7.1378529],
          [117.1187439, 7.1382912],
          [117.1190492, 7.1391496],
          [117.1183142, 7.1399589],
          [117.1183958, 7.1408469],
          [117.1191453, 7.1416097],
          [117.12028, 7.1421698],
          [117.122094, 7.1419807],
          [117.1251644, 7.141984],
          [117.1295198, 7.1427378],
          [117.1306924, 7.1443292],
          [117.1328701, 7.1479308],
          [117.1347965, 7.1506111],
          [117.1369229, 7.1516785],
          [117.137192, 7.1527654],
          [117.1386911, 7.154005],
          [117.1393166, 7.1537075],
          [117.1411621, 7.1546315],
          [117.1428616, 7.1555686],
          [117.1440099, 7.1550503],
          [117.1479135, 7.1566275],
          [117.1490886, 7.156217],
          [117.1495353, 7.1553124],
          [117.1489875, 7.1544995],
          [117.1463221, 7.1536611],
          [117.1452256, 7.1530133],
          [117.1451825, 7.1516999],
          [117.1452256, 7.1493329],
          [117.1466064, 7.1498573],
          [117.1494541, 7.1500248],
          [117.150208, 7.1478471],
          [117.1498729, 7.1465907],
          [117.1481978, 7.1426541],
          [117.1461038, 7.1421515],
          [117.1451825, 7.1407277],
          [117.148449, 7.1406439],
          [117.1510455, 7.1397226],
          [117.1470252, 7.1357022],
          [117.1451825, 7.133692],
          [117.1438424, 7.1305092],
          [117.142921, 7.1278289],
          [117.1414134, 7.1250649],
          [117.137058, 7.1241436],
          [117.1333726, 7.1248974],
          [117.1328701, 7.1269076],
          [117.1306924, 7.1290016],
          [117.1298865, 7.1306031],
          [117.1308916, 7.1325714],
          [117.13353, 7.1324458],
          [117.1355263, 7.1340622],
          [117.1356448, 7.135306],
          [117.134401, 7.1369051],
          [117.1348748, 7.1392741],
        ],
      ],
      [
        [
          [117.215397, 7.1748167],
          [117.2154989, 7.1748114],
          [117.2158476, 7.1748167],
          [117.2161856, 7.1747741],
          [117.2165611, 7.1746943],
          [117.2167488, 7.1746145],
          [117.2170761, 7.1745027],
          [117.217414, 7.1744016],
          [117.2175374, 7.1742472],
          [117.2175374, 7.1739918],
          [117.2174409, 7.1736884],
          [117.2172424, 7.1733637],
          [117.2170654, 7.1732413],
          [117.2167435, 7.1731561],
          [117.2164914, 7.1729752],
          [117.2161427, 7.1729007],
          [117.2157189, 7.1726984],
          [117.2154989, 7.172544],
          [117.2150376, 7.1724802],
          [117.2148659, 7.1725866],
          [117.2145119, 7.1726186],
          [117.21419, 7.1724589],
          [117.2139862, 7.1725334],
          [117.2139057, 7.172709],
          [117.2135624, 7.1729007],
          [117.2133854, 7.1730869],
          [117.2133693, 7.1734595],
          [117.2134873, 7.1736085],
          [117.2137662, 7.1737416],
          [117.2139111, 7.17388],
          [117.2138038, 7.1741035],
          [117.2138091, 7.1742685],
          [117.2138467, 7.1744335],
          [117.2139325, 7.1746358],
          [117.2139486, 7.1747688],
          [117.2141256, 7.1750137],
          [117.2144475, 7.1750296],
          [117.2147211, 7.1749924],
          [117.2149196, 7.1749019],
          [117.214984, 7.1748593],
          [117.2150966, 7.1748274],
          [117.215397, 7.1748167],
        ],
      ],
      [
        [
          [117.2135168, 7.1806367],
          [117.2136884, 7.1807325],
          [117.2139459, 7.1807645],
          [117.2143375, 7.180839],
          [117.2145682, 7.1808869],
          [117.2146111, 7.1807272],
          [117.2146809, 7.1805303],
          [117.2149223, 7.1804185],
          [117.2150188, 7.1802535],
          [117.2150296, 7.1800885],
          [117.2152066, 7.1800087],
          [117.2153461, 7.1798544],
          [117.215507, 7.1796628],
          [117.2155714, 7.1795031],
          [117.215684, 7.1791039],
          [117.2154963, 7.1789496],
          [117.215169, 7.1788325],
          [117.2148847, 7.1787473],
          [117.2146594, 7.1786515],
          [117.2146004, 7.1785131],
          [117.214536, 7.1782683],
          [117.2145199, 7.1781299],
          [117.2143536, 7.1778319],
          [117.2141981, 7.1776935],
          [117.2139781, 7.1775232],
          [117.2138333, 7.1773262],
          [117.2135704, 7.1772091],
          [117.213329, 7.1772304],
          [117.2131788, 7.1773848],
          [117.2130715, 7.1776935],
          [117.2129911, 7.1778957],
          [117.212814, 7.1783215],
          [117.2125995, 7.1785397],
          [117.2123259, 7.1786196],
          [117.2121864, 7.1788378],
          [117.2121918, 7.1789868],
          [117.2122883, 7.1791731],
          [117.2123688, 7.1793381],
          [117.2124385, 7.179567],
          [117.2123205, 7.1795776],
          [117.2121757, 7.1797532],
          [117.2122669, 7.1799342],
          [117.2123581, 7.1801258],
          [117.2125351, 7.1802855],
          [117.2127282, 7.1803919],
          [117.2129857, 7.180509],
          [117.213329, 7.1805569],
          [117.2135168, 7.1806367],
        ],
      ],
      [
        [
          [117.0586585, 7.1283824],
          [117.0598176, 7.1305173],
          [117.0596267, 7.1309565],
          [117.0597742, 7.1315766],
          [117.0608331, 7.1320588],
          [117.0621263, 7.1327306],
          [117.0626818, 7.1336349],
          [117.0632546, 7.1338674],
          [117.0636018, 7.1336521],
          [117.0640965, 7.133988],
          [117.0644177, 7.1353573],
          [117.0644003, 7.1363046],
          [117.0646346, 7.1370022],
          [117.0642701, 7.1389916],
          [117.0644784, 7.1400595],
          [117.0648172, 7.1407812],
          [117.0651294, 7.1424622],
          [117.0644524, 7.1440382],
          [117.0643656, 7.1448305],
          [117.0658497, 7.1466821],
          [117.0661448, 7.1475088],
          [117.0662056, 7.148792],
          [117.0666916, 7.1491278],
          [117.0670475, 7.1490073],
          [117.0672298, 7.1495412],
          [117.0670735, 7.1496446],
          [117.0669607, 7.1505402],
          [117.0665094, 7.152297],
          [117.0661816, 7.1527808],
          [117.0661535, 7.1528223],
          [117.0654418, 7.1528568],
          [117.0651033, 7.1533562],
          [117.0644031, 7.1537638],
          [117.0642032, 7.1546391],
          [117.0642461, 7.1560396],
          [117.0638595, 7.1578421],
          [117.0640191, 7.1585972],
          [117.0656454, 7.1609659],
          [117.0658374, 7.1636365],
          [117.0665231, 7.1638827],
          [117.0677014, 7.1628415],
          [117.0680819, 7.1630789],
          [117.0687202, 7.163286],
          [117.0693891, 7.1633286],
          [117.0701686, 7.1634321],
          [117.0704631, 7.1638462],
          [117.0705306, 7.1644308],
          [117.0707577, 7.1647535],
          [117.0707516, 7.1650884],
          [117.0708191, 7.1664402],
          [117.0720895, 7.1667569],
          [117.0726296, 7.1674084],
          [117.0731021, 7.1676215],
          [117.0739982, 7.1677738],
          [117.0741946, 7.1680782],
          [117.0736238, 7.1683096],
          [117.0737465, 7.1687724],
          [117.0740104, 7.1688394],
          [117.0748923, 7.1731563],
          [117.074796, 7.1745815],
          [117.0747408, 7.1760368],
          [117.075152, 7.1772425],
          [117.0746426, 7.1776504],
          [117.0734024, 7.1768188],
          [117.0714205, 7.1766396],
          [117.0697574, 7.1769502],
          [117.0684256, 7.1766396],
          [117.0675787, 7.1765239],
          [117.067186, 7.1761978],
          [117.064197, 7.1751965],
          [117.0621227, 7.1750017],
          [117.0619263, 7.175233],
          [117.0617238, 7.1753548],
          [117.0608891, 7.1753974],
          [117.0605282, 7.1755409],
          [117.0602852, 7.1758983],
          [117.0597644, 7.1763117],
          [117.0596707, 7.1766524],
          [117.0600551, 7.1773967],
          [117.0600942, 7.1777153],
          [117.0605647, 7.178506],
          [117.0606855, 7.1786567],
          [117.0609466, 7.1787988],
          [117.0618102, 7.1788935],
          [117.0621566, 7.1790097],
          [117.06283, 7.1795307],
          [117.063107, 7.1801421],
          [117.0632329, 7.1804392],
          [117.0633074, 7.1807277],
          [117.0633023, 7.1812056],
          [117.0632293, 7.1816749],
          [117.0630115, 7.1822433],
          [117.0627606, 7.1827599],
          [117.0623526, 7.1830872],
          [117.0609162, 7.1833473],
          [117.0584296, 7.1835091],
          [117.0572145, 7.183845],
          [117.0553224, 7.1834402],
          [117.0545628, 7.1836268],
          [117.0542809, 7.185438],
          [117.0538816, 7.1873325],
          [117.0539077, 7.1885122],
          [117.0538816, 7.1896489],
          [117.0538032, 7.1911635],
          [117.0553218, 7.1926058],
          [117.0567273, 7.1946151],
          [117.0579974, 7.1976212],
          [117.0588262, 7.197964],
          [117.0620421, 7.1979762],
          [117.0647547, 7.1993766],
          [117.0642208, 7.2001986],
          [117.0643251, 7.2006126],
          [117.065236, 7.2008813],
          [117.0652518, 7.2012398],
          [117.0651843, 7.2021653],
          [117.0652948, 7.2029325],
          [117.0655035, 7.2036936],
          [117.0658533, 7.2042903],
          [117.0660497, 7.2052706],
          [117.0664941, 7.2059713],
          [117.0667616, 7.2071946],
          [117.0657551, 7.2085585],
          [117.0649573, 7.2086011],
          [117.0630973, 7.2091172],
          [117.0623612, 7.2089238],
          [117.0617107, 7.2088142],
          [117.0606919, 7.2078461],
          [117.0594531, 7.2077901],
          [117.0584334, 7.208047],
          [117.0564655, 7.2088668],
          [117.054959, 7.2088921],
          [117.0542993, 7.2092207],
          [117.0544738, 7.2099687],
          [117.0552271, 7.2102221],
          [117.0556484, 7.2111847],
          [117.0568485, 7.2122613],
          [117.0557008, 7.2149443],
          [117.0575763, 7.2162132],
          [117.057921, 7.2186071],
          [117.0571039, 7.2193924],
          [117.0571549, 7.2197217],
          [117.0579593, 7.2199117],
          [117.0580997, 7.221229],
          [117.0592999, 7.2226856],
          [117.0598871, 7.2235089],
          [117.0600914, 7.2252948],
          [117.0600531, 7.2258014],
          [117.0596956, 7.226612],
          [117.0597113, 7.22747],
          [117.0595424, 7.2309944],
          [117.0603468, 7.2323117],
          [117.0601605, 7.2350015],
          [117.0596172, 7.2364441],
          [117.063234, 7.2414493],
          [117.0642035, 7.2412293],
          [117.06601, 7.2456669],
          [117.0664429, 7.2470879],
          [117.0664968, 7.249607],
          [117.067089, 7.250024],
          [117.0667969, 7.2507482],
          [117.0662578, 7.2511467],
          [117.0660967, 7.2518894],
          [117.0664468, 7.2555611],
          [117.0666817, 7.2565944],
          [117.0686476, 7.2590839],
          [117.0694729, 7.260746],
          [117.0694729, 7.2626314],
          [117.0689283, 7.2649053],
          [117.0655095, 7.2726765],
          [117.0649963, 7.2739934],
          [117.0646212, 7.276102],
          [117.064096, 7.2795502],
          [117.0633707, 7.2818821],
          [117.0631468, 7.2836866],
          [117.0626955, 7.2849333],
          [117.0629206, 7.2861489],
          [117.0628967, 7.2871933],
          [117.0608582, 7.2922734],
          [117.0611199, 7.2932684],
          [117.0616942, 7.2937185],
          [117.0666457, 7.2944903],
          [117.0677973, 7.2950048],
          [117.0685976, 7.2959226],
          [117.069798, 7.2969397],
          [117.071297, 7.2995576],
          [117.0730714, 7.2996178],
          [117.074851, 7.3012259],
          [117.0757473, 7.302731],
          [117.0769478, 7.3033511],
          [117.0769102, 7.3038968],
          [117.0754722, 7.3050379],
          [117.0757705, 7.3055759],
          [117.0802976, 7.3055759],
          [117.0804517, 7.3033148],
          [117.0810089, 7.3022333],
          [117.0821871, 7.3019992],
          [117.0833527, 7.3020001],
          [117.0850776, 7.302399],
          [117.0867789, 7.3032404],
          [117.0869387, 7.3039092],
          [117.086979, 7.3046543],
          [117.0876792, 7.3052249],
          [117.0879641, 7.3060425],
          [117.0876251, 7.3067747],
          [117.0874764, 7.3075805],
          [117.0871888, 7.308213],
          [117.0872296, 7.3092243],
          [117.0879891, 7.3105075],
          [117.0901298, 7.3113291],
          [117.0927283, 7.3112641],
          [117.0942413, 7.3115494],
          [117.0941162, 7.3123059],
          [117.0946539, 7.3125292],
          [117.0950416, 7.3121943],
          [117.0946039, 7.3112393],
          [117.094954, 7.3108548],
          [117.0963237, 7.3109234],
          [117.0974549, 7.3117478],
          [117.0986553, 7.3119586],
          [117.0995556, 7.311599],
          [117.0997807, 7.3109292],
          [117.1005185, 7.3105819],
          [117.1013438, 7.3107928],
          [117.1021087, 7.3114452],
          [117.1043198, 7.310954],
          [117.1044448, 7.3097262],
          [117.1056916, 7.3092243],
          [117.1075914, 7.3092563],
          [117.1100772, 7.3101364],
          [117.1109971, 7.3110036],
          [117.1114428, 7.3117617],
          [117.1110796, 7.312723],
          [117.1118099, 7.3133601],
          [117.112035, 7.3140795],
          [117.1124977, 7.3143523],
          [117.1128853, 7.3148236],
          [117.1128353, 7.3161011],
          [117.1140607, 7.3167833],
          [117.1142759, 7.3173837],
          [117.1150485, 7.3176638],
          [117.1158931, 7.3171523],
          [117.1173477, 7.3140259],
          [117.1185558, 7.3138074],
          [117.1193988, 7.313843],
          [117.1200958, 7.3140686],
          [117.1208729, 7.3144195],
          [117.1217099, 7.3148692],
          [117.1231588, 7.3159345],
          [117.1233736, 7.3159596],
          [117.1235379, 7.3158008],
          [117.1237468, 7.3160791],
          [117.123858, 7.316323],
          [117.123778, 7.3164567],
          [117.1238704, 7.3168522],
          [117.1254748, 7.3176513],
          [117.127703, 7.3192299],
          [117.1297366, 7.3212645],
          [117.1305501, 7.3228606],
          [117.1313635, 7.3238252],
          [117.1320576, 7.3234094],
          [117.1325703, 7.3240915],
          [117.1324971, 7.3247442],
          [117.1319076, 7.3253317],
          [117.1309072, 7.3276882],
          [117.1294958, 7.3301948],
          [117.1325251, 7.3290755],
          [117.1337832, 7.3290276],
          [117.1349071, 7.3292521],
          [117.1361966, 7.3299454],
          [117.1375912, 7.3311159],
          [117.1387523, 7.3325233],
          [117.1392476, 7.3343109],
          [117.1398695, 7.3349845],
          [117.1424036, 7.3353013],
          [117.1438995, 7.3358629],
          [117.1445495, 7.3363944],
          [117.1453873, 7.3373866],
          [117.1455373, 7.3389244],
          [117.1458673, 7.3401753],
          [117.1476506, 7.3429923],
          [117.1482883, 7.3448773],
          [117.1487322, 7.3458764],
          [117.1487509, 7.3475809],
          [117.1481556, 7.3489929],
          [117.1494916, 7.3509636],
          [117.151364, 7.3517512],
          [117.154763, 7.3509841],
          [117.1547906, 7.349962],
          [117.153115, 7.3493543],
          [117.153265, 7.3478165],
          [117.154703, 7.3469484],
          [117.1556714, 7.3455155],
          [117.156091, 7.3450013],
          [117.1581858, 7.3435361],
          [117.1588795, 7.3430047],
          [117.1603925, 7.3423846],
          [117.1615226, 7.3421138],
          [117.1626433, 7.3422606],
          [117.1644814, 7.3418389],
          [117.1659727, 7.3419179],
          [117.1682077, 7.3425334],
          [117.1700084, 7.3434511],
          [117.1716359, 7.344494],
          [117.1733845, 7.3459811],
          [117.1736471, 7.3465888],
          [117.1735971, 7.3470848],
          [117.1739347, 7.3477669],
          [117.1749601, 7.3480026],
          [117.1756228, 7.3492179],
          [117.1755103, 7.3499868],
          [117.1767243, 7.3509038],
          [117.1777235, 7.3501356],
          [117.1779895, 7.3502669],
          [117.1780396, 7.350561],
          [117.1809056, 7.3511338],
          [117.1810772, 7.3541983],
          [117.1798291, 7.355848],
          [117.1804293, 7.3575503],
          [117.1823549, 7.3581794],
          [117.1830052, 7.3585763],
          [117.1844557, 7.3584771],
          [117.1861813, 7.3591219],
          [117.1874817, 7.3586755],
          [117.1886571, 7.3592956],
          [117.1896955, 7.3598842],
          [117.1901827, 7.3590475],
          [117.1903577, 7.3566913],
          [117.1925671, 7.3557024],
          [117.1951594, 7.3538885],
          [117.1963348, 7.3523507],
          [117.202487, 7.3509618],
          [117.2037365, 7.3509636],
          [117.2061632, 7.352946],
          [117.2080139, 7.3560712],
          [117.2091893, 7.3558232],
          [117.2107398, 7.3561952],
          [117.2121903, 7.3569145],
          [117.2138159, 7.3581794],
          [117.2157778, 7.3592133],
          [117.2208433, 7.3604117],
          [117.222819, 7.3604613],
          [117.2249448, 7.361255],
          [117.2294463, 7.3614286],
          [117.2308468, 7.3616518],
          [117.2327475, 7.3623959],
          [117.2346232, 7.3628175],
          [117.2356621, 7.3625867],
          [117.2409504, 7.3588739],
          [117.2419507, 7.3579314],
          [117.242776, 7.3575594],
          [117.2451268, 7.3575098],
          [117.2466269, 7.3565423],
          [117.2480278, 7.3559968],
          [117.2491782, 7.3550543],
          [117.251304, 7.354087],
          [117.2559556, 7.3533181],
          [117.2606349, 7.3518463],
          [117.2644335, 7.3501681],
          [117.2678347, 7.3502425],
          [117.2706107, 7.349796],
          [117.2731355, 7.3499172],
          [117.2750122, 7.3509618],
          [117.2760693, 7.3518969],
          [117.2770705, 7.3523228],
          [117.277776, 7.3523685],
          [117.2787261, 7.3518703],
          [117.2798756, 7.3507077],
          [117.2814145, 7.3490271],
          [117.28235, 7.3468928],
          [117.2846656, 7.3413628],
          [117.2846906, 7.3391305],
          [117.2853733, 7.3371699],
          [117.285966, 7.3369974],
          [117.2870265, 7.3370288],
          [117.2883205, 7.3362393],
          [117.288717, 7.3352363],
          [117.2883919, 7.3332768],
          [117.2897174, 7.3305483],
          [117.2912179, 7.3291344],
          [117.2935187, 7.3278694],
          [117.294976, 7.3253161],
          [117.2941825, 7.3235572],
          [117.294373, 7.3221485],
          [117.2934031, 7.3192969],
          [117.2940093, 7.3185067],
          [117.2927796, 7.3164625],
          [117.2907359, 7.3156723],
          [117.2899045, 7.3146931],
          [117.2870295, 7.3128893],
          [117.2826996, 7.3099174],
          [117.2821973, 7.3089554],
          [117.2827169, 7.3087664],
          [117.2837215, 7.3088008],
          [117.283661, 7.3084097],
          [117.2830375, 7.3079459],
          [117.281981, 7.3076366],
          [117.2802663, 7.3079974],
          [117.2792964, 7.3089938],
          [117.2791764, 7.3096902],
          [117.2793111, 7.3103583],
          [117.2788947, 7.3104069],
          [117.2775476, 7.3097752],
          [117.2774864, 7.3092893],
          [117.2774864, 7.3088399],
          [117.2784478, 7.3085128],
          [117.2786729, 7.3077913],
          [117.2762655, 7.3050942],
          [117.2736676, 7.3028609],
          [117.2732101, 7.3012768],
          [117.2725072, 7.3002325],
          [117.2735463, 7.2994251],
          [117.2734944, 7.298463],
          [117.2746548, 7.2968825],
          [117.2745509, 7.2950615],
          [117.27391, 7.2935669],
          [117.2726111, 7.2928454],
          [117.2726457, 7.2916428],
          [117.2708964, 7.2891689],
          [117.2684895, 7.2842214],
          [117.2673459, 7.2820393],
          [117.2654234, 7.2795826],
          [117.2633451, 7.2780536],
          [117.262254, 7.2767135],
          [117.2626696, 7.2742911],
          [117.2625484, 7.2709238],
          [117.2618556, 7.2703569],
          [117.2609896, 7.2703397],
          [117.2607818, 7.2698586],
          [117.261336, 7.2694635],
          [117.2626177, 7.2687591],
          [117.2636395, 7.2680719],
          [117.2649656, 7.2636327],
          [117.267493, 7.2638178],
          [117.2722426, 7.2654332],
          [117.2728024, 7.2661231],
          [117.2752869, 7.2657852],
          [117.2761799, 7.2660769],
          [117.2768936, 7.2654106],
          [117.2768996, 7.2645718],
          [117.2773973, 7.2640661],
          [117.2776552, 7.2634653],
          [117.2765757, 7.2624004],
          [117.2752743, 7.2615615],
          [117.2743147, 7.2611094],
          [117.2736762, 7.2612771],
          [117.2728094, 7.2616865],
          [117.2722397, 7.2616389],
          [117.2717677, 7.2613275],
          [117.271508, 7.2609785],
          [117.2714063, 7.2605672],
          [117.2704276, 7.2602337],
          [117.2666883, 7.2582769],
          [117.2632618, 7.2556519],
          [117.2624816, 7.2554583],
          [117.2616249, 7.2557023],
          [117.2611839, 7.2559884],
          [117.2609379, 7.2558117],
          [117.2609888, 7.2555172],
          [117.2589024, 7.2529427],
          [117.2589533, 7.2525725],
          [117.2595131, 7.2524547],
          [117.260005, 7.252034],
          [117.2612772, 7.2503345],
          [117.2615179, 7.2473996],
          [117.261379, 7.2453873],
          [117.2640421, 7.2443271],
          [117.2688087, 7.2460267],
          [117.2713022, 7.244142],
          [117.2735244, 7.2443944],
          [117.2772205, 7.2443006],
          [117.2783249, 7.2442093],
          [117.2786302, 7.2446637],
          [117.2776294, 7.2452526],
          [117.2771714, 7.2460099],
          [117.2769678, 7.2485845],
          [117.2773919, 7.2491902],
          [117.2782565, 7.2485673],
          [117.2804113, 7.2489715],
          [117.2821245, 7.247457],
          [117.2825284, 7.2456111],
          [117.2816835, 7.2442598],
          [117.2815308, 7.2429305],
          [117.2805639, 7.2410794],
          [117.2802925, 7.2401203],
          [117.2786641, 7.2395481],
          [117.2778499, 7.2384038],
          [117.2761706, 7.2365023],
          [117.2758652, 7.2359133],
          [117.277572, 7.2356911],
          [117.2786811, 7.2346176],
          [117.2788507, 7.2337257],
          [117.2807826, 7.2341477],
          [117.2824129, 7.2340791],
          [117.2831423, 7.2331031],
          [117.2827014, 7.2321445],
          [117.280547, 7.2315718],
          [117.2791353, 7.2304107],
          [117.2788507, 7.2292158],
          [117.2792578, 7.2282735],
          [117.2784775, 7.2275499],
          [117.2764589, 7.2272806],
          [117.2748814, 7.2267926],
          [117.2745082, 7.225968],
          [117.2757295, 7.2253117],
          [117.2769339, 7.2243357],
          [117.2776803, 7.2231746],
          [117.277324, 7.2224509],
          [117.2766625, 7.2221817],
          [117.2766625, 7.2211383],
          [117.2760009, 7.2201118],
          [117.2754242, 7.2203979],
          [117.2743725, 7.2194723],
          [117.2747616, 7.2188491],
          [117.2711156, 7.2124212],
          [117.2702336, 7.2124717],
          [117.2697586, 7.2108561],
          [117.2659929, 7.2087189],
          [117.2629735, 7.2076418],
          [117.2620914, 7.2078774],
          [117.2614468, 7.2076755],
          [117.2612093, 7.2071201],
          [117.2617522, 7.2065311],
          [117.2620575, 7.2054036],
          [117.2610567, 7.2032327],
          [117.260005, 7.2027615],
          [117.2593774, 7.2025763],
          [117.2594113, 7.2022061],
          [117.2598523, 7.2017181],
          [117.2599032, 7.2008598],
          [117.258771, 7.1999169],
          [117.2574762, 7.2001419],
          [117.2549573, 7.1985354],
          [117.2543696, 7.1978333],
          [117.2579679, 7.1953105],
          [117.2579439, 7.1934183],
          [117.2589875, 7.1920974],
          [117.2599328, 7.1901759],
          [117.2589252, 7.1892953],
          [117.2578127, 7.1878413],
          [117.2557587, 7.1866772],
          [117.2520644, 7.1862964],
          [117.2497134, 7.1854634],
          [117.2491857, 7.1847732],
          [117.2450356, 7.1827977],
          [117.2432015, 7.1833083],
          [117.2412213, 7.1861774],
          [117.2421809, 7.1878435],
          [117.2425647, 7.1901997],
          [117.2433563, 7.1916992],
          [117.2446997, 7.1925084],
          [117.246235, 7.1927464],
          [117.2458512, 7.1940554],
          [117.2451555, 7.1945314],
          [117.2450835, 7.195412],
          [117.2464029, 7.1968162],
          [117.2478663, 7.2008384],
          [117.2470746, 7.2041704],
          [117.246619, 7.2065815],
          [117.2479893, 7.2096955],
          [117.2476054, 7.2110044],
          [117.2482291, 7.2153597],
          [117.2478693, 7.2160499],
          [117.2494046, 7.2188819],
          [117.2495965, 7.2210714],
          [117.2500763, 7.2225945],
          [117.2515396, 7.2246412],
          [117.2512278, 7.2253314],
          [117.2489488, 7.2236417],
          [117.2478933, 7.2228563],
          [117.2477734, 7.2245936],
          [117.2466219, 7.2247126],
          [117.2464779, 7.2238559],
          [117.2454464, 7.2242367],
          [117.2449906, 7.2229991],
          [117.2444389, 7.2229039],
          [117.2438631, 7.2221186],
          [117.2454704, 7.2209048],
          [117.2456143, 7.2199291],
          [117.2451585, 7.2190247],
          [117.2443909, 7.2184773],
          [117.2435513, 7.2185249],
          [117.2433594, 7.2179062],
          [117.2436712, 7.2168114],
          [117.2433354, 7.2158119],
          [117.2438871, 7.2145743],
          [117.2434793, 7.2126228],
          [117.2418355, 7.2122359],
          [117.2410084, 7.212361],
          [117.2405024, 7.2132898],
          [117.2407206, 7.2142173],
          [117.2414623, 7.2161065],
          [117.2394946, 7.2172172],
          [117.236577, 7.2166113],
          [117.2361359, 7.2152314],
          [117.2370519, 7.2145919],
          [117.2375608, 7.2136495],
          [117.2370982, 7.213194],
          [117.2361699, 7.2128417],
          [117.2355253, 7.2122023],
          [117.2372555, 7.209375],
          [117.2346432, 7.2072546],
          [117.233388, 7.2068844],
          [117.2313079, 7.2043191],
          [117.227249, 7.1980672],
          [117.2274743, 7.1958987],
          [117.2265264, 7.1939032],
          [117.2234776, 7.1888557],
          [117.22169, 7.186237],
          [117.2212175, 7.1851339],
          [117.2199945, 7.1851615],
          [117.2172429, 7.184279],
          [117.2149637, 7.1849409],
          [117.2137963, 7.1865955],
          [117.2137407, 7.1895186],
          [117.2122184, 7.1897593],
          [117.2041792, 7.1869815],
          [117.2004798, 7.1867826],
          [117.1967302, 7.186623],
          [117.1931447, 7.18552],
          [117.1917579, 7.1859507],
          [117.1909274, 7.1879457],
          [117.1865295, 7.1898219],
          [117.1839955, 7.1891963],
          [117.1711817, 7.1851501],
          [117.1656336, 7.1798892],
          [117.1583672, 7.1793534],
          [117.1493916, 7.1742592],
          [117.1433801, 7.1688313],
          [117.1419685, 7.1668096],
          [117.1402992, 7.1656162],
          [117.1380285, 7.1629856],
          [117.1347389, 7.1631195],
          [117.1311548, 7.1614267],
          [117.1311793, 7.1595512],
          [117.1285158, 7.1590031],
          [117.1276411, 7.1582723],
          [117.1262082, 7.15647],
          [117.1243302, 7.1562142],
          [117.1235201, 7.1554469],
          [117.1229923, 7.1556174],
          [117.1187453, 7.1542169],
          [117.1178248, 7.1528163],
          [117.1153699, 7.1525727],
          [117.1124608, 7.1511356],
          [117.1113807, 7.1495402],
          [117.1119208, 7.1483101],
          [117.1118226, 7.1475915],
          [117.1099814, 7.1464954],
          [117.1096254, 7.1468851],
          [117.1078947, 7.1462153],
          [117.1073792, 7.1450948],
          [117.1046175, 7.1420622],
          [117.10085, 7.1414095],
          [117.099855, 7.1402353],
          [117.0976456, 7.1393219],
          [117.0968969, 7.1387129],
          [117.0958904, 7.1389078],
          [117.0954175, 7.1396329],
          [117.094442, 7.1397603],
          [117.0937546, 7.1391148],
          [117.0926978, 7.1387859],
          [117.0916925, 7.1389078],
          [117.0903486, 7.1393207],
          [117.0896696, 7.1398626],
          [117.0890576, 7.1394528],
          [117.0891507, 7.1389377],
          [117.0887785, 7.1381727],
          [117.0885052, 7.1380991],
          [117.0885651, 7.1376105],
          [117.0888671, 7.1376106],
          [117.0892436, 7.1369755],
          [117.0898268, 7.1355585],
          [117.0911033, 7.1349617],
          [117.0910977, 7.1344448],
          [117.0907495, 7.1340992],
          [117.0907458, 7.1335497],
          [117.0902412, 7.132549],
          [117.0900436, 7.1326144],
          [117.0896483, 7.1320727],
          [117.0898742, 7.1317178],
          [117.0892908, 7.1310708],
          [117.0890667, 7.1297536],
          [117.0887344, 7.1291399],
          [117.0874457, 7.1281214],
          [117.0874701, 7.1276297],
          [117.0890509, 7.1272454],
          [117.0898105, 7.1266135],
          [117.0899618, 7.125754],
          [117.0910788, 7.1254252],
          [117.0917683, 7.1257665],
          [117.0933127, 7.1246578],
          [117.0932882, 7.1242437],
          [117.0922571, 7.1238053],
          [117.0920944, 7.1226606],
          [117.0923676, 7.1211988],
          [117.0914716, 7.120468],
          [117.0913857, 7.1195789],
          [117.092073, 7.1190673],
          [117.0918153, 7.1182148],
          [117.0911267, 7.1181708],
          [117.0904773, 7.1186532],
          [117.0901337, 7.1180321],
          [117.0905387, 7.1174961],
          [117.0900355, 7.1170942],
          [117.0893113, 7.1171429],
          [117.0896181, 7.1167654],
          [117.0910297, 7.1161929],
          [117.0903792, 7.1153647],
          [117.0890861, 7.1152152],
          [117.0874442, 7.1143329],
          [117.0867002, 7.1136814],
          [117.086117, 7.1125351],
          [117.0862889, 7.1116952],
          [117.0866959, 7.1111868],
          [117.0861742, 7.1108397],
          [117.086475, 7.110456],
          [117.0859657, 7.109919],
          [117.085806, 7.1098409],
          [117.0855114, 7.1098896],
          [117.0852782, 7.1097191],
          [117.0850266, 7.1098165],
          [117.084867, 7.1097008],
          [117.0851186, 7.1090553],
          [117.0846461, 7.108495],
          [117.0842533, 7.1085072],
          [117.0824211, 7.1072251],
          [117.0822492, 7.105861],
          [117.0814391, 7.1054834],
          [117.0812305, 7.1051058],
          [117.0800153, 7.1044968],
          [117.0789359, 7.1032564],
          [117.0768985, 7.1023459],
          [117.0757008, 7.1013726],
          [117.0754364, 7.1002878],
          [117.0750498, 7.10083],
          [117.0745551, 7.1008387],
          [117.0738, 7.1009248],
          [117.0731022, 7.101307],
          [117.0726891, 7.1019153],
          [117.072663, 7.102699],
          [117.07334, 7.1034828],
          [117.0737132, 7.1036636],
          [117.0740344, 7.1040684],
          [117.0741385, 7.1049125],
          [117.074477, 7.1054895],
          [117.0748936, 7.1057737],
          [117.0752234, 7.1063077],
          [117.0751974, 7.1071432],
          [117.0755446, 7.1075652],
          [117.075831, 7.1084781],
          [117.0765861, 7.1097786],
          [117.0769665, 7.1117237],
          [117.0771676, 7.1143949],
          [117.0770808, 7.1159882],
          [117.0770982, 7.1165567],
          [117.0764236, 7.1178665],
          [117.0766729, 7.1183653],
          [117.0778793, 7.1188906],
          [117.0766208, 7.1191576],
          [117.0755966, 7.1190629],
          [117.0741645, 7.1183911],
          [117.0727238, 7.1184342],
          [117.0709272, 7.1207251],
          [117.0699551, 7.1219567],
          [117.0692086, 7.1229643],
          [117.0686185, 7.123016],
          [117.0683494, 7.1237394],
          [117.0674645, 7.1245508],
          [117.0659018, 7.1251002],
          [117.0652335, 7.1262111],
          [117.0640444, 7.127391],
          [117.0627078, 7.1278905],
          [117.0621957, 7.1276322],
          [117.0618536, 7.1269307],
          [117.0612844, 7.1258666],
          [117.0603818, 7.1258322],
          [117.0601561, 7.1259011],
          [117.0599565, 7.1266418],
          [117.0588108, 7.1278733],
          [117.0586585, 7.1283824],
        ],
      ],
      [
        [
          [117.6849102, 5.9430258],
          [117.6835369, 5.9452454],
          [117.6823353, 5.9472943],
          [117.6819919, 5.9496846],
          [117.6831936, 5.9530993],
          [117.6845669, 5.9548067],
          [117.6862835, 5.956514],
          [117.6878284, 5.956514],
          [117.6919483, 5.9563433],
          [117.6958965, 5.9571969],
          [117.6977848, 5.9578799],
          [117.6995014, 5.9568555],
          [117.6989864, 5.9553189],
          [117.6974415, 5.9527578],
          [117.6957249, 5.9498553],
          [117.6941799, 5.9471235],
          [117.6912617, 5.9445625],
          [117.6880001, 5.9433673],
          [117.6849102, 5.9430258],
        ],
      ],
      [
        [
          [115.99451, 5.9589099],
          [115.9942525, 5.9586751],
          [115.9942096, 5.9582803],
          [115.9941452, 5.9579068],
          [115.9939843, 5.9578108],
          [115.9938341, 5.9577788],
          [115.9936624, 5.9577147],
          [115.9933513, 5.9578535],
          [115.9931045, 5.9579815],
          [115.9928899, 5.9579282],
          [115.9926217, 5.9579068],
          [115.99238, 5.9580269],
          [115.9922677, 5.9581843],
          [115.9922314, 5.9585916],
          [115.9921604, 5.9588779],
          [115.9920853, 5.9592514],
          [115.9920316, 5.9595608],
          [115.9920102, 5.9599236],
          [115.9919994, 5.960073],
          [115.9917741, 5.9602864],
          [115.9916561, 5.9605425],
          [115.991581, 5.9610014],
          [115.9916025, 5.9613002],
          [115.991699, 5.9615776],
          [115.9918385, 5.9617377],
          [115.9919672, 5.9617697],
          [115.9920853, 5.961823],
          [115.9921496, 5.9619724],
          [115.9923213, 5.9620151],
          [115.9926324, 5.9619617],
          [115.9928899, 5.9618871],
          [115.9930509, 5.9616523],
          [115.9933835, 5.9616096],
          [115.9935873, 5.961695],
          [115.9938341, 5.9615883],
          [115.9940165, 5.9614922],
          [115.9941774, 5.9613642],
          [115.9942632, 5.9610547],
          [115.9942203, 5.960884],
          [115.9941774, 5.9607133],
          [115.9940808, 5.9605639],
          [115.9940594, 5.9604785],
          [115.9942203, 5.9603398],
          [115.9943061, 5.9602437],
          [115.9944027, 5.960105],
          [115.9944778, 5.9598809],
          [115.9949713, 5.9597742],
          [115.9953146, 5.9597529],
          [115.9956472, 5.9598062],
          [115.9957867, 5.9599023],
          [115.9959047, 5.960009],
          [115.9960227, 5.9600837],
          [115.9961193, 5.9600303],
          [115.9961515, 5.9598809],
          [115.9961408, 5.9597529],
          [115.996012, 5.9596462],
          [115.995658, 5.9594754],
          [115.9952717, 5.9592514],
          [115.9951108, 5.9591126],
          [115.99451, 5.9589099],
        ],
      ],
      [
        [
          [116.0136936, 5.9654293],
          [116.0132886, 5.9650345],
          [116.013129, 5.9649598],
          [116.0129977, 5.9649734],
          [116.0129144, 5.9651148],
          [116.0127912, 5.9659391],
          [116.0128717, 5.9670488],
          [116.0128904, 5.9672436],
          [116.0130567, 5.9677691],
          [116.0133357, 5.9681319],
          [116.0134215, 5.9681773],
          [116.0135181, 5.9681319],
          [116.0136496, 5.9677766],
          [116.0137567, 5.9677208],
          [116.0140919, 5.9676128],
          [116.0143669, 5.9674696],
          [116.0146471, 5.9672286],
          [116.0148322, 5.9669645],
          [116.0149006, 5.9667325],
          [116.0149164, 5.9665068],
          [116.0148134, 5.9662376],
          [116.0146163, 5.9660562],
          [116.014226, 5.9657494],
          [116.0136936, 5.9654293],
        ],
      ],
      [
        [
          [117.595591250334763, 4.169705260957982],
          [117.5955912, 4.1697053],
          [117.594283, 4.17072],
          [117.591174, 4.174307],
          [117.588732, 4.176255],
          [117.585956, 4.178304],
          [117.582293, 4.179739],
          [117.578629, 4.180764],
          [117.575187, 4.181277],
          [117.571857, 4.181174],
          [117.568971, 4.180867],
          [117.565751, 4.180252],
          [117.562531, 4.179227],
          [117.557536, 4.175742],
          [117.55465, 4.173283],
          [117.549543, 4.169183],
          [117.5484753, 4.168633],
          [117.546705, 4.1681942],
          [117.545793, 4.1684083],
          [117.5443125, 4.1690931],
          [117.543304, 4.169917],
          [117.5424349, 4.1710192],
          [117.5417054, 4.1725065],
          [117.540661, 4.173692],
          [117.540217, 4.174102],
          [117.539551, 4.174102],
          [117.538441, 4.17277],
          [117.5373809, 4.1717952],
          [117.5364426, 4.1706856],
          [117.5356231, 4.1691938],
          [117.535841, 4.167454],
          [117.5356759, 4.1661592],
          [117.5352138, 4.1653235],
          [117.5342818, 4.1647794],
          [117.5330982, 4.1643742],
          [117.5319092, 4.1643266],
          [117.5311656, 4.1641132],
          [117.530448, 4.163546],
          [117.530115, 4.162828],
          [117.530004, 4.162111],
          [117.529338, 4.161496],
          [117.528117, 4.161701],
          [117.526562, 4.162316],
          [117.524453, 4.163033],
          [117.522455, 4.163853],
          [117.515572, 4.16744],
          [117.510909, 4.170412],
          [117.509132, 4.17113],
          [117.507467, 4.171642],
          [117.506135, 4.17154],
          [117.505136, 4.171232],
          [117.504581, 4.17072],
          [117.503249, 4.17031],
          [117.50125, 4.17031],
          [117.500251, 4.170515],
          [117.499363, 4.171335],
          [117.498586, 4.172155],
          [117.497587, 4.172872],
          [117.496588, 4.173282],
          [117.495588, 4.17318],
          [117.494256, 4.172667],
          [117.493035, 4.172052],
          [117.491037, 4.17236],
          [117.490815, 4.173385],
          [117.490815, 4.174614],
          [117.490371, 4.175434],
          [117.489372, 4.175742],
          [117.488039, 4.175434],
          [117.486263, 4.17441],
          [117.484598, 4.173282],
          [117.484376, 4.172462],
          [117.484487, 4.171335],
          [117.484043, 4.170617],
          [117.483155, 4.17031],
          [117.4816, 4.170822],
          [117.479713, 4.17154],
          [117.478714, 4.171232],
          [117.476161, 4.171232],
          [117.475828, 4.17154],
          [117.475606, 4.172257],
          [117.47505, 4.17277],
          [117.474273, 4.173077],
          [117.473274, 4.17318],
          [117.472275, 4.173385],
          [117.471387, 4.173897],
          [117.471054, 4.174307],
          [117.469833, 4.174512],
          [117.469278, 4.174307],
          [117.468056, 4.174307],
          [117.467501, 4.174717],
          [117.467279, 4.175537],
          [117.466835, 4.176049],
          [117.465503, 4.176664],
          [117.464615, 4.176972],
          [117.463616, 4.177484],
          [117.462284, 4.178407],
          [117.461284, 4.179022],
          [117.459841, 4.179329],
          [117.459064, 4.179124],
          [117.457954, 4.178714],
          [117.457177, 4.179124],
          [117.457177, 4.179739],
          [117.457732, 4.180661],
          [117.457621, 4.181891],
          [117.456844, 4.183326],
          [117.456178, 4.184249],
          [117.455512, 4.184864],
          [117.453846, 4.185171],
          [117.453291, 4.185479],
          [117.45318, 4.186299],
          [117.45318, 4.187323],
          [117.452625, 4.188041],
          [117.451404, 4.188758],
          [117.449961, 4.189476],
          [117.449406, 4.190091],
          [117.448962, 4.191218],
          [117.448518, 4.191936],
          [117.447741, 4.192243],
          [117.446741, 4.192038],
          [117.44663, 4.191321],
          [117.446408, 4.190808],
          [117.445409, 4.190706],
          [117.444521, 4.191116],
          [117.443522, 4.192141],
          [117.442523, 4.192858],
          [117.441635, 4.193575],
          [117.44119, 4.194805],
          [117.440746, 4.196343],
          [117.440413, 4.198495],
          [117.440524, 4.199725],
          [117.440969, 4.200545],
          [117.441302, 4.20075],
          [117.441635, 4.201262],
          [117.441302, 4.202287],
          [117.440413, 4.204234],
          [117.439969, 4.205054],
          [117.43897, 4.205772],
          [117.438526, 4.206284],
          [117.438526, 4.206899],
          [117.438415, 4.207924],
          [117.437527, 4.208744],
          [117.436084, 4.209051],
          [117.434529, 4.209256],
          [117.431199, 4.210691],
          [117.4302, 4.211716],
          [117.429423, 4.213048],
          [117.428423, 4.214074],
          [117.426314, 4.216431],
          [117.425759, 4.217456],
          [117.425981, 4.218583],
          [117.42587, 4.219915],
          [117.425204, 4.220735],
          [117.424427, 4.221453],
          [117.423983, 4.222273],
          [117.424427, 4.2234],
          [117.424871, 4.22422],
          [117.425648, 4.224528],
          [117.426425, 4.224323],
          [117.427091, 4.22422],
          [117.427535, 4.22422],
          [117.428201, 4.224937],
          [117.428645, 4.225655],
          [117.42909, 4.22709],
          [117.428979, 4.228832],
          [117.428756, 4.229755],
          [117.428312, 4.230882],
          [117.426203, 4.232932],
          [117.423983, 4.233957],
          [117.42254, 4.234572],
          [117.421207, 4.234674],
          [117.419431, 4.234879],
          [117.41821, 4.235597],
          [117.415212, 4.237647],
          [117.413436, 4.238466],
          [117.41166, 4.238261],
          [117.410883, 4.238364],
          [117.409439, 4.240311],
          [117.409106, 4.242361],
          [117.40955, 4.243591],
          [117.410438, 4.245231],
          [117.41166, 4.246768],
          [117.411882, 4.247691],
          [117.410883, 4.248921],
          [117.409439, 4.250355],
          [117.407663, 4.252303],
          [117.406442, 4.253738],
          [117.40511, 4.254353],
          [117.403666, 4.254558],
          [117.401002, 4.255583],
          [117.4000195, 4.2560365],
          [117.398116, 4.256915],
          [117.397227, 4.257632],
          [117.396783, 4.258452],
          [117.395673, 4.25876],
          [117.393009, 4.258862],
          [117.391455, 4.258555],
          [117.388013, 4.25835],
          [117.38657, 4.258657],
          [117.38546, 4.259272],
          [117.384794, 4.260092],
          [117.384016, 4.260912],
          [117.381796, 4.262347],
          [117.380686, 4.264294],
          [117.38002, 4.265627],
          [117.37891, 4.266652],
          [117.377244, 4.267881],
          [117.375579, 4.269419],
          [117.374136, 4.270854],
          [117.373248, 4.272289],
          [117.372471, 4.275978],
          [117.371583, 4.277208],
          [117.371138, 4.279258],
          [117.371694, 4.281205],
          [117.372582, 4.282743],
          [117.37236, 4.283768],
          [117.371583, 4.285203],
          [117.370694, 4.285817],
          [117.368918, 4.286227],
          [117.365699, 4.286022],
          [117.363811, 4.28633],
          [117.361924, 4.28715],
          [117.360925, 4.28797],
          [117.358594, 4.28838],
          [117.352155, 4.289712],
          [117.350934, 4.289917],
          [117.347603, 4.28961],
          [117.34616, 4.289917],
          [117.34183, 4.291045],
          [117.340387, 4.291865],
          [117.338056, 4.293812],
          [117.3359757, 4.2949564],
          [117.3308086, 4.2961291],
          [117.3288774, 4.2983545],
          [117.3268175, 4.3000235],
          [117.3254013, 4.299082],
          [117.3246717, 4.300751],
          [117.3232984, 4.3027195],
          [117.3202085, 4.3036182],
          [117.3185348, 4.3013073],
          [117.3165178, 4.300109],
          [117.3119258, 4.2994671],
          [117.3094797, 4.3031474],
          [117.308859, 4.306418],
          [117.309192, 4.307853],
          [117.308636, 4.30898],
          [117.307526, 4.309698],
          [117.305639, 4.310825],
          [117.303752, 4.311338],
          [117.301864, 4.311338],
          [117.298756, 4.310005],
          [117.297424, 4.309903],
          [117.296203, 4.31021],
          [117.295203, 4.311543],
          [117.294315, 4.312875],
          [117.293205, 4.313797],
          [117.291429, 4.314515],
          [117.289653, 4.315437],
          [117.288987, 4.31595],
          [117.288653, 4.31718],
          [117.288653, 4.318204],
          [117.288209, 4.319332],
          [117.287432, 4.321074],
          [117.285767, 4.323535],
          [117.284879, 4.325072],
          [117.285101, 4.327634],
          [117.285212, 4.330299],
          [117.284879, 4.332656],
          [117.284102, 4.334296],
          [117.282992, 4.335628],
          [117.280438, 4.337473],
          [117.275776, 4.341061],
          [117.27411, 4.342905],
          [117.273111, 4.344648],
          [117.271557, 4.347825],
          [117.270447, 4.349055],
          [117.26967, 4.34967],
          [117.269114, 4.351617],
          [117.269003, 4.352335],
          [117.268226, 4.353155],
          [117.267116, 4.353564],
          [117.266339, 4.354384],
          [117.265118, 4.356537],
          [117.26423, 4.357562],
          [117.263009, 4.358382],
          [117.26101, 4.359611],
          [117.259012, 4.360636],
          [117.257236, 4.361149],
          [117.255459, 4.361456],
          [117.252906, 4.363096],
          [117.250242, 4.364941],
          [117.249242, 4.365863],
          [117.248687, 4.367298],
          [117.248909, 4.36863],
          [117.248798, 4.370475],
          [117.248132, 4.372115],
          [117.246689, 4.373448],
          [117.245912, 4.37396],
          [117.244913, 4.37396],
          [117.243692, 4.374063],
          [117.242359, 4.374063],
          [117.240694, 4.372935],
          [117.240028, 4.372115],
          [117.235587, 4.370168],
          [117.2335337, 4.3697375],
          [117.233145, 4.369656],
          [117.231591, 4.369656],
          [117.229592, 4.370168],
          [117.227816, 4.370373],
          [117.225707, 4.369553],
          [117.224486, 4.368631],
          [117.22382, 4.366888],
          [117.223598, 4.365453],
          [117.22282, 4.364634],
          [117.221599, 4.363199],
          [117.218269, 4.361149],
          [117.216381, 4.360226],
          [117.21516, 4.359201],
          [117.215049, 4.357869],
          [117.21516, 4.356332],
          [117.214383, 4.355204],
          [117.211608, 4.354282],
          [117.210498, 4.353052],
          [117.210053, 4.351617],
          [117.20972, 4.349977],
          [117.209165, 4.34885],
          [117.208166, 4.34762],
          [117.207278, 4.346288],
          [117.206945, 4.344955],
          [117.206834, 4.343725],
          [117.205835, 4.342495],
          [117.204725, 4.341778],
          [117.203281, 4.340343],
          [117.200839, 4.337473],
          [117.198508, 4.336551],
          [117.19651, 4.335833],
          [117.193068, 4.336448],
          [117.19107, 4.336961],
          [117.190293, 4.337371],
          [117.189738, 4.338088],
          [117.187517, 4.338601],
          [117.185963, 4.338601],
          [117.184187, 4.338088],
          [117.182855, 4.337473],
          [117.1813, 4.337166],
          [117.179302, 4.337063],
          [117.177304, 4.336551],
          [117.175194, 4.336551],
          [117.173529, 4.336858],
          [117.1682, 4.337678],
          [117.166424, 4.338396],
          [117.164759, 4.339523],
          [117.163871, 4.340958],
          [117.163316, 4.342393],
          [117.162983, 4.34352],
          [117.163427, 4.34475],
          [117.164897, 4.346953],
          [117.164231, 4.347312],
          [117.163343, 4.347056],
          [117.162233, 4.346082],
          [117.161178, 4.345775],
          [117.160457, 4.34598],
          [117.160124, 4.346646],
          [117.160207, 4.347415],
          [117.159541, 4.34844],
          [117.158653, 4.349055],
          [117.157987, 4.34926],
          [117.157209, 4.34967],
          [117.156876, 4.35049],
          [117.156987, 4.351412],
          [117.156876, 4.35254],
          [117.156543, 4.35295],
          [117.155211, 4.353257],
          [117.154212, 4.353155],
          [117.152991, 4.353359],
          [117.151325, 4.353974],
          [117.149771, 4.353667],
          [117.149216, 4.353155],
          [117.147773, 4.352232],
          [117.146552, 4.351617],
          [117.145109, 4.351515],
          [117.142555, 4.35172],
          [117.141889, 4.351617],
          [117.141334, 4.351105],
          [117.141334, 4.350387],
          [117.141556, 4.34967],
          [117.142222, 4.349055],
          [117.142666, 4.34844],
          [117.142666, 4.347415],
          [117.142333, 4.346083],
          [117.141778, 4.345058],
          [117.140668, 4.34393],
          [117.139447, 4.343725],
          [117.138448, 4.343828],
          [117.136782, 4.344545],
          [117.134118, 4.345468],
          [117.132453, 4.345365],
          [117.131342, 4.34516],
          [117.130565, 4.34475],
          [117.128678, 4.343315],
          [117.127346, 4.342598],
          [117.125681, 4.342188],
          [117.12346, 4.341983],
          [117.12124, 4.341573],
          [117.120241, 4.341266],
          [117.119242, 4.340753],
          [117.118575, 4.339728],
          [117.118353, 4.339318],
          [117.117909, 4.338293],
          [117.117132, 4.337268],
          [117.116133, 4.336141],
          [117.115356, 4.335526],
          [117.114801, 4.335219],
          [117.112581, 4.335219],
          [117.111026, 4.334911],
          [117.109805, 4.334604],
          [117.108806, 4.334194],
          [117.108029, 4.333681],
          [117.107363, 4.333374],
          [117.106142, 4.333271],
          [117.105364, 4.333271],
          [117.104476, 4.333476],
          [117.103255, 4.333886],
          [117.102145, 4.334091],
          [117.100813, 4.334501],
          [117.098814, 4.335219],
          [117.097704, 4.335424],
          [117.09393, 4.335833],
          [117.091931, 4.335424],
          [117.091487, 4.334809],
          [117.090377, 4.334399],
          [117.088823, 4.334501],
          [117.087602, 4.334706],
          [117.086381, 4.334706],
          [117.08416, 4.334501],
          [117.082939, 4.334706],
          [117.081496, 4.335321],
          [117.079942, 4.335731],
          [117.076722, 4.336141],
          [117.072614, 4.336448],
          [117.071282, 4.336653],
          [117.070727, 4.337166],
          [117.06995, 4.337473],
          [117.068285, 4.337883],
          [117.06662, 4.338396],
          [117.065398, 4.338601],
          [117.064399, 4.338908],
          [117.063844, 4.339318],
          [117.063067, 4.340036],
          [117.061735, 4.341778],
          [117.060514, 4.34229],
          [117.057294, 4.341983],
          [117.054741, 4.341983],
          [117.052743, 4.342495],
          [117.050411, 4.343725],
          [117.048191, 4.34475],
          [117.046304, 4.345775],
          [117.044195, 4.346698],
          [117.041863, 4.3468],
          [117.04042, 4.34639],
          [117.039115, 4.345672],
          [117.037339, 4.344955],
          [117.035646, 4.344033],
          [117.034314, 4.343623],
          [117.032982, 4.342803],
          [117.030762, 4.34188],
          [117.029651, 4.341573],
          [117.028874, 4.340548],
          [117.027875, 4.338703],
          [117.027098, 4.337576],
          [117.026432, 4.336141],
          [117.025766, 4.334296],
          [117.025433, 4.332554],
          [117.025433, 4.330504],
          [117.025211, 4.329274],
          [117.024767, 4.328147],
          [117.02399, 4.326712],
          [117.02299, 4.325687],
          [117.022324, 4.323944],
          [117.022102, 4.321895],
          [117.021658, 4.32046],
          [117.02077, 4.319332],
          [117.019327, 4.318717],
          [117.016774, 4.31923],
          [117.014553, 4.32005],
          [117.012888, 4.320357],
          [117.011001, 4.320255],
          [117.009446, 4.32005],
          [117.008003, 4.320152],
          [117.006782, 4.320562],
          [117.005783, 4.32169],
          [117.005006, 4.322817],
          [117.004895, 4.324457],
          [117.005117, 4.326404],
          [117.006338, 4.331426],
          [117.00656, 4.333169],
          [117.006671, 4.335014],
          [117.00656, 4.336961],
          [117.006227, 4.339831],
          [117.006338, 4.341983],
          [117.006116, 4.343828],
          [117.005228, 4.34516],
          [117.004118, 4.346185],
          [117.003008, 4.346493],
          [117.001342, 4.34639],
          [117.00001, 4.346185],
          [116.992737, 4.343439],
          [116.988061, 4.342587],
          [116.983047, 4.342571],
          [116.976354, 4.344221],
          [116.97351, 4.34488],
          [116.970184, 4.341023],
          [116.967183, 4.339174],
          [116.965015, 4.337996],
          [116.962499, 4.340161],
          [116.960979, 4.343835],
          [116.95896, 4.346839],
          [116.953263, 4.350165],
          [116.952414, 4.353172],
          [116.953074, 4.355181],
          [116.942025, 4.359158],
          [116.93903, 4.356137],
          [116.93668, 4.358303],
          [116.934665, 4.360471],
          [116.930155, 4.359787],
          [116.926969, 4.362117],
          [116.924632, 4.36144],
          [116.918592, 4.366604],
          [116.915255, 4.365422],
          [116.910732, 4.367581],
          [116.907719, 4.368741],
          [116.903875, 4.368561],
          [116.898867, 4.367039],
          [116.894545, 4.361673],
          [116.891893, 4.356814],
          [116.886898, 4.352281],
          [116.883237, 4.348757],
          [116.879229, 4.347907],
          [116.87472, 4.347056],
          [116.873729, 4.344376],
          [116.87224, 4.341027],
          [116.868067, 4.339674],
          [116.865072, 4.336821],
          [116.861053, 4.33848],
          [116.859379, 4.338975],
          [116.856043, 4.337626],
          [116.852694, 4.338952],
          [116.850191, 4.338107],
          [116.848013, 4.339271],
          [116.845508, 4.33876],
          [116.845859, 4.335082],
          [116.847201, 4.333916],
          [116.844209, 4.330227],
          [116.840211, 4.327203],
          [116.834182, 4.330025],
          [116.828659, 4.331678],
          [116.824972, 4.333839],
          [116.821115, 4.336668],
          [116.818583, 4.342178],
          [116.81639, 4.346685],
          [116.814036, 4.349687],
          [116.810854, 4.351181],
          [116.806005, 4.351666],
          [116.801517, 4.346131],
          [116.797199, 4.340096],
          [116.789688, 4.337895],
          [116.785656, 4.342396],
          [116.782438, 4.351582],
          [116.782078, 4.357267],
          [116.777369, 4.363605],
          [116.770999, 4.367595],
          [116.769295, 4.37478],
          [116.762255, 4.379103],
          [116.756718, 4.383598],
          [116.75257, 4.389008],
          [116.752053, 4.389008],
          [116.751535, 4.388897],
          [116.74965, 4.38764],
          [116.748984, 4.387085],
          [116.748504, 4.386346],
          [116.747764, 4.38398],
          [116.747025, 4.382982],
          [116.746286, 4.382243],
          [116.745842, 4.381947],
          [116.745399, 4.381725],
          [116.744659, 4.381503],
          [116.743698, 4.381318],
          [116.742146, 4.381171],
          [116.741406, 4.381023],
          [116.740852, 4.380653],
          [116.74063, 4.380209],
          [116.740482, 4.379359],
          [116.740371, 4.372779],
          [116.740408, 4.372002],
          [116.740667, 4.371079],
          [116.740852, 4.369933],
          [116.740889, 4.367456],
          [116.740741, 4.367012],
          [116.740334, 4.366236],
          [116.740001, 4.365829],
          [116.73904, 4.364831],
          [116.73856, 4.364018],
          [116.738264, 4.363242],
          [116.738153, 4.362613],
          [116.737931, 4.362059],
          [116.737488, 4.361467],
          [116.737081, 4.361061],
          [116.73649, 4.360728],
          [116.736083, 4.360432],
          [116.735861, 4.360062],
          [116.735454, 4.359138],
          [116.734309, 4.357401],
          [116.734124, 4.356698],
          [116.733717, 4.355516],
          [116.733532, 4.354407],
          [116.733495, 4.353963],
          [116.733606, 4.35315],
          [116.733495, 4.352041],
          [116.733089, 4.351376],
          [116.731462, 4.349786],
          [116.731056, 4.349675],
          [116.729947, 4.349897],
          [116.729503, 4.34986],
          [116.728986, 4.349527],
          [116.728394, 4.348603],
          [116.728172, 4.34816],
          [116.727803, 4.34779],
          [116.727433, 4.347716],
          [116.726915, 4.347716],
          [116.726435, 4.347605],
          [116.726213, 4.347383],
          [116.725991, 4.347014],
          [116.725585, 4.346644],
          [116.723884, 4.346053],
          [116.722664, 4.34572],
          [116.721888, 4.345609],
          [116.719855, 4.34572],
          [116.718524, 4.34572],
          [116.717637, 4.345646],
          [116.716898, 4.345461],
          [116.716417, 4.345276],
          [116.716084, 4.344759],
          [116.715086, 4.342504],
          [116.71468, 4.342208],
          [116.714014, 4.341875],
          [116.71065, 4.341173],
          [116.709578, 4.340692],
          [116.708617, 4.340101],
          [116.70821, 4.33962],
          [116.707989, 4.339066],
          [116.70773, 4.337624],
          [116.707545, 4.337107],
          [116.707286, 4.336589],
          [116.706732, 4.336035],
          [116.706288, 4.335813],
          [116.70566, 4.335702],
          [116.70444, 4.335776],
          [116.703848, 4.335702],
          [116.703368, 4.335406],
          [116.702924, 4.334889],
          [116.702333, 4.333632],
          [116.701778, 4.333299],
          [116.701224, 4.333262],
          [116.700743, 4.333336],
          [116.699597, 4.333964],
          [116.698932, 4.334556],
          [116.698414, 4.335221],
          [116.698193, 4.335887],
          [116.697268, 4.337328],
          [116.696529, 4.338031],
          [116.695975, 4.3384],
          [116.695309, 4.338622],
          [116.694496, 4.338659],
          [116.693757, 4.338881],
          [116.69213, 4.33999],
          [116.690688, 4.339953],
          [116.689506, 4.340101],
          [116.687546, 4.340618],
          [116.686142, 4.340655],
          [116.685402, 4.340581],
          [116.684182, 4.340138],
          [116.683887, 4.340101],
          [116.683295, 4.340138],
          [116.681484, 4.340397],
          [116.681077, 4.340323],
          [116.680782, 4.340101],
          [116.679562, 4.338696],
          [116.679081, 4.338289],
          [116.678601, 4.338289],
          [116.678046, 4.338585],
          [116.67191, 4.345535],
          [116.669803, 4.348012],
          [116.668102, 4.350378],
          [116.667326, 4.351117],
          [116.666993, 4.351524],
          [116.666771, 4.351524],
          [116.666328, 4.351413],
          [116.665477, 4.350599],
          [116.664072, 4.34949],
          [116.663296, 4.348751],
          [116.661522, 4.3479],
          [116.660745, 4.347605],
          [116.658934, 4.347235],
          [116.658158, 4.346828],
          [116.657381, 4.346015],
          [116.656162, 4.345128],
          [116.655459, 4.3445],
          [116.654757, 4.343797],
          [116.654276, 4.343243],
          [116.6535, 4.342873],
          [116.652576, 4.34254],
          [116.6518, 4.342318],
          [116.651319, 4.34206],
          [116.650986, 4.341542],
          [116.650875, 4.340618],
          [116.65106, 4.340064],
          [116.651984, 4.338326],
          [116.652354, 4.337476],
          [116.652428, 4.336958],
          [116.652391, 4.336515],
          [116.652243, 4.336108],
          [116.6518, 4.335886],
          [116.651282, 4.335923],
          [116.65058, 4.336182],
          [116.648399, 4.337069],
          [116.64729, 4.337587],
          [116.646292, 4.338104],
          [116.644998, 4.339398],
          [116.644517, 4.339805],
          [116.644111, 4.339805],
          [116.643741, 4.339694],
          [116.643113, 4.338733],
          [116.642558, 4.337291],
          [116.642151, 4.336552],
          [116.641782, 4.336182],
          [116.641153, 4.335849],
          [116.639379, 4.335591],
          [116.638677, 4.335591],
          [116.637863, 4.335775],
          [116.634462, 4.336884],
          [116.633723, 4.336958],
          [116.632984, 4.336847],
          [116.632577, 4.336589],
          [116.631949, 4.336071],
          [116.630581, 4.334556],
          [116.630064, 4.33426],
          [116.629546, 4.334334],
          [116.629176, 4.334741],
          [116.628659, 4.335184],
          [116.628215, 4.335517],
          [116.627291, 4.336071],
          [116.625849, 4.337254],
          [116.624666, 4.337993],
          [116.623114, 4.338622],
          [116.622485, 4.338585],
          [116.622153, 4.338326],
          [116.621229, 4.336441],
          [116.620637, 4.335849],
          [116.620378, 4.335738],
          [116.620268, 4.335738],
          [116.619898, 4.335849],
          [116.619417, 4.336219],
          [116.615129, 4.34132],
          [116.614348, 4.343034],
          [116.613983, 4.344583],
          [116.613983, 4.345796],
          [116.614348, 4.346806],
          [116.61515, 4.348288],
          [116.615832, 4.349305],
          [116.617421, 4.351856],
          [116.61805, 4.353224],
          [116.618493, 4.354259],
          [116.618678, 4.356292],
          [116.618419, 4.357697],
          [116.618124, 4.358473],
          [116.61731, 4.360063],
          [116.616756, 4.360506],
          [116.61561, 4.361134],
          [116.615166, 4.361504],
          [116.614686, 4.362021],
          [116.614094, 4.36313],
          [116.613688, 4.364202],
          [116.613577, 4.364868],
          [116.613946, 4.37008],
          [116.613873, 4.370967],
          [116.613688, 4.372187],
          [116.613355, 4.373851],
          [116.612653, 4.375699],
          [116.61195, 4.376697],
          [116.608993, 4.378435],
          [116.607884, 4.378767],
          [116.60696, 4.378804],
          [116.605037, 4.378546],
          [116.602856, 4.377917],
          [116.601599, 4.377363],
          [116.600564, 4.37655],
          [116.599788, 4.375404],
          [116.599123, 4.373851],
          [116.59879, 4.373592],
          [116.598309, 4.373297],
          [116.597496, 4.373075],
          [116.596572, 4.37289],
          [116.595944, 4.372594],
          [116.595278, 4.372373],
          [116.594613, 4.372483],
          [116.592469, 4.373149],
          [116.590658, 4.373592],
          [116.588846, 4.373518],
          [116.587996, 4.373629],
          [116.587146, 4.373925],
          [116.586406, 4.374295],
          [116.585297, 4.375441],
          [116.584706, 4.376365],
          [116.584484, 4.377067],
          [116.584447, 4.378102],
          [116.584188, 4.380431],
          [116.583893, 4.38154],
          [116.583523, 4.382205],
          [116.583153, 4.382575],
          [116.582229, 4.383092],
          [116.581564, 4.383647],
          [116.581305, 4.384128],
          [116.581194, 4.384793],
          [116.581194, 4.385939],
          [116.581083, 4.386715],
          [116.580788, 4.387343],
          [116.57783, 4.391558],
          [116.576721, 4.393406],
          [116.576056, 4.394885],
          [116.575575, 4.396548],
          [116.575243, 4.397325],
          [116.574836, 4.397953],
          [116.572322, 4.400504],
          [116.571509, 4.401834],
          [116.570991, 4.402869],
          [116.570585, 4.404015],
          [116.569772, 4.40579],
          [116.568884, 4.407194],
          [116.568699, 4.407675],
          [116.568182, 4.407823],
          [116.567627, 4.407823],
          [116.567036, 4.407564],
          [116.566445, 4.407416],
          [116.565188, 4.407305],
          [116.564707, 4.40712],
          [116.564374, 4.406677],
          [116.5643, 4.406233],
          [116.564411, 4.405013],
          [116.5643, 4.404422],
          [116.563746, 4.402685],
          [116.56297, 4.400688],
          [116.562785, 4.399764],
          [116.562637, 4.398582],
          [116.562563, 4.396918],
          [116.562822, 4.394885],
          [116.563228, 4.393332],
          [116.563376, 4.392186],
          [116.563265, 4.391669],
          [116.562822, 4.390929],
          [116.561565, 4.389599],
          [116.559199, 4.38812],
          [116.55761, 4.386826],
          [116.555355, 4.385495],
          [116.554726, 4.385274],
          [116.554024, 4.385274],
          [116.553211, 4.385385],
          [116.551621, 4.385717],
          [116.550327, 4.385902],
          [116.54944, 4.385976],
          [116.548922, 4.385976],
          [116.548442, 4.385865],
          [116.548035, 4.385385],
          [116.54785, 4.384941],
          [116.547592, 4.382908],
          [116.54737, 4.382057],
          [116.547111, 4.381355],
          [116.546742, 4.380837],
          [116.546187, 4.380431],
          [116.544782, 4.379728],
          [116.539053, 4.377806],
          [116.537722, 4.377178],
          [116.536983, 4.376734],
          [116.536428, 4.376217],
          [116.536021, 4.375662],
          [116.535689, 4.374368],
          [116.534839, 4.37045],
          [116.532768, 4.361948],
          [116.532399, 4.360839],
          [116.531881, 4.359545],
          [116.531512, 4.358769],
          [116.531068, 4.357031],
          [116.531068, 4.355774],
          [116.531142, 4.35522],
          [116.531327, 4.354591],
          [116.531401, 4.353889],
          [116.531253, 4.3523],
          [116.531068, 4.351486],
          [116.530735, 4.350673],
          [116.528998, 4.347457],
          [116.52885, 4.34705],
          [116.52885, 4.346681],
          [116.528961, 4.346015],
          [116.52922, 4.345719],
          [116.529811, 4.34535],
          [116.53007, 4.34498],
          [116.530329, 4.3445],
          [116.530587, 4.343575],
          [116.531363, 4.341986],
          [116.53177, 4.341395],
          [116.532435, 4.340692],
          [116.534394, 4.339066],
          [116.535984, 4.33792],
          [116.538682, 4.336589],
          [116.539422, 4.33585],
          [116.53957, 4.33548],
          [116.539607, 4.335036],
          [116.539311, 4.332633],
          [116.53883, 4.329602],
          [116.538313, 4.327199],
          [116.537647, 4.324427],
          [116.537389, 4.323724],
          [116.537019, 4.323244],
          [116.536317, 4.322985],
          [116.535392, 4.322985],
          [116.533766, 4.323244],
          [116.528886, 4.324427],
          [116.527482, 4.324907],
          [116.525707, 4.32561],
          [116.523822, 4.325979],
          [116.521493, 4.325942],
          [116.520273, 4.325832],
          [116.519275, 4.325573],
          [116.517538, 4.32487],
          [116.516281, 4.324279],
          [116.515616, 4.323909],
          [116.514839, 4.323133],
          [116.514433, 4.3228],
          [116.514026, 4.322579],
          [116.513435, 4.322505],
          [116.512769, 4.322505],
          [116.512141, 4.322615],
          [116.511549, 4.3228],
          [116.510958, 4.323059],
          [116.509516, 4.323355],
          [116.508333, 4.323355],
          [116.507742, 4.323281],
          [116.507224, 4.323318],
          [116.506707, 4.323503],
          [116.506115, 4.323835],
          [116.505524, 4.323983],
          [116.504674, 4.324094],
          [116.504156, 4.324242],
          [116.503491, 4.324538],
          [116.502714, 4.324723],
          [116.498755, 4.327608],
          [116.497733, 4.331617],
          [116.496049, 4.334119],
          [116.493367, 4.335781],
          [116.4917, 4.334771],
          [116.48902, 4.335931],
          [116.485673, 4.336921],
          [116.48502, 4.333741],
          [116.482521, 4.332226],
          [116.483542, 4.328384],
          [116.483909, 4.321864],
          [116.480752, 4.318172],
          [116.477914, 4.317492],
          [116.480122, 4.310477],
          [116.477793, 4.308127],
          [116.475474, 4.304104],
          [116.468795, 4.30274],
          [116.464291, 4.301217],
          [116.460964, 4.298027],
          [116.456627, 4.296504],
          [116.453608, 4.298666],
          [116.450102, 4.297816],
          [116.445113, 4.292947],
          [116.440956, 4.288749],
          [116.436603, 4.290237],
          [116.433402, 4.295408],
          [116.434038, 4.301932],
          [116.434018, 4.305946],
          [116.433339, 4.30795],
          [116.433128, 4.316644],
          [116.433934, 4.3225],
          [116.432749, 4.325673],
          [116.427384, 4.328996],
          [116.426196, 4.332503],
          [116.423352, 4.33316],
          [116.416333, 4.332965],
          [116.413302, 4.337467],
          [116.409108, 4.340628],
          [116.406246, 4.344629],
          [116.400189, 4.352631],
          [116.399673, 4.355472],
          [116.399654, 4.359318],
          [116.394786, 4.363479],
          [116.392108, 4.364137],
          [116.388106, 4.362281],
          [116.384767, 4.361598],
          [116.382941, 4.35925],
          [116.379922, 4.361244],
          [116.377725, 4.366085],
          [116.377689, 4.373108],
          [116.374824, 4.377611],
          [116.370974, 4.378766],
          [116.366952, 4.380923],
          [116.365287, 4.379578],
          [116.360766, 4.381232],
          [116.35958, 4.384404],
          [116.353037, 4.389227],
          [116.348513, 4.391382],
          [116.344167, 4.391364],
          [116.337494, 4.388995],
          [116.3355, 4.386646],
          [116.330317, 4.387126],
          [116.325642, 4.386103],
          [116.321129, 4.386084],
          [116.316463, 4.383556],
          [116.316147, 4.380043],
          [116.314329, 4.376022],
          [116.312168, 4.373839],
          [116.30749, 4.373485],
          [116.304157, 4.371465],
          [116.29998, 4.37128],
          [116.294122, 4.372927],
          [116.289936, 4.374247],
          [116.284773, 4.370881],
          [116.281286, 4.366519],
          [116.277292, 4.363157],
          [116.271777, 4.363134],
          [116.264917, 4.36461],
          [116.259405, 4.363918],
          [116.25537, 4.368582],
          [116.252337, 4.373084],
          [116.251439, 4.379833],
          [116.251032, 4.379579],
          [116.250499, 4.378969],
          [116.250042, 4.378359],
          [116.249762, 4.378105],
          [116.249051, 4.377826],
          [116.248289, 4.3778],
          [116.247374, 4.377902],
          [116.246052, 4.378156],
          [116.243283, 4.379274],
          [116.240158, 4.380367],
          [116.238557, 4.380722],
          [116.230479, 4.382196],
          [116.224229, 4.383441],
          [116.223289, 4.383568],
          [116.222527, 4.383517],
          [116.221384, 4.383237],
          [116.22019, 4.382729],
          [116.219275, 4.382145],
          [116.218614, 4.381815],
          [116.217827, 4.381611],
          [116.213965, 4.381332],
          [116.212924, 4.381103],
          [116.209976, 4.379884],
          [116.209138, 4.379325],
          [116.208528, 4.37869],
          [116.207639, 4.378233],
          [116.206674, 4.378131],
          [116.2052, 4.378258],
          [116.204616, 4.378487],
          [116.204108, 4.378893],
          [116.203651, 4.379503],
          [116.203244, 4.381154],
          [116.202863, 4.381637],
          [116.202533, 4.38184],
          [116.202075, 4.381942],
          [116.201415, 4.381916],
          [116.200627, 4.381764],
          [116.199967, 4.381535],
          [116.199408, 4.381459],
          [116.198773, 4.38151],
          [116.197731, 4.38184],
          [116.196791, 4.382247],
          [116.195064, 4.382577],
          [116.194098, 4.382551],
          [116.193412, 4.382424],
          [116.192625, 4.382374],
          [116.190948, 4.382704],
          [116.189957, 4.382729],
          [116.188357, 4.383059],
          [116.187315, 4.383187],
          [116.18546, 4.382932],
          [116.183453, 4.382323],
          [116.182285, 4.382272],
          [116.181268, 4.382348],
          [116.180278, 4.382551],
          [116.179363, 4.382907],
          [116.178169, 4.383898],
          [116.177432, 4.384635],
          [116.176924, 4.385676],
          [116.176543, 4.38687],
          [116.176416, 4.387886],
          [116.176009, 4.389309],
          [116.175527, 4.390554],
          [116.174917, 4.391418],
          [116.174002, 4.392231],
          [116.173367, 4.392637],
          [116.17258, 4.392967],
          [116.171055, 4.393069],
          [116.170674, 4.392866],
          [116.170395, 4.392231],
          [116.170344, 4.391545],
          [116.17009, 4.390122],
          [116.169607, 4.388826],
          [116.169328, 4.388268],
          [116.167981, 4.386794],
          [116.167575, 4.386032],
          [116.166558, 4.382323],
          [116.165516, 4.379273],
          [116.164881, 4.376657],
          [116.1645, 4.374802],
          [116.164475, 4.372922],
          [116.164018, 4.370941],
          [116.164018, 4.370077],
          [116.164119, 4.368553],
          [116.163967, 4.367714],
          [116.163611, 4.366495],
          [116.162874, 4.363065],
          [116.16262, 4.36055],
          [116.16262, 4.357933],
          [116.162493, 4.356307],
          [116.161858, 4.351658],
          [116.161909, 4.350566],
          [116.162265, 4.349676],
          [116.163332, 4.346069],
          [116.163459, 4.344976],
          [116.163433, 4.344036],
          [116.163205, 4.343045],
          [116.162773, 4.342334],
          [116.162163, 4.341775],
          [116.161477, 4.34142],
          [116.160715, 4.341216],
          [116.158708, 4.341089],
          [116.156904, 4.340632],
          [116.155862, 4.340429],
          [116.153448, 4.340556],
          [116.152661, 4.340479],
          [116.15167, 4.3402],
          [116.149841, 4.339463],
          [116.148367, 4.338777],
          [116.147453, 4.338167],
          [116.146894, 4.337659],
          [116.146436, 4.336923],
          [116.146055, 4.336033],
          [116.145547, 4.335322],
          [116.144912, 4.334687],
          [116.144226, 4.33423],
          [116.143591, 4.334001],
          [116.142854, 4.333798],
          [116.141406, 4.333798],
          [116.140466, 4.333976],
          [116.139729, 4.33423],
          [116.139094, 4.334687],
          [116.138078, 4.335729],
          [116.137545, 4.33611],
          [116.136935, 4.336313],
          [116.136554, 4.336364],
          [116.136071, 4.336338],
          [116.135537, 4.336186],
          [116.134902, 4.335779],
          [116.134521, 4.335271],
          [116.133683, 4.33395],
          [116.133124, 4.332934],
          [116.132616, 4.332274],
          [116.132082, 4.331892],
          [116.131244, 4.331511],
          [116.130583, 4.331359],
          [116.129821, 4.33141],
          [116.12911, 4.331638],
          [116.127738, 4.332528],
          [116.124892, 4.334738],
          [116.123394, 4.335729],
          [116.12253, 4.33616],
          [116.122047, 4.336516],
          [116.121717, 4.336897],
          [116.121386, 4.337761],
          [116.121056, 4.338422],
          [116.120878, 4.338676],
          [116.120523, 4.338904],
          [116.119837, 4.338853],
          [116.119557, 4.338574],
          [116.119252, 4.337888],
          [116.118948, 4.336897],
          [116.118668, 4.336237],
          [116.118262, 4.335602],
          [116.117144, 4.334662],
          [116.114654, 4.332401],
          [116.113993, 4.331638],
          [116.113689, 4.331079],
          [116.113739, 4.330343],
          [116.113892, 4.329657],
          [116.114401, 4.327904],
          [116.114985, 4.326303],
          [116.115036, 4.325515],
          [116.114985, 4.32516],
          [116.114782, 4.324626],
          [116.114274, 4.324042],
          [116.113689, 4.323661],
          [116.112902, 4.323534],
          [116.111606, 4.323635],
          [116.109675, 4.323686],
          [116.109116, 4.323458],
          [116.108532, 4.323],
          [116.108075, 4.32234],
          [116.107744, 4.321654],
          [116.107516, 4.32079],
          [116.105509, 4.315455],
          [116.105077, 4.314642],
          [116.104569, 4.314007],
          [116.103781, 4.313626],
          [116.102257, 4.313448],
          [116.101266, 4.313245],
          [116.1003, 4.312864],
          [116.098446, 4.311542],
          [116.097201, 4.310577],
          [116.09649, 4.309739],
          [116.093568, 4.305572],
          [116.093085, 4.304683],
          [116.092908, 4.304047],
          [116.091713, 4.295435],
          [116.091459, 4.292615],
          [116.091027, 4.290125],
          [116.090215, 4.286645],
          [116.089706, 4.285146],
          [116.089173, 4.283774],
          [116.088157, 4.281513],
          [116.08714, 4.280445],
          [116.084929, 4.27854],
          [116.083456, 4.277549],
          [116.081093, 4.276711],
          [116.080204, 4.276559],
          [116.07901, 4.276432],
          [116.077206, 4.276559],
          [116.075911, 4.276762],
          [116.070195, 4.278794],
          [116.062013, 4.283799],
          [116.059117, 4.28573],
          [116.05838, 4.286315],
          [116.057796, 4.287128],
          [116.057364, 4.287864],
          [116.056805, 4.288398],
          [116.056297, 4.288754],
          [116.055535, 4.289008],
          [116.053807, 4.289338],
          [116.052943, 4.28944],
          [116.051953, 4.289338],
          [116.05119, 4.28944],
          [116.04954, 4.289795],
          [116.047838, 4.290074],
          [116.046898, 4.290456],
          [116.045576, 4.291243],
          [116.044052, 4.292336],
          [116.042756, 4.293174],
          [116.041943, 4.293581],
          [116.040317, 4.293987],
          [116.039555, 4.294444],
          [116.039047, 4.294902],
          [116.038692, 4.29574],
          [116.038183, 4.297417],
          [116.037726, 4.298484],
          [116.036634, 4.300364],
          [116.036075, 4.301507],
          [116.035719, 4.302777],
          [116.035541, 4.303641],
          [116.035567, 4.304276],
          [116.036278, 4.307096],
          [116.036583, 4.314312],
          [116.037193, 4.316522],
          [116.03732, 4.317132],
          [116.037675, 4.319927],
          [116.03765, 4.320587],
          [116.037447, 4.32107],
          [116.037091, 4.321603],
          [116.036202, 4.322721],
          [116.034931, 4.324195],
          [116.034627, 4.324753],
          [116.03455, 4.325084],
          [116.034601, 4.325439],
          [116.034779, 4.325922],
          [116.036024, 4.327345],
          [116.036151, 4.327624],
          [116.036126, 4.328056],
          [116.035948, 4.328717],
          [116.035592, 4.329301],
          [116.034957, 4.329962],
          [116.034424, 4.330292],
          [116.033712, 4.330622],
          [116.032772, 4.330851],
          [116.031451, 4.330952],
          [116.030867, 4.331257],
          [116.02947, 4.332197],
          [116.027818, 4.332959],
          [116.025964, 4.33329],
          [116.024871, 4.333569],
          [116.022153, 4.334382],
          [116.020678, 4.334687],
          [116.019078, 4.334941],
          [116.018214, 4.335043],
          [116.017503, 4.335043],
          [116.015394, 4.334763],
          [116.012574, 4.33423],
          [116.011532, 4.334179],
          [116.009754, 4.334535],
          [116.008966, 4.334992],
          [116.007874, 4.335932],
          [116.007086, 4.33677],
          [116.006578, 4.337685],
          [116.006324, 4.338371],
          [116.006223, 4.339057],
          [116.006299, 4.339641],
          [116.006781, 4.340378],
          [116.007696, 4.34142],
          [116.007976, 4.341953],
          [116.008052, 4.34241],
          [116.008052, 4.343452],
          [116.00795, 4.344875],
          [116.007747, 4.346323],
          [116.007569, 4.347009],
          [116.007112, 4.347568],
          [116.006629, 4.347898],
          [116.00607, 4.348177],
          [116.004673, 4.348431],
          [116.0029, 4.348656],
          [116.001599, 4.348732],
          [115.999608, 4.348541],
          [115.998651, 4.348541],
          [115.997924, 4.348732],
          [115.996355, 4.349268],
          [115.995589, 4.349421],
          [115.993944, 4.349498],
          [115.993293, 4.349612],
          [115.992642, 4.350148],
          [115.992068, 4.351105],
          [115.991839, 4.351718],
          [115.989657, 4.352981],
          [115.989006, 4.35321],
          [115.987973, 4.353172],
          [115.987399, 4.352942],
          [115.987054, 4.352751],
          [115.986327, 4.352177],
          [115.985944, 4.351756],
          [115.985485, 4.351411],
          [115.984873, 4.351258],
          [115.984299, 4.35145],
          [115.983648, 4.351909],
          [115.982653, 4.352292],
          [115.981773, 4.352483],
          [115.980433, 4.352675],
          [115.979361, 4.352751],
          [115.978672, 4.352981],
          [115.978366, 4.353249],
          [115.97829, 4.353861],
          [115.978098, 4.354627],
          [115.977945, 4.355545],
          [115.977677, 4.356158],
          [115.977141, 4.357],
          [115.97672, 4.357574],
          [115.976223, 4.358033],
          [115.975764, 4.358224],
          [115.974922, 4.358301],
          [115.974118, 4.358224],
          [115.973314, 4.358071],
          [115.972702, 4.357727],
          [115.972013, 4.357191],
          [115.971171, 4.356923],
          [115.969755, 4.356693],
          [115.968339, 4.356311],
          [115.965698, 4.355124],
          [115.964129, 4.354474],
          [115.962827, 4.353976],
          [115.96187, 4.353746],
          [115.961181, 4.353823],
          [115.960799, 4.354091],
          [115.960569, 4.354627],
          [115.960339, 4.355392],
          [115.959995, 4.356158],
          [115.95923, 4.357727],
          [115.95877, 4.358377],
          [115.958081, 4.359028],
          [115.957392, 4.359526],
          [115.956857, 4.359679],
          [115.956015, 4.359717],
          [115.952991, 4.358722],
          [115.952302, 4.358645],
          [115.951268, 4.358645],
          [115.950196, 4.358492],
          [115.949622, 4.358148],
          [115.949354, 4.357842],
          [115.949163, 4.357421],
          [115.948972, 4.356808],
          [115.948627, 4.356234],
          [115.948244, 4.355928],
          [115.94767, 4.355775],
          [115.946828, 4.355737],
          [115.945986, 4.35589],
          [115.944953, 4.356196],
          [115.94392, 4.356655],
          [115.942044, 4.357038],
          [115.9417, 4.356923],
          [115.941164, 4.356617],
          [115.940781, 4.356311],
          [115.940322, 4.355737],
          [115.939901, 4.355009],
          [115.939327, 4.354512],
          [115.938523, 4.354014],
          [115.937757, 4.35367],
          [115.937107, 4.353478],
          [115.936303, 4.353555],
          [115.935308, 4.353899],
          [115.934504, 4.354244],
          [115.933968, 4.354282],
          [115.933509, 4.354244],
          [115.932437, 4.353785],
          [115.931787, 4.353785],
          [115.929873, 4.354244],
          [115.929376, 4.35455],
          [115.928916, 4.354971],
          [115.928189, 4.356425],
          [115.927806, 4.357],
          [115.927424, 4.357344],
          [115.927003, 4.357612],
          [115.924898, 4.358569],
          [115.923788, 4.35987],
          [115.922793, 4.36121],
          [115.922104, 4.362243],
          [115.921797, 4.363123],
          [115.920802, 4.366491],
          [115.920458, 4.367065],
          [115.920113, 4.367295],
          [115.919386, 4.36741],
          [115.918582, 4.367448],
          [115.918353, 4.367563],
          [115.91797, 4.367984],
          [115.917779, 4.368367],
          [115.917472, 4.368788],
          [115.916554, 4.369362],
          [115.915482, 4.370357],
          [115.914793, 4.371199],
          [115.914487, 4.371696],
          [115.914449, 4.372194],
          [115.914487, 4.372883],
          [115.915291, 4.376289],
          [115.915329, 4.377131],
          [115.915214, 4.377974],
          [115.915023, 4.378624],
          [115.913224, 4.381074],
          [115.912803, 4.381763],
          [115.912114, 4.383677],
          [115.909205, 4.390222],
          [115.908937, 4.390949],
          [115.908172, 4.391982],
          [115.907598, 4.392174],
          [115.907062, 4.39225],
          [115.905722, 4.392135],
          [115.905033, 4.392174],
          [115.904, 4.392327],
          [115.902967, 4.392595],
          [115.902584, 4.392862],
          [115.902278, 4.393284],
          [115.902125, 4.394087],
          [115.901627, 4.394853],
          [115.901397, 4.394929],
          [115.901053, 4.394929],
          [115.900555, 4.394776],
          [115.896766, 4.39336],
          [115.895197, 4.392403],
          [115.894164, 4.391867],
          [115.893628, 4.391714],
          [115.89336, 4.391753],
          [115.892786, 4.392212],
          [115.89202, 4.392709],
          [115.891446, 4.392939],
          [115.890987, 4.393016],
          [115.890451, 4.392901],
          [115.889839, 4.392441],
          [115.88915, 4.391753],
          [115.888346, 4.391332],
          [115.887466, 4.391102],
          [115.886739, 4.391064],
          [115.885897, 4.391102],
          [115.881725, 4.391791],
          [115.881074, 4.391753],
          [115.879007, 4.391408],
          [115.878395, 4.391255],
          [115.877897, 4.391025],
          [115.877553, 4.390681],
          [115.87717, 4.390068],
          [115.876825, 4.38915],
          [115.875754, 4.386662],
          [115.873993, 4.38383],
          [115.873381, 4.383179],
          [115.872615, 4.382643],
          [115.872118, 4.382107],
          [115.871926, 4.381725],
          [115.87185, 4.380997],
          [115.871926, 4.380155],
          [115.872041, 4.375907],
          [115.871926, 4.374912],
          [115.871735, 4.374376],
          [115.871352, 4.373955],
          [115.869974, 4.372921],
          [115.869285, 4.372233],
          [115.869132, 4.371697],
          [115.869132, 4.371429],
          [115.8694, 4.371199],
          [115.86986, 4.37097],
          [115.870702, 4.370358],
          [115.871008, 4.369592],
          [115.871199, 4.36787],
          [115.871161, 4.367104],
          [115.870931, 4.366262],
          [115.870319, 4.36542],
          [115.869974, 4.364808],
          [115.869783, 4.364348],
          [115.869821, 4.363813],
          [115.870051, 4.363315],
          [115.870204, 4.362741],
          [115.870242, 4.362014],
          [115.869974, 4.361057],
          [115.869668, 4.360176],
          [115.86963, 4.359411],
          [115.869745, 4.358645],
          [115.870625, 4.357382],
          [115.871429, 4.356579],
          [115.872386, 4.355354],
          [115.872845, 4.354626],
          [115.873036, 4.354091],
          [115.87296, 4.35344],
          [115.872654, 4.352904],
          [115.872233, 4.35256],
          [115.871467, 4.352253],
          [115.87051, 4.351756],
          [115.869821, 4.351105],
          [115.869477, 4.350225],
          [115.868941, 4.347163],
          [115.86875, 4.346589],
          [115.868405, 4.346206],
          [115.867908, 4.346053],
          [115.866568, 4.346053],
          [115.865688, 4.345785],
          [115.863468, 4.344293],
          [115.862205, 4.343527],
          [115.862128, 4.343374],
          [115.862166, 4.343183],
          [115.86232, 4.342915],
          [115.862587, 4.342685],
          [115.864042, 4.342302],
          [115.864769, 4.341996],
          [115.865037, 4.341728],
          [115.865267, 4.341346],
          [115.865343, 4.340886],
          [115.865381, 4.340083],
          [115.865305, 4.337557],
          [115.865037, 4.336638],
          [115.864769, 4.335911],
          [115.864578, 4.335069],
          [115.864386, 4.332313],
          [115.86408, 4.325117],
          [115.863927, 4.322553],
          [115.864004, 4.321328],
          [115.864386, 4.319223],
          [115.865152, 4.316391],
          [115.865649, 4.314898],
          [115.866142, 4.309972],
          [115.869517, 4.304304],
          [115.869539, 4.300458],
          [115.863046, 4.296414],
          [115.856207, 4.294208],
          [115.850192, 4.294179],
          [115.847367, 4.291491],
          [115.84573, 4.285631],
          [115.845766, 4.279445],
          [115.847432, 4.276395],
          [115.847355, 4.27494],
          [115.847164, 4.273869],
          [115.846781, 4.272835],
          [115.845441, 4.270348],
          [115.844752, 4.269467],
          [115.844178, 4.268893],
          [115.843528, 4.268702],
          [115.840083, 4.268434],
          [115.839317, 4.268319],
          [115.837824, 4.267439],
          [115.835681, 4.264645],
          [115.834801, 4.263841],
          [115.834265, 4.263535],
          [115.833844, 4.263497],
          [115.833538, 4.263612],
          [115.832887, 4.263956],
          [115.832581, 4.264033],
          [115.832122, 4.263918],
          [115.831662, 4.26365],
          [115.831241, 4.263305],
          [115.830705, 4.263037],
          [115.830017, 4.263076],
          [115.829404, 4.263267],
          [115.828103, 4.263267],
          [115.826916, 4.263114],
          [115.824849, 4.262655],
          [115.824314, 4.26231],
          [115.824046, 4.261506],
          [115.823893, 4.260741],
          [115.823854, 4.26009],
          [115.824161, 4.259401],
          [115.824582, 4.258138],
          [115.8255, 4.255804],
          [115.825653, 4.254694],
          [115.826419, 4.251555],
          [115.826687, 4.250062],
          [115.826802, 4.249144],
          [115.826802, 4.248416],
          [115.827108, 4.24723],
          [115.827682, 4.244321],
          [115.828333, 4.24279],
          [115.829481, 4.241719],
          [115.829596, 4.241183],
          [115.829519, 4.240915],
          [115.829175, 4.240417],
          [115.828715, 4.239958],
          [115.826955, 4.238733],
          [115.825615, 4.237929],
          [115.824811, 4.237394],
          [115.82374, 4.236054],
          [115.822936, 4.235442],
          [115.821788, 4.234676],
          [115.820984, 4.233911],
          [115.82041, 4.233145],
          [115.81953, 4.232265],
          [115.818343, 4.23081],
          [115.817846, 4.230351],
          [115.81708, 4.230083],
          [115.81643, 4.229968],
          [115.815549, 4.230198],
          [115.813636, 4.23127],
          [115.812564, 4.231461],
          [115.811531, 4.231461],
          [115.810421, 4.231308],
          [115.809655, 4.230887],
          [115.808775, 4.229854],
          [115.808009, 4.229318],
          [115.80533, 4.228131],
          [115.804412, 4.227672],
          [115.802766, 4.2266],
          [115.802153, 4.226103],
          [115.801541, 4.225873],
          [115.801082, 4.225911],
          [115.800699, 4.226179],
          [115.800355, 4.226792],
          [115.800125, 4.227366],
          [115.799589, 4.229318],
          [115.798632, 4.230925],
          [115.797905, 4.232609],
          [115.797637, 4.234753],
          [115.797637, 4.237126],
          [115.797561, 4.237929],
          [115.797331, 4.238657],
          [115.796297, 4.242714],
          [115.795876, 4.243135],
          [115.794652, 4.243632],
          [115.793503, 4.2439],
          [115.791666, 4.244475],
          [115.791207, 4.244742],
          [115.790327, 4.245814],
          [115.789714, 4.246235],
          [115.788949, 4.246541],
          [115.788107, 4.246656],
          [115.78715, 4.246656],
          [115.785887, 4.246541],
          [115.783897, 4.246656],
          [115.781447, 4.246809],
          [115.780146, 4.247039],
          [115.779227, 4.247115],
          [115.777314, 4.246771],
          [115.77628, 4.246809],
          [115.775783, 4.247039],
          [115.7754, 4.24746],
          [115.774788, 4.247957],
          [115.77341, 4.248838],
          [115.770577, 4.250522],
          [115.769582, 4.250904],
          [115.768051, 4.250981],
          [115.767209, 4.250866],
          [115.766061, 4.250636],
          [115.765257, 4.250215],
          [115.764951, 4.249756],
          [115.764836, 4.249258],
          [115.764836, 4.248225],
          [115.764951, 4.247306],
          [115.765181, 4.246541],
          [115.765181, 4.245928],
          [115.764607, 4.241986],
          [115.763879, 4.239345],
          [115.76166, 4.23548],
          [115.760473, 4.234102],
          [115.75898, 4.232647],
          [115.758751, 4.23192],
          [115.758598, 4.231078],
          [115.758406, 4.229356],
          [115.758253, 4.22859],
          [115.758291, 4.227634],
          [115.758024, 4.226753],
          [115.757449, 4.225796],
          [115.756416, 4.223653],
          [115.755497, 4.221433],
          [115.754694, 4.220132],
          [115.753928, 4.219596],
          [115.752856, 4.219023],
          [115.752397, 4.21887],
          [115.751823, 4.218602],
          [115.751134, 4.218142],
          [115.750368, 4.217415],
          [115.749947, 4.216841],
          [115.749603, 4.216229],
          [115.749182, 4.215846],
          [115.74857, 4.215846],
          [115.748149, 4.216114],
          [115.747881, 4.21642],
          [115.747039, 4.21665],
          [115.746082, 4.216726],
          [115.745278, 4.216726],
          [115.742637, 4.216114],
          [115.741757, 4.215654],
          [115.740877, 4.21508],
          [115.739996, 4.21443],
          [115.739346, 4.213779],
          [115.738925, 4.213511],
          [115.736628, 4.212822],
          [115.735824, 4.212401],
          [115.735595, 4.211904],
          [115.73548, 4.210947],
          [115.735442, 4.20953],
          [115.735366, 4.208497],
          [115.734179, 4.204134],
          [115.732342, 4.198163],
          [115.731576, 4.196211],
          [115.73104, 4.194336],
          [115.730658, 4.193915],
          [115.730237, 4.193838],
          [115.725185, 4.195561],
          [115.720476, 4.197895],
          [115.71952, 4.198048],
          [115.718869, 4.197972],
          [115.717797, 4.19778],
          [115.716419, 4.197245],
          [115.715195, 4.196938],
          [115.712822, 4.196824],
          [115.712133, 4.197092],
          [115.711406, 4.197551],
          [115.710793, 4.19801],
          [115.710525, 4.198087],
          [115.709262, 4.197972],
          [115.708688, 4.197972],
          [115.708152, 4.198125],
          [115.70777, 4.198469],
          [115.707655, 4.198929],
          [115.70731, 4.19935],
          [115.706813, 4.199541],
          [115.7062, 4.199426],
          [115.704937, 4.198469],
          [115.704593, 4.197704],
          [115.704516, 4.197015],
          [115.704134, 4.196211],
          [115.702411, 4.193456],
          [115.7, 4.190164],
          [115.699541, 4.189284],
          [115.699388, 4.188671],
          [115.699235, 4.187753],
          [115.698891, 4.186528],
          [115.698661, 4.185992],
          [115.698049, 4.185418],
          [115.6969, 4.183887],
          [115.69602, 4.182815],
          [115.693647, 4.18071],
          [115.692422, 4.179485],
          [115.691886, 4.178835],
          [115.691695, 4.178107],
          [115.691542, 4.177074],
          [115.691236, 4.176117],
          [115.690776, 4.175466],
          [115.689705, 4.174777],
          [115.689322, 4.174395],
          [115.689054, 4.174012],
          [115.688824, 4.173361],
          [115.688824, 4.172749],
          [115.689016, 4.17206],
          [115.689322, 4.171294],
          [115.689552, 4.170529],
          [115.689666, 4.169649],
          [115.689513, 4.168883],
          [115.689207, 4.168271],
          [115.688212, 4.16785],
          [115.686911, 4.167391],
          [115.68626, 4.16697],
          [115.685877, 4.166166],
          [115.685609, 4.164941],
          [115.684806, 4.163793],
          [115.684538, 4.162989],
          [115.684346, 4.161803],
          [115.684002, 4.16031],
          [115.683122, 4.155717],
          [115.682854, 4.154875],
          [115.682854, 4.154148],
          [115.68293, 4.153383],
          [115.682892, 4.152655],
          [115.682662, 4.151354],
          [115.682777, 4.150244],
          [115.683198, 4.149632],
          [115.683657, 4.147642],
          [115.683619, 4.146647],
          [115.683428, 4.145651],
          [115.683122, 4.145268],
          [115.682777, 4.145038],
          [115.681246, 4.144617],
          [115.680787, 4.144196],
          [115.680595, 4.143814],
          [115.680595, 4.14324],
          [115.680863, 4.142512],
          [115.681323, 4.141785],
          [115.682854, 4.140369],
          [115.683772, 4.139183],
          [115.684538, 4.137575],
          [115.684729, 4.136657],
          [115.684691, 4.136083],
          [115.684461, 4.135202],
          [115.682892, 4.132868],
          [115.681667, 4.131222],
          [115.680978, 4.129844],
          [115.680672, 4.129117],
          [115.680328, 4.128543],
          [115.679945, 4.128237],
          [115.678988, 4.128122],
          [115.677916, 4.128122],
          [115.676271, 4.127931],
          [115.675773, 4.127586],
          [115.675658, 4.127165],
          [115.67562, 4.126514],
          [115.675658, 4.125978],
          [115.675658, 4.124333],
          [115.67562, 4.121424],
          [115.675658, 4.120658],
          [115.675888, 4.118745],
          [115.675735, 4.118285],
          [115.675429, 4.117635],
          [115.675046, 4.117099],
          [115.674625, 4.116716],
          [115.67428, 4.116218],
          [115.673859, 4.114879],
          [115.6708807, 4.116111],
          [115.667829, 4.1185282],
          [115.6648221, 4.1200949],
          [115.6623089, 4.1215273],
          [115.6606484, 4.1226464],
          [115.6585391, 4.1240788],
          [115.657181, 4.1214878],
          [115.6565976, 4.1200554],
          [115.6556103, 4.1192497],
          [115.653501, 4.1186678],
          [115.6526932, 4.1189363],
          [115.6510775, 4.1185782],
          [115.6491477, 4.119563],
          [115.6467692, 4.1208611],
          [115.6439867, 4.1225621],
          [115.6449291, 4.1244422],
          [115.644974, 4.125785],
          [115.6459165, 4.1279336],
          [115.6459613, 4.1299032],
          [115.6463204, 4.1316041],
          [115.6476219, 4.1337079],
          [115.6481604, 4.1343794],
          [115.6491029, 4.1372441],
          [115.6490131, 4.1384527],
          [115.6485194, 4.1411832],
          [115.6478462, 4.1458831],
          [115.6445252, 4.1475841],
          [115.6412939, 4.1488821],
          [115.640531, 4.1506278],
          [115.6406208, 4.1526421],
          [115.6409798, 4.154522],
          [115.6408003, 4.1561782],
          [115.6409349, 4.1591324],
          [115.63905, 4.1622656],
          [115.6387359, 4.164056],
          [115.6359983, 4.1645036],
          [115.6319592, 4.1639665],
          [115.6305679, 4.1643693],
          [115.6294908, 4.1666969],
          [115.6294908, 4.1675473],
          [115.6300743, 4.1697406],
          [115.6314655, 4.1723814],
          [115.6306128, 4.1741718],
          [115.629805, 4.1762307],
          [115.6298947, 4.1784687],
          [115.6328567, 4.1806619],
          [115.6356392, 4.1824971],
          [115.6396334, 4.188271],
          [115.6414735, 4.1904194],
          [115.6421466, 4.1931944],
          [115.6412939, 4.1989235],
          [115.6396783, 4.2024594],
          [115.6385115, 4.2038469],
          [115.6385115, 4.2060401],
          [115.6391398, 4.2085018],
          [115.6398578, 4.2102473],
          [115.6399027, 4.2123509],
          [115.6393193, 4.2137831],
          [115.6385115, 4.2152601],
          [115.6384217, 4.2164686],
          [115.6387807, 4.2177666],
          [115.640172, 4.22081],
          [115.6397681, 4.2229136],
          [115.6397681, 4.2245696],
          [115.6424159, 4.2262256],
          [115.6425506, 4.2272103],
          [115.6426403, 4.2302537],
          [115.6424159, 4.2334762],
          [115.6417427, 4.2353559],
          [115.6397681, 4.2358035],
          [115.6378832, 4.2365644],
          [115.6360431, 4.2377728],
          [115.6365817, 4.2395183],
          [115.6374344, 4.2409057],
          [115.6397681, 4.2426959],
          [115.6411593, 4.2443967],
          [115.6417876, 4.2465449],
          [115.6429096, 4.2476191],
          [115.6452882, 4.2495883],
          [115.6464101, 4.2504386],
          [115.6491477, 4.2517366],
          [115.649776, 4.2553617],
          [115.6512122, 4.2587183],
          [115.6533663, 4.2604638],
          [115.6570913, 4.2620302],
          [115.6581684, 4.2639099],
          [115.6574952, 4.2667742],
          [115.6574054, 4.2688329],
          [115.6584825, 4.270444],
          [115.661265, 4.2726817],
          [115.6637333, 4.2734426],
          [115.6655734, 4.2769781],
          [115.664227, 4.279171],
          [115.6641821, 4.2814982],
          [115.6639577, 4.2838702],
          [115.6649899, 4.2851233],
          [115.667189, 4.2863316],
          [115.6669646, 4.2888825],
          [115.6671441, 4.291523],
          [115.6681315, 4.2937606],
          [115.6711832, 4.2966248],
          [115.6715422, 4.2985044],
          [115.6707793, 4.2996232],
          [115.67051, 4.3015028],
          [115.6705998, 4.3034272],
          [115.6704203, 4.3046802],
          [115.6676827, 4.3071416],
          [115.6664709, 4.3091107],
          [115.6662914, 4.3123328],
          [115.6663363, 4.3137649],
          [115.6666505, 4.3151522],
          [115.6661119, 4.3181057],
          [115.6662914, 4.3226704],
          [115.6659324, 4.3256687],
          [115.6626562, 4.328309],
          [115.6611304, 4.3333211],
          [115.6593352, 4.3359613],
          [115.6546229, 4.3381989],
          [115.6485643, 4.3402574],
          [115.6472179, 4.3425396],
          [115.6448394, 4.3435241],
          [115.6432237, 4.3438821],
          [115.642012, 4.3428529],
          [115.6402169, 4.3427634],
          [115.6385115, 4.3432556],
          [115.6361329, 4.3449561],
          [115.6344275, 4.3465223],
          [115.6310167, 4.3505946],
          [115.6276957, 4.3471936],
          [115.625362, 4.3440164],
          [115.6226244, 4.3436584],
          [115.6209812, 4.3448548],
          [115.6175256, 4.3448548],
          [115.6127684, 4.3415433],
          [115.6110181, 4.343915],
          [115.6074727, 4.3469133],
          [115.6061712, 4.3510302],
          [115.6075625, 4.3557289],
          [115.6081908, 4.3582349],
          [115.6082447, 4.3602642],
          [115.6034211, 4.3603275],
          [115.5987562, 4.3608338],
          [115.5954876, 4.3608654],
          [115.5923776, 4.3617831],
          [115.5899976, 4.3622577],
          [115.5883474, 4.3636816],
          [115.5871415, 4.36577],
          [115.5872684, 4.3701998],
          [115.5877762, 4.3726995],
          [115.5873723, 4.3794565],
          [115.5884045, 4.3824098],
          [115.5893918, 4.3856316],
          [115.5880903, 4.3883165],
          [115.586744, 4.3912698],
          [115.5875518, 4.3930597],
          [115.5895713, 4.3952075],
          [115.5906484, 4.3969974],
          [115.5914114, 4.3989215],
          [115.5909177, 4.4017853],
          [115.5901996, 4.4070653],
          [115.5901996, 4.4113609],
          [115.5906933, 4.4129718],
          [115.5934309, 4.4168199],
          [115.593027, 4.4202206],
          [115.5925782, 4.4247398],
          [115.5923089, 4.4284089],
          [115.591187, 4.4314963],
          [115.5877762, 4.4344942],
          [115.5880455, 4.4370893],
          [115.5865645, 4.4391923],
          [115.5863849, 4.441698],
          [115.5817624, 4.4452328],
          [115.580057, 4.4475147],
          [115.5793838, 4.4497071],
          [115.5794736, 4.4541367],
          [115.5790248, 4.4561054],
          [115.5752101, 4.4579399],
          [115.573774, 4.4608482],
          [115.568972, 4.4689913],
          [115.5665036, 4.4714521],
          [115.5640802, 4.4736445],
          [115.5628236, 4.4765975],
          [115.5604899, 4.4779845],
          [115.5598616, 4.480311],
          [115.5599513, 4.4826376],
          [115.5594577, 4.484651],
          [115.5604001, 4.4881408],
          [115.5647085, 4.4883645],
          [115.5669075, 4.4895278],
          [115.5684783, 4.4905121],
          [115.5709018, 4.4951651],
          [115.5744472, 4.4958362],
          [115.5784863, 4.4987891],
          [115.5773643, 4.5016525],
          [115.5754794, 4.5064844],
          [115.5759731, 4.5087662],
          [115.5771399, 4.5112269],
          [115.5794736, 4.5150745],
          [115.5809097, 4.5176694],
          [115.5802365, 4.5196826],
          [115.5788902, 4.5222775],
          [115.5781272, 4.524246],
          [115.5761526, 4.5250513],
          [115.573774, 4.5265277],
          [115.5732355, 4.5280488],
          [115.5743125, 4.5304647],
          [115.5749408, 4.5325674],
          [115.5752999, 4.5348491],
          [115.5755243, 4.536907],
          [115.5748511, 4.5378018],
          [115.5739535, 4.5398597],
          [115.5702286, 4.541694],
          [115.5685681, 4.543573],
          [115.5681193, 4.5450046],
          [115.564888, 4.5479125],
          [115.5634519, 4.5511784],
          [115.5643495, 4.5526099],
          [115.5666832, 4.5549363],
          [115.5679398, 4.5571284],
          [115.5683885, 4.5599915],
          [115.5701837, 4.5645099],
          [115.5706774, 4.5664336],
          [115.5691964, 4.5683573],
          [115.5678051, 4.569923],
          [115.5661895, 4.5707283],
          [115.5640802, 4.5718914],
          [115.5614323, 4.5725177],
          [115.5606245, 4.5743519],
          [115.5594128, 4.5778413],
          [115.5585601, 4.5812412],
          [115.5577972, 4.5820017],
          [115.5553288, 4.584149],
          [115.5535337, 4.5850437],
          [115.551918, 4.5887567],
          [115.5492253, 4.5906355],
          [115.5454555, 4.592917],
          [115.5458145, 4.5943485],
          [115.5463082, 4.5991351],
          [115.5464428, 4.6044137],
          [115.5480136, 4.6058452],
          [115.5481482, 4.607724],
          [115.5485072, 4.6091108],
          [115.5499434, 4.6108107],
          [115.5512449, 4.6124658],
          [115.5539825, 4.6164471],
          [115.5563162, 4.6197573],
          [115.5582459, 4.6240964],
          [115.5594128, 4.6266909],
          [115.5588294, 4.6280329],
          [115.5590089, 4.6304037],
          [115.5596821, 4.6336244],
          [115.5618811, 4.6352795],
          [115.5643495, 4.638858],
          [115.5663241, 4.6412288],
          [115.5652919, 4.6456125],
          [115.5686578, 4.6462835],
          [115.5752999, 4.6481175],
          [115.5765116, 4.6508908],
          [115.5786209, 4.6520538],
          [115.5801917, 4.6546929],
          [115.5810892, 4.6569294],
          [115.5839166, 4.6577346],
          [115.585263, 4.6610894],
          [115.5870581, 4.6629233],
          [115.5889879, 4.6655624],
          [115.5969763, 4.67424],
          [115.5996691, 4.6763423],
          [115.6017335, 4.6774158],
          [115.603394, 4.6830516],
          [115.6048301, 4.6852881],
          [115.6053238, 4.6881507],
          [115.6070741, 4.6907897],
          [115.6085551, 4.692713],
          [115.6094078, 4.695352],
          [115.6101707, 4.6985277],
          [115.6108439, 4.7024637],
          [115.6114722, 4.7074285],
          [115.6125941, 4.7109172],
          [115.6139854, 4.7131536],
          [115.6168576, 4.7165528],
          [115.6176206, 4.718163],
          [115.6182938, 4.7213386],
          [115.6190567, 4.7234407],
          [115.6192362, 4.7274214],
          [115.6208518, 4.7338619],
          [115.6213006, 4.7369927],
          [115.6230509, 4.7400787],
          [115.6262822, 4.7437462],
          [115.6268207, 4.7482187],
          [115.6270451, 4.7517072],
          [115.6287056, 4.7542565],
          [115.6308149, 4.7555982],
          [115.6322062, 4.7582817],
          [115.6331935, 4.7618149],
          [115.6332833, 4.7682551],
          [115.6368287, 4.7709385],
          [115.6405985, 4.7720566],
          [115.6436503, 4.772146],
          [115.6480035, 4.7725485],
          [115.6494845, 4.7732194],
          [115.6497538, 4.7745163],
          [115.6514592, 4.7761264],
          [115.6531197, 4.7780494],
          [115.6543763, 4.780867],
          [115.6554983, 4.7852051],
          [115.6559022, 4.7899009],
          [115.655947, 4.7932998],
          [115.6556329, 4.798398],
          [115.6544212, 4.7998738],
          [115.6550944, 4.8027807],
          [115.6560368, 4.8057323],
          [115.6561266, 4.8084156],
          [115.6558573, 4.8129324],
          [115.6553187, 4.8152131],
          [115.6531646, 4.8180752],
          [115.6528055, 4.8195062],
          [115.6545558, 4.823218],
          [115.6547802, 4.8254093],
          [115.6527158, 4.8282713],
          [115.6517733, 4.8369021],
          [115.6514143, 4.8420001],
          [115.6521772, 4.8478135],
          [115.6520875, 4.8506307],
          [115.6492601, 4.8514356],
          [115.6473303, 4.8521511],
          [115.6451313, 4.8542529],
          [115.6436054, 4.8568912],
          [115.6409575, 4.8587246],
          [115.6379955, 4.8661477],
          [115.6374121, 4.8703511],
          [115.6368736, 4.8741073],
          [115.6356618, 4.8779529],
          [115.6373672, 4.8809042],
          [115.6383097, 4.8839896],
          [115.6384443, 4.88864],
          [115.6379955, 4.8923961],
          [115.6354374, 4.8983432],
          [115.6349438, 4.9009814],
          [115.6340013, 4.9038878],
          [115.6338218, 4.9063471],
          [115.6340911, 4.9083145],
          [115.633014, 4.9106397],
          [115.6314881, 4.9135014],
          [115.6291993, 4.9155582],
          [115.627449, 4.9171232],
          [115.6252051, 4.9192247],
          [115.6234997, 4.9230701],
          [115.6216148, 4.9239196],
          [115.6229936, 4.9281583],
          [115.6231282, 4.9400518],
          [115.625058, 4.9459985],
          [115.6247887, 4.9483682],
          [115.6237565, 4.9543148],
          [115.6218267, 4.9595907],
          [115.6207048, 4.965403],
          [115.6181018, 4.9673256],
          [115.6158579, 4.9686222],
          [115.6076002, 4.9750604],
          [115.6052665, 4.9764464],
          [115.599501, 4.983956],
          [115.5955025, 4.9879394],
          [115.5930907, 4.9900891],
          [115.5891557, 4.9868013],
          [115.5830627, 4.9870542],
          [115.5760178, 4.9883188],
          [115.5721462, 4.9859161],
          [115.5672591, 4.9840825],
          [115.5581197, 4.97953],
          [115.5499958, 4.9780758],
          [115.5450453, 4.9756099],
          [115.5359693, 4.9689708],
          [115.5160403, 4.9595495],
          [115.5090747, 4.9591587],
          [115.4938159, 4.9590246],
          [115.484081, 4.9609271],
          [115.4821172, 4.9613109],
          [115.4818118, 4.9613706],
          [115.4675266, 4.9641624],
          [115.4543323, 4.9706006],
          [115.447617, 4.9794313],
          [115.4534618, 4.9825074],
          [115.4556934, 4.9862698],
          [115.4580967, 4.990032],
          [115.4580967, 4.9924261],
          [115.4591267, 4.9955044],
          [115.4582684, 4.9997796],
          [115.45845273332263, 5.0005],
          [115.4605, 5.008501],
          [115.4616432, 5.0106812],
          [115.4663585, 5.0143588],
          [115.470113, 5.01346],
          [115.4740612, 5.015683],
          [115.4767759, 5.0177044],
          [115.4811861, 5.0207986],
          [115.4831501, 5.0225849],
          [115.4859277, 5.0257903],
          [115.4861159, 5.0265783],
          [115.4853712, 5.0269588],
          [115.4858703, 5.0275453],
          [115.4879317, 5.0268877],
          [115.4895599, 5.0258728],
          [115.4896213, 5.0250103],
          [115.4910902, 5.0239997],
          [115.4923561, 5.0234531],
          [115.4929019, 5.0250729],
          [115.495772, 5.0248403],
          [115.4969827, 5.0250074],
          [115.498663, 5.0255625],
          [115.500672, 5.0258159],
          [115.5033359, 5.0266985],
          [115.5038254, 5.0263112],
          [115.5051686, 5.0259668],
          [115.5080977, 5.0268236],
          [115.5092865, 5.0276919],
          [115.509918, 5.0279281],
          [115.5108468, 5.0285686],
          [115.5117412, 5.0293195],
          [115.5121931, 5.0298027],
          [115.5125603, 5.0303478],
          [115.5128189, 5.0310339],
          [115.5127585, 5.031694],
          [115.5125739, 5.0321206],
          [115.5122938, 5.0324038],
          [115.5118581, 5.0327027],
          [115.5101006, 5.0334514],
          [115.5094076, 5.0338158],
          [115.5095133, 5.0340521],
          [115.5098148, 5.0337532],
          [115.5105064, 5.0333831],
          [115.5122224, 5.0327084],
          [115.5123431, 5.0332892],
          [115.5141856, 5.0362981],
          [115.5136763, 5.0368873],
          [115.5137463, 5.0373513],
          [115.5129561, 5.0381954],
          [115.5124832, 5.0383121],
          [115.5121531, 5.0386508],
          [115.5121231, 5.0390693],
          [115.5122903, 5.0391789],
          [115.5126318, 5.0386508],
          [115.5145764, 5.0369386],
          [115.515443, 5.0373428],
          [115.5157331, 5.0373257],
          [115.5164175, 5.038127],
          [115.5166569, 5.0386826],
          [115.5168017, 5.0393117],
          [115.5166199, 5.0396156],
          [115.5155662, 5.0402079],
          [115.5151102, 5.0401097],
          [115.5147651, 5.0403522],
          [115.5147312, 5.04092],
          [115.5140603, 5.0412356],
          [115.5141338, 5.0413914],
          [115.5148141, 5.0410903],
          [115.5149838, 5.0411563],
          [115.5152057, 5.0411287],
          [115.5153996, 5.0409444],
          [115.5154522, 5.0406959],
          [115.5158065, 5.0405578],
          [115.5165552, 5.0399808],
          [115.5171659, 5.0397138],
          [115.5182027, 5.0408432],
          [115.5182157, 5.041133],
          [115.5171261, 5.0417672],
          [115.5162528, 5.042387],
          [115.5163005, 5.0424649],
          [115.517202, 5.0418551],
          [115.5172932, 5.0418854],
          [115.5175812, 5.0417456],
          [115.5178127, 5.0420238],
          [115.517516, 5.042217],
          [115.5178503, 5.0427215],
          [115.5177765, 5.0431568],
          [115.5166, 5.0440606],
          [115.5164886, 5.0444815],
          [115.5167621, 5.0445766],
          [115.5179834, 5.0438804],
          [115.5193415, 5.0440116],
          [115.5196389, 5.0446502],
          [115.5208588, 5.0455741],
          [115.521468, 5.0462545],
          [115.5221604, 5.0463223],
          [115.5226756, 5.0465918],
          [115.5232602, 5.0464534],
          [115.5240199, 5.0460714],
          [115.5241255, 5.0463223],
          [115.5248144, 5.0476239],
          [115.5258881, 5.0493104],
          [115.5271051, 5.0511641],
          [115.5281284, 5.0536587],
          [115.52922, 5.0552953],
          [115.5311764, 5.0603057],
          [115.5329188, 5.0669155],
          [115.5333398, 5.0685507],
          [115.5330735, 5.0711121],
          [115.5332338, 5.0711804],
          [115.5334447, 5.0704879],
          [115.5336212, 5.0705052],
          [115.5337363, 5.0703352],
          [115.533975, 5.0680044],
          [115.5340966, 5.0678876],
          [115.5350054, 5.0674163],
          [115.5356884, 5.0669349],
          [115.5362108, 5.0666985],
          [115.5374914, 5.0669522],
          [115.5377389, 5.0671813],
          [115.5383176, 5.0669802],
          [115.5389953, 5.0665397],
          [115.5395658, 5.0660556],
          [115.5413139, 5.0653479],
          [115.5414615, 5.0655987],
          [115.5419672, 5.066279],
          [115.542847, 5.0670242],
          [115.5437269, 5.0672822],
          [115.5441595, 5.0676988],
          [115.5442608, 5.0686473],
          [115.5439439, 5.0688563],
          [115.544169, 5.0691633],
          [115.544093, 5.0696635],
          [115.5454677, 5.0706977],
          [115.5462885, 5.0717218],
          [115.5473029, 5.0735898],
          [115.5480091, 5.0750745],
          [115.5483955, 5.0760878],
          [115.5489287, 5.0775845],
          [115.5492337, 5.0789036],
          [115.5493071, 5.0796956],
          [115.5493676, 5.0797655],
          [115.5489577, 5.0804718],
          [115.5488864, 5.0808343],
          [115.5489244, 5.0810988],
          [115.549081, 5.0813539],
          [115.5493893, 5.0814952],
          [115.5497438, 5.0814952],
          [115.5499659, 5.0815948],
          [115.5500821, 5.0819427],
          [115.5501678, 5.0822043],
          [115.5499041, 5.0827232],
          [115.5498209, 5.0832802],
          [115.5511584, 5.0896417],
          [115.5530476, 5.0985344],
          [115.5539337, 5.107044],
          [115.5541802, 5.1080709],
          [115.5547423, 5.1130911],
          [115.5552779, 5.1138688],
          [115.5547884, 5.1151533],
          [115.5549291, 5.118207],
          [115.5543603, 5.1202498],
          [115.5513089, 5.1220826],
          [115.5508052, 5.1220343],
          [115.5504211, 5.1236837],
          [115.5511773, 5.1271315],
          [115.5540248, 5.130693],
          [115.553273, 5.1375505],
          [115.5542271, 5.140675],
          [115.5552193, 5.1427293],
          [115.5597669, 5.147811],
          [115.5621568, 5.1517231],
          [115.5675865, 5.1549438],
          [115.5689058, 5.1562746],
          [115.5686563, 5.1573108],
          [115.5689909, 5.1591377],
          [115.5717574, 5.1605208],
          [115.5725903, 5.1645939],
          [115.5719621, 5.1685525],
          [115.5715588, 5.1709913],
          [115.5706125, 5.1739797],
          [115.5703797, 5.1754203],
          [115.5702646, 5.177688],
          [115.5705368, 5.1787458],
          [115.5708493, 5.1804461],
          [115.5708325, 5.1813196],
          [115.5716365, 5.1836062],
          [115.5726424, 5.1855388],
          [115.5729307, 5.1864764],
          [115.5734422, 5.1870517],
          [115.5742869, 5.1857661],
          [115.5758371, 5.1838589],
          [115.57654, 5.1797625],
          [115.579419, 5.1793948],
          [115.5804974, 5.1792784],
          [115.5832747, 5.1795572],
          [115.5860848, 5.1803392],
          [115.5878236, 5.1807501],
          [115.5936965, 5.1807799],
          [115.5942833, 5.1816726],
          [115.5928761, 5.1816672],
          [115.5927295, 5.1823652],
          [115.5939736, 5.1831443],
          [115.5969888, 5.1834798],
          [115.5965705, 5.1838856],
          [115.5961087, 5.184497],
          [115.5952503, 5.1858118],
          [115.5951362, 5.1863637],
          [115.5950221, 5.1867262],
          [115.5918602, 5.1879111],
          [115.5893605, 5.1872832],
          [115.5860994, 5.1913166],
          [115.587992, 5.194627],
          [115.5878262, 5.1959725],
          [115.5879105, 5.1977529],
          [115.5873793, 5.2067021],
          [115.5877863, 5.2082028],
          [115.5895437, 5.2114452],
          [115.591135, 5.2129906],
          [115.5935878, 5.2147692],
          [115.596638, 5.2159793],
          [115.5971202, 5.2157677],
          [115.5981998, 5.2160802],
          [115.5984614, 5.2162325],
          [115.5987886, 5.2167253],
          [115.5994457, 5.216756],
          [115.6002845, 5.2170572],
          [115.6002537, 5.2172618],
          [115.600182, 5.2176311],
          [115.6003382, 5.2176555],
          [115.6004697, 5.2174371],
          [115.602773, 5.2178845],
          [115.6031378, 5.2176001],
          [115.603068, 5.2174438],
          [115.6033309, 5.2174732],
          [115.603134, 5.2182209],
          [115.6029004, 5.2186198],
          [115.6027837, 5.2190732],
          [115.6019925, 5.2190759],
          [115.601813, 5.2197958],
          [115.6017216, 5.2202111],
          [115.6005954, 5.2199064],
          [115.5984933, 5.2195941],
          [115.595297, 5.2188944],
          [115.5921407, 5.2172355],
          [115.5929901, 5.2195252],
          [115.5939706, 5.2208271],
          [115.5953868, 5.221478],
          [115.5966894, 5.2208501],
          [115.5972751, 5.2201623],
          [115.5978545, 5.2201142],
          [115.5990964, 5.2201062],
          [115.5980825, 5.2202879],
          [115.597361, 5.2206485],
          [115.5971804, 5.2209771],
          [115.5971225, 5.2215158],
          [115.5957975, 5.2222834],
          [115.5962272, 5.2231646],
          [115.591605, 5.2247859],
          [115.590965, 5.2240216],
          [115.5901418, 5.2242945],
          [115.5880738, 5.2232901],
          [115.5877815, 5.2222164],
          [115.5880551, 5.220547],
          [115.5898602, 5.2195934],
          [115.5893613, 5.2160622],
          [115.5878907, 5.2158352],
          [115.5863391, 5.2157397],
          [115.5856176, 5.2145029],
          [115.5847754, 5.2134104],
          [115.5837213, 5.2115968],
          [115.5832224, 5.2109824],
          [115.5827503, 5.2105791],
          [115.5835147, 5.2117864],
          [115.5840807, 5.2135654],
          [115.584703, 5.2171019],
          [115.5848854, 5.2200962],
          [115.5836274, 5.2199861],
          [115.5828083, 5.2199148],
          [115.5826611, 5.21958],
          [115.5822159, 5.2196308],
          [115.5812895, 5.220445],
          [115.5810438, 5.2204401],
          [115.5803767, 5.2195973],
          [115.5805804, 5.218923],
          [115.5808976, 5.2181149],
          [115.5811953, 5.2169316],
          [115.5811065, 5.216237],
          [115.5808942, 5.215189],
          [115.5769762, 5.2089023],
          [115.5768796, 5.2083787],
          [115.5726149, 5.2016421],
          [115.5700641, 5.1959925],
          [115.5693467, 5.1947993],
          [115.5681995, 5.1929748],
          [115.565304, 5.1899024],
          [115.564225, 5.1874019],
          [115.5628463, 5.1859167],
          [115.5609405, 5.185195],
          [115.5586258, 5.1841744],
          [115.5577067, 5.183468],
          [115.5566894, 5.1829458],
          [115.5542758, 5.1825651],
          [115.5585786, 5.188766],
          [115.5515175, 5.1837047],
          [115.5495228, 5.1830472],
          [115.5493602, 5.1852299],
          [115.5513774, 5.1877815],
          [115.5545044, 5.1899793],
          [115.5528359, 5.19007],
          [115.5489036, 5.1923008],
          [115.5488105, 5.19425],
          [115.5494771, 5.1950554],
          [115.5502827, 5.195026],
          [115.5514073, 5.192997],
          [115.5522711, 5.1924614],
          [115.5545529, 5.1918392],
          [115.5590513, 5.1920231],
          [115.5571525, 5.1922963],
          [115.5581005, 5.1925966],
          [115.5555525, 5.1925912],
          [115.5525482, 5.1931864],
          [115.5517767, 5.1937599],
          [115.5514833, 5.1947771],
          [115.551911, 5.1958793],
          [115.5498947, 5.1981299],
          [115.5501139, 5.1990919],
          [115.5510182, 5.1997012],
          [115.5517801, 5.1999697],
          [115.5523472, 5.200125],
          [115.5537285, 5.2005172],
          [115.5531825, 5.2009023],
          [115.5527689, 5.2013289],
          [115.5521988, 5.2016844],
          [115.5511701, 5.2017791],
          [115.5510383, 5.2025382],
          [115.5516296, 5.2042535],
          [115.547077, 5.2011072],
          [115.544134, 5.2002562],
          [115.5426616, 5.2000064],
          [115.5409237, 5.2004558],
          [115.5399562, 5.1999384],
          [115.5317004, 5.1980759],
          [115.5204355, 5.1957316],
          [115.5190258, 5.196166],
          [115.5176534, 5.1965724],
          [115.5168515, 5.1974699],
          [115.5172726, 5.1984476],
          [115.5185278, 5.1997564],
          [115.5185278, 5.2007314],
          [115.5185788, 5.2013378],
          [115.5190428, 5.2030206],
          [115.5182516, 5.2039742],
          [115.5189587, 5.2053976],
          [115.5165421, 5.2051625],
          [115.5146109, 5.2053628],
          [115.5130271, 5.2068749],
          [115.5110999, 5.2074544],
          [115.5111089, 5.2070968],
          [115.5107843, 5.2069472],
          [115.5099867, 5.206466],
          [115.5089138, 5.2056513],
          [115.5081464, 5.2052484],
          [115.5074034, 5.2056143],
          [115.5070386, 5.2069205],
          [115.5077444, 5.208432],
          [115.5072337, 5.208399],
          [115.5057116, 5.2074961],
          [115.5054675, 5.207054],
          [115.5051684, 5.2072824],
          [115.5053857, 5.2076257],
          [115.5051403, 5.2077359],
          [115.5030429, 5.2067016],
          [115.5007569, 5.2064237],
          [115.4998717, 5.2061058],
          [115.4972754, 5.2068363],
          [115.497105, 5.2071061],
          [115.496809, 5.2074223],
          [115.4950069, 5.2084791],
          [115.4948057, 5.2090587],
          [115.4940748, 5.2097479],
          [115.4939125, 5.2105425],
          [115.4929091, 5.2109121],
          [115.4912813, 5.2113092],
          [115.4913028, 5.2118407],
          [115.4922295, 5.2130494],
          [115.4921447, 5.21359],
          [115.4918767, 5.2143435],
          [115.4920068, 5.2153172],
          [115.4918137, 5.2155749],
          [115.4906268, 5.2160143],
          [115.4905289, 5.2160397],
          [115.4906255, 5.2163188],
          [115.4899657, 5.2165753],
          [115.4898879, 5.2169773],
          [115.4884207, 5.2180924],
          [115.4880047, 5.2179292],
          [115.4874632, 5.2180497],
          [115.4861767, 5.21806],
          [115.4852554, 5.2191671],
          [115.4852374, 5.2194702],
          [115.4849529, 5.2200684],
          [115.4849773, 5.2203036],
          [115.4842769, 5.2210299],
          [115.4836509, 5.2215627],
          [115.4834896, 5.2221245],
          [115.4834938, 5.222534],
          [115.4834006, 5.2230757],
          [115.4826008, 5.2233899],
          [115.4813662, 5.2244152],
          [115.4773214, 5.2242683],
          [115.4770468, 5.2240102],
          [115.4761922, 5.2242956],
          [115.47433, 5.2261776],
          [115.4712178, 5.2270111],
          [115.4704465, 5.2269872],
          [115.4695949, 5.2275199],
          [115.4694338, 5.2303995],
          [115.4689538, 5.2320792],
          [115.4676146, 5.2335799],
          [115.4671473, 5.2337286],
          [115.4666904, 5.2338741],
          [115.4657454, 5.2348834],
          [115.4654963, 5.2360117],
          [115.4650321, 5.2360934],
          [115.4571601, 5.2353546],
          [115.4566262, 5.2360278],
          [115.4561462, 5.2363053],
          [115.4549169, 5.2368673],
          [115.4541017, 5.2372917],
          [115.4529259, 5.2377883],
          [115.4510516, 5.2379361],
          [115.4504139, 5.239071],
          [115.4501973, 5.239896],
          [115.4497788, 5.240748],
          [115.447566, 5.240318],
          [115.4465977, 5.2410979],
          [115.4460828, 5.239888],
          [115.4452861, 5.2396556],
          [115.4444813, 5.2388296],
          [115.4436575, 5.2389376],
          [115.4419755, 5.2400904],
          [115.4418164, 5.240553],
          [115.4419853, 5.2407996],
          [115.4435575, 5.2417317],
          [115.4438402, 5.2425904],
          [115.44339, 5.2444466],
          [115.4425258, 5.2449148],
          [115.4429655, 5.2466915],
          [115.4430701, 5.2477637],
          [115.4388888, 5.2485898],
          [115.4369155, 5.248779],
          [115.4367711, 5.2493738],
          [115.4359232, 5.2502689],
          [115.4360471, 5.2512795],
          [115.435387, 5.2522101],
          [115.4357825, 5.25259],
          [115.4358655, 5.2530651],
          [115.4355193, 5.2539187],
          [115.434047, 5.2539719],
          [115.4312348, 5.2515261],
          [115.4299596, 5.251899],
          [115.4293086, 5.2534973],
          [115.430472, 5.2559636],
          [115.4318823, 5.2575642],
          [115.4339162, 5.2596814],
          [115.4341279, 5.2599168],
          [115.4343948, 5.2601937],
          [115.4354553, 5.2611205],
          [115.4369331, 5.2616186],
          [115.4375765, 5.26213],
          [115.4382474, 5.2626816],
          [115.4372331, 5.2640018],
          [115.4362277, 5.2652376],
          [115.434715, 5.2665891],
          [115.4346344, 5.2672534],
          [115.4359474, 5.2683572],
          [115.4362083, 5.2687681],
          [115.4346955, 5.2734419],
          [115.4339057, 5.2739363],
          [115.4331452, 5.2740989],
          [115.4315681, 5.2738853],
          [115.4302367, 5.2730158],
          [115.4295859, 5.2725472],
          [115.4302028, 5.2696519],
          [115.4300794, 5.2690644],
          [115.4292107, 5.2682365],
          [115.4262828, 5.2678344],
          [115.4212943, 5.2659316],
          [115.4174934, 5.2663208],
          [115.4162421, 5.2689905],
          [115.4144839, 5.270685],
          [115.4127191, 5.2716946],
          [115.4070811, 5.2767265],
          [115.4067287, 5.2793568],
          [115.4079813, 5.2809273],
          [115.4075707, 5.2812943],
          [115.4071042, 5.2815709],
          [115.4034698, 5.2833791],
          [115.4022612, 5.2829095],
          [115.4011019, 5.2823142],
          [115.4007795, 5.2822293],
          [115.395852601502625, 5.285022228956223],
          [115.3895389, 5.2886013],
          [115.3896591, 5.289343],
          [115.3873578, 5.2903098],
          [115.3858273, 5.2900859],
          [115.3839286, 5.2911231],
          [115.3795067, 5.293849],
          [115.3771907, 5.2969672],
          [115.3743146, 5.3004205],
          [115.3732196, 5.3012215],
          [115.3729388, 5.3012917],
          [115.3720035, 5.3012564],
          [115.3718623, 5.3006034],
          [115.3715446, 5.3000739],
          [115.3709622, 5.2997563],
          [115.3700445, 5.2993327],
          [115.3701328, 5.2989974],
          [115.3692504, 5.2983797],
          [115.3685444, 5.298062],
          [115.3684915, 5.2973738],
          [115.3679091, 5.2969678],
          [115.367062, 5.2968973],
          [115.3669384, 5.2965619],
          [115.3654913, 5.2960501],
          [115.3623322, 5.2959442],
          [115.3611145, 5.2957325],
          [115.3590497, 5.2954677],
          [115.358431816101074, 5.295562765966724],
          [115.3576731, 5.2956795],
          [115.3572142, 5.2961384],
          [115.356173, 5.2958031],
          [115.3561377, 5.2953266],
          [115.3553259, 5.2948501],
          [115.3543023, 5.2944088],
          [115.3520256, 5.2948148],
          [115.350349, 5.2952383],
          [115.3495902, 5.2948148],
          [115.3482313, 5.2952207],
          [115.3466176, 5.2954669],
          [115.3455864, 5.2958319],
          [115.344278, 5.2962796],
          [115.3424956, 5.2963502],
          [115.3415426, 5.2964207],
          [115.3393866, 5.2969704],
          [115.3391247, 5.2972679],
          [115.3389694, 5.2978927],
          [115.3390069, 5.2986137],
          [115.339368, 5.2993612],
          [115.3400816, 5.3003449],
          [115.3419554, 5.3013023],
          [115.3441387, 5.3030152],
          [115.3458864, 5.3041063],
          [115.352525, 5.3087169],
          [115.3561298, 5.3116226],
          [115.3562918, 5.313489],
          [115.3571598, 5.3152121],
          [115.3566066, 5.3171162],
          [115.3576748, 5.3193142],
          [115.358431816101074, 5.319732948800918],
          [115.3607647, 5.3210234],
          [115.362653, 5.324271],
          [115.3638546, 5.3271766],
          [115.3648846, 5.328544],
          [115.3647129, 5.3321333],
          [115.3657429, 5.3374318],
          [115.3651066, 5.340553],
          [115.3657429, 5.3437556],
          [115.3641759, 5.3451184],
          [115.3636293, 5.3477993],
          [115.3681461, 5.3536686],
          [115.3707211, 5.3620433],
          [115.370957, 5.3688367],
          [115.3710971, 5.3699661],
          [115.3710394, 5.3707575],
          [115.370957, 5.3716066],
          [115.3706519, 5.3719694],
          [115.3704376, 5.372464],
          [115.3704623, 5.3732389],
          [115.3703881, 5.3746156],
          [115.3701738, 5.3758604],
          [115.3698523, 5.3771382],
          [115.3694813, 5.3784078],
          [115.3689537, 5.3804192],
          [115.3687806, 5.3812436],
          [115.3686322, 5.3821339],
          [115.3681871, 5.3825874],
          [115.3678326, 5.3833623],
          [115.3672143, 5.3843845],
          [115.3664724, 5.3852336],
          [115.3659695, 5.3855469],
          [115.3655243, 5.3858519],
          [115.3653759, 5.3865444],
          [115.3655243, 5.387006],
          [115.3661921, 5.3878139],
          [115.3675111, 5.3894627],
          [115.3680552, 5.3903447],
          [115.3684921, 5.3915154],
          [115.3690774, 5.3924964],
          [115.3698276, 5.3936917],
          [115.3700501, 5.3944337],
          [115.3703057, 5.3950437],
          [115.3707261, 5.395695],
          [115.3710064, 5.3959423],
          [115.3713444, 5.3959258],
          [115.3714021, 5.3961648],
          [115.3712208, 5.3964039],
          [115.3748699, 5.4024994],
          [115.375752, 5.4036041],
          [115.3768649, 5.4046098],
          [115.3775162, 5.4047912],
          [115.3779448, 5.4047005],
          [115.3780273, 5.4049808],
          [115.3788764, 5.4054259],
          [115.3807807, 5.4063492],
          [115.3816793, 5.4068274],
          [115.3816875, 5.4073385],
          [115.3818854, 5.4077094],
          [115.3836664, 5.409641],
          [115.385095, 5.4109128],
          [115.3865036, 5.4110029],
          [115.3890615, 5.4114537],
          [115.3911124, 5.4112283],
          [115.3942789, 5.4108564],
          [115.3964424, 5.4113185],
          [115.399699, 5.4127045],
          [115.4015245, 5.4121298],
          [115.4053784, 5.412896],
          [115.4085223, 5.4140567],
          [115.4099647, 5.4148793],
          [115.4119141, 5.4158146],
          [115.4132325, 5.4167161],
          [115.4147425, 5.4171781],
          [115.424966, 5.4242514],
          [115.4338924, 5.4336505],
          [115.4464529, 5.4512202],
          [115.45535, 5.459967],
          [115.466508, 5.4703907],
          [115.4757491, 5.4825557],
          [115.4809555, 5.4870799],
          [115.488309, 5.4917502],
          [115.4916853, 5.4964039],
          [115.4929302, 5.4981363],
          [115.4933286, 5.4994271],
          [115.4948425, 5.5007498],
          [115.4960855, 5.5014988],
          [115.5006273, 5.5061522],
          [115.50246, 5.5085426],
          [115.5039579, 5.5098015],
          [115.5060456, 5.5109489],
          [115.5083802, 5.5123194],
          [115.5094559, 5.512925],
          [115.5107069, 5.5136182],
          [115.5119101, 5.514431],
          [115.5123164, 5.5146222],
          [115.5126352, 5.5144389],
          [115.5128105, 5.514662],
          [115.5126272, 5.5149091],
          [115.5128503, 5.515164],
          [115.5140136, 5.5161441],
          [115.5150335, 5.5170445],
          [115.5155435, 5.5178971],
          [115.5161092, 5.5188054],
          [115.5173443, 5.5196819],
          [115.5186431, 5.5203592],
          [115.5194478, 5.5208293],
          [115.519655, 5.5208692],
          [115.5198383, 5.5211321],
          [115.5209219, 5.5218253],
          [115.521647, 5.5225026],
          [115.5228024, 5.5232914],
          [115.5247307, 5.5248612],
          [115.5259737, 5.5255145],
          [115.5265394, 5.5262715],
          [115.5271211, 5.5264627],
          [115.5274796, 5.5264866],
          [115.5272486, 5.5266779],
          [115.5274239, 5.5270603],
          [115.5279896, 5.5280803],
          [115.5304199, 5.528893],
          [115.5325951, 5.52965],
          [115.5344039, 5.5305025],
          [115.5363481, 5.5317695],
          [115.5381409, 5.533124],
          [115.5421427, 5.5365968],
          [115.5452078, 5.5393914],
          [115.5474164, 5.5416451],
          [115.5541968, 5.5501103],
          [115.5580606, 5.5559415],
          [115.5588289, 5.5565231],
          [115.5592905, 5.5572892],
          [115.55921, 5.5576185],
          [115.5619082, 5.5647424],
          [115.562909, 5.5680923],
          [115.5633966, 5.5705514],
          [115.563513, 5.5720172],
          [115.5634912, 5.5731966],
          [115.5633034, 5.5740964],
          [115.5633856, 5.5746842],
          [115.5636637, 5.5752372],
          [115.5640587, 5.5757302],
          [115.5648992, 5.5762863],
          [115.5656292, 5.5769594],
          [115.5659452, 5.5774113],
          [115.5660526, 5.5776325],
          [115.5691716, 5.5782929],
          [115.5701764, 5.5789597],
          [115.5709633, 5.5797276],
          [115.5718544, 5.5808652],
          [115.5723695, 5.5818163],
          [115.5725686, 5.5824041],
          [115.5729383, 5.5832131],
          [115.5733649, 5.5837029],
          [115.5739116, 5.584357],
          [115.5740633, 5.5847267],
          [115.5748153, 5.5853208],
          [115.5755643, 5.5859686],
          [115.5763827, 5.586569],
          [115.5768851, 5.5872958],
          [115.5771379, 5.5880194],
          [115.5771632, 5.5888253],
          [115.5772801, 5.5895931],
          [115.577732, 5.590143],
          [115.5780544, 5.5909993],
          [115.5781112, 5.5917799],
          [115.5780973, 5.5921688],
          [115.578364, 5.5922539],
          [115.5787369, 5.5916345],
          [115.578541, 5.5920769],
          [115.5784841, 5.5922254],
          [115.5784241, 5.5924751],
          [115.5782661, 5.5927247],
          [115.5782629, 5.5932335],
          [115.5783293, 5.5940709],
          [115.5784304, 5.5949146],
          [115.5786232, 5.5959795],
          [115.5788633, 5.5968011],
          [115.5790801, 5.597914],
          [115.581753, 5.6022493],
          [115.5835645, 5.6048038],
          [115.5849435, 5.607517],
          [115.5854576, 5.6093165],
          [115.5860413, 5.6112916],
          [115.5863188, 5.6123488],
          [115.5865819, 5.6134969],
          [115.5869167, 5.6145157],
          [115.587132, 5.6147214],
          [115.5870937, 5.6150802],
          [115.5871081, 5.615329],
          [115.5872707, 5.6155059],
          [115.5876677, 5.6156447],
          [115.5881078, 5.6159412],
          [115.5891172, 5.6166731],
          [115.5899208, 5.6173428],
          [115.5905905, 5.6180221],
          [115.5910449, 5.6188592],
          [115.5915042, 5.6198829],
          [115.5917385, 5.6206674],
          [115.5917577, 5.6214519],
          [115.591619, 5.6219159],
          [115.5914946, 5.6220259],
          [115.5911884, 5.6221838],
          [115.5908966, 5.6221503],
          [115.5906527, 5.6219398],
          [115.5901265, 5.6218872],
          [115.5898634, 5.6221694],
          [115.5896434, 5.6225521],
          [115.589562, 5.622753],
          [115.5898012, 5.62282],
          [115.5902509, 5.6228391],
          [115.5904422, 5.622863],
          [115.5907149, 5.6229539],
          [115.5909636, 5.6233031],
          [115.5912602, 5.6239872],
          [115.5913272, 5.6242885],
          [115.5912745, 5.624609],
          [115.591375, 5.6246425],
          [115.5916764, 5.62492],
          [115.5919108, 5.6254222],
          [115.5920064, 5.6259963],
          [115.5920925, 5.6263168],
          [115.5924848, 5.6269482],
          [115.5927527, 5.627484],
          [115.5930349, 5.6277805],
          [115.593365, 5.6284168],
          [115.5935898, 5.6289238],
          [115.5936137, 5.6291582],
          [115.5935946, 5.6294213],
          [115.5934606, 5.6295744],
          [115.5930492, 5.6294787],
          [115.5929966, 5.6296174],
          [115.5930158, 5.629737],
          [115.5931019, 5.6298757],
          [115.593164, 5.6300766],
          [115.593225, 5.6301077],
          [115.5932035, 5.6301628],
          [115.5931425, 5.6302202],
          [115.593188, 5.6303421],
          [115.5931189, 5.6304724],
          [115.5931088, 5.6305806],
          [115.5931629, 5.6307092],
          [115.5931156, 5.6308377],
          [115.5930987, 5.6309764],
          [115.5930479, 5.6310846],
          [115.5930276, 5.6312199],
          [115.5932069, 5.6312977],
          [115.5932678, 5.6315108],
          [115.5932069, 5.6316596],
          [115.5931663, 5.6318355],
          [115.5932238, 5.6318727],
          [115.5933253, 5.6317679],
          [115.5933862, 5.6316495],
          [115.593376, 5.6316123],
          [115.5933659, 5.6314804],
          [115.593447, 5.6312267],
          [115.5935621, 5.6310508],
          [115.5935519, 5.6308309],
          [115.5936365, 5.6306889],
          [115.593765, 5.6306821],
          [115.59388, 5.6304386],
          [115.593968, 5.6304115],
          [115.5940863, 5.6301747],
          [115.5943671, 5.6300733],
          [115.5948508, 5.6300124],
          [115.5952465, 5.6299752],
          [115.5956998, 5.6300462],
          [115.5961733, 5.6302018],
          [115.5963154, 5.6301206],
          [115.5963627, 5.6300158],
          [115.5965285, 5.6297553],
          [115.5968769, 5.6296538],
          [115.5973065, 5.6296572],
          [115.5976921, 5.6297079],
          [115.5981893, 5.6299177],
          [115.6000605, 5.6313989],
          [115.6006632, 5.6304422],
          [115.6009981, 5.6298394],
          [115.6015119, 5.6294596],
          [115.6024686, 5.6284598],
          [115.6035592, 5.627637],
          [115.6042672, 5.6271874],
          [115.6055061, 5.6266994],
          [115.6063194, 5.6260058],
          [115.6068312, 5.6256423],
          [115.6075679, 5.6252357],
          [115.6082854, 5.624896],
          [115.6083442, 5.6248506],
          [115.6082653, 5.6246688],
          [115.6083059, 5.6245684],
          [115.6087006, 5.6244201],
          [115.6098719, 5.6241785],
          [115.6112587, 5.6238809],
          [115.6130379, 5.6234885],
          [115.6141541, 5.6235088],
          [115.6152636, 5.6236644],
          [115.6155883, 5.6237997],
          [115.6165083, 5.6235832],
          [115.6172322, 5.6232315],
          [115.6173878, 5.6227173],
          [115.6173201, 5.62244],
          [115.6176365, 5.6218339],
          [115.6179714, 5.6213942],
          [115.6182454, 5.6208293],
          [115.6186479, 5.6203862],
          [115.6186411, 5.6200175],
          [115.6185329, 5.6198145],
          [115.6189151, 5.6190467],
          [115.6191823, 5.6186713],
          [115.6195882, 5.6183296],
          [115.6197946, 5.6178696],
          [115.6197979, 5.617183],
          [115.6197303, 5.6168616],
          [115.6195544, 5.6166858],
          [115.619754, 5.6160904],
          [115.6200077, 5.615397],
          [115.6205996, 5.6146969],
          [115.6208668, 5.6143282],
          [115.6203324, 5.6137836],
          [115.6201632, 5.6135028],
          [115.6200584, 5.6128466],
          [115.6202444, 5.6121634],
          [115.6201632, 5.6112941],
          [115.6204305, 5.6104857],
          [115.6202985, 5.6101406],
          [115.619984, 5.6097517],
          [115.6197371, 5.6091191],
          [115.6194868, 5.6088519],
          [115.6193278, 5.6083378],
          [115.6194563, 5.607685],
          [115.619639, 5.607181],
          [115.6196491, 5.6065687],
          [115.6197607, 5.6062339],
          [115.6198893, 5.6056521],
          [115.6197269, 5.6053138],
          [115.6193278, 5.6052631],
          [115.6186141, 5.6051075],
          [115.6182183, 5.6051447],
          [115.617508, 5.6046746],
          [115.616588, 5.604015],
          [115.6163681, 5.6037241],
          [115.6160231, 5.6032708],
          [115.6157288, 5.6028954],
          [115.6155326, 5.6023203],
          [115.6154176, 5.6016743],
          [115.6152451, 5.600964],
          [115.6149542, 5.6003585],
          [115.6149475, 5.6000845],
          [115.6146295, 5.5997869],
          [115.6144502, 5.5992795],
          [115.6143251, 5.5987755],
          [115.6143318, 5.597798],
          [115.6142608, 5.5975003],
          [115.6141289, 5.5972399],
          [115.6137737, 5.5970234],
          [115.6134423, 5.5969997],
          [115.613405, 5.5968881],
          [115.6133137, 5.5967426],
          [115.6130127, 5.5966445],
          [115.6126981, 5.596746],
          [115.6122381, 5.5965532],
          [115.6119066, 5.5963909],
          [115.6118423, 5.5960898],
          [115.6117138, 5.5956501],
          [115.6114466, 5.5947605],
          [115.6112707, 5.594632],
          [115.6110644, 5.594517],
          [115.6109054, 5.5942396],
          [115.6108073, 5.5939149],
          [115.6105063, 5.5926972],
          [115.6103507, 5.5921695],
          [115.6103777, 5.5917366],
          [115.6102052, 5.5916351],
          [115.6102593, 5.5911108],
          [115.6101308, 5.5907387],
          [115.6099312, 5.5901569],
          [115.6097418, 5.589883],
          [115.6095659, 5.5894601],
          [115.6095693, 5.589264],
          [115.6095084, 5.5891693],
          [115.6093258, 5.5888344],
          [115.6093731, 5.5886213],
          [115.6093731, 5.5883811],
          [115.6093562, 5.5876471],
          [115.609275, 5.5871939],
          [115.6092615, 5.586717],
          [115.6092784, 5.5864633],
          [115.6091702, 5.5863483],
          [115.6091939, 5.5860743],
          [115.609045, 5.5858646],
          [115.6088996, 5.5855399],
          [115.6088116, 5.5853335],
          [115.6090619, 5.5851069],
          [115.6093156, 5.5849648],
          [115.6090755, 5.5848566],
          [115.6091634, 5.5847957],
          [115.6090315, 5.5842985],
          [115.6090721, 5.5838824],
          [115.60916, 5.5835205],
          [115.6090586, 5.5831992],
          [115.6088928, 5.5830199],
          [115.6084429, 5.5819544],
          [115.6081825, 5.5817176],
          [115.6077766, 5.5815215],
          [115.6075432, 5.581146],
          [115.6073504, 5.5801854],
          [115.6072658, 5.5795901],
          [115.607205, 5.5792248],
          [115.6071948, 5.5783893],
          [115.6072591, 5.5776821],
          [115.607232, 5.5772356],
          [115.6069817, 5.5771138],
          [115.6066164, 5.5771274],
          [115.6064676, 5.5772221],
          [115.6064307, 5.5776376],
          [115.606182, 5.5781638],
          [115.6061489, 5.5782893],
          [115.6060576, 5.578403],
          [115.6058472, 5.5785274],
          [115.6058476, 5.578672],
          [115.6064159, 5.5791031],
          [115.6064329, 5.5793961],
          [115.6066714, 5.5797847],
          [115.6067373, 5.5798552],
          [115.6067447, 5.5800945],
          [115.6069993, 5.5801959],
          [115.6070493, 5.5804243],
          [115.6071344, 5.580729],
          [115.6070992, 5.5809239],
          [115.6072461, 5.5810435],
          [115.6073175, 5.5811979],
          [115.6073496, 5.5813283],
          [115.6074271, 5.5813801],
          [115.6073653, 5.5815073],
          [115.6069242, 5.5815838],
          [115.6067621, 5.5814785],
          [115.6066452, 5.581462],
          [115.6064581, 5.580954],
          [115.6061894, 5.5804977],
          [115.6059159, 5.5799436],
          [115.6058792, 5.5796727],
          [115.6057894, 5.5791385],
          [115.6057649, 5.5788072],
          [115.6055506, 5.5784126],
          [115.6056845, 5.5777716],
          [115.6062203, 5.5771784],
          [115.6067165, 5.5761284],
          [115.6058546, 5.5756282],
          [115.6055485, 5.5757908],
          [115.605338, 5.5759056],
          [115.6048597, 5.5755995],
          [115.6046301, 5.5751116],
          [115.6044483, 5.5748246],
          [115.603549, 5.5743366],
          [115.6029941, 5.5738965],
          [115.6027836, 5.5741453],
          [115.6027262, 5.5746332],
          [115.6025731, 5.5746523],
          [115.6025349, 5.5742792],
          [115.602487, 5.5740975],
          [115.6019895, 5.5732555],
          [115.6012911, 5.5726624],
          [115.6014155, 5.5724232],
          [115.5991824, 5.570666],
          [115.5972973, 5.5683436],
          [115.5969785, 5.5681356],
          [115.5947961, 5.5656158],
          [115.5943279, 5.5656095],
          [115.5943279, 5.5652699],
          [115.5936486, 5.5645727],
          [115.593023, 5.5636611],
          [115.592737, 5.5636074],
          [115.5926833, 5.5632678],
          [115.5916644, 5.5620701],
          [115.5912533, 5.5619986],
          [115.5909136, 5.5611227],
          [115.5891976, 5.5590134],
          [115.5883186, 5.5573404],
          [115.5881714, 5.5570603],
          [115.5880893, 5.556904],
          [115.5873385, 5.5555812],
          [115.5866235, 5.5522563],
          [115.5855867, 5.5471439],
          [115.5850861, 5.5452491],
          [115.5851219, 5.5444983],
          [115.5849431, 5.5423889],
          [115.5850861, 5.5406014],
          [115.5845856, 5.5402796],
          [115.5839778, 5.5395646],
          [115.5838348, 5.5385278],
          [115.5845499, 5.538063],
          [115.5846873, 5.5372184],
          [115.586981, 5.536347],
          [115.588268, 5.5344521],
          [115.5905919, 5.5326646],
          [115.5921292, 5.531163],
          [115.5931841, 5.5302215],
          [115.5936644, 5.5306765],
          [115.5942205, 5.5298929],
          [115.5951306, 5.5295389],
          [115.5960407, 5.5289828],
          [115.5970519, 5.5282244],
          [115.5973806, 5.5276682],
          [115.5973806, 5.5269098],
          [115.5977092, 5.5268592],
          [115.5986193, 5.526834],
          [115.5993018, 5.5263789],
          [115.5998616, 5.5257044],
          [115.5999754, 5.5253125],
          [115.6000765, 5.5248701],
          [115.5997605, 5.5242887],
          [115.5992802, 5.5239221],
          [115.5985723, 5.5235303],
          [115.5976749, 5.5231005],
          [115.5970808, 5.5229741],
          [115.5969291, 5.522557],
          [115.5965499, 5.5219503],
          [115.5965625, 5.5215331],
          [115.596651, 5.5209264],
          [115.5964867, 5.5204082],
          [115.59571, 5.5192487],
          [115.5962086, 5.5196245],
          [115.5965246, 5.5200922],
          [115.5969038, 5.5208632],
          [115.5968659, 5.5213435],
          [115.5969544, 5.5219755],
          [115.5972198, 5.5224432],
          [115.5976496, 5.5226707],
          [115.598092, 5.5227466],
          [115.598585, 5.5226075],
          [115.5988209, 5.5224873],
          [115.599089, 5.5223265],
          [115.599375, 5.5219511],
          [115.5996789, 5.5214505],
          [115.5997772, 5.5211377],
          [115.5998005, 5.5208485],
          [115.5996432, 5.5202797],
          [115.599384, 5.5197613],
          [115.5991069, 5.5193948],
          [115.5989281, 5.5189479],
          [115.5989818, 5.5184653],
          [115.5992588, 5.5179737],
          [115.5993482, 5.5178486],
          [115.5993184, 5.5161853],
          [115.5997236, 5.5174464],
          [115.5997326, 5.5177324],
          [115.5996253, 5.5179737],
          [115.5995091, 5.5181346],
          [115.5993393, 5.518367],
          [115.5991873, 5.5187066],
          [115.5991963, 5.5190016],
          [115.5993572, 5.5193502],
          [115.599661, 5.5197077],
          [115.599813, 5.51994],
          [115.5999649, 5.5201546],
          [115.6000811, 5.5203869],
          [115.6001884, 5.5210656],
          [115.6002778, 5.5217627],
          [115.6003493, 5.5224778],
          [115.6005995, 5.5232822],
          [115.6008319, 5.5236039],
          [115.6013324, 5.5234073],
          [115.6021056, 5.5223614],
          [115.6021368, 5.5217806],
          [115.6022977, 5.5209226],
          [115.6027625, 5.5207617],
          [115.6036205, 5.5203684],
          [115.6042998, 5.5202075],
          [115.605569, 5.5203506],
          [115.60657, 5.5208332],
          [115.6074996, 5.5214946],
          [115.6075711, 5.5225135],
          [115.6081431, 5.5237469],
          [115.6092335, 5.5260708],
          [115.6095195, 5.5272327],
          [115.6098055, 5.5277511],
          [115.6090547, 5.5278941],
          [115.6062661, 5.5313263],
          [115.6039065, 5.53424],
          [115.6029412, 5.5360812],
          [115.6016006, 5.5398172],
          [115.6016006, 5.5422662],
          [115.6011179, 5.5429097],
          [115.6013855, 5.5433774],
          [115.6014598, 5.5435073],
          [115.6004233, 5.5435073],
          [115.5994374, 5.5439624],
          [115.598679, 5.5454286],
          [115.5983251, 5.5463134],
          [115.5961763, 5.5478049],
          [115.5947606, 5.548715],
          [115.5930415, 5.550156],
          [115.5919545, 5.5508638],
          [115.5911202, 5.5520267],
          [115.5900585, 5.5534677],
          [115.5900332, 5.5545547],
          [115.5903871, 5.5556671],
          [115.591373, 5.5570575],
          [115.5932943, 5.559813],
          [115.5957971, 5.5636303],
          [115.5963307, 5.5642555],
          [115.5968317, 5.564298],
          [115.5969227, 5.5652104],
          [115.5977942, 5.5662847],
          [115.599855, 5.5682155],
          [115.6001669, 5.5681145],
          [115.6001298, 5.5677276],
          [115.6011787, 5.568004],
          [115.6001624, 5.568647],
          [115.6002512, 5.5687668],
          [115.6012119, 5.5682588],
          [115.6015348, 5.5682893],
          [115.6017515, 5.5688844],
          [115.6012697, 5.5694541],
          [115.6010454, 5.5692978],
          [115.6008865, 5.569455],
          [115.6017035, 5.5702247],
          [115.6027091, 5.5705694],
          [115.6041043, 5.5704097],
          [115.6051669, 5.5699403],
          [115.6070385, 5.5677278],
          [115.6115196, 5.5629726],
          [115.6160657, 5.5591886],
          [115.6200682, 5.5561836],
          [115.6265009, 5.553224],
          [115.6306569, 5.5513488],
          [115.6375507, 5.5469858],
          [115.642524, 5.5450997],
          [115.6468809, 5.5432788],
          [115.6553236, 5.5410058],
          [115.6584916, 5.5396777],
          [115.6587125, 5.5395851],
          [115.6590039, 5.5394491],
          [115.6596257, 5.5394103],
          [115.6599365, 5.5398183],
          [115.660772, 5.5401874],
          [115.6617434, 5.5403623],
          [115.6660373, 5.5393326],
          [115.6698065, 5.5388663],
          [115.6759655, 5.5376811],
          [115.6911008, 5.534961],
          [115.6956666, 5.5342421],
          [115.6972598, 5.5339507],
          [115.7026611, 5.5338147],
          [115.7205669, 5.5322459],
          [115.7299443, 5.5313529],
          [115.7391705, 5.5305494],
          [115.7484995, 5.530196],
          [115.7594061, 5.5302577],
          [115.7664692, 5.5306284],
          [115.7707679, 5.5312258],
          [115.7825808, 5.5332743],
          [115.7906935, 5.536279],
          [115.7967236, 5.5368984],
          [115.7991091, 5.5378338],
          [115.8005374, 5.5382585],
          [115.8033942, 5.5390112],
          [115.8066429, 5.5400306],
          [115.807157, 5.5406226],
          [115.8084317, 5.5408151],
          [115.8117481, 5.5422384],
          [115.8151569, 5.543544],
          [115.8162687, 5.5440105],
          [115.8207661, 5.5466356],
          [115.8257268, 5.5490677],
          [115.8283326, 5.5503995],
          [115.8296065, 5.551326],
          [115.8300273, 5.5514663],
          [115.8308225, 5.5517314],
          [115.8336666, 5.5524446],
          [115.8345984, 5.5528904],
          [115.8370111, 5.5542223],
          [115.8409874, 5.5560753],
          [115.8486503, 5.5606885],
          [115.854441, 5.5646454],
          [115.8566993, 5.566344],
          [115.8612535, 5.5702534],
          [115.8666789, 5.5759175],
          [115.8683833, 5.5781992],
          [115.8699348, 5.5805701],
          [115.8721079, 5.5844159],
          [115.8744226, 5.5897588],
          [115.8766071, 5.5978531],
          [115.8809798, 5.6062581],
          [115.8865136, 5.6209542],
          [115.8860298, 5.6211488],
          [115.8861093, 5.6217087],
          [115.8863774, 5.622529],
          [115.8864669, 5.6235559],
          [115.8864037, 5.6245746],
          [115.8863149, 5.6250542],
          [115.8859593, 5.6258273],
          [115.8844686, 5.6261102],
          [115.8837444, 5.62604],
          [115.8833438, 5.6255843],
          [115.8832926, 5.6258458],
          [115.8829095, 5.6260116],
          [115.8829766, 5.6261231],
          [115.8832805, 5.6259787],
          [115.8834588, 5.6260912],
          [115.8834655, 5.6262046],
          [115.8835095, 5.626281],
          [115.8835861, 5.6263245],
          [115.8837172, 5.6263471],
          [115.8838533, 5.6263491],
          [115.8840318, 5.6263521],
          [115.8843197, 5.6263817],
          [115.8844092, 5.6264128],
          [115.8845988, 5.6268953],
          [115.8848777, 5.6273721],
          [115.8853239, 5.6281753],
          [115.885614, 5.6286048],
          [115.8858733, 5.6290649],
          [115.8859877, 5.6292518],
          [115.8860379, 5.6296701],
          [115.8863, 5.6302613],
          [115.8865175, 5.6306155],
          [115.8865482, 5.6308024],
          [115.8864813, 5.6308749],
          [115.8863809, 5.6308526],
          [115.8863251, 5.630702],
          [115.8862889, 5.6306183],
          [115.8861689, 5.6304705],
          [115.8861689, 5.6306573],
          [115.8862219, 5.6308916],
          [115.8863865, 5.6314522],
          [115.8864924, 5.6319235],
          [115.8865064, 5.6321549],
          [115.8864869, 5.6331896],
          [115.8864924, 5.6343971],
          [115.8857891, 5.6346982],
          [115.8858036, 5.6348712],
          [115.8852793, 5.6350804],
          [115.8848749, 5.6352059],
          [115.884835315818492, 5.635510325431824],
          [115.8848303, 5.6355489],
          [115.8846184, 5.6355656],
          [115.88461, 5.6359561],
          [115.884557, 5.636023],
          [115.8845124, 5.6368485],
          [115.8855721, 5.6371246],
          [115.8857952, 5.6371692],
          [115.8858371, 5.6382373],
          [115.8860183, 5.6384688],
          [115.886063, 5.6387533],
          [115.8860797, 5.6404544],
          [115.8860574, 5.6414556],
          [115.8860769, 5.6421835],
          [115.8868606, 5.642507],
          [115.8871645, 5.6426353],
          [115.8873793, 5.6437396],
          [115.8877669, 5.644607],
          [115.8881211, 5.6452177],
          [115.8882622, 5.64557],
          [115.8881167, 5.6462863],
          [115.8882115, 5.6474743],
          [115.8882673, 5.6483779],
          [115.888825, 5.6497611],
          [115.8889087, 5.6501962],
          [115.8886354, 5.6514121],
          [115.8884067, 5.6515906],
          [115.8882505, 5.652193],
          [115.8882784, 5.6521986],
          [115.8884235, 5.6522822],
          [115.8888333, 5.6524303],
          [115.8898717, 5.6539342],
          [115.8898844, 5.6543541],
          [115.8898922, 5.6546121],
          [115.889931, 5.6553099],
          [115.8899787, 5.6561695],
          [115.8906709, 5.6584884],
          [115.8914323, 5.6602015],
          [115.8944087, 5.669425],
          [115.8940799, 5.6709306],
          [115.8933185, 5.6716747],
          [115.8931628, 5.6722111],
          [115.8939588, 5.6745646],
          [115.8953778, 5.6774026],
          [115.8952567, 5.6790119],
          [115.8951135, 5.6799854],
          [115.8949971, 5.680777],
          [115.8950836, 5.6839957],
          [115.8953605, 5.688893],
          [115.8956547, 5.6913849],
          [115.8966841, 5.6964016],
          [115.8969424, 5.7055026],
          [115.8967556, 5.7114862],
          [115.8965545, 5.7141331],
          [115.8961288, 5.7196872],
          [115.8956834, 5.7224423],
          [115.895744, 5.7244357],
          [115.8955781, 5.7248148],
          [115.8955544, 5.7252767],
          [115.8959927, 5.726947],
          [115.8960757, 5.7277407],
          [115.8961937, 5.728056],
          [115.8962889, 5.72825],
          [115.8966679, 5.7282382],
          [115.8966798, 5.7285462],
          [115.8964073, 5.7286528],
          [115.8961141, 5.7285336],
          [115.895934, 5.7284205],
          [115.8958502, 5.7282153],
          [115.8957748, 5.7281232],
          [115.8955487, 5.7278928],
          [115.8954523, 5.7273693],
          [115.8953183, 5.7267872],
          [115.8952136, 5.7262804],
          [115.8951801, 5.7257108],
          [115.8951592, 5.7251119],
          [115.8950754, 5.725493],
          [115.8950796, 5.7261631],
          [115.8951047, 5.7267579],
          [115.8952094, 5.7273526],
          [115.895289, 5.7276709],
          [115.8953183, 5.7279515],
          [115.8955194, 5.7289482],
          [115.8956199, 5.7300204],
          [115.8956785, 5.7306151],
          [115.8957036, 5.7310297],
          [115.895779, 5.7312308],
          [115.8957748, 5.7314234],
          [115.8959005, 5.7316454],
          [115.895867, 5.7321186],
          [115.8960094, 5.7326086],
          [115.8960889, 5.7330107],
          [115.8962146, 5.7335803],
          [115.8964994, 5.7345142],
          [115.8967339, 5.7354189],
          [115.897069, 5.7365413],
          [115.8973956, 5.7375129],
          [115.897693, 5.7383464],
          [115.8979778, 5.7389076],
          [115.8983086, 5.7395148],
          [115.8985139, 5.7400258],
          [115.8986856, 5.7400802],
          [115.8987861, 5.7404865],
          [115.8990667, 5.7409849],
          [115.8992887, 5.7411691],
          [115.8993473, 5.7414539],
          [115.8996237, 5.74199],
          [115.8997033, 5.7421617],
          [115.8997326, 5.7422999],
          [115.8996823, 5.7423921],
          [115.8994939, 5.742501],
          [115.8994855, 5.7425512],
          [115.8998792, 5.7432548],
          [115.9011356, 5.7451688],
          [115.9013157, 5.7453112],
          [115.9018099, 5.7458347],
          [115.9024004, 5.7465844],
          [115.9031752, 5.7475853],
          [115.9037699, 5.7483769],
          [115.9037951, 5.748624],
          [115.9042348, 5.7492941],
          [115.904507, 5.7499684],
          [115.9047583, 5.7505714],
          [115.9051018, 5.7512876],
          [115.9052693, 5.7514174],
          [115.9055373, 5.751518],
          [115.905931, 5.7516143],
          [115.9065299, 5.7516939],
          [115.9069529, 5.7517316],
          [115.9072209, 5.7515892],
          [115.9074555, 5.7515389],
          [115.907958, 5.7514928],
          [115.9082762, 5.7514329],
          [115.9086434, 5.7510183],
          [115.9090521, 5.7505563],
          [115.9113916, 5.7488772],
          [115.9117167, 5.7492135],
          [115.9121486, 5.7496603],
          [115.9118998, 5.7503769],
          [115.9113075, 5.7506612],
          [115.9104487, 5.7514904],
          [115.9097498, 5.7517806],
          [115.9093411, 5.7522545],
          [115.9088969, 5.7524559],
          [115.9086244, 5.7529178],
          [115.9085948, 5.7532673],
          [115.9086777, 5.7534154],
          [115.9088614, 5.7539129],
          [115.9098209, 5.7542446],
          [115.9104191, 5.7544815],
          [115.9115307, 5.7554436],
          [115.9129297, 5.7558279],
          [115.9165775, 5.7571003],
          [115.9359449, 5.7631344],
          [115.9397183, 5.7646982],
          [115.9425453, 5.7660549],
          [115.9432255, 5.766594],
          [115.9438928, 5.7669206],
          [115.9442405, 5.7670445],
          [115.9449925, 5.7668982],
          [115.9457983, 5.7665854],
          [115.9470954, 5.7668882],
          [115.9469752, 5.7677938],
          [115.9483528, 5.7690555],
          [115.9501646, 5.7703243],
          [115.9559915, 5.7732155],
          [115.9651926, 5.7778342],
          [115.9716933, 5.7818565],
          [115.9729061, 5.782916],
          [115.9729635, 5.7830534],
          [115.9728314, 5.7840896],
          [115.9727076, 5.784697],
          [115.9727076, 5.7849938],
          [115.9727784, 5.7853575],
          [115.9728629, 5.7855069],
          [115.973144, 5.7855561],
          [115.9734408, 5.7854735],
          [115.9736983, 5.7852376],
          [115.9739244, 5.7852808],
          [115.9742035, 5.7854322],
          [115.9744945, 5.7856366],
          [115.9747107, 5.7858922],
          [115.974921, 5.7861045],
          [115.9750704, 5.7860298],
          [115.9752434, 5.7859531],
          [115.975326, 5.7858942],
          [115.9753358, 5.785788],
          [115.9752474, 5.7856799],
          [115.9752651, 5.7851177],
          [115.9753437, 5.784982],
          [115.9755167, 5.7848916],
          [115.9755363, 5.7849742],
          [115.9756209, 5.785155],
          [115.9757337, 5.7851618],
          [115.9758831, 5.784517],
          [115.9758045, 5.7843126],
          [115.9754742, 5.7838565],
          [115.975663, 5.7835971],
          [115.977094, 5.783086],
          [115.9775679, 5.7831198],
          [115.9820615, 5.785786],
          [115.9857152, 5.7896422],
          [115.9871568, 5.7906047],
          [115.9878913, 5.7910938],
          [115.9888607, 5.7915197],
          [115.9894659, 5.7919273],
          [115.9904238, 5.7927459],
          [115.9925834, 5.794667],
          [115.9936585, 5.7972195],
          [115.996122, 5.7980599],
          [115.998026, 5.8001319],
          [115.999497687452674, 5.801122935654252],
          [115.999497687452674, 5.801122935654251],
          [116.0010058, 5.8021385],
          [116.00332, 5.803997],
          [116.0037857, 5.8042854],
          [116.004305, 5.804607],
          [116.004398, 5.804972],
          [116.0050892, 5.8076837],
          [116.0052762, 5.8098989],
          [116.0054639, 5.8123829],
          [116.0051869, 5.8140717],
          [116.0063485, 5.8150635],
          [116.0066829, 5.8160074],
          [116.0071169, 5.8177798],
          [116.0077781, 5.8189414],
          [116.0089914, 5.821795],
          [116.010214328765869, 5.823600091932508],
          [116.0109551, 5.8246935],
          [116.011656, 5.8244902],
          [116.0121653, 5.8237754],
          [116.0136287, 5.8234106],
          [116.0138204, 5.823713],
          [116.0139435, 5.8239072],
          [116.015017, 5.8256008],
          [116.0202469, 5.8257257],
          [116.0227715, 5.8266526],
          [116.0256933, 5.8279482],
          [116.0287371, 5.8286213],
          [116.0306792, 5.8289847],
          [116.0325229, 5.8295405],
          [116.0355499, 5.8319472],
          [116.0367, 5.8338449],
          [116.0380433, 5.8367266],
          [116.0408392, 5.8410045],
          [116.0430644, 5.8434699],
          [116.043766, 5.8457348],
          [116.0347409, 5.8337851],
          [116.0318985, 5.8357864],
          [116.039998, 5.8468213],
          [116.043103, 5.8467845],
          [116.0435013, 5.846748],
          [116.043126, 5.8480257],
          [116.0428973, 5.8495794],
          [116.0427507, 5.8506527],
          [116.042572, 5.8516803],
          [116.0423438, 5.853212],
          [116.0422897, 5.8535754],
          [116.0421901, 5.8545236],
          [116.0422264, 5.8552466],
          [116.0422264, 5.8559153],
          [116.0422991, 5.8561683],
          [116.0422991, 5.8569817],
          [116.0424444, 5.8576866],
          [116.0424064, 5.8579257],
          [116.0422809, 5.8587168],
          [116.0419756, 5.8603109],
          [116.0415723, 5.861681],
          [116.0413361, 5.8631089],
          [116.0415178, 5.8649343],
          [116.0417904, 5.8658923],
          [116.0420629, 5.8666333],
          [116.0422628, 5.867284],
          [116.0425535, 5.8679708],
          [116.0427872, 5.8681702],
          [116.0430214, 5.8679986],
          [116.0431693, 5.8677534],
          [116.043576, 5.8676308],
          [116.0440813, 5.8676798],
          [116.0437855, 5.8673611],
          [116.043576, 5.8673611],
          [116.0432432, 5.8672753],
          [116.0432802, 5.8668462],
          [116.0436006, 5.8661474],
          [116.0437115, 5.8658164],
          [116.0437362, 5.8651911],
          [116.0434897, 5.8646272],
          [116.0429228, 5.8640755],
          [116.0427133, 5.8636587],
          [116.0422819, 5.8629599],
          [116.0420478, 5.8623959],
          [116.0420847, 5.8617829],
          [116.0421833, 5.8615377],
          [116.0422203, 5.8612067],
          [116.0423928, 5.8608021],
          [116.0427379, 5.8606182],
          [116.0431323, 5.8605692],
          [116.0435636, 5.8604466],
          [116.0436966, 5.8603595],
          [116.0437142, 5.8604948],
          [116.043725, 5.860879],
          [116.0437786, 5.8612205],
          [116.0439288, 5.8614873],
          [116.0441219, 5.8616901],
          [116.0443472, 5.8617755],
          [116.0442045, 5.8619975],
          [116.0441922, 5.8623162],
          [116.0443524, 5.8626595],
          [116.0445927, 5.8627882],
          [116.0450857, 5.8635177],
          [116.045289, 5.8634503],
          [116.0455294, 5.8638303],
          [116.0459422, 5.8640387],
          [116.0460778, 5.8643452],
          [116.0462935, 5.8644801],
          [116.0463058, 5.8647498],
          [116.0463551, 5.8653076],
          [116.0470144, 5.8664539],
          [116.047187, 5.8668646],
          [116.0473965, 5.8673366],
          [116.0477908, 5.867594],
          [116.0481359, 5.8675021],
          [116.0482468, 5.8676369],
          [116.0481421, 5.8679986],
          [116.0481113, 5.868256],
          [116.0479942, 5.8683419],
          [116.0477785, 5.8682622],
          [116.0475382, 5.8683909],
          [116.0474827, 5.868679],
          [116.0475505, 5.8689303],
          [116.047671, 5.8691384],
          [116.0477847, 5.8693349],
          [116.0479572, 5.8695249],
          [116.0481421, 5.8695556],
          [116.0483578, 5.8694759],
          [116.0484379, 5.869151],
          [116.0485549, 5.8689794],
          [116.0487953, 5.8688752],
          [116.0489863, 5.8689058],
          [116.0492143, 5.8688752],
          [116.0493807, 5.8687648],
          [116.0494669, 5.8689916],
          [116.0492389, 5.8690774],
          [116.0490294, 5.8690713],
          [116.0488199, 5.8691081],
          [116.048672, 5.8692184],
          [116.0486227, 5.8693839],
          [116.0485611, 5.8696352],
          [116.0486166, 5.8698007],
          [116.0487275, 5.8699233],
          [116.0489185, 5.8699417],
          [116.0490294, 5.8698682],
          [116.0490787, 5.869672],
          [116.0491773, 5.8696475],
          [116.049775, 5.8699356],
          [116.0500154, 5.8706221],
          [116.0503173, 5.8716458],
          [116.0502742, 5.8717929],
          [116.0503604, 5.872081],
          [116.050305, 5.8722527],
          [116.0506994, 5.8738525],
          [116.0508226, 5.8738341],
          [116.0510444, 5.8745574],
          [116.0511245, 5.8749865],
          [116.0511061, 5.8755566],
          [116.0510321, 5.8760041],
          [116.0506069, 5.8772668],
          [116.0500537, 5.8790098],
          [116.0497664, 5.8797786],
          [116.0495656, 5.8800294],
          [116.0493088, 5.8798669],
          [116.0492107, 5.8792444],
          [116.0486737, 5.8795092],
          [116.0482907, 5.8794813],
          [116.0480245, 5.879472],
          [116.0478938, 5.8798436],
          [116.0481086, 5.8804011],
          [116.0487624, 5.8809446],
          [116.0493695, 5.8814881],
          [116.0493368, 5.881725],
          [116.0490473, 5.8816971],
          [116.0488932, 5.8817343],
          [116.0480899, 5.8812651],
          [116.0476253, 5.880509],
          [116.0475945, 5.8797673],
          [116.0478533, 5.8790869],
          [116.0480998, 5.8788846],
          [116.0486605, 5.8792462],
          [116.0487468, 5.8792769],
          [116.0489193, 5.8791727],
          [116.0489008, 5.8789888],
          [116.0485866, 5.8786884],
          [116.048297, 5.8785904],
          [116.0479889, 5.8786701],
          [116.0474712, 5.8789949],
          [116.0465038, 5.8799634],
          [116.046399, 5.8804354],
          [116.0467441, 5.8808461],
          [116.0472001, 5.8812568],
          [116.0472432, 5.8813732],
          [116.0471385, 5.8814039],
          [116.0466455, 5.8810729],
          [116.0461587, 5.8809626],
          [116.045715, 5.8808032],
          [116.045561, 5.8805703],
          [116.0456014, 5.880321],
          [116.0457936, 5.8801999],
          [116.0456783, 5.8800916],
          [116.0452029, 5.8805563],
          [116.04482, 5.8808072],
          [116.0443156, 5.8810395],
          [116.0439981, 5.8810395],
          [116.0437272, 5.8812624],
          [116.0434563, 5.8814018],
          [116.0432415, 5.8814204],
          [116.0430641, 5.8812067],
          [116.042909, 5.8812592],
          [116.0427786, 5.8825564],
          [116.0426822, 5.883222],
          [116.0426255, 5.8836451],
          [116.0428693, 5.8836563],
          [116.0431018, 5.8836676],
          [116.0431018, 5.8842937],
          [116.0431301, 5.8846772],
          [116.0431415, 5.8849085],
          [116.0430062, 5.885066],
          [116.0423218, 5.8851697],
          [116.042027, 5.8868495],
          [116.0413816, 5.8884036],
          [116.0396712, 5.8920436],
          [116.0400568, 5.8922917],
          [116.0401702, 5.8924328],
          [116.0401705, 5.8926259],
          [116.0400852, 5.8929404],
          [116.0399888, 5.8931265],
          [116.0396882, 5.8935044],
          [116.0393821, 5.8937695],
          [116.0391779, 5.8937921],
          [116.0389851, 5.8936398],
          [116.0386392, 5.8934029],
          [116.0381686, 5.8933408],
          [116.0379305, 5.8934029],
          [116.0377093, 5.8934649],
          [116.0351379, 5.8943015],
          [116.0326271, 5.8950431],
          [116.0330292, 5.8969463],
          [116.0340835, 5.8968927],
          [116.0368168, 5.8961552],
          [116.0370863, 5.8964926],
          [116.0356199, 5.8972987],
          [116.0348162, 5.8977863],
          [116.0345214, 5.8976254],
          [116.033447, 5.8974388],
          [116.0329845, 5.8973127],
          [116.0328773, 5.897688],
          [116.0330336, 5.8985364],
          [116.0337082, 5.8999039],
          [116.034298, 5.9010208],
          [116.0352408, 5.9004776],
          [116.0369471, 5.9025544],
          [116.038651, 5.9053231],
          [116.0397091, 5.9082011],
          [116.0404722, 5.9096434],
          [116.0409115, 5.9101862],
          [116.0420416, 5.9115827],
          [116.042658, 5.9123249],
          [116.043126, 5.9137536],
          [116.0421789, 5.914111],
          [116.0405973, 5.9148169],
          [116.0399629, 5.9155674],
          [116.0401684, 5.9166307],
          [116.0425006, 5.922117],
          [116.043539, 5.9255469],
          [116.0439034, 5.9253069],
          [116.0444038, 5.9265578],
          [116.0453688, 5.9286665],
          [116.0464499, 5.9311237],
          [116.0464332, 5.932303],
          [116.0465661, 5.9347336],
          [116.0465365, 5.9379097],
          [116.0461378, 5.9438856],
          [116.0456815, 5.9471447],
          [116.0448896, 5.9490345],
          [116.0442161, 5.9503078],
          [116.0435083, 5.9512118],
          [116.0433137, 5.9514604],
          [116.0414563, 5.9534737],
          [116.041212, 5.9542245],
          [116.0405015, 5.9546978],
          [116.0402878, 5.9551872],
          [116.0402391, 5.955533],
          [116.0403038, 5.9559019],
          [116.0404617, 5.9562547],
          [116.0404448, 5.9564271],
          [116.0404052, 5.9565022],
          [116.0402838, 5.9565698],
          [116.0400709, 5.9565771],
          [116.0400079, 5.9566589],
          [116.0400206, 5.9567067],
          [116.0401008, 5.9567812],
          [116.0403031, 5.9568725],
          [116.0403716, 5.9567387],
          [116.040363, 5.9566502],
          [116.0405234, 5.9565435],
          [116.0405469, 5.956491],
          [116.0406985, 5.9565268],
          [116.0408955, 5.9566484],
          [116.0410587, 5.9568397],
          [116.0411277, 5.9570187],
          [116.0411227, 5.9571652],
          [116.0410173, 5.9572147],
          [116.0408199, 5.9572784],
          [116.0407304, 5.957362],
          [116.0406879, 5.9574933],
          [116.0407055, 5.9575943],
          [116.0407988, 5.9577516],
          [116.0409379, 5.9578098],
          [116.0410898, 5.9577894],
          [116.0413088, 5.957605],
          [116.0413568, 5.9574794],
          [116.0413497, 5.9572928],
          [116.0418709, 5.9567929],
          [116.0430479, 5.9567763],
          [116.043957, 5.9568126],
          [116.0443151, 5.9566719],
          [116.0445136, 5.9564576],
          [116.0445674, 5.9562348],
          [116.0445022, 5.9549152],
          [116.0455904, 5.9548346],
          [116.0467165, 5.9552112],
          [116.0474394, 5.955513],
          [116.0478058, 5.9558005],
          [116.0486445, 5.9564585],
          [116.0496329, 5.957015],
          [116.0503368, 5.9574113],
          [116.0517122, 5.9581102],
          [116.0517245, 5.9581164],
          [116.0521369, 5.958326],
          [116.0524289, 5.9583499],
          [116.0525933, 5.9583302],
          [116.0526514, 5.9582724],
          [116.0527, 5.9582287],
          [116.052538, 5.9580482],
          [116.0524246, 5.9578607],
          [116.05279, 5.9575762],
          [116.0529134, 5.957683],
          [116.0531936, 5.9579113],
          [116.0536897, 5.9574056],
          [116.0542759, 5.9576941],
          [116.0545829, 5.9574588],
          [116.0547423, 5.9575809],
          [116.0548935, 5.9573618],
          [116.0555264, 5.9573562],
          [116.0557407, 5.9574084],
          [116.0559914, 5.9573736],
          [116.0563717, 5.9575606],
          [116.0565685, 5.9576939],
          [116.0566705, 5.9577186],
          [116.0570713, 5.9583854],
          [116.0571515, 5.9583506],
          [116.0571746, 5.9583397],
          [116.0572098, 5.958323],
          [116.0572958, 5.9584448],
          [116.0574794, 5.9584143],
          [116.0575829, 5.9583955],
          [116.0576936, 5.9584651],
          [116.0576164, 5.958813],
          [116.0576237, 5.9592681],
          [116.0580172, 5.9609707],
          [116.0598432, 5.9628769],
          [116.0608041, 5.9638287],
          [116.061674, 5.9647096],
          [116.0616489, 5.9647949],
          [116.061572, 5.9648469],
          [116.0609642, 5.9642265],
          [116.057035, 5.960259],
          [116.0565203, 5.9603509],
          [116.0560142, 5.9608071],
          [116.0554527, 5.9612555],
          [116.0546855, 5.9618061],
          [116.0541794, 5.9618533],
          [116.0522813, 5.9633832],
          [116.0526204, 5.9640469],
          [116.0527409, 5.9640486],
          [116.0528683, 5.9639972],
          [116.0529458, 5.9640965],
          [116.0530094, 5.9641907],
          [116.0530252, 5.9642169],
          [116.0528614, 5.9644372],
          [116.0531992, 5.9650895],
          [116.0533352, 5.9651152],
          [116.0535073, 5.9653497],
          [116.0538963, 5.9654216],
          [116.0541046, 5.9654644],
          [116.054275, 5.9655346],
          [116.0547896, 5.9655739],
          [116.0550891, 5.965728],
          [116.0552544, 5.9660635],
          [116.0552389, 5.9662758],
          [116.055275, 5.9664862],
          [116.054558, 5.9671979],
          [116.0552333, 5.9679907],
          [116.0552995, 5.9684496],
          [116.0555014, 5.9683838],
          [116.0554924, 5.9679996],
          [116.0547563, 5.9672096],
          [116.0558677, 5.9662036],
          [116.0575118, 5.966293],
          [116.0574948, 5.9665118],
          [116.0573599, 5.9682498],
          [116.0566808, 5.9684106],
          [116.0562251, 5.967669],
          [116.0560911, 5.9677673],
          [116.0561358, 5.9683123],
          [116.0563055, 5.9682945],
          [116.0564785, 5.9692205],
          [116.0565276, 5.9692669],
          [116.0566166, 5.9692769],
          [116.0566992, 5.9692693],
          [116.0567573, 5.9692926],
          [116.0567875, 5.96934],
          [116.0567943, 5.969386],
          [116.0567798, 5.9694388],
          [116.0567242, 5.9694726],
          [116.0566272, 5.9694948],
          [116.0565674, 5.969518],
          [116.0565301, 5.9695629],
          [116.0565647, 5.9698313],
          [116.0570293, 5.9707874],
          [116.0570472, 5.9713414],
          [116.0567022, 5.9723987],
          [116.0570472, 5.9731285],
          [116.0575878, 5.9734107],
          [116.0577144, 5.9738525],
          [116.0577373, 5.9743358],
          [116.0577441, 5.9753623],
          [116.0581015, 5.9761218],
          [116.0585419, 5.9778889],
          [116.0589415, 5.9789185],
          [116.0590545, 5.9790806],
          [116.0592279, 5.9791919],
          [116.0594409, 5.9792029],
          [116.0597213, 5.9792023],
          [116.0599854, 5.9790849],
          [116.0600917, 5.978982],
          [116.0606615, 5.9790086],
          [116.0619304, 5.9792245],
          [116.0619204, 5.9792919],
          [116.0624482, 5.9793691],
          [116.0624582, 5.9793017],
          [116.062765, 5.9792647],
          [116.0642082, 5.9779703],
          [116.0643078, 5.9774547],
          [116.0640138, 5.9774067],
          [116.0640231, 5.9773587],
          [116.0643171, 5.9774067],
          [116.0643892, 5.9770331],
          [116.0642751, 5.9766524],
          [116.0641479, 5.976384],
          [116.0639672, 5.9761362],
          [116.064163, 5.9753438],
          [116.0645266, 5.9739203],
          [116.0649004, 5.9724566],
          [116.065135, 5.9717544],
          [116.0655128, 5.9722061],
          [116.0657418, 5.9724246],
          [116.0658897, 5.9726173],
          [116.0665189, 5.9732786],
          [116.0664193, 5.9736044],
          [116.0665414, 5.9739002],
          [116.0665707, 5.9739461],
          [116.0670837, 5.9747516],
          [116.0678322, 5.9759268],
          [116.0678328, 5.9761768],
          [116.0690985, 5.9781185],
          [116.0691902, 5.9782573],
          [116.0710625, 5.9811142],
          [116.0715312, 5.9817867],
          [116.0708343, 5.9822514],
          [116.071701, 5.9834755],
          [116.0719512, 5.9833236],
          [116.0722193, 5.9837436],
          [116.0726571, 5.983538],
          [116.0728179, 5.9837346],
          [116.0726785, 5.9838081],
          [116.0728754, 5.9840521],
          [116.0730011, 5.9839771],
          [116.0730753, 5.9840505],
          [116.0739433, 5.9845788],
          [116.0739378, 5.9846852],
          [116.0741082, 5.9849046],
          [116.0740951, 5.9849984],
          [116.0742865, 5.9852522],
          [116.0743457, 5.9852514],
          [116.0743958, 5.9853212],
          [116.0745286, 5.9853736],
          [116.0747253, 5.9856497],
          [116.0745733, 5.9857522],
          [116.0745473, 5.9857202],
          [116.0745269, 5.9857374],
          [116.0745782, 5.9858049],
          [116.0745427, 5.9858278],
          [116.0746367, 5.9859582],
          [116.0747041, 5.9859123],
          [116.0747235, 5.9859388],
          [116.0748494, 5.9858524],
          [116.07495425493795, 5.985998421907425],
          [116.0753527, 5.9865533],
          [116.0752792, 5.9866253],
          [116.0752692, 5.98673],
          [116.0753744, 5.9869095],
          [116.0753469, 5.9870541],
          [116.0753919, 5.9871839],
          [116.076143, 5.9885983],
          [116.0768031, 5.9882916],
          [116.0772409, 5.9882648],
          [116.0773736, 5.9883531],
          [116.0775579, 5.9886139],
          [116.077301, 5.9887363],
          [116.0772602, 5.9887232],
          [116.0771574, 5.9885293],
          [116.0770723, 5.9885751],
          [116.0773521, 5.9890545],
          [116.0774084, 5.9890165],
          [116.0773123, 5.988837],
          [116.0773248, 5.9887921],
          [116.0782267, 5.9883357],
          [116.07834, 5.9885515],
          [116.0779876, 5.9887186],
          [116.0774889, 5.9889582],
          [116.0774803, 5.9891],
          [116.0777171, 5.9896125],
          [116.077959, 5.989531],
          [116.0786259, 5.989337],
          [116.0788642, 5.9897333],
          [116.0788353, 5.9897122],
          [116.0786687, 5.9898778],
          [116.0787705, 5.9899744],
          [116.0784071, 5.9903283],
          [116.0784133, 5.9903707],
          [116.0776336, 5.9905458],
          [116.0775464, 5.9902699],
          [116.0774289, 5.9902954],
          [116.0778689, 5.9920528],
          [116.0780035, 5.9920156],
          [116.0776698, 5.9907399],
          [116.0777794, 5.9907148],
          [116.0784114, 5.9905701],
          [116.0789565, 5.9916513],
          [116.0789207, 5.992375],
          [116.0779944, 5.9928716],
          [116.0785097, 5.9938583],
          [116.0787757, 5.9936921],
          [116.0783372, 5.9928905],
          [116.0790226, 5.9924991],
          [116.0791173, 5.9926788],
          [116.0807435, 5.9931256],
          [116.0815528, 5.9942192],
          [116.0815525, 5.9943466],
          [116.080432, 5.9950018],
          [116.0803108, 5.9947856],
          [116.0793433, 5.9953262],
          [116.0809043, 5.9981472],
          [116.0818872, 5.9975932],
          [116.0809746, 5.9959979],
          [116.0822625, 5.9952879],
          [116.0829163, 5.996198],
          [116.0829406, 5.9964277],
          [116.0828211, 5.9967661],
          [116.0828121, 5.9969389],
          [116.0828682, 5.9972486],
          [116.08302, 5.9972131],
          [116.0829694, 5.9969681],
          [116.0829867, 5.996963],
          [116.082963, 5.9968879],
          [116.0829149, 5.996898],
          [116.082933, 5.9967288],
          [116.083, 5.996565],
          [116.0833952, 5.998654],
          [116.0835383, 5.9986504],
          [116.0833241, 5.997584],
          [116.0841992, 5.9988035],
          [116.0859024, 5.9975606],
          [116.0865693, 5.9980579],
          [116.0874418, 5.9984052],
          [116.0882581, 5.9985404],
          [116.0890669, 5.9984112],
          [116.0898039, 5.9980042],
          [116.0902618, 5.9975038],
          [116.0909114, 5.996454],
          [116.0921539, 5.9939387],
          [116.0926601, 5.9935106],
          [116.0932106, 5.9932612],
          [116.0956475, 5.9925895],
          [116.0985281, 5.9918446],
          [116.1008568, 5.9929022],
          [116.1018884, 5.9935828],
          [116.1026749, 5.9941017],
          [116.1043952, 5.9952343],
          [116.1046454, 5.9962976],
          [116.1052083, 5.9968605],
          [116.1062626, 5.9970392],
          [116.1072572, 5.998139],
          [116.108729839504619, 6.0005],
          [116.1089343, 6.0008278],
          [116.1093301, 6.0020434],
          [116.1094347, 6.0023647],
          [116.1091487, 6.0032939],
          [116.1094356, 6.004296],
          [116.1099869, 6.0059853],
          [116.1101406, 6.0067161],
          [116.1101406, 6.0074399],
          [116.1101763, 6.0084496],
          [116.1102097, 6.0087008],
          [116.1103321, 6.009622],
          [116.1106754, 6.011475],
          [116.1113111, 6.0119611],
          [116.1117846, 6.0123632],
          [116.1124419, 6.0126056],
          [116.1123215, 6.0132298],
          [116.1123208, 6.0136678],
          [116.1105141, 6.014025],
          [116.1095419, 6.0144183],
          [116.1089997, 6.0153291],
          [116.1081559, 6.0177826],
          [116.1081953, 6.0192108],
          [116.1087556, 6.020405],
          [116.109082, 6.0205982],
          [116.1091595, 6.0227549],
          [116.1086841, 6.0229783],
          [116.1089968, 6.0238897],
          [116.1090147, 6.0247386],
          [116.1076208, 6.0255249],
          [116.1068702, 6.0256232],
          [116.1066469, 6.0259359],
          [116.1067131, 6.0263263],
          [116.1071383, 6.0265614],
          [116.1078531, 6.0261414],
          [116.1084428, 6.026472],
          [116.1089231, 6.0269448],
          [116.1096097, 6.0281407],
          [116.1097653, 6.0293134],
          [116.1101445, 6.0302527],
          [116.1103729, 6.0310022],
          [116.1096428, 6.0318307],
          [116.109667, 6.0324586],
          [116.1100423, 6.0326731],
          [116.1105644, 6.0324319],
          [116.1111592, 6.0330662],
          [116.1115535, 6.0337448],
          [116.1120259, 6.0344512],
          [116.1121629, 6.0352918],
          [116.1122826, 6.036624],
          [116.1121459, 6.0376378],
          [116.1112753, 6.0385525],
          [116.1107392, 6.0383202],
          [116.1103068, 6.0389258],
          [116.1110609, 6.0400447],
          [116.1111432, 6.0401396],
          [116.1118976, 6.0410088],
          [116.1119193, 6.0410859],
          [116.1121043, 6.0417421],
          [116.1120625, 6.0419875],
          [116.1119339, 6.0427422],
          [116.1115288, 6.0435962],
          [116.1111158, 6.0451438],
          [116.111119, 6.0459664],
          [116.1111217, 6.0466618],
          [116.1108425, 6.0493387],
          [116.1100155, 6.0495786],
          [116.109658, 6.0499271],
          [116.1098468, 6.0503902],
          [116.1098904, 6.0513657],
          [116.1103282, 6.051455],
          [116.1107124, 6.0511602],
          [116.1111324, 6.0509993],
          [116.1117668, 6.0516695],
          [116.1123179, 6.0527378],
          [116.1134913, 6.0541982],
          [116.1142061, 6.0544662],
          [116.1148494, 6.055047],
          [116.1154555, 6.055741],
          [116.1168509, 6.0578795],
          [116.1178606, 6.0592377],
          [116.118612, 6.0594711],
          [116.1191205, 6.0591751],
          [116.1192992, 6.0586926],
          [116.1199783, 6.0578974],
          [116.121215, 6.0579138],
          [116.1225963, 6.0583977],
          [116.124007, 6.0587035],
          [116.1242225, 6.0580493],
          [116.1246558, 6.0567584],
          [116.1256809, 6.0559672],
          [116.127064, 6.0562265],
          [116.1279485, 6.0573076],
          [116.1286902, 6.0586211],
          [116.1288063, 6.0594164],
          [116.1279217, 6.0599882],
          [116.127813, 6.0608949],
          [116.1277155, 6.0617085],
          [116.1275511, 6.062223],
          [116.1271154, 6.0635869],
          [116.1263697, 6.0640267],
          [116.1262496, 6.0640976],
          [116.1260971, 6.0641216],
          [116.1258749, 6.0641135],
          [116.1258459, 6.0640644],
          [116.1258799, 6.0639118],
          [116.1259069, 6.0636845],
          [116.1259134, 6.063463],
          [116.1259484, 6.0632249],
          [116.1259392, 6.0631344],
          [116.1258973, 6.0630645],
          [116.125826, 6.0630132],
          [116.1257263, 6.0629924],
          [116.1255767, 6.0630108],
          [116.1254324, 6.0630507],
          [116.1252822, 6.0631354],
          [116.1250488, 6.0633212],
          [116.124924, 6.0635178],
          [116.124808, 6.0636723],
          [116.1246501, 6.0638324],
          [116.1218726, 6.0658498],
          [116.1220317, 6.0662534],
          [116.1220818, 6.0664029],
          [116.1220693, 6.0665898],
          [116.1219816, 6.0667517],
          [116.1217624, 6.0669012],
          [116.1216057, 6.067032],
          [116.1215744, 6.0671753],
          [116.1216872, 6.067356],
          [116.1217644, 6.067461],
          [116.1218751, 6.0676114],
          [116.1220944, 6.0678792],
          [116.1222009, 6.0680536],
          [116.1221006, 6.0681782],
          [116.1218939, 6.0683215],
          [116.1215619, 6.0686205],
          [116.121518, 6.0688697],
          [116.1222384, 6.0698414],
          [116.1223825, 6.0698477],
          [116.1229401, 6.0706388],
          [116.1230653, 6.0706886],
          [116.1243934, 6.0724764],
          [116.1244247, 6.0725761],
          [116.1243307, 6.0726695],
          [116.1243683, 6.0727443],
          [116.1245751, 6.072738],
          [116.1245876, 6.0728252],
          [116.1244623, 6.0729623],
          [116.1245249, 6.0730246],
          [116.1246753, 6.0728875],
          [116.1250261, 6.0730869],
          [116.1248757, 6.0733547],
          [116.1249384, 6.0734419],
          [116.1249259, 6.0735167],
          [116.1250574, 6.0736101],
          [116.125333, 6.0732364],
          [116.1254959, 6.0733423],
          [116.125682405884447, 6.073620446026325],
          [116.1257214, 6.0736786],
          [116.1255961, 6.0737908],
          [116.1256149, 6.0738967],
          [116.1257528, 6.0738282],
          [116.1257903, 6.0739091],
          [116.12564, 6.0740026],
          [116.1257152, 6.074096],
          [116.1258405, 6.0741708],
          [116.1259156, 6.0742953],
          [116.1261599, 6.0742704],
          [116.1261725, 6.074096],
          [116.1286845, 6.0741458],
          [116.1291794, 6.0746317],
          [116.1295364, 6.0750553],
          [116.1297995, 6.0755661],
          [116.1299186, 6.0760271],
          [116.1299749, 6.0764444],
          [116.1300188, 6.0769178],
          [116.1300376, 6.0775034],
          [116.1300125, 6.0777152],
          [116.1299499, 6.0778148],
          [116.129881, 6.0778896],
          [116.1270307, 6.0796898],
          [116.1273251, 6.0803376],
          [116.1278012, 6.0814776],
          [116.1278701, 6.0816333],
          [116.127914, 6.0820506],
          [116.1279515, 6.0824555],
          [116.1279049, 6.0829419],
          [116.1281309, 6.0832877],
          [116.1285849, 6.0833912],
          [116.1286292, 6.0834242],
          [116.1293069, 6.0845342],
          [116.1290943, 6.084706],
          [116.1291041, 6.0847232],
          [116.1291139, 6.0847406],
          [116.1291561, 6.0848152],
          [116.129174, 6.0848469],
          [116.1293955, 6.0850672],
          [116.1294974, 6.08512],
          [116.1296701, 6.0849923],
          [116.1296834, 6.0848602],
          [116.1297056, 6.0848161],
          [116.1307864, 6.08438],
          [116.1309016, 6.084358],
          [116.1310123, 6.08438],
          [116.1314198, 6.0845783],
          [116.1314641, 6.0846223],
          [116.1315217, 6.0847456],
          [116.1316634, 6.0851465],
          [116.1318273, 6.0851641],
          [116.1319204, 6.0851509],
          [116.1319336, 6.0852081],
          [116.1318583, 6.0852389],
          [116.1318052, 6.0852918],
          [116.1317387, 6.0853314],
          [116.1317476, 6.0854416],
          [116.1318539, 6.0857543],
          [116.1318451, 6.085816],
          [116.1318583, 6.0858908],
          [116.1319115, 6.0859613],
          [116.1319026, 6.086067],
          [116.1318318, 6.0860846],
          [116.1317742, 6.0860362],
          [116.1316812, 6.0859965],
          [116.1304187, 6.0873488],
          [116.1286912, 6.0883354],
          [116.1290057, 6.0891194],
          [116.1293556, 6.0892648],
          [116.1292272, 6.0893793],
          [116.129267, 6.0894894],
          [116.1293689, 6.0897449],
          [116.1294797, 6.0897405],
          [116.1298695, 6.0897008],
          [116.1304143, 6.0898418],
          [116.1313844, 6.0902029],
          [116.13204, 6.0904628],
          [116.1322703, 6.0905773],
          [116.1322792, 6.0907051],
          [116.1324386, 6.0907975],
          [116.1323545, 6.0909869],
          [116.1320887, 6.0909077],
          [116.1319115, 6.0908812],
          [116.131814, 6.0910574],
          [116.1299891, 6.0904452],
          [116.129865, 6.0904011],
          [116.129834, 6.0905333],
          [116.129834, 6.0906654],
          [116.129741, 6.0909033],
          [116.129555, 6.0909781],
          [116.1293601, 6.0909297],
          [116.1291607, 6.0909165],
          [116.1290854, 6.0908284],
          [116.1289924, 6.0908152],
          [116.1289393, 6.0909165],
          [116.1288639, 6.0910618],
          [116.1282748, 6.0909605],
          [116.1283856, 6.0907711],
          [116.1287045, 6.0903571],
          [116.1290101, 6.0899959],
          [116.1291652, 6.0898726],
          [116.1291563, 6.0898065],
          [116.1290146, 6.0895555],
          [116.1288994, 6.0895378],
          [116.1287842, 6.0895731],
          [116.1286292, 6.0897316],
          [116.1284564, 6.0900312],
          [116.1282748, 6.0903527],
          [116.1279515, 6.0907931],
          [116.1278762, 6.0908372],
          [116.1277521, 6.090824],
          [116.1276374, 6.0908744],
          [116.1276269, 6.0909604],
          [116.1276901, 6.0910398],
          [116.1277034, 6.0911279],
          [116.1276015, 6.0912909],
          [116.1275882, 6.0913701],
          [116.1276325, 6.0913966],
          [116.1277964, 6.0915111],
          [116.1278673, 6.0915375],
          [116.1279337, 6.0914714],
          [116.1281774, 6.0911103],
          [116.1285716, 6.0911411],
          [116.1287798, 6.0911675],
          [116.12905, 6.0911763],
          [116.1290367, 6.0912909],
          [116.1288152, 6.0914803],
          [116.1287001, 6.091652],
          [116.1286735, 6.0918414],
          [116.1287311, 6.0921586],
          [116.1287798, 6.092348],
          [116.1287798, 6.0925065],
          [116.1286823, 6.0927179],
          [116.1285805, 6.0928281],
          [116.1284077, 6.0929954],
          [116.1281552, 6.093154],
          [116.1278319, 6.0932112],
          [116.1275794, 6.0933478],
          [116.1272427, 6.0933918],
          [116.1269371, 6.0934315],
          [116.1267289, 6.0934579],
          [116.1266182, 6.0934843],
          [116.126627, 6.0936429],
          [116.126596, 6.0938059],
          [116.1265074, 6.0938851],
          [116.1263214, 6.0938587],
          [116.1260512, 6.0937486],
          [116.1257057, 6.0937266],
          [116.1257987, 6.0936693],
          [116.1260157, 6.0936605],
          [116.1262815, 6.0935504],
          [116.1265251, 6.0933918],
          [116.1268352, 6.0930527],
          [116.1270257, 6.092784],
          [116.1275794, 6.0919736],
          [116.1277831, 6.0917225],
          [116.1277876, 6.0916344],
          [116.1277344, 6.0915772],
          [116.1275572, 6.0914759],
          [116.1274465, 6.0914714],
          [116.127349, 6.0915507],
          [116.1272782, 6.091608],
          [116.1271054, 6.0916829],
          [116.1269282, 6.0917489],
          [116.1267688, 6.0917842],
          [116.1265871, 6.0917798],
          [116.1264675, 6.0917401],
          [116.1263081, 6.0916432],
          [116.1261752, 6.0915243],
          [116.1260202, 6.091379],
          [116.1259449, 6.0912865],
          [116.1258917, 6.0912204],
          [116.1257943, 6.091194],
          [116.1256436, 6.0912072],
          [116.1256525, 6.0913041],
          [116.1257145, 6.0928897],
          [116.1257012, 6.0930351],
          [116.1256481, 6.0931672],
          [116.1255595, 6.0932553],
          [116.125462, 6.0933302],
          [116.1237712, 6.0935444],
          [116.1237168, 6.092373],
          [116.1232433, 6.0923818],
          [116.1232191, 6.0917524],
          [116.1230394, 6.0917631],
          [116.1230877, 6.0923845],
          [116.1224107, 6.0924085],
          [116.1224681, 6.0921803],
          [116.1229992, 6.091051],
          [116.1229268, 6.0905976],
          [116.1225915, 6.0904456],
          [116.1222991, 6.0906029],
          [116.1220765, 6.0910697],
          [116.1217466, 6.0919311],
          [116.1213577, 6.0927499],
          [116.1213648, 6.0931147],
          [116.1216608, 6.09331],
          [116.1216527, 6.0935794],
          [116.1213122, 6.0945527],
          [116.1204929, 6.0945502],
          [116.1205655, 6.0960232],
          [116.1215258, 6.095558],
          [116.1215108, 6.0956382],
          [116.1213691, 6.0959112],
          [116.1211786, 6.0961006],
          [116.1209572, 6.0962328],
          [116.1207047, 6.0963209],
          [116.1203459, 6.0963517],
          [116.1200358, 6.0963209],
          [116.1196681, 6.0963209],
          [116.1193049, 6.0963517],
          [116.1189417, 6.0964618],
          [116.1187734, 6.0965895],
          [116.1186006, 6.0967393],
          [116.1184677, 6.0969155],
          [116.1182994, 6.097193],
          [116.1181931, 6.0974572],
          [116.1180824, 6.0977699],
          [116.1179628, 6.0980562],
          [116.1178653, 6.09825],
          [116.1177324, 6.0984526],
          [116.1175464, 6.0986024],
          [116.1173825, 6.0986729],
          [116.1171964, 6.0987169],
          [116.1170104, 6.0987786],
          [116.1166693, 6.098761],
          [116.1163725, 6.0987169],
          [116.1161245, 6.0986773],
          [116.1160315, 6.0986376],
          [116.1159517, 6.0985495],
          [116.1160447, 6.0985055],
          [116.1162485, 6.0984482],
          [116.1163814, 6.0984835],
          [116.1164966, 6.0984615],
          [116.1165807, 6.0983734],
          [116.1165896, 6.0983073],
          [116.1165231, 6.0982765],
          [116.116532, 6.0980827],
          [116.1164523, 6.0980298],
          [116.116532, 6.0978757],
          [116.1162219, 6.0976642],
          [116.1159872, 6.0976334],
          [116.1159207, 6.097814],
          [116.1158366, 6.0980783],
          [116.1157878, 6.0981619],
          [116.1157214, 6.0982104],
          [116.1156239, 6.0981708],
          [116.1155265, 6.0981796],
          [116.1154733, 6.0981267],
          [116.1154157, 6.0981179],
          [116.1154202, 6.0980342],
          [116.1154512, 6.0979726],
          [116.1154955, 6.0976422],
          [116.1153582, 6.0976554],
          [116.1152386, 6.0976995],
          [116.115119, 6.0977788],
          [116.1149816, 6.0978008],
          [116.1148089, 6.0977876],
          [116.1147557, 6.0979505],
          [116.1146051, 6.0979461],
          [116.1145918, 6.0982324],
          [116.1145608, 6.0983557],
          [116.1145165, 6.0984659],
          [116.1145033, 6.0986729],
          [116.1143349, 6.0986861],
          [116.1141755, 6.0986597],
          [116.1140337, 6.0986244],
          [116.1138875, 6.0985672],
          [116.1137236, 6.0984835],
          [116.1136085, 6.0983646],
          [116.11348, 6.0982544],
          [116.1134091, 6.0981047],
          [116.1133073, 6.0979329],
          [116.1132541, 6.0976158],
          [116.11317, 6.0970476],
          [116.1131257, 6.0967217],
          [116.1131345, 6.0966336],
          [116.1130061, 6.0960786],
          [116.1135509, 6.0960874],
          [116.1136351, 6.0960037],
          [116.1142552, 6.0959949],
          [116.1148709, 6.0953915],
          [116.1146627, 6.0951404],
          [116.1147203, 6.0950127],
          [116.1147292, 6.0949202],
          [116.114645, 6.0949158],
          [116.1145697, 6.0949643],
          [116.1144944, 6.0949775],
          [116.1144147, 6.0948982],
          [116.1143615, 6.0948453],
          [116.1143659, 6.094559],
          [116.1143969, 6.094101],
          [116.1151943, 6.0930439],
          [116.115181, 6.0925021],
          [116.1151411, 6.0924537],
          [116.1142375, 6.0924537],
          [116.1136306, 6.0919956],
          [116.1140514, 6.0905245],
          [116.1136085, 6.0897405],
          [116.1126428, 6.0897625],
          [116.1124834, 6.0894277],
          [116.1124479, 6.0893969],
          [116.1127624, 6.0888772],
          [116.1128599, 6.0888111],
          [116.1129109, 6.0887322],
          [116.1129497, 6.0886723],
          [116.1129795, 6.0886261],
          [116.1129706, 6.088516],
          [116.113387, 6.0878289],
          [116.1135376, 6.0877011],
          [116.1135957, 6.0876125],
          [116.1136452, 6.087537],
          [116.1136705, 6.0874985],
          [116.1136351, 6.087384],
          [116.1138388, 6.0870404],
          [116.1138964, 6.0869612],
          [116.1139141, 6.0868951],
          [116.1138432, 6.0868422],
          [116.1137788, 6.0868071],
          [116.1137059, 6.0867673],
          [116.1136528, 6.0867982],
          [116.1135598, 6.0869479],
          [116.1134534, 6.087036],
          [116.1132984, 6.0870581],
          [116.1130858, 6.0870845],
          [116.112882, 6.0870492],
          [116.1128599, 6.0870557],
          [116.1128067, 6.0870713],
          [116.1127713, 6.0871197],
          [116.1126606, 6.0871461],
          [116.1125808, 6.0872034],
          [116.1120714, 6.0880887],
          [116.1119784, 6.0881988],
          [116.1118499, 6.0882605],
          [116.1117303, 6.0882869],
          [116.111624, 6.0882561],
          [116.1105166, 6.087591],
          [116.1104369, 6.0875822],
          [116.1093295, 6.0875866],
          [116.1092409, 6.0875778],
          [116.1086031, 6.0872783],
          [116.1089043, 6.0867762],
          [116.1092719, 6.0860934],
          [116.1094668, 6.0858292],
          [116.1096174, 6.0855605],
          [116.110211, 6.0845562],
          [116.1101977, 6.0841378],
          [116.1102154, 6.0840805],
          [116.1101888, 6.0840365],
          [116.1101582, 6.0840202],
          [116.1099099, 6.0838883],
          [116.1098655, 6.0838647],
          [116.1097326, 6.0838779],
          [116.1096617, 6.0837986],
          [116.1095466, 6.0837766],
          [116.1093871, 6.0837722],
          [116.1085455, 6.0833361],
          [116.1080361, 6.0830763],
          [116.1078766, 6.0829838],
          [116.1072565, 6.0825125],
          [116.1070438, 6.0823451],
          [116.1069818, 6.0821998],
          [116.1069995, 6.082094],
          [116.1070483, 6.0819839],
          [116.1071324, 6.0818518],
          [116.1071797, 6.0817499],
          [116.1071477, 6.0816623],
          [116.1070342, 6.0815935],
          [116.1068977, 6.0816536],
          [116.1068357, 6.0817417],
          [116.1067825, 6.0818562],
          [116.1067116, 6.0819399],
          [116.106654, 6.0819487],
          [116.1065787, 6.081865],
          [116.1062203, 6.081454],
          [116.1061003, 6.081288],
          [116.1059763, 6.0811294],
          [116.1059054, 6.0810325],
          [116.1058036, 6.0808211],
          [116.1057371, 6.0807815],
          [116.1058744, 6.0804555],
          [116.1059586, 6.0802353],
          [116.1060339, 6.0800062],
          [116.1059365, 6.0798256],
          [116.1058257, 6.0797508],
          [116.1057017, 6.0796406],
          [116.1056441, 6.0795129],
          [116.1056131, 6.0794292],
          [116.1055555, 6.0793632],
          [116.104674, 6.0785439],
          [116.1045589, 6.0784602],
          [116.1044658, 6.0784117],
          [116.1042444, 6.0783501],
          [116.1022466, 6.0777643],
          [116.1021093, 6.0777643],
          [116.1019587, 6.0777951],
          [116.1018435, 6.07787],
          [116.1017505, 6.0780285],
          [116.1016973, 6.0781607],
          [116.1017018, 6.0784954],
          [116.1016973, 6.0788874],
          [116.1017106, 6.0793147],
          [116.1017904, 6.0798785],
          [116.1018612, 6.0803454],
          [116.101879, 6.0805172],
          [116.1021359, 6.0806713],
          [116.1024681, 6.0809224],
          [116.1025523, 6.0810149],
          [116.1024681, 6.0811559],
          [116.1025567, 6.081288],
          [116.1026586, 6.0814333],
          [116.1026984, 6.0815831],
          [116.102787, 6.0818121],
          [116.102818, 6.0820412],
          [116.1028047, 6.0825389],
          [116.1027914, 6.0827195],
          [116.1028446, 6.082768],
          [116.1028003, 6.0829177],
          [116.1027472, 6.083041],
          [116.1023928, 6.0838163],
          [116.1023795, 6.083922],
          [116.1024238, 6.0839968],
          [116.1027073, 6.0845518],
          [116.1028933, 6.0849218],
          [116.1030306, 6.0852345],
          [116.1032034, 6.0856133],
          [116.1032787, 6.0857587],
          [116.1032787, 6.0858732],
          [116.103199, 6.0860009],
          [116.1027693, 6.0864943],
          [116.1024637, 6.0868687],
          [116.1023308, 6.086992],
          [116.1022688, 6.0870713],
          [116.1021447, 6.0871197],
          [116.1015556, 6.0873576],
          [116.101498, 6.087362],
          [116.1013696, 6.0873003],
          [116.1001824, 6.0868643],
          [116.1001027, 6.0868554],
          [116.100023, 6.0868775],
          [116.0998148, 6.0869612],
          [116.0997528, 6.0869788],
          [116.099673, 6.0869567],
          [116.0980961, 6.0863577],
          [116.0979986, 6.0863797],
          [116.0979233, 6.0864987],
          [116.0978259, 6.0867365],
          [116.0978569, 6.0868246],
          [116.0983176, 6.0869788],
          [116.0988757, 6.0871682],
          [116.0989643, 6.0872034],
          [116.0995844, 6.0874324],
          [116.0993231, 6.0880799],
          [116.0992478, 6.0883046],
          [116.0992744, 6.088723],
          [116.0992212, 6.0890049],
          [116.0991149, 6.0892736],
          [116.0990352, 6.0894674],
          [116.0989111, 6.0895555],
          [116.098827, 6.0896259],
          [116.0986808, 6.0896435],
          [116.0985789, 6.08967],
          [116.0984327, 6.0896788],
          [116.098291, 6.0896744],
          [116.0981315, 6.0897052],
          [116.0979278, 6.0897405],
          [116.0977152, 6.0898594],
          [116.0975867, 6.0899519],
          [116.0974494, 6.0900444],
          [116.0973431, 6.0901589],
          [116.0972368, 6.0902866],
          [116.0971083, 6.0904099],
          [116.0970551, 6.0904804],
          [116.0970153, 6.0906037],
          [116.0969311, 6.0907579],
          [116.0968381, 6.09089],
          [116.0967451, 6.0909781],
          [116.0966476, 6.0910574],
          [116.0965457, 6.0910398],
          [116.0964926, 6.0910706],
          [116.0964882, 6.091194],
          [116.0964793, 6.0913305],
          [116.0966122, 6.0913745],
          [116.0966831, 6.0914803],
          [116.0967229, 6.0915728],
          [116.0967805, 6.0917269],
          [116.0968558, 6.0919824],
          [116.0968514, 6.0922202],
          [116.0968824, 6.0923788],
          [116.096909, 6.0924977],
          [116.09694, 6.0927576],
          [116.0969666, 6.0929866],
          [116.0970064, 6.0932597],
          [116.0970197, 6.0935592],
          [116.0969621, 6.0938103],
          [116.0969045, 6.0940349],
          [116.0968381, 6.0941847],
          [116.0968071, 6.0942331],
          [116.0968204, 6.0943212],
          [116.0968735, 6.0942992],
          [116.09694, 6.0943124],
          [116.0969621, 6.0943741],
          [116.0969843, 6.094537],
          [116.0969488, 6.0947661],
          [116.0968514, 6.0949422],
          [116.0967008, 6.0951184],
          [116.096528, 6.0952858],
          [116.096311, 6.0954444],
          [116.0961249, 6.0955501],
          [116.0960673, 6.0956602],
          [116.0960098, 6.0957262],
          [116.0960186, 6.0957835],
          [116.0959655, 6.0958055],
          [116.0959212, 6.0957703],
          [116.0958104, 6.0957659],
          [116.0956864, 6.0957967],
          [116.0955004, 6.0958804],
          [116.0953542, 6.0959289],
          [116.0952302, 6.0959068],
          [116.0951283, 6.0958275],
          [116.0950043, 6.0957395],
          [116.0948226, 6.095713],
          [116.094672, 6.0956998],
          [116.094517, 6.0957086],
          [116.0943664, 6.0957835],
          [116.0942468, 6.0958452],
          [116.0941759, 6.0959377],
          [116.0941449, 6.0960434],
          [116.0941006, 6.0961182],
          [116.0939766, 6.0962328],
          [116.0937197, 6.0963781],
          [116.0936089, 6.0964001],
          [116.0934849, 6.0962636],
          [116.0934273, 6.0962063],
          [116.093352, 6.0962548],
          [116.0932811, 6.0961711],
          [116.0931837, 6.0961975],
          [116.0930907, 6.0961359],
          [116.0929534, 6.0961931],
          [116.0929002, 6.0960786],
          [116.0928205, 6.0960698],
          [116.0927939, 6.0959905],
          [116.092692, 6.0960037],
          [116.09263, 6.0959421],
          [116.092599, 6.0958716],
          [116.0925591, 6.0958187],
          [116.0924528, 6.0958143],
          [116.0923819, 6.0958011],
          [116.0922845, 6.0957879],
          [116.0921383, 6.0957571],
          [116.0919213, 6.0956602],
          [116.0918194, 6.0955897],
          [116.0916688, 6.095484],
          [116.0915447, 6.0953342],
          [116.0914119, 6.0952814],
          [116.0912214, 6.0952946],
          [116.0910974, 6.0953254],
          [116.0909822, 6.0953651],
          [116.0908936, 6.0954223],
          [116.090836, 6.0955236],
          [116.0908227, 6.0956382],
          [116.0907696, 6.0957703],
          [116.0906943, 6.0958936],
          [116.0906588, 6.0960346],
          [116.0906677, 6.0961843],
          [116.0906943, 6.0963649],
          [116.0906766, 6.0964882],
          [116.0906323, 6.0966116],
          [116.0905437, 6.096704],
          [116.0904684, 6.0967129],
          [116.0904551, 6.0968186],
          [116.0903931, 6.0969683],
          [116.0903222, 6.097052],
          [116.0902159, 6.0971974],
          [116.0901361, 6.0973559],
          [116.0900874, 6.0974969],
          [116.0900298, 6.0977303],
          [116.0899678, 6.0979373],
          [116.0899014, 6.0981311],
          [116.0898084, 6.0983029],
          [116.0897065, 6.098391],
          [116.0895381, 6.0984438],
          [116.0894274, 6.0984394],
          [116.0893698, 6.0983866],
          [116.0893078, 6.0984306],
          [116.0892547, 6.0985099],
          [116.0891483, 6.0985804],
          [116.0890642, 6.098598],
          [116.0890465, 6.0987345],
          [116.0890509, 6.0988358],
          [116.0889845, 6.0989371],
          [116.0891173, 6.0990517],
          [116.0891572, 6.0991926],
          [116.0892104, 6.0992895],
          [116.0892945, 6.0994701],
          [116.0893388, 6.099589],
          [116.0893831, 6.0997608],
          [116.0893344, 6.099959],
          [116.0892724, 6.1002012],
          [116.0892192, 6.1003906],
          [116.0891971, 6.10058],
          [116.0890996, 6.1006285],
          [116.0890243, 6.1007562],
          [116.0888781, 6.1008443],
          [116.0887187, 6.1008751],
          [116.0885946, 6.1008839],
          [116.0884928, 6.1008399],
          [116.0884042, 6.1009324],
          [116.0882801, 6.1011702],
          [116.0881473, 6.1013816],
          [116.0880587, 6.1013464],
          [116.0879834, 6.1013288],
          [116.0879834, 6.1014653],
          [116.0879302, 6.101549],
          [116.0879435, 6.1016503],
          [116.0878859, 6.1017604],
          [116.0878682, 6.1018926],
          [116.0879169, 6.1020027],
          [116.0878948, 6.1021612],
          [116.0879302, 6.1023198],
          [116.0879612, 6.1024695],
          [116.0880099, 6.1028087],
          [116.0880365, 6.1030025],
          [116.0881163, 6.1032668],
          [116.0881561, 6.103531],
          [116.088165, 6.1037909],
          [116.0882359, 6.1040419],
          [116.0882447, 6.1042578],
          [116.0883555, 6.1046145],
          [116.0884529, 6.1047731],
          [116.0884972, 6.104936],
          [116.0884529, 6.1051563],
          [116.0884219, 6.1054734],
          [116.0883156, 6.1056099],
          [116.0881827, 6.1057773],
          [116.0879568, 6.1062354],
          [116.0877796, 6.1066053],
          [116.0876113, 6.1069225],
          [116.087536, 6.1070326],
          [116.0874208, 6.1071251],
          [116.0873499, 6.1071515],
          [116.0872348, 6.1071427],
          [116.0871152, 6.107103],
          [116.0869911, 6.1070986],
          [116.0868893, 6.1071691],
          [116.0868981, 6.1072924],
          [116.0868007, 6.1073585],
          [116.0866678, 6.1073629],
          [116.0865083, 6.1073541],
          [116.0863666, 6.1073277],
          [116.0862293, 6.1072616],
          [116.0861052, 6.1071471],
          [116.0859989, 6.1071207],
          [116.0858749, 6.1071427],
          [116.0857907, 6.1070986],
          [116.0856623, 6.1070414],
          [116.0855161, 6.1070326],
          [116.0853921, 6.1069973],
          [116.0852237, 6.1070017],
          [116.0850864, 6.1070149],
          [116.0850023, 6.1070149],
          [116.0850111, 6.1071559],
          [116.0850111, 6.1073144],
          [116.084989, 6.1074994],
          [116.0850466, 6.1076668],
          [116.0851307, 6.1080148],
          [116.0853212, 6.1082746],
          [116.0854851, 6.1086358],
          [116.0856268, 6.1088472],
          [116.0857376, 6.1090146],
          [116.0858882, 6.1091511],
          [116.0861805, 6.1094021],
          [116.0864507, 6.1095827],
          [116.0866456, 6.1097237],
          [116.0867918, 6.1097985],
          [116.0869247, 6.1097985],
          [116.0870842, 6.1098382],
          [116.0872303, 6.109869],
          [116.0875714, 6.11001],
          [116.0877929, 6.1100892],
          [116.0879789, 6.1101465],
          [116.0881827, 6.1101685],
          [116.0883643, 6.1101641],
          [116.0884485, 6.1101113],
          [116.0886124, 6.1101245],
          [116.0887408, 6.1101905],
          [116.0888781, 6.1102082],
          [116.0890553, 6.1102214],
          [116.0892547, 6.1102346],
          [116.0894806, 6.1103227],
          [116.0896755, 6.1103403],
          [116.0898748, 6.1103359],
          [116.090083, 6.1103579],
          [116.0902602, 6.11049],
          [116.0905304, 6.110653],
          [116.0907474, 6.1108908],
          [116.0909423, 6.1110846],
          [116.0911461, 6.1113885],
          [116.0912125, 6.1116132],
          [116.0913011, 6.1118246],
          [116.0913897, 6.1119567],
          [116.0914207, 6.1121109],
          [116.0914694, 6.1124852],
          [116.0914473, 6.1127759],
          [116.0914074, 6.1129477],
          [116.091372, 6.1131283],
          [116.0913454, 6.1132736],
          [116.0914517, 6.1132824],
          [116.0914694, 6.1135511],
          [116.0915005, 6.1139078],
          [116.091589, 6.1142646],
          [116.0916289, 6.1145641],
          [116.0915492, 6.1147887],
          [116.0916245, 6.1151014],
          [116.0917751, 6.1153789],
          [116.0919124, 6.115489],
          [116.0920276, 6.1156388],
          [116.0921162, 6.1158326],
          [116.0921427, 6.1159427],
          [116.0922225, 6.116066],
          [116.0923199, 6.1162906],
          [116.0923066, 6.1163787],
          [116.0924395, 6.116524],
          [116.0925503, 6.1167399],
          [116.0927142, 6.1169248],
          [116.092816, 6.1171495],
          [116.0930065, 6.1173873],
          [116.0931172, 6.1174886],
          [116.093228, 6.1176604],
          [116.0933299, 6.1178762],
          [116.0933476, 6.118092],
          [116.0933564, 6.1182241],
          [116.0933476, 6.118321],
          [116.0934273, 6.1184576],
          [116.0936267, 6.1187042],
          [116.0937684, 6.1190433],
          [116.0939146, 6.1191843],
          [116.0939544, 6.1193649],
          [116.0940165, 6.119519],
          [116.0940873, 6.1197348],
          [116.0941316, 6.1198978],
          [116.0943885, 6.1200651],
          [116.0946543, 6.1201444],
          [116.0948979, 6.1202193],
          [116.0949998, 6.1202677],
          [116.0951947, 6.1202193],
          [116.0952656, 6.1201312],
          [116.0953586, 6.1200123],
          [116.0954206, 6.1198449],
          [116.0954649, 6.119704],
          [116.0955712, 6.1195366],
          [116.0956022, 6.1193957],
          [116.0956864, 6.1193032],
          [116.0957218, 6.1191755],
          [116.0957661, 6.1190521],
          [116.095899, 6.1189949],
          [116.0960142, 6.11892],
          [116.0961294, 6.1188892],
          [116.0962224, 6.1187571],
          [116.0963996, 6.1185985],
          [116.0964793, 6.1184664],
          [116.0965325, 6.1183122],
          [116.0967406, 6.1180656],
          [116.0968337, 6.1179246],
          [116.0970064, 6.1178013],
          [116.0971127, 6.1177],
          [116.0971216, 6.117537],
          [116.097064, 6.1174446],
          [116.0970463, 6.1173344],
          [116.0969798, 6.1172067],
          [116.0968735, 6.1170966],
          [116.0967849, 6.1169909],
          [116.0967185, 6.116894],
          [116.0967229, 6.1166914],
          [116.0968159, 6.116502],
          [116.096971, 6.1162334],
          [116.097157, 6.116088],
          [116.0972545, 6.1159559],
          [116.0975291, 6.1157665],
          [116.0977949, 6.1155947],
          [116.0982423, 6.1153437],
          [116.0986144, 6.1151279],
          [116.0990617, 6.1149825],
          [116.0992123, 6.1149032],
          [116.0993718, 6.114912],
          [116.0998458, 6.1149341],
          [116.1005324, 6.1149076],
          [116.1011436, 6.1148812],
          [116.1019321, 6.1148152],
          [116.1025035, 6.1148504],
          [116.1031325, 6.1148636],
          [116.1037305, 6.114912],
          [116.1042576, 6.1149781],
          [116.1045987, 6.1150442],
          [116.1050594, 6.1151367],
          [116.1059054, 6.1154097],
          [116.1068357, 6.1157797],
          [116.1073982, 6.1159471],
          [116.1080228, 6.1162378],
          [116.109201, 6.1168412],
          [116.1102331, 6.1174005],
          [116.1109064, 6.1178145],
          [116.1117924, 6.1183739],
          [116.1128333, 6.1189641],
          [116.1135996, 6.1195675],
          [116.1142773, 6.1201885],
          [116.114831, 6.1206245],
          [116.115429, 6.1212015],
          [116.1161909, 6.1218709],
          [116.1165763, 6.1222189],
          [116.1172983, 6.1229852],
          [116.1179007, 6.1235666],
          [116.1184146, 6.1242316],
          [116.1190126, 6.1249539],
          [116.1195796, 6.1255265],
          [116.1205541, 6.1265175],
          [116.1210457, 6.1271385],
          [116.1215197, 6.1276758],
          [116.1217545, 6.1279885],
          [116.1224056, 6.1286315],
          [116.1228707, 6.1291952],
          [116.1230833, 6.1294507],
          [116.1236858, 6.1301862],
          [116.1240667, 6.130631],
          [116.1247533, 6.1313621],
          [116.125812, 6.132221],
          [116.1264454, 6.1328332],
          [116.1268972, 6.1333705],
          [116.1274376, 6.1339254],
          [116.1279825, 6.1345244],
          [116.1285937, 6.1350529],
          [116.129174, 6.1358324],
          [116.1299359, 6.136678],
          [116.1304985, 6.1372858],
          [116.1310389, 6.1378231],
          [116.1316723, 6.1386027],
          [116.1322659, 6.1391047],
          [116.1327354, 6.1395099],
          [116.1332315, 6.1399327],
          [116.1339491, 6.1403071],
          [116.1347038, 6.1404207],
          [116.1352768, 6.1402756],
          [116.1359372, 6.1396516],
          [116.1365163, 6.1388588],
          [116.1376332, 6.1376952],
          [116.1383627, 6.1366712],
          [116.138013, 6.1358348],
          [116.137247, 6.1353484],
          [116.1357878, 6.1335563],
          [116.1342858, 6.1330869],
          [116.1327838, 6.1333429],
          [116.133685, 6.1314655],
          [116.1326121, 6.129588],
          [116.1306809, 6.1287773],
          [116.1284922, 6.1284786],
          [116.127076, 6.1267291],
          [116.1250161, 6.1257903],
          [116.1227845, 6.1248089],
          [116.1226922, 6.1241006],
          [116.1234218, 6.1239299],
          [116.1249302, 6.1246809],
          [116.12634, 6.1247407],
          [116.1268185, 6.1239982],
          [116.1255311, 6.1230594],
          [116.1222695, 6.1200298],
          [116.1206816, 6.1181523],
          [116.1187504, 6.1177256],
          [116.1171626, 6.1174696],
          [116.1175853, 6.1165052],
          [116.1184436, 6.1158651],
          [116.120246, 6.1157798],
          [116.1213189, 6.1160785],
          [116.1211966, 6.114952],
          [116.1217974, 6.1139705],
          [116.1228639, 6.1136462],
          [116.1242007, 6.1166162],
          [116.1253594, 6.1190484],
          [116.1284064, 6.1200725],
          [116.1315328, 6.1188948],
          [116.1339854, 6.1153787],
          [116.1351377, 6.111726],
          [116.1355733, 6.108722],
          [116.1363393, 6.1060507],
          [116.1375044, 6.1045401],
          [116.1366826, 6.1011861],
          [116.1355733, 6.0990354],
          [116.1363886, 6.0983953],
          [116.1359101, 6.0967055],
          [116.1363393, 6.0955533],
          [116.1378907, 6.0967738],
          [116.1393498, 6.098438],
          [116.1408883, 6.0981137],
          [116.1428259, 6.098694],
          [116.1442851, 6.0995475],
          [116.1456519, 6.0992232],
          [116.1471175, 6.0996328],
          [116.1483191, 6.1004436],
          [116.1520034, 6.0976016],
          [116.1542779, 6.098071],
          [116.155486, 6.0998035],
          [116.1567734, 6.1001022],
          [116.1588763, 6.1011263],
          [116.1601208, 6.1025772],
          [116.1605071, 6.1038574],
          [116.1583184, 6.106119],
          [116.1575459, 6.1073565],
          [116.155958, 6.1082099],
          [116.155486, 6.1098741],
          [116.1538981, 6.1107275],
          [116.1539775, 6.1087817],
          [116.151924, 6.1085939],
          [116.1505936, 6.1099168],
          [116.1498641, 6.1112823],
          [116.1469458, 6.1108555],
          [116.1454803, 6.1086964],
          [116.1485702, 6.1069895],
          [116.1492568, 6.1043012],
          [116.1494714, 6.102893],
          [116.1454009, 6.1018518],
          [116.1409377, 6.0997608],
          [116.1389206, 6.1013824],
          [116.1392211, 6.1038147],
          [116.139264, 6.1066737],
          [116.1383627, 6.1103435],
          [116.137719, 6.1144399],
          [116.1365174, 6.118835],
          [116.1351012, 6.1203712],
          [116.1328267, 6.1207979],
          [116.1316647, 6.1214696],
          [116.1309813, 6.1218647],
          [116.1317967, 6.1258757],
          [116.1331271, 6.1269851],
          [116.1342364, 6.1281969],
          [116.1348866, 6.1298867],
          [116.1360453, 6.1286919],
          [116.136732, 6.1272838],
          [116.1396073, 6.1271558],
          [116.1408518, 6.1284786],
          [116.1423539, 6.1306547],
          [116.1435834, 6.1328416],
          [116.1446777, 6.1346764],
          [116.1464308, 6.1362871],
          [116.14789, 6.1373965],
          [116.1492632, 6.1383353],
          [116.1505507, 6.1393167],
          [116.1518746, 6.1397604],
          [116.1534196, 6.1402298],
          [116.1550568, 6.1405114],
          [116.155958, 6.1392313],
          [116.1572026, 6.1379939],
          [116.1587475, 6.1369272],
          [116.1603783, 6.1353911],
          [116.1608869, 6.1344694],
          [116.1599921, 6.133599],
          [116.160801, 6.1328906],
          [116.1610156, 6.1314825],
          [116.1599492, 6.1309961],
          [116.1593913, 6.1308254],
          [116.1597711, 6.1299891],
          [116.1606294, 6.1295624],
          [116.1609727, 6.1284103],
          [116.1605929, 6.1269424],
          [116.1610221, 6.1258757],
          [116.1627816, 6.1268571],
          [116.1634253, 6.1271131],
          [116.1649273, 6.1257477],
          [116.1660367, 6.124826],
          [116.1666375, 6.1248687],
          [116.1670731, 6.1268144],
          [116.1680173, 6.1287346],
          [116.1688756, 6.1300574],
          [116.1700708, 6.1303305],
          [116.1714934, 6.1297587],
          [116.1723088, 6.1312095],
          [116.173159956932068, 6.132140387815602],
          [116.1731671, 6.1321482],
          [116.1739825, 6.1328309],
          [116.1743687, 6.133983],
          [116.1745404, 6.1351777],
          [116.17424, 6.1359458],
          [116.173159956932068, 6.136267925466473],
          [116.1729525, 6.1363298],
          [116.1724804, 6.1371405],
          [116.173159956932068, 6.137879536101397],
          [116.1738537, 6.138634],
          [116.1754416, 6.139914],
          [116.1765574, 6.1409381],
          [116.1776303, 6.1429862],
          [116.1784457, 6.1436689],
          [116.1796044, 6.1434129],
          [116.1805914, 6.1427302],
          [116.1814927, 6.1419195],
          [116.1820012, 6.1415952],
          [116.1826514, 6.1417061],
          [116.182866, 6.1412794],
          [116.1832093, 6.14017],
          [116.1830741, 6.137883],
          [116.1824733, 6.1358775],
          [116.1835955, 6.1357324],
          [116.1844967, 6.1365858],
          [116.1848401, 6.139018],
          [116.1859988, 6.1395727],
          [116.1868507, 6.1382243],
          [116.1881445, 6.1371405],
          [116.189947, 6.1374819],
          [116.189947, 6.1391887],
          [116.1886166, 6.1411941],
          [116.1872433, 6.1408101],
          [116.1858271, 6.1417488],
          [116.1857413, 6.1426022],
          [116.1867713, 6.1435409],
          [116.1875008, 6.1452477],
          [116.1881445, 6.1466557],
          [116.189432, 6.1470397],
          [116.1898118, 6.1458194],
          [116.1908847, 6.1451367],
          [116.1921786, 6.1449916],
          [116.1918717, 6.144326],
          [116.1915778, 6.1431569],
          [116.1928159, 6.1431313],
          [116.1935519, 6.1426875],
          [116.1942321, 6.1415952],
          [116.195011, 6.1404261],
          [116.1953908, 6.1402298],
          [116.196513, 6.1418341],
          [116.1977082, 6.1423632],
          [116.1988305, 6.1426022],
          [116.2003325, 6.1426875],
          [116.2013625, 6.1434129],
          [116.2021779, 6.1440103],
          [116.2005471, 6.1438822],
          [116.1993025, 6.1445223],
          [116.199045, 6.1458023],
          [116.2000257, 6.1463741],
          [116.2005471, 6.1472957],
          [116.1993025, 6.1485758],
          [116.1973284, 6.1484905],
          [116.2007617, 6.1494292],
          [116.202092, 6.1511359],
          [116.2014912, 6.1548907],
          [116.2007617, 6.1562561],
          [116.2022573, 6.1568705],
          [116.2033795, 6.1584748],
          [116.2038945, 6.1612055],
          [116.2038945, 6.161242470145226],
          [116.2038945, 6.1635522],
          [116.2044953, 6.1656856],
          [116.2056476, 6.1660867],
          [116.2072784, 6.1660013],
          [116.2078856, 6.1670936],
          [116.206684, 6.1691416],
          [116.2040661, 6.1704216],
          [116.2026499, 6.1703363],
          [116.1996459, 6.1682883],
          [116.1971139, 6.1695683],
          [116.1962985, 6.1676483],
          [116.196292, 6.165916],
          [116.1941956, 6.1648749],
          [116.1921357, 6.1634242],
          [116.1906766, 6.1622295],
          [116.190832536179286, 6.161242470145226],
          [116.1909705, 6.1603692],
          [116.1920005, 6.1581079],
          [116.1921786, 6.1568108],
          [116.1926013, 6.1551211],
          [116.1907195, 6.1546774],
          [116.1894749, 6.1533973],
          [116.1908418, 6.152561],
          [116.1909705, 6.1508116],
          [116.1919147, 6.1500009],
          [116.1920434, 6.1486355],
          [116.1910199, 6.1479358],
          [116.1889599, 6.1480211],
          [116.1873292, 6.1491732],
          [116.185649, 6.1465448],
          [116.185134, 6.1446674],
          [116.1837672, 6.1435836],
          [116.1823939, 6.1439676],
          [116.1808489, 6.1449063],
          [116.1805056, 6.1460157],
          [116.1789178, 6.1456743],
          [116.1771582, 6.1468691],
          [116.1775809, 6.1458621],
          [116.1770724, 6.1447356],
          [116.1757849, 6.1438396],
          [116.1749202, 6.1414672],
          [116.1734181, 6.1402298],
          [116.171708, 6.1403407],
          [116.1695193, 6.1410661],
          [116.166129, 6.1414075],
          [116.1652213, 6.1409125],
          [116.1639768, 6.1408272],
          [116.1626099, 6.1414075],
          [116.1609362, 6.1413221],
          [116.1589192, 6.1418341],
          [116.1571168, 6.1421328],
          [116.1546706, 6.1428582],
          [116.1524819, 6.1437116],
          [116.1505936, 6.1438396],
          [116.150158, 6.1429606],
          [116.1494285, 6.1419792],
          [116.1481474, 6.1417915],
          [116.14583, 6.1417488],
          [116.1447936, 6.1410832],
          [116.1433774, 6.1401871],
          [116.1417037, 6.1395897],
          [116.1404162, 6.1392057],
          [116.1387601, 6.1396535],
          [116.1381053, 6.1403407],
          [116.1377169, 6.1413477],
          [116.1376739, 6.1424915],
          [116.1378689, 6.1436102],
          [116.1384979, 6.1444382],
          [116.1395167, 6.1456449],
          [116.1401899, 6.1462262],
          [116.1405089, 6.1467635],
          [116.140881, 6.1477589],
          [116.1412973, 6.1480936],
          [116.1426262, 6.1488511],
          [116.1435299, 6.1501371],
          [116.1445487, 6.1518282],
          [116.1453814, 6.1536603],
          [116.1455143, 6.1546909],
          [116.1455143, 6.1557302],
          [116.1451777, 6.156611],
          [116.144779, 6.1569458],
          [116.1444158, 6.1569634],
          [116.1440968, 6.1569634],
          [116.1436539, 6.1569193],
          [116.1435299, 6.1569193],
          [116.1431932, 6.1574742],
          [116.1430426, 6.157624],
          [116.1427857, 6.1576064],
          [116.1425199, 6.1575623],
          [116.142325, 6.1575183],
          [116.142201, 6.1578001],
          [116.1419972, 6.1578266],
          [116.1417935, 6.1578442],
          [116.1416429, 6.1579323],
          [116.1415897, 6.1581613],
          [116.1416783, 6.1583198],
          [116.1418643, 6.158355],
          [116.1420238, 6.1585664],
          [116.1422896, 6.1587866],
          [116.1425819, 6.1590068],
          [116.1428034, 6.1591566],
          [116.1430692, 6.159456],
          [116.1442031, 6.1600902],
          [116.1452131, 6.1606363],
          [116.1458421, 6.1610239],
          [116.146239289190646, 6.161242470145226],
          [116.1463382, 6.1612969],
          [116.146390751258522, 6.161242470145226],
          [116.1467634, 6.1608565],
          [116.1472861, 6.1602047],
          [116.1477468, 6.1596498],
          [116.147923, 6.1597398],
          [116.1480746, 6.1598172],
          [116.1479417, 6.1602928],
          [116.1475785, 6.1608741],
          [116.1473481, 6.1611736],
          [116.147364998074963, 6.161242470145226],
          [116.1474367, 6.1615347],
          [116.1478354, 6.1619399],
          [116.1484821, 6.1623098],
          [116.150502, 6.1639833],
          [116.1520878, 6.1649258],
          [116.1533281, 6.1658947],
          [116.1550113, 6.167163],
          [116.1560744, 6.1681847],
          [116.156916, 6.1689422],
          [116.1582715, 6.1701577],
          [116.1592194, 6.1712498],
          [116.1611064, 6.1730731],
          [116.1623821, 6.1744559],
          [116.1634098, 6.175733],
          [116.1648538, 6.1776002],
          [116.1657486, 6.178895],
          [116.1669623, 6.180771],
          [116.1680963, 6.1827703],
          [116.1690354, 6.1846728],
          [116.1700276, 6.1865224],
          [116.1705857, 6.1879404],
          [116.1713476, 6.1898692],
          [116.1720563, 6.1917893],
          [116.1728094, 6.1937886],
          [116.173159956932068, 6.194623850673863],
          [116.173837, 6.196237],
          [116.1748027, 6.1981394],
          [116.1758924, 6.2005086],
          [116.176734, 6.202455],
          [116.1777794, 6.2048066],
          [116.1786121, 6.206524],
          [116.179188, 6.2082238],
          [116.1802776, 6.2104256],
          [116.1809332, 6.2122046],
          [116.1815799, 6.2137635],
          [116.1822709, 6.2152959],
          [116.1831214, 6.2171806],
          [116.1837504, 6.2184224],
          [116.1841934, 6.2193031],
          [116.185097, 6.2208708],
          [116.1853982, 6.2214432],
          [116.1859298, 6.2222006],
          [116.1862576, 6.2225705],
          [116.1863905, 6.2227907],
          [116.1865056, 6.2227114],
          [116.1867537, 6.2222623],
          [116.1870638, 6.2219364],
          [116.1876448, 6.2221597],
          [116.1881623, 6.2223591],
          [116.1879585, 6.2228083],
          [116.1877813, 6.2233808],
          [116.187675, 6.2241734],
          [116.1877548, 6.225195],
          [116.1880914, 6.2267714],
          [116.1887204, 6.2275024],
          [116.1892431, 6.2277578],
          [116.1901113, 6.2281629],
          [116.1913782, 6.2293518],
          [116.1923704, 6.2307433],
          [116.1929462, 6.2314831],
          [116.1935487, 6.2324166],
          [116.1941688, 6.2334558],
          [116.1954179, 6.2348561],
          [116.1962153, 6.2355342],
          [116.1971898, 6.2362828],
          [116.1981023, 6.2368112],
          [116.1987135, 6.2371018],
          [116.1992451, 6.237137],
          [116.199502, 6.2369609],
          [116.1990591, 6.2367319],
          [116.1982972, 6.235816],
          [116.1986515, 6.2354197],
          [116.1992097, 6.2353581],
          [116.2002462, 6.2358072],
          [116.2010346, 6.2359041],
          [116.2019471, 6.2356839],
          [116.2029748, 6.2356135],
          [116.204348, 6.2356135],
          [116.2050744, 6.235816],
          [116.2061907, 6.236045],
          [116.2077499, 6.2368552],
          [116.2084763, 6.2375421],
          [116.2091851, 6.237868],
          [116.2099558, 6.2380882],
          [116.2111695, 6.2383612],
          [116.2124541, 6.2388191],
          [116.2132514, 6.2388103],
          [116.2136678, 6.2392418],
          [116.2147486, 6.2395413],
          [116.2151739, 6.2394092],
          [116.2155725, 6.2395589],
          [116.216361, 6.2399904],
          [116.2172558, 6.2402018],
          [116.217681, 6.240281],
          [116.2179645, 6.240281],
          [116.2184429, 6.2401665],
          [116.2186644, 6.2401929],
          [116.2188858, 6.2403867],
          [116.2190365, 6.2406421],
          [116.2211804, 6.2412718],
          [116.2226997, 6.2414347],
          [116.222837, 6.2413951],
          [116.223249, 6.2415492],
          [116.2235236, 6.2415184],
          [116.2235989, 6.2414391],
          [116.2236388, 6.2412982],
          [116.2237894, 6.2413334],
          [116.2237407, 6.2415272],
          [116.2238071, 6.2416549],
          [116.2242944, 6.2417517],
          [116.2255878, 6.2421392],
          [116.2258181, 6.2421216],
          [116.2261636, 6.2422317],
          [116.2262655, 6.2422009],
          [116.227869, 6.242694],
          [116.228879, 6.2430683],
          [116.2293529, 6.2430463],
          [116.2294504, 6.2430991],
          [116.2294903, 6.2432665],
          [116.2299332, 6.2435219],
          [116.2301104, 6.2434998],
          [116.2302654, 6.2435659],
          [116.2304515, 6.2435263],
          [116.2306331, 6.2435747],
          [116.2309033, 6.2438521],
          [116.2310273, 6.2438565],
          [116.2312532, 6.2439622],
          [116.2317892, 6.2441163],
          [116.2329409, 6.2446271],
          [116.2331845, 6.2447768],
          [116.2331447, 6.2446843],
          [116.2331624, 6.2445698],
          [116.2332953, 6.244561],
          [116.2335167, 6.2448252],
          [116.2336762, 6.2449089],
          [116.2339464, 6.2451555],
          [116.2342343, 6.245248],
          [116.2343274, 6.2452612],
          [116.2342963, 6.2451863],
          [116.2343539, 6.2450146],
          [116.234159, 6.2449045],
          [116.2342166, 6.2447152],
          [116.2343495, 6.2446623],
          [116.2347083, 6.2449045],
          [116.2348633, 6.2449397],
          [116.2349298, 6.2450498],
          [116.2347172, 6.2452347],
          [116.2346108, 6.2452391],
          [116.2345223, 6.2451511],
          [116.2345178, 6.2452612],
          [116.2344425, 6.2453052],
          [116.234571, 6.2454285],
          [116.2347747, 6.2455033],
          [116.2350095, 6.2456883],
          [116.2363783, 6.246428],
          [116.2362587, 6.2461726],
          [116.2362454, 6.2458776],
          [116.2363428, 6.2458644],
          [116.2363915, 6.2457147],
          [116.2363251, 6.245587],
          [116.2361745, 6.2454945],
          [116.2362985, 6.2453492],
          [116.2366529, 6.245565],
          [116.2368035, 6.2453756],
          [116.2368301, 6.2451599],
          [116.237118, 6.2454109],
          [116.2372287, 6.245292],
          [116.237335, 6.2453448],
          [116.2374236, 6.245336],
          [116.2374236, 6.2454373],
          [116.2373572, 6.2455033],
          [116.2371623, 6.2455518],
          [116.2371136, 6.2456398],
          [116.2371844, 6.2457411],
          [116.23718, 6.2458732],
          [116.237087, 6.2463356],
          [116.2370205, 6.2464721],
          [116.2370205, 6.2462827],
          [116.2368434, 6.246362],
          [116.2367503, 6.2463003],
          [116.2367636, 6.2464148],
          [116.2368655, 6.2465249],
          [116.2370161, 6.2465645],
          [116.2370914, 6.2466394],
          [116.2374635, 6.2467275],
          [116.2375432, 6.2468199],
          [116.2375299, 6.2469872],
          [116.2377293, 6.2469828],
          [116.2378444, 6.2469212],
          [116.2380748, 6.2469344],
          [116.2382165, 6.2470269],
          [116.2384557, 6.2472735],
          [116.2388278, 6.2478371],
          [116.2389297, 6.2478855],
          [116.2387525, 6.2481189],
          [116.2390271, 6.2483479],
          [116.2391556, 6.2482114],
          [116.239222, 6.2481981],
          [116.2395055, 6.2483875],
          [116.2395543, 6.2483699],
          [116.2398776, 6.2485064],
          [116.240303870424185, 6.248864494264126],
          [116.2408211, 6.249299],
          [116.2409983, 6.2492858],
          [116.2411445, 6.2495632],
          [116.2413527, 6.2496997],
          [116.241614, 6.2499639],
          [116.241707, 6.250162],
          [116.2420393, 6.2503601],
          [116.2420614, 6.2504614],
          [116.2422164, 6.2505759],
          [116.2425309, 6.2506331],
          [116.2427879, 6.2508181],
          [116.2431422, 6.2509634],
          [116.243408, 6.2509898],
          [116.2440459, 6.2508929],
          [116.2442939, 6.2507696],
          [116.24448, 6.2506067],
          [116.2445685, 6.2505011],
          [116.2446217, 6.2501708],
          [116.2447369, 6.2500783],
          [116.2448786, 6.2503425],
          [116.2450779, 6.2503646],
          [116.2454988, 6.2501928],
          [116.2456006, 6.2502016],
          [116.2457778, 6.2500695],
          [116.2466017, 6.2497613],
          [116.2472617, 6.2493122],
          [116.2476648, 6.249136],
          [116.247935, 6.2489379],
          [116.247991195605294, 6.248864494264126],
          [116.2480901, 6.2487353],
          [116.2481122, 6.2484095],
          [116.2482274, 6.2484183],
          [116.2483425, 6.2483214],
          [116.248471, 6.2483258],
          [116.2488254, 6.2479207],
          [116.2489494, 6.2476918],
          [116.2493348, 6.2472338],
          [116.2499106, 6.2463488],
          [116.2500036, 6.2461506],
          [116.2499771, 6.2460185],
          [116.2498575, 6.2460185],
          [116.2498176, 6.2459084],
          [116.2499195, 6.2458864],
          [116.2501631, 6.2456487],
          [116.250482, 6.2451335],
          [116.2506504, 6.2447416],
          [116.2508807, 6.2440591],
          [116.250894, 6.2438389],
          [116.2508054, 6.2437244],
          [116.2504953, 6.2436011],
          [116.2505308, 6.2435571],
          [116.2508807, 6.2434778],
          [116.2510845, 6.2433369],
          [116.2512528, 6.2431476],
          [116.2513857, 6.2427865],
          [116.2515717, 6.242443],
          [116.2518641, 6.2420423],
          [116.2518906, 6.2417914],
          [116.2517799, 6.2416857],
          [116.2519837, 6.2417561],
          [116.2520988, 6.2416901],
          [116.2525418, 6.2411529],
          [116.2527012, 6.2408094],
          [116.2529094, 6.2404924],
          [116.2529892, 6.240215],
          [116.2529759, 6.2401533],
          [116.2526791, 6.2399684],
          [116.2526968, 6.2399287],
          [116.2528961, 6.2399904],
          [116.2532106, 6.2399684],
          [116.253379, 6.2398231],
          [116.2538352, 6.239233],
          [116.253915, 6.2390261],
          [116.2540035, 6.2390305],
          [116.2544465, 6.2382423],
          [116.2544554, 6.2379781],
          [116.2540611, 6.2376742],
          [116.2542029, 6.2376963],
          [116.2543048, 6.237661],
          [116.2544554, 6.2377139],
          [116.2546281, 6.2377095],
          [116.2550977, 6.2374012],
          [116.2553767, 6.2371282],
          [116.2557045, 6.2369169],
          [116.2559614, 6.2366703],
          [116.2560323, 6.2365646],
          [116.2559924, 6.2363532],
          [116.2561918, 6.2363796],
          [116.2563291, 6.2363136],
          [116.2566081, 6.2361331],
          [116.2569758, 6.2357984],
          [116.2572859, 6.235248],
          [116.257308, 6.2353537],
          [116.2574232, 6.2354329],
          [116.2575295, 6.2354461],
          [116.2587388, 6.2350542],
          [116.2591729, 6.2348473],
          [116.2593766, 6.2348737],
          [116.2594962, 6.2347812],
          [116.2596601, 6.2348297],
          [116.2597443, 6.2348165],
          [116.2597664, 6.2347636],
          [116.2598373, 6.2348341],
          [116.2601297, 6.234746],
          [116.2601739, 6.2348385],
          [116.2604796, 6.2348737],
          [116.2607631, 6.2348165],
          [116.2608384, 6.2348825],
          [116.2609536, 6.2348869],
          [116.2611219, 6.234724],
          [116.261144, 6.2346095],
          [116.2612902, 6.2343717],
          [116.2613611, 6.2340767],
          [116.2613389, 6.2334514],
          [116.2614319, 6.2328746],
          [116.2614895, 6.2317869],
          [116.2613345, 6.2314611],
          [116.2609087, 6.2311408],
          [116.2604013, 6.2309913],
          [116.2602509, 6.2308605],
          [116.2598062, 6.2308232],
          [116.2593927, 6.2308917],
          [116.2591296, 6.2300821],
          [116.2593739, 6.2299264],
          [116.2595243, 6.2297271],
          [116.2600004, 6.2297085],
          [116.2604138, 6.2298642],
          [116.2608273, 6.2298455],
          [116.2617043, 6.2301008],
          [116.2622806, 6.2303997],
          [116.2624811, 6.2305803],
          [116.2626377, 6.2308356],
          [116.2629572, 6.2309664],
          [116.2630762, 6.2308854],
          [116.2635899, 6.2297147],
          [116.2635648, 6.229366],
          [116.2637026, 6.229503],
          [116.2640785, 6.2294469],
          [116.2642664, 6.2292912],
          [116.2644543, 6.229285],
          [116.2654441, 6.2289363],
          [116.2656383, 6.2287619],
          [116.2663211, 6.2288927],
          [116.266722, 6.228874],
          [116.2669476, 6.2289114],
          [116.2671042, 6.2288802],
          [116.2671668, 6.2289861],
          [116.2675677, 6.2293224],
          [116.2679185, 6.2298766],
          [116.2682819, 6.2300883],
          [116.2683821, 6.2300946],
          [116.2688645, 6.229914],
          [116.2692904, 6.2296586],
          [116.2697415, 6.2294656],
          [116.2699858, 6.2294531],
          [116.2706059, 6.2292788],
          [116.2712199, 6.2292103],
          [116.2722096, 6.2293597],
          [116.2724038, 6.2294594],
          [116.2727734, 6.2295528],
          [116.2729112, 6.2296275],
          [116.2730992, 6.2298206],
          [116.2735753, 6.2300759],
          [116.2738196, 6.2303312],
          [116.2740012, 6.2302627],
          [116.2740388, 6.2304059],
          [116.2741766, 6.2304931],
          [116.2742957, 6.2304495],
          [116.2744272, 6.230462],
          [116.2744961, 6.2303312],
          [116.2746402, 6.2302565],
          [116.2747843, 6.2302565],
          [116.2751601, 6.2298268],
          [116.2751977, 6.2296213],
          [116.2751789, 6.2293722],
          [116.2750537, 6.229229],
          [116.2749409, 6.2288802],
          [116.2747279, 6.228519],
          [116.2745087, 6.2283883],
          [116.2744585, 6.2281952],
          [116.2742706, 6.2281703],
          [116.2740827, 6.2278901],
          [116.2738947, 6.227834],
          [116.2739386, 6.2275102],
          [116.2738321, 6.2272237],
          [116.2735815, 6.2268688],
          [116.2730491, 6.2265636],
          [116.2728549, 6.2265636],
          [116.2727985, 6.2266508],
          [116.2726043, 6.2264702],
          [116.2724351, 6.2264079],
          [116.2721783, 6.2263955],
          [116.2720595, 6.2264359],
          [116.2718248, 6.2263522],
          [116.2719134, 6.2262378],
          [116.2718558, 6.2261057],
          [116.2717406, 6.2261277],
          [116.271714, 6.2260308],
          [116.2713685, 6.2260484],
          [116.2713597, 6.2259647],
          [116.2715501, 6.2258635],
          [116.271559, 6.2257093],
          [116.271435, 6.2255684],
          [116.2713154, 6.2256213],
          [116.271216, 6.2257784],
          [116.2712372, 6.2254446],
          [116.2713745, 6.2253169],
          [116.2713391, 6.2251363],
          [116.2712283, 6.2250615],
          [116.2712328, 6.2248853],
          [116.2713302, 6.2246828],
          [116.2713563, 6.2244725],
          [116.2711348, 6.2235646],
          [116.2706376, 6.2227648],
          [116.2696757, 6.2223325],
          [116.269011, 6.2223271],
          [116.2687408, 6.2221758],
          [116.2689029, 6.2218569],
          [116.2686435, 6.2215327],
          [116.2679788, 6.221122],
          [116.2670115, 6.2211382],
          [116.2661739, 6.2213219],
          [116.2656875, 6.2215219],
          [116.2653687, 6.221403],
          [116.264958, 6.2214787],
          [116.2648337, 6.2216138],
          [116.2641041, 6.2214841],
          [116.2639798, 6.2211814],
          [116.2643635, 6.2210517],
          [116.2643149, 6.2207653],
          [116.2639204, 6.2204843],
          [116.2639528, 6.2203168],
          [116.2642284, 6.2201493],
          [116.2640014, 6.2199493],
          [116.2635799, 6.2196845],
          [116.2636448, 6.2193332],
          [116.2632719, 6.2191117],
          [116.2632287, 6.2183389],
          [116.2634232, 6.2181498],
          [116.2639906, 6.2186577],
          [116.2639906, 6.2191495],
          [116.2648931, 6.2201601],
          [116.2657145, 6.2206626],
          [116.2660442, 6.2206843],
          [116.2664549, 6.2204789],
          [116.2670871, 6.2206951],
          [116.2677356, 6.2207113],
          [116.2682436, 6.2205329],
          [116.2684219, 6.2206464],
          [116.2687732, 6.2210031],
          [116.2692163, 6.220587],
          [116.2692596, 6.220306],
          [116.2690002, 6.2198196],
          [116.2696108, 6.2195602],
          [116.2697946, 6.2197007],
          [116.2700432, 6.2196034],
          [116.2699026, 6.2202087],
          [116.269573, 6.2203924],
          [116.2695568, 6.2207221],
          [116.2697081, 6.2210301],
          [116.2699459, 6.2211706],
          [116.2701945, 6.221176],
          [116.2705349, 6.2216408],
          [116.2709456, 6.2220028],
          [116.2714644, 6.2226459],
          [116.2718373, 6.223543],
          [116.2718967, 6.2239321],
          [116.2720372, 6.2240618],
          [116.2720859, 6.2243914],
          [116.2722161, 6.2247136],
          [116.272411, 6.2250659],
          [116.27273, 6.2254049],
          [116.2731109, 6.2256119],
          [116.2732704, 6.2257572],
          [116.2734476, 6.2258189],
          [116.2736336, 6.2258409],
          [116.2736779, 6.225766],
          [116.2742626, 6.2258541],
          [116.2747543, 6.2258585],
          [116.2751751, 6.2257308],
          [116.2754807, 6.2253961],
          [116.275525, 6.2251672],
          [116.2754985, 6.2250483],
          [116.2755826, 6.2251628],
          [116.2758041, 6.2251055],
          [116.2759813, 6.2248193],
          [116.2759503, 6.2247488],
          [116.2760965, 6.2247092],
          [116.276123, 6.2246431],
          [116.2764154, 6.2244846],
          [116.2766634, 6.2243966],
          [116.276783, 6.2240531],
          [116.2768495, 6.2240575],
          [116.2769204, 6.2245331],
          [116.2770045, 6.2247136],
          [116.2771551, 6.2248149],
          [116.2774652, 6.2248765],
          [116.2773323, 6.2249822],
          [116.2773412, 6.2251143],
          [116.2771507, 6.22522],
          [116.277133, 6.2253477],
          [116.2771861, 6.2254358],
          [116.2773677, 6.2254578],
          [116.277443, 6.2254005],
          [116.2774475, 6.2253433],
          [116.2775228, 6.2253389],
          [116.2776158, 6.225264],
          [116.2776645, 6.2251143],
          [116.2776379, 6.2249338],
          [116.2776911, 6.2248985],
          [116.2778196, 6.2250879],
          [116.2780012, 6.2251848],
          [116.2781562, 6.2250615],
          [116.2783378, 6.2250703],
          [116.278453, 6.2252288],
          [116.2786346, 6.2253433],
          [116.2784574, 6.2255106],
          [116.2784441, 6.2256427],
          [116.2784929, 6.2258189],
          [116.2787498, 6.225973],
          [116.2787586, 6.2260479],
          [116.2786523, 6.2262592],
          [116.2787055, 6.2264045],
          [116.2788029, 6.2264706],
          [116.2789485, 6.2264657],
          [116.2793369, 6.2262975],
          [116.279412, 6.2261605],
          [116.2794747, 6.2261481],
          [116.2794747, 6.2262913],
          [116.2797065, 6.2262913],
          [116.2798693, 6.2261979],
          [116.2799633, 6.2262788],
          [116.2798631, 6.2265279],
          [116.2798944, 6.2268082],
          [116.2800949, 6.2271195],
          [116.2802139, 6.2271631],
          [116.2802984, 6.227132],
          [116.2802828, 6.2270074],
          [116.2803548, 6.226967],
          [116.2804081, 6.2268518],
          [116.2806837, 6.2269016],
          [116.2807338, 6.2270074],
          [116.2808247, 6.2270448],
          [116.2808528, 6.2271164],
          [116.280809, 6.2271694],
          [116.2804926, 6.2272005],
          [116.2804363, 6.2271569],
          [116.2803204, 6.2272067],
          [116.2803454, 6.227434],
          [116.2802327, 6.2276707],
          [116.2802577, 6.2277485],
          [116.280336, 6.2277267],
          [116.2804049, 6.2276426],
          [116.2805553, 6.2275741],
          [116.2805647, 6.2275181],
          [116.2806524, 6.2275928],
          [116.2809969, 6.2276271],
          [116.2810126, 6.2278886],
          [116.2810752, 6.2279478],
          [116.2811034, 6.2281284],
          [116.2810846, 6.2283463],
          [116.2811914, 6.2284706],
          [116.2811249, 6.2285279],
          [116.2811116, 6.2286644],
          [116.2811737, 6.2287084],
          [116.2811382, 6.2290651],
          [116.2812135, 6.229184],
          [116.281435, 6.2292456],
          [116.2815856, 6.2291752],
          [116.2819843, 6.2291224],
          [116.2821526, 6.2290079],
          [116.2821437, 6.2291752],
          [116.2820729, 6.2292985],
          [116.2821083, 6.2296992],
          [116.2820684, 6.2298577],
          [116.2821304, 6.2299414],
          [116.2822722, 6.2299326],
          [116.282219, 6.230003],
          [116.2819621, 6.2300867],
          [116.2819621, 6.2302364],
          [116.2820197, 6.2303289],
          [116.28194, 6.2306239],
          [116.2821393, 6.230756],
          [116.2822589, 6.230778],
          [116.2823475, 6.2306768],
          [116.2823342, 6.2305799],
          [116.2833087, 6.2306635],
          [116.283477, 6.2306944],
          [116.2836144, 6.2308573],
          [116.2837694, 6.2309189],
          [116.2838801, 6.2308485],
          [116.2839554, 6.2309013],
          [116.2842744, 6.230668],
          [116.2846553, 6.2305447],
          [116.284704, 6.2306724],
          [116.2843319, 6.2308969],
          [116.284044, 6.2313505],
          [116.284044, 6.2315046],
          [116.2842921, 6.2315927],
          [116.2846642, 6.2315971],
          [116.2847439, 6.2317116],
          [116.2850495, 6.2319229],
          [116.2849875, 6.2320022],
          [116.2849742, 6.2321123],
          [116.2849078, 6.2321651],
          [116.2848945, 6.2322708],
          [116.2849477, 6.2323633],
          [116.2850584, 6.2323545],
          [116.2850983, 6.2322091],
          [116.285209, 6.2324601],
          [116.2851913, 6.2326055],
          [116.285116, 6.2325922],
          [116.2850274, 6.2326363],
          [116.2852976, 6.2329445],
          [116.2854305, 6.2329753],
          [116.2855456, 6.2331823],
          [116.2854881, 6.2333056],
          [116.2854172, 6.233354],
          [116.2853419, 6.2336491],
          [116.2851603, 6.233856],
          [116.2851426, 6.2339617],
          [116.2853109, 6.2341466],
          [116.2852799, 6.2342039],
          [116.2851647, 6.2342215],
          [116.285054, 6.2343624],
          [116.285023, 6.2346883],
          [116.284704, 6.2350713],
          [116.2840174, 6.2356394],
          [116.2839023, 6.2358419],
          [116.2838004, 6.2358243],
          [116.2835878, 6.2360093],
          [116.2834992, 6.2361061],
          [116.2834593, 6.2362603],
          [116.2835479, 6.2363747],
          [116.2838624, 6.2365377],
          [116.2842212, 6.2366257],
          [116.2844958, 6.2366213],
          [116.2844515, 6.2369824],
          [116.2841459, 6.2370264],
          [116.2839776, 6.2369296],
          [116.2837207, 6.2368635],
          [116.283291, 6.2364452],
          [116.2830208, 6.2362735],
          [116.282848, 6.236225],
          [116.2826531, 6.2362294],
          [116.282538, 6.2362823],
          [116.2824892, 6.2364012],
          [116.2824937, 6.2370793],
          [116.2823076, 6.2372598],
          [116.2823342, 6.2374095],
          [116.2824494, 6.2375064],
          [116.2826886, 6.2375901],
          [116.2811515, 6.2384707],
          [116.2804162, 6.2389903],
          [116.280022, 6.2394131],
          [116.2799777, 6.23962],
          [116.2799245, 6.2393294],
          [116.2793752, 6.2392413],
          [116.2791626, 6.239281],
          [116.2790297, 6.2393646],
          [116.2789456, 6.2395143],
          [116.2789323, 6.2396817],
          [116.279012, 6.2397697],
          [116.2793088, 6.2398974],
          [116.2797119, 6.2399591],
          [116.2799245, 6.2399855],
          [116.280022, 6.2399547],
          [116.2800397, 6.2398358],
          [116.2801327, 6.2399679],
          [116.2807396, 6.2400372],
          [116.2813863, 6.2400416],
          [116.2819843, 6.2401253],
          [116.2823076, 6.2401209],
          [116.2839687, 6.2403234],
          [116.2851869, 6.2403411],
          [116.2853286, 6.2402574],
          [116.2856342, 6.2401825],
          [116.2864404, 6.2400725],
          [116.2866043, 6.2399756],
          [116.2871447, 6.2399888],
          [116.2874459, 6.2398699],
          [116.2877206, 6.239685],
          [116.2878667, 6.2397202],
          [116.2882654, 6.2396145],
          [116.2898379, 6.2388527],
          [116.2898689, 6.238637],
          [116.2899929, 6.2387294],
          [116.2905378, 6.2385709],
          [116.290839, 6.2384036],
          [116.290932, 6.2382627],
          [116.2910871, 6.2382891],
          [116.291375, 6.2381086],
          [116.2914547, 6.2380117],
          [116.2916009, 6.2379985],
          [116.2922609, 6.2376462],
          [116.2926551, 6.2375449],
          [116.2931247, 6.2375405],
          [116.2931009, 6.2380683],
          [116.2930914, 6.2382803],
          [116.2928567, 6.2383067],
          [116.2926263, 6.2383948],
          [116.2924713, 6.2382847],
          [116.2920593, 6.2382847],
          [116.2917936, 6.2384917],
          [116.2917803, 6.2387515],
          [116.2916297, 6.2387691],
          [116.2913993, 6.238998],
          [116.2906862, 6.239456],
          [116.2897382, 6.2401781],
          [116.2896718, 6.240319],
          [116.2897515, 6.2404952],
          [116.2904736, 6.2408298],
          [116.2911646, 6.2416136],
          [116.2912576, 6.241618],
          [116.2912842, 6.2417413],
          [116.2911646, 6.2418206],
          [116.291014, 6.241847],
          [116.2908279, 6.2420099],
          [116.2906242, 6.2422125],
          [116.2905001, 6.2424414],
          [116.2895212, 6.242349],
          [116.2893174, 6.2420628],
          [116.2893706, 6.2418558],
          [116.2892953, 6.2415476],
          [116.2890517, 6.241301],
          [116.288653, 6.2410456],
          [116.2883031, 6.2409531],
          [116.2879044, 6.2409575],
          [116.2870318, 6.2411821],
          [116.2869786, 6.2413054],
          [116.2870141, 6.2414507],
          [116.2871204, 6.2415476],
          [116.2872444, 6.241574],
          [116.2872222, 6.2416753],
          [116.2871469, 6.2417457],
          [116.2871204, 6.2418998],
          [116.2873463, 6.242415],
          [116.2866907, 6.242851],
          [116.2865179, 6.2428289],
          [116.2862522, 6.2429038],
          [116.2861591, 6.2430227],
          [116.2856143, 6.2433221],
          [116.2854504, 6.2436127],
          [116.2854105, 6.2438021],
          [116.2851625, 6.2440487],
          [116.285096, 6.2443393],
          [116.2851182, 6.2448985],
          [116.2850562, 6.2445374],
          [116.2848834, 6.2443349],
          [116.2848214, 6.2441191],
          [116.28491, 6.2440002],
          [116.2848701, 6.2438505],
          [116.2849233, 6.2437096],
          [116.2849986, 6.243648],
          [116.2850828, 6.2433573],
          [116.2850429, 6.2431152],
          [116.2851005, 6.2427056],
          [116.285065, 6.2424987],
          [116.2848701, 6.2422257],
          [116.2842323, 6.2420407],
          [116.2839001, 6.2420231],
          [116.2838425, 6.2422477],
          [116.2838115, 6.242054],
          [116.2837495, 6.2419879],
          [116.2824604, 6.2419615],
          [116.2819865, 6.2421508],
          [116.2818492, 6.2422477],
          [116.2817251, 6.2424282],
          [116.2816764, 6.2424282],
          [116.2816986, 6.2421993],
          [116.2815347, 6.2420275],
          [116.2805513, 6.2417721],
          [116.280383, 6.2416709],
          [116.2799799, 6.2417061],
          [116.2799622, 6.2415476],
          [116.2798293, 6.2414507],
          [116.2789877, 6.2412878],
          [116.2786554, 6.2411601],
          [116.2782966, 6.2411733],
          [116.2781239, 6.2411205],
          [116.2774639, 6.2411513],
          [116.2772867, 6.2411249],
          [116.2771051, 6.2411645],
          [116.2770564, 6.2412878],
          [116.2770696, 6.2414331],
          [116.2772247, 6.2416841],
          [116.2771405, 6.2417017],
          [116.2770254, 6.2416929],
          [116.2769102, 6.2415035],
          [116.2767862, 6.2414419],
          [116.2766577, 6.24153],
          [116.2767551, 6.2412349],
          [116.2766001, 6.2411601],
          [116.275661, 6.2411469],
          [116.2750586, 6.2410808],
          [116.274784, 6.2411601],
          [116.2748017, 6.2413186],
          [116.2747043, 6.2413186],
          [116.2746865, 6.2411116],
          [116.2745935, 6.2410764],
          [116.2742126, 6.2411072],
          [116.2734773, 6.241279],
          [116.2732248, 6.2414023],
          [116.2730742, 6.2414023],
          [116.2729767, 6.2414639],
          [116.2728527, 6.2414815],
          [116.2727685, 6.2414023],
          [116.2726489, 6.2417017],
          [116.2727951, 6.2419395],
          [116.2728527, 6.2421905],
          [116.2728394, 6.242393],
          [116.272959, 6.24271],
          [116.2729723, 6.2433001],
          [116.2730653, 6.2434234],
          [116.273402, 6.2434322],
          [116.2736589, 6.2433221],
          [116.2736722, 6.243441],
          [116.2743809, 6.243648],
          [116.2745802, 6.2438461],
          [116.274691, 6.2438989],
          [116.2747397, 6.2440178],
          [116.274908, 6.2441984],
          [116.2749745, 6.2443657],
          [116.2750808, 6.2444273],
          [116.2752269, 6.244762],
          [116.2753776, 6.2449117],
          [116.2755149, 6.2452023],
          [116.2759047, 6.2457219],
          [116.2760287, 6.2460477],
          [116.2759933, 6.246149],
          [116.2760508, 6.2462503],
          [116.2763122, 6.2464088],
          [116.2764008, 6.2468095],
          [116.2765957, 6.2471398],
          [116.27664, 6.2473335],
          [116.276702, 6.2474128],
          [116.2767906, 6.2474172],
          [116.2768083, 6.2473511],
          [116.2767463, 6.247241],
          [116.276919, 6.2472939],
          [116.2768836, 6.247426],
          [116.276733, 6.2475228],
          [116.2766931, 6.2476065],
          [116.2767773, 6.2480556],
          [116.2769501, 6.2484035],
          [116.2768836, 6.2484916],
          [116.2769456, 6.2486897],
          [116.276919, 6.2488174],
          [116.276940881352417, 6.248864494264126],
          [116.2769722, 6.2489319],
          [116.2770254, 6.2498037],
          [116.2769899, 6.2499711],
          [116.2768925, 6.2501208],
          [116.2769943, 6.2502749],
          [116.2773266, 6.250451],
          [116.2777695, 6.2508341],
          [116.2779334, 6.2515826],
          [116.2779334, 6.2520538],
          [116.2778891, 6.2521639],
          [116.2779423, 6.2525822],
          [116.2779246, 6.2528067],
          [116.2780176, 6.2528684],
          [116.2778581, 6.2530445],
          [116.2778537, 6.2531634],
          [116.2779556, 6.2531282],
          [116.2779556, 6.2532691],
          [116.2777872, 6.2535245],
          [116.2778227, 6.2540352],
          [116.2780574, 6.2541982],
          [116.278146, 6.2541673],
          [116.2781903, 6.2542818],
          [116.2783675, 6.2543038],
          [116.2782834, 6.2543699],
          [116.2781283, 6.2543963],
          [116.2781372, 6.2544976],
          [116.2780353, 6.2544888],
          [116.277991, 6.2545372],
          [116.2779954, 6.2546209],
          [116.2778493, 6.2544932],
          [116.2778936, 6.2543963],
          [116.2778537, 6.2543347],
          [116.2777297, 6.2543435],
          [116.2777385, 6.2544491],
          [116.2776499, 6.2544007],
          [116.2775392, 6.2544359],
          [116.2775658, 6.254502],
          [116.277269, 6.2546561],
          [116.2771007, 6.2549203],
          [116.2768836, 6.2550216],
          [116.2769323, 6.2551184],
          [116.2768127, 6.2551272],
          [116.2765735, 6.2554002],
          [116.2762413, 6.2555632],
          [116.2760243, 6.2555896],
          [116.2757806, 6.2555544],
          [116.2754041, 6.2556336],
          [116.2752491, 6.2557217],
          [116.2751251, 6.2556953],
          [116.2750143, 6.2557437],
          [116.2748549, 6.2559022],
          [116.274846, 6.2561356],
          [116.2755547, 6.2564482],
          [116.2757408, 6.2566199],
          [116.2762502, 6.2567344],
          [116.276197, 6.2568577],
          [116.2768305, 6.257276],
          [116.2787175, 6.2588832],
          [116.2793907, 6.2593455],
          [116.2796255, 6.2594512],
          [116.2798957, 6.2595084],
          [116.2801216, 6.2596405],
          [116.2806709, 6.2597286],
          [116.2808791, 6.259733],
          [116.2809101, 6.2596625],
          [116.2810208, 6.259733],
          [116.281136, 6.2596889],
          [116.2813619, 6.2597418],
          [116.2818536, 6.2597198],
          [116.2822655, 6.2596097],
          [116.2823674, 6.2596361],
          [116.2827882, 6.2595965],
          [116.2831072, 6.2596537],
          [116.2834837, 6.2598078],
          [116.2835501, 6.2597198],
          [116.2835457, 6.2598431],
          [116.2837805, 6.2599575],
          [116.2839532, 6.2599752],
          [116.2838646, 6.2600368],
          [116.2839222, 6.2602349],
          [116.284157, 6.2601865],
          [116.2841525, 6.260257],
          [116.2842721, 6.260389],
          [116.2844272, 6.2604155],
          [116.2845645, 6.2603318],
          [116.2846708, 6.2603758],
          [116.284693, 6.2596449],
          [116.284755, 6.2594644],
          [116.2849056, 6.2593543],
          [116.2849277, 6.259253],
          [116.2850429, 6.2591121],
          [116.2850695, 6.2587643],
          [116.2851492, 6.258958],
          [116.2848746, 6.2596933],
          [116.2848834, 6.2601953],
          [116.2852156, 6.2609615],
          [116.2856542, 6.2614898],
          [116.2858624, 6.2618157],
          [116.2859598, 6.2618333],
          [116.2860528, 6.2619478],
          [116.2863939, 6.2620843],
          [116.2864604, 6.2621987],
          [116.2868413, 6.2624717],
          [116.2870805, 6.2627095],
          [116.2872267, 6.262868],
          [116.2874216, 6.2632115],
          [116.2875589, 6.2633392],
          [116.2878069, 6.2634052],
          [116.2889409, 6.2644443],
          [116.2892333, 6.2646337],
          [116.2893219, 6.2645896],
          [116.2894636, 6.264735],
          [116.2896939, 6.2648362],
          [116.2898003, 6.2648406],
          [116.2897958, 6.2646997],
          [116.2898933, 6.2646777],
          [116.2899066, 6.2645764],
          [116.2900084, 6.2646293],
          [116.2901059, 6.2647438],
          [116.290004, 6.2648847],
          [116.2900705, 6.2649507],
          [116.2902919, 6.2649727],
          [116.2904824, 6.2651004],
          [116.290983, 6.2655804],
          [116.2911247, 6.2656772],
          [116.2912797, 6.2656728],
          [116.2917005, 6.2660383],
          [116.2918334, 6.2660955],
          [116.2920062, 6.2662893],
          [116.2921657, 6.2663685],
          [116.2921745, 6.2664654],
          [116.2926396, 6.2667692],
          [116.2919619, 6.2669365],
          [116.2918201, 6.2670906],
          [116.2917316, 6.2672667],
          [116.2916873, 6.2675221],
          [116.2914569, 6.2675485],
          [116.2913772, 6.2676058],
          [116.2913196, 6.2677951],
          [116.2914126, 6.2680549],
          [116.2914879, 6.2681033],
          [116.291767, 6.2680505],
          [116.292241, 6.2677467],
          [116.2924624, 6.2676718],
          [116.2927371, 6.2676718],
          [116.2929541, 6.2677467],
          [116.2931224, 6.2679008],
          [116.2928611, 6.2678127],
          [116.2925732, 6.2678127],
          [116.2924181, 6.26787],
          [116.2921214, 6.2680813],
          [116.2920726, 6.268209],
          [116.2915101, 6.2684688],
          [116.291355, 6.2684204],
          [116.291045, 6.2684556],
          [116.2909032, 6.2685436],
          [116.2907836, 6.2688254],
          [116.2906507, 6.2689619],
          [116.2896762, 6.2693406],
          [116.2894636, 6.2693626],
          [116.2888745, 6.2696488],
          [116.2887194, 6.2696532],
          [116.2883917, 6.2697897],
          [116.2879974, 6.2700891],
          [116.2875456, 6.2700495],
          [116.2873728, 6.2701199],
          [116.2871337, 6.2701288],
          [116.2870185, 6.2702256],
          [116.2868236, 6.2716654],
          [116.2868413, 6.2725372],
          [116.2869343, 6.2728454],
          [116.2871558, 6.2732637],
          [116.2875545, 6.2744526],
          [116.2876431, 6.2744085],
          [116.2877006, 6.274479],
          [116.2875899, 6.2745758],
          [116.2877051, 6.2748356],
          [116.2875988, 6.2755665],
          [116.2875013, 6.2756282],
          [116.2874614, 6.2758175],
          [116.2872444, 6.2756634],
          [116.2871779, 6.2755401],
          [116.2870008, 6.2755797],
          [116.2868945, 6.2755489],
          [116.2867483, 6.2756898],
          [116.2863629, 6.2756942],
          [116.2863496, 6.2758395],
          [116.2857162, 6.2756634],
          [116.2856232, 6.2757074],
          [116.2855612, 6.2756062],
          [116.285096, 6.2755621],
          [116.2848701, 6.275725],
          [116.2846974, 6.2760861],
          [116.284693, 6.276183],
          [116.2847461, 6.2762182],
          [116.2848303, 6.2761918],
          [116.2847505, 6.2764339],
          [116.2849144, 6.2767686],
          [116.2851005, 6.2769799],
          [116.2852112, 6.2772045],
          [116.2852777, 6.2772177],
          [116.2853574, 6.277363],
          [116.2856054, 6.277473],
          [116.2859997, 6.2775919],
          [116.2860484, 6.2776492],
          [116.2862079, 6.2776271],
          [116.2865534, 6.2777592],
          [116.2865312, 6.2778297],
          [116.28623, 6.2777592],
          [116.2857959, 6.2778077],
          [116.2855789, 6.2777813],
          [116.2854504, 6.2777152],
          [116.285353, 6.2777548],
          [116.2853087, 6.2778385],
          [116.2852334, 6.2778165],
          [116.2848923, 6.2781775],
          [116.2848303, 6.2781687],
          [116.2849543, 6.2780014],
          [116.2850119, 6.2777328],
          [116.2849277, 6.2775655],
          [116.2845246, 6.2771868],
          [116.2840772, 6.2768654],
          [116.2839266, 6.2768214],
          [116.2835811, 6.2765],
          [116.2833109, 6.2763943],
          [116.2833375, 6.2762886],
          [116.2832976, 6.2761918],
          [116.2831337, 6.2761125],
          [116.2831205, 6.27602],
          [116.2827794, 6.2756414],
          [116.2822567, 6.2752803],
          [116.2823453, 6.275157],
          [116.2823098, 6.2749941],
          [116.2821061, 6.2747476],
          [116.2816897, 6.2744702],
          [116.2815524, 6.274479],
          [116.2814461, 6.2745406],
          [116.2813973, 6.2746903],
          [116.2812955, 6.2746155],
          [116.2812645, 6.274545],
          [116.2813663, 6.2740519],
          [116.2812379, 6.2737745],
          [116.2812512, 6.2736776],
          [116.2810518, 6.2734399],
          [116.2807994, 6.2734443],
          [116.2807905, 6.2733518],
          [116.2806842, 6.2733122],
          [116.2807152, 6.2731889],
          [116.2808392, 6.2732637],
          [116.281105, 6.2731889],
          [116.2811493, 6.2730612],
          [116.2811227, 6.2729027],
          [116.2810518, 6.2728146],
          [116.28095, 6.2727926],
          [116.2807728, 6.2729335],
          [116.2806842, 6.272841],
          [116.2808392, 6.2726209],
          [116.2807905, 6.2722114],
          [116.2806045, 6.2717799],
          [116.2804273, 6.2716038],
          [116.2802678, 6.2715289],
          [116.2801792, 6.2713748],
          [116.2798514, 6.2710754],
          [116.2794395, 6.2707628],
          [116.2792357, 6.2706791],
          [116.2781638, 6.2707188],
          [116.2779954, 6.2706615],
          [116.277548, 6.2707056],
          [116.2773664, 6.2706659],
          [116.2773399, 6.2706043],
          [116.2776632, 6.2706263],
          [116.2780353, 6.2705426],
          [116.2781106, 6.2704898],
          [116.2785491, 6.2704546],
          [116.2787706, 6.2703533],
          [116.2789655, 6.2701332],
          [116.2790497, 6.2698998],
          [116.2787883, 6.2692657],
          [116.2786643, 6.2691865],
          [116.278651, 6.2690676],
          [116.2785668, 6.2689355],
          [116.2782169, 6.2686537],
          [116.2780309, 6.2683763],
          [116.2777872, 6.2682134],
          [116.2776854, 6.2682002],
          [116.2774506, 6.2679844],
          [116.2769279, 6.2679448],
          [116.2768172, 6.2678876],
          [116.2767507, 6.2679184],
          [116.2766223, 6.267848],
          [116.2764938, 6.2678435],
          [116.2763033, 6.2676938],
          [116.2757806, 6.2674429],
          [116.2756743, 6.2673548],
          [116.275506, 6.2673812],
          [116.275475, 6.2672932],
          [116.2752845, 6.2672139],
          [116.2748327, 6.2671214],
          [116.2747441, 6.2670158],
          [116.2746644, 6.2669849],
          [116.2746201, 6.2670202],
          [116.2744429, 6.2668749],
          [116.2742923, 6.267073],
          [116.2741284, 6.2670554],
          [116.2739114, 6.2669541],
          [116.2739025, 6.2668396],
          [116.2739689, 6.2667912],
          [116.273588, 6.2665578],
          [116.2732248, 6.2664742],
          [116.2729058, 6.266461],
          [116.2727951, 6.266505],
          [116.2726799, 6.2663245],
          [116.2724142, 6.266254],
          [116.2719446, 6.2663157],
          [116.2719225, 6.2664522],
          [116.2718295, 6.2666063],
          [116.2716833, 6.2666063],
          [116.2715593, 6.2664698],
          [116.2713156, 6.266483],
          [116.2711296, 6.2664434],
          [116.2709568, 6.2666371],
          [116.2710676, 6.2667736],
          [116.2713112, 6.2668793],
          [116.2714042, 6.2669938],
          [116.2714042, 6.2671479],
          [116.2712669, 6.2672447],
          [116.2711739, 6.2671082],
          [116.2711872, 6.2669541],
          [116.2710454, 6.2669013],
          [116.270886, 6.2669189],
          [116.2707708, 6.2668749],
          [116.2705272, 6.2670246],
          [116.2704164, 6.2669982],
          [116.2703234, 6.2669321],
          [116.270412, 6.2668088],
          [116.2705715, 6.2668],
          [116.2706113, 6.2667164],
          [116.2704829, 6.2666283],
          [116.270629, 6.2665931],
          [116.2704829, 6.2663553],
          [116.2705139, 6.2662716],
          [116.2704031, 6.2662056],
          [116.2697431, 6.266144],
          [116.2696147, 6.2661616],
          [116.2695349, 6.266254],
          [116.2696412, 6.2660163],
          [116.2695039, 6.2658489],
          [116.2689458, 6.2654879],
          [116.268494, 6.2654879],
          [116.2683832, 6.265413],
          [116.2685737, 6.2652986],
          [116.2686047, 6.2651753],
          [116.2684541, 6.2649375],
          [116.2683257, 6.2649287],
          [116.2682725, 6.2648318],
          [116.2681352, 6.2647834],
          [116.2679934, 6.2648318],
          [116.2679491, 6.2646381],
          [116.2678799, 6.2645302],
          [116.2674915, 6.2642282],
          [116.2668526, 6.2640507],
          [116.2665519, 6.2638016],
          [116.2662669, 6.2634342],
          [116.265678, 6.2633969],
          [116.2649513, 6.2632755],
          [116.2645974, 6.2629018],
          [116.2643568, 6.2624938],
          [116.2643155, 6.262276],
          [116.2643249, 6.2620736],
          [116.2642201, 6.2619403],
          [116.2642577, 6.2617473],
          [116.2638975, 6.2615293],
          [116.2634934, 6.261383],
          [116.2631896, 6.2616726],
          [116.2632961, 6.2612989],
          [116.2630102, 6.2610936],
          [116.262654, 6.2610343],
          [116.2624034, 6.261025],
          [116.2620589, 6.2607479],
          [116.261312, 6.260494],
          [116.2602134, 6.259446],
          [116.2595401, 6.2589177],
          [116.2578896, 6.2570426],
          [116.2572823, 6.2571611],
          [116.2571476, 6.2571455],
          [116.2570098, 6.2572078],
          [116.2562769, 6.2572202],
          [116.2557695, 6.2573946],
          [116.2555847, 6.2576219],
          [116.2554187, 6.2573728],
          [116.255331, 6.2573105],
          [116.2547672, 6.2571704],
          [116.254632771015167, 6.257239098344575],
          [116.254432, 6.2573417],
          [116.2541658, 6.2576499],
          [116.2540656, 6.2576499],
          [116.2539685, 6.2576219],
          [116.2543005, 6.2570895],
          [116.2543631, 6.2566007],
          [116.2542786, 6.2564605],
          [116.2540249, 6.2563516],
          [116.2535644, 6.2563578],
          [116.2531228, 6.2564761],
          [116.2528033, 6.2567563],
          [116.2525026, 6.2570957],
          [116.2517979, 6.257323],
          [116.2504103, 6.2571891],
          [116.2500031, 6.2572078],
          [116.2498998, 6.2572483],
          [116.2494863, 6.2579083],
          [116.2493516, 6.2580951],
          [116.2492514, 6.2581481],
          [116.2492169, 6.2581325],
          [116.2493892, 6.2579488],
          [116.2494769, 6.257625],
          [116.2496053, 6.2574008],
          [116.2495584, 6.2573697],
          [116.2496742, 6.2571984],
          [116.2495897, 6.2570365],
          [116.2488724, 6.2570023],
          [116.2485028, 6.2570365],
          [116.2484057, 6.25694],
          [116.2482616, 6.2568902],
          [116.2476728, 6.2565135],
          [116.2474911, 6.2563485],
          [116.2472092, 6.256227],
          [116.2470557, 6.2559748],
          [116.24673, 6.2558783],
          [116.2467832, 6.2559375],
          [116.2465734, 6.2560215],
          [116.2458906, 6.2561243],
          [116.2457246, 6.2564076],
          [116.245521, 6.2564699],
          [116.2455805, 6.2563329],
          [116.2455554, 6.2561834],
          [116.2449688, 6.25588],
          [116.2448049, 6.2557435],
          [116.244703, 6.2557435],
          [116.2442955, 6.2555013],
          [116.2439677, 6.2551886],
          [116.2437772, 6.2549421],
          [116.2436665, 6.2546735],
          [116.243445, 6.2544973],
          [116.243321, 6.2542243],
          [116.2431748, 6.2540262],
          [116.2429179, 6.2539161],
          [116.2425635, 6.2539205],
          [116.2421516, 6.2541407],
          [116.2418459, 6.2539778],
          [116.2415403, 6.2539293],
          [116.2411726, 6.2537928],
          [116.240929, 6.2536255],
          [116.2408183, 6.2536387],
          [116.2407031, 6.2535639],
          [116.2406765, 6.253489],
          [116.240424, 6.2533129],
          [116.240331, 6.2533173],
          [116.2402734, 6.253216],
          [116.2400785, 6.2530971],
          [116.2400387, 6.2529738],
          [116.2399501, 6.252921],
          [116.239795, 6.2525863],
          [116.2395115, 6.2521548],
          [116.2392015, 6.2519303],
          [116.2390154, 6.2518818],
          [116.2386389, 6.2518906],
          [116.2381738, 6.2517541],
          [116.2378903, 6.2516044],
          [116.2375935, 6.2515384],
          [116.2375005, 6.2514415],
          [116.2370974, 6.2513931],
          [116.2369955, 6.2514679],
          [116.2369069, 6.2517189],
          [116.2368582, 6.2517101],
          [116.2369069, 6.2513446],
          [116.2368405, 6.2511685],
          [116.2365969, 6.2509483],
          [116.2365216, 6.2511421],
          [116.2364285, 6.2511201],
          [116.2363665, 6.2511817],
          [116.2362779, 6.251076],
          [116.2360742, 6.2512302],
          [116.2359812, 6.2512125],
          [116.2359679, 6.2510937],
          [116.2358749, 6.2510056],
          [116.2358616, 6.2508118],
          [116.2357863, 6.2506621],
          [116.2356667, 6.2505873],
          [116.2354895, 6.2505653],
          [116.2351351, 6.2505917],
          [116.2351307, 6.2504728],
          [116.2350155, 6.2504596],
          [116.2346036, 6.2505873],
          [116.2348605, 6.2503627],
          [116.2347896, 6.2502438],
          [116.2346877, 6.2501249],
          [116.2341606, 6.2498519],
          [116.2338815, 6.2497683],
          [116.2336645, 6.2497859],
          [116.2335006, 6.2496098],
          [116.23178568041719, 6.248864494264126],
          [116.2288805, 6.2476019],
          [116.2286768, 6.2474698],
          [116.2282073, 6.2473597],
          [116.2278928, 6.2471571],
          [116.2275384, 6.2471175],
          [116.2273878, 6.2471439],
          [116.2271397, 6.2472848],
          [116.2270334, 6.2472628],
          [116.2270467, 6.2472012],
          [116.2271574, 6.2471924],
          [116.2272815, 6.2470867],
          [116.2272992, 6.2469678],
          [116.2272106, 6.2468665],
          [116.2270157, 6.2467564],
          [116.2261564, 6.2464482],
          [116.2255894, 6.2462853],
          [116.225297, 6.2462809],
          [116.2250888, 6.2463778],
          [116.225235, 6.2461796],
          [116.2249781, 6.2460519],
          [116.2234765, 6.2456468],
          [116.2227544, 6.2455279],
          [116.2222317, 6.2453826],
          [116.2220058, 6.245409],
          [116.2211066, 6.2453386],
          [116.2210446, 6.2454619],
          [116.2209206, 6.2455147],
          [116.2208852, 6.2454487],
          [116.2207257, 6.2453914],
          [116.2204156, 6.2454134],
          [116.220048, 6.245343],
          [116.2197157, 6.245343],
          [116.2189627, 6.2451889],
          [116.2188741, 6.2450524],
          [116.2186571, 6.2451096],
          [116.2185729, 6.2450436],
          [116.2187191, 6.2447882],
          [116.2186172, 6.2446957],
          [116.2183913, 6.2445988],
          [116.2181875, 6.2445812],
          [116.2180148, 6.2447001],
          [116.2173858, 6.2445724],
          [116.2168675, 6.2446297],
          [116.2165663, 6.2444535],
          [116.2163626, 6.2444095],
          [116.2159373, 6.2444491],
          [116.2158133, 6.2445592],
          [116.2156981, 6.2447926],
          [116.2155608, 6.2447662],
          [116.2155608, 6.2450876],
          [116.2154944, 6.2452373],
          [116.2153748, 6.2454002],
          [116.2150771, 6.2456661],
          [116.2150904, 6.245785],
          [116.2152233, 6.2457894],
          [116.2153429, 6.245741],
          [116.2154359, 6.2458467],
          [116.2157194, 6.2456749],
          [116.215839, 6.2456573],
          [116.2160826, 6.2458202],
          [116.2162376, 6.2463354],
          [116.2162199, 6.2466789],
          [116.2163705, 6.2467185],
          [116.2163307, 6.2469343],
          [116.2163971, 6.2470311],
          [116.2163439, 6.2471412],
          [116.2164148, 6.2473438],
          [116.2163439, 6.2475023],
          [116.2164148, 6.2475551],
          [116.216499, 6.2477709],
          [116.2163617, 6.2483081],
          [116.2162421, 6.2481892],
          [116.2161933, 6.2477445],
          [116.2157194, 6.2473614],
          [116.2156441, 6.2472117],
          [116.215489, 6.2471676],
          [116.215427, 6.2470267],
          [116.2150948, 6.2468022],
          [116.2151568, 6.246582],
          [116.2150505, 6.2464675],
          [116.2147094, 6.2463839],
          [116.2146607, 6.2462606],
          [116.2145544, 6.2462165],
          [116.2145101, 6.2459832],
          [116.2142488, 6.2455913],
          [116.2140627, 6.2455737],
          [116.2136862, 6.2456221],
          [116.2135489, 6.2455957],
          [116.2133806, 6.2457322],
          [116.2132477, 6.2457718],
          [116.2131458, 6.2457013],
          [116.2131945, 6.2454988],
          [116.2130483, 6.2453095],
          [116.2131325, 6.245239],
          [116.2133628, 6.2451906],
          [116.2134337, 6.2449616],
          [116.2135622, 6.2448779],
          [116.213952, 6.2447458],
          [116.2140096, 6.2446093],
          [116.2140096, 6.2444464],
          [116.2138147, 6.2443892],
          [116.2132654, 6.2444288],
          [116.2126453, 6.2447899],
          [116.2125611, 6.2449132],
          [116.2125079, 6.245217],
          [116.2124105, 6.2453315],
          [116.2123839, 6.2455296],
          [116.2124415, 6.2458599],
          [116.2123042, 6.246014],
          [116.2120074, 6.2462121],
          [116.2118346, 6.2460008],
          [116.2116043, 6.245992],
          [116.2112101, 6.2458731],
          [116.2110019, 6.2458907],
          [116.210745, 6.2450364],
          [116.2107405, 6.2446886],
          [116.2106121, 6.2445037],
          [116.2104659, 6.2444728],
          [116.2106298, 6.2443936],
          [116.2106785, 6.244169],
          [116.2106874, 6.2437727],
          [116.2105766, 6.2432179],
          [116.2106165, 6.2430241],
          [116.2108601, 6.2428612],
          [116.2106874, 6.2427952],
          [116.2108823, 6.2426499],
          [116.2110329, 6.2424341],
          [116.2113297, 6.2422007],
          [116.2115113, 6.2421655],
          [116.2116575, 6.2421875],
          [116.2117992, 6.24228],
          [116.2120251, 6.242302],
          [116.2122599, 6.2423857],
          [116.2126054, 6.2428172],
          [116.2126142, 6.2430285],
          [116.2127737, 6.243372],
          [116.2128579, 6.2434336],
          [116.2130439, 6.2433984],
          [116.2131768, 6.2432795],
          [116.2133407, 6.2432971],
          [116.2135312, 6.2434116],
          [116.2136862, 6.2437199],
          [116.213921, 6.2436274],
          [116.2138944, 6.2437595],
          [116.2140007, 6.2438564],
          [116.2141424, 6.2438343],
          [116.2142576, 6.243601],
          [116.2141735, 6.2434953],
          [116.2139121, 6.2428788],
          [116.2135445, 6.2426807],
          [116.2130395, 6.2425398],
          [116.2127826, 6.2423504],
          [116.2119675, 6.2421611],
          [116.2118125, 6.2420906],
          [116.2116929, 6.2419629],
          [116.2114315, 6.2418881],
          [116.2105102, 6.2418088],
          [116.210209, 6.241756],
          [116.2099609, 6.2416591],
          [116.2091636, 6.2415358],
          [116.2090041, 6.2414257],
          [116.2088092, 6.2411703],
          [116.208539, 6.2407124],
          [116.2082733, 6.2404834],
          [116.2078259, 6.2403821],
          [116.2075557, 6.2404262],
          [116.2068647, 6.240708],
          [116.2067805, 6.2409898],
          [116.2066476, 6.2418132],
          [116.206714, 6.2424033],
          [116.2071836, 6.2432927],
          [116.2076487, 6.2438167],
          [116.2088402, 6.2449264],
          [116.2099166, 6.2456177],
          [116.2104526, 6.2460492],
          [116.211055, 6.2464015],
          [116.2117505, 6.2468946],
          [116.2123972, 6.2471544],
          [116.2133717, 6.2478061],
          [116.215159143102994, 6.248864494264126],
          [116.2156175, 6.2491359],
          [116.2162908, 6.2496643],
          [116.2171014, 6.2502059],
          [116.219263, 6.2515092],
          [116.2199275, 6.2519628],
          [116.220149, 6.252227],
          [116.2227447, 6.2540455],
          [116.2234534, 6.2546135],
          [116.2239584, 6.2551023],
          [116.2246361, 6.2555514],
          [116.2259473, 6.2566258],
          [116.2267712, 6.2571586],
          [116.2275065, 6.2577838],
          [116.2279805, 6.2581229],
          [116.228419, 6.2586865],
          [116.2303458, 6.2607428],
          [116.2310324, 6.2615573],
          [116.2319582, 6.2628431],
          [116.2327378, 6.2642829],
          [116.2330169, 6.2650534],
          [116.2331498, 6.2655642],
          [116.2332871, 6.2662951],
          [116.2333137, 6.267277],
          [116.2330257, 6.2684086],
          [116.2328486, 6.2685407],
          [116.2327422, 6.268457],
          [116.2325916, 6.2685451],
          [116.2324809, 6.268664],
          [116.2323392, 6.2687256],
          [116.2323214, 6.2689062],
          [116.2322196, 6.268915],
          [116.2321044, 6.2688269],
          [116.2318475, 6.268981],
          [116.2317367, 6.269003],
          [116.2316526, 6.2690823],
          [116.2312672, 6.2691131],
          [116.231121, 6.2692056],
          [116.230904, 6.2690955],
          [116.2306825, 6.2690647],
          [116.2303016, 6.2691043],
          [116.2301864, 6.2692408],
          [116.2301509, 6.2694301],
          [116.2299383, 6.2694874],
          [116.2296283, 6.2694301],
          [116.2293758, 6.2696062],
          [116.2293891, 6.2697736],
          [116.2295751, 6.2699937],
          [116.2301687, 6.2716713],
          [116.2306293, 6.272389],
          [116.2314001, 6.2733665],
          [116.231471, 6.2735118],
          [116.2314444, 6.2746257],
          [116.2316526, 6.2749251],
          [116.2319538, 6.2752113],
          [116.2337478, 6.2760919],
          [116.2342306, 6.2764354],
          [116.2344609, 6.2767128],
          [116.2348596, 6.2774789],
          [116.2348552, 6.2777695],
          [116.2343192, 6.277884],
          [116.2344565, 6.2780557],
          [116.2343325, 6.2781394],
          [116.2343325, 6.2783551],
          [116.234235, 6.2785092],
          [116.2343945, 6.2788086],
          [116.2346071, 6.27902],
          [116.2348862, 6.2790948],
          [116.2351785, 6.2790464],
          [116.2355639, 6.2788394],
          [116.235998, 6.2788394],
          [116.2362948, 6.2789759],
          [116.2364808, 6.2792093],
          [116.2364409, 6.279403],
          [116.2365561, 6.2794999],
          [116.2369902, 6.2796628],
          [116.2378097, 6.2798081],
          [116.2379869, 6.2799578],
          [116.2381286, 6.280266],
          [116.2383545, 6.2803893],
          [116.2387709, 6.2803585],
          [116.2390898, 6.280539],
          [116.2398872, 6.2808384],
          [116.2403611, 6.2809353],
          [116.2408971, 6.2811774],
          [116.2415704, 6.2815649],
          [116.2420045, 6.2819479],
          [116.2419159, 6.2820888],
          [116.2419159, 6.2822209],
          [116.2421905, 6.282648],
          [116.242288, 6.2829386],
          [116.2420621, 6.2835154],
          [116.2420532, 6.2836827],
          [116.2419602, 6.2837972],
          [116.2417432, 6.2838809],
          [116.2416546, 6.2839733],
          [116.2415128, 6.2839645],
          [116.2414907, 6.2840966],
          [116.2419602, 6.2843476],
          [116.2421285, 6.2842815],
          [116.2422791, 6.2841406],
          [116.2424164, 6.2840966],
          [116.2426955, 6.2840394],
          [116.2431252, 6.284057],
          [116.2433467, 6.2841847],
          [116.2436479, 6.2846954],
          [116.2439934, 6.2848847],
          [116.2446888, 6.2851137],
          [116.2454817, 6.2854483],
          [116.2464385, 6.2853691],
          [116.246691, 6.285259],
          [116.2469568, 6.2852282],
          [116.2472934, 6.2850432],
          [116.2475105, 6.2850961],
          [116.2480996, 6.2854043],
          [116.2492823, 6.2856288],
          [116.2493709, 6.2857433],
          [116.2492823, 6.285849],
          [116.2494816, 6.2859634],
          [116.2498138, 6.2859723],
          [116.250589, 6.285827],
          [116.2510586, 6.2856024],
          [116.2511737, 6.2854923],
          [116.25128, 6.2852854],
          [116.2514484, 6.2851577],
          [116.2518249, 6.2850961],
          [116.2520198, 6.2851489],
          [116.2522102, 6.285259],
          [116.252321, 6.2853779],
          [116.2523343, 6.2854967],
          [116.2531095, 6.28562],
          [116.2533221, 6.2857433],
          [116.2540485, 6.2856861],
          [116.2543364, 6.2858093],
          [116.254632771015167, 6.285997259689291],
          [116.2552667, 6.2863993],
          [116.2555413, 6.2867296],
          [116.2556963, 6.2870246],
          [116.2561747, 6.2874604],
          [116.2567417, 6.287663],
          [116.2571315, 6.2877334],
          [116.2574859, 6.2879007],
          [116.2588147, 6.2888606],
          [116.2590938, 6.2896707],
          [116.2589565, 6.289838],
          [116.2590141, 6.2899921],
          [116.2591027, 6.290089],
          [116.2591913, 6.2900053],
          [116.2591913, 6.2898248],
          [116.2593552, 6.2897103],
          [116.2595058, 6.2894506],
          [116.2598513, 6.2891864],
          [116.2602322, 6.2887065],
          [116.2605866, 6.2885612],
          [116.2608524, 6.2883631],
          [116.2611934, 6.2883278],
          [116.2615567, 6.2883322],
          [116.2617073, 6.2883851],
          [116.2617117, 6.2885216],
          [116.2618136, 6.2886448],
          [116.261973, 6.288658],
          [116.2620661, 6.2885039],
          [116.2621989, 6.2880548],
          [116.2622875, 6.2878963],
          [116.2624071, 6.2877907],
          [116.2625843, 6.2877598],
          [116.2632975, 6.2878259],
          [116.2640594, 6.2881737],
          [116.2643606, 6.2881913],
          [116.2645732, 6.288253],
          [116.2650427, 6.288636],
          [116.2652731, 6.2886977],
          [116.2658489, 6.2885744],
          [116.2660704, 6.288592],
          [116.2662742, 6.2884247],
          [116.2668234, 6.2883322],
          [116.2675587, 6.2885788],
          [116.2677492, 6.2887901],
          [116.2680726, 6.2887857],
          [116.2682896, 6.2887197],
          [116.2684136, 6.2884335],
          [116.2685687, 6.288341],
          [116.2687414, 6.2883014],
          [116.2688788, 6.2883278],
          [116.2690958, 6.2881605],
          [116.2693129, 6.2882001],
          [116.2706329, 6.288636],
          [116.2712043, 6.2893229],
          [116.2713593, 6.2893757],
          [116.2721522, 6.289455],
          [116.2724357, 6.2896047],
          [116.2727325, 6.2896795],
          [116.2730293, 6.2899129],
          [116.273326, 6.2900141],
          [116.2737734, 6.2902563],
          [116.2741322, 6.2905337],
          [116.274491, 6.290657],
          [116.2754257, 6.2914451],
          [116.2756959, 6.2917665],
          [116.2757889, 6.2919822],
          [116.275718, 6.2921275],
          [116.2758553, 6.2922112],
          [116.2759351, 6.2924754],
          [116.27635618120928, 6.292675506323576],
          [116.2768431, 6.2929069],
          [116.2779195, 6.2936553],
          [116.2782916, 6.2939856],
          [116.2792838, 6.2950731],
          [116.2805285, 6.2971556],
          [116.2809006, 6.2980186],
          [116.2811443, 6.298745],
          [116.281162, 6.2992117],
          [116.2811132, 6.2997401],
          [116.2809892, 6.3004225],
          [116.2806437, 6.3011314],
          [116.2802451, 6.3023245],
          [116.2800767, 6.3024963],
          [116.2798375, 6.3024654],
          [116.2796869, 6.3025667],
          [116.2795895, 6.3027032],
          [116.279461, 6.3027516],
          [116.2793591, 6.3030026],
          [116.2792041, 6.3031215],
          [116.278863, 6.3031435],
          [116.2786017, 6.3033592],
          [116.2782296, 6.3033768],
          [116.2780701, 6.3033064],
          [116.2776848, 6.3032623],
          [116.2774411, 6.3030686],
          [116.2773525, 6.3028044],
          [116.2772374, 6.3028573],
          [116.2771621, 6.3031743],
          [116.2771754, 6.303412],
          [116.2770823, 6.3037114],
          [116.2771488, 6.3037599],
          [116.2770823, 6.3042662],
          [116.2771089, 6.3044599],
          [116.2772108, 6.3046712],
          [116.2772418, 6.3049794],
          [116.277357, 6.3051996],
          [116.2776449, 6.3055826],
          [116.2778044, 6.3056971],
          [116.2780347, 6.3061153],
          [116.2783093, 6.3061858],
          [116.2785131, 6.3064323],
          [116.2787257, 6.3065512],
          [116.2787789, 6.306833],
          [116.2791377, 6.3072337],
          [116.2794654, 6.3069299],
          [116.279523, 6.3066657],
          [116.2797002, 6.3063223],
          [116.2798375, 6.3061418],
          [116.2799217, 6.3059392],
          [116.2800989, 6.3057675],
          [116.2803026, 6.3056707],
          [116.2804577, 6.3056971],
          [116.280812, 6.3056266],
          [116.2808873, 6.3055254],
          [116.2810335, 6.3054505],
          [116.281348, 6.3053581],
          [116.2814809, 6.3051996],
          [116.2817112, 6.3051599],
          [116.2818264, 6.3049618],
          [116.2822915, 6.3046624],
          [116.2828098, 6.3041033],
          [116.2828939, 6.3039624],
          [116.2829471, 6.3037334],
          [116.283018, 6.303663],
          [116.2833413, 6.3037158],
          [116.2843158, 6.3041341],
          [116.2848562, 6.3044247],
          [116.2863844, 6.3056178],
          [116.2869116, 6.3061242],
          [116.2872482, 6.3066129],
          [116.2875494, 6.3072028],
          [116.2877443, 6.3079029],
          [116.2877, 6.3088803],
          [116.2875804, 6.3090564],
          [116.2872659, 6.3092193],
          [116.2871596, 6.3094835],
          [116.2871552, 6.3097784],
          [116.2872305, 6.3100514],
          [116.287421, 6.3101791],
          [116.2876159, 6.3103948],
          [116.2880898, 6.3113282],
          [116.2885727, 6.3116012],
          [116.2888606, 6.3119226],
          [116.2889713, 6.3122132],
          [116.2890068, 6.3125434],
          [116.2891042, 6.312561],
          [116.2891884, 6.3127635],
          [116.2892371, 6.3133182],
          [116.2891352, 6.3135076],
          [116.2890511, 6.313578],
          [116.2887321, 6.3136528],
          [116.2886037, 6.3136572],
          [116.2884796, 6.3135956],
          [116.288236, 6.3136749],
          [116.2882626, 6.3137893],
          [116.2884708, 6.3141327],
          [116.2885594, 6.3144013],
          [116.2891175, 6.3149164],
          [116.2894896, 6.3156605],
          [116.2895029, 6.3160831],
          [116.2894586, 6.3161184],
          [116.289401, 6.3160611],
          [116.2893035, 6.3161096],
          [116.2890688, 6.316246],
          [116.288865, 6.316431],
          [116.288772, 6.3164574],
          [116.2886524, 6.3168492],
          [116.2886081, 6.3176725],
          [116.2888074, 6.3182933],
          [116.2889137, 6.3183417],
          [116.2892592, 6.3187115],
          [116.2892592, 6.3189977],
          [116.2893877, 6.3192707],
          [116.2896446, 6.3195789],
          [116.2898218, 6.3196933],
          [116.290247, 6.3196845],
          [116.290721, 6.3195657],
          [116.2915892, 6.3191342],
          [116.2916955, 6.3189845],
          [116.2917265, 6.3186807],
          [116.2916512, 6.318553],
          [116.2915759, 6.318082],
          [116.2916335, 6.3175889],
          [116.2917221, 6.3173555],
          [116.2918638, 6.317197],
          [116.2923998, 6.3170077],
          [116.292577, 6.3168184],
          [116.2927498, 6.3164618],
          [116.2930111, 6.3162416],
          [116.2934496, 6.3159819],
          [116.2941406, 6.3150353],
          [116.2946412, 6.3145598],
          [116.295031, 6.3143925],
          [116.2950709, 6.3142428],
          [116.2953632, 6.3141548],
          [116.2956511, 6.3141944],
          [116.2958593, 6.3143309],
          [116.2959745, 6.3144982],
          [116.2962403, 6.3145158],
          [116.2964839, 6.3146038],
          [116.2966744, 6.3147271],
          [116.296568, 6.314551],
          [116.2965769, 6.3143661],
          [116.2966478, 6.3140491],
          [116.2967629, 6.3139434],
          [116.296887, 6.313578],
          [116.2967364, 6.3133226],
          [116.2967851, 6.3130497],
          [116.2966123, 6.3128736],
          [116.2966212, 6.3126314],
          [116.2967142, 6.31231],
          [116.2967489, 6.3120435],
          [116.2968142, 6.311895],
          [116.2968189, 6.3116956],
          [116.2968515, 6.3113848],
          [116.2969402, 6.3111854],
          [116.2970523, 6.3110692],
          [116.2970895, 6.3109674],
          [116.2972389, 6.3108097],
          [116.2975235, 6.3105267],
          [116.2975982, 6.3104711],
          [116.2976729, 6.310485],
          [116.2977475, 6.310369],
          [116.2978543, 6.3102369],
          [116.2978175, 6.3101139],
          [116.2977382, 6.3100443],
          [116.2977335, 6.3099098],
          [116.2976169, 6.3097985],
          [116.2975002, 6.3096501],
          [116.2973929, 6.309548],
          [116.2973275, 6.3094089],
          [116.2972622, 6.3093347],
          [116.2972575, 6.309247],
          [116.2971549, 6.3092883],
          [116.2969355, 6.3091909],
          [116.2968842, 6.3092697],
          [116.2968889, 6.3094135],
          [116.2967722, 6.3094042],
          [116.2966835, 6.3095248],
          [116.2964222, 6.3096083],
          [116.2961842, 6.3095758],
          [116.2959781, 6.3095232],
          [116.2958435, 6.3094089],
          [116.2956569, 6.3094784],
          [116.2954469, 6.3094645],
          [116.2952415, 6.3093207],
          [116.2950857, 6.3090282],
          [116.2949335, 6.3088244],
          [116.2946955, 6.3083328],
          [116.2945182, 6.3078504],
          [116.2944575, 6.3075025],
          [116.2944357, 6.3071811],
          [116.2944482, 6.3070247],
          [116.2946442, 6.3065655],
          [116.2948449, 6.3062548],
          [116.2948423, 6.3061318],
          [116.2947049, 6.305995],
          [116.2945929, 6.3057816],
          [116.2945695, 6.3053039],
          [116.2941962, 6.3050395],
          [116.2940655, 6.3047194],
          [116.2940607, 6.3044335],
          [116.2945509, 6.3028177],
          [116.2947655, 6.3018436],
          [116.2948729, 6.301338],
          [116.2948682, 6.3010365],
          [116.294859, 6.3008278],
          [116.2947375, 6.3007907],
          [116.2946955, 6.3008788],
          [116.2946442, 6.3013241],
          [116.2945695, 6.3016488],
          [116.2944755, 6.3022207],
          [116.2942102, 6.3029707],
          [116.2940049, 6.3036387],
          [116.2936222, 6.3037268],
          [116.2933422, 6.3026089],
          [116.2928942, 6.3025023],
          [116.2923202, 6.3022193],
          [116.2920813, 6.3019207],
          [116.2919329, 6.3015328],
          [116.2918349, 6.3009298],
          [116.2917835, 6.3007536],
          [116.2918069, 6.3006283],
          [116.2917182, 6.300415],
          [116.2916109, 6.3002062],
          [116.292148, 6.2995638],
          [116.2920589, 6.2991997],
          [116.2920729, 6.2989492],
          [116.2920262, 6.2987961],
          [116.2920822, 6.298592],
          [116.2920215, 6.2984575],
          [116.2920915, 6.2981792],
          [116.2921399, 6.2979278],
          [116.2920869, 6.2978452],
          [116.2921615, 6.2977432],
          [116.2922315, 6.2974927],
          [116.2922035, 6.2973675],
          [116.2923202, 6.2973211],
          [116.2924135, 6.2971587],
          [116.2924695, 6.2969825],
          [116.292728, 6.296822],
          [116.2928849, 6.2968016],
          [116.2929595, 6.2969593],
          [116.2930202, 6.2970706],
          [116.2930995, 6.2972144],
          [116.2932442, 6.2973072],
          [116.2933189, 6.2974231],
          [116.2935102, 6.2974788],
          [116.2935289, 6.2975437],
          [116.2937575, 6.2976319],
          [116.2937202, 6.2977432],
          [116.2937855, 6.2978452],
          [116.2939395, 6.2980029],
          [116.2939675, 6.2981931],
          [116.2940329, 6.2983184],
          [116.2942436, 6.2984124],
          [116.2944342, 6.2985132],
          [116.2945042, 6.2984111],
          [116.2946115, 6.2984668],
          [116.2945415, 6.2985317],
          [116.2945975, 6.2986199],
          [116.2947329, 6.298708],
          [116.2948775, 6.2987451],
          [116.2950838, 6.2987294],
          [116.2952602, 6.2987219],
          [116.2953395, 6.2985828],
          [116.2953069, 6.2985456],
          [116.2953722, 6.2984575],
          [116.2954002, 6.298374],
          [116.2953769, 6.2982627],
          [116.2953815, 6.2980632],
          [116.2954469, 6.2980772],
          [116.2954889, 6.2980076],
          [116.2955309, 6.2979473],
          [116.2955402, 6.2978638],
          [116.2955729, 6.2977571],
          [116.2958304, 6.2976342],
          [116.2966182, 6.2973025],
          [116.2969773, 6.29714],
          [116.2972389, 6.2970289],
          [116.2975049, 6.2964351],
          [116.2979109, 6.2964954],
          [116.2981395, 6.2968851],
          [116.2984195, 6.2971958],
          [116.298668, 6.2973702],
          [116.2988068, 6.2975298],
          [116.2992222, 6.2974185],
          [116.2992175, 6.2971124],
          [116.2993668, 6.2968572],
          [116.3000113, 6.2966394],
          [116.3002302, 6.2964305],
          [116.3004402, 6.2962589],
          [116.3006082, 6.2961707],
          [116.3008512, 6.2962598],
          [116.3011448, 6.2961893],
          [116.3013222, 6.2959898],
          [116.3014622, 6.2958924],
          [116.3016162, 6.2957533],
          [116.3017562, 6.2957394],
          [116.3019382, 6.2959249],
          [116.3020828, 6.2960455],
          [116.3024515, 6.2961429],
          [116.3027267, 6.2962118],
          [116.3030535, 6.2963609],
          [116.3032868, 6.2963563],
          [116.3035342, 6.2964166],
          [116.3035995, 6.2965975],
          [116.3038328, 6.2967784],
          [116.3040148, 6.296899],
          [116.3043224, 6.2972714],
          [116.3045655, 6.2975391],
          [116.3047755, 6.2977246],
          [116.3053005, 6.2978339],
          [116.3056202, 6.297836],
          [116.3060355, 6.2977571],
          [116.3072384, 6.2972552],
          [116.3079304, 6.2972384],
          [116.3087655, 6.2976968],
          [116.309679, 6.2990974],
          [116.3102868, 6.3001181],
          [116.3108661, 6.3005943],
          [116.3107255, 6.3012963],
          [116.3100688, 6.3017919],
          [116.3099812, 6.3022299],
          [116.3101468, 6.3025023],
          [116.3100628, 6.3027527],
          [116.3103615, 6.3030403],
          [116.3106312, 6.3041048],
          [116.3108314, 6.3041133],
          [116.3110335, 6.3035969],
          [116.3109308, 6.3030496],
          [116.3108468, 6.3026229],
          [116.3111103, 6.3022361],
          [116.3119295, 6.3016581],
          [116.3123588, 6.301389],
          [116.3136095, 6.3009808],
          [116.3138811, 6.3007118],
          [116.3139641, 6.3001088],
          [116.3141644, 6.299501],
          [116.3141041, 6.2992924],
          [116.3142348, 6.298977],
          [116.3144028, 6.2987915],
          [116.3148228, 6.2986987],
          [116.3153268, 6.2988379],
          [116.3156445, 6.2987596],
          [116.3158681, 6.2985596],
          [116.3162975, 6.2982813],
          [116.316812, 6.2985684],
          [116.3171153, 6.2986659],
          [116.3174406, 6.2987118],
          [116.3181933, 6.2984386],
          [116.319187, 6.2983219],
          [116.3204877, 6.2986152],
          [116.3210913, 6.2987076],
          [116.3213106, 6.2991158],
          [116.3220874, 6.2994771],
          [116.3229066, 6.3004331],
          [116.3231354, 6.3011481],
          [116.3236626, 6.3017551],
          [116.3240126, 6.3014536],
          [116.3245119, 6.3016994],
          [116.3246653, 6.301789],
          [116.3246426, 6.3023859],
          [116.3247499, 6.3030121],
          [116.3245728, 6.3032951],
          [116.3246613, 6.3038377],
          [116.3243673, 6.3044036],
          [116.3238528, 6.3044077],
          [116.3233313, 6.3046958],
          [116.3228039, 6.3049324],
          [116.3224166, 6.3049463],
          [116.3221879, 6.304951],
          [116.3220199, 6.3048396],
          [116.3218613, 6.3050298],
          [116.321665, 6.3049451],
          [116.3216979, 6.305336],
          [116.3216233, 6.3055354],
          [116.3217119, 6.3056653],
          [116.3216466, 6.3058601],
          [116.3217166, 6.3059992],
          [116.3216559, 6.3061338],
          [116.3217026, 6.3062868],
          [116.3216793, 6.3064399],
          [116.3215999, 6.306593],
          [116.321817, 6.3068807],
          [116.3223653, 6.3069687],
          [116.3228899, 6.3068482],
          [116.3229719, 6.3067646],
          [116.3235366, 6.3067136],
          [116.3237326, 6.3064863],
          [116.3239799, 6.3063379],
          [116.3241666, 6.3060549],
          [116.3244139, 6.3058091],
          [116.3246786, 6.3054457],
          [116.3248293, 6.3055029],
          [116.3253379, 6.3058369],
          [116.3257113, 6.3060178],
          [116.3260893, 6.3061616],
          [116.3262526, 6.3060085],
          [116.3264673, 6.3060317],
          [116.3266363, 6.30581],
          [116.3267893, 6.3058415],
          [116.3272793, 6.3062219],
          [116.3275499, 6.3065373],
          [116.3280276, 6.3073514],
          [116.3280399, 6.3075253],
          [116.3281426, 6.3076598],
          [116.3282079, 6.3078453],
          [116.3280726, 6.3080819],
          [116.3278953, 6.3082535],
          [116.3277542, 6.3083961],
          [116.3279699, 6.3085365],
          [116.3281986, 6.3086014],
          [116.3284739, 6.3085828],
          [116.3286839, 6.3085318],
          [116.3288986, 6.3082953],
          [116.3289546, 6.3081561],
          [116.3290293, 6.3079938],
          [116.3291413, 6.3078036],
          [116.3290946, 6.3076181],
          [116.3292206, 6.3073537],
          [116.3290717, 6.3071186],
          [116.3288799, 6.3067878],
          [116.3289919, 6.3065327],
          [116.3292533, 6.3064492],
          [116.3294866, 6.3065605],
          [116.3296219, 6.3065327],
          [116.3297853, 6.3063889],
          [116.3297433, 6.306695],
          [116.3299486, 6.3067924],
          [116.3302566, 6.3068295],
          [116.3305039, 6.3066347],
          [116.3306859, 6.306426],
          [116.3311059, 6.3064445],
          [116.3315399, 6.3065141],
          [116.3319972, 6.3065048],
          [116.332514, 6.3066676],
          [116.3328139, 6.3068342],
          [116.3330939, 6.3070985],
          [116.3335279, 6.3074928],
          [116.3333926, 6.3076923],
          [116.3329166, 6.3080587],
          [116.3325292, 6.3082535],
          [116.3324527, 6.3082813],
          [116.3323768, 6.3084092],
          [116.3319918, 6.3085799],
          [116.3317234, 6.3090095],
          [116.3316871, 6.3093571],
          [116.3322184, 6.3099081],
          [116.3323999, 6.3103214],
          [116.332357, 6.3106034],
          [116.3320402, 6.310797],
          [116.3317003, 6.3109446],
          [116.3315287, 6.3111348],
          [116.3312482, 6.3111348],
          [116.3308951, 6.3110889],
          [116.3306321, 6.3111321],
          [116.3303903, 6.3113349],
          [116.3303639, 6.3115349],
          [116.3304067, 6.311724],
          [116.33042, 6.3119875],
          [116.330387, 6.3124893],
          [116.3309938, 6.3132198],
          [116.3305738, 6.3140455],
          [116.3301072, 6.3141382],
          [116.3298178, 6.3144629],
          [116.3294725, 6.3142495],
          [116.3298458, 6.3136744],
          [116.3300418, 6.3131827],
          [116.3300183, 6.3129104],
          [116.3296312, 6.3130343],
          [116.3290712, 6.3138785],
          [116.3286605, 6.3143423],
          [116.3283262, 6.314509],
          [116.3279232, 6.314333],
          [116.3277365, 6.3143609],
          [116.3272232, 6.3141475],
          [116.3268218, 6.3143238],
          [116.3265232, 6.3142495],
          [116.3261906, 6.3142512],
          [116.3254778, 6.3138599],
          [116.3252445, 6.3138692],
          [116.3248688, 6.3136215],
          [116.3246658, 6.3138321],
          [116.3244206, 6.3140492],
          [116.3241429, 6.314114],
          [116.3236392, 6.3140547],
          [116.3234618, 6.3137764],
          [116.3232005, 6.3136002],
          [116.3229731, 6.3132556],
          [116.3227152, 6.3132198],
          [116.3225658, 6.3133868],
          [116.3224538, 6.3134054],
          [116.3222814, 6.3133344],
          [116.3221738, 6.3136187],
          [116.3219872, 6.3140547],
          [116.3215484, 6.314036],
          [116.3213058, 6.3139898],
          [116.3209219, 6.3138331],
          [116.3205218, 6.3138414],
          [116.3202978, 6.3140826],
          [116.3200163, 6.3142791],
          [116.3196538, 6.3142217],
          [116.3193645, 6.3140269],
          [116.3191405, 6.3140547],
          [116.3189818, 6.3138878],
          [116.3187909, 6.3137693],
          [116.3185152, 6.3139991],
          [116.3185597, 6.3143514],
          [116.3187018, 6.3144907],
          [116.3187205, 6.3147319],
          [116.3188792, 6.3150288],
          [116.3189735, 6.3152094],
          [116.3196445, 6.3157802],
          [116.3203133, 6.3162698],
          [116.3205965, 6.3165502],
          [116.3213152, 6.317027],
          [116.3213152, 6.3175613],
          [116.3212872, 6.3180622],
          [116.3211472, 6.3184797],
          [116.3211818, 6.3187131],
          [116.3214458, 6.319092],
          [116.3218822, 6.3193684],
          [116.3217725, 6.3198526],
          [116.3216325, 6.3201866],
          [116.3215325, 6.3206356],
          [116.3212685, 6.3205855],
          [116.3209512, 6.3207525],
          [116.3207552, 6.3210957],
          [116.3208112, 6.3214204],
          [116.3211378, 6.3216523],
          [116.3208485, 6.3216152],
          [116.3205685, 6.3218378],
          [116.3202138, 6.3223573],
          [116.319985, 6.3228708],
          [116.3195325, 6.3233406],
          [116.3189761, 6.3237244],
          [116.318724, 6.3235275],
          [116.3185058, 6.3234612],
          [116.3182912, 6.3233777],
          [116.3172739, 6.3240827],
          [116.3161954, 6.3245556],
          [116.3155083, 6.3246332],
          [116.3151085, 6.324528],
          [116.3147539, 6.324528],
          [116.3144272, 6.3243889],
          [116.3143525, 6.3242312],
          [116.3139885, 6.3242404],
          [116.3137531, 6.3240123],
          [116.31301, 6.3244395],
          [116.3125792, 6.3245187],
          [116.3122893, 6.3244219],
          [116.3119819, 6.3241941],
          [116.3119259, 6.3239529],
          [116.3116552, 6.3237766],
          [116.3114231, 6.3237287],
          [116.3113565, 6.3235818],
          [116.3111699, 6.3234983],
          [116.3109739, 6.3233963],
          [116.3108899, 6.3232386],
          [116.3107965, 6.3228026],
          [116.310673, 6.3224533],
          [116.3105819, 6.3222553],
          [116.3101805, 6.321912],
          [116.3099093, 6.3216922],
          [116.3095739, 6.3216059],
          [116.3093125, 6.3216801],
          [116.3091072, 6.3219491],
          [116.309127, 6.322161],
          [116.3091539, 6.3224593],
          [116.3092659, 6.322849],
          [116.3092099, 6.3230902],
          [116.3092099, 6.3232386],
          [116.3093618, 6.323681],
          [116.3095832, 6.3239621],
          [116.3098445, 6.3240085],
          [116.3100779, 6.3241384],
          [116.3104472, 6.3241771],
          [116.3107965, 6.3243518],
          [116.3109272, 6.3246764],
          [116.3111045, 6.324862],
          [116.3113939, 6.3250011],
          [116.3116924, 6.3251892],
          [116.3118792, 6.3253536],
          [116.3120192, 6.3256783],
          [116.3121779, 6.3259473],
          [116.3125324, 6.3260773],
          [116.3127005, 6.3260865],
          [116.3130459, 6.3259566],
          [116.3133046, 6.326029],
          [116.3136525, 6.3262163],
          [116.3139512, 6.3265225],
          [116.3142032, 6.3268008],
          [116.3143966, 6.3270828],
          [116.3145859, 6.3272646],
          [116.3148939, 6.3272924],
          [116.3151976, 6.3271413],
          [116.3154352, 6.3270976],
          [116.3157152, 6.327311],
          [116.3159485, 6.32758],
          [116.3160045, 6.3277933],
          [116.3163158, 6.3279226],
          [116.3168072, 6.3280345],
          [116.3171619, 6.3280716],
          [116.3175072, 6.3283314],
          [116.3177498, 6.3285447],
          [116.3182165, 6.3286468],
          [116.3185764, 6.3286994],
          [116.3194765, 6.3289251],
          [116.3200552, 6.3290085],
          [116.3203818, 6.3290364],
          [116.3208238, 6.3292823],
          [116.3211192, 6.329528],
          [116.3215765, 6.3297228],
          [116.3218565, 6.3299547],
          [116.3220996, 6.3301553],
          [116.3224165, 6.330298],
          [116.3227338, 6.3303722],
          [116.3231575, 6.3306901],
          [116.3233872, 6.3309659],
          [116.3237512, 6.331439],
          [116.3239752, 6.3318564],
          [116.3240943, 6.3324895],
          [116.3247626, 6.3327514],
          [116.3252352, 6.3329232],
          [116.3255898, 6.3332108],
          [116.3257952, 6.3334983],
          [116.3262058, 6.3338601],
          [116.3268284, 6.3339368],
          [116.3271952, 6.3341106],
          [116.3279045, 6.3346022],
          [116.3283058, 6.3346022],
          [116.3286792, 6.3346671],
          [116.3289146, 6.3350203],
          [116.3293325, 6.3352237],
          [116.3295752, 6.3354835],
          [116.3296498, 6.3357339],
          [116.3296325, 6.3362162],
          [116.329546667461386, 6.336486518383026],
          [116.3293232, 6.3371903],
          [116.3287793, 6.3380346],
          [116.3284458, 6.338591],
          [116.3282125, 6.3389342],
          [116.3278112, 6.3395001],
          [116.3273264, 6.3399893],
          [116.3268592, 6.3402422],
          [116.3264629, 6.3403647],
          [116.3258232, 6.3403535],
          [116.3254872, 6.3401958],
          [116.3252406, 6.3399014],
          [116.3251325, 6.3395743],
          [116.3250578, 6.339194],
          [116.3247758, 6.3390431],
          [116.3245725, 6.3391754],
          [116.3242085, 6.3396021],
          [116.3238818, 6.3399175],
          [116.3234993, 6.3402182],
          [116.3228085, 6.3402329],
          [116.3225004, 6.3401478],
          [116.3222392, 6.3405205],
          [116.3216034, 6.3409402],
          [116.3211098, 6.3411976],
          [116.3207445, 6.3411107],
          [116.3201858, 6.3414295],
          [116.3197791, 6.3416235],
          [116.3193498, 6.3417162],
          [116.319135, 6.3416621],
          [116.3189112, 6.3417904],
          [116.3184962, 6.3417995],
          [116.3183325, 6.3416235],
          [116.3181458, 6.3414008],
          [116.3174042, 6.3413981],
          [116.3171285, 6.3411597],
          [116.3168858, 6.3411411],
          [116.3165965, 6.3413081],
          [116.3163411, 6.3412748],
          [116.3160272, 6.3409463],
          [116.3157192, 6.340835],
          [116.3153832, 6.3405845],
          [116.3151362, 6.3401438],
          [116.3149072, 6.3399909],
          [116.3146925, 6.3397218],
          [116.3142818, 6.339694],
          [116.3140742, 6.3395592],
          [116.3138618, 6.3393786],
          [116.3137312, 6.3394899],
          [116.3136285, 6.339694],
          [116.3133392, 6.3397868],
          [116.3131353, 6.3397927],
          [116.3129658, 6.339694],
          [116.3127792, 6.3395641],
          [116.3125085, 6.3395363],
          [116.3122344, 6.3396489],
          [116.3120512, 6.3401578],
          [116.3121165, 6.3404547],
          [116.3123872, 6.3406309],
          [116.3126952, 6.3406587],
          [116.3129938, 6.340668],
          [116.3132365, 6.3409927],
          [116.3134707, 6.341134],
          [116.3139409, 6.3416864],
          [116.3142868, 6.3421971],
          [116.3144174, 6.3425774],
          [116.3146228, 6.3429113],
          [116.3146439, 6.343177],
          [116.3148188, 6.3433751],
          [116.3153508, 6.343672],
          [116.315704, 6.3437122],
          [116.3163774, 6.3440894],
          [116.317869, 6.3442915],
          [116.3182068, 6.3444604],
          [116.3192049, 6.3440797],
          [116.3207352, 6.3443566],
          [116.3214075, 6.3448589],
          [116.3219862, 6.3454154],
          [116.3230351, 6.3453808],
          [116.3236081, 6.3455141],
          [116.3241329, 6.3461575],
          [116.3244773, 6.3467942],
          [116.3248982, 6.3465657],
          [116.3259435, 6.3467141],
          [116.3263509, 6.3472587],
          [116.3265595, 6.3482725],
          [116.3269525, 6.3485958],
          [116.3276048, 6.348829],
          [116.3280108, 6.3491666],
          [116.3278848, 6.3493856],
          [116.3279222, 6.3502019],
          [116.3282857, 6.3505429],
          [116.3275862, 6.350944],
          [116.3272689, 6.3508883],
          [116.3274742, 6.3504802],
          [116.3264475, 6.3503874],
          [116.3257523, 6.3501806],
          [116.3253649, 6.3502019],
          [116.3246369, 6.3499607],
          [116.3243984, 6.3496385],
          [116.3237409, 6.3494041],
          [116.3233271, 6.3490888],
          [116.3230031, 6.3493827],
          [116.3220982, 6.350944],
          [116.3215569, 6.3517417],
          [116.3213136, 6.3520677],
          [116.3206049, 6.3521127],
          [116.3197089, 6.3521498],
          [116.3194289, 6.3526879],
          [116.3189988, 6.352862],
          [116.3186262, 6.3534856],
          [116.3181882, 6.3537843],
          [116.3177117, 6.353579],
          [116.3169771, 6.354227],
          [116.3163885, 6.3551179],
          [116.3155615, 6.3559613],
          [116.3144806, 6.3568946],
          [116.3136302, 6.3574933],
          [116.3120742, 6.3573629],
          [116.311529, 6.3570399],
          [116.3110426, 6.3570726],
          [116.3114209, 6.3574557],
          [116.3114815, 6.3580624],
          [116.3118316, 6.3587914],
          [116.312388, 6.3592071],
          [116.3128956, 6.3595149],
          [116.3135116, 6.3600158],
          [116.3143617, 6.360347],
          [116.3149933, 6.361002],
          [116.3152289, 6.3615742],
          [116.3150745, 6.3621717],
          [116.3148303, 6.3626481],
          [116.3148233, 6.3630308],
          [116.3153409, 6.3632624],
          [116.3160315, 6.362947],
          [116.3167268, 6.3628937],
          [116.3171142, 6.3631325],
          [116.3176555, 6.3632624],
          [116.3180948, 6.3636147],
          [116.3185329, 6.3642271],
          [116.3187843, 6.364649],
          [116.3187195, 6.3649691],
          [116.3188129, 6.3653587],
          [116.3193355, 6.3658411],
          [116.3201378, 6.3668233],
          [116.3205049, 6.3675129],
          [116.3208289, 6.36816],
          [116.3206717, 6.3686418],
          [116.320422, 6.3689657],
          [116.3202875, 6.3695142],
          [116.3200705, 6.3699983],
          [116.3200075, 6.3703862],
          [116.3203435, 6.3704418],
          [116.3207257, 6.3709653],
          [116.3211275, 6.3713323],
          [116.3214515, 6.3718362],
          [116.3217249, 6.3722969],
          [116.3221423, 6.372372],
          [116.3225162, 6.3723188],
          [116.3228635, 6.3719816],
          [116.323057, 6.3714189],
          [116.3236849, 6.3710355],
          [116.3241884, 6.370741],
          [116.3244292, 6.3703857],
          [116.3243942, 6.369978],
          [116.3243076, 6.3696156],
          [116.3245062, 6.3693287],
          [116.3247209, 6.3690037],
          [116.3247675, 6.3685496],
          [116.3245264, 6.3682789],
          [116.3246929, 6.3680116],
          [116.3250475, 6.3677704],
          [116.3258502, 6.3674179],
          [116.3266342, 6.367251],
          [116.3274058, 6.3675695],
          [116.3279408, 6.367622],
          [116.3285414, 6.3678928],
          [116.3288555, 6.3686052],
          [116.329475, 6.3691135],
          [116.3299008, 6.3695884],
          [116.3304205, 6.3698149],
          [116.3308245, 6.3696106],
          [116.3307782, 6.3692545],
          [116.3304982, 6.3689763],
          [116.330305, 6.3684566],
          [116.3303862, 6.3679745],
          [116.3305211, 6.3675651],
          [116.3307408, 6.3672695],
          [116.3308902, 6.3668614],
          [116.3313241, 6.3664111],
          [116.3316358, 6.3659582],
          [116.3316368, 6.3655999],
          [116.3315248, 6.3651917],
          [116.3316629, 6.3645992],
          [116.3314502, 6.3643013],
          [116.331656, 6.3638552],
          [116.3326989, 6.3630773],
          [116.3331605, 6.362655],
          [116.3331456, 6.3622845],
          [116.3330368, 6.3620009],
          [116.3330555, 6.3616484],
          [116.3328729, 6.361339],
          [116.3331115, 6.3610733],
          [116.3334102, 6.3608507],
          [116.3348418, 6.3605048],
          [116.3359669, 6.3602564],
          [116.336105585098267, 6.360223156961525],
          [116.3365835, 6.3601086],
          [116.3373595, 6.3601014],
          [116.3381328, 6.3603312],
          [116.3387734, 6.3609264],
          [116.3391091, 6.3615082],
          [116.3386254, 6.3619437],
          [116.3381599, 6.3622205],
          [116.3378528, 6.36278],
          [116.3379648, 6.3631511],
          [116.3388235, 6.3634479],
          [116.3393525, 6.3635641],
          [116.3401488, 6.3635407],
          [116.3407323, 6.3633396],
          [116.3414181, 6.3633922],
          [116.3420722, 6.3636786],
          [116.3425195, 6.3640787],
          [116.3430322, 6.3647907],
          [116.3432475, 6.3657854],
          [116.3433346, 6.3660649],
          [116.3431728, 6.3665831],
          [116.3432913, 6.3670447],
          [116.3438075, 6.3673437],
          [116.3440501, 6.3670655],
          [116.3444048, 6.3669727],
          [116.3447552, 6.3665538],
          [116.3451888, 6.3664161],
          [116.3455248, 6.3664904],
          [116.3457861, 6.3668057],
          [116.3463559, 6.3671079],
          [116.3467008, 6.3675292],
          [116.3470245, 6.368422],
          [116.3470368, 6.3690319],
          [116.3467141, 6.3697407],
          [116.3458516, 6.3701866],
          [116.3456741, 6.3704789],
          [116.3453381, 6.3708499],
          [116.3452779, 6.3714859],
          [116.3454315, 6.3719445],
          [116.3457301, 6.3720929],
          [116.3460567, 6.3720754],
          [116.3461918, 6.3723142],
          [116.3462709, 6.3724888],
          [116.3463084, 6.3726287],
          [116.3463347, 6.3728049],
          [116.3463527, 6.3730659],
          [116.3463307, 6.3733464],
          [116.346299, 6.373491],
          [116.3461998, 6.3736696],
          [116.3461301, 6.3737016],
          [116.3461086, 6.3737496],
          [116.3461113, 6.3738816],
          [116.3460871, 6.3740868],
          [116.3460603, 6.3742054],
          [116.3460134, 6.3743272],
          [116.3459101, 6.3744987],
          [116.3458162, 6.3746226],
          [116.345714, 6.3747531],
          [116.3452353, 6.3753017],
          [116.3450947, 6.3754183],
          [116.3450357, 6.3754956],
          [116.3449687, 6.3756076],
          [116.3448799, 6.3757686],
          [116.3445072, 6.3759372],
          [116.3441248, 6.3759144],
          [116.3438879, 6.3759686],
          [116.3436208, 6.3764524],
          [116.3431568, 6.3760797],
          [116.3426501, 6.3766194],
          [116.3420402, 6.3760856],
          [116.3416147, 6.3764793],
          [116.3427621, 6.3773985],
          [116.3427047, 6.3779025],
          [116.3421453, 6.3785779],
          [116.3417801, 6.3786222],
          [116.3411568, 6.3788641],
          [116.3406901, 6.3790496],
          [116.340562, 6.3793813],
          [116.3402424, 6.3796397],
          [116.3397362, 6.3795803],
          [116.3392897, 6.3798124],
          [116.3388982, 6.3799215],
          [116.3384688, 6.379736],
          [116.3380492, 6.3796165],
          [116.3376102, 6.3796989],
          [116.3374967, 6.3801334],
          [116.3376475, 6.3807748],
          [116.3381134, 6.3812544],
          [116.3378688, 6.3825332],
          [116.337647, 6.3831026],
          [116.3374608, 6.3834647],
          [116.3370502, 6.3834832],
          [116.3369196, 6.3838535],
          [116.3365835, 6.3842253],
          [116.336105585098267, 6.38415222914546],
          [116.3360982, 6.3841511],
          [116.3356607, 6.383929],
          [116.334894, 6.3836017],
          [116.3349459, 6.3838831],
          [116.3352022, 6.3842253],
          [116.3355008, 6.3845963],
          [116.3357901, 6.3849163],
          [116.3359179, 6.385222],
          [116.3358368, 6.3857094],
          [116.3360235, 6.3860618],
          [116.3358742, 6.3864328],
          [116.3360795, 6.3867111],
          [116.336105585098267, 6.386835977003607],
          [116.3361157, 6.3868844],
          [116.3363035, 6.3870265],
          [116.3362475, 6.3874902],
          [116.3365479, 6.3881082],
          [116.3367328, 6.3883621],
          [116.3371248, 6.3884549],
          [116.3375119, 6.3883232],
          [116.3377595, 6.3880282],
          [116.3381328, 6.3878241],
          [116.3385248, 6.3877128],
          [116.339193, 6.3880277],
          [116.3396714, 6.3884578],
          [116.3398875, 6.3885476],
          [116.3401302, 6.3883992],
          [116.3428637, 6.3872943],
          [116.3432802, 6.3871482],
          [116.3435565, 6.3871109],
          [116.3440087, 6.386991],
          [116.3446052, 6.3867551],
          [116.3452825, 6.3866159],
          [116.3458618, 6.3865538],
          [116.3462937, 6.3864432],
          [116.3465358, 6.3863463],
          [116.3467175, 6.3862153],
          [116.3468596, 6.3860687],
          [116.3469776, 6.3859447],
          [116.3471059, 6.3857846],
          [116.3472177, 6.3856195],
          [116.3473124, 6.3853738],
          [116.3474119, 6.3849977],
          [116.3475035, 6.3845406],
          [116.3474459, 6.3842248],
          [116.3474288, 6.383576],
          [116.3474537, 6.3830459],
          [116.3474068, 6.3829926],
          [116.3473503, 6.3829677],
          [116.3473545, 6.3823982],
          [116.3474001, 6.3821788],
          [116.3475896, 6.3817535],
          [116.3479515, 6.38122],
          [116.3482501, 6.3811644],
          [116.3485102, 6.3802906],
          [116.3482875, 6.3798473],
          [116.348043, 6.3790451],
          [116.3480075, 6.3784188],
          [116.3481008, 6.3780107],
          [116.3482479, 6.3770311],
          [116.3486235, 6.3762298],
          [116.3491408, 6.375845],
          [116.3500048, 6.3755434],
          [116.3511995, 6.3754135],
          [116.3519957, 6.3754407],
          [116.3529541, 6.3751353],
          [116.3549493, 6.3746974],
          [116.3563514, 6.3745602],
          [116.3575074, 6.3742792],
          [116.3576394, 6.3745973],
          [116.3581061, 6.3745231],
          [116.3587512, 6.3748411],
          [116.3588341, 6.3752651],
          [116.3591166, 6.3755061],
          [116.3596928, 6.3753764],
          [116.3599541, 6.3755063],
          [116.3596928, 6.3756176],
          [116.3594314, 6.375933],
          [116.3593568, 6.3762669],
          [116.3596368, 6.3764524],
          [116.3593568, 6.3765823],
          [116.3590394, 6.3768976],
          [116.3587663, 6.3770771],
          [116.3584608, 6.3773429],
          [116.3583301, 6.3771017],
          [116.3581248, 6.376842],
          [116.3577892, 6.376729],
          [116.3573408, 6.3766565],
          [116.3569301, 6.3770089],
          [116.3568928, 6.3773243],
          [116.3567621, 6.3775469],
          [116.3562954, 6.3779551],
          [116.3560532, 6.3781849],
          [116.3557861, 6.3780911],
          [116.3555488, 6.3781406],
          [116.3552893, 6.378104],
          [116.3548768, 6.378122],
          [116.3545781, 6.3781962],
          [116.3544848, 6.3783632],
          [116.3546788, 6.3785395],
          [116.3557168, 6.3787528],
          [116.3564074, 6.3789568],
          [116.3575274, 6.379031],
          [116.3584718, 6.3790523],
          [116.3596928, 6.3789939],
          [116.3609248, 6.3786414],
          [116.361948, 6.3780958],
          [116.3627354, 6.37738],
          [116.3634547, 6.3764982],
          [116.364476, 6.3768713],
          [116.3655625, 6.3772682],
          [116.3654608, 6.3776768],
          [116.3650501, 6.3778252],
          [116.3647141, 6.3781777],
          [116.3645648, 6.3785487],
          [116.3641541, 6.3787156],
          [116.3640137, 6.3791115],
          [116.3638554, 6.3794948],
          [116.3630901, 6.3799957],
          [116.3620321, 6.3804378],
          [116.3614474, 6.3806079],
          [116.3602714, 6.380645],
          [116.3594688, 6.3808305],
          [116.3587581, 6.3809255],
          [116.3578634, 6.380849],
          [116.3573968, 6.3809232],
          [116.3570234, 6.380645],
          [116.3563496, 6.3805],
          [116.3558288, 6.3803852],
          [116.3551754, 6.380107],
          [116.3543541, 6.3797731],
          [116.3534208, 6.3796432],
          [116.3529648, 6.3796278],
          [116.3524875, 6.3797174],
          [116.3520768, 6.3799029],
          [116.3511808, 6.3798287],
          [116.3505115, 6.3798586],
          [116.3503823, 6.3801567],
          [116.3499455, 6.3802297],
          [116.3497389, 6.3803339],
          [116.3496049, 6.380423],
          [116.3495271, 6.3805056],
          [116.3494734, 6.3806058],
          [116.3494909, 6.3806549],
          [116.3494895, 6.3808508],
          [116.3495058, 6.3811945],
          [116.3495568, 6.3815354],
          [116.3496315, 6.3817951],
          [116.3498881, 6.3822179],
          [116.3501158, 6.3825408],
          [116.3502106, 6.3827159],
          [116.350266, 6.3828927],
          [116.3502418, 6.3830847],
          [116.3501775, 6.3831512],
          [116.3501225, 6.3831699],
          [116.3500354, 6.3831392],
          [116.3499911, 6.3831032],
          [116.3499576, 6.3830513],
          [116.3499321, 6.3829926],
          [116.3498704, 6.3829593],
          [116.3497752, 6.382922],
          [116.3497376, 6.38289],
          [116.3497068, 6.3828367],
          [116.349688, 6.3827434],
          [116.349688, 6.3826901],
          [116.3497108, 6.3826341],
          [116.3497323, 6.3825515],
          [116.3497189, 6.3824795],
          [116.3497229, 6.3823969],
          [116.3497645, 6.3823342],
          [116.3497698, 6.3822676],
          [116.3497242, 6.3822249],
          [116.3496719, 6.3822209],
          [116.349629, 6.3822689],
          [116.3495847, 6.3823449],
          [116.3495445, 6.3824861],
          [116.3495164, 6.3826434],
          [116.3495083, 6.3827914],
          [116.3495365, 6.38295],
          [116.3495754, 6.3831179],
          [116.3496089, 6.3832605],
          [116.3496403, 6.3833925],
          [116.3496151, 6.3835176],
          [116.3495043, 6.3836963],
          [116.3494207, 6.3839205],
          [116.3493672, 6.3842335],
          [116.349374, 6.384357],
          [116.3492573, 6.3848558],
          [116.3492629, 6.3850784],
          [116.3492403, 6.3852255],
          [116.349161, 6.385417],
          [116.3491127, 6.3854329],
          [116.3490644, 6.3853716],
          [116.3490349, 6.3852917],
          [116.3489705, 6.385265],
          [116.3489035, 6.385289],
          [116.348823, 6.3854143],
          [116.3487318, 6.3857102],
          [116.3486648, 6.3858461],
          [116.3486299, 6.3859581],
          [116.3486487, 6.3860274],
          [116.3487238, 6.386086],
          [116.3487828, 6.3861127],
          [116.3488606, 6.386198],
          [116.3489303, 6.3862593],
          [116.3490545, 6.3862821],
          [116.3492066, 6.3863659],
          [116.3492775, 6.3864943],
          [116.3497061, 6.3866925],
          [116.3506646, 6.3872394],
          [116.3513115, 6.3877314],
          [116.3516288, 6.3882137],
          [116.3517936, 6.3887924],
          [116.3515915, 6.3894566],
          [116.3508807, 6.3896526],
          [116.3506845, 6.3900199],
          [116.3508075, 6.3904027],
          [116.3508261, 6.3911447],
          [116.350602, 6.3914175],
          [116.3503968, 6.391998],
          [116.3500235, 6.3923134],
          [116.3497898, 6.3926665],
          [116.349362, 6.3926593],
          [116.3488288, 6.3929256],
          [116.3486981, 6.3935748],
          [116.3487915, 6.3938902],
          [116.3491712, 6.3942191],
          [116.3491088, 6.3943911],
          [116.3491648, 6.3946322],
          [116.3493701, 6.394725],
          [116.3498741, 6.3947064],
          [116.3505648, 6.3947806],
          [116.3511142, 6.3948628],
          [116.3513488, 6.3947992],
          [116.3516101, 6.3947435],
          [116.3520395, 6.3948919],
          [116.3523568, 6.3949105],
          [116.3526555, 6.3951145],
          [116.3531408, 6.3951331],
          [116.3534183, 6.3952121],
          [116.3537941, 6.3952258],
          [116.3547461, 6.3956525],
          [116.3552688, 6.395838],
          [116.3560085, 6.395796],
          [116.3564821, 6.3957081],
          [116.3569625, 6.39528],
          [116.3573221, 6.3948734],
          [116.3573767, 6.3938553],
          [116.3576768, 6.3941128],
          [116.3581031, 6.3944603],
          [116.3586578, 6.3950257],
          [116.3618417, 6.3967078],
          [116.3642514, 6.3978876],
          [116.3665193, 6.3991025],
          [116.3691239, 6.4007401],
          [116.3721538, 6.4029939],
          [116.3762467, 6.4057759],
          [116.3810306, 6.4095791],
          [116.3850173, 6.4128365],
          [116.3873029, 6.414474],
          [116.3887204, 6.4154952],
          [116.391892, 6.4175905],
          [116.3955951, 6.419809],
          [116.3986958, 6.4217106],
          [116.3997327, 6.4224015],
          [116.4002028, 6.4226153],
          [116.4010795, 6.4229725],
          [116.4015732, 6.423116],
          [116.4021884, 6.4233966],
          [116.4030997, 6.4233261],
          [116.4037013, 6.4233003],
          [116.4037352, 6.4232229],
          [116.4035272, 6.423102],
          [116.4030482, 6.4230391],
          [116.4028281, 6.4229568],
          [116.4026346, 6.4229617],
          [116.4022911, 6.4228262],
          [116.4020516, 6.4226206],
          [116.4018799, 6.4223497],
          [116.4018726, 6.4220497],
          [116.4017758, 6.4216724],
          [116.4016622, 6.421324],
          [116.4016065, 6.4210507],
          [116.4013622, 6.420654],
          [116.400655, 6.4199051],
          [116.3997519, 6.4189062],
          [116.3983972, 6.4172641],
          [116.3976036, 6.4165115],
          [116.3970015, 6.4163884],
          [116.3959479, 6.4164431],
          [116.3949079, 6.4163473],
          [116.3938953, 6.4161694],
          [116.3932796, 6.4164705],
          [116.3927733, 6.4167441],
          [116.3922943, 6.4167852],
          [116.3917607, 6.4168126],
          [116.3912954, 6.4164705],
          [116.3909534, 6.4161557],
          [116.3916649, 6.4159779],
          [116.3912954, 6.4156084],
          [116.3911449, 6.4152526],
          [116.3916102, 6.4149105],
          [116.3921986, 6.4146505],
          [116.3926091, 6.414459],
          [116.3921165, 6.4149379],
          [116.3917881, 6.4152937],
          [116.3917333, 6.4155673],
          [116.3918975, 6.4159368],
          [116.392308, 6.4162515],
          [116.392828, 6.4161694],
          [116.3934711, 6.4158958],
          [116.3945111, 6.4157589],
          [116.3950037, 6.4159779],
          [116.3959889, 6.4160326],
          [116.3970289, 6.4158547],
          [116.397412, 6.4157179],
          [116.3979183, 6.4159779],
          [116.3988488, 6.4167168],
          [116.3999709, 6.4182767],
          [116.4009013, 6.4193988],
          [116.4015581, 6.4201823],
          [116.4018387, 6.4206854],
          [116.4020637, 6.421162],
          [116.4023056, 6.4213966],
          [116.4022209, 6.4215296],
          [116.4023927, 6.4218731],
          [116.4029515, 6.4222795],
          [116.4033216, 6.422386],
          [116.4034933, 6.4225093],
          [116.4036409, 6.4225432],
          [116.4039747, 6.4228867],
          [116.4041682, 6.4230874],
          [116.4043907, 6.4232423],
          [116.4046907, 6.4232616],
          [116.4047533, 6.4234617],
          [116.4048165, 6.4236632],
          [116.4045407, 6.423818],
          [116.4043182, 6.4238809],
          [116.404011, 6.4238857],
          [116.4037262, 6.4239706],
          [116.403233, 6.4238846],
          [116.403681, 6.4243669],
          [116.4061793, 6.4258998],
          [116.407927, 6.4268342],
          [116.412711, 6.4298098],
          [116.4190187, 6.4336481],
          [116.4238735, 6.4367117],
          [116.4297737, 6.4409548],
          [116.4349475, 6.4445818],
          [116.4402314, 6.4483596],
          [116.4451178, 6.4516066],
          [116.4494588, 6.454406],
          [116.4544022, 6.457804],
          [116.4579813, 6.4599695],
          [116.4618084, 6.4626103],
          [116.4646079, 6.464635],
          [116.4683642, 6.4674167],
          [116.4725255, 6.4706904],
          [116.474565, 6.4722713],
          [116.4767206, 6.4740829],
          [116.4813567, 6.4780957],
          [116.4832289, 6.4794996],
          [116.4852852, 6.481677],
          [116.4881556, 6.4853212],
          [116.4908665, 6.4895112],
          [116.4924788, 6.4922927],
          [116.4933825, 6.4942468],
          [116.4950834, 6.4984367],
          [116.496182, 6.5015175],
          [116.4975994, 6.5058481],
          [116.4987511, 6.5105308],
          [116.499389, 6.5139812],
          [116.4996902, 6.5170795],
          [116.5004521, 6.5213924],
          [116.5008773, 6.5241914],
          [116.5016248, 6.5254139],
          [116.5019729, 6.5260266],
          [116.5021782, 6.5264899],
          [116.5020216, 6.5269807],
          [116.5013368, 6.5273501],
          [116.5008673, 6.5275833],
          [116.5004955, 6.5276805],
          [116.5000847, 6.5275444],
          [116.4998499, 6.5273306],
          [116.4995955, 6.526903],
          [116.4991651, 6.5266503],
          [116.4988325, 6.5267475],
          [116.4986564, 6.5271751],
          [116.4985782, 6.5276222],
          [116.4983238, 6.5280693],
          [116.497913, 6.5283025],
          [116.4975608, 6.5286524],
          [116.497326, 6.5289245],
          [116.4971108, 6.5290412],
          [116.4967391, 6.5288468],
          [116.4962695, 6.5288274],
          [116.4959565, 6.5289829],
          [116.4957217, 6.5293522],
          [116.4957608, 6.5297604],
          [116.495663, 6.5302269],
          [116.4953304, 6.530499],
          [116.4950956, 6.5308684],
          [116.4951934, 6.5314515],
          [116.4953499, 6.5319374],
          [116.4955065, 6.5323456],
          [116.4955456, 6.5329288],
          [116.4956043, 6.5332009],
          [116.495976, 6.533162],
          [116.4963086, 6.5332787],
          [116.497189, 6.533337],
          [116.4982651, 6.5335897],
          [116.4999868, 6.5342506],
          [116.5016107, 6.5350864],
          [116.502902, 6.5359222],
          [116.5036064, 6.5368747],
          [116.5039585, 6.5376911],
          [116.5039585, 6.5384297],
          [116.5038803, 6.5390128],
          [116.5036846, 6.5392655],
          [116.5033716, 6.5393627],
          [116.5031955, 6.5397126],
          [116.5029216, 6.5399653],
          [116.5026868, 6.5402957],
          [116.5025107, 6.5407039],
          [116.5026477, 6.5409177],
          [116.5029216, 6.5410149],
          [116.5032933, 6.5408983],
          [116.5037238, 6.5408011],
          [116.5043107, 6.5407622],
          [116.5047607, 6.5408594],
          [116.5051911, 6.5411315],
          [116.5056803, 6.541773],
          [116.506052, 6.5423755],
          [116.5062476, 6.5429392],
          [116.5062085, 6.5435224],
          [116.5061451, 6.5437788],
          [116.505993, 6.5438406],
          [116.5057993, 6.5438681],
          [116.5057716, 6.543978],
          [116.5056817, 6.5441086],
          [116.5056194, 6.5443491],
          [116.505737, 6.5444179],
          [116.5058961, 6.544411],
          [116.5062609, 6.5444693],
          [116.5065349, 6.5444887],
          [116.5069457, 6.5445859],
          [116.5073957, 6.5449358],
          [116.5078653, 6.545344],
          [116.5081001, 6.545655],
          [116.5081783, 6.5458494],
          [116.5079631, 6.5461992],
          [116.5081587, 6.5466074],
          [116.5081001, 6.5469962],
          [116.5080218, 6.5473266],
          [116.5081979, 6.5475599],
          [116.5085696, 6.5474432],
          [116.5089022, 6.5471322],
          [116.5091761, 6.5467241],
          [116.50945, 6.5465686],
          [116.5097631, 6.5462576],
          [116.5099783, 6.5466269],
          [116.5106826, 6.5471128],
          [116.5112892, 6.5475599],
          [116.5124435, 6.5485512],
          [116.5134609, 6.5495814],
          [116.5141261, 6.5503005],
          [116.5144391, 6.5508642],
          [116.5144391, 6.5512141],
          [116.5141848, 6.5514668],
          [116.5141848, 6.5519138],
          [116.5145761, 6.5521082],
          [116.5148304, 6.5524969],
          [116.515163, 6.5528079],
          [116.5153391, 6.5532939],
          [116.5152413, 6.5537798],
          [116.5150652, 6.5542268],
          [116.5147326, 6.554635],
          [116.5145174, 6.5551598],
          [116.514713, 6.555568],
          [116.51485, 6.5560345],
          [116.5147522, 6.5565593],
          [116.5147522, 6.5571618],
          [116.5145565, 6.5577255],
          [116.5143413, 6.5582503],
          [116.5140869, 6.5585419],
          [116.5140087, 6.5588917],
          [116.5143413, 6.5590278],
          [116.5145565, 6.5587751],
          [116.5148304, 6.5583669],
          [116.5152413, 6.5582309],
          [116.5154956, 6.5580171],
          [116.5157695, 6.55757],
          [116.5159456, 6.557434],
          [116.5163956, 6.5572007],
          [116.5167869, 6.5570452],
          [116.5170217, 6.5569286],
          [116.5173152, 6.557259],
          [116.5181173, 6.5577061],
          [116.5192521, 6.5583475],
          [116.5200543, 6.5589501],
          [116.5208173, 6.5597275],
          [116.5214238, 6.5604661],
          [116.5220108, 6.5611659],
          [116.5227151, 6.5620211],
          [116.5228912, 6.5622738],
          [116.5226956, 6.5626819],
          [116.5226564, 6.563129],
          [116.5228521, 6.5636538],
          [116.5229499, 6.5642563],
          [116.5230086, 6.5647034],
          [116.5232042, 6.5648977],
          [116.5233608, 6.5653836],
          [116.523576, 6.5656558],
          [116.5238108, 6.5659862],
          [116.5239086, 6.5662389],
          [116.5237912, 6.5664721],
          [116.5240455, 6.566647],
          [116.5242021, 6.566958],
          [116.5246716, 6.5672496],
          [116.5251999, 6.5674245],
          [116.5256499, 6.5676772],
          [116.5258455, 6.567891],
          [116.5262955, 6.567891],
          [116.5265499, 6.5677744],
          [116.5269607, 6.5676772],
          [116.5278607, 6.568202],
          [116.5287803, 6.5689405],
          [116.5295824, 6.5696597],
          [116.5302476, 6.5703983],
          [116.5306976, 6.5707287],
          [116.5315389, 6.5715839],
          [116.5323607, 6.5726529],
          [116.5331237, 6.573547],
          [116.5338476, 6.5744605],
          [116.5343172, 6.575063],
          [116.5346106, 6.5757433],
          [116.5345324, 6.5760543],
          [116.5343954, 6.5764236],
          [116.5348454, 6.5766762],
          [116.5352172, 6.5767151],
          [116.5355498, 6.5769095],
          [116.5358628, 6.5770844],
          [116.5366063, 6.577823],
          [116.5374671, 6.578717],
          [116.5380345, 6.5795139],
          [116.5389541, 6.5805052],
          [116.5397758, 6.5819629],
          [116.5406758, 6.5839259],
          [116.5413019, 6.5854808],
          [116.5422801, 6.5881046],
          [116.5433366, 6.5916419],
          [116.5440214, 6.594499],
          [116.5444714, 6.5972005],
          [116.544804, 6.5987748],
          [116.5450975, 6.6012431],
          [116.5455671, 6.6021565],
          [116.5458214, 6.6025841],
          [116.5456649, 6.6028173],
          [116.5450192, 6.6027785],
          [116.5445105, 6.6028173],
          [116.5440605, 6.602759],
          [116.5436106, 6.6028368],
          [116.5433366, 6.6030505],
          [116.5434149, 6.6033615],
          [116.5432779, 6.6036142],
          [116.5430236, 6.6039057],
          [116.5431997, 6.6042555],
          [116.543454, 6.6045082],
          [116.5439236, 6.6045471],
          [116.5442953, 6.6045082],
          [116.5446671, 6.6048969],
          [116.5449605, 6.6056549],
          [116.5449214, 6.6062768],
          [116.5449214, 6.6069764],
          [116.5447258, 6.6073263],
          [116.5444323, 6.6074623],
          [116.5442513, 6.6074479],
          [116.5440576, 6.6074547],
          [116.543895, 6.6075235],
          [116.5438224, 6.6076265],
          [116.5438674, 6.6077433],
          [116.5439435, 6.6078052],
          [116.54394, 6.6079048],
          [116.5440126, 6.6080045],
          [116.5441441, 6.6080491],
          [116.5443585, 6.6080938],
          [116.5446386, 6.6080938],
          [116.5448427, 6.6081213],
          [116.5452854, 6.6080938],
          [116.5458665, 6.60808],
          [116.5461847, 6.6083274],
          [116.5465167, 6.608616],
          [116.5468349, 6.6093031],
          [116.5470839, 6.6098254],
          [116.5471531, 6.6101552],
          [116.5470562, 6.6103476],
          [116.5473329, 6.61054],
          [116.5475819, 6.6105812],
          [116.547914, 6.61054],
          [116.548329, 6.6105537],
          [116.5486472, 6.6107736],
          [116.5490346, 6.6109522],
          [116.5495465, 6.6111172],
          [116.5500168, 6.6112958],
          [116.5508469, 6.6116531],
          [116.5516493, 6.6119692],
          [116.5525347, 6.6123265],
          [116.5533786, 6.6128212],
          [116.5540842, 6.6134671],
          [116.5545269, 6.6139344],
          [116.5547898, 6.6143329],
          [116.5548451, 6.6148276],
          [116.5548589, 6.6152124],
          [116.5548313, 6.6154323],
          [116.5550803, 6.6155422],
          [116.5553708, 6.615556],
          [116.5556198, 6.615556],
          [116.5559657, 6.6157484],
          [116.5564361, 6.6158995],
          [116.5569064, 6.6162019],
          [116.5576258, 6.6168203],
          [116.5583591, 6.6173975],
          [116.5593828, 6.6184007],
          [116.5603236, 6.6194451],
          [116.5612228, 6.6205032],
          [116.5621082, 6.6216301],
          [116.5627031, 6.6224821],
          [116.5632565, 6.6234166],
          [116.5638099, 6.6241587],
          [116.5643356, 6.6247908],
          [116.5645984, 6.6250931],
          [116.5647921, 6.6254092],
          [116.5647921, 6.6266323],
          [116.5648198, 6.6275118],
          [116.5645984, 6.6280065],
          [116.5645846, 6.6285012],
          [116.5646815, 6.6289409],
          [116.5649028, 6.629257],
          [116.5654009, 6.6294631],
          [116.5658436, 6.6295044],
          [116.5662171, 6.6294494],
          [116.5668258, 6.6299166],
          [116.5676005, 6.6306312],
          [116.5686935, 6.6320879],
          [116.5696342, 6.6334483],
          [116.5702153, 6.6346713],
          [116.5704366, 6.635592],
          [116.5703813, 6.6366227],
          [116.570326, 6.6370762],
          [116.5700908, 6.6373647],
          [116.5703675, 6.6375022],
          [116.5708102, 6.6377358],
          [116.5712944, 6.6378732],
          [116.5721936, 6.6382442],
          [116.572747, 6.6388351],
          [116.5730375, 6.6395085],
          [116.5730652, 6.6402917],
          [116.5730237, 6.6412399],
          [116.5731759, 6.6416659],
          [116.5733557, 6.6422568],
          [116.5734802, 6.6426004],
          [116.5730929, 6.6429302],
          [116.5728438, 6.6433012],
          [116.5728023, 6.6439058],
          [116.5728162, 6.6442356],
          [116.5732035, 6.6444005],
          [116.5735217, 6.6445517],
          [116.5739783, 6.64528],
          [116.5744625, 6.6459533],
          [116.5746562, 6.6464343],
          [116.5747115, 6.6469839],
          [116.5746147, 6.6475473],
          [116.5743241, 6.6481245],
          [116.5740059, 6.6487291],
          [116.5738399, 6.6492375],
          [116.5737431, 6.6496498],
          [116.5735771, 6.6499933],
          [116.5731759, 6.6504193],
          [116.5730099, 6.6505979],
          [116.57283, 6.6505979],
          [116.5725533, 6.650433],
          [116.5720553, 6.6501582],
          [116.5717647, 6.6502544],
          [116.5714742, 6.6505018],
          [116.5711145, 6.6507354],
          [116.5708102, 6.650969],
          [116.5706026, 6.6512438],
          [116.5704228, 6.6515598],
          [116.5700631, 6.651711],
          [116.5698832, 6.6516286],
          [116.5697034, 6.6519583],
          [116.5698694, 6.6520133],
          [116.5698832, 6.6522332],
          [116.5700354, 6.6524668],
          [116.5700908, 6.6528378],
          [116.5701046, 6.6531676],
          [116.5701876, 6.6534562],
          [116.5703813, 6.6536898],
          [116.570575, 6.6535798],
          [116.5708378, 6.6531813],
          [116.5710869, 6.6528653],
          [116.5712529, 6.6526317],
          [116.5715157, 6.6524668],
          [116.5717232, 6.6523568],
          [116.5719337, 6.6520381],
          [116.5724452, 6.6514975],
          [116.5729379, 6.6508826],
          [116.5731654, 6.6506441],
          [116.5732317, 6.6505406],
          [116.5733707, 6.6505124],
          [116.5735065, 6.6504873],
          [116.5736897, 6.6505061],
          [116.5738224, 6.6506002],
          [116.5738934, 6.6505877],
          [116.5739471, 6.6505328],
          [116.5739203, 6.65047],
          [116.5739013, 6.6504135],
          [116.5739424, 6.6503445],
          [116.5740624, 6.650241],
          [116.5742756, 6.6502127],
          [116.5746357, 6.6501782],
          [116.5750211, 6.6501563],
          [116.5752959, 6.6501029],
          [116.5754759, 6.6500841],
          [116.5755328, 6.6499147],
          [116.5757855, 6.6498143],
          [116.5759718, 6.6497766],
          [116.5763098, 6.6494723],
          [116.5765499, 6.6491837],
          [116.5766257, 6.6489578],
          [116.5768531, 6.648848],
          [116.5771595, 6.6487287],
          [116.5775575, 6.6486629],
          [116.578085, 6.6486942],
          [116.5787325, 6.6487884],
          [116.5792126, 6.6488699],
          [116.5794337, 6.6489201],
          [116.5797369, 6.6491805],
          [116.5800907, 6.6494315],
          [116.5810425, 6.6498847],
          [116.5819359, 6.6503638],
          [116.5827221, 6.6510116],
          [116.5835708, 6.6517925],
          [116.5842945, 6.6526711],
          [116.5847323, 6.6535673],
          [116.5847144, 6.6538868],
          [116.5847412, 6.6541707],
          [116.5847769, 6.6544103],
          [116.5846518, 6.6546943],
          [116.5846518, 6.655138],
          [116.5847948, 6.6553332],
          [116.5849199, 6.6555462],
          [116.5851253, 6.6560342],
          [116.5851651, 6.6561419],
          [116.585282, 6.6561325],
          [116.5854336, 6.6562203],
          [116.5855252, 6.656327],
          [116.5857906, 6.656556],
          [116.586018, 6.6566564],
          [116.5862801, 6.6567474],
          [116.5864791, 6.6568635],
          [116.5867002, 6.6571145],
          [116.5869308, 6.6573968],
          [116.5870856, 6.6575255],
          [116.5874141, 6.6575663],
          [116.5877868, 6.6576635],
          [116.5880483, 6.6577753],
          [116.5884146, 6.6577398],
          [116.5886558, 6.6579616],
          [116.5888166, 6.6581391],
          [116.5891918, 6.6581835],
          [116.5896475, 6.6582279],
          [116.5899691, 6.6584142],
          [116.5902282, 6.6587337],
          [116.5904068, 6.6590265],
          [116.5904515, 6.6592572],
          [116.5904068, 6.6594702],
          [116.5902982, 6.6596426],
          [116.5903614, 6.6598309],
          [116.5905541, 6.6599815],
          [116.5906615, 6.6600411],
          [116.5907057, 6.6601917],
          [116.5908636, 6.6601666],
          [116.59123, 6.659994],
          [116.5916596, 6.6597964],
          [116.5917089, 6.6596176],
          [116.591892, 6.6595155],
          [116.5920171, 6.6594224],
          [116.5921869, 6.6591961],
          [116.592169, 6.6589388],
          [116.5922807, 6.658779],
          [116.5924683, 6.6585306],
          [116.5925174, 6.6582732],
          [116.5924683, 6.657905],
          [116.5926291, 6.6577408],
          [116.5926023, 6.6575411],
          [116.5927452, 6.6572927],
          [116.5927988, 6.657093],
          [116.5929329, 6.6569954],
          [116.5931205, 6.6569022],
          [116.5933706, 6.6567736],
          [116.5935761, 6.6567514],
          [116.5936654, 6.6567957],
          [116.5937995, 6.6569288],
          [116.5939335, 6.657022],
          [116.5940273, 6.6570309],
          [116.5941389, 6.6569865],
          [116.5942551, 6.6570087],
          [116.5943891, 6.6569111],
          [116.5945633, 6.6568046],
          [116.5947107, 6.6568268],
          [116.5949519, 6.656778],
          [116.5952021, 6.6567647],
          [116.5953986, 6.6566981],
          [116.595488, 6.6566094],
          [116.5957426, 6.6565073],
          [116.5959302, 6.6564896],
          [116.5963457, 6.6565872],
          [116.5966717, 6.6567203],
          [116.597105, 6.6568845],
          [116.5976053, 6.6570664],
          [116.5980297, 6.657266],
          [116.5983022, 6.657479],
          [116.5984228, 6.6576831],
          [116.5986283, 6.657905],
          [116.5989052, 6.6580869],
          [116.5992537, 6.6580869],
          [116.5995128, 6.6582333],
          [116.5999671, 6.6586339],
          [116.6003271, 6.6588723],
          [116.6007504, 6.6591421],
          [116.6009841, 6.6593555],
          [116.6015274, 6.6597508],
          [116.601976, 6.6601712],
          [116.6024245, 6.6608112],
          [116.6026393, 6.6612441],
          [116.6027403, 6.6615077],
          [116.6027656, 6.6617775],
          [116.602753, 6.6619343],
          [116.6029741, 6.66243],
          [116.6035047, 6.6629446],
          [116.604326, 6.6636473],
          [116.604446, 6.6640489],
          [116.6046987, 6.6642058],
          [116.6049451, 6.6644379],
          [116.605261, 6.6649462],
          [116.6054631, 6.6650968],
          [116.6057663, 6.6655234],
          [116.6059432, 6.6656866],
          [116.6062022, 6.6658623],
          [116.6066129, 6.6659689],
          [116.6069666, 6.6659313],
          [116.6073267, 6.6658748],
          [116.6076994, 6.6657117],
          [116.6079395, 6.6654293],
          [116.6081101, 6.6652348],
          [116.6084512, 6.6652285],
          [116.6092409, 6.6656238],
          [116.6100937, 6.6660693],
          [116.6106686, 6.6663266],
          [116.6118625, 6.666678],
          [116.6124185, 6.6668913],
          [116.6135745, 6.667067],
          [116.6146169, 6.6672929],
          [116.615356, 6.6675941],
          [116.6160446, 6.6679768],
          [116.6169606, 6.6687737],
          [116.6177819, 6.6696521],
          [116.618401, 6.6704741],
          [116.6190011, 6.6712709],
          [116.6192285, 6.6715282],
          [116.6193107, 6.6718921],
          [116.6194244, 6.6724255],
          [116.6195191, 6.673078],
          [116.6197023, 6.6743266],
          [116.6199234, 6.6752302],
          [116.6202519, 6.6756756],
          [116.6205109, 6.6758764],
          [116.6213006, 6.6762215],
          [116.6219071, 6.676529],
          [116.6226651, 6.6770247],
          [116.6233221, 6.6776396],
          [116.6237264, 6.6782607],
          [116.6238844, 6.6786999],
          [116.6239728, 6.6794152],
          [116.6240107, 6.6800929],
          [116.6240486, 6.6807893],
          [116.6241687, 6.6813477],
          [116.6244277, 6.6818936],
          [116.624693, 6.6823956],
          [116.6250215, 6.6830732],
          [116.62535, 6.6838136],
          [116.6256848, 6.684372],
          [116.6259249, 6.6846857],
          [116.6262976, 6.684943],
          [116.6268662, 6.6852692],
          [116.6272705, 6.685652],
          [116.6279527, 6.686292],
          [116.6283582, 6.6868742],
          [116.6284202, 6.6869633],
          [116.628430572850888, 6.686974614858627],
          [116.6288056, 6.6873837],
          [116.6292351, 6.6881303],
          [116.6294815, 6.6888895],
          [116.6296331, 6.6898244],
          [116.6298037, 6.6902071],
          [116.6300564, 6.6904769],
          [116.6304417, 6.6905522],
          [116.6306818, 6.6906714],
          [116.6313704, 6.6918196],
          [116.6317305, 6.6926792],
          [116.6321853, 6.6938211],
          [116.6324507, 6.6951073],
          [116.6327981, 6.6959292],
          [116.6331519, 6.6962367],
          [116.633613, 6.6964061],
          [116.6341563, 6.6963182],
          [116.6349611, 6.6961327],
          [116.6342258, 6.6965378],
          [116.6336825, 6.696839],
          [116.6335309, 6.6972907],
          [116.6340047, 6.6980499],
          [116.6347817, 6.6987464],
          [116.6355019, 6.6996247],
          [116.6365, 6.700547],
          [116.6376119, 6.7015572],
          [116.6387743, 6.7027555],
          [116.640202, 6.7042739],
          [116.6412191, 6.7057044],
          [116.6420403, 6.7071098],
          [116.6424952, 6.7081701],
          [116.6429563, 6.7095065],
          [116.6433417, 6.7101903],
          [116.6435818, 6.710962],
          [116.643746, 6.7116836],
          [116.6441314, 6.7128819],
          [116.6443461, 6.7138983],
          [116.644662, 6.7146261],
          [116.64506, 6.7152409],
          [116.6453948, 6.7155421],
          [116.6454011, 6.7158369],
          [116.6451484, 6.7164016],
          [116.6450853, 6.7171482],
          [116.6451295, 6.7189049],
          [116.6453443, 6.7203855],
          [116.6453632, 6.7220732],
          [116.6453316, 6.7235538],
          [116.6452053, 6.7267159],
          [116.6447051, 6.7308463],
          [116.6441869, 6.7337032],
          [116.6436508, 6.7357971],
          [116.6426324, 6.7382814],
          [116.6418283, 6.7394703],
          [116.6409349, 6.7405172],
          [116.6398628, 6.7417593],
          [116.6389873, 6.7425933],
          [116.6378616, 6.7438177],
          [116.63754, 6.7443145],
          [116.6372541, 6.7454324],
          [116.6370397, 6.7473133],
          [116.6365751, 6.7482892],
          [116.6362535, 6.7493184],
          [116.6355924, 6.750596],
          [116.6354523, 6.7508424],
          [116.6352375, 6.7510243],
          [116.6350164, 6.7513255],
          [116.6348458, 6.7517019],
          [116.6347763, 6.7519779],
          [116.6345426, 6.7522351],
          [116.6343025, 6.7524296],
          [116.6341193, 6.7524735],
          [116.6339298, 6.7523794],
          [116.633715, 6.7522163],
          [116.6335002, 6.752141],
          [116.6332728, 6.7519654],
          [116.6329949, 6.7518524],
          [116.6326853, 6.7519403],
          [116.6324895, 6.7520344],
          [116.6323315, 6.7521473],
          [116.6321294, 6.7522916],
          [116.6319525, 6.7523857],
          [116.6317756, 6.752461],
          [116.6316556, 6.752599],
          [116.631504, 6.7527433],
          [116.6314598, 6.7529127],
          [116.6315103, 6.7530507],
          [116.631605, 6.7531071],
          [116.6317314, 6.7531322],
          [116.6318956, 6.7531134],
          [116.6320662, 6.7530946],
          [116.6321926, 6.7531511],
          [116.6322747, 6.7532452],
          [116.6323, 6.753471],
          [116.6323379, 6.7538662],
          [116.6323, 6.7541674],
          [116.6321989, 6.754412],
          [116.6321357, 6.7547947],
          [116.6321168, 6.7552338],
          [116.6320283, 6.7555224],
          [116.6319209, 6.7559051],
          [116.6319462, 6.7562752],
          [116.6319778, 6.7567457],
          [116.6320599, 6.7572978],
          [116.6321799, 6.7576617],
          [116.6324326, 6.7578185],
          [116.6327169, 6.7578812],
          [116.6329696, 6.7580569],
          [116.6332475, 6.7584584],
          [116.6334876, 6.7591422],
          [116.6337087, 6.7597507],
          [116.6341193, 6.76056],
          [116.6343657, 6.7610556],
          [116.6344857, 6.7613881],
          [116.6346563, 6.7617205],
          [116.6348964, 6.7620217],
          [116.6349406, 6.7622914],
          [116.635048, 6.7623855],
          [116.6352249, 6.762235],
          [116.6354839, 6.7623793],
          [116.6358629, 6.7625424],
          [116.6363493, 6.7625737],
          [116.6367221, 6.762511],
          [116.637, 6.7623479],
          [116.6372211, 6.7625424],
          [116.6370695, 6.7635147],
          [116.6368674, 6.7644934],
          [116.6360019, 6.7659739],
          [116.6354397, 6.7670905],
          [116.6344921, 6.7683389],
          [116.6338603, 6.769581],
          [116.6334813, 6.7705659],
          [116.633317, 6.7713438],
          [116.6328559, 6.7721907],
          [116.6323758, 6.7728745],
          [116.6318262, 6.7739472],
          [116.6314282, 6.774976],
          [116.6308091, 6.7761114],
          [116.6304742, 6.7768078],
          [116.6302026, 6.7777738],
          [116.6298804, 6.7788654],
          [116.6294698, 6.7795429],
          [116.6291918, 6.7799757],
          [116.6288697, 6.7801827],
          [116.6286296, 6.7802831],
          [116.6282948, 6.7803395],
          [116.6281621, 6.780396],
          [116.6279347, 6.780465],
          [116.6277389, 6.7804211],
          [116.6275872, 6.7802956],
          [116.6274419, 6.7802015],
          [116.6272714, 6.7800823],
          [116.6271198, 6.7800698],
          [116.6270124, 6.7801953],
          [116.6270503, 6.7803584],
          [116.6270187, 6.7805277],
          [116.6268734, 6.7806156],
          [116.6268165, 6.7808414],
          [116.6268797, 6.7810421],
          [116.627006, 6.7812617],
          [116.6270819, 6.7816255],
          [116.6272272, 6.7818263],
          [116.6272966, 6.7819329],
          [116.6275495, 6.7824364],
          [116.6276675, 6.7829531],
          [116.6277886, 6.7832914],
          [116.6279706, 6.7833633],
          [116.6282228, 6.7834272],
          [116.6286921, 6.7829904],
          [116.6289872, 6.7824045],
          [116.6297707, 6.7819619],
          [116.6306716, 6.7820422],
          [116.6306383, 6.7828529],
          [116.6304302, 6.7835018],
          [116.6303551, 6.7840611],
          [116.6302103, 6.7843061],
          [116.6302103, 6.7845991],
          [116.6299903, 6.7849028],
          [116.6296792, 6.7849241],
          [116.6291097, 6.7848005],
          [116.6289496, 6.7851691],
          [116.6289389, 6.7855367],
          [116.6292447, 6.7859308],
          [116.6295934, 6.7861013],
          [116.629868, 6.7861586],
          [116.6299689, 6.7858989],
          [116.6299367, 6.7856272],
          [116.6302854, 6.785542],
          [116.6306964, 6.7852573],
          [116.631664, 6.7847163],
          [116.632681, 6.7844302],
          [116.633548, 6.7844344],
          [116.6345385, 6.784629],
          [116.6357113, 6.7850136],
          [116.6370262, 6.7856621],
          [116.6382606, 6.7864269],
          [116.6391689, 6.7871454],
          [116.6400808, 6.7879071],
          [116.6404766, 6.7885431],
          [116.6408759, 6.7895291],
          [116.6414564, 6.7906503],
          [116.6421944, 6.7919182],
          [116.6424831, 6.7929256],
          [116.6428751, 6.7939075],
          [116.6432204, 6.7950303],
          [116.643604, 6.7962375],
          [116.6437823, 6.7974633],
          [116.6440076, 6.7981398],
          [116.6441103, 6.7984557],
          [116.6437608, 6.7984594],
          [116.6432415, 6.7990519],
          [116.6432404, 6.7994928],
          [116.6435843, 6.8003006],
          [116.6438574, 6.8015116],
          [116.6440584, 6.8027374],
          [116.6443039, 6.8036591],
          [116.6447254, 6.8045279],
          [116.6454954, 6.8058237],
          [116.6463142, 6.8068007],
          [116.6470976, 6.8080051],
          [116.6475524, 6.8089084],
          [116.6481083, 6.809912],
          [116.6487148, 6.8106647],
          [116.6490938, 6.8112669],
          [116.6495487, 6.8118942],
          [116.6498266, 6.8124964],
          [116.6499552, 6.8129081],
          [116.6499681, 6.8133923],
          [116.6500035, 6.8140771],
          [116.6504078, 6.8143029],
          [116.6507057, 6.8145758],
          [116.6508308, 6.8147976],
          [116.6511524, 6.8147444],
          [116.6514204, 6.8146557],
          [116.6516259, 6.8147178],
          [116.6518492, 6.8149396],
          [116.6520368, 6.8150637],
          [116.6524299, 6.8150283],
          [116.6527337, 6.814975],
          [116.6530017, 6.8150371],
          [116.6535467, 6.8149484],
          [116.6538594, 6.8150992],
          [116.6542257, 6.8152057],
          [116.6546545, 6.8151879],
          [116.6549493, 6.8152057],
          [116.6553424, 6.815321],
          [116.6556551, 6.8153299],
          [116.6560393, 6.8152855],
          [116.6563073, 6.81525],
          [116.6565128, 6.8151613],
          [116.6567361, 6.8150726],
          [116.6570667, 6.8151081],
          [116.6573794, 6.8149928],
          [116.6575581, 6.8148952],
          [116.6577993, 6.8147444],
          [116.6580673, 6.8145758],
          [116.6582817, 6.8146202],
          [116.6585766, 6.8148242],
          [116.6588714, 6.8150017],
          [116.6591305, 6.8150194],
          [116.6594074, 6.81525],
          [116.6596486, 6.8154674],
          [116.6598363, 6.8156492],
          [116.6600239, 6.8158887],
          [116.6602428, 6.8160883],
          [116.6603991, 6.8162569],
          [116.660752, 6.8164432],
          [116.6609128, 6.816523],
          [116.6610825, 6.8166694],
          [116.6612746, 6.8167492],
          [116.6615605, 6.8168512],
          [116.6617169, 6.8170464],
          [116.661996841430664, 6.817149003404141],
          [116.6620072, 6.8171528],
          [116.6621814, 6.8172061],
          [116.6623556, 6.817317],
          [116.662445, 6.8174012],
          [116.6625299, 6.8173746],
          [116.6626683, 6.8174988],
          [116.6627711, 6.8175742],
          [116.6629274, 6.8175432],
          [116.6630793, 6.817552],
          [116.6632044, 6.8176053],
          [116.6632848, 6.817725],
          [116.6634188, 6.8178802],
          [116.6635081, 6.8180532],
          [116.663593, 6.8182839],
          [116.6636958, 6.8183726],
          [116.6641067, 6.8186343],
          [116.6643211, 6.8187939],
          [116.6644953, 6.8189093],
          [116.6646874, 6.8190423],
          [116.6650091, 6.8190157],
          [116.6653128, 6.8190246],
          [116.6657908, 6.8190202],
          [116.6661193, 6.8190578],
          [116.666511, 6.8190829],
          [116.6667005, 6.8192083],
          [116.6671174, 6.8193463],
          [116.6673575, 6.8194969],
          [116.667547, 6.8196725],
          [116.6677491, 6.8197101],
          [116.6680271, 6.8196474],
          [116.6682419, 6.8197478],
          [116.6685704, 6.8197729],
          [116.6688231, 6.8197352],
          [116.6690631, 6.8196223],
          [116.6694043, 6.81966],
          [116.6698339, 6.8195721],
          [116.6701118, 6.8193589],
          [116.670415, 6.8191582],
          [116.6708446, 6.8193212],
          [116.6716911, 6.8193589],
          [116.6724492, 6.8194467],
          [116.6724956, 6.8198723],
          [116.6724203, 6.8203244],
          [116.6722505, 6.8205239],
          [116.6721813, 6.8208899],
          [116.6718887, 6.8212957],
          [116.6717056, 6.821411],
          [116.6714554, 6.8216661],
          [116.6715604, 6.8212647],
          [116.6717547, 6.8210518],
          [116.6719155, 6.8208788],
          [116.6719825, 6.8205195],
          [116.671786, 6.8203953],
          [116.6714623, 6.8204209],
          [116.6712723, 6.8204929],
          [116.670964, 6.8203731],
          [116.6706603, 6.820453],
          [116.6702582, 6.8202356],
          [116.6700739, 6.820237],
          [116.6699232, 6.8203155],
          [116.6697445, 6.8205417],
          [116.6694899, 6.8209852],
          [116.6693068, 6.8215796],
          [116.6691119, 6.8222095],
          [116.6688529, 6.8227489],
          [116.6687013, 6.8234138],
          [116.6684802, 6.8244425],
          [116.6683223, 6.8250823],
          [116.6680885, 6.825697],
          [116.6678548, 6.8262678],
          [116.6675958, 6.8267885],
          [116.6673305, 6.827353],
          [116.6670841, 6.8279426],
          [116.6669009, 6.8282688],
          [116.6666671, 6.8286138],
          [116.6663576, 6.8289462],
          [116.6660354, 6.8292661],
          [116.665789, 6.8294668],
          [116.6655048, 6.8296048],
          [116.6653026, 6.8297365],
          [116.6650878, 6.8298745],
          [116.6648842, 6.8299126],
          [116.6647011, 6.8299171],
          [116.6642767, 6.8299215],
          [116.6637809, 6.8299215],
          [116.6635352, 6.8299836],
          [116.6632359, 6.8301255],
          [116.6630706, 6.8303074],
          [116.6629723, 6.8305424],
          [116.6630125, 6.8308485],
          [116.6631197, 6.8310658],
          [116.6630125, 6.8312654],
          [116.6626388, 6.8312097],
          [116.6622487, 6.8311102],
          [116.662271, 6.8307953],
          [116.6621638, 6.8306533],
          [116.6620521, 6.8305469],
          [116.661996841430664, 6.830484713490633],
          [116.6619181, 6.8303961],
          [116.6618422, 6.830263],
          [116.6618109, 6.8301255],
          [116.6618198, 6.8299792],
          [116.6618422, 6.8298239],
          [116.661927, 6.8297086],
          [116.661996841430664, 6.829635929172355],
          [116.6620208, 6.829611],
          [116.6620566, 6.8294913],
          [116.6620342, 6.8292961],
          [116.661996841430664, 6.829130434068408],
          [116.6619762, 6.8290389],
          [116.661996841430664, 6.828985390408882],
          [116.6620493, 6.8288494],
          [116.6620448, 6.8287385],
          [116.6620224, 6.8285966],
          [116.661996841430664, 6.828541620465595],
          [116.6619688, 6.8284813],
          [116.6618348, 6.8283837],
          [116.6617098, 6.828326],
          [116.6616338, 6.8281309],
          [116.6615713, 6.8279845],
          [116.6614641, 6.8278204],
          [116.661339, 6.8276031],
          [116.6612005, 6.8273946],
          [116.6610754, 6.8272616],
          [116.6609727, 6.8273946],
          [116.6607494, 6.8277317],
          [116.6605215, 6.8280377],
          [116.6603071, 6.8282772],
          [116.6600212, 6.8286099],
          [116.65978, 6.8288805],
          [116.6595075, 6.8291954],
          [116.6589134, 6.8297099],
          [116.6583863, 6.8299982],
          [116.6577118, 6.8303397],
          [116.6571177, 6.8305215],
          [116.6564834, 6.8306945],
          [116.6561617, 6.8307655],
          [116.6558714, 6.8307788],
          [116.6555274, 6.8307566],
          [116.6549824, 6.8306856],
          [116.6545402, 6.8305792],
          [116.6542766, 6.8305304],
          [116.6541784, 6.8304151],
          [116.6541114, 6.8302953],
          [116.6539327, 6.8301889],
          [116.6537451, 6.8301977],
          [116.6534994, 6.8302155],
          [116.6532358, 6.8301711],
          [116.6530348, 6.8300957],
          [116.6528785, 6.8299804],
          [116.6526864, 6.8298474],
          [116.6525568, 6.8297941],
          [116.6523826, 6.8298074],
          [116.6522531, 6.8299272],
          [116.6520476, 6.8302377],
          [116.6516322, 6.8308941],
          [116.6511631, 6.8315771],
          [116.650779, 6.8319497],
          [116.650645, 6.8321315],
          [116.6503993, 6.8325085],
          [116.6501447, 6.8329787],
          [116.6497292, 6.833471],
          [116.649153, 6.8340919],
          [116.6488046, 6.8343802],
          [116.6486661, 6.8345798],
          [116.6485008, 6.8347439],
          [116.6483534, 6.8348326],
          [116.6480184, 6.8349879],
          [116.6476431, 6.8352185],
          [116.6470892, 6.8355644],
          [116.646504, 6.8357951],
          [116.6461065, 6.8358705],
          [116.645982, 6.8358994],
          [116.6455659, 6.8358194],
          [116.6449115, 6.8367062],
          [116.6444038, 6.8377063],
          [116.6437641, 6.8390945],
          [116.6436213, 6.8394759],
          [116.6433772, 6.8396623],
          [116.6428591, 6.8397253],
          [116.6426611, 6.839862],
          [116.6426155, 6.8400671],
          [116.6427845, 6.8404772],
          [116.6428998, 6.8412442],
          [116.6425881, 6.8426852],
          [116.6423714, 6.8436224],
          [116.6423607, 6.8446024],
          [116.6425675, 6.8456558],
          [116.6426691, 6.8461257],
          [116.6423055, 6.8476916],
          [116.6421086, 6.8485224],
          [116.6422534, 6.8494758],
          [116.6420881, 6.8507998],
          [116.6420495, 6.8524797],
          [116.6422671, 6.854019],
          [116.6424197, 6.8551534],
          [116.6424997, 6.8565402],
          [116.6425596, 6.8575417],
          [116.6424572, 6.8584662],
          [116.6421819, 6.859428],
          [116.6415775, 6.8605274],
          [116.6407411, 6.8617412],
          [116.6401237, 6.8627057],
          [116.6394693, 6.8630732],
          [116.6395014, 6.863478],
          [116.6397984, 6.8641006],
          [116.6404456, 6.8642077],
          [116.6420754, 6.8642808],
          [116.6429454, 6.8647456],
          [116.6440611, 6.8650346],
          [116.6449732, 6.8653368],
          [116.64579, 6.8656674],
          [116.6469018, 6.86642],
          [116.6477357, 6.8662695],
          [116.6488223, 6.8663448],
          [116.6509449, 6.8670222],
          [116.654123, 6.868414],
          [116.6547952, 6.8688338],
          [116.655452, 6.8693426],
          [116.6564704, 6.8697297],
          [116.6572367, 6.870089],
          [116.657739, 6.8703328],
          [116.6580746, 6.8707401],
          [116.6586011, 6.8709626],
          [116.6589763, 6.87063],
          [116.6593203, 6.8701421],
          [116.659564, 6.8701433],
          [116.6604113, 6.8705226],
          [116.6618308, 6.8711674],
          [116.661996841430664, 6.871241316337158],
          [116.6630746, 6.8717211],
          [116.6649288, 6.8726362],
          [116.6670373, 6.8737413],
          [116.6678504, 6.8742942],
          [116.6686014, 6.8747895],
          [116.6698403, 6.8757135],
          [116.670881, 6.8765244],
          [116.671451, 6.8769662],
          [116.6722964, 6.8776663],
          [116.6727707, 6.8780351],
          [116.6736157, 6.8787214],
          [116.6749514, 6.8799542],
          [116.6760204, 6.8810686],
          [116.6770321, 6.8822426],
          [116.6787173, 6.8841549],
          [116.6796628, 6.8852387],
          [116.6802743, 6.8860588],
          [116.6806445, 6.8864583],
          [116.6812413, 6.8878653],
          [116.6813316, 6.8880412],
          [116.6815229, 6.8883183],
          [116.6816651, 6.8884887],
          [116.6819387, 6.8886857],
          [116.6820942, 6.8888216],
          [116.6822203, 6.8889867],
          [116.6824644, 6.8893195],
          [116.6832147, 6.8902431],
          [116.6838591, 6.8911356],
          [116.6849499, 6.8927512],
          [116.6863103, 6.895122],
          [116.6870684, 6.8959094],
          [116.6883293, 6.8982422],
          [116.6898482, 6.9019407],
          [116.6919402, 6.906009],
          [116.6941754, 6.9110161],
          [116.695551, 6.9135196],
          [116.6978436, 6.9164498],
          [116.7000215, 6.9193516],
          [116.7019416, 6.9222249],
          [116.7030592, 6.9243869],
          [116.7048073, 6.9277723],
          [116.7061828, 6.9314705],
          [116.7071285, 6.9353678],
          [116.7078736, 6.9386108],
          [116.7083608, 6.9420814],
          [116.7085041, 6.9457795],
          [116.7085631, 6.9473464],
          [116.7088108, 6.9482103],
          [116.7088396, 6.9486197],
          [116.7086098, 6.9492471],
          [116.7082268, 6.9497033],
          [116.7076714, 6.9502927],
          [116.7072501, 6.9505969],
          [116.7069245, 6.9507109],
          [116.7066181, 6.9505969],
          [116.7060818, 6.9502356],
          [116.7058583, 6.9501107],
          [116.7055468, 6.9499158],
          [116.705066, 6.9497746],
          [116.7046124, 6.949909],
          [116.7043957, 6.9500367],
          [116.7043269, 6.9501735],
          [116.7032243, 6.951334],
          [116.7032283, 6.9514976],
          [116.7034077, 6.9515043],
          [116.7034297, 6.9515329],
          [116.7034094, 6.951627],
          [116.703357, 6.9516841],
          [116.7032385, 6.9517497],
          [116.7024888, 6.9534442],
          [116.7027639, 6.9541166],
          [116.7031227, 6.9541972],
          [116.7033868, 6.9543048],
          [116.7036103, 6.9545534],
          [116.7036577, 6.9548021],
          [116.7036103, 6.954997],
          [116.7036712, 6.9551315],
          [116.7038879, 6.9551449],
          [116.7040571, 6.9551382],
          [116.7042129, 6.9553062],
          [116.7043348, 6.9555549],
          [116.7043551, 6.95577],
          [116.7042806, 6.9561397],
          [116.7041926, 6.9563816],
          [116.7039956, 6.9565381],
          [116.7038328, 6.9566997],
          [116.7036413, 6.9568137],
          [116.7034497, 6.9568993],
          [116.7032965, 6.9569658],
          [116.7032295, 6.9570894],
          [116.7032151, 6.9572224],
          [116.703172, 6.957346],
          [116.7032678, 6.9574078],
          [116.703354, 6.9573365],
          [116.7034737, 6.957308],
          [116.7036173, 6.9574268],
          [116.7037035, 6.9575551],
          [116.7037562, 6.9576644],
          [116.7038184, 6.9577928],
          [116.7039237, 6.9578926],
          [116.7039716, 6.9580684],
          [116.7040339, 6.958249],
          [116.7041057, 6.9583916],
          [116.7041679, 6.9584629],
          [116.7043451, 6.9584771],
          [116.7045462, 6.9584724],
          [116.7046994, 6.9585009],
          [116.7048143, 6.9585532],
          [116.7049196, 6.9588288],
          [116.7050154, 6.9590047],
          [116.7051016, 6.9590997],
          [116.7052021, 6.9593136],
          [116.7053026, 6.9594942],
          [116.705384, 6.9596082],
          [116.705542, 6.9596938],
          [116.7056713, 6.9598506],
          [116.7057527, 6.9599552],
          [116.7058197, 6.9599789],
          [116.7058963, 6.9599219],
          [116.7060256, 6.9598696],
          [116.7061214, 6.9598459],
          [116.7062889, 6.9598126],
          [116.7063895, 6.9597413],
          [116.7066002, 6.9597508],
          [116.7067869, 6.9597984],
          [116.707213, 6.9599599],
          [116.707462, 6.9600265],
          [116.7077588, 6.96015],
          [116.7079647, 6.9602974],
          [116.7083286, 6.9605588],
          [116.7084674, 6.9606728],
          [116.7085919, 6.9607204],
          [116.7088026, 6.9607584],
          [116.709018, 6.9607821],
          [116.7092526, 6.9608582],
          [116.7095208, 6.9609295],
          [116.7097649, 6.9610293],
          [116.7100235, 6.9610625],
          [116.7102102, 6.9610815],
          [116.7105071, 6.9611386],
          [116.7107991, 6.9612289],
          [116.7109044, 6.9613667],
          [116.7110337, 6.9613857],
          [116.7111103, 6.961457],
          [116.7112444, 6.9614237],
          [116.7113737, 6.961381],
          [116.7115077, 6.9613857],
          [116.7115939, 6.9613477],
          [116.7117136, 6.9610958],
          [116.7117998, 6.9608962],
          [116.7117998, 6.9607251],
          [116.7118907, 6.9607061],
          [116.7120296, 6.9607631],
          [116.712202, 6.9607869],
          [116.7124318, 6.9608344],
          [116.712585, 6.9608914],
          [116.7127526, 6.9609532],
          [116.7129393, 6.9609627],
          [116.713059, 6.9608392],
          [116.713193, 6.9607869],
          [116.7132792, 6.9606491],
          [116.7134516, 6.9605778],
          [116.7134947, 6.9604637],
          [116.7136192, 6.9604162],
          [116.713758, 6.9604209],
          [116.7138538, 6.9603449],
          [116.713983, 6.9603069],
          [116.7142368, 6.9603354],
          [116.7146246, 6.9604875],
          [116.7150364, 6.9606918],
          [116.7154721, 6.960977],
          [116.7160849, 6.9614332],
          [116.7165972, 6.9617897],
          [116.7170473, 6.9620368],
          [116.7176218, 6.9622982],
          [116.7181628, 6.9624978],
          [116.7188236, 6.9627592],
          [116.7196279, 6.9629636],
          [116.7205041, 6.9632677],
          [116.721026, 6.9634816],
          [116.7214856, 6.9637762],
          [116.721816, 6.9639759],
          [116.7221416, 6.9640899],
          [116.7225246, 6.9642182],
          [116.7230082, 6.9645176],
          [116.723463, 6.9649406],
          [116.7238221, 6.9653208],
          [116.724028, 6.965606],
          [116.7242243, 6.9659814],
          [116.7243966, 6.9663616],
          [116.7244637, 6.9667133],
          [116.7245642, 6.9668987],
          [116.7247174, 6.9669937],
          [116.7248706, 6.9671695],
          [116.7247654, 6.9675054],
          [116.7246504, 6.9679822],
          [116.7245259, 6.9681913],
          [116.7244302, 6.9682246],
          [116.7242482, 6.9682103],
          [116.7241477, 6.9682816],
          [116.7240328, 6.9682959],
          [116.7238891, 6.9683149],
          [116.7238221, 6.9683862],
          [116.7237024, 6.9684955],
          [116.7236066, 6.9685478],
          [116.7234726, 6.9684955],
          [116.7233433, 6.9684337],
          [116.7231853, 6.9684195],
          [116.7229986, 6.9684717],
          [116.722831, 6.9685193],
          [116.7227305, 6.9686238],
          [116.7225964, 6.9687664],
          [116.7224815, 6.9688757],
          [116.7225006, 6.9689898],
          [116.7225581, 6.9691656],
          [116.7225342, 6.9693272],
          [116.7225198, 6.969427],
          [116.7224288, 6.9695363],
          [116.7223235, 6.9696171],
          [116.7222613, 6.9697311],
          [116.7221224, 6.9698214],
          [116.7220506, 6.9698832],
          [116.7219309, 6.969907],
          [116.7218447, 6.9699688],
          [116.7217011, 6.9700163],
          [116.7216005, 6.9701066],
          [116.7215431, 6.9701779],
          [116.7214425, 6.9702919],
          [116.7213755, 6.9704345],
          [116.721251, 6.9705296],
          [116.7212414, 6.9705913],
          [116.7211553, 6.9707292],
          [116.7210787, 6.9708147],
          [116.7210451, 6.9709573],
          [116.7210499, 6.9710856],
          [116.7209925, 6.9711902],
          [116.7208824, 6.9713185],
          [116.720777, 6.971442],
          [116.720686, 6.9715513],
          [116.7206956, 6.9716464],
          [116.7207435, 6.9717842],
          [116.7207052, 6.9719505],
          [116.7206238, 6.9720599],
          [116.7205664, 6.9721882],
          [116.720552, 6.9723212],
          [116.7205472, 6.9723973],
          [116.7206573, 6.9724971],
          [116.7207052, 6.9726301],
          [116.7206477, 6.972768],
          [116.7205664, 6.9728915],
          [116.7206382, 6.9730056],
          [116.7206573, 6.9731624],
          [116.72071, 6.9733335],
          [116.7207866, 6.9734238],
          [116.7209398, 6.9734951],
          [116.7210882, 6.9734808],
          [116.7212175, 6.9734523],
          [116.7214473, 6.973381],
          [116.7216628, 6.973381],
          [116.7218208, 6.9733573],
          [116.7218926, 6.9732575],
          [116.7220362, 6.973229],
          [116.7221368, 6.9731624],
          [116.7222852, 6.9730294],
          [116.7223857, 6.9728773],
          [116.7224288, 6.9726397],
          [116.7224576, 6.9724638],
          [116.7224719, 6.9722214],
          [116.7225054, 6.9720741],
          [116.7225198, 6.9719315],
          [116.7225916, 6.9718365],
          [116.7227688, 6.9717509],
          [116.723013, 6.9716084],
          [116.7231566, 6.9715181],
          [116.7233098, 6.9714896],
          [116.7234534, 6.9715561],
          [116.7235013, 6.9716559],
          [116.7236545, 6.9716559],
          [116.7237455, 6.9715561],
          [116.7237742, 6.9713612],
          [116.7238987, 6.9710333],
          [116.724028, 6.9707767],
          [116.724186, 6.9707149],
          [116.7243871, 6.9706341],
          [116.7245929, 6.9705961],
          [116.7248276, 6.9706389],
          [116.7250047, 6.9706769],
          [116.7251579, 6.9707387],
          [116.7253542, 6.9707102],
          [116.7255314, 6.9707672],
          [116.7257325, 6.9708765],
          [116.7260485, 6.9711474],
          [116.7263262, 6.9714563],
          [116.7266038, 6.9717937],
          [116.7268145, 6.9721692],
          [116.7269103, 6.9725921],
          [116.7269438, 6.9732242],
          [116.7268911, 6.973785],
          [116.7268337, 6.9741747],
          [116.7267188, 6.9744598],
          [116.7266469, 6.9747735],
          [116.726623, 6.9749874],
          [116.7267283, 6.9751299],
          [116.7269294, 6.9751727],
          [116.7268289, 6.9752963],
          [116.7267188, 6.9754293],
          [116.7266326, 6.9755149],
          [116.7263166, 6.9755101],
          [116.7260772, 6.9754911],
          [116.7257564, 6.9754816],
          [116.7255409, 6.9755244],
          [116.7253877, 6.9755814],
          [116.7252058, 6.9756004],
          [116.7249903, 6.9755434],
          [116.7248371, 6.9755339],
          [116.7243919, 6.9755862],
          [116.7241908, 6.9756099],
          [116.7238125, 6.9756622],
          [116.7235013, 6.9757192],
          [116.72308, 6.9759378],
          [116.7229076, 6.9760852],
          [116.7227592, 6.9762515],
          [116.7226156, 6.9763846],
          [116.722582, 6.9765604],
          [116.7224863, 6.9766555],
          [116.722357, 6.9767648],
          [116.7222086, 6.9768265],
          [116.7220362, 6.9767458],
          [116.7218016, 6.9766555],
          [116.7216149, 6.9766364],
          [116.7215, 6.9766792],
          [116.7211744, 6.9768788],
          [116.720959, 6.9770452],
          [116.72071, 6.9772162],
          [116.7205472, 6.9773778],
          [116.7204227, 6.9775442],
          [116.7202982, 6.977758],
          [116.7202551, 6.9779814],
          [116.7203126, 6.9781905],
          [116.7203796, 6.9783188],
          [116.720485, 6.9784519],
          [116.7205951, 6.9785612],
          [116.7207818, 6.9786942],
          [116.7209254, 6.9787703],
          [116.7210739, 6.9788178],
          [116.7212175, 6.9787798],
          [116.7213276, 6.9790079],
          [116.7214808, 6.9790364],
          [116.7215335, 6.97916],
          [116.7215957, 6.979236],
          [116.7216293, 6.9793168],
          [116.7216053, 6.9793881],
          [116.7217633, 6.9794499],
          [116.7218687, 6.9794404],
          [116.7219931, 6.9794261],
          [116.7220745, 6.9794831],
          [116.7222277, 6.9793976],
          [116.7223379, 6.9794119],
          [116.7225533, 6.9795877],
          [116.7226778, 6.9797778],
          [116.7227065, 6.9799441],
          [116.7229268, 6.9798918],
          [116.7230943, 6.9797683],
          [116.7232715, 6.9797493],
          [116.723554, 6.9799916],
          [116.7238843, 6.9804194],
          [116.7241429, 6.9809374],
          [116.7244158, 6.9817215],
          [116.7247962, 6.9829815],
          [116.7249723, 6.9839359],
          [116.7251077, 6.9848634],
          [116.7249723, 6.9857774],
          [116.7247556, 6.9863688],
          [116.7243222, 6.987014],
          [116.7237839, 6.9875382],
          [116.7234623, 6.9878239],
          [116.7230493, 6.9880625],
          [116.7234792, 6.9878726],
          [116.7237636, 6.9877247],
          [116.7243527, 6.9875937],
          [116.7249215, 6.9875903],
          [116.7254699, 6.987681],
          [116.7259541, 6.987839],
          [116.726445, 6.9880036],
          [116.7268851, 6.9882355],
          [116.7269478, 6.9885816],
          [116.7268688, 6.9889547],
          [116.7267443, 6.9893135],
          [116.7267276, 6.989658],
          [116.7269205, 6.9902115],
          [116.7264822, 6.9925737],
          [116.7265635, 6.9927552],
          [116.7266989, 6.992856],
          [116.7268614, 6.9928728],
          [116.7269765, 6.9929165],
          [116.7271322, 6.9928896],
          [116.7272338, 6.9928526],
          [116.7273489, 6.9927283],
          [116.7274911, 6.9927485],
          [116.7277518, 6.9928762],
          [116.7282122, 6.9933735],
          [116.7285372, 6.9939415],
          [116.7290275, 6.9949235],
          [116.7294067, 6.9960929],
          [116.7297452, 6.997222],
          [116.7299077, 6.9980553],
          [116.729813, 6.9986467],
          [116.7297168, 6.9988844],
          [116.7295684, 6.998989],
          [116.7294008, 6.9991791],
          [116.7293099, 6.9992979],
          [116.7292524, 6.9994404],
          [116.7292668, 6.999621],
          [116.7294056, 6.9998206],
          [116.7295109, 7.0000202],
          [116.7295205, 7.0002531],
          [116.729595924170923, 7.0005],
          [116.7296163, 7.0005667],
          [116.7297025, 7.000714],
          [116.72987, 7.001137],
          [116.7300663, 7.0013841],
          [116.7303105, 7.0017025],
          [116.7305403, 7.0019686],
          [116.730818, 7.0024153],
          [116.7311101, 7.0029475],
          [116.7313974, 7.0038029],
          [116.7315745, 7.0044635],
          [116.731699, 7.0056277],
          [116.7317469, 7.00683],
          [116.7314969, 7.0082424],
          [116.7312646, 7.0084031],
          [116.7308997, 7.0087843],
          [116.7307076, 7.0092037],
          [116.7300739, 7.0095086],
          [116.7297474, 7.0099661],
          [116.7294401, 7.0101186],
          [116.7292096, 7.0104808],
          [116.7290368, 7.0107476],
          [116.7287487, 7.0111098],
          [116.7284606, 7.011167],
          [116.7280573, 7.0113767],
          [116.7280189, 7.011491],
          [116.7284222, 7.0115673],
          [116.7288063, 7.0117007],
          [116.7290176, 7.0119295],
          [116.7291712, 7.0120057],
          [116.7293441, 7.0119866],
          [116.7296129, 7.0118151],
          [116.7298434, 7.0119295],
          [116.7299586, 7.0117388],
          [116.7302851, 7.0116626],
          [116.7305348, 7.0118341],
          [116.7304772, 7.0120629],
          [116.7306692, 7.0118723],
          [116.7308805, 7.0120438],
          [116.7315898, 7.0127043],
          [116.7319652, 7.0132483],
          [116.7320194, 7.0135875],
          [116.7320725, 7.0137275],
          [116.7320939, 7.0138766],
          [116.7320939, 7.0140789],
          [116.7319974, 7.0141534],
          [116.7317935, 7.0142386],
          [116.7316755, 7.0143877],
          [116.7316004, 7.0144516],
          [116.7314609, 7.0144942],
          [116.7313966, 7.014622],
          [116.7313751, 7.0148988],
          [116.7314824, 7.0151011],
          [116.7315038, 7.0152715],
          [116.7316219, 7.0154525],
          [116.7317613, 7.0155803],
          [116.7319008, 7.0155803],
          [116.7319652, 7.0156868],
          [116.7321583, 7.0156655],
          [116.7323622, 7.0155803],
          [116.7325875, 7.0154312],
          [116.732802, 7.0153886],
          [116.7329522, 7.0151757],
          [116.7330917, 7.0150053],
          [116.7330703, 7.0148349],
          [116.7332205, 7.0147391],
          [116.7334865, 7.0145768],
          [116.7339063, 7.0146709],
          [116.7340823, 7.0149397],
          [116.7341636, 7.0153967],
          [116.7343938, 7.015773],
          [116.7346375, 7.0160149],
          [116.7348813, 7.0161493],
          [116.7352199, 7.0162703],
          [116.7356126, 7.0162972],
          [116.7360053, 7.0162434],
          [116.7363032, 7.0159074],
          [116.7366689, 7.0155311],
          [116.7369126, 7.0154773],
          [116.7375491, 7.0156789],
          [116.7384023, 7.0162165],
          [116.7397565, 7.0173321],
          [116.7407586, 7.0183267],
          [116.7417065, 7.0195364],
          [116.7424378, 7.0209073],
          [116.7431962, 7.0223589],
          [116.7439816, 7.024133],
          [116.7446452, 7.0260282],
          [116.7454848, 7.0286221],
          [116.7456744, 7.0299393],
          [116.7456744, 7.0311489],
          [116.7452275, 7.0325333],
          [116.7449228, 7.0332859],
          [116.7446858, 7.0337295],
          [116.7445842, 7.0338706],
          [116.7444285, 7.0340856],
          [116.7444624, 7.0342536],
          [116.7443676, 7.0344821],
          [116.7442321, 7.0346299],
          [116.7440696, 7.0345963],
          [116.7438123, 7.034556],
          [116.743467, 7.0345963],
          [116.7432232, 7.0346568],
          [116.7429321, 7.0347778],
          [116.7428034, 7.0349458],
          [116.7427628, 7.0352079],
          [116.742756, 7.0354095],
          [116.7426545, 7.0355775],
          [116.742492, 7.0356984],
          [116.7424784, 7.0358328],
          [116.7426139, 7.0357589],
          [116.7427493, 7.0357522],
          [116.7428373, 7.0359135],
          [116.7427831, 7.0360815],
          [116.742756, 7.0362495],
          [116.7426477, 7.0363301],
          [116.7424717, 7.0363436],
          [116.7423024, 7.036404],
          [116.7421263, 7.0364847],
          [116.7421805, 7.0366056],
          [116.7421399, 7.0367199],
          [116.7419909, 7.0368073],
          [116.7422414, 7.0368073],
          [116.742255, 7.0369551],
          [116.7420347, 7.0370987],
          [116.7418555, 7.037197],
          [116.7415466, 7.0373471],
          [116.7414727, 7.0374276],
          [116.7415015, 7.0374623],
          [116.7415779, 7.0374591],
          [116.7418431, 7.0373909],
          [116.7421787, 7.0373014],
          [116.7422086, 7.0373462],
          [116.7428966, 7.0370539],
          [116.7431222, 7.0369156],
          [116.743461, 7.036576],
          [116.7437139, 7.0366015],
          [116.7440174, 7.0364884],
          [116.7443257, 7.0361895],
          [116.7445783, 7.0361142],
          [116.7447209, 7.0360716],
          [116.7448943, 7.0359444],
          [116.7448634, 7.0359011],
          [116.7450502, 7.0357877],
          [116.7452662, 7.0359304],
          [116.7457202, 7.0358755],
          [116.745846, 7.0356253],
          [116.7461173, 7.0351563],
          [116.7460707, 7.0350544],
          [116.7463913, 7.0348599],
          [116.7467337, 7.0348223],
          [116.7473633, 7.0348644],
          [116.7481778, 7.0350709],
          [116.7489237, 7.0350633],
          [116.7493241, 7.0350432],
          [116.7498304, 7.0351264],
          [116.7503545, 7.035018],
          [116.7515692, 7.0350606],
          [116.7521549, 7.0351045],
          [116.7539972, 7.0348372],
          [116.7546435, 7.0342864],
          [116.7547389, 7.0337396],
          [116.7561554, 7.0329987],
          [116.756198, 7.0315712],
          [116.7577594, 7.0305905],
          [116.7577824, 7.0302107],
          [116.7580603, 7.0300627],
          [116.758257, 7.0305643],
          [116.75854, 7.0301676],
          [116.7589441, 7.0300328],
          [116.7590059, 7.0298241],
          [116.7589456, 7.029266],
          [116.7586519, 7.0290094],
          [116.7587656, 7.0286101],
          [116.7608814, 7.0271463],
          [116.7618439, 7.0265853],
          [116.7628068, 7.026211],
          [116.7632049, 7.0254414],
          [116.7628006, 7.0250036],
          [116.7627277, 7.0248957],
          [116.7626499, 7.0247805],
          [116.7628012, 7.0243482],
          [116.762835, 7.0237423],
          [116.7631911, 7.0237802],
          [116.7638859, 7.024117],
          [116.7644392, 7.0242542],
          [116.7648372, 7.024585],
          [116.7649781, 7.023887],
          [116.7684779, 7.0227181],
          [116.7744861, 7.015392],
          [116.7798076, 7.0101102],
          [116.7808292, 7.0072075],
          [116.7848896, 7.0025732],
          [116.7867175, 7.0017389],
          [116.7892578, 7.0005794],
          [116.790334278832574, 6.9995],
          [116.7911372, 6.9986949],
          [116.7914128, 6.9969506],
          [116.793816, 6.9945653],
          [116.7939877, 6.9913281],
          [116.7967343, 6.9896242],
          [116.8008541, 6.9850237],
          [116.8005108, 6.9826384],
          [116.8017124, 6.9797416],
          [116.8011975, 6.9761635],
          [116.8034968, 6.9751814],
          [116.8050418, 6.9739888],
          [116.8062434, 6.9741592],
          [116.8077884, 6.9717736],
          [116.809505, 6.9700697],
          [116.8113933, 6.971092],
          [116.8132815, 6.9717736],
          [116.8147458, 6.9712399],
          [116.8179164, 6.9700697],
          [116.820663, 6.9683657],
          [116.8218646, 6.967173],
          [116.8237529, 6.9666618],
          [116.8254695, 6.9668322],
          [116.8300366, 6.9662807],
          [116.8309969, 6.9642843],
          [116.8322682, 6.9623616],
          [116.8326186, 6.9609128],
          [116.8336415, 6.9594649],
          [116.8334698, 6.9569091],
          [116.8358731, 6.954353],
          [116.8370747, 6.952649],
          [116.837933, 6.9516266],
          [116.8396496, 6.9512858],
          [116.8404516, 6.9494614],
          [116.8401646, 6.9480482],
          [116.8413662, 6.9468554],
          [116.8419567, 6.9455252],
          [116.8403363, 6.9434474],
          [116.8393063, 6.9419137],
          [116.8359906, 6.9389822],
          [116.8360791, 6.9383281],
          [116.8360408, 6.9375455],
          [116.8362725, 6.9368976],
          [116.8363894, 6.935718],
          [116.8367111, 6.932583],
          [116.8370613, 6.9317605],
          [116.8385501, 6.929445],
          [116.8391822, 6.9285272],
          [116.8398934, 6.9279319],
          [116.8403293, 6.9270571],
          [116.8404623, 6.9260674],
          [116.8404688, 6.9260191],
          [116.8419025, 6.9229863],
          [116.8416828, 6.9227488],
          [116.8421427, 6.9213871],
          [116.8431005, 6.9201944],
          [116.845147, 6.9174208],
          [116.8460504, 6.9163377],
          [116.8459992, 6.9162132],
          [116.8458932, 6.9161368],
          [116.8456839, 6.9159145],
          [116.8456833, 6.9155886],
          [116.8458216, 6.9152121],
          [116.8493331, 6.9106312],
          [116.8498554, 6.9090787],
          [116.8503146, 6.9064462],
          [116.8511308, 6.9046871],
          [116.8514211, 6.9032946],
          [116.8522753, 6.9007135],
          [116.8528005, 6.8997721],
          [116.8531614, 6.8991315],
          [116.8547769, 6.8975046],
          [116.8548493, 6.8974317],
          [116.856258, 6.8950201],
          [116.856728, 6.8948137],
          [116.8594508, 6.8952339],
          [116.8606286, 6.8932327],
          [116.8588693, 6.890082],
          [116.8586636, 6.8899631],
          [116.8584564, 6.8899954],
          [116.8582773, 6.8901195],
          [116.8582298, 6.8903819],
          [116.858656, 6.891124],
          [116.8588256, 6.8914773],
          [116.8587594, 6.8918366],
          [116.8585656, 6.8920902],
          [116.8582712, 6.8923176],
          [116.8578403, 6.892377],
          [116.8575182, 6.892242],
          [116.8572759, 6.8918964],
          [116.8571517, 6.8915594],
          [116.8572626, 6.8911257],
          [116.857542, 6.8906362],
          [116.8572459, 6.8879955],
          [116.8572566, 6.8878651],
          [116.8572754, 6.8877639],
          [116.8573102, 6.8876787],
          [116.8573692, 6.8875908],
          [116.8574631, 6.8875002],
          [116.8575516, 6.887455],
          [116.8576697, 6.8874097],
          [116.8577957, 6.8873857],
          [116.8579084, 6.8873911],
          [116.858013, 6.8874177],
          [116.8581069, 6.887471],
          [116.8581954, 6.8875428],
          [116.8584126, 6.8877692],
          [116.8586648, 6.8880701],
          [116.8591824, 6.8886533],
          [116.859228, 6.8886985],
          [116.8592763, 6.8887305],
          [116.8593299, 6.8887571],
          [116.859397, 6.8887704],
          [116.8605021, 6.8886879],
          [116.861693, 6.8885867],
          [116.8617439, 6.8885601],
          [116.86176, 6.8885015],
          [116.8617144, 6.8879849],
          [116.8616822, 6.8876094],
          [116.8615535, 6.8867813],
          [116.8604507, 6.8781989],
          [116.8529958, 6.8785873],
          [116.8529395, 6.8785261],
          [116.8523306, 6.8772479],
          [116.8513664, 6.8753978],
          [116.8493897, 6.8759366],
          [116.848721, 6.8776373],
          [116.8480378, 6.8790721],
          [116.8470951, 6.8793074],
          [116.8466649, 6.878541],
          [116.8460057, 6.877852],
          [116.8459212, 6.8779299],
          [116.8466446, 6.8786988],
          [116.8469879, 6.879305],
          [116.8465741, 6.879432],
          [116.8463766, 6.8792817],
          [116.8460998, 6.8796201],
          [116.8458269, 6.8793743],
          [116.8452634, 6.8800626],
          [116.8463968, 6.8810274],
          [116.846197, 6.8812697],
          [116.8458389, 6.8809742],
          [116.8455962, 6.8812671],
          [116.8461313, 6.8817065],
          [116.846358, 6.8817912],
          [116.8466548, 6.8821984],
          [116.8465067, 6.8823351],
          [116.8453, 6.8815682],
          [116.8449635, 6.8814172],
          [116.844917, 6.8809788],
          [116.8444074, 6.8809175],
          [116.8441904, 6.8811054],
          [116.8422649, 6.8804296],
          [116.8412045, 6.8799754],
          [116.8406716, 6.8799319],
          [116.8404571, 6.8800438],
          [116.8404069, 6.8802567],
          [116.8404491, 6.8805102],
          [116.8408914, 6.8809948],
          [116.8412476, 6.8813025],
          [116.8391453, 6.8860349],
          [116.8382183, 6.8864923],
          [116.8359096, 6.8868966],
          [116.8345478, 6.890294],
          [116.833688, 6.8904351],
          [116.8331747, 6.8910923],
          [116.8328461, 6.8914208],
          [116.8323327, 6.8907432],
          [116.8314496, 6.890086],
          [116.8303612, 6.8893467],
          [116.8294577, 6.8891619],
          [116.8290059, 6.889819],
          [116.8285824, 6.8906913],
          [116.8285927, 6.891369],
          [116.8290239, 6.8920775],
          [116.8294987, 6.89407],
          [116.8290264, 6.8942548],
          [116.8285952, 6.8926941],
          [116.8281023, 6.8916057],
          [116.8271987, 6.8909074],
          [116.8261719, 6.8904967],
          [116.8234201, 6.8892646],
          [116.8201344, 6.8876423],
          [116.8182451, 6.8880324],
          [116.817588, 6.8880289],
          [116.8178494, 6.8868672],
          [116.8187787, 6.8862573],
          [116.8194757, 6.8853425],
          [116.819011, 6.8843696],
          [116.8190982, 6.8828594],
          [116.8195193, 6.8815671],
          [116.8206084, 6.8801004],
          [116.8213054, 6.8792437],
          [116.821073, 6.8787064],
          [116.8195483, 6.877748],
          [116.8189384, 6.8777335],
          [116.8181833, 6.8778642],
          [116.8171378, 6.8782708],
          [116.8162375, 6.8779804],
          [116.8145241, 6.8776464],
          [116.8136092, 6.8771817],
          [116.8129993, 6.87602],
          [116.8130139, 6.8743211],
          [116.8136238, 6.8738855],
          [116.8148145, 6.873232],
          [116.8150759, 6.8727528],
          [116.8148, 6.8723462],
          [116.8142046, 6.8718235],
          [116.8116688, 6.8691461],
          [116.804459, 6.8725546],
          [116.8017124, 6.8768154],
          [116.7960854, 6.8821223],
          [116.78884, 6.8857965],
          [116.7870446, 6.8825899],
          [116.7881614, 6.8780812],
          [116.7912138, 6.8741637],
          [116.7945876, 6.8718729],
          [116.7915116, 6.8696549],
          [116.7889059, 6.8687679],
          [116.7877147, 6.8659591],
          [116.7902111, 6.8642036],
          [116.8005915, 6.8649413],
          [116.8017124, 6.8633514],
          [116.800229114191993, 6.862218663096428],
          [116.7994808, 6.8616472],
          [116.7986225, 6.8602837],
          [116.7994808, 6.8585793],
          [116.8016546, 6.8582937],
          [116.8001675, 6.8546593],
          [116.7957603, 6.8525817],
          [116.7952426, 6.8520965],
          [116.7972047, 6.8514072],
          [116.7992256, 6.8513701],
          [116.8009314, 6.8511476],
          [116.8020558, 6.8509099],
          [116.8036013, 6.8511847],
          [116.8045469, 6.8512589],
          [116.8051402, 6.8509993],
          [116.8061599, 6.8512218],
          [116.8056964, 6.8517039],
          [116.8049362, 6.8520191],
          [116.8045654, 6.8527792],
          [116.8049177, 6.8536692],
          [116.8058818, 6.8548187],
          [116.8064381, 6.8552081],
          [116.8068274, 6.8557643],
          [116.8074764, 6.856061],
          [116.8083292, 6.8560054],
          [116.809145, 6.8557829],
          [116.808904, 6.856543],
          [116.8088113, 6.8575628],
          [116.808459, 6.8587865],
          [116.8080326, 6.8597692],
          [116.8081067, 6.8601956],
          [116.8086444, 6.860585],
          [116.8094973, 6.86103],
          [116.8101463, 6.8610485],
          [116.811166, 6.8606591],
          [116.8120004, 6.8604923],
          [116.8134095, 6.8599917],
          [116.8151709, 6.8601215],
          [116.8160794, 6.8602142],
          [116.8184897, 6.8608446],
          [116.8198617, 6.8609373],
          [116.8209742, 6.860585],
          [116.8226985, 6.8592129],
          [116.8237182, 6.8587123],
          [116.8244413, 6.8591944],
          [116.8258875, 6.8601585],
          [116.8302141, 6.8613436],
          [116.8341564, 6.8614768],
          [116.837418, 6.8578976],
          [116.8401646, 6.8556819],
          [116.8422245, 6.8565341],
          [116.8461727, 6.8555115],
          [116.8473744, 6.8563637],
          [116.8492626, 6.8561932],
          [116.8506405, 6.8573718],
          [116.8545841, 6.8573863],
          [116.8571591, 6.858068],
          [116.8595623, 6.8555115],
          [116.848576, 6.8471602],
          [116.8457023, 6.8461794],
          [116.8430828, 6.8452854],
          [116.84214, 6.8434327],
          [116.8403363, 6.842388],
          [116.8402306, 6.8411051],
          [116.8396496, 6.8406835],
          [116.8405079, 6.8400018],
          [116.8405079, 6.8382974],
          [116.8401646, 6.8371043],
          [116.8411946, 6.8364225],
          [116.8422114, 6.836077],
          [116.843122, 6.8355463],
          [116.8437215, 6.834023],
          [116.8452172, 6.8316147],
          [116.8461301, 6.8300854],
          [116.8477177, 6.8297753],
          [116.8481466, 6.8295062],
          [116.8484348, 6.828985],
          [116.8482128, 6.8278321],
          [116.8503639, 6.8235121],
          [116.8510803, 6.8234613],
          [116.8529899, 6.8232754],
          [116.854708, 6.8228039],
          [116.8556762, 6.8232921],
          [116.8556501, 6.8243241],
          [116.8557141, 6.8263439],
          [116.8557939, 6.8263863],
          [116.8557305, 6.8243532],
          [116.8557616, 6.8233147],
          [116.856228, 6.8235663],
          [116.857085, 6.8231673],
          [116.858704, 6.8224462],
          [116.8599476, 6.8216668],
          [116.8616865, 6.82151],
          [116.8631684, 6.822146],
          [116.8649714, 6.8240926],
          [116.8646904, 6.8255282],
          [116.8641811, 6.8271816],
          [116.8685887, 6.8232848],
          [116.8681578, 6.8227834],
          [116.8666823, 6.8231169],
          [116.8616506, 6.8210343],
          [116.8603825, 6.819107],
          [116.8587683, 6.8200139],
          [116.8574057, 6.8195977],
          [116.8563606, 6.8188128],
          [116.8556678, 6.8177566],
          [116.8563998, 6.8170834],
          [116.8566569, 6.8169039],
          [116.8565871, 6.8167612],
          [116.8552149, 6.8180991],
          [116.8543682, 6.8174483],
          [116.8540293, 6.8169219],
          [116.8539507, 6.8157974],
          [116.8538997, 6.8152668],
          [116.853667, 6.8144641],
          [116.8535746, 6.8138343],
          [116.8537446, 6.8130864],
          [116.8541961, 6.8127256],
          [116.8539082, 6.8115853],
          [116.8538508, 6.8108437],
          [116.853923, 6.8103142],
          [116.8541865, 6.8097393],
          [116.8546338, 6.809216],
          [116.854416, 6.8084713],
          [116.8542662, 6.8080683],
          [116.8542003, 6.8076601],
          [116.8543841, 6.8072487],
          [116.8542534, 6.8069691],
          [116.853292, 6.8068594],
          [116.8531178, 6.8064775],
          [116.8532707, 6.8061537],
          [116.8538944, 6.8060387],
          [116.8540006, 6.8059807],
          [116.8546486, 6.8058868],
          [116.854536, 6.8057581],
          [116.8545435, 6.8058689],
          [116.8542067, 6.805929],
          [116.8539071, 6.8057708],
          [116.8538859, 6.8059733],
          [116.85331, 6.8060313],
          [116.8532431, 6.805602],
          [116.8529032, 6.8052317],
          [116.8526503, 6.8045925],
          [116.8526482, 6.8038635],
          [116.8522721, 6.8035439],
          [116.8521945, 6.8033667],
          [116.8516708, 6.8029953],
          [116.8520341, 6.8025966],
          [116.8516304, 6.802048],
          [116.8508315, 6.8015364],
          [116.8506998, 6.8012832],
          [116.8507635, 6.8006777],
          [116.8513542, 6.8004741],
          [116.8513266, 6.7993011],
          [116.8520532, 6.7978938],
          [116.852134, 6.7974276],
          [116.8518471, 6.7968537],
          [116.8518578, 6.7965161],
          [116.8520129, 6.7962144],
          [116.8516984, 6.7957334],
          [116.8516527, 6.7952798],
          [116.8512076, 6.7948694],
          [116.8508676, 6.7934062],
          [116.8509399, 6.7925496],
          [116.851299, 6.7923344],
          [116.8515178, 6.7910063],
          [116.852186, 6.7893121],
          [116.8526376, 6.7883827],
          [116.8528288, 6.7877824],
          [116.8531517, 6.7871347],
          [116.854433, 6.7850364],
          [116.8547315, 6.7845047],
          [116.8550608, 6.7838243],
          [116.8549939, 6.7834319],
          [116.8554072, 6.7828875],
          [116.8552414, 6.7826765],
          [116.8550991, 6.7820488],
          [116.8550683, 6.7814074],
          [116.8554082, 6.7804833],
          [116.8558566, 6.7797301],
          [116.856698, 6.7795845],
          [116.8570039, 6.7787553],
          [116.8564557, 6.7775611],
          [116.8582724, 6.7740607],
          [116.8581747, 6.7732695],
          [116.8583999, 6.7719803],
          [116.8581492, 6.7709802],
          [116.8562199, 6.770708],
          [116.854382, 6.7711321],
          [116.8522317, 6.7709655],
          [116.8502918, 6.7705709],
          [116.8494334, 6.7698831],
          [116.8482053, 6.7672382],
          [116.8483965, 6.7670431],
          [116.8485644, 6.7666253],
          [116.8485262, 6.766293],
          [116.8482096, 6.7658931],
          [116.8481394, 6.7655745],
          [116.8482956, 6.7651937],
          [116.8482021, 6.764759],
          [116.8482276, 6.7643075],
          [116.8480555, 6.7639952],
          [116.8479121, 6.7635996],
          [116.8475647, 6.7630415],
          [116.8474903, 6.7626586],
          [116.8472269, 6.7624518],
          [116.8471217, 6.7617449],
          [116.8468359, 6.7613873],
          [116.8460912, 6.7602152],
          [116.8458352, 6.7600612],
          [116.8451053, 6.7598861],
          [116.8448216, 6.7596666],
          [116.8445996, 6.7591655],
          [116.8443786, 6.7591275],
          [116.8440737, 6.7589228],
          [116.8439377, 6.7587182],
          [116.8439994, 6.758465],
          [116.8437322, 6.7582614],
          [116.8437279, 6.7579565],
          [116.8432546, 6.7573198],
          [116.843158, 6.7570281],
          [116.8428552, 6.756806],
          [116.8426725, 6.756537],
          [116.8422539, 6.7561719],
          [116.8420541, 6.7559667],
          [116.8420308, 6.7557684],
          [116.841847, 6.7552842],
          [116.8418342, 6.7549618],
          [116.8414157, 6.7546206],
          [116.8408776, 6.7545346],
          [116.8407495, 6.7544354],
          [116.8405689, 6.7541442],
          [116.8404686, 6.7539712],
          [116.8404516, 6.753794],
          [116.8405047, 6.7537333],
          [116.8404813, 6.7536062],
          [116.8402656, 6.753421],
          [116.839991, 6.7533625],
          [116.8398789, 6.753277],
          [116.8396755, 6.7530022],
          [116.8395448, 6.752653],
          [116.839565, 6.7524884],
          [116.8394471, 6.7520368],
          [116.8394896, 6.751771],
          [116.8392442, 6.7510989],
          [116.8389786, 6.7506611],
          [116.8387533, 6.7506157],
          [116.8386365, 6.7502887],
          [116.8386747, 6.7500281],
          [116.8384952, 6.7495607],
          [116.8379789, 6.7488475],
          [116.8374965, 6.7484477],
          [116.8364374, 6.7478579],
          [116.8358785, 6.7474697],
          [116.8350765, 6.7468936],
          [116.8345081, 6.7464252],
          [116.8343477, 6.7460444],
          [116.8340523, 6.7459853],
          [116.8336699, 6.745558],
          [116.833757, 6.7452278],
          [116.8329283, 6.7444397],
          [116.8326978, 6.7440936],
          [116.8322771, 6.7437507],
          [116.8319786, 6.7433551],
          [116.8312901, 6.7432517],
          [116.8311584, 6.7429816],
          [116.8305582, 6.7426408],
          [116.8299632, 6.7427938],
          [116.8296307, 6.7427611],
          [116.8293194, 6.7425132],
          [116.8291239, 6.7422821],
          [116.8287367, 6.7419192],
          [116.828546, 6.7416723],
          [116.8284122, 6.7414418],
          [116.8281843, 6.7414107],
          [116.8280191, 6.7412419],
          [116.8275378, 6.7409217],
          [116.8274225, 6.7408304],
          [116.8270651, 6.7406911],
          [116.826315, 6.7402913],
          [116.826144, 6.7399589],
          [116.8258539, 6.7398745],
          [116.8255783, 6.7396403],
          [116.825285, 6.7392452],
          [116.8250933, 6.7389398],
          [116.8249892, 6.7386808],
          [116.8249291, 6.7386148],
          [116.8247236, 6.7385167],
          [116.8245515, 6.7381327],
          [116.8242731, 6.7378104],
          [116.824075, 6.7377977],
          [116.8235815, 6.7375851],
          [116.8234004, 6.7373623],
          [116.8226679, 6.7369094],
          [116.8224735, 6.7366151],
          [116.8222684, 6.7363924],
          [116.8220018, 6.73606],
          [116.8217346, 6.735964],
          [116.8215588, 6.7361169],
          [116.8212374, 6.7360664],
          [116.8209798, 6.7357878],
          [116.820881, 6.7355879],
          [116.8208533, 6.7354349],
          [116.8205915, 6.7350562],
          [116.8203652, 6.7348536],
          [116.8204441, 6.7347286],
          [116.8203115, 6.73467],
          [116.8198602, 6.7348552],
          [116.819945, 6.7345803],
          [116.8197198, 6.7343862],
          [116.8191415, 6.7343325],
          [116.8189415, 6.734014],
          [116.8186924, 6.7338466],
          [116.8182718, 6.7336506],
          [116.8180763, 6.7333955],
          [116.8178267, 6.7332382],
          [116.8175451, 6.7328247],
          [116.8173507, 6.7324143],
          [116.8172561, 6.7320989],
          [116.8172614, 6.7316251],
          [116.8170808, 6.7309795],
          [116.8165592, 6.7304873],
          [116.8162888, 6.7300141],
          [116.8159762, 6.7295663],
          [116.8156213, 6.7292621],
          [116.8151069, 6.729476],
          [116.8152408, 6.7292449],
          [116.815347, 6.7287891],
          [116.8153088, 6.7284378],
          [116.8152153, 6.7282395],
          [116.8151446, 6.7280211],
          [116.8151117, 6.7275589],
          [116.8152461, 6.7272604],
          [116.8155951, 6.7271253],
          [116.815427, 6.7267781],
          [116.8151059, 6.7265584],
          [116.8149678, 6.7262507],
          [116.8150156, 6.7260291],
          [116.814836, 6.7258487],
          [116.8148102, 6.7255699],
          [116.8144323, 6.7252853],
          [116.8145247, 6.7251745],
          [116.8143112, 6.7249856],
          [116.8143624, 6.7246996],
          [116.8141997, 6.72461],
          [116.8141427, 6.7242687],
          [116.8140584, 6.7238114],
          [116.8141497, 6.7235033],
          [116.8140095, 6.7231034],
          [116.8140052, 6.7228491],
          [116.8138047, 6.7220043],
          [116.8136483, 6.7217993],
          [116.8134656, 6.7217376],
          [116.8133402, 6.7214675],
          [116.8132978, 6.7210749],
          [116.8129561, 6.7210165],
          [116.8128494, 6.7209431],
          [116.8127001, 6.7207548],
          [116.812562, 6.7204784],
          [116.8125397, 6.7202948],
          [116.812571, 6.7201038],
          [116.8124744, 6.7199339],
          [116.8124436, 6.719743],
          [116.812452, 6.7195784],
          [116.8125062, 6.7194555],
          [116.8124239, 6.7193115],
          [116.8120866, 6.7190688],
          [116.811923, 6.7190788],
          [116.811778, 6.7189517],
          [116.8117551, 6.7188451],
          [116.811506, 6.7186109],
          [116.8114141, 6.7184067],
          [116.8113573, 6.718249],
          [116.8113036, 6.7180047],
          [116.8111188, 6.7177172],
          [116.8109382, 6.71756],
          [116.8103693, 6.7172319],
          [116.8102232, 6.7171084],
          [116.8097929, 6.7169903],
          [116.8097749, 6.7168046],
          [116.809897, 6.7166569],
          [116.8101658, 6.7165777],
          [116.810306, 6.7161029],
          [116.8103815, 6.7155522],
          [116.8103815, 6.715395],
          [116.8102965, 6.7152262],
          [116.8100277, 6.7148168],
          [116.8098896, 6.7140128],
          [116.8099151, 6.7126644],
          [116.8099013, 6.7113497],
          [116.8098402, 6.7104909],
          [116.8096628, 6.7103717],
          [116.8095757, 6.7100024],
          [116.8089361, 6.7094189],
          [116.8086859, 6.7094775],
          [116.8083056, 6.7092227],
          [116.8077925, 6.7091805],
          [116.8073327, 6.7090349],
          [116.8070499, 6.7087088],
          [116.8070063, 6.7083121],
          [116.8070403, 6.7078204],
          [116.8071386, 6.7077598],
          [116.8080671, 6.7074992],
          [116.8083784, 6.7074577],
          [116.8086551, 6.7072837],
          [116.8097045, 6.707012],
          [116.8098827, 6.706993],
          [116.8099518, 6.7070056],
          [116.8100659, 6.707056],
          [116.8102986, 6.7069742],
          [116.8103209, 6.7069262],
          [116.8103018, 6.7067975],
          [116.8101833, 6.7065754],
          [116.8100282, 6.7066113],
          [116.8098981, 6.7067506],
          [116.8097, 6.706894],
          [116.8088798, 6.7071193],
          [116.808701, 6.7071893],
          [116.808397, 6.7071831],
          [116.8081685, 6.707266],
          [116.8081218, 6.7073235],
          [116.8080283, 6.7073815],
          [116.8074907, 6.7075487],
          [116.8071503, 6.7076458],
          [116.8069367, 6.7076511],
          [116.8067572, 6.7075419],
          [116.8066127, 6.7074089],
          [116.8064879, 6.7072048],
          [116.8062754, 6.7070797],
          [116.8060369, 6.706912],
          [116.8057984, 6.7068059],
          [116.805672, 6.706788],
          [116.8055817, 6.7067321],
          [116.8052799, 6.7066878],
          [116.8049628, 6.7065564],
          [116.804549, 6.7065427],
          [116.8043153, 6.7064889],
          [116.8037575, 6.7063085],
          [116.8035897, 6.706012],
          [116.8031382, 6.7057788],
          [116.8030468, 6.705209],
          [116.8025868, 6.7046467],
          [116.8019488, 6.7036345],
          [116.8014511, 6.7034227],
          [116.8016519, 6.7026198],
          [116.8012009, 6.7021904],
          [116.8010474, 6.702049],
          [116.8009959, 6.701944],
          [116.8009869, 6.7017852],
          [116.8009061, 6.7014412],
          [116.8003314, 6.7008862],
          [116.7997386, 6.6998459],
          [116.7997293, 6.6993636],
          [116.799456, 6.6990556],
          [116.7991001, 6.6982273],
          [116.7985264, 6.6976976],
          [116.7983617, 6.6974518],
          [116.7985083, 6.6969928],
          [116.7984287, 6.6966974],
          [116.7980781, 6.695925],
          [116.7979283, 6.6958739],
          [116.7977211, 6.6955151],
          [116.7976473, 6.6951885],
          [116.7976818, 6.6948963],
          [116.7976754, 6.6944552],
          [116.7977456, 6.6933252],
          [116.797771, 6.6928029],
          [116.7978295, 6.6926035],
          [116.7977275, 6.6921951],
          [116.7971242, 6.6908799],
          [116.7967908, 6.6906566],
          [116.795655, 6.6904409],
          [116.7954115, 6.6900768],
          [116.7950587, 6.6893946],
          [116.7948401, 6.6893142],
          [116.7943954, 6.6885647],
          [116.7942045, 6.6884692],
          [116.7941107, 6.6882651],
          [116.7939399, 6.688093],
          [116.7939091, 6.6880127],
          [116.793871, 6.6879852],
          [116.7937714, 6.6874091],
          [116.7937355, 6.6871412],
          [116.79373370931205, 6.686974614858627],
          [116.793732, 6.6868156],
          [116.7937514, 6.6865737],
          [116.793824, 6.6861911],
          [116.793806, 6.6860805],
          [116.793714, 6.6859953],
          [116.7937244, 6.6857699],
          [116.7935203, 6.6855652],
          [116.7933035, 6.6856329],
          [116.7932229, 6.6855796],
          [116.7931572, 6.6854845],
          [116.7931032, 6.6853364],
          [116.7931026, 6.6851949],
          [116.7930016, 6.6850637],
          [116.7926163, 6.6847937],
          [116.79235, 6.6842455],
          [116.7923209, 6.6838442],
          [116.7922151, 6.6836691],
          [116.7918792, 6.6832997],
          [116.7916112, 6.6828103],
          [116.7915344, 6.6824764],
          [116.7912875, 6.6824929],
          [116.7911706, 6.6824544],
          [116.7909679, 6.68226],
          [116.7906152, 6.6817516],
          [116.7904159, 6.6812377],
          [116.7902507, 6.6805914],
          [116.7900818, 6.6795944],
          [116.7899345, 6.679448],
          [116.7893438, 6.6791512],
          [116.7888554, 6.6789582],
          [116.7887689, 6.6788153],
          [116.7887697, 6.6786903],
          [116.7894489, 6.6785762],
          [116.7898487, 6.6784367],
          [116.789873, 6.6780891],
          [116.7897685, 6.677984],
          [116.7898667, 6.6769617],
          [116.789994, 6.6766045],
          [116.7901171, 6.6756715],
          [116.7898667, 6.6744376],
          [116.7894517, 6.6740432],
          [116.7889426, 6.6737835],
          [116.7890215, 6.6730883],
          [116.7892317, 6.6728341],
          [116.7891117, 6.6721439],
          [116.7890048, 6.6717858],
          [116.7891175, 6.6709436],
          [116.7892286, 6.670513],
          [116.7893612, 6.6701895],
          [116.7894739, 6.6698618],
          [116.7895775, 6.6692616],
          [116.7895071, 6.6690089],
          [116.7893322, 6.6690369],
          [116.7890329, 6.6686343],
          [116.7889509, 6.6683396],
          [116.7889873, 6.6681971],
          [116.7889302, 6.6677592],
          [116.7885215, 6.6675039],
          [116.7876702, 6.6671005],
          [116.7866689, 6.6666461],
          [116.7864418, 6.6663341],
          [116.7853494, 6.6654457],
          [116.7847046, 6.6651782],
          [116.7838274, 6.6649202],
          [116.7830624, 6.6650514],
          [116.7829514, 6.6652119],
          [116.7825336, 6.6651526],
          [116.7818995, 6.6654095],
          [116.7816127, 6.6655412],
          [116.7811444, 6.6653459],
          [116.7806462, 6.6648332],
          [116.7807556, 6.664438],
          [116.7809131, 6.6641548],
          [116.7810491, 6.663264],
          [116.7808137, 6.6627972],
          [116.779548, 6.6625107],
          [116.7790398, 6.6622892],
          [116.7781811, 6.6617508],
          [116.7778247, 6.6615713],
          [116.7778038, 6.6610427],
          [116.7772329, 6.6603232],
          [116.7776597, 6.659998],
          [116.7779598, 6.6596703],
          [116.7778131, 6.659369],
          [116.7775578, 6.6592957],
          [116.7775163, 6.6591426],
          [116.7778197, 6.6582633],
          [116.7784331, 6.6576903],
          [116.7784348, 6.6567402],
          [116.7781612, 6.6564636],
          [116.7774271, 6.6565755],
          [116.7766883, 6.6561656],
          [116.7761092, 6.654669],
          [116.775891, 6.6546316],
          [116.7753985, 6.6548498],
          [116.7753665, 6.6546882],
          [116.7752974, 6.6544514],
          [116.7751354, 6.6532558],
          [116.7747801, 6.6522552],
          [116.7746049, 6.6515291],
          [116.7740939, 6.6505004],
          [116.7737938, 6.6501003],
          [116.7726409, 6.6488772],
          [116.770577, 6.6475357],
          [116.76813, 6.6459783],
          [116.7669596, 6.6450773],
          [116.7664428, 6.6446075],
          [116.7652349, 6.6440346],
          [116.7643664, 6.6438701],
          [116.7642089, 6.6439532],
          [116.7639598, 6.6439916],
          [116.7637398, 6.6440102],
          [116.7636953, 6.6434144],
          [116.7637578, 6.6422449],
          [116.7636184, 6.6419003],
          [116.7637587, 6.6418303],
          [116.7638436, 6.6416665],
          [116.7634683, 6.6411541],
          [116.7626826, 6.6404649],
          [116.7623864, 6.6398936],
          [116.7620774, 6.6398637],
          [116.7617395, 6.6392674],
          [116.761541, 6.6387558],
          [116.7610561, 6.6378943],
          [116.7604101, 6.6375453],
          [116.7601424, 6.6378102],
          [116.7600935, 6.6374117],
          [116.7602624, 6.6371477],
          [116.7601181, 6.6366101],
          [116.7598022, 6.6360746],
          [116.7598213, 6.6356808],
          [116.7596129, 6.635468],
          [116.7594817, 6.6351649],
          [116.7595919, 6.6348054],
          [116.7589765, 6.6340408],
          [116.7586, 6.6338509],
          [116.758296, 6.6332877],
          [116.7580776, 6.6319381],
          [116.7577684, 6.6319478],
          [116.7577512, 6.6320549],
          [116.7576499, 6.6321772],
          [116.7572681, 6.6319243],
          [116.7571287, 6.6312286],
          [116.7562003, 6.6300783],
          [116.7555141, 6.6292083],
          [116.7552633, 6.6289876],
          [116.7547175, 6.6289231],
          [116.7551391, 6.6284734],
          [116.7551145, 6.6281468],
          [116.7546716, 6.6278589],
          [116.7545461, 6.6276262],
          [116.7543353, 6.6275415],
          [116.7545642, 6.6274739],
          [116.7549267, 6.6276653],
          [116.7552129, 6.6277402],
          [116.7554556, 6.6276914],
          [116.7556426, 6.6274225],
          [116.7562922, 6.6249378],
          [116.7565956, 6.6237875],
          [116.7569598, 6.6230283],
          [116.7573321, 6.6226226],
          [116.75746, 6.622168],
          [116.7574755, 6.621257],
          [116.7572796, 6.6210087],
          [116.7569477, 6.620805],
          [116.7568612, 6.6204501],
          [116.7575265, 6.6193973],
          [116.7581055, 6.618634],
          [116.7581809, 6.6179961],
          [116.7582867, 6.6175407],
          [116.7582613, 6.6172841],
          [116.7581695, 6.6169998],
          [116.7579833, 6.6167798],
          [116.7577682, 6.6161502],
          [116.7574912, 6.6151993],
          [116.7571648, 6.6145052],
          [116.7571468, 6.614036],
          [116.7569729, 6.6131423],
          [116.7570057, 6.6126755],
          [116.7567504, 6.6119563],
          [116.7564541, 6.6117198],
          [116.7561601, 6.6115936],
          [116.7558411, 6.6115463],
          [116.7558165, 6.6113769],
          [116.7557837, 6.6103113],
          [116.7562577, 6.6099023],
          [116.7561003, 6.6096278],
          [116.7566014, 6.6093239],
          [116.7564122, 6.6086904],
          [116.7562236, 6.6079869],
          [116.7562176, 6.6073822],
          [116.7561236, 6.6066641],
          [116.756032, 6.6065021],
          [116.7559569, 6.6061618],
          [116.7561411, 6.6060389],
          [116.7562541, 6.6055272],
          [116.7562206, 6.605191],
          [116.756014, 6.6049126],
          [116.7552225, 6.6047803],
          [116.7555184, 6.6045385],
          [116.7557117, 6.6040804],
          [116.7553449, 6.603157],
          [116.7557444, 6.6011049],
          [116.7560941, 6.6006132],
          [116.7566455, 6.6006319],
          [116.756702, 6.6003474],
          [116.7565877, 6.599849],
          [116.7560499, 6.5988837],
          [116.7553614, 6.5983059],
          [116.7551249, 6.5976144],
          [116.7548244, 6.5972852],
          [116.7547679, 6.5963499],
          [116.7545592, 6.5953214],
          [116.7543227, 6.5949801],
          [116.7535833, 6.594118],
          [116.7541397, 6.5935508],
          [116.7549545, 6.5929728],
          [116.7555521, 6.5931297],
          [116.7560839, 6.5927845],
          [116.7560523, 6.5923556],
          [116.7556969, 6.5919294],
          [116.7553139, 6.5910663],
          [116.7542273, 6.5900211],
          [116.7538817, 6.5898189],
          [116.753496, 6.5898542],
          [116.7534487, 6.5895665],
          [116.7528782, 6.5884662],
          [116.752449, 6.5878399],
          [116.7519862, 6.5873174],
          [116.7516822, 6.5864543],
          [116.7520204, 6.5866217],
          [116.7524272, 6.5865276],
          [116.752855, 6.5870231],
          [116.7532407, 6.5872977],
          [116.7533815, 6.5873069],
          [116.7534236, 6.5871801],
          [116.7529571, 6.5864865],
          [116.7529761, 6.5858057],
          [116.7533276, 6.5862046],
          [116.7537501, 6.5864059],
          [116.754141, 6.5864282],
          [116.7557535, 6.5859313],
          [116.7569106, 6.5847917],
          [116.7567342, 6.5846674],
          [116.7562975, 6.5841593],
          [116.7562542, 6.5838867],
          [116.7558615, 6.583435],
          [116.7560787, 6.5832022],
          [116.7566236, 6.5834611],
          [116.7569303, 6.5838194],
          [116.7578241, 6.5839907],
          [116.7582677, 6.5839947],
          [116.7586699, 6.5836699],
          [116.7585212, 6.5828337],
          [116.7581186, 6.5820161],
          [116.7575611, 6.5813657],
          [116.7567683, 6.5805976],
          [116.7570197, 6.5794486],
          [116.7578596, 6.5784071],
          [116.7610346, 6.5787183],
          [116.7624578, 6.5787936],
          [116.7626116, 6.5784398],
          [116.7620574, 6.5777088],
          [116.7619159, 6.5772917],
          [116.761207, 6.5765215],
          [116.7624707, 6.5764809],
          [116.7628577, 6.5769504],
          [116.763149, 6.5771568],
          [116.7633882, 6.5768536],
          [116.7628656, 6.576443],
          [116.762759, 6.5760585],
          [116.7629301, 6.5758611],
          [116.7631921, 6.5762259],
          [116.7640358, 6.5764116],
          [116.7645057, 6.5755721],
          [116.7645781, 6.5751432],
          [116.7644518, 6.5746005],
          [116.7647843, 6.5743042],
          [116.7650625, 6.574828],
          [116.7660959, 6.5757081],
          [116.7661235, 6.5761906],
          [116.766343, 6.5768362],
          [116.7664292, 6.5773249],
          [116.766688, 6.5775894],
          [116.7670962, 6.5775549],
          [116.7672134, 6.5772995],
          [116.7678505, 6.5771557],
          [116.7701119, 6.5784816],
          [116.7704665, 6.5808271],
          [116.7708859, 6.5809047],
          [116.7705766, 6.5812787],
          [116.771142, 6.583037],
          [116.7719179, 6.5837959],
          [116.7735133, 6.583945],
          [116.7745006, 6.5836102],
          [116.7727893, 6.5814448],
          [116.7734159, 6.5811152],
          [116.7734607, 6.5782358],
          [116.773999, 6.5737623],
          [116.7741182, 6.5712123],
          [116.7744663, 6.5711692],
          [116.7739293, 6.5697883],
          [116.774798, 6.5695267],
          [116.7744808, 6.5680334],
          [116.77496, 6.5670565],
          [116.7750179, 6.5667035],
          [116.7752825, 6.5666682],
          [116.7751377, 6.5671311],
          [116.7753878, 6.5679915],
          [116.7753614, 6.56858],
          [116.7773833, 6.5689278],
          [116.7778308, 6.5691815],
          [116.7780309, 6.5697543],
          [116.7777808, 6.5713732],
          [116.7784995, 6.5720218],
          [116.7789681, 6.5718047],
          [116.7798896, 6.5715379],
          [116.7810585, 6.5730156],
          [116.7816666, 6.5729476],
          [116.7820404, 6.5723749],
          [116.782359, 6.5721682],
          [116.7832317, 6.571993],
          [116.7841018, 6.5721081],
          [116.7849758, 6.5725344],
          [116.7857683, 6.5722179],
          [116.7860184, 6.5716112],
          [116.7865501, 6.5713392],
          [116.7867292, 6.5707272],
          [116.7873031, 6.5712607],
          [116.7888274, 6.5707664],
          [116.788809, 6.5699818],
          [116.7893644, 6.5694247],
          [116.7890906, 6.568512],
          [116.7897146, 6.5678738],
          [116.7901358, 6.5678137],
          [116.7904149, 6.5670578],
          [116.7900911, 6.5659071],
          [116.79012, 6.5656377],
          [116.7903122, 6.5655383],
          [116.7907466, 6.5651486],
          [116.7906544, 6.5648374],
          [116.7914942, 6.5647249],
          [116.7916654, 6.5633571],
          [116.7921234, 6.5630825],
          [116.7924841, 6.5633205],
          [116.7936451, 6.5628235],
          [116.7925157, 6.5612778],
          [116.7952036, 6.5625254],
          [116.7956801, 6.5632655],
          [116.7959618, 6.56467],
          [116.7972281, 6.5661817],
          [116.7980706, 6.5662235],
          [116.7986629, 6.5647145],
          [116.79935, 6.5646255],
          [116.8000714, 6.5648112],
          [116.8007822, 6.5660248],
          [116.8015312, 6.5668839],
          [116.8019972, 6.5670408],
          [116.8022091, 6.5667361],
          [116.8031239, 6.5668408],
          [116.8031503, 6.5665008],
          [116.8022367, 6.566013],
          [116.8018642, 6.5653382],
          [116.8030173, 6.5655736],
          [116.8031832, 6.5651865],
          [116.8026277, 6.5642908],
          [116.8035781, 6.5648871],
          [116.8043205, 6.5649499],
          [116.8046653, 6.5643719],
          [116.8059237, 6.5639822],
          [116.8066688, 6.5647145],
          [116.8079035, 6.5652009],
          [116.8093093, 6.5652271],
          [116.810236, 6.5649132],
          [116.8110469, 6.564296],
          [116.8117366, 6.5652323],
          [116.8114418, 6.5665191],
          [116.8114334, 6.5673379],
          [116.8117524, 6.5678791],
          [116.812253, 6.5677572],
          [116.8125739, 6.5666855],
          [116.8131688, 6.5663778],
          [116.8146694, 6.5685695],
          [116.8149274, 6.5686349],
          [116.8151038, 6.5687971],
          [116.8170098, 6.5697569],
          [116.8178312, 6.5705546],
          [116.8179154, 6.571891],
          [116.8191238, 6.5729895],
          [116.8207797, 6.5726338],
          [116.8207402, 6.5736067],
          [116.8202374, 6.5745325],
          [116.8210404, 6.5753929],
          [116.8220566, 6.57527],
          [116.8216148, 6.5761802],
          [116.8216972, 6.5774473],
          [116.822117, 6.5777428],
          [116.8224174, 6.5778787],
          [116.8260759, 6.578662],
          [116.826776, 6.5792243],
          [116.8278515, 6.5790564],
          [116.8284497, 6.579179],
          [116.8290156, 6.5795547],
          [116.8307161, 6.5796986],
          [116.833629, 6.577447],
          [116.8340421, 6.5765091],
          [116.8342674, 6.5749103],
          [116.8353215, 6.5746146],
          [116.8366626, 6.5746306],
          [116.8381324, 6.5760668],
          [116.8388674, 6.5779639],
          [116.8395513, 6.578694],
          [116.8399134, 6.5783929],
          [116.8401575, 6.5779586],
          [116.8408763, 6.5777454],
          [116.8412665, 6.5775226],
          [116.8421209, 6.5783716],
          [116.8427914, 6.5792296],
          [116.8432769, 6.5792722],
          [116.8442532, 6.5769514],
          [116.8474799, 6.5760881],
          [116.8492073, 6.5763492],
          [116.8523616, 6.5785901],
          [116.8523562, 6.5789445],
          [116.8518171, 6.5792882],
          [116.8517581, 6.580386],
          [116.8519109, 6.5812999],
          [116.8527022, 6.5808816],
          [116.8533406, 6.5806578],
          [116.8541761, 6.5812413],
          [116.8539199, 6.582944],
          [116.8539226, 6.5848731],
          [116.854223, 6.5866889],
          [116.8538985, 6.5873897],
          [116.8543839, 6.5882064],
          [116.8546455, 6.5882557],
          [116.8550035, 6.5895359],
          [116.8550746, 6.5900076],
          [116.8547561, 6.5906802],
          [116.8552111, 6.592364],
          [116.8550486, 6.5942594],
          [116.8548312, 6.5950524],
          [116.8547608, 6.5960437],
          [116.854473, 6.5965937],
          [116.8542812, 6.5978345],
          [116.8539998, 6.5983973],
          [116.8540126, 6.5991136],
          [116.8539358, 6.5998107],
          [116.8553492, 6.6009875],
          [116.8555795, 6.6014032],
          [116.8561359, 6.601384],
          [116.8563022, 6.6018701],
          [116.8566347, 6.6021643],
          [116.8570441, 6.6025864],
          [116.8576708, 6.6024777],
          [116.8580801, 6.6027463],
          [116.8583935, 6.6030596],
          [116.8585918, 6.6035265],
          [116.8590651, 6.6042748],
          [116.8592647, 6.605486],
          [116.8601104, 6.6063324],
          [116.8614723, 6.6074666],
          [116.8610893, 6.6095871],
          [116.8598181, 6.6109371],
          [116.8591541, 6.6114799],
          [116.8583463, 6.6112197],
          [116.8581135, 6.6106721],
          [116.8587434, 6.6094535],
          [116.859469, 6.6085225],
          [116.8598524, 6.6078379],
          [116.8584191, 6.6090778],
          [116.8578543, 6.6102048],
          [116.8575248, 6.6107816],
          [116.8570169, 6.6118834],
          [116.856539, 6.6129859],
          [116.8569908, 6.6141087],
          [116.8578123, 6.6149302],
          [116.8596333, 6.6161076],
          [116.8599932, 6.6156336],
          [116.8612276, 6.6162401],
          [116.8616018, 6.6161655],
          [116.8618472, 6.6162041],
          [116.8621355, 6.6165145],
          [116.8625287, 6.6168073],
          [116.8639648, 6.6170634],
          [116.8651731, 6.6173911],
          [116.8655036, 6.6178575],
          [116.8657981, 6.6181091],
          [116.8667056, 6.6183633],
          [116.8681464, 6.6184435],
          [116.8702914, 6.6189994],
          [116.8711979, 6.61937],
          [116.8727404, 6.6197756],
          [116.8738422, 6.6200561],
          [116.8749089, 6.6199559],
          [116.8764965, 6.6203165],
          [116.8775983, 6.6209475],
          [116.8782744, 6.6215385],
          [116.8784647, 6.6222146],
          [116.8788653, 6.6235217],
          [116.879296, 6.6250141],
          [116.8806305, 6.6267629],
          [116.8813934, 6.6279479],
          [116.8839424, 6.6303255],
          [116.8840404, 6.6305838],
          [116.8840953, 6.6312182],
          [116.8839055, 6.6314679],
          [116.8836608, 6.6326169],
          [116.8838406, 6.6331464],
          [116.8843601, 6.633516],
          [116.8848804, 6.6342064],
          [116.8856235, 6.6335591],
          [116.8872071, 6.6326231],
          [116.8872404, 6.6335058],
          [116.8876011, 6.6335203],
          [116.887636, 6.6339639],
          [116.8876736, 6.6342251],
          [116.8878781, 6.6344233],
          [116.8879523, 6.6346245],
          [116.887926, 6.6347556],
          [116.8883606, 6.6357346],
          [116.8886403, 6.6360044],
          [116.8884255, 6.6361592],
          [116.8882707, 6.6361393],
          [116.8877212, 6.636394],
          [116.8876563, 6.6366987],
          [116.8877512, 6.6369385],
          [116.887906, 6.6369585],
          [116.8880759, 6.6369635],
          [116.8882257, 6.6370834],
          [116.8885354, 6.6371383],
          [116.8881108, 6.6373231],
          [116.8878761, 6.6376428],
          [116.8877412, 6.6376828],
          [116.8872167, 6.6379076],
          [116.8869569, 6.6383322],
          [116.886932, 6.6386219],
          [116.8871418, 6.6389866],
          [116.8876812, 6.6390215],
          [116.8878511, 6.6390765],
          [116.8880609, 6.6393812],
          [116.8882807, 6.6396709],
          [116.8884455, 6.6397009],
          [116.8886004, 6.639616],
          [116.8887902, 6.6395311],
          [116.8891449, 6.6393562],
          [116.8893247, 6.6390415],
          [116.8896744, 6.6386369],
          [116.8899841, 6.6383771],
          [116.8901989, 6.6379725],
          [116.8904936, 6.6377627],
          [116.8904736, 6.637458],
          [116.8906335, 6.6373231],
          [116.8910081, 6.6371233],
          [116.8912407, 6.6369765],
          [116.8916186, 6.6371178],
          [116.8922473, 6.63763],
          [116.8926889, 6.6381492],
          [116.8929891, 6.6386049],
          [116.8929043, 6.6389687],
          [116.8929785, 6.6393643],
          [116.8926429, 6.6394385],
          [116.8924028, 6.6394526],
          [116.8923922, 6.6398058],
          [116.8924487, 6.6409856],
          [116.8925193, 6.6411269],
          [116.8927595, 6.6412752],
          [116.8933564, 6.6419216],
          [116.8936284, 6.6424832],
          [116.8938616, 6.6429672],
          [116.894017, 6.6437266],
          [116.8942678, 6.6450865],
          [116.8947163, 6.6456234],
          [116.8953239, 6.645853],
          [116.8956206, 6.646026],
          [116.895776, 6.646245],
          [116.8961504, 6.6464005],
          [116.8960409, 6.6466159],
          [116.8962564, 6.6470822],
          [116.8963906, 6.6473895],
          [116.896433, 6.6479405],
          [116.8966516, 6.6480666],
          [116.8966542, 6.6480966],
          [116.8966798, 6.6481125],
          [116.896633, 6.6481611],
          [116.8965571, 6.6481479],
          [116.8964405, 6.6481867],
          [116.8962948, 6.6483386],
          [116.8962206, 6.6484852],
          [116.8962763, 6.6485179],
          [116.8961677, 6.6485964],
          [116.8961323, 6.64866],
          [116.8961173, 6.6488163],
          [116.8961014, 6.649038],
          [116.8961297, 6.6491466],
          [116.8962206, 6.6492861],
          [116.8963646, 6.6495148],
          [116.896316, 6.6496367],
          [116.8964246, 6.6497338],
          [116.8964432, 6.6499201],
          [116.8965827, 6.6500473],
          [116.8966701, 6.6501647],
          [116.8968432, 6.6502972],
          [116.8969776, 6.6503666],
          [116.8970688, 6.6502392],
          [116.8971537, 6.6502354],
          [116.8971999, 6.6503416],
          [116.8972861, 6.6503016],
          [116.8974234, 6.6503366],
          [116.8975296, 6.6503366],
          [116.8977831, 6.6503354],
          [116.897873, 6.6503828],
          [116.8978777, 6.6504931],
          [116.8981356, 6.6504154],
          [116.8983546, 6.6502953],
          [116.8985382, 6.6502423],
          [116.8986018, 6.6504154],
          [116.8986583, 6.6505249],
          [116.8985948, 6.6506662],
          [116.8985912, 6.6508816],
          [116.898676, 6.6510441],
          [116.8985524, 6.6514644],
          [116.8985559, 6.6516269],
          [116.8988137, 6.6514609],
          [116.8990151, 6.6512596],
          [116.8991564, 6.6512031],
          [116.8993083, 6.6512454],
          [116.899499, 6.6509805],
          [116.8997533, 6.6507297],
          [116.8996827, 6.6506662],
          [116.899605, 6.6505284],
          [116.8999733, 6.6501737],
          [116.9000444, 6.6500027],
          [116.9003553, 6.6500663],
          [116.9008498, 6.6504548],
          [116.9012595, 6.6503559],
          [116.9013231, 6.6501935],
          [116.9014856, 6.6504054],
          [116.9014502, 6.6509776],
          [116.9019145, 6.6525014],
          [116.9032093, 6.6540577],
          [116.903646, 6.6542643],
          [116.9039581, 6.6544815],
          [116.9048623, 6.6551456],
          [116.9057122, 6.6553958],
          [116.9066052, 6.6569011],
          [116.9069817, 6.6575899],
          [116.906798, 6.6579148],
          [116.907236, 6.6583387],
          [116.9078435, 6.6588191],
          [116.9087054, 6.6601048],
          [116.9094501, 6.6617819],
          [116.9119445, 6.6636486],
          [116.9127867, 6.663978],
          [116.9146576, 6.664374],
          [116.9155589, 6.6646881],
          [116.9162281, 6.6646881],
          [116.916706, 6.6645242],
          [116.9170338, 6.6642784],
          [116.9181126, 6.6640599],
          [116.9205434, 6.663459],
          [116.9217725, 6.6633088],
          [116.922428, 6.6633634],
          [116.9227148, 6.6634864],
          [116.9259103, 6.6635273],
          [116.928027, 6.6636093],
          [116.9295429, 6.6639097],
          [116.9340085, 6.666518],
          [116.9344864, 6.6675422],
          [116.9343362, 6.6685118],
          [116.9343703, 6.6687713],
          [116.9347186, 6.6687986],
          [116.9348483, 6.6690786],
          [116.935258, 6.6699594],
          [116.9352307, 6.6709426],
          [116.9351214, 6.6711816],
          [116.9351283, 6.6715708],
          [116.9349303, 6.6721307],
          [116.9352307, 6.6734895],
          [116.9353877, 6.6738651],
          [116.9363095, 6.6744182],
          [116.9371084, 6.6749507],
          [116.9378732, 6.675695],
          [116.9384194, 6.6760228],
          [116.9386584, 6.6760432],
          [116.9395461, 6.6761183],
          [116.9399148, 6.6761457],
          [116.9408927, 6.6762806],
          [116.942228, 6.6770559],
          [116.9428409, 6.677147],
          [116.9437234, 6.6776851],
          [116.9441217, 6.6781479],
          [116.9435297, 6.6790412],
          [116.9430777, 6.6798053],
          [116.9428517, 6.6804511],
          [116.9427979, 6.6807847],
          [116.9426472, 6.6809892],
          [116.9426687, 6.6811721],
          [116.9429916, 6.681226],
          [116.9433468, 6.681043],
          [116.9438311, 6.6811183],
          [116.9441432, 6.6813443],
          [116.9443577, 6.6815717],
          [116.9439978, 6.6825202],
          [116.9452825, 6.6837061],
          [116.9459794, 6.6842314],
          [116.9465984, 6.6847568],
          [116.9466868, 6.685022],
          [116.9465672, 6.6852353],
          [116.9464735, 6.6854017],
          [116.9466244, 6.6855317],
          [116.946744, 6.6853965],
          [116.9471705, 6.6852145],
          [116.9474202, 6.6850584],
          [116.9478727, 6.6848868],
          [116.9489129, 6.6845175],
          [116.9495162, 6.684169],
          [116.9499167, 6.6842054],
          [116.9506085, 6.6842991],
          [116.9511026, 6.6842574],
          [116.9516539, 6.6843251],
          [116.9519452, 6.6844343],
          [116.9532039, 6.6846839],
          [116.9536824, 6.6848608],
          [116.954317, 6.6851052],
          [116.9552688, 6.6854797],
          [116.9557577, 6.685719],
          [116.9564651, 6.6859374],
          [116.9566211, 6.6860363],
          [116.9572556, 6.6865304],
          [116.957438014885284, 6.686974614858627],
          [116.9574585, 6.6870245],
          [116.9576873, 6.6876122],
          [116.9579266, 6.6877371],
          [116.9580462, 6.687685],
          [116.9581867, 6.6875758],
          [116.9583115, 6.687477],
          [116.9584311, 6.687373],
          [116.9585455, 6.6873262],
          [116.9585872, 6.687451],
          [116.9584311, 6.6876174],
          [116.9580358, 6.6880751],
          [116.9579734, 6.6885953],
          [116.9581711, 6.6888865],
          [116.9593634, 6.689466],
          [116.9608017, 6.6915746],
          [116.9608904, 6.6922107],
          [116.9610701, 6.6924598],
          [116.9621699, 6.6929086],
          [116.9625226, 6.6931897],
          [116.9624595, 6.6936599],
          [116.962658, 6.6938983],
          [116.9636115, 6.6941927],
          [116.9639763, 6.6944724],
          [116.9641346, 6.6947468],
          [116.9643049, 6.6953661],
          [116.9645517, 6.6957883],
          [116.9640461, 6.6964087],
          [116.9638496, 6.6967021],
          [116.9638221, 6.6968226],
          [116.9638784, 6.6968612],
          [116.9639844, 6.6967993],
          [116.9641621, 6.6965266],
          [116.9646603, 6.6958683],
          [116.9648695, 6.6956725],
          [116.965131, 6.6956591],
          [116.9654182, 6.695341],
          [116.9657767, 6.6948033],
          [116.9661106, 6.6942901],
          [116.9662598, 6.6938355],
          [116.9665592, 6.6937882],
          [116.9675958, 6.6944198],
          [116.9683158, 6.6946674],
          [116.9694736, 6.6952953],
          [116.9705866, 6.6959269],
          [116.9711901, 6.6961879],
          [116.9713242, 6.6963304],
          [116.9717118, 6.6964157],
          [116.971925, 6.6963238],
          [116.9729039, 6.6968205],
          [116.9749452, 6.6975479],
          [116.9756587, 6.6979781],
          [116.9778192, 6.6993233],
          [116.9783114, 6.6997949],
          [116.9791, 6.7007951],
          [116.9795667, 6.7011987],
          [116.979965, 6.7014238],
          [116.9801433, 6.701569],
          [116.9802278, 6.7018261],
          [116.9804786, 6.7019406],
          [116.9806181, 6.7019153],
          [116.9808058, 6.7017248],
          [116.9811277, 6.7017595],
          [116.9814657, 6.7015357],
          [116.9820155, 6.7015464],
          [116.9818358, 6.7024055],
          [116.9822824, 6.7027211],
          [116.9821912, 6.7036402],
          [116.9813892, 6.7049428],
          [116.980024, 6.7061335],
          [116.9808984, 6.7069567],
          [116.9821161, 6.7072311],
          [116.982615, 6.7077771],
          [116.9828591, 6.7083659],
          [116.9833177, 6.710156],
          [116.9833124, 6.714514],
          [116.9830817, 6.7159151],
          [116.9832185, 6.7168874],
          [116.9838434, 6.7167702],
          [116.98475, 6.7173163],
          [116.9843316, 6.7186775],
          [116.9867912, 6.7199801],
          [116.9872606, 6.7212747],
          [116.9872445, 6.7217009],
          [116.987046, 6.7221084],
          [116.9842391, 6.7244379],
          [116.9839776, 6.7247003],
          [116.983602, 6.7248947],
          [116.981864, 6.7242288],
          [116.9818237, 6.7252836],
          [116.9820705, 6.7268073],
          [116.9815944, 6.7272548],
          [116.9812444, 6.7273134],
          [116.979847, 6.7276584],
          [116.97761, 6.7297694],
          [116.9755192, 6.7323705],
          [116.9747481, 6.7345507],
          [116.9743913, 6.7365538],
          [116.9746717, 6.7394897],
          [116.9755943, 6.742041],
          [116.9770172, 6.7443877],
          [116.9775423, 6.7451042],
          [116.9781974, 6.7457914],
          [116.980083, 6.7477652],
          [116.9819778, 6.7508865],
          [116.9826914, 6.751823],
          [116.9833114, 6.7527248],
          [116.9843992, 6.7536307],
          [116.9857615, 6.7549228],
          [116.9867384, 6.7559701],
          [116.9873537, 6.7572617],
          [116.9877857, 6.7582386],
          [116.9882601, 6.7588115],
          [116.9889137, 6.7590076],
          [116.9893607, 6.7593132],
          [116.9900119, 6.7591027],
          [116.9931939, 6.7613785],
          [116.9961923, 6.763523],
          [116.9960609, 6.7636888],
          [116.9965491, 6.7649759],
          [116.9966644, 6.7668044],
          [116.9965047, 6.768106],
          [116.9963506, 6.768581],
          [116.9956969, 6.7698156],
          [116.9950122, 6.7717985],
          [116.9947265, 6.7721368],
          [116.9946608, 6.7727188],
          [116.9935477, 6.7752797],
          [116.9926719, 6.7764117],
          [116.9925915, 6.7766421],
          [116.9926773, 6.7769923],
          [116.9932365, 6.7775769],
          [116.9944784, 6.7784918],
          [116.9954708, 6.7793934],
          [116.9972277, 6.7791351],
          [116.9993909, 6.779512],
          [116.999496765133657, 6.779558982223857],
          [117.0017794, 6.780572],
          [117.0045119, 6.7823752],
          [117.009191, 6.7857557],
          [117.0109592, 6.7872679],
          [117.012417, 6.7886901],
          [117.014253, 6.7908115],
          [117.0159079, 6.7931087],
          [117.0171297, 6.7951169],
          [117.0186659, 6.7983102],
          [117.0200425, 6.8022653],
          [117.0205937, 6.8031921],
          [117.0216988, 6.8048567],
          [117.0220032, 6.8055399],
          [117.0241664, 6.8070446],
          [117.0247927, 6.807283],
          [117.024904, 6.8080514],
          [117.0250154, 6.8088197],
          [117.0243891, 6.8094443],
          [117.0252675, 6.8116028],
          [117.0265201, 6.8136616],
          [117.0278893, 6.814105],
          [117.027662, 6.8165332],
          [117.0274347, 6.8189614],
          [117.0265818, 6.8196299],
          [117.0252916, 6.8211959],
          [117.0249698, 6.8222665],
          [117.0248276, 6.8232785],
          [117.024959, 6.8250895],
          [117.0251226, 6.8259124],
          [117.0263135, 6.8284132],
          [117.0275152, 6.8297581],
          [117.0303449, 6.8322641],
          [117.0315465, 6.8338487],
          [117.0327428, 6.8362908],
          [117.0334294, 6.836637],
          [117.0357093, 6.8361683],
          [117.0364402, 6.835858],
          [117.0364429, 6.8355384],
          [117.0361009, 6.8353547],
          [117.0358998, 6.8348514],
          [117.0363021, 6.8337635],
          [117.0368493, 6.8337555],
          [117.0371121, 6.8330604],
          [117.0362028, 6.8321363],
          [117.0356208, 6.8322801],
          [117.0350549, 6.830826],
          [117.0329922, 6.8302135],
          [117.0320079, 6.8286129],
          [117.0294759, 6.8286715],
          [117.0273676, 6.8273825],
          [117.0268393, 6.8265116],
          [117.0269908, 6.8256994],
          [117.0265979, 6.8253332],
          [117.0256537, 6.8253132],
          [117.0250851, 6.8235262],
          [117.0266971, 6.8248365],
          [117.028006, 6.8255848],
          [117.0302819, 6.8278672],
          [117.0310986, 6.8277287],
          [117.0341858, 6.8283998],
          [117.0350119, 6.829545],
          [117.0368949, 6.830288],
          [117.039616, 6.8329658],
          [117.0421573, 6.8343547],
          [117.0461245, 6.8390692],
          [117.0481789, 6.8415105],
          [117.0480797, 6.8422668],
          [117.0488119, 6.8467488],
          [117.04952, 6.8490444],
          [117.0505044, 6.8507061],
          [117.0530391, 6.8520643],
          [117.0539081, 6.8537047],
          [117.055048, 6.8560589],
          [117.0568103, 6.8602638],
          [117.0576578, 6.8634354],
          [117.0576498, 6.8644873],
          [117.0581205, 6.8705375],
          [117.0577732, 6.8713045],
          [117.0579489, 6.8731166],
          [117.0576417, 6.874223],
          [117.0574325, 6.8753441],
          [117.0564911, 6.8768594],
          [117.0537204, 6.88018],
          [117.0506412, 6.8831438],
          [117.0502228, 6.8833901],
          [117.0486215, 6.8840065],
          [117.0473836, 6.8855789],
          [117.046579, 6.886459],
          [117.0462477, 6.8872579],
          [117.0446625, 6.889026],
          [117.0429459, 6.8902163],
          [117.0408216, 6.8900752],
          [117.039223, 6.8891432],
          [117.0377532, 6.8914306],
          [117.0362619, 6.8920057],
          [117.0343347, 6.890645],
          [117.033868, 6.8912175],
          [117.0330647, 6.8913773],
          [117.0325255, 6.8913666],
          [117.0324558, 6.8918193],
          [117.0330486, 6.8931188],
          [117.03318, 6.8942425],
          [117.0331559, 6.8951585],
          [117.0329949, 6.896414],
          [117.0326945, 6.8973473],
          [117.0322466, 6.9006785],
          [117.0342803, 6.9061179],
          [117.0352453, 6.9094283],
          [117.0352077, 6.9101872],
          [117.0348537, 6.9112576],
          [117.0346472, 6.9128099],
          [117.0340624, 6.9141386],
          [117.0339927, 6.9168386],
          [117.0341228, 6.9177439],
          [117.0342126, 6.9186466],
          [117.0357133, 6.9230519],
          [117.0357677, 6.9244265],
          [117.0354947, 6.9258371],
          [117.0352225, 6.9265134],
          [117.034843, 6.9271258],
          [117.0339176, 6.9282641],
          [117.0335636, 6.9288791],
          [117.0333248, 6.9302064],
          [117.0329627, 6.9309786],
          [117.0325738, 6.931358],
          [117.0319945, 6.9310838],
          [117.0312783, 6.9326361],
          [117.0305112, 6.9329529],
          [117.029272, 6.9327479],
          [117.0289689, 6.9335147],
          [117.0279899, 6.9339035],
          [117.0270377, 6.9338875],
          [117.026335, 6.9340472],
          [117.0270002, 6.9340366],
          [117.0285945, 6.9349835],
          [117.0288026, 6.937016],
          [117.0294195, 6.9379825],
          [117.0298916, 6.9421734],
          [117.0306319, 6.9439893],
          [117.0319194, 6.948248],
          [117.0327482, 6.9495033],
          [117.0340088, 6.9505737],
          [117.0352373, 6.9511354],
          [117.0379221, 6.9515614],
          [117.0396012, 6.9506083],
          [117.0400545, 6.9494581],
          [117.0411247, 6.9502888],
          [117.045325, 6.9474931],
          [117.0495361, 6.945411],
          [117.0511508, 6.9439733],
          [117.0512474, 6.9424982],
          [117.0494556, 6.9397611],
          [117.050727, 6.9363956],
          [117.0524704, 6.9350058],
          [117.0567968, 6.9340952],
          [117.0605653, 6.9343242],
          [117.0649052, 6.9341325],
          [117.0674425, 6.9342336],
          [117.0694005, 6.9350111],
          [117.0702213, 6.9360229],
          [117.0718092, 6.9360335],
          [117.0728016, 6.9362891],
          [117.0733407, 6.9370773],
          [117.0739227, 6.9372104],
          [117.0775204, 6.9408778],
          [117.0790195, 6.9416265],
          [117.0803402, 6.9428284],
          [117.082994, 6.9453631],
          [117.0845014, 6.9452087],
          [117.0855153, 6.9460288],
          [117.0869958, 6.9474026],
          [117.0862529, 6.9479165],
          [117.0855045, 6.9477967],
          [117.0862448, 6.9487658],
          [117.0831697, 6.9494138],
          [117.0803188, 6.9497522],
          [117.0786837, 6.9500305],
          [117.0767713, 6.9499213],
          [117.0750493, 6.9501317],
          [117.0730966, 6.9500119],
          [117.0713398, 6.9493542],
          [117.0712191, 6.9485715],
          [117.0667398, 6.9511488],
          [117.0665252, 6.9514762],
          [117.0657581, 6.9517252],
          [117.062636, 6.954192],
          [117.0603213, 6.9550852],
          [117.0600644, 6.9556217],
          [117.0595884, 6.9609446],
          [117.0588045, 6.9620143],
          [117.0588152, 6.9623298],
          [117.0575438, 6.9632257],
          [117.0565367, 6.9634972],
          [117.057055, 6.9644936],
          [117.057281, 6.9655353],
          [117.0569712, 6.9661969],
          [117.0581138, 6.9718066],
          [117.0586248, 6.973428],
          [117.0587482, 6.9737501],
          [117.0587019, 6.9738579],
          [117.0587522, 6.9740343],
          [117.0592564, 6.9743305],
          [117.059412, 6.9745542],
          [117.0623213, 6.9756104],
          [117.0647201, 6.9759253],
          [117.0662731, 6.9752623],
          [117.0681614, 6.975265],
          [117.071085, 6.9766521],
          [117.0717502, 6.9786142],
          [117.0728787, 6.9804519],
          [117.0730001, 6.9807894],
          [117.0730202, 6.9811701],
          [117.0728673, 6.9813964],
          [117.0726621, 6.9816027],
          [117.0728606, 6.9821844],
          [117.0733112, 6.9841812],
          [117.077761, 6.9854032],
          [117.0815204, 6.9863868],
          [117.0875286, 6.9889426],
          [117.0914476, 6.9927257],
          [117.09594, 6.9921799],
          [117.0993732, 6.9952468],
          [117.1034217, 6.9964329],
          [117.1080512, 6.9942392],
          [117.1141827, 6.9911776],
          [117.1152395, 6.9919763],
          [117.1170849, 6.9915823],
          [117.1185118, 6.9885313],
          [117.1195847, 6.9874025],
          [117.121532, 6.9860048],
          [117.1235061, 6.9862524],
          [117.124182, 6.9871257],
          [117.1263251, 6.9871975],
          [117.1272048, 6.9881826],
          [117.1280256, 6.9901793],
          [117.12868, 6.9902246],
          [117.1303377, 6.9890931],
          [117.1315473, 6.9889014],
          [117.132757, 6.9887097],
          [117.1348853, 6.9886597],
          [117.1352504, 6.9925207],
          [117.1327892, 6.9944961],
          [117.1328026, 6.9953521],
          [117.1325465, 6.9956955],
          [117.1325438, 6.9960735],
          [117.1323104, 6.996409],
          [117.1315822, 6.9970745],
          [117.1298736, 7.0003198],
          [117.1307872, 7.0049586],
          [117.1311555, 7.0076663],
          [117.1328389, 7.0099183],
          [117.138512, 7.0121145],
          [117.1472667, 7.0116034],
          [117.1517891, 7.0120067],
          [117.1531649, 7.0125964],
          [117.1556067, 7.0106264],
          [117.1553734, 7.0103456],
          [117.1555772, 7.0096468],
          [117.1566743, 7.0078724],
          [117.1573931, 7.0055963],
          [117.1584579, 7.0057587],
          [117.1588147, 7.0052755],
          [117.1592291, 7.0045127],
          [117.1602376, 7.0035823],
          [117.1608357, 7.0032589],
          [117.1606198, 7.0028635],
          [117.160599, 7.0026139],
          [117.1608833, 7.0019777],
          [117.1609484, 7.0016695],
          [117.1613118, 7.0008802],
          [117.162120378346401, 6.9995],
          [117.1623123, 6.9991724],
          [117.1629533, 6.9976442],
          [117.1643105, 6.9978945],
          [117.1653606, 6.9955757],
          [117.1675265, 6.9930998],
          [117.1685994, 6.992365],
          [117.1703133, 6.9913653],
          [117.1704367, 6.9910219],
          [117.1702778, 6.9906492],
          [117.1703388, 6.9905387],
          [117.1707666, 6.9905227],
          [117.171641, 6.9909753],
          [117.1764492, 6.9918391],
          [117.1786121, 6.9918725],
          [117.1801436, 6.9921361],
          [117.1785477, 6.9898918],
          [117.1799183, 6.9846364],
          [117.1831691, 6.9807947],
          [117.1860042, 6.9769556],
          [117.18793, 6.9747032],
          [117.1877986, 6.9735611],
          [117.1890217, 6.9733002],
          [117.1900839, 6.9700095],
          [117.1903092, 6.9681618],
          [117.189483, 6.9671075],
          [117.1894991, 6.9662449],
          [117.1902046, 6.9658748],
          [117.1904728, 6.9656006],
          [117.191028, 6.9655606],
          [117.1907276, 6.9627864],
          [117.1913552, 6.9613487],
          [117.1925354, 6.9605872],
          [117.1937102, 6.9588912],
          [117.1953383, 6.9580499],
          [117.1968001, 6.9558454],
          [117.1973205, 6.9557522],
          [117.1984819, 6.9560264],
          [117.1985935, 6.9553754],
          [117.203511, 6.9554167],
          [117.2065312, 6.954208],
          [117.2116397, 6.951797],
          [117.215073, 6.9477074],
          [117.2224544, 6.9453218],
          [117.2298359, 6.9439586],
          [117.2361873, 6.9461738],
          [117.2409938, 6.9420841],
          [117.247517, 6.9437882],
          [117.2521518, 6.9436178],
          [117.2547268, 6.9415729],
          [117.2555381, 6.9388835],
          [117.2535251, 6.9349272],
          [117.2512935, 6.9241915],
          [117.2526668, 6.9206129],
          [117.2546786, 6.9129416],
          [117.2551614, 6.9118766],
          [117.2550863, 6.9111523],
          [117.2548931, 6.9107795],
          [117.2545069, 6.9103641],
          [117.2546678, 6.9091712],
          [117.2551077, 6.9086067],
          [117.255612, 6.9082766],
          [117.2559446, 6.9081487],
          [117.2560154, 6.9078311],
          [117.2559406, 6.9071032],
          [117.2558005, 6.906737],
          [117.2558472, 6.9063058],
          [117.255978, 6.9058329],
          [117.256375, 6.9052627],
          [117.2562722, 6.9049845],
          [117.2560294, 6.9046461],
          [117.2560761, 6.9040063],
          [117.256473, 6.9035751],
          [117.2563469, 6.9030559],
          [117.256417, 6.9023975],
          [117.2564403, 6.9018644],
          [117.2566225, 6.9014332],
          [117.2566131, 6.9009371],
          [117.2568046, 6.9005153],
          [117.2568373, 6.8998662],
          [117.2565384, 6.8993098],
          [117.2565477, 6.8991244],
          [117.2567345, 6.8987535],
          [117.2572015, 6.8986005],
          [117.2579067, 6.898582],
          [117.2583223, 6.8987396],
          [117.2586072, 6.8985542],
          [117.2586866, 6.8983502],
          [117.2597049, 6.8970956],
          [117.258675, 6.8885746],
          [117.2555851, 6.8865295],
          [117.2530101, 6.8838027],
          [117.2478603, 6.8819281],
          [117.2447704, 6.87869],
          [117.2440837, 6.8745998],
          [117.2466587, 6.8715321],
          [117.2502791, 6.8713542],
          [117.2519802, 6.8735772],
          [117.2543834, 6.8727251],
          [117.2559284, 6.8691461],
          [117.2541835, 6.8672976],
          [117.2547268, 6.8653966],
          [117.2569584, 6.8645445],
          [117.2523235, 6.863181],
          [117.2471498, 6.8587774],
          [117.2458004, 6.8536368],
          [117.2420179, 6.8544093],
          [117.2401417, 6.8560227],
          [117.2393782, 6.8546256],
          [117.23727, 6.8539885],
          [117.2365083, 6.853931],
          [117.2349065, 6.8520995],
          [117.2310086, 6.8461083],
          [117.2291064, 6.8423016],
          [117.231193, 6.8397565],
          [117.2341238, 6.8382166],
          [117.2356723, 6.8359112],
          [117.2361873, 6.8318206],
          [117.233991, 6.8309623],
          [117.2315525, 6.8279004],
          [117.2281192, 6.8256846],
          [117.224171, 6.824662],
          [117.2210811, 6.8214235],
          [117.2195362, 6.8210826],
          [117.2183345, 6.8186964],
          [117.2204048, 6.8180894],
          [117.2228276, 6.817379],
          [117.224171, 6.8164805],
          [117.2250921, 6.814831],
          [117.2260593, 6.811708],
          [117.2265439, 6.8086329],
          [117.2272609, 6.8055717],
          [117.2291492, 6.8050604],
          [117.2313808, 6.8035263],
          [117.2324299, 6.8038422],
          [117.2343452, 6.804419],
          [117.2365306, 6.8036968],
          [117.23676, 6.8017019],
          [117.2380756, 6.799265],
          [117.2387622, 6.7950036],
          [117.2365306, 6.7944922],
          [117.235329, 6.7929581],
          [117.2351574, 6.791424],
          [117.2370456, 6.7886967],
          [117.2399639, 6.7888672],
          [117.2403072, 6.7900604],
          [117.2410425, 6.790342],
          [117.2416805, 6.7890376],
          [117.2463153, 6.7880149],
          [117.2476397, 6.7894592],
          [117.2483753, 6.7883558],
          [117.2495471, 6.7863939],
          [117.2449421, 6.7851171],
          [117.2430538, 6.7840943],
          [117.2432254, 6.7830716],
          [117.2444271, 6.7830716],
          [117.2444271, 6.7815374],
          [117.2420238, 6.7794919],
          [117.2415088, 6.7757417],
          [117.2430468, 6.7745397],
          [117.2432795, 6.7723693],
          [117.2437404, 6.7680709],
          [117.2463153, 6.764832],
          [117.2473453, 6.7617636],
          [117.2478603, 6.7598885],
          [117.2516368, 6.757161],
          [117.2531818, 6.7544335],
          [117.2578167, 6.7494898],
          [117.2617649, 6.7469327],
          [117.2641681, 6.7440347],
          [117.267258, 6.7426709],
          [117.2685163, 6.7433528],
          [117.269833, 6.7421595],
          [117.2754978, 6.7402843],
          [117.2759565, 6.7368673],
          [117.2725407, 6.7336101],
          [117.2739059, 6.7321084],
          [117.2768711, 6.7300557],
          [117.280476, 6.73159],
          [117.2833942, 6.73159],
          [117.2842525, 6.7293737],
          [117.2839092, 6.7283509],
          [117.2857975, 6.7274985],
          [117.2863124, 6.7239184],
          [117.2863124, 6.7191449],
          [117.2851108, 6.7164172],
          [117.2828792, 6.7136894],
          [117.2757045, 6.7115151],
          [117.2701763, 6.7104503],
          [117.2672581, 6.707211],
          [117.2667662, 6.7047412],
          [117.2639965, 6.7019259],
          [117.2641681, 6.7003914],
          [117.2665925, 6.6938578],
          [117.268159717308194, 6.686974614858627],
          [117.268288, 6.6864112],
          [117.2690655, 6.6843998],
          [117.2694897, 6.6782274],
          [117.2737965, 6.6721608],
          [117.2777209, 6.6681598],
          [117.279446, 6.665099],
          [117.2817884, 6.6640127],
          [117.2835659, 6.6622005],
          [117.2827076, 6.660666],
          [117.282678, 6.6587711],
          [117.2839092, 6.6569149],
          [117.2842525, 6.6557213],
          [117.2823643, 6.6553803],
          [117.2806743, 6.6543675],
          [117.2797893, 6.6517998],
          [117.280476, 6.6478781],
          [117.2821926, 6.6417398],
          [117.2842525, 6.6403757],
          [117.2854849, 6.641922],
          [117.2862623, 6.6399105],
          [117.2845959, 6.637818],
          [117.2863125, 6.6340669],
          [117.2868275, 6.6315091],
          [117.2892307, 6.630145],
          [117.2910728, 6.6274647],
          [117.2914616, 6.6264589],
          [117.2887157, 6.6246885],
          [117.2885441, 6.6214488],
          [117.2897457, 6.618891],
          [117.2912906, 6.6146281],
          [117.2966121, 6.6122408],
          [117.2998737, 6.613605],
          [117.300732, 6.6125819],
          [117.3019337, 6.6130934],
          [117.3036503, 6.609342],
          [117.313779354095459, 6.608503517775274],
          [117.3139499, 6.6084894],
          [117.3149799, 6.6107061],
          [117.3141765, 6.614692],
          [117.3158222, 6.6155282],
          [117.3170399, 6.6153101],
          [117.3166965, 6.6176974],
          [117.3170399, 6.6195731],
          [117.3221897, 6.6219604],
          [117.3261046, 6.6142024],
          [117.3304294, 6.6088304],
          [117.3335193, 6.6071252],
          [117.3376392, 6.6074661],
          [117.3383259, 6.6098535],
          [117.3430159, 6.6104371],
          [117.3453407, 6.6117217],
          [117.3481106, 6.6151395],
          [117.3512005, 6.6151395],
          [117.3501705, 6.6166742],
          [117.3512005, 6.6173564],
          [117.3512005, 6.6183795],
          [117.3525738, 6.6192321],
          [117.3558353, 6.6168447],
          [117.3568653, 6.6175269],
          [117.3575519, 6.6158216],
          [117.35755, 6.6141162],
          [117.3590969, 6.6127524],
          [117.358368, 6.6100418],
          [117.359262, 6.6083086],
          [117.3640751, 6.6071252],
          [117.3687099, 6.6062726],
          [117.3705982, 6.6066136],
          [117.3721144, 6.608269],
          [117.3735164, 6.6101946],
          [117.3766064, 6.6103651],
          [117.378323, 6.6072956],
          [117.378323, 6.605761],
          [117.3858761, 6.6038853],
          [117.388546, 6.6023422],
          [117.3892524, 6.6010482],
          [117.388451, 6.5996221],
          [117.3906826, 6.5984285],
          [117.3934292, 6.5996221],
          [117.3953174, 6.5984285],
          [117.3959549, 6.5967053],
          [117.3934292, 6.5924601],
          [117.3934292, 6.5895612],
          [117.3963921, 6.5870273],
          [117.3943959, 6.5844055],
          [117.3965799, 6.5817761],
          [117.3963474, 6.5793294],
          [117.3956608, 6.5777946],
          [117.397204, 6.5762307],
          [117.400712, 6.5736186],
          [117.4009823, 6.5716555],
          [117.4011539, 6.5685859],
          [117.4059604, 6.5636403],
          [117.4117969, 6.5605707],
          [117.4164318, 6.5615939],
          [117.4207361, 6.5609723],
          [117.4236416, 6.5581832],
          [117.4253582, 6.5540902],
          [117.431538, 6.5557956],
          [117.4336761, 6.5563879],
          [117.4358295, 6.555625],
          [117.4390267, 6.5571936],
          [117.441666, 6.5566483],
          [117.4440693, 6.5578421],
          [117.4483608, 6.5576716],
          [117.4621787, 6.5479301],
          [117.4774975, 6.5458614],
          [117.4739384, 6.562276],
          [117.4785732, 6.5675627],
          [117.4802724, 6.5719097],
          [117.4832081, 6.5762599],
          [117.4840664, 6.5806937],
          [117.4924778, 6.5933128],
          [117.4950177, 6.5971455],
          [117.4984859, 6.5997926],
          [117.4996513, 6.6041378],
          [117.5039791, 6.6090009],
          [117.5038139, 6.6104191],
          [117.510228, 6.6200978],
          [117.5117039, 6.6214488],
          [117.5124006, 6.6233761],
          [117.5149654, 6.6272463],
          [117.518227, 6.6282694],
          [117.5190853, 6.6299746],
          [117.5209736, 6.6309975],
          [117.5214474, 6.6303357],
          [117.5211452, 6.6231539],
          [117.5213169, 6.6171859],
          [117.5233768, 6.6088304],
          [117.5254368, 6.6021801],
          [117.5256084, 6.5994517],
          [117.5266384, 6.5957001],
          [117.5285267, 6.5938243],
          [117.5302433, 6.5910959],
          [117.5326465, 6.5858096],
          [117.5340198, 6.5791589],
          [117.5343632, 6.576942],
          [117.5323032, 6.5757484],
          [117.5339189, 6.5746581],
          [117.5345348, 6.5733608],
          [117.5378972, 6.5719735],
          [117.5414013, 6.5696091],
          [117.5427953, 6.5680931],
          [117.5427746, 6.5655162],
          [117.5427746, 6.5639814],
          [117.5418575, 6.5630867],
          [117.5412296, 6.5614233],
          [117.5429624, 6.5589978],
          [117.5422596, 6.5568188],
          [117.5450062, 6.5561366],
          [117.549126, 6.5554545],
          [117.5541976, 6.5539081],
          [117.5577091, 6.5518733],
          [117.5546192, 6.5474391],
          [117.5529026, 6.5423229],
          [117.5522159, 6.5401058],
          [117.5539326, 6.5389119],
          [117.5551342, 6.5394235],
          [117.5578808, 6.5378887],
          [117.5603729, 6.536982],
          [117.5611423, 6.5349895],
          [117.5635456, 6.5329429],
          [117.5662922, 6.5305551],
          [117.5678371, 6.5300436],
          [117.5690388, 6.5273148],
          [117.5762485, 6.5252682],
          [117.5793384, 6.5221983],
          [117.5846823, 6.5205322],
          [117.5920414, 6.5163996],
          [117.5949596, 6.5152057],
          [117.5966762, 6.5124769],
          [117.5999378, 6.5104302],
          [117.6043885, 6.5098272],
          [117.6076626, 6.5090658],
          [117.6112675, 6.5088952],
          [117.6143574, 6.5078719],
          [117.614754, 6.5099656],
          [117.6174473, 6.5088952],
          [117.6231121, 6.5126474],
          [117.6249778, 6.5129007],
          [117.6260303, 6.5109419],
          [117.6286053, 6.5119652],
          [117.6302096, 6.5115728],
          [117.6319156, 6.5097941],
          [117.6359867, 6.503608],
          [117.6407932, 6.5005379],
          [117.6490401, 6.4979894],
          [117.6553844, 6.4967857],
          [117.6543545, 6.4983208],
          [117.6548694, 6.4991736],
          [117.6565861, 6.4988325],
          [117.6577877, 6.4947389],
          [117.6602132, 6.4899295],
          [117.6632809, 6.4882577],
          [117.6654695, 6.4853098],
          [117.669289, 6.4809235],
          [117.6722072, 6.4793884],
          [117.6735206, 6.4803298],
          [117.6765741, 6.4781269],
          [117.6823353, 6.4715423],
          [117.6850818, 6.4689837],
          [117.6868588, 6.4707077],
          [117.6885151, 6.4723952],
          [117.689545, 6.4723952],
          [117.6907467, 6.4723952],
          [117.6924633, 6.4710306],
          [117.7021114, 6.4603267],
          [117.7079433, 6.4546199],
          [117.711111, 6.4517575],
          [117.7138147, 6.4495747],
          [117.7171943, 6.4473265],
          [117.7181974, 6.4467335],
          [117.7200079, 6.4458913],
          [117.7203244, 6.4458593],
          [117.7209681, 6.4455262],
          [117.7230495, 6.4450837],
          [117.7242297, 6.4450997],
          [117.7252194, 6.4447106],
          [117.7254233, 6.4441136],
          [117.7254501, 6.4437591],
          [117.7252838, 6.443322],
          [117.7245703, 6.4423998],
          [117.7240285, 6.441968],
          [117.723248, 6.4409419],
          [117.7223018, 6.4400422],
          [117.7208447, 6.4365522],
          [117.7229383, 6.4342407],
          [117.726177, 6.4341267],
          [117.7280599, 6.4338336],
          [117.7281913, 6.4336603],
          [117.7284864, 6.4334551],
          [117.7289088, 6.4334498],
          [117.7293178, 6.4335217],
          [117.7305731, 6.4338682],
          [117.7307689, 6.4338149],
          [117.7307689, 6.4336177],
          [117.7308628, 6.4336145],
          [117.7309661, 6.433611],
          [117.7317332, 6.433719],
          [117.7322388, 6.4337989],
          [117.7329342, 6.4336887],
          [117.73384, 6.4334844],
          [117.7344395, 6.4331686],
          [117.7348553, 6.4327648],
          [117.7390395, 6.4288334],
          [117.7398401, 6.4281431],
          [117.7405938, 6.427021],
          [117.741251, 6.4262347],
          [117.7413636, 6.4260028],
          [117.7415943, 6.425611],
          [117.7415796, 6.4244795],
          [117.741318, 6.4230682],
          [117.7409291, 6.4221354],
          [117.7402666, 6.4202643],
          [117.7401513, 6.4195473],
          [117.7398737, 6.4187237],
          [117.7396001, 6.4184331],
          [117.7389, 6.418068],
          [117.7388135, 6.4179529],
          [117.7387378, 6.4178521],
          [117.7387243, 6.4175869],
          [117.738672, 6.417355],
          [117.738664, 6.416554],
          [117.7387351, 6.4164234],
          [117.7387136, 6.4155358],
          [117.7384025, 6.4135181],
          [117.7380592, 6.4118336],
          [117.737866, 6.4107487],
          [117.7373135, 6.408715],
          [117.7368146, 6.4065347],
          [117.7364123, 6.404333],
          [117.7361682, 6.4029176],
          [117.7359858, 6.4025764],
          [117.7354172, 6.4002095],
          [117.7347547, 6.3971495],
          [117.733997, 6.3943027],
          [117.7336657, 6.3932285],
          [117.7329415, 6.3905856],
          [117.7327006, 6.389628],
          [117.7314557, 6.386441],
          [117.7301078, 6.3840924],
          [117.7294007, 6.3835023],
          [117.7292388, 6.3836269],
          [117.7270609, 6.3835187],
          [117.7224766, 6.3832911],
          [117.7227224, 6.3795562],
          [117.7217109, 6.3753875],
          [117.7207738, 6.3728715],
          [117.720631, 6.3723748],
          [117.7205328, 6.3714671],
          [117.7204227, 6.3696163],
          [117.7203959, 6.3685194],
          [117.7205149, 6.3658998],
          [117.7207024, 6.3626003],
          [117.7208273, 6.3598772],
          [117.7209166, 6.3590346],
          [117.7211962, 6.3578135],
          [117.7213628, 6.3557926],
          [117.7213926, 6.3552412],
          [117.721104, 6.3541295],
          [117.7207351, 6.3537185],
          [117.7205417, 6.3536387],
          [117.7203617, 6.3537022],
          [117.7201728, 6.3538382],
          [117.7202546, 6.3538855],
          [117.7203484, 6.3538338],
          [117.7203818, 6.3538974],
          [117.7203067, 6.3540201],
          [117.7198233, 6.3540201],
          [117.7186422, 6.3537362],
          [117.7182786, 6.3536185],
          [117.7179208, 6.3535026],
          [117.7182659, 6.3527812],
          [117.7187181, 6.3484881],
          [117.7189557, 6.347852],
          [117.7177827, 6.3459399],
          [117.7164975, 6.3454596],
          [117.7151467, 6.3449548],
          [117.7152419, 6.3448011],
          [117.7153475, 6.3445291],
          [117.7153237, 6.3442201],
          [117.7157848, 6.3439185],
          [117.7156003, 6.3431246],
          [117.7170823, 6.342975],
          [117.7186169, 6.3428201],
          [117.7191286, 6.3427462],
          [117.7201996, 6.3425126],
          [117.7212245, 6.3421193],
          [117.7215473, 6.3419064],
          [117.722166, 6.3413151],
          [117.7227045, 6.3404399],
          [117.7234839, 6.3387457],
          [117.7238618, 6.3376694],
          [117.72426397608362, 6.336486518383026],
          [117.7247394, 6.3350882],
          [117.725263, 6.3335181],
          [117.725852, 6.3320131],
          [117.7264946, 6.3302449],
          [117.7267802, 6.3298428],
          [117.72775, 6.3274773],
          [117.7279255, 6.3264602],
          [117.7281308, 6.3258747],
          [117.728113, 6.3254223],
          [117.7284759, 6.3249404],
          [117.7289519, 6.3231722],
          [117.7288686, 6.3226311],
          [117.7291006, 6.3224123],
          [117.730005, 6.3193371],
          [117.7301895, 6.318116],
          [117.7302157, 6.3176105],
          [117.7302311, 6.3173146],
          [117.7307607, 6.315579],
          [117.7307071, 6.3118592],
          [117.7308737, 6.3102122],
          [117.7308916, 6.3092452],
          [117.7311355, 6.307891],
          [117.7313854, 6.3053746],
          [117.7311712, 6.3023319],
          [117.7309778, 6.3013916],
          [117.7298424, 6.3007763],
          [117.7288745, 6.300129],
          [117.7283093, 6.298683],
          [117.7268575, 6.2968822],
          [117.7262536, 6.2968704],
          [117.7257271, 6.2962937],
          [117.7252124, 6.2953298],
          [117.7249595, 6.2930055],
          [117.7251618, 6.2920031],
          [117.7257509, 6.2903708],
          [117.7272205, 6.2874286],
          [117.7282141, 6.2858554],
          [117.730191, 6.2836568],
          [117.730478, 6.2832532],
          [117.7305137, 6.2830639],
          [117.7301121, 6.2819743],
          [117.7299336, 6.2811167],
          [117.7299054, 6.2804499],
          [117.729532, 6.2799708],
          [117.729306, 6.28004],
          [117.728818, 6.2797505],
          [117.7284313, 6.2793587],
          [117.7281516, 6.2790009],
          [117.727933, 6.2785337],
          [117.7264619, 6.2769664],
          [117.7249387, 6.2758575],
          [117.7236386, 6.2752971],
          [117.721576, 6.2743252],
          [117.7203216, 6.2741719],
          [117.7191108, 6.2737816],
          [117.7190096, 6.2724923],
          [117.7185753, 6.2715785],
          [117.7174805, 6.2702715],
          [117.7173258, 6.2697214],
          [117.7154471, 6.266621],
          [117.7160516, 6.2644617],
          [117.7165821, 6.2625666],
          [117.7169242, 6.2623403],
          [117.7179119, 6.2604507],
          [117.7182094, 6.2596079],
          [117.7181856, 6.2582949],
          [117.7184622, 6.2568488],
          [117.719316, 6.2555358],
          [117.7199291, 6.2543637],
          [117.7207797, 6.2532705],
          [117.7206191, 6.2570706],
          [117.7211813, 6.2573574],
          [117.7225915, 6.2543026],
          [117.7228027, 6.2523952],
          [117.7224308, 6.2507155],
          [117.7214164, 6.2491363],
          [117.7211595, 6.2487745],
          [117.7210165, 6.248573],
          [117.7210815, 6.247815],
          [117.714706, 6.2388404],
          [117.7116561, 6.2367569],
          [117.7115439, 6.2353387],
          [117.7090812, 6.2316375],
          [117.7065063, 6.2297605],
          [117.7023864, 6.2295898],
          [117.6982233, 6.2306103],
          [117.6998115, 6.2330027],
          [117.6953483, 6.2350504],
          [117.692587, 6.2348221],
          [117.6869369, 6.2362449],
          [117.6852298, 6.2356152],
          [117.6824498, 6.2361133],
          [117.6816154, 6.2372688],
          [117.681272, 6.2388047],
          [117.681787, 6.2398286],
          [117.6805653, 6.2404905],
          [117.6782632, 6.241122],
          [117.676404, 6.2419824],
          [117.6751584, 6.241756],
          [117.6739559, 6.241425],
          [117.6722496, 6.2425454],
          [117.6707301, 6.243034],
          [117.670121, 6.2440537],
          [117.6685823, 6.2445317],
          [117.6654037, 6.2452212],
          [117.6621507, 6.2433623],
          [117.6612736, 6.2417912],
          [117.6599272, 6.2406759],
          [117.6588373, 6.2393375],
          [117.6576513, 6.2386365],
          [117.6562408, 6.2375212],
          [117.6547983, 6.2366289],
          [117.6538687, 6.2374256],
          [117.6528429, 6.2386684],
          [117.6524903, 6.2398474],
          [117.6520415, 6.2413132],
          [117.651909, 6.2421008],
          [117.6464638, 6.2416],
          [117.6452777, 6.2411858],
          [117.6439313, 6.2401979],
          [117.6429055, 6.2388914],
          [117.643162, 6.2382541],
          [117.6422644, 6.2369157],
          [117.6428735, 6.2360872],
          [117.643194, 6.2350038],
          [117.6429055, 6.2343664],
          [117.6402128, 6.2320402],
          [117.6391871, 6.2324226],
          [117.6382574, 6.2327731],
          [117.6365264, 6.2321358],
          [117.6350843, 6.2322421],
          [117.6329756, 6.2323889],
          [117.6308965, 6.2331588],
          [117.6300191, 6.2310842],
          [117.6305961, 6.2299689],
          [117.6309487, 6.2291722],
          [117.6313975, 6.2281843],
          [117.631109, 6.2274195],
          [117.6312808, 6.2264113],
          [117.632263, 6.2240735],
          [117.6327353, 6.218846],
          [117.6303321, 6.2167982],
          [117.6282721, 6.2164569],
          [117.6263839, 6.2167982],
          [117.6253539, 6.2150916],
          [117.6242291, 6.2139902],
          [117.6205474, 6.2128732],
          [117.6181441, 6.2132146],
          [117.6164825, 6.2125229],
          [117.613927, 6.2140672],
          [117.6115549, 6.2182737],
          [117.6084775, 6.2217791],
          [117.6066167, 6.2222747],
          [117.6054001, 6.220122],
          [117.605881, 6.2186242],
          [117.6059451, 6.2180187],
          [117.60649, 6.2161067],
          [117.6069202, 6.2141951],
          [117.6073235, 6.2119321],
          [117.6073446, 6.2087903],
          [117.6071866, 6.2063182],
          [117.6064069, 6.2048054],
          [117.6052866, 6.2040702],
          [117.604653, 6.2013488],
          [117.6031495, 6.2007522],
          [117.600938, 6.1986722],
          [117.5983702, 6.1949624],
          [117.5937525, 6.1947952],
          [117.5924672, 6.1925111],
          [117.5918273, 6.1895835],
          [117.5902954, 6.1851561],
          [117.5867425, 6.1820859],
          [117.5841088, 6.17855],
          [117.5827646, 6.1773275],
          [117.580782, 6.1792186],
          [117.580172, 6.1815062],
          [117.5791654, 6.1839768],
          [117.5780979, 6.1860204],
          [117.5751392, 6.1872405],
          [117.5726076, 6.1876675],
          [117.5701564, 6.1873446],
          [117.5686313, 6.1851485],
          [117.5682043, 6.1829524],
          [117.5682653, 6.1804513],
          [117.5690583, 6.1786822],
          [117.5686115, 6.1779226],
          [117.5676355, 6.1773125],
          [117.5660951, 6.1771143],
          [117.5647988, 6.1774041],
          [117.5625265, 6.1776328],
          [117.5568994, 6.1796701],
          [117.5579974, 6.1804631],
          [117.5593395, 6.1820187],
          [117.5616576, 6.1835438],
          [117.5633047, 6.1838488],
          [117.5647993, 6.1839708],
          [117.5644943, 6.1851604],
          [117.5629692, 6.1848554],
          [117.5606511, 6.1843978],
          [117.559126, 6.1832388],
          [117.5566859, 6.1811037],
          [117.5550083, 6.1800056],
          [117.5539407, 6.1801581],
          [117.5526597, 6.1808902],
          [117.5525376, 6.1815917],
          [117.5524766, 6.1832693],
          [117.5523851, 6.1846114],
          [117.5516836, 6.1844588],
          [117.5515616, 6.1819577],
          [117.5518056, 6.1805546],
          [117.5526597, 6.1797311],
          [117.5560148, 6.177962],
          [117.5588515, 6.1766504],
          [117.5621452, 6.176184],
          [117.5641278, 6.1757875],
          [117.5667204, 6.1755587],
          [117.5680472, 6.1758332],
          [117.5689318, 6.176306],
          [117.5698926, 6.1767788],
          [117.5712738, 6.1788737],
          [117.5715594, 6.1822813],
          [117.5723525, 6.1836844],
          [117.5738277, 6.1836413],
          [117.5768473, 6.1779985],
          [117.5777929, 6.1761684],
          [117.5779454, 6.1750703],
          [117.5787384, 6.1752534],
          [117.5802025, 6.1756499],
          [117.5823681, 6.1747043],
          [117.5845337, 6.1742163],
          [117.5805369, 6.1664816],
          [117.58012, 6.1646471],
          [117.5814125, 6.1640634],
          [117.5815376, 6.1628126],
          [117.5799115, 6.1612699],
          [117.5783272, 6.1610198],
          [117.5754921, 6.1609781],
          [117.5714478, 6.1597273],
          [117.5699052, 6.159769],
          [117.5690296, 6.1591853],
          [117.5682375, 6.157726],
          [117.5625255, 6.1555997],
          [117.5598155, 6.1537652],
          [117.555938, 6.1514721],
          [117.5525192, 6.1494291],
          [117.5506847, 6.149846],
          [117.5498925, 6.1515138],
          [117.5478912, 6.1535984],
          [117.5463903, 6.1547658],
          [117.5440972, 6.1552244],
          [117.5437219, 6.153932],
          [117.5455981, 6.1533483],
          [117.5466404, 6.1530564],
          [117.5470574, 6.1521808],
          [117.5477245, 6.1509301],
          [117.5477662, 6.1490122],
          [117.5490586, 6.1480532],
          [117.5530612, 6.1483451],
          [117.5549374, 6.1487203],
          [117.5582311, 6.149304],
          [117.5639014, 6.1517639],
          [117.5681541, 6.1515555],
          [117.5723651, 6.1527229],
          [117.5754504, 6.1538903],
          [117.5781187, 6.1552244],
          [117.5808705, 6.1581013],
          [117.5829968, 6.1607696],
          [117.5844978, 6.1622289],
          [117.5852899, 6.1655226],
          [117.5861238, 6.1686913],
          [117.5876247, 6.1706509],
          [117.5891674, 6.1719017],
          [117.5906348, 6.1719076],
          [117.5920663, 6.1701115],
          [117.5940681, 6.1688356],
          [117.5947437, 6.1679738],
          [117.5992179, 6.166105],
          [117.6052261, 6.1650809],
          [117.6069427, 6.1637156],
          [117.608316, 6.1618382],
          [117.608316, 6.1556941],
          [117.6096892, 6.1502326],
          [117.6131225, 6.1454537],
          [117.6112014, 6.1449667],
          [117.6084407, 6.1452255],
          [117.6049467, 6.1447942],
          [117.602488, 6.1439315],
          [117.6003312, 6.1422492],
          [117.5993829, 6.1411069],
          [117.5990474, 6.1399478],
          [117.5988643, 6.139597],
          [117.597263, 6.1401156],
          [117.5956617, 6.1406798],
          [117.5937248, 6.1407103],
          [117.5932673, 6.1405578],
          [117.5936486, 6.139536],
          [117.5950516, 6.1394445],
          [117.5962717, 6.1387735],
          [117.596897, 6.1383465],
          [117.5985136, 6.1382855],
          [117.5995659, 6.1384075],
          [117.5999861, 6.1388846],
          [117.601539, 6.14061],
          [117.6025311, 6.1419904],
          [117.6038252, 6.1428099],
          [117.6059389, 6.1434138],
          [117.6094329, 6.1429825],
          [117.6107192, 6.1425522],
          [117.6126075, 6.1377732],
          [117.6160407, 6.1348718],
          [117.6158691, 6.1317996],
          [117.6230432, 6.124426],
          [117.6253104, 6.1230949],
          [117.6290409, 6.1191262],
          [117.6342368, 6.1167795],
          [117.6356101, 6.1147314],
          [117.6346691, 6.1130145],
          [117.6379623, 6.1077419],
          [117.6411033, 6.102442],
          [117.6515746, 6.0797398],
          [117.6517463, 6.0775208],
          [117.6507163, 6.0756431],
          [117.6537678, 6.074676],
          [117.6558662, 6.0737655],
          [117.6580978, 6.066084],
          [117.6586127, 6.0621579],
          [117.6586127, 6.0584024],
          [117.6568961, 6.0548176],
          [117.6551795, 6.0520864],
          [117.6517463, 6.0519157],
          [117.6479697, 6.0519157],
          [117.6411033, 6.0503794],
          [117.63973, 6.0481602],
          [117.6369834, 6.0464531],
          [117.6345801, 6.0454288],
          [117.6330352, 6.0428682],
          [117.6320052, 6.0404784],
          [117.6276666, 6.0358481],
          [117.6249671, 6.0329671],
          [117.6249671, 6.0292115],
          [117.6244521, 6.0268215],
          [117.6256538, 6.0247729],
          [117.6277137, 6.0228951],
          [117.6329959, 6.0207306],
          [117.6373222, 6.0189578],
          [117.6393867, 6.0223829],
          [117.6426482, 6.0242608],
          [117.6464248, 6.0227244],
          [117.6500297, 6.0201638],
          [117.6543212, 6.0165786],
          [117.6553989, 6.0115502],
          [117.6567245, 6.0073599],
          [117.6563811, 6.005482],
          [117.6580978, 6.0034334],
          [117.6606727, 6.0034334],
          [117.6663375, 6.0022383],
          [117.672152, 6.003049],
          [117.6760018, 6.0010954],
          [117.676614168819384, 6.0005],
          [117.6790404, 5.998141],
          [117.6798988, 5.9948973],
          [117.6795554, 5.9899464],
          [117.6797271, 5.983971],
          [117.6804137, 5.9773126],
          [117.6816154, 5.9716786],
          [117.6826453, 5.9703127],
          [117.6829887, 5.9691175],
          [117.6819587, 5.967581],
          [117.6771522, 5.9655323],
          [117.6735473, 5.9645079],
          [117.6699424, 5.9624591],
          [117.6642776, 5.9588736],
          [117.6591277, 5.9546054],
          [117.6558662, 5.9498247],
          [117.6523998, 5.9445702],
          [117.6503814, 5.9421435],
          [117.6435675, 5.937326],
          [117.6430033, 5.9372605],
          [117.642763, 5.9368161],
          [117.6419133, 5.9363667],
          [117.6397707, 5.9361947],
          [117.6392748, 5.9352014],
          [117.6386102, 5.9338701],
          [117.635949, 5.9293996],
          [117.63476, 5.9276999],
          [117.633756, 5.9266305],
          [117.6303384, 5.9235711],
          [117.627846, 5.9218923],
          [117.624977, 5.9212233],
          [117.6232378, 5.9211953],
          [117.6211036, 5.9211012],
          [117.6171306, 5.9209932],
          [117.6099714, 5.9208409],
          [117.6071418, 5.9206206],
          [117.6058918, 5.9130051],
          [117.6050716, 5.9045123],
          [117.6070774, 5.9036337],
          [117.6088008, 5.9028386],
          [117.6120664, 5.9010781],
          [117.6165895, 5.8986507],
          [117.617946, 5.897573],
          [117.6193077, 5.8963787],
          [117.6221434, 5.8958911],
          [117.6270776, 5.8962645],
          [117.6282366, 5.8963887],
          [117.6310099, 5.8966868],
          [117.631207, 5.8966028],
          [117.6316458, 5.8967755],
          [117.6347179, 5.8970704],
          [117.6373589, 5.8976869],
          [117.6385447, 5.8978745],
          [117.6407588, 5.8985845],
          [117.6414851, 5.8981057],
          [117.6420434, 5.8978968],
          [117.642383, 5.8992983],
          [117.6441402, 5.899939],
          [117.645228, 5.9003407],
          [117.6468988, 5.9007696],
          [117.6485427, 5.9010377],
          [117.6502674, 5.9011985],
          [117.6513184, 5.9016006],
          [117.6520999, 5.9018418],
          [117.6537977, 5.9028336],
          [117.6553876, 5.9028336],
          [117.6571123, 5.9031553],
          [117.6586215, 5.903343],
          [117.6594569, 5.9036914],
          [117.6605618, 5.9041739],
          [117.6621248, 5.9050585],
          [117.6642538, 5.9057823],
          [117.6669217, 5.9065328],
          [117.6680535, 5.9067205],
          [117.6695357, 5.9076051],
          [117.6710987, 5.9075783],
          [117.6725001, 5.9078463],
          [117.6737128, 5.9086505],
          [117.675168, 5.9087309],
          [117.6767041, 5.9088381],
          [117.6776473, 5.9091598],
          [117.6789947, 5.9094547],
          [117.6802883, 5.9094547],
          [117.6808542, 5.9093742],
          [117.6817974, 5.9087577],
          [117.6827945, 5.9086237],
          [117.6838185, 5.9082484],
          [117.6852199, 5.9080608],
          [117.6867021, 5.9079535],
          [117.6882112, 5.9074978],
          [117.6906635, 5.9073102],
          [117.6917145, 5.9068009],
          [117.6928733, 5.9065865],
          [117.693035, 5.9055142],
          [117.693035, 5.9049781],
          [117.6942477, 5.9049781],
          [117.6954065, 5.9050049],
          [117.6962419, 5.9048977],
          [117.6979666, 5.9041739],
          [117.6984248, 5.9035842],
          [117.6999339, 5.9024316],
          [117.7011196, 5.9016542],
          [117.7028174, 5.9008768],
          [117.7043804, 5.9001799],
          [117.7059435, 5.8995901],
          [117.7067789, 5.8990272],
          [117.7084227, 5.8990272],
          [117.7101475, 5.899188],
          [117.7115488, 5.8998582],
          [117.7123303, 5.8976869],
          [117.7122225, 5.8970436],
          [117.7133005, 5.8970704],
          [117.7151599, 5.8981694],
          [117.7162109, 5.8983839],
          [117.7169385, 5.8985983],
          [117.7172889, 5.8985983],
          [117.7179087, 5.8976333],
          [117.7179087, 5.8967755],
          [117.7183938, 5.8958373],
          [117.7191483, 5.8959177],
          [117.7197951, 5.8966415],
          [117.720388, 5.8977137],
          [117.7213312, 5.8981426],
          [117.723002, 5.8980622],
          [117.7234871, 5.8982766],
          [117.7244842, 5.8983034],
          [117.7254543, 5.8984107],
          [117.7265053, 5.8979282],
          [117.7280145, 5.8974188],
          [117.7293889, 5.8973116],
          [117.7304399, 5.8974456],
          [117.7311136, 5.8977673],
          [117.7317603, 5.8980086],
          [117.7333503, 5.8988664],
          [117.7337276, 5.8988664],
          [117.7346978, 5.8984911],
          [117.7356679, 5.8983839],
          [117.7360182, 5.8977941],
          [117.7365572, 5.8974993],
          [117.7372848, 5.8971776],
          [117.7390096, 5.8981694],
          [117.7396833, 5.89892],
          [117.7408151, 5.8992953],
          [117.7418392, 5.8995097],
          [117.7427015, 5.8994829],
          [117.74351, 5.8995901],
          [117.7445071, 5.8998582],
          [117.746124, 5.899885],
          [117.7505975, 5.899885],
          [117.7517294, 5.899885],
          [117.7524031, 5.9006088],
          [117.7532385, 5.9010108],
          [117.7538314, 5.9017078],
          [117.7543703, 5.9030749],
          [117.7548285, 5.9041203],
          [117.7559603, 5.9046028],
          [117.7573616, 5.9045224],
          [117.759814, 5.904308],
          [117.7615656, 5.9044152],
          [117.7640988, 5.9046564],
          [117.7653654, 5.9046832],
          [117.7698389, 5.9128589],
          [117.768761, 5.9148693],
          [117.7681142, 5.9157539],
          [117.7674135, 5.9170674],
          [117.7654193, 5.9181664],
          [117.7643953, 5.9189437],
          [117.7638563, 5.9196943],
          [117.7621316, 5.9203108],
          [117.7613501, 5.9210345],
          [117.760865, 5.9222139],
          [117.7601913, 5.9231521],
          [117.7596523, 5.9238222],
          [117.7581701, 5.9262347],
          [117.7578198, 5.9271728],
          [117.7574155, 5.9283254],
          [117.7574155, 5.9304966],
          [117.7575503, 5.9311935],
          [117.7571191, 5.9323193],
          [117.7569574, 5.9336059],
          [117.7565801, 5.9346781],
          [117.7567418, 5.9362595],
          [117.7570113, 5.9373049],
          [117.757712, 5.9384307],
          [117.7581701, 5.9392616],
          [117.758763, 5.9394492],
          [117.7601643, 5.9396637],
          [117.7621046, 5.9398245],
          [117.7658235, 5.93961],
          [117.7670362, 5.9400657],
          [117.7683298, 5.9402265],
          [117.7703779, 5.9402265],
          [117.7735039, 5.9395028],
          [117.7754712, 5.9391812],
          [117.7772498, 5.9392616],
          [117.7799986, 5.939342],
          [117.7822084, 5.9392884],
          [117.7848224, 5.939342],
          [117.7868166, 5.9394224],
          [117.7884874, 5.9394224],
          [117.7907781, 5.9391008],
          [117.7928531, 5.9391812],
          [117.794497, 5.9391008],
          [117.7973536, 5.9384843],
          [117.7990244, 5.9383234],
          [117.801342, 5.9381358],
          [117.8031206, 5.9378946],
          [117.805034, 5.9377338],
          [117.8064353, 5.9378678],
          [117.807621, 5.9380018],
          [117.8098308, 5.9375193],
          [117.8115825, 5.9374657],
          [117.812903, 5.9374389],
          [117.814493, 5.9372513],
          [117.8152745, 5.9372513],
          [117.8169144, 5.9371898],
          [117.8189461, 5.9371898],
          [117.8202464, 5.9369742],
          [117.8213841, 5.9368664],
          [117.8232804, 5.9366509],
          [117.8245807, 5.9358964],
          [117.8253121, 5.9356539],
          [117.8264499, 5.9358695],
          [117.8273438, 5.9358426],
          [117.8289963, 5.9358156],
          [117.8310551, 5.9356001],
          [117.8326534, 5.935627],
          [117.8341433, 5.9356539],
          [117.8355791, 5.9355462],
          [117.8373128, 5.9357887],
          [117.8384235, 5.9362198],
          [117.8398321, 5.9362198],
          [117.841593, 5.9364353],
          [117.8431371, 5.9364623],
          [117.8447895, 5.9364892],
          [117.8457919, 5.9365431],
          [117.847038, 5.9365701],
          [117.8479861, 5.936597],
          [117.8489884, 5.9374323],
          [117.8509118, 5.9377287],
          [117.8525643, 5.938025],
          [117.8539187, 5.9382406],
          [117.8553274, 5.9388603],
          [117.8565464, 5.9392645],
          [117.857928, 5.9397225],
          [117.8595534, 5.9400189],
          [117.8605828, 5.9402345],
          [117.8615309, 5.9405847],
          [117.8631021, 5.9412314],
          [117.8640502, 5.9416356],
          [117.8653505, 5.9420936],
          [117.8671114, 5.942363],
          [117.8686013, 5.9426594],
          [117.8698474, 5.9430905],
          [117.8709039, 5.9427403],
          [117.8716624, 5.9426594],
          [117.8725022, 5.9427942],
          [117.8733149, 5.9429828],
          [117.8740734, 5.9429558],
          [117.875753, 5.9431714],
          [117.8774054, 5.9434139],
          [117.8786515, 5.9436564],
          [117.8803582, 5.9440066],
          [117.8819023, 5.9445994],
          [117.8833922, 5.9452191],
          [117.885153, 5.9459735],
          [117.8865617, 5.9463777],
          [117.8876182, 5.9468627],
          [117.8887018, 5.9473477],
          [117.8898395, 5.948129],
          [117.8913566, 5.9484524],
          [117.8924672, 5.9493415],
          [117.8940655, 5.9504462],
          [117.8953116, 5.9509851],
          [117.8960702, 5.9521167],
          [117.8968828, 5.9528172],
          [117.8974517, 5.9536525],
          [117.897831, 5.9541375],
          [117.8988062, 5.9550535],
          [117.8993751, 5.9559696],
          [117.8995105, 5.9569126],
          [117.9003232, 5.9577209],
          [117.9003503, 5.958637],
          [117.9004587, 5.959122],
          [117.9008108, 5.9596878],
          [117.9014068, 5.9606308],
          [117.9022915, 5.9612528],
          [117.9028155, 5.9616816],
          [117.9033573, 5.9623552],
          [117.903549, 5.9632568],
          [117.9039023, 5.9637985],
          [117.9040639, 5.9640464],
          [117.9046723, 5.9651199],
          [117.9033037, 5.9713539],
          [117.9034122, 5.9714631],
          [117.903059, 5.97231],
          [117.9023009, 5.9730189],
          [117.9021625, 5.9729547],
          [117.901912, 5.9730476],
          [117.9016506, 5.9733478],
          [117.9015237, 5.973504],
          [117.9014475, 5.9738044],
          [117.9012849, 5.9743461],
          [117.9007715, 5.975544],
          [117.900226, 5.9760431],
          [117.9000296, 5.9758437],
          [117.8996874, 5.9764292],
          [117.8993714, 5.9764516],
          [117.899182, 5.9766755],
          [117.8990015, 5.976702],
          [117.8986802, 5.976892],
          [117.8982531, 5.9773929],
          [117.8982432, 5.9776673],
          [117.8982575, 5.9780841],
          [117.8983607, 5.9783013],
          [117.8984302, 5.9786295],
          [117.8990229, 5.9794098],
          [117.8996752, 5.9800848],
          [117.9011116, 5.9814954],
          [117.9019757, 5.9827237],
          [117.9023549, 5.9836398],
          [117.9032489, 5.9845558],
          [117.9044137, 5.9852293],
          [117.9044137, 5.9861184],
          [117.9051723, 5.9871153],
          [117.9061746, 5.9880043],
          [117.9071227, 5.9888126],
          [117.9079354, 5.9892437],
          [117.9086397, 5.989567],
          [117.9089106, 5.9906985],
          [117.9089648, 5.9916954],
          [117.9089648, 5.9931771],
          [117.9091544, 5.9947667],
          [117.9097504, 5.9951439],
          [117.9112674, 5.9956019],
          [117.9124323, 5.9958713],
          [117.9127303, 5.9954672],
          [117.9134617, 5.9952247],
          [117.9142744, 5.9952247],
          [117.9151141, 5.9956288],
          [117.9166853, 5.9958713],
          [117.9179315, 5.9964371],
          [117.9192047, 5.9969489],
          [117.9196652, 5.9975147],
          [117.9210197, 5.9977302],
          [117.922022, 5.9980535],
          [117.9228076, 5.9987271],
          [117.9242162, 5.9994275],
          [117.924321172164497, 5.9995],
          [117.9251915, 6.0001011],
          [117.926573, 6.0006938],
          [117.9276566, 6.0016098],
          [117.9285235, 6.0024719],
          [117.9307448, 6.0048158],
          [117.9317201, 6.0055432],
          [117.9330204, 6.0062975],
          [117.933833, 6.0070519],
          [117.9343477, 6.0076715],
          [117.9355126, 6.0085336],
          [117.9359189, 6.0097459],
          [117.9365149, 6.0113354],
          [117.9376527, 6.0147838],
          [117.939549, 6.0149185],
          [117.9403887, 6.0161578],
          [117.9415807, 6.0168044],
          [117.9428539, 6.0172085],
          [117.9451023, 6.0177473],
          [117.9461588, 6.017882],
          [117.9475133, 6.0182591],
          [117.9488678, 6.0181244],
          [117.9509266, 6.0187441],
          [117.9515496, 6.0190404],
          [117.9527687, 6.0198755],
          [117.9532563, 6.0206837],
          [117.9539335, 6.0215458],
          [117.9544753, 6.0226234],
          [117.9546108, 6.0241051],
          [117.9546649, 6.0251827],
          [117.9547733, 6.0262603],
          [117.9546379, 6.0277959],
          [117.9552338, 6.0291968],
          [117.9553693, 6.0300319],
          [117.9561549, 6.0311095],
          [117.9567238, 6.0315675],
          [117.9570488, 6.0311095],
          [117.9574281, 6.0306515],
          [117.9578073, 6.0305438],
          [117.9585929, 6.0305707],
          [117.9593514, 6.0307323],
          [117.9604892, 6.0308401],
          [117.9610039, 6.0314058],
          [117.9617353, 6.0321332],
          [117.9633607, 6.0338573],
          [117.9640921, 6.0344231],
          [117.9644714, 6.0352043],
          [117.965555, 6.035797],
          [117.965561866760254, 6.035801552256467],
          [117.9665302, 6.0364435],
          [117.9670991, 6.0361472],
          [117.9680201, 6.0355815],
          [117.9688599, 6.0354737],
          [117.9695642, 6.0372248],
          [117.9700789, 6.0383293],
          [117.9703769, 6.0394068],
          [117.9705394, 6.0400803],
          [117.9705936, 6.0408346],
          [117.9705936, 6.0415889],
          [117.970702, 6.0423971],
          [117.9718397, 6.0444175],
          [117.9727337, 6.0456028],
          [117.9730859, 6.0454951],
          [117.9746571, 6.0461147],
          [117.9750363, 6.0468151],
          [117.9744945, 6.0478118],
          [117.9740069, 6.0485392],
          [117.9733568, 6.0491049],
          [117.9721648, 6.0513677],
          [117.9719752, 6.0529032],
          [117.9717856, 6.0537114],
          [117.971298, 6.054331],
          [117.9710271, 6.0549775],
          [117.9709187, 6.0555163],
          [117.9709729, 6.0563244],
          [117.9715418, 6.056513],
          [117.9724357, 6.0560012],
          [117.9733839, 6.0558934],
          [117.9741965, 6.0558934],
          [117.9758219, 6.0564861],
          [117.9767701, 6.0571595],
          [117.977366, 6.057806],
          [117.9783954, 6.0585064],
          [117.9788289, 6.0592068],
          [117.9804001, 6.0604729],
          [117.9817816, 6.0613888],
          [117.9821338, 6.0620353],
          [117.9826756, 6.062628],
          [117.9830007, 6.0634092],
          [117.9831903, 6.0643251],
          [117.9831903, 6.0649177],
          [117.9831632, 6.0653757],
          [117.9822151, 6.0664801],
          [117.9816191, 6.0676115],
          [117.9826485, 6.0685543],
          [117.9833257, 6.0689045],
          [117.9838946, 6.0689045],
          [117.9833257, 6.0676654],
          [117.9840301, 6.0658067],
          [117.9845989, 6.0646753],
          [117.9850866, 6.0638941],
          [117.9858722, 6.06349],
          [117.9866848, 6.0628704],
          [117.9879581, 6.0624663],
          [117.9891229, 6.06217],
          [117.9906941, 6.0616313],
          [117.991886, 6.0612541],
          [117.993376, 6.0609309],
          [117.9942699, 6.0606346],
          [117.9956244, 6.0602574],
          [117.9968976, 6.0600958],
          [117.998523, 6.0594493],
          [117.999496765133657, 6.059231864422468],
          [117.9998504, 6.0591529],
          [118.000503234866343, 6.058968986449305],
          [118.0014758, 6.058695],
          [118.0021801, 6.0588566],
          [118.003372, 6.0589913],
          [118.0047265, 6.0589913],
          [118.0059726, 6.0587758],
          [118.0073271, 6.0587489],
          [118.0085462, 6.0590721],
          [118.0096568, 6.0594762],
          [118.010605, 6.060015],
          [118.0106862, 6.0588566],
          [118.0104695, 6.0571865],
          [118.0104695, 6.0562436],
          [118.0104695, 6.0555163],
          [118.0099548, 6.0552199],
          [118.0100903, 6.0546812],
          [118.0101444, 6.0539],
          [118.0104424, 6.0533612],
          [118.0109029, 6.0524722],
          [118.0113906, 6.0513677],
          [118.0119323, 6.0506673],
          [118.0118782, 6.0498592],
          [118.011824, 6.0491049],
          [118.011824, 6.0482159],
          [118.011824, 6.0473269],
          [118.011526, 6.0463302],
          [118.0114718, 6.0453334],
          [118.0119053, 6.0445522],
          [118.0120407, 6.0436363],
          [118.0119323, 6.0421546],
          [118.0120949, 6.0412926],
          [118.0125283, 6.0404305],
          [118.0126096, 6.0394877],
          [118.013341, 6.0382754],
          [118.0134765, 6.0373864],
          [118.012745, 6.0364166],
          [118.0127179, 6.0359047],
          [118.0124156, 6.0350912],
          [118.0124299, 6.0342467],
          [118.0125033, 6.0333076],
          [118.0128404, 6.0330586],
          [118.0130989, 6.0329216],
          [118.0133124, 6.0327423],
          [118.0131808, 6.0324069],
          [118.0133333, 6.0321803],
          [118.0138255, 6.031931],
          [118.014254, 6.0319986],
          [118.0145338, 6.0316512],
          [118.0145472, 6.0312356],
          [118.0143159, 6.0308067],
          [118.0138286, 6.0302744],
          [118.0133583, 6.0298098],
          [118.013357699788386, 6.029809433966875],
          [118.0126327, 6.0293673],
          [118.0120252, 6.0288678],
          [118.0112542, 6.0283323],
          [118.011063, 6.028161],
          [118.0108601, 6.0280296],
          [118.0106925, 6.0279639],
          [118.0106147, 6.0279756],
          [118.010433, 6.0281023],
          [118.0102796, 6.0282666],
          [118.0101498, 6.0283323],
          [118.009987, 6.0283159],
          [118.009843, 6.0282901],
          [118.0096778, 6.0282502],
          [118.0095811, 6.0283088],
          [118.009456, 6.0283886],
          [118.009331, 6.0284966],
          [118.009213, 6.0285975],
          [118.009095, 6.028696],
          [118.008977, 6.0287735],
          [118.0087599, 6.0287852],
          [118.0086278, 6.0287453],
          [118.0084838, 6.0286749],
          [118.0083517, 6.0286069],
          [118.0082478, 6.0285458],
          [118.0081652, 6.0285412],
          [118.0080331, 6.0285576],
          [118.0078962, 6.0286069],
          [118.0078042, 6.0286538],
          [118.0079269, 6.0285224],
          [118.0080732, 6.0284708],
          [118.0082384, 6.0284848],
          [118.0084413, 6.0286069],
          [118.0086632, 6.0286773],
          [118.0088567, 6.0287242],
          [118.0090502, 6.0286444],
          [118.0092248, 6.0284801],
          [118.0094205, 6.0283228],
          [118.0095941, 6.0282099],
          [118.0097409, 6.0281834],
          [118.0098811, 6.0282431],
          [118.010128, 6.0282763],
          [118.0102949, 6.0281369],
          [118.0103416, 6.0278781],
          [118.0101547, 6.0276789],
          [118.0098544, 6.0273404],
          [118.0094606, 6.0267895],
          [118.0091869, 6.0262585],
          [118.0089177, 6.0254874],
          [118.0088042, 6.0251091],
          [118.0087375, 6.0245582],
          [118.0087975, 6.0240604],
          [118.0088861, 6.0236879],
          [118.0089711, 6.0235635],
          [118.0089923, 6.0234203],
          [118.0089711, 6.0232819],
          [118.0088743, 6.0232068],
          [118.0088413, 6.0230824],
          [118.008813, 6.0229017],
          [118.0088083, 6.0227351],
          [118.0088484, 6.0226037],
          [118.0088932, 6.0224676],
          [118.0089876, 6.0223714],
          [118.0090678, 6.022186],
          [118.0091271, 6.0220285],
          [118.0091655, 6.0219472],
          [118.0091939, 6.0218526],
          [118.0091822, 6.0217182],
          [118.0091271, 6.021612],
          [118.0090721, 6.0215357],
          [118.0090053, 6.0214759],
          [118.0088935, 6.0214178],
          [118.0087367, 6.0213316],
          [118.0086583, 6.0212901],
          [118.0085314, 6.0212702],
          [118.008408, 6.0212685],
          [118.0083162, 6.0212818],
          [118.0082394, 6.0213249],
          [118.008181, 6.0213714],
          [118.008116, 6.0214543],
          [118.0080242, 6.0215937],
          [118.0079808, 6.0216933],
          [118.0079174, 6.0217995],
          [118.0078523, 6.0219472],
          [118.0078073, 6.0220816],
          [118.0077255, 6.0221729],
          [118.0076204, 6.0222426],
          [118.0075303, 6.0222757],
          [118.0074335, 6.0222641],
          [118.0073401, 6.0222392],
          [118.0072817, 6.0222027],
          [118.0072233, 6.0221447],
          [118.0071816, 6.0220385],
          [118.0071265, 6.0219422],
          [118.0070764, 6.0218576],
          [118.0070114, 6.0218111],
          [118.006963, 6.0218061],
          [118.0069079, 6.021836],
          [118.0068595, 6.0218742],
          [118.0068328, 6.021914],
          [118.0067817, 6.0218578],
          [118.0068568, 6.0217931],
          [118.0069469, 6.0217516],
          [118.0070254, 6.02173],
          [118.007032, 6.0216537],
          [118.0070137, 6.0215491],
          [118.0069603, 6.0214479],
          [118.0068802, 6.0213699],
          [118.0067984, 6.0213351],
          [118.0065882, 6.0212903],
          [118.0064731, 6.0212571],
          [118.0063312, 6.0212289],
          [118.006298273801804, 6.021219958189589],
          [118.0062211, 6.021199],
          [118.0060909, 6.0211824],
          [118.0060359, 6.0211808],
          [118.0059825, 6.0212156],
          [118.0059338, 6.0211517],
          [118.0059472, 6.0211135],
          [118.0059338, 6.0210322],
          [118.0059171, 6.0209576],
          [118.0059236, 6.0208391],
          [118.0060121, 6.0207063],
          [118.0060705, 6.0206632],
          [118.0062039, 6.0205968],
          [118.0062974, 6.0205288],
          [118.006298273801804, 6.020528107352225],
          [118.0063958, 6.0204508],
          [118.0064809, 6.020401],
          [118.0065927, 6.0203413],
          [118.0066661, 6.0202882],
          [118.0067212, 6.0202251],
          [118.0067696, 6.0201571],
          [118.006808, 6.0200758],
          [118.0068931, 6.0199845],
          [118.0069331, 6.0199065],
          [118.0070227, 6.0197785],
          [118.0070652, 6.0197292],
          [118.0071123, 6.0196518],
          [118.0070758, 6.0195567],
          [118.0070109, 6.0195051],
          [118.0069153, 6.019511],
          [118.0068622, 6.0194887],
          [118.0067666, 6.0194324],
          [118.0067006, 6.0194265],
          [118.0065649, 6.0194265],
          [118.0064174, 6.0193972],
          [118.006298273801804, 6.019370995953283],
          [118.0062251, 6.0193549],
          [118.0061283, 6.0193455],
          [118.0060328, 6.0193361],
          [118.0059785, 6.0192998],
          [118.0059335, 6.0192491],
          [118.0058759, 6.0191844],
          [118.0058142, 6.019157],
          [118.0057541, 6.0191645],
          [118.0056824, 6.0191994],
          [118.0056365, 6.0191894],
          [118.0055714, 6.0191355],
          [118.0055097, 6.0191355],
          [118.0054379, 6.019157],
          [118.0053904, 6.0192018],
          [118.0053637, 6.0192466],
          [118.0052852, 6.0192815],
          [118.005246, 6.0192624],
          [118.0052293, 6.0192201],
          [118.0052175, 6.0191794],
          [118.0051455, 6.0191489],
          [118.0051007, 6.0191184],
          [118.0050807, 6.0190679],
          [118.0050324, 6.0189436],
          [118.0050248, 6.018899],
          [118.0049976, 6.0188433],
          [118.0049528, 6.0188398],
          [118.0049097, 6.0188327],
          [118.0048682, 6.0188833],
          [118.0048415, 6.0189895],
          [118.0047614, 6.0190475],
          [118.0046846, 6.0190376],
          [118.0046446, 6.019011],
          [118.0045695, 6.0190193],
          [118.0045612, 6.018855],
          [118.0046262, 6.018943],
          [118.0046863, 6.0189778],
          [118.0047697, 6.0189845],
          [118.0048081, 6.0189198],
          [118.0048498, 6.0188385],
          [118.0049333, 6.0187704],
          [118.005, 6.018782],
          [118.0050434, 6.0188667],
          [118.0050751, 6.0189546],
          [118.0050968, 6.0190376],
          [118.0051302, 6.0191056],
          [118.0052153, 6.0191272],
          [118.0052829, 6.019158],
          [118.0053337, 6.0191357],
          [118.0054198, 6.0191181],
          [118.0055083, 6.0190829],
          [118.005618, 6.0190712],
          [118.00569, 6.0190947],
          [118.005782, 6.0191158],
          [118.0058257, 6.0191264],
          [118.0058245, 6.0190771],
          [118.0058021, 6.0190172],
          [118.0057986, 6.0189363],
          [118.0058316, 6.0188577],
          [118.0058847, 6.0187779],
          [118.0059354, 6.0187321],
          [118.0060416, 6.0187227],
          [118.0061549, 6.0187368],
          [118.006254, 6.0187497],
          [118.006298273801804, 6.01875354581753],
          [118.0063484, 6.0187579],
          [118.006385, 6.0187814],
          [118.0064286, 6.0187802],
          [118.0064569, 6.0187638],
          [118.0064911, 6.0186875],
          [118.006497, 6.0186112],
          [118.0064711, 6.0185197],
          [118.006381, 6.0183952],
          [118.0063291, 6.0183553],
          [118.006298273801804, 6.018339709738843],
          [118.0062595, 6.0183201],
          [118.0062016, 6.0182861],
          [118.0061869, 6.0182377],
          [118.0062269, 6.0181497],
          [118.006232, 6.0179971],
          [118.006268, 6.0178004],
          [118.0062881, 6.0177593],
          [118.0062916, 6.0177065],
          [118.0062704, 6.0176584],
          [118.0062527, 6.0176021],
          [118.0062421, 6.017534],
          [118.0062574, 6.0174613],
          [118.0062621, 6.0174132],
          [118.0062716, 6.0173486],
          [118.006268, 6.0172507],
          [118.0062421, 6.0171756],
          [118.0061972, 6.0171146],
          [118.0061925, 6.0170137],
          [118.0062043, 6.0169128],
          [118.006235, 6.0168213],
          [118.006298273801804, 6.016836718875922],
          [118.0063023, 6.0168377],
          [118.006298273801804, 6.016847268931052],
          [118.0062723, 6.016909],
          [118.0062539, 6.0170136],
          [118.0062656, 6.0171247],
          [118.0062856, 6.0171928],
          [118.0062956, 6.0172708],
          [118.006298273801804, 6.017296678581733],
          [118.006304, 6.0173521],
          [118.006298273801804, 6.017423336632337],
          [118.0062956, 6.0174566],
          [118.006298273801804, 6.017550576269304],
          [118.006299, 6.0175761],
          [118.0063123, 6.0176773],
          [118.0063423, 6.0177835],
          [118.0063273, 6.0179013],
          [118.0063073, 6.0180026],
          [118.006298273801804, 6.018061463155771],
          [118.0062956, 6.0180789],
          [118.0062773, 6.0181602],
          [118.006298273801804, 6.018237647430682],
          [118.0063056, 6.0182647],
          [118.0063707, 6.0183162],
          [118.0064508, 6.0184025],
          [118.0065059, 6.0184954],
          [118.0065492, 6.0185784],
          [118.0065576, 6.0186912],
          [118.0065442, 6.0187559],
          [118.0064992, 6.0188107],
          [118.0064374, 6.0188289],
          [118.0063557, 6.0187908],
          [118.006298273801804, 6.018783968439749],
          [118.0061321, 6.0187642],
          [118.006007, 6.0187924],
          [118.0059519, 6.0188439],
          [118.0058985, 6.0189584],
          [118.0058952, 6.019053],
          [118.0059435, 6.0191558],
          [118.0060403, 6.0192222],
          [118.0062522, 6.0193019],
          [118.006298273801804, 6.019305135206422],
          [118.0064174, 6.0193135],
          [118.0065392, 6.0193035],
          [118.0066594, 6.0193168],
          [118.0067912, 6.0193334],
          [118.0069047, 6.0193666],
          [118.0070432, 6.019423],
          [118.0071499, 6.0194877],
          [118.0072217, 6.0195242],
          [118.0072551, 6.0196039],
          [118.0072567, 6.019662],
          [118.0072801, 6.0197034],
          [118.0073535, 6.019735],
          [118.0073952, 6.0197682],
          [118.007452, 6.0197101],
          [118.0075037, 6.019662],
          [118.0075788, 6.0196255],
          [118.0076489, 6.0195956],
          [118.0076839, 6.0195856],
          [118.0077323, 6.0196172],
          [118.0076689, 6.0196338],
          [118.0076172, 6.0196537],
          [118.0075421, 6.0196885],
          [118.007477, 6.01973],
          [118.007472, 6.0198063],
          [118.0075354, 6.0198976],
          [118.0076005, 6.019949],
          [118.0077022, 6.0200038],
          [118.0077707, 6.0200353],
          [118.0078758, 6.0200386],
          [118.0079726, 6.0200403],
          [118.0081061, 6.0200602],
          [118.0081845, 6.0200835],
          [118.0082345, 6.0201183],
          [118.0082746, 6.0201598],
          [118.0082128, 6.0201432],
          [118.008056, 6.02011],
          [118.0079509, 6.0201017],
          [118.0078294, 6.0200893],
          [118.0076996, 6.0200401],
          [118.0075038, 6.0199321],
          [118.0073952, 6.01985],
          [118.0073197, 6.0197608],
          [118.0072513, 6.0197326],
          [118.0071852, 6.0197303],
          [118.007105, 6.0198453],
          [118.00702, 6.0200307],
          [118.0069304, 6.0201457],
          [118.0068289, 6.0202771],
          [118.006718, 6.0204038],
          [118.0066279, 6.0204727],
          [118.006517, 6.0205477],
          [118.0063731, 6.0206463],
          [118.006298273801804, 6.020705437489617],
          [118.006189, 6.0207918],
          [118.0061229, 6.0208035],
          [118.0060403, 6.0208106],
          [118.0060333, 6.0209443],
          [118.0060309, 6.0211016],
          [118.0061111, 6.0211344],
          [118.006298273801804, 6.02110021092271],
          [118.0063164, 6.0210969],
          [118.0064769, 6.0211063],
          [118.0066279, 6.0211274],
          [118.0067388, 6.0211626],
          [118.0068733, 6.0212447],
          [118.0069654, 6.0213245],
          [118.0070598, 6.0214489],
          [118.007107, 6.0215662],
          [118.0071683, 6.0217],
          [118.0072344, 6.0218173],
          [118.0072816, 6.0219652],
          [118.0073052, 6.022052],
          [118.0074066, 6.0221365],
          [118.0075081, 6.0221647],
          [118.0075978, 6.0221365],
          [118.0076733, 6.0220731],
          [118.0077323, 6.0219605],
          [118.0077579, 6.0218766],
          [118.0078213, 6.0217306],
          [118.0078864, 6.021631],
          [118.0079348, 6.0215447],
          [118.0080249, 6.0214186],
          [118.0080799, 6.0213406],
          [118.0081383, 6.0212759],
          [118.0082418, 6.0212228],
          [118.0083436, 6.0211531],
          [118.0084053, 6.0211315],
          [118.0084437, 6.0210569],
          [118.0084637, 6.0209822],
          [118.0084454, 6.0209208],
          [118.0084036, 6.0208793],
          [118.0083986, 6.0208328],
          [118.0084387, 6.0208129],
          [118.0085088, 6.0208826],
          [118.0085288, 6.0209639],
          [118.0085455, 6.0210303],
          [118.0085437, 6.0210877],
          [118.0087419, 6.0212191],
          [118.0089307, 6.0212613],
          [118.0091053, 6.0213552],
          [118.0092138, 6.0215289],
          [118.0092893, 6.0217119],
          [118.0093129, 6.021909],
          [118.0092705, 6.0221015],
          [118.0091525, 6.0223737],
          [118.0090014, 6.0226412],
          [118.0089118, 6.0228947],
          [118.0090109, 6.0229838],
          [118.0091147, 6.0230589],
          [118.0092091, 6.0229838],
          [118.0093696, 6.0228008],
          [118.0095583, 6.0225286],
          [118.0097047, 6.0222939],
          [118.0099076, 6.0220968],
          [118.0102049, 6.0218762],
          [118.0104787, 6.0217119],
          [118.0107193, 6.021604],
          [118.0110828, 6.0214538],
          [118.0115736, 6.0212379],
          [118.0117482, 6.0210783],
          [118.0119464, 6.020914],
          [118.0121494, 6.0206934],
          [118.0123853, 6.0203743],
          [118.0126072, 6.0200739],
          [118.0128243, 6.0197923],
          [118.0132632, 6.019398],
          [118.0135652, 6.0191305],
          [118.013905, 6.0189146],
          [118.0142543, 6.0186565],
          [118.0146554, 6.0184218],
          [118.0149999, 6.0181777],
          [118.0153067, 6.0178867],
          [118.01542, 6.0176004],
          [118.0155002, 6.0172202],
          [118.0155569, 6.0168025],
          [118.0155757, 6.016535],
          [118.015571, 6.0160609],
          [118.015656, 6.0157606],
          [118.0157928, 6.0154555],
          [118.0159533, 6.0151692],
          [118.0161279, 6.014958],
          [118.0164016, 6.0147749],
          [118.0166282, 6.0144604],
          [118.0167556, 6.0141741],
          [118.016883, 6.0139864],
          [118.0170293, 6.0137752],
          [118.0171709, 6.0136156],
          [118.0172795, 6.0134419],
          [118.0174399, 6.0132448],
          [118.0176429, 6.0130759],
          [118.0178458, 6.0128881],
          [118.0180336, 6.0127422],
          [118.018127, 6.0126792],
          [118.0182939, 6.0125995],
          [118.0184107, 6.0124867],
          [118.0184541, 6.0123838],
          [118.0186376, 6.0122411],
          [118.0188478, 6.0121581],
          [118.0189324, 6.0121318],
          [118.0192628, 6.0120051],
          [118.0196781, 6.0119628],
          [118.020084, 6.0119675],
          [118.0205512, 6.0119722],
          [118.0209807, 6.01193],
          [118.0212402, 6.011944],
          [118.0214385, 6.011944],
          [118.0215565, 6.0118173],
          [118.0216367, 6.0116624],
          [118.0216499, 6.0115296],
          [118.0215965, 6.0114035],
          [118.0213963, 6.0110915],
          [118.021196, 6.0106734],
          [118.0210559, 6.0102552],
          [118.0209691, 6.009983],
          [118.0207689, 6.0097043],
          [118.0204685, 6.0095317],
          [118.0202816, 6.0093724],
          [118.0202349, 6.0091932],
          [118.0199946, 6.0090206],
          [118.0198345, 6.0087484],
          [118.0196338, 6.0083672],
          [118.019596, 6.0081959],
          [118.0195323, 6.00798],
          [118.019509009989022, 6.00790392793715],
          [118.0194065, 6.0075691],
          [118.0193581, 6.007514],
          [118.0192637, 6.0075093],
          [118.0192378, 6.0075287],
          [118.0191422, 6.0075405],
          [118.0190549, 6.0075487],
          [118.0190136, 6.007524],
          [118.0189676, 6.0075358],
          [118.0189121, 6.0075475],
          [118.0188197, 6.0076176],
          [118.0188998, 6.007528],
          [118.0189732, 6.0074915],
          [118.0190633, 6.0074981],
          [118.0192568, 6.0074317],
          [118.0194037, 6.0073189],
          [118.0194771, 6.0070202],
          [118.0194971, 6.0067149],
          [118.0194704, 6.0064759],
          [118.0194304, 6.0061905],
          [118.0193303, 6.0059117],
          [118.0191033, 6.0055599],
          [118.0189031, 6.0053342],
          [118.0187729, 6.0050986],
          [118.0186128, 6.0050023],
          [118.0184993, 6.0048862],
          [118.0183892, 6.0047866],
          [118.0183558, 6.0046738],
          [118.0183224, 6.0045742],
          [118.018259, 6.004551],
          [118.018209, 6.0046339],
          [118.0181856, 6.004687],
          [118.0181322, 6.0047435],
          [118.0180755, 6.0047733],
          [118.0179887, 6.0047534],
          [118.0179086, 6.0047103],
          [118.0178419, 6.0046572],
          [118.0178218, 6.0045775],
          [118.0178218, 6.0044746],
          [118.0178552, 6.0043784],
          [118.0178619, 6.0043153],
          [118.0178352, 6.0042489],
          [118.0177718, 6.0042357],
          [118.0177284, 6.0043054],
          [118.017685, 6.0043883],
          [118.0176632, 6.004256],
          [118.0176966, 6.0042063],
          [118.0177066, 6.0041498],
          [118.0177833, 6.0041233],
          [118.0178434, 6.0041565],
          [118.0179202, 6.0042195],
          [118.0179202, 6.004339],
          [118.0179135, 6.0044784],
          [118.0179068, 6.0046078],
          [118.0179435, 6.0046543],
          [118.0180603, 6.0046643],
          [118.0181171, 6.0046344],
          [118.0181404, 6.0045547],
          [118.0181938, 6.0044751],
          [118.0182773, 6.0044286],
          [118.0183807, 6.0044419],
          [118.0184374, 6.004495],
          [118.0184508, 6.004578],
          [118.0184975, 6.0046244],
          [118.0186076, 6.0045381],
          [118.0186043, 6.0044187],
          [118.018611, 6.0042959],
          [118.0186744, 6.0042295],
          [118.0187027, 6.0041173],
          [118.0187895, 6.0039497],
          [118.0188479, 6.0038634],
          [118.018943, 6.0037871],
          [118.0190381, 6.0037406],
          [118.0190972, 6.0037008],
          [118.0190171, 6.0035581],
          [118.0189671, 6.0034021],
          [118.0189637, 6.0032262],
          [118.0189904, 6.003037],
          [118.0190591, 6.002844],
          [118.0191575, 6.0026764],
          [118.0192893, 6.0024872],
          [118.0193544, 6.0023511],
          [118.0194045, 6.0022399],
          [118.0194712, 6.0021669],
          [118.0195046, 6.0020574],
          [118.0195046, 6.0019081],
          [118.0194679, 6.0018035],
          [118.0194128, 6.0016956],
          [118.0193444, 6.0016392],
          [118.019271, 6.0015795],
          [118.0191942, 6.001528],
          [118.0190658, 6.0015098],
          [118.0189273, 6.0014899],
          [118.0187971, 6.0014617],
          [118.0186937, 6.0014799],
          [118.0186469, 6.0015894],
          [118.0186319, 6.0017355],
          [118.0186586, 6.0018201],
          [118.0186803, 6.0019678],
          [118.0186937, 6.0021454],
          [118.0187137, 6.0022051],
          [118.0187454, 6.0022781],
          [118.0187771, 6.0023312],
          [118.0187954, 6.0024756],
          [118.0187954, 6.0026299],
          [118.0187637, 6.0027013],
          [118.0186486, 6.002761],
          [118.0185335, 6.0028274],
          [118.0183616, 6.0028805],
          [118.0182181, 6.0029353],
          [118.0179728, 6.0029801],
          [118.0177526, 6.0030033],
          [118.0176207, 6.0029983],
          [118.017524, 6.002927],
          [118.0174689, 6.0028407],
          [118.0174355, 6.0027129],
          [118.0174472, 6.0025361],
          [118.0174522, 6.0024482],
          [118.0174789, 6.0022922],
          [118.0175139, 6.0021345],
          [118.0175406, 6.0020433],
          [118.0175323, 6.0019553],
          [118.0174973, 6.0019254],
          [118.0174105, 6.0019537],
          [118.0173221, 6.0019736],
          [118.017262, 6.0019703],
          [118.0172286, 6.0019437],
          [118.0171702, 6.0019404],
          [118.0171135, 6.0019503],
          [118.0171001, 6.0019122],
          [118.0171802, 6.0018989],
          [118.0172253, 6.0019022],
          [118.0172586, 6.0019354],
          [118.0173187, 6.0019454],
          [118.0173988, 6.0019205],
          [118.0174589, 6.0018972],
          [118.0175406, 6.0018757],
          [118.0175832, 6.00186],
          [118.0176183, 6.0018401],
          [118.01766, 6.0017571],
          [118.017695, 6.0016791],
          [118.017705, 6.0015978],
          [118.0177134, 6.0014717],
          [118.01771, 6.0013837],
          [118.0177034, 6.001324],
          [118.0177151, 6.0012128],
          [118.0177351, 6.001095],
          [118.0177351, 6.0010219],
          [118.0176984, 6.0009191],
          [118.01764, 6.0008626],
          [118.0175665, 6.0008162],
          [118.0174881, 6.0007797],
          [118.0174314, 6.0007564],
          [118.0173697, 6.0007415],
          [118.0172645, 6.0007166],
          [118.0171911, 6.000778],
          [118.0171761, 6.0008261],
          [118.0171411, 6.000861],
          [118.0171294, 6.0009473],
          [118.0171327, 6.0010336],
          [118.0171027, 6.0011746],
          [118.0170676, 6.0012742],
          [118.0170426, 6.0013887],
          [118.0169759, 6.0014567],
          [118.0168741, 6.0015098],
          [118.0168007, 6.0015447],
          [118.0166889, 6.0015447],
          [118.0166154, 6.0015148],
          [118.0165604, 6.0014401],
          [118.0165404, 6.0013605],
          [118.0165053, 6.001251],
          [118.016482, 6.0011414],
          [118.0164703, 6.0009987],
          [118.0164836, 6.0009008],
          [118.0164903, 6.0008062],
          [118.016487, 6.0007166],
          [118.0164402, 6.0006618],
          [118.0164035, 6.0005905],
          [118.0163668, 6.0005108],
          [118.016367188160672, 6.0005],
          [118.0163702, 6.0004162],
          [118.0164052, 6.000325],
          [118.0164452, 6.0002652],
          [118.0164636, 6.0001474],
          [118.0164953, 6.0000528],
          [118.016547, 5.9999765],
          [118.0165954, 5.9999416],
          [118.0166154, 5.9998769],
          [118.0166538, 5.9998155],
          [118.0167322, 5.9996678],
          [118.0168224, 5.9995268],
          [118.0168841, 5.9994338],
          [118.0169291, 5.9993393],
          [118.0169475, 5.9992281],
          [118.0169425, 5.999097],
          [118.0168974, 5.9990306],
          [118.0168224, 5.9989875],
          [118.0167189, 5.9989991],
          [118.0166004, 5.9990372],
          [118.0165287, 5.9991003],
          [118.0164636, 5.9991916],
          [118.0164019, 5.9992696],
          [118.0163485, 5.9993675],
          [118.0162951, 5.9994139],
          [118.0161866, 5.999409],
          [118.0161299, 5.9993459],
          [118.0161299, 5.9992596],
          [118.0161432, 5.99916],
          [118.0161583, 5.9990455],
          [118.0161966, 5.9989393],
          [118.0162634, 5.9986871],
          [118.0163201, 5.9985527],
          [118.0163068, 5.9984498],
          [118.0162851, 5.9983552],
          [118.0162333, 5.9982872],
          [118.0161883, 5.9981942],
          [118.0161733, 5.9981295],
          [118.0161449, 5.9980947],
          [118.0160832, 5.9981312],
          [118.0159864, 5.9981627],
          [118.0158512, 5.9982208],
          [118.0157595, 5.9982855],
          [118.0156527, 5.9983502],
          [118.0155676, 5.998405],
          [118.0154391, 5.998468],
          [118.0153557, 5.9985079],
          [118.0152839, 5.9985477],
          [118.0151421, 5.9985659],
          [118.0150336, 5.9985659],
          [118.0148901, 5.9985659],
          [118.0146932, 5.9985643],
          [118.0144338, 5.9985639],
          [118.0141955, 5.9985592],
          [118.0140161, 5.9985709],
          [118.0138651, 5.9985686],
          [118.0137542, 5.998578],
          [118.0136575, 5.9985991],
          [118.0136268, 5.9985475],
          [118.0137825, 5.9985263],
          [118.0142002, 5.9985287],
          [118.0145494, 5.9985357],
          [118.0147665, 5.9985428],
          [118.0149388, 5.9985451],
          [118.0150697, 5.9985395],
          [118.0152018, 5.9985278],
          [118.0153151, 5.9984902],
          [118.0154331, 5.9984198],
          [118.0155652, 5.9983588],
          [118.0157021, 5.9982626],
          [118.0158366, 5.9981922],
          [118.0159498, 5.9981311],
          [118.0160891, 5.9980889],
          [118.0161504, 5.9980607],
          [118.0161929, 5.9980138],
          [118.0162189, 5.9979058],
          [118.0162613, 5.9978049],
          [118.0162731, 5.9977275],
          [118.0162307, 5.9976266],
          [118.0161504, 5.9975609],
          [118.0160324, 5.9975092],
          [118.0159569, 5.9974858],
          [118.015846, 5.9974787],
          [118.0157115, 5.997467],
          [118.0155841, 5.9974506],
          [118.0155204, 5.9974388],
          [118.0154283, 5.9973872],
          [118.0153693, 5.9973074],
          [118.0153033, 5.9972417],
          [118.0151994, 5.9971877],
          [118.0151121, 5.9971736],
          [118.0150272, 5.9971901],
          [118.0149658, 5.9972253],
          [118.0149233, 5.9972863],
          [118.0149163, 5.9973684],
          [118.0149375, 5.9974412],
          [118.0149517, 5.9975045],
          [118.0149186, 5.9975444],
          [118.014803, 5.9976031],
          [118.0147298, 5.9975984],
          [118.014593, 5.9975726],
          [118.0144538, 5.9975632],
          [118.0143877, 5.9975397],
          [118.0142909, 5.997474],
          [118.0142249, 5.9974107],
          [118.0140809, 5.9972206],
          [118.0140054, 5.9971384],
          [118.0139511, 5.997068],
          [118.0137741, 5.997007],
          [118.0136444, 5.996953],
          [118.0134273, 5.9968803],
          [118.013281, 5.9968052],
          [118.0131111, 5.996723],
          [118.0129836, 5.9966503],
          [118.012986, 5.9965893],
          [118.0130183, 5.9965704],
          [118.0131103, 5.996615],
          [118.0132047, 5.996669],
          [118.0133722, 5.9967136],
          [118.0136389, 5.996777],
          [118.0137545, 5.996838],
          [118.0138395, 5.9968873],
          [118.0139126, 5.9969319],
          [118.0139622, 5.9969882],
          [118.0140518, 5.9970633],
          [118.0141014, 5.9971759],
          [118.0141934, 5.9972698],
          [118.0142878, 5.9973472],
          [118.0143657, 5.9974083],
          [118.0144483, 5.9974857],
          [118.0145568, 5.9975162],
          [118.0146937, 5.9975115],
          [118.014755, 5.9974576],
          [118.0148211, 5.9973801],
          [118.0148353, 5.9973027],
          [118.0148541, 5.9972229],
          [118.0149249, 5.9971618],
          [118.0149948, 5.9971284],
          [118.015108, 5.9971213],
          [118.0152685, 5.9971471],
          [118.0153464, 5.9972058],
          [118.0154124, 5.9973067],
          [118.0155328, 5.9973795],
          [118.0156555, 5.9974217],
          [118.0158136, 5.9974405],
          [118.0159269, 5.9974311],
          [118.0160283, 5.9973795],
          [118.0161109, 5.9974123],
          [118.0161652, 5.9974757],
          [118.0162077, 5.9975297],
          [118.0162879, 5.9975766],
          [118.0163422, 5.9976189],
          [118.0163587, 5.9977221],
          [118.0163587, 5.9978301],
          [118.0163091, 5.9979357],
          [118.0162785, 5.9980413],
          [118.0162502, 5.9981633],
          [118.0162926, 5.9982666],
          [118.0163752, 5.998337],
          [118.0163799, 5.9985552],
          [118.0163965, 5.9986327],
          [118.0163445, 5.9987406],
          [118.0163021, 5.9988697],
          [118.0162714, 5.9989777],
          [118.0162785, 5.9990645],
          [118.0163398, 5.9990974],
          [118.0164389, 5.9990762],
          [118.0165121, 5.9989965],
          [118.016538, 5.9989378],
          [118.0166348, 5.9989073],
          [118.0167599, 5.9988885],
          [118.0168448, 5.9988885],
          [118.0169368, 5.9989026],
          [118.0169911, 5.9989894],
          [118.0170171, 5.9990551],
          [118.0170407, 5.9991466],
          [118.0170289, 5.999318],
          [118.016951, 5.9994259],
          [118.016905440489353, 5.9995],
          [118.0168731, 5.9995526],
          [118.016833, 5.9995832],
          [118.0168047, 5.9996935],
          [118.0167622, 5.9998178],
          [118.0165994, 6.0000291],
          [118.0165475, 6.0001534],
          [118.016505, 6.0002497],
          [118.0165003, 6.0004961],
          [118.0165404, 6.0006533],
          [118.0165664, 6.0007331],
          [118.0165664, 6.0008645],
          [118.016564, 6.0010523],
          [118.0165829, 6.0011931],
          [118.0166395, 6.0012963],
          [118.0167103, 6.0013621],
          [118.0168047, 6.0013785],
          [118.0169203, 6.0012799],
          [118.0169864, 6.0011368],
          [118.0170194, 6.0010077],
          [118.0170336, 6.0008622],
          [118.0170478, 6.0007495],
          [118.0171138, 6.0006463],
          [118.0171823, 6.0006064],
          [118.0173191, 6.0006087],
          [118.0175173, 6.0006463],
          [118.0176165, 6.0007167],
          [118.0177439, 6.000834],
          [118.0178194, 6.0009162],
          [118.017843, 6.0009913],
          [118.0178619, 6.0010734],
          [118.0178312, 6.0011673],
          [118.0177911, 6.0012518],
          [118.0177934, 6.0013902],
          [118.017817, 6.0015052],
          [118.0178052, 6.0016179],
          [118.0177722, 6.001754],
          [118.0177156, 6.0019394],
          [118.0176872, 6.002045],
          [118.0176495, 6.0021623],
          [118.0175999, 6.0023712],
          [118.0175834, 6.0026129],
          [118.017574, 6.0027068],
          [118.017633, 6.0027983],
          [118.017699, 6.0028452],
          [118.0178666, 6.0028335],
          [118.0180247, 6.0027913],
          [118.0181568, 6.0027608],
          [118.0182913, 6.0027068],
          [118.0184117, 6.0026669],
          [118.0184778, 6.0026552],
          [118.0185816, 6.0026059],
          [118.0186241, 6.0025707],
          [118.0186241, 6.0024346],
          [118.0185887, 6.0022492],
          [118.0185627, 6.0020661],
          [118.0185556, 6.0019206],
          [118.0185132, 6.0017375],
          [118.0184825, 6.0016484],
          [118.0184141, 6.0015568],
          [118.0183834, 6.0014911],
          [118.018407, 6.001416],
          [118.0184612, 6.0013691],
          [118.0185486, 6.0013574],
          [118.0187963, 6.0013667],
          [118.0189568, 6.0013738],
          [118.0191597, 6.0013785],
          [118.0193414, 6.0014254],
          [118.0194807, 6.0014817],
          [118.0195798, 6.0015991],
          [118.0196175, 6.0017493],
          [118.0196388, 6.0019229],
          [118.0196128, 6.0020802],
          [118.0195751, 6.0022093],
          [118.0195444, 6.0022867],
          [118.0194854, 6.0024674],
          [118.019417, 6.0025589],
          [118.0192848, 6.002742],
          [118.0191951, 6.0028875],
          [118.019155, 6.0030588],
          [118.0191267, 6.0031926],
          [118.0191621, 6.0033146],
          [118.0192895, 6.0033662],
          [118.019365, 6.0034226],
          [118.0194759, 6.003507],
          [118.0195963, 6.0035282],
          [118.0197827, 6.0035399],
          [118.019955, 6.0035728],
          [118.0201792, 6.0035962],
          [118.0204222, 6.0036244],
          [118.0205331, 6.0036643],
          [118.0205898, 6.0037112],
          [118.0206723, 6.0037675],
          [118.0208045, 6.0037722],
          [118.0208871, 6.0037136],
          [118.020939, 6.0036479],
          [118.0209343, 6.003554],
          [118.0208894, 6.0034672],
          [118.0208375, 6.003378],
          [118.020788, 6.0032067],
          [118.0207573, 6.0030987],
          [118.0207974, 6.0030142],
          [118.0208234, 6.0028851],
          [118.0208588, 6.0027349],
          [118.0208588, 6.0025636],
          [118.0209414, 6.0021858],
          [118.0209555, 6.0020239],
          [118.0209862, 6.0017774],
          [118.0210334, 6.0016507],
          [118.02109, 6.0015381],
          [118.0211703, 6.0013973],
          [118.021267, 6.0012283],
          [118.0213944, 6.0011203],
          [118.0214865, 6.0010452],
          [118.0216564, 6.0009701],
          [118.0217979, 6.000895],
          [118.0219419, 6.000773],
          [118.0220127, 6.0006557],
          [118.0220434, 6.0005829],
          [118.0220563, 6.0005078],
          [118.02205471111111, 6.0005],
          [118.0220398, 6.0004268],
          [118.0220068, 6.0002802],
          [118.0219832, 6.0002274],
          [118.021923, 6.0001335],
          [118.0218699, 6.0000443],
          [118.0218086, 5.9999633],
          [118.0217567, 5.9998871],
          [118.0216693, 5.9998108],
          [118.0215891, 5.9997463],
          [118.0215242, 5.9996806],
          [118.0214935, 5.9996242],
          [118.02149, 5.9995808],
          [118.0215136, 5.9995421],
          [118.021510637561946, 5.9995],
          [118.0215065, 5.9994412],
          [118.0215018, 5.9992757],
          [118.021536, 5.9991889],
          [118.0215089, 5.9991255],
          [118.0214629, 5.9990493],
          [118.021457, 5.9989366],
          [118.0214522, 5.9988791],
          [118.021457, 5.9988228],
          [118.0214817, 5.9987946],
          [118.0215065, 5.9987336],
          [118.0215289, 5.9986479],
          [118.0215891, 5.9985881],
          [118.0216363, 5.9985423],
          [118.0216859, 5.99854],
          [118.0217413, 5.9985236],
          [118.0217861, 5.9984801],
          [118.0218015, 5.9984215],
          [118.0217696, 5.9983804],
          [118.0217295, 5.9983652],
          [118.0216587, 5.9983288],
          [118.0216139, 5.9982912],
          [118.0215891, 5.9982372],
          [118.0215726, 5.9981704],
          [118.0215573, 5.9980882],
          [118.0215704, 5.9980209],
          [118.0216005, 5.9979487],
          [118.021653, 5.997894],
          [118.0217565, 5.9978674],
          [118.0218507, 5.9978666],
          [118.0219066, 5.9978823],
          [118.0219408, 5.9978915],
          [118.0219859, 5.9979255],
          [118.0220051, 5.9979678],
          [118.0220718, 5.9980026],
          [118.0221636, 5.9980209],
          [118.0222637, 5.9980217],
          [118.0223338, 5.9980068],
          [118.0223816, 5.9979813],
          [118.0224124, 5.9979439],
          [118.0224475, 5.9979025],
          [118.0224608, 5.9978502],
          [118.0224491, 5.9977904],
          [118.0224283, 5.997734],
          [118.022364, 5.9976455],
          [118.0223144, 5.9975739],
          [118.0222507, 5.9975082],
          [118.0221882, 5.9974565],
          [118.0221528, 5.9974295],
          [118.0220832, 5.9974131],
          [118.0220124, 5.9974108],
          [118.0219617, 5.9974237],
          [118.0218897, 5.9974272],
          [118.0218024, 5.9974671],
          [118.0216667, 5.9974753],
          [118.0216101, 5.997473],
          [118.0215381, 5.9974554],
          [118.0214472, 5.9973603],
          [118.0213941, 5.9972946],
          [118.0213517, 5.997196],
          [118.021334, 5.9971315],
          [118.0213481, 5.9970787],
          [118.0213812, 5.99702],
          [118.0214484, 5.9969801],
          [118.0215251, 5.9969743],
          [118.0216313, 5.9969719],
          [118.021721, 5.9969778],
          [118.0218189, 5.9969731],
          [118.0219363, 5.9969664],
          [118.0219741, 5.9969529],
          [118.0220207, 5.9969359],
          [118.0220614, 5.9969171],
          [118.0220903, 5.9968866],
          [118.0221009, 5.9968555],
          [118.022088, 5.9968291],
          [118.0220363, 5.9967843],
          [118.0219979, 5.9967295],
          [118.0219512, 5.9966366],
          [118.0219329, 5.996527],
          [118.0219395, 5.9964026],
          [118.0219662, 5.9962997],
          [118.0219762, 5.9961188],
          [118.0219879, 5.996001],
          [118.0220013, 5.9958682],
          [118.022023, 5.9957554],
          [118.0220413, 5.9956442],
          [118.022078, 5.9955347],
          [118.0221131, 5.9954517],
          [118.0221515, 5.9954086],
          [118.0222149, 5.9953737],
          [118.0222766, 5.9953455],
          [118.02233, 5.9953189],
          [118.022365, 5.9952675],
          [118.0223917, 5.9952028],
          [118.0223934, 5.9951049],
          [118.02237, 5.9950053],
          [118.0223166, 5.9949389],
          [118.0222349, 5.9948908],
          [118.0221631, 5.9948526],
          [118.0220613, 5.9948344],
          [118.0220196, 5.9948377],
          [118.0219546, 5.9948377],
          [118.0219145, 5.9948062],
          [118.0218928, 5.9947149],
          [118.0218811, 5.994612],
          [118.0219045, 5.9944627],
          [118.0219229, 5.9943515],
          [118.0219412, 5.9942967],
          [118.0219963, 5.9942187],
          [118.0220597, 5.9941474],
          [118.0221281, 5.9940992],
          [118.0221765, 5.9940411],
          [118.0222098, 5.993962],
          [118.0221744, 5.9938869],
          [118.0220753, 5.993854],
          [118.021955, 5.9938963],
          [118.0218417, 5.9939362],
          [118.0217143, 5.9939573],
          [118.0216222, 5.9939409],
          [118.0216128, 5.9937953],
          [118.0215986, 5.9936217],
          [118.021575, 5.9934879],
          [118.0215184, 5.9933682],
          [118.0215821, 5.9933401],
          [118.0216836, 5.9933307],
          [118.0217402, 5.9934081],
          [118.021752, 5.9935395],
          [118.0217, 5.9936873],
          [118.0216667, 5.9937885],
          [118.0216934, 5.9938516],
          [118.0217635, 5.9938682],
          [118.0219019, 5.9938482],
          [118.0220054, 5.993825],
          [118.0221272, 5.9938117],
          [118.0221906, 5.99382],
          [118.022249, 5.9938648],
          [118.0222607, 5.993903],
          [118.0222473, 5.9939876],
          [118.0221789, 5.9941005],
          [118.0220888, 5.9941718],
          [118.0220187, 5.9942614],
          [118.0219687, 5.9943793],
          [118.0219403, 5.9944855],
          [118.0219286, 5.994595],
          [118.0219236, 5.9946995],
          [118.0219503, 5.994746],
          [118.0219954, 5.9947892],
          [118.0221172, 5.9948157],
          [118.0222473, 5.9948638],
          [118.0223441, 5.994912],
          [118.0223942, 5.9949584],
          [118.0224192, 5.9950265],
          [118.0224242, 5.9950928],
          [118.0224359, 5.9951592],
          [118.0224392, 5.9952704],
          [118.0223792, 5.9953401],
          [118.0222991, 5.9953799],
          [118.0221973, 5.9954314],
          [118.0221572, 5.9954778],
          [118.0221372, 5.9955475],
          [118.0221089, 5.9956355],
          [118.0220855, 5.9957517],
          [118.0220638, 5.9958628],
          [118.0220555, 5.9960155],
          [118.0220588, 5.9961234],
          [118.0220354, 5.9962644],
          [118.0220121, 5.996359],
          [118.0219937, 5.9964652],
          [118.0219837, 5.9965482],
          [118.0220004, 5.9966445],
          [118.0220354, 5.9967291],
          [118.0220905, 5.9967822],
          [118.0221155, 5.996817],
          [118.0221322, 5.9968784],
          [118.0220738, 5.9969614],
          [118.0219971, 5.9969913],
          [118.0219103, 5.9970178],
          [118.0218135, 5.9970195],
          [118.0216917, 5.9970212],
          [118.0215832, 5.9970228],
          [118.0214798, 5.9970311],
          [118.0214164, 5.997066],
          [118.0213897, 5.9971157],
          [118.021408, 5.997202],
          [118.0214347, 5.9972833],
          [118.0214931, 5.9973364],
          [118.0215666, 5.9973945],
          [118.0216266, 5.9974261],
          [118.0217251, 5.9974277],
          [118.0218052, 5.9974211],
          [118.0219086, 5.9973912],
          [118.0220137, 5.9973846],
          [118.0220972, 5.9973862],
          [118.0221673, 5.9974061],
          [118.0222307, 5.9974443],
          [118.0222824, 5.9974974],
          [118.0223441, 5.9975671],
          [118.0223908, 5.9976435],
          [118.0224626, 5.997743],
          [118.0224943, 5.9978061],
          [118.022501, 5.9978691],
          [118.022486, 5.9979388],
          [118.0224409, 5.9979836],
          [118.0224175, 5.9980152],
          [118.0223692, 5.9980484],
          [118.0223108, 5.9980616],
          [118.0222357, 5.9980666],
          [118.0221406, 5.998055],
          [118.0220671, 5.9980334],
          [118.0220304, 5.9980168],
          [118.0219403, 5.9979919],
          [118.0218919, 5.9979289],
          [118.0218269, 5.9979056],
          [118.0217651, 5.9979007],
          [118.0217234, 5.9979023],
          [118.0216517, 5.9979388],
          [118.0216233, 5.9980351],
          [118.0215999, 5.9980998],
          [118.0216316, 5.9981994],
          [118.0216892, 5.9982541],
          [118.0217576, 5.9983081],
          [118.0218027, 5.9983728],
          [118.0218527, 5.9984334],
          [118.0218529, 5.9984898],
          [118.0218212, 5.9985379],
          [118.0217128, 5.9985877],
          [118.0216143, 5.9986756],
          [118.0215476, 5.9987536],
          [118.0215259, 5.9988432],
          [118.0215442, 5.9989262],
          [118.021611, 5.9989976],
          [118.0217094, 5.9990274],
          [118.0217695, 5.9990109],
          [118.0218162, 5.9989727],
          [118.021913, 5.9989627],
          [118.0219981, 5.9989461],
          [118.0221399, 5.9989246],
          [118.02224, 5.9989345],
          [118.0223335, 5.9989378],
          [118.0224186, 5.9989163],
          [118.0224803, 5.9988466],
          [118.0225454, 5.9987669],
          [118.0226388, 5.9986292],
          [118.0227456, 5.9984417],
          [118.022804, 5.9983023],
          [118.0228507, 5.9982259],
          [118.0229158, 5.9981446],
          [118.0229492, 5.9981031],
          [118.0229742, 5.9980202],
          [118.0229926, 5.9979214],
          [118.0230226, 5.9978783],
          [118.0230385, 5.9978111],
          [118.0230401, 5.9977214],
          [118.0230187, 5.9976426],
          [118.0229951, 5.9975417],
          [118.0229526, 5.9974548],
          [118.022955, 5.9973234],
          [118.0229786, 5.9972108],
          [118.0230494, 5.9971239],
          [118.0230777, 5.9970394],
          [118.0231603, 5.9969714],
          [118.0233278, 5.9969573],
          [118.0234151, 5.9969432],
          [118.0234364, 5.9968705],
          [118.0234671, 5.9967719],
          [118.0235402, 5.9967062],
          [118.0236393, 5.9966428],
          [118.0237715, 5.9966475],
          [118.0240004, 5.9966053],
          [118.0241443, 5.9965536],
          [118.0242529, 5.9964715],
          [118.0243968, 5.9962838],
          [118.02447, 5.9961077],
          [118.0245407, 5.9959927],
          [118.0245667, 5.9959012],
          [118.0245313, 5.9957886],
          [118.0244251, 5.9956407],
          [118.0243661, 5.995521],
          [118.0243708, 5.9953826],
          [118.0244416, 5.9952629],
          [118.0244959, 5.9951221],
          [118.0245294, 5.9949522],
          [118.0245861, 5.9948349],
          [118.0246309, 5.9947351],
          [118.0246887, 5.9946213],
          [118.0247135, 5.9945086],
          [118.0247265, 5.9944253],
          [118.0246993, 5.9943291],
          [118.0246639, 5.9942763],
          [118.0246297, 5.9942388],
          [118.024592, 5.9941965],
          [118.0245684, 5.994186],
          [118.0245082, 5.9941766],
          [118.024448, 5.9941414],
          [118.0244268, 5.9940768],
          [118.0244374, 5.9940416],
          [118.0244704, 5.9939818],
          [118.0245212, 5.9939149],
          [118.0245931, 5.993848],
          [118.024651, 5.9938105],
          [118.0246875, 5.9937647],
          [118.0247041, 5.9936861],
          [118.0247123, 5.9936051],
          [118.0247218, 5.993523],
          [118.0247265, 5.9934749],
          [118.0247465, 5.9934127],
          [118.0247709, 5.9933423],
          [118.0247892, 5.9932942],
          [118.0248109, 5.9932975],
          [118.0247892, 5.9933606],
          [118.0247709, 5.9934187],
          [118.0247492, 5.9934933],
          [118.0247392, 5.993578],
          [118.0247408, 5.9936842],
          [118.0247077, 5.9937769],
          [118.0246723, 5.9938402],
          [118.0245992, 5.9938825],
          [118.0245355, 5.9939482],
          [118.0245166, 5.9940233],
          [118.0245473, 5.9941007],
          [118.0246086, 5.9941594],
          [118.0247266, 5.9943026],
          [118.0247644, 5.9944621],
          [118.0247434, 5.9945876],
          [118.0247316, 5.9946556],
          [118.0246867, 5.9947589],
          [118.0246513, 5.9949373],
          [118.0245896, 5.9951625],
          [118.0245141, 5.9952962],
          [118.0244693, 5.9954089],
          [118.024507, 5.9955427],
          [118.0245731, 5.9956224],
          [118.0246321, 5.9957327],
          [118.0246651, 5.995829],
          [118.0246698, 5.9959651],
          [118.0246345, 5.9960308],
          [118.0245566, 5.9961176],
          [118.0245023, 5.9962537],
          [118.0244433, 5.9964086],
          [118.0242805, 5.9965776],
          [118.0242168, 5.9966574],
          [118.0241271, 5.9966644],
          [118.023995, 5.9966879],
          [118.0239171, 5.9967489],
          [118.02391, 5.9968076],
          [118.0239171, 5.9968968],
          [118.023884, 5.9969484],
          [118.0238321, 5.9968851],
          [118.0237897, 5.9968193],
          [118.0237236, 5.9968522],
          [118.0235678, 5.9969954],
          [118.0234192, 5.997101],
          [118.0232965, 5.9971761],
          [118.0231879, 5.9972206],
          [118.0231124, 5.9972887],
          [118.0230912, 5.9973873],
          [118.0231313, 5.9974858],
          [118.0231525, 5.9975609],
          [118.0231714, 5.9976947],
          [118.0231742, 5.997796],
          [118.0231342, 5.997952],
          [118.0230608, 5.9981678],
          [118.0229206, 5.9984399],
          [118.0228038, 5.9986888],
          [118.0225836, 5.9989743],
          [118.0224501, 5.9990539],
          [118.0222632, 5.9991269],
          [118.0220396, 5.9991468],
          [118.0218727, 5.9991867],
          [118.021786, 5.9992199],
          [118.0217226, 5.9993228],
          [118.0216992, 5.9994688],
          [118.0217526, 5.9996148],
          [118.0218694, 5.999721],
          [118.0219695, 5.9998505],
          [118.0220997, 6.000033],
          [118.0221497, 6.0002155],
          [118.0221898, 6.0004246],
          [118.022196532867142, 6.0005],
          [118.0222064, 6.0006105],
          [118.0221163, 6.0007665],
          [118.0220362, 6.0009092],
          [118.0217292, 6.0011747],
          [118.0215457, 6.0012876],
          [118.0213822, 6.0014435],
          [118.0212654, 6.0016028],
          [118.0211519, 6.0018916],
          [118.0211452, 6.0021405],
          [118.0210685, 6.0023961],
          [118.0210117, 6.0026815],
          [118.0209517, 6.0028441],
          [118.020965, 6.0029968],
          [118.0210051, 6.0031262],
          [118.0210651, 6.0032557],
          [118.0211085, 6.0034282],
          [118.0211229, 6.0035637],
          [118.0211394, 6.0036129],
          [118.0211323, 6.003674],
          [118.0210356, 6.0038148],
          [118.0209459, 6.0038993],
          [118.0209152, 6.0039814],
          [118.0209506, 6.0040729],
          [118.0209719, 6.0042278],
          [118.0209648, 6.0043639],
          [118.0209437, 6.0045603],
          [118.0209136, 6.0047246],
          [118.0209453, 6.0048888],
          [118.0210521, 6.0049851],
          [118.0211923, 6.0050249],
          [118.0213875, 6.0050432],
          [118.0216428, 6.0050564],
          [118.0220099, 6.0050216],
          [118.0220933, 6.0050316],
          [118.0222685, 6.0050199],
          [118.022654, 6.0050166],
          [118.0228025, 6.0050083],
          [118.0228859, 6.0049519],
          [118.0229476, 6.0048789],
          [118.0230277, 6.0045005],
          [118.0231112, 6.004089],
          [118.0232046, 6.0038865],
          [118.0233314, 6.0037903],
          [118.023505, 6.0038367],
          [118.0236952, 6.0040093],
          [118.0237753, 6.0042184],
          [118.0237819, 6.0045038],
          [118.0237819, 6.0047063],
          [118.0236617, 6.0047322],
          [118.023631, 6.0046242],
          [118.0235791, 6.0042417],
          [118.0235366, 6.0040539],
          [118.0234682, 6.0039835],
          [118.0233879, 6.0039882],
          [118.0232936, 6.0040891],
          [118.0232558, 6.0042299],
          [118.0232416, 6.0043895],
          [118.0231779, 6.0048378],
          [118.0231001, 6.0049997],
          [118.02289, 6.0051335],
          [118.0227272, 6.0051687],
          [118.0225479, 6.0051757],
          [118.0223945, 6.0051921],
          [118.0219178, 6.005225],
          [118.0213444, 6.0052203],
          [118.0211249, 6.0051945],
          [118.0209692, 6.0051499],
          [118.0208347, 6.0050701],
          [118.0207545, 6.0049668],
          [118.0207332, 6.0048777],
          [118.0207332, 6.0047462],
          [118.0207592, 6.0045374],
          [118.0207828, 6.0043309],
          [118.020771, 6.0041502],
          [118.0207073, 6.0040492],
          [118.0205775, 6.0039929],
          [118.0202259, 6.0039108],
          [118.0200088, 6.0038967],
          [118.019761, 6.003885],
          [118.0195368, 6.0039624],
          [118.0193008, 6.0041666],
          [118.0191711, 6.004352],
          [118.0189988, 6.0045585],
          [118.0190318, 6.0046923],
          [118.0191309, 6.0048331],
          [118.0192584, 6.0049528],
          [118.0194306, 6.0050959],
          [118.0195392, 6.0052743],
          [118.0196218, 6.0055066],
          [118.0197044, 6.005692],
          [118.0197445, 6.0058938],
          [118.0197917, 6.0060745],
          [118.0198412, 6.0063045],
          [118.0198483, 6.0065556],
          [118.0198436, 6.006741],
          [118.0198648, 6.006964],
          [118.0198318, 6.0071635],
          [118.0198365, 6.0073418],
          [118.0198459, 6.0075413],
          [118.0198766, 6.0076798],
          [118.0198908, 6.0078839],
          [118.019896833979843, 6.00790392793715],
          [118.0199403, 6.0080482],
          [118.0199875, 6.0081843],
          [118.0200796, 6.0083627],
          [118.0201763, 6.0085269],
          [118.0202849, 6.0086326],
          [118.0204099, 6.0087147],
          [118.0205374, 6.0088015],
          [118.0206483, 6.0089118],
          [118.020863, 6.0090878],
          [118.0209621, 6.0091934],
          [118.0210235, 6.0093014],
          [118.0211297, 6.009468],
          [118.0212618, 6.00959],
          [118.0214624, 6.0098482],
          [118.0215332, 6.0099491],
          [118.0215969, 6.010104],
          [118.0216417, 6.0102401],
          [118.0217408, 6.010456],
          [118.0218399, 6.0106344],
          [118.0219815, 6.0110052],
          [118.0220618, 6.0111765],
          [118.0221325, 6.0112868],
          [118.0222812, 6.0112023],
          [118.0224676, 6.0110568],
          [118.0226069, 6.0109535],
          [118.0227532, 6.0109348],
          [118.0228617, 6.0109582],
          [118.0229962, 6.0110145],
          [118.0231024, 6.0110591],
          [118.0232393, 6.0111436],
          [118.023395, 6.0112774],
          [118.0238056, 6.0117256],
          [118.0239661, 6.0118617],
          [118.0241053, 6.0119227],
          [118.0243271, 6.011911],
          [118.024523, 6.0118359],
          [118.0246669, 6.0117303],
          [118.0247755, 6.0115919],
          [118.0248156, 6.0114909],
          [118.0248581, 6.0113032],
          [118.02491, 6.011153],
          [118.0250091, 6.0110357],
          [118.0251955, 6.0109582],
          [118.0254574, 6.0109629],
          [118.0256698, 6.0109512],
          [118.0258185, 6.0109441],
          [118.0259011, 6.0108784],
          [118.0259483, 6.0107681],
          [118.0259341, 6.0106907],
          [118.0258444, 6.0106132],
          [118.0257477, 6.0105663],
          [118.0253206, 6.0105288],
          [118.0250964, 6.0104889],
          [118.0249761, 6.0104607],
          [118.0248887, 6.0104044],
          [118.0248534, 6.0102917],
          [118.0249005, 6.0101603],
          [118.0249831, 6.0100336],
          [118.0250634, 6.0099397],
          [118.0251648, 6.0097825],
          [118.0252875, 6.0096863],
          [118.0253819, 6.0095689],
          [118.0254928, 6.0094727],
          [118.0255872, 6.009386],
          [118.0257357, 6.0093611],
          [118.0258592, 6.0092815],
          [118.0258792, 6.0091786],
          [118.0258375, 6.0090956],
          [118.0257608, 6.0089944],
          [118.0256923, 6.0089015],
          [118.0256456, 6.0088119],
          [118.0256373, 6.0087123],
          [118.0256358, 6.0086221],
          [118.0257043, 6.0084836],
          [118.0257963, 6.0084273],
          [118.025919, 6.0083381],
          [118.0259969, 6.0083241],
          [118.0261054, 6.0082466],
          [118.0261951, 6.0081786],
          [118.026273, 6.0080636],
          [118.0263509, 6.0079673],
          [118.0263886, 6.0079627],
          [118.0264122, 6.0080072],
          [118.0263509, 6.0080941],
          [118.0262919, 6.0081762],
          [118.0262045, 6.0082795],
          [118.0261031, 6.0083522],
          [118.0259497, 6.0084297],
          [118.0258341, 6.0085282],
          [118.025742, 6.0085916],
          [118.0256878, 6.0087277],
          [118.0257137, 6.008831],
          [118.0257987, 6.0089295],
          [118.0258695, 6.0090469],
          [118.0259285, 6.0091478],
          [118.0259308, 6.0092417],
          [118.0258836, 6.0093402],
          [118.025742, 6.0094224],
          [118.0255769, 6.0094763],
          [118.0254471, 6.0095843],
          [118.0253409, 6.0097063],
          [118.0252512, 6.0098401],
          [118.0251474, 6.0099645],
          [118.0250766, 6.0100865],
          [118.0250034, 6.0101804],
          [118.024968, 6.0102907],
          [118.0250435, 6.010347],
          [118.0251545, 6.0103329],
          [118.0252842, 6.010354],
          [118.0255084, 6.0103986],
          [118.025683, 6.0104456],
          [118.0258175, 6.0104878],
          [118.0259308, 6.0105629],
          [118.0260276, 6.0106802],
          [118.0260724, 6.0107718],
          [118.0260134, 6.0109243],
          [118.0258742, 6.0110088],
          [118.0257798, 6.0110675],
          [118.0256429, 6.0110792],
          [118.0255131, 6.011044],
          [118.0252866, 6.011051],
          [118.0251356, 6.0110722],
          [118.0250341, 6.0111519],
          [118.0249374, 6.0112505],
          [118.0249421, 6.0113796],
          [118.0249657, 6.0114688],
          [118.0250412, 6.0115063],
          [118.0251356, 6.0115509],
          [118.0252512, 6.0116307],
          [118.0253881, 6.0117105],
          [118.0255839, 6.0117856],
          [118.0257633, 6.0118654],
          [118.0259261, 6.0119358],
          [118.0260748, 6.0119968],
          [118.0262258, 6.0120625],
          [118.0264334, 6.0121165],
          [118.0265939, 6.012154],
          [118.0268275, 6.0121611],
          [118.026988, 6.0121869],
          [118.0271319, 6.0122315],
          [118.027257, 6.0122737],
          [118.0273516, 6.0123135],
          [118.0274483, 6.0123799],
          [118.0275735, 6.012438],
          [118.0276452, 6.0124546],
          [118.0277554, 6.0123965],
          [118.0278021, 6.0123666],
          [118.0278341, 6.0123653],
          [118.0278105, 6.0124216],
          [118.0278129, 6.0124732],
          [118.0279851, 6.0125154],
          [118.0281857, 6.0125624],
          [118.0283193, 6.0126514],
          [118.0281024, 6.012595],
          [118.0278254, 6.0125187],
          [118.0274917, 6.0124656],
          [118.0272514, 6.0123826],
          [118.0269511, 6.0122996],
          [118.0267275, 6.0122631],
          [118.0264872, 6.0122266],
          [118.0263571, 6.0121667],
          [118.0262037, 6.0121304],
          [118.0260385, 6.0121292],
          [118.0259594, 6.0121022],
          [118.0258662, 6.0120494],
          [118.0257718, 6.0120001],
          [118.0256881, 6.0119473],
          [118.0255783, 6.0118863],
          [118.0254497, 6.0118241],
          [118.0253034, 6.0117736],
          [118.0251949, 6.011742],
          [118.0250793, 6.0116645],
          [118.0250073, 6.0116504],
          [118.0249294, 6.0117068],
          [118.0248492, 6.011783],
          [118.0248008, 6.0118253],
          [118.0247772, 6.0118945],
          [118.0248102, 6.0119485],
          [118.0248456, 6.0120071],
          [118.0248527, 6.0120717],
          [118.024835, 6.0121092],
          [118.0247914, 6.0121386],
          [118.0247639, 6.0121488],
          [118.0248239, 6.0120725],
          [118.0248139, 6.0120028],
          [118.0247505, 6.011943],
          [118.0246704, 6.0119364],
          [118.0244902, 6.0120691],
          [118.0242366, 6.0120924],
          [118.0239696, 6.0120094],
          [118.0237694, 6.0118966],
          [118.0234924, 6.0116908],
          [118.0230953, 6.0113025],
          [118.0229384, 6.0111664],
          [118.0227916, 6.0110602],
          [118.0226569, 6.0110734],
          [118.0225153, 6.0112189],
          [118.0223572, 6.0113644],
          [118.0222794, 6.0114653],
          [118.022159, 6.0115874],
          [118.0221, 6.0117188],
          [118.0221236, 6.0118502],
          [118.0220693, 6.0119816],
          [118.0219372, 6.0121389],
          [118.0217295, 6.0122984],
          [118.0214983, 6.0124533],
          [118.0213732, 6.0125308],
          [118.0212458, 6.0125566],
          [118.0211325, 6.0125448],
          [118.0210877, 6.0125941],
          [118.0211207, 6.0127396],
          [118.0211585, 6.0128312],
          [118.0212977, 6.0129813],
          [118.0213874, 6.0130658],
          [118.0215832, 6.0132231],
          [118.0213189, 6.0130541],
          [118.021149, 6.0128757],
          [118.0210216, 6.0127021],
          [118.0209319, 6.0125894],
          [118.0203137, 6.0125237],
          [118.0198276, 6.012505],
          [118.0195067, 6.0125331],
          [118.0192235, 6.0126176],
          [118.0187633, 6.0128914],
          [118.0183228, 6.0132896],
          [118.017969, 6.0136016],
          [118.0176086, 6.0140065],
          [118.0173149, 6.0143915],
          [118.0169145, 6.0149026],
          [118.0165741, 6.0153672],
          [118.0163672, 6.0156526],
          [118.0162671, 6.0160177],
          [118.0162537, 6.0163496],
          [118.016247, 6.0166549],
          [118.0162604, 6.0170332],
          [118.016207, 6.0173651],
          [118.0160668, 6.0178032],
          [118.0157064, 6.0183143],
          [118.0154795, 6.018613],
          [118.0151391, 6.0189183],
          [118.0145451, 6.0192834],
          [118.0142247, 6.0194361],
          [118.0139444, 6.0196551],
          [118.0135239, 6.0199936],
          [118.013337, 6.0202259],
          [118.0129966, 6.0206109],
          [118.0128097, 6.0208831],
          [118.0125961, 6.0211419],
          [118.0123692, 6.0214473],
          [118.0121356, 6.0216862],
          [118.0119153, 6.0218787],
          [118.0116817, 6.0219849],
          [118.0115616, 6.0221442],
          [118.0114148, 6.0222836],
          [118.0110877, 6.0225823],
          [118.010774, 6.0227947],
          [118.0104937, 6.0230137],
          [118.0102267, 6.023173],
          [118.0101199, 6.0233788],
          [118.0099798, 6.0236708],
          [118.0098329, 6.0239894],
          [118.0098396, 6.0244142],
          [118.0097996, 6.0247859],
          [118.0098412, 6.0250129],
          [118.009879, 6.0252475],
          [118.01003, 6.0254822],
          [118.0102565, 6.025623],
          [118.0105114, 6.025745],
          [118.0103132, 6.0257544],
          [118.0101999, 6.0258389],
          [118.010236, 6.0260228],
          [118.0106514, 6.0265109],
          [118.0113687, 6.0270929],
          [118.0120295, 6.0275059],
          [118.0122938, 6.027825],
          [118.0127721, 6.0281461],
          [118.0139052, 6.0290453],
          [118.0145572, 6.0293913],
          [118.0146997, 6.0293825],
          [118.0147351, 6.0292863],
          [118.0148272, 6.0292417],
          [118.0149334, 6.0293825],
          [118.0150136, 6.02942],
          [118.0150958, 6.0294123],
          [118.0151702, 6.0293769],
          [118.0152307, 6.0293426],
          [118.015279, 6.0292828],
          [118.0152991, 6.0292018],
          [118.0152718, 6.0290662],
          [118.0152085, 6.0289445],
          [118.0145534, 6.0286386],
          [118.0143366, 6.028223],
          [118.0141536, 6.0274392],
          [118.0138866, 6.0268949],
          [118.0139782, 6.0261237],
          [118.0154618, 6.0265365],
          [118.0168593, 6.0271151],
          [118.0178921, 6.0276343],
          [118.0187047, 6.0277959],
          [118.0195174, 6.027769],
          [118.0205739, 6.0276881],
          [118.0219013, 6.0276073],
          [118.0229307, 6.0276073],
          [118.0237163, 6.0276073],
          [118.0244477, 6.0274996],
          [118.0245832, 6.0268261],
          [118.0251521, 6.0270955],
          [118.0265336, 6.0282269],
          [118.0271838, 6.0283886],
          [118.0279152, 6.0285772],
          [118.028159, 6.0285502],
          [118.0288904, 6.0276073],
          [118.0291613, 6.0281731],
          [118.029351, 6.0286041],
          [118.0300282, 6.0289004],
          [118.0308951, 6.0291429],
          [118.0314369, 6.0294931],
          [118.0321683, 6.0296548],
          [118.032420988405732, 6.029809433966875],
          [118.0328726, 6.0300858],
          [118.033279, 6.0305168],
          [118.0342271, 6.0314328],
          [118.035094, 6.0319716],
          [118.0361775, 6.0328067],
          [118.036909, 6.0332647],
          [118.0375049, 6.0336418],
          [118.0379925, 6.0339651],
          [118.0389407, 6.0343422],
          [118.0389949, 6.0339382],
          [118.039645, 6.03445],
          [118.0401597, 6.0348541],
          [118.0403493, 6.0351774],
          [118.0410266, 6.0357162],
          [118.0417851, 6.0361202],
          [118.042354, 6.0364166],
          [118.0429499, 6.0364435],
          [118.0439252, 6.0364435],
          [118.0449004, 6.036228],
          [118.0453067, 6.0361472],
          [118.0462278, 6.0359586],
          [118.0462278, 6.0356623],
          [118.0466883, 6.0347194],
          [118.046905, 6.0339651],
          [118.047034680843353, 6.033671350017455],
          [118.0473926, 6.0328606],
          [118.0473655, 6.0318638],
          [118.0471488, 6.0303282],
          [118.047034680843353, 6.029987668436563],
          [118.0469863, 6.0298433],
          [118.046971031673237, 6.029809433966875],
          [118.0466341, 6.0290621],
          [118.0460652, 6.0285772],
          [118.044169, 6.0279575],
          [118.0439793, 6.0275265],
          [118.0436272, 6.0269069],
          [118.0422727, 6.0247517],
          [118.0421914, 6.0241051],
          [118.0419747, 6.022812],
          [118.0419205, 6.0221385],
          [118.0418664, 6.0211956],
          [118.0418393, 6.0199294],
          [118.0418393, 6.0191212],
          [118.0412433, 6.0189596],
          [118.0412433, 6.0186094],
          [118.0416225, 6.0176934],
          [118.0428145, 6.0168044],
          [118.0430312, 6.0162925],
          [118.0428416, 6.0152687],
          [118.0431125, 6.0147838],
          [118.0428687, 6.0140025],
          [118.0425165, 6.0137331],
          [118.0427874, 6.0130327],
          [118.0427874, 6.0125478],
          [118.0425165, 6.0118473],
          [118.0420289, 6.0111738],
          [118.0416225, 6.0107158],
          [118.0408369, 6.0098268],
          [118.0404035, 6.0091802],
          [118.0392658, 6.0089108],
          [118.038724, 6.0089108],
          [118.0378029, 6.0088569],
          [118.036555028943155, 6.00790392793715],
          [118.0362859, 6.0076984],
          [118.0356087, 6.0074829],
          [118.0350669, 6.0068094],
          [118.0347689, 6.0061089],
          [118.0345251, 6.0050852],
          [118.0345251, 6.0046002],
          [118.0347418, 6.0037112],
          [118.0350398, 6.0024449],
          [118.0353107, 6.0009093],
          [118.035506686513472, 6.0005],
          [118.0358525, 5.9997778],
          [118.0366652, 5.9978111],
          [118.0370715, 5.9967334],
          [118.0382905, 5.9954402],
          [118.0387781, 5.9944703],
          [118.0392387, 5.9931771],
          [118.0397805, 5.9921803],
          [118.0403764, 5.9912643],
          [118.04146, 5.9892975],
          [118.0419476, 5.9884893],
          [118.0427332, 5.9873039],
          [118.04359915558193, 5.985998421907425],
          [118.0453067, 5.9834242],
          [118.0465258, 5.9815652],
          [118.0479615, 5.9797601],
          [118.0491805, 5.9781705],
          [118.0507788, 5.9763923],
          [118.0516999, 5.9752338],
          [118.0525397, 5.9740483],
          [118.05384, 5.9725126],
          [118.0554653, 5.9707344],
          [118.0567927, 5.9693064],
          [118.0583368, 5.9677437],
          [118.0596913, 5.9662619],
          [118.0609916, 5.9649686],
          [118.0621565, 5.9641334],
          [118.0634568, 5.9631904],
          [118.0642694, 5.9626515],
          [118.0652447, 5.9621396],
          [118.0660845, 5.9614391],
          [118.0673306, 5.9607386],
          [118.0679807, 5.9603614],
          [118.068658, 5.9599033],
          [118.0692539, 5.9593106],
          [118.0695248, 5.9587178],
          [118.0691727, 5.9574246],
          [118.0689289, 5.9568049],
          [118.0688205, 5.9559157],
          [118.0689289, 5.9550266],
          [118.0690914, 5.9542722],
          [118.068983, 5.9524939],
          [118.0685767, 5.951012],
          [118.0682787, 5.9509042],
          [118.0681162, 5.950042],
          [118.0675202, 5.9492876],
          [118.0670326, 5.9491798],
          [118.0660303, 5.9484524],
          [118.0655271, 5.9475036],
          [118.0644075, 5.9459746],
          [118.0632859, 5.9443587],
          [118.0630465, 5.9436177],
          [118.0624075, 5.9427656],
          [118.0611668, 5.941561],
          [118.0593312, 5.9398549],
          [118.0577079, 5.9385388],
          [118.0563899, 5.9385686],
          [118.0562584, 5.9394293],
          [118.0557119, 5.9397859],
          [118.0547351, 5.9403171],
          [118.0527204, 5.940851],
          [118.0517622, 5.941504],
          [118.0507701, 5.9419381],
          [118.0493923, 5.9419038],
          [118.0475847, 5.9416791],
          [118.0461623, 5.9418567],
          [118.0445407, 5.9431591],
          [118.0439696, 5.9448254],
          [118.0436306, 5.947185],
          [118.0430658, 5.949952],
          [118.0415689, 5.9525645],
          [118.0404656, 5.9536178],
          [118.0393239, 5.9547197],
          [118.0378718, 5.9560681],
          [118.0374261, 5.9566712],
          [118.0372553, 5.9574214],
          [118.0372453, 5.9575356],
          [118.03743, 5.9582805],
          [118.0377446, 5.9591346],
          [118.0379642, 5.9597801],
          [118.038124, 5.960525],
          [118.038104, 5.9612997],
          [118.0378095, 5.962124],
          [118.0374001, 5.9627199],
          [118.0368359, 5.9633456],
          [118.0361868, 5.9641848],
          [118.0358373, 5.9647012],
          [118.0351982, 5.966032],
          [118.0348288, 5.966901],
          [118.0341772, 5.96814],
          [118.0339401, 5.9685844],
          [118.0335756, 5.968937],
          [118.0330464, 5.9693789],
          [118.0327643, 5.9698383],
          [118.0327093, 5.9700344],
          [118.0327118, 5.9703522],
          [118.0327942, 5.9706104],
          [118.0328766, 5.9707818],
          [118.0330513, 5.9710375],
          [118.0331537, 5.9712907],
          [118.0333334, 5.9716061],
          [118.0334058, 5.9717029],
          [118.0335481, 5.9718245],
          [118.0338552, 5.9719661],
          [118.0340724, 5.9720455],
          [118.0342546, 5.9721746],
          [118.0343969, 5.9722466],
          [118.0345067, 5.9722392],
          [118.0346241, 5.9722069],
          [118.034709, 5.972341],
          [118.0347739, 5.9725644],
          [118.0349205, 5.9730084],
          [118.0349905, 5.9732382],
          [118.035039, 5.973469],
          [118.0350872, 5.9736262],
          [118.0351423, 5.9738509],
          [118.0351821, 5.9740021],
          [118.0352369, 5.9741446],
          [118.0352705, 5.974248],
          [118.0352792, 5.9743276],
          [118.0352283, 5.9743418],
          [118.0351771, 5.9741873],
          [118.0351436, 5.9740664],
          [118.034956, 5.9735645],
          [118.0349128, 5.973438],
          [118.0348306, 5.9733469],
          [118.0347891, 5.9733032],
          [118.0347292, 5.9733356],
          [118.0346743, 5.9733364],
          [118.0346743, 5.9732818],
          [118.0347067, 5.9732247],
          [118.0347741, 5.9731949],
          [118.034723, 5.972934],
          [118.0345719, 5.9726286],
          [118.0342524, 5.9724598],
          [118.033868, 5.9723108],
          [118.0336682, 5.9722016],
          [118.0334136, 5.9720377],
          [118.0332089, 5.9718391],
          [118.0330192, 5.971571],
          [118.0328594, 5.9713078],
          [118.0326847, 5.9712531],
          [118.0324899, 5.9713326],
          [118.0323601, 5.9714816],
          [118.0322153, 5.9717249],
          [118.0321005, 5.9720824],
          [118.031974, 5.9724129],
          [118.0319775, 5.9725639],
          [118.0320799, 5.9727394],
          [118.0322218, 5.972878],
          [118.0323241, 5.9729376],
          [118.032434, 5.9729996],
          [118.0325114, 5.9731213],
          [118.0325838, 5.9732628],
          [118.0326487, 5.9734292],
          [118.0326991, 5.9736029],
          [118.0326642, 5.9737072],
          [118.0326017, 5.9738537],
          [118.0323246, 5.9740573],
          [118.0321967, 5.9741628],
          [118.0320424, 5.9743296],
          [118.0317406, 5.9746374],
          [118.031324, 5.9750728],
          [118.0307592, 5.9757119],
          [118.030625, 5.9759436],
          [118.0306038, 5.9762105],
          [118.0305191, 5.9765756],
          [118.0304555, 5.9767933],
          [118.0303002, 5.9769548],
          [118.0301519, 5.9771796],
          [118.0299159, 5.9774286],
          [118.0297311, 5.9775875],
          [118.0296213, 5.9778159],
          [118.0294915, 5.9780046],
          [118.0294016, 5.9781983],
          [118.0293267, 5.9784515],
          [118.0293567, 5.9786799],
          [118.0293916, 5.9788935],
          [118.0294665, 5.9789828],
          [118.0294515, 5.979102],
          [118.0293517, 5.9792609],
          [118.0293517, 5.979385],
          [118.0293717, 5.9795142],
          [118.0293043, 5.9795178],
          [118.0292697, 5.9796013],
          [118.0292653, 5.9796687],
          [118.0292619, 5.9797238],
          [118.029222, 5.9797238],
          [118.0291969, 5.9798121],
          [118.0291844, 5.9798142],
          [118.0291954, 5.9797831],
          [118.0291997, 5.9797423],
          [118.0292046, 5.9796965],
          [118.0292242, 5.979649],
          [118.0292451, 5.9795935],
          [118.0292494, 5.9795423],
          [118.0292487, 5.9794947],
          [118.0291914, 5.9794498],
          [118.0291117, 5.9794843],
          [118.0290076, 5.9795533],
          [118.0289365, 5.9796171],
          [118.0289296, 5.979674],
          [118.0288376, 5.9797965],
          [118.0287926, 5.9798569],
          [118.028711, 5.9799328],
          [118.0286677, 5.9800138],
          [118.0285966, 5.980088],
          [118.0285428, 5.9801656],
          [118.0284578, 5.9802156],
          [118.028333, 5.9803312],
          [118.0282497, 5.9803916],
          [118.0281665, 5.9804588],
          [118.0281214, 5.9805106],
          [118.0280572, 5.9805986],
          [118.0280277, 5.9806641],
          [118.0280156, 5.9807245],
          [118.0280191, 5.9808055],
          [118.0280225, 5.9808659],
          [118.0280784, 5.9809168],
          [118.0280723, 5.9809868],
          [118.0280406, 5.9810593],
          [118.0280203, 5.9811662],
          [118.0279344, 5.9812145],
          [118.027774, 5.9812318],
          [118.027689, 5.981268],
          [118.0276075, 5.9813163],
          [118.027533, 5.9813904],
          [118.0275026, 5.98145],
          [118.0274723, 5.9815112],
          [118.027441, 5.9815785],
          [118.0273855, 5.9816423],
          [118.0273162, 5.981694],
          [118.027252, 5.9817354],
          [118.0271693, 5.981778],
          [118.027127, 5.9818139],
          [118.0270414, 5.9818714],
          [118.0271496, 5.9817639],
          [118.0272536, 5.9817001],
          [118.0273784, 5.9816053],
          [118.0274163, 5.9815503],
          [118.0274391, 5.9814894],
          [118.0274891, 5.9813808],
          [118.0275739, 5.981293],
          [118.0277236, 5.9812137],
          [118.0278474, 5.9811743],
          [118.0279337, 5.9811494],
          [118.0279654, 5.9811041],
          [118.0279394, 5.9810342],
          [118.0278808, 5.981014],
          [118.0278817, 5.9809691],
          [118.0279233, 5.9809312],
          [118.0279439, 5.980875],
          [118.0279743, 5.980703],
          [118.0280245, 5.9805771],
          [118.0280951, 5.9804581],
          [118.0283578, 5.9802509],
          [118.0285111, 5.9801098],
          [118.0286195, 5.9799472],
          [118.028879, 5.9796175],
          [118.0291277, 5.9794199],
          [118.029256, 5.979231],
          [118.0292725, 5.9789892],
          [118.0291826, 5.9785323],
          [118.0292725, 5.9782046],
          [118.0294123, 5.9777776],
          [118.0296819, 5.9775193],
          [118.0300273, 5.9770413],
          [118.030297, 5.9766937],
          [118.0304368, 5.9762964],
          [118.0305266, 5.9758396],
          [118.0309061, 5.9753132],
          [118.0311371, 5.9750214],
          [118.0315748, 5.9746071],
          [118.0319526, 5.9742173],
          [118.032062, 5.9740944],
          [118.0322103, 5.9739961],
          [118.0323031, 5.9738553],
          [118.0323154, 5.9738202],
          [118.0323995, 5.9737729],
          [118.0324842, 5.9736991],
          [118.0325301, 5.9734288],
          [118.0324877, 5.973324],
          [118.0323486, 5.9731932],
          [118.0321139, 5.9730492],
          [118.0318793, 5.97293],
          [118.0318243, 5.9727115],
          [118.0318295, 5.9723584],
          [118.0319144, 5.9719409],
          [118.0322812, 5.9710927],
          [118.0323907, 5.9706011],
          [118.0323946, 5.9702274],
          [118.03239, 5.970062],
          [118.0323293, 5.9698946],
          [118.0322625, 5.9698215],
          [118.0321582, 5.9697625],
          [118.0318706, 5.9696924],
          [118.0315715, 5.9696756],
          [118.0310388, 5.9696649],
          [118.0306007, 5.9696355],
          [118.0301694, 5.9695843],
          [118.0297163, 5.9695152],
          [118.0295297, 5.9694506],
          [118.0294655, 5.9694233],
          [118.0293636, 5.969402],
          [118.0292519, 5.96939],
          [118.0291189, 5.9693636],
          [118.0290515, 5.969357],
          [118.0289868, 5.9693786],
          [118.0289522, 5.9694285],
          [118.0289079, 5.969559],
          [118.028818, 5.9697551],
          [118.0286758, 5.9699893],
          [118.0285841, 5.9701315],
          [118.0284923, 5.9702537],
          [118.0283263, 5.9704634],
          [118.0281125, 5.9706618],
          [118.0279746, 5.9707854],
          [118.0278433, 5.9709084],
          [118.0277743, 5.9710222],
          [118.0277218, 5.9711861],
          [118.0277011, 5.9713508],
          [118.027716, 5.9714948],
          [118.027737, 5.9715764],
          [118.0277972, 5.9716572],
          [118.0278521, 5.9717096],
          [118.0279822, 5.971783],
          [118.0278497, 5.971728],
          [118.0277311, 5.9716134],
          [118.0276702, 5.9714791],
          [118.0276709, 5.9712955],
          [118.0276956, 5.9711641],
          [118.0277591, 5.9709811],
          [118.0278429, 5.9708612],
          [118.0279577, 5.9707371],
          [118.0282523, 5.9704714],
          [118.0283819, 5.9703319],
          [118.0284819, 5.970197],
          [118.0285797, 5.9700486],
          [118.0286526, 5.9699338],
          [118.0287333, 5.9697853],
          [118.028765, 5.969708],
          [118.0287828, 5.9696265],
          [118.0287809, 5.9694429],
          [118.0287615, 5.9692533],
          [118.0290433, 5.9690047],
          [118.0291354, 5.9691012],
          [118.0292872, 5.9691556],
          [118.0295564, 5.9691708],
          [118.0308905, 5.9692787],
          [118.0316193, 5.969363],
          [118.0321877, 5.9693911],
          [118.0323501, 5.969363],
          [118.0324587, 5.9692779],
          [118.0326755, 5.9690598],
          [118.0330261, 5.9687269],
          [118.033277, 5.9684342],
          [118.0335124, 5.9680775],
          [118.0336018, 5.9676617],
          [118.0335873, 5.9674479],
          [118.0336788, 5.9671719],
          [118.0335498, 5.9670111],
          [118.0333844, 5.9668137],
          [118.0332643, 5.9666689],
          [118.0331579, 5.9665227],
          [118.0330231, 5.9662997],
          [118.033142, 5.9664724],
          [118.0332057, 5.9665556],
          [118.0332727, 5.9666368],
          [118.0334824, 5.9668745],
          [118.0337059, 5.9671062],
          [118.0338365, 5.9670981],
          [118.0339297, 5.9669999],
          [118.0341684, 5.966557],
          [118.0349353, 5.9647157],
          [118.0360733, 5.963167],
          [118.0372222, 5.9617514],
          [118.0374311, 5.9611811],
          [118.0374833, 5.9605237],
          [118.0373319, 5.9598092],
          [118.0368953, 5.9592945],
          [118.0363645, 5.9584868],
          [118.0364385, 5.9573675],
          [118.0370457, 5.9554555],
          [118.0385144, 5.9541071],
          [118.0408868, 5.9516351],
          [118.0416776, 5.9491631],
          [118.0419036, 5.9468034],
          [118.0423472, 5.9437417],
          [118.043251, 5.9419438],
          [118.0441297, 5.9413082],
          [118.0454878, 5.9406725],
          [118.0469257, 5.9401958],
          [118.0488986, 5.9401989],
          [118.0497216, 5.9404342],
          [118.0509998, 5.9405136],
          [118.0520383, 5.9401163],
          [118.0531567, 5.9394012],
          [118.0537704, 5.9381529],
          [118.0541317, 5.9370094],
          [118.0541645, 5.9355392],
          [118.0536061, 5.9341997],
          [118.0531023, 5.9308455],
          [118.0539674, 5.9287437],
          [118.0540988, 5.9272081],
          [118.0543677, 5.9266553],
          [118.0535724, 5.9264207],
          [118.0531719, 5.9267128],
          [118.0525578, 5.9270049],
          [118.0513298, 5.9269518],
          [118.0503953, 5.9269518],
          [118.0494609, 5.9274564],
          [118.0486066, 5.9282264],
          [118.0476837, 5.9290655],
          [118.046776, 5.9295435],
          [118.0458683, 5.9300215],
          [118.0454678, 5.9299153],
          [118.0447206, 5.9297288],
          [118.0440532, 5.9294102],
          [118.0435593, 5.9292375],
          [118.0431722, 5.928733],
          [118.042478, 5.9279496],
          [118.0416237, 5.9269937],
          [118.0403689, 5.9245227],
          [118.0395947, 5.9232915],
          [118.0389806, 5.9222824],
          [118.038638, 5.9216233],
          [118.0383844, 5.9209727],
          [118.0374396, 5.9192539],
          [118.0365585, 5.9184041],
          [118.0355707, 5.9174746],
          [118.0346363, 5.9168108],
          [118.0337863, 5.9164415],
          [118.0326702, 5.915723],
          [118.0306101, 5.9144586],
          [118.030321, 5.9144463],
          [118.0301027, 5.9144784],
          [118.0296939, 5.9140045],
          [118.0293602, 5.9136241],
          [118.029055, 5.9130371],
          [118.0286634, 5.9128573],
          [118.0283673, 5.9129454],
          [118.0281479, 5.9130894],
          [118.027863, 5.9133365],
          [118.027613, 5.9135104],
          [118.0272268, 5.9133381],
          [118.0273159, 5.9130814],
          [118.0276066, 5.9129806],
          [118.0277327, 5.9128344],
          [118.0279875, 5.9125164],
          [118.0280213, 5.9121749],
          [118.0277554, 5.9118489],
          [118.0275548, 5.9115501],
          [118.0265903, 5.9111104],
          [118.0261075, 5.9107806],
          [118.0257749, 5.9102428],
          [118.0257052, 5.9095854],
          [118.0258607, 5.9089227],
          [118.0261204, 5.9079814],
          [118.0263875, 5.9069996],
          [118.0269945, 5.9062034],
          [118.0269144, 5.9051279],
          [118.0268343, 5.9041055],
          [118.0267809, 5.9033752],
          [118.0266474, 5.9026582],
          [118.0259533, 5.9018349],
          [118.0249922, 5.9015163],
          [118.0238976, 5.9016491],
          [118.0235772, 5.9019412],
          [118.0237641, 5.9027379],
          [118.0244048, 5.9035611],
          [118.0251257, 5.9045968],
          [118.0252592, 5.9054997],
          [118.0251791, 5.9062964],
          [118.0245383, 5.9071993],
          [118.0234437, 5.9074648],
          [118.0223758, 5.906854],
          [118.0214414, 5.9061901],
          [118.0207206, 5.9052872],
          [118.019359, 5.9043843],
          [118.0187717, 5.9044109],
          [118.0183178, 5.9049154],
          [118.0179707, 5.9053669],
          [118.0174501, 5.9054997],
          [118.0170497, 5.9054864],
          [118.0169097, 5.9056807],
          [118.0169631, 5.9061587],
          [118.0170407, 5.9068763],
          [118.0172484, 5.9077401],
          [118.0174749, 5.9082283],
          [118.0177581, 5.9084536],
          [118.0181734, 5.9084161],
          [118.0183433, 5.9081344],
          [118.0187209, 5.9079842],
          [118.0192117, 5.9079279],
          [118.0195138, 5.9080593],
          [118.0196397, 5.9083869],
          [118.0195329, 5.9086259],
          [118.0192926, 5.9090243],
          [118.0189856, 5.9094359],
          [118.0184778, 5.9099373],
          [118.0178638, 5.9101763],
          [118.0174099, 5.9101498],
          [118.0173315, 5.9102777],
          [118.0173881, 5.9105312],
          [118.0176524, 5.9106251],
          [118.0179639, 5.9106908],
          [118.0181904, 5.9107659],
          [118.0185261, 5.9109064],
          [118.0188432, 5.9111072],
          [118.0190254, 5.9113728],
          [118.0190167, 5.911639],
          [118.0188106, 5.911954],
          [118.018224, 5.9119783],
          [118.0180772, 5.9121244],
          [118.0180837, 5.9123319],
          [118.018184, 5.9124696],
          [118.0183206, 5.9125045],
          [118.0184276, 5.9125393],
          [118.0184637, 5.9126377],
          [118.018441, 5.9127318],
          [118.0182507, 5.9128281],
          [118.0180371, 5.9131335],
          [118.0174632, 5.9132264],
          [118.0179771, 5.9130605],
          [118.018155, 5.9127894],
          [118.0183419, 5.9126832],
          [118.0183373, 5.912634],
          [118.0183085, 5.9125903],
          [118.0180349, 5.9125106],
          [118.0179548, 5.9122982],
          [118.0179815, 5.912006],
          [118.0182151, 5.9118401],
          [118.0185288, 5.9118401],
          [118.0187958, 5.9118268],
          [118.0188625, 5.9117007],
          [118.0189426, 5.9115148],
          [118.0188912, 5.9112907],
          [118.0186035, 5.9111186],
          [118.0183432, 5.9109726],
          [118.0180829, 5.910873],
          [118.0177625, 5.9108],
          [118.0172352, 5.9106074],
          [118.0171485, 5.9103286],
          [118.0171084, 5.9100033],
          [118.0170045, 5.9095289],
          [118.0166647, 5.9091158],
          [118.0162871, 5.9091722],
          [118.0159473, 5.9094538],
          [118.0155698, 5.9095853],
          [118.0154376, 5.9100359],
          [118.0149279, 5.9100923],
          [118.0147014, 5.9097355],
          [118.0143805, 5.9093975],
          [118.0137952, 5.9093412],
          [118.0134932, 5.9093412],
          [118.0135748, 5.9092054],
          [118.0138552, 5.9090925],
          [118.0143824, 5.9091589],
          [118.0146895, 5.9092651],
          [118.014863, 5.909544],
          [118.0150632, 5.9097298],
          [118.0153769, 5.9096435],
          [118.0155638, 5.9092253],
          [118.0159976, 5.9090726],
          [118.0165182, 5.9088535],
          [118.0169854, 5.9088734],
          [118.0172925, 5.9095373],
          [118.0174055, 5.9099157],
          [118.0177992, 5.9099423],
          [118.0182865, 5.9096966],
          [118.018727, 5.9092917],
          [118.0191608, 5.9088269],
          [118.0192943, 5.908422],
          [118.0192142, 5.908183],
          [118.0187337, 5.9082162],
          [118.01848, 5.9084021],
          [118.0182331, 5.9087008],
          [118.017559, 5.9087207],
          [118.0172386, 5.9084087],
          [118.0171051, 5.908017],
          [118.0168246, 5.9071723],
          [118.0167019, 5.9065339],
          [118.0167232, 5.9043564],
          [118.0166165, 5.9036792],
          [118.0164029, 5.9035464],
          [118.0156019, 5.9035199],
          [118.0148411, 5.9037589],
          [118.0146942, 5.9039979],
          [118.0145698, 5.9036748],
          [118.0149568, 5.90344],
          [118.015778, 5.903318],
          [118.0165992, 5.9032804],
          [118.016873, 5.9035058],
          [118.0169202, 5.9040597],
          [118.0169579, 5.9051019],
          [118.0171467, 5.9052239],
          [118.0176658, 5.9051864],
          [118.0179207, 5.9048953],
          [118.0182983, 5.9045291],
          [118.018579, 5.904227],
          [118.0189, 5.9040204],
          [118.0193908, 5.904058],
          [118.0197778, 5.9043209],
          [118.0202773, 5.9046005],
          [118.0207045, 5.9049324],
          [118.0212251, 5.9054104],
          [118.0215721, 5.9058221],
          [118.0219459, 5.9061806],
          [118.0226133, 5.9066187],
          [118.0234276, 5.9070304],
          [118.0241351, 5.906964],
          [118.0244798, 5.9067905],
          [118.0247892, 5.9064594],
          [118.0249191, 5.9060513],
          [118.0249484, 5.9056747],
          [118.0249191, 5.9051875],
          [118.0248566, 5.9048793],
          [118.0245685, 5.9043938],
          [118.0240778, 5.9037082],
          [118.0235595, 5.9031635],
          [118.0230425, 5.9024653],
          [118.0230405, 5.9016528],
          [118.0234143, 5.9010951],
          [118.0241618, 5.9008428],
          [118.0252297, 5.9009889],
          [118.025969, 5.9012676],
          [118.0265512, 5.9016262],
          [118.0271386, 5.902476],
          [118.0272587, 5.9035382],
          [118.0273121, 5.9048129],
          [118.0274189, 5.9058088],
          [118.0277259, 5.906393],
          [118.0280463, 5.906632],
          [118.0287401, 5.9067135],
          [118.0293408, 5.9068596],
          [118.0297637, 5.9066704],
          [118.0297872, 5.9060335],
          [118.0298006, 5.9052762],
          [118.0298347, 5.9040446],
          [118.0299682, 5.9021326],
          [118.03002, 5.9011913],
          [118.0300559, 5.9006548],
          [118.0300939, 5.9001371],
          [118.0299148, 5.8997957],
          [118.0293007, 5.8993176],
          [118.0286891, 5.8987584],
          [118.0285703, 5.8984192],
          [118.028563589262077, 5.898376397788525],
          [118.028519, 5.898092],
          [118.0284836, 5.8977022],
          [118.0284531, 5.8973525],
          [118.0284836, 5.8969984],
          [118.0285517, 5.8966296],
          [118.0286961, 5.8964029],
          [118.028806, 5.8962663],
          [118.0291138, 5.896111],
          [118.0294334, 5.8961733],
          [118.0297012, 5.8963699],
          [118.0299682, 5.8965823],
          [118.0303144, 5.8967111],
          [118.0306156, 5.8966288],
          [118.0308759, 5.8959848],
          [118.0310494, 5.8956794],
          [118.0312622, 5.8955492],
          [118.0314899, 5.8954471],
          [118.0316864, 5.8953823],
          [118.0319229, 5.8953766],
          [118.0321736, 5.8953956],
          [118.0323643, 5.8954869],
          [118.0325273, 5.8956413],
          [118.0327113, 5.8958587],
          [118.0331986, 5.896184],
          [118.0326402, 5.8959184],
          [118.0323332, 5.8955998],
          [118.0320601, 5.8954819],
          [118.0317992, 5.8954803],
          [118.0314461, 5.8955682],
          [118.0311852, 5.8957392],
          [118.0310189, 5.8959201],
          [118.0309316, 5.8961707],
          [118.0306913, 5.8967284],
          [118.030485, 5.8968296],
          [118.0302241, 5.8968612],
          [118.0299612, 5.8967877],
          [118.0297168, 5.8966332],
          [118.0295034, 5.8964403],
          [118.0292363, 5.8963234],
          [118.0290503, 5.8963558],
          [118.0289394, 5.8964127],
          [118.0287813, 5.8965811],
          [118.0287163, 5.8967378],
          [118.0286255, 5.8972008],
          [118.0286306, 5.8976563],
          [118.0286895, 5.8981676],
          [118.028746550937868, 5.898376397788525],
          [118.0288215, 5.8986507],
          [118.0291378, 5.8989187],
          [118.0295316, 5.8992611],
          [118.0299757, 5.8995545],
          [118.0303723, 5.9000567],
          [118.0303318, 5.9007008],
          [118.0302752, 5.9019401],
          [118.030203, 5.9039406],
          [118.0301952, 5.9054671],
          [118.0301665, 5.9059737],
          [118.0301398, 5.9064916],
          [118.0298784, 5.9070807],
          [118.0297831, 5.9078011],
          [118.0300663, 5.9085146],
          [118.0309347, 5.9085522],
          [118.0317276, 5.9078386],
          [118.0327847, 5.9069185],
          [118.0333511, 5.9061486],
          [118.0340118, 5.9059045],
          [118.0343327, 5.9061111],
          [118.034748, 5.9062989],
          [118.0353521, 5.9059233],
          [118.0358052, 5.9054914],
          [118.0358704, 5.9056623],
          [118.0355634, 5.9059279],
          [118.0350695, 5.9063129],
          [118.0347224, 5.9064855],
          [118.0344554, 5.9063793],
          [118.0341884, 5.9062598],
          [118.0337212, 5.9062864],
          [118.0332674, 5.9068042],
          [118.0317935, 5.9082799],
          [118.0311706, 5.9088056],
          [118.0302267, 5.9089934],
          [118.0291641, 5.9090923],
          [118.0289034, 5.9086057],
          [118.0285784, 5.9080497],
          [118.0279979, 5.9077082],
          [118.0273799, 5.9076004],
          [118.0269905, 5.9079259],
          [118.026762, 5.9085129],
          [118.0266536, 5.9091948],
          [118.026821, 5.9097775],
          [118.0270656, 5.9104263],
          [118.028396, 5.9111029],
          [118.0293476, 5.9118702],
          [118.0311232, 5.9134091],
          [118.0334228, 5.913911],
          [118.0352649, 5.9151481],
          [118.0364396, 5.9167414],
          [118.0379881, 5.9175912],
          [118.0389492, 5.9196094],
          [118.0402841, 5.9217338],
          [118.0418325, 5.9243893],
          [118.0435946, 5.9275228],
          [118.0451964, 5.9286912],
          [118.0466114, 5.9285319],
          [118.0473322, 5.9274431],
          [118.0483734, 5.9255577],
          [118.0496282, 5.924469],
          [118.0514437, 5.9238051],
          [118.0535261, 5.9238051],
          [118.055475, 5.9234068],
          [118.0566017, 5.923726],
          [118.0574492, 5.9242024],
          [118.0581029, 5.92348],
          [118.0577127, 5.9227707],
          [118.0582778, 5.9226369],
          [118.0590447, 5.9227172],
          [118.059233, 5.921941],
          [118.0588967, 5.9215396],
          [118.0593676, 5.9208838],
          [118.059233, 5.9202816],
          [118.0595021, 5.9194118],
          [118.0599999, 5.9184616],
          [118.059825, 5.9176052],
          [118.0597236, 5.9167423],
          [118.0616547, 5.9166684],
          [118.0645474, 5.9155577],
          [118.0675207, 5.9133362],
          [118.0721085, 5.9092411],
          [118.0733463, 5.9084917],
          [118.0741401, 5.9073676],
          [118.0757008, 5.9070732],
          [118.0771807, 5.9058554],
          [118.0790508, 5.9053602],
          [118.0801944, 5.9049186],
          [118.0806787, 5.9031387],
          [118.081969, 5.9034101],
          [118.0830226, 5.9035166],
          [118.0843151, 5.9028868],
          [118.0856702, 5.9028175],
          [118.0864976, 5.9030517],
          [118.0871512, 5.9026044],
          [118.0877915, 5.9019759],
          [118.0885055, 5.9012266],
          [118.0893612, 5.9005996],
          [118.0904316, 5.9001425],
          [118.0914597, 5.8995517],
          [118.0921123, 5.8986583],
          [118.092222898470496, 5.898376397788525],
          [118.0927015, 5.8971565],
          [118.0948587, 5.8924139],
          [118.0956477, 5.8900117],
          [118.0967908, 5.8879689],
          [118.0980501, 5.8874293],
          [118.0981276, 5.8859454],
          [118.0974495, 5.8845],
          [118.0971589, 5.8821681],
          [118.0964227, 5.8814744],
          [118.0973526, 5.8782367],
          [118.0992524, 5.8771541],
          [118.1024011, 5.8760278],
          [118.1032569, 5.8749932],
          [118.1031221, 5.8737982],
          [118.1039146, 5.8731561],
          [118.1038995, 5.8721088],
          [118.1052571, 5.8700268],
          [118.1094322, 5.8697343],
          [118.109714, 5.8673157],
          [118.1116731, 5.8677598],
          [118.1132779, 5.871954],
          [118.1147911, 5.8702133],
          [118.1156197, 5.8704298],
          [118.1156027, 5.8690053],
          [118.1166634, 5.8685136],
          [118.1174982, 5.8664662],
          [118.1193157, 5.865421],
          [118.1216596, 5.8651661],
          [118.1212372, 5.8643071],
          [118.1215288, 5.8636992],
          [118.1230616, 5.8628575],
          [118.1241614, 5.8619309],
          [118.1257885, 5.8589984],
          [118.1280467, 5.8565822],
          [118.128507494926453, 5.856344137194227],
          [118.1296277, 5.8557654],
          [118.1302123, 5.854252],
          [118.1302362, 5.8536619],
          [118.1297388, 5.8511099],
          [118.129262, 5.8481994],
          [118.1296007, 5.8472504],
          [118.1303067, 5.8469427],
          [118.1302377, 5.8462436],
          [118.1297832, 5.8458833],
          [118.128507494926453, 5.844939643990589],
          [118.1282764, 5.8447687],
          [118.1263718, 5.8429421],
          [118.125394, 5.842076],
          [118.1245356, 5.8414331],
          [118.1237943, 5.8409862],
          [118.1234264, 5.8413764],
          [118.1229764, 5.8412453],
          [118.1217832, 5.8401389],
          [118.1213077, 5.8397823],
          [118.121704, 5.8390714],
          [118.1218027, 5.8389123],
          [118.1218609, 5.8388161],
          [118.1218367, 5.8387057],
          [118.1218093, 5.8386434],
          [118.1217508, 5.8385894],
          [118.1216763, 5.8385472],
          [118.1215983, 5.8385319],
          [118.1214863, 5.8385582],
          [118.1214105, 5.8386063],
          [118.1212918, 5.8387702],
          [118.1198175, 5.8379317],
          [118.1196078, 5.8383102],
          [118.1192684, 5.83824],
          [118.118988, 5.83827],
          [118.1171223, 5.8374323],
          [118.1164992, 5.8371566],
          [118.1165736, 5.8369533],
          [118.1171539, 5.8372428],
          [118.1172244, 5.8370427],
          [118.1157919, 5.8364336],
          [118.1155098, 5.8367822],
          [118.1151389, 5.8366954],
          [118.1143312, 5.836668],
          [118.1142853, 5.8367047],
          [118.1125458, 5.8367233],
          [118.1121299, 5.8366175],
          [118.111907, 5.8367062],
          [118.1118216, 5.8370122],
          [118.1108139, 5.8370736],
          [118.1104557, 5.8367986],
          [118.1101538, 5.8368206],
          [118.1101478, 5.8371246],
          [118.1074985, 5.8368649],
          [118.107519, 5.8359718],
          [118.1070227, 5.8359933],
          [118.106972, 5.8366331],
          [118.1068418, 5.8365882],
          [118.1059282, 5.8363846],
          [118.1049139, 5.835924],
          [118.1048912, 5.83442],
          [118.1014395, 5.8341126],
          [118.0992122, 5.8336302],
          [118.0980988, 5.8339552],
          [118.0970244, 5.8333086],
          [118.0960855, 5.8343174],
          [118.0939374, 5.8337489],
          [118.094214, 5.8329049],
          [118.0934037, 5.8309742],
          [118.0926923, 5.8307137],
          [118.0916526, 5.8310088],
          [118.0907099, 5.8303237],
          [118.0904006, 5.8306097],
          [118.0889139, 5.8294439],
          [118.0879894, 5.8301104],
          [118.0857416, 5.8286247],
          [118.0846371, 5.8277572],
          [118.0845483, 5.8276968],
          [118.0844875, 5.8277014],
          [118.0844361, 5.8277165],
          [118.0842243, 5.8276223],
          [118.0840085, 5.8274072],
          [118.083857, 5.8271707],
          [118.0837257, 5.8269218],
          [118.0832298, 5.8255283],
          [118.083038, 5.8241791],
          [118.083259, 5.8235794],
          [118.083319, 5.8227343],
          [118.0834112, 5.8220522],
          [118.0834774, 5.8215511],
          [118.0836133, 5.8213061],
          [118.0836974, 5.8208402],
          [118.0836528, 5.8206357],
          [118.0835466, 5.8204346],
          [118.0833362, 5.8191506],
          [118.0836516, 5.817938],
          [118.0841831, 5.8179694],
          [118.0841753, 5.8170525],
          [118.0833654, 5.8159377],
          [118.0827797, 5.8160563],
          [118.080936, 5.8138784],
          [118.0791066, 5.8116921],
          [118.080369805582677, 5.810754373669624],
          [118.0805228, 5.8106408],
          [118.0795894, 5.8093546],
          [118.0792515, 5.80891],
          [118.0779893, 5.8082537],
          [118.077926, 5.8082208],
          [118.0771368, 5.8081346],
          [118.0770875, 5.8075849],
          [118.0763221, 5.8074193],
          [118.0761412, 5.8075942],
          [118.076079, 5.8076376],
          [118.075991, 5.8076565],
          [118.0758547, 5.80767],
          [118.0756841, 5.8076597],
          [118.0755821, 5.8079352],
          [118.0753473, 5.8079799],
          [118.0752224, 5.8079926],
          [118.0751147, 5.8079754],
          [118.0749638, 5.8078875],
          [118.0748176, 5.8077597],
          [118.0741343, 5.807566],
          [118.0740598, 5.8075669],
          [118.0731276, 5.8072647],
          [118.0720958, 5.8072254],
          [118.0718417, 5.8072581],
          [118.0695456, 5.8065517],
          [118.0697078, 5.8079249],
          [118.0692186, 5.807952],
          [118.0691672, 5.8076667],
          [118.0687134, 5.8077549],
          [118.0682876, 5.8078732],
          [118.0644145, 5.8051469],
          [118.0628591, 5.8061325],
          [118.0627623, 5.807334],
          [118.0565096, 5.8072587],
          [118.0568835, 5.8054685],
          [118.0543966, 5.8053558],
          [118.0529127, 5.8038619],
          [118.0533603, 5.800352],
          [118.05302, 5.8001025],
          [118.0525077, 5.8009569],
          [118.0522542, 5.8032053],
          [118.0503107, 5.8023352],
          [118.0496494, 5.8028693],
          [118.0494955, 5.8026603],
          [118.0493366, 5.8024445],
          [118.0486483, 5.8003392],
          [118.0460887, 5.7983856],
          [118.0451565, 5.7973361],
          [118.0433978, 5.7966136],
          [118.0417907, 5.7971031],
          [118.0393659, 5.7954696],
          [118.0387761, 5.7933727],
          [118.0376054, 5.7927571],
          [118.0364548, 5.7941152],
          [118.0329793, 5.7914462],
          [118.032468, 5.7908671],
          [118.0309375, 5.7903127],
          [118.0282845, 5.78903],
          [118.0270862, 5.789944],
          [118.0261675, 5.789944],
          [118.0258872, 5.7903717],
          [118.0256995, 5.7910009],
          [118.0256493, 5.7915394],
          [118.0256378, 5.7918525],
          [118.0255646, 5.79211],
          [118.0254715, 5.7923867],
          [118.0252228, 5.7930349],
          [118.0247261, 5.7932298],
          [118.0237675, 5.7922761],
          [118.0219701, 5.7909648],
          [118.0185501, 5.789791],
          [118.0178645, 5.7919851],
          [118.0173666, 5.7924964],
          [118.0166146, 5.794778],
          [118.0111209, 5.7982363],
          [118.0084757, 5.7999628],
          [118.0079296, 5.8011439],
          [118.007352, 5.8026482],
          [118.0072612, 5.8030976],
          [118.007226, 5.803938],
          [118.0073975, 5.804401],
          [118.0074457, 5.8057771],
          [118.0079462, 5.8093711],
          [118.00802963439601, 5.810754373669624],
          [118.0081748, 5.8131611],
          [118.0080768, 5.8132584],
          [118.0080267, 5.8134724],
          [118.0080763, 5.8136346],
          [118.008219, 5.8137277],
          [118.0082447, 5.8141392],
          [118.0081219, 5.8142016],
          [118.0080492, 5.8142924],
          [118.0080205, 5.8144175],
          [118.0080269, 5.8146179],
          [118.0080639, 5.8147545],
          [118.0081503, 5.8148591],
          [118.0082933, 5.8149237],
          [118.0083217, 5.8155062],
          [118.0081162, 5.8156663],
          [118.0080932, 5.8158256],
          [118.0079832, 5.8157928],
          [118.0079017, 5.8158425],
          [118.0078926, 5.8159668],
          [118.0079478, 5.8160495],
          [118.0079993, 5.8161037],
          [118.0080725, 5.8162785],
          [118.008087, 5.81658],
          [118.0081884, 5.8168586],
          [118.0084633, 5.8170779],
          [118.0085945, 5.8182926],
          [118.0084939, 5.8193068],
          [118.0083005, 5.820833],
          [118.0081965, 5.821776],
          [118.0079134, 5.8217253],
          [118.0078512, 5.8220679],
          [118.0079566, 5.8239512],
          [118.0078445, 5.8250132],
          [118.0080269, 5.8260026],
          [118.0083906, 5.8265337],
          [118.009243, 5.8283695],
          [118.0101678, 5.8296145],
          [118.0132366, 5.8332078],
          [118.014957, 5.8352091],
          [118.0159421, 5.8360234],
          [118.0162612, 5.8364789],
          [118.0157895, 5.8372242],
          [118.015845, 5.838356],
          [118.0163583, 5.8387149],
          [118.017163, 5.83975],
          [118.0180371, 5.8422758],
          [118.0185088, 5.8439321],
          [118.0176708, 5.8451276],
          [118.0173189, 5.8458971],
          [118.0164596, 5.8478929],
          [118.0153853, 5.8491848],
          [118.0151729, 5.8498406],
          [118.0152753, 5.8500258],
          [118.0150931, 5.8501285],
          [118.0146332, 5.851029],
          [118.0145895, 5.8514207],
          [118.0146946, 5.8519074],
          [118.0150117, 5.8530852],
          [118.0154872, 5.8543192],
          [118.0161986, 5.8555995],
          [118.0175343, 5.8575196],
          [118.0171963, 5.8578835],
          [118.0164239, 5.8570083],
          [118.0153832, 5.8555461],
          [118.014498, 5.8542403],
          [118.0140598, 5.8536479],
          [118.0139578, 5.8535604],
          [118.0132787, 5.853435],
          [118.0120524, 5.8527546],
          [118.0127168, 5.8520262],
          [118.0130525, 5.8512702],
          [118.0141722, 5.8487489],
          [118.0148534, 5.8476528],
          [118.0164231, 5.8460604],
          [118.0168222, 5.8453122],
          [118.0166602, 5.843676],
          [118.0154606, 5.8396485],
          [118.0150166, 5.8384892],
          [118.0143506, 5.8369433],
          [118.0125192, 5.8355631],
          [118.0117978, 5.8344037],
          [118.009685, 5.8327162],
          [118.0085724, 5.8309764],
          [118.0075543, 5.8292233],
          [118.0062003, 5.825446],
          [118.0063036, 5.8235274],
          [118.0062481, 5.8200492],
          [118.0055187, 5.8180356],
          [118.0055723, 5.8168326],
          [118.0049286, 5.815286],
          [118.004582, 5.8147492],
          [118.003168, 5.8140778],
          [118.0012625, 5.8127297],
          [118.0006167, 5.8117723],
          [118.0005716, 5.8107882],
          [118.000510627746095, 5.810754373669624],
          [117.9998962, 5.8104135],
          [117.999497687452674, 5.810493576077319],
          [117.9992348, 5.8105464],
          [117.9987729, 5.8104167],
          [117.9984038, 5.8101589],
          [117.9988239, 5.8101013],
          [117.9988474, 5.8096595],
          [117.9986892, 5.8095457],
          [117.9986077, 5.8093106],
          [117.9960049, 5.8070828],
          [117.9917911, 5.8049704],
          [117.99137, 5.8052581],
          [117.9907703, 5.8051508],
          [117.9906287, 5.8048274],
          [117.9899613, 5.804584],
          [117.977662, 5.805485],
          [117.9701089, 5.8032649],
          [117.965561866760254, 5.807336207204367],
          [117.964959, 5.807876],
          [117.9567193, 5.8036064],
          [117.9627274, 5.7962628],
          [117.9553391, 5.7928869],
          [117.9429486, 5.7928185],
          [117.933429, 5.7950952],
          [117.925374, 5.7983736],
          [117.916312, 5.8000128],
          [117.9133829, 5.7983736],
          [117.9118268, 5.796188],
          [117.9102707, 5.7951862],
          [117.9122845, 5.7946398],
          [117.907067, 5.7924542],
          [117.8998994, 5.7930179],
          [117.8993844, 5.7855033],
          [117.9059075, 5.7814044],
          [117.9126023, 5.7725233],
          [117.9232453, 5.7699615],
          [117.9204987, 5.7682536],
          [117.9088258, 5.762105],
          [117.9064225, 5.7586891],
          [117.911229, 5.7586891],
          [117.9136323, 5.756298],
          [117.911229, 5.7551024],
          [117.9110574, 5.7510033],
          [117.9053925, 5.7469041],
          [117.9065942, 5.7450253],
          [117.9196404, 5.7486121],
          [117.9249619, 5.7443422],
          [117.9095124, 5.7421218],
          [117.9114007, 5.7388765],
          [117.9225587, 5.7393889],
          [117.9476212, 5.7327277],
          [117.9477929, 5.7197465],
          [117.9405831, 5.7195757],
          [117.9307984, 5.7158179],
          [117.9385232, 5.7146223],
          [117.9393815, 5.7118893],
          [117.9397248, 5.692246],
          [117.9546594, 5.6908794],
          [117.9546594, 5.6883172],
          [117.9525094, 5.6882367],
          [117.9460763, 5.6797763],
          [117.9479646, 5.6726019],
          [117.9525994, 5.6794347],
          [117.965561866760254, 5.679845106892673],
          [117.9903649, 5.6806304],
          [117.999497687452674, 5.686426605392077],
          [118.0121659, 5.6944666],
          [118.0221223, 5.6954914],
          [118.0331086, 5.7098396],
          [118.0592011, 5.7206005],
          [118.0674409, 5.7200881],
          [118.0652954, 5.7079526],
          [118.0660676, 5.7030072],
          [118.0727624, 5.7026656],
          [118.0746507, 5.6997618],
          [118.0803155, 5.6973704],
          [118.0943917, 5.7016407],
          [118.0950784, 5.7067651],
          [118.0988549, 5.7105229],
          [118.1048631, 5.7083023],
          [118.1132745, 5.7062526],
          [118.1149911, 5.6980536],
          [118.1172227, 5.6901962],
          [118.1197976, 5.6736268],
          [118.1234025, 5.6662815],
          [118.129239, 5.6645733],
          [118.1252908, 5.6748226],
          [118.1373071, 5.6814845],
          [118.1470918, 5.6852425],
          [118.1486367, 5.6910503],
          [118.1467484, 5.699591],
          [118.1355905, 5.7081315],
          [118.1273507, 5.7269203],
          [118.1347321, 5.7380225],
          [118.1361054, 5.7407554],
          [118.140912, 5.741097],
          [118.1450318, 5.7388765],
          [118.1525849, 5.7416094],
          [118.1615113, 5.7474165],
          [118.1685494, 5.7458793],
          [118.1726693, 5.7443422],
          [118.1858872, 5.7463917],
          [118.1735276, 5.7469041],
          [118.1673478, 5.7482705],
          [118.1649445, 5.7506617],
          [118.1549882, 5.7668872],
          [118.1625413, 5.7749144],
          [118.1685494, 5.7791842],
          [118.1594514, 5.778501],
          [118.1470918, 5.7950673],
          [118.1522886, 5.8023078],
          [118.1538991, 5.8020789],
          [118.1565988, 5.8047981],
          [118.1597491, 5.8044658],
          [118.1626742, 5.8053486],
          [118.166158, 5.8069835],
          [118.1759784, 5.8085118],
          [118.1765864, 5.8082792],
          [118.1772215, 5.8081818],
          [118.1781556, 5.8082737],
          [118.1784102, 5.808582],
          [118.1789037, 5.8089339],
          [118.1793293, 5.8089884],
          [118.1797727, 5.8091784],
          [118.1802517, 5.8091258],
          [118.1805688, 5.8090183],
          [118.1807499, 5.8086945],
          [118.1807589, 5.8079387],
          [118.1808222, 5.8073857],
          [118.1812389, 5.8071843],
          [118.1817477, 5.8071333],
          [118.1822565, 5.8073258],
          [118.1831246, 5.807278],
          [118.183462, 5.8073023],
          [118.1837995, 5.8075158],
          [118.1843656, 5.8079971],
          [118.1852529, 5.8099589],
          [118.1864361, 5.8124438],
          [118.1871227, 5.812931],
          [118.1878776, 5.8132484],
          [118.1883958, 5.8134681],
          [118.1890165, 5.8136199],
          [118.1898822, 5.8136516],
          [118.1901424, 5.8137406],
          [118.1912395, 5.8138636],
          [118.1915209, 5.8147552],
          [118.1918473, 5.8152406],
          [118.1922763, 5.8156579],
          [118.1933964, 5.8163686],
          [118.1939792, 5.816577],
          [118.1943819, 5.816497],
          [118.1946479, 5.8161451],
          [118.1955153, 5.8164902],
          [118.1960786, 5.8167144],
          [118.1982731, 5.8173862],
          [118.1996798, 5.8170503],
          [118.2017617, 5.8164905],
          [118.2082887, 5.8163226],
          [118.2113272, 5.8169383],
          [118.2167289, 5.8190095],
          [118.2220743, 5.8224241],
          [118.2245501, 5.8241594],
          [118.2278699, 5.8274061],
          [118.2339126, 5.8349403],
          [118.235406, 5.8396142],
          [118.2367535, 5.8413143],
          [118.2381667, 5.8419682],
          [118.2431294, 5.8402354],
          [118.2480593, 5.8378159],
          [118.2507871, 5.8365408],
          [118.2537779, 5.8353638],
          [118.2553226, 5.8334348],
          [118.2569659, 5.8346445],
          [118.2616, 5.8326828],
          [118.2659054, 5.8305575],
          [118.2700013, 5.8278569],
          [118.2711639, 5.8271245],
          [118.2723682, 5.8253021],
          [118.2729386, 5.8214354],
          [118.2725771, 5.8178715],
          [118.272117, 5.8164328],
          [118.2729527, 5.8146093],
          [118.2762472, 5.8164951],
          [118.2766525, 5.8167271],
          [118.2759623, 5.8194082],
          [118.2775544, 5.8203428],
          [118.2801037, 5.8216423],
          [118.2823711, 5.8222201],
          [118.2848689, 5.8218277],
          [118.289733, 5.8212719],
          [118.2944036, 5.8206134],
          [118.3027341, 5.8194554],
          [118.3098268, 5.8184642],
          [118.3116596, 5.8185416],
          [118.3134241, 5.820081],
          [118.3153551, 5.8205779],
          [118.3186635, 5.8191087],
          [118.3209438, 5.8187847],
          [118.3230342, 5.8170562],
          [118.3258032, 5.8173533],
          [118.3276795, 5.8164149],
          [118.3293476, 5.8153748],
          [118.3305848, 5.8131272],
          [118.3318151, 5.8094447],
          [118.3318726, 5.8085842],
          [118.3317004, 5.8079956],
          [118.3312056, 5.807514],
          [118.330829, 5.8071875],
          [118.3295937, 5.8063399],
          [118.3286936, 5.8059353],
          [118.3276178, 5.8055981],
          [118.3255146, 5.8051807],
          [118.3233122, 5.8047836],
          [118.3177906, 5.8043128],
          [118.3124401, 5.8031571],
          [118.310977, 5.802969],
          [118.3089953, 5.8023823],
          [118.3080321, 5.8017733],
          [118.3070357, 5.8015076],
          [118.3080802, 5.7984967],
          [118.3085089, 5.7987083],
          [118.3090817, 5.7987718],
          [118.30944, 5.7986971],
          [118.3104921, 5.7988566],
          [118.3120052, 5.7988561],
          [118.3141128, 5.7987415],
          [118.3150978, 5.7988217],
          [118.3160485, 5.7992455],
          [118.3182592, 5.7998869],
          [118.320172, 5.8004138],
          [118.3212105, 5.800856],
          [118.3227818, 5.8016174],
          [118.324264, 5.8020223],
          [118.3253088, 5.8021033],
          [118.3263293, 5.8018685],
          [118.3265617, 5.8018669],
          [118.3271786, 5.8018626],
          [118.3275037, 5.8018604],
          [118.3290831, 5.8019332],
          [118.3297634, 5.8020466],
          [118.330792, 5.8025893],
          [118.3310026, 5.8021762],
          [118.331197, 5.8002972],
          [118.3316424, 5.7995116],
          [118.3320555, 5.7990418],
          [118.3321932, 5.7977378],
          [118.3313428, 5.7966849],
          [118.3317639, 5.7966282],
          [118.3325334, 5.7972357],
          [118.3329059, 5.7974786],
          [118.3338779, 5.7979889],
          [118.3345987, 5.7981833],
          [118.3352628, 5.7975596],
          [118.3366705, 5.7950461],
          [118.3370946, 5.7917228],
          [118.3394276, 5.7890325],
          [118.3409122, 5.7858147],
          [118.341124, 5.7857163],
          [118.3438279, 5.7874982],
          [118.3459652, 5.7889067],
          [118.3460629, 5.7889207],
          [118.3461391, 5.7889881],
          [118.3462281, 5.7890176],
          [118.3464398, 5.7889628],
          [118.3465457, 5.7889375],
          [118.3467066, 5.7888701],
          [118.3467913, 5.7889291],
          [118.3469522, 5.7890386],
          [118.3470666, 5.7889923],
          [118.3471386, 5.7890555],
          [118.3472444, 5.7891397],
          [118.3473715, 5.7890808],
          [118.3475451, 5.7890176],
          [118.3476679, 5.7890765],
          [118.3478077, 5.7891608],
          [118.3480596, 5.78916],
          [118.3482573, 5.7893447],
          [118.3483172, 5.7894937],
          [118.3484429, 5.7894818],
          [118.3485747, 5.7893566],
          [118.3486552, 5.7892474],
          [118.3489053, 5.78913],
          [118.3492109, 5.7889906],
          [118.3497739, 5.7889548],
          [118.3504566, 5.7890621],
          [118.3509957, 5.7891812],
          [118.3512711, 5.7894077],
          [118.351379, 5.7900154],
          [118.3508759, 5.7906828],
          [118.3505764, 5.7910641],
          [118.3506004, 5.7916004],
          [118.3511154, 5.7923273],
          [118.3515107, 5.793233],
          [118.3519659, 5.7937812],
          [118.3520976, 5.7943889],
          [118.3519539, 5.7948418],
          [118.3521216, 5.7953065],
          [118.352469, 5.7958547],
          [118.3526486, 5.7963314],
          [118.3526965, 5.7968796],
          [118.3529121, 5.7974396],
          [118.3531996, 5.7977614],
          [118.3535567, 5.7977504],
          [118.3523777, 5.8018789],
          [118.3523184, 5.8018789],
          [118.352166, 5.8020559],
          [118.3520177, 5.8022623],
          [118.3516874, 5.8024435],
          [118.3513952, 5.8026415],
          [118.3510861, 5.8028395],
          [118.3507176, 5.8030249],
          [118.3506202, 5.8031007],
          [118.3504, 5.8031597],
          [118.3501036, 5.8031513],
          [118.3498749, 5.8031681],
          [118.3494726, 5.803324],
          [118.349066, 5.8035515],
          [118.3485578, 5.803817],
          [118.3484181, 5.8039434],
          [118.3484012, 5.804074],
          [118.3483037, 5.8041456],
          [118.3481725, 5.8043015],
          [118.3480581, 5.8044995],
          [118.3479946, 5.8045374],
          [118.3479692, 5.8043605],
          [118.3478506, 5.8042931],
          [118.3477405, 5.8043858],
          [118.3475415, 5.8045922],
          [118.3474525, 5.8048029],
          [118.3473975, 5.8048745],
          [118.3472535, 5.8048871],
          [118.34719, 5.805043],
          [118.3471392, 5.8053042],
          [118.3469316, 5.8056876],
          [118.3468004, 5.8058983],
          [118.3466437, 5.80613],
          [118.3465886, 5.806328],
          [118.3464446, 5.8065387],
          [118.3462879, 5.8067662],
          [118.3462879, 5.8068926],
          [118.3463176, 5.8072886],
          [118.3463345, 5.8076552],
          [118.3462625, 5.8080849],
          [118.3463049, 5.8082071],
          [118.3464023, 5.8083293],
          [118.3464939, 5.8084406],
          [118.3463877, 5.8084348],
          [118.3463368, 5.8085272],
          [118.3465075, 5.8087596],
          [118.3464655, 5.8088638],
          [118.3465494, 5.8089502],
          [118.3466272, 5.8094567],
          [118.3468099, 5.8095609],
          [118.346747, 5.8096444],
          [118.34678, 5.8099035],
          [118.3469447, 5.8100078],
          [118.3469207, 5.8100793],
          [118.3468698, 5.8100793],
          [118.3468279, 5.810118],
          [118.3468818, 5.8102134],
          [118.3469926, 5.8103147],
          [118.3469514, 5.8107797],
          [118.3468861, 5.8110487],
          [118.3467244, 5.8112334],
          [118.3465806, 5.8114121],
          [118.3465148, 5.8116326],
          [118.346359, 5.811865],
          [118.3461734, 5.8121033],
          [118.3460237, 5.8122701],
          [118.3459758, 5.8125025],
          [118.3459159, 5.8127944],
          [118.3456943, 5.8131937],
          [118.3455745, 5.813575],
          [118.3454966, 5.8137835],
          [118.3454846, 5.8139682],
          [118.3453289, 5.814856],
          [118.3450295, 5.814993],
          [118.3450175, 5.8152492],
          [118.3449337, 5.8154042],
          [118.3450355, 5.8158272],
          [118.3451493, 5.816423],
          [118.3451792, 5.816995],
          [118.3451792, 5.8177457],
          [118.3451133, 5.8180973],
          [118.3451373, 5.8187467],
          [118.3451972, 5.8191995],
          [118.3452271, 5.8194498],
          [118.3453349, 5.8199443],
          [118.3454188, 5.8210525],
          [118.3454008, 5.8217377],
          [118.3454846, 5.8223633],
          [118.3456583, 5.8240614],
          [118.3458979, 5.8249551],
          [118.3462333, 5.8256522],
          [118.3465567, 5.8262182],
          [118.3468382, 5.8264327],
          [118.3471436, 5.8267425],
          [118.3473053, 5.8269034],
          [118.3474371, 5.8271298],
          [118.3474371, 5.8272788],
          [118.3475089, 5.8274754],
          [118.3476587, 5.8276541],
          [118.3480353, 5.8277993],
          [118.3517121, 5.8271479],
          [118.3571061, 5.8231592],
          [118.3640978, 5.8164106],
          [118.3758339, 5.8071776],
          [118.3860444, 5.7998492],
          [118.3876962, 5.7983123],
          [118.3877324, 5.7979668],
          [118.3878192, 5.7974916],
          [118.3879639, 5.7969156],
          [118.3880363, 5.7964189],
          [118.3882389, 5.7952814],
          [118.3883619, 5.794691],
          [118.3883764, 5.7942374],
          [118.3883764, 5.7936471],
          [118.3896283, 5.7932151],
          [118.3900915, 5.7937335],
          [118.3907934, 5.7943022],
          [118.3914809, 5.794367],
          [118.3932176, 5.7941078],
          [118.3938689, 5.7939926],
          [118.3947283, 5.7935969],
          [118.4002218, 5.7906572],
          [118.4065637, 5.7871018],
          [118.4084817, 5.7859929],
          [118.4093617, 5.7851235],
          [118.4096221, 5.7847145],
          [118.40979, 5.7843493],
          [118.4097956, 5.7835849],
          [118.4135593, 5.7829789],
          [118.4143233, 5.7829697],
          [118.4150029, 5.7828118],
          [118.4172149, 5.7819364],
          [118.4219877, 5.7798503],
          [118.4293031, 5.7759392],
          [118.4365122, 5.7723972],
          [118.4402894, 5.7689367],
          [118.4404611, 5.7636422],
          [118.441215, 5.762708],
          [118.4420474, 5.7652338],
          [118.4437226, 5.7658625],
          [118.4456265, 5.7668073],
          [118.4476708, 5.7656917],
          [118.4571961, 5.7613002],
          [118.4593438, 5.7590307],
          [118.459684, 5.7567742],
          [118.4598588, 5.7556148],
          [118.4616492, 5.7576563],
          [118.4635643, 5.7575907],
          [118.4663936, 5.756911],
          [118.4698062, 5.7547164],
          [118.4761666, 5.7503201],
          [118.4802866, 5.7454785],
          [118.4805464, 5.7449609],
          [118.480533, 5.7445146],
          [118.4805732, 5.7442815],
          [118.4804794, 5.7432559],
          [118.480512, 5.7425398],
          [118.4806315, 5.741688],
          [118.4810811, 5.7401433],
          [118.4811332, 5.7399314],
          [118.4811994, 5.7393992],
          [118.4811739, 5.7389787],
          [118.4811213, 5.7390272],
          [118.4810881, 5.7390979],
          [118.4810124, 5.7392109],
          [118.4808373, 5.7393663],
          [118.4806479, 5.7395735],
          [118.480538, 5.7398308],
          [118.4803934, 5.740305],
          [118.480029, 5.7399518],
          [118.4803461, 5.739575],
          [118.4805165, 5.7391747],
          [118.4804692, 5.738845],
          [118.4802829, 5.7381056],
          [118.4805266, 5.7378331],
          [118.480704, 5.7374801],
          [118.4807113, 5.7372797],
          [118.4806786, 5.7369143],
          [118.4807663, 5.7367817],
          [118.4809114, 5.7367689],
          [118.4813264, 5.7368089],
          [118.4815941, 5.7358631],
          [118.4821698, 5.7355568],
          [118.4833578, 5.7352776],
          [118.4856487, 5.7361773],
          [118.4857765, 5.7366011],
          [118.4859996, 5.7369316],
          [118.4865176, 5.737301],
          [118.4870664, 5.7374608],
          [118.487622, 5.7375407],
          [118.4886994, 5.7375964],
          [118.4895565, 5.7374542],
          [118.4909489, 5.7370079],
          [118.4929035, 5.7359889],
          [118.4976067, 5.7330597],
          [118.5024344, 5.7305337],
          [118.5222027, 5.7173239],
          [118.5531661, 5.6946305],
          [118.5579297, 5.6899458],
          [118.5587275, 5.688817],
          [118.5591256, 5.687944],
          [118.5593711, 5.6870452],
          [118.5593887, 5.6861639],
          [118.5589223, 5.6846462],
          [118.5604801, 5.6837347],
          [118.562512, 5.6825459],
          [118.5670934, 5.684849],
          [118.5691663, 5.6850463],
          [118.5726261, 5.6848648],
          [118.5737952, 5.6846533],
          [118.5749001, 5.6842956],
          [118.5766634, 5.6832899],
          [118.5779676, 5.6824143],
          [118.5788578, 5.6812566],
          [118.5794673, 5.6800515],
          [118.5806856, 5.678108],
          [118.5858278, 5.6724165],
          [118.5912347, 5.6669003],
          [118.6010808, 5.6549449],
          [118.6054443, 5.6470646],
          [118.6059032, 5.646102],
          [118.6058306, 5.6456087],
          [118.6053049, 5.6448642],
          [118.5921544, 5.6361537],
          [118.591283127587104, 5.635510325431824],
          [118.5841416, 5.6302368],
          [118.5757302, 5.6278452],
          [118.571689, 5.6285215],
          [118.5710483, 5.6273559],
          [118.5704025, 5.626181],
          [118.5683488, 5.6080281],
          [118.5736703, 5.5943607],
          [118.5734053, 5.5906283],
          [118.5722118, 5.5866847],
          [118.5718139, 5.5843913],
          [118.5712375, 5.5839462],
          [118.5708138, 5.5830383],
          [118.5706685, 5.582203],
          [118.570269, 5.5811982],
          [118.5698938, 5.5808229],
          [118.5695185, 5.5801692],
          [118.5692158, 5.5798787],
          [118.5688527, 5.5792855],
          [118.5684653, 5.5787286],
          [118.5679931, 5.5785228],
          [118.567521, 5.5784139],
          [118.5675452, 5.5781839],
          [118.5677995, 5.5778691],
          [118.5678116, 5.577518],
          [118.5673515, 5.5771064],
          [118.5669157, 5.5766706],
          [118.5666857, 5.5764769],
          [118.5669157, 5.5760169],
          [118.5666131, 5.5756295],
          [118.5663347, 5.5754964],
          [118.5661652, 5.5748911],
          [118.5659231, 5.5744432],
          [118.5656688, 5.5742132],
          [118.5649546, 5.5741042],
          [118.5650635, 5.5735473],
          [118.5653057, 5.5731357],
          [118.5648577, 5.5716588],
          [118.5642767, 5.5708356],
          [118.5638409, 5.570073],
          [118.5630055, 5.5696977],
          [118.5618259, 5.5692766],
          [118.555281, 5.5657839],
          [118.5522511, 5.5556892],
          [118.5495385, 5.5445142],
          [118.5466722, 5.5421023],
          [118.5518152, 5.5364526],
          [118.5507675, 5.533013],
          [118.5397315, 5.5337323],
          [118.5412665, 5.529263],
          [118.5411046, 5.5293689],
          [118.5409609, 5.5293039],
          [118.5410653, 5.5291404],
          [118.5411273, 5.529086],
          [118.5412529, 5.5290981],
          [118.541262, 5.5287621],
          [118.5412968, 5.5286486],
          [118.5412877, 5.5285246],
          [118.5412998, 5.5284232],
          [118.5412499, 5.528349],
          [118.5414194, 5.5283505],
          [118.5415768, 5.5283929],
          [118.5417129, 5.5285321],
          [118.5417099, 5.5286562],
          [118.5417856, 5.5287546],
          [118.5419006, 5.5287924],
          [118.5419637, 5.5286852],
          [118.542105, 5.5284498],
          [118.5421799, 5.5283363],
          [118.5423232, 5.5282015],
          [118.5423874, 5.5279469],
          [118.5423725, 5.5277735],
          [118.5424409, 5.5278506],
          [118.5424859, 5.5279982],
          [118.5425458, 5.5280967],
          [118.5426464, 5.5281587],
          [118.5428985, 5.5278741],
          [118.5431639, 5.5274974],
          [118.5436176, 5.5270181],
          [118.5439353, 5.5263813],
          [118.5441835, 5.5261271],
          [118.5443529, 5.5258668],
          [118.5444589, 5.5257095],
          [118.5445618, 5.52554],
          [118.5448069, 5.5253069],
          [118.5449855, 5.5248621],
          [118.5452488, 5.5245413],
          [118.545388, 5.5244989],
          [118.5457481, 5.5245291],
          [118.5458601, 5.5247017],
          [118.5459721, 5.525086],
          [118.5460901, 5.5254552],
          [118.5461385, 5.525885],
          [118.5461839, 5.5263087],
          [118.5462354, 5.5267324],
          [118.5463716, 5.5270623],
          [118.5466652, 5.5270532],
          [118.5469406, 5.5268111],
          [118.547446, 5.5264661],
          [118.5481542, 5.5260151],
          [118.5524815, 5.5249577],
          [118.5527083, 5.5248037],
          [118.5529865, 5.5245233],
          [118.553239, 5.5239434],
          [118.553498, 5.5234148],
          [118.5536713, 5.5231794],
          [118.5538596, 5.5228562],
          [118.5539709, 5.522563],
          [118.5541272, 5.5221158],
          [118.5546604, 5.5216838],
          [118.5549146, 5.5214295],
          [118.5552084, 5.5212055],
          [118.5552534, 5.5210579],
          [118.555202, 5.5207647],
          [118.5550244, 5.5204373],
          [118.5546306, 5.5200563],
          [118.5540678, 5.5196754],
          [118.5535007, 5.5194143],
          [118.5530556, 5.5192174],
          [118.5528908, 5.5190955],
          [118.5524029, 5.5188472],
          [118.5521632, 5.5186675],
          [118.5516303, 5.5184342],
          [118.5512087, 5.5182031],
          [118.5507144, 5.5179249],
          [118.5503741, 5.5177494],
          [118.5500958, 5.5177657],
          [118.5498047, 5.5175046],
          [118.5492269, 5.5170381],
          [118.5487219, 5.5168369],
          [118.5480028, 5.5166229],
          [118.5476989, 5.5164474],
          [118.5473779, 5.5164003],
          [118.5472752, 5.5162976],
          [118.5469114, 5.5162035],
          [118.5465818, 5.5160922],
          [118.5462266, 5.5160494],
          [118.5458628, 5.5158696],
          [118.5453877, 5.5156256],
          [118.5447371, 5.515433],
          [118.5442578, 5.515386],
          [118.5437527, 5.5153389],
          [118.5433247, 5.515279],
          [118.5428282, 5.5152404],
          [118.5423489, 5.515142],
          [118.5416469, 5.5150008],
          [118.5412318, 5.5149794],
          [118.5410734, 5.5147696],
          [118.5405256, 5.5145685],
          [118.5399905, 5.514316],
          [118.5402217, 5.5138922],
          [118.540517, 5.5140934],
          [118.5411162, 5.5143202],
          [118.5416298, 5.51447],
          [118.5424473, 5.514577],
          [118.5431535, 5.514744],
          [118.5439068, 5.5148681],
          [118.545071, 5.5149708],
          [118.5459612, 5.515065],
          [118.5463079, 5.5150607],
          [118.5465005, 5.5152961],
          [118.5468857, 5.5155015],
          [118.5476005, 5.5156984],
          [118.5483367, 5.5158311],
          [118.5491327, 5.5159895],
          [118.5494281, 5.5159852],
          [118.5497105, 5.5158354],
          [118.5501471, 5.5153817],
          [118.5498432, 5.5165715],
          [118.5500699, 5.5167856],
          [118.5503755, 5.517058],
          [118.5506343, 5.5171639],
          [118.5508704, 5.5172169],
          [118.5510569, 5.517355],
          [118.551299, 5.5174972],
          [118.5515048, 5.5175426],
          [118.551986, 5.5177242],
          [118.5523068, 5.5179482],
          [118.552558, 5.5179905],
          [118.5531693, 5.5180541],
          [118.5533751, 5.5179542],
          [118.5536112, 5.5180057],
          [118.5537383, 5.5178513],
          [118.554041, 5.5178937],
          [118.5540773, 5.5176697],
          [118.5544737, 5.5176486],
          [118.5546402, 5.5176486],
          [118.554622, 5.5177969],
          [118.5545706, 5.5180087],
          [118.554619, 5.5181479],
          [118.5548036, 5.5180965],
          [118.5552515, 5.5180481],
          [118.5556995, 5.518045],
          [118.5562926, 5.5180117],
          [118.5571935, 5.5180904],
          [118.5580227, 5.5181812],
          [118.5587188, 5.5183144],
          [118.5590215, 5.5185444],
          [118.5595844, 5.5184718],
          [118.5603289, 5.5180057],
          [118.5610492, 5.5183325],
          [118.560916, 5.5189257],
          [118.5602139, 5.5197731],
          [118.5594452, 5.5207658],
          [118.5588459, 5.5220611],
          [118.5593423, 5.5228662],
          [118.5600202, 5.5233686],
          [118.5617402, 5.5245876],
          [118.5617402, 5.5267618],
          [118.5637432, 5.5309734],
          [118.5643938, 5.5324457],
          [118.5638867, 5.544555],
          [118.5726372, 5.545527],
          [118.5691832, 5.5481309],
          [118.5846596, 5.562474],
          [118.5830696, 5.5684417],
          [118.583118, 5.5689986],
          [118.5834933, 5.569386],
          [118.5838201, 5.5699913],
          [118.5838322, 5.5710202],
          [118.5839291, 5.5718677],
          [118.5841833, 5.5724245],
          [118.5844981, 5.5734051],
          [118.584946, 5.5744825],
          [118.5854302, 5.5753178],
          [118.586076, 5.5761727],
          [118.5869024, 5.5768445],
          [118.588222, 5.5774135],
          [118.5885246, 5.5775103],
          [118.5890694, 5.5778977],
          [118.5897836, 5.578733],
          [118.5906232, 5.5788941],
          [118.5908843, 5.5788727],
          [118.5911497, 5.5788727],
          [118.5919714, 5.5788769],
          [118.5925364, 5.5789283],
          [118.5930158, 5.5789625],
          [118.5935508, 5.5788898],
          [118.594013, 5.5789112],
          [118.5940558, 5.5797116],
          [118.593401, 5.5797158],
          [118.5930757, 5.5797415],
          [118.5929216, 5.5798271],
          [118.5927204, 5.5800796],
          [118.5926819, 5.5800112],
          [118.5926819, 5.5798442],
          [118.5925835, 5.5797244],
          [118.5921469, 5.5796302],
          [118.5915991, 5.5795446],
          [118.5911068, 5.579566],
          [118.5908672, 5.5796259],
          [118.5903664, 5.5795061],
          [118.58984, 5.5795318],
          [118.5893691, 5.5794034],
          [118.589031, 5.5794419],
          [118.5888684, 5.5793392],
          [118.5884147, 5.5793563],
          [118.5882949, 5.5793691],
          [118.5876357, 5.5792493],
          [118.587349, 5.5793092],
          [118.5872334, 5.5794119],
          [118.5869723, 5.5792365],
          [118.5858295, 5.5792065],
          [118.584817, 5.5793686],
          [118.5844139, 5.5798268],
          [118.5842674, 5.5804702],
          [118.5849658, 5.5849764],
          [118.5857147, 5.592114],
          [118.5861827, 5.5945946],
          [118.5875074, 5.5954992],
          [118.5881528, 5.5959212],
          [118.5884258, 5.5977083],
          [118.5882769, 5.598031],
          [118.5874329, 5.5976007],
          [118.5867628, 5.5977],
          [118.5867166, 5.5981193],
          [118.5864566, 5.5987508],
          [118.5871599, 5.6011419],
          [118.5880617, 5.6029043],
          [118.588161, 5.6036986],
          [118.5886906, 5.6042198],
          [118.5950726, 5.6245371],
          [118.6072606, 5.6317121],
          [118.611925406648567, 5.635510325431824],
          [118.6185902, 5.640937],
          [118.6261433, 5.6436703],
          [118.6322734, 5.642736],
          [118.6395329, 5.6400828],
          [118.646731319883997, 5.635510325431824],
          [118.647601, 5.6349579],
          [118.6563557, 5.6257329],
          [118.6745518, 5.6019864],
          [118.7037343, 5.5685005],
          [118.7522478, 5.5361098],
          [118.7542668, 5.5346401],
          [118.7547566, 5.5331559],
          [118.7554586, 5.5332967],
          [118.7561607, 5.5334375],
          [118.7563638, 5.5339503],
          [118.7579566, 5.5347809],
          [118.75886, 5.5349109],
          [118.7667692, 5.5325997],
          [118.7707347, 5.5312094],
          [118.7725742, 5.5303283],
          [118.7785352, 5.5271287],
          [118.7831102, 5.5242542],
          [118.7857769, 5.5222138],
          [118.7944662, 5.5138393],
          [118.7995491, 5.5079853],
          [118.8037196, 5.5027145],
          [118.8048353, 5.5010153],
          [118.8057623, 5.4985108],
          [118.8059128, 5.4958257],
          [118.8111772, 5.4973606],
          [118.8109269, 5.4980793],
          [118.8105786, 5.5006361],
          [118.8102085, 5.502254],
          [118.8098021, 5.5032833],
          [118.8095228, 5.5038178],
          [118.8095192, 5.5048687],
          [118.8091455, 5.5049698],
          [118.8086738, 5.50638],
          [118.8087464, 5.5076585],
          [118.8090729, 5.5082056],
          [118.8093958, 5.5084331],
          [118.809726, 5.5084584],
          [118.8131618, 5.5076747],
          [118.8161477, 5.506241],
          [118.8210239, 5.5030197],
          [118.8316542, 5.4952443],
          [118.8370891, 5.4908636],
          [118.8410437, 5.4875628],
          [118.8433403, 5.4843919],
          [118.8434854, 5.4839747],
          [118.8433911, 5.4819559],
          [118.8430374, 5.4810928],
          [118.8429449, 5.4807081],
          [118.8429557, 5.4800418],
          [118.8436306, 5.4793972],
          [118.8435907, 5.4787669],
          [118.8448133, 5.4764032],
          [118.8447335, 5.4757278],
          [118.844915, 5.4757288],
          [118.8450872, 5.4757296],
          [118.846065, 5.475791],
          [118.8472823, 5.4765115],
          [118.847519, 5.4768619],
          [118.8477557, 5.4772122],
          [118.8477811, 5.4777359],
          [118.8486119, 5.4782631],
          [118.8499979, 5.4786026],
          [118.8529076, 5.4775228],
          [118.8601928, 5.4728458],
          [118.8661647, 5.4691728],
          [118.8790082, 5.4622386],
          [118.880278, 5.4618341],
          [118.8857492, 5.4591795],
          [118.8887279, 5.457984],
          [118.8896603, 5.4571461],
          [118.8990861, 5.4522054],
          [118.916472, 5.4408573],
          [118.9200892, 5.4386758],
          [118.9221064, 5.4372998],
          [118.9236447, 5.4360067],
          [118.9255821, 5.4337494],
          [118.9266633, 5.4305349],
          [118.9290107, 5.4271145],
          [118.9319422, 5.4215233],
          [118.9344377, 5.4170834],
          [118.9449076, 5.4194086],
          [118.9432093, 5.4222601],
          [118.9413753, 5.423322],
          [118.9412573, 5.4235622],
          [118.9412392, 5.4240588],
          [118.9423548, 5.4257239],
          [118.9431893, 5.4275407],
          [118.9434142, 5.4287615],
          [118.9440927, 5.4302784],
          [118.9446587, 5.4304229],
          [118.9456927, 5.4303796],
          [118.9484646, 5.4298522],
          [118.9516029, 5.4291082],
          [118.9545017, 5.4281547],
          [118.9571793, 5.4278802],
          [118.9583765, 5.4270278],
          [118.9591421, 5.4268038],
          [118.9603212, 5.4270747],
          [118.9616128, 5.4259695],
          [118.9627847, 5.4257889],
          [118.9640642, 5.4257616],
          [118.9667974, 5.4251135],
          [118.9670731, 5.4242178],
          [118.9687819, 5.4227875],
          [118.9713978, 5.4228958],
          [118.9754107, 5.4206107],
          [118.9768744, 5.4195837],
          [118.9780717, 5.4195079],
          [118.9781225, 5.4188018],
          [118.9791365, 5.4184171],
          [118.9794866, 5.4179584],
          [118.9808762, 5.4173335],
          [118.9819411, 5.4173877],
          [118.9829225, 5.4172504],
          [118.9841143, 5.417348],
          [118.9847546, 5.4168441],
          [118.9856943, 5.4166418],
          [118.9881814, 5.4155276],
          [118.9886168, 5.4154517],
          [118.9897886, 5.414742],
          [118.9906285, 5.4144964],
          [118.9912653, 5.4135808],
          [118.9921396, 5.4132773],
          [118.9942004, 5.4125459],
          [118.9950621, 5.4123762],
          [118.9960435, 5.4121053],
          [118.9972879, 5.4115057],
          [118.9975092, 5.4113666],
          [118.9982766, 5.4113703],
          [118.9985705, 5.4110957],
          [118.9991745, 5.4107869],
          [119.000502312547326, 5.410275049216382],
          [119.000502312547326, 5.410275049216382],
          [119.0010249, 5.4100736],
          [119.0015002, 5.409949],
          [119.0019355, 5.4100681],
          [119.0021822, 5.4093403],
          [119.0032562, 5.4088094],
          [119.0034521, 5.4088527],
          [119.0037387, 5.4086649],
          [119.0041124, 5.4087444],
          [119.0044571, 5.4085385],
          [119.0046766, 5.4086143],
          [119.0060389, 5.4078396],
          [119.0075119, 5.4068445],
          [119.0089196, 5.4067759],
          [119.0095926, 5.4069745],
          [119.0097632, 5.4078071],
          [119.0099446, 5.4078215],
          [119.0102457, 5.4072418],
          [119.0102566, 5.4067126],
          [119.0112561, 5.4056435],
          [119.0134021, 5.4048976],
          [119.0138937, 5.4049518],
          [119.0143146, 5.4053094],
          [119.0145522, 5.4048055],
          [119.014955, 5.4046502],
          [119.01553, 5.404838],
          [119.0159219, 5.4052787],
          [119.0161287, 5.4052498],
          [119.0161686, 5.4056056],
          [119.0166602, 5.4061329],
          [119.0170974, 5.4061203],
          [119.0173259, 5.4053148],
          [119.0176234, 5.4051884],
          [119.0179518, 5.4054304],
          [119.0180751, 5.4050078],
          [119.0184216, 5.40508],
          [119.0187481, 5.4045978],
          [119.0209123, 5.4035937],
          [119.0214982, 5.4035359],
          [119.0221368, 5.40387],
          [119.0225595, 5.4038447],
          [119.022652, 5.4034077],
          [119.0230638, 5.4033029],
          [119.0234484, 5.4035883],
          [119.0237513, 5.4032506],
          [119.0242629, 5.4033499],
          [119.0245295, 5.402828],
          [119.0264288, 5.4021706],
          [119.0270438, 5.4022862],
          [119.0276479, 5.4023566],
          [119.0306429, 5.4018852],
          [119.0311871, 5.4020135],
          [119.0322846, 5.4017805],
          [119.032798, 5.4021209],
          [119.0329104, 5.4021814],
          [119.0330465, 5.4020812],
          [119.0333077, 5.4019755],
          [119.0339481, 5.4019683],
          [119.0357204, 5.4017913],
          [119.0366692, 5.4017299],
          [119.0373549, 5.4015728],
          [119.0377122, 5.4015873],
          [119.0381984, 5.4014319],
          [119.0395227, 5.4013398],
          [119.043383, 5.4006337],
          [119.0445439, 5.4005344],
          [119.0469421, 5.4004657],
          [119.047265, 5.4005344],
          [119.0497811, 5.4003104],
          [119.05188, 5.4002093],
          [119.0521484, 5.3999799],
          [119.052377, 5.4001298],
          [119.0561684, 5.4000413],
          [119.0565711, 5.4001479],
          [119.0589239, 5.4000594],
          [119.0644568, 5.4002075],
          [119.0649937, 5.40037],
          [119.0659389, 5.4004531],
          [119.0661928, 5.4005976],
          [119.066806, 5.4005994],
          [119.0670563, 5.4003231],
          [119.0673175, 5.4007673],
          [119.0676241, 5.4009118],
          [119.0681756, 5.4010003],
          [119.0688921, 5.4010111],
          [119.0691642, 5.4009082],
          [119.0697212, 5.4012748],
          [119.0710853, 5.4015204],
          [119.0718708, 5.4014482],
          [119.072132, 5.4013073],
          [119.0722554, 5.4017552],
          [119.0726146, 5.4019033],
          [119.077117, 5.4024],
          [119.0790436, 5.4026871],
          [119.0814381, 5.4032415],
          [119.0843352, 5.4041572],
          [119.0853565, 5.4044281],
          [119.0942526, 5.4079425],
          [119.0996301, 5.4111306],
          [119.1054219, 5.4205248],
          [119.1043201, 5.423916],
          [119.1052502, 5.4273606],
          [119.1092564, 5.4353369],
          [119.1095944, 5.4356963],
          [119.1123084, 5.4360647],
          [119.114826, 5.4365264],
          [119.1177411, 5.4369221],
          [119.1207887, 5.4381093],
          [119.1257577, 5.4384391],
          [119.1306603, 5.4393625],
          [119.1338405, 5.4398901],
          [119.1374181, 5.441473],
          [119.1378156, 5.4430559],
          [119.140267, 5.4455621],
          [119.1433809, 5.4481343],
          [119.146296, 5.4476726],
          [119.1471573, 5.4494534],
          [119.1518258, 5.4511763],
          [119.194679, 5.4333874],
          [119.2434513, 5.3863496],
          [119.2556809, 5.3576949],
          [119.2610818, 5.3261494],
          [119.2619784, 5.3253524],
          [119.2621384, 5.3244279],
          [119.2620744, 5.3228657],
          [119.2621705, 5.3215905],
          [119.2619784, 5.3203472],
          [119.2622985, 5.3189763],
          [119.2623306, 5.3160752],
          [119.2634192, 5.3149594],
          [119.2645078, 5.3135566],
          [119.2645775, 5.3123184],
          [119.2642039, 5.3101156],
          [119.2630173, 5.3073296],
          [119.2623607, 5.3045793],
          [119.261206, 5.3018064],
          [119.2609343, 5.2998001],
          [119.2612287, 5.2987856],
          [119.2562019, 5.2867977],
          [119.2579185, 5.2791057],
          [119.2488205, 5.2715846],
          [119.2469322, 5.2647472],
          [119.252082, 5.2534652],
          [119.2659866, 5.2451448],
          [119.2670441, 5.2430916],
          [119.2674617, 5.2423947],
          [119.2674067, 5.2410172],
          [119.2656515, 5.2399141],
          [119.2675785, 5.2393224],
          [119.26797, 5.2383417],
          [119.2683096, 5.2369326],
          [119.2683775, 5.2360307],
          [119.2686832, 5.2353205],
          [119.2690567, 5.2342271],
          [119.2687964, 5.2338663],
          [119.2691247, 5.2329081],
          [119.2690681, 5.2323106],
          [119.2689096, 5.2311044],
          [119.2688756, 5.2295375],
          [119.2685813, 5.2287709],
          [119.2681058, 5.2285342],
          [119.2676417, 5.2288385],
          [119.2672681, 5.2284891],
          [119.2670417, 5.2275421],
          [119.2669172, 5.2267981],
          [119.2664531, 5.226381],
          [119.2658418, 5.2266064],
          [119.2652984, 5.2262683],
          [119.2650104, 5.2271133],
          [119.2641727, 5.2275191],
          [119.2634256, 5.228466],
          [119.2625879, 5.2288042],
          [119.2613879, 5.2281729],
          [119.2619992, 5.2276769],
          [119.2638331, 5.2258281],
          [119.2648519, 5.2248812],
          [119.2657802, 5.2238666],
          [119.2659386, 5.2222207],
          [119.2655085, 5.2212512],
          [119.2645802, 5.220665],
          [119.2643085, 5.2201013],
          [119.2650783, 5.2196729],
          [119.265667, 5.2192671],
          [119.2649877, 5.2184554],
          [119.2641048, 5.2178241],
          [119.2645689, 5.2170237],
          [119.2643821, 5.2166235],
          [119.2646368, 5.2165559],
          [119.2646481, 5.2163191],
          [119.2646821, 5.216088],
          [119.2645463, 5.2158175],
          [119.2648405, 5.2154242],
          [119.2652836, 5.2149175],
          [119.2653001, 5.2144653],
          [119.2653493, 5.213877],
          [119.2653548, 5.2134901],
          [119.2651305, 5.2127002],
          [119.2646709, 5.2124496],
          [119.2642169, 5.2124986],
          [119.2637354, 5.2123842],
          [119.2633689, 5.2122861],
          [119.2632978, 5.2115288],
          [119.2631884, 5.2114308],
          [119.2629313, 5.211251],
          [119.2629258, 5.2107716],
          [119.2632267, 5.2099925],
          [119.2637354, 5.2094804],
          [119.2641184, 5.2094095],
          [119.2645451, 5.2094477],
          [119.2650429, 5.209306],
          [119.2653602, 5.209366],
          [119.2657815, 5.2093932],
          [119.2661754, 5.2096765],
          [119.2665091, 5.209731],
          [119.2666404, 5.2097201],
          [119.2668264, 5.2092189],
          [119.2668756, 5.208516],
          [119.2668154, 5.208211],
          [119.2665528, 5.2077806],
          [119.266066, 5.2075027],
          [119.2658034, 5.2075735],
          [119.2655189, 5.2074973],
          [119.2653493, 5.2071431],
          [119.2653493, 5.206789],
          [119.2650867, 5.2069089],
          [119.2647694, 5.2069579],
          [119.2644466, 5.2068489],
          [119.2640473, 5.2063749],
          [119.2639543, 5.2061025],
          [119.2635604, 5.2055904],
          [119.2210053, 5.1479511],
          [119.2174005, 5.1479511],
          [119.2148255, 5.1394026],
          [119.2101907, 5.1394026],
          [119.1629838, 5.1050365],
          [119.1082238, 5.0846895],
          [119.0955269, 5.0766882],
          [119.0562164, 5.0677967],
          [119.0436851, 5.0604441],
          [119.0357887, 5.061812],
          [119.000502312547326, 5.054008469459637],
          [118.9549715, 5.0439394],
          [118.9486923, 5.0420476],
          [118.9452616, 5.0409448],
          [118.940091, 5.0399891],
          [118.9334501, 5.0386168],
          [118.9294803, 5.0376856],
          [118.9289167, 5.0376611],
          [118.9285981, 5.0382003],
          [118.9285001, 5.0384208],
          [118.9266867, 5.0373426],
          [118.9209525, 5.0345],
          [118.9177842, 5.0326343],
          [118.9166784, 5.032037],
          [118.9152602, 5.0313478],
          [118.9138419, 5.0307566],
          [118.9124574, 5.0302543],
          [118.9102795, 5.029369],
          [118.9097128, 5.0292404],
          [118.9092013, 5.0292832],
          [118.9090334, 5.0293846],
          [118.9089003, 5.0294512],
          [118.9088496, 5.0293688],
          [118.9089225, 5.0293244],
          [118.9089636, 5.0292832],
          [118.9089953, 5.0291818],
          [118.908951, 5.0290772],
          [118.9086404, 5.0288617],
          [118.9078988, 5.0285258],
          [118.9067358, 5.0281297],
          [118.9057185, 5.0277811],
          [118.9049706, 5.0275687],
          [118.9041117, 5.0272582],
          [118.9024923, 5.026637],
          [118.9015035, 5.0262472],
          [118.9006511, 5.0259588],
          [118.9004165, 5.0259873],
          [118.8967876, 5.0243864],
          [118.8964398, 5.024354],
          [118.8960642, 5.024405],
          [118.8960271, 5.0242149],
          [118.8958184, 5.0239579],
          [118.8950765, 5.0234942],
          [118.8940657, 5.0229749],
          [118.8912849, 5.0211144],
          [118.8894225, 5.0196324],
          [118.8875143, 5.0180193],
          [118.8863077, 5.0169045],
          [118.8848585, 5.0156192],
          [118.8836191, 5.0145175],
          [118.8827601, 5.0140979],
          [118.8821896, 5.0138028],
          [118.8819863, 5.0138487],
          [118.8820467, 5.0139861],
          [118.8821794, 5.0140363],
          [118.8823034, 5.0141904],
          [118.8824866, 5.0144849],
          [118.8828553, 5.0151931],
          [118.8830717, 5.0156358],
          [118.8830964, 5.0158325],
          [118.8830315, 5.0161617],
          [118.8830129, 5.0162452],
          [118.8828321, 5.0162452],
          [118.8828831, 5.0159762],
          [118.8828979, 5.0156948],
          [118.8825602, 5.0151833],
          [118.8823536, 5.0149046],
          [118.8822153, 5.0146483],
          [118.882027, 5.0142385],
          [118.8818663, 5.0140484],
          [118.8784273, 5.010393],
          [118.8777217, 5.0095381],
          [118.876582, 5.008534],
          [118.8755236, 5.0075299],
          [118.8745602, 5.0064444],
          [118.8737325, 5.0057388],
          [118.8723485, 5.0041784],
          [118.8712494, 5.002808],
          [118.8696075, 5.0012204],
          [118.8677079, 4.9996464],
          [118.8668938, 4.9989137],
          [118.8630974, 4.9923856],
          [118.8610526, 4.9928719],
          [118.8574451, 4.9958523],
          [118.8540677, 4.9953917],
          [118.8528174, 4.9939555],
          [118.8428413, 4.9937835],
          [118.835693, 4.9938444],
          [118.8275517, 4.9914562],
          [118.8208215, 4.9889595],
          [118.8176735, 4.9864629],
          [118.8157188, 4.9864002],
          [118.8094846, 4.9848818],
          [118.8008981, 4.9817409],
          [118.7956876, 4.9792442],
          [118.787738, 4.9749424],
          [118.7479126, 4.9494607],
          [118.7367128, 4.9510266],
          [118.7360699, 4.9492586],
          [118.7344397, 4.9475135],
          [118.7324352, 4.9464364],
          [118.73194, 4.9463147],
          [118.7310551, 4.946615],
          [118.7304869, 4.9476623],
          [118.7306411, 4.9482224],
          [118.7304869, 4.9489936],
          [118.730633, 4.9494076],
          [118.7309496, 4.95026],
          [118.7309577, 4.9507795],
          [118.7305112, 4.9500002],
          [118.7298131, 4.9500327],
          [118.7293422, 4.9500246],
          [118.7288876, 4.9503899],
          [118.728636, 4.9511692],
          [118.7279866, 4.9518348],
          [118.7274751, 4.9521677],
          [118.7275807, 4.9513397],
          [118.7275888, 4.9505441],
          [118.7270611, 4.9499109],
          [118.7264766, 4.949846],
          [118.7261925, 4.9497891],
          [118.726501, 4.9492452],
          [118.7267608, 4.9485065],
          [118.7264523, 4.9476217],
          [118.7259327, 4.9468748],
          [118.7246095, 4.9467449],
          [118.7233756, 4.9463147],
          [118.721955, 4.9459413],
          [118.7213218, 4.9461767],
          [118.7208509, 4.9465826],
          [118.7200716, 4.9475648],
          [118.7197388, 4.9484903],
          [118.7198281, 4.9498135],
          [118.7196901, 4.9506659],
          [118.7195358, 4.9517618],
          [118.7189114, 4.9517934],
          [118.7191786, 4.9525411],
          [118.7183831, 4.9530688],
          [118.7177255, 4.9523544],
          [118.7163049, 4.9518511],
          [118.7145758, 4.9523869],
          [118.7145352, 4.9518267],
          [118.7138533, 4.9509175],
          [118.7122784, 4.9504304],
          [118.7109415, 4.9502163],
          [118.7090257, 4.9494369],
          [118.7055188, 4.9494369],
          [118.7024989, 4.9509631],
          [118.701265, 4.9511255],
          [118.7016222, 4.9493071],
          [118.7006633, 4.9472802],
          [118.6996473, 4.9468325],
          [118.6979252, 4.9470621],
          [118.6974832, 4.9476017],
          [118.6962319, 4.9465914],
          [118.6949862, 4.9465225],
          [118.6940047, 4.9469243],
          [118.6924146, 4.9471654],
          [118.6910863, 4.9470387],
          [118.6901219, 4.9478883],
          [118.6893757, 4.9489559],
          [118.6887443, 4.9486919],
          [118.6882162, 4.9479801],
          [118.6884458, 4.9474864],
          [118.6886983, 4.9469124],
          [118.688082, 4.9458819],
          [118.6867066, 4.9442269],
          [118.6837296, 4.9437509],
          [118.6826166, 4.941154],
          [118.6799072, 4.9401323],
          [118.678587, 4.9393401],
          [118.6772552, 4.9392368],
          [118.6777718, 4.937354],
          [118.6777489, 4.9366422],
          [118.6784721, 4.9362519],
          [118.6807682, 4.9365619],
          [118.679781579476881, 4.934534132480621],
          [118.6795215, 4.9339996],
          [118.6779383, 4.9337394],
          [118.6758439, 4.933983],
          [118.674921558489686, 4.934534132480621],
          [118.6745126, 4.9347785],
          [118.6743502, 4.9360124],
          [118.6733923, 4.9365807],
          [118.6725967, 4.9361748],
          [118.6718661, 4.9349896],
          [118.671052790907325, 4.934534132480621],
          [118.6698367, 4.9338531],
          [118.6680336, 4.9335195],
          [118.667174, 4.9338531],
          [118.666198424089274, 4.934534132480621],
          [118.665453, 4.9350545],
          [118.6651932, 4.9363209],
          [118.6656478, 4.9377984],
          [118.6651932, 4.938805],
          [118.6646574, 4.9398116],
          [118.6632287, 4.940185],
          [118.6616863, 4.939909],
          [118.6609557, 4.9384478],
          [118.6605498, 4.9381393],
          [118.6596893, 4.9389186],
          [118.6594945, 4.9397142],
          [118.6595919, 4.9404773],
          [118.6589262, 4.9413053],
          [118.6582605, 4.9421333],
          [118.6582768, 4.9429289],
          [118.6571078, 4.9426204],
          [118.6568156, 4.9415488],
          [118.657741, 4.9411592],
          [118.658358, 4.940461],
          [118.6584878, 4.9396005],
          [118.6587314, 4.9382854],
          [118.6592834, 4.9377659],
          [118.6600627, 4.9367106],
          [118.660014, 4.9355416],
          [118.6598841, 4.9345999],
          [118.6575949, 4.9348435],
          [118.657077590277822, 4.934534132480621],
          [118.6559388, 4.9338531],
          [118.6552407, 4.9329601],
          [118.6541529, 4.9324243],
          [118.6525618, 4.9322295],
          [118.6514383, 4.9328668],
          [118.6509707, 4.9334147],
          [118.6509869, 4.9343077],
          [118.651230766869688, 4.934534132480621],
          [118.6514415, 4.9347298],
          [118.6523345, 4.9358988],
          [118.6526592, 4.9374737],
          [118.6528216, 4.938529],
          [118.6531625, 4.9394707],
          [118.6529514, 4.9403474],
          [118.6524319, 4.9409806],
          [118.6515552, 4.9415813],
          [118.6513928, 4.9423444],
          [118.6504024, 4.9415488],
          [118.6494932, 4.9407533],
          [118.6495095, 4.9398441],
          [118.6487626, 4.9386426],
          [118.6479184, 4.9401201],
          [118.6469929, 4.9392596],
          [118.6458889, 4.9392758],
          [118.6449147, 4.938253],
          [118.6442491, 4.938188],
          [118.6431406, 4.9389582],
          [118.6421222, 4.939081],
          [118.6416676, 4.9398765],
          [118.641765, 4.9410131],
          [118.6411967, 4.9417274],
          [118.6399628, 4.9409968],
          [118.6397031, 4.9402987],
          [118.638047, 4.9401688],
          [118.6360987, 4.9413865],
          [118.6353032, 4.9429451],
          [118.6347349, 4.9429938],
          [118.6331276, 4.9424418],
          [118.6325918, 4.9421983],
          [118.6330139, 4.9413215],
          [118.6318125, 4.9405585],
          [118.6317859, 4.9406986],
          [118.6310656, 4.9403636],
          [118.6304162, 4.9417599],
          [118.629507, 4.9426366],
          [118.6286952, 4.9431724],
          [118.6268768, 4.9448122],
          [118.6266657, 4.9450071],
          [118.624884, 4.9456849],
          [118.6228279, 4.9467986],
          [118.6210931, 4.9475911],
          [118.6203649, 4.947741],
          [118.6193797, 4.9476767],
          [118.6181803, 4.9476125],
          [118.6173878, 4.9485549],
          [118.617344379425049, 4.948523580023087],
          [118.6160813, 4.9476125],
          [118.6158029, 4.9467129],
          [118.6143251, 4.9453636],
          [118.6132542, 4.9452351],
          [118.611605, 4.9463703],
          [118.6110267, 4.9453208],
          [118.6102343, 4.9450424],
          [118.6095918, 4.944614],
          [118.607193, 4.9442927],
          [118.6071113, 4.9446144],
          [118.59663, 4.9494005],
          [118.588114, 4.956797],
          [118.5819999, 4.9502706],
          [118.5721737, 4.9591899],
          [118.5695534, 4.9504882],
          [118.5506736, 4.9593798],
          [118.528253075476414, 4.934534132480621],
          [118.518573, 4.923807],
          [118.4752875, 4.9233701],
          [118.466285932075209, 4.934534132480621],
          [118.4502517, 4.9544203],
          [118.4500547, 4.9612342],
          [118.4493875, 4.984313],
          [118.4459542, 4.9932056],
          [118.43737510617683, 5.0005],
          [118.4368622, 5.0009361],
          [118.4119546, 5.0128892],
          [118.411282, 5.0133359],
          [118.4109284, 5.0137568],
          [118.4107128, 5.0143925],
          [118.4107042, 5.0149079],
          [118.4108389, 5.0156964],
          [118.4108985, 5.0159911],
          [118.4108035, 5.0158233],
          [118.4106637, 5.0155356],
          [118.4104991, 5.015408],
          [118.4103406, 5.015244],
          [118.4100083, 5.0155933],
          [118.4100723, 5.0157603],
          [118.4098284, 5.015982],
          [118.4096851, 5.0161217],
          [118.4095723, 5.0162918],
          [118.409493, 5.0164224],
          [118.4094686, 5.0165621],
          [118.4095266, 5.0165925],
          [118.4096729, 5.0165226],
          [118.4098314, 5.0164437],
          [118.4099442, 5.0163343],
          [118.410057, 5.0162584],
          [118.4102186, 5.0162827],
          [118.410365, 5.0163252],
          [118.4103802, 5.0164406],
          [118.4102217, 5.0163799],
          [118.4100784, 5.0164315],
          [118.4099808, 5.0165257],
          [118.4099229, 5.0166927],
          [118.4095205, 5.0167899],
          [118.409118, 5.0170875],
          [118.4082187, 5.0176615],
          [118.4079443, 5.0180259],
          [118.4080205, 5.0181596],
          [118.4084469, 5.0182542],
          [118.4080243, 5.0185463],
          [118.4079122, 5.018993],
          [118.4076794, 5.0194654],
          [118.4074121, 5.0194396],
          [118.4075414, 5.0200753],
          [118.4079985, 5.0209601],
          [118.4080008, 5.0220765],
          [118.4075252, 5.0209467],
          [118.4070618, 5.0198413],
          [118.4069896, 5.0194396],
          [118.4070496, 5.0191002],
          [118.4070374, 5.0180433],
          [118.4062082, 5.0179462],
          [118.4057448, 5.0185293],
          [118.404696, 5.0181891],
          [118.4042082, 5.0173145],
          [118.4042326, 5.0163912],
          [118.4039399, 5.0161968],
          [118.4031107, 5.0167556],
          [118.4017205, 5.0178004],
          [118.4002571, 5.0188694],
          [118.3983303, 5.0188208],
          [118.3967206, 5.0184321],
          [118.3953928, 5.0181266],
          [118.3948266, 5.0183749],
          [118.394469, 5.0188418],
          [118.393724, 5.019408],
          [118.392979, 5.0196067],
          [118.3926711, 5.0196563],
          [118.391946, 5.0200537],
          [118.3918168, 5.0204609],
          [118.3914096, 5.0210768],
          [118.3915387, 5.0211662],
          [118.3915884, 5.0220602],
          [118.391201, 5.0215734],
          [118.3911612, 5.0211264],
          [118.3909626, 5.0208284],
          [118.3903268, 5.0206596],
          [118.3900686, 5.0208086],
          [118.3897904, 5.0206695],
          [118.389418, 5.0207772],
          [118.3891946, 5.0215796],
          [118.3886608, 5.0226473],
          [118.3879303, 5.0233356],
          [118.3871437, 5.0236025],
          [118.385736, 5.0236915],
          [118.3847456, 5.0235581],
          [118.3837763, 5.0239585],
          [118.3828281, 5.0242605],
          [118.3823505, 5.0248856],
          [118.3822381, 5.0251455],
          [118.382491, 5.0254124],
          [118.3829124, 5.0256863],
          [118.3828632, 5.0258128],
          [118.3822381, 5.0256933],
          [118.3818096, 5.0259603],
          [118.3812758, 5.0260235],
          [118.3809036, 5.0259743],
          [118.3800396, 5.0262623],
          [118.3790282, 5.026782],
          [118.3783539, 5.0271754],
          [118.3779114, 5.0273229],
          [118.3775672, 5.0274563],
          [118.3763029, 5.0276811],
          [118.3762397, 5.0274072],
          [118.3777147, 5.0268312],
          [118.3785084, 5.0264098],
          [118.3795058, 5.0258057],
          [118.3798429, 5.025293],
          [118.3803346, 5.024106],
          [118.3808333, 5.0235089],
          [118.3812477, 5.0226942],
          [118.3812799, 5.0221691],
          [118.3815819, 5.0217196],
          [118.382193, 5.0209188],
          [118.3826003, 5.020378],
          [118.3826987, 5.0201673],
          [118.3829726, 5.0200689],
          [118.383092, 5.019795],
          [118.3829515, 5.0196405],
          [118.3826776, 5.018327],
          [118.3825231, 5.0179969],
          [118.3823053, 5.0174771],
          [118.3823967, 5.0167958],
          [118.3820244, 5.0163674],
          [118.3801701, 5.0145763],
          [118.3788644, 5.0141901],
          [118.3765528, 5.0145201],
          [118.3753154, 5.0148807],
          [118.3744936, 5.0150914],
          [118.3733909, 5.0150914],
          [118.3730116, 5.0153302],
          [118.3721968, 5.0153513],
          [118.371677, 5.0156323],
          [118.3705953, 5.0156533],
          [118.3702582, 5.0163979],
          [118.3702793, 5.0167561],
          [118.3700545, 5.0169808],
          [118.3691133, 5.0171705],
          [118.3678701, 5.0173953],
          [118.3673714, 5.0174866],
          [118.3670132, 5.0176622],
          [118.367012, 5.0171618],
          [118.3670904, 5.0169176],
          [118.3670694, 5.0148315],
          [118.3670413, 5.0135251],
          [118.3666901, 5.0135532],
          [118.3667269, 5.0171184],
          [118.3667322, 5.0178167],
          [118.3666694, 5.0178707],
          [118.3666835, 5.0194405],
          [118.3666466, 5.0195486],
          [118.3663834, 5.0196082],
          [118.3661102, 5.0196479],
          [118.3659414, 5.0196181],
          [118.3659463, 5.0189924],
          [118.3659016, 5.0181331],
          [118.3658222, 5.0180686],
          [118.3653752, 5.0180189],
          [118.3650275, 5.0181878],
          [118.3644464, 5.0185702],
          [118.3640988, 5.0187887],
          [118.3638653, 5.0190967],
          [118.3632693, 5.0192556],
          [118.3629614, 5.0194145],
          [118.3623555, 5.0193897],
          [118.3620128, 5.0195387],
          [118.3617982, 5.0195983],
          [118.3615176, 5.0194989],
          [118.3613984, 5.0193773],
          [118.3612692, 5.0190395],
          [118.3611823, 5.0189328],
          [118.3610581, 5.0188334],
          [118.3606186, 5.0186223],
          [118.3603703, 5.0184783],
          [118.3600325, 5.0184286],
          [118.359814, 5.0183939],
          [118.3596178, 5.018456],
          [118.3594713, 5.0186869],
          [118.3594167, 5.0190942],
          [118.3593844, 5.0193872],
          [118.3594713, 5.020686],
          [118.3594713, 5.0209144],
          [118.359439, 5.0214682],
          [118.3594539, 5.0216122],
          [118.3594018, 5.0216892],
          [118.3593173, 5.0216619],
          [118.3591758, 5.0217265],
          [118.3588703, 5.0216321],
          [118.3587089, 5.0216321],
          [118.3587338, 5.0218109],
          [118.3586245, 5.021796],
          [118.3585252, 5.0217314],
          [118.3584159, 5.0216147],
          [118.3584333, 5.0215328],
          [118.3581626, 5.0212919],
          [118.3582197, 5.021128],
          [118.3583017, 5.0208499],
          [118.358401, 5.0206835],
          [118.3584732, 5.0205046],
          [118.3584192, 5.0203608],
          [118.3583524, 5.0199446],
          [118.3582882, 5.0197622],
          [118.3581649, 5.0196465],
          [118.3578925, 5.0196003],
          [118.3577564, 5.0195977],
          [118.3575329, 5.0194693],
          [118.357186, 5.0191892],
          [118.3569908, 5.0190402],
          [118.3568212, 5.018994],
          [118.3567133, 5.0189451],
          [118.3563793, 5.0189503],
          [118.3559168, 5.01894],
          [118.3554981, 5.018994],
          [118.3544021, 5.0192553],
          [118.3541478, 5.0193425],
          [118.3540372, 5.0195183],
          [118.3540108, 5.0193301],
          [118.3539284, 5.0193061],
          [118.3538819, 5.0193221],
          [118.3538726, 5.0193713],
          [118.3538433, 5.0193752],
          [118.353846, 5.0195799],
          [118.3538646, 5.0199228],
          [118.3535483, 5.0198324],
          [118.3532532, 5.0198138],
          [118.3530539, 5.0197819],
          [118.3528572, 5.019657],
          [118.3528492, 5.0194071],
          [118.3528253, 5.0192264],
          [118.3526392, 5.0191466],
          [118.3524026, 5.0191227],
          [118.3520385, 5.0192849],
          [118.3519906, 5.0196464],
          [118.3521049, 5.0198112],
          [118.3520704, 5.0202737],
          [118.3513075, 5.0202444],
          [118.3512836, 5.0193593],
          [118.3506669, 5.0193806],
          [118.3505154, 5.0194603],
          [118.3504313, 5.0193894],
          [118.3508598, 5.0176941],
          [118.3524875, 5.0176903],
          [118.3524724, 5.0173971],
          [118.350732, 5.0174084],
          [118.3504576, 5.0182955],
          [118.3505365, 5.0183632],
          [118.3502658, 5.019333],
          [118.3500793, 5.0192618],
          [118.3500527, 5.0193761],
          [118.3495955, 5.0193947],
          [118.3494653, 5.0195197],
          [118.3490559, 5.0195197],
          [118.3489416, 5.0194133],
          [118.3486545, 5.0193788],
          [118.3486599, 5.0192034],
          [118.3481867, 5.0190492],
          [118.3481336, 5.018895],
          [118.3480671, 5.0183501],
          [118.3486986, 5.0182892],
          [118.3486761, 5.0181689],
          [118.3483002, 5.0181839],
          [118.348007, 5.018199],
          [118.3478434, 5.0182422],
          [118.3478585, 5.0183381],
          [118.3479299, 5.0183606],
          [118.347975, 5.019041],
          [118.3470671, 5.0197257],
          [118.3460483, 5.02044],
          [118.3459205, 5.0205866],
          [118.3451161, 5.0205565],
          [118.3448116, 5.0200753],
          [118.3447417, 5.01944],
          [118.3424712, 5.0185303],
          [118.3409225, 5.0194325],
          [118.3406293, 5.019425],
          [118.3403736, 5.019643],
          [118.3404655, 5.0206877],
          [118.3407206, 5.0216553],
          [118.3408535, 5.0217829],
          [118.3407366, 5.0220912],
          [118.3404601, 5.0223039],
          [118.3400136, 5.0226335],
          [118.3394129, 5.0223198],
          [118.3398009, 5.0220912],
          [118.3398488, 5.0219158],
          [118.339769, 5.0215756],
          [118.339567, 5.0211449],
          [118.3394288, 5.0209748],
          [118.3387111, 5.0212672],
          [118.3386473, 5.0209323],
          [118.3384932, 5.0207569],
          [118.3380466, 5.0205336],
          [118.337363, 5.0205527],
          [118.3373037, 5.0205543],
          [118.3368735, 5.0205663],
          [118.3367871, 5.0205687],
          [118.3367122, 5.0205708],
          [118.3366378, 5.0211822],
          [118.3364358, 5.0215011],
          [118.3355958, 5.0222613],
          [118.3345132, 5.0231178],
          [118.334393, 5.0229724],
          [118.3343531, 5.022924],
          [118.3345073, 5.0226635],
          [118.3342999, 5.0225227],
          [118.3341431, 5.0227087],
          [118.3339597, 5.0225891],
          [118.333973, 5.0222143],
          [118.3337524, 5.0219857],
          [118.3334228, 5.0220442],
          [118.3329709, 5.0217438],
          [118.3325897, 5.0219767],
          [118.3324302, 5.0218756],
          [118.3324727, 5.0217162],
          [118.3319783, 5.0215673],
          [118.331771, 5.021562],
          [118.331856, 5.0214185],
          [118.3320474, 5.0212856],
          [118.3310801, 5.020872],
          [118.3306591, 5.0206014],
          [118.3301178, 5.0210675],
          [118.329366, 5.0204961],
          [118.3289449, 5.02003],
          [118.3285239, 5.0199548],
          [118.3279074, 5.0198421],
          [118.3282006, 5.0196391],
          [118.3283961, 5.019624],
          [118.3285089, 5.0197443],
          [118.3287119, 5.0196842],
          [118.3288397, 5.0191128],
          [118.3288247, 5.0188196],
          [118.3285239, 5.018158],
          [118.3279601, 5.0178648],
          [118.327742, 5.0175791],
          [118.3273586, 5.0176016],
          [118.326915, 5.0179926],
          [118.3264339, 5.0184286],
          [118.3255993, 5.0184813],
          [118.3253963, 5.0188722],
          [118.3246821, 5.0186842],
          [118.3239754, 5.0190451],
          [118.3230131, 5.0141282],
          [118.3228106, 5.0130595],
          [118.3223321, 5.0131365],
          [118.3218921, 5.0133895],
          [118.3218151, 5.0144071],
          [118.3214246, 5.0149516],
          [118.3210341, 5.0150176],
          [118.3206711, 5.0142641],
          [118.3197415, 5.0142586],
          [118.3194335, 5.013538],
          [118.3195215, 5.0128945],
          [118.319329, 5.0123665],
          [118.3200276, 5.0123995],
          [118.3204236, 5.0111895],
          [118.3204236, 5.0100894],
          [118.3197305, 5.010436],
          [118.319263, 5.010513],
          [118.318632, 5.0106695],
          [118.317619, 5.0106148],
          [118.317564, 5.0103508],
          [118.3181308, 5.0103175],
          [118.3184935, 5.0102866],
          [118.3187269, 5.0102243],
          [118.3189408, 5.0101952],
          [118.3192908, 5.010131],
          [118.3196019, 5.0099949],
          [118.3197089, 5.0098976],
          [118.3196758, 5.0095107],
          [118.3195598, 5.0088628],
          [118.3191858, 5.0086208],
          [118.3190098, 5.0085933],
          [118.3186523, 5.0078728],
          [118.3181875, 5.0071908],
          [118.3175084, 5.0063431],
          [118.317135, 5.0059386],
          [118.3164194, 5.006172],
          [118.3160694, 5.0064209],
          [118.3158516, 5.0061409],
          [118.3151515, 5.0062886],
          [118.3144126, 5.0065842],
          [118.3138447, 5.0075876],
          [118.3135569, 5.0073543],
          [118.3137534, 5.0069144],
          [118.3140035, 5.006517],
          [118.314266, 5.0063089],
          [118.3146529, 5.0060105],
          [118.3149972, 5.0058373],
          [118.315353, 5.0056642],
          [118.3154911, 5.0056234],
          [118.3155572, 5.0054542],
          [118.3155164, 5.005143],
          [118.3156377, 5.0042344],
          [118.3160887, 5.0032444],
          [118.3163637, 5.0028154],
          [118.3162977, 5.0022544],
          [118.3161657, 5.0017153],
          [118.3168917, 5.0007913],
          [118.3170237, 4.9998013],
          [118.3170567, 4.9986793],
          [118.3163197, 4.9977992],
          [118.3156487, 4.9972162],
          [118.3147906, 4.9971282],
          [118.3139766, 4.9975792],
          [118.3126676, 4.9992953],
          [118.312552680701756, 4.9995],
          [118.312348604182986, 4.999863511299051],
          [118.311991277192988, 5.0005],
          [118.3119636, 5.0005493],
          [118.3113035, 5.0011873],
          [118.3103465, 5.0016273],
          [118.3090595, 5.0018803],
          [118.3077175, 5.0018473],
          [118.3068154, 5.0018693],
          [118.3054404, 5.0021884],
          [118.3045824, 5.0025844],
          [118.3036034, 5.0026394],
          [118.3028883, 5.0023314],
          [118.3022943, 5.0019353],
          [118.3015793, 5.0014843],
          [118.3006003, 5.0014513],
          [118.2998743, 5.0014623],
          [118.2994452, 5.0015943],
          [118.2992692, 5.0010553],
          [118.2998193, 5.0008353],
          [118.3004463, 5.0008573],
          [118.3010183, 5.0008353],
          [118.3016704, 5.0008728],
          [118.3021099, 5.0010323],
          [118.3024988, 5.0012734],
          [118.3028005, 5.0016008],
          [118.3031621, 5.001902],
          [118.3033272, 5.0020257],
          [118.3034977, 5.0020972],
          [118.3037795, 5.0020752],
          [118.3040367, 5.0019638],
          [118.3042182, 5.0017878],
          [118.3043846, 5.0016985],
          [118.3045523, 5.0016833],
          [118.3046898, 5.0015486],
          [118.3048727, 5.0014578],
          [118.3049662, 5.0013932],
          [118.3048919, 5.0015926],
          [118.3049263, 5.0016861],
          [118.3049964, 5.0017053],
          [118.3052481, 5.0016325],
          [118.3059328, 5.0014042],
          [118.3061281, 5.0013011],
          [118.306234, 5.0012103],
          [118.3063206, 5.0012681],
          [118.3066231, 5.0012488],
          [118.3068926, 5.0011842],
          [118.3075917, 5.001105],
          [118.3083229, 5.0011321],
          [118.3089813, 5.0011209],
          [118.3094558, 5.0010626],
          [118.31001, 5.0009673],
          [118.3105535, 5.0007739],
          [118.3110705, 5.0003999],
          [118.3115435, 4.9999214],
          [118.3118185, 4.9993109],
          [118.3119395, 4.9982879],
          [118.3118845, 4.9980293],
          [118.3115655, 4.9979853],
          [118.3113015, 4.9981943],
          [118.3108725, 4.9981943],
          [118.3109165, 4.9980128],
          [118.3110383, 4.9980376],
          [118.3111662, 4.998017],
          [118.3113546, 4.9978382],
          [118.3114859, 4.9974268],
          [118.3117504, 4.9969757],
          [118.3123493, 4.9956145],
          [118.3124193, 4.9942766],
          [118.3118593, 4.9929231],
          [118.310139, 4.9908906],
          [118.3088849, 4.9909346],
          [118.3083019, 4.9910446],
          [118.3088849, 4.9904946],
          [118.3083789, 4.9893835],
          [118.3086759, 4.9888995],
          [118.309765, 4.9887455],
          [118.310469, 4.9880085],
          [118.310579, 4.9886135],
          [118.311195, 4.9886905],
          [118.311855, 4.9881955],
          [118.312559, 4.9871395],
          [118.3134941, 4.9866115],
          [118.3141651, 4.9861605],
          [118.3145642, 4.985864],
          [118.3151446, 4.9852096],
          [118.3149568, 4.9837813],
          [118.3147065, 4.9830301],
          [118.3137049, 4.982666],
          [118.3129595, 4.981966],
          [118.3122709, 4.981471],
          [118.3116052, 4.9811068],
          [118.3074113, 4.9805548],
          [118.3067256, 4.9805946],
          [118.3061736, 4.9806032],
          [118.3054492, 4.9806727],
          [118.3046405, 4.9818843],
          [118.3044795, 4.9823251],
          [118.304451, 4.9825954],
          [118.3043685, 4.9829426],
          [118.3044261, 4.9846023],
          [118.3040505, 4.9839308],
          [118.3038684, 4.9832366],
          [118.3022962, 4.9810768],
          [118.3027468, 4.9786303],
          [118.3041255, 4.9791481],
          [118.305431, 4.9797837],
          [118.305744, 4.9798093],
          [118.3062817, 4.9797468],
          [118.3070471, 4.9797041],
          [118.3076844, 4.9799032],
          [118.3081795, 4.9799772],
          [118.3087542, 4.9800341],
          [118.3093802, 4.9800142],
          [118.3095452, 4.9799545],
          [118.3099549, 4.9794423],
          [118.3106492, 4.9791436],
          [118.3110134, 4.9786769],
          [118.3112432, 4.9782671],
          [118.3105438, 4.9774678],
          [118.3100776, 4.9771015],
          [118.3100617, 4.9766337],
          [118.3098494, 4.9761675],
          [118.3097953, 4.975747],
          [118.309631, 4.9751831],
          [118.3092425, 4.9743824],
          [118.309372, 4.9739232],
          [118.3101727, 4.9743118],
          [118.3109145, 4.9739821],
          [118.3113148, 4.9724631],
          [118.3108166, 4.9715751],
          [118.3098746, 4.9709393],
          [118.3093483, 4.970698],
          [118.3083329, 4.9710687],
          [118.3078089, 4.9705859],
          [118.307438, 4.9701149],
          [118.3070965, 4.9698205],
          [118.3066138, 4.969638],
          [118.3062605, 4.9697322],
          [118.3059838, 4.9695909],
          [118.3058484, 4.9692259],
          [118.3054517, 4.9687664],
          [118.3049418, 4.9679484],
          [118.3044526, 4.9675009],
          [118.304459, 4.9666002],
          [118.3043413, 4.9659702],
          [118.3039821, 4.9655699],
          [118.3036819, 4.9655463],
          [118.3036866, 4.9651696],
          [118.3039863, 4.9638707],
          [118.3040587, 4.9635682],
          [118.3040292, 4.963056],
          [118.3038644, 4.9625261],
          [118.3036642, 4.9621611],
          [118.3038232, 4.962008],
          [118.3039174, 4.9613899],
          [118.3036466, 4.961172],
          [118.3030205, 4.9614062],
          [118.2999233, 4.9615062],
          [118.2974255, 4.9620057],
          [118.2951275, 4.9619058],
          [118.29233, 4.9628383],
          [118.291453123092651, 4.963159414966155],
          [118.2911222, 4.9632806],
          [118.2907647, 4.9635044],
          [118.2905789, 4.9636012],
          [118.2906226, 4.9637531],
          [118.2908557, 4.9638322],
          [118.291272, 4.9637552],
          [118.291453123092651, 4.963815802752203],
          [118.2918007, 4.9639321],
          [118.2923711, 4.9639675],
          [118.292983, 4.9639217],
          [118.2937407, 4.9638426],
          [118.2939572, 4.9638718],
          [118.2942382, 4.964055],
          [118.2944817, 4.9643318],
          [118.2944942, 4.9648543],
          [118.2944234, 4.9653725],
          [118.2945296, 4.9660282],
          [118.2944401, 4.9665319],
          [118.2947606, 4.9668775],
          [118.2951228, 4.9669711],
          [118.2953747, 4.9667859],
          [118.2955516, 4.9666235],
          [118.2958347, 4.9660823],
          [118.295893, 4.9657368],
          [118.2959637, 4.965487],
          [118.295995, 4.9652435],
          [118.2961074, 4.9650104],
          [118.2965715, 4.9648132],
          [118.2967945, 4.9648015],
          [118.2969402, 4.9646808],
          [118.2968372, 4.964587],
          [118.2965307, 4.9643684],
          [118.2967662, 4.963968],
          [118.2973432, 4.9645568],
          [118.2968133, 4.9650042],
          [118.2962834, 4.965487],
          [118.2961892, 4.9666998],
          [118.2959773, 4.967418],
          [118.2955534, 4.9680067],
          [118.2956241, 4.9686897],
          [118.2961657, 4.9687839],
          [118.2963541, 4.9690311],
          [118.2960479, 4.9697494],
          [118.2951648, 4.9714921],
          [118.2941758, 4.9720101],
          [118.2932215, 4.9716775],
          [118.2926357, 4.9716893],
          [118.2921324, 4.9718482],
          [118.2914995, 4.9720101],
          [118.2914641, 4.9724105],
          [118.2916643, 4.9729698],
          [118.2916437, 4.9732995],
          [118.291453123092651, 4.973833175113775],
          [118.2914524, 4.9738352],
          [118.291453123092651, 4.97383649014603],
          [118.2915613, 4.9740295],
          [118.2923266, 4.9747831],
          [118.2922501, 4.9750892],
          [118.2919587, 4.9760253],
          [118.2919204, 4.976617],
          [118.2916496, 4.9770173],
          [118.291453123092651, 4.977491995622366],
          [118.2914376, 4.9775295],
          [118.2913876, 4.9781507],
          [118.291453123092651, 4.97865440082293],
          [118.2914906, 4.9789425],
          [118.291453123092651, 4.979117784940495],
          [118.2913729, 4.979493],
          [118.2912699, 4.9800493],
          [118.2913376, 4.9804379],
          [118.2914465, 4.9806498],
          [118.291453123092651, 4.980655545450348],
          [118.2917876, 4.9809457],
          [118.2923621, 4.9817949],
          [118.292283, 4.9822112],
          [118.2920665, 4.9828773],
          [118.291453123092651, 4.983173655084848],
          [118.2914462, 4.983177],
          [118.2909883, 4.9835392],
          [118.2906011, 4.9840554],
          [118.2907302, 4.9845591],
          [118.2905054, 4.9851461],
          [118.2904183, 4.9855072],
          [118.2904277, 4.9856665],
          [118.2904412, 4.985833],
          [118.2904131, 4.9860214],
          [118.2904745, 4.986063],
          [118.2905276, 4.9862764],
          [118.2906421, 4.9864418],
          [118.2907971, 4.9864751],
          [118.2909449, 4.9864169],
          [118.2911135, 4.9863159],
          [118.2913409, 4.9862143],
          [118.2914502, 4.9861315],
          [118.291453123092651, 4.986129474203209],
          [118.2916095, 4.9860211],
          [118.2917458, 4.9859852],
          [118.2918738, 4.9859623],
          [118.2920632, 4.9859175],
          [118.2922047, 4.9859144],
          [118.2922755, 4.9859935],
          [118.2919758, 4.9860789],
          [118.291649, 4.9862454],
          [118.291453123092651, 4.986403253742978],
          [118.291445, 4.9864098],
          [118.2911598, 4.9866159],
          [118.2909454, 4.9869385],
          [118.2906769, 4.9875068],
          [118.2905125, 4.9876379],
          [118.2902523, 4.9881603],
          [118.290244, 4.9885246],
          [118.2901149, 4.9887161],
          [118.2900525, 4.9889888],
          [118.2901128, 4.9891449],
          [118.2904688, 4.9893281],
          [118.2907748, 4.989378],
          [118.2910438, 4.9895392],
          [118.2912894, 4.9896307],
          [118.291453123092651, 4.989716284050072],
          [118.2915335, 4.9897583],
          [118.2922884, 4.9899272],
          [118.2925506, 4.989897],
          [118.2928379, 4.9899469],
          [118.2932613, 4.9902281],
          [118.2934445, 4.9902685],
          [118.2935593, 4.9902656],
          [118.2937592, 4.9900769],
          [118.2942585, 4.9898146],
          [118.2947831, 4.990131],
          [118.2941128, 4.9901892],
          [118.2935717, 4.9905264],
          [118.2931137, 4.9904265],
          [118.2925184, 4.9901518],
          [118.2920105, 4.9902517],
          [118.291453123092651, 4.990002115707887],
          [118.2910156, 4.9898062],
          [118.290566, 4.989748],
          [118.2901663, 4.9899478],
          [118.2902829, 4.9906055],
          [118.2904744, 4.9915464],
          [118.2908532, 4.992583],
          [118.2912862, 4.993657],
          [118.291453123092651, 4.993868007052554],
          [118.2918856, 4.9944147],
          [118.2924143, 4.9947311],
          [118.2928141, 4.9952075],
          [118.2927434, 4.9955961],
          [118.291719, 4.9949014],
          [118.291453123092651, 4.994683822353233],
          [118.291201, 4.9944775],
          [118.2907956, 4.9938899],
          [118.2895261, 4.9903985],
          [118.2889315, 4.9894153],
          [118.2885635, 4.9887853],
          [118.2882986, 4.9883467],
          [118.2874685, 4.9879582],
          [118.2869416, 4.9876108],
          [118.2866295, 4.9873576],
          [118.2861379, 4.9872693],
          [118.2857906, 4.9877403],
          [118.2855227, 4.9881259],
          [118.2853019, 4.9882849],
          [118.2850661, 4.9884236],
          [118.284807, 4.9885604],
          [118.2845834, 4.9885362],
          [118.285391, 4.9880449],
          [118.2855741, 4.9871707],
          [118.2848165, 4.986438],
          [118.2846749, 4.9863298],
          [118.2839888, 4.9857636],
          [118.2854042, 4.9862299],
          [118.2861535, 4.9866462],
          [118.2874024, 4.9870125],
          [118.2883183, 4.9869626],
          [118.288668, 4.9861133],
          [118.2885181, 4.9852974],
          [118.2878354, 4.9846812],
          [118.2872692, 4.9837321],
          [118.2867325, 4.9824334],
          [118.2841348, 4.9803686],
          [118.2837973, 4.9802478],
          [118.2836207, 4.979765],
          [118.2838798, 4.9793058],
          [118.2847393, 4.9788112],
          [118.2849513, 4.9779281],
          [118.2841741, 4.9767389],
          [118.2845627, 4.9748549],
          [118.2845862, 4.9742191],
          [118.2843036, 4.9739483],
          [118.284098, 4.9726383],
          [118.2845101, 4.9723385],
          [118.2845767, 4.9719764],
          [118.2845601, 4.9715892],
          [118.2843311, 4.9708399],
          [118.2841646, 4.9705401],
          [118.2844768, 4.9702404],
          [118.2844352, 4.9700156],
          [118.2833441, 4.9691289],
          [118.2831589, 4.9690623],
          [118.283111, 4.9689644],
          [118.2830277, 4.9688042],
          [118.2829424, 4.9686335],
          [118.2827467, 4.9683337],
          [118.2824532, 4.9679445],
          [118.2824408, 4.9677946],
          [118.2824116, 4.967524],
          [118.2821202, 4.9673159],
          [118.2821743, 4.9671535],
          [118.2819787, 4.9670266],
          [118.2818309, 4.9669267],
          [118.2816914, 4.9666665],
          [118.2817081, 4.9665041],
          [118.2816499, 4.9664819],
          [118.2816579, 4.9662346],
          [118.2817491, 4.9659403],
          [118.2816785, 4.9658048],
          [118.281861, 4.9656047],
          [118.2821259, 4.9651307],
          [118.2823644, 4.9646127],
          [118.2826205, 4.9647304],
          [118.2828027, 4.9646491],
          [118.2830826, 4.9646009],
          [118.2834094, 4.9642771],
          [118.2834447, 4.9641093],
          [118.2833063, 4.9638767],
          [118.2831268, 4.9636089],
          [118.2828814, 4.9633179],
          [118.2821987, 4.9631139],
          [118.2814077, 4.9629974],
          [118.280571, 4.9629474],
          [118.280213, 4.9630681],
          [118.2794262, 4.9634345],
          [118.2792846, 4.9637425],
          [118.2791764, 4.9641838],
          [118.2788751, 4.9643589],
          [118.2783099, 4.964406],
          [118.2779095, 4.964253],
          [118.2776127, 4.9638212],
          [118.2773337, 4.963765],
          [118.2769841, 4.963867],
          [118.2765927, 4.9641293],
          [118.2763097, 4.9641293],
          [118.27596, 4.963996],
          [118.2759142, 4.9638212],
          [118.2756394, 4.9635797],
          [118.2752564, 4.9635631],
          [118.2748193, 4.9638836],
          [118.2745487, 4.9641084],
          [118.2741407, 4.9641417],
          [118.2739034, 4.9639502],
          [118.2736911, 4.9637546],
          [118.2734247, 4.9638462],
          [118.273229, 4.963842],
          [118.2728502, 4.9639711],
          [118.2729127, 4.9641001],
          [118.272896, 4.96425],
          [118.2724755, 4.9643374],
          [118.2721592, 4.9646746],
          [118.2721675, 4.9653615],
          [118.2718344, 4.9655572],
          [118.2713515, 4.9656654],
          [118.2711725, 4.9653615],
          [118.2711517, 4.9649535],
          [118.2708811, 4.964787],
          [118.2703899, 4.9648869],
          [118.2702317, 4.9651617],
          [118.2701651, 4.9654739],
          [118.2702442, 4.9659027],
          [118.2698612, 4.9661858],
          [118.2699944, 4.9664647],
          [118.2700111, 4.966652],
          [118.269857, 4.9666104],
          [118.2696405, 4.9671141],
          [118.2692367, 4.9675637],
          [118.2688038, 4.9675845],
          [118.2684916, 4.9674139],
          [118.2684583, 4.9670059],
          [118.2683375, 4.9667977],
          [118.2680794, 4.9666187],
          [118.2675965, 4.9665813],
          [118.2674966, 4.9662732],
          [118.2674163, 4.9661811],
          [118.2670304, 4.9661608],
          [118.2663684, 4.9662399],
          [118.2660562, 4.966448],
          [118.2659063, 4.9663315],
          [118.2654692, 4.9662441],
          [118.2650113, 4.9663107],
          [118.2648073, 4.966548],
          [118.2650071, 4.9668185],
          [118.2651154, 4.9670309],
          [118.2650529, 4.9672681],
          [118.2646241, 4.9673805],
          [118.2641121, 4.9675887],
          [118.2644035, 4.9679883],
          [118.2643619, 4.9683172],
          [118.2645201, 4.9686461],
          [118.2646117, 4.9689375],
          [118.2642911, 4.969104],
          [118.2638748, 4.9691998],
          [118.2633503, 4.9691207],
          [118.263113, 4.9687002],
          [118.2625801, 4.9685545],
          [118.2621055, 4.9684379],
          [118.2618516, 4.9685628],
          [118.2616726, 4.9687668],
          [118.2617725, 4.9690333],
          [118.261989, 4.969179],
          [118.2621513, 4.969487],
          [118.2621472, 4.9696744],
          [118.2620889, 4.9698325],
          [118.2617517, 4.9700823],
          [118.2615644, 4.9699824],
          [118.2613021, 4.9697576],
          [118.2609857, 4.9695578],
          [118.260482, 4.9694537],
          [118.2603113, 4.969562],
          [118.260074, 4.9694745],
          [118.259845, 4.9693205],
          [118.2597909, 4.9690915],
          [118.2600407, 4.9687751],
          [118.2601406, 4.9685379],
          [118.2601781, 4.9682631],
          [118.2600199, 4.9681507],
          [118.2596993, 4.9681549],
          [118.2596827, 4.9685379],
          [118.2596077, 4.9688542],
          [118.2592414, 4.9689125],
          [118.2588001, 4.9690083],
          [118.258542, 4.9692622],
          [118.2586294, 4.9695953],
          [118.2589417, 4.9697493],
          [118.2589916, 4.9700074],
          [118.2588501, 4.9703155],
          [118.2585462, 4.9705902],
          [118.2580924, 4.970611],
          [118.2578968, 4.97079],
          [118.2581965, 4.9712313],
          [118.2583505, 4.9715852],
          [118.2584213, 4.9721597],
          [118.2587627, 4.9726426],
          [118.2586211, 4.9728049],
          [118.2584504, 4.9731255],
          [118.2580175, 4.9732379],
          [118.2577844, 4.9732795],
          [118.2572265, 4.9733544],
          [118.2569185, 4.9730963],
          [118.2565729, 4.9726675],
          [118.2560609, 4.9726717],
          [118.2556612, 4.9725676],
          [118.2552658, 4.9723137],
          [118.2548453, 4.9720722],
          [118.2548578, 4.9717933],
          [118.2548411, 4.9712605],
          [118.2548078, 4.9709191],
          [118.2546663, 4.9707526],
          [118.2543582, 4.97079],
          [118.2538878, 4.9707484],
          [118.2533383, 4.9706318],
          [118.2527888, 4.9706651],
          [118.2525099, 4.9709482],
          [118.2523267, 4.9714103],
          [118.2521935, 4.9717225],
          [118.2518854, 4.9717933],
          [118.2513859, 4.9718766],
          [118.2509113, 4.97181],
          [118.2504284, 4.9714686],
          [118.2502618, 4.970994],
          [118.2504575, 4.9707526],
          [118.2508412, 4.9707112],
          [118.2509825, 4.9704992],
          [118.2508883, 4.9700871],
          [118.2505351, 4.9702049],
          [118.2503349, 4.9700165],
          [118.2500876, 4.9696044],
          [118.2495695, 4.9695337],
          [118.24944, 4.9701342],
          [118.2491103, 4.9704757],
          [118.2483567, 4.9706759],
          [118.2476031, 4.9705228],
          [118.2470968, 4.9701813],
          [118.2461431, 4.9699341],
          [118.2449584, 4.9699229],
          [118.2446804, 4.9700309],
          [118.2440601, 4.9703556],
          [118.2434482, 4.9708718],
          [118.2431068, 4.9715087],
          [118.2427821, 4.9722581],
          [118.2425823, 4.9730615],
          [118.2424116, 4.9734237],
          [118.2421493, 4.9736568],
          [118.2421368, 4.9738941],
          [118.2418995, 4.9741272],
          [118.2414291, 4.9740773],
          [118.241146, 4.9740065],
          [118.2407589, 4.9739732],
          [118.2405799, 4.9742896],
          [118.2402468, 4.974248],
          [118.2399929, 4.9741397],
          [118.2399471, 4.9742063],
          [118.2399263, 4.9745061],
          [118.239893, 4.9746851],
          [118.2395017, 4.9746518],
          [118.2392436, 4.974606],
          [118.2388689, 4.9748058],
          [118.2386108, 4.9751305],
          [118.2383568, 4.9753845],
          [118.2381695, 4.9758549],
          [118.2380321, 4.9762337],
          [118.2377948, 4.9763128],
          [118.2374826, 4.9766417],
          [118.2371621, 4.9769372],
          [118.2370788, 4.9770871],
          [118.237083, 4.9771662],
          [118.2367999, 4.9773119],
          [118.2364214, 4.9775868],
          [118.2363154, 4.9778046],
          [118.2360534, 4.9780577],
          [118.2358797, 4.9780784],
          [118.2357855, 4.9779341],
          [118.2354058, 4.9773424],
          [118.2351203, 4.9768479],
          [118.234979, 4.9764741],
          [118.2346787, 4.9761561],
          [118.2341989, 4.976424],
          [118.2340458, 4.9762327],
          [118.2339916, 4.9759653],
          [118.234283, 4.9756209],
          [118.2343625, 4.9752676],
          [118.2340554, 4.9749772],
          [118.2332438, 4.9745996],
          [118.2325726, 4.973952],
          [118.2324431, 4.9732691],
          [118.2335499, 4.9733515],
          [118.2348687, 4.9737283],
          [118.2351661, 4.9731459],
          [118.2356032, 4.972588],
          [118.2359862, 4.9720926],
          [118.2364358, 4.9716847],
          [118.2366707, 4.971545],
          [118.2374891, 4.9717216],
          [118.2380013, 4.9718394],
          [118.2384016, 4.9716804],
          [118.238484, 4.9713802],
          [118.2382721, 4.9708915],
          [118.2376951, 4.9705206],
          [118.2373772, 4.9703558],
          [118.237024, 4.9703852],
          [118.2367414, 4.9706443],
          [118.2364647, 4.9707385],
          [118.2358112, 4.9702498],
          [118.2352973, 4.9704722],
          [118.2338025, 4.9692062],
          [118.2338025, 4.9683198],
          [118.2329161, 4.9673448],
          [118.2314979, 4.9675221],
          [118.2311434, 4.966813],
          [118.2291048, 4.9674335],
          [118.2288389, 4.9644198],
          [118.227598, 4.9645971],
          [118.2268889, 4.9659267],
          [118.2253865, 4.9659051],
          [118.2238823, 4.9644636],
          [118.2243882, 4.9620337],
          [118.2237677, 4.9614133],
          [118.2230586, 4.9621224],
          [118.2218177, 4.9615019],
          [118.2221858, 4.9599479],
          [118.2219859, 4.9599437],
          [118.2218923, 4.9597085],
          [118.2216092, 4.9594899],
          [118.2214926, 4.9596835],
          [118.2213053, 4.9599062],
          [118.2209598, 4.9599312],
          [118.220633, 4.9598126],
          [118.2203957, 4.9598979],
          [118.2196548, 4.9599058],
          [118.2196254, 4.9593171],
          [118.2189955, 4.9591699],
          [118.2188659, 4.958746],
          [118.2180888, 4.9579983],
          [118.2179067, 4.958107],
          [118.2177625, 4.9584985],
          [118.2175564, 4.9589871],
          [118.2175241, 4.9596436],
          [118.2171325, 4.9596583],
          [118.2168735, 4.9595552],
          [118.2169147, 4.9591402],
          [118.2168146, 4.9589312],
          [118.2166056, 4.9588193],
          [118.2165909, 4.9586545],
          [118.2160228, 4.9584985],
          [118.2155871, 4.9582777],
          [118.2153241, 4.9580816],
          [118.2151597, 4.9581121],
          [118.2150592, 4.9582278],
          [118.2150592, 4.9584928],
          [118.2152724, 4.9589557],
          [118.2152906, 4.959175],
          [118.215315, 4.9593729],
          [118.2150622, 4.9594186],
          [118.2149952, 4.9596836],
          [118.2145049, 4.9599912],
          [118.214176, 4.9602013],
          [118.2138806, 4.9603109],
          [118.2135425, 4.9602439],
          [118.2131709, 4.9601617],
          [118.2129821, 4.960183],
          [118.2129513, 4.9605563],
          [118.2131604, 4.9611369],
          [118.2133178, 4.963006],
          [118.213335, 4.9632558],
          [118.2132661, 4.9637554],
          [118.2129818, 4.9637813],
          [118.2129043, 4.9633936],
          [118.212844, 4.9628337],
          [118.212844, 4.9624805],
          [118.2127665, 4.9621015],
          [118.2124564, 4.9618862],
          [118.2122582, 4.9620929],
          [118.2125511, 4.9633333],
          [118.2123185, 4.9637985],
          [118.2119309, 4.9638157],
          [118.2121893, 4.9631438],
          [118.2117586, 4.9625667],
          [118.2113365, 4.9621532],
          [118.2103029, 4.9615933],
          [118.2092606, 4.9610678],
          [118.2093811, 4.9603959],
          [118.2087954, 4.9600255],
          [118.2075894, 4.9606199],
          [118.2073827, 4.9604218],
          [118.2072018, 4.9598705],
          [118.2072793, 4.9593278],
          [118.2069627, 4.9587212],
          [118.2066656, 4.9584175],
          [118.2064028, 4.9580837],
          [118.2063447, 4.9578447],
          [118.206896, 4.9574312],
          [118.2070721, 4.9573017],
          [118.2071765, 4.9571908],
          [118.207335, 4.9571166],
          [118.2073989, 4.957039],
          [118.2074002, 4.9569953],
          [118.2071762, 4.9570298],
          [118.2069566, 4.9571762],
          [118.206724, 4.9572365],
          [118.2065388, 4.9571633],
          [118.2068144, 4.9570642],
          [118.2068144, 4.9568532],
          [118.2063493, 4.956444],
          [118.2059703, 4.9557592],
          [118.2058152, 4.9553026],
          [118.2049667, 4.9548289],
          [118.2045748, 4.9549193],
          [118.2043465, 4.9547384],
          [118.2044782, 4.9544843],
          [118.204312, 4.9543774],
          [118.2042618, 4.9543566],
          [118.2037197, 4.9544419],
          [118.2033603, 4.9543871],
          [118.2034029, 4.9538876],
          [118.2038841, 4.9535404],
          [118.203939, 4.9531202],
          [118.2037258, 4.9521578],
          [118.2038523, 4.9513446],
          [118.2040419, 4.9500525],
          [118.2039637, 4.9490438],
          [118.2036795, 4.9485312],
          [118.2028654, 4.9484839],
          [118.2026802, 4.9479412],
          [118.2021418, 4.9476268],
          [118.2016078, 4.9476655],
          [118.201358, 4.9478033],
          [118.2008756, 4.9477172],
          [118.2005267, 4.9479756],
          [118.2002123, 4.9478593],
          [118.1999237, 4.9476871],
          [118.1995619, 4.9477431],
          [118.1992734, 4.9478636],
          [118.1989891, 4.9479713],
          [118.1988082, 4.9480144],
          [118.1985153, 4.9484494],
          [118.1980975, 4.9484494],
          [118.1977013, 4.9487466],
          [118.1974472, 4.9489361],
          [118.1970854, 4.9491471],
          [118.1969346, 4.9492376],
          [118.1964221, 4.9489792],
          [118.195944, 4.9490998],
          [118.1954056, 4.9488026],
          [118.1951774, 4.9482427],
          [118.1949663, 4.9479196],
          [118.1946691, 4.9477086],
          [118.1944366, 4.9475837],
          [118.194316, 4.9474545],
          [118.1946433, 4.9468946],
          [118.1947811, 4.9461882],
          [118.194962, 4.9453914],
          [118.1954186, 4.9451158],
          [118.1954918, 4.9446161],
          [118.195509, 4.9439916],
          [118.1953238, 4.9436815],
          [118.1953496, 4.9433757],
          [118.1960948, 4.9429838],
          [118.1964264, 4.9427684],
          [118.1965125, 4.9423937],
          [118.1966116, 4.9420405],
          [118.1964092, 4.9418338],
          [118.1961508, 4.9417864],
          [118.1953152, 4.942187],
          [118.1947424, 4.9422946],
          [118.1944495, 4.9427383],
          [118.1947424, 4.9433843],
          [118.1947467, 4.9436686],
          [118.1945787, 4.9440821],
          [118.1943978, 4.9442845],
          [118.1940231, 4.9444525],
          [118.1940188, 4.9448832],
          [118.1938594, 4.9451459],
          [118.193545, 4.9452234],
          [118.1931358, 4.9451244],
          [118.1929291, 4.9449952],
          [118.192774, 4.9446291],
          [118.1924725, 4.9442888],
          [118.1924725, 4.9438926],
          [118.1926491, 4.94366],
          [118.1928645, 4.9433197],
          [118.1925372, 4.9427383],
          [118.1922012, 4.942678],
          [118.1916413, 4.9426823],
          [118.1914499, 4.942569],
          [118.1913051, 4.942293],
          [118.1911985, 4.9420935],
          [118.1912067, 4.9418831],
          [118.1909512, 4.9417465],
          [118.1906322, 4.9421145],
          [118.1903125, 4.9419342],
          [118.1905038, 4.9413003],
          [118.1904409, 4.9407319],
          [118.1901103, 4.9401417],
          [118.1899983, 4.9398001],
          [118.1899846, 4.9393192],
          [118.1897442, 4.9390213],
          [118.189878, 4.9387836],
          [118.1900119, 4.938606],
          [118.1897387, 4.9384393],
          [118.1897851, 4.9381005],
          [118.1898343, 4.9377398],
          [118.1894135, 4.9377234],
          [118.1891184, 4.9373873],
          [118.188796, 4.9372452],
          [118.1885173, 4.937196],
          [118.1881292, 4.9368736],
          [118.1876527, 4.9367747],
          [118.1878034, 4.9365351],
          [118.1875754, 4.9362762],
          [118.1872238, 4.9358859],
          [118.1866441, 4.9357391],
          [118.1863118, 4.9359516],
          [118.1858249, 4.9354995],
          [118.1849477, 4.9350319],
          [118.1847351, 4.9346145],
          [118.184665590197554, 4.934534132480621],
          [118.1841903, 4.9339846],
          [118.1838889, 4.9333393],
          [118.1834792, 4.9328988],
          [118.1832319, 4.9327867],
          [118.1832667, 4.9324814],
          [118.1833285, 4.9322302],
          [118.1832474, 4.9321452],
          [118.1833826, 4.9319172],
          [118.1832435, 4.9313646],
          [118.1830696, 4.9312294],
          [118.1833903, 4.9310361],
          [118.1835411, 4.9306381],
          [118.1834815, 4.9300907],
          [118.1835007, 4.9299486],
          [118.1837083, 4.9297027],
          [118.1838012, 4.9294158],
          [118.1840224, 4.9289433],
          [118.1840592, 4.9288429],
          [118.1840514, 4.9285878],
          [118.1839316, 4.9284004],
          [118.1838331, 4.9282671],
          [118.1839181, 4.9281956],
          [118.1841577, 4.9281125],
          [118.1842466, 4.9279734],
          [118.1844185, 4.9278884],
          [118.1845306, 4.9277821],
          [118.1846156, 4.9277125],
          [118.1848475, 4.9276391],
          [118.185004, 4.9275232],
          [118.185203, 4.927442],
          [118.1855141, 4.9274343],
          [118.1856976, 4.9274053],
          [118.185829, 4.9271541],
          [118.1861614, 4.9266015],
          [118.186314, 4.926418],
          [118.1863159, 4.9262943],
          [118.1866311, 4.9266331],
          [118.1872493, 4.9267722],
          [118.1881459, 4.9270737],
          [118.1886637, 4.927576],
          [118.1888025, 4.9278513],
          [118.1889746, 4.9279715],
          [118.1891495, 4.9280754],
          [118.1894064, 4.9280344],
          [118.1897397, 4.9280371],
          [118.189819, 4.9278349],
          [118.1895375, 4.9274797],
          [118.1890866, 4.9270479],
          [118.1888025, 4.9266845],
          [118.1887861, 4.9264823],
          [118.1882751, 4.9260806],
          [118.1879636, 4.9258101],
          [118.1878871, 4.9255587],
          [118.1879253, 4.9252035],
          [118.1879226, 4.9250915],
          [118.1878215, 4.9249712],
          [118.1881658, 4.9248756],
          [118.1885784, 4.9246297],
          [118.1889227, 4.9245914],
          [118.189338, 4.9246379],
          [118.1895457, 4.9245586],
          [118.1897479, 4.9246597],
          [118.1902042, 4.9247554],
          [118.1905813, 4.9249767],
          [118.1908245, 4.9254112],
          [118.191147, 4.9256079],
          [118.1915869, 4.9256871],
          [118.1918519, 4.9258593],
          [118.1919913, 4.9260014],
          [118.1922154, 4.9259877],
          [118.1923739, 4.9257227],
          [118.1924476, 4.9255614],
          [118.1925788, 4.9254248],
          [118.1931308, 4.9253729],
          [118.1933466, 4.9253647],
          [118.1936663, 4.9255888],
          [118.1937702, 4.9257719],
          [118.1945817, 4.9262227],
          [118.1948714, 4.926373],
          [118.1949998, 4.9265206],
          [118.1952129, 4.926537],
          [118.1952785, 4.9264441],
          [118.1951883, 4.9262692],
          [118.1951446, 4.9260451],
          [118.1953386, 4.9256981],
          [118.1954261, 4.9254877],
          [118.1956341, 4.9252015],
          [118.1955553, 4.9248263],
          [118.1956537, 4.9243454],
          [118.195375, 4.9240667],
          [118.1949378, 4.9238317],
          [118.1949159, 4.9234928],
          [118.1947355, 4.9231595],
          [118.1945661, 4.9229026],
          [118.1942109, 4.9229081],
          [118.1940251, 4.9227441],
          [118.1936261, 4.9222468],
          [118.193413, 4.9219517],
          [118.1929922, 4.9216074],
          [118.192943, 4.9213451],
          [118.192402, 4.9213451],
          [118.1920761, 4.9212541],
          [118.1915911, 4.9210937],
          [118.1915332, 4.9208136],
          [118.1911119, 4.9206319],
          [118.1907893, 4.9206242],
          [118.1903383, 4.9206493],
          [118.1901064, 4.9207459],
          [118.1897895, 4.9205411],
          [118.1894572, 4.9203518],
          [118.1888582, 4.9201315],
          [118.1881008, 4.9203981],
          [118.1873627, 4.9200851],
          [118.1869145, 4.9200658],
          [118.1862266, 4.9195518],
          [118.1858208, 4.9188331],
          [118.1857397, 4.9187133],
          [118.185361, 4.9180293],
          [118.185218, 4.9174728],
          [118.1852528, 4.9171212],
          [118.1854035, 4.9169743],
          [118.1858054, 4.9168468],
          [118.1859484, 4.9166961],
          [118.1859909, 4.9165145],
          [118.1856817, 4.916441],
          [118.1851832, 4.9166304],
          [118.1847813, 4.9169395],
          [118.1846074, 4.9172873],
          [118.1841128, 4.9174535],
          [118.184051, 4.9176544],
          [118.1835911, 4.9176351],
          [118.1830887, 4.9174921],
          [118.1826714, 4.917098],
          [118.18237, 4.9167231],
          [118.1821072, 4.9165261],
          [118.1818406, 4.9167579],
          [118.1813807, 4.9168584],
          [118.1811798, 4.9167115],
          [118.1811218, 4.9167579],
          [118.1810426, 4.9169048],
          [118.181031, 4.9169936],
          [118.1806349, 4.9169627],
          [118.1802987, 4.9166884],
          [118.1799779, 4.916271],
          [118.179804, 4.9157609],
          [118.1798949, 4.9154846],
          [118.1798021, 4.9150267],
          [118.1800726, 4.9147678],
          [118.1803566, 4.9145475],
          [118.1804243, 4.9144122],
          [118.1803798, 4.9143002],
          [118.1801074, 4.9142461],
          [118.1796785, 4.9144606],
          [118.1794234, 4.9145668],
          [118.1791548, 4.9144876],
          [118.1789558, 4.9142152],
          [118.1788863, 4.9139968],
          [118.1787781, 4.9138191],
          [118.1785964, 4.9137572],
          [118.1782989, 4.9139157],
          [118.1779685, 4.9139794],
          [118.1777096, 4.9139833],
          [118.1774139, 4.913906],
          [118.1771995, 4.9135041],
          [118.1771009, 4.9134017],
          [118.1767937, 4.9134152],
          [118.1764324, 4.9133979],
          [118.1761348, 4.9134404],
          [118.1757677, 4.9136027],
          [118.1755127, 4.913423],
          [118.1754041, 4.9129483],
          [118.1750708, 4.9126586],
          [118.1746992, 4.9120903],
          [118.1747229, 4.910985],
          [118.1746546, 4.9108375],
          [118.1744907, 4.9107282],
          [118.1748377, 4.9104249],
          [118.1749552, 4.9100697],
          [118.1748076, 4.9097472],
          [118.174447, 4.9096598],
          [118.174212, 4.9094794],
          [118.1740535, 4.9093865],
          [118.1742611, 4.9092444],
          [118.1742529, 4.9090942],
          [118.1739715, 4.9088947],
          [118.17366, 4.9086242],
          [118.1734592, 4.9084975],
          [118.1731368, 4.9085412],
          [118.1728253, 4.9085248],
          [118.1726395, 4.908809],
          [118.1725684, 4.9097271],
          [118.1725793, 4.9104321],
          [118.1727925, 4.9109294],
          [118.1730439, 4.9114868],
          [118.1730002, 4.9118257],
          [118.172787, 4.9122738],
          [118.1729018, 4.9127493],
          [118.1732133, 4.9132466],
          [118.1734374, 4.913722],
          [118.1735576, 4.9141264],
          [118.1731696, 4.9143833],
          [118.1729947, 4.9145636],
          [118.1727542, 4.9146019],
          [118.1721094, 4.9144707],
          [118.1713989, 4.9145691],
          [118.1711967, 4.9144981],
          [118.171, 4.9142084],
          [118.170683, 4.9137603],
          [118.1700436, 4.9133723],
          [118.1693113, 4.913028],
          [118.1683549, 4.9129187],
          [118.1672564, 4.9126673],
          [118.166546, 4.9121317],
          [118.1658738, 4.9117],
          [118.1654256, 4.9114704],
          [118.1649666, 4.9113666],
          [118.1647917, 4.9115524],
          [118.1643272, 4.9112245],
          [118.1639064, 4.9111371],
          [118.1638572, 4.9109458],
          [118.1638954, 4.9108037],
          [118.163573, 4.9105141],
          [118.1630647, 4.9104102],
          [118.162633, 4.9102681],
          [118.1627419, 4.9102209],
          [118.1629331, 4.9101861],
          [118.1631302, 4.9102325],
          [118.1632751, 4.9102982],
          [118.1633775, 4.9103021],
          [118.1634703, 4.9102228],
          [118.1635379, 4.9100258],
          [118.1635089, 4.9098615],
          [118.1634452, 4.9097533],
          [118.1633621, 4.909535],
          [118.1633157, 4.909448],
          [118.1634104, 4.9091853],
          [118.1633911, 4.908965],
          [118.1632404, 4.9088761],
          [118.1633003, 4.9087776],
          [118.1632462, 4.9086868],
          [118.1632072, 4.9085147],
          [118.1632381, 4.9081244],
          [118.1634429, 4.9075718],
          [118.1637018, 4.907336],
          [118.1636709, 4.9071042],
          [118.1637868, 4.9068453],
          [118.1640303, 4.9068453],
          [118.1643356, 4.9063545],
          [118.1646872, 4.9057091],
          [118.1652901, 4.9053613],
          [118.1658427, 4.9053072],
          [118.1660939, 4.9054811],
          [118.1662793, 4.9056125],
          [118.1665537, 4.9055739],
          [118.1666851, 4.9054811],
          [118.1667469, 4.9052261],
          [118.1665808, 4.9049092],
          [118.1664223, 4.9047315],
          [118.1661596, 4.9040397],
          [118.1660475, 4.9036417],
          [118.1659779, 4.9033944],
          [118.1657693, 4.9033287],
          [118.1656765, 4.9032089],
          [118.1655876, 4.9030659],
          [118.1653442, 4.9031007],
          [118.1650157, 4.9029036],
          [118.1648689, 4.9027916],
          [118.1646679, 4.9026602],
          [118.1643935, 4.9026447],
          [118.1640883, 4.9027297],
          [118.1640767, 4.9026718],
          [118.1641403, 4.9026277],
          [118.1643097, 4.902582],
          [118.1644204, 4.9025574],
          [118.1645352, 4.9024932],
          [118.164598, 4.9024112],
          [118.1646228, 4.9021172],
          [118.1646344, 4.9020824],
          [118.1645765, 4.9020148],
          [118.1646847, 4.9018448],
          [118.1646866, 4.9017501],
          [118.1645939, 4.9016941],
          [118.1645185, 4.901669],
          [118.1645919, 4.9015801],
          [118.1646615, 4.9014757],
          [118.1646267, 4.9012014],
          [118.1645398, 4.901097],
          [118.1645823, 4.9010101],
          [118.164476, 4.9006855],
          [118.1643601, 4.9005193],
          [118.1642364, 4.9003493],
          [118.1640741, 4.9002952],
          [118.1639601, 4.9000846],
          [118.1638577, 4.8999976],
          [118.1639311, 4.8997986],
          [118.1638828, 4.8996421],
          [118.1638287, 4.8994682],
          [118.163877, 4.8992325],
          [118.1637881, 4.8989407],
          [118.1636915, 4.8988963],
          [118.1636316, 4.89879],
          [118.1634945, 4.8987649],
          [118.1633843, 4.8984925],
          [118.163193, 4.8981408],
          [118.1629515, 4.8980519],
          [118.1629631, 4.8976751],
          [118.1622971, 4.8962448],
          [118.162668, 4.8955183],
          [118.1627917, 4.8945909],
          [118.1629231, 4.8938335],
          [118.1617251, 4.8940499],
          [118.1610759, 4.8937562],
          [118.160419, 4.8926355],
          [118.1600248, 4.8911748],
          [118.1600094, 4.8902551],
          [118.1595456, 4.8892967],
          [118.1589196, 4.8891344],
          [118.1584559, 4.8885393],
          [118.1580617, 4.8879751],
          [118.1578762, 4.8873182],
          [118.1577217, 4.8849609],
          [118.1586491, 4.8845668],
          [118.158765, 4.8836857],
          [118.1593988, 4.8823022],
          [118.1595843, 4.8816685],
          [118.1594452, 4.8814366],
          [118.1589273, 4.8811275],
          [118.1582395, 4.8808106],
          [118.1581236, 4.8804087],
          [118.1574434, 4.8803392],
          [118.1571961, 4.8796281],
          [118.157312, 4.8793963],
          [118.1566937, 4.878778],
          [118.1561991, 4.8784688],
          [118.1556967, 4.8784611],
          [118.1551325, 4.8783065],
          [118.1553412, 4.8786852],
          [118.1549393, 4.8788552],
          [118.1545452, 4.8790253],
          [118.1543365, 4.8796668],
          [118.1540969, 4.8798213],
          [118.1540814, 4.8803469],
          [118.1534941, 4.8808029],
          [118.1528062, 4.880857],
          [118.1531926, 4.8813284],
          [118.1526053, 4.8811739],
          [118.1509436, 4.8822868],
          [118.1513146, 4.8827196],
          [118.15133, 4.883222],
          [118.1518556, 4.8835234],
          [118.1523889, 4.8834538],
          [118.1529221, 4.8830674],
          [118.1531849, 4.8828974],
          [118.1533086, 4.8831447],
          [118.1532236, 4.8840567],
          [118.1534322, 4.8851851],
          [118.1537878, 4.8855947],
          [118.1542437, 4.8862593],
          [118.1536541, 4.8856646],
          [118.1533457, 4.8857432],
          [118.1531574, 4.8860257],
          [118.1529358, 4.8864024],
          [118.1526532, 4.8867625],
          [118.1520827, 4.8874661],
          [118.1514013, 4.8878428],
          [118.1510024, 4.8880699],
          [118.1509082, 4.8882306],
          [118.1510167, 4.8883203],
          [118.1510971, 4.888319],
          [118.1512079, 4.8883162],
          [118.1512771, 4.8883605],
          [118.1509987, 4.8884948],
          [118.1509253, 4.8885489],
          [118.1508575, 4.8886555],
          [118.1508104, 4.8887885],
          [118.150755, 4.8888092],
          [118.1507591, 4.8887303],
          [118.1507716, 4.8884796],
          [118.1507148, 4.8884242],
          [118.1506511, 4.8883702],
          [118.1505434, 4.8883213],
          [118.1503554, 4.8882566],
          [118.1503867, 4.8881607],
          [118.1503534, 4.8880627],
          [118.1501105, 4.8879472],
          [118.1499049, 4.8878532],
          [118.149756, 4.887765],
          [118.1496757, 4.8876965],
          [118.1495719, 4.8877004],
          [118.1491978, 4.8879296],
          [118.149002, 4.887814],
          [118.1488198, 4.887765],
          [118.1482533, 4.8876906],
          [118.1479086, 4.8874086],
          [118.1476187, 4.8871109],
          [118.1475326, 4.8868288],
          [118.1479164, 4.886962],
          [118.1482768, 4.8868523],
          [118.1484335, 4.8865155],
          [118.1484413, 4.8860219],
          [118.1475361, 4.8854271],
          [118.1474032, 4.8852886],
          [118.1466996, 4.884934],
          [118.1450709, 4.8845296],
          [118.1438522, 4.8843745],
          [118.1434201, 4.8840255],
          [118.1425171, 4.8837098],
          [118.1419465, 4.882635],
          [118.1406075, 4.881456],
          [118.1403803, 4.8813072],
          [118.1399847, 4.8813542],
          [118.1395851, 4.8820436],
          [118.1388095, 4.8825842],
          [118.1384648, 4.8827174],
          [118.1374111, 4.8823374],
          [118.1369489, 4.8823726],
          [118.1365258, 4.881836],
          [118.1362046, 4.8818164],
          [118.1355779, 4.8817733],
          [118.1350882, 4.8818908],
          [118.1347631, 4.8822003],
          [118.1344184, 4.8823335],
          [118.1341598, 4.8821807],
          [118.1343283, 4.8813816],
          [118.1343792, 4.8811583],
          [118.1339052, 4.8812171],
          [118.1334861, 4.8814325],
          [118.1332158, 4.8814874],
          [118.1330944, 4.8812758],
          [118.1330434, 4.880653],
          [118.1328319, 4.8799675],
          [118.132683, 4.879709],
          [118.1326439, 4.8794896],
          [118.132448, 4.8793133],
          [118.1322757, 4.8790665],
          [118.1320328, 4.8789334],
          [118.1318134, 4.8790117],
          [118.1315627, 4.8788002],
          [118.1308968, 4.8788824],
          [118.1304228, 4.879141],
          [118.1302231, 4.8794818],
          [118.1301604, 4.8796972],
          [118.1295493, 4.8796972],
          [118.1290087, 4.8793799],
          [118.1286366, 4.879043],
          [118.1275868, 4.8792389],
          [118.1272146, 4.8794778],
          [118.1270775, 4.8797403],
          [118.1269992, 4.8799283],
          [118.1268621, 4.8797599],
          [118.1267994, 4.8796384],
          [118.1261492, 4.8799009],
          [118.125392, 4.8804172],
          [118.1250708, 4.8810753],
          [118.1250747, 4.8815023],
          [118.1252392, 4.882082],
          [118.1256701, 4.8827244],
          [118.1262107, 4.8832337],
          [118.1266337, 4.8834726],
          [118.1274955, 4.8836411],
          [118.1282045, 4.8839349],
          [118.1287177, 4.8844245],
          [118.1288195, 4.884828],
          [118.1288822, 4.8853215],
          [118.1288548, 4.8856584],
          [118.1287013, 4.8860159],
          [118.1284576, 4.8862596],
          [118.1284465, 4.8865089],
          [118.1286274, 4.8866053],
          [118.1288487, 4.8866915],
          [118.1289701, 4.8867395],
          [118.1291321, 4.8867359],
          [118.1290802, 4.8868079],
          [118.1289396, 4.8869581],
          [118.1288191, 4.8870856],
          [118.128763, 4.88717],
          [118.1287367, 4.8871936],
          [118.1287596, 4.8871091],
          [118.1288094, 4.8869775],
          [118.1288357, 4.8868641],
          [118.1287489, 4.8867757],
          [118.1284649, 4.8866249],
          [118.1281972, 4.8864978],
          [118.1280591, 4.8863617],
          [118.1279099, 4.8863865],
          [118.1270873, 4.8864649],
          [118.1264811, 4.8865434],
          [118.12587, 4.8866726],
          [118.1250513, 4.8867862],
          [118.124354, 4.886657],
          [118.1237077, 4.8863475],
          [118.1229674, 4.8859166],
          [118.121898, 4.8855406],
          [118.1211772, 4.8852938],
          [118.1206405, 4.8849882],
          [118.1201391, 4.8845887],
          [118.1199198, 4.8841931],
          [118.1197161, 4.8838758],
          [118.1195046, 4.8834214],
          [118.1192382, 4.8829278],
          [118.1190032, 4.8826771],
          [118.1192304, 4.8825008],
          [118.1195947, 4.882967],
          [118.1198767, 4.8836446],
          [118.1200647, 4.8839737],
          [118.1203938, 4.8844046],
          [118.1209187, 4.884859],
          [118.1220311, 4.8852703],
          [118.1230418, 4.8856111],
          [118.1240015, 4.8860968],
          [118.1245068, 4.886371],
          [118.1250983, 4.886418],
          [118.1259993, 4.8863632],
          [118.1265986, 4.8863044],
          [118.1273263, 4.8862259],
          [118.1278943, 4.8860653],
          [118.1281862, 4.8858331],
          [118.1284161, 4.885443],
          [118.1283769, 4.8850904],
          [118.1283103, 4.8847966],
          [118.1281575, 4.8845303],
          [118.1277619, 4.8843344],
          [118.1269197, 4.8841151],
          [118.1257445, 4.883551],
          [118.1251491, 4.8829086],
          [118.1246086, 4.8822426],
          [118.1241463, 4.8817569],
          [118.1237311, 4.8807854],
          [118.1233159, 4.8798766],
          [118.1232191, 4.8796742],
          [118.1228509, 4.8795096],
          [118.1221301, 4.8793686],
          [118.1218559, 4.8791649],
          [118.12166, 4.8788515],
          [118.1216679, 4.8783893],
          [118.1219891, 4.8778801],
          [118.1221849, 4.8779663],
          [118.1220439, 4.8782718],
          [118.1220361, 4.8786714],
          [118.1221928, 4.8790631],
          [118.1224356, 4.8790709],
          [118.1228665, 4.8790866],
          [118.1232896, 4.8792824],
          [118.1238486, 4.8794614],
          [118.1245537, 4.8794458],
          [118.1250081, 4.879007],
          [118.1248486, 4.8759293],
          [118.1244883, 4.875044],
          [118.1242611, 4.8737592],
          [118.1240574, 4.8729836],
          [118.1254127, 4.8721845],
          [118.1258984, 4.8715656],
          [118.1267602, 4.8700144],
          [118.1272068, 4.8689881],
          [118.1270344, 4.8678991],
          [118.1271114, 4.8675391],
          [118.1286847, 4.8676277],
          [118.1290725, 4.8664865],
          [118.1307344, 4.8651237],
          [118.132126, 4.8648343],
          [118.1336098, 4.8649819],
          [118.1351419, 4.8655923],
          [118.1358988, 4.8655222],
          [118.1362062, 4.8651321],
          [118.1361461, 4.8646964],
          [118.1363848, 4.8641833],
          [118.1354503, 4.8639909],
          [118.1350941, 4.8640684],
          [118.1347755, 4.8632201],
          [118.1338008, 4.8623841],
          [118.1329196, 4.8608059],
          [118.1316787, 4.8582908],
          [118.1314128, 4.856784],
          [118.1312454, 4.8547541],
          [118.1312232, 4.8530479],
          [118.1316221, 4.8514303],
          [118.1324863, 4.8504996],
          [118.1330685, 4.8501991],
          [118.1330567, 4.8498465],
          [118.1327786, 4.8492394],
          [118.1323242, 4.8490513],
          [118.1306692, 4.84917],
          [118.1295834, 4.8496354],
          [118.129384, 4.8491479],
          [118.1302481, 4.8487576],
          [118.1306633, 4.8486635],
          [118.1318166, 4.8483709],
          [118.1328802, 4.8478169],
          [118.133412, 4.8465095],
          [118.1330575, 4.8439612],
          [118.1318609, 4.841701],
          [118.1315063, 4.8406374],
          [118.1303984, 4.8402164],
          [118.1293569, 4.839884],
          [118.1288694, 4.8382885],
          [118.1286257, 4.83738],
          [118.1294899, 4.8373135],
          [118.1294999, 4.8374114],
          [118.1293902, 4.8376386],
          [118.1293785, 4.8379403],
          [118.1294176, 4.8383437],
          [118.1295195, 4.838708],
          [118.1296683, 4.8390723],
          [118.1299582, 4.8393309],
          [118.1305223, 4.8395306],
          [118.1311608, 4.8395894],
          [118.1316896, 4.8396129],
          [118.1318659, 4.8397696],
          [118.133186, 4.8400046],
          [118.133609, 4.840032],
          [118.1336208, 4.8401378],
          [118.1347724, 4.8401261],
          [118.1359946, 4.8399615],
          [118.1361474, 4.8398166],
          [118.1361944, 4.8394405],
          [118.1363824, 4.8393896],
          [118.1364372, 4.8396364],
          [118.1363785, 4.8400673],
          [118.136778, 4.840079],
          [118.1378435, 4.8399576],
          [118.1385525, 4.8398754],
          [118.1397943, 4.8401221],
          [118.1414082, 4.8401809],
          [118.1429582, 4.8394252],
          [118.1440107, 4.8386275],
          [118.1441104, 4.8383616],
          [118.1436451, 4.83814],
          [118.142936, 4.8379073],
          [118.1421826, 4.837309],
          [118.1419278, 4.8367107],
          [118.1420607, 4.8360238],
          [118.1419666, 4.835522],
          [118.1424153, 4.8352117],
          [118.1425704, 4.8349569],
          [118.1430191, 4.8342977],
          [118.1433515, 4.8335997],
          [118.1433293, 4.8329903],
          [118.1432629, 4.8325582],
          [118.1427144, 4.8319488],
          [118.1425593, 4.8314946],
          [118.1428806, 4.8313284],
          [118.1432019, 4.8318103],
          [118.1435011, 4.8322812],
          [118.1436728, 4.8327466],
          [118.1437116, 4.8329737],
          [118.1437005, 4.8332784],
          [118.1437337, 4.8335997],
          [118.1437393, 4.8340207],
          [118.1436728, 4.8343143],
          [118.1434678, 4.8348129],
          [118.1432352, 4.8353115],
          [118.1429471, 4.8357823],
          [118.1427587, 4.8359485],
          [118.1426313, 4.836292],
          [118.1425593, 4.8366742],
          [118.1426701, 4.8370288],
          [118.1431742, 4.8371728],
          [118.1441603, 4.8373999],
          [118.1447863, 4.83776],
          [118.1456893, 4.8383916],
          [118.1462986, 4.8387516],
          [118.147185, 4.838386],
          [118.1483151, 4.8376437],
          [118.1496003, 4.8367463],
          [118.1504756, 4.8367241],
          [118.151229, 4.8372559],
          [118.1515503, 4.837134],
          [118.1514949, 4.8361147],
          [118.1517387, 4.8359485],
          [118.1518495, 4.8365579],
          [118.1524256, 4.8370011],
          [118.1522476, 4.8372613],
          [118.1525159, 4.8390524],
          [118.1532693, 4.8398723],
          [118.1542443, 4.8403377],
          [118.1556403, 4.8403377],
          [118.1594295, 4.8398945],
          [118.1643267, 4.8389638],
          [118.1667642, 4.8385871],
          [118.1674496, 4.8379853],
          [118.1676727, 4.8377894],
          [118.1677054, 4.8377744],
          [118.1674289, 4.8367479],
          [118.1650099, 4.8343036],
          [118.1656367, 4.8328621],
          [118.1662321, 4.8307624],
          [118.1670782, 4.829885],
          [118.1674934, 4.8289051],
          [118.1669726, 4.8285616],
          [118.1668286, 4.8280409],
          [118.1673715, 4.827642],
          [118.1675488, 4.827221],
          [118.1671942, 4.8268776],
          [118.1673826, 4.8260688],
          [118.1675598, 4.8256367],
          [118.1679698, 4.8250938],
          [118.1685127, 4.8249608],
          [118.1693104, 4.8235094],
          [118.1697647, 4.8221023],
          [118.1704516, 4.8204736],
          [118.1720692, 4.8185458],
          [118.1723129, 4.8179032],
          [118.1733877, 4.8172495],
          [118.1740728, 4.8169854],
          [118.1748283, 4.8163187],
          [118.175778, 4.8155229],
          [118.1765982, 4.8146196],
          [118.1770515, 4.8135441],
          [118.1781523, 4.813071],
          [118.179102, 4.8130279],
          [118.1802244, 4.8128774],
          [118.1812604, 4.8121031],
          [118.1833325, 4.8114794],
          [118.1844333, 4.8108771],
          [118.1853183, 4.8106835],
          [118.1864622, 4.8097587],
          [118.1869587, 4.8088338],
          [118.1883617, 4.807952],
          [118.1901532, 4.8073928],
          [118.1904553, 4.8066185],
          [118.1908438, 4.8053925],
          [118.1909734, 4.8043386],
          [118.1917936, 4.8020372],
          [118.1921389, 4.8010478],
          [118.1928944, 4.7997572],
          [118.1931534, 4.7988539],
          [118.1941031, 4.7977569],
          [118.1954197, 4.7963374],
          [118.1964558, 4.7955415],
          [118.1965205, 4.7948318],
          [118.1961752, 4.794208],
          [118.1964342, 4.7936918],
          [118.1968011, 4.7931971],
          [118.1968011, 4.7923582],
          [118.1966932, 4.7900568],
          [118.196909, 4.7888738],
          [118.1969954, 4.788121],
          [118.1972976, 4.7870885],
          [118.1971896, 4.7862927],
          [118.1968875, 4.7854754],
          [118.1971465, 4.7844644],
          [118.197794, 4.7840127],
          [118.1979667, 4.7834105],
          [118.1987653, 4.782163],
          [118.1991801, 4.7810862],
          [118.1990512, 4.7799949],
          [118.1990512, 4.7784543],
          [118.1978273, 4.777684],
          [118.1976985, 4.7765285],
          [118.1977629, 4.7748595],
          [118.1985359, 4.7738966],
          [118.1986003, 4.7724202],
          [118.1973764, 4.7712005],
          [118.1973764, 4.7701092],
          [118.1964746, 4.766707],
          [118.1963457, 4.7646528],
          [118.19422, 4.7637541],
          [118.1942844, 4.7624702],
          [118.1952507, 4.7617641],
          [118.196539, 4.7606086],
          [118.1975148, 4.7588051],
          [118.1996483, 4.7570727],
          [118.2022558, 4.7556553],
          [118.2024139, 4.7542379],
          [118.2024139, 4.7519543],
          [118.2024139, 4.7494344],
          [118.202973, 4.7488416],
          [118.2062067, 4.744001],
          [118.20755, 4.7425048],
          [118.2089723, 4.7414023],
          [118.2096044, 4.7406936],
          [118.2104736, 4.7395124],
          [118.2114218, 4.7386462],
          [118.2117379, 4.736835],
          [118.2111848, 4.7354963],
          [118.2102366, 4.7339214],
          [118.2089723, 4.7317952],
          [118.2069969, 4.7316377],
          [118.2062857, 4.7305352],
          [118.2072339, 4.729669],
          [118.2087352, 4.7288028],
          [118.2099995, 4.7288028],
          [118.2115798, 4.7289603],
          [118.2118959, 4.7307715],
          [118.2128441, 4.7307715],
          [118.2139504, 4.729039],
          [118.2139504, 4.7279365],
          [118.2141084, 4.7269916],
          [118.2154517, 4.7271491],
          [118.217032, 4.7271491],
          [118.2182963, 4.7266766],
          [118.2193235, 4.7259678],
          [118.2201927, 4.7251016],
          [118.221457, 4.7243141],
          [118.2228793, 4.7242354],
          [118.2246967, 4.7242354],
          [118.2272252, 4.7243141],
          [118.2283315, 4.7229754],
          [118.2283315, 4.7212429],
          [118.2280944, 4.7195104],
          [118.2257239, 4.7188017],
          [118.2250918, 4.7179355],
          [118.2240645, 4.7170692],
          [118.2235114, 4.7161242],
          [118.2247757, 4.715573],
          [118.2242226, 4.7147855],
          [118.2231163, 4.7132105],
          [118.2228793, 4.7122655],
          [118.2228793, 4.7107692],
          [118.2250127, 4.709273],
          [118.2258819, 4.7084855],
          [118.2265141, 4.706753],
          [118.2288056, 4.7071467],
          [118.2301488, 4.706123],
          [118.23086, 4.7054142],
          [118.23086, 4.7039967],
          [118.2328354, 4.7031304],
          [118.2344158, 4.7029729],
          [118.2348899, 4.7040754],
          [118.2363912, 4.7040754],
          [118.2377345, 4.7031304],
          [118.2378135, 4.7015554],
          [118.2370233, 4.7008466],
          [118.2371814, 4.6985629],
          [118.2381296, 4.6970666],
          [118.2381296, 4.6992716],
          [118.2383666, 4.7002954],
          [118.2392358, 4.7005316],
          [118.2408161, 4.7010041],
          [118.2418434, 4.7003741],
          [118.2445299, 4.6995079],
          [118.244609, 4.6972241],
          [118.2437398, 4.694704],
          [118.2453991, 4.6936802],
          [118.2483764, 4.6950243],
          [118.2492776, 4.6950885],
          [118.2540627, 4.6949815],
          [118.2527752, 4.6907685],
          [118.2533008, 4.6905302],
          [118.254091, 4.6898214],
          [118.254249, 4.6880101],
          [118.254249, 4.6847812],
          [118.2545651, 4.6826549],
          [118.2556713, 4.6812373],
          [118.2569356, 4.6791897],
          [118.2580419, 4.6782447],
          [118.2596222, 4.6769846],
          [118.2602543, 4.6762758],
          [118.2619137, 4.6756458],
          [118.2627829, 4.6743857],
          [118.263178, 4.6731257],
          [118.2630199, 4.6720231],
          [118.2628619, 4.6686367],
          [118.263336, 4.6673766],
          [118.2641262, 4.666274],
          [118.2652324, 4.6648564],
          [118.2661016, 4.6635176],
          [118.2668127, 4.6612337],
          [118.267919, 4.6611549],
          [118.2691833, 4.6616274],
          [118.2698944, 4.6598948],
          [118.2711587, 4.6584772],
          [118.2731341, 4.6579259],
          [118.2742403, 4.6591073],
          [118.2762158, 4.6595798],
          [118.2788233, 4.6594223],
          [118.2809568, 4.6594223],
          [118.282142, 4.6606036],
          [118.2840385, 4.6613912],
          [118.2876732, 4.6609974],
          [118.2891746, 4.6594223],
          [118.2918611, 4.6580834],
          [118.2941526, 4.6572171],
          [118.2953379, 4.6564295],
          [118.2969972, 4.6565871],
          [118.2987356, 4.6566658],
          [118.3011851, 4.6565871],
          [118.3028445, 4.6561145],
          [118.3040298, 4.6546182],
          [118.3041088, 4.6532793],
          [118.3041088, 4.6506803],
          [118.3039507, 4.6484751],
          [118.3024494, 4.6435134],
          [118.3012642, 4.6412294],
          [118.3012642, 4.6396543],
          [118.3015802, 4.6368977],
          [118.3030025, 4.6343775],
          [118.3042668, 4.6329598],
          [118.3107462, 4.6295732],
          [118.3139859, 4.6294156],
          [118.3133538, 4.6315421],
          [118.3168305, 4.6304395],
          [118.3195171, 4.630597],
          [118.3218876, 4.630597],
          [118.3241001, 4.6293369],
          [118.3271027, 4.6273679],
          [118.3316857, 4.6268166],
          [118.3352415, 4.6271316],
          [118.3389553, 4.6273679],
          [118.3415628, 4.6286281],
          [118.3441704, 4.6291006],
          [118.3461458, 4.6292581],
          [118.347015, 4.6302032],
          [118.3495146, 4.6308754],
          [118.3569397, 4.6323665],
          [118.3713592, 4.6277469],
          [118.3711876, 4.611492],
          [118.3826889, 4.5983167],
          [118.3945335, 4.5938679],
          [118.400588740986578, 4.58404603600502],
          [118.4015941, 4.5824153],
          [118.4057655, 4.5757077],
          [118.4083147, 4.5812261],
          [118.4109145, 4.5835647],
          [118.411231133162602, 4.58404603600502],
          [118.4134902, 4.5874802],
          [118.4148378, 4.5927847],
          [118.4156961, 4.5967801],
          [118.4181337, 4.5991328],
          [118.4230518, 4.6015797],
          [118.4261192, 4.6003701],
          [118.4345359, 4.6008781],
          [118.4352741, 4.5940509],
          [118.4390249, 4.5914842],
          [118.4408874, 4.5892598],
          [118.4438222, 4.5909444],
          [118.4506893, 4.5854526],
          [118.45176233482664, 4.58404603600502],
          [118.4570922, 4.5770595],
          [118.4629537, 4.5689057],
          [118.46807, 4.5702063],
          [118.4718637, 4.5722597],
          [118.4747757, 4.5747979],
          [118.482806, 4.5693408],
          [118.4886722, 4.561081],
          [118.4852258, 4.5534059],
          [118.477893, 4.5373244],
          [118.4772224, 4.5310108],
          [118.4698324, 4.5260824],
          [118.4674242, 4.5254632],
          [118.4639078, 4.5254445],
          [118.4631005, 4.5273456],
          [118.4626392, 4.5280087],
          [118.4613222, 4.5295569],
          [118.4599757, 4.5305542],
          [118.4558639, 4.5312628],
          [118.4514946, 4.5329419],
          [118.4501106, 4.5329526],
          [118.4492227, 4.5326425],
          [118.4488714, 4.5317334],
          [118.4472835, 4.5301317],
          [118.4435606, 4.5293082],
          [118.4413987, 4.5298857],
          [118.439607, 4.5290836],
          [118.4403312, 4.525768],
          [118.4407765, 4.5253162],
          [118.4418413, 4.5251103],
          [118.4457815, 4.52153],
          [118.446849, 4.5206957],
          [118.4472647, 4.5205513],
          [118.4478441, 4.5205808],
          [118.4522028, 4.518356],
          [118.4547434, 4.514249],
          [118.4629745, 4.5105012],
          [118.4698581, 4.5103215],
          [118.4738064, 4.5089782],
          [118.4760851, 4.504254],
          [118.4774771, 4.5010105],
          [118.4813766, 4.4978033],
          [118.4827691, 4.4952374],
          [118.4853548, 4.4910981],
          [118.485631, 4.4878733],
          [118.4877768, 4.4867048],
          [118.4882784, 4.4865938],
          [118.4883548, 4.4862783],
          [118.4886754, 4.4864294],
          [118.4888229, 4.4863371],
          [118.4888645, 4.4861807],
          [118.4881563, 4.4852247],
          [118.4885842, 4.4836644],
          [118.4905958, 4.4825628],
          [118.4926891, 4.4818792],
          [118.4934175, 4.4822151],
          [118.4953111, 4.4821617],
          [118.4979665, 4.4812632],
          [118.501668, 4.4803112],
          [118.5048115, 4.481076],
          [118.5057986, 4.4805252],
          [118.5061687, 4.4806883],
          [118.506453, 4.4809209],
          [118.505879, 4.483496],
          [118.5057449, 4.4842795],
          [118.5045755, 4.4846431],
          [118.5055196, 4.4868358],
          [118.5096407, 4.4906414],
          [118.5113744, 4.4959465],
          [118.515299, 4.5010345],
          [118.5163504, 4.5028956],
          [118.5175145, 4.5036523],
          [118.5184479, 4.5042031],
          [118.5188368, 4.5047861],
          [118.5228843, 4.5092434],
          [118.5272428, 4.5148266],
          [118.5299492, 4.5193107],
          [118.53158, 4.5205166],
          [118.5326166, 4.5202773],
          [118.53262, 4.5205393],
          [118.532097, 4.520804],
          [118.5322183, 4.520901],
          [118.5323068, 4.5208936],
          [118.5323739, 4.5212639],
          [118.5330284, 4.5214725],
          [118.5329546, 4.5216556],
          [118.5336828, 4.5234792],
          [118.540374, 4.5253669],
          [118.563635, 4.5139974],
          [118.5640027, 4.5138177],
          [118.5692549, 4.5112505],
          [118.5828087, 4.5159018],
          [118.5845708, 4.5165065],
          [118.5840633, 4.51229],
          [118.5806796, 4.5033511],
          [118.57316, 4.4956672],
          [118.5705286, 4.4929783],
          [118.5699365, 4.4873281],
          [118.5773806, 4.4846295],
          [118.5786494, 4.4777142],
          [118.585428, 4.4766503],
          [118.5867354, 4.4759196],
          [118.5879438, 4.4739645],
          [118.5895483, 4.4719698],
          [118.5926385, 4.4712786],
          [118.5957369, 4.4717265],
          [118.5968578, 4.4727598],
          [118.5970757, 4.4743594],
          [118.5969638, 4.4752692],
          [118.5970801, 4.4758489],
          [118.5974153, 4.4761217],
          [118.5974427, 4.4766946],
          [118.5972238, 4.4770015],
          [118.5971554, 4.4773903],
          [118.5974222, 4.4777381],
          [118.5980379, 4.4780996],
          [118.5979763, 4.4786248],
          [118.5978395, 4.4789249],
          [118.5980242, 4.4792045],
          [118.5985373, 4.4793477],
          [118.5986331, 4.4796615],
          [118.5986208, 4.4799769],
          [118.5990983, 4.4801389],
          [118.5991598, 4.4804663],
          [118.5997291, 4.4809045],
          [118.599974, 4.4810986],
          [118.6002339, 4.4810372],
          [118.6003844, 4.4810781],
          [118.6004563, 4.4813714],
          [118.6008633, 4.4815248],
          [118.6008531, 4.4818147],
          [118.6012983, 4.4823697],
          [118.6015549, 4.482339],
          [118.6019277, 4.4824481],
          [118.602008, 4.482365],
          [118.602109, 4.4822606],
          [118.6022723, 4.4823156],
          [118.6025947, 4.4824242],
          [118.6029102, 4.4825964],
          [118.6031082, 4.482662],
          [118.6031933, 4.4828676],
          [118.6031759, 4.4831024],
          [118.6030721, 4.4833605],
          [118.6030778, 4.4836623],
          [118.6031872, 4.4839828],
          [118.6032214, 4.484375],
          [118.6032658, 4.4846101],
          [118.6032864, 4.4847194],
          [118.6032556, 4.4849308],
          [118.6030333, 4.4850263],
          [118.6031635, 4.4851498],
          [118.603242, 4.4852241],
          [118.603346, 4.4853094],
          [118.603452, 4.4851627],
          [118.6034784, 4.4850953],
          [118.6035786, 4.4848388],
          [118.6037872, 4.4847194],
          [118.6039617, 4.4847876],
          [118.6041552, 4.4846715],
          [118.6046683, 4.4843633],
          [118.6048305, 4.4842693],
          [118.6049537, 4.4840988],
          [118.6051281, 4.4840237],
          [118.6052581, 4.4840237],
          [118.6052923, 4.4841124],
          [118.6053742, 4.4842312],
          [118.6055557, 4.4844943],
          [118.6058854, 4.4846303],
          [118.6060106, 4.4846819],
          [118.6060808, 4.484709],
          [118.6063019, 4.4847942],
          [118.6064766, 4.4848437],
          [118.6065263, 4.4848306],
          [118.6068485, 4.484746],
          [118.6070248, 4.4845688],
          [118.6071939, 4.4843987],
          [118.607242, 4.4843503],
          [118.607491, 4.4840159],
          [118.6075526, 4.4839551],
          [118.6078363, 4.4836752],
          [118.6083889, 4.4833324],
          [118.6084953, 4.4830141],
          [118.6086017, 4.4825415],
          [118.6090371, 4.4820881],
          [118.6097046, 4.4819338],
          [118.6101013, 4.4814515],
          [118.6106721, 4.4811622],
          [118.6112014, 4.4806286],
          [118.6117859, 4.481191],
          [118.6125381, 4.4804938],
          [118.6129949, 4.4809126],
          [118.6143674, 4.4795134],
          [118.6143665, 4.4793274],
          [118.6139078, 4.4788571],
          [118.6141336, 4.4786465],
          [118.6144384, 4.4783753],
          [118.6160773, 4.4801572],
          [118.6162842, 4.480537],
          [118.6164677, 4.4805268],
          [118.617344379425049, 4.480147096636298],
          [118.617829, 4.4799372],
          [118.6177932, 4.4798177],
          [118.6185292, 4.4793738],
          [118.6183614, 4.4788914],
          [118.6182208, 4.4788042],
          [118.6180152, 4.4782031],
          [118.617344379425049, 4.478416084427439],
          [118.6163459, 4.4787331],
          [118.616039, 4.4784555],
          [118.6155515, 4.4788177],
          [118.6151819, 4.4788323],
          [118.6150317, 4.4788582],
          [118.6144163, 4.4781166],
          [118.6147029, 4.4778591],
          [118.6149554, 4.4781448],
          [118.6154721, 4.4777422],
          [118.6152166, 4.4774253],
          [118.6157276, 4.4772749],
          [118.6160977, 4.4770844],
          [118.6162896, 4.4768934],
          [118.6165945, 4.4761902],
          [118.6164136, 4.4755462],
          [118.6172856, 4.4745625],
          [118.617344379425049, 4.47444552339254],
          [118.6177197, 4.4736986],
          [118.6180776, 4.4728638],
          [118.6189007, 4.4725241],
          [118.6204362, 4.4714014],
          [118.6207433, 4.4702596],
          [118.6209016, 4.4698919],
          [118.6210179, 4.4691662],
          [118.6200492, 4.4672387],
          [118.6195294, 4.4666591],
          [118.6178913, 4.4657355],
          [118.617344379425049, 4.465427126815595],
          [118.6158086, 4.4645612],
          [118.6154977, 4.464391],
          [118.6151784, 4.46412],
          [118.617344379425049, 4.46298832928651],
          [118.6176767, 4.4628147],
          [118.6191432, 4.4626208],
          [118.6211971, 4.4638732],
          [118.6228604, 4.4653032],
          [118.6250608, 4.4651077],
          [118.6245437, 4.463084],
          [118.6259739, 4.4628315],
          [118.6267389, 4.4618948],
          [118.6259752, 4.4607513],
          [118.624975, 4.4606174],
          [118.6243388, 4.4581177],
          [118.6224226, 4.4561484],
          [118.6197925, 4.4545921],
          [118.6186976, 4.4543258],
          [118.617344379425049, 4.453661855128843],
          [118.6166462, 4.4533193],
          [118.616466, 4.4526785],
          [118.6163405, 4.4521721],
          [118.617344379425049, 4.451717867842861],
          [118.6180544, 4.4513966],
          [118.6197066, 4.4509072],
          [118.6204571, 4.4489561],
          [118.6214688, 4.4501531],
          [118.6234161, 4.4509981],
          [118.6240679, 4.4522282],
          [118.626447, 4.4539691],
          [118.6276513, 4.4530438],
          [118.628055, 4.4520624],
          [118.6284359, 4.4508042],
          [118.628865, 4.4515864],
          [118.6291131, 4.4519581],
          [118.6294672, 4.4520918],
          [118.6293854, 4.4527697],
          [118.6295557, 4.4530492],
          [118.6301163, 4.4528647],
          [118.6313005, 4.4530318],
          [118.632195, 4.4547232],
          [118.6318544, 4.4549571],
          [118.6319415, 4.4552566],
          [118.6321239, 4.4553302],
          [118.6335388, 4.4547365],
          [118.6351401, 4.4536401],
          [118.6356094, 4.4527831],
          [118.6359943, 4.4524502],
          [118.6371155, 4.4522576],
          [118.6375581, 4.4520611],
          [118.6379681, 4.4517565],
          [118.6384244, 4.4508123],
          [118.6389099, 4.4502133],
          [118.6393672, 4.4498376],
          [118.6398775, 4.4491343],
          [118.6402047, 4.4489524],
          [118.6405642, 4.4488461],
          [118.6408921, 4.448572],
          [118.6410825, 4.4486155],
          [118.6412528, 4.4485954],
          [118.641925, 4.4469336],
          [118.6428243, 4.4439546],
          [118.6435119, 4.4407165],
          [118.630178, 4.4283266],
          [118.62535, 4.4229675],
          [118.6225712, 4.4194642],
          [118.6208439, 4.41725],
          [118.617344379425049, 4.407367264242144],
          [118.617003, 4.4064032],
          [118.6110485, 4.4046167],
          [118.6069179, 4.4064887],
          [118.6041498, 4.4106392],
          [118.6034632, 4.4145757],
          [118.6040754, 4.4223891],
          [118.6038741, 4.4251601],
          [118.6030394, 4.425508],
          [118.6020416, 4.4255989],
          [118.6001068, 4.4242243],
          [118.5953003, 4.4268932],
          [118.5873297, 4.4284603],
          [118.5847065, 4.4273077],
          [118.5823423, 4.4278575],
          [118.5813812, 4.4272903],
          [118.579812, 4.4268992],
          [118.5788999, 4.4268601],
          [118.5780369, 4.4269774],
          [118.5772812, 4.4271339],
          [118.5766345, 4.4285028],
          [118.576252, 4.4282877],
          [118.5758499, 4.4280628],
          [118.5747907, 4.4282583],
          [118.5736041, 4.4289819],
          [118.5733099, 4.4295979],
          [118.5727733, 4.4295407],
          [118.5722547, 4.4293396],
          [118.5722131, 4.4288971],
          [118.5722201, 4.428503],
          [118.5721854, 4.4279223],
          [118.572012, 4.4273207],
          [118.571804, 4.4270511],
          [118.5712631, 4.4268368],
          [118.5721854, 4.4259725],
          [118.5724836, 4.4259207],
          [118.5728858, 4.4256441],
          [118.5731459, 4.4253433],
          [118.5732568, 4.4249976],
          [118.5733851, 4.4246519],
          [118.5734059, 4.424282],
          [118.5733643, 4.4239398],
          [118.5738151, 4.4235941],
          [118.5741618, 4.4234464],
          [118.5743976, 4.4233012],
          [118.5744669, 4.4231353],
          [118.5744506, 4.4230502],
          [118.5743931, 4.4229645],
          [118.5743797, 4.4229071],
          [118.5743568, 4.422809],
          [118.5743466, 4.422765],
          [118.5743636, 4.4225877],
          [118.5743966, 4.4224563],
          [118.574485, 4.4222005],
          [118.5745879, 4.4220238],
          [118.5748674, 4.4217891],
          [118.5748723, 4.4217891],
          [118.5751567, 4.4216962],
          [118.5753626, 4.4213149],
          [118.5756176, 4.421134],
          [118.5756225, 4.4211291],
          [118.5758922, 4.4209824],
          [118.5760442, 4.4205277],
          [118.5761729, 4.4199195],
          [118.5770948, 4.4190297],
          [118.5779186, 4.4190101],
          [118.5780951, 4.4189172],
          [118.5783697, 4.4188977],
          [118.5783697, 4.418795],
          [118.5789533, 4.4186288],
          [118.5795368, 4.4186728],
          [118.5802086, 4.4186826],
          [118.5804096, 4.4183501],
          [118.581101, 4.4179834],
          [118.5819297, 4.4175679],
          [118.5822141, 4.4168638],
          [118.5827486, 4.4163847],
          [118.5839206, 4.4159349],
          [118.5839255, 4.4159349],
          [118.584764, 4.4158469],
          [118.5851661, 4.4156416],
          [118.5852985, 4.4153678],
          [118.5852593, 4.4151282],
          [118.58522, 4.41483],
          [118.5852593, 4.4145367],
          [118.5853573, 4.4142727],
          [118.585681, 4.4140038],
          [118.5949042, 4.4019478],
          [118.595733, 4.4000811],
          [118.5952717, 4.3986557],
          [118.5921282, 4.3980085],
          [118.5902318, 4.398181],
          [118.589678, 4.3983254],
          [118.5883972, 4.3976916],
          [118.5866893, 4.3954546],
          [118.5846609, 4.3937323],
          [118.5840534, 4.3921812],
          [118.5835491, 4.3914123],
          [118.5828926, 4.3916644],
          [118.582613, 4.3909838],
          [118.5805558, 4.3885454],
          [118.5802983, 4.3881871],
          [118.5781984, 4.3861776],
          [118.5777615, 4.3853271],
          [118.5774806, 4.3846425],
          [118.5771893, 4.3842483],
          [118.5766588, 4.3842898],
          [118.5763809, 4.3842932],
          [118.576106, 4.3844269],
          [118.5759008, 4.3844022],
          [118.575889, 4.3832629],
          [118.5758474, 4.3828792],
          [118.5757121, 4.3823502],
          [118.5750359, 4.3823294],
          [118.5747447, 4.3826095],
          [118.5737812, 4.3826277],
          [118.5736719, 4.3841822],
          [118.5733366, 4.3835029],
          [118.5730516, 4.3833204],
          [118.5726225, 4.3831165],
          [118.5717903, 4.3828825],
          [118.5706732, 4.3822166],
          [118.5697521, 4.3816168],
          [118.5697313, 4.3805484],
          [118.5705948, 4.3806936],
          [118.5713438, 4.3806936],
          [118.5715102, 4.3802994],
          [118.5714166, 4.3798223],
          [118.5715623, 4.3793348],
          [118.5711253, 4.3784013],
          [118.570011, 4.3775027],
          [118.5696261, 4.376953],
          [118.5689812, 4.3766625],
          [118.5688251, 4.3763306],
          [118.5685754, 4.3765173],
          [118.5679097, 4.3764343],
          [118.5670878, 4.3761335],
          [118.5663596, 4.3755008],
          [118.5657771, 4.3753763],
          [118.5657354, 4.3749614],
          [118.5655274, 4.3748681],
          [118.5654442, 4.374505],
          [118.5651113, 4.3744636],
          [118.5643581, 4.3739345],
          [118.5643287, 4.3736778],
          [118.5642478, 4.3736484],
          [118.5641411, 4.3735421],
          [118.5641926, 4.3734137],
          [118.5642625, 4.3733697],
          [118.5642699, 4.3732964],
          [118.5640639, 4.373212],
          [118.5640823, 4.3731753],
          [118.5638763, 4.3730983],
          [118.5635527, 4.3730433],
          [118.5625964, 4.3724456],
          [118.5622801, 4.3723795],
          [118.5621992, 4.3721448],
          [118.5620116, 4.3721338],
          [118.5620484, 4.3720715],
          [118.5617173, 4.3720018],
          [118.5616732, 4.3717634],
          [118.5616879, 4.3715214],
          [118.5615592, 4.3714554],
          [118.5612318, 4.3714334],
          [118.5609082, 4.3714407],
          [118.5591464, 4.37103],
          [118.5589846, 4.370909],
          [118.5588816, 4.3707806],
          [118.5584513, 4.3707109],
          [118.5583814, 4.3706302],
          [118.5582233, 4.3705972],
          [118.5580725, 4.3704139],
          [118.5572964, 4.3704139],
          [118.5571713, 4.3705642],
          [118.5564468, 4.3706559],
          [118.5534543, 4.3722581],
          [118.5531423, 4.3727741],
          [118.5528254, 4.3727956],
          [118.5525974, 4.3736611],
          [118.5522296, 4.3743065],
          [118.5515676, 4.375194],
          [118.5516485, 4.3760448],
          [118.5514499, 4.3766682],
          [118.5515823, 4.3775337],
          [118.5516043, 4.3779004],
          [118.5518103, 4.3782818],
          [118.5531638, 4.37925],
          [118.5535316, 4.3794187],
          [118.5538626, 4.3794407],
          [118.554326, 4.3793967],
          [118.554407, 4.3796167],
          [118.5542304, 4.3801154],
          [118.5542819, 4.3802328],
          [118.5542231, 4.3804382],
          [118.5543922, 4.3809516],
          [118.5544502, 4.3817779],
          [118.5451805, 4.3761296],
          [118.5462105, 4.3667157],
          [118.551532, 4.3504551],
          [118.5268127, 4.3504551],
          [118.5096439, 4.3545943],
          [118.5079273, 4.3583599],
          [118.5038101, 4.3603827],
          [118.4888728, 4.3600716],
          [118.4787448, 4.3576753],
          [118.4708511, 4.3550766],
          [118.4469186, 4.3462843],
          [118.4293807, 4.3443468],
          [118.4244026, 4.3392117],
          [118.4249228, 4.3337252],
          [118.4144462, 4.3277433],
          [118.4132354, 4.3253401],
          [118.4117672, 4.3249909],
          [118.4109764, 4.3294705],
          [118.4080645, 4.3305131],
          [118.4018847, 4.3304382],
          [118.4003397, 4.3303205],
          [118.3991235, 4.329394],
          [118.3981506, 4.3301503],
          [118.3917203, 4.3319834],
          [118.3905043, 4.331142],
          [118.3901858, 4.331467],
          [118.3900309, 4.3337107],
          [118.3883542, 4.3350386],
          [118.3878195, 4.3347631],
          [118.3877247, 4.3372256],
          [118.3865677, 4.3386747],
          [118.386392, 4.3400268],
          [118.3848335, 4.3409095],
          [118.3849653, 4.342335],
          [118.3844326, 4.3443215],
          [118.3828081, 4.3454757],
          [118.3823376, 4.3465463],
          [118.3761911, 4.3479801],
          [118.3678683, 4.3467985],
          [118.3577218, 4.3407038],
          [118.3567041, 4.3402668],
          [118.3557446, 4.3404121],
          [118.350276, 4.3443039],
          [118.3491418, 4.3463129],
          [118.3398474, 4.3457781],
          [118.3382901, 4.3419702],
          [118.3378609, 4.3405542],
          [118.337582, 4.338176],
          [118.3373754, 4.3373417],
          [118.3366968, 4.3360795],
          [118.336286, 4.335758],
          [118.3352388, 4.3349387],
          [118.3335801, 4.3336407],
          [118.3309086, 4.3331708],
          [118.3265313, 4.3306782],
          [118.3245786, 4.3267388],
          [118.3242353, 4.3262077],
          [118.3235486, 4.325923],
          [118.3232697, 4.3253393],
          [118.3218964, 4.3250195],
          [118.3208235, 4.3249823],
          [118.3203944, 4.3245747],
          [118.3198794, 4.3244973],
          [118.3193215, 4.3240782],
          [118.3185061, 4.324396],
          [118.3137425, 4.3233986],
          [118.3118431, 4.3246351],
          [118.3104405, 4.3254242],
          [118.3082255, 4.3266705],
          [118.3054169, 4.3263707],
          [118.2942375, 4.3249392],
          [118.2800969, 4.3234873],
          [118.2796167, 4.3238331],
          [118.2752313, 4.323323],
          [118.2749148, 4.3229448],
          [118.2710203, 4.3219315],
          [118.2707842, 4.3214456],
          [118.2684668, 4.3207733],
          [118.2647975, 4.3202708],
          [118.2623299, 4.3205368],
          [118.2553709, 4.3191856],
          [118.2541847, 4.3194987],
          [118.2506414, 4.3182281],
          [118.2495785, 4.3168249],
          [118.2445969, 4.3147279],
          [118.2379402, 4.3128052],
          [118.230039, 4.3048914],
          [118.2235588, 4.2971029],
          [118.1962383, 4.2917752],
          [118.195391, 4.2918172],
          [118.1942217, 4.2924466],
          [118.1912832, 4.292282],
          [118.1902056, 4.2916873],
          [118.1891167, 4.2921366],
          [118.1877821, 4.2931432],
          [118.1857812, 4.2929979],
          [118.1842688, 4.2935922],
          [118.1817761, 4.2960194],
          [118.178655, 4.2976232],
          [118.1772214, 4.2978632],
          [118.1756137, 4.2980732],
          [118.1754355, 4.297853],
          [118.1745615, 4.2981747],
          [118.174046, 4.2979823],
          [118.1734586, 4.298212],
          [118.1717468, 4.2980784],
          [118.1704048, 4.2977481],
          [118.1702841, 4.2967681],
          [118.1691492, 4.2974442],
          [118.1686129, 4.2972752],
          [118.1675589, 4.297647],
          [118.1621298, 4.2960033],
          [118.1589346, 4.2947022],
          [118.1579325, 4.2941311],
          [118.1577191, 4.2938795],
          [118.1580849, 4.292694],
          [118.1582185, 4.2915604],
          [118.1578293, 4.2902071],
          [118.1574992, 4.290231],
          [118.1572478, 4.2905993],
          [118.1571098, 4.2915209],
          [118.15675, 4.292],
          [118.155, 4.29175],
          [118.15125, 4.2915],
          [118.1485, 4.29075],
          [118.14725, 4.2915],
          [118.147, 4.291],
          [118.146, 4.2905],
          [118.1455, 4.29075],
          [118.144, 4.2905],
          [118.135, 4.28825],
          [118.13475, 4.28875],
          [118.13425, 4.289],
          [118.13025, 4.289],
          [118.13, 4.288],
          [118.131, 4.2875],
          [118.13025, 4.287],
          [118.12875, 4.28675],
          [118.12275, 4.2845],
          [118.12175, 4.285],
          [118.1215, 4.28475],
          [118.121, 4.28375],
          [118.1205, 4.28375],
          [118.11925, 4.2835],
          [118.11875, 4.283],
          [118.11225, 4.28025],
          [118.11125, 4.27925],
          [118.11025, 4.27925],
          [118.10825, 4.27775],
          [118.10225, 4.27425],
          [118.10125, 4.273],
          [118.097, 4.271],
          [118.09575, 4.2695],
          [118.0947486, 4.2687959],
          [118.09275, 4.268],
          [118.087, 4.2635],
          [118.0865, 4.26325],
          [118.08575, 4.26375],
          [118.08575, 4.263],
          [118.086, 4.26275],
          [118.084, 4.26075],
          [118.083, 4.2605],
          [118.08025, 4.2575],
          [118.07925, 4.2575],
          [118.079, 4.25725],
          [118.07875, 4.25625],
          [118.07625, 4.25425],
          [118.07475, 4.254],
          [118.07425, 4.254],
          [118.07275, 4.25575],
          [118.0725, 4.255],
          [118.07325, 4.25425],
          [118.07275, 4.25325],
          [118.07175, 4.253],
          [118.068, 4.2525],
          [118.06725, 4.2535],
          [118.0665, 4.25275],
          [118.067, 4.252],
          [118.0655, 4.25175],
          [118.06275, 4.25125],
          [118.0615, 4.25025],
          [118.0595, 4.25025],
          [118.05, 4.246],
          [118.049, 4.24625],
          [118.049, 4.2455],
          [118.0465, 4.244],
          [118.04475, 4.24325],
          [118.04425, 4.243],
          [118.044, 4.24325],
          [118.04275, 4.242],
          [118.0415, 4.24175],
          [118.04125, 4.2415],
          [118.04075, 4.24175],
          [118.03975, 4.241],
          [118.04, 4.24125],
          [118.0395, 4.2415],
          [118.03925, 4.24125],
          [118.039, 4.2405],
          [118.0375, 4.23975],
          [118.03675, 4.24],
          [118.0365, 4.24025],
          [118.03625, 4.23975],
          [118.03575, 4.23925],
          [118.03525, 4.23925],
          [118.03375, 4.2385],
          [118.03275, 4.2385],
          [118.0325, 4.23825],
          [118.03125, 4.2375],
          [118.02625, 4.23575],
          [118.02575, 4.23575],
          [118.02425, 4.23575],
          [118.02375, 4.23625],
          [118.023, 4.2355],
          [118.022, 4.2345],
          [118.0215, 4.23475],
          [118.01975, 4.234],
          [118.019473712205894, 4.233557939529419],
          [118.0185, 4.232],
          [118.0175, 4.2315],
          [118.0165, 4.23175],
          [118.01, 4.2295],
          [118.00975, 4.22925],
          [118.00275, 4.22425],
          [118.002, 4.22425],
          [118.000501546099244, 4.223584020488552],
          [117.99975, 4.22325],
          [117.999498453900756, 4.22325],
          [117.99875, 4.22325],
          [117.99825, 4.223],
          [117.99725, 4.2225],
          [117.9965, 4.2215],
          [117.99475, 4.22225],
          [117.993, 4.22375],
          [117.9915, 4.22425],
          [117.99025, 4.224],
          [117.99, 4.22425],
          [117.988, 4.22375],
          [117.987, 4.22275],
          [117.9865, 4.22275],
          [117.98575, 4.2235],
          [117.98525, 4.2235],
          [117.98325, 4.223],
          [117.98275, 4.223],
          [117.9815, 4.2235],
          [117.9805, 4.2235],
          [117.97925, 4.223],
          [117.9785, 4.22325],
          [117.97725, 4.224],
          [117.97675, 4.22425],
          [117.977, 4.2245],
          [117.97725, 4.22475],
          [117.97625, 4.22575],
          [117.9755, 4.226],
          [117.97525, 4.22625],
          [117.973, 4.22625],
          [117.97275, 4.2265],
          [117.972, 4.22775],
          [117.9695, 4.22825],
          [117.96975, 4.2295],
          [117.9695, 4.23175],
          [117.969, 4.23225],
          [117.9695, 4.23325],
          [117.969192060470576, 4.233557939529419],
          [117.969, 4.23375],
          [117.96825, 4.23425],
          [117.96775, 4.236],
          [117.965561866760254, 4.237750506591795],
          [117.96525, 4.238],
          [117.963, 4.23825],
          [117.95875, 4.2385],
          [117.95975, 4.23875],
          [117.96025, 4.2385],
          [117.96075, 4.239],
          [117.96, 4.24025],
          [117.9595, 4.2395],
          [117.95825, 4.2395],
          [117.958, 4.2385],
          [117.9555, 4.239],
          [117.95475, 4.2395],
          [117.953, 4.241],
          [117.95275, 4.24225],
          [117.951, 4.24325],
          [117.95, 4.24325],
          [117.9475, 4.24175],
          [117.9465, 4.2425],
          [117.946, 4.24325],
          [117.944, 4.244],
          [117.9435, 4.2435],
          [117.94275, 4.24325],
          [117.9425, 4.244],
          [117.94175, 4.24325],
          [117.94175, 4.24375],
          [117.942, 4.244],
          [117.94125, 4.2445],
          [117.94125, 4.244],
          [117.94075, 4.2435],
          [117.94025, 4.24325],
          [117.9395, 4.2435],
          [117.939, 4.243],
          [117.93875, 4.24375],
          [117.93975, 4.2445],
          [117.93975, 4.24525],
          [117.939, 4.2455],
          [117.9385, 4.24575],
          [117.93825, 4.2455],
          [117.9385, 4.2445],
          [117.93825, 4.24425],
          [117.9375, 4.2445],
          [117.93725, 4.244],
          [117.9375, 4.24375],
          [117.937, 4.24375],
          [117.937, 4.24425],
          [117.93725, 4.246],
          [117.937, 4.247],
          [117.9365, 4.247],
          [117.9365, 4.246],
          [117.936, 4.24625],
          [117.93525, 4.24575],
          [117.935, 4.2465],
          [117.93375, 4.2465],
          [117.933, 4.24725],
          [117.933, 4.24875],
          [117.932, 4.24875],
          [117.93125, 4.24775],
          [117.9305, 4.2485],
          [117.93025, 4.24875],
          [117.92975, 4.24825],
          [117.9295, 4.249],
          [117.929, 4.249],
          [117.92825, 4.24825],
          [117.928, 4.24875],
          [117.92775, 4.249],
          [117.92725, 4.24875],
          [117.9265, 4.24825],
          [117.92625, 4.2485],
          [117.926, 4.24875],
          [117.92375, 4.24825],
          [117.92325, 4.248],
          [117.9185, 4.2465],
          [117.918, 4.24625],
          [117.91725, 4.2455],
          [117.9138659, 4.2438926],
          [117.9115927, 4.242484],
          [117.9115069, 4.2429762],
          [117.9105408, 4.2423702],
          [117.9104813, 4.2418789],
          [117.9081443, 4.2406446],
          [117.9078405, 4.2407108],
          [117.9075285, 4.2410121],
          [117.9074309, 4.2408851],
          [117.9076809, 4.2402053],
          [117.9074045, 4.2395946],
          [117.9065306, 4.2395647],
          [117.9058089, 4.2393376],
          [117.9049035, 4.2389888],
          [117.9044672, 4.2380658],
          [117.9035589, 4.2382364],
          [117.9030685, 4.2381612],
          [117.9024292, 4.2380159],
          [117.9021084, 4.2381094],
          [117.9021203, 4.2386994],
          [117.9018514, 4.2390465],
          [117.901361, 4.2394036],
          [117.9011441, 4.2399883],
          [117.9008777, 4.2408407],
          [117.900512, 4.240365],
          [117.8990715, 4.2410725],
          [117.8985118, 4.2412865],
          [117.898561, 4.2416961],
          [117.8982622, 4.2418804],
          [117.8981158, 4.2416689],
          [117.8972297, 4.2419838],
          [117.8970079, 4.2423435],
          [117.897136, 4.2427854],
          [117.8967884, 4.2429978],
          [117.8962682, 4.2429105],
          [117.8956441, 4.2428346],
          [117.8956753, 4.2420591],
          [117.8952126, 4.2419767],
          [117.8865, 4.24075],
          [117.886249, 4.2407223],
          [117.885665, 4.2410378],
          [117.8849705, 4.2414198],
          [117.8847012, 4.2415623],
          [117.8846647, 4.2415812],
          [117.8846293, 4.2415998],
          [117.8842103, 4.2417983],
          [117.8835639, 4.2422407],
          [117.8832323, 4.2423998],
          [117.8827517, 4.2425642],
          [117.8822291, 4.2427247],
          [117.8806277, 4.243508],
          [117.8803847, 4.2436776],
          [117.8799785, 4.2440601],
          [117.8796472, 4.2443682],
          [117.8792316, 4.2447355],
          [117.8782431, 4.2457657],
          [117.878208, 4.2459526],
          [117.8782094, 4.2461115],
          [117.8782582, 4.2462747],
          [117.8784664, 4.2466582],
          [117.8787749, 4.2471091],
          [117.8789845, 4.2474011],
          [117.8790074, 4.2475013],
          [117.8789701, 4.2475471],
          [117.8788453, 4.2476646],
          [117.8787975, 4.2477496],
          [117.878443, 4.2480244],
          [117.8781861, 4.2484194],
          [117.8781287, 4.2485511],
          [117.8779868, 4.2490809],
          [117.8774377, 4.2490043],
          [117.8773803, 4.2492891],
          [117.8773494, 4.2495746],
          [117.8773745, 4.2495947],
          [117.877409, 4.2496083],
          [117.8776386, 4.2496269],
          [117.8777362, 4.2496054],
          [117.8777962, 4.2495978],
          [117.8778472, 4.2496807],
          [117.8775276, 4.2505815],
          [117.8772491, 4.2517436],
          [117.877092, 4.2523302],
          [117.8770257, 4.252578],
          [117.8768564, 4.2532332],
          [117.8766991, 4.2531917],
          [117.8766493, 4.2533546],
          [117.8766138, 4.2533991],
          [117.8765732, 4.2536289],
          [117.8764697, 4.2537695],
          [117.8763489, 4.2538819],
          [117.8762647, 4.2539264],
          [117.8761957, 4.2539335],
          [117.8757409, 4.2538362],
          [117.875719, 4.2538384],
          [117.8757062, 4.2538545],
          [117.8756096, 4.2542603],
          [117.8754922, 4.254753],
          [117.8755131, 4.2547831],
          [117.8765796, 4.2549708],
          [117.8764509, 4.2557758],
          [117.8747333, 4.2554054],
          [117.874827, 4.2549646],
          [117.8741308, 4.2548154],
          [117.873651, 4.2552364],
          [117.873635, 4.2552505],
          [117.8736027, 4.255329],
          [117.8735613, 4.2554709],
          [117.8730815, 4.2559475],
          [117.872428, 4.2565443],
          [117.8717951, 4.2571138],
          [117.8717413, 4.2571625],
          [117.871784, 4.2572096],
          [117.8719156, 4.2572455],
          [117.8720918, 4.2573334],
          [117.872277, 4.257443],
          [117.8725516, 4.2576206],
          [117.8725315, 4.2576981],
          [117.8724538, 4.2578731],
          [117.8724525, 4.2579398],
          [117.8725034, 4.2579999],
          [117.8726266, 4.2580614],
          [117.8729076, 4.2583034],
          [117.8727766, 4.2584728],
          [117.8725851, 4.258442],
          [117.8725623, 4.2584928],
          [117.8724069, 4.2584607],
          [117.8723239, 4.2583819],
          [117.8722248, 4.2583539],
          [117.872052, 4.2583272],
          [117.8718926, 4.2582671],
          [117.8715658, 4.2580427],
          [117.871247, 4.2578824],
          [117.8711384, 4.2579348],
          [117.8708935, 4.2587773],
          [117.8706604, 4.2591246],
          [117.8704568, 4.2594611],
          [117.8701169, 4.2601267],
          [117.8701132, 4.2602215],
          [117.870187, 4.2603367],
          [117.8708481, 4.2607825],
          [117.8707197, 4.260949],
          [117.8718977, 4.2617116],
          [117.8718179, 4.2618572],
          [117.8720376, 4.2620027],
          [117.8719751, 4.2620801],
          [117.8712572, 4.2617326],
          [117.870731, 4.2625926],
          [117.8717686, 4.2632267],
          [117.8729516, 4.2640439],
          [117.8730057, 4.264148],
          [117.8729236, 4.2644231],
          [117.8727702, 4.2647235],
          [117.872608, 4.2648753],
          [117.8724697, 4.2652771],
          [117.8722008, 4.265682],
          [117.8717909, 4.2662364],
          [117.871472, 4.2665498],
          [117.8712655, 4.2666518],
          [117.8710344, 4.2665536],
          [117.87075, 4.267],
          [117.8705741, 4.2670957],
          [117.8704264, 4.2670447],
          [117.8694907, 4.2663836],
          [117.8685813, 4.2657803],
          [117.8671388, 4.2647266],
          [117.86689, 4.2645686],
          [117.8665967, 4.2643735],
          [117.8665391, 4.2643495],
          [117.8664949, 4.2643722],
          [117.8663744, 4.2645365],
          [117.8662297, 4.2647275],
          [117.866136, 4.264857],
          [117.8661318, 4.2649091],
          [117.8664093, 4.2651549],
          [117.8665267, 4.2653495],
          [117.8667862, 4.2655742],
          [117.8670827, 4.2657755],
          [117.8672938, 4.2659316],
          [117.8674087, 4.2660775],
          [117.8674796, 4.2661406],
          [117.8674649, 4.2662128],
          [117.8672626, 4.2664291],
          [117.8671689, 4.2664104],
          [117.867035, 4.2662822],
          [117.8669651, 4.2662755],
          [117.8668381, 4.2663931],
          [117.8667015, 4.2665707],
          [117.8667309, 4.2666696],
          [117.8669894, 4.2669046],
          [117.8670068, 4.26695],
          [117.866976, 4.2669914],
          [117.8669988, 4.2670609],
          [117.8673066, 4.2672786],
          [117.8673711, 4.2674393],
          [117.8675881, 4.2675823],
          [117.8678493, 4.2676651],
          [117.8681747, 4.2677025],
          [117.8684051, 4.2676811],
          [117.8688598, 4.2671984],
          [117.8690979, 4.2673098],
          [117.868, 4.2685],
          [117.868, 4.269],
          [117.8685, 4.27025],
          [117.86375, 4.279],
          [117.863, 4.279],
          [117.86325, 4.2785],
          [117.8625, 4.278],
          [117.8619449, 4.2775135],
          [117.8613999, 4.277034],
          [117.8608579, 4.2767276],
          [117.8603399, 4.2762997],
          [117.8598749, 4.2759466],
          [117.8598157, 4.2758197],
          [117.8592442, 4.2754628],
          [117.8589538, 4.2753444],
          [117.8586004, 4.2752688],
          [117.8583921, 4.275226],
          [117.858106, 4.2750886],
          [117.8577415, 4.2749492],
          [117.8573515, 4.2747547],
          [117.8567877, 4.2744313],
          [117.8566817, 4.2743383],
          [117.8565885, 4.2743172],
          [117.8561133, 4.2741526],
          [117.8561126, 4.2741143],
          [117.8560501, 4.2740984],
          [117.8560035, 4.2741259],
          [117.8558837, 4.2740942],
          [117.855871, 4.2740509],
          [117.8558053, 4.2740234],
          [117.8557857, 4.2740409],
          [117.8556095, 4.2739781],
          [117.855605, 4.273942],
          [117.8555372, 4.2739156],
          [117.855513, 4.2739447],
          [117.8553781, 4.273898],
          [117.8553581, 4.2738765],
          [117.8553549, 4.2738374],
          [117.8552913, 4.2738057],
          [117.8552723, 4.2738342],
          [117.8552435, 4.2738472],
          [117.8549713, 4.2737571],
          [117.8549215, 4.2737817],
          [117.854294, 4.275077],
          [117.8537716, 4.2763331],
          [117.8538238, 4.2763116],
          [117.8540051, 4.2759619],
          [117.8541011, 4.2759858],
          [117.854116, 4.276071],
          [117.8541164, 4.2762799],
          [117.8542165, 4.2765702],
          [117.8542464, 4.2770335],
          [117.8543034, 4.2774609],
          [117.8543407, 4.2776587],
          [117.8544682, 4.2777658],
          [117.854801, 4.2779302],
          [117.8552955, 4.2781394],
          [117.8556156, 4.2783027],
          [117.8560246, 4.2783302],
          [117.8562182, 4.2783859],
          [117.8564061, 4.27855],
          [117.85651, 4.2785606],
          [117.8566096, 4.2786599],
          [117.8567728, 4.278958],
          [117.8567687, 4.2790481],
          [117.8567971, 4.2791169],
          [117.8568706, 4.2791707],
          [117.856972, 4.2791806],
          [117.8571104, 4.2793784],
          [117.8571658, 4.27948],
          [117.857188, 4.2796966],
          [117.8571208, 4.2798031],
          [117.8569725, 4.2800719],
          [117.8569065, 4.2804201],
          [117.8568384, 4.2805807],
          [117.8567507, 4.2807085],
          [117.8567462, 4.2808505],
          [117.8567926, 4.2810044],
          [117.8567627, 4.2811165],
          [117.8567042, 4.2812645],
          [117.8567267, 4.2814588],
          [117.8567402, 4.2816112],
          [117.8566847, 4.2818324],
          [117.8567027, 4.2819505],
          [117.8569485, 4.2821209],
          [117.856938, 4.2821448],
          [117.8570069, 4.2821971],
          [117.8570999, 4.2822016],
          [117.8571566, 4.2822623],
          [117.8572705, 4.2822787],
          [117.8577728, 4.2824522],
          [117.8580806, 4.2825803],
          [117.8582431, 4.282775],
          [117.858406, 4.2830229],
          [117.8585451, 4.2830553],
          [117.8586468, 4.2831736],
          [117.85881, 4.2831948],
          [117.8588906, 4.2831588],
          [117.858969, 4.2831715],
          [117.8590538, 4.2833047],
          [117.8591632, 4.2833783],
          [117.8592894, 4.283404],
          [117.8594102, 4.2833977],
          [117.8594907, 4.2834146],
          [117.8595869, 4.2834988],
          [117.8597853, 4.2835393],
          [117.8600058, 4.2835329],
          [117.8602008, 4.2835562],
          [117.8602919, 4.2836302],
          [117.8603788, 4.2837506],
          [117.8604251, 4.285012],
          [117.8598693, 4.2853595],
          [117.8589403, 4.2852917],
          [117.8581035, 4.2856126],
          [117.8572022, 4.2864258],
          [117.8564941, 4.2871533],
          [117.8559148, 4.287731],
          [117.8552281, 4.2883301],
          [117.8535759, 4.289614],
          [117.852267, 4.289935],
          [117.8510439, 4.2904913],
          [117.8497564, 4.2911332],
          [117.8486621, 4.2916896],
          [117.8476107, 4.2920747],
          [117.8463661, 4.2927808],
          [117.8453361, 4.2934442],
          [117.8442203, 4.2944284],
          [117.8432848, 4.2954065],
          [117.8419179, 4.2964321],
          [117.8394418, 4.2981783],
          [117.8379269, 4.2994016],
          [117.8369365, 4.3002103],
          [117.8366209, 4.3006892],
          [117.8364171, 4.3013228],
          [117.836482, 4.3016793],
          [117.8369506, 4.302375],
          [117.8370661, 4.303063],
          [117.8367072, 4.3035423],
          [117.8350714, 4.304471],
          [117.8333803, 4.3053502],
          [117.8319432, 4.3059468],
          [117.8298985, 4.306837],
          [117.8278847, 4.3077935],
          [117.8265327, 4.3080532],
          [117.8253884, 4.3080495],
          [117.8241508, 4.3078648],
          [117.8230451, 4.3075858],
          [117.821515, 4.3070653],
          [117.8195128, 4.3062709],
          [117.8170105, 4.3053144],
          [117.816043, 4.3053003],
          [117.8149968, 4.3056731],
          [117.813943, 4.3065535],
          [117.812636, 4.3082271],
          [117.8114617, 4.3110254],
          [117.8107709, 4.3138961],
          [117.810517, 4.3161847],
          [117.8108734, 4.3176219],
          [117.8121025, 4.3188827],
          [117.8124539, 4.3196533],
          [117.811541, 4.3199893],
          [117.8089318, 4.3208087],
          [117.8071747, 4.3222216],
          [117.8057497, 4.324139],
          [117.8045783, 4.3264931],
          [117.8037022, 4.329441],
          [117.8135, 4.329],
          [117.814, 4.33],
          [117.8135, 4.33025],
          [117.813, 4.32975],
          [117.81325, 4.3305],
          [117.81275, 4.3315],
          [117.81425, 4.33225],
          [117.81325, 4.3325],
          [117.8125, 4.33275],
          [117.81225, 4.33225],
          [117.81175, 4.33175],
          [117.81125, 4.33175],
          [117.811, 4.332],
          [117.80975, 4.3335],
          [117.80975, 4.335],
          [117.81025, 4.336],
          [117.8115, 4.337],
          [117.811, 4.3375],
          [117.8115, 4.3375],
          [117.81175, 4.33775],
          [117.81175, 4.33825],
          [117.81175, 4.33875],
          [117.81225, 4.33875],
          [117.8125, 4.339],
          [117.81225, 4.33925],
          [117.81225, 4.33975],
          [117.81375, 4.34175],
          [117.81325, 4.342],
          [117.812, 4.342],
          [117.81175, 4.34275],
          [117.8125, 4.343],
          [117.813, 4.34375],
          [117.81425, 4.344],
          [117.81425, 4.3455],
          [117.81325, 4.34625],
          [117.81275, 4.3465],
          [117.8115, 4.34575],
          [117.81125, 4.346],
          [117.81125, 4.347],
          [117.81075, 4.34725],
          [117.8105, 4.347],
          [117.81025, 4.345],
          [117.80925, 4.345],
          [117.80775, 4.34525],
          [117.80675, 4.34375],
          [117.806, 4.3445],
          [117.8065, 4.3445],
          [117.80725, 4.3455],
          [117.8075, 4.34625],
          [117.807, 4.34675],
          [117.8075, 4.347],
          [117.80875, 4.34825],
          [117.80975, 4.35025],
          [117.8105, 4.3505],
          [117.8135, 4.348],
          [117.81375, 4.349],
          [117.814, 4.3495],
          [117.8135, 4.34975],
          [117.8125, 4.35],
          [117.8125, 4.3505],
          [117.81375, 4.3515],
          [117.81425, 4.352],
          [117.81425, 4.3525],
          [117.81475, 4.35225],
          [117.81525, 4.35325],
          [117.8155, 4.3525],
          [117.815, 4.35075],
          [117.81575, 4.3505],
          [117.81675, 4.3495],
          [117.817, 4.34975],
          [117.8165, 4.351],
          [117.8175, 4.3515],
          [117.8175, 4.351],
          [117.818, 4.3485],
          [117.8185, 4.34825],
          [117.81875, 4.348],
          [117.82075, 4.34825],
          [117.821, 4.34725],
          [117.82175, 4.34575],
          [117.822, 4.3455],
          [117.8225, 4.346],
          [117.8225, 4.3455],
          [117.82225, 4.34525],
          [117.823, 4.34275],
          [117.82375, 4.34225],
          [117.82475, 4.3415],
          [117.825, 4.342],
          [117.8245, 4.34325],
          [117.825, 4.34375],
          [117.82525, 4.3435],
          [117.826, 4.342],
          [117.82675, 4.342],
          [117.82475, 4.34475],
          [117.8245, 4.3445],
          [117.82425, 4.34475],
          [117.824, 4.34575],
          [117.82325, 4.3465],
          [117.824, 4.34725],
          [117.8235, 4.3475],
          [117.82225, 4.3485],
          [117.8225, 4.3495],
          [117.822, 4.34975],
          [117.821, 4.3505],
          [117.82075, 4.35025],
          [117.82, 4.34925],
          [117.81825, 4.34975],
          [117.81825, 4.35025],
          [117.81825, 4.35225],
          [117.817, 4.3535],
          [117.818, 4.35425],
          [117.819, 4.35425],
          [117.8195, 4.3555],
          [117.81975, 4.35525],
          [117.82175, 4.35425],
          [117.8225, 4.3545],
          [117.82275, 4.35475],
          [117.823, 4.355],
          [117.823, 4.356],
          [117.82225, 4.35575],
          [117.82175, 4.35575],
          [117.82, 4.3565],
          [117.8175, 4.355],
          [117.81725, 4.35525],
          [117.81575, 4.35675],
          [117.81525, 4.35675],
          [117.81475, 4.3565],
          [117.8145, 4.357],
          [117.81575, 4.3575],
          [117.81575, 4.358],
          [117.81625, 4.358],
          [117.81725, 4.35925],
          [117.817, 4.36],
          [117.81575, 4.3595],
          [117.8155, 4.36],
          [117.81575, 4.3605],
          [117.81625, 4.361],
          [117.816, 4.36125],
          [117.81575, 4.3615],
          [117.816, 4.36225],
          [117.8165, 4.36225],
          [117.81675, 4.362],
          [117.817, 4.36225],
          [117.817, 4.36275],
          [117.81875, 4.363],
          [117.81825, 4.3635],
          [117.81725, 4.36425],
          [117.815, 4.36375],
          [117.81575, 4.36425],
          [117.81575, 4.3655],
          [117.81725, 4.36675],
          [117.81675, 4.36725],
          [117.81525, 4.36675],
          [117.81475, 4.3675],
          [117.81425, 4.367],
          [117.81475, 4.3665],
          [117.8135, 4.36375],
          [117.81375, 4.3625],
          [117.8135, 4.36175],
          [117.813, 4.36175],
          [117.81275, 4.362],
          [117.812, 4.361],
          [117.81225, 4.36075],
          [117.8125, 4.3605],
          [117.812, 4.3595],
          [117.811, 4.36025],
          [117.8115, 4.361],
          [117.811, 4.36125],
          [117.8105, 4.36075],
          [117.80975, 4.3605],
          [117.80975, 4.36],
          [117.81, 4.3595],
          [117.80925, 4.35875],
          [117.809, 4.359],
          [117.8085, 4.35775],
          [117.80725, 4.35775],
          [117.80825, 4.35925],
          [117.80825, 4.3605],
          [117.80925, 4.36125],
          [117.809, 4.36175],
          [117.80875, 4.362],
          [117.80775, 4.362],
          [117.80875, 4.36225],
          [117.80925, 4.36275],
          [117.8095, 4.3625],
          [117.81, 4.36225],
          [117.81075, 4.3625],
          [117.8115, 4.36375],
          [117.81, 4.364],
          [117.81025, 4.36425],
          [117.81075, 4.365],
          [117.81025, 4.36525],
          [117.8095, 4.36475],
          [117.80875, 4.364],
          [117.80725, 4.36425],
          [117.807, 4.36375],
          [117.80675, 4.36275],
          [117.8065, 4.363],
          [117.8065, 4.3645],
          [117.808, 4.3655],
          [117.80825, 4.36525],
          [117.80875, 4.36475],
          [117.80925, 4.36525],
          [117.809, 4.3655],
          [117.809, 4.366],
          [117.80975, 4.36675],
          [117.8085, 4.3675],
          [117.80775, 4.36675],
          [117.80775, 4.36725],
          [117.807, 4.368],
          [117.80675, 4.36775],
          [117.80625, 4.3675],
          [117.806, 4.368],
          [117.80625, 4.36825],
          [117.808, 4.36925],
          [117.80825, 4.37025],
          [117.808, 4.3705],
          [117.807, 4.36975],
          [117.80825, 4.37225],
          [117.8075, 4.37225],
          [117.8065, 4.3715],
          [117.806, 4.37275],
          [117.805, 4.372],
          [117.8065, 4.37375],
          [117.80575, 4.374],
          [117.80675, 4.375],
          [117.80725, 4.37475],
          [117.80925, 4.3765],
          [117.80925, 4.37575],
          [117.80925, 4.37475],
          [117.80975, 4.37425],
          [117.81025, 4.3745],
          [117.811, 4.37375],
          [117.8115, 4.37375],
          [117.812, 4.37425],
          [117.81225, 4.374],
          [117.8125, 4.37375],
          [117.813, 4.374],
          [117.813, 4.37525],
          [117.8125, 4.37475],
          [117.812, 4.3755],
          [117.8105, 4.37525],
          [117.811, 4.37575],
          [117.80925, 4.3785],
          [117.8095, 4.37875],
          [117.81025, 4.38],
          [117.81075, 4.38],
          [117.811, 4.37975],
          [117.8115, 4.38025],
          [117.80925, 4.3815],
          [117.80975, 4.38225],
          [117.8105, 4.38275],
          [117.811, 4.38275],
          [117.81175, 4.38225],
          [117.812, 4.3825],
          [117.8125, 4.38275],
          [117.813, 4.3825],
          [117.81325, 4.38275],
          [117.81375, 4.3845],
          [117.81425, 4.3845],
          [117.81475, 4.38525],
          [117.8125, 4.38475],
          [117.81225, 4.3845],
          [117.8115, 4.38475],
          [117.81175, 4.3855],
          [117.8115, 4.38625],
          [117.81225, 4.387],
          [117.812, 4.38725],
          [117.81225, 4.38775],
          [117.81175, 4.388],
          [117.8115, 4.38775],
          [117.81075, 4.387],
          [117.8095, 4.38425],
          [117.809, 4.385],
          [117.8095, 4.3865],
          [117.809, 4.38675],
          [117.8085, 4.38675],
          [117.80875, 4.38725],
          [117.80775, 4.38775],
          [117.80775, 4.38625],
          [117.808, 4.3855],
          [117.8075, 4.3845],
          [117.808, 4.38375],
          [117.80775, 4.3835],
          [117.80725, 4.383],
          [117.80675, 4.38025],
          [117.806, 4.38],
          [117.8055, 4.3795],
          [117.8045, 4.3795],
          [117.80375, 4.3785],
          [117.8025, 4.37875],
          [117.80325, 4.38],
          [117.8035, 4.3805],
          [117.80375, 4.38025],
          [117.80425, 4.38025],
          [117.80425, 4.38075],
          [117.80425, 4.382],
          [117.80475, 4.3825],
          [117.80375, 4.38325],
          [117.8035, 4.38175],
          [117.802, 4.38325],
          [117.802, 4.385],
          [117.802, 4.3855],
          [117.8005, 4.38675],
          [117.8005, 4.38875],
          [117.8, 4.38925],
          [117.7995, 4.3885],
          [117.79925, 4.38875],
          [117.799, 4.3895],
          [117.79825, 4.38975],
          [117.7985, 4.39025],
          [117.79825, 4.391],
          [117.79725, 4.392],
          [117.7975, 4.39225],
          [117.798, 4.39225],
          [117.79825, 4.393],
          [117.7975, 4.393],
          [117.7955, 4.392],
          [117.79525, 4.39125],
          [117.79475, 4.39175],
          [117.7965, 4.3895],
          [117.79675, 4.38925],
          [117.79625, 4.38925],
          [117.79575, 4.3895],
          [117.7955, 4.38925],
          [117.79575, 4.38875],
          [117.796, 4.3885],
          [117.79575, 4.3875],
          [117.7965, 4.38725],
          [117.798, 4.3855],
          [117.7985, 4.38375],
          [117.798, 4.38275],
          [117.79725, 4.38225],
          [117.79675, 4.38275],
          [117.7955, 4.3835],
          [117.79475, 4.385],
          [117.7945, 4.38475],
          [117.7945, 4.38325],
          [117.79475, 4.38275],
          [117.7955, 4.38225],
          [117.79525, 4.3815],
          [117.797, 4.3805],
          [117.7965, 4.37975],
          [117.7965, 4.3785],
          [117.79475, 4.37975],
          [117.7945, 4.37675],
          [117.79525, 4.37725],
          [117.7955, 4.377],
          [117.7955, 4.376],
          [117.796, 4.3755],
          [117.7955, 4.375],
          [117.79675, 4.373],
          [117.79725, 4.37275],
          [117.7985, 4.372],
          [117.79925, 4.3725],
          [117.79925, 4.371],
          [117.79875, 4.3705],
          [117.79825, 4.36975],
          [117.798, 4.37025],
          [117.7985, 4.371],
          [117.79725, 4.37175],
          [117.79675, 4.37125],
          [117.7965, 4.371],
          [117.79725, 4.3705],
          [117.797, 4.37025],
          [117.79525, 4.36925],
          [117.7945, 4.368],
          [117.79475, 4.36775],
          [117.79475, 4.36675],
          [117.795, 4.3665],
          [117.79675, 4.36575],
          [117.79675, 4.36525],
          [117.7985, 4.36275],
          [117.799, 4.3625],
          [117.79925, 4.363],
          [117.7995, 4.36275],
          [117.79975, 4.3615],
          [117.80025, 4.36125],
          [117.7995, 4.3615],
          [117.798, 4.361],
          [117.7985, 4.36],
          [117.799, 4.3595],
          [117.79925, 4.35975],
          [117.7995, 4.36],
          [117.79975, 4.35975],
          [117.7995, 4.3595],
          [117.79925, 4.35925],
          [117.79925, 4.35825],
          [117.79875, 4.35825],
          [117.797, 4.35925],
          [117.79675, 4.3585],
          [117.79675, 4.3575],
          [117.797, 4.35725],
          [117.7975, 4.35775],
          [117.7975, 4.3565],
          [117.7965, 4.3565],
          [117.796, 4.35575],
          [117.79525, 4.35625],
          [117.7945, 4.3555],
          [117.79575, 4.355],
          [117.7955, 4.35475],
          [117.7945, 4.35425],
          [117.7959477, 4.3503539],
          [117.8000763, 4.3462372],
          [117.7987586, 4.3447481],
          [117.7977924, 4.3424708],
          [117.7940151, 4.343697],
          [117.7912042, 4.3409817],
          [117.7963869, 4.3388795],
          [117.7991846, 4.3366431],
          [117.7989216, 4.3325664],
          [117.7942787, 4.3295072],
          [117.7874807, 4.3278322],
          [117.7715686, 4.3316459],
          [117.7629913, 4.3342478],
          [117.7596017, 4.335328],
          [117.7561688, 4.33932],
          [117.7535228, 4.3435266],
          [117.7516185, 4.3468445],
          [117.7507502, 4.3484723],
          [117.7489512, 4.3507073],
          [117.745372, 4.3535959],
          [117.7374966, 4.355882],
          [117.7348066, 4.3570795],
          [117.7325678, 4.3580762],
          [117.7283483, 4.3562345],
          [117.7170037, 4.3595821],
          [117.71533, 4.3698107],
          [117.7103086, 4.3742741],
          [117.7054733, 4.3727863],
          [117.6993361, 4.3772497],
          [117.6863178, 4.382829],
          [117.677019, 4.3938016],
          [117.6759819, 4.3946807],
          [117.6728632, 4.3955658],
          [117.6554254, 4.3987536],
          [117.6518739, 4.3934691],
          [117.6500509, 4.392485],
          [117.6488946, 4.3886125],
          [117.6445213, 4.385168],
          [117.6400821, 4.3843122],
          [117.6323377, 4.3854889],
          [117.6297952, 4.3903669],
          [117.6205714, 4.3965927],
          [117.6163838, 4.3961648],
          [117.6137851, 4.3954588],
          [117.6079822, 4.3957797],
          [117.6049257, 4.3943249],
          [117.6009857, 4.3961648],
          [117.5981527, 4.3962718],
          [117.5892465, 4.3995074],
          [117.5880324, 4.3974119],
          [117.58892, 4.39329],
          [117.5923352, 4.3900769],
          [117.5951843, 4.3873965],
          [117.5960412, 4.386595],
          [117.596339, 4.3852486],
          [117.5963796, 4.3817392],
          [117.5971452, 4.3777822],
          [117.5984123, 4.3739112],
          [117.5997626, 4.3722628],
          [117.6018025, 4.3699835],
          [117.6028125, 4.3697154],
          [117.6056693, 4.3686016],
          [117.6081228, 4.3660952],
          [117.6101189, 4.3651026],
          [117.6103984, 4.3641957],
          [117.6103013, 4.3628316],
          [117.6094322, 4.3612953],
          [117.6100467, 4.3580406],
          [117.6113529, 4.3570823],
          [117.6126497, 4.3561309],
          [117.6158105, 4.3599123],
          [117.6183556, 4.3623403],
          [117.6191543, 4.3601786],
          [117.6239629, 4.3536804],
          [117.626323, 4.3506167],
          [117.6285381, 4.3475183],
          [117.6290249, 4.3421205],
          [117.6278233, 4.3361296],
          [117.6266216, 4.3291116],
          [117.6224803, 4.3147758],
          [117.6220083, 4.3118658],
          [117.6208495, 4.3108388],
          [117.6215791, 4.306217],
          [117.6204633, 4.3033497],
          [117.6278509, 4.2963855],
          [117.6283168, 4.2959463],
          [117.6306772, 4.2943843],
          [117.6319431, 4.2941918],
          [117.6338314, 4.2945341],
          [117.6353764, 4.2955612],
          [117.6408695, 4.2959036],
          [117.6446461, 4.2941918],
          [117.6478022, 4.2918506],
          [117.6564907, 4.2815244],
          [117.6580357, 4.2784431],
          [117.6585495, 4.2753618],
          [117.6506374, 4.2736797],
          [117.6461688, 4.2742097],
          [117.6523697, 4.2542532],
          [117.6509976, 4.2517381],
          [117.6470493, 4.2500262],
          [117.6395237, 4.2472372],
          [117.6336598, 4.2440345],
          [117.6288532, 4.2399259],
          [117.6252483, 4.2361597],
          [117.62309316164098, 4.233557939529419],
          [117.6219868, 4.2322223],
          [117.6187252, 4.229312],
          [117.6170086, 4.228456],
          [117.6154253, 4.2279526],
          [117.6127699, 4.2229499],
          [117.6085933, 4.2150814],
          [117.6080411, 4.212485],
          [117.6073917, 4.2094318],
          [117.6068767, 4.2053231],
          [117.6051601, 4.2022415],
          [117.6034435, 4.1996735],
          [117.6035184, 4.1967606],
          [117.6035798, 4.1943697],
          [117.6036151, 4.1929966],
          [117.6055034, 4.1914558],
          [117.6063763, 4.1907901],
          [117.6072889, 4.1898743],
          [117.6096075, 4.1899311],
          [117.6106708, 4.1887354],
          [117.6121184, 4.1867137],
          [117.6136136, 4.1858881],
          [117.6149448, 4.1847789],
          [117.6155461, 4.183216],
          [117.6175702, 4.180849],
          [117.6183571, 4.1789232],
          [117.6187085, 4.1768206],
          [117.6204577, 4.1734476],
          [117.621783, 4.1708631],
          [117.6226695, 4.169028],
          [117.6229415, 4.1652514],
          [117.6229249, 4.1632848],
          [117.622981, 4.1605472],
          [117.6218269, 4.1592984],
          [117.6200946, 4.1589267],
          [117.6176914, 4.1589267],
          [117.6158097, 4.1606126],
          [117.6135697, 4.1624963],
          [117.6096233, 4.1638918],
          [117.6025852, 4.1680007],
          [117.5974353, 4.1719385],
          [117.595591250334763, 4.169705260957982],
        ],
      ],
      [
        [
          [115.9996461, 5.977407],
          [116.0016843, 5.9776885],
          [116.0026711, 5.9773829],
          [116.0042887, 5.977592],
          [116.0059872, 5.9771335],
          [116.0061537, 5.9766635],
          [116.0067799, 5.9765704],
          [116.0070953, 5.9762889],
          [116.0075321, 5.976136],
          [116.0077504, 5.9762326],
          [116.0087776, 5.9760154],
          [116.0085929, 5.9755921],
          [116.0084056, 5.9751627],
          [116.0081796, 5.9749344],
          [116.0079417, 5.9746941],
          [116.0077623, 5.9746799],
          [116.0075797, 5.9746654],
          [116.0063831, 5.9751202],
          [116.0057918, 5.9752028],
          [116.0052113, 5.9751202],
          [116.0046578, 5.9751195],
          [116.0040131, 5.9750683],
          [116.003595, 5.9746714],
          [116.0033875, 5.9746736],
          [116.0020973, 5.9746875],
          [116.0019379, 5.9745863],
          [115.9997738, 5.9741964],
          [115.999497687452674, 5.973983257810537],
          [115.9992314, 5.9737777],
          [115.99894, 5.9733832],
          [115.9981466, 5.9724331],
          [115.9978471, 5.9723123],
          [115.9974828, 5.9720386],
          [115.9965032, 5.9721593],
          [115.9963575, 5.9718856],
          [115.9960013, 5.9717568],
          [115.9958798, 5.9714186],
          [115.995637, 5.9712012],
          [115.9955398, 5.9708872],
          [115.995386, 5.9707744],
          [115.9952058, 5.9707786],
          [115.9951336, 5.9708293],
          [115.9950433, 5.9711936],
          [115.9950036, 5.97176],
          [115.9957638, 5.9736504],
          [115.9969771, 5.9752512],
          [115.998441, 5.9762808],
          [115.9996461, 5.977407],
        ],
      ],
      [
        [
          [116.0717285, 6.0698522],
          [116.0725339, 6.0711708],
          [116.0725529, 6.0715954],
          [116.0724566, 6.0720189],
          [116.0719896, 6.0727282],
          [116.071451, 6.0733646],
          [116.0713105, 6.0736124],
          [116.0713019, 6.0738994],
          [116.071386, 6.0741865],
          [116.0715498, 6.074353],
          [116.0717481, 6.0743893],
          [116.0718999, 6.0743008],
          [116.0718757, 6.0741385],
          [116.0718695, 6.0739161],
          [116.0719816, 6.0737331],
          [116.0722728, 6.0734469],
          [116.0725336, 6.0733225],
          [116.0728397, 6.0732847],
          [116.0734451, 6.0733655],
          [116.0742679, 6.0735454],
          [116.0748681, 6.0738724],
          [116.0754397, 6.0742268],
          [116.0760206, 6.0745492],
          [116.0767635, 6.0751718],
          [116.0773582, 6.0755663],
          [116.0781875, 6.0762986],
          [116.078594, 6.0765865],
          [116.0788579, 6.0766858],
          [116.0791224, 6.0767042],
          [116.079359, 6.0766841],
          [116.0795194, 6.0767206],
          [116.079696, 6.0768599],
          [116.0798081, 6.0770094],
          [116.0799653, 6.0770843],
          [116.0800516, 6.0771774],
          [116.080175, 6.0772367],
          [116.0802775, 6.0772637],
          [116.0803984, 6.0772262],
          [116.0804319, 6.0771232],
          [116.0803535, 6.0769127],
          [116.0802461, 6.076701],
          [116.0802104, 6.0766289],
          [116.0801153, 6.0765856],
          [116.0799465, 6.0765842],
          [116.0798328, 6.076555],
          [116.0797314, 6.0764215],
          [116.0795083, 6.0759407],
          [116.0791015, 6.074996],
          [116.0787562, 6.074229],
          [116.0786105, 6.073529],
          [116.0785915, 6.0726168],
          [116.078532, 6.0721096],
          [116.0783858, 6.0717624],
          [116.078351, 6.0714475],
          [116.0783811, 6.0711823],
          [116.0786044, 6.0706895],
          [116.078794, 6.070178],
          [116.0788739, 6.0699657],
          [116.0789628, 6.0695356],
          [116.0791587, 6.0690555],
          [116.0793756, 6.0686796],
          [116.0795347, 6.0685094],
          [116.0795523, 6.0683704],
          [116.0794724, 6.0682388],
          [116.0790818, 6.0678727],
          [116.0788634, 6.0676022],
          [116.0787736, 6.0673452],
          [116.0786991, 6.0672177],
          [116.0786239, 6.0671567],
          [116.0785324, 6.0671199],
          [116.0782139, 6.066992],
          [116.077508, 6.0667102],
          [116.0768687, 6.066472],
          [116.0764311, 6.0662861],
          [116.0762352, 6.0661267],
          [116.0760649, 6.065924],
          [116.0758971, 6.0657029],
          [116.0758109, 6.065463],
          [116.0757317, 6.0651905],
          [116.0756693, 6.0648482],
          [116.0756702, 6.0645935],
          [116.075742, 6.0641775],
          [116.0757804, 6.0636042],
          [116.0756382, 6.063421],
          [116.075479, 6.0630298],
          [116.0753391, 6.0624614],
          [116.0753271, 6.0623128],
          [116.075146, 6.0619773],
          [116.0749606, 6.0616315],
          [116.0747721, 6.0612696],
          [116.0747314, 6.0610438],
          [116.0745133, 6.0607759],
          [116.0742358, 6.0606101],
          [116.0739488, 6.0604387],
          [116.0736209, 6.0603573],
          [116.0730079, 6.0602997],
          [116.072562, 6.0602382],
          [116.0719644, 6.0601892],
          [116.0715916, 6.0601048],
          [116.0714797, 6.0600547],
          [116.071344, 6.0598518],
          [116.0712368, 6.0596489],
          [116.0709277, 6.0596244],
          [116.0706897, 6.0594918],
          [116.0703449, 6.0595565],
          [116.0699158, 6.0597187],
          [116.0697046, 6.0597455],
          [116.0694265, 6.0595371],
          [116.0691759, 6.0594729],
          [116.0685573, 6.0593577],
          [116.0684135, 6.0592502],
          [116.0680098, 6.0587862],
          [116.0677325, 6.0585661],
          [116.0676409, 6.0589691],
          [116.06802, 6.0594663],
          [116.0682867, 6.0600189],
          [116.0685186, 6.0608295],
          [116.0686959, 6.0618214],
          [116.0686184, 6.0626029],
          [116.0686421, 6.0635191],
          [116.0687933, 6.0647042],
          [116.0690332, 6.0654691],
          [116.069419, 6.0661691],
          [116.0698372, 6.0667373],
          [116.0699773, 6.0671754],
          [116.0699643, 6.0675065],
          [116.0698252, 6.067669],
          [116.0695241, 6.0679522],
          [116.0694056, 6.0681553],
          [116.0694322, 6.068416],
          [116.0695418, 6.0686681],
          [116.0697913, 6.0688424],
          [116.0700313, 6.0689267],
          [116.0704319, 6.0688648],
          [116.0707929, 6.0688705],
          [116.0710525, 6.0690183],
          [116.0712926, 6.0692804],
          [116.0717285, 6.0698522],
        ],
      ],
      [
        [
          [117.6960075, 6.0480615],
          [117.6963776, 6.0480882],
          [117.696898, 6.0480882],
          [117.6973218, 6.0480028],
          [117.6979011, 6.0478268],
          [117.6983249, 6.0475494],
          [117.6984483, 6.0471386],
          [117.6985663, 6.0466158],
          [117.6986521, 6.0459917],
          [117.6986897, 6.0455489],
          [117.6982981, 6.0452608],
          [117.6980299, 6.0449141],
          [117.6977831, 6.0446047],
          [117.6975095, 6.0441086],
          [117.6972574, 6.0436285],
          [117.6972038, 6.0430843],
          [117.6971877, 6.0423748],
          [117.6971555, 6.041724],
          [117.6972574, 6.0411532],
          [117.6973915, 6.0406357],
          [117.6975685, 6.0402143],
          [117.6976812, 6.0397715],
          [117.6977777, 6.0392221],
          [117.697767, 6.0387259],
          [117.6975793, 6.0380964],
          [117.6974827, 6.037595],
          [117.6975095, 6.0372162],
          [117.6971716, 6.0368161],
          [117.6967478, 6.0366561],
          [117.6963454, 6.0367361],
          [117.6957178, 6.0368641],
          [117.6952833, 6.0370508],
          [117.6947898, 6.0368535],
          [117.6948649, 6.0365014],
          [117.695058, 6.0360639],
          [117.6953852, 6.0359306],
          [117.6957017, 6.0357759],
          [117.6952779, 6.0354771],
          [117.6948112, 6.0352797],
          [117.6944196, 6.0351677],
          [117.6940012, 6.0353864],
          [117.6937491, 6.0359252],
          [117.6936096, 6.036432],
          [117.6934701, 6.0369975],
          [117.6934487, 6.0373709],
          [117.6933843, 6.0379097],
          [117.6933038, 6.0383845],
          [117.6933896, 6.0388913],
          [117.6936364, 6.0393928],
          [117.693792, 6.0399262],
          [117.6938993, 6.040481],
          [117.6941138, 6.0407904],
          [117.6943821, 6.0412812],
          [117.694661, 6.041772],
          [117.6948219, 6.0424228],
          [117.6948273, 6.0428923],
          [117.6947361, 6.0435378],
          [117.6946503, 6.0441139],
          [117.6948112, 6.0444393],
          [117.6950526, 6.0449781],
          [117.695176, 6.0455862],
          [117.6952511, 6.0462317],
          [117.6953423, 6.0467972],
          [117.6953798, 6.0473467],
          [117.695691, 6.0476187],
          [117.6960075, 6.0480615],
        ],
      ],
      [
        [
          [117.689534800046246, 5.9995],
          [117.6898243, 6.0013234],
          [117.6918842, 6.0050792],
          [117.6908543, 6.0074693],
          [117.6908543, 6.0098594],
          [117.6915409, 6.0102008],
          [117.6951458, 6.0110544],
          [117.6977207, 6.0117373],
          [117.6966908, 6.0146394],
          [117.6973774, 6.0177123],
          [117.698064, 6.0192488],
          [117.7037289, 6.0189073],
          [117.7049305, 6.0175416],
          [117.7073338, 6.0165173],
          [117.7090504, 6.0168588],
          [117.710252, 6.0168588],
          [117.7116253, 6.0158345],
          [117.7124836, 6.0102008],
          [117.7150585, 6.0040549],
          [117.717062305815475, 5.9995],
          [117.7174618, 5.9985919],
          [117.7174618, 5.9907386],
          [117.7157452, 5.9840804],
          [117.7154018, 5.9823731],
          [117.7140286, 5.9823731],
          [117.7105953, 5.9789585],
          [117.7061321, 5.9746903],
          [117.7011539, 5.9673489],
          [117.6960041, 5.9600075],
          [117.6936008, 5.9577879],
          [117.6905109, 5.9576172],
          [117.6870777, 5.9576172],
          [117.6850178, 5.9569342],
          [117.6831295, 5.954544],
          [117.6810696, 5.9502756],
          [117.6815846, 5.9463486],
          [117.6803829, 5.9444705],
          [117.6784946, 5.9417387],
          [117.6766064, 5.9405435],
          [117.6733448, 5.9410557],
          [117.6707699, 5.9413972],
          [117.6683666, 5.9415679],
          [117.6654484, 5.940885],
          [117.6632168, 5.9415679],
          [117.6644184, 5.9444705],
          [117.6663067, 5.9477145],
          [117.66768, 5.9516415],
          [117.6693966, 5.9540318],
          [117.6724865, 5.9553976],
          [117.6757481, 5.9567635],
          [117.6807262, 5.959666],
          [117.6851894, 5.9627392],
          [117.6881077, 5.9671782],
          [117.6886227, 5.9705928],
          [117.6881077, 5.9746903],
          [117.6872494, 5.9787878],
          [117.6860477, 5.9837389],
          [117.6860477, 5.9869827],
          [117.6870777, 5.9883485],
          [117.687936, 5.9910801],
          [117.688451, 5.9953481],
          [117.6891377, 5.9963725],
          [117.6893093, 5.9980797],
          [117.689534800046246, 5.9995],
        ],
      ],
      [
        [
          [116.0056396, 6.0075136],
          [116.0055248, 6.0072163],
          [116.0054162, 6.0069321],
          [116.0052842, 6.0067883],
          [116.0051276, 6.0066837],
          [116.0050466, 6.0067614],
          [116.0049956, 6.0067168],
          [116.0049658, 6.0066146],
          [116.0049205, 6.006588],
          [116.0049162, 6.0067059],
          [116.0048315, 6.0067784],
          [116.0048001, 6.00701],
          [116.0048239, 6.0071911],
          [116.0047488, 6.0073554],
          [116.0046389, 6.0075467],
          [116.0046534, 6.0077032],
          [116.0046362, 6.0078761],
          [116.00474, 6.0080564],
          [116.0047151, 6.0081778],
          [116.0047869, 6.008237],
          [116.0047574, 6.0083288],
          [116.0047263, 6.0084896],
          [116.0046853, 6.0086262],
          [116.0046131, 6.0087115],
          [116.004667, 6.0087908],
          [116.0045933, 6.0090452],
          [116.0044093, 6.0094936],
          [116.0042424, 6.0096865],
          [116.0040866, 6.0099706],
          [116.0039557, 6.0103126],
          [116.0038887, 6.0106535],
          [116.0039042, 6.0108482],
          [116.0041217, 6.0115068],
          [116.004688, 6.0119402],
          [116.0052443, 6.0121155],
          [116.0055758, 6.0121294],
          [116.0058072, 6.0121184],
          [116.006002, 6.0120035],
          [116.0060626, 6.011837],
          [116.0061055, 6.0116226],
          [116.0061777, 6.0114465],
          [116.0063678, 6.0113948],
          [116.0065722, 6.0113753],
          [116.0067085, 6.0113161],
          [116.0068375, 6.0113998],
          [116.0069914, 6.0113371],
          [116.007118, 6.0112635],
          [116.0071693, 6.0111427],
          [116.0072404, 6.0110434],
          [116.0072465, 6.0108951],
          [116.0072481, 6.010745],
          [116.00736, 6.0105817],
          [116.0074069, 6.0104195],
          [116.0073128, 6.0103099],
          [116.0073844, 6.0102424],
          [116.0074485, 6.0101688],
          [116.0073881, 6.0100968],
          [116.0073919, 6.0100181],
          [116.0075788, 6.00965],
          [116.0076649, 6.0095643],
          [116.0078948, 6.0094056],
          [116.0081075, 6.0093064],
          [116.0082875, 6.0093104],
          [116.0083629, 6.0092578],
          [116.00852, 6.009172],
          [116.0087457, 6.0092351],
          [116.0089402, 6.009224],
          [116.009022, 6.0090375],
          [116.0088853, 6.0088903],
          [116.0087636, 6.0086987],
          [116.0086485, 6.0085462],
          [116.0085683, 6.0083245],
          [116.0086404, 6.008429],
          [116.0085774, 6.0081835],
          [116.0085871, 6.0079572],
          [116.0087097, 6.0077393],
          [116.0085697, 6.0075691],
          [116.0084433, 6.0075704],
          [116.0082223, 6.0076806],
          [116.0079847, 6.007739],
          [116.0077307, 6.0076824],
          [116.0075204, 6.0076662],
          [116.0073906, 6.0077942],
          [116.0071245, 6.0078543],
          [116.0068302, 6.0080439],
          [116.0066615, 6.0080924],
          [116.0065301, 6.0080223],
          [116.0064032, 6.0078809],
          [116.0063252, 6.0077742],
          [116.0062318, 6.0078059],
          [116.0061503, 6.0078062],
          [116.0061012, 6.0077131],
          [116.0060296, 6.0077003],
          [116.0059304, 6.0078174],
          [116.0057928, 6.0077806],
          [116.0056576, 6.0076942],
          [116.0056396, 6.0075136],
        ],
      ],
      [
        [
          [116.069851, 6.0133548],
          [116.0700098, 6.0136335],
          [116.0703554, 6.0135684],
          [116.0703928, 6.0132805],
          [116.0701312, 6.0131319],
          [116.0697109, 6.013039],
          [116.0692907, 6.0129833],
          [116.0691225, 6.0131876],
          [116.0693654, 6.0133548],
          [116.0696176, 6.0132433],
          [116.069851, 6.0133548],
        ],
      ],
      [
        [
          [116.038008965920056, 6.0005],
          [116.0382884, 6.0007853],
          [116.039161, 6.0013146],
          [116.0392913, 6.0024407],
          [116.0389135, 6.00354],
          [116.0384964, 6.0038843],
          [116.0381982, 6.0038887],
          [116.0379134, 6.0043866],
          [116.0363702, 6.0055833],
          [116.0357731, 6.0056245],
          [116.0355733, 6.0061498],
          [116.035637, 6.0067134],
          [116.035145, 6.0074316],
          [116.0349768, 6.0079429],
          [116.0350497, 6.0088563],
          [116.0348299, 6.0094182],
          [116.0343944, 6.0095229],
          [116.0339079, 6.0095412],
          [116.0336598, 6.0089701],
          [116.0332223, 6.0083828],
          [116.0328205, 6.0082929],
          [116.0327352, 6.0084185],
          [116.0328669, 6.0088736],
          [116.032789, 6.0091125],
          [116.0319874, 6.0098431],
          [116.0318768, 6.0101105],
          [116.0318732, 6.0111238],
          [116.0314998, 6.0128515],
          [116.0312424, 6.012955],
          [116.030758, 6.012641],
          [116.0305437, 6.012206],
          [116.0300305, 6.0120595],
          [116.0293488, 6.0120695],
          [116.0289945, 6.0126904],
          [116.0282682, 6.0126425],
          [116.0277263, 6.0117114],
          [116.0272646, 6.0108393],
          [116.0267274, 6.0104796],
          [116.0264141, 6.0098198],
          [116.0265835, 6.0095881],
          [116.0262616, 6.00903],
          [116.026073, 6.0090863],
          [116.025978, 6.0093368],
          [116.0257065, 6.0095161],
          [116.0254194, 6.0094299],
          [116.0253417, 6.0083897],
          [116.0252727, 6.0075481],
          [116.0255211, 6.0071135],
          [116.0251793, 6.0062616],
          [116.0246941, 6.005753],
          [116.0242631, 6.005709],
          [116.0239504, 6.0058965],
          [116.0234157, 6.0066691],
          [116.022992, 6.0082117],
          [116.022513, 6.0100437],
          [116.0221569, 6.0111375],
          [116.0219714, 6.0117072],
          [116.0217416, 6.0122295],
          [116.0214155, 6.0125562],
          [116.0211019, 6.0128065],
          [116.0199192, 6.0125646],
          [116.0195839, 6.0123851],
          [116.0195176, 6.0121839],
          [116.0197335, 6.0116239],
          [116.0197748, 6.011215],
          [116.0197385, 6.0107778],
          [116.0195141, 6.0106658],
          [116.0192679, 6.0103495],
          [116.0190857, 6.0104723],
          [116.018802, 6.0112125],
          [116.0187217, 6.0118132],
          [116.0184558, 6.0128816],
          [116.0181761, 6.012995],
          [116.0181721, 6.0133016],
          [116.0174494, 6.0135565],
          [116.017011, 6.0143734],
          [116.0167163, 6.0141886],
          [116.016327, 6.0143311],
          [116.0163629, 6.0135683],
          [116.0158878, 6.0131517],
          [116.0155119, 6.0122213],
          [116.0158548, 6.011913],
          [116.0156354, 6.0112827],
          [116.0148232, 6.0106005],
          [116.0141729, 6.0108587],
          [116.0137741, 6.0106345],
          [116.0131579, 6.0104423],
          [116.0127857, 6.0107921],
          [116.0126015, 6.0109273],
          [116.0120292, 6.0111089],
          [116.0116757, 6.0109473],
          [116.0112181, 6.0113358],
          [116.0109002, 6.0114463],
          [116.0107419, 6.0118885],
          [116.0101893, 6.0121159],
          [116.0098472, 6.0120946],
          [116.0097012, 6.0119275],
          [116.008793, 6.0121128],
          [116.0084613, 6.0122988],
          [116.008003, 6.0128047],
          [116.0079799, 6.013394],
          [116.0073258, 6.0142742],
          [116.0073603, 6.0154531],
          [116.0070985, 6.0158744],
          [116.0070697, 6.0168362],
          [116.0076567, 6.0190423],
          [116.0073868, 6.0198526],
          [116.0065092, 6.0206098],
          [116.0052013, 6.0225456],
          [116.0048703, 6.0243146],
          [116.0055172, 6.0260605],
          [116.0061175, 6.0279968],
          [116.0062253, 6.0300096],
          [116.0066254, 6.0317163],
          [116.0087187, 6.0358414],
          [116.0100809, 6.0379614],
          [116.0106812, 6.0394308],
          [116.0107351, 6.04064],
          [116.0109588, 6.0412958],
          [116.0114277, 6.0418874],
          [116.0123764, 6.0424813],
          [116.014366, 6.0440521],
          [116.0150099, 6.0430133],
          [116.0162453, 6.0427446],
          [116.017469, 6.0424079],
          [116.0176591, 6.0414915],
          [116.0165839, 6.0400507],
          [116.0168985, 6.039636],
          [116.0167721, 6.0385719],
          [116.0174666, 6.0379257],
          [116.0176306, 6.0378236],
          [116.0173198, 6.0368662],
          [116.0177078, 6.03651],
          [116.0174151, 6.0357343],
          [116.017481, 6.0353141],
          [116.0176867, 6.034971],
          [116.0177152, 6.0339281],
          [116.0174305, 6.0319995],
          [116.0171196, 6.0309458],
          [116.0173384, 6.0301458],
          [116.0175777, 6.0295329],
          [116.0176995, 6.0291594],
          [116.0182656, 6.0283732],
          [116.0187696, 6.0280039],
          [116.0194436, 6.0277979],
          [116.0206456, 6.0277554],
          [116.0215339, 6.0278229],
          [116.0219098, 6.0279831],
          [116.0223994, 6.0288973],
          [116.0235564, 6.030155],
          [116.024852, 6.0311565],
          [116.0252227, 6.0323577],
          [116.0257021, 6.0329413],
          [116.0262808, 6.0336985],
          [116.0273275, 6.0342346],
          [116.0274828, 6.0341197],
          [116.0271413, 6.033333],
          [116.0269862, 6.0327325],
          [116.0278122, 6.0323515],
          [116.0276839, 6.0313845],
          [116.0278661, 6.0310352],
          [116.027843, 6.0293361],
          [116.0277644, 6.0290435],
          [116.0279032, 6.0288127],
          [116.0284505, 6.0284468],
          [116.028858, 6.028328],
          [116.0290692, 6.0284299],
          [116.030066, 6.0278314],
          [116.0308454, 6.027727],
          [116.0310307, 6.0271065],
          [116.0308598, 6.0264508],
          [116.0300517, 6.0264049],
          [116.0294261, 6.0254162],
          [116.0289678, 6.0253688],
          [116.028657, 6.0249817],
          [116.0289296, 6.0242293],
          [116.0295438, 6.0239711],
          [116.0310907, 6.0244686],
          [116.0319218, 6.0241854],
          [116.0336332, 6.0246015],
          [116.0345308, 6.0260299],
          [116.0369722, 6.0265018],
          [116.0381404, 6.0261657],
          [116.0397342, 6.0263952],
          [116.0406182, 6.0272085],
          [116.04118, 6.0284713],
          [116.0418111, 6.0298489],
          [116.0420342, 6.0307979],
          [116.0428115, 6.0316245],
          [116.0436273, 6.0310811],
          [116.0449916, 6.0309171],
          [116.0465299, 6.0311381],
          [116.0470058, 6.0308592],
          [116.0483987, 6.0312265],
          [116.0492828, 6.0312441],
          [116.0499625, 6.0313633],
          [116.0504192, 6.0316177],
          [116.0505395, 6.0315377],
          [116.0501311, 6.0308226],
          [116.0502535, 6.0302698],
          [116.0498007, 6.0295194],
          [116.0498186, 6.0293426],
          [116.0498433, 6.0288513],
          [116.0499238, 6.0283136],
          [116.0501046, 6.0278965],
          [116.0504556, 6.0275725],
          [116.0507126, 6.0274655],
          [116.0510907, 6.0274699],
          [116.0513496, 6.0272711],
          [116.0514092, 6.0267684],
          [116.0515413, 6.0265707],
          [116.0514657, 6.0258544],
          [116.0510769, 6.0256625],
          [116.0510738, 6.0253254],
          [116.0512324, 6.0251577],
          [116.0511666, 6.0248052],
          [116.0507794, 6.0246847],
          [116.0504997, 6.0242696],
          [116.0500296, 6.0239586],
          [116.0499653, 6.0232375],
          [116.0499457, 6.0230583],
          [116.0495769, 6.0222778],
          [116.0490267, 6.0218177],
          [116.048744, 6.0213079],
          [116.0481888, 6.0210141],
          [116.0479629, 6.0206776],
          [116.047933, 6.0201041],
          [116.0477027, 6.0193211],
          [116.0475199, 6.0179227],
          [116.0471079, 6.0174764],
          [116.0471206, 6.0168584],
          [116.047004, 6.0166187],
          [116.046852, 6.016431],
          [116.0465979, 6.0159809],
          [116.0455342, 6.0153206],
          [116.0454028, 6.0147484],
          [116.0455936, 6.0143731],
          [116.0454047, 6.0138141],
          [116.0459727, 6.0134559],
          [116.0464771, 6.0128494],
          [116.0465614, 6.0124342],
          [116.0465137, 6.0122969],
          [116.0470495, 6.0120926],
          [116.0473627, 6.0123719],
          [116.0477302, 6.0125431],
          [116.0479674, 6.0126411],
          [116.0483009, 6.0126169],
          [116.048363, 6.0126183],
          [116.0492215, 6.012327],
          [116.0494287, 6.0122182],
          [116.0496328, 6.0121914],
          [116.0498566, 6.0122438],
          [116.050196, 6.0124355],
          [116.0504423, 6.012661],
          [116.0506457, 6.0128605],
          [116.0508605, 6.0129537],
          [116.0507214, 6.0124446],
          [116.0507141, 6.0121314],
          [116.0505661, 6.0119622],
          [116.0502878, 6.0116441],
          [116.0500734, 6.0112154],
          [116.0503186, 6.0105465],
          [116.0509101, 6.0099284],
          [116.0510808, 6.0096752],
          [116.0508928, 6.0094445],
          [116.0506578, 6.0092139],
          [116.0506583, 6.0089165],
          [116.0509066, 6.0084621],
          [116.0514001, 6.007738],
          [116.0519132, 6.0072358],
          [116.0523187, 6.0070003],
          [116.0527241, 6.0068628],
          [116.0534611, 6.006637],
          [116.0546638, 6.006651],
          [116.0566923, 6.007143],
          [116.0578791, 6.007916],
          [116.0583264, 6.0082968],
          [116.0582726, 6.008955],
          [116.0586112, 6.00933],
          [116.0586574, 6.0098198],
          [116.0590224, 6.0101078],
          [116.059604, 6.0107306],
          [116.0595732, 6.0114807],
          [116.0600041, 6.0118786],
          [116.0604132, 6.0125571],
          [116.0604304, 6.0132193],
          [116.0596899, 6.013831],
          [116.0605198, 6.0142589],
          [116.0608507, 6.014986],
          [116.0616187, 6.0151622],
          [116.0622509, 6.0161277],
          [116.0622371, 6.0167322],
          [116.0627562, 6.0172839],
          [116.063248, 6.0174884],
          [116.0633568, 6.0173166],
          [116.0627439, 6.0140293],
          [116.0628206, 6.0127402],
          [116.0637136, 6.0117255],
          [116.0638213, 6.0113965],
          [116.0633365, 6.0105163],
          [116.0627362, 6.0104704],
          [116.062336, 6.0099193],
          [116.0611124, 6.0049598],
          [116.0605198, 6.0049445],
          [116.0597117, 6.002059],
          [116.0602735, 6.0009263],
          [116.060738703707074, 6.0005],
          [116.0613509, 5.999939],
          [116.0623129, 6.0003905],
          [116.0631826, 5.9996864],
          [116.0645884, 5.999227],
          [116.065292674442546, 5.9995],
          [116.0656412, 5.9996351],
          [116.0662569, 5.999227],
          [116.0661944, 5.9987478],
          [116.0654806, 5.9984993],
          [116.0648115, 5.9974611],
          [116.0639549, 5.9965915],
          [116.06383, 5.9954912],
          [116.0624381, 5.9957663],
          [116.0622597, 5.9968932],
          [116.0613585, 5.9979935],
          [116.0605555, 5.9984461],
          [116.0594759, 5.9987922],
          [116.0592261, 5.9984195],
          [116.0578342, 5.9985348],
          [116.0573613, 5.9979758],
          [116.057397, 5.9988099],
          [116.0567317, 5.9996194],
          [116.0570038, 6.0001957],
          [116.0576638, 6.0005682],
          [116.0578243, 6.0015704],
          [116.0565668, 6.0028032],
          [116.0561655, 6.0028831],
          [116.0555056, 6.0022179],
          [116.0549348, 6.0023864],
          [116.0543016, 6.0030871],
          [116.0531868, 6.0035394],
          [116.0509744, 6.0041092],
          [116.0502372, 6.0044142],
          [116.0501847, 6.0038193],
          [116.0501527, 6.0032549],
          [116.0497469, 6.0028502],
          [116.0495973, 6.002067],
          [116.0491792, 6.0008873],
          [116.049021869184628, 6.0005],
          [116.048955059939971, 6.000335536220961],
          [116.048615644512878, 5.9995],
          [116.0484274, 5.9990366],
          [116.0477723, 5.998523],
          [116.0474784, 5.9977496],
          [116.0467451, 5.9972103],
          [116.0467883, 5.9968242],
          [116.0465726, 5.9965914],
          [116.0453525, 5.9958008],
          [116.0450277, 5.995187],
          [116.0442531, 5.9950077],
          [116.0433745, 5.9941339],
          [116.0423885, 5.9930921],
          [116.0419942, 5.9928408],
          [116.0416922, 5.9922708],
          [116.0414026, 5.9921176],
          [116.0411723, 5.991711],
          [116.0408489, 5.9916827],
          [116.0407316, 5.9920959],
          [116.0408568, 5.9927369],
          [116.040466, 5.9933494],
          [116.0399853, 5.9937049],
          [116.0396464, 5.9936007],
          [116.0388453, 5.9934475],
          [116.0380812, 5.993852],
          [116.0376377, 5.9948103],
          [116.0376621, 5.9956586],
          [116.0373393, 5.9981196],
          [116.0373898, 5.9988048],
          [116.0371748, 5.9992591],
          [116.0374357, 5.9999147],
          [116.038008965920056, 6.0005],
        ],
      ],
      [
        [
          [117.6982357, 6.0236873],
          [117.698064, 6.0265895],
          [117.698579, 6.0294916],
          [117.6999523, 6.0317108],
          [117.7011539, 6.0318815],
          [117.7025272, 6.0335886],
          [117.7047588, 6.0349543],
          [117.7064755, 6.0352957],
          [117.7095654, 6.0342715],
          [117.7142002, 6.0334179],
          [117.7148869, 6.0346129],
          [117.7164318, 6.0356372],
          [117.7186634, 6.0359786],
          [117.7227833, 6.0352957],
          [117.7243282, 6.0330765],
          [117.7248432, 6.0267602],
          [117.7248432, 6.0224923],
          [117.7239849, 6.0202731],
          [117.721925, 6.0189073],
          [117.719865, 6.0168588],
          [117.7174618, 6.0139566],
          [117.7148869, 6.014298],
          [117.7136852, 6.0151516],
          [117.7128269, 6.016688],
          [117.7109386, 6.0175416],
          [117.7088787, 6.0175416],
          [117.7059605, 6.0192488],
          [117.7042439, 6.0202731],
          [117.6982357, 6.0236873],
        ],
      ],
      [
        [
          [117.9651248, 6.0710905],
          [117.9652537, 6.0703623],
          [117.9650766, 6.0692402],
          [117.9644663, 6.0686173],
          [117.9636939, 6.0684905],
          [117.9634013, 6.0679854],
          [117.9630865, 6.0680878],
          [117.9630016, 6.0685044],
          [117.963244, 6.0686695],
          [117.963304, 6.0691244],
          [117.9634989, 6.0692959],
          [117.963349, 6.0696538],
          [117.9633891, 6.0700796],
          [117.9635889, 6.0701982],
          [117.9635739, 6.0708544],
          [117.9637314, 6.0711975],
          [117.9642714, 6.0711975],
          [117.9647813, 6.0715107],
          [117.9651248, 6.0710905],
        ],
      ],
      [
        [
          [116.0877415, 6.0794125],
          [116.0876976, 6.0795203],
          [116.087762, 6.0797417],
          [116.0878177, 6.0798117],
          [116.0878968, 6.0799341],
          [116.0879466, 6.0800389],
          [116.0880082, 6.0801234],
          [116.0880638, 6.0802429],
          [116.0880902, 6.0803828],
          [116.08814, 6.0804702],
          [116.0882279, 6.0805314],
          [116.0883744, 6.0805926],
          [116.0884887, 6.0806508],
          [116.0885708, 6.0806858],
          [116.0886323, 6.0807149],
          [116.0886733, 6.0807412],
          [116.08877, 6.0807557],
          [116.0888433, 6.0807062],
          [116.0889458, 6.0806771],
          [116.0890045, 6.0806654],
          [116.0890836, 6.0805256],
          [116.0891158, 6.0804498],
          [116.0890748, 6.0803915],
          [116.0890279, 6.0803595],
          [116.0890191, 6.0803216],
          [116.0890103, 6.0802721],
          [116.0889986, 6.0802109],
          [116.0890162, 6.0801555],
          [116.0890513, 6.0801118],
          [116.0890513, 6.0800273],
          [116.0890132, 6.0799224],
          [116.0889634, 6.0797796],
          [116.0889107, 6.0796951],
          [116.0888931, 6.0796368],
          [116.0888697, 6.0795582],
          [116.0888697, 6.0794678],
          [116.0888697, 6.0793863],
          [116.0888697, 6.0792755],
          [116.0888755, 6.0791532],
          [116.088855, 6.0790395],
          [116.0888404, 6.0789346],
          [116.0887905, 6.0788938],
          [116.0887583, 6.0788152],
          [116.088688, 6.0787627],
          [116.0886323, 6.0787307],
          [116.0885708, 6.0786928],
          [116.0885151, 6.0786432],
          [116.0884624, 6.078585],
          [116.0883158, 6.0785646],
          [116.0882279, 6.07855],
          [116.0881107, 6.0785354],
          [116.0880228, 6.0785034],
          [116.0879437, 6.0784597],
          [116.087888, 6.0784072],
          [116.0878411, 6.0783402],
          [116.0878089, 6.0782295],
          [116.0877737, 6.0781392],
          [116.0876858, 6.0780313],
          [116.0875891, 6.0779964],
          [116.0874954, 6.0779935],
          [116.087384, 6.0779935],
          [116.0873225, 6.0780168],
          [116.0872639, 6.0780488],
          [116.0872316, 6.0780954],
          [116.0872141, 6.0781799],
          [116.0871935, 6.078244],
          [116.0871555, 6.078279],
          [116.0871672, 6.0783402],
          [116.0871848, 6.078416],
          [116.0872023, 6.0784946],
          [116.0872199, 6.0785617],
          [116.0872434, 6.0786199],
          [116.0872727, 6.0787044],
          [116.087302, 6.0788035],
          [116.0873371, 6.0788559],
          [116.0873899, 6.0789404],
          [116.0874338, 6.0790395],
          [116.0874866, 6.0790949],
          [116.0875598, 6.0791415],
          [116.0876331, 6.0791852],
          [116.0876712, 6.0792493],
          [116.087721, 6.0793018],
          [116.0877532, 6.0793659],
          [116.0877415, 6.0794125],
        ],
      ],
      [
        [
          [116.1259274, 6.0844797],
          [116.125966, 6.0845199],
          [116.12602, 6.084542],
          [116.1261008, 6.0845071],
          [116.127276, 6.0837102],
          [116.1272893, 6.0836208],
          [116.1272126, 6.0834878],
          [116.127111, 6.08345],
          [116.1267208, 6.0837071],
          [116.1266589, 6.0836361],
          [116.1265796, 6.0836345],
          [116.1262401, 6.083879],
          [116.1261976, 6.0839237],
          [116.1262072, 6.0840104],
          [116.1258598, 6.0842502],
          [116.1258249, 6.0843054],
          [116.1258578, 6.0843771],
          [116.1259274, 6.0844797],
        ],
      ],
      [
        [
          [116.0963198, 6.0862564],
          [116.0964749, 6.0863093],
          [116.0965635, 6.0863137],
          [116.0966166, 6.0862476],
          [116.0966831, 6.0860494],
          [116.0967052, 6.0859481],
          [116.0966742, 6.08586],
          [116.0965989, 6.0858071],
          [116.0961958, 6.0856662],
          [116.0957351, 6.0855076],
          [116.0956244, 6.0854548],
          [116.0955801, 6.0853931],
          [116.0956155, 6.0853535],
          [116.0956643, 6.0853226],
          [116.09562, 6.0852213],
          [116.0953852, 6.0848602],
          [116.0952745, 6.0847941],
          [116.0953232, 6.084728],
          [116.0951903, 6.0846531],
          [116.0950087, 6.0845386],
          [116.0947606, 6.0844021],
          [116.0945214, 6.0842964],
          [116.0943354, 6.0842215],
          [116.0941058, 6.0841011],
          [116.0940876, 6.0839897],
          [116.0940252, 6.0838679],
          [116.094012, 6.083781],
          [116.0939101, 6.0836445],
          [116.093764, 6.0834815],
          [116.0936045, 6.0834022],
          [116.0934982, 6.0833494],
          [116.0931704, 6.0833494],
          [116.0929844, 6.0833846],
          [116.0928603, 6.0834066],
          [116.092816, 6.0834507],
          [116.0928293, 6.0836269],
          [116.0927053, 6.0836709],
          [116.09263, 6.0836709],
          [116.0925547, 6.0837282],
          [116.0924882, 6.0838559],
          [116.0924528, 6.0840101],
          [116.0924794, 6.0841686],
          [116.0923952, 6.0841774],
          [116.0924129, 6.0842523],
          [116.0925724, 6.0844858],
          [116.0925705, 6.0845316],
          [116.0925961, 6.084593],
          [116.092692, 6.084772],
          [116.0927598, 6.0847552],
          [116.0927856, 6.0847954],
          [116.0928781, 6.0847985],
          [116.0929268, 6.0849571],
          [116.0929666, 6.0851509],
          [116.0930419, 6.0852434],
          [116.0930951, 6.0852742],
          [116.0930995, 6.085446],
          [116.0931793, 6.0855957],
          [116.0932944, 6.0857763],
          [116.0934317, 6.0859129],
          [116.0935602, 6.0860538],
          [116.0937285, 6.0861155],
          [116.0938924, 6.0861111],
          [116.0940873, 6.0860934],
          [116.0943044, 6.086045],
          [116.0944195, 6.0861199],
          [116.0950131, 6.0860318],
          [116.0953896, 6.0859261],
          [116.0963198, 6.0862564],
        ],
      ],
      [
        [
          [116.0961116, 6.0868422],
          [116.0960629, 6.0868158],
          [116.0960142, 6.0868202],
          [116.09593, 6.0869303],
          [116.095899, 6.0870184],
          [116.0959389, 6.0870977],
          [116.0959079, 6.087177],
          [116.0959788, 6.0872386],
          [116.0960629, 6.0872959],
          [116.0961692, 6.0873488],
          [116.0962312, 6.0874236],
          [116.0962977, 6.0875161],
          [116.0963553, 6.0875866],
          [116.0963464, 6.0876791],
          [116.0965856, 6.0878333],
          [116.0966343, 6.0878861],
          [116.0967362, 6.0878817],
          [116.0968027, 6.0878377],
          [116.0967539, 6.087798],
          [116.0967096, 6.0877276],
          [116.0966432, 6.0875954],
          [116.0966078, 6.0875205],
          [116.0965413, 6.0874765],
          [116.0965812, 6.0873091],
          [116.0966653, 6.0871153],
          [116.0966565, 6.0869964],
          [116.0965413, 6.0869964],
          [116.0963065, 6.0870801],
          [116.0961116, 6.0868422],
        ],
      ],
      [
        [
          [118.0185981, 6.1087402],
          [118.0171177, 6.109116],
          [118.0157318, 6.1090847],
          [118.0143773, 6.1086463],
          [118.0127709, 6.109899],
          [118.0097785, 6.1132502],
          [118.0123929, 6.113908],
          [118.0151963, 6.1145343],
          [118.0170547, 6.1154426],
          [118.0188186, 6.116852],
          [118.0186611, 6.1187625],
          [118.0194171, 6.1206729],
          [118.0207716, 6.121957],
          [118.0234489, 6.1228339],
          [118.0259058, 6.1218631],
          [118.0283627, 6.1215499],
          [118.0323946, 6.1194515],
          [118.0299377, 6.1181674],
          [118.0286147, 6.1170086],
          [118.0271028, 6.1149102],
          [118.0254649, 6.1130936],
          [118.0234489, 6.1109326],
          [118.021811, 6.1099304],
          [118.0185981, 6.1087402],
        ],
      ],
      [
        [
          [118.055264, 6.1504741],
          [118.0549084, 6.1497654],
          [118.054772, 6.14927],
          [118.0546279, 6.148736],
          [118.0543811, 6.1484366],
          [118.0537976, 6.1479003],
          [118.0529478, 6.1472819],
          [118.0531916, 6.1478946],
          [118.0535389, 6.1483682],
          [118.053908, 6.1486933],
          [118.054116, 6.1489423],
          [118.0542355, 6.1494915],
          [118.054472, 6.149856],
          [118.0548048, 6.1502127],
          [118.055264, 6.1504741],
        ],
      ],
      [
        [
          [118.1089959, 6.166857],
          [118.1090715, 6.1672076],
          [118.1094473, 6.1674682],
          [118.1098338, 6.1675901],
          [118.1103003, 6.1676809],
          [118.1107669, 6.1676117],
          [118.1111283, 6.1674414],
          [118.1113824, 6.1671751],
          [118.1114905, 6.166811],
          [118.1113679, 6.1663563],
          [118.1109425, 6.1656051],
          [118.1106222, 6.1653811],
          [118.1100276, 6.1653592],
          [118.1096663, 6.1653839],
          [118.1096277, 6.1653816],
          [118.1094873, 6.1654024],
          [118.1092464, 6.1656887],
          [118.1091665, 6.1660497],
          [118.1091319, 6.1662115],
          [118.1089364, 6.1663734],
          [118.1089959, 6.166857],
        ],
      ],
      [
        [
          [118.0610536, 6.1724098],
          [118.0609638, 6.1722934],
          [118.0609112, 6.1721204],
          [118.0608715, 6.1718138],
          [118.0608397, 6.1715221],
          [118.0607134, 6.1713129],
          [118.0606335, 6.1712878],
          [118.060503, 6.1713841],
          [118.0604399, 6.1716979],
          [118.0604176, 6.1718953],
          [118.0603691, 6.1721993],
          [118.060317, 6.1723579],
          [118.0603174, 6.1725244],
          [118.0602845, 6.1726448],
          [118.0602673, 6.1728986],
          [118.0602613, 6.1731672],
          [118.0602759, 6.1733756],
          [118.0603221, 6.1735262],
          [118.0604453, 6.1737638],
          [118.06052, 6.1739769],
          [118.0605311, 6.1741817],
          [118.0605283, 6.1744356],
          [118.060503, 6.1746057],
          [118.0604422, 6.1747612],
          [118.0603855, 6.1748502],
          [118.0603224, 6.1749552],
          [118.0603012, 6.1750896],
          [118.0602211, 6.1752416],
          [118.0602042, 6.1754383],
          [118.0602124, 6.1756133],
          [118.0602716, 6.1756893],
          [118.0603634, 6.1757855],
          [118.0604041, 6.1758812],
          [118.0605419, 6.1760877],
          [118.0606702, 6.1762891],
          [118.0608053, 6.1763832],
          [118.0609743, 6.1764089],
          [118.0611116, 6.176363],
          [118.0612762, 6.1762807],
          [118.0613964, 6.1761432],
          [118.0615172, 6.1759863],
          [118.0616343, 6.1758086],
          [118.0617104, 6.1757064],
          [118.0617941, 6.1756098],
          [118.0619189, 6.1754993],
          [118.0620685, 6.1753253],
          [118.0621755, 6.1752451],
          [118.0622399, 6.17514],
          [118.062342, 6.174932],
          [118.0624314, 6.174736],
          [118.0624637, 6.1745584],
          [118.0624617, 6.1745236],
          [118.0624682, 6.1744162],
          [118.0624548, 6.1742031],
          [118.0624209, 6.1739573],
          [118.0623312, 6.1737173],
          [118.0622663, 6.173589],
          [118.0621157, 6.1734435],
          [118.0620432, 6.1733003],
          [118.0619934, 6.1732128],
          [118.0619436, 6.1730077],
          [118.0619002, 6.1729447],
          [118.061736, 6.1728066],
          [118.0615759, 6.1726536],
          [118.0613795, 6.1725552],
          [118.0611745, 6.1725126],
          [118.0610536, 6.1724098],
        ],
      ],
      [
        [
          [117.5957743, 6.1843742],
          [117.5975284, 6.1836859],
          [117.5981747, 6.1835482],
          [117.5992364, 6.1827221],
          [117.6000211, 6.1824468],
          [117.6011751, 6.1818043],
          [117.6027907, 6.1808405],
          [117.6035293, 6.1801522],
          [117.6062989, 6.1791425],
          [117.6070837, 6.1783624],
          [117.6064836, 6.1783165],
          [117.6043602, 6.1785],
          [117.6030677, 6.1786836],
          [117.6004827, 6.1786836],
          [117.5984516, 6.178959],
          [117.5960513, 6.178959],
          [117.5939279, 6.1788672],
          [117.5925431, 6.1785918],
          [117.590512, 6.1782247],
          [117.5879732, 6.1770774],
          [117.588481, 6.1779034],
          [117.5900043, 6.1792802],
          [117.5909736, 6.1804275],
          [117.5927739, 6.1817125],
          [117.5935125, 6.182355],
          [117.5940212, 6.1827505],
          [117.5944818, 6.1834564],
          [117.5957743, 6.1843742],
        ],
      ],
      [
        [
          [117.5979368, 6.1862843],
          [117.598504, 6.1860728],
          [117.5987876, 6.1855559],
          [117.5987404, 6.1851799],
          [117.5984095, 6.184945],
          [117.597795, 6.1852034],
          [117.5973696, 6.1856264],
          [117.5977005, 6.1860023],
          [117.5979368, 6.1862843],
        ],
      ],
      [
        [
          [115.5839924, 6.2033104],
          [115.5838692, 6.2037148],
          [115.5839801, 6.2041436],
          [115.5841773, 6.2044744],
          [115.5846949, 6.2048419],
          [115.5852248, 6.205087],
          [115.5854467, 6.205332],
          [115.5861245, 6.2057241],
          [115.5871597, 6.2063367],
          [115.5881826, 6.2070105],
          [115.5890823, 6.2076109],
          [115.5898218, 6.2080152],
          [115.5905366, 6.2085788],
          [115.5911774, 6.2090444],
          [115.5918799, 6.2093507],
          [115.5923852, 6.2094487],
          [115.5929028, 6.2094364],
          [115.5936792, 6.2096325],
          [115.5942092, 6.2098775],
          [115.5947268, 6.2102818],
          [115.5953183, 6.2107106],
          [115.5960701, 6.2110169],
          [115.5971054, 6.211409],
          [115.5976969, 6.2115928],
          [115.5985473, 6.2116663],
          [115.5993114, 6.211556],
          [115.6004329, 6.2114702],
          [115.6018625, 6.211605],
          [115.6019419, 6.2116413],
          [115.6029347, 6.2120951],
          [115.6037974, 6.2122911],
          [115.6043397, 6.2122789],
          [115.6046847, 6.2120461],
          [115.6048942, 6.2116295],
          [115.6049682, 6.2108331],
          [115.6050616, 6.2100688],
          [115.6050668, 6.2100245],
          [115.6054119, 6.2088973],
          [115.6057569, 6.2078192],
          [115.6066196, 6.2061039],
          [115.6073221, 6.2039353],
          [115.6079013, 6.2023425],
          [115.6084313, 6.1999289],
          [115.6083697, 6.1995858],
          [115.6081355, 6.19901],
          [115.6078028, 6.1984341],
          [115.607433, 6.197748],
          [115.6071619, 6.1967923],
          [115.6069401, 6.1958857],
          [115.6067305, 6.1945869],
          [115.6062869, 6.1938273],
          [115.6058432, 6.1930064],
          [115.6050795, 6.1920825],
          [115.6045122, 6.1912053],
          [115.6041671, 6.1904334],
          [115.6035632, 6.1899678],
          [115.6026759, 6.1895267],
          [115.6020888, 6.189258],
          [115.6018995, 6.1891714],
          [115.6014065, 6.1890856],
          [115.6006177, 6.1891224],
          [115.5999892, 6.1890611],
          [115.5991881, 6.1888528],
          [115.5985226, 6.1885588],
          [115.5979187, 6.1884608],
          [115.5964645, 6.1883137],
          [115.5954416, 6.188179],
          [115.5945173, 6.1880319],
          [115.593975, 6.1877869],
          [115.5933711, 6.1874806],
          [115.5929398, 6.1873458],
          [115.5925208, 6.1873826],
          [115.5919169, 6.1875908],
          [115.5913007, 6.1878604],
          [115.5907461, 6.1882035],
          [115.5905242, 6.1884363],
          [115.590401, 6.1887548],
          [115.590327, 6.1892694],
          [115.5902285, 6.1898943],
          [115.5899696, 6.1907029],
          [115.5897601, 6.1913278],
          [115.5893904, 6.1921487],
          [115.5891686, 6.1926633],
          [115.5884414, 6.1941581],
          [115.5879608, 6.1950525],
          [115.5875911, 6.1955671],
          [115.5871474, 6.1960204],
          [115.586753, 6.196486],
          [115.5863587, 6.1971476],
          [115.5860629, 6.1979685],
          [115.5858534, 6.1986914],
          [115.5858534, 6.1994143],
          [115.5857671, 6.2002597],
          [115.5854467, 6.2012766],
          [115.5849783, 6.2021342],
          [115.5844854, 6.2028081],
          [115.5839924, 6.2033104],
        ],
      ],
      [
        [
          [116.2620787, 6.2316196],
          [116.2619546, 6.2314875],
          [116.2617243, 6.231417],
          [116.261618, 6.2314699],
          [116.2616357, 6.2316636],
          [116.2616711, 6.2319366],
          [116.2616623, 6.2322625],
          [116.2616091, 6.2326676],
          [116.2615205, 6.232967],
          [116.2615117, 6.2332048],
          [116.2615028, 6.2335747],
          [116.2615028, 6.2339358],
          [116.2614319, 6.2342792],
          [116.2614674, 6.2344818],
          [116.2614851, 6.2347108],
          [116.2616268, 6.2347108],
          [116.2618926, 6.2347284],
          [116.2621141, 6.2347284],
          [116.2623267, 6.234746],
          [116.2626191, 6.2348253],
          [116.2627608, 6.2349574],
          [116.2630709, 6.2349926],
          [116.2635227, 6.2349397],
          [116.2637796, 6.2349133],
          [116.2640808, 6.2348605],
          [116.2644263, 6.23479],
          [116.2646567, 6.2347196],
          [116.2648516, 6.2345434],
          [116.2649402, 6.2343849],
          [116.2648604, 6.2341295],
          [116.2647364, 6.2339358],
          [116.2645592, 6.2336892],
          [116.264258, 6.233425],
          [116.2639568, 6.2332841],
          [116.263753, 6.2330111],
          [116.2632304, 6.2325443],
          [116.2627608, 6.2321304],
          [116.262371, 6.231875],
          [116.2620787, 6.2316196],
        ],
      ],
      [
        [
          [116.2699646, 6.2431724],
          [116.2701196, 6.2430799],
          [116.2703633, 6.242895],
          [116.2705139, 6.2427761],
          [116.2708417, 6.2425207],
          [116.2709701, 6.2423754],
          [116.2710676, 6.2422917],
          [116.2711872, 6.2421772],
          [116.2712758, 6.2420231],
          [116.2712979, 6.2418338],
          [116.2713156, 6.241574],
          [116.2712182, 6.2415123],
          [116.2710764, 6.241618],
          [116.2708417, 6.2417325],
          [116.2706025, 6.2418338],
          [116.2705006, 6.2419923],
          [116.2702791, 6.2420848],
          [116.2701595, 6.2421684],
          [116.2700753, 6.2422301],
          [116.2699602, 6.2422389],
          [116.2698494, 6.2423622],
          [116.2697033, 6.2424767],
          [116.2695748, 6.2426176],
          [116.269402, 6.242666],
          [116.2693135, 6.2428289],
          [116.2692249, 6.2428906],
          [116.2691451, 6.2429742],
          [116.2691186, 6.2430667],
          [116.2692249, 6.2431196],
          [116.2693489, 6.2431988],
          [116.2695394, 6.2432384],
          [116.2696767, 6.2432517],
          [116.2699646, 6.2431724],
        ],
      ],
      [
        [
          [117.7405285, 6.2540224],
          [117.7425884, 6.2550462],
          [117.7439617, 6.2564114],
          [117.74482, 6.2588003],
          [117.74482, 6.2577765],
          [117.7444767, 6.2553875],
          [117.7427601, 6.2540224],
          [117.7405285, 6.2540224],
        ],
      ],
      [
        [
          [116.3090221, 6.304867],
          [116.3090221, 6.3069145],
          [116.3088504, 6.3081089],
          [116.3098804, 6.3094738],
          [116.3107387, 6.3081089],
          [116.3114253, 6.3065733],
          [116.311597, 6.304867],
          [116.3090221, 6.304867],
        ],
      ],
      [
        [
          [116.3090221, 6.3500802],
          [116.3095371, 6.3511038],
          [116.310052, 6.3504214],
          [116.3090221, 6.3500802],
        ],
      ],
      [
        [
          [116.3205234, 6.3889774],
          [116.3206951, 6.3896598],
          [116.3215534, 6.3894892],
          [116.3205234, 6.3889774],
        ],
      ],
      [
        [
          [116.3326047, 6.3915186],
          [116.3323706, 6.3917642],
          [116.3321857, 6.3920485],
          [116.3317498, 6.3925041],
          [116.3314446, 6.3930125],
          [116.3311265, 6.3936576],
          [116.330831, 6.3940918],
          [116.3304469, 6.3945296],
          [116.330298, 6.3945544],
          [116.3299622, 6.3946596],
          [116.3294812, 6.3948289],
          [116.3291235, 6.3948976],
          [116.3290876, 6.3950474],
          [116.3288753, 6.3952081],
          [116.3285893, 6.3952614],
          [116.3281059, 6.3952466],
          [116.3278948, 6.3951387],
          [116.3274752, 6.3952561],
          [116.3272767, 6.3952688],
          [116.3268991, 6.3952598],
          [116.3264977, 6.3953973],
          [116.3263789, 6.395655],
          [116.3261903, 6.3958802],
          [116.3260475, 6.3961222],
          [116.3260439, 6.3963282],
          [116.3261539, 6.3966406],
          [116.3263958, 6.3968069],
          [116.3266099, 6.3969541],
          [116.3268616, 6.3969168],
          [116.3272093, 6.3969222],
          [116.328246, 6.396789],
          [116.3288005, 6.3967564],
          [116.3292713, 6.3968091],
          [116.3296132, 6.396718],
          [116.3302171, 6.3963486],
          [116.3310531, 6.3960074],
          [116.3312987, 6.3959373],
          [116.3318024, 6.3960141],
          [116.3323064, 6.3962597],
          [116.3328311, 6.3966965],
          [116.3330318, 6.3970122],
          [116.3331455, 6.3973712],
          [116.3331779, 6.3975929],
          [116.3331237, 6.3978421],
          [116.3330243, 6.3979622],
          [116.3328157, 6.398045],
          [116.3325939, 6.3981015],
          [116.3323726, 6.3980957],
          [116.3322074, 6.3980562],
          [116.3321816, 6.398118],
          [116.3322277, 6.398186],
          [116.3325192, 6.3982717],
          [116.332749, 6.3983006],
          [116.3330879, 6.3981536],
          [116.3333395, 6.3981381],
          [116.3337155, 6.3985328],
          [116.334226, 6.398952],
          [116.3342959, 6.3992947],
          [116.3344483, 6.399593],
          [116.3343649, 6.3996492],
          [116.3343861, 6.3997047],
          [116.3346415, 6.3997673],
          [116.3348049, 6.3998631],
          [116.3349784, 6.4000822],
          [116.3352874, 6.4002682],
          [116.3353474, 6.4003867],
          [116.3357856, 6.4006045],
          [116.3360175, 6.4007865],
          [116.3362254, 6.4007782],
          [116.3366382, 6.4009981],
          [116.3367383, 6.4009714],
          [116.3370136, 6.4014492],
          [116.3372239, 6.4017318],
          [116.3374045, 6.4018347],
          [116.3376115, 6.4018761],
          [116.3378864, 6.401852],
          [116.338504, 6.4016816],
          [116.3392433, 6.4017195],
          [116.3398644, 6.4017713],
          [116.3404712, 6.4018432],
          [116.3411849, 6.4018896],
          [116.3413627, 6.4018112],
          [116.3414858, 6.4016588],
          [116.3416517, 6.4015884],
          [116.3417335, 6.40152],
          [116.3416768, 6.4014699],
          [116.3415491, 6.4014708],
          [116.3412842, 6.4013661],
          [116.3403256, 6.4011461],
          [116.3402038, 6.4009937],
          [116.340154, 6.4004695],
          [116.3401725, 6.4001653],
          [116.3404731, 6.3992275],
          [116.3406927, 6.398726],
          [116.3408918, 6.3984562],
          [116.3411182, 6.3982286],
          [116.3412196, 6.3981234],
          [116.3412091, 6.3980436],
          [116.3410697, 6.3979979],
          [116.3409375, 6.3979103],
          [116.3408665, 6.3977885],
          [116.3408552, 6.3976091],
          [116.3408438, 6.3974473],
          [116.3407626, 6.3973543],
          [116.3406165, 6.3973269],
          [116.3402589, 6.3975528],
          [116.3396032, 6.3976837],
          [116.339157, 6.3976175],
          [116.3387434, 6.3974543],
          [116.3384191, 6.3971693],
          [116.3381001, 6.3967166],
          [116.3378995, 6.3962565],
          [116.3378084, 6.3958785],
          [116.3377792, 6.3952761],
          [116.3378568, 6.3947842],
          [116.3379019, 6.3944911],
          [116.3378815, 6.3942454],
          [116.3378016, 6.394109],
          [116.3376726, 6.394044],
          [116.3376485, 6.3939752],
          [116.3376447, 6.3936343],
          [116.3376713, 6.393418],
          [116.3376382, 6.3932098],
          [116.3375497, 6.3930853],
          [116.3372751, 6.3928943],
          [116.3370829, 6.3926948],
          [116.3370382, 6.3925925],
          [116.337032, 6.3923773],
          [116.3369904, 6.3922692],
          [116.3368642, 6.3921856],
          [116.3366256, 6.3921381],
          [116.3363722, 6.3921072],
          [116.3361195, 6.3919995],
          [116.3359203, 6.3918753],
          [116.3357033, 6.3916545],
          [116.3356225, 6.3913749],
          [116.3355818, 6.3910645],
          [116.3355903, 6.3908215],
          [116.3356357, 6.3906505],
          [116.3357806, 6.3905451],
          [116.3357709, 6.3904524],
          [116.3356291, 6.3905324],
          [116.33542, 6.3907369],
          [116.3351658, 6.3909371],
          [116.3350241, 6.3910149],
          [116.3347565, 6.3910289],
          [116.3345829, 6.3912474],
          [116.3344633, 6.3913629],
          [116.3342741, 6.3914357],
          [116.3341013, 6.3915542],
          [116.3339968, 6.3915596],
          [116.3338761, 6.3915262],
          [116.3332524, 6.3914843],
          [116.3326047, 6.3915186],
        ],
      ],
      [
        [
          [117.7241867, 6.4369191],
          [117.7242215, 6.4374227],
          [117.7250087, 6.4380197],
          [117.7263671, 6.4402763],
          [117.7266805, 6.4412904],
          [117.726529, 6.4416624],
          [117.7260954, 6.4417749],
          [117.7258864, 6.4419289],
          [117.7264925, 6.4422439],
          [117.7268512, 6.4422023],
          [117.7281016, 6.4416866],
          [117.7296237, 6.4408439],
          [117.730066, 6.4402278],
          [117.7301879, 6.4389161],
          [117.7301426, 6.4375559],
          [117.7295853, 6.4360781],
          [117.7293189, 6.4356904],
          [117.7291064, 6.4356178],
          [117.7285178, 6.4357631],
          [117.7283193, 6.4361196],
          [117.7271438, 6.436303],
          [117.7264576, 6.4363134],
          [117.7261981, 6.4362079],
          [117.7255416, 6.4362805],
          [117.7246029, 6.4365886],
          [117.7241867, 6.4369191],
        ],
      ],
      [
        [
          [116.2799758, 6.2276956],
          [116.280051, 6.2276395],
          [116.280145, 6.2275959],
          [116.2802515, 6.2275212],
          [116.2802859, 6.2274683],
          [116.2802828, 6.2273811],
          [116.2802546, 6.2272939],
          [116.2802233, 6.227241],
          [116.2801293, 6.2272036],
          [116.280051, 6.2271631],
          [116.2799946, 6.2270977],
          [116.2799132, 6.2270106],
          [116.2798787, 6.2269296],
          [116.2798443, 6.2268144],
          [116.2798192, 6.2267085],
          [116.2797973, 6.2265995],
          [116.2797816, 6.2265186],
          [116.2797127, 6.2264625],
          [116.2796657, 6.2263816],
          [116.279578, 6.2263473],
          [116.279506, 6.2263411],
          [116.279412, 6.2263816],
          [116.2793745, 6.2264096],
          [116.2793588, 6.2264937],
          [116.2793118, 6.2264843],
          [116.2793409, 6.2264484],
          [116.2793564, 6.2264076],
          [116.279342, 6.2263746],
          [116.2792833, 6.2263713],
          [116.2792158, 6.2264087],
          [116.279043, 6.2264847],
          [116.2788924, 6.2265816],
          [116.278764, 6.2266168],
          [116.2786665, 6.2266212],
          [116.2786842, 6.2267533],
          [116.2787817, 6.2268238],
          [116.2788969, 6.2267445],
          [116.2789456, 6.226925],
          [116.2790785, 6.2268722],
          [116.2791981, 6.2268722],
          [116.2792645, 6.2267225],
          [116.2793708, 6.2267269],
          [116.2794771, 6.226837],
          [116.2794948, 6.2269691],
          [116.2795746, 6.2270351],
          [116.2796366, 6.2270704],
          [116.279672, 6.2272025],
          [116.2796322, 6.2273125],
          [116.2796765, 6.2274711],
          [116.2797429, 6.2275547],
          [116.2798359, 6.2275107],
          [116.2798005, 6.2276032],
          [116.2798404, 6.2277044],
          [116.2798935, 6.2277309],
          [116.2799758, 6.2276956],
        ],
      ],
      [
        [
          [116.2778382, 6.2396508],
          [116.2786355, 6.2397785],
          [116.2789013, 6.23974],
          [116.2789234, 6.2393712],
          [116.2786178, 6.2390817],
          [116.2778426, 6.2389364],
          [116.2775193, 6.2389408],
          [116.2761328, 6.2387779],
          [116.2750874, 6.2388659],
          [116.2746223, 6.2390729],
          [116.274609, 6.239183],
          [116.2759335, 6.2392666],
          [116.2765979, 6.2393679],
          [116.2773199, 6.2395353],
          [116.2778382, 6.2396508],
        ],
      ],
      [
        [
          [117.6519512, 6.2332039],
          [117.6538395, 6.2316681],
          [117.6558994, 6.2309855],
          [117.6577877, 6.2299616],
          [117.6572727, 6.2274019],
          [117.6553844, 6.2245009],
          [117.6546978, 6.2217705],
          [117.6543545, 6.2186988],
          [117.6531528, 6.2154564],
          [117.6519512, 6.212214],
          [117.6502049, 6.2086727],
          [117.6483463, 6.2074357],
          [117.6468719, 6.2061563],
          [117.6449131, 6.2053878],
          [117.6445698, 6.2076063],
          [117.6442264, 6.2082889],
          [117.6433681, 6.2086303],
          [117.6414799, 6.2101661],
          [117.6397633, 6.2126677],
          [117.6385616, 6.2169923],
          [117.6397633, 6.2188694],
          [117.6411365, 6.2207466],
          [117.6419948, 6.2231357],
          [117.6419948, 6.2251835],
          [117.6421665, 6.226378],
          [117.6430238, 6.2290797],
          [117.6457714, 6.2299616],
          [117.647488, 6.2301323],
          [117.6498913, 6.2321801],
          [117.6519512, 6.2332039],
        ],
      ],
      [
        [
          [117.7240703, 6.2716764],
          [117.7273106, 6.2663083],
          [117.7290698, 6.2620424],
          [117.7304005, 6.2574352],
          [117.7304005, 6.2531692],
          [117.7293705, 6.24805],
          [117.7283405, 6.2444665],
          [117.7283405, 6.2393472],
          [117.7285122, 6.2330333],
          [117.7283405, 6.2287671],
          [117.7264522, 6.2260367],
          [117.724049, 6.2253541],
          [117.7219891, 6.2265487],
          [117.7219891, 6.2282552],
          [117.7242206, 6.2285965],
          [117.7249073, 6.2320094],
          [117.7254223, 6.2366169],
          [117.7242206, 6.2393472],
          [117.723019, 6.240371],
          [117.7237057, 6.2422481],
          [117.7257656, 6.2463436],
          [117.7274822, 6.2514628],
          [117.7274822, 6.2572645],
          [117.7251718, 6.2643677],
          [117.7240703, 6.2716764],
        ],
      ],
      [
        [
          [117.9182729, 6.5057859],
          [117.9180837, 6.5052537],
          [117.9179973, 6.5051788],
          [117.9179572, 6.5051765],
          [117.9178783, 6.5051567],
          [117.9178287, 6.5051442],
          [117.9177869, 6.5051337],
          [117.9176975, 6.5051359],
          [117.9175343, 6.5053287],
          [117.9173711, 6.5057107],
          [117.9171878, 6.5057687],
          [117.916927, 6.5061687],
          [117.9168682, 6.5065131],
          [117.9168678, 6.5068637],
          [117.9169221, 6.5071971],
          [117.9170964, 6.5075278],
          [117.9173209, 6.5079185],
          [117.9174463, 6.5080199],
          [117.9175836, 6.5082045],
          [117.9180553, 6.5087242],
          [117.9183461, 6.5088231],
          [117.9185008, 6.5087906],
          [117.9185659, 6.5085481],
          [117.9186449, 6.508279],
          [117.9186647, 6.5079708],
          [117.9186767, 6.5078507],
          [117.9186864, 6.5075219],
          [117.918646, 6.5072741],
          [117.9186273, 6.5064388],
          [117.918604, 6.5061187],
          [117.9184923, 6.5061021],
          [117.9183101, 6.5058851],
          [117.9182729, 6.5057859],
        ],
      ],
      [
        [
          [117.7181717, 6.5505339],
          [117.7189197, 6.5508399],
          [117.7200636, 6.5507525],
          [117.7210756, 6.5502717],
          [117.7218236, 6.5487855],
          [117.7218236, 6.5476052],
          [117.7208996, 6.5465561],
          [117.7200196, 6.546425],
          [117.7189197, 6.5475178],
          [117.7181277, 6.5486106],
          [117.7176877, 6.5495286],
          [117.7181717, 6.5505339],
        ],
      ],
      [
        [
          [116.7801629, 6.5732497],
          [116.7799963, 6.5733637],
          [116.7798111, 6.574281],
          [116.7807073, 6.5746108],
          [116.7805073, 6.5737684],
          [116.7801629, 6.5732497],
        ],
      ],
      [
        [
          [116.7844252, 6.5732154],
          [116.7844092, 6.5738322],
          [116.7852794, 6.5743227],
          [116.7863089, 6.573869],
          [116.7857868, 6.5735121],
          [116.7849289, 6.5731038],
          [116.7844252, 6.5732154],
        ],
      ],
      [
        [
          [116.7864866, 6.5741755],
          [116.7851535, 6.5746194],
          [116.7849363, 6.5749971],
          [116.7858571, 6.5759315],
          [116.7859522, 6.5767973],
          [116.7865335, 6.5762982],
          [116.7876272, 6.5764981],
          [116.7879111, 6.5761289],
          [116.787805, 6.5754312],
          [116.7873779, 6.5745287],
          [116.7864866, 6.5741755],
        ],
      ],
      [
        [
          [116.7996712, 6.5655331],
          [116.7991368, 6.5665635],
          [116.7988709, 6.5674057],
          [116.7989657, 6.5684963],
          [116.7996581, 6.5693724],
          [116.8015483, 6.5693777],
          [116.801414, 6.5687787],
          [116.8006242, 6.5678869],
          [116.8006716, 6.5666185],
          [116.7996712, 6.5655331],
        ],
      ],
      [
        [
          [117.463982, 6.5675627],
          [117.4632954, 6.5680743],
          [117.4648403, 6.5706323],
          [117.4656986, 6.5697796],
          [117.463982, 6.5675627],
        ],
      ],
      [
        [
          [117.4723166, 6.5752088],
          [117.4724561, 6.5751768],
          [117.4727136, 6.5750915],
          [117.4730086, 6.5748144],
          [117.4732339, 6.574532],
          [117.4733949, 6.5741536],
          [117.4733895, 6.5737433],
          [117.4731964, 6.5733223],
          [117.473073, 6.5729172],
          [117.4729121, 6.5725335],
          [117.4726921, 6.5721872],
          [117.4727672, 6.5719953],
          [117.4725634, 6.5716969],
          [117.4727941, 6.5717981],
          [117.4730623, 6.5718781],
          [117.4734431, 6.5719793],
          [117.4737489, 6.5718887],
          [117.473985, 6.5717182],
          [117.4739581, 6.5715423],
          [117.4736094, 6.5711693],
          [117.4731535, 6.5708122],
          [117.4728531, 6.5704925],
          [117.4724132, 6.5701034],
          [117.4721342, 6.5699702],
          [117.4719304, 6.5698263],
          [117.4715924, 6.5693947],
          [117.4712062, 6.5691229],
          [117.4708199, 6.568915],
          [117.4704283, 6.5686219],
          [117.4699509, 6.5683821],
          [117.4695969, 6.5682595],
          [117.4691999, 6.5681689],
          [117.4689692, 6.5682329],
          [117.4688566, 6.5684407],
          [117.46876, 6.5689257],
          [117.4688673, 6.5694266],
          [117.4690175, 6.5699382],
          [117.4691838, 6.5703965],
          [117.4693823, 6.5708655],
          [117.4695432, 6.5713238],
          [117.4697041, 6.5718088],
          [117.4698973, 6.5722618],
          [117.4702084, 6.572784],
          [117.4703425, 6.573173],
          [117.4706268, 6.5736314],
          [117.4709701, 6.5740524],
          [117.4713027, 6.574452],
          [117.4717051, 6.5747611],
          [117.4720752, 6.5750169],
          [117.4723166, 6.5752088],
        ],
      ],
      [
        [
          [117.4008106, 6.576942],
          [117.4002956, 6.5781357],
          [117.4009823, 6.5789884],
          [117.4016689, 6.5801821],
          [117.4028706, 6.5812053],
          [117.4042438, 6.5820579],
          [117.4042438, 6.579841],
          [117.4037289, 6.5783063],
          [117.4021839, 6.5771125],
          [117.4008106, 6.576942],
        ],
      ],
      [
        [
          [116.8258177, 6.5800813],
          [116.8257268, 6.5807666],
          [116.8258012, 6.5812431],
          [116.8260104, 6.5813758],
          [116.8262907, 6.581353],
          [116.8264221, 6.5809461],
          [116.82642, 6.5804457],
          [116.8262568, 6.5802325],
          [116.8258177, 6.5800813],
        ],
      ],
      [
        [
          [116.7643919, 6.5813462],
          [116.7642308, 6.5817178],
          [116.764076, 6.5822691],
          [116.7640512, 6.5829319],
          [116.7641875, 6.5831363],
          [116.7646211, 6.5832478],
          [116.7649556, 6.5833903],
          [116.7650918, 6.5831301],
          [116.7654325, 6.5832416],
          [116.7655812, 6.5828823],
          [116.7658413, 6.5830372],
          [116.7661511, 6.5827708],
          [116.7664484, 6.5826903],
          [116.7673713, 6.5832044],
          [116.767551, 6.583031],
          [116.7677368, 6.5826098],
          [116.7674519, 6.5823063],
          [116.7668386, 6.5818479],
          [116.7660334, 6.5813152],
          [116.7653892, 6.5808073],
          [116.7647883, 6.5806029],
          [116.7643052, 6.5806834],
          [116.7641937, 6.580925],
          [116.7643919, 6.5813462],
        ],
      ],
      [
        [
          [116.7596675, 6.5807936],
          [116.7599322, 6.5815034],
          [116.7600979, 6.5820732],
          [116.7603646, 6.5826475],
          [116.7605696, 6.5834791],
          [116.7608369, 6.5843593],
          [116.7609352, 6.5845685],
          [116.7611469, 6.5848832],
          [116.7612908, 6.5849795],
          [116.7615607, 6.5849956],
          [116.7617797, 6.5849599],
          [116.7623295, 6.584508],
          [116.7626749, 6.5840878],
          [116.7627358, 6.5839695],
          [116.7628716, 6.5833995],
          [116.7630543, 6.5828391],
          [116.7631866, 6.5823036],
          [116.7633444, 6.582037],
          [116.7635473, 6.581479],
          [116.7635385, 6.5811364],
          [116.7634212, 6.5808913],
          [116.7630092, 6.5805496],
          [116.7625782, 6.5803462],
          [116.762134, 6.5802649],
          [116.7617063, 6.5802675],
          [116.7611017, 6.5803342],
          [116.7604216, 6.5804555],
          [116.7598199, 6.5804915],
          [116.7597213, 6.5805445],
          [116.7596776, 6.5806489],
          [116.7596675, 6.5807936],
        ],
      ],
      [
        [
          [116.761068, 6.586632],
          [116.7611555, 6.5871231],
          [116.7612851, 6.5877556],
          [116.7613079, 6.5879246],
          [116.7613141, 6.58837],
          [116.7613537, 6.5889572],
          [116.7614072, 6.5904105],
          [116.7614383, 6.590568],
          [116.7614836, 6.5906996],
          [116.7615474, 6.5908078],
          [116.7616685, 6.5908327],
          [116.7621823, 6.5906956],
          [116.7623126, 6.5906875],
          [116.7626835, 6.5907815],
          [116.7630085, 6.5907942],
          [116.7633363, 6.5907406],
          [116.7634644, 6.5907063],
          [116.7639161, 6.5904594],
          [116.7641296, 6.5902134],
          [116.7643023, 6.589925],
          [116.7643442, 6.589787],
          [116.7643869, 6.5893754],
          [116.7643423, 6.5888249],
          [116.7643207, 6.5886606],
          [116.7643422, 6.5885061],
          [116.7644119, 6.5883516],
          [116.7645216, 6.5882309],
          [116.7649658, 6.5879053],
          [116.7650816, 6.5878318],
          [116.7651363, 6.5877448],
          [116.7651518, 6.5876592],
          [116.7651401, 6.5875749],
          [116.7650972, 6.5874683],
          [116.7648997, 6.5871349],
          [116.7648252, 6.5870274],
          [116.7647633, 6.5869527],
          [116.7646748, 6.5868514],
          [116.7645514, 6.5867395],
          [116.7642596, 6.5864842],
          [116.7632277, 6.5857177],
          [116.7631003, 6.5856258],
          [116.7629895, 6.585565],
          [116.7628804, 6.5855219],
          [116.7627718, 6.5854912],
          [116.7623552, 6.5854406],
          [116.7621428, 6.5854179],
          [116.7613923, 6.5854079],
          [116.7612751, 6.5854246],
          [116.7611767, 6.5854892],
          [116.761096, 6.5856198],
          [116.7610773, 6.5857077],
          [116.7610397, 6.5861127],
          [116.7610494, 6.5863728],
          [116.761068, 6.586632],
        ],
      ],
      [
        [
          [116.7583058, 6.6019691],
          [116.7581232, 6.6023235],
          [116.7582639, 6.6026828],
          [116.7581404, 6.6030175],
          [116.758844, 6.6033376],
          [116.7590354, 6.6032468],
          [116.7591205, 6.6026337],
          [116.7585799, 6.6022757],
          [116.7583058, 6.6019691],
        ],
      ],
      [
        [
          [117.4011469, 6.611307],
          [117.4013507, 6.6112324],
          [117.401635, 6.6110352],
          [117.4020106, 6.6106143],
          [117.4022788, 6.6103958],
          [117.4027026, 6.6099482],
          [117.4029064, 6.6095485],
          [117.4032015, 6.6092341],
          [117.4035233, 6.608941],
          [117.403695, 6.6086],
          [117.4038935, 6.6081684],
          [117.40399, 6.6077367],
          [117.4041563, 6.607385],
          [117.4042368, 6.6070387],
          [117.4042904, 6.6065537],
          [117.4043924, 6.6063832],
          [117.404387, 6.6060369],
          [117.4044728, 6.6058077],
          [117.4045962, 6.6054081],
          [117.4045694, 6.6050404],
          [117.4047142, 6.604742],
          [117.4048215, 6.6043956],
          [117.4048805, 6.6040012],
          [117.404902, 6.6034364],
          [117.4049878, 6.6029675],
          [117.404961, 6.6026104],
          [117.4048966, 6.601971],
          [117.4048269, 6.6014754],
          [117.4046928, 6.6011183],
          [117.4043924, 6.6006334],
          [117.4041402, 6.6002071],
          [117.4039525, 6.5997968],
          [117.4036038, 6.5994344],
          [117.4032444, 6.5990294],
          [117.4029601, 6.5986511],
          [117.4029869, 6.5984859],
          [117.4028796, 6.5982354],
          [117.4025041, 6.597921],
          [117.4021232, 6.597596],
          [117.401678, 6.597191],
          [117.4013293, 6.5967806],
          [117.4009752, 6.596365],
          [117.4006158, 6.595992],
          [117.4003422, 6.5955497],
          [117.3999721, 6.5951127],
          [117.3995322, 6.5948462],
          [117.3990869, 6.5946331],
          [117.3985773, 6.5945532],
          [117.3981428, 6.5947823],
          [117.3979068, 6.5951607],
          [117.397837, 6.5957788],
          [117.3979282, 6.5963437],
          [117.3983574, 6.5966634],
          [117.398352, 6.5969085],
          [117.3987275, 6.5972336],
          [117.3990709, 6.5977505],
          [117.3993874, 6.5983367],
          [117.3996556, 6.5988589],
          [117.3998862, 6.5992746],
          [117.4001116, 6.5997915],
          [117.4002778, 6.6002391],
          [117.400471, 6.6008306],
          [117.4005622, 6.6013315],
          [117.4005139, 6.6019869],
          [117.4004656, 6.6026584],
          [117.4003422, 6.6032446],
          [117.4003315, 6.6038893],
          [117.4002886, 6.6045501],
          [117.4002671, 6.6053015],
          [117.4002886, 6.6059036],
          [117.4003422, 6.6065005],
          [117.4004495, 6.6071399],
          [117.4005407, 6.6077634],
          [117.400589, 6.6085147],
          [117.4006373, 6.6091595],
          [117.4006695, 6.6098043],
          [117.4006587, 6.6103851],
          [117.4008089, 6.610886],
          [117.4011469, 6.611307],
        ],
      ],
      [
        [
          [117.3611568, 6.6086599],
          [117.3615002, 6.610024],
          [117.3625301, 6.6110472],
          [117.3625301, 6.6095125],
          [117.3620151, 6.6086599],
          [117.3611568, 6.6086599],
        ],
      ],
      [
        [
          [117.3855328, 6.6090009],
          [117.3848461, 6.6117292],
          [117.3862194, 6.6127524],
          [117.3875927, 6.6127524],
          [117.389481, 6.6110472],
          [117.3899959, 6.609683],
          [117.388966, 6.6084894],
          [117.386906, 6.6079778],
          [117.3855328, 6.6090009],
        ],
      ],
      [
        [
          [117.7667354, 6.6122677],
          [117.7672332, 6.612314],
          [117.7676221, 6.6120977],
          [117.7679799, 6.611835],
          [117.7685711, 6.6118041],
          [117.7691311, 6.6116959],
          [117.7689911, 6.6112014],
          [117.7684466, 6.6109233],
          [117.7674355, 6.6109696],
          [117.7668132, 6.6112632],
          [117.7665799, 6.6116959],
          [117.7667354, 6.6122677],
        ],
      ],
      [
        [
          [117.4308189, 6.6976931],
          [117.4309375, 6.6977857],
          [117.4312216, 6.6978121],
          [117.4314403, 6.6976755],
          [117.4315392, 6.6973398],
          [117.43142, 6.6971156],
          [117.4312127, 6.6971988],
          [117.4308189, 6.6976931],
        ],
      ],
      [
        [
          [116.340703, 6.7041324],
          [116.3407686, 6.7041151],
          [116.3407948, 6.7040586],
          [116.3408342, 6.7039501],
          [116.3408867, 6.7038546],
          [116.3409085, 6.7037981],
          [116.3409129, 6.7036983],
          [116.340926, 6.7036331],
          [116.3410134, 6.7035246],
          [116.3410834, 6.7034768],
          [116.3411796, 6.7034378],
          [116.3412189, 6.703403],
          [116.3412276, 6.7033422],
          [116.3412233, 6.703212],
          [116.3412014, 6.7031295],
          [116.3411315, 6.7031382],
          [116.3410528, 6.7031946],
          [116.3409872, 6.7032815],
          [116.3409173, 6.70339],
          [116.3408167, 6.7035376],
          [116.3407948, 6.7035767],
          [116.340738, 6.7036809],
          [116.3407205, 6.7037287],
          [116.3406943, 6.7037894],
          [116.3406681, 6.7038676],
          [116.340655, 6.7039848],
          [116.340703, 6.7041324],
        ],
      ],
      [
        [
          [116.3039096, 6.71183],
          [116.3034911, 6.7120527],
          [116.3032342, 6.7124267],
          [116.3032546, 6.7128076],
          [116.3032986, 6.7129866],
          [116.303511, 6.712987],
          [116.3045935, 6.712708],
          [116.3071187, 6.7114689],
          [116.3078401, 6.71079],
          [116.308369, 6.7107799],
          [116.3086275, 6.7106609],
          [116.3088534, 6.7103787],
          [116.3092042, 6.7104533],
          [116.3100345, 6.7101733],
          [116.3103075, 6.7101145],
          [116.3104637, 6.7099806],
          [116.310633, 6.7099169],
          [116.3107004, 6.7098874],
          [116.3108468, 6.709881],
          [116.3110326, 6.7099365],
          [116.3113351, 6.7099545],
          [116.3117125, 6.7100356],
          [116.3126039, 6.7100418],
          [116.3131158, 6.7100342],
          [116.3139613, 6.7098604],
          [116.3167684, 6.7091162],
          [116.3171602, 6.7089952],
          [116.3175188, 6.7088493],
          [116.3186117, 6.7082601],
          [116.3193291, 6.7077828],
          [116.3194174, 6.7076881],
          [116.319541, 6.7075793],
          [116.3198333, 6.7072675],
          [116.3201772, 6.7068587],
          [116.3203547, 6.7063894],
          [116.3203379, 6.7062212],
          [116.3202174, 6.7062611],
          [116.3201505, 6.7062744],
          [116.3200008, 6.7062803],
          [116.3197185, 6.7063894],
          [116.3197327, 6.7062837],
          [116.3195778, 6.7063538],
          [116.319464, 6.7063334],
          [116.3191838, 6.7064692],
          [116.3186986, 6.7068153],
          [116.3185916, 6.7066818],
          [116.3181808, 6.7068477],
          [116.3181337, 6.7067904],
          [116.3168829, 6.7072402],
          [116.3168345, 6.7073734],
          [116.3168569, 6.7075374],
          [116.3168026, 6.7076159],
          [116.3167086, 6.70767],
          [116.3165155, 6.7077213],
          [116.3163287, 6.7077639],
          [116.3162231, 6.7077413],
          [116.3161057, 6.7077065],
          [116.3160446, 6.7075862],
          [116.3160134, 6.7074864],
          [116.3159108, 6.7073601],
          [116.3155992, 6.7073585],
          [116.3147671, 6.7074581],
          [116.3145899, 6.7074873],
          [116.3145335, 6.7075099],
          [116.3145631, 6.7075914],
          [116.3146221, 6.7075972],
          [116.314601, 6.707731],
          [116.3144933, 6.7078307],
          [116.314398, 6.7078707],
          [116.3142819, 6.7078789],
          [116.3142157, 6.7077591],
          [116.3138075, 6.7079957],
          [116.3135913, 6.7081795],
          [116.3132785, 6.7083489],
          [116.3130221, 6.7083942],
          [116.3128808, 6.7082657],
          [116.3126687, 6.7082917],
          [116.312624, 6.7082164],
          [116.3126285, 6.7081499],
          [116.3126687, 6.7080856],
          [116.3126218, 6.707997],
          [116.3125028, 6.7080095],
          [116.3122358, 6.7081565],
          [116.3119687, 6.7083585],
          [116.311968, 6.7084491],
          [116.3119412, 6.7085222],
          [116.3118925, 6.7086002],
          [116.311216, 6.7089588],
          [116.3109014, 6.7090855],
          [116.3106872, 6.7092291],
          [116.3106046, 6.7092513],
          [116.3104998, 6.7092513],
          [116.3104733, 6.7092637],
          [116.310415, 6.7092912],
          [116.3103538, 6.709343],
          [116.3100695, 6.7093681],
          [116.309705, 6.7094626],
          [116.3093233, 6.709846],
          [116.3091499, 6.7098687],
          [116.3089956, 6.7097932],
          [116.3086083, 6.7099701],
          [116.3082397, 6.7102269],
          [116.3076346, 6.710358],
          [116.3072655, 6.7102647],
          [116.3070487, 6.7103814],
          [116.3070215, 6.7104848],
          [116.306853, 6.7105386],
          [116.3065156, 6.7106702],
          [116.306271, 6.7106142],
          [116.3051643, 6.7112067],
          [116.3047979, 6.7113351],
          [116.3039096, 6.71183],
        ],
      ],
      [
        [
          [116.3688174, 6.7085737],
          [116.3679047, 6.7085625],
          [116.3674114, 6.7085342],
          [116.3665653, 6.7083571],
          [116.3662047, 6.7083563],
          [116.3657, 6.7084552],
          [116.3652697, 6.7085001],
          [116.3649053, 6.7086368],
          [116.364681, 6.7088996],
          [116.3641603, 6.709207],
          [116.3639608, 6.7094819],
          [116.3638142, 6.709644],
          [116.3634962, 6.70988],
          [116.3631977, 6.7098405],
          [116.3628626, 6.7100074],
          [116.3625782, 6.7100078],
          [116.3623415, 6.7099182],
          [116.3621151, 6.7099451],
          [116.3618876, 6.7099345],
          [116.3616696, 6.7100239],
          [116.3615456, 6.7101008],
          [116.3613607, 6.7102213],
          [116.3608967, 6.7100958],
          [116.3608992, 6.7096964],
          [116.3610975, 6.70953],
          [116.3611925, 6.7092676],
          [116.3611271, 6.7091383],
          [116.3609675, 6.708881],
          [116.3608385, 6.7088749],
          [116.3607067, 6.7090508],
          [116.3606661, 6.7092141],
          [116.360207, 6.7094562],
          [116.3599748, 6.7094227],
          [116.3597221, 6.7093639],
          [116.359038, 6.7094927],
          [116.3586521, 6.7095445],
          [116.3585366, 6.7093558],
          [116.3585105, 6.7091818],
          [116.358857, 6.7092524],
          [116.3590618, 6.7091869],
          [116.3599156, 6.7089252],
          [116.3602737, 6.7089773],
          [116.3605238, 6.7087279],
          [116.3606035, 6.7086192],
          [116.3603793, 6.7084815],
          [116.3602053, 6.7084819],
          [116.3600938, 6.7085604],
          [116.359924, 6.7085763],
          [116.3599042, 6.7084379],
          [116.3598482, 6.7082314],
          [116.359549, 6.708201],
          [116.3594517, 6.7080627],
          [116.3598359, 6.7079126],
          [116.3601656, 6.707924],
          [116.3603779, 6.70757],
          [116.3604305, 6.70726],
          [116.3602458, 6.7070605],
          [116.360216, 6.7067868],
          [116.360089, 6.7064598],
          [116.359929, 6.7063517],
          [116.3594591, 6.7061502],
          [116.3588778, 6.7058233],
          [116.3585806, 6.7059108],
          [116.3586077, 6.706038],
          [116.358692, 6.706155],
          [116.358802, 6.7062465],
          [116.358926, 6.7062366],
          [116.3591742, 6.7064332],
          [116.3592029, 6.7066036],
          [116.3592527, 6.7067404],
          [116.3593864, 6.7068339],
          [116.3593437, 6.7069115],
          [116.3590792, 6.7069781],
          [116.3589565, 6.7069779],
          [116.3588652, 6.7069481],
          [116.3589107, 6.7071606],
          [116.3586514, 6.7071046],
          [116.3583555, 6.7071322],
          [116.3583664, 6.7070375],
          [116.3584426, 6.7069439],
          [116.3584317, 6.7067662],
          [116.3583829, 6.7067693],
          [116.3583145, 6.706916],
          [116.3582515, 6.7069605],
          [116.3582291, 6.7068609],
          [116.3578848, 6.7068866],
          [116.3578754, 6.7070346],
          [116.3577648, 6.7070522],
          [116.3576463, 6.706992],
          [116.3577884, 6.7067544],
          [116.3577732, 6.7066513],
          [116.3576581, 6.7066537],
          [116.357566, 6.7067193],
          [116.3573238, 6.7069233],
          [116.3572175, 6.706908],
          [116.357159, 6.7068333],
          [116.3571155, 6.7068019],
          [116.3570644, 6.7067882],
          [116.3570138, 6.7068215],
          [116.3570093, 6.7069121],
          [116.3570375, 6.7070181],
          [116.3569218, 6.7071536],
          [116.3568914, 6.7072643],
          [116.3568957, 6.7074156],
          [116.3568606, 6.7075792],
          [116.3568612, 6.7077607],
          [116.3569363, 6.7078965],
          [116.3570301, 6.707985],
          [116.3571897, 6.7080483],
          [116.3569525, 6.7081495],
          [116.3569132, 6.7081954],
          [116.3567847, 6.7082866],
          [116.356704, 6.7083345],
          [116.356162, 6.708705],
          [116.3559407, 6.7089695],
          [116.3556814, 6.7091484],
          [116.3556412, 6.7092069],
          [116.3557165, 6.7093047],
          [116.3557831, 6.7093649],
          [116.3552671, 6.7097949],
          [116.3551817, 6.7097908],
          [116.3550866, 6.7098666],
          [116.3547976, 6.7099806],
          [116.3546549, 6.7098747],
          [116.354332, 6.709429],
          [116.3552636, 6.7087883],
          [116.3553546, 6.7087952],
          [116.3554615, 6.7087431],
          [116.3554547, 6.7083893],
          [116.3553381, 6.7081061],
          [116.3553307, 6.7079657],
          [116.3552245, 6.707976],
          [116.3552378, 6.7083035],
          [116.3545571, 6.7086337],
          [116.354516, 6.7087956],
          [116.3541189, 6.7089515],
          [116.3540532, 6.7091559],
          [116.3538992, 6.7091169],
          [116.3535079, 6.7093385],
          [116.3532576, 6.7093637],
          [116.3529933, 6.7092008],
          [116.3528792, 6.7089357],
          [116.3525846, 6.7086935],
          [116.3523547, 6.7084279],
          [116.3522246, 6.7082824],
          [116.3515066, 6.7084147],
          [116.3513401, 6.7085432],
          [116.3511929, 6.7087128],
          [116.3512212, 6.7087739],
          [116.3513037, 6.7089011],
          [116.3512686, 6.7090397],
          [116.3511684, 6.7090333],
          [116.3510845, 6.7090761],
          [116.3511955, 6.7092066],
          [116.3510584, 6.709284],
          [116.3510452, 6.7094226],
          [116.35117, 6.7095294],
          [116.351086, 6.7096715],
          [116.3509687, 6.7096335],
          [116.3507536, 6.7097449],
          [116.350457, 6.7096795],
          [116.3503921, 6.7094005],
          [116.3501547, 6.7094857],
          [116.3498507, 6.7094789],
          [116.3496852, 6.7093406],
          [116.3495368, 6.7089844],
          [116.348918, 6.7091431],
          [116.3484633, 6.7092757],
          [116.3480262, 6.709343],
          [116.3476833, 6.7093403],
          [116.3476208, 6.7094574],
          [116.3476981, 6.7095555],
          [116.3476651, 6.709703],
          [116.3477558, 6.7099378],
          [116.3478963, 6.7101323],
          [116.3481088, 6.7100897],
          [116.3481047, 6.7101908],
          [116.3483361, 6.7102979],
          [116.3484674, 6.7104154],
          [116.3486051, 6.710468],
          [116.348723, 6.710484],
          [116.3487593, 6.7105557],
          [116.3486076, 6.7106015],
          [116.3484554, 6.71082],
          [116.3482841, 6.7108297],
          [116.3482405, 6.7107658],
          [116.3480952, 6.7107451],
          [116.3478423, 6.7109493],
          [116.3476046, 6.7111067],
          [116.3473212, 6.7111784],
          [116.3469793, 6.7111712],
          [116.3468253, 6.7110927],
          [116.3467753, 6.7109562],
          [116.346919, 6.7105686],
          [116.3470616, 6.7106509],
          [116.3471935, 6.7104667],
          [116.3472821, 6.7103384],
          [116.3473959, 6.7101891],
          [116.3472882, 6.7100831],
          [116.3472101, 6.7100193],
          [116.3470489, 6.7101032],
          [116.3468788, 6.7099693],
          [116.3469443, 6.7098891],
          [116.3468716, 6.7097384],
          [116.3467146, 6.7097272],
          [116.3466452, 6.7097335],
          [116.3465362, 6.7096912],
          [116.3464337, 6.7097007],
          [116.3463885, 6.7097422],
          [116.3463684, 6.7098203],
          [116.3463151, 6.7101149],
          [116.3462665, 6.7102537],
          [116.3459526, 6.7104424],
          [116.3458741, 6.7106768],
          [116.3457019, 6.7107337],
          [116.3456451, 6.7109026],
          [116.3457041, 6.7109765],
          [116.3456021, 6.7111263],
          [116.3454927, 6.7114451],
          [116.3456524, 6.7115584],
          [116.3455739, 6.7117863],
          [116.3457898, 6.7117794],
          [116.3458704, 6.7118578],
          [116.3456298, 6.7120782],
          [116.345596, 6.7122749],
          [116.3452516, 6.7123506],
          [116.3449304, 6.7123022],
          [116.3446989, 6.7121197],
          [116.3445456, 6.7119494],
          [116.344453, 6.7120051],
          [116.3444767, 6.7121697],
          [116.3445138, 6.7124483],
          [116.3444491, 6.7132054],
          [116.3443235, 6.7136055],
          [116.3443597, 6.7137214],
          [116.3446301, 6.7137189],
          [116.3447829, 6.7138086],
          [116.3449687, 6.7138847],
          [116.3451577, 6.7139962],
          [116.3452817, 6.7141443],
          [116.3454898, 6.7142209],
          [116.3454971, 6.714262],
          [116.3453776, 6.7142853],
          [116.3452214, 6.7142648],
          [116.3451368, 6.714236],
          [116.3450183, 6.7142731],
          [116.344957, 6.7142658],
          [116.3448676, 6.7143191],
          [116.3448563, 6.7143991],
          [116.3449061, 6.7144531],
          [116.344919, 6.7145149],
          [116.3448242, 6.7146655],
          [116.3448277, 6.7148026],
          [116.3449252, 6.714913],
          [116.3450289, 6.714912],
          [116.3450472, 6.7150231],
          [116.3451437, 6.71512],
          [116.3450047, 6.7151925],
          [116.3448766, 6.7151757],
          [116.3447774, 6.7149779],
          [116.3446484, 6.7149525],
          [116.3445901, 6.7148302],
          [116.3444951, 6.7147784],
          [116.3444076, 6.7148166],
          [116.3443417, 6.7149733],
          [116.3442453, 6.7150424],
          [116.3439729, 6.7149023],
          [116.3441625, 6.7145471],
          [116.3440678, 6.7144151],
          [116.3439309, 6.7144243],
          [116.3437348, 6.7146088],
          [116.3436293, 6.71494],
          [116.3437296, 6.7149813],
          [116.3436542, 6.7150771],
          [116.3435537, 6.7152578],
          [116.3434177, 6.7154723],
          [116.3434223, 6.7155872],
          [116.3435228, 6.7156676],
          [116.3441647, 6.7156549],
          [116.3442355, 6.7158593],
          [116.3444438, 6.7159609],
          [116.3447419, 6.7159625],
          [116.3449719, 6.7160861],
          [116.344771, 6.7164125],
          [116.344189, 6.7166511],
          [116.3440289, 6.7168432],
          [116.343594, 6.7166991],
          [116.3439479, 6.7164051],
          [116.343884, 6.7162699],
          [116.3436154, 6.7161673],
          [116.3429804, 6.7161002],
          [116.3426528, 6.716531],
          [116.3424313, 6.7167465],
          [116.341652, 6.7171981],
          [116.341404, 6.7172187],
          [116.3411598, 6.7171573],
          [116.3410399, 6.7170495],
          [116.3408259, 6.7167858],
          [116.3407023, 6.7165555],
          [116.340523, 6.7163683],
          [116.3403622, 6.7162731],
          [116.3401313, 6.7161911],
          [116.3396884, 6.7161442],
          [116.3394132, 6.7161687],
          [116.3390486, 6.7162674],
          [116.3389094, 6.7163314],
          [116.3387765, 6.7164849],
          [116.3387394, 6.7166783],
          [116.3387363, 6.716795],
          [116.3386899, 6.7169301],
          [116.3387703, 6.717059],
          [116.3388445, 6.7171084],
          [116.3389959, 6.7172125],
          [116.3391536, 6.7172647],
          [116.3392873, 6.7172881],
          [116.3393112, 6.7174489],
          [116.3394658, 6.7175287],
          [116.3395647, 6.7176792],
          [116.3395029, 6.717716],
          [116.3394936, 6.7177927],
          [116.3397423, 6.7180692],
          [116.3399511, 6.7182095],
          [116.3401164, 6.7182687],
          [116.3404241, 6.7180775],
          [116.3406034, 6.7181113],
          [116.340696, 6.7179814],
          [116.3408121, 6.7179333],
          [116.3408558, 6.718005],
          [116.340965, 6.7180161],
          [116.3410919, 6.717942],
          [116.3411203, 6.7179833],
          [116.3410825, 6.718099],
          [116.341352, 6.7183588],
          [116.3413455, 6.7184348],
          [116.3414548, 6.7184891],
          [116.3415772, 6.7185564],
          [116.3416799, 6.7185303],
          [116.3418735, 6.7186089],
          [116.3418963, 6.7187127],
          [116.3419662, 6.718793],
          [116.3420537, 6.718806],
          [116.3421061, 6.7189297],
          [116.3421759, 6.7189976],
          [116.3421181, 6.7191159],
          [116.3421273, 6.7192479],
          [116.3420686, 6.7193554],
          [116.3421212, 6.7194014],
          [116.3422324, 6.7194505],
          [116.342382, 6.7194616],
          [116.3424187, 6.7193009],
          [116.3424799, 6.7191859],
          [116.3425783, 6.7192033],
          [116.3426548, 6.7191273],
          [116.3427467, 6.7190208],
          [116.3428253, 6.7189167],
          [116.3428886, 6.7188711],
          [116.34292, 6.7188473],
          [116.3429553, 6.7188913],
          [116.3429236, 6.718971],
          [116.3429389, 6.7190079],
          [116.3429826, 6.7190665],
          [116.3429564, 6.7191251],
          [116.3430504, 6.7191881],
          [116.3430395, 6.7192467],
          [116.3431211, 6.7192969],
          [116.3431304, 6.7193307],
          [116.343169, 6.7193952],
          [116.3432169, 6.7193629],
          [116.3433004, 6.7193691],
          [116.3433545, 6.7192908],
          [116.3434349, 6.7192724],
          [116.3434859, 6.7192708],
          [116.3436683, 6.7193967],
          [116.3435833, 6.7195795],
          [116.3436082, 6.719597],
          [116.3435062, 6.7197145],
          [116.3434969, 6.7197751],
          [116.3435069, 6.7198096],
          [116.3435371, 6.7198465],
          [116.3435579, 6.7198512],
          [116.3435734, 6.7198311],
          [116.3436005, 6.7198404],
          [116.343601, 6.7198583],
          [116.3436081, 6.7198784],
          [116.3436316, 6.7199098],
          [116.3436753, 6.719887],
          [116.3436942, 6.7198704],
          [116.3436731, 6.7198549],
          [116.3437967, 6.7196185],
          [116.3441953, 6.7197436],
          [116.3442396, 6.7197052],
          [116.3442457, 6.7196638],
          [116.3442975, 6.7196715],
          [116.3442836, 6.719716],
          [116.344306, 6.7197252],
          [116.3443074, 6.7197498],
          [116.3444565, 6.7198188],
          [116.3445168, 6.7197927],
          [116.3446683, 6.7198527],
          [116.3448043, 6.7199571],
          [116.3451915, 6.7199906],
          [116.3452773, 6.7197821],
          [116.3453824, 6.71969],
          [116.3454875, 6.7196501],
          [116.3455648, 6.7196777],
          [116.3455802, 6.7197453],
          [116.3456977, 6.719779],
          [116.3457502, 6.7198926],
          [116.3459017, 6.7199479],
          [116.345911, 6.7200523],
          [116.3461124, 6.7200941],
          [116.3463283, 6.7201628],
          [116.3463748, 6.7200736],
          [116.3463879, 6.7201235],
          [116.3464273, 6.7201485],
          [116.3465018, 6.7201279],
          [116.3465322, 6.7200693],
          [116.3465584, 6.7200975],
          [116.3465737, 6.7201083],
          [116.3465712, 6.7201435],
          [116.346758, 6.7202334],
          [116.3467209, 6.7203009],
          [116.346826, 6.7204237],
          [116.3473422, 6.7205066],
          [116.3474226, 6.720393],
          [116.3474226, 6.720522],
          [116.3475493, 6.7205373],
          [116.3476518, 6.7205063],
          [116.3477935, 6.7204943],
          [116.3478801, 6.7204084],
          [116.348333, 6.720198],
          [116.3485335, 6.7199822],
          [116.3485835, 6.7198261],
          [116.3485848, 6.7197143],
          [116.3486759, 6.7195069],
          [116.3487031, 6.7192107],
          [116.3485536, 6.7189067],
          [116.348463, 6.7187884],
          [116.3483575, 6.7186925],
          [116.3483217, 6.7186409],
          [116.3482638, 6.7185907],
          [116.3482065, 6.7185805],
          [116.3481536, 6.7184923],
          [116.3480611, 6.7184121],
          [116.3476864, 6.7182455],
          [116.347471, 6.7182113],
          [116.3472947, 6.7180663],
          [116.3471665, 6.718053],
          [116.3471137, 6.7179563],
          [116.3470925, 6.7177743],
          [116.3471063, 6.7175639],
          [116.3471749, 6.7172826],
          [116.3472376, 6.7169942],
          [116.3473625, 6.7167396],
          [116.3475356, 6.7164911],
          [116.3477268, 6.7162656],
          [116.3478911, 6.7160575],
          [116.3480105, 6.7159441],
          [116.348107, 6.715821],
          [116.34828, 6.7157746],
          [116.3483972, 6.7156801],
          [116.3485722, 6.7156179],
          [116.3488903, 6.7155178],
          [116.3494363, 6.7153346],
          [116.3497308, 6.7153139],
          [116.3505119, 6.7150944],
          [116.3511948, 6.7150129],
          [116.352184, 6.7149913],
          [116.3536115, 6.7150018],
          [116.3554733, 6.714734],
          [116.3569901, 6.7143311],
          [116.3585257, 6.713815],
          [116.3596523, 6.7130168],
          [116.359884, 6.7127203],
          [116.3597559, 6.7126067],
          [116.3596567, 6.7123921],
          [116.3594198, 6.7121717],
          [116.3592731, 6.7119991],
          [116.3596699, 6.711774],
          [116.3598225, 6.7119258],
          [116.3599012, 6.7121717],
          [116.3604005, 6.7123193],
          [116.3610524, 6.7117857],
          [116.3635263, 6.7100486],
          [116.3646473, 6.7092324],
          [116.3652748, 6.708928],
          [116.3657621, 6.7088093],
          [116.3665265, 6.7088127],
          [116.3670504, 6.7088642],
          [116.367425, 6.708949],
          [116.3678557, 6.7090452],
          [116.3686772, 6.7090086],
          [116.3692417, 6.7088112],
          [116.3693938, 6.7087376],
          [116.369347, 6.7086694],
          [116.3688174, 6.7085737],
        ],
      ],
      [
        [
          [116.3362237, 6.7158726],
          [116.3363418, 6.7157234],
          [116.3365671, 6.7155956],
          [116.3366744, 6.7153931],
          [116.3366207, 6.7151054],
          [116.3363954, 6.7148071],
          [116.3361701, 6.7147005],
          [116.3359663, 6.7147005],
          [116.3356015, 6.7146259],
          [116.335344, 6.7146259],
          [116.3353279, 6.7149168],
          [116.3352367, 6.7150308],
          [116.3353011, 6.715244],
          [116.3354191, 6.7154464],
          [116.3355264, 6.715489],
          [116.3355693, 6.7156275],
          [116.3356766, 6.7156915],
          [116.3358053, 6.7156488],
          [116.3359877, 6.715798],
          [116.3362237, 6.7158726],
        ],
      ],
      [
        [
          [116.984219, 6.7210083],
          [116.9834988, 6.721928],
          [116.983012, 6.7230194],
          [116.9835444, 6.7237866],
          [116.9838367, 6.7237733],
          [116.9856217, 6.722199],
          [116.9862829, 6.7218967],
          [116.9862963, 6.7216023],
          [116.9851202, 6.7207845],
          [116.984219, 6.7210083],
        ],
      ],
      [
        [
          [117.2754003, 6.733139],
          [117.2766994, 6.7355109],
          [117.2779011, 6.7372157],
          [117.2792744, 6.7382386],
          [117.2806477, 6.7392614],
          [117.2816776, 6.7375567],
          [117.2821926, 6.7353405],
          [117.2808193, 6.7334652],
          [117.2754003, 6.733139],
        ],
      ],
      [
        [
          [117.2566633, 6.8030567],
          [117.2561433, 6.8036633],
          [117.2564899, 6.8044866],
          [117.2569232, 6.8056131],
          [117.2567932, 6.8070862],
          [117.2575298, 6.8097293],
          [117.258483, 6.8098159],
          [117.2597829, 6.8089493],
          [117.261386, 6.808906],
          [117.2624259, 6.8086027],
          [117.2609527, 6.8063497],
          [117.2598262, 6.8060897],
          [117.259003, 6.8048332],
          [117.2566633, 6.8030567],
        ],
      ],
      [
        [
          [117.2687084, 6.8049198],
          [117.2679719, 6.8072596],
          [117.266802, 6.8084727],
          [117.2660654, 6.8094693],
          [117.2661088, 6.8108558],
          [117.2668887, 6.8113324],
          [117.2681885, 6.8095993],
          [117.2710482, 6.8093393],
          [117.2717414, 6.8076062],
          [117.2706149, 6.8057864],
          [117.2687084, 6.8049198],
        ],
      ],
      [
        [
          [117.2581951, 6.8231699],
          [117.2581946, 6.8239439],
          [117.2603177, 6.8243339],
          [117.2619641, 6.8252871],
          [117.2648671, 6.8257637],
          [117.2677268, 6.8280168],
          [117.2688966, 6.8298799],
          [117.2726146, 6.8330557],
          [117.281541, 6.8371462],
          [117.2882358, 6.840555],
          [117.2889224, 6.8381689],
          [117.2878925, 6.8333965],
          [117.2839443, 6.8299877],
          [117.2770662, 6.8243344],
          [117.2681514, 6.8223177],
          [117.2635649, 6.8161587],
          [117.2617475, 6.8173147],
          [117.2619641, 6.8193078],
          [117.2594078, 6.8216475],
          [117.2576313, 6.8215176],
          [117.2572414, 6.8226441],
          [117.2581951, 6.8231699],
        ],
      ],
      [
        [
          [117.2489396, 6.8427074],
          [117.2497362, 6.8428912],
          [117.2500119, 6.8423091],
          [117.2497668, 6.8412368],
          [117.2493685, 6.8405321],
          [117.2484188, 6.8401951],
          [117.2471933, 6.8401645],
          [117.2472545, 6.8411142],
          [117.2484494, 6.8416657],
          [117.2489396, 6.8427074],
        ],
      ],
      [
        [
          [117.4676602, 6.8920237],
          [117.4679044, 6.8928259],
          [117.4683893, 6.8934012],
          [117.4697837, 6.8938418],
          [117.4730678, 6.8937036],
          [117.4774167, 6.8927267],
          [117.4800495, 6.891569],
          [117.4806323, 6.8908465],
          [117.4805947, 6.8901189],
          [117.4802198, 6.8893887],
          [117.4803204, 6.8888236],
          [117.4808329, 6.888665],
          [117.4815939, 6.8889139],
          [117.4820209, 6.8888183],
          [117.482206, 6.8884801],
          [117.4820475, 6.8881089],
          [117.4816451, 6.8877882],
          [117.4814957, 6.8872854],
          [117.4815427, 6.8865235],
          [117.4819971, 6.8858784],
          [117.481877, 6.8849442],
          [117.4813798, 6.8840598],
          [117.4803322, 6.883232],
          [117.4785179, 6.8824362],
          [117.4764982, 6.8819188],
          [117.4739613, 6.8822861],
          [117.4712823, 6.8836218],
          [117.4698148, 6.8849038],
          [117.468556, 6.887163],
          [117.4677068, 6.8897671],
          [117.4676602, 6.8920237],
        ],
      ],
      [
        [
          [117.3383468, 6.9296339],
          [117.3387765, 6.9292074],
          [117.3392715, 6.9289107],
          [117.3393462, 6.9290683],
          [117.3396731, 6.9291981],
          [117.3402148, 6.9291425],
          [117.340411, 6.9291518],
          [117.340495, 6.9292352],
          [117.3407192, 6.9291239],
          [117.3408313, 6.9290868],
          [117.34085, 6.9289478],
          [117.3406258, 6.9288736],
          [117.3406445, 6.9287438],
          [117.3404577, 6.9286603],
          [117.3402989, 6.9284842],
          [117.3400187, 6.9283358],
          [117.3397945, 6.9282246],
          [117.3392435, 6.9279742],
          [117.3388512, 6.9278352],
          [117.3385523, 6.9277795],
          [117.3382815, 6.9277517],
          [117.338076, 6.9278444],
          [117.3375436, 6.9282338],
          [117.3369552, 6.9286789],
          [117.3364508, 6.9289385],
          [117.336208, 6.9289478],
          [117.3360492, 6.9290683],
          [117.3356102, 6.929161],
          [117.3346482, 6.9295504],
          [117.3342092, 6.9296339],
          [117.333845, 6.929569],
          [117.3335367, 6.9298286],
          [117.3333126, 6.9300326],
          [117.3331818, 6.930116],
          [117.3331351, 6.9302736],
          [117.3329016, 6.930218],
          [117.3326961, 6.9304683],
          [117.3325747, 6.9305796],
          [117.3328082, 6.9306909],
          [117.3329577, 6.9309134],
          [117.3331818, 6.9309041],
          [117.3333873, 6.9310988],
          [117.3335461, 6.931173],
          [117.3334994, 6.9313492],
          [117.3335834, 6.9313677],
          [117.3336301, 6.9312472],
          [117.3338076, 6.9313121],
          [117.3344054, 6.9312935],
          [117.3355952, 6.9313613],
          [117.3363481, 6.931581],
          [117.3365816, 6.9316551],
          [117.3367123, 6.9316366],
          [117.3366563, 6.931479],
          [117.3373288, 6.9307836],
          [117.3380013, 6.9300048],
          [117.3383468, 6.9296339],
        ],
      ],
      [
        [
          [117.4675744, 6.9347545],
          [117.4681921, 6.9346196],
          [117.4682909, 6.9343621],
          [117.4682044, 6.9341046],
          [117.4678461, 6.9340065],
          [117.4673767, 6.9339819],
          [117.4668084, 6.9341659],
          [117.4666725, 6.9343866],
          [117.4667219, 6.9347055],
          [117.4669937, 6.9347791],
          [117.4675744, 6.9347545],
        ],
      ],
      [
        [
          [117.4070209, 6.9313314],
          [117.4070907, 6.9313234],
          [117.4071899, 6.9313314],
          [117.4072731, 6.9313447],
          [117.4073669, 6.9313527],
          [117.4074098, 6.931374],
          [117.4075225, 6.9314432],
          [117.4076244, 6.9314912],
          [117.4076942, 6.9315018],
          [117.4077371, 6.9314832],
          [117.4077693, 6.9314512],
          [117.40778, 6.9314113],
          [117.4077639, 6.9313793],
          [117.4077317, 6.9313713],
          [117.4076566, 6.9313846],
          [117.407603, 6.9313633],
          [117.4075493, 6.9312941],
          [117.4075091, 6.9312515],
          [117.4074823, 6.9312036],
          [117.4074259, 6.9311663],
          [117.4073482, 6.931137],
          [117.4072516, 6.931129],
          [117.4072194, 6.931121],
          [117.4071497, 6.9311024],
          [117.4071041, 6.9310651],
          [117.407088, 6.9309826],
          [117.4070933, 6.9308867],
          [117.4071175, 6.9307856],
          [117.4071389, 6.930695],
          [117.4071765, 6.9305566],
          [117.4071872, 6.9304847],
          [117.4071872, 6.9303915],
          [117.4071792, 6.9302637],
          [117.4071577, 6.9301625],
          [117.4071175, 6.9300347],
          [117.4070826, 6.9299735],
          [117.4070343, 6.9298962],
          [117.4069995, 6.9298031],
          [117.4069995, 6.9296939],
          [117.4070182, 6.9296193],
          [117.4070585, 6.9295528],
          [117.4071416, 6.9294809],
          [117.4071792, 6.929433],
          [117.4071845, 6.9293504],
          [117.4071819, 6.9292892],
          [117.4071228, 6.9292226],
          [117.4070531, 6.9291986],
          [117.4069378, 6.9291933],
          [117.4068117, 6.9292253],
          [117.4067393, 6.9292625],
          [117.4066052, 6.9293398],
          [117.4065194, 6.9293957],
          [117.4064228, 6.9294543],
          [117.406345, 6.9295102],
          [117.4062324, 6.9295821],
          [117.4061787, 6.9296247],
          [117.4061143, 6.9296726],
          [117.40605, 6.9297392],
          [117.4060017, 6.9298323],
          [117.405948, 6.9299282],
          [117.4058998, 6.9300134],
          [117.4058622, 6.9301306],
          [117.4058059, 6.9303249],
          [117.4057791, 6.9304155],
          [117.4057737, 6.9305353],
          [117.4057817, 6.9306178],
          [117.4057978, 6.9306791],
          [117.4058408, 6.9307935],
          [117.4059185, 6.9309107],
          [117.4059936, 6.9309959],
          [117.4061089, 6.931078],
          [117.4063504, 6.9311983],
          [117.4064567, 6.9312611],
          [117.4066374, 6.9313154],
          [117.4067554, 6.9313367],
          [117.4068519, 6.9313314],
          [117.4069405, 6.9313367],
          [117.4070209, 6.9313314],
        ],
      ],
      [
        [
          [117.2076067, 6.9800306],
          [117.2077355, 6.9797484],
          [117.207588, 6.9795221],
          [117.2077033, 6.9793064],
          [117.2078857, 6.9789603],
          [117.2080654, 6.9789816],
          [117.2080439, 6.9788539],
          [117.2082853, 6.978545],
          [117.208339, 6.9786222],
          [117.2084731, 6.9784625],
          [117.2084221, 6.9784305],
          [117.208516, 6.9782096],
          [117.2087386, 6.9779673],
          [117.2088915, 6.9779726],
          [117.209031, 6.9778848],
          [117.2091249, 6.9776744],
          [117.2089505, 6.9775387],
          [117.208685, 6.9775014],
          [117.2087198, 6.9772032],
          [117.2089371, 6.9770754],
          [117.2090605, 6.9768917],
          [117.2090524, 6.9766201],
          [117.2089961, 6.9763166],
          [117.2089237, 6.976314],
          [117.2088513, 6.9760158],
          [117.2087842, 6.9757336],
          [117.2086018, 6.9756138],
          [117.2083873, 6.9753981],
          [117.2081861, 6.9751718],
          [117.2080359, 6.9750094],
          [117.2078213, 6.9749029],
          [117.2077167, 6.9746873],
          [117.2074217, 6.9744024],
          [117.2071293, 6.9741468],
          [117.2069576, 6.974003],
          [117.2066921, 6.9736836],
          [117.2064587, 6.9735478],
          [117.2063005, 6.9732922],
          [117.2060162, 6.9731857],
          [117.2057694, 6.972994],
          [117.2057104, 6.9727411],
          [117.2056621, 6.9725121],
          [117.2054529, 6.9723497],
          [117.2051337, 6.9721101],
          [117.2048977, 6.9720063],
          [117.2047394, 6.9718146],
          [117.2044444, 6.9717081],
          [117.2042057, 6.9718865],
          [117.204254, 6.9716948],
          [117.2042057, 6.9714765],
          [117.2038677, 6.9713274],
          [117.2034252, 6.971149],
          [117.2031328, 6.9710452],
          [117.202878, 6.9709706],
          [117.2026017, 6.9708934],
          [117.2024462, 6.970928],
          [117.2021672, 6.9708827],
          [117.2018668, 6.9708881],
          [117.2014993, 6.9709307],
          [117.2011667, 6.9711011],
          [117.2010836, 6.9712342],
          [117.2009441, 6.9714019],
          [117.2009763, 6.9716229],
          [117.2007939, 6.9716681],
          [117.2006866, 6.9719131],
          [117.2005606, 6.9721421],
          [117.2005257, 6.9724189],
          [117.2004774, 6.9727118],
          [117.200228, 6.9729328],
          [117.2001153, 6.9732469],
          [117.2000188, 6.973428],
          [117.1998954, 6.9736197],
          [117.1996459, 6.973846],
          [117.1993831, 6.9739711],
          [117.199316, 6.974051],
          [117.1991095, 6.9741255],
          [117.1988896, 6.974208],
          [117.198852, 6.9743784],
          [117.1987313, 6.9745914],
          [117.1987018, 6.9747059],
          [117.1984014, 6.9746553],
          [117.198168, 6.974674],
          [117.1978462, 6.9748683],
          [117.197637, 6.9747618],
          [117.1973929, 6.9747751],
          [117.197181, 6.9747911],
          [117.196835, 6.9748976],
          [117.196489, 6.9750707],
          [117.1961698, 6.9752437],
          [117.1959043, 6.9754647],
          [117.1956038, 6.9756271],
          [117.1954268, 6.9758241],
          [117.1951747, 6.9759146],
          [117.1949226, 6.9760823],
          [117.1949226, 6.9762022],
          [117.194716, 6.9763539],
          [117.1946275, 6.9765536],
          [117.1946517, 6.9766761],
          [117.1945631, 6.9768624],
          [117.1946704, 6.9769796],
          [117.1947858, 6.9772458],
          [117.1948636, 6.9773576],
          [117.1949574, 6.9776931],
          [117.1952015, 6.9778182],
          [117.1954241, 6.9778927],
          [117.1955609, 6.9776638],
          [117.1956897, 6.9777783],
          [117.1956548, 6.9779194],
          [117.1956816, 6.9781084],
          [117.1956682, 6.9782761],
          [117.1958345, 6.978332],
          [117.1958721, 6.9783081],
          [117.1961349, 6.9784785],
          [117.1963495, 6.9786063],
          [117.1966982, 6.9786781],
          [117.1969798, 6.9788512],
          [117.1969771, 6.9790402],
          [117.197122, 6.9792505],
          [117.1973097, 6.9793757],
          [117.1975511, 6.9795035],
          [117.1978247, 6.9796552],
          [117.1980554, 6.9797218],
          [117.1981493, 6.9798309],
          [117.1983397, 6.9797857],
          [117.1985623, 6.9798576],
          [117.1987849, 6.9798389],
          [117.1989164, 6.9799161],
          [117.1991497, 6.979815],
          [117.1992141, 6.9796233],
          [117.1992785, 6.9796366],
          [117.1993321, 6.9797937],
          [117.1994153, 6.9798176],
          [117.1994716, 6.9797511],
          [117.1995681, 6.9798016],
          [117.1995413, 6.9800359],
          [117.1995226, 6.980209],
          [117.1994609, 6.9802755],
          [117.1995306, 6.9804166],
          [117.1996459, 6.9806137],
          [117.1997264, 6.9806589],
          [117.1998444, 6.9808639],
          [117.2, 6.9809651],
          [117.2000536, 6.9809385],
          [117.2001663, 6.9809731],
          [117.2000268, 6.981037],
          [117.2000563, 6.9813005],
          [117.2001904, 6.9814789],
          [117.2003916, 6.981596],
          [117.2006652, 6.9815588],
          [117.200582, 6.9813618],
          [117.2007671, 6.9813192],
          [117.2006866, 6.9812313],
          [117.2008824, 6.9811408],
          [117.2010595, 6.9811088],
          [117.2012767, 6.9811488],
          [117.201561, 6.9811195],
          [117.2017649, 6.981037],
          [117.2020438, 6.9809385],
          [117.202355, 6.9808453],
          [117.2027788, 6.9807015],
          [117.2030684, 6.9805737],
          [117.2034466, 6.9803714],
          [117.2038785, 6.9801584],
          [117.2042084, 6.9800572],
          [117.2045115, 6.9799907],
          [117.2046, 6.9801105],
          [117.2048333, 6.980193],
          [117.2050291, 6.9800013],
          [117.2052437, 6.9798868],
          [117.2055307, 6.9797404],
          [117.2058231, 6.9796632],
          [117.2063407, 6.9795993],
          [117.2065955, 6.9796366],
          [117.2068611, 6.9798868],
          [117.2069067, 6.9800359],
          [117.2069067, 6.9801318],
          [117.2071534, 6.9800439],
          [117.2071561, 6.980177],
          [117.2072983, 6.9801903],
          [117.2074002, 6.9800679],
          [117.2076067, 6.9800306],
        ],
      ],
      [
        [
          [116.7227717, 6.9882237],
          [116.7225482, 6.9882641],
          [116.7224365, 6.9882305],
          [116.722406, 6.9881263],
          [116.7222672, 6.9880423],
          [116.7221589, 6.9879482],
          [116.7220269, 6.9878642],
          [116.7219117, 6.9877701],
          [116.7217763, 6.9877499],
          [116.7216544, 6.9878104],
          [116.7215529, 6.9878776],
          [116.7214953, 6.9879818],
          [116.7214547, 6.9881263],
          [116.7213836, 6.9882506],
          [116.7212008, 6.9883884],
          [116.721001, 6.9884287],
          [116.7208385, 6.9884791],
          [116.7207319, 6.9885547],
          [116.7206303, 6.988632],
          [116.7205491, 6.9886724],
          [116.7204729, 6.9888219],
          [116.720456, 6.9888908],
          [116.7207742, 6.9892705],
          [116.7211246, 6.9895561],
          [116.7215258, 6.989583],
          [116.7218457, 6.9896939],
          [116.722059, 6.9896922],
          [116.7221961, 6.9897275],
          [116.7222672, 6.9897074],
          [116.7223163, 6.9896385],
          [116.7223316, 6.9895511],
          [116.7223095, 6.9893965],
          [116.7222994, 6.9892789],
          [116.7223671, 6.9890991],
          [116.7224619, 6.9889798],
          [116.7225042, 6.9888538],
          [116.7225753, 6.9884422],
          [116.7227717, 6.9882237],
        ],
      ],
      [
        [
          [117.2899063, 7.0231452],
          [117.2878918, 7.0227366],
          [117.2869169, 7.0228087],
          [117.2865572, 7.0230387],
          [117.2861659, 7.0235966],
          [117.2855048, 7.0254628],
          [117.2847926, 7.0262907],
          [117.2838807, 7.0269721],
          [117.2833939, 7.0270094],
          [117.2825672, 7.0266399],
          [117.2822284, 7.0266421],
          [117.281998, 7.0268779],
          [117.281347, 7.0285792],
          [117.280567, 7.0303195],
          [117.280544, 7.0309013],
          [117.2811023, 7.0330226],
          [117.2811743, 7.0330789],
          [117.2814345, 7.0331348],
          [117.2814854, 7.0332187],
          [117.2814536, 7.0335071],
          [117.2814134, 7.0336748],
          [117.2813463, 7.0337546],
          [117.2812605, 7.0337759],
          [117.2810188, 7.0338096],
          [117.2809694, 7.0338666],
          [117.280662, 7.0352151],
          [117.280654, 7.0353775],
          [117.2807398, 7.035468],
          [117.2812414, 7.0358141],
          [117.2812816, 7.0358966],
          [117.2812682, 7.0359871],
          [117.280949, 7.036232],
          [117.2809115, 7.0363385],
          [117.2809249, 7.0364343],
          [117.2809705, 7.0365328],
          [117.2811039, 7.0366271],
          [117.2813433, 7.037036],
          [117.2816451, 7.0375217],
          [117.2818315, 7.0376056],
          [117.2818878, 7.0377015],
          [117.2820477, 7.038131],
          [117.2820554, 7.0382325],
          [117.2820099, 7.038327],
          [117.2818851, 7.0383563],
          [117.2813996, 7.0381939],
          [117.2808981, 7.038158],
          [117.2808337, 7.0382112],
          [117.2808095, 7.038363],
          [117.2807613, 7.0384508],
          [117.2806751, 7.0384649],
          [117.2800562, 7.0382954],
          [117.2799435, 7.0382395],
          [117.2798788, 7.038178],
          [117.2798734, 7.0380475],
          [117.2798117, 7.037953],
          [117.2793236, 7.0377294],
          [117.2791684, 7.0377017],
          [117.2790304, 7.0377403],
          [117.2788435, 7.037929],
          [117.2784546, 7.0382592],
          [117.2783687, 7.0384561],
          [117.2783741, 7.0386132],
          [117.2783929, 7.0387357],
          [117.2783902, 7.0388262],
          [117.2783423, 7.0390008],
          [117.2781622, 7.039135],
          [117.2780764, 7.0391616],
          [117.2779235, 7.0391296],
          [117.2778166, 7.0391525],
          [117.2776985, 7.0394107],
          [117.2776794, 7.0395635],
          [117.2777653, 7.0397924],
          [117.2777813, 7.0399016],
          [117.2777518, 7.0400001],
          [117.277497, 7.0403355],
          [117.2774142, 7.0405368],
          [117.2774017, 7.0407538],
          [117.2777114, 7.0415919],
          [117.2784974, 7.0422655],
          [117.278674, 7.0427352],
          [117.2787027, 7.0430707],
          [117.2783771, 7.0433924],
          [117.2775305, 7.0435842],
          [117.2767538, 7.0435291],
          [117.2758273, 7.0428205],
          [117.2744402, 7.0406083],
          [117.2744509, 7.0404193],
          [117.2746306, 7.0401451],
          [117.2746601, 7.0399987],
          [117.2746177, 7.0397875],
          [117.2744214, 7.0395887],
          [117.2739717, 7.0394725],
          [117.2737218, 7.0395303],
          [117.273437, 7.0397192],
          [117.2727882, 7.0409633],
          [117.2714173, 7.0424317],
          [117.2706381, 7.0427811],
          [117.2698836, 7.0428564],
          [117.2681638, 7.0426587],
          [117.2675898, 7.0422487],
          [117.2673698, 7.0417403],
          [117.2672282, 7.0416678],
          [117.2668829, 7.0416149],
          [117.2664772, 7.0417317],
          [117.2658181, 7.042105],
          [117.2652294, 7.0425815],
          [117.2649537, 7.043084],
          [117.2634624, 7.0475082],
          [117.2631298, 7.0482269],
          [117.2630332, 7.0489243],
          [117.2632263, 7.0495099],
          [117.2635643, 7.0502233],
          [117.2645498, 7.0510814],
          [117.2659954, 7.0520332],
          [117.2671692, 7.052225],
          [117.2680114, 7.0522198],
          [117.2684342, 7.0520742],
          [117.2685854, 7.0518311],
          [117.2689837, 7.0498838],
          [117.2690467, 7.0494514],
          [117.2690172, 7.0491945],
          [117.2690569, 7.0490108],
          [117.2691647, 7.0488977],
          [117.2705362, 7.0483193],
          [117.2714355, 7.0480377],
          [117.2728429, 7.0477869],
          [117.2737001, 7.0479226],
          [117.2761836, 7.0474049],
          [117.2787428, 7.0469633],
          [117.2790782, 7.0467495],
          [117.2794805, 7.0465525],
          [117.2802154, 7.0466643],
          [117.2811381, 7.0469731],
          [117.281814, 7.0470026],
          [117.2820737, 7.0472922],
          [117.2819589, 7.0476386],
          [117.2817191, 7.047878],
          [117.2808335, 7.0491763],
          [117.2807572, 7.0496989],
          [117.2807143, 7.0499492],
          [117.2806285, 7.0502154],
          [117.2804515, 7.0503751],
          [117.2802101, 7.0503485],
          [117.2799526, 7.0503485],
          [117.2796629, 7.0505028],
          [117.2795363, 7.0508042],
          [117.2766697, 7.0533472],
          [117.2762064, 7.0536858],
          [117.2759401, 7.0541535],
          [117.2758201, 7.0549422],
          [117.2755749, 7.0553004],
          [117.2751657, 7.055432],
          [117.272644, 7.0565647],
          [117.2718827, 7.0566351],
          [117.2708517, 7.0565008],
          [117.2676662, 7.0546973],
          [117.2670547, 7.054676],
          [117.2666111, 7.0550054],
          [117.2662286, 7.0556449],
          [117.2660234, 7.0558199],
          [117.2657181, 7.0559166],
          [117.2646429, 7.0558366],
          [117.2640415, 7.0555203],
          [117.2630421, 7.0555225],
          [117.262188, 7.0558116],
          [117.2619102, 7.0560309],
          [117.2618136, 7.0563264],
          [117.2620728, 7.0595723],
          [117.2621127, 7.0599459],
          [117.2621047, 7.0601029],
          [117.2619759, 7.0602254],
          [117.2616836, 7.0602653],
          [117.2614632, 7.0604463],
          [117.2612851, 7.0606736],
          [117.261312, 7.0610635],
          [117.2627549, 7.0631252],
          [117.2630231, 7.0634553],
          [117.2633222, 7.063587],
          [117.2635971, 7.063579],
          [117.2636964, 7.0636123],
          [117.2637487, 7.0636875],
          [117.2637701, 7.0638938],
          [117.2637929, 7.0639963],
          [117.2638466, 7.0640588],
          [117.2639981, 7.0641294],
          [117.2640933, 7.0642565],
          [117.2641913, 7.0645007],
          [117.2643133, 7.0645586],
          [117.264493, 7.0645613],
          [117.265075, 7.0643111],
          [117.2653272, 7.0642578],
          [117.2661989, 7.0641926],
          [117.2663545, 7.0642086],
          [117.2665261, 7.0642898],
          [117.2670304, 7.064532],
          [117.2672515, 7.0645516],
          [117.2673537, 7.0645901],
          [117.267371, 7.0646824],
          [117.26732, 7.0649046],
          [117.2673361, 7.0650231],
          [117.2677546, 7.0653518],
          [117.2689173, 7.0662243],
          [117.2691267, 7.0664614],
          [117.2692715, 7.0668181],
          [117.2692445, 7.0669323],
          [117.2689039, 7.0672065],
          [117.2682308, 7.0677883],
          [117.268086, 7.0678788],
          [117.2674005, 7.0680376],
          [117.2672623, 7.0681119],
          [117.2672035, 7.0682475],
          [117.2672563, 7.0683545],
          [117.2678365, 7.0686308],
          [117.2678848, 7.0686734],
          [117.2678821, 7.0687373],
          [117.2678339, 7.0688238],
          [117.2665804, 7.0708658],
          [117.2665134, 7.0711406],
          [117.2666039, 7.0713023],
          [117.2667655, 7.0714334],
          [117.2672483, 7.0714388],
          [117.2685963, 7.0721295],
          [117.2693599, 7.0730112],
          [117.2695952, 7.0734757],
          [117.2697032, 7.0735482],
          [117.2697649, 7.0735815],
          [117.2697622, 7.0736268],
          [117.2697381, 7.073682],
          [117.2696473, 7.0738985],
          [117.269614, 7.0741345],
          [117.2696544, 7.074394],
          [117.2697772, 7.0745211],
          [117.2698909, 7.0746635],
          [117.2700995, 7.0746655],
          [117.2703139, 7.074592],
          [117.2705608, 7.0744492],
          [117.2706923, 7.0743055],
          [117.2707486, 7.0741545],
          [117.2712314, 7.0737552],
          [117.2716538, 7.0734744],
          [117.2718188, 7.0734677],
          [117.2720039, 7.0735116],
          [117.2736541, 7.0743947],
          [117.2747135, 7.0750368],
          [117.27478, 7.0752824],
          [117.2749731, 7.0754581],
          [117.277556, 7.0761821],
          [117.2780871, 7.0761129],
          [117.2783397, 7.0759761],
          [117.2785967, 7.0758733],
          [117.2788274, 7.0759372],
          [117.2791117, 7.0762779],
          [117.2799514, 7.076594],
          [117.2808587, 7.07658],
          [117.2849756, 7.0753937],
          [117.285906, 7.0753014],
          [117.2881337, 7.0754152],
          [117.289388, 7.075502],
          [117.2898352, 7.0753569],
          [117.2901571, 7.0749071],
          [117.2903093, 7.0744651],
          [117.2898137, 7.0723491],
          [117.2898566, 7.0720829],
          [117.2900156, 7.0720084],
          [117.2903019, 7.0720297],
          [117.290951, 7.0722107],
          [117.2912246, 7.0722],
          [117.2914687, 7.0720273],
          [117.2914205, 7.0717696],
          [117.2909569, 7.0714571],
          [117.2882077, 7.0703181],
          [117.287625, 7.0698204],
          [117.2874909, 7.0692667],
          [117.2873193, 7.0688036],
          [117.2873997, 7.0685108],
          [117.2877431, 7.0684149],
          [117.28814, 7.0684589],
          [117.2889071, 7.068194],
          [117.2910232, 7.0690228],
          [117.2916744, 7.0695768],
          [117.2919971, 7.06965],
          [117.2921683, 7.0695678],
          [117.2922599, 7.0692933],
          [117.2921982, 7.0689287],
          [117.2922277, 7.068769],
          [117.2923296, 7.0686705],
          [117.2924638, 7.0685054],
          [117.2932711, 7.0682925],
          [117.29352, 7.0683285],
          [117.2937163, 7.0684895],
          [117.2938378, 7.0686686],
          [117.2938271, 7.0692574],
          [117.2940355, 7.0695901],
          [117.2946846, 7.0697552],
          [117.294926, 7.0697339],
          [117.2952599, 7.0696549],
          [117.2957755, 7.0693407],
          [117.2960096, 7.0686345],
          [117.2962349, 7.0682832],
          [117.2964817, 7.0683204],
          [117.2971402, 7.0689017],
          [117.2977221, 7.0697614],
          [117.2978228, 7.0702316],
          [117.2980481, 7.0705564],
          [117.29837, 7.0706309],
          [117.298665, 7.07037],
          [117.2987723, 7.0698856],
          [117.2989334, 7.0696922],
          [117.2996413, 7.0696141],
          [117.3007478, 7.0698325],
          [117.3010777, 7.070382],
          [117.3012493, 7.0704988],
          [117.3014116, 7.0704872],
          [117.3020714, 7.0702316],
          [117.3024201, 7.0702849],
          [117.3027259, 7.0704978],
          [117.3027527, 7.0709397],
          [117.3017228, 7.0729007],
          [117.3011866, 7.0750235],
          [117.300741, 7.07551],
          [117.3005881, 7.0758191],
          [117.3006284, 7.0759997],
          [117.3008162, 7.0762872],
          [117.3012832, 7.0764422],
          [117.3013231, 7.076604],
          [117.301256, 7.0767397],
          [117.3011058, 7.0768249],
          [117.3007709, 7.0768069],
          [117.3006444, 7.0768801],
          [117.3005319, 7.0770405],
          [117.3002395, 7.0777432],
          [117.3001831, 7.0779613],
          [117.3002559, 7.0781524],
          [117.3005563, 7.0785091],
          [117.3009181, 7.0788106],
          [117.3011625, 7.0788338],
          [117.3014039, 7.0787167],
          [117.301594, 7.078571],
          [117.3018223, 7.0782748],
          [117.3019856, 7.0778793],
          [117.3021841, 7.0777196],
          [117.3034887, 7.0773308],
          [117.3038845, 7.0773682],
          [117.304244, 7.0773416],
          [117.3070818, 7.0758217],
          [117.3101073, 7.0747173],
          [117.3103434, 7.0745597],
          [117.3104667, 7.0743361],
          [117.3106545, 7.0737292],
          [117.3108798, 7.0734737],
          [117.3113894, 7.0733459],
          [117.3117335, 7.0731033],
          [117.3118322, 7.0727763],
          [117.3117488, 7.072409],
          [117.3115343, 7.0721162],
          [117.3115074, 7.0718633],
          [117.3115587, 7.0717135],
          [117.3117622, 7.0716344],
          [117.3121986, 7.0716488],
          [117.3154235, 7.0725421],
          [117.3158254, 7.0724659],
          [117.3160457, 7.0723611],
          [117.3162174, 7.0720044],
          [117.3161423, 7.0716468],
          [117.3151391, 7.0696513],
          [117.3151231, 7.0694011],
          [117.3153966, 7.0690924],
          [117.3155415, 7.0689273],
          [117.3155683, 7.0686239],
          [117.31552, 7.0682725],
          [117.3153108, 7.0680063],
          [117.3139, 7.0667766],
          [117.3136264, 7.0665849],
          [117.3135781, 7.0662974],
          [117.3137927, 7.0660153],
          [117.3144474, 7.0658658],
          [117.3171243, 7.06493],
          [117.3204231, 7.0648042],
          [117.3211607, 7.0647909],
          [117.3215503, 7.0649279],
          [117.3218124, 7.0653206],
          [117.32185, 7.0660339],
          [117.3219251, 7.066191],
          [117.3220089, 7.0662495],
          [117.3221075, 7.0662455],
          [117.3223194, 7.0661564],
          [117.322736, 7.0661686],
          [117.3236994, 7.0664156],
          [117.3238013, 7.0664372],
          [117.3238357, 7.0664721],
          [117.3238471, 7.0665126],
          [117.3238288, 7.0665666],
          [117.3237961, 7.0665952],
          [117.3237276, 7.0666222],
          [117.3236813, 7.0666385],
          [117.3236513, 7.0666704],
          [117.3233789, 7.0674181],
          [117.3234057, 7.0682619],
          [117.323446, 7.0685866],
          [117.3243406, 7.0699878],
          [117.3250995, 7.070399],
          [117.325298, 7.0704256],
          [117.325524, 7.0703881],
          [117.3267536, 7.0695846],
          [117.3268001, 7.0694913],
          [117.3268564, 7.0692411],
          [117.3268981, 7.0691509],
          [117.3269744, 7.0691027],
          [117.3273177, 7.0690388],
          [117.3274248, 7.0689905],
          [117.3274947, 7.06891],
          [117.3275028, 7.0688561],
          [117.3274314, 7.0682519],
          [117.3274347, 7.0682027],
          [117.3274456, 7.0681698],
          [117.3274656, 7.0681541],
          [117.3275125, 7.0681438],
          [117.3275387, 7.0681315],
          [117.3275658, 7.0680842],
          [117.3276771, 7.0678104],
          [117.3277039, 7.0677086],
          [117.3276852, 7.06762],
          [117.3275698, 7.0672913],
          [117.327543, 7.0671722],
          [117.3275631, 7.0671203],
          [117.3276235, 7.0671296],
          [117.3277053, 7.0671695],
          [117.3284186, 7.0675225],
          [117.3288592, 7.0676112],
          [117.3295708, 7.0675482],
          [117.3306292, 7.0670249],
          [117.3313625, 7.066377],
          [117.3324515, 7.0653873],
          [117.3327653, 7.0649853],
          [117.3328913, 7.0647484],
          [117.3329889, 7.0644555],
          [117.333012, 7.0640537],
          [117.3330818, 7.063862],
          [117.3332937, 7.0637417],
          [117.3338112, 7.0635653],
          [117.333846, 7.0635387],
          [117.3338501, 7.0634895],
          [117.3337664, 7.0632793],
          [117.3337651, 7.0632149],
          [117.3337879, 7.0631661],
          [117.3338388, 7.0631235],
          [117.333867, 7.0630809],
          [117.3338764, 7.063031],
          [117.333862, 7.062882],
          [117.3338546, 7.0628391],
          [117.3338747, 7.0627986],
          [117.3340212, 7.0627377],
          [117.3340507, 7.0627279],
          [117.3340705, 7.0626885],
          [117.334093, 7.0625003],
          [117.3340836, 7.0624278],
          [117.3338496, 7.0613521],
          [117.3338315, 7.0613088],
          [117.3338026, 7.0612782],
          [117.3335887, 7.0611411],
          [117.3328712, 7.0608996],
          [117.3326929, 7.0609015],
          [117.3325319, 7.0609654],
          [117.3323884, 7.0609748],
          [117.3322744, 7.0608749],
          [117.3321752, 7.0606913],
          [117.3321162, 7.0604637],
          [117.332137, 7.0594299],
          [117.3324492, 7.0587989],
          [117.33367, 7.0572271],
          [117.3340769, 7.0570046],
          [117.3345409, 7.0568982],
          [117.3380566, 7.0568319],
          [117.3383979, 7.0566059],
          [117.3384267, 7.0565677],
          [117.3384435, 7.0565194],
          [117.3384314, 7.0564665],
          [117.3383798, 7.0564346],
          [117.3381693, 7.0563028],
          [117.3380988, 7.0562649],
          [117.3380754, 7.0562136],
          [117.3380901, 7.0561444],
          [117.338357, 7.055805],
          [117.3384294, 7.0557558],
          [117.3384858, 7.0557491],
          [117.3390919, 7.0558729],
          [117.3391952, 7.0558789],
          [117.3393011, 7.055829],
          [117.3401952, 7.055229],
          [117.3420635, 7.0526666],
          [117.3438401, 7.0501288],
          [117.3443927, 7.0495342],
          [117.3445013, 7.0494384],
          [117.3445978, 7.0494211],
          [117.3446783, 7.0494664],
          [117.3447118, 7.0495116],
          [117.3447521, 7.0496128],
          [117.3454139, 7.0500613],
          [117.3455292, 7.0500929],
          [117.3456418, 7.0500602],
          [117.3461984, 7.0497326],
          [117.3466906, 7.0494258],
          [117.3467389, 7.0493679],
          [117.3467523, 7.0493033],
          [117.3467108, 7.0491323],
          [117.3467302, 7.0490837],
          [117.3467805, 7.0490571],
          [117.3468254, 7.0490451],
          [117.3470916, 7.0490824],
          [117.3471419, 7.0491143],
          [117.3472049, 7.0493366],
          [117.3472358, 7.0493858],
          [117.3473022, 7.0493965],
          [117.3473826, 7.0493712],
          [117.3481799, 7.0486887],
          [117.3482765, 7.0485131],
          [117.348276, 7.0483566],
          [117.3482463, 7.0482206],
          [117.3481638, 7.0480605],
          [117.3475464, 7.0475793],
          [117.3474579, 7.0474489],
          [117.3473801, 7.0472785],
          [117.3468732, 7.046802],
          [117.3466774, 7.0467408],
          [117.3459022, 7.046621],
          [117.3457547, 7.0465864],
          [117.3455991, 7.0466423],
          [117.3454033, 7.0467674],
          [117.3453068, 7.0468047],
          [117.345178, 7.0467647],
          [117.3445799, 7.0464799],
          [117.3439394, 7.0461732],
          [117.3438609, 7.0460617],
          [117.3438535, 7.0459203],
          [117.3442799, 7.0446551],
          [117.3442766, 7.0445271],
          [117.3442444, 7.0444479],
          [117.3436691, 7.0438284],
          [117.3433499, 7.0437032],
          [117.3430254, 7.043626],
          [117.3427746, 7.043461],
          [117.3426928, 7.0433871],
          [117.342666, 7.0433073],
          [117.3426814, 7.0431971],
          [117.342646, 7.0428171],
          [117.3427116, 7.0424761],
          [117.3427882, 7.042298],
          [117.3429094, 7.0421803],
          [117.3455574, 7.0414006],
          [117.3457217, 7.0412699],
          [117.3458858, 7.0410176],
          [117.3463336, 7.0399478],
          [117.3466973, 7.0388118],
          [117.3467508, 7.0385658],
          [117.3466987, 7.0383879],
          [117.3466061, 7.0382934],
          [117.3464919, 7.0382308],
          [117.3435655, 7.0388085],
          [117.3414155, 7.0390065],
          [117.3406684, 7.0387223],
          [117.3400743, 7.0382272],
          [117.3391556, 7.0369016],
          [117.3385488, 7.0364393],
          [117.3377118, 7.0361759],
          [117.3360594, 7.0360123],
          [117.3354374, 7.0358085],
          [117.3345248, 7.0351552],
          [117.3340051, 7.0346265],
          [117.3338274, 7.0342023],
          [117.3334514, 7.0322653],
          [117.3333802, 7.0320856],
          [117.3331689, 7.0319715],
          [117.3327517, 7.0319034],
          [117.3279118, 7.0317798],
          [117.3276939, 7.0318354],
          [117.3275414, 7.0319958],
          [117.3259142, 7.0335318],
          [117.3254194, 7.0341506],
          [117.3253711, 7.0342432],
          [117.3253617, 7.0343443],
          [117.3254086, 7.0346045],
          [117.325418, 7.0346638],
          [117.3253926, 7.0347097],
          [117.3251713, 7.034892],
          [117.325115, 7.0348954],
          [117.3249393, 7.0347523],
          [117.3248803, 7.0347609],
          [117.3247662, 7.0348961],
          [117.3246, 7.0350824],
          [117.3245624, 7.0352128],
          [117.3245503, 7.0353419],
          [117.3244819, 7.0360181],
          [117.3240796, 7.0365026],
          [117.3239643, 7.0366051],
          [117.3237685, 7.0366397],
          [117.3235941, 7.0365199],
          [117.3232267, 7.0358104],
          [117.3230899, 7.0356706],
          [117.3228388, 7.0356194],
          [117.3225967, 7.0356449],
          [117.3220216, 7.0361477],
          [117.3202394, 7.038363],
          [117.3200892, 7.038512],
          [117.3198746, 7.0385653],
          [117.319601, 7.0384109],
          [117.3187377, 7.0378089],
          [117.3176926, 7.0375218],
          [117.3162321, 7.0372662],
          [117.3160467, 7.0371615],
          [117.3159264, 7.0369361],
          [117.3160068, 7.0367232],
          [117.3163823, 7.0364197],
          [117.3174744, 7.035656],
          [117.3175132, 7.0355881],
          [117.3175106, 7.0353698],
          [117.3175293, 7.0352154],
          [117.3176849, 7.0351169],
          [117.3187575, 7.0346494],
          [117.3190107, 7.0344487],
          [117.3190782, 7.0342796],
          [117.3191309, 7.0338791],
          [117.3191092, 7.0337932],
          [117.3189911, 7.0337533],
          [117.3186957, 7.0337098],
          [117.3186398, 7.0336575],
          [117.3184838, 7.0334036],
          [117.3185294, 7.0333157],
          [117.3199943, 7.0323824],
          [117.3214081, 7.0315128],
          [117.3215979, 7.0312496],
          [117.3217468, 7.0309306],
          [117.3217191, 7.0305532],
          [117.3208122, 7.0294738],
          [117.3206699, 7.0292322],
          [117.3206913, 7.0289128],
          [117.3208215, 7.0285164],
          [117.3223454, 7.024796],
          [117.3223825, 7.0244144],
          [117.322188, 7.0241184],
          [117.321897, 7.0239294],
          [117.3211138, 7.02394],
          [117.3200627, 7.0241822],
          [117.3197043, 7.0241237],
          [117.3193755, 7.0239071],
          [117.3178012, 7.0215096],
          [117.3177324, 7.0211985],
          [117.317588, 7.021065],
          [117.317301, 7.0210251],
          [117.3145812, 7.0201519],
          [117.3138719, 7.0196825],
          [117.3127247, 7.0185171],
          [117.3058017, 7.0151925],
          [117.304995, 7.0149927],
          [117.3043744, 7.0150972],
          [117.3036474, 7.0155484],
          [117.298667, 7.0164361],
          [117.2981943, 7.0167488],
          [117.2979033, 7.0173247],
          [117.297715, 7.0180559],
          [117.2935818, 7.0213765],
          [117.2921781, 7.0226729],
          [117.291702, 7.023011],
          [117.291285, 7.0230634],
          [117.2906398, 7.0231494],
          [117.2899063, 7.0231452],
        ],
      ],
      [
        [
          [117.4109388, 6.6245005],
          [117.4108392, 6.6242517],
          [117.4105609, 6.6241169],
          [117.4103605, 6.6243783],
          [117.410339, 6.624542],
          [117.4109388, 6.6245005],
        ],
      ],
      [
        [
          [117.3855058, 6.6280973],
          [117.3850759, 6.6279826],
          [117.3848451, 6.6280859],
          [117.3848248, 6.6287724],
          [117.3849896, 6.6291314],
          [117.3856947, 6.6294702],
          [117.3863059, 6.6289525],
          [117.3855058, 6.6280973],
        ],
      ],
      [
        [
          [116.7641852, 6.6423386],
          [116.7641593, 6.6428817],
          [116.7641975, 6.6433795],
          [116.7643444, 6.6435058],
          [116.7647838, 6.6435561],
          [116.765327, 6.6433795],
          [116.7656566, 6.6431343],
          [116.7656936, 6.6426941],
          [116.7657899, 6.6424391],
          [116.7654442, 6.6420664],
          [116.7649207, 6.6417955],
          [116.7645789, 6.6419168],
          [116.7643098, 6.6420321],
          [116.7641852, 6.6423386],
        ],
      ],
      [
        [
          [116.7766513, 6.6537633],
          [116.7765234, 6.6539744],
          [116.776485, 6.6541982],
          [116.7766321, 6.6543133],
          [116.7769774, 6.6544988],
          [116.7773292, 6.6544348],
          [116.7773612, 6.6542877],
          [116.7773548, 6.6538081],
          [116.7772205, 6.6535971],
          [116.7769135, 6.6535907],
          [116.7766513, 6.6537633],
        ],
      ],
      [
        [
          [117.3858006, 6.6279203],
          [117.3857822, 6.6275569],
          [117.3856224, 6.6272577],
          [117.3853644, 6.627344],
          [117.3852655, 6.6277143],
          [117.3858006, 6.6279203],
        ],
      ],
      [
        [
          [117.3869003, 6.6283954],
          [117.3871086, 6.628518],
          [117.3873635, 6.6285608],
          [117.3882194, 6.6285003],
          [117.3882017, 6.6279726],
          [117.3880013, 6.6276161],
          [117.3874191, 6.6275713],
          [117.3868536, 6.628084],
          [117.3869003, 6.6283954],
        ],
      ],
      [
        [
          [117.4364981, 6.6924016],
          [117.436239, 6.6930523],
          [117.4359836, 6.6936938],
          [117.434561, 6.6938122],
          [117.4340957, 6.694176],
          [117.4331964, 6.6955],
          [117.432283, 6.696397],
          [117.432065, 6.6968811],
          [117.4318905, 6.6970174],
          [117.4316749, 6.6971837],
          [117.4315544, 6.6977164],
          [117.4313312, 6.6981055],
          [117.430973, 6.6982729],
          [117.4305691, 6.698307],
          [117.4299738, 6.6988225],
          [117.4290994, 6.7009459],
          [117.4282251, 6.7023448],
          [117.4273258, 6.7032941],
          [117.4264764, 6.7035939],
          [117.4253273, 6.7052177],
          [117.423079, 6.7061919],
          [117.4211305, 6.7060171],
          [117.4194567, 6.7058422],
          [117.4173833, 6.7068415],
          [117.4153598, 6.707491],
          [117.4130677, 6.7071321],
          [117.4123371, 6.7076409],
          [117.4115127, 6.7075909],
          [117.4103211, 6.708155],
          [117.4096141, 6.70879],
          [117.4055359, 6.7113024],
          [117.4042504, 6.7117637],
          [117.4039631, 6.7118528],
          [117.4034188, 6.7124123],
          [117.4019449, 6.7131117],
          [117.400152, 6.712906],
          [117.4000518, 6.7128128],
          [117.3999174, 6.7126528],
          [117.399502, 6.7128142],
          [117.3978979, 6.7123373],
          [117.3967238, 6.7123623],
          [117.3944505, 6.7133615],
          [117.3931265, 6.7151352],
          [117.392125, 6.7183841],
          [117.391803, 6.7196033],
          [117.3915571, 6.721428],
          [117.392564, 6.7251905],
          [117.392511, 6.7259148],
          [117.3923432, 6.7266214],
          [117.3916189, 6.7276989],
          [117.3910095, 6.7285556],
          [117.3904178, 6.7295007],
          [117.3900645, 6.7302956],
          [117.3897642, 6.7303927],
          [117.3894904, 6.7306047],
          [117.3892872, 6.7313642],
          [117.3889163, 6.7318765],
          [117.3884658, 6.7322298],
          [117.3877593, 6.7323976],
          [117.387353, 6.732168],
          [117.3869555, 6.732115],
          [117.3866906, 6.7325743],
          [117.3887536, 6.7387541],
          [117.3891414, 6.739424],
          [117.3895097, 6.7402834],
          [117.3899168, 6.7409877],
          [117.3903626, 6.7416468],
          [117.3906986, 6.7420021],
          [117.3915709, 6.7425514],
          [117.3927275, 6.743288],
          [117.3951893, 6.7443606],
          [117.3968887, 6.744871],
          [117.3980452, 6.7452716],
          [117.4004558, 6.7472833],
          [117.4015413, 6.7482913],
          [117.4037124, 6.7492218],
          [117.4061419, 6.7502556],
          [117.4077831, 6.7513799],
          [117.4100058, 6.7523103],
          [117.4125534, 6.7531115],
          [117.4132204, 6.7532988],
          [117.4151348, 6.7538882],
          [117.4160943, 6.7540984],
          [117.4169487, 6.754071],
          [117.4174375, 6.754039],
          [117.4178259, 6.7538928],
          [117.4181531, 6.7536293],
          [117.418712, 6.753652],
          [117.418922, 6.7536164],
          [117.4191837, 6.753513],
          [117.4191805, 6.7533483],
          [117.4191837, 6.7532578],
          [117.4193614, 6.7530381],
          [117.419804, 6.7527861],
          [117.4199946, 6.7527118],
          [117.4203564, 6.7525406],
          [117.4207861, 6.7522563],
          [117.4212352, 6.7519558],
          [117.421597, 6.7516974],
          [117.4220461, 6.7514745],
          [117.4224015, 6.7511869],
          [117.4230056, 6.7507443],
          [117.4233449, 6.7505699],
          [117.4238488, 6.7503792],
          [117.4240976, 6.7502791],
          [117.424201, 6.7500239],
          [117.4245434, 6.74983],
          [117.4243819, 6.7494908],
          [117.4246048, 6.7494553],
          [117.4249247, 6.7494617],
          [117.4250894, 6.7495296],
          [117.4256128, 6.749452],
          [117.4260606, 6.7493698],
          [117.4271388, 6.7498815],
          [117.4274495, 6.7500186],
          [117.4289764, 6.7498773],
          [117.4291153, 6.7499936],
          [117.4293253, 6.7502003],
          [117.429406, 6.7503296],
          [117.4301717, 6.7504265],
          [117.4306272, 6.750504],
          [117.4310653, 6.7500364],
          [117.4319243, 6.7494881],
          [117.433761, 6.7484373],
          [117.4340808, 6.7486292],
          [117.4344646, 6.7487023],
          [117.4347022, 6.7483002],
          [117.4353053, 6.7483276],
          [117.4356251, 6.7480078],
          [117.4364841, 6.747889],
          [117.4376354, 6.7477794],
          [117.4383, 6.7477275],
          [117.4398194, 6.7475783],
          [117.4404499, 6.7473864],
          [117.4410164, 6.7476149],
          [117.4424511, 6.747094],
          [117.4432186, 6.7469204],
          [117.4441781, 6.7470483],
          [117.4452838, 6.7471306],
          [117.4455853, 6.7468747],
          [117.4455671, 6.7464818],
          [117.4456767, 6.7463356],
          [117.4459874, 6.7462899],
          [117.4460422, 6.7464818],
          [117.4462158, 6.7464909],
          [117.4462889, 6.7463539],
          [117.4471388, 6.7462625],
          [117.4476048, 6.7468108],
          [117.4479612, 6.7472859],
          [117.4490486, 6.7475692],
          [117.4499258, 6.7474504],
          [117.4504832, 6.7470209],
          [117.450602, 6.7474139],
          [117.4518082, 6.7477337],
          [117.4521006, 6.7479895],
          [117.4524935, 6.7479164],
          [117.4525361, 6.7477355],
          [117.4525974, 6.7474545],
          [117.4526265, 6.7472897],
          [117.4527622, 6.7473091],
          [117.4527751, 6.7473123],
          [117.4528139, 6.7473963],
          [117.4527525, 6.7476548],
          [117.452717, 6.7478357],
          [117.4527008, 6.7479617],
          [117.4527945, 6.7480715],
          [117.4531208, 6.7482104],
          [117.4533922, 6.7483267],
          [117.4537476, 6.7483817],
          [117.4539543, 6.7484334],
          [117.4542871, 6.7484301],
          [117.454384, 6.7485497],
          [117.4546037, 6.7485432],
          [117.4547523, 6.7484237],
          [117.4548525, 6.7485367],
          [117.4548266, 6.7486466],
          [117.4554049, 6.7488081],
          [117.4560478, 6.7489115],
          [117.4574258, 6.7495083],
          [117.4575263, 6.7496363],
          [117.4582299, 6.7498007],
          [117.4587051, 6.7496271],
          [117.4590889, 6.7493347],
          [117.4593721, 6.7492251],
          [117.4593447, 6.7495814],
          [117.4591711, 6.7497459],
          [117.4588513, 6.7501388],
          [117.459299, 6.7505683],
          [117.4599387, 6.7510618],
          [117.4621774, 6.7515278],
          [117.4633197, 6.7517197],
          [117.4637766, 6.7518568],
          [117.4643431, 6.752268],
          [117.4651198, 6.752999],
          [117.4654397, 6.7535929],
          [117.4650285, 6.7539859],
          [117.4651107, 6.7547443],
          [117.4656681, 6.7552012],
          [117.4659422, 6.7555941],
          [117.4668926, 6.7560053],
          [117.4681993, 6.7564074],
          [117.468382, 6.7566176],
          [117.4688663, 6.7569008],
          [117.4711599, 6.7574674],
          [117.4717722, 6.7575953],
          [117.4721468, 6.7574034],
          [117.4726951, 6.7560327],
          [117.4738465, 6.7551555],
          [117.4744039, 6.7550276],
          [117.4747694, 6.754854],
          [117.4748882, 6.754196],
          [117.4748699, 6.7537483],
          [117.4751714, 6.7532],
          [117.4750683, 6.7528796],
          [117.4754625, 6.7522076],
          [117.4755465, 6.7518135],
          [117.4755497, 6.7512191],
          [117.4755756, 6.7508249],
          [117.475834, 6.7504405],
          [117.4758986, 6.7502434],
          [117.4757952, 6.7499882],
          [117.4758728, 6.7496877],
          [117.4758534, 6.7493711],
          [117.4759374, 6.7492031],
          [117.4762669, 6.7489608],
          [117.4760731, 6.7487023],
          [117.4763219, 6.7484826],
          [117.4761345, 6.7480562],
          [117.4747388, 6.7472342],
          [117.474483, 6.7465946],
          [117.4745195, 6.7458087],
          [117.4744997, 6.745459],
          [117.4746824, 6.7453219],
          [117.4748652, 6.7450752],
          [117.4748972, 6.7446092],
          [117.4749748, 6.7439741],
          [117.4748972, 6.7436999],
          [117.4749337, 6.7432613],
          [117.4753084, 6.7428501],
          [117.475482, 6.7423521],
          [117.4758612, 6.7418952],
          [117.4761719, 6.7416942],
          [117.4776124, 6.7385006],
          [117.4812414, 6.7323282],
          [117.4815515, 6.7293302],
          [117.4841355, 6.7284424],
          [117.4852733, 6.7269523],
          [117.4896154, 6.7223001],
          [117.4914763, 6.7187851],
          [117.4928202, 6.7148566],
          [117.4925101, 6.7118585],
          [117.4967488, 6.7077232],
          [117.5004706, 6.7028642],
          [117.5022281, 6.6997627],
          [117.5102281, 6.6948569],
          [117.5098784, 6.688494],
          [117.5093615, 6.6831181],
          [117.5102919, 6.6778456],
          [117.5101885, 6.6748475],
          [117.5129746, 6.6730336],
          [117.509775, 6.6677141],
          [117.50626, 6.6630618],
          [117.504089, 6.6597536],
          [117.5023314, 6.6573758],
          [117.5006773, 6.6551014],
          [117.4968522, 6.6539642],
          [117.4947845, 6.6535506],
          [117.4942635, 6.6520618],
          [117.4918898, 6.6507593],
          [117.489512, 6.6464172],
          [117.4858936, 6.6468308],
          [117.4804056, 6.6429173],
          [117.4788362, 6.643117],
          [117.4758838, 6.643737],
          [117.4748106, 6.6442393],
          [117.474071, 6.6442341],
          [117.4728874, 6.6439919],
          [117.471912, 6.643585],
          [117.4708811, 6.6426152],
          [117.4703182, 6.6410906],
          [117.4702717, 6.6403831],
          [117.4696677, 6.6405557],
          [117.4690764, 6.6405999],
          [117.468948, 6.6411516],
          [117.4689065, 6.64133],
          [117.4678975, 6.6409969],
          [117.4666825, 6.6408543],
          [117.4661974, 6.6406699],
          [117.4661821, 6.6405241],
          [117.4661702, 6.6404103],
          [117.4659425, 6.6399825],
          [117.465361, 6.640241],
          [117.4641204, 6.6404607],
          [117.4632804, 6.6400342],
          [117.4617038, 6.6399825],
          [117.4605795, 6.6400988],
          [117.4596749, 6.6402927],
          [117.4592205, 6.6403158],
          [117.4589677, 6.6402006],
          [117.4587101, 6.6399203],
          [117.4582117, 6.6395786],
          [117.4573747, 6.6394139],
          [117.4559144, 6.6392201],
          [117.4549683, 6.6390706],
          [117.4537617, 6.6392118],
          [117.4525555, 6.6391205],
          [117.4516858, 6.6388046],
          [117.4502534, 6.6380366],
          [117.4487517, 6.6366938],
          [117.4475537, 6.6356082],
          [117.4469412, 6.6354167],
          [117.4465428, 6.6358674],
          [117.4462864, 6.6358124],
          [117.4460311, 6.6357577],
          [117.4455801, 6.635288],
          [117.4446835, 6.6342312],
          [117.4438458, 6.6333686],
          [117.4430706, 6.6330838],
          [117.4424241, 6.6328521],
          [117.4420252, 6.6326717],
          [117.4409322, 6.6318833],
          [117.4391646, 6.6309729],
          [117.4376608, 6.6293795],
          [117.4359039, 6.6276375],
          [117.4350268, 6.62676],
          [117.4340148, 6.6263054],
          [117.4328178, 6.6249568],
          [117.431173, 6.6239151],
          [117.4298445, 6.6236031],
          [117.4285541, 6.6237758],
          [117.4266419, 6.6235974],
          [117.4258514, 6.6236467],
          [117.4251237, 6.62344],
          [117.4243667, 6.6237475],
          [117.4241595, 6.6239291],
          [117.4241719, 6.6242235],
          [117.4239444, 6.6242397],
          [117.4237526, 6.6242537],
          [117.4234606, 6.6240935],
          [117.4229032, 6.6239132],
          [117.4213407, 6.6238237],
          [117.4186724, 6.6242624],
          [117.4177223, 6.624486],
          [117.4173248, 6.624558],
          [117.4169155, 6.6246322],
          [117.4154559, 6.6254137],
          [117.4141949, 6.6260534],
          [117.4119652, 6.6252492],
          [117.4106475, 6.6246655],
          [117.4103656, 6.6247036],
          [117.4099682, 6.624735],
          [117.4096322, 6.6247215],
          [117.4092266, 6.624163],
          [117.4086006, 6.6237289],
          [117.4069832, 6.623103],
          [117.4053019, 6.6221069],
          [117.4033098, 6.6212891],
          [117.4018249, 6.620887],
          [117.4010574, 6.6205033],
          [117.400171, 6.6197311],
          [117.3992024, 6.6189909],
          [117.3980329, 6.6191396],
          [117.3972575, 6.6196823],
          [117.3964304, 6.620406],
          [117.3959264, 6.6214527],
          [117.3961332, 6.6217241],
          [117.3965855, 6.6215949],
          [117.3970627, 6.6219211],
          [117.3969603, 6.6221506],
          [117.3959652, 6.622241],
          [117.3949701, 6.6231973],
          [117.394828, 6.6240244],
          [117.3937812, 6.6251616],
          [117.3923985, 6.6259628],
          [117.3902016, 6.6266606],
          [117.3892996, 6.6271735],
          [117.3885008, 6.6277418],
          [117.3885043, 6.6284502],
          [117.3877171, 6.6287694],
          [117.386958, 6.6290772],
          [117.3864411, 6.6295554],
          [117.3857949, 6.6300206],
          [117.3846706, 6.6303695],
          [117.3834171, 6.6312999],
          [117.3817889, 6.632463],
          [117.3803544, 6.6330833],
          [117.3789329, 6.6333676],
          [117.3782738, 6.6333934],
          [117.3774174, 6.6334221],
          [117.3752228, 6.6340217],
          [117.3733562, 6.6342706],
          [117.3714444, 6.6346778],
          [117.3699172, 6.635119],
          [117.3682995, 6.6358996],
          [117.3670325, 6.6361711],
          [117.3666139, 6.6359109],
          [117.3652564, 6.6359222],
          [117.364917, 6.6360353],
          [117.3647926, 6.635843],
          [117.3641365, 6.6354471],
          [117.3632428, 6.6353227],
          [117.3621681, 6.6358091],
          [117.3611839, 6.6358543],
          [117.3602902, 6.6357525],
          [117.3598943, 6.6357186],
          [117.3594644, 6.6354584],
          [117.359125, 6.6350285],
          [117.3587065, 6.6351869],
          [117.3585933, 6.6351643],
          [117.3582653, 6.6350172],
          [117.3577336, 6.635334],
          [117.3566815, 6.6353566],
          [117.3560141, 6.6354923],
          [117.3549959, 6.6359109],
          [117.3541701, 6.6362616],
          [117.3532425, 6.6364652],
          [117.3527447, 6.6363747],
          [117.3524619, 6.6364087],
          [117.3522017, 6.6364765],
          [117.352213, 6.6360919],
          [117.3502673, 6.6361711],
          [117.3493623, 6.6363408],
          [117.3490003, 6.6365783],
          [117.34805, 6.6366575],
          [117.3464776, 6.6368159],
          [117.3452219, 6.6370535],
          [117.3447349, 6.6381138],
          [117.3447096, 6.6385401],
          [117.344519, 6.6387539],
          [117.3443326, 6.6388688],
          [117.3431404, 6.6397685],
          [117.3421788, 6.6406735],
          [117.3400973, 6.6430152],
          [117.3388868, 6.6440446],
          [117.3388755, 6.6446781],
          [117.3385732, 6.6448644],
          [117.338347, 6.6449829],
          [117.3379505, 6.6451459],
          [117.3373825, 6.6457578],
          [117.3371266, 6.6464577],
          [117.3365826, 6.6472297],
          [117.3362827, 6.6475936],
          [117.3358507, 6.6478376],
          [117.3354707, 6.6479616],
          [117.3350708, 6.6483335],
          [117.3343948, 6.6487775],
          [117.3338669, 6.6494174],
          [117.3332101, 6.6500471],
          [117.3327903, 6.6504169],
          [117.332221, 6.6505862],
          [117.3316509, 6.6507558],
          [117.3311717, 6.6510421],
          [117.3306882, 6.6515023],
          [117.3305933, 6.6517068],
          [117.330491, 6.6519931],
          [117.3303274, 6.6522794],
          [117.3302587, 6.6523978],
          [117.3299651, 6.6526023],
          [117.3299125, 6.6527703],
          [117.3298138, 6.6529532],
          [117.3294861, 6.6532184],
          [117.3292864, 6.6534098],
          [117.328962, 6.6534449],
          [117.3285241, 6.6536494],
          [117.3281811, 6.6538808],
          [117.3280076, 6.6539759],
          [117.3278465, 6.6543787],
          [117.3276643, 6.6545457],
          [117.3274598, 6.654721],
          [117.3274014, 6.6548802],
          [117.3273123, 6.6550906],
          [117.3274043, 6.6552323],
          [117.3271779, 6.6556354],
          [117.3271019, 6.6558516],
          [117.3271413, 6.6559846],
          [117.3270332, 6.6561394],
          [117.3270391, 6.656417],
          [117.3269967, 6.6566405],
          [117.3268667, 6.6567983],
          [117.3267411, 6.6568581],
          [117.3265672, 6.6570933],
          [117.3260954, 6.6572847],
          [117.325736, 6.657533],
          [117.3254453, 6.6578091],
          [117.3252715, 6.6583044],
          [117.3252496, 6.6588361],
          [117.3253475, 6.65908],
          [117.3256192, 6.6594175],
          [117.3259581, 6.6597038],
          [117.3260194, 6.659828],
          [117.3258865, 6.6599229],
          [117.3258178, 6.6601012],
          [117.325812, 6.6602998],
          [117.3257448, 6.6604751],
          [117.3257813, 6.6607278],
          [117.3257565, 6.6608535],
          [117.3257594, 6.6611383],
          [117.3257448, 6.6614495],
          [117.3256688, 6.6617811],
          [117.3255432, 6.6620733],
          [117.3253957, 6.6625144],
          [117.3252759, 6.6628504],
          [117.3252496, 6.6631513],
          [117.3253548, 6.6634231],
          [117.3253811, 6.6637342],
          [117.3254278, 6.663965],
          [117.3255271, 6.6643098],
          [117.3256484, 6.6646136],
          [117.3256747, 6.6650124],
          [117.3258281, 6.6653236],
          [117.3260793, 6.665531],
          [117.3261202, 6.6657122],
          [117.3260589, 6.6659079],
          [117.326183, 6.6662527],
          [117.3263174, 6.6663914],
          [117.3265, 6.6664528],
          [117.3266476, 6.666688],
          [117.3268988, 6.6668808],
          [117.3270783, 6.6671654],
          [117.3270949, 6.6673781],
          [117.3272805, 6.667597],
          [117.3275084, 6.6678022],
          [117.3277438, 6.6679607],
          [117.327854, 6.6681402],
          [117.3279974, 6.6683817],
          [117.3282132, 6.6686051],
          [117.3282056, 6.6688782],
          [117.3284335, 6.6691076],
          [117.328601, 6.6694064],
          [117.3287942, 6.6696871],
          [117.3290236, 6.6699949],
          [117.3292362, 6.6702564],
          [117.3291978, 6.6705851],
          [117.3297697, 6.6712083],
          [117.3303844, 6.6720065],
          [117.3308454, 6.6722754],
          [117.3308326, 6.6726041],
          [117.3313064, 6.6727791],
          [117.3316649, 6.6730779],
          [117.3318612, 6.6734065],
          [117.3324759, 6.6737523],
          [117.3328174, 6.6740468],
          [117.3335217, 6.6745078],
          [117.3340509, 6.6750029],
          [117.3346528, 6.6752633],
          [117.3351863, 6.6755023],
          [117.3353229, 6.6754895],
          [117.3354296, 6.6755108],
          [117.3356601, 6.6756901],
          [117.3368766, 6.6762365],
          [117.3376705, 6.6767017],
          [117.3384175, 6.6770133],
          [117.3394974, 6.6772865],
          [117.3403639, 6.6776578],
          [117.3407182, 6.6778499],
          [117.3414993, 6.6779011],
          [117.3423017, 6.6779225],
          [117.3432195, 6.6777176],
          [117.3438725, 6.6778072],
          [117.3450634, 6.6779865],
          [117.3457762, 6.6780121],
          [117.3462201, 6.678089],
          [117.3465531, 6.6784432],
          [117.3474366, 6.6788103],
          [117.3484056, 6.6790621],
          [117.3486659, 6.6791219],
          [117.3490202, 6.6790792],
          [117.349541, 6.6790878],
          [117.3501257, 6.6791262],
          [117.3504373, 6.6794122],
          [117.3509026, 6.6795402],
          [117.3514276, 6.6793951],
          [117.3516217, 6.679537],
          [117.351812, 6.6796595],
          [117.3517896, 6.6798456],
          [117.3517724, 6.6799893],
          [117.3517916, 6.6801878],
          [117.3519795, 6.6803286],
          [117.3521075, 6.6805356],
          [117.3522718, 6.6805527],
          [117.3524276, 6.6806615],
          [117.3524618, 6.6809112],
          [117.3527683, 6.6812267],
          [117.3531064, 6.6814561],
          [117.3534142, 6.6816614],
          [117.3539153, 6.6816976],
          [117.3542231, 6.6817579],
          [117.3544102, 6.6820356],
          [117.3547121, 6.6822469],
          [117.3551105, 6.6822046],
          [117.3554546, 6.6821684],
          [117.3557443, 6.6823374],
          [117.3562514, 6.6825125],
          [117.3569757, 6.6823978],
          [117.3574466, 6.6821141],
          [117.3577725, 6.6816191],
          [117.3584848, 6.681269],
          [117.3591006, 6.6812448],
          [117.3595895, 6.6812328],
          [117.3598551, 6.6809249],
          [117.360338, 6.6807619],
          [117.3607968, 6.6807378],
          [117.3608873, 6.6805205],
          [117.361165, 6.6804601],
          [117.3612133, 6.6806231],
          [117.3611952, 6.6808706],
          [117.3616359, 6.6809732],
          [117.3621007, 6.6812207],
          [117.3626983, 6.6813958],
          [117.3634709, 6.6818002],
          [117.3640444, 6.6818907],
          [117.3647386, 6.6819149],
          [117.3657165, 6.6819934],
          [117.3658855, 6.6821805],
          [117.3668332, 6.6820115],
          [117.3676542, 6.6818968],
          [117.3688735, 6.6819511],
          [117.3703042, 6.6814501],
          [117.3716041, 6.680788],
          [117.3722862, 6.6801421],
          [117.3724909, 6.6800742],
          [117.3726377, 6.6800249],
          [117.3727086, 6.680126],
          [117.3729063, 6.6802663],
          [117.3731025, 6.6803705],
          [117.373362, 6.6805063],
          [117.3735311, 6.6805591],
          [117.3737031, 6.6804022],
          [117.373851, 6.6802905],
          [117.3738857, 6.6804203],
          [117.37394, 6.6804776],
          [117.3740351, 6.6804927],
          [117.3742871, 6.6804233],
          [117.3747142, 6.6804233],
          [117.3749285, 6.6805063],
          [117.3750085, 6.6805274],
          [117.3756203, 6.6807856],
          [117.3763075, 6.6808369],
          [117.3768752, 6.6808753],
          [117.3774222, 6.680629],
          [117.3778991, 6.6804661],
          [117.3786536, 6.680454],
          [117.3796858, 6.6805626],
          [117.3801929, 6.6805989],
          [117.3809957, 6.6805808],
          [117.3819012, 6.6804238],
          [117.3823781, 6.6798685],
          [117.3828491, 6.6799029],
          [117.3830393, 6.6800176],
          [117.383151, 6.6799331],
          [117.3832445, 6.6797279],
          [117.3833622, 6.6797641],
          [117.3833743, 6.6799542],
          [117.3836641, 6.6799723],
          [117.3841711, 6.6798425],
          [117.3845092, 6.6796403],
          [117.3847084, 6.679429],
          [117.384983, 6.6789824],
          [117.3852607, 6.6787681],
          [117.3855543, 6.6787027],
          [117.3857645, 6.6786901],
          [117.3859595, 6.6786784],
          [117.3869553, 6.6788054],
          [117.3888334, 6.6778493],
          [117.3920774, 6.6766542],
          [117.3950482, 6.6753907],
          [117.3957752, 6.6754394],
          [117.3967048, 6.6750168],
          [117.3971957, 6.6748962],
          [117.3976948, 6.6747735],
          [117.3984034, 6.6746796],
          [117.3991162, 6.6743936],
          [117.4003882, 6.6740949],
          [117.4013358, 6.6738174],
          [117.4024925, 6.6736125],
          [117.4031626, 6.673207],
          [117.4036663, 6.6732753],
          [117.4047249, 6.6730619],
          [117.4060139, 6.6729552],
          [117.4069274, 6.6728826],
          [117.4069359, 6.6725369],
          [117.407303, 6.6725668],
          [117.4077981, 6.6730662],
          [117.4080286, 6.6734461],
          [117.4086646, 6.6737705],
          [117.4095951, 6.6743638],
          [117.4104829, 6.6748546],
          [117.4109311, 6.674893],
          [117.4113409, 6.6749016],
          [117.4115799, 6.6748845],
          [117.4121049, 6.6746711],
          [117.4125275, 6.6749998],
          [117.412564, 6.675356],
          [117.4126016, 6.6757223],
          [117.4129699, 6.675849],
          [117.4131872, 6.6764708],
          [117.4136037, 6.6769899],
          [117.4142677, 6.6776298],
          [117.4160001, 6.678819],
          [117.4188342, 6.6800958],
          [117.4202827, 6.6805021],
          [117.4216252, 6.6804491],
          [117.4230913, 6.6803431],
          [117.4232151, 6.6806273],
          [117.4233337, 6.6808995],
          [117.4230479, 6.6812883],
          [117.4229511, 6.6814913],
          [117.4227919, 6.681663],
          [117.4226201, 6.6819831],
          [117.4225093, 6.6822141],
          [117.4224812, 6.6824343],
          [117.4224203, 6.6826701],
          [117.4223469, 6.6829105],
          [117.4223688, 6.6830713],
          [117.4225249, 6.6830994],
          [117.4225108, 6.6832493],
          [117.422578, 6.6834523],
          [117.422756, 6.6837239],
          [117.4229371, 6.6839769],
          [117.4228996, 6.6841564],
          [117.4229433, 6.684261],
          [117.4231182, 6.6842267],
          [117.4233383, 6.6844281],
          [117.4233711, 6.6845764],
          [117.4236522, 6.6847013],
          [117.4238411, 6.6848356],
          [117.4239113, 6.6848559],
          [117.4240503, 6.6849574],
          [117.4241752, 6.6850745],
          [117.4243673, 6.6851463],
          [117.42445, 6.6852681],
          [117.424717, 6.6854211],
          [117.4248232, 6.6855975],
          [117.4248013, 6.6857708],
          [117.4248435, 6.6859363],
          [117.4250121, 6.6859926],
          [117.4251417, 6.6861018],
          [117.425393, 6.6861221],
          [117.425468, 6.6862189],
          [117.4257381, 6.6862673],
          [117.4259848, 6.6863392],
          [117.4262939, 6.6863485],
          [117.4263985, 6.6864547],
          [117.426678, 6.6865578],
          [117.427034, 6.686717],
          [117.4274946, 6.6869356],
          [117.4278631, 6.687098],
          [117.4279505, 6.6872728],
          [117.4283096, 6.6875195],
          [117.4286609, 6.6876897],
          [117.42902, 6.6876429],
          [117.4294962, 6.6875492],
          [117.4297975, 6.6874945],
          [117.4302077, 6.6876661],
          [117.4305619, 6.6877833],
          [117.4306193, 6.6881167],
          [117.4305664, 6.6884302],
          [117.430637, 6.688598],
          [117.4308092, 6.6890198],
          [117.4309594, 6.6892582],
          [117.4311228, 6.6895431],
          [117.431836, 6.6898699],
          [117.4323284, 6.6899869],
          [117.4327258, 6.6900841],
          [117.4331476, 6.6901459],
          [117.4338917, 6.6901768],
          [117.4344349, 6.6900752],
          [117.4350502, 6.6899978],
          [117.4353375, 6.6901727],
          [117.4358411, 6.6910145],
          [117.4362412, 6.6917328],
          [117.4364981, 6.6924016],
        ],
      ],
    ],
  },
};
