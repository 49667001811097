export const penang = {
  type: "Feature",
  properties: {
    gid: 9,
    id: 4445131,
    country: "MYS",
    name: "Penang",
    enname: "Penang",
    locname: "Pulau Pinang",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q188096",
    wikimedia: "en:Penang",
    timestamp: "2020-01-01 22:53:02",
    note: null,
    path: "0,2108121,4445131",
    rpath: "4445131,2108121,0",
    iso3166_2: "MY-07",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [100.3258313, 5.3607859],
          [100.3257851, 5.3608817],
          [100.3258133, 5.3608992],
          [100.3258656, 5.3609045],
          [100.3259568, 5.3608818],
          [100.3260304, 5.3608685],
          [100.3261377, 5.3608071],
          [100.326261, 5.3607644],
          [100.3263601, 5.3606899],
          [100.3264191, 5.3606365],
          [100.3264821, 5.3605446],
          [100.3265414, 5.3604953],
          [100.3264931, 5.3604152],
          [100.326418, 5.3603671],
          [100.3263268, 5.3603511],
          [100.3262201, 5.3603831],
          [100.3261173, 5.3604868],
          [100.3259445, 5.3606348],
          [100.3258313, 5.3607859],
        ],
      ],
      [
        [
          [100.3252119, 5.3641419],
          [100.3251743, 5.3643021],
          [100.3252387, 5.364481],
          [100.3260124, 5.3645909],
          [100.3264832, 5.3645451],
          [100.3267065, 5.3644157],
          [100.3269258, 5.3638455],
          [100.3269372, 5.3633262],
          [100.3266314, 5.3628241],
          [100.3263089, 5.3626758],
          [100.3259575, 5.3629028],
          [100.3255632, 5.3634235],
          [100.3254721, 5.3636238],
          [100.3254609, 5.3638987],
          [100.3252119, 5.3641419],
        ],
      ],
      [
        [
          [100.3601812, 5.4121836],
          [100.360056, 5.4121839],
          [100.3605346, 5.4187686],
          [100.3609983, 5.425721],
          [100.3626467, 5.4256062],
          [100.3622518, 5.4196002],
          [100.362718, 5.419582],
          [100.3626373, 5.4186956],
          [100.3621765, 5.4187336],
          [100.362172, 5.418653],
          [100.3610027, 5.4187219],
          [100.3606835, 5.4137966],
          [100.3607506, 5.4137899],
          [100.3607444, 5.4135911],
          [100.3607341, 5.4134479],
          [100.3607224, 5.4132025],
          [100.3606245, 5.4132011],
          [100.360568, 5.4121591],
          [100.3601812, 5.4121836],
        ],
      ],
      [
        [
          [100.3194178, 5.4426725],
          [100.3175081, 5.444125],
          [100.3163708, 5.446197],
          [100.3162376, 5.4483161],
          [100.3163778, 5.4488978],
          [100.3171326, 5.4525988],
          [100.317534915057138, 5.455156765231036],
          [100.3187772, 5.4553961],
          [100.3190703, 5.4555842],
          [100.3194315, 5.4555928],
          [100.3197926, 5.4553665],
          [100.3196052, 5.4562522],
          [100.3198041, 5.4564971],
          [100.3260053, 5.4413694],
          [100.3248895, 5.4406218],
          [100.3220142, 5.4415403],
          [100.3194178, 5.4426725],
        ],
      ],
      [
        [
          [100.2969089, 5.4759499],
          [100.2968913, 5.476077],
          [100.2970679, 5.4760947],
          [100.2971423, 5.4761725],
          [100.2972076, 5.4763087],
          [100.2971636, 5.4764676],
          [100.2970848, 5.4766438],
          [100.2971694, 5.4768089],
          [100.297315, 5.4768662],
          [100.2972202, 5.4769066],
          [100.2971288, 5.4770717],
          [100.2971918, 5.4771887],
          [100.2976927, 5.4771072],
          [100.2977278, 5.4769335],
          [100.2978101, 5.4767684],
          [100.297826, 5.4766135],
          [100.2977684, 5.4764619],
          [100.2978062, 5.476281],
          [100.2976331, 5.4761756],
          [100.2975676, 5.4759792],
          [100.2976206, 5.4756812],
          [100.2974952, 5.4753892],
          [100.2973082, 5.4753166],
          [100.2973048, 5.4754783],
          [100.2971525, 5.4755928],
          [100.2970307, 5.4757478],
          [100.2970666, 5.4758563],
          [100.2969089, 5.4759499],
        ],
      ],
      [
        [
          [100.1766842, 5.2250933],
          [100.1763782, 5.225488],
          [100.176658, 5.2257734],
          [100.1768377, 5.2258264],
          [100.1769508, 5.2262108],
          [100.177366, 5.226677],
          [100.177201, 5.2269421],
          [100.1773895, 5.2271943],
          [100.1773833, 5.2276555],
          [100.1776229, 5.2279736],
          [100.177563, 5.2282983],
          [100.1777304, 5.2290264],
          [100.177912, 5.2295558],
          [100.1784055, 5.2299979],
          [100.1784316, 5.2302821],
          [100.1779556, 5.2305872],
          [100.177956, 5.230845],
          [100.1778739, 5.2311149],
          [100.1776298, 5.2313357],
          [100.1772862, 5.2312564],
          [100.17686, 5.2313262],
          [100.1766714, 5.2313628],
          [100.1764153, 5.2318788],
          [100.1764036, 5.2325372],
          [100.1767125, 5.2327103],
          [100.1768168, 5.2330329],
          [100.177209, 5.233279],
          [100.1777043, 5.2336809],
          [100.1779622, 5.2341959],
          [100.1782198, 5.2344532],
          [100.1783366, 5.2348626],
          [100.1786348, 5.2353722],
          [100.1790192, 5.2354887],
          [100.1792177, 5.2357616],
          [100.1804092, 5.2361241],
          [100.1810735, 5.2362438],
          [100.1814143, 5.236059],
          [100.1816598, 5.2360311],
          [100.1817266, 5.2359104],
          [100.1814135, 5.2357966],
          [100.1812897, 5.2351573],
          [100.1808904, 5.2348591],
          [100.1809794, 5.2345194],
          [100.1810053, 5.2341049],
          [100.1807573, 5.2333415],
          [100.1808235, 5.2330674],
          [100.1807232, 5.2324396],
          [100.1801717, 5.2317444],
          [100.1796909, 5.2315021],
          [100.1794197, 5.231254],
          [100.1796079, 5.2310605],
          [100.1792848, 5.2305517],
          [100.1792403, 5.2300691],
          [100.1794963, 5.2295531],
          [100.179496, 5.2292954],
          [100.1792001, 5.2290538],
          [100.1793465, 5.2285634],
          [100.1791705, 5.228029],
          [100.1792753, 5.2276128],
          [100.1790229, 5.2272245],
          [100.1788922, 5.2273485],
          [100.1783427, 5.2271791],
          [100.1780501, 5.2273329],
          [100.177743, 5.2271035],
          [100.1776208, 5.226437],
          [100.1773355, 5.2256909],
          [100.1770373, 5.2253824],
          [100.1770393, 5.2251204],
          [100.1766842, 5.2250933],
        ],
      ],
      [
        [
          [100.2687186, 5.2480131],
          [100.2686401, 5.2482632],
          [100.2688308, 5.2484816],
          [100.2690884, 5.2487388],
          [100.2696151, 5.2490499],
          [100.2701218, 5.2492096],
          [100.2701893, 5.2494432],
          [100.2702897, 5.2497252],
          [100.2706466, 5.2497885],
          [100.2711472, 5.2496373],
          [100.2713816, 5.2494182],
          [100.2714863, 5.2491043],
          [100.2716695, 5.2487902],
          [100.2717844, 5.2484421],
          [100.2719419, 5.2482391],
          [100.2724259, 5.2480314],
          [100.2728666, 5.2481337],
          [100.2732015, 5.2482151],
          [100.2734591, 5.2484723],
          [100.2737169, 5.2487296],
          [100.2739745, 5.2489868],
          [100.2742321, 5.249244],
          [100.2750068, 5.2498874],
          [100.2752737, 5.2499143],
          [100.2755194, 5.2500146],
          [100.2757065, 5.2502231],
          [100.2762797, 5.2503082],
          [100.2764857, 5.2503868],
          [100.2767025, 5.2499648],
          [100.2767736, 5.2498347],
          [100.2768041, 5.2496033],
          [100.2764855, 5.2495391],
          [100.2762893, 5.2493691],
          [100.2761879, 5.2492727],
          [100.2760562, 5.2490784],
          [100.2759878, 5.2485681],
          [100.2760826, 5.2481205],
          [100.2762715, 5.2482039],
          [100.2763343, 5.2480169],
          [100.2760673, 5.2476273],
          [100.2760618, 5.2469909],
          [100.2762272, 5.2460523],
          [100.2761406, 5.2458241],
          [100.2762141, 5.2456654],
          [100.2764456, 5.2457903],
          [100.2765628, 5.2457061],
          [100.2765688, 5.2453231],
          [100.2764681, 5.2450511],
          [100.276151, 5.2447296],
          [100.2758928, 5.2442147],
          [100.2757643, 5.2440856],
          [100.275635, 5.2439573],
          [100.2751489, 5.2437922],
          [100.2749924, 5.2438294],
          [100.2748637, 5.243959],
          [100.274736, 5.2440877],
          [100.2747293, 5.2443949],
          [100.2744401, 5.2451739],
          [100.2743533, 5.2452066],
          [100.2743812, 5.2453555],
          [100.2739267, 5.2455546],
          [100.2736201, 5.2459211],
          [100.2734863, 5.2460935],
          [100.2730198, 5.246263],
          [100.2727031, 5.2461761],
          [100.2724254, 5.2458966],
          [100.2722962, 5.2457685],
          [100.2721678, 5.2456394],
          [100.271653, 5.2453827],
          [100.2715237, 5.2452546],
          [100.2713954, 5.2451255],
          [100.2706233, 5.2448694],
          [100.2701092, 5.2448704],
          [100.2699803, 5.245],
          [100.2698526, 5.2451287],
          [100.2697239, 5.2452582],
          [100.2697254, 5.2460316],
          [100.2697259, 5.2462894],
          [100.269598, 5.2464181],
          [100.2694693, 5.2465476],
          [100.2693224, 5.2471422],
          [100.2691148, 5.2475374],
          [100.2690292, 5.2478413],
          [100.2687186, 5.2480131],
        ],
      ],
      [
        [
          [100.3841205, 5.2573016],
          [100.3840011, 5.2575677],
          [100.3840011, 5.2582301],
          [100.3841375, 5.2586491],
          [100.3846265, 5.2591813],
          [100.3849392, 5.259906],
          [100.38478, 5.260625],
          [100.3844332, 5.2607778],
          [100.384291, 5.2611119],
          [100.3843479, 5.2614799],
          [100.3843479, 5.261763],
          [100.3847004, 5.2620517],
          [100.3849847, 5.2620517],
          [100.3851439, 5.2622442],
          [100.3852405, 5.2626858],
          [100.3853713, 5.2630255],
          [100.3856556, 5.2633086],
          [100.3861388, 5.2633255],
          [100.38648, 5.2636822],
          [100.3867529, 5.2638974],
          [100.3876171, 5.2642257],
          [100.3878729, 5.2646787],
          [100.3881913, 5.2648259],
          [100.388305, 5.2654033],
          [100.3885438, 5.2656581],
          [100.3885609, 5.266196],
          [100.388612, 5.2664168],
          [100.3888167, 5.2665809],
          [100.3890953, 5.2671414],
          [100.3891294, 5.2673566],
          [100.3892377, 5.2678225],
          [100.3892297, 5.2681668],
          [100.3891412, 5.2687032],
          [100.3891653, 5.2689434],
          [100.3889724, 5.2692957],
          [100.3887472, 5.2694318],
          [100.3884497, 5.2694398],
          [100.3884578, 5.2694959],
          [100.3889322, 5.2694638],
          [100.3892699, 5.2693998],
          [100.3904679, 5.2691836],
          [100.3907172, 5.2692397],
          [100.3909262, 5.2688794],
          [100.3913845, 5.2687032],
          [100.3916499, 5.268423],
          [100.3921323, 5.2673981],
          [100.3922368, 5.2669498],
          [100.3922207, 5.2664213],
          [100.3922851, 5.2657808],
          [100.3920278, 5.2656127],
          [100.3919474, 5.2653004],
          [100.3920358, 5.2650762],
          [100.3918107, 5.2649321],
          [100.3917222, 5.2644357],
          [100.3915373, 5.2640914],
          [100.3914684, 5.2637641],
          [100.3914161, 5.2635239],
          [100.3911428, 5.2631356],
          [100.3910463, 5.2629154],
          [100.3910221, 5.2624871],
          [100.3909659, 5.2620427],
          [100.3904314, 5.2618366],
          [100.3901415, 5.2618026],
          [100.3896184, 5.2615648],
          [100.3893455, 5.2611232],
          [100.3886177, 5.2602173],
          [100.3881345, 5.2599909],
          [100.3878559, 5.2596455],
          [100.3879639, 5.2590794],
          [100.3877365, 5.2582414],
          [100.387293, 5.2577262],
          [100.3871224, 5.2573073],
          [100.3867358, 5.2569449],
          [100.3857579, 5.2565826],
          [100.3853372, 5.256492],
          [100.3848653, 5.2566222],
          [100.3842285, 5.2567751],
          [100.384092, 5.2570751],
          [100.3841205, 5.2573016],
        ],
      ],
      [
        [
          [100.3845179, 5.2734511],
          [100.384349, 5.2738754],
          [100.3843651, 5.2742197],
          [100.3846144, 5.27458],
          [100.3856677, 5.2752045],
          [100.3856677, 5.2759411],
          [100.3854345, 5.2762774],
          [100.3853621, 5.2765496],
          [100.3856034, 5.2768699],
          [100.3859813, 5.2774944],
          [100.386729, 5.2776865],
          [100.3870426, 5.2779908],
          [100.3871069, 5.2783271],
          [100.3870426, 5.2789115],
          [100.3868738, 5.2793519],
          [100.3861662, 5.2796882],
          [100.385523, 5.2800404],
          [100.3855551, 5.2801766],
          [100.3862305, 5.280753],
          [100.386327, 5.2811293],
          [100.3860617, 5.2817058],
          [100.3859973, 5.281938],
          [100.3863753, 5.2822822],
          [100.386914, 5.283259],
          [100.3868175, 5.2836033],
          [100.3867532, 5.2841637],
          [100.3864637, 5.2844039],
          [100.3864235, 5.2847402],
          [100.3866888, 5.2855008],
          [100.3870909, 5.2858371],
          [100.3872276, 5.2860773],
          [100.3875572, 5.2860533],
          [100.3876698, 5.2859252],
          [100.3877341, 5.285773],
          [100.3879834, 5.2855649],
          [100.3877984, 5.2849003],
          [100.3879512, 5.2844119],
          [100.3880155, 5.2833791],
          [100.3882969, 5.2830509],
          [100.3887392, 5.2823543],
          [100.388699, 5.2818339],
          [100.3883211, 5.2812334],
          [100.3882326, 5.2802326],
          [100.3880477, 5.2795841],
          [100.3878386, 5.2784952],
          [100.38771, 5.278215],
          [100.3876859, 5.2777106],
          [100.387911, 5.277062],
          [100.3881924, 5.2761013],
          [100.388104, 5.2740756],
          [100.3883452, 5.2730828],
          [100.3883774, 5.2726665],
          [100.3880799, 5.2722341],
          [100.3880879, 5.2713053],
          [100.3882246, 5.2710732],
          [100.3881763, 5.2705687],
          [100.387903, 5.2702565],
          [100.3877663, 5.2692236],
          [100.3875894, 5.2689034],
          [100.3869703, 5.2681988],
          [100.386914, 5.2676543],
          [100.3866004, 5.2675983],
          [100.3859813, 5.2669898],
          [100.3855873, 5.2670058],
          [100.3853782, 5.2673661],
          [100.3850405, 5.2676624],
          [100.3849119, 5.2680627],
          [100.3846867, 5.2682949],
          [100.3846707, 5.2685591],
          [100.3846707, 5.2689915],
          [100.3844455, 5.2692797],
          [100.3843973, 5.269616],
          [100.3847993, 5.2700563],
          [100.3848073, 5.2705768],
          [100.3850888, 5.2709611],
          [100.3850968, 5.2711452],
          [100.3852415, 5.2712333],
          [100.3851129, 5.2718498],
          [100.3850888, 5.2722581],
          [100.3852094, 5.2726344],
          [100.385137, 5.2730348],
          [100.3848636, 5.273315],
          [100.3846787, 5.273323],
          [100.3845179, 5.2734511],
        ],
      ],
      [
        [
          [100.1827264, 5.3146299],
          [100.1826771, 5.3149001],
          [100.182818, 5.3150289],
          [100.1836025, 5.3153241],
          [100.1837874, 5.3151886],
          [100.1841261, 5.3151467],
          [100.1845022, 5.3151284],
          [100.1846414, 5.3149323],
          [100.1846839, 5.3144535],
          [100.1847013, 5.3139763],
          [100.1842925, 5.3133907],
          [100.1840712, 5.3132175],
          [100.1838372, 5.3134518],
          [100.18365, 5.3135618],
          [100.1834719, 5.3139359],
          [100.1828443, 5.314453],
          [100.1827264, 5.3146299],
        ],
      ],
      [
        [
          [100.3070005, 5.3104591],
          [100.3068945, 5.3108843],
          [100.3071131, 5.31156],
          [100.3071142, 5.3120756],
          [100.3072617, 5.3125184],
          [100.3073662, 5.3128223],
          [100.307492, 5.3130407],
          [100.3076344, 5.3133468],
          [100.3079092, 5.3136167],
          [100.3084649, 5.3140562],
          [100.3091218, 5.3142815],
          [100.309434, 5.3146483],
          [100.3096918, 5.3149055],
          [100.3099511, 5.315936],
          [100.3098268, 5.3181515],
          [100.3097783, 5.3190175],
          [100.3102159, 5.319544],
          [100.3101607, 5.3199223],
          [100.3105985, 5.3204244],
          [100.3109354, 5.3209067],
          [100.3111203, 5.3213465],
          [100.3114509, 5.32181],
          [100.3116762, 5.3222982],
          [100.3120323, 5.3230369],
          [100.3123526, 5.3236175],
          [100.3123786, 5.32449],
          [100.3122105, 5.3245857],
          [100.3122034, 5.3246814],
          [100.3125417, 5.324952],
          [100.3126718, 5.3254672],
          [100.3127492, 5.325632],
          [100.3134195, 5.3256796],
          [100.3141677, 5.3261098],
          [100.3143097, 5.32603],
          [100.3147311, 5.3263646],
          [100.3152456, 5.3264927],
          [100.3153743, 5.3267504],
          [100.3151441, 5.3273792],
          [100.3153792, 5.3276745],
          [100.3155644, 5.3280787],
          [100.3158222, 5.328464],
          [100.3158931, 5.3288112],
          [100.3158934, 5.329069],
          [100.3161518, 5.329584],
          [100.31641, 5.3300989],
          [100.316535, 5.3309221],
          [100.3163649, 5.3312433],
          [100.3167265, 5.3314962],
          [100.3171425, 5.331609],
          [100.3173777, 5.3317822],
          [100.3178876, 5.332422],
          [100.3178309, 5.3329312],
          [100.3186028, 5.333058],
          [100.3193692, 5.3332233],
          [100.3198958, 5.3333533],
          [100.3201609, 5.3340116],
          [100.3200333, 5.3342098],
          [100.3199775, 5.3344959],
          [100.320029, 5.3346645],
          [100.3202123, 5.3346497],
          [100.3205324, 5.3349047],
          [100.3207122, 5.3355065],
          [100.3209116, 5.3357596],
          [100.3211281, 5.336383],
          [100.3214347, 5.3365889],
          [100.3216877, 5.3365437],
          [100.3221638, 5.3363222],
          [100.32263, 5.3361984],
          [100.322989, 5.3363239],
          [100.3232667, 5.3361033],
          [100.3235287, 5.3359704],
          [100.3237425, 5.3358483],
          [100.3238624, 5.3354126],
          [100.32375, 5.3349805],
          [100.323736, 5.3348537],
          [100.3236077, 5.3346405],
          [100.3234765, 5.3342484],
          [100.3236429, 5.3337841],
          [100.3237163, 5.3331231],
          [100.323557, 5.3325249],
          [100.3238511, 5.3323147],
          [100.3239523, 5.3320366],
          [100.3243862, 5.3317123],
          [100.3245791, 5.3311312],
          [100.3246487, 5.3305581],
          [100.3250497, 5.3302438],
          [100.3253777, 5.3303629],
          [100.3257245, 5.329972],
          [100.3257957, 5.3295632],
          [100.3262239, 5.3295268],
          [100.3263039, 5.3292686],
          [100.3264591, 5.3291332],
          [100.326437, 5.3290463],
          [100.3264364, 5.3287885],
          [100.326317, 5.3284101],
          [100.3261333, 5.3281652],
          [100.3262218, 5.3277609],
          [100.3264738, 5.3276786],
          [100.326701, 5.3273831],
          [100.3268358, 5.3266011],
          [100.3271456, 5.3261001],
          [100.3269663, 5.3260606],
          [100.3268566, 5.3259089],
          [100.3267616, 5.3253704],
          [100.3266487, 5.3246318],
          [100.326558, 5.3242276],
          [100.326426, 5.3238912],
          [100.3263652, 5.3235447],
          [100.3261811, 5.3233745],
          [100.3261776, 5.3231302],
          [100.3260388, 5.3230962],
          [100.325908, 5.3229671],
          [100.3256712, 5.3228577],
          [100.3254183, 5.3226094],
          [100.3252621, 5.3220812],
          [100.325265, 5.3219601],
          [100.3251357, 5.321832],
          [100.3249936, 5.3213177],
          [100.3249018, 5.32071],
          [100.324853, 5.3202135],
          [100.3253217, 5.3195875],
          [100.3255721, 5.3191839],
          [100.3254876, 5.3186052],
          [100.3253461, 5.3184755],
          [100.3252143, 5.317788],
          [100.3250744, 5.3175545],
          [100.3245665, 5.3174271],
          [100.3243984, 5.3173735],
          [100.324085, 5.3171495],
          [100.3235154, 5.317007],
          [100.3232134, 5.3168541],
          [100.3231295, 5.3165836],
          [100.323125, 5.3162329],
          [100.3230529, 5.3160729],
          [100.3227602, 5.3158232],
          [100.3223725, 5.3156751],
          [100.3219822, 5.3155765],
          [100.3213247, 5.315587],
          [100.3211535, 5.3156739],
          [100.3201765, 5.3156124],
          [100.32008, 5.3154524],
          [100.3196533, 5.3154325],
          [100.3194918, 5.3152898],
          [100.3194083, 5.3152775],
          [100.3190645, 5.3146028],
          [100.3191013, 5.3141988],
          [100.3190487, 5.314199],
          [100.3190155, 5.3142682],
          [100.3189824, 5.3144887],
          [100.3187434, 5.314434],
          [100.3186934, 5.3140948],
          [100.3170394, 5.3143918],
          [100.3169117, 5.3144301],
          [100.3167306, 5.3143401],
          [100.3166565, 5.3142967],
          [100.3163309, 5.3141056],
          [100.3158776, 5.3138284],
          [100.315629, 5.3138068],
          [100.3156126, 5.3138054],
          [100.3154648, 5.3137969],
          [100.3153507, 5.3137232],
          [100.3152778, 5.3137239],
          [100.3152365, 5.3137243],
          [100.3150743, 5.3135782],
          [100.3149952, 5.3136244],
          [100.3147962, 5.3134834],
          [100.3139577, 5.3127574],
          [100.3137265, 5.3125555],
          [100.3135374, 5.3123264],
          [100.3134669, 5.312241],
          [100.3133574, 5.3121742],
          [100.3132126, 5.3121517],
          [100.3129519, 5.3112726],
          [100.3129663, 5.310646],
          [100.3132045, 5.3104553],
          [100.3134662, 5.3096638],
          [100.3134722, 5.309328],
          [100.3135747, 5.3087012],
          [100.3137216, 5.3081735],
          [100.3138891, 5.3077109],
          [100.3139319, 5.3070149],
          [100.3137828, 5.3069177],
          [100.3137431, 5.306524],
          [100.313837, 5.3061506],
          [100.3139577, 5.3057741],
          [100.3138702, 5.3053885],
          [100.3135912, 5.3053201],
          [100.3135076, 5.3050824],
          [100.3132619, 5.3049349],
          [100.3130371, 5.3046251],
          [100.3130323, 5.3042053],
          [100.3129674, 5.3036669],
          [100.3126718, 5.3033673],
          [100.3123463, 5.3032305],
          [100.3123188, 5.3025346],
          [100.3123655, 5.3023262],
          [100.3123677, 5.3020719],
          [100.311968, 5.3015795],
          [100.3116161, 5.301588],
          [100.3112444, 5.3014967],
          [100.3110791, 5.3010304],
          [100.3106827, 5.300969],
          [100.310452, 5.3007365],
          [100.3095046, 5.3009743],
          [100.3092471, 5.3013251],
          [100.3091191, 5.3015641],
          [100.3088201, 5.301668],
          [100.3086227, 5.3025819],
          [100.3087105, 5.3030041],
          [100.3086387, 5.3033085],
          [100.3086408, 5.3035021],
          [100.3087308, 5.3037811],
          [100.3086408, 5.3043395],
          [100.3085961, 5.304364],
          [100.3086374, 5.3046327],
          [100.3086458, 5.3046874],
          [100.3085452, 5.3048719],
          [100.308385, 5.3050334],
          [100.3082984, 5.3051206],
          [100.3080571, 5.3053617],
          [100.3080552, 5.305715],
          [100.3080999, 5.3062548],
          [100.308225, 5.3066718],
          [100.3081079, 5.3070838],
          [100.3080279, 5.3072748],
          [100.3079685, 5.307627],
          [100.307928, 5.3080032],
          [100.3078081, 5.3082227],
          [100.3076257, 5.3084698],
          [100.3074601, 5.3094651],
          [100.3069106, 5.3101398],
          [100.3070005, 5.3104591],
        ],
      ],
      [
        [
          [100.1849916, 5.3127529],
          [100.1847022, 5.3129391],
          [100.1847182, 5.3131117],
          [100.1847628, 5.3133567],
          [100.1848966, 5.3135993],
          [100.1853925, 5.3138097],
          [100.1853701, 5.3136604],
          [100.1854172, 5.3136288],
          [100.1855028, 5.3136762],
          [100.1855273, 5.3135921],
          [100.1855149, 5.3133615],
          [100.185432, 5.3131702],
          [100.1854199, 5.3130302],
          [100.1852708, 5.3127737],
          [100.1851425, 5.3126447],
          [100.1849916, 5.3127529],
        ],
      ],
      [
        [
          [100.338626, 5.571992],
          [100.3383712, 5.5735024],
          [100.3387681, 5.574826],
          [100.3388302, 5.57618],
          [100.3390188, 5.5766898],
          [100.3395291, 5.5773947],
          [100.3397689, 5.577199],
          [100.3397858, 5.5776534],
          [100.3422532, 5.5771125],
          [100.3458404, 5.5770391],
          [100.3531683, 5.575242],
          [100.3588592, 5.5738346],
          [100.3603875, 5.5734916],
          [100.3634384, 5.5734803],
          [100.3651659, 5.5744077],
          [100.3663003, 5.5750008],
          [100.3674283, 5.5759901],
          [100.3681379, 5.5773804],
          [100.3683077, 5.5790478],
          [100.3682845, 5.5807555],
          [100.368225, 5.5829797],
          [100.3685891, 5.5841038],
          [100.3692385, 5.5848925],
          [100.3703397, 5.585314],
          [100.3708374, 5.5856112],
          [100.3718702, 5.5856779],
          [100.3728085, 5.5850344],
          [100.3734932, 5.5844645],
          [100.3737202, 5.5840415],
          [100.3744289, 5.5827212],
          [100.3754132, 5.5805777],
          [100.3759639, 5.5799137],
          [100.3768123, 5.579597],
          [100.3784577, 5.5794761],
          [100.3793945, 5.5799634],
          [100.3802637, 5.5809052],
          [100.3812018, 5.5832105],
          [100.3816221, 5.5839117],
          [100.3822237, 5.5845191],
          [100.3825115, 5.5848097],
          [100.3828874, 5.5848202],
          [100.3838958, 5.5848484],
          [100.3846767, 5.584568],
          [100.3854937, 5.5840051],
          [100.3862815, 5.5828922],
          [100.3863291, 5.5802159],
          [100.3866758, 5.5786242],
          [100.3872346, 5.5779163],
          [100.3880025, 5.5777693],
          [100.3897449, 5.5784],
          [100.3922695, 5.5792965],
          [100.3941654, 5.5793455],
          [100.3975218, 5.5775631],
          [100.4014424, 5.5756994],
          [100.4051459, 5.5735256],
          [100.4082667, 5.5685326],
          [100.4098516, 5.5665158],
          [100.4133868, 5.5654682],
          [100.4158247, 5.5649371],
          [100.4187435, 5.564547],
          [100.4223869, 5.5628327],
          [100.4242398, 5.5624687],
          [100.4257998, 5.5628041],
          [100.4286249, 5.564592],
          [100.430378, 5.565727],
          [100.4316354, 5.5657867],
          [100.4335734, 5.5614088],
          [100.4345441, 5.560739],
          [100.4365308, 5.5648353],
          [100.4381058, 5.5658894],
          [100.4397105, 5.5654485],
          [100.441799, 5.566464],
          [100.4435188, 5.5673114],
          [100.4446282, 5.5672777],
          [100.4451666, 5.5670766],
          [100.4459297, 5.5659246],
          [100.4461268, 5.563027],
          [100.4465721, 5.5619892],
          [100.4469953, 5.5614551],
          [100.4477322, 5.5610782],
          [100.4491182, 5.5610782],
          [100.449575, 5.5616938],
          [100.4499066, 5.563168],
          [100.4501273, 5.5658731],
          [100.4506581, 5.5670403],
          [100.4515203, 5.5678101],
          [100.4522838, 5.5679805],
          [100.4534596, 5.5678357],
          [100.4545714, 5.5674914],
          [100.4565055, 5.5654616],
          [100.4577639, 5.564342],
          [100.459563, 5.5637669],
          [100.4604687, 5.563943],
          [100.4637511, 5.5659092],
          [100.4666389, 5.5659092],
          [100.4686874, 5.5657671],
          [100.4708917, 5.5644475],
          [100.4728328, 5.562126],
          [100.4741536, 5.5620398],
          [100.4755116, 5.5628827],
          [100.4768039, 5.5643916],
          [100.4782857, 5.5647994],
          [100.4795773, 5.5647824],
          [100.4824551, 5.5639676],
          [100.4843627, 5.5614634],
          [100.485953, 5.5605304],
          [100.4864798, 5.5605489],
          [100.4866357, 5.5607314],
          [100.4865407, 5.5635363],
          [100.4866815, 5.568824],
          [100.4871607, 5.5693241],
          [100.4880377, 5.5691535],
          [100.490921, 5.5671269],
          [100.4921909, 5.5668664],
          [100.4992146, 5.5671758],
          [100.5004912, 5.5673095],
          [100.5047062, 5.5670561],
          [100.5059959, 5.5663619],
          [100.5063728, 5.5656274],
          [100.5070736, 5.5630119],
          [100.507061, 5.5619067],
          [100.5071244, 5.5605033],
          [100.5076078, 5.5596701],
          [100.5077757, 5.5593806],
          [100.5095642, 5.5587881],
          [100.5122566, 5.5604768],
          [100.5142163, 5.5606452],
          [100.5155134, 5.5606809],
          [100.5170748, 5.5599393],
          [100.5174123, 5.5597713],
          [100.5187969, 5.5590822],
          [100.5196485, 5.5586187],
          [100.5225761, 5.5592826],
          [100.5240641, 5.5585086],
          [100.524131, 5.5560346],
          [100.523525, 5.5543702],
          [100.5234059, 5.5523554],
          [100.5246869, 5.5500087],
          [100.5258603, 5.5494551],
          [100.526823, 5.5504978],
          [100.5284725, 5.5548015],
          [100.5285362, 5.541169],
          [100.5278436, 5.506499],
          [100.5276884, 5.4995939],
          [100.5267411, 5.4643211],
          [100.5258138, 5.4309111],
          [100.5257099, 5.4271589],
          [100.5251524, 5.406968],
          [100.5250775, 5.3989619],
          [100.5249954, 5.3901806],
          [100.5248467, 5.3852012],
          [100.5247757, 5.3838853],
          [100.5247198, 5.3828487],
          [100.5245736, 5.3801368],
          [100.5245248, 5.3792313],
          [100.5244455, 5.3777618],
          [100.5243647, 5.3762444],
          [100.5241038, 5.3710254],
          [100.524185, 5.3702703],
          [100.5243485, 5.368749],
          [100.5245468, 5.3669041],
          [100.5246853, 5.3656164],
          [100.5248474, 5.3641078],
          [100.525746, 5.3557491],
          [100.5258389, 5.3548845],
          [100.5260123, 5.3532718],
          [100.5260347, 5.3529616],
          [100.5260956, 5.3521163],
          [100.5261092, 5.3520149],
          [100.526366, 5.3500952],
          [100.5263775, 5.3500095],
          [100.5264097, 5.3497751],
          [100.5265125, 5.348357],
          [100.5265293, 5.3481925],
          [100.5266598, 5.3471147],
          [100.5269794, 5.3444751],
          [100.5271975, 5.3425208],
          [100.5273067, 5.3417963],
          [100.527822, 5.3383258],
          [100.5278759, 5.337963],
          [100.5278915, 5.3378579],
          [100.5285004, 5.3323373],
          [100.5295786, 5.3225628],
          [100.5296132, 5.322249],
          [100.5311489, 5.309167],
          [100.5311745, 5.3089489],
          [100.5315134, 5.3061625],
          [100.5321398, 5.300691],
          [100.5330238, 5.2931282],
          [100.5330885, 5.2924519],
          [100.5330912, 5.2924243],
          [100.5332134, 5.2911475],
          [100.5333551, 5.2896677],
          [100.5333932, 5.2892697],
          [100.5334791, 5.2887035],
          [100.5334862, 5.2886567],
          [100.5336922, 5.2872984],
          [100.533803, 5.2858326],
          [100.5354143, 5.2723903],
          [100.5360382, 5.2668639],
          [100.5362886, 5.2645759],
          [100.5366797, 5.2593917],
          [100.5381297, 5.2476583],
          [100.5390626, 5.2404015],
          [100.5400578, 5.2316354],
          [100.5419224, 5.2180386],
          [100.5420635, 5.2170093],
          [100.5421567, 5.2162377],
          [100.5425187, 5.2132404],
          [100.542701, 5.2117305],
          [100.5427275, 5.2115115],
          [100.5428761, 5.2102806],
          [100.542887, 5.2101907],
          [100.5432, 5.2074159],
          [100.5432234, 5.2072084],
          [100.5434498, 5.2049174],
          [100.5441911, 5.1985597],
          [100.5442804, 5.1978151],
          [100.544803, 5.1934582],
          [100.5452619, 5.1899707],
          [100.5454184, 5.1887816],
          [100.5462969, 5.1815474],
          [100.5470142, 5.1738744],
          [100.5476583, 5.1684973],
          [100.5482486, 5.1629574],
          [100.548678, 5.1587414],
          [100.549028, 5.1556687],
          [100.5492627, 5.1536851],
          [100.5494715, 5.1520345],
          [100.5495975, 5.1509965],
          [100.5498932, 5.1484786],
          [100.5501338, 5.1460908],
          [100.5502888, 5.1444391],
          [100.5503059, 5.1434307],
          [100.5503058, 5.142857],
          [100.5492933, 5.1422395],
          [100.5485997, 5.1417822],
          [100.5476512, 5.141074],
          [100.5469316, 5.1406457],
          [100.5458359, 5.1399045],
          [100.5449201, 5.1392951],
          [100.5444785, 5.1389821],
          [100.5436935, 5.1386034],
          [100.5430394, 5.1383399],
          [100.5419928, 5.1381425],
          [100.5411752, 5.1379945],
          [100.5408318, 5.1379452],
          [100.5403248, 5.1379453],
          [100.5399324, 5.137929],
          [100.539311, 5.1378633],
          [100.5373731, 5.1382301],
          [100.5359118, 5.1386141],
          [100.5346716, 5.1388873],
          [100.5338147, 5.1391148],
          [100.5324392, 5.1393198],
          [100.5321221, 5.1394399],
          [100.5312427, 5.1396901],
          [100.5281083, 5.1404184],
          [100.5251994, 5.1410329],
          [100.5230572, 5.1415108],
          [100.5211856, 5.1420113],
          [100.5197215, 5.1423696],
          [100.5182623, 5.142788],
          [100.5173456, 5.1429739],
          [100.5159575, 5.1432864],
          [100.5152517, 5.143439],
          [100.5118264, 5.1441985],
          [100.5092152, 5.1447256],
          [100.5079566, 5.1450157],
          [100.5075971, 5.1451202],
          [100.5074037, 5.1451707],
          [100.5070965, 5.1451398],
          [100.5067893, 5.1449851],
          [100.5041011, 5.1433605],
          [100.5026734, 5.1424742],
          [100.5025045, 5.1425207],
          [100.5020898, 5.1430161],
          [100.5016136, 5.1430008],
          [100.5003694, 5.1428928],
          [100.49954, 5.142893],
          [100.4989111, 5.142765],
          [100.4983581, 5.1425794],
          [100.497882, 5.1423163],
          [100.4971446, 5.1417902],
          [100.4967452, 5.1413259],
          [100.4965609, 5.1411867],
          [100.4961308, 5.1408772],
          [100.4955471, 5.1401343],
          [100.4947022, 5.1389736],
          [100.4936739, 5.1369417],
          [100.4930441, 5.1355796],
          [100.4925064, 5.1341556],
          [100.4922549, 5.1332371],
          [100.4922196, 5.1331085],
          [100.4921998, 5.1328951],
          [100.475102, 5.130652],
          [100.4719856, 5.1302218],
          [100.4579345, 5.1283723],
          [100.4554936, 5.1280539],
          [100.4509574, 5.1274961],
          [100.4469636, 5.1269752],
          [100.4417958, 5.1263531],
          [100.4390447, 5.1260219],
          [100.4332468, 5.1253215],
          [100.4308381, 5.1250197],
          [100.4263213, 5.1244747],
          [100.4227169, 5.124027],
          [100.4217615, 5.1239083],
          [100.4192054, 5.1235797],
          [100.4183283, 5.1234862],
          [100.4112419, 5.122642],
          [100.4097721, 5.1224336],
          [100.4073876, 5.1221638],
          [100.406717, 5.1220196],
          [100.4066098, 5.1220035],
          [100.4062584, 5.1221531],
          [100.4034367, 5.1229573],
          [100.4029137, 5.123195],
          [100.4024523, 5.1234488],
          [100.4023611, 5.1234996],
          [100.4021358, 5.1236465],
          [100.3994509, 5.1262031],
          [100.3990888, 5.1264569],
          [100.3972006, 5.1268229],
          [100.3955564, 5.1270046],
          [100.3951674, 5.127074],
          [100.3948375, 5.1273465],
          [100.3944352, 5.1277659],
          [100.3941643, 5.1278113],
          [100.3938317, 5.1277686],
          [100.3935045, 5.1278701],
          [100.3929493, 5.1282815],
          [100.3924396, 5.1288025],
          [100.3921661, 5.12892],
          [100.3917503, 5.1289547],
          [100.3903073, 5.128725],
          [100.3895697, 5.1285059],
          [100.3888569, 5.1285836],
          [100.3888885, 5.1288039],
          [100.3891611, 5.1307075],
          [100.3899811, 5.1331036],
          [100.3914354, 5.1351044],
          [100.3932347, 5.1371788],
          [100.3941344, 5.1381608],
          [100.3948943, 5.1387424],
          [100.3950088, 5.1389435],
          [100.3954427, 5.1403045],
          [100.3966899, 5.1426315],
          [100.3971254, 5.1432959],
          [100.3986514, 5.1442744],
          [100.3992798, 5.1444434],
          [100.3999338, 5.1444296],
          [100.4008009, 5.1444516],
          [100.401687, 5.144564],
          [100.4015947, 5.1451575],
          [100.4010252, 5.1457304],
          [100.400153, 5.1465626],
          [100.4028905, 5.1472971],
          [100.4044618, 5.1488764],
          [100.4051244, 5.148943],
          [100.405516, 5.1495627],
          [100.4055865, 5.1500068],
          [100.4069283, 5.1514751],
          [100.4069582, 5.1515079],
          [100.4070322, 5.1515888],
          [100.4087667, 5.153487],
          [100.4104268, 5.1556507],
          [100.4115942, 5.1571722],
          [100.4134431, 5.1593826],
          [100.4173285, 5.1633791],
          [100.4185039, 5.1645881],
          [100.4222272, 5.1685653],
          [100.4226614, 5.16859],
          [100.4235167, 5.1680801],
          [100.4244227, 5.1676735],
          [100.4250975, 5.1672068],
          [100.4255577, 5.167032],
          [100.4261337, 5.1666092],
          [100.4267982, 5.1662233],
          [100.4276778, 5.1659192],
          [100.4283974, 5.1656745],
          [100.4292707, 5.1652417],
          [100.429288969363284, 5.165401779962317],
          [100.4293238, 5.1653853],
          [100.429719, 5.1662381],
          [100.4301788, 5.1671381],
          [100.4299897, 5.1674871],
          [100.429466, 5.1677374],
          [100.428247, 5.168129],
          [100.4274121, 5.1688372],
          [100.4270471, 5.1694901],
          [100.4264884, 5.1704916],
          [100.4258851, 5.1712335],
          [100.4254158, 5.1718937],
          [100.4249316, 5.1724501],
          [100.4244548, 5.1727914],
          [100.4239185, 5.1732588],
          [100.4229352, 5.1739858],
          [100.4223691, 5.1741861],
          [100.4214529, 5.1745793],
          [100.4204919, 5.1750319],
          [100.4199929, 5.175247],
          [100.4197321, 5.1756179],
          [100.4194044, 5.1759444],
          [100.4191362, 5.1763153],
          [100.419017, 5.176753],
          [100.4189114, 5.1772743],
          [100.4187787, 5.17794],
          [100.4186967, 5.178897],
          [100.4186806, 5.179369],
          [100.4185176, 5.1797546],
          [100.4185763, 5.1803175],
          [100.4188322, 5.1806048],
          [100.4190436, 5.1819082],
          [100.4190007, 5.1832332],
          [100.4189578, 5.1859258],
          [100.4187647, 5.1883619],
          [100.4186359, 5.1905844],
          [100.4185501, 5.1922726],
          [100.4187218, 5.1935761],
          [100.4190007, 5.1947087],
          [100.4193655, 5.1955421],
          [100.4202208, 5.1984804],
          [100.4209074, 5.2021025],
          [100.4214922, 5.2041273],
          [100.4220755, 5.2054868],
          [100.4237519, 5.2074629],
          [100.4245769, 5.2082021],
          [100.4249323, 5.2084344],
          [100.4260614, 5.2084014],
          [100.426737, 5.2090209],
          [100.4273817, 5.2089539],
          [100.4287842, 5.208646],
          [100.4294437, 5.2085919],
          [100.4295733, 5.2088304],
          [100.4296136, 5.2089262],
          [100.4288444, 5.2091014],
          [100.4282107, 5.2094596],
          [100.42801, 5.2101149],
          [100.4273281, 5.2116099],
          [100.4261826, 5.2129753],
          [100.4251711, 5.2141626],
          [100.424547586590052, 5.21509537819721],
          [100.4244705, 5.2152107],
          [100.424219, 5.2165931],
          [100.4241881, 5.2171055],
          [100.4247747, 5.2172798],
          [100.4254437, 5.2188069],
          [100.4247541, 5.2194218],
          [100.4248756, 5.220906],
          [100.424547586590052, 5.223012422295891],
          [100.4242551, 5.2248907],
          [100.4237898, 5.2248937],
          [100.4234043, 5.2264528],
          [100.4234347, 5.2265978],
          [100.4235287, 5.2270461],
          [100.4235374, 5.2270877],
          [100.4236906, 5.2278192],
          [100.4226545, 5.2324279],
          [100.4225024, 5.2331042],
          [100.4201502, 5.2382817],
          [100.4174557, 5.2453411],
          [100.415834, 5.2486507],
          [100.414024, 5.2516583],
          [100.4129334, 5.2549361],
          [100.4122534, 5.2566019],
          [100.4117146, 5.2580751],
          [100.4115767, 5.2586656],
          [100.4116722, 5.2596352],
          [100.4116976, 5.2605002],
          [100.4113176, 5.2607378],
          [100.4112673, 5.2607602],
          [100.4109864, 5.2608854],
          [100.410494, 5.261105],
          [100.4082302, 5.2623124],
          [100.4070023, 5.2622935],
          [100.4067537, 5.2626796],
          [100.4065084, 5.2628441],
          [100.4062498, 5.2636518],
          [100.4060689, 5.26477],
          [100.4062114, 5.2655344],
          [100.406268, 5.2658381],
          [100.406391193054546, 5.265921147779831],
          [100.4067106, 5.2660406],
          [100.406889, 5.2660415],
          [100.4072618, 5.265859],
          [100.40776, 5.2662636],
          [100.407810772117912, 5.266281956416293],
          [100.4080806, 5.2662795],
          [100.408253128384771, 5.266441888207946],
          [100.4083295, 5.2664695],
          [100.408571392001164, 5.266741446210487],
          [100.40903, 5.2671731],
          [100.4092168, 5.2676922],
          [100.4091268, 5.2683291],
          [100.4091274, 5.2690876],
          [100.4093939, 5.2697258],
          [100.4099528, 5.2701363],
          [100.4103899, 5.2705649],
          [100.4107329, 5.2709709],
          [100.411223, 5.2714068],
          [100.4119464, 5.272052],
          [100.4123763, 5.2726077],
          [100.4129036, 5.2733204],
          [100.4139319, 5.2741901],
          [100.415019, 5.2754773],
          [100.4162819, 5.2766948],
          [100.4176008, 5.2780095],
          [100.4190658, 5.2788609],
          [100.4190976, 5.2810798],
          [100.4191007, 5.2812936],
          [100.4190484, 5.2835875],
          [100.4166406, 5.2869946],
          [100.415879, 5.289987],
          [100.4155703, 5.2917189],
          [100.4150145, 5.2948342],
          [100.4143558, 5.2971707],
          [100.4143044, 5.2988206],
          [100.413728, 5.3011469],
          [100.4135222, 5.3033194],
          [100.4137838, 5.3034175],
          [100.4137979, 5.3036417],
          [100.4141075, 5.3037935],
          [100.4142589, 5.3038052],
          [100.4147345, 5.303896],
          [100.4147812, 5.3039664],
          [100.4147625, 5.3040665],
          [100.4145632, 5.3040585],
          [100.414539, 5.3042743],
          [100.414707, 5.3043149],
          [100.4146781, 5.3045175],
          [100.4142973, 5.3044987],
          [100.4135967, 5.3044373],
          [100.4121532, 5.3055702],
          [100.4106883, 5.3079423],
          [100.4106705, 5.3079868],
          [100.4096216, 5.3106121],
          [100.4097077, 5.3121714],
          [100.4100213, 5.3126909],
          [100.4107506, 5.3129628],
          [100.4106641, 5.3131073],
          [100.4113433, 5.3134977],
          [100.4119059, 5.3137746],
          [100.4118411, 5.3138462],
          [100.4111717, 5.3134742],
          [100.4107198, 5.3133118],
          [100.4106191, 5.3134714],
          [100.4103239, 5.3133547],
          [100.4101906, 5.3135277],
          [100.4094762, 5.3133131],
          [100.408631, 5.3131522],
          [100.4082557, 5.3133748],
          [100.4083782, 5.3130378],
          [100.4083198, 5.3127656],
          [100.4080006, 5.3123593],
          [100.4075222, 5.3123068],
          [100.406809, 5.3123667],
          [100.4064159, 5.3125968],
          [100.4059766, 5.3132762],
          [100.4045651, 5.3155349],
          [100.4034401, 5.3177018],
          [100.4031272, 5.3184665],
          [100.4031477, 5.3187743],
          [100.4033072, 5.3188957],
          [100.4032345, 5.3189728],
          [100.4029741, 5.3189074],
          [100.4027926, 5.3200375],
          [100.402645, 5.3210598],
          [100.4026108, 5.3220629],
          [100.4026796, 5.3235991],
          [100.4028055, 5.3249628],
          [100.4034835, 5.3255872],
          [100.4038981, 5.3261773],
          [100.404301, 5.326691],
          [100.4045025, 5.3283984],
          [100.4046083, 5.3290201],
          [100.4050173, 5.3311444],
          [100.4048102, 5.3325716],
          [100.4049309, 5.3333765],
          [100.4048089, 5.3339194],
          [100.4047115, 5.3358826],
          [100.4047469, 5.3368371],
          [100.4048537, 5.3372815],
          [100.4046945, 5.3376764],
          [100.4053596, 5.3387583],
          [100.4051261, 5.3394519],
          [100.4048199, 5.3399986],
          [100.4046568, 5.3399296],
          [100.4046037, 5.3398366],
          [100.4042576, 5.3398434],
          [100.4041811, 5.3397017],
          [100.4040718, 5.3397006],
          [100.4040409, 5.3397864],
          [100.4038194, 5.3397775],
          [100.4038285, 5.3396291],
          [100.4036156, 5.3396573],
          [100.4033672, 5.3394966],
          [100.4027922, 5.3394927],
          [100.4020411, 5.3397427],
          [100.4008712, 5.3405847],
          [100.4002821, 5.3417082],
          [100.3993429, 5.3438404],
          [100.3982839, 5.3453386],
          [100.3972201, 5.3467689],
          [100.3954, 5.3479327],
          [100.3950068, 5.3482094],
          [100.3937037, 5.3492028],
          [100.3936793, 5.3493767],
          [100.3938306, 5.3495287],
          [100.393767, 5.3496164],
          [100.3936953, 5.3496954],
          [100.393659249466566, 5.349663171945305],
          [100.3936235, 5.3496805],
          [100.3935719, 5.3497261],
          [100.3934897, 5.3495116],
          [100.3922999, 5.3499447],
          [100.3903247, 5.3506001],
          [100.3890909, 5.352032],
          [100.3887449, 5.3529656],
          [100.3888951, 5.3532663],
          [100.3888753, 5.3533934],
          [100.3888412, 5.3536338],
          [100.3888114, 5.3537556],
          [100.3887019, 5.3542031],
          [100.3879418, 5.3543276],
          [100.3865986, 5.355955],
          [100.385677, 5.3574414],
          [100.3843896, 5.359727],
          [100.3841883, 5.3599762],
          [100.3841155, 5.360035],
          [100.3840363, 5.3600657],
          [100.3839643, 5.3600612],
          [100.3838342, 5.3600734],
          [100.3836966, 5.360134],
          [100.3835957, 5.3603688],
          [100.3835418, 5.3603863],
          [100.3835107, 5.3604136],
          [100.3835217, 5.360454],
          [100.3835525, 5.3604806],
          [100.3835891, 5.3604669],
          [100.3836258, 5.3604532],
          [100.3836631, 5.3606388],
          [100.3833091, 5.3608484],
          [100.3818173, 5.3619625],
          [100.3810598, 5.3626504],
          [100.3786971, 5.3640595],
          [100.3766648, 5.3640775],
          [100.3759149, 5.3667892],
          [100.375497, 5.3683258],
          [100.3747561, 5.371383],
          [100.3749459, 5.3714063],
          [100.3752978, 5.3712421],
          [100.37558, 5.3707667],
          [100.3757703, 5.370346],
          [100.3760165, 5.3703017],
          [100.3760813, 5.3704297],
          [100.3764355, 5.3708745],
          [100.376077, 5.3709748],
          [100.375775, 5.371418],
          [100.3759906, 5.3717332],
          [100.3756622, 5.3722596],
          [100.3751335, 5.372646],
          [100.3745442, 5.3725065],
          [100.3744333, 5.3724802],
          [100.3733338, 5.3744112],
          [100.3732579, 5.3745087],
          [100.3696591, 5.3806849],
          [100.3716804, 5.3818461],
          [100.3728574, 5.3825179],
          [100.3730645, 5.3827117],
          [100.3731038, 5.3829846],
          [100.372546, 5.3837543],
          [100.3699738, 5.3822747],
          [100.3697843, 5.3826178],
          [100.3692633, 5.3834943],
          [100.3691516, 5.3836896],
          [100.3684516, 5.3849141],
          [100.368024, 5.3846638],
          [100.3678399, 5.3847283],
          [100.3679552, 5.3842396],
          [100.3661335, 5.383753],
          [100.3637947, 5.3929222],
          [100.3637112, 5.3929006],
          [100.3636486, 5.3931202],
          [100.3635663, 5.3930945],
          [100.3635925, 5.3928362],
          [100.3635546, 5.3928667],
          [100.3634597, 5.3932912],
          [100.3637818, 5.3933613],
          [100.3643704, 5.3927897],
          [100.3657375, 5.3873581],
          [100.365825, 5.3872993],
          [100.3665717, 5.3874912],
          [100.3663933, 5.3882095],
          [100.3667059, 5.3882736],
          [100.3668131, 5.3878624],
          [100.3668439, 5.3878691],
          [100.3667514, 5.3882523],
          [100.3667996, 5.3882616],
          [100.3665569, 5.3889212],
          [100.3665502, 5.3890948],
          [100.3665824, 5.3891589],
          [100.366581, 5.3892176],
          [100.3663538, 5.3900902],
          [100.3662686, 5.3902396],
          [100.366259, 5.3902564],
          [100.3661964, 5.390634],
          [100.3659764, 5.3914063],
          [100.3659328, 5.3916121],
          [100.3657754, 5.391816],
          [100.3656829, 5.3919629],
          [100.3655432, 5.3925722],
          [100.3655898, 5.3928194],
          [100.3655201, 5.3930798],
          [100.3654917, 5.3931426],
          [100.3654551, 5.3932018],
          [100.3653961, 5.3932873],
          [100.365370218081196, 5.393322263837618],
          [100.3653166, 5.3934295],
          [100.3651819, 5.39351],
          [100.3650447, 5.3934495],
          [100.364842, 5.3937734],
          [100.3643969, 5.3938297],
          [100.3643469, 5.3939113],
          [100.3641587, 5.3938958],
          [100.3641626, 5.3938618],
          [100.3638813, 5.3938418],
          [100.3638803, 5.3937973],
          [100.363822, 5.3937945],
          [100.3638147, 5.3939413],
          [100.3634643, 5.3939128],
          [100.3634429, 5.3939291],
          [100.3634672, 5.3939507],
          [100.363622, 5.3939516],
          [100.363821, 5.3939761],
          [100.3638735, 5.3940078],
          [100.363873, 5.3940511],
          [100.3638074, 5.3940833],
          [100.3638082, 5.3941276],
          [100.3638685, 5.3941421],
          [100.3638691, 5.3940861],
          [100.3641531, 5.3940996],
          [100.3641491, 5.3940687],
          [100.3642186, 5.394076],
          [100.3643303, 5.3942443],
          [100.364309, 5.3944664],
          [100.3640566, 5.3945357],
          [100.3640533, 5.3945214],
          [100.3638658, 5.3945758],
          [100.363872, 5.3945996],
          [100.3637095, 5.394643],
          [100.3636592, 5.3946284],
          [100.3636317, 5.3946014],
          [100.3636071, 5.3946142],
          [100.3636172, 5.3946508],
          [100.3636925, 5.3946716],
          [100.3637137, 5.3947505],
          [100.3636862, 5.3947739],
          [100.3632713, 5.3948776],
          [100.3632605, 5.3948937],
          [100.3632657, 5.3949148],
          [100.3632812, 5.3949212],
          [100.3636985, 5.394812],
          [100.3637309, 5.3948146],
          [100.3637479, 5.3948778],
          [100.3636744, 5.3949297],
          [100.3636842, 5.3949837],
          [100.3637693, 5.394974],
          [100.3637599, 5.3949231],
          [100.3638848, 5.3948908],
          [100.3638329, 5.3958174],
          [100.3636592, 5.3964893],
          [100.3636417, 5.3966275],
          [100.363626, 5.3966941],
          [100.3633856, 5.3978061],
          [100.3632508, 5.3980807],
          [100.3632193, 5.3980981],
          [100.3632118, 5.3980998],
          [100.363106, 5.3980647],
          [100.3631017, 5.3981542],
          [100.363095, 5.3982931],
          [100.3630936, 5.3983225],
          [100.3631124, 5.3987376],
          [100.3633213, 5.3994042],
          [100.3633477, 5.3995895],
          [100.3641253, 5.4011291],
          [100.3649308, 5.4029439],
          [100.36588, 5.4053241],
          [100.3662924, 5.4064484],
          [100.366473, 5.4063742],
          [100.3666547, 5.4063082],
          [100.3668378, 5.4066038],
          [100.366976, 5.4070204],
          [100.3672119, 5.4078271],
          [100.3675829, 5.4088635],
          [100.3675419, 5.4089109],
          [100.3675446, 5.4089857],
          [100.3675261, 5.4090331],
          [100.36755, 5.4090992],
          [100.3674829, 5.4092278],
          [100.3670691, 5.4092317],
          [100.3670712, 5.4095402],
          [100.3670001, 5.4098032],
          [100.3668897, 5.4100149],
          [100.366693, 5.4102011],
          [100.3664739, 5.4103188],
          [100.3650083, 5.4104649],
          [100.3653826, 5.4159843],
          [100.3651354, 5.4161264],
          [100.3654056, 5.4192076],
          [100.3655221, 5.4193859],
          [100.3657761, 5.4194777],
          [100.3709202, 5.4190031],
          [100.3712802, 5.4192147],
          [100.3722566, 5.4204791],
          [100.3732698, 5.4219977],
          [100.3739497, 5.4232688],
          [100.3741374, 5.4237067],
          [100.3742702, 5.4241112],
          [100.3740556, 5.4241996],
          [100.3745443, 5.4250665],
          [100.3753009, 5.427524],
          [100.3758185, 5.429524],
          [100.3760338, 5.4301501],
          [100.3760576, 5.43023],
          [100.376072, 5.4302783],
          [100.3758976, 5.4303677],
          [100.3761068, 5.4308217],
          [100.3758259, 5.432296],
          [100.3757438, 5.4327268],
          [100.3779472, 5.4386126],
          [100.3782716, 5.4396971],
          [100.3782744, 5.4400227],
          [100.377588, 5.4402344],
          [100.3774362, 5.4402047],
          [100.3773276, 5.4402447],
          [100.3772965, 5.4403257],
          [100.3773285, 5.4404052],
          [100.3774074, 5.4404281],
          [100.3776341, 5.4404058],
          [100.3780065, 5.441176],
          [100.3786654, 5.4431526],
          [100.3788809, 5.44423],
          [100.3789227, 5.444783],
          [100.3789949, 5.4454091],
          [100.3791275, 5.4461838],
          [100.3788337, 5.4466696],
          [100.3779271, 5.4466646],
          [100.3779271, 5.4469155],
          [100.377925238335962, 5.447159102186561],
          [100.3779217, 5.4476221],
          [100.3794352, 5.4476269],
          [100.3794384, 5.4476404],
          [100.3800315, 5.4501881],
          [100.3801003, 5.4505811],
          [100.380514, 5.4529476],
          [100.380374, 5.4530282],
          [100.380315, 5.4531083],
          [100.3802559, 5.4532751],
          [100.380382, 5.4549359],
          [100.3804893, 5.456594],
          [100.3805483, 5.4576594],
          [100.3805046, 5.4586648],
          [100.380551, 5.4598301],
          [100.3804035, 5.4638924],
          [100.3803397, 5.4663942],
          [100.3795184, 5.4708499],
          [100.3791831, 5.4715612],
          [100.3791721, 5.4716843],
          [100.378975, 5.4738942],
          [100.3791037, 5.4767404],
          [100.3787674, 5.480606],
          [100.3784209, 5.483084],
          [100.3783996, 5.4846434],
          [100.3783611, 5.487458],
          [100.378157, 5.4911879],
          [100.3778336, 5.4931221],
          [100.3777502, 5.4945689],
          [100.3774702, 5.4956233],
          [100.377493, 5.4962251],
          [100.3773276, 5.496694],
          [100.3771654, 5.4976911],
          [100.377089, 5.498096],
          [100.3755013, 5.498186],
          [100.3749149, 5.4996973],
          [100.373861, 5.5019083],
          [100.3722035, 5.5081536],
          [100.3718903, 5.5092499],
          [100.3713229, 5.5112353],
          [100.3703746, 5.5148738],
          [100.3700221, 5.5182389],
          [100.3702414, 5.5192066],
          [100.3701734, 5.5195586],
          [100.3701317, 5.5197746],
          [100.3684156, 5.5198653],
          [100.3674214, 5.5207455],
          [100.3659203, 5.5261699],
          [100.3635945, 5.5310234],
          [100.3622587, 5.5369624],
          [100.362214, 5.5371612],
          [100.3619993, 5.5374194],
          [100.3604293, 5.5393067],
          [100.3593565, 5.5427143],
          [100.3583165, 5.5475102],
          [100.3568784, 5.5550853],
          [100.3538654, 5.5629326],
          [100.3512501, 5.5655024],
          [100.3501305, 5.5669172],
          [100.3487992, 5.5678718],
          [100.3474672, 5.5679407],
          [100.344632, 5.5680779],
          [100.3429991, 5.5685189],
          [100.3422019, 5.5690673],
          [100.3411199, 5.5688697],
          [100.3404054, 5.5691789],
          [100.3399076, 5.5696727],
          [100.3394661, 5.5703246],
          [100.338626, 5.571992],
        ],
      ],
      [
        [
          [100.1752005, 5.4716028],
          [100.1751148, 5.4722038],
          [100.1752316, 5.4724111],
          [100.1752791, 5.4727044],
          [100.1752936, 5.4728333],
          [100.1751948, 5.4729594],
          [100.1752844, 5.4733138],
          [100.1754008, 5.4734669],
          [100.1754566, 5.4736048],
          [100.1755007, 5.473591],
          [100.1757639, 5.4739203],
          [100.1759941, 5.4742544],
          [100.1761715, 5.474477],
          [100.1760841, 5.4745367],
          [100.1761964, 5.4746296],
          [100.1765011, 5.474898],
          [100.1766484, 5.4753013],
          [100.176595, 5.4755375],
          [100.1767385, 5.4757268],
          [100.176917, 5.4758887],
          [100.1772125, 5.4758945],
          [100.1775998, 5.4759963],
          [100.1781039, 5.4761936],
          [100.1785904, 5.4764896],
          [100.1791784, 5.476759],
          [100.1795648, 5.4769371],
          [100.1797693, 5.4769195],
          [100.1798257, 5.4770324],
          [100.1800305, 5.4769962],
          [100.1801508, 5.4768513],
          [100.1801235, 5.4767021],
          [100.1803851, 5.476602],
          [100.1806445, 5.4766794],
          [100.1807646, 5.4767049],
          [100.1808965, 5.4768351],
          [100.1812131, 5.4767737],
          [100.1814255, 5.4766588],
          [100.181602, 5.476755],
          [100.1819696, 5.4767975],
          [100.1821937, 5.4769391],
          [100.1826486, 5.4770376],
          [100.1833571, 5.4775547],
          [100.1838863, 5.4777715],
          [100.1841972, 5.4780131],
          [100.1844656, 5.4781627],
          [100.1847825, 5.4781282],
          [100.1849977, 5.478194],
          [100.1849686, 5.4782856],
          [100.1851133, 5.4783709],
          [100.1855937, 5.4782511],
          [100.1856153, 5.4781067],
          [100.1857932, 5.4779025],
          [100.1857538, 5.4778285],
          [100.1860831, 5.4775699],
          [100.1863398, 5.4773115],
          [100.186378, 5.4770689],
          [100.186483, 5.4767897],
          [100.1865955, 5.4765378],
          [100.1868196, 5.4760642],
          [100.1867783, 5.4758297],
          [100.1869997, 5.4753786],
          [100.186969, 5.4745329],
          [100.1866706, 5.4738545],
          [100.1864879, 5.4736014],
          [100.1862524, 5.4734117],
          [100.186009, 5.4730994],
          [100.1856964, 5.4728563],
          [100.1858108, 5.4726272],
          [100.1857497, 5.4722792],
          [100.1859704, 5.4720125],
          [100.1862009, 5.4717693],
          [100.1866001, 5.4714403],
          [100.1870374, 5.4711736],
          [100.187334, 5.4709897],
          [100.1876017, 5.4708632],
          [100.1878182, 5.4707656],
          [100.1884253, 5.4705879],
          [100.1885178, 5.470562],
          [100.1887869, 5.4705063],
          [100.1890277, 5.470475],
          [100.1893039, 5.470417],
          [100.1906291, 5.4703816],
          [100.1911637, 5.4704157],
          [100.1919083, 5.4705011],
          [100.1920315, 5.4706833],
          [100.1921931, 5.4708267],
          [100.1922703, 5.4708842],
          [100.1922041, 5.4710239],
          [100.192353, 5.4711052],
          [100.1924842, 5.4711985],
          [100.1926085, 5.4712135],
          [100.1928316, 5.4712962],
          [100.1931558, 5.4712027],
          [100.1933588, 5.4712727],
          [100.1935817, 5.4713321],
          [100.193838, 5.4715265],
          [100.1941084, 5.4717679],
          [100.1944238, 5.4718583],
          [100.1947053, 5.4719452],
          [100.1948536, 5.4722481],
          [100.1950918, 5.4727109],
          [100.1952969, 5.47282],
          [100.1955722, 5.4728253],
          [100.1957648, 5.472699],
          [100.1957948, 5.4725511],
          [100.1960731, 5.4722823],
          [100.1965272, 5.4717527],
          [100.1968352, 5.4713371],
          [100.1971313, 5.4711036],
          [100.1970585, 5.4706434],
          [100.1967616, 5.4702476],
          [100.1966268, 5.4701723],
          [100.1965435, 5.4700256],
          [100.1965, 5.4698189],
          [100.1964629, 5.469699],
          [100.1967098, 5.4694385],
          [100.1965996, 5.469296],
          [100.196712, 5.4690787],
          [100.1971162, 5.4689204],
          [100.1974374, 5.4686232],
          [100.1977695, 5.4683954],
          [100.1981094, 5.4682764],
          [100.1980442, 5.4680789],
          [100.1980532, 5.4678166],
          [100.198269, 5.4676927],
          [100.198664, 5.4676203],
          [100.1991537, 5.4676387],
          [100.1997199, 5.4676877],
          [100.2001421, 5.4677139],
          [100.200699, 5.4677594],
          [100.2010687, 5.467735],
          [100.2012778, 5.4680463],
          [100.201609, 5.4682123],
          [100.2018653, 5.4683468],
          [100.202269, 5.4684064],
          [100.2026254, 5.4684698],
          [100.2030679, 5.468463],
          [100.203437, 5.4684543],
          [100.2037172, 5.4682248],
          [100.2039396, 5.4680446],
          [100.2039514, 5.4678818],
          [100.203892, 5.4677319],
          [100.2039604, 5.4672922],
          [100.2038656, 5.4671002],
          [100.2036945, 5.4670009],
          [100.2032339, 5.4664835],
          [100.2030072, 5.4662015],
          [100.2026451, 5.4659759],
          [100.2025909, 5.4658901],
          [100.202852, 5.4657331],
          [100.2030977, 5.4656826],
          [100.2031494, 5.4655479],
          [100.2032837, 5.4652623],
          [100.2032083, 5.4649997],
          [100.2034235, 5.4646198],
          [100.2032265, 5.464485],
          [100.2029717, 5.4643621],
          [100.2028449, 5.4641661],
          [100.2025757, 5.4638122],
          [100.2026106, 5.4635997],
          [100.2027461, 5.4633545],
          [100.202857, 5.4632662],
          [100.2028496, 5.4629965],
          [100.2026741, 5.4628917],
          [100.2028855, 5.4626561],
          [100.2030837, 5.4624664],
          [100.2032772, 5.4623338],
          [100.2034725, 5.4621846],
          [100.203931, 5.4618926],
          [100.2044113, 5.461583],
          [100.204958, 5.4614212],
          [100.2053576, 5.4612384],
          [100.2055775, 5.4609623],
          [100.2057583, 5.460604],
          [100.2059828, 5.4605159],
          [100.2064544, 5.4603509],
          [100.2065491, 5.4603171],
          [100.2068088, 5.4602663],
          [100.2070617, 5.4602341],
          [100.2072589, 5.4601899],
          [100.2075008, 5.4601086],
          [100.207952, 5.4599508],
          [100.2085292, 5.4598487],
          [100.2087864, 5.4598864],
          [100.2096464, 5.4611391],
          [100.2096786, 5.4611706],
          [100.2111001, 5.4598363],
          [100.2116873, 5.4599698],
          [100.2121189, 5.4601261],
          [100.2125268, 5.4601828],
          [100.2126659, 5.4602022],
          [100.2136747, 5.4603539],
          [100.2138724, 5.4603865],
          [100.2139223, 5.4603947],
          [100.2141699, 5.4604166],
          [100.2151732, 5.4606815],
          [100.2156552, 5.4609308],
          [100.2158841, 5.4609899],
          [100.216113, 5.4610893],
          [100.2166938, 5.4613178],
          [100.2170176, 5.4614375],
          [100.2173727, 5.4615947],
          [100.2180974, 5.4619754],
          [100.2185248, 5.4621983],
          [100.2188391, 5.4623117],
          [100.2194519, 5.462695],
          [100.2196872, 5.4628195],
          [100.2198533, 5.462919],
          [100.2202116, 5.4631074],
          [100.2206983, 5.4633923],
          [100.2210677, 5.4635651],
          [100.2214361, 5.4637491],
          [100.2215979, 5.4639077],
          [100.2216291, 5.4640335],
          [100.2217102, 5.4642198],
          [100.221746, 5.464416],
          [100.2218298, 5.4646248],
          [100.2219704, 5.464935],
          [100.2223917, 5.4652918],
          [100.2226498, 5.4653813],
          [100.2229874, 5.4652532],
          [100.223307, 5.4652532],
          [100.223741, 5.4650021],
          [100.2242557, 5.4650355],
          [100.2245215, 5.4646471],
          [100.2249172, 5.4644304],
          [100.2251288, 5.4643265],
          [100.2253545, 5.463931],
          [100.2260283, 5.4636797],
          [100.2267937, 5.4635723],
          [100.2269614, 5.4635918],
          [100.2270087, 5.4635125],
          [100.2271825, 5.4634068],
          [100.2277141, 5.4634043],
          [100.2281213, 5.4634916],
          [100.2284254, 5.4636619],
          [100.2290348, 5.4639042],
          [100.2291705, 5.4640441],
          [100.2292531, 5.4643666],
          [100.2294162, 5.4645845],
          [100.2297581, 5.4646574],
          [100.2299882, 5.464374],
          [100.2301603, 5.4641204],
          [100.2302305, 5.4638052],
          [100.230529, 5.4635421],
          [100.2307611, 5.4632646],
          [100.2310751, 5.4630953],
          [100.2315012, 5.4631739],
          [100.2320496, 5.4633491],
          [100.2326134, 5.4634214],
          [100.2328709, 5.4634764],
          [100.2332941, 5.4634348],
          [100.2336316, 5.4636142],
          [100.2339808, 5.4637664],
          [100.2344378, 5.4640948],
          [100.234691, 5.4643767],
          [100.2350548, 5.4645575],
          [100.2352132, 5.464871],
          [100.2353857, 5.4652312],
          [100.2355437, 5.4654454],
          [100.2358013, 5.4655741],
          [100.2360289, 5.4653784],
          [100.2363225, 5.4648945],
          [100.2366804, 5.4648452],
          [100.2370916, 5.4649458],
          [100.2374721, 5.4651274],
          [100.2379627, 5.4653616],
          [100.2392804, 5.4663596],
          [100.2395382, 5.4666168],
          [100.2396381, 5.4669279],
          [100.2399245, 5.4673966],
          [100.240121, 5.467591],
          [100.240957, 5.4684181],
          [100.2412144, 5.4685461],
          [100.2416455, 5.4689441],
          [100.2420606, 5.4694698],
          [100.2422458, 5.4697043],
          [100.2425032, 5.4698322],
          [100.2443804, 5.4718035],
          [100.2454091, 5.4728615],
          [100.2460847, 5.473538],
          [100.2466274, 5.4739479],
          [100.2468852, 5.4742051],
          [100.247143, 5.4744624],
          [100.2474008, 5.4747196],
          [100.2476586, 5.4749768],
          [100.2484318, 5.4757485],
          [100.250879, 5.4776771],
          [100.2522972, 5.4790913],
          [100.2540375, 5.4803159],
          [100.2542182, 5.4806094],
          [100.2542788, 5.4809354],
          [100.2542305, 5.4812788],
          [100.2541029, 5.4815368],
          [100.2551332, 5.4821787],
          [100.2555166, 5.4826931],
          [100.2559747, 5.4828304],
          [100.2564204, 5.4826914],
          [100.2566456, 5.4824021],
          [100.2564501, 5.4820576],
          [100.2566533, 5.4816777],
          [100.2570428, 5.4814944],
          [100.2573998, 5.4814933],
          [100.2578318, 5.4816066],
          [100.2584078, 5.4817883],
          [100.2589443, 5.4819341],
          [100.2594994, 5.4820599],
          [100.2599232, 5.4818249],
          [100.2600788, 5.4815548],
          [100.260584, 5.4812073],
          [100.2607694, 5.4808887],
          [100.260883331298828, 5.480859344029194],
          [100.2612685, 5.4807601],
          [100.2613662, 5.480395],
          [100.2615426, 5.4802379],
          [100.2615588, 5.4797167],
          [100.2617042, 5.4793169],
          [100.2620404, 5.4789259],
          [100.2621855, 5.4788096],
          [100.2624297, 5.4787215],
          [100.2628721, 5.4786934],
          [100.2638136, 5.4788519],
          [100.2638034, 5.4790175],
          [100.2638995, 5.4792873],
          [100.2640147, 5.4794064],
          [100.2641771, 5.4794927],
          [100.2644466, 5.4795477],
          [100.2647305, 5.4794932],
          [100.2650526, 5.4791447],
          [100.2652641, 5.4788733],
          [100.2653756, 5.4785091],
          [100.2652981, 5.4780936],
          [100.2655715, 5.4779894],
          [100.2658939, 5.4779336],
          [100.2660893, 5.4779324],
          [100.2663795, 5.4777879],
          [100.2666343, 5.4778126],
          [100.2669139, 5.4779289],
          [100.2670696, 5.4780944],
          [100.2679863, 5.4782812],
          [100.2683331, 5.4784115],
          [100.2684888, 5.478482],
          [100.2686339, 5.4786581],
          [100.2689206, 5.478799],
          [100.2690091, 5.4789364],
          [100.2691719, 5.4789999],
          [100.2693418, 5.4789822],
          [100.26954, 5.4791169],
          [100.2698164, 5.479161],
          [100.2700886, 5.4791902],
          [100.27067, 5.4790006],
          [100.2713185, 5.4788958],
          [100.2719083, 5.4786276],
          [100.2723264, 5.4782752],
          [100.2724675, 5.4779062],
          [100.2728203, 5.4777154],
          [100.2736369, 5.4776492],
          [100.2739839, 5.4776902],
          [100.2745117, 5.4779224],
          [100.274858, 5.4777209],
          [100.2750919, 5.4772959],
          [100.2751279, 5.4769894],
          [100.2755448, 5.4766746],
          [100.2755945, 5.4766085],
          [100.275767, 5.476379],
          [100.2758535, 5.4758346],
          [100.2761232, 5.4754611],
          [100.2762576, 5.4752333],
          [100.276771, 5.4751545],
          [100.2773241, 5.4751497],
          [100.2773703, 5.4750594],
          [100.2774655, 5.474873],
          [100.2773935, 5.4742393],
          [100.277398, 5.4735744],
          [100.2774494, 5.4734162],
          [100.277478662870948, 5.473388338943221],
          [100.2775134, 5.4730717],
          [100.2775642, 5.4728623],
          [100.2775683, 5.4726988],
          [100.2774457, 5.4725055],
          [100.277496, 5.4723813],
          [100.277544306280703, 5.472294855427277],
          [100.2775586, 5.4721841],
          [100.2776409, 5.472122],
          [100.2778142, 5.4718849],
          [100.2778105, 5.4716156],
          [100.2778544, 5.4714071],
          [100.2785051, 5.4709906],
          [100.2785003, 5.4708171],
          [100.2782468, 5.4706934],
          [100.2783352, 5.469849],
          [100.2782695, 5.4697632],
          [100.2782016, 5.4698151],
          [100.278181, 5.4698678],
          [100.2782034, 5.4699299],
          [100.2781882, 5.4699882],
          [100.2781343, 5.4699729],
          [100.2780535, 5.469928],
          [100.2780022, 5.4698403],
          [100.2779324, 5.4695025],
          [100.2777988, 5.4691982],
          [100.2777595, 5.4691306],
          [100.2778918, 5.4688533],
          [100.2780234, 5.4686176],
          [100.2781504, 5.4683766],
          [100.2782191, 5.4682462],
          [100.2783553, 5.4680523],
          [100.2784954, 5.4679255],
          [100.2786627, 5.4677275],
          [100.2787627, 5.4675885],
          [100.2789291, 5.4674243],
          [100.2791621, 5.4672979],
          [100.2798119, 5.4670931],
          [100.2803221, 5.4670216],
          [100.2807397, 5.4670368],
          [100.2809589, 5.4670467],
          [100.2814228, 5.4670984],
          [100.2821991, 5.4670939],
          [100.2828747, 5.4671745],
          [100.2834022, 5.467289],
          [100.2838342, 5.467244],
          [100.2842947, 5.4672528],
          [100.284759, 5.4672811],
          [100.2852646, 5.4673137],
          [100.2855728, 5.4673649],
          [100.2861347, 5.4674389],
          [100.2868675, 5.467617],
          [100.2875206, 5.4677742],
          [100.2879334, 5.4678797],
          [100.2884827, 5.4680295],
          [100.2890573, 5.4681874],
          [100.2901864, 5.4684933],
          [100.2910996, 5.4685711],
          [100.2913454, 5.4687245],
          [100.2916667, 5.4687733],
          [100.2921174, 5.4689271],
          [100.2924371, 5.4689023],
          [100.2925701, 5.4686703],
          [100.2923861, 5.4683712],
          [100.2921887, 5.4680855],
          [100.2923051, 5.4678765],
          [100.2926621, 5.4677061],
          [100.2927945, 5.4676952],
          [100.293046, 5.4677708],
          [100.2932138, 5.4678791],
          [100.293278, 5.467798],
          [100.2933619, 5.4677299],
          [100.2940676, 5.4678055],
          [100.2946807, 5.4678454],
          [100.2948151, 5.4678799],
          [100.2948824, 5.4682198],
          [100.2953737, 5.4685121],
          [100.2953793, 5.4682648],
          [100.2954363, 5.4681029],
          [100.2951353, 5.4677612],
          [100.2951173, 5.4675004],
          [100.2952618, 5.4673835],
          [100.2954244, 5.4667899],
          [100.2956684, 5.4668439],
          [100.2957949, 5.4664661],
          [100.295322, 5.4662663],
          [100.2953239, 5.4660374],
          [100.2953252, 5.4658729],
          [100.2953965, 5.4655473],
          [100.2959117, 5.4652527],
          [100.2970622, 5.4654106],
          [100.2982568, 5.4653414],
          [100.2988343, 5.46543],
          [100.298948, 5.4654534],
          [100.2995979, 5.4655871],
          [100.2999498, 5.4656341],
          [100.3001292, 5.465658],
          [100.3005527, 5.4657624],
          [100.3008228, 5.4657986],
          [100.3011126, 5.4656874],
          [100.3011973, 5.4653378],
          [100.3013103, 5.46492],
          [100.3013958, 5.4644236],
          [100.3021922, 5.4642165],
          [100.3024925, 5.4641851],
          [100.3026324, 5.4641794],
          [100.3044365, 5.4641064],
          [100.3050146, 5.4641863],
          [100.305614, 5.4642795],
          [100.3058813, 5.4642949],
          [100.3060249, 5.4643031],
          [100.3065398, 5.4642832],
          [100.3073115, 5.4642815],
          [100.3078108, 5.4643503],
          [100.3079697, 5.4642836],
          [100.3081678, 5.4642484],
          [100.3083498, 5.4642901],
          [100.3083553, 5.4643537],
          [100.3083938, 5.4643885],
          [100.3090611, 5.464581],
          [100.3097337, 5.4647361],
          [100.30981, 5.464721],
          [100.3098378, 5.4646678],
          [100.3097871, 5.4646031],
          [100.3095739, 5.4645708],
          [100.3086702, 5.4643106],
          [100.308597, 5.4638333],
          [100.3084886, 5.4632631],
          [100.3086095, 5.4627222],
          [100.3088982, 5.4621909],
          [100.3093069, 5.4619282],
          [100.3098584, 5.4616488],
          [100.3104174, 5.4614715],
          [100.311324, 5.4614022],
          [100.3121474, 5.4614876],
          [100.3128073, 5.461525],
          [100.3129332, 5.4614635],
          [100.3132485, 5.461622],
          [100.313721, 5.4616501],
          [100.3141335, 5.4614916],
          [100.3145085, 5.4610227],
          [100.3145205, 5.4597109],
          [100.3145312, 5.4590198],
          [100.3149845, 5.4579931],
          [100.315002, 5.4577918],
          [100.3149298, 5.4577949],
          [100.3149248, 5.4579718],
          [100.3144695, 5.4589664],
          [100.3137637, 5.4589765],
          [100.3137136, 5.4574608],
          [100.3143324, 5.4574573],
          [100.3145716, 5.4578087],
          [100.314564, 5.4579806],
          [100.3146351, 5.4579807],
          [100.3146283, 5.4577554],
          [100.3144599, 5.4574185],
          [100.3143514, 5.4547796],
          [100.3143649, 5.4543054],
          [100.3143288, 5.4541841],
          [100.314054, 5.4529372],
          [100.3138622, 5.4520499],
          [100.3134818, 5.451058],
          [100.3131502, 5.4501043],
          [100.3128339, 5.4492097],
          [100.311901, 5.447302],
          [100.3118428, 5.4470245],
          [100.3119523, 5.4468337],
          [100.3125937, 5.4454993],
          [100.3134972, 5.4444344],
          [100.3145859, 5.4431629],
          [100.3149284, 5.4413833],
          [100.3149786, 5.438995],
          [100.3150995, 5.4379719],
          [100.3152616, 5.4372189],
          [100.3152909, 5.4371565],
          [100.3159018, 5.4359488],
          [100.3163169, 5.4354604],
          [100.3170713, 5.4347056],
          [100.3212216, 5.4312218],
          [100.3213817, 5.4308888],
          [100.3214363, 5.4302893],
          [100.3212199, 5.4300103],
          [100.3213206, 5.4298509],
          [100.3213849, 5.4297494],
          [100.3218788, 5.429189],
          [100.3221506, 5.4289674],
          [100.3228686, 5.4285207],
          [100.3230348, 5.4284214],
          [100.3231899, 5.4283277],
          [100.3239509, 5.4279013],
          [100.3245804, 5.427517],
          [100.3249039, 5.4273273],
          [100.325313964010604, 5.427130345013089],
          [100.3254228, 5.42705],
          [100.3259952, 5.4267444],
          [100.3266088, 5.4264634],
          [100.3271225, 5.4262709],
          [100.3275478, 5.4260891],
          [100.3279166, 5.4259151],
          [100.3278499, 5.425731],
          [100.328232786523131, 5.425564170872065],
          [100.3282895, 5.4254883],
          [100.3297876, 5.4248021],
          [100.3303124, 5.4246273],
          [100.3306976, 5.4244296],
          [100.331051, 5.4242716],
          [100.3314433, 5.4241663],
          [100.3318738, 5.4242505],
          [100.3320044, 5.424403],
          [100.3321013, 5.4244164],
          [100.3322201, 5.4243831],
          [100.3323357, 5.4243386],
          [100.332517, 5.4242297],
          [100.332628, 5.4241563],
          [100.3326715, 5.4239592],
          [100.3329305, 5.4238674],
          [100.3333661, 5.4238546],
          [100.3335228, 5.4241831],
          [100.3339259, 5.4240864],
          [100.3346562, 5.4239278],
          [100.3351445, 5.4238587],
          [100.3353202, 5.4238001],
          [100.3353612, 5.4237898],
          [100.3355274, 5.4237386],
          [100.3357576, 5.4237394],
          [100.3358615, 5.4237821],
          [100.3359866, 5.4237635],
          [100.3361453, 5.4236913],
          [100.3363146, 5.4235655],
          [100.3367584, 5.4234661],
          [100.3369441, 5.4234409],
          [100.3370241, 5.423458],
          [100.3376188, 5.423182],
          [100.3378831, 5.4232457],
          [100.3383159, 5.4232529],
          [100.3385447, 5.4236371],
          [100.3387928, 5.4235912],
          [100.3389603, 5.4235442],
          [100.3396111, 5.4233722],
          [100.3399678, 5.4235576],
          [100.3402801, 5.4234737],
          [100.3405369, 5.4233588],
          [100.340889, 5.4230847],
          [100.3413189, 5.4229114],
          [100.3416394, 5.4227094],
          [100.3418793, 5.4225817],
          [100.3419967, 5.4224549],
          [100.3420457, 5.4222968],
          [100.3421702, 5.4221999],
          [100.3422571, 5.4221209],
          [100.3425024, 5.4220625],
          [100.3432623, 5.42168],
          [100.3436987, 5.4215528],
          [100.3438526, 5.4218151],
          [100.3447043, 5.4215062],
          [100.3449695, 5.4214293],
          [100.3453505, 5.4211934],
          [100.3454402, 5.4208719],
          [100.3453967, 5.4204524],
          [100.3454995, 5.4195071],
          [100.3454503, 5.4194949],
          [100.3455069, 5.419203],
          [100.3454812, 5.4189993],
          [100.3456956, 5.419007],
          [100.3457013, 5.4190555],
          [100.3462227, 5.4190915],
          [100.3460984, 5.4209562],
          [100.3463395, 5.420975],
          [100.3466567, 5.4173572],
          [100.346382, 5.4173214],
          [100.346257, 5.418678],
          [100.3459356, 5.4186462],
          [100.3457048, 5.4186431],
          [100.3457121, 5.4186913],
          [100.3454607, 5.418688],
          [100.3452565, 5.4177714],
          [100.3449459, 5.417279],
          [100.3446803, 5.416674],
          [100.3442359, 5.4162383],
          [100.3441644, 5.4160927],
          [100.3439515, 5.4156972],
          [100.3438278, 5.4157625],
          [100.3437649, 5.4156294],
          [100.3436853, 5.4155626],
          [100.3436066, 5.4154967],
          [100.3438246, 5.4153618],
          [100.3431457, 5.4141067],
          [100.3431498, 5.4140287],
          [100.3431766, 5.4139582],
          [100.3436828, 5.4135861],
          [100.3437652, 5.4135252],
          [100.3437848, 5.41355],
          [100.3438578, 5.413501],
          [100.3438402, 5.4134757],
          [100.3440066, 5.4133697],
          [100.3440236, 5.4133912],
          [100.3440724, 5.4133618],
          [100.3440397, 5.4133097],
          [100.3439767, 5.41332],
          [100.3439307, 5.4132495],
          [100.3443845, 5.4129379],
          [100.3443939, 5.4129246],
          [100.3443953, 5.4129123],
          [100.3443864, 5.412906],
          [100.3443787, 5.4129044],
          [100.3443685, 5.412905],
          [100.3439069, 5.4132055],
          [100.3438418, 5.4131183],
          [100.3438564, 5.4131079],
          [100.343877, 5.4130644],
          [100.3438683, 5.4130402],
          [100.3438129, 5.4130718],
          [100.3438315, 5.4131047],
          [100.3436727, 5.4132044],
          [100.3436579, 5.4131847],
          [100.343502, 5.4132548],
          [100.3434776, 5.4132657],
          [100.3435205, 5.4133327],
          [100.3429934, 5.4136723],
          [100.3429249, 5.4135852],
          [100.3428747, 5.4135056],
          [100.3428764, 5.4134163],
          [100.3430406, 5.4132016],
          [100.3430679, 5.4131636],
          [100.3431013, 5.4131069],
          [100.3430659, 5.4130797],
          [100.3431057, 5.4130296],
          [100.343134, 5.4130521],
          [100.3433058, 5.4128377],
          [100.343342, 5.4128609],
          [100.343372, 5.4128206],
          [100.3432522, 5.4127342],
          [100.3434841, 5.4124484],
          [100.3434659, 5.412433],
          [100.3432314, 5.4127145],
          [100.3431697, 5.4127416],
          [100.3430468, 5.4128827],
          [100.3430258, 5.4128612],
          [100.3431471, 5.412708],
          [100.3431296, 5.4126925],
          [100.3431489, 5.4126302],
          [100.3431297, 5.4126097],
          [100.3430941, 5.4126538],
          [100.3431207, 5.4126756],
          [100.3429437, 5.4128975],
          [100.3429683, 5.4129157],
          [100.3426715, 5.4132747],
          [100.3426407, 5.4132601],
          [100.342621171913578, 5.413247026620007],
          [100.3425972, 5.4132784],
          [100.3425578, 5.4132408],
          [100.3424794, 5.4133447],
          [100.3424419, 5.4133061],
          [100.342546297375762, 5.413196900700662],
          [100.3421524, 5.4129332],
          [100.3420597, 5.4128918],
          [100.3416137, 5.413376],
          [100.3414235, 5.4134502],
          [100.3413782, 5.4133689],
          [100.341561, 5.4133277],
          [100.3416701, 5.4132144],
          [100.3416959, 5.4131306],
          [100.3416231, 5.4130532],
          [100.3411952, 5.4131887],
          [100.3410029, 5.4133031],
          [100.3406164, 5.413094],
          [100.340426, 5.4129822],
          [100.3409073, 5.4125297],
          [100.3409006, 5.4124495],
          [100.3408, 5.4123524],
          [100.3406083, 5.4121778],
          [100.3403746, 5.4123807],
          [100.3392851, 5.4121262],
          [100.3389395, 5.412014],
          [100.3393204, 5.4115784],
          [100.3392791, 5.4113313],
          [100.3390788, 5.4110178],
          [100.3386486, 5.4110643],
          [100.3375698, 5.410722],
          [100.3379016, 5.4104308],
          [100.3379011, 5.410173],
          [100.3379005, 5.4099154],
          [100.3381566, 5.4093993],
          [100.3384132, 5.4091409],
          [100.3384127, 5.4088831],
          [100.3382842, 5.4087542],
          [100.3380531, 5.4087706],
          [100.3376626, 5.4084394],
          [100.3375338, 5.4081778],
          [100.3378954, 5.4075955],
          [100.3381009, 5.4072213],
          [100.3375087, 5.4069515],
          [100.3373792, 5.4068234],
          [100.3366503, 5.4067839],
          [100.3371009, 5.4065457],
          [100.3375269, 5.4061585],
          [100.3377382, 5.4061676],
          [100.3378799, 5.4061281],
          [100.3387065, 5.4056207],
          [100.3385906, 5.4054119],
          [100.3384952, 5.405406],
          [100.3383165, 5.4053291],
          [100.3382682, 5.4051294],
          [100.337985, 5.4052778],
          [100.3378133, 5.405069],
          [100.3381062, 5.4048335],
          [100.3380521, 5.4047059],
          [100.3381862, 5.4045814],
          [100.3380601, 5.4044639],
          [100.3379099, 5.4045846],
          [100.3378444, 5.4045024],
          [100.3380274, 5.404324],
          [100.3379018, 5.4041766],
          [100.3378117, 5.4042348],
          [100.3373767, 5.4045526],
          [100.337138, 5.4043176],
          [100.3368123, 5.4045761],
          [100.3367292, 5.4044992],
          [100.3373649, 5.4039603],
          [100.3371492, 5.4037472],
          [100.3370451, 5.4039347],
          [100.3367673, 5.4039721],
          [100.3363437, 5.4037327],
          [100.3362154, 5.4036036],
          [100.3359041, 5.4035074],
          [100.3357057, 5.4033269],
          [100.3352191, 5.4028682],
          [100.3351171, 5.4027315],
          [100.3348393, 5.4028821],
          [100.3347669, 5.4027801],
          [100.3348662, 5.4025264],
          [100.3347514, 5.4023363],
          [100.3346011, 5.4021317],
          [100.3348431, 5.4015955],
          [100.3343774, 5.4010914],
          [100.3339273, 5.4010785],
          [100.3328405, 5.4023554],
          [100.3327971, 5.4029346],
          [100.3320354, 5.4035005],
          [100.3315347, 5.4038348],
          [100.3314691, 5.4037276],
          [100.3310837, 5.4038156],
          [100.3308815, 5.4038657],
          [100.3305433, 5.4039922],
          [100.330338, 5.4039195],
          [100.3302074, 5.4037857],
          [100.3317255, 5.4026548],
          [100.3323689, 5.4020091],
          [100.3334102, 5.4009445],
          [100.3333793, 5.4007702],
          [100.3271708, 5.3948706],
          [100.3269825, 5.3947489],
          [100.3269087, 5.3948254],
          [100.3268083, 5.3947321],
          [100.3268673, 5.3946386],
          [100.3269249, 5.3945957],
          [100.3269297, 5.3944711],
          [100.3268243, 5.3942911],
          [100.3268068, 5.394276],
          [100.3267817, 5.394245],
          [100.3267501, 5.3942103],
          [100.3267191, 5.3941721],
          [100.3266586, 5.3941101],
          [100.3262743, 5.393834],
          [100.3256575, 5.3932892],
          [100.3253904, 5.3931856],
          [100.3251764, 5.3930185],
          [100.3249204, 5.3928251],
          [100.3249232, 5.3926766],
          [100.3248803, 5.3925933],
          [100.3253625, 5.3920192],
          [100.3256877, 5.3915792],
          [100.3259736, 5.3915965],
          [100.3263312, 5.3917591],
          [100.3271578, 5.3910499],
          [100.3275699, 5.3902468],
          [100.3275029, 5.3898291],
          [100.3273636, 5.3884859],
          [100.3266744, 5.3879184],
          [100.3259669, 5.3876491],
          [100.3253825, 5.3875846],
          [100.3245858, 5.3877443],
          [100.3240464, 5.3876516],
          [100.323535, 5.3878485],
          [100.3233347, 5.3880564],
          [100.3232812, 5.3884591],
          [100.3229072, 5.3886295],
          [100.3225998, 5.3887811],
          [100.3222292, 5.388881],
          [100.3218075, 5.3887518],
          [100.3212666, 5.3889442],
          [100.3211379, 5.3890737],
          [100.3208818, 5.3895898],
          [100.3207953, 5.3899016],
          [100.3206263, 5.3903636],
          [100.3205507, 5.3906183],
          [100.3203958, 5.3906011],
          [100.3202588, 5.3905858],
          [100.3200051, 5.3901083],
          [100.3198815, 5.3898915],
          [100.3198804, 5.3893759],
          [100.3196209, 5.3883455],
          [100.3195683, 5.3882926],
          [100.3194926, 5.3882164],
          [100.3189779, 5.388089],
          [100.3189077, 5.3877002],
          [100.3181225, 5.3862477],
          [100.3176604, 5.385504],
          [100.3177041, 5.3853526],
          [100.3178141, 5.3852562],
          [100.3176669, 5.3849627],
          [100.317554, 5.385011],
          [100.3173835, 5.3844141],
          [100.3172975, 5.3842264],
          [100.3170392, 5.3837114],
          [100.3170386, 5.3834536],
          [100.317038, 5.3831958],
          [100.3170363, 5.3824226],
          [100.3172924, 5.3819064],
          [100.3172919, 5.3816488],
          [100.317355, 5.3814423],
          [100.3176432, 5.3813978],
          [100.3178002, 5.3806364],
          [100.3178308, 5.3800008],
          [100.3176483, 5.3789628],
          [100.3180246, 5.378656],
          [100.3194771, 5.3778099],
          [100.3195085, 5.3776036],
          [100.3192523, 5.3772454],
          [100.3189869, 5.3768421],
          [100.3179868, 5.3736969],
          [100.3179208, 5.372429],
          [100.3183337, 5.3720945],
          [100.318718, 5.3718083],
          [100.3188844, 5.3714676],
          [100.3188611, 5.3711241],
          [100.3187215, 5.3709364],
          [100.3185082, 5.3707219],
          [100.3183087, 5.369435],
          [100.3184061, 5.3690719],
          [100.3185534, 5.368729],
          [100.3185232, 5.36844],
          [100.3183001, 5.3682392],
          [100.3179828, 5.3681497],
          [100.3177943, 5.3679321],
          [100.3170078, 5.3673531],
          [100.3169894, 5.3671894],
          [100.316986305838327, 5.367109330673408],
          [100.3169861, 5.367107],
          [100.3169805, 5.3670439],
          [100.3171661, 5.366677],
          [100.3174933, 5.3662305],
          [100.3174663, 5.3652468],
          [100.3175435, 5.3642673],
          [100.3174868, 5.3635854],
          [100.31753514473246, 5.362400449508048],
          [100.3173115, 5.3622563],
          [100.3166334, 5.3618514],
          [100.3163598, 5.361767],
          [100.3160835, 5.3616559],
          [100.3159631, 5.3616481],
          [100.315854520400791, 5.361641015373668],
          [100.3157381, 5.3616657],
          [100.3156984, 5.3616308],
          [100.3159617, 5.361031],
          [100.3162172, 5.360257],
          [100.3164144, 5.3599634],
          [100.3166733, 5.3594655],
          [100.3167294, 5.3592249],
          [100.3167044, 5.3591749],
          [100.3166568, 5.3590797],
          [100.3166007, 5.3589675],
          [100.31657, 5.3584558],
          [100.3164987, 5.3581881],
          [100.3164631, 5.3580543],
          [100.3163326, 5.3575638],
          [100.3160053, 5.3574236],
          [100.3158618, 5.3572594],
          [100.3157438, 5.3572046],
          [100.3156312, 5.3571299],
          [100.315544, 5.3570618],
          [100.3153563, 5.3568922],
          [100.3151497, 5.3567426],
          [100.31473, 5.3563875],
          [100.3151001, 5.3557292],
          [100.3148681, 5.3556063],
          [100.3148144, 5.3555716],
          [100.3147514, 5.3555169],
          [100.314626, 5.3554498],
          [100.3142784, 5.3553638],
          [100.3144055, 5.354848],
          [100.3145903, 5.3535753],
          [100.3146596, 5.3534714],
          [100.3146594, 5.3533609],
          [100.3142283, 5.350992],
          [100.3141232, 5.3507607],
          [100.3140108, 5.350467],
          [100.313727, 5.3490515],
          [100.3135803, 5.3488348],
          [100.3133724, 5.3485499],
          [100.3131964, 5.3482505],
          [100.3130325, 5.3478755],
          [100.3128222, 5.3472059],
          [100.312519, 5.3459009],
          [100.3123062, 5.3450244],
          [100.3123918, 5.3434393],
          [100.3121942, 5.3427381],
          [100.3119673, 5.3420002],
          [100.3117723, 5.3420105],
          [100.3115054, 5.3413667],
          [100.3112802, 5.3411512],
          [100.3111837, 5.340975],
          [100.3110871, 5.3407987],
          [100.3113091, 5.3405102],
          [100.3115224, 5.3399459],
          [100.311559, 5.3389144],
          [100.3114249, 5.3389084],
          [100.3112604, 5.3388173],
          [100.3107607, 5.3381074],
          [100.3105844, 5.3371099],
          [100.3119741, 5.3366567],
          [100.3120748, 5.3364741],
          [100.3118755, 5.3357549],
          [100.3100688, 5.3313868],
          [100.3100086, 5.3312672],
          [100.3099103, 5.3312274],
          [100.3089311, 5.331474],
          [100.3086963, 5.3306309],
          [100.3084805, 5.3298316],
          [100.3080873, 5.3290258],
          [100.3071971, 5.327428],
          [100.3070443, 5.327366],
          [100.3062033, 5.32759],
          [100.3059352, 5.3276173],
          [100.3058806, 5.3274805],
          [100.3058533, 5.3272565],
          [100.3042361, 5.3241056],
          [100.303507, 5.3228083],
          [100.3020484, 5.319946],
          [100.3013694, 5.3181156],
          [100.3005013, 5.3163283],
          [100.300427, 5.316174],
          [100.3000732, 5.3153721],
          [100.299697, 5.3132652],
          [100.3000509, 5.3127027],
          [100.2999367, 5.3120117],
          [100.2996897, 5.3116585],
          [100.299634, 5.3113775],
          [100.2995083, 5.3110978],
          [100.2995591, 5.3107751],
          [100.2997169, 5.3104125],
          [100.2995607, 5.3102597],
          [100.2996427, 5.3097335],
          [100.2985534, 5.3078801],
          [100.2979406, 5.3055809],
          [100.297798, 5.3054293],
          [100.2975559, 5.3045805],
          [100.2974574, 5.3039808],
          [100.2967494, 5.3017902],
          [100.2959433, 5.2991362],
          [100.2959916, 5.2988904],
          [100.2957903, 5.2982864],
          [100.2956089, 5.2982072],
          [100.2955498, 5.2980827],
          [100.2954321, 5.2977851],
          [100.2954421, 5.2975166],
          [100.2947643, 5.2951669],
          [100.2943074, 5.2937105],
          [100.2941086, 5.2930749],
          [100.2941788, 5.2927846],
          [100.2944002, 5.2926824],
          [100.2945082, 5.2926931],
          [100.2945676, 5.2926232],
          [100.2945406, 5.2925318],
          [100.2944704, 5.2924888],
          [100.2943786, 5.2925211],
          [100.294276, 5.292591],
          [100.2941464, 5.2926555],
          [100.2940546, 5.2926286],
          [100.2939736, 5.2924942],
          [100.2938872, 5.2923006],
          [100.2939088, 5.2921877],
          [100.2939304, 5.2921232],
          [100.2937522, 5.2916984],
          [100.2931581, 5.289967],
          [100.2930123, 5.2898648],
          [100.2929091, 5.2898407],
          [100.2927959, 5.2896805],
          [100.2924209, 5.2892215],
          [100.2926025, 5.2889436],
          [100.2927407, 5.2886147],
          [100.2926075, 5.2882204],
          [100.2919489, 5.2873883],
          [100.291752, 5.2871864],
          [100.2914982, 5.2870177],
          [100.2907571, 5.2865834],
          [100.2898621, 5.2861366],
          [100.2901013, 5.2857368],
          [100.2903312, 5.2853525],
          [100.2906007, 5.285303],
          [100.2906995, 5.2852295],
          [100.2907287, 5.2851028],
          [100.2907626, 5.285097],
          [100.2907485, 5.2849958],
          [100.2906773, 5.2849958],
          [100.2906014, 5.2845704],
          [100.290597, 5.2832783],
          [100.290972, 5.2832471],
          [100.290854, 5.2829106],
          [100.2912139, 5.2828446],
          [100.2911565, 5.2824384],
          [100.2905686, 5.2825081],
          [100.2905476, 5.2823307],
          [100.2911326, 5.2822637],
          [100.2909411, 5.2811475],
          [100.2908049, 5.2806211],
          [100.2909323, 5.2798626],
          [100.2907204, 5.2795095],
          [100.2902215, 5.279099],
          [100.2896773, 5.2785387],
          [100.289493, 5.2787561],
          [100.2889979, 5.2783913],
          [100.2881894, 5.277324],
          [100.287884, 5.2769311],
          [100.2877546, 5.2768029],
          [100.2876262, 5.2766739],
          [100.2875132, 5.2767478],
          [100.2872875, 5.27654],
          [100.2870779, 5.2763705],
          [100.2865958, 5.2758955],
          [100.286482, 5.2756794],
          [100.2862725, 5.2754796],
          [100.2861037, 5.2751969],
          [100.2859254, 5.2748667],
          [100.285707, 5.274505],
          [100.2855497, 5.2740371],
          [100.2853145, 5.2738432],
          [100.2849572, 5.2736675],
          [100.2848287, 5.2735384],
          [100.2846994, 5.2734102],
          [100.2843844, 5.2729356],
          [100.2841174, 5.2724851],
          [100.2839193, 5.2721223],
          [100.2835951, 5.2717435],
          [100.2834826, 5.2714317],
          [100.2833705, 5.2711269],
          [100.2832016, 5.2708038],
          [100.283048, 5.270619],
          [100.282838, 5.2701615],
          [100.2825778, 5.269454],
          [100.2822097, 5.2685659],
          [100.2821149, 5.2681477],
          [100.2820678, 5.2678014],
          [100.2819069, 5.2669307],
          [100.2817829, 5.2658319],
          [100.2819937, 5.2656823],
          [100.2818548, 5.2653911],
          [100.2817991, 5.2652742],
          [100.2817566, 5.2647959],
          [100.2818722, 5.2647083],
          [100.2818856, 5.2644258],
          [100.2822556, 5.2641676],
          [100.2823743, 5.2639173],
          [100.2824768, 5.2636024],
          [100.2825243, 5.2632245],
          [100.2827122, 5.2628941],
          [100.2829693, 5.2627642],
          [100.2832257, 5.262506],
          [100.2834824, 5.2622477],
          [100.2839153, 5.2619242],
          [100.2839455, 5.2616015],
          [100.2839443, 5.2613123],
          [100.2839438, 5.2610545],
          [100.2839353, 5.2607023],
          [100.2837707, 5.2602993],
          [100.2835443, 5.2596544],
          [100.2834405, 5.2592783],
          [100.2833717, 5.2590127],
          [100.2833235, 5.2587282],
          [100.2832426, 5.2585408],
          [100.2830228, 5.2582992],
          [100.2828318, 5.2580981],
          [100.2826375, 5.2578526],
          [100.2823224, 5.2576798],
          [100.281998, 5.2575808],
          [100.2816243, 5.2574993],
          [100.2809513, 5.2572674],
          [100.2803721, 5.2570032],
          [100.2795451, 5.2569144],
          [100.2790275, 5.2569882],
          [100.2782754, 5.2572836],
          [100.2777725, 5.2575298],
          [100.2774091, 5.2577001],
          [100.2768949, 5.2575705],
          [100.2763965, 5.2574926],
          [100.2760405, 5.257483],
          [100.2756685, 5.2573831],
          [100.2753383, 5.257416],
          [100.2749899, 5.2572419],
          [100.2747576, 5.2574743],
          [100.2746787, 5.2576459],
          [100.2745099, 5.2578641],
          [100.274416, 5.258248],
          [100.2743851, 5.2585487],
          [100.2743175, 5.2587174],
          [100.2741456, 5.2588071],
          [100.2741191, 5.259003],
          [100.2737832, 5.2594707],
          [100.2738442, 5.2596938],
          [100.2737179, 5.2599367],
          [100.273583, 5.2600511],
          [100.2734095, 5.260215],
          [100.2734749, 5.2604009],
          [100.2734567, 5.260657],
          [100.2733469, 5.2608051],
          [100.2732816, 5.2613086],
          [100.2732176, 5.2614959],
          [100.2731069, 5.261679],
          [100.2730127, 5.2618543],
          [100.2728999, 5.2621518],
          [100.2727018, 5.2622918],
          [100.272544, 5.2625634],
          [100.2725727, 5.2627206],
          [100.2727191, 5.2628063],
          [100.2727679, 5.2629549],
          [100.2727208, 5.2631351],
          [100.2725296, 5.2633351],
          [100.2724722, 5.2634608],
          [100.2725153, 5.2635751],
          [100.2725469, 5.2638352],
          [100.27261, 5.2639953],
          [100.2726966, 5.2641513],
          [100.2728222, 5.2643313],
          [100.2727795, 5.264463],
          [100.2728367, 5.2645183],
          [100.2727994, 5.2646727],
          [100.2726793, 5.2647878],
          [100.2724472, 5.2649684],
          [100.2723536, 5.2651125],
          [100.2723358, 5.2653284],
          [100.2721618, 5.2657886],
          [100.2719039, 5.266136],
          [100.2716769, 5.2663074],
          [100.2714764, 5.2665028],
          [100.2712079, 5.2667459],
          [100.2709962, 5.2669923],
          [100.270908, 5.2671821],
          [100.2707669, 5.2673235],
          [100.2706361, 5.2674293],
          [100.2705074, 5.267559],
          [100.2703794, 5.2676875],
          [100.2702995, 5.2677599],
          [100.2701258, 5.2677886],
          [100.2699509, 5.2678783],
          [100.2698232, 5.2680327],
          [100.2697089, 5.2681908],
          [100.2695465, 5.2682652],
          [100.2694732, 5.2685167],
          [100.2693178, 5.2686168],
          [100.2692375, 5.268734],
          [100.2693265, 5.2688112],
          [100.2692145, 5.2689827],
          [100.2690796, 5.2690599],
          [100.2689389, 5.2691143],
          [100.2688184, 5.2691284],
          [100.2688012, 5.2692285],
          [100.2686807, 5.2692856],
          [100.2686893, 5.2694314],
          [100.2688941, 5.2696213],
          [100.2688666, 5.2698509],
          [100.2687668, 5.2700459],
          [100.2687013, 5.270234],
          [100.2686185, 5.2703379],
          [100.2684934, 5.2704637],
          [100.2684228, 5.2706291],
          [100.2683349, 5.2707789],
          [100.2682319, 5.2707769],
          [100.2681273, 5.2709617],
          [100.2680756, 5.2711003],
          [100.268167, 5.2712034],
          [100.2680452, 5.2712498],
          [100.2678889, 5.2711427],
          [100.2678666, 5.2711973],
          [100.2680249, 5.2712963],
          [100.2680513, 5.271359],
          [100.2679965, 5.2714176],
          [100.2677449, 5.2713477],
          [100.2677895, 5.2715186],
          [100.2679113, 5.2714681],
          [100.2680858, 5.2715085],
          [100.2682016, 5.2716879],
          [100.2684031, 5.2720952],
          [100.2682353, 5.2721323],
          [100.2681478, 5.272209],
          [100.2681955, 5.272332],
          [100.2681128, 5.2724126],
          [100.2680595, 5.2725015],
          [100.2675418, 5.2731889],
          [100.267351, 5.2735261],
          [100.2670368, 5.2737136],
          [100.2668913, 5.2736561],
          [100.2668254, 5.2736745],
          [100.266622, 5.2738452],
          [100.2664159, 5.2737378],
          [100.2663481, 5.273714],
          [100.2662365, 5.2735911],
          [100.2663257, 5.2733822],
          [100.266439, 5.2732534],
          [100.2663986, 5.2732233],
          [100.2663502, 5.2732628],
          [100.2661376, 5.273571],
          [100.2659895, 5.2736869],
          [100.2660258, 5.2737766],
          [100.2660691, 5.2739465],
          [100.2660654, 5.2741592],
          [100.2658402, 5.2744602],
          [100.2656366, 5.2747147],
          [100.2641934, 5.2758961],
          [100.2630504, 5.2767469],
          [100.2620029, 5.2774097],
          [100.2619474, 5.2774464],
          [100.2583776, 5.2795231],
          [100.2580144, 5.2796753],
          [100.2578066, 5.2795812],
          [100.2575614, 5.2796314],
          [100.2561471, 5.2805215],
          [100.2560046, 5.2805616],
          [100.2553097, 5.2800208],
          [100.2550926, 5.279851],
          [100.2549124, 5.2799192],
          [100.2546, 5.279947],
          [100.2542172, 5.2799025],
          [100.2538148, 5.2795175],
          [100.2536807, 5.2793256],
          [100.2534998, 5.2791974],
          [100.2533542, 5.2790912],
          [100.2530127, 5.2788349],
          [100.2522727, 5.2788725],
          [100.2519257, 5.2789409],
          [100.2515619, 5.2789845],
          [100.2511762, 5.279115],
          [100.2508839, 5.2790301],
          [100.2505389, 5.2791231],
          [100.2501566, 5.2791029],
          [100.2496367, 5.2791213],
          [100.2486368, 5.2790547],
          [100.2480093, 5.2789422],
          [100.2475934, 5.2786274],
          [100.2475866, 5.2782266],
          [100.2478595, 5.2776055],
          [100.24788, 5.2771345],
          [100.247769, 5.2766266],
          [100.2473859, 5.2752678],
          [100.2474009, 5.274987],
          [100.2472716, 5.2748589],
          [100.2472509, 5.2746941],
          [100.2471216, 5.2745659],
          [100.2469933, 5.2744369],
          [100.246864, 5.2743085],
          [100.2466369, 5.2742597],
          [100.2462299, 5.2741549],
          [100.2458496, 5.2741695],
          [100.2457569, 5.2738529],
          [100.2454213, 5.2734803],
          [100.2450728, 5.2733156],
          [100.2446742, 5.2732638],
          [100.2441005, 5.2734959],
          [100.2439088, 5.2738303],
          [100.243789, 5.2740849],
          [100.2438615, 5.2744131],
          [100.2440675, 5.2747197],
          [100.2443296, 5.2749976],
          [100.2442748, 5.275442],
          [100.2441094, 5.275656],
          [100.2439993, 5.2758707],
          [100.2437369, 5.2759712],
          [100.243458, 5.2762511],
          [100.243454, 5.2765224],
          [100.2432643, 5.2766974],
          [100.2429746, 5.2768792],
          [100.2426784, 5.2770989],
          [100.2425468, 5.2772399],
          [100.2425125, 5.2774941],
          [100.2420712, 5.2780991],
          [100.2415868, 5.27874],
          [100.2411586, 5.2791327],
          [100.2409721, 5.2792132],
          [100.2407981, 5.2792585],
          [100.2406718, 5.2792861],
          [100.2405917, 5.2792738],
          [100.2404771, 5.2791978],
          [100.2404233, 5.2791534],
          [100.2403656, 5.2792006],
          [100.2403867, 5.2792591],
          [100.2405069, 5.2794103],
          [100.2406047, 5.2795959],
          [100.240644, 5.2797647],
          [100.2406394, 5.2799227],
          [100.2405686, 5.2800408],
          [100.2404761, 5.2801328],
          [100.2403236, 5.2802632],
          [100.240128, 5.2804573],
          [100.2399523, 5.2805877],
          [100.2397444, 5.2807211],
          [100.2394933, 5.2808538],
          [100.2393261, 5.2809267],
          [100.2393076, 5.2809589],
          [100.2393223, 5.2809896],
          [100.2393616, 5.2809888],
          [100.2395233, 5.2809113],
          [100.2397706, 5.2807764],
          [100.2399939, 5.2806398],
          [100.2401734, 5.2804926],
          [100.2403606, 5.28032],
          [100.24053, 5.2801911],
          [100.2405664, 5.2802396],
          [100.240675, 5.2803729],
          [100.2405853, 5.2804923],
          [100.2405538, 5.2806238],
          [100.2404775, 5.2807039],
          [100.2404511, 5.2807636],
          [100.2404714, 5.2808749],
          [100.2405161, 5.2809579],
          [100.2405527, 5.2810257],
          [100.2404958, 5.2810561],
          [100.2404755, 5.2810105],
          [100.2404257, 5.2810115],
          [100.240384, 5.2810763],
          [100.2403679, 5.2811151],
          [100.240385, 5.2811805],
          [100.2403051, 5.2813063],
          [100.2402946, 5.2814082],
          [100.2402678, 5.2815534],
          [100.2402163, 5.2816754],
          [100.2401939, 5.2816977],
          [100.2401657, 5.2817767],
          [100.2401244, 5.281882],
          [100.2398219, 5.2822298],
          [100.239488, 5.2824974],
          [100.2394543, 5.282577],
          [100.2392717, 5.282716],
          [100.2389871, 5.282957],
          [100.2387494, 5.2831794],
          [100.2383318, 5.2834873],
          [100.2380384, 5.2836977],
          [100.237534, 5.2840367],
          [100.2374665, 5.2839986],
          [100.2373895, 5.2840106],
          [100.2371707, 5.2841424],
          [100.2370964, 5.284265],
          [100.2369213, 5.2843603],
          [100.2368882, 5.2844532],
          [100.2360817, 5.2846895],
          [100.2354428, 5.2850282],
          [100.2346718, 5.2853683],
          [100.2338197, 5.2856842],
          [100.2326277, 5.2861347],
          [100.2320653, 5.2863126],
          [100.2314704, 5.2864743],
          [100.2301687, 5.2867871],
          [100.2294979, 5.2869033],
          [100.2284261, 5.2870889],
          [100.2269676, 5.2872155],
          [100.2261769, 5.2871663],
          [100.2256611, 5.2870157],
          [100.2251404, 5.2869626],
          [100.225105, 5.2869594],
          [100.2248528, 5.2869366],
          [100.2240491, 5.2866213],
          [100.2235362, 5.2863208],
          [100.2232201, 5.2859703],
          [100.2232089, 5.2858008],
          [100.2229328, 5.2853966],
          [100.2228882, 5.2849439],
          [100.2230339, 5.2846503],
          [100.2232866, 5.2844558],
          [100.2234621, 5.2842098],
          [100.2235661, 5.2842002],
          [100.2236148, 5.2843215],
          [100.2237122, 5.2843134],
          [100.223757, 5.2841571],
          [100.2238102, 5.283855],
          [100.2239964, 5.2836338],
          [100.2239268, 5.2833922],
          [100.2237203, 5.2831696],
          [100.2235184, 5.2830105],
          [100.2234159, 5.2826967],
          [100.223336, 5.2823588],
          [100.2225794, 5.2820898],
          [100.2222306, 5.2818842],
          [100.2223727, 5.2817751],
          [100.2224173, 5.2814558],
          [100.2224579, 5.2811284],
          [100.2225642, 5.2809407],
          [100.2226821, 5.2806231],
          [100.2226583, 5.2803555],
          [100.2225574, 5.2801812],
          [100.2223843, 5.2801039],
          [100.2221988, 5.2800771],
          [100.2220074, 5.280029],
          [100.2218206, 5.2798754],
          [100.2212895, 5.279901],
          [100.2211606, 5.2800305],
          [100.2210328, 5.2801592],
          [100.220978, 5.280285],
          [100.2209125, 5.280449],
          [100.2209055, 5.2805336],
          [100.2208268, 5.280596],
          [100.2196405, 5.2811418],
          [100.2184499, 5.2816107],
          [100.217047, 5.2822007],
          [100.2162303, 5.282523],
          [100.216001486707128, 5.282458298629089],
          [100.2159268, 5.282484],
          [100.2156482, 5.2823584],
          [100.2151595, 5.2822696],
          [100.2150458, 5.2821564],
          [100.2146558, 5.2820491],
          [100.214299, 5.2820616],
          [100.2138903, 5.2822268],
          [100.2136621, 5.2821219],
          [100.2132664, 5.2820371],
          [100.2128177, 5.2820842],
          [100.2123414, 5.2821809],
          [100.2119724, 5.2821551],
          [100.2118005, 5.2819973],
          [100.2117518, 5.2817689],
          [100.2118899, 5.2817632],
          [100.2118865, 5.2816178],
          [100.2118039, 5.2814497],
          [100.2114118, 5.2815296],
          [100.2104421, 5.2816331],
          [100.2099529, 5.2815791],
          [100.2096984, 5.2814679],
          [100.2095163, 5.2812705],
          [100.2094402, 5.2809109],
          [100.2094752, 5.2802029],
          [100.2093019, 5.2801367],
          [100.2091974, 5.2800104],
          [100.209053, 5.2800173],
          [100.208982, 5.2798789],
          [100.2088443, 5.2797406],
          [100.2087426, 5.2798521],
          [100.2085367, 5.2798123],
          [100.2084199, 5.2797266],
          [100.2084752, 5.2794788],
          [100.2082805, 5.2794228],
          [100.2082079, 5.2795889],
          [100.2079897, 5.2796562],
          [100.2078514, 5.2795736],
          [100.2078332, 5.2793159],
          [100.2076487, 5.2791541],
          [100.2075657, 5.2787675],
          [100.2074211, 5.2785852],
          [100.2071814, 5.278576],
          [100.2067972, 5.2784781],
          [100.2064653, 5.2785821],
          [100.2062628, 5.2787396],
          [100.2054727, 5.2788851],
          [100.205319, 5.2787657],
          [100.2051899, 5.2788025],
          [100.2050035, 5.2787815],
          [100.2048734, 5.2786892],
          [100.2047312, 5.2787146],
          [100.2046522, 5.278856],
          [100.204349, 5.278936],
          [100.2038408, 5.2788973],
          [100.2034976, 5.2787965],
          [100.2033644, 5.2787841],
          [100.2033921, 5.2786525],
          [100.2032711, 5.2786625],
          [100.20322, 5.2787565],
          [100.2030656, 5.2787524],
          [100.2029284, 5.2789515],
          [100.2025591, 5.2792592],
          [100.201794, 5.2794022],
          [100.2012526, 5.2795674],
          [100.2007952, 5.279679],
          [100.2005883, 5.279813],
          [100.2004604, 5.2799415],
          [100.2003317, 5.2800711],
          [100.1999655, 5.2806864],
          [100.1995265, 5.2808542],
          [100.1991915, 5.2807846],
          [100.1990474, 5.280847],
          [100.1989195, 5.2809757],
          [100.1987907, 5.2811052],
          [100.1986628, 5.2812339],
          [100.1985341, 5.2813635],
          [100.1984061, 5.2814922],
          [100.1983838, 5.2816408],
          [100.198161, 5.2816265],
          [100.1980448, 5.2816191],
          [100.1979738, 5.2816472],
          [100.1977606, 5.2817315],
          [100.1971508, 5.281763],
          [100.1961455, 5.2818312],
          [100.1951115, 5.28183],
          [100.194769, 5.2818166],
          [100.1943459, 5.2818001],
          [100.1928887, 5.2816671],
          [100.1921036, 5.2815319],
          [100.1915198, 5.2814291],
          [100.1911237, 5.2813386],
          [100.1907435, 5.2812247],
          [100.1901194, 5.281055],
          [100.1897535, 5.2809472],
          [100.189492, 5.2808525],
          [100.1892932, 5.2807806],
          [100.1891605, 5.2807294],
          [100.1890251, 5.2806779],
          [100.1888898, 5.2806087],
          [100.1886231, 5.280313],
          [100.1884407, 5.2799765],
          [100.1883709, 5.2797949],
          [100.1883817, 5.2795705],
          [100.1884299, 5.2792607],
          [100.1883602, 5.2788334],
          [100.1877165, 5.2786304],
          [100.1875997, 5.2782911],
          [100.1873249, 5.2778131],
          [100.1870942, 5.2773804],
          [100.1867992, 5.2771133],
          [100.1864397, 5.2770012],
          [100.1861715, 5.2767394],
          [100.1861393, 5.2765418],
          [100.1863164, 5.2763815],
          [100.1864381, 5.2762312],
          [100.186252, 5.2759809],
          [100.1860911, 5.2756551],
          [100.1858121, 5.2754147],
          [100.1855278, 5.2750515],
          [100.185619, 5.2746722],
          [100.1855224, 5.2740205],
          [100.1854581, 5.2735397],
          [100.1856609, 5.2731396],
          [100.1859176, 5.2728812],
          [100.1859161, 5.272108],
          [100.1859151, 5.2715924],
          [100.1858443, 5.2710932],
          [100.1860255, 5.2706783],
          [100.1860463, 5.2704365],
          [100.1861693, 5.2700453],
          [100.1864254, 5.2695293],
          [100.1865899, 5.2689102],
          [100.1865894, 5.2686526],
          [100.1865522, 5.2683686],
          [100.186423, 5.2682403],
          [100.1862946, 5.2681112],
          [100.1860796, 5.2680899],
          [100.1859087, 5.2674822],
          [100.1859057, 5.2666947],
          [100.1857773, 5.2665656],
          [100.1853222, 5.2666256],
          [100.1848777, 5.2669545],
          [100.1847497, 5.2670831],
          [100.184621, 5.2672127],
          [100.1844931, 5.2673414],
          [100.184038, 5.2676906],
          [100.1838511, 5.2679874],
          [100.1837231, 5.2681161],
          [100.1835622, 5.268593],
          [100.1832264, 5.2688859],
          [100.182969, 5.269245],
          [100.1827061, 5.2691488],
          [100.1824605, 5.2692669],
          [100.1823116, 5.2697948],
          [100.1823554, 5.2698754],
          [100.1822588, 5.2701331],
          [100.1820557, 5.2703313],
          [100.181868, 5.2705999],
          [100.1817451, 5.2711141],
          [100.1817296, 5.2715722],
          [100.181859, 5.2719234],
          [100.1816076, 5.2720957],
          [100.1815875, 5.2722694],
          [100.1817919, 5.2723713],
          [100.1818193, 5.2729728],
          [100.1819857, 5.273275],
          [100.182002, 5.2734035],
          [100.1820719, 5.2734523],
          [100.182054, 5.2735169],
          [100.181987, 5.2737531],
          [100.1819933, 5.2741832],
          [100.1819471, 5.2749525],
          [100.181932194741961, 5.27497395799952],
          [100.1819256, 5.2750593],
          [100.1816796, 5.2753376],
          [100.1815509, 5.2754673],
          [100.1812946, 5.2759833],
          [100.1810349, 5.2770801],
          [100.1807998, 5.277557],
          [100.1803986, 5.2777893],
          [100.1798845, 5.2779188],
          [100.1797556, 5.2780485],
          [100.1796278, 5.278177],
          [100.179114, 5.2784359],
          [100.1789852, 5.2785654],
          [100.178729, 5.2790813],
          [100.1786012, 5.2792101],
          [100.1784723, 5.2793397],
          [100.1782162, 5.2798557],
          [100.1779605, 5.2806294],
          [100.1777053, 5.281661],
          [100.1777057, 5.2819188],
          [100.1777063, 5.2821766],
          [100.1777067, 5.2824344],
          [100.1779654, 5.2832072],
          [100.1779492, 5.2836317],
          [100.1777789, 5.2836942],
          [100.1777129, 5.2840062],
          [100.1779342, 5.2845537],
          [100.178108, 5.2847113],
          [100.1783543, 5.2847014],
          [100.1789979, 5.2852675],
          [100.1791348, 5.2855747],
          [100.1793472, 5.2858325],
          [100.1795352, 5.2860913],
          [100.1799797, 5.2870095],
          [100.1800288, 5.2871812],
          [100.1800308, 5.2875854],
          [100.18008, 5.2881491],
          [100.1800701, 5.2888999],
          [100.1801744, 5.2897457],
          [100.1806183, 5.2901383],
          [100.1807991, 5.2906816],
          [100.1810555, 5.2909802],
          [100.1813892, 5.291376],
          [100.1817674, 5.2918458],
          [100.1820139, 5.2921542],
          [100.182354, 5.292221],
          [100.1826118, 5.2924782],
          [100.1828698, 5.2929933],
          [100.1831285, 5.2937661],
          [100.183422, 5.2941971],
          [100.1840807, 5.2946361],
          [100.1840653, 5.2957622],
          [100.1841167, 5.2963671],
          [100.184283, 5.297895],
          [100.1843674, 5.2984823],
          [100.1844251, 5.2990212],
          [100.1845705, 5.300108],
          [100.1844359, 5.3004018],
          [100.1840202, 5.300757],
          [100.1838775, 5.3011234],
          [100.1840266, 5.3013782],
          [100.1837283, 5.3018659],
          [100.1835084, 5.3024214],
          [100.1831602, 5.3026137],
          [100.1831467, 5.3033036],
          [100.1830969, 5.3037189],
          [100.1829312, 5.3042097],
          [100.1826758, 5.3044907],
          [100.1824596, 5.304535],
          [100.1822005, 5.3048496],
          [100.1819932, 5.3051102],
          [100.1823996, 5.3050446],
          [100.1826056, 5.3046995],
          [100.1827641, 5.3047216],
          [100.1829913, 5.3051269],
          [100.1832471, 5.3050419],
          [100.1837487, 5.305113],
          [100.1845609, 5.3057924],
          [100.1846607, 5.3060451],
          [100.184824, 5.3061359],
          [100.1850814, 5.3062639],
          [100.1853391, 5.3065211],
          [100.1856284, 5.306756],
          [100.1860683, 5.3072474],
          [100.1864073, 5.3075951],
          [100.1866278, 5.3078798],
          [100.1870521, 5.3080849],
          [100.1874861, 5.3080689],
          [100.1876513, 5.3077479],
          [100.1879308, 5.307828],
          [100.1879308, 5.3080844],
          [100.1881714, 5.30832],
          [100.1884292, 5.3087126],
          [100.1890895, 5.3090053],
          [100.1896056, 5.3092814],
          [100.1902064, 5.309157],
          [100.1904006, 5.3091089],
          [100.1909011, 5.3090897],
          [100.1912042, 5.3092141],
          [100.1920783, 5.309194],
          [100.1924061, 5.3092221],
          [100.1925817, 5.30903],
          [100.1925986, 5.308583],
          [100.1926547, 5.3080026],
          [100.192816, 5.3080451],
          [100.1928891, 5.3080148],
          [100.1930443, 5.3078026],
          [100.1933271, 5.3074311],
          [100.1934216, 5.3075021],
          [100.19344, 5.3077208],
          [100.1933852, 5.3080148],
          [100.1933365, 5.308142],
          [100.1933608, 5.3081693],
          [100.1934065, 5.3081481],
          [100.1934795, 5.3078329],
          [100.1934826, 5.3076359],
          [100.1934339, 5.3072995],
          [100.1934948, 5.3071268],
          [100.1935286, 5.3068648],
          [100.1935678, 5.3067237],
          [100.1937687, 5.3066449],
          [100.194073, 5.3066116],
          [100.1941613, 5.3066904],
          [100.1940893, 5.3068236],
          [100.1940335, 5.3069268],
          [100.1939422, 5.3068874],
          [100.1938904, 5.3069116],
          [100.1938387, 5.3070389],
          [100.1938935, 5.3070904],
          [100.1939604, 5.3072117],
          [100.1938811, 5.3075861],
          [100.1939429, 5.3076046],
          [100.1939842, 5.3074433],
          [100.1942379, 5.3073911],
          [100.1944724, 5.307397],
          [100.1948587, 5.3076116],
          [100.1952868, 5.3080047],
          [100.1956145, 5.3082863],
          [100.1960506, 5.3087489],
          [100.1962859, 5.3090896],
          [100.1964152, 5.3094446],
          [100.1967209, 5.3108761],
          [100.1958144, 5.3136482],
          [100.1961523, 5.316399],
          [100.1963615, 5.3166287],
          [100.196206, 5.3204638],
          [100.1963937, 5.321703],
          [100.1960718, 5.3218899],
          [100.1956373, 5.3240157],
          [100.1955698, 5.327264],
          [100.1955682, 5.3273388],
          [100.1955664, 5.3274273],
          [100.1954925, 5.3309807],
          [100.195049, 5.333008],
          [100.1952548, 5.3344977],
          [100.1955245, 5.3368807],
          [100.1951457, 5.3380013],
          [100.1948421, 5.3379136],
          [100.1946762, 5.3381898],
          [100.1945767, 5.3383556],
          [100.1939546, 5.3413697],
          [100.1929076, 5.3485572],
          [100.1930237, 5.3492881],
          [100.1932114, 5.3496476],
          [100.1931602, 5.3499059],
          [100.1931039, 5.35019],
          [100.1927051, 5.3502739],
          [100.1923924, 5.3504935],
          [100.1921699, 5.3508039],
          [100.190828, 5.3549055],
          [100.1904596, 5.3554403],
          [100.1897319, 5.3586019],
          [100.1893714, 5.3592081],
          [100.1891302, 5.3602185],
          [100.189075, 5.3613978],
          [100.1887152, 5.3624925],
          [100.1889123, 5.3632182],
          [100.1887522, 5.3647913],
          [100.1884026, 5.36618],
          [100.1883127, 5.3669253],
          [100.1882714, 5.3672707],
          [100.1881331, 5.3684297],
          [100.1877621, 5.3691838],
          [100.1872606, 5.369465],
          [100.187197897481667, 5.369872331619263],
          [100.1868733, 5.371981],
          [100.1862061, 5.3740085],
          [100.1855734, 5.3762461],
          [100.1851351, 5.3780362],
          [100.1847156, 5.3789381],
          [100.184048, 5.3808325],
          [100.1839119, 5.3824988],
          [100.1840888, 5.3830429],
          [100.1840139, 5.3844519],
          [100.184115, 5.3853659],
          [100.1845249, 5.3862882],
          [100.1848407, 5.3876364],
          [100.1849083, 5.3879251],
          [100.1850319, 5.3887227],
          [100.1860762, 5.3898464],
          [100.1868648, 5.3910088],
          [100.1870148, 5.3910704],
          [100.1868452, 5.3923941],
          [100.1860926, 5.3923195],
          [100.1858045, 5.392549],
          [100.1854933, 5.3931685],
          [100.1852895, 5.3942687],
          [100.1856221, 5.3967467],
          [100.1883579, 5.4075774],
          [100.1876606, 5.4070434],
          [100.1877464, 5.4080047],
          [100.189048, 5.4120855],
          [100.1891519, 5.4136015],
          [100.1898975, 5.4148202],
          [100.1901121, 5.414959],
          [100.1918134, 5.415119],
          [100.1925299, 5.4152561],
          [100.1929011, 5.4153587],
          [100.1933573, 5.4153329],
          [100.1936823, 5.4148723],
          [100.1949956, 5.4146773],
          [100.195654, 5.4146197],
          [100.1942937, 5.4148665],
          [100.1937944, 5.414981],
          [100.1934217, 5.4155082],
          [100.1914258, 5.4152645],
          [100.1908284, 5.4154582],
          [100.190325, 5.415767],
          [100.1896484, 5.41619],
          [100.1891303, 5.4169484],
          [100.1891528, 5.4177385],
          [100.1895245, 5.4179953],
          [100.18957, 5.4191433],
          [100.18979, 5.4191811],
          [100.1900176, 5.4195965],
          [100.1896156, 5.4200798],
          [100.1895473, 5.4210239],
          [100.1896307, 5.4224135],
          [100.1899342, 5.4230555],
          [100.1899196, 5.4236037],
          [100.189474, 5.4236603],
          [100.189128, 5.4238497],
          [100.188828, 5.423741],
          [100.188568, 5.4240467],
          [100.1885819, 5.424184],
          [100.1885193, 5.4244364],
          [100.1885005, 5.4247348],
          [100.1886396, 5.4249568],
          [100.1887434, 5.4253619],
          [100.1886663, 5.4256438],
          [100.1887689, 5.4258488],
          [100.1887375, 5.4261896],
          [100.1888931, 5.4265976],
          [100.1889438, 5.4268959],
          [100.1890213, 5.4273665],
          [100.1891697, 5.4274386],
          [100.1891385, 5.4276283],
          [100.1892318, 5.4278418],
          [100.189223, 5.4280535],
          [100.1890904, 5.4287654],
          [100.1889363, 5.4288482],
          [100.188945, 5.4292432],
          [100.1888211, 5.4294579],
          [100.1886423, 5.4298156],
          [100.1882782, 5.4301171],
          [100.1880989, 5.4304345],
          [100.1878938, 5.4306334],
          [100.1877592, 5.4305033],
          [100.1876857, 5.4302456],
          [100.1875501, 5.430202],
          [100.187356, 5.4300921],
          [100.1870535, 5.4300406],
          [100.1869364, 5.4302329],
          [100.1863697, 5.4300244],
          [100.1860459, 5.4301267],
          [100.1860926, 5.4302498],
          [100.1859524, 5.4303907],
          [100.1858265, 5.4304735],
          [100.1856338, 5.4304881],
          [100.1855658, 5.430319],
          [100.1852752, 5.430238],
          [100.1853642, 5.4303607],
          [100.1852384, 5.4305097],
          [100.1850849, 5.4304177],
          [100.1849486, 5.4302639],
          [100.1848672, 5.4301714],
          [100.1846549, 5.4301381],
          [100.1845969, 5.430305],
          [100.1847192, 5.4303702],
          [100.1847184, 5.4305291],
          [100.1844719, 5.4305712],
          [100.1841405, 5.4304061],
          [100.183924, 5.4302771],
          [100.1838515, 5.4304331],
          [100.1837156, 5.4305416],
          [100.1835739, 5.4305795],
          [100.1833704, 5.4304317],
          [100.1833862, 5.4303153],
          [100.1832612, 5.4301711],
          [100.1830882, 5.4301622],
          [100.182892, 5.4302919],
          [100.1828751, 5.4304424],
          [100.1827803, 5.4305532],
          [100.1829034, 5.4306192],
          [100.1828829, 5.4307834],
          [100.1824684, 5.4311501],
          [100.1822149, 5.4312614],
          [100.181722, 5.4312895],
          [100.1812079, 5.4315482],
          [100.1810792, 5.4316779],
          [100.1809512, 5.4318066],
          [100.1807443, 5.4318862],
          [100.1804333, 5.4320685],
          [100.1801887, 5.4321965],
          [100.1798668, 5.4322803],
          [100.1796557, 5.4324731],
          [100.1792497, 5.4325663],
          [100.1790369, 5.4324381],
          [100.1787264, 5.4323461],
          [100.1784947, 5.4323826],
          [100.1785784, 5.4325504],
          [100.1784457, 5.4327043],
          [100.1782304, 5.4326697],
          [100.1780096, 5.4325842],
          [100.1774852, 5.4327672],
          [100.177178, 5.4331839],
          [100.1770632, 5.4334047],
          [100.1768922, 5.4336785],
          [100.1768172, 5.4342809],
          [100.1770236, 5.4344745],
          [100.1772849, 5.4348699],
          [100.177539, 5.4350695],
          [100.1780288, 5.4351559],
          [100.1781015, 5.4352658],
          [100.1782929, 5.4351973],
          [100.1785656, 5.4350943],
          [100.1786505, 5.4351088],
          [100.1788375, 5.4352857],
          [100.1789225, 5.435459],
          [100.1790205, 5.4357992],
          [100.1790981, 5.4362739],
          [100.1791426, 5.4365032],
          [100.1793082, 5.4368078],
          [100.1795121, 5.4370123],
          [100.1795889, 5.4371819],
          [100.1796202, 5.4374289],
          [100.1797228, 5.4376832],
          [100.1797741, 5.437857],
          [100.1798658, 5.4380965],
          [100.1802179, 5.4389114],
          [100.1807196, 5.4399695],
          [100.1809143, 5.4407178],
          [100.180928, 5.4414715],
          [100.1808526, 5.4420752],
          [100.1805547, 5.4425393],
          [100.1803524, 5.4427269],
          [100.1800856, 5.4428556],
          [100.179796, 5.4428956],
          [100.1796538, 5.4429911],
          [100.1795598, 5.4432247],
          [100.1793196, 5.443342],
          [100.1788462, 5.44312],
          [100.1784012, 5.4431534],
          [100.1782723, 5.443283],
          [100.1777469, 5.4438459],
          [100.1777061, 5.4446553],
          [100.1780714, 5.4452271],
          [100.1783566, 5.4460309],
          [100.1785967, 5.4463636],
          [100.1788473, 5.4471129],
          [100.1791556, 5.447566],
          [100.1792492, 5.4481075],
          [100.1794636, 5.4484912],
          [100.1797083, 5.4487188],
          [100.1800347, 5.4489591],
          [100.1803781, 5.4490771],
          [100.1807306, 5.449089],
          [100.1814242, 5.4491492],
          [100.1816384, 5.449142],
          [100.1818005, 5.4493226],
          [100.1820344, 5.4497755],
          [100.1821575, 5.4500425],
          [100.1826677, 5.4509479],
          [100.1828301, 5.4512947],
          [100.1831982, 5.4520081],
          [100.1839376, 5.4531779],
          [100.1838252, 5.4533568],
          [100.1837494, 5.4535073],
          [100.1837998, 5.4537057],
          [100.1837714, 5.45394],
          [100.1839681, 5.4541505],
          [100.1839338, 5.4543749],
          [100.1838704, 5.4546526],
          [100.1838147, 5.4549949],
          [100.1837612, 5.4554271],
          [100.1837255, 5.4559218],
          [100.1835344, 5.4560412],
          [100.1833948, 5.4562337],
          [100.183329, 5.4565731],
          [100.1831325, 5.4566325],
          [100.1829883, 5.4567799],
          [100.1827621, 5.4569493],
          [100.182588, 5.4571936],
          [100.1824259, 5.4574949],
          [100.1823789, 5.4577516],
          [100.1822042, 5.4579072],
          [100.1822396, 5.4582186],
          [100.1822168, 5.4587512],
          [100.1822715, 5.4588617],
          [100.1821114, 5.4591685],
          [100.1820052, 5.4594806],
          [100.1819753, 5.4596582],
          [100.1819325, 5.459792],
          [100.1818045, 5.4599207],
          [100.1815996, 5.4601176],
          [100.1814276, 5.460554],
          [100.1814068, 5.4610882],
          [100.1813233, 5.461265],
          [100.1813299, 5.4620683],
          [100.1812501, 5.4624119],
          [100.1812921, 5.4625751],
          [100.1814007, 5.4625789],
          [100.1813856, 5.4629091],
          [100.1818618, 5.463616],
          [100.1816468, 5.4640214],
          [100.1816602, 5.4641948],
          [100.1817998, 5.4642999],
          [100.1817981, 5.4644803],
          [100.181901, 5.4645726],
          [100.1819169, 5.4651401],
          [100.1818134, 5.4656378],
          [100.1816916, 5.46567],
          [100.1814876, 5.4659209],
          [100.1811639, 5.4661124],
          [100.180879, 5.4663419],
          [100.1807035, 5.4665561],
          [100.1804011, 5.4666666],
          [100.1797714, 5.4668339],
          [100.1795949, 5.466846],
          [100.179485, 5.466771],
          [100.1794569, 5.4668729],
          [100.1795135, 5.4669532],
          [100.179242, 5.4671668],
          [100.1789505, 5.4674416],
          [100.1788041, 5.4674927],
          [100.1785981, 5.4674906],
          [100.1784232, 5.467291],
          [100.1783348, 5.4670181],
          [100.1781972, 5.4669272],
          [100.177933, 5.4669189],
          [100.1777702, 5.4670149],
          [100.1778284, 5.4671728],
          [100.1780266, 5.4672332],
          [100.178149, 5.4672408],
          [100.1779133, 5.4674701],
          [100.1775059, 5.4676333],
          [100.1771203, 5.467669],
          [100.1771581, 5.4678821],
          [100.177207, 5.4680962],
          [100.1770822, 5.4681326],
          [100.1771372, 5.468237],
          [100.1772508, 5.4683024],
          [100.1772791, 5.4685385],
          [100.1772319, 5.4688014],
          [100.1772223, 5.4689765],
          [100.1770861, 5.4691653],
          [100.1769704, 5.4693441],
          [100.1768698, 5.469575],
          [100.1767192, 5.4697271],
          [100.1762931, 5.469856],
          [100.1761294, 5.4699344],
          [100.1760217, 5.4700177],
          [100.1759465, 5.4701714],
          [100.1758374, 5.4702186],
          [100.1756545, 5.4704517],
          [100.1755759, 5.4706477],
          [100.1753183, 5.4710919],
          [100.1752005, 5.4716028],
        ],
      ],
    ],
  },
};
