import React from "react";
import styled from "styled-components";

import Settings from "./assets/settings.svg";
import Menu from "./assets/menu.svg";

const sizeChanger = (size) => {
  switch (size) {
    case "sm":
      return `
         width: 18px;
         height: 18px;
    `;
    case "md":
      return `
         width: 24px;
         height: 24px;
    `;
    case "lg":
      return `
         width: 32px;
         height: 32px;
    `;
    default:
      return `
         width: 24px;
         height: 24px;
    `;
  }
};

const iconChanger = (icon) => {
  switch (icon) {
    case "settings":
      return Settings;
    case "menu":
      return Menu;
    default:
      return "";
  }
};

const IconHolder = styled.div`
  ${({ size }) => sizeChanger(size)};
  background-color: ${({ theme }) => theme.text};
  mask: url(${({ name }) => iconChanger(name)}) no-repeat center / contain;
  -webkit-mask: url(${({ name }) => iconChanger(name)}) no-repeat center /
    contain;
  cursor: pointer;
  opacity: 1;

  :hover {
    opacity: 0.75;
  }
`;

const Icon = ({ size, name }) => {
  return <IconHolder size={size} name={name} />;
};

export default Icon;
