import React from "react";
import styled from "styled-components";
import { Button as MButton } from "@material-ui/core";

const CustomButton = styled(MButton)`
  text-transform: capitalize;
`;

const Button = ({ name, click }) => {
  return <CustomButton onClick={click}>{name}</CustomButton>;
};

export default Button;
