export const melacca = {
  type: "Feature",
  properties: {
    gid: 6,
    id: 2939673,
    country: "MYS",
    name: "Malacca",
    enname: "Malacca",
    locname: "Melaka",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q185221",
    wikimedia: "ms:Melaka",
    timestamp: "2019-12-03 22:59:01",
    note: null,
    path: "0,2108121,2939673",
    rpath: "2939673,2108121,0",
    iso3166_2: "MY-04",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [102.304883, 2.0795868],
          [102.3051012, 2.0795977],
          [102.305243, 2.0796795],
          [102.305483, 2.0798103],
          [102.3056903, 2.0798866],
          [102.3058976, 2.0798866],
          [102.3061266, 2.079794],
          [102.3062957, 2.079734],
          [102.3063066, 2.0796195],
          [102.3062085, 2.0795541],
          [102.3060885, 2.0794996],
          [102.3059794, 2.0792707],
          [102.3057503, 2.0790636],
          [102.3053903, 2.0789436],
          [102.3050467, 2.0788782],
          [102.3047249, 2.0789382],
          [102.3044358, 2.0791181],
          [102.3042885, 2.0792053],
          [102.3043267, 2.0793743],
          [102.3044303, 2.0794506],
          [102.3046158, 2.0795214],
          [102.304883, 2.0795868],
        ],
      ],
      [
        [
          [101.856720983020637, 2.416523909010285],
          [101.856721, 2.4165239],
          [101.859478797443444, 2.415060454422393],
          [101.8588051, 2.4141246],
          [101.8586549, 2.413444],
          [101.8591312, 2.4124796],
          [101.8594436, 2.4109901],
          [101.8586048, 2.4092429],
          [101.8581104, 2.4089398],
          [101.8576159, 2.4085807],
          [101.8565819, 2.4074436],
          [101.8561269, 2.4064184],
          [101.8561214, 2.405823],
          [101.8566764, 2.4054516],
          [101.857892, 2.4047295],
          [101.8579924, 2.4044367],
          [101.8569231, 2.4044899],
          [101.856078, 2.4042817],
          [101.8551629, 2.4044627],
          [101.8546121, 2.4042333],
          [101.8541361, 2.4039013],
          [101.8539176, 2.4036249],
          [101.8539156, 2.4035452],
          [101.8539045, 2.403106],
          [101.8537334, 2.402935],
          [101.8535623, 2.4029319],
          [101.8535196, 2.4031908],
          [101.8536571, 2.4035209],
          [101.853539, 2.4037513],
          [101.8536077, 2.404047],
          [101.8527059, 2.4046665],
          [101.8513577, 2.4061696],
          [101.849973, 2.4086291],
          [101.8493239, 2.4086815],
          [101.8491495, 2.4088677],
          [101.8495175, 2.4096949],
          [101.8477296, 2.4119432],
          [101.8485025, 2.4122117],
          [101.8500327, 2.4114647],
          [101.8503372, 2.4113979],
          [101.8515617, 2.413124],
          [101.8518616, 2.4139645],
          [101.8523545, 2.4149015],
          [101.8524842, 2.415278],
          [101.8533168, 2.4145226],
          [101.8539134, 2.4142925],
          [101.8543341, 2.4139019],
          [101.8546835, 2.413742],
          [101.8552474, 2.4143003],
          [101.8561393, 2.4155295],
          [101.8565682, 2.416224],
          [101.856720983020637, 2.416523909010285],
        ],
      ],
      [
        [
          [102.2456493, 2.1829898],
          [102.245819, 2.1826163],
          [102.2457106, 2.1822574],
          [102.2447413, 2.1813972],
          [102.2459749, 2.1810111],
          [102.2471679, 2.1806047],
          [102.2473847, 2.1808892],
          [102.246951, 2.1811398],
          [102.2463206, 2.1811805],
          [102.2460698, 2.1814649],
          [102.245941, 2.1817697],
          [102.2461715, 2.1822032],
          [102.2466496, 2.1823031],
          [102.2473014, 2.1820631],
          [102.248646, 2.1824972],
          [102.2491451, 2.1825991],
          [102.2500741, 2.182002],
          [102.2530302, 2.1810299],
          [102.253777, 2.180627],
          [102.255906, 2.1801931],
          [102.2575597, 2.1799587],
          [102.2578797, 2.1791222],
          [102.2583133, 2.1786225],
          [102.2579544, 2.1779018],
          [102.2578252, 2.1773143],
          [102.2571504, 2.1769193],
          [102.2567788, 2.1765815],
          [102.2567351, 2.1757707],
          [102.2563412, 2.1758648],
          [102.2559848, 2.1758466],
          [102.2553408, 2.1750861],
          [102.2552827, 2.1756396],
          [102.2554278, 2.1760639],
          [102.2556539, 2.1765142],
          [102.2554684, 2.1768101],
          [102.2551296, 2.1770618],
          [102.254817, 2.1771273],
          [102.2540537, 2.1770797],
          [102.2537515, 2.1771529],
          [102.2533662, 2.1772236],
          [102.2529538, 2.1773075],
          [102.2523731, 2.1773522],
          [102.2516376, 2.1776322],
          [102.2511838, 2.1775689],
          [102.2505082, 2.1779428],
          [102.2499766, 2.1782165],
          [102.2498684, 2.1784375],
          [102.2499855, 2.1787638],
          [102.2488849, 2.1792285],
          [102.2474949, 2.1795285],
          [102.2462543, 2.1799562],
          [102.2453146, 2.1802594],
          [102.2441683, 2.1805441],
          [102.243806, 2.1809502],
          [102.2437518, 2.1815801],
          [102.2440582, 2.1820924],
          [102.2444108, 2.1825142],
          [102.2446582, 2.1829394],
          [102.2449845, 2.1831997],
          [102.2453195, 2.1832011],
          [102.2456493, 2.1829898],
        ],
      ],
      [
        [
          [102.2035273, 2.1946085],
          [102.203997, 2.1943403],
          [102.2042079, 2.1939332],
          [102.2042084, 2.1936712],
          [102.2042079, 2.1934495],
          [102.2039299, 2.1930568],
          [102.2037525, 2.1928509],
          [102.2037286, 2.1926641],
          [102.2036567, 2.1924246],
          [102.2034123, 2.192099],
          [102.2032637, 2.1919505],
          [102.2030959, 2.1919218],
          [102.2029234, 2.1920319],
          [102.2028228, 2.1921756],
          [102.2027892, 2.1924103],
          [102.202818, 2.1926833],
          [102.2027844, 2.1928078],
          [102.2026886, 2.1929036],
          [102.2026119, 2.1931334],
          [102.2025112, 2.193234],
          [102.2024537, 2.1933681],
          [102.2025304, 2.1934926],
          [102.2026598, 2.193665],
          [102.2026454, 2.1938422],
          [102.2026886, 2.1939763],
          [102.2029138, 2.1941152],
          [102.2031151, 2.1942781],
          [102.2032301, 2.1944792],
          [102.2035273, 2.1946085],
        ],
      ],
      [
        [
          [102.341372, 2.1165003],
          [102.3412939, 2.1163467],
          [102.3410997, 2.1161871],
          [102.3410037, 2.1160082],
          [102.3409862, 2.1159989],
          [102.3408867, 2.1159459],
          [102.3407898, 2.1158869],
          [102.3406946, 2.1158669],
          [102.3405299, 2.1158611],
          [102.3402772, 2.1158611],
          [102.3401837, 2.1159984],
          [102.3400441, 2.1160142],
          [102.3398767, 2.1161161],
          [102.3397673, 2.1161192],
          [102.3396986, 2.1161527],
          [102.3398336, 2.1163537],
          [102.339963, 2.1163599],
          [102.3403389, 2.1164399],
          [102.3405915, 2.1166308],
          [102.3406934, 2.116738],
          [102.340865, 2.116663],
          [102.341372, 2.1165003],
        ],
      ],
      [
        [
          [102.3155218, 2.1211848],
          [102.3158555, 2.1209989],
          [102.3161397, 2.1208192],
          [102.3162617, 2.1207542],
          [102.3161966, 2.1206079],
          [102.3160421, 2.1204942],
          [102.3161479, 2.1203235],
          [102.3159202, 2.1201692],
          [102.3156925, 2.1201042],
          [102.3154405, 2.1201204],
          [102.3151559, 2.1201448],
          [102.3149394, 2.1202564],
          [102.3148046, 2.1203546],
          [102.3146834, 2.1205168],
          [102.3145355, 2.120652],
          [102.314289, 2.120733],
          [102.3141401, 2.1208925],
          [102.3140157, 2.121047],
          [102.3138956, 2.1212173],
          [102.3138956, 2.1213636],
          [102.3138956, 2.1215667],
          [102.313954, 2.1216973],
          [102.314107, 2.1216561],
          [102.3142794, 2.1217558],
          [102.3143672, 2.1218674],
          [102.314481, 2.122038],
          [102.3148062, 2.1219405],
          [102.3151884, 2.1216561],
          [102.3153673, 2.121388],
          [102.3155218, 2.1211848],
        ],
      ],
      [
        [
          [102.3194656, 2.1112403],
          [102.3193496, 2.1113999],
          [102.3191239, 2.111594],
          [102.3191106, 2.111922],
          [102.3193357, 2.1121236],
          [102.3199196, 2.112128],
          [102.3204691, 2.1125797],
          [102.3205894, 2.1129671],
          [102.3205708, 2.1138966],
          [102.3220449, 2.1138335],
          [102.3199938, 2.1161528],
          [102.320179, 2.1162717],
          [102.3221542, 2.114196],
          [102.3224546, 2.1143234],
          [102.3226626, 2.1145555],
          [102.3229126, 2.1149902],
          [102.3228506, 2.1156585],
          [102.3234716, 2.1162496],
          [102.3238476, 2.1163531],
          [102.3247074, 2.1173545],
          [102.3252826, 2.117379],
          [102.3257694, 2.1171352],
          [102.3263944, 2.1162821],
          [102.3266647, 2.1160444],
          [102.3269829, 2.1160921],
          [102.3271546, 2.1166396],
          [102.3275855, 2.1162841],
          [102.3275489, 2.1160089],
          [102.3278477, 2.1157616],
          [102.3281191, 2.1153373],
          [102.3284189, 2.1153173],
          [102.3289484, 2.115386],
          [102.3290841, 2.1154036],
          [102.3292744, 2.1156316],
          [102.3295541, 2.1154777],
          [102.3314211, 2.115691],
          [102.3327523, 2.1158677],
          [102.333423, 2.1157661],
          [102.3341731, 2.1158047],
          [102.3346708, 2.1158477],
          [102.3349402, 2.1154923],
          [102.3350051, 2.1153532],
          [102.3350862, 2.1153522],
          [102.3350826, 2.1153173],
          [102.335077, 2.1152622],
          [102.3350715, 2.115209],
          [102.3347689, 2.1152004],
          [102.3345357, 2.1148395],
          [102.3343576, 2.1142253],
          [102.3343572, 2.1134304],
          [102.3343169, 2.1126296],
          [102.3344245, 2.1120905],
          [102.3340603, 2.1117309],
          [102.3334434, 2.1113097],
          [102.3315084, 2.1096522],
          [102.3302401, 2.1086326],
          [102.3299271, 2.1083604],
          [102.3296201, 2.1079684],
          [102.3296414, 2.1077562],
          [102.3298879, 2.1076663],
          [102.3298498, 2.107097],
          [102.3290172, 2.1068284],
          [102.3288055, 2.1064411],
          [102.3294574, 2.1060539],
          [102.3294021, 2.1059585],
          [102.3285439, 2.1064186],
          [102.3280469, 2.105927],
          [102.3277816, 2.1052506],
          [102.3280611, 2.1046757],
          [102.3279514, 2.1040989],
          [102.3282807, 2.103457],
          [102.3280124, 2.1030619],
          [102.3276668, 2.1028903],
          [102.3262064, 2.1029423],
          [102.3254128, 2.1031569],
          [102.3249394, 2.1036591],
          [102.3247826, 2.1047089],
          [102.3250701, 2.1056644],
          [102.3254781, 2.1060041],
          [102.3254511, 2.1067987],
          [102.3250237, 2.1076821],
          [102.3254524, 2.1083838],
          [102.3253069, 2.10895],
          [102.3242996, 2.1098168],
          [102.323776, 2.1099437],
          [102.3232605, 2.1097943],
          [102.3231491, 2.1098244],
          [102.3226333, 2.1093408],
          [102.3225461, 2.1093437],
          [102.3225001, 2.109386],
          [102.3226176, 2.1094003],
          [102.3230691, 2.109863],
          [102.3230094, 2.1099536],
          [102.3228919, 2.1102608],
          [102.3218981, 2.1114707],
          [102.3211076, 2.1114455],
          [102.3206362, 2.1118609],
          [102.3194656, 2.1112403],
        ],
      ],
      [
        [
          [101.9734161, 2.3910722],
          [101.9761387, 2.3899271],
          [101.9781557, 2.3908275],
          [101.9801191, 2.3930036],
          [101.9820514, 2.3958682],
          [101.9830803, 2.397345],
          [101.9846145, 2.3983633],
          [101.9869105, 2.398642],
          [101.9906441, 2.3989636],
          [101.9933799, 2.3996925],
          [101.9965771, 2.4015148],
          [101.9986156, 2.4034229],
          [102.0008579, 2.4054381],
          [102.0033148, 2.4067352],
          [102.0049993, 2.4073676],
          [102.0079497, 2.4082144],
          [102.0112327, 2.4088469],
          [102.0130352, 2.4101439],
          [102.0140222, 2.411248],
          [102.0164899, 2.4154929],
          [102.017434, 2.4170257],
          [102.0192901, 2.419384],
          [102.0219294, 2.4218816],
          [102.0238391, 2.4236074],
          [102.0260707, 2.4256869],
          [102.0273474, 2.4277557],
          [102.0278624, 2.4301354],
          [102.0276797, 2.431915],
          [102.0305414, 2.4338287],
          [102.0326471, 2.4336623],
          [102.0342231, 2.4327773],
          [102.035598, 2.4299642],
          [102.0349194, 2.4272242],
          [102.0349477, 2.4257835],
          [102.0388778, 2.4221959],
          [102.0410548, 2.4206423],
          [102.0440687, 2.4208309],
          [102.0456069, 2.420727],
          [102.0471337, 2.4193993],
          [102.0486206, 2.4197917],
          [102.0513465, 2.4216027],
          [102.0515726, 2.4232411],
          [102.0509177, 2.4247103],
          [102.0498904, 2.4256846],
          [102.0492118, 2.4268711],
          [102.0492259, 2.4281987],
          [102.0506084, 2.4299662],
          [102.0509506, 2.4311366],
          [102.0511061, 2.4330433],
          [102.0522654, 2.4341732],
          [102.0540607, 2.4348088],
          [102.0562944, 2.4351054],
          [102.0573688, 2.4349077],
          [102.0590652, 2.4342156],
          [102.0599841, 2.4341167],
          [102.060903, 2.4345546],
          [102.061737, 2.435176],
          [102.0624863, 2.4362142],
          [102.062967, 2.4373229],
          [102.063384, 2.4387071],
          [102.0644703, 2.4405042],
          [102.0652642, 2.4421675],
          [102.064939, 2.4433186],
          [102.0635112, 2.4435657],
          [102.061836, 2.4419768],
          [102.0605778, 2.4423652],
          [102.0601184, 2.443481],
          [102.0602976, 2.4447515],
          [102.061857, 2.4469108],
          [102.0623591, 2.4483608],
          [102.0624163, 2.449508],
          [102.061197, 2.4505692],
          [102.0609815, 2.4518855],
          [102.0615013, 2.4535528],
          [102.0622673, 2.4550014],
          [102.0698434, 2.4576607],
          [102.0723899, 2.4559854],
          [102.0734295, 2.4560401],
          [102.077369, 2.4545094],
          [102.0794483, 2.4540721],
          [102.0814728, 2.4545641],
          [102.0821294, 2.4533068],
          [102.0850857, 2.4528623],
          [102.0889275, 2.4456207],
          [102.089461, 2.447396],
          [102.1220312, 2.4619327],
          [102.1418582, 2.4570746],
          [102.1788418, 2.4980802],
          [102.1920836, 2.4925459],
          [102.1958183, 2.4862437],
          [102.2033205, 2.4835788],
          [102.2058106, 2.482448],
          [102.2061879, 2.4801864],
          [102.2114351, 2.4816422],
          [102.213281, 2.4789048],
          [102.2150181, 2.478617],
          [102.2217732, 2.4745899],
          [102.2245499, 2.4729346],
          [102.2283624, 2.4678824],
          [102.2299057, 2.4672428],
          [102.2313125, 2.4663466],
          [102.2328727, 2.4663747],
          [102.2346335, 2.4656921],
          [102.2351097, 2.4655075],
          [102.2373834, 2.4651381],
          [102.2795893, 2.4582806],
          [102.3171609, 2.4788987],
          [102.3346914, 2.4827456],
          [102.3596285, 2.4886465],
          [102.3642926, 2.4897502],
          [102.368262, 2.4893683],
          [102.4363412, 2.4670146],
          [102.4350555, 2.4566864],
          [102.4703032, 2.4468697],
          [102.5100568, 2.4376173],
          [102.567904, 2.4178581],
          [102.5879129, 2.416268],
          [102.5943828, 2.4095606],
          [102.5931212, 2.4089841],
          [102.5871284, 2.4062454],
          [102.5665781, 2.3959321],
          [102.5589014, 2.3969652],
          [102.556354, 2.3965045],
          [102.5550865, 2.3960914],
          [102.5492407, 2.3941626],
          [102.5400246, 2.3906731],
          [102.5389866, 2.3886961],
          [102.5376701, 2.3855219],
          [102.5362265, 2.3839978],
          [102.5360643, 2.3837727],
          [102.5360321, 2.3836601],
          [102.5360428, 2.3835475],
          [102.5359838, 2.3834243],
          [102.5357478, 2.3832099],
          [102.53556, 2.3829097],
          [102.5355868, 2.382765],
          [102.5355278, 2.3826953],
          [102.535383, 2.3826632],
          [102.5353454, 2.3826149],
          [102.5353454, 2.3825399],
          [102.535383, 2.382497],
          [102.5354581, 2.3824488],
          [102.5355386, 2.3823577],
          [102.5356083, 2.382154],
          [102.5356727, 2.3811517],
          [102.5357102, 2.3810177],
          [102.5358926, 2.3807765],
          [102.5359784, 2.3806586],
          [102.5360106, 2.3805354],
          [102.5359999, 2.3802781],
          [102.5359945, 2.3801709],
          [102.5362467, 2.3794688],
          [102.5363486, 2.3793991],
          [102.5364291, 2.3793884],
          [102.5367938, 2.3795224],
          [102.536885, 2.3795009],
          [102.536944, 2.3794527],
          [102.5370138, 2.3793026],
          [102.5372552, 2.3790132],
          [102.5373893, 2.3789703],
          [102.5375341, 2.3789703],
          [102.5375931, 2.3789274],
          [102.5376468, 2.3788202],
          [102.5376092, 2.3786541],
          [102.5374483, 2.3784236],
          [102.5373946, 2.378311],
          [102.5374215, 2.3782306],
          [102.537518, 2.3781074],
          [102.5378506, 2.3776679],
          [102.5377487, 2.377282],
          [102.5376951, 2.3771801],
          [102.5372337, 2.3768157],
          [102.5370996, 2.3767406],
          [102.5369923, 2.3767513],
          [102.5366919, 2.3768693],
          [102.5365149, 2.3769336],
          [102.5363218, 2.3768907],
          [102.5362574, 2.376746],
          [102.536252, 2.3765852],
          [102.5362198, 2.376151],
          [102.5361394, 2.3759634],
          [102.5360643, 2.3757491],
          [102.5360053, 2.3757115],
          [102.5358497, 2.3757276],
          [102.5357424, 2.375733],
          [102.5356619, 2.3756847],
          [102.535619, 2.3755722],
          [102.535619, 2.3754757],
          [102.5356727, 2.3752345],
          [102.5356834, 2.3750844],
          [102.5356298, 2.3749772],
          [102.5355171, 2.3747736],
          [102.5351899, 2.3740822],
          [102.5348841, 2.3735247],
          [102.5348358, 2.3733961],
          [102.5348787, 2.3732567],
          [102.5349431, 2.3731763],
          [102.5350879, 2.3726511],
          [102.5352006, 2.3725385],
          [102.535383, 2.3720293],
          [102.5354742, 2.3717935],
          [102.5354742, 2.3716917],
          [102.5354259, 2.3715202],
          [102.5353508, 2.3712093],
          [102.5352381, 2.3705661],
          [102.5351738, 2.3704375],
          [102.5350665, 2.3704053],
          [102.5347446, 2.3702392],
          [102.5345086, 2.3700837],
          [102.5342779, 2.3700033],
          [102.5334609, 2.3695781],
          [102.5329002, 2.3694242],
          [102.531754, 2.3692149],
          [102.5307249, 2.3689071],
          [102.5298191, 2.3684822],
          [102.5291598, 2.3681067],
          [102.52818, 2.3670846],
          [102.5276254, 2.3665921],
          [102.5271448, 2.366118],
          [102.5262574, 2.3655085],
          [102.5258507, 2.3651822],
          [102.5256597, 2.3650713],
          [102.525216, 2.3640247],
          [102.5251852, 2.3638892],
          [102.5251298, 2.363095],
          [102.5251482, 2.3618144],
          [102.5252407, 2.3611248],
          [102.5252961, 2.3609832],
          [102.52529, 2.3607492],
          [102.5253824, 2.3604168],
          [102.5253331, 2.3601274],
          [102.5252407, 2.3599858],
          [102.5251174, 2.3599057],
          [102.5249819, 2.3598626],
          [102.524797, 2.3595363],
          [102.5244827, 2.3590191],
          [102.5243903, 2.3588468],
          [102.5240144, 2.3585635],
          [102.5235646, 2.358348],
          [102.5228621, 2.358071],
          [102.5222089, 2.3577816],
          [102.5219131, 2.3575969],
          [102.5216851, 2.3574245],
          [102.5212723, 2.3571844],
          [102.5204404, 2.3568335],
          [102.5201446, 2.3567473],
          [102.5199043, 2.3567657],
          [102.51959, 2.3567657],
          [102.5190354, 2.356698],
          [102.5185363, 2.3566056],
          [102.5180742, 2.3563347],
          [102.5170944, 2.3559038],
          [102.5164104, 2.3558484],
          [102.5159975, 2.3558176],
          [102.5156648, 2.3558915],
          [102.5154368, 2.3559653],
          [102.5153197, 2.3560638],
          [102.5137363, 2.354228],
          [102.5123922, 2.3484146],
          [102.5095577, 2.3423214],
          [102.511326, 2.3364331],
          [102.5145175, 2.3319228],
          [102.5158034, 2.3293533],
          [102.5156392, 2.3277951],
          [102.5162958, 2.3258816],
          [102.5155165, 2.3240546],
          [102.5133411, 2.3210979],
          [102.5118638, 2.3215899],
          [102.5111525, 2.3212893],
          [102.51066, 2.3158768],
          [102.5084167, 2.3133072],
          [102.509511, 2.3082501],
          [102.5104685, 2.307102],
          [102.5100308, 2.3052158],
          [102.5084744, 2.3041655],
          [102.5063851, 2.3038949],
          [102.5053294, 2.3025397],
          [102.5068838, 2.2996995],
          [102.5068537, 2.299173],
          [102.5070578, 2.2976737],
          [102.5070448, 2.2972114],
          [102.506916, 2.2967188],
          [102.5068179, 2.2963371],
          [102.5066188, 2.2960182],
          [102.5062481, 2.29563],
          [102.5058399, 2.2952604],
          [102.5053025, 2.2950142],
          [102.5049627, 2.2948454],
          [102.5043373, 2.294335],
          [102.5039259, 2.2938044],
          [102.5037028, 2.2933483],
          [102.5032866, 2.2919446],
          [102.5032197, 2.291384],
          [102.5031231, 2.2909254],
          [102.502711, 2.2897199],
          [102.5025736, 2.2895638],
          [102.5023568, 2.2893749],
          [102.5021908, 2.2890655],
          [102.5020078, 2.2885233],
          [102.5019035, 2.28782],
          [102.5019067, 2.2873593],
          [102.5018382, 2.2870353],
          [102.5015706, 2.2861912],
          [102.5013622, 2.2857723],
          [102.5009433, 2.284794],
          [102.5005533, 2.2837291],
          [102.500066, 2.2821011],
          [102.4991152, 2.2790483],
          [102.4983018, 2.2762529],
          [102.4977334, 2.2739953],
          [102.4975383, 2.273286],
          [102.4974396, 2.2729745],
          [102.4973562, 2.2724845],
          [102.4973189, 2.2717138],
          [102.4973764, 2.2713268],
          [102.4974182, 2.2704507],
          [102.4973742, 2.2696223],
          [102.4973388, 2.2692549],
          [102.497179, 2.2688334],
          [102.4969441, 2.2684333],
          [102.4966139, 2.268019],
          [102.4962379, 2.2676522],
          [102.4955186, 2.2666099],
          [102.4948614, 2.2651245],
          [102.4942607, 2.263494],
          [102.4941314, 2.2626079],
          [102.4937989, 2.2608728],
          [102.4937556, 2.2601052],
          [102.4935475, 2.2596232],
          [102.4931219, 2.2588954],
          [102.4924966, 2.2577234],
          [102.4925049, 2.2572339],
          [102.4924501, 2.2568188],
          [102.4924109, 2.2565603],
          [102.492653, 2.2549727],
          [102.4926134, 2.2542833],
          [102.4927091, 2.2536247],
          [102.4930358, 2.2528146],
          [102.4931322, 2.2518464],
          [102.494025, 2.2477723],
          [102.4975177, 2.2376948],
          [102.4976546, 2.2372997],
          [102.4973259, 2.2362915],
          [102.4966938, 2.2348635],
          [102.4966938, 2.2332433],
          [102.4966388, 2.2317604],
          [102.4966615, 2.230204],
          [102.4969441, 2.2292532],
          [102.4974138, 2.2261413],
          [102.4965446, 2.2244788],
          [102.4959015, 2.2226678],
          [102.495588, 2.2215233],
          [102.494966, 2.2205062],
          [102.4941193, 2.2199649],
          [102.4927577, 2.2193005],
          [102.4915166, 2.2180526],
          [102.4907566, 2.2176436],
          [102.4899782, 2.2166849],
          [102.4885349, 2.2156573],
          [102.4874826, 2.2150731],
          [102.4860794, 2.2137878],
          [102.4845593, 2.2123857],
          [102.4838578, 2.2113342],
          [102.4834062, 2.2108189],
          [102.4831484, 2.2099665],
          [102.482213, 2.2093134],
          [102.4809184, 2.2089122],
          [102.4793159, 2.2084103],
          [102.4778915, 2.2079997],
          [102.4760704, 2.207338],
          [102.4750219, 2.206566],
          [102.4737527, 2.2060697],
          [102.4720972, 2.2052425],
          [102.4715453, 2.2040294],
          [102.4712142, 2.2034228],
          [102.4708831, 2.2025957],
          [102.471159, 2.2009414],
          [102.4738694, 2.197195],
          [102.4741255, 2.1967981],
          [102.4742645, 2.1964794],
          [102.474602, 2.1961234],
          [102.4747489, 2.1959945],
          [102.4751067, 2.195624],
          [102.4755958, 2.1948491],
          [102.4759293, 2.1941436],
          [102.4763805, 2.1931295],
          [102.4770012, 2.1919241],
          [102.4774626, 2.1910903],
          [102.4777862, 2.1903399],
          [102.4780005, 2.1895896],
          [102.4782993, 2.1880929],
          [102.4787745, 2.1862731],
          [102.4802093, 2.1826336],
          [102.4813958, 2.1803605],
          [102.4829685, 2.1783875],
          [102.4851451, 2.1776001],
          [102.4878907, 2.1775225],
          [102.4882907, 2.1775112],
          [102.4904735, 2.1772295],
          [102.4921606, 2.1764726],
          [102.4931775, 2.1760163],
          [102.496599, 2.1745825],
          [102.4974957, 2.1744431],
          [102.4990209, 2.1748996],
          [102.500577, 2.1749854],
          [102.5014692, 2.1748512],
          [102.502444, 2.1742822],
          [102.5029759, 2.1735868],
          [102.5039385, 2.171329],
          [102.5044903, 2.1687923],
          [102.505594, 2.167524],
          [102.507084, 2.1664763],
          [102.5092361, 2.1662005],
          [102.5123816, 2.1670277],
          [102.5158583, 2.1672483],
          [102.5204415, 2.1670139],
          [102.5226477, 2.1668598],
          [102.5256678, 2.1664724],
          [102.5266329, 2.1660336],
          [102.5283973, 2.1647867],
          [102.5300513, 2.1629103],
          [102.5306813, 2.1615623],
          [102.5313006, 2.1582751],
          [102.5321636, 2.1554259],
          [102.5339035, 2.1511459],
          [102.5357798, 2.1475063],
          [102.5362212, 2.1459071],
          [102.5369903, 2.1436294],
          [102.5372927, 2.1411358],
          [102.5368344, 2.1353314],
          [102.5364358, 2.1333247],
          [102.5355038, 2.1310729],
          [102.5328519, 2.1268061],
          [102.5320211, 2.1254694],
          [102.5307467, 2.1238233],
          [102.5296399, 2.1225948],
          [102.5276567, 2.1203933],
          [102.5275267, 2.1202489],
          [102.5265306, 2.1193651],
          [102.5261317, 2.1190112],
          [102.524499, 2.1182912],
          [102.520618, 2.1179665],
          [102.5200472, 2.1169027],
          [102.5188027, 2.1154338],
          [102.517998, 2.1144689],
          [102.5168929, 2.1137398],
          [102.5159917, 2.1130108],
          [102.5153158, 2.1129357],
          [102.5148974, 2.113193],
          [102.5145433, 2.1139006],
          [102.5144467, 2.114694],
          [102.5146291, 2.1154445],
          [102.5145755, 2.1158198],
          [102.5153265, 2.117353],
          [102.5164101, 2.1194544],
          [102.5155823, 2.1207606],
          [102.5139268, 2.1210915],
          [102.5098984, 2.1211466],
          [102.508905, 2.1209812],
          [102.5073924, 2.120941],
          [102.5070691, 2.1210158],
          [102.5067529, 2.1212569],
          [102.5066259, 2.1214733],
          [102.5064919, 2.122008],
          [102.5064008, 2.1231623],
          [102.5061619, 2.1250819],
          [102.5059837, 2.125454],
          [102.5053277, 2.1259747],
          [102.5048959, 2.1263617],
          [102.5043184, 2.1266497],
          [102.5033835, 2.1268989],
          [102.5027999, 2.1268187],
          [102.5024197, 2.1267015],
          [102.5021927, 2.1265164],
          [102.5020533, 2.1263062],
          [102.5016515, 2.1259055],
          [102.5010929, 2.1252218],
          [102.5010235, 2.1250665],
          [102.5009937, 2.1246942],
          [102.5010368, 2.1244398],
          [102.5011269, 2.1243186],
          [102.5013994, 2.1242028],
          [102.5016652, 2.1240862],
          [102.5022443, 2.1233451],
          [102.5027458, 2.122663],
          [102.5029773, 2.1224047],
          [102.5031171, 2.1220863],
          [102.5031678, 2.1218263],
          [102.5031267, 2.1212182],
          [102.5030396, 2.1209048],
          [102.502996, 2.1199599],
          [102.5029484, 2.1197423],
          [102.5027428, 2.1194881],
          [102.502029, 2.1189251],
          [102.5017451, 2.1188107],
          [102.5013632, 2.1184651],
          [102.5011725, 2.1183713],
          [102.5008777, 2.1184535],
          [102.500332, 2.1190511],
          [102.4999981, 2.1193297],
          [102.4997333, 2.11947],
          [102.4994546, 2.1195228],
          [102.4990876, 2.1194842],
          [102.4984203, 2.1193836],
          [102.4979766, 2.1193887],
          [102.4975593, 2.1194782],
          [102.4969767, 2.119722],
          [102.4967274, 2.1197993],
          [102.496515, 2.1197743],
          [102.496356, 2.1196928],
          [102.496252, 2.1195708],
          [102.496192, 2.1194204],
          [102.4960912, 2.1189816],
          [102.495992, 2.1188365],
          [102.4958693, 2.1187803],
          [102.4957103, 2.118809],
          [102.4955617, 2.1188762],
          [102.4953248, 2.119117],
          [102.4951535, 2.1192089],
          [102.4949621, 2.1192224],
          [102.4947505, 2.1191709],
          [102.4943394, 2.1189326],
          [102.4941542, 2.1189231],
          [102.4940006, 2.1189712],
          [102.4938633, 2.1190802],
          [102.4936569, 2.1197273],
          [102.493479, 2.1199356],
          [102.4932228, 2.1199688],
          [102.493054, 2.1199081],
          [102.492807, 2.1196503],
          [102.4925534, 2.1195255],
          [102.4921982, 2.1195144],
          [102.4917326, 2.1198118],
          [102.4914824, 2.1198573],
          [102.4910588, 2.1197233],
          [102.4901505, 2.118941],
          [102.4898113, 2.1185121],
          [102.4895983, 2.1178862],
          [102.4893112, 2.1161934],
          [102.4889465, 2.1145202],
          [102.4887357, 2.1136155],
          [102.488689, 2.1129476],
          [102.488753, 2.1119351],
          [102.4888212, 2.1095374],
          [102.4888459, 2.1067681],
          [102.4887429, 2.1063296],
          [102.4887282, 2.1062671],
          [102.488456, 2.1057823],
          [102.4883415, 2.1053804],
          [102.4882961, 2.1052213],
          [102.4882988, 2.1047943],
          [102.4892976, 2.1023747],
          [102.489453, 2.1009488],
          [102.4895567, 2.0998642],
          [102.4893989, 2.0990036],
          [102.4891503, 2.0990668],
          [102.4890356, 2.099096],
          [102.4887653, 2.0985691],
          [102.4879542, 2.0982855],
          [102.4861158, 2.0985151],
          [102.4802222, 2.1025001],
          [102.469773, 2.1066066],
          [102.4650588, 2.1075353],
          [102.4636812, 2.1086043],
          [102.4628188, 2.1092734],
          [102.4627179, 2.1093479],
          [102.4624094, 2.1084538],
          [102.460571, 2.1081161],
          [102.4561913, 2.1088186],
          [102.4486215, 2.1102505],
          [102.4464546, 2.1100636],
          [102.4448637, 2.1099263],
          [102.4415519, 2.1105747],
          [102.4387267, 2.111169],
          [102.4366586, 2.1126414],
          [102.4337523, 2.1144245],
          [102.427628, 2.1184908],
          [102.4246831, 2.1204462],
          [102.4141058, 2.1276587],
          [102.4139275, 2.1277435],
          [102.4093942, 2.1298988],
          [102.4043469, 2.1316808],
          [102.403512, 2.1319755],
          [102.4033186, 2.132008],
          [102.3996838, 2.1326192],
          [102.3958652, 2.1332612],
          [102.3902297, 2.1332464],
          [102.3862303, 2.1333436],
          [102.3846186, 2.1331122],
          [102.3838704, 2.1329277],
          [102.383532, 2.1327839],
          [102.3830348, 2.1326302],
          [102.3828033, 2.1326009],
          [102.3824269, 2.1326306],
          [102.3818511, 2.132497],
          [102.3799218, 2.1319296],
          [102.3724172, 2.1332358],
          [102.3654577, 2.1370758],
          [102.3583814, 2.1418231],
          [102.3562381, 2.1430718],
          [102.3562515, 2.1431519],
          [102.355929, 2.1431057],
          [102.35536, 2.143546],
          [102.3548444, 2.14378],
          [102.3549471, 2.144014],
          [102.3540829, 2.1446452],
          [102.3541438, 2.1447391],
          [102.3540506, 2.144796],
          [102.3540171, 2.1448109],
          [102.3538545, 2.1445094],
          [102.3531905, 2.1437371],
          [102.3527004, 2.1431654],
          [102.3524815, 2.143311],
          [102.3523981, 2.1433336],
          [102.3520419, 2.1428523],
          [102.3516025, 2.1422141],
          [102.3515371, 2.1422061],
          [102.3514803, 2.1422701],
          [102.3518843, 2.1428785],
          [102.351848, 2.1429807],
          [102.3518986, 2.1430619],
          [102.3532532, 2.1449493],
          [102.3529121, 2.1452265],
          [102.3506554, 2.1478918],
          [102.3488039, 2.1496256],
          [102.343313, 2.1527557],
          [102.3390941, 2.1542217],
          [102.3386059, 2.1545025],
          [102.3385403, 2.1546643],
          [102.338489, 2.1547526],
          [102.3383616, 2.1548981],
          [102.3382314, 2.1549465],
          [102.3375244, 2.1550275],
          [102.3374694, 2.1550636],
          [102.3371957, 2.1552329],
          [102.3369879, 2.1553261],
          [102.3367521, 2.1554263],
          [102.3366774, 2.155531],
          [102.3366143, 2.1556587],
          [102.3366072, 2.1557672],
          [102.3366317, 2.1559415],
          [102.3366305, 2.1564937],
          [102.3370461, 2.1572371],
          [102.3370399, 2.1572885],
          [102.3369409, 2.1572955],
          [102.3367071, 2.1569047],
          [102.336454, 2.1566893],
          [102.3363896, 2.1564934],
          [102.3363768, 2.1560174],
          [102.3362986, 2.1558845],
          [102.3358999, 2.1558993],
          [102.3358693, 2.1555882],
          [102.3355843, 2.1552034],
          [102.3350489, 2.1551465],
          [102.3308257, 2.156136],
          [102.3295288, 2.1565378],
          [102.3287383, 2.1565774],
          [102.3268719, 2.1566299],
          [102.326295, 2.155861],
          [102.3261401, 2.1550877],
          [102.3259081, 2.1551511],
          [102.3261175, 2.1563192],
          [102.326102, 2.1566085],
          [102.3258965, 2.156671],
          [102.3239338, 2.1567197],
          [102.3238953, 2.1574291],
          [102.3233242, 2.1574728],
          [102.3228581, 2.1569919],
          [102.3224796, 2.1569695],
          [102.3223556, 2.1566377],
          [102.3219933, 2.1566256],
          [102.3217817, 2.1568845],
          [102.3209286, 2.1573212],
          [102.3201618, 2.1572975],
          [102.3195397, 2.157444],
          [102.3190512, 2.157297],
          [102.3174377, 2.158596],
          [102.3169524, 2.1586565],
          [102.3166836, 2.1589069],
          [102.3167709, 2.159698],
          [102.3162835, 2.1604809],
          [102.3122664, 2.1647265],
          [102.3067489, 2.1672342],
          [102.3064356, 2.1676344],
          [102.3059916, 2.167932],
          [102.3053976, 2.1681274],
          [102.3044676, 2.1684825],
          [102.3042504, 2.168517],
          [102.3039987, 2.1683998],
          [102.3037654, 2.1685418],
          [102.3035042, 2.1685952],
          [102.3030696, 2.1695405],
          [102.3005697, 2.1701837],
          [102.2980485, 2.1708377],
          [102.296407, 2.1711701],
          [102.2942459, 2.1714436],
          [102.2905049, 2.1725042],
          [102.2901889, 2.1722367],
          [102.2900624, 2.1722809],
          [102.2900223, 2.1721734],
          [102.2899535, 2.1721979],
          [102.2893162, 2.1724462],
          [102.2887251, 2.1726764],
          [102.2883276, 2.1725711],
          [102.2881261, 2.1725577],
          [102.2878061, 2.1721028],
          [102.285215, 2.1729039],
          [102.2830737, 2.1738317],
          [102.2819518, 2.174938],
          [102.275139503180981, 2.178316315229852],
          [102.2745235, 2.1786218],
          [102.2727958, 2.1790982],
          [102.2697987, 2.1811818],
          [102.2685558, 2.1821401],
          [102.2674453, 2.1815166],
          [102.2667094, 2.1820678],
          [102.266957, 2.1825092],
          [102.2658837, 2.1831449],
          [102.2657377, 2.1832585],
          [102.2657614, 2.183411],
          [102.2657606, 2.1835596],
          [102.2657035, 2.1835626],
          [102.2652867, 2.1835841],
          [102.2646096, 2.1835954],
          [102.2642469, 2.1835025],
          [102.2639329, 2.1833435],
          [102.2636759, 2.1831049],
          [102.2637363, 2.1830515],
          [102.2638217, 2.1829182],
          [102.263779, 2.1825973],
          [102.2639351, 2.1825368],
          [102.2640076, 2.1827226],
          [102.2640624, 2.1827142],
          [102.2640134, 2.1820078],
          [102.263795, 2.1817288],
          [102.2636161, 2.1812738],
          [102.2630728, 2.1805873],
          [102.2625896, 2.1799868],
          [102.2619958, 2.1795082],
          [102.2613677, 2.1793647],
          [102.2610292, 2.179361],
          [102.2607766, 2.1796236],
          [102.2604469, 2.1804401],
          [102.2603883, 2.1807971],
          [102.2593589, 2.1813879],
          [102.2587936, 2.1815836],
          [102.2581811, 2.1818466],
          [102.2573708, 2.1821871],
          [102.2568837, 2.1824008],
          [102.2568157, 2.1822944],
          [102.2565528, 2.1813072],
          [102.2560421, 2.1813024],
          [102.2551864, 2.1817287],
          [102.2527789, 2.1820737],
          [102.2522669, 2.1821693],
          [102.2516955, 2.1824469],
          [102.2514119, 2.1826557],
          [102.2512787, 2.1828982],
          [102.2510263, 2.1829433],
          [102.2509248, 2.1829614],
          [102.247524, 2.1836632],
          [102.2468083, 2.1838283],
          [102.2438734, 2.1839192],
          [102.2435388, 2.1842246],
          [102.2437767, 2.1857237],
          [102.2441512, 2.1872961],
          [102.244079, 2.1876513],
          [102.244038, 2.1876553],
          [102.2435343, 2.1876475],
          [102.2430182, 2.1876396],
          [102.2429135, 2.1875072],
          [102.2427023, 2.187293],
          [102.2422783, 2.1871373],
          [102.2416408, 2.1870367],
          [102.2407478, 2.1874295],
          [102.2367298, 2.1905906],
          [102.2360848, 2.189517],
          [102.2354639, 2.1887843],
          [102.2351297, 2.1884941],
          [102.2333205, 2.1863485],
          [102.2324182, 2.1851517],
          [102.2284814, 2.1801555],
          [102.2266633, 2.1783171],
          [102.2260251, 2.1780684],
          [102.2251782, 2.1782637],
          [102.2229857, 2.1803259],
          [102.2223618, 2.1804765],
          [102.2206922, 2.1820721],
          [102.2207601, 2.1829795],
          [102.2214247, 2.1848536],
          [102.2216034, 2.1856747],
          [102.2213377, 2.1861334],
          [102.218151, 2.1882991],
          [102.2177343, 2.188226],
          [102.2172984, 2.1881496],
          [102.2159044, 2.1887571],
          [102.2156209, 2.189372],
          [102.2145484, 2.1898836],
          [102.2130813, 2.1908675],
          [102.2121229, 2.1917244],
          [102.2106651, 2.1928403],
          [102.2104002, 2.1941233],
          [102.2084688, 2.1974841],
          [102.2081818, 2.1985561],
          [102.2077728, 2.1993652],
          [102.2076834, 2.1995636],
          [102.2073745, 2.1998949],
          [102.2073855, 2.2000939],
          [102.2072089, 2.2004699],
          [102.2073632, 2.2007369],
          [102.2074511, 2.2004774],
          [102.2078695, 2.200333],
          [102.2080596, 2.1999524],
          [102.2085695, 2.1999134],
          [102.2093717, 2.2006921],
          [102.2091073, 2.2016468],
          [102.2087105, 2.201874],
          [102.208002, 2.2021065],
          [102.2071667, 2.2021382],
          [102.20686, 2.2013774],
          [102.2069763, 2.2003736],
          [102.2061304, 2.1997501],
          [102.203634, 2.2013636],
          [102.2024041, 2.2027388],
          [102.2016002, 2.2042776],
          [102.1974169, 2.2042175],
          [102.1962849, 2.2025793],
          [102.194397, 2.2015774],
          [102.192435, 2.2019297],
          [102.1913138, 2.201966],
          [102.1909076, 2.201513],
          [102.189367, 2.1996425],
          [102.1871866, 2.1972417],
          [102.1869797, 2.1970671],
          [102.1867488, 2.1969213],
          [102.1865186, 2.1968143],
          [102.1862549, 2.1967409],
          [102.1859966, 2.1967155],
          [102.1857096, 2.1967284],
          [102.1854172, 2.1967848],
          [102.1852017, 2.1968747],
          [102.1805941, 2.199408],
          [102.1803271, 2.1996103],
          [102.1801474, 2.1998399],
          [102.1799917, 2.2001442],
          [102.179882, 2.200507],
          [102.1798375, 2.200844],
          [102.1798697, 2.2011828],
          [102.1799688, 2.2015337],
          [102.1801708, 2.2019038],
          [102.1820358, 2.2047559],
          [102.183515, 2.2070279],
          [102.1848664, 2.2089423],
          [102.1868763, 2.2120142],
          [102.1872513, 2.2123476],
          [102.191446, 2.2131391],
          [102.1921038, 2.2134035],
          [102.1934647, 2.214947],
          [102.1936457, 2.2152166],
          [102.193623, 2.2154268],
          [102.1934733, 2.21564],
          [102.1918198, 2.2163967],
          [102.1902563, 2.216783],
          [102.1898979, 2.2167428],
          [102.1894259, 2.2168012],
          [102.1889388, 2.2166581],
          [102.1890139, 2.2164506],
          [102.1888948, 2.2163059],
          [102.1886523, 2.2162485],
          [102.1876293, 2.2161178],
          [102.1873541, 2.2159655],
          [102.1870333, 2.2156873],
          [102.1868375, 2.2152933],
          [102.186852, 2.217928],
          [102.1866031, 2.2184967],
          [102.1858027, 2.2188912],
          [102.1852046, 2.2188821],
          [102.1844861, 2.219191],
          [102.1829241, 2.2192081],
          [102.182642, 2.2193319],
          [102.181861, 2.2190328],
          [102.1815239, 2.2190431],
          [102.1806315, 2.2193459],
          [102.178822, 2.2203122],
          [102.1785723, 2.2204605],
          [102.1779447, 2.2203897],
          [102.1769492, 2.2202987],
          [102.1760419, 2.2204806],
          [102.1756434, 2.2204909],
          [102.1753667, 2.2202818],
          [102.1740574, 2.2204639],
          [102.1720666, 2.2206123],
          [102.171989, 2.2206831],
          [102.1716853, 2.2207269],
          [102.171611, 2.2206359],
          [102.1713748, 2.2206561],
          [102.169519, 2.2205684],
          [102.1692929, 2.2204808],
          [102.1677703, 2.2203693],
          [102.1676496, 2.22012],
          [102.1663505, 2.2197592],
          [102.1649535, 2.2190714],
          [102.1642651, 2.2183734],
          [102.1647848, 2.2180059],
          [102.1647886, 2.2166633],
          [102.1639716, 2.2166706],
          [102.163678, 2.2165695],
          [102.1635937, 2.2167246],
          [102.1622203, 2.2169471],
          [102.1617175, 2.2168729],
          [102.1617007, 2.2166369],
          [102.1613362, 2.2166302],
          [102.1610258, 2.2165695],
          [102.1608571, 2.2167819],
          [102.1602598, 2.2168358],
          [102.1597098, 2.2166335],
          [102.15945, 2.2163233],
          [102.1594264, 2.2158681],
          [102.1592543, 2.2158884],
          [102.1587684, 2.216202],
          [102.158495, 2.2161885],
          [102.1574962, 2.216583],
          [102.15698, 2.2167381],
          [102.156403, 2.2170415],
          [102.1555695, 2.2173382],
          [102.1547529, 2.217318],
          [102.1543817, 2.2167583],
          [102.1535483, 2.2186364],
          [102.1526128, 2.2204502],
          [102.1520332, 2.2216272],
          [102.1513584, 2.2224364],
          [102.1507645, 2.2226522],
          [102.1506632, 2.2233265],
          [102.1502381, 2.2245943],
          [102.1498736, 2.2251338],
          [102.1495767, 2.2251406],
          [102.1494012, 2.225444],
          [102.1496239, 2.2257677],
          [102.1493473, 2.2264218],
          [102.148335, 2.2285461],
          [102.1475192, 2.229917],
          [102.1429906, 2.2390432],
          [102.1403383, 2.2437096],
          [102.1397076, 2.2446729],
          [102.1380729, 2.2471695],
          [102.136792, 2.2489779],
          [102.1347775, 2.251822],
          [102.1341296, 2.2519164],
          [102.1328676, 2.2532921],
          [102.1328811, 2.2535416],
          [102.1318081, 2.2550319],
          [102.1305258, 2.2563604],
          [102.1259441, 2.2621532],
          [102.122749, 2.2661549],
          [102.1226308, 2.2663029],
          [102.1210838, 2.2675564],
          [102.1200751, 2.2683181],
          [102.1190972, 2.2688529],
          [102.1166011, 2.2705454],
          [102.1141496, 2.2724829],
          [102.1116089, 2.2736039],
          [102.1101801, 2.2740718],
          [102.108948, 2.2743334],
          [102.1072693, 2.2745911],
          [102.1061374, 2.2746537],
          [102.10535, 2.2746796],
          [102.1049438, 2.2746161],
          [102.1044972, 2.2744142],
          [102.1042392, 2.2741833],
          [102.1041642, 2.2739025],
          [102.1042315, 2.2736947],
          [102.1043413, 2.2736332],
          [102.1045088, 2.273612],
          [102.1045299, 2.2734601],
          [102.1043798, 2.2733023],
          [102.1042816, 2.2734004],
          [102.1038215, 2.2733908],
          [102.103448, 2.2733235],
          [102.1031458, 2.2730292],
          [102.1031285, 2.2726983],
          [102.1032979, 2.2724983],
          [102.1031766, 2.272429],
          [102.1029494, 2.2723733],
          [102.10273, 2.2723752],
          [102.1023623, 2.2722463],
          [102.1022218, 2.2721155],
          [102.1023007, 2.2719193],
          [102.1022718, 2.2717712],
          [102.102149, 2.271684],
          [102.1020042, 2.2718154],
          [102.1019657, 2.2719751],
          [102.1019984, 2.2721251],
          [102.1019465, 2.2722097],
          [102.1015152, 2.2727176],
          [102.1010917, 2.2737101],
          [102.1004103, 2.2745334],
          [102.0999636, 2.2752952],
          [102.0982492, 2.2784985],
          [102.0978133, 2.2790326],
          [102.0974822, 2.2792211],
          [102.0973397, 2.2795231],
          [102.0969012, 2.2801833],
          [102.0960837, 2.2812236],
          [102.0957939, 2.2814352],
          [102.0954638, 2.2815707],
          [102.0952607, 2.281589],
          [102.095056, 2.2815515],
          [102.0950142, 2.2814082],
          [102.0950335, 2.2812063],
          [102.094949, 2.2811632],
          [102.0948853, 2.2814198],
          [102.0946959, 2.2817229],
          [102.0928883, 2.2830723],
          [102.0926596, 2.2831811],
          [102.0925507, 2.2837143],
          [102.0917209, 2.2853759],
          [102.0912031, 2.2861762],
          [102.0906069, 2.2878165],
          [102.0903047, 2.2883252],
          [102.0900215, 2.2887278],
          [102.0896044, 2.2891823],
          [102.089083, 2.2904086],
          [102.0884329, 2.2912673],
          [102.0877704, 2.2918334],
          [102.0870446, 2.2922229],
          [102.0866456, 2.292335],
          [102.0863517, 2.2923353],
          [102.0859922, 2.292052],
          [102.0857938, 2.2919301],
          [102.0855893, 2.2920112],
          [102.0852735, 2.2921201],
          [102.085004, 2.2921119],
          [102.084808, 2.2919786],
          [102.0847426, 2.2916848],
          [102.0846201, 2.2917746],
          [102.0844459, 2.2920711],
          [102.0840212, 2.2921745],
          [102.0837938, 2.2920417],
          [102.0837734, 2.2922398],
          [102.0836046, 2.2924166],
          [102.083316, 2.2928165],
          [102.0829594, 2.2932435],
          [102.0823904, 2.2939073],
          [102.0819848, 2.2943643],
          [102.0813395, 2.2948676],
          [102.0806189, 2.295215],
          [102.0805389, 2.2952278],
          [102.079802, 2.2954677],
          [102.0797169, 2.295579],
          [102.0795284, 2.295691],
          [102.0793678, 2.2957267],
          [102.0792322, 2.2957104],
          [102.0790191, 2.2956028],
          [102.0788927, 2.2956121],
          [102.0787535, 2.2955476],
          [102.0787558, 2.2954227],
          [102.0787348, 2.2953666],
          [102.0787757, 2.2952813],
          [102.0787032, 2.2952672],
          [102.0785824, 2.2953583],
          [102.0785665, 2.2955098],
          [102.0786587, 2.2955841],
          [102.0786906, 2.2956809],
          [102.0786331, 2.2958831],
          [102.0784282, 2.2961862],
          [102.0781515, 2.2964236],
          [102.0779718, 2.2965949],
          [102.0777976, 2.2972505],
          [102.0772172, 2.2994417],
          [102.077035, 2.2998224],
          [102.0768463, 2.300084],
          [102.0769233, 2.3002418],
          [102.0768656, 2.300588],
          [102.0765922, 2.3009612],
          [102.0766038, 2.3012343],
          [102.0759993, 2.3034733],
          [102.0757606, 2.3038272],
          [102.0749387, 2.3069468],
          [102.0740549, 2.3102707],
          [102.0730597, 2.3135179],
          [102.0718935, 2.3167734],
          [102.0707152, 2.3199051],
          [102.0696598, 2.3221915],
          [102.0680366, 2.3252419],
          [102.0672159, 2.3267191],
          [102.0659381, 2.3291609],
          [102.064504, 2.3315678],
          [102.0631139, 2.3335854],
          [102.0616703, 2.3355378],
          [102.0605114, 2.3368881],
          [102.0602342, 2.3369612],
          [102.0602958, 2.3372921],
          [102.0600455, 2.3376691],
          [102.0594642, 2.3382884],
          [102.0592717, 2.3383923],
          [102.058929, 2.338877],
          [102.0575085, 2.3402517],
          [102.0568172, 2.3408492],
          [102.0546014, 2.3425355],
          [102.0524184, 2.3440396],
          [102.0516214, 2.3444166],
          [102.0514104, 2.3443852],
          [102.0512556, 2.3445628],
          [102.0508052, 2.3447391],
          [102.0500436, 2.3442475],
          [102.0499042, 2.3442281],
          [102.0497846, 2.3444585],
          [102.0492497, 2.3446782],
          [102.0487722, 2.3447513],
          [102.0485453, 2.3446491],
          [102.0485374, 2.3448552],
          [102.0486798, 2.3452629],
          [102.0481267, 2.3457215],
          [102.0471513, 2.3459939],
          [102.0454518, 2.3461495],
          [102.0446064, 2.3460439],
          [102.04441, 2.3458329],
          [102.0443657, 2.3458458],
          [102.0443369, 2.3461439],
          [102.0441886, 2.3463504],
          [102.0437266, 2.3467351],
          [102.0429431, 2.3475501],
          [102.0411046, 2.3485739],
          [102.0404865, 2.3487395],
          [102.0393528, 2.3488509],
          [102.0391376, 2.3486699],
          [102.0390795, 2.3487244],
          [102.0390495, 2.3490155],
          [102.0387909, 2.349331],
          [102.038554, 2.3493963],
          [102.0382927, 2.3497363],
          [102.0371193, 2.3508298],
          [102.0369521, 2.3508794],
          [102.0368634, 2.3510284],
          [102.0360657, 2.3516269],
          [102.0359023, 2.3516921],
          [102.0358805, 2.3518635],
          [102.0353442, 2.3522607],
          [102.0351956, 2.3522707],
          [102.0351264, 2.3524266],
          [102.0344785, 2.352734],
          [102.0342237, 2.352352],
          [102.0336105, 2.3526646],
          [102.0339721, 2.3531175],
          [102.0338646, 2.3531986],
          [102.0337944, 2.3533314],
          [102.0335739, 2.3529024],
          [102.0331955, 2.3525929],
          [102.033099, 2.3526659],
          [102.033505, 2.3531924],
          [102.0334499, 2.3534049],
          [102.0318132, 2.3548204],
          [102.0316756, 2.354864],
          [102.0316372, 2.355096],
          [102.0308364, 2.35565],
          [102.0305938, 2.3557423],
          [102.0305514, 2.3558962],
          [102.0299893, 2.3563424],
          [102.0297545, 2.3564001],
          [102.029639, 2.3566117],
          [102.0278871, 2.3579927],
          [102.0267321, 2.3588006],
          [102.0260044, 2.3594238],
          [102.0249187, 2.3602316],
          [102.0247146, 2.3604663],
          [102.0240139, 2.3609125],
          [102.0237867, 2.3611164],
          [102.0231745, 2.3614434],
          [102.022932, 2.3615088],
          [102.022778, 2.3616588],
          [102.0222336, 2.361912],
          [102.0178921, 2.3639247],
          [102.0131704, 2.3660026],
          [102.0116163, 2.3666329],
          [102.0097143, 2.3672561],
          [102.0073933, 2.367705],
          [102.0059121, 2.3679914],
          [102.00050047634258, 2.368585998334775],
          [101.99949952365742, 2.368695977305499],
          [101.9991025, 2.3687396],
          [101.9984821, 2.3685574],
          [101.9981554, 2.368231],
          [101.9974115, 2.3678846],
          [101.9966988, 2.3675238],
          [101.9966362, 2.3666424],
          [101.996662, 2.3658004],
          [101.9960094, 2.3652626],
          [101.9952821, 2.3655318],
          [101.9951416, 2.3658345],
          [101.9948767, 2.3660154],
          [101.9942459, 2.3664221],
          [101.9928381, 2.3671587],
          [101.9891561, 2.3688327],
          [101.9879542, 2.3692565],
          [101.9866774, 2.3694687],
          [101.9862636, 2.3692919],
          [101.9858579, 2.3693082],
          [101.9842462, 2.3695965],
          [101.983778, 2.36964],
          [101.983032, 2.3696428],
          [101.9820468, 2.3698719],
          [101.9770502, 2.3711474],
          [101.9764031, 2.3702456],
          [101.9759858, 2.3702115],
          [101.9759517, 2.3705859],
          [101.9757899, 2.3713261],
          [101.9752705, 2.3719813],
          [101.974155, 2.3730703],
          [101.9732608, 2.3740572],
          [101.9730224, 2.3745252],
          [101.972818, 2.3746273],
          [101.9726648, 2.3744571],
          [101.9724859, 2.3746443],
          [101.9724263, 2.3754526],
          [101.9719154, 2.375929],
          [101.9717621, 2.375861],
          [101.9714556, 2.3759801],
          [101.9718217, 2.376431],
          [101.9723923, 2.3763459],
          [101.9725285, 2.3771032],
          [101.9722901, 2.3783283],
          [101.9718388, 2.3794259],
          [101.9707147, 2.3817486],
          [101.9698632, 2.3830078],
          [101.9687647, 2.3841564],
          [101.9690031, 2.3843606],
          [101.9690883, 2.3846754],
          [101.9686369, 2.3853646],
          [101.9682282, 2.3861133],
          [101.9683559, 2.3862154],
          [101.9682537, 2.3867259],
          [101.9683048, 2.3873044],
          [101.9690457, 2.3877979],
          [101.9700505, 2.3876788],
          [101.9706381, 2.3879],
          [101.971396, 2.3883169],
          [101.9716594, 2.3897995],
          [101.972537, 2.3896186],
          [101.9728519, 2.3900187],
          [101.9734161, 2.3910722],
        ],
      ],
    ],
  },
};
