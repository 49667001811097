import React, { useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Routes from "../config/routes";
import { AppHistory } from "../utils/history";

const Pages = ({ common }) => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <Router history={AppHistory}>
      <Switch>
        {Routes.NormalRouting.map((route, index) => {
          return (
            <Route
              exact
              key={index}
              path={route.path}
              render={(props) => {
                return <route.component {...common} {...props} />;
              }}
            />
          );
        })}
        {Routes.SecuredRouting.map((route, index) => {
          return (
            <Route
              exact
              key={index}
              path={route.path}
              render={(props) => {
                return authenticated ? (
                  <route.component {...common} {...props} />
                ) : (
                  <Redirect to="/" />
                );
              }}
            />
          );
        })}
      </Switch>
    </Router>
  );
};

export default Pages;
