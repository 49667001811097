export const selangor = {
  type: "Feature",
  properties: {
    gid: 15,
    id: 2932285,
    country: "MYS",
    name: "Selangor",
    enname: null,
    locname: "Selangor",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q189710",
    wikimedia: "en:Selangor",
    timestamp: "2019-12-03 22:59:01",
    note: null,
    path: "0,2108121,2932285",
    rpath: "2932285,2108121,0",
    iso3166_2: "MY-10",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [101.3728856, 2.9741794],
          [101.3745628, 2.9768396],
          [101.3759111, 2.9783175],
          [101.3777363, 2.9795162],
          [101.3799726, 2.9806657],
          [101.3820115, 2.9814867],
          [101.384182, 2.9817494],
          [101.3837874, 2.9806657],
          [101.3814196, 2.9781204],
          [101.379545, 2.9764783],
          [101.3774568, 2.9754931],
          [101.3754671, 2.9744585],
          [101.3738064, 2.973851],
          [101.3729678, 2.9736211],
          [101.3728856, 2.9741794],
        ],
      ],
      [
        [
          [101.2301261, 2.9450693],
          [101.2300718, 2.945593],
          [101.2298227, 2.945801],
          [101.2300384, 2.9459718],
          [101.2302801, 2.9464361],
          [101.2305367, 2.9466515],
          [101.2300793, 2.946904],
          [101.2308007, 2.9485456],
          [101.2339342, 2.9525224],
          [101.2366505, 2.9551972],
          [101.2421062, 2.9591699],
          [101.2447166, 2.9605638],
          [101.247067, 2.9619008],
          [101.2493476, 2.9633576],
          [101.2532665, 2.965981],
          [101.2536939, 2.966116],
          [101.2544414, 2.9658783],
          [101.2551968, 2.9653638],
          [101.2556686, 2.9644745],
          [101.2572647, 2.9562233],
          [101.2584559, 2.9507548],
          [101.2588577, 2.943217],
          [101.2587189, 2.9405852],
          [101.2586511, 2.9399256],
          [101.2584072, 2.9394329],
          [101.2587255, 2.9390713],
          [101.2589561, 2.9352385],
          [101.259447, 2.9331439],
          [101.2595622, 2.9259598],
          [101.2594098, 2.9224922],
          [101.259261, 2.9220131],
          [101.2588073, 2.9220762],
          [101.2591457, 2.9217605],
          [101.2591941, 2.9212851],
          [101.2590602, 2.9197512],
          [101.2589338, 2.9191533],
          [101.2587478, 2.9193947],
          [101.2587627, 2.9203789],
          [101.2586883, 2.9203752],
          [101.2584949, 2.9196955],
          [101.2586325, 2.9191124],
          [101.2583759, 2.9187076],
          [101.2584958, 2.9181613],
          [101.2584838, 2.9180131],
          [101.2582644, 2.9179833],
          [101.257926, 2.9162823],
          [101.2574425, 2.9162638],
          [101.2573049, 2.9161672],
          [101.2576508, 2.9159481],
          [101.257714, 2.9157884],
          [101.2575801, 2.9156844],
          [101.2576173, 2.914949],
          [101.2574388, 2.913456],
          [101.2565612, 2.9138868],
          [101.2571748, 2.91317],
          [101.257171, 2.9126574],
          [101.2568512, 2.912338],
          [101.2567917, 2.9118181],
          [101.2560256, 2.9120483],
          [101.256312, 2.9115618],
          [101.2563529, 2.9111978],
          [101.2560926, 2.9110307],
          [101.2561855, 2.9108041],
          [101.2559252, 2.9103102],
          [101.2555124, 2.9103807],
          [101.2551145, 2.9106593],
          [101.255351, 2.9100695],
          [101.2533592, 2.9090734],
          [101.2510953, 2.9080964],
          [101.2469926, 2.9067892],
          [101.2428348, 2.9056035],
          [101.2405813, 2.9047948],
          [101.2387144, 2.904364],
          [101.2373772, 2.9038958],
          [101.2360964, 2.903974],
          [101.2354524, 2.9040774],
          [101.2345568, 2.9062284],
          [101.23433, 2.90691],
          [101.234434, 2.9097605],
          [101.2349361, 2.9104587],
          [101.2353563, 2.912],
          [101.2354865, 2.9130734],
          [101.235308, 2.9136045],
          [101.2351704, 2.9141059],
          [101.2352596, 2.9144625],
          [101.2352262, 2.9148041],
          [101.2354434, 2.9150016],
          [101.2352857, 2.9154207],
          [101.2353229, 2.9158961],
          [101.2351481, 2.9162006],
          [101.2353154, 2.9165349],
          [101.2352076, 2.9168617],
          [101.2353675, 2.9171217],
          [101.2353749, 2.9177976],
          [101.2353749, 2.918273],
          [101.2352076, 2.9185442],
          [101.235189, 2.9188487],
          [101.235003, 2.9189193],
          [101.2350365, 2.9191273],
          [101.2351778, 2.9191867],
          [101.2352596, 2.9198552],
          [101.2346237, 2.9208877],
          [101.23452, 2.9217268],
          [101.2343522, 2.9222359],
          [101.234475, 2.922611],
          [101.2356538, 2.9224327],
          [101.2354009, 2.923001],
          [101.2347613, 2.9238589],
          [101.2341142, 2.924234],
          [101.2331882, 2.9241226],
          [101.2320292, 2.9272268],
          [101.2315557, 2.9302135],
          [101.2313773, 2.931487],
          [101.2326365, 2.9359542],
          [101.2330432, 2.9373889],
          [101.2328907, 2.9382988],
          [101.2321916, 2.9365496],
          [101.2318457, 2.9366499],
          [101.2315148, 2.9379943],
          [101.231439, 2.9393494],
          [101.2313995, 2.9403304],
          [101.2301574, 2.9425476],
          [101.2300272, 2.943153],
          [101.2303694, 2.9435801],
          [101.2300235, 2.9443006],
          [101.2298487, 2.9449245],
          [101.2301261, 2.9450693],
        ],
      ],
      [
        [
          [101.2789115, 2.8985897],
          [101.2799102, 2.900253],
          [101.2804167, 2.9014485],
          [101.2808586, 2.9021933],
          [101.2812546, 2.902506],
          [101.2814019, 2.9031497],
          [101.2821109, 2.903821],
          [101.2818163, 2.9041429],
          [101.2820052, 2.9044074],
          [101.2793178, 2.9060324],
          [101.2836383, 2.9135492],
          [101.2825889, 2.9140198],
          [101.2893834, 2.9257455],
          [101.2974714, 2.939885],
          [101.3001757, 2.9445636],
          [101.3028188, 2.9492727],
          [101.3055715, 2.9539617],
          [101.3084764, 2.9590574],
          [101.3085567, 2.9590122],
          [101.3083211, 2.9586112],
          [101.308364, 2.9585888],
          [101.3086961, 2.9591083],
          [101.3087214, 2.9590949],
          [101.3083945, 2.958578],
          [101.3084227, 2.9585628],
          [101.3083676, 2.9584588],
          [101.3094221, 2.9578802],
          [101.3124777, 2.9629957],
          [101.3143708, 2.9652577],
          [101.3151902, 2.9665272],
          [101.3152993, 2.9666961],
          [101.3161378, 2.9681521],
          [101.3162087, 2.9684125],
          [101.3169658, 2.9692758],
          [101.3182217, 2.9705941],
          [101.3194731, 2.9719229],
          [101.3205528, 2.9729574],
          [101.3213171, 2.9736291],
          [101.3233805, 2.9752648],
          [101.3238161, 2.9754975],
          [101.3240865, 2.9756813],
          [101.3243726, 2.9760375],
          [101.3246525, 2.9764582],
          [101.3267414, 2.9782082],
          [101.3289544, 2.979595],
          [101.3298324, 2.9796934],
          [101.3307553, 2.9799887],
          [101.333461, 2.9823061],
          [101.3355486, 2.984185],
          [101.336561, 2.985214],
          [101.3375824, 2.9860998],
          [101.3398044, 2.987934],
          [101.3458037, 2.9904719],
          [101.3478992, 2.9915775],
          [101.3508342, 2.9931259],
          [101.3514406, 2.9932901],
          [101.3530208, 2.9940801],
          [101.3533934, 2.9945522],
          [101.3535393, 2.9946306],
          [101.3545605, 2.995288],
          [101.3550184, 2.9953333],
          [101.3554705, 2.995131],
          [101.3567271, 2.994947],
          [101.3582095, 2.9946805],
          [101.3589996, 2.9945628],
          [101.3602882, 2.9942753],
          [101.36137, 2.9939662],
          [101.3619908, 2.9937605],
          [101.363142, 2.9937937],
          [101.3651677, 2.9935212],
          [101.3667453, 2.9931659],
          [101.3679737, 2.9930263],
          [101.3694595, 2.9926178],
          [101.3711002, 2.9924149],
          [101.3732404, 2.992244],
          [101.3748051, 2.9922499],
          [101.3886237, 2.9927938],
          [101.3902246, 2.9914718],
          [101.390055, 2.9897225],
          [101.3876817, 2.9866752],
          [101.3842348, 2.9843616],
          [101.3797707, 2.9832894],
          [101.3770292, 2.9816673],
          [101.3708631, 2.9751646],
          [101.3671131, 2.9694074],
          [101.3636097, 2.9628613],
          [101.3607843, 2.9539451],
          [101.3577894, 2.9446337],
          [101.3532123, 2.9362817],
          [101.3494829, 2.9315413],
          [101.3484092, 2.930977],
          [101.3446232, 2.9304126],
          [101.3394246, 2.9290582],
          [101.3369383, 2.9266316],
          [101.3355256, 2.9234713],
          [101.3354581, 2.9195744],
          [101.3369383, 2.9155141],
          [101.3380014, 2.9138918],
          [101.3395941, 2.9121281],
          [101.3429875, 2.9089398],
          [101.3446985, 2.9069486],
          [101.346262, 2.9046223],
          [101.3473842, 2.9025943],
          [101.3484607, 2.900158],
          [101.3486353, 2.8986402],
          [101.3483701, 2.8976882],
          [101.3475051, 2.896665],
          [101.3459972, 2.8958867],
          [101.3442278, 2.8954209],
          [101.3425228, 2.8954389],
          [101.3412685, 2.895743],
          [101.3401091, 2.8966866],
          [101.3391374, 2.8981989],
          [101.3368129, 2.9027072],
          [101.334468, 2.906204],
          [101.3324859, 2.9085181],
          [101.3304026, 2.9106252],
          [101.3282387, 2.9122749],
          [101.3268384, 2.9130523],
          [101.3245769, 2.9138639],
          [101.3235186, 2.9140383],
          [101.3221641, 2.9140974],
          [101.3218491, 2.9142512],
          [101.3217248, 2.914232],
          [101.3216448, 2.9140989],
          [101.3212585, 2.9141669],
          [101.3210928, 2.9146281],
          [101.3210706, 2.9147434],
          [101.3209181, 2.9146917],
          [101.3210054, 2.9145173],
          [101.3210084, 2.9143029],
          [101.3208619, 2.9141817],
          [101.3187542, 2.9142645],
          [101.3177685, 2.9141196],
          [101.3162546, 2.9133694],
          [101.3141037, 2.9122098],
          [101.3130381, 2.9112313],
          [101.311114, 2.9098506],
          [101.3111051, 2.9097413],
          [101.3112916, 2.909555],
          [101.3100275, 2.9081063],
          [101.3097729, 2.9082127],
          [101.3045409, 2.901042],
          [101.3022382, 2.8983186],
          [101.3002545, 2.8961521],
          [101.3007726, 2.8956998],
          [101.3006275, 2.8955135],
          [101.3007104, 2.8954307],
          [101.300592, 2.8952799],
          [101.3000147, 2.8957589],
          [101.2999348, 2.8959038],
          [101.2995974, 2.8955401],
          [101.2994375, 2.8949074],
          [101.2988406, 2.8944652],
          [101.29864, 2.8945479],
          [101.2972946, 2.8932572],
          [101.2974444, 2.8927288],
          [101.2973419, 2.89245],
          [101.297162, 2.8923664],
          [101.2968563, 2.8924625],
          [101.2967139, 2.8927134],
          [101.2962739, 2.8926475],
          [101.2956975, 2.8919633],
          [101.2957395, 2.8912719],
          [101.2936753, 2.8895208],
          [101.2934155, 2.8888619],
          [101.293274, 2.8889024],
          [101.29322, 2.8889586],
          [101.2932801, 2.8892835],
          [101.293244, 2.8893326],
          [101.2931552, 2.8893805],
          [101.2930228, 2.8893769],
          [101.2929076, 2.889346],
          [101.2927376, 2.8892406],
          [101.2926258, 2.8891884],
          [101.2925201, 2.8891986],
          [101.2923814, 2.889233],
          [101.2922411, 2.8892199],
          [101.2921674, 2.8891728],
          [101.2922568, 2.8890202],
          [101.2922391, 2.8889513],
          [101.2921001, 2.888825],
          [101.2920104, 2.8887773],
          [101.2918244, 2.8887268],
          [101.2916615, 2.8886661],
          [101.291379, 2.8885411],
          [101.2911915, 2.8883841],
          [101.2909676, 2.8880466],
          [101.2908349, 2.8878017],
          [101.2907439, 2.8875407],
          [101.2907523, 2.8874493],
          [101.2908074, 2.8874028],
          [101.2908625, 2.8873563],
          [101.2909003, 2.8872927],
          [101.2908902, 2.8871899],
          [101.290839, 2.8871212],
          [101.2907015, 2.8871572],
          [101.2905622, 2.8871728],
          [101.2902195, 2.8871976],
          [101.2898898, 2.887206],
          [101.2895762, 2.8871788],
          [101.289373, 2.8871399],
          [101.2892173, 2.8871396],
          [101.2889745, 2.8873583],
          [101.2886442, 2.8874752],
          [101.2882596, 2.8875913],
          [101.2878322, 2.8876252],
          [101.2876136, 2.8875561],
          [101.2875274, 2.8875288],
          [101.2873183, 2.8873623],
          [101.2872057, 2.8870956],
          [101.2870963, 2.886949],
          [101.2869772, 2.886888],
          [101.2867338, 2.8868805],
          [101.2856577, 2.8860072],
          [101.2856241, 2.8859327],
          [101.2856656, 2.8858668],
          [101.2858113, 2.8857902],
          [101.2857823, 2.8857166],
          [101.2856895, 2.8856048],
          [101.2855749, 2.8854904],
          [101.2856218, 2.8853882],
          [101.2857675, 2.8853207],
          [101.2856965, 2.8851515],
          [101.2854651, 2.8850349],
          [101.2855, 2.8849112],
          [101.2855267, 2.8848543],
          [101.2854412, 2.8847325],
          [101.2855343, 2.8845949],
          [101.2855762, 2.8845083],
          [101.2855289, 2.8842482],
          [101.2853381, 2.8841138],
          [101.284285, 2.8842993],
          [101.2836486, 2.8849014],
          [101.2826264, 2.8858685],
          [101.282485, 2.8860328],
          [101.2824743, 2.8861492],
          [101.2825176, 2.8862366],
          [101.2827116, 2.8864422],
          [101.2827327, 2.8865274],
          [101.282715, 2.8866511],
          [101.2826018, 2.8868518],
          [101.2819657, 2.8878364],
          [101.2816618, 2.8883117],
          [101.2815053, 2.8886197],
          [101.2812955, 2.8890381],
          [101.2811092, 2.8892486],
          [101.2807716, 2.8894177],
          [101.2806405, 2.8895324],
          [101.280472, 2.8898185],
          [101.2801356, 2.8901847],
          [101.2796503, 2.8905956],
          [101.2774417, 2.8913614],
          [101.2758528, 2.8928029],
          [101.2747478, 2.8939946],
          [101.2742642, 2.8949345],
          [101.2751314, 2.896078],
          [101.2772595, 2.8968599],
          [101.2778937, 2.8973378],
          [101.2780687, 2.8979999],
          [101.2789115, 2.8985897],
        ],
      ],
      [
        [
          [101.3063617, 2.9784566],
          [101.3067209, 2.9775],
          [101.3060823, 2.9763442],
          [101.3049829, 2.9751755],
          [101.3040424, 2.9748838],
          [101.3045037, 2.9738704],
          [101.3014594, 2.9670531],
          [101.3007214, 2.9637826],
          [101.2960167, 2.9551687],
          [101.2949558, 2.9524509],
          [101.2941256, 2.9501477],
          [101.2926957, 2.9484894],
          [101.2920499, 2.9465547],
          [101.2895131, 2.9427775],
          [101.284054, 2.9339749],
          [101.2783028, 2.9231991],
          [101.2759051, 2.9194142],
          [101.2745902, 2.9182555],
          [101.2726566, 2.9177148],
          [101.2720379, 2.9165561],
          [101.2711098, 2.9164789],
          [101.2659277, 2.9169423],
          [101.2642261, 2.918101],
          [101.2642261, 2.921268],
          [101.2643808, 2.9264433],
          [101.2638394, 2.9300738],
          [101.2633498, 2.9339003],
          [101.2632389, 2.9381677],
          [101.2632481, 2.9458221],
          [101.2635533, 2.9477188],
          [101.2642316, 2.9492429],
          [101.2655881, 2.9505299],
          [101.2683351, 2.9523926],
          [101.2702342, 2.953849],
          [101.2739547, 2.9561368],
          [101.2773071, 2.9584884],
          [101.2791031, 2.9598037],
          [101.2801008, 2.9611987],
          [101.2810986, 2.9647858],
          [101.282256, 2.967496],
          [101.2825752, 2.9687715],
          [101.2832138, 2.9695287],
          [101.2852492, 2.9704853],
          [101.2868855, 2.9720397],
          [101.2888012, 2.9743514],
          [101.2903577, 2.9755072],
          [101.2922335, 2.9760652],
          [101.2941891, 2.9762246],
          [101.295586, 2.976663],
          [101.2990581, 2.9788153],
          [101.3007344, 2.9798117],
          [101.3023707, 2.9804494],
          [101.3038075, 2.9803697],
          [101.3056832, 2.9792537],
          [101.3063617, 2.9784566],
        ],
      ],
      [
        [
          [101.307025, 2.9798283],
          [101.3057631, 2.9803298],
          [101.3040868, 2.9812066],
          [101.3042066, 2.9816451],
          [101.3056034, 2.9826016],
          [101.3078783, 2.9839966],
          [101.309355, 2.9850328],
          [101.3099935, 2.9853517],
          [101.3111509, 2.9853517],
          [101.3117935, 2.9845246],
          [101.3120731, 2.9836051],
          [101.3110865, 2.9823571],
          [101.3093106, 2.980912],
          [101.307025, 2.9798283],
        ],
      ],
      [
        [
          [101.2209751, 2.999668],
          [101.2212239, 3.0013284],
          [101.2215522, 3.0023716],
          [101.2220696, 3.0028651],
          [101.2229571, 3.0036909],
          [101.2235184, 3.0042951],
          [101.2242032, 3.0048774],
          [101.2250983, 3.0054197],
          [101.2252896, 3.0053469],
          [101.2254536, 3.0053378],
          [101.2253461, 3.0055325],
          [101.2254335, 3.0056489],
          [101.2269453, 3.0063918],
          [101.2272685, 3.006658],
          [101.227387, 3.0067662],
          [101.2284275, 3.0073067],
          [101.2286115, 3.0072859],
          [101.2290197, 3.0075451],
          [101.229632, 3.0078872],
          [101.2316093, 3.0087882],
          [101.2318739, 3.0088085],
          [101.2323859, 3.0090032],
          [101.2331453, 3.0092627],
          [101.2336503, 3.0094828],
          [101.2348082, 3.0100812],
          [101.2349794, 3.0102092],
          [101.2356071, 3.0105349],
          [101.2383472, 3.0114134],
          [101.23892, 3.0115703],
          [101.24168, 3.0129824],
          [101.24266, 3.0133677],
          [101.2429581, 3.013712],
          [101.2431548, 3.0139149],
          [101.2434604, 3.014127],
          [101.244878, 3.0146179],
          [101.2454585, 3.0148752],
          [101.2485261, 3.0153887],
          [101.2535267, 3.015459],
          [101.2541379, 3.0153914],
          [101.2550515, 3.0152158],
          [101.2560354, 3.0152611],
          [101.2564653, 3.0152812],
          [101.257043, 3.0153448],
          [101.2572361, 3.0152739],
          [101.2577587, 3.0153208],
          [101.2579395, 3.0154467],
          [101.258468, 3.0154922],
          [101.2586577, 3.0155925],
          [101.2588142, 3.015836],
          [101.259295, 3.0159007],
          [101.2590592, 3.0160757],
          [101.2591172, 3.0162559],
          [101.2598643, 3.0163916],
          [101.2603706, 3.0160292],
          [101.2607587, 3.0154761],
          [101.2608239, 3.0138624],
          [101.2610956, 3.0128697],
          [101.2615004, 3.0118148],
          [101.2622336, 3.0104935],
          [101.2624202, 3.009483],
          [101.2626326, 3.0059647],
          [101.2625827, 3.0040777],
          [101.2622885, 3.0041871],
          [101.2625473, 3.003925],
          [101.2625593, 3.0037648],
          [101.262291, 3.002738],
          [101.2616007, 3.001953],
          [101.2610492, 3.0016597],
          [101.2608327, 3.0012942],
          [101.260043596889901, 3.0005],
          [101.2596251, 3.0000788],
          [101.258840465196158, 2.9995],
          [101.2580702, 2.9989318],
          [101.2572249, 2.9984325],
          [101.2572043, 2.9982009],
          [101.2559175, 2.9972835],
          [101.2555684, 2.9970199],
          [101.2552775, 2.9968194],
          [101.2525033, 2.9949802],
          [101.2495909, 2.9929081],
          [101.2445086, 2.9889748],
          [101.2443444, 2.9888518],
          [101.2428906, 2.987789],
          [101.242795, 2.9876778],
          [101.2410567, 2.9855701],
          [101.2402732, 2.9848907],
          [101.2400052, 2.9848186],
          [101.239815, 2.9845482],
          [101.239843, 2.984355],
          [101.2387187, 2.9830854],
          [101.2385959, 2.9831131],
          [101.2384465, 2.9829407],
          [101.2382301, 2.9828218],
          [101.2382129, 2.9825165],
          [101.2382184, 2.9824466],
          [101.2368355, 2.9805929],
          [101.2349285, 2.9784002],
          [101.2346924, 2.9781371],
          [101.2345703, 2.9778005],
          [101.2344507, 2.9777184],
          [101.2342986, 2.9774286],
          [101.2342478, 2.9772527],
          [101.2331545, 2.976099],
          [101.2324489, 2.9751193],
          [101.2322758, 2.9748982],
          [101.2314732, 2.9737229],
          [101.2311717, 2.9736354],
          [101.2312542, 2.9735273],
          [101.2312021, 2.973248],
          [101.2304064, 2.9721762],
          [101.2301151, 2.972153],
          [101.2302827, 2.9719446],
          [101.2299643, 2.9715087],
          [101.2275562, 2.9687585],
          [101.2273783, 2.968653],
          [101.2271875, 2.9684559],
          [101.2269113, 2.9682461],
          [101.2266307, 2.9677457],
          [101.2258891, 2.9666735],
          [101.2257141, 2.966632],
          [101.2255447, 2.9666829],
          [101.2252531, 2.9669195],
          [101.2252713, 2.9668285],
          [101.2254864, 2.9666411],
          [101.2256012, 2.9665628],
          [101.2257724, 2.9665064],
          [101.2252956, 2.9657383],
          [101.2249458, 2.9653719],
          [101.2247891, 2.9650553],
          [101.2236384, 2.9636777],
          [101.2236265, 2.9638378],
          [101.2237832, 2.9641417],
          [101.2237777, 2.9642709],
          [101.2236465, 2.9641381],
          [101.2233805, 2.9636431],
          [101.2232675, 2.9632336],
          [101.223004, 2.9628936],
          [101.2225259, 2.9623529],
          [101.2220656, 2.9619289],
          [101.2219794, 2.961711],
          [101.2211883, 2.9608734],
          [101.2202906, 2.9599018],
          [101.2194426, 2.9588534],
          [101.2192531, 2.9584621],
          [101.2189761, 2.9583293],
          [101.2188194, 2.9578889],
          [101.218678, 2.9578119],
          [101.2185024, 2.9577106],
          [101.2182637, 2.9575359],
          [101.2176251, 2.9566983],
          [101.217143, 2.9560745],
          [101.2169553, 2.9559581],
          [101.2169072, 2.9556541],
          [101.216518, 2.9553776],
          [101.2156451, 2.9545641],
          [101.2155759, 2.954424],
          [101.215246, 2.9541128],
          [101.2151823, 2.9539181],
          [101.2146083, 2.9535032],
          [101.2144588, 2.9532302],
          [101.2142507, 2.9533266],
          [101.2133637, 2.9524822],
          [101.2129755, 2.9524968],
          [101.2126882, 2.952155],
          [101.2117714, 2.9519485],
          [101.2116034, 2.9516451],
          [101.2113938, 2.9516015],
          [101.2113701, 2.9520091],
          [101.2109974, 2.9522237],
          [101.2105259, 2.9517075],
          [101.2098923, 2.9518963],
          [101.2092635, 2.9519392],
          [101.2081203, 2.9517951],
          [101.2074651, 2.9511037],
          [101.2070424, 2.9509519],
          [101.2066688, 2.9507769],
          [101.2063389, 2.9506302],
          [101.2061946, 2.9503728],
          [101.205839, 2.9502982],
          [101.2056122, 2.950468],
          [101.2051659, 2.9501566],
          [101.2042489, 2.949647],
          [101.2030836, 2.949302],
          [101.2022159, 2.9491521],
          [101.2015351, 2.9494075],
          [101.2000393, 2.9495655],
          [101.1991439, 2.9506416],
          [101.1986874, 2.9513977],
          [101.1984712, 2.9521863],
          [101.1984046, 2.9531559],
          [101.1990456, 2.9557473],
          [101.2002572, 2.9580793],
          [101.2024878, 2.9618874],
          [101.2094007, 2.9711545],
          [101.2109716, 2.9734445],
          [101.2130889, 2.9753009],
          [101.2140543, 2.976308],
          [101.2146739, 2.9772685],
          [101.2149806, 2.9788204],
          [101.2150024, 2.9792929],
          [101.2150099, 2.9794759],
          [101.2149823, 2.9800092],
          [101.216318, 2.981403],
          [101.2166956, 2.9814952],
          [101.2188771, 2.9859079],
          [101.2189506, 2.9861421],
          [101.2187154, 2.9865392],
          [101.2185608, 2.9869105],
          [101.2179539, 2.9879533],
          [101.2190746, 2.9881488],
          [101.2185019, 2.9895368],
          [101.2179923, 2.9902282],
          [101.2181469, 2.991412],
          [101.2191341, 2.9916083],
          [101.2187782, 2.9935357],
          [101.2187705, 2.9940055],
          [101.2190953, 2.994284],
          [101.2197755, 2.9947775],
          [101.2201549, 2.9948526],
          [101.220461, 2.9955135],
          [101.2206259, 2.9956834],
          [101.2206415, 2.9961331],
          [101.2209455, 2.9962496],
          [101.2210613, 2.9964564],
          [101.2212991, 2.997914],
          [101.2209751, 2.999668],
        ],
      ],
      [
        [
          [101.336034592844371, 3.0005],
          [101.3359843, 3.0025469],
          [101.3366253, 3.0062876],
          [101.3365652, 3.0076878],
          [101.3368457, 3.0079278],
          [101.3373064, 3.0076678],
          [101.3372663, 3.0070877],
          [101.337707, 3.0064876],
          [101.3379474, 3.0022669],
          [101.3379474, 3.0005],
          [101.3379474, 3.0002665],
          [101.336893894510879, 2.9995],
          [101.3365452, 2.9992463],
          [101.3360845, 2.9994264],
          [101.3360379, 3.0003654],
          [101.336034592844371, 3.0005],
        ],
      ],
      [
        [
          [101.2633575, 3.0121316],
          [101.2634667, 3.0123141],
          [101.2638002, 3.0123463],
          [101.2644712, 3.0121868],
          [101.2651251, 3.0118001],
          [101.2659619, 3.0109849],
          [101.2663724, 3.0105038],
          [101.2673905, 3.0092076],
          [101.2674265, 3.009129],
          [101.2670896, 3.0082397],
          [101.2666006, 3.0073211],
          [101.2659159, 3.0065905],
          [101.2657063, 3.0066214],
          [101.2655897, 3.0067561],
          [101.2657573, 3.0070145],
          [101.2657937, 3.0072401],
          [101.2657448, 3.0073499],
          [101.265586, 3.0072583],
          [101.2654512, 3.006949],
          [101.2653865, 3.0067246],
          [101.2653563, 3.0063599],
          [101.2647942, 3.005824],
          [101.2646727, 3.0057621],
          [101.2645624, 3.0058266],
          [101.2643811, 3.0065111],
          [101.2642449, 3.0076896],
          [101.2641367, 3.0089446],
          [101.2640259, 3.0098899],
          [101.2638094, 3.0106151],
          [101.263513, 3.0116734],
          [101.2633592, 3.0119532],
          [101.2633575, 3.0121316],
        ],
      ],
      [
        [
          [101.276498734254218, 2.9995],
          [101.2778277, 3.0024386],
          [101.281272, 3.007135],
          [101.2823864, 3.009373],
          [101.2831343, 3.0120663],
          [101.2848617, 3.0169095],
          [101.2867884, 3.0216865],
          [101.2882046, 3.0250978],
          [101.2884494, 3.0259326],
          [101.2911069, 3.0337614],
          [101.2946946, 3.041391],
          [101.2952925, 3.0439121],
          [101.2984816, 3.0457034],
          [101.2998768, 3.0474284],
          [101.300209, 3.0493524],
          [101.3001425, 3.0535984],
          [101.3009398, 3.0565839],
          [101.301737, 3.059503],
          [101.3031322, 3.059503],
          [101.3038631, 3.0620241],
          [101.3043946, 3.0632846],
          [101.3074507, 3.0644124],
          [101.3106398, 3.06627],
          [101.3130316, 3.0672652],
          [101.3212035, 3.0690565],
          [101.3227316, 3.0689238],
          [101.3251233, 3.068194],
          [101.3287775, 3.0668671],
          [101.3315014, 3.0658056],
          [101.3343583, 3.0644788],
          [101.335687, 3.0627538],
          [101.3366172, 3.059503],
          [101.3374809, 3.0578444],
          [101.3380788, 3.0544609],
          [101.3377466, 3.050812],
          [101.3372816, 3.0481582],
          [101.3369356, 3.0466826],
          [101.3360192, 3.0427843],
          [101.3343583, 3.0351546],
          [101.3349562, 3.0313729],
          [101.3347569, 3.0305104],
          [101.3363514, 3.0232788],
          [101.3370158, 3.0182365],
          [101.3366172, 3.0176393],
          [101.3351555, 3.0176393],
          [101.3364843, 3.0154499],
          [101.3366836, 3.0130614],
          [101.3372151, 3.0112037],
          [101.3374809, 3.0096778],
          [101.3370158, 3.0092133],
          [101.3354505, 3.0078112],
          [101.3358687, 3.0064364],
          [101.3353614, 3.0030739],
          [101.3350465, 3.0005165],
          [101.335045505071335, 3.0005],
          [101.3349924, 2.9996193],
          [101.334986708182569, 2.9995],
          [101.3349001, 2.9976847],
          [101.333747, 2.9965792],
          [101.331533, 2.9957501],
          [101.3294112, 2.9945064],
          [101.3286732, 2.9932167],
          [101.3260441, 2.9931245],
          [101.3250755, 2.9921572],
          [101.3226308, 2.9916966],
          [101.3222023, 2.9912527],
          [101.3229537, 2.9906832],
          [101.3228615, 2.9898541],
          [101.3158043, 2.9850636],
          [101.3134981, 2.9847412],
          [101.3126302, 2.9849984],
          [101.3111403, 2.9863932],
          [101.3097585, 2.9858838],
          [101.3063161, 2.9837357],
          [101.3036279, 2.9819217],
          [101.3004594, 2.9805944],
          [101.294113, 2.9773264],
          [101.2894627, 2.975914],
          [101.285077, 2.9714844],
          [101.2833937, 2.9703971],
          [101.2823521, 2.9695277],
          [101.281801, 2.9681858],
          [101.2804892, 2.9651965],
          [101.2801812, 2.9634083],
          [101.2788209, 2.9603878],
          [101.274609, 2.9574858],
          [101.273108, 2.9565549],
          [101.2698638, 2.9549204],
          [101.2654413, 2.9530261],
          [101.2634542, 2.9528276],
          [101.2618645, 2.9541506],
          [101.2614671, 2.9558044],
          [101.2612684, 2.9588472],
          [101.2620458, 2.9619141],
          [101.2622158, 2.9635371],
          [101.2622091, 2.9694369],
          [101.2625269, 2.971217],
          [101.2631892, 2.9750536],
          [101.2644478, 2.9798163],
          [101.2710715, 2.9901353],
          [101.2745158, 2.9961546],
          [101.275973, 2.9983375],
          [101.276498734254218, 2.9995],
        ],
      ],
      [
        [
          [101.2680556, 3.0101976],
          [101.2676123, 3.0093692],
          [101.2674527, 3.0093716],
          [101.2669731, 3.010066],
          [101.26602, 3.0112283],
          [101.265547, 3.0116457],
          [101.2646972, 3.0121624],
          [101.2643115, 3.0123658],
          [101.2636363, 3.0124945],
          [101.2632328, 3.0125245],
          [101.2631351, 3.012612],
          [101.2628339, 3.013384],
          [101.2626559, 3.0140695],
          [101.2626648, 3.0145458],
          [101.2627215, 3.0150631],
          [101.2629332, 3.0155839],
          [101.2635342, 3.0166008],
          [101.264084, 3.0172875],
          [101.266217, 3.0192134],
          [101.2666308, 3.019641],
          [101.2666028, 3.0190555],
          [101.2665317, 3.0189227],
          [101.2665226, 3.0188175],
          [101.266652, 3.018657],
          [101.2667923, 3.0186661],
          [101.2668579, 3.0187243],
          [101.2666684, 3.0187662],
          [101.2666514, 3.0188638],
          [101.2667571, 3.0197465],
          [101.2681786, 3.0204758],
          [101.2693594, 3.0212707],
          [101.269633, 3.0213497],
          [101.2698856, 3.0212777],
          [101.2700816, 3.0210563],
          [101.270166, 3.0206473],
          [101.2701372, 3.0189526],
          [101.2701742, 3.0173635],
          [101.2701493, 3.0161524],
          [101.2700041, 3.0148235],
          [101.2697129, 3.0147051],
          [101.2698727, 3.0145224],
          [101.269725, 3.0138793],
          [101.2692429, 3.0128738],
          [101.2688773, 3.0115804],
          [101.2680556, 3.0101976],
        ],
      ],
      [
        [
          [101.2371286, 3.0465325],
          [101.2378407, 3.0474029],
          [101.2385803, 3.0477599],
          [101.2404383, 3.0504778],
          [101.2434076, 3.0522826],
          [101.2467101, 3.0541563],
          [101.2489534, 3.0550434],
          [101.2531909, 3.056349],
          [101.25371, 3.0564875],
          [101.2538236, 3.0565159],
          [101.2556149, 3.0570517],
          [101.2595604, 3.0584887],
          [101.260965, 3.0585272],
          [101.2625329, 3.0584227],
          [101.2657187, 3.0582166],
          [101.2672779, 3.0579197],
          [101.2683005, 3.0574931],
          [101.2685252, 3.0570289],
          [101.2686676, 3.056496],
          [101.2686807, 3.0560452],
          [101.2688082, 3.055583],
          [101.2691813, 3.0546492],
          [101.2696724, 3.054055],
          [101.2697622, 3.0540786],
          [101.2699063, 3.0541865],
          [101.2700692, 3.0540786],
          [101.2700881, 3.0534985],
          [101.2705603, 3.0534608],
          [101.2709169, 3.0536468],
          [101.2710846, 3.0536164],
          [101.2712499, 3.0534419],
          [101.2716927, 3.0531969],
          [101.2718166, 3.0529986],
          [101.2717316, 3.0527817],
          [101.2714766, 3.0525883],
          [101.271623, 3.05256],
          [101.2720197, 3.0525789],
          [101.2724825, 3.0523383],
          [101.2727375, 3.051763],
          [101.2728981, 3.0517064],
          [101.2729973, 3.0513668],
          [101.2732618, 3.0513385],
          [101.2734223, 3.0510414],
          [101.2734318, 3.0508622],
          [101.274041, 3.050235],
          [101.2741355, 3.0500558],
          [101.2738096, 3.0498105],
          [101.2738805, 3.0497256],
          [101.2744047, 3.0495276],
          [101.2744897, 3.0492682],
          [101.2743952, 3.0490748],
          [101.2741969, 3.0489003],
          [101.2743811, 3.0488767],
          [101.2748864, 3.0489852],
          [101.2750438, 3.0488474],
          [101.2752123, 3.0485372],
          [101.2751934, 3.0482023],
          [101.275302, 3.0480467],
          [101.2755854, 3.0478439],
          [101.2758499, 3.0474147],
          [101.2762277, 3.0472025],
          [101.2764024, 3.0468111],
          [101.2767094, 3.046646],
          [101.2769975, 3.0462404],
          [101.2777956, 3.0458679],
          [101.2788861, 3.0450234],
          [101.2797712, 3.0436457],
          [101.2802023, 3.0427792],
          [101.2803919, 3.0424822],
          [101.2796828, 3.0409777],
          [101.2795899, 3.0406403],
          [101.2794892, 3.0402703],
          [101.2783476, 3.0383402],
          [101.2770962, 3.036569],
          [101.2762635, 3.0361295],
          [101.2758337, 3.0359503],
          [101.2755789, 3.0360083],
          [101.2753992, 3.0360493],
          [101.2751442, 3.0356484],
          [101.2748845, 3.0348137],
          [101.2746058, 3.0342241],
          [101.2743886, 3.0339082],
          [101.2741571, 3.034111],
          [101.2740155, 3.0339553],
          [101.2741288, 3.0336818],
          [101.2739871, 3.0334931],
          [101.2740202, 3.0332102],
          [101.2739446, 3.0331772],
          [101.2737604, 3.0333564],
          [101.2735715, 3.0331253],
          [101.2735715, 3.0327904],
          [101.2735054, 3.032715],
          [101.2733023, 3.0329461],
          [101.2731134, 3.0327385],
          [101.2734393, 3.0322528],
          [101.2734393, 3.0321113],
          [101.2731276, 3.0321773],
          [101.27283, 3.0324273],
          [101.2728206, 3.0323282],
          [101.2730331, 3.0319274],
          [101.2730662, 3.0316963],
          [101.2728867, 3.0314652],
          [101.2727493, 3.0309489],
          [101.2724333, 3.030621],
          [101.2720461, 3.0304323],
          [101.2715454, 3.0299607],
          [101.2704167, 3.0291731],
          [101.2692947, 3.0285379],
          [101.268929, 3.0283383],
          [101.2674508, 3.027513],
          [101.2663683, 3.0271013],
          [101.2657113, 3.0273141],
          [101.2649619, 3.0262868],
          [101.2641165, 3.0249851],
          [101.2632475, 3.0241268],
          [101.2629027, 3.0238721],
          [101.2626013, 3.0234973],
          [101.262421, 3.0232731],
          [101.2624414, 3.0231579],
          [101.262242, 3.0229336],
          [101.2620464, 3.0228481],
          [101.2617035, 3.0226865],
          [101.2605599, 3.0217687],
          [101.2596799, 3.0207755],
          [101.2588034, 3.0198303],
          [101.2587701, 3.0198875],
          [101.2586048, 3.0202012],
          [101.2584411, 3.0204734],
          [101.258375, 3.0206998],
          [101.2584766, 3.0212587],
          [101.2584889, 3.0212723],
          [101.2588662, 3.021735],
          [101.2589347, 3.0217138],
          [101.2589984, 3.0218034],
          [101.259126, 3.022183],
          [101.2591425, 3.0224354],
          [101.2591189, 3.0226358],
          [101.2590598, 3.0226193],
          [101.2590457, 3.0223316],
          [101.2589937, 3.0221736],
          [101.2588827, 3.0219637],
          [101.2586395, 3.0217751],
          [101.2583632, 3.0214167],
          [101.2583374, 3.0213595],
          [101.2582145, 3.0210441],
          [101.2580751, 3.0208436],
          [101.2580397, 3.0205418],
          [101.2581656, 3.0200842],
          [101.2579834, 3.0199311],
          [101.2580035, 3.0198111],
          [101.2576695, 3.0196143],
          [101.2568614, 3.0193475],
          [101.2566655, 3.0192524],
          [101.256504, 3.0191741],
          [101.2563891, 3.0194646],
          [101.2565708, 3.0197911],
          [101.257005, 3.0200745],
          [101.2570083, 3.0202613],
          [101.2570751, 3.0204314],
          [101.2574358, 3.0207582],
          [101.2576246, 3.0212024],
          [101.2578277, 3.0216033],
          [101.2577909, 3.0217113],
          [101.257601, 3.0222683],
          [101.2574357, 3.0222306],
          [101.2576293, 3.0215797],
          [101.2574034, 3.0214341],
          [101.2571018, 3.0209483],
          [101.2569665, 3.0208059],
          [101.256661, 3.0205114],
          [101.2567979, 3.0203146],
          [101.2566697, 3.0202042],
          [101.2562636, 3.0198544],
          [101.2560023, 3.0194225],
          [101.2556617, 3.0192791],
          [101.2556049, 3.0194025],
          [101.2553645, 3.0193391],
          [101.2548201, 3.0191724],
          [101.2547867, 3.0194658],
          [101.2551875, 3.0199127],
          [101.2558417, 3.0203323],
          [101.2560141, 3.0205162],
          [101.2562172, 3.0209996],
          [101.2560897, 3.0211458],
          [101.255948, 3.0213628],
          [101.255726, 3.0217259],
          [101.2554379, 3.0222518],
          [101.2552042, 3.0224687],
          [101.2551144, 3.0226244],
          [101.2550389, 3.0225466],
          [101.2550058, 3.0224357],
          [101.2545335, 3.0225937],
          [101.2543564, 3.0225796],
          [101.2544438, 3.0222825],
          [101.2546634, 3.022398],
          [101.2548901, 3.0223626],
          [101.2552986, 3.0221457],
          [101.2557567, 3.0211789],
          [101.2557331, 3.0209761],
          [101.2555631, 3.020752],
          [101.254989, 3.0201494],
          [101.2546374, 3.0198748],
          [101.2546138, 3.0197781],
          [101.2544036, 3.0195989],
          [101.2544249, 3.0194975],
          [101.2542761, 3.0193961],
          [101.2543092, 3.0192782],
          [101.254177, 3.0192098],
          [101.2540754, 3.0194439],
          [101.2536997, 3.0192188],
          [101.2537388, 3.0190548],
          [101.2537415, 3.0190437],
          [101.2532693, 3.0189355],
          [101.2531554, 3.0190671],
          [101.2528982, 3.0190871],
          [101.252949, 3.0193347],
          [101.2525326, 3.0194318],
          [101.2521736, 3.0194056],
          [101.2521084, 3.0192922],
          [101.251835, 3.0191665],
          [101.2517494, 3.0191271],
          [101.2509732, 3.0189533],
          [101.2507806, 3.0191678],
          [101.2501839, 3.0192794],
          [101.2498698, 3.0191874],
          [101.249163, 3.0191988],
          [101.2490743, 3.0190806],
          [101.2491511, 3.0190038],
          [101.2491912, 3.0188871],
          [101.2488542, 3.0186781],
          [101.2487437, 3.0185853],
          [101.2485868, 3.0188404],
          [101.2486619, 3.0189305],
          [101.2486193, 3.0190001],
          [101.2481089, 3.0188585],
          [101.2482293, 3.0184823],
          [101.2482845, 3.0183102],
          [101.2481693, 3.0182518],
          [101.248201, 3.0181785],
          [101.2480324, 3.0181051],
          [101.2479906, 3.0181751],
          [101.2479372, 3.0183102],
          [101.2478988, 3.0183002],
          [101.2479155, 3.0181868],
          [101.2477986, 3.0181735],
          [101.2477836, 3.0181184],
          [101.2474964, 3.0181018],
          [101.2475172, 3.0182953],
          [101.2474117, 3.0182994],
          [101.2469522, 3.0182948],
          [101.246917, 3.0182285],
          [101.2470122, 3.0181451],
          [101.2468385, 3.01799],
          [101.2466899, 3.0181601],
          [101.2465396, 3.0180517],
          [101.2464094, 3.0181751],
          [101.2462791, 3.0180651],
          [101.246376, 3.017945],
          [101.2463142, 3.0178874],
          [101.2460587, 3.0181251],
          [101.2459803, 3.0180284],
          [101.2459135, 3.0180701],
          [101.2458417, 3.0180501],
          [101.245697, 3.0181602],
          [101.2455879, 3.0180784],
          [101.24583, 3.0178033],
          [101.2457632, 3.0177449],
          [101.2456363, 3.0179233],
          [101.245454, 3.017784],
          [101.2453655, 3.0179032],
          [101.245304, 3.0178817],
          [101.2452299, 3.0179792],
          [101.2451654, 3.01798],
          [101.2450968, 3.0180654],
          [101.2448846, 3.0179338],
          [101.2448421, 3.017731],
          [101.2449367, 3.0176882],
          [101.2450986, 3.0176582],
          [101.2451454, 3.0175548],
          [101.2450151, 3.0174748],
          [101.2449016, 3.0175048],
          [101.2448699, 3.0175698],
          [101.2445242, 3.0173314],
          [101.2443522, 3.0176232],
          [101.2434953, 3.0173719],
          [101.2429672, 3.0170896],
          [101.2426744, 3.0170518],
          [101.2402974, 3.0157208],
          [101.2400242, 3.0156598],
          [101.2392456, 3.0154908],
          [101.2389575, 3.0151936],
          [101.2379658, 3.0147079],
          [101.2377202, 3.0144956],
          [101.2372054, 3.0142834],
          [101.2370495, 3.0143164],
          [101.2365639, 3.0140941],
          [101.2363831, 3.0139872],
          [101.2353846, 3.0132604],
          [101.2336444, 3.0125101],
          [101.2326857, 3.012246],
          [101.2326126, 3.0122243],
          [101.2324059, 3.0121385],
          [101.2290161, 3.0103359],
          [101.228591, 3.0102274],
          [101.2284471, 3.0101433],
          [101.2283073, 3.0100748],
          [101.2282699, 3.0100435],
          [101.2279912, 3.0098029],
          [101.225474, 3.0083409],
          [101.2252142, 3.0083126],
          [101.2248931, 3.008289],
          [101.2245105, 3.0078598],
          [101.2236037, 3.0073929],
          [101.2233865, 3.0073599],
          [101.2231919, 3.0072403],
          [101.2229331, 3.0070769],
          [101.220799, 3.0060136],
          [101.2193674, 3.0055158],
          [101.2169635, 3.0045914],
          [101.2166109, 3.004549],
          [101.2163409, 3.0045112],
          [101.2151783, 3.0043839],
          [101.2143896, 3.0043179],
          [101.2130411, 3.0043468],
          [101.2120772, 3.0052396],
          [101.2115916, 3.0062866],
          [101.2115066, 3.0065967],
          [101.2113129, 3.0068901],
          [101.2109322, 3.0069935],
          [101.2105616, 3.0069268],
          [101.2100272, 3.00667],
          [101.2097367, 3.0067601],
          [101.2096164, 3.0068916],
          [101.2095731, 3.0075404],
          [101.209677, 3.0084115],
          [101.2099905, 3.010135],
          [101.21042, 3.011672],
          [101.2109823, 3.0130864],
          [101.2115868, 3.0143603],
          [101.2132863, 3.0173518],
          [101.2137708, 3.0184956],
          [101.2147026, 3.0197462],
          [101.215494, 3.0204965],
          [101.2169097, 3.022468],
          [101.2186493, 3.0248069],
          [101.2207612, 3.0273351],
          [101.2232871, 3.0306692],
          [101.2244583, 3.0321689],
          [101.2265222, 3.0340978],
          [101.2268393, 3.0348241],
          [101.2274384, 3.0347722],
          [101.2274785, 3.0348166],
          [101.2275446, 3.0349051],
          [101.2277784, 3.0352627],
          [101.2270929, 3.035677],
          [101.2278898, 3.036452],
          [101.2287683, 3.0364803],
          [101.2294389, 3.036735],
          [101.2345109, 3.0434644],
          [101.2347379, 3.0441629],
          [101.2343978, 3.0444742],
          [101.2343884, 3.0447618],
          [101.2349929, 3.0449835],
          [101.2358808, 3.0448939],
          [101.2369531, 3.0462788],
          [101.2371286, 3.0465325],
        ],
      ],
      [
        [
          [100.8294804, 3.8497019],
          [100.8463315, 3.8506015],
          [100.8582288, 3.8546684],
          [100.8857749, 3.8534635],
          [100.8945336, 3.8564755],
          [100.9306116, 3.8688824],
          [100.9357338, 3.8701891],
          [100.9385478, 3.8706503],
          [100.9405624, 3.8705676],
          [100.9424112, 3.8701848],
          [100.9436835, 3.8694242],
          [100.9443743, 3.8685911],
          [100.9454296, 3.8663386],
          [100.9456072, 3.8642056],
          [100.9450217, 3.8616738],
          [100.9429026, 3.8557315],
          [100.9404383, 3.8485385],
          [100.9390399, 3.8441995],
          [100.9383601, 3.8410699],
          [100.938172, 3.8393041],
          [100.9383654, 3.8375201],
          [100.9386432, 3.8352211],
          [100.9391347, 3.8337427],
          [100.9401349, 3.8322824],
          [100.9423512, 3.8310154],
          [100.9452579, 3.8298572],
          [100.9522961, 3.8296859],
          [100.9608791, 3.8290008],
          [100.968779, 3.8266068],
          [100.9739254, 3.8219784],
          [100.9777548, 3.8176331],
          [100.9784868, 3.8160964],
          [100.978705, 3.8156383],
          [100.9793843, 3.8130692],
          [100.9789036, 3.808961],
          [100.9775598, 3.8061073],
          [100.9757828, 3.8044774],
          [100.9691124, 3.801892],
          [100.964484, 3.7990266],
          [100.9603641, 3.7959435],
          [100.9587977, 3.7931136],
          [100.9579253, 3.7891976],
          [100.9581242, 3.7840795],
          [100.9591362, 3.7809423],
          [100.960851, 3.7786532],
          [100.9631686, 3.7754653],
          [100.9658573, 3.7724772],
          [100.9692905, 3.770593],
          [100.9703705, 3.7699828],
          [100.9740757, 3.7693337],
          [100.9785115, 3.7698402],
          [100.9833655, 3.7716763],
          [100.9843234, 3.7732978],
          [100.984736, 3.7746666],
          [100.9849433, 3.7762107],
          [100.9846798, 3.7796628],
          [100.9848368, 3.788153],
          [100.9851028, 3.7909002],
          [100.9856803, 3.7926585],
          [100.9861903, 3.7942113],
          [100.9882502, 3.7969518],
          [100.9896838, 3.7983421],
          [100.991939, 3.8004982],
          [100.9948679, 3.801462],
          [100.9966205, 3.8011945],
          [100.998988, 3.8003969],
          [101.0030973, 3.7987061],
          [101.0057154, 3.7986433],
          [101.0098026, 3.8005682],
          [101.015228, 3.803924],
          [101.0197025, 3.8057927],
          [101.0235203, 3.8060202],
          [101.0261105, 3.804679],
          [101.0285137, 3.8015959],
          [101.0302303, 3.7981702],
          [101.0308645, 3.7969445],
          [101.031021, 3.7948063],
          [101.0303678, 3.7923128],
          [101.0267784, 3.7881775],
          [101.0258786, 3.7866626],
          [101.0254716, 3.7851304],
          [101.0263432, 3.7831354],
          [101.0279239, 3.7822164],
          [101.0304837, 3.7823863],
          [101.0330971, 3.7841198],
          [101.0349048, 3.7850773],
          [101.0364819, 3.7855024],
          [101.0404876, 3.7854049],
          [101.0418772, 3.7855156],
          [101.043008, 3.7858991],
          [101.0441501, 3.7864274],
          [101.0459041, 3.7878275],
          [101.0479641, 3.7902255],
          [101.0506888, 3.7930049],
          [101.0530476, 3.7943824],
          [101.0565851, 3.7959251],
          [101.0581564, 3.7959617],
          [101.0589647, 3.7955564],
          [101.0604078, 3.7938693],
          [101.0609619, 3.7923367],
          [101.060864, 3.7905918],
          [101.0598421, 3.788737],
          [101.0582207, 3.7875538],
          [101.0557754, 3.7850815],
          [101.0544269, 3.7822861],
          [101.0540588, 3.7796003],
          [101.0540166, 3.7775089],
          [101.0556362, 3.7748752],
          [101.05793, 3.771638],
          [101.0583875, 3.7701733],
          [101.0585737, 3.7685818],
          [101.0586113, 3.7664007],
          [101.0583774, 3.764182],
          [101.0582631, 3.762304],
          [101.0573546, 3.758694],
          [101.0571829, 3.7562959],
          [101.0573546, 3.7540691],
          [101.0587261, 3.7526074],
          [101.0602387, 3.7523312],
          [101.061612, 3.7530163],
          [101.0633274, 3.754513],
          [101.0639536, 3.7558052],
          [101.0642969, 3.7578608],
          [101.0640952, 3.7603844],
          [101.0641501, 3.7612325],
          [101.0649595, 3.7630928],
          [101.0657171, 3.7641735],
          [101.06814, 3.7654],
          [101.0714016, 3.7657426],
          [101.0748348, 3.766599],
          [101.0769457, 3.767555],
          [101.0784171, 3.7685659],
          [101.0796136, 3.770045],
          [101.0802498, 3.7714908],
          [101.0816255, 3.7746381],
          [101.0829847, 3.7772385],
          [101.0843129, 3.7785851],
          [101.0864571, 3.7808136],
          [101.0883968, 3.7825868],
          [101.0897296, 3.7828846],
          [101.0905336, 3.7826978],
          [101.0925434, 3.780699],
          [101.0926965, 3.7794212],
          [101.0925242, 3.7784454],
          [101.092064, 3.7771169],
          [101.0892897, 3.7729608],
          [101.087542, 3.7699011],
          [101.0873703, 3.7673318],
          [101.0876418, 3.7662006],
          [101.0883523, 3.7650364],
          [101.0923438, 3.76265],
          [101.0941772, 3.761148],
          [101.0973499, 3.7567184],
          [101.098663, 3.7550017],
          [101.1004574, 3.7535386],
          [101.1017346, 3.7523766],
          [101.100059, 3.7494135],
          [101.0980945, 3.7495711],
          [101.0964854, 3.7513442],
          [101.0953797, 3.7525263],
          [101.0944069, 3.7543827],
          [101.0933738, 3.7551071],
          [101.0862817, 3.7573361],
          [101.0847599, 3.7576565],
          [101.0803344, 3.7580326],
          [101.0767255, 3.7591331],
          [101.0752456, 3.7595023],
          [101.0745266, 3.7592933],
          [101.0742265, 3.7589451],
          [101.0738775, 3.7580326],
          [101.0741357, 3.7570365],
          [101.0757482, 3.7535678],
          [101.0783519, 3.7490193],
          [101.079734, 3.7465535],
          [101.0799225, 3.7455017],
          [101.078886, 3.7436261],
          [101.0766977, 3.7409489],
          [101.0759449, 3.7397945],
          [101.0762792, 3.7395687],
          [101.0735917, 3.7354521],
          [101.073138, 3.7343933],
          [101.0729006, 3.7334599],
          [101.0730333, 3.7322688],
          [101.0735287, 3.7311143],
          [101.0743332, 3.7300848],
          [101.0745222, 3.7299776],
          [101.0748709, 3.7299847],
          [101.0759184, 3.7296801],
          [101.0756555, 3.7286429],
          [101.0760799, 3.7285028],
          [101.0765825, 3.7287884],
          [101.076786, 3.7288139],
          [101.077482, 3.7286956],
          [101.077867, 3.7283213],
          [101.0782767, 3.7281982],
          [101.079022, 3.7283312],
          [101.0802165, 3.7289518],
          [101.0805916, 3.7296019],
          [101.0808631, 3.7309614],
          [101.0812481, 3.7322715],
          [101.0812826, 3.7333305],
          [101.0814998, 3.734813],
          [101.0819342, 3.735739],
          [101.0824574, 3.7383889],
          [101.0828325, 3.7402556],
          [101.0833459, 3.7415904],
          [101.0840319, 3.7423489],
          [101.0847378, 3.7427676],
          [101.085567, 3.7429153],
          [101.0864259, 3.7428956],
          [101.0879757, 3.7420238],
          [101.0890814, 3.7415855],
          [101.090187, 3.7414919],
          [101.0918011, 3.7417234],
          [101.0931486, 3.7422799],
          [101.0948663, 3.7438955],
          [101.0960756, 3.7447131],
          [101.0967518, 3.7450037],
          [101.0985484, 3.7451416],
          [101.0993944, 3.7450615],
          [101.0998052, 3.7417531],
          [101.0996835, 3.7386163],
          [101.0999697, 3.7355787],
          [101.1002906, 3.7341027],
          [101.1010792, 3.7332824],
          [101.1017494, 3.7326362],
          [101.1027901, 3.7323433],
          [101.1035723, 3.7323286],
          [101.1056099, 3.7327692],
          [101.107074, 3.7339414],
          [101.1073007, 3.7349889],
          [101.1073127, 3.7359271],
          [101.1064159, 3.739141],
          [101.1060401, 3.7411834],
          [101.1060621, 3.7423346],
          [101.1064888, 3.7441067],
          [101.1075421, 3.745723],
          [101.1084405, 3.746505],
          [101.1094417, 3.7471659],
          [101.1107491, 3.7473737],
          [101.1119001, 3.7467646],
          [101.1128786, 3.7458195],
          [101.1160044, 3.7418316],
          [101.1177707, 3.7390092],
          [101.1195426, 3.7362224],
          [101.1211566, 3.7358975],
          [101.1220999, 3.7363227],
          [101.1230476, 3.7372176],
          [101.1235733, 3.737967],
          [101.1238651, 3.7388711],
          [101.1237094, 3.7399236],
          [101.1225781, 3.7431815],
          [101.1221549, 3.745231],
          [101.1227077, 3.7472642],
          [101.1256698, 3.7507849],
          [101.1294441, 3.7571275],
          [101.1311259, 3.7625942],
          [101.1319089, 3.7636559],
          [101.1325723, 3.7640137],
          [101.1330606, 3.7640006],
          [101.1334459, 3.7635704],
          [101.134813, 3.754167],
          [101.1352173, 3.7528013],
          [101.135899, 3.7519192],
          [101.1441657, 3.7473923],
          [101.1448546, 3.7468411],
          [101.1448232, 3.7460158],
          [101.1444568, 3.7450598],
          [101.1429458, 3.7440304],
          [101.1396983, 3.7422913],
          [101.1368545, 3.7388308],
          [101.1363899, 3.7385041],
          [101.1351911, 3.7376109],
          [101.1338324, 3.7367134],
          [101.1337285, 3.7359328],
          [101.1339714, 3.735046],
          [101.135076, 3.7344453],
          [101.1392648, 3.7342092],
          [101.1454514, 3.7317446],
          [101.1480167, 3.7306545],
          [101.150352, 3.7293999],
          [101.1524582, 3.7284395],
          [101.1536357, 3.72849],
          [101.1540725, 3.7287633],
          [101.1543057, 3.7291401],
          [101.15452, 3.7302494],
          [101.1543353, 3.7310239],
          [101.1535431, 3.7325051],
          [101.1527621, 3.7348432],
          [101.1519441, 3.7375174],
          [101.1507262, 3.7399036],
          [101.1506148, 3.7409649],
          [101.1507447, 3.7420902],
          [101.1516845, 3.7435838],
          [101.1534095, 3.7449467],
          [101.1542242, 3.7449529],
          [101.1550937, 3.7447436],
          [101.1557674, 3.7442819],
          [101.1562897, 3.7431319],
          [101.1564004, 3.7396389],
          [101.1565821, 3.7377095],
          [101.1572813, 3.7358418],
          [101.1601616, 3.7316555],
          [101.1613942, 3.729129],
          [101.1617829, 3.7279691],
          [101.1618158, 3.7263525],
          [101.1620309, 3.7256605],
          [101.1624677, 3.7252653],
          [101.1636802, 3.7249492],
          [101.1640293, 3.7257224],
          [101.1641689, 3.7268578],
          [101.1637989, 3.7315875],
          [101.163785, 3.7336075],
          [101.1638408, 3.7348404],
          [101.1642457, 3.7374595],
          [101.1646994, 3.7389989],
          [101.1649088, 3.7400855],
          [101.1652718, 3.7423284],
          [101.1651043, 3.7435335],
          [101.1644551, 3.745031],
          [101.1640293, 3.7457206],
          [101.1636802, 3.7470023],
          [101.1635057, 3.7481446],
          [101.1637361, 3.7488064],
          [101.164141, 3.7491825],
          [101.1647622, 3.7494054],
          [101.1654742, 3.7495586],
          [101.1661932, 3.7493636],
          [101.166989, 3.7490223],
          [101.1672123, 3.7481307],
          [101.16768, 3.746654],
          [101.1677917, 3.7459679],
          [101.1677289, 3.7453933],
          [101.1677812, 3.7443066],
          [101.1679802, 3.7420881],
          [101.1681023, 3.7415761],
          [101.1681652, 3.7404059],
          [101.1683851, 3.7391939],
          [101.1692646, 3.7369232],
          [101.171254, 3.7339697],
          [101.1715542, 3.7332244],
          [101.1714006, 3.7311138],
          [101.1705769, 3.7296232],
          [101.1702349, 3.7288012],
          [101.1703605, 3.7277146],
          [101.1708073, 3.7270877],
          [101.1716728, 3.7271295],
          [101.173027, 3.7278609],
          [101.1782065, 3.7293028],
          [101.1800703, 3.7295953],
          [101.1871205, 3.7304939],
          [101.1902826, 3.7316432],
          [101.1910378, 3.7317122],
          [101.1914278, 3.7311605],
          [101.1913142, 3.7303872],
          [101.1904159, 3.7297371],
          [101.1860032, 3.7275847],
          [101.1845471, 3.7264518],
          [101.1828738, 3.7248067],
          [101.1811562, 3.7221124],
          [101.1789054, 3.719694],
          [101.1768718, 3.7171229],
          [101.1765756, 3.7166008],
          [101.1764621, 3.7157487],
          [101.1761413, 3.7150493],
          [101.1759014, 3.714942],
          [101.1754651, 3.7149015],
          [101.1750134, 3.7150665],
          [101.1743742, 3.7154433],
          [101.1738017, 3.7162043],
          [101.1737079, 3.7164186],
          [101.1732168, 3.7179405],
          [101.1722592, 3.7192729],
          [101.1712843, 3.7201767],
          [101.170961, 3.7203294],
          [101.1706377, 3.72029],
          [101.170465, 3.7201718],
          [101.1703663, 3.7197753],
          [101.1702916, 3.7194944],
          [101.1703663, 3.7192877],
          [101.1704335, 3.7171648],
          [101.170352, 3.7161417],
          [101.1704668, 3.7156208],
          [101.1707407, 3.7150926],
          [101.1713108, 3.7138441],
          [101.1716661, 3.713556],
          [101.1730468, 3.7128726],
          [101.1748495, 3.7100949],
          [101.1753307, 3.7096923],
          [101.1756462, 3.7097276],
          [101.1759637, 3.70984],
          [101.1820119, 3.7159594],
          [101.1834881, 3.7171505],
          [101.185069, 3.7181117],
          [101.1874142, 3.7187594],
          [101.189037, 3.7191669],
          [101.1894852, 3.7197549],
          [101.1900351, 3.7216258],
          [101.1904086, 3.7219459],
          [101.1913535, 3.7223037],
          [101.1927642, 3.7222489],
          [101.1934029, 3.72204],
          [101.1935076, 3.7215071],
          [101.193285, 3.7210089],
          [101.1923875, 3.7205722],
          [101.1920931, 3.7204659],
          [101.1920822, 3.7200243],
          [101.1923337, 3.7195388],
          [101.1926595, 3.719334],
          [101.1933296, 3.7185505],
          [101.1940846, 3.7179948],
          [101.1948952, 3.7179578],
          [101.1954642, 3.7182905],
          [101.1967682, 3.7178839],
          [101.1971196, 3.7172758],
          [101.197416, 3.717027],
          [101.1977568, 3.717028],
          [101.1980305, 3.7175841],
          [101.1978343, 3.7182459],
          [101.1978935, 3.7191103],
          [101.1976274, 3.7205094],
          [101.196672, 3.72266],
          [101.1963796, 3.7237054],
          [101.1963049, 3.7251713],
          [101.196324, 3.7256113],
          [101.1965869, 3.726195],
          [101.1967951, 3.7271279],
          [101.1975515, 3.7278906],
          [101.1994884, 3.7272141],
          [101.2002817, 3.7271266],
          [101.2006688, 3.7273003],
          [101.201386, 3.7273421],
          [101.2016244, 3.7270509],
          [101.2015745, 3.7267152],
          [101.2006874, 3.7257554],
          [101.2001927, 3.725523],
          [101.1997004, 3.7250332],
          [101.1993106, 3.7246636],
          [101.1991352, 3.724311],
          [101.1991141, 3.7238996],
          [101.1993499, 3.7236084],
          [101.200428, 3.723022],
          [101.2005801, 3.7228614],
          [101.200627, 3.7224735],
          [101.2004937, 3.7221249],
          [101.2003835, 3.7218153],
          [101.2006165, 3.7216403],
          [101.2009334, 3.7217357],
          [101.2011138, 3.7218989],
          [101.2015797, 3.7227791],
          [101.2019357, 3.7227556],
          [101.2024251, 3.7226149],
          [101.2025071, 3.7224016],
          [101.2024557, 3.72196],
          [101.2025212, 3.7215921],
          [101.2030457, 3.7210987],
          [101.2038391, 3.7209158],
          [101.2039473, 3.7205009],
          [101.2034911, 3.7194013],
          [101.2035504, 3.7189802],
          [101.2038835, 3.7189137],
          [101.204587, 3.7194494],
          [101.2051865, 3.7196155],
          [101.2057402, 3.7195177],
          [101.2060893, 3.719277],
          [101.2064672, 3.7191132],
          [101.2067856, 3.7187734],
          [101.2066375, 3.718138],
          [101.2064746, 3.7175618],
          [101.2066079, 3.7169043],
          [101.2072149, 3.7161138],
          [101.2074286, 3.7159123],
          [101.2075111, 3.7154711],
          [101.2072614, 3.7147781],
          [101.2073926, 3.7141118],
          [101.2077978, 3.7138237],
          [101.2083356, 3.7140932],
          [101.20871, 3.7141993],
          [101.2089547, 3.7141044],
          [101.2093619, 3.7137719],
          [101.2096728, 3.7134247],
          [101.2104945, 3.7134691],
          [101.2107685, 3.7137202],
          [101.2106826, 3.7139934],
          [101.2100466, 3.714681],
          [101.210017, 3.7151979],
          [101.2105016, 3.7153545],
          [101.2110134, 3.7150606],
          [101.2115237, 3.7152875],
          [101.2118127, 3.7156833],
          [101.2119015, 3.7160651],
          [101.2123702, 3.7164187],
          [101.2129121, 3.7167614],
          [101.2132521, 3.7163785],
          [101.2133254, 3.7158509],
          [101.2130752, 3.7154147],
          [101.2133945, 3.7150102],
          [101.214508, 3.714459],
          [101.2146661, 3.7139496],
          [101.2145946, 3.7136009],
          [101.2144367, 3.7134391],
          [101.2138552, 3.7137395],
          [101.2129752, 3.7144825],
          [101.2124826, 3.7144091],
          [101.2121508, 3.7139468],
          [101.2123322, 3.7130986],
          [101.2126768, 3.7124957],
          [101.2127391, 3.7121733],
          [101.2125454, 3.7114942],
          [101.2127705, 3.7110188],
          [101.2131061, 3.7109152],
          [101.2143231, 3.7113701],
          [101.2144456, 3.7112173],
          [101.2144771, 3.710977],
          [101.2143043, 3.7103293],
          [101.2139588, 3.7096658],
          [101.2132469, 3.7089188],
          [101.2126449, 3.7084539],
          [101.2124145, 3.7080726],
          [101.2126135, 3.7077173],
          [101.2131212, 3.7076129],
          [101.2145189, 3.7079838],
          [101.2149953, 3.7079315],
          [101.2160056, 3.7083651],
          [101.2175761, 3.7096658],
          [101.2182043, 3.7103658],
          [101.2193821, 3.7109457],
          [101.2204814, 3.7115412],
          [101.2211567, 3.7123509],
          [101.2217535, 3.712936],
          [101.2228686, 3.7131003],
          [101.2229733, 3.7131115],
          [101.2245657, 3.7132814],
          [101.2258061, 3.713461],
          [101.2266257, 3.7135796],
          [101.2274155, 3.7140269],
          [101.2280772, 3.7139417],
          [101.2286643, 3.7133027],
          [101.2290592, 3.7132814],
          [101.2296356, 3.7140056],
          [101.230308, 3.7150068],
          [101.2307456, 3.7151027],
          [101.2312366, 3.7148257],
          [101.2316955, 3.7145595],
          [101.23256, 3.7145275],
          [101.2341077, 3.7150814],
          [101.2352924, 3.7158056],
          [101.2367227, 3.7162636],
          [101.2370962, 3.7165086],
          [101.237267, 3.7167962],
          [101.2370962, 3.7172861],
          [101.2374378, 3.7176695],
          [101.237822, 3.7172755],
          [101.2383237, 3.7171263],
          [101.2389747, 3.7171902],
          [101.2394444, 3.7177334],
          [101.2399353, 3.7185642],
          [101.2399674, 3.7191181],
          [101.2398286, 3.7196613],
          [101.2399994, 3.7202364],
          [101.2403196, 3.7203323],
          [101.2406718, 3.7201192],
          [101.2406291, 3.7196399],
          [101.240992, 3.719363],
          [101.2419953, 3.7193737],
          [101.242529, 3.7193843],
          [101.2431907, 3.7190861],
          [101.2436283, 3.7190542],
          [101.2437884, 3.719821],
          [101.2443007, 3.7198636],
          [101.2448984, 3.7196399],
          [101.2453787, 3.7189263],
          [101.2456883, 3.7185216],
          [101.2460832, 3.7186707],
          [101.2464247, 3.7187879],
          [101.2464674, 3.7185429],
          [101.2464141, 3.7181062],
          [101.2466702, 3.7177441],
          [101.2470011, 3.7176589],
          [101.2477482, 3.7181062],
          [101.2483246, 3.7182766],
          [101.248933, 3.7181595],
          [101.249486, 3.7181728],
          [101.2496738, 3.7181879],
          [101.2498565, 3.7181358],
          [101.2499896, 3.7178506],
          [101.2499043, 3.7174885],
          [101.2496801, 3.7172328],
          [101.2491144, 3.7172968],
          [101.2486982, 3.7170411],
          [101.2488476, 3.7166044],
          [101.2490931, 3.7159654],
          [101.248997, 3.7150707],
          [101.2489543, 3.7147725],
          [101.248965, 3.7139311],
          [101.2487409, 3.7134198],
          [101.2483673, 3.7131216],
          [101.247919, 3.7128553],
          [101.2472466, 3.7127382],
          [101.2470865, 3.7125571],
          [101.2472573, 3.7123654],
          [101.2477269, 3.712195],
          [101.247951, 3.7118861],
          [101.2478977, 3.711492],
          [101.2475561, 3.711034],
          [101.2469371, 3.7105334],
          [101.2467129, 3.7100222],
          [101.2465635, 3.7092553],
          [101.2462326, 3.7085843],
          [101.2460618, 3.708073],
          [101.2460939, 3.7077109],
          [101.2462753, 3.7072422],
          [101.2466275, 3.7068162],
          [101.2466596, 3.7064328],
          [101.2464781, 3.7059961],
          [101.2462433, 3.7055913],
          [101.2461899, 3.7052292],
          [101.2462433, 3.7048138],
          [101.246286, 3.7044197],
          [101.2459551, 3.7039191],
          [101.245731, 3.7033759],
          [101.245763, 3.7029712],
          [101.2461045, 3.7026729],
          [101.2463073, 3.7024173],
          [101.2462753, 3.7021723],
          [101.2456776, 3.7022256],
          [101.2450906, 3.7023002],
          [101.2446957, 3.7020445],
          [101.2445996, 3.7015333],
          [101.2447597, 3.7007664],
          [101.2451973, 3.7002445],
          [101.2461543, 3.6999571],
          [101.2469165, 3.6996558],
          [101.2471279, 3.6993169],
          [101.2469241, 3.6990823],
          [101.2461618, 3.6987897],
          [101.2456561, 3.6983303],
          [101.2456712, 3.6978709],
          [101.2460939, 3.6977654],
          [101.2465316, 3.698255],
          [101.2470151, 3.6988228],
          [101.2471882, 3.6985336],
          [101.2472562, 3.6979989],
          [101.2470901, 3.6974039],
          [101.2475052, 3.6964022],
          [101.2481014, 3.6946399],
          [101.2481543, 3.6937813],
          [101.2480788, 3.6932766],
          [101.2475727, 3.6924654],
          [101.247372, 3.6917869],
          [101.2475058, 3.6913086],
          [101.2477844, 3.6912085],
          [101.2481523, 3.6915756],
          [101.2483529, 3.6923765],
          [101.2487096, 3.6927213],
          [101.2491443, 3.6925322],
          [101.2491554, 3.6919538],
          [101.2488322, 3.6911752],
          [101.2484086, 3.690352],
          [101.2475058, 3.6899405],
          [101.2465806, 3.6890951],
          [101.2464469, 3.6886724],
          [101.2467367, 3.6877603],
          [101.2466587, 3.6872932],
          [101.2463354, 3.6865702],
          [101.2463354, 3.6863254],
          [101.2463912, 3.6857915],
          [101.2461236, 3.6852576],
          [101.2459676, 3.6849684],
          [101.2461014, 3.6842565],
          [101.2457781, 3.6839006],
          [101.2450313, 3.683667],
          [101.2449979, 3.6833333],
          [101.2452096, 3.6831776],
          [101.245767, 3.6832109],
          [101.2464023, 3.6829662],
          [101.2467367, 3.6821987],
          [101.2468259, 3.6815981],
          [101.2466698, 3.6813089],
          [101.2461794, 3.6809752],
          [101.2459119, 3.6805191],
          [101.2461348, 3.680152],
          [101.2466475, 3.6800853],
          [101.247294, 3.6803411],
          [101.2481188, 3.6802633],
          [101.2485647, 3.6800185],
          [101.2485758, 3.6795625],
          [101.2484421, 3.6791287],
          [101.2486204, 3.6787727],
          [101.2490997, 3.6782722],
          [101.2493338, 3.6780497],
          [101.2499134, 3.6781387],
          [101.2504373, 3.6781498],
          [101.250649, 3.6778495],
          [101.2504707, 3.6774824],
          [101.2499914, 3.677171],
          [101.2494452, 3.6770931],
          [101.2492112, 3.676815],
          [101.2493003, 3.6764702],
          [101.2497462, 3.6761699],
          [101.2501697, 3.6757694],
          [101.2503035, 3.6753801],
          [101.2503035, 3.6748351],
          [101.2505822, 3.6745904],
          [101.2510503, 3.6744458],
          [101.2515742, 3.6742567],
          [101.251786, 3.6740231],
          [101.2518528, 3.6735114],
          [101.2520423, 3.6731777],
          [101.2529786, 3.6725437],
          [101.2533353, 3.6723323],
          [101.2537923, 3.672121],
          [101.2541267, 3.6716204],
          [101.2545837, 3.6713312],
          [101.2549961, 3.6715314],
          [101.2552636, 3.6721321],
          [101.255598, 3.6725993],
          [101.2560884, 3.6727995],
          [101.2566457, 3.672666],
          [101.2570582, 3.6722878],
          [101.2573814, 3.6723546],
          [101.2573814, 3.6726215],
          [101.2569968, 3.673136],
          [101.257157, 3.6733124],
          [101.2581839, 3.6731999],
          [101.2590756, 3.6724435],
          [101.2597333, 3.671854],
          [101.2598782, 3.6712645],
          [101.2598336, 3.6704302],
          [101.259867, 3.6701187],
          [101.260324, 3.6699074],
          [101.2609148, 3.669874],
          [101.2613049, 3.6695515],
          [101.2616018, 3.6694427],
          [101.2619431, 3.6697234],
          [101.262013, 3.6700592],
          [101.2621966, 3.6700965],
          [101.2623749, 3.6698184],
          [101.2624976, 3.669329],
          [101.2627093, 3.6689063],
          [101.2635676, 3.6686171],
          [101.2640267, 3.6682776],
          [101.2643384, 3.6683365],
          [101.2645039, 3.6685726],
          [101.2644147, 3.6691844],
          [101.2643924, 3.6697517],
          [101.264816, 3.6698073],
          [101.2651727, 3.6695515],
          [101.2652507, 3.6691176],
          [101.2653287, 3.6688729],
          [101.2655405, 3.6689063],
          [101.2657746, 3.6692734],
          [101.2662761, 3.6696071],
          [101.2670007, 3.6694513],
          [101.2674911, 3.6692066],
          [101.268015, 3.6693179],
          [101.2684385, 3.6691288],
          [101.2686392, 3.6687394],
          [101.2687395, 3.6682166],
          [101.2689958, 3.6678162],
          [101.2693748, 3.6678051],
          [101.269542, 3.668339],
          [101.2696535, 3.6688618],
          [101.2699433, 3.6689619],
          [101.2707012, 3.6687951],
          [101.2709241, 3.6684614],
          [101.2710133, 3.6680275],
          [101.2710579, 3.6676827],
          [101.2714592, 3.667616],
          [101.271827, 3.6680498],
          [101.2720053, 3.668339],
          [101.272204, 3.6687207],
          [101.2726771, 3.6689459],
          [101.2729471, 3.6692085],
          [101.2729298, 3.6696341],
          [101.2725807, 3.6701091],
          [101.2727248, 3.6704259],
          [101.2733434, 3.6704135],
          [101.2737798, 3.6691508],
          [101.2740074, 3.6693657],
          [101.2741441, 3.6698808],
          [101.2746589, 3.6699949],
          [101.2755774, 3.6690278],
          [101.2764505, 3.668662],
          [101.276912, 3.6683788],
          [101.2772053, 3.6681143],
          [101.278203, 3.6681819],
          [101.2787862, 3.6679533],
          [101.2790519, 3.6678739],
          [101.2791723, 3.6682744],
          [101.2790022, 3.6687397],
          [101.2791102, 3.6690365],
          [101.2793635, 3.6692947],
          [101.2796835, 3.6692517],
          [101.279875, 3.6688303],
          [101.2798098, 3.6681738],
          [101.2799408, 3.6678301],
          [101.2803523, 3.6677021],
          [101.2805776, 3.6677815],
          [101.2806171, 3.6683133],
          [101.28036, 3.6690786],
          [101.2805556, 3.6694907],
          [101.2809095, 3.6696065],
          [101.2811315, 3.6693161],
          [101.281202, 3.6683626],
          [101.2813803, 3.6680959],
          [101.2817743, 3.6682709],
          [101.2821671, 3.6692092],
          [101.2822424, 3.6697002],
          [101.2819819, 3.6703093],
          [101.2819467, 3.6707072],
          [101.2821733, 3.6715807],
          [101.282157, 3.6719881],
          [101.2819003, 3.6723555],
          [101.2814616, 3.6727547],
          [101.2813314, 3.6730685],
          [101.28137, 3.6733233],
          [101.2816322, 3.6735597],
          [101.2832767, 3.6736656],
          [101.2841001, 3.67343],
          [101.2844765, 3.6735181],
          [101.2850881, 3.6744447],
          [101.2860547, 3.6749798],
          [101.2864562, 3.6750433],
          [101.2867084, 3.6748196],
          [101.2872595, 3.6745772],
          [101.2874597, 3.6748214],
          [101.2874918, 3.6751402],
          [101.287449, 3.675437],
          [101.2874065, 3.6757777],
          [101.2876095, 3.6761952],
          [101.2880156, 3.6762473],
          [101.2887902, 3.6757921],
          [101.2892037, 3.6752671],
          [101.2896732, 3.6752268],
          [101.2904093, 3.6757464],
          [101.2905103, 3.676117],
          [101.2905467, 3.6765497],
          [101.2904073, 3.6770837],
          [101.290667, 3.6772237],
          [101.2917439, 3.6765238],
          [101.2926268, 3.6762641],
          [101.2930463, 3.6765729],
          [101.2930533, 3.6770206],
          [101.2928122, 3.6774591],
          [101.2924569, 3.6775252],
          [101.2923615, 3.6777455],
          [101.2926223, 3.6779504],
          [101.2930074, 3.6780799],
          [101.2935366, 3.677937],
          [101.2938294, 3.6774158],
          [101.2942506, 3.6772531],
          [101.294687, 3.6774489],
          [101.2954031, 3.6783889],
          [101.2957889, 3.6782754],
          [101.2956965, 3.677473],
          [101.2958444, 3.6771212],
          [101.2961153, 3.6768572],
          [101.2965234, 3.6769988],
          [101.2970157, 3.6778302],
          [101.2973677, 3.6779828],
          [101.2979326, 3.6774818],
          [101.2982403, 3.6770971],
          [101.2987872, 3.6768835],
          [101.2988085, 3.6764454],
          [101.2984899, 3.6758057],
          [101.2985318, 3.6754934],
          [101.2998727, 3.6743931],
          [101.3001356, 3.673903],
          [101.3001358, 3.6731582],
          [101.3002327, 3.6725895],
          [101.3007483, 3.6722016],
          [101.301448, 3.6720655],
          [101.3028452, 3.6719722],
          [101.3045372, 3.6732672],
          [101.3051524, 3.6741428],
          [101.3055315, 3.6744617],
          [101.305925, 3.6745855],
          [101.3064974, 3.6745547],
          [101.306866, 3.674449],
          [101.3071487, 3.6744504],
          [101.3074291, 3.6746267],
          [101.3075589, 3.6754089],
          [101.3078367, 3.6761136],
          [101.3078249, 3.6766684],
          [101.3075222, 3.6771357],
          [101.3077108, 3.6773208],
          [101.3084742, 3.6768344],
          [101.3089283, 3.6767823],
          [101.3092569, 3.6770209],
          [101.3093779, 3.6779905],
          [101.3090519, 3.678637],
          [101.3090908, 3.6790372],
          [101.3097908, 3.6790239],
          [101.3102038, 3.6792114],
          [101.3104074, 3.6795865],
          [101.310407, 3.6799512],
          [101.3100446, 3.6804967],
          [101.31009, 3.6811387],
          [101.3103524, 3.6814161],
          [101.3111348, 3.6815428],
          [101.3117124, 3.6819245],
          [101.3117947, 3.6821098],
          [101.3115731, 3.6822876],
          [101.3110869, 3.6823007],
          [101.3108228, 3.6824982],
          [101.3104171, 3.6832534],
          [101.310344, 3.6838052],
          [101.3106699, 3.6845232],
          [101.3111286, 3.6848244],
          [101.3117131, 3.6848076],
          [101.3124907, 3.6845553],
          [101.3128013, 3.6845646],
          [101.313401, 3.6850756],
          [101.3138066, 3.6851769],
          [101.3141028, 3.6847371],
          [101.3145662, 3.6842508],
          [101.3148902, 3.6840642],
          [101.3152377, 3.6840766],
          [101.3153728, 3.6842965],
          [101.3153684, 3.6846555],
          [101.3151666, 3.6849667],
          [101.3151491, 3.685225],
          [101.3153073, 3.6854633],
          [101.3158169, 3.6855545],
          [101.316278, 3.685437],
          [101.3169214, 3.6854163],
          [101.3172854, 3.6855461],
          [101.3177775, 3.6859984],
          [101.3185256, 3.6871386],
          [101.3190133, 3.6872777],
          [101.319426, 3.6874765],
          [101.3196777, 3.6881356],
          [101.3200096, 3.6884262],
          [101.3207163, 3.6883863],
          [101.3212069, 3.6885634],
          [101.3215695, 3.6891318],
          [101.3215458, 3.6896895],
          [101.3216057, 3.6900341],
          [101.3218543, 3.6902735],
          [101.3225016, 3.6904525],
          [101.3229881, 3.6907815],
          [101.3233243, 3.6908748],
          [101.3234518, 3.6907604],
          [101.3238055, 3.6904429],
          [101.324866, 3.6896898],
          [101.3254422, 3.6894041],
          [101.3256933, 3.6891325],
          [101.3257235, 3.6888034],
          [101.3258053, 3.6884878],
          [101.3261112, 3.6883423],
          [101.3266803, 3.6889396],
          [101.3269693, 3.6896207],
          [101.3273604, 3.6898086],
          [101.3281053, 3.6896066],
          [101.3284555, 3.6896498],
          [101.3287263, 3.6899041],
          [101.3287413, 3.6902984],
          [101.3288029, 3.6909256],
          [101.3290383, 3.6912108],
          [101.3297892, 3.6918931],
          [101.3301516, 3.6921018],
          [101.3307644, 3.6917736],
          [101.3311681, 3.6918305],
          [101.3321975, 3.6916451],
          [101.3322965, 3.6914468],
          [101.3324876, 3.6910636],
          [101.3327869, 3.6909574],
          [101.3334043, 3.6909501],
          [101.3339666, 3.6906745],
          [101.3340451, 3.6904961],
          [101.3339033, 3.6901765],
          [101.3336686, 3.6900959],
          [101.3332645, 3.6898373],
          [101.3328884, 3.6894575],
          [101.3326966, 3.6891266],
          [101.3327801, 3.6888937],
          [101.3329844, 3.6886514],
          [101.3338412, 3.6884466],
          [101.3342101, 3.6885029],
          [101.3344211, 3.6888421],
          [101.3345013, 3.6891907],
          [101.3345124, 3.6899623],
          [101.3347961, 3.6903125],
          [101.33527, 3.690286],
          [101.3362732, 3.6900124],
          [101.3366972, 3.6900708],
          [101.3370046, 3.6902011],
          [101.3372931, 3.6903235],
          [101.3388256, 3.691388],
          [101.339894, 3.6924346],
          [101.3407834, 3.6941402],
          [101.3408296, 3.6942271],
          [101.3415396, 3.6953435],
          [101.3419105, 3.6960822],
          [101.3420643, 3.6964966],
          [101.3425289, 3.6977488],
          [101.342987, 3.6984007],
          [101.3429502, 3.6986542],
          [101.3417734, 3.6990184],
          [101.3418625, 3.6994364],
          [101.3421967, 3.6999282],
          [101.3430669, 3.7005185],
          [101.3435967, 3.7010408],
          [101.3440465, 3.7025072],
          [101.3447365, 3.7033895],
          [101.3450784, 3.7040719],
          [101.3454696, 3.7043792],
          [101.3455712, 3.7047417],
          [101.3454742, 3.7054149],
          [101.3455674, 3.7057515],
          [101.3457961, 3.7060881],
          [101.3461349, 3.7063217],
          [101.3465138, 3.7065737],
          [101.3466478, 3.7067981],
          [101.3463751, 3.7076682],
          [101.3464491, 3.7080522],
          [101.3469358, 3.7087177],
          [101.3470281, 3.7091373],
          [101.3468403, 3.7093893],
          [101.3463198, 3.7096537],
          [101.3462811, 3.7099916],
          [101.3464898, 3.7103204],
          [101.34697, 3.710903],
          [101.3470682, 3.711215],
          [101.3469959, 3.7116637],
          [101.3466648, 3.7124936],
          [101.3463448, 3.7129097],
          [101.3459844, 3.7131543],
          [101.3456054, 3.7131752],
          [101.3450564, 3.7128006],
          [101.344769, 3.7127149],
          [101.3445851, 3.7128582],
          [101.3445132, 3.7131506],
          [101.3449487, 3.7141083],
          [101.345035, 3.7145137],
          [101.3448342, 3.7155417],
          [101.3448296, 3.716346],
          [101.3446523, 3.7166311],
          [101.3442844, 3.7168381],
          [101.3439164, 3.717045],
          [101.3438062, 3.7174589],
          [101.3441838, 3.7181189],
          [101.3441706, 3.7185668],
          [101.3438586, 3.718642],
          [101.3433155, 3.7184415],
          [101.34304, 3.7185397],
          [101.3428055, 3.7194795],
          [101.3421277, 3.7200778],
          [101.3419636, 3.7204803],
          [101.3417382, 3.7212481],
          [101.3414929, 3.7217211],
          [101.3404067, 3.7230624],
          [101.3404012, 3.7233476],
          [101.3410589, 3.7237726],
          [101.3412534, 3.7241194],
          [101.3411701, 3.7244025],
          [101.3406852, 3.7247042],
          [101.3404251, 3.7254447],
          [101.3405668, 3.7261387],
          [101.3409434, 3.7264081],
          [101.3413043, 3.7267046],
          [101.3412507, 3.7270412],
          [101.3410851, 3.7271915],
          [101.3406977, 3.7271006],
          [101.3402564, 3.7270875],
          [101.3398991, 3.7273447],
          [101.3400624, 3.7279523],
          [101.3401463, 3.728571],
          [101.3399601, 3.7291958],
          [101.3394191, 3.7302774],
          [101.339327, 3.7309864],
          [101.339364, 3.7318346],
          [101.3391956, 3.7324591],
          [101.3385543, 3.7334771],
          [101.3385092, 3.7338977],
          [101.3387536, 3.7348868],
          [101.338626, 3.7355133],
          [101.3383022, 3.7360466],
          [101.3374582, 3.7368135],
          [101.33738, 3.7372728],
          [101.3374852, 3.7377999],
          [101.3384115, 3.7394914],
          [101.338357, 3.7400179],
          [101.3381481, 3.7407342],
          [101.3377057, 3.7419351],
          [101.3372234, 3.7428936],
          [101.3373574, 3.7435],
          [101.337424, 3.7440726],
          [101.3371991, 3.7449535],
          [101.3370282, 3.745339],
          [101.3374678, 3.7458083],
          [101.3376535, 3.746406],
          [101.3374349, 3.7469086],
          [101.3372016, 3.7478448],
          [101.3369561, 3.7487919],
          [101.3362624, 3.7505771],
          [101.3357576, 3.7532742],
          [101.3355213, 3.7541861],
          [101.3349304, 3.7557457],
          [101.3339877, 3.7571102],
          [101.3299231, 3.7612788],
          [101.329376, 3.7624876],
          [101.3290909, 3.7631359],
          [101.3290135, 3.7637627],
          [101.3291601, 3.7651927],
          [101.3293003, 3.7708221],
          [101.329275, 3.7716922],
          [101.3287144, 3.7743375],
          [101.3274658, 3.7784414],
          [101.3267865, 3.779504],
          [101.3267256, 3.7827175],
          [101.3266014, 3.7837102],
          [101.3253485, 3.7857096],
          [101.3263385, 3.7864682],
          [101.3274252, 3.7864714],
          [101.328198, 3.7870077],
          [101.3290322, 3.7874716],
          [101.3303522, 3.7876155],
          [101.3307229, 3.7880388],
          [101.3316354, 3.7885098],
          [101.3327417, 3.7877279],
          [101.3333654, 3.7879159],
          [101.3334354, 3.7883651],
          [101.3331309, 3.7895731],
          [101.3335306, 3.7907304],
          [101.334249, 3.7911866],
          [101.334737, 3.7901123],
          [101.3354089, 3.7896454],
          [101.3363335, 3.7901232],
          [101.3368702, 3.7909178],
          [101.3367057, 3.7914418],
          [101.336273, 3.7919851],
          [101.3362087, 3.792851],
          [101.3364276, 3.7941409],
          [101.3372639, 3.795017],
          [101.3381145, 3.7952374],
          [101.3387785, 3.7948758],
          [101.339237, 3.7941751],
          [101.3397393, 3.7941115],
          [101.3402133, 3.794387],
          [101.3405142, 3.7948196],
          [101.3412279, 3.795496],
          [101.3419802, 3.7953334],
          [101.3427681, 3.7955923],
          [101.343321, 3.7961595],
          [101.3446255, 3.796248],
          [101.345716, 3.7971964],
          [101.346673, 3.7972086],
          [101.3474725, 3.7968047],
          [101.3475486, 3.7960189],
          [101.3478344, 3.7954694],
          [101.3483514, 3.7945448],
          [101.3489596, 3.7943626],
          [101.3492362, 3.7944995],
          [101.3492811, 3.7947342],
          [101.3493376, 3.7950315],
          [101.3493807, 3.7951577],
          [101.3495742, 3.7952756],
          [101.349436, 3.7956158],
          [101.3494978, 3.7960968],
          [101.3499595, 3.7966392],
          [101.3505441, 3.7971277],
          [101.3514234, 3.7979481],
          [101.3519487, 3.7987991],
          [101.3521259, 3.7999833],
          [101.352455, 3.8004877],
          [101.3529542, 3.80064],
          [101.353638, 3.8001937],
          [101.3540597, 3.7998872],
          [101.3546745, 3.7999082],
          [101.3550342, 3.8002431],
          [101.3552508, 3.8010147],
          [101.3550828, 3.8017248],
          [101.3550374, 3.8023114],
          [101.3552685, 3.8028426],
          [101.3557379, 3.803139],
          [101.3560303, 3.8031106],
          [101.3562458, 3.8029594],
          [101.3563924, 3.8026658],
          [101.3563852, 3.8023722],
          [101.3562825, 3.8015062],
          [101.3565413, 3.8009472],
          [101.3570743, 3.8008632],
          [101.3575611, 3.8011784],
          [101.3580563, 3.8018616],
          [101.3586439, 3.8030514],
          [101.3593796, 3.8034751],
          [101.3607002, 3.8039271],
          [101.3625066, 3.8044816],
          [101.3632175, 3.8044059],
          [101.3638207, 3.8039233],
          [101.364131, 3.8032391],
          [101.3647259, 3.8026624],
          [101.3660207, 3.8014366],
          [101.3664446, 3.8007317],
          [101.3674338, 3.8011546],
          [101.3683381, 3.8023954],
          [101.3692707, 3.8028748],
          [101.3703446, 3.8021698],
          [101.3704294, 3.8016904],
          [101.3694968, 3.8005907],
          [101.3699207, 3.7998575],
          [101.3710511, 3.7995473],
          [101.3722663, 3.8000267],
          [101.3736964, 3.8020075],
          [101.3739865, 3.8017125],
          [101.3739865, 3.8012907],
          [101.3737183, 3.8006794],
          [101.3739865, 3.8003079],
          [101.3745369, 3.8004995],
          [101.3747998, 3.8004653],
          [101.3749157, 3.7999418],
          [101.3747483, 3.798685],
          [101.374995, 3.7980769],
          [101.3754092, 3.7989569],
          [101.3756452, 3.7991282],
          [101.3759724, 3.798958],
          [101.3759456, 3.7986358],
          [101.3759381, 3.7982932],
          [101.3764391, 3.7981219],
          [101.3768468, 3.7980577],
          [101.3770292, 3.7978907],
          [101.3769541, 3.7977793],
          [101.3766966, 3.7975866],
          [101.3765893, 3.7973725],
          [101.3764735, 3.7970482],
          [101.376525, 3.7967945],
          [101.3769756, 3.7965589],
          [101.3773178, 3.7959991],
          [101.3772116, 3.7957667],
          [101.3769756, 3.7954242],
          [101.377276, 3.7952743],
          [101.3778554, 3.7951458],
          [101.3785066, 3.7950441],
          [101.378778, 3.7946534],
          [101.3791364, 3.7943194],
          [101.3792286, 3.7946534],
          [101.3793359, 3.7950388],
          [101.3795505, 3.7950816],
          [101.379808, 3.7946962],
          [101.3801299, 3.7944179],
          [101.3806448, 3.7941395],
          [101.380838, 3.793797],
          [101.3807307, 3.7932617],
          [101.3807521, 3.7929191],
          [101.3810622, 3.7925412],
          [101.3815707, 3.7920499],
          [101.3816351, 3.7918572],
          [101.38131, 3.791763],
          [101.3808326, 3.7915842],
          [101.3806727, 3.791248],
          [101.3807521, 3.791035],
          [101.3810955, 3.7909708],
          [101.3814388, 3.7908423],
          [101.381589, 3.7905211],
          [101.3818926, 3.7902342],
          [101.3821254, 3.7906496],
          [101.3824473, 3.7910778],
          [101.3827906, 3.7913133],
          [101.3831554, 3.7911206],
          [101.3834783, 3.7909579],
          [101.3835202, 3.7906924],
          [101.3835416, 3.7902856],
          [101.3836489, 3.7893435],
          [101.3839922, 3.7890224],
          [101.3843141, 3.7888939],
          [101.3844214, 3.7886798],
          [101.3843141, 3.7884015],
          [101.3840137, 3.7879518],
          [101.3839493, 3.7876521],
          [101.384121, 3.7873738],
          [101.3842562, 3.7871789],
          [101.3842068, 3.7869027],
          [101.3840995, 3.7865816],
          [101.384018, 3.7861801],
          [101.3842068, 3.7859392],
          [101.3844643, 3.7855538],
          [101.3845727, 3.7852808],
          [101.3844643, 3.7849329],
          [101.3842927, 3.7845903],
          [101.3840781, 3.7842906],
          [101.3842497, 3.7840122],
          [101.3847765, 3.7837018],
          [101.3850008, 3.7833913],
          [101.3851724, 3.7830273],
          [101.3853226, 3.782642],
          [101.3852797, 3.782278],
          [101.3851424, 3.7819579],
          [101.3853226, 3.7815714],
          [101.3855994, 3.7813701],
          [101.3858805, 3.7809933],
          [101.3860736, 3.7805009],
          [101.3862775, 3.780109],
          [101.3860093, 3.7798799],
          [101.3857089, 3.779623],
          [101.3852834, 3.7791224],
          [101.3851817, 3.7786471],
          [101.3855262, 3.7775655],
          [101.385837, 3.7772671],
          [101.3861559, 3.7771981],
          [101.3865015, 3.7768785],
          [101.386465, 3.7765995],
          [101.3858518, 3.7765161],
          [101.3853937, 3.7765973],
          [101.3850999, 3.7764097],
          [101.3851809, 3.7762354],
          [101.38538, 3.7760295],
          [101.3862922, 3.7755348],
          [101.3870243, 3.7755436],
          [101.3872725, 3.7753495],
          [101.3871405, 3.774987],
          [101.3870558, 3.7745835],
          [101.3872966, 3.7738489],
          [101.387241, 3.7735393],
          [101.3870265, 3.7733367],
          [101.3863162, 3.7735224],
          [101.3859984, 3.7734847],
          [101.385836, 3.7732405],
          [101.3859339, 3.7729795],
          [101.3861461, 3.7727657],
          [101.3866193, 3.772679],
          [101.3868339, 3.7725541],
          [101.3869192, 3.772308],
          [101.3868662, 3.7718753],
          [101.386923, 3.7714805],
          [101.3872641, 3.7712426],
          [101.3876863, 3.7712861],
          [101.3883874, 3.7717125],
          [101.3894569, 3.7720853],
          [101.3902824, 3.7728231],
          [101.3909578, 3.7729845],
          [101.3919957, 3.7735218],
          [101.3924076, 3.7735561],
          [101.3926134, 3.7733974],
          [101.3926022, 3.7727555],
          [101.3926613, 3.7723582],
          [101.3936067, 3.7712144],
          [101.3938121, 3.7711253],
          [101.394229, 3.7711823],
          [101.3944221, 3.771034],
          [101.394763, 3.7707722],
          [101.3951013, 3.7705483],
          [101.3959037, 3.770689],
          [101.3964459, 3.7705701],
          [101.3971238, 3.7701213],
          [101.3977996, 3.7699928],
          [101.3983128, 3.7696777],
          [101.3983643, 3.7690466],
          [101.3984971, 3.7685697],
          [101.3990211, 3.7680189],
          [101.3991711, 3.7675167],
          [101.399855, 3.766169],
          [101.3998559, 3.7659733],
          [101.3999474, 3.7657746],
          [101.4002534, 3.7657482],
          [101.4006584, 3.766019],
          [101.4010479, 3.7658623],
          [101.4012179, 3.765665],
          [101.4012787, 3.7651775],
          [101.4014289, 3.7649738],
          [101.4020059, 3.7649561],
          [101.4024279, 3.7652288],
          [101.4024589, 3.7656607],
          [101.4025047, 3.7658917],
          [101.4028401, 3.7659481],
          [101.4036632, 3.7655799],
          [101.4040471, 3.7656337],
          [101.4042784, 3.7655951],
          [101.4045621, 3.7653395],
          [101.4050158, 3.76475],
          [101.405081, 3.7644417],
          [101.404943, 3.7642389],
          [101.4046344, 3.7642714],
          [101.4042125, 3.764515],
          [101.4040028, 3.7648722],
          [101.4037947, 3.7651973],
          [101.4036226, 3.7652796],
          [101.4034735, 3.7651358],
          [101.4033867, 3.7648645],
          [101.4035801, 3.7642406],
          [101.4035783, 3.7635519],
          [101.4032984, 3.7632616],
          [101.4032751, 3.7629568],
          [101.4035376, 3.7628341],
          [101.4038348, 3.7629294],
          [101.4041691, 3.7629415],
          [101.4044474, 3.7624628],
          [101.4047724, 3.7620257],
          [101.4047722, 3.7616536],
          [101.4049506, 3.7614447],
          [101.405186, 3.7614956],
          [101.4053238, 3.7617249],
          [101.4057448, 3.7618308],
          [101.4059038, 3.7614747],
          [101.4061694, 3.7612382],
          [101.4065652, 3.7611477],
          [101.4068362, 3.7613561],
          [101.4072645, 3.7613025],
          [101.4075217, 3.7610976],
          [101.4073886, 3.7605763],
          [101.4074152, 3.7601828],
          [101.4077773, 3.7599151],
          [101.4084039, 3.7598015],
          [101.4094946, 3.7598988],
          [101.4096595, 3.7596716],
          [101.4093918, 3.7593799],
          [101.4093761, 3.7590557],
          [101.4095562, 3.7588131],
          [101.410079, 3.7586685],
          [101.4107017, 3.7585253],
          [101.4111829, 3.7586934],
          [101.4116442, 3.759244],
          [101.411886, 3.7593808],
          [101.412272, 3.7589242],
          [101.4125724, 3.7588223],
          [101.412806, 3.7588647],
          [101.4129826, 3.7590856],
          [101.4130079, 3.7600484],
          [101.4131226, 3.7604108],
          [101.4136936, 3.7608923],
          [101.4140875, 3.7608003],
          [101.4144525, 3.7604542],
          [101.4147761, 3.7599033],
          [101.4151699, 3.7580615],
          [101.4170736, 3.7583292],
          [101.4177372, 3.7585875],
          [101.4184593, 3.7590574],
          [101.4187228, 3.7591057],
          [101.4189521, 3.758992],
          [101.4192456, 3.7587972],
          [101.4192708, 3.7585456],
          [101.4186911, 3.7573973],
          [101.4187455, 3.7571577],
          [101.4189926, 3.7569787],
          [101.4190513, 3.756738],
          [101.41887, 3.7561875],
          [101.4188676, 3.7555721],
          [101.4193831, 3.7546497],
          [101.4196173, 3.7539568],
          [101.4196141, 3.7534637],
          [101.4200368, 3.7526782],
          [101.4201343, 3.7523146],
          [101.4203536, 3.7520743],
          [101.4204415, 3.7521235],
          [101.4208716, 3.7523642],
          [101.4212932, 3.7524604],
          [101.4219099, 3.7527189],
          [101.4221558, 3.752654],
          [101.4222624, 3.7524755],
          [101.4222552, 3.7517615],
          [101.422503, 3.7514207],
          [101.4227057, 3.7513396],
          [101.4230137, 3.7514694],
          [101.4231417, 3.7518751],
          [101.4233326, 3.7521185],
          [101.4237309, 3.7523457],
          [101.4246076, 3.7524297],
          [101.4248908, 3.7524081],
          [101.4247742, 3.7520568],
          [101.4244434, 3.7519869],
          [101.4240746, 3.7518471],
          [101.4236844, 3.7515444],
          [101.4235868, 3.7512011],
          [101.4237008, 3.7508878],
          [101.4239124, 3.7507642],
          [101.4242056, 3.7507928],
          [101.424792, 3.7512882],
          [101.4259521, 3.7525494],
          [101.4269468, 3.753176],
          [101.4271609, 3.753332],
          [101.4273139, 3.7533022],
          [101.4277921, 3.7525585],
          [101.4280195, 3.7523736],
          [101.4283933, 3.7518398],
          [101.4287017, 3.7517459],
          [101.4297415, 3.7518988],
          [101.4301949, 3.7518639],
          [101.4305162, 3.752297],
          [101.4306872, 3.7524493],
          [101.4315745, 3.7524996],
          [101.4319821, 3.7524931],
          [101.4322281, 3.7520094],
          [101.4326005, 3.7517652],
          [101.4329729, 3.7517644],
          [101.4336526, 3.7521361],
          [101.4340689, 3.7523601],
          [101.434382, 3.7528569],
          [101.4345573, 3.7530926],
          [101.4352205, 3.7534095],
          [101.4359168, 3.7535844],
          [101.4361837, 3.7536637],
          [101.4363855, 3.7532563],
          [101.4367252, 3.7529525],
          [101.4373165, 3.7527717],
          [101.4378265, 3.752096],
          [101.4387652, 3.7515398],
          [101.4391139, 3.7511575],
          [101.4395684, 3.7510051],
          [101.4401019, 3.7513888],
          [101.4404972, 3.7514399],
          [101.4407998, 3.7512986],
          [101.4409986, 3.7513244],
          [101.4411196, 3.7517006],
          [101.4411178, 3.7519498],
          [101.4410329, 3.7522211],
          [101.4411069, 3.7525204],
          [101.4413688, 3.7525346],
          [101.4416325, 3.7522873],
          [101.4418345, 3.7516141],
          [101.4420272, 3.7510791],
          [101.4425381, 3.7505753],
          [101.4427117, 3.7503578],
          [101.4426116, 3.7501372],
          [101.4422314, 3.7499166],
          [101.4421065, 3.749638],
          [101.4420907, 3.7492843],
          [101.4418213, 3.7487533],
          [101.4419014, 3.7483387],
          [101.4420189, 3.7481384],
          [101.4424831, 3.7478449],
          [101.4425751, 3.7475489],
          [101.4425931, 3.7470096],
          [101.4428449, 3.7467492],
          [101.4431857, 3.7467122],
          [101.4435709, 3.7470106],
          [101.4439, 3.7470853],
          [101.4440343, 3.7469482],
          [101.4440828, 3.7467119],
          [101.443939, 3.7464726],
          [101.4434308, 3.7456647],
          [101.4435337, 3.7455225],
          [101.4437065, 3.74547],
          [101.4440545, 3.7455463],
          [101.4442564, 3.745687],
          [101.4446937, 3.745776],
          [101.4451084, 3.7456061],
          [101.4452243, 3.7453989],
          [101.445243, 3.7443113],
          [101.4452337, 3.7437362],
          [101.4453317, 3.7433944],
          [101.4451006, 3.7427761],
          [101.445041, 3.7424111],
          [101.4451794, 3.7421447],
          [101.4454073, 3.7419369],
          [101.4454279, 3.7417212],
          [101.4452607, 3.7412592],
          [101.4451771, 3.7407393],
          [101.4453924, 3.7396508],
          [101.4456119, 3.7391608],
          [101.4460743, 3.7386457],
          [101.4462876, 3.738669],
          [101.4467438, 3.7389812],
          [101.4470608, 3.7390349],
          [101.4476489, 3.7390525],
          [101.448536, 3.7392151],
          [101.4491337, 3.7393383],
          [101.4492925, 3.7392601],
          [101.4493532, 3.7390812],
          [101.4492644, 3.7385015],
          [101.4494445, 3.7378154],
          [101.4492765, 3.7375654],
          [101.4492205, 3.7373565],
          [101.449426, 3.7372054],
          [101.4497622, 3.7373163],
          [101.4499957, 3.7372785],
          [101.4501825, 3.737073],
          [101.4502895, 3.7367032],
          [101.4504643, 3.7363226],
          [101.4505458, 3.7359234],
          [101.4507092, 3.7356786],
          [101.4510502, 3.7355921],
          [101.4515172, 3.7355313],
          [101.4517368, 3.7354421],
          [101.451856, 3.7352484],
          [101.4518537, 3.7350454],
          [101.4517161, 3.7346853],
          [101.451722, 3.7343747],
          [101.4519491, 3.7341729],
          [101.4523335, 3.733988],
          [101.4528059, 3.733849],
          [101.4532783, 3.7336821],
          [101.453739, 3.7331509],
          [101.4538012, 3.7326803],
          [101.4537981, 3.7323775],
          [101.4540976, 3.7317895],
          [101.4540232, 3.7314209],
          [101.4537525, 3.7312646],
          [101.453449, 3.7311958],
          [101.453288, 3.7309563],
          [101.4533382, 3.7305197],
          [101.4535221, 3.7301989],
          [101.4537527, 3.7301204],
          [101.4540621, 3.7301045],
          [101.4543048, 3.729923],
          [101.4543327, 3.7295272],
          [101.4544508, 3.729357],
          [101.4548117, 3.7291775],
          [101.4551577, 3.7291795],
          [101.4552933, 3.7290687],
          [101.4553518, 3.7288083],
          [101.4552515, 3.7285105],
          [101.4553674, 3.7281166],
          [101.4556775, 3.7276307],
          [101.4560062, 3.7272753],
          [101.4560506, 3.7270882],
          [101.4559748, 3.726812],
          [101.4557314, 3.7266273],
          [101.4552359, 3.7264892],
          [101.4550203, 3.7262721],
          [101.4549685, 3.7260424],
          [101.455064, 3.7258064],
          [101.4552799, 3.7256418],
          [101.4555145, 3.7257288],
          [101.4557374, 3.7258156],
          [101.4559236, 3.726064],
          [101.4562315, 3.726328],
          [101.4564788, 3.7265159],
          [101.4567333, 3.7264515],
          [101.4568698, 3.7262515],
          [101.4569288, 3.7260955],
          [101.4567294, 3.7259617],
          [101.4561751, 3.725194],
          [101.4560325, 3.7248769],
          [101.4561873, 3.724678],
          [101.4565169, 3.7246531],
          [101.4568592, 3.7247898],
          [101.4570794, 3.7248132],
          [101.4572557, 3.7246874],
          [101.4573345, 3.7244941],
          [101.4572639, 3.7241796],
          [101.4572891, 3.7237951],
          [101.4574605, 3.7236028],
          [101.4579572, 3.7234507],
          [101.4581214, 3.7232256],
          [101.4581643, 3.7230563],
          [101.4579355, 3.7227981],
          [101.4573354, 3.7229113],
          [101.4569513, 3.7228934],
          [101.4567312, 3.7226607],
          [101.4567146, 3.7223952],
          [101.4568847, 3.7217197],
          [101.4567135, 3.720925],
          [101.4567183, 3.7206946],
          [101.456938, 3.7205302],
          [101.4571225, 3.7205365],
          [101.4577472, 3.7206561],
          [101.4580263, 3.7204682],
          [101.4581596, 3.7201611],
          [101.4582636, 3.7198957],
          [101.4590681, 3.7192668],
          [101.4597596, 3.718466],
          [101.4599184, 3.7181594],
          [101.4599511, 3.7178663],
          [101.459818, 3.7176638],
          [101.4594712, 3.7173295],
          [101.4593445, 3.7169946],
          [101.4595054, 3.7164078],
          [101.4597819, 3.7161889],
          [101.4600491, 3.7161005],
          [101.4604749, 3.7160514],
          [101.4608278, 3.7158683],
          [101.4609763, 3.7157209],
          [101.4611813, 3.7153396],
          [101.4614706, 3.7151024],
          [101.4618767, 3.7149931],
          [101.4623227, 3.7150037],
          [101.4630126, 3.7147666],
          [101.4635625, 3.7145948],
          [101.464017, 3.7143223],
          [101.4642652, 3.7143199],
          [101.4644668, 3.7145132],
          [101.4646152, 3.7147937],
          [101.4645493, 3.7150831],
          [101.4643576, 3.7157544],
          [101.4644087, 3.7159784],
          [101.4646199, 3.7161691],
          [101.4647564, 3.71622],
          [101.4649271, 3.7161386],
          [101.4651152, 3.7158369],
          [101.465284, 3.7156581],
          [101.4660688, 3.7154475],
          [101.4664146, 3.7154634],
          [101.4667462, 3.7156857],
          [101.4673674, 3.7162995],
          [101.4676566, 3.7164733],
          [101.4680298, 3.7164793],
          [101.4685389, 3.7164746],
          [101.4691297, 3.7167145],
          [101.469369, 3.7166947],
          [101.4696008, 3.7166289],
          [101.4696232, 3.7164002],
          [101.4694029, 3.7160504],
          [101.469415, 3.7158203],
          [101.4696078, 3.7155654],
          [101.4700248, 3.7154038],
          [101.4707137, 3.715367],
          [101.4710291, 3.7152463],
          [101.4711922, 3.7150073],
          [101.4713485, 3.714543],
          [101.4715574, 3.7143668],
          [101.4718487, 3.7143439],
          [101.4722278, 3.7146493],
          [101.4725481, 3.7145877],
          [101.4729137, 3.7144544],
          [101.4734662, 3.7139762],
          [101.4736889, 3.7136871],
          [101.4740057, 3.7134866],
          [101.4743322, 3.7135914],
          [101.4746916, 3.7135133],
          [101.4749928, 3.7132879],
          [101.475343, 3.7129041],
          [101.475655, 3.7121721],
          [101.4755565, 3.7111788],
          [101.4756991, 3.7107602],
          [101.4757002, 3.7104141],
          [101.4755101, 3.7101174],
          [101.4754132, 3.7099479],
          [101.4754174, 3.7095381],
          [101.4761571, 3.7088238],
          [101.4763217, 3.7085655],
          [101.4766137, 3.7077987],
          [101.4768911, 3.7072658],
          [101.4769909, 3.7067168],
          [101.4770765, 3.7063867],
          [101.4777078, 3.7057761],
          [101.477971, 3.7055893],
          [101.478148, 3.70529],
          [101.4784454, 3.7041712],
          [101.4791077, 3.7029483],
          [101.4794514, 3.7027002],
          [101.4798416, 3.7019779],
          [101.4801624, 3.7016649],
          [101.4803628, 3.7015355],
          [101.48116, 3.7015452],
          [101.4818073, 3.7017372],
          [101.4825222, 3.7020852],
          [101.4831833, 3.7018352],
          [101.4837276, 3.7016084],
          [101.4841121, 3.7014184],
          [101.4844352, 3.7013281],
          [101.4846574, 3.7011711],
          [101.4848013, 3.7009248],
          [101.4849199, 3.7005454],
          [101.4851986, 3.7002578],
          [101.4855148, 3.6999469],
          [101.4857248, 3.6998124],
          [101.4860492, 3.6997266],
          [101.4862429, 3.6998132],
          [101.486483, 3.7001764],
          [101.4865844, 3.7002695],
          [101.4867378, 3.7003161],
          [101.4868612, 3.7002508],
          [101.4873619, 3.6998919],
          [101.4876822, 3.6997824],
          [101.4881693, 3.6997183],
          [101.4883709, 3.6996677],
          [101.4885463, 3.6995351],
          [101.4885921, 3.6993896],
          [101.488563, 3.699081],
          [101.4885347, 3.6987445],
          [101.4886092, 3.6984551],
          [101.4888239, 3.6982545],
          [101.4891881, 3.6981191],
          [101.4897007, 3.6981775],
          [101.4899943, 3.6980716],
          [101.4902299, 3.697986],
          [101.4904317, 3.6980737],
          [101.4905747, 3.6982434],
          [101.4906368, 3.6989061],
          [101.490752, 3.6991303],
          [101.4908982, 3.6992051],
          [101.4910695, 3.6991912],
          [101.4912858, 3.6991097],
          [101.4916369, 3.698794],
          [101.4918805, 3.6986927],
          [101.4922031, 3.6987493],
          [101.4924848, 3.6989617],
          [101.4926792, 3.6991755],
          [101.4928418, 3.6995201],
          [101.4930632, 3.699711],
          [101.4933467, 3.6998111],
          [101.4936145, 3.6998332],
          [101.4939151, 3.6997808],
          [101.4941939, 3.6996192],
          [101.4944212, 3.6993897],
          [101.4946096, 3.6990839],
          [101.4948999, 3.6988891],
          [101.4951435, 3.6987688],
          [101.4953485, 3.6987553],
          [101.4956097, 3.698921],
          [101.4959505, 3.6992369],
          [101.4965201, 3.6993617],
          [101.4971858, 3.6992668],
          [101.4974205, 3.6992007],
          [101.4975426, 3.6990837],
          [101.4976366, 3.698799],
          [101.4975387, 3.6985985],
          [101.497373, 3.6983398],
          [101.4973882, 3.6981079],
          [101.4974892, 3.6978755],
          [101.4978573, 3.6975495],
          [101.4982374, 3.6973399],
          [101.4984696, 3.6973097],
          [101.4989445, 3.6974519],
          [101.4992382, 3.6975031],
          [101.4995485, 3.6974402],
          [101.4998391, 3.6973155],
          [101.5000918, 3.6971646],
          [101.5002278, 3.6969764],
          [101.5002922, 3.6967317],
          [101.5002567, 3.696304],
          [101.5001969, 3.6954703],
          [101.5003071, 3.6949463],
          [101.5005621, 3.6945294],
          [101.5010571, 3.6939967],
          [101.5021063, 3.6931373],
          [101.5023974, 3.6930245],
          [101.5031509, 3.6931727],
          [101.503511, 3.6931311],
          [101.5040928, 3.6927234],
          [101.5044677, 3.6925988],
          [101.5048706, 3.6926187],
          [101.5051572, 3.6925417],
          [101.5053799, 3.692368],
          [101.5067607, 3.6907496],
          [101.5077745, 3.6894387],
          [101.5089752, 3.6885334],
          [101.5108969, 3.687687],
          [101.5110487, 3.6876201],
          [101.5111005, 3.6875929],
          [101.5117886, 3.6872324],
          [101.5124066, 3.6870143],
          [101.512675, 3.6868813],
          [101.5128397, 3.6867417],
          [101.5135615, 3.6859205],
          [101.5139386, 3.6856107],
          [101.5142857, 3.6852042],
          [101.5144051, 3.6849478],
          [101.5144714, 3.6847127],
          [101.514537, 3.6839744],
          [101.5145702, 3.6838203],
          [101.5146461, 3.6836642],
          [101.514741, 3.6835456],
          [101.5149186, 3.6834295],
          [101.5151896, 3.6833378],
          [101.5152926, 3.6832722],
          [101.5156355, 3.6829592],
          [101.5164465, 3.6818028],
          [101.5166315, 3.6809588],
          [101.516712, 3.6806223],
          [101.5169198, 3.6802858],
          [101.5174625, 3.6796219],
          [101.5186791, 3.6782094],
          [101.5191328, 3.6779165],
          [101.5195782, 3.6778568],
          [101.5201541, 3.67795],
          [101.5220472, 3.6784022],
          [101.5221717, 3.6784384],
          [101.5225083, 3.6786209],
          [101.5238209, 3.6800572],
          [101.5253469, 3.6811912],
          [101.5257355, 3.6813563],
          [101.5260416, 3.6813359],
          [101.5264884, 3.6813364],
          [101.5266384, 3.681406],
          [101.5268559, 3.6816277],
          [101.5271119, 3.6816451],
          [101.5275825, 3.6816386],
          [101.5279179, 3.6814745],
          [101.5281572, 3.6812361],
          [101.5284515, 3.6810115],
          [101.5288473, 3.6810158],
          [101.529515, 3.6811755],
          [101.5300578, 3.6813431],
          [101.5311406, 3.6815078],
          [101.5314938, 3.6815798],
          [101.5318746, 3.681748],
          [101.5322231, 3.6818232],
          [101.5325069, 3.6817469],
          [101.5328955, 3.6814981],
          [101.5331496, 3.6811793],
          [101.5333822, 3.6806926],
          [101.5335446, 3.680324],
          [101.5338281, 3.6801914],
          [101.5340371, 3.680256],
          [101.5342348, 3.680399],
          [101.5343409, 3.6808085],
          [101.534388, 3.6812703],
          [101.534675, 3.6815068],
          [101.535211, 3.6817134],
          [101.5357127, 3.681975],
          [101.5359723, 3.6822232],
          [101.5363815, 3.6826922],
          [101.5366904, 3.6829157],
          [101.5372342, 3.6831211],
          [101.5377987, 3.6834158],
          [101.5380463, 3.6835244],
          [101.5383075, 3.6834804],
          [101.538527, 3.683365],
          [101.5388145, 3.6831479],
          [101.5391002, 3.6829885],
          [101.5393275, 3.6830545],
          [101.5394379, 3.6832277],
          [101.5394108, 3.683519],
          [101.5391226, 3.6837171],
          [101.5387664, 3.6838108],
          [101.538536, 3.6843692],
          [101.5385988, 3.6850976],
          [101.5390541, 3.6864375],
          [101.5392554, 3.6870996],
          [101.5394015, 3.6873495],
          [101.5397834, 3.68754],
          [101.5408637, 3.6879484],
          [101.5410691, 3.688202],
          [101.5414528, 3.6890503],
          [101.5417401, 3.6898986],
          [101.5421947, 3.6910841],
          [101.5425084, 3.6914766],
          [101.5429574, 3.6918481],
          [101.5430622, 3.692096],
          [101.5427792, 3.6930199],
          [101.542861, 3.6932843],
          [101.5432776, 3.693429],
          [101.543726, 3.6934686],
          [101.5443258, 3.6933982],
          [101.544658, 3.6935461],
          [101.5449679, 3.693911],
          [101.545587, 3.6947075],
          [101.5462268, 3.6958062],
          [101.5463143, 3.6959823],
          [101.5464231, 3.6961584],
          [101.546676, 3.6962916],
          [101.546835, 3.6964697],
          [101.5468203, 3.6966635],
          [101.5466722, 3.6967342],
          [101.546503, 3.6967978],
          [101.5461618, 3.6968428],
          [101.5458949, 3.6968666],
          [101.5457788, 3.6970273],
          [101.5457943, 3.6971861],
          [101.5459598, 3.6974119],
          [101.5462341, 3.6978],
          [101.5460576, 3.6982202],
          [101.5459219, 3.6983612],
          [101.5458712, 3.6986152],
          [101.5459408, 3.6993672],
          [101.5461131, 3.7002746],
          [101.5462188, 3.7006351],
          [101.5463743, 3.7007695],
          [101.5466679, 3.7008791],
          [101.5469386, 3.7011634],
          [101.5471802, 3.7015881],
          [101.5472585, 3.7018994],
          [101.5473302, 3.7021158],
          [101.5474657, 3.7022192],
          [101.547673, 3.7022424],
          [101.5482692, 3.7020712],
          [101.5488504, 3.7018796],
          [101.548957, 3.701752],
          [101.5488866, 3.7013984],
          [101.5488952, 3.7005546],
          [101.5489525, 3.7003675],
          [101.5490945, 3.7002316],
          [101.5492647, 3.7003429],
          [101.5497124, 3.7006241],
          [101.5502902, 3.7007859],
          [101.5506379, 3.7008311],
          [101.5507772, 3.7010168],
          [101.550874, 3.7012026],
          [101.5508506, 3.7015141],
          [101.5507926, 3.7019141],
          [101.5508332, 3.702196],
          [101.5509652, 3.702406],
          [101.5521204, 3.7032172],
          [101.5528007, 3.7035521],
          [101.5531373, 3.7037125],
          [101.5532065, 3.7038634],
          [101.5533331, 3.7040836],
          [101.553499, 3.7042149],
          [101.5539587, 3.7043073],
          [101.5546368, 3.7042896],
          [101.5552837, 3.7041218],
          [101.5569924, 3.7034808],
          [101.5582213, 3.7033613],
          [101.5588524, 3.7033747],
          [101.5594976, 3.7036072],
          [101.5602183, 3.704464],
          [101.5604834, 3.7050654],
          [101.5603678, 3.7060055],
          [101.559845, 3.7065685],
          [101.5598783, 3.7070884],
          [101.5616631, 3.7091853],
          [101.5625395, 3.7094238],
          [101.5629165, 3.7098913],
          [101.5638214, 3.7100974],
          [101.5646609, 3.7097958],
          [101.5649677, 3.7098215],
          [101.5653899, 3.7103443],
          [101.5672383, 3.7112046],
          [101.5682265, 3.7112685],
          [101.5684517, 3.7114496],
          [101.5686444, 3.7124129],
          [101.5684685, 3.7133328],
          [101.5687573, 3.7136016],
          [101.5698609, 3.7141975],
          [101.5699688, 3.7143541],
          [101.5696297, 3.7148057],
          [101.5694839, 3.7151275],
          [101.569435, 3.7155303],
          [101.5695744, 3.7164646],
          [101.5703737, 3.7173594],
          [101.5713814, 3.7183057],
          [101.5716181, 3.7197474],
          [101.5721985, 3.7203002],
          [101.5728821, 3.7202449],
          [101.57369, 3.7199609],
          [101.5752716, 3.7200986],
          [101.5767412, 3.7209896],
          [101.5771106, 3.7208159],
          [101.5773897, 3.7201551],
          [101.5781136, 3.7201049],
          [101.5789138, 3.721],
          [101.578933, 3.7235182],
          [101.5798078, 3.7243821],
          [101.5799937, 3.7251972],
          [101.5812253, 3.7260468],
          [101.5816753, 3.726488],
          [101.5818687, 3.7276855],
          [101.5817029, 3.7282385],
          [101.5813359, 3.7287312],
          [101.5809941, 3.7303348],
          [101.5812454, 3.7310385],
          [101.5811663, 3.73277],
          [101.5811348, 3.734105],
          [101.5815923, 3.7353617],
          [101.5814314, 3.7361258],
          [101.5816727, 3.7370257],
          [101.5815169, 3.7379908],
          [101.5819299, 3.7385496],
          [101.5808636, 3.7421518],
          [101.5790218, 3.7434149],
          [101.5777047, 3.7461195],
          [101.5767295, 3.7502818],
          [101.5763675, 3.7519709],
          [101.5767697, 3.754042],
          [101.578004, 3.7556276],
          [101.577878, 3.7580625],
          [101.5795495, 3.7614048],
          [101.5791861, 3.763565],
          [101.5796126, 3.7667784],
          [101.5811802, 3.7700345],
          [101.5995705, 3.7713769],
          [101.6050924, 3.7728683],
          [101.610046, 3.7738909],
          [101.6194409, 3.7724706],
          [101.6247931, 3.7724706],
          [101.6255333, 3.7712206],
          [101.6252486, 3.7691753],
          [101.6265582, 3.768948],
          [101.6278108, 3.7698002],
          [101.6393693, 3.7527555],
          [101.6505293, 3.7396308],
          [101.6543441, 3.7373581],
          [101.6582729, 3.7299719],
          [101.6626002, 3.7284378],
          [101.6665859, 3.7276423],
          [101.6692051, 3.7234946],
          [101.6715395, 3.7200287],
          [101.6737601, 3.719631],
          [101.6820732, 3.7196878],
          [101.6851478, 3.7138923],
          [101.685888, 3.7097445],
          [101.6901584, 3.7088354],
          [101.6932331, 3.7080967],
          [101.6983006, 3.7105968],
          [101.7040514, 3.7092899],
          [101.709005, 3.7109945],
          [101.7121936, 3.7116195],
          [101.7162362, 3.7094036],
          [101.7165209, 3.707699],
          [101.7209052, 3.7073581],
          [101.7255172, 3.7099718],
          [101.7267643, 3.7108063],
          [101.7277096, 3.7113823],
          [101.7278364, 3.7114639],
          [101.7298445, 3.7127559],
          [101.7301673, 3.7125229],
          [101.7311956, 3.7117805],
          [101.7325206, 3.710824],
          [101.7348551, 3.7118468],
          [101.7375881, 3.7109377],
          [101.7411752, 3.7094036],
          [101.7411183, 3.7082104],
          [101.739524, 3.7067899],
          [101.7384991, 3.7060512],
          [101.7383222, 3.7052187],
          [101.7381006, 3.7041762],
          [101.7393532, 3.7015625],
          [101.741403, 3.6959941],
          [101.7453317, 3.6963919],
          [101.7504611, 3.6920805],
          [101.7502049, 3.6907452],
          [101.7511796, 3.6907738],
          [101.7521408, 3.690802],
          [101.7570944, 3.6855461],
          [101.7583471, 3.6856029],
          [101.7590125, 3.6834604],
          [101.7605424, 3.6823354],
          [101.760882, 3.6823889],
          [101.7626533, 3.682668],
          [101.7628373, 3.682697],
          [101.765092, 3.68113],
          [101.7677594, 3.6820903],
          [101.7685527, 3.6810828],
          [101.7679037, 3.6775564],
          [101.7690575, 3.6744618],
          [101.7720143, 3.6712952],
          [101.7684806, 3.6659696],
          [101.7706441, 3.6635946],
          [101.7736008, 3.6614356],
          [101.7779999, 3.6553182],
          [101.7810287, 3.6517917],
          [101.7842739, 3.6516478],
          [101.7858605, 3.6492008],
          [101.784346, 3.6450266],
          [101.7859326, 3.6408523],
          [101.7858605, 3.6371818],
          [101.7842018, 3.6350947],
          [101.7861489, 3.6322878],
          [101.7875191, 3.6299128],
          [101.7908365, 3.6278256],
          [101.7973269, 3.6272499],
          [101.7981201, 3.6234354],
          [101.7996346, 3.6175337],
          [101.8017259, 3.6132873],
          [101.8090817, 3.6138631],
          [101.811101, 3.611488],
          [101.8097308, 3.6074576],
          [101.8114616, 3.6048665],
          [101.8158606, 3.6050105],
          [101.8165097, 3.6031392],
          [101.8152116, 3.5942864],
          [101.8132644, 3.5933508],
          [101.8118942, 3.5865132],
          [101.8085769, 3.5852897],
          [101.8095144, 3.5783081],
          [101.8081442, 3.5758609],
          [101.8101635, 3.5724781],
          [101.8108846, 3.5702468],
          [101.8091539, 3.5685914],
          [101.8103798, 3.5649926],
          [101.808, 3.5620416],
          [101.8069904, 3.557507],
          [101.8066458, 3.5494517],
          [101.8038921, 3.5496044],
          [101.8027703, 3.5506732],
          [101.8010365, 3.5491972],
          [101.800709, 3.548933],
          [101.7997617, 3.5468051],
          [101.7954272, 3.546856],
          [101.7942034, 3.5475686],
          [101.7914497, 3.5459908],
          [101.7885431, 3.5456345],
          [101.7872683, 3.5469578],
          [101.7866089, 3.5466005],
          [101.7860444, 3.5460926],
          [101.7856875, 3.5438023],
          [101.7834437, 3.5420718],
          [101.7820159, 3.5413084],
          [101.781761, 3.5391707],
          [101.7789563, 3.5364223],
          [101.7781914, 3.5347936],
          [101.7778855, 3.5331649],
          [101.7777835, 3.5279735],
          [101.7749788, 3.5248179],
          [101.7729391, 3.5211024],
          [101.7719192, 3.5187102],
          [101.7714093, 3.5164707],
          [101.7706444, 3.5147911],
          [101.7687066, 3.5135186],
          [101.7670238, 3.5133151],
          [101.765443, 3.511839],
          [101.7653415, 3.5108532],
          [101.763178, 3.5081899],
          [101.7633944, 3.5060305],
          [101.7634206, 3.5032444],
          [101.7629617, 3.5022264],
          [101.7639305, 3.4991725],
          [101.7628597, 3.4954569],
          [101.7636756, 3.4932682],
          [101.7658173, 3.4900616],
          [101.7676021, 3.4849717],
          [101.768061, 3.481307],
          [101.768214, 3.4777949],
          [101.7703047, 3.4760643],
          [101.7747139, 3.4691017],
          [101.7773655, 3.4647752],
          [101.7777735, 3.4622302],
          [101.7789463, 3.4602959],
          [101.7791503, 3.4507775],
          [101.781598, 3.445382],
          [101.7842496, 3.4420734],
          [101.7844536, 3.4375431],
          [101.7857795, 3.4370341],
          [101.7852185, 3.4346417],
          [101.7854225, 3.4314349],
          [101.7893401, 3.4288277],
          [101.7899099, 3.4283553],
          [101.7932252, 3.4245432],
          [101.7925085, 3.4232204],
          [101.7893091, 3.4185797],
          [101.7851532, 3.415691],
          [101.7831134, 3.4132476],
          [101.782374, 3.4119751],
          [101.7818386, 3.4102953],
          [101.7817494, 3.4098753],
          [101.7799391, 3.4074956],
          [101.7784985, 3.4082082],
          [101.7777719, 3.4073683],
          [101.7766373, 3.4046577],
          [101.7755281, 3.4024943],
          [101.7704798, 3.4007381],
          [101.7684018, 3.3994783],
          [101.7654442, 3.3985493],
          [101.7651892, 3.3971367],
          [101.7658266, 3.3913591],
          [101.7662983, 3.3880248],
          [101.7662983, 3.3860014],
          [101.7640419, 3.3827817],
          [101.7639526, 3.3804782],
          [101.7614157, 3.3788493],
          [101.7610587, 3.3763168],
          [101.7593359, 3.3715778],
          [101.76652, 3.3649275],
          [101.7729939, 3.3602554],
          [101.7776767, 3.3579701],
          [101.7806387, 3.3567668],
          [101.7810547, 3.3560745],
          [101.7814611, 3.3554261],
          [101.7812733, 3.352973],
          [101.781989, 3.3486851],
          [101.7811682, 3.3450159],
          [101.7810075, 3.33942],
          [101.783132, 3.3368151],
          [101.7875011, 3.334527],
          [101.7939652, 3.3336876],
          [101.7990658, 3.3318843],
          [101.8055834, 3.3239897],
          [101.8098062, 3.3168951],
          [101.8132693, 3.310972],
          [101.8165217, 3.3053489],
          [101.822579, 3.2988528],
          [101.8294486, 3.2913647],
          [101.8377646, 3.2876407],
          [101.8442384, 3.2843728],
          [101.8474929, 3.2850166],
          [101.8518162, 3.2844193],
          [101.8545424, 3.2805992],
          [101.8571673, 3.2740104],
          [101.8610623, 3.2721166],
          [101.8694474, 3.2685918],
          [101.8785068, 3.2704094],
          [101.888424, 3.2590455],
          [101.893205, 3.258972],
          [101.8968826, 3.2641858],
          [101.9040398, 3.2645596],
          [101.9066652, 3.2624969],
          [101.9099015, 3.2621297],
          [101.911961, 3.2605141],
          [101.9126965, 3.2572096],
          [101.9150216, 3.2567889],
          [101.9283889, 3.2584601],
          [101.9304228, 3.2565487],
          [101.9308641, 3.2527301],
          [101.9340269, 3.2523629],
          [101.9366748, 3.2492053],
          [101.9331336, 3.2436506],
          [101.9366013, 3.238704],
          [101.9419159, 3.2353574],
          [101.9515174, 3.213712],
          [101.9506499, 3.2098435],
          [101.9518267, 3.2063919],
          [101.9480755, 3.203895],
          [101.9499143, 3.1903823],
          [101.9421832, 3.1837996],
          [101.9470655, 3.1771579],
          [101.9467466, 3.1752181],
          [101.9468288, 3.1725099],
          [101.9476508, 3.1708685],
          [101.9482062, 3.1651519],
          [101.9506098, 3.1632361],
          [101.9515139, 3.1590506],
          [101.9561168, 3.1578196],
          [101.9560346, 3.156014],
          [101.9578429, 3.1551933],
          [101.9635143, 3.1490381],
          [101.9635143, 3.1463298],
          [101.9658157, 3.1391076],
          [101.9625279, 3.129259],
          [101.9551305, 3.1180151],
          [101.950692, 3.1163737],
          [101.9504454, 3.1126804],
          [101.9515139, 3.1067711],
          [101.9548017, 3.0989741],
          [101.9549661, 3.0954449],
          [101.9567743, 3.0917516],
          [101.9593224, 3.080179],
          [101.9615416, 3.0729564],
          [101.9619526, 3.0683601],
          [101.9626101, 3.0645025],
          [101.9671308, 3.0591675],
          [101.9694323, 3.0553099],
          [101.9681171, 3.0528475],
          [101.9677884, 3.051206],
          [101.9685281, 3.0496465],
          [101.9677062, 3.0485795],
          [101.9671308, 3.0477587],
          [101.9693501, 3.0442294],
          [101.9683637, 3.0416849],
          [101.9699254, 3.0398792],
          [101.9711583, 3.0352828],
          [101.9707474, 3.03241],
          [101.9670486, 3.0295373],
          [101.9635965, 3.0281419],
          [101.9541441, 3.0260078],
          [101.9478152, 3.0238738],
          [101.9428835, 3.0165686],
          [101.9428835, 3.0075398],
          [101.9445274, 3.0049953],
          [101.9444452, 2.9994137],
          [101.9341709, 2.9994958],
          [101.9338422, 2.998675],
          [101.9342531, 2.9974438],
          [101.9340887, 2.9930113],
          [101.9351573, 2.9914518],
          [101.9288283, 2.9798781],
          [101.9182331, 2.9553513],
          [101.9162398, 2.950758],
          [101.9126844, 2.9417412],
          [101.9106484, 2.9364292],
          [101.8991093, 2.9084082],
          [101.8950046, 2.8967411],
          [101.8915827, 2.8895144],
          [101.8907182, 2.8861042],
          [101.8904692, 2.8855451],
          [101.8869519, 2.8776481],
          [101.886407, 2.8767992],
          [101.883719, 2.8706244],
          [101.8828958, 2.8669251],
          [101.872728, 2.8666332],
          [101.8639777, 2.8668741],
          [101.8509222, 2.8672355],
          [101.8418918, 2.8676055],
          [101.8307182, 2.8680989],
          [101.8192831, 2.8684617],
          [101.8097441, 2.8692236],
          [101.7964703, 2.8690906],
          [101.7945312, 2.8690712],
          [101.7942106, 2.8691704],
          [101.7923735, 2.8697388],
          [101.7888817, 2.8696587],
          [101.7667811, 2.8700221],
          [101.7555374, 2.8702499],
          [101.7514919, 2.8694599],
          [101.7519978, 2.8628488],
          [101.7525393, 2.8580524],
          [101.7528529, 2.8514981],
          [101.7528457, 2.8407379],
          [101.7533898, 2.8396653],
          [101.7537623, 2.8313885],
          [101.7535129, 2.8287909],
          [101.7547951, 2.8223646],
          [101.7558579, 2.8054074],
          [101.7561181, 2.8015792],
          [101.7564836, 2.7914193],
          [101.7567404, 2.7895895],
          [101.7567856, 2.7869135],
          [101.7571342, 2.7815034],
          [101.7575796, 2.775371],
          [101.758149, 2.7653928],
          [101.7581873, 2.7622126],
          [101.7587801, 2.7539039],
          [101.7596023, 2.7401769],
          [101.7602716, 2.7303973],
          [101.7608835, 2.722203],
          [101.7616192, 2.7120263],
          [101.7618986, 2.7080553],
          [101.762124, 2.7029498],
          [101.7605561, 2.6992876],
          [101.7596656, 2.6988822],
          [101.7587765, 2.6987088],
          [101.7577941, 2.6983958],
          [101.7569939, 2.6983197],
          [101.7561936, 2.6979856],
          [101.7553764, 2.6974104],
          [101.7549172, 2.696955],
          [101.7548862, 2.6966772],
          [101.7546081, 2.696317],
          [101.7544433, 2.6956893],
          [101.7541033, 2.6954217],
          [101.7536809, 2.6941457],
          [101.7536603, 2.6939193],
          [101.7539076, 2.693734],
          [101.7552056, 2.6933636],
          [101.755137, 2.6922666],
          [101.7551232, 2.6920464],
          [101.7550923, 2.6917891],
          [101.7546802, 2.6912643],
          [101.7545566, 2.6911922],
          [101.7540621, 2.691254],
          [101.7531967, 2.6913054],
          [101.7529209, 2.6912466],
          [101.7527225, 2.6909903],
          [101.7526288, 2.6907287],
          [101.7523313, 2.6898544],
          [101.7517132, 2.6889077],
          [101.751332, 2.687858],
          [101.751332, 2.6875493],
          [101.751569, 2.6871686],
          [101.7527331, 2.6851516],
          [101.7527125, 2.6846988],
          [101.7518883, 2.6818071],
          [101.7517338, 2.6814675],
          [101.751489, 2.6811106],
          [101.7512417, 2.6810263],
          [101.7508601, 2.6810493],
          [101.7499341, 2.6813328],
          [101.7485422, 2.6817675],
          [101.7482275, 2.6818212],
          [101.747951, 2.6818684],
          [101.7472394, 2.6817612],
          [101.7463409, 2.6814157],
          [101.7453033, 2.6807922],
          [101.74485, 2.6803752],
          [101.7442259, 2.6794698],
          [101.7433831, 2.6784015],
          [101.7413742, 2.6759012],
          [101.7406045, 2.6748528],
          [101.7402022, 2.6739792],
          [101.7398961, 2.6727473],
          [101.7398436, 2.6718212],
          [101.7399048, 2.6709562],
          [101.7403421, 2.6692264],
          [101.7407182, 2.6683876],
          [101.7410506, 2.6678984],
          [101.7415491, 2.6674615],
          [101.7423975, 2.6670509],
          [101.7434821, 2.6666577],
          [101.7443742, 2.6662122],
          [101.7451963, 2.6655307],
          [101.7456861, 2.6645784],
          [101.7459398, 2.6632941],
          [101.7461846, 2.6609701],
          [101.7465432, 2.6596246],
          [101.747138, 2.6581044],
          [101.7475666, 2.6556231],
          [101.7474616, 2.6547494],
          [101.7470855, 2.654033],
          [101.7465345, 2.653404],
          [101.7456074, 2.6527312],
          [101.7441118, 2.6520672],
          [101.7403421, 2.6508877],
          [101.7342023, 2.6481181],
          [101.7325929, 2.6470872],
          [101.7314035, 2.6460475],
          [101.730214, 2.644536],
          [101.7286396, 2.6427012],
          [101.7265276, 2.6395881],
          [101.7260129, 2.6387565],
          [101.7253161, 2.6375289],
          [101.72487, 2.6365416],
          [101.7247388, 2.6356155],
          [101.724975, 2.6350214],
          [101.7255347, 2.6344622],
          [101.7268292, 2.6332216],
          [101.7277563, 2.6320683],
          [101.7291644, 2.6294734],
          [101.7298729, 2.6271406],
          [101.7304851, 2.6240739],
          [101.7305288, 2.6214702],
          [101.7303102, 2.61905],
          [101.7297504, 2.6172152],
          [101.7290682, 2.6164813],
          [101.7283947, 2.6162367],
          [101.7277475, 2.616324],
          [101.7272052, 2.6167696],
          [101.725972, 2.6183423],
          [101.724905, 2.6194869],
          [101.7239604, 2.6201422],
          [101.7228584, 2.6203955],
          [101.7211966, 2.6202645],
          [101.7182228, 2.6193383],
          [101.7153628, 2.6179841],
          [101.7134211, 2.6167264],
          [101.7115319, 2.6155027],
          [101.70729, 2.612401],
          [101.7056282, 2.6110031],
          [101.7037303, 2.6088625],
          [101.7027594, 2.6070539],
          [101.7024708, 2.6060054],
          [101.7024271, 2.6050531],
          [101.7029081, 2.6039609],
          [101.7037215, 2.6030872],
          [101.7050684, 2.6024668],
          [101.7077186, 2.6014358],
          [101.7111908, 2.5994962],
          [101.7122841, 2.5983341],
          [101.7126165, 2.5968575],
          [101.7125902, 2.596211],
          [101.7124503, 2.5953285],
          [101.709895, 2.5954778],
          [101.7080141, 2.5951893],
          [101.7068813, 2.5951599],
          [101.7045273, 2.5952481],
          [101.7037476, 2.594403],
          [101.7029385, 2.5941899],
          [101.7020999, 2.5941826],
          [101.7001505, 2.5942781],
          [101.6989883, 2.5942487],
          [101.6978922, 2.5941311],
          [101.6973111, 2.5941752],
          [101.6964946, 2.5943589],
          [101.6957148, 2.5945867],
          [101.6954323, 2.5951014],
          [101.6952189, 2.5954737],
          [101.6943345, 2.596098],
          [101.6935448, 2.5965047],
          [101.6927622, 2.5970841],
          [101.6921426, 2.5975475],
          [101.691804, 2.5977606],
          [101.6915501, 2.5980143],
          [101.6904327, 2.5989106],
          [101.6893628, 2.5997427],
          [101.6889937, 2.6000877],
          [101.6884032, 2.6004831],
          [101.6877128, 2.6002242],
          [101.6875803, 2.6004606],
          [101.6874081, 2.600273],
          [101.6872595, 2.6002891],
          [101.6870959, 2.6007441],
          [101.6869639, 2.6006974],
          [101.6868314, 2.6010211],
          [101.6882685, 2.6017724],
          [101.6881382, 2.602095],
          [101.6878114, 2.6021692],
          [101.6875836, 2.6024657],
          [101.6874939, 2.6029759],
          [101.6874939, 2.6033172],
          [101.6876112, 2.603493],
          [101.6877596, 2.6035964],
          [101.687985, 2.6037608],
          [101.6880428, 2.6040144],
          [101.6874079, 2.6071296],
          [101.6873792, 2.6072702],
          [101.6870103, 2.6083175],
          [101.686766, 2.6086145],
          [101.6857535, 2.6086196],
          [101.6847575, 2.6085953],
          [101.6843671, 2.6085347],
          [101.6849095, 2.6092266],
          [101.6848313, 2.6099085],
          [101.6846535, 2.6094823],
          [101.6844134, 2.6094127],
          [101.6839199, 2.6096968],
          [101.6828476, 2.6104191],
          [101.6820482, 2.6112253],
          [101.6812562, 2.611978],
          [101.6801702, 2.6127804],
          [101.6791928, 2.6125462],
          [101.6775929, 2.6134869],
          [101.6767672, 2.6137823],
          [101.6749365, 2.6147671],
          [101.6731868, 2.6160021],
          [101.6717319, 2.616743],
          [101.6697254, 2.617427],
          [101.6678616, 2.6176645],
          [101.6654147, 2.6180713],
          [101.6632438, 2.6184535],
          [101.6591126, 2.6206625],
          [101.654242, 2.6222607],
          [101.6528483, 2.6228147],
          [101.6504023, 2.6232764],
          [101.6487171, 2.6239441],
          [101.64333, 2.6254796],
          [101.6393668, 2.6265722],
          [101.6291491, 2.6301735],
          [101.625611, 2.6310643],
          [101.6241504, 2.6319066],
          [101.6239549, 2.6319401],
          [101.6234465, 2.6320273],
          [101.6217542, 2.6320131],
          [101.6201046, 2.6324888],
          [101.6155556, 2.6330529],
          [101.6102205, 2.6334067],
          [101.6088131, 2.6337595],
          [101.608411, 2.6339767],
          [101.6081991, 2.6332981],
          [101.6079763, 2.6334176],
          [101.6079763, 2.6338952],
          [101.6080306, 2.6345032],
          [101.6078839, 2.6346443],
          [101.6065472, 2.6345032],
          [101.6056662, 2.634572],
          [101.6016294, 2.6362294],
          [101.5968452, 2.6392425],
          [101.5943847, 2.641192],
          [101.5918048, 2.6429766],
          [101.5905307, 2.644278],
          [101.590377, 2.6445582],
          [101.5904001, 2.6446887],
          [101.5903117, 2.6448077],
          [101.5897776, 2.6449497],
          [101.5894587, 2.6449536],
          [101.5891129, 2.6449344],
          [101.5886595, 2.6452798],
          [101.5869874, 2.6467575],
          [101.5860935, 2.6468091],
          [101.5855772, 2.6471375],
          [101.5853572, 2.6474686],
          [101.5846073, 2.648025],
          [101.5841427, 2.6484511],
          [101.5837161, 2.648669],
          [101.5834158, 2.6488967],
          [101.5826714, 2.6493065],
          [101.5822285, 2.649757],
          [101.581622, 2.6501299],
          [101.5810711, 2.6504709],
          [101.5807749, 2.6504573],
          [101.5797153, 2.6508318],
          [101.5796283, 2.6509078],
          [101.57968, 2.6512444],
          [101.579487, 2.6512851],
          [101.5792072, 2.6512335],
          [101.5790279, 2.6512416],
          [101.578979, 2.6513068],
          [101.5789898, 2.6514995],
          [101.5782454, 2.6518062],
          [101.5775106, 2.6520414],
          [101.5770608, 2.6522431],
          [101.5757838, 2.6529135],
          [101.5749986, 2.6532636],
          [101.5744715, 2.6534319],
          [101.5739281, 2.6533179],
          [101.5734934, 2.6529949],
          [101.5733603, 2.6526041],
          [101.5720996, 2.6527697],
          [101.5716458, 2.6528891],
          [101.57151, 2.6536029],
          [101.5713221, 2.6537731],
          [101.5703036, 2.6540833],
          [101.5700917, 2.6540561],
          [101.5696244, 2.6544198],
          [101.5683583, 2.6547781],
          [101.5662771, 2.6552558],
          [101.5651033, 2.6553643],
          [101.5647175, 2.655196],
          [101.5641959, 2.6553046],
          [101.5637286, 2.6535568],
          [101.5608214, 2.6546478],
          [101.5608703, 2.655994],
          [101.5593162, 2.6563685],
          [101.5585228, 2.6563902],
          [101.5582076, 2.6565205],
          [101.5571218, 2.656802],
          [101.5562274, 2.657012],
          [101.5561644, 2.6569357],
          [101.5555006, 2.6573363],
          [101.5553439, 2.6573716],
          [101.5551725, 2.6574103],
          [101.5550806, 2.657431],
          [101.5545188, 2.6575417],
          [101.5537156, 2.6575857],
          [101.553229, 2.6578306],
          [101.5531192, 2.6577471],
          [101.5496872, 2.6589469],
          [101.547968, 2.6594732],
          [101.5442986, 2.6599643],
          [101.5390366, 2.6624969],
          [101.5389819, 2.6625381],
          [101.5368739, 2.6641253],
          [101.534902736544609, 2.665025915918976],
          [101.528681, 2.6678686],
          [101.528492, 2.6679598],
          [101.5216176, 2.6710784],
          [101.5197867, 2.6726774],
          [101.5172551, 2.6737178],
          [101.515714, 2.6744712],
          [101.5142903, 2.6751443],
          [101.5134119, 2.675336],
          [101.5111116, 2.6762527],
          [101.5100996, 2.676123],
          [101.5082325, 2.6765054],
          [101.5056509, 2.6772992],
          [101.5042321, 2.6778691],
          [101.5042103, 2.6784934],
          [101.5043842, 2.6788299],
          [101.5041771, 2.6789197],
          [101.5040988, 2.6789536],
          [101.501947, 2.6801357],
          [101.5009096, 2.6809225],
          [101.5007982, 2.6814676],
          [101.4998107, 2.6820126],
          [101.4990345, 2.6820663],
          [101.4902859, 2.6850012],
          [101.4865165, 2.6862657],
          [101.48028, 2.6882546],
          [101.4800002, 2.6883438],
          [101.4733766, 2.6947425],
          [101.4626945, 2.7076776],
          [101.4593188, 2.7141937],
          [101.4579115, 2.7169102],
          [101.4556081, 2.7240421],
          [101.4545309, 2.7268398],
          [101.4541532, 2.7276077],
          [101.4539993, 2.7279208],
          [101.4535353, 2.7288641],
          [101.44905, 2.736283],
          [101.4476443, 2.7390399],
          [101.4445581, 2.74521],
          [101.4430563, 2.748331],
          [101.442113, 2.7503349],
          [101.442174, 2.7505602],
          [101.441956, 2.7513951],
          [101.4408421, 2.7539116],
          [101.4402106, 2.7551461],
          [101.4398235, 2.7556006],
          [101.4394772, 2.7559255],
          [101.4394582, 2.7553824],
          [101.4391833, 2.7554329],
          [101.4389071, 2.7556092],
          [101.4385536, 2.755894],
          [101.4365696, 2.7588293],
          [101.4363843, 2.7590641],
          [101.433398, 2.7628485],
          [101.43138, 2.766139],
          [101.4312552, 2.7665059],
          [101.4313564, 2.76656],
          [101.4315283, 2.7664965],
          [101.4316201, 2.7665647],
          [101.4316413, 2.7668117],
          [101.4309891, 2.7675878],
          [101.4311563, 2.767776],
          [101.4312034, 2.7679595],
          [101.4311939, 2.7685804],
          [101.4301343, 2.7707254],
          [101.4293714, 2.771939],
          [101.429037, 2.7726211],
          [101.4287073, 2.7731856],
          [101.4283494, 2.7735008],
          [101.427899, 2.7741073],
          [101.4278973, 2.7744416],
          [101.4278219, 2.7747332],
          [101.4275346, 2.7749919],
          [101.4271343, 2.7749778],
          [101.4265268, 2.7745921],
          [101.4257544, 2.7743381],
          [101.4245707, 2.7763731],
          [101.4236975, 2.7773443],
          [101.4231615, 2.7781159],
          [101.4226489, 2.7789679],
          [101.4221813, 2.7798449],
          [101.4213645, 2.7803051],
          [101.4199335, 2.7812059],
          [101.4177565, 2.7824029],
          [101.415989, 2.7841962],
          [101.4154671, 2.7848876],
          [101.4147215, 2.7855431],
          [101.4131532, 2.7869623],
          [101.4119606, 2.7883028],
          [101.4116493, 2.7886815],
          [101.4112803, 2.7893289],
          [101.4106988, 2.7899351],
          [101.4099283, 2.7905222],
          [101.4097263, 2.7904725],
          [101.4094549, 2.7900123],
          [101.4091502, 2.7900302],
          [101.4088641, 2.7902852],
          [101.4085697, 2.7906724],
          [101.4087839, 2.7909748],
          [101.4089304, 2.7914338],
          [101.4086174, 2.7914571],
          [101.4080679, 2.7913972],
          [101.407655, 2.7923552],
          [101.4072947, 2.7935839],
          [101.4072853, 2.7936158],
          [101.4071134, 2.7942067],
          [101.4069078, 2.7949138],
          [101.4061524, 2.7975109],
          [101.4055243, 2.799496],
          [101.4059001, 2.7999621],
          [101.407182, 2.8000699],
          [101.4079378, 2.800357],
          [101.4085195, 2.8005779],
          [101.4103939, 2.8010577],
          [101.411838, 2.8018641],
          [101.4107371, 2.8033469],
          [101.4098202, 2.8045819],
          [101.406082, 2.8043579],
          [101.4001172, 2.8048144],
          [101.3924599, 2.8059545],
          [101.3872026, 2.8057788],
          [101.3758195, 2.8092941],
          [101.3720162, 2.8106698],
          [101.3679763, 2.8109438],
          [101.3661212, 2.8114031],
          [101.3646292, 2.8115184],
          [101.3618348, 2.8117814],
          [101.3594837, 2.812478],
          [101.357579, 2.8133545],
          [101.3559179, 2.8147663],
          [101.3547328, 2.8158058],
          [101.3542677, 2.8173484],
          [101.3544471, 2.8183275],
          [101.3541792, 2.8190389],
          [101.3534861, 2.8195624],
          [101.3528267, 2.8191867],
          [101.352406, 2.8186775],
          [101.351964, 2.817772],
          [101.3510239, 2.8173575],
          [101.3484988, 2.8175769],
          [101.3469284, 2.8184695],
          [101.3469771, 2.8185994],
          [101.3476209, 2.8185482],
          [101.3480624, 2.8186199],
          [101.3484826, 2.818641],
          [101.3485525, 2.8187223],
          [101.3483801, 2.8191721],
          [101.3478253, 2.8197685],
          [101.3466337, 2.8203476],
          [101.3457849, 2.8209842],
          [101.3456023, 2.8212274],
          [101.3449968, 2.821349],
          [101.3444741, 2.8216573],
          [101.3435602, 2.8223824],
          [101.3430749, 2.8225775],
          [101.3425351, 2.8228495],
          [101.3418719, 2.823302],
          [101.3410728, 2.8238509],
          [101.3402976, 2.8244796],
          [101.3391598, 2.8249559],
          [101.3356162, 2.8270382],
          [101.3348506, 2.8273949],
          [101.33429, 2.8275597],
          [101.3331092, 2.8281705],
          [101.3310965, 2.8293335],
          [101.3297542, 2.8299032],
          [101.3285511, 2.8301226],
          [101.3254195, 2.8318969],
          [101.324324, 2.8321624],
          [101.3236609, 2.8323576],
          [101.3236449, 2.8321784],
          [101.3246891, 2.8311994],
          [101.3240246, 2.8309373],
          [101.3229562, 2.8314842],
          [101.321803, 2.8313946],
          [101.3209573, 2.8318489],
          [101.3201501, 2.8320121],
          [101.3197977, 2.8323448],
          [101.3178895, 2.8323483],
          [101.3158673, 2.8322424],
          [101.3152042, 2.8321529],
          [101.3112001, 2.8328439],
          [101.308157, 2.8335001],
          [101.3072184, 2.8338453],
          [101.3051587, 2.8342068],
          [101.3030029, 2.8347347],
          [101.3017793, 2.8352018],
          [101.3014109, 2.8355282],
          [101.3006389, 2.8358353],
          [101.3002161, 2.835541],
          [101.2997676, 2.8355378],
          [101.2994088, 2.8353682],
          [101.2981104, 2.8354489],
          [101.2962696, 2.8359537],
          [101.2943989, 2.8367503],
          [101.2942195, 2.8369903],
          [101.2942516, 2.8373486],
          [101.2940978, 2.8376589],
          [101.2937038, 2.8374574],
          [101.2934764, 2.8374894],
          [101.2933528, 2.837583],
          [101.2925354, 2.840091],
          [101.2915096, 2.8421132],
          [101.2899603, 2.8466921],
          [101.2887235, 2.8516406],
          [101.2876921, 2.8565539],
          [101.2875044, 2.8580399],
          [101.287229, 2.8595466],
          [101.2874259, 2.8601135],
          [101.2876946, 2.8605944],
          [101.2880171, 2.8608978],
          [101.2883935, 2.8610054],
          [101.2883682, 2.8613775],
          [101.2881764, 2.8615682],
          [101.2881165, 2.8617328],
          [101.2880241, 2.8628169],
          [101.2879663, 2.8641939],
          [101.2875443, 2.8663829],
          [101.2874957, 2.867816],
          [101.2873683, 2.8687097],
          [101.2871217, 2.8692692],
          [101.2868462, 2.8697435],
          [101.2866139, 2.8705283],
          [101.2864538, 2.8713895],
          [101.2865443, 2.8735151],
          [101.2864995, 2.8740447],
          [101.286653, 2.8745993],
          [101.2868294, 2.8747102],
          [101.2875937, 2.8765662],
          [101.2891529, 2.8789996],
          [101.2902719, 2.8796031],
          [101.2906827, 2.8793706],
          [101.2912935, 2.8797914],
          [101.2925052, 2.8800361],
          [101.2926986, 2.880311],
          [101.2939586, 2.8807929],
          [101.2949188, 2.8820389],
          [101.2955445, 2.8834288],
          [101.2976159, 2.8842407],
          [101.3014866, 2.8871931],
          [101.3037587, 2.889271],
          [101.3074912, 2.8932547],
          [101.310857, 2.8980814],
          [101.3135323, 2.902034],
          [101.3169425, 2.9064686],
          [101.3184003, 2.9091546],
          [101.3193686, 2.9102782],
          [101.3204018, 2.9109181],
          [101.3219233, 2.9107682],
          [101.3236177, 2.9090741],
          [101.329496, 2.90368],
          [101.3341331, 2.8980676],
          [101.3357581, 2.8962426],
          [101.3375928, 2.8944382],
          [101.3384625, 2.8938218],
          [101.3391658, 2.8932407],
          [101.3402683, 2.892656],
          [101.3424803, 2.892123],
          [101.3439466, 2.891932],
          [101.3456971, 2.892055],
          [101.3482203, 2.8932485],
          [101.3502512, 2.8951995],
          [101.3514374, 2.8973298],
          [101.3518886, 2.8991142],
          [101.3520213, 2.9005435],
          [101.3518121, 2.9036213],
          [101.3514751, 2.905338],
          [101.3492165, 2.9097037],
          [101.3392649, 2.9202136],
          [101.3388428, 2.9209537],
          [101.3390078, 2.9220946],
          [101.3425091, 2.9232812],
          [101.3444326, 2.9237168],
          [101.3468799, 2.9237257],
          [101.3478835, 2.9235863],
          [101.3579096, 2.9257931],
          [101.3580537, 2.9266324],
          [101.3588289, 2.9289237],
          [101.3615211, 2.9339277],
          [101.3637646, 2.9394545],
          [101.365709, 2.9468484],
          [101.3676534, 2.955512],
          [101.3694483, 2.9601424],
          [101.3725892, 2.9643995],
          [101.3766276, 2.9688058],
          [101.3808903, 2.9726894],
          [101.3862, 2.9762742],
          [101.3896401, 2.9788882],
          [101.3915097, 2.9811287],
          [101.3939029, 2.9859084],
          [101.3943516, 2.987178],
          [101.3941685, 2.9881455],
          [101.3940298, 2.9888782],
          [101.3938977, 2.9888823],
          [101.3938829, 2.9892359],
          [101.3938609, 2.989235],
          [101.3938543, 2.989394],
          [101.3938762, 2.9893949],
          [101.3938124, 2.9909184],
          [101.393799, 2.990918],
          [101.3937745, 2.9916677],
          [101.3937879, 2.9916681],
          [101.3937827, 2.9920865],
          [101.3935926, 2.9927955],
          [101.3931482, 2.9949349],
          [101.3929419, 2.9957983],
          [101.3921107, 2.9971445],
          [101.3919307, 2.997436],
          [101.390987, 2.9983129],
          [101.3910337, 2.9983685],
          [101.3917031, 2.9983999],
          [101.3908956, 2.9994388],
          [101.3905017, 2.9992682],
          [101.3905143, 2.9991809],
          [101.3906187, 2.998986],
          [101.3905746, 2.9989526],
          [101.390199397437655, 2.9995],
          [101.3899754, 2.9998268],
          [101.3896355, 2.9996075],
          [101.3895278, 2.9998038],
          [101.3895395, 3.0007957],
          [101.3881835, 3.0015045],
          [101.386922, 3.0021294],
          [101.3835245, 3.0020392],
          [101.374766, 3.0020539],
          [101.3746001, 3.0020037],
          [101.3736557, 3.0017183],
          [101.3725463, 3.0014672],
          [101.369953, 3.0009741],
          [101.3686128, 3.0009831],
          [101.365847, 3.000287],
          [101.3648729, 3.0003865],
          [101.364748208054195, 3.0005],
          [101.3642891, 3.0009179],
          [101.3613361, 3.0063534],
          [101.3599559, 3.0109061],
          [101.3598103, 3.0109508],
          [101.3592385, 3.0132694],
          [101.3584324, 3.0172797],
          [101.3575449, 3.0185131],
          [101.3573776, 3.0147221],
          [101.3566002, 3.0147341],
          [101.356857, 3.0217224],
          [101.3572047, 3.0314523],
          [101.3565694, 3.0431907],
          [101.3582771, 3.0432687],
          [101.3586025, 3.0447975],
          [101.3584116, 3.0492733],
          [101.3594073, 3.0502981],
          [101.3600317, 3.0515115],
          [101.3606616, 3.0529261],
          [101.3613879, 3.0551654],
          [101.3613819, 3.0559093],
          [101.3617632, 3.0603893],
          [101.3620973, 3.0634569],
          [101.3621134, 3.0639049],
          [101.3614682, 3.0649758],
          [101.3612131, 3.0670859],
          [101.3608803, 3.0679648],
          [101.3610356, 3.0697889],
          [101.3597626, 3.0718103],
          [101.3594071, 3.0729743],
          [101.3593913, 3.0731567],
          [101.359725, 3.0731317],
          [101.3600622, 3.0731424],
          [101.359664, 3.0733932],
          [101.3593877, 3.0734111],
          [101.3592873, 3.073798],
          [101.3591043, 3.073995],
          [101.3586399, 3.0752828],
          [101.3567032, 3.0755937],
          [101.356441, 3.0760359],
          [101.3561706, 3.0766749],
          [101.3471881, 3.0876258],
          [101.3372784, 3.0999608],
          [101.3298613, 3.1065292],
          [101.3296711, 3.1066763],
          [101.3293042, 3.107275],
          [101.3293143, 3.1091405],
          [101.3277942, 3.1098554],
          [101.3251624, 3.1110893],
          [101.3212537, 3.1111948],
          [101.319282, 3.1109761],
          [101.3185755, 3.1114292],
          [101.3180691, 3.1124647],
          [101.3180958, 3.1149457],
          [101.3173638, 3.116749],
          [101.3171126, 3.1171812],
          [101.3170271, 3.1178641],
          [101.3168241, 3.1189046],
          [101.3166003, 3.1196083],
          [101.3130702, 3.127472],
          [101.3126919, 3.1276429],
          [101.3128112, 3.1281508],
          [101.3133834, 3.1279334],
          [101.3136025, 3.1282541],
          [101.3133871, 3.1285936],
          [101.3136894, 3.1288502],
          [101.3142373, 3.1288615],
          [101.3143393, 3.1298726],
          [101.3142259, 3.1303404],
          [101.3140492, 3.1305407],
          [101.3132813, 3.1324342],
          [101.3126466, 3.1341697],
          [101.3123972, 3.1351732],
          [101.3124577, 3.1361541],
          [101.3121554, 3.1364936],
          [101.3123859, 3.1367841],
          [101.3121743, 3.136935],
          [101.3116793, 3.1373161],
          [101.311396, 3.1384252],
          [101.311139, 3.1412849],
          [101.3112751, 3.1421903],
          [101.3107461, 3.1425374],
          [101.3101857, 3.1447005],
          [101.3093708, 3.1494413],
          [101.3090431, 3.1503513],
          [101.3087436, 3.1531234],
          [101.3088078, 3.1543079],
          [101.3084666, 3.1552864],
          [101.306953, 3.1632854],
          [101.30693, 3.1642298],
          [101.3071869, 3.1649164],
          [101.3069451, 3.1675572],
          [101.307013, 3.168745],
          [101.3074684, 3.1690696],
          [101.3077685, 3.1703007],
          [101.3076576, 3.1707176],
          [101.3072336, 3.1708283],
          [101.3071357, 3.1709716],
          [101.3074489, 3.170913],
          [101.3075271, 3.1712192],
          [101.3072792, 3.1714276],
          [101.306872, 3.1720988],
          [101.3067334, 3.1724858],
          [101.3070224, 3.1726706],
          [101.3076726, 3.1726423],
          [101.3078071, 3.1723529],
          [101.3079184, 3.1722766],
          [101.3080821, 3.1722474],
          [101.3084159, 3.1716208],
          [101.3084514, 3.1716297],
          [101.3084846, 3.1716378],
          [101.3084751, 3.1717365],
          [101.3085462, 3.171839],
          [101.3083856, 3.172203],
          [101.3085633, 3.1722779],
          [101.3084346, 3.1728221],
          [101.308394, 3.1729387],
          [101.3078436, 3.1731],
          [101.3078827, 3.1732173],
          [101.3082252, 3.1731082],
          [101.3084078, 3.1732189],
          [101.3084861, 3.1737986],
          [101.309008, 3.1740917],
          [101.3087993, 3.1744239],
          [101.3082382, 3.1743197],
          [101.3075598, 3.1745347],
          [101.3069393, 3.1777426],
          [101.3071976, 3.180414],
          [101.3070684, 3.1835644],
          [101.307214, 3.1855819],
          [101.3070966, 3.1869367],
          [101.3070593, 3.1881633],
          [101.30708, 3.1888059],
          [101.3069554, 3.1898146],
          [101.3068055, 3.1900702],
          [101.3067948, 3.1911118],
          [101.3067328, 3.1913426],
          [101.3065333, 3.1914888],
          [101.3065172, 3.1916822],
          [101.3067131, 3.1920232],
          [101.3062438, 3.1925692],
          [101.3058643, 3.1947803],
          [101.305755, 3.1960726],
          [101.3057302, 3.1980514],
          [101.305572, 3.2002715],
          [101.3053208, 3.201275],
          [101.3051004, 3.2028313],
          [101.3049127, 3.2043143],
          [101.304774, 3.2065877],
          [101.3047894, 3.2094442],
          [101.3049796, 3.212851],
          [101.3049404, 3.2159579],
          [101.3048752, 3.2221196],
          [101.3046939, 3.2244489],
          [101.3045612, 3.224897],
          [101.3040787, 3.2249593],
          [101.3039669, 3.2253447],
          [101.303179, 3.2278775],
          [101.3021222, 3.2299618],
          [101.2994376, 3.2359158],
          [101.2978556, 3.2417249],
          [101.2986576, 3.2451714],
          [101.3001911, 3.2465838],
          [101.3002303, 3.2482512],
          [101.2999122, 3.2491987],
          [101.2992778, 3.2515469],
          [101.2990878, 3.2530649],
          [101.2990911, 3.25329],
          [101.298685, 3.2540446],
          [101.2983596, 3.2546494],
          [101.2983348, 3.2547364],
          [101.2981489, 3.2552401],
          [101.2977081, 3.2555177],
          [101.2970954, 3.2553237],
          [101.2967093, 3.2551155],
          [101.2958985, 3.255077],
          [101.2948547, 3.2558586],
          [101.2904073, 3.2623234],
          [101.280503, 3.2785515],
          [101.2678606, 3.2937906],
          [101.2549562, 3.3080398],
          [101.2468122, 3.3132758],
          [101.2318121, 3.3295975],
          [101.2301989, 3.3315723],
          [101.2263948, 3.3339086],
          [101.2183263, 3.3388638],
          [101.211105, 3.3417228],
          [101.20691, 3.3417655],
          [101.2053904, 3.342898],
          [101.2012883, 3.3427897],
          [101.1997614, 3.3421715],
          [101.1996758, 3.3413809],
          [101.2006603, 3.3408895],
          [101.2015807, 3.3400562],
          [101.199847, 3.3405904],
          [101.1919517, 3.3478356],
          [101.1811203, 3.3709105],
          [101.1796246, 3.3756624],
          [101.1788193, 3.3807875],
          [101.179035, 3.3811464],
          [101.1775969, 3.3886976],
          [101.1771799, 3.3892432],
          [101.1763601, 3.389358],
          [101.1756555, 3.3899897],
          [101.1763458, 3.3907936],
          [101.1736844, 3.3947136],
          [101.1715137, 3.3945118],
          [101.1698011, 3.3947134],
          [101.1666968, 3.3961717],
          [101.1646876, 3.396934],
          [101.164482, 3.397012],
          [101.1625406, 3.3989322],
          [101.1611608, 3.4019336],
          [101.1582407, 3.408546],
          [101.1565137, 3.4123752],
          [101.1560123, 3.4127263],
          [101.1546362, 3.4153739],
          [101.1536491, 3.4167662],
          [101.1513746, 3.4199898],
          [101.1509347, 3.4201826],
          [101.149261, 3.4224745],
          [101.1487031, 3.4236525],
          [101.1473084, 3.4249591],
          [101.145742, 3.4268654],
          [101.1443153, 3.4282487],
          [101.1437412, 3.4288664],
          [101.1433456, 3.4288919],
          [101.1412915, 3.4305349],
          [101.1383569, 3.4330438],
          [101.1362326, 3.4354127],
          [101.1353841, 3.4358457],
          [101.1340508, 3.4370938],
          [101.1323794, 3.4387877],
          [101.1314225, 3.4403542],
          [101.1308548, 3.441857],
          [101.1309696, 3.4432898],
          [101.1313651, 3.4449646],
          [101.130791, 3.4461235],
          [101.1302168, 3.4469068],
          [101.1300765, 3.4477028],
          [101.1290674, 3.4488896],
          [101.1264468, 3.4485587],
          [101.123901, 3.4496455],
          [101.1197297, 3.4563748],
          [101.1185163, 3.4602103],
          [101.1112756, 3.468948],
          [101.1035508, 3.4812849],
          [101.0984615, 3.4906346],
          [101.097746, 3.4917221],
          [101.0961036, 3.4974762],
          [101.0957539, 3.4987098],
          [101.0955588, 3.4989858],
          [101.0955344, 3.499351],
          [101.0958596, 3.4996675],
          [101.0957214, 3.5006657],
          [101.0943717, 3.5013393],
          [101.0942253, 3.5017208],
          [101.0943066, 3.5022889],
          [101.0940871, 3.5031491],
          [101.0934285, 3.5033115],
          [101.0924685, 3.5048012],
          [101.0915746, 3.506436],
          [101.0912413, 3.5075316],
          [101.090729, 3.5097229],
          [101.0902249, 3.5110295],
          [101.0891841, 3.5135616],
          [101.0881352, 3.5160937],
          [101.0868668, 3.5193805],
          [101.0860443, 3.5217335],
          [101.0854927, 3.5231299],
          [101.0856814, 3.5234694],
          [101.0860462, 3.52378],
          [101.0860354, 3.524037],
          [101.0856438, 3.52514],
          [101.0852576, 3.5261787],
          [101.0848177, 3.5269926],
          [101.0844905, 3.5277475],
          [101.084115, 3.5285613],
          [101.0839234, 3.5289812],
          [101.0836858, 3.5296804],
          [101.0834176, 3.5301623],
          [101.0830206, 3.5308369],
          [101.0826559, 3.5313027],
          [101.0821183, 3.532268],
          [101.0813296, 3.5334123],
          [101.0804759, 3.5345403],
          [101.080175, 3.5352707],
          [101.0797418, 3.5364889],
          [101.0790855, 3.5382328],
          [101.0781098, 3.5410895],
          [101.0768836, 3.5447392],
          [101.0764592, 3.5456747],
          [101.075825, 3.5463807],
          [101.0754103, 3.5476061],
          [101.0751176, 3.5481904],
          [101.0744915, 3.5500813],
          [101.0738654, 3.5518342],
          [101.0723303, 3.5546591],
          [101.0710382, 3.5578109],
          [101.0701266, 3.5595681],
          [101.069427, 3.5614517],
          [101.0689931, 3.5626708],
          [101.0688668, 3.5639008],
          [101.0692402, 3.5645945],
          [101.069201, 3.564754],
          [101.0690871, 3.5652126],
          [101.0685576, 3.5651362],
          [101.0678558, 3.565429],
          [101.0673678, 3.5658493],
          [101.0670219, 3.5665502],
          [101.066323, 3.5673948],
          [101.0659528, 3.5682954],
          [101.0650954, 3.5689914],
          [101.0646605, 3.569768],
          [101.0642101, 3.5708086],
          [101.0641324, 3.5718337],
          [101.0635733, 3.5729365],
          [101.0633248, 3.5741635],
          [101.0625637, 3.5756235],
          [101.0618648, 3.5771301],
          [101.0614454, 3.5782795],
          [101.0605756, 3.579957],
          [101.0596437, 3.5816965],
          [101.0587642, 3.5833205],
          [101.0582905, 3.5841515],
          [101.0579178, 3.5850213],
          [101.0573586, 3.5861629],
          [101.0564733, 3.5880189],
          [101.0559918, 3.5903099],
          [101.0565509, 3.5911719],
          [101.0562284, 3.592094],
          [101.0558618, 3.5930169],
          [101.0553733, 3.5935167],
          [101.0551558, 3.5940937],
          [101.0548019, 3.5947653],
          [101.0539617, 3.5954023],
          [101.0525724, 3.5959899],
          [101.0528964, 3.5967203],
          [101.0529458, 3.5971431],
          [101.0525339, 3.5966324],
          [101.0518632, 3.5964382],
          [101.0501858, 3.5980691],
          [101.049083, 3.5993893],
          [101.0479647, 3.6012842],
          [101.0461475, 3.6039246],
          [101.0453398, 3.605804],
          [101.0432023, 3.6082037],
          [101.0415636, 3.610417],
          [101.0394223, 3.6133325],
          [101.0389728, 3.6141296],
          [101.0385799, 3.614851],
          [101.0379835, 3.6153329],
          [101.037985, 3.6161194],
          [101.037837, 3.6168476],
          [101.037939, 3.6177742],
          [101.0376963, 3.6187489],
          [101.0364947, 3.6191118],
          [101.0362874, 3.6195417],
          [101.0357594, 3.6198279],
          [101.0348577, 3.6200182],
          [101.0338199, 3.6204723],
          [101.0325778, 3.6212563],
          [101.0317506, 3.6219955],
          [101.0312274, 3.6227791],
          [101.0304366, 3.6237539],
          [101.0295472, 3.6253242],
          [101.0288144, 3.6266],
          [101.0285778, 3.6271149],
          [101.0285389, 3.627931],
          [101.0284508, 3.6281957],
          [101.0281853, 3.6288588],
          [101.027351, 3.6293335],
          [101.0270441, 3.6294491],
          [101.0266203, 3.6295854],
          [101.0258385, 3.6300961],
          [101.025172, 3.6300241],
          [101.0245452, 3.6302879],
          [101.023153, 3.6314396],
          [101.0222971, 3.6322335],
          [101.0213643, 3.6331633],
          [101.0204154, 3.6343436],
          [101.0195066, 3.6355324],
          [101.0182764, 3.6371287],
          [101.0176064, 3.6365577],
          [101.017577, 3.6366211],
          [101.0186335, 3.6376852],
          [101.0187753, 3.637568],
          [101.0193769, 3.6381245],
          [101.0193524, 3.6381733],
          [101.0195187, 3.6383588],
          [101.0194796, 3.6385101],
          [101.0194013, 3.6386223],
          [101.0192742, 3.6386711],
          [101.0191372, 3.6386516],
          [101.0189514, 3.6384954],
          [101.0185405, 3.6381977],
          [101.0182177, 3.6378414],
          [101.0176148, 3.6375656],
          [101.0170987, 3.6378958],
          [101.0169602, 3.6381525],
          [101.0160341, 3.6389037],
          [101.0143735, 3.6401761],
          [101.0132455, 3.6410902],
          [101.0123, 3.6419957],
          [101.0115194, 3.6427883],
          [101.0107706, 3.6432745],
          [101.0093877, 3.6437293],
          [101.008498, 3.6445635],
          [101.0078747, 3.6451861],
          [101.0074229, 3.645564],
          [101.0054651, 3.6465457],
          [101.004595, 3.6474976],
          [101.004171, 3.6478944],
          [101.0040225, 3.6477398],
          [101.0037499, 3.6480199],
          [101.0039015, 3.6481681],
          [101.0005889, 3.6513711],
          [100.999499065656465, 3.65217711003886],
          [100.9993064, 3.6523196],
          [100.9966988, 3.6544407],
          [100.995680747667919, 3.655192710459232],
          [100.9945, 3.6560649],
          [100.9927176, 3.6570313],
          [100.9916082, 3.6578473],
          [100.9910417, 3.6581733],
          [100.9902081, 3.6588396],
          [100.9892315, 3.6594525],
          [100.987883, 3.6601436],
          [100.985954, 3.6609458],
          [100.9843611, 3.661627],
          [100.9820338, 3.6623237],
          [100.9814399, 3.6630596],
          [100.9803228, 3.6633261],
          [100.9800004, 3.6639149],
          [100.9795963, 3.664951],
          [100.9795431, 3.6664877],
          [100.9795028, 3.6670564],
          [100.9792524, 3.66741],
          [100.9790289, 3.6676457],
          [100.9788539, 3.6678493],
          [100.9778026, 3.6688751],
          [100.9773775, 3.6695001],
          [100.977367, 3.669846],
          [100.9770269, 3.6699526],
          [100.9768564, 3.6699168],
          [100.9766302, 3.6700559],
          [100.9760519, 3.6700341],
          [100.97561, 3.6699956],
          [100.975177, 3.6699505],
          [100.974584, 3.6697049],
          [100.9737284, 3.6696315],
          [100.9721326, 3.6696235],
          [100.9712298, 3.6698537],
          [100.9706542, 3.6699098],
          [100.9703171, 3.6697653],
          [100.9699934, 3.6695843],
          [100.9695561, 3.6694658],
          [100.969035, 3.6694812],
          [100.9684513, 3.6692732],
          [100.9679553, 3.669407],
          [100.967386, 3.6695467],
          [100.9665471, 3.669487],
          [100.9660117, 3.6696269],
          [100.9655444, 3.6699821],
          [100.9643934, 3.670271],
          [100.9632801, 3.6705829],
          [100.9619961, 3.6706682],
          [100.9609341, 3.6709436],
          [100.9592574, 3.6714685],
          [100.9571451, 3.671837],
          [100.9557222, 3.6722714],
          [100.9545205, 3.6722986],
          [100.9534689, 3.6725762],
          [100.9526847, 3.6729572],
          [100.9517586, 3.6731877],
          [100.9509956, 3.6737451],
          [100.950373, 3.6741247],
          [100.9499789, 3.6741128],
          [100.9493732, 3.673983],
          [100.948443, 3.6741777],
          [100.9472532, 3.6741994],
          [100.9458471, 3.6744806],
          [100.9448087, 3.6747185],
          [100.9443328, 3.6746753],
          [100.9441598, 3.6741777],
          [100.9439002, 3.67381],
          [100.9429916, 3.6741128],
          [100.9423643, 3.6747618],
          [100.9415422, 3.6751945],
          [100.9412178, 3.6754108],
          [100.9410014, 3.6752594],
          [100.9404822, 3.6753026],
          [100.9395737, 3.6756055],
          [100.939141, 3.6762977],
          [100.9386219, 3.6770549],
          [100.9381676, 3.6781797],
          [100.937908, 3.6791532],
          [100.9378431, 3.6801483],
          [100.9378311, 3.6809385],
          [100.9378661, 3.681719],
          [100.9381047, 3.6821828],
          [100.9386075, 3.6825357],
          [100.9386638, 3.6830449],
          [100.9385542, 3.6835223],
          [100.9383829, 3.6840911],
          [100.9382615, 3.6845741],
          [100.9381423, 3.6852767],
          [100.9378797, 3.6860708],
          [100.9373486, 3.6870871],
          [100.9366218, 3.6880905],
          [100.936021, 3.6887254],
          [100.9355871, 3.6893133],
          [100.9351441, 3.6897096],
          [100.9346883, 3.6900969],
          [100.9343017, 3.6901425],
          [100.9339869, 3.6898435],
          [100.9338136, 3.6893895],
          [100.9332954, 3.6890455],
          [100.9330711, 3.6894129],
          [100.932925, 3.6906396],
          [100.9327589, 3.6922319],
          [100.9326809, 3.6932119],
          [100.9324821, 3.6943395],
          [100.9319799, 3.6946285],
          [100.9312555, 3.6947412],
          [100.9305007, 3.6948693],
          [100.9299128, 3.6953914],
          [100.9297047, 3.696326],
          [100.9292351, 3.6973058],
          [100.9286424, 3.6980054],
          [100.928165, 3.6983574],
          [100.9280946, 3.6989427],
          [100.9281999, 3.6995234],
          [100.927994, 3.7003364],
          [100.928082, 3.7009563],
          [100.9281869, 3.7014607],
          [100.928045, 3.7019652],
          [100.9277601, 3.702517],
          [100.9275862, 3.7030539],
          [100.9274446, 3.7037124],
          [100.9270929, 3.7044395],
          [100.9268519, 3.7050854],
          [100.9268131, 3.705778],
          [100.9266944, 3.7063114],
          [100.9263474, 3.7069497],
          [100.9260732, 3.7076995],
          [100.9260152, 3.7084782],
          [100.9260699, 3.7091133],
          [100.925884, 3.7098988],
          [100.9258113, 3.7108342],
          [100.9258713, 3.7114494],
          [100.9256168, 3.7120317],
          [100.9251616, 3.7125578],
          [100.9246198, 3.7131011],
          [100.9238548, 3.7131761],
          [100.9231779, 3.7133522],
          [100.9226257, 3.7137597],
          [100.9222174, 3.7144469],
          [100.9217668, 3.7160346],
          [100.9213818, 3.7176138],
          [100.9209289, 3.7184221],
          [100.9205728, 3.7192408],
          [100.9200749, 3.7202371],
          [100.9192871, 3.7213076],
          [100.9182665, 3.7224471],
          [100.9177662, 3.722958],
          [100.9173183, 3.7236348],
          [100.9169337, 3.7238933],
          [100.9168597, 3.7242577],
          [100.9168596, 3.7246549],
          [100.9170203, 3.7254549],
          [100.9158512, 3.7269121],
          [100.9143181, 3.7287682],
          [100.9124573, 3.7309242],
          [100.9113645, 3.7324559],
          [100.9074675, 3.7370161],
          [100.9045853, 3.7403517],
          [100.9026835, 3.7423153],
          [100.9009299, 3.7440217],
          [100.8990591, 3.7458005],
          [100.8972492, 3.7477424],
          [100.8960493, 3.7485878],
          [100.8958637, 3.7489856],
          [100.8959947, 3.7493242],
          [100.8959514, 3.7498586],
          [100.8954496, 3.7501693],
          [100.8944948, 3.7505787],
          [100.8934584, 3.7512877],
          [100.8923889, 3.7523024],
          [100.8915172, 3.7532835],
          [100.8912278, 3.7536631],
          [100.8906965, 3.7539282],
          [100.8901585, 3.7542876],
          [100.8897842, 3.7549298],
          [100.8891941, 3.7551157],
          [100.8885481, 3.7552871],
          [100.8879519, 3.7558818],
          [100.8873495, 3.7562239],
          [100.8866696, 3.7563829],
          [100.8863455, 3.7566528],
          [100.8860653, 3.7570371],
          [100.8854132, 3.7572603],
          [100.884747, 3.7575491],
          [100.8836146, 3.7581431],
          [100.8825342, 3.7589822],
          [100.8811789, 3.7600612],
          [100.8795211, 3.7611627],
          [100.8777498, 3.7623328],
          [100.8764135, 3.7633692],
          [100.8757833, 3.7639406],
          [100.8753227, 3.7641055],
          [100.8748318, 3.7643782],
          [100.8743773, 3.7647413],
          [100.8735232, 3.7656325],
          [100.8731989, 3.7660466],
          [100.8728038, 3.7661736],
          [100.8724612, 3.7665314],
          [100.872498, 3.7669325],
          [100.8726523, 3.7672342],
          [100.8726061, 3.7674754],
          [100.8722705, 3.7676925],
          [100.8720462, 3.7678592],
          [100.8716296, 3.7678402],
          [100.8711961, 3.7679957],
          [100.8710469, 3.7683538],
          [100.8710469, 3.7687715],
          [100.8706292, 3.7691296],
          [100.8700325, 3.7690997],
          [100.8690478, 3.7691892],
          [100.8675858, 3.7694876],
          [100.8654375, 3.7704126],
          [100.8632295, 3.7715762],
          [100.8610812, 3.7726802],
          [100.8587838, 3.7737842],
          [100.8565161, 3.7745898],
          [100.8550541, 3.7749777],
          [100.8538445, 3.7752536],
          [100.8527557, 3.7753309],
          [100.8519212, 3.7752462],
          [100.8512349, 3.7753656],
          [100.8504175, 3.7756103],
          [100.8494124, 3.7756067],
          [100.848461, 3.7758246],
          [100.8477738, 3.776201],
          [100.8471653, 3.7765588],
          [100.8464907, 3.7766784],
          [100.8459238, 3.7766486],
          [100.8454464, 3.7770066],
          [100.8450287, 3.7770365],
          [100.8442828, 3.7769171],
          [100.8439546, 3.7770365],
          [100.8433578, 3.7772453],
          [100.8429103, 3.7770962],
          [100.841965, 3.7770699],
          [100.8409756, 3.7770675],
          [100.840534, 3.7772549],
          [100.8404302, 3.7775819],
          [100.8400339, 3.7779113],
          [100.8394503, 3.7779101],
          [100.8389562, 3.7777896],
          [100.8384943, 3.7776929],
          [100.8380169, 3.7774244],
          [100.8373307, 3.7773945],
          [100.8366181, 3.7776547],
          [100.8360751, 3.7776464],
          [100.835698, 3.7773778],
          [100.8353316, 3.777305],
          [100.834773, 3.7773719],
          [100.8342706, 3.7771268],
          [100.8336809, 3.7769816],
          [100.8328849, 3.7767978],
          [100.8319814, 3.776762],
          [100.8310421, 3.7767548],
          [100.8302592, 3.7765292],
          [100.8297655, 3.7764905],
          [100.8293044, 3.7767083],
          [100.8288163, 3.776941],
          [100.8283201, 3.7768772],
          [100.8279319, 3.7767978],
          [100.8274701, 3.7767608],
          [100.8271525, 3.7765973],
          [100.8265868, 3.7764266],
          [100.825922, 3.7765949],
          [100.825515, 3.7769171],
          [100.8249732, 3.7771284],
          [100.8244839, 3.7772203],
          [100.8241974, 3.7775736],
          [100.8235756, 3.7774542],
          [100.8225015, 3.7775139],
          [100.8213676, 3.7781405],
          [100.8204128, 3.7791251],
          [100.8196967, 3.7800501],
          [100.8186307, 3.7811207],
          [100.8176821, 3.7820601],
          [100.8165329, 3.7829103],
          [100.8155502, 3.7834462],
          [100.8142169, 3.7841822],
          [100.8138739, 3.784636],
          [100.8137671, 3.785261],
          [100.8136466, 3.7870776],
          [100.8133938, 3.7885156],
          [100.8126295, 3.7903029],
          [100.8115728, 3.7924508],
          [100.81057, 3.7947213],
          [100.8099223, 3.7975843],
          [100.8097027, 3.8000038],
          [100.8095906, 3.8025453],
          [100.8098755, 3.8056034],
          [100.8103406, 3.8080689],
          [100.811302, 3.8113213],
          [100.8119805, 3.8137884],
          [100.812418, 3.8153589],
          [100.8127761, 3.8167729],
          [100.8129978, 3.8188753],
          [100.8134429, 3.8202769],
          [100.8137175, 3.8223308],
          [100.8140036, 3.8243501],
          [100.8141865, 3.8260896],
          [100.8146285, 3.8277936],
          [100.8150675, 3.8293165],
          [100.8153961, 3.8309878],
          [100.815761, 3.8329825],
          [100.8160663, 3.8349662],
          [100.8162553, 3.8367204],
          [100.8165765, 3.8380208],
          [100.8170162, 3.8390114],
          [100.8173676, 3.8401852],
          [100.8177952, 3.8413064],
          [100.8183778, 3.8424393],
          [100.8188951, 3.8438725],
          [100.819114, 3.8451969],
          [100.8191, 3.8459083],
          [100.8192754, 3.8461938],
          [100.8195699, 3.8462588],
          [100.8203979, 3.8463061],
          [100.8212011, 3.8461949],
          [100.8217393, 3.8458723],
          [100.8228199, 3.845665],
          [100.8241471, 3.8455919],
          [100.8257325, 3.8457625],
          [100.8274769, 3.8456326],
          [100.82918, 3.8451401],
          [100.8294804, 3.8497019],
        ],
        [
          [101.6596875, 2.8991418],
          [101.6611215, 2.8939641],
          [101.6618804, 2.8912238],
          [101.66278, 2.8900445],
          [101.6637359, 2.8893707],
          [101.6633985, 2.8879668],
          [101.6626113, 2.8870683],
          [101.66278, 2.8854397],
          [101.6655633, 2.879796],
          [101.6711331, 2.8833749],
          [101.671864, 2.8803424],
          [101.674338, 2.8768326],
          [101.675069, 2.8813813],
          [101.6764827, 2.8815092],
          [101.6782718, 2.8795635],
          [101.6792658, 2.8798812],
          [101.6792658, 2.8803577],
          [101.6804586, 2.8805165],
          [101.6806176, 2.8819857],
          [101.6820489, 2.8818666],
          [101.6830429, 2.8823431],
          [101.6835597, 2.8818666],
          [101.684673, 2.8821843],
          [101.6848718, 2.883177],
          [101.6849513, 2.885083],
          [101.6867007, 2.885083],
          [101.6878537, 2.8900465],
          [101.6880922, 2.8920717],
          [101.6906766, 2.8935806],
          [101.6925452, 2.8931041],
          [101.6956464, 2.8934614],
          [101.697197, 2.8944144],
          [101.6974356, 2.898822],
          [101.7008946, 2.8999736],
          [101.7016898, 2.901681],
          [101.7010934, 2.9054532],
          [101.6975168, 2.9062996],
          [101.6975964, 2.9095159],
          [101.7003, 2.9095556],
          [101.7003795, 2.9130896],
          [101.707218, 2.9132882],
          [101.7074963, 2.9292504],
          [101.7326821, 2.9292292],
          [101.732274, 2.9531418],
          [101.7308825, 2.9548492],
          [101.7151778, 2.9549683],
          [101.7149392, 2.9635845],
          [101.7144223, 2.9632271],
          [101.713985, 2.9639815],
          [101.7125934, 2.9618374],
          [101.7104465, 2.9611227],
          [101.7084188, 2.9617977],
          [101.7084983, 2.9630286],
          [101.7033694, 2.9629889],
          [101.7030911, 2.9667212],
          [101.701779, 2.9679124],
          [101.6997911, 2.9678727],
          [101.6995525, 2.9700168],
          [101.7005067, 2.9731138],
          [101.7049597, 2.976449],
          [101.7077031, 2.9797049],
          [101.7082995, 2.9823651],
          [101.7071862, 2.9824445],
          [101.707067, 2.9812136],
          [101.7062125, 2.979564],
          [101.7058742, 2.9789108],
          [101.7026537, 2.9770843],
          [101.6971272, 2.976052],
          [101.6930321, 2.9741461],
          [101.6904875, 2.9721609],
          [101.6846032, 2.9704138],
          [101.6825358, 2.9708109],
          [101.6762936, 2.9717241],
          [101.6757768, 2.9711285],
          [101.6735503, 2.9713271],
          [101.672278, 2.9706521],
          [101.6716816, 2.97093],
          [101.6706479, 2.9704932],
          [101.6697732, 2.9694212],
          [101.6688985, 2.9694212],
          [101.667825, 2.9688256],
          [101.6653202, 2.9667609],
          [101.6648828, 2.9658874],
          [101.6640081, 2.9654506],
          [101.6628154, 2.9643389],
          [101.6629347, 2.9612418],
          [101.6642467, 2.9578271],
          [101.664799, 2.9574323],
          [101.6680238, 2.9551271],
          [101.669431, 2.9550936],
          [101.6696937, 2.9550874],
          [101.6743852, 2.9507198],
          [101.6747033, 2.9496477],
          [101.6747033, 2.9467889],
          [101.6738286, 2.9460344],
          [101.6722382, 2.9460742],
          [101.6710455, 2.9458756],
          [101.667984, 2.9453594],
          [101.6659166, 2.9441683],
          [101.6648431, 2.9440094],
          [101.6672286, 2.9414682],
          [101.6685804, 2.9390461],
          [101.6687394, 2.9371799],
          [101.6674672, 2.930946],
          [101.6682623, 2.9297548],
          [101.6681828, 2.9286033],
          [101.6685804, 2.9278885],
          [101.6694153, 2.9274915],
          [101.6694153, 2.9264988],
          [101.6706081, 2.9259429],
          [101.6712704, 2.9247484],
          [101.6725636, 2.922783],
          [101.6715515, 2.9216599],
          [101.6722262, 2.9204806],
          [101.6723387, 2.9190768],
          [101.6720013, 2.9157075],
          [101.6698647, 2.9153706],
          [101.6693024, 2.9145844],
          [101.6699209, 2.9113836],
          [101.6695273, 2.9103166],
          [101.6683465, 2.9094743],
          [101.6657601, 2.9093059],
          [101.664748, 2.9082389],
          [101.6644106, 2.9091935],
          [101.6643094, 2.9091292],
          [101.6640482, 2.9089632],
          [101.6637921, 2.9088005],
          [101.6630612, 2.9042519],
          [101.6603622, 2.902118],
          [101.6596875, 2.8991418],
        ],
        [
          [101.615081, 3.1534803],
          [101.6151785, 3.152866],
          [101.6164905, 3.1506222],
          [101.6173808, 3.1492228],
          [101.6187211, 3.1469526],
          [101.6193005, 3.1460884],
          [101.6200255, 3.144747],
          [101.6213231, 3.1433364],
          [101.6233233, 3.1413027],
          [101.6248359, 3.1415503],
          [101.6253218, 3.1415494],
          [101.6261565, 3.1409386],
          [101.6271544, 3.1394086],
          [101.628069, 3.138012],
          [101.629104, 3.1373507],
          [101.6296007, 3.136808],
          [101.6296214, 3.1351764],
          [101.6293781, 3.1339877],
          [101.6292225, 3.133422],
          [101.6292746, 3.1326296],
          [101.6294992, 3.1305895],
          [101.6300031, 3.1306056],
          [101.630369, 3.1306962],
          [101.6317081, 3.1316348],
          [101.6348734, 3.1323485],
          [101.6361197, 3.1320682],
          [101.6366216, 3.1318186],
          [101.6371602, 3.131462],
          [101.6385446, 3.1303833],
          [101.6403895, 3.1295851],
          [101.6437713, 3.1277758],
          [101.6446912, 3.1275108],
          [101.6457262, 3.1275397],
          [101.6464341, 3.1277216],
          [101.6470491, 3.1282235],
          [101.647883, 3.1272514],
          [101.6481373, 3.126654],
          [101.6483615, 3.1260753],
          [101.6481066, 3.1250671],
          [101.6441065, 3.1208351],
          [101.6436373, 3.1203716],
          [101.6434965, 3.1201506],
          [101.6434442, 3.1199364],
          [101.6434241, 3.1196016],
          [101.643412, 3.1192253],
          [101.6434643, 3.1190632],
          [101.6435863, 3.1188731],
          [101.6437116, 3.1187534],
          [101.6438385, 3.1186575],
          [101.6439726, 3.118612],
          [101.6453069, 3.1185693],
          [101.6473941, 3.1184553],
          [101.6478878, 3.1183575],
          [101.6484564, 3.1181754],
          [101.6488641, 3.1179933],
          [101.6492074, 3.1177469],
          [101.6494864, 3.1174684],
          [101.6498404, 3.1170184],
          [101.6501408, 3.1165042],
          [101.6505271, 3.1157864],
          [101.6508811, 3.1149401],
          [101.6526728, 3.1101407],
          [101.6531881, 3.1104568],
          [101.6585004, 3.1137155],
          [101.6596906, 3.1145888],
          [101.659689, 3.1135419],
          [101.6598268, 3.1073154],
          [101.6599186, 3.1040359],
          [101.6603895, 3.0999536],
          [101.6595741, 3.0988413],
          [101.6599531, 3.0973736],
          [101.6597923, 3.0957109],
          [101.6600266, 3.0919161],
          [101.6607337, 3.0909607],
          [101.6631881, 3.087721],
          [101.6636166, 3.0863646],
          [101.6638084, 3.085849],
          [101.6598479, 3.0844879],
          [101.6603731, 3.0832446],
          [101.6594713, 3.082867],
          [101.6601638, 3.0811182],
          [101.6589734, 3.0806229],
          [101.6591694, 3.0801685],
          [101.6592471, 3.0799778],
          [101.659276, 3.0798196],
          [101.6589014, 3.0795659],
          [101.6590814, 3.0790415],
          [101.6575435, 3.0784848],
          [101.6574377, 3.0786884],
          [101.6573126, 3.0787017],
          [101.6570745, 3.0786298],
          [101.6554244, 3.078617],
          [101.6546138, 3.0783374],
          [101.6540135, 3.0776378],
          [101.6541042, 3.0769934],
          [101.652048, 3.0763907],
          [101.6516167, 3.0752235],
          [101.6515034, 3.0750706],
          [101.6513633, 3.0749177],
          [101.6510831, 3.0746493],
          [101.6505603, 3.0741876],
          [101.6474545, 3.0722224],
          [101.6466491, 3.071786],
          [101.646425, 3.0716645],
          [101.6465681, 3.0713969],
          [101.6485206, 3.0704929],
          [101.6506622, 3.0701461],
          [101.6503309, 3.0680643],
          [101.6505723, 3.067358],
          [101.6514225, 3.06645],
          [101.6500159, 3.0654362],
          [101.6485939, 3.0635833],
          [101.6484426, 3.0634034],
          [101.6493963, 3.0618149],
          [101.6485786, 3.0613413],
          [101.6490665, 3.0605033],
          [101.6495834, 3.0600205],
          [101.6479765, 3.0590096],
          [101.6489106, 3.057521],
          [101.6505927, 3.0581893],
          [101.6517234, 3.0586806],
          [101.6524511, 3.0589968],
          [101.6531397, 3.0573621],
          [101.6559671, 3.0588024],
          [101.6570918, 3.0581678],
          [101.6591857, 3.0586967],
          [101.659277, 3.0585131],
          [101.6593112, 3.0569725],
          [101.6593304, 3.0560661],
          [101.6593713, 3.0541451],
          [101.6592993, 3.0509139],
          [101.6628977, 3.0462403],
          [101.6657662, 3.0458161],
          [101.6686965, 3.0453827],
          [101.6685105, 3.0405502],
          [101.6712413, 3.0420981],
          [101.6720721, 3.0421216],
          [101.680401, 3.0423576],
          [101.6867673, 3.0456011],
          [101.6882289, 3.044401],
          [101.6893658, 3.0450822],
          [101.6901453, 3.0436875],
          [101.6912342, 3.0442943],
          [101.6927487, 3.0442365],
          [101.6927705, 3.0444548],
          [101.6951149, 3.0444335],
          [101.6972262, 3.0454714],
          [101.6992725, 3.0456336],
          [101.6991101, 3.0473526],
          [101.6985904, 3.0477743],
          [101.6985904, 3.048423],
          [101.7015137, 3.0490393],
          [101.7065807, 3.0495907],
          [101.7069671, 3.0459361],
          [101.7081832, 3.0459738],
          [101.7082337, 3.0478368],
          [101.7086784, 3.0478256],
          [101.7104459, 3.0531909],
          [101.7140838, 3.054164],
          [101.7154155, 3.0535153],
          [101.7150582, 3.0518287],
          [101.7166823, 3.0475662],
          [101.7199304, 3.0473068],
          [101.7215544, 3.04069],
          [101.7243478, 3.0401061],
          [101.724121, 3.0362455],
          [101.7229595, 3.0362864],
          [101.7222163, 3.035743],
          [101.7222225, 3.0333968],
          [101.7236392, 3.033413],
          [101.7242257, 3.0337398],
          [101.7289028, 3.0376322],
          [101.7306302, 3.035264],
          [101.7304618, 3.0550269],
          [101.7366427, 3.0549666],
          [101.7466776, 3.0548692],
          [101.7495919, 3.0549719],
          [101.7496982, 3.0659233],
          [101.7500728, 3.0738383],
          [101.7489035, 3.0742924],
          [101.7498779, 3.0757195],
          [101.7498129, 3.0768872],
          [101.7476042, 3.0767574],
          [101.7475393, 3.0787035],
          [101.7452656, 3.0846065],
          [101.7452969, 3.0863139],
          [101.7512956, 3.0924531],
          [101.751676, 3.0962642],
          [101.7525736, 3.0976666],
          [101.7522815, 3.0993962],
          [101.7505275, 3.101472],
          [101.7536457, 3.1036126],
          [101.753061, 3.1067911],
          [101.7517618, 3.1084776],
          [101.7478641, 3.1098398],
          [101.7453955, 3.1117209],
          [101.7441612, 3.1102939],
          [101.7413029, 3.1101641],
          [101.739354, 3.1121101],
          [101.7390292, 3.1154831],
          [101.7384446, 3.1161318],
          [101.7389643, 3.118467],
          [101.7399782, 3.1211468],
          [101.7404395, 3.122366],
          [101.7408715, 3.1235079],
          [101.741173, 3.1243049],
          [101.7390942, 3.1283265],
          [101.7380548, 3.1320239],
          [101.7354563, 3.1345536],
          [101.7350665, 3.1356563],
          [101.7352996, 3.1364998],
          [101.7355862, 3.1375374],
          [101.7388086, 3.1413125],
          [101.7404584, 3.1432454],
          [101.7390942, 3.1450616],
          [101.7401985, 3.1460995],
          [101.7422124, 3.1479157],
          [101.7408482, 3.1507048],
          [101.7414328, 3.1511589],
          [101.7467597, 3.1497318],
          [101.7470845, 3.1532994],
          [101.7505925, 3.1571912],
          [101.749683, 3.1579047],
          [101.7486436, 3.1585533],
          [101.7507224, 3.1607587],
          [101.7490009, 3.1619262],
          [101.7482214, 3.1612451],
          [101.7424073, 3.1625748],
          [101.7407061, 3.1622637],
          [101.7378924, 3.1647477],
          [101.7355538, 3.1648126],
          [101.7352245, 3.1683143],
          [101.7349041, 3.1717205],
          [101.7335724, 3.1729529],
          [101.7334479, 3.172949],
          [101.7314936, 3.172888],
          [101.7315586, 3.1739907],
          [101.7335075, 3.1761311],
          [101.7338647, 3.1782391],
          [101.73773, 3.1781418],
          [101.7378274, 3.1774284],
          [101.7434791, 3.1755474],
          [101.7463699, 3.1756447],
          [101.7478316, 3.1750933],
          [101.7499428, 3.1751582],
          [101.7503651, 3.1763581],
          [101.7561467, 3.1807363],
          [101.7566989, 3.1835578],
          [101.7568288, 3.1844335],
          [101.755627, 3.1847253],
          [101.7564391, 3.185828],
          [101.7581605, 3.1855361],
          [101.7591999, 3.187936],
          [101.7580306, 3.1896224],
          [101.7569263, 3.1937411],
          [101.7568938, 3.197049],
          [101.7534508, 3.1999353],
          [101.7510147, 3.2000002],
          [101.7505275, 3.2022703],
          [101.7482863, 3.2023676],
          [101.747117, 3.2044107],
          [101.7463884, 3.2095151],
          [101.7453539, 3.2098476],
          [101.7447134, 3.2100535],
          [101.7436415, 3.2127776],
          [101.7424397, 3.2130046],
          [101.7406533, 3.2148207],
          [101.7391592, 3.2152099],
          [101.7395489, 3.2174151],
          [101.7398823, 3.2231422],
          [101.7412952, 3.2236611],
          [101.7416119, 3.2250475],
          [101.7424077, 3.2253555],
          [101.742562, 3.2265149],
          [101.743845, 3.2276013],
          [101.7439911, 3.2285904],
          [101.7403025, 3.2332249],
          [101.7389951, 3.2333141],
          [101.7389708, 3.2326087],
          [101.7395717, 3.2323331],
          [101.7394905, 3.2314332],
          [101.7319305, 3.2315872],
          [101.7299654, 3.2329006],
          [101.7250218, 3.228731],
          [101.7248124, 3.2292747],
          [101.7237751, 3.2292792],
          [101.7237578, 3.2270852],
          [101.7193265, 3.2226199],
          [101.7184391, 3.2210858],
          [101.7184035, 3.2182084],
          [101.7156301, 3.2182535],
          [101.7156188, 3.2168432],
          [101.7149411, 3.2168423],
          [101.7148997, 3.2145469],
          [101.715701, 3.2145513],
          [101.7157054, 3.2153411],
          [101.7173291, 3.2153596],
          [101.7147728, 3.2128792],
          [101.7127506, 3.2149999],
          [101.711169, 3.2153721],
          [101.7103367, 3.2160767],
          [101.7085672, 3.2147495],
          [101.7072354, 3.2158846],
          [101.7038574, 3.2159819],
          [101.7032403, 3.2152684],
          [101.7018436, 3.2172466],
          [101.7014863, 3.2229867],
          [101.6971338, 3.2232785],
          [101.696809, 3.2279484],
          [101.672091, 3.2277214],
          [101.6720585, 3.2307049],
          [101.6734227, 3.2321642],
          [101.6715388, 3.236153],
          [101.6682907, 3.2406931],
          [101.6659845, 3.2431901],
          [101.6635809, 3.2443252],
          [101.6611124, 3.2425416],
          [101.6611449, 3.2405958],
          [101.659261, 3.2414066],
          [101.6577993, 3.2413093],
          [101.6572471, 3.2372556],
          [101.6573446, 3.2360233],
          [101.6540965, 3.2368665],
          [101.654064, 3.234337],
          [101.6504911, 3.2337208],
          [101.6462036, 3.2335911],
          [101.6460737, 3.2321642],
          [101.6374012, 3.2320994],
          [101.6379534, 3.2299915],
          [101.6380833, 3.2280133],
          [101.6369564, 3.2280566],
          [101.6355498, 3.2281106],
          [101.6357122, 3.2268134],
          [101.6392202, 3.2215598],
          [101.6364259, 3.2161596],
          [101.6355412, 3.216616],
          [101.6353854, 3.2166964],
          [101.6346685, 3.2166742],
          [101.634035, 3.2174496],
          [101.6339978, 3.2166154],
          [101.6339432, 3.2153934],
          [101.6341649, 3.2153778],
          [101.6341665, 3.215325],
          [101.6341764, 3.2150052],
          [101.6341991, 3.2142665],
          [101.6339902, 3.2141463],
          [101.6331713, 3.2132934],
          [101.6315713, 3.2119985],
          [101.6312656, 3.2116072],
          [101.6309129, 3.210555],
          [101.6303256, 3.2086088],
          [101.6294006, 3.2085925],
          [101.6293308, 3.2083997],
          [101.6276245, 3.2036909],
          [101.6262049, 3.2011251],
          [101.6251656, 3.1995411],
          [101.6239211, 3.198324],
          [101.6230345, 3.197846],
          [101.6218987, 3.1975594],
          [101.6202478, 3.1974894],
          [101.6191165, 3.1979053],
          [101.6188486, 3.1962285],
          [101.6188589, 3.195483],
          [101.6190204, 3.1938579],
          [101.6196138, 3.1901749],
          [101.6198848, 3.1883939],
          [101.619557, 3.1857576],
          [101.6191681, 3.1821202],
          [101.619304, 3.178065],
          [101.6190405, 3.1740359],
          [101.618942, 3.1733769],
          [101.6179704, 3.1722066],
          [101.6178152, 3.1710006],
          [101.6168932, 3.1702644],
          [101.6167597, 3.1701578],
          [101.6167563, 3.1701078],
          [101.6166459, 3.168471],
          [101.6165338, 3.1675584],
          [101.6161834, 3.1657988],
          [101.6160501, 3.1644449],
          [101.6165536, 3.1633401],
          [101.6163936, 3.1616919],
          [101.6163601, 3.161347],
          [101.6156169, 3.1607388],
          [101.6152677, 3.158678],
          [101.615081, 3.1534803],
        ],
      ],
    ],
  },
};
