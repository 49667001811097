export const kedah = {
  type: "Feature",
  properties: {
    gid: 2,
    id: 4444908,
    country: "MYS",
    name: "Kedah",
    enname: "Kedah",
    locname: "Kedah",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q188947",
    wikimedia: "en:Kedah",
    timestamp: "2020-01-01 22:53:02",
    note: null,
    path: "0,2108121,4444908",
    rpath: "4444908,2108121,0",
    iso3166_2: "MY-02",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [98.9381013, 5.6832217],
          [98.9372144, 5.6838364],
          [98.937707, 5.6844869],
          [98.9374466, 5.6854276],
          [98.9388368, 5.6859371],
          [98.9398743, 5.6846968],
          [98.9405113, 5.6826215],
          [98.9399198, 5.6811333],
          [98.9394729, 5.6812205],
          [98.9391263, 5.6820721],
          [98.938862, 5.6827679],
          [98.9381013, 5.6832217],
        ],
      ],
      [
        [
          [100.1953348, 6.250844],
          [100.1951113, 6.2518092],
          [100.195397, 6.2521609],
          [100.1956236, 6.2525751],
          [100.195804, 6.2528045],
          [100.1959295, 6.2530042],
          [100.1961812, 6.2532452],
          [100.1963935, 6.2536921],
          [100.1958019, 6.2541251],
          [100.1953576, 6.2543839],
          [100.19597, 6.2552622],
          [100.1972733, 6.2572647],
          [100.1978148, 6.25755],
          [100.198042, 6.2576395],
          [100.1988935, 6.2589508],
          [100.1985079, 6.25928],
          [100.1978245, 6.2598599],
          [100.1975464, 6.2601969],
          [100.1975538, 6.2608461],
          [100.1979361, 6.2611633],
          [100.198345, 6.2613783],
          [100.1986697, 6.2617095],
          [100.1987603, 6.2620726],
          [100.1987608, 6.2621576],
          [100.1984714, 6.2626182],
          [100.1981815, 6.2629802],
          [100.1982252, 6.2634694],
          [100.1983955, 6.2635398],
          [100.1984586, 6.2632947],
          [100.1985544, 6.2628488],
          [100.1988384, 6.2626262],
          [100.199239, 6.2626067],
          [100.1995697, 6.2628019],
          [100.1995554, 6.2632336],
          [100.1993433, 6.2635612],
          [100.1990841, 6.2639435],
          [100.1993056, 6.2640645],
          [100.1999403, 6.2639723],
          [100.2003894, 6.2641021],
          [100.2004416, 6.2643057],
          [100.200085, 6.2643147],
          [100.1996641, 6.2643648],
          [100.1995876, 6.2646372],
          [100.1996141, 6.2650823],
          [100.1996888, 6.2656291],
          [100.2000226, 6.2657766],
          [100.2001634, 6.2654834],
          [100.2001913, 6.265038],
          [100.200475, 6.2647712],
          [100.2008797, 6.2648435],
          [100.2010175, 6.2651655],
          [100.2008258, 6.265476],
          [100.2010842, 6.2655118],
          [100.2013393, 6.2655715],
          [100.2017442, 6.2656914],
          [100.2016837, 6.2657869],
          [100.2014563, 6.2658291],
          [100.2011373, 6.2658752],
          [100.20101, 6.2661649],
          [100.2013069, 6.266401],
          [100.2018412, 6.2665609],
          [100.2018909, 6.2669073],
          [100.2020676, 6.2674773],
          [100.2023306, 6.2677136],
          [100.202808, 6.2680268],
          [100.2027205, 6.2681633],
          [100.2024176, 6.2680564],
          [100.2022447, 6.2681084],
          [100.2022765, 6.2683122],
          [100.2022881, 6.2685568],
          [100.2023949, 6.2688043],
          [100.202867, 6.268798],
          [100.2031911, 6.2690204],
          [100.2031453, 6.2693028],
          [100.2029873, 6.2695723],
          [100.2027921, 6.2698827],
          [100.2027188, 6.2701109],
          [100.2032454, 6.2701281],
          [100.2036127, 6.2701973],
          [100.2036342, 6.2704362],
          [100.2034188, 6.2705372],
          [100.2029622, 6.270551],
          [100.2027698, 6.2706782],
          [100.2027032, 6.2708962],
          [100.2028039, 6.2712661],
          [100.2028775, 6.2713674],
          [100.2030117, 6.2713735],
          [100.2033852, 6.2713407],
          [100.2035518, 6.2713533],
          [100.203784, 6.2715626],
          [100.2037134, 6.2716888],
          [100.2035954, 6.2718255],
          [100.2034916, 6.272081],
          [100.203751, 6.2722868],
          [100.2039084, 6.2724626],
          [100.2038581, 6.2725785],
          [100.2035098, 6.2728321],
          [100.2031886, 6.2730822],
          [100.2029768, 6.2734506],
          [100.2028599, 6.2737776],
          [100.2026514, 6.2741221],
          [100.2025551, 6.2744728],
          [100.2028206, 6.2745732],
          [100.2032113, 6.2745742],
          [100.2035721, 6.2747012],
          [100.2035805, 6.274973],
          [100.2033592, 6.2754468],
          [100.2033103, 6.2757836],
          [100.2031851, 6.2764132],
          [100.2031119, 6.2766618],
          [100.2030503, 6.2771618],
          [100.2031947, 6.2774328],
          [100.2034488, 6.2778868],
          [100.2034145, 6.2783968],
          [100.2035368, 6.2789569],
          [100.2035526, 6.2793137],
          [100.203273, 6.2797097],
          [100.2026695, 6.2798935],
          [100.2024158, 6.2800718],
          [100.2022032, 6.2802974],
          [100.2021574, 6.2805798],
          [100.2022678, 6.2808749],
          [100.2022361, 6.2812353],
          [100.2018675, 6.2815197],
          [100.2015999, 6.2816369],
          [100.2013198, 6.2819411],
          [100.2012842, 6.2822404],
          [100.2018696, 6.2824136],
          [100.2023091, 6.2826319],
          [100.2026756, 6.2831395],
          [100.2029831, 6.2834231],
          [100.2033253, 6.2838357],
          [100.2035895, 6.2842658],
          [100.2038143, 6.2849374],
          [100.2040151, 6.2855582],
          [100.2042755, 6.2859271],
          [100.2043546, 6.2865777],
          [100.2043568, 6.2870041],
          [100.204383, 6.2879625],
          [100.2045181, 6.2889542],
          [100.204688, 6.2895173],
          [100.2049054, 6.2900701],
          [100.2051844, 6.2907006],
          [100.2055721, 6.2907764],
          [100.2060962, 6.290933],
          [100.2063556, 6.2911489],
          [100.2066016, 6.2913785],
          [100.2072527, 6.2917485],
          [100.2078089, 6.2921778],
          [100.214734, 6.2969998],
          [100.2159725, 6.2977848],
          [100.2170549, 6.2986737],
          [100.2175883, 6.2993058],
          [100.2178167, 6.299816],
          [100.2178937, 6.3002969],
          [100.2178931, 6.3004037],
          [100.2179242, 6.3005363],
          [100.2180857, 6.3010989],
          [100.2181729, 6.3017194],
          [100.2182523, 6.3020956],
          [100.2183829, 6.3025294],
          [100.2184694, 6.3029938],
          [100.2185422, 6.3033972],
          [100.2186833, 6.3039463],
          [100.2188885, 6.3043899],
          [100.2190528, 6.3047998],
          [100.2191216, 6.3050539],
          [100.2191772, 6.3054099],
          [100.2192499, 6.305793],
          [100.2194451, 6.306274],
          [100.2196202, 6.3068399],
          [100.2198186, 6.3072971],
          [100.2200031, 6.3076696],
          [100.2202351, 6.3080452],
          [100.2210848, 6.3094363],
          [100.2212416, 6.309663],
          [100.2215518, 6.3100655],
          [100.2217869, 6.3103801],
          [100.2218791, 6.3105324],
          [100.2220257, 6.3107421],
          [100.2223288, 6.3110904],
          [100.2226277, 6.3112317],
          [100.2231122, 6.3119965],
          [100.2232654, 6.3121723],
          [100.2235445, 6.3124461],
          [100.2237248, 6.3125845],
          [100.2238847, 6.3127263],
          [100.2241432, 6.3129356],
          [100.2243951, 6.3131891],
          [100.2245074, 6.3132938],
          [100.224657, 6.3134154],
          [100.2248135, 6.3135539],
          [100.2250995, 6.3138615],
          [100.2253214, 6.3142304],
          [100.2254442, 6.3144301],
          [100.2256748, 6.3146264],
          [100.225814, 6.314807],
          [100.2260291, 6.3150907],
          [100.2263585, 6.3154649],
          [100.2266624, 6.3158261],
          [100.2278922, 6.3170271],
          [100.2305785, 6.3199183],
          [100.2307816, 6.3200735],
          [100.2309972, 6.3202673],
          [100.2312512, 6.3205733],
          [100.2313908, 6.3206896],
          [100.2315303, 6.3208188],
          [100.2317459, 6.3210126],
          [100.232025, 6.3212452],
          [100.2321897, 6.3214259],
          [100.2323672, 6.3215938],
          [100.2328107, 6.3220714],
          [100.2330898, 6.322304],
          [100.2333306, 6.3225622],
          [100.2335462, 6.3227559],
          [100.233762, 6.322924],
          [100.2340793, 6.3231569],
          [100.2342367, 6.3232503],
          [100.2348245, 6.3235669],
          [100.2350385, 6.3236645],
          [100.2354157, 6.3238631],
          [100.2358712, 6.3241735],
          [100.236031, 6.3242882],
          [100.236371, 6.324521],
          [100.2365172, 6.3246052],
          [100.236847, 6.324838],
          [100.2370035, 6.3249934],
          [100.2372521, 6.3252469],
          [100.2375579, 6.3254289],
          [100.2378742, 6.3256516],
          [100.2380307, 6.3257867],
          [100.2384768, 6.326287],
          [100.2387053, 6.3266288],
          [100.23892, 6.3268925],
          [100.2393421, 6.3273387],
          [100.2396927, 6.3276732],
          [100.2399207, 6.327886],
          [100.2400365, 6.3280077],
          [100.240289, 6.3282087],
          [100.2404533, 6.3283426],
          [100.240673, 6.3285379],
          [100.24077, 6.3286367],
          [100.2411303, 6.3289895],
          [100.2413932, 6.3293279],
          [100.241739, 6.3298071],
          [100.2419052, 6.3299905],
          [100.2420709, 6.3302581],
          [100.242209, 6.3304975],
          [100.2424026, 6.3307793],
          [100.2426231, 6.3312298],
          [100.2429134, 6.3316666],
          [100.2432038, 6.3320753],
          [100.2437398, 6.3325019],
          [100.2443887, 6.3329861],
          [100.2448659, 6.3332214],
          [100.2450892, 6.3333176],
          [100.245343, 6.3334733],
          [100.2461253, 6.3338122],
          [100.2468967, 6.3342332],
          [100.2474266, 6.3344788],
          [100.2483392, 6.3345872],
          [100.2489064, 6.3348326],
          [100.2493243, 6.3350379],
          [100.2495092, 6.3354918],
          [100.2497295, 6.3363289],
          [100.2501756, 6.337751],
          [100.2504095, 6.3384368],
          [100.2506963, 6.3393633],
          [100.2510676, 6.3403166],
          [100.251497, 6.3417432],
          [100.2518579, 6.3429732],
          [100.2521648, 6.3434096],
          [100.2526467, 6.344361],
          [100.2533323, 6.3453931],
          [100.2543015, 6.34696],
          [100.2551995, 6.3485408],
          [100.2557417, 6.3491907],
          [100.2565574, 6.3498884],
          [100.2570907, 6.3503639],
          [100.2575926, 6.3508078],
          [100.2583542, 6.3515218],
          [100.2590156, 6.3521049],
          [100.2601491, 6.3530139],
          [100.2608012, 6.3535034],
          [100.2616015, 6.3541227],
          [100.2626099, 6.3548623],
          [100.2642325, 6.3558978],
          [100.2646478, 6.3561962],
          [100.2653882, 6.3568766],
          [100.2658721, 6.3574548],
          [100.2664576, 6.3583129],
          [100.2671677, 6.3592477],
          [100.2693988, 6.3619229],
          [100.2707592, 6.3636336],
          [100.2720912, 6.3651787],
          [100.2732017, 6.3664295],
          [100.2738017, 6.3672244],
          [100.274515, 6.3675302],
          [100.2752226, 6.3674854],
          [100.2763064, 6.3681402],
          [100.2787357, 6.3698907],
          [100.2793473, 6.3704391],
          [100.2827862, 6.3754797],
          [100.2939072, 6.3920275],
          [100.3024205, 6.4038126],
          [100.3048881, 6.4045367],
          [100.3046416, 6.4068953],
          [100.3057137, 6.4071531],
          [100.3055421, 6.4082698],
          [100.3075902, 6.409159],
          [100.3084158, 6.4105044],
          [100.3095527, 6.4103147],
          [100.3107257, 6.4095511],
          [100.3208591, 6.4179916],
          [100.3422567, 6.4362971],
          [100.3553429, 6.4476279],
          [100.3630019, 6.4542178],
          [100.36524, 6.4754432],
          [100.368278, 6.5042542],
          [100.3690119, 6.5118746],
          [100.3699827, 6.5217723],
          [100.3705472, 6.5282035],
          [100.3706848, 6.5299344],
          [100.3706481, 6.5302959],
          [100.3699077, 6.5326856],
          [100.3681658, 6.536737],
          [100.3673907, 6.5381143],
          [100.366784, 6.5395792],
          [100.3672207, 6.539449],
          [100.3677825, 6.5392316],
          [100.3681515, 6.5390564],
          [100.3684128, 6.5390434],
          [100.3689452, 6.5391018],
          [100.3691314, 6.5394847],
          [100.3691673, 6.539806],
          [100.3693959, 6.5401694],
          [100.3696768, 6.540575],
          [100.3697519, 6.5410552],
          [100.3702974, 6.5415485],
          [100.3702419, 6.5418697],
          [100.3708526, 6.5422623],
          [100.3712095, 6.5433072],
          [100.3719162, 6.5435137],
          [100.3724474, 6.5442984],
          [100.3728538, 6.5445187],
          [100.3727062, 6.5448178],
          [100.3728355, 6.5450426],
          [100.3732974, 6.5451115],
          [100.3735607, 6.5448774],
          [100.3742212, 6.544648],
          [100.3744198, 6.5444002],
          [100.3747431, 6.5435834],
          [100.3754591, 6.5435008],
          [100.3761612, 6.5433264],
          [100.3767478, 6.5428262],
          [100.3774591, 6.5428491],
          [100.378045, 6.5424559],
          [100.378567, 6.5423641],
          [100.3788996, 6.5421301],
          [100.3794908, 6.5421485],
          [100.380179, 6.5419695],
          [100.3806409, 6.5420337],
          [100.3811583, 6.5418043],
          [100.3816192, 6.5418556],
          [100.3821514, 6.5416161],
          [100.3831814, 6.5406249],
          [100.3835232, 6.5404781],
          [100.3832507, 6.5398081],
          [100.383611, 6.5392712],
          [100.3839666, 6.5387021],
          [100.3842293, 6.5384668],
          [100.384724, 6.5385625],
          [100.3851489, 6.5385808],
          [100.3853749, 6.5388239],
          [100.3860478, 6.5390211],
          [100.386485, 6.5387716],
          [100.3870648, 6.5389533],
          [100.387316, 6.53875],
          [100.387471, 6.538214],
          [100.387623, 6.5367476],
          [100.3878431, 6.535768],
          [100.3878679, 6.5353737],
          [100.3881997, 6.5349547],
          [100.3891734, 6.5348561],
          [100.3895268, 6.535044],
          [100.390333, 6.5350317],
          [100.3912416, 6.5349547],
          [100.3915827, 6.5345943],
          [100.3925532, 6.5345696],
          [100.393164, 6.5345511],
          [100.3935578, 6.5346651],
          [100.3945563, 6.5339658],
          [100.3952354, 6.5340521],
          [100.3958834, 6.5337687],
          [100.3966524, 6.5339874],
          [100.3973842, 6.533898],
          [100.3979888, 6.5343601],
          [100.3985222, 6.5341322],
          [100.3989408, 6.5344803],
          [100.3993749, 6.5346682],
          [100.4000601, 6.5347575],
          [100.4011671, 6.5353336],
          [100.401288, 6.5355862],
          [100.401657, 6.5360144],
          [100.4019733, 6.5360637],
          [100.402119, 6.5363502],
          [100.4025563, 6.5357926],
          [100.4027795, 6.5351734],
          [100.402826, 6.5346374],
          [100.4031857, 6.5341106],
          [100.4026958, 6.5334144],
          [100.4019671, 6.5324964],
          [100.4018214, 6.5319665],
          [100.4022834, 6.5312672],
          [100.4023516, 6.530688],
          [100.4028353, 6.530457],
          [100.403009, 6.5298717],
          [100.4036322, 6.5294003],
          [100.4045469, 6.5292863],
          [100.4050152, 6.5294866],
          [100.4056756, 6.5294927],
          [100.4064942, 6.5290738],
          [100.4069252, 6.528701],
          [100.4073097, 6.5287749],
          [100.4078121, 6.5286147],
          [100.4081469, 6.5287657],
          [100.4084043, 6.528242],
          [100.4083547, 6.5275642],
          [100.4083637, 6.5272197],
          [100.4084181, 6.5269529],
          [100.4084835, 6.5266212],
          [100.408625, 6.5263003],
          [100.408948, 6.5258424],
          [100.4092238, 6.5255575],
          [100.4095505, 6.5252438],
          [100.4101965, 6.5247823],
          [100.410367, 6.5246272],
          [100.410563, 6.5243352],
          [100.4105993, 6.5237835],
          [100.4106138, 6.5233688],
          [100.4107481, 6.5231381],
          [100.4110523, 6.5228508],
          [100.4112163, 6.5226874],
          [100.4115756, 6.5225395],
          [100.411757, 6.5225395],
          [100.4121417, 6.5227811],
          [100.4130091, 6.5228676],
          [100.4134461, 6.5231404],
          [100.4147981, 6.5235409],
          [100.4160849, 6.5233252],
          [100.4163418, 6.5231622],
          [100.4163498, 6.5224724],
          [100.4162058, 6.5215543],
          [100.4161862, 6.5213804],
          [100.417221, 6.5202352],
          [100.4173177, 6.519556],
          [100.4173507, 6.5193846],
          [100.4175518, 6.5190699],
          [100.4182732, 6.5186425],
          [100.419027, 6.5189592],
          [100.4191312, 6.5189727],
          [100.4194297, 6.5190114],
          [100.4195854, 6.5189918],
          [100.4198583, 6.5186767],
          [100.4199194, 6.5180853],
          [100.4204953, 6.518066],
          [100.4207164, 6.5178873],
          [100.4213985, 6.5179674],
          [100.4222549, 6.5177157],
          [100.4226935, 6.5178529],
          [100.4233339, 6.5174573],
          [100.4236848, 6.5175138],
          [100.424048, 6.5180318],
          [100.4247846, 6.5184049],
          [100.4253768, 6.5189717],
          [100.425383, 6.5196772],
          [100.4255008, 6.5199822],
          [100.4260868, 6.5202517],
          [100.4264403, 6.5201931],
          [100.4267504, 6.5202578],
          [100.4271876, 6.5205936],
          [100.4273954, 6.5207661],
          [100.4278326, 6.5215825],
          [100.4280651, 6.5222849],
          [100.4284976, 6.5227465],
          [100.4298884, 6.5234063],
          [100.4301767, 6.523617],
          [100.4305314, 6.5237685],
          [100.4307501, 6.5238179],
          [100.4307655, 6.5235889],
          [100.4308839, 6.5229093],
          [100.431193, 6.5226065],
          [100.4317609, 6.522478],
          [100.4320823, 6.5224312],
          [100.4324291, 6.5224451],
          [100.4326307, 6.522515],
          [100.4327643, 6.5226966],
          [100.4329183, 6.5228831],
          [100.4332323, 6.5230664],
          [100.4339041, 6.5230674],
          [100.4340665, 6.5230659],
          [100.4343595, 6.52318],
          [100.4345681, 6.523409],
          [100.4350242, 6.5239187],
          [100.4352665, 6.5241178],
          [100.4357024, 6.524325],
          [100.4360446, 6.5243856],
          [100.4364946, 6.5244042],
          [100.4370918, 6.5244807],
          [100.4373374, 6.5247813],
          [100.4377276, 6.524899],
          [100.4379184, 6.5250667],
          [100.4380675, 6.5254392],
          [100.4381452, 6.5256606],
          [100.4384709, 6.5256559],
          [100.438899, 6.5259346],
          [100.4390569, 6.5262017],
          [100.4392259, 6.5265178],
          [100.4395143, 6.5270611],
          [100.43983, 6.5271122],
          [100.4408006, 6.5271092],
          [100.4407975, 6.5268781],
          [100.4410455, 6.5264283],
          [100.4417804, 6.5260895],
          [100.4427448, 6.5263482],
          [100.4428378, 6.5265639],
          [100.4429525, 6.5272231],
          [100.4432998, 6.5274511],
          [100.4435662, 6.5273731],
          [100.4439879, 6.5270773],
          [100.4442235, 6.5271081],
          [100.4446483, 6.5274039],
          [100.4448406, 6.5274254],
          [100.4450607, 6.5273022],
          [100.4452902, 6.526988],
          [100.445408, 6.5266091],
          [100.4455941, 6.5262763],
          [100.4459073, 6.5261316],
          [100.4464189, 6.5260607],
          [100.4467042, 6.5260915],
          [100.44707, 6.5262887],
          [100.4473026, 6.5263718],
          [100.447777, 6.526187],
          [100.4479724, 6.5261131],
          [100.4480654, 6.5261508],
          [100.448585, 6.5266191],
          [100.44896, 6.5266562],
          [100.4492428, 6.5265886],
          [100.4494336, 6.5264863],
          [100.4496835, 6.5262074],
          [100.4500694, 6.5262553],
          [100.4502931, 6.5262575],
          [100.4508938, 6.5262445],
          [100.4513411, 6.5267433],
          [100.4514113, 6.5267716],
          [100.4520362, 6.526813],
          [100.4526129, 6.5267021],
          [100.4532206, 6.5268531],
          [100.4536827, 6.526773],
          [100.4538532, 6.5269208],
          [100.4542532, 6.5275246],
          [100.4542005, 6.5277834],
          [100.4538935, 6.5283564],
          [100.4538904, 6.5286306],
          [100.4541695, 6.5288185],
          [100.4553354, 6.5290218],
          [100.4556516, 6.528991],
          [100.4556982, 6.5291451],
          [100.4557106, 6.5294285],
          [100.4558396, 6.5297119],
          [100.455914, 6.5300693],
          [100.4559574, 6.5302633],
          [100.4561186, 6.530556],
          [100.4562365, 6.5309349],
          [100.4562768, 6.5313785],
          [100.4564568, 6.5316972],
          [100.4566185, 6.5317974],
          [100.4567545, 6.5318369],
          [100.4569637, 6.5317676],
          [100.4571613, 6.5316899],
          [100.4576841, 6.5313607],
          [100.457824, 6.531422],
          [100.4580196, 6.5315815],
          [100.4581822, 6.5316081],
          [100.458353, 6.5315406],
          [100.4585568, 6.5313811],
          [100.4587132, 6.5313034],
          [100.458954, 6.5312993],
          [100.4591536, 6.5313218],
          [100.4593265, 6.531375],
          [100.4594912, 6.5313546],
          [100.4602444, 6.5311491],
          [100.460145, 6.5306811],
          [100.4603487, 6.5299061],
          [100.4604477, 6.5296169],
          [100.4607126, 6.5290646],
          [100.4608028, 6.5286077],
          [100.4606893, 6.5283214],
          [100.4608743, 6.5282686],
          [100.4610636, 6.5280314],
          [100.4614588, 6.5273709],
          [100.461729, 6.5268209],
          [100.4620142, 6.5259152],
          [100.4620568, 6.5256196],
          [100.4623599, 6.5252017],
          [100.462544, 6.5250841],
          [100.4635307, 6.5253324],
          [100.4639955, 6.5250318],
          [100.4655435, 6.5247879],
          [100.4663723, 6.5247922],
          [100.4669555, 6.5248445],
          [100.4673589, 6.5246104],
          [100.4675607, 6.5241094],
          [100.4678698, 6.5237304],
          [100.4678413, 6.5232882],
          [100.4675387, 6.5225628],
          [100.4673414, 6.5216326],
          [100.4674379, 6.521515],
          [100.4678216, 6.5214474],
          [100.4682535, 6.520966],
          [100.4689244, 6.5207743],
          [100.4697291, 6.5207046],
          [100.4704711, 6.5204181],
          [100.4710385, 6.5205198],
          [100.4716742, 6.5204674],
          [100.4720494, 6.5208063],
          [100.4723812, 6.5216812],
          [100.473144, 6.5226516],
          [100.4742726, 6.5227902],
          [100.4746292, 6.5231753],
          [100.4754385, 6.5232185],
          [100.4756618, 6.5234772],
          [100.4767036, 6.5236621],
          [100.4770137, 6.5239578],
          [100.4778075, 6.5237483],
          [100.4787253, 6.5236374],
          [100.478961, 6.5236313],
          [100.4792959, 6.5232801],
          [100.4802509, 6.5228549],
          [100.4809207, 6.5230151],
          [100.4814788, 6.5238531],
          [100.482068, 6.5240872],
          [100.4825393, 6.5238962],
          [100.4830168, 6.5228919],
          [100.4835315, 6.5229597],
          [100.483978, 6.5227502],
          [100.484561, 6.5229474],
          [100.485547, 6.522627],
          [100.4867185, 6.5224058],
          [100.4873675, 6.5226498],
          [100.4879551, 6.523312],
          [100.4884462, 6.5234514],
          [100.4887356, 6.5239132],
          [100.4886743, 6.5242182],
          [100.4891917, 6.5252987],
          [100.4894285, 6.5252899],
          [100.4898232, 6.5255252],
          [100.4902091, 6.5254729],
          [100.4903641, 6.5249662],
          [100.490606, 6.5247444],
          [100.4917036, 6.5243994],
          [100.4917904, 6.524079],
          [100.4927145, 6.5237709],
          [100.4934587, 6.5235491],
          [100.4937625, 6.5237832],
          [100.4943269, 6.5230069],
          [100.4948912, 6.5221443],
          [100.4958835, 6.521904],
          [100.4961997, 6.5212817],
          [100.4958586, 6.5202281],
          [100.4957718, 6.518429],
          [100.4957656, 6.5181086],
          [100.4953625, 6.5179853],
          [100.4943393, 6.5174123],
          [100.493693, 6.5171429],
          [100.4932545, 6.5169164],
          [100.4929168, 6.5169512],
          [100.4927326, 6.5166375],
          [100.4928335, 6.5159971],
          [100.4927239, 6.515483],
          [100.4927107, 6.5130344],
          [100.4928423, 6.5126685],
          [100.4927853, 6.5116228],
          [100.4932106, 6.5106992],
          [100.4935877, 6.5098539],
          [100.493443, 6.5092047],
          [100.4938158, 6.5086384],
          [100.4945876, 6.5078236],
          [100.4952194, 6.507332],
          [100.4955474, 6.506737],
          [100.4964069, 6.5058657],
          [100.4973453, 6.5052862],
          [100.4981741, 6.5059092],
          [100.498595, 6.5059746],
          [100.4991914, 6.5066935],
          [100.4996694, 6.5067719],
          [100.4999457, 6.5062621],
          [100.5000991, 6.5053951],
          [100.4999752, 6.5045045],
          [100.5003543, 6.5028847],
          [100.5006701, 6.5021091],
          [100.5005297, 6.5016124],
          [100.5010647, 6.5008718],
          [100.5013191, 6.5001659],
          [100.5018979, 6.4995385],
          [100.5024241, 6.4995734],
          [100.5030476, 6.4991182],
          [100.5035862, 6.4988153],
          [100.5040071, 6.4991333],
          [100.5042133, 6.4991813],
          [100.5044588, 6.4990201],
          [100.5049149, 6.498702],
          [100.5055726, 6.4986584],
          [100.506419, 6.4988937],
          [100.5068882, 6.499325],
          [100.5071513, 6.499813],
          [100.5079538, 6.5005493],
          [100.5080853, 6.5002749],
          [100.5086028, 6.4998304],
          [100.5091334, 6.4993381],
          [100.5093176, 6.4989808],
          [100.5092693, 6.4984972],
          [100.5092737, 6.4981138],
          [100.5096552, 6.4975997],
          [100.509436, 6.4971335],
          [100.5093746, 6.4962795],
          [100.509892, 6.4955345],
          [100.5102165, 6.4946761],
          [100.5111242, 6.4946631],
          [100.5114926, 6.4942099],
          [100.5117689, 6.4933516],
          [100.511839, 6.49299],
          [100.5116066, 6.4922667],
          [100.5122512, 6.4925848],
          [100.5130669, 6.4928506],
          [100.5137948, 6.4929421],
          [100.514663, 6.4930989],
          [100.5151849, 6.4932427],
          [100.5157933, 6.4927046],
          [100.5157809, 6.4923164],
          [100.5162522, 6.4918543],
          [100.5166026, 6.4912781],
          [100.5168196, 6.4907451],
          [100.5170743, 6.4905088],
          [100.5176097, 6.4900654],
          [100.5179436, 6.4897553],
          [100.5186195, 6.4890364],
          [100.5187478, 6.4888528],
          [100.5187222, 6.4887508],
          [100.5188248, 6.4885621],
          [100.5190455, 6.4885366],
          [100.5192065, 6.4886735],
          [100.5193662, 6.4888574],
          [100.5196312, 6.4889512],
          [100.5199832, 6.4890053],
          [100.5202481, 6.4889981],
          [100.5203824, 6.4888863],
          [100.5205748, 6.4888178],
          [100.5207998, 6.4888466],
          [100.5211228, 6.4889872],
          [100.5211881, 6.4891495],
          [100.5212679, 6.4893298],
          [100.5213877, 6.4894777],
          [100.52162, 6.4895822],
          [100.5219285, 6.4896688],
          [100.522364, 6.4898202],
          [100.5224555, 6.489997],
          [100.5227224, 6.4901601],
          [100.5232716, 6.4903131],
          [100.5236719, 6.4903998],
          [100.5239799, 6.4903998],
          [100.5242622, 6.4902009],
          [100.524565, 6.4898236],
          [100.5247035, 6.4894615],
          [100.5249499, 6.4891912],
          [100.5247651, 6.4888444],
          [100.5246368, 6.4885895],
          [100.5246112, 6.4883651],
          [100.524606, 6.4881254],
          [100.5247189, 6.4879571],
          [100.5250064, 6.487748],
          [100.5251552, 6.4877174],
          [100.5255042, 6.4873277],
          [100.5257814, 6.487595],
          [100.5261663, 6.4879316],
          [100.5264281, 6.4881611],
          [100.5270696, 6.488411],
          [100.5274494, 6.4885232],
          [100.5279268, 6.488666],
          [100.5282296, 6.4886762],
          [100.5286504, 6.4884977],
          [100.5289892, 6.4883753],
          [100.5293793, 6.4886048],
          [100.5295794, 6.4887985],
          [100.5298361, 6.4889158],
          [100.5301389, 6.488926],
          [100.5303134, 6.4889311],
          [100.5305722, 6.48906],
          [100.5309363, 6.4892259],
          [100.5313305, 6.4894489],
          [100.5315741, 6.4896693],
          [100.5319957, 6.4898325],
          [100.5324455, 6.4896441],
          [100.5328156, 6.4893051],
          [100.5329898, 6.4892402],
          [100.5333237, 6.4892907],
          [100.5336286, 6.48937],
          [100.5338536, 6.4893917],
          [100.5339843, 6.4890022],
          [100.5340955, 6.488662],
          [100.5346898, 6.4881744],
          [100.5357429, 6.48719],
          [100.536241, 6.486945],
          [100.536503, 6.486876],
          [100.536897, 6.486841],
          [100.537272, 6.487014],
          [100.537534, 6.487291],
          [100.537928, 6.48807],
          [100.538115, 6.48826],
          [100.538434, 6.488416],
          [100.538752, 6.488468],
          [100.539671, 6.488398],
          [100.539895, 6.488502],
          [100.540139, 6.488727],
          [100.541001, 6.489938],
          [100.541263, 6.490163],
          [100.541451, 6.490769],
          [100.541545, 6.490942],
          [100.541732, 6.49108],
          [100.542107, 6.491184],
          [100.542594, 6.490838],
          [100.542875, 6.490526],
          [100.543381, 6.489782],
          [100.544449, 6.488502],
          [100.545068, 6.487845],
          [100.545311, 6.487533],
          [100.545649, 6.486841],
          [100.546642, 6.485734],
          [100.547054, 6.485076],
          [100.54741, 6.484748],
          [100.547785, 6.484557],
          [100.548122, 6.484471],
          [100.548347, 6.484471],
          [100.548891, 6.484748],
          [100.549359, 6.485267],
          [100.550334, 6.486045],
          [100.550484, 6.486357],
          [100.550465, 6.48653],
          [100.550221, 6.487291],
          [100.550203, 6.487551],
          [100.55024, 6.487776],
          [100.550353, 6.487966],
          [100.551796, 6.489281],
          [100.552414, 6.489973],
          [100.552527, 6.49025],
          [100.552583, 6.490544],
          [100.552639, 6.491271],
          [100.552808, 6.491444],
          [100.55322, 6.491651],
          [100.554551, 6.492015],
          [100.555263, 6.492257],
          [100.555825, 6.492568],
          [100.556987, 6.492776],
          [100.557774, 6.492672],
          [100.557999, 6.492689],
          [100.558336, 6.492897],
          [100.558805, 6.493312],
          [100.559161, 6.493503],
          [100.559648, 6.49371],
          [100.56021, 6.493831],
          [100.560285, 6.493901],
          [100.560435, 6.495025],
          [100.560548, 6.495302],
          [100.560791, 6.495613],
          [100.561185, 6.495821],
          [100.561616, 6.495925],
          [100.562122, 6.495976],
          [100.562516, 6.495959],
          [100.562965, 6.495803],
          [100.563471, 6.495475],
          [100.563809, 6.495094],
          [100.563921, 6.4948],
          [100.564015, 6.494264],
          [100.564184, 6.49371],
          [100.564502, 6.493156],
          [100.565064, 6.49198],
          [100.565383, 6.491582],
          [100.565552, 6.491288],
          [100.565739, 6.490752],
          [100.565908, 6.490631],
          [100.566564, 6.490406],
          [100.567013, 6.490163],
          [100.567238, 6.489921],
          [100.567501, 6.489541],
          [100.567782, 6.489039],
          [100.568625, 6.488139],
          [100.568962, 6.487949],
          [100.569768, 6.487707],
          [100.570087, 6.487551],
          [100.570406, 6.487309],
          [100.571061, 6.486461],
          [100.571118, 6.485457],
          [100.571736, 6.485059],
          [100.57183, 6.484869],
          [100.571905, 6.484471],
          [100.572055, 6.484229],
          [100.572561, 6.48371],
          [100.57301, 6.482706],
          [100.57316, 6.482603],
          [100.573479, 6.482533],
          [100.573835, 6.482585],
          [100.574397, 6.482776],
          [100.574547, 6.482758],
          [100.574791, 6.482135],
          [100.574978, 6.48198],
          [100.575672, 6.481824],
          [100.576721, 6.48172],
          [100.577227, 6.481495],
          [100.579064, 6.480405],
          [100.579569, 6.48018],
          [100.5815, 6.479713],
          [100.581912, 6.479557],
          [100.582306, 6.47928],
          [100.582755, 6.47871],
          [100.583111, 6.478623],
          [100.583336, 6.47864],
          [100.583692, 6.478485],
          [100.584067, 6.47826],
          [100.584536, 6.477862],
          [100.584948, 6.477706],
          [100.585285, 6.477706],
          [100.586278, 6.478087],
          [100.58729, 6.478381],
          [100.588134, 6.478485],
          [100.58834, 6.478606],
          [100.588865, 6.479107],
          [100.589164, 6.479177],
          [100.589483, 6.479159],
          [100.590101, 6.4789],
          [100.590289, 6.478675],
          [100.59042, 6.478433],
          [100.590589, 6.478329],
          [100.590907, 6.478312],
          [100.591432, 6.47838],
          [100.592144, 6.478311],
          [100.59295, 6.477965],
          [100.594431, 6.477619],
          [100.595199, 6.477377],
          [100.595649, 6.477117],
          [100.595855, 6.476944],
          [100.596155, 6.476097],
          [100.596361, 6.475716],
          [100.596567, 6.475509],
          [100.596829, 6.475439],
          [100.59726, 6.475405],
          [100.597617, 6.475284],
          [100.598347, 6.474938],
          [100.598497, 6.474782],
          [100.599078, 6.473432],
          [100.599322, 6.473034],
          [100.599847, 6.472342],
          [100.600109, 6.472083],
          [100.600521, 6.471443],
          [100.601309, 6.47063],
          [100.601346, 6.470457],
          [100.601327, 6.469955],
          [100.601384, 6.469695],
          [100.601552, 6.469505],
          [100.602039, 6.469194],
          [100.602096, 6.469003],
          [100.602077, 6.468519],
          [100.602114, 6.468415],
          [100.6022248, 6.4683766],
          [100.602264, 6.468363],
          [100.602658, 6.468519],
          [100.60337, 6.468709],
          [100.60397, 6.469211],
          [100.604045, 6.469384],
          [100.604063, 6.469834],
          [100.604157, 6.470059],
          [100.604326, 6.470214],
          [100.604382, 6.47037],
          [100.604363, 6.470509],
          [100.604251, 6.470664],
          [100.603989, 6.470889],
          [100.603989, 6.471045],
          [100.604157, 6.471426],
          [100.604551, 6.471772],
          [100.604813, 6.471823],
          [100.605132, 6.471806],
          [100.605507, 6.471702],
          [100.6059, 6.47165],
          [100.606294, 6.471668],
          [100.606668, 6.471754],
          [100.607381, 6.472014],
          [100.60783, 6.4721],
          [100.608093, 6.472083],
          [100.608261, 6.471893],
          [100.608636, 6.471062],
          [100.608842, 6.470716],
          [100.608974, 6.46973],
          [100.609049, 6.469488],
          [100.609199, 6.469211],
          [100.609761, 6.468986],
          [100.610098, 6.468796],
          [100.610735, 6.468138],
          [100.610941, 6.467792],
          [100.611709, 6.466944],
          [100.612084, 6.466685],
          [100.612646, 6.466546],
          [100.61364, 6.466079],
          [100.613827, 6.465837],
          [100.613902, 6.464972],
          [100.613808, 6.463864],
          [100.613808, 6.462774],
          [100.613846, 6.462394],
          [100.614014, 6.462134],
          [100.614352, 6.461823],
          [100.614727, 6.461563],
          [100.615514, 6.461131],
          [100.616732, 6.460577],
          [100.617444, 6.460318],
          [100.617838, 6.459833],
          [100.6181, 6.459729],
          [100.618363, 6.459712],
          [100.619056, 6.459816],
          [100.620105, 6.459764],
          [100.620574, 6.459608],
          [100.621698, 6.459055],
          [100.621942, 6.459003],
          [100.622579, 6.459072],
          [100.622991, 6.459072],
          [100.623385, 6.458985],
          [100.62584, 6.457896],
          [100.626252, 6.457774],
          [100.627133, 6.457896],
          [100.627676, 6.458017],
          [100.628201, 6.458034],
          [100.629494, 6.457999],
          [100.629794, 6.457947],
          [100.63015, 6.457809],
          [100.630338, 6.457792],
          [100.630544, 6.457844],
          [100.630956, 6.458138],
          [100.631668, 6.458484],
          [100.631856, 6.458484],
          [100.632118, 6.458363],
          [100.634292, 6.45812],
          [100.635154, 6.457965],
          [100.635754, 6.457965],
          [100.636391, 6.458103],
          [100.637178, 6.458172],
          [100.638752, 6.458207],
          [100.638996, 6.458086],
          [100.639108, 6.457965],
          [100.639389, 6.457913],
          [100.639652, 6.457965],
          [100.63997, 6.457947],
          [100.640326, 6.457878],
          [100.640645, 6.457878],
          [100.641001, 6.457965],
          [100.642482, 6.458536],
          [100.642838, 6.45857],
          [100.643587, 6.458536],
          [100.645049, 6.458657],
          [100.645705, 6.458657],
          [100.646042, 6.458553],
          [100.646324, 6.458397],
          [100.646511, 6.458172],
          [100.646586, 6.456979],
          [100.646792, 6.456252],
          [100.646736, 6.456027],
          [100.64653, 6.455612],
          [100.646024, 6.455006],
          [100.645986, 6.454851],
          [100.646005, 6.454574],
          [100.646099, 6.45421],
          [100.646099, 6.453709],
          [100.645986, 6.453311],
          [100.644431, 6.451304],
          [100.644056, 6.450958],
          [100.642688, 6.450438],
          [100.642575, 6.450231],
          [100.642557, 6.450006],
          [100.642688, 6.449764],
          [100.643363, 6.448916],
          [100.643606, 6.448899],
          [100.644524, 6.449002],
          [100.645105, 6.448899],
          [100.645649, 6.448639],
          [100.645818, 6.448449],
          [100.646061, 6.448051],
          [100.64608, 6.44786],
          [100.645893, 6.446995],
          [100.645968, 6.446788],
          [100.646249, 6.446494],
          [100.646567, 6.446321],
          [100.646923, 6.446286],
          [100.647561, 6.446321],
          [100.649022, 6.446528],
          [100.649341, 6.446424],
          [100.649903, 6.446096],
          [100.65039, 6.44575],
          [100.650859, 6.445161],
          [100.651102, 6.444763],
          [100.65129, 6.44421],
          [100.651309, 6.443846],
          [100.651271, 6.443431],
          [100.651365, 6.44312],
          [100.651533, 6.442843],
          [100.651796, 6.442635],
          [100.652058, 6.442584],
          [100.652583, 6.442584],
          [100.652939, 6.442757],
          [100.653688, 6.443276],
          [100.654326, 6.443847],
          [100.656462, 6.445179],
          [100.656668, 6.445438],
          [100.656818, 6.445698],
          [100.656931, 6.446027],
          [100.65723, 6.446477],
          [100.65753, 6.446892],
          [100.658167, 6.447618],
          [100.658448, 6.447895],
          [100.659161, 6.448293],
          [100.65976, 6.448899],
          [100.660547, 6.449401],
          [100.660998, 6.449764],
          [100.661204, 6.449972],
          [100.661372, 6.450283],
          [100.661803, 6.45075],
          [100.662141, 6.450992],
          [100.662366, 6.451027],
          [100.663659, 6.450698],
          [100.664165, 6.450508],
          [100.664446, 6.450491],
          [100.664633, 6.450612],
          [100.665102, 6.451079],
          [100.665514, 6.451338],
          [100.665851, 6.451477],
          [100.666189, 6.451425],
          [100.666451, 6.451183],
          [100.666901, 6.451131],
          [100.667332, 6.451165],
          [100.668756, 6.45146],
          [100.669056, 6.451684],
          [100.669449, 6.451857],
          [100.669787, 6.451875],
          [100.670162, 6.451736],
          [100.670667, 6.451633],
          [100.671117, 6.451667],
          [100.672466, 6.451892],
          [100.67286, 6.452031],
          [100.67316, 6.452221],
          [100.673703, 6.452688],
          [100.674434, 6.452792],
          [100.674903, 6.452982],
          [100.675652, 6.453069],
          [100.676008, 6.453224],
          [100.676383, 6.453259],
          [100.677152, 6.45319],
          [100.677601, 6.453259],
          [100.677958, 6.45338],
          [100.678164, 6.453657],
          [100.678576, 6.453968],
          [100.679101, 6.454072],
          [100.679326, 6.454038],
          [100.680132, 6.453536],
          [100.6806, 6.45338],
          [100.682268, 6.453224],
          [100.68283, 6.453207],
          [100.68343, 6.453259],
          [100.684404, 6.453501],
          [100.684742, 6.453657],
          [100.684892, 6.453847],
          [100.684967, 6.454141],
          [100.684967, 6.454384],
          [100.684648, 6.455266],
          [100.683898, 6.456511],
          [100.683917, 6.456702],
          [100.684086, 6.456979],
          [100.684461, 6.457446],
          [100.684517, 6.457861],
          [100.684461, 6.458276],
          [100.684554, 6.458795],
          [100.684686, 6.459107],
          [100.684761, 6.459609],
          [100.684761, 6.459972],
          [100.684854, 6.460266],
          [100.685042, 6.460456],
          [100.685342, 6.460664],
          [100.68551, 6.460872],
          [100.685641, 6.461148],
          [100.685848, 6.461373],
          [100.686503, 6.461668],
          [100.686578, 6.461892],
          [100.686691, 6.462533],
          [100.686578, 6.46274],
          [100.686279, 6.463086],
          [100.68611, 6.463207],
          [100.685923, 6.463259],
          [100.685735, 6.463536],
          [100.685716, 6.463761],
          [100.685829, 6.463969],
          [100.686391, 6.464712],
          [100.686466, 6.464903],
          [100.686672, 6.465145],
          [100.686935, 6.465231],
          [100.687459, 6.465283],
          [100.68834, 6.465093],
          [100.688471, 6.464989],
          [100.688696, 6.464903],
          [100.688996, 6.464868],
          [100.68984, 6.465151],
          [100.6902763, 6.4650778],
          [100.6904177, 6.4650738],
          [100.6904466, 6.4650501],
          [100.6904832, 6.4650466],
          [100.69051, 6.4650662],
          [100.6905093, 6.4650928],
          [100.6905253, 6.4651134],
          [100.6906608, 6.465155],
          [100.6907584, 6.4651431],
          [100.690874, 6.4651589],
          [100.6911116, 6.4652203],
          [100.6912969, 6.4652164],
          [100.6914822, 6.4652382],
          [100.6917292, 6.4652599],
          [100.692028, 6.4653371],
          [100.6920878, 6.4653648],
          [100.6921994, 6.465347],
          [100.6924684, 6.465353],
          [100.6925998, 6.4653827],
          [100.6927672, 6.4652263],
          [100.692988, 6.465076],
          [100.693663, 6.464453],
          [100.6937514, 6.4642998],
          [100.694118, 6.464672],
          [100.694379, 6.4647551],
          [100.694636, 6.4648719],
          [100.6949149, 6.4649412],
          [100.6951161, 6.464963],
          [100.695312, 6.465093],
          [100.6955027, 6.4653173],
          [100.6955066, 6.4653827],
          [100.695443, 6.465612],
          [100.6955943, 6.4657093],
          [100.6958971, 6.4658261],
          [100.6962079, 6.4658598],
          [100.6967319, 6.4658499],
          [100.6968495, 6.4658677],
          [100.6970746, 6.465931],
          [100.6975548, 6.4660538],
          [100.6976344, 6.4661013],
          [100.6975886, 6.4666873],
          [100.6976344, 6.466814],
          [100.6976663, 6.4669703],
          [100.6975966, 6.4670852],
          [100.6975129, 6.4672138],
          [100.6974651, 6.4673465],
          [100.6973874, 6.4676771],
          [100.6973276, 6.4680492],
          [100.6973276, 6.4683759],
          [100.6973834, 6.4685719],
          [100.6974711, 6.4687679],
          [100.697499, 6.4688411],
          [100.6974751, 6.4689599],
          [100.6974073, 6.4690608],
          [100.6973495, 6.4691895],
          [100.6971463, 6.46973],
          [100.6972997, 6.4702704],
          [100.6974213, 6.4705416],
          [100.6976603, 6.4707772],
          [100.6978357, 6.4709197],
          [100.6981345, 6.4711216],
          [100.6985828, 6.4714136],
          [100.6986177, 6.4714255],
          [100.6986794, 6.47138],
          [100.6986565, 6.4713295],
          [100.6986475, 6.4712879],
          [100.6986605, 6.4712662],
          [100.6987003, 6.4712959],
          [100.6987521, 6.4713255],
          [100.6989155, 6.4712345],
          [100.6989265, 6.4711761],
          [100.6989803, 6.4711771],
          [100.6991456, 6.4710108],
          [100.6992104, 6.4709623],
          [100.6992642, 6.4708772],
          [100.699313, 6.4708108],
          [100.6994584, 6.4706802],
          [100.6996059, 6.4706359],
          [100.6998961, 6.4705841],
          [100.7000961, 6.4705547],
          [100.7002427, 6.4705029],
          [100.7003807, 6.4705435],
          [100.7007462, 6.4705633],
          [100.7010331, 6.4705415],
          [100.7012244, 6.4705277],
          [100.7013678, 6.4704703],
          [100.7017344, 6.4702386],
          [100.7028296, 6.469897],
          [100.7028946, 6.4698352],
          [100.7029721, 6.4698301],
          [100.7030698, 6.4698404],
          [100.7032688, 6.4698432],
          [100.7034165, 6.4697675],
          [100.7035242, 6.4697738],
          [100.7035924, 6.4698418],
          [100.7036914, 6.4699209],
          [100.7038997, 6.4700614],
          [100.7043706, 6.4703911],
          [100.7051553, 6.4708047],
          [100.7055895, 6.4709973],
          [100.705675, 6.470958],
          [100.705919, 6.470785],
          [100.706612, 6.470526],
          [100.707174, 6.470387],
          [100.707699, 6.470353],
          [100.7083941, 6.4704325],
          [100.708842, 6.470595],
          [100.709385, 6.470872],
          [100.709573, 6.471149],
          [100.709704, 6.471495],
          [100.70976, 6.471823],
          [100.709873, 6.472844],
          [100.709835, 6.473104],
          [100.709554, 6.473744],
          [100.709535, 6.474038],
          [100.709592, 6.47428],
          [100.70994, 6.4747238],
          [100.7100843, 6.4749206],
          [100.7101202, 6.4750698],
          [100.7100828, 6.475361],
          [100.7100502, 6.4755146],
          [100.7100459, 6.4759684],
          [100.7101823, 6.4762948],
          [100.7102807, 6.4764332],
          [100.7102816, 6.4765137],
          [100.7102827, 6.4766119],
          [100.7104646, 6.4767959],
          [100.710679, 6.477447],
          [100.71096, 6.477948],
          [100.711054, 6.47826],
          [100.711129, 6.479419],
          [100.71126, 6.479696],
          [100.711766, 6.480249],
          [100.711897, 6.480595],
          [100.712216, 6.481772],
          [100.712384, 6.481927],
          [100.712703, 6.482066],
          [100.714221, 6.482291],
          [100.714408, 6.482447],
          [100.714615, 6.483069],
          [100.714839, 6.483312],
          [100.715158, 6.483554],
          [100.715776, 6.483848],
          [100.716189, 6.483865],
          [100.71647, 6.484055],
          [100.717875, 6.485232],
          [100.718269, 6.485405],
          [100.718606, 6.48544],
          [100.718887, 6.485544],
          [100.719524, 6.486357],
          [100.719674, 6.486616],
          [100.71958, 6.487187],
          [100.719599, 6.48743],
          [100.719955, 6.487481],
          [100.720068, 6.487689],
          [100.720049, 6.488208],
          [100.72018, 6.488952],
          [100.720161, 6.490319],
          [100.720236, 6.490838],
          [100.720461, 6.491271],
          [100.721548, 6.492118],
          [100.721605, 6.492361],
          [100.721923, 6.492984],
          [100.722841, 6.494437],
          [100.723441, 6.495216],
          [100.723704, 6.495354],
          [100.72391, 6.495337],
          [100.72421, 6.495267],
          [100.724697, 6.49506],
          [100.725109, 6.494973],
          [100.72554, 6.494973],
          [100.725952, 6.495112],
          [100.726533, 6.495181],
          [100.726946, 6.495077],
          [100.727208, 6.494921],
          [100.727714, 6.494766],
          [100.729195, 6.495025],
          [100.729326, 6.495146],
          [100.729382, 6.495319],
          [100.72942, 6.495769],
          [100.729963, 6.496496],
          [100.730038, 6.496703],
          [100.730169, 6.496824],
          [100.73075, 6.497118],
          [100.730825, 6.49724],
          [100.730844, 6.497378],
          [100.73075, 6.497482],
          [100.7306, 6.497862],
          [100.730694, 6.498641],
          [100.730656, 6.49897],
          [100.730263, 6.499818],
          [100.729888, 6.500319],
          [100.729569, 6.500492],
          [100.7294, 6.500631],
          [100.729325, 6.500769],
          [100.7294, 6.500873],
          [100.729625, 6.501029],
          [100.729906, 6.501132],
          [100.730431, 6.501219],
          [100.731087, 6.501479],
          [100.731312, 6.501669],
          [100.731387, 6.50179],
          [100.731387, 6.501946],
          [100.731593, 6.502344],
          [100.731612, 6.502551],
          [100.731537, 6.502811],
          [100.73105, 6.503278],
          [100.7309, 6.503485],
          [100.730431, 6.504783],
          [100.730337, 6.505129],
          [100.730356, 6.505354],
          [100.730562, 6.505458],
          [100.731181, 6.505458],
          [100.731762, 6.505613],
          [100.732511, 6.505458],
          [100.732811, 6.50544],
          [100.73298, 6.505475],
          [100.733167, 6.505579],
          [100.73328, 6.505769],
          [100.733392, 6.506703],
          [100.733655, 6.507361],
          [100.733692, 6.507655],
          [100.733673, 6.507914],
          [100.733748, 6.508105],
          [100.733992, 6.508312],
          [100.734086, 6.508606],
          [100.734217, 6.509177],
          [100.734311, 6.510094],
          [100.734385, 6.510302],
          [100.73461, 6.510406],
          [100.734966, 6.510423],
          [100.735491, 6.510112],
          [100.735941, 6.509904],
          [100.736297, 6.509904],
          [100.736972, 6.509991],
          [100.738302, 6.510388],
          [100.73849, 6.510423],
          [100.73864, 6.510354],
          [100.738827, 6.509523],
          [100.738771, 6.508918],
          [100.738808, 6.508728],
          [100.739015, 6.508468],
          [100.739595, 6.507914],
          [100.739727, 6.507672],
          [100.739877, 6.507534],
          [100.740495, 6.507465],
          [100.741039, 6.507049],
          [100.741114, 6.506911],
          [100.741076, 6.506409],
          [100.74117, 6.506132],
          [100.741245, 6.505492],
          [100.740907, 6.503797],
          [100.740907, 6.503451],
          [100.741001, 6.503312],
          [100.741301, 6.503036],
          [100.741395, 6.502863],
          [100.741545, 6.502344],
          [100.741582, 6.501998],
          [100.741882, 6.501271],
          [100.742051, 6.501132],
          [100.743138, 6.50089],
          [100.7434, 6.500786],
          [100.743569, 6.500613],
          [100.743625, 6.500371],
          [100.743587, 6.50006],
          [100.743625, 6.499575],
          [100.74355, 6.499125],
          [100.743587, 6.498329],
          [100.743513, 6.498105],
          [100.743363, 6.497914],
          [100.742332, 6.497395],
          [100.742051, 6.497188],
          [100.741976, 6.496928],
          [100.741845, 6.496236],
          [100.742201, 6.495648],
          [100.742238, 6.49544],
          [100.742201, 6.495163],
          [100.741845, 6.494835],
          [100.741507, 6.494385],
          [100.740945, 6.493866],
          [100.740364, 6.492949],
          [100.740083, 6.492637],
          [100.739371, 6.492066],
          [100.739183, 6.491824],
          [100.73909, 6.491599],
          [100.739071, 6.49134],
          [100.739221, 6.490942],
          [100.739127, 6.490751],
          [100.739127, 6.490492],
          [100.739258, 6.489713],
          [100.739183, 6.489108],
          [100.73924, 6.488866],
          [100.739821, 6.488433],
          [100.740045, 6.488173],
          [100.740102, 6.487862],
          [100.73997, 6.486911],
          [100.739952, 6.486651],
          [100.739989, 6.486478],
          [100.740158, 6.486322],
          [100.740402, 6.485994],
          [100.741001, 6.485423],
          [100.741039, 6.485198],
          [100.741151, 6.485007],
          [100.742201, 6.484402],
          [100.742519, 6.484194],
          [100.742688, 6.484004],
          [100.742744, 6.483727],
          [100.742763, 6.483277],
          [100.74265, 6.482568],
          [100.742669, 6.48236],
          [100.742725, 6.482152],
          [100.743025, 6.481841],
          [100.743081, 6.48172],
          [100.743194, 6.481045],
          [100.743494, 6.480215],
          [100.743662, 6.479903],
          [100.743737, 6.479592],
          [100.743681, 6.479246],
          [100.743306, 6.478588],
          [100.743213, 6.478191],
          [100.743213, 6.477637],
          [100.743306, 6.477187],
          [100.743513, 6.476997],
          [100.743962, 6.476443],
          [100.744037, 6.476183],
          [100.743962, 6.475854],
          [100.743625, 6.47492],
          [100.743644, 6.474591],
          [100.743587, 6.47428],
          [100.743306, 6.473709],
          [100.743213, 6.47338],
          [100.743231, 6.473138],
          [100.743306, 6.472913],
          [100.743681, 6.472463],
          [100.743887, 6.472152],
          [100.743944, 6.471961],
          [100.743925, 6.471667],
          [100.7437, 6.471114],
          [100.7437, 6.470871],
          [100.743925, 6.47037],
          [100.74445, 6.469833],
          [100.744693, 6.469505],
          [100.745049, 6.468831],
          [100.745612, 6.468363],
          [100.746043, 6.468225],
          [100.746192, 6.468069],
          [100.746586, 6.46736],
          [100.746773, 6.46646],
          [100.746867, 6.466356],
          [100.747036, 6.466304],
          [100.747223, 6.466183],
          [100.747261, 6.466097],
          [100.747298, 6.465785],
          [100.747261, 6.464816],
          [100.747317, 6.464574],
          [100.747617, 6.464194],
          [100.747823, 6.464021],
          [100.748123, 6.463553],
          [100.748685, 6.462931],
          [100.749566, 6.462187],
          [100.749959, 6.462048],
          [100.750174, 6.461804],
          [100.75035, 6.461587],
          [100.750409, 6.461262],
          [100.750526, 6.460992],
          [100.750995, 6.460369],
          [100.751376, 6.460018],
          [100.751699, 6.459801],
          [100.752109, 6.459287],
          [100.752373, 6.459043],
          [100.752754, 6.458367],
          [100.753164, 6.457799],
          [100.753545, 6.457474],
          [100.754366, 6.457122],
          [100.754806, 6.457095],
          [100.755275, 6.457149],
          [100.755832, 6.457474],
          [100.756154, 6.457474],
          [100.756359, 6.457203],
          [100.756975, 6.456608],
          [100.757356, 6.456175],
          [100.75759, 6.455823],
          [100.757737, 6.455742],
          [100.757854, 6.455742],
          [100.758001, 6.455877],
          [100.758206, 6.456229],
          [100.758734, 6.456689],
          [100.758851, 6.456824],
          [100.759027, 6.457203],
          [100.759437, 6.45723],
          [100.759818, 6.457122],
          [100.760229, 6.45677],
          [100.761782, 6.456094],
          [100.762134, 6.456013],
          [100.762749, 6.456121],
          [100.763072, 6.456121],
          [100.763512, 6.455986],
          [100.763893, 6.455796],
          [100.764215, 6.455769],
          [100.764625, 6.455769],
          [100.765358, 6.45585],
          [100.765446, 6.455769],
          [100.765534, 6.455525],
          [100.765974, 6.455174],
          [100.767029, 6.455038],
          [100.767293, 6.455038],
          [100.76785, 6.455255],
          [100.768964, 6.455309],
          [100.769433, 6.455525],
          [100.769697, 6.455715],
          [100.770078, 6.456229],
          [100.770195, 6.456473],
          [100.770341, 6.456527],
          [100.770752, 6.456527],
          [100.770986, 6.456581],
          [100.771631, 6.456824],
          [100.772042, 6.457176],
          [100.772276, 6.457528],
          [100.772599, 6.45769],
          [100.772804, 6.457907],
          [100.773595, 6.459043],
          [100.774181, 6.45972],
          [100.774914, 6.460207],
          [100.776263, 6.462318],
          [100.776292, 6.46267],
          [100.77638, 6.462913],
          [100.776497, 6.463048],
          [100.776614, 6.462994],
          [100.776702, 6.462751],
          [100.777259, 6.461993],
          [100.77808, 6.461506],
          [100.778315, 6.461317],
          [100.778989, 6.45972],
          [100.779223, 6.459422],
          [100.78022, 6.458421],
          [100.780542, 6.45769],
          [100.780777, 6.457014],
          [100.781246, 6.457068],
          [100.78148, 6.457041],
          [100.782389, 6.456418],
          [100.78236, 6.456283],
          [100.782096, 6.455877],
          [100.781949, 6.455498],
          [100.781715, 6.455147],
          [100.781715, 6.454849],
          [100.781773, 6.454524],
          [100.78192, 6.454416],
          [100.782184, 6.454335],
          [100.782535, 6.454281],
          [100.784206, 6.453848],
          [100.785174, 6.453875],
          [100.785672, 6.454281],
          [100.786082, 6.454497],
          [100.78705, 6.454632],
          [100.787284, 6.454497],
          [100.787519, 6.454308],
          [100.787694, 6.454064],
          [100.787694, 6.453821],
          [100.787812, 6.453577],
          [100.788457, 6.453117],
          [100.788603, 6.452955],
          [100.788955, 6.452846],
          [100.789043, 6.452711],
          [100.789131, 6.452008],
          [100.789219, 6.451872],
          [100.789277, 6.451629],
          [100.789248, 6.450303],
          [100.789805, 6.44968],
          [100.790303, 6.449383],
          [100.791036, 6.449166],
          [100.791476, 6.448679],
          [100.791534, 6.448327],
          [100.79174, 6.448219],
          [100.792062, 6.448192],
          [100.792502, 6.448057],
          [100.792648, 6.447894],
          [100.792854, 6.447813],
          [100.793147, 6.447813],
          [100.79385, 6.448003],
          [100.794114, 6.447975],
          [100.794436, 6.44784],
          [100.7947, 6.447488],
          [100.794935, 6.447407],
          [100.795316, 6.447542],
          [100.795873, 6.447624],
          [100.796693, 6.447434],
          [100.797309, 6.447083],
          [100.797572, 6.447029],
          [100.798452, 6.447029],
          [100.798774, 6.446948],
          [100.799067, 6.446623],
          [100.799302, 6.446623],
          [100.800621, 6.446352],
          [100.801383, 6.446352],
          [100.801588, 6.446271],
          [100.801911, 6.445838],
          [100.802087, 6.445757],
          [100.802262, 6.445757],
          [100.802643, 6.445973],
          [100.802966, 6.446055],
          [100.80323, 6.446082],
          [100.803523, 6.446001],
          [100.80493, 6.445162],
          [100.80578, 6.445053],
          [100.806219, 6.444891],
          [100.806923, 6.444485],
          [100.807245, 6.444215],
          [100.807451, 6.443917],
          [100.807714, 6.443809],
          [100.808535, 6.443998],
          [100.809942, 6.444485],
          [100.810235, 6.444539],
          [100.810528, 6.444512],
          [100.810792, 6.44435],
          [100.811642, 6.443646],
          [100.81258, 6.442185],
          [100.812785, 6.441319],
          [100.812785, 6.441102],
          [100.812668, 6.44094],
          [100.812375, 6.440697],
          [100.812316, 6.440534],
          [100.812316, 6.440237],
          [100.812404, 6.439993],
          [100.813342, 6.438748],
          [100.813401, 6.438451],
          [100.813372, 6.437774],
          [100.813254, 6.437152],
          [100.812961, 6.436556],
          [100.812991, 6.436313],
          [100.813078, 6.436096],
          [100.813372, 6.435799],
          [100.813723, 6.435609],
          [100.814134, 6.435474],
          [100.814456, 6.435311],
          [100.814749, 6.435041],
          [100.815042, 6.434391],
          [100.815423, 6.432957],
          [100.815306, 6.432714],
          [100.814984, 6.432308],
          [100.814603, 6.432064],
          [100.814397, 6.431848],
          [100.814251, 6.431388],
          [100.814104, 6.430684],
          [100.81428, 6.43044],
          [100.814603, 6.430197],
          [100.814691, 6.430007],
          [100.81472, 6.428817],
          [100.814837, 6.428411],
          [100.815892, 6.427355],
          [100.816215, 6.427166],
          [100.816742, 6.427058],
          [100.816772, 6.426841],
          [100.816742, 6.426544],
          [100.816537, 6.425732],
          [100.816449, 6.424676],
          [100.816332, 6.424271],
          [100.816098, 6.423865],
          [100.81601, 6.423621],
          [100.81601, 6.423323],
          [100.816098, 6.423134],
          [100.816479, 6.422701],
          [100.817592, 6.422079],
          [100.81812, 6.421646],
          [100.818589, 6.421402],
          [100.818765, 6.421213],
          [100.818911, 6.420834],
          [100.819, 6.420347],
          [100.819176, 6.419968],
          [100.819469, 6.419589],
          [100.819703, 6.4194],
          [100.81982, 6.419075],
          [100.819938, 6.418453],
          [100.820612, 6.417587],
          [100.820876, 6.416829],
          [100.821198, 6.416288],
          [100.821374, 6.415422],
          [100.821814, 6.414718],
          [100.821872, 6.414096],
          [100.821784, 6.412526],
          [100.821843, 6.411417],
          [100.822019, 6.409982],
          [100.822195, 6.409712],
          [100.823221, 6.408602],
          [100.823367, 6.408196],
          [100.82366, 6.407655],
          [100.823836, 6.407006],
          [100.823924, 6.40687],
          [100.823865, 6.406654],
          [100.823895, 6.406113],
          [100.8241, 6.405003],
          [100.823983, 6.404056],
          [100.823865, 6.403758],
          [100.823103, 6.403271],
          [100.822605, 6.402892],
          [100.822371, 6.402649],
          [100.821696, 6.402162],
          [100.82152, 6.401918],
          [100.821433, 6.401702],
          [100.821403, 6.401404],
          [100.82155, 6.400836],
          [100.821491, 6.399753],
          [100.821257, 6.399158],
          [100.821462, 6.398562],
          [100.821491, 6.398211],
          [100.821374, 6.397967],
          [100.821374, 6.397696],
          [100.82155, 6.397426],
          [100.821902, 6.395965],
          [100.822019, 6.395288],
          [100.822253, 6.395017],
          [100.822195, 6.393719],
          [100.822019, 6.39269],
          [100.821814, 6.392176],
          [100.821784, 6.391635],
          [100.821814, 6.391418],
          [100.822077, 6.391175],
          [100.822283, 6.390904],
          [100.822634, 6.38993],
          [100.822693, 6.387359],
          [100.822576, 6.386628],
          [100.822546, 6.386141],
          [100.822605, 6.385817],
          [100.82281, 6.385546],
          [100.823103, 6.38533],
          [100.823572, 6.385113],
          [100.823983, 6.38487],
          [100.824217, 6.38468],
          [100.824276, 6.384409],
          [100.824246, 6.382136],
          [100.824071, 6.380378],
          [100.823572, 6.379809],
          [100.823221, 6.379187],
          [100.823015, 6.378727],
          [100.822957, 6.378375],
          [100.822927, 6.377509],
          [100.82284, 6.377185],
          [100.822605, 6.376752],
          [100.822224, 6.376508],
          [100.822077, 6.376319],
          [100.821755, 6.375155],
          [100.821169, 6.374343],
          [100.821139, 6.37391],
          [100.821257, 6.372584],
          [100.821462, 6.372151],
          [100.822136, 6.370988],
          [100.822165, 6.370717],
          [100.822077, 6.370446],
          [100.821784, 6.370149],
          [100.821345, 6.369878],
          [100.820905, 6.369743],
          [100.820758, 6.369445],
          [100.8207, 6.368876],
          [100.820729, 6.368362],
          [100.821491, 6.366928],
          [100.82196, 6.366468],
          [100.822107, 6.366008],
          [100.822253, 6.365304],
          [100.822224, 6.36479],
          [100.822136, 6.364249],
          [100.822195, 6.363897],
          [100.822341, 6.363247],
          [100.822605, 6.362869],
          [100.822664, 6.362571],
          [100.822488, 6.362003],
          [100.822429, 6.361624],
          [100.822517, 6.361326],
          [100.822546, 6.360623],
          [100.822341, 6.360271],
          [100.821931, 6.359324],
          [100.821784, 6.358864],
          [100.821814, 6.357971],
          [100.821755, 6.357456],
          [100.821755, 6.356834],
          [100.821814, 6.356482],
          [100.822547, 6.35521],
          [100.822781, 6.35521],
          [100.823045, 6.355345],
          [100.823397, 6.355372],
          [100.824012, 6.355291],
          [100.824276, 6.3554],
          [100.824657, 6.355481],
          [100.824862, 6.355372],
          [100.824979, 6.354967],
          [100.825126, 6.354804],
          [100.825536, 6.354479],
          [100.826093, 6.354128],
          [100.826298, 6.353857],
          [100.826592, 6.353316],
          [100.827178, 6.352423],
          [100.827764, 6.352288],
          [100.827911, 6.352098],
          [100.828086, 6.351395],
          [100.828262, 6.350962],
          [100.828497, 6.350637],
          [100.828526, 6.35042],
          [100.828292, 6.349825],
          [100.828233, 6.3495],
          [100.828526, 6.34812],
          [100.828731, 6.347444],
          [100.829142, 6.347065],
          [100.829581, 6.346524],
          [100.829669, 6.346469],
          [100.829992, 6.346442],
          [100.83005, 6.346605],
          [100.830256, 6.346659],
          [100.830519, 6.346605],
          [100.830783, 6.346388],
          [100.831428, 6.345549],
          [100.831868, 6.344386],
          [100.832484, 6.343168],
          [100.833011, 6.342437],
          [100.833685, 6.342032],
          [100.83433, 6.341003],
          [100.835034, 6.339569],
          [100.835591, 6.338947],
          [100.835766, 6.338622],
          [100.835972, 6.33781],
          [100.836001, 6.337323],
          [100.836235, 6.336484],
          [100.836235, 6.336213],
          [100.836382, 6.335916],
          [100.836734, 6.335429],
          [100.837115, 6.335131],
          [100.837467, 6.334752],
          [100.837936, 6.333832],
          [100.838141, 6.332641],
          [100.838434, 6.332019],
          [100.838463, 6.331559],
          [100.838434, 6.331072],
          [100.838551, 6.330774],
          [100.838991, 6.330368],
          [100.839255, 6.329962],
          [100.839284, 6.328962],
          [100.839372, 6.328583],
          [100.84028, 6.327934],
          [100.840456, 6.327717],
          [100.840691, 6.32723],
          [100.840456, 6.326418],
          [100.840368, 6.325904],
          [100.840193, 6.325525],
          [100.840163, 6.325282],
          [100.840603, 6.323928],
          [100.840456, 6.32355],
          [100.840134, 6.323225],
          [100.840193, 6.322981],
          [100.840398, 6.322602],
          [100.84072, 6.322197],
          [100.841482, 6.32198],
          [100.841951, 6.321791],
          [100.842215, 6.321574],
          [100.842625, 6.320952],
          [100.8433, 6.320411],
          [100.843827, 6.319842],
          [100.844267, 6.319626],
          [100.845058, 6.318489],
          [100.845527, 6.317894],
          [100.845674, 6.317515],
          [100.846407, 6.316893],
          [100.846758, 6.316162],
          [100.847579, 6.315594],
          [100.848077, 6.315404],
          [100.848341, 6.315458],
          [100.848634, 6.315431],
          [100.848869, 6.31535],
          [100.849133, 6.314646],
          [100.849133, 6.314295],
          [100.848927, 6.313997],
          [100.848869, 6.313699],
          [100.848898, 6.31332],
          [100.849162, 6.312752],
          [100.848986, 6.3124],
          [100.849309, 6.310208],
          [100.849484, 6.309802],
          [100.849865, 6.309315],
          [100.849983, 6.308991],
          [100.849924, 6.30872],
          [100.849719, 6.308558],
          [100.849309, 6.308666],
          [100.848781, 6.308936],
          [100.848341, 6.309261],
          [100.848019, 6.309424],
          [100.847521, 6.309559],
          [100.847052, 6.309505],
          [100.846641, 6.309369],
          [100.845117, 6.309234],
          [100.844677, 6.309045],
          [100.843065, 6.308152],
          [100.842772, 6.307935],
          [100.842391, 6.307096],
          [100.842127, 6.306799],
          [100.841922, 6.306636],
          [100.841834, 6.306339],
          [100.841775, 6.30577],
          [100.841775, 6.304147],
          [100.841951, 6.303687],
          [100.842039, 6.302631],
          [100.84201, 6.302361],
          [100.841687, 6.301846],
          [100.8414997, 6.3014153],
          [100.841512, 6.30117],
          [100.841541, 6.300764],
          [100.841306, 6.300412],
          [100.8411217, 6.3002416],
          [100.840749, 6.300088],
          [100.840398, 6.300142],
          [100.839958, 6.300358],
          [100.83943, 6.300548],
          [100.838991, 6.300548],
          [100.837936, 6.30025],
          [100.837642, 6.300115],
          [100.837027, 6.299546],
          [100.836822, 6.299222],
          [100.836617, 6.298356],
          [100.836353, 6.297679],
          [100.836323, 6.29749],
          [100.836118, 6.2973],
          [100.835679, 6.297084],
          [100.835356, 6.296813],
          [100.835151, 6.296543],
          [100.834975, 6.296083],
          [100.834916, 6.295433],
          [100.835004, 6.294946],
          [100.835239, 6.294513],
          [100.835503, 6.294242],
          [100.835503, 6.293972],
          [100.835444, 6.293755],
          [100.835473, 6.293485],
          [100.83562, 6.293349],
          [100.835825, 6.292862],
          [100.835942, 6.292673],
          [100.836499, 6.292456],
          [100.836529, 6.292348],
          [100.836441, 6.292132],
          [100.83647, 6.291753],
          [100.836646, 6.290968],
          [100.836529, 6.290616],
          [100.836177, 6.290183],
          [100.835884, 6.28994],
          [100.835532, 6.289777],
          [100.83518, 6.289453],
          [100.834916, 6.28929],
          [100.834741, 6.289101],
          [100.834711, 6.28883],
          [100.83477, 6.288533],
          [100.834946, 6.288316],
          [100.835327, 6.288127],
          [100.835532, 6.28791],
          [100.835766, 6.287504],
          [100.835268, 6.286232],
          [100.835239, 6.285718],
          [100.835356, 6.285475],
          [100.835591, 6.285312],
          [100.835854, 6.285231],
          [100.83606, 6.285069],
          [100.836499, 6.284122],
          [100.836646, 6.283364],
          [100.837115, 6.283256],
          [100.837467, 6.283012],
          [100.837965, 6.282796],
          [100.838522, 6.28239],
          [100.83861, 6.282173],
          [100.838551, 6.280928],
          [100.838463, 6.280577],
          [100.838141, 6.279927],
          [100.838111, 6.279738],
          [100.838258, 6.279494],
          [100.83861, 6.279332],
          [100.838844, 6.279142],
          [100.838961, 6.278818],
          [100.838991, 6.278412],
          [100.839137, 6.278195],
          [100.839225, 6.27787],
          [100.839108, 6.276653],
          [100.839284, 6.276301],
          [100.839489, 6.275624],
          [100.839841, 6.275272],
          [100.840134, 6.274894],
          [100.840251, 6.274596],
          [100.840749, 6.273865],
          [100.840867, 6.273189],
          [100.840837, 6.272945],
          [100.840867, 6.272485],
          [100.840955, 6.271917],
          [100.84116, 6.271538],
          [100.841306, 6.271376],
          [100.841775, 6.271159],
          [100.842127, 6.270834],
          [100.842625, 6.269914],
          [100.842801, 6.269211],
          [100.842567, 6.268507],
          [100.842508, 6.268182],
          [100.842508, 6.267804],
          [100.84242, 6.267533],
          [100.84286, 6.266829],
          [100.842977, 6.266559],
          [100.843065, 6.266045],
          [100.843006, 6.265557],
          [100.843124, 6.265368],
          [100.843593, 6.265179],
          [100.843681, 6.26507],
          [100.843739, 6.264881],
          [100.84371, 6.26461],
          [100.844062, 6.26388],
          [100.845146, 6.262933],
          [100.845205, 6.262635],
          [100.845117, 6.262418],
          [100.845088, 6.262201],
          [100.845146, 6.261958],
          [100.845117, 6.261796],
          [100.844912, 6.261579],
          [100.844472, 6.261308],
          [100.844238, 6.26093],
          [100.844267, 6.260767],
          [100.844824, 6.260253],
          [100.845176, 6.260064],
          [100.845527, 6.259712],
          [100.84585, 6.259144],
          [100.845703, 6.258575],
          [100.846114, 6.257899],
          [100.846201, 6.257628],
          [100.846407, 6.257412],
          [100.846436, 6.257168],
          [100.846114, 6.256681],
          [100.845586, 6.256546],
          [100.845322, 6.256383],
          [100.844443, 6.255274],
          [100.844267, 6.254841],
          [100.844267, 6.254624],
          [100.844648, 6.253677],
          [100.84456, 6.253055],
          [100.844179, 6.252649],
          [100.844091, 6.252459],
          [100.844091, 6.252216],
          [100.844179, 6.251891],
          [100.844355, 6.251621],
          [100.844619, 6.25135],
          [100.84497, 6.251079],
          [100.844912, 6.25089],
          [100.84456, 6.250701],
          [100.844091, 6.250105],
          [100.844091, 6.249835],
          [100.844443, 6.249483],
          [100.844648, 6.249212],
          [100.845557, 6.24859],
          [100.845762, 6.248319],
          [100.84585, 6.248049],
          [100.84585, 6.247697],
          [100.846113, 6.247075],
          [100.846465, 6.24694],
          [100.846582, 6.246777],
          [100.846758, 6.245857],
          [100.846875, 6.245776],
          [100.846963, 6.245641],
          [100.847608, 6.243341],
          [100.847696, 6.242421],
          [100.847608, 6.241582],
          [100.847637, 6.241365],
          [100.847755, 6.241203],
          [100.848048, 6.241041],
          [100.848341, 6.240743],
          [100.848546, 6.240337],
          [100.848781, 6.239687],
          [100.84922, 6.237901],
          [100.849367, 6.237523],
          [100.850188, 6.236575],
          [100.850774, 6.235601],
          [100.851038, 6.234302],
          [100.852357, 6.233166],
          [100.853324, 6.232137],
          [100.853676, 6.231894],
          [100.854614, 6.231732],
          [100.854995, 6.231786],
          [100.855229, 6.231921],
          [100.855669, 6.232056],
          [100.856431, 6.232219],
          [100.857574, 6.232543],
          [100.858014, 6.232814],
          [100.858659, 6.233328],
          [100.85904, 6.233788],
          [100.859832, 6.234979],
          [100.859919, 6.235222],
          [100.860183, 6.235466],
          [100.862821, 6.236819],
          [100.864609, 6.237441],
          [100.86546, 6.237658],
          [100.866398, 6.23801],
          [100.866925, 6.238416],
          [100.868127, 6.239011],
          [100.869856, 6.239769],
          [100.87012, 6.240039],
          [100.870355, 6.240418],
          [100.870443, 6.240635],
          [100.870091, 6.241203],
          [100.867013, 6.243232],
          [100.866573, 6.243638],
          [100.866134, 6.244207],
          [100.865929, 6.24464],
          [100.86587, 6.245154],
          [100.865899, 6.245614],
          [100.866104, 6.246155],
          [100.866573, 6.24675],
          [100.867101, 6.247319],
          [100.867218, 6.247508],
          [100.867453, 6.247968],
          [100.867599, 6.248752],
          [100.867834, 6.249104],
          [100.868127, 6.249375],
          [100.868449, 6.249997],
          [100.868537, 6.251079],
          [100.868684, 6.251404],
          [100.869387, 6.252297],
          [100.869827, 6.25273],
          [100.869915, 6.252974],
          [100.869651, 6.25365],
          [100.869534, 6.254381],
          [100.869505, 6.255382],
          [100.869387, 6.25595],
          [100.869417, 6.256437],
          [100.869387, 6.257033],
          [100.869534, 6.257466],
          [100.869593, 6.257818],
          [100.869329, 6.258629],
          [100.869329, 6.259279],
          [100.869446, 6.259874],
          [100.869563, 6.260145],
          [100.869798, 6.26028],
          [100.87056, 6.260388],
          [100.870706, 6.260713],
          [100.870443, 6.261363],
          [100.870384, 6.261634],
          [100.870472, 6.26204],
          [100.870853, 6.263231],
          [100.870765, 6.263582],
          [100.870765, 6.263826],
          [100.870941, 6.263988],
          [100.872172, 6.264448],
          [100.872348, 6.264557],
          [100.872553, 6.264259],
          [100.872758, 6.26407],
          [100.873843, 6.263528],
          [100.874195, 6.263393],
          [100.874869, 6.263447],
          [100.875045, 6.263393],
          [100.875103, 6.263231],
          [100.875074, 6.262771],
          [100.875162, 6.262392],
          [100.87569, 6.261336],
          [100.87569, 6.261093],
          [100.875426, 6.26047],
          [100.875514, 6.259794],
          [100.875895, 6.259036],
          [100.875865, 6.258549],
          [100.875953, 6.258197],
          [100.876716, 6.25771],
          [100.877272, 6.257142],
          [100.87736, 6.256925],
          [100.877624, 6.256682],
          [100.878005, 6.256465],
          [100.879002, 6.256276],
          [100.87953, 6.256032],
          [100.880028, 6.255383],
          [100.880262, 6.254869],
          [100.880614, 6.254652],
          [100.881054, 6.254544],
          [100.881669, 6.254463],
          [100.882138, 6.25449],
          [100.882549, 6.254625],
          [100.882988, 6.254869],
          [100.883692, 6.255085],
          [100.884044, 6.255058],
          [100.88463, 6.254761],
          [100.884864, 6.254544],
          [100.884982, 6.254246],
          [100.885099, 6.253516],
          [100.885246, 6.253272],
          [100.885304, 6.252163],
          [100.885421, 6.251865],
          [100.88548, 6.25154],
          [100.885832, 6.250593],
          [100.88592, 6.250187],
          [100.886066, 6.249944],
          [100.886301, 6.249808],
          [100.887033, 6.249592],
          [100.887649, 6.249538],
          [100.889554, 6.249889],
          [100.890023, 6.249889],
          [100.890786, 6.249808],
          [100.891401, 6.2497],
          [100.891899, 6.249511],
          [100.892134, 6.249294],
          [100.892427, 6.248401],
          [100.892662, 6.248131],
          [100.892779, 6.247319],
          [100.892749, 6.24648],
          [100.892544, 6.246128],
          [100.892456, 6.245641],
          [100.892456, 6.245235],
          [100.892515, 6.244829],
          [100.892662, 6.244558],
          [100.892779, 6.243801],
          [100.892779, 6.241961],
          [100.89272, 6.240174],
          [100.892808, 6.239687],
          [100.893189, 6.239443],
          [100.893746, 6.239281],
          [100.8953, 6.238604],
          [100.895974, 6.238171],
          [100.896531, 6.237603],
          [100.896883, 6.237116],
          [100.897058, 6.236521],
          [100.897264, 6.236007],
          [100.897557, 6.235574],
          [100.898231, 6.235032],
          [100.898934, 6.234653],
          [100.899609, 6.234518],
          [100.900312, 6.234545],
          [100.900928, 6.234464],
          [100.902628, 6.233679],
          [100.903038, 6.233652],
          [100.903361, 6.233544],
          [100.903742, 6.233598],
          [100.903976, 6.233788],
          [100.90635, 6.236142],
          [100.906732, 6.236602],
          [100.90723, 6.236981],
          [100.907435, 6.23736],
          [100.907523, 6.237738],
          [100.907523, 6.238144],
          [100.907142, 6.239092],
          [100.907025, 6.239552],
          [100.906995, 6.239876],
          [100.907288, 6.240255],
          [100.908256, 6.240986],
          [100.90852, 6.241283],
          [100.908666, 6.241554],
          [100.909047, 6.241662],
          [100.909575, 6.241608],
          [100.91022, 6.241446],
          [100.910777, 6.241392],
          [100.91107, 6.241446],
          [100.911451, 6.241689],
          [100.911891, 6.242068],
          [100.912154, 6.242474],
          [100.912447, 6.24445],
          [100.912682, 6.24491],
          [100.913503, 6.245505],
          [100.914148, 6.24564],
          [100.915144, 6.245776],
          [100.915437, 6.245911],
          [100.916404, 6.247616],
          [100.916785, 6.247887],
          [100.917401, 6.248157],
          [100.917811, 6.248103],
          [100.919863, 6.246912],
          [100.921622, 6.245505],
          [100.922267, 6.245072],
          [100.922736, 6.244937],
          [100.925667, 6.244558],
          [100.927308, 6.24472],
          [100.927983, 6.24472],
          [100.928276, 6.244585],
          [100.929272, 6.243909],
          [100.9298, 6.243421],
          [100.930298, 6.243313],
          [100.930943, 6.243232],
          [100.931441, 6.243313],
          [100.931969, 6.243584],
          [100.933112, 6.244017],
          [100.933347, 6.24399],
          [100.93361, 6.243882],
          [100.934109, 6.24353],
          [100.934578, 6.243313],
          [100.935369, 6.24307],
          [100.935926, 6.243043],
          [100.936454, 6.243124],
          [100.936893, 6.243259],
          [100.938095, 6.243963],
          [100.938711, 6.244098],
          [100.939033, 6.244017],
          [100.939561, 6.243719],
          [100.94003, 6.243259],
          [100.940587, 6.242907],
          [100.941261, 6.242664],
          [100.941906, 6.242637],
          [100.942522, 6.242745],
          [100.943929, 6.243043],
          [100.944661, 6.243286],
          [100.944867, 6.24353],
          [100.944984, 6.24399],
          [100.945511, 6.245343],
          [100.945541, 6.245749],
          [100.945511, 6.246425],
          [100.94516, 6.247751],
          [100.944837, 6.248753],
          [100.944837, 6.24905],
          [100.94513, 6.249267],
          [100.945511, 6.249348],
          [100.946332, 6.249727],
          [100.947036, 6.250647],
          [100.947153, 6.25108],
          [100.947563, 6.252054],
          [100.947593, 6.252298],
          [100.947387, 6.253164],
          [100.946186, 6.255843],
          [100.946039, 6.256492],
          [100.94601, 6.256979],
          [100.946215, 6.257575],
          [100.946303, 6.258116],
          [100.946596, 6.258684],
          [100.947065, 6.260227],
          [100.947124, 6.260741],
          [100.946948, 6.262013],
          [100.946948, 6.263663],
          [100.947036, 6.263988],
          [100.947299, 6.264123],
          [100.947798, 6.264205],
          [100.948736, 6.264204],
          [100.949322, 6.264339],
          [100.950084, 6.264583],
          [100.9507, 6.26488],
          [100.95111, 6.264934],
          [100.95196, 6.264826],
          [100.952429, 6.26488],
          [100.95325, 6.26488],
          [100.953602, 6.265016],
          [100.954276, 6.265449],
          [100.95495, 6.265584],
          [100.955273, 6.265746],
          [100.955624, 6.266044],
          [100.9558, 6.266423],
          [100.955859, 6.266937],
          [100.955771, 6.267776],
          [100.955888, 6.268046],
          [100.956035, 6.26894],
          [100.956123, 6.269102],
          [100.956328, 6.269129],
          [100.956709, 6.268912],
          [100.957149, 6.268804],
          [100.958057, 6.268804],
          [100.958819, 6.268642],
          [100.959113, 6.268642],
          [100.95923, 6.268831],
          [100.959288, 6.26986],
          [100.959406, 6.270347],
          [100.95964, 6.270807],
          [100.960109, 6.271077],
          [100.961809, 6.271321],
          [100.96222, 6.271564],
          [100.963333, 6.271808],
          [100.963802, 6.272024],
          [100.964652, 6.272187],
          [100.965268, 6.272214],
          [100.96562, 6.272322],
          [100.965825, 6.272566],
          [100.96603, 6.27308],
          [100.966323, 6.273621],
          [100.966734, 6.274054],
          [100.967818, 6.274974],
          [100.967877, 6.275245],
          [100.967847, 6.275569],
          [100.967965, 6.276679],
          [100.967906, 6.277572],
          [100.96773, 6.278086],
          [100.967789, 6.278276],
          [100.968141, 6.278573],
          [100.968375, 6.278871],
          [100.968346, 6.279087],
          [100.968141, 6.279575],
          [100.967848, 6.280089],
          [100.967115, 6.280738],
          [100.966968, 6.281036],
          [100.96691, 6.282172],
          [100.967086, 6.282632],
          [100.967525, 6.282876],
          [100.968229, 6.283065],
          [100.968991, 6.283147],
          [100.970222, 6.283011],
          [100.971189, 6.283038],
          [100.971834, 6.282876],
          [100.972215, 6.282632],
          [100.972567, 6.282308],
          [100.973036, 6.281198],
          [100.973358, 6.280901],
          [100.97412, 6.280413],
          [100.974619, 6.280278],
          [100.975615, 6.280143],
          [100.976172, 6.27971],
          [100.97711, 6.279493],
          [100.977462, 6.27971],
          [100.978517, 6.280143],
          [100.979602, 6.279926],
          [100.980071, 6.279899],
          [100.980364, 6.279926],
          [100.981331, 6.279223],
          [100.981888, 6.278627],
          [100.98224, 6.278492],
          [100.982797, 6.278465],
          [100.983295, 6.278546],
          [100.984145, 6.278844],
          [100.984409, 6.278736],
          [100.984643, 6.278411],
          [100.984643, 6.278249],
          [100.984409, 6.277599],
          [100.984409, 6.276787],
          [100.984526, 6.276084],
          [100.984849, 6.27538],
          [100.985024, 6.275137],
          [100.985347, 6.274893],
          [100.985728, 6.274676],
          [100.985992, 6.274406],
          [100.986109, 6.274108],
          [100.986197, 6.273621],
          [100.986373, 6.273296],
          [100.986607, 6.27308],
          [100.986783, 6.272755],
          [100.98693, 6.272052],
          [100.987047, 6.2717],
          [100.987223, 6.271375],
          [100.98778, 6.27105],
          [100.988044, 6.27078],
          [100.988337, 6.270076],
          [100.988278, 6.269589],
          [100.988336, 6.268669],
          [100.988424, 6.26829],
          [100.988571, 6.267993],
          [100.988776, 6.267722],
          [100.989128, 6.267533],
          [100.989362, 6.267343],
          [100.98948, 6.267046],
          [100.989568, 6.266559],
          [100.989685, 6.266369],
          [100.990212, 6.266126],
          [100.991121, 6.265882],
          [100.991737, 6.265828],
          [100.992147, 6.265936],
          [100.992528, 6.265909],
          [100.992763, 6.265638],
          [100.993027, 6.26507],
          [100.993701, 6.263257],
          [100.993935, 6.263149],
          [100.994668, 6.263365],
          [100.995225, 6.263365],
          [100.995635, 6.263176],
          [100.996016, 6.262905],
          [100.99631, 6.262608],
          [100.996632, 6.262581],
          [100.996984, 6.262743],
          [100.997277, 6.262635],
          [100.997306, 6.26231],
          [100.997453, 6.261904],
          [100.997775, 6.26166],
          [100.999182, 6.261228],
          [100.999915, 6.260849],
          [101.000795, 6.260064],
          [101.001117, 6.259902],
          [101.001498, 6.259848],
          [101.002583, 6.260389],
          [101.002993, 6.260497],
          [101.003345, 6.260497],
          [101.003579, 6.260308],
          [101.003814, 6.259929],
          [101.004195, 6.259009],
          [101.004634, 6.25844],
          [101.005103, 6.258034],
          [101.00566, 6.257683],
          [101.006276, 6.257466],
          [101.006921, 6.257168],
          [101.007653, 6.256032],
          [101.007829, 6.255869],
          [101.008181, 6.255815],
          [101.008679, 6.255815],
          [101.009324, 6.255923],
          [101.009764, 6.255923],
          [101.010174, 6.255842],
          [101.010292, 6.255626],
          [101.010174, 6.25522],
          [101.010086, 6.254327],
          [101.010174, 6.253433],
          [101.010673, 6.252405],
          [101.010907, 6.252134],
          [101.011845, 6.251783],
          [101.012197, 6.251485],
          [101.012373, 6.251241],
          [101.012431, 6.250862],
          [101.012431, 6.250456],
          [101.012343, 6.249482],
          [101.01249, 6.248778],
          [101.012783, 6.248508],
          [101.014454, 6.247209],
          [101.014571, 6.247019],
          [101.014806, 6.246803],
          [101.015275, 6.246667],
          [101.015949, 6.246532],
          [101.016125, 6.246586],
          [101.016388, 6.246749],
          [101.016564, 6.246938],
          [101.016828, 6.246965],
          [101.017883, 6.246749],
          [101.018499, 6.246397],
          [101.019115, 6.246424],
          [101.019672, 6.246559],
          [101.019847, 6.246749],
          [101.019994, 6.247019],
          [101.020053, 6.247479],
          [101.019994, 6.248075],
          [101.020111, 6.248372],
          [101.02058, 6.248724],
          [101.020756, 6.248941],
          [101.020991, 6.249536],
          [101.021108, 6.249671],
          [101.021401, 6.249671],
          [101.022134, 6.249563],
          [101.023599, 6.24913],
          [101.024801, 6.248345],
          [101.025182, 6.247939],
          [101.025739, 6.24756],
          [101.026003, 6.24756],
          [101.026619, 6.247425],
          [101.026853, 6.247263],
          [101.027733, 6.245477],
          [101.027908, 6.244773],
          [101.028055, 6.244584],
          [101.028612, 6.244421],
          [101.029227, 6.244476],
          [101.030283, 6.244448],
          [101.031045, 6.244394],
          [101.031631, 6.244259],
          [101.031983, 6.244421],
          [101.032247, 6.244746],
          [101.033038, 6.245396],
          [101.033214, 6.245639],
          [101.033302, 6.24591],
          [101.033595, 6.246126],
          [101.034152, 6.246235],
          [101.034855, 6.246126],
          [101.03509, 6.246126],
          [101.0355, 6.246262],
          [101.035764, 6.246478],
          [101.035969, 6.246938],
          [101.036204, 6.247073],
          [101.036556, 6.247155],
          [101.036849, 6.247344],
          [101.037201, 6.247615],
          [101.037464, 6.247993],
          [101.038197, 6.248454],
          [101.03852, 6.248589],
          [101.039018, 6.248643],
          [101.03934, 6.248724],
          [101.039604, 6.248914],
          [101.040044, 6.249401],
          [101.040308, 6.249536],
          [101.040982, 6.249671],
          [101.041275, 6.249536],
          [101.041568, 6.249238],
          [101.041773, 6.248751],
          [101.042096, 6.248616],
          [101.04277, 6.248886],
          [101.043327, 6.249563],
          [101.04362, 6.249698],
          [101.04403, 6.249698],
          [101.04447, 6.24978],
          [101.044675, 6.250321],
          [101.04488, 6.250591],
          [101.045203, 6.250781],
          [101.045613, 6.250835],
          [101.046082, 6.25097],
          [101.046522, 6.251187],
          [101.047137, 6.25189],
          [101.047254, 6.252269],
          [101.047518, 6.252702],
          [101.047841, 6.252973],
          [101.048573, 6.253757],
          [101.049453, 6.254163],
          [101.050889, 6.254596],
          [101.052091, 6.254813],
          [101.053937, 6.255029],
          [101.054846, 6.254975],
          [101.055813, 6.25484],
          [101.05681, 6.254623],
          [101.057719, 6.25438],
          [101.058745, 6.25373],
          [101.059214, 6.253541],
          [101.059683, 6.253487],
          [101.059976, 6.253622],
          [101.060064, 6.253811],
          [101.060239, 6.25511],
          [101.060503, 6.256247],
          [101.060972, 6.257356],
          [101.0615, 6.257979],
          [101.062086, 6.258277],
          [101.06279, 6.258493],
          [101.063053, 6.25871],
          [101.063288, 6.25917],
          [101.063581, 6.259305],
          [101.064021, 6.259359],
          [101.064841, 6.258953],
          [101.065516, 6.258682],
          [101.065867, 6.258439],
          [101.066366, 6.257356],
          [101.066278, 6.256951],
          [101.066307, 6.256409],
          [101.066454, 6.256166],
          [101.066776, 6.255868],
          [101.066923, 6.255814],
          [101.067216, 6.255814],
          [101.068183, 6.256166],
          [101.068711, 6.256301],
          [101.069092, 6.256491],
          [101.06959, 6.256951],
          [101.070147, 6.257302],
          [101.070821, 6.2576],
          [101.072228, 6.257952],
          [101.072698, 6.257925],
          [101.073049, 6.257843],
          [101.073255, 6.257735],
          [101.07343, 6.25741],
          [101.073665, 6.256571],
          [101.074105, 6.255841],
          [101.07431, 6.255597],
          [101.074515, 6.255489],
          [101.074925, 6.255435],
          [101.075658, 6.255706],
          [101.07601, 6.256111],
          [101.077827, 6.256896],
          [101.078765, 6.257546],
          [101.080407, 6.258547],
          [101.081579, 6.259413],
          [101.082077, 6.259548],
          [101.082429, 6.259629],
          [101.082986, 6.259575],
          [101.083748, 6.259467],
          [101.084745, 6.259196],
          [101.085566, 6.259088],
          [101.08709, 6.259115],
          [101.087617, 6.259251],
          [101.087998, 6.259413],
          [101.088262, 6.259711],
          [101.088409, 6.259954],
          [101.088702, 6.260144],
          [101.088995, 6.260198],
          [101.089405, 6.260144],
          [101.089933, 6.259927],
          [101.090314, 6.259846],
          [101.0943, 6.260441],
          [101.094945, 6.260441],
          [101.095356, 6.260333],
          [101.096909, 6.259467],
          [101.097378, 6.259061],
          [101.097759, 6.258655],
          [101.097935, 6.258303],
          [101.098287, 6.257816],
          [101.098609, 6.257492],
          [101.099284, 6.257275],
          [101.099841, 6.257248],
          [101.100544, 6.257059],
          [101.100779, 6.256788],
          [101.100984, 6.256436],
          [101.101599, 6.255246],
          [101.101922, 6.254488],
          [101.102274, 6.254109],
          [101.102801, 6.253838],
          [101.104648, 6.253514],
          [101.105205, 6.253378],
          [101.105674, 6.253162],
          [101.105996, 6.25281],
          [101.106758, 6.252404],
          [101.106992, 6.252242],
          [101.107168, 6.252052],
          [101.108165, 6.251565],
          [101.108956, 6.251538],
          [101.10966, 6.251674],
          [101.110246, 6.25189],
          [101.111038, 6.252025],
          [101.111683, 6.251971],
          [101.112122, 6.251863],
          [101.112415, 6.251647],
          [101.112533, 6.251349],
          [101.112503, 6.250997],
          [101.111888, 6.249942],
          [101.111634, 6.249346],
          [101.111463, 6.248713],
          [101.111429, 6.247701],
          [101.111189, 6.247512],
          [101.110538, 6.247259],
          [101.110024, 6.247006],
          [101.109682, 6.246753],
          [101.109442, 6.246436],
          [101.109134, 6.245646],
          [101.108483, 6.244539],
          [101.108038, 6.244128],
          [101.107935, 6.24378],
          [101.107866, 6.243179],
          [101.107901, 6.242863],
          [101.107866, 6.242578],
          [101.107558, 6.242167],
          [101.105743, 6.24046],
          [101.105366, 6.239954],
          [101.10516, 6.239227],
          [101.105092, 6.238594],
          [101.105195, 6.237962],
          [101.105366, 6.237456],
          [101.105743, 6.237045],
          [101.106256, 6.236887],
          [101.106565, 6.236602],
          [101.10701, 6.236286],
          [101.107627, 6.235938],
          [101.107798, 6.235717],
          [101.107901, 6.235432],
          [101.107935, 6.234831],
          [101.108106, 6.23461],
          [101.108243, 6.234135],
          [101.108346, 6.233977],
          [101.108723, 6.233661],
          [101.109168, 6.233155],
          [101.109922, 6.232586],
          [101.11023, 6.232301],
          [101.110435, 6.232017],
          [101.110504, 6.231574],
          [101.110504, 6.230435],
          [101.110675, 6.229518],
          [101.110709, 6.228981],
          [101.110607, 6.228348],
          [101.110641, 6.227968],
          [101.110983, 6.227684],
          [101.111668, 6.227526],
          [101.112593, 6.227178],
          [101.112936, 6.226925],
          [101.113381, 6.226672],
          [101.113484, 6.226514],
          [101.113587, 6.226039],
          [101.113381, 6.223889],
          [101.113176, 6.222972],
          [101.113141, 6.222529],
          [101.113244, 6.222276],
          [101.11345, 6.222055],
          [101.113655, 6.22196],
          [101.114477, 6.222023],
          [101.114923, 6.22196],
          [101.115334, 6.22177],
          [101.116156, 6.220505],
          [101.116224, 6.220126],
          [101.115813, 6.218703],
          [101.115368, 6.217754],
          [101.115334, 6.217248],
          [101.115402, 6.216932],
          [101.115573, 6.216584],
          [101.11619, 6.215857],
          [101.116293, 6.215509],
          [101.11643, 6.215319],
          [101.116909, 6.215288],
          [101.11708, 6.215161],
          [101.117355, 6.214782],
          [101.117492, 6.214181],
          [101.1178, 6.213612],
          [101.118142, 6.213169],
          [101.118999, 6.212442],
          [101.119067, 6.212284],
          [101.118793, 6.211967],
          [101.118553, 6.211461],
          [101.118622, 6.21124],
          [101.118725, 6.211082],
          [101.118725, 6.210892],
          [101.118485, 6.210544],
          [101.118142, 6.210165],
          [101.11804, 6.209754],
          [101.117971, 6.209184],
          [101.117834, 6.208647],
          [101.117697, 6.208362],
          [101.117457, 6.208141],
          [101.117115, 6.207951],
          [101.116669, 6.207856],
          [101.116429, 6.207476],
          [101.116292, 6.207065],
          [101.116087, 6.206812],
          [101.115984, 6.206464],
          [101.116018, 6.206021],
          [101.115984, 6.205673],
          [101.115676, 6.204915],
          [101.115504, 6.204693],
          [101.115333, 6.204345],
          [101.115436, 6.204061],
          [101.11571, 6.203523],
          [101.116053, 6.203175],
          [101.116361, 6.203049],
          [101.11708, 6.20308],
          [101.117251, 6.203049],
          [101.117525, 6.203144],
          [101.117731, 6.20327],
          [101.117971, 6.20327],
          [101.118313, 6.203175],
          [101.11869, 6.202827],
          [101.119307, 6.202385],
          [101.119581, 6.202132],
          [101.119923, 6.201942],
          [101.120403, 6.201531],
          [101.120711, 6.201436],
          [101.121088, 6.201436],
          [101.121705, 6.201594],
          [101.122013, 6.201468],
          [101.122321, 6.201278],
          [101.122664, 6.200803],
          [101.123177, 6.20055],
          [101.123657, 6.200108],
          [101.123691, 6.199791],
          [101.123589, 6.199475],
          [101.123349, 6.199222],
          [101.123314, 6.198938],
          [101.123486, 6.19821],
          [101.123863, 6.197641],
          [101.124342, 6.197135],
          [101.124959, 6.196629],
          [101.125164, 6.19625],
          [101.125267, 6.195775],
          [101.12561, 6.195301],
          [101.12626, 6.194953],
          [101.126637, 6.194637],
          [101.126911, 6.194289],
          [101.12698, 6.193878],
          [101.126877, 6.193245],
          [101.126671, 6.192645],
          [101.126432, 6.192423],
          [101.126123, 6.192297],
          [101.125336, 6.192044],
          [101.124685, 6.191949],
          [101.124411, 6.191854],
          [101.123863, 6.191412],
          [101.123417, 6.190937],
          [101.123383, 6.190495],
          [101.12328, 6.190052],
          [101.122938, 6.189577],
          [101.122561, 6.189135],
          [101.122184, 6.189071],
          [101.121876, 6.188819],
          [101.121602, 6.188186],
          [101.121568, 6.18787],
          [101.121465, 6.187648],
          [101.121293, 6.187522],
          [101.120711, 6.187237],
          [101.120574, 6.187111],
          [101.120334, 6.186636],
          [101.120095, 6.18651],
          [101.119307, 6.186415],
          [101.118896, 6.186289],
          [101.11845, 6.186067],
          [101.118074, 6.185783],
          [101.117868, 6.185435],
          [101.1178, 6.184992],
          [101.117628, 6.184707],
          [101.117286, 6.184423],
          [101.11684, 6.18417],
          [101.116224, 6.183948],
          [101.115299, 6.183411],
          [101.114614, 6.182937],
          [101.113757, 6.182873],
          [101.113004, 6.182715],
          [101.111908, 6.182589],
          [101.111531, 6.182431],
          [101.111291, 6.182272],
          [101.110503, 6.181229],
          [101.109613, 6.180502],
          [101.109167, 6.180059],
          [101.10814, 6.179395],
          [101.107729, 6.179268],
          [101.107112, 6.179331],
          [101.106393, 6.178983],
          [101.105263, 6.178857],
          [101.104783, 6.178762],
          [101.103892, 6.178319],
          [101.10355, 6.178256],
          [101.102111, 6.178446],
          [101.101186, 6.178256],
          [101.100673, 6.178256],
          [101.098583, 6.178793],
          [101.09783, 6.179046],
          [101.097076, 6.179458],
          [101.096699, 6.179616],
          [101.096528, 6.179616],
          [101.096357, 6.179426],
          [101.096117, 6.178825],
          [101.095877, 6.178509],
          [101.095021, 6.177718],
          [101.094473, 6.177402],
          [101.094267, 6.177212],
          [101.094027, 6.176675],
          [101.093377, 6.175916],
          [101.093205, 6.175884],
          [101.09276, 6.175884],
          [101.092452, 6.176074],
          [101.091904, 6.176327],
          [101.091424, 6.176833],
          [101.090808, 6.177022],
          [101.09026, 6.177275],
          [101.089883, 6.177592],
          [101.089095, 6.177845],
          [101.088478, 6.178224],
          [101.087348, 6.17873],
          [101.086971, 6.17873],
          [101.086423, 6.178604],
          [101.085772, 6.178667],
          [101.085601, 6.178477],
          [101.085807, 6.178224],
          [101.085841, 6.177813],
          [101.085772, 6.177339],
          [101.085601, 6.177149],
          [101.085259, 6.176137],
          [101.08519, 6.175821],
          [101.085327, 6.175599],
          [101.085704, 6.175157],
          [101.085704, 6.174935],
          [101.085601, 6.174556],
          [101.085327, 6.174271],
          [101.084299, 6.173987],
          [101.083717, 6.174082],
          [101.08334, 6.174208],
          [101.082998, 6.174208],
          [101.082176, 6.173987],
          [101.081867, 6.173955],
          [101.081559, 6.173765],
          [101.081114, 6.173259],
          [101.080737, 6.172975],
          [101.079538, 6.172658],
          [101.078682, 6.171868],
          [101.078202, 6.171678],
          [101.077312, 6.171678],
          [101.077072, 6.171583],
          [101.076284, 6.171425],
          [101.076113, 6.171267],
          [101.075702, 6.171046],
          [101.075428, 6.170793],
          [101.075188, 6.170319],
          [101.075085, 6.169971],
          [101.075188, 6.169655],
          [101.075325, 6.169496],
          [101.075291, 6.169243],
          [101.074948, 6.168516],
          [101.074914, 6.168105],
          [101.075085, 6.167188],
          [101.075291, 6.166745],
          [101.075531, 6.165638],
          [101.075839, 6.164532],
          [101.07601, 6.162792],
          [101.075942, 6.161907],
          [101.076045, 6.161527],
          [101.07625, 6.161116],
          [101.076387, 6.160484],
          [101.07625, 6.160389],
          [101.075908, 6.160262],
          [101.075565, 6.160009],
          [101.075291, 6.159883],
          [101.074195, 6.15982],
          [101.073818, 6.15963],
          [101.073373, 6.159472],
          [101.073099, 6.159472],
          [101.072688, 6.159788],
          [101.072379, 6.159915],
          [101.071009, 6.159156],
          [101.070358, 6.15865],
          [101.069879, 6.15808],
          [101.069228, 6.157511],
          [101.068954, 6.157163],
          [101.068783, 6.156847],
          [101.068543, 6.156626],
          [101.067584, 6.156151],
          [101.067139, 6.155772],
          [101.06659, 6.153906],
          [101.066453, 6.15359],
          [101.066179, 6.1534],
          [101.065665, 6.153147],
          [101.065494, 6.152894],
          [101.065391, 6.152452],
          [101.065083, 6.152009],
          [101.064398, 6.151377],
          [101.063884, 6.151124],
          [101.062959, 6.151029],
          [101.062582, 6.150997],
          [101.06224, 6.151124],
          [101.061966, 6.151155],
          [101.061349, 6.150997],
          [101.061041, 6.150744],
          [101.060801, 6.150396],
          [101.060801, 6.150206],
          [101.061349, 6.149606],
          [101.061349, 6.149321],
          [101.060904, 6.148847],
          [101.060938, 6.148151],
          [101.060835, 6.147866],
          [101.060698, 6.147677],
          [101.060082, 6.147107],
          [101.059979, 6.146949],
          [101.05991, 6.14676],
          [101.059979, 6.14657],
          [101.059979, 6.146348],
          [101.059808, 6.146095],
          [101.059431, 6.145147],
          [101.059465, 6.144736],
          [101.059397, 6.144388],
          [101.059534, 6.144103],
          [101.059671, 6.143534],
          [101.059534, 6.143249],
          [101.05878, 6.142648],
          [101.058677, 6.142269],
          [101.058746, 6.141699],
          [101.05902, 6.141414],
          [101.059362, 6.141351],
          [101.059671, 6.141225],
          [101.060733, 6.140624],
          [101.061452, 6.140371],
          [101.061657, 6.140213],
          [101.061966, 6.140276],
          [101.062993, 6.14113],
          [101.06337, 6.141256],
          [101.063815, 6.141225],
          [101.064432, 6.14094],
          [101.064569, 6.140466],
          [101.064501, 6.140244],
          [101.064672, 6.140055],
          [101.064843, 6.13996],
          [101.065151, 6.140118],
          [101.065357, 6.140402],
          [101.065631, 6.140466],
          [101.066042, 6.140402],
          [101.066487, 6.140181],
          [101.066933, 6.140023],
          [101.067309, 6.140055],
          [101.068371, 6.140308],
          [101.069536, 6.139991],
          [101.071249, 6.140118],
          [101.071523, 6.140086],
          [101.071797, 6.139865],
          [101.072037, 6.139169],
          [101.072516, 6.138473],
          [101.07303, 6.138189],
          [101.073715, 6.137651],
          [101.074092, 6.136987],
          [101.074434, 6.135912],
          [101.074674, 6.135596],
          [101.07488, 6.135406],
          [101.075359, 6.135438],
          [101.075805, 6.135659],
          [101.07738, 6.135754],
          [101.078065, 6.135596],
          [101.07875, 6.135374],
          [101.079607, 6.135026],
          [101.080532, 6.134552],
          [101.081936, 6.134236],
          [101.082484, 6.134141],
          [101.083135, 6.134141],
          [101.083683, 6.134078],
          [101.084608, 6.134299],
          [101.085019, 6.134331],
          [101.085327, 6.134267],
          [101.085636, 6.134078],
          [101.085841, 6.133888],
          [101.086252, 6.13373],
          [101.086663, 6.133698],
          [101.087348, 6.133761],
          [101.08889, 6.134299],
          [101.089232, 6.134489],
          [101.089438, 6.134489],
          [101.090636, 6.133383],
          [101.09115, 6.132466],
          [101.091801, 6.131548],
          [101.092692, 6.130821],
          [101.093445, 6.130125],
          [101.093822, 6.129525],
          [101.094199, 6.129082],
          [101.094644, 6.128734],
          [101.095124, 6.128544],
          [101.095809, 6.128165],
          [101.096836, 6.127343],
          [101.097076, 6.127216],
          [101.09759, 6.127216],
          [101.097864, 6.127279],
          [101.098481, 6.127248],
          [101.098994, 6.126995],
          [101.09944, 6.126837],
          [101.099851, 6.126615],
          [101.100262, 6.126236],
          [101.100536, 6.125919],
          [101.10105, 6.124338],
          [101.101426, 6.123737],
          [101.101803, 6.123453],
          [101.102317, 6.123168],
          [101.102694, 6.123073],
          [101.102865, 6.123073],
          [101.103893, 6.123516],
          [101.104715, 6.124149],
          [101.10492, 6.124022],
          [101.105057, 6.123832],
          [101.105605, 6.123548],
          [101.10564, 6.1232],
          [101.105537, 6.12282],
          [101.105503, 6.122409],
          [101.105571, 6.121998],
          [101.105879, 6.121492],
          [101.106256, 6.120733],
          [101.106633, 6.119468],
          [101.106667, 6.119184],
          [101.106907, 6.118899],
          [101.107318, 6.118741],
          [101.107695, 6.118488],
          [101.107935, 6.118235],
          [101.108072, 6.117855],
          [101.108106, 6.117571],
          [101.107832, 6.116812],
          [101.107318, 6.116084],
          [101.106838, 6.115767],
          [101.10653, 6.115451],
          [101.106462, 6.115072],
          [101.106325, 6.114755],
          [101.106051, 6.114503],
          [101.105777, 6.114123],
          [101.105742, 6.11368],
          [101.105879, 6.113427],
          [101.106393, 6.113143],
          [101.106941, 6.113016],
          [101.107455, 6.112953],
          [101.107866, 6.113079],
          [101.10838, 6.113396],
          [101.108928, 6.113585],
          [101.110264, 6.113712],
          [101.110778, 6.113585],
          [101.111634, 6.113617],
          [101.112011, 6.113554],
          [101.112422, 6.113301],
          [101.113484, 6.113016],
          [101.113963, 6.112985],
          [101.114374, 6.113016],
          [101.114991, 6.113143],
          [101.115265, 6.113301],
          [101.115573, 6.11368],
          [101.116053, 6.113838],
          [101.11708, 6.113965],
          [101.117457, 6.114123],
          [101.117765, 6.114534],
          [101.117971, 6.114629],
          [101.119067, 6.114503],
          [101.120129, 6.113522],
          [101.120471, 6.113016],
          [101.120848, 6.112605],
          [101.121019, 6.112226],
          [101.121568, 6.11172],
          [101.122527, 6.111056],
          [101.123623, 6.110613],
          [101.123965, 6.11036],
          [101.124308, 6.110012],
          [101.124411, 6.109664],
          [101.124274, 6.109221],
          [101.123897, 6.108399],
          [101.123965, 6.108178],
          [101.124513, 6.107735],
          [101.124924, 6.107545],
          [101.126329, 6.107134],
          [101.126637, 6.106913],
          [101.126945, 6.105869],
          [101.127048, 6.104952],
          [101.127459, 6.103845],
          [101.127459, 6.103023],
          [101.127322, 6.102454],
          [101.126808, 6.10081],
          [101.126843, 6.10043],
          [101.126911, 6.100019],
          [101.127391, 6.099323],
          [101.12787, 6.098407],
          [101.127939, 6.097837],
          [101.127699, 6.097141],
          [101.127459, 6.096794],
          [101.127254, 6.096382],
          [101.12698, 6.095339],
          [101.126808, 6.095086],
          [101.126466, 6.094928],
          [101.125678, 6.094928],
          [101.12465, 6.094643],
          [101.12376, 6.09458],
          [101.123383, 6.094643],
          [101.122253, 6.095118],
          [101.12167, 6.095181],
          [101.120985, 6.095181],
          [101.120608, 6.095086],
          [101.120334, 6.094928],
          [101.120095, 6.094706],
          [101.119478, 6.09439],
          [101.119341, 6.094232],
          [101.119273, 6.093979],
          [101.119136, 6.093821],
          [101.118005, 6.093663],
          [101.116772, 6.093568],
          [101.116532, 6.093473],
          [101.115744, 6.092936],
          [101.115402, 6.092841],
          [101.115094, 6.092651],
          [101.115094, 6.092303],
          [101.115231, 6.091639],
          [101.115402, 6.091196],
          [101.115642, 6.090753],
          [101.115779, 6.090342],
          [101.115916, 6.090153],
          [101.116018, 6.0899],
          [101.116018, 6.089394],
          [101.116155, 6.088287],
          [101.116464, 6.087686],
          [101.116806, 6.086832],
          [101.116909, 6.086674],
          [101.11708, 6.086579],
          [101.117115, 6.086389],
          [101.11708, 6.085915],
          [101.116943, 6.085599],
          [101.116704, 6.085314],
          [101.116292, 6.08503],
          [101.116155, 6.084808],
          [101.116121, 6.084524],
          [101.116258, 6.083796],
          [101.116258, 6.083417],
          [101.116121, 6.081678],
          [101.116018, 6.081203],
          [101.115813, 6.080603],
          [101.11547, 6.079844],
          [101.114751, 6.079369],
          [101.1141, 6.079021],
          [101.113895, 6.078832],
          [101.113689, 6.078452],
          [101.113518, 6.078294],
          [101.11321, 6.078389],
          [101.112901, 6.078421],
          [101.111497, 6.077187],
          [101.111702, 6.076808],
          [101.112011, 6.075922],
          [101.112011, 6.075543],
          [101.111531, 6.074689],
          [101.110504, 6.07393],
          [101.110367, 6.073551],
          [101.110641, 6.07257],
          [101.110675, 6.072191],
          [101.110572, 6.071116],
          [101.110367, 6.069756],
          [101.110435, 6.069313],
          [101.110401, 6.068396],
          [101.110435, 6.068048],
          [101.110606, 6.067732],
          [101.111086, 6.067068],
          [101.111051, 6.066436],
          [101.110914, 6.066214],
          [101.110092, 6.065613],
          [101.109613, 6.064886],
          [101.109339, 6.064696],
          [101.108962, 6.064601],
          [101.108277, 6.064538],
          [101.107421, 6.06438],
          [101.105639, 6.063495],
          [101.104989, 6.063052],
          [101.104715, 6.062736],
          [101.105126, 6.061502],
          [101.105194, 6.061154],
          [101.1054, 6.060933],
          [101.1054, 6.060617],
          [101.105194, 6.060332],
          [101.104989, 6.059921],
          [101.104646, 6.05951],
          [101.104612, 6.059194],
          [101.10468, 6.058846],
          [101.104406, 6.057897],
          [101.104235, 6.057771],
          [101.10355, 6.057486],
          [101.10331, 6.057296],
          [101.10307, 6.056885],
          [101.102899, 6.056379],
          [101.102865, 6.055178],
          [101.102488, 6.053944],
          [101.101666, 6.052774],
          [101.100467, 6.051287],
          [101.1004137, 6.050881],
          [101.10033, 6.050244],
          [101.10033, 6.050054],
          [101.100433, 6.049674],
          [101.101392, 6.04901],
          [101.102488, 6.048441],
          [101.102865, 6.048188],
          [101.103344, 6.04822],
          [101.103892, 6.048694],
          [101.104578, 6.048789],
          [101.105057, 6.048662],
          [101.105502, 6.048378],
          [101.10605, 6.047872],
          [101.106393, 6.047492],
          [101.106461, 6.04686],
          [101.106427, 6.046386],
          [101.106153, 6.045595],
          [101.106187, 6.045342],
          [101.106393, 6.045089],
          [101.106736, 6.044899],
          [101.107284, 6.044773],
          [101.107626, 6.044583],
          [101.108243, 6.043951],
          [101.108859, 6.04316],
          [101.109921, 6.042496],
          [101.111394, 6.041389],
          [101.111942, 6.041168],
          [101.112901, 6.040504],
          [101.113689, 6.039745],
          [101.11434, 6.039239],
          [101.114408, 6.039081],
          [101.114306, 6.038764],
          [101.113929, 6.0381],
          [101.113723, 6.037436],
          [101.113723, 6.036772],
          [101.113415, 6.03576],
          [101.113381, 6.035254],
          [101.11297, 6.03478],
          [101.112833, 6.034527],
          [101.112559, 6.032661],
          [101.112524, 6.032218],
          [101.112559, 6.031839],
          [101.112524, 6.031618],
          [101.11136, 6.030764],
          [101.110914, 6.030574],
          [101.110127, 6.030416],
          [101.109613, 6.030384],
          [101.108756, 6.030163],
          [101.10838, 6.029657],
          [101.10814, 6.029024],
          [101.108174, 6.027348],
          [101.108277, 6.026716],
          [101.108551, 6.026273],
          [101.108996, 6.025831],
          [101.109544, 6.025514],
          [101.109647, 6.02504],
          [101.110983, 6.02289],
          [101.111086, 6.022415],
          [101.110777, 6.020866],
          [101.110846, 6.02055],
          [101.111188, 6.020044],
          [101.111257, 6.019822],
          [101.110983, 6.019538],
          [101.110846, 6.019221],
          [101.110606, 6.018937],
          [101.109681, 6.018621],
          [101.108996, 6.018115],
          [101.108722, 6.017545],
          [101.108106, 6.01685],
          [101.108277, 6.01666],
          [101.108414, 6.016597],
          [101.108585, 6.016154],
          [101.108619, 6.015363],
          [101.108756, 6.015079],
          [101.109099, 6.014826],
          [101.109955, 6.014446],
          [101.110161, 6.014162],
          [101.110298, 6.013719],
          [101.110229, 6.013023],
          [101.110058, 6.012486],
          [101.110127, 6.012074],
          [101.110846, 6.011126],
          [101.111051, 6.010272],
          [101.112387, 6.009292],
          [101.112901, 6.00907],
          [101.113038, 6.008944],
          [101.113004, 6.008691],
          [101.11273, 6.008374],
          [101.112113, 6.007869],
          [101.111325, 6.006793],
          [101.110675, 6.006445],
          [101.110161, 6.006256],
          [101.109647, 6.006161],
          [101.109202, 6.006003],
          [101.109031, 6.005876],
          [101.108722, 6.005497],
          [101.109031, 6.00341],
          [101.108996, 6.003125],
          [101.108756, 6.002493],
          [101.10814, 6.001923],
          [101.107558, 6.001164],
          [101.106633, 6.000753],
          [101.106324, 6.0005],
          [101.106222, 6.000311],
          [101.106187, 6.000026],
          [101.106546, 5.999147],
          [101.10689, 5.998141],
          [101.107234, 5.997841],
          [101.107311, 5.997682],
          [101.107062, 5.99687],
          [101.106852, 5.996341],
          [101.106623, 5.995953],
          [101.106508, 5.995564],
          [101.106489, 5.995229],
          [101.106584, 5.994929],
          [101.107387, 5.993376],
          [101.107521, 5.993041],
          [101.107521, 5.992459],
          [101.107368, 5.991859],
          [101.10712, 5.991153],
          [101.10689, 5.990853],
          [101.106871, 5.990694],
          [101.106967, 5.990359],
          [101.107215, 5.990059],
          [101.107693, 5.989653],
          [101.108152, 5.989371],
          [101.108534, 5.989247],
          [101.108859, 5.989194],
          [101.110254, 5.988594],
          [101.110732, 5.988612],
          [101.111707, 5.98893],
          [101.11228, 5.989],
          [101.112624, 5.988965],
          [101.113217, 5.988771],
          [101.113656, 5.988877],
          [101.113962, 5.988806],
          [101.114249, 5.988665],
          [101.114612, 5.98863],
          [101.114822, 5.988524],
          [101.114861, 5.988242],
          [101.114842, 5.987747],
          [101.114784, 5.9875],
          [101.114077, 5.986989],
          [101.113943, 5.986847],
          [101.113924, 5.986671],
          [101.113962, 5.986512],
          [101.114192, 5.986265],
          [101.114822, 5.986036],
          [101.115205, 5.985824],
          [101.115453, 5.985595],
          [101.115893, 5.984518],
          [101.116122, 5.984342],
          [101.116963, 5.984236],
          [101.11725, 5.984006],
          [101.117785, 5.983406],
          [101.118033, 5.983335],
          [101.118339, 5.983318],
          [101.119295, 5.983459],
          [101.11962, 5.983424],
          [101.120957, 5.982894],
          [101.12197, 5.982647],
          [101.122315, 5.982365],
          [101.122869, 5.982118],
          [101.123251, 5.981888],
          [101.123557, 5.981641],
          [101.123691, 5.981465],
          [101.123729, 5.981306],
          [101.123691, 5.981094],
          [101.123328, 5.980512],
          [101.123232, 5.980106],
          [101.123213, 5.9797],
          [101.123347, 5.9791],
          [101.123672, 5.978253],
          [101.123844, 5.977918],
          [101.124398, 5.977195],
          [101.124436, 5.977001],
          [101.124283, 5.976754],
          [101.123901, 5.976365],
          [101.123423, 5.976118],
          [101.122162, 5.975801],
          [101.122047, 5.975713],
          [101.121856, 5.975518],
          [101.121531, 5.974759],
          [101.121187, 5.974318],
          [101.120824, 5.974194],
          [101.120499, 5.97423],
          [101.120231, 5.974353],
          [101.119925, 5.974388],
          [101.119734, 5.974353],
          [101.119486, 5.974141],
          [101.119275, 5.973824],
          [101.119218, 5.973506],
          [101.11939, 5.972447],
          [101.119352, 5.971653],
          [101.118721, 5.9701],
          [101.118606, 5.969959],
          [101.11832, 5.969871],
          [101.117861, 5.969624],
          [101.117402, 5.969183],
          [101.116944, 5.968848],
          [101.1166, 5.968512],
          [101.116141, 5.968195],
          [101.115529, 5.968001],
          [101.115338, 5.967895],
          [101.11509, 5.967648],
          [101.114765, 5.966748],
          [101.114688, 5.966254],
          [101.114669, 5.965672],
          [101.114459, 5.964789],
          [101.11444, 5.964525],
          [101.114554, 5.964048],
          [101.114516, 5.963677],
          [101.114382, 5.963342],
          [101.114115, 5.963166],
          [101.113962, 5.963007],
          [101.113904, 5.962813],
          [101.114, 5.962354],
          [101.113981, 5.962089],
          [101.113388, 5.960925],
          [101.113465, 5.960077],
          [101.113446, 5.959848],
          [101.113369, 5.959725],
          [101.11335, 5.959548],
          [101.113274, 5.959372],
          [101.113044, 5.959107],
          [101.112567, 5.958719],
          [101.111898, 5.958066],
          [101.11163, 5.957554],
          [101.111516, 5.957466],
          [101.111401, 5.957466],
          [101.111172, 5.957589],
          [101.110866, 5.957836],
          [101.110369, 5.958789],
          [101.110082, 5.959071],
          [101.109432, 5.959354],
          [101.109279, 5.959336],
          [101.109088, 5.959195],
          [101.108897, 5.95893],
          [101.108744, 5.958595],
          [101.108572, 5.958419],
          [101.107846, 5.958083],
          [101.107655, 5.957801],
          [101.107464, 5.957042],
          [101.107253, 5.956795],
          [101.107062, 5.956654],
          [101.106336, 5.956336],
          [101.106164, 5.95616],
          [101.105992, 5.955542],
          [101.105762, 5.955224],
          [101.105514, 5.95466],
          [101.105266, 5.954554],
          [101.10494, 5.954554],
          [101.104501, 5.954466],
          [101.104099, 5.954113],
          [101.103832, 5.953989],
          [101.10278, 5.953901],
          [101.102608, 5.953725],
          [101.102532, 5.953566],
          [101.102398, 5.952878],
          [101.101806, 5.952278],
          [101.101634, 5.951925],
          [101.101557, 5.951519],
          [101.1015, 5.951448],
          [101.100085, 5.951484],
          [101.099856, 5.951431],
          [101.099588, 5.951325],
          [101.09913, 5.950972],
          [101.098499, 5.950584],
          [101.098212, 5.950548],
          [101.097849, 5.950601],
          [101.097448, 5.950795],
          [101.09676, 5.951272],
          [101.09632, 5.951448],
          [101.096205, 5.951378],
          [101.09611, 5.951148],
          [101.095785, 5.950831],
          [101.095613, 5.950742],
          [101.095345, 5.950707],
          [101.095231, 5.950637],
          [101.095135, 5.950495],
          [101.095173, 5.950337],
          [101.096014, 5.949401],
          [101.096454, 5.948307],
          [101.096721, 5.947372],
          [101.096874, 5.947143],
          [101.097792, 5.946543],
          [101.097983, 5.946348],
          [101.098078, 5.946137],
          [101.098193, 5.945607],
          [101.098537, 5.94499],
          [101.098633, 5.944284],
          [101.098709, 5.944072],
          [101.099703, 5.943137],
          [101.09978, 5.94289],
          [101.099684, 5.942272],
          [101.099569, 5.94199],
          [101.099397, 5.941831],
          [101.099149, 5.941707],
          [101.098843, 5.941478],
          [101.098614, 5.941248],
          [101.098518, 5.940825],
          [101.098059, 5.939837],
          [101.097333, 5.938601],
          [101.097085, 5.938266],
          [101.096798, 5.937296],
          [101.096569, 5.936855],
          [101.096358, 5.936608],
          [101.096148, 5.936484],
          [101.095957, 5.936431],
          [101.095517, 5.936449],
          [101.09504, 5.936202],
          [101.094581, 5.936202],
          [101.094428, 5.936114],
          [101.094294, 5.935831],
          [101.094084, 5.93449],
          [101.094027, 5.934367],
          [101.093816, 5.934173],
          [101.092574, 5.933467],
          [101.092211, 5.933043],
          [101.092115, 5.932849],
          [101.092115, 5.932637],
          [101.092192, 5.932478],
          [101.092249, 5.931914],
          [101.092211, 5.931543],
          [101.092058, 5.930678],
          [101.091561, 5.929214],
          [101.091542, 5.928984],
          [101.091943, 5.927555],
          [101.092039, 5.926955],
          [101.091867, 5.92639],
          [101.091504, 5.925755],
          [101.09137, 5.925667],
          [101.090376, 5.926055],
          [101.089592, 5.926231],
          [101.089076, 5.926196],
          [101.088694, 5.925984],
          [101.088426, 5.925931],
          [101.088159, 5.925931],
          [101.087891, 5.926002],
          [101.087777, 5.925984],
          [101.0877, 5.925878],
          [101.087757, 5.92489],
          [101.087738, 5.924484],
          [101.087662, 5.924043],
          [101.087452, 5.923337],
          [101.087509, 5.923143],
          [101.08856, 5.921943],
          [101.088923, 5.921625],
          [101.089095, 5.921378],
          [101.089344, 5.920725],
          [101.089401, 5.920319],
          [101.089267, 5.920072],
          [101.088981, 5.919808],
          [101.088885, 5.919649],
          [101.088866, 5.919437],
          [101.088943, 5.919314],
          [101.089, 5.919084],
          [101.088981, 5.918819],
          [101.088293, 5.91792],
          [101.087968, 5.917603],
          [101.087241, 5.917232],
          [101.087108, 5.917091],
          [101.086993, 5.916862],
          [101.086936, 5.916438],
          [101.086974, 5.916015],
          [101.087184, 5.915503],
          [101.087337, 5.915326],
          [101.087433, 5.914938],
          [101.087375, 5.914638],
          [101.087108, 5.91402],
          [101.087069, 5.913809],
          [101.087031, 5.913562],
          [101.087127, 5.913085],
          [101.08705, 5.912873],
          [101.086725, 5.912309],
          [101.08663, 5.911815],
          [101.08663, 5.911532],
          [101.086802, 5.910897],
          [101.086725, 5.910773],
          [101.086477, 5.910632],
          [101.085923, 5.910526],
          [101.08577, 5.910403],
          [101.085215, 5.90982],
          [101.085024, 5.90915],
          [101.08489, 5.90915],
          [101.084699, 5.909273],
          [101.084489, 5.909573],
          [101.084279, 5.909767],
          [101.084049, 5.910067],
          [101.083992, 5.910297],
          [101.083686, 5.910614],
          [101.082272, 5.911638],
          [101.081679, 5.912608],
          [101.080914, 5.913402],
          [101.08057, 5.913844],
          [101.080379, 5.914232],
          [101.080073, 5.915096],
          [101.079787, 5.915396],
          [101.0795, 5.915608],
          [101.079041, 5.915785],
          [101.078755, 5.915996],
          [101.078544, 5.916279],
          [101.078277, 5.916508],
          [101.077264, 5.917179],
          [101.076079, 5.917691],
          [101.075047, 5.918273],
          [101.074168, 5.918326],
          [101.073805, 5.918273],
          [101.073499, 5.918097],
          [101.072868, 5.917514],
          [101.072696, 5.917479],
          [101.071798, 5.917744],
          [101.071626, 5.917744],
          [101.071186, 5.917567],
          [101.070976, 5.917426],
          [101.070708, 5.917391],
          [101.070039, 5.917567],
          [101.069715, 5.917762],
          [101.069428, 5.917991],
          [101.068778, 5.918662],
          [101.068529, 5.91882],
          [101.068319, 5.918873],
          [101.067383, 5.918873],
          [101.066943, 5.918697],
          [101.066561, 5.918609],
          [101.066217, 5.918556],
          [101.065529, 5.918556],
          [101.06528, 5.918662],
          [101.064267, 5.919526],
          [101.063827, 5.919703],
          [101.063426, 5.919738],
          [101.062165, 5.91965],
          [101.061763, 5.919597],
          [101.061572, 5.919456],
          [101.0614, 5.919032],
          [101.061171, 5.918873],
          [101.060635, 5.918203],
          [101.060253, 5.917162],
          [101.060215, 5.916862],
          [101.060349, 5.916332],
          [101.06033, 5.91612],
          [101.060158, 5.916032],
          [101.059871, 5.915926],
          [101.059431, 5.915891],
          [101.058475, 5.915961],
          [101.057997, 5.91605],
          [101.057214, 5.916367],
          [101.056832, 5.916385],
          [101.056124, 5.916173],
          [101.055933, 5.915802],
          [101.055991, 5.915591],
          [101.056163, 5.915432],
          [101.056277, 5.915255],
          [101.056277, 5.915079],
          [101.056182, 5.914885],
          [101.056029, 5.914708],
          [101.055799, 5.914532],
          [101.055494, 5.913879],
          [101.055417, 5.913367],
          [101.055417, 5.911708],
          [101.055341, 5.911514],
          [101.055188, 5.91132],
          [101.05492, 5.911232],
          [101.054614, 5.911197],
          [101.054156, 5.911267],
          [101.053926, 5.911214],
          [101.053716, 5.911108],
          [101.05362, 5.910879],
          [101.053276, 5.910403],
          [101.053066, 5.910279],
          [101.052454, 5.910332],
          [101.051346, 5.910103],
          [101.051097, 5.910155],
          [101.050964, 5.910226],
          [101.050792, 5.910667],
          [101.050543, 5.910932],
          [101.050084, 5.911232],
          [101.049434, 5.911444],
          [101.049243, 5.911585],
          [101.048842, 5.912061],
          [101.048498, 5.912255],
          [101.04802, 5.91245],
          [101.047179, 5.912626],
          [101.046835, 5.912802],
          [101.046548, 5.913173],
          [101.046204, 5.913808],
          [101.045994, 5.914514],
          [101.04565, 5.915079],
          [101.04521, 5.915608],
          [101.04479, 5.915961],
          [101.044465, 5.916138],
          [101.044083, 5.916155],
          [101.043815, 5.915838],
          [101.043395, 5.915838],
          [101.042859, 5.91605],
          [101.042152, 5.916191],
          [101.041502, 5.916402],
          [101.039667, 5.917267],
          [101.038597, 5.917426],
          [101.037068, 5.918044],
          [101.036494, 5.918044],
          [101.036246, 5.917744],
          [101.036036, 5.917567],
          [101.035558, 5.917426],
          [101.035328, 5.916985],
          [101.034965, 5.916614],
          [101.03443, 5.916332],
          [101.034239, 5.916138],
          [101.03401, 5.916032],
          [101.033474, 5.915926],
          [101.033264, 5.915714],
          [101.033016, 5.915308],
          [101.032653, 5.914867],
          [101.03141, 5.914514],
          [101.031028, 5.914514],
          [101.029403, 5.914902],
          [101.028639, 5.91485],
          [101.028142, 5.914761],
          [101.027893, 5.914673],
          [101.027224, 5.914108],
          [101.026976, 5.913791],
          [101.025848, 5.912997],
          [101.025714, 5.912785],
          [101.025791, 5.912591],
          [101.025829, 5.912167],
          [101.025542, 5.91155],
          [101.025542, 5.911373],
          [101.025638, 5.911232],
          [101.026039, 5.911108],
          [101.026173, 5.910897],
          [101.026307, 5.910491],
          [101.026804, 5.910385],
          [101.026957, 5.910261],
          [101.027071, 5.910085],
          [101.02711, 5.909891],
          [101.026746, 5.909361],
          [101.026957, 5.908673],
          [101.026823, 5.908056],
          [101.026766, 5.907509],
          [101.026651, 5.907209],
          [101.026498, 5.906979],
          [101.026479, 5.90675],
          [101.026537, 5.906609],
          [101.02669, 5.906485],
          [101.027053, 5.906344],
          [101.027378, 5.905956],
          [101.027569, 5.905938],
          [101.027798, 5.906115],
          [101.028066, 5.906027],
          [101.028429, 5.905832],
          [101.028811, 5.905479],
          [101.029079, 5.904685],
          [101.029193, 5.904138],
          [101.029232, 5.903044],
          [101.029308, 5.902656],
          [101.02948, 5.902338],
          [101.029499, 5.902074],
          [101.029442, 5.901827],
          [101.029289, 5.901615],
          [101.028926, 5.901403],
          [101.028754, 5.900609],
          [101.028773, 5.90045],
          [101.028295, 5.899991],
          [101.027779, 5.899727],
          [101.027091, 5.899233],
          [101.026842, 5.899144],
          [101.025829, 5.899056],
          [101.025504, 5.898985],
          [101.025256, 5.898774],
          [101.024912, 5.89798],
          [101.024759, 5.89775],
          [101.024319, 5.897291],
          [101.024128, 5.896674],
          [101.023937, 5.896356],
          [101.023803, 5.896003],
          [101.023612, 5.895738],
          [101.023498, 5.895262],
          [101.023478, 5.89498],
          [101.023478, 5.894397],
          [101.023555, 5.893868],
          [101.023478, 5.893586],
          [101.022943, 5.89228],
          [101.022466, 5.891627],
          [101.02237, 5.891415],
          [101.021567, 5.891115],
          [101.021433, 5.890991],
          [101.0213, 5.89078],
          [101.020994, 5.890586],
          [101.020268, 5.890674],
          [101.019943, 5.890639],
          [101.019771, 5.890568],
          [101.019292, 5.890038],
          [101.018948, 5.889262],
          [101.018834, 5.888891],
          [101.018719, 5.888697],
          [101.01849, 5.88845],
          [101.018394, 5.888203],
          [101.018451, 5.887956],
          [101.018604, 5.887709],
          [101.018776, 5.88755],
          [101.018891, 5.887339],
          [101.019082, 5.886756],
          [101.019101, 5.886474],
          [101.018623, 5.88575],
          [101.017725, 5.885292],
          [101.0174, 5.885009],
          [101.016597, 5.884674],
          [101.016158, 5.884427],
          [101.015374, 5.883915],
          [101.01526, 5.883633],
          [101.014591, 5.882945],
          [101.014208, 5.882415],
          [101.014151, 5.88201],
          [101.013922, 5.881551],
          [101.013941, 5.881374],
          [101.014036, 5.880986],
          [101.014304, 5.880316],
          [101.014533, 5.879398],
          [101.014591, 5.878921],
          [101.014399, 5.878357],
          [101.014304, 5.876857],
          [101.014132, 5.876416],
          [101.013826, 5.875869],
          [101.013406, 5.875322],
          [101.013042, 5.874563],
          [101.01245, 5.874174],
          [101.011991, 5.873769],
          [101.011781, 5.873751],
          [101.011265, 5.873857],
          [101.010883, 5.873874],
          [101.010634, 5.873822],
          [101.010386, 5.873892],
          [101.010003, 5.874157],
          [101.009698, 5.87421],
          [101.009124, 5.87391],
          [101.00899, 5.873733],
          [101.008971, 5.873627],
          [101.009028, 5.873433],
          [101.009334, 5.87278],
          [101.009583, 5.87188],
          [101.009583, 5.871651],
          [101.00943, 5.870663],
          [101.009411, 5.870257],
          [101.009487, 5.869886],
          [101.009716, 5.869357],
          [101.010194, 5.868545],
          [101.010194, 5.867945],
          [101.009774, 5.867398],
          [101.009659, 5.867116],
          [101.009449, 5.866834],
          [101.009391, 5.866675],
          [101.009525, 5.865969],
          [101.009774, 5.865422],
          [101.009946, 5.865263],
          [101.010022, 5.865087],
          [101.01006, 5.864822],
          [101.009946, 5.864487],
          [101.009869, 5.863993],
          [101.009869, 5.863569],
          [101.009965, 5.86281],
          [101.009927, 5.862404],
          [101.009869, 5.862122],
          [101.009736, 5.861875],
          [101.009143, 5.861133],
          [101.009124, 5.860939],
          [101.009181, 5.860639],
          [101.009544, 5.859969],
          [101.009583, 5.859775],
          [101.009506, 5.859528],
          [101.009449, 5.859104],
          [101.00943, 5.858557],
          [101.009487, 5.858257],
          [101.009908, 5.857516],
          [101.009927, 5.857216],
          [101.009774, 5.856987],
          [101.009411, 5.85681],
          [101.009219, 5.856652],
          [101.008531, 5.855593],
          [101.008264, 5.85531],
          [101.008015, 5.854887],
          [101.007557, 5.853952],
          [101.007595, 5.853422],
          [101.007346, 5.852822],
          [101.007136, 5.852611],
          [101.006219, 5.851905],
          [101.006066, 5.851658],
          [101.006085, 5.851428],
          [101.006352, 5.850864],
          [101.006524, 5.850122],
          [101.007136, 5.848534],
          [101.007977, 5.847775],
          [101.008455, 5.847175],
          [101.009372, 5.845569],
          [101.009468, 5.845287],
          [101.00943, 5.844775],
          [101.009162, 5.844175],
          [101.008952, 5.843487],
          [101.008875, 5.843222],
          [101.008914, 5.843064],
          [101.009028, 5.842817],
          [101.009391, 5.84234],
          [101.009449, 5.841581],
          [101.009869, 5.840646],
          [101.010729, 5.839464],
          [101.011609, 5.838476],
          [101.011934, 5.838175],
          [101.01201, 5.838034],
          [101.012029, 5.837875],
          [101.011915, 5.837487],
          [101.01159, 5.837293],
          [101.011379, 5.837099],
          [101.010806, 5.836464],
          [101.010577, 5.836393],
          [101.010366, 5.836375],
          [101.010137, 5.836464],
          [101.009716, 5.836817],
          [101.009296, 5.836834],
          [101.009143, 5.836728],
          [101.00899, 5.836534],
          [101.008914, 5.836305],
          [101.008818, 5.835934],
          [101.008761, 5.835264],
          [101.008302, 5.83477],
          [101.008092, 5.834399],
          [101.008054, 5.834152],
          [101.007939, 5.833905],
          [101.007576, 5.833358],
          [101.006811, 5.831681],
          [101.006601, 5.83147],
          [101.005167, 5.830975],
          [101.004594, 5.829917],
          [101.004327, 5.829564],
          [101.004021, 5.829299],
          [101.00339, 5.829141],
          [101.002014, 5.828999],
          [101.001784, 5.828929],
          [101.001632, 5.828823],
          [101.00146, 5.828258],
          [101.001498, 5.827923],
          [101.001593, 5.827552],
          [101.001804, 5.827129],
          [101.001804, 5.826864],
          [101.001976, 5.826106],
          [101.002434, 5.825347],
          [101.00232, 5.824659],
          [101.002052, 5.824429],
          [101.001479, 5.824076],
          [101.001096, 5.8239],
          [101.000141, 5.823582],
          [100.99972, 5.823212],
          [100.999529, 5.822912],
          [100.99951, 5.822488],
          [100.999587, 5.820653],
          [100.999682, 5.819364],
          [100.999663, 5.819011],
          [100.99951, 5.818623],
          [100.999281, 5.818217],
          [100.999051, 5.818041],
          [100.99823, 5.817882],
          [100.997656, 5.817706],
          [100.996643, 5.817176],
          [100.99649, 5.817053],
          [100.996299, 5.816841],
          [100.996165, 5.816382],
          [100.995955, 5.816206],
          [100.995726, 5.8161],
          [100.995401, 5.815764],
          [100.994005, 5.814035],
          [100.99305, 5.813029],
          [100.992228, 5.811971],
          [100.991998, 5.811759],
          [100.990259, 5.81093],
          [100.987354, 5.810877],
          [100.986647, 5.810771],
          [100.98592, 5.809977],
          [100.9855, 5.809782],
          [100.984754, 5.809782],
          [100.98441, 5.809571],
          [100.984181, 5.808971],
          [100.98399, 5.807188],
          [100.984009, 5.806977],
          [100.984124, 5.806783],
          [100.984238, 5.806306],
          [100.985041, 5.805071],
          [100.985175, 5.804753],
          [100.985213, 5.804506],
          [100.985098, 5.8041],
          [100.98462, 5.803659],
          [100.984525, 5.803465],
          [100.984525, 5.8032],
          [100.984582, 5.802953],
          [100.984869, 5.802548],
          [100.98527, 5.802195],
          [100.985748, 5.801948],
          [100.98657, 5.80163],
          [100.986991, 5.801365],
          [100.987143, 5.801171],
          [100.987316, 5.800818],
          [100.987316, 5.800606],
          [100.98722, 5.800377],
          [100.987124, 5.799848],
          [100.987105, 5.799142],
          [100.98722, 5.798824],
          [100.987507, 5.798418],
          [100.987927, 5.797977],
          [100.988367, 5.797412],
          [100.988653, 5.796883],
          [100.988806, 5.796442],
          [100.988806, 5.795083],
          [100.988692, 5.794554],
          [100.988348, 5.794042],
          [100.988214, 5.793724],
          [100.98829, 5.793512],
          [100.988653, 5.792948],
          [100.989131, 5.790054],
          [100.989342, 5.789348],
          [100.989418, 5.788907],
          [100.989437, 5.788501],
          [100.989322, 5.788201],
          [100.98938, 5.787671],
          [100.98938, 5.78663],
          [100.989246, 5.785978],
          [100.98894, 5.785289],
          [100.989074, 5.785113],
          [100.989227, 5.784795],
          [100.989456, 5.78393],
          [100.9894814, 5.7839133],
          [100.9887791, 5.7827515],
          [100.9882722, 5.7820228],
          [100.9876988, 5.7809972],
          [100.9872793, 5.7804087],
          [100.9866332, 5.779786],
          [100.9860322, 5.7790504],
          [100.9856144, 5.7783482],
          [100.9850285, 5.7777132],
          [100.9845311, 5.7773657],
          [100.9839958, 5.7766583],
          [100.9836363, 5.7761101],
          [100.9830489, 5.7754562],
          [100.9822551, 5.775043],
          [100.9814864, 5.7749263],
          [100.9808831, 5.7750264],
          [100.9800981, 5.7752553],
          [100.9798978, 5.7755218],
          [100.9796404, 5.7756494],
          [100.9791693, 5.7757043],
          [100.9790009, 5.7753631],
          [100.978918, 5.7749616],
          [100.9787079, 5.7744386],
          [100.97836, 5.7740293],
          [100.9779349, 5.7735546],
          [100.9774353, 5.7732262],
          [100.9769505, 5.7730289],
          [100.9765915, 5.7730251],
          [100.976195, 5.7730699],
          [100.9757436, 5.7731799],
          [100.9754455, 5.7734509],
          [100.9750937, 5.7737566],
          [100.9747418, 5.7741078],
          [100.9742889, 5.7746036],
          [100.9736879, 5.7750892],
          [100.9733418, 5.7753362],
          [100.9730324, 5.7755411],
          [100.97194, 5.7760317],
          [100.9714867, 5.7761949],
          [100.9710336, 5.7762962],
          [100.9706567, 5.7759955],
          [100.9703112, 5.7754801],
          [100.970106, 5.7751949],
          [100.9698833, 5.7749716],
          [100.9695694, 5.774741],
          [100.9692348, 5.7747579],
          [100.9688902, 5.7748361],
          [100.9686282, 5.7749365],
          [100.9683607, 5.7750247],
          [100.9677872, 5.7751448],
          [100.9676888, 5.7751417],
          [100.9671725, 5.7751838],
          [100.9666746, 5.7752046],
          [100.9662387, 5.7752301],
          [100.9655407, 5.7751906],
          [100.9648443, 5.7750985],
          [100.9643672, 5.7750842],
          [100.963919, 5.7750752],
          [100.9637468, 5.7751416],
          [100.9632763, 5.7755179],
          [100.9629236, 5.7759939],
          [100.9624917, 5.7764302],
          [100.9622683, 5.7766226],
          [100.9619261, 5.7768295],
          [100.9616737, 5.7768583],
          [100.9612284, 5.7768479],
          [100.9608254, 5.7766361],
          [100.9603334, 5.7764431],
          [100.960052, 5.77624],
          [100.9598042, 5.7758032],
          [100.9597158, 5.7756245],
          [100.9595538, 5.7752819],
          [100.9593512, 5.7749112],
          [100.9592738, 5.7747456],
          [100.9590024, 5.7743999],
          [100.9587166, 5.7741441],
          [100.9584161, 5.7738503],
          [100.9578492, 5.7734837],
          [100.9573791, 5.7732815],
          [100.957023, 5.7732206],
          [100.956626, 5.7731709],
          [100.9562962, 5.7730841],
          [100.9558766, 5.7730601],
          [100.955439, 5.7729839],
          [100.9554357, 5.7729556],
          [100.9550498, 5.7725156],
          [100.9544829, 5.772008],
          [100.9533786, 5.7714965],
          [100.9530875, 5.7713689],
          [100.9524935, 5.7708052],
          [100.9516391, 5.7701586],
          [100.9509866, 5.7697048],
          [100.9496933, 5.7691095],
          [100.9486294, 5.768797],
          [100.9476635, 5.7685094],
          [100.9467986, 5.7681866],
          [100.9460331, 5.7677156],
          [100.9451537, 5.7671634],
          [100.9446432, 5.766655],
          [100.9443306, 5.7660685],
          [100.9441168, 5.7655522],
          [100.943918, 5.7653028],
          [100.9429709, 5.7644731],
          [100.9423413, 5.7640529],
          [100.9419791, 5.7636741],
          [100.941711, 5.763309],
          [100.9416675, 5.7628806],
          [100.941872, 5.7627273],
          [100.942735, 5.7623765],
          [100.9435988, 5.7618113],
          [100.9443742, 5.7613714],
          [100.945436, 5.7607206],
          [100.9457064, 5.7605811],
          [100.9463012, 5.7601925],
          [100.9464654, 5.7599905],
          [100.9475029, 5.7581585],
          [100.9481253, 5.757112],
          [100.9493309, 5.7554396],
          [100.949855, 5.7546574],
          [100.9500638, 5.7542233],
          [100.9502009, 5.7539225],
          [100.9502382, 5.7535659],
          [100.9500097, 5.752561],
          [100.9496469, 5.7516423],
          [100.9492751, 5.7505275],
          [100.9491016, 5.7495695],
          [100.9487902, 5.7485695],
          [100.9484322, 5.7470071],
          [100.9482324, 5.7455549],
          [100.9482128, 5.7447259],
          [100.9482497, 5.7442601],
          [100.9484011, 5.7438581],
          [100.9485767, 5.7436489],
          [100.9494727, 5.7432434],
          [100.9506864, 5.7427725],
          [100.9516532, 5.7423881],
          [100.952586, 5.7420064],
          [100.9535158, 5.7417225],
          [100.9541146, 5.741564],
          [100.9544492, 5.741322],
          [100.9549956, 5.7406686],
          [100.9555619, 5.7400459],
          [100.9577295, 5.7371927],
          [100.9586374, 5.7353098],
          [100.9589259, 5.7343082],
          [100.9591222, 5.732462],
          [100.9591985, 5.731085],
          [100.9593575, 5.7307097],
          [100.9594973, 5.7306525],
          [100.960117, 5.7306844],
          [100.9605491, 5.7305347],
          [100.9612777, 5.7298471],
          [100.9618622, 5.7292093],
          [100.9622041, 5.7288687],
          [100.9626117, 5.7286865],
          [100.9629946, 5.7286642],
          [100.9638525, 5.7287765],
          [100.9643311, 5.7288343],
          [100.9647785, 5.72885],
          [100.965422, 5.7287403],
          [100.9665493, 5.7283138],
          [100.9666726, 5.7282312],
          [100.9688532, 5.7273104],
          [100.9706309, 5.7268128],
          [100.972073, 5.726328],
          [100.9730736, 5.7259718],
          [100.9734102, 5.7258073],
          [100.9737389, 5.7254916],
          [100.9741701, 5.7248219],
          [100.9754334, 5.7224637],
          [100.9787147, 5.7224056],
          [100.9799794, 5.7223248],
          [100.9809414, 5.7222931],
          [100.9810557, 5.7222859],
          [100.9811092, 5.7222327],
          [100.9811325, 5.7221184],
          [100.9805107, 5.7198221],
          [100.9779035, 5.7168308],
          [100.9775116, 5.7160499],
          [100.9775055, 5.7156843],
          [100.9781472, 5.7136961],
          [100.9771474, 5.7129544],
          [100.9766764, 5.7124988],
          [100.976594, 5.712247],
          [100.9765174, 5.7109271],
          [100.9766874, 5.7103512],
          [100.9769755, 5.7101231],
          [100.9783813, 5.7090979],
          [100.9785094, 5.7087735],
          [100.9785417, 5.7083565],
          [100.9786091, 5.7076998],
          [100.977725, 5.7055779],
          [100.9773661, 5.704912],
          [100.9770265, 5.7045771],
          [100.9769237, 5.7043642],
          [100.9767725, 5.7029279],
          [100.9767394, 5.7022921],
          [100.9767865, 5.7018705],
          [100.9767613, 5.7012419],
          [100.9767089, 5.7010525],
          [100.9754527, 5.699854],
          [100.9753549, 5.6995956],
          [100.9748172, 5.6982496],
          [100.9747101, 5.6978974],
          [100.9740395, 5.6967932],
          [100.9736821, 5.6964102],
          [100.972915, 5.695655],
          [100.9733804, 5.6928522],
          [100.9731213, 5.6926703],
          [100.9731363, 5.6921018],
          [100.9732732, 5.69167],
          [100.9735448, 5.6913792],
          [100.9742611, 5.6907943],
          [100.9744578, 5.6904969],
          [100.9747402, 5.6899785],
          [100.9750391, 5.6896134],
          [100.9754418, 5.6887633],
          [100.9754937, 5.6885251],
          [100.9753007, 5.6878516],
          [100.9751911, 5.687328],
          [100.9752435, 5.6869534],
          [100.9754156, 5.686375],
          [100.9756044, 5.6858988],
          [100.9757777, 5.6850223],
          [100.9765134, 5.6837732],
          [100.9759099, 5.6837624],
          [100.9746427, 5.6838854],
          [100.9742258, 5.6839691],
          [100.9736648, 5.683967],
          [100.9729612, 5.6838731],
          [100.9721134, 5.6833992],
          [100.9715192, 5.6827994],
          [100.971034, 5.6820281],
          [100.9708374, 5.6814117],
          [100.9708129, 5.6807055],
          [100.9708384, 5.6800535],
          [100.9709631, 5.6793206],
          [100.9710146, 5.6785874],
          [100.970992, 5.6781228],
          [100.9709122, 5.6776988],
          [100.9707422, 5.6774619],
          [100.9703208, 5.6770529],
          [100.9697447, 5.6766515],
          [100.9689321, 5.6764284],
          [100.9680652, 5.6764479],
          [100.9675163, 5.6750048],
          [100.9670984, 5.6742781],
          [100.9671834, 5.6741348],
          [100.9679133, 5.6736069],
          [100.9683347, 5.6733816],
          [100.9686624, 5.6730081],
          [100.9689847, 5.6725981],
          [100.9693098, 5.6721102],
          [100.9696354, 5.6715483],
          [100.9697128, 5.6714028],
          [100.9698152, 5.6711662],
          [100.9697312, 5.6708997],
          [100.9692396, 5.6696947],
          [100.9692233, 5.6696065],
          [100.9691804, 5.6693167],
          [100.9692202, 5.6690933],
          [100.969431, 5.668622],
          [100.9695853, 5.6680831],
          [100.9696115, 5.6676441],
          [100.9696698, 5.6672326],
          [100.9696461, 5.666798],
          [100.9696796, 5.666358],
          [100.9695493, 5.6661623],
          [100.9693267, 5.665984],
          [100.9689411, 5.6658264],
          [100.9685917, 5.6654844],
          [100.9683888, 5.6651073],
          [100.9681969, 5.6642983],
          [100.9680743, 5.6637662],
          [100.9678265, 5.6634018],
          [100.967719, 5.6632061],
          [100.9675738, 5.6629343],
          [100.9674978, 5.6627852],
          [100.9673912, 5.6623454],
          [100.9671878, 5.6617044],
          [100.966826, 5.6614047],
          [100.9666909, 5.6613337],
          [100.9665744, 5.6612805],
          [100.9662275, 5.6613552],
          [100.9659291, 5.6614924],
          [100.9658071, 5.6615516],
          [100.9654411, 5.661675],
          [100.965227, 5.6617258],
          [100.9650513, 5.6616383],
          [100.9650761, 5.6615164],
          [100.9652639, 5.6612567],
          [100.9654733, 5.6611209],
          [100.9655733, 5.6610598],
          [100.9656168, 5.6610193],
          [100.9657064, 5.660941],
          [100.9659456, 5.6607373],
          [100.9661993, 5.6604678],
          [100.9662169, 5.6603701],
          [100.9662293, 5.6601228],
          [100.9662556, 5.6596717],
          [100.966118, 5.6590897],
          [100.9661302, 5.6586913],
          [100.9661421, 5.658379],
          [100.9664471, 5.6573141],
          [100.9664818, 5.6566573],
          [100.9661864, 5.6551486],
          [100.9664481, 5.6537704],
          [100.9664387, 5.6526829],
          [100.9663069, 5.6504175],
          [100.9659132, 5.6484829],
          [100.9651876, 5.6474186],
          [100.9645665, 5.6466339],
          [100.9637824, 5.6464312],
          [100.9624206, 5.6466189],
          [100.9619896, 5.6470684],
          [100.960468, 5.6482195],
          [100.9601465, 5.6481379],
          [100.9599377, 5.6480364],
          [100.9596695, 5.647692],
          [100.9589917, 5.6464271],
          [100.9583389, 5.6448599],
          [100.9578809, 5.6434903],
          [100.9568847, 5.6426014],
          [100.956611, 5.6423531],
          [100.955927, 5.6421569],
          [100.9552236, 5.6421569],
          [100.9547216, 5.6421986],
          [100.9542352, 5.6422506],
          [100.9535601, 5.6424837],
          [100.9528812, 5.642653],
          [100.9524585, 5.6429501],
          [100.9521912, 5.6433888],
          [100.9518454, 5.6439001],
          [100.9514076, 5.6450733],
          [100.9512241, 5.6455899],
          [100.9509081, 5.6460956],
          [100.9503648, 5.6466414],
          [100.949704, 5.6473],
          [100.9486849, 5.6483235],
          [100.9482586, 5.648436],
          [100.947805, 5.6482823],
          [100.9473906, 5.6480923],
          [100.9469542, 5.6477226],
          [100.9465574, 5.6476371],
          [100.9460783, 5.6476849],
          [100.9452421, 5.6479316],
          [100.9448276, 5.6482395],
          [100.943739, 5.6485467],
          [100.9437494, 5.6485951],
          [100.9432591, 5.648519],
          [100.9429861, 5.6484261],
          [100.9424758, 5.6485814],
          [100.9422282, 5.6487209],
          [100.9421732, 5.6489958],
          [100.9422408, 5.6493689],
          [100.9425301, 5.6499211],
          [100.9425049, 5.6501905],
          [100.9422737, 5.6502912],
          [100.941917, 5.6501203],
          [100.9417242, 5.6499207],
          [100.9414414, 5.6492889],
          [100.9412963, 5.648879],
          [100.9411662, 5.6483436],
          [100.9410681, 5.6479648],
          [100.9406599, 5.6477518],
          [100.9400307, 5.6475596],
          [100.9392331, 5.6471325],
          [100.9387409, 5.6468378],
          [100.9385245, 5.6466207],
          [100.9383855, 5.6463122],
          [100.9383835, 5.6460294],
          [100.9385833, 5.6457193],
          [100.9387503, 5.6455546],
          [100.9390187, 5.6453837],
          [100.9393538, 5.6451334],
          [100.939573, 5.6449579],
          [100.9397269, 5.6446997],
          [100.9398943, 5.6442897],
          [100.9401165, 5.6439141],
          [100.9403859, 5.6436979],
          [100.940743, 5.6433746],
          [100.9410818, 5.6430599],
          [100.9413452, 5.6428063],
          [100.9415386, 5.6425155],
          [100.9416926, 5.6420724],
          [100.9419241, 5.6415378],
          [100.9421269, 5.6405901],
          [100.9421811, 5.6402397],
          [100.9420922, 5.6399509],
          [100.941888, 5.6397482],
          [100.9417837, 5.6388906],
          [100.9418951, 5.6385519],
          [100.9420608, 5.6382401],
          [100.9423642, 5.6380256],
          [100.9428047, 5.6378368],
          [100.9432625, 5.6376847],
          [100.9435647, 5.6375118],
          [100.9437468, 5.6370972],
          [100.9437201, 5.636465],
          [100.9437983, 5.6355738],
          [100.9439927, 5.6346114],
          [100.9441048, 5.6341595],
          [100.9442275, 5.6333826],
          [100.9444078, 5.6328737],
          [100.9446784, 5.6324274],
          [100.9451259, 5.6316044],
          [100.9452979, 5.6312092],
          [100.9456705, 5.630768],
          [100.945859, 5.6305831],
          [100.9464845, 5.630153],
          [100.9471303, 5.6296839],
          [100.947336, 5.6293471],
          [100.9474401, 5.6290126],
          [100.9473882, 5.6286498],
          [100.9472645, 5.6284083],
          [100.9468564, 5.6282594],
          [100.9460621, 5.6283263],
          [100.9454943, 5.6280889],
          [100.9449652, 5.6277437],
          [100.9446912, 5.6272585],
          [100.9445026, 5.6268775],
          [100.9441208, 5.6259123],
          [100.9437306, 5.6253737],
          [100.9432914, 5.6249072],
          [100.9426917, 5.6248314],
          [100.9422249, 5.6248969],
          [100.9416913, 5.6249958],
          [100.9411696, 5.6250321],
          [100.9406843, 5.6248502],
          [100.9395258, 5.6238787],
          [100.9389811, 5.6236066],
          [100.9384434, 5.6234282],
          [100.93706, 5.6234258],
          [100.9364582, 5.6234007],
          [100.93585, 5.6231208],
          [100.9353361, 5.6226503],
          [100.9349296, 5.6224051],
          [100.9338043, 5.6215311],
          [100.9334609, 5.6214022],
          [100.930952, 5.6214136],
          [100.9288017, 5.6198121],
          [100.9267493, 5.6189003],
          [100.9260031, 5.6185411],
          [100.9253913, 5.6182275],
          [100.9246854, 5.6180633],
          [100.9244877, 5.6179463],
          [100.924284, 5.6175071],
          [100.9242089, 5.6167277],
          [100.9243549, 5.6158368],
          [100.924696, 5.6148083],
          [100.9249344, 5.6141613],
          [100.9249416, 5.613371],
          [100.9247604, 5.6129169],
          [100.9245421, 5.6124065],
          [100.9244528, 5.6123125],
          [100.924056, 5.6115578],
          [100.9235668, 5.610593],
          [100.923287, 5.6095953],
          [100.9227625, 5.6081396],
          [100.9228814, 5.606867],
          [100.9227473, 5.6056032],
          [100.9226603, 5.6038738],
          [100.9220244, 5.6029717],
          [100.9221027, 5.6027764],
          [100.9225499, 5.6023283],
          [100.9232085, 5.601976],
          [100.9237849, 5.60184],
          [100.9243535, 5.6017826],
          [100.9254032, 5.6019708],
          [100.9257771, 5.6019837],
          [100.9261404, 5.6018242],
          [100.9268848, 5.6008873],
          [100.9275411, 5.6004332],
          [100.9287993, 5.5991503],
          [100.9293781, 5.5984711],
          [100.9296271, 5.5979515],
          [100.9297012, 5.5972701],
          [100.9296858, 5.5965134],
          [100.9297584, 5.594461],
          [100.9298579, 5.5939296],
          [100.9300463, 5.5936157],
          [100.930549, 5.5932172],
          [100.930991, 5.5930431],
          [100.9316716, 5.5930011],
          [100.9322767, 5.5931312],
          [100.9325975, 5.5933086],
          [100.9331464, 5.5934496],
          [100.9337257, 5.5935009],
          [100.9340065, 5.5934197],
          [100.9343068, 5.5931476],
          [100.9344317, 5.5928073],
          [100.9344267, 5.5922417],
          [100.9341681, 5.5915363],
          [100.9340944, 5.5912775],
          [100.9341522, 5.5908807],
          [100.9344155, 5.590526],
          [100.9347946, 5.5901981],
          [100.9352628, 5.5899904],
          [100.936426, 5.5898869],
          [100.9369341, 5.589979],
          [100.9382581, 5.5907115],
          [100.9388482, 5.5908602],
          [100.9407325, 5.5908795],
          [100.9412604, 5.590706],
          [100.9415534, 5.5903927],
          [100.9417988, 5.5898394],
          [100.9423076, 5.5889164],
          [100.9424855, 5.5884265],
          [100.9424598, 5.5881855],
          [100.9423045, 5.5879238],
          [100.9421318, 5.5875842],
          [100.9420125, 5.5871583],
          [100.9421638, 5.5867395],
          [100.9428783, 5.5858137],
          [100.943213, 5.5853544],
          [100.9433569, 5.5849393],
          [100.9434319, 5.5831791],
          [100.9436248, 5.582678],
          [100.943839, 5.5824204],
          [100.9443899, 5.5821195],
          [100.9448734, 5.5818631],
          [100.9453652, 5.5813896],
          [100.9455761, 5.5810346],
          [100.9456416, 5.5805929],
          [100.9455537, 5.580173],
          [100.9453939, 5.5799551],
          [100.9450506, 5.5798075],
          [100.9437871, 5.5797719],
          [100.9432864, 5.5797061],
          [100.9428273, 5.5795317],
          [100.9423396, 5.5790576],
          [100.9411669, 5.5770822],
          [100.9406096, 5.5762818],
          [100.940066, 5.5757663],
          [100.9395699, 5.5754944],
          [100.9386033, 5.575123],
          [100.9379056, 5.5747791],
          [100.9369839, 5.5743892],
          [100.9364991, 5.5741136],
          [100.9361158, 5.5737074],
          [100.9355989, 5.5730683],
          [100.935257, 5.5726023],
          [100.93458, 5.571839],
          [100.9344881, 5.5714864],
          [100.9344716, 5.5701304],
          [100.9343417, 5.5690623],
          [100.9340429, 5.5681395],
          [100.933875, 5.5672285],
          [100.9338957, 5.5659138],
          [100.9336765, 5.5638976],
          [100.9338129, 5.5634974],
          [100.9352012, 5.5614995],
          [100.9362301, 5.5604702],
          [100.9370177, 5.5598856],
          [100.9372358, 5.5596019],
          [100.9373248, 5.5589131],
          [100.9374916, 5.5583856],
          [100.9377513, 5.558001],
          [100.9379887, 5.5575713],
          [100.9380912, 5.5572046],
          [100.9382872, 5.5570558],
          [100.9401611, 5.5553991],
          [100.9405621, 5.5546068],
          [100.9406696, 5.5541282],
          [100.9406966, 5.5524327],
          [100.941081, 5.5510555],
          [100.9411824, 5.5501167],
          [100.9411924, 5.5485828],
          [100.9413538, 5.5469186],
          [100.9412359, 5.5462207],
          [100.940351, 5.5449862],
          [100.9399756, 5.5445428],
          [100.9402999, 5.5425339],
          [100.9402661, 5.5420087],
          [100.9382391, 5.5377963],
          [100.9374476, 5.537206],
          [100.9356294, 5.5366051],
          [100.9351555, 5.5361809],
          [100.9343285, 5.533335],
          [100.934159, 5.5317719],
          [100.9337761, 5.5297601],
          [100.9343758, 5.5274143],
          [100.9334772, 5.5261416],
          [100.9333536, 5.5255],
          [100.933027, 5.5249567],
          [100.9324687, 5.523847],
          [100.9322328, 5.5228734],
          [100.9322757, 5.5223058],
          [100.9324684, 5.5219697],
          [100.9334853, 5.5211016],
          [100.934477, 5.5202025],
          [100.934544, 5.5194924],
          [100.9344758, 5.5188754],
          [100.9339424, 5.5185395],
          [100.9317209, 5.5168082],
          [100.9306655, 5.5166532],
          [100.9301901, 5.5163797],
          [100.9291054, 5.5149457],
          [100.9289469, 5.5145404],
          [100.9291288, 5.514087],
          [100.9295631, 5.5133073],
          [100.9294074, 5.5127211],
          [100.9292191, 5.5113647],
          [100.9290436, 5.5099673],
          [100.9284448, 5.5078911],
          [100.9282132, 5.5072193],
          [100.9256817, 5.5057406],
          [100.9233737, 5.5040136],
          [100.9230924, 5.503257],
          [100.9220601, 5.5018325],
          [100.9205034, 5.5009733],
          [100.9198108, 5.4989955],
          [100.9193572, 5.4983231],
          [100.9184751, 5.4965949],
          [100.9172874, 5.4953079],
          [100.9151722, 5.4944964],
          [100.9135644, 5.4931754],
          [100.9125979, 5.4932687],
          [100.9119653, 5.4931817],
          [100.9112946, 5.4924789],
          [100.910748, 5.4921953],
          [100.9100485, 5.4923962],
          [100.9092282, 5.4939688],
          [100.9089419, 5.4941884],
          [100.9084818, 5.4942207],
          [100.9079042, 5.4939624],
          [100.9071919, 5.4932656],
          [100.9066567, 5.4926657],
          [100.9051541, 5.4920799],
          [100.9040816, 5.4913588],
          [100.9034112, 5.4907502],
          [100.9030434, 5.4916681],
          [100.9020338, 5.4920199],
          [100.9008159, 5.4923038],
          [100.8998555, 5.4926461],
          [100.8988805, 5.4927692],
          [100.8981281, 5.4923499],
          [100.8976938, 5.4918743],
          [100.8972348, 5.4916654],
          [100.8964598, 5.4918749],
          [100.8956747, 5.4924685],
          [100.8950019, 5.4938089],
          [100.8951918, 5.494909],
          [100.8952486, 5.495944],
          [100.8949915, 5.4963984],
          [100.8940442, 5.4974197],
          [100.8928324, 5.4977453],
          [100.8917906, 5.4973193],
          [100.8908141, 5.4972727],
          [100.890297, 5.4972163],
          [100.8887413, 5.4964325],
          [100.8876186, 5.4961212],
          [100.8866727, 5.4957431],
          [100.8853224, 5.4946264],
          [100.8839527, 5.4938936],
          [100.8834319, 5.4931285],
          [100.8828308, 5.4911663],
          [100.882841, 5.4897472],
          [100.8829145, 5.4891312],
          [100.8830393, 5.4884098],
          [100.8833634, 5.4879948],
          [100.883915, 5.4871214],
          [100.8845261, 5.4859103],
          [100.8845912, 5.4856706],
          [100.8845692, 5.4852786],
          [100.8842659, 5.4848619],
          [100.8837322, 5.4839547],
          [100.8831416, 5.483438],
          [100.8826216, 5.4831448],
          [100.8822109, 5.4830196],
          [100.8818798, 5.4830391],
          [100.8813614, 5.4830321],
          [100.8809147, 5.4828309],
          [100.8774287, 5.4811397],
          [100.8758292, 5.4803498],
          [100.875216, 5.4802053],
          [100.8750165, 5.4800393],
          [100.8746749, 5.4796594],
          [100.8743711, 5.4790846],
          [100.8735483, 5.4776217],
          [100.8727383, 5.4757185],
          [100.8726218, 5.4753584],
          [100.8722257, 5.4750678],
          [100.8717645, 5.4746554],
          [100.8712641, 5.4743212],
          [100.8706057, 5.4738143],
          [100.8702971, 5.4732921],
          [100.8698844, 5.4723535],
          [100.8693826, 5.4713566],
          [100.8688332, 5.470852],
          [100.8682139, 5.4704513],
          [100.86769, 5.4698041],
          [100.8674934, 5.4694988],
          [100.8674288, 5.4691662],
          [100.8672977, 5.4682372],
          [100.8674082, 5.4665225],
          [100.8673441, 5.4648946],
          [100.8672397, 5.4643088],
          [100.8667513, 5.4633912],
          [100.8661741, 5.4625923],
          [100.865887, 5.4621067],
          [100.865637, 5.4616738],
          [100.8656404, 5.4613274],
          [100.8657393, 5.4609002],
          [100.865982, 5.4606116],
          [100.8661328, 5.4604076],
          [100.8665207, 5.4601822],
          [100.8672954, 5.4597803],
          [100.8682653, 5.4595744],
          [100.8687278, 5.4593769],
          [100.8689409, 5.4592458],
          [100.8689591, 5.4590918],
          [100.8687011, 5.4586685],
          [100.8682146, 5.4580258],
          [100.8678853, 5.4575935],
          [100.8678672, 5.4571978],
          [100.8677054, 5.4564791],
          [100.8677144, 5.4544008],
          [100.8678675, 5.452992],
          [100.8676339, 5.4526558],
          [100.8673156, 5.452058],
          [100.8671071, 5.4514174],
          [100.8670186, 5.450975],
          [100.8670626, 5.4504789],
          [100.8671709, 5.4497808],
          [100.8673814, 5.4485012],
          [100.8674589, 5.4482053],
          [100.8674682, 5.4477537],
          [100.8674188, 5.4474176],
          [100.8673404, 5.4470281],
          [100.8673823, 5.4466374],
          [100.8675101, 5.446271],
          [100.8677051, 5.4457404],
          [100.8677436, 5.4455153],
          [100.8677978, 5.4452528],
          [100.8678015, 5.4450759],
          [100.8677832, 5.4448685],
          [100.8677348, 5.4448449],
          [100.8676036, 5.4446501],
          [100.8671913, 5.4442349],
          [100.8666791, 5.4437499],
          [100.8664489, 5.4436134],
          [100.8662309, 5.4432549],
          [100.8661344, 5.4428312],
          [100.8659727, 5.4417472],
          [100.8659384, 5.4410424],
          [100.8659892, 5.4405803],
          [100.866101, 5.4402588],
          [100.8662643, 5.4398141],
          [100.8668029, 5.4390195],
          [100.8670188, 5.4383876],
          [100.8668921, 5.4377901],
          [100.8668808, 5.4363327],
          [100.8670385, 5.4343328],
          [100.8670181, 5.4336847],
          [100.8670142, 5.4331424],
          [100.8667768, 5.432633],
          [100.8665301, 5.4322062],
          [100.8664712, 5.4315987],
          [100.8667674, 5.4309007],
          [100.8669225, 5.4304822],
          [100.866945, 5.4301212],
          [100.866649, 5.4294472],
          [100.866398, 5.4290832],
          [100.8661653, 5.4285855],
          [100.8661398, 5.4280675],
          [100.866314, 5.427494],
          [100.8663732, 5.427035],
          [100.8662042, 5.4264028],
          [100.8659777, 5.4258279],
          [100.8656372, 5.4251377],
          [100.8652393, 5.4245047],
          [100.8648988, 5.4238145],
          [100.8647861, 5.4234123],
          [100.8647262, 5.4226264],
          [100.8647606, 5.4223934],
          [100.8642869, 5.4217832],
          [100.8639773, 5.4214386],
          [100.8636143, 5.4211045],
          [100.8636891, 5.4211048],
          [100.8635043, 5.4209595],
          [100.8632445, 5.4208061],
          [100.8631058, 5.4206923],
          [100.8629733, 5.4205622],
          [100.8629373, 5.4205181],
          [100.8625815, 5.4201732],
          [100.8621433, 5.4197375],
          [100.8616261, 5.4192901],
          [100.8615286, 5.4191634],
          [100.8613349, 5.4189499],
          [100.8611899, 5.4187365],
          [100.8607756, 5.4182974],
          [100.8604042, 5.4179522],
          [100.8601403, 5.4175936],
          [100.8598894, 5.417336],
          [100.8594678, 5.4169663],
          [100.8590969, 5.4164737],
          [100.858516, 5.4158003],
          [100.8582578, 5.4154882],
          [100.8580641, 5.4152747],
          [100.8576519, 5.4146669],
          [100.8573943, 5.4142245],
          [100.8570702, 5.4138511],
          [100.8564703, 5.4130925],
          [100.8561784, 5.4127405],
          [100.8558602, 5.4123996],
          [100.8554108, 5.4119638],
          [100.8550797, 5.4117061],
          [100.8547628, 5.411322],
          [100.8546424, 5.4111072],
          [100.8546459, 5.4105837],
          [100.8548591, 5.4099596],
          [100.8548902, 5.4097606],
          [100.8547559, 5.4091936],
          [100.8546966, 5.4088564],
          [100.8546069, 5.4085345],
          [100.8542595, 5.4079611],
          [100.8538058, 5.407612],
          [100.8536531, 5.4072719],
          [100.8535909, 5.4069171],
          [100.8536396, 5.4061594],
          [100.8537822, 5.4056185],
          [100.8539839, 5.4047566],
          [100.854108, 5.404218],
          [100.8542198, 5.4036092],
          [100.8543763, 5.4031385],
          [100.8546018, 5.402741],
          [100.854923, 5.4025266],
          [100.8554116, 5.4024053],
          [100.8559755, 5.4025153],
          [100.8562957, 5.4025474],
          [100.8564786, 5.4025789],
          [100.8567681, 5.4026571],
          [100.8570581, 5.402612],
          [100.8575624, 5.4023675],
          [100.8577422, 5.4022725],
          [100.8579854, 5.4022407],
          [100.8582133, 5.401996],
          [100.8586525, 5.4015885],
          [100.8590603, 5.4009516],
          [100.8593057, 5.4003469],
          [100.859438, 5.3996762],
          [100.8595852, 5.3993493],
          [100.8597644, 5.3991208],
          [100.8601873, 5.3987296],
          [100.8604475, 5.3985178],
          [100.8605779, 5.3983219],
          [100.8602884, 5.3977478],
          [100.8601281, 5.3972888],
          [100.8598377, 5.3969275],
          [100.8594187, 5.3963038],
          [100.8594367, 5.3958455],
          [100.8595311, 5.3951798],
          [100.8591618, 5.3944917],
          [100.858784, 5.3940186],
          [100.858445, 5.3933005],
          [100.8578771, 5.3926989],
          [100.8573769, 5.392089],
          [100.8565453, 5.3915159],
          [100.856028, 5.3909103],
          [100.8555991, 5.3904617],
          [100.8548896, 5.3896909],
          [100.854401, 5.3891654],
          [100.8539693, 5.3883832],
          [100.8539533, 5.3883467],
          [100.8539386, 5.388275],
          [100.8539179, 5.3881417],
          [100.8539012, 5.3880203],
          [100.8538538, 5.38789],
          [100.853603, 5.3876211],
          [100.8533724, 5.3874635],
          [100.8532055, 5.3873477],
          [100.8529852, 5.3869733],
          [100.8524121, 5.3864389],
          [100.8521473, 5.3860635],
          [100.8520003, 5.3852038],
          [100.8520609, 5.3843254],
          [100.8520243, 5.3838123],
          [100.8518972, 5.3836397],
          [100.8518124, 5.3835102],
          [100.8517157, 5.3833207],
          [100.8513156, 5.3828022],
          [100.8508804, 5.3821295],
          [100.850393, 5.381439],
          [100.8496801, 5.3809391],
          [100.8491699, 5.3806237],
          [100.8489822, 5.3797517],
          [100.8488727, 5.3794595],
          [100.8488041, 5.3793196],
          [100.8487699, 5.3792147],
          [100.8487293, 5.3791132],
          [100.8486345, 5.3789145],
          [100.8485511, 5.3786184],
          [100.8484716, 5.3779279],
          [100.8484299, 5.3777467],
          [100.8485525, 5.3773456],
          [100.8486412, 5.3772146],
          [100.8488147, 5.3769679],
          [100.849369, 5.3763186],
          [100.8495601, 5.3760924],
          [100.8496295, 5.3760054],
          [100.8498386, 5.3756046],
          [100.8500128, 5.3752561],
          [100.850083, 5.3749945],
          [100.8500839, 5.374323],
          [100.8500899, 5.3742532],
          [100.8500486, 5.3738326],
          [100.8497825, 5.3730442],
          [100.8497516, 5.3729374],
          [100.8497834, 5.3728246],
          [100.8498213, 5.3727244],
          [100.849891, 5.3725541],
          [100.8499655, 5.3723131],
          [100.8499875, 5.3716489],
          [100.8500108, 5.3714944],
          [100.850173, 5.3709217],
          [100.8501919, 5.3707919],
          [100.8501609, 5.3705491],
          [100.8501002, 5.3701966],
          [100.84992, 5.3698256],
          [100.8499405, 5.3692874],
          [100.8499557, 5.368828],
          [100.8501225, 5.3685611],
          [100.8506802, 5.3681209],
          [100.8507543, 5.3679727],
          [100.8508655, 5.3677318],
          [100.8510503, 5.3674541],
          [100.8512355, 5.3670836],
          [100.8512674, 5.3667445],
          [100.8512132, 5.366503],
          [100.8510041, 5.3659218],
          [100.8509967, 5.3658916],
          [100.8509688, 5.3658746],
          [100.8507944, 5.3657134],
          [100.8503662, 5.3653066],
          [100.8500601, 5.3650387],
          [100.8496392, 5.3641392],
          [100.8495818, 5.3638],
          [100.8497477, 5.3636707],
          [100.8499015, 5.3635465],
          [100.8503254, 5.3632326],
          [100.8505651, 5.3630294],
          [100.8509713, 5.3625484],
          [100.8509842, 5.3623577],
          [100.851003, 5.3622278],
          [100.851001, 5.3620522],
          [100.8510297, 5.3618976],
          [100.8509324, 5.3614851],
          [100.8507184, 5.3610823],
          [100.8501989, 5.3603596],
          [100.8495975, 5.3597721],
          [100.8491292, 5.3590158],
          [100.8488483, 5.3586741],
          [100.8487222, 5.3583539],
          [100.8484474, 5.3578001],
          [100.8485216, 5.3576148],
          [100.8485774, 5.3574479],
          [100.8486597, 5.357245],
          [100.8488264, 5.3570007],
          [100.8488737, 5.3568737],
          [100.8489664, 5.3566699],
          [100.8490223, 5.3564659],
          [100.8491712, 5.3556938],
          [100.849133, 5.3550186],
          [100.8489926, 5.3544711],
          [100.8487407, 5.3536365],
          [100.8487199, 5.353214],
          [100.8486592, 5.3528536],
          [100.8487815, 5.3526559],
          [100.8492858, 5.352392],
          [100.8494841, 5.3521734],
          [100.8494953, 5.3517326],
          [100.8490528, 5.3513739],
          [100.8483952, 5.3509031],
          [100.8479613, 5.3508026],
          [100.8479318, 5.3507942],
          [100.8471247, 5.3506497],
          [100.8470038, 5.3505922],
          [100.8466934, 5.3503877],
          [100.8462031, 5.3498725],
          [100.8458962, 5.3497536],
          [100.8455685, 5.3496851],
          [100.8450982, 5.3494514],
          [100.844397, 5.34915],
          [100.8441145, 5.3489398],
          [100.8435829, 5.3483324],
          [100.8430883, 5.3478278],
          [100.8423979, 5.3473195],
          [100.8420627, 5.3472245],
          [100.8418158, 5.3471343],
          [100.8417171, 5.3470744],
          [100.8412552, 5.3469102],
          [100.8403762, 5.3469133],
          [100.8398722, 5.3468321],
          [100.8395607, 5.3467928],
          [100.8392396, 5.3467503],
          [100.8387363, 5.3464807],
          [100.8386688, 5.3464087],
          [100.8383436, 5.3461292],
          [100.8383026, 5.3461023],
          [100.8381232, 5.345783],
          [100.837816, 5.3453533],
          [100.8375558, 5.3445153],
          [100.8373756, 5.3441882],
          [100.8373122, 5.344104],
          [100.8371968, 5.3439212],
          [100.8368591, 5.3435883],
          [100.8368081, 5.3435557],
          [100.8365029, 5.343409],
          [100.8361244, 5.3431116],
          [100.835962, 5.3429637],
          [100.8358069, 5.3428541],
          [100.8352351, 5.342493],
          [100.8347367, 5.3420956],
          [100.8345839, 5.3420085],
          [100.8344108, 5.3419323],
          [100.8341492, 5.3418674],
          [100.833995, 5.3418356],
          [100.8336295, 5.3417452],
          [100.8333741, 5.3416729],
          [100.8331585, 5.3415919],
          [100.8328724, 5.3414705],
          [100.8327712, 5.3414212],
          [100.8327009, 5.3413541],
          [100.8324949, 5.3410728],
          [100.8324644, 5.3409747],
          [100.8324252, 5.3408677],
          [100.8323511, 5.3406492],
          [100.8322287, 5.3403905],
          [100.8320332, 5.3400373],
          [100.8317213, 5.3396687],
          [100.8314686, 5.3393351],
          [100.8312088, 5.3389668],
          [100.8308997, 5.3386767],
          [100.8307223, 5.3386209],
          [100.8306474, 5.3386098],
          [100.830587, 5.3386115],
          [100.8301187, 5.3385656],
          [100.8298814, 5.3384748],
          [100.8297801, 5.3383546],
          [100.829739, 5.3383036],
          [100.8296876, 5.3377846],
          [100.8296142, 5.337406],
          [100.8294198, 5.3371388],
          [100.8288555, 5.3369592],
          [100.8285247, 5.3368078],
          [100.8282742, 5.3365528],
          [100.8281256, 5.3363787],
          [100.8275572, 5.335788],
          [100.827227, 5.3354709],
          [100.8270108, 5.3352086],
          [100.8269766, 5.3351854],
          [100.8267946, 5.3350046],
          [100.8264763, 5.3345254],
          [100.8261726, 5.3342345],
          [100.8254953, 5.3341292],
          [100.8254622, 5.3341267],
          [100.8246007, 5.3339902],
          [100.8243092, 5.3338387],
          [100.824085, 5.3336623],
          [100.8239778, 5.3335035],
          [100.8237098, 5.3332059],
          [100.8236322, 5.3330976],
          [100.8233564, 5.3328648],
          [100.8229486, 5.3326316],
          [100.8227471, 5.3322009],
          [100.8225975, 5.3319648],
          [100.8224746, 5.3317565],
          [100.8217341, 5.3314091],
          [100.8213097, 5.3310572],
          [100.8211066, 5.3308782],
          [100.8208176, 5.3305458],
          [100.8204237, 5.3301404],
          [100.8201136, 5.3298051],
          [100.8196051, 5.3290233],
          [100.8191628, 5.3283533],
          [100.8184553, 5.3272589],
          [100.8182345, 5.3268124],
          [100.8181019, 5.3265891],
          [100.8180136, 5.3264105],
          [100.8176371, 5.3260081],
          [100.8173715, 5.3256729],
          [100.8172837, 5.3253606],
          [100.8173323, 5.3251482],
          [100.8176854, 5.3249495],
          [100.8181058, 5.3246693],
          [100.8184134, 5.3244193],
          [100.8187562, 5.3238951],
          [100.8188232, 5.3238062],
          [100.8188564, 5.3237484],
          [100.8190308, 5.3234803],
          [100.8190407, 5.3234534],
          [100.8190796, 5.3230468],
          [100.8190791, 5.3229206],
          [100.8190499, 5.3228043],
          [100.8189639, 5.3226707],
          [100.818675, 5.3223129],
          [100.8181341, 5.3218649],
          [100.8174532, 5.3217351],
          [100.8169729, 5.3219883],
          [100.8163408, 5.3223556],
          [100.8156477, 5.3227699],
          [100.8150018, 5.3231241],
          [100.8145119, 5.3233452],
          [100.8142449, 5.3234111],
          [100.8140904, 5.3234344],
          [100.8138133, 5.3233059],
          [100.8136503, 5.3229995],
          [100.813551, 5.3225404],
          [100.8134646, 5.3220048],
          [100.8133789, 5.3212781],
          [100.8133523, 5.3211965],
          [100.8132575, 5.320833],
          [100.8131157, 5.3207802],
          [100.8128375, 5.3207445],
          [100.8122821, 5.3208063],
          [100.8117512, 5.3210975],
          [100.8116428, 5.3211939],
          [100.8114066, 5.3213311],
          [100.8112308, 5.3215069],
          [100.8109944, 5.3217707],
          [100.8102577, 5.3224175],
          [100.8102066, 5.322461],
          [100.8099438, 5.3226479],
          [100.8097951, 5.3227221],
          [100.8097141, 5.3227342],
          [100.8094743, 5.3227461],
          [100.8093119, 5.322725],
          [100.8088457, 5.3225451],
          [100.8085598, 5.3221913],
          [100.8083286, 5.3218912],
          [100.8081567, 5.3214262],
          [100.8081425, 5.3211129],
          [100.8082272, 5.3207139],
          [100.8085001, 5.3202566],
          [100.8087416, 5.3198242],
          [100.8087303, 5.3195054],
          [100.8085406, 5.3192059],
          [100.8082178, 5.3188645],
          [100.8076599, 5.3187143],
          [100.8072687, 5.3187127],
          [100.8067754, 5.3189657],
          [100.8063575, 5.3193337],
          [100.8058894, 5.3195995],
          [100.8053707, 5.3197013],
          [100.8049304, 5.3197504],
          [100.8046598, 5.3196984],
          [100.8044909, 5.3195959],
          [100.8042722, 5.3192727],
          [100.8040701, 5.3190343],
          [100.8038003, 5.3187618],
          [100.8034962, 5.3186248],
          [100.8032931, 5.318607],
          [100.8032404, 5.3186167],
          [100.8030762, 5.3186616],
          [100.8028262, 5.3188016],
          [100.8026207, 5.3189576],
          [100.8024467, 5.3192245],
          [100.8023427, 5.3194991],
          [100.8023074, 5.319604],
          [100.8022876, 5.3203165],
          [100.8022856, 5.3207916],
          [100.8021315, 5.3212321],
          [100.8016775, 5.3217252],
          [100.8008444, 5.3220421],
          [100.8000159, 5.3222501],
          [100.7999268, 5.322275],
          [100.7994017, 5.3223577],
          [100.7990971, 5.3223565],
          [100.7987078, 5.322376],
          [100.7982337, 5.322374],
          [100.7974714, 5.3223089],
          [100.7974341, 5.3222804],
          [100.7972795, 5.3222233],
          [100.7968755, 5.321852],
          [100.7966497, 5.3216238],
          [100.7962211, 5.3205891],
          [100.7960783, 5.3202167],
          [100.7959758, 5.3200716],
          [100.7959402, 5.3200408],
          [100.7957631, 5.3199715],
          [100.7956363, 5.3199405],
          [100.7949259, 5.320279],
          [100.7943039, 5.3206639],
          [100.7939084, 5.3208639],
          [100.7932702, 5.3211012],
          [100.7928373, 5.3213286],
          [100.7925447, 5.3215783],
          [100.7923408, 5.3219162],
          [100.7922273, 5.3226133],
          [100.7921385, 5.3231911],
          [100.7920401, 5.3234852],
          [100.7918557, 5.3236917],
          [100.7917808, 5.3237271],
          [100.7916555, 5.3238259],
          [100.7914469, 5.3237926],
          [100.7912313, 5.3236915],
          [100.791095, 5.3236002],
          [100.7909634, 5.323538],
          [100.7909094, 5.3235234],
          [100.7906938, 5.3234893],
          [100.7905383, 5.3234825],
          [100.7891807, 5.3235567],
          [100.7886805, 5.3235736],
          [100.7879748, 5.3235539],
          [100.7872339, 5.3234154],
          [100.7869967, 5.3233381],
          [100.7866843, 5.3231297],
          [100.7865134, 5.3228855],
          [100.7865019, 5.3225923],
          [100.7866165, 5.3223123],
          [100.7865921, 5.3220827],
          [100.7864286, 5.3217287],
          [100.786442, 5.3215376],
          [100.7865438, 5.3213213],
          [100.7866707, 5.3211305],
          [100.7867977, 5.3209143],
          [100.7869121, 5.3207108],
          [100.786976, 5.3204943],
          [100.7869769, 5.3202394],
          [100.7869401, 5.3199716],
          [100.7868279, 5.3196142],
          [100.786665, 5.3193076],
          [100.786439, 5.3190009],
          [100.7862507, 5.3187324],
          [100.7859489, 5.3184254],
          [100.7856599, 5.3181056],
          [100.785358, 5.3178367],
          [100.7850942, 5.3175298],
          [100.7848178, 5.3171846],
          [100.7845793, 5.3168522],
          [100.7843421, 5.3165101],
          [100.7840982, 5.3162134],
          [100.7839069, 5.3159729],
          [100.7837659, 5.3157191],
          [100.7835739, 5.3154021],
          [100.783386, 5.3150316],
          [100.7832234, 5.3146613],
          [100.7830983, 5.3143931],
          [100.7828977, 5.3140481],
          [100.7827851, 5.3138181],
          [100.782559, 5.3135495],
          [100.78232, 5.3133446],
          [100.7821311, 5.3132418],
          [100.7819166, 5.3132282],
          [100.7816514, 5.3132654],
          [100.7814241, 5.31329],
          [100.7812724, 5.3133531],
          [100.7812088, 5.3134931],
          [100.7811872, 5.3135776],
          [100.7812029, 5.3136193],
          [100.7813349, 5.3137924],
          [100.7818405, 5.3141323],
          [100.781875, 5.3141587],
          [100.7820888, 5.314338],
          [100.7822522, 5.3145172],
          [100.7823524, 5.314696],
          [100.7824523, 5.3149641],
          [100.7824767, 5.315181],
          [100.7824505, 5.3154358],
          [100.7823108, 5.3156392],
          [100.7821337, 5.3157405],
          [100.7818685, 5.3157904],
          [100.781591, 5.3157383],
          [100.7813138, 5.3156352],
          [100.7810743, 5.3155578],
          [100.7808221, 5.3155058],
          [100.7805445, 5.3154791],
          [100.7803047, 5.3154782],
          [100.7800395, 5.3155281],
          [100.7798624, 5.3156166],
          [100.7797105, 5.3157307],
          [100.7795709, 5.3159214],
          [100.7794316, 5.3160483],
          [100.7793051, 5.3161115],
          [100.7791537, 5.3161109],
          [100.7790025, 5.3160466],
          [100.7788261, 5.3159694],
          [100.7786496, 5.3159177],
          [100.7784477, 5.3159169],
          [100.7782834, 5.3159545],
          [100.7781065, 5.3160047],
          [100.7779547, 5.3160934],
          [100.7778405, 5.3162459],
          [100.7777768, 5.3164113],
          [100.7777006, 5.3165258],
          [100.7775616, 5.3165635],
          [100.7774229, 5.3165247],
          [100.7772848, 5.3163329],
          [100.7771342, 5.3161283],
          [100.7770594, 5.3159113],
          [100.7769082, 5.3158469],
          [100.7767551, 5.3158662],
          [100.7765276, 5.3159418],
          [100.7763633, 5.3160176],
          [100.7762367, 5.3161063],
          [100.7760975, 5.3162078],
          [100.7759835, 5.3163093],
          [100.7758567, 5.316449],
          [100.7757804, 5.3166017],
          [100.7756912, 5.3168053],
          [100.7756273, 5.3169962],
          [100.7755803, 5.3172547],
          [100.7753608, 5.3173649],
          [100.7751713, 5.3174151],
          [100.774944, 5.3174397],
          [100.7747294, 5.3174515],
          [100.7745273, 5.3175017],
          [100.7742872, 5.3175517],
          [100.7740598, 5.3176273],
          [100.7738018, 5.3177384],
          [100.7737814, 5.3178047],
          [100.7737428, 5.3179957],
          [100.7737548, 5.318136],
          [100.7738044, 5.3183529],
          [100.7738667, 5.3185699],
          [100.7739163, 5.3187868],
          [100.7739154, 5.3190035],
          [100.7739023, 5.3191006],
          [100.7736625, 5.3191172],
          [100.7734608, 5.3190654],
          [100.7732338, 5.3190135],
          [100.7729816, 5.3189743],
          [100.7726535, 5.3189602],
          [100.7724136, 5.318972],
          [100.7721862, 5.3190221],
          [100.7719462, 5.3190848],
          [100.771807, 5.3191735],
          [100.7716675, 5.3193387],
          [100.771553, 5.3195677],
          [100.7715141, 5.3198225],
          [100.7714374, 5.3200644],
          [100.7713611, 5.3202043],
          [100.7712222, 5.3202292],
          [100.7710584, 5.3201648],
          [100.7709582, 5.3199604],
          [100.7709593, 5.3196927],
          [100.7709983, 5.3194124],
          [100.7711004, 5.3191196],
          [100.7710888, 5.3188774],
          [100.7709257, 5.3186218],
          [100.770636, 5.3184804],
          [100.7702073, 5.3183766],
          [100.7699046, 5.3183372],
          [100.7695766, 5.3182976],
          [100.7693241, 5.3183221],
          [100.7691345, 5.3183978],
          [100.7689069, 5.3185116],
          [100.7687424, 5.3186257],
          [100.7685906, 5.3187143],
          [100.7684135, 5.3188028],
          [100.7681988, 5.3188402],
          [100.7679968, 5.3188521],
          [100.7677697, 5.3188257],
          [100.767543, 5.3187228],
          [100.7674677, 5.3186912],
          [100.7674048, 5.3185565],
          [100.7673803, 5.3183652],
          [100.7674066, 5.3181231],
          [100.7674074, 5.3179064],
          [100.7673956, 5.3177024],
          [100.7673458, 5.3175364],
          [100.7671319, 5.3173698],
          [100.7668798, 5.3173051],
          [100.7666399, 5.3173296],
          [100.7663745, 5.3174178],
          [100.765995, 5.3176202],
          [100.7658307, 5.317696],
          [100.7655783, 5.3176822],
          [100.7653387, 5.3176303],
          [100.7651877, 5.3175277],
          [100.7650621, 5.3173614],
          [100.764949, 5.317259],
          [100.7647848, 5.3172711],
          [100.7646709, 5.3173471],
          [100.7645316, 5.3174613],
          [100.7643672, 5.3175626],
          [100.7641901, 5.3176511],
          [100.7640386, 5.3176632],
          [100.7638497, 5.3175732],
          [100.7637642, 5.3175368],
          [100.7637253, 5.3171138],
          [100.7637136, 5.3168715],
          [100.7636387, 5.31668],
          [100.7635135, 5.3164373],
          [100.7632747, 5.3161814],
          [100.7630609, 5.316002],
          [100.7628214, 5.3159246],
          [100.762481, 5.3158339],
          [100.7622163, 5.3157564],
          [100.761977, 5.3156279],
          [100.7617504, 5.315474],
          [100.7615237, 5.3153584],
          [100.7613219, 5.3153193],
          [100.7610568, 5.3153692],
          [100.7609177, 5.3154324],
          [100.7607658, 5.3155338],
          [100.7606013, 5.3156606],
          [100.7604997, 5.3158004],
          [100.760436, 5.3159531],
          [100.7603719, 5.3161951],
          [100.7603331, 5.3164371],
          [100.7603195, 5.3166793],
          [100.7602176, 5.3168956],
          [100.7600537, 5.3170362],
          [100.7598644, 5.3171259],
          [100.7596326, 5.31722],
          [100.7593705, 5.3172746],
          [100.7591937, 5.3172994],
          [100.7590043, 5.3173114],
          [100.7588151, 5.3172851],
          [100.7585754, 5.3172586],
          [100.7583734, 5.3172833],
          [100.7582343, 5.3173465],
          [100.7582086, 5.3174611],
          [100.7582585, 5.3176015],
          [100.7584094, 5.3177296],
          [100.7585982, 5.3178579],
          [100.7587366, 5.3179732],
          [100.7588495, 5.3181394],
          [100.7589118, 5.3183563],
          [100.7589615, 5.3185478],
          [100.7589104, 5.3186878],
          [100.7587838, 5.3187765],
          [100.758645, 5.3187887],
          [100.7584181, 5.3187113],
          [100.7582168, 5.3185575],
          [100.758024, 5.3184608],
          [100.7578543, 5.3184829],
          [100.757735, 5.318572],
          [100.7575339, 5.3188989],
          [100.7573816, 5.3191022],
          [100.7571915, 5.3192799],
          [100.7569386, 5.3194064],
          [100.7566985, 5.3194819],
          [100.7564584, 5.3195702],
          [100.7564012, 5.319586],
          [100.7562596, 5.3195874],
          [100.756192, 5.3195781],
          [100.7560244, 5.3195066],
          [100.7559791, 5.319479],
          [100.7558281, 5.3193636],
          [100.7556773, 5.31921],
          [100.7554759, 5.319069],
          [100.7552489, 5.3190298],
          [100.7550342, 5.3190672],
          [100.7548446, 5.3191302],
          [100.7546047, 5.3191547],
          [100.7543524, 5.3191409],
          [100.7542097, 5.3191448],
          [100.7541265, 5.3191099],
          [100.7541002, 5.3190761],
          [100.7539494, 5.3189226],
          [100.7538493, 5.3187054],
          [100.7536986, 5.3185136],
          [100.7535226, 5.3183471],
          [100.7533085, 5.3182698],
          [100.7530687, 5.3182688],
          [100.7528668, 5.3182552],
          [100.7526524, 5.3182034],
          [100.7523877, 5.3181258],
          [100.7521605, 5.3181249],
          [100.7519834, 5.3182389],
          [100.7518565, 5.3183914],
          [100.7517926, 5.3185951],
          [100.751716, 5.3188115],
          [100.7516649, 5.3189642],
          [100.7515255, 5.3191039],
          [100.751323, 5.3192433],
          [100.7510955, 5.3193189],
          [100.7508682, 5.3193562],
          [100.7506157, 5.3193679],
          [100.7503381, 5.3193413],
          [100.7500859, 5.319302],
          [100.7498715, 5.3192501],
          [100.7496952, 5.3191602],
          [100.749582, 5.3190705],
          [100.7494805, 5.3189287],
          [100.7493916, 5.3188333],
          [100.7492888, 5.3187882],
          [100.7491506, 5.3187888],
          [100.7490024, 5.3188386],
          [100.7488003, 5.3188633],
          [100.7485984, 5.3188497],
          [100.7483841, 5.3187979],
          [100.7481821, 5.3188225],
          [100.7480178, 5.3188729],
          [100.7478159, 5.3188593],
          [100.7477193, 5.3188366],
          [100.7475045, 5.3187327],
          [100.7474761, 5.3186284],
          [100.7474375, 5.3185298],
          [100.7473639, 5.3182992],
          [100.7470866, 5.3181934],
          [100.7468848, 5.3181543],
          [100.7467195, 5.3180843],
          [100.7465685, 5.3179817],
          [100.7464049, 5.3178663],
          [100.7459267, 5.3175202],
          [100.7457126, 5.3174046],
          [100.7455611, 5.3174039],
          [100.7453591, 5.3174286],
          [100.7451822, 5.3174661],
          [100.7449675, 5.3175035],
          [100.7447783, 5.3174772],
          [100.7446272, 5.3173874],
          [100.7444766, 5.3171955],
          [100.7444091, 5.3169923],
          [100.7443363, 5.3169272],
          [100.7442539, 5.3169254],
          [100.7442126, 5.3169267],
          [100.744061, 5.3169644],
          [100.743845, 5.3170239],
          [100.7436726, 5.317018],
          [100.7434932, 5.3169365],
          [100.7434055, 5.3167705],
          [100.7434191, 5.3165283],
          [100.7434197, 5.3163753],
          [100.7433415, 5.3161652],
          [100.7431634, 5.3160744],
          [100.7429542, 5.3160165],
          [100.7427403, 5.3158754],
          [100.742577, 5.3156835],
          [100.7423758, 5.3154914],
          [100.7421996, 5.3153632],
          [100.7419602, 5.315273],
          [100.7417712, 5.3151958],
          [100.7416077, 5.3150549],
          [100.7414194, 5.3148119],
          [100.7412938, 5.3146456],
          [100.7410421, 5.3144916],
          [100.7408405, 5.3143888],
          [100.7406265, 5.3142732],
          [100.7404, 5.3140938],
          [100.7401606, 5.3140036],
          [100.7396841, 5.3139928],
          [100.7395723, 5.3139813],
          [100.7393899, 5.3139481],
          [100.7391971, 5.3138935],
          [100.7390992, 5.313755],
          [100.7389484, 5.3135886],
          [100.7388104, 5.3133841],
          [100.738698, 5.3130904],
          [100.7385853, 5.3128733],
          [100.738556, 5.3128065],
          [100.7384325, 5.3127372],
          [100.7383462, 5.3127066],
          [100.7380812, 5.31268],
          [100.7378286, 5.3127299],
          [100.7373862, 5.3129066],
          [100.737184, 5.3129568],
          [100.7369945, 5.3130197],
          [100.7367922, 5.3130954],
          [100.7365898, 5.313222],
          [100.7364126, 5.3133488],
          [100.7362105, 5.3133862],
          [100.7359329, 5.3133596],
          [100.7356933, 5.3133203],
          [100.7355673, 5.3132561],
          [100.7353661, 5.3130895],
          [100.7351521, 5.3129484],
          [100.7349003, 5.3127944],
          [100.7346339, 5.3126164],
          [100.7343248, 5.3124473],
          [100.734183, 5.3122815],
          [100.7341151, 5.3122073],
          [100.7340778, 5.3121274],
          [100.734049, 5.311993],
          [100.7340492, 5.3119553],
          [100.734104, 5.3118562],
          [100.7341897, 5.3117125],
          [100.7345888, 5.3118115],
          [100.7347557, 5.3118365],
          [100.7349441, 5.3118289],
          [100.7351201, 5.311782],
          [100.7353469, 5.3116107],
          [100.7355243, 5.3114329],
          [100.7356135, 5.3112166],
          [100.73569, 5.3110384],
          [100.7357034, 5.3109825],
          [100.7357542, 5.310771],
          [100.7358183, 5.310529],
          [100.7359833, 5.3102875],
          [100.7361739, 5.3099823],
          [100.7363265, 5.3097025],
          [100.7364537, 5.309448],
          [100.7366071, 5.3089642],
          [100.7366205, 5.3087858],
          [100.7366214, 5.3085691],
          [100.7366475, 5.3083525],
          [100.7366993, 5.3080212],
          [100.7367639, 5.3076646],
          [100.7368533, 5.30741],
          [100.7369249, 5.3073371],
          [100.7369959, 5.307202],
          [100.7371575, 5.3070766],
          [100.7372967, 5.3070007],
          [100.737423, 5.3069757],
          [100.7376501, 5.3070021],
          [100.7378141, 5.3070156],
          [100.7379908, 5.3070035],
          [100.7381805, 5.3069023],
          [100.7382824, 5.3066733],
          [100.7383086, 5.3064439],
          [100.7382968, 5.3062399],
          [100.7381967, 5.3060355],
          [100.7381092, 5.3058312],
          [100.7380847, 5.3056399],
          [100.738035, 5.3054612],
          [100.738017, 5.3053807],
          [100.7377196, 5.3054344],
          [100.7375426, 5.3055102],
          [100.7373278, 5.305573],
          [100.7371384, 5.3055722],
          [100.7370925, 5.3055511],
          [100.7369039, 5.3055221],
          [100.7367233, 5.3052391],
          [100.736661, 5.3050349],
          [100.736599, 5.3047797],
          [100.736474, 5.3044859],
          [100.736387, 5.3041541],
          [100.7362869, 5.3040084],
          [100.7359739, 5.3036894],
          [100.7358969, 5.3036294],
          [100.7358098, 5.3033359],
          [100.735836, 5.3031065],
          [100.7359378, 5.3028902],
          [100.7359663, 5.3028548],
          [100.7359916, 5.3027277],
          [100.7359393, 5.3025333],
          [100.7357633, 5.3023668],
          [100.7355868, 5.3023024],
          [100.7354731, 5.3023274],
          [100.735334, 5.3023906],
          [100.7352074, 5.302492],
          [100.7350554, 5.3026316],
          [100.7348529, 5.3027838],
          [100.7346379, 5.3028849],
          [100.7344233, 5.3028967],
          [100.7341205, 5.3028828],
          [100.7338052, 5.3028177],
          [100.7335026, 5.30274],
          [100.7332759, 5.3026371],
          [100.7331083, 5.3025182],
          [100.7330822, 5.3022963],
          [100.7331395, 5.3020374],
          [100.7331893, 5.3018407],
          [100.7331773, 5.301675],
          [100.7331023, 5.3015089],
          [100.7330612, 5.3014619],
          [100.7329732, 5.3014085],
          [100.7328797, 5.3012811],
          [100.7328877, 5.3011926],
          [100.7329145, 5.3011257],
          [100.7330161, 5.3009859],
          [100.7331678, 5.30091],
          [100.7333701, 5.3008344],
          [100.733522, 5.3007203],
          [100.733611, 5.3005549],
          [100.7336496, 5.3003638],
          [100.7336252, 5.3001598],
          [100.7335753, 5.3000321],
          [100.7333861, 5.3000058],
          [100.7332346, 5.3000052],
          [100.7330707, 5.299979],
          [100.7329216, 5.2999492],
          [100.7328798, 5.2998412],
          [100.7328765, 5.2997026],
          [100.7329519, 5.2995912],
          [100.7331991, 5.2994441],
          [100.7333022, 5.299379],
          [100.7334745, 5.2992054],
          [100.7334657, 5.2990628],
          [100.7333532, 5.2987946],
          [100.7331354, 5.298497],
          [100.732976, 5.2984489],
          [100.7326988, 5.298333],
          [100.7324217, 5.2981916],
          [100.7321827, 5.2979994],
          [100.7320445, 5.2978331],
          [100.7319825, 5.2975779],
          [100.7319081, 5.2972462],
          [100.7319091, 5.2970167],
          [100.7319604, 5.2968129],
          [100.7320241, 5.2966475],
          [100.7320502, 5.2964563],
          [100.7319502, 5.2962265],
          [100.731787, 5.2960091],
          [100.7317752, 5.2957923],
          [100.7318266, 5.2955886],
          [100.731941, 5.2953819],
          [100.7320679, 5.295204],
          [100.7322202, 5.2950006],
          [100.73236, 5.2947717],
          [100.7324492, 5.2945554],
          [100.73245, 5.2943641],
          [100.732375, 5.2941854],
          [100.7322366, 5.2940701],
          [100.731997, 5.2940181],
          [100.7319563, 5.2939962],
          [100.7318109, 5.2939381],
          [100.7315475, 5.2937964],
          [100.7313693, 5.2934818],
          [100.7313048, 5.2932957],
          [100.7313064, 5.2931739],
          [100.7313199, 5.2929572],
          [100.7313587, 5.2927279],
          [100.7313847, 5.2925368],
          [100.7313352, 5.2922944],
          [100.7313237, 5.2920266],
          [100.731324, 5.2917625],
          [100.7313378, 5.29152],
          [100.7314022, 5.2912778],
          [100.7315294, 5.2910998],
          [100.7316943, 5.2909602],
          [100.7318844, 5.2908335],
          [100.7320239, 5.290732],
          [100.732113, 5.2905921],
          [100.7321265, 5.2904262],
          [100.7320895, 5.2902346],
          [100.7319642, 5.290017],
          [100.7319146, 5.2898253],
          [100.7319787, 5.2896214],
          [100.7320177, 5.2894046],
          [100.7320058, 5.2892386],
          [100.731931, 5.2890468],
          [100.7318306, 5.2889059],
          [100.7316798, 5.2887265],
          [100.7315544, 5.2885344],
          [100.7315299, 5.2883684],
          [100.7315337, 5.2883182],
          [100.7315606, 5.2882647],
          [100.7315987, 5.2882114],
          [100.7316289, 5.2880493],
          [100.7316131, 5.287917],
          [100.7315707, 5.2877559],
          [100.7314581, 5.2875384],
          [100.7313206, 5.2872187],
          [100.7312746, 5.2870496],
          [100.731195, 5.2868586],
          [100.7311318, 5.2867281],
          [100.7310493, 5.2866214],
          [100.7309509, 5.286515],
          [100.7308375, 5.2863521],
          [100.7307568, 5.2862331],
          [100.7307074, 5.2859904],
          [100.730759, 5.2857737],
          [100.7308181, 5.2856714],
          [100.731082, 5.2855493],
          [100.7311901, 5.2854567],
          [100.7312541, 5.285291],
          [100.7312361, 5.2852537],
          [100.7311742, 5.2851553],
          [100.731087, 5.2850759],
          [100.73073, 5.2850244],
          [100.7305379, 5.2849079],
          [100.7304416, 5.2846834],
          [100.7304733, 5.2845451],
          [100.7305839, 5.2844286],
          [100.7308953, 5.2843472],
          [100.7311069, 5.2840978],
          [100.7311237, 5.2837543],
          [100.7308657, 5.2830778],
          [100.7304983, 5.2824319],
          [100.7305111, 5.2822117],
          [100.7308364, 5.282109],
          [100.7311848, 5.2820906],
          [100.7315137, 5.2820439],
          [100.7316305, 5.2819483],
          [100.7316514, 5.2818454],
          [100.7316096, 5.2815828],
          [100.7314485, 5.2812395],
          [100.7312198, 5.2811063],
          [100.7309093, 5.2809396],
          [100.7307955, 5.2807406],
          [100.7311342, 5.2806267],
          [100.7312651, 5.2804419],
          [100.731205, 5.2800573],
          [100.7310391, 5.2800069],
          [100.7305878, 5.2799332],
          [100.7305538, 5.2797802],
          [100.7308018, 5.2794362],
          [100.7308096, 5.279196],
          [100.7306128, 5.2787558],
          [100.7302588, 5.2782602],
          [100.7298121, 5.2779465],
          [100.7296499, 5.2777],
          [100.7295112, 5.2774064],
          [100.7294957, 5.2772079],
          [100.72955, 5.2769983],
          [100.7297279, 5.2766141],
          [100.7296126, 5.2763391],
          [100.729374, 5.2760841],
          [100.729135, 5.2759184],
          [100.728361, 5.2759633],
          [100.7281215, 5.2759074],
          [100.7279579, 5.2757488],
          [100.7280069, 5.2754813],
          [100.72833, 5.2751737],
          [100.728338, 5.2748992],
          [100.7280509, 5.2747676],
          [100.7278234, 5.274717],
          [100.727689, 5.2745954],
          [100.7277766, 5.2744821],
          [100.7284215, 5.274136],
          [100.7286215, 5.2738005],
          [100.7286583, 5.2731622],
          [100.7287324, 5.2729168],
          [100.729109, 5.2729012],
          [100.7296746, 5.2729326],
          [100.7298242, 5.2726797],
          [100.7299002, 5.272366],
          [100.7298525, 5.2720096],
          [100.7295287, 5.2717041],
          [100.7293836, 5.2714798],
          [100.7292503, 5.2712562],
          [100.7289908, 5.270946],
          [100.7289787, 5.2706967],
          [100.7290447, 5.2704257],
          [100.7290848, 5.2699164],
          [100.7293538, 5.2693178],
          [100.7293589, 5.2689427],
          [100.7292935, 5.2685555],
          [100.7293489, 5.2684402],
          [100.7294538, 5.2684539],
          [100.7295719, 5.2685988],
          [100.7297607, 5.2689249],
          [100.729907, 5.2688736],
          [100.7300888, 5.2686849],
          [100.7301785, 5.2683482],
          [100.730502, 5.2682208],
          [100.7306468, 5.2679889],
          [100.7306157, 5.2678302],
          [100.7305329, 5.2677075],
          [100.7304004, 5.2675166],
          [100.7302579, 5.267322],
          [100.7297404, 5.2671678],
          [100.7294243, 5.2670196],
          [100.7285312, 5.2669387],
          [100.7282351, 5.2668674],
          [100.728183, 5.2666756],
          [100.7280594, 5.2664232],
          [100.7278778, 5.2662675],
          [100.7276877, 5.2660364],
          [100.7276791, 5.2655577],
          [100.72746, 5.2652583],
          [100.726857, 5.2649749],
          [100.7266374, 5.2647609],
          [100.7264031, 5.264436],
          [100.7264219, 5.2643572],
          [100.7268372, 5.2638521],
          [100.726631, 5.2634484],
          [100.7262039, 5.2630623],
          [100.7257902, 5.2629462],
          [100.7254025, 5.2630042],
          [100.7248396, 5.2630438],
          [100.7246079, 5.2629912],
          [100.7245789, 5.2628186],
          [100.7244715, 5.2622141],
          [100.7240031, 5.2617181],
          [100.7235387, 5.2615948],
          [100.7231851, 5.2616659],
          [100.7228998, 5.2616694],
          [100.7222805, 5.2616163],
          [100.7216637, 5.2615378],
          [100.7212239, 5.2613843],
          [100.7210087, 5.2611969],
          [100.7208367, 5.2610606],
          [100.7205725, 5.2607438],
          [100.7199244, 5.2601634],
          [100.7195631, 5.2600053],
          [100.7189509, 5.2599567],
          [100.7182834, 5.2593258],
          [100.717671, 5.2590036],
          [100.7173052, 5.2588347],
          [100.716816, 5.2582214],
          [100.7166008, 5.2580839],
          [100.716454, 5.2579665],
          [100.716027, 5.2576664],
          [100.7156594, 5.2573017],
          [100.7156368, 5.2571292],
          [100.7155958, 5.2568792],
          [100.7154005, 5.2567413],
          [100.7151205, 5.2568098],
          [100.7147884, 5.2572007],
          [100.71468, 5.2573246],
          [100.7145447, 5.2573203],
          [100.7144527, 5.25725],
          [100.7144071, 5.25711],
          [100.7142479, 5.2565735],
          [100.713929, 5.2566895],
          [100.7135697, 5.2571362],
          [100.7133523, 5.2572772],
          [100.7125357, 5.2573618],
          [100.7120078, 5.2574952],
          [100.7118914, 5.2570408],
          [100.7117129, 5.2567721],
          [100.7116669, 5.256438],
          [100.7114463, 5.2563522],
          [100.711102, 5.2564162],
          [100.7109025, 5.256781],
          [100.7107454, 5.257456],
          [100.7106282, 5.2576577],
          [100.7102471, 5.2580606],
          [100.7100551, 5.2581173],
          [100.7098098, 5.2580296],
          [100.7096538, 5.2578319],
          [100.7094168, 5.2573966],
          [100.7091935, 5.2572982],
          [100.7089949, 5.257422],
          [100.7087244, 5.2578269],
          [100.7086713, 5.2579969],
          [100.7085486, 5.2580265],
          [100.7084547, 5.2580045],
          [100.7080211, 5.2578939],
          [100.7078089, 5.2576435],
          [100.7077769, 5.2574022],
          [100.7078128, 5.2567541],
          [100.7077021, 5.2563874],
          [100.7074821, 5.2562411],
          [100.7071572, 5.2559686],
          [100.7070908, 5.2557939],
          [100.7070632, 5.2555129],
          [100.7071418, 5.2550579],
          [100.7070548, 5.2545691],
          [100.7068454, 5.2544876],
          [100.7063947, 5.2543458],
          [100.7061272, 5.2539843],
          [100.7059553, 5.2536334],
          [100.7053368, 5.253268],
          [100.7049265, 5.252782],
          [100.7046706, 5.2526531],
          [100.703938, 5.2528711],
          [100.7038082, 5.2525623],
          [100.7034905, 5.2522497],
          [100.7034699, 5.2522343],
          [100.7033165, 5.2521671],
          [100.7029735, 5.2519809],
          [100.7025648, 5.2517433],
          [100.7025062, 5.2514753],
          [100.7020203, 5.2509186],
          [100.702045, 5.2504104],
          [100.7019921, 5.2501992],
          [100.7019057, 5.2501153],
          [100.7017399, 5.2500935],
          [100.7016615, 5.2501617],
          [100.7016098, 5.2502831],
          [100.7013677, 5.2508711],
          [100.7011186, 5.2508585],
          [100.7011166, 5.250806],
          [100.7009231, 5.2500439],
          [100.7007778, 5.2498159],
          [100.7006767, 5.2497403],
          [100.7004425, 5.2497542],
          [100.7002857, 5.2498816],
          [100.7001574, 5.2503247],
          [100.6999958, 5.2504017],
          [100.6996829, 5.2500522],
          [100.6995669, 5.2497129],
          [100.6996319, 5.2493061],
          [100.6999105, 5.2491034],
          [100.7000655, 5.2489],
          [100.7000855, 5.2488231],
          [100.7001039, 5.2487009],
          [100.6999732, 5.2485107],
          [100.6998962, 5.2484457],
          [100.6994855, 5.2483664],
          [100.6991379, 5.2484218],
          [100.6988842, 5.2484397],
          [100.6984225, 5.2478514],
          [100.6983251, 5.2478245],
          [100.6976428, 5.2481367],
          [100.6972264, 5.2481911],
          [100.6969091, 5.2480161],
          [100.6967166, 5.2479185],
          [100.6964345, 5.2479125],
          [100.6963664, 5.2479806],
          [100.6963321, 5.2480832],
          [100.6965099, 5.2482158],
          [100.6967796, 5.2485081],
          [100.6966191, 5.2487718],
          [100.6962223, 5.249015],
          [100.6957342, 5.2491421],
          [100.6952893, 5.2487632],
          [100.6950427, 5.248735],
          [100.6946346, 5.2488805],
          [100.6940926, 5.2486022],
          [100.6940494, 5.2483954],
          [100.6946588, 5.2475618],
          [100.6946743, 5.247192],
          [100.6944317, 5.2469328],
          [100.6939302, 5.2469865],
          [100.6934695, 5.2472847],
          [100.6931492, 5.2473043],
          [100.6925949, 5.2466739],
          [100.6923449, 5.2465193],
          [100.6921035, 5.2464226],
          [100.6917794, 5.2461879],
          [100.6916996, 5.2457705],
          [100.6918248, 5.2453703],
          [100.6922102, 5.2452079],
          [100.6927686, 5.2453813],
          [100.6929737, 5.2452655],
          [100.6930917, 5.2450702],
          [100.6929756, 5.2447956],
          [100.6926561, 5.2446063],
          [100.6922194, 5.2444243],
          [100.6920482, 5.2442591],
          [100.6920242, 5.2438891],
          [100.6919314, 5.2436616],
          [100.6917429, 5.2435116],
          [100.6913867, 5.2435957],
          [100.6907454, 5.2437995],
          [100.6906054, 5.2436422],
          [100.6908401, 5.2431576],
          [100.690568, 5.2427709],
          [100.6900859, 5.2417006],
          [100.6899495, 5.2411941],
          [100.6901445, 5.2407107],
          [100.6902007, 5.2404102],
          [100.6900926, 5.2401121],
          [100.6899918, 5.2399159],
          [100.6898055, 5.2396332],
          [100.6896425, 5.2393819],
          [100.6896359, 5.2390999],
          [100.6899992, 5.2381546],
          [100.689819, 5.2377831],
          [100.6895849, 5.2377038],
          [100.6892572, 5.2376241],
          [100.6890388, 5.237537],
          [100.6889614, 5.2373644],
          [100.6889814, 5.2362915],
          [100.689004, 5.2360078],
          [100.6890675, 5.2357731],
          [100.68921, 5.2355606],
          [100.68974, 5.235401],
          [100.690098, 5.2353306],
          [100.6902662, 5.234948],
          [100.6900171, 5.2347433],
          [100.6892213, 5.2344894],
          [100.689058, 5.2343007],
          [100.6889392, 5.2337013],
          [100.6882706, 5.232931],
          [100.6880387, 5.2323192],
          [100.6873601, 5.2312219],
          [100.6870782, 5.2305575],
          [100.6871915, 5.2301096],
          [100.6876592, 5.2299382],
          [100.6879159, 5.2296583],
          [100.6880563, 5.2292145],
          [100.6879692, 5.2288419],
          [100.6875883, 5.2284903],
          [100.6872463, 5.2283266],
          [100.6868149, 5.2282794],
          [100.6864461, 5.2282316],
          [100.6860317, 5.2281618],
          [100.6858642, 5.2281116],
          [100.6855683, 5.2279816],
          [100.6853211, 5.2278173],
          [100.6852146, 5.2276072],
          [100.6851458, 5.2273603],
          [100.6851326, 5.2270754],
          [100.6851913, 5.2265011],
          [100.685233, 5.2262099],
          [100.6852749, 5.2257845],
          [100.6852145, 5.2251486],
          [100.6855395, 5.2246552],
          [100.6862519, 5.2238346],
          [100.6864366, 5.2237263],
          [100.6866298, 5.223467],
          [100.6860653, 5.2227984],
          [100.6857198, 5.2220147],
          [100.6856962, 5.2219588],
          [100.6854266, 5.2215688],
          [100.685229, 5.2215312],
          [100.6849469, 5.2215392],
          [100.684741, 5.2216014],
          [100.6845016, 5.221681],
          [100.6842793, 5.2216875],
          [100.6840734, 5.2216027],
          [100.6837408, 5.2210919],
          [100.6833623, 5.2202597],
          [100.6830221, 5.2201327],
          [100.6829372, 5.2201295],
          [100.6827281, 5.2201777],
          [100.6826207, 5.220235],
          [100.6824728, 5.2203826],
          [100.6823488, 5.2205582],
          [100.682225, 5.220712],
          [100.6819659, 5.2209321],
          [100.6819256, 5.220951],
          [100.6817514, 5.2209659],
          [100.6814211, 5.2208629],
          [100.6814263, 5.2205819],
          [100.6814679, 5.2203929],
          [100.6815773, 5.2202023],
          [100.6819635, 5.2198052],
          [100.6820436, 5.2196492],
          [100.6820459, 5.2193403],
          [100.681991, 5.219157],
          [100.6816496, 5.2186662],
          [100.6812455, 5.2184987],
          [100.6811233, 5.218469],
          [100.6806709, 5.2184211],
          [100.6802521, 5.2183902],
          [100.6797727, 5.2183715],
          [100.6795398, 5.2183512],
          [100.6790611, 5.218304],
          [100.6790256, 5.218291],
          [100.6786021, 5.2181234],
          [100.6783928, 5.2173891],
          [100.6782407, 5.2169236],
          [100.6781988, 5.2168596],
          [100.6781812, 5.216734],
          [100.6781995, 5.216585],
          [100.6782426, 5.2164637],
          [100.6782695, 5.2164187],
          [100.6783102, 5.2163699],
          [100.678754, 5.2160459],
          [100.6788295, 5.215962],
          [100.6788723, 5.2158585],
          [100.6788787, 5.2158039],
          [100.6788608, 5.2156049],
          [100.6788153, 5.2154456],
          [100.6787897, 5.2153912],
          [100.6787112, 5.2152633],
          [100.6785254, 5.2149984],
          [100.6782339, 5.214812],
          [100.6777912, 5.2145682],
          [100.6774414, 5.2143926],
          [100.6772156, 5.2142572],
          [100.677017, 5.2139851],
          [100.6769639, 5.2139061],
          [100.6768515, 5.2136924],
          [100.676632, 5.2130333],
          [100.676567, 5.2128689],
          [100.6764736, 5.212702],
          [100.6763821, 5.2124902],
          [100.6763686, 5.2124126],
          [100.6763638, 5.212309],
          [100.6763739, 5.2122473],
          [100.6764394, 5.2120811],
          [100.6765194, 5.2119219],
          [100.6765925, 5.211815],
          [100.6766918, 5.2116354],
          [100.6767289, 5.2115277],
          [100.6767369, 5.2114837],
          [100.676734, 5.2114106],
          [100.6766815, 5.2113036],
          [100.6765802, 5.2111776],
          [100.6764462, 5.2110533],
          [100.6763345, 5.2108983],
          [100.6762437, 5.2107153],
          [100.6762277, 5.2106565],
          [100.6761929, 5.2105093],
          [100.6761869, 5.2104745],
          [100.6762078, 5.2103031],
          [100.6765065, 5.2097273],
          [100.6760309, 5.209534],
          [100.6757243, 5.2094732],
          [100.675407, 5.2092978],
          [100.6751319, 5.2089527],
          [100.6748836, 5.2086008],
          [100.6745763, 5.2082367],
          [100.6743398, 5.2081307],
          [100.6741363, 5.2081095],
          [100.6739387, 5.208071],
          [100.6738018, 5.2080803],
          [100.6741388, 5.2073589],
          [100.6742002, 5.2072109],
          [100.6743134, 5.2067191],
          [100.6743343, 5.2065529],
          [100.6743232, 5.2064071],
          [100.6742735, 5.2060994],
          [100.6742075, 5.2059212],
          [100.6739355, 5.2057379],
          [100.6737244, 5.2056585],
          [100.6735899, 5.2055851],
          [100.6734259, 5.2054432],
          [100.6730105, 5.2049314],
          [100.67276, 5.2048402],
          [100.6723771, 5.2047228],
          [100.6720616, 5.2045765],
          [100.6720158, 5.2043819],
          [100.6720358, 5.2042371],
          [100.6721148, 5.2039405],
          [100.6721246, 5.2038403],
          [100.6721239, 5.2037553],
          [100.6720797, 5.2035237],
          [100.6720539, 5.2034325],
          [100.6718689, 5.2030269],
          [100.6717994, 5.2028224],
          [100.6717913, 5.2027005],
          [100.6717998, 5.2026003],
          [100.671835, 5.2024511],
          [100.6720225, 5.202106],
          [100.6720377, 5.2020671],
          [100.6720631, 5.2019397],
          [100.6720461, 5.201859],
          [100.6720344, 5.2018351],
          [100.6717187, 5.2015884],
          [100.6713574, 5.2013415],
          [100.6712911, 5.201236],
          [100.6712464, 5.2011449],
          [100.6711809, 5.2009582],
          [100.6711416, 5.2007821],
          [100.6710874, 5.20065],
          [100.6709422, 5.2003857],
          [100.6707035, 5.2002498],
          [100.6705858, 5.2002408],
          [100.670409, 5.2002882],
          [100.6703331, 5.2003187],
          [100.6700876, 5.200453],
          [100.6699585, 5.2005405],
          [100.6697396, 5.2005873],
          [100.6697295, 5.2002942],
          [100.6697407, 5.2001856],
          [100.669871, 5.1998488],
          [100.6699236, 5.1996552],
          [100.6699389, 5.199537],
          [100.6699298, 5.1992746],
          [100.6699153, 5.1991657],
          [100.6698777, 5.1989933],
          [100.6698058, 5.1987254],
          [100.6698005, 5.1985761],
          [100.6698377, 5.1983852],
          [100.6700857, 5.1982543],
          [100.670203, 5.1982417],
          [100.6707113, 5.1983245],
          [100.6705211, 5.1979317],
          [100.6703641, 5.1977258],
          [100.6700802, 5.1974048],
          [100.6695946, 5.1970349],
          [100.6694156, 5.1969681],
          [100.6691823, 5.1968942],
          [100.6689806, 5.1968502],
          [100.6687552, 5.1968125],
          [100.6684581, 5.1968122],
          [100.6682234, 5.1968248],
          [100.6680022, 5.1968551],
          [100.6679248, 5.1968955],
          [100.6677331, 5.1970452],
          [100.6677052, 5.1972542],
          [100.667741, 5.1974064],
          [100.6678077, 5.1976782],
          [100.6678115, 5.1979795],
          [100.6675875, 5.1979983],
          [100.6672412, 5.1978796],
          [100.6670472, 5.1978192],
          [100.6669423, 5.1977802],
          [100.6667807, 5.1977403],
          [100.6664755, 5.1976267],
          [100.6663053, 5.197206],
          [100.6662599, 5.1969564],
          [100.6662503, 5.1968503],
          [100.6662248, 5.1966444],
          [100.6661441, 5.1963807],
          [100.6660724, 5.1962301],
          [100.6658746, 5.1958928],
          [100.6657174, 5.195682],
          [100.6656011, 5.1955445],
          [100.6655273, 5.1954439],
          [100.6651343, 5.1949848],
          [100.6648777, 5.1946612],
          [100.6647132, 5.1945159],
          [100.6645792, 5.1943707],
          [100.6645069, 5.1942525],
          [100.6644314, 5.1940423],
          [100.6644046, 5.193852],
          [100.6644173, 5.1936089],
          [100.664463, 5.1934421],
          [100.6645617, 5.1932085],
          [100.6646313, 5.1929922],
          [100.6647045, 5.1926907],
          [100.6647061, 5.192424],
          [100.6646997, 5.1923957],
          [100.6645309, 5.1921055],
          [100.6643167, 5.1921033],
          [100.664259, 5.1921011],
          [100.6641359, 5.1921226],
          [100.6640424, 5.1920945],
          [100.6637968, 5.1919525],
          [100.6637361, 5.191671],
          [100.6637757, 5.1914683],
          [100.6638612, 5.1912663],
          [100.6639964, 5.1908976],
          [100.6640139, 5.1906983],
          [100.6639922, 5.1905493],
          [100.6639092, 5.1902424],
          [100.6636136, 5.1900973],
          [100.6634392, 5.1900401],
          [100.663168, 5.1900207],
          [100.6630297, 5.1900222],
          [100.6627769, 5.1900533],
          [100.66256, 5.1900837],
          [100.6624815, 5.1900848],
          [100.6623287, 5.1900666],
          [100.662155, 5.1899872],
          [100.661962, 5.1898512],
          [100.661869, 5.1896475],
          [100.6618366, 5.1895112],
          [100.6618023, 5.1893033],
          [100.6617958, 5.1890999],
          [100.6618106, 5.1888163],
          [100.6618096, 5.1887178],
          [100.6618127, 5.1884024],
          [100.6618476, 5.1880058],
          [100.661851, 5.1878926],
          [100.6618054, 5.1874081],
          [100.6617971, 5.1871313],
          [100.6618167, 5.1868872],
          [100.6618308, 5.1868075],
          [100.6618422, 5.1865993],
          [100.6617909, 5.186336],
          [100.6616859, 5.1860588],
          [100.6615829, 5.1858943],
          [100.6613425, 5.1855887],
          [100.6608835, 5.1852072],
          [100.6605825, 5.1849647],
          [100.6604148, 5.184772],
          [100.6603429, 5.1846218],
          [100.6603126, 5.1845125],
          [100.6603141, 5.1843995],
          [100.6603232, 5.1843324],
          [100.6603958, 5.184111],
          [100.6605098, 5.1838382],
          [100.6605644, 5.1836808],
          [100.6606064, 5.183446],
          [100.660604, 5.1833591],
          [100.6605886, 5.1832624],
          [100.6605586, 5.1830918],
          [100.6604711, 5.1828689],
          [100.6603959, 5.1827414],
          [100.6601773, 5.1824662],
          [100.6596559, 5.1821201],
          [100.6593687, 5.1819029],
          [100.6588765, 5.1814755],
          [100.6587381, 5.1813715],
          [100.6586317, 5.1812621],
          [100.6585821, 5.1812165],
          [100.6585426, 5.1811921],
          [100.6582691, 5.1810811],
          [100.6580574, 5.1810678],
          [100.6579672, 5.1810746],
          [100.6577095, 5.1811277],
          [100.6575735, 5.1811753],
          [100.6573452, 5.1813284],
          [100.6572644, 5.1813733],
          [100.6568836, 5.1815306],
          [100.6565627, 5.1816016],
          [100.6564615, 5.1816146],
          [100.6561056, 5.1816268],
          [100.6559301, 5.1816162],
          [100.6558631, 5.181601],
          [100.6557375, 5.1815553],
          [100.6556658, 5.1815197],
          [100.6554872, 5.1813996],
          [100.655292, 5.1812401],
          [100.6552289, 5.1812139],
          [100.6549712, 5.181151],
          [100.6549158, 5.1816121],
          [100.6548754, 5.181761],
          [100.654719, 5.1820978],
          [100.6545572, 5.1822865],
          [100.6543378, 5.182475],
          [100.6541624, 5.1827177],
          [100.6540704, 5.1828695],
          [100.6540414, 5.1829586],
          [100.6539897, 5.1830767],
          [100.6538404, 5.1835764],
          [100.6536631, 5.1836386],
          [100.6536134, 5.18364],
          [100.6535107, 5.1835937],
          [100.6532882, 5.1834524],
          [100.6531449, 5.1833828],
          [100.6528309, 5.1832761],
          [100.6526094, 5.1832427],
          [100.6525253, 5.1832384],
          [100.6522949, 5.1832706],
          [100.6521534, 5.1833002],
          [100.6518522, 5.1835055],
          [100.6516273, 5.1836327],
          [100.651593, 5.1836401],
          [100.6515303, 5.1836245],
          [100.651319, 5.1834806],
          [100.6512116, 5.183265],
          [100.6511425, 5.1831023],
          [100.6510625, 5.1828993],
          [100.6510341, 5.1828058],
          [100.651016, 5.1826497],
          [100.6510212, 5.1825523],
          [100.6510671, 5.1824036],
          [100.6514013, 5.1819064],
          [100.6514932, 5.1816808],
          [100.6515283, 5.1814869],
          [100.6515311, 5.1812695],
          [100.6515601, 5.1810801],
          [100.6516094, 5.1808911],
          [100.6516499, 5.1807927],
          [100.6517501, 5.1806268],
          [100.6518447, 5.1805245],
          [100.6519727, 5.1803547],
          [100.6520025, 5.1803047],
          [100.6520391, 5.1802155],
          [100.6520447, 5.1801191],
          [100.6520379, 5.1800388],
          [100.6520127, 5.179957],
          [100.6519071, 5.1797324],
          [100.6512799, 5.1792746],
          [100.6511547, 5.179205],
          [100.6509932, 5.1791323],
          [100.6508133, 5.1790733],
          [100.6506295, 5.1790019],
          [100.6503663, 5.1788364],
          [100.6502457, 5.1786358],
          [100.6500643, 5.1782177],
          [100.6499769, 5.1780341],
          [100.6498529, 5.1777079],
          [100.6497284, 5.1774074],
          [100.6496105, 5.1772154],
          [100.6494957, 5.1770641],
          [100.6491369, 5.1766468],
          [100.6489896, 5.1764369],
          [100.6488909, 5.1762727],
          [100.6488525, 5.1761351],
          [100.6488772, 5.1759816],
          [100.6489224, 5.1758512],
          [100.6491009, 5.1754726],
          [100.649394, 5.1753483],
          [100.6496702, 5.1752811],
          [100.6500575, 5.1752457],
          [100.6502558, 5.1752413],
          [100.6504315, 5.1752406],
          [100.6505313, 5.1752105],
          [100.6506606, 5.1750326],
          [100.6505257, 5.1747464],
          [100.6504671, 5.1746975],
          [100.6503566, 5.174623],
          [100.6501684, 5.1745308],
          [100.6500559, 5.1745118],
          [100.649672, 5.1745632],
          [100.6494943, 5.1746566],
          [100.6489745, 5.174844],
          [100.6487466, 5.1749092],
          [100.6485072, 5.1749643],
          [100.6483625, 5.1750009],
          [100.6480812, 5.1751059],
          [100.6480043, 5.1751261],
          [100.6478505, 5.1751523],
          [100.6475231, 5.1751485],
          [100.6475258, 5.1749035],
          [100.647533, 5.1748759],
          [100.6477346, 5.174613],
          [100.6477584, 5.1745718],
          [100.6477461, 5.1742774],
          [100.6475915, 5.1741868],
          [100.6471963, 5.1743469],
          [100.6470464, 5.174392],
          [100.6468428, 5.1744342],
          [100.6467345, 5.1744473],
          [100.6466811, 5.1744385],
          [100.6466137, 5.1744057],
          [100.646414, 5.1742673],
          [100.6463125, 5.1739929],
          [100.6462924, 5.1738751],
          [100.6462844, 5.1737584],
          [100.6462918, 5.1737031],
          [100.6464063, 5.1733433],
          [100.6464149, 5.173163],
          [100.6463623, 5.1728716],
          [100.646044, 5.1728001],
          [100.6459992, 5.1727969],
          [100.6457769, 5.1728594],
          [100.6454185, 5.1730433],
          [100.6451376, 5.1731318],
          [100.6449653, 5.1731493],
          [100.644858, 5.1731444],
          [100.6445158, 5.1730633],
          [100.6443417, 5.172975],
          [100.6442975, 5.172948],
          [100.6441506, 5.1728293],
          [100.6439818, 5.172681],
          [100.6438159, 5.1726121],
          [100.6436615, 5.1727015],
          [100.6433836, 5.1731557],
          [100.6433244, 5.1731936],
          [100.6430832, 5.173312],
          [100.6427157, 5.1734695],
          [100.642607, 5.1735083],
          [100.6422854, 5.1735416],
          [100.6422195, 5.1732746],
          [100.6423532, 5.1729553],
          [100.6424951, 5.1726454],
          [100.6425212, 5.1725332],
          [100.6424674, 5.1723581],
          [100.6421015, 5.172124],
          [100.6418504, 5.1720233],
          [100.6416728, 5.1719358],
          [100.6415033, 5.1718397],
          [100.6414205, 5.1717839],
          [100.6412593, 5.1716517],
          [100.6411557, 5.1713084],
          [100.6411501, 5.1711499],
          [100.6411555, 5.1709328],
          [100.641153, 5.17078],
          [100.6411216, 5.1705758],
          [100.6410675, 5.1704074],
          [100.6409948, 5.1702976],
          [100.6405357, 5.1699393],
          [100.6399927, 5.1695544],
          [100.639817, 5.1693337],
          [100.6394307, 5.1690342],
          [100.6390749, 5.1687348],
          [100.6389376, 5.1685426],
          [100.6387867, 5.1682866],
          [100.6386218, 5.1680733],
          [100.6383497, 5.167974],
          [100.6379718, 5.1679287],
          [100.6377834, 5.167879],
          [100.6375981, 5.1678018],
          [100.6372766, 5.1676032],
          [100.6369653, 5.1673749],
          [100.6368588, 5.167315],
          [100.6367423, 5.1672719],
          [100.6366742, 5.1672621],
          [100.6365577, 5.1672686],
          [100.6363498, 5.1673068],
          [100.6361452, 5.1673642],
          [100.6357367, 5.1675477],
          [100.6356543, 5.1676069],
          [100.6355347, 5.1677249],
          [100.6353936, 5.1678135],
          [100.635234, 5.1678653],
          [100.635085, 5.1676317],
          [100.6350535, 5.1675272],
          [100.6349666, 5.1670828],
          [100.634927, 5.1669664],
          [100.63483, 5.1667258],
          [100.6347558, 5.166452],
          [100.6344554, 5.1661401],
          [100.6338183, 5.1656366],
          [100.6337398, 5.1655314],
          [100.6336836, 5.1654397],
          [100.6335967, 5.1652705],
          [100.6335558, 5.1651478],
          [100.6335248, 5.1649948],
          [100.6335108, 5.1648679],
          [100.6334121, 5.164609],
          [100.6332129, 5.1646495],
          [100.6326918, 5.1648913],
          [100.6324609, 5.1649583],
          [100.6323013, 5.164987],
          [100.6319782, 5.1649947],
          [100.6316867, 5.1650928],
          [100.6315059, 5.1651299],
          [100.6313575, 5.165117],
          [100.6312641, 5.1650945],
          [100.631008, 5.1649966],
          [100.630882, 5.1649743],
          [100.6307916, 5.1649763],
          [100.6305677, 5.1650038],
          [100.6302623, 5.1650737],
          [100.6301322, 5.1650613],
          [100.6298614, 5.1649635],
          [100.6298286, 5.1646516],
          [100.6299102, 5.1643358],
          [100.630061, 5.163859],
          [100.630156, 5.1635368],
          [100.6301974, 5.1633701],
          [100.6302085, 5.1631756],
          [100.6301549, 5.1629813],
          [100.6300306, 5.1627895],
          [100.629808, 5.1627457],
          [100.6293475, 5.1629978],
          [100.629279, 5.1631185],
          [100.6292664, 5.163292],
          [100.6292705, 5.163331],
          [100.6293309, 5.163522],
          [100.6294992, 5.1639016],
          [100.6295641, 5.1641393],
          [100.629458, 5.1643234],
          [100.6291689, 5.1641772],
          [100.6288345, 5.1638658],
          [100.6285807, 5.1634516],
          [100.6285486, 5.1633385],
          [100.628521, 5.1631523],
          [100.6285093, 5.162962],
          [100.6285234, 5.1622541],
          [100.6285197, 5.1620347],
          [100.6285322, 5.1617006],
          [100.6284654, 5.1605866],
          [100.6284706, 5.160497],
          [100.6285123, 5.1603439],
          [100.6285634, 5.1602319],
          [100.628698, 5.1600267],
          [100.6288619, 5.1597884],
          [100.6290398, 5.1594924],
          [100.6290893, 5.1594037],
          [100.6291352, 5.1592812],
          [100.6291858, 5.1591011],
          [100.6291899, 5.1589835],
          [100.6291754, 5.1588805],
          [100.629086, 5.1587259],
          [100.6289542, 5.1586102],
          [100.6289287, 5.1585988],
          [100.6288449, 5.1585891],
          [100.6287834, 5.1585916],
          [100.6283359, 5.1586884],
          [100.6282444, 5.1587032],
          [100.628011, 5.1586379],
          [100.6279136, 5.1577256],
          [100.6279034, 5.1574173],
          [100.6279009, 5.1571994],
          [100.6279258, 5.1569658],
          [100.6279414, 5.1566034],
          [100.6279395, 5.1564956],
          [100.6278936, 5.1562056],
          [100.6276249, 5.1559642],
          [100.6275856, 5.1559438],
          [100.6274327, 5.1559198],
          [100.6270975, 5.1559796],
          [100.6269904, 5.1559362],
          [100.6268826, 5.1557267],
          [100.6270295, 5.1554643],
          [100.6271027, 5.155422],
          [100.6272209, 5.1553783],
          [100.6273608, 5.1553741],
          [100.6274867, 5.1553931],
          [100.6277519, 5.1554822],
          [100.6282594, 5.155511],
          [100.6285388, 5.1552788],
          [100.6286118, 5.1551448],
          [100.6286631, 5.1549514],
          [100.6286537, 5.1545789],
          [100.6286366, 5.154426],
          [100.6285567, 5.1540855],
          [100.6283111, 5.1539363],
          [100.6281676, 5.1538873],
          [100.6279286, 5.1538448],
          [100.6277441, 5.1537862],
          [100.6275925, 5.153477],
          [100.6274807, 5.1531948],
          [100.6273096, 5.152822],
          [100.6271917, 5.1526439],
          [100.6270303, 5.1524461],
          [100.6269951, 5.1523785],
          [100.6269736, 5.1523141],
          [100.6269694, 5.152237],
          [100.6270106, 5.1519084],
          [100.6270694, 5.1516788],
          [100.627172, 5.1514502],
          [100.6272071, 5.1513208],
          [100.6272598, 5.1511015],
          [100.6272716, 5.1509691],
          [100.627182, 5.1506041],
          [100.6270716, 5.1504595],
          [100.626946, 5.1504364],
          [100.6268914, 5.150451],
          [100.6267779, 5.1505035],
          [100.6264773, 5.1507379],
          [100.6262039, 5.1510898],
          [100.6259131, 5.1514144],
          [100.6257655, 5.1515082],
          [100.6256066, 5.1515174],
          [100.6255019, 5.151237],
          [100.625458, 5.1510553],
          [100.6253877, 5.1509026],
          [100.6252915, 5.1507643],
          [100.6252739, 5.1505911],
          [100.6253189, 5.1504174],
          [100.6253601, 5.1501602],
          [100.6253612, 5.1498875],
          [100.625312, 5.1496336],
          [100.6252236, 5.1493966],
          [100.6251784, 5.149251],
          [100.6251494, 5.1491013],
          [100.6251514, 5.1490002],
          [100.6252119, 5.1486047],
          [100.6253133, 5.1484662],
          [100.6255708, 5.1484188],
          [100.6256927, 5.1484584],
          [100.6259261, 5.1486674],
          [100.6260462, 5.1488086],
          [100.6261701, 5.1489179],
          [100.6263354, 5.1489704],
          [100.6264804, 5.1489244],
          [100.6265851, 5.1488109],
          [100.6266604, 5.148676],
          [100.6267863, 5.1484323],
          [100.6268146, 5.1483246],
          [100.626838, 5.1481654],
          [100.6268394, 5.1477518],
          [100.626791, 5.1473533],
          [100.6267178, 5.1469104],
          [100.6265394, 5.1468579],
          [100.6264329, 5.1468562],
          [100.6263971, 5.1468628],
          [100.6262794, 5.1469154],
          [100.6259234, 5.1471125],
          [100.6256665, 5.1473036],
          [100.6254853, 5.1473481],
          [100.6253887, 5.14727],
          [100.6253644, 5.1471485],
          [100.6253625, 5.147087],
          [100.6253779, 5.1470274],
          [100.6255392, 5.1466145],
          [100.6255896, 5.1464506],
          [100.62562, 5.1463134],
          [100.6256344, 5.1460815],
          [100.6256278, 5.1458642],
          [100.6256069, 5.1456897],
          [100.6255841, 5.1456032],
          [100.6254611, 5.1452518],
          [100.625299, 5.1448823],
          [100.6250815, 5.1445631],
          [100.624526, 5.144139],
          [100.6244391, 5.1439157],
          [100.6244247, 5.1438554],
          [100.6243928, 5.1435792],
          [100.6244018, 5.1434287],
          [100.6244858, 5.1432728],
          [100.6245562, 5.143191],
          [100.6252818, 5.1428511],
          [100.6254736, 5.1425077],
          [100.6255533, 5.142297],
          [100.6255629, 5.1422503],
          [100.6255706, 5.1420351],
          [100.6255604, 5.1418845],
          [100.6255174, 5.1416017],
          [100.6254903, 5.1415049],
          [100.6253956, 5.1413214],
          [100.6252804, 5.1411459],
          [100.6252281, 5.1410492],
          [100.625114, 5.140901],
          [100.6250067, 5.1407829],
          [100.6249659, 5.140665],
          [100.6249251, 5.1405631],
          [100.6247826, 5.1405116],
          [100.6246558, 5.1405047],
          [100.6246257, 5.1405215],
          [100.624554, 5.1405615],
          [100.6244332, 5.1406182],
          [100.6243568, 5.1406847],
          [100.6239935, 5.1411998],
          [100.6238255, 5.1413773],
          [100.6236107, 5.1415458],
          [100.62351, 5.141611],
          [100.6233895, 5.1416698],
          [100.6231979, 5.1417248],
          [100.6230843, 5.1417374],
          [100.6229435, 5.1417361],
          [100.6227783, 5.1416956],
          [100.6224468, 5.1415486],
          [100.6222553, 5.1413395],
          [100.622175, 5.1412105],
          [100.6220922, 5.1410062],
          [100.6220418, 5.1408374],
          [100.6220299, 5.1407559],
          [100.6220326, 5.1405982],
          [100.6220779, 5.14044],
          [100.6223104, 5.1401805],
          [100.6225709, 5.1398506],
          [100.6228206, 5.139552],
          [100.6228484, 5.1395085],
          [100.6230063, 5.1392095],
          [100.6230341, 5.1391301],
          [100.6231495, 5.1386641],
          [100.6232014, 5.1383939],
          [100.6231992, 5.1382838],
          [100.6231878, 5.1382071],
          [100.6231551, 5.1380688],
          [100.623129, 5.1380081],
          [100.6228091, 5.1377903],
          [100.6225963, 5.1377912],
          [100.6223001, 5.1381089],
          [100.6221895, 5.138281],
          [100.6221362, 5.1383497],
          [100.6219041, 5.1385431],
          [100.6216127, 5.1386133],
          [100.6213238, 5.1385224],
          [100.6210585, 5.1383293],
          [100.6209224, 5.1381615],
          [100.6208827, 5.1381005],
          [100.6207106, 5.1377662],
          [100.6204133, 5.1375785],
          [100.6202451, 5.1375215],
          [100.6201312, 5.137502],
          [100.6200174, 5.1374927],
          [100.6198254, 5.1375065],
          [100.6192533, 5.1378446],
          [100.6191279, 5.1378939],
          [100.6190661, 5.1379053],
          [100.6189574, 5.1379083],
          [100.6188814, 5.1378847],
          [100.6187706, 5.1378639],
          [100.618642, 5.1377555],
          [100.6186103, 5.1374749],
          [100.6186337, 5.1374179],
          [100.618889, 5.1370756],
          [100.6189935, 5.1368678],
          [100.6190983, 5.1366419],
          [100.6191095, 5.1366008],
          [100.6191292, 5.1363746],
          [100.61906, 5.1360976],
          [100.6189898, 5.1359649],
          [100.6188792, 5.1358623],
          [100.6185172, 5.1352707],
          [100.61841, 5.1350286],
          [100.6184047, 5.1349738],
          [100.6184338, 5.1347029],
          [100.6185809, 5.1343908],
          [100.6186095, 5.13435],
          [100.6189624, 5.1340962],
          [100.6192909, 5.1338425],
          [100.6193835, 5.1336939],
          [100.6194001, 5.133631],
          [100.6193538, 5.133476],
          [100.6192443, 5.1332959],
          [100.6179321, 5.133217],
          [100.6177577, 5.1332279],
          [100.6173758, 5.1332964],
          [100.6170066, 5.1333936],
          [100.6168179, 5.1334687],
          [100.616757, 5.1335074],
          [100.6165517, 5.1336913],
          [100.6163609, 5.133843],
          [100.6163197, 5.1338669],
          [100.6160996, 5.1339475],
          [100.616067, 5.1339499],
          [100.6158983, 5.1339128],
          [100.6156987, 5.1337514],
          [100.6154104, 5.1333922],
          [100.6151345, 5.1333144],
          [100.6150811, 5.1333128],
          [100.6148921, 5.1333308],
          [100.6148313, 5.1333607],
          [100.6147015, 5.1334642],
          [100.6144379, 5.1336939],
          [100.6142476, 5.133872],
          [100.6140979, 5.1339635],
          [100.6140272, 5.1339886],
          [100.6138924, 5.1340106],
          [100.613841, 5.1339989],
          [100.6137158, 5.1339497],
          [100.6134153, 5.1336822],
          [100.6131147, 5.1334348],
          [100.6129644, 5.1333843],
          [100.6127464, 5.1333431],
          [100.6125238, 5.1333784],
          [100.6123795, 5.1334411],
          [100.6121274, 5.1334783],
          [100.6120392, 5.1334757],
          [100.6119317, 5.1334612],
          [100.6118893, 5.1334442],
          [100.6117312, 5.1333558],
          [100.611556, 5.1331743],
          [100.611381, 5.1329657],
          [100.6111587, 5.1328131],
          [100.6109984, 5.1327378],
          [100.6108622, 5.132702],
          [100.6106275, 5.132665],
          [100.6105066, 5.1326631],
          [100.6101976, 5.132675],
          [100.6098546, 5.132722],
          [100.6093068, 5.1328523],
          [100.6090422, 5.1329457],
          [100.6082599, 5.1334236],
          [100.6079759, 5.1337784],
          [100.6077049, 5.1341533],
          [100.6074126, 5.1345159],
          [100.6071951, 5.1346127],
          [100.6070777, 5.1346289],
          [100.6069109, 5.1346159],
          [100.6066959, 5.1344995],
          [100.6065553, 5.1342134],
          [100.6065679, 5.1339647],
          [100.6065788, 5.1339249],
          [100.6067124, 5.1336768],
          [100.6071101, 5.1334698],
          [100.6071915, 5.1334084],
          [100.6074177, 5.1332034],
          [100.6074626, 5.1329625],
          [100.6073639, 5.1326193],
          [100.6071353, 5.1325316],
          [100.6069037, 5.1324939],
          [100.6062831, 5.1325047],
          [100.6060981, 5.1324897],
          [100.6059116, 5.1324369],
          [100.6058107, 5.1324247],
          [100.6055956, 5.1323697],
          [100.6052146, 5.1322269],
          [100.6047639, 5.1319917],
          [100.6045196, 5.131612],
          [100.6044678, 5.1313763],
          [100.6044674, 5.1313432],
          [100.6044994, 5.131209],
          [100.6045395, 5.1311084],
          [100.6046251, 5.1309636],
          [100.6048942, 5.1309086],
          [100.6053594, 5.1308998],
          [100.6057404, 5.1308045],
          [100.6057567, 5.1306123],
          [100.6057222, 5.1304904],
          [100.6056987, 5.1304434],
          [100.605539, 5.1302505],
          [100.6052525, 5.1298439],
          [100.604969, 5.1294738],
          [100.6048065, 5.1292491],
          [100.6047736, 5.1291625],
          [100.6047426, 5.1289939],
          [100.6047484, 5.1287586],
          [100.6047754, 5.1286068],
          [100.6048765, 5.1283222],
          [100.605146, 5.1277804],
          [100.6052101, 5.1276126],
          [100.6051893, 5.1274003],
          [100.6050895, 5.1272361],
          [100.604874, 5.126992],
          [100.6046221, 5.1268342],
          [100.6044055, 5.1268694],
          [100.6043088, 5.1270433],
          [100.6042534, 5.1271872],
          [100.6042463, 5.1272956],
          [100.6042659, 5.1278322],
          [100.604247, 5.1281785],
          [100.6041896, 5.1282937],
          [100.604037, 5.1285237],
          [100.6035308, 5.1288996],
          [100.6034884, 5.1289218],
          [100.6032201, 5.1290344],
          [100.602875, 5.1290694],
          [100.6027461, 5.1287802],
          [100.6027768, 5.1286481],
          [100.6028668, 5.1284656],
          [100.6031172, 5.1280755],
          [100.6032613, 5.1277786],
          [100.6032761, 5.1277334],
          [100.6033502, 5.1273767],
          [100.6033555, 5.1273201],
          [100.6033508, 5.1268892],
          [100.603343, 5.1268139],
          [100.6032163, 5.1264418],
          [100.6031798, 5.1263848],
          [100.603018, 5.1261855],
          [100.6028698, 5.1260887],
          [100.602764, 5.1260308],
          [100.6026919, 5.1259719],
          [100.6023991, 5.1257884],
          [100.602227, 5.1256625],
          [100.6021737, 5.125562],
          [100.6021201, 5.1254918],
          [100.6020013, 5.1254519],
          [100.6019115, 5.125433],
          [100.6017111, 5.1254513],
          [100.6015417, 5.1254836],
          [100.6014342, 5.1255251],
          [100.6012231, 5.1256218],
          [100.6010013, 5.1257026],
          [100.6006845, 5.1257697],
          [100.6004973, 5.1257927],
          [100.6003014, 5.1257936],
          [100.6001035, 5.1258211],
          [100.5998532, 5.1259395],
          [100.5993758, 5.1262488],
          [100.5992732, 5.1263254],
          [100.5992179, 5.1263547],
          [100.599007, 5.1263971],
          [100.5986258, 5.1264074],
          [100.5980826, 5.1266252],
          [100.5979376, 5.126713],
          [100.5977795, 5.1267639],
          [100.5977164, 5.1267709],
          [100.5975412, 5.1267345],
          [100.5974838, 5.1264376],
          [100.5974871, 5.1264003],
          [100.5975393, 5.1262659],
          [100.597643, 5.1261392],
          [100.5977798, 5.1260126],
          [100.5978224, 5.1259431],
          [100.5978308, 5.1257991],
          [100.5977812, 5.1255463],
          [100.5977613, 5.125504],
          [100.5976048, 5.1253207],
          [100.5973775, 5.1251204],
          [100.5971779, 5.1249203],
          [100.5970302, 5.1247182],
          [100.5969015, 5.1245189],
          [100.5965444, 5.1243493],
          [100.5963923, 5.1243803],
          [100.5963439, 5.1245165],
          [100.5963483, 5.1246157],
          [100.5963758, 5.1247213],
          [100.5964224, 5.1248759],
          [100.5965075, 5.1250062],
          [100.5965728, 5.1251368],
          [100.5966448, 5.1253771],
          [100.5966547, 5.125469],
          [100.5966623, 5.1258553],
          [100.5965716, 5.126201],
          [100.5963881, 5.1267647],
          [100.5961914, 5.1271884],
          [100.5958926, 5.1272518],
          [100.5958163, 5.1272417],
          [100.5956544, 5.1271744],
          [100.5953786, 5.1264724],
          [100.5952388, 5.126256],
          [100.5950218, 5.1260617],
          [100.5948436, 5.1258675],
          [100.5948026, 5.1257816],
          [100.5947453, 5.1256009],
          [100.5947323, 5.1253304],
          [100.594793, 5.1249528],
          [100.5947903, 5.1249155],
          [100.5947931, 5.1246646],
          [100.5947601, 5.1244566],
          [100.594689, 5.124343],
          [100.5945234, 5.1242932],
          [100.5943462, 5.1243226],
          [100.5941575, 5.1243898],
          [100.5940249, 5.1245404],
          [100.593956, 5.1246517],
          [100.5937533, 5.1248286],
          [100.5936845, 5.12488],
          [100.5935959, 5.1249215],
          [100.5935115, 5.1249508],
          [100.5933572, 5.1249291],
          [100.5930822, 5.1248206],
          [100.5928968, 5.1245722],
          [100.5928409, 5.1244793],
          [100.5928094, 5.1243954],
          [100.592795, 5.1242475],
          [100.5928169, 5.1240953],
          [100.5928572, 5.1239884],
          [100.5929033, 5.123923],
          [100.5930516, 5.12379],
          [100.5931603, 5.123709],
          [100.5932966, 5.1236367],
          [100.5933892, 5.1236035],
          [100.5935671, 5.1235248],
          [100.5936484, 5.1234762],
          [100.5938267, 5.1233499],
          [100.5938651, 5.123176],
          [100.5938009, 5.1230542],
          [100.5936359, 5.1230175],
          [100.5934916, 5.12301],
          [100.5933594, 5.1230232],
          [100.5927468, 5.1231866],
          [100.592537, 5.1232365],
          [100.5924761, 5.1232425],
          [100.5922384, 5.1232228],
          [100.5921188, 5.1231889],
          [100.5919993, 5.1231677],
          [100.5917314, 5.1230387],
          [100.591639, 5.1228158],
          [100.5916348, 5.1227644],
          [100.5916583, 5.1226802],
          [100.5917401, 5.1224718],
          [100.5920611, 5.1222676],
          [100.5922095, 5.1221544],
          [100.5923946, 5.1220695],
          [100.5924583, 5.1219686],
          [100.5924994, 5.1218352],
          [100.5924647, 5.1215989],
          [100.5922354, 5.1214781],
          [100.59209, 5.1214683],
          [100.5920291, 5.1214839],
          [100.5917348, 5.1216187],
          [100.5914595, 5.1216591],
          [100.5913813, 5.121659],
          [100.5911459, 5.121625],
          [100.5910865, 5.1216066],
          [100.5907573, 5.121455],
          [100.5905281, 5.1213311],
          [100.5904148, 5.1213128],
          [100.5901689, 5.121384],
          [100.5900509, 5.1215935],
          [100.5901567, 5.121747],
          [100.5902555, 5.1218684],
          [100.5903152, 5.1220217],
          [100.5902335, 5.1220391],
          [100.5901094, 5.122003],
          [100.5898621, 5.1217457],
          [100.589598, 5.1212498],
          [100.5895582, 5.1211527],
          [100.5894404, 5.1209126],
          [100.5894059, 5.1207537],
          [100.5893575, 5.1204191],
          [100.5893591, 5.1201894],
          [100.5893664, 5.1200969],
          [100.5894215, 5.1199673],
          [100.5895925, 5.1196434],
          [100.5899004, 5.1195568],
          [100.5900479, 5.1195778],
          [100.5902271, 5.1196705],
          [100.5904534, 5.1197622],
          [100.5907225, 5.1198536],
          [100.5907873, 5.1198625],
          [100.5909979, 5.1197939],
          [100.5910924, 5.119625],
          [100.5911132, 5.1195274],
          [100.5910356, 5.1192276],
          [100.5909014, 5.1190417],
          [100.590837, 5.1189314],
          [100.5907952, 5.1188163],
          [100.5907726, 5.1186079],
          [100.5907348, 5.1183757],
          [100.5907153, 5.1180895],
          [100.5905607, 5.1175959],
          [100.5906045, 5.1173244],
          [100.5906416, 5.1172639],
          [100.5910836, 5.1170279],
          [100.5914154, 5.1167625],
          [100.5914786, 5.116524],
          [100.591467, 5.1162361],
          [100.5914481, 5.1161419],
          [100.5914099, 5.1160146],
          [100.5913709, 5.1157832],
          [100.5913966, 5.1155926],
          [100.5914158, 5.1155218],
          [100.5914552, 5.1154221],
          [100.5915144, 5.1153181],
          [100.591747, 5.1150035],
          [100.591875, 5.1148692],
          [100.5919208, 5.1147356],
          [100.5919415, 5.114523],
          [100.5919201, 5.1144195],
          [100.591833, 5.1142044],
          [100.5916835, 5.1138925],
          [100.5912977, 5.1137812],
          [100.5912107, 5.1137706],
          [100.5910127, 5.1137194],
          [100.5909351, 5.1136858],
          [100.5907966, 5.1136553],
          [100.5907257, 5.1136581],
          [100.5905837, 5.1136791],
          [100.5903791, 5.1137875],
          [100.5901619, 5.1138904],
          [100.589896, 5.1139663],
          [100.5896507, 5.1139219],
          [100.5895594, 5.1136852],
          [100.5895389, 5.1135551],
          [100.5895435, 5.1135152],
          [100.5896111, 5.1133328],
          [100.5898205, 5.113012],
          [100.5898647, 5.1129143],
          [100.5899019, 5.1126657],
          [100.5899029, 5.112604],
          [100.5898783, 5.1124735],
          [100.5898119, 5.112301],
          [100.5896923, 5.1120779],
          [100.5889223, 5.1116469],
          [100.5887843, 5.1114234],
          [100.588765, 5.1113624],
          [100.5887113, 5.1111132],
          [100.5886833, 5.1106039],
          [100.5886343, 5.1104553],
          [100.5885461, 5.1102654],
          [100.588493, 5.1101843],
          [100.5883648, 5.1100574],
          [100.5882587, 5.110009],
          [100.5880826, 5.1098417],
          [100.5879186, 5.1097018],
          [100.5876784, 5.109579],
          [100.5874968, 5.1095236],
          [100.5874002, 5.1094885],
          [100.5872993, 5.1094656],
          [100.5871458, 5.109448],
          [100.5869706, 5.1093085],
          [100.5869826, 5.1091248],
          [100.5874466, 5.108715],
          [100.5874993, 5.1086095],
          [100.5875742, 5.108432],
          [100.5876625, 5.1081451],
          [100.5877333, 5.1079908],
          [100.5877746, 5.1079122],
          [100.5880056, 5.1075917],
          [100.5880901, 5.1074554],
          [100.5881763, 5.1072313],
          [100.5881709, 5.1070008],
          [100.5880692, 5.106745],
          [100.5879855, 5.1066119],
          [100.5877693, 5.1065386],
          [100.587619, 5.1065139],
          [100.5874625, 5.1065433],
          [100.5873119, 5.106597],
          [100.5871731, 5.1066989],
          [100.5870825, 5.1067829],
          [100.5869542, 5.1069701],
          [100.5867931, 5.1071042],
          [100.58671, 5.1071453],
          [100.5866047, 5.1071694],
          [100.5865039, 5.1071652],
          [100.5863872, 5.1071381],
          [100.5862345, 5.1070541],
          [100.5859916, 5.1068818],
          [100.5859093, 5.1066421],
          [100.5858862, 5.1065431],
          [100.5858445, 5.1061748],
          [100.5858252, 5.1060811],
          [100.5857651, 5.105935],
          [100.5857355, 5.1058948],
          [100.5855065, 5.1057211],
          [100.5854671, 5.1057062],
          [100.5853393, 5.1056822],
          [100.585134, 5.1056572],
          [100.5848953, 5.1056675],
          [100.5842466, 5.1057145],
          [100.5840265, 5.1057105],
          [100.5836197, 5.1056721],
          [100.5832677, 5.105597],
          [100.5829776, 5.1055638],
          [100.5827853, 5.1055923],
          [100.582288, 5.1058825],
          [100.5821008, 5.1058776],
          [100.5819792, 5.1057628],
          [100.5819535, 5.1055975],
          [100.5819486, 5.1055451],
          [100.5819735, 5.1054698],
          [100.5820332, 5.1053574],
          [100.5822277, 5.1051456],
          [100.5824184, 5.1049052],
          [100.5825136, 5.1047552],
          [100.5826332, 5.1044867],
          [100.5826678, 5.104251],
          [100.5826668, 5.1042027],
          [100.5825919, 5.1039497],
          [100.5825232, 5.1037785],
          [100.5825086, 5.1036971],
          [100.5825534, 5.1035805],
          [100.582668, 5.1034929],
          [100.5832388, 5.1035042],
          [100.5834061, 5.1033992],
          [100.5834924, 5.1032256],
          [100.5835137, 5.1031623],
          [100.5835327, 5.1030716],
          [100.5835325, 5.1029198],
          [100.5835105, 5.1028274],
          [100.5834628, 5.1027464],
          [100.5833806, 5.1026656],
          [100.5833071, 5.1025848],
          [100.5832513, 5.1024724],
          [100.5832355, 5.1024045],
          [100.583241, 5.1022732],
          [100.5833324, 5.1021572],
          [100.5835065, 5.1020942],
          [100.5839739, 5.1018386],
          [100.5840036, 5.1017915],
          [100.5840717, 5.1016514],
          [100.5841816, 5.1014454],
          [100.5842606, 5.1012398],
          [100.5842778, 5.1011262],
          [100.584342, 5.1008858],
          [100.5845137, 5.1005681],
          [100.5846324, 5.1003995],
          [100.5847306, 5.1002094],
          [100.5848434, 5.0998756],
          [100.5848661, 5.099633],
          [100.5849033, 5.0993528],
          [100.5849035, 5.0989407],
          [100.5849306, 5.0987351],
          [100.5849709, 5.0985137],
          [100.585177, 5.0981181],
          [100.585603, 5.0975598],
          [100.5856261, 5.0975185],
          [100.5857705, 5.0971443],
          [100.585756, 5.0968462],
          [100.5857487, 5.0968131],
          [100.5856121, 5.0965044],
          [100.5853782, 5.0963908],
          [100.5851651, 5.0963356],
          [100.5849436, 5.0963553],
          [100.5848971, 5.0963654],
          [100.584737, 5.096444],
          [100.5843379, 5.0967693],
          [100.584205, 5.0968345],
          [100.5839978, 5.0968951],
          [100.5837799, 5.096942],
          [100.5835212, 5.097011],
          [100.5831345, 5.0971635],
          [100.583018, 5.0974375],
          [100.5828891, 5.0979942],
          [100.58267, 5.0981083],
          [100.5826097, 5.0981232],
          [100.5823588, 5.0980637],
          [100.5821379, 5.0979641],
          [100.5819552, 5.0979319],
          [100.5818373, 5.0979244],
          [100.5813064, 5.097951],
          [100.5809451, 5.0979234],
          [100.5804094, 5.0981538],
          [100.5802355, 5.0981601],
          [100.5800951, 5.0981528],
          [100.5799481, 5.0981142],
          [100.5797748, 5.0979889],
          [100.5796699, 5.0978608],
          [100.5795966, 5.0976949],
          [100.5795884, 5.0976235],
          [100.5795065, 5.0975249],
          [100.5793695, 5.0973858],
          [100.5790715, 5.0972225],
          [100.578974, 5.0971887],
          [100.5786533, 5.0971435],
          [100.5783105, 5.0971126],
          [100.577982, 5.0970417],
          [100.5778542, 5.0968273],
          [100.5778129, 5.0966629],
          [100.5778071, 5.0965849],
          [100.5776766, 5.0961911],
          [100.5775968, 5.0961388],
          [100.5774744, 5.0961383],
          [100.5773471, 5.0961661],
          [100.5771772, 5.0962692],
          [100.5769221, 5.0964569],
          [100.5768042, 5.0965178],
          [100.5766723, 5.0965314],
          [100.5765687, 5.0965356],
          [100.5764889, 5.0964881],
          [100.5764375, 5.0963982],
          [100.5764429, 5.096233],
          [100.5765901, 5.0959315],
          [100.5767793, 5.0957435],
          [100.5769354, 5.0955671],
          [100.5770149, 5.0952957],
          [100.5770245, 5.0952115],
          [100.5770239, 5.0949928],
          [100.5769936, 5.0948275],
          [100.5769281, 5.0946756],
          [100.5765806, 5.0944819],
          [100.5764114, 5.0944083],
          [100.5761418, 5.0944042],
          [100.5757614, 5.0944428],
          [100.5750602, 5.0944507],
          [100.57478, 5.0944707],
          [100.5746585, 5.0944898],
          [100.5744181, 5.0945433],
          [100.5741089, 5.0946331],
          [100.5737196, 5.0949432],
          [100.573623, 5.0950024],
          [100.573498, 5.0950658],
          [100.5733231, 5.0950942],
          [100.5731779, 5.0949826],
          [100.5731559, 5.0948436],
          [100.5731504, 5.0947446],
          [100.5731681, 5.0946458],
          [100.5732314, 5.0945061],
          [100.5734192, 5.094251],
          [100.5736164, 5.094043],
          [100.5737152, 5.0937878],
          [100.573691, 5.0935815],
          [100.5734763, 5.0932613],
          [100.5728167, 5.0932742],
          [100.5725485, 5.0932436],
          [100.5720827, 5.0932179],
          [100.5718166, 5.0931808],
          [100.5715686, 5.0931204],
          [100.5710686, 5.0929606],
          [100.5704001, 5.0926142],
          [100.5699333, 5.0921012],
          [100.5697381, 5.0917366],
          [100.5696347, 5.0915812],
          [100.5695378, 5.0914516],
          [100.569504, 5.091391],
          [100.5693924, 5.0911372],
          [100.5692884, 5.0909471],
          [100.5691921, 5.0906932],
          [100.5691174, 5.0904203],
          [100.5690489, 5.0901214],
          [100.5689898, 5.0899442],
          [100.5689233, 5.0898041],
          [100.5688253, 5.0895659],
          [100.5685953, 5.0892576],
          [100.5683913, 5.0890847],
          [100.5683461, 5.089059],
          [100.5678412, 5.0888863],
          [100.5676977, 5.0888705],
          [100.5673214, 5.0888503],
          [100.5671932, 5.0888237],
          [100.5668224, 5.0886713],
          [100.5665668, 5.0883809],
          [100.5664231, 5.0881786],
          [100.5662513, 5.0879692],
          [100.5660314, 5.0877476],
          [100.5658005, 5.087558],
          [100.5656161, 5.0873686],
          [100.5655078, 5.0872284],
          [100.5653923, 5.0870995],
          [100.5652384, 5.0868584],
          [100.5649971, 5.086607],
          [100.5647949, 5.0863238],
          [100.5646813, 5.0860233],
          [100.5646595, 5.0858439],
          [100.5646622, 5.0857829],
          [100.5646974, 5.0856291],
          [100.5648227, 5.085278],
          [100.5649651, 5.0849931],
          [100.5649905, 5.0849169],
          [100.565026, 5.084664],
          [100.5650452, 5.084093],
          [100.5650989, 5.0839015],
          [100.5651836, 5.0837276],
          [100.5654498, 5.0834867],
          [100.5658582, 5.083199],
          [100.5660372, 5.0829805],
          [100.5661007, 5.0828651],
          [100.5661274, 5.0827956],
          [100.5661178, 5.0826912],
          [100.5661074, 5.0826221],
          [100.5659106, 5.082341],
          [100.5656396, 5.0821706],
          [100.5651118, 5.0819395],
          [100.5649242, 5.0818453],
          [100.5648901, 5.0818367],
          [100.5647198, 5.0818242],
          [100.5643443, 5.0818486],
          [100.5641546, 5.0818527],
          [100.5636067, 5.0818401],
          [100.5632619, 5.081766],
          [100.5629665, 5.0816448],
          [100.5626278, 5.0813729],
          [100.5626129, 5.0812061],
          [100.5626135, 5.0810656],
          [100.5627061, 5.0808771],
          [100.5628774, 5.0802834],
          [100.5628943, 5.0801859],
          [100.5629227, 5.0798728],
          [100.5628716, 5.0794687],
          [100.562644, 5.0793558],
          [100.5625375, 5.07934],
          [100.562425, 5.0793634],
          [100.5623498, 5.0794069],
          [100.5621431, 5.0795854],
          [100.5618633, 5.0801115],
          [100.5617791, 5.0805152],
          [100.5617405, 5.0807664],
          [100.5617253, 5.080931],
          [100.5616668, 5.0810421],
          [100.5615844, 5.0810999],
          [100.5614588, 5.0811042],
          [100.5613044, 5.0810696],
          [100.5611118, 5.0809428],
          [100.5609571, 5.0807551],
          [100.5607531, 5.0806134],
          [100.5606778, 5.080576],
          [100.5602066, 5.080378],
          [100.56004, 5.0803326],
          [100.559886, 5.0802643],
          [100.5597318, 5.0801344],
          [100.5593495, 5.0797895],
          [100.5591756, 5.079691],
          [100.5587788, 5.0795651],
          [100.5586065, 5.0795938],
          [100.5583671, 5.0797287],
          [100.55813, 5.0799038],
          [100.5579128, 5.0799924],
          [100.557879, 5.0799999],
          [100.5575812, 5.0800185],
          [100.5575168, 5.0800139],
          [100.557301, 5.079974],
          [100.5569589, 5.0799363],
          [100.5566568, 5.0799236],
          [100.5563218, 5.0798833],
          [100.5557036, 5.0798384],
          [100.5555393, 5.0798038],
          [100.5549258, 5.0797216],
          [100.5545597, 5.0796921],
          [100.5542196, 5.0796828],
          [100.5540453, 5.079667],
          [100.5536328, 5.0796138],
          [100.5534752, 5.0796041],
          [100.5533531, 5.0796049],
          [100.5532057, 5.0796254],
          [100.5531566, 5.0796416],
          [100.5530279, 5.0797022],
          [100.5527538, 5.079925],
          [100.5524798, 5.0801919],
          [100.5523961, 5.080243],
          [100.5521387, 5.0803746],
          [100.5519968, 5.0804314],
          [100.5518737, 5.0804469],
          [100.5517661, 5.0804007],
          [100.5516737, 5.0802776],
          [100.5516737, 5.080216],
          [100.5517045, 5.0800467],
          [100.5517967, 5.0797234],
          [100.5518428, 5.0794924],
          [100.5518274, 5.0792769],
          [100.5517658, 5.0791076],
          [100.5517043, 5.0789075],
          [100.5515812, 5.0787536],
          [100.5515196, 5.0785689],
          [100.5515196, 5.0784611],
          [100.5515503, 5.0782918],
          [100.5515811, 5.0781378],
          [100.5517656, 5.0779838],
          [100.5521499, 5.0778145],
          [100.5523258, 5.0777395],
          [100.5523893, 5.0776425],
          [100.5524121, 5.0774413],
          [100.5523955, 5.0773529],
          [100.5522816, 5.0771203],
          [100.5521535, 5.0769799],
          [100.5518577, 5.0767677],
          [100.5516115, 5.07666],
          [100.5512885, 5.0764907],
          [100.5509963, 5.0763523],
          [100.5507501, 5.0763369],
          [100.5505194, 5.0763677],
          [100.5503349, 5.0764909],
          [100.5501965, 5.0767373],
          [100.5501043, 5.0771221],
          [100.5500121, 5.0773993],
          [100.5498429, 5.0775994],
          [100.5495353, 5.077815],
          [100.5493508, 5.0779844],
          [100.5491509, 5.0782923],
          [100.5488895, 5.0786464],
          [100.5486896, 5.0789081],
          [100.5485512, 5.0791083],
          [100.5484743, 5.0793084],
          [100.5485359, 5.0797702],
          [100.5486128, 5.0800165],
          [100.5487205, 5.0802166],
          [100.5489821, 5.0803397],
          [100.5492436, 5.0804782],
          [100.549582, 5.0807706],
          [100.5497974, 5.0810477],
          [100.5499205, 5.0813247],
          [100.5500128, 5.0816172],
          [100.5501359, 5.0817249],
          [100.5503666, 5.0818481],
          [100.5506128, 5.0820327],
          [100.5508589, 5.0822482],
          [100.5510743, 5.0824329],
          [100.5513819, 5.0825713],
          [100.5519203, 5.0829407],
          [100.552127, 5.0830713],
          [100.5523344, 5.0832687],
          [100.5524613, 5.0833558],
          [100.5526553, 5.0834357],
          [100.552804, 5.0835234],
          [100.5531292, 5.0836713],
          [100.5533328, 5.0840231],
          [100.5535943, 5.0844343],
          [100.5536365, 5.0845261],
          [100.5538195, 5.0851327],
          [100.5538449, 5.0852596],
          [100.5538485, 5.0855464],
          [100.5537144, 5.085855],
          [100.553334, 5.0859221],
          [100.5532649, 5.0859084],
          [100.5529784, 5.0857889],
          [100.5528028, 5.0857003],
          [100.5521176, 5.0852165],
          [100.5518382, 5.0851637],
          [100.5514954, 5.0852462],
          [100.5510874, 5.0855589],
          [100.5509346, 5.0857375],
          [100.5508886, 5.0858045],
          [100.5505324, 5.0857072],
          [100.5501406, 5.0855757],
          [100.5497161, 5.085181],
          [100.549308, 5.0847698],
          [100.54921, 5.0844573],
          [100.549014, 5.0837828],
          [100.5488833, 5.0832564],
          [100.548798, 5.0829875],
          [100.5487364, 5.0826643],
          [100.5485671, 5.0821255],
          [100.548444, 5.0815868],
          [100.5481978, 5.0813559],
          [100.547967, 5.0810635],
          [100.5478285, 5.0807556],
          [100.5477362, 5.0805247],
          [100.5474901, 5.0804016],
          [100.5471825, 5.0803709],
          [100.5468749, 5.0805095],
          [100.5466903, 5.0806789],
          [100.5460751, 5.0809869],
          [100.5457368, 5.0811871],
          [100.5455061, 5.0814026],
          [100.545337, 5.0816182],
          [100.5452293, 5.0818799],
          [100.5451525, 5.0821878],
          [100.545091, 5.0824803],
          [100.5449988, 5.082742],
          [100.5449823, 5.0828956],
          [100.5450178, 5.0832176],
          [100.5451976, 5.0838353],
          [100.545409, 5.0843678],
          [100.545705, 5.0849642],
          [100.5460433, 5.085486],
          [100.5463499, 5.0861037],
          [100.5465712, 5.0865114],
          [100.5467721, 5.0867456],
          [100.5472583, 5.0871289],
          [100.5478185, 5.0875015],
          [100.5483469, 5.0876611],
          [100.5486428, 5.0876503],
          [100.5488225, 5.0875864],
          [100.5489916, 5.0875011],
          [100.5490973, 5.0874052],
          [100.5495516, 5.0870535],
          [100.5496996, 5.0870322],
          [100.5499321, 5.0870534],
          [100.5500378, 5.0871705],
          [100.5502492, 5.0872663],
          [100.5504711, 5.0873088],
          [100.5509784, 5.0872341],
          [100.5516654, 5.0871806],
          [100.5518344, 5.0871912],
          [100.5521087, 5.0872542],
          [100.5527212, 5.0875972],
          [100.552995, 5.0879838],
          [100.5532312, 5.0884373],
          [100.5534867, 5.0892741],
          [100.553413, 5.0896872],
          [100.5530557, 5.0904621],
          [100.5526932, 5.0907525],
          [100.5520928, 5.0909057],
          [100.5517964, 5.090982],
          [100.5516168, 5.0909985],
          [100.5513556, 5.0908835],
          [100.5510944, 5.0906697],
          [100.5508005, 5.0903079],
          [100.5506209, 5.0900447],
          [100.550376, 5.0898967],
          [100.5500332, 5.089831],
          [100.5493966, 5.0897654],
          [100.5490538, 5.0897162],
          [100.5485477, 5.0893545],
          [100.5480579, 5.0889598],
          [100.5477314, 5.0887789],
          [100.5473723, 5.0886639],
          [100.5470947, 5.0883514],
          [100.546654, 5.088039],
          [100.5463111, 5.0878581],
          [100.5459846, 5.0877431],
          [100.5456255, 5.0876116],
          [100.5451358, 5.0872992],
          [100.5448093, 5.0871677],
          [100.5443685, 5.087283],
          [100.5437972, 5.0874477],
          [100.5436177, 5.0876288],
          [100.5435851, 5.0878097],
          [100.5441142, 5.0889873],
          [100.5443495, 5.0895967],
          [100.5444839, 5.0899691],
          [100.5445176, 5.0904262],
          [100.5445512, 5.0908325],
          [100.5445009, 5.091188],
          [100.5444338, 5.0917468],
          [100.5442827, 5.0922886],
          [100.5441316, 5.0929489],
          [100.5439973, 5.0935077],
          [100.5437118, 5.0940834],
          [100.5434095, 5.0945406],
          [100.5429392, 5.0950487],
          [100.5424689, 5.0953197],
          [100.5420994, 5.0955569],
          [100.541629, 5.0957263],
          [100.5410915, 5.0957604],
          [100.5405372, 5.0957437],
          [100.5397476, 5.0955238],
          [100.53921, 5.0953716],
          [100.5385885, 5.0951518],
          [100.5382525, 5.0950164],
          [100.5376141, 5.0946442],
          [100.5371941, 5.0942549],
          [100.5368749, 5.0939165],
          [100.536751, 5.0937532],
          [100.5364924, 5.0932158],
          [100.5364438, 5.0928086],
          [100.5364276, 5.092548],
          [100.5364114, 5.0922385],
          [100.5364922, 5.0917172],
          [100.5365244, 5.0913263],
          [100.5365729, 5.0909028],
          [100.5365243, 5.0901698],
          [100.5363626, 5.089388],
          [100.5360878, 5.0888668],
          [100.5356513, 5.0884109],
          [100.5350856, 5.0880038],
          [100.5344229, 5.0877109],
          [100.5337926, 5.0875482],
          [100.5328552, 5.0874996],
          [100.5322087, 5.0873695],
          [100.5314653, 5.0873046],
          [100.5305441, 5.0872072],
          [100.5300431, 5.0872237],
          [100.5294774, 5.0873867],
          [100.5289926, 5.0875987],
          [100.5286694, 5.0878431],
          [100.5276029, 5.0891303],
          [100.5264071, 5.0905641],
          [100.5253082, 5.0917709],
          [100.5246113, 5.0926053],
          [100.524154, 5.0931762],
          [100.5235442, 5.0941423],
          [100.5229344, 5.0949547],
          [100.5224335, 5.0954378],
          [100.5220197, 5.0957453],
          [100.5214752, 5.0959211],
          [100.5210831, 5.096009],
          [100.5206039, 5.0959653],
          [100.5198851, 5.0958777],
          [100.518491, 5.0953513],
          [100.517293, 5.0949345],
          [100.5163346, 5.0947153],
          [100.5151845, 5.0944377],
          [100.5139803, 5.094261],
          [100.5132778, 5.094236],
          [100.5128261, 5.0943372],
          [100.5123997, 5.0946155],
          [100.5119983, 5.0951467],
          [100.5115217, 5.0959307],
          [100.5114214, 5.0967146],
          [100.5114214, 5.0974226],
          [100.5115972, 5.0988892],
          [100.5116976, 5.09985],
          [100.5118232, 5.1007856],
          [100.5118734, 5.1017717],
          [100.5118233, 5.102505],
          [100.511723, 5.1032131],
          [100.5115976, 5.1038453],
          [100.5113468, 5.1048568],
          [100.5111963, 5.1054385],
          [100.5110817, 5.1063037],
          [100.5106274, 5.1084738],
          [100.5105318, 5.1096311],
          [100.5105558, 5.1101374],
          [100.5109147, 5.1106678],
          [100.5112975, 5.1110052],
          [100.5121587, 5.1112702],
          [100.5132353, 5.1115351],
          [100.5137138, 5.1115349],
          [100.5145033, 5.1116552],
          [100.5156277, 5.1116308],
          [100.5161779, 5.1116547],
          [100.516776, 5.1118233],
          [100.5173263, 5.1121366],
          [100.5179962, 5.1127874],
          [100.5183551, 5.1132454],
          [100.5186902, 5.1141374],
          [100.5188338, 5.1147883],
          [100.5188338, 5.1155117],
          [100.5186186, 5.1161868],
          [100.5180206, 5.1172961],
          [100.5177589, 5.1176752],
          [100.5169217, 5.1185435],
          [100.5159409, 5.1193876],
          [100.5149122, 5.1201113],
          [100.5139075, 5.1209555],
          [100.5130702, 5.1216308],
          [100.5124243, 5.1222579],
          [100.5115631, 5.1232226],
          [100.5107512, 5.1242528],
          [100.5102728, 5.1249039],
          [100.5097226, 5.1254828],
          [100.5093637, 5.1256999],
          [100.5084786, 5.12611],
          [100.5079044, 5.1262066],
          [100.5073781, 5.1261586],
          [100.5066603, 5.12599],
          [100.5060622, 5.1257732],
          [100.5056316, 5.1255081],
          [100.5050813, 5.1251466],
          [100.5044353, 5.1243511],
          [100.5041003, 5.123869],
          [100.5038371, 5.1230493],
          [100.5036696, 5.1221813],
          [100.503502, 5.1212893],
          [100.5035498, 5.12059],
          [100.5035498, 5.1198667],
          [100.5034301, 5.1189264],
          [100.5033104, 5.1185889],
          [100.5029037, 5.1180345],
          [100.502162, 5.1176007],
          [100.50154, 5.117408],
          [100.5009509, 5.1173889],
          [100.5002368, 5.1174431],
          [100.4993263, 5.1176952],
          [100.4986836, 5.1181992],
          [100.4979696, 5.1189191],
          [100.4974698, 5.1196389],
          [100.4971474, 5.1206336],
          [100.4970225, 5.1209755],
          [100.4969868, 5.1214433],
          [100.4971297, 5.1225587],
          [100.4975225, 5.1242319],
          [100.4975226, 5.1248076],
          [100.497343, 5.1256404],
          [100.4970039, 5.1264321],
          [100.4966111, 5.127008],
          [100.4959149, 5.1278898],
          [100.4954865, 5.1284117],
          [100.4945225, 5.1292576],
          [100.4935763, 5.1299775],
          [100.4926301, 5.1307334],
          [100.4924516, 5.1310573],
          [100.4922731, 5.1315072],
          [100.4921839, 5.131957],
          [100.4921661, 5.1325328],
          [100.4921998, 5.1328951],
          [100.4922196, 5.1331085],
          [100.4922549, 5.1332371],
          [100.4925064, 5.1341556],
          [100.4930441, 5.1355796],
          [100.4936739, 5.1369417],
          [100.4947022, 5.1389736],
          [100.4955471, 5.1401343],
          [100.4961308, 5.1408772],
          [100.4965609, 5.1411867],
          [100.4967452, 5.1413259],
          [100.4971446, 5.1417902],
          [100.497882, 5.1423163],
          [100.4983581, 5.1425794],
          [100.4989111, 5.142765],
          [100.49954, 5.142893],
          [100.5003694, 5.1428928],
          [100.5016136, 5.1430008],
          [100.5020898, 5.1430161],
          [100.5025045, 5.1425207],
          [100.5026734, 5.1424742],
          [100.5041011, 5.1433605],
          [100.5067893, 5.1449851],
          [100.5070965, 5.1451398],
          [100.5074037, 5.1451707],
          [100.5075971, 5.1451202],
          [100.5079566, 5.1450157],
          [100.5092152, 5.1447256],
          [100.5118264, 5.1441985],
          [100.5152517, 5.143439],
          [100.5159575, 5.1432864],
          [100.5173456, 5.1429739],
          [100.5182623, 5.142788],
          [100.5197215, 5.1423696],
          [100.5211856, 5.1420113],
          [100.5230572, 5.1415108],
          [100.5251994, 5.1410329],
          [100.5281083, 5.1404184],
          [100.5312427, 5.1396901],
          [100.5321221, 5.1394399],
          [100.5324392, 5.1393198],
          [100.5338147, 5.1391148],
          [100.5346716, 5.1388873],
          [100.5359118, 5.1386141],
          [100.5373731, 5.1382301],
          [100.539311, 5.1378633],
          [100.5399324, 5.137929],
          [100.5403248, 5.1379453],
          [100.5408318, 5.1379452],
          [100.5411752, 5.1379945],
          [100.5419928, 5.1381425],
          [100.5430394, 5.1383399],
          [100.5436935, 5.1386034],
          [100.5444785, 5.1389821],
          [100.5449201, 5.1392951],
          [100.5458359, 5.1399045],
          [100.5469316, 5.1406457],
          [100.5476512, 5.141074],
          [100.5485997, 5.1417822],
          [100.5492933, 5.1422395],
          [100.5503058, 5.142857],
          [100.5503059, 5.1434307],
          [100.5502888, 5.1444391],
          [100.5501338, 5.1460908],
          [100.5498932, 5.1484786],
          [100.5495975, 5.1509965],
          [100.5494715, 5.1520345],
          [100.5492627, 5.1536851],
          [100.549028, 5.1556687],
          [100.548678, 5.1587414],
          [100.5482486, 5.1629574],
          [100.5476583, 5.1684973],
          [100.5470142, 5.1738744],
          [100.5462969, 5.1815474],
          [100.5454184, 5.1887816],
          [100.5452619, 5.1899707],
          [100.544803, 5.1934582],
          [100.5442804, 5.1978151],
          [100.5441911, 5.1985597],
          [100.5434498, 5.2049174],
          [100.5432234, 5.2072084],
          [100.5432, 5.2074159],
          [100.542887, 5.2101907],
          [100.5428761, 5.2102806],
          [100.5427275, 5.2115115],
          [100.542701, 5.2117305],
          [100.5425187, 5.2132404],
          [100.5421567, 5.2162377],
          [100.5420635, 5.2170093],
          [100.5419224, 5.2180386],
          [100.5400578, 5.2316354],
          [100.5390626, 5.2404015],
          [100.5381297, 5.2476583],
          [100.5366797, 5.2593917],
          [100.5362886, 5.2645759],
          [100.5360382, 5.2668639],
          [100.5354143, 5.2723903],
          [100.533803, 5.2858326],
          [100.5336922, 5.2872984],
          [100.5334862, 5.2886567],
          [100.5334791, 5.2887035],
          [100.5333932, 5.2892697],
          [100.5333551, 5.2896677],
          [100.5332134, 5.2911475],
          [100.5330912, 5.2924243],
          [100.5330885, 5.2924519],
          [100.5330238, 5.2931282],
          [100.5321398, 5.300691],
          [100.5315134, 5.3061625],
          [100.5311745, 5.3089489],
          [100.5311489, 5.309167],
          [100.5296132, 5.322249],
          [100.5295786, 5.3225628],
          [100.5285004, 5.3323373],
          [100.5278915, 5.3378579],
          [100.5278759, 5.337963],
          [100.527822, 5.3383258],
          [100.5273067, 5.3417963],
          [100.5271975, 5.3425208],
          [100.5269794, 5.3444751],
          [100.5266598, 5.3471147],
          [100.5265293, 5.3481925],
          [100.5265125, 5.348357],
          [100.5264097, 5.3497751],
          [100.5263775, 5.3500095],
          [100.526366, 5.3500952],
          [100.5261092, 5.3520149],
          [100.5260956, 5.3521163],
          [100.5260347, 5.3529616],
          [100.5260123, 5.3532718],
          [100.5258389, 5.3548845],
          [100.525746, 5.3557491],
          [100.5248474, 5.3641078],
          [100.5246853, 5.3656164],
          [100.5245468, 5.3669041],
          [100.5243485, 5.368749],
          [100.524185, 5.3702703],
          [100.5241038, 5.3710254],
          [100.5243647, 5.3762444],
          [100.5244455, 5.3777618],
          [100.5245248, 5.3792313],
          [100.5245736, 5.3801368],
          [100.5247198, 5.3828487],
          [100.5247757, 5.3838853],
          [100.5248467, 5.3852012],
          [100.5249954, 5.3901806],
          [100.5250775, 5.3989619],
          [100.5251524, 5.406968],
          [100.5257099, 5.4271589],
          [100.5258138, 5.4309111],
          [100.5267411, 5.4643211],
          [100.5276884, 5.4995939],
          [100.5278436, 5.506499],
          [100.5285362, 5.541169],
          [100.5284725, 5.5548015],
          [100.526823, 5.5504978],
          [100.5258603, 5.5494551],
          [100.5246869, 5.5500087],
          [100.5234059, 5.5523554],
          [100.523525, 5.5543702],
          [100.524131, 5.5560346],
          [100.5240641, 5.5585086],
          [100.5225761, 5.5592826],
          [100.5196485, 5.5586187],
          [100.5187969, 5.5590822],
          [100.5174123, 5.5597713],
          [100.5170748, 5.5599393],
          [100.5155134, 5.5606809],
          [100.5142163, 5.5606452],
          [100.5122566, 5.5604768],
          [100.5095642, 5.5587881],
          [100.5077757, 5.5593806],
          [100.5076078, 5.5596701],
          [100.5071244, 5.5605033],
          [100.507061, 5.5619067],
          [100.5070736, 5.5630119],
          [100.5063728, 5.5656274],
          [100.5059959, 5.5663619],
          [100.5047062, 5.5670561],
          [100.5004912, 5.5673095],
          [100.4992146, 5.5671758],
          [100.4921909, 5.5668664],
          [100.490921, 5.5671269],
          [100.4880377, 5.5691535],
          [100.4871607, 5.5693241],
          [100.4866815, 5.568824],
          [100.4865407, 5.5635363],
          [100.4866357, 5.5607314],
          [100.4864798, 5.5605489],
          [100.485953, 5.5605304],
          [100.4843627, 5.5614634],
          [100.4824551, 5.5639676],
          [100.4795773, 5.5647824],
          [100.4782857, 5.5647994],
          [100.4768039, 5.5643916],
          [100.4755116, 5.5628827],
          [100.4741536, 5.5620398],
          [100.4728328, 5.562126],
          [100.4708917, 5.5644475],
          [100.4686874, 5.5657671],
          [100.4666389, 5.5659092],
          [100.4637511, 5.5659092],
          [100.4604687, 5.563943],
          [100.459563, 5.5637669],
          [100.4577639, 5.564342],
          [100.4565055, 5.5654616],
          [100.4545714, 5.5674914],
          [100.4534596, 5.5678357],
          [100.4522838, 5.5679805],
          [100.4515203, 5.5678101],
          [100.4506581, 5.5670403],
          [100.4501273, 5.5658731],
          [100.4499066, 5.563168],
          [100.449575, 5.5616938],
          [100.4491182, 5.5610782],
          [100.4477322, 5.5610782],
          [100.4469953, 5.5614551],
          [100.4465721, 5.5619892],
          [100.4461268, 5.563027],
          [100.4459297, 5.5659246],
          [100.4451666, 5.5670766],
          [100.4446282, 5.5672777],
          [100.4435188, 5.5673114],
          [100.441799, 5.566464],
          [100.4397105, 5.5654485],
          [100.4381058, 5.5658894],
          [100.4365308, 5.5648353],
          [100.4345441, 5.560739],
          [100.4335734, 5.5614088],
          [100.4316354, 5.5657867],
          [100.430378, 5.565727],
          [100.4286249, 5.564592],
          [100.4257998, 5.5628041],
          [100.4242398, 5.5624687],
          [100.4223869, 5.5628327],
          [100.4187435, 5.564547],
          [100.4158247, 5.5649371],
          [100.4133868, 5.5654682],
          [100.4098516, 5.5665158],
          [100.4082667, 5.5685326],
          [100.4051459, 5.5735256],
          [100.4014424, 5.5756994],
          [100.3975218, 5.5775631],
          [100.3941654, 5.5793455],
          [100.3922695, 5.5792965],
          [100.3897449, 5.5784],
          [100.3880025, 5.5777693],
          [100.3872346, 5.5779163],
          [100.3866758, 5.5786242],
          [100.3863291, 5.5802159],
          [100.3862815, 5.5828922],
          [100.3854937, 5.5840051],
          [100.3846767, 5.584568],
          [100.3838958, 5.5848484],
          [100.3828874, 5.5848202],
          [100.3825115, 5.5848097],
          [100.3822237, 5.5845191],
          [100.3816221, 5.5839117],
          [100.3812018, 5.5832105],
          [100.3802637, 5.5809052],
          [100.3793945, 5.5799634],
          [100.3784577, 5.5794761],
          [100.3768123, 5.579597],
          [100.3759639, 5.5799137],
          [100.3754132, 5.5805777],
          [100.3744289, 5.5827212],
          [100.3737202, 5.5840415],
          [100.3734932, 5.5844645],
          [100.3728085, 5.5850344],
          [100.3718702, 5.5856779],
          [100.3708374, 5.5856112],
          [100.3703397, 5.585314],
          [100.3692385, 5.5848925],
          [100.3685891, 5.5841038],
          [100.368225, 5.5829797],
          [100.3682845, 5.5807555],
          [100.3683077, 5.5790478],
          [100.3681379, 5.5773804],
          [100.3674283, 5.5759901],
          [100.3663003, 5.5750008],
          [100.3651659, 5.5744077],
          [100.3634384, 5.5734803],
          [100.3603875, 5.5734916],
          [100.3588592, 5.5738346],
          [100.3531683, 5.575242],
          [100.3458404, 5.5770391],
          [100.3422532, 5.5771125],
          [100.3397858, 5.5776534],
          [100.3401599, 5.5781557],
          [100.339983, 5.5782978],
          [100.3396897, 5.5785075],
          [100.3392703, 5.5787802],
          [100.338894, 5.5793486],
          [100.3387463, 5.5801377],
          [100.338663, 5.5811898],
          [100.3386943, 5.5829725],
          [100.3388431, 5.5862528],
          [100.3392311, 5.5898264],
          [100.3403552, 5.6001207],
          [100.3409316, 5.6048431],
          [100.3416027, 5.6114414],
          [100.3416319, 5.6161861],
          [100.3414534, 5.62169],
          [100.341602, 5.6231687],
          [100.3416683, 5.6277171],
          [100.3414689, 5.6305734],
          [100.3411327, 5.6348831],
          [100.3405119, 5.6389539],
          [100.3401354, 5.642549],
          [100.3396232, 5.6458835],
          [100.3391568, 5.6489848],
          [100.3388801, 5.6516638],
          [100.3386823, 5.652825],
          [100.3383827, 5.6535484],
          [100.3379219, 5.6540151],
          [100.3375561, 5.6539821],
          [100.3371095, 5.6538652],
          [100.3368767, 5.6539284],
          [100.3366463, 5.6538398],
          [100.336164, 5.6537536],
          [100.3360139, 5.6534395],
          [100.3358304, 5.6533834],
          [100.3356257, 5.6539861],
          [100.3353768, 5.6544919],
          [100.3350871, 5.6548255],
          [100.334674, 5.6547829],
          [100.3339222, 5.654719],
          [100.3332259, 5.6549312],
          [100.3330073, 5.6548872],
          [100.3325564, 5.6546446],
          [100.3322214, 5.6546099],
          [100.3319232, 5.6543525],
          [100.33174, 5.6541401],
          [100.3315085, 5.6539265],
          [100.3312671, 5.6537496],
          [100.3310327, 5.6538721],
          [100.3312377, 5.654311],
          [100.3312615, 5.6545244],
          [100.3312773, 5.6547721],
          [100.3313205, 5.6550436],
          [100.3313116, 5.6553604],
          [100.3316641, 5.6555086],
          [100.332447, 5.6553161],
          [100.3328093, 5.6553549],
          [100.3330953, 5.6553606],
          [100.333352, 5.6554469],
          [100.3334398, 5.6555832],
          [100.3336553, 5.6561841],
          [100.3337592, 5.6576666],
          [100.3337079, 5.6590187],
          [100.333418, 5.6601403],
          [100.3332684, 5.660534],
          [100.3328915, 5.6606565],
          [100.3325422, 5.6609325],
          [100.3326136, 5.6610977],
          [100.3323977, 5.6613865],
          [100.3323016, 5.661658],
          [100.3323934, 5.6617007],
          [100.3328654, 5.6615095],
          [100.3329914, 5.6613227],
          [100.3333447, 5.6612005],
          [100.333487, 5.6610945],
          [100.3336065, 5.6610609],
          [100.3342382, 5.6610853],
          [100.3345505, 5.66099],
          [100.3350111, 5.6612806],
          [100.3357774, 5.6613101],
          [100.336186, 5.6610673],
          [100.336412, 5.6612042],
          [100.3365261, 5.6611997],
          [100.3366858, 5.6612328],
          [100.3372643, 5.6616185],
          [100.337655, 5.6619709],
          [100.3377875, 5.6620904],
          [100.3379034, 5.662195],
          [100.3381148, 5.6621889],
          [100.3387666, 5.6628312],
          [100.3388741, 5.6629371],
          [100.3395042, 5.6643076],
          [100.3396225, 5.6650523],
          [100.3398545, 5.6651719],
          [100.3399615, 5.6656582],
          [100.3399608, 5.6656916],
          [100.3399556, 5.6659577],
          [100.3398901, 5.6662246],
          [100.340226, 5.6665054],
          [100.340778, 5.6667154],
          [100.3408437, 5.6668377],
          [100.3413609, 5.6685505],
          [100.3416958, 5.6697526],
          [100.3417235, 5.6700415],
          [100.3417489, 5.6703061],
          [100.3418044, 5.6707775],
          [100.342085, 5.6714597],
          [100.3419428, 5.6719516],
          [100.3419892, 5.6725599],
          [100.342623, 5.6729864],
          [100.3429286, 5.6730513],
          [100.3432638, 5.6731826],
          [100.3435221, 5.6734904],
          [100.3437775, 5.6732739],
          [100.3438405, 5.6729165],
          [100.3439977, 5.6725431],
          [100.3444048, 5.6723042],
          [100.3448745, 5.6719156],
          [100.3453283, 5.6709256],
          [100.3462228, 5.6701879],
          [100.3469747, 5.670146],
          [100.3471455, 5.6698607],
          [100.3476087, 5.669838],
          [100.347896, 5.6704148],
          [100.3482841, 5.6701799],
          [100.3483962, 5.6697731],
          [100.348739, 5.6696141],
          [100.3488404, 5.6696022],
          [100.3490209, 5.669581],
          [100.3496174, 5.6692892],
          [100.3501067, 5.6692262],
          [100.3505262, 5.6690896],
          [100.3510232, 5.6689834],
          [100.3513743, 5.6683628],
          [100.3518826, 5.6673811],
          [100.3521044, 5.6668902],
          [100.352566, 5.6663028],
          [100.3532655, 5.6656422],
          [100.3533422, 5.6654289],
          [100.3542842, 5.6649212],
          [100.354564, 5.6649333],
          [100.3546828, 5.6650221],
          [100.3548732, 5.6649583],
          [100.3555427, 5.6651401],
          [100.3557774, 5.6653117],
          [100.3562838, 5.6652584],
          [100.3567325, 5.6648425],
          [100.3567331, 5.6643266],
          [100.3571314, 5.664121],
          [100.3575953, 5.6642053],
          [100.358046, 5.6643679],
          [100.3583405, 5.6642967],
          [100.3590554, 5.6645884],
          [100.3598004, 5.6650339],
          [100.3605496, 5.6654084],
          [100.3617102, 5.665841],
          [100.3630644, 5.6657038],
          [100.363482, 5.6651892],
          [100.3639455, 5.6649567],
          [100.3641601, 5.6650093],
          [100.3642848, 5.6649381],
          [100.3645222, 5.6649455],
          [100.3649613, 5.6651401],
          [100.3656656, 5.6655466],
          [100.3668454, 5.6663301],
          [100.3675579, 5.6670807],
          [100.3684303, 5.6679109],
          [100.3691549, 5.6687797],
          [100.3699049, 5.6697325],
          [100.3705712, 5.6702386],
          [100.3709246, 5.6704263],
          [100.3710633, 5.6704356],
          [100.3719224, 5.670947],
          [100.3723763, 5.671078],
          [100.3728655, 5.6710663],
          [100.3735739, 5.6708322],
          [100.3735045, 5.6753191],
          [100.3715074, 5.6787605],
          [100.3710415, 5.6788946],
          [100.3703337, 5.6793252],
          [100.3691811, 5.6788651],
          [100.3682904, 5.6788379],
          [100.3675405, 5.6790519],
          [100.3666043, 5.6791429],
          [100.3661855, 5.6793905],
          [100.3655622, 5.6794686],
          [100.3653506, 5.6794492],
          [100.365187, 5.6795716],
          [100.3645465, 5.6797957],
          [100.3635957, 5.680669],
          [100.362862, 5.6814168],
          [100.3624443, 5.6818424],
          [100.362231, 5.6819121],
          [100.3611545, 5.683152],
          [100.3609867, 5.6831873],
          [100.3604953, 5.6838115],
          [100.3590155, 5.685453],
          [100.3577947, 5.6869663],
          [100.3571919, 5.6878535],
          [100.3563479, 5.688396],
          [100.3549207, 5.6896574],
          [100.3535851, 5.6910315],
          [100.352383, 5.6921266],
          [100.351307, 5.694603],
          [100.3508926, 5.6960196],
          [100.3503929, 5.6980969],
          [100.3501888, 5.7012113],
          [100.3502239, 5.7029402],
          [100.3504029, 5.7041998],
          [100.3507343, 5.7054471],
          [100.3508008, 5.7063394],
          [100.351265, 5.7076049],
          [100.3517343, 5.7087474],
          [100.351892, 5.7094776],
          [100.3526647, 5.7112129],
          [100.3532021, 5.7128155],
          [100.3538103, 5.7141745],
          [100.3543553, 5.7158716],
          [100.3548078, 5.7171198],
          [100.354991, 5.7175737],
          [100.354745, 5.7182198],
          [100.3561253, 5.7217192],
          [100.356477, 5.7233621],
          [100.3572664, 5.7252723],
          [100.3581183, 5.7275547],
          [100.3588448, 5.729274],
          [100.3593607, 5.7305148],
          [100.359463, 5.7311024],
          [100.3600877, 5.7325369],
          [100.3607815, 5.734943],
          [100.3614415, 5.7373384],
          [100.3618918, 5.7394928],
          [100.3621539, 5.7406252],
          [100.3622577, 5.7415956],
          [100.3625077, 5.7432816],
          [100.3627136, 5.7451154],
          [100.3626211, 5.7457453],
          [100.3619588, 5.745938],
          [100.3617737, 5.7465762],
          [100.3616862, 5.7472914],
          [100.3606792, 5.7478324],
          [100.3600346, 5.7479655],
          [100.3592612, 5.7477569],
          [100.3584986, 5.7478764],
          [100.3581917, 5.7481516],
          [100.3580668, 5.748667],
          [100.3579708, 5.7489527],
          [100.3575529, 5.7490675],
          [100.3577653, 5.7501446],
          [100.3580292, 5.7504286],
          [100.3582661, 5.7500964],
          [100.3591681, 5.7496895],
          [100.3595011, 5.7500421],
          [100.3601343, 5.7501617],
          [100.3610925, 5.7517855],
          [100.3622773, 5.75459],
          [100.3642045, 5.7596952],
          [100.3654427, 5.7643492],
          [100.3667253, 5.7701784],
          [100.3666031, 5.7702809],
          [100.3667727, 5.7713389],
          [100.3667615, 5.771396],
          [100.3667553, 5.7714603],
          [100.3668585, 5.771535],
          [100.3669725, 5.7715871],
          [100.367175, 5.7716351],
          [100.3671745, 5.7717033],
          [100.3671817, 5.7717552],
          [100.3669833, 5.7717819],
          [100.366779, 5.7718467],
          [100.3667334, 5.7719445],
          [100.3667172, 5.772003],
          [100.3670669, 5.7741379],
          [100.3673774, 5.777862],
          [100.3674245, 5.7807377],
          [100.3673655, 5.7813247],
          [100.367395, 5.782184],
          [100.3674245, 5.783645],
          [100.3674285, 5.7842686],
          [100.3675237, 5.787337],
          [100.3674298, 5.7885752],
          [100.3674996, 5.7894505],
          [100.3679331, 5.7895761],
          [100.3678992, 5.78972],
          [100.3674272, 5.7900135],
          [100.3673885, 5.7900597],
          [100.3673172, 5.7907741],
          [100.3671777, 5.791676],
          [100.3672287, 5.7922738],
          [100.3671004, 5.7923325],
          [100.3672006, 5.7942498],
          [100.3672716, 5.7948195],
          [100.367336, 5.7953345],
          [100.3674634, 5.7953732],
          [100.3678778, 5.7954066],
          [100.3678745, 5.79555],
          [100.3678711, 5.7957028],
          [100.3677598, 5.7957562],
          [100.367623, 5.7959163],
          [100.3675586, 5.7962392],
          [100.3674915, 5.7966128],
          [100.367526, 5.7971432],
          [100.367421, 5.7981257],
          [100.3673144, 5.798933],
          [100.3672887, 5.7996228],
          [100.367291, 5.800347],
          [100.3673639, 5.8016194],
          [100.3672436, 5.803466],
          [100.3673278, 5.8051043],
          [100.3673835, 5.8060031],
          [100.3675178, 5.8067663],
          [100.3674835, 5.8068917],
          [100.3675092, 5.8070323],
          [100.3675409, 5.8072879],
          [100.3675882, 5.8076108],
          [100.3676038, 5.8079163],
          [100.3677101, 5.8083801],
          [100.3677646, 5.8087482],
          [100.3679234, 5.8088548],
          [100.368009, 5.8090269],
          [100.3680106, 5.8092],
          [100.3678972, 5.8094357],
          [100.3677754, 5.8098659],
          [100.3677153, 5.8103255],
          [100.3677565, 5.8107021],
          [100.3678387, 5.8109395],
          [100.3677193, 5.8112739],
          [100.3677121, 5.811911],
          [100.3677332, 5.812415],
          [100.367857, 5.8128195],
          [100.3680202, 5.8131475],
          [100.3681332, 5.8133307],
          [100.36856, 5.8133886],
          [100.3685671, 5.8134965],
          [100.3685822, 5.8136916],
          [100.3685332, 5.8138349],
          [100.3683119, 5.8143358],
          [100.3679466, 5.8149552],
          [100.367743, 5.8157895],
          [100.3676688, 5.8165285],
          [100.3675458, 5.8175008],
          [100.3675403, 5.8185601],
          [100.36746, 5.819471],
          [100.3675454, 5.8202299],
          [100.3676213, 5.8210183],
          [100.3677931, 5.8214794],
          [100.3678493, 5.8218836],
          [100.368059, 5.8220719],
          [100.3680622, 5.822353],
          [100.3682667, 5.8227231],
          [100.3681955, 5.8231236],
          [100.3679333, 5.8235106],
          [100.3678826, 5.8235693],
          [100.3675661, 5.8235667],
          [100.3673837, 5.8236574],
          [100.3673347, 5.8239432],
          [100.3670409, 5.8243044],
          [100.3670099, 5.8247856],
          [100.3663653, 5.8265149],
          [100.3660849, 5.8289941],
          [100.3658381, 5.829421],
          [100.3657737, 5.8300935],
          [100.365645, 5.8305204],
          [100.3656772, 5.8311821],
          [100.3657201, 5.831449],
          [100.365881, 5.8315344],
          [100.3656541, 5.8337618],
          [100.365182, 5.8361668],
          [100.3648507, 5.8380982],
          [100.3645358, 5.8400299],
          [100.3642783, 5.8416093],
          [100.3639325, 5.8436664],
          [100.3636771, 5.8452477],
          [100.363448, 5.8466991],
          [100.3633786, 5.8474834],
          [100.3634681, 5.848299],
          [100.3635407, 5.8487046],
          [100.3635152, 5.8489688],
          [100.3635619, 5.8490624],
          [100.3636848, 5.8491227],
          [100.3638439, 5.8493591],
          [100.3641039, 5.8495158],
          [100.3639971, 5.8497566],
          [100.3640503, 5.8501135],
          [100.3633879, 5.8501796],
          [100.3633245, 5.850261],
          [100.3632807, 5.8503172],
          [100.3632053, 5.8504141],
          [100.3629023, 5.8514476],
          [100.3627032, 5.8515836],
          [100.3623042, 5.8532507],
          [100.3623015, 5.8547082],
          [100.3620118, 5.856053],
          [100.3618063, 5.8569002],
          [100.3616309, 5.8597618],
          [100.3615043, 5.8599442],
          [100.3612848, 5.8619077],
          [100.3611427, 5.8628889],
          [100.3610283, 5.864844],
          [100.3608656, 5.8663492],
          [100.3609277, 5.8670522],
          [100.3609419, 5.8673721],
          [100.3609488, 5.8674731],
          [100.3612393, 5.8676596],
          [100.3612629, 5.8680531],
          [100.3607449, 5.8681903],
          [100.3605128, 5.8684779],
          [100.360478, 5.8685873],
          [100.3601695, 5.8695185],
          [100.3599657, 5.8695825],
          [100.3597565, 5.8700468],
          [100.3593795, 5.8703969],
          [100.3589169, 5.8714022],
          [100.3584771, 5.8723681],
          [100.3582518, 5.8728217],
          [100.3579138, 5.8732859],
          [100.3576294, 5.8738229],
          [100.357777, 5.8743105],
          [100.3570357, 5.8757387],
          [100.3565674, 5.8767282],
          [100.3565219, 5.8776459],
          [100.3565428, 5.87874],
          [100.3562564, 5.8788575],
          [100.3550248, 5.878843],
          [100.3541437, 5.8788326],
          [100.3541421, 5.8790001],
          [100.3556075, 5.8790133],
          [100.3565482, 5.8790218],
          [100.3567475, 5.879503],
          [100.356879, 5.8801353],
          [100.3571789, 5.8812742],
          [100.3573058, 5.8825789],
          [100.3574578, 5.8844653],
          [100.3575597, 5.8865517],
          [100.3576617, 5.8879444],
          [100.3575866, 5.889017],
          [100.3576, 5.8898494],
          [100.3577502, 5.8921866],
          [100.3579224, 5.8932527],
          [100.3577912, 5.8936219],
          [100.3577655, 5.8937067],
          [100.3577387, 5.8937951],
          [100.3574826, 5.894867],
          [100.3574718, 5.8954368],
          [100.357575, 5.8959768],
          [100.3572573, 5.8988165],
          [100.3568469, 5.9026854],
          [100.3566702, 5.9107748],
          [100.3565262, 5.9120061],
          [100.3563661, 5.9133138],
          [100.3563527, 5.9149377],
          [100.3554457, 5.9244899],
          [100.3549326, 5.9304247],
          [100.3547555, 5.9324727],
          [100.3542627, 5.9364302],
          [100.3538901, 5.9395362],
          [100.3538671, 5.940997],
          [100.3540095, 5.9418151],
          [100.3538437, 5.942423],
          [100.3533499, 5.9436253],
          [100.3509851, 5.9580693],
          [100.3495344, 5.9666367],
          [100.3493386, 5.9684433],
          [100.3493005, 5.970308],
          [100.349439, 5.9715132],
          [100.3496696, 5.9721934],
          [100.3499654, 5.9729411],
          [100.3499763, 5.9729685],
          [100.3502231, 5.9735045],
          [100.3496741, 5.9743737],
          [100.349068, 5.975046],
          [100.3480097, 5.9764168],
          [100.3472994, 5.9784395],
          [100.3464711, 5.9820362],
          [100.3436494, 5.9934675],
          [100.3428903, 5.9964658],
          [100.3422171, 5.9992347],
          [100.34214863781429, 5.9995],
          [100.3419328, 6.0003364],
          [100.3417665, 6.0011153],
          [100.3414822, 6.0022384],
          [100.3414339, 6.0025585],
          [100.341458, 6.0026678],
          [100.3415142, 6.0027563],
          [100.3413708, 6.0033101],
          [100.3412879, 6.0037014],
          [100.3409815, 6.0044573],
          [100.3374552, 6.0163952],
          [100.3349005, 6.0244322],
          [100.3331092, 6.0295736],
          [100.329242, 6.0388249],
          [100.3286312, 6.0402594],
          [100.3278115, 6.0424632],
          [100.3270148, 6.0444829],
          [100.3270154, 6.0449871],
          [100.3269131, 6.0455275],
          [100.3266534, 6.0459568],
          [100.3262282, 6.0460276],
          [100.3253612, 6.0472598],
          [100.3253806, 6.0474903],
          [100.32437320866805, 6.049234631190602],
          [100.324385, 6.0492409],
          [100.3238847, 6.0500805],
          [100.3224175, 6.0525219],
          [100.3198815, 6.0566561],
          [100.317975, 6.0596284],
          [100.3165752, 6.0621392],
          [100.3139171, 6.0660493],
          [100.3111303, 6.0700821],
          [100.3061521, 6.0755231],
          [100.3025097, 6.0800999],
          [100.3020161, 6.0802492],
          [100.3013188, 6.0813481],
          [100.3005248, 6.0820522],
          [100.2993235, 6.0805237],
          [100.2988514, 6.0809505],
          [100.299826, 6.082072],
          [100.2993313, 6.0825064],
          [100.2994844, 6.0826787],
          [100.2965382, 6.0852661],
          [100.293433, 6.0876546],
          [100.2927188, 6.0881397],
          [100.2926328, 6.0885942],
          [100.2905856, 6.0899849],
          [100.2903549, 6.0901416],
          [100.2904815, 6.0903259],
          [100.290242, 6.0904886],
          [100.2899876, 6.0903671],
          [100.2893845, 6.0905775],
          [100.2891178, 6.0908863],
          [100.2892857, 6.0910297],
          [100.2887737, 6.0916225],
          [100.2885085, 6.091396],
          [100.2881949, 6.091759],
          [100.2886244, 6.0921258],
          [100.288148, 6.0926774],
          [100.2878158, 6.0923936],
          [100.2873448, 6.0929389],
          [100.2876103, 6.0931657],
          [100.2869391, 6.0939427],
          [100.2861327, 6.0946799],
          [100.2862093, 6.0950209],
          [100.2860265, 6.0953072],
          [100.2857542, 6.0954487],
          [100.2857005, 6.0967182],
          [100.2851587, 6.0976037],
          [100.2853492, 6.0976783],
          [100.2852312, 6.0981531],
          [100.2851024, 6.0981584],
          [100.2850434, 6.0983398],
          [100.2850917, 6.0984251],
          [100.2849683, 6.0986918],
          [100.28492, 6.0990492],
          [100.2849093, 6.0993586],
          [100.2849737, 6.099844],
          [100.285038, 6.1004147],
          [100.285266, 6.1007881],
          [100.2854162, 6.1011348],
          [100.2855503, 6.1016255],
          [100.2855879, 6.1019563],
          [100.2858186, 6.1021376],
          [100.2858829, 6.102367],
          [100.2858561, 6.102543],
          [100.2850836, 6.1031084],
          [100.2849699, 6.1028256],
          [100.2848666, 6.1031672],
          [100.2848838, 6.103259],
          [100.2850167, 6.1033581],
          [100.284905, 6.103433],
          [100.2849249, 6.1035346],
          [100.2850242, 6.1036245],
          [100.2852003, 6.1039447],
          [100.2850361, 6.1042862],
          [100.284553, 6.1049395],
          [100.2837252, 6.1062119],
          [100.2820253, 6.1088249],
          [100.2816287, 6.1088694],
          [100.2814007, 6.1089467],
          [100.281288, 6.1089494],
          [100.2809608, 6.1090134],
          [100.2807569, 6.1092267],
          [100.2798852, 6.1108723],
          [100.2794883, 6.111499],
          [100.2792764, 6.111859],
          [100.2792084, 6.1124986],
          [100.2786775, 6.1179338],
          [100.2780763, 6.1281733],
          [100.2775038, 6.1345942],
          [100.2768672, 6.1378213],
          [100.2753053, 6.1425614],
          [100.2729802, 6.149916],
          [100.2702386, 6.1579456],
          [100.2680758, 6.1635053],
          [100.2662661, 6.1680181],
          [100.2648573, 6.1711063],
          [100.2637864, 6.1733778],
          [100.2637038, 6.1739111],
          [100.263819, 6.1744332],
          [100.2637415, 6.1746322],
          [100.2636541, 6.1748567],
          [100.2630066, 6.1752756],
          [100.2599776, 6.1806924],
          [100.2584431, 6.1836239],
          [100.2583574, 6.1837876],
          [100.2563366, 6.1874474],
          [100.2547442, 6.190277],
          [100.2531685, 6.1932169],
          [100.2508456, 6.1968819],
          [100.248599, 6.2006113],
          [100.2468805, 6.203377],
          [100.2457355, 6.2052154],
          [100.2449071, 6.2062775],
          [100.2438262, 6.2076865],
          [100.2431435, 6.2086523],
          [100.2423646, 6.2100304],
          [100.2418227, 6.2111225],
          [100.2414962, 6.2120655],
          [100.2415622, 6.2128682],
          [100.2413102, 6.2131818],
          [100.2410209, 6.2136985],
          [100.2408274, 6.2138695],
          [100.2408011, 6.2141465],
          [100.2390786, 6.2146626],
          [100.238501, 6.2151584],
          [100.2381604, 6.2152415],
          [100.2372497, 6.2159315],
          [100.236569, 6.2166692],
          [100.2359914, 6.2174808],
          [100.2356275, 6.2181778],
          [100.2353324, 6.2189062],
          [100.2352565, 6.2190928],
          [100.2350328, 6.2192158],
          [100.2348012, 6.2193787],
          [100.2351599, 6.219898],
          [100.235623, 6.220482],
          [100.2353789, 6.220722],
          [100.2351994, 6.2208722],
          [100.2350667, 6.2209705],
          [100.2347263, 6.2208699],
          [100.2344312, 6.2207599],
          [100.2342405, 6.2206397],
          [100.2339863, 6.2209429],
          [100.2308252, 6.2240623],
          [100.2300691, 6.2249108],
          [100.2284148, 6.2268576],
          [100.2269245, 6.2283655],
          [100.2260338, 6.2293219],
          [100.2254271, 6.2298977],
          [100.2247623, 6.2305369],
          [100.2237756, 6.2315697],
          [100.2232127, 6.2320505],
          [100.2230927, 6.2321576],
          [100.2224791, 6.2327591],
          [100.2220143, 6.2332267],
          [100.2214264, 6.2337094],
          [100.2207566, 6.2342642],
          [100.2201869, 6.2347309],
          [100.2192675, 6.2354262],
          [100.2184329, 6.2361328],
          [100.2172221, 6.2370464],
          [100.2161461, 6.2373013],
          [100.2152592, 6.2377642],
          [100.2145813, 6.2386721],
          [100.2139992, 6.2392585],
          [100.2132315, 6.2402589],
          [100.2118301, 6.2416142],
          [100.2114653, 6.2418031],
          [100.2111942, 6.2418509],
          [100.2109045, 6.241902],
          [100.209774, 6.2422253],
          [100.2084844, 6.2426466],
          [100.2069918, 6.243521],
          [100.2059667, 6.2440923],
          [100.2046562, 6.2448472],
          [100.2032504, 6.2457036],
          [100.2024199, 6.2463684],
          [100.2011787, 6.2470264],
          [100.1994751, 6.2464471],
          [100.1972492, 6.2478715],
          [100.1970489, 6.2481859],
          [100.1967151, 6.2483058],
          [100.1956508, 6.2495779],
          [100.1953348, 6.250844],
        ],
      ],
      [
        [
          [100.036137589482081, 6.057200285143847],
          [100.0361298, 6.0572398],
          [100.036035922002995, 6.057302726480322],
          [100.036557, 6.0579936],
          [100.0372222, 6.058143],
          [100.0373724, 6.0589965],
          [100.038059, 6.0589965],
          [100.0382092, 6.0594019],
          [100.0386598, 6.0594872],
          [100.0388744, 6.0599993],
          [100.0395396, 6.0606181],
          [100.0396469, 6.0619411],
          [100.0401619, 6.0634561],
          [100.0408056, 6.0642242],
          [100.0419429, 6.0653764],
          [100.0420072, 6.0661659],
          [100.042577763644616, 6.066411743981071],
          [100.0425011, 6.0662255],
          [100.0423869, 6.0653251],
          [100.0423413, 6.0652294],
          [100.0421234, 6.0647722],
          [100.0421572, 6.0645797],
          [100.0420943, 6.0643676],
          [100.0418329, 6.0640838],
          [100.0417142, 6.0640243],
          [100.0415049, 6.0640529],
          [100.0413138, 6.0641032],
          [100.0411783, 6.06398],
          [100.0410378, 6.06382],
          [100.0409743, 6.063572],
          [100.0410067, 6.0633068],
          [100.040941, 6.0630215],
          [100.0408386, 6.0626238],
          [100.0408013, 6.0624791],
          [100.0408271, 6.0623775],
          [100.0409305, 6.0622996],
          [100.041094, 6.0620973],
          [100.0411591, 6.0619286],
          [100.0411298, 6.0615336],
          [100.0408892, 6.0614863],
          [100.0406051, 6.0612732],
          [100.0405299, 6.0610489],
          [100.0402344, 6.0607443],
          [100.039709, 6.0600733],
          [100.039546, 6.0596041],
          [100.0394084, 6.059473],
          [100.0391913, 6.0590377],
          [100.0392789, 6.0589889],
          [100.038975, 6.0588769],
          [100.0387073, 6.0586639],
          [100.0386503, 6.0587858],
          [100.0384494, 6.0586259],
          [100.0382899, 6.0582299],
          [100.038186, 6.0577681],
          [100.0376369, 6.0572731],
          [100.0375952, 6.057326],
          [100.0374972, 6.0572588],
          [100.0374752, 6.057182],
          [100.0370498, 6.0570439],
          [100.03691441886275, 6.056944625720877],
          [100.0369647, 6.057012],
          [100.0370505, 6.0574601],
          [100.0363853, 6.0571614],
          [100.036137589482081, 6.057200285143847],
        ],
      ],
      [
        [
          [100.043863105486167, 6.067801781617972],
          [100.043275, 6.0672485],
          [100.042709315772839, 6.066622571581339],
          [100.042887, 6.0671688],
          [100.0437024, 6.0677449],
          [100.043863105486167, 6.067801781617972],
        ],
      ],
      [
        [
          [99.7928384, 6.1584105],
          [99.792662, 6.1585671],
          [99.7926739, 6.1588588],
          [99.792805, 6.159101],
          [99.7927711, 6.1598153],
          [99.793053, 6.16089],
          [99.7930662, 6.1612077],
          [99.7935525, 6.1619427],
          [99.7936204, 6.1622723],
          [99.7937353, 6.1625898],
          [99.7939359, 6.1628753],
          [99.7940404, 6.1630235],
          [99.7944453, 6.1632463],
          [99.7948365, 6.163197],
          [99.7951087, 6.1630087],
          [99.7954234, 6.1624617],
          [99.7957563, 6.161919],
          [99.7959498, 6.1614737],
          [99.796294, 6.161223],
          [99.796489, 6.1607643],
          [99.7968009, 6.1606147],
          [99.7970088, 6.1603897],
          [99.797013, 6.1600367],
          [99.7969997, 6.1595867],
          [99.7971597, 6.1592332],
          [99.7970086, 6.1587837],
          [99.7969209, 6.1586101],
          [99.7973677, 6.1584121],
          [99.7976821, 6.1578883],
          [99.7977099, 6.1577152],
          [99.7980231, 6.1572837],
          [99.7980702, 6.1570822],
          [99.7983574, 6.1563378],
          [99.7983633, 6.1561929],
          [99.7982355, 6.1561379],
          [99.7980014, 6.1562839],
          [99.797898, 6.1564905],
          [99.7978628, 6.1566691],
          [99.798007, 6.1568555],
          [99.7979521, 6.1569724],
          [99.7977136, 6.1569716],
          [99.7974726, 6.1573966],
          [99.7974411, 6.1577986],
          [99.7971636, 6.1583678],
          [99.7968447, 6.1584717],
          [99.7963755, 6.1584484],
          [99.7962924, 6.1580305],
          [99.7958689, 6.1576427],
          [99.7955571, 6.1575547],
          [99.7954169, 6.1571893],
          [99.794798, 6.1570649],
          [99.794458, 6.1570578],
          [99.7938423, 6.1573637],
          [99.7938621, 6.1576287],
          [99.7939892, 6.1579577],
          [99.7939754, 6.1582382],
          [99.7937042, 6.1581133],
          [99.7933203, 6.1581149],
          [99.7931617, 6.1583405],
          [99.793145, 6.158621],
          [99.7928325, 6.158606],
          [99.7928384, 6.1584105],
        ],
      ],
      [
        [
          [99.800404694664365, 6.159991436180138],
          [99.8002639, 6.1603725],
          [99.800188482357143, 6.160390415429484],
          [99.800134, 6.160933],
          [99.800316, 6.162656],
          [99.800241, 6.163516],
          [99.800339885162913, 6.163605180696845],
          [99.8004849, 6.1636923],
          [99.8006916, 6.1637771],
          [99.8009155, 6.1637416],
          [99.8010212, 6.1635665],
          [99.8008696, 6.1634031],
          [99.8009101, 6.1631515],
          [99.8011095, 6.1627293],
          [99.8011535, 6.1624137],
          [99.8013153, 6.1621941],
          [99.8014145, 6.1615141],
          [99.8014324, 6.1611353],
          [99.8012909, 6.1609361],
          [99.8012325, 6.160573],
          [99.8011055, 6.1595605],
          [99.801103102547359, 6.159558954237562],
          [99.800875, 6.159481],
          [99.800404694664365, 6.159991436180138],
        ],
      ],
      [
        [
          [99.8125269, 6.1651933],
          [99.8123844, 6.1659065],
          [99.8127401, 6.1664999],
          [99.8132832, 6.1668901],
          [99.8131768, 6.1669333],
          [99.8132322, 6.1671966],
          [99.813343, 6.1673],
          [99.8135062, 6.1672854],
          [99.8136354, 6.1669321],
          [99.814, 6.1664439],
          [99.8145349, 6.1661928],
          [99.8147021, 6.1665032],
          [99.8145256, 6.1667855],
          [99.8150922, 6.1671976],
          [99.815442, 6.1672599],
          [99.8154263, 6.1669951],
          [99.8158547, 6.167092],
          [99.8162006, 6.1669302],
          [99.8163986, 6.166759],
          [99.8164909, 6.1665873],
          [99.8162171, 6.1662124],
          [99.8161889, 6.1659707],
          [99.8159536, 6.1659613],
          [99.8157045, 6.1661456],
          [99.8154911, 6.1660927],
          [99.8154906, 6.1657096],
          [99.8153022, 6.1655531],
          [99.8150956, 6.1655278],
          [99.8150062, 6.1655788],
          [99.8150658, 6.1656808],
          [99.814793, 6.1659703],
          [99.8146188, 6.1659622],
          [99.8145808, 6.1654969],
          [99.8144877, 6.1651758],
          [99.814523, 6.1649908],
          [99.8146958, 6.1649426],
          [99.8147688, 6.1644905],
          [99.8147878, 6.1638052],
          [99.8146781, 6.1636318],
          [99.8146876, 6.1633738],
          [99.814964, 6.1630868],
          [99.815202, 6.163012],
          [99.8154832, 6.1630291],
          [99.815611, 6.1634526],
          [99.8157816, 6.1635377],
          [99.8160036, 6.1635215],
          [99.8160729, 6.1633707],
          [99.8161291, 6.1632085],
          [99.8157115, 6.163055],
          [99.815609, 6.1629946],
          [99.8156253, 6.162806],
          [99.8155617, 6.1625606],
          [99.8153023, 6.1625352],
          [99.8152434, 6.1626709],
          [99.814904, 6.1626881],
          [99.8147873, 6.162638],
          [99.8146561, 6.1624362],
          [99.8144789, 6.1622187],
          [99.814414, 6.1623765],
          [99.814216, 6.1624381],
          [99.8141954, 6.162354],
          [99.8140471, 6.1622445],
          [99.8136142, 6.1623133],
          [99.8133445, 6.1626541],
          [99.8132445, 6.1629619],
          [99.8131832, 6.1636276],
          [99.8131985, 6.1638955],
          [99.8130759, 6.1638216],
          [99.8129549, 6.1639924],
          [99.8128594, 6.1643919],
          [99.8130092, 6.1645812],
          [99.8127466, 6.1647735],
          [99.8125269, 6.1651933],
        ],
      ],
      [
        [
          [99.7778276, 6.1672371],
          [99.7775276, 6.1673918],
          [99.777525104511795, 6.167527912606672],
          [99.7775552, 6.1676454],
          [99.777930980788568, 6.167626522365174],
          [99.7779913, 6.1676204],
          [99.7781095, 6.1675682],
          [99.7781231, 6.1674061],
          [99.7781581, 6.1673667],
          [99.7785778, 6.1672943],
          [99.7788534, 6.1673065],
          [99.7791322, 6.1671426],
          [99.7794954, 6.1667031],
          [99.7797948, 6.1665791],
          [99.7798394, 6.1663175],
          [99.779901, 6.166039],
          [99.7798252, 6.1658629],
          [99.7798465, 6.1656371],
          [99.7796443, 6.1657613],
          [99.7794411, 6.1658065],
          [99.7791521, 6.165736],
          [99.778812, 6.165767],
          [99.7785791, 6.1660656],
          [99.7782485, 6.1666741],
          [99.7779661, 6.1667407],
          [99.7779356, 6.166901],
          [99.7780226, 6.1671081],
          [99.7778276, 6.1672371],
        ],
      ],
      [
        [
          [99.8112059, 6.1677499],
          [99.8109715, 6.1683224],
          [99.8111142, 6.1684946],
          [99.8110734, 6.1685352],
          [99.8111193, 6.1686821],
          [99.8112467, 6.1686213],
          [99.8112365, 6.1688341],
          [99.8113333, 6.1691482],
          [99.8114454, 6.1690317],
          [99.8114199, 6.1688949],
          [99.8115015, 6.1686264],
          [99.8116849, 6.1683933],
          [99.8119091, 6.16776],
          [99.8120467, 6.1674358],
          [99.8122811, 6.1671673],
          [99.8126072, 6.1670254],
          [99.8124595, 6.1667975],
          [99.8124085, 6.1666404],
          [99.8118735, 6.166762],
          [99.8116034, 6.1667164],
          [99.8112824, 6.1666201],
          [99.8115117, 6.1672737],
          [99.8112059, 6.1677499],
        ],
      ],
      [
        [
          [99.73189786935518, 6.173771207308086],
          [99.731902, 6.1737677],
          [99.7319491, 6.1737625],
          [99.7319898, 6.1736915],
          [99.7320514, 6.1736902],
          [99.7321956, 6.1732171],
          [99.7322178, 6.1730694],
          [99.7321499, 6.1729134],
          [99.7319065, 6.1727336],
          [99.731763, 6.1727071],
          [99.7317289, 6.1727917],
          [99.7316362, 6.1727827],
          [99.7315176, 6.1729252],
          [99.7315504, 6.173013],
          [99.7315325, 6.1731025],
          [99.7314238, 6.1731951],
          [99.7314314, 6.1732619],
          [99.731442, 6.1733398],
          [99.7313855, 6.1733719],
          [99.7314149, 6.1735168],
          [99.7314572, 6.1736325],
          [99.7314302, 6.1736562],
          [99.7314029, 6.1735928],
          [99.7314168, 6.1737525],
          [99.7314582, 6.1738918],
          [99.731520555459383, 6.173918904257472],
          [99.731546528341724, 6.17390428022988],
          [99.7315597, 6.1738709],
          [99.73159764964791, 6.173875496385927],
          [99.731801, 6.173761],
          [99.73189786935518, 6.173771207308086],
        ],
      ],
      [
        [
          [99.7739354, 6.169312],
          [99.773624437246184, 6.16939643585471],
          [99.7738574, 6.1698917],
          [99.773888524857099, 6.169927931763168],
          [99.7740839, 6.169792],
          [99.774032160033755, 6.170095134320468],
          [99.7741085, 6.170184],
          [99.774602135008195, 6.170389714427815],
          [99.7746423, 6.1703721],
          [99.774664181736611, 6.170372123106374],
          [99.7748628, 6.1703103],
          [99.7750238, 6.170687],
          [99.7755208, 6.1707927],
          [99.7751189, 6.1707916],
          [99.775125162659236, 6.170806801668574],
          [99.775301410050162, 6.171015110444948],
          [99.77535377951574, 6.171027972706662],
          [99.776029, 6.1709307],
          [99.7761833, 6.1712013],
          [99.7763799, 6.1712483],
          [99.7762314, 6.1713572],
          [99.7762001, 6.1714863],
          [99.7764741, 6.1716255],
          [99.7766755, 6.1716097],
          [99.776598, 6.1718713],
          [99.776706, 6.172116],
          [99.777093, 6.172414],
          [99.77749, 6.172545],
          [99.77785, 6.173011],
          [99.7781673, 6.1729377],
          [99.7781989, 6.1730627],
          [99.7784823, 6.1730527],
          [99.7782615, 6.1726375],
          [99.7783026, 6.172255],
          [99.7780834, 6.1721692],
          [99.7779094, 6.1719769],
          [99.7779619, 6.1715715],
          [99.7779179, 6.1711447],
          [99.7774094, 6.1709047],
          [99.7771873, 6.170902],
          [99.7768939, 6.170506],
          [99.7767722, 6.170238],
          [99.7765588, 6.1703363],
          [99.7762293, 6.1702603],
          [99.7758782, 6.1703337],
          [99.7754014, 6.1698937],
          [99.7750842, 6.1697233],
          [99.7749064, 6.1693077],
          [99.7751388, 6.1690137],
          [99.7749133, 6.1684927],
          [99.7752259, 6.168194],
          [99.7752254, 6.167959],
          [99.7753994, 6.1680517],
          [99.7754299, 6.1677777],
          [99.7752801, 6.1676667],
          [99.7750659, 6.1677037],
          [99.7750482, 6.1678265],
          [99.7748354, 6.1680906],
          [99.7743965, 6.1682653],
          [99.7742843, 6.1683633],
          [99.7742711, 6.168519],
          [99.7744576, 6.1686182],
          [99.774215, 6.169016],
          [99.7740591, 6.1691107],
          [99.7739354, 6.169312],
        ],
      ],
      [
        [
          [99.783661121746206, 6.172458289144578],
          [99.7837549, 6.1728774],
          [99.7834486, 6.1727315],
          [99.783274, 6.1728982],
          [99.783062728814542, 6.173221935575498],
          [99.783035212337396, 6.173722735459506],
          [99.783038, 6.1738267],
          [99.7830902, 6.174251],
          [99.7833994, 6.1746007],
          [99.7837086, 6.1748702],
          [99.784061, 6.1749806],
          [99.785105, 6.175538],
          [99.7854471, 6.1756148],
          [99.7856509, 6.1755999],
          [99.785897, 6.1754784],
          [99.7862215, 6.1752146],
          [99.7866684, 6.1744782],
          [99.787, 6.1741658],
          [99.7874932, 6.173455],
          [99.7880791, 6.1729401],
          [99.788227, 6.1727345],
          [99.7882111, 6.1722664],
          [99.788174633186287, 6.172198065229117],
          [99.787391, 6.171779],
          [99.786132, 6.171903],
          [99.785663, 6.172496],
          [99.783946, 6.172221],
          [99.783661121746206, 6.172458289144578],
        ],
      ],
      [
        [
          [99.8338002, 6.1894833],
          [99.8335493, 6.1900309],
          [99.8335946, 6.1901966],
          [99.8337847, 6.1902352],
          [99.834074, 6.18989],
          [99.8341255, 6.1895833],
          [99.8344324, 6.189429],
          [99.8346748, 6.1892142],
          [99.8345571, 6.1888491],
          [99.8342011, 6.1887294],
          [99.8338644, 6.1891402],
          [99.8338002, 6.1894833],
        ],
      ],
      [
        [
          [99.8424658, 6.191073],
          [99.8419912, 6.1917274],
          [99.8420954, 6.1919217],
          [99.8424853, 6.1920437],
          [99.8427161, 6.1917407],
          [99.8427283, 6.1914224],
          [99.8425209, 6.1912488],
          [99.842943, 6.1909381],
          [99.843129, 6.191054],
          [99.8434213, 6.191248],
          [99.8436538, 6.1910652],
          [99.8437469, 6.1907863],
          [99.8439853, 6.190638],
          [99.8440521, 6.190095],
          [99.8438783, 6.1899628],
          [99.8438762, 6.1895747],
          [99.8434329, 6.1900697],
          [99.8432354, 6.1903437],
          [99.8426313, 6.190456],
          [99.8425483, 6.190625],
          [99.8424658, 6.191073],
        ],
      ],
      [
        [
          [99.8359436, 6.188765],
          [99.8355805, 6.1890519],
          [99.8361915, 6.1889184],
          [99.8366493, 6.188861],
          [99.8368695, 6.1889647],
          [99.8372089, 6.1887128],
          [99.837658, 6.188786],
          [99.8376387, 6.1885713],
          [99.8375567, 6.188447],
          [99.8373725, 6.1883397],
          [99.8371893, 6.188325],
          [99.837004, 6.1881992],
          [99.8366825, 6.1880819],
          [99.8363697, 6.1883134],
          [99.8362156, 6.1885815],
          [99.8361706, 6.1886643],
          [99.8359436, 6.188765],
        ],
      ],
      [
        [
          [99.8449501, 6.1939377],
          [99.8443956, 6.1942215],
          [99.8445706, 6.1944307],
          [99.8445648, 6.1946617],
          [99.8447951, 6.194786],
          [99.8450826, 6.194532],
          [99.8451763, 6.194283],
          [99.8453988, 6.1940553],
          [99.8454652, 6.1938467],
          [99.8452665, 6.1937737],
          [99.8449501, 6.1939377],
        ],
      ],
      [
        [
          [99.8406697, 6.1937262],
          [99.8405251, 6.1939779],
          [99.8405815, 6.1941145],
          [99.8408136, 6.1943107],
          [99.8412817, 6.1945817],
          [99.8414356, 6.1947575],
          [99.8417378, 6.1949414],
          [99.8419972, 6.195296],
          [99.8421594, 6.1950734],
          [99.8420763, 6.1947197],
          [99.8422199, 6.194431],
          [99.8424811, 6.1942735],
          [99.8425278, 6.1943933],
          [99.8429, 6.194313],
          [99.843232, 6.194183],
          [99.8434169, 6.1938657],
          [99.843237, 6.193719],
          [99.842775, 6.193584],
          [99.8421917, 6.1935962],
          [99.8419409, 6.194131],
          [99.8414933, 6.1941697],
          [99.8411729, 6.1942423],
          [99.841002, 6.19414],
          [99.8406697, 6.1937262],
        ],
      ],
      [
        [
          [99.7651436, 6.208713],
          [99.7649772, 6.2093913],
          [99.7656384, 6.209708],
          [99.7657777, 6.2099165],
          [99.766904, 6.210381],
          [99.767833, 6.210122],
          [99.768384, 6.2090617],
          [99.7688471, 6.2087237],
          [99.769439, 6.208279],
          [99.7693472, 6.2060007],
          [99.769264, 6.2051092],
          [99.7691164, 6.204239],
          [99.7687317, 6.2038292],
          [99.7678321, 6.203782],
          [99.766854, 6.2046295],
          [99.7669487, 6.205285],
          [99.7672991, 6.2062725],
          [99.7670485, 6.206812],
          [99.7662866, 6.20785],
          [99.765868, 6.207992],
          [99.7651436, 6.208713],
        ],
      ],
      [
        [
          [99.830477904761011, 6.206114965749844],
          [99.8303921, 6.2062743],
          [99.8302685, 6.2065408],
          [99.8304871, 6.2067997],
          [99.830493050655463, 6.206887322614531],
          [99.830636, 6.207033],
          [99.830959, 6.207895],
          [99.831225747246421, 6.208688803012649],
          [99.8314271, 6.2089348],
          [99.8315584, 6.2089655],
          [99.8318162, 6.2088965],
          [99.8319847, 6.208744],
          [99.8322663, 6.2086477],
          [99.8323867, 6.2085185],
          [99.8323376, 6.2083106],
          [99.8321841, 6.208289],
          [99.8323191, 6.2077783],
          [99.8319379, 6.2072064],
          [99.8319695, 6.2069717],
          [99.8318557, 6.2065492],
          [99.8318515, 6.2063082],
          [99.831640960408222, 6.206125830051972],
          [99.830811, 6.20604],
          [99.830477904761011, 6.206114965749844],
        ],
      ],
      [
        [
          [99.8719117, 6.2350337],
          [99.8715877, 6.2355173],
          [99.8716532, 6.2358245],
          [99.8722558, 6.2362685],
          [99.8725324, 6.2362938],
          [99.8726424, 6.2366959],
          [99.8729788, 6.2368025],
          [99.8730688, 6.2365098],
          [99.8731908, 6.2352815],
          [99.8728564, 6.2349046],
          [99.8723328, 6.2350115],
          [99.8719117, 6.2350337],
        ],
      ],
      [
        [
          [99.721678, 6.2073852],
          [99.7209285, 6.2075211],
          [99.7210944, 6.2082341],
          [99.7219208, 6.208604],
          [99.7225445, 6.2099549],
          [99.7230126, 6.2104727],
          [99.723751, 6.210641],
          [99.72414, 6.211571],
          [99.723601, 6.212494],
          [99.7239878, 6.2129959],
          [99.7244381, 6.2128168],
          [99.7247443, 6.2130363],
          [99.7244108, 6.213388],
          [99.724645, 6.214089],
          [99.7255383, 6.2151641],
          [99.7259683, 6.2156809],
          [99.7271283, 6.2156602],
          [99.7270527, 6.2164139],
          [99.7277735, 6.2170311],
          [99.727906, 6.219009],
          [99.728936, 6.222061],
          [99.731943, 6.22592],
          [99.732973, 6.228971],
          [99.733631, 6.229373],
          [99.734161, 6.229244],
          [99.733907, 6.228183],
          [99.733529, 6.226194],
          [99.733803, 6.225269],
          [99.73317, 6.22222],
          [99.733717, 6.22037],
          [99.734635, 6.221302],
          [99.736069, 6.223562],
          [99.736853, 6.224626],
          [99.737901, 6.225825],
          [99.738014, 6.227811],
          [99.7392, 6.228348],
          [99.7413392, 6.2293955],
          [99.7420426, 6.2292319],
          [99.7424293, 6.2290615],
          [99.7428566, 6.2293346],
          [99.7430159, 6.2295636],
          [99.743094, 6.2303032],
          [99.7427637, 6.2307613],
          [99.7421054, 6.2322407],
          [99.7415689, 6.2330885],
          [99.7415121, 6.2337685],
          [99.7418652, 6.2336977],
          [99.7421499, 6.2331248],
          [99.7421165, 6.2329055],
          [99.7435566, 6.2321891],
          [99.7442219, 6.2312207],
          [99.7441947, 6.2309689],
          [99.7433564, 6.2300721],
          [99.7432139, 6.2293963],
          [99.7429903, 6.2287607],
          [99.7423503, 6.2285696],
          [99.7422606, 6.227978],
          [99.7422022, 6.2261369],
          [99.7425223, 6.2250483],
          [99.743878, 6.223611],
          [99.7465221, 6.2216772],
          [99.7484811, 6.2219344],
          [99.7485426, 6.2221024],
          [99.7488573, 6.2221192],
          [99.7491497, 6.221832],
          [99.7497826, 6.2217287],
          [99.7503559, 6.2218786],
          [99.7510751, 6.2217923],
          [99.751578, 6.221276],
          [99.7520425, 6.2203457],
          [99.7517636, 6.2196977],
          [99.751085, 6.217433],
          [99.7515357, 6.2167726],
          [99.7517053, 6.2150751],
          [99.7508745, 6.2136598],
          [99.7501469, 6.2128003],
          [99.7486993, 6.2124057],
          [99.7472855, 6.211981],
          [99.7467425, 6.2114093],
          [99.7465916, 6.2111333],
          [99.746533, 6.2103124],
          [99.7456319, 6.2090469],
          [99.743489, 6.209044],
          [99.742554, 6.208831],
          [99.741646, 6.207973],
          [99.7411225, 6.2068151],
          [99.7408091, 6.2065391],
          [99.7404958, 6.2065318],
          [99.7401419, 6.206323],
          [99.739815, 6.205711],
          [99.7391818, 6.2053097],
          [99.7378674, 6.2045358],
          [99.7383331, 6.2037676],
          [99.7387704, 6.2033496],
          [99.7396192, 6.2034622],
          [99.7411321, 6.2029823],
          [99.7421346, 6.2023709],
          [99.742917, 6.2023224],
          [99.7439559, 6.2025449],
          [99.7442726, 6.2023873],
          [99.7448973, 6.2025437],
          [99.7450339, 6.2027294],
          [99.7452374, 6.2026879],
          [99.7450958, 6.2022237],
          [99.744373, 6.2019745],
          [99.7440161, 6.201593],
          [99.7438485, 6.2008084],
          [99.7437402, 6.2004961],
          [99.7435779, 6.2004341],
          [99.7435643, 6.2000361],
          [99.7430821, 6.1995307],
          [99.7430032, 6.1991302],
          [99.7430989, 6.1986612],
          [99.743073, 6.1980309],
          [99.7436978, 6.1970328],
          [99.7438346, 6.1974475],
          [99.7447951, 6.1977759],
          [99.7453834, 6.1975503],
          [99.745323, 6.197271],
          [99.7458393, 6.1973005],
          [99.7461663, 6.1975182],
          [99.7473611, 6.197201],
          [99.7480723, 6.1962463],
          [99.748378, 6.196098],
          [99.7487483, 6.1950932],
          [99.7481514, 6.1939787],
          [99.7468527, 6.1934215],
          [99.7467441, 6.1929816],
          [99.7464597, 6.192582],
          [99.7462764, 6.1920148],
          [99.7456335, 6.1917433],
          [99.7452977, 6.1912364],
          [99.7454216, 6.1906759],
          [99.7449438, 6.1895015],
          [99.7447591, 6.1893846],
          [99.744572, 6.1887349],
          [99.7444245, 6.1886116],
          [99.7444391, 6.1876551],
          [99.7442102, 6.1867243],
          [99.7440147, 6.1865545],
          [99.743711, 6.185874],
          [99.742649, 6.186264],
          [99.741572, 6.188111],
          [99.740634, 6.189297],
          [99.7378465, 6.1892686],
          [99.7363264, 6.1896253],
          [99.734536, 6.190318],
          [99.730831, 6.190163],
          [99.7299759, 6.189552],
          [99.7291893, 6.1895032],
          [99.7279173, 6.1886455],
          [99.727849, 6.1881629],
          [99.727943, 6.187761],
          [99.7275261, 6.1865435],
          [99.7275204, 6.1853569],
          [99.7278932, 6.1843584],
          [99.7276326, 6.1827904],
          [99.7273411, 6.182553],
          [99.7266576, 6.18034],
          [99.7266636, 6.1798436],
          [99.7262611, 6.1789638],
          [99.7257369, 6.1783931],
          [99.724728, 6.177943],
          [99.7243706, 6.1779722],
          [99.7240672, 6.1781627],
          [99.7239132, 6.1798135],
          [99.7239473, 6.1809004],
          [99.7238733, 6.1827533],
          [99.7236223, 6.1830062],
          [99.7233347, 6.1839658],
          [99.7235336, 6.1847374],
          [99.7237182, 6.184881],
          [99.7231458, 6.1860595],
          [99.722853, 6.1874224],
          [99.723182, 6.187334],
          [99.7236786, 6.1873121],
          [99.7240215, 6.1870727],
          [99.7247835, 6.1871021],
          [99.725821, 6.188145],
          [99.7269464, 6.1890527],
          [99.7276984, 6.1890093],
          [99.727396, 6.189611],
          [99.727392, 6.190009],
          [99.725901, 6.193574],
          [99.725096, 6.194761],
          [99.7245353, 6.1962676],
          [99.724272, 6.197801],
          [99.7232775, 6.1992677],
          [99.7231102, 6.2000656],
          [99.7234565, 6.2008904],
          [99.7239447, 6.2013559],
          [99.7240815, 6.2026142],
          [99.724089, 6.203096],
          [99.7243927, 6.20369],
          [99.7248854, 6.2037711],
          [99.725348, 6.2041207],
          [99.724944, 6.2046315],
          [99.724404, 6.2045509],
          [99.7239583, 6.2049004],
          [99.7236613, 6.2045376],
          [99.7225745, 6.2047843],
          [99.7222758, 6.2054658],
          [99.7217735, 6.2061351],
          [99.7217251, 6.2068594],
          [99.721501, 6.2072209],
          [99.721678, 6.2073852],
        ],
      ],
      [
        [
          [99.7105609, 6.2313168],
          [99.7101394, 6.2318715],
          [99.7101417, 6.2324639],
          [99.7106981, 6.2341716],
          [99.7111757, 6.2345683],
          [99.7120723, 6.235637],
          [99.7125138, 6.2357587],
          [99.7126472, 6.2353951],
          [99.7123616, 6.2351122],
          [99.7123851, 6.2345732],
          [99.7127296, 6.2339253],
          [99.713094, 6.23357],
          [99.7131051, 6.2332586],
          [99.7148001, 6.2323045],
          [99.7152642, 6.2324496],
          [99.7155503, 6.2329925],
          [99.7158961, 6.2328205],
          [99.7156066, 6.2322589],
          [99.7151498, 6.23205],
          [99.7152209, 6.2318977],
          [99.7154229, 6.2314648],
          [99.7158239, 6.2310708],
          [99.7165346, 6.2305158],
          [99.717947, 6.2299102],
          [99.7182623, 6.2297907],
          [99.7185697, 6.2294986],
          [99.7195785, 6.2294405],
          [99.7203982, 6.2295301],
          [99.7212184, 6.2299041],
          [99.7217278, 6.2299958],
          [99.7220436, 6.2298837],
          [99.7222697, 6.2294446],
          [99.7221487, 6.2289379],
          [99.7214578, 6.2282573],
          [99.7214428, 6.2279665],
          [99.7210342, 6.2276535],
          [99.7211382, 6.2267631],
          [99.7213369, 6.2260206],
          [99.7217882, 6.2250998],
          [99.7221667, 6.2239089],
          [99.721973, 6.2227335],
          [99.7221443, 6.2220418],
          [99.7221139, 6.2214131],
          [99.7218672, 6.2210665],
          [99.72154, 6.2207906],
          [99.7199151, 6.2201922],
          [99.7197033, 6.2199871],
          [99.7192347, 6.2188413],
          [99.7194416, 6.2178202],
          [99.7190808, 6.217471],
          [99.7184484, 6.2173516],
          [99.7183026, 6.2171113],
          [99.7178217, 6.2168726],
          [99.7173335, 6.2161355],
          [99.716994, 6.2153716],
          [99.7166377, 6.2151062],
          [99.7149726, 6.2152794],
          [99.7148657, 6.2154798],
          [99.714798, 6.2157694],
          [99.7145801, 6.2163044],
          [99.7141014, 6.2166489],
          [99.7136375, 6.2165488],
          [99.7125294, 6.217038],
          [99.712344, 6.2176979],
          [99.7124024, 6.2183137],
          [99.7126691, 6.2184611],
          [99.7124959, 6.2193978],
          [99.7125657, 6.2198943],
          [99.7121848, 6.2201347],
          [99.711745, 6.2199971],
          [99.7114789, 6.2202756],
          [99.7106656, 6.2207675],
          [99.7110133, 6.2222866],
          [99.7115004, 6.2229524],
          [99.7115735, 6.2242202],
          [99.7119986, 6.224741],
          [99.7117981, 6.2251561],
          [99.7117048, 6.2255926],
          [99.7118951, 6.2265418],
          [99.7122034, 6.2270751],
          [99.7127066, 6.227414],
          [99.7129291, 6.2278497],
          [99.7120493, 6.2286138],
          [99.7119773, 6.2294425],
          [99.7121891, 6.2302175],
          [99.7121533, 6.2305848],
          [99.7114597, 6.2305402],
          [99.7109648, 6.2305047],
          [99.7105156, 6.230595],
          [99.7104823, 6.2308294],
          [99.7105609, 6.2313168],
        ],
      ],
      [
        [
          [99.74853318543542, 6.231302575225221],
          [99.7482861, 6.2312388],
          [99.748140314967628, 6.23114173048682],
          [99.7477675, 6.2311367],
          [99.7475889, 6.2313946],
          [99.7476028, 6.2319226],
          [99.747430371223615, 6.232277333504354],
          [99.747577496833927, 6.232436255377402],
          [99.747785108604262, 6.232577896551642],
          [99.7484409, 6.2327983],
          [99.749093619183171, 6.23262523227872],
          [99.7492735, 6.2324627],
          [99.749446835375039, 6.232419990679325],
          [99.749530087427772, 6.232340795925298],
          [99.74970667880126, 6.232047505503456],
          [99.749700834297784, 6.232014644147332],
          [99.7495838, 6.2317547],
          [99.7494987, 6.2316357],
          [99.7491078, 6.2313914],
          [99.748976950602625, 6.23137428763232],
          [99.7486084, 6.2313356],
          [99.74853318543542, 6.231302575225221],
        ],
      ],
      [
        [
          [99.834343268251118, 6.211993362638801],
          [99.8343001, 6.2119387],
          [99.834173, 6.211825],
          [99.8339276, 6.211761],
          [99.8337869, 6.211801],
          [99.8337449, 6.2118863],
          [99.8337613, 6.212069],
          [99.8337265, 6.2121284],
          [99.8338111, 6.212254],
          [99.8338131, 6.2124609],
          [99.8337337, 6.2127589],
          [99.8337535, 6.2128277],
          [99.8338205, 6.2128399],
          [99.8338352, 6.2129846],
          [99.833820065765053, 6.213173195850924],
          [99.833912451776712, 6.213361876513191],
          [99.8340162, 6.2133827],
          [99.8341012, 6.2133875],
          [99.8341544, 6.2134779],
          [99.8343202, 6.2135683],
          [99.8344791, 6.2136358],
          [99.8345816, 6.2137593],
          [99.8346554, 6.2137915],
          [99.8347318, 6.2137757],
          [99.8348179, 6.2136583],
          [99.834818208778699, 6.213655742506952],
          [99.834523, 6.212485],
          [99.834343268251118, 6.211993362638801],
        ],
      ],
      [
        [
          [99.7600497, 6.211337],
          [99.759663, 6.2118767],
          [99.7600059, 6.212523],
          [99.7606489, 6.2124505],
          [99.761291, 6.2129027],
          [99.7620163, 6.2128041],
          [99.7621407, 6.2126629],
          [99.7626043, 6.212679],
          [99.7626707, 6.2133913],
          [99.7629731, 6.2135277],
          [99.7626081, 6.2146855],
          [99.7625435, 6.2157366],
          [99.7626719, 6.216303],
          [99.763273, 6.2170209],
          [99.7636809, 6.216971],
          [99.7637923, 6.2168335],
          [99.7641397, 6.2155867],
          [99.7646199, 6.215013],
          [99.7655667, 6.2149582],
          [99.7659555, 6.2146474],
          [99.7660806, 6.2143637],
          [99.7663131, 6.21376],
          [99.766667, 6.2132043],
          [99.7661197, 6.2124993],
          [99.765743, 6.2123851],
          [99.7650015, 6.2123776],
          [99.7642645, 6.2124733],
          [99.7635919, 6.2121849],
          [99.7633624, 6.2116083],
          [99.7621282, 6.210734],
          [99.7614949, 6.2104997],
          [99.7605286, 6.2112683],
          [99.7600497, 6.211337],
        ],
      ],
      [
        [
          [99.7568928, 6.2223255],
          [99.7566683, 6.2224349],
          [99.7567007, 6.2230146],
          [99.7571506, 6.223774],
          [99.7574344, 6.2240092],
          [99.7575605, 6.2237996],
          [99.7573949, 6.2235516],
          [99.7576619, 6.2230886],
          [99.7575326, 6.222751],
          [99.7576302, 6.2225755],
          [99.7575385, 6.2219163],
          [99.7569656, 6.2225643],
          [99.7568928, 6.2223255],
        ],
      ],
      [
        [
          [99.8713768, 6.2387958],
          [99.8713697, 6.2392261],
          [99.8715542, 6.2396531],
          [99.8719147, 6.2398772],
          [99.8726077, 6.2395468],
          [99.8728857, 6.2394742],
          [99.8732172, 6.239551],
          [99.8738991, 6.2394444],
          [99.8740766, 6.2387938],
          [99.8743853, 6.2383764],
          [99.8741942, 6.2380011],
          [99.8735097, 6.237807],
          [99.8734401, 6.2377037],
          [99.873279, 6.2377304],
          [99.8732472, 6.2379487],
          [99.873057, 6.2380094],
          [99.8728288, 6.2379382],
          [99.8727231, 6.2380125],
          [99.872595806752372, 6.238111473750992],
          [99.8724948, 6.2382437],
          [99.8723535, 6.2383737],
          [99.872015681711275, 6.238517909589886],
          [99.8718815, 6.2385886],
          [99.8717695, 6.2385925],
          [99.8715555, 6.2386001],
          [99.8713768, 6.2387958],
        ],
      ],
      [
        [
          [99.712134917802473, 6.241560787516591],
          [99.7121077, 6.2415883],
          [99.712035819644697, 6.241589632351349],
          [99.7118217, 6.2419793],
          [99.711915, 6.2424331],
          [99.7122339, 6.2426452],
          [99.7121483, 6.2431582],
          [99.7123787, 6.2436183],
          [99.712292065321719, 6.244160276355026],
          [99.7124597, 6.2441983],
          [99.712515536699897, 6.244284345468795],
          [99.7129288, 6.2443327],
          [99.7139014, 6.2440704],
          [99.7142433, 6.2444988],
          [99.7148243, 6.2444861],
          [99.7147167, 6.244121],
          [99.7146971, 6.2440545],
          [99.7140853, 6.2435169],
          [99.7134241, 6.2430626],
          [99.7132272, 6.2429273],
          [99.7129979, 6.2422308],
          [99.712897231043343, 6.241811306720787],
          [99.7125913, 6.2415378],
          [99.712134917802473, 6.241560787516591],
        ],
      ],
      [
        [
          [99.7171488, 6.2449113],
          [99.7169718, 6.2464146],
          [99.7196749, 6.2475008],
          [99.7202516, 6.2456963],
          [99.7207693, 6.2459496],
          [99.7210176, 6.2458243],
          [99.7211469, 6.2453305],
          [99.721818, 6.2452057],
          [99.7220546, 6.2450137],
          [99.7217295, 6.2447087],
          [99.7221924, 6.2443383],
          [99.7230642, 6.2441466],
          [99.7232369, 6.2438832],
          [99.7232208, 6.2434539],
          [99.7240594, 6.2428933],
          [99.7254465, 6.2437083],
          [99.7265634, 6.2438187],
          [99.7269115, 6.2441776],
          [99.7271642, 6.2442298],
          [99.7273407, 6.2440938],
          [99.7275944, 6.2439696],
          [99.7275907, 6.2432625],
          [99.7266943, 6.2427927],
          [99.726528, 6.2424749],
          [99.726227, 6.2422557],
          [99.7257909, 6.2422365],
          [99.7253692, 6.2425778],
          [99.7248983, 6.242477],
          [99.7243339, 6.2422189],
          [99.7238179, 6.2414921],
          [99.7235486, 6.2403215],
          [99.7234955, 6.2395952],
          [99.7236001, 6.2387889],
          [99.7241853, 6.236923],
          [99.7236054, 6.2355381],
          [99.72276, 6.2355381],
          [99.7217751, 6.2360085],
          [99.7209608, 6.2357989],
          [99.7206641, 6.2372659],
          [99.720427, 6.2384732],
          [99.7207913, 6.2394209],
          [99.7208068, 6.2403215],
          [99.720846, 6.2413603],
          [99.7201073, 6.241451],
          [99.7190333, 6.2423746],
          [99.7183896, 6.2421981],
          [99.7175613, 6.2428156],
          [99.7171086, 6.243936],
          [99.7192061, 6.2441189],
          [99.7171488, 6.2449113],
        ],
      ],
      [
        [
          [99.743669129809817, 6.251783377223328],
          [99.7436371, 6.2518308],
          [99.7435831, 6.2517366],
          [99.743553, 6.251803],
          [99.743539339116808, 6.251778131887392],
          [99.7433823, 6.2519366],
          [99.7434052, 6.2522335],
          [99.7435629, 6.2524728],
          [99.7436079, 6.2528169],
          [99.7439467, 6.253047],
          [99.744226019528043, 6.253034418039277],
          [99.7442692, 6.2530255],
          [99.744278221185482, 6.253032066613267],
          [99.7443019, 6.253031],
          [99.74397, 6.252005],
          [99.743669129809817, 6.251783377223328],
        ],
      ],
      [
        [
          [99.770492602636466, 6.256209287405732],
          [99.7703602, 6.2562196],
          [99.7701689, 6.2564231],
          [99.770165301831256, 6.25679782357458],
          [99.7702118, 6.2569139],
          [99.7702079, 6.2571614],
          [99.7704706, 6.2574391],
          [99.7707483, 6.2577961],
          [99.770905517578001, 6.257915101245999],
          [99.7714056, 6.2582052],
          [99.7719179, 6.2580461],
          [99.7721443, 6.25792],
          [99.772170110128414, 6.257930329127105],
          [99.7722791, 6.2578803],
          [99.772194442906994, 6.25737558370313],
          [99.772068, 6.2570882],
          [99.771943902377117, 6.256962578772165],
          [99.7712929, 6.2565301],
          [99.770492602636466, 6.256209287405732],
        ],
      ],
      [
        [
          [99.883608, 6.2645162],
          [99.883141, 6.2649762],
          [99.8831808, 6.2657479],
          [99.88346, 6.2661702],
          [99.88355, 6.2667138],
          [99.8835592, 6.2671651],
          [99.883663716632853, 6.267366934415978],
          [99.884331343164618, 6.268034118701151],
          [99.8848452, 6.2684952],
          [99.8853154, 6.2689536],
          [99.8862514, 6.2688645],
          [99.8866077, 6.2689225],
          [99.8870544, 6.2692137],
          [99.887087, 6.2688645],
          [99.8868458, 6.2681316],
          [99.886489, 6.2672927],
          [99.8861801, 6.2665206],
          [99.8859145, 6.2662695],
          [99.8852409, 6.2656623],
          [99.884742, 6.265149],
          [99.8842638, 6.2648287],
          [99.8839258, 6.2644922],
          [99.883608, 6.2645162],
        ],
      ],
      [
        [
          [99.8945467, 6.2748221],
          [99.8944986, 6.2750202],
          [99.894741, 6.2751624],
          [99.8947482, 6.2754596],
          [99.8950574, 6.2755279],
          [99.8948467, 6.2756872],
          [99.8948719, 6.275801],
          [99.8950276, 6.2758534],
          [99.8950023, 6.2761126],
          [99.8952695, 6.2762108],
          [99.8955716, 6.2764072],
          [99.8960115, 6.2761152],
          [99.8960711, 6.2759422],
          [99.8964175, 6.2757853],
          [99.896378, 6.275537],
          [99.8959156, 6.2753567],
          [99.8951795, 6.2749742],
          [99.8948341, 6.2749524],
          [99.8947711, 6.2748403],
          [99.8945467, 6.2748221],
        ],
      ],
      [
        [
          [99.878711842498092, 6.270382704039762],
          [99.878688, 6.2706185],
          [99.878718676022061, 6.270715459902547],
          [99.8789198, 6.2708799],
          [99.878964457951454, 6.270923345279814],
          [99.8790241, 6.2709299],
          [99.879031821324958, 6.270926101231905],
          [99.8791076, 6.2708257],
          [99.8791088, 6.2706556],
          [99.8791072, 6.2705699],
          [99.8792099, 6.2704193],
          [99.87924710168393, 6.27041066306337],
          [99.8792752, 6.2703609],
          [99.8795427, 6.2703335],
          [99.8798628, 6.2709002],
          [99.8800924, 6.2709619],
          [99.8801611, 6.2708298],
          [99.8801419, 6.2705701],
          [99.8800237, 6.270288],
          [99.879774, 6.2701812],
          [99.8796344, 6.2699693],
          [99.8794969, 6.2699192],
          [99.8792669, 6.2696798],
          [99.879228486467142, 6.269690296401682],
          [99.879129, 6.2698506],
          [99.8791422, 6.2700867],
          [99.879128, 6.2701577],
          [99.8790289, 6.2701638],
          [99.878998594084194, 6.27014454934081],
          [99.8789702, 6.2701833],
          [99.878881244745429, 6.270163316389994],
          [99.878781944111168, 6.270243350873587],
          [99.878763544517042, 6.270279926946302],
          [99.8787555, 6.2703153],
          [99.878747714312979, 6.270311395395631],
          [99.878711842498092, 6.270382704039762],
        ],
      ],
      [
        [
          [99.8736904, 6.2731967],
          [99.8735633, 6.2733388],
          [99.8736831, 6.2736077],
          [99.8738716, 6.2737394],
          [99.8741125, 6.2738334],
          [99.8743795, 6.2736975],
          [99.8747702, 6.2734701],
          [99.8747524, 6.2732168],
          [99.8744174, 6.2730482],
          [99.8742016, 6.273142],
          [99.8740596, 6.2731094],
          [99.8736904, 6.2731967],
        ],
      ],
      [
        [
          [99.7681182, 6.2171462],
          [99.7680115, 6.2176234],
          [99.7684359, 6.2182883],
          [99.7681883, 6.218587],
          [99.7682178, 6.2191595],
          [99.7688116, 6.2198352],
          [99.7696268, 6.2198845],
          [99.7701087, 6.2210694],
          [99.7705711, 6.2213909],
          [99.7708632, 6.2220342],
          [99.7711782, 6.222148],
          [99.7716252, 6.2220187],
          [99.7724299, 6.2222957],
          [99.7726542, 6.2218173],
          [99.7725279, 6.2213072],
          [99.773393, 6.2212807],
          [99.7736467, 6.2211404],
          [99.7736373, 6.2218068],
          [99.7736241, 6.2224617],
          [99.773532, 6.223266],
          [99.772049, 6.226038],
          [99.7721053, 6.2268793],
          [99.7717248, 6.228011],
          [99.7713982, 6.2282552],
          [99.7715145, 6.2295829],
          [99.7710859, 6.230112],
          [99.7711339, 6.2303326],
          [99.7715252, 6.2303293],
          [99.7718763, 6.2298107],
          [99.7728604, 6.2292067],
          [99.7736505, 6.2295378],
          [99.7739901, 6.2296876],
          [99.7743086, 6.2297966],
          [99.7748112, 6.2293605],
          [99.7755606, 6.2292016],
          [99.7767012, 6.228789],
          [99.7769926, 6.2289454],
          [99.777048, 6.229379],
          [99.7768791, 6.229885],
          [99.7768268, 6.2304793],
          [99.7769998, 6.230775],
          [99.7778871, 6.2310045],
          [99.779268, 6.2303061],
          [99.7800611, 6.2296737],
          [99.7802015, 6.2293061],
          [99.7793705, 6.2284504],
          [99.7788585, 6.2282892],
          [99.7786146, 6.2278827],
          [99.7791103, 6.2274028],
          [99.7798313, 6.2271043],
          [99.7811948, 6.2271565],
          [99.782488, 6.2275985],
          [99.7827213, 6.2288216],
          [99.7822916, 6.2291792],
          [99.7816473, 6.2302833],
          [99.7815156, 6.2308463],
          [99.7819861, 6.231222],
          [99.782049, 6.232323],
          [99.781376, 6.23351],
          [99.782026, 6.234838],
          [99.783219, 6.234581],
          [99.78638, 6.236322],
          [99.788095, 6.236862],
          [99.788743, 6.238322],
          [99.791772, 6.23993],
          [99.7915, 6.240723],
          [99.789911, 6.240845],
          [99.788971, 6.242164],
          [99.789856, 6.246539],
          [99.791422, 6.248932],
          [99.791387, 6.252639],
          [99.791897, 6.254628],
          [99.793219, 6.254901],
          [99.794283, 6.254378],
          [99.795062, 6.255972],
          [99.796087, 6.259553],
          [99.797016, 6.259294],
          [99.797137, 6.260486],
          [99.796306, 6.264321],
          [99.796682, 6.266574],
          [99.797862, 6.267773],
          [99.799315, 6.268047],
          [99.799433, 6.269637],
          [99.798362, 6.270822],
          [99.799673, 6.272154],
          [99.80204, 6.27389],
          [99.803753, 6.274695],
          [99.80456, 6.273376],
          [99.805321793365081, 6.273266515551831],
          [99.8054827, 6.27312],
          [99.8068248, 6.2727817],
          [99.809586, 6.273804],
          [99.810898, 6.275136],
          [99.812650352289637, 6.276238275888309],
          [99.8139185, 6.2764687],
          [99.814370717787483, 6.277087800902112],
          [99.815905, 6.27755],
          [99.818090512602566, 6.278160890269635],
          [99.8183198, 6.2782123],
          [99.81881, 6.278362],
          [99.8215761, 6.2778051],
          [99.8229216, 6.2786625],
          [99.8239238, 6.2795838],
          [99.824024709343334, 6.279888852449967],
          [99.824353, 6.280117],
          [99.824857, 6.282901],
          [99.825767, 6.284628],
          [99.8264527, 6.2859641],
          [99.826573, 6.2867736],
          [99.826032001577573, 6.287709840560841],
          [99.825861, 6.288733],
          [99.827034, 6.290594],
          [99.828873, 6.292194],
          [99.830481066456471, 6.29254316184015],
          [99.831898, 6.2919712],
          [99.8328241, 6.2925621],
          [99.832848406158675, 6.292860833186448],
          [99.833103, 6.292882],
          [99.833747, 6.29474],
          [99.8344, 6.295671],
          [99.8347184, 6.2955329],
          [99.8345986, 6.2950586],
          [99.8350966, 6.2946722],
          [99.8366975, 6.2943297],
          [99.8368177, 6.2941151],
          [99.8375807, 6.2942953],
          [99.838644, 6.294902],
          [99.841689, 6.29492],
          [99.842897, 6.293074],
          [99.841717, 6.291875],
          [99.840129, 6.291866],
          [99.839744, 6.290539],
          [99.840564, 6.287763],
          [99.841115, 6.285516],
          [99.840617, 6.282203],
          [99.839057, 6.279148],
          [99.840532, 6.27717],
          [99.840943, 6.275716],
          [99.837908, 6.274639],
          [99.835127, 6.274754],
          [99.834218, 6.272895],
          [99.833291, 6.272889],
          [99.831063, 6.270492],
          [99.829891, 6.268499],
          [99.829093, 6.268891],
          [99.827627, 6.269942],
          [99.825644, 6.269665],
          [99.824337, 6.267803],
          [99.824253, 6.2665577],
          [99.8239902, 6.2663844],
          [99.8237085, 6.2664884],
          [99.8234725, 6.2659071],
          [99.823494, 6.2642994],
          [99.8233303, 6.2641234],
          [99.8233169, 6.2635982],
          [99.8236307, 6.2623798],
          [99.8237702, 6.2621131],
          [99.8236, 6.26184],
          [99.8235261, 6.2611933],
          [99.8231506, 6.2605161],
          [99.8231614, 6.2599828],
          [99.8229092, 6.259183],
          [99.8230004, 6.2583564],
          [99.823645, 6.257074],
          [99.825017, 6.251918],
          [99.8250765, 6.2514615],
          [99.8248002, 6.2510163],
          [99.824709, 6.2510669],
          [99.8246017, 6.2511469],
          [99.8245132, 6.2510483],
          [99.8246795, 6.2509096],
          [99.8249638, 6.2508563],
          [99.8248136, 6.2500564],
          [99.8246822, 6.2496831],
          [99.8241699, 6.2490166],
          [99.821754, 6.246999],
          [99.819404, 6.243542],
          [99.8184701, 6.2412801],
          [99.8180542, 6.2402729],
          [99.8179306, 6.2387646],
          [99.817734, 6.2359818],
          [99.8176842, 6.2355049],
          [99.8176653, 6.2353238],
          [99.8180556, 6.2325518],
          [99.818598, 6.2303768],
          [99.8187034, 6.2298622],
          [99.8189741, 6.2285397],
          [99.8190043, 6.2283923],
          [99.821318, 6.2251295],
          [99.8231289, 6.2230746],
          [99.8246332, 6.2211974],
          [99.8252128, 6.2200354],
          [99.8256477, 6.218721],
          [99.8251958, 6.217583],
          [99.8250421, 6.2168682],
          [99.8250736, 6.2167463],
          [99.8252621, 6.2160173],
          [99.8252551, 6.2160145],
          [99.8261214, 6.2150567],
          [99.8262922, 6.2148679],
          [99.8290605, 6.2150693],
          [99.8296701, 6.21548],
          [99.8299221, 6.2161194],
          [99.8308244, 6.2170307],
          [99.8315138, 6.2179844],
          [99.8314108, 6.2185035],
          [99.8315455, 6.2190167],
          [99.8315418, 6.2194712],
          [99.8318701, 6.2201965],
          [99.8319328, 6.2205991],
          [99.8321061, 6.2209002],
          [99.8325719, 6.2207743],
          [99.8329927, 6.2207426],
          [99.8332552, 6.2208993],
          [99.8336371, 6.2209495],
          [99.8337976, 6.220661],
          [99.8337949, 6.2202871],
          [99.8341131, 6.2198781],
          [99.8343155, 6.2191208],
          [99.8346712, 6.2185837],
          [99.8348036, 6.2176618],
          [99.8350172, 6.2175281],
          [99.8353932, 6.2175315],
          [99.836164, 6.2171113],
          [99.8365006, 6.2164202],
          [99.8365429, 6.2161248],
          [99.836332, 6.215598],
          [99.8359764, 6.215613],
          [99.8357081, 6.2152706],
          [99.8355627, 6.215397],
          [99.8353578, 6.215735],
          [99.8346074, 6.2160048],
          [99.834486, 6.216358],
          [99.8342896, 6.2170889],
          [99.8338208, 6.2171765],
          [99.8332407, 6.2168268],
          [99.8324722, 6.2161104],
          [99.8320359, 6.21598],
          [99.8321958, 6.2155956],
          [99.8321408, 6.2152839],
          [99.8322692, 6.2148975],
          [99.8322025, 6.2145481],
          [99.8323927, 6.2138831],
          [99.8322081, 6.2132923],
          [99.832066, 6.2129131],
          [99.8317137, 6.2128149],
          [99.8315371, 6.2124494],
          [99.8317289, 6.2121924],
          [99.831891, 6.210781],
          [99.8317907, 6.210553],
          [99.8318436, 6.2102404],
          [99.8314996, 6.2095616],
          [99.8311216, 6.2096108],
          [99.8307232, 6.2101663],
          [99.8307609, 6.2106678],
          [99.8300187, 6.2108327],
          [99.827261, 6.210488],
          [99.826768, 6.2104013],
          [99.8266326, 6.2101623],
          [99.8269408, 6.2095995],
          [99.8277617, 6.208728],
          [99.8276274, 6.2075768],
          [99.8272631, 6.2071367],
          [99.8274714, 6.2068829],
          [99.8275301, 6.2063585],
          [99.8280547, 6.2061062],
          [99.8281545, 6.2058318],
          [99.8281692, 6.2052104],
          [99.8284539, 6.2048818],
          [99.8288919, 6.2049551],
          [99.8289467, 6.2045712],
          [99.8288008, 6.2043454],
          [99.8285942, 6.2043677],
          [99.8285385, 6.2045817],
          [99.8275923, 6.2041738],
          [99.8276679, 6.2036306],
          [99.8278531, 6.2030689],
          [99.828377, 6.2031476],
          [99.8295307, 6.2029365],
          [99.8298376, 6.2025685],
          [99.8298551, 6.202192],
          [99.8299751, 6.2017947],
          [99.8304025, 6.201711],
          [99.830525, 6.201239],
          [99.8302368, 6.2007502],
          [99.8296419, 6.2005154],
          [99.8294061, 6.2000457],
          [99.8287421, 6.1998571],
          [99.8284272, 6.1995669],
          [99.8283594, 6.1985802],
          [99.8281388, 6.1984862],
          [99.8285321, 6.1975532],
          [99.8285181, 6.1967538],
          [99.8281836, 6.1960862],
          [99.8283662, 6.1956485],
          [99.8283227, 6.1951117],
          [99.8279995, 6.1950155],
          [99.8268928, 6.1945045],
          [99.8269445, 6.1940581],
          [99.8265541, 6.1929621],
          [99.8263289, 6.1929812],
          [99.8261804, 6.1927125],
          [99.8264454, 6.1918871],
          [99.8259705, 6.191354],
          [99.8256053, 6.189723],
          [99.8251719, 6.1897513],
          [99.8247014, 6.1898596],
          [99.8243841, 6.1900357],
          [99.8241583, 6.1898968],
          [99.8241495, 6.1895682],
          [99.8239617, 6.1895036],
          [99.8236541, 6.1896793],
          [99.8231069, 6.1892179],
          [99.8224725, 6.1890739],
          [99.8219701, 6.1892093],
          [99.8216768, 6.1888257],
          [99.8218859, 6.1883152],
          [99.821793, 6.1879036],
          [99.8214031, 6.1876054],
          [99.8209989, 6.1871458],
          [99.8205352, 6.1871264],
          [99.8207488, 6.1867659],
          [99.8207994, 6.1862879],
          [99.8214375, 6.1857422],
          [99.8212321, 6.1853788],
          [99.8215025, 6.1847874],
          [99.8216516, 6.1843498],
          [99.8213877, 6.1842363],
          [99.8215923, 6.18362],
          [99.8212707, 6.1797387],
          [99.8207356, 6.1796539],
          [99.820524, 6.1794505],
          [99.8194268, 6.1796356],
          [99.8190022, 6.1794482],
          [99.818596, 6.179649],
          [99.8182008, 6.1798475],
          [99.8178621, 6.1804207],
          [99.817335, 6.1810545],
          [99.8170971, 6.1808253],
          [99.8170803, 6.1805245],
          [99.8172849, 6.180376],
          [99.8173094, 6.178662],
          [99.816199, 6.176524],
          [99.8160917, 6.1759431],
          [99.8158823, 6.1756331],
          [99.8155315, 6.1754872],
          [99.8151365, 6.1758389],
          [99.8149085, 6.1757972],
          [99.8146619, 6.1757437],
          [99.8143385, 6.1755123],
          [99.8142028, 6.1741184],
          [99.8141188, 6.1736842],
          [99.8138384, 6.1734841],
          [99.8132174, 6.1735211],
          [99.8130227, 6.1732648],
          [99.8125313, 6.1730909],
          [99.811855, 6.1732172],
          [99.8116573, 6.1730634],
          [99.8115106, 6.1725456],
          [99.8115068, 6.1718656],
          [99.8118417, 6.1712554],
          [99.8133943, 6.1710262],
          [99.8135925, 6.1715211],
          [99.8137395, 6.171525],
          [99.8139825, 6.171211],
          [99.8141789, 6.1704307],
          [99.8152701, 6.1706987],
          [99.815209, 6.169235],
          [99.8152135, 6.1688553],
          [99.8146731, 6.1678491],
          [99.8137454, 6.1679024],
          [99.81344, 6.1681784],
          [99.8134848, 6.1694875],
          [99.8131496, 6.1700267],
          [99.8116223, 6.1703205],
          [99.8111735, 6.1707894],
          [99.8107482, 6.1706452],
          [99.8102148, 6.170044],
          [99.8098509, 6.1699436],
          [99.8095016, 6.1701998],
          [99.8094975, 6.1704478],
          [99.8093916, 6.1708214],
          [99.8091409, 6.1710341],
          [99.808878, 6.1715103],
          [99.8088462, 6.1718023],
          [99.808561, 6.172505],
          [99.8076052, 6.1739989],
          [99.8070215, 6.1738621],
          [99.8062675, 6.1735729],
          [99.8059857, 6.1733332],
          [99.8056008, 6.1727141],
          [99.8055224, 6.172222],
          [99.8058255, 6.1720506],
          [99.8061082, 6.1719616],
          [99.8058221, 6.1716651],
          [99.8051318, 6.1713466],
          [99.8043904, 6.171172],
          [99.8047873, 6.1725417],
          [99.8048778, 6.1728701],
          [99.8047816, 6.1734664],
          [99.8045461, 6.1738111],
          [99.8045221, 6.1741867],
          [99.8041098, 6.1743745],
          [99.8037334, 6.175061],
          [99.8036976, 6.1760099],
          [99.8035519, 6.176872],
          [99.804666, 6.178439],
          [99.8044667, 6.1794589],
          [99.80397, 6.1798069],
          [99.8033692, 6.1803049],
          [99.8026874, 6.1810313],
          [99.8028528, 6.1829678],
          [99.8025045, 6.1847297],
          [99.8030245, 6.1852162],
          [99.803274, 6.185713],
          [99.8033747, 6.1862449],
          [99.8030428, 6.1877444],
          [99.8025141, 6.1881632],
          [99.8020517, 6.1886513],
          [99.8005693, 6.1887805],
          [99.7985392, 6.188812],
          [99.7975906, 6.1886284],
          [99.7973692, 6.1884624],
          [99.7973099, 6.187275],
          [99.7971103, 6.1865934],
          [99.7968751, 6.1862896],
          [99.7967886, 6.1853944],
          [99.7962911, 6.184733],
          [99.7958188, 6.1836855],
          [99.7954951, 6.1826379],
          [99.7952532, 6.1816717],
          [99.7946073, 6.1808949],
          [99.7942344, 6.1802784],
          [99.7937532, 6.1796217],
          [99.7928721, 6.1793026],
          [99.7925427, 6.1793096],
          [99.7922353, 6.179189],
          [99.7922152, 6.1790287],
          [99.7924996, 6.1786542],
          [99.7924465, 6.1779321],
          [99.7922237, 6.1779726],
          [99.7915412, 6.1784969],
          [99.7912847, 6.1790886],
          [99.7910016, 6.1789726],
          [99.7905562, 6.1791792],
          [99.7906388, 6.1796729],
          [99.7911554, 6.1802034],
          [99.7909989, 6.1805387],
          [99.7900913, 6.1814243],
          [99.7889503, 6.1808558],
          [99.7888231, 6.1799379],
          [99.7889849, 6.1797366],
          [99.7901335, 6.1790381],
          [99.7898624, 6.1788506],
          [99.7895905, 6.1776693],
          [99.7882344, 6.1771203],
          [99.7876757, 6.1765848],
          [99.7873696, 6.1765083],
          [99.7872587, 6.1766666],
          [99.7871884, 6.1770937],
          [99.7876695, 6.1779211],
          [99.7876586, 6.1785813],
          [99.7873394, 6.1791761],
          [99.7872416, 6.1799624],
          [99.7865994, 6.180747],
          [99.78616, 6.1834667],
          [99.7868221, 6.1845789],
          [99.787398, 6.184953],
          [99.7885923, 6.1852906],
          [99.7889485, 6.1861389],
          [99.7886506, 6.1877176],
          [99.7884095, 6.1874307],
          [99.7881967, 6.1885377],
          [99.7885614, 6.1891401],
          [99.7886374, 6.189783],
          [99.7887623, 6.1902522],
          [99.788631, 6.1912269],
          [99.788105, 6.1918482],
          [99.7872324, 6.1926636],
          [99.785453, 6.194607],
          [99.785555, 6.197786],
          [99.784616, 6.199104],
          [99.783426, 6.198964],
          [99.782611, 6.20121],
          [99.7825766, 6.2024375],
          [99.7827491, 6.2030094],
          [99.7827556, 6.2035963],
          [99.7819831, 6.2044446],
          [99.7818626, 6.2048447],
          [99.7814053, 6.2044901],
          [99.7810158, 6.2047287],
          [99.7810356, 6.2051147],
          [99.781506, 6.20597],
          [99.782013, 6.208356],
          [99.781868, 6.20968],
          [99.780556, 6.2095423],
          [99.7794842, 6.2071571],
          [99.7787778, 6.2047974],
          [99.777166, 6.20303],
          [99.7761508, 6.202223],
          [99.77373, 6.202479],
          [99.773056, 6.203799],
          [99.774368, 6.204999],
          [99.776351, 6.205276],
          [99.77448, 6.207118],
          [99.773294, 6.206581],
          [99.772095, 6.207368],
          [99.772733, 6.209888],
          [99.773385, 6.210951],
          [99.772185, 6.211871],
          [99.772705, 6.212801],
          [99.773885, 6.214],
          [99.774794, 6.215859],
          [99.7740062, 6.2153689],
          [99.7729148, 6.2148607],
          [99.7724705, 6.2148267],
          [99.772161, 6.214386],
          [99.7717643, 6.2141263],
          [99.7714356, 6.2146454],
          [99.7714937, 6.215108],
          [99.7712773, 6.2158588],
          [99.770553, 6.216362],
          [99.7695711, 6.2153951],
          [99.7692982, 6.2153887],
          [99.7687869, 6.2160595],
          [99.7681182, 6.2171462],
        ],
      ],
      [
        [
          [99.740021102599826, 6.259846144599238],
          [99.7400225, 6.2598522],
          [99.740018963936961, 6.259850510199967],
          [99.739988038251639, 6.259913638052932],
          [99.74005, 6.2599422],
          [99.740164010328897, 6.259961928452205],
          [99.7403505, 6.2599457],
          [99.740480142874262, 6.260009991713556],
          [99.7405094, 6.2600116],
          [99.7405806, 6.2600351],
          [99.740615025582926, 6.260046589019844],
          [99.740777843449877, 6.259973323685236],
          [99.7408768, 6.2598511],
          [99.7408839, 6.2597577],
          [99.7410023, 6.2595993],
          [99.7410568, 6.2596209],
          [99.7411159, 6.2595908],
          [99.7412295, 6.2595964],
          [99.741233303756033, 6.259594093417314],
          [99.741322519677297, 6.259447498215181],
          [99.7413138, 6.2594371],
          [99.7413779, 6.2593565],
          [99.7416648, 6.259126],
          [99.7417266, 6.2590774],
          [99.74192803349851, 6.25891844269574],
          [99.7419449, 6.2588933],
          [99.741976333042061, 6.258894084075883],
          [99.742087, 6.2588839],
          [99.7421694, 6.2588236],
          [99.742211463912568, 6.258786105330333],
          [99.7422278, 6.2587423],
          [99.742304308689995, 6.258730130036026],
          [99.7424589, 6.2586933],
          [99.742540051357821, 6.25867207841061],
          [99.7427533, 6.2585083],
          [99.742773145139296, 6.258396044363407],
          [99.7427473, 6.2584012],
          [99.7427224, 6.2582989],
          [99.7427532, 6.2581883],
          [99.7428099, 6.2581132],
          [99.742835802046443, 6.258117670403955],
          [99.7430197, 6.2579301],
          [99.7431855, 6.257727],
          [99.7433023, 6.2573184],
          [99.7431038, 6.2570448],
          [99.7429371, 6.2573156],
          [99.742771190454761, 6.257252146166616],
          [99.742766, 6.2572596],
          [99.742573513843453, 6.257214729373848],
          [99.742551407694037, 6.257230265916816],
          [99.7426357, 6.2573191],
          [99.7427589, 6.2574004],
          [99.7429092, 6.257474],
          [99.7428795, 6.2574162],
          [99.7429301, 6.2573825],
          [99.7429421, 6.2574315],
          [99.7429522, 6.2575158],
          [99.7429242, 6.2575817],
          [99.742834, 6.257611],
          [99.7427773, 6.2575193],
          [99.7426569, 6.2574726],
          [99.7425332, 6.2574338],
          [99.742404099524109, 6.257333796372004],
          [99.742377961983536, 6.257352166238898],
          [99.7423756, 6.2573586],
          [99.742370534263941, 6.257357386554856],
          [99.742321, 6.2573922],
          [99.742303519842963, 6.25741789430525],
          [99.7423221, 6.2574227],
          [99.7424001, 6.2574708],
          [99.7423909, 6.2575644],
          [99.742447, 6.2575863],
          [99.7423941, 6.2576641],
          [99.7423228, 6.2576489],
          [99.7423039, 6.2577347],
          [99.7422456, 6.2576465],
          [99.7421771, 6.2576069],
          [99.742175653248751, 6.257605847039033],
          [99.742147746909666, 6.257646866921942],
          [99.7421674, 6.2576741],
          [99.7421651, 6.257744],
          [99.742115199365244, 6.257694708971609],
          [99.7421033, 6.2577122],
          [99.742321, 6.2579502],
          [99.742138168661413, 6.257993677320639],
          [99.7421859, 6.2580087],
          [99.742107201970086, 6.258001041204635],
          [99.7420628, 6.2580116],
          [99.742009974924301, 6.258059447891212],
          [99.7420251, 6.2580664],
          [99.74208, 6.2580595],
          [99.7421568, 6.258141],
          [99.7421476, 6.2582225],
          [99.7419917, 6.2581531],
          [99.7419619, 6.2581989],
          [99.7419779, 6.2582631],
          [99.7419756, 6.2583095],
          [99.7419227, 6.2582861],
          [99.7419133, 6.2581846],
          [99.741884439053379, 6.258173155758466],
          [99.741835793569436, 6.258217217859515],
          [99.741824, 6.2582643],
          [99.741823741995859, 6.258275248637131],
          [99.7419056, 6.2584859],
          [99.741653, 6.258737],
          [99.7415351, 6.2587203],
          [99.7413618, 6.2586166],
          [99.7409625, 6.2588766],
          [99.740946136733371, 6.25900657441676],
          [99.7409852, 6.2590212],
          [99.7409889, 6.2591328],
          [99.7409189, 6.2591277],
          [99.740879423220122, 6.259133065395677],
          [99.740820965666543, 6.259168436940805],
          [99.740825, 6.2592065],
          [99.740792949574328, 6.259185388941173],
          [99.7407179, 6.2592308],
          [99.7408278, 6.2595685],
          [99.7406986, 6.2597024],
          [99.740619228461114, 6.259645414298402],
          [99.7405924, 6.2596485],
          [99.74058812922398, 6.259623086246408],
          [99.7404965, 6.2595573],
          [99.740425409031488, 6.259617622996872],
          [99.7404149, 6.2596561],
          [99.7403854, 6.2596608],
          [99.740384698401584, 6.259652167289215],
          [99.7403057, 6.2597192],
          [99.7402902, 6.2598518],
          [99.7401976, 6.2598742],
          [99.740115921957454, 6.259809206783248],
          [99.7401289, 6.2598365],
          [99.740088642850424, 6.259812279169508],
          [99.740032, 6.2598239],
          [99.740021102599826, 6.259846144599238],
        ],
      ],
      [
        [
          [99.8667001, 6.2744528],
          [99.8664223, 6.2746553],
          [99.8665426, 6.2750977],
          [99.8665622, 6.2755339],
          [99.8669099, 6.2756579],
          [99.8674198, 6.2761716],
          [99.8677521, 6.2763188],
          [99.867755101469143, 6.276322411054939],
          [99.8683636, 6.2765961],
          [99.8688342, 6.2773163],
          [99.8693027, 6.2776406],
          [99.8694679, 6.2780902],
          [99.8697413, 6.2785666],
          [99.869785681235427, 6.278587864150755],
          [99.8699015, 6.2786289],
          [99.869923118533222, 6.278653713769208],
          [99.8701892, 6.2787812],
          [99.870502747827786, 6.279453330980436],
          [99.8706071, 6.2796211],
          [99.8707653, 6.2797337],
          [99.870804243070737, 6.279741197061747],
          [99.8712471, 6.279393],
          [99.8714147, 6.2790709],
          [99.8717446, 6.278937],
          [99.8716149, 6.2785075],
          [99.8719014, 6.2785749],
          [99.8724584, 6.2782526],
          [99.8734375, 6.2773881],
          [99.8737634, 6.2764733],
          [99.8737432, 6.2760536],
          [99.873448, 6.27577],
          [99.8730413, 6.275543],
          [99.872683, 6.2754979],
          [99.872587, 6.2754231],
          [99.872592828072854, 6.275258005653069],
          [99.8725125, 6.2750443],
          [99.8723419, 6.274846],
          [99.8719995, 6.27475],
          [99.871597538494626, 6.274816344381006],
          [99.8713916, 6.2749494],
          [99.8711927, 6.2753327],
          [99.8709081, 6.275365],
          [99.870792098903905, 6.275276208569701],
          [99.8704786, 6.275232],
          [99.8702703, 6.2745905],
          [99.869986308091242, 6.274364548614119],
          [99.8698057, 6.2743329],
          [99.8695458, 6.2743395],
          [99.869394084361403, 6.274410819796133],
          [99.8691437, 6.2746795],
          [99.868816, 6.2750973],
          [99.8685573, 6.2752317],
          [99.8680359, 6.2750839],
          [99.8677984, 6.2749093],
          [99.8675343, 6.2745239],
          [99.867108, 6.2743535],
          [99.8668428, 6.2741158],
          [99.8667001, 6.2744528],
        ],
      ],
      [
        [
          [99.823002, 6.227013],
          [99.820814, 6.234415],
          [99.8215789, 6.2395486],
          [99.8217237, 6.2402631],
          [99.8222172, 6.2424175],
          [99.8230112, 6.2444866],
          [99.823451, 6.2445026],
          [99.8233974, 6.2446572],
          [99.8230916, 6.2448972],
          [99.8235315, 6.2455371],
          [99.8253544, 6.2468222],
          [99.8256167, 6.2470294],
          [99.8257327, 6.2471168],
          [99.8257953, 6.2473182],
          [99.8262191, 6.2477234],
          [99.8266536, 6.2477181],
          [99.8268601, 6.2476781],
          [99.8269406, 6.2478434],
          [99.8269299, 6.2479714],
          [99.827123, 6.2482087],
          [99.8270345, 6.2484487],
          [99.8274636, 6.2486433],
          [99.8276514, 6.2485766],
          [99.828481, 6.249556],
          [99.828983, 6.252472],
          [99.830544, 6.255395],
          [99.8308944, 6.2566386],
          [99.831039, 6.2570393],
          [99.8316452, 6.2583831],
          [99.8318464, 6.258879],
          [99.8317391, 6.2590817],
          [99.8321629, 6.2603188],
          [99.8325705, 6.2610547],
          [99.83293, 6.2612946],
          [99.8334235, 6.2616252],
          [99.8345393, 6.2621691],
          [99.8356068, 6.2628143],
          [99.8362237, 6.2631769],
          [99.837082, 6.2634009],
          [99.8374602, 6.2634276],
          [99.8376828, 6.2634276],
          [99.8379484, 6.2635635],
          [99.8382997, 6.2638488],
          [99.8384875, 6.2639261],
          [99.8390454, 6.2640461],
          [99.8391312, 6.2641101],
          [99.8393539, 6.2643767],
          [99.8398903, 6.2646967],
          [99.8401237, 6.2648913],
          [99.8402578, 6.2651179],
          [99.840593, 6.2650966],
          [99.840695, 6.2651766],
          [99.8409632, 6.2655712],
          [99.841505, 6.2657232],
          [99.8418188, 6.2659045],
          [99.8418269, 6.2661551],
          [99.8420683, 6.266531],
          [99.8423204, 6.2668616],
          [99.8423338, 6.2670563],
          [99.8424679, 6.2673229],
          [99.8425832, 6.2673549],
          [99.8428032, 6.2672829],
          [99.8437151, 6.2677708],
          [99.844322, 6.268322],
          [99.8445144, 6.2686773],
          [99.8447961, 6.2688026],
          [99.8451608, 6.2687973],
          [99.8455551, 6.2689652],
          [99.8456664, 6.2689566],
          [99.845767, 6.2689972],
          [99.8458287, 6.2690026],
          [99.8458609, 6.2689626],
          [99.8458656, 6.2688899],
          [99.8458448, 6.2687573],
          [99.8459668, 6.2681187],
          [99.846101, 6.2679068],
          [99.8461714, 6.2678148],
          [99.8463276, 6.2677081],
          [99.8464589, 6.2676331],
          [99.8465636, 6.2676455],
          [99.846691, 6.2677255],
          [99.8467661, 6.2678121],
          [99.8467715, 6.2679148],
          [99.8467836, 6.2679334],
          [99.8467863, 6.2679881],
          [99.8468198, 6.2679947],
          [99.8468198, 6.2679201],
          [99.8468828, 6.2678788],
          [99.8469847, 6.2678841],
          [99.8470102, 6.2677948],
          [99.8468761, 6.2676908],
          [99.8465972, 6.2675828],
          [99.8464952, 6.2675202],
          [99.846451, 6.2674842],
          [99.8464765, 6.2672509],
          [99.846561, 6.2669949],
          [99.8467205, 6.2666417],
          [99.8476994, 6.2664587],
          [99.8481889, 6.2662364],
          [99.848384, 6.265765],
          [99.8483822, 6.2655659],
          [99.8484331, 6.2653712],
          [99.8484626, 6.2651393],
          [99.8485377, 6.264934],
          [99.8485646, 6.2647313],
          [99.8487014, 6.2642834],
          [99.8487684, 6.2638862],
          [99.8488328, 6.2636089],
          [99.8489773, 6.2633934],
          [99.8491278, 6.263129],
          [99.8493826, 6.2627477],
          [99.8497528, 6.2623184],
          [99.8498735, 6.2623558],
          [99.8500532, 6.2623104],
          [99.850257, 6.2624971],
          [99.8505038, 6.2625957],
          [99.8507211, 6.2625264],
          [99.8515896, 6.2618912],
          [99.852917, 6.262349],
          [99.853715, 6.261957],
          [99.853728, 6.260567],
          [99.853202, 6.260166],
          [99.8528373, 6.2602575],
          [99.8524967, 6.2600975],
          [99.8522392, 6.2598575],
          [99.8519656, 6.2597535],
          [99.8518717, 6.2597295],
          [99.8518825, 6.2588577],
          [99.8520327, 6.2581938],
          [99.8520729, 6.2577885],
          [99.8521614, 6.2576632],
          [99.8524296, 6.2577485],
          [99.853157, 6.257915],
          [99.85462, 6.257196],
          [99.855478, 6.257466],
          [99.857326, 6.258006],
          [99.858265, 6.256688],
          [99.857888, 6.254567],
          [99.8580335, 6.2535125],
          [99.8579775, 6.2529018],
          [99.8575204, 6.2522019],
          [99.8573637, 6.2516726],
          [99.8576042, 6.2519989],
          [99.8580705, 6.2526821],
          [99.8585904, 6.2537386],
          [99.8591242, 6.2533815],
          [99.8591906, 6.2533365],
          [99.8595444, 6.2530999],
          [99.8598043, 6.2532721],
          [99.8600152, 6.2531457],
          [99.8602134, 6.2530073],
          [99.8603431, 6.2529324],
          [99.8600475, 6.2524876],
          [99.8603147, 6.2520032],
          [99.8611279, 6.2521862],
          [99.8613382, 6.2519764],
          [99.8613876, 6.2514724],
          [99.8611894, 6.2502939],
          [99.860446, 6.2487944],
          [99.8595953, 6.2476362],
          [99.859321, 6.2475914],
          [99.8587723, 6.2469686],
          [99.8583436, 6.2464072],
          [99.857636, 6.246091],
          [99.8570387, 6.2460606],
          [99.8567418, 6.2456462],
          [99.8568087, 6.2445644],
          [99.8559315, 6.2431094],
          [99.8552733, 6.2416213],
          [99.8557408, 6.2409632],
          [99.8560473, 6.240721],
          [99.8568749, 6.2393833],
          [99.8574215, 6.2387706],
          [99.8585917, 6.2379684],
          [99.8607053, 6.2382828],
          [99.8616064, 6.2382665],
          [99.8622501, 6.2387516],
          [99.8630226, 6.2388128],
          [99.8636878, 6.239078],
          [99.8640096, 6.2390613],
          [99.8642456, 6.2388953],
          [99.8643853, 6.2383587],
          [99.8642353, 6.2378542],
          [99.8643214, 6.2367811],
          [99.8645691, 6.2362995],
          [99.8645593, 6.2352527],
          [99.8646095, 6.2342716],
          [99.86378, 6.232544],
          [99.86343, 6.2322031],
          [99.863395, 6.2313719],
          [99.8629603, 6.230968],
          [99.8624771, 6.2299257],
          [99.8619623, 6.2296724],
          [99.8616191, 6.2290884],
          [99.8616409, 6.2282404],
          [99.8605258, 6.2272484],
          [99.859819, 6.225051],
          [99.859445, 6.222599],
          [99.857677, 6.220603],
          [99.8572099, 6.2191702],
          [99.857365, 6.218548],
          [99.8578227, 6.216395],
          [99.8576403, 6.215439],
          [99.857136, 6.214707],
          [99.856476, 6.214571],
          [99.855425, 6.213704],
          [99.854173, 6.213167],
          [99.853708, 6.213296],
          [99.853427, 6.215016],
          [99.8535474, 6.2170381],
          [99.8538825, 6.2177376],
          [99.853389, 6.21912],
          [99.8527247, 6.223929],
          [99.8527032, 6.2247236],
          [99.8526898, 6.2252249],
          [99.8526442, 6.2254355],
          [99.8524082, 6.2256195],
          [99.8519951, 6.2257102],
          [99.8506808, 6.2259608],
          [99.8495355, 6.2260195],
          [99.8473495, 6.2259235],
          [99.8461854, 6.2257475],
          [99.8451126, 6.2255715],
          [99.8425041, 6.2250895],
          [99.8414433, 6.2248116],
          [99.8411506, 6.2245932],
          [99.8409278, 6.2240576],
          [99.8408667, 6.2232427],
          [99.8404333, 6.2226209],
          [99.8399577, 6.2222384],
          [99.8397428, 6.2215532],
          [99.8396516, 6.2215746],
          [99.8395148, 6.2215079],
          [99.8392814, 6.2217612],
          [99.8387933, 6.2217399],
          [99.8384726, 6.2216857],
          [99.8381103, 6.2219134],
          [99.8378454, 6.2223689],
          [99.837533, 6.223129],
          [99.8365138, 6.2234347],
          [99.8360226, 6.2235542],
          [99.8351919, 6.2232974],
          [99.834557, 6.222846],
          [99.8343563, 6.2225337],
          [99.833832, 6.222577],
          [99.833497, 6.222972],
          [99.8332156, 6.223555],
          [99.8331725, 6.2240363],
          [99.8330184, 6.2238817],
          [99.8326079, 6.2236232],
          [99.8318721, 6.2236479],
          [99.8310647, 6.2234434],
          [99.8306511, 6.2232453],
          [99.8301505, 6.2229456],
          [99.8297522, 6.2219518],
          [99.8296707, 6.2203535],
          [99.8292467, 6.2200245],
          [99.8288226, 6.2193737],
          [99.8284296, 6.2190144],
          [99.8282324, 6.218562],
          [99.8278722, 6.218199],
          [99.8271604, 6.2183704],
          [99.8269, 6.2186519],
          [99.826856, 6.2189269],
          [99.8269895, 6.2193586],
          [99.82649, 6.222135],
          [99.823002, 6.227013],
        ],
      ],
      [
        [
          [99.712651998214881, 6.268422154727801],
          [99.7126455, 6.2684697],
          [99.712493166329921, 6.268523333789354],
          [99.712728, 6.269294],
          [99.71316, 6.2698741],
          [99.7131949, 6.2700052],
          [99.713173234910684, 6.270061332276855],
          [99.7134604, 6.2706805],
          [99.713834384883341, 6.271033272659506],
          [99.713869749290495, 6.271044441396498],
          [99.7147949, 6.2707734],
          [99.7155433, 6.2705829],
          [99.715423962570384, 6.27026714438812],
          [99.715348, 6.2701674],
          [99.7153137, 6.2699754],
          [99.7162138, 6.2688504],
          [99.717001, 6.2689531],
          [99.717008577250127, 6.268955925586957],
          [99.7172027, 6.2689817],
          [99.7176396, 6.2691741],
          [99.717703351892723, 6.269215009837032],
          [99.717824, 6.26926],
          [99.719075, 6.269997],
          [99.7200358, 6.2708764],
          [99.720052586554189, 6.270955048800422],
          [99.720187, 6.2713558],
          [99.7202806, 6.2715372],
          [99.720222615398612, 6.271757725690477],
          [99.720461188023179, 6.27314216845869],
          [99.720535013843275, 6.273196558181769],
          [99.720605203750537, 6.273165698372448],
          [99.7206179, 6.2731155],
          [99.721409, 6.2728123],
          [99.721701, 6.272146],
          [99.721765, 6.2721034],
          [99.7222272, 6.2717958],
          [99.7224687, 6.2713053],
          [99.7224757, 6.2708265],
          [99.722364, 6.2702891],
          [99.7221928, 6.2698371],
          [99.72216, 6.2694768],
          [99.7223971, 6.2686251],
          [99.721941, 6.267962],
          [99.7211922, 6.2671241],
          [99.721049, 6.267068],
          [99.7201985, 6.2667347],
          [99.7199018, 6.2665881],
          [99.7193525, 6.265694],
          [99.7190746, 6.2650591],
          [99.718745188171795, 6.264668761409143],
          [99.7186742, 6.2646883],
          [99.718602628711665, 6.264616437475496],
          [99.7182124, 6.2646972],
          [99.7180164, 6.2655883],
          [99.7176701, 6.2659675],
          [99.7168089, 6.2665696],
          [99.7160229, 6.2664945],
          [99.715487743461594, 6.266611279032324],
          [99.7154864, 6.2666122],
          [99.7152378, 6.2666695],
          [99.7152173, 6.2668471],
          [99.715124397060592, 6.266860111616164],
          [99.715100074744896, 6.26692475081384],
          [99.7151933, 6.2671349],
          [99.7150452, 6.26716],
          [99.7149547, 6.2673111],
          [99.7149071, 6.267472],
          [99.714779578180455, 6.267493192386917],
          [99.714667647153348, 6.267609578728431],
          [99.7146467, 6.2676786],
          [99.7146609, 6.2678105],
          [99.7143683, 6.2681342],
          [99.713995, 6.268309],
          [99.7136055, 6.2684552],
          [99.7131543, 6.2685254],
          [99.713091716265382, 6.26843149445371],
          [99.712651998214881, 6.268422154727801],
        ],
      ],
      [
        [
          [99.755974, 6.263244],
          [99.7558232, 6.2630262],
          [99.75554, 6.2628523],
          [99.755391797314232, 6.262704097314234],
          [99.754457, 6.262671],
          [99.753825, 6.263065],
          [99.753834900229108, 6.263240375486834],
          [99.7538823, 6.2633411],
          [99.7540161, 6.2634865],
          [99.7541342, 6.2636243],
          [99.7543448, 6.263784],
          [99.754468390597992, 6.263874301083798],
          [99.754841, 6.264064],
          [99.755055250550228, 6.264259960869111],
          [99.7550954, 6.2642841],
          [99.755341, 6.2644692],
          [99.7557616, 6.2646815],
          [99.7567934, 6.2649811],
          [99.7569451, 6.2650712],
          [99.7571409, 6.2653059],
          [99.757249828553725, 6.265389239304818],
          [99.757251102275532, 6.265389974466092],
          [99.7576278, 6.2655658],
          [99.7579179, 6.2655474],
          [99.7581612, 6.2655156],
          [99.7582898, 6.2655101],
          [99.7584632, 6.2655034],
          [99.758713, 6.2654287],
          [99.758820698936304, 6.265381096012285],
          [99.758772, 6.264817],
          [99.758248, 6.264218],
          [99.757719, 6.264181],
          [99.756595, 6.264108],
          [99.755969, 6.26374],
          [99.755974, 6.263244],
        ],
      ],
      [
        [
          [99.78188103222675, 6.264429342285138],
          [99.7818315, 6.2645028],
          [99.7817837, 6.2646508],
          [99.7816852, 6.264729],
          [99.7816092, 6.264771],
          [99.7814401, 6.265084],
          [99.7814706, 6.2651943],
          [99.781475, 6.2653896],
          [99.7816928, 6.2655877],
          [99.7818346, 6.265558],
          [99.7823518, 6.2653699],
          [99.7823992, 6.2652706],
          [99.7824191, 6.2650948],
          [99.782457069281051, 6.265087567755992],
          [99.782471922497365, 6.265030630426769],
          [99.7824618, 6.2650182],
          [99.782429, 6.26506],
          [99.7823922, 6.2650347],
          [99.782382, 6.2649669],
          [99.7823156, 6.26486],
          [99.7823852, 6.2648008],
          [99.7823511, 6.264713],
          [99.7823176, 6.2646062],
          [99.7822216, 6.2645243],
          [99.7822141, 6.2644292],
          [99.7821342, 6.264445],
          [99.7821206, 6.2644063],
          [99.78211120653188, 6.264405397978227],
          [99.78196908682753, 6.264420182242773],
          [99.7819668, 6.2644311],
          [99.781953741148754, 6.26442177860533],
          [99.78188103222675, 6.264429342285138],
        ],
      ],
      [
        [
          [99.937835320583986, 6.312071443723599],
          [99.937785, 6.3121923],
          [99.9378434, 6.3123893],
          [99.9382081, 6.3126515],
          [99.9382592, 6.3129586],
          [99.9384272, 6.3133335],
          [99.9381504, 6.3140181],
          [99.9379908, 6.3142132],
          [99.9379121, 6.3146033],
          [99.9375943, 6.3148655],
          [99.937528, 6.3154539],
          [99.937411627851219, 6.315666195797803],
          [99.937447081204809, 6.315861892682038],
          [99.9375188, 6.315929],
          [99.9383086, 6.3162021],
          [99.9386841, 6.3167534],
          [99.9389439, 6.3168415],
          [99.9390897, 6.3171564],
          [99.9391847, 6.3177673],
          [99.9387262, 6.3184257],
          [99.9389196, 6.3187245],
          [99.939397355375263, 6.318986569696143],
          [99.939665431723455, 6.318851120033231],
          [99.9397629, 6.3187853],
          [99.939789191759544, 6.318788588387742],
          [99.9397957, 6.3187853],
          [99.940418527012881, 6.318808301069764],
          [99.940802, 6.3186964],
          [99.9410603, 6.3185231],
          [99.9412314, 6.3178051],
          [99.941357285428111, 6.317732513790888],
          [99.9413627, 6.3177181],
          [99.941400972020119, 6.31770732386899],
          [99.941620866056226, 6.317580531795222],
          [99.9417531, 6.3173349],
          [99.941935732531931, 6.317309504730895],
          [99.9421154, 6.3171123],
          [99.9423625, 6.3175406],
          [99.9425938, 6.3175469],
          [99.9426649, 6.317371],
          [99.9427142, 6.3171155],
          [99.943107, 6.3167093],
          [99.94310866761785, 6.316708435785354],
          [99.9431179, 6.3166658],
          [99.943306639445382, 6.316605840245912],
          [99.9437816, 6.3163597],
          [99.9441431, 6.3162023],
          [99.944175757220236, 6.316037486075677],
          [99.9441083, 6.3159013],
          [99.944203035596431, 6.315899818013378],
          [99.9442286, 6.3157708],
          [99.944539443563869, 6.315894555449716],
          [99.9447028, 6.315892],
          [99.944795495859253, 6.315996496974803],
          [99.944894378644534, 6.31603586495595],
          [99.944990224682115, 6.315991955194888],
          [99.945033973923586, 6.31590744318713],
          [99.9450472, 6.3155422],
          [99.945201833349117, 6.315583183003439],
          [99.945439378849755, 6.315124307683027],
          [99.945414632972899, 6.315028022239273],
          [99.945257265129626, 6.314783878134581],
          [99.945095, 6.3147117],
          [99.945144722947958, 6.314609277596063],
          [99.9448833, 6.3142037],
          [99.944505868969898, 6.314279225645],
          [99.9443583, 6.3143564],
          [99.9441177, 6.3143569],
          [99.94396, 6.3140644],
          [99.9433256, 6.3138562],
          [99.9431197, 6.3140231],
          [99.9427712, 6.3139507],
          [99.942787, 6.3134689],
          [99.9428258, 6.3129127],
          [99.9426631, 6.3126914],
          [99.9425051, 6.3127068],
          [99.9421661, 6.3128548],
          [99.94217602243431, 6.312750128045812],
          [99.9421083, 6.3127355],
          [99.942202412424962, 6.312471739517064],
          [99.942263, 6.3118326],
          [99.9422864, 6.3113023],
          [99.9420568, 6.3107637],
          [99.9413898, 6.310578],
          [99.9406263, 6.310682],
          [99.940359783373566, 6.310792676557766],
          [99.9401767, 6.3110069],
          [99.9398029, 6.3115317],
          [99.9395804, 6.3114723],
          [99.9395511, 6.3112449],
          [99.9396237, 6.3110945],
          [99.93962788860452, 6.311030073838431],
          [99.939200665083433, 6.311148877801993],
          [99.9391983, 6.3111703],
          [99.9393775, 6.3114453],
          [99.939386, 6.311604],
          [99.9390748, 6.3118562],
          [99.9389469, 6.3122616],
          [99.938633, 6.3122865],
          [99.9383552, 6.3120537],
          [99.938191170386048, 6.31181239063873],
          [99.937835320583986, 6.312071443723599],
        ],
      ],
      [
        [
          [99.9262426, 6.3257224],
          [99.9260873, 6.325946],
          [99.9262457, 6.326053],
          [99.9265436, 6.3260152],
          [99.9268129, 6.325798],
          [99.9269744, 6.3255114],
          [99.9270125, 6.3252689],
          [99.9268826, 6.3250107],
          [99.9265531, 6.3250422],
          [99.9263947, 6.3251965],
          [99.9263123, 6.3254358],
          [99.9262426, 6.3257224],
        ],
      ],
      [
        [
          [99.669722, 6.357934],
          [99.669649, 6.358595],
          [99.669969, 6.359789],
          [99.6704059, 6.3604785],
          [99.6710679, 6.3608112],
          [99.671544, 6.361123],
          [99.67194, 6.361324],
          [99.672472, 6.360997],
          [99.672352, 6.359738],
          [99.6720345, 6.3591776],
          [99.6717599, 6.3586669],
          [99.671906, 6.357948],
          [99.6711107, 6.3577614],
          [99.6708844, 6.3574406],
          [99.670453, 6.357607],
          [99.669722, 6.357934],
        ],
      ],
      [
        [
          [99.6761977, 6.3602761],
          [99.6759094, 6.3604603],
          [99.6759197, 6.3608083],
          [99.6762904, 6.3610539],
          [99.6765684, 6.3608185],
          [99.6768773, 6.3609209],
          [99.6776599, 6.3606343],
          [99.6779276, 6.3608492],
          [99.6779997, 6.3611358],
          [99.6780718, 6.3609311],
          [99.6779585, 6.360225],
          [99.6761977, 6.3602761],
        ],
      ],
      [
        [
          [99.679987, 6.3603273],
          [99.6797296, 6.360665],
          [99.6799458, 6.360972],
          [99.6799458, 6.3608595],
          [99.6802444, 6.3607162],
          [99.6807696, 6.3606855],
          [99.6812123, 6.3609004],
          [99.681861, 6.3608902],
          [99.6821699, 6.3609106],
          [99.6822214, 6.3604296],
          [99.6820155, 6.360184],
          [99.679987, 6.3603273],
        ],
      ],
      [
        [
          [99.67278, 6.435126],
          [99.67244, 6.435984],
          [99.673131, 6.436473],
          [99.6729004, 6.4367523],
          [99.6731621, 6.4371937],
          [99.6734207, 6.4374677],
          [99.6742264, 6.4375807],
          [99.6748809, 6.4376148],
          [99.6753229, 6.4381425],
          [99.675401, 6.437923],
          [99.6754206, 6.4374314],
          [99.67521, 6.437061],
          [99.6746771, 6.4368696],
          [99.6744571, 6.4365284],
          [99.674093, 6.436259],
          [99.6739067, 6.4358706],
          [99.6741519, 6.4354735],
          [99.6744196, 6.4352533],
          [99.674176, 6.435151],
          [99.673763, 6.4346382],
          [99.673247, 6.434731],
          [99.67278, 6.435126],
        ],
      ],
      [
        [
          [99.776271168864866, 6.448311312088678],
          [99.7763475, 6.4484598],
          [99.7759308, 6.4491439],
          [99.775775, 6.449223],
          [99.775948332679391, 6.450262074093952],
          [99.7763866, 6.4504772],
          [99.776837, 6.4510438],
          [99.7772045, 6.4514386],
          [99.777219912686505, 6.451445114428828],
          [99.777330913560419, 6.451390448584903],
          [99.7773388, 6.4513782],
          [99.7772966, 6.451142],
          [99.7773613, 6.4509796],
          [99.7773173, 6.4505508],
          [99.7771052, 6.4502194],
          [99.7771007, 6.44996],
          [99.7772868, 6.4490813],
          [99.777415, 6.448786],
          [99.7776161, 6.4479708],
          [99.7777404, 6.4479087],
          [99.777733508062937, 6.447802879229194],
          [99.777472578508679, 6.44753797302288],
          [99.7773981, 6.4475573],
          [99.777414433669946, 6.447478942037721],
          [99.777343038311457, 6.447406458590526],
          [99.7773422, 6.447407],
          [99.777342204172086, 6.44740561173861],
          [99.777211087235358, 6.447272496536724],
          [99.7771833, 6.4472722],
          [99.777181704156121, 6.447242665649704],
          [99.777053, 6.447112],
          [99.77704094206274, 6.447104343439079],
          [99.7770111, 6.4470963],
          [99.776614, 6.4472534],
          [99.776295604371626, 6.447493475698752],
          [99.776385, 6.44777],
          [99.776271168864866, 6.448311312088678],
        ],
      ],
      [
        [
          [99.908659, 6.4113092],
          [99.9083806, 6.4121051],
          [99.9089272, 6.4132353],
          [99.909236, 6.4134023],
          [99.9104137, 6.4140312],
          [99.9107082, 6.4142636],
          [99.9113749, 6.4158069],
          [99.9114555, 6.416687],
          [99.9111503, 6.4183475],
          [99.9113053, 6.4187218],
          [99.9108649, 6.4192106],
          [99.9109813, 6.4197117],
          [99.9111308, 6.4197209],
          [99.9116406, 6.4194883],
          [99.9123959, 6.4197559],
          [99.9120617, 6.4205662],
          [99.9124474, 6.4208551],
          [99.9123868, 6.4220908],
          [99.9120729, 6.4223563],
          [99.9123637, 6.4237337],
          [99.9127333, 6.4239443],
          [99.9129634, 6.4235408],
          [99.9127714, 6.4229128],
          [99.9127928, 6.4227028],
          [99.9131791, 6.4225823],
          [99.9132547, 6.4232028],
          [99.914164, 6.4244147],
          [99.9152524, 6.4249902],
          [99.9149842, 6.4257877],
          [99.9152514, 6.4277494],
          [99.9146977, 6.4296311],
          [99.915526, 6.4311046],
          [99.9162288, 6.4320352],
          [99.9160812, 6.4334196],
          [99.9173845, 6.435415],
          [99.9174567, 6.4360695],
          [99.9182764, 6.437828],
          [99.9185456, 6.4382705],
          [99.9188977, 6.4382733],
          [99.9192897, 6.4382785],
          [99.9194925, 6.4380077],
          [99.9193283, 6.4375162],
          [99.9195922, 6.4374639],
          [99.9195724, 6.4356169],
          [99.9194308, 6.4350822],
          [99.9187425, 6.4329857],
          [99.9183659, 6.4316642],
          [99.9181106, 6.4311983],
          [99.9179191, 6.4301828],
          [99.9173676, 6.4287308],
          [99.9167942, 6.4279541],
          [99.9160501, 6.4276086],
          [99.9157701, 6.4262498],
          [99.915908, 6.4257456],
          [99.9161054, 6.4255856],
          [99.9163629, 6.4255446],
          [99.9163849, 6.4253617],
          [99.9155131, 6.4234965],
          [99.9150845, 6.4233697],
          [99.9150534, 6.4225706],
          [99.9152615, 6.4222683],
          [99.9153045, 6.420821],
          [99.9155609, 6.4203135],
          [99.9155711, 6.4190901],
          [99.9158135, 6.418887],
          [99.9157819, 6.4177825],
          [99.9158334, 6.4168672],
          [99.91476, 6.4124229],
          [99.9144263, 6.4119196],
          [99.9144252, 6.4112831],
          [99.9138722, 6.409759],
          [99.914319, 6.4084769],
          [99.9139269, 6.407994],
          [99.9135482, 6.4071144],
          [99.9131936, 6.4066154],
          [99.9130439, 6.4060455],
          [99.912884, 6.4054863],
          [99.9127859, 6.4048594],
          [99.9130251, 6.4042954],
          [99.9133561, 6.4040032],
          [99.9132413, 6.4036354],
          [99.9141398, 6.4032025],
          [99.9141291, 6.4029573],
          [99.9139612, 6.4024141],
          [99.9137332, 6.4022925],
          [99.9136174, 6.4025154],
          [99.9130557, 6.4024807],
          [99.9129114, 6.4018426],
          [99.9127253, 6.4018058],
          [99.9122457, 6.4018948],
          [99.911882, 6.4028136],
          [99.9110923, 6.4044564],
          [99.910587, 6.4048567],
          [99.910533, 6.4054536],
          [99.909516, 6.4080956],
          [99.9089063, 6.4088917],
          [99.908908, 6.4094156],
          [99.9093194, 6.4098998],
          [99.9091665, 6.410919],
          [99.9090206, 6.4113636],
          [99.908659, 6.4113092],
        ],
      ],
      [
        [
          [99.8824586, 6.4218317],
          [99.882439, 6.4220215],
          [99.8826006, 6.4220458],
          [99.8826937, 6.4219874],
          [99.8828357, 6.4217489],
          [99.8829337, 6.4216224],
          [99.883159, 6.4215202],
          [99.8831982, 6.4213401],
          [99.8830904, 6.4211649],
          [99.8829337, 6.4211406],
          [99.8828406, 6.4213255],
          [99.8827427, 6.4215543],
          [99.8826398, 6.42171],
          [99.8824586, 6.4218317],
        ],
      ],
      [
        [
          [99.7423242, 6.4290707],
          [99.7419308, 6.4295961],
          [99.7420263, 6.429917],
          [99.7425024, 6.4307873],
          [99.7429946, 6.4318129],
          [99.7436041, 6.432537],
          [99.7443877, 6.4331887],
          [99.7447107, 6.433078],
          [99.7450111, 6.4327754],
          [99.7449159, 6.4325702],
          [99.7442919, 6.431834],
          [99.7439911, 6.4310664],
          [99.7437634, 6.4304927],
          [99.7437479, 6.43039],
          [99.743739, 6.4303311],
          [99.7438203, 6.4299756],
          [99.7436333, 6.4296524],
          [99.7433975, 6.4294989],
          [99.7430804, 6.4293292],
          [99.742682, 6.4295151],
          [99.7423242, 6.4290707],
        ],
      ],
      [
        [
          [99.8656268, 6.4320729],
          [99.8653263, 6.4330163],
          [99.8654841, 6.4335748],
          [99.8657503, 6.4336483],
          [99.8660493, 6.4335173],
          [99.866176, 6.4334297],
          [99.8664034, 6.4336262],
          [99.8663773, 6.4338833],
          [99.8661443, 6.4340143],
          [99.8658499, 6.4343763],
          [99.8658687, 6.4344825],
          [99.8663916, 6.4343642],
          [99.8667314, 6.4343243],
          [99.8670149, 6.4344514],
          [99.8672687, 6.4347573],
          [99.8674009, 6.4347323],
          [99.8675621, 6.4343524],
          [99.8677977, 6.4341595],
          [99.8679963, 6.4342243],
          [99.8682188, 6.4337492],
          [99.8680743, 6.4336211],
          [99.8675637, 6.4339455],
          [99.8673066, 6.4337502],
          [99.8674403, 6.4335253],
          [99.867369, 6.433014],
          [99.8676988, 6.4326165],
          [99.8680388, 6.4323713],
          [99.8682128, 6.4321095],
          [99.867675, 6.4316718],
          [99.867358, 6.4316355],
          [99.8672178, 6.431802],
          [99.8670986, 6.4324158],
          [99.8669679, 6.4329236],
          [99.8664331, 6.4328291],
          [99.8665282, 6.4325161],
          [99.8666903, 6.4322963],
          [99.8666173, 6.4320497],
          [99.8663597, 6.4319772],
          [99.8656268, 6.4320729],
        ],
      ],
      [
        [
          [99.880565, 6.434253],
          [99.879544, 6.437292],
          [99.879929, 6.438619],
          [99.88098, 6.43942],
          [99.881608, 6.443131],
          [99.882129, 6.443995],
          [99.883116, 6.444729],
          [99.883027, 6.447178],
          [99.883277, 6.448768],
          [99.883332, 6.44996],
          [99.883857, 6.450427],
          [99.88505, 6.450302],
          [99.88624, 6.450508],
          [99.886625, 6.451834],
          [99.8891959, 6.4519376],
          [99.889446, 6.454632],
          [99.8932814, 6.4520996],
          [99.8940024, 6.4490037],
          [99.895144, 6.4452681],
          [99.8943286, 6.4429824],
          [99.894928, 6.441953],
          [99.89579, 6.441825],
          [99.8970494, 6.4409184],
          [99.8979249, 6.4418821],
          [99.898358, 6.443297],
          [99.898948, 6.444029],
          [99.9011607, 6.4443129],
          [99.9013581, 6.4414642],
          [99.9011092, 6.4389653],
          [99.9008775, 6.4354599],
          [99.901166, 6.433317],
          [99.90164, 6.432194],
          [99.90238, 6.430941],
          [99.901851, 6.430872],
          [99.902474, 6.427962],
          [99.904292, 6.424729],
          [99.905833, 6.422752],
          [99.9046792, 6.4219351],
          [99.9042854, 6.4203285],
          [99.9051142, 6.4200875],
          [99.9058347, 6.4201264],
          [99.9064913, 6.4207155],
          [99.906738, 6.4212704],
          [99.9069907, 6.4213983],
          [99.9072863, 6.4212597],
          [99.907695, 6.4214623],
          [99.9078265, 6.4209783],
          [99.9085491, 6.4206819],
          [99.9089868, 6.4210097],
          [99.9097067, 6.4205859],
          [99.9101723, 6.4201909],
          [99.909741, 6.4199175],
          [99.909833, 6.419796],
          [99.908721, 6.418399],
          [99.9071098, 6.4162818],
          [99.907755, 6.415481],
          [99.9076312, 6.414021],
          [99.9062794, 6.4140296],
          [99.9054071, 6.4150595],
          [99.90445, 6.414865],
          [99.9038713, 6.4145296],
          [99.9036277, 6.4140839],
          [99.903327, 6.414594],
          [99.9025135, 6.4150232],
          [99.901388, 6.416635],
          [99.899116, 6.418872],
          [99.8993244, 6.4194681],
          [99.8986083, 6.420023],
          [99.8981523, 6.4205769],
          [99.8975386, 6.4205198],
          [99.8965408, 6.4207997],
          [99.8956219, 6.4212363],
          [99.8951225, 6.4209729],
          [99.8948757, 6.4212976],
          [99.893438, 6.4218829],
          [99.8927353, 6.4216953],
          [99.8926537, 6.421941],
          [99.8921323, 6.4224261],
          [99.8916478, 6.422306],
          [99.8894625, 6.4232854],
          [99.8886127, 6.4238313],
          [99.8875801, 6.4248058],
          [99.885795, 6.427267],
          [99.884667, 6.427525],
          [99.882399, 6.429299],
          [99.882124, 6.430422],
          [99.882239, 6.432343],
          [99.880565, 6.434253],
        ],
      ],
      [
        [
          [99.794542997785499, 6.454252425746192],
          [99.7943567, 6.4543152],
          [99.7940558, 6.4542584],
          [99.7940553, 6.4540363],
          [99.793903596387509, 6.453863952184563],
          [99.7935672, 6.4539804],
          [99.793624277577749, 6.454251449225408],
          [99.7936817, 6.4541595],
          [99.7937962, 6.454232],
          [99.7938105, 6.454345],
          [99.793680427282567, 6.45451809218041],
          [99.793732, 6.454763],
          [99.793835300064686, 6.455191533862096],
          [99.7940215, 6.4551756],
          [99.7941507, 6.4550998],
          [99.7947633, 6.4546391],
          [99.794868647426227, 6.454624835420643],
          [99.7950229, 6.4545225],
          [99.795374, 6.4550401],
          [99.795620725084646, 6.455141135379723],
          [99.795682, 6.4551286],
          [99.7958992, 6.4548623],
          [99.7960524, 6.4544961],
          [99.7963664, 6.4542472],
          [99.7964582, 6.4540585],
          [99.7964145, 6.453786],
          [99.7961772, 6.4533726],
          [99.7960686, 6.4530178],
          [99.7962055, 6.4529052],
          [99.7962217, 6.4526907],
          [99.7961148, 6.4524962],
          [99.7959545, 6.452427],
          [99.7955327, 6.4523841],
          [99.7952553, 6.4522089],
          [99.795212111371413, 6.452201557586802],
          [99.7951722, 6.4523478],
          [99.795341, 6.452721],
          [99.7956727, 6.4531704],
          [99.7954326, 6.4536026],
          [99.795062, 6.454242],
          [99.794948, 6.4543283],
          [99.7948642, 6.4542763],
          [99.794733465105907, 6.454248599627757],
          [99.794542997785499, 6.454252425746192],
        ],
      ],
      [
        [
          [99.789673648479322, 6.453194270246046],
          [99.7896356, 6.4533041],
          [99.789616, 6.4535771],
          [99.7895515, 6.4536593],
          [99.7895507, 6.4537219],
          [99.7894593, 6.453758],
          [99.7893919, 6.4537922],
          [99.7894106, 6.453886],
          [99.7895377, 6.4538574],
          [99.7894591, 6.4540799],
          [99.7892523, 6.4544914],
          [99.7891911, 6.4546042],
          [99.789116334589636, 6.454821654951721],
          [99.789107055100899, 6.454971316449554],
          [99.7892722, 6.4553697],
          [99.7893041, 6.4559514],
          [99.7895866, 6.456122],
          [99.7897355, 6.4559041],
          [99.7897645, 6.4559899],
          [99.7896946, 6.456047],
          [99.7896954, 6.4561103],
          [99.7898636, 6.456046],
          [99.7898927, 6.4558413],
          [99.7898228, 6.4557879],
          [99.7898471, 6.4556513],
          [99.7898388, 6.4555067],
          [99.7899266, 6.4549996],
          [99.7898075, 6.4548885],
          [99.7897359, 6.4546282],
          [99.7898104, 6.4544002],
          [99.7897583, 6.4542348],
          [99.7901243, 6.453546],
          [99.7902372, 6.453134],
          [99.79013077597871, 6.452983264538199],
          [99.790115878654632, 6.452978185904986],
          [99.789965240258226, 6.453003277630862],
          [99.789869588514065, 6.453065929523286],
          [99.7897816, 6.4531782],
          [99.789738545240894, 6.453151762867217],
          [99.789673648479322, 6.453194270246046],
        ],
      ],
      [
        [
          [99.853612744044852, 6.457255925061576],
          [99.8536354, 6.4573587],
          [99.8537402, 6.4574243],
          [99.8539029, 6.4572921],
          [99.8540681, 6.4571972],
          [99.854287, 6.4571768],
          [99.8544049, 6.4571305],
          [99.8544955, 6.4570067],
          [99.8547041, 6.4569602],
          [99.8548335, 6.4568305],
          [99.85483146734984, 6.456788461098842],
          [99.8547979, 6.4567551],
          [99.8546269, 6.4568765],
          [99.8544152, 6.4570059],
          [99.854171, 6.4570544],
          [99.8539837, 6.4570625],
          [99.8537232, 6.4570949],
          [99.853612744044852, 6.457255925061576],
        ],
      ],
      [
        [
          [99.8186782, 6.4592204],
          [99.8184997, 6.459249],
          [99.8185055, 6.4593691],
          [99.8187645, 6.4593634],
          [99.8189775, 6.4593634],
          [99.8190294, 6.459289],
          [99.8189488, 6.4591003],
          [99.8186782, 6.4592204],
        ],
      ],
      [
        [
          [99.833994, 6.4568314],
          [99.833937, 6.4568719],
          [99.8340714, 6.4570256],
          [99.8341365, 6.4572359],
          [99.8340347, 6.4573573],
          [99.8341487, 6.4574624],
          [99.8344174, 6.4575959],
          [99.8345639, 6.4574746],
          [99.8347064, 6.4574503],
          [99.8348407, 6.4575393],
          [99.8346331, 6.4577011],
          [99.834857, 6.4577982],
          [99.8352885, 6.4578386],
          [99.8355653, 6.4578184],
          [99.836066, 6.4574665],
          [99.8364853, 6.4571591],
          [99.8366075, 6.4569851],
          [99.8365464, 6.4568355],
          [99.8363062, 6.4567788],
          [99.835948, 6.4567627],
          [99.8356549, 6.4568072],
          [99.8353903, 6.4567465],
          [99.8351786, 6.4567263],
          [99.8349792, 6.4566494],
          [99.8347227, 6.4565685],
          [99.8344988, 6.4565928],
          [99.8342871, 6.4564108],
          [99.8341324, 6.4564593],
          [99.8340592, 6.4565564],
          [99.8340958, 6.4566858],
          [99.8342912, 6.4567546],
          [99.8341569, 6.4569245],
          [99.833994, 6.4568314],
        ],
      ],
      [
        [
          [99.8183903, 6.4600212],
          [99.8182464, 6.4600899],
          [99.8183213, 6.4606047],
          [99.8185227, 6.4607649],
          [99.818943, 6.4607992],
          [99.8191733, 6.4608049],
          [99.8192078, 6.4609022],
          [99.8191618, 6.4610566],
          [99.8193978, 6.4610909],
          [99.8194323, 6.4608736],
          [99.8192251, 6.4606562],
          [99.8191848, 6.4605647],
          [99.8190812, 6.4602672],
          [99.818753, 6.4602901],
          [99.8185746, 6.4601986],
          [99.8183903, 6.4600212],
        ],
      ],
      [
        [
          [99.8222705, 6.4655414],
          [99.8222245, 6.4656844],
          [99.8223166, 6.4659075],
          [99.822472, 6.4662164],
          [99.8223626, 6.4665024],
          [99.8224662, 6.4665768],
          [99.8224893, 6.4668971],
          [99.8223223, 6.4670458],
          [99.8223684, 6.4672632],
          [99.8224778, 6.4674348],
          [99.8226965, 6.4676636],
          [99.8228347, 6.4676064],
          [99.8229498, 6.4672861],
          [99.8229959, 6.4669314],
          [99.8231283, 6.4664909],
          [99.8230765, 6.4660562],
          [99.8229095, 6.4658102],
          [99.8225699, 6.46557],
          [99.8222705, 6.4655414],
        ],
      ],
      [
        [
          [99.8488148, 6.4685073],
          [99.8487256, 6.4686589],
          [99.8489069, 6.4687247],
          [99.8490019, 6.4687876],
          [99.8492092, 6.4688133],
          [99.849402, 6.4689077],
          [99.8495863, 6.4689449],
          [99.8497647, 6.4688591],
          [99.849854, 6.4687533],
          [99.8498655, 6.4685874],
          [99.8498021, 6.4685302],
          [99.8497043, 6.4686074],
          [99.8495316, 6.4685874],
          [99.8494366, 6.4686131],
          [99.8494049, 6.4686102],
          [99.8493963, 6.4685359],
          [99.8493042, 6.4685244],
          [99.8491977, 6.4684272],
          [99.8489731, 6.46837],
          [99.8488148, 6.4685073],
        ],
      ],
      [
        [
          [99.7776176, 6.2831573],
          [99.7774244, 6.2832213],
          [99.7774781, 6.2834346],
          [99.777757, 6.2835412],
          [99.7779528, 6.2835161],
          [99.7780253, 6.2831786],
          [99.7776176, 6.2831573],
        ],
      ],
      [
        [
          [99.7046113, 6.2929911],
          [99.7045094, 6.2930268],
          [99.7045934, 6.2933485],
          [99.7047852, 6.2936047],
          [99.704923, 6.2937239],
          [99.7051748, 6.2938133],
          [99.7051928, 6.293563],
          [99.7051148, 6.2932473],
          [99.7049051, 6.2931162],
          [99.7046113, 6.2929911],
        ],
      ],
      [
        [
          [99.706654148184981, 6.292107281224826],
          [99.7066548, 6.2921119],
          [99.7066951, 6.292133],
          [99.7066716, 6.2922619],
          [99.7064495, 6.2922593],
          [99.7064417, 6.2923356],
          [99.7064664, 6.2924786],
          [99.706399517440005, 6.292514957140925],
          [99.706411587790484, 6.29253658801534],
          [99.7064897, 6.2926126],
          [99.7066245, 6.292631],
          [99.706576594829201, 6.292753988864452],
          [99.706637689540173, 6.292807584771053],
          [99.7068466, 6.2928769],
          [99.7069783, 6.2930906],
          [99.7070395, 6.2932975],
          [99.7069606, 6.2935614],
          [99.7068808, 6.293722],
          [99.706742389640354, 6.293703106944293],
          [99.706738345501364, 6.2937140423201],
          [99.7068033, 6.2937542],
          [99.7067728, 6.2938561],
          [99.7069074, 6.2938556],
          [99.7071188, 6.2939928],
          [99.7073859, 6.2941015],
          [99.7074973, 6.294193],
          [99.707459747127885, 6.294245019150026],
          [99.707465072825457, 6.294247950506234],
          [99.7075476, 6.2942836],
          [99.707548, 6.2941882],
          [99.7077507, 6.2941641],
          [99.7077766, 6.2942192],
          [99.7078451, 6.2941797],
          [99.7078278, 6.2941165],
          [99.7080176, 6.2940652],
          [99.7083107, 6.2941204],
          [99.7089408, 6.2943003],
          [99.7096678, 6.2944373],
          [99.709960797881521, 6.294522031233891],
          [99.7101667, 6.2944458],
          [99.7106285, 6.2945902],
          [99.7110268, 6.2947504],
          [99.711424, 6.2950391],
          [99.7117895, 6.2952726],
          [99.711921793194961, 6.295174916786972],
          [99.7119622, 6.2950879],
          [99.712049250932267, 6.295080803999782],
          [99.712245009370065, 6.294936259050314],
          [99.7122403, 6.2948365],
          [99.7120422, 6.2946469],
          [99.7121655, 6.2944298],
          [99.7121557, 6.2942778],
          [99.7120237, 6.2940045],
          [99.7120014, 6.29371],
          [99.7120141, 6.2935588],
          [99.7122507, 6.2935582],
          [99.7122848, 6.2933839],
          [99.712197809873459, 6.293253790848182],
          [99.712066251937927, 6.293154941812296],
          [99.7119496, 6.2931237],
          [99.711892737812761, 6.293024568042622],
          [99.711701769645913, 6.292881079783228],
          [99.7115489, 6.2929177],
          [99.7114368, 6.293064],
          [99.711206, 6.2931055],
          [99.7110819, 6.2930893],
          [99.7109656, 6.2930454],
          [99.710982642116434, 6.292992209956899],
          [99.7109118, 6.293005],
          [99.7104818, 6.2924563],
          [99.709453469529365, 6.291608948786787],
          [99.709404027686332, 6.291576253007483],
          [99.709300207777034, 6.291640587311937],
          [99.7093173, 6.2919468],
          [99.7091113, 6.2921058],
          [99.7088455, 6.2921972],
          [99.7085598, 6.2920337],
          [99.708540716862885, 6.291993708082677],
          [99.706919411070729, 6.292035559875361],
          [99.7069235, 6.2920452],
          [99.706850650200934, 6.292037777431009],
          [99.706654148184981, 6.292107281224826],
        ],
      ],
      [
        [
          [99.6872427, 6.2921996],
          [99.686493, 6.2926331],
          [99.6865427, 6.2931157],
          [99.6870462, 6.2933421],
          [99.686534, 6.2944141],
          [99.688448, 6.295705],
          [99.689702, 6.296044],
          [99.6905825, 6.2969538],
          [99.691775, 6.2969564],
          [99.6928026, 6.2973013],
          [99.6939771, 6.2985061],
          [99.694241, 6.3001424],
          [99.6934025, 6.3035725],
          [99.6942914, 6.3042556],
          [99.695806, 6.3038896],
          [99.69713, 6.3042074],
          [99.6975, 6.3041285],
          [99.6975691, 6.3029629],
          [99.6991481, 6.3022641],
          [99.7004141, 6.3025358],
          [99.7019071, 6.30354],
          [99.7036685, 6.3039507],
          [99.7041015, 6.3031283],
          [99.704741, 6.301502],
          [99.7038643, 6.2991969],
          [99.7038496, 6.298962],
          [99.7039773, 6.2985651],
          [99.7039886, 6.2982774],
          [99.7036482, 6.2979436],
          [99.703588652312945, 6.29792069796676],
          [99.7030605, 6.2978338],
          [99.70241, 6.2968577],
          [99.7024115, 6.2953904],
          [99.7026384, 6.2945945],
          [99.702197, 6.2940299],
          [99.70174, 6.2940908],
          [99.7005025, 6.2934396],
          [99.700327, 6.2928935],
          [99.7006949, 6.2925062],
          [99.7005569, 6.2919634],
          [99.7003268, 6.2917493],
          [99.7003028, 6.2913799],
          [99.7005246, 6.2913679],
          [99.7004766, 6.2910879],
          [99.6999431, 6.290796],
          [99.7000031, 6.2902955],
          [99.6996854, 6.2902061],
          [99.6992478, 6.2907662],
          [99.6993737, 6.29107],
          [99.699161, 6.2913339],
          [99.6981085, 6.2908858],
          [99.696675, 6.2907734],
          [99.69564, 6.291249],
          [99.6952609, 6.2911601],
          [99.6950397, 6.2916929],
          [99.6953905, 6.2924052],
          [99.6964538, 6.2931119],
          [99.697664533346071, 6.293433806581685],
          [99.6979513, 6.2934986],
          [99.6981362, 6.2935696],
          [99.698331, 6.2936869],
          [99.6986008, 6.2939214],
          [99.6986214, 6.2940002],
          [99.6984948, 6.2941702],
          [99.698440563235835, 6.294204131819185],
          [99.698365365965756, 6.29428893269557],
          [99.6982673, 6.2945199],
          [99.6982359, 6.294812],
          [99.698387849656513, 6.295132175408896],
          [99.698497, 6.2952041],
          [99.6986242, 6.295453],
          [99.6988512, 6.2960856],
          [99.698850609015949, 6.29610726262212],
          [99.6988782, 6.2961654],
          [99.698848654282855, 6.296178913700889],
          [99.6988483, 6.2961919],
          [99.698811, 6.2962631],
          [99.6981515, 6.2966451],
          [99.6979838, 6.296728],
          [99.6978213, 6.2967351],
          [99.6977092, 6.2966633],
          [99.69705775952832, 6.295596013852761],
          [99.696929952104782, 6.29540719075703],
          [99.6968953, 6.2954008],
          [99.6964318, 6.2948737],
          [99.696398349429728, 6.29482733346221],
          [99.6961612, 6.2945914],
          [99.6962371, 6.2938487],
          [99.696043360292094, 6.293486683517231],
          [99.6959988, 6.2934438],
          [99.6955224, 6.2931859],
          [99.6950793, 6.2928487],
          [99.6944258, 6.2916865],
          [99.694586, 6.2911879],
          [99.694812, 6.2906655],
          [99.6945755, 6.2905707],
          [99.694369, 6.2908709],
          [99.6939837, 6.290791],
          [99.6940881, 6.2912536],
          [99.6932606, 6.2915242],
          [99.6925916, 6.2908542],
          [99.6913004, 6.2904266],
          [99.6895637, 6.2896261],
          [99.6883482, 6.2894691],
          [99.688162, 6.291069],
          [99.6872427, 6.2921996],
        ],
      ],
      [
        [
          [99.8678559, 6.3050766],
          [99.8676686, 6.3051616],
          [99.8677012, 6.3055055],
          [99.8678029, 6.3055864],
          [99.868039, 6.3057038],
          [99.8682548, 6.3056512],
          [99.8682874, 6.3055055],
          [99.8682996, 6.3053477],
          [99.8682426, 6.3052061],
          [99.8680757, 6.3050766],
          [99.8678559, 6.3050766],
        ],
      ],
      [
        [
          [99.935077037183419, 6.306499280489177],
          [99.9347485, 6.3065615],
          [99.9344133, 6.3064285],
          [99.9330824, 6.305933],
          [99.9324761, 6.3059618],
          [99.9321222, 6.3063112],
          [99.931792, 6.3062962],
          [99.9316722, 6.3064346],
          [99.9316113, 6.3067299],
          [99.9311157, 6.3074852],
          [99.9311881, 6.3082629],
          [99.9308538, 6.3088594],
          [99.930812061314711, 6.309267991344476],
          [99.931114, 6.309491],
          [99.932107, 6.309497],
          [99.932911, 6.308508],
          [99.933770233469446, 6.308405864700801],
          [99.9340082, 6.3082895],
          [99.934078970296909, 6.308290907286975],
          [99.934438, 6.307988],
          [99.934744681261506, 6.307836580931039],
          [99.934823, 6.3076934],
          [99.935067432417242, 6.307435545739357],
          [99.935307, 6.307066],
          [99.935077037183419, 6.306499280489177],
        ],
      ],
      [
        [
          [99.886858224010325, 6.299627861701264],
          [99.88676, 6.2996004],
          [99.8866452, 6.2996281],
          [99.8863861, 6.2996383],
          [99.8862238, 6.2998333],
          [99.886191, 6.3000643],
          [99.886241, 6.3003884],
          [99.8857499, 6.3008469],
          [99.885577, 6.3012076],
          [99.8855199, 6.3017795],
          [99.8854603, 6.30265],
          [99.8851689, 6.3030019],
          [99.8848759, 6.3033218],
          [99.884842106715055, 6.303452555997215],
          [99.885295, 6.303658],
          [99.88569807296291, 6.303509219772633],
          [99.8858936, 6.3032796],
          [99.886297, 6.30267],
          [99.886373, 6.301678],
          [99.886775, 6.301151],
          [99.886777372991034, 6.301149684149705],
          [99.8869882, 6.3007697],
          [99.8872401, 6.300734],
          [99.887396, 6.3007779],
          [99.887507589892905, 6.300744770642092],
          [99.887557489375624, 6.300717100816148],
          [99.8875782, 6.3006474],
          [99.8870379, 6.300028],
          [99.88695124272337, 6.299716362362531],
          [99.886858224010325, 6.299627861701264],
        ],
      ],
      [
        [
          [99.8841081, 6.3109259],
          [99.8838935, 6.311372],
          [99.8840621, 6.3116447],
          [99.8845029, 6.3118173],
          [99.8847373, 6.3118401],
          [99.8851571, 6.3121647],
          [99.8853363, 6.3125165],
          [99.885741, 6.3132511],
          [99.8854458, 6.3136878],
          [99.885255, 6.3143221],
          [99.8853194, 6.3147485],
          [99.8854841, 6.3150327],
          [99.8858969, 6.3151276],
          [99.8863973, 6.3149394],
          [99.8865819, 6.3146586],
          [99.8866805, 6.3142756],
          [99.8867029, 6.3138481],
          [99.887244, 6.3136073],
          [99.8876403, 6.3135872],
          [99.8883562, 6.3134517],
          [99.8887079, 6.3140827],
          [99.889091, 6.3142712],
          [99.88954, 6.3142511],
          [99.8898223, 6.3140433],
          [99.8899065, 6.3136922],
          [99.8902784, 6.3134873],
          [99.8905383, 6.3132513],
          [99.8907041, 6.313042],
          [99.8909102, 6.3130465],
          [99.8910088, 6.313238],
          [99.8907982, 6.3134205],
          [99.890843, 6.3136566],
          [99.8907837, 6.3139016],
          [99.8910804, 6.3142712],
          [99.8905741, 6.3149837],
          [99.8905352, 6.3154344],
          [99.8906108, 6.3160431],
          [99.8909774, 6.316391],
          [99.891512, 6.316653],
          [99.8921289, 6.3167829],
          [99.8924761, 6.3169587],
          [99.8926442, 6.3176112],
          [99.8929036, 6.3181591],
          [99.893146, 6.3183594],
          [99.8934507, 6.3182926],
          [99.893603, 6.3181056],
          [99.8938091, 6.3176424],
          [99.8940063, 6.3176379],
          [99.8943041, 6.3171409],
          [99.8946605, 6.3168408],
          [99.8950055, 6.317059],
          [99.8952017, 6.3169833],
          [99.8955207, 6.3171614],
          [99.895755, 6.3172102],
          [99.896157, 6.3168853],
          [99.8964751, 6.3169254],
          [99.8968022, 6.3171214],
          [99.8969931, 6.3178499],
          [99.8975057, 6.3181056],
          [99.897891, 6.3181234],
          [99.8983928, 6.3179853],
          [99.8985362, 6.3180299],
          [99.8985945, 6.3183505],
          [99.8988225, 6.3185143],
          [99.8991545, 6.3184752],
          [99.8998894, 6.318159],
          [99.9000283, 6.3183327],
          [99.9005749, 6.3182258],
          [99.9015786, 6.3178651],
          [99.9020938, 6.3179052],
          [99.9024468, 6.3179001],
          [99.9027838, 6.3180967],
          [99.9030885, 6.3185019],
          [99.9034709, 6.3190124],
          [99.9039477, 6.3193663],
          [99.9044238, 6.3195485],
          [99.9045313, 6.3197489],
          [99.904818, 6.3198023],
          [99.905106, 6.3194739],
          [99.9055081, 6.3192724],
          [99.9064875, 6.3192345],
          [99.9073362, 6.3193881],
          [99.9078828, 6.319544],
          [99.90845, 6.319288],
          [99.908873, 6.3191877],
          [99.90945, 6.318565],
          [99.9095989, 6.3183772],
          [99.9102082, 6.3183995],
          [99.9103427, 6.3186979],
          [99.9112091, 6.3188685],
          [99.9116644, 6.3189383],
          [99.9120139, 6.3187468],
          [99.9123452, 6.3181793],
          [99.9126278, 6.3177315],
          [99.9131086, 6.3175632],
          [99.9136001, 6.3176736],
          [99.914196, 6.3179141],
          [99.9145365, 6.318306],
          [99.9144839, 6.3194032],
          [99.9143349, 6.3197132],
          [99.9146667, 6.3200565],
          [99.9152803, 6.3200695],
          [99.9159076, 6.3203234],
          [99.9165976, 6.3207598],
          [99.9173562, 6.3211069],
          [99.9188917, 6.3214144],
          [99.9202817, 6.3210944],
          [99.9205424, 6.3213938],
          [99.9208677, 6.3213922],
          [99.9217134, 6.3223084],
          [99.9215173, 6.3226168],
          [99.9217011, 6.3228751],
          [99.9220132, 6.3228548],
          [99.9223463, 6.323365],
          [99.9222611, 6.3235966],
          [99.9222198, 6.3238207],
          [99.9223402, 6.3238711],
          [99.9224511, 6.3237105],
          [99.9226733, 6.3236411],
          [99.9230452, 6.3234585],
          [99.9231634, 6.3232938],
          [99.923503, 6.32368],
          [99.9238742, 6.323846],
          [99.9240048, 6.3234688],
          [99.9242602, 6.323298],
          [99.9250927, 6.323229],
          [99.9252518, 6.3233924],
          [99.9259552, 6.3235499],
          [99.926272, 6.3240474],
          [99.9265469, 6.3241787],
          [99.9269627, 6.3246048],
          [99.9272193, 6.3247465],
          [99.9274728, 6.3249858],
          [99.927797, 6.324632],
          [99.9288966, 6.3242256],
          [99.9288524, 6.323483],
          [99.9289017, 6.3227927],
          [99.9293843, 6.3220954],
          [99.929995, 6.3215146],
          [99.9304483, 6.320479],
          [99.9306047, 6.3199767],
          [99.9305642, 6.3197027],
          [99.9303641, 6.3195534],
          [99.930403534844331, 6.319485124747136],
          [99.9303641, 6.3194338],
          [99.930575041624493, 6.319025283349004],
          [99.9306031, 6.3188523],
          [99.9307655, 6.3187274],
          [99.9310172, 6.3187858],
          [99.931346595330737, 6.319059857328146],
          [99.932208169333933, 6.319306841875728],
          [99.9325892, 6.319358],
          [99.9329251, 6.3192778],
          [99.9331375, 6.3191175],
          [99.9337256, 6.3187294],
          [99.933748908609431, 6.318738815956919],
          [99.93401, 6.318512],
          [99.933961184836292, 6.318361001799327],
          [99.9337462, 6.3184775],
          [99.9334773, 6.3183652],
          [99.9332772, 6.3181569],
          [99.9333412, 6.3179766],
          [99.9337123, 6.3177669],
          [99.933728858774316, 6.317642355897771],
          [99.933624, 6.317318],
          [99.933630854572982, 6.317183531895354],
          [99.9334693, 6.317148],
          [99.9333149, 6.317239],
          [99.9329418, 6.3171996],
          [99.9328968, 6.3165948],
          [99.9326336, 6.3162271],
          [99.9326552, 6.3156519],
          [99.9330707, 6.3154621],
          [99.9333769, 6.3154434],
          [99.9334862, 6.3154572],
          [99.9335317, 6.3150738],
          [99.9336529, 6.3148603],
          [99.9334291, 6.3145144],
          [99.9331203, 6.3143305],
          [99.9329112, 6.3139342],
          [99.9327411, 6.3133555],
          [99.932640781696236, 6.313155603030717],
          [99.9311091, 6.3133407],
          [99.9306275, 6.3134919],
          [99.9303804, 6.3134226],
          [99.9303297, 6.3131392],
          [99.9300319, 6.3131518],
          [99.9297341, 6.3133029],
          [99.9296517, 6.31358],
          [99.9294616, 6.3139138],
          [99.9293729, 6.314235],
          [99.9296137, 6.314594],
          [99.9300002, 6.3146381],
          [99.9305325, 6.3143736],
          [99.9308176, 6.3145499],
          [99.9313435, 6.3151231],
          [99.931559, 6.3154884],
          [99.9314956, 6.3159481],
          [99.9313689, 6.3161182],
          [99.9310521, 6.3160678],
          [99.9305705, 6.3165464],
          [99.9305642, 6.3168299],
          [99.9302473, 6.3170125],
          [99.929658, 6.3172329],
          [99.9290751, 6.3172833],
          [99.9284731, 6.3172707],
          [99.9279915, 6.3170377],
          [99.9280359, 6.3167291],
          [99.9280739, 6.3163953],
          [99.9279281, 6.3158033],
          [99.9279915, 6.3154254],
          [99.9278204, 6.3149656],
          [99.9274529, 6.3144051],
          [99.9270981, 6.3138383],
          [99.9270347, 6.3134415],
          [99.9268826, 6.3133092],
          [99.9266355, 6.3132966],
          [99.9262743, 6.3129187],
          [99.9262173, 6.3125156],
          [99.9263123, 6.3122133],
          [99.9259955, 6.3117599],
          [99.9257927, 6.3116654],
          [99.9255456, 6.3115268],
          [99.9248929, 6.3101664],
          [99.9245888, 6.3094043],
          [99.9244811, 6.3091209],
          [99.9241579, 6.3086234],
          [99.9240248, 6.3078235],
          [99.9241325, 6.307074],
          [99.9248486, 6.3061734],
          [99.9253824, 6.305495],
          [99.925006, 6.304357],
          [99.9244494, 6.30378],
          [99.9235486, 6.3025148],
          [99.9230201, 6.3016592],
          [99.922854, 6.300835],
          [99.9227004, 6.3000785],
          [99.923592, 6.29978],
          [99.923611, 6.297728],
          [99.9226672, 6.2972572],
          [99.922446095130383, 6.296966078588341],
          [99.9223975, 6.2969534],
          [99.9221872, 6.2966252],
          [99.922076, 6.2959068],
          [99.9220541, 6.2953907],
          [99.9222569, 6.2944459],
          [99.9219874, 6.2939039],
          [99.9217481, 6.2938153],
          [99.9214185, 6.2941879],
          [99.9208375, 6.294383],
          [99.920271, 6.29427],
          [99.9202736, 6.2950695],
          [99.920657, 6.2962387],
          [99.9209199, 6.2965181],
          [99.9207552, 6.2966693],
          [99.9208375, 6.2971039],
          [99.920434, 6.297643],
          [99.9195503, 6.2998767],
          [99.9196019, 6.3009836],
          [99.9197103, 6.3014109],
          [99.9192851, 6.3021362],
          [99.9185324, 6.3024856],
          [99.917137, 6.3026967],
          [99.91581, 6.3029815],
          [99.9156035, 6.3028479],
          [99.914758, 6.303039],
          [99.914873, 6.3036073],
          [99.9134327, 6.3037615],
          [99.9125556, 6.3033329],
          [99.911765016170676, 6.303345841780089],
          [99.9115375, 6.3038034],
          [99.9110246, 6.304221],
          [99.910828360065835, 6.304834113996665],
          [99.910830455965552, 6.304845125787308],
          [99.9113655, 6.3052357],
          [99.9112851, 6.3060619],
          [99.9114925, 6.3070667],
          [99.912132, 6.3079056],
          [99.912188301839649, 6.308016897824847],
          [99.9122653, 6.3081042],
          [99.9125071, 6.3086471],
          [99.9126126, 6.3093312],
          [99.911853, 6.3103574],
          [99.9111567, 6.3106645],
          [99.9105323, 6.3106142],
          [99.9105676810468, 6.310199224726396],
          [99.909995, 6.309763],
          [99.909771677576799, 6.309564774763415],
          [99.908938, 6.309558],
          [99.90834, 6.309753],
          [99.9080611, 6.3092933],
          [99.907486, 6.309086],
          [99.907022, 6.309216],
          [99.9069965, 6.3093815],
          [99.9062335, 6.3094145],
          [99.9053452, 6.3093272],
          [99.9050107, 6.3092975],
          [99.9048104, 6.3089469],
          [99.9043464, 6.3088806],
          [99.9042492, 6.3093328],
          [99.903152, 6.3093101],
          [99.9027577, 6.309121],
          [99.9022378, 6.3092996],
          [99.901884, 6.3097332],
          [99.9015344, 6.3097972],
          [99.9010099, 6.3097264],
          [99.9007027, 6.3092608],
          [99.900202, 6.309308],
          [99.899603, 6.309701],
          [99.898881, 6.309035],
          [99.899417, 6.308376],
          [99.899089, 6.308043],
          [99.897625, 6.308895],
          [99.897021, 6.309752],
          [99.896424, 6.309881],
          [99.896012, 6.3096543],
          [99.895846263526195, 6.309658061484571],
          [99.8958226, 6.3096757],
          [99.8957048, 6.310062],
          [99.8951391, 6.310084],
          [99.895064, 6.3108265],
          [99.895006, 6.3115419],
          [99.8948259, 6.3119334],
          [99.8942832, 6.3120078],
          [99.8937057, 6.3118755],
          [99.8932503, 6.3116929],
          [99.8928497, 6.3113415],
          [99.8929016, 6.3108943],
          [99.8932313, 6.3104333],
          [99.8934341, 6.3103199],
          [99.893444, 6.309996],
          [99.8926578, 6.3093915],
          [99.8924393, 6.309243],
          [99.8924036, 6.3088041],
          [99.8920464, 6.3085061],
          [99.8916028, 6.3086194],
          [99.8915235, 6.3091308],
          [99.891601, 6.3094865],
          [99.8911335, 6.310181],
          [99.8905193, 6.3106789],
          [99.8900045, 6.3105375],
          [99.8895118, 6.3101562],
          [99.8894313, 6.309996],
          [99.8895167, 6.3097464],
          [99.8895794, 6.3098845],
          [99.8898393, 6.3098043],
          [99.889799, 6.3096796],
          [99.889687, 6.3096663],
          [99.8895615, 6.3097286],
          [99.8897497, 6.3095149],
          [99.8898348, 6.3091408],
          [99.8895817, 6.3086804],
          [99.8888088, 6.3084995],
          [99.8886475, 6.3082857],
          [99.8881815, 6.3078982],
          [99.887935, 6.3080497],
          [99.8867597, 6.3075323],
          [99.8862847, 6.3077112],
          [99.8862369, 6.3078715],
          [99.8865819, 6.3087578],
          [99.8866389, 6.3093471],
          [99.8864296, 6.3097019],
          [99.8863355, 6.3101339],
          [99.8860308, 6.3103387],
          [99.8855036, 6.3104865],
          [99.8850854, 6.3100448],
          [99.88477, 6.310209],
          [99.8844357, 6.3105436],
          [99.8844536, 6.310744],
          [99.8846059, 6.31098],
          [99.8843416, 6.3108821],
          [99.8841081, 6.3109259],
        ],
      ],
      [
        [
          [99.880251, 6.298],
          [99.880188, 6.2983965],
          [99.8803325, 6.298546],
          [99.880791, 6.2986813],
          [99.8811056, 6.2984389],
          [99.8812703, 6.2984704],
          [99.8814096, 6.2986478],
          [99.8816505, 6.298653],
          [99.8817899, 6.2984168],
          [99.8819167, 6.2983539],
          [99.88197, 6.298209],
          [99.8822558, 6.297766],
          [99.8821321, 6.2975319],
          [99.8818124, 6.2972179],
          [99.8813876, 6.296773],
          [99.880991, 6.296746],
          [99.8805891, 6.2970966],
          [99.8805291, 6.2975686],
          [99.880527299844289, 6.297571392474572],
          [99.8805226, 6.2976075],
          [99.880251, 6.298],
        ],
      ],
      [
        [
          [99.8627355, 6.2815473],
          [99.8623418, 6.2826682],
          [99.8621638, 6.284361],
          [99.8616859, 6.2846977],
          [99.8614238, 6.2852131],
          [99.8610107, 6.2857808],
          [99.8605801, 6.2871157],
          [99.860762, 6.2881165],
          [99.8611762, 6.2890136],
          [99.8620636, 6.2900853],
          [99.8632398, 6.2907495],
          [99.863623596170655, 6.29135815045115],
          [99.8643797, 6.2916311],
          [99.8647352, 6.2918217],
          [99.8651597, 6.2924475],
          [99.8656247, 6.2925627],
          [99.8660141, 6.292202],
          [99.8665475, 6.2914343],
          [99.8666355, 6.2908566],
          [99.8664782, 6.2902064],
          [99.8664746, 6.289636],
          [99.8667715, 6.2887883],
          [99.8668755, 6.2883164],
          [99.86677, 6.287968],
          [99.8662522, 6.2871535],
          [99.866349, 6.2866473],
          [99.8666957, 6.2860455],
          [99.8672316, 6.2855915],
          [99.8678168, 6.2853015],
          [99.8682953, 6.2857907],
          [99.8692999, 6.2862748],
          [99.869575253025602, 6.286385404575173],
          [99.8697114, 6.2857093],
          [99.8696294, 6.2851423],
          [99.8690439, 6.2847183],
          [99.8687622, 6.28415],
          [99.8682082, 6.2837093],
          [99.8677488, 6.2826103],
          [99.8671814, 6.2825351],
          [99.8658607, 6.2818001],
          [99.8655839, 6.2811685],
          [99.865444, 6.2811009],
          [99.8644525, 6.2809662],
          [99.8641345, 6.2805623],
          [99.8627355, 6.2815473],
        ],
      ],
      [
        [
          [99.868065505036313, 6.292503139744218],
          [99.867908, 6.2926417],
          [99.8681072, 6.2927547],
          [99.868231, 6.2927846],
          [99.8683735, 6.2928651],
          [99.8686254, 6.2929659],
          [99.8689015, 6.29292],
          [99.8692193, 6.2930762],
          [99.8693198, 6.293059],
          [99.8694148, 6.2930855],
          [99.869495082846854, 6.293078726134798],
          [99.8696761, 6.2928187],
          [99.8696193, 6.2925303],
          [99.8693351, 6.2923299],
          [99.8690214, 6.2924002],
          [99.8687753, 6.2925751],
          [99.868705843985481, 6.292566543099077],
          [99.8686714, 6.2925847],
          [99.8684642, 6.2925064],
          [99.868065505036313, 6.292503139744218],
        ],
      ],
      [
        [
          [99.8964396, 6.3222197],
          [99.8963677, 6.3223713],
          [99.8964367, 6.3224285],
          [99.8964684, 6.3225716],
          [99.8965634, 6.322646],
          [99.8966814, 6.3226088],
          [99.8966958, 6.3225687],
          [99.8966555, 6.3225401],
          [99.8966238, 6.3224772],
          [99.8967073, 6.3225229],
          [99.8967706, 6.3224686],
          [99.8968484, 6.3224056],
          [99.8967937, 6.3222769],
          [99.8967505, 6.3221853],
          [99.8966526, 6.3221338],
          [99.8966008, 6.3221796],
          [99.8964396, 6.3222197],
        ],
      ],
      [
        [
          [99.6405954, 6.4336903],
          [99.6404969, 6.4341083],
          [99.640726, 6.4351352],
          [99.6414698, 6.4356856],
          [99.6417774, 6.4357743],
          [99.6419204, 6.4362794],
          [99.6420078, 6.4365131],
          [99.642183, 6.4367093],
          [99.6426043, 6.4370326],
          [99.6434491, 6.437366],
          [99.6438677, 6.4373778],
          [99.6443188, 6.4372741],
          [99.6444848, 6.4373698],
          [99.6448024, 6.4377749],
          [99.6451275, 6.4379276],
          [99.6455411, 6.4378658],
          [99.6458563, 6.4377381],
          [99.6460995, 6.4375835],
          [99.6466805, 6.4376392],
          [99.6468996, 6.437748],
          [99.6470072, 6.4373804],
          [99.6473363, 6.4371875],
          [99.6475742, 6.4371198],
          [99.6476995, 6.4372517],
          [99.6479149, 6.4375387],
          [99.6481589, 6.437857],
          [99.6483644, 6.4376627],
          [99.64901, 6.4379444],
          [99.6495397, 6.4380974],
          [99.6498224, 6.4380947],
          [99.6501926, 6.437962],
          [99.6506456, 6.4376832],
          [99.6510447, 6.4374521],
          [99.6516139, 6.4368639],
          [99.6517225, 6.4366397],
          [99.6520889, 6.4364518],
          [99.6524459, 6.4364588],
          [99.6529477, 6.4364841],
          [99.6533423, 6.4365091],
          [99.6536752, 6.4363732],
          [99.654089, 6.436079],
          [99.6543068, 6.4358375],
          [99.6546311, 6.4355192],
          [99.6548671, 6.4351839],
          [99.6548521, 6.4347236],
          [99.6549903, 6.4342743],
          [99.6551238, 6.4337452],
          [99.6554594, 6.4330879],
          [99.6557354, 6.4329024],
          [99.6560854, 6.4328489],
          [99.6567868, 6.4328012],
          [99.6571881, 6.4329667],
          [99.6577232, 6.4329379],
          [99.6579758, 6.4327782],
          [99.6587279, 6.4328041],
          [99.658869, 6.4325818],
          [99.6591158, 6.4321852],
          [99.6592298, 6.4318216],
          [99.6595312, 6.4317036],
          [99.6599537, 6.4317028],
          [99.6605186, 6.4318584],
          [99.6610102, 6.4320335],
          [99.6612519, 6.4322803],
          [99.6614286, 6.4323723],
          [99.6618315, 6.4324067],
          [99.6625396, 6.4332116],
          [99.6625729, 6.4334789],
          [99.6623424, 6.4340646],
          [99.6625012, 6.4342484],
          [99.6628572, 6.4342364],
          [99.6628939, 6.4336895],
          [99.6628526, 6.4321975],
          [99.6630157, 6.4309639],
          [99.6632973, 6.4302059],
          [99.6638423, 6.4294383],
          [99.6643659, 6.429103],
          [99.6650391, 6.4288322],
          [99.6653095, 6.4284148],
          [99.6652211, 6.427916],
          [99.6654159, 6.4275798],
          [99.6654303, 6.4272929],
          [99.6657575, 6.4269392],
          [99.6657671, 6.426638],
          [99.6660846, 6.426442],
          [99.6666956, 6.4262077],
          [99.6672056, 6.4260021],
          [99.6674461, 6.4258109],
          [99.6675423, 6.426179],
          [99.669154, 6.4262507],
          [99.6703374, 6.426485],
          [99.6712034, 6.4267957],
          [99.6725553, 6.4274411],
          [99.6735655, 6.4280148],
          [99.674138, 6.4286363],
          [99.674883, 6.4295355],
          [99.6753287, 6.4302521],
          [99.6755702, 6.4308504],
          [99.6756688, 6.431415],
          [99.6754477, 6.4316144],
          [99.6753185, 6.4322601],
          [99.6754511, 6.4324156],
          [99.6752266, 6.4327671],
          [99.6753389, 6.4334635],
          [99.6754681, 6.4340314],
          [99.6754375, 6.4345655],
          [99.6758593, 6.4348562],
          [99.6765431, 6.4349035],
          [99.6769377, 6.4349069],
          [99.6773595, 6.4351165],
          [99.6776385, 6.4354275],
          [99.6775671, 6.4357114],
          [99.6772439, 6.4361915],
          [99.6772133, 6.4363672],
          [99.6774344, 6.4364855],
          [99.6777916, 6.4364517],
          [99.6780739, 6.4363131],
          [99.6784005, 6.43609],
          [99.6785808, 6.4358669],
          [99.6787407, 6.4354647],
          [99.6790945, 6.435441],
          [99.679387, 6.4354579],
          [99.6795707, 6.435343],
          [99.679768, 6.4352382],
          [99.6799177, 6.4353497],
          [99.680166, 6.4354681],
          [99.6806729, 6.4352314],
          [99.6811628, 6.4349475],
          [99.6813839, 6.4345554],
          [99.6814995, 6.4342004],
          [99.6816424, 6.4338624],
          [99.682037, 6.4336731],
          [99.6824384, 6.4336832],
          [99.6828092, 6.4336393],
          [99.68318, 6.4339604],
          [99.6831883, 6.4341029],
          [99.6831936, 6.4341937],
          [99.6838638, 6.4346601],
          [99.6842584, 6.4348494],
          [99.6847381, 6.4348461],
          [99.6850476, 6.4347818],
          [99.6848435, 6.4342342],
          [99.6847147, 6.4340078],
          [99.6847755, 6.4339807],
          [99.6852823, 6.4349948],
          [99.6854626, 6.4350286],
          [99.6855783, 6.4347818],
          [99.6859525, 6.4346939],
          [99.6862281, 6.4344235],
          [99.6862178, 6.4340551],
          [99.6860648, 6.4335886],
          [99.6862383, 6.4333925],
          [99.6867996, 6.4331356],
          [99.687201, 6.4330071],
          [99.6876262, 6.4330139],
          [99.6882011, 6.4330477],
          [99.6886161, 6.4329598],
          [99.6889869, 6.432828],
          [99.6896707, 6.4329125],
          [99.6900313, 6.4331221],
          [99.6904769, 6.4332269],
          [99.691113, 6.4335784],
          [99.691807, 6.4339705],
          [99.6923887, 6.4342444],
          [99.6930895, 6.4343458],
          [99.6934841, 6.4342139],
          [99.6938583, 6.4342579],
          [99.6944638, 6.434383],
          [99.6955932, 6.4349103],
          [99.6964165, 6.4351503],
          [99.69742, 6.4353396],
          [99.6978928, 6.4354275],
          [99.698944, 6.4354985],
          [99.6995019, 6.4355593],
          [99.7000394, 6.4355965],
          [99.7004918, 6.4356878],
          [99.7006925, 6.4356134],
          [99.7011144, 6.4357283],
          [99.7016484, 6.4359819],
          [99.7020839, 6.4363064],
          [99.7024138, 6.4366579],
          [99.7029037, 6.4367019],
          [99.7031418, 6.4366951],
          [99.7035671, 6.4366647],
          [99.7039481, 6.4366951],
          [99.7042644, 6.4368168],
          [99.7044753, 6.436925],
          [99.7046829, 6.4368608],
          [99.7049414, 6.4368608],
          [99.7052476, 6.4368574],
          [99.7053734, 6.4367188],
          [99.7054006, 6.4365126],
          [99.7054483, 6.4363267],
          [99.7057544, 6.4360664],
          [99.7060436, 6.4358636],
          [99.7063395, 6.4357757],
          [99.7066525, 6.4356202],
          [99.7068328, 6.4356033],
          [99.7070913, 6.4355999],
          [99.7072512, 6.4355154],
          [99.7073805, 6.4355627],
          [99.7074761, 6.4356835],
          [99.7078701, 6.4355002],
          [99.7080232, 6.4353176],
          [99.7081469, 6.4352142],
          [99.7080342, 6.4351782],
          [99.708104, 6.4351116],
          [99.7082542, 6.4350876],
          [99.7086219, 6.4351148],
          [99.7090607, 6.435081],
          [99.7092342, 6.4350235],
          [99.7095744, 6.4351317],
          [99.7100064, 6.4351993],
          [99.7105133, 6.4352805],
          [99.7107446, 6.43525],
          [99.7112107, 6.4350675],
          [99.7114726, 6.4351824],
          [99.7119965, 6.4355374],
          [99.7121632, 6.4354698],
          [99.7124693, 6.4352872],
          [99.7127006, 6.4350641],
          [99.7129354, 6.4348545],
          [99.713296, 6.4349289],
          [99.7137484, 6.4351554],
          [99.7141804, 6.4351554],
          [99.7144492, 6.4350776],
          [99.7150751, 6.4348883],
          [99.7154765, 6.4348647],
          [99.7160276, 6.4347227],
          [99.7165039, 6.4344083],
          [99.7172421, 6.4339452],
          [99.7175074, 6.4340365],
          [99.7179871, 6.4339891],
          [99.7186606, 6.4338404],
          [99.7192389, 6.4334686],
          [99.719443, 6.4333976],
          [99.7196539, 6.4334179],
          [99.720137, 6.4335801],
          [99.7204193, 6.4335531],
          [99.7206711, 6.4333334],
          [99.7210691, 6.4333672],
          [99.7213753, 6.4331711],
          [99.7218209, 6.432975],
          [99.7220692, 6.432948],
          [99.7223958, 6.4326877],
          [99.7227802, 6.4325457],
          [99.7231612, 6.4325897],
          [99.7235422, 6.4324747],
          [99.7235456, 6.4322111],
          [99.723566, 6.4318392],
          [99.7236579, 6.4316668],
          [99.7239334, 6.4314708],
          [99.7243382, 6.4313829],
          [99.7248961, 6.4313457],
          [99.7252635, 6.4312882],
          [99.7254166, 6.4312409],
          [99.725433, 6.431003],
          [99.7257636, 6.4309052],
          [99.7263025, 6.4307284],
          [99.7267852, 6.4306494],
          [99.7270886, 6.4307629],
          [99.7274628, 6.4309522],
          [99.7277587, 6.4309927],
          [99.7282758, 6.4310029],
          [99.7286228, 6.4310299],
          [99.7289357, 6.4312293],
          [99.7291262, 6.4315708],
          [99.7295141, 6.4317567],
          [99.7302454, 6.4316553],
          [99.7303611, 6.4314491],
          [99.7307081, 6.4313341],
          [99.731004, 6.4314119],
          [99.7314769, 6.4315403],
          [99.7317558, 6.4317229],
          [99.7319225, 6.4319257],
          [99.7320246, 6.4320981],
          [99.7320654, 6.4322502],
          [99.7322321, 6.4321691],
          [99.7322865, 6.4318919],
          [99.7321062, 6.4318108],
          [99.7319259, 6.4315403],
          [99.7320042, 6.4312091],
          [99.7323614, 6.4309353],
          [99.7329975, 6.4309082],
          [99.7333581, 6.4307899],
          [99.733535, 6.4306074],
          [99.7336438, 6.4302997],
          [99.7335588, 6.4300665],
          [99.7333581, 6.4297115],
          [99.7328818, 6.4295696],
          [99.7327798, 6.4294073],
          [99.7325825, 6.4291741],
          [99.7322219, 6.4292079],
          [99.7318511, 6.4292079],
          [99.7316334, 6.4293194],
          [99.731232, 6.429191],
          [99.7311843, 6.4290118],
          [99.7309258, 6.4289307],
          [99.7309156, 6.4285994],
          [99.7307183, 6.4283695],
          [99.7304087, 6.4282681],
          [99.7303237, 6.4280957],
          [99.7303101, 6.4278219],
          [99.7297284, 6.4275853],
          [99.7297726, 6.42745],
          [99.7299019, 6.4272675],
          [99.7296195, 6.4269869],
          [99.7293746, 6.4269937],
          [99.7291228, 6.427041],
          [99.7290922, 6.4269903],
          [99.7291909, 6.4267537],
          [99.7292997, 6.4263311],
          [99.7294086, 6.4260742],
          [99.7292283, 6.4256077],
          [99.7290888, 6.4253778],
          [99.7290718, 6.4251649],
          [99.7291977, 6.4249215],
          [99.7291807, 6.4246341],
          [99.7293133, 6.4243975],
          [99.7292929, 6.4241305],
          [99.7292113, 6.4240054],
          [99.7291943, 6.4238397],
          [99.7291194, 6.4237823],
          [99.7287895, 6.4234645],
          [99.7287895, 6.4233056],
          [99.7284459, 6.4230825],
          [99.728599, 6.4230251],
          [99.7286126, 6.4228966],
          [99.7284425, 6.4228222],
          [99.7284595, 6.4226228],
          [99.7284153, 6.4222239],
          [99.7282486, 6.4221326],
          [99.7282146, 6.4220549],
          [99.72832, 6.4220143],
          [99.7284119, 6.4218757],
          [99.7283336, 6.4218318],
          [99.7283574, 6.4215038],
          [99.7283983, 6.4212943],
          [99.7284255, 6.4211895],
          [99.7285309, 6.421159],
          [99.728582, 6.4212909],
          [99.7287384, 6.4212233],
          [99.7286466, 6.4210982],
          [99.7285241, 6.4210847],
          [99.7285173, 6.4209596],
          [99.7287486, 6.4207432],
          [99.728718, 6.4204965],
          [99.7286194, 6.4202091],
          [99.728582, 6.4198508],
          [99.7286092, 6.4197359],
          [99.728735, 6.4196852],
          [99.7287963, 6.4193403],
          [99.7288201, 6.4190192],
          [99.7287623, 6.4186981],
          [99.7285207, 6.418311],
          [99.7284362, 6.4182391],
          [99.7278636, 6.4182168],
          [99.726943, 6.4172015],
          [99.7270103, 6.4168444],
          [99.7264826, 6.4164316],
          [99.7264377, 6.415963],
          [99.7271147, 6.4156427],
          [99.7274189, 6.4155992],
          [99.727684, 6.4155614],
          [99.7282678, 6.4156841],
          [99.7289345, 6.4159685],
          [99.729133, 6.4153646],
          [99.7299856, 6.4158961],
          [99.7312319, 6.4159519],
          [99.7315335, 6.4154846],
          [99.7316024, 6.4146465],
          [99.731434, 6.4137985],
          [99.731894, 6.4122861],
          [99.7318494, 6.4115113],
          [99.7323459, 6.4102735],
          [99.7328394, 6.4097384],
          [99.7332702, 6.4092712],
          [99.7343659, 6.4108554],
          [99.735859, 6.413471],
          [99.7368911, 6.4140681],
          [99.7379646, 6.4174385],
          [99.7393106, 6.4200818],
          [99.7394847, 6.4200985],
          [99.7395033, 6.4204665],
          [99.7389774, 6.4210288],
          [99.7386089, 6.4219673],
          [99.7384405, 6.4224861],
          [99.738637, 6.4229603],
          [99.7390719, 6.4229119],
          [99.7397934, 6.423758],
          [99.7400067, 6.4243996],
          [99.7398578, 6.4255571],
          [99.739552, 6.4257217],
          [99.7395913, 6.4261066],
          [99.7401302, 6.4262739],
          [99.7407814, 6.4263967],
          [99.7414449, 6.4261661],
          [99.7422205, 6.4259379],
          [99.743549, 6.4260508],
          [99.7439699, 6.4264535],
          [99.7445409, 6.4269067],
          [99.7449317, 6.4266718],
          [99.7456093, 6.4267537],
          [99.7458451, 6.4269824],
          [99.7459578, 6.4273284],
          [99.7458844, 6.4275793],
          [99.7459629, 6.4280312],
          [99.7463222, 6.4284217],
          [99.7465973, 6.4287062],
          [99.7468497, 6.4286611],
          [99.7467769, 6.4289907],
          [99.7470857, 6.4293867],
          [99.7473422, 6.4298616],
          [99.7473046, 6.4304243],
          [99.7472373, 6.43072],
          [99.747344, 6.430836],
          [99.7476667, 6.4309006],
          [99.7479109, 6.430759],
          [99.7480176, 6.4304857],
          [99.7479168, 6.4300182],
          [99.7477762, 6.4296266],
          [99.7481411, 6.4293979],
          [99.748587, 6.429206],
          [99.7491347, 6.428907],
          [99.7499795, 6.4291144],
          [99.7504147, 6.429225],
          [99.7504068, 6.4290531],
          [99.7502013, 6.4284217],
          [99.7502743, 6.4275905],
          [99.7498982, 6.4275681],
          [99.7496287, 6.4273394],
          [99.7491419, 6.4272518],
          [99.749275, 6.426495],
          [99.7495782, 6.4258779],
          [99.7499936, 6.4256436],
          [99.7503167, 6.4250236],
          [99.7507178, 6.4249853],
          [99.7509817, 6.4252531],
          [99.7514034, 6.4254091],
          [99.752667, 6.424862],
          [99.7536819, 6.4244151],
          [99.7540019, 6.4245056],
          [99.7542264, 6.4248124],
          [99.75459, 6.4249267],
          [99.7546418, 6.4247677],
          [99.7545071, 6.4245892],
          [99.754631, 6.4239737],
          [99.7543836, 6.4233899],
          [99.754471, 6.423218],
          [99.7549281, 6.4228766],
          [99.7552313, 6.4226925],
          [99.7556025, 6.4229127],
          [99.7552762, 6.4247956],
          [99.7554727, 6.4250578],
          [99.7556467, 6.424857],
          [99.755933, 6.4234177],
          [99.7561407, 6.4228376],
          [99.7565194, 6.422661],
          [99.7567579, 6.4226068],
          [99.7570881, 6.4225257],
          [99.7575348, 6.4224651],
          [99.758119, 6.422512],
          [99.760306, 6.4230088],
          [99.7638069, 6.4241709],
          [99.764309, 6.4245653],
          [99.7642349, 6.4252146],
          [99.7631406, 6.4305922],
          [99.7616686, 6.4309707],
          [99.761704, 6.4313151],
          [99.7635934, 6.430766],
          [99.7637264, 6.4310091],
          [99.7647907, 6.4306892],
          [99.7646523, 6.4301871],
          [99.7634067, 6.4305176],
          [99.7641813, 6.4264631],
          [99.765443, 6.4267691],
          [99.7656147, 6.4259726],
          [99.766679, 6.4262339],
          [99.7669483, 6.4255899],
          [99.7678774, 6.426462],
          [99.7684846, 6.4272072],
          [99.7685855, 6.428041],
          [99.767857, 6.428929],
          [99.7687089, 6.4287638],
          [99.7688151, 6.4278917],
          [99.7692442, 6.4275036],
          [99.770189, 6.427421],
          [99.7708965, 6.4271486],
          [99.770928, 6.4263],
          [99.7715076, 6.4258763],
          [99.7719824, 6.42577],
          [99.7721437, 6.4252802],
          [99.7718665, 6.4248933],
          [99.7719427, 6.4245748],
          [99.7716086, 6.424229],
          [99.7715864, 6.4240633],
          [99.771255, 6.4239863],
          [99.7711371, 6.4237269],
          [99.7709883, 6.4236154],
          [99.770746, 6.4233436],
          [99.7707992, 6.423212],
          [99.7709663, 6.4231306],
          [99.771081, 6.4234201],
          [99.7711652, 6.4236126],
          [99.7713729, 6.4238078],
          [99.7715497, 6.4238915],
          [99.7716753, 6.4238822],
          [99.7718269, 6.4237316],
          [99.7720391, 6.4238772],
          [99.7720907, 6.4240665],
          [99.772182, 6.4240468],
          [99.7721105, 6.4238062],
          [99.7719557, 6.4235892],
          [99.7718942, 6.423408],
          [99.7718043, 6.4232665],
          [99.7719983, 6.4229733],
          [99.7722903, 6.4226281],
          [99.7730131, 6.4220314],
          [99.7738623, 6.4216391],
          [99.774417, 6.4216959],
          [99.7747689, 6.4217142],
          [99.7754373, 6.4214872],
          [99.7760913, 6.4214584],
          [99.7761878, 6.421469],
          [99.776297, 6.4215453],
          [99.7766592, 6.4216944],
          [99.777591705322266, 6.421690935890364],
          [99.7777898, 6.4216902],
          [99.7800113, 6.4221773],
          [99.7809921, 6.4224688],
          [99.781784, 6.4222591],
          [99.7821037, 6.4223851],
          [99.7826426, 6.4221285],
          [99.782957, 6.421777],
          [99.7833948, 6.4218496],
          [99.7837485, 6.4220002],
          [99.7843548, 6.4220448],
          [99.7847903, 6.4222352],
          [99.7853597, 6.422307],
          [99.7859323, 6.4226027],
          [99.7863364, 6.4229932],
          [99.7867912, 6.4230155],
          [99.7871673, 6.4232219],
          [99.7876894, 6.4234952],
          [99.7878417, 6.4232828],
          [99.7879757, 6.4229876],
          [99.7882058, 6.4229987],
          [99.7884641, 6.4233056],
          [99.7887391, 6.4233948],
          [99.7891658, 6.4237016],
          [99.7896674, 6.424133],
          [99.790213, 6.4246599],
          [99.7909632, 6.4246957],
          [99.7911206, 6.4243545],
          [99.7918333, 6.4244893],
          [99.792114, 6.4246343],
          [99.7926361, 6.4247068],
          [99.7933413, 6.4253861],
          [99.793961, 6.4261349],
          [99.7945269, 6.4270011],
          [99.7946841, 6.4272718],
          [99.7948312, 6.4276121],
          [99.7951123, 6.4281884],
          [99.7952429, 6.4284767],
          [99.7953522, 6.4287419],
          [99.7954262, 6.4291529],
          [99.7956536, 6.4296178],
          [99.795532, 6.4297567],
          [99.7957831, 6.4301484],
          [99.796379, 6.4318208],
          [99.7966453, 6.4327486],
          [99.796872, 6.4330014],
          [99.7974416, 6.4338704],
          [99.7974662, 6.4343059],
          [99.7975178, 6.4344243],
          [99.7976672, 6.4344653],
          [99.7978195, 6.4345071],
          [99.7979017, 6.434844],
          [99.798407, 6.4353552],
          [99.7983785, 6.4358418],
          [99.7987364, 6.4360533],
          [99.7989164, 6.4360763],
          [99.7995662, 6.4363847],
          [99.8008441, 6.4374864],
          [99.8021604, 6.4393439],
          [99.8032792, 6.4412753],
          [99.8058076, 6.4469329],
          [99.8059271, 6.4476352],
          [99.8062609, 6.4481044],
          [99.8076206, 6.4492217],
          [99.8081497, 6.4496981],
          [99.8083858, 6.4500946],
          [99.8099811, 6.4515573],
          [99.812349, 6.4544727],
          [99.8126031, 6.4550456],
          [99.8122693, 6.4552236],
          [99.8123752, 6.455353],
          [99.8132707, 6.4546977],
          [99.8138976, 6.4550699],
          [99.8141784, 6.4554943],
          [99.8143604, 6.4556244],
          [99.8145924, 6.4554592],
          [99.8147492, 6.4552026],
          [99.8148413, 6.4549223],
          [99.8146741, 6.4545375],
          [99.8147607, 6.4540242],
          [99.8150082, 6.4540986],
          [99.8151924, 6.4540414],
          [99.8154803, 6.4537496],
          [99.8154285, 6.4535151],
          [99.815227, 6.4532519],
          [99.8155782, 6.4527171],
          [99.8158611, 6.4524848],
          [99.8167756, 6.4522165],
          [99.8172477, 6.4521536],
          [99.8180133, 6.4521479],
          [99.8186142, 6.4522526],
          [99.8193202, 6.4525369],
          [99.8198786, 6.4528973],
          [99.821147, 6.4539698],
          [99.8219626, 6.4548651],
          [99.8224347, 6.4555515],
          [99.8226246, 6.4560607],
          [99.8234315, 6.456598],
          [99.8249304, 6.4584573],
          [99.8251692, 6.4589495],
          [99.8253937, 6.4591726],
          [99.8259176, 6.4591668],
          [99.8262918, 6.4591382],
          [99.8268056, 6.4595136],
          [99.8271899, 6.459756],
          [99.8275065, 6.460248],
          [99.8277023, 6.4604425],
          [99.8281838, 6.4606526],
          [99.8284852, 6.4605397],
          [99.8286153, 6.4602427],
          [99.8289458, 6.4599162],
          [99.8290746, 6.4594837],
          [99.8290573, 6.4591119],
          [99.8290746, 6.4588602],
          [99.8299955, 6.4575036],
          [99.8309435, 6.4563428],
          [99.8310445, 6.45626],
          [99.8323058, 6.4549141],
          [99.8327475, 6.4541066],
          [99.8336449, 6.4538449],
          [99.8338522, 6.4535246],
          [99.8338752, 6.4527809],
          [99.8339213, 6.4523233],
          [99.8342206, 6.4520144],
          [99.8348539, 6.4516712],
          [99.8352914, 6.4514995],
          [99.835798, 6.4514195],
          [99.8361435, 6.4514538],
          [99.8366961, 6.4512707],
          [99.8370185, 6.4511792],
          [99.8371797, 6.4507673],
          [99.8376518, 6.4509046],
          [99.8379396, 6.4507216],
          [99.8384002, 6.4510991],
          [99.8389413, 6.4510076],
          [99.8392997, 6.4507722],
          [99.8397557, 6.4505538],
          [99.8402116, 6.4502626],
          [99.8406757, 6.4498176],
          [99.8407001, 6.4495102],
          [99.8404721, 6.4489277],
          [99.8406512, 6.4488144],
          [99.8408141, 6.4487335],
          [99.841099, 6.4487255],
          [99.8414165, 6.448596],
          [99.8414735, 6.4483857],
          [99.8413921, 6.4481025],
          [99.8415794, 6.4480216],
          [99.8417829, 6.4480863],
          [99.8423609, 6.448054],
          [99.8430123, 6.4479488],
          [99.8434601, 6.4477708],
          [99.8438264, 6.447334],
          [99.8440951, 6.4468567],
          [99.8440625, 6.4465978],
          [99.8439323, 6.4464926],
          [99.8439404, 6.446347],
          [99.8443638, 6.4459587],
          [99.8447301, 6.4456512],
          [99.8450069, 6.4451335],
          [99.8451128, 6.4447452],
          [99.8455036, 6.4447209],
          [99.8456501, 6.4449879],
          [99.8455443, 6.4452306],
          [99.8455199, 6.4456108],
          [99.8455931, 6.4459344],
          [99.8454466, 6.4462014],
          [99.8454466, 6.446711],
          [99.8455524, 6.4475362],
          [99.8454384, 6.4479407],
          [99.8457641, 6.4478841],
          [99.8457153, 6.4480378],
          [99.8455117, 6.448143],
          [99.8453326, 6.448596],
          [99.8451454, 6.448774],
          [99.8452838, 6.4489601],
          [99.8455117, 6.4490814],
          [99.8455769, 6.4494778],
          [99.845585, 6.4497933],
          [99.8454303, 6.4504567],
          [99.8454547, 6.4506104],
          [99.8453489, 6.450748],
          [99.8450965, 6.4508774],
          [99.8448767, 6.4512172],
          [99.8446324, 6.4516459],
          [99.8443556, 6.4520019],
          [99.8440788, 6.4524226],
          [99.8439974, 6.4528271],
          [99.8437776, 6.4531911],
          [99.8435822, 6.4534015],
          [99.8435333, 6.453628],
          [99.8433135, 6.4537412],
          [99.8431832, 6.4536361],
          [99.8428901, 6.4536118],
          [99.8426133, 6.4537736],
          [99.8425645, 6.4539354],
          [99.8427192, 6.4542024],
          [99.8430367, 6.454437],
          [99.8430855, 6.4547039],
          [99.8430774, 6.4548415],
          [99.8427029, 6.4550761],
          [99.8426948, 6.4552702],
          [99.8428169, 6.4553431],
          [99.8431344, 6.4552945],
          [99.8432484, 6.4556828],
          [99.8431914, 6.4558365],
          [99.8425191, 6.4567893],
          [99.841591, 6.4585205],
          [99.8410211, 6.4598958],
          [99.8410374, 6.4601708],
          [99.8418678, 6.4602841],
          [99.8421284, 6.4604621],
          [99.841933, 6.4610607],
          [99.8408257, 6.4619021],
          [99.8406629, 6.4614329],
          [99.8405815, 6.4609636],
          [99.8407443, 6.4603974],
          [99.8401255, 6.460818],
          [99.8393277, 6.4613843],
          [99.8388392, 6.4614329],
          [99.8381879, 6.4612225],
          [99.8378459, 6.4608827],
          [99.8374877, 6.4602517],
          [99.8371783, 6.4596046],
          [99.836641, 6.4601061],
          [99.8361525, 6.4600899],
          [99.835778, 6.460009],
          [99.8358105, 6.4594913],
          [99.8355174, 6.4591677],
          [99.8352732, 6.4595398],
          [99.8350127, 6.4599767],
          [99.8354686, 6.4604135],
          [99.8357291, 6.4606239],
          [99.8352569, 6.4613843],
          [99.8345404, 6.4614329],
          [99.8336612, 6.4617726],
          [99.832961, 6.4620962],
          [99.8322771, 6.462258],
          [99.8320166, 6.4620477],
          [99.8327167, 6.4617403],
          [99.8328307, 6.4613843],
          [99.8326218, 6.4612004],
          [99.8323178, 6.4612077],
          [99.8320166, 6.4611416],
          [99.8317723, 6.4607048],
          [99.8316651, 6.4606631],
          [99.8313164, 6.4611254],
          [99.8306162, 6.4614005],
          [99.8303231, 6.4618535],
          [99.8297858, 6.4624198],
          [99.8294601, 6.4625331],
          [99.829395, 6.4621609],
          [99.8290856, 6.4623875],
          [99.8288251, 6.462436],
          [99.8285646, 6.4616594],
          [99.8280924, 6.4618697],
          [99.8278318, 6.4622257],
          [99.8277178, 6.4627272],
          [99.8274637, 6.4630571],
          [99.8271805, 6.4632935],
          [99.8266594, 6.4629376],
          [99.8262361, 6.4630185],
          [99.8262849, 6.4634877],
          [99.8259267, 6.4638598],
          [99.8258153, 6.4641471],
          [99.8257868, 6.4643857],
          [99.8255141, 6.4646932],
          [99.8252495, 6.4649318],
          [99.8250297, 6.4651705],
          [99.8248628, 6.4653565],
          [99.8248587, 6.4654779],
          [99.8247447, 6.465583],
          [99.8247651, 6.4656963],
          [99.8248831, 6.4656801],
          [99.8249238, 6.4655588],
          [99.8249971, 6.4655143],
          [99.8253146, 6.465579],
          [99.8254652, 6.46549],
          [99.8256159, 6.4655304],
          [99.8258072, 6.4656639],
          [99.8259049, 6.4657772],
          [99.8259252, 6.4664648],
          [99.8260189, 6.4665093],
          [99.8260189, 6.4668814],
          [99.8259293, 6.4670109],
          [99.8257013, 6.4674113],
          [99.8256484, 6.4676823],
          [99.8254693, 6.4676783],
          [99.8253187, 6.4679493],
          [99.8251966, 6.4681758],
          [99.8249645, 6.4684266],
          [99.8245656, 6.46856],
          [99.8243743, 6.4687178],
          [99.8243091, 6.4689605],
          [99.8241219, 6.4690333],
          [99.8241382, 6.4691546],
          [99.8240853, 6.4692315],
          [99.8239428, 6.4691991],
          [99.8238369, 6.4693367],
          [99.8236782, 6.4692881],
          [99.8235968, 6.4694054],
          [99.8233892, 6.469365],
          [99.8233484, 6.4692558],
          [99.8231368, 6.4692881],
          [99.822807, 6.4691668],
          [99.8226727, 6.4689039],
          [99.822343, 6.4686207],
          [99.8217934, 6.4680019],
          [99.8212927, 6.4677066],
          [99.8212316, 6.4672131],
          [99.8211299, 6.46693],
          [99.8212276, 6.4668814],
          [99.8212032, 6.4667196],
          [99.8214108, 6.4664608],
          [99.8215207, 6.46621],
          [99.8215085, 6.4659835],
          [99.8215654, 6.4658904],
          [99.821598, 6.4657246],
          [99.8212316, 6.4650248],
          [99.8213171, 6.4647053],
          [99.8214678, 6.4643655],
          [99.8215858, 6.4640217],
          [99.8214433, 6.4638154],
          [99.8212479, 6.46369],
          [99.820967, 6.4636496],
          [99.8207757, 6.4634473],
          [99.8205722, 6.4634392],
          [99.8203117, 6.4635727],
          [99.8201651, 6.4637669],
          [99.8200471, 6.4638882],
          [99.8199453, 6.4638518],
          [99.8196522, 6.4640015],
          [99.8194527, 6.4641754],
          [99.8193306, 6.4644302],
          [99.8190619, 6.4647093],
          [99.8186671, 6.4648914],
          [99.8189642, 6.46513],
          [99.8189561, 6.4653848],
          [99.8189317, 6.4657529],
          [99.8189846, 6.4661372],
          [99.8189724, 6.4664001],
          [99.8188299, 6.4665659],
          [99.8187566, 6.4666671],
          [99.8189276, 6.4668855],
          [99.8189113, 6.4670918],
          [99.8190171, 6.4673506],
          [99.8187607, 6.4676257],
          [99.818606, 6.4678037],
          [99.8187078, 6.4679695],
          [99.8188787, 6.4681677],
          [99.8190049, 6.4683133],
          [99.8192736, 6.4685034],
          [99.8194731, 6.4685034],
          [99.8196033, 6.4684549],
          [99.8197295, 6.468281],
          [99.8196929, 6.4685034],
          [99.8197906, 6.4685843],
          [99.8201936, 6.4687502],
          [99.8203076, 6.4686854],
          [99.8203971, 6.4685034],
          [99.8205763, 6.4683902],
          [99.820678, 6.4682365],
          [99.8208205, 6.4682769],
          [99.820906, 6.4684144],
          [99.8208246, 6.4685479],
          [99.820678, 6.468738],
          [99.8207798, 6.4690657],
          [99.8209182, 6.4693569],
          [99.8209955, 6.4695429],
          [99.8212235, 6.4695429],
          [99.8213741, 6.4696238],
          [99.8214067, 6.4697775],
          [99.8212601, 6.4698504],
          [99.8213049, 6.4699717],
          [99.8214637, 6.4700971],
          [99.8215573, 6.47036],
          [99.8216998, 6.4703843],
          [99.8218097, 6.4708616],
          [99.82194, 6.4711204],
          [99.8223145, 6.4713995],
          [99.8224407, 6.4713267],
          [99.8225506, 6.471169],
          [99.8226035, 6.4713833],
          [99.8225791, 6.4716422],
          [99.8226238, 6.4719173],
          [99.8225465, 6.4721761],
          [99.8228844, 6.4728274],
          [99.8229861, 6.4731954],
          [99.8230228, 6.47343],
          [99.8229129, 6.4735595],
          [99.8230838, 6.4739275],
          [99.8231001, 6.4741419],
          [99.8231571, 6.4743522],
          [99.823324, 6.4743886],
          [99.8233037, 6.4747527],
          [99.8233566, 6.4748457],
          [99.823609, 6.4750318],
          [99.8236619, 6.4751369],
          [99.8235846, 6.4751976],
          [99.8236619, 6.4754363],
          [99.8236538, 6.4757517],
          [99.8239794, 6.4760875],
          [99.8241382, 6.4761724],
          [99.8242399, 6.4759419],
          [99.8242766, 6.4762129],
          [99.8243743, 6.4762776],
          [99.824533, 6.4763989],
          [99.8245615, 6.4766416],
          [99.8247081, 6.4768398],
          [99.8248506, 6.4769369],
          [99.8248506, 6.4770178],
          [99.8248953, 6.4770825],
          [99.824818, 6.477216],
          [99.824875, 6.4773858],
          [99.8250093, 6.4775395],
          [99.8252047, 6.4775881],
          [99.825449, 6.4775112],
          [99.8256566, 6.4773778],
          [99.8261369, 6.4771391],
          [99.8264178, 6.4770461],
          [99.8267964, 6.4770987],
          [99.8271465, 6.476949],
          [99.8275413, 6.4768074],
          [99.8278141, 6.4766861],
          [99.8278833, 6.4764879],
          [99.8278751, 6.4763059],
          [99.8280502, 6.4762452],
          [99.8282008, 6.476314],
          [99.8283473, 6.4762371],
          [99.8284532, 6.4760875],
          [99.8283311, 6.4758124],
          [99.8282415, 6.4755495],
          [99.8281031, 6.4753473],
          [99.8284532, 6.4750682],
          [99.8288196, 6.4748659],
          [99.8291127, 6.4746839],
          [99.8292877, 6.4746394],
          [99.8294994, 6.4746839],
          [99.8297192, 6.4747082],
          [99.8298657, 6.474599],
          [99.829939, 6.4746799],
          [99.8302565, 6.4747689],
          [99.8303626, 6.474705],
          [99.8303626, 6.4745934],
          [99.8301467, 6.4742131],
          [99.8302705, 6.4740357],
          [99.8303914, 6.4740529],
          [99.8307915, 6.4739528],
          [99.8308088, 6.4737697],
          [99.8307598, 6.4735466],
          [99.8304519, 6.4732149],
          [99.8304173, 6.473049],
          [99.8305785, 6.4728745],
          [99.8308347, 6.4726858],
          [99.8311398, 6.4726085],
          [99.8314593, 6.4724798],
          [99.831632, 6.472374],
          [99.8316982, 6.4721509],
          [99.8316004, 6.4719049],
          [99.8316378, 6.4717505],
          [99.8315658, 6.4716447],
          [99.8316378, 6.4715474],
          [99.8318047, 6.4714988],
          [99.8318105, 6.471596],
          [99.8318162, 6.471699],
          [99.831917, 6.4716761],
          [99.8321185, 6.4715932],
          [99.8322653, 6.4713901],
          [99.8324524, 6.4713529],
          [99.832533, 6.4712042],
          [99.8326452, 6.4712071],
          [99.8330396, 6.4712099],
          [99.8331691, 6.4711098],
          [99.8332555, 6.4707409],
          [99.8332871, 6.4704663],
          [99.833244, 6.4702632],
          [99.8332065, 6.4701059],
          [99.8333879, 6.46994],
          [99.8335376, 6.4697799],
          [99.83386, 6.4698027],
          [99.8339406, 6.4697112],
          [99.8340528, 6.4697141],
          [99.8342457, 6.4697884],
          [99.834427, 6.4697656],
          [99.8344817, 6.4696111],
          [99.8347523, 6.4696254],
          [99.8349682, 6.4696998],
          [99.8350286, 6.4697942],
          [99.83502, 6.4699572],
          [99.8349365, 6.4700773],
          [99.8348933, 6.4702403],
          [99.8347782, 6.4704777],
          [99.8347206, 6.4707123],
          [99.8346832, 6.4709697],
          [99.8346314, 6.4712643],
          [99.8346918, 6.4713587],
          [99.8347696, 6.4713072],
          [99.8348127, 6.4713386],
          [99.8350142, 6.4714931],
          [99.8351524, 6.4716904],
          [99.8352157, 6.4719335],
          [99.8352243, 6.4720451],
          [99.8351927, 6.4721795],
          [99.8349854, 6.4722453],
          [99.8346314, 6.4723711],
          [99.8346055, 6.472477],
          [99.8346285, 6.4726486],
          [99.8348214, 6.4729203],
          [99.8350056, 6.4731949],
          [99.8352186, 6.473069],
          [99.8353078, 6.4729975],
          [99.8354949, 6.4729861],
          [99.8356043, 6.4729775],
          [99.835731, 6.4731005],
          [99.8359382, 6.4731262],
          [99.8360159, 6.4730576],
          [99.8359929, 6.4727716],
          [99.8361858, 6.4723769],
          [99.8363095, 6.4721623],
          [99.8366175, 6.4720565],
          [99.8367902, 6.4719536],
          [99.8374724, 6.4716361],
          [99.8379531, 6.4714416],
          [99.8382381, 6.4712814],
          [99.8384943, 6.4713415],
          [99.838667, 6.4712814],
          [99.8388138, 6.4712986],
          [99.8390067, 6.4714931],
          [99.8392024, 6.4715417],
          [99.8392657, 6.4715017],
          [99.8393866, 6.4714673],
          [99.8395852, 6.4714702],
          [99.8397551, 6.4715274],
          [99.8399968, 6.4713958],
          [99.8400084, 6.471044],
          [99.840089, 6.4707895],
          [99.8401897, 6.4706493],
          [99.8403768, 6.4704892],
          [99.8405438, 6.4702861],
          [99.8409352, 6.4701545],
          [99.8411079, 6.4699372],
          [99.8413962, 6.4697691],
          [99.8417731, 6.4695585],
          [99.842176, 6.4693945],
          [99.8427171, 6.4693297],
          [99.8430797, 6.4692984],
          [99.8433716, 6.469327],
          [99.8436255, 6.4693683],
          [99.8436669, 6.469408],
          [99.8437101, 6.4695882],
          [99.8438338, 6.4700459],
          [99.8438598, 6.4703719],
          [99.8439749, 6.4706064],
          [99.8441591, 6.470555],
          [99.8443951, 6.4704634],
          [99.8446485, 6.4703261],
          [99.8447377, 6.4701488],
          [99.8447262, 6.4699229],
          [99.8446513, 6.4697884],
          [99.8446513, 6.4695968],
          [99.8447492, 6.4695167],
          [99.8449795, 6.4695196],
          [99.8453191, 6.4694624],
          [99.8456156, 6.469328],
          [99.8458114, 6.4691506],
          [99.8459006, 6.4690248],
          [99.8459812, 6.4689933],
          [99.8460618, 6.4690791],
          [99.8460848, 6.4692536],
          [99.846139907836914, 6.469259231868872],
          [99.8462805, 6.4692736],
          [99.8464217, 6.4692318],
          [99.8465479, 6.4690943],
          [99.846613, 6.4690134],
          [99.8468003, 6.4690377],
          [99.8469631, 6.4689608],
          [99.847073, 6.4688071],
          [99.8470527, 6.4686777],
          [99.8471341, 6.4686575],
          [99.8472074, 6.4686858],
          [99.8472237, 6.4688314],
          [99.8473376, 6.4688273],
          [99.8473376, 6.4689285],
          [99.8474761, 6.4689811],
          [99.8476592, 6.4691024],
          [99.8478221, 6.4692561],
          [99.8479442, 6.4692116],
          [99.8480053, 6.4692844],
          [99.8480989, 6.469333],
          [99.8481925, 6.4690943],
          [99.8481762, 6.4687829],
          [99.8481559, 6.4685725],
          [99.8483024, 6.4684026],
          [99.8484856, 6.4682206],
          [99.848449, 6.4681357],
          [99.8483431, 6.4680386],
          [99.8482006, 6.4680426],
          [99.848107, 6.4679658],
          [99.8480582, 6.4679577],
          [99.8479849, 6.4679213],
          [99.8478424, 6.4678202],
          [99.8477122, 6.4677878],
          [99.8475941, 6.4677919],
          [99.8473173, 6.4676827],
          [99.8472074, 6.4675613],
          [99.8470445, 6.4674481],
          [99.8469857, 6.467331],
          [99.847003, 6.4668333],
          [99.8470893, 6.4662213],
          [99.847216, 6.465941],
          [99.8472563, 6.4657293],
          [99.8475844, 6.4656206],
          [99.8476132, 6.465552],
          [99.8474981, 6.4655177],
          [99.8475096, 6.4654376],
          [99.8476535, 6.4653289],
          [99.8477571, 6.4655234],
          [99.8479183, 6.4656893],
          [99.8481141, 6.4657636],
          [99.8483846, 6.4656149],
          [99.8484307, 6.4653403],
          [99.8484882, 6.465203],
          [99.8485918, 6.4650657],
          [99.8489833, 6.4652373],
          [99.8490696, 6.4651859],
          [99.849369, 6.465163],
          [99.8493172, 6.4649856],
          [99.8493402, 6.4647111],
          [99.8492884, 6.4644594],
          [99.8492481, 6.4642363],
          [99.849392, 6.4640361],
          [99.8495705, 6.4638931],
          [99.8496856, 6.4638988],
          [99.8496453, 6.4639674],
          [99.8496281, 6.464099],
          [99.849772, 6.4641448],
          [99.8498526, 6.4640818],
          [99.8499562, 6.4641448],
          [99.8499274, 6.4642878],
          [99.8500656, 6.4643621],
          [99.8502556, 6.4643335],
          [99.8503304, 6.4641505],
          [99.850221, 6.4640304],
          [99.850008, 6.4640361],
          [99.8497835, 6.4638301],
          [99.8498967, 6.4637043],
          [99.8500514, 6.4635668],
          [99.8504014, 6.4635425],
          [99.8505154, 6.4638742],
          [99.8506538, 6.4640602],
          [99.8511016, 6.464125],
          [99.8512237, 6.4638175],
          [99.8512319, 6.4633969],
          [99.8509876, 6.4629762],
          [99.8515087, 6.4624018],
          [99.8519809, 6.4622562],
          [99.8522007, 6.4619407],
          [99.8523147, 6.4616657],
          [99.8526322, 6.4616414],
          [99.8531614, 6.4618517],
          [99.8535034, 6.461609],
          [99.8536743, 6.4612935],
          [99.8535034, 6.4610266],
          [99.8532184, 6.4610023],
          [99.8531533, 6.4605816],
          [99.8535196, 6.460614],
          [99.8537883, 6.4603551],
          [99.8536743, 6.460169],
          [99.8532835, 6.4600962],
          [99.8535034, 6.459894],
          [99.8539104, 6.4599992],
          [99.8541873, 6.4599344],
          [99.8545618, 6.4598535],
          [99.8547002, 6.4594652],
          [99.8542605, 6.4593358],
          [99.8538372, 6.4595785],
          [99.8533161, 6.459716],
          [99.8531126, 6.459627],
          [99.8527951, 6.4596998],
          [99.8526485, 6.4599102],
          [99.8523391, 6.4599911],
          [99.8522821, 6.4598293],
          [99.8520216, 6.4597565],
          [99.8517936, 6.4599668],
          [99.8517041, 6.4601448],
          [99.8512807, 6.4602985],
          [99.8509551, 6.4602176],
          [99.8508981, 6.4599183],
          [99.8512237, 6.4595542],
          [99.8515901, 6.4594329],
          [99.8518425, 6.4589798],
          [99.8517529, 6.4586401],
          [99.8518262, 6.4583488],
          [99.8516878, 6.458187],
          [99.851126, 6.4581466],
          [99.8509551, 6.4580333],
          [99.850719, 6.4578877],
          [99.8505317, 6.4576612],
          [99.8506375, 6.4570302],
          [99.8507922, 6.4568198],
          [99.8511667, 6.4566904],
          [99.8515738, 6.4567309],
          [99.851989, 6.4568603],
          [99.852217, 6.4570302],
          [99.8525671, 6.4569412],
          [99.8527299, 6.4566338],
          [99.8528439, 6.4562535],
          [99.8532021, 6.4560675],
          [99.8536092, 6.456027],
          [99.8538779, 6.4557682],
          [99.8544885, 6.4558895],
          [99.8547734, 6.4556549],
          [99.855034, 6.4555821],
          [99.8550421, 6.4554284],
          [99.8555795, 6.455485],
          [99.8559051, 6.4553313],
          [99.8562633, 6.4552342],
          [99.8564425, 6.4550239],
          [99.8565646, 6.4546437],
          [99.8570368, 6.4545547],
          [99.857338, 6.4541744],
          [99.8572973, 6.4535434],
          [99.8571426, 6.4533169],
          [99.857737, 6.4532036],
          [99.8576311, 6.4527263],
          [99.8574032, 6.4525726],
          [99.857623, 6.4521681],
          [99.8579812, 6.4521277],
          [99.8583801, 6.45144],
          [99.8581929, 6.4512944],
          [99.85768, 6.4508252],
          [99.8575579, 6.4503964],
          [99.8571182, 6.4503883],
          [99.8568414, 6.4506229],
          [99.8566216, 6.4505501],
          [99.856361, 6.4501375],
          [99.856475, 6.4495793],
          [99.8564099, 6.449369],
          [99.8555143, 6.4492315],
          [99.8553108, 6.449102],
          [99.8550584, 6.4486652],
          [99.854635, 6.4483011],
          [99.8545862, 6.4479452],
          [99.8547979, 6.4474031],
          [99.8550991, 6.4469582],
          [99.8551317, 6.446586],
          [99.8557179, 6.4460683],
          [99.8556609, 6.4458903],
          [99.8551561, 6.4455424],
          [99.8549281, 6.4452916],
          [99.854977, 6.4450408],
          [99.8549281, 6.4447091],
          [99.8544152, 6.4441914],
          [99.8542442, 6.4439082],
          [99.8541355, 6.443205],
          [99.8539052, 6.442856],
          [99.8536634, 6.4424442],
          [99.8535195, 6.4422783],
          [99.8537267, 6.4420666],
          [99.8537843, 6.4421467],
          [99.8539685, 6.4422268],
          [99.854124, 6.4422153],
          [99.8542218, 6.441998],
          [99.8542564, 6.4417119],
          [99.8544118, 6.4414431],
          [99.8544866, 6.4414602],
          [99.8548436, 6.4412772],
          [99.8548896, 6.4411627],
          [99.8547515, 6.4410197],
          [99.8547227, 6.4406479],
          [99.8546824, 6.4403218],
          [99.854999, 6.4401273],
          [99.8551832, 6.4401616],
          [99.8553847, 6.4404362],
          [99.8554135, 6.4406193],
          [99.8555459, 6.4406021],
          [99.8555459, 6.440442],
          [99.8552466, 6.4400529],
          [99.8552235, 6.4394809],
          [99.855189, 6.4392463],
          [99.8549587, 6.4393207],
          [99.8548321, 6.4395953],
          [99.8546709, 6.439744],
          [99.8544176, 6.4397269],
          [99.8539628, 6.4395038],
          [99.8537498, 6.4396182],
          [99.8536519, 6.4395438],
          [99.853744, 6.4392864],
          [99.8535886, 6.4392406],
          [99.8535713, 6.4390804],
          [99.8533525, 6.4390232],
          [99.8534043, 6.4389603],
          [99.8533813, 6.4388459],
          [99.8530244, 6.4387715],
          [99.853082, 6.4386686],
          [99.8530877, 6.438537],
          [99.852938, 6.4384169],
          [99.8529495, 6.4382224],
          [99.8527768, 6.438005],
          [99.8530417, 6.4374958],
          [99.8532719, 6.4371812],
          [99.8532201, 6.4369753],
          [99.8534331, 6.436775],
          [99.8535886, 6.436632],
          [99.8535828, 6.4364604],
          [99.8538707, 6.4363917],
          [99.8540664, 6.4366034],
          [99.8540606, 6.4367865],
          [99.854147, 6.4368322],
          [99.8542736, 6.4366663],
          [99.8544406, 6.4365634],
          [99.8545442, 6.4367521],
          [99.8546018, 6.4370782],
          [99.854763, 6.437124],
          [99.8549069, 6.437227],
          [99.8550278, 6.437513],
          [99.8551948, 6.4375874],
          [99.8553387, 6.4375016],
          [99.8552811, 6.4371354],
          [99.8551545, 6.4367865],
          [99.8550278, 6.4366663],
          [99.8548263, 6.4367693],
          [99.8547975, 6.4363975],
          [99.8550278, 6.436243],
          [99.8549414, 6.4360256],
          [99.8546018, 6.4359913],
          [99.8545845, 6.4356938],
          [99.8546363, 6.435465],
          [99.8546881, 6.4351904],
          [99.854809, 6.4349959],
          [99.8550393, 6.4349902],
          [99.8551487, 6.4347156],
          [99.8553847, 6.4346813],
          [99.8555229, 6.4343094],
          [99.8557935, 6.4342808],
          [99.8560122, 6.4340749],
          [99.8560871, 6.4337488],
          [99.8563634, 6.4335772],
          [99.8569218, 6.4339319],
          [99.8572039, 6.4338747],
          [99.8574803, 6.4338632],
          [99.857676, 6.4339776],
          [99.8579523, 6.4339948],
          [99.8581481, 6.4339605],
          [99.8581308, 6.4336859],
          [99.8582171, 6.4336401],
          [99.858269, 6.4339033],
          [99.8584877, 6.4340692],
          [99.858695, 6.4339776],
          [99.8587641, 6.4337602],
          [99.8588562, 6.4335429],
          [99.8589828, 6.4339433],
          [99.8589257, 6.4342704],
          [99.8588244, 6.4351359],
          [99.8592561, 6.4352216],
          [99.8592964, 6.4353874],
          [99.8593194, 6.4355476],
          [99.8591755, 6.4356906],
          [99.8593137, 6.4358737],
          [99.8595958, 6.4359138],
          [99.8595497, 6.4356392],
          [99.8597915, 6.4356735],
          [99.8601081, 6.4358851],
          [99.8603902, 6.4358394],
          [99.8604651, 6.4355476],
          [99.8606378, 6.4354275],
          [99.8609371, 6.43509],
          [99.8612192, 6.4347982],
          [99.8611329, 6.4343863],
          [99.8614034, 6.4341804],
          [99.8614092, 6.4338371],
          [99.8616567, 6.4337056],
          [99.86191, 6.4334939],
          [99.8621346, 6.4332422],
          [99.8619849, 6.4332022],
          [99.8616664, 6.4332557],
          [99.8614629, 6.4333447],
          [99.8612878, 6.4333002],
          [99.8610476, 6.4331303],
          [99.8608767, 6.4329362],
          [99.8607586, 6.432657],
          [99.8606935, 6.4323011],
          [99.8607953, 6.4321554],
          [99.8608441, 6.431933],
          [99.8608726, 6.4317226],
          [99.8609906, 6.4316538],
          [99.8610232, 6.4314556],
          [99.8612837, 6.4313707],
          [99.8616053, 6.4312332],
          [99.8618984, 6.4310794],
          [99.8621508, 6.4310309],
          [99.862505, 6.4310147],
          [99.8624643, 6.4312857],
          [99.8625416, 6.4315163],
          [99.8625498, 6.4318237],
          [99.8623991, 6.4321231],
          [99.8624154, 6.4323698],
          [99.8625864, 6.4327258],
          [99.8627289, 6.4327986],
          [99.8628795, 6.4326813],
          [99.8628998, 6.4324548],
          [99.8631685, 6.4324993],
          [99.8632133, 6.4327744],
          [99.8631522, 6.4329321],
          [99.8633354, 6.4328957],
          [99.8633313, 6.4330656],
          [99.863254, 6.4332315],
          [99.8634453, 6.4332436],
          [99.8635959, 6.4330575],
          [99.8637181, 6.4327905],
          [99.8639257, 6.4326934],
          [99.8640193, 6.4328714],
          [99.8641577, 6.4328512],
          [99.8644508, 6.432564],
          [99.86452, 6.432386],
          [99.864634, 6.4321433],
          [99.864695, 6.4319653],
          [99.8646706, 6.4316781],
          [99.8644996, 6.4314799],
          [99.8645119, 6.4312817],
          [99.8646014, 6.4310228],
          [99.8645607, 6.4307113],
          [99.8645844, 6.4304032],
          [99.8646414, 6.4299663],
          [99.8649712, 6.4297681],
          [99.8651747, 6.4300068],
          [99.8652968, 6.4302414],
          [99.8656835, 6.4300149],
          [99.8659319, 6.4298611],
          [99.8663878, 6.4298692],
          [99.8667012, 6.4296993],
          [99.8667134, 6.4294971],
          [99.8668315, 6.4291694],
          [99.867088, 6.4289712],
          [99.8673851, 6.4286071],
          [99.8676375, 6.428239],
          [99.8678573, 6.4279033],
          [99.8679998, 6.4277658],
          [99.8681952, 6.4274988],
          [99.8683725, 6.4272846],
          [99.8688271, 6.4271174],
          [99.869241, 6.4269413],
          [99.8696268, 6.4268419],
          [99.8698556, 6.4265206],
          [99.8700714, 6.4266218],
          [99.8698638, 6.4268443],
          [99.869876, 6.4269737],
          [99.8701581, 6.4271313],
          [99.8705266, 6.4269839],
          [99.8712653, 6.4268464],
          [99.8714562, 6.4269738],
          [99.8716628, 6.4270249],
          [99.8722196, 6.4276678],
          [99.8730125, 6.4287308],
          [99.873397, 6.429046],
          [99.874196, 6.428587],
          [99.8745445, 6.4275228],
          [99.874662, 6.4267973],
          [99.8748535, 6.4266294],
          [99.876483, 6.424761],
          [99.8774634, 6.4226554],
          [99.8785362, 6.4217464],
          [99.8789449, 6.4216156],
          [99.878841, 6.4211957],
          [99.8793087, 6.42099],
          [99.8794367, 6.420707],
          [99.8798315, 6.4207827],
          [99.8796929, 6.4211062],
          [99.8793885, 6.421368],
          [99.879652, 6.421779],
          [99.8801362, 6.4222213],
          [99.8803163, 6.4220286],
          [99.8802244, 6.421852],
          [99.8800323, 6.4215812],
          [99.880247, 6.4213747],
          [99.8808427, 6.4213471],
          [99.881189, 6.4211062],
          [99.8812569, 6.4209967],
          [99.8809535, 6.4206313],
          [99.8811336, 6.4201839],
          [99.8810577, 6.4197233],
          [99.8819163, 6.4192754],
          [99.8820244, 6.4190267],
          [99.8821714, 6.4192409],
          [99.8823312, 6.4193334],
          [99.8825485, 6.4191873],
          [99.8825828, 6.4189537],
          [99.8827836, 6.419017],
          [99.8829838, 6.4188191],
          [99.8833664, 6.4182577],
          [99.8836407, 6.417883],
          [99.8841696, 6.417737],
          [99.8844243, 6.418248],
          [99.8847476, 6.4184427],
          [99.8845713, 6.4186909],
          [99.884285, 6.418849],
          [99.8840374, 6.4188418],
          [99.8840031, 6.4193139],
          [99.884297, 6.419601],
          [99.8845352, 6.4195101],
          [99.8848242, 6.4195539],
          [99.8846283, 6.4197096],
          [99.8845597, 6.4198897],
          [99.8846332, 6.4201428],
          [99.884501, 6.4204056],
          [99.8844814, 6.4206782],
          [99.8843638, 6.4208826],
          [99.8844757, 6.4210119],
          [99.8847148, 6.4207179],
          [99.8848776, 6.4204804],
          [99.8852343, 6.419861],
          [99.8857468, 6.4200915],
          [99.8862534, 6.4199645],
          [99.8865753, 6.419603],
          [99.8869342, 6.4196774],
          [99.8875367, 6.4190057],
          [99.887566, 6.4182951],
          [99.8879832, 6.4170277],
          [99.8886925, 6.4156864],
          [99.8891862, 6.4143489],
          [99.8895056, 6.4141484],
          [99.8902139, 6.4140182],
          [99.8904235, 6.4147165],
          [99.8901386, 6.4150811],
          [99.8902892, 6.4159979],
          [99.8904502, 6.4163125],
          [99.8913275, 6.4164262],
          [99.8920328, 6.416066],
          [99.8925219, 6.4154959],
          [99.8934238, 6.4139342],
          [99.8943336, 6.4120715],
          [99.8954514, 6.4100113],
          [99.8953045, 6.4098556],
          [99.8957627, 6.4088161],
          [99.8960969, 6.4086128],
          [99.8963531, 6.408457],
          [99.8976478, 6.4059761],
          [99.8980171, 6.4040809],
          [99.8985111, 6.4022838],
          [99.8982127, 6.3992739],
          [99.8982806, 6.3968139],
          [99.8988895, 6.3943239],
          [99.9001062, 6.3918122],
          [99.9002509, 6.3909567],
          [99.9007206, 6.390382],
          [99.9016993, 6.3899737],
          [99.9025749, 6.3898179],
          [99.9047792, 6.3906547],
          [99.9050888, 6.3905258],
          [99.9049748, 6.3908818],
          [99.9048001, 6.3919888],
          [99.9044683, 6.3927237],
          [99.9049911, 6.3931634],
          [99.9052597, 6.393576],
          [99.9055284, 6.3934223],
          [99.9060527, 6.3938914],
          [99.9065054, 6.3940615],
          [99.907268, 6.3956011],
          [99.9079953, 6.3957687],
          [99.9085589, 6.3952535],
          [99.9088438, 6.3946154],
          [99.9089706, 6.3942519],
          [99.9092123, 6.394009],
          [99.9093677, 6.3936142],
          [99.9102082, 6.3928934],
          [99.9102773, 6.3922297],
          [99.9104616, 6.3919615],
          [99.9109145, 6.391321],
          [99.9111221, 6.390969],
          [99.9113012, 6.3905159],
          [99.9113704, 6.390261],
          [99.9112523, 6.3898646],
          [99.9110569, 6.3896461],
          [99.9107761, 6.3895045],
          [99.9107638, 6.3891647],
          [99.9108086, 6.388837],
          [99.9110203, 6.3883111],
          [99.9113785, 6.3874697],
          [99.9115658, 6.3869033],
          [99.911521, 6.3866767],
          [99.9115088, 6.3863167],
          [99.9114233, 6.3861791],
          [99.9112849, 6.3856654],
          [99.9110976, 6.3853458],
          [99.9109674, 6.3853174],
          [99.9108534, 6.3852325],
          [99.9108982, 6.3847673],
          [99.9108493, 6.3845569],
          [99.9106661, 6.3842413],
          [99.910772, 6.3835819],
          [99.9107943, 6.3834559],
          [99.9108778, 6.3829832],
          [99.9109633, 6.3827485],
          [99.9111953, 6.3826069],
          [99.911293, 6.3824613],
          [99.911346, 6.3822631],
          [99.9112645, 6.3820608],
          [99.9110284, 6.3818747],
          [99.910829, 6.3816401],
          [99.9106092, 6.3815066],
          [99.9103893, 6.3813933],
          [99.9102346, 6.381191],
          [99.9099823, 6.3806125],
          [99.9099008, 6.3804992],
          [99.9096892, 6.3806165],
          [99.9095508, 6.3805923],
          [99.9095019, 6.3803172],
          [99.9093635, 6.3800299],
          [99.9092414, 6.3799005],
          [99.909107, 6.3796416],
          [99.9089523, 6.3791601],
          [99.9085697, 6.3788163],
          [99.9084109, 6.378525],
          [99.9080405, 6.3781366],
          [99.9078655, 6.3777887],
          [99.9078939, 6.3770605],
          [99.9077922, 6.3768703],
          [99.9078573, 6.3762433],
          [99.907784, 6.3759601],
          [99.907727, 6.3754989],
          [99.9076945, 6.3748759],
          [99.9076782, 6.3744673],
          [99.9078492, 6.3742569],
          [99.9078736, 6.3741153],
          [99.9077555, 6.3738847],
          [99.9072874, 6.3734842],
          [99.906974, 6.3732576],
          [99.9066239, 6.3733062],
          [99.9061883, 6.3733345],
          [99.9058464, 6.3732334],
          [99.9055695, 6.3730513],
          [99.9054433, 6.3728733],
          [99.9053619, 6.3717162],
          [99.9053953, 6.3711256],
          [99.9054108, 6.3708505],
          [99.9054556, 6.3706725],
          [99.9056428, 6.3704581],
          [99.9060133, 6.3704581],
          [99.906172, 6.3703407],
          [99.9062371, 6.3699969],
          [99.9063674, 6.3698876],
          [99.9067256, 6.3698998],
          [99.9069251, 6.3697986],
          [99.9071571, 6.3698998],
          [99.9072793, 6.3698714],
          [99.9073607, 6.3696975],
          [99.9072467, 6.3694102],
          [99.9071286, 6.3691351],
          [99.9069577, 6.3690057],
          [99.9067989, 6.3688964],
          [99.9065384, 6.3688964],
          [99.9064488, 6.3689409],
          [99.9061435, 6.3690016],
          [99.9057364, 6.3690502],
          [99.9053864, 6.3690744],
          [99.9049833, 6.3691837],
          [99.9046699, 6.3694305],
          [99.904332, 6.369831],
          [99.9041163, 6.3699928],
          [99.903754, 6.3699362],
          [99.9033306, 6.3698633],
          [99.9028625, 6.3696287],
          [99.9026386, 6.3693657],
          [99.9023943, 6.3693253],
          [99.9021053, 6.3689693],
          [99.9019221, 6.3689612],
          [99.9018489, 6.3687427],
          [99.9016575, 6.3685566],
          [99.9015639, 6.3683139],
          [99.9012342, 6.3678891],
          [99.900823, 6.3671042],
          [99.9005421, 6.3667077],
          [99.9003305, 6.3665904],
          [99.9003915, 6.3664933],
          [99.9001636, 6.3661333],
          [99.9001524, 6.3659207],
          [99.9001391, 6.365668],
          [99.9000821, 6.3655183],
          [99.899785, 6.3654253],
          [99.8998013, 6.3653039],
          [99.9004241, 6.365405],
          [99.9006113, 6.3651744],
          [99.9007579, 6.3649762],
          [99.9005951, 6.3648467],
          [99.9008963, 6.3648144],
          [99.9009411, 6.3645595],
          [99.900823, 6.3642925],
          [99.9006195, 6.3641711],
          [99.9006521, 6.3640497],
          [99.9008637, 6.3641509],
          [99.9011039, 6.3639769],
          [99.9011813, 6.3636614],
          [99.9011243, 6.3634874],
          [99.9007253, 6.3634429],
          [99.9007701, 6.3629736],
          [99.900994, 6.3629736],
          [99.9010713, 6.362387],
          [99.9012097, 6.3618408],
          [99.9016005, 6.361153],
          [99.9018163, 6.3611611],
          [99.9022885, 6.3606716],
          [99.9028177, 6.3599757],
          [99.9035097, 6.3591463],
          [99.9038028, 6.3588793],
          [99.9040226, 6.3586325],
          [99.904328, 6.3585314],
          [99.9044216, 6.3582482],
          [99.9045518, 6.3581511],
          [99.9053334, 6.3579812],
          [99.9058301, 6.3578719],
          [99.9062779, 6.3576413],
          [99.9066117, 6.3571154],
          [99.906803, 6.3566339],
          [99.9068478, 6.3559907],
          [99.9069552, 6.3557683],
          [99.9072386, 6.3550197],
          [99.9076049, 6.3542105],
          [99.9077515, 6.3540487],
          [99.908704, 6.3537008],
          [99.909046, 6.3534378],
          [99.9092547, 6.353084],
          [99.9096414, 6.3525742],
          [99.909678, 6.3524205],
          [99.9096495, 6.3521616],
          [99.909735, 6.3518258],
          [99.9098775, 6.3514697],
          [99.9105166, 6.3506485],
          [99.9108423, 6.3508588],
          [99.911115, 6.3508831],
          [99.9113186, 6.3507658],
          [99.9114651, 6.3506201],
          [99.9116931, 6.350519],
          [99.9118681, 6.3505675],
          [99.9122426, 6.350875],
          [99.9124258, 6.3509923],
          [99.9127067, 6.3509276],
          [99.9127495, 6.3506391],
          [99.9128309, 6.3504611],
          [99.9128187, 6.35019],
          [99.9128512, 6.350012],
          [99.9130059, 6.3498987],
          [99.9132135, 6.3499756],
          [99.9134374, 6.3500282],
          [99.913584, 6.3498866],
          [99.9137794, 6.3496398],
          [99.9140928, 6.3493485],
          [99.9141417, 6.3492514],
          [99.9141172, 6.3488751],
          [99.9143004, 6.348689],
          [99.9143818, 6.3484786],
          [99.9143248, 6.3481105],
          [99.9142434, 6.3477706],
          [99.9140317, 6.3475683],
          [99.9138526, 6.3473377],
          [99.9138241, 6.3471921],
          [99.9138079, 6.3470424],
          [99.9138974, 6.3467308],
          [99.9139992, 6.3465852],
          [99.9141457, 6.34648],
          [99.9144063, 6.3464962],
          [99.9144877, 6.3465043],
          [99.9145935, 6.3464233],
          [99.9146831, 6.3462777],
          [99.9146668, 6.346039],
          [99.9146749, 6.3457477],
          [99.9145324, 6.3455292],
          [99.9144592, 6.3453107],
          [99.9144877, 6.3450275],
          [99.9144266, 6.3448819],
          [99.9141742, 6.3447039],
          [99.9142516, 6.3443519],
          [99.9141661, 6.34419],
          [99.9139544, 6.3439958],
          [99.9138974, 6.3438381],
          [99.9137916, 6.3437086],
          [99.9135717, 6.3436317],
          [99.9134822, 6.3435751],
          [99.913356, 6.3433566],
          [99.9133438, 6.3431381],
          [99.9133682, 6.3429561],
          [99.9134537, 6.3426567],
          [99.9137061, 6.3423371],
          [99.9137183, 6.3420862],
          [99.9136532, 6.3418313],
          [99.9135188, 6.3415319],
          [99.91347, 6.341184],
          [99.9134333, 6.3407308],
          [99.9132461, 6.3405326],
          [99.9129693, 6.3405245],
          [99.9129123, 6.3402939],
          [99.9129164, 6.3398934],
          [99.9131402, 6.3392339],
          [99.9134496, 6.3384652],
          [99.9135799, 6.3380403],
          [99.9138486, 6.3377248],
          [99.9141417, 6.3374173],
          [99.914394, 6.3370491],
          [99.9145406, 6.3366688],
          [99.9145854, 6.3363815],
          [99.9145528, 6.335985],
          [99.9143411, 6.3357989],
          [99.9142068, 6.3357301],
          [99.9141946, 6.3356007],
          [99.9142516, 6.3353862],
          [99.9144022, 6.335188],
          [99.9145406, 6.3349897],
          [99.9145528, 6.3348198],
          [99.9144673, 6.3346337],
          [99.9142516, 6.3344678],
          [99.9140765, 6.3344112],
          [99.9138648, 6.3343869],
          [99.9136328, 6.3345123],
          [99.91347, 6.3346539],
          [99.9133601, 6.3347996],
          [99.9132685, 6.3348218],
          [99.9130792, 6.3347389],
          [99.9129306, 6.3345791],
          [99.912841, 6.3344375],
          [99.9128329, 6.3343646],
          [99.9129062, 6.3342898],
          [99.9130161, 6.334217],
          [99.9130731, 6.334128],
          [99.9131117, 6.3340207],
          [99.9131488, 6.3338375],
          [99.9129575, 6.3337202],
          [99.9127336, 6.3336555],
          [99.9124649, 6.333704],
          [99.9123225, 6.3338375],
          [99.9122695, 6.3338173],
          [99.9120782, 6.3336029],
          [99.911952, 6.3333358],
          [99.9119235, 6.3329717],
          [99.9119846, 6.3322677],
          [99.912009, 6.3319076],
          [99.91218, 6.3317215],
          [99.9121718, 6.3316001],
          [99.911952, 6.331499],
          [99.9117566, 6.3315556],
          [99.911513, 6.331686],
          [99.911378, 6.3317822],
          [99.9111094, 6.3315516],
          [99.9110157, 6.3313493],
          [99.9109099, 6.3309083],
          [99.9109262, 6.3306251],
          [99.9111094, 6.3302286],
          [99.9113292, 6.3300789],
          [99.9115083, 6.3300465],
          [99.9116996, 6.3301274],
          [99.9118747, 6.3301355],
          [99.9120131, 6.3300748],
          [99.9120172, 6.329917],
          [99.9118014, 6.3297673],
          [99.9114798, 6.329561],
          [99.9112437, 6.3293101],
          [99.911089, 6.3291847],
          [99.910914, 6.3291483],
          [99.9106575, 6.3291847],
          [99.9104458, 6.3291726],
          [99.910283, 6.3290391],
          [99.9099981, 6.3289339],
          [99.909709, 6.3290512],
          [99.9097009, 6.3291847],
          [99.9095706, 6.3292859],
          [99.9094159, 6.3296217],
          [99.9092857, 6.3297997],
          [99.9090536, 6.3298685],
          [99.9089315, 6.3296581],
          [99.908728, 6.3297026],
          [99.9085448, 6.3296176],
          [99.9085285, 6.3295367],
          [99.9083901, 6.3294234],
          [99.9084919, 6.3293061],
          [99.9083412, 6.3290795],
          [99.9081947, 6.3288691],
          [99.9080359, 6.3287963],
          [99.907845, 6.3288283],
          [99.9076536, 6.3291438],
          [99.9072628, 6.3296779],
          [99.9070308, 6.3296617],
          [99.9067988, 6.3297548],
          [99.9064283, 6.3295808],
          [99.9061027, 6.3293745],
          [99.9060986, 6.3287028],
          [99.9060823, 6.3282416],
          [99.9059724, 6.3280272],
          [99.9058055, 6.3279786],
          [99.9055735, 6.3281324],
          [99.9053414, 6.328367],
          [99.9051053, 6.3286543],
          [99.9049466, 6.3288121],
          [99.9046616, 6.3288121],
          [99.904397, 6.3287352],
          [99.9040632, 6.3285774],
          [99.9037538, 6.3283751],
          [99.9034037, 6.3282214],
          [99.9031514, 6.3278613],
          [99.9028949, 6.3277237],
          [99.9026669, 6.3276226],
          [99.9023128, 6.3276064],
          [99.9018487, 6.3274526],
          [99.901523, 6.3274041],
          [99.9012747, 6.3271937],
          [99.901116, 6.3271977],
          [99.9007781, 6.3272948],
          [99.9005013, 6.3273393],
          [99.8997116, 6.3269428],
          [99.8996301, 6.3268296],
          [99.8995121, 6.326607],
          [99.8992516, 6.3266475],
          [99.8990236, 6.3268983],
          [99.8988689, 6.3269671],
          [99.8984415, 6.3268376],
          [99.8979326, 6.3265868],
          [99.8977372, 6.3265827],
          [99.8975459, 6.3264735],
          [99.8971918, 6.3264816],
          [99.8970615, 6.326344],
          [99.89688, 6.3262181],
          [99.8967063, 6.3262343],
          [99.8965231, 6.3261574],
          [99.8962422, 6.3260927],
          [99.8961893, 6.3259591],
          [99.8959735, 6.3259915],
          [99.8959206, 6.3258297],
          [99.8957211, 6.3257568],
          [99.895485, 6.3258782],
          [99.895371, 6.3258013],
          [99.8954118, 6.3256233],
          [99.8953909, 6.3251956],
          [99.895157, 6.325035],
          [99.8950332, 6.3248182],
          [99.8950083, 6.3246266],
          [99.8949721, 6.3243569],
          [99.8948614, 6.324223],
          [99.8946668, 6.3240292],
          [99.8945488, 6.3236893],
          [99.8945462, 6.3233416],
          [99.894565, 6.3230743],
          [99.8946057, 6.3229044],
          [99.8947726, 6.3226738],
          [99.8950332, 6.3224391],
          [99.8949762, 6.3223663],
          [99.8943045, 6.3220993],
          [99.8937183, 6.3220831],
          [99.8932868, 6.3219738],
          [99.8927983, 6.3218727],
          [99.8924116, 6.3220102],
          [99.8923261, 6.3221802],
          [99.8922898, 6.3225458],
          [99.892133, 6.322766],
          [99.8920167, 6.3229692],
          [99.8917318, 6.3231027],
          [99.891455, 6.3230582],
          [99.8913084, 6.3228114],
          [99.8911252, 6.3226414],
          [99.8909095, 6.3228478],
          [99.8906937, 6.3230946],
          [99.8907059, 6.3235194],
          [99.8907467, 6.3237824],
          [99.8904006, 6.3238835],
          [99.8900465, 6.3239402],
          [99.8899529, 6.3239442],
          [99.8898063, 6.3237298],
          [99.8896842, 6.3237338],
          [99.8897045, 6.3238593],
          [99.8895906, 6.3239483],
          [99.8894644, 6.3240049],
          [99.8893097, 6.3239847],
          [99.8891794, 6.3238755],
          [99.8889555, 6.3238714],
          [99.8888415, 6.3236772],
          [99.8889514, 6.3234061],
          [99.8888578, 6.3230258],
          [99.8887276, 6.3230137],
          [99.8887438, 6.3231634],
          [99.8885647, 6.323131],
          [99.8883571, 6.3229327],
          [99.8882065, 6.3229449],
          [99.8881007, 6.3230784],
          [99.8880233, 6.3230096],
          [99.8878361, 6.3228397],
          [99.8876895, 6.3229449],
          [99.8874453, 6.3231027],
          [99.8872092, 6.3232645],
          [99.8867166, 6.323046],
          [99.8863828, 6.3227264],
          [99.8859716, 6.3224068],
          [99.8856948, 6.3221761],
          [99.885414, 6.3219819],
          [99.8851534, 6.3219536],
          [99.8849255, 6.3220548],
          [99.8846853, 6.3220305],
          [99.8840502, 6.3214681],
          [99.8839607, 6.3213224],
          [99.8837937, 6.320344],
          [99.8836556, 6.3199263],
          [99.8834944, 6.3195028],
          [99.883149, 6.3191538],
          [99.8828726, 6.3190279],
          [99.8824869, 6.3189421],
          [99.8820206, 6.3189593],
          [99.881704, 6.3189879],
          [99.8814562, 6.3190671],
          [99.8810561, 6.3190528],
          [99.8806617, 6.3190642],
          [99.8804804, 6.3189841],
          [99.8803969, 6.3187896],
          [99.8801091, 6.3184319],
          [99.8800486, 6.3182918],
          [99.8799133, 6.3181058],
          [99.8796284, 6.3175908],
          [99.8794585, 6.3170787],
          [99.8794787, 6.3168126],
          [99.8795305, 6.3164979],
          [99.8796648, 6.3165303],
          [99.8799274, 6.3164453],
          [99.8801024, 6.316245],
          [99.8800678, 6.3160771],
          [99.8799335, 6.3158485],
          [99.8799518, 6.3156503],
          [99.8800455, 6.3151971],
          [99.8802856, 6.3145922],
          [99.8806276, 6.3140116],
          [99.8808759, 6.3137001],
          [99.8810224, 6.3133804],
          [99.8811914, 6.3130931],
          [99.8813888, 6.3128261],
          [99.8816697, 6.3125429],
          [99.8818223, 6.3123001],
          [99.8818061, 6.3119522],
          [99.8816758, 6.3116548],
          [99.8815985, 6.3115051],
          [99.8814214, 6.311323],
          [99.8811832, 6.3111996],
          [99.8808616, 6.3110519],
          [99.8807355, 6.3108192],
          [99.8806194, 6.3107707],
          [99.8804607, 6.3105684],
          [99.880481, 6.3104247],
          [99.8803223, 6.3101516],
          [99.8801309, 6.3100485],
          [99.879787, 6.3098279],
          [99.8796017, 6.309834],
          [99.879388, 6.3097329],
          [99.8791967, 6.3096014],
          [99.8791743, 6.3095245],
          [99.8791234, 6.3094941],
          [99.8791051, 6.3092554],
          [99.8786451, 6.3090733],
          [99.8784945, 6.3090106],
          [99.8784986, 6.308865],
          [99.8783825, 6.3087658],
          [99.8782482, 6.3087173],
          [99.87816, 6.3086166],
          [99.8778445, 6.3086712],
          [99.8776736, 6.3085882],
          [99.8773723, 6.3085336],
          [99.8771586, 6.3085033],
          [99.8768574, 6.3083536],
          [99.8767108, 6.3082099],
          [99.8767129, 6.3080198],
          [99.8765398, 6.3079631],
          [99.8760595, 6.3079651],
          [99.8758621, 6.307868],
          [99.8755588, 6.3076758],
          [99.8751375, 6.3073845],
          [99.8745228, 6.3069131],
          [99.8744312, 6.3067918],
          [99.8742215, 6.3059886],
          [99.8740261, 6.305764],
          [99.8737046, 6.3055759],
          [99.8733687, 6.3054869],
          [99.8731224, 6.3054525],
          [99.8728375, 6.3054828],
          [99.8725546, 6.3056164],
          [99.8723022, 6.3057236],
          [99.87229, 6.3059057],
          [99.8721414, 6.3061666],
          [99.8718015, 6.3063062],
          [99.8714921, 6.3064155],
          [99.8711848, 6.3065106],
          [99.8710179, 6.3064923],
          [99.8708184, 6.3064195],
          [99.8706047, 6.3063649],
          [99.8703726, 6.3062961],
          [99.8702688, 6.3062334],
          [99.8701569, 6.3061403],
          [99.8701121, 6.3060392],
          [99.8701426, 6.3058348],
          [99.8702241, 6.3058166],
          [99.870277, 6.3057377],
          [99.8701671, 6.3056811],
          [99.8700999, 6.305497],
          [99.8699432, 6.3053574],
          [99.8697478, 6.305321],
          [99.8695015, 6.3053938],
          [99.8694649, 6.3054909],
          [99.869245, 6.305495],
          [99.8691209, 6.3056244],
          [99.8688726, 6.305588],
          [99.8686955, 6.3056993],
          [99.8685957, 6.3059016],
          [99.868435, 6.3060149],
          [99.8681785, 6.3060857],
          [99.8679587, 6.3059947],
          [99.8678223, 6.3060513],
          [99.8676859, 6.3062718],
          [99.8676941, 6.3063912],
          [99.8678182, 6.3064276],
          [99.8678732, 6.3065692],
          [99.8679055, 6.3067641],
          [99.8677917, 6.3070616],
          [99.8671689, 6.3073246],
          [99.8669898, 6.3072194],
          [99.8664524, 6.3076766],
          [99.8661838, 6.3080934],
          [99.8658866, 6.3084818],
          [99.8655732, 6.3087974],
          [99.8654429, 6.3088257],
          [99.865048, 6.3086356],
          [99.8650236, 6.3079761],
          [99.8648526, 6.3078628],
          [99.8645148, 6.3081298],
          [99.8646735, 6.3084859],
          [99.8646532, 6.308757],
          [99.8644537, 6.3088176],
          [99.8641321, 6.3088217],
          [99.8637576, 6.3086801],
          [99.8633953, 6.3084049],
          [99.8629475, 6.3081258],
          [99.8626829, 6.3081177],
          [99.8624997, 6.3078911],
          [99.8623369, 6.3074541],
          [99.8621768, 6.3068448],
          [99.8627945, 6.3063273],
          [99.8627105, 6.3062076],
          [99.8623377, 6.3064529],
          [99.8622358, 6.3063409],
          [99.8621553, 6.3063063],
          [99.8621124, 6.3063089],
          [99.8619139, 6.3064769],
          [99.861694, 6.3061863],
          [99.8616886, 6.3057331],
          [99.8615894, 6.304872],
          [99.8615861, 6.3048456],
          [99.861499, 6.3043304],
          [99.8621668, 6.3040386],
          [99.8623452, 6.3037582],
          [99.8629785, 6.3035121],
          [99.863522, 6.303063],
          [99.8642142, 6.3011978],
          [99.8640865, 6.3000134],
          [99.8645342, 6.2995797],
          [99.8645305, 6.2985335],
          [99.8648152, 6.2974404],
          [99.8643554, 6.2962813],
          [99.864339, 6.2947272],
          [99.8624768, 6.2935712],
          [99.8619633, 6.2935234],
          [99.8617274, 6.2934278],
          [99.8616649, 6.2928241],
          [99.8618541, 6.2914161],
          [99.8613745, 6.2910443],
          [99.8609669, 6.2911908],
          [99.8610743, 6.2916563],
          [99.8610207, 6.2920839],
          [99.860726350251625, 6.292280070531313],
          [99.8608592, 6.2927617],
          [99.8617887, 6.2940123],
          [99.862275, 6.294913],
          [99.8623881, 6.2952762],
          [99.8621498, 6.2959888],
          [99.8617473, 6.2965474],
          [99.8610879, 6.2970388],
          [99.860391, 6.2973229],
          [99.8601598, 6.2969507],
          [99.8597397, 6.2968819],
          [99.8596283, 6.2969629],
          [99.8589056, 6.2974884],
          [99.8574811, 6.298234],
          [99.8554911, 6.2989225],
          [99.8544052, 6.2990943],
          [99.8538293, 6.2993545],
          [99.853622, 6.2994525],
          [99.8529588, 6.2989849],
          [99.8522002, 6.2987483],
          [99.852037, 6.299618],
          [99.8519936, 6.3000403],
          [99.8515832, 6.300423],
          [99.8514903, 6.3012093],
          [99.8514905, 6.3013065],
          [99.851490522619287, 6.301323012077167],
          [99.8514927, 6.3024352],
          [99.8510581, 6.3027774],
          [99.8514706, 6.303119],
          [99.8511991, 6.3040251],
          [99.85113, 6.3043196],
          [99.8509542, 6.3050681],
          [99.850802, 6.3052141],
          [99.8506423, 6.3051774],
          [99.8504187, 6.306087],
          [99.8504039, 6.3061474],
          [99.8503105, 6.3065573],
          [99.8503488, 6.3066087],
          [99.8508054, 6.3068214],
          [99.8527844, 6.3072915],
          [99.8529064, 6.3075235],
          [99.852548, 6.3076922],
          [99.8524366, 6.3078735],
          [99.8522643, 6.3077992],
          [99.8521364, 6.3076922],
          [99.8520655, 6.3075462],
          [99.8520275, 6.3074128],
          [99.8519415, 6.3072765],
          [99.8517009, 6.3072086],
          [99.851559, 6.3071884],
          [99.8514425, 6.3072035],
          [99.8513691, 6.3072816],
          [99.8513691, 6.30741],
          [99.8514881, 6.3075081],
          [99.8518125, 6.3076936],
          [99.8516759, 6.3082621],
          [99.8513709, 6.3087135],
          [99.8511367, 6.3086464],
          [99.8512816, 6.3088548],
          [99.8511584, 6.3090562],
          [99.8513926, 6.3089776],
          [99.8515485, 6.3091752],
          [99.851547, 6.3089271],
          [99.8520606, 6.3087313],
          [99.852645, 6.3087169],
          [99.8527063, 6.3090367],
          [99.8528453, 6.309358],
          [99.853121, 6.3095544],
          [99.8535078, 6.3095404],
          [99.8536703, 6.3094044],
          [99.8538027, 6.3092297],
          [99.8538637, 6.3091],
          [99.8538207, 6.3089754],
          [99.8537151, 6.3087902],
          [99.8536183, 6.3087279],
          [99.853493, 6.308719],
          [99.8533515, 6.3084841],
          [99.8532691, 6.3084734],
          [99.8531885, 6.3085214],
          [99.8530219, 6.3086354],
          [99.8528411, 6.3085001],
          [99.852662, 6.3083381],
          [99.8526082, 6.3082099],
          [99.8525849, 6.3080586],
          [99.8527855, 6.3079856],
          [99.8530416, 6.3076915],
          [99.853819, 6.308687],
          [99.853844, 6.3088419],
          [99.8539336, 6.308954],
          [99.8539336, 6.3091356],
          [99.8538779, 6.3093312],
          [99.8537276, 6.3096694],
          [99.8535681, 6.3099299],
          [99.8534009, 6.3102756],
          [99.8531521, 6.3107497],
          [99.852668, 6.3111773],
          [99.8520615, 6.3113816],
          [99.8518119, 6.311553],
          [99.8516453, 6.3117666],
          [99.8515573, 6.3119596],
          [99.8516722, 6.3120247],
          [99.8518136, 6.3119375],
          [99.8519093, 6.3119566],
          [99.8520454, 6.3119837],
          [99.8521565, 6.3122116],
          [99.8521708, 6.312411],
          [99.8519961, 6.3130888],
          [99.8517696, 6.3138564],
          [99.8514354, 6.3145979],
          [99.8510291, 6.315171],
          [99.8509371, 6.3151774],
          [99.8508702, 6.3152252],
          [99.8506725, 6.3152387],
          [99.8505441, 6.3152193],
          [99.8504558, 6.3153212],
          [99.8504749, 6.31553],
          [99.8508101, 6.3159376],
          [99.8505971, 6.3162335],
          [99.8496868, 6.3155209],
          [99.847966, 6.317499],
          [99.8493038, 6.3186869],
          [99.8495632, 6.3189088],
          [99.8496076, 6.319251],
          [99.8495469, 6.3193872],
          [99.8494707, 6.3195204],
          [99.8489864, 6.3195485],
          [99.8484722, 6.3198019],
          [99.8476369, 6.3192379],
          [99.8475634, 6.3193376],
          [99.8480487, 6.3196861],
          [99.8480527, 6.3199389],
          [99.8474746, 6.3204081],
          [99.8468898, 6.3199853],
          [99.8465563, 6.3193355],
          [99.846622, 6.3190204],
          [99.8470058, 6.3188427],
          [99.8472373, 6.3190838],
          [99.8474361, 6.3188676],
          [99.8470792, 6.3183774],
          [99.8467881, 6.3184282],
          [99.8451726, 6.3201776],
          [99.8459896, 6.3209904],
          [99.8460849, 6.3210778],
          [99.8466414, 6.3216327],
          [99.8468546, 6.3218522],
          [99.8467681, 6.321899],
          [99.8459514, 6.3211234],
          [99.8457611, 6.3212171],
          [99.8454252, 6.3210311],
          [99.8452944, 6.3211517],
          [99.8448868, 6.3211802],
          [99.8444511, 6.3208913],
          [99.8438012, 6.3215358],
          [99.8431104, 6.3225127],
          [99.843149, 6.3226485],
          [99.8430174, 6.3227194],
          [99.8423294, 6.3232005],
          [99.8422193, 6.3234464],
          [99.8417216, 6.3236425],
          [99.8413338, 6.323615],
          [99.8408976, 6.3234903],
          [99.8403834, 6.3232355],
          [99.8402947, 6.3228019],
          [99.8400959, 6.3227681],
          [99.839859, 6.3227977],
          [99.8395256, 6.3229371],
          [99.8392831, 6.3231871],
          [99.8391209, 6.3233792],
          [99.8390057, 6.3240198],
          [99.8391107, 6.3244649],
          [99.8396909, 6.325028],
          [99.8402666, 6.3252113],
          [99.8402476, 6.3254458],
          [99.8394763, 6.3252255],
          [99.8393519, 6.3253388],
          [99.8392241, 6.3256228],
          [99.838772, 6.3257866],
          [99.8384879, 6.3259077],
          [99.8383526, 6.3257302],
          [99.8374652, 6.3256733],
          [99.8367454, 6.3254828],
          [99.8360264, 6.3243034],
          [99.8360494, 6.3240534],
          [99.8364517, 6.3236402],
          [99.83661, 6.3233416],
          [99.8365778, 6.3225178],
          [99.8364544, 6.3218887],
          [99.8361325, 6.3214142],
          [99.8360521, 6.3215208],
          [99.836323, 6.3218167],
          [99.8363632, 6.32199],
          [99.8363766, 6.3221713],
          [99.8363766, 6.3223979],
          [99.836331, 6.3224698],
          [99.8361084, 6.3224778],
          [99.8359287, 6.3225018],
          [99.8356685, 6.3225312],
          [99.8356149, 6.3223259],
          [99.8353735, 6.3223499],
          [99.8353788, 6.3225578],
          [99.8352635, 6.3224912],
          [99.835234, 6.3223899],
          [99.8352045, 6.3218514],
          [99.8350972, 6.3218354],
          [99.8350972, 6.3229604],
          [99.8348758, 6.3229508],
          [99.8349849, 6.3242921],
          [99.8347422, 6.3243638],
          [99.8345651, 6.3243043],
          [99.8345171, 6.3231118],
          [99.8337814, 6.3218974],
          [99.833533, 6.3215749],
          [99.8315861, 6.3217277],
          [99.8313575, 6.3225542],
          [99.8312724, 6.3235719],
          [99.8315042, 6.3234906],
          [99.831567, 6.3230672],
          [99.8325739, 6.3236224],
          [99.8324092, 6.3243205],
          [99.8312805, 6.3245553],
          [99.8303492, 6.324879],
          [99.8297291, 6.3242653],
          [99.8294126, 6.3240893],
          [99.8293456, 6.3236937],
          [99.8294083, 6.3233957],
          [99.8293654, 6.3233253],
          [99.8292764, 6.3236516],
          [99.8292887, 6.3246049],
          [99.8291417, 6.3249366],
          [99.8292039, 6.3254575],
          [99.82908, 6.3254425],
          [99.8289625, 6.3249419],
          [99.8291047, 6.3245479],
          [99.8290543, 6.3236501],
          [99.8290001, 6.3234293],
          [99.8289502, 6.3237204],
          [99.8289078, 6.3243229],
          [99.8283135, 6.3238425],
          [99.8278494, 6.3240797],
          [99.8260647, 6.3242376],
          [99.8259869, 6.3245313],
          [99.8249462, 6.3243053],
          [99.824074, 6.323712],
          [99.8230718, 6.3228871],
          [99.8229221, 6.3228263],
          [99.8225422, 6.3226668],
          [99.8220322, 6.3225971],
          [99.8216061, 6.3224016],
          [99.8214902, 6.3221487],
          [99.8207912, 6.3219687],
          [99.8191241, 6.3216662],
          [99.8191756, 6.3204886],
          [99.8192878, 6.3204333],
          [99.8200281, 6.3204376],
          [99.8199798, 6.320822],
          [99.8200978, 6.3210651],
          [99.8204465, 6.3201689],
          [99.819938, 6.3201875],
          [99.8189029, 6.3202287],
          [99.818225, 6.32047],
          [99.8173705, 6.3206397],
          [99.8182407, 6.3212566],
          [99.8178115, 6.3214035],
          [99.8166242, 6.3217776],
          [99.8162067, 6.3220013],
          [99.8158648, 6.3220153],
          [99.8154987, 6.3223219],
          [99.8149095, 6.322234],
          [99.814422, 6.322329],
          [99.8139836, 6.3221868],
          [99.8136392, 6.3220617],
          [99.8132518, 6.3219399],
          [99.8130994, 6.3216865],
          [99.8129041, 6.3214298],
          [99.8125895, 6.3212883],
          [99.812457, 6.3210349],
          [99.8121988, 6.3208539],
          [99.8119902, 6.3206268],
          [99.8119272, 6.3204096],
          [99.811712, 6.3203207],
          [99.811606, 6.3201495],
          [99.8113279, 6.3200804],
          [99.8110531, 6.320008],
          [99.8109802, 6.320143],
          [99.81101, 6.3203602],
          [99.8109239, 6.3203799],
          [99.8109007, 6.3201561],
          [99.8108279, 6.3200409],
          [99.8106954, 6.3198731],
          [99.8105199, 6.3198895],
          [99.8106259, 6.3200903],
          [99.8104769, 6.3200969],
          [99.8102451, 6.3200146],
          [99.8099073, 6.3201002],
          [99.8096822, 6.3204096],
          [99.8093709, 6.3204754],
          [99.8091027, 6.3203174],
          [99.8087186, 6.3200311],
          [99.8083709, 6.3200344],
          [99.8081822, 6.3201397],
          [99.8078676, 6.3202647],
          [99.8077119, 6.3203668],
          [99.8075298, 6.3203437],
          [99.8073406, 6.3201404],
          [99.8071532, 6.3201464],
          [99.8069598, 6.3205617],
          [99.8067412, 6.3206242],
          [99.8066507, 6.3205435],
          [99.806564, 6.320294],
          [99.8066386, 6.3199087],
          [99.8062175, 6.3195404],
          [99.8060293, 6.3198692],
          [99.805771, 6.3198692],
          [99.8058167, 6.3194621],
          [99.8056794, 6.3193243],
          [99.8052963, 6.3192566],
          [99.8056757, 6.3191681],
          [99.805877, 6.3185659],
          [99.8058174, 6.3180656],
          [99.8061485, 6.3179142],
          [99.806257, 6.31771],
          [99.8065856, 6.31746],
          [99.8065326, 6.3170848],
          [99.806257, 6.3169653],
          [99.8058041, 6.3169795],
          [99.8051684, 6.3172823],
          [99.8047246, 6.3175324],
          [99.8040847, 6.3173364],
          [99.8036981, 6.3174534],
          [99.803216, 6.317261],
          [99.8028438, 6.3171243],
          [99.802494, 6.3171632],
          [99.8023881, 6.3170047],
          [99.8023273, 6.3169137],
          [99.803175, 6.3165385],
          [99.8034001, 6.3163279],
          [99.8029564, 6.3155314],
          [99.8021934, 6.3148571],
          [99.8017114, 6.3141688],
          [99.8018252, 6.3139002],
          [99.8019552, 6.3135933],
          [99.8019832, 6.3130132],
          [99.802222, 6.312464],
          [99.8024252, 6.3120514],
          [99.8023447, 6.3117342],
          [99.802098, 6.3116702],
          [99.802224, 6.3108624],
          [99.8024279, 6.3107478],
          [99.8026612, 6.310985],
          [99.8029482, 6.3107318],
          [99.8030582, 6.3105878],
          [99.8024699, 6.3099877],
          [99.8023796, 6.3096281],
          [99.8022991, 6.3092548],
          [99.8023742, 6.3088763],
          [99.8025218, 6.3085644],
          [99.8029483, 6.3086333],
          [99.8029202, 6.3081493],
          [99.8025971, 6.3080236],
          [99.802588, 6.3077619],
          [99.8024378, 6.3077769],
          [99.802199, 6.3079584],
          [99.801937, 6.3078179],
          [99.8028275, 6.3064769],
          [99.8022938, 6.3061383],
          [99.8021114, 6.3065569],
          [99.8018566, 6.3064236],
          [99.8013416, 6.3070767],
          [99.8011454, 6.3069717],
          [99.8010751, 6.3065016],
          [99.8010507, 6.3062154],
          [99.8007305, 6.3057729],
          [99.8001573, 6.3050493],
          [99.8000496, 6.3047654],
          [99.7997545, 6.304407],
          [99.7995204, 6.3041045],
          [99.79737, 6.3023851],
          [99.797296, 6.3018516],
          [99.7967679, 6.301338],
          [99.795841, 6.3021423],
          [99.7957803, 6.3020706],
          [99.7958631, 6.3018863],
          [99.7958333, 6.3017777],
          [99.7957207, 6.3017514],
          [99.7954343, 6.3017744],
          [99.7953714, 6.3019291],
          [99.7953068, 6.3019456],
          [99.7952952, 6.3016971],
          [99.7950916, 6.3016642],
          [99.7950204, 6.3014897],
          [99.7950452, 6.301182],
          [99.7947715, 6.3011286],
          [99.7950039, 6.3009458],
          [99.7951046, 6.3006409],
          [99.7952638, 6.3004873],
          [99.7953575, 6.300478],
          [99.7954133, 6.3003864],
          [99.794967, 6.3000247],
          [99.7948453, 6.2998362],
          [99.7945409, 6.299536],
          [99.7942131, 6.2991706],
          [99.7938104, 6.2988495],
          [99.7937983, 6.2987404],
          [99.7933605, 6.2984895],
          [99.7930073, 6.2986789],
          [99.7926285, 6.2984647],
          [99.7921045, 6.2984009],
          [99.791483, 6.2985725],
          [99.7913003, 6.2988518],
          [99.7912045, 6.2988656],
          [99.7911096, 6.2988031],
          [99.7910123, 6.298754],
          [99.790703, 6.2985059],
          [99.7905293, 6.2985432],
          [99.7904101, 6.2984411],
          [99.7903571, 6.2983029],
          [99.7900418, 6.2980994],
          [99.7898236, 6.2979405],
          [99.7896898, 6.2978734],
          [99.7895989, 6.2978962],
          [99.7896246, 6.2977706],
          [99.7897698, 6.2976845],
          [99.7897878, 6.297547],
          [99.7897393, 6.297271],
          [99.7896268, 6.2970926],
          [99.7907726, 6.2959827],
          [99.7903617, 6.2955606],
          [99.7892281, 6.2943963],
          [99.789434, 6.2942223],
          [99.7870554, 6.2915203],
          [99.7872613, 6.2909778],
          [99.7863861, 6.2900362],
          [99.7862007, 6.2901488],
          [99.7850166, 6.2888387],
          [99.7841321, 6.2889438],
          [99.7835367, 6.2896055],
          [99.7832346, 6.2899393],
          [99.7818072, 6.2900182],
          [99.7816682, 6.288242],
          [99.7813689, 6.2875265],
          [99.7805685, 6.2871975],
          [99.7800637, 6.2869751],
          [99.778823, 6.2868783],
          [99.777978, 6.2871825],
          [99.7772474, 6.2861636],
          [99.777359, 6.2851121],
          [99.777322, 6.2844392],
          [99.7769776, 6.2841123],
          [99.7759541, 6.2839683],
          [99.7752084, 6.283129],
          [99.7753173, 6.2823847],
          [99.7756198, 6.2819831],
          [99.7756257, 6.2816397],
          [99.7757241, 6.2815239],
          [99.775784, 6.2813971],
          [99.7757363, 6.2812222],
          [99.7755056, 6.2811076],
          [99.7750491, 6.2809882],
          [99.7744922, 6.280456],
          [99.7743699, 6.2803733],
          [99.7742101, 6.2803302],
          [99.7738748, 6.2804037],
          [99.7734735, 6.2793682],
          [99.7725015, 6.2790989],
          [99.772002, 6.27953],
          [99.7715906, 6.2802603],
          [99.7712806, 6.2804261],
          [99.7709855, 6.2810116],
          [99.7710665, 6.2815235],
          [99.770581, 6.2818962],
          [99.7700392, 6.2821431],
          [99.7690081, 6.2821396],
          [99.7690137, 6.2825748],
          [99.7689239, 6.2829933],
          [99.7685534, 6.28325],
          [99.7679984, 6.2833305],
          [99.7674542, 6.2836794],
          [99.7668019, 6.2835904],
          [99.7664763, 6.2833839],
          [99.7659823, 6.2832277],
          [99.7653454, 6.2827043],
          [99.7650173, 6.2820962],
          [99.7650336, 6.281788],
          [99.7648595, 6.2814755],
          [99.7643091, 6.2812111],
          [99.7637108, 6.2812339],
          [99.7628696, 6.281329],
          [99.7620817, 6.2824899],
          [99.7617466, 6.2828598],
          [99.7614688, 6.2834118],
          [99.7610759, 6.2835346],
          [99.7603065, 6.2836259],
          [99.759824, 6.2836239],
          [99.7597454, 6.283808],
          [99.7593188, 6.2837466],
          [99.7590394, 6.283879],
          [99.7588235, 6.2841618],
          [99.7588388, 6.2846757],
          [99.7588809, 6.2849017],
          [99.7587518, 6.285217],
          [99.758516, 6.285309],
          [99.7585641, 6.2847678],
          [99.7575018, 6.2847059],
          [99.7572476, 6.2834047],
          [99.7569077, 6.2833309],
          [99.7568234, 6.282706],
          [99.756554, 6.282706],
          [99.7558742, 6.2829465],
          [99.7557119, 6.2815955],
          [99.7558691, 6.2812384],
          [99.7558803, 6.2808143],
          [99.7558649, 6.2803409],
          [99.7555828, 6.2796369],
          [99.7561947, 6.2789673],
          [99.7561498, 6.2788278],
          [99.7558496, 6.2791818],
          [99.75542, 6.279676],
          [99.7547756, 6.279878],
          [99.7538201, 6.2807976],
          [99.7536461, 6.2806302],
          [99.7533205, 6.2810431],
          [99.753126, 6.2810807],
          [99.7530229, 6.2808534],
          [99.7523044, 6.2808813],
          [99.7520461, 6.2809761],
          [99.7518665, 6.2812886],
          [99.751664, 6.281357],
          [99.751423, 6.2810375],
          [99.7522761, 6.2794887],
          [99.752063, 6.2794193],
          [99.752164, 6.2783256],
          [99.7509675, 6.2784317],
          [99.7510498, 6.277453],
          [99.7506568, 6.2770348],
          [99.7511213, 6.2769401],
          [99.7510816, 6.2765337],
          [99.7508336, 6.2765774],
          [99.7507005, 6.2764508],
          [99.7506687, 6.2762496],
          [99.7509228, 6.2761194],
          [99.7512126, 6.2759891],
          [99.7513198, 6.2757879],
          [99.751157, 6.2752197],
          [99.7511054, 6.2750185],
          [99.7511419, 6.2748033],
          [99.7512761, 6.2746239],
          [99.7513436, 6.2743793],
          [99.7515103, 6.2741544],
          [99.7515817, 6.2734402],
          [99.7518586, 6.2731257],
          [99.7521335, 6.2730851],
          [99.7522993, 6.2728795],
          [99.752201, 6.2726747],
          [99.752193, 6.2724656],
          [99.7519609, 6.2721603],
          [99.7520065, 6.271925],
          [99.7514448, 6.2718569],
          [99.750502, 6.2728207],
          [99.7500693, 6.2728168],
          [99.750159, 6.272641],
          [99.749506, 6.271644],
          [99.7495414, 6.2708044],
          [99.7497716, 6.2706466],
          [99.7500932, 6.2699679],
          [99.7499904, 6.2697916],
          [99.7495732, 6.2695773],
          [99.7492159, 6.2694628],
          [99.7490541, 6.2697488],
          [99.7487554, 6.2698022],
          [99.748264, 6.270048],
          [99.7476876, 6.2702047],
          [99.7471478, 6.2705753],
          [99.7465563, 6.2708478],
          [99.746203, 6.2708675],
          [99.7460482, 6.2707531],
          [99.7458016, 6.270571],
          [99.7455044, 6.2702836],
          [99.7457307, 6.2699008],
          [99.7456592, 6.2696365],
          [99.7458344, 6.2690442],
          [99.7458299, 6.2686263],
          [99.7459926, 6.2682554],
          [99.7459212, 6.2680226],
          [99.7460919, 6.2675491],
          [99.7460791, 6.2672624],
          [99.745687, 6.2672453],
          [99.7452424, 6.2673952],
          [99.7448335, 6.2677149],
          [99.7446163, 6.2676738],
          [99.7440396, 6.2679279],
          [99.7435528, 6.2679346],
          [99.7430115, 6.2677622],
          [99.7426741, 6.267999],
          [99.7424121, 6.2684369],
          [99.7420183, 6.2685671],
          [99.7416103, 6.2690406],
          [99.741126, 6.2698338],
          [99.740845, 6.270039],
          [99.7405306, 6.2700666],
          [99.7401098, 6.2698732],
          [99.7396255, 6.2695141],
          [99.7393895, 6.2692001],
          [99.7393675, 6.2689223],
          [99.7395977, 6.268725],
          [99.7397367, 6.2684803],
          [99.7397588, 6.268396],
          [99.7395541, 6.2680068],
          [99.7396533, 6.2678096],
          [99.7396454, 6.2676636],
          [99.7398796, 6.2675373],
          [99.7401177, 6.2672808],
          [99.7401296, 6.2670165],
          [99.7402264, 6.2667325],
          [99.7401932, 6.2663654],
          [99.7403678, 6.2661484],
          [99.740479, 6.2661563],
          [99.7405504, 6.2659826],
          [99.7406457, 6.2657814],
          [99.7404472, 6.2656433],
          [99.7402646, 6.2657577],
          [99.7402717, 6.2661802],
          [99.740082, 6.2663575],
          [99.7397644, 6.266393],
          [99.7391589, 6.266739],
          [99.7388078, 6.2669533],
          [99.7384505, 6.2670875],
          [99.7381052, 6.2669888],
          [99.7374986, 6.2668289],
          [99.7372261, 6.2668571],
          [99.7370426, 6.2668162],
          [99.7368389, 6.2667008],
          [99.7367983, 6.2666445],
          [99.7366956, 6.2665019],
          [99.7365443, 6.2665635],
          [99.7363916, 6.2664964],
          [99.735991, 6.2662501],
          [99.7359452, 6.2662219],
          [99.7354826, 6.2658778],
          [99.7353888, 6.2656645],
          [99.7356229, 6.2646841],
          [99.7357329, 6.2640989],
          [99.7358751, 6.2634042],
          [99.736049, 6.2627629],
          [99.736409, 6.2622961],
          [99.7370731, 6.2616304],
          [99.7371498, 6.2613391],
          [99.7362229, 6.2597335],
          [99.7360043, 6.2596882],
          [99.7358808, 6.2595982],
          [99.7356426, 6.2595475],
          [99.7350508, 6.2588302],
          [99.7339906, 6.2595735],
          [99.7321531, 6.2604273],
          [99.7309604, 6.2609545],
          [99.7305281, 6.2608392],
          [99.7301188, 6.2602149],
          [99.729759, 6.2597677],
          [99.7291574, 6.2594282],
          [99.7289169, 6.2598459],
          [99.7286938, 6.2605128],
          [99.7284566, 6.2610568],
          [99.728227, 6.261315],
          [99.7275042, 6.2617606],
          [99.7271654, 6.2622789],
          [99.7270845, 6.2625454],
          [99.7272889, 6.2626527],
          [99.7277055, 6.2626397],
          [99.7288551, 6.2636295],
          [99.7292706, 6.2639027],
          [99.7295004, 6.2646078],
          [99.7295008, 6.2647063],
          [99.7299641, 6.2652803],
          [99.7302249, 6.2653257],
          [99.7305, 6.2655768],
          [99.7306909, 6.266637],
          [99.7311925, 6.2676564],
          [99.7313928, 6.2679339],
          [99.7312803, 6.2683836],
          [99.7314487, 6.2685789],
          [99.7311456, 6.2690644],
          [99.731241, 6.2697061],
          [99.7309042, 6.2703032],
          [99.7304834, 6.2707709],
          [99.730844, 6.271262],
          [99.7312702, 6.2712391],
          [99.7312956, 6.2713155],
          [99.7312623, 6.2714006],
          [99.7312196, 6.2719462],
          [99.7309329, 6.272923],
          [99.7307389, 6.2740463],
          [99.7306576, 6.2747263],
          [99.7304445, 6.2752991],
          [99.7303798, 6.2754588],
          [99.7304389, 6.2757796],
          [99.7303786, 6.2760995],
          [99.7302843, 6.2767074],
          [99.7299366, 6.2790294],
          [99.7294932, 6.2809001],
          [99.7285149, 6.2842815],
          [99.7279241, 6.2860757],
          [99.7275682, 6.2861394],
          [99.7273053, 6.2861805],
          [99.7271754, 6.2863537],
          [99.7271635, 6.2865886],
          [99.7272728, 6.2867618],
          [99.7273201, 6.2868293],
          [99.7272285, 6.2869732],
          [99.7271576, 6.2870789],
          [99.7271682, 6.287278],
          [99.7274353, 6.2875202],
          [99.7266136, 6.2897547],
          [99.7254977, 6.2919153],
          [99.7247074, 6.2929632],
          [99.7233767, 6.2947062],
          [99.722191, 6.2959863],
          [99.7207316, 6.2971276],
          [99.7195224, 6.2979067],
          [99.7192026, 6.2981522],
          [99.7188542, 6.2983937],
          [99.718653, 6.2986375],
          [99.7185184, 6.2989941],
          [99.7185033, 6.2994619],
          [99.7186336, 6.2998568],
          [99.7188998, 6.300479],
          [99.7191557, 6.3010411],
          [99.7191931, 6.3013434],
          [99.7189339, 6.3015087],
          [99.7192908, 6.3022439],
          [99.7192279, 6.302813],
          [99.7193555, 6.3030707],
          [99.7193282, 6.3032425],
          [99.7180164, 6.3033477],
          [99.7175162, 6.3033061],
          [99.7171016, 6.3033118],
          [99.7167131, 6.3035615],
          [99.7167635, 6.3036589],
          [99.717068, 6.3034608],
          [99.717191, 6.3034304],
          [99.7176632, 6.3034776],
          [99.7175637, 6.3036215],
          [99.7175158, 6.3038205],
          [99.7175158, 6.304153],
          [99.7175848, 6.3043899],
          [99.7174725, 6.3044654],
          [99.7169026, 6.3045936],
          [99.716743, 6.3045967],
          [99.7165061, 6.3044236],
          [99.7164428, 6.3044709],
          [99.716558, 6.3046338],
          [99.7167386, 6.3047203],
          [99.7168492, 6.3047373],
          [99.7175009, 6.3046119],
          [99.7178208, 6.3045172],
          [99.7180347, 6.3046225],
          [99.7182364, 6.3042706],
          [99.7182439, 6.3037873],
          [99.7182048, 6.3036946],
          [99.7183576, 6.3036145],
          [99.7185833, 6.3035611],
          [99.7192953, 6.3036445],
          [99.7198566, 6.304324],
          [99.7193258, 6.3052857],
          [99.7178857, 6.3068148],
          [99.7134973, 6.3103328],
          [99.7168038, 6.3150303],
          [99.7168049, 6.3152294],
          [99.7177536, 6.3166852],
          [99.7184404, 6.3179241],
          [99.7187857, 6.3181411],
          [99.7201611, 6.3201374],
          [99.7217631, 6.3227578],
          [99.7222727, 6.3246879],
          [99.7226053, 6.3266873],
          [99.7225179, 6.3268289],
          [99.7225224, 6.3268787],
          [99.7226519, 6.328311],
          [99.722509, 6.3284688],
          [99.7223979, 6.330907],
          [99.7221701, 6.3348837],
          [99.7219923, 6.3352244],
          [99.7213204, 6.3368399],
          [99.7206788, 6.3380931],
          [99.7199049, 6.3381444],
          [99.7196299, 6.3380384],
          [99.7189658, 6.3380264],
          [99.7188753, 6.3388653],
          [99.7180881, 6.3388273],
          [99.7180239, 6.3395425],
          [99.7195322, 6.339697],
          [99.719935, 6.3399967],
          [99.7212241, 6.3402813],
          [99.7213028, 6.3414642],
          [99.7208466, 6.3443487],
          [99.7201109, 6.346569],
          [99.71962, 6.3476326],
          [99.7192124, 6.3482911],
          [99.7186571, 6.3488642],
          [99.7184479, 6.3492774],
          [99.7184812, 6.3495864],
          [99.7183326, 6.3498372],
          [99.7182122, 6.3500661],
          [99.7182414, 6.3503704],
          [99.7178364, 6.3508768],
          [99.7176942, 6.3515086],
          [99.7174956, 6.3521601],
          [99.7163456, 6.3528614],
          [99.7165055, 6.3530279],
          [99.7165653, 6.353387],
          [99.716771, 6.3535186],
          [99.7168437, 6.3547239],
          [99.7169358, 6.3550979],
          [99.7169254, 6.3552513],
          [99.7169093, 6.3554109],
          [99.7167108, 6.3554001],
          [99.7163888, 6.35609],
          [99.7161555, 6.3565742],
          [99.7155515, 6.3573246],
          [99.7150514, 6.3581221],
          [99.7142336, 6.3594549],
          [99.7139637, 6.3595378],
          [99.7132769, 6.360688],
          [99.7124881, 6.3612492],
          [99.7119516, 6.3615744],
          [99.7117478, 6.3614731],
          [99.71156, 6.361801],
          [99.7113481, 6.3618543],
          [99.7111201, 6.3618543],
          [99.7110001, 6.3617934],
          [99.71092, 6.3617505],
          [99.7108453, 6.3617106],
          [99.7109324, 6.3614731],
          [99.7108447, 6.3610357],
          [99.7105059, 6.3611132],
          [99.7100673, 6.3607993],
          [99.7098609, 6.3604955],
          [99.7097219, 6.3603614],
          [99.7092932, 6.3602667],
          [99.7092738, 6.3600088],
          [99.7091106, 6.3598564],
          [99.7088209, 6.359813],
          [99.7084894, 6.359433],
          [99.708285, 6.3596315],
          [99.7078602, 6.3595526],
          [99.7071346, 6.3597401],
          [99.707115929841294, 6.35975453181808],
          [99.7071216, 6.3599175],
          [99.7068603, 6.3600275],
          [99.706766614868812, 6.360024548276688],
          [99.706717, 6.3600629],
          [99.7064475, 6.359756],
          [99.7060826, 6.3596054],
          [99.7057071, 6.3592321],
          [99.705525732032569, 6.359244407409069],
          [99.705414, 6.3592827],
          [99.7053194, 6.3592587],
          [99.705319169804383, 6.359258424469945],
          [99.7043985, 6.3593209],
          [99.7043031, 6.35912],
          [99.7037389, 6.3590856],
          [99.7038251, 6.3593398],
          [99.7033299, 6.3598593],
          [99.7025424, 6.3604404],
          [99.7023601, 6.360601],
          [99.7022284, 6.3606756],
          [99.7010661, 6.3603897],
          [99.700798, 6.36019],
          [99.6998233, 6.3601019],
          [99.699260212873426, 6.359628665255331],
          [99.6992025, 6.3596513],
          [99.6989738, 6.359699],
          [99.6987356, 6.3596864],
          [99.698588, 6.3596047],
          [99.6984194, 6.3596235],
          [99.6981495, 6.3595125],
          [99.6978565, 6.3593784],
          [99.6974496, 6.3593554],
          [99.6971482, 6.3593009],
          [99.6968573, 6.3591815],
          [99.6965073, 6.358993],
          [99.6964188, 6.3587792],
          [99.6963893, 6.358467],
          [99.6963007, 6.3583078],
          [99.696248, 6.3581088],
          [99.6964019, 6.3579118],
          [99.69656, 6.3576583],
          [99.6964314, 6.3575096],
          [99.6959529, 6.3576332],
          [99.6957708, 6.357439],
          [99.695702, 6.3573231],
          [99.6953647, 6.3572623],
          [99.6950528, 6.35699],
          [99.6948588, 6.3567197],
          [99.6942878, 6.3564771],
          [99.6940373, 6.3562371],
          [99.6938674, 6.3558075],
          [99.6938615, 6.3556564],
          [99.6937482, 6.3556801],
          [99.6936766, 6.3555171],
          [99.6936885, 6.3553541],
          [99.6936349, 6.3552623],
          [99.6935246, 6.3552416],
          [99.6934868, 6.3553147],
          [99.6931281, 6.3552949],
          [99.6931072, 6.3551853],
          [99.6930356, 6.3551823],
          [99.6929969, 6.3553364],
          [99.6926898, 6.3553867],
          [99.6925825, 6.3555141],
          [99.6925735, 6.3556416],
          [99.6927196, 6.355797],
          [99.6927405, 6.3560386],
          [99.6926242, 6.3560949],
          [99.6926332, 6.3562519],
          [99.6929581, 6.3564919],
          [99.6929969, 6.3568415],
          [99.6929295, 6.3570706],
          [99.6926123, 6.3575675],
          [99.6923082, 6.3578519],
          [99.692025, 6.3580178],
          [99.6917336, 6.3580746],
          [99.6915211, 6.3580089],
          [99.6911813, 6.357846],
          [99.6911753, 6.3577808],
          [99.6908981, 6.3576297],
          [99.6907076, 6.3577254],
          [99.6906428, 6.3586636],
          [99.6903797, 6.358798],
          [99.6901084, 6.3588158],
          [99.6897953, 6.3587713],
          [99.6894674, 6.358478],
          [99.6893004, 6.3584187],
          [99.6892054, 6.3584624],
          [99.688731, 6.3581728],
          [99.688412, 6.3581491],
          [99.6882153, 6.3581758],
          [99.6881407, 6.3583447],
          [99.6879648, 6.3583387],
          [99.6877532, 6.3580395],
          [99.6875564, 6.3578943],
          [99.6872881, 6.3577847],
          [99.6869303, 6.3577136],
          [99.6869095, 6.3578795],
          [99.6866143, 6.3578736],
          [99.6861645, 6.357756],
          [99.6858541, 6.3581106],
          [99.6858451, 6.3582706],
          [99.6858809, 6.358398],
          [99.6858094, 6.3584573],
          [99.685733, 6.3583928],
          [99.6855411, 6.358401],
          [99.6854009, 6.3585313],
          [99.6854486, 6.3587358],
          [99.685553, 6.3590854],
          [99.6852549, 6.3592247],
          [99.6851058, 6.3591624],
          [99.6849478, 6.3589373],
          [99.6848583, 6.3588187],
          [99.6846437, 6.3588336],
          [99.6844887, 6.358715],
          [99.6843277, 6.3586854],
          [99.6839401, 6.3588099],
          [99.6838924, 6.3592187],
          [99.6840087, 6.3596454],
          [99.6838686, 6.3596898],
          [99.6837105, 6.3596217],
          [99.6834542, 6.3598835],
          [99.6835338, 6.3604615],
          [99.6836182, 6.361075],
          [99.6841309, 6.3622439],
          [99.6841399, 6.3626824],
          [99.6841309, 6.3628246],
          [99.6840282, 6.3630938],
          [99.6842138, 6.363498],
          [99.6848399, 6.3642879],
          [99.6851294, 6.3650622],
          [99.6853629, 6.3655685],
          [99.6853709, 6.3655833],
          [99.6855438, 6.3659341],
          [99.6855559, 6.366031],
          [99.6855581, 6.3660652],
          [99.6856418, 6.3671348],
          [99.685559, 6.3672192],
          [99.6855405, 6.3672413],
          [99.6849925, 6.3678912],
          [99.684602, 6.3681193],
          [99.6845244, 6.3680749],
          [99.6841071, 6.3684245],
          [99.6839669, 6.3684097],
          [99.6837404, 6.3684126],
          [99.6835436, 6.3684719],
          [99.6833826, 6.3686674],
          [99.6833111, 6.3688926],
          [99.6830759, 6.3690027],
          [99.6830535, 6.3690132],
          [99.682926, 6.3688355],
          [99.682769297384453, 6.368617073300773],
          [99.682476612319434, 6.36820912361145],
          [99.6819309, 6.3674485],
          [99.6819493, 6.3673711],
          [99.6821461, 6.3673503],
          [99.6823786, 6.3671992],
          [99.6824532, 6.3670096],
          [99.6824919, 6.3667696],
          [99.6825873, 6.3666452],
          [99.6827066, 6.3665859],
          [99.6828795, 6.3666304],
          [99.6830584, 6.3666393],
          [99.6831806, 6.3666067],
          [99.6832939, 6.3665],
          [99.6833297, 6.3664378],
          [99.6834191, 6.3663844],
          [99.6835234, 6.3663874],
          [99.6837419, 6.3663549],
          [99.6839632, 6.3661915],
          [99.683978, 6.3660176],
          [99.6840981, 6.3658751],
          [99.6842984, 6.365871],
          [99.6844944, 6.3658856],
          [99.6846315, 6.3658332],
          [99.6846568, 6.3657054],
          [99.6847266, 6.3655206],
          [99.684427, 6.3645699],
          [99.6841451, 6.3644127],
          [99.6839964, 6.3643055],
          [99.6838355, 6.3642475],
          [99.6836637, 6.3641665],
          [99.6835103, 6.3640436],
          [99.6834213, 6.3639694],
          [99.683422, 6.3637303],
          [99.6831927, 6.3636703],
          [99.6830444, 6.3637201],
          [99.6829352, 6.3638462],
          [99.6829936, 6.3640141],
          [99.6831495, 6.3641737],
          [99.6834372, 6.364331],
          [99.6833881, 6.3644764],
          [99.683341, 6.3646108],
          [99.6832916, 6.3646806],
          [99.6830108, 6.3650226],
          [99.6826615, 6.3653062],
          [99.6813682, 6.3658963],
          [99.6806799, 6.3661996],
          [99.6801534, 6.3663526],
          [99.6797457, 6.3663815],
          [99.6795473, 6.3664068],
          [99.6793727, 6.3665568],
          [99.6791269, 6.3665467],
          [99.6783951, 6.3666558],
          [99.6776519, 6.3666444],
          [99.6770311, 6.3663845],
          [99.6754556, 6.3657961],
          [99.675259, 6.3655197],
          [99.6748418, 6.3654053],
          [99.6734495, 6.3648155],
          [99.6730421, 6.3643813],
          [99.6730556, 6.3639735],
          [99.6732692, 6.3636938],
          [99.6730183, 6.3633703],
          [99.6724627, 6.362785],
          [99.6720011, 6.3624031],
          [99.6718188, 6.362514],
          [99.6715331, 6.3625783],
          [99.6710607, 6.3628702],
          [99.6709417, 6.363339],
          [99.669589, 6.3644625],
          [99.6689213, 6.3647003],
          [99.6680886, 6.3650085],
          [99.6674407, 6.3651521],
          [99.6670993, 6.3652625],
          [99.6670217, 6.3652504],
          [99.6667567, 6.3651071],
          [99.666184, 6.3648295],
          [99.6654113, 6.3645385],
          [99.6647195, 6.3635224],
          [99.6646041, 6.3634677],
          [99.6645215, 6.3634452],
          [99.6641306, 6.3635164],
          [99.6638956, 6.3634347],
          [99.6637146, 6.3634213],
          [99.6633351, 6.3632747],
          [99.6632237, 6.3631774],
          [99.6631504, 6.3630957],
          [99.6631232, 6.3630127],
          [99.6631424, 6.3629321],
          [99.6632557, 6.3626768],
          [99.6627081, 6.3622552],
          [99.6619174, 6.3618855],
          [99.6618405, 6.3620029],
          [99.6616302, 6.3618978],
          [99.6614997, 6.361902],
          [99.6608029, 6.3618773],
          [99.660619, 6.3618974],
          [99.6604574, 6.3619403],
          [99.6602155, 6.3617087],
          [99.6597906, 6.3615722],
          [99.6590002, 6.3612169],
          [99.658691, 6.3611738],
          [99.6581773, 6.3612044],
          [99.6577312, 6.361166],
          [99.6572312, 6.3609806],
          [99.6567002, 6.3606982],
          [99.6564731, 6.3605189],
          [99.6564144, 6.3605247],
          [99.6563136, 6.3606092],
          [99.6565068, 6.3608913],
          [99.6563507, 6.3611572],
          [99.6562086, 6.3612974],
          [99.6555972, 6.3617344],
          [99.655414, 6.3617318],
          [99.6551013, 6.3619192],
          [99.6551409, 6.3619693],
          [99.6550259, 6.3620098],
          [99.6548142, 6.3621855],
          [99.6545838, 6.3622297],
          [99.6543959, 6.3622253],
          [99.6538812, 6.3624014],
          [99.6536737, 6.3625589],
          [99.6533383, 6.3626844],
          [99.653271, 6.3626013],
          [99.6531605, 6.3625517],
          [99.653117, 6.3623484],
          [99.6529899, 6.3623094],
          [99.6524202, 6.3636488],
          [99.6522545, 6.3643776],
          [99.6523599, 6.3644243],
          [99.6523803, 6.3646105],
          [99.6522844, 6.3647463],
          [99.652454, 6.3649069],
          [99.6521751, 6.365292],
          [99.6520952, 6.3652709],
          [99.6519974, 6.3652817],
          [99.6516948, 6.3654715],
          [99.6515342, 6.3654502],
          [99.6513829, 6.3654182],
          [99.6511462, 6.3654823],
          [99.6508706, 6.3656028],
          [99.6506471, 6.3658407],
          [99.6502989, 6.3662884],
          [99.650239, 6.3665026],
          [99.6500767, 6.367147],
          [99.6500221, 6.3676821],
          [99.6495261, 6.3681867],
          [99.6493742, 6.3685707],
          [99.6493742, 6.3688268],
          [99.6490728, 6.3692798],
          [99.648936, 6.3692992],
          [99.6489065, 6.3698978],
          [99.6485863, 6.3699573],
          [99.6483046, 6.3701036],
          [99.6482763, 6.3703892],
          [99.6482213, 6.3705712],
          [99.6479387, 6.3706882],
          [99.6475574, 6.3707468],
          [99.6472207, 6.3708215],
          [99.6469738, 6.3711725],
          [99.6467116, 6.3720069],
          [99.6467772, 6.3736445],
          [99.6472499, 6.3746413],
          [99.6473146, 6.374983],
          [99.6470977, 6.3754325],
          [99.6469316, 6.37585],
          [99.6468162, 6.376046],
          [99.6467289, 6.3759811],
          [99.6463148, 6.3765668],
          [99.6464701, 6.3768695],
          [99.6462855, 6.3773324],
          [99.6456744, 6.3783909],
          [99.6449246, 6.3795804],
          [99.6446232, 6.3804937],
          [99.6443907, 6.3810557],
          [99.6444568, 6.3816857],
          [99.6446784, 6.3827609],
          [99.6450858, 6.383404],
          [99.6449926, 6.384653],
          [99.6445384, 6.3854002],
          [99.6443224, 6.3858297],
          [99.6440074, 6.3867964],
          [99.6438355, 6.3869406],
          [99.6435227, 6.3865504],
          [99.643472, 6.3876438],
          [99.643646, 6.3890427],
          [99.6436434, 6.3891573],
          [99.6434812, 6.3892194],
          [99.6434503, 6.389616],
          [99.643677, 6.3907556],
          [99.6441426, 6.391269],
          [99.6442147, 6.3916176],
          [99.6441517, 6.3918234],
          [99.6435555, 6.3921427],
          [99.6431735, 6.3923229],
          [99.6429957, 6.39226],
          [99.6427972, 6.3922283],
          [99.6426569, 6.3931503],
          [99.6423222, 6.3944551],
          [99.6422253, 6.3958057],
          [99.6428511, 6.3970305],
          [99.6428085, 6.397465],
          [99.6432454, 6.3983403],
          [99.6443202, 6.3995377],
          [99.644796, 6.3999564],
          [99.6445157, 6.4003301],
          [99.6442067, 6.4003898],
          [99.644215, 6.4017689],
          [99.6444811, 6.4020272],
          [99.6443215, 6.4021842],
          [99.6441541, 6.4024878],
          [99.6440358, 6.4029537],
          [99.6443998, 6.4032795],
          [99.6443736, 6.4034775],
          [99.643869, 6.4038317],
          [99.6436965, 6.4050499],
          [99.6435192, 6.4064279],
          [99.6435176, 6.4081677],
          [99.6440608, 6.4113217],
          [99.6444837, 6.4116298],
          [99.6444815, 6.4120816],
          [99.6439514, 6.4124859],
          [99.6438518, 6.4134031],
          [99.6435109, 6.414106],
          [99.6430711, 6.4143547],
          [99.6421307, 6.4148739],
          [99.6417273, 6.415061],
          [99.6415433, 6.4149757],
          [99.6413413, 6.4151609],
          [99.6411353, 6.4167082],
          [99.6410366, 6.4178589],
          [99.6415918, 6.4208617],
          [99.6411798, 6.421415],
          [99.6412066, 6.4224428],
          [99.6410285, 6.4240186],
          [99.6415757, 6.426218],
          [99.6412345, 6.4267367],
          [99.6412962, 6.4273695],
          [99.6416524, 6.4284628],
          [99.6417173, 6.4294111],
          [99.6414872, 6.429555],
          [99.641156, 6.4299122],
          [99.641054, 6.4303912],
          [99.6412075, 6.4309093],
          [99.641091, 6.4313299],
          [99.6411069, 6.4319794],
          [99.6412498, 6.4323808],
          [99.6412195, 6.4326087],
          [99.6412919, 6.4328219],
          [99.6409897, 6.4330352],
          [99.6405954, 6.4336903],
        ],
      ],
    ],
  },
};
