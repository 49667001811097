export const labuan = {
  type: "Feature",
  properties: {
    gid: 5,
    id: 4521286,
    country: "MYS",
    name: "Labuan",
    enname: null,
    locname: "Labuan",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q214504",
    wikimedia: "en:Labuan",
    timestamp: "2019-11-06 22:59:02",
    note: null,
    path: "0,2108121,4521286",
    rpath: "4521286,2108121,0",
    iso3166_2: "MY-15",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [115.246342, 4.9195254],
          [115.2463837, 4.9198612],
          [115.2480398, 4.9202896],
          [115.2485147, 4.9197365],
          [115.2479242, 4.9192249],
          [115.246342, 4.9195254],
        ],
      ],
      [
        [
          [115.3945825, 4.9282213],
          [115.3939006, 4.9268285],
          [115.3906412, 4.9229053],
          [115.3938577, 4.9268819],
          [115.3945825, 4.9282213],
        ],
      ],
      [
        [
          [115.1892308, 4.9641773],
          [115.1887051, 4.9636536],
          [115.1881794, 4.9632935],
          [115.1874894, 4.9620497],
          [115.1871609, 4.9600857],
          [115.1865695, 4.959562],
          [115.1853866, 4.9601839],
          [115.1843353, 4.9630644],
          [115.1842038, 4.9654866],
          [115.1853209, 4.9678761],
          [115.1856823, 4.9699382],
          [115.1869309, 4.9688908],
          [115.188508, 4.9664686],
          [115.1892308, 4.9641773],
        ],
      ],
      [
        [
          [115.1385943, 5.185603],
          [115.1387197, 5.1860452],
          [115.1389193, 5.1865105],
          [115.1394476, 5.1873766],
          [115.1398193, 5.1881399],
          [115.1401168, 5.1886386],
          [115.1405411, 5.1891966],
          [115.1408597, 5.1894028],
          [115.1413079, 5.1895668],
          [115.1418076, 5.1895224],
          [115.1423961, 5.1891647],
          [115.1429537, 5.1887647],
          [115.1433484, 5.1881861],
          [115.1438383, 5.1873698],
          [115.1440582, 5.1868727],
          [115.1438544, 5.1862425],
          [115.1432936, 5.1862874],
          [115.1421673, 5.1861752],
          [115.1407966, 5.1857135],
          [115.1403246, 5.1857402],
          [115.1396574, 5.1856277],
          [115.1395432, 5.1854973],
          [115.1392579, 5.1854906],
          [115.1387016, 5.185556],
          [115.1385943, 5.185603],
        ],
      ],
      [
        [
          [115.1462737, 5.2008298],
          [115.146214, 5.2010683],
          [115.1461971, 5.201282],
          [115.1461607, 5.2017422],
          [115.1460484, 5.202267],
          [115.1457185, 5.2025207],
          [115.1455124, 5.2028009],
          [115.1456488, 5.203071],
          [115.1459732, 5.2030868],
          [115.1461282, 5.2030577],
          [115.146311, 5.2030234],
          [115.146432, 5.2027504],
          [115.1467049, 5.2027607],
          [115.1471644, 5.2029391],
          [115.1477787, 5.2024967],
          [115.1477114, 5.2016954],
          [115.1478439, 5.2014051],
          [115.1481762, 5.2010267],
          [115.1487347, 5.2005708],
          [115.1488969, 5.2000392],
          [115.1487477, 5.1996707],
          [115.1482285, 5.1996391],
          [115.148083, 5.1998311],
          [115.1481501, 5.2000713],
          [115.1479935, 5.2002425],
          [115.1476847, 5.2002093],
          [115.147411, 5.2002155],
          [115.1465467, 5.2005439],
          [115.1462737, 5.2008298],
        ],
      ],
      [
        [
          [115.1315, 5.2169905],
          [115.1311866, 5.2170724],
          [115.1308459, 5.2174642],
          [115.130631, 5.2177986],
          [115.1304925, 5.2181342],
          [115.1300144, 5.2189046],
          [115.1297834, 5.2190357],
          [115.1296207, 5.2191246],
          [115.1295379, 5.2192167],
          [115.12919, 5.2196691],
          [115.1290618, 5.219903],
          [115.1285068, 5.2208553],
          [115.1279691, 5.221905],
          [115.1278871, 5.2222751],
          [115.1278203, 5.2228754],
          [115.1276945, 5.2230266],
          [115.127358, 5.2240224],
          [115.127027, 5.2248404],
          [115.1267492, 5.2253737],
          [115.1263018, 5.2260467],
          [115.1260801, 5.2264305],
          [115.1259486, 5.2269481],
          [115.1256993, 5.2272633],
          [115.1254586, 5.2273869],
          [115.1251831, 5.2278438],
          [115.1246312, 5.228438],
          [115.1242054, 5.2285908],
          [115.1232592, 5.2287874],
          [115.122874, 5.2284028],
          [115.1222614, 5.2283398],
          [115.1218186, 5.2284691],
          [115.1210694, 5.2293633],
          [115.1209621, 5.2307309],
          [115.1213482, 5.2319423],
          [115.1217618, 5.2324458],
          [115.1219759, 5.2328926],
          [115.122265, 5.2335531],
          [115.1225694, 5.2338997],
          [115.1228762, 5.2339899],
          [115.1230557, 5.2339485],
          [115.1231513, 5.2336925],
          [115.1231546, 5.2333527],
          [115.1231186, 5.2326508],
          [115.1229963, 5.2318538],
          [115.1233986, 5.2314143],
          [115.124553, 5.2306262],
          [115.1249803, 5.2304526],
          [115.1256742, 5.230296],
          [115.1260543, 5.2301994],
          [115.1268962, 5.2297063],
          [115.1286236, 5.2289498],
          [115.1304217, 5.2284071],
          [115.132318, 5.2283697],
          [115.1339443, 5.2288609],
          [115.1343878, 5.2291583],
          [115.135435, 5.2291676],
          [115.1358741, 5.2283399],
          [115.1367465, 5.2275223],
          [115.1385677, 5.2266408],
          [115.1386949, 5.2261935],
          [115.1382052, 5.2251222],
          [115.1376744, 5.22444],
          [115.1377979, 5.2237881],
          [115.1382888, 5.22281],
          [115.1388412, 5.2219883],
          [115.1392076, 5.2210601],
          [115.1394247, 5.2202426],
          [115.1396771, 5.2199212],
          [115.140186, 5.2194921],
          [115.1407611, 5.2190882],
          [115.141107, 5.2188857],
          [115.1421075, 5.21856],
          [115.1440863, 5.2177488],
          [115.146391, 5.2166712],
          [115.1465986, 5.2167043],
          [115.1468963, 5.2167086],
          [115.14673, 5.2163805],
          [115.1463817, 5.216227],
          [115.1460729, 5.2163065],
          [115.1458795, 5.2164887],
          [115.1457375, 5.2167031],
          [115.1443626, 5.2173295],
          [115.1435332, 5.2177332],
          [115.1425636, 5.2181835],
          [115.1418233, 5.2184292],
          [115.1412504, 5.2186194],
          [115.1403405, 5.2186801],
          [115.1393808, 5.2187554],
          [115.138028, 5.2188222],
          [115.137065, 5.2191021],
          [115.1360681, 5.2189791],
          [115.1343713, 5.2186679],
          [115.1336944, 5.2185919],
          [115.1329784, 5.2183585],
          [115.132265, 5.2180657],
          [115.131929, 5.2175667],
          [115.1317402, 5.2171271],
          [115.1315, 5.2169905],
        ],
      ],
      [
        [
          [115.1904873, 5.2379099],
          [115.1906831, 5.2381704],
          [115.1907823, 5.2381873],
          [115.1909707, 5.2381056],
          [115.1912456, 5.2380952],
          [115.1914614, 5.238155],
          [115.1915499, 5.2382081],
          [115.1915729, 5.2382287],
          [115.1916395, 5.2382101],
          [115.1916757, 5.2381916],
          [115.191689, 5.2381529],
          [115.1916285, 5.2378939],
          [115.1915853, 5.2376912],
          [115.1916378, 5.2374063],
          [115.1917159, 5.2372879],
          [115.1918286, 5.2372426],
          [115.19169, 5.2371887],
          [115.1915838, 5.237133],
          [115.1913723, 5.237083],
          [115.19119, 5.2371389],
          [115.1909584, 5.2372778],
          [115.1907904, 5.2374104],
          [115.1905518, 5.2374705],
          [115.1904032, 5.2375789],
          [115.1904015, 5.2377229],
          [115.1904873, 5.2379099],
        ],
      ],
      [
        [
          [115.3270112, 5.2725706],
          [115.3268914, 5.2725176],
          [115.3267783, 5.2725242],
          [115.3266319, 5.272723],
          [115.3265054, 5.2733857],
          [115.3266385, 5.2735182],
          [115.3267583, 5.2734917],
          [115.3269446, 5.2732929],
          [115.3270777, 5.2728754],
          [115.3270112, 5.2725706],
        ],
      ],
      [
        [
          [115.268242, 5.251885],
          [115.2680363, 5.2524046],
          [115.2680767, 5.2528443],
          [115.2681597, 5.2529189],
          [115.2681299, 5.253085],
          [115.2682907, 5.2533692],
          [115.2687071, 5.2537098],
          [115.2689553, 5.2543142],
          [115.2691117, 5.2547912],
          [115.2692872, 5.2549677],
          [115.2699566, 5.2551279],
          [115.2710167, 5.2549163],
          [115.2719123, 5.2549507],
          [115.2726816, 5.254877],
          [115.2736531, 5.2546384],
          [115.2737305, 5.2544861],
          [115.2736751, 5.2540828],
          [115.273629, 5.2536048],
          [115.2734852, 5.2533847],
          [115.27342, 5.2532848],
          [115.273267, 5.2531228],
          [115.2730732, 5.2530464],
          [115.2729108, 5.2531227],
          [115.2724776, 5.2531563],
          [115.2716998, 5.2531169],
          [115.2710843, 5.2530117],
          [115.2709237, 5.2528905],
          [115.2707075, 5.2528631],
          [115.2706031, 5.2528498],
          [115.2705053, 5.252849],
          [115.2699869, 5.2528448],
          [115.2697893, 5.2527362],
          [115.2697032, 5.2525268],
          [115.2695471, 5.2523111],
          [115.2691175, 5.2521648],
          [115.2688311, 5.2521192],
          [115.2685514, 5.2519378],
          [115.268242, 5.251885],
        ],
      ],
      [
        [
          [115.3241562, 5.2646448],
          [115.3226588, 5.2656322],
          [115.3216339, 5.2661425],
          [115.320263, 5.2666859],
          [115.3187989, 5.2670835],
          [115.3177075, 5.267395],
          [115.3174479, 5.2675076],
          [115.3172882, 5.2674877],
          [115.3166094, 5.26766],
          [115.3157908, 5.2677462],
          [115.3147659, 5.2676203],
          [115.3140938, 5.2675938],
          [115.3130223, 5.267342],
          [115.3124966, 5.2672492],
          [115.3116181, 5.2673154],
          [115.3108594, 5.2673883],
          [115.3079445, 5.267607],
          [115.3075585, 5.267554],
          [115.3071393, 5.2676335],
          [115.3068997, 5.2678191],
          [115.3063739, 5.2687535],
          [115.3063007, 5.2693367],
          [115.3063606, 5.2701584],
          [115.3063473, 5.270609],
          [115.306507, 5.2709139],
          [115.306913, 5.2713314],
          [115.3076384, 5.271782],
          [115.3085302, 5.2725838],
          [115.3089827, 5.2733062],
          [115.3091158, 5.2743068],
          [115.3091158, 5.2746117],
          [115.3095683, 5.2753936],
          [115.3102006, 5.2768847],
          [115.3105333, 5.2774744],
          [115.3105932, 5.2777594],
          [115.3106731, 5.2780974],
          [115.3124034, 5.2797077],
          [115.3125964, 5.2803836],
          [115.313029, 5.2811457],
          [115.3134349, 5.2814903],
          [115.3146661, 5.2818481],
          [115.3156644, 5.2818084],
          [115.3161635, 5.2816029],
          [115.3167292, 5.2813975],
          [115.3175145, 5.2807613],
          [115.3182665, 5.2806752],
          [115.3189187, 5.2805493],
          [115.3194178, 5.280271],
          [115.3195376, 5.2799529],
          [115.319877, 5.2795619],
          [115.3200434, 5.2795023],
          [115.3202963, 5.2791709],
          [115.3204074, 5.2791305],
          [115.3207155, 5.2790185],
          [115.3211015, 5.2787932],
          [115.3214875, 5.2786739],
          [115.3218868, 5.278442],
          [115.3229317, 5.2780576],
          [115.3240098, 5.2778853],
          [115.3253807, 5.2777528],
          [115.3261993, 5.2776004],
          [115.3288879, 5.2774479],
          [115.3291541, 5.2773021],
          [115.3293338, 5.2770503],
          [115.3290876, 5.2769045],
          [115.3287681, 5.2764672],
          [115.3281758, 5.2758774],
          [115.3275369, 5.2751484],
          [115.327284, 5.2749231],
          [115.3269846, 5.2743863],
          [115.3267184, 5.274108],
          [115.3264189, 5.2741345],
          [115.3259553, 5.2747626],
          [115.3258466, 5.2749099],
          [115.3255804, 5.2750623],
          [115.3253874, 5.2749231],
          [115.3252494, 5.2748839],
          [115.3251078, 5.2748436],
          [115.3250147, 5.2750689],
          [115.3251054, 5.2752676],
          [115.3251478, 5.2753605],
          [115.3249215, 5.2756123],
          [115.3249481, 5.2758708],
          [115.3247152, 5.2762551],
          [115.3244024, 5.2762153],
          [115.3240697, 5.2760696],
          [115.3239698, 5.2755063],
          [115.3237369, 5.275387],
          [115.3236038, 5.2751484],
          [115.3232245, 5.2747972],
          [115.3231579, 5.2745056],
          [115.3229849, 5.2744725],
          [115.3228584, 5.2747906],
          [115.3226189, 5.2761093],
          [115.3227226, 5.276559],
          [115.3228451, 5.2770901],
          [115.322732, 5.2773883],
          [115.3224591, 5.2775937],
          [115.3210283, 5.2779648],
          [115.3210616, 5.2773684],
          [115.3212746, 5.2769841],
          [115.3221131, 5.27489],
          [115.3221197, 5.2742339],
          [115.3223327, 5.2734321],
          [115.3231646, 5.2729881],
          [115.3239432, 5.2727164],
          [115.3246553, 5.2727826],
          [115.3249614, 5.2730543],
          [115.3251345, 5.2735248],
          [115.3249215, 5.2738098],
          [115.3248882, 5.2741146],
          [115.3250746, 5.2744725],
          [115.3255005, 5.27434],
          [115.3258931, 5.2739026],
          [115.3259198, 5.2732399],
          [115.3262858, 5.272564],
          [115.3265853, 5.2722923],
          [115.3269846, 5.2722591],
          [115.3273639, 5.2724977],
          [115.3274571, 5.2729218],
          [115.327284, 5.273114],
          [115.3271576, 5.2734652],
          [115.3269912, 5.2734652],
          [115.3268981, 5.2736309],
          [115.3272175, 5.2738496],
          [115.3274105, 5.2742207],
          [115.3273373, 5.2745918],
          [115.3274371, 5.2746845],
          [115.3275702, 5.2745984],
          [115.3277765, 5.2744327],
          [115.3281559, 5.2731339],
          [115.3281292, 5.2729152],
          [115.3279229, 5.2725109],
          [115.3272241, 5.2717025],
          [115.326725, 5.2709404],
          [115.3263657, 5.2702379],
          [115.3261194, 5.2694427],
          [115.3258599, 5.2684023],
          [115.3257334, 5.267216],
          [115.3259996, 5.2664738],
          [115.3260462, 5.2659238],
          [115.3263657, 5.2651087],
          [115.3265653, 5.2644062],
          [115.3264455, 5.2639755],
          [115.3267184, 5.2634453],
          [115.3266319, 5.2634453],
          [115.3263523, 5.2639026],
          [115.3262059, 5.263949],
          [115.3259663, 5.2640219],
          [115.3255737, 5.2640351],
          [115.3241562, 5.2646448],
        ],
      ],
      [
        [
          [115.324835, 5.2749297],
          [115.3246553, 5.2749165],
          [115.3245222, 5.2752213],
          [115.3246154, 5.2754002],
          [115.3247684, 5.2753936],
          [115.324835, 5.2749297],
        ],
      ],
      [
        [
          [115.2489945, 5.2731834],
          [115.2489781, 5.2731929],
          [115.2489737, 5.2731955],
          [115.2477807, 5.2736375],
          [115.2471208, 5.273876],
          [115.2471151, 5.2738781],
          [115.2463054, 5.2741707],
          [115.2452015, 5.2743195],
          [115.244321, 5.2746653],
          [115.2435364, 5.2750289],
          [115.243725, 5.2742884],
          [115.2449984, 5.2724595],
          [115.2448455, 5.2723574],
          [115.2435741, 5.2741883],
          [115.2436164, 5.2742183],
          [115.2432947, 5.2754367],
          [115.241716, 5.2761293],
          [115.2416745, 5.276615],
          [115.2414622, 5.2766259],
          [115.2407646, 5.2775351],
          [115.2407667, 5.2775753],
          [115.2406844, 5.2776677],
          [115.2406648, 5.2777392],
          [115.2406067, 5.2777851],
          [115.2405982, 5.2778936],
          [115.2403583, 5.2780832],
          [115.2402765, 5.2781478],
          [115.2397575, 5.2786744],
          [115.2396438, 5.2788042],
          [115.2392051, 5.2788714],
          [115.2386558, 5.2793674],
          [115.2386942, 5.2794531],
          [115.2386333, 5.279588],
          [115.2383804, 5.2798353],
          [115.2383071, 5.2799773],
          [115.238339, 5.2800313],
          [115.2382433, 5.2804146],
          [115.2381822, 5.2804825],
          [115.2381428, 5.2804716],
          [115.2379226, 5.2807821],
          [115.2379503, 5.2808887],
          [115.2385349, 5.2811234],
          [115.238638, 5.2810702],
          [115.2390593, 5.2812279],
          [115.2391761, 5.2812917],
          [115.2392033, 5.2813066],
          [115.2392285, 5.2813249],
          [115.238382, 5.2821288],
          [115.2383391, 5.2824493],
          [115.239412, 5.2837847],
          [115.2396909, 5.2842013],
          [115.2402167, 5.2847782],
          [115.2399033, 5.2849565],
          [115.239766, 5.2850346],
          [115.2374057, 5.2862845],
          [115.2361825, 5.2858636],
          [115.2360511, 5.2860382],
          [115.2359956, 5.2864059],
          [115.2357928, 5.2866952],
          [115.235412, 5.2866273],
          [115.2353999, 5.2863526],
          [115.2348609, 5.285738],
          [115.2348199, 5.2856523],
          [115.2343579, 5.2852995],
          [115.2341331, 5.2850892],
          [115.2341789, 5.2848061],
          [115.2339982, 5.2843861],
          [115.2335591, 5.2843391],
          [115.2335438, 5.2826249],
          [115.233635, 5.2820009],
          [115.2352936, 5.2815257],
          [115.235361, 5.2807703],
          [115.2355073, 5.2800128],
          [115.2353211, 5.2792337],
          [115.235288, 5.2789428],
          [115.2356565, 5.278049],
          [115.2349021, 5.2780025],
          [115.2349992, 5.2778254],
          [115.2351201, 5.2776908],
          [115.2352893, 5.2776399],
          [115.2354087, 5.2774341],
          [115.2353331, 5.2772001],
          [115.2351114, 5.2769213],
          [115.235423, 5.2762986],
          [115.2354439, 5.2733968],
          [115.236822, 5.2735022],
          [115.2371538, 5.2726586],
          [115.2374735, 5.2718921],
          [115.2379263, 5.272058],
          [115.2397801, 5.2676018],
          [115.2393295, 5.2674415],
          [115.2388038, 5.2687128],
          [115.2378382, 5.2682748],
          [115.2376772, 5.2684992],
          [115.2370872, 5.2678154],
          [115.2366535, 5.2676862],
          [115.2367331, 5.2670248],
          [115.2364542, 5.2666509],
          [115.2366258, 5.2658924],
          [115.2376129, 5.2655398],
          [115.2388574, 5.2602835],
          [115.2401449, 5.2593968],
          [115.2404667, 5.2592151],
          [115.2406491, 5.2590121],
          [115.2405633, 5.2588305],
          [115.2407886, 5.2585955],
          [115.2410354, 5.2588626],
          [115.2412607, 5.2586809],
          [115.2418615, 5.259354],
          [115.2433743, 5.259119],
          [115.2441897, 5.2578797],
          [115.2455522, 5.2576767],
          [115.2455415, 5.2574523],
          [115.2456599, 5.2574251],
          [115.2457476, 5.257439],
          [115.2458312, 5.2574523],
          [115.2462432, 5.2572472],
          [115.2463247, 5.2572066],
          [115.2464053, 5.2544504],
          [115.2464382, 5.2533274],
          [115.245917, 5.252165],
          [115.2457775, 5.251854],
          [115.2449621, 5.2513733],
          [115.2444364, 5.2507963],
          [115.2441789, 5.2501874],
          [115.2440502, 5.2493006],
          [115.2441413, 5.2488898],
          [115.2442326, 5.248478],
          [115.2432133, 5.246213],
          [115.2426021, 5.2448745],
          [115.242061, 5.2436896],
          [115.2411105, 5.2416082],
          [115.2401342, 5.2416082],
          [115.2377953, 5.2459245],
          [115.2376394, 5.246188],
          [115.2360452, 5.2489588],
          [115.2357938, 5.2483315],
          [115.2333213, 5.2494929],
          [115.2301456, 5.2503156],
          [115.2264978, 5.2542045],
          [115.2285148, 5.2562664],
          [115.2281071, 5.2569929],
          [115.2239956, 5.2601822],
          [115.2242048, 5.2609093],
          [115.2244764, 5.2619315],
          [115.2241482, 5.2626909],
          [115.2237848, 5.2629896],
          [115.2237081, 5.2633982],
          [115.2225643, 5.2662024],
          [115.2227677, 5.2670476],
          [115.2226806, 5.267943],
          [115.2223136, 5.268841],
          [115.2216913, 5.2718324],
          [115.2210476, 5.2737448],
          [115.2192451, 5.275155],
          [115.2195348, 5.2755075],
          [115.2185906, 5.2762233],
          [115.2174345, 5.2758982],
          [115.2153935, 5.2762767],
          [115.2145566, 5.277548],
          [115.2138728, 5.2791587],
          [115.213075, 5.2794804],
          [115.2122628, 5.2789593],
          [115.2111985, 5.278595],
          [115.2106191, 5.2778151],
          [115.2099647, 5.2779754],
          [115.2096106, 5.2787446],
          [115.2092887, 5.2794283],
          [115.2084304, 5.279674],
          [115.206956, 5.2794807],
          [115.2054371, 5.279204],
          [115.2039887, 5.2782638],
          [115.2012958, 5.2766827],
          [115.1994289, 5.2772169],
          [115.1987492, 5.2777923],
          [115.1981252, 5.2779779],
          [115.1974763, 5.277751],
          [115.195798, 5.2776004],
          [115.1930936, 5.276055],
          [115.191607, 5.2723763],
          [115.189703, 5.2706522],
          [115.189494, 5.2685846],
          [115.1867551, 5.2656373],
          [115.1859309, 5.2647504],
          [115.182177, 5.2607108],
          [115.1804926, 5.2621852],
          [115.1787008, 5.2619181],
          [115.177422, 5.2610293],
          [115.1764478, 5.2606788],
          [115.1753244, 5.2602083],
          [115.1748189, 5.2595445],
          [115.1729257, 5.2580217],
          [115.1746086, 5.2563201],
          [115.1753017, 5.255783],
          [115.1751973, 5.2556436],
          [115.1745565, 5.2559195],
          [115.1736476, 5.2556788],
          [115.1725425, 5.2550164],
          [115.1720019, 5.254497],
          [115.1720152, 5.2540271],
          [115.1724557, 5.2535532],
          [115.1720218, 5.2526306],
          [115.170504, 5.2515763],
          [115.169162, 5.2509139],
          [115.1686694, 5.2502942],
          [115.1675107, 5.2497493],
          [115.165837, 5.2492899],
          [115.1653181, 5.2482924],
          [115.163538, 5.2487172],
          [115.1626119, 5.2492914],
          [115.1604296, 5.2505399],
          [115.1599208, 5.2504214],
          [115.1583286, 5.2507198],
          [115.1576785, 5.2505824],
          [115.1572199, 5.2508203],
          [115.1570572, 5.2513108],
          [115.1572027, 5.2519382],
          [115.1576751, 5.2530018],
          [115.1577262, 5.253401],
          [115.1576399, 5.2542152],
          [115.1573226, 5.2553369],
          [115.1573027, 5.2557689],
          [115.15808, 5.2566404],
          [115.1591207, 5.2576874],
          [115.1594855, 5.2583711],
          [115.1598825, 5.2595891],
          [115.1604461, 5.2599923],
          [115.1607113, 5.2603123],
          [115.1616181, 5.2625704],
          [115.1625457, 5.2642716],
          [115.1636595, 5.2656542],
          [115.1667731, 5.2679653],
          [115.1670776, 5.2701658],
          [115.1670144, 5.2714485],
          [115.1676072, 5.2732747],
          [115.1683252, 5.27489],
          [115.1689861, 5.2764281],
          [115.169979, 5.2779451],
          [115.1709709, 5.2793446],
          [115.1721708, 5.2806389],
          [115.1726906, 5.2827407],
          [115.1727657, 5.2845426],
          [115.1723211, 5.2862284],
          [115.1720914, 5.2890569],
          [115.1718844, 5.2924771],
          [115.1722895, 5.2945143],
          [115.1725983, 5.2953317],
          [115.1729301, 5.2962933],
          [115.1739338, 5.2989221],
          [115.1753053, 5.3015961],
          [115.176556, 5.3046759],
          [115.1783096, 5.3076882],
          [115.1799589, 5.3095305],
          [115.181113, 5.3105514],
          [115.1819357, 5.3115732],
          [115.1831177, 5.3136474],
          [115.1840909, 5.3150641],
          [115.184465, 5.3158119],
          [115.1838936, 5.3160283],
          [115.1839657, 5.3161612],
          [115.1846953, 5.3159232],
          [115.1849279, 5.3161049],
          [115.1848912, 5.3163172],
          [115.1841189, 5.3164449],
          [115.1836021, 5.3164097],
          [115.1835024, 5.3165704],
          [115.1840262, 5.3167158],
          [115.1851731, 5.3181742],
          [115.1858141, 5.3199061],
          [115.1859148, 5.3210799],
          [115.1860108, 5.3224064],
          [115.1859113, 5.3232354],
          [115.1864119, 5.3240649],
          [115.1873595, 5.3251391],
          [115.1883095, 5.3267872],
          [115.1887973, 5.3279683],
          [115.1891192, 5.3284811],
          [115.18982, 5.3294852],
          [115.1903025, 5.3301107],
          [115.1908754, 5.3308081],
          [115.1913629, 5.3314126],
          [115.1923211, 5.3331792],
          [115.1937337, 5.3344854],
          [115.1943046, 5.3359832],
          [115.1947866, 5.3383355],
          [115.1965079, 5.3408414],
          [115.1970882, 5.3416269],
          [115.1978092, 5.3427841],
          [115.1985547, 5.3439274],
          [115.1992576, 5.3454809],
          [115.1990506, 5.3462139],
          [115.1983602, 5.3466695],
          [115.197596, 5.3464746],
          [115.197299, 5.346891],
          [115.1979887, 5.3472937],
          [115.1992805, 5.3480212],
          [115.1997836, 5.3482925],
          [115.2007728, 5.3488736],
          [115.2028858, 5.350144],
          [115.2062549, 5.3520412],
          [115.206689, 5.3528873],
          [115.2068511, 5.3540403],
          [115.2084427, 5.3570423],
          [115.2099058, 5.3595559],
          [115.2131056, 5.3627869],
          [115.2184679, 5.3680421],
          [115.220821, 5.3715347],
          [115.2226946, 5.3726936],
          [115.2251277, 5.3747668],
          [115.2270826, 5.3762784],
          [115.2285026, 5.3778992],
          [115.2291612, 5.3807661],
          [115.2300026, 5.3824598],
          [115.2307262, 5.3835992],
          [115.2322965, 5.3846014],
          [115.2345094, 5.3860372],
          [115.2357804, 5.3872372],
          [115.2368798, 5.3875628],
          [115.2369511, 5.3890202],
          [115.2374056, 5.3882309],
          [115.2406843, 5.3868087],
          [115.2421377, 5.3870263],
          [115.2431766, 5.3880137],
          [115.2440133, 5.3883509],
          [115.2446329, 5.3884564],
          [115.2454082, 5.3888682],
          [115.2471609, 5.3899326],
          [115.2487936, 5.3912923],
          [115.2501352, 5.3921047],
          [115.2517456, 5.3932558],
          [115.2520412, 5.3931765],
          [115.252006, 5.3928641],
          [115.2518027, 5.3923738],
          [115.2519406, 5.3907378],
          [115.2524463, 5.38921],
          [115.2538196, 5.3869883],
          [115.2547357, 5.3860129],
          [115.2557079, 5.3852792],
          [115.2567155, 5.3845857],
          [115.2571536, 5.384434],
          [115.2572482, 5.3842053],
          [115.2570488, 5.3839571],
          [115.2565746, 5.3839993],
          [115.2561914, 5.3837838],
          [115.2559744, 5.3833245],
          [115.2560512, 5.3827157],
          [115.2559298, 5.3813298],
          [115.2550364, 5.3810252],
          [115.2531664, 5.3808979],
          [115.2519022, 5.3809094],
          [115.2512564, 5.3808488],
          [115.2508441, 5.3805361],
          [115.2505244, 5.3801043],
          [115.2502114, 5.379767],
          [115.2499533, 5.3791493],
          [115.2497345, 5.3786895],
          [115.2494739, 5.3779811],
          [115.2489397, 5.3778022],
          [115.2485744, 5.3775102],
          [115.2482622, 5.3770181],
          [115.2479213, 5.3767275],
          [115.2476686, 5.3765229],
          [115.2475032, 5.3762444],
          [115.2473377, 5.3758447],
          [115.2471453, 5.375599],
          [115.2471354, 5.3750372],
          [115.2471681, 5.3731692],
          [115.246912, 5.372707],
          [115.2469643, 5.3720439],
          [115.2469367, 5.3704683],
          [115.2464324, 5.3694661],
          [115.2441937, 5.3674806],
          [115.2440349, 5.3652832],
          [115.2440349, 5.3574213],
          [115.2439668, 5.3565899],
          [115.2440613, 5.3554346],
          [115.2440308, 5.3552128],
          [115.2439692, 5.3548775],
          [115.2438635, 5.354302],
          [115.2440111, 5.3526954],
          [115.2441192, 5.3514998],
          [115.2443343, 5.3503723],
          [115.2447117, 5.3492093],
          [115.2449727, 5.3478783],
          [115.2448686, 5.3475974],
          [115.2452366, 5.3466199],
          [115.2454649, 5.3461056],
          [115.24593, 5.3451447],
          [115.2463291, 5.3446045],
          [115.246449, 5.3442076],
          [115.2463345, 5.3438874],
          [115.2464565, 5.3434877],
          [115.2471941, 5.3401911],
          [115.24848, 5.3375146],
          [115.2490749, 5.3365992],
          [115.249496, 5.3353173],
          [115.2498666, 5.3335169],
          [115.2498436, 5.3327819],
          [115.2494225, 5.3321705],
          [115.2491399, 5.3317331],
          [115.2490088, 5.3309758],
          [115.2492588, 5.330176],
          [115.2502216, 5.3286592],
          [115.2504295, 5.3269129],
          [115.2498937, 5.3252291],
          [115.2515727, 5.3195027],
          [115.2535181, 5.3171785],
          [115.2548496, 5.3146919],
          [115.2558689, 5.3119969],
          [115.2574489, 5.3102293],
          [115.2592625, 5.3078665],
          [115.2608577, 5.305462],
          [115.2616306, 5.3044945],
          [115.2623031, 5.3030841],
          [115.2629292, 5.3004131],
          [115.2637888, 5.2983982],
          [115.2641193, 5.2977703],
          [115.2647628, 5.2967761],
          [115.2640317, 5.2959981],
          [115.2649052, 5.2942552],
          [115.2665132, 5.2924599],
          [115.2696311, 5.2891052],
          [115.2678878, 5.2872204],
          [115.2665225, 5.2868308],
          [115.2660046, 5.285922],
          [115.2657477, 5.2848795],
          [115.2646071, 5.2845398],
          [115.2642674, 5.2841147],
          [115.2636905, 5.2835632],
          [115.2633602, 5.2831942],
          [115.262743, 5.2827865],
          [115.2622607, 5.2815331],
          [115.2620996, 5.2812863],
          [115.261935, 5.280994],
          [115.2617468, 5.2806764],
          [115.2609946, 5.2789772],
          [115.2603855, 5.2777472],
          [115.2597956, 5.2765129],
          [115.2591655, 5.2761236],
          [115.2558024, 5.2740456],
          [115.2559851, 5.2735827],
          [115.256047, 5.2735686],
          [115.256086, 5.2735298],
          [115.2561054, 5.2734787],
          [115.2561001, 5.2734276],
          [115.2560718, 5.2733906],
          [115.2560276, 5.2733642],
          [115.2559745, 5.2733624],
          [115.2559302, 5.2733853],
          [115.2559055, 5.2734188],
          [115.2558895, 5.2734893],
          [115.2559037, 5.2735316],
          [115.2559355, 5.2735615],
          [115.255761, 5.2740164],
          [115.2541511, 5.2727413],
          [115.2535104, 5.2725686],
          [115.2526283, 5.2725532],
          [115.2526227, 5.272466],
          [115.2525664, 5.272474],
          [115.2525235, 5.2720493],
          [115.2525691, 5.2719585],
          [115.2526818, 5.2719532],
          [115.2526818, 5.2718757],
          [115.252199, 5.2719264],
          [115.2522258, 5.2719932],
          [115.2523277, 5.2719852],
          [115.2524888, 5.2721472],
          [115.2525286, 5.2725669],
          [115.2521417, 5.2726037],
          [115.2498971, 5.2728562],
          [115.2498172, 5.2728727],
          [115.2495421, 5.2729294],
          [115.2489987, 5.273182],
          [115.2489945, 5.2731834],
        ],
      ],
    ],
  },
};
