import React from "react";
import { GeoJSON } from "react-leaflet";
import { malaysiaStates } from "./malaysia";
const Malaysia = ({ theme, openDrawer }) => {
  const onEachFeature = (feature, layer) => {
    layer.on({
      click: openDrawer,
    });
  };

  return (
    <GeoJSON
      color={theme === "light" ? "#212121" : "#fff"}
      weight="2"
      fillColor={theme === "dark" ? "#212121" : "#fff"}
      fillOpacity="0.2"
      data={malaysiaStates}
      onEachFeature={onEachFeature}
    />
  );
};

export default Malaysia;
