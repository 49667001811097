const initialState = {
  total: 1,
  balance: {
    main: 1,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_PLUS":
      return {
        total: state.total + action.payload,
        balance: {
          main: state.balance.main + action.payload,
        },
      };
    case "ACTION_MINUS":
      return {
        total: state.total - action.payload,
        balance: {
          main: state.balance.main - action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
