import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
//to be used when custom components does not have customize style 
//and only able to customize on top of their css class.

body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
  }

  a {
    color:red;
    text-decoration: none;
    font-weight: bold;
    :hover,
    :focus,
    :active{
      opacity: 0.95;
      font-weight: bold;
    }
  }

`;
