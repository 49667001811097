import { johor } from "./states/johor/";
import { kedah } from "./states/kedah/";
import { kelantan } from "./states/kelantan/";
import { kualalumpur } from "./states/kualalumpur/";
import { labuan } from "./states/labuan/";
import { melacca } from "./states/melacca/";
import { negerisembilan } from "./states/negerisembilan/";
import { selangor } from "./states/selangor";
import { pahang } from "./states/pahang";
import { penang } from "./states/penang";
import { sabah } from "./states/sabah";
import { sarawak } from "./states/sarawak";
import { perak } from "./states/perak";
import { perlis } from "./states/perlis";
import { putrajaya } from "./states/putrajaya";
import { terengganu } from "./states/terengganu";
export const malaysiaStates = [
  {
    type: "FeatureCollection",
    name: "malaysia_singapore_brunei_administrative_malaysia_state_province_boundary",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      johor,
      kedah,
      kelantan,
      kualalumpur,
      labuan,
      melacca,
      negerisembilan,
      selangor,
      pahang,
      penang,
      sabah,
      perak,
      perlis,
      putrajaya,
      terengganu,
      sarawak,
    ],
  },
];
