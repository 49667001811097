import React, { useEffect } from "react";
import { Marker as LMarker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import WhiteMarker from "./src/white.png";
import BlackMarker from "./src/black.png";
import RedMarker from "./src/red.png";

const Marker = ({ theme, zoom, position, setPosition }) => {
  const map = useMap();

  const marker = new L.Icon({
    iconUrl: theme === "light" ? BlackMarker : WhiteMarker,

    iconSize: [30, 30],
  });

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
      const circle = L.circle(e.latlng, {
        color: "red",
        weight: 2,
        radius: 3,
        fill: true,
        fillColor: "red",
        fillOpacity: theme === "light" ? "0.55" : "0.25",
        className: "frame-circle",
        radius: 10000,
      });
      circle.addTo(map).bringToBack();
    });
  }, [map]);

  return position === null ? null : zoom <= 10 ? (
    ""
  ) : (
    <LMarker position={position} icon={marker} />
  );
};

export default Marker;
