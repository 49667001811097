import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./state/store";
import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { useTheme } from "./theme/useTheme";
import { palette, lightTheme, darkTheme } from "./theme/theme";
import { Styles } from "./theme/style";
import Pages from "./pages";
import translate from "./translate";
import Loading from "./components/loading";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "./state/index";
import { useTranslation } from "react-i18next";

const App = (props) => {
  //theme related
  const [theme, switchTheme, componentMounted] = useTheme();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  const muiThemeMode = theme === "light" ? palette.light : palette.dark;
  const muiTheme = createMuiTheme({
    palette: {
      primary: { main: muiThemeMode.primary },
    },
    typography: {
      fontFamily: "Raleway",
    },
    type: theme,
  });

  const [loadingScreen, setLoadingScreen] = useState(false);
  const loading = (yesno) => {
    setLoadingScreen(yesno);
  };

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const Actions = bindActionCreators(actionCreators, dispatch);

  //translation related
  const switchLanguage = (lng) => {
    translate.changeLanguage(lng);
  };
  const { t } = useTranslation();

  const commonProps = {
    theme: theme,
    switchTheme: switchTheme,
    switchLanguage: switchLanguage,
    loading: loading,
    state: state,
    function: Actions,
    translate: t,
  };

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={themeMode}>
          <Styles />
          <Pages common={commonProps} />
          <Loading
            show={loadingScreen}
            closeLoad={() => {
              loading(false);
            }}
          />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
