import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const LoadingWrapper = styled(Grid)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.body};
`;

const Loading = ({ show, closeLoad }) => {
  //TODO: Remove this line if overflow problem persist.
  if (show) {
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
  } else {
    document.body.style.position = "";
    document.body.style.top = "";
    // window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  return show ? (
    <LoadingWrapper
      container
      justify="center"
      alignItems="center"
      onClick={() => {
        closeLoad();
      }}
    >
      Please close this by pressing on the screen.
      <br />
      To apply it, please call it using loading(true)
    </LoadingWrapper>
  ) : (
    ""
  );
};

export default Loading;
