import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Button from "../../button";

const Title = styled.div`
  font-family: "Staatliches", cursive;
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  opacity: 0.8;
  padding: 8px 0px;
`;

const ThemeDialog = ({ open, close, translate, changetheme }) => {
  const changeTheme = () => {
    changetheme();
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogContent>
        <Title>{translate("theme.title")} ?</Title>
        <Description>{translate("theme.description")}</Description>
      </DialogContent>

      <DialogActions>
        <Button
          click={close}
          color="primary"
          name={translate("general.cancel")}
        />
        <Button
          color="primary"
          click={changeTheme}
          name={translate("general.confirm")}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ThemeDialog;
