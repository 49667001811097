import { Selangor } from "./state/selangor";

export const ConfirmedCaseMalaysia = {
  new: 17045,
  recover: 9683,
  active: 160903,
  death: 92,
};

export const MalaysiaStateData = [
  {
    id: 1,
    name: "Johor",
    data: "",
  },
  {
    id: 2,
    name: "Kedah",
    data: "",
  },
  {
    id: 3,
    name: "Kelantan",
    data: "",
  },
  {
    id: 4,
    name: "Kuala Lumpur",
    data: "",
  },
  {
    id: 5,
    name: "Labuan",
    data: "",
  },
  {
    id: 6,
    name: "Melacca",
    data: "",
  },
  {
    id: 7,
    name: "Negeri Sembilan",
    data: "",
  },
  {
    id: 8,
    name: "Pahang",
    data: "",
  },
  {
    id: 9,
    name: "Penang",
    data: "",
  },
  {
    id: 10,
    name: "Perak",
    data: "",
  },
  {
    id: 11,
    name: "Perlis",
    data: "",
  },
  {
    id: 12,
    name: "Putrajaya",
    data: "",
  },
  {
    id: 13,
    name: "Sabah",
    data: "",
  },
  {
    id: 14,
    name: "Sarawak",
    data: "",
  },
  {
    id: 15,
    name: "Selangor",
    data: Selangor,
  },
  {
    id: 16,
    name: "Terengganu",
    data: "",
  },
];
