import translate from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./en/english.json";
import chinese from "./cn/chinese.json";

translate.on("languageChanged", function (lng) {
  //do whatever stuff whenever language changed.
  window.localStorage.setItem("language", lng);
});

const localLanguage = window.localStorage.getItem("language");

translate.use(initReactI18next).init({
  resources: {
    en: {
      translation: english,
    },
    cn: {
      translation: chinese,
    },
  },
  lng: localLanguage, // default language, get from localStorage
  fallbackLng: ["en"], // if no default language found, switch to English.
  whitelist: ["en", "cn"],
  detection: {
    checkWhitelist: true,
  },
  interpolation: {
    escapeValue: false,
    formatSeparator: ".",
  },
});

export default translate;
