export const palette = {
  light: {
    primary: "#fff",
  },
  dark: { primary: "#212121" },
};

export const lightTheme = {
  variant: "light",
  body: "#fff",
  invert: "#212121",
  text: "#212121",
};

export const darkTheme = {
  variant: "dark",
  body: "#212121",
  invert: "#fff",
  text: "#fff",
};
