import React, { useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Marker from "../../components/marker";
import Zone from "../../components/zone";
import AppBar from "../../components/appbar";
import { malaysiaStates } from "../../components/malaysia/malaysia";
import Overlay from "../../components/overlay";

const Landing = (props) => {
  const mapSettings = {
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: true,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: true,
    zoomControl: false,
    zoom: 12,
    minZoom: 7,
    maxZoom: 13,
  };

  const mapRef = useRef();
  const [zoomLevel, setZoomLevel] = useState(5);
  const [position, setPosition] = useState(null);

  return (
    <div>
      <AppBar {...props} />

      <MapContainer
        center={[4.1093195, 109.45547499999998]}
        {...mapSettings}
        style={{ height: "100vh" }}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}
      >
        <TileLayer
          url={
            props.theme === "dark"
              ? "https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png"
              : "https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
          }
        />

        {
          //https://wiki.openstreetmap.org/wiki/Tiles#Servers
        }

        <Zone
          theme={props.theme}
          setZoom={setZoomLevel}
          position={position}
          translate={props.translate}
          setPosition={setPosition}
        />
        <Marker
          theme={props.theme}
          zoom={zoomLevel}
          position={position}
          setPosition={setPosition}
        />
      </MapContainer>
      <Overlay {...props} />
    </div>
  );
};

export default Landing;
