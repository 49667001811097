const format = {
  toNumberWithCommaAndDecimalPoint,
  numberWithCommas,
};

function toNumberWithCommaAndDecimalPoint(num) {
  if (isNaN(num)) return num;
  return parseFloat(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default format;
