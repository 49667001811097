import About from "../pages/about";
import Landing from "../pages/landing";

//Please read this:
//NormalRouting is for pages that doesn't required any sort of authentication and/or verification.
//SecuredRouting is for pages that required any sort of authentication and/or verification.

const NormalRouting = [
  { path: "/", component: Landing },
  { path: "/about", component: About },
];

const SecuredRouting = [{ path: "/loggedin", component: "" }];

const Routes = {
  NormalRouting,
  SecuredRouting,
};

export default Routes;
