export const perlis = {
  type: "Feature",
  properties: {
    gid: 11,
    id: 4444918,
    country: "MYS",
    name: "Perlis",
    enname: "Perlis",
    locname: "Perlis",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q213467",
    wikimedia: "en:Perlis",
    timestamp: "2019-12-30 19:12:02",
    note: null,
    path: "0,2108121,4444918",
    rpath: "4444918,2108121,0",
    iso3166_2: "MY-09",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [100.1217712, 6.4185458],
          [100.1203879, 6.4227403],
          [100.1211206, 6.422825],
          [100.1235919, 6.4228111],
          [100.123814, 6.422881],
          [100.124268, 6.423152],
          [100.124616, 6.423621],
          [100.124963, 6.424213],
          [100.124963, 6.424411],
          [100.124776, 6.424806],
          [100.124776, 6.425003],
          [100.124883, 6.425225],
          [100.125177, 6.425398],
          [100.125899, 6.425719],
          [100.126219, 6.425817],
          [100.126593, 6.42562],
          [100.126968, 6.424978],
          [100.127796, 6.425348],
          [100.128224, 6.425817],
          [100.128705, 6.426039],
          [100.129881, 6.427816],
          [100.13111, 6.42831],
          [100.133355, 6.429075],
          [100.133569, 6.429247],
          [100.133729, 6.429568],
          [100.133889, 6.430679],
          [100.134263, 6.431197],
          [100.134531, 6.432012],
          [100.134905, 6.432678],
          [100.134985, 6.434726],
          [100.135225, 6.435318],
          [100.135172, 6.435491],
          [100.134424, 6.436207],
          [100.134424, 6.436453],
          [100.134531, 6.436626],
          [100.134584, 6.436898],
          [100.134504, 6.437243],
          [100.134237, 6.437934],
          [100.134183, 6.438452],
          [100.134183, 6.439069],
          [100.134317, 6.439415],
          [100.135172, 6.440254],
          [100.135546, 6.440501],
          [100.135893, 6.440599],
          [100.136481, 6.440501],
          [100.136294, 6.440846],
          [100.136107, 6.442919],
          [100.136027, 6.443191],
          [100.135706, 6.443882],
          [100.135706, 6.444178],
          [100.13584, 6.444499],
          [100.13616, 6.444869],
          [100.137844, 6.445757],
          [100.138111, 6.445955],
          [100.139127, 6.447262],
          [100.139741, 6.448225],
          [100.140035, 6.448398],
          [100.140597, 6.448398],
          [100.141505, 6.447978],
          [100.142254, 6.447559],
          [100.142735, 6.447534],
          [100.142735, 6.448126],
          [100.142841, 6.448669],
          [100.143002, 6.44899],
          [100.143349, 6.449113],
          [100.143643, 6.449113],
          [100.144151, 6.449015],
          [100.144204, 6.449138],
          [100.144178, 6.449385],
          [100.143937, 6.450322],
          [100.143991, 6.450717],
          [100.144204, 6.450939],
          [100.144525, 6.451038],
          [100.144953, 6.451087],
          [100.145434, 6.45131],
          [100.145647, 6.45131],
          [100.145968, 6.451112],
          [100.146316, 6.451038],
          [100.146529, 6.451087],
          [100.146797, 6.451211],
          [100.147171, 6.451606],
          [100.147572, 6.451951],
          [100.147919, 6.452173],
          [100.148026, 6.452371],
          [100.148026, 6.452642],
          [100.147919, 6.452988],
          [100.147545, 6.453827],
          [100.147385, 6.454345],
          [100.147117, 6.454839],
          [100.146904, 6.455085],
          [100.14669, 6.455184],
          [100.146583, 6.455332],
          [100.146529, 6.455505],
          [100.146556, 6.45585],
          [100.146396, 6.456295],
          [100.146369, 6.456986],
          [100.146129, 6.45743],
          [100.145915, 6.458294],
          [100.145113, 6.459281],
          [100.145033, 6.459552],
          [100.145033, 6.460218],
          [100.145113, 6.460465],
          [100.145033, 6.460885],
          [100.145086, 6.461107],
          [100.14538, 6.461699],
          [100.14538, 6.462217],
          [100.145247, 6.46244],
          [100.144926, 6.462686],
          [100.144579, 6.46281],
          [100.144498, 6.463007],
          [100.144579, 6.463229],
          [100.144739, 6.463377],
          [100.145113, 6.46392],
          [100.145434, 6.464562],
          [100.145514, 6.464883],
          [100.145434, 6.466018],
          [100.145487, 6.466388],
          [100.145647, 6.46661],
          [100.146235, 6.466955],
          [100.146182, 6.467375],
          [100.145941, 6.467696],
          [100.145701, 6.468189],
          [100.145621, 6.46856],
          [100.145647, 6.468732],
          [100.145728, 6.468806],
          [100.145915, 6.468831],
          [100.146262, 6.468757],
          [100.14701, 6.468411],
          [100.147224, 6.468387],
          [100.147438, 6.468485],
          [100.147919, 6.468905],
          [100.148213, 6.469102],
          [100.148801, 6.469695],
          [100.149282, 6.469917],
          [100.150084, 6.470164],
          [100.150404, 6.470361],
          [100.150778, 6.471003],
          [100.151473, 6.471891],
          [100.151741, 6.472434],
          [100.151767, 6.472631],
          [100.15166, 6.473569],
          [100.15126, 6.474754],
          [100.15118, 6.475124],
          [100.151153, 6.475519],
          [100.15126, 6.475839],
          [100.151474, 6.476062],
          [100.151821, 6.476234],
          [100.152276, 6.476333],
          [100.152757, 6.476506],
          [100.153024, 6.476876],
          [100.153211, 6.47695],
          [100.153264, 6.477468],
          [100.153612, 6.477567],
          [100.154039, 6.477592],
          [100.154066, 6.477789],
          [100.153852, 6.478455],
          [100.153959, 6.478702],
          [100.154146, 6.478801],
          [100.154333, 6.478727],
          [100.15444, 6.478579],
          [100.15452, 6.478233],
          [100.15452, 6.478011],
          [100.155001, 6.477616],
          [100.155269, 6.477468],
          [100.155616, 6.477419],
          [100.156792, 6.477789],
          [100.157487, 6.478134],
          [100.157888, 6.478505],
          [100.158235, 6.478949],
          [100.158556, 6.479516],
          [100.159063, 6.480652],
          [100.158957, 6.480997],
          [100.158796, 6.48117],
          [100.158529, 6.481639],
          [100.158021, 6.481935],
          [100.15762, 6.482009],
          [100.157433, 6.482132],
          [100.157246, 6.48228],
          [100.156685, 6.483095],
          [100.156525, 6.483193],
          [100.156364, 6.483193],
          [100.156124, 6.483119],
          [100.156017, 6.482971],
          [100.155696, 6.482848],
          [100.155189, 6.483169],
          [100.154975, 6.483193],
          [100.154895, 6.483243],
          [100.154574, 6.484427],
          [100.15444, 6.484748],
          [100.154387, 6.485143],
          [100.154654, 6.485883],
          [100.154601, 6.486426],
          [100.154627, 6.486747],
          [100.154788, 6.486895],
          [100.155322, 6.48692],
          [100.15599, 6.486451],
          [100.156311, 6.486402],
          [100.156658, 6.486427],
          [100.156899, 6.486501],
          [100.157086, 6.486624],
          [100.157326, 6.486871],
          [100.157968, 6.48729],
          [100.158208, 6.487315],
          [100.158743, 6.487093],
          [100.159197, 6.487142],
          [100.159438, 6.487216],
          [100.159598, 6.48729],
          [100.159705, 6.487414],
          [100.159838, 6.488055],
          [100.160239, 6.488623],
          [100.160373, 6.488993],
          [100.1604, 6.489487],
          [100.160346, 6.489832],
          [100.160239, 6.49003],
          [100.160213, 6.49035],
          [100.16048, 6.491066],
          [100.160533, 6.491535],
          [100.16048, 6.492152],
          [100.160507, 6.492744],
          [100.160613, 6.492793],
          [100.16072, 6.492941],
          [100.16072, 6.493065],
          [100.160507, 6.493287],
          [100.160106, 6.493657],
          [100.159705, 6.493879],
          [100.159545, 6.494027],
          [100.159464, 6.494397],
          [100.159438, 6.495434],
          [100.159464, 6.495829],
          [100.159785, 6.496125],
          [100.160106, 6.496248],
          [100.160266, 6.49647],
          [100.160186, 6.497013],
          [100.160026, 6.497433],
          [100.160052, 6.49879],
          [100.159945, 6.499037],
          [100.159865, 6.499457],
          [100.159865, 6.499753],
          [100.159946, 6.500061],
          [100.160213, 6.500592],
          [100.160293, 6.502072],
          [100.160614, 6.502986],
          [100.160587, 6.503282],
          [100.159946, 6.503997],
          [100.159812, 6.504367],
          [100.159716, 6.504814],
          [100.159518, 6.505231],
          [100.15885, 6.505749],
          [100.158396, 6.506268],
          [100.158128, 6.507452],
          [100.158102, 6.507748],
          [100.158208, 6.508069],
          [100.158476, 6.508415],
          [100.158476, 6.508834],
          [100.1584, 6.509325],
          [100.158262, 6.509698],
          [100.157995, 6.509969],
          [100.15797, 6.510219],
          [100.158048, 6.510561],
          [100.158208, 6.510833],
          [100.158075, 6.511055],
          [100.158075, 6.512239],
          [100.157888, 6.512708],
          [100.157803, 6.513235],
          [100.157487, 6.513597],
          [100.15714, 6.514238],
          [100.157033, 6.514806],
          [100.15722, 6.515324],
          [100.157246, 6.515719],
          [100.157033, 6.516286],
          [100.156525, 6.516977],
          [100.155777, 6.517841],
          [100.155536, 6.518853],
          [100.154975, 6.520235],
          [100.154841, 6.521074],
          [100.154734, 6.521469],
          [100.154574, 6.52179],
          [100.154681, 6.522037],
          [100.154895, 6.522259],
          [100.155189, 6.522259],
          [100.155643, 6.522086],
          [100.156258, 6.521642],
          [100.156578, 6.52174],
          [100.157059, 6.522135],
          [100.157434, 6.52253],
          [100.15754, 6.522925],
          [100.15746, 6.524184],
          [100.157246, 6.524578],
          [100.156952, 6.524924],
          [100.156792, 6.525689],
          [100.156799, 6.525885],
          [100.156899, 6.526183],
          [100.157113, 6.526479],
          [100.15714, 6.526898],
          [100.157123, 6.527444],
          [100.156765, 6.528256],
          [100.156816, 6.528922],
          [100.156712, 6.529243],
          [100.156759, 6.529589],
          [100.156685, 6.529934],
          [100.156525, 6.530255],
          [100.156204, 6.530624],
          [100.156017, 6.53097],
          [100.156071, 6.531167],
          [100.156346, 6.531235],
          [100.156605, 6.531562],
          [100.156925, 6.53171],
          [100.157112, 6.532056],
          [100.157193, 6.5325],
          [100.157166, 6.532919],
          [100.156872, 6.533635],
          [100.156525, 6.534672],
          [100.15607, 6.535387],
          [100.156097, 6.535782],
          [100.156038, 6.53618],
          [100.155803, 6.536522],
          [100.155723, 6.536769],
          [100.155883, 6.537041],
          [100.156177, 6.537238],
          [100.156177, 6.537485],
          [100.155776, 6.538398],
          [100.15575, 6.538867],
          [100.15583, 6.539311],
          [100.156043, 6.539582],
          [100.156337, 6.539706],
          [100.15615, 6.540841],
          [100.156284, 6.541754],
          [100.156257, 6.542149],
          [100.155856, 6.544098],
          [100.155696, 6.544542],
          [100.155108, 6.545209],
          [100.154814, 6.545406],
          [100.154761, 6.545604],
          [100.154841, 6.545924],
          [100.154841, 6.546492],
          [100.154681, 6.547109],
          [100.154283, 6.547968],
          [100.153932, 6.548319],
          [100.153772, 6.548713],
          [100.153825, 6.549454],
          [100.153932, 6.550095],
          [100.153932, 6.550515],
          [100.153665, 6.551181],
          [100.153531, 6.551699],
          [100.153665, 6.553106],
          [100.153424, 6.554364],
          [100.153478, 6.554661],
          [100.153665, 6.555105],
          [100.153772, 6.555623],
          [100.153772, 6.557375],
          [100.15436, 6.558732],
          [100.154948, 6.559325],
          [100.155349, 6.559547],
          [100.155776, 6.559917],
          [100.156311, 6.560065],
          [100.157059, 6.56009],
          [100.157433, 6.560164],
          [100.157647, 6.560386],
          [100.157941, 6.560904],
          [100.158101, 6.561299],
          [100.159464, 6.562311],
          [100.159652, 6.562557],
          [100.159625, 6.564112],
          [100.159277, 6.565149],
          [100.158983, 6.566851],
          [100.158689, 6.567715],
          [100.158583, 6.568283],
          [100.158716, 6.568688],
          [100.159197, 6.569082],
          [100.159598, 6.570143],
          [100.159678, 6.570464],
          [100.158877, 6.571352],
          [100.158422, 6.572167],
          [100.158128, 6.572981],
          [100.158182, 6.573326],
          [100.158369, 6.573598],
          [100.158262, 6.574535],
          [100.158048, 6.574708],
          [100.157514, 6.574856],
          [100.157006, 6.574856],
          [100.156498, 6.574708],
          [100.156151, 6.574683],
          [100.156044, 6.57493],
          [100.156899, 6.57609],
          [100.156899, 6.576559],
          [100.156819, 6.576954],
          [100.156552, 6.577669],
          [100.156578, 6.577965],
          [100.156792, 6.578335],
          [100.156872, 6.578656],
          [100.156846, 6.579322],
          [100.156979, 6.579544],
          [100.157247, 6.579717],
          [100.15754, 6.579668],
          [100.157888, 6.579396],
          [100.158209, 6.578952],
          [100.158476, 6.578261],
          [100.15869, 6.578113],
          [100.15909, 6.578039],
          [100.159438, 6.577866],
          [100.159972, 6.577373],
          [100.160801, 6.576929],
          [100.16179, 6.576608],
          [100.162297, 6.576238],
          [100.162511, 6.575917],
          [100.162725, 6.575054],
          [100.162965, 6.574486],
          [100.163286, 6.574239],
          [100.163366, 6.573968],
          [100.163259, 6.573548],
          [100.163286, 6.572413],
          [100.163179, 6.572019],
          [100.163179, 6.571599],
          [100.164515, 6.570415],
          [100.164809, 6.570637],
          [100.165157, 6.57076],
          [100.165451, 6.570736],
          [100.165504, 6.570563],
          [100.165424, 6.570267],
          [100.165478, 6.57002],
          [100.165691, 6.569847],
          [100.166092, 6.569724],
          [100.166413, 6.569847],
          [100.16668, 6.570119],
          [100.166894, 6.570415],
          [100.167295, 6.570563],
          [100.167642, 6.570884],
          [100.169165, 6.572438],
          [100.169379, 6.572932],
          [100.169352, 6.574067],
          [100.169593, 6.574387],
          [100.16986, 6.574609],
          [100.170047, 6.574585],
          [100.170582, 6.574239],
          [100.171784, 6.573968],
          [100.172105, 6.573746],
          [100.172239, 6.573499],
          [100.172265, 6.573104],
          [100.172559, 6.572907],
          [100.172853, 6.572882],
          [100.173147, 6.572759],
          [100.173227, 6.572438],
          [100.173308, 6.571723],
          [100.173682, 6.571303],
          [100.173735, 6.571007],
          [100.173575, 6.570884],
          [100.173415, 6.570662],
          [100.173415, 6.570217],
          [100.173495, 6.569872],
          [100.173922, 6.569378],
          [100.174136, 6.568984],
          [100.174163, 6.568441],
          [100.174323, 6.568219],
          [100.174804, 6.567873],
          [100.175098, 6.567553],
          [100.175392, 6.566985],
          [100.175686, 6.56664],
          [100.175927, 6.56659],
          [100.17614, 6.566664],
          [100.176194, 6.567577],
          [100.176007, 6.568688],
          [100.176381, 6.569601],
          [100.176782, 6.569921],
          [100.176862, 6.570267],
          [100.176915, 6.571007],
          [100.177049, 6.571451],
          [100.177076, 6.571846],
          [100.177183, 6.572167],
          [100.177423, 6.572438],
          [100.177771, 6.572561],
          [100.179214, 6.5734],
          [100.179748, 6.573474],
          [100.180176, 6.5734],
          [100.180577, 6.572512],
          [100.180817, 6.572117],
          [100.181138, 6.572093],
          [100.182608, 6.572413],
          [100.183196, 6.572784],
          [100.18357, 6.572808],
          [100.183409, 6.573178],
          [100.182848, 6.573919],
          [100.182848, 6.574116],
          [100.183062, 6.574313],
          [100.183134, 6.574573],
          [100.182768, 6.5753],
          [100.182447, 6.575596],
          [100.182474, 6.575868],
          [100.182821, 6.576164],
          [100.183276, 6.576435],
          [100.183917, 6.576978],
          [100.184131, 6.577373],
          [100.184291, 6.578261],
          [100.184291, 6.578829],
          [100.18438, 6.579181],
          [100.184291, 6.579544],
          [100.183463, 6.580334],
          [100.182394, 6.581197],
          [100.182056, 6.581668],
          [100.181378, 6.58248],
          [100.18087, 6.583344],
          [100.180763, 6.584109],
          [100.180844, 6.584652],
          [100.181191, 6.585244],
          [100.181056, 6.585875],
          [100.181191, 6.586477],
          [100.181244, 6.587144],
          [100.181057, 6.587662],
          [100.179694, 6.589389],
          [100.179081, 6.590082],
          [100.178385, 6.591486],
          [100.178278, 6.592301],
          [100.178599, 6.594201],
          [100.178545, 6.595311],
          [100.178706, 6.595977],
          [100.179026, 6.596298],
          [100.180603, 6.596816],
          [100.180977, 6.597359],
          [100.18087, 6.598297],
          [100.180523, 6.598445],
          [100.180443, 6.598988],
          [100.180683, 6.600246],
          [100.180844, 6.601381],
          [100.180977, 6.601677],
          [100.180576, 6.601924],
          [100.179668, 6.602269],
          [100.179374, 6.602565],
          [100.17924, 6.603034],
          [100.17924, 6.603503],
          [100.179106, 6.603972],
          [100.178946, 6.604243],
          [100.178358, 6.604613],
          [100.17777, 6.604761],
          [100.177449, 6.604613],
          [100.177102, 6.603947],
          [100.176835, 6.603848],
          [100.176434, 6.603848],
          [100.175659, 6.604145],
          [100.173895, 6.60523],
          [100.171784, 6.606341],
          [100.170368, 6.607525],
          [100.169753, 6.608512],
          [100.169432, 6.609449],
          [100.169272, 6.61019],
          [100.169379, 6.611374],
          [100.169593, 6.612509],
          [100.169566, 6.612978],
          [100.169325, 6.614039],
          [100.168604, 6.616333],
          [100.168524, 6.617221],
          [100.168764, 6.619368],
          [100.168737, 6.619911],
          [100.168524, 6.620404],
          [100.168497, 6.620799],
          [100.168737, 6.621219],
          [100.168711, 6.621638],
          [100.168604, 6.622033],
          [100.168283, 6.622255],
          [100.168069, 6.622946],
          [100.167802, 6.623143],
          [100.167268, 6.623291],
          [100.167134, 6.623538],
          [100.167161, 6.623982],
          [100.167294, 6.624426],
          [100.167294, 6.624846],
          [100.167161, 6.625191],
          [100.167268, 6.625536],
          [100.168096, 6.626178],
          [100.168337, 6.62677],
          [100.168444, 6.627189],
          [100.168283, 6.627559],
          [100.167856, 6.628053],
          [100.167161, 6.628694],
          [100.16692, 6.629287],
          [100.166573, 6.630496],
          [100.166279, 6.631088],
          [100.166092, 6.631606],
          [100.166092, 6.632124],
          [100.166332, 6.632371],
          [100.166653, 6.632371],
          [100.166974, 6.632124],
          [100.167481, 6.631927],
          [100.168096, 6.631902],
          [100.168444, 6.632149],
          [100.168684, 6.632544],
          [100.169005, 6.632642],
          [100.168684, 6.634049],
          [100.168871, 6.635233],
          [100.168818, 6.635751],
          [100.16823, 6.63812],
          [100.168176, 6.638663],
          [100.168791, 6.639427],
          [100.169138, 6.641821],
          [100.169031, 6.642536],
          [100.169005, 6.64303],
          [100.169058, 6.643523],
          [100.169085, 6.64456],
          [100.169005, 6.645719],
          [100.168764, 6.646509],
          [100.16839, 6.647175],
          [100.168283, 6.64757],
          [100.16855, 6.647742],
          [100.169325, 6.64794],
          [100.169619, 6.648236],
          [100.169887, 6.648853],
          [100.170234, 6.649149],
          [100.170742, 6.649223],
          [100.171089, 6.649322],
          [100.171276, 6.649667],
          [100.171437, 6.650136],
          [100.171597, 6.650309],
          [100.171998, 6.650259],
          [100.172612, 6.650259],
          [100.17312, 6.650358],
          [100.173628, 6.650235],
          [100.174456, 6.649939],
          [100.174884, 6.649865],
          [100.175178, 6.650013],
          [100.175525, 6.650333],
          [100.175659, 6.650827],
          [100.175686, 6.651345],
          [100.175926, 6.65169],
          [100.176648, 6.651814],
          [100.177075, 6.652184],
          [100.177583, 6.652455],
          [100.178091, 6.653097],
          [100.178732, 6.653492],
          [100.179828, 6.65401],
          [100.180469, 6.654133],
          [100.180683, 6.654281],
          [100.180656, 6.65475],
          [100.180763, 6.655391],
          [100.18071, 6.657118],
          [100.180523, 6.657883],
          [100.180309, 6.658451],
          [100.180282, 6.658772],
          [100.180523, 6.659537],
          [100.180576, 6.660179],
          [100.180496, 6.660524],
          [100.180122, 6.66124],
          [100.179587, 6.661906],
          [100.179454, 6.662449],
          [100.179374, 6.664447],
          [100.179026, 6.666273],
          [100.178759, 6.66689],
          [100.178412, 6.667458],
          [100.178225, 6.668124],
          [100.17785, 6.668963],
          [100.177877, 6.669357],
          [100.178037, 6.670097],
          [100.178385, 6.670221],
          [100.178812, 6.670122],
          [100.17908, 6.670147],
          [100.17916, 6.670393],
          [100.17924, 6.671677],
          [100.179347, 6.672565],
          [100.179454, 6.672861],
          [100.179721, 6.673009],
          [100.179855, 6.67328],
          [100.179881, 6.674193],
          [100.180095, 6.675575],
          [100.179908, 6.676167],
          [100.179881, 6.677204],
          [100.179721, 6.677969],
          [100.179561, 6.679597],
          [100.179828, 6.680411],
          [100.179694, 6.680929],
          [100.179347, 6.681941],
          [100.179133, 6.682336],
          [100.178812, 6.682558],
          [100.178358, 6.682681],
          [100.178011, 6.682656],
          [100.176888, 6.682804],
          [100.17598, 6.682632],
          [100.175552, 6.682656],
          [100.175205, 6.682878],
          [100.174804, 6.683742],
          [100.174109, 6.685494],
          [100.173708, 6.685987],
          [100.173548, 6.68658],
          [100.173414, 6.687344],
          [100.173414, 6.687912],
          [100.172612, 6.689491],
          [100.172078, 6.69075],
          [100.171784, 6.69218],
          [100.17149, 6.692896],
          [100.171463, 6.693389],
          [100.17157, 6.693759],
          [100.171944, 6.693932],
          [100.17288, 6.694056],
          [100.173548, 6.694204],
          [100.174056, 6.694426],
          [100.17451, 6.694722],
          [100.175231, 6.694944],
          [100.175766, 6.695018],
          [100.176648, 6.694845],
          [100.177049, 6.695092],
          [100.17769, 6.695659],
          [100.1786271, 6.6965088],
          [100.1786681, 6.696595],
          [100.1788871, 6.6970802],
          [100.178973, 6.69825],
          [100.178946, 6.698571],
          [100.178732, 6.699188],
          [100.178652, 6.699657],
          [100.178732, 6.699854],
          [100.179213, 6.700471],
          [100.179801, 6.701063],
          [100.180042, 6.701606],
          [100.180175, 6.702222],
          [100.180389, 6.702568],
          [100.180763, 6.702839],
          [100.181111, 6.702963],
          [100.181672, 6.70469],
          [100.183249, 6.707034],
          [100.183329, 6.708292],
          [100.183944, 6.709477],
          [100.184264, 6.709896],
          [100.184371, 6.710241],
          [100.184451, 6.710809],
          [100.184638, 6.711253],
          [100.185039, 6.711771],
          [100.185601, 6.71224],
          [100.186001, 6.712733],
          [100.186215, 6.713276],
          [100.185734, 6.715176],
          [100.185707, 6.71562],
          [100.185788, 6.71599],
          [100.185681, 6.716286],
          [100.185654, 6.716582],
          [100.185841, 6.71678],
          [100.187338, 6.717323],
          [100.188781, 6.718186],
          [100.189182, 6.71826],
          [100.190144, 6.718186],
          [100.190384, 6.718458],
          [100.190785, 6.718803],
          [100.191266, 6.71905],
          [100.191587, 6.719075],
          [100.191934, 6.718902],
          [100.192255, 6.718976],
          [100.192602, 6.719321],
          [100.193137, 6.719618],
          [100.193965, 6.71984],
          [100.194366, 6.720012],
          [100.194687, 6.720308],
          [100.195836, 6.720679],
          [100.19605, 6.721024],
          [100.19637, 6.72206],
          [100.196531, 6.722381],
          [100.196825, 6.72243],
          [100.197333, 6.722332],
          [100.198027, 6.721937],
          [100.198401, 6.72159],
          [100.198615, 6.721542],
          [100.198936, 6.72169],
          [100.19915, 6.722036],
          [100.199257, 6.722603],
          [100.199444, 6.723096],
          [100.200219, 6.723738],
          [100.200994, 6.72391],
          [100.201449, 6.72433],
          [100.20201, 6.725095],
          [100.202304, 6.725317],
          [100.202972, 6.72549],
          [100.203266, 6.725416],
          [100.203533, 6.72549],
          [100.203934, 6.725687],
          [100.204388, 6.725761],
          [100.204869, 6.725934],
          [100.205056, 6.726082],
          [100.204976, 6.725736],
          [100.204869, 6.725342],
          [100.204735, 6.724712],
          [100.204522, 6.724194],
          [100.204388, 6.723873],
          [100.204335, 6.723553],
          [100.204602, 6.723281],
          [100.204896, 6.723108],
          [100.205029, 6.722837],
          [100.205136, 6.722541],
          [100.205056, 6.722294],
          [100.205083, 6.721998],
          [100.205216, 6.721801],
          [100.20543, 6.721357],
          [100.205591, 6.720962],
          [100.206072, 6.720641],
          [100.206446, 6.720518],
          [100.206873, 6.720246],
          [100.207221, 6.719925],
          [100.207702, 6.719679],
          [100.208103, 6.719555],
          [100.208584, 6.719629],
          [100.209091, 6.719802],
          [100.209572, 6.719777],
          [100.210027, 6.719753],
          [100.210428, 6.719999],
          [100.210775, 6.720197],
          [100.211363, 6.720394],
          [100.211871, 6.720493],
          [100.212298, 6.720394],
          [100.212833, 6.719999],
          [100.213367, 6.719605],
          [100.214409, 6.719037],
          [100.21473, 6.71884],
          [100.214704, 6.718494],
          [100.21441, 6.717803],
          [100.214223, 6.717261],
          [100.214276, 6.71694],
          [100.214383, 6.716644],
          [100.214757, 6.716323],
          [100.215131, 6.715731],
          [100.215559, 6.715163],
          [100.215853, 6.714695],
          [100.215933, 6.714349],
          [100.215933, 6.71388],
          [100.215746, 6.713313],
          [100.215666, 6.712943],
          [100.215773, 6.712721],
          [100.2162, 6.712449],
          [100.216419, 6.71206],
          [100.216788, 6.711635],
          [100.217456, 6.711166],
          [100.21767, 6.710846],
          [100.217857, 6.710451],
          [100.218258, 6.709809],
          [100.218632, 6.70934],
          [100.218819, 6.708872],
          [100.2189, 6.708378],
          [100.2189, 6.707687],
          [100.218873, 6.706972],
          [100.218846, 6.706528],
          [100.218526, 6.706084],
          [100.218392, 6.705615],
          [100.218472, 6.705196],
          [100.218552, 6.704751],
          [100.218445, 6.704184],
          [100.218285, 6.703567],
          [100.218365, 6.702851],
          [100.218579, 6.702358],
          [100.218873, 6.702087],
          [100.219407, 6.70179],
          [100.219675, 6.701568],
          [100.220022, 6.700952],
          [100.22045, 6.700483],
          [100.220931, 6.699965],
          [100.221545, 6.699471],
          [100.222133, 6.69915],
          [100.222534, 6.69883],
          [100.223095, 6.698731],
          [100.223461, 6.698595],
          [100.22371, 6.698237],
          [100.223897, 6.697843],
          [100.223924, 6.697596],
          [100.223897, 6.697275],
          [100.224057, 6.697102],
          [100.224458, 6.697028],
          [100.225073, 6.697053],
          [100.225634, 6.69693],
          [100.226169, 6.696634],
          [100.226596, 6.696362],
          [100.22697, 6.696115],
          [100.227077, 6.695819],
          [100.227211, 6.695301],
          [100.227478, 6.694808],
          [100.227906, 6.694388],
          [100.22852, 6.693673],
          [100.228761, 6.693401],
          [100.229242, 6.693525],
          [100.230311, 6.694043],
          [100.230765, 6.694215],
          [100.231059, 6.694635],
          [100.2313, 6.694832],
          [100.2317, 6.694906],
          [100.231994, 6.694758],
          [100.232422, 6.694586],
          [100.232823, 6.694487],
          [100.233063, 6.69424],
          [100.233117, 6.69387],
          [100.23325, 6.693574],
          [100.233544, 6.693352],
          [100.233838, 6.693105],
          [100.234079, 6.693278],
          [100.23464, 6.693623],
          [100.235308, 6.693821],
          [100.235629, 6.693796],
          [100.235923, 6.693648],
          [100.236404, 6.693451],
          [100.236752, 6.693278],
          [100.237046, 6.692908],
          [100.2373184, 6.6923756],
          [100.237946, 6.6921428],
          [100.2384782, 6.6923386],
          [100.2394409, 6.692465],
          [100.2398864, 6.6924866],
          [100.2400897, 6.692513],
          [100.2402222, 6.692232],
          [100.2403814, 6.6921355],
          [100.24062, 6.6921091],
          [100.240841, 6.6922276],
          [100.2410399, 6.6922584],
          [100.2412034, 6.692254],
          [100.2414465, 6.6925217],
          [100.2416388, 6.6927542],
          [100.2419263, 6.6927728],
          [100.2422076, 6.6926859],
          [100.2425201, 6.6927107],
          [100.2437451, 6.6934432],
          [100.2436389, 6.6936915],
          [100.2435013, 6.6941943],
          [100.2434638, 6.6944426],
          [100.2436326, 6.6951751],
          [100.2437951, 6.695889],
          [100.2439362, 6.6959882],
          [100.2441889, 6.6960132],
          [100.2445201, 6.6959697],
          [100.2448264, 6.6960194],
          [100.2450389, 6.696069],
          [100.2452889, 6.6959511],
          [100.2455014, 6.6957587],
          [100.2457452, 6.6955973],
          [100.2462264, 6.6956283],
          [100.2468014, 6.695591],
          [100.2469827, 6.6962366],
          [100.2473202, 6.696578],
          [100.2476157, 6.6968871],
          [100.247847, 6.6970175],
          [100.2481095, 6.6971603],
          [100.2485533, 6.697303],
          [100.2488845, 6.6973341],
          [100.2491158, 6.6974644],
          [100.2493095, 6.6979921],
          [100.2494408, 6.6979983],
          [100.249572, 6.6980728],
          [100.2496345, 6.6981907],
          [100.2496095, 6.6983707],
          [100.2497158, 6.6988115],
          [100.2498596, 6.6994322],
          [100.2499908, 6.6997985],
          [100.2502096, 6.7003199],
          [100.2503721, 6.7009593],
          [100.2503908, 6.7013255],
          [100.2505096, 6.7021759],
          [100.2508158, 6.7027408],
          [100.2513783, 6.7034112],
          [100.2517346, 6.7037588],
          [100.2520659, 6.7039202],
          [100.2532909, 6.7037712],
          [100.2531971, 6.7043423],
          [100.2532846, 6.7047582],
          [100.2533159, 6.7050934],
          [100.2532409, 6.7055155],
          [100.2532159, 6.7058756],
          [100.2532159, 6.7060432],
          [100.2533728, 6.7061809],
          [100.2533784, 6.7064218],
          [100.2529916, 6.7070437],
          [100.2526103, 6.7074472],
          [100.2524665, 6.7076458],
          [100.2528103, 6.708577],
          [100.253789, 6.7088026],
          [100.253895, 6.708965],
          [100.2541646, 6.7091362],
          [100.2547657, 6.7093294],
          [100.254969, 6.7090484],
          [100.2552872, 6.7088992],
          [100.2555922, 6.7086227],
          [100.2558485, 6.7082628],
          [100.2561579, 6.7081618],
          [100.2564363, 6.7081574],
          [100.2566352, 6.7082101],
          [100.2567854, 6.7085481],
          [100.2569092, 6.7088685],
          [100.2568959, 6.709224],
          [100.2571522, 6.7095357],
          [100.2572451, 6.7097024],
          [100.2574761, 6.7110132],
          [100.2574953, 6.7113679],
          [100.2579203, 6.7109396],
          [100.2583235, 6.7103375],
          [100.2588575, 6.7099257],
          [100.2593135, 6.7094464],
          [100.2596363, 6.7089803],
          [100.2596796, 6.708544],
          [100.2598294, 6.7083589],
          [100.2603419, 6.7081341],
          [100.2611507, 6.7075689],
          [100.2615268, 6.7072516],
          [100.2615967, 6.7065773],
          [100.261783, 6.7059393],
          [100.2614203, 6.705384],
          [100.261397, 6.705222],
          [100.2616133, 6.7052154],
          [100.261793, 6.7052914],
          [100.2622923, 6.705113],
          [100.2626084, 6.7045312],
          [100.262675, 6.7040453],
          [100.2628248, 6.7037213],
          [100.2629346, 6.7033941],
          [100.2630777, 6.7026933],
          [100.2629912, 6.7024785],
          [100.2632907, 6.7012455],
          [100.2638432, 6.7006737],
          [100.2638565, 6.6992159],
          [100.2639997, 6.6991069],
          [100.2642393, 6.6990639],
          [100.2646853, 6.6990738],
          [100.2649948, 6.6989218],
          [100.2656548, 6.6988986],
          [100.2667515, 6.6990318],
          [100.2670998, 6.6993521],
          [100.2720503, 6.7037408],
          [100.2720669, 6.7038499],
          [100.2722101, 6.7039292],
          [100.2725462, 6.7039788],
          [100.2732052, 6.7039722],
          [100.2741038, 6.7038168],
          [100.2747961, 6.7038201],
          [100.2749625, 6.7039722],
          [100.2753453, 6.7045275],
          [100.2756215, 6.7045804],
          [100.2768596, 6.7042961],
          [100.2776085, 6.7041473],
          [100.2779712, 6.7043688],
          [100.2782974, 6.7049374],
          [100.27876, 6.7056084],
          [100.2788732, 6.706481],
          [100.2793059, 6.7068413],
          [100.2796753, 6.7067785],
          [100.2802677, 6.7068215],
          [100.2806671, 6.7069107],
          [100.2812962, 6.7073074],
          [100.2814692, 6.7072776],
          [100.2818387, 6.7071785],
          [100.2821082, 6.7073107],
          [100.2824211, 6.7074925],
          [100.2826807, 6.7077767],
          [100.2829703, 6.7081337],
          [100.2833863, 6.7082461],
          [100.2838423, 6.7083321],
          [100.284255, 6.7086758],
          [100.2847143, 6.7085073],
          [100.2862219, 6.7075454],
          [100.2865747, 6.7075123],
          [100.2869608, 6.707585],
          [100.2872803, 6.7076809],
          [100.2876597, 6.7080676],
          [100.2882322, 6.7086824],
          [100.2888113, 6.709056],
          [100.2890975, 6.7091155],
          [100.2893904, 6.7092609],
          [100.2895768, 6.7095518],
          [100.2898331, 6.7098394],
          [100.290126, 6.7098856],
          [100.2902658, 6.7098691],
          [100.2907417, 6.7097237],
          [100.2913574, 6.7093435],
          [100.2920131, 6.7086229],
          [100.2923393, 6.7080809],
          [100.2930349, 6.7078481],
          [100.2934542, 6.7073919],
          [100.2940799, 6.7065391],
          [100.2942131, 6.706083],
          [100.2951017, 6.7043608],
          [100.295115, 6.7038088],
          [100.2948254, 6.7033692],
          [100.2945958, 6.7031147],
          [100.2944826, 6.7027147],
          [100.2941565, 6.7023544],
          [100.2934309, 6.7019049],
          [100.2931913, 6.701495],
          [100.2927919, 6.7011578],
          [100.2925423, 6.700348],
          [100.2921928, 6.7000637],
          [100.2916636, 6.6997331],
          [100.2914007, 6.6990952],
          [100.2915738, 6.6987283],
          [100.2916403, 6.6984308],
          [100.2915671, 6.6980407],
          [100.2915671, 6.6976903],
          [100.2915405, 6.697135],
          [100.2918034, 6.69654],
          [100.2917901, 6.6959748],
          [100.2912509, 6.6956409],
          [100.2907883, 6.6951748],
          [100.2908715, 6.6949533],
          [100.2909614, 6.6944344],
          [100.2910479, 6.6936344],
          [100.2909847, 6.6932477],
          [100.2908715, 6.6926626],
          [100.2904655, 6.6922395],
          [100.2903723, 6.6920279],
          [100.2902358, 6.6919122],
          [100.2901027, 6.6917271],
          [100.2899695, 6.6908214],
          [100.2899862, 6.6906131],
          [100.2904022, 6.6903851],
          [100.2905886, 6.6895487],
          [100.2904788, 6.6891884],
          [100.2901726, 6.6888909],
          [100.2901759, 6.6887554],
          [100.2902524, 6.6886496],
          [100.2905054, 6.6884116],
          [100.2906219, 6.688048],
          [100.2908915, 6.6876348],
          [100.2909248, 6.6872448],
          [100.2912709, 6.6864514],
          [100.2914673, 6.6861043],
          [100.2918334, 6.6858465],
          [100.2919632, 6.6855225],
          [100.2921961, 6.6849639],
          [100.2923126, 6.6846366],
          [100.2922793, 6.6841044],
          [100.2925023, 6.6837276],
          [100.2930815, 6.6831359],
          [100.2934109, 6.68265],
          [100.2934642, 6.6820682],
          [100.2937471, 6.6811558],
          [100.2941132, 6.6801575],
          [100.2942563, 6.6797509],
          [100.2947722, 6.6792088],
          [100.2954545, 6.6790898],
          [100.2958539, 6.6787691],
          [100.2965428, 6.6783625],
          [100.2969222, 6.6781146],
          [100.297285, 6.677913],
          [100.2979141, 6.6779923],
          [100.2982901, 6.6780948],
          [100.2985597, 6.6782072],
          [100.2987028, 6.6784452],
          [100.2990623, 6.6791427],
          [100.2992986, 6.6793013],
          [100.2997646, 6.6796187],
          [100.3002904, 6.6800054],
          [100.3003603, 6.6801145],
          [100.3004934, 6.6803327],
          [100.3007164, 6.680293],
          [100.3013122, 6.6800451],
          [100.3018468, 6.6798681],
          [100.3022396, 6.6795177],
          [100.3025857, 6.6791144],
          [100.3028187, 6.6786252],
          [100.3029052, 6.677693],
          [100.3031049, 6.6766418],
          [100.3033845, 6.6760666],
          [100.3034178, 6.6755377],
          [100.3038371, 6.6747575],
          [100.3041332, 6.674216],
          [100.3042603, 6.674015],
          [100.3044297, 6.6733932],
          [100.3043748, 6.6729955],
          [100.3044203, 6.6727574],
          [100.3046027, 6.6725505],
          [100.3046934, 6.6721365],
          [100.3046445, 6.6715742],
          [100.305642, 6.6708035],
          [100.3063933, 6.6699555],
          [100.3059185, 6.6691859],
          [100.3058805, 6.6684524],
          [100.3071433, 6.6676879],
          [100.3075748, 6.6675602],
          [100.3082169, 6.6671932],
          [100.3085686, 6.6668468],
          [100.3089227, 6.6666342],
          [100.3094519, 6.6665929],
          [100.3099235, 6.6666746],
          [100.3103948, 6.6666299],
          [100.3106708, 6.6667157],
          [100.3109424, 6.6669149],
          [100.3111728, 6.666961],
          [100.3116757, 6.6666421],
          [100.312061, 6.6665519],
          [100.3124467, 6.6663182],
          [100.3128863, 6.6662233],
          [100.3138409, 6.6667116],
          [100.3144516, 6.6671707],
          [100.314949, 6.6672192],
          [100.3157501, 6.6677738],
          [100.3160458, 6.6677167],
          [100.3164629, 6.667491],
          [100.3169541, 6.666593],
          [100.3170511, 6.6662343],
          [100.3179156, 6.6654988],
          [100.3184177, 6.6651736],
          [100.3185529, 6.6647011],
          [100.3188558, 6.6643663],
          [100.3193273, 6.6643023],
          [100.3198097, 6.6646496],
          [100.3202111, 6.664585],
          [100.321042, 6.6654033],
          [100.3214694, 6.66535],
          [100.3216625, 6.6656432],
          [100.3222358, 6.6652749],
          [100.3228125, 6.6652208],
          [100.3241549, 6.6653114],
          [100.3245617, 6.6653181],
          [100.3250945, 6.6654683],
          [100.3259076, 6.6653383],
          [100.3262382, 6.6651929],
          [100.3267742, 6.6648994],
          [100.3269204, 6.6646865],
          [100.327316, 6.6643591],
          [100.3273428, 6.66432],
          [100.3273936, 6.6642459],
          [100.3275012, 6.6640889],
          [100.3274531, 6.6638851],
          [100.3272799, 6.6636188],
          [100.3271193, 6.6633718],
          [100.3266711, 6.6629902],
          [100.3264516, 6.6625678],
          [100.3266771, 6.6614498],
          [100.326247, 6.6603571],
          [100.3260476, 6.6597018],
          [100.3258603, 6.6595098],
          [100.3252701, 6.6591855],
          [100.3250119, 6.6590823],
          [100.3247046, 6.6587681],
          [100.3244863, 6.6585448],
          [100.3243959, 6.658091],
          [100.324182, 6.6574404],
          [100.3237293, 6.6568323],
          [100.3232194, 6.6563456],
          [100.3224414, 6.6555968],
          [100.3221227, 6.6554952],
          [100.3216391, 6.6553337],
          [100.3209957, 6.6550367],
          [100.3205669, 6.6549568],
          [100.3201635, 6.6549508],
          [100.3200424, 6.6548926],
          [100.3199597, 6.6547994],
          [100.3198409, 6.6545117],
          [100.3196349, 6.6542851],
          [100.3194744, 6.6539878],
          [100.3195984, 6.6537704],
          [100.3196236, 6.6534563],
          [100.3196886, 6.6532998],
          [100.3198476, 6.6531444],
          [100.3198634, 6.6530639],
          [100.3198313, 6.6527997],
          [100.3199798, 6.6525538],
          [100.3202018, 6.6523989],
          [100.3203324, 6.6523808],
          [100.320455, 6.652223],
          [100.3204474, 6.6520106],
          [100.3205003, 6.65163],
          [100.3207237, 6.6511029],
          [100.3210082, 6.6507297],
          [100.3212737, 6.6505637],
          [100.3215446, 6.6503194],
          [100.3218277, 6.6498736],
          [100.3221718, 6.6496775],
          [100.3221783, 6.6494594],
          [100.3223039, 6.6490695],
          [100.3219929, 6.6484167],
          [100.3218241, 6.6477714],
          [100.3217203, 6.6470549],
          [100.3223281, 6.6467857],
          [100.3222117, 6.6459295],
          [100.3218922, 6.6457397],
          [100.3219434, 6.6455906],
          [100.322181, 6.6453353],
          [100.3220738, 6.6447571],
          [100.3220529, 6.6441438],
          [100.3218454, 6.6420542],
          [100.3212411, 6.642185],
          [100.3209526, 6.6420742],
          [100.3204016, 6.6416061],
          [100.3198907, 6.6410921],
          [100.319202, 6.6408283],
          [100.3182395, 6.6404704],
          [100.3171739, 6.6398457],
          [100.3173075, 6.639267],
          [100.3168482, 6.6385728],
          [100.3171166, 6.638043],
          [100.3167085, 6.637138],
          [100.3161734, 6.6365988],
          [100.3169057, 6.6358863],
          [100.3165487, 6.6348173],
          [100.3177283, 6.6351707],
          [100.3182794, 6.6349561],
          [100.3190854, 6.6349624],
          [100.3195042, 6.6353991],
          [100.3196639, 6.635571],
          [100.3200101, 6.6356371],
          [100.3202161, 6.6360205],
          [100.3208198, 6.6360097],
          [100.3212662, 6.6356787],
          [100.3213007, 6.6345646],
          [100.3216748, 6.6342452],
          [100.3216098, 6.6317736],
          [100.3218355, 6.631029],
          [100.3223875, 6.6304466],
          [100.3221488, 6.630072],
          [100.3227603, 6.6296713],
          [100.3231738, 6.6290662],
          [100.3239512, 6.6279215],
          [100.3241257, 6.6275488],
          [100.3239973, 6.6270317],
          [100.3236181, 6.6267658],
          [100.3236057, 6.6264987],
          [100.3232378, 6.62595],
          [100.3235122, 6.6251233],
          [100.3237995, 6.6245435],
          [100.3244662, 6.6241702],
          [100.3254223, 6.6235641],
          [100.3254802, 6.6234567],
          [100.3260926, 6.622322],
          [100.3260294, 6.621392],
          [100.3257392, 6.6213681],
          [100.3253728, 6.6211224],
          [100.3250718, 6.621073],
          [100.3246647, 6.6206749],
          [100.3243628, 6.6205261],
          [100.3239947, 6.620095],
          [100.3237938, 6.619491],
          [100.3232742, 6.6193123],
          [100.3228262, 6.6189453],
          [100.3208613, 6.6171102],
          [100.3199269, 6.6165013],
          [100.3192358, 6.615815],
          [100.3187969, 6.61596],
          [100.3184794, 6.615516],
          [100.3183438, 6.6153264],
          [100.3178693, 6.6153399],
          [100.3178764, 6.6150085],
          [100.3175415, 6.6148101],
          [100.3172205, 6.6147897],
          [100.3165691, 6.6143242],
          [100.3166272, 6.6138563],
          [100.3165816, 6.6136528],
          [100.3161066, 6.6133188],
          [100.315244, 6.6131488],
          [100.313808, 6.6133396],
          [100.3129756, 6.6130337],
          [100.3122879, 6.6130263],
          [100.3119478, 6.6127454],
          [100.3115084, 6.6127819],
          [100.310748, 6.6121534],
          [100.310616, 6.6118405],
          [100.3104618, 6.6114721],
          [100.3104667, 6.6111855],
          [100.3102202, 6.6110458],
          [100.3099246, 6.6108505],
          [100.3096196, 6.6108108],
          [100.3091207, 6.6109345],
          [100.3084985, 6.6106846],
          [100.3077268, 6.6108074],
          [100.3073247, 6.610652],
          [100.3069994, 6.610914],
          [100.3058558, 6.6111053],
          [100.3056866, 6.6107512],
          [100.305376, 6.6101009],
          [100.3055429, 6.609592],
          [100.3053906, 6.6089359],
          [100.3055861, 6.6085872],
          [100.3056691, 6.6081295],
          [100.3058196, 6.6079301],
          [100.3058639, 6.6073828],
          [100.3054114, 6.6060732],
          [100.305056, 6.6056225],
          [100.3051792, 6.6054608],
          [100.3051751, 6.6052288],
          [100.3055841, 6.6049994],
          [100.3063598, 6.6056494],
          [100.3066833, 6.6055932],
          [100.3068736, 6.6058327],
          [100.3071413, 6.6057901],
          [100.3073186, 6.6054542],
          [100.308134, 6.60512],
          [100.3087044, 6.6047629],
          [100.308875, 6.6045698],
          [100.3091683, 6.6044888],
          [100.3091575, 6.6041091],
          [100.3094434, 6.6039953],
          [100.309994, 6.6039997],
          [100.3103606, 6.6037703],
          [100.3107067, 6.6038588],
          [100.3113914, 6.6036728],
          [100.3116654, 6.6034397],
          [100.311984, 6.6033704],
          [100.3123472, 6.6031691],
          [100.3134942, 6.6036023],
          [100.3158202, 6.6041633],
          [100.3167074, 6.6032638],
          [100.3173568, 6.6013922],
          [100.3177135, 6.5999475],
          [100.3175123, 6.5986029],
          [100.3177409, 6.5975489],
          [100.3183171, 6.5958317],
          [100.3195885, 6.5943598],
          [100.3196811, 6.5939299],
          [100.3195696, 6.5931724],
          [100.3202063, 6.5917101],
          [100.320838, 6.5909345],
          [100.3212438, 6.5908862],
          [100.322079, 6.5910552],
          [100.3226849, 6.5908103],
          [100.323039, 6.5905156],
          [100.3231074, 6.5897736],
          [100.3232906, 6.589254],
          [100.3237385, 6.5890827],
          [100.3240834, 6.5893094],
          [100.3243592, 6.5892886],
          [100.3246741, 6.5890144],
          [100.3251569, 6.5888202],
          [100.3252239, 6.5884376],
          [100.3255412, 6.5876225],
          [100.3255412, 6.5873577],
          [100.3252711, 6.5869479],
          [100.3249173, 6.5863142],
          [100.3252336, 6.585796],
          [100.3257252, 6.5854896],
          [100.3260575, 6.5855229],
          [100.3278503, 6.5847723],
          [100.3284385, 6.5842423],
          [100.3287588, 6.584331],
          [100.3310487, 6.5844017],
          [100.3334999, 6.5845199],
          [100.3348079, 6.5849923],
          [100.3366198, 6.5836135],
          [100.3363568, 6.5829253],
          [100.3358766, 6.5823529],
          [100.3381037, 6.5813716],
          [100.339805, 6.5814942],
          [100.3408293, 6.5806629],
          [100.3415793, 6.5802222],
          [100.3421235, 6.5789501],
          [100.3421418, 6.5778098],
          [100.3419635, 6.5766786],
          [100.3411495, 6.5761562],
          [100.3415336, 6.5751476],
          [100.3432394, 6.5739074],
          [100.3443781, 6.5725217],
          [100.3451921, 6.5711906],
          [100.3455305, 6.5711225],
          [100.346436, 6.5720538],
          [100.3474924, 6.5723082],
          [100.3483339, 6.5730033],
          [100.3495686, 6.5728307],
          [100.3515122, 6.5729442],
          [100.3525914, 6.5715631],
          [100.3531494, 6.5704274],
          [100.3532728, 6.5695869],
          [100.3537805, 6.5686737],
          [100.353934, 6.5677823],
          [100.3539919, 6.5669864],
          [100.3542835, 6.5653163],
          [100.3538856, 6.5639034],
          [100.3539437, 6.5636657],
          [100.3535386, 6.5628958],
          [100.3535036, 6.5617041],
          [100.3534724, 6.5614797],
          [100.3535114, 6.5613869],
          [100.3536827, 6.5614565],
          [100.353893, 6.5616384],
          [100.3544298, 6.5618408],
          [100.3548589, 6.5616035],
          [100.3555274, 6.5616318],
          [100.3565518, 6.5602144],
          [100.3562492, 6.5591777],
          [100.3564673, 6.5588372],
          [100.3570476, 6.5583613],
          [100.3575383, 6.5580595],
          [100.3577018, 6.5580363],
          [100.3578187, 6.5579744],
          [100.3578771, 6.5578506],
          [100.3579861, 6.5576069],
          [100.3585275, 6.5573747],
          [100.3589894, 6.5572965],
          [100.3594513, 6.5570075],
          [100.3597608, 6.5571818],
          [100.3601627, 6.556957],
          [100.3595345, 6.5562515],
          [100.3590402, 6.5561185],
          [100.3588462, 6.5557605],
          [100.3588, 6.5550768],
          [100.3585229, 6.5548657],
          [100.3583104, 6.5543013],
          [100.3582042, 6.5538149],
          [100.3589987, 6.5531174],
          [100.3590324, 6.5526824],
          [100.359194, 6.5525034],
          [100.3591894, 6.5523244],
          [100.3591478, 6.5520032],
          [100.3591063, 6.5517921],
          [100.3590185, 6.5515856],
          [100.3590277, 6.5513011],
          [100.3593003, 6.5503053],
          [100.3597021, 6.5490617],
          [100.3596282, 6.5485386],
          [100.3596421, 6.5480568],
          [100.360104, 6.5476208],
          [100.3606444, 6.5469876],
          [100.3609446, 6.5465792],
          [100.3611802, 6.5461753],
          [100.3611756, 6.5457899],
          [100.3613696, 6.5454273],
          [100.3616329, 6.5451887],
          [100.36191, 6.5451428],
          [100.3622195, 6.5450832],
          [100.3624689, 6.544918],
          [100.3627045, 6.5447574],
          [100.3628292, 6.5447344],
          [100.3630417, 6.5449508],
          [100.3631803, 6.5450518],
          [100.3636098, 6.5450518],
          [100.3647784, 6.5436843],
          [100.3647415, 6.5430464],
          [100.3653327, 6.5427756],
          [100.3656283, 6.5420277],
          [100.3657484, 6.5415091],
          [100.3655822, 6.5407657],
          [100.3659471, 6.5400544],
          [100.3664829, 6.5396689],
          [100.366784, 6.5395792],
          [100.3673907, 6.5381143],
          [100.3681658, 6.536737],
          [100.3699077, 6.5326856],
          [100.3706481, 6.5302959],
          [100.3706848, 6.5299344],
          [100.3705472, 6.5282035],
          [100.3699827, 6.5217723],
          [100.3690119, 6.5118746],
          [100.368278, 6.5042542],
          [100.36524, 6.4754432],
          [100.3630019, 6.4542178],
          [100.3553429, 6.4476279],
          [100.3422567, 6.4362971],
          [100.3208591, 6.4179916],
          [100.3107257, 6.4095511],
          [100.3095527, 6.4103147],
          [100.3084158, 6.4105044],
          [100.3075902, 6.409159],
          [100.3055421, 6.4082698],
          [100.3057137, 6.4071531],
          [100.3046416, 6.4068953],
          [100.3048881, 6.4045367],
          [100.3024205, 6.4038126],
          [100.2939072, 6.3920275],
          [100.2827862, 6.3754797],
          [100.2793473, 6.3704391],
          [100.2787357, 6.3698907],
          [100.2763064, 6.3681402],
          [100.2752226, 6.3674854],
          [100.274515, 6.3675302],
          [100.2738017, 6.3672244],
          [100.2732017, 6.3664295],
          [100.2720912, 6.3651787],
          [100.2707592, 6.3636336],
          [100.2693988, 6.3619229],
          [100.2671677, 6.3592477],
          [100.2664576, 6.3583129],
          [100.2658721, 6.3574548],
          [100.2653882, 6.3568766],
          [100.2646478, 6.3561962],
          [100.2642325, 6.3558978],
          [100.2626099, 6.3548623],
          [100.2616015, 6.3541227],
          [100.2608012, 6.3535034],
          [100.2601491, 6.3530139],
          [100.2590156, 6.3521049],
          [100.2583542, 6.3515218],
          [100.2575926, 6.3508078],
          [100.2570907, 6.3503639],
          [100.2565574, 6.3498884],
          [100.2557417, 6.3491907],
          [100.2551995, 6.3485408],
          [100.2543015, 6.34696],
          [100.2533323, 6.3453931],
          [100.2526467, 6.344361],
          [100.2521648, 6.3434096],
          [100.2518579, 6.3429732],
          [100.251497, 6.3417432],
          [100.2510676, 6.3403166],
          [100.2506963, 6.3393633],
          [100.2504095, 6.3384368],
          [100.2501756, 6.337751],
          [100.2497295, 6.3363289],
          [100.2495092, 6.3354918],
          [100.2493243, 6.3350379],
          [100.2489064, 6.3348326],
          [100.2483392, 6.3345872],
          [100.2474266, 6.3344788],
          [100.2468967, 6.3342332],
          [100.2461253, 6.3338122],
          [100.245343, 6.3334733],
          [100.2450892, 6.3333176],
          [100.2448659, 6.3332214],
          [100.2443887, 6.3329861],
          [100.2437398, 6.3325019],
          [100.2432038, 6.3320753],
          [100.2429134, 6.3316666],
          [100.2426231, 6.3312298],
          [100.2424026, 6.3307793],
          [100.242209, 6.3304975],
          [100.2420709, 6.3302581],
          [100.2419052, 6.3299905],
          [100.241739, 6.3298071],
          [100.2413932, 6.3293279],
          [100.2411303, 6.3289895],
          [100.24077, 6.3286367],
          [100.240673, 6.3285379],
          [100.2404533, 6.3283426],
          [100.240289, 6.3282087],
          [100.2400365, 6.3280077],
          [100.2399207, 6.327886],
          [100.2396927, 6.3276732],
          [100.2393421, 6.3273387],
          [100.23892, 6.3268925],
          [100.2387053, 6.3266288],
          [100.2384768, 6.326287],
          [100.2380307, 6.3257867],
          [100.2378742, 6.3256516],
          [100.2375579, 6.3254289],
          [100.2372521, 6.3252469],
          [100.2370035, 6.3249934],
          [100.236847, 6.324838],
          [100.2365172, 6.3246052],
          [100.236371, 6.324521],
          [100.236031, 6.3242882],
          [100.2358712, 6.3241735],
          [100.2354157, 6.3238631],
          [100.2350385, 6.3236645],
          [100.2348245, 6.3235669],
          [100.2342367, 6.3232503],
          [100.2340793, 6.3231569],
          [100.233762, 6.322924],
          [100.2335462, 6.3227559],
          [100.2333306, 6.3225622],
          [100.2330898, 6.322304],
          [100.2328107, 6.3220714],
          [100.2323672, 6.3215938],
          [100.2321897, 6.3214259],
          [100.232025, 6.3212452],
          [100.2317459, 6.3210126],
          [100.2315303, 6.3208188],
          [100.2313908, 6.3206896],
          [100.2312512, 6.3205733],
          [100.2309972, 6.3202673],
          [100.2307816, 6.3200735],
          [100.2305785, 6.3199183],
          [100.2278922, 6.3170271],
          [100.2266624, 6.3158261],
          [100.2263585, 6.3154649],
          [100.2260291, 6.3150907],
          [100.225814, 6.314807],
          [100.2256748, 6.3146264],
          [100.2254442, 6.3144301],
          [100.2253214, 6.3142304],
          [100.2250995, 6.3138615],
          [100.2248135, 6.3135539],
          [100.224657, 6.3134154],
          [100.2245074, 6.3132938],
          [100.2243951, 6.3131891],
          [100.2241432, 6.3129356],
          [100.2238847, 6.3127263],
          [100.2237248, 6.3125845],
          [100.2235445, 6.3124461],
          [100.2232654, 6.3121723],
          [100.2231122, 6.3119965],
          [100.2226277, 6.3112317],
          [100.2223288, 6.3110904],
          [100.2220257, 6.3107421],
          [100.2218791, 6.3105324],
          [100.2217869, 6.3103801],
          [100.2215518, 6.3100655],
          [100.2212416, 6.309663],
          [100.2210848, 6.3094363],
          [100.2202351, 6.3080452],
          [100.2200031, 6.3076696],
          [100.2198186, 6.3072971],
          [100.2196202, 6.3068399],
          [100.2194451, 6.306274],
          [100.2192499, 6.305793],
          [100.2191772, 6.3054099],
          [100.2191216, 6.3050539],
          [100.2190528, 6.3047998],
          [100.2188885, 6.3043899],
          [100.2186833, 6.3039463],
          [100.2185422, 6.3033972],
          [100.2184694, 6.3029938],
          [100.2183829, 6.3025294],
          [100.2182523, 6.3020956],
          [100.2181729, 6.3017194],
          [100.2180857, 6.3010989],
          [100.2179242, 6.3005363],
          [100.2178931, 6.3004037],
          [100.2178937, 6.3002969],
          [100.2178167, 6.299816],
          [100.2175883, 6.2993058],
          [100.2170549, 6.2986737],
          [100.2159725, 6.2977848],
          [100.214734, 6.2969998],
          [100.2078089, 6.2921778],
          [100.2072527, 6.2917485],
          [100.2066016, 6.2913785],
          [100.2063556, 6.2911489],
          [100.2060962, 6.290933],
          [100.2055721, 6.2907764],
          [100.2051844, 6.2907006],
          [100.2049054, 6.2900701],
          [100.204688, 6.2895173],
          [100.2045181, 6.2889542],
          [100.204383, 6.2879625],
          [100.2043568, 6.2870041],
          [100.2043546, 6.2865777],
          [100.2042755, 6.2859271],
          [100.2040151, 6.2855582],
          [100.2038143, 6.2849374],
          [100.2035895, 6.2842658],
          [100.2033253, 6.2838357],
          [100.2029831, 6.2834231],
          [100.2026756, 6.2831395],
          [100.2023091, 6.2826319],
          [100.2018696, 6.2824136],
          [100.2012842, 6.2822404],
          [100.2013198, 6.2819411],
          [100.2015999, 6.2816369],
          [100.2018675, 6.2815197],
          [100.2022361, 6.2812353],
          [100.2022678, 6.2808749],
          [100.2021574, 6.2805798],
          [100.2022032, 6.2802974],
          [100.2024158, 6.2800718],
          [100.2026695, 6.2798935],
          [100.203273, 6.2797097],
          [100.2035526, 6.2793137],
          [100.2035368, 6.2789569],
          [100.2034145, 6.2783968],
          [100.2034488, 6.2778868],
          [100.2031947, 6.2774328],
          [100.2030503, 6.2771618],
          [100.2031119, 6.2766618],
          [100.2031851, 6.2764132],
          [100.2033103, 6.2757836],
          [100.2033592, 6.2754468],
          [100.2035805, 6.274973],
          [100.2035721, 6.2747012],
          [100.2032113, 6.2745742],
          [100.2028206, 6.2745732],
          [100.2025551, 6.2744728],
          [100.2026514, 6.2741221],
          [100.2028599, 6.2737776],
          [100.2029768, 6.2734506],
          [100.2031886, 6.2730822],
          [100.2035098, 6.2728321],
          [100.2038581, 6.2725785],
          [100.2039084, 6.2724626],
          [100.203751, 6.2722868],
          [100.2034916, 6.272081],
          [100.2035954, 6.2718255],
          [100.2037134, 6.2716888],
          [100.203784, 6.2715626],
          [100.2035518, 6.2713533],
          [100.2033852, 6.2713407],
          [100.2030117, 6.2713735],
          [100.2028775, 6.2713674],
          [100.2028039, 6.2712661],
          [100.2027032, 6.2708962],
          [100.2027698, 6.2706782],
          [100.2029622, 6.270551],
          [100.2034188, 6.2705372],
          [100.2036342, 6.2704362],
          [100.2036127, 6.2701973],
          [100.2032454, 6.2701281],
          [100.2027188, 6.2701109],
          [100.2027921, 6.2698827],
          [100.2029873, 6.2695723],
          [100.2031453, 6.2693028],
          [100.2031911, 6.2690204],
          [100.202867, 6.268798],
          [100.2023949, 6.2688043],
          [100.2022881, 6.2685568],
          [100.2022765, 6.2683122],
          [100.2022447, 6.2681084],
          [100.2024176, 6.2680564],
          [100.2027205, 6.2681633],
          [100.202808, 6.2680268],
          [100.2023306, 6.2677136],
          [100.2020676, 6.2674773],
          [100.2018909, 6.2669073],
          [100.2018412, 6.2665609],
          [100.2013069, 6.266401],
          [100.20101, 6.2661649],
          [100.2011373, 6.2658752],
          [100.2014563, 6.2658291],
          [100.2016837, 6.2657869],
          [100.2017442, 6.2656914],
          [100.2013393, 6.2655715],
          [100.2010842, 6.2655118],
          [100.2008258, 6.265476],
          [100.2010175, 6.2651655],
          [100.2008797, 6.2648435],
          [100.200475, 6.2647712],
          [100.2001913, 6.265038],
          [100.2001634, 6.2654834],
          [100.2000226, 6.2657766],
          [100.1996888, 6.2656291],
          [100.1996141, 6.2650823],
          [100.1995876, 6.2646372],
          [100.1996641, 6.2643648],
          [100.200085, 6.2643147],
          [100.2004416, 6.2643057],
          [100.2003894, 6.2641021],
          [100.1999403, 6.2639723],
          [100.1993056, 6.2640645],
          [100.1990841, 6.2639435],
          [100.1993433, 6.2635612],
          [100.1995554, 6.2632336],
          [100.1995697, 6.2628019],
          [100.199239, 6.2626067],
          [100.1988384, 6.2626262],
          [100.1985544, 6.2628488],
          [100.1984586, 6.2632947],
          [100.1983955, 6.2635398],
          [100.1982252, 6.2634694],
          [100.1981815, 6.2629802],
          [100.1984714, 6.2626182],
          [100.1987608, 6.2621576],
          [100.1987603, 6.2620726],
          [100.1986697, 6.2617095],
          [100.198345, 6.2613783],
          [100.1979361, 6.2611633],
          [100.1975538, 6.2608461],
          [100.1975464, 6.2601969],
          [100.1978245, 6.2598599],
          [100.1985079, 6.25928],
          [100.1988935, 6.2589508],
          [100.198042, 6.2576395],
          [100.1978148, 6.25755],
          [100.1972733, 6.2572647],
          [100.19597, 6.2552622],
          [100.1953576, 6.2543839],
          [100.1950049, 6.2545894],
          [100.1945895, 6.2545247],
          [100.1934478, 6.2545837],
          [100.1920908, 6.2552918],
          [100.1909208, 6.2563576],
          [100.1896201, 6.2577211],
          [100.1885868, 6.2592599],
          [100.1881109, 6.2599731],
          [100.1879501, 6.2601511],
          [100.1875117, 6.2606327],
          [100.1868133, 6.2615773],
          [100.1861408, 6.2624616],
          [100.1858443, 6.2628705],
          [100.1855235, 6.263244],
          [100.1848449, 6.264256],
          [100.1841088, 6.2652217],
          [100.1838783, 6.2662583],
          [100.1834168, 6.266993],
          [100.1830639, 6.267812],
          [100.1828114, 6.268352],
          [100.1818093, 6.2699361],
          [100.1793733, 6.2747097],
          [100.1778218, 6.2777685],
          [100.1760204, 6.2807045],
          [100.1753327, 6.2822028],
          [100.1743296, 6.2842131],
          [100.1732846, 6.2863182],
          [100.1721162, 6.2877526],
          [100.1700101, 6.2909529],
          [100.1686208, 6.2940807],
          [100.1678655, 6.2962114],
          [100.1676165, 6.2978025],
          [100.1675146, 6.2995759],
          [100.1674223, 6.3008546],
          [100.165769, 6.302211],
          [100.1655309, 6.303704],
          [100.1641876, 6.3067161],
          [100.1637544, 6.3082529],
          [100.1635116, 6.3090182],
          [100.1632074, 6.3096021],
          [100.1616905, 6.3123718],
          [100.1610217, 6.3142803],
          [100.1599467, 6.3180419],
          [100.1589479, 6.3217688],
          [100.1581119, 6.3235631],
          [100.1566598, 6.326848],
          [100.1563072, 6.3278553],
          [100.155975, 6.3297336],
          [100.1560314, 6.3298662],
          [100.1564921, 6.330127],
          [100.1566834, 6.3306747],
          [100.1565052, 6.3317439],
          [100.1563593, 6.3318736],
          [100.156214, 6.3319525],
          [100.1557054, 6.331783],
          [100.1554636, 6.3318749],
          [100.1553882, 6.332031],
          [100.1546033, 6.3338929],
          [100.1530485, 6.3369756],
          [100.1527471, 6.3375784],
          [100.1527064, 6.3377767],
          [100.1526499, 6.3386938],
          [100.1526176, 6.3387976],
          [100.150716, 6.3429101],
          [100.1501765, 6.3433162],
          [100.1481877, 6.3490436],
          [100.1480946, 6.3493758],
          [100.14813, 6.3495248],
          [100.1481597, 6.3496493],
          [100.1481135, 6.3498404],
          [100.1480731, 6.3500076],
          [100.1480417, 6.350146],
          [100.148015666276734, 6.350138454344888],
          [100.147902449885507, 6.350396401346385],
          [100.1478927, 6.3504463],
          [100.1477645, 6.3507107],
          [100.1472324, 6.3524031],
          [100.1448251, 6.3599894],
          [100.1436056, 6.3639042],
          [100.1429868, 6.3657646],
          [100.14225, 6.3675455],
          [100.1414645, 6.3695261],
          [100.1414055, 6.3698753],
          [100.1411909, 6.3706537],
          [100.1410675, 6.3708616],
          [100.1409095, 6.3711292],
          [100.1411434, 6.3713843],
          [100.1410975, 6.3715422],
          [100.1410604, 6.37167],
          [100.1409126, 6.3721789],
          [100.1399653, 6.3749985],
          [100.1381872, 6.3797137],
          [100.1379997, 6.3797137],
          [100.1376868, 6.3804967],
          [100.1377988, 6.3806375],
          [100.1365813, 6.3836952],
          [100.1363231, 6.3850428],
          [100.1359251, 6.3859804],
          [100.1353798, 6.3870825],
          [100.1351414, 6.3871175],
          [100.1349509, 6.3872681],
          [100.1348955, 6.3874833],
          [100.1350093, 6.3879012],
          [100.1339177, 6.3892202],
          [100.133601, 6.3898258],
          [100.1331726, 6.3912036],
          [100.1327206, 6.3922928],
          [100.1323103, 6.3927169],
          [100.1307303, 6.393212],
          [100.1301748, 6.39367],
          [100.1298398, 6.3935256],
          [100.1293582, 6.3934105],
          [100.1289209, 6.3941156],
          [100.1285099, 6.3944858],
          [100.1284801, 6.3945531],
          [100.1282355, 6.3951053],
          [100.1281156, 6.3955294],
          [100.127828, 6.3958746],
          [100.1277499, 6.396224],
          [100.1274551, 6.3966416],
          [100.1272367, 6.396768],
          [100.1270922, 6.3971952],
          [100.1272735, 6.3974841],
          [100.1271347, 6.3977729],
          [100.1274057, 6.3978898],
          [100.1273356, 6.3981164],
          [100.127929, 6.3984365],
          [100.1289222, 6.3989337],
          [100.1290254, 6.3988056],
          [100.1297212, 6.3991944],
          [100.1296627, 6.3993106],
          [100.1298153, 6.3994384],
          [100.1296018, 6.3998497],
          [100.1292, 6.399864],
          [100.1289251, 6.4005242],
          [100.1286158, 6.4012446],
          [100.1282781, 6.4019952],
          [100.127621, 6.4018917],
          [100.127054, 6.4021024],
          [100.126768, 6.4019829],
          [100.1255044, 6.4029564],
          [100.1248199, 6.4039638],
          [100.1246973, 6.4043761],
          [100.1244555, 6.404653],
          [100.1244729, 6.4048366],
          [100.124652, 6.4050215],
          [100.124469, 6.4056639],
          [100.1247948, 6.4057059],
          [100.1252738, 6.4055956],
          [100.1245977, 6.4067615],
          [100.1243257, 6.4072655],
          [100.1239868, 6.4109801],
          [100.1232854, 6.4139541],
          [100.1217712, 6.4185458],
        ],
      ],
    ],
  },
};
