export const terengganu = {
  type: "Feature",
  properties: {
    gid: 16,
    id: 4444411,
    country: "MYS",
    name: "Terengganu",
    enname: "Terengganu",
    locname: "Terengganu / ???????",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q189701",
    wikimedia: "en:Terengganu",
    timestamp: "2019-11-26 10:31:02",
    note: null,
    path: "0,2108121,4444411",
    rpath: "4444411,2108121,0",
    iso3166_2: "MY-11",
    dailyCase: {
      hahahaha: 1,
    },
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [103.687577226157913, 4.781981435229016],
          [103.6875697, 4.7819876],
          [103.6875628, 4.782021],
          [103.6875453, 4.7820361],
          [103.6875194, 4.7820203],
          [103.6874972, 4.7820524],
          [103.6875229, 4.782076],
          [103.6875153, 4.7820901],
          [103.6874632, 4.7820631],
          [103.68745347826173, 4.782073073596774],
          [103.687455289213133, 4.78209162315502],
          [103.6874926, 4.7821202],
          [103.6875266, 4.7821262],
          [103.6875758, 4.7821834],
          [103.687706, 4.7822325],
          [103.6877901, 4.7822264],
          [103.687829, 4.7821995],
          [103.6878016, 4.7822445],
          [103.6879276, 4.7822471],
          [103.687954398480102, 4.78226669386359],
          [103.68797657495719, 4.782265559598271],
          [103.6879962, 4.7822428],
          [103.688022423160888, 4.782263214589764],
          [103.688086824526664, 4.782259920638455],
          [103.6881479, 4.7821977],
          [103.6882793, 4.7823131],
          [103.6883059, 4.7823663],
          [103.6884215, 4.7824695],
          [103.6886798, 4.7825561],
          [103.6887155, 4.7825888],
          [103.688717335809471, 4.782589200440513],
          [103.688879152889868, 4.782543904357841],
          [103.6889016, 4.7825202],
          [103.688988, 4.7825014],
          [103.6890035, 4.7824502],
          [103.689036249235045, 4.782470231892499],
          [103.6890801710381, 4.782428657681148],
          [103.6890818, 4.7824228],
          [103.6890389, 4.7823773],
          [103.6891304, 4.782338],
          [103.689146548865949, 4.782302203347119],
          [103.6891445, 4.7822826],
          [103.6891044, 4.7822557],
          [103.6890643, 4.7822545],
          [103.6890542, 4.7822264],
          [103.6890712, 4.7821886],
          [103.689096014612133, 4.782187815968021],
          [103.6890802, 4.7821569],
          [103.688988574275314, 4.782151872915726],
          [103.6889411, 4.7821719],
          [103.6888949, 4.782176],
          [103.6887361, 4.7821341],
          [103.6885994, 4.7821387],
          [103.6883937, 4.7821535],
          [103.6883157, 4.7821403],
          [103.68830369702566, 4.782132132122343],
          [103.688176192505964, 4.782101860889666],
          [103.688166, 4.7821268],
          [103.6881358, 4.7821514],
          [103.6881407, 4.7821779],
          [103.688084, 4.7821867],
          [103.6880592, 4.7821896],
          [103.6880263, 4.7821713],
          [103.6879823, 4.7821756],
          [103.6879107, 4.7821302],
          [103.687825103333836, 4.782083549604118],
          [103.6877545, 4.7820732],
          [103.6877313, 4.7820493],
          [103.687756305838121, 4.782046054797897],
          [103.6877096, 4.7820206],
          [103.687617064836132, 4.781993222094789],
          [103.687608, 4.7820146],
          [103.6875915, 4.7820124],
          [103.687595431130958, 4.781986821441194],
          [103.687577226157913, 4.781981435229016],
        ],
      ],
      [
        [
          [103.674421, 4.8007288],
          [103.6731335, 4.8032733],
          [103.6734125, 4.8042355],
          [103.6740777, 4.8050695],
          [103.6755153, 4.8054543],
          [103.6761338, 4.8059435],
          [103.6775065, 4.805415],
          [103.6782568, 4.8070026],
          [103.6784722, 4.8073813],
          [103.6785921, 4.807783],
          [103.6786826, 4.808086],
          [103.678662, 4.8085917],
          [103.6785783, 4.8089355],
          [103.6781266, 4.8099031],
          [103.6778927, 4.8102483],
          [103.6776627, 4.8104717],
          [103.677465, 4.8105494],
          [103.6773934, 4.8105428],
          [103.6773076, 4.8105003],
          [103.6772447, 4.8104379],
          [103.6772109, 4.8103637],
          [103.6764814, 4.810052],
          [103.6766955, 4.811869],
          [103.6795708, 4.8158888],
          [103.6802146, 4.8167868],
          [103.6813518, 4.8171076],
          [103.6815449, 4.816851],
          [103.680472, 4.8148411],
          [103.6820599, 4.8144776],
          [103.6821028, 4.8135368],
          [103.6835834, 4.8129595],
          [103.6846134, 4.8129808],
          [103.6858579, 4.8131091],
          [103.6867162, 4.8133871],
          [103.6864373, 4.8125532],
          [103.6863514, 4.8120828],
          [103.6871239, 4.8120828],
          [103.6879608, 4.8117835],
          [103.6890551, 4.8115482],
          [103.6903855, 4.8115696],
          [103.6907288, 4.810693],
          [103.6909976, 4.810093],
          [103.6900207, 4.8094742],
          [103.6889264, 4.8092604],
          [103.6879608, 4.8087258],
          [103.6869093, 4.808234],
          [103.686094, 4.807336],
          [103.6862442, 4.8065662],
          [103.6866733, 4.8057965],
          [103.6871411, 4.8051863],
          [103.6871787, 4.8043316],
          [103.6877913, 4.8039772],
          [103.6880155, 4.8038702],
          [103.6874136, 4.8028973],
          [103.6870177, 4.8027049],
          [103.6865875, 4.8024822],
          [103.6859867, 4.8011137],
          [103.6850211, 4.7995742],
          [103.6851713, 4.7990182],
          [103.6857292, 4.7984837],
          [103.6858794, 4.7976284],
          [103.686051, 4.7969014],
          [103.6862227, 4.7958109],
          [103.6854073, 4.7953405],
          [103.6834761, 4.7950197],
          [103.6822101, 4.793245],
          [103.6806652, 4.7932663],
          [103.6783263, 4.7947204],
          [103.6777469, 4.7963027],
          [103.6775109, 4.7984623],
          [103.675753, 4.8003836],
          [103.6755153, 4.8006433],
          [103.6746932, 4.8007075],
          [103.674421, 4.8007288],
        ],
      ],
      [
        [
          [103.6620408, 4.846661],
          [103.6619899, 4.8469256],
          [103.6621079, 4.8472115],
          [103.6622608, 4.8474013],
          [103.6626363, 4.8475135],
          [103.6629447, 4.8477006],
          [103.6632022, 4.8477354],
          [103.6634785, 4.8477942],
          [103.6637199, 4.8478423],
          [103.6640257, 4.8477674],
          [103.6643234, 4.8476124],
          [103.6643475, 4.8477006],
          [103.6644387, 4.8477006],
          [103.6646479, 4.8476311],
          [103.6649188, 4.8474948],
          [103.6651978, 4.847436],
          [103.6653587, 4.8472302],
          [103.6654553, 4.8468561],
          [103.6656055, 4.8466423],
          [103.6654912, 4.8465105],
          [103.6654962, 4.8463786],
          [103.6654009, 4.8462337],
          [103.6655739, 4.8463795],
          [103.6656465, 4.8463662],
          [103.6656664, 4.8462244],
          [103.6654197, 4.8459429],
          [103.6655991, 4.8459972],
          [103.6656081, 4.8459475],
          [103.6654653, 4.8458843],
          [103.6655272, 4.8458072],
          [103.6657924, 4.8458489],
          [103.6658362, 4.8457443],
          [103.665922, 4.845779],
          [103.6662546, 4.8457924],
          [103.6664209, 4.8455518],
          [103.6664128, 4.8452017],
          [103.6664584, 4.8450788],
          [103.6666059, 4.8447554],
          [103.6665925, 4.8445737],
          [103.6662707, 4.8442984],
          [103.665989, 4.8440872],
          [103.6660427, 4.8438868],
          [103.6662599, 4.8438574],
          [103.666555, 4.8436917],
          [103.6668956, 4.8438093],
          [103.6671075, 4.8437345],
          [103.6672175, 4.8435875],
          [103.6673731, 4.8434859],
          [103.6674213, 4.8432694],
          [103.6673731, 4.8430957],
          [103.6673918, 4.8428632],
          [103.6672416, 4.8426654],
          [103.6669788, 4.8425131],
          [103.6671585, 4.8424142],
          [103.6670056, 4.8423073],
          [103.6668366, 4.8423874],
          [103.6667454, 4.8422805],
          [103.666504, 4.8421255],
          [103.6662841, 4.8419705],
          [103.666496, 4.8419759],
          [103.6666247, 4.8419144],
          [103.6668581, 4.8418529],
          [103.6667857, 4.841738],
          [103.6664772, 4.8417273],
          [103.6662304, 4.8417407],
          [103.6661366, 4.8418984],
          [103.6659354, 4.842016],
          [103.6657289, 4.8422084],
          [103.6654901, 4.8423874],
          [103.6654285, 4.8425425],
          [103.6651495, 4.8426467],
          [103.6648867, 4.8427483],
          [103.664597, 4.8429728],
          [103.6644495, 4.8429246],
          [103.6642939, 4.8430209],
          [103.6643609, 4.8431892],
          [103.6644548, 4.8434966],
          [103.6645755, 4.8437585],
          [103.6642402, 4.8441166],
          [103.6640525, 4.8443438],
          [103.6639988, 4.8445068],
          [103.6638218, 4.8447982],
          [103.6637655, 4.8449612],
          [103.6635992, 4.8450146],
          [103.6635268, 4.8452124],
          [103.66339, 4.8455305],
          [103.6633202, 4.8457042],
          [103.6630601, 4.8457416],
          [103.6628991, 4.8457389],
          [103.6626577, 4.8458298],
          [103.6623707, 4.8459928],
          [103.6621803, 4.84622],
          [103.6620918, 4.8464338],
          [103.6620408, 4.846661],
        ],
      ],
      [
        [
          [103.2607292, 5.2221361],
          [103.2604639, 5.2222279],
          [103.2605422, 5.2224328],
          [103.260775, 5.2225528],
          [103.2608544, 5.2224553],
          [103.2608933, 5.2223592],
          [103.2611159, 5.2224005],
          [103.2612743, 5.2224893],
          [103.2619149, 5.2234518],
          [103.2620607, 5.2239663],
          [103.2620324, 5.2242227],
          [103.2617546, 5.2244357],
          [103.2614795, 5.2245081],
          [103.2614698, 5.2247745],
          [103.2616404, 5.2248794],
          [103.2618292, 5.2248061],
          [103.261945, 5.2249333],
          [103.2620956, 5.2250986],
          [103.2623281, 5.2251833],
          [103.2631602, 5.226089],
          [103.263235, 5.2262637],
          [103.2636256, 5.2265756],
          [103.2643343, 5.2267045],
          [103.2653457, 5.2275266],
          [103.2659573, 5.2278905],
          [103.2666804, 5.2281775],
          [103.2667551, 5.2285668],
          [103.2669487, 5.2289517],
          [103.2670306, 5.2302415],
          [103.2673753, 5.230596],
          [103.2675129, 5.2311578],
          [103.2674437, 5.2314002],
          [103.2675836, 5.2314906],
          [103.2676849, 5.2311784],
          [103.2678569, 5.2309386],
          [103.2680771, 5.2309934],
          [103.2681802, 5.2303151],
          [103.2680358, 5.2303699],
          [103.2678638, 5.230459],
          [103.2677606, 5.2308564],
          [103.2677537, 5.229815],
          [103.2676044, 5.2297161],
          [103.2675473, 5.2295615],
          [103.2678087, 5.2296163],
          [103.2678775, 5.2294313],
          [103.2680495, 5.229445],
          [103.2683397, 5.2292049],
          [103.2684142, 5.2287325],
          [103.2682284, 5.2281912],
          [103.2680633, 5.2281158],
          [103.2681321, 5.227554],
          [103.2682146, 5.2277253],
          [103.2684073, 5.2274855],
          [103.2684898, 5.2271772],
          [103.2682838, 5.2273987],
          [103.2682559, 5.2270128],
          [103.2681734, 5.2266976],
          [103.2682422, 5.226218],
          [103.2684649, 5.2260149],
          [103.2687031, 5.226218],
          [103.2688191, 5.2260905],
          [103.2687788, 5.2258069],
          [103.2686756, 5.2254095],
          [103.268765, 5.2252383],
          [103.2687843, 5.2250389],
          [103.2689646, 5.2249779],
          [103.2690815, 5.2243339],
          [103.2691384, 5.2238777],
          [103.2687994, 5.2235391],
          [103.2687788, 5.2232651],
          [103.2689302, 5.2232582],
          [103.2688545, 5.222991],
          [103.2681674, 5.2226073],
          [103.2684004, 5.2224977],
          [103.2688272, 5.2224625],
          [103.2687582, 5.2221483],
          [103.2686304, 5.2220228],
          [103.2685999, 5.2217372],
          [103.2689577, 5.2214426],
          [103.2690789, 5.2210696],
          [103.2691296, 5.220093],
          [103.2691965, 5.2200429],
          [103.2693239, 5.2202781],
          [103.2695906, 5.2203624],
          [103.2698739, 5.2204395],
          [103.2702494, 5.2204913],
          [103.2703511, 5.2197635],
          [103.2706158, 5.2190788],
          [103.2705906, 5.2189137],
          [103.270375, 5.2191336],
          [103.2702374, 5.2187911],
          [103.2701111, 5.218905],
          [103.2696801, 5.2184279],
          [103.2696457, 5.2179072],
          [103.2693567, 5.2176332],
          [103.2693705, 5.2173523],
          [103.2695425, 5.2174345],
          [103.2698865, 5.2172769],
          [103.2696113, 5.216626],
          [103.2696663, 5.2163862],
          [103.2698914, 5.2164971],
          [103.2700034, 5.2161395],
          [103.2698177, 5.2156462],
          [103.2705244, 5.2151409],
          [103.2704988, 5.2144541],
          [103.2702577, 5.2141431],
          [103.2703681, 5.2139882],
          [103.2701392, 5.2135745],
          [103.2696765, 5.2131349],
          [103.26919, 5.2108308],
          [103.268893, 5.2102404],
          [103.2681753, 5.2098742],
          [103.2680151, 5.2105281],
          [103.2680755, 5.2111986],
          [103.2672857, 5.211563],
          [103.2667765, 5.2127555],
          [103.2642979, 5.2128071],
          [103.2635651, 5.2131135],
          [103.2632516, 5.2131206],
          [103.2630478, 5.2134165],
          [103.2632744, 5.2137858],
          [103.2631293, 5.2145657],
          [103.2625141, 5.2155521],
          [103.2620989, 5.21653],
          [103.261371, 5.2174443],
          [103.2612186, 5.2176221],
          [103.2610931, 5.2177686],
          [103.2609735, 5.2179289],
          [103.2608588, 5.2183291],
          [103.2608211, 5.2185529],
          [103.2606961, 5.2186729],
          [103.2606651, 5.2187835],
          [103.2607145, 5.2189922],
          [103.2608588, 5.2190988],
          [103.2610995, 5.2196584],
          [103.2613347, 5.2204827],
          [103.2611204, 5.2207856],
          [103.261165, 5.2210346],
          [103.2613947, 5.2211934],
          [103.2613058, 5.2219892],
          [103.2611736, 5.2222197],
          [103.2611465, 5.2223273],
          [103.2609547, 5.2223019],
          [103.2607292, 5.2221361],
        ],
      ],
      [
        [
          [103.2673684, 5.2314935],
          [103.2672652, 5.2318156],
          [103.2673753, 5.2320896],
          [103.2673684, 5.2323911],
          [103.2675748, 5.2324733],
          [103.2677468, 5.2322883],
          [103.2678156, 5.2318704],
          [103.267678, 5.2318019],
          [103.267506, 5.2319183],
          [103.2675473, 5.2316032],
          [103.2673684, 5.2314935],
        ],
      ],
      [
        [
          [103.064060527654988, 5.596014706188896],
          [103.0638318, 5.5961099],
          [103.0632546, 5.5958552],
          [103.0631723, 5.59587],
          [103.063073, 5.5957395],
          [103.0631846, 5.595677],
          [103.0631557, 5.5955804],
          [103.0630365, 5.5955491],
          [103.062992, 5.5956582],
          [103.0628494, 5.5956664],
          [103.0626686, 5.5956801],
          [103.062416, 5.5960432],
          [103.0625002, 5.5964622],
          [103.0629212, 5.5968393],
          [103.0639737, 5.5974817],
          [103.0653349, 5.5984314],
          [103.0661208, 5.5991437],
          [103.066359752962455, 5.599299556761454],
          [103.0663812, 5.5992618],
          [103.066380920146386, 5.599313363029862],
          [103.0665277, 5.5994091],
          [103.066758677098534, 5.599367825451786],
          [103.0668836, 5.5993196],
          [103.0669984, 5.5992365],
          [103.0670176, 5.5991671],
          [103.0670131, 5.5991114],
          [103.0670383, 5.5990673],
          [103.0669852, 5.5990316],
          [103.067079, 5.5989566],
          [103.0670822, 5.5989136],
          [103.0671029, 5.5989085],
          [103.067105, 5.5988569],
          [103.0670623, 5.5988502],
          [103.0670574, 5.5988088],
          [103.0669811, 5.5987989],
          [103.067118, 5.5987559],
          [103.0671098, 5.5986609],
          [103.0671386, 5.5985889],
          [103.0671721, 5.5985856],
          [103.0671263, 5.5984516],
          [103.0670851, 5.5984176],
          [103.0669989, 5.5983652],
          [103.0670324, 5.5982873],
          [103.0669944, 5.5982048],
          [103.0668852, 5.5981744],
          [103.0668279, 5.5980542],
          [103.066807, 5.5979372],
          [103.0667364, 5.5979304],
          [103.0666136, 5.597859],
          [103.0665194, 5.5977226],
          [103.0665598, 5.5976108],
          [103.0665554, 5.5975685],
          [103.066313, 5.5975861],
          [103.0662282, 5.5973903],
          [103.0661886, 5.5972952],
          [103.0661551, 5.5972666],
          [103.0660782, 5.5972959],
          [103.0660424, 5.5972153],
          [103.0659316, 5.5972947],
          [103.0658516, 5.5971439],
          [103.065416, 5.5967836],
          [103.0653785, 5.5966312],
          [103.0653844, 5.5965795],
          [103.0653902, 5.5965033],
          [103.0653501, 5.5964638],
          [103.065248, 5.595927],
          [103.0653118, 5.5958437],
          [103.0653765, 5.5958537],
          [103.0654581, 5.5958179],
          [103.0654549, 5.5957113],
          [103.0654331, 5.5956801],
          [103.06541, 5.5957714],
          [103.0653657, 5.5957626],
          [103.0653435, 5.5956902],
          [103.0653023, 5.595703],
          [103.0653118, 5.5957502],
          [103.0651825, 5.5957493],
          [103.065201, 5.5957838],
          [103.0651253, 5.5957639],
          [103.0651066, 5.5958764],
          [103.0650644, 5.5958755],
          [103.0650516, 5.5958234],
          [103.064968653141364, 5.595778549255587],
          [103.0646613, 5.5959454],
          [103.0641982, 5.596099],
          [103.064060527654988, 5.596014706188896],
        ],
      ],
      [
        [
          [103.2613911, 5.2322043],
          [103.2612673, 5.2326497],
          [103.2613774, 5.2326497],
          [103.2615012, 5.2324989],
          [103.2614806, 5.2327867],
          [103.2616732, 5.2328895],
          [103.2617764, 5.2327456],
          [103.2620103, 5.2328209],
          [103.2622442, 5.2330607],
          [103.2623612, 5.233602],
          [103.2625951, 5.2337527],
          [103.2628566, 5.2342049],
          [103.2632143, 5.2341912],
          [103.2632143, 5.2340473],
          [103.264095, 5.2342871],
          [103.2639986, 5.2348489],
          [103.2641156, 5.2351093],
          [103.2642945, 5.2351093],
          [103.2642463, 5.2348695],
          [103.2641913, 5.2346365],
          [103.2643839, 5.2345767],
          [103.264387200229507, 5.234604777143363],
          [103.2644871, 5.2345886],
          [103.264611, 5.2342871],
          [103.2644527, 5.2341912],
          [103.2642463, 5.233876],
          [103.2641431, 5.2332663],
          [103.2639298, 5.233095],
          [103.2635514, 5.2331704],
          [103.2632006, 5.233095],
          [103.2631937, 5.2329922],
          [103.2628841, 5.232773],
          [103.2628497, 5.2324441],
          [103.2626639, 5.2323071],
          [103.2627396, 5.2320604],
          [103.2626983, 5.2319714],
          [103.2624959, 5.2320627],
          [103.2622687, 5.2322237],
          [103.2618596, 5.232397],
          [103.2616388, 5.2322865],
          [103.2615218, 5.2323551],
          [103.2613911, 5.2322043],
        ],
      ],
      [
        [
          [103.057656618298864, 5.615759960515502],
          [103.0574151, 5.6158475],
          [103.057020763040555, 5.615814404726349],
          [103.0569158, 5.615871],
          [103.0568597, 5.6163458],
          [103.0565509, 5.6168207],
          [103.056172, 5.6171698],
          [103.0561299, 5.6178122],
          [103.0564387, 5.6188178],
          [103.0572526, 5.6197814],
          [103.0580385, 5.6205635],
          [103.0581928, 5.6212757],
          [103.0580806, 5.6220159],
          [103.0576455, 5.6226444],
          [103.0575192, 5.623217],
          [103.0576315, 5.6241247],
          [103.0579402, 5.6247951],
          [103.0585577, 5.6256051],
          [103.0589927, 5.6261917],
          [103.0596944, 5.6266944],
          [103.0606486, 5.6269039],
          [103.0611398, 5.627267],
          [103.061659, 5.6274625],
          [103.0619537, 5.6278675],
          [103.0631465, 5.6285658],
          [103.0638061, 5.6288311],
          [103.0642832, 5.6287753],
          [103.06433, 5.6286593],
          [103.0642858, 5.6285388],
          [103.064423961629274, 5.628536370998079],
          [103.064429736902994, 5.628526568039766],
          [103.0644542, 5.6283895],
          [103.0643796, 5.6282667],
          [103.0644195, 5.6280995],
          [103.064425, 5.6279919],
          [103.064307, 5.6280149],
          [103.0643011, 5.6279496],
          [103.0645368, 5.6277064],
          [103.064580822245958, 5.627706853170179],
          [103.0647323, 5.6275184],
          [103.0652375, 5.6271972],
          [103.0657848, 5.6270156],
          [103.0663602, 5.6270715],
          [103.0669074, 5.6271553],
          [103.0675249, 5.6269458],
          [103.0684792, 5.6263453],
          [103.068906403048018, 5.626066086776214],
          [103.0689124, 5.6260179],
          [103.068937098260449, 5.626046024865942],
          [103.069236590780903, 5.625850281243495],
          [103.0692301, 5.6257061],
          [103.0692697, 5.6257116],
          [103.0692675, 5.6256214],
          [103.0693558, 5.6255595],
          [103.0693709, 5.6251633],
          [103.0692895, 5.6249929],
          [103.069146, 5.6247846],
          [103.0691323, 5.6247236],
          [103.0689998, 5.6246884],
          [103.0689577, 5.6245938],
          [103.0689736, 5.6243599],
          [103.0692361, 5.6241445],
          [103.069286, 5.6237033],
          [103.0693638, 5.6239035],
          [103.069403224438631, 5.623894432379114],
          [103.069749032927433, 5.623593290050007],
          [103.0698135, 5.6234644],
          [103.069821453919019, 5.623362393571028],
          [103.069739232155271, 5.623300211604317],
          [103.0696289, 5.6233412],
          [103.0694498, 5.623416],
          [103.069581040403278, 5.623180575709775],
          [103.0695738, 5.6231751],
          [103.069604807220145, 5.62313794184207],
          [103.0696511, 5.6230549],
          [103.069701669509925, 5.623021864868846],
          [103.069891976789776, 5.622793806115803],
          [103.0699398, 5.6227241],
          [103.069946694536227, 5.622728233947278],
          [103.070126329981719, 5.622512964012606],
          [103.070121, 5.6224422],
          [103.070162056429581, 5.622470150468343],
          [103.070194482127334, 5.622431292447755],
          [103.07017, 5.6223345],
          [103.0700518, 5.6222252],
          [103.0701956, 5.6222282],
          [103.0702337, 5.6216337],
          [103.0703056, 5.6216833],
          [103.07029, 5.6218877],
          [103.07034704014464, 5.62194391942314],
          [103.0703877, 5.6217506],
          [103.070806142864541, 5.621467048648723],
          [103.0709603, 5.6211066],
          [103.0710821, 5.6207648],
          [103.0711913, 5.6205987],
          [103.0712024, 5.6204579],
          [103.0713005, 5.620322],
          [103.0713505, 5.6203139],
          [103.0713707, 5.6201608],
          [103.0712359, 5.6199737],
          [103.071241, 5.6197984],
          [103.0712725, 5.6194945],
          [103.0711868, 5.6191935],
          [103.071284, 5.6191199],
          [103.0712412, 5.6189646],
          [103.0711226, 5.6189976],
          [103.071073, 5.618894],
          [103.0711883, 5.6188518],
          [103.0711734, 5.6187201],
          [103.0711322, 5.6186302],
          [103.0710343, 5.6186814],
          [103.0710337, 5.6185772],
          [103.0710865, 5.6180781],
          [103.0710887, 5.6178045],
          [103.0710813, 5.6177136],
          [103.0709147, 5.6176275],
          [103.0708556, 5.6177336],
          [103.0707922, 5.6176423],
          [103.0707267, 5.6175631],
          [103.0706988, 5.617336],
          [103.0706076, 5.6173138],
          [103.0706102, 5.6170239],
          [103.070497, 5.6169647],
          [103.0701929, 5.6165382],
          [103.0701176, 5.6165682],
          [103.0701331, 5.6164342],
          [103.0700201, 5.6164441],
          [103.0699298, 5.6166702],
          [103.0699475, 5.6164804],
          [103.0699128, 5.6162941],
          [103.069852, 5.6162587],
          [103.0697926, 5.6160247],
          [103.069925295015352, 5.61593837306992],
          [103.0698685, 5.6158291],
          [103.069820484188426, 5.615440954374509],
          [103.0696984, 5.6154169],
          [103.0696162, 5.6152604],
          [103.0696717, 5.6151341],
          [103.0697562, 5.6149213],
          [103.069642987172372, 5.614717268330494],
          [103.0695648, 5.6148036],
          [103.0695072, 5.6146782],
          [103.0694301, 5.614678],
          [103.0693625, 5.6145],
          [103.0690727, 5.6145072],
          [103.0691162, 5.6143089],
          [103.0690703, 5.6141136],
          [103.0690728, 5.6139423],
          [103.0690098, 5.6136871],
          [103.068901, 5.6134297],
          [103.0686921, 5.6132625],
          [103.0685845, 5.6130329],
          [103.0684189, 5.613004],
          [103.0682829, 5.6129944],
          [103.0681376, 5.6130617],
          [103.0681179, 5.6129079],
          [103.0680443, 5.6129818],
          [103.0680172, 5.6127543],
          [103.06791841414794, 5.612690416903027],
          [103.067391724193342, 5.612713207679747],
          [103.0673716, 5.6127451],
          [103.0670337, 5.6127287],
          [103.0668691, 5.612763],
          [103.0666322, 5.6126936],
          [103.0663544, 5.6125484],
          [103.0659848, 5.612432],
          [103.0657865, 5.6122882],
          [103.0656583, 5.6121106],
          [103.0656702, 5.6118897],
          [103.0656877, 5.611715],
          [103.0655322, 5.611844],
          [103.0654642, 5.6113172],
          [103.0653912, 5.6111846],
          [103.065382, 5.6110996],
          [103.0652035, 5.6111148],
          [103.0652012, 5.61106],
          [103.0652773, 5.6110566],
          [103.0652719, 5.6109779],
          [103.064996, 5.6108801],
          [103.0647489, 5.6108418],
          [103.0646108, 5.6109716],
          [103.064566, 5.6108587],
          [103.0643407, 5.6108118],
          [103.0642715, 5.6109134],
          [103.0641878, 5.6109717],
          [103.0640498, 5.6109207],
          [103.0639518, 5.6109337],
          [103.0638062, 5.610945],
          [103.0637238, 5.6108689],
          [103.0636391, 5.6109222],
          [103.0634277, 5.6108335],
          [103.0634427, 5.6107555],
          [103.0633322, 5.6107448],
          [103.0633036, 5.6108107],
          [103.0631928, 5.6107517],
          [103.063035459182885, 5.610723707236152],
          [103.06288273634226, 5.610837691686134],
          [103.0628996, 5.6108981],
          [103.0627798, 5.6109314],
          [103.062779186265956, 5.610919026038454],
          [103.0625151, 5.6115416],
          [103.06208, 5.6118767],
          [103.061917961844202, 5.6118767],
          [103.061876, 5.6119191],
          [103.0616743, 5.6120449],
          [103.0601468, 5.6118911],
          [103.0598914, 5.611925],
          [103.0598464, 5.6118581],
          [103.059603, 5.6118229],
          [103.059531604038909, 5.611770450089075],
          [103.0593015, 5.6118069],
          [103.0591471, 5.6120862],
          [103.0592453, 5.6124773],
          [103.0596944, 5.61298],
          [103.0598207, 5.6137901],
          [103.0596523, 5.6145722],
          [103.0592453, 5.6152286],
          [103.0585296, 5.6156475],
          [103.0580525, 5.6158291],
          [103.058037717038786, 5.615826518202674],
          [103.0580187, 5.6158435],
          [103.057850231447944, 5.615793774438403],
          [103.057656618298864, 5.615759960515502],
        ],
      ],
      [
        [
          [103.161772961787719, 5.624058569610702],
          [103.1618248, 5.6240031],
          [103.1617986, 5.6239729],
          [103.1618529, 5.6238459],
          [103.1619072, 5.6238292],
          [103.1619635, 5.6237634],
          [103.1620044, 5.6236254],
          [103.1619818, 5.6235636],
          [103.1620476, 5.6234919],
          [103.1621487, 5.6232085],
          [103.1622009, 5.6231979],
          [103.162253, 5.6230338],
          [103.1621876, 5.6230238],
          [103.1620959, 5.6230104],
          [103.1617689, 5.6230355],
          [103.1618393, 5.6229882],
          [103.1620061, 5.6229456],
          [103.1620593, 5.6228215],
          [103.1620481, 5.6227227],
          [103.1622738, 5.6225706],
          [103.1623116, 5.6223906],
          [103.162194, 5.6224401],
          [103.1622559, 5.6223402],
          [103.1621821, 5.6223087],
          [103.1617353, 5.6226521],
          [103.1614712, 5.6226577],
          [103.1612955, 5.6227793],
          [103.1613488, 5.6228667],
          [103.1610195, 5.6230341],
          [103.160881875622067, 5.623071964019791],
          [103.1607101, 5.6232115],
          [103.1603599, 5.6236763],
          [103.1598479, 5.624293],
          [103.1596234, 5.6246148],
          [103.1597222, 5.6249366],
          [103.1600725, 5.6250438],
          [103.16053022771743, 5.624868613479403],
          [103.1604637, 5.6248139],
          [103.1606989, 5.6247353],
          [103.1608786, 5.6246048],
          [103.160889931763904, 5.624605942567356],
          [103.161231, 5.624293],
          [103.161772961787719, 5.624058569610702],
        ],
      ],
      [
        [
          [102.967538465735274, 5.640605403926676],
          [102.9674264, 5.6406195],
          [102.967363540208396, 5.640668991172723],
          [102.967331692236598, 5.640753588895629],
          [102.9673487, 5.6407896],
          [102.9673621, 5.640888],
          [102.9673326, 5.6410879],
          [102.9676223, 5.6413615],
          [102.967643114521621, 5.64186912144078],
          [102.9677952, 5.6417499],
          [102.9678482, 5.6416485],
          [102.9678827, 5.6416671],
          [102.9678356, 5.6417299],
          [102.967879977333283, 5.64172875781277],
          [102.9680353, 5.6416271],
          [102.968079264828091, 5.641614944036139],
          [102.9680957, 5.641559],
          [102.9680414, 5.6415503],
          [102.968092, 5.6414766],
          [102.9681522, 5.6415358],
          [102.968305293743342, 5.641493048954681],
          [102.9682392, 5.6413121],
          [102.9682392, 5.641215028771928],
          [102.96821, 5.6412472],
          [102.9682392, 5.641135055072465],
          [102.9682392, 5.640992679545488],
          [102.9682338, 5.6409504],
          [102.9682392, 5.640941009491518],
          [102.9682392, 5.6409024],
          [102.968066795153476, 5.640808709852675],
          [102.9680011, 5.6408258],
          [102.968034919207042, 5.640791387473534],
          [102.9676866, 5.6406021],
          [102.967538465735274, 5.640605403926676],
        ],
      ],
      [
        [
          [103.1535397, 5.6395174],
          [103.1535218, 5.64003],
          [103.1533432, 5.6406801],
          [103.1526215, 5.641595],
          [103.1518223, 5.64243],
          [103.1515189, 5.6431371],
          [103.1517547, 5.6437404],
          [103.1520354, 5.6438633],
          [103.152145390959973, 5.643793625694051],
          [103.152133, 5.6436454],
          [103.152334, 5.6435598],
          [103.152334674377755, 5.643559334802125],
          [103.1524171, 5.6433271],
          [103.1528661, 5.6429696],
          [103.1534948, 5.6425227],
          [103.1541459, 5.6420088],
          [103.15449917257159, 5.641702121650475],
          [103.154491, 5.6416143],
          [103.154644, 5.6414551],
          [103.1546729, 5.6413325],
          [103.1546131, 5.6412614],
          [103.1547153, 5.6410015],
          [103.1548013, 5.6409671],
          [103.1548255, 5.6407807],
          [103.1547852, 5.6407313],
          [103.1548082, 5.6405886],
          [103.1547697, 5.6404927],
          [103.154852, 5.640396],
          [103.1546239, 5.6404096],
          [103.1545373, 5.6402749],
          [103.1546153, 5.6401527],
          [103.1546234, 5.6400553],
          [103.1546763, 5.6399131],
          [103.1546035, 5.6398077],
          [103.1544579, 5.6397293],
          [103.1543884, 5.639543],
          [103.1542433, 5.6395668],
          [103.1541942, 5.6393921],
          [103.1541256, 5.6394545],
          [103.1539883, 5.639344],
          [103.1538062, 5.6393075],
          [103.1535397, 5.6395174],
        ],
      ],
      [
        [
          [103.067953463703773, 5.655828653332184],
          [103.0679508, 5.6558766],
          [103.0679462, 5.6559584],
          [103.0678741, 5.6562019],
          [103.0677777, 5.6563821],
          [103.067683782459483, 5.656513889337195],
          [103.067552, 5.6572131],
          [103.0677698, 5.6587303],
          [103.0681473, 5.6598141],
          [103.0686265, 5.6599297],
          [103.069022214412456, 5.65967428634238],
          [103.0689125, 5.6595302],
          [103.0688485, 5.6595043],
          [103.0690193, 5.6594386],
          [103.0692987, 5.6591908],
          [103.0694869, 5.6588695],
          [103.0695517, 5.6584955],
          [103.0696105, 5.6584807],
          [103.0696311, 5.6583811],
          [103.0695841, 5.6583884],
          [103.0696135, 5.6581024],
          [103.0696987, 5.6577225],
          [103.0697369, 5.6577125],
          [103.0697604, 5.6575802],
          [103.0697634, 5.6574702],
          [103.0697164, 5.6573932],
          [103.0697546, 5.657324],
          [103.069734, 5.6572851],
          [103.0697369, 5.6571646],
          [103.0698045, 5.6571044],
          [103.0698207, 5.6570524],
          [103.0698244, 5.6569737],
          [103.0697852, 5.6569489],
          [103.0697896, 5.6569028],
          [103.0698321, 5.6568067],
          [103.0698723, 5.656801],
          [103.0698877, 5.6567676],
          [103.0698859, 5.6567343],
          [103.0698565, 5.6567012],
          [103.0698514, 5.6566564],
          [103.0698867, 5.6566481],
          [103.069864, 5.6566015],
          [103.0698906, 5.6565358],
          [103.0698779, 5.65647],
          [103.0698371, 5.6564724],
          [103.0698642, 5.6564265],
          [103.0698279, 5.6564058],
          [103.0698406, 5.6563696],
          [103.0698232, 5.6563161],
          [103.0698643, 5.6562894],
          [103.0698777, 5.6562407],
          [103.069875, 5.6561951],
          [103.0698404, 5.656111],
          [103.0698682, 5.6560501],
          [103.0698773, 5.6560102],
          [103.0698961, 5.6559927],
          [103.0699078, 5.6560145],
          [103.0699492, 5.6560019],
          [103.0699392, 5.6559437],
          [103.0698548, 5.6559441],
          [103.0698552, 5.6559254],
          [103.069962, 5.6558901],
          [103.069942, 5.6557853],
          [103.069967, 5.6556851],
          [103.0698912, 5.6556414],
          [103.0698107, 5.6555866],
          [103.0698012, 5.6555568],
          [103.069742, 5.6555584],
          [103.0697574, 5.6555238],
          [103.0698456, 5.6554995],
          [103.0699087, 5.6554991],
          [103.0699734, 5.6554541],
          [103.0699702, 5.6553892],
          [103.0699031, 5.6553596],
          [103.0698928, 5.6553426],
          [103.069909, 5.6552822],
          [103.0698579, 5.6552756],
          [103.0698228, 5.6552299],
          [103.0697769, 5.6552447],
          [103.06978, 5.6552192],
          [103.0698076, 5.6552111],
          [103.0698024, 5.6551929],
          [103.0697785, 5.6551767],
          [103.0697618, 5.6551522],
          [103.0697819, 5.655121],
          [103.0696836, 5.6550474],
          [103.0696451, 5.6550467],
          [103.0696401, 5.6550039],
          [103.0694623, 5.6548434],
          [103.0694326, 5.6547773],
          [103.0693538, 5.6547702],
          [103.0693594, 5.6547313],
          [103.0693291, 5.6547],
          [103.0692286, 5.6547033],
          [103.0692189, 5.654763],
          [103.0691856, 5.6547104],
          [103.0691453, 5.6546817],
          [103.0689309, 5.6546744],
          [103.0689231, 5.6545836],
          [103.069014, 5.6546136],
          [103.0691258, 5.6545979],
          [103.0691381, 5.6545705],
          [103.0692066, 5.6545584],
          [103.0691981, 5.654524],
          [103.0692295, 5.6545021],
          [103.0692357, 5.6543639],
          [103.0692601, 5.6543018],
          [103.0692746, 5.6541906],
          [103.069308, 5.6541539],
          [103.0693104, 5.6540601],
          [103.0693448, 5.6540297],
          [103.0693431, 5.6541842],
          [103.0692806, 5.6542544],
          [103.0692849, 5.6544074],
          [103.0692634, 5.6545121],
          [103.0693592, 5.6544938],
          [103.0694095, 5.6544469],
          [103.0693776, 5.6544346],
          [103.0693541, 5.6543881],
          [103.0695017, 5.6543847],
          [103.069566, 5.6543641],
          [103.0695456, 5.6543254],
          [103.0696097, 5.6543233],
          [103.0696178, 5.6542782],
          [103.0696366, 5.654274],
          [103.0696367, 5.6542223],
          [103.0696239, 5.6542001],
          [103.0695985, 5.6542092],
          [103.0695987, 5.6541826],
          [103.0696044, 5.6541453],
          [103.0695851, 5.6541267],
          [103.0695939, 5.6540898],
          [103.0696352, 5.6540309],
          [103.069684, 5.6540161],
          [103.0696997, 5.6539278],
          [103.0696872, 5.6538844],
          [103.0696077, 5.6538584],
          [103.0694922, 5.653792],
          [103.0693804, 5.6538176],
          [103.0693542, 5.65388],
          [103.0693023, 5.6538156],
          [103.0692689, 5.6538514],
          [103.0691673, 5.6537995],
          [103.0690767, 5.6538174],
          [103.068641, 5.654381],
          [103.0681619, 5.6554069],
          [103.067953463703773, 5.655828653332184],
        ],
      ],
      [
        [
          [102.967596584831568, 5.642390107796429],
          [102.9675696, 5.6424246],
          [102.9675363, 5.6424402],
          [102.9675079, 5.6425214],
          [102.9674732, 5.6425957],
          [102.9674288, 5.6426507],
          [102.9674426, 5.6426961],
          [102.967434131296571, 5.642728281073021],
          [102.9674721, 5.6428176],
          [102.967494321484338, 5.642824605198669],
          [102.9675195, 5.6427606],
          [102.9675895, 5.6427757],
          [102.9675684, 5.6428392],
          [102.967572030984101, 5.642849102683885],
          [102.967667203358545, 5.642879105242691],
          [102.9677, 5.6428691],
          [102.9677268, 5.6427875],
          [102.967747983915572, 5.642770046854979],
          [102.9677671, 5.6427015],
          [102.9676491, 5.6423958],
          [102.967596584831568, 5.642390107796429],
        ],
      ],
      [
        [
          [102.9975386, 5.7309183],
          [102.9974787, 5.7311437],
          [102.9975719, 5.7311768],
          [102.9975719, 5.7312828],
          [102.9977251, 5.7312762],
          [102.9977651, 5.7311503],
          [102.9977651, 5.7309382],
          [102.9976452, 5.7308189],
          [102.9976186, 5.7309117],
          [102.9975386, 5.7309183],
        ],
      ],
      [
        [
          [103.0291565, 5.7370275],
          [103.0290841, 5.7372185],
          [103.0293151, 5.737437],
          [103.0293363, 5.7377996],
          [103.0295503, 5.738167],
          [103.0293898, 5.7387497],
          [103.0296165, 5.7390071],
          [103.0298293, 5.7390314],
          [103.0298693, 5.7392183],
          [103.0299821, 5.7397454],
          [103.030438, 5.7403016],
          [103.0307433, 5.7403657],
          [103.0309342, 5.7400623],
          [103.0309882, 5.73982],
          [103.030775, 5.7393495],
          [103.0309882, 5.7392169],
          [103.0308883, 5.7388789],
          [103.0309016, 5.7386271],
          [103.0308217, 5.7384681],
          [103.0309948, 5.7380042],
          [103.0307166, 5.7377429],
          [103.0308209, 5.7375052],
          [103.0307074, 5.7372447],
          [103.0302062, 5.7368958],
          [103.0298068, 5.7367212],
          [103.0294015, 5.7367227],
          [103.0292032, 5.7369041],
          [103.0291565, 5.7370275],
        ],
      ],
      [
        [
          [103.020032345171003, 5.733413753359624],
          [103.0199902, 5.7333624],
          [103.019965, 5.7333741],
          [103.0199928, 5.7334308],
          [103.0199618, 5.7334449],
          [103.0199288, 5.7333847],
          [103.0198797, 5.7333726],
          [103.019813593793387, 5.733416514323182],
          [103.0197597, 5.7334969],
          [103.019833, 5.7337488],
          [103.0197997, 5.7338482],
          [103.0195599, 5.7338614],
          [103.0196065, 5.7341663],
          [103.0197331, 5.7343055],
          [103.0198863, 5.7341928],
          [103.0199529, 5.7340337],
          [103.0200594, 5.7341133],
          [103.020122557237229, 5.734100926590983],
          [103.0201083, 5.7340695],
          [103.020183125451098, 5.734089060408792],
          [103.02027877961433, 5.734070320385674],
          [103.0202878, 5.7340613],
          [103.020304410736614, 5.734065298881037],
          [103.020376394314468, 5.734051196265245],
          [103.020385, 5.7340419],
          [103.020392779872282, 5.734047986099351],
          [103.02044076647374, 5.734038584835566],
          [103.020459919980354, 5.734022205026585],
          [103.0204124, 5.7339277],
          [103.02036397536861, 5.733855426601201],
          [103.0203216, 5.7338662],
          [103.020312899647024, 5.73377919647019],
          [103.020305624848135, 5.733768338887461],
          [103.0202921, 5.7337514],
          [103.020292962453354, 5.733749440358308],
          [103.02022241322706, 5.733644146167726],
          [103.020177, 5.7336476],
          [103.0200528, 5.7336789],
          [103.020038348139551, 5.733590552777648],
          [103.0199995, 5.7335036],
          [103.020032345171003, 5.733413753359624],
        ],
      ],
      [
        [
          [102.9975386, 5.7351398],
          [102.9970524, 5.735617],
          [102.9971923, 5.7359417],
          [102.9975586, 5.7360941],
          [102.9978317, 5.7367303],
          [102.9986443, 5.7373798],
          [102.9985843, 5.7376117],
          [102.9987642, 5.7380292],
          [102.9990572, 5.738228],
          [102.9990572, 5.7384401],
          [102.9991305, 5.7386853],
          [102.9993103, 5.7388046],
          [102.9996367, 5.7391426],
          [102.99967, 5.739454],
          [103.0002827, 5.7403023],
          [103.000502312547326, 5.740949628202522],
          [103.0006357, 5.7413428],
          [103.0006424, 5.7418066],
          [103.0004026, 5.7420253],
          [103.0003893, 5.7423169],
          [103.0004026, 5.7426218],
          [103.0002628, 5.7432182],
          [103.0000629, 5.7434104],
          [103.0000496, 5.7437815],
          [102.9997432, 5.7443846],
          [102.9996234, 5.74459],
          [102.9994102, 5.7445767],
          [102.999297, 5.7448021],
          [102.9994169, 5.7449744],
          [102.999317, 5.7453455],
          [102.9995101, 5.7456702],
          [102.9998365, 5.7457696],
          [102.9999764, 5.7460413],
          [103.0001895, 5.7465648],
          [103.0004759, 5.7469227],
          [103.0007756, 5.747095],
          [103.0010154, 5.7472408],
          [103.001122, 5.7474396],
          [103.0011153, 5.7476185],
          [103.0010221, 5.7477643],
          [103.001097710796941, 5.747890851313383],
          [103.0011088, 5.7478906],
          [103.0011156, 5.7479171],
          [103.00111476062952, 5.747919387961488],
          [103.0011686, 5.7480095],
          [103.001342193099887, 5.747843426093011],
          [103.0013649, 5.7478141],
          [103.0013949, 5.7477863],
          [103.0014095, 5.7477461],
          [103.001441154042993, 5.74774875164758],
          [103.0015149, 5.7476782],
          [103.001447553528621, 5.747564815294134],
          [103.0013184, 5.7475675],
          [103.0012418, 5.747592],
          [103.0011752, 5.7473137],
          [103.0012485, 5.7470817],
          [103.0015216, 5.7465781],
          [103.0018679, 5.7454979],
          [103.002514, 5.7436158],
          [103.0026738, 5.74347],
          [103.0031068, 5.7434965],
          [103.0033399, 5.743417],
          [103.0035597, 5.743417],
          [103.0037662, 5.7432646],
          [103.0037129, 5.7430592],
          [103.0036862, 5.7427411],
          [103.003886, 5.7424428],
          [103.0038194, 5.7423103],
          [103.0038461, 5.7420121],
          [103.0048118, 5.7412301],
          [103.0051116, 5.7411373],
          [103.0053447, 5.7408722],
          [103.0051848, 5.7407331],
          [103.0052381, 5.7400969],
          [103.0053513, 5.7398914],
          [103.0057043, 5.7393679],
          [103.006044, 5.7391956],
          [103.0063371, 5.7391094],
          [103.0065635, 5.738851],
          [103.0063904, 5.7387847],
          [103.0067101, 5.738175],
          [103.0070764, 5.7379364],
          [103.0071297, 5.7377907],
          [103.0069898, 5.7376979],
          [103.0069205, 5.7373825],
          [103.0065302, 5.7367833],
          [103.0053247, 5.736114],
          [103.0051982, 5.736061],
          [103.0049184, 5.7363194],
          [103.0047319, 5.7363393],
          [103.0045454, 5.7362134],
          [103.0040859, 5.7361339],
          [103.0036329, 5.7361007],
          [103.0032533, 5.735776],
          [103.0031534, 5.7355507],
          [103.002887, 5.7354778],
          [103.0029003, 5.7356766],
          [103.0026472, 5.7355242],
          [103.0025073, 5.7353386],
          [103.0022076, 5.7351995],
          [103.0022875, 5.7350404],
          [103.0023541, 5.7348151],
          [103.0022076, 5.7347157],
          [103.0019812, 5.7350338],
          [103.0015016, 5.7352525],
          [103.0011752, 5.7355507],
          [103.0008955, 5.7357429],
          [102.9999231, 5.7357363],
          [102.9994968, 5.7355772],
          [102.9993436, 5.7352856],
          [102.9991571, 5.7349808],
          [102.9989174, 5.7347024],
          [102.9988574, 5.7341192],
          [102.9987708, 5.734053],
          [102.9989373, 5.7338409],
          [102.9989906, 5.7332047],
          [102.9988441, 5.7328402],
          [102.9986376, 5.7327275],
          [102.9982779, 5.7329462],
          [102.9984045, 5.7326016],
          [102.9983978, 5.7323167],
          [102.998178, 5.7322902],
          [102.9981248, 5.7321245],
          [102.9980115, 5.7319257],
          [102.9979582, 5.7317202],
          [102.9977851, 5.7317534],
          [102.9978583, 5.732204],
          [102.9980049, 5.7323631],
          [102.9980315, 5.7326878],
          [102.9981714, 5.7328071],
          [102.9979982, 5.7328733],
          [102.9980781, 5.733092],
          [102.997825, 5.7333174],
          [102.9976985, 5.7335427],
          [102.997512, 5.7341855],
          [102.9975653, 5.7344639],
          [102.9975386, 5.7351398],
        ],
      ],
      [
        [
          [103.0055378, 5.7431321],
          [103.0054313, 5.7432381],
          [103.0054512, 5.7433441],
          [103.0055578, 5.7433507],
          [103.0056377, 5.7434435],
          [103.0058509, 5.7433971],
          [103.0059508, 5.7433309],
          [103.0059974, 5.7432315],
          [103.0058975, 5.7431519],
          [103.0059108, 5.7430326],
          [103.0056977, 5.7429796],
          [103.0057043, 5.7431652],
          [103.0056044, 5.7431983],
          [103.0055378, 5.7431321],
        ],
      ],
      [
        [
          [102.738058, 5.8937817],
          [102.7380028, 5.8944574],
          [102.7380961, 5.895459],
          [102.7381777, 5.8961191],
          [102.7381954, 5.8976383],
          [102.738484, 5.8987407],
          [102.738671, 5.8988106],
          [102.7389134, 5.8998681],
          [102.7392297, 5.9012874],
          [102.7392696, 5.9014196],
          [102.7393139, 5.9018883],
          [102.7392126, 5.9024099],
          [102.7390392, 5.9026127],
          [102.7387295, 5.9025759],
          [102.7386299, 5.9026319],
          [102.7382288, 5.9029607],
          [102.7380882, 5.9030458],
          [102.7382134, 5.9032466],
          [102.7383311, 5.9033736],
          [102.738311, 5.9034827],
          [102.7382444, 5.9034613],
          [102.7382003, 5.9035051],
          [102.7382435, 5.9036181],
          [102.7382678, 5.9036697],
          [102.7384377, 5.9036479],
          [102.7385882, 5.9035541],
          [102.7387763, 5.9035051],
          [102.7388655, 5.9033588],
          [102.739058, 5.9031639],
          [102.7393605, 5.9031489],
          [102.7394437, 5.9030305],
          [102.7394988, 5.9029073],
          [102.7396747, 5.9028806],
          [102.7397588, 5.9028073],
          [102.7397231, 5.9027769],
          [102.7395302, 5.9027688],
          [102.7394961, 5.9027481],
          [102.7395105, 5.902603],
          [102.7396185, 5.9025276],
          [102.7397764, 5.9024973],
          [102.7399489, 5.9023468],
          [102.7401555, 5.902244],
          [102.7402517, 5.9022863],
          [102.7402918, 5.9023862],
          [102.7403793, 5.9023087],
          [102.7404909, 5.9024086],
          [102.7405303, 5.9023162],
          [102.7406292, 5.9022828],
          [102.7407414, 5.9023162],
          [102.7407696, 5.9023106],
          [102.7408221, 5.9024105],
          [102.7408522, 5.9024394],
          [102.7408822, 5.9023927],
          [102.7409085, 5.902305],
          [102.7409536, 5.9022951],
          [102.7410278, 5.9022789],
          [102.7411408, 5.9023294],
          [102.7416351, 5.9023837],
          [102.7423583, 5.9026335],
          [102.7430432, 5.9029782],
          [102.7432665, 5.9032215],
          [102.7434219, 5.9035601],
          [102.7439273, 5.9045297],
          [102.7438718, 5.9046779],
          [102.7437312, 5.9047046],
          [102.7435321, 5.9049691],
          [102.7433163, 5.9051856],
          [102.7433381, 5.9053152],
          [102.7436506, 5.9055198],
          [102.7428796, 5.9067884],
          [102.742658, 5.9072345],
          [102.742952, 5.9074853],
          [102.7430641, 5.9079068],
          [102.7434327, 5.9079564],
          [102.7435469, 5.9082254],
          [102.7447496, 5.9086015],
          [102.7449336, 5.9087125],
          [102.7451326, 5.908951],
          [102.7462817, 5.9095129],
          [102.74647, 5.9100252],
          [102.7469233, 5.9108127],
          [102.747395, 5.9109438],
          [102.7484312, 5.9103475],
          [102.7487864, 5.9099211],
          [102.7492472, 5.9095796],
          [102.7495483, 5.90969],
          [102.7498104, 5.9098688],
          [102.7497541, 5.9105646],
          [102.7500105, 5.9108127],
          [102.7508115, 5.9104639],
          [102.7513117, 5.9103325],
          [102.7521272, 5.9107769],
          [102.7527085, 5.9110247],
          [102.7533588, 5.911266],
          [102.7537444, 5.9116117],
          [102.7540075, 5.9118799],
          [102.7541277, 5.9129226],
          [102.7548235, 5.9133265],
          [102.7548626, 5.9139156],
          [102.7550906, 5.9142549],
          [102.7551448, 5.9153445],
          [102.7551126, 5.9162303],
          [102.7552773, 5.9178235],
          [102.7558711, 5.918736],
          [102.7567032, 5.919197],
          [102.7574467, 5.9201846],
          [102.757554, 5.9211931],
          [102.7584648, 5.9225105],
          [102.7590683, 5.9229966],
          [102.7595651, 5.9243209],
          [102.7598488, 5.924568],
          [102.7604513, 5.9237377],
          [102.7602989, 5.9229038],
          [102.7604277, 5.9227752],
          [102.7602485, 5.9214946],
          [102.7603842, 5.9209194],
          [102.7601176, 5.9206755],
          [102.7602984, 5.9190209],
          [102.7623921, 5.91845],
          [102.7632933, 5.916854],
          [102.765908, 5.916107],
          [102.7668414, 5.916075],
          [102.7686567, 5.9153803],
          [102.7693621, 5.914479],
          [102.769515, 5.9131317],
          [102.7702226, 5.9121542],
          [102.77106, 5.9113703],
          [102.7709108, 5.9098395],
          [102.7704398, 5.9095241],
          [102.7702199, 5.9096783],
          [102.7698948, 5.9097733],
          [102.769567, 5.9093854],
          [102.7697451, 5.9091965],
          [102.7695086, 5.9087205],
          [102.7699501, 5.9078401],
          [102.7697167, 5.9068791],
          [102.7700166, 5.9058765],
          [102.7693444, 5.9036023],
          [102.7685494, 5.9025228],
          [102.7685365, 5.9017464],
          [102.7699999, 5.9008655],
          [102.7710567, 5.899206],
          [102.7712522, 5.8982511],
          [102.7712139, 5.8973144],
          [102.7716674, 5.8962902],
          [102.7721001, 5.8957641],
          [102.7722575, 5.8957641],
          [102.772393, 5.8956511],
          [102.7724236, 5.8954859],
          [102.7723231, 5.8953163],
          [102.7725285, 5.8948902],
          [102.7729219, 5.8944728],
          [102.773181, 5.8934756],
          [102.7730049, 5.893225],
          [102.7726902, 5.892925],
          [102.772098, 5.8925375],
          [102.7716704, 5.8915994],
          [102.771481, 5.8904422],
          [102.7714344, 5.8901687],
          [102.7715179, 5.889844],
          [102.7712944, 5.8895461],
          [102.7710343, 5.8894167],
          [102.770914, 5.8891921],
          [102.7705901, 5.8890017],
          [102.7704526, 5.8891384],
          [102.7701859, 5.888857],
          [102.7699666, 5.8887819],
          [102.7698856, 5.8885304],
          [102.7696549, 5.8883937],
          [102.7695739, 5.8882106],
          [102.7693824, 5.8881715],
          [102.7692414, 5.8879602],
          [102.7693527, 5.8878185],
          [102.7693184, 5.8875666],
          [102.7694804, 5.8872907],
          [102.7696154, 5.8873004],
          [102.7696841, 5.8872638],
          [102.7695319, 5.8866095],
          [102.7695488, 5.8864632],
          [102.7696915, 5.8862872],
          [102.7695835, 5.8861611],
          [102.7696182, 5.8860627],
          [102.7695713, 5.8860247],
          [102.7697241, 5.8858313],
          [102.7698109, 5.885638],
          [102.7698161, 5.8855188],
          [102.7699362, 5.8854794],
          [102.7699514, 5.8850182],
          [102.7699254, 5.8847419],
          [102.7698403, 5.8845221],
          [102.7695436, 5.8844882],
          [102.7694169, 5.8845693],
          [102.7693665, 5.8845382],
          [102.7693509, 5.8844208],
          [102.7692254, 5.8844709],
          [102.7690864, 5.8846517],
          [102.7689747, 5.8845906],
          [102.7688445, 5.8846281],
          [102.7685489, 5.884948],
          [102.7677995, 5.886163],
          [102.7670703, 5.8865598],
          [102.766262, 5.8870926],
          [102.7658077, 5.8870824],
          [102.7650159, 5.8868162],
          [102.7641855, 5.8862591],
          [102.7637187, 5.8857142],
          [102.7636752, 5.8853079],
          [102.7636897, 5.8852051],
          [102.7635589, 5.8849868],
          [102.7633527, 5.8848316],
          [102.7631337, 5.8847313],
          [102.7630435, 5.8846534],
          [102.7630018, 5.8845241],
          [102.7626564, 5.8844378],
          [102.7625904, 5.8842876],
          [102.7623862, 5.8843834],
          [102.7622676, 5.8843567],
          [102.7620142, 5.884569],
          [102.7618262, 5.8848775],
          [102.7620874, 5.8852804],
          [102.7610693, 5.8863754],
          [102.7610714, 5.8871625],
          [102.7608381, 5.8876176],
          [102.7606401, 5.888354],
          [102.7603928, 5.8887083],
          [102.7595447, 5.8895493],
          [102.7592311, 5.8897306],
          [102.7586199, 5.8902489],
          [102.7583989, 5.8904783],
          [102.758004, 5.8906096],
          [102.7575615, 5.890683],
          [102.7568287, 5.8911619],
          [102.7566082, 5.8917521],
          [102.7562708, 5.892338],
          [102.7552256, 5.894756],
          [102.7546556, 5.8950967],
          [102.7539764, 5.8950663],
          [102.7529207, 5.8948032],
          [102.7511558, 5.8942557],
          [102.749619, 5.8935042],
          [102.7484793, 5.8926875],
          [102.7475161, 5.8918983],
          [102.7463881, 5.890673],
          [102.7457619, 5.8897606],
          [102.7456541, 5.8894244],
          [102.7457812, 5.8893385],
          [102.7459539, 5.8891164],
          [102.7461423, 5.8890181],
          [102.7462091, 5.888898],
          [102.7461023, 5.8886476],
          [102.7458909, 5.8883672],
          [102.7459158, 5.8881658],
          [102.7458482, 5.8881447],
          [102.7458705, 5.887946],
          [102.7457473, 5.8879784],
          [102.7455838, 5.8877724],
          [102.7454952, 5.8874636],
          [102.745571, 5.887332],
          [102.7455562, 5.8872392],
          [102.745677, 5.887135],
          [102.7458872, 5.8870041],
          [102.7458442, 5.8868736],
          [102.7459039, 5.8868538],
          [102.7458933, 5.8867918],
          [102.7458097, 5.8867311],
          [102.7458097, 5.8866373],
          [102.7457659, 5.886603],
          [102.7457503, 5.8862225],
          [102.7458119, 5.8859821],
          [102.7456923, 5.8857229],
          [102.7458396, 5.8854447],
          [102.745727, 5.885004],
          [102.7457542, 5.8848054],
          [102.7459229, 5.8844996],
          [102.745893, 5.8843424],
          [102.74603, 5.8840566],
          [102.7461044, 5.8839643],
          [102.7460361, 5.8837604],
          [102.7461194, 5.8836118],
          [102.7460489, 5.8835226],
          [102.7461102, 5.8833096],
          [102.7459652, 5.8832027],
          [102.7458235, 5.8831579],
          [102.7457757, 5.8831672],
          [102.7456662, 5.8831821],
          [102.7451852, 5.8831513],
          [102.7448086, 5.8831561],
          [102.7447961, 5.8829225],
          [102.7446899, 5.8827985],
          [102.7445044, 5.8826704],
          [102.7444347, 5.8825952],
          [102.7444533, 5.8825034],
          [102.7444228, 5.8824414],
          [102.744377, 5.8823866],
          [102.7442029, 5.8824293],
          [102.744097, 5.8821827],
          [102.7441421, 5.8821596],
          [102.7441687, 5.8821114],
          [102.7441627, 5.8820632],
          [102.7441408, 5.8820296],
          [102.7440864, 5.8820454],
          [102.7440307, 5.8820956],
          [102.7439889, 5.8820771],
          [102.7439803, 5.8819636],
          [102.7439344, 5.8818676],
          [102.7437772, 5.8817174],
          [102.7437236, 5.8816154],
          [102.743616, 5.8815881],
          [102.7434999, 5.8816526],
          [102.74328, 5.8814933],
          [102.7432518, 5.8814118],
          [102.7432617, 5.8813373],
          [102.7432093, 5.8813036],
          [102.7431244, 5.8813175],
          [102.7430987, 5.8812548],
          [102.7429618, 5.8812369],
          [102.7429155, 5.8811755],
          [102.7428616, 5.8812218],
          [102.7427588, 5.8812468],
          [102.7426771, 5.881334],
          [102.7426759, 5.881396],
          [102.7426437, 5.8814424],
          [102.7424735, 5.8814165],
          [102.7424257, 5.8814686],
          [102.7424074, 5.8815213],
          [102.7423384, 5.8815507],
          [102.7422914, 5.8816229],
          [102.7422107, 5.8815646],
          [102.7421497, 5.8816003],
          [102.7421259, 5.8815333],
          [102.7420943, 5.8814887],
          [102.742052, 5.8815996],
          [102.7421345, 5.8816658],
          [102.7420883, 5.8817354],
          [102.7420624, 5.8817442],
          [102.7420793, 5.8817694],
          [102.7421051, 5.8817652],
          [102.7421582, 5.8818067],
          [102.7421298, 5.8818649],
          [102.7421272, 5.8819415],
          [102.7420708, 5.881917],
          [102.7420402, 5.8819438],
          [102.7420608, 5.8820636],
          [102.7420907, 5.8821626],
          [102.7420681, 5.8821645],
          [102.7420363, 5.8821949],
          [102.7420084, 5.882121],
          [102.7419407, 5.8821243],
          [102.7419082, 5.8821678],
          [102.7419274, 5.8822279],
          [102.7420137, 5.882251],
          [102.7420887, 5.8823018],
          [102.7420469, 5.8823539],
          [102.7420807, 5.8823942],
          [102.7421716, 5.8825328],
          [102.7421351, 5.8825387],
          [102.7421417, 5.8825882],
          [102.7420933, 5.8826311],
          [102.7421497, 5.8826965],
          [102.7421305, 5.8827321],
          [102.7421331, 5.882775],
          [102.7422466, 5.8828635],
          [102.7422372, 5.8829436],
          [102.7423076, 5.8830674],
          [102.7423992, 5.8831796],
          [102.7424629, 5.8832132],
          [102.7426259, 5.8834704],
          [102.7426497, 5.8841112],
          [102.7423685, 5.8852796],
          [102.742106, 5.8858914],
          [102.7416222, 5.8862697],
          [102.7410691, 5.8865275],
          [102.7404436, 5.8866748],
          [102.740052, 5.8866545],
          [102.7399415, 5.8865571],
          [102.7399691, 5.8864513],
          [102.7399098, 5.8863594],
          [102.7398146, 5.8864324],
          [102.7396786, 5.8864268],
          [102.7395957, 5.8864985],
          [102.739638, 5.886551],
          [102.7396108, 5.8866397],
          [102.7396954, 5.8866321],
          [102.7397014, 5.8867208],
          [102.7398226, 5.8867141],
          [102.74004, 5.8870615],
          [102.7400935, 5.887972],
          [102.7400069, 5.8887102],
          [102.7397801, 5.8894866],
          [102.7397557, 5.8895463],
          [102.7396978, 5.889615],
          [102.7397047, 5.8896601],
          [102.7397408, 5.8897059],
          [102.7394469, 5.8905103],
          [102.7392956, 5.8908785],
          [102.7391229, 5.8911132],
          [102.7390276, 5.89126],
          [102.7389487, 5.8912674],
          [102.7389351, 5.8913407],
          [102.7390649, 5.8914704],
          [102.73911, 5.8916363],
          [102.7389397, 5.8922321],
          [102.7387549, 5.8926722],
          [102.7384448, 5.8931912],
          [102.738058, 5.8937817],
        ],
      ],
      [
        [
          [102.714633, 5.8977871],
          [102.7146168, 5.8986586],
          [102.7148988, 5.8991029],
          [102.7150114, 5.8991967],
          [102.7151088, 5.8993687],
          [102.7151578, 5.8995858],
          [102.7151568, 5.8998532],
          [102.7150726, 5.9002643],
          [102.7148954, 5.9006929],
          [102.7150606, 5.9012215],
          [102.7149655, 5.9015877],
          [102.7151017, 5.9018645],
          [102.7152716, 5.90201],
          [102.7153631, 5.9022524],
          [102.7154047, 5.9024905],
          [102.7153267, 5.902685],
          [102.7154214, 5.9032547],
          [102.7153919, 5.9040701],
          [102.715274, 5.904553],
          [102.7154501, 5.9054525],
          [102.7153414, 5.9064615],
          [102.7152193, 5.9069476],
          [102.7153328, 5.9075054],
          [102.7153908, 5.9080953],
          [102.7150744, 5.9090692],
          [102.715101, 5.9095794],
          [102.7155649, 5.9103942],
          [102.7157042, 5.9111869],
          [102.7164899, 5.9116435],
          [102.7168128, 5.9122166],
          [102.7170204, 5.9132918],
          [102.7170183, 5.9144033],
          [102.7166626, 5.9149737],
          [102.7161835, 5.9147856],
          [102.7157523, 5.914812],
          [102.7155099, 5.9152051],
          [102.7163252, 5.9162831],
          [102.7164199, 5.9166735],
          [102.7164789, 5.9168691],
          [102.7165966, 5.9170637],
          [102.7167389, 5.9173662],
          [102.7167784, 5.9174995],
          [102.716785, 5.9176493],
          [102.7167629, 5.9177835],
          [102.716543, 5.9182248],
          [102.7164518, 5.918498],
          [102.7165044, 5.9190759],
          [102.7162361, 5.9198442],
          [102.7161176, 5.9199579],
          [102.7160114, 5.9202844],
          [102.715181, 5.9208249],
          [102.7152453, 5.9215261],
          [102.7154395, 5.9221685],
          [102.7160312, 5.9226476],
          [102.7167812, 5.9225415],
          [102.7170045, 5.9228221],
          [102.7171794, 5.923146],
          [102.7172887, 5.923322],
          [102.7176282, 5.9236668],
          [102.7176116, 5.9250653],
          [102.7174485, 5.9250599],
          [102.7171331, 5.9263373],
          [102.7169083, 5.9264574],
          [102.7166776, 5.9269605],
          [102.7167415, 5.927174],
          [102.7169351, 5.9272385],
          [102.7168917, 5.9279743],
          [102.7171136, 5.9289937],
          [102.7173589, 5.9298344],
          [102.7178959, 5.930234],
          [102.7181995, 5.9302153],
          [102.7184586, 5.9307281],
          [102.7183438, 5.9308577],
          [102.7184258, 5.9311308],
          [102.7185385, 5.9310856],
          [102.7186467, 5.931702],
          [102.7188207, 5.931877],
          [102.7189248, 5.9320433],
          [102.7189343, 5.9322889],
          [102.7189135, 5.9324695],
          [102.7184737, 5.9329566],
          [102.7182768, 5.933715],
          [102.7185503, 5.9339572],
          [102.7186142, 5.9340283],
          [102.7186426, 5.9341431],
          [102.7186644, 5.9342974],
          [102.7186579, 5.934467],
          [102.7186319, 5.9346023],
          [102.718236, 5.9348094],
          [102.7175011, 5.9355499],
          [102.7177143, 5.9361482],
          [102.7184136, 5.9366413],
          [102.7183712, 5.9367771],
          [102.718839, 5.9369474],
          [102.7188035, 5.9372659],
          [102.7189436, 5.9372691],
          [102.7189988, 5.9368999],
          [102.7193834, 5.9369324],
          [102.719392, 5.9371026],
          [102.7197922, 5.9372675],
          [102.7199544, 5.9372581],
          [102.7200909, 5.937294],
          [102.720468, 5.9376254],
          [102.7209268, 5.9380806],
          [102.7209617, 5.9381579],
          [102.7210534, 5.9383682],
          [102.7223687, 5.9383544],
          [102.7231251, 5.9382087],
          [102.7235232, 5.9383031],
          [102.7239604, 5.9383037],
          [102.7243761, 5.9381431],
          [102.7245204, 5.9379414],
          [102.7252789, 5.9376303],
          [102.7259007, 5.9371522],
          [102.7261603, 5.9376308],
          [102.7265471, 5.9376533],
          [102.7268325, 5.9375567],
          [102.7269875, 5.9372691],
          [102.7269086, 5.9367537],
          [102.7267043, 5.9367649],
          [102.7267005, 5.9364367],
          [102.7271232, 5.9358696],
          [102.7267402, 5.9356471],
          [102.7266104, 5.9349374],
          [102.7268775, 5.9347811],
          [102.7263867, 5.9344833],
          [102.7262333, 5.9340133],
          [102.7258143, 5.9338095],
          [102.7249458, 5.9339604],
          [102.724603, 5.9341077],
          [102.7242393, 5.934056],
          [102.7241317, 5.9339369],
          [102.7239538, 5.9339052],
          [102.7240667, 5.9338173],
          [102.7241264, 5.9337302],
          [102.7240581, 5.9336537],
          [102.7239574, 5.9337062],
          [102.7238912, 5.9337965],
          [102.7237144, 5.9338588],
          [102.7232519, 5.9339461],
          [102.7230403, 5.9338396],
          [102.7227834, 5.9335645],
          [102.7227752, 5.933319],
          [102.7227127, 5.9332655],
          [102.7226815, 5.933174],
          [102.722586, 5.9331567],
          [102.7224385, 5.9329253],
          [102.7227185, 5.9323928],
          [102.7225769, 5.9317195],
          [102.7222765, 5.9315429],
          [102.7217282, 5.9308007],
          [102.722282, 5.9307543],
          [102.7217829, 5.9300126],
          [102.7214782, 5.9300334],
          [102.7213747, 5.929821],
          [102.7213978, 5.9296503],
          [102.7211092, 5.9293579],
          [102.7214128, 5.9289774],
          [102.7213436, 5.9287475],
          [102.7213736, 5.928367],
          [102.7217888, 5.9284135],
          [102.7219138, 5.9281349],
          [102.7215882, 5.9279962],
          [102.7219884, 5.9278468],
          [102.7218677, 5.9273645],
          [102.7221043, 5.9270662],
          [102.7228344, 5.9268319],
          [102.7234025, 5.9267359],
          [102.7232421, 5.9264579],
          [102.7239577, 5.9261804],
          [102.7246143, 5.9261159],
          [102.7245488, 5.9256789],
          [102.7246003, 5.9253331],
          [102.7248974, 5.9252115],
          [102.7246561, 5.9243668],
          [102.725353, 5.9242916],
          [102.725294, 5.9244527],
          [102.7256303, 5.9244661],
          [102.7259983, 5.9240461],
          [102.7267273, 5.9239496],
          [102.7265546, 5.9237883],
          [102.7265787, 5.9233312],
          [102.7263438, 5.9230868],
          [102.7260697, 5.9230115],
          [102.7263727, 5.9221567],
          [102.7261072, 5.9220522],
          [102.7259135, 5.9218998],
          [102.7257918, 5.9217192],
          [102.725273, 5.921621],
          [102.7248519, 5.9214855],
          [102.7240972, 5.9213665],
          [102.7235934, 5.9213078],
          [102.7234093, 5.9213203],
          [102.7229181, 5.9213537],
          [102.7228017, 5.921314],
          [102.7223929, 5.9204498],
          [102.7221799, 5.9192562],
          [102.7222106, 5.9186191],
          [102.7223737, 5.9180024],
          [102.7226075, 5.9172702],
          [102.7230387, 5.9165873],
          [102.7234128, 5.9160051],
          [102.7241433, 5.9150409],
          [102.7255911, 5.9141562],
          [102.7263872, 5.9136216],
          [102.7270288, 5.9134199],
          [102.7279188, 5.9134412],
          [102.7284836, 5.9130917],
          [102.7289868, 5.9131013],
          [102.7292271, 5.9129071],
          [102.7296863, 5.9121723],
          [102.7297271, 5.911706],
          [102.7300136, 5.911666],
          [102.7301933, 5.9117246],
          [102.7306873, 5.911531],
          [102.731028, 5.911238],
          [102.7311471, 5.9107519],
          [102.731322, 5.9098656],
          [102.7317275, 5.90945],
          [102.732235, 5.9091394],
          [102.7324243, 5.9088],
          [102.732552, 5.9079682],
          [102.7327773, 5.9077867],
          [102.732721, 5.9072884],
          [102.732853, 5.9061417],
          [102.7331646, 5.9035767],
          [102.7327087, 5.9027822],
          [102.7324018, 5.9022694],
          [102.7323059, 5.9005725],
          [102.7318016, 5.8998246],
          [102.7317167, 5.8996988],
          [102.7311412, 5.8991708],
          [102.7310839, 5.8989713],
          [102.731153, 5.8987305],
          [102.7298696, 5.8961107],
          [102.7297208, 5.8959597],
          [102.7296021, 5.8958336],
          [102.7284488, 5.8953352],
          [102.72762, 5.8948518],
          [102.7271989, 5.8945594],
          [102.726844, 5.8937732],
          [102.7259468, 5.8930791],
          [102.7248101, 5.8930039],
          [102.7237801, 5.8931747],
          [102.7230071, 5.8937189],
          [102.7226953, 5.8943743],
          [102.7227104, 5.8947585],
          [102.7225372, 5.894839],
          [102.7219696, 5.8949894],
          [102.7211119, 5.8951186],
          [102.7206323, 5.8951799],
          [102.7199317, 5.8952397],
          [102.7193318, 5.8951952],
          [102.718612, 5.8953187],
          [102.7175891, 5.8954466],
          [102.7169091, 5.8954746],
          [102.7158924, 5.8959024],
          [102.7157133, 5.8961293],
          [102.7152333, 5.8970789],
          [102.714633, 5.8977871],
        ],
      ],
      [
        [
          [102.667816809593333, 5.940694826667428],
          [102.6677531, 5.940705],
          [102.667598, 5.9405839],
          [102.6673951, 5.940603],
          [102.6673928, 5.9406848],
          [102.6673273, 5.9406363],
          [102.6672832, 5.9406571],
          [102.667241, 5.9407406],
          [102.6671727, 5.9407901],
          [102.6671702, 5.9408518],
          [102.6671009, 5.9408846],
          [102.6671124, 5.940774],
          [102.6669691, 5.9407715],
          [102.6669026, 5.940804],
          [102.6669411, 5.9408476],
          [102.6669108, 5.9408922],
          [102.6668181, 5.9408906],
          [102.6667561, 5.9410154],
          [102.6667983, 5.9410249],
          [102.6668076, 5.9411612],
          [102.6667083, 5.9410712],
          [102.6665866, 5.9411065],
          [102.6665561, 5.9411845],
          [102.6665756, 5.9412764],
          [102.666636, 5.9412949],
          [102.666612, 5.9413318],
          [102.6666444, 5.9413736],
          [102.6667575, 5.9414253],
          [102.6667839, 5.9414625],
          [102.66682, 5.941446],
          [102.6669908, 5.9414312],
          [102.6670908, 5.9415885],
          [102.6670173, 5.9417163],
          [102.6669615, 5.9418012],
          [102.6670377, 5.9418695],
          [102.6669799, 5.9419268],
          [102.6670857, 5.9419867],
          [102.6671256, 5.9421547],
          [102.667082, 5.9423411],
          [102.6669466, 5.9423672],
          [102.6668634, 5.9425155],
          [102.6668423, 5.9426413],
          [102.6668909, 5.9427032],
          [102.6670631, 5.9426774],
          [102.6670912, 5.9427113],
          [102.6670185, 5.9427365],
          [102.6669482, 5.9427921],
          [102.6670319, 5.942811],
          [102.667133, 5.9428664],
          [102.6672127, 5.9428551],
          [102.6672915, 5.9429125],
          [102.6672937, 5.9429419],
          [102.6672336, 5.9429207],
          [102.6671971, 5.9430386],
          [102.667194, 5.9430983],
          [102.6670964, 5.9431165],
          [102.6671266, 5.9431421],
          [102.6670852, 5.9432573],
          [102.667029, 5.9433223],
          [102.6669704, 5.9433189],
          [102.6669282, 5.9433549],
          [102.6669232, 5.9434095],
          [102.6670014, 5.9434508],
          [102.6669325, 5.9435279],
          [102.666961, 5.9435863],
          [102.6669536, 5.9436442],
          [102.6670193, 5.9436908],
          [102.6671668, 5.9436826],
          [102.6671507, 5.9437301],
          [102.6671774, 5.9437665],
          [102.6672308, 5.943802],
          [102.6671874, 5.9438249],
          [102.6672295, 5.9438655],
          [102.6672545, 5.9438292],
          [102.6673206, 5.9438259],
          [102.6672813, 5.9438941],
          [102.6674118, 5.9439131],
          [102.6673956, 5.9439778],
          [102.6674813, 5.9439892],
          [102.6674919, 5.9439532],
          [102.667506276267687, 5.943976211069966],
          [102.667612263706957, 5.943920733396972],
          [102.6676129, 5.9439196],
          [102.667613472763975, 5.943920100532642],
          [102.667752057784028, 5.943847560102597],
          [102.6677808, 5.9438019],
          [102.667790083799474, 5.943625879161994],
          [102.667759532651743, 5.943609749893691],
          [102.6676265, 5.9435989],
          [102.6676089, 5.9436252],
          [102.6675469, 5.9436567],
          [102.6675248, 5.9437302],
          [102.6675558, 5.9437653],
          [102.6675534, 5.943814],
          [102.6674574, 5.9437834],
          [102.6674906, 5.9436101],
          [102.6674016, 5.9436317],
          [102.6673714, 5.9435139],
          [102.6674058, 5.9435256],
          [102.6674949, 5.9435],
          [102.667501009507404, 5.943473264377833],
          [102.6673772, 5.9434079],
          [102.667415766742607, 5.943359820678405],
          [102.6673078, 5.9433058],
          [102.66742772330899, 5.943344914996431],
          [102.667663092774447, 5.943051491091321],
          [102.667637, 5.9429669],
          [102.667532, 5.9429474],
          [102.6676171, 5.9429085],
          [102.6675298, 5.9428733],
          [102.6676073, 5.9428242],
          [102.6677032, 5.9428167],
          [102.6676859, 5.9427696],
          [102.667815, 5.942801],
          [102.6678906, 5.9427791],
          [102.667897622057183, 5.942779183077859],
          [102.668052, 5.9426078],
          [102.668199375407255, 5.942753417634031],
          [102.6682104, 5.9427439],
          [102.6682346, 5.9426985],
          [102.6683046, 5.9426825],
          [102.668377, 5.9425922],
          [102.6683904, 5.9425127],
          [102.6683635, 5.9424015],
          [102.6684277, 5.9423873],
          [102.6684651, 5.9425136],
          [102.6684485, 5.9426328],
          [102.6685174, 5.9426951],
          [102.6685745, 5.9426969],
          [102.6685763, 5.9427487],
          [102.6686924, 5.9427723],
          [102.6686945, 5.9427999],
          [102.6686564, 5.9427928],
          [102.6686005, 5.9428737],
          [102.6686711, 5.9429875],
          [102.668719821460542, 5.943011729617622],
          [102.66938943237524, 5.942771026997383],
          [102.669375, 5.9427417],
          [102.6694155, 5.9426701],
          [102.669460705895034, 5.942665263561448],
          [102.669375838759436, 5.942540108555431],
          [102.6692738, 5.9425907],
          [102.6692524, 5.9425371],
          [102.6690434, 5.9424672],
          [102.669033, 5.9423861],
          [102.6691463, 5.9423733],
          [102.6691797, 5.9423264],
          [102.6692145, 5.9423232],
          [102.669213506447505, 5.94230071433844],
          [102.6691069, 5.9421435],
          [102.669239097184331, 5.942026853189092],
          [102.6692078, 5.9419701],
          [102.669215, 5.9419099],
          [102.6689916, 5.9419564],
          [102.6688879, 5.9419053],
          [102.668847, 5.9418405],
          [102.6689152, 5.941807],
          [102.6689284, 5.9417774],
          [102.6690244, 5.9416807],
          [102.6689805, 5.9416048],
          [102.6687747, 5.9414903],
          [102.6686312, 5.9415068],
          [102.668561, 5.9415993],
          [102.668539, 5.9418091],
          [102.66842, 5.9416846],
          [102.6682358, 5.9416489],
          [102.6680066, 5.941663],
          [102.6679883, 5.9415738],
          [102.6675728, 5.9415275],
          [102.66758336472256, 5.941479457502495],
          [102.667373010727488, 5.941434975636779],
          [102.6672984, 5.9414329],
          [102.667298663146923, 5.941419253952512],
          [102.6670752, 5.941372],
          [102.667869774899273, 5.940773612831395],
          [102.66785295308317, 5.940724577237459],
          [102.667816809593333, 5.940694826667428],
        ],
      ],
      [
        [
          [102.682121532011053, 5.961933804739448],
          [102.681999, 5.9622582],
          [102.6819155, 5.9623009],
          [102.6818935, 5.9623508],
          [102.6817989, 5.9623643],
          [102.681623647050401, 5.962512793776447],
          [102.6814796, 5.9627332],
          [102.681437127724621, 5.962738911233999],
          [102.6815224, 5.962861],
          [102.6820104, 5.9628865],
          [102.6822073, 5.9630313],
          [102.6824984, 5.9633208],
          [102.683123908814181, 5.963470564952374],
          [102.6831285, 5.9634603],
          [102.683134502493402, 5.963473101386439],
          [102.683248347928753, 5.963500359285634],
          [102.6832559, 5.9634445],
          [102.68323, 5.9633787],
          [102.6833828, 5.9633387],
          [102.6834341, 5.963138],
          [102.6834595, 5.9629431],
          [102.6834015, 5.9628694],
          [102.6834069, 5.9627898],
          [102.6830884, 5.9627403],
          [102.683214, 5.9626826],
          [102.6834699, 5.9626804],
          [102.6835978, 5.9626633],
          [102.6836725, 5.9624627],
          [102.6836562, 5.9623464],
          [102.6837339, 5.9622562],
          [102.683644, 5.9620991],
          [102.6837171, 5.9620045],
          [102.6837268, 5.9618131],
          [102.6836673, 5.9617121],
          [102.6837072, 5.9616616],
          [102.6836441, 5.9616524],
          [102.6836661, 5.9615731],
          [102.6837094, 5.9615121],
          [102.6836773, 5.9613855],
          [102.6837471, 5.9612422],
          [102.6836399, 5.9611999],
          [102.6836775, 5.9609067],
          [102.6835756, 5.9608215],
          [102.6834612, 5.9605562],
          [102.6830794, 5.9604304],
          [102.6832476, 5.9604333],
          [102.6833371, 5.9603974],
          [102.683457, 5.9604328],
          [102.6834686, 5.9604825],
          [102.6836032, 5.9604433],
          [102.6837324, 5.9603561],
          [102.6837969, 5.9602593],
          [102.6836307, 5.9602479],
          [102.6837089, 5.9601018],
          [102.683587, 5.9601035],
          [102.6836656, 5.9600243],
          [102.6836458, 5.9599633],
          [102.6834214, 5.9597728],
          [102.6832555, 5.9597042],
          [102.6833364, 5.959673],
          [102.6833134, 5.9596143],
          [102.6831792, 5.9596494],
          [102.6831716, 5.9596321],
          [102.68326, 5.9595976],
          [102.6831861, 5.9595173],
          [102.68325, 5.9594972],
          [102.6830747, 5.9594055],
          [102.6830065, 5.9594127],
          [102.68299, 5.9594747],
          [102.68297062108806, 5.959473258242875],
          [102.682953562800932, 5.959526823571045],
          [102.6829617, 5.9595756],
          [102.682940107322182, 5.959569075593749],
          [102.6828409, 5.9598806],
          [102.682697444811467, 5.960090128344705],
          [102.6827134, 5.9601178],
          [102.6827104, 5.9602433],
          [102.682765, 5.9602713],
          [102.6826956, 5.9602758],
          [102.6827008, 5.9603135],
          [102.6827573, 5.9603256],
          [102.6827494, 5.9603615],
          [102.6826816, 5.9603644],
          [102.6825979, 5.9604104],
          [102.682509178340524, 5.960526186024479],
          [102.6823786, 5.9610813],
          [102.6823015, 5.9617369],
          [102.682121532011053, 5.961933804739448],
        ],
      ],
      [
        [
          [102.676009106184779, 5.962212872893482],
          [102.67572, 5.9622613],
          [102.6755459, 5.9621843],
          [102.6754397, 5.9622136],
          [102.675331, 5.9622035],
          [102.6752914, 5.9622416],
          [102.6752604, 5.9623264],
          [102.6752153, 5.9623347],
          [102.6752447, 5.9623598],
          [102.6752182, 5.9623845],
          [102.6751481, 5.9624],
          [102.6751359, 5.9624269],
          [102.6751819, 5.9624393],
          [102.6752065, 5.9625132],
          [102.6753753, 5.9626101],
          [102.675364705647638, 5.962620405086776],
          [102.675824444564142, 5.962783196241162],
          [102.6758571, 5.9627717],
          [102.6758446, 5.9626901],
          [102.6757898, 5.9626542],
          [102.6757995, 5.9625613],
          [102.6757508, 5.9624814],
          [102.6757856, 5.9624745],
          [102.6758211, 5.9625666],
          [102.6759185, 5.9627648],
          [102.675955706010058, 5.962829675235085],
          [102.676090116254969, 5.96287726920232],
          [102.6761098, 5.9628775],
          [102.6760991, 5.962846],
          [102.6760169, 5.9627815],
          [102.6760633, 5.9627938],
          [102.6760737, 5.9627575],
          [102.6761386, 5.9628412],
          [102.6762329, 5.9629191],
          [102.6762332, 5.9628902],
          [102.6763421, 5.9629351],
          [102.6763581, 5.9629067],
          [102.6764301, 5.962903],
          [102.6764384, 5.9629437],
          [102.6764767, 5.9629703],
          [102.6765235, 5.9629667],
          [102.6765427, 5.96302],
          [102.6766045, 5.963014],
          [102.67662574365356, 5.96304292497463],
          [102.676955767170895, 5.962893411557936],
          [102.6769837, 5.9628727],
          [102.67699422924187, 5.9628759867523],
          [102.677105626949938, 5.96282551928235],
          [102.6770815, 5.9627469],
          [102.6770881, 5.9626681],
          [102.6770116, 5.9626635],
          [102.6769738, 5.9626417],
          [102.676962, 5.9625684],
          [102.6770281, 5.9626168],
          [102.6771257, 5.9626099],
          [102.6771504, 5.9625875],
          [102.677123, 5.9625257],
          [102.6771724, 5.9625679],
          [102.6772127, 5.9625726],
          [102.6772133, 5.9626059],
          [102.6772359, 5.9626262],
          [102.67726, 5.9626287],
          [102.6772696, 5.9625911],
          [102.6773401, 5.962584],
          [102.6773701, 5.9625824],
          [102.6773832, 5.9625664],
          [102.677354, 5.962543],
          [102.6774021, 5.9625217],
          [102.6774303, 5.9625299],
          [102.6774566, 5.9624701],
          [102.677407, 5.96238],
          [102.6774315, 5.9623526],
          [102.6775187, 5.9624166],
          [102.6775525, 5.9624853],
          [102.6775306, 5.9625887],
          [102.6775506, 5.9626003],
          [102.6775565, 5.9625746],
          [102.6776195, 5.9625743],
          [102.67762968354171, 5.962588101378894],
          [102.677664230516868, 5.962572450263075],
          [102.6776574, 5.9625612],
          [102.6776636, 5.9625432],
          [102.6776843, 5.9625454],
          [102.677702345186631, 5.962555182843627],
          [102.678225, 5.9623184],
          [102.678067663515293, 5.961990015674758],
          [102.6778963, 5.9619798],
          [102.6777192, 5.9620603],
          [102.6776724, 5.9620291],
          [102.6774259, 5.9622706],
          [102.6773017, 5.9622619],
          [102.677143, 5.9622712],
          [102.6768797, 5.9624152],
          [102.6767937, 5.9623363],
          [102.6766576, 5.9623755],
          [102.6766103, 5.9624358],
          [102.6765245, 5.9624134],
          [102.6764698, 5.9624759],
          [102.6763836, 5.9624396],
          [102.6763841, 5.9624014],
          [102.6761907, 5.9623478],
          [102.676065581017383, 5.962233631993002],
          [102.676009106184779, 5.962212872893482],
        ],
      ],
      [
        [
          [102.665959893686761, 5.962891741970962],
          [102.6660386, 5.9629496],
          [102.6660708, 5.9628943],
          [102.666113, 5.9630411],
          [102.666094, 5.9632611],
          [102.6660339, 5.9632161],
          [102.6660023, 5.9632656],
          [102.6659686, 5.9635485],
          [102.666027, 5.9636292],
          [102.6660624, 5.9635921],
          [102.666111, 5.9636355],
          [102.6659865, 5.9637077],
          [102.6659741, 5.9637784],
          [102.6660379, 5.9637509],
          [102.666062, 5.9637988],
          [102.6659034, 5.9639166],
          [102.6658744, 5.9640714],
          [102.665905, 5.9641309],
          [102.6659567, 5.9641001],
          [102.6659825, 5.9640251],
          [102.6660045, 5.9640809],
          [102.6660559, 5.9641117],
          [102.6661293, 5.9640556],
          [102.6661724, 5.9640612],
          [102.6663105, 5.9639841],
          [102.6663287, 5.9639524],
          [102.6664023, 5.9639318],
          [102.6664164, 5.9638684],
          [102.66645263891661, 5.963872426546289],
          [102.666622907929494, 5.963541534583532],
          [102.6666277, 5.963503],
          [102.6666223, 5.9633599],
          [102.6667112, 5.9633125],
          [102.666793, 5.9631883],
          [102.6668014, 5.9631238],
          [102.6669472939152, 5.962911139694394],
          [102.666989180611921, 5.96282973926191],
          [102.6670235, 5.9626964],
          [102.667046746591936, 5.962717868523993],
          [102.6670739, 5.9626651],
          [102.66706334880152, 5.962553844021524],
          [102.6669387, 5.9627176],
          [102.6669425, 5.9626358],
          [102.66704997815576, 5.962412858684351],
          [102.6670311, 5.9622138],
          [102.666884151175736, 5.962105225096975],
          [102.6668694, 5.962107],
          [102.666848, 5.962184],
          [102.666798, 5.9621835],
          [102.6668087, 5.962065],
          [102.6663761, 5.9619885],
          [102.6663278, 5.9620517],
          [102.6663567, 5.9621192],
          [102.6662078, 5.9621808],
          [102.6661241, 5.9623334],
          [102.6660604, 5.9623445],
          [102.6659993, 5.9624769],
          [102.6659946, 5.9626444],
          [102.6659508, 5.9626136],
          [102.6659096, 5.9626842],
          [102.665909316300372, 5.962705477472583],
          [102.6659694, 5.9627843],
          [102.665959893686761, 5.962891741970962],
        ],
      ],
      [
        [
          [102.669495739820405, 5.958498084532262],
          [102.6694132, 5.9585733],
          [102.669278, 5.9585441],
          [102.6691992, 5.9585628],
          [102.6691272, 5.9585105],
          [102.6690644, 5.9585617],
          [102.6690035, 5.95859],
          [102.6687934, 5.9586708],
          [102.6688439, 5.9587512],
          [102.6687511, 5.9587888],
          [102.6687074, 5.9587409],
          [102.6686614, 5.9587863],
          [102.6687081, 5.9588223],
          [102.6686859, 5.9588483],
          [102.6687442, 5.9589037],
          [102.668721472143972, 5.958939382155651],
          [102.6690089, 5.9586993],
          [102.669136000267684, 5.958743816151473],
          [102.6691464, 5.958716],
          [102.6692542, 5.9587155],
          [102.6692511, 5.9587655],
          [102.669284892145598, 5.958795964691443],
          [102.669347224134114, 5.95881779611865],
          [102.6693686, 5.9588225],
          [102.66937230817787, 5.958826581663414],
          [102.6694386, 5.9588498],
          [102.6696244, 5.958649],
          [102.669495739820405, 5.958498084532262],
        ],
      ],
      [
        [
          [102.663009698837314, 5.957572227019049],
          [102.6629933, 5.9575793],
          [102.6628546, 5.957774],
          [102.6625478, 5.9579779],
          [102.662348, 5.9579962],
          [102.6621554, 5.958154],
          [102.6619623, 5.9582308],
          [102.6618458, 5.9582264],
          [102.6617533, 5.9581918],
          [102.6617246, 5.9580864],
          [102.6616977, 5.9581114],
          [102.6616845, 5.9582533],
          [102.6616511, 5.9583638],
          [102.6617195, 5.9582978],
          [102.6617637, 5.9583423],
          [102.6617857, 5.9586774],
          [102.661715, 5.9590573],
          [102.6616071, 5.9592475],
          [102.6615131, 5.9593376],
          [102.6615217, 5.9594112],
          [102.6615821, 5.9594075],
          [102.6616252, 5.9593708],
          [102.6616375, 5.9594444],
          [102.6614622, 5.9595584],
          [102.6614754, 5.9596027],
          [102.6615129, 5.9595847],
          [102.6615103, 5.9596184],
          [102.6614533, 5.9596713],
          [102.6615129, 5.9597475],
          [102.6615399, 5.9597456],
          [102.6616454, 5.9596536],
          [102.6617125, 5.9597269],
          [102.6616398, 5.9597741],
          [102.6616365, 5.9598596],
          [102.6617334, 5.9597988],
          [102.6617387, 5.9598501],
          [102.6617569, 5.9598829],
          [102.6617303, 5.9599594],
          [102.6618026, 5.95999],
          [102.6617658, 5.9600243],
          [102.6617667, 5.9600695],
          [102.6618016, 5.9600644],
          [102.661842, 5.9600469],
          [102.6617675, 5.9601661],
          [102.6618403, 5.9601584],
          [102.6619267, 5.9601357],
          [102.6620361, 5.9603298],
          [102.6621455, 5.960364],
          [102.6621943, 5.9603038],
          [102.6622319, 5.9603392],
          [102.6621957, 5.9604077],
          [102.6622319, 5.9604463],
          [102.6622415, 5.9604722],
          [102.6623453, 5.9605375],
          [102.6623936, 5.9605127],
          [102.6623535, 5.9604706],
          [102.6623953, 5.9604341],
          [102.6625084, 5.9604457],
          [102.6625904, 5.9605057],
          [102.6625366, 5.9606071],
          [102.6625842, 5.9607403],
          [102.6625499, 5.9608485],
          [102.6625997, 5.9608848],
          [102.6625957, 5.9609833],
          [102.6625766, 5.9610442],
          [102.6626162, 5.9610632],
          [102.6627913, 5.9611121],
          [102.6627943, 5.9611842],
          [102.6628408, 5.9611962],
          [102.6628304, 5.9612533],
          [102.6628317, 5.9613747],
          [102.6629231, 5.9614264],
          [102.6631207, 5.9614196],
          [102.6632793, 5.9613618],
          [102.6634675, 5.9613923],
          [102.6634931, 5.9614375],
          [102.6633738, 5.9614564],
          [102.663372527136232, 5.961515072958768],
          [102.6635893, 5.9615482],
          [102.6646032, 5.9611784],
          [102.664830254638716, 5.961149247413243],
          [102.6648397, 5.9611409],
          [102.665056246781518, 5.96112023124521],
          [102.6656453, 5.9610446],
          [102.6670441, 5.9602741],
          [102.6671311, 5.9598235],
          [102.6670912, 5.9591878],
          [102.6670798, 5.9587908],
          [102.6671711, 5.9584197],
          [102.6668914, 5.9581548],
          [102.6666096, 5.9582289],
          [102.666495901849373, 5.957993417257618],
          [102.6663721, 5.9579429],
          [102.6659727, 5.9572675],
          [102.6659727, 5.9564993],
          [102.6658528, 5.955996],
          [102.6656648, 5.9560342],
          [102.6654427, 5.9563138],
          [102.6650196, 5.956698],
          [102.6642549, 5.9570556],
          [102.663487, 5.9575411],
          [102.6633017, 5.9575295],
          [102.663253070912845, 5.957505158228487],
          [102.663009698837314, 5.957572227019049],
        ],
      ],
      [
        [
          [103.4439969, 4.1887882],
          [103.444368, 4.186782],
          [103.4374951, 4.185166],
          [103.4229699, 4.1828446],
          [103.4072719, 4.1809011],
          [103.3748949, 4.1852229],
          [103.35331, 4.1887753],
          [103.3332241, 4.1941749],
          [103.3239063, 4.1938441],
          [103.3179063, 4.1944073],
          [103.321458, 4.1601663],
          [103.3042918, 4.1200197],
          [103.3048838, 4.0922701],
          [103.3149467, 4.0780998],
          [103.320866, 4.054482],
          [103.3196821, 4.0261397],
          [103.3297451, 3.9954344],
          [103.3338886, 3.9682711],
          [103.3244176, 3.9405164],
          [103.302516, 3.9328394],
          [103.2806144, 3.943469],
          [103.2681838, 3.9729952],
          [103.2711434, 3.9966154],
          [103.2516096, 4.0107872],
          [103.2409547, 4.0367681],
          [103.2125418, 4.0361777],
          [103.2048466, 4.0556629],
          [103.1935999, 4.0556629],
          [103.182945, 4.046806],
          [103.1657789, 4.0438537],
          [103.1497966, 4.0627483],
          [103.1326305, 4.0721954],
          [103.1148724, 4.1005361],
          [103.1018499, 4.1088019],
          [103.0734369, 4.1123444],
          [103.0586386, 4.1282853],
          [103.0302256, 4.1341893],
          [103.0053644, 4.1406836],
          [102.9852385, 4.1424548],
          [102.9562337, 4.1265141],
          [102.9301885, 4.1188389],
          [102.9094708, 4.1265141],
          [102.8976321, 4.1465875],
          [102.8976321, 4.1578048],
          [102.9171659, 4.1719737],
          [102.9230853, 4.193817],
          [102.9088788, 4.2321888],
          [102.9224934, 4.2498983],
          [102.9295966, 4.261114],
          [102.9290047, 4.2829548],
          [102.9301885, 4.298302],
          [102.9473547, 4.3018436],
          [102.9568256, 4.3325369],
          [102.9751756, 4.3478831],
          [103.0059563, 4.3419808],
          [103.0130595, 4.3514245],
          [103.0047724, 4.3655899],
          [103.0018127, 4.3773941],
          [103.0195708, 4.4122157],
          [103.0219385, 4.4210683],
          [103.0118756, 4.4322816],
          [103.0065482, 4.4493961],
          [102.9935256, 4.4588385],
          [102.9751756, 4.4842143],
          [102.9597853, 4.4853945],
          [102.9183498, 4.4712314],
          [102.8775063, 4.4789031],
          [102.8544208, 4.4989672],
          [102.8325192, 4.5514853],
          [102.8396224, 4.5638766],
          [102.8686272, 4.5733175],
          [102.8745466, 4.5827582],
          [102.8627079, 4.593969],
          [102.8597482, 4.6051795],
          [102.8745466, 4.61934],
          [102.8680353, 4.6329101],
          [102.8638917, 4.64943],
          [102.8485014, 4.6718491],
          [102.8348869, 4.6842384],
          [102.8230482, 4.6883681],
          [102.8094337, 4.6824685],
          [102.7916756, 4.6801087],
          [102.7656304, 4.6848283],
          [102.7378095, 4.689548],
          [102.7230111, 4.6942676],
          [102.7088046, 4.710786],
          [102.6951901, 4.7219947],
          [102.6721046, 4.7255343],
          [102.6576767, 4.7617331],
          [102.6578018, 4.7621586],
          [102.6581437, 4.7629995],
          [102.6584966, 4.7638288],
          [102.6587573, 4.7647614],
          [102.6591103, 4.7656483],
          [102.6594983, 4.7664088],
          [102.6598062, 4.7670079],
          [102.6601821, 4.7678719],
          [102.6611331, 4.7685421],
          [102.6619804, 4.7692696],
          [102.6623814, 4.7695929],
          [102.663, 4.7700436],
          [102.6637785, 4.7707823],
          [102.6641667, 4.7714623],
          [102.66446, 4.7729011],
          [102.664526, 4.7737295],
          [102.6643511, 4.7744881],
          [102.6641853, 4.7758449],
          [102.6648251, 4.7768363],
          [102.6654557, 4.7771259],
          [102.6658778, 4.777967],
          [102.6662665, 4.7784859],
          [102.6664926, 4.7795334],
          [102.666325, 4.7814078],
          [102.6659775, 4.7822463],
          [102.6656311, 4.7827629],
          [102.6651126, 4.7832213],
          [102.6644793, 4.7835873],
          [102.6640765, 4.7838045],
          [102.6631542, 4.7847678],
          [102.6626002, 4.7854906],
          [102.6622418, 4.7861452],
          [102.6615269, 4.7868789],
          [102.6608921, 4.7877166],
          [102.6598908, 4.7883],
          [102.6600947, 4.7891404],
          [102.6602756, 4.7898886],
          [102.660661, 4.7913738],
          [102.659852, 4.7927055],
          [102.6593676, 4.793233],
          [102.6589057, 4.7939217],
          [102.6587543, 4.7945654],
          [102.6586148, 4.7950135],
          [102.6588641, 4.7960036],
          [102.6591245, 4.7970166],
          [102.6596152, 4.7979499],
          [102.6597044, 4.798675],
          [102.659495, 4.7993759],
          [102.6589163, 4.8006049],
          [102.6584885, 4.8014431],
          [102.6580038, 4.8020397],
          [102.6577484, 4.8028211],
          [102.6575711, 4.8041894],
          [102.657385, 4.8048444],
          [102.6568307, 4.8056133],
          [102.6562772, 4.8062556],
          [102.6558956, 4.8069791],
          [102.6553648, 4.8076331],
          [102.6546131, 4.8089764],
          [102.6542662, 4.8096999],
          [102.6536214, 4.8100659],
          [102.6529996, 4.810478],
          [102.652344, 4.8107864],
          [102.6519271, 4.8116593],
          [102.6516252, 4.8125554],
          [102.651371, 4.8129687],
          [102.6507951, 4.8134385],
          [102.6504145, 4.8138858],
          [102.6496222, 4.8137797],
          [102.6485548, 4.8135116],
          [102.6477387, 4.8136125],
          [102.6467274, 4.8137472],
          [102.6458879, 4.8139974],
          [102.6451861, 4.8143518],
          [102.6445184, 4.8146947],
          [102.6438494, 4.8154862],
          [102.6437657, 4.8164061],
          [102.6438896, 4.8170851],
          [102.6441401, 4.8176612],
          [102.6444819, 4.818479],
          [102.6446634, 4.8191583],
          [102.6454388, 4.8207252],
          [102.6454594, 4.821381],
          [102.6449413, 4.8217243],
          [102.644239, 4.8221591],
          [102.6438799, 4.8229516],
          [102.6441055, 4.8241717],
          [102.6446413, 4.8253122],
          [102.645098, 4.8260154],
          [102.6455783, 4.8266956],
          [102.6459089, 4.8273985],
          [102.6463187, 4.828366],
          [102.6467977, 4.8294029],
          [102.6473115, 4.8301982],
          [102.647596, 4.8309583],
          [102.6477666, 4.8315225],
          [102.6480273, 4.8324436],
          [102.6487962, 4.8327108],
          [102.6492764, 4.8333449],
          [102.6493656, 4.8340929],
          [102.6496959, 4.8348532],
          [102.6503707, 4.8356721],
          [102.6510597, 4.8356973],
          [102.6515082, 4.8356299],
          [102.6517585, 4.8362978],
          [102.6517562, 4.8369534],
          [102.6517992, 4.8377588],
          [102.6516469, 4.838552],
          [102.6514833, 4.8394027],
          [102.6519862, 4.840106],
          [102.6525585, 4.84066],
          [102.6525791, 4.8413043],
          [102.6521285, 4.8419814],
          [102.6514492, 4.8425083],
          [102.6509298, 4.8431393],
          [102.6511553, 4.8443248],
          [102.6511859, 4.8454637],
          [102.6508383, 4.8462448],
          [102.6504342, 4.8468417],
          [102.649858, 4.8473458],
          [102.6489611, 4.8475844],
          [102.6483492, 4.8483877],
          [102.6485755, 4.8494006],
          [102.6478859, 4.8494674],
          [102.647105, 4.8493728],
          [102.6463135, 4.8491056],
          [102.6456741, 4.8479533],
          [102.6447118, 4.847237],
          [102.6440561, 4.8475224],
          [102.6433651, 4.8479342],
          [102.6427662, 4.8483694],
          [102.6417434, 4.8484924],
          [102.6412371, 4.8487669],
          [102.6407289, 4.8494899],
          [102.6401415, 4.849925],
          [102.6394164, 4.8503023],
          [102.6387367, 4.8508062],
          [102.638081, 4.8510685],
          [102.6370699, 4.8511457],
          [102.6367364, 4.8512021],
          [102.6362288, 4.8518217],
          [102.6359863, 4.8521774],
          [102.6353749, 4.8528425],
          [102.6352341, 4.8536588],
          [102.6349679, 4.8542561],
          [102.6343002, 4.8546795],
          [102.6335756, 4.8548842],
          [102.6327133, 4.8550194],
          [102.631909, 4.8550858],
          [102.6312316, 4.855003],
          [102.6304982, 4.8544829],
          [102.6299241, 4.854412],
          [102.6287615, 4.8550753],
          [102.6283135, 4.8550279],
          [102.6275666, 4.8551288],
          [102.6271066, 4.8552078],
          [102.6267252, 4.8558738],
          [102.6257109, 4.8568251],
          [102.6249615, 4.8574898],
          [102.6244886, 4.8580519],
          [102.6238779, 4.858579],
          [102.6232438, 4.8592096],
          [102.6223213, 4.8601612],
          [102.6214698, 4.860561],
          [102.620746, 4.8605356],
          [102.619896, 4.8605443],
          [102.6190922, 4.8604497],
          [102.6178985, 4.860112],
          [102.6170859, 4.8592696],
          [102.6164645, 4.8595667],
          [102.6158195, 4.8600247],
          [102.6154156, 4.8605754],
          [102.6145958, 4.8616654],
          [102.6139267, 4.862457],
          [102.6131669, 4.8628455],
          [102.6123848, 4.863188],
          [102.6119576, 4.8637272],
          [102.6110575, 4.864817],
          [102.610607, 4.8655057],
          [102.6103287, 4.8662064],
          [102.6097864, 4.8669063],
          [102.6094049, 4.8675491],
          [102.609286, 4.8686531],
          [102.6087532, 4.869813],
          [102.6083835, 4.8703754],
          [102.6077725, 4.8709601],
          [102.6072427, 4.8713609],
          [102.6062743, 4.8722664],
          [102.605284, 4.8729418],
          [102.6048352, 4.8730784],
          [102.6040077, 4.8732251],
          [102.6029734, 4.8733711],
          [102.601538, 4.8731593],
          [102.6007229, 4.872984],
          [102.5999074, 4.8729583],
          [102.5991609, 4.8728868],
          [102.5984376, 4.8727234],
          [102.5975788, 4.8720188],
          [102.5964892, 4.8715204],
          [102.5958345, 4.8714952],
          [102.5950064, 4.871734],
          [102.5941094, 4.8719611],
          [102.592915, 4.8718996],
          [102.5919618, 4.8718043],
          [102.5913396, 4.8722738],
          [102.5908663, 4.8728819],
          [102.5904275, 4.8735361],
          [102.5900798, 4.8743171],
          [102.5892619, 4.8750161],
          [102.588731, 4.8756355],
          [102.5883502, 4.8760598],
          [102.5879693, 4.8766222],
          [102.5873455, 4.8775287],
          [102.5867108, 4.8782743],
          [102.5866855, 4.8789528],
          [102.5869013, 4.8795748],
          [102.5867034, 4.8802989],
          [102.5860012, 4.8806875],
          [102.5854017, 4.8812837],
          [102.5848484, 4.881811],
          [102.5837764, 4.8826812],
          [102.5818272, 4.8848278],
          [102.5810783, 4.8858605],
          [102.580583, 4.8865491],
          [102.5799381, 4.8873179],
          [102.5793051, 4.887983],
          [102.5780983, 4.8884055],
          [102.5773638, 4.8881623],
          [102.5765719, 4.8879075],
          [102.5757441, 4.8882504],
          [102.5749269, 4.8891796],
          [102.5745351, 4.889719],
          [102.5742466, 4.8903965],
          [102.5738545, 4.8910738],
          [102.5733011, 4.8919577],
          [102.5728749, 4.8925774],
          [102.5729301, 4.8935777],
          [102.5720222, 4.893794],
          [102.5712528, 4.8937806],
          [102.5706782, 4.8939287],
          [102.5693573, 4.8939714],
          [102.5684617, 4.8939233],
          [102.5677733, 4.8935883],
          [102.5670962, 4.8933911],
          [102.5663836, 4.8936308],
          [102.5652007, 4.893674],
          [102.5642238, 4.8940395],
          [102.563832, 4.8946134],
          [102.5637038, 4.8953947],
          [102.5632886, 4.8962444],
          [102.5618759, 4.896195],
          [102.5610728, 4.8958252],
          [102.5604646, 4.8957317],
          [102.5597403, 4.8959944],
          [102.5593597, 4.8966947],
          [102.5587945, 4.897751],
          [102.5578967, 4.898634],
          [102.5570003, 4.8989307],
          [102.5562074, 4.8990552],
          [102.5554488, 4.8994212],
          [102.5545749, 4.8999594],
          [102.553449, 4.9000141],
          [102.5527946, 4.8999205],
          [102.5519903, 4.9001945],
          [102.5502199, 4.9008914],
          [102.5490824, 4.901107],
          [102.5483593, 4.9008984],
          [102.547659, 4.9007702],
          [102.5469357, 4.900688],
          [102.5458326, 4.9009841],
          [102.5450278, 4.90135],
          [102.544487, 4.9017397],
          [102.5439002, 4.9022899],
          [102.5435078, 4.9030708],
          [102.5430123, 4.9038627],
          [102.5422184, 4.904516],
          [102.5414018, 4.9049393],
          [102.5406089, 4.9051214],
          [102.5394487, 4.9052679],
          [102.5384039, 4.9051849],
          [102.537623, 4.90516],
          [102.536785, 4.9049625],
          [102.5363271, 4.9043521],
          [102.5355936, 4.9037066],
          [102.5346092, 4.9022556],
          [102.5343012, 4.9013811],
          [102.5338436, 4.9006557],
          [102.5335698, 4.8998963],
          [102.5331927, 4.8990447],
          [102.5326667, 4.8979973],
          [102.5323697, 4.8973757],
          [102.5320385, 4.8965242],
          [102.5315803, 4.8959828],
          [102.530709, 4.8954403],
          [102.5298259, 4.8949553],
          [102.5293787, 4.8945978],
          [102.5285527, 4.8942509],
          [102.5277606, 4.8941339],
          [102.5266791, 4.894913],
          [102.525529, 4.8955769],
          [102.5247706, 4.8958509],
          [102.5237705, 4.8962048],
          [102.5227927, 4.8968232],
          [102.5216312, 4.8974985],
          [102.5209758, 4.8978533],
          [102.5202165, 4.8983917],
          [102.5188026, 4.8989744],
          [102.5174928, 4.8993276],
          [102.5168946, 4.8996135],
          [102.5159748, 4.900071],
          [102.5148377, 4.9001141],
          [102.5140456, 4.8999972],
          [102.513081, 4.9000753],
          [102.5123804, 4.900039],
          [102.5104953, 4.9006551],
          [102.5097475, 4.9012051],
          [102.5088741, 4.9014903],
          [102.50714, 4.9014859],
          [102.5051866, 4.9019524],
          [102.5055868, 4.9026201],
          [102.5059415, 4.9032878],
          [102.5062841, 4.9040359],
          [102.5064658, 4.9048755],
          [102.5062575, 4.9055418],
          [102.5066119, 4.9062439],
          [102.5072419, 4.9069698],
          [102.5075615, 4.9077293],
          [102.5080191, 4.9084662],
          [102.5090509, 4.9092736],
          [102.5096123, 4.9098383],
          [102.5101742, 4.9102535],
          [102.5107471, 4.9108757],
          [102.5112274, 4.9116472],
          [102.5104436, 4.9128178],
          [102.509972, 4.913242],
          [102.5098208, 4.9140233],
          [102.5099337, 4.9147823],
          [102.5100928, 4.915484],
          [102.5103661, 4.9165653],
          [102.511479, 4.9169934],
          [102.5119713, 4.917627],
          [102.5122683, 4.9183634],
          [102.5127262, 4.9189394],
          [102.5135757, 4.9191944],
          [102.5132738, 4.9204928],
          [102.5129625, 4.9210668],
          [102.512651, 4.9217097],
          [102.5127514, 4.9228711],
          [102.5127126, 4.9247104],
          [102.5127782, 4.926228],
          [102.513027, 4.9277921],
          [102.5127251, 4.9292053],
          [102.5125512, 4.9298487],
          [102.5124118, 4.9305956],
          [102.5125463, 4.931918],
          [102.5124171, 4.9331938],
          [102.5122193, 4.9343429],
          [102.5123092, 4.9351017],
          [102.5125948, 4.9357233],
          [102.5130516, 4.9368166],
          [102.5135208, 4.9375765],
          [102.5139557, 4.9382098],
          [102.5145745, 4.9388092],
          [102.5152621, 4.9394432],
          [102.5164553, 4.9399635],
          [102.5179366, 4.9400591],
          [102.5187294, 4.9400841],
          [102.5196936, 4.9402819],
          [102.5199098, 4.9411102],
          [102.519942, 4.9421449],
          [102.5203764, 4.9429506],
          [102.5205699, 4.9437214],
          [102.5205217, 4.9445949],
          [102.5200373, 4.945525],
          [102.5194045, 4.9460061],
          [102.5186799, 4.9464183],
          [102.5179443, 4.9465888],
          [102.5170361, 4.946966],
          [102.5153588, 4.9472032],
          [102.5146564, 4.9480407],
          [102.5141033, 4.948729],
          [102.5135378, 4.9498887],
          [102.5135916, 4.9514753],
          [102.5140148, 4.9521432],
          [102.5146339, 4.9526275],
          [102.5152068, 4.9532267],
          [102.5166183, 4.9537131],
          [102.5173738, 4.9548761],
          [102.5178432, 4.955521],
          [102.5182664, 4.9562119],
          [102.5189184, 4.9574206],
          [102.519182, 4.9576397],
          [102.5197207, 4.9581008],
          [102.5204204, 4.9585394],
          [102.5209591, 4.9590006],
          [102.52144, 4.9595765],
          [102.5218628, 4.9606122],
          [102.5214239, 4.9615308],
          [102.5205158, 4.9619195],
          [102.5197453, 4.9623774],
          [102.5192843, 4.96302],
          [102.5184903, 4.9635813],
          [102.5174564, 4.9637397],
          [102.51664, 4.9640366],
          [102.5159271, 4.9644256],
          [102.5153277, 4.9652979],
          [102.5146712, 4.9661814],
          [102.5135312, 4.9673281],
          [102.5125653, 4.9677741],
          [102.5110812, 4.968874],
          [102.5104014, 4.969723],
          [102.5097224, 4.9703766],
          [102.5092844, 4.9710538],
          [102.5086614, 4.9720983],
          [102.5083264, 4.9729827],
          [102.5081408, 4.9737295],
          [102.5078976, 4.974637],
          [102.5078269, 4.9753381],
          [102.5076752, 4.9763839],
          [102.5074893, 4.9772686],
          [102.5068567, 4.9776119],
          [102.5057195, 4.9776436],
          [102.5048116, 4.9778828],
          [102.5039951, 4.9782945],
          [102.5037057, 4.979271],
          [102.5035084, 4.9801441],
          [102.5033804, 4.980891],
          [102.5030568, 4.981695],
          [102.5027906, 4.9825221],
          [102.5020182, 4.9836927],
          [102.5016243, 4.9851632],
          [102.5017372, 4.9860372],
          [102.5017461, 4.9871063],
          [102.5010192, 4.9885415],
          [102.5004891, 4.9892644],
          [102.5000166, 4.9899071],
          [102.4995438, 4.9906416],
          [102.4986685, 4.9917085],
          [102.4982301, 4.9924202],
          [102.4985618, 4.9930073],
          [102.4991006, 4.9934915],
          [102.4994894, 4.9942282],
          [102.4997737, 4.995436],
          [102.5001949, 4.9970235],
          [102.500549, 4.9977946],
          [102.5007426, 4.9986343],
          [102.5009575, 5.0000029],
          [102.5005294, 5.0013584],
          [102.5001369, 5.0020931],
          [102.4998483, 5.0027707],
          [102.4990887, 5.0034125],
          [102.4987529, 5.0045843],
          [102.498797, 5.0053316],
          [102.4991057, 5.0058842],
          [102.4993227, 5.0063905],
          [102.5003085, 5.0073472],
          [102.50141, 5.0078098],
          [102.5021793, 5.0078692],
          [102.5029718, 5.0079862],
          [102.503867, 5.0082298],
          [102.5051535, 5.008325],
          [102.5061415, 5.0081665],
          [102.5068996, 5.0081569],
          [102.5077041, 5.0080326],
          [102.5097256, 5.0080376],
          [102.510632, 5.0084191],
          [102.5113205, 5.0086968],
          [102.5119166, 5.0092041],
          [102.5124896, 5.0097804],
          [102.5131536, 5.0107362],
          [102.5137584, 5.0124736],
          [102.5138143, 5.0131404],
          [102.5137658, 5.014198],
          [102.5136376, 5.0150368],
          [102.5142781, 5.0161651],
          [102.5147129, 5.0168559],
          [102.5151823, 5.0175353],
          [102.5157777, 5.0183645],
          [102.5165899, 5.019769],
          [102.5169777, 5.0209312],
          [102.5172863, 5.0216446],
          [102.5175951, 5.0221742],
          [102.5185806, 5.0231998],
          [102.5192215, 5.024282],
          [102.5197139, 5.0249385],
          [102.520057, 5.0254452],
          [102.5196192, 5.0260074],
          [102.5188596, 5.0266953],
          [102.5178463, 5.0277619],
          [102.517294, 5.0281169],
          [102.5165803, 5.0288049],
          [102.515327, 5.0293765],
          [102.51428, 5.0300407],
          [102.5143815, 5.0308916],
          [102.5148397, 5.0314102],
          [102.5157001, 5.0319411],
          [102.5162843, 5.0326438],
          [102.5162366, 5.0333449],
          [102.515936, 5.0341604],
          [102.5156124, 5.0349643],
          [102.5146684, 5.0358702],
          [102.513552, 5.0367986],
          [102.5129303, 5.0373604],
          [102.512032, 5.0383582],
          [102.5107664, 5.0392173],
          [102.5103516, 5.0398601],
          [102.5100853, 5.0406641],
          [102.5100244, 5.0421699],
          [102.510514, 5.044045],
          [102.5105696, 5.0448154],
          [102.5103952, 5.0457001],
          [102.5098074, 5.0465724],
          [102.5093005, 5.0471919],
          [102.509643, 5.0481124],
          [102.5101815, 5.0486081],
          [102.5107772, 5.0494028],
          [102.5119227, 5.0507507],
          [102.5124153, 5.0513152],
          [102.512896, 5.0520291],
          [102.5136274, 5.0536634],
          [102.5131755, 5.0552716],
          [102.5126573, 5.0557992],
          [102.511978, 5.0565217],
          [102.511172, 5.0573935],
          [102.5108948, 5.0579906],
          [102.5104796, 5.0586678],
          [102.5097896, 5.0590569],
          [102.5090075, 5.0593999],
          [102.5080653, 5.0595814],
          [102.5070302, 5.0601077],
          [102.5071434, 5.0608092],
          [102.5071069, 5.0616253],
          [102.5069662, 5.062878],
          [102.5065842, 5.0640957],
          [102.5064212, 5.0650379],
          [102.5063041, 5.0658883],
          [102.5061644, 5.0667847],
          [102.506183, 5.0686126],
          [102.5059739, 5.0695548],
          [102.5057199, 5.0701059],
          [102.5049029, 5.0707362],
          [102.5037069, 5.071331],
          [102.5026708, 5.0722251],
          [102.5019919, 5.0726716],
          [102.5009002, 5.0728874],
          [102.499097, 5.0726875],
          [102.4983619, 5.0727201],
          [102.4981762, 5.0734784],
          [102.4986798, 5.0742384],
          [102.4998702, 5.0761382],
          [102.500397, 5.0767488],
          [102.5007744, 5.077428],
          [102.5014161, 5.0782114],
          [102.5024004, 5.0797658],
          [102.5025478, 5.0805134],
          [102.502914, 5.0811236],
          [102.5038428, 5.0819306],
          [102.5054124, 5.0837164],
          [102.5058474, 5.0843153],
          [102.5063636, 5.0846615],
          [102.5080403, 5.084838],
          [102.5088206, 5.0851619],
          [102.5095324, 5.0853591],
          [102.5104627, 5.0855568],
          [102.5115896, 5.0849964],
          [102.5120389, 5.0844227],
          [102.5125451, 5.0841251],
          [102.51343, 5.0839893],
          [102.5140617, 5.0840714],
          [102.5150127, 5.0851658],
          [102.515792, 5.0859839],
          [102.5163995, 5.0865717],
          [102.5170181, 5.0872286],
          [102.5182229, 5.0879097],
          [102.5183004, 5.0891286],
          [102.5181375, 5.0900019],
          [102.518124, 5.0909214],
          [102.518832, 5.0927741],
          [102.5187955, 5.0935327],
          [102.5187249, 5.0943143],
          [102.5182396, 5.0954511],
          [102.5182929, 5.0972562],
          [102.5187627, 5.0978552],
          [102.5190486, 5.0983617],
          [102.5203471, 5.0981925],
          [102.5211729, 5.0987463],
          [102.5218041, 5.0990697],
          [102.522538, 5.0995658],
          [102.5231111, 5.100142],
          [102.5241985, 5.1018232],
          [102.5246339, 5.102422],
          [102.5250111, 5.1032047],
          [102.5254, 5.1038838],
          [102.5271561, 5.1045665],
          [102.5280173, 5.104718],
          [102.5277399, 5.1054761],
          [102.5275653, 5.1064988],
          [102.5282967, 5.1081215],
          [102.5277895, 5.108879],
          [102.5272598, 5.1093835],
          [102.5263285, 5.109818],
          [102.5255005, 5.1101839],
          [102.5246153, 5.1104461],
          [102.5241195, 5.1111462],
          [102.5237046, 5.1117775],
          [102.5231403, 5.1123968],
          [102.5213686, 5.1133696],
          [102.5199655, 5.1140444],
          [102.5191828, 5.1146633],
          [102.5183976, 5.1164087],
          [102.5180281, 5.1172814],
          [102.5177277, 5.1179705],
          [102.517071, 5.1187851],
          [102.5156794, 5.1194714],
          [102.5150578, 5.1199412],
          [102.5145277, 5.1206986],
          [102.5142502, 5.1213991],
          [102.5148324, 5.1229871],
          [102.5153578, 5.1242529],
          [102.5155399, 5.1250006],
          [102.5152169, 5.125701],
          [102.5134232, 5.1262599],
          [102.5122855, 5.1264065],
          [102.5116992, 5.126566],
          [102.5103664, 5.1266317],
          [102.508964, 5.1269616],
          [102.5081479, 5.127201],
          [102.5075034, 5.1276478],
          [102.5063757, 5.1284842],
          [102.5054195, 5.1294819],
          [102.5044882, 5.129836],
          [102.503833, 5.1300068],
          [102.5024647, 5.1304402],
          [102.5010164, 5.1307929],
          [102.5002459, 5.1311014],
          [102.4995905, 5.1313987],
          [102.498589, 5.1322699],
          [102.4973918, 5.1331865],
          [102.4966561, 5.1333916],
          [102.4960236, 5.1336889],
          [102.4941738, 5.1336843],
          [102.4931052, 5.1337965],
          [102.4921981, 5.1336564],
          [102.4916691, 5.1337585],
          [102.4906347, 5.1340203],
          [102.487956, 5.1347493],
          [102.4872427, 5.1352189],
          [102.4863442, 5.1362398],
          [102.4863513, 5.1380561],
          [102.4862347, 5.1388146],
          [102.4859317, 5.1406187],
          [102.4855366, 5.1424686],
          [102.4853279, 5.1432037],
          [102.484635, 5.1447769],
          [102.4841274, 5.1456954],
          [102.4832304, 5.1460264],
          [102.4824487, 5.1462429],
          [102.4813084, 5.1473897],
          [102.4804318, 5.1489164],
          [102.4796383, 5.1491788],
          [102.4781078, 5.1502785],
          [102.4767147, 5.1514936],
          [102.4761161, 5.1519289],
          [102.4754604, 5.1523066],
          [102.4749536, 5.1528801],
          [102.4748245, 5.1540062],
          [102.4748964, 5.1545946],
          [102.4751616, 5.1553489],
          [102.475065, 5.1564202],
          [102.4745528, 5.157338],
          [102.4741484, 5.1578513],
          [102.4735466, 5.1582417],
          [102.4720033, 5.1585915],
          [102.4703599, 5.1591387],
          [102.4696026, 5.1591409],
          [102.4675487, 5.1591548],
          [102.4660437, 5.1597339],
          [102.4652819, 5.1601617],
          [102.4645552, 5.1612904],
          [102.4645201, 5.1626021],
          [102.4648057, 5.163195],
          [102.4652866, 5.1638189],
          [102.4654444, 5.1643221],
          [102.4647776, 5.1649206],
          [102.4633694, 5.1651299],
          [102.4629478, 5.1652986],
          [102.4622025, 5.1653465],
          [102.4610366, 5.1656205],
          [102.4603733, 5.166426],
          [102.4598496, 5.1673555],
          [102.4591452, 5.1677822],
          [102.458137, 5.1678923],
          [102.4576803, 5.1680155],
          [102.4568787, 5.168122],
          [102.4559578, 5.1679661],
          [102.4553159, 5.1673335],
          [102.4541436, 5.1665611],
          [102.452782, 5.166114],
          [102.4521072, 5.1662527],
          [102.4513053, 5.1663476],
          [102.4502754, 5.1665271],
          [102.4491608, 5.1671221],
          [102.4482518, 5.1669775],
          [102.4475149, 5.1668413],
          [102.4464589, 5.1668372],
          [102.4459049, 5.1673188],
          [102.4447804, 5.1673389],
          [102.4438436, 5.1675973],
          [102.443174, 5.1680348],
          [102.4427956, 5.1687202],
          [102.4427828, 5.1699971],
          [102.4426205, 5.170575],
          [102.4423547, 5.1711433],
          [102.441699, 5.1717301],
          [102.4411205, 5.1721316],
          [102.4408042, 5.1731033],
          [102.4405329, 5.1740283],
          [102.4402566, 5.1746542],
          [102.4396669, 5.1750674],
          [102.4388936, 5.1754953],
          [102.4378331, 5.1765724],
          [102.4377097, 5.1774142],
          [102.4375564, 5.1778425],
          [102.436975, 5.178083],
          [102.4356898, 5.1780945],
          [102.4341643, 5.1781449],
          [102.4336055, 5.178362],
          [102.4328833, 5.1783979],
          [102.4317868, 5.1780381],
          [102.4308271, 5.1782968],
          [102.4301982, 5.1777561],
          [102.429561, 5.178078],
          [102.4288496, 5.1787693],
          [102.4281126, 5.1793116],
          [102.4265211, 5.1795357],
          [102.4258575, 5.1796511],
          [102.4249991, 5.1797816],
          [102.4235533, 5.179796],
          [102.4220565, 5.1788452],
          [102.4213607, 5.1790877],
          [102.4190661, 5.1791404],
          [102.4179559, 5.1793098],
          [102.4171052, 5.179233],
          [102.4156415, 5.1788797],
          [102.414297, 5.1794444],
          [102.4138823, 5.1800384],
          [102.4133283, 5.1805313],
          [102.4126363, 5.1810152],
          [102.4113038, 5.1816142],
          [102.4108215, 5.1822784],
          [102.4111836, 5.1826514],
          [102.411349, 5.182936],
          [102.4118637, 5.1835133],
          [102.4127268, 5.1843259],
          [102.4131631, 5.1850081],
          [102.4137262, 5.1857341],
          [102.4136647, 5.1861722],
          [102.4136299, 5.1868284],
          [102.4134207, 5.1880282],
          [102.4133712, 5.1891677],
          [102.4136335, 5.1897495],
          [102.4144533, 5.1900454],
          [102.4152587, 5.1901575],
          [102.4164256, 5.1906195],
          [102.4173343, 5.1914083],
          [102.4179647, 5.1920411],
          [102.418344, 5.1927588],
          [102.4188389, 5.1941876],
          [102.419288, 5.1949501],
          [102.4193472, 5.195731],
          [102.4191642, 5.1964589],
          [102.4190024, 5.1977499],
          [102.4189291, 5.1981537],
          [102.419484, 5.1990638],
          [102.4198626, 5.1997471],
          [102.4201395, 5.2005012],
          [102.4209708, 5.2014754],
          [102.4214466, 5.2024674],
          [102.4217341, 5.2031753],
          [102.4221506, 5.2040533],
          [102.4221754, 5.2055136],
          [102.4220905, 5.2065962],
          [102.4221918, 5.2071579],
          [102.422297, 5.2079496],
          [102.4223651, 5.2085808],
          [102.4218809, 5.2091301],
          [102.4217211, 5.2098575],
          [102.4219394, 5.2105552],
          [102.4223311, 5.2113187],
          [102.4222874, 5.2121246],
          [102.42225, 5.2132983],
          [102.4219624, 5.2146146],
          [102.4217905, 5.2153191],
          [102.4215897, 5.2163348],
          [102.4213815, 5.2169136],
          [102.4209921, 5.2183123],
          [102.4209886, 5.2194739],
          [102.4206446, 5.2201701],
          [102.4198794, 5.2217364],
          [102.4192679, 5.222909],
          [102.4185749, 5.223324],
          [102.416747, 5.2245068],
          [102.416198, 5.2259773],
          [102.4158887, 5.2266958],
          [102.4153541, 5.22766],
          [102.4148468, 5.2288882],
          [102.4148968, 5.2297958],
          [102.4148986, 5.2305779],
          [102.4146967, 5.2322147],
          [102.4148088, 5.2340873],
          [102.4148463, 5.2349491],
          [102.4155599, 5.2364315],
          [102.4161142, 5.2372955],
          [102.4163111, 5.2380856],
          [102.4165294, 5.2387717],
          [102.416711, 5.2400106],
          [102.4168107, 5.2411473],
          [102.4167192, 5.2418391],
          [102.4164918, 5.2426482],
          [102.4162131, 5.2438262],
          [102.4160775, 5.2453122],
          [102.4161725, 5.2461617],
          [102.4163114, 5.2469067],
          [102.4160409, 5.2485562],
          [102.4158511, 5.2502272],
          [102.4153205, 5.2521114],
          [102.4160198, 5.2534214],
          [102.4159206, 5.2543317],
          [102.4154035, 5.2549736],
          [102.4151092, 5.2558989],
          [102.4148588, 5.257387],
          [102.4150449, 5.2582118],
          [102.4153917, 5.2590336],
          [102.4157321, 5.260143],
          [102.4163587, 5.261236],
          [102.4164062, 5.2620057],
          [102.416145, 5.2628384],
          [102.41601, 5.2643589],
          [102.4165564, 5.2654417],
          [102.4168101, 5.2661962],
          [102.4171202, 5.2668807],
          [102.4178702, 5.2677988],
          [102.4187602, 5.2688294],
          [102.4188781, 5.2696898],
          [102.4188662, 5.2703456],
          [102.4190211, 5.2713664],
          [102.4192228, 5.2724324],
          [102.4187105, 5.2733386],
          [102.4180751, 5.2737871],
          [102.417543, 5.2742221],
          [102.4174798, 5.2752354],
          [102.4171517, 5.2761957],
          [102.4168653, 5.2769139],
          [102.4165095, 5.2775874],
          [102.4159442, 5.2787821],
          [102.415538, 5.2798589],
          [102.4155529, 5.2807443],
          [102.4155137, 5.2811475],
          [102.4155898, 5.2815717],
          [102.4155875, 5.2827908],
          [102.4156091, 5.284067],
          [102.4161137, 5.2847135],
          [102.4164898, 5.2852358],
          [102.4169077, 5.2855043],
          [102.4174464, 5.2861502],
          [102.4178229, 5.2866725],
          [102.4179771, 5.2876588],
          [102.4179548, 5.2883722],
          [102.4175999, 5.2891031],
          [102.417142, 5.2898589],
          [102.4162117, 5.2911751],
          [102.4157427, 5.2919427],
          [102.4152972, 5.2927672],
          [102.415016, 5.2931058],
          [102.415338, 5.293813],
          [102.4158993, 5.294424],
          [102.4164046, 5.295105],
          [102.4166341, 5.2957909],
          [102.4172184, 5.29639],
          [102.4179854, 5.2969513],
          [102.418606, 5.2976647],
          [102.4186069, 5.2984122],
          [102.4184571, 5.2990599],
          [102.4181707, 5.2997542],
          [102.4183466, 5.3006481],
          [102.4186341, 5.3013675],
          [102.4192067, 5.3019553],
          [102.41957, 5.3030873],
          [102.4196089, 5.3040182],
          [102.4191768, 5.3049345],
          [102.4185535, 5.3060958],
          [102.4194751, 5.3069648],
          [102.4199676, 5.307577],
          [102.4204665, 5.3085686],
          [102.4203882, 5.3100421],
          [102.4203442, 5.3108249],
          [102.4205045, 5.3114776],
          [102.4213095, 5.3122337],
          [102.4222814, 5.3133664],
          [102.4225632, 5.3144194],
          [102.4228054, 5.3151626],
          [102.4226017, 5.3160173],
          [102.4218658, 5.3166171],
          [102.4215661, 5.3172205],
          [102.4216295, 5.3175759],
          [102.4219678, 5.3179033],
          [102.4223509, 5.318161],
          [102.4229991, 5.3184599],
          [102.4238289, 5.3186635],
          [102.4248995, 5.3188283],
          [102.4257753, 5.3190426],
          [102.426455, 5.3191683],
          [102.4270664, 5.3193529],
          [102.4276106, 5.3196422],
          [102.4278318, 5.3204893],
          [102.4280658, 5.3214281],
          [102.4286148, 5.3219818],
          [102.4291086, 5.3226745],
          [102.429464, 5.3233236],
          [102.4294321, 5.3241408],
          [102.429163, 5.3251922],
          [102.4291868, 5.3259278],
          [102.4292238, 5.3267552],
          [102.4292135, 5.3281815],
          [102.4292775, 5.3299285],
          [102.4290712, 5.3306222],
          [102.4286133, 5.331378],
          [102.4280607, 5.3326415],
          [102.4282596, 5.3335235],
          [102.4285201, 5.3339903],
          [102.4282538, 5.3345472],
          [102.4278055, 5.3351762],
          [102.4275303, 5.3365613],
          [102.4273023, 5.338003],
          [102.4273481, 5.3386692],
          [102.427674, 5.3389394],
          [102.4282494, 5.3396881],
          [102.4286093, 5.3406017],
          [102.4286198, 5.3412226],
          [102.4285171, 5.3419489],
          [102.4287128, 5.3426585],
          [102.4293042, 5.3430044],
          [102.4294569, 5.3438757],
          [102.4293469, 5.3448437],
          [102.4292958, 5.3458603],
          [102.4291121, 5.3465731],
          [102.4284199, 5.3477241],
          [102.4276639, 5.3484852],
          [102.4270999, 5.3490819],
          [102.426524, 5.3496443],
          [102.4266668, 5.3506193],
          [102.427218, 5.3512994],
          [102.4275878, 5.3521209],
          [102.4277628, 5.3529687],
          [102.4279895, 5.3534707],
          [102.4284661, 5.3538302],
          [102.4289947, 5.3545337],
          [102.429609, 5.3555463],
          [102.4304552, 5.3560372],
          [102.4312121, 5.3559775],
          [102.4323548, 5.3556695],
          [102.4334423, 5.355489],
          [102.4347601, 5.3560288],
          [102.4356305, 5.3559097],
          [102.4365538, 5.3555135],
          [102.4374663, 5.3551751],
          [102.4384687, 5.3553756],
          [102.4385921, 5.3558909],
          [102.4387767, 5.3566237],
          [102.4393075, 5.3574537],
          [102.4401444, 5.3587382],
          [102.4404537, 5.3593767],
          [102.4406165, 5.3601673],
          [102.4411467, 5.3616529],
          [102.4414949, 5.3625552],
          [102.441381, 5.3632818],
          [102.4411167, 5.3639306],
          [102.4417818, 5.3645627],
          [102.4408995, 5.3660162],
          [102.4402283, 5.3670633],
          [102.439559, 5.3675239],
          [102.4392743, 5.3683455],
          [102.4389857, 5.3696043],
          [102.4385444, 5.3706473],
          [102.4382734, 5.3709166],
          [102.4378257, 5.3709247],
          [102.4367764, 5.3706561],
          [102.4355971, 5.3701598],
          [102.4346906, 5.370199],
          [102.4339793, 5.3702233],
          [102.4332, 5.3702948],
          [102.4315411, 5.3699566],
          [102.4303098, 5.3691161],
          [102.4300187, 5.3688798],
          [102.4293189, 5.3689039],
          [102.4287841, 5.3705351],
          [102.4281967, 5.3717762],
          [102.4274519, 5.3725372],
          [102.4260572, 5.3735628],
          [102.4257874, 5.3742998],
          [102.4254427, 5.3752415],
          [102.4250304, 5.3759733],
          [102.4242776, 5.3769299],
          [102.4235754, 5.378179],
          [102.4233614, 5.3797756],
          [102.4228983, 5.3808994],
          [102.4225262, 5.3819872],
          [102.4219602, 5.3824574],
          [102.4213021, 5.3829063],
          [102.4197512, 5.3835206],
          [102.4195332, 5.383738],
          [102.4188705, 5.384399],
          [102.419025, 5.3845151],
          [102.4193937, 5.3847921],
          [102.4196222, 5.3854091],
          [102.4199665, 5.38607],
          [102.4204639, 5.386958],
          [102.4203621, 5.3877305],
          [102.4202811, 5.3883759],
          [102.4206701, 5.388967],
          [102.420993, 5.3897317],
          [102.4211967, 5.3902456],
          [102.4211272, 5.3908794],
          [102.4211134, 5.3914202],
          [102.42074, 5.3917489],
          [102.4201894, 5.3917703],
          [102.4195536, 5.3915172],
          [102.4189131, 5.3909767],
          [102.4183982, 5.3903994],
          [102.4177216, 5.3897674],
          [102.416811, 5.3895538],
          [102.4163985, 5.3895366],
          [102.4158458, 5.3895136],
          [102.4151444, 5.3894456],
          [102.4130594, 5.3897016],
          [102.4122048, 5.3907634],
          [102.4119439, 5.3916306],
          [102.411536, 5.392627],
          [102.4108431, 5.3937435],
          [102.4104964, 5.3942904],
          [102.4102134, 5.3946166],
          [102.4099897, 5.3948744],
          [102.4090564, 5.3960297],
          [102.4080975, 5.396332],
          [102.4078571, 5.3964077],
          [102.4072883, 5.3960269],
          [102.4066181, 5.3957744],
          [102.4058427, 5.3954088],
          [102.4050287, 5.3954693],
          [102.4039263, 5.3961332],
          [102.403119, 5.3965962],
          [102.4024006, 5.3968966],
          [102.4009318, 5.3976129],
          [102.399849, 5.3980809],
          [102.3993062, 5.3985621],
          [102.3991203, 5.3991174],
          [102.3992143, 5.3999208],
          [102.3991985, 5.4007745],
          [102.3991903, 5.4012209],
          [102.3985573, 5.4017957],
          [102.3979756, 5.4020246],
          [102.3972088, 5.4021649],
          [102.396492, 5.4025572],
          [102.3963573, 5.4026497],
          [102.3959247, 5.4029469],
          [102.3956931, 5.4035261],
          [102.3949326, 5.4040342],
          [102.3942037, 5.4043808],
          [102.3937952, 5.4046641],
          [102.3934243, 5.4051308],
          [102.3931618, 5.405906],
          [102.3930056, 5.4064246],
          [102.39289, 5.4068081],
          [102.3924764, 5.407471],
          [102.3921639, 5.4080056],
          [102.3916631, 5.4096132],
          [102.3910821, 5.409888],
          [102.3905933, 5.4094943],
          [102.3902531, 5.4090634],
          [102.3898499, 5.4090005],
          [102.3897235, 5.4089808],
          [102.3889528, 5.4089026],
          [102.3885721, 5.4087944],
          [102.3880702, 5.4089874],
          [102.3870773, 5.4093272],
          [102.3864019, 5.4094542],
          [102.385511, 5.4097232],
          [102.3851072, 5.4102825],
          [102.3851078, 5.411007],
          [102.384911, 5.4115741],
          [102.3848378, 5.4126795],
          [102.3849088, 5.41317],
          [102.3849806, 5.413666],
          [102.3846709, 5.4143616],
          [102.3840726, 5.4149588],
          [102.3837629, 5.4156544],
          [102.3838594, 5.4166073],
          [102.3839065, 5.4173655],
          [102.3839364, 5.4177789],
          [102.3841777, 5.4184647],
          [102.3843753, 5.4192892],
          [102.3846424, 5.420147],
          [102.3849631, 5.4207853],
          [102.3846298, 5.4214468],
          [102.38461, 5.4223097],
          [102.3846599, 5.4232289],
          [102.3845014, 5.4240368],
          [102.3851058, 5.4252667],
          [102.3870687, 5.4277577],
          [102.3883865, 5.4273064],
          [102.3897272, 5.4267631],
          [102.3910909, 5.4260588],
          [102.391939, 5.4259075],
          [102.3927301, 5.4258024],
          [102.3934409, 5.4257318],
          [102.3937851, 5.4261796],
          [102.3938661, 5.4270532],
          [102.3941537, 5.4279036],
          [102.3943951, 5.4286964],
          [102.3949923, 5.429569],
          [102.3955662, 5.4303038],
          [102.3958419, 5.4308551],
          [102.3959569, 5.4313607],
          [102.3962215, 5.4321996],
          [102.3965777, 5.4328773],
          [102.3969933, 5.4335647],
          [102.3971981, 5.4336207],
          [102.3973465, 5.4338184],
          [102.3976681, 5.4342202],
          [102.3981042, 5.4347137],
          [102.3986428, 5.4346666],
          [102.3993769, 5.4349754],
          [102.3998589, 5.4354574],
          [102.4001117, 5.4359743],
          [102.4004789, 5.4364334],
          [102.4008805, 5.436835],
          [102.4012362, 5.4371562],
          [102.4017182, 5.4376956],
          [102.4020969, 5.4381317],
          [102.4022811, 5.4387868],
          [102.4022242, 5.4393503],
          [102.4021562, 5.4398909],
          [102.4020992, 5.440236],
          [102.4022601, 5.4407531],
          [102.4025473, 5.4412929],
          [102.4029149, 5.442028],
          [102.4030873, 5.442545],
          [102.4027437, 5.4429023],
          [102.4020563, 5.4433868],
          [102.4014605, 5.4437674],
          [102.4011401, 5.4442396],
          [102.4009914, 5.4447229],
          [102.4007745, 5.4455742],
          [102.4007291, 5.4461377],
          [102.4004429, 5.4464833],
          [102.400237, 5.4468403],
          [102.3999963, 5.4470362],
          [102.3989558, 5.4497867],
          [102.3987505, 5.4510634],
          [102.3982819, 5.4523867],
          [102.3985351, 5.4533981],
          [102.3985471, 5.4539845],
          [102.3982154, 5.4547556],
          [102.3973448, 5.4554589],
          [102.3981711, 5.4565035],
          [102.3988832, 5.4575943],
          [102.3994573, 5.458697],
          [102.3994353, 5.4595365],
          [102.3994132, 5.4601804],
          [102.399712, 5.4609847],
          [102.399747, 5.4617895],
          [102.3986015, 5.4627924],
          [102.3981444, 5.4641387],
          [102.3975495, 5.4656002],
          [102.3968747, 5.4673495],
          [102.3964399, 5.4679714],
          [102.3954783, 5.4694913],
          [102.3944129, 5.4704939],
          [102.3937944, 5.4709897],
          [102.3922134, 5.4723499],
          [102.3918242, 5.47278],
          [102.3914001, 5.4732486],
          [102.3906783, 5.4736986],
          [102.3893375, 5.4742533],
          [102.3885232, 5.4739792],
          [102.3885586, 5.474876],
          [102.3887806, 5.475522],
          [102.3890757, 5.4763812],
          [102.389663, 5.4787716],
          [102.3896071, 5.4802895],
          [102.3887139, 5.4816368],
          [102.3893467, 5.4836132],
          [102.3895651, 5.4845096],
          [102.390209, 5.4863596],
          [102.3902218, 5.4876129],
          [102.3902339, 5.4883487],
          [102.3902922, 5.489211],
          [102.390568, 5.4898314],
          [102.3909252, 5.4918314],
          [102.391075, 5.4925899],
          [102.3916036, 5.4938766],
          [102.3923733, 5.4952319],
          [102.3925806, 5.4960823],
          [102.3922165, 5.4991878],
          [102.3917814, 5.4999245],
          [102.3904883, 5.5022386],
          [102.3899389, 5.5035276],
          [102.3890824, 5.506818],
          [102.3887392, 5.5075662],
          [102.386723, 5.5095252],
          [102.38669, 5.5108937],
          [102.3863693, 5.5114233],
          [102.38424, 5.511916],
          [102.3835607, 5.5120731],
          [102.3829307, 5.5126494],
          [102.3825033, 5.5134205],
          [102.3814313, 5.5153548],
          [102.3805969, 5.5179552],
          [102.3810345, 5.5199551],
          [102.3818389, 5.5220346],
          [102.3825158, 5.5225047],
          [102.3831926, 5.5226872],
          [102.385841, 5.5224631],
          [102.3872283, 5.5222531],
          [102.3891313, 5.5219616],
          [102.3903702, 5.5224764],
          [102.3906804, 5.5232461],
          [102.3915761, 5.5246125],
          [102.3930799, 5.5264146],
          [102.3945836, 5.5279176],
          [102.3957651, 5.528467],
          [102.3966024, 5.5287528],
          [102.397624, 5.5297394],
          [102.3988875, 5.5320594],
          [102.3997492, 5.5338513],
          [102.3998882, 5.5353803],
          [102.4001084, 5.5374841],
          [102.4005681, 5.5387249],
          [102.4016918, 5.53878],
          [102.4015653, 5.5391977],
          [102.4014863, 5.5394589],
          [102.4001815, 5.5420489],
          [102.3996438, 5.5432114],
          [102.4016062, 5.5447365],
          [102.4028219, 5.5452053],
          [102.4041872, 5.5457542],
          [102.4056656, 5.5452796],
          [102.4062616, 5.5448414],
          [102.4071323, 5.5440691],
          [102.4078783, 5.5448723],
          [102.4074097, 5.5463107],
          [102.4058873, 5.5488552],
          [102.4055438, 5.5495919],
          [102.4058324, 5.551362],
          [102.4058915, 5.5533396],
          [102.4052615, 5.5538814],
          [102.4038976, 5.5546203],
          [102.4030278, 5.5563125],
          [102.4032816, 5.5578643],
          [102.4040059, 5.5595644],
          [102.4039614, 5.5608984],
          [102.4026911, 5.5635573],
          [102.4023602, 5.5650299],
          [102.4016409, 5.5683085],
          [102.4014355, 5.5691713],
          [102.4000386, 5.5714281],
          [102.3997878, 5.5729119],
          [102.4005117, 5.5742787],
          [102.400329, 5.575245],
          [102.397591, 5.5778496],
          [102.3972707, 5.5788277],
          [102.3965952, 5.5797375],
          [102.3961615, 5.5818887],
          [102.3963983, 5.5843952],
          [102.3965092, 5.5855675],
          [102.3969239, 5.5874178],
          [102.3968225, 5.5893613],
          [102.3969614, 5.5906374],
          [102.3964816, 5.5923748],
          [102.3965747, 5.5938003],
          [102.3969324, 5.5960073],
          [102.3965323, 5.5975144],
          [102.3964763, 5.5988024],
          [102.3967995, 5.600768],
          [102.3969039, 5.6019406],
          [102.3968129, 5.6027342],
          [102.3977439, 5.6050434],
          [102.3980086, 5.6059397],
          [102.3968629, 5.6068391],
          [102.3979871, 5.6073081],
          [102.3988471, 5.6073293],
          [102.3989985, 5.6096171],
          [102.3995731, 5.6108578],
          [102.4000788, 5.612064],
          [102.400526, 5.6121205],
          [102.4015012, 5.6122334],
          [102.4016621, 5.6126585],
          [102.4017431, 5.6134057],
          [102.4012542, 5.6138896],
          [102.3996236, 5.615503],
          [102.3980671, 5.618703],
          [102.3978728, 5.6194739],
          [102.3976673, 5.6202332],
          [102.3961429, 5.6210643],
          [102.3944255, 5.6211598],
          [102.3942048, 5.6211721],
          [102.3940064, 5.6214666],
          [102.393724, 5.621886],
          [102.3940929, 5.624024],
          [102.394392, 5.6247937],
          [102.3941993, 5.6274043],
          [102.3929386, 5.6280509],
          [102.3918623, 5.629755],
          [102.3902578, 5.6307704],
          [102.3878729, 5.6314654],
          [102.3875765, 5.6330183],
          [102.387441, 5.6353184],
          [102.3880276, 5.6370994],
          [102.3893258, 5.6392813],
          [102.3896026, 5.6409135],
          [102.3894897, 5.6428915],
          [102.3888036, 5.6448247],
          [102.3869253, 5.6476344],
          [102.3867787, 5.6500034],
          [102.386608, 5.6515676],
          [102.3851757, 5.6527895],
          [102.3860371, 5.653926],
          [102.3866803, 5.655155],
          [102.3859146, 5.6577323],
          [102.385526, 5.6592164],
          [102.3859746, 5.6604573],
          [102.3872488, 5.6616965],
          [102.3876165, 5.6623971],
          [102.3870675, 5.6641115],
          [102.3861054, 5.66516],
          [102.3854063, 5.6657939],
          [102.3852359, 5.6674616],
          [102.3854898, 5.6690823],
          [102.3853184, 5.6699105],
          [102.3862249, 5.6703916],
          [102.38665, 5.671023],
          [102.3864791, 5.6721158],
          [102.3865271, 5.6743004],
          [102.3858865, 5.676245],
          [102.3851759, 5.6767295],
          [102.3825619, 5.677747],
          [102.3821965, 5.6792885],
          [102.3816933, 5.680911],
          [102.3792516, 5.6820315],
          [102.3788127, 5.6829724],
          [102.3784734, 5.6837004],
          [102.3792205, 5.6854582],
          [102.380071, 5.6872271],
          [102.3803478, 5.6888823],
          [102.3796841, 5.6903671],
          [102.3799379, 5.6919878],
          [102.3811773, 5.6928935],
          [102.3819459, 5.6928919],
          [102.3832767, 5.693326],
          [102.384402, 5.694485],
          [102.3852276, 5.6944372],
          [102.3862951, 5.6953778],
          [102.3865003, 5.6964022],
          [102.3871403, 5.6972789],
          [102.3898712, 5.6982098],
          [102.3905124, 5.6987874],
          [102.3910275, 5.6992725],
          [102.392346, 5.7000138],
          [102.3931772, 5.6988897],
          [102.3936738, 5.6981555],
          [102.3941358, 5.6974786],
          [102.3956095, 5.6966215],
          [102.3968261, 5.6969713],
          [102.3975134, 5.6974226],
          [102.3978899, 5.6981257],
          [102.3985731, 5.6997848],
          [102.3988213, 5.7010511],
          [102.3988871, 5.7019486],
          [102.3989308, 5.7025354],
          [102.3991005, 5.7032033],
          [102.400511, 5.7039333],
          [102.4010801, 5.7053504],
          [102.4012152, 5.7061331],
          [102.401967, 5.7078961],
          [102.4026155, 5.7095895],
          [102.4027735, 5.7104068],
          [102.4034223, 5.7120773],
          [102.4052803, 5.7128666],
          [102.4061185, 5.7130079],
          [102.4070931, 5.7135753],
          [102.4068028, 5.7143909],
          [102.407911, 5.7160515],
          [102.4093523, 5.7177941],
          [102.4108171, 5.7193526],
          [102.4114351, 5.7200106],
          [102.4120856, 5.7211749],
          [102.4144417, 5.7208619],
          [102.4154065, 5.7209806],
          [102.4167694, 5.7221016],
          [102.4168689, 5.7232523],
          [102.417061, 5.7240698],
          [102.417518, 5.7248307],
          [102.4184912, 5.7257087],
          [102.4195179, 5.7276911],
          [102.4313926, 5.7457198],
          [102.4338438, 5.7474994],
          [102.4396714, 5.7515842],
          [102.4410634, 5.7525599],
          [102.4569201, 5.7637073],
          [102.4596844, 5.7661049],
          [102.4598453, 5.7662561],
          [102.4604953, 5.7667828],
          [102.4616232, 5.7677269],
          [102.4621273, 5.7681386],
          [102.4622791, 5.7682724],
          [102.4625757, 5.7685198],
          [102.4627254, 5.7686003],
          [102.4634043, 5.7691696],
          [102.4649823, 5.7705078],
          [102.4663121, 5.7716324],
          [102.46681, 5.7720494],
          [102.4670118, 5.7722286],
          [102.4681715, 5.7732013],
          [102.4687038, 5.773639],
          [102.469752, 5.7746891],
          [102.4704211, 5.775339],
          [102.470763, 5.7756288],
          [102.4710332, 5.7758647],
          [102.4718864, 5.7766136],
          [102.4724342, 5.7770818],
          [102.4727355, 5.7773291],
          [102.4730507, 5.7776075],
          [102.473383, 5.7779077],
          [102.4738331, 5.7782946],
          [102.4743228, 5.7787292],
          [102.4748636, 5.7791886],
          [102.4754549, 5.779703],
          [102.4765252, 5.7806126],
          [102.4768711, 5.7809067],
          [102.477218, 5.7812001],
          [102.4774271, 5.7813715],
          [102.4789797, 5.782711],
          [102.4801779, 5.7837365],
          [102.4804953, 5.784007],
          [102.4808174, 5.784286],
          [102.4809354, 5.7844117],
          [102.4817361, 5.7851112],
          [102.4820979, 5.7854131],
          [102.4824334, 5.7857006],
          [102.4832101, 5.7863711],
          [102.4839326, 5.7869822],
          [102.4846857, 5.7876192],
          [102.4852435, 5.7881122],
          [102.4856293, 5.7884548],
          [102.4866608, 5.7893551],
          [102.4880705, 5.7905791],
          [102.4889031, 5.7915592],
          [102.4891774, 5.7918518],
          [102.4899366, 5.7927521],
          [102.4905656, 5.7934901],
          [102.4915799, 5.7946788],
          [102.4927377, 5.7960562],
          [102.4939101, 5.7974408],
          [102.4949259, 5.7986163],
          [102.4961763, 5.8000732],
          [102.4974739, 5.8015705],
          [102.4986963, 5.8029984],
          [102.4997429, 5.8041897],
          [102.5009949, 5.8056305],
          [102.5016901, 5.8063602],
          [102.502276, 5.8070216],
          [102.5035108, 5.8084275],
          [102.5046777, 5.8098642],
          [102.5060049, 5.8113934],
          [102.5073498, 5.8129384],
          [102.5081646, 5.8138718],
          [102.5083579, 5.8140892],
          [102.5088391, 5.81464],
          [102.5101858, 5.8162055],
          [102.5110184, 5.817165],
          [102.5115366, 5.8177563],
          [102.5127206, 5.8191392],
          [102.514045, 5.8206524],
          [102.5340288, 5.84379],
          [102.5360047, 5.8460173],
          [102.5440273, 5.8388186],
          [102.5455077, 5.8378007],
          [102.5478992, 5.8369099],
          [102.5497628, 5.8364344],
          [102.5523688, 5.835798],
          [102.5544843, 5.8352462],
          [102.5558109, 5.8343384],
          [102.5564136, 5.8340433],
          [102.556916, 5.8339224],
          [102.557365, 5.8337981],
          [102.5578011, 5.8337767],
          [102.5584728, 5.8352566],
          [102.558561, 5.8353484],
          [102.5586997, 5.8353107],
          [102.5587018, 5.8352051],
          [102.5579436, 5.8335699],
          [102.5587506, 5.8325133],
          [102.5589606, 5.8322413],
          [102.5603853, 5.832024],
          [102.5620413, 5.8317715],
          [102.5626474, 5.8331365],
          [102.5626798, 5.8347605],
          [102.5626311, 5.8354286],
          [102.5625388, 5.8357173],
          [102.562323, 5.8359385],
          [102.560908945507023, 5.837600883973297],
          [102.560966746291598, 5.837677093911866],
          [102.560981363083258, 5.837688329377831],
          [102.5626316, 5.8360345],
          [102.5628226, 5.8356782],
          [102.5633649, 5.8348095],
          [102.5655863, 5.8326367],
          [102.567402, 5.8305971],
          [102.5680974, 5.8298867],
          [102.5683748, 5.8295672],
          [102.5686522, 5.8291924],
          [102.5691592, 5.8287898],
          [102.5693117, 5.8286686],
          [102.5697926, 5.8281708],
          [102.5699468, 5.8279803],
          [102.5701611, 5.8275854],
          [102.574116159362802, 5.824376578252821],
          [102.5748423, 5.8236698],
          [102.577055100205499, 5.821992154550259],
          [102.5788584, 5.8205291],
          [102.5941877, 5.8123448],
          [102.5964052, 5.8103952],
          [102.6013456, 5.8048153],
          [102.6025186, 5.8033412],
          [102.6037954, 5.8022618],
          [102.604484216948165, 5.802096883313141],
          [102.6047062, 5.8019805],
          [102.60479910266379, 5.802021493314251],
          [102.6049958, 5.8019744],
          [102.6056564, 5.8021557],
          [102.6069208, 5.8015933],
          [102.6080911, 5.8009193],
          [102.6087082, 5.8001643],
          [102.6092421, 5.7997512],
          [102.6097635, 5.7987068],
          [102.610356, 5.7977604],
          [102.6131728, 5.7946057],
          [102.6136603, 5.794018],
          [102.6142116, 5.7938403],
          [102.6145787, 5.7929467],
          [102.6153865, 5.7919937],
          [102.6158469, 5.7921406],
          [102.6163419, 5.7919143],
          [102.6169056, 5.7912998],
          [102.6173366, 5.7911665],
          [102.6181111, 5.790658],
          [102.6187967, 5.7897497],
          [102.6199563, 5.7879088],
          [102.621475, 5.7853713],
          [102.6231824, 5.7832157],
          [102.6244061, 5.782098],
          [102.625408265067691, 5.782041531202554],
          [102.6259951, 5.7817822],
          [102.6272707, 5.7816281],
          [102.627678401101932, 5.781913616295096],
          [102.6301846, 5.7817724],
          [102.630598518637768, 5.781158703320059],
          [102.6342155, 5.7755271],
          [102.6428647, 5.7625491],
          [102.6452715, 5.7594038],
          [102.6522484, 5.7496291],
          [102.6600581, 5.7395892],
          [102.6659434, 5.7323493],
          [102.6730399, 5.7240118],
          [102.6776315, 5.7190094],
          [102.6820212, 5.7140968],
          [102.6910776, 5.704572],
          [102.7003537, 5.6954443],
          [102.7148795, 5.6819427],
          [102.7192613, 5.6786898],
          [102.720903607757705, 5.677736428799116],
          [102.7266024, 5.6727511],
          [102.7270214, 5.6725264],
          [102.7272365, 5.6724832],
          [102.727774, 5.6724929],
          [102.7284842, 5.6721386],
          [102.7290249, 5.6719638],
          [102.7292119, 5.6718035],
          [102.7308409, 5.6711148],
          [102.7346087, 5.6696922],
          [102.7349172, 5.6695116],
          [102.7351486, 5.6692683],
          [102.7356077, 5.6690399],
          [102.7362437, 5.6685872],
          [102.737856219467588, 5.667636662034848],
          [102.7415287, 5.6651089],
          [102.7528614, 5.658669],
          [102.7563877, 5.6557255],
          [102.757606, 5.6553688],
          [102.760668, 5.6530144],
          [102.764369698291105, 5.650594103310501],
          [102.764661, 5.6503929],
          [102.765747109409602, 5.649976868200504],
          [102.7723173, 5.6451931],
          [102.7730947, 5.6444255],
          [102.7737638, 5.6440889],
          [102.7802644, 5.6386334],
          [102.7887415, 5.6327067],
          [102.8029409, 5.6201282],
          [102.8099573, 5.6130325],
          [102.8137151, 5.6089001],
          [102.8237669, 5.5986567],
          [102.8289669, 5.5933523],
          [102.8337776, 5.5887315],
          [102.8370291, 5.585691],
          [102.8406506, 5.5826101],
          [102.8454386, 5.5787674],
          [102.8504411, 5.5750956],
          [102.8516002, 5.5741797],
          [102.8532159, 5.5731939],
          [102.853365559497846, 5.573107110788546],
          [102.8537473, 5.5727561],
          [102.8547361, 5.5722959],
          [102.8560844, 5.5717677],
          [102.8602038, 5.5690313],
          [102.8624175, 5.5677117],
          [102.8629919, 5.5673406],
          [102.8638405, 5.5672535],
          [102.864763, 5.5669675],
          [102.8652923, 5.5672535],
          [102.8659076, 5.5672043],
          [102.8784799, 5.5581017],
          [102.8831682, 5.5549164],
          [102.8881741, 5.5522012],
          [102.8958761, 5.5475279],
          [102.9126921, 5.5391994],
          [102.9164768, 5.5381345],
          [102.9200282, 5.5369812],
          [102.9236313, 5.5356185],
          [102.9273025, 5.5344659],
          [102.9296916, 5.5338612],
          [102.9311706, 5.533616],
          [102.9345669, 5.5329684],
          [102.9391631, 5.5332525],
          [102.9397916, 5.5332632],
          [102.9406977, 5.5333736],
          [102.9419662, 5.5335939],
          [102.9433069, 5.5339169],
          [102.9435758, 5.5340604],
          [102.9434913, 5.5345088],
          [102.9434028, 5.534897],
          [102.9432423, 5.5349107],
          [102.9431298, 5.535039],
          [102.9430487, 5.5352383],
          [102.9430692, 5.53557],
          [102.9431868, 5.5359085],
          [102.9430894, 5.5367679],
          [102.9431237, 5.5369476],
          [102.9432311, 5.5370269],
          [102.9446022, 5.5375202],
          [102.9446946, 5.5376998],
          [102.9446069, 5.5379629],
          [102.9446032, 5.5381411],
          [102.9450349, 5.5385659],
          [102.9452875, 5.5387816],
          [102.9459873, 5.539458],
          [102.9465572, 5.539891],
          [102.9465683, 5.5400423],
          [102.9464834, 5.5400533],
          [102.9464415, 5.5400787],
          [102.9464202, 5.5401297],
          [102.9464262, 5.5401916],
          [102.9464783, 5.5402244],
          [102.9474123, 5.5401409],
          [102.9475567, 5.5400298],
          [102.950386, 5.5390309],
          [102.9522151, 5.5381679],
          [102.9545739, 5.5369451],
          [102.9600523, 5.5338073],
          [102.9664384, 5.5294022],
          [102.9712275, 5.5259695],
          [102.974728, 5.5231663],
          [102.9807831, 5.5178604],
          [102.9867052, 5.512671],
          [103.0020547, 5.4987702],
          [103.015376269817352, 5.484733275216154],
          [103.0372453, 5.4616899],
          [103.0430729, 5.4558693],
          [103.049915, 5.4502567],
          [103.0649838, 5.4362317],
          [103.0730358, 5.4285262],
          [103.0746187, 5.4270114],
          [103.078213, 5.4239633],
          [103.0828699, 5.4191086],
          [103.0834805, 5.4187036],
          [103.0836184, 5.4186455],
          [103.0837295, 5.4185986],
          [103.0847115, 5.4196026],
          [103.0847456, 5.4196279],
          [103.084786, 5.4196411],
          [103.0848286, 5.4196408],
          [103.0848688, 5.4196269],
          [103.0849025, 5.4196011],
          [103.0849246, 5.4195692],
          [103.0849364, 5.4195323],
          [103.0849369, 5.4194935],
          [103.0849259, 5.4194563],
          [103.0849045, 5.4194239],
          [103.084287, 5.4187435],
          [103.0847685, 5.4177758],
          [103.085141, 5.4172422],
          [103.0865245, 5.4157512],
          [103.0869045, 5.415418],
          [103.0873592, 5.4151406],
          [103.0878699, 5.4149545],
          [103.0883993, 5.4148288],
          [103.0888293, 5.4148727],
          [103.0891655, 5.4150717],
          [103.0893648, 5.4153024],
          [103.0894483, 5.4154864],
          [103.0890783, 5.4158126],
          [103.0889314, 5.4159148],
          [103.0888846, 5.4159764],
          [103.0888573, 5.4160486],
          [103.0888519, 5.4161256],
          [103.0888686, 5.4162009],
          [103.0889063, 5.4162684],
          [103.0889707, 5.4163287],
          [103.0890512, 5.4163649],
          [103.0891392, 5.4163733],
          [103.0892251, 5.4163529],
          [103.0892998, 5.4163058],
          [103.0906584, 5.4150747],
          [103.0907152, 5.415008],
          [103.0907465, 5.4149264],
          [103.0907485, 5.414839],
          [103.0907211, 5.4147559],
          [103.0906674, 5.4146867],
          [103.0906059, 5.414645],
          [103.0905351, 5.4146224],
          [103.0904608, 5.4146208],
          [103.090389, 5.4146402],
          [103.0903258, 5.4146792],
          [103.0901854, 5.4148537],
          [103.0896704, 5.4153062],
          [103.0895198, 5.4151711],
          [103.0893835, 5.4149722],
          [103.0892563, 5.414728],
          [103.0892291, 5.4145381],
          [103.0892317, 5.4142752],
          [103.0892996, 5.4135339],
          [103.08949, 5.4129329],
          [103.0910926, 5.4114144],
          [103.0917911, 5.4110729],
          [103.0927804, 5.4108886],
          [103.0931809, 5.4110597],
          [103.0935025, 5.4113684],
          [103.0936249, 5.4116083],
          [103.0936926, 5.4118214],
          [103.0932576, 5.4121853],
          [103.0930213, 5.4123485],
          [103.0929713, 5.412407],
          [103.0929411, 5.4124776],
          [103.0929335, 5.412554],
          [103.0929491, 5.4126292],
          [103.0929865, 5.4126963],
          [103.0930465, 5.412752],
          [103.093121, 5.4127859],
          [103.0932026, 5.4127945],
          [103.0932826, 5.412777],
          [103.093353, 5.4127351],
          [103.094797, 5.4115608],
          [103.0948462, 5.4115003],
          [103.0948745, 5.4114278],
          [103.0948794, 5.4113501],
          [103.0948603, 5.4112746],
          [103.0948191, 5.4112085],
          [103.0947584, 5.4111572],
          [103.0946847, 5.4111271],
          [103.0946052, 5.4111212],
          [103.0945278, 5.4111401],
          [103.0944601, 5.4111819],
          [103.0941232, 5.4115152],
          [103.0939523, 5.4113317],
          [103.0938034, 5.4111229],
          [103.0936747, 5.4105799],
          [103.0936724, 5.4100852],
          [103.0957495, 5.4080157],
          [103.0960179, 5.4078992],
          [103.0961899, 5.4078702],
          [103.0962892, 5.4078039],
          [103.0963432, 5.4076586],
          [103.0963887, 5.4074245],
          [103.0973523, 5.4064495],
          [103.098134, 5.4056265],
          [103.0989494, 5.4047592],
          [103.0990436, 5.4047006],
          [103.0991774, 5.4046759],
          [103.0996436, 5.4046716],
          [103.1009581, 5.4045905],
          [103.1013349, 5.4045292],
          [103.1016906, 5.4044619],
          [103.1019695, 5.4043703],
          [103.1022107, 5.4042684],
          [103.1024454, 5.4041371],
          [103.1026685, 5.4039645],
          [103.1029169, 5.4037492],
          [103.1032671, 5.4033957],
          [103.1032884, 5.4033696],
          [103.1032997, 5.4033379],
          [103.1032996, 5.4033043],
          [103.1032883, 5.4032726],
          [103.1032669, 5.4032466],
          [103.1032423, 5.4032309],
          [103.1032142, 5.403223],
          [103.103185, 5.4032237],
          [103.1031572, 5.4032327],
          [103.1031333, 5.4032494],
          [103.103113, 5.4032766],
          [103.103103, 5.4033091],
          [103.1031047, 5.403343],
          [103.1028224, 5.403621],
          [103.1025731, 5.4038458],
          [103.1023586, 5.4040065],
          [103.1021357, 5.4041243],
          [103.1019133, 5.4042229],
          [103.1016473, 5.4043126],
          [103.1013035, 5.4043777],
          [103.100821, 5.4044416],
          [103.0996566, 5.4045163],
          [103.0996259, 5.4043438],
          [103.0996439, 5.4041307],
          [103.0996882, 5.4039394],
          [103.0997766, 5.4037834],
          [103.0999048, 5.4037062],
          [103.1001042, 5.4036733],
          [103.1002754, 5.4035344],
          [103.1003559, 5.4033418],
          [103.1003865, 5.4028111],
          [103.1004761, 5.4025563],
          [103.1009713, 5.401869],
          [103.1013628, 5.4010645],
          [103.1018647, 5.4004503],
          [103.1022479, 5.4000684],
          [103.1025578, 5.3996378],
          [103.103292, 5.3991599],
          [103.1038316, 5.3989075],
          [103.1039642, 5.3988379],
          [103.1040615, 5.3987613],
          [103.1041075, 5.3986976],
          [103.1041129, 5.3986146],
          [103.1040798, 5.3985344],
          [103.1040626, 5.3984825],
          [103.1040937, 5.3983461],
          [103.1042217, 5.3981382],
          [103.1043324, 5.398011],
          [103.1046326, 5.3979039],
          [103.1047972, 5.3979142],
          [103.105159, 5.3978459],
          [103.10539, 5.3978777],
          [103.1052342, 5.398061],
          [103.1049269, 5.3985107],
          [103.1045881, 5.3990889],
          [103.1042487, 5.3997636],
          [103.1040541, 5.400425],
          [103.1039527, 5.4009935],
          [103.1038891, 5.4019319],
          [103.1038626, 5.4021034],
          [103.1037996, 5.4022521],
          [103.1037878, 5.4022696],
          [103.1037634, 5.4022862],
          [103.103739, 5.402308],
          [103.1037247, 5.4023335],
          [103.1037189, 5.4023622],
          [103.1037223, 5.4023913],
          [103.1037345, 5.4024179],
          [103.1037543, 5.4024396],
          [103.1037803, 5.4024543],
          [103.1038096, 5.4024602],
          [103.1038393, 5.4024566],
          [103.1038664, 5.402444],
          [103.1038882, 5.4024237],
          [103.1039034, 5.4023948],
          [103.1039079, 5.4023625],
          [103.1039162, 5.4023262],
          [103.1039785, 5.4021592],
          [103.104006, 5.4019367],
          [103.1040879, 5.401013],
          [103.1041823, 5.4004579],
          [103.10439, 5.3998295],
          [103.104725, 5.3991649],
          [103.1050712, 5.3985981],
          [103.1054001, 5.3981205],
          [103.1058347, 5.397643],
          [103.1068777, 5.3968365],
          [103.1078243, 5.3963323],
          [103.1086801, 5.3960087],
          [103.1095347, 5.3958433],
          [103.1104346, 5.3957569],
          [103.1111004, 5.395731],
          [103.1117814, 5.3957816],
          [103.1125417, 5.3959112],
          [103.113518, 5.3962695],
          [103.1141903, 5.3965685],
          [103.1145434, 5.3966893],
          [103.1149117, 5.3967504],
          [103.1152851, 5.3967503],
          [103.1156533, 5.3966889],
          [103.1160064, 5.3965679],
          [103.1163345, 5.3963906],
          [103.1166288, 5.3961618],
          [103.1168812, 5.3958879],
          [103.1170848, 5.3955763],
          [103.117234, 5.3952356],
          [103.1173248, 5.394875],
          [103.1173545, 5.3945044],
          [103.1173225, 5.3941341],
          [103.1172296, 5.393774],
          [103.1170784, 5.3934342],
          [103.1168729, 5.3931238],
          [103.1163115, 5.3923016],
          [103.1158789, 5.3915261],
          [103.1155519, 5.3908102],
          [103.1152873, 5.3900322],
          [103.1150598, 5.3889386],
          [103.114981, 5.3878758],
          [103.1149628, 5.3869867],
          [103.1150442, 5.3863871],
          [103.1151711, 5.3859955],
          [103.1159437, 5.3847397],
          [103.1189126, 5.3805156],
          [103.120283, 5.3782332],
          [103.1212257, 5.3764538],
          [103.1269849, 5.3646969],
          [103.1280497, 5.3622288],
          [103.1289833, 5.3602093],
          [103.1318831, 5.3542991],
          [103.1333529, 5.3516962],
          [103.1341132, 5.3507436],
          [103.1345574, 5.3503941],
          [103.1347115, 5.3502555],
          [103.1347789, 5.3501289],
          [103.1347806, 5.3501016],
          [103.1347926, 5.349916],
          [103.1348836, 5.3498525],
          [103.1359939, 5.3480901],
          [103.1362718, 5.3479881],
          [103.1363089, 5.348122],
          [103.1363729, 5.3481877],
          [103.1364808, 5.3482126],
          [103.1403758, 5.3473799],
          [103.1415345, 5.347209],
          [103.1418135, 5.3470808],
          [103.1420924, 5.3470167],
          [103.1447103, 5.346504],
          [103.1457617, 5.3462903],
          [103.1471135, 5.3460126],
          [103.1480577, 5.3458203],
          [103.1492808, 5.3454998],
          [103.1499889, 5.3452862],
          [103.150697, 5.3450084],
          [103.1514909, 5.3445598],
          [103.1522329, 5.344085],
          [103.1530777, 5.34334],
          [103.1533792, 5.3430216],
          [103.1540033, 5.3421948],
          [103.1542695, 5.3417704],
          [103.1544958, 5.3415215],
          [103.1545516, 5.3414522],
          [103.1545819, 5.3413687],
          [103.1548577, 5.3403224],
          [103.1551421, 5.339197],
          [103.155171, 5.3388168],
          [103.1552385, 5.3376479],
          [103.1551889, 5.3370964],
          [103.1550478, 5.336551],
          [103.1548843, 5.3361306],
          [103.1547157, 5.3357862],
          [103.1544304, 5.3353526],
          [103.1541146, 5.3349596],
          [103.153701, 5.3346253],
          [103.1515103, 5.3328525],
          [103.1514798, 5.3324811],
          [103.1514904, 5.3321383],
          [103.1515727, 5.3315938],
          [103.1517822, 5.3308619],
          [103.1519279, 5.3303794],
          [103.152199, 5.32969],
          [103.152218, 5.3296504],
          [103.1527461, 5.3285487],
          [103.1530386, 5.3279384],
          [103.1531002, 5.3278099],
          [103.1533199, 5.3273763],
          [103.1537521, 5.3265234],
          [103.1540197, 5.326016],
          [103.154194, 5.3254697],
          [103.1545023, 5.3250934],
          [103.1548887, 5.3244281],
          [103.1565397, 5.3215858],
          [103.1590863, 5.3169894],
          [103.1599256, 5.3157738],
          [103.1612609, 5.3129723],
          [103.1637026, 5.3082999],
          [103.1672697, 5.301785],
          [103.1675558, 5.3010443],
          [103.1680136, 5.300313],
          [103.1682807, 5.2995058],
          [103.1687671, 5.2989644],
          [103.1691963, 5.2979103],
          [103.1695778, 5.2974354],
          [103.1698449, 5.2968656],
          [103.170255, 5.2962198],
          [103.1705411, 5.2955075],
          [103.1710371, 5.2948997],
          [103.1711802, 5.2943679],
          [103.1721244, 5.2931333],
          [103.1724868, 5.2921741],
          [103.1736981, 5.2897523],
          [103.1739652, 5.2887741],
          [103.1744295, 5.2872765],
          [103.1745088, 5.2868112],
          [103.1743055, 5.2863328],
          [103.1740605, 5.2859629],
          [103.1737905, 5.2856608],
          [103.1736237, 5.2848571],
          [103.1742332, 5.2846089],
          [103.1747204, 5.2843148],
          [103.1751574, 5.2844814],
          [103.175806, 5.2842155],
          [103.1761398, 5.2838831],
          [103.1766834, 5.2829428],
          [103.1768933, 5.2827339],
          [103.1773034, 5.2818981],
          [103.1777993, 5.2813568],
          [103.1781045, 5.2808439],
          [103.1802601, 5.277311],
          [103.1811932, 5.2752692],
          [103.1816787, 5.2746648],
          [103.1825689, 5.2726235],
          [103.1844822, 5.2692533],
          [103.1852064, 5.2687492],
          [103.1860732, 5.268808],
          [103.1861473, 5.2686996],
          [103.1862116, 5.2686055],
          [103.1864907, 5.2681973],
          [103.186406, 5.2669761],
          [103.1866015, 5.2662979],
          [103.1868038, 5.2659823],
          [103.1870924, 5.2642469],
          [103.1869488, 5.2642262],
          [103.1868117, 5.2642128],
          [103.186706, 5.2642025],
          [103.1860176, 5.264138],
          [103.185984, 5.2641349],
          [103.182616, 5.2638352],
          [103.1824812, 5.2638232],
          [103.1824499, 5.2635135],
          [103.1824646, 5.2630789],
          [103.1825659, 5.2630889],
          [103.1826108, 5.2625192],
          [103.1825511, 5.2625075],
          [103.182421, 5.2623701],
          [103.1826498, 5.2581105],
          [103.1828383, 5.2580164],
          [103.1827725, 5.2577086],
          [103.1827749, 5.2572524],
          [103.1827131, 5.2565552],
          [103.1827689, 5.2561291],
          [103.1830821, 5.2550524],
          [103.1836256, 5.253531],
          [103.1847174, 5.2510116],
          [103.1864157, 5.2475299],
          [103.1867939, 5.2467942],
          [103.1898621, 5.2408262],
          [103.1939678, 5.2335229],
          [103.1979556, 5.2266437],
          [103.2013986, 5.2213287],
          [103.2046487, 5.2166416],
          [103.20899, 5.2109715],
          [103.2092098, 5.2108782],
          [103.2110236, 5.2111535],
          [103.2118463, 5.2112206],
          [103.2131409, 5.2110326],
          [103.2145637, 5.2103611],
          [103.2146312, 5.2101534],
          [103.2148086, 5.2099039],
          [103.215056, 5.2095558],
          [103.2152043, 5.2095419],
          [103.2153459, 5.2090919],
          [103.2154605, 5.2083063],
          [103.2155212, 5.2072587],
          [103.2153054, 5.2064059],
          [103.2150559, 5.2056874],
          [103.2144153, 5.2045995],
          [103.2143884, 5.2043041],
          [103.2149413, 5.2033975],
          [103.2157538, 5.2023567],
          [103.2192749, 5.197012],
          [103.222093, 5.1919668],
          [103.2341409, 5.1637698],
          [103.2448502, 5.1427655],
          [103.2658128, 5.1012735],
          [103.2711711, 5.0903742],
          [103.2775335, 5.078085],
          [103.2838194, 5.0661268],
          [103.2965057, 5.0413042],
          [103.3028659, 5.0293371],
          [103.3057139, 5.0239406],
          [103.3061383, 5.0231364],
          [103.3066834, 5.0221036],
          [103.3092057, 5.0173243],
          [103.318524914972741, 5.0005],
          [103.3223729, 4.9935531],
          [103.3319879, 4.9767072],
          [103.3392863, 4.9633577],
          [103.3467592, 4.9495697],
          [103.3619181, 4.9220391],
          [103.3714441, 4.9059731],
          [103.3799422, 4.8912431],
          [103.3967368, 4.862017],
          [103.4108576, 4.8384024],
          [103.4118609, 4.836815],
          [103.4122436, 4.8359311],
          [103.4127949, 4.8352295],
          [103.4130591, 4.834414],
          [103.4135603, 4.8337944],
          [103.4141105, 4.8326939],
          [103.4143348, 4.8318672],
          [103.4147911, 4.8309913],
          [103.4163284, 4.8289788],
          [103.4167808, 4.8280341],
          [103.4170137, 4.8277713],
          [103.4172204, 4.8277097],
          [103.4179158, 4.825753],
          [103.4182621, 4.8251061],
          [103.418441, 4.8249904],
          [103.4237594, 4.8155328],
          [103.4254521, 4.8131927],
          [103.4252833, 4.8126536],
          [103.4247101, 4.8124916],
          [103.4241743, 4.8114947],
          [103.4242291, 4.8103755],
          [103.4245093, 4.8091449],
          [103.4251712, 4.8077191],
          [103.4254827, 4.8073328],
          [103.4257942, 4.8065851],
          [103.4276135, 4.802959],
          [103.4313642, 4.7956694],
          [103.4405398, 4.7808869],
          [103.4401034, 4.7807094],
          [103.4391922, 4.7806013],
          [103.4386825, 4.7809381],
          [103.43857174266229, 4.78088392276667],
          [103.4384901, 4.7809381],
          [103.4383925, 4.7809331],
          [103.4382375, 4.7808219],
          [103.4381484, 4.7806883],
          [103.4377931, 4.7808371],
          [103.4375451, 4.7808395],
          [103.4373073, 4.7807162],
          [103.4369893, 4.7805728],
          [103.4367002, 4.7805856],
          [103.4364011, 4.7803944],
          [103.4363098, 4.7799843],
          [103.4360459, 4.780024],
          [103.4359789, 4.7805158],
          [103.4358336, 4.7810625],
          [103.4359826, 4.7812531],
          [103.4360866, 4.7814836],
          [103.4360251, 4.7817554],
          [103.4358322, 4.7820503],
          [103.435678, 4.7820573],
          [103.4354143, 4.7820691],
          [103.4350763, 4.7822123],
          [103.4347998, 4.7823196],
          [103.434531, 4.7823506],
          [103.4338518, 4.782319],
          [103.4333294, 4.7822231],
          [103.433117, 4.782017],
          [103.4332403, 4.7818113],
          [103.4332214, 4.7816962],
          [103.4330942, 4.7816906],
          [103.4329158, 4.781887],
          [103.4326852, 4.7818834],
          [103.4321437, 4.7815217],
          [103.4316498, 4.7817717],
          [103.4307133, 4.7815593],
          [103.4294247, 4.7804565],
          [103.4277568, 4.7784461],
          [103.4277955, 4.7784042],
          [103.4278031, 4.7783367],
          [103.4277559, 4.7782766],
          [103.427674, 4.7782723],
          [103.4275862, 4.778371],
          [103.4274308, 4.778288],
          [103.4260171, 4.7759852],
          [103.4260547, 4.7757554],
          [103.4256685, 4.7759746],
          [103.4252715, 4.7755736],
          [103.4248705, 4.7748666],
          [103.4246381, 4.7742037],
          [103.4241772, 4.7728152],
          [103.4239948, 4.7725265],
          [103.4240068, 4.7723515],
          [103.4241369, 4.7722619],
          [103.4240699, 4.7721376],
          [103.4239009, 4.7719011],
          [103.4236112, 4.771162],
          [103.4235281, 4.7707423],
          [103.4233859, 4.7700086],
          [103.4231941, 4.7695542],
          [103.4229809, 4.7691653],
          [103.423001, 4.7690037],
          [103.4231539, 4.7689448],
          [103.423162, 4.7688472],
          [103.4231056, 4.7687978],
          [103.4230574, 4.7688005],
          [103.4228777, 4.7688446],
          [103.4226443, 4.7685385],
          [103.4225249, 4.7682832],
          [103.4222728, 4.7669508],
          [103.4221964, 4.7663334],
          [103.4221333, 4.7658803],
          [103.4221548, 4.7657092],
          [103.4222017, 4.765625],
          [103.4224445, 4.765605],
          [103.4224802, 4.7654338],
          [103.4218986, 4.7655417],
          [103.4215711, 4.76504],
          [103.4216302, 4.7638651],
          [103.4225222, 4.7619952],
          [103.4226242, 4.7618054],
          [103.4227556, 4.7617279],
          [103.4230694, 4.7617065],
          [103.4232733, 4.7616343],
          [103.4234458, 4.761452],
          [103.4235173, 4.7612761],
          [103.4235114, 4.7610807],
          [103.4234234, 4.760811],
          [103.4230069, 4.7604974],
          [103.4229115, 4.7603132],
          [103.4228709, 4.7591511],
          [103.4230259, 4.7582707],
          [103.4231231, 4.7577371],
          [103.4233725, 4.7572934],
          [103.4240833, 4.7572694],
          [103.4243301, 4.7570502],
          [103.4244293, 4.7567882],
          [103.4243864, 4.7564408],
          [103.4240752, 4.7561574],
          [103.4236407, 4.7559596],
          [103.4238151, 4.7551043],
          [103.4242898, 4.753594],
          [103.4243569, 4.7531877],
          [103.4245489, 4.7529184],
          [103.425073, 4.7529178],
          [103.4253922, 4.7527467],
          [103.4255639, 4.7524553],
          [103.425521, 4.7520731],
          [103.4252983, 4.7518138],
          [103.4246626, 4.7515412],
          [103.4246707, 4.7509317],
          [103.4248584, 4.7503357],
          [103.4251119, 4.7495936],
          [103.4265954, 4.7455639],
          [103.4266934, 4.7453326],
          [103.426693403844723, 4.74533260023084],
          [103.427046425633208, 4.744499430283208],
          [103.4270464, 4.7444994],
          [103.4382141, 4.7181425],
          [103.4468455, 4.6981008],
          [103.4470538, 4.6976172],
          [103.4471502, 4.6973933],
          [103.4464065, 4.6950805],
          [103.4476535, 4.6906011],
          [103.4487935, 4.6878586],
          [103.4492145, 4.6871105],
          [103.4492832, 4.6864813],
          [103.4491342, 4.6860053],
          [103.4488836, 4.6857342],
          [103.4486117, 4.6855905],
          [103.4482735, 4.6855811],
          [103.4478834, 4.6856211],
          [103.4475216, 4.685369],
          [103.4474554, 4.6850484],
          [103.447165, 4.6844173],
          [103.4470889, 4.6838418],
          [103.4471693, 4.6830358],
          [103.4474199, 4.6818669],
          [103.4477391, 4.6808111],
          [103.4479236, 4.6801347],
          [103.4479207, 4.6798374],
          [103.4477756, 4.6795702],
          [103.4475649, 4.6795186],
          [103.447197, 4.6796119],
          [103.4468225, 4.6792819],
          [103.4466185, 4.6790686],
          [103.4464328, 4.678689],
          [103.4462188, 4.6777358],
          [103.4461027, 4.6770219],
          [103.4461885, 4.6756357],
          [103.446819, 4.6737195],
          [103.4469728, 4.6736528],
          [103.4473708, 4.6736428],
          [103.4475948, 4.6735828],
          [103.4481065, 4.6732529],
          [103.4487117, 4.6725396],
          [103.4493003, 4.6714598],
          [103.4492782, 4.6713582],
          [103.4492321, 4.6713334],
          [103.4491895, 4.6714354],
          [103.4486749, 4.672283],
          [103.4482469, 4.672503],
          [103.4478256, 4.672353],
          [103.4476316, 4.6724663],
          [103.4474778, 4.6727196],
          [103.4472638, 4.6732562],
          [103.4469895, 4.6732295],
          [103.4464679, 4.6729862],
          [103.4465281, 4.6727196],
          [103.4465816, 4.6721163],
          [103.4473641, 4.6715864],
          [103.4479994, 4.6709498],
          [103.4485011, 4.6702099],
          [103.4484317, 4.6701445],
          [103.4483472, 4.6701532],
          [103.4481767, 4.6703799],
          [103.4479928, 4.6706998],
          [103.4477018, 4.6710231],
          [103.4468223, 4.6717531],
          [103.4464277, 4.6714364],
          [103.4464077, 4.6712898],
          [103.4464645, 4.6706598],
          [103.4466083, 4.6702432],
          [103.4467053, 4.6701166],
          [103.4469762, 4.6701999],
          [103.4470334, 4.6700873],
          [103.4470313, 4.6698933],
          [103.4468809, 4.6694933],
          [103.4468591, 4.66919],
          [103.4468257, 4.6687467],
          [103.4468725, 4.6685751],
          [103.4469829, 4.6685034],
          [103.4470146, 4.6683901],
          [103.4470129, 4.6681251],
          [103.4473306, 4.6679368],
          [103.4473808, 4.6677118],
          [103.4474243, 4.6674752],
          [103.4475396, 4.6672419],
          [103.4477039, 4.6671223],
          [103.4477018, 4.6670253],
          [103.447645, 4.6669619],
          [103.4476767, 4.6668269],
          [103.4476349, 4.6666786],
          [103.4474677, 4.666647],
          [103.4474126, 4.6665653],
          [103.4475196, 4.6660304],
          [103.4476032, 4.6659787],
          [103.4476266, 4.6658304],
          [103.4478286, 4.6655461],
          [103.4478741, 4.6653257],
          [103.4476367, 4.6647901],
          [103.4474286, 4.6645332],
          [103.4473388, 4.6642551],
          [103.448514, 4.661077],
          [103.4486511, 4.6609061],
          [103.4487268, 4.6610251],
          [103.4488084, 4.6610263],
          [103.4488119, 4.6608932],
          [103.4485991, 4.6607918],
          [103.44867, 4.6606716],
          [103.4487528, 4.6607117],
          [103.4488403, 4.6606316],
          [103.4487717, 4.6605726],
          [103.4487717, 4.6605043],
          [103.4488616, 4.6605208],
          [103.4488379, 4.66041],
          [103.4489784, 4.6603387],
          [103.448942, 4.6601508],
          [103.4488758, 4.6600777],
          [103.4488758, 4.6597431],
          [103.4488521, 4.659373],
          [103.4487126, 4.6592363],
          [103.4487504, 4.659135],
          [103.4488545, 4.6591491],
          [103.4490058, 4.6590667],
          [103.4490224, 4.6587886],
          [103.4489538, 4.6587485],
          [103.4489751, 4.6586424],
          [103.4490224, 4.6586141],
          [103.4489538, 4.6584445],
          [103.4490034, 4.6584374],
          [103.4489987, 4.6583125],
          [103.4488592, 4.6582441],
          [103.448994, 4.658098],
          [103.4488994, 4.6580037],
          [103.4489207, 4.6579142],
          [103.4490318, 4.657992],
          [103.4490862, 4.6579448],
          [103.4491004, 4.6577963],
          [103.4489443, 4.6577044],
          [103.4488923, 4.6575489],
          [103.4490034, 4.6576219],
          [103.4491004, 4.65753],
          [103.4490961, 4.6574032],
          [103.4486156, 4.6568748],
          [103.4486865, 4.6563622],
          [103.4476643, 4.6546981],
          [103.4462668, 4.6544397],
          [103.4457284, 4.6548197],
          [103.4456214, 4.655383],
          [103.445414, 4.6558963],
          [103.44519, 4.6560296],
          [103.4446215, 4.6562696],
          [103.4444543, 4.6570561],
          [103.4440731, 4.6573428],
          [103.4435548, 4.6577127],
          [103.4433039, 4.6578694],
          [103.4430732, 4.6576861],
          [103.4426753, 4.6576994],
          [103.4425482, 4.6576294],
          [103.4425482, 4.6574061],
          [103.442345, 4.65727],
          [103.4420336, 4.6566508],
          [103.4416206, 4.6555034],
          [103.4413744, 4.6551217],
          [103.4410347, 4.6548823],
          [103.4397801, 4.6533772],
          [103.4391515, 4.651764],
          [103.4385109, 4.6491233],
          [103.4382856, 4.6463216],
          [103.438248, 4.6449475],
          [103.4383894, 4.6436019],
          [103.4384894, 4.6423917],
          [103.4387282, 4.6416164],
          [103.4387362, 4.6410924],
          [103.4389347, 4.6396541],
          [103.4390366, 4.639344],
          [103.4393855, 4.6380585],
          [103.4397712, 4.6361585],
          [103.4412702, 4.6313417],
          [103.4418144, 4.6293943],
          [103.4421565, 4.6286247],
          [103.4429603, 4.6261456],
          [103.4433741, 4.6252712],
          [103.4465827, 4.6157868],
          [103.4535015, 4.5980115],
          [103.4550135, 4.5950008],
          [103.456030685870189, 4.59405921993137],
          [103.4563648, 4.5934107],
          [103.4576514, 4.5919314],
          [103.457720479776967, 4.591464733832744],
          [103.4576618, 4.5908741],
          [103.457938683379467, 4.590686933022811],
          [103.4580543, 4.5903371],
          [103.458146363147364, 4.59009183426042],
          [103.4577864, 4.589692],
          [103.45788, 4.5863615],
          [103.4580368, 4.5859051],
          [103.4582277, 4.5857374],
          [103.4586288, 4.5845644],
          [103.4590129, 4.5838498],
          [103.4591679, 4.5836767],
          [103.4592599, 4.5833555],
          [103.4591816, 4.5828136],
          [103.4596908, 4.5815756],
          [103.460005, 4.5812028],
          [103.4604555, 4.5813053],
          [103.4603201, 4.5806713],
          [103.4604062, 4.5799864],
          [103.461911, 4.5766806],
          [103.4626481, 4.5752822],
          [103.4633232, 4.5744642],
          [103.4636236, 4.5744611],
          [103.4635407, 4.5741935],
          [103.4634567, 4.5737881],
          [103.4634487, 4.5734411],
          [103.463594, 4.5729752],
          [103.4648395, 4.570573],
          [103.4656408, 4.568659],
          [103.4661521, 4.5679651],
          [103.4663398, 4.5678994],
          [103.4662593, 4.5671419],
          [103.4662957, 4.5667971],
          [103.4663991, 4.5665313],
          [103.4665274, 4.5664323],
          [103.4666171, 4.5665442],
          [103.4668322, 4.5664982],
          [103.4667865, 4.5663139],
          [103.4668829, 4.5661879],
          [103.4669651, 4.5659041],
          [103.4669126, 4.5657707],
          [103.4669545, 4.5656022],
          [103.4673029, 4.5653487],
          [103.4671713, 4.5649392],
          [103.4671651, 4.5645817],
          [103.4674941, 4.5638087],
          [103.4676501, 4.563626],
          [103.4680857, 4.563645],
          [103.4678316, 4.5634347],
          [103.468156, 4.5633918],
          [103.4680899, 4.5630862],
          [103.4679241, 4.5630013],
          [103.4678955, 4.5627466],
          [103.4679972, 4.5625514],
          [103.4683555, 4.5623731],
          [103.4683733, 4.5621791],
          [103.4686291, 4.5620058],
          [103.46851, 4.5617323],
          [103.4685527, 4.5610948],
          [103.4686507, 4.560742],
          [103.4688359, 4.5606506],
          [103.468936, 4.5601007],
          [103.4689601, 4.5593212],
          [103.4688019, 4.559186],
          [103.4686802, 4.5588044],
          [103.4686692, 4.5585501],
          [103.468289, 4.5583456],
          [103.4679797, 4.5581839],
          [103.4678591, 4.5576107],
          [103.4679351, 4.5567469],
          [103.4682796, 4.5559754],
          [103.4686582, 4.5554416],
          [103.4688267, 4.5547024],
          [103.469489, 4.5530803],
          [103.4700985, 4.5520092],
          [103.4703721, 4.5516751],
          [103.4708087, 4.5518813],
          [103.4705317, 4.5512788],
          [103.4705124, 4.5508162],
          [103.4705612, 4.5503197],
          [103.4720593, 4.5464408],
          [103.4721161, 4.5457779],
          [103.4725816, 4.5452679],
          [103.4729676, 4.5449269],
          [103.4728029, 4.5444316],
          [103.4728875, 4.5437031],
          [103.472504, 4.543585],
          [103.472376, 4.5432802],
          [103.4712612, 4.5432811],
          [103.4709137, 4.5427045],
          [103.4706385, 4.5417874],
          [103.4712963, 4.5389374],
          [103.4727652, 4.5350326],
          [103.475413, 4.5280429],
          [103.4750222, 4.5277633],
          [103.4750053, 4.5276289],
          [103.4749511, 4.5271969],
          [103.4751255, 4.5259963],
          [103.4748041, 4.524498],
          [103.4745161, 4.5241437],
          [103.4739213, 4.5238693],
          [103.4737172, 4.5232056],
          [103.4735743, 4.5229568],
          [103.4734198, 4.5228206],
          [103.4729129, 4.5225497],
          [103.4724646, 4.5225718],
          [103.471892, 4.5218461],
          [103.4714037, 4.5215895],
          [103.471093, 4.5204611],
          [103.4705204, 4.5195495],
          [103.4696157, 4.5188513],
          [103.4695261, 4.5183194],
          [103.4697251, 4.5170649],
          [103.4693974, 4.5165051],
          [103.4690716, 4.5160816],
          [103.4679112, 4.5156346],
          [103.4674324, 4.5148015],
          [103.4670936, 4.5147838],
          [103.4667519, 4.5148502],
          [103.466672, 4.5153414],
          [103.4669028, 4.5156024],
          [103.4669383, 4.5158812],
          [103.4668263, 4.5162515],
          [103.4664056, 4.5169742],
          [103.4657973, 4.5172331],
          [103.4650651, 4.5173592],
          [103.4642972, 4.5173415],
          [103.4632053, 4.5169742],
          [103.4622006, 4.5167809],
          [103.4617893, 4.5169432],
          [103.4613321, 4.5169565],
          [103.4608838, 4.5168414],
          [103.4606807, 4.5167507],
          [103.4584487, 4.517319],
          [103.4576523, 4.5166998],
          [103.4563612, 4.5157908],
          [103.4555626, 4.5150847],
          [103.4555187, 4.5146317],
          [103.4554017, 4.5141264],
          [103.4553871, 4.5140821],
          [103.4553606, 4.5140487],
          [103.4553249, 4.5140253],
          [103.4552837, 4.5140145],
          [103.4552411, 4.5140171],
          [103.4552015, 4.5140331],
          [103.4551674, 4.5140627],
          [103.4551453, 4.5141016],
          [103.4551374, 4.5141463],
          [103.4551449, 4.5141907],
          [103.4545514, 4.5145004],
          [103.4541447, 4.5143685],
          [103.4540319, 4.5143203],
          [103.4539608, 4.5142947],
          [103.4538465, 4.5143101],
          [103.4535839, 4.5142004],
          [103.4532347, 4.5139686],
          [103.4527782, 4.5135128],
          [103.4522709, 4.5127282],
          [103.4518739, 4.5119959],
          [103.450528, 4.5085836],
          [103.4497823, 4.5066152],
          [103.4492046, 4.5046189],
          [103.4491592, 4.5044997],
          [103.449048, 4.5042076],
          [103.4489437, 4.5039335],
          [103.4486029, 4.5030383],
          [103.4475207, 4.4966285],
          [103.4472108, 4.4942776],
          [103.4466017, 4.4919139],
          [103.4461954, 4.4890702],
          [103.4459993, 4.4863191],
          [103.4460168, 4.4844595],
          [103.4458305, 4.4833903],
          [103.4456098, 4.4820693],
          [103.4456201, 4.481145],
          [103.4457404, 4.4806021],
          [103.4456891, 4.4799033],
          [103.445427, 4.4790065],
          [103.4452131, 4.4784892],
          [103.4451579, 4.477789],
          [103.4451338, 4.476468],
          [103.4448954, 4.4751494],
          [103.4446647, 4.4736777],
          [103.4446095, 4.4723671],
          [103.4445849, 4.4695388],
          [103.4463382, 4.4610859],
          [103.4482026, 4.4542296],
          [103.4523619, 4.4422034],
          [103.4528581, 4.4411762],
          [103.4531169, 4.4403585],
          [103.4544754, 4.4371072],
          [103.4553959, 4.4349319],
          [103.4554018, 4.4343162],
          [103.4557047, 4.4334468],
          [103.4555249, 4.4327097],
          [103.4551848, 4.4322119],
          [103.4551438, 4.4314197],
          [103.4552376, 4.4309858],
          [103.4554253, 4.4307044],
          [103.4552318, 4.4306106],
          [103.4552318, 4.4299597],
          [103.4553285, 4.4296752],
          [103.455473, 4.4285778],
          [103.4556229, 4.4279461],
          [103.4557824, 4.427529],
          [103.4557821, 4.4271593],
          [103.4556176, 4.4267154],
          [103.4553954, 4.4267336],
          [103.455208, 4.4266266],
          [103.4550445, 4.426309],
          [103.4549343, 4.4259124],
          [103.4550153, 4.4251411],
          [103.4553606, 4.4240062],
          [103.4558745, 4.4224243],
          [103.4569282, 4.4192416],
          [103.4572034, 4.4180941],
          [103.4574287, 4.4176844],
          [103.4577339, 4.4175015],
          [103.4577113, 4.4173206],
          [103.4572732, 4.4173003],
          [103.4567553, 4.4171867],
          [103.456786, 4.4169439],
          [103.4566976, 4.4166281],
          [103.4567753, 4.4163444],
          [103.4568903, 4.4162748],
          [103.4570081, 4.4163979],
          [103.4569412, 4.4158171],
          [103.4570096, 4.4151987],
          [103.4564208, 4.4140281],
          [103.4559058, 4.4133221],
          [103.4557195, 4.4125097],
          [103.4556566, 4.4104823],
          [103.4559007, 4.409042],
          [103.4554836, 4.4080581],
          [103.4560044, 4.4049228],
          [103.4556698, 4.4040584],
          [103.455584, 4.4029031],
          [103.4549402, 4.4014697],
          [103.455305, 4.3998651],
          [103.4551548, 4.3989238],
          [103.4553908, 4.398068],
          [103.4555196, 4.3974689],
          [103.4553073, 4.3971979],
          [103.4550639, 4.396887],
          [103.4552192, 4.3958429],
          [103.4587168, 4.3871139],
          [103.4619193, 4.3795829],
          [103.4630023, 4.3773561],
          [103.4680809, 4.3671066],
          [103.4732919, 4.3579201],
          [103.4766212, 4.351911],
          [103.4776661, 4.3500519],
          [103.4845129, 4.3388791],
          [103.488295, 4.3335614],
          [103.4894732, 4.3318909],
          [103.490838, 4.3303989],
          [103.4916989, 4.3301747],
          [103.4918311, 4.3300124],
          [103.491518, 4.3298705],
          [103.4917039, 4.3295134],
          [103.4920072, 4.3295526],
          [103.491293, 4.3286524],
          [103.4915082, 4.3278208],
          [103.4921764, 4.3265419],
          [103.4922469, 4.3249444],
          [103.4921833, 4.3234817],
          [103.4922029, 4.3220239],
          [103.4921246, 4.3212901],
          [103.4917021, 4.3203658],
          [103.4912294, 4.3197051],
          [103.4909471, 4.3189457],
          [103.4913468, 4.3182179],
          [103.4914029, 4.3169462],
          [103.4910777, 4.3166134],
          [103.490476, 4.3166623],
          [103.4901042, 4.3162514],
          [103.4900113, 4.3154393],
          [103.4901546, 4.3141661],
          [103.4909016, 4.3120149],
          [103.4912881, 4.3117654],
          [103.4913692, 4.3106073],
          [103.4910826, 4.3095151],
          [103.4905445, 4.3081503],
          [103.4900896, 4.3077198],
          [103.4896786, 4.307891],
          [103.489028, 4.3067414],
          [103.4886024, 4.3067121],
          [103.4879811, 4.3060908],
          [103.4879498, 4.3052324],
          [103.4877121, 4.3043639],
          [103.4874234, 4.3038209],
          [103.48758, 4.3035127],
          [103.4877757, 4.303498],
          [103.4878637, 4.3030088],
          [103.487805, 4.3023142],
          [103.4882159, 4.3013896],
          [103.4881448, 4.2986652],
          [103.4874849, 4.2981682],
          [103.4873904, 4.297308],
          [103.4872823, 4.2958102],
          [103.486763, 4.29458],
          [103.4861822, 4.29373],
          [103.4855694, 4.2934108],
          [103.4850817, 4.2931278],
          [103.4837352, 4.2911355],
          [103.4837352, 4.2898923],
          [103.4839927, 4.2883089],
          [103.484336, 4.2870892],
          [103.4841429, 4.2870678],
          [103.4837996, 4.2878595],
          [103.4836708, 4.288908],
          [103.4819757, 4.2888438],
          [103.4818684, 4.2882875],
          [103.4821044, 4.2879879],
          [103.4820615, 4.2872818],
          [103.4817289, 4.2867683],
          [103.4826838, 4.2867896],
          [103.4826194, 4.2865971],
          [103.4816109, 4.2865115],
          [103.4814392, 4.2862119],
          [103.4804307, 4.2856984],
          [103.4802031, 4.2857673],
          [103.4801495, 4.286],
          [103.4801518, 4.2864045],
          [103.4797043, 4.2867088],
          [103.4786743, 4.2866286],
          [103.4774834, 4.2854143],
          [103.4770404, 4.2840293],
          [103.477019, 4.2820394],
          [103.4770918, 4.2806399],
          [103.4771665, 4.2802981],
          [103.4770274, 4.2799124],
          [103.477191, 4.2787221],
          [103.4778773, 4.2772248],
          [103.4794714, 4.2740505],
          [103.4788214, 4.2738226],
          [103.4785425, 4.2739296],
          [103.4779631, 4.2736086],
          [103.4776412, 4.2733518],
          [103.4760963, 4.273309],
          [103.4755813, 4.2722605],
          [103.4754311, 4.2708268],
          [103.4754968, 4.2673182],
          [103.4745138, 4.2663172],
          [103.473575, 4.2654452],
          [103.4730041, 4.2654767],
          [103.4724914, 4.2656324],
          [103.4719795, 4.2653697],
          [103.4717113, 4.264364],
          [103.4718852, 4.2631289],
          [103.4719979, 4.2618877],
          [103.4722906, 4.2616464],
          [103.4726179, 4.2615502],
          [103.4726608, 4.2613094],
          [103.4724086, 4.2611008],
          [103.4724623, 4.2604214],
          [103.4727198, 4.2593194],
          [103.4732974, 4.2590049],
          [103.473503, 4.257982],
          [103.4730309, 4.257875],
          [103.472127, 4.2571742],
          [103.4718635, 4.2565983],
          [103.47116813325745, 4.252514841035008],
          [103.4711426, 4.2523649],
          [103.470584, 4.2504596],
          [103.4688809, 4.2503927],
          [103.4689083, 4.2505145],
          [103.4694415, 4.2526337],
          [103.4695798, 4.2535216],
          [103.4701548, 4.2561837],
          [103.4703514, 4.2561604],
          [103.4705116, 4.2568123],
          [103.4704104, 4.2568719],
          [103.470287, 4.2567088],
          [103.4699684, 4.2568942],
          [103.4699061, 4.2569415],
          [103.4699786, 4.25711],
          [103.4692135, 4.2571251],
          [103.4692134, 4.2569353],
          [103.4663649, 4.2569326],
          [103.4658835, 4.2569345],
          [103.4658748, 4.2570618],
          [103.4660786, 4.2570538],
          [103.466076, 4.2572919],
          [103.4653437, 4.2572776],
          [103.4653178, 4.256855],
          [103.4649467, 4.2568291],
          [103.4647214, 4.2568478],
          [103.4646921, 4.2570614],
          [103.4633374, 4.2566713],
          [103.4616637, 4.2555586],
          [103.4610706, 4.2544846],
          [103.4610274, 4.25418],
          [103.461012, 4.2540709],
          [103.460791422141156, 4.252514841035008],
          [103.4604869, 4.2503666],
          [103.4604741, 4.2502495],
          [103.4604406, 4.2499415],
          [103.4603337, 4.2492947],
          [103.4602103, 4.2491333],
          [103.4599968, 4.249093],
          [103.4597887, 4.2488617],
          [103.459722, 4.2484395],
          [103.4597757, 4.2477719],
          [103.4599213, 4.2463707],
          [103.4599296, 4.246291],
          [103.4599185, 4.2461971],
          [103.4596292, 4.2437679],
          [103.4597171, 4.2426118],
          [103.4597934, 4.2410691],
          [103.4606118, 4.2395288],
          [103.4608743, 4.2392749],
          [103.4611896, 4.2390436],
          [103.461204, 4.2388619],
          [103.4601729, 4.2388278],
          [103.4600233, 4.2384668],
          [103.4597036, 4.2382134],
          [103.4593463, 4.2380651],
          [103.4580065, 4.2378556],
          [103.4575536, 4.237697],
          [103.457244, 4.2374066],
          [103.4570286, 4.2370072],
          [103.4571266, 4.2367076],
          [103.4571906, 4.2364456],
          [103.4571505, 4.2362574],
          [103.4569562, 4.2361316],
          [103.4567176, 4.2360049],
          [103.4564039, 4.2360121],
          [103.4554044, 4.2361159],
          [103.4545753, 4.2359975],
          [103.4540012, 4.2361159],
          [103.4531811, 4.2363619],
          [103.452636, 4.2360208],
          [103.4528419, 4.2363264],
          [103.4528531, 4.2367811],
          [103.4525068, 4.2373278],
          [103.4526526, 4.2382572],
          [103.4524839, 4.2389692],
          [103.4522061, 4.239214],
          [103.4515545, 4.2395323],
          [103.4514788, 4.2397291],
          [103.4488768, 4.2389808],
          [103.4466535, 4.2383525],
          [103.4466617, 4.2383361],
          [103.4466642, 4.2383179],
          [103.4466608, 4.2382998],
          [103.4466512, 4.238283],
          [103.4466378, 4.2382711],
          [103.4466204, 4.2382638],
          [103.4466011, 4.2382625],
          [103.4465823, 4.2382678],
          [103.4465665, 4.2382791],
          [103.4465326, 4.2383038],
          [103.44651, 4.2383081],
          [103.4464887, 4.2383084],
          [103.4464585, 4.2382953],
          [103.4463328, 4.2382071],
          [103.4453175, 4.2367356],
          [103.4444974, 4.2353961],
          [103.443691, 4.2341249],
          [103.4425929, 4.2323117],
          [103.442151, 4.2312729],
          [103.4417729, 4.2295644],
          [103.4411768, 4.2252748],
          [103.4408116, 4.2227896],
          [103.4406703, 4.2224069],
          [103.4403197, 4.2211295],
          [103.4397728, 4.218794],
          [103.4398822, 4.2186026],
          [103.4397227, 4.2178965],
          [103.4388488, 4.2169119],
          [103.4387577, 4.2166177],
          [103.4387201, 4.216179],
          [103.4386825, 4.214451],
          [103.4387791, 4.2118402],
          [103.43916, 4.2093953],
          [103.439514, 4.2070306],
          [103.4401202, 4.2034943],
          [103.4405279, 4.2014934],
          [103.4409785, 4.1997386],
          [103.4415471, 4.1976307],
          [103.4439969, 4.1887882],
        ],
      ],
      [
        [
          [103.0284004, 5.7530368],
          [103.0283271, 5.7531826],
          [103.0287267, 5.753514],
          [103.0288466, 5.7534146],
          [103.0289798, 5.753514],
          [103.0289865, 5.7536134],
          [103.0290931, 5.7536995],
          [103.0292796, 5.753726],
          [103.0293795, 5.7538519],
          [103.029546, 5.753779],
          [103.0294661, 5.7536598],
          [103.0291397, 5.7536134],
          [103.0291797, 5.7534278],
          [103.0292862, 5.7531495],
          [103.029233, 5.7527386],
          [103.0289798, 5.7525133],
          [103.0287334, 5.7528115],
          [103.0286801, 5.7527518],
          [103.0285735, 5.7527452],
          [103.0286135, 5.7529904],
          [103.0284537, 5.7529109],
          [103.0284004, 5.7530368],
        ],
      ],
      [
        [
          [103.0585736, 5.7754635],
          [103.0585498, 5.7757265],
          [103.058814, 5.7759971],
          [103.0590498, 5.7761807],
          [103.0590164, 5.7763331],
          [103.0592727, 5.7764844],
          [103.059516, 5.7761939],
          [103.0595893, 5.7760415],
          [103.0596805, 5.7760423],
          [103.0596442, 5.7758446],
          [103.0598091, 5.7753457],
          [103.0597292, 5.7751933],
          [103.0598355, 5.7751749],
          [103.0599589, 5.77528],
          [103.060342, 5.7754716],
          [103.0608548, 5.7756704],
          [103.0610185, 5.7754397],
          [103.0609082, 5.7751391],
          [103.0610413, 5.7751402],
          [103.061088, 5.775021],
          [103.0612667, 5.7750848],
          [103.0612878, 5.7749481],
          [103.0610347, 5.7747691],
          [103.0608948, 5.7747161],
          [103.0606284, 5.7741793],
          [103.0599223, 5.7738745],
          [103.0600581, 5.77379],
          [103.0602714, 5.7739078],
          [103.0607283, 5.7741064],
          [103.0611479, 5.7740396],
          [103.0612079, 5.7737552],
          [103.061159, 5.7735797],
          [103.0610967, 5.7734946],
          [103.0608748, 5.7731919],
          [103.0612504, 5.7732535],
          [103.061533, 5.7731728],
          [103.061814, 5.7730925],
          [103.0618401, 5.7727196],
          [103.0620947, 5.772454],
          [103.0621004, 5.7721051],
          [103.0616541, 5.7718267],
          [103.0613744, 5.7717538],
          [103.0616345, 5.7716754],
          [103.0618172, 5.7714543],
          [103.0618173, 5.7712811],
          [103.0613877, 5.7711441],
          [103.0610214, 5.7713623],
          [103.0610195, 5.7715931],
          [103.0609054, 5.7718343],
          [103.0603957, 5.7720234],
          [103.0601088, 5.7724364],
          [103.0601888, 5.7726684],
          [103.0600207, 5.7729214],
          [103.060138, 5.773029],
          [103.0596159, 5.7734305],
          [103.0593961, 5.7739474],
          [103.059535, 5.7741326],
          [103.0593753, 5.7743244],
          [103.0589656, 5.7743745],
          [103.0587125, 5.7744775],
          [103.0586009, 5.7747746],
          [103.0587341, 5.7750055],
          [103.0585736, 5.7754635],
        ],
      ],
      [
        [
          [103.040776086051849, 5.777907158238767],
          [103.0408442, 5.7779904],
          [103.0407239, 5.777934925885228],
          [103.0407239, 5.778353],
          [103.0414097, 5.7784734],
          [103.0416719, 5.7789149],
          [103.042056172524454, 5.779029635699219],
          [103.0420696, 5.7786891],
          [103.0421676, 5.7786583],
          [103.0422022, 5.778849],
          [103.0424075, 5.7788945],
          [103.0425379, 5.7790146],
          [103.04264587687949, 5.779025008582077],
          [103.042649530158201, 5.779024309270005],
          [103.0426863, 5.7789665],
          [103.0426762, 5.7786755],
          [103.042845617703449, 5.778776202830729],
          [103.0428621, 5.7786942],
          [103.043167553782837, 5.778559097059974],
          [103.0431647, 5.7785566],
          [103.0432252, 5.7785336],
          [103.0433375, 5.7785996],
          [103.0434124, 5.7785938],
          [103.0434247, 5.7784942],
          [103.0433275, 5.7783488],
          [103.0433405, 5.7782873],
          [103.0434358, 5.778233],
          [103.0433267, 5.7781361],
          [103.0433389, 5.7779996],
          [103.0433173, 5.7779329],
          [103.0432395, 5.7779258],
          [103.0428995, 5.7773152],
          [103.0429543, 5.7771345],
          [103.0428488, 5.7770417],
          [103.042783910769899, 5.777005814289411],
          [103.0427612, 5.7770284],
          [103.0429024, 5.7775904],
          [103.0429226, 5.7780319],
          [103.042530857731748, 5.777690895678908],
          [103.0425826, 5.7778699],
          [103.0422656, 5.7776736],
          [103.0422123, 5.777785],
          [103.0419745, 5.7777187],
          [103.041937, 5.777804],
          [103.0416892, 5.7776306],
          [103.0416489, 5.7776851],
          [103.0414875, 5.7776249],
          [103.0413261, 5.7774586],
          [103.041113, 5.7773753],
          [103.041037649647109, 5.777335480707823],
          [103.0410668, 5.777771],
          [103.040776086051849, 5.777907158238767],
        ],
      ],
      [
        [
          [103.0386297, 5.7721046],
          [103.0385964, 5.7722372],
          [103.0387429, 5.7724823],
          [103.038996, 5.7726546],
          [103.039056, 5.7728203],
          [103.0396021, 5.7728799],
          [103.0399951, 5.7729992],
          [103.0401616, 5.772986],
          [103.0401882, 5.7728137],
          [103.0403414, 5.7727872],
          [103.0402748, 5.7726811],
          [103.0401683, 5.7726414],
          [103.0401416, 5.7725486],
          [103.0399618, 5.7723763],
          [103.0398485, 5.7723366],
          [103.039682, 5.7721378],
          [103.0392691, 5.7722239],
          [103.0391559, 5.7721245],
          [103.039016, 5.7721709],
          [103.0389294, 5.7720582],
          [103.0386297, 5.7721046],
        ],
      ],
      [
        [
          [103.0274812, 5.7546008],
          [103.0274479, 5.7547334],
          [103.0276606, 5.754868],
          [103.0277192, 5.755067],
          [103.0279474, 5.7553696],
          [103.02856, 5.7559434],
          [103.0287969, 5.7558988],
          [103.029229, 5.7560312],
          [103.0292996, 5.7558865],
          [103.0291131, 5.7557208],
          [103.0292396, 5.7555882],
          [103.0292093, 5.755525],
          [103.0288678, 5.7553817],
          [103.0288, 5.7551244],
          [103.0285869, 5.7549918],
          [103.0286474, 5.7546538],
          [103.0283604, 5.7544086],
          [103.0282882, 5.7540451],
          [103.0283217, 5.7537624],
          [103.0281857, 5.7536192],
          [103.0280083, 5.7535837],
          [103.0277809, 5.7540972],
          [103.0277906, 5.754346],
          [103.0274812, 5.7546008],
        ],
      ],
      [
        [
          [103.024757, 5.7551641],
          [103.0247237, 5.7553165],
          [103.0248369, 5.7553894],
          [103.0249368, 5.75529],
          [103.0249368, 5.7551376],
          [103.0250101, 5.7550183],
          [103.0252965, 5.7548261],
          [103.0255896, 5.75474],
          [103.0258493, 5.75474],
          [103.0260225, 5.7548261],
          [103.0261291, 5.7548593],
          [103.0260825, 5.75474],
          [103.0259559, 5.7546406],
          [103.0257561, 5.7545876],
          [103.0253298, 5.7546803],
          [103.0250367, 5.7548261],
          [103.024757, 5.7551641],
        ],
      ],
      [
        [
          [103.0295759, 5.7574063],
          [103.0295426, 5.7575189],
          [103.0296359, 5.7576581],
          [103.0296226, 5.7578436],
          [103.0297491, 5.7579563],
          [103.0298557, 5.7582346],
          [103.0302553, 5.7583937],
          [103.0304418, 5.7586124],
          [103.0304551, 5.7588973],
          [103.0305551, 5.7589106],
          [103.030595, 5.7587781],
          [103.030575, 5.7586654],
          [103.0306483, 5.7586323],
          [103.0306416, 5.75846],
          [103.0304685, 5.7584069],
          [103.0304285, 5.7583142],
          [103.0303486, 5.7582744],
          [103.0303353, 5.7581617],
          [103.0305151, 5.7579894],
          [103.0303819, 5.7578503],
          [103.0301354, 5.7579762],
          [103.0300622, 5.7579033],
          [103.0300955, 5.7578105],
          [103.0300755, 5.7576581],
          [103.029889, 5.7575123],
          [103.0297158, 5.7575454],
          [103.0296625, 5.7574195],
          [103.0295759, 5.7574063],
        ],
      ],
      [
        [
          [103.034347, 5.7689702],
          [103.0342738, 5.7690033],
          [103.034347, 5.7691292],
          [103.0345468, 5.769169],
          [103.0347466, 5.7690961],
          [103.0349731, 5.7687581],
          [103.0351396, 5.7688377],
          [103.0352995, 5.7687648],
          [103.035446, 5.7684997],
          [103.0356192, 5.7683804],
          [103.0357191, 5.7681551],
          [103.0352662, 5.76789],
          [103.0351463, 5.7679828],
          [103.0349731, 5.7680292],
          [103.0348599, 5.7681419],
          [103.0348599, 5.7683539],
          [103.0347333, 5.7686322],
          [103.0345801, 5.768884],
          [103.034347, 5.7689702],
        ],
      ],
      [
        [
          [102.9884765, 5.7839],
          [102.9884632, 5.7843241],
          [102.9888162, 5.7847747],
          [102.9893357, 5.7848409],
          [102.9897154, 5.7850795],
          [102.9901283, 5.7851458],
          [102.9902749, 5.7856759],
          [102.9910874, 5.7862126],
          [102.9912606, 5.786597],
          [102.991194, 5.7867693],
          [102.9912806, 5.7870078],
          [102.9911274, 5.7870741],
          [102.991134, 5.7872066],
          [102.9912939, 5.7872795],
          [102.9914737, 5.7871801],
          [102.9915337, 5.7872464],
          [102.9914005, 5.7873922],
          [102.9919799, 5.7872729],
          [102.9923263, 5.7872132],
          [102.9925128, 5.7870675],
          [102.9926859, 5.7871669],
          [102.9930389, 5.7873656],
          [102.9939914, 5.788459],
          [102.9939581, 5.7891548],
          [102.9941179, 5.7895524],
          [102.994018, 5.7899301],
          [102.9942978, 5.7904337],
          [102.9943244, 5.7910964],
          [102.9941579, 5.791759],
          [102.9943644, 5.7921897],
          [102.9944443, 5.7927331],
          [102.994704, 5.7929518],
          [102.9948839, 5.7932632],
          [102.9950371, 5.7937005],
          [102.9948772, 5.793906],
          [102.9948772, 5.7944493],
          [102.9950104, 5.7949331],
          [102.9949039, 5.7950855],
          [102.9950571, 5.7955692],
          [102.995077, 5.7958873],
          [102.994744, 5.7962782],
          [102.994704, 5.7967884],
          [102.994451, 5.7970668],
          [102.9945242, 5.7976896],
          [102.9947107, 5.7980475],
          [102.9946108, 5.7986372],
          [102.9944043, 5.7994059],
          [102.9940646, 5.7997902],
          [102.9936251, 5.8001613],
          [102.9933054, 5.8006582],
          [102.9930523, 5.8010359],
          [102.9928325, 5.8018245],
          [102.9927459, 5.8025202],
          [102.9928391, 5.8034877],
          [102.9929857, 5.803713],
          [102.9928724, 5.8043557],
          [102.992939, 5.8046473],
          [102.9929524, 5.8053696],
          [102.9930789, 5.805681],
          [102.9931322, 5.8059924],
          [102.9935385, 5.8067611],
          [102.993685, 5.8073707],
          [102.9939781, 5.8078345],
          [102.9940846, 5.8082652],
          [102.9946041, 5.8093056],
          [102.9945309, 5.8094447],
          [102.9946041, 5.8096435],
          [102.9945775, 5.8099947],
          [102.9946574, 5.8104784],
          [102.9945375, 5.8112537],
          [102.9945509, 5.8117506],
          [102.9947107, 5.8118765],
          [102.9951503, 5.8119295],
          [102.9952702, 5.8120886],
          [102.9956165, 5.8123139],
          [102.9958696, 5.8128705],
          [102.9959429, 5.8132548],
          [102.9960694, 5.8135993],
          [102.9959162, 5.8136987],
          [102.9958363, 5.8138313],
          [102.995803, 5.8141891],
          [102.9957697, 5.8144607],
          [102.9959362, 5.8146927],
          [102.9958763, 5.8150969],
          [102.9963425, 5.8155408],
          [102.9966955, 5.8155077],
          [102.9969553, 5.815627],
          [102.9973815, 5.8156137],
          [102.9974548, 5.815342],
          [102.9977212, 5.8154745],
          [102.997941, 5.8154547],
          [102.9981808, 5.8155938],
          [102.9983207, 5.8155342],
          [102.9984872, 5.8156468],
          [102.9987469, 5.816051],
          [102.9990333, 5.8165811],
          [102.9989734, 5.8169058],
          [102.9990067, 5.8170913],
          [102.9993997, 5.8175817],
          [102.9995062, 5.8175154],
          [102.9996861, 5.8176546],
          [102.9998992, 5.817628],
          [103.0001523, 5.8177539],
          [103.000119, 5.8175883],
          [103.0002455, 5.8175154],
          [103.0005586, 5.817575],
          [103.000432, 5.8174491],
          [103.000502312547326, 5.817396662406075],
          [103.0005386, 5.8173696],
          [103.0007251, 5.8175022],
          [103.0008716, 5.8173431],
          [103.0010581, 5.8169058],
          [103.0013179, 5.8168925],
          [103.0015776, 5.8166606],
          [103.001571, 5.8165149],
          [103.0017108, 5.8163625],
          [103.0018707, 5.8166076],
          [103.0020905, 5.816389],
          [103.0021171, 5.8161438],
          [103.0022437, 5.816157],
          [103.0024035, 5.8160974],
          [103.0024302, 5.8159052],
          [103.0027565, 5.8160179],
          [103.0027832, 5.8158125],
          [103.0028964, 5.8158125],
          [103.002963, 5.8160378],
          [103.0031162, 5.8159649],
          [103.0032094, 5.8158522],
          [103.0031428, 5.8155077],
          [103.003336, 5.8154414],
          [103.003709, 5.8155011],
          [103.0038155, 5.8156932],
          [103.0039288, 5.8157065],
          [103.0041352, 5.8159185],
          [103.0041685, 5.8157926],
          [103.0043817, 5.8159781],
          [103.0047946, 5.8162167],
          [103.0049412, 5.816442],
          [103.005121, 5.816601],
          [103.0052276, 5.8163426],
          [103.0053608, 5.8163094],
          [103.0054473, 5.816442],
          [103.0055339, 5.8163161],
          [103.0056338, 5.8162432],
          [103.0056938, 5.8161239],
          [103.0056472, 5.8159649],
          [103.0057937, 5.8157595],
          [103.005767, 5.8155276],
          [103.005867, 5.81513],
          [103.0057138, 5.8149378],
          [103.0058403, 5.8148649],
          [103.0059003, 5.8145071],
          [103.0060668, 5.8143216],
          [103.0060335, 5.8141824],
          [103.0061134, 5.8141626],
          [103.0062399, 5.814262],
          [103.0062333, 5.813977],
          [103.0063998, 5.8139174],
          [103.0065463, 5.8134801],
          [103.0068327, 5.8130295],
          [103.0070259, 5.8128705],
          [103.0074122, 5.8128506],
          [103.0087309, 5.8128307],
          [103.0097034, 5.8133608],
          [103.0096501, 5.8137186],
          [103.0096701, 5.8139638],
          [103.0099098, 5.8142951],
          [103.0099498, 5.8146595],
          [103.0098166, 5.8151234],
          [103.0097367, 5.8156071],
          [103.0098233, 5.8165281],
          [103.0099698, 5.8167733],
          [103.0100031, 5.8171311],
          [103.0102562, 5.8173232],
          [103.0103428, 5.817575],
          [103.0105959, 5.8174558],
          [103.0105826, 5.8172769],
          [103.010789, 5.817151],
          [103.010809, 5.8169456],
          [103.0109622, 5.8166673],
          [103.011122, 5.8169389],
          [103.011182, 5.8166209],
          [103.011142, 5.8164884],
          [103.0110621, 5.8164486],
          [103.0111687, 5.815998],
          [103.0113685, 5.8158986],
          [103.0113885, 5.815733],
          [103.0116482, 5.8156468],
          [103.0116416, 5.8154878],
          [103.0119479, 5.815448],
          [103.0121411, 5.815077],
          [103.0120678, 5.8149776],
          [103.0120079, 5.8145999],
          [103.0120812, 5.8141096],
          [103.0122343, 5.8139439],
          [103.0123276, 5.8136258],
          [103.0125873, 5.8138048],
          [103.0126739, 5.8136523],
          [103.0125873, 5.8135132],
          [103.0128071, 5.813268],
          [103.0128404, 5.8131156],
          [103.0125807, 5.8129235],
          [103.0126606, 5.812559],
          [103.0126406, 5.8123669],
          [103.0127605, 5.8122277],
          [103.0128338, 5.8118368],
          [103.0128404, 5.8112404],
          [103.012947, 5.8110549],
          [103.0131402, 5.8110549],
          [103.0132401, 5.8104916],
          [103.0134599, 5.8102531],
          [103.01334, 5.8100742],
          [103.0133267, 5.8098754],
          [103.0135331, 5.8097495],
          [103.0135598, 5.8095905],
          [103.0138129, 5.8093652],
          [103.0142991, 5.8092923],
          [103.0143191, 5.8095574],
          [103.0144723, 5.809617],
          [103.0144656, 5.8097959],
          [103.0147187, 5.8097959],
          [103.0146254, 5.809935],
          [103.0148053, 5.810114],
          [103.0149851, 5.8098887],
          [103.015145, 5.8098688],
          [103.0152049, 5.8097098],
          [103.0151649, 5.8093321],
          [103.0152582, 5.8093056],
          [103.0152515, 5.8091333],
          [103.0153981, 5.8090273],
          [103.0153381, 5.8085833],
          [103.015458, 5.8087158],
          [103.0155179, 5.808908],
          [103.0156978, 5.8089014],
          [103.0160175, 5.8084773],
          [103.0163172, 5.8083713],
          [103.0160974, 5.8081725],
          [103.0165037, 5.8080532],
          [103.0168434, 5.8080598],
          [103.0174961, 5.8076821],
          [103.017576, 5.8074171],
          [103.0178025, 5.8072912],
          [103.0179557, 5.8069864],
          [103.017929, 5.8073641],
          [103.0180622, 5.8075098],
          [103.0184086, 5.8075761],
          [103.0187682, 5.80749],
          [103.0186417, 5.8072978],
          [103.0187882, 5.8068008],
          [103.0186484, 5.8065755],
          [103.0187216, 5.8061846],
          [103.0185817, 5.8059593],
          [103.0187483, 5.8059063],
          [103.0187483, 5.8056876],
          [103.0186683, 5.8055617],
          [103.0187349, 5.8053762],
          [103.0187083, 5.8051178],
          [103.0188548, 5.8051178],
          [103.0188348, 5.8050184],
          [103.0189214, 5.8050051],
          [103.0189614, 5.8047798],
          [103.0188348, 5.804634],
          [103.0188282, 5.8044684],
          [103.0189014, 5.8043756],
          [103.0189014, 5.8041503],
          [103.0187882, 5.8039979],
          [103.0188615, 5.8036931],
          [103.0187216, 5.8036268],
          [103.0189681, 5.8030901],
          [103.0188748, 5.8027522],
          [103.0189081, 5.802454],
          [103.0187949, 5.8019769],
          [103.0185351, 5.8016456],
          [103.0180023, 5.8014534],
          [103.0175161, 5.8012215],
          [103.0174894, 5.8009299],
          [103.017243, 5.8007444],
          [103.0166502, 5.8007974],
          [103.0160841, 5.8007709],
          [103.0157977, 5.800645],
          [103.0157244, 5.8004131],
          [103.0155379, 5.8001944],
          [103.015498, 5.7998432],
          [103.0156645, 5.7997107],
          [103.0157577, 5.799545],
          [103.0165303, 5.7992203],
          [103.0174828, 5.7989685],
          [103.017576, 5.7986041],
          [103.0167235, 5.7979613],
          [103.0168034, 5.7975439],
          [103.0167834, 5.7967752],
          [103.0166169, 5.7960927],
          [103.0162373, 5.7957547],
          [103.0160108, 5.7956818],
          [103.0161307, 5.7955692],
          [103.0160708, 5.795483],
          [103.0155912, 5.79543],
          [103.0156178, 5.7951584],
          [103.015498, 5.7949993],
          [103.015498, 5.7947276],
          [103.0150251, 5.7944162],
          [103.0152981, 5.7944493],
          [103.0153514, 5.7942505],
          [103.0152249, 5.7939921],
          [103.0149651, 5.7938463],
          [103.0147986, 5.7934421],
          [103.0146987, 5.7932367],
          [103.014732, 5.7929849],
          [103.0143524, 5.792574],
          [103.0141192, 5.7926072],
          [103.0137596, 5.7921632],
          [103.0135864, 5.7916994],
          [103.0136797, 5.7910036],
          [103.0135997, 5.7907186],
          [103.0136397, 5.7904337],
          [103.0135531, 5.7902946],
          [103.013673, 5.7902482],
          [103.0136464, 5.7900494],
          [103.0134932, 5.7896717],
          [103.0135931, 5.7893138],
          [103.0133933, 5.7891084],
          [103.0134399, 5.7887638],
          [103.01336, 5.7885452],
          [103.0131268, 5.7884193],
          [103.0131601, 5.7879488],
          [103.0132867, 5.7875976],
          [103.0133799, 5.786862],
          [103.0135065, 5.7866434],
          [103.0133799, 5.7865506],
          [103.0139194, 5.786047],
          [103.0144123, 5.7857024],
          [103.0148586, 5.7855102],
          [103.0156978, 5.785265],
          [103.0158909, 5.7855699],
          [103.0162839, 5.7856693],
          [103.0165969, 5.7858018],
          [103.01689, 5.7856428],
          [103.0171764, 5.7854506],
          [103.017243, 5.7849138],
          [103.0180955, 5.7847879],
          [103.019401, 5.7847946],
          [103.0204933, 5.7851193],
          [103.0210395, 5.785212],
          [103.0209862, 5.7855831],
          [103.0212925, 5.7859608],
          [103.0216522, 5.7864048],
          [103.0219852, 5.7868422],
          [103.0221118, 5.7871403],
          [103.021932, 5.7874916],
          [103.0218121, 5.7877699],
          [103.0219386, 5.7883132],
          [103.021892, 5.7887837],
          [103.0221451, 5.7892608],
          [103.0226979, 5.7897976],
          [103.0232241, 5.7906126],
          [103.0237569, 5.7908313],
          [103.0237103, 5.7910765],
          [103.0238701, 5.791653],
          [103.0240833, 5.7917723],
          [103.0241499, 5.7918716],
          [103.0242897, 5.7920307],
          [103.0242098, 5.7922096],
          [103.0244563, 5.7924481],
          [103.0245495, 5.7925939],
          [103.0247493, 5.7928722],
          [103.0251956, 5.7932698],
          [103.0256019, 5.7933758],
          [103.0255752, 5.7935216],
          [103.0257217, 5.7937138],
          [103.0259615, 5.7938662],
          [103.0262013, 5.7938066],
          [103.0263212, 5.7936409],
          [103.0264011, 5.793621],
          [103.0264477, 5.7934951],
          [103.026541, 5.7935349],
          [103.026561, 5.7936939],
          [103.0270005, 5.7936873],
          [103.0272736, 5.793462],
          [103.0273269, 5.7935945],
          [103.0275733, 5.7937403],
          [103.0276733, 5.793621],
          [103.0277865, 5.7937005],
          [103.0280795, 5.7933758],
          [103.0280396, 5.7935548],
          [103.0282594, 5.7935349],
          [103.0282461, 5.793674],
          [103.0284126, 5.7936542],
          [103.0287789, 5.7931174],
          [103.0290053, 5.7930975],
          [103.0291053, 5.792965],
          [103.0291053, 5.7928391],
          [103.0292185, 5.7927861],
          [103.0292251, 5.7926536],
          [103.0294183, 5.7926006],
          [103.0295781, 5.7923753],
          [103.0295715, 5.7922295],
          [103.029718, 5.7921168],
          [103.0297513, 5.7918716],
          [103.0298712, 5.7917656],
          [103.0300044, 5.7914674],
          [103.0299578, 5.7913548],
          [103.0299245, 5.7911494],
          [103.0299978, 5.7912554],
          [103.0301176, 5.7912421],
          [103.0301776, 5.7909108],
          [103.0305905, 5.7909174],
          [103.0310301, 5.7910632],
          [103.0313365, 5.7912024],
          [103.0314497, 5.7920572],
          [103.0314964, 5.7927066],
          [103.031543, 5.7929186],
          [103.031856, 5.7930445],
          [103.0320358, 5.7929981],
          [103.0321358, 5.7928457],
          [103.0319293, 5.7926138],
          [103.031916, 5.792468],
          [103.0320358, 5.792574],
          [103.0321691, 5.7926204],
          [103.0323689, 5.7925608],
          [103.032269, 5.7921168],
          [103.0321224, 5.7919445],
          [103.0317561, 5.7913283],
          [103.0314564, 5.7910831],
          [103.031543, 5.7908247],
          [103.0315563, 5.7905927],
          [103.0317961, 5.7903608],
          [103.0320625, 5.7901952],
          [103.0322756, 5.7904403],
          [103.032622, 5.7903542],
          [103.0329816, 5.7905596],
          [103.0331282, 5.7907253],
          [103.0341072, 5.7912355],
          [103.0342405, 5.7912024],
          [103.0342205, 5.7910831],
          [103.0340939, 5.7909903],
          [103.0342604, 5.7908512],
          [103.0342738, 5.790997],
          [103.0345335, 5.7911494],
          [103.03476, 5.79105],
          [103.0347533, 5.7909439],
          [103.0348799, 5.7909506],
          [103.0349331, 5.7907849],
          [103.0348865, 5.7905662],
          [103.0347666, 5.7904138],
          [103.03468, 5.7901289],
          [103.0344669, 5.7897976],
          [103.0346534, 5.7895524],
          [103.0348665, 5.7896518],
          [103.0349931, 5.7896054],
          [103.035446, 5.7895855],
          [103.0356192, 5.7899102],
          [103.0358589, 5.7899235],
          [103.0358989, 5.7900295],
          [103.0361187, 5.7900162],
          [103.0362719, 5.7897379],
          [103.036525, 5.7896982],
          [103.0366249, 5.7899566],
          [103.0366649, 5.7901355],
          [103.0367714, 5.7902946],
          [103.0371844, 5.7902548],
          [103.0371577, 5.7903608],
          [103.0372177, 5.7905066],
          [103.0374641, 5.7906789],
          [103.0376906, 5.7905662],
          [103.0377572, 5.7907717],
          [103.0378438, 5.7908909],
          [103.0378771, 5.7905994],
          [103.0384499, 5.7906391],
          [103.0384432, 5.790447],
          [103.0383233, 5.7902084],
          [103.0383433, 5.7900295],
          [103.0381968, 5.7898903],
          [103.03827, 5.7897379],
          [103.0383833, 5.7897114],
          [103.0383699, 5.7898771],
          [103.0387163, 5.7899699],
          [103.0389494, 5.7900162],
          [103.0390027, 5.7899367],
          [103.0387296, 5.7898042],
          [103.0393157, 5.7898771],
          [103.0392025, 5.7895921],
          [103.0390626, 5.7895325],
          [103.0390426, 5.7894331],
          [103.0392225, 5.7894662],
          [103.0394489, 5.789665],
          [103.0395688, 5.7896915],
          [103.0395755, 5.7894795],
          [103.0398086, 5.7895723],
          [103.0400484, 5.7896717],
          [103.0401483, 5.789718],
          [103.040095, 5.7899168],
          [103.0402881, 5.7900295],
          [103.0405013, 5.7900759],
          [103.0406611, 5.7898108],
          [103.0407011, 5.7899102],
          [103.0406478, 5.7901819],
          [103.0406611, 5.7903608],
          [103.0409342, 5.7906192],
          [103.0412339, 5.7906192],
          [103.041547, 5.7904602],
          [103.0416868, 5.7901289],
          [103.04184, 5.7901355],
          [103.0417201, 5.7905],
          [103.0422796, 5.790606],
          [103.0423262, 5.7904403],
          [103.0420665, 5.7902813],
          [103.042213, 5.7902482],
          [103.0422929, 5.7903211],
          [103.0423795, 5.7903476],
          [103.0425394, 5.7902548],
          [103.0424328, 5.7901819],
          [103.0426526, 5.7900759],
          [103.0430522, 5.7900759],
          [103.043332, 5.7899632],
          [103.043958, 5.7893867],
          [103.0438715, 5.7891018],
          [103.0436583, 5.7890421],
          [103.0436184, 5.7889428],
          [103.0434918, 5.7889361],
          [103.0434452, 5.788797],
          [103.0431388, 5.7889428],
          [103.0436716, 5.7883861],
          [103.0437849, 5.7880813],
          [103.0436383, 5.7881409],
          [103.0434185, 5.7879223],
          [103.0430722, 5.7877964],
          [103.0425527, 5.7877102],
          [103.0424128, 5.7874717],
          [103.042233, 5.7875247],
          [103.042233, 5.7874253],
          [103.0420398, 5.7873259],
          [103.0419466, 5.7873723],
          [103.0418733, 5.7872994],
          [103.0419533, 5.7872199],
          [103.0419999, 5.7870675],
          [103.0418933, 5.7868223],
          [103.0417801, 5.7867361],
          [103.0417601, 5.7866102],
          [103.0415736, 5.7865307],
          [103.0412672, 5.7865307],
          [103.0415336, 5.7863982],
          [103.0415336, 5.786312],
          [103.0412939, 5.7862723],
          [103.041114, 5.7862988],
          [103.0411673, 5.7861729],
          [103.0410674, 5.7860867],
          [103.0410341, 5.7858283],
          [103.0409808, 5.7855301],
          [103.0408476, 5.7853247],
          [103.0406811, 5.7852319],
          [103.0404347, 5.785053],
          [103.0406545, 5.7850464],
          [103.0408676, 5.7851723],
          [103.041134, 5.7851458],
          [103.0410741, 5.7849735],
          [103.0407477, 5.7847614],
          [103.0402615, 5.7847018],
          [103.0399418, 5.7843837],
          [103.039682, 5.7843572],
          [103.0398086, 5.7841518],
          [103.0396288, 5.7839],
          [103.0394223, 5.7838602],
          [103.0395155, 5.7837144],
          [103.039409, 5.7836018],
          [103.0394889, 5.7834693],
          [103.0394356, 5.783297],
          [103.039329, 5.7832042],
          [103.0389227, 5.783297],
          [103.0385364, 5.7830452],
          [103.0381701, 5.7828994],
          [103.0383833, 5.7827337],
          [103.0384432, 5.7825084],
          [103.0382234, 5.7823891],
          [103.0383033, 5.7822036],
          [103.0381302, 5.7820711],
          [103.0380835, 5.7819186],
          [103.0377638, 5.7817662],
          [103.0379237, 5.7817],
          [103.0380436, 5.7815674],
          [103.0380302, 5.7813156],
          [103.0376706, 5.7810837],
          [103.0376573, 5.7808783],
          [103.0375307, 5.7809578],
          [103.0373908, 5.7808518],
          [103.036878, 5.7806331],
          [103.0365316, 5.7806795],
          [103.0363185, 5.7806397],
          [103.0362985, 5.7803548],
          [103.0359255, 5.7803945],
          [103.035839, 5.7801295],
          [103.0354993, 5.7799638],
          [103.0355259, 5.779553],
          [103.0354593, 5.7792017],
          [103.0351396, 5.7788505],
          [103.0350663, 5.778559],
          [103.0348266, 5.7782343],
          [103.0343337, 5.7778897],
          [103.033974, 5.7778102],
          [103.0337609, 5.7776644],
          [103.0335811, 5.7773264],
          [103.0334612, 5.7764782],
          [103.0334212, 5.7757559],
          [103.0335544, 5.7749342],
          [103.0338875, 5.7740462],
          [103.0342072, 5.7734167],
          [103.0344602, 5.7732775],
          [103.0345935, 5.7734233],
          [103.0350131, 5.7734565],
          [103.0352528, 5.7735956],
          [103.0356192, 5.7735824],
          [103.0359056, 5.7734034],
          [103.0363252, 5.7732974],
          [103.0365516, 5.7729727],
          [103.0364584, 5.7727474],
          [103.0361254, 5.7725751],
          [103.0356258, 5.7723498],
          [103.0353328, 5.7723034],
          [103.0352528, 5.7725221],
          [103.0351796, 5.772754],
          [103.0349331, 5.7729661],
          [103.0346334, 5.7729131],
          [103.0342338, 5.7727275],
          [103.0338408, 5.7724227],
          [103.0334012, 5.7718727],
          [103.0330349, 5.7711835],
          [103.032975, 5.77066],
          [103.0329816, 5.7702757],
          [103.0332147, 5.7700702],
          [103.0334146, 5.7698317],
          [103.0338142, 5.7698582],
          [103.0337676, 5.7700371],
          [103.0338941, 5.7700437],
          [103.0339541, 5.7698979],
          [103.0341405, 5.7698515],
          [103.0343071, 5.76956],
          [103.0343803, 5.7693148],
          [103.0340873, 5.7692419],
          [103.0341472, 5.7690298],
          [103.0337476, 5.7688973],
          [103.0338741, 5.7683407],
          [103.0338208, 5.7680888],
          [103.0338208, 5.7676581],
          [103.0338608, 5.7673732],
          [103.0338009, 5.7671213],
          [103.0333879, 5.7668894],
          [103.0331948, 5.766949],
          [103.0331615, 5.7672075],
          [103.0330016, 5.7670816],
          [103.0328218, 5.7671677],
          [103.0325221, 5.7672141],
          [103.0320758, 5.7670948],
          [103.0320159, 5.7668761],
          [103.0318227, 5.766843],
          [103.0317161, 5.766949],
          [103.031563, 5.7670021],
          [103.0315496, 5.7671346],
          [103.0316762, 5.7672804],
          [103.0316629, 5.7676647],
          [103.031563, 5.7678503],
          [103.031346, 5.7678943],
          [103.0311965, 5.7679246],
          [103.0310235, 5.7679298],
          [103.0307237, 5.7678105],
          [103.0302841, 5.7674726],
          [103.0299978, 5.7670551],
          [103.0298845, 5.7667304],
          [103.0298512, 5.7663129],
          [103.0299245, 5.7660809],
          [103.0300977, 5.7659219],
          [103.0303907, 5.7659418],
          [103.0310301, 5.7655773],
          [103.0312166, 5.7651996],
          [103.0311433, 5.7645303],
          [103.0310434, 5.7642387],
          [103.0310834, 5.763808],
          [103.0309302, 5.7635959],
          [103.0309435, 5.7634567],
          [103.0309102, 5.7633043],
          [103.0310767, 5.7633441],
          [103.0314098, 5.7633573],
          [103.0313565, 5.7628404],
          [103.0311567, 5.7627676],
          [103.0307371, 5.7626218],
          [103.0305372, 5.7627477],
          [103.0305106, 5.7630525],
          [103.0303108, 5.7629929],
          [103.0302708, 5.7629266],
          [103.0301709, 5.7629862],
          [103.0300577, 5.7631188],
          [103.0300244, 5.7634104],
          [103.0299378, 5.7637748],
          [103.030071, 5.7640465],
          [103.030071, 5.7641923],
          [103.0299711, 5.764358],
          [103.0299311, 5.764517],
          [103.0300577, 5.7649875],
          [103.0299711, 5.7651598],
          [103.0296581, 5.7652857],
          [103.0289454, 5.7653785],
          [103.0284658, 5.7653454],
          [103.027953, 5.7651664],
          [103.0270405, 5.7646827],
          [103.0258283, 5.7638411],
          [103.0245495, 5.7625422],
          [103.024423, 5.7623766],
          [103.0237303, 5.7615946],
          [103.023677, 5.7609054],
          [103.0235704, 5.7603156],
          [103.0232574, 5.7600572],
          [103.0230043, 5.7596463],
          [103.0229577, 5.7590565],
          [103.0227312, 5.7585662],
          [103.0221917, 5.7580161],
          [103.0215923, 5.757433],
          [103.0208263, 5.7564986],
          [103.0209129, 5.7563197],
          [103.0209329, 5.7561871],
          [103.0208986, 5.7559655],
          [103.0205039, 5.7558214],
          [103.0203623, 5.7557251],
          [103.0202335, 5.7555509],
          [103.0194276, 5.7545768],
          [103.0192411, 5.7539671],
          [103.0192678, 5.753702],
          [103.0194809, 5.7533641],
          [103.0195542, 5.753099],
          [103.0196807, 5.7529466],
          [103.0195875, 5.7526815],
          [103.019421, 5.7522706],
          [103.0192145, 5.7521779],
          [103.0190879, 5.7519592],
          [103.0188149, 5.751767],
          [103.018282, 5.751714],
          [103.0181755, 5.7516344],
          [103.0181555, 5.7514356],
          [103.0179956, 5.7514224],
          [103.0174495, 5.75127],
          [103.0173762, 5.7510977],
          [103.0170165, 5.7509386],
          [103.0169433, 5.7511507],
          [103.0167901, 5.7512368],
          [103.0160841, 5.7511242],
          [103.0153847, 5.7509718],
          [103.0150983, 5.7507398],
          [103.015105, 5.7505278],
          [103.0149718, 5.7503488],
          [103.014732, 5.7503157],
          [103.0146654, 5.7501368],
          [103.0145389, 5.7501169],
          [103.014066, 5.75015],
          [103.013653, 5.7500572],
          [103.0136197, 5.7497855],
          [103.0132467, 5.7497259],
          [103.0131601, 5.7495867],
          [103.0125407, 5.7495602],
          [103.0125474, 5.749759],
          [103.0122876, 5.7500042],
          [103.0118281, 5.7501301],
          [103.0116749, 5.7503753],
          [103.0114484, 5.7504151],
          [103.0113019, 5.7505675],
          [103.0109755, 5.7505543],
          [103.0108356, 5.7502892],
          [103.0106492, 5.7502958],
          [103.0103095, 5.7501235],
          [103.0098699, 5.7502163],
          [103.0096234, 5.7504946],
          [103.0094037, 5.7508127],
          [103.0090307, 5.750932],
          [103.008202, 5.7510181],
          [103.008162, 5.7509055],
          [103.0073026, 5.751097],
          [103.0070991, 5.7509916],
          [103.0068927, 5.7511706],
          [103.006513, 5.7512567],
          [103.0063532, 5.7513495],
          [103.0059069, 5.7517073],
          [103.0056738, 5.7517869],
          [103.0055406, 5.7522375],
          [103.0053408, 5.7523369],
          [103.0052409, 5.7529333],
          [103.0056538, 5.7532514],
          [103.0056538, 5.7533641],
          [103.0060201, 5.7536159],
          [103.0059003, 5.7540002],
          [103.0059535, 5.7542521],
          [103.005867, 5.7545304],
          [103.0058603, 5.7547623],
          [103.0057471, 5.7550672],
          [103.0055672, 5.7556172],
          [103.0056405, 5.756048],
          [103.0054207, 5.7561805],
          [103.0053736, 5.7562319],
          [103.0052142, 5.7564058],
          [103.0050144, 5.7566311],
          [103.0049451, 5.756769],
          [103.0048945, 5.7568697],
          [103.0051743, 5.7574528],
          [103.0051343, 5.7575788],
          [103.0054007, 5.7582679],
          [103.0053541, 5.7584137],
          [103.0053741, 5.7586059],
          [103.0053075, 5.758924],
          [103.0052076, 5.7591427],
          [103.0048013, 5.7592222],
          [103.004708, 5.7591559],
          [103.0045149, 5.7592355],
          [103.0045149, 5.7593481],
          [103.0046414, 5.7595005],
          [103.0046148, 5.7598849],
          [103.0037223, 5.7596331],
          [103.0037489, 5.7594873],
          [103.0038888, 5.7595005],
          [103.0038155, 5.7592553],
          [103.0034759, 5.7594409],
          [103.0032228, 5.7593084],
          [103.003003, 5.7590102],
          [103.0028098, 5.7585264],
          [103.0026966, 5.758374],
          [103.0028031, 5.7581155],
          [103.0029697, 5.7582348],
          [103.0030829, 5.7581023],
          [103.0028165, 5.757698],
          [103.0025168, 5.7576053],
          [103.0019639, 5.7576649],
          [103.0017108, 5.7575059],
          [103.0016642, 5.757148],
          [103.0014378, 5.7569558],
          [103.0012846, 5.7564257],
          [103.0014511, 5.7561076],
          [103.0013911, 5.7556967],
          [103.0011114, 5.7553985],
          [103.000865, 5.75516],
          [103.0006452, 5.7545039],
          [103.0005919, 5.7539406],
          [103.0003188, 5.7536689],
          [103.0002855, 5.7533707],
          [102.9998659, 5.7536888],
          [102.9995995, 5.7540798],
          [102.999497687452674, 5.754004281383813],
          [102.9992332, 5.7538081],
          [102.999, 5.7535695],
          [102.9988535, 5.7532912],
          [102.9987936, 5.7529002],
          [102.9989601, 5.7525357],
          [102.9996661, 5.7522574],
          [102.9997527, 5.7520652],
          [102.9996061, 5.7518995],
          [102.9997194, 5.751661],
          [102.9995995, 5.7514621],
          [102.999497687452674, 5.7514621],
          [102.999413, 5.7514621],
          [102.9993597, 5.7513429],
          [102.9993664, 5.7511507],
          [102.9989734, 5.7505808],
          [102.9989867, 5.7503952],
          [102.9988801, 5.7502229],
          [102.9989601, 5.7497259],
          [102.9991732, 5.7494012],
          [102.9990999, 5.7492156],
          [102.9989201, 5.7492222],
          [102.9988202, 5.7490566],
          [102.9987003, 5.7490566],
          [102.998647, 5.7493018],
          [102.9984605, 5.7494144],
          [102.998314, 5.7504151],
          [102.9980742, 5.7506802],
          [102.9978278, 5.7517802],
          [102.997941, 5.7522242],
          [102.9979077, 5.7529068],
          [102.9975414, 5.7538147],
          [102.9971085, 5.7547425],
          [102.9968154, 5.7556702],
          [102.9963092, 5.7562534],
          [102.9957031, 5.757148],
          [102.9955366, 5.7577245],
          [102.9954567, 5.7580691],
          [102.9955433, 5.7585662],
          [102.9956365, 5.7592222],
          [102.9955766, 5.7601301],
          [102.9957631, 5.7610247],
          [102.996216, 5.7621977],
          [102.9961494, 5.7632381],
          [102.9963492, 5.7642917],
          [102.9963425, 5.7648417],
          [102.9957497, 5.7660279],
          [102.9956965, 5.766505],
          [102.9958963, 5.7673135],
          [102.9959962, 5.7682611],
          [102.9959695, 5.7687913],
          [102.9958163, 5.7689437],
          [102.9954767, 5.7695202],
          [102.9950504, 5.7710841],
          [102.9948239, 5.7716871],
          [102.9945842, 5.7718528],
          [102.9940713, 5.7728733],
          [102.9938848, 5.7729528],
          [102.9936051, 5.7733372],
          [102.9935318, 5.7738276],
          [102.993292, 5.7739336],
          [102.9929723, 5.7745499],
          [102.9927192, 5.7750005],
          [102.9922796, 5.774974],
          [102.9919866, 5.7750667],
          [102.9914871, 5.7757692],
          [102.9913538, 5.7764451],
          [102.9914538, 5.77673],
          [102.9914005, 5.7768692],
          [102.991214, 5.7769421],
          [102.9910208, 5.7771806],
          [102.9910408, 5.7775385],
          [102.9912939, 5.7780355],
          [102.9912539, 5.7786385],
          [102.9912473, 5.7790626],
          [102.9910408, 5.7792481],
          [102.9909342, 5.7796656],
          [102.9904946, 5.7801228],
          [102.9905146, 5.7805204],
          [102.990095, 5.781415],
          [102.9895822, 5.7821042],
          [102.988683, 5.7833632],
          [102.9886564, 5.7836946],
          [102.9884765, 5.7839],
        ],
      ],
      [
        [
          [102.8896447, 5.7986128],
          [102.8889967, 5.8004861],
          [102.8899964, 5.803237],
          [102.8920383, 5.8034945],
          [102.8929117, 5.8021267],
          [102.8945746, 5.8007626],
          [102.8953258, 5.7996194],
          [102.8970358, 5.7988541],
          [102.8984134, 5.7976693],
          [102.8986022, 5.7973314],
          [102.8986081, 5.7969925],
          [102.8990237, 5.7965195],
          [102.8992546, 5.7965394],
          [102.8993905, 5.7962133],
          [102.8992746, 5.7960467],
          [102.9001458, 5.7944052],
          [102.9005326, 5.794336],
          [102.9007204, 5.7940927],
          [102.9008078, 5.7941106],
          [102.9008918, 5.7942122],
          [102.901046, 5.7941685],
          [102.901068, 5.7940422],
          [102.9012201, 5.7938597],
          [102.9015253, 5.7943035],
          [102.901771, 5.7942824],
          [102.9023273, 5.7935694],
          [102.903394, 5.7941066],
          [102.9041887, 5.7931542],
          [102.9040259, 5.7929108],
          [102.9041372, 5.7923379],
          [102.9044237, 5.7920555],
          [102.904506, 5.79164],
          [102.9043089, 5.7916718],
          [102.9046501, 5.7912691],
          [102.9046211, 5.7911186],
          [102.9043467, 5.7910805],
          [102.904432, 5.7908107],
          [102.9043719, 5.7906327],
          [102.9045001, 5.7905628],
          [102.9043443, 5.7903981],
          [102.9039881, 5.7903426],
          [102.9036421, 5.7904712],
          [102.9030378, 5.7904221],
          [102.9028766, 5.7902497],
          [102.9029227, 5.7898297],
          [102.9030263, 5.7894132],
          [102.9029085, 5.7890025],
          [102.9029807, 5.7888229],
          [102.9027285, 5.7885982],
          [102.9027365, 5.7884978],
          [102.9026019, 5.7883188],
          [102.9024365, 5.7882812],
          [102.9024324, 5.7880455],
          [102.9023136, 5.7877856],
          [102.9021763, 5.787827],
          [102.9021115, 5.7879931],
          [102.9017565, 5.7883575],
          [102.9015832, 5.7891156],
          [102.9013003, 5.7892186],
          [102.9008789, 5.7891962],
          [102.9007849, 5.7890892],
          [102.9005546, 5.7890406],
          [102.9004707, 5.7890916],
          [102.9000182, 5.7890241],
          [102.8998468, 5.7891943],
          [102.8995365, 5.789145],
          [102.8994348, 5.7889659],
          [102.8995622, 5.7887898],
          [102.8994855, 5.78871],
          [102.8993047, 5.788852],
          [102.8985304, 5.7889125],
          [102.8981216, 5.7892389],
          [102.8978064, 5.7900424],
          [102.8976653, 5.7900662],
          [102.8976981, 5.7905085],
          [102.8976409, 5.7906799],
          [102.8967214, 5.7909249],
          [102.8965242, 5.7909775],
          [102.8963122, 5.791034],
          [102.8953243, 5.7909705],
          [102.8949421, 5.7909606],
          [102.8945159, 5.7908521],
          [102.8934071, 5.790725],
          [102.8931986, 5.7907586],
          [102.8926783, 5.7908424],
          [102.8922647, 5.7910474],
          [102.8920906, 5.7912534],
          [102.8920041, 5.7915135],
          [102.891997, 5.7915349],
          [102.8918683, 5.7917484],
          [102.8917246, 5.7918292],
          [102.8915751, 5.7920457],
          [102.8919109, 5.7921628],
          [102.8920668, 5.7923963],
          [102.8925477, 5.7933386],
          [102.8928323, 5.7940701],
          [102.8928398, 5.7940895],
          [102.8929283, 5.7946282],
          [102.8928183, 5.7959307],
          [102.8926011, 5.7965779],
          [102.8924214, 5.7966136],
          [102.8907648, 5.7980119],
          [102.8896447, 5.7986128],
        ],
      ],
      [
        [
          [102.6103177, 5.8276108],
          [102.6097433, 5.8274999],
          [102.6094542, 5.8275322],
          [102.6092641, 5.8276808],
          [102.609149173001725, 5.828007681156677],
          [102.609344, 5.8285958],
          [102.609197108911673, 5.829032556169285],
          [102.6092254, 5.8290461],
          [102.6095373, 5.829142],
          [102.6101868, 5.8294316],
          [102.6108471, 5.8302052],
          [102.6109948, 5.8305673],
          [102.6110283, 5.8308199],
          [102.6107472, 5.8310637],
          [102.610569499452694, 5.831425573315959],
          [102.6108705, 5.8316569],
          [102.6118775, 5.8320319],
          [102.6122267, 5.8318757],
          [102.6127846, 5.832324],
          [102.613364, 5.8325588],
          [102.613827021646912, 5.832227994003278],
          [102.6138333, 5.8321954],
          [102.613924331601879, 5.832158470867891],
          [102.6145785, 5.8316911],
          [102.6148142, 5.8317629],
          [102.6149141, 5.8316635],
          [102.6151651, 5.8315617],
          [102.6158334, 5.8302188],
          [102.6161749, 5.8292715],
          [102.6157801, 5.8284891],
          [102.6152227, 5.8280258],
          [102.615025, 5.8279918],
          [102.6147902, 5.8280911],
          [102.614243, 5.8287559],
          [102.613635, 5.8294483],
          [102.613179, 5.8295282],
          [102.6127345, 5.8294342],
          [102.6122181, 5.8292149],
          [102.61151, 5.8286172],
          [102.6109971, 5.8280782],
          [102.6103177, 5.8276108],
        ],
      ],
    ],
  },
};
