import React, { useState } from "react";
import { useMapEvents } from "react-leaflet";
import Malaysia from "../malaysia";
import InfoDrawer from "../drawer";

const Zone = ({ theme, setZoom, translate }) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
    },
  });

  const [infoDrawer, setInfoDrawer] = useState(false);
  const [properties, setProperties] = useState("");

  const openInfoDrawer = (e) => {
    setInfoDrawer(true);
    setProperties(e.target.feature.properties);
  };

  return (
    <div>
      <InfoDrawer
        properties={properties}
        open={infoDrawer}
        close={() => {
          setInfoDrawer(!infoDrawer);
        }}
        translate={translate}
      />
      <Malaysia theme={theme} openDrawer={openInfoDrawer} />
    </div>
  );
};

export default Zone;
