import { Collapse, Grid } from "@material-ui/core";
import React, { useState } from "react";

import styled from "styled-components";
import { ConfirmedCaseMalaysia } from "../../data";
import format from "../../utils/format";

const OverlayContainer = styled.div`
  width: calc(25vw - 2rem);
  
  min-width: 280px;
  background: ${({ theme }) => theme.body};
  position: fixed;
  bottom 1rem;
  right: 1rem;
  z-index:1000;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 10px;
  
  @media  (max-width: 600px){
    width: calc(100vw - 2rem);
  }
`;

const Cases = styled.div`
  border-radius: 10px;
  font-family: "Staatliches", cursive;
  font-size: 24px;
  background: red;
  cursor: pointer;
  color: white;
  padding: 0.5rem 0.75rem;
  font-weight: bold;
`;

const Date = styled.span`
  font-size: 12px;
  font-family: "Raleway", sans-serif;
`;

const DailyCaseContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
`;

const DailyCase = styled(Grid)`
  width: auto;
  position: relative;
  padding: 0.5rem;
`;

const DailyCaseCounter = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  font-size: 24px;
  height: 100%;
  padding: 0.5rem;
`;

const DailyCaseCategory = styled.div`
  padding-bottom: 5px 10px;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
`;

const Disclaimer = styled.div`
  padding: 0.5rem;
  font-size: 12px;

  text-align: center;
`;

const Overlay = (props) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <OverlayContainer>
      <Cases onClick={handleClick}>
        {props.translate("case.title")}{" "}
        <Date>({props.translate("case.asof")} 24/7/2021)</Date>
      </Cases>{" "}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <DailyCaseContainer>
          <DailyCase
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <DailyCaseCounter>
              {format.numberWithCommas(ConfirmedCaseMalaysia.new)}
            </DailyCaseCounter>
            <DailyCaseCategory>{props.translate("case.new")}</DailyCaseCategory>
          </DailyCase>
          <DailyCase
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <DailyCaseCounter>
              {format.numberWithCommas(ConfirmedCaseMalaysia.recover)}
            </DailyCaseCounter>
            <DailyCaseCategory>
              {props.translate("case.recover")}
            </DailyCaseCategory>
          </DailyCase>
          <DailyCase
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <DailyCaseCounter>
              {format.numberWithCommas(ConfirmedCaseMalaysia.death)}
            </DailyCaseCounter>
            <DailyCaseCategory>
              {props.translate("case.death")}
            </DailyCaseCategory>
          </DailyCase>
          <DailyCase
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <DailyCaseCounter>
              {format.numberWithCommas(ConfirmedCaseMalaysia.active)}
            </DailyCaseCounter>
            <DailyCaseCategory>
              {props.translate("case.active")}
            </DailyCaseCategory>
          </DailyCase>
        </DailyCaseContainer>
        <Disclaimer>
          {props.translate("general.source")}:{" "}
          <a
            target="_blank"
            href="https://www.facebook.com/kementeriankesihatanmalaysia"
          >
            KEMENTERIAN KESIHATAN MALAYSIA
          </a>{" "}
        </Disclaimer>
      </Collapse>
    </OverlayContainer>
  );
};

export default Overlay;
