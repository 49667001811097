export const putrajaya = {
  type: "Feature",
  properties: {
    gid: 12,
    id: 4443881,
    country: "MYS",
    name: "Putrajaya",
    enname: "Putrajaya",
    locname: "Putrajaya",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q182378",
    wikimedia: "en:Putrajaya",
    timestamp: "2019-10-31 23:10:02",
    note: null,
    path: "0,2108121,4443881",
    rpath: "4443881,2108121,0",
    iso3166_2: "MY-16",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [101.6596875, 2.8991418],
          [101.6603622, 2.902118],
          [101.6630612, 2.9042519],
          [101.6637921, 2.9088005],
          [101.6640482, 2.9089632],
          [101.6643094, 2.9091292],
          [101.6644106, 2.9091935],
          [101.664748, 2.9082389],
          [101.6657601, 2.9093059],
          [101.6683465, 2.9094743],
          [101.6695273, 2.9103166],
          [101.6699209, 2.9113836],
          [101.6693024, 2.9145844],
          [101.6698647, 2.9153706],
          [101.6720013, 2.9157075],
          [101.6723387, 2.9190768],
          [101.6722262, 2.9204806],
          [101.6715515, 2.9216599],
          [101.6725636, 2.922783],
          [101.6712704, 2.9247484],
          [101.6706081, 2.9259429],
          [101.6694153, 2.9264988],
          [101.6694153, 2.9274915],
          [101.6685804, 2.9278885],
          [101.6681828, 2.9286033],
          [101.6682623, 2.9297548],
          [101.6674672, 2.930946],
          [101.6687394, 2.9371799],
          [101.6685804, 2.9390461],
          [101.6672286, 2.9414682],
          [101.6648431, 2.9440094],
          [101.6659166, 2.9441683],
          [101.667984, 2.9453594],
          [101.6710455, 2.9458756],
          [101.6722382, 2.9460742],
          [101.6738286, 2.9460344],
          [101.6747033, 2.9467889],
          [101.6747033, 2.9496477],
          [101.6743852, 2.9507198],
          [101.6696937, 2.9550874],
          [101.669431, 2.9550936],
          [101.6680238, 2.9551271],
          [101.664799, 2.9574323],
          [101.6642467, 2.9578271],
          [101.6629347, 2.9612418],
          [101.6628154, 2.9643389],
          [101.6640081, 2.9654506],
          [101.6648828, 2.9658874],
          [101.6653202, 2.9667609],
          [101.667825, 2.9688256],
          [101.6688985, 2.9694212],
          [101.6697732, 2.9694212],
          [101.6706479, 2.9704932],
          [101.6716816, 2.97093],
          [101.672278, 2.9706521],
          [101.6735503, 2.9713271],
          [101.6757768, 2.9711285],
          [101.6762936, 2.9717241],
          [101.6825358, 2.9708109],
          [101.6846032, 2.9704138],
          [101.6904875, 2.9721609],
          [101.6930321, 2.9741461],
          [101.6971272, 2.976052],
          [101.7026537, 2.9770843],
          [101.7058742, 2.9789108],
          [101.7062125, 2.979564],
          [101.707067, 2.9812136],
          [101.7071862, 2.9824445],
          [101.7082995, 2.9823651],
          [101.7077031, 2.9797049],
          [101.7049597, 2.976449],
          [101.7005067, 2.9731138],
          [101.6995525, 2.9700168],
          [101.6997911, 2.9678727],
          [101.701779, 2.9679124],
          [101.7030911, 2.9667212],
          [101.7033694, 2.9629889],
          [101.7084983, 2.9630286],
          [101.7084188, 2.9617977],
          [101.7104465, 2.9611227],
          [101.7125934, 2.9618374],
          [101.713985, 2.9639815],
          [101.7144223, 2.9632271],
          [101.7149392, 2.9635845],
          [101.7151778, 2.9549683],
          [101.7308825, 2.9548492],
          [101.732274, 2.9531418],
          [101.7326821, 2.9292292],
          [101.7074963, 2.9292504],
          [101.707218, 2.9132882],
          [101.7003795, 2.9130896],
          [101.7003, 2.9095556],
          [101.6975964, 2.9095159],
          [101.6975168, 2.9062996],
          [101.7010934, 2.9054532],
          [101.7016898, 2.901681],
          [101.7008946, 2.8999736],
          [101.6974356, 2.898822],
          [101.697197, 2.8944144],
          [101.6956464, 2.8934614],
          [101.6925452, 2.8931041],
          [101.6906766, 2.8935806],
          [101.6880922, 2.8920717],
          [101.6878537, 2.8900465],
          [101.6867007, 2.885083],
          [101.6849513, 2.885083],
          [101.6848718, 2.883177],
          [101.684673, 2.8821843],
          [101.6835597, 2.8818666],
          [101.6830429, 2.8823431],
          [101.6820489, 2.8818666],
          [101.6806176, 2.8819857],
          [101.6804586, 2.8805165],
          [101.6792658, 2.8803577],
          [101.6792658, 2.8798812],
          [101.6782718, 2.8795635],
          [101.6764827, 2.8815092],
          [101.675069, 2.8813813],
          [101.674338, 2.8768326],
          [101.671864, 2.8803424],
          [101.6711331, 2.8833749],
          [101.6655633, 2.879796],
          [101.66278, 2.8854397],
          [101.6626113, 2.8870683],
          [101.6633985, 2.8879668],
          [101.6637359, 2.8893707],
          [101.66278, 2.8900445],
          [101.6618804, 2.8912238],
          [101.6611215, 2.8939641],
          [101.6596875, 2.8991418],
        ],
      ],
    ],
  },
};
