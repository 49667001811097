export const sarawak = {
  type: "Feature",
  properties: {
    gid: 14,
    id: 3879784,
    country: "MYS",
    name: "Sarawak",
    enname: "Sarawak",
    locname: "Sarawak",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q170462",
    wikimedia: "en:Sarawak",
    timestamp: "2019-12-30 19:12:02",
    note: null,
    path: "0,2108121,3879784",
    rpath: "3879784,2108121,0",
    iso3166_2: "MY-13",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [113.9488735, 4.5947939],
          [113.9515606, 4.5947971],
          [113.9533607, 4.5945888],
          [113.9539029, 4.5943571],
          [113.9545175, 4.5943402],
          [113.9547473, 4.5946261],
          [113.9547059, 4.5949705],
          [113.9544929, 4.5953156],
          [113.95458, 4.5956308],
          [113.9548665, 4.5958017],
          [113.9551682, 4.596159],
          [113.9554114, 4.5962154],
          [113.9555956, 4.5958417],
          [113.9553802, 4.5955988],
          [113.9553504, 4.5952834],
          [113.9556494, 4.5950096],
          [113.9557051, 4.5946508],
          [113.955833, 4.5944782],
          [113.9566324, 4.594231],
          [113.9572887, 4.5939415],
          [113.9582435, 4.593249],
          [113.9590391, 4.592084],
          [113.9595217, 4.5912644],
          [113.9593895, 4.5904044],
          [113.9590853, 4.5894161],
          [113.9587971, 4.5888579],
          [113.9584538, 4.5887876],
          [113.9582549, 4.5890753],
          [113.9582284, 4.5895917],
          [113.958488, 4.59015],
          [113.9586469, 4.5905509],
          [113.9587065, 4.5911531],
          [113.9586225, 4.5915694],
          [113.9583079, 4.591542],
          [113.9581633, 4.5911267],
          [113.9580329, 4.5907113],
          [113.957618, 4.5905982],
          [113.9573053, 4.5910298],
          [113.9569667, 4.5921069],
          [113.9563672, 4.5923102],
          [113.9557977, 4.5928576],
          [113.9551414, 4.5931328],
          [113.9546682, 4.5927619],
          [113.9544231, 4.5922609],
          [113.9542918, 4.5916017],
          [113.9539897, 4.591144],
          [113.9532605, 4.5910753],
          [113.9526624, 4.5916084],
          [113.9521625, 4.5917109],
          [113.9521028, 4.5910944],
          [113.9519299, 4.5907509],
          [113.9517565, 4.5902927],
          [113.9518962, 4.5895176],
          [113.9525505, 4.5887403],
          [113.9532622, 4.5880059],
          [113.9542013, 4.5869837],
          [113.9545504, 4.5849743],
          [113.9544909, 4.5844008],
          [113.9552314, 4.583738],
          [113.956159, 4.5834042],
          [113.9572864, 4.5829693],
          [113.9573987, 4.5824812],
          [113.9567674, 4.5818814],
          [113.9561358, 4.5812242],
          [113.9566757, 4.5804475],
          [113.957502, 4.5797843],
          [113.9583004, 4.5793077],
          [113.958413, 4.578877],
          [113.9575553, 4.5788518],
          [113.9566286, 4.579415],
          [113.9556732, 4.5799497],
          [113.9546335, 4.5808576],
          [113.9536676, 4.5823102],
          [113.9527019, 4.5838488],
          [113.9512309, 4.5875984],
          [113.9509539, 4.589751],
          [113.9508716, 4.5905975],
          [113.9502042, 4.591676],
          [113.9501088, 4.5927951],
          [113.9498275, 4.5939007],
          [113.9494712, 4.5941603],
          [113.9490424, 4.5941621],
          [113.9487433, 4.5944215],
          [113.9488735, 4.5947939],
        ],
      ],
      [
        [
          [115.029249313687529, 4.8109330213128],
          [115.027041364444614, 4.807305346015322],
          [115.0265238, 4.8072293],
          [115.0263418, 4.8075325],
          [115.0263255, 4.8082214],
          [115.0268172, 4.8095072],
          [115.0286114, 4.8109689],
          [115.0290732, 4.8109946],
          [115.029249313687529, 4.8109330213128],
        ],
      ],
      [
        [
          [109.6699283, 1.9728682],
          [109.6700999, 1.9735544],
          [109.6716449, 1.9733829],
          [109.6716449, 1.9723535],
          [109.6699283, 1.9728682],
        ],
      ],
      [
        [
          [111.4012492, 2.76302],
          [111.4015871, 2.7629503],
          [111.4022148, 2.7625806],
          [111.4030248, 2.7618733],
          [111.4033413, 2.7613053],
          [111.4038616, 2.7605284],
          [111.4042211, 2.7597675],
          [111.4045805, 2.7592317],
          [111.4047897, 2.7586423],
          [111.4049613, 2.7577314],
          [111.405074, 2.7567616],
          [111.4051866, 2.755915],
          [111.404897, 2.7550255],
          [111.4044839, 2.7542111],
          [111.4038402, 2.7540664],
          [111.4037651, 2.7548219],
          [111.4031374, 2.7547147],
          [111.4026761, 2.7541736],
          [111.402263, 2.753686],
          [111.4020431, 2.7529787],
          [111.4014637, 2.7518963],
          [111.4013564, 2.7509854],
          [111.4012116, 2.7489707],
          [111.401158, 2.7481991],
          [111.4009058, 2.7472936],
          [111.4006323, 2.7460451],
          [111.4004391, 2.744802],
          [111.400364, 2.7439447],
          [111.4001816, 2.7430284],
          [111.4001495, 2.7421175],
          [111.400069, 2.7414102],
          [111.40001, 2.7405368],
          [111.3998544, 2.7395723],
          [111.3998222, 2.7387847],
          [111.3997149, 2.7376648],
          [111.3995969, 2.7368717],
          [111.3994414, 2.7359072],
          [111.3993019, 2.735141],
          [111.3991356, 2.7343855],
          [111.3987815, 2.733421],
          [111.3985348, 2.7326869],
          [111.3983631, 2.7318081],
          [111.39817, 2.730983],
          [111.3978642, 2.7300292],
          [111.3976496, 2.7293808],
          [111.3974619, 2.7286896],
          [111.3972527, 2.7279341],
          [111.397022, 2.727066],
          [111.3969523, 2.726482],
          [111.3967913, 2.7257961],
          [111.3967055, 2.7251263],
          [111.3965607, 2.7243815],
          [111.3964266, 2.723701],
          [111.3962817, 2.7230258],
          [111.3961798, 2.7224578],
          [111.3959652, 2.7217666],
          [111.3957453, 2.7212415],
          [111.3955039, 2.7207592],
          [111.3952035, 2.7202127],
          [111.3948655, 2.7197733],
          [111.3944632, 2.7195054],
          [111.3941306, 2.719141],
          [111.3937926, 2.7190178],
          [111.3934922, 2.7192482],
          [111.3932669, 2.7196554],
          [111.3929772, 2.7201912],
          [111.3927895, 2.7207967],
          [111.39265, 2.7214183],
          [111.3924944, 2.7219702],
          [111.392414, 2.7226454],
          [111.3923442, 2.7234223],
          [111.3922369, 2.7240439],
          [111.392194, 2.7247351],
          [111.3921672, 2.7254746],
          [111.3920706, 2.7260801],
          [111.3919204, 2.7267927],
          [111.3917595, 2.7273822],
          [111.3915878, 2.7280412],
          [111.3914859, 2.7286574],
          [111.3913089, 2.7292737],
          [111.3911748, 2.7299059],
          [111.3910675, 2.73034],
          [111.3909334, 2.7309508],
          [111.3908261, 2.7315509],
          [111.390633, 2.7321779],
          [111.3905257, 2.7329387],
          [111.3904399, 2.7332549],
          [111.3902897, 2.7338925],
          [111.3901126, 2.7347123],
          [111.3900322, 2.7352214],
          [111.3898659, 2.7359715],
          [111.3897908, 2.7365074],
          [111.3896513, 2.7372361],
          [111.3895279, 2.7379327],
          [111.3893992, 2.7385757],
          [111.3893187, 2.7390258],
          [111.3892221, 2.7395723],
          [111.3890666, 2.7402796],
          [111.3888949, 2.7409816],
          [111.3887715, 2.7416942],
          [111.3885248, 2.7424604],
          [111.3881653, 2.7432535],
          [111.3879722, 2.7438482],
          [111.3877416, 2.7443519],
          [111.3873821, 2.7449413],
          [111.387012, 2.7456272],
          [111.386674, 2.7463291],
          [111.3865238, 2.7467792],
          [111.3861752, 2.7465166],
          [111.385805, 2.7467631],
          [111.3853919, 2.7472614],
          [111.3851291, 2.7479473],
          [111.3853544, 2.7482956],
          [111.3855475, 2.7486975],
          [111.3858777, 2.7494737],
          [111.3861, 2.750112],
          [111.3863254, 2.7509265],
          [111.3861054, 2.7510712],
          [111.3858157, 2.7512962],
          [111.3856548, 2.751891],
          [111.3856012, 2.7525554],
          [111.385687, 2.7530323],
          [111.3854617, 2.7536592],
          [111.3850325, 2.7537449],
          [111.3848394, 2.7534984],
          [111.3843888, 2.7534181],
          [111.3838792, 2.7539914],
          [111.3834339, 2.7546451],
          [111.3834554, 2.7551916],
          [111.3836056, 2.7558989],
          [111.3836861, 2.7565473],
          [111.3839865, 2.7573296],
          [111.3842332, 2.7580476],
          [111.3846946, 2.7587441],
          [111.3841474, 2.758353],
          [111.383391, 2.7576886],
          [111.3836271, 2.7580368],
          [111.3842547, 2.7586905],
          [111.3847053, 2.7590549],
          [111.3855046, 2.7595103],
          [111.3865024, 2.7598586],
          [111.3872856, 2.7600194],
          [111.3880527, 2.7601319],
          [111.3885301, 2.7601373],
          [111.3891149, 2.7601855],
          [111.3901073, 2.7601962],
          [111.3909548, 2.760223],
          [111.3917702, 2.760432],
          [111.3925159, 2.7607642],
          [111.3929182, 2.7611928],
          [111.3934171, 2.7619215],
          [111.3942379, 2.7624091],
          [111.3949513, 2.7624359],
          [111.3958472, 2.7623341],
          [111.3964266, 2.7621626],
          [111.3967591, 2.762168],
          [111.3976067, 2.7623555],
          [111.3986099, 2.7622377],
          [111.3990229, 2.7621519],
          [111.3988995, 2.7618358],
          [111.3992268, 2.7618572],
          [111.3998598, 2.7619858],
          [111.3998276, 2.7622323],
          [111.4002514, 2.7626449],
          [111.4007342, 2.7628592],
          [111.4012492, 2.76302],
        ],
      ],
      [
        [
          [111.2547145, 2.1944121],
          [111.253191, 2.1947766],
          [111.2522039, 2.1953555],
          [111.2511525, 2.1963204],
          [111.2501869, 2.19795],
          [111.2489088, 2.2017114],
          [111.2486205, 2.20256],
          [111.2485132, 2.2054975],
          [111.2473759, 2.2106435],
          [111.246582, 2.2135167],
          [111.2448869, 2.2186413],
          [111.2424407, 2.2237444],
          [111.2399945, 2.2272393],
          [111.2381492, 2.2290833],
          [111.2348232, 2.2328355],
          [111.2321839, 2.2369952],
          [111.2291155, 2.2405973],
          [111.2258754, 2.2438993],
          [111.2233219, 2.2452715],
          [111.2183652, 2.2485305],
          [111.2168631, 2.2482733],
          [111.2152324, 2.2483376],
          [111.2124429, 2.2477158],
          [111.2104902, 2.2479088],
          [111.20976, 2.2476577],
          [111.2084946, 2.2472226],
          [111.2071428, 2.2448427],
          [111.2062518, 2.2444876],
          [111.2026929, 2.241834],
          [111.2010918, 2.2406402],
          [111.1972937, 2.238346],
          [111.1955128, 2.2365663],
          [111.1963281, 2.2360732],
          [111.1950192, 2.2344436],
          [111.1937103, 2.2336932],
          [111.1923156, 2.233586],
          [111.1913071, 2.2340148],
          [111.1904058, 2.2355372],
          [111.1898479, 2.2399326],
          [111.1892042, 2.2521541],
          [111.1892686, 2.2555418],
          [111.1898908, 2.2674201],
          [111.1908994, 2.2761036],
          [111.1904702, 2.2783334],
          [111.1901483, 2.2897184],
          [111.1897621, 2.3005458],
          [111.188303, 2.3115018],
          [111.1874661, 2.3185342],
          [111.1874232, 2.3262955],
          [111.1855779, 2.3298331],
          [111.1846123, 2.3358148],
          [111.1822734, 2.3439619],
          [111.1818442, 2.3533954],
          [111.18296, 2.3623571],
          [111.1854706, 2.373527],
          [111.1877022, 2.3794657],
          [111.190835, 2.3866692],
          [111.1947832, 2.3934225],
          [111.1993108, 2.3998113],
          [111.2056408, 2.4066503],
          [111.2152967, 2.4154616],
          [111.2169061, 2.4174982],
          [111.2194166, 2.4197064],
          [111.2273559, 2.4251089],
          [111.2327847, 2.4282604],
          [111.2331924, 2.4294395],
          [111.2393293, 2.4320121],
          [111.2426553, 2.4329982],
          [111.2432346, 2.4335771],
          [111.2446937, 2.4362783],
          [111.2459168, 2.4372001],
          [111.2479982, 2.4375431],
          [111.249908, 2.4375003],
          [111.2519035, 2.4371573],
          [111.2525472, 2.4365355],
          [111.2585983, 2.4233081],
          [111.2591777, 2.4224505],
          [111.2594137, 2.4201137],
          [111.260508, 2.4176483],
          [111.261302, 2.4177769],
          [111.2701855, 2.4131891],
          [111.2749705, 2.4098875],
          [111.2866649, 2.4049995],
          [111.2881455, 2.4036274],
          [111.2910852, 2.4024483],
          [111.2953553, 2.4026412],
          [111.2984023, 2.4025769],
          [111.3005481, 2.4022339],
          [111.301857, 2.4018694],
          [111.3037882, 2.4005831],
          [111.3137409, 2.3958419],
          [111.3148818, 2.3958665],
          [111.3171992, 2.3951805],
          [111.3202677, 2.3934654],
          [111.3232074, 2.3925006],
          [111.3249883, 2.3924149],
          [111.3257823, 2.3923077],
          [111.3268766, 2.3920718],
          [111.3275847, 2.3915573],
          [111.3281641, 2.3898422],
          [111.3287434, 2.3873553],
          [111.3287864, 2.385683],
          [111.3285289, 2.3844181],
          [111.3267479, 2.3824028],
          [111.3213405, 2.3786724],
          [111.313487, 2.372305],
          [111.3087663, 2.3672882],
          [111.30411, 2.3609207],
          [111.3033161, 2.358841],
          [111.3030801, 2.3570187],
          [111.3032303, 2.3537384],
          [111.3039813, 2.350887],
          [111.3049254, 2.3491503],
          [111.3105044, 2.345377],
          [111.3138089, 2.3449053],
          [111.3147316, 2.3447767],
          [111.3164482, 2.3439834],
          [111.3181219, 2.3428042],
          [111.3202247, 2.3403815],
          [111.3205895, 2.3393095],
          [111.3210187, 2.33753],
          [111.3208256, 2.3362436],
          [111.3199673, 2.3347214],
          [111.3193235, 2.3342712],
          [111.3167486, 2.3330062],
          [111.3146243, 2.3329633],
          [111.3134012, 2.3325988],
          [111.3120065, 2.3324488],
          [111.3087663, 2.3319128],
          [111.3066206, 2.3311195],
          [111.30411, 2.3296187],
          [111.3020716, 2.3270459],
          [111.3010845, 2.3252021],
          [111.3007197, 2.3239157],
          [111.3004622, 2.3220075],
          [111.3006768, 2.3197349],
          [111.3003979, 2.3165188],
          [111.3009343, 2.3128097],
          [111.3014493, 2.3114161],
          [111.3018784, 2.3107729],
          [111.3022712, 2.3101521],
          [111.3028011, 2.3093149],
          [111.3030372, 2.3085645],
          [111.3029942, 2.308393],
          [111.3014493, 2.3081786],
          [111.299561, 2.3080928],
          [111.2966642, 2.3083287],
          [111.294733, 2.3079642],
          [111.2931881, 2.3068064],
          [111.2920937, 2.3051555],
          [111.2917504, 2.3033331],
          [111.2929091, 2.2997311],
          [111.2935314, 2.2988092],
          [111.2946257, 2.2979087],
          [111.296278, 2.2970939],
          [111.2972865, 2.296515],
          [111.29778, 2.2960219],
          [111.2972865, 2.2958933],
          [111.2962565, 2.296172],
          [111.2945614, 2.2962149],
          [111.2903342, 2.2960648],
          [111.287137, 2.2954645],
          [111.2845406, 2.2942852],
          [111.283103, 2.2932775],
          [111.2820301, 2.2922698],
          [111.2800345, 2.2898471],
          [111.2793296, 2.2884573],
          [111.2776313, 2.2851087],
          [111.2767944, 2.282836],
          [111.2760863, 2.2788051],
          [111.2759361, 2.2759535],
          [111.2759147, 2.2696714],
          [111.2759576, 2.2609449],
          [111.2755284, 2.256571],
          [111.275507, 2.2542982],
          [111.2756142, 2.2526472],
          [111.2756786, 2.2507604],
          [111.2761507, 2.2380887],
          [111.2770948, 2.2315919],
          [111.2778369, 2.2291147],
          [111.278039, 2.2284401],
          [111.2818155, 2.2140742],
          [111.2826524, 2.2101075],
          [111.2827382, 2.207706],
          [111.2822018, 2.2054761],
          [111.2811074, 2.2034391],
          [111.2790046, 2.2012092],
          [111.2771377, 2.1997726],
          [111.269649, 2.1965563],
          [111.2635765, 2.1948409],
          [111.2598429, 2.1942834],
          [111.2547145, 2.1944121],
        ],
      ],
      [
        [
          [111.3448745, 2.3702518],
          [111.3445036, 2.373782],
          [111.3456516, 2.3831228],
          [111.345621, 2.388237],
          [111.3441958, 2.3974172],
          [111.3398392, 2.4042894],
          [111.3356421, 2.4103523],
          [111.3297762, 2.4154258],
          [111.3181154, 2.4225294],
          [111.3143914, 2.4245162],
          [111.3091011, 2.4273606],
          [111.3040993, 2.4301336],
          [111.3022153, 2.430511],
          [111.3016928, 2.4315464],
          [111.301814, 2.4336431],
          [111.3021481, 2.4369181],
          [111.302171, 2.4397215],
          [111.3014229, 2.4446637],
          [111.300676, 2.4494707],
          [111.3006119, 2.4540504],
          [111.3008052, 2.456272],
          [111.3013206, 2.4607365],
          [111.3017678, 2.4667073],
          [111.3022687, 2.4680475],
          [111.3032275, 2.471907],
          [111.3036432, 2.4759602],
          [111.303745, 2.4765847],
          [111.3039908, 2.4770563],
          [111.3041052, 2.4781175],
          [111.3039215, 2.4797039],
          [111.3041231, 2.4805721],
          [111.3049039, 2.4828873],
          [111.3056974, 2.4858052],
          [111.3098059, 2.5031349],
          [111.3104529, 2.5066878],
          [111.3108949, 2.5082211],
          [111.3109829, 2.5100009],
          [111.3112662, 2.5119313],
          [111.3116965, 2.5159325],
          [111.3120197, 2.521402],
          [111.3119351, 2.5226485],
          [111.311593, 2.523016],
          [111.3105894, 2.5234143],
          [111.3103326, 2.5244862],
          [111.3096899, 2.52514],
          [111.3102672, 2.525558],
          [111.3102883, 2.5261368],
          [111.3097871, 2.5266513],
          [111.3092407, 2.5267478],
          [111.3088803, 2.5273694],
          [111.3091024, 2.5280055],
          [111.3088953, 2.5284486],
          [111.3070118, 2.5312718],
          [111.3071453, 2.5323158],
          [111.306318, 2.5344251],
          [111.3062255, 2.5383147],
          [111.3062833, 2.5395675],
          [111.3057064, 2.5424124],
          [111.3059339, 2.5439536],
          [111.3049361, 2.5457136],
          [111.3037238, 2.5474285],
          [111.3026173, 2.5509455],
          [111.3018863, 2.5514399],
          [111.3012183, 2.5581309],
          [111.3004027, 2.5594278],
          [111.2984843, 2.5629064],
          [111.2984165, 2.5635234],
          [111.2988117, 2.5636604],
          [111.2986124, 2.5647471],
          [111.2982767, 2.5648295],
          [111.2988598, 2.5652138],
          [111.2987223, 2.5658239],
          [111.2979839, 2.5663697],
          [111.2981809, 2.5668962],
          [111.2976522, 2.5676704],
          [111.2983052, 2.568138],
          [111.2976492, 2.5685412],
          [111.2973673, 2.5693905],
          [111.2974412, 2.5700011],
          [111.2969453, 2.5703218],
          [111.297208, 2.5706212],
          [111.2973849, 2.5711779],
          [111.2971378, 2.5717981],
          [111.2977868, 2.572151],
          [111.2978323, 2.5725312],
          [111.2974916, 2.5726541],
          [111.2971372, 2.5726526],
          [111.2967498, 2.5728827],
          [111.2965114, 2.5734714],
          [111.2967594, 2.5750762],
          [111.2961514, 2.5756793],
          [111.2968879, 2.5762461],
          [111.2964657, 2.5768128],
          [111.2954926, 2.5781822],
          [111.2961427, 2.5785094],
          [111.2961768, 2.5788637],
          [111.2950864, 2.5801295],
          [111.2957809, 2.5813323],
          [111.2949384, 2.5840378],
          [111.2949485, 2.5852046],
          [111.2946119, 2.5870803],
          [111.2944561, 2.588838],
          [111.2942922, 2.5906065],
          [111.2949115, 2.5916032],
          [111.2943574, 2.5930287],
          [111.2947789, 2.5939719],
          [111.2943556, 2.5942934],
          [111.2945222, 2.5945935],
          [111.2936484, 2.5977767],
          [111.293182, 2.5989021],
          [111.2937068, 2.5991432],
          [111.2936951, 2.5996416],
          [111.2929712, 2.6008205],
          [111.2930348, 2.6015601],
          [111.2925005, 2.6029855],
          [111.2928163, 2.6032749],
          [111.292124, 2.6044217],
          [111.2925935, 2.605113],
          [111.2926124, 2.6054399],
          [111.2919348, 2.6060615],
          [111.2916165, 2.6082694],
          [111.2906997, 2.6101128],
          [111.2905773, 2.6105898],
          [111.2910955, 2.6113534],
          [111.2899828, 2.6128673],
          [111.2899618, 2.6133496],
          [111.2909966, 2.6150858],
          [111.2893944, 2.617015],
          [111.2894061, 2.6176688],
          [111.2891459, 2.6179796],
          [111.288848, 2.6189013],
          [111.2891053, 2.6195015],
          [111.28888, 2.6199516],
          [111.2889068, 2.6202839],
          [111.2892997, 2.6204259],
          [111.2894425, 2.6207327],
          [111.2890489, 2.6211467],
          [111.2886546, 2.6227597],
          [111.2899791, 2.6241235],
          [111.289866, 2.6247156],
          [111.2888028, 2.6251925],
          [111.2885862, 2.6255891],
          [111.2880944, 2.628933],
          [111.286725, 2.6328521],
          [111.2863731, 2.6353557],
          [111.286254, 2.6402777],
          [111.2860169, 2.6440315],
          [111.2856071, 2.645623],
          [111.2852791, 2.6474286],
          [111.2858522, 2.6483447],
          [111.2853686, 2.6488214],
          [111.2851318, 2.650134],
          [111.28515, 2.6519763],
          [111.2851161, 2.654199],
          [111.2845993, 2.6565824],
          [111.2845743, 2.6579626],
          [111.2853826, 2.6585581],
          [111.2848319, 2.6593204],
          [111.2853614, 2.6594945],
          [111.2842317, 2.660786],
          [111.2841367, 2.661309],
          [111.284546, 2.6616069],
          [111.2840986, 2.662117],
          [111.2838476, 2.6638875],
          [111.2844383, 2.6642455],
          [111.2840206, 2.6659324],
          [111.2846657, 2.6661768],
          [111.284325, 2.666537],
          [111.2844806, 2.6668074],
          [111.2849205, 2.6668551],
          [111.2844269, 2.6673363],
          [111.2845363, 2.6677543],
          [111.2850085, 2.6685973],
          [111.2851736, 2.6688921],
          [111.2835471, 2.6730092],
          [111.2839054, 2.6734504],
          [111.2837917, 2.6740417],
          [111.2832723, 2.6747776],
          [111.2831818, 2.6771509],
          [111.2837565, 2.6790098],
          [111.2832537, 2.6796412],
          [111.2835998, 2.6814826],
          [111.284571, 2.6820145],
          [111.2826939, 2.6843645],
          [111.2831608, 2.6847002],
          [111.2826193, 2.6857004],
          [111.282567, 2.6869778],
          [111.2826869, 2.6886926],
          [111.2831995, 2.6889885],
          [111.2833901, 2.6893487],
          [111.2829132, 2.6901762],
          [111.2833482, 2.6907037],
          [111.2836071, 2.6906534],
          [111.2837803, 2.6909674],
          [111.283354, 2.6915527],
          [111.2833248, 2.6932237],
          [111.2837033, 2.693448],
          [111.2838593, 2.6936393],
          [111.2835063, 2.6941076],
          [111.2839052, 2.6947774],
          [111.2847224, 2.6953293],
          [111.2859063, 2.6951899],
          [111.2859567, 2.6954257],
          [111.2857784, 2.6961328],
          [111.2859155, 2.6967327],
          [111.2854815, 2.6979539],
          [111.2855791, 2.6982314],
          [111.2858585, 2.6984709],
          [111.2856047, 2.6988701],
          [111.2859907, 2.699083],
          [111.2860778, 2.6992658],
          [111.28627, 2.6996686],
          [111.2848198, 2.7010271],
          [111.2846282, 2.7048636],
          [111.2852736, 2.7052139],
          [111.2854608, 2.70751],
          [111.2847422, 2.708321],
          [111.2846481, 2.7090157],
          [111.2857403, 2.7098702],
          [111.2848057, 2.7110732],
          [111.2860288, 2.7115621],
          [111.2851443, 2.7141631],
          [111.2852329, 2.7154727],
          [111.2858366, 2.7156952],
          [111.2858977, 2.7164332],
          [111.2855667, 2.7170305],
          [111.2859444, 2.7174263],
          [111.2871796, 2.7172858],
          [111.2874373, 2.7176439],
          [111.2868866, 2.7189193],
          [111.2863181, 2.7189404],
          [111.2863276, 2.7193022],
          [111.2865598, 2.7195731],
          [111.2857446, 2.7201415],
          [111.2858492, 2.7211761],
          [111.2868869, 2.7213719],
          [111.2860831, 2.7233346],
          [111.2865015, 2.7240113],
          [111.2878449, 2.7235275],
          [111.2881591, 2.7245569],
          [111.287268, 2.7262163],
          [111.2873232, 2.726531],
          [111.2882439, 2.7271011],
          [111.2883874, 2.7274167],
          [111.2875446, 2.7277856],
          [111.2873785, 2.7282037],
          [111.2873595, 2.7289034],
          [111.2877215, 2.7291576],
          [111.2892452, 2.729533],
          [111.2895201, 2.7303636],
          [111.289137, 2.7318917],
          [111.2897804, 2.7343406],
          [111.2911302, 2.7367495],
          [111.2904442, 2.7383455],
          [111.2904319, 2.7386349],
          [111.2908338, 2.738628],
          [111.2919596, 2.7391073],
          [111.2920424, 2.7396132],
          [111.2914664, 2.7399961],
          [111.2914633, 2.7404184],
          [111.2911105, 2.7405173],
          [111.2912046, 2.7409549],
          [111.2917127, 2.7409248],
          [111.2925424, 2.7411574],
          [111.2938284, 2.7418356],
          [111.2938147, 2.7421803],
          [111.2931518, 2.7433318],
          [111.2934219, 2.7438042],
          [111.2942287, 2.7441367],
          [111.2942961, 2.7445087],
          [111.293418, 2.7450664],
          [111.2933945, 2.7459687],
          [111.2955069, 2.7463887],
          [111.2955462, 2.7466428],
          [111.2949052, 2.7485092],
          [111.2944762, 2.7485407],
          [111.294258, 2.7488699],
          [111.2945947, 2.7491289],
          [111.2946454, 2.7495306],
          [111.2955359, 2.7497992],
          [111.295664, 2.7510286],
          [111.2960535, 2.751384],
          [111.2968326, 2.7514291],
          [111.2970845, 2.7518121],
          [111.2963634, 2.7524614],
          [111.2960954, 2.7529509],
          [111.2962499, 2.7533646],
          [111.2970655, 2.753553],
          [111.297577, 2.7531841],
          [111.29844, 2.7533251],
          [111.2994195, 2.755045],
          [111.2991394, 2.7560883],
          [111.3005518, 2.7571897],
          [111.3008973, 2.7596322],
          [111.301387, 2.7703179],
          [111.3065966, 2.7806707],
          [111.3106053, 2.7853892],
          [111.3132059, 2.7885097],
          [111.3142702, 2.7917246],
          [111.3183729, 2.7945793],
          [111.3228962, 2.7969369],
          [111.3267843, 2.7996802],
          [111.3309386, 2.8019434],
          [111.3346292, 2.8010829],
          [111.3369834, 2.8009124],
          [111.339011, 2.8004743],
          [111.3401055, 2.8001462],
          [111.3413072, 2.7992193],
          [111.3436353, 2.7978369],
          [111.3451427, 2.7972207],
          [111.3463497, 2.7961223],
          [111.3476157, 2.7948203],
          [111.34777, 2.7933395],
          [111.348356, 2.7921842],
          [111.3495791, 2.7915037],
          [111.3513762, 2.790041],
          [111.352953, 2.7880918],
          [111.3537151, 2.7865529],
          [111.3541979, 2.7847365],
          [111.3546056, 2.7822235],
          [111.3548134, 2.7800236],
          [111.3553727, 2.7789551],
          [111.3555765, 2.7778084],
          [111.3561183, 2.7750329],
          [111.3571536, 2.7727879],
          [111.3574648, 2.7716627],
          [111.3576844, 2.7705365],
          [111.357674, 2.7698516],
          [111.3581729, 2.7688229],
          [111.3585162, 2.7678691],
          [111.3594603, 2.7652382],
          [111.359809, 2.7638719],
          [111.3602275, 2.7625377],
          [111.3606244, 2.7611124],
          [111.3611091, 2.7597441],
          [111.3618582, 2.7580261],
          [111.3628131, 2.7564026],
          [111.3637733, 2.7547898],
          [111.3646378, 2.7532532],
          [111.3653988, 2.7517516],
          [111.3663644, 2.7503692],
          [111.3668364, 2.7495387],
          [111.3673782, 2.7487242],
          [111.3685302, 2.7470654],
          [111.3694113, 2.7460183],
          [111.3706124, 2.7449424],
          [111.3709182, 2.7455117],
          [111.3716538, 2.7456126],
          [111.3717784, 2.7455279],
          [111.3721311, 2.7449627],
          [111.3723395, 2.7447595],
          [111.3725817, 2.7442715],
          [111.3739496, 2.7428677],
          [111.3752362, 2.7413981],
          [111.3765905, 2.7401772],
          [111.3799632, 2.7384471],
          [111.3824263, 2.7370594],
          [111.3839275, 2.7367592],
          [111.3846946, 2.7346052],
          [111.3853209, 2.7324556],
          [111.3859874, 2.7301042],
          [111.3868028, 2.7277519],
          [111.3876021, 2.7254049],
          [111.388266, 2.7231353],
          [111.3888466, 2.7208718],
          [111.3891039, 2.7183899],
          [111.3886213, 2.7164672],
          [111.3885315, 2.7160161],
          [111.3883507, 2.7151073],
          [111.3879132, 2.7135415],
          [111.3873454, 2.7114518],
          [111.3860196, 2.7101014],
          [111.3854454, 2.708643],
          [111.3844961, 2.7062862],
          [111.383689, 2.7039489],
          [111.3827074, 2.7005693],
          [111.381843, 2.6970789],
          [111.3811067, 2.6929205],
          [111.3810003, 2.6882609],
          [111.3817289, 2.667265],
          [111.3826479, 2.6570595],
          [111.3839328, 2.6271556],
          [111.3841164, 2.622521],
          [111.3839801, 2.62069],
          [111.3841274, 2.6155472],
          [111.3839819, 2.6082452],
          [111.383861, 2.6037871],
          [111.3829802, 2.5966726],
          [111.3824502, 2.5941676],
          [111.3823841, 2.5938595],
          [111.3783564, 2.5750909],
          [111.3773264, 2.5452517],
          [111.3780298, 2.5370347],
          [111.3779507, 2.5366651],
          [111.3781175, 2.535804],
          [111.378429, 2.532896],
          [111.3798007, 2.524283],
          [111.379954, 2.521532],
          [111.3800104, 2.519064],
          [111.3802008, 2.5186389],
          [111.3801461, 2.5163819],
          [111.3799387, 2.5155858],
          [111.3798571, 2.5135022],
          [111.3791125, 2.5059112],
          [111.3791171, 2.5013219],
          [111.3789077, 2.4984569],
          [111.3786821, 2.4974278],
          [111.3786563, 2.4971626],
          [111.3782245, 2.4947197],
          [111.3781097, 2.4939867],
          [111.3782637, 2.4935607],
          [111.3788323, 2.4933784],
          [111.3799134, 2.4896328],
          [111.3794292, 2.4897254],
          [111.3804161, 2.4874687],
          [111.3810255, 2.4857838],
          [111.3823586, 2.4846431],
          [111.3826482, 2.4841542],
          [111.3905443, 2.470822],
          [111.3938782, 2.4663918],
          [111.3972391, 2.4569303],
          [111.4017023, 2.4203995],
          [111.4066805, 2.401705],
          [111.4113154, 2.3927864],
          [111.422645, 2.3823242],
          [111.4223482, 2.3795317],
          [111.4196446, 2.3762558],
          [111.4193834, 2.3727194],
          [111.412615, 2.3684519],
          [111.4030756, 2.3710043],
          [111.3955225, 2.3740915],
          [111.3881411, 2.3734055],
          [111.380588, 2.3716903],
          [111.3738932, 2.3655158],
          [111.36943, 2.3507654],
          [111.3661684, 2.3478496],
          [111.3622202, 2.3471636],
          [111.3539501, 2.3566049],
          [111.3466237, 2.3668245],
          [111.3448745, 2.3702518],
        ],
      ],
      [
        [
          [110.9839352, 1.5196684],
          [110.9840961, 1.519706],
          [110.9841229, 1.5195666],
          [110.9845252, 1.5197703],
          [110.9846647, 1.5199151],
          [110.9848525, 1.5199312],
          [110.9849866, 1.5200921],
          [110.9852173, 1.5201189],
          [110.9854265, 1.5201886],
          [110.9856679, 1.5202208],
          [110.9860058, 1.5200974],
          [110.9861668, 1.5199848],
          [110.9863706, 1.5197542],
          [110.9865476, 1.519647],
          [110.9866066, 1.5193842],
          [110.9866066, 1.5192019],
          [110.9865423, 1.5190196],
          [110.9864135, 1.5188748],
          [110.9864403, 1.51873],
          [110.9864082, 1.5186228],
          [110.9862419, 1.5185799],
          [110.9862472, 1.5184458],
          [110.9859468, 1.5180865],
          [110.9857644, 1.5179042],
          [110.9856196, 1.5177218],
          [110.9853996, 1.5175985],
          [110.9850244, 1.5175197],
          [110.984654, 1.5175127],
          [110.9844075, 1.5175679],
          [110.9841715, 1.5175197],
          [110.984139, 1.5176039],
          [110.9841232, 1.5176966],
          [110.9841015, 1.5178934],
          [110.9840532, 1.5181026],
          [110.9839998, 1.518308],
          [110.9838871, 1.5184796],
          [110.9836082, 1.5185171],
          [110.9835975, 1.5186458],
          [110.9835757, 1.5188641],
          [110.9837101, 1.5190426],
          [110.9836938, 1.519218],
          [110.9838389, 1.5193966],
          [110.9839352, 1.5196684],
        ],
      ],
      [
        [
          [110.8002305, 1.6401345],
          [110.8004451, 1.6402793],
          [110.800606, 1.6400273],
          [110.800944, 1.6397913],
          [110.800901, 1.6395929],
          [110.8011693, 1.6394643],
          [110.8014911, 1.6394857],
          [110.8016521, 1.639298],
          [110.8017218, 1.6388637],
          [110.8019793, 1.6386009],
          [110.8023441, 1.638306],
          [110.8025908, 1.6379843],
          [110.8030522, 1.637625],
          [110.8034813, 1.6373462],
          [110.8038408, 1.6372228],
          [110.8041841, 1.6368904],
          [110.8043235, 1.6367027],
          [110.8045489, 1.6366437],
          [110.8046669, 1.6363863],
          [110.8049512, 1.6359788],
          [110.8051711, 1.6358876],
          [110.8052516, 1.6355659],
          [110.805316, 1.6352442],
          [110.805493, 1.6350404],
          [110.8055144, 1.6346436],
          [110.8057398, 1.6343004],
          [110.8057934, 1.6338554],
          [110.8058095, 1.6334103],
          [110.8059436, 1.6331958],
          [110.805847, 1.632992],
          [110.8057237, 1.6325845],
          [110.805375, 1.6323593],
          [110.8051282, 1.6323593],
          [110.8046669, 1.6325631],
          [110.8042431, 1.6329706],
          [110.8039802, 1.6334961],
          [110.8037871, 1.6339787],
          [110.8036154, 1.6342307],
          [110.8035618, 1.6345364],
          [110.8032882, 1.635019],
          [110.8031219, 1.6355069],
          [110.8028752, 1.6356892],
          [110.8025533, 1.6364882],
          [110.8022904, 1.6370137],
          [110.802108, 1.6371907],
          [110.8017862, 1.637373],
          [110.8014375, 1.6377644],
          [110.8011103, 1.6380165],
          [110.8009493, 1.6384508],
          [110.8008474, 1.6388744],
          [110.8007508, 1.6391693],
          [110.8007187, 1.6394053],
          [110.8005631, 1.6398396],
          [110.800311, 1.6398503],
          [110.8002305, 1.6401345],
        ],
      ],
      [
        [
          [110.2975062, 1.6636038],
          [110.296576, 1.6638783],
          [110.2958082, 1.6641664],
          [110.2954642, 1.6643357],
          [110.2944624, 1.6649185],
          [110.2938737, 1.6653398],
          [110.293171, 1.6659886],
          [110.2924897, 1.6667393],
          [110.2907824, 1.6688007],
          [110.2901916, 1.6696446],
          [110.2897739, 1.6704255],
          [110.2893262, 1.6715549],
          [110.2889733, 1.6727483],
          [110.2886863, 1.6739796],
          [110.2885063, 1.6754141],
          [110.2884516, 1.6764176],
          [110.2884637, 1.677166],
          [110.2884999, 1.677474],
          [110.2886045, 1.6779753],
          [110.2886984, 1.6782435],
          [110.2887735, 1.6783507],
          [110.2888338, 1.6784047],
          [110.2889344, 1.6784392],
          [110.2890296, 1.6784288],
          [110.2891195, 1.6783855],
          [110.2899939, 1.6778467],
          [110.2901226, 1.6778014],
          [110.2902406, 1.6777823],
          [110.290364, 1.6777934],
          [110.2904794, 1.677852],
          [110.2908495, 1.6780745],
          [110.2909461, 1.6781255],
          [110.2910091, 1.6781419],
          [110.2910721, 1.6781335],
          [110.2911378, 1.6781111],
          [110.2912103, 1.6780645],
          [110.2917319, 1.6777454],
          [110.2918285, 1.6776979],
          [110.2918929, 1.6776824],
          [110.2919573, 1.6776811],
          [110.2920122, 1.6776915],
          [110.2920612, 1.6777116],
          [110.2921108, 1.6777491],
          [110.2924313, 1.6780373],
          [110.2924575, 1.6780558],
          [110.2924783, 1.6780605],
          [110.2925024, 1.6780601],
          [110.2925272, 1.6780511],
          [110.2928759, 1.6778205],
          [110.2929282, 1.6777984],
          [110.2929765, 1.6777934],
          [110.29301, 1.6777957],
          [110.2930677, 1.6778041],
          [110.2931374, 1.6778068],
          [110.2932005, 1.677796],
          [110.2933694, 1.6776982],
          [110.2934056, 1.6776821],
          [110.2934351, 1.6776848],
          [110.2935156, 1.6777478],
          [110.2935518, 1.6777532],
          [110.2935867, 1.6777371],
          [110.2940413, 1.6772984],
          [110.2940883, 1.6772303],
          [110.2941768, 1.6769435],
          [110.294209, 1.6768657],
          [110.2942733, 1.6768014],
          [110.294386, 1.6767344],
          [110.2945228, 1.6766807],
          [110.2946059, 1.6766191],
          [110.2958035, 1.6755517],
          [110.2975497, 1.674249],
          [110.2978863, 1.6740305],
          [110.2981223, 1.6739219],
          [110.2983744, 1.6738428],
          [110.2984375, 1.673808],
          [110.2985179, 1.6737356],
          [110.2987057, 1.6735084],
          [110.2987915, 1.6734252],
          [110.2993199, 1.6730445],
          [110.3000924, 1.6724976],
          [110.3003901, 1.6722731],
          [110.3004625, 1.6722194],
          [110.3005591, 1.6721601],
          [110.3005966, 1.672141],
          [110.3008528, 1.6720291],
          [110.3009011, 1.6719983],
          [110.3009292, 1.6719674],
          [110.300952, 1.6719279],
          [110.3009762, 1.6718508],
          [110.3009976, 1.6717462],
          [110.3010868, 1.6710887],
          [110.3010875, 1.6710538],
          [110.3010714, 1.671021],
          [110.3010486, 1.6709996],
          [110.300999, 1.6709674],
          [110.3009842, 1.6709392],
          [110.3009856, 1.6709017],
          [110.3010057, 1.6707422],
          [110.301007, 1.6706926],
          [110.3009507, 1.6702388],
          [110.3009561, 1.6701423],
          [110.3009748, 1.6700109],
          [110.301007, 1.6698527],
          [110.301062, 1.6694157],
          [110.3010754, 1.6692334],
          [110.3010835, 1.6680296],
          [110.3010888, 1.6679492],
          [110.3011586, 1.6673848],
          [110.3011599, 1.6673158],
          [110.3011465, 1.6672139],
          [110.3011224, 1.6671174],
          [110.3010741, 1.6669511],
          [110.301058, 1.6668251],
          [110.3010794, 1.6666911],
          [110.3012323, 1.6660248],
          [110.3012712, 1.6658841],
          [110.3013195, 1.6657487],
          [110.3013933, 1.6656052],
          [110.3014509, 1.6654497],
          [110.301526, 1.6652084],
          [110.3016628, 1.663833],
          [110.3016682, 1.6636561],
          [110.301644, 1.6635676],
          [110.3015864, 1.6635083],
          [110.3015032, 1.6634922],
          [110.3013544, 1.663514],
          [110.3009909, 1.6636102],
          [110.3006262, 1.6636906],
          [110.3000668, 1.6637469],
          [110.2995318, 1.6637121],
          [110.2989793, 1.6635834],
          [110.298534, 1.6635029],
          [110.2980256, 1.6635192],
          [110.2975062, 1.6636038],
        ],
      ],
      [
        [
          [110.0852127, 1.7329775],
          [110.0858135, 1.7337711],
          [110.0859208, 1.734629],
          [110.0865431, 1.7356156],
          [110.0873584, 1.7357657],
          [110.0882811, 1.7358086],
          [110.0887317, 1.7361303],
          [110.0895257, 1.7369024],
          [110.0902123, 1.7368595],
          [110.0909419, 1.7366236],
          [110.0917573, 1.7371384],
          [110.0923795, 1.7374815],
          [110.0933881, 1.7367952],
          [110.0938172, 1.7359373],
          [110.0934524, 1.7350365],
          [110.0927229, 1.734629],
          [110.0922293, 1.7335137],
          [110.0916929, 1.7329346],
          [110.0912852, 1.7322483],
          [110.0909204, 1.7320981],
          [110.090856, 1.7313903],
          [110.0906415, 1.7305968],
          [110.09062, 1.7297389],
          [110.0901694, 1.7293957],
          [110.0893969, 1.7293957],
          [110.0890965, 1.7295887],
          [110.0888176, 1.730704],
          [110.0884957, 1.7316263],
          [110.0877876, 1.7317979],
          [110.0871868, 1.7322697],
          [110.0856633, 1.7322483],
          [110.0851912, 1.7325485],
          [110.0852127, 1.7329775],
        ],
      ],
      [
        [
          [110.499233, 1.7545663],
          [110.4993188, 1.7547379],
          [110.4995924, 1.7542821],
          [110.4998445, 1.7541427],
          [110.4999143, 1.7537031],
          [110.4997534, 1.7534457],
          [110.4995656, 1.7533224],
          [110.4996729, 1.7529363],
          [110.4997748, 1.7525824],
          [110.5001342, 1.7524162],
          [110.5000967, 1.7520784],
          [110.4996675, 1.7519819],
          [110.5003005, 1.7516334],
          [110.5004239, 1.7513116],
          [110.5003059, 1.7513438],
          [110.5003273, 1.7511133],
          [110.5002254, 1.750813],
          [110.4999411, 1.7506253],
          [110.4995334, 1.7503626],
          [110.4991794, 1.7500677],
          [110.4988736, 1.7500516],
          [110.4986376, 1.7496387],
          [110.4986215, 1.7493009],
          [110.4986107, 1.748695],
          [110.4985356, 1.7480784],
          [110.4984176, 1.7478747],
          [110.4982084, 1.7479551],
          [110.4980797, 1.7482875],
          [110.4976773, 1.7484859],
          [110.4971141, 1.7486897],
          [110.4969263, 1.7485235],
          [110.4966634, 1.7480302],
          [110.4964167, 1.747569],
          [110.4961431, 1.7474832],
          [110.4954082, 1.7476173],
          [110.4950756, 1.7477138],
          [110.494684, 1.7477942],
          [110.4942709, 1.7476387],
          [110.4937452, 1.7477621],
          [110.493418, 1.7476387],
          [110.4931176, 1.7475851],
          [110.4928708, 1.7477942],
          [110.4929084, 1.7481052],
          [110.4922593, 1.7486575],
          [110.4919642, 1.7486414],
          [110.4916745, 1.7490167],
          [110.4918247, 1.749183],
          [110.4922807, 1.7498103],
          [110.4928011, 1.7504269],
          [110.4933268, 1.7508827],
          [110.4937023, 1.7516119],
          [110.4942816, 1.7518907],
          [110.4950166, 1.7525985],
          [110.4962826, 1.7530864],
          [110.4968512, 1.7532473],
          [110.49779, 1.7535958],
          [110.498423, 1.7540623],
          [110.4986215, 1.7540623],
          [110.499233, 1.7545663],
        ],
      ],
      [
        [
          [110.1639838, 1.7602482],
          [110.1642735, 1.7603126],
          [110.1646383, 1.7603126],
          [110.1647777, 1.7601303],
          [110.1649494, 1.7602804],
          [110.1654644, 1.7603233],
          [110.1663334, 1.7604949],
          [110.1665158, 1.760334],
          [110.1668377, 1.7600981],
          [110.1671059, 1.7596155],
          [110.1673097, 1.7591222],
          [110.1670737, 1.758822],
          [110.166666, 1.7585003],
          [110.1662583, 1.7582965],
          [110.1663227, 1.7578783],
          [110.1662261, 1.757578],
          [110.1660866, 1.7574601],
          [110.166269, 1.7570097],
          [110.1661188, 1.7565593],
          [110.1657433, 1.7560338],
          [110.1658292, 1.7554654],
          [110.1656146, 1.7550794],
          [110.1652927, 1.7550472],
          [110.1651318, 1.7554762],
          [110.1650889, 1.7560552],
          [110.1648099, 1.7566022],
          [110.1646168, 1.7573099],
          [110.1643808, 1.7576424],
          [110.1644773, 1.7580177],
          [110.1641984, 1.7582],
          [110.1639731, 1.7585324],
          [110.1639194, 1.7588434],
          [110.1636941, 1.7592295],
          [110.163898, 1.7595512],
          [110.1638014, 1.7599373],
          [110.1639838, 1.7602482],
        ],
      ],
      [
        [
          [110.1583512, 1.7872076],
          [110.1586516, 1.7866822],
          [110.1590593, 1.7864033],
          [110.1590378, 1.7867679],
          [110.1596386, 1.7862854],
          [110.1602287, 1.7860709],
          [110.16069, 1.7859637],
          [110.1612265, 1.7861889],
          [110.1611728, 1.7865213],
          [110.1620419, 1.7861352],
          [110.1626749, 1.7861674],
          [110.1636297, 1.7856741],
          [110.1642842, 1.7858779],
          [110.1647241, 1.7861031],
          [110.1652927, 1.7865213],
          [110.1656789, 1.7870897],
          [110.1657219, 1.7876044],
          [110.1654966, 1.7881835],
          [110.1655502, 1.7887625],
          [110.1660008, 1.7890306],
          [110.166666, 1.7892665],
          [110.1671059, 1.7893738],
          [110.167696, 1.7893416],
          [110.1679642, 1.7893416],
          [110.1680393, 1.789481],
          [110.1683612, 1.7891808],
          [110.1684899, 1.7886017],
          [110.1685114, 1.7877116],
          [110.1683504, 1.7868752],
          [110.1680071, 1.7857921],
          [110.1678462, 1.7852881],
          [110.1678462, 1.7845267],
          [110.1677282, 1.7839155],
          [110.1671381, 1.7834114],
          [110.1667089, 1.783272],
          [110.1663763, 1.7826286],
          [110.165797, 1.7818994],
          [110.1652713, 1.781567],
          [110.1650245, 1.7808592],
          [110.1647241, 1.7801729],
          [110.1645524, 1.7794544],
          [110.1638443, 1.7791434],
          [110.1634044, 1.7796582],
          [110.1626963, 1.7800657],
          [110.1617951, 1.7802372],
          [110.1604969, 1.7810308],
          [110.1598854, 1.7815455],
          [110.1595206, 1.7820174],
          [110.1594026, 1.7834007],
          [110.1590807, 1.7837868],
          [110.1587696, 1.7842801],
          [110.1586194, 1.7848913],
          [110.1588125, 1.7851701],
          [110.1585657, 1.7855669],
          [110.1587481, 1.7858243],
          [110.1585335, 1.7863176],
          [110.1579864, 1.7868001],
          [110.1581044, 1.7871862],
          [110.1583512, 1.7872076],
        ],
      ],
      [
        [
          [109.6532771, 1.9601727],
          [109.6534488, 1.9630893],
          [109.6548221, 1.9630893],
          [109.6575686, 1.9617168],
          [109.6575686, 1.960859],
          [109.655852, 1.9606874],
          [109.6532771, 1.9601727],
        ],
      ],
      [
        [
          [115.447617, 4.9794313],
          [115.4543323, 4.9706006],
          [115.4675266, 4.9641624],
          [115.4818118, 4.9613706],
          [115.4821172, 4.9613109],
          [115.484081, 4.9609271],
          [115.4938159, 4.9590246],
          [115.5090747, 4.9591587],
          [115.5160403, 4.9595495],
          [115.5359693, 4.9689708],
          [115.5450453, 4.9756099],
          [115.5499958, 4.9780758],
          [115.5581197, 4.97953],
          [115.5672591, 4.9840825],
          [115.5721462, 4.9859161],
          [115.5760178, 4.9883188],
          [115.5830627, 4.9870542],
          [115.5891557, 4.9868013],
          [115.5930907, 4.9900891],
          [115.5955025, 4.9879394],
          [115.599501, 4.983956],
          [115.6052665, 4.9764464],
          [115.6076002, 4.9750604],
          [115.6158579, 4.9686222],
          [115.6181018, 4.9673256],
          [115.6207048, 4.965403],
          [115.6218267, 4.9595907],
          [115.6237565, 4.9543148],
          [115.6247887, 4.9483682],
          [115.625058, 4.9459985],
          [115.6231282, 4.9400518],
          [115.6229936, 4.9281583],
          [115.6216148, 4.9239196],
          [115.6234997, 4.9230701],
          [115.6252051, 4.9192247],
          [115.627449, 4.9171232],
          [115.6291993, 4.9155582],
          [115.6314881, 4.9135014],
          [115.633014, 4.9106397],
          [115.6340911, 4.9083145],
          [115.6338218, 4.9063471],
          [115.6340013, 4.9038878],
          [115.6349438, 4.9009814],
          [115.6354374, 4.8983432],
          [115.6379955, 4.8923961],
          [115.6384443, 4.88864],
          [115.6383097, 4.8839896],
          [115.6373672, 4.8809042],
          [115.6356618, 4.8779529],
          [115.6368736, 4.8741073],
          [115.6374121, 4.8703511],
          [115.6379955, 4.8661477],
          [115.6409575, 4.8587246],
          [115.6436054, 4.8568912],
          [115.6451313, 4.8542529],
          [115.6473303, 4.8521511],
          [115.6492601, 4.8514356],
          [115.6520875, 4.8506307],
          [115.6521772, 4.8478135],
          [115.6514143, 4.8420001],
          [115.6517733, 4.8369021],
          [115.6527158, 4.8282713],
          [115.6547802, 4.8254093],
          [115.6545558, 4.823218],
          [115.6528055, 4.8195062],
          [115.6531646, 4.8180752],
          [115.6553187, 4.8152131],
          [115.6558573, 4.8129324],
          [115.6561266, 4.8084156],
          [115.6560368, 4.8057323],
          [115.6550944, 4.8027807],
          [115.6544212, 4.7998738],
          [115.6556329, 4.798398],
          [115.655947, 4.7932998],
          [115.6559022, 4.7899009],
          [115.6554983, 4.7852051],
          [115.6543763, 4.780867],
          [115.6531197, 4.7780494],
          [115.6514592, 4.7761264],
          [115.6497538, 4.7745163],
          [115.6494845, 4.7732194],
          [115.6480035, 4.7725485],
          [115.6436503, 4.772146],
          [115.6405985, 4.7720566],
          [115.6368287, 4.7709385],
          [115.6332833, 4.7682551],
          [115.6331935, 4.7618149],
          [115.6322062, 4.7582817],
          [115.6308149, 4.7555982],
          [115.6287056, 4.7542565],
          [115.6270451, 4.7517072],
          [115.6268207, 4.7482187],
          [115.6262822, 4.7437462],
          [115.6230509, 4.7400787],
          [115.6213006, 4.7369927],
          [115.6208518, 4.7338619],
          [115.6192362, 4.7274214],
          [115.6190567, 4.7234407],
          [115.6182938, 4.7213386],
          [115.6176206, 4.718163],
          [115.6168576, 4.7165528],
          [115.6139854, 4.7131536],
          [115.6125941, 4.7109172],
          [115.6114722, 4.7074285],
          [115.6108439, 4.7024637],
          [115.6101707, 4.6985277],
          [115.6094078, 4.695352],
          [115.6085551, 4.692713],
          [115.6070741, 4.6907897],
          [115.6053238, 4.6881507],
          [115.6048301, 4.6852881],
          [115.603394, 4.6830516],
          [115.6017335, 4.6774158],
          [115.5996691, 4.6763423],
          [115.5969763, 4.67424],
          [115.5889879, 4.6655624],
          [115.5870581, 4.6629233],
          [115.585263, 4.6610894],
          [115.5839166, 4.6577346],
          [115.5810892, 4.6569294],
          [115.5801917, 4.6546929],
          [115.5786209, 4.6520538],
          [115.5765116, 4.6508908],
          [115.5752999, 4.6481175],
          [115.5686578, 4.6462835],
          [115.5652919, 4.6456125],
          [115.5663241, 4.6412288],
          [115.5643495, 4.638858],
          [115.5618811, 4.6352795],
          [115.5596821, 4.6336244],
          [115.5590089, 4.6304037],
          [115.5588294, 4.6280329],
          [115.5594128, 4.6266909],
          [115.5582459, 4.6240964],
          [115.5563162, 4.6197573],
          [115.5539825, 4.6164471],
          [115.5512449, 4.6124658],
          [115.5499434, 4.6108107],
          [115.5485072, 4.6091108],
          [115.5481482, 4.607724],
          [115.5480136, 4.6058452],
          [115.5464428, 4.6044137],
          [115.5463082, 4.5991351],
          [115.5458145, 4.5943485],
          [115.5454555, 4.592917],
          [115.5492253, 4.5906355],
          [115.551918, 4.5887567],
          [115.5535337, 4.5850437],
          [115.5553288, 4.584149],
          [115.5577972, 4.5820017],
          [115.5585601, 4.5812412],
          [115.5594128, 4.5778413],
          [115.5606245, 4.5743519],
          [115.5614323, 4.5725177],
          [115.5640802, 4.5718914],
          [115.5661895, 4.5707283],
          [115.5678051, 4.569923],
          [115.5691964, 4.5683573],
          [115.5706774, 4.5664336],
          [115.5701837, 4.5645099],
          [115.5683885, 4.5599915],
          [115.5679398, 4.5571284],
          [115.5666832, 4.5549363],
          [115.5643495, 4.5526099],
          [115.5634519, 4.5511784],
          [115.564888, 4.5479125],
          [115.5681193, 4.5450046],
          [115.5685681, 4.543573],
          [115.5702286, 4.541694],
          [115.5739535, 4.5398597],
          [115.5748511, 4.5378018],
          [115.5755243, 4.536907],
          [115.5752999, 4.5348491],
          [115.5749408, 4.5325674],
          [115.5743125, 4.5304647],
          [115.5732355, 4.5280488],
          [115.573774, 4.5265277],
          [115.5761526, 4.5250513],
          [115.5781272, 4.524246],
          [115.5788902, 4.5222775],
          [115.5802365, 4.5196826],
          [115.5809097, 4.5176694],
          [115.5794736, 4.5150745],
          [115.5771399, 4.5112269],
          [115.5759731, 4.5087662],
          [115.5754794, 4.5064844],
          [115.5773643, 4.5016525],
          [115.5784863, 4.4987891],
          [115.5744472, 4.4958362],
          [115.5709018, 4.4951651],
          [115.5684783, 4.4905121],
          [115.5669075, 4.4895278],
          [115.5647085, 4.4883645],
          [115.5604001, 4.4881408],
          [115.5594577, 4.484651],
          [115.5599513, 4.4826376],
          [115.5598616, 4.480311],
          [115.5604899, 4.4779845],
          [115.5628236, 4.4765975],
          [115.5640802, 4.4736445],
          [115.5665036, 4.4714521],
          [115.568972, 4.4689913],
          [115.573774, 4.4608482],
          [115.5752101, 4.4579399],
          [115.5790248, 4.4561054],
          [115.5794736, 4.4541367],
          [115.5793838, 4.4497071],
          [115.580057, 4.4475147],
          [115.5817624, 4.4452328],
          [115.5863849, 4.441698],
          [115.5865645, 4.4391923],
          [115.5880455, 4.4370893],
          [115.5877762, 4.4344942],
          [115.591187, 4.4314963],
          [115.5923089, 4.4284089],
          [115.5925782, 4.4247398],
          [115.593027, 4.4202206],
          [115.5934309, 4.4168199],
          [115.5906933, 4.4129718],
          [115.5901996, 4.4113609],
          [115.5901996, 4.4070653],
          [115.5909177, 4.4017853],
          [115.5914114, 4.3989215],
          [115.5906484, 4.3969974],
          [115.5895713, 4.3952075],
          [115.5875518, 4.3930597],
          [115.586744, 4.3912698],
          [115.5880903, 4.3883165],
          [115.5893918, 4.3856316],
          [115.5884045, 4.3824098],
          [115.5873723, 4.3794565],
          [115.5877762, 4.3726995],
          [115.5872684, 4.3701998],
          [115.5871415, 4.36577],
          [115.5883474, 4.3636816],
          [115.5899976, 4.3622577],
          [115.5923776, 4.3617831],
          [115.5954876, 4.3608654],
          [115.5987562, 4.3608338],
          [115.6034211, 4.3603275],
          [115.6082447, 4.3602642],
          [115.6081908, 4.3582349],
          [115.6075625, 4.3557289],
          [115.6061712, 4.3510302],
          [115.6074727, 4.3469133],
          [115.6110181, 4.343915],
          [115.6127684, 4.3415433],
          [115.6175256, 4.3448548],
          [115.6209812, 4.3448548],
          [115.6226244, 4.3436584],
          [115.625362, 4.3440164],
          [115.6276957, 4.3471936],
          [115.6310167, 4.3505946],
          [115.6344275, 4.3465223],
          [115.6361329, 4.3449561],
          [115.6385115, 4.3432556],
          [115.6402169, 4.3427634],
          [115.642012, 4.3428529],
          [115.6432237, 4.3438821],
          [115.6448394, 4.3435241],
          [115.6472179, 4.3425396],
          [115.6485643, 4.3402574],
          [115.6546229, 4.3381989],
          [115.6593352, 4.3359613],
          [115.6611304, 4.3333211],
          [115.6626562, 4.328309],
          [115.6659324, 4.3256687],
          [115.6662914, 4.3226704],
          [115.6661119, 4.3181057],
          [115.6666505, 4.3151522],
          [115.6663363, 4.3137649],
          [115.6662914, 4.3123328],
          [115.6664709, 4.3091107],
          [115.6676827, 4.3071416],
          [115.6704203, 4.3046802],
          [115.6705998, 4.3034272],
          [115.67051, 4.3015028],
          [115.6707793, 4.2996232],
          [115.6715422, 4.2985044],
          [115.6711832, 4.2966248],
          [115.6681315, 4.2937606],
          [115.6671441, 4.291523],
          [115.6669646, 4.2888825],
          [115.667189, 4.2863316],
          [115.6649899, 4.2851233],
          [115.6639577, 4.2838702],
          [115.6641821, 4.2814982],
          [115.664227, 4.279171],
          [115.6655734, 4.2769781],
          [115.6637333, 4.2734426],
          [115.661265, 4.2726817],
          [115.6584825, 4.270444],
          [115.6574054, 4.2688329],
          [115.6574952, 4.2667742],
          [115.6581684, 4.2639099],
          [115.6570913, 4.2620302],
          [115.6533663, 4.2604638],
          [115.6512122, 4.2587183],
          [115.649776, 4.2553617],
          [115.6491477, 4.2517366],
          [115.6464101, 4.2504386],
          [115.6452882, 4.2495883],
          [115.6429096, 4.2476191],
          [115.6417876, 4.2465449],
          [115.6411593, 4.2443967],
          [115.6397681, 4.2426959],
          [115.6374344, 4.2409057],
          [115.6365817, 4.2395183],
          [115.6360431, 4.2377728],
          [115.6378832, 4.2365644],
          [115.6397681, 4.2358035],
          [115.6417427, 4.2353559],
          [115.6424159, 4.2334762],
          [115.6426403, 4.2302537],
          [115.6425506, 4.2272103],
          [115.6424159, 4.2262256],
          [115.6397681, 4.2245696],
          [115.6397681, 4.2229136],
          [115.640172, 4.22081],
          [115.6387807, 4.2177666],
          [115.6384217, 4.2164686],
          [115.6385115, 4.2152601],
          [115.6393193, 4.2137831],
          [115.6399027, 4.2123509],
          [115.6398578, 4.2102473],
          [115.6391398, 4.2085018],
          [115.6385115, 4.2060401],
          [115.6385115, 4.2038469],
          [115.6396783, 4.2024594],
          [115.6412939, 4.1989235],
          [115.6421466, 4.1931944],
          [115.6414735, 4.1904194],
          [115.6396334, 4.188271],
          [115.6356392, 4.1824971],
          [115.6328567, 4.1806619],
          [115.6298947, 4.1784687],
          [115.629805, 4.1762307],
          [115.6306128, 4.1741718],
          [115.6314655, 4.1723814],
          [115.6300743, 4.1697406],
          [115.6294908, 4.1675473],
          [115.6294908, 4.1666969],
          [115.6305679, 4.1643693],
          [115.6319592, 4.1639665],
          [115.6359983, 4.1645036],
          [115.6387359, 4.164056],
          [115.63905, 4.1622656],
          [115.6409349, 4.1591324],
          [115.6408003, 4.1561782],
          [115.6409798, 4.154522],
          [115.6406208, 4.1526421],
          [115.640531, 4.1506278],
          [115.6412939, 4.1488821],
          [115.6445252, 4.1475841],
          [115.6478462, 4.1458831],
          [115.6485194, 4.1411832],
          [115.6490131, 4.1384527],
          [115.6491029, 4.1372441],
          [115.6481604, 4.1343794],
          [115.6476219, 4.1337079],
          [115.6463204, 4.1316041],
          [115.6459613, 4.1299032],
          [115.6459165, 4.1279336],
          [115.644974, 4.125785],
          [115.6449291, 4.1244422],
          [115.6439867, 4.1225621],
          [115.6467692, 4.1208611],
          [115.6491477, 4.119563],
          [115.6510775, 4.1185782],
          [115.6526932, 4.1189363],
          [115.653501, 4.1186678],
          [115.6556103, 4.1192497],
          [115.6565976, 4.1200554],
          [115.657181, 4.1214878],
          [115.6585391, 4.1240788],
          [115.6606484, 4.1226464],
          [115.6623089, 4.1215273],
          [115.6648221, 4.1200949],
          [115.667829, 4.1185282],
          [115.6708807, 4.116111],
          [115.673859, 4.114879],
          [115.673898, 4.114611],
          [115.674089, 4.114266],
          [115.67451, 4.113922],
          [115.675582, 4.113233],
          [115.675888, 4.112812],
          [115.676194, 4.111817],
          [115.676539, 4.109214],
          [115.67696, 4.107837],
          [115.677266, 4.107339],
          [115.677763, 4.106918],
          [115.677916, 4.106497],
          [115.678031, 4.105923],
          [115.677993, 4.105157],
          [115.67761, 4.10401],
          [115.675505, 4.099149],
          [115.675467, 4.098575],
          [115.675697, 4.098154],
          [115.676424, 4.097388],
          [115.677036, 4.096623],
          [115.677419, 4.095895],
          [115.677572, 4.095206],
          [115.677572, 4.094441],
          [115.677381, 4.093254],
          [115.677457, 4.092642],
          [115.677649, 4.091991],
          [115.677649, 4.091149],
          [115.677495, 4.090269],
          [115.6765, 4.087398],
          [115.675773, 4.085906],
          [115.675046, 4.084681],
          [115.674778, 4.083342],
          [115.674854, 4.082193],
          [115.675084, 4.081466],
          [115.675084, 4.080969],
          [115.674969, 4.080509],
          [115.674587, 4.080203],
          [115.673898, 4.079782],
          [115.673017, 4.079093],
          [115.672137, 4.078175],
          [115.671563, 4.077218],
          [115.671333, 4.076567],
          [115.67118, 4.07584],
          [115.671104, 4.075074],
          [115.671142, 4.072051],
          [115.671065, 4.071477],
          [115.670759, 4.070979],
          [115.670262, 4.070558],
          [115.669266, 4.069984],
          [115.668692, 4.069754],
          [115.668386, 4.069372],
          [115.668271, 4.068912],
          [115.668348, 4.068606],
          [115.668577, 4.068453],
          [115.668692, 4.068108],
          [115.667965, 4.067573],
          [115.667697, 4.067266],
          [115.667468, 4.066731],
          [115.667429, 4.066156],
          [115.667468, 4.065659],
          [115.667621, 4.0652],
          [115.667621, 4.064664],
          [115.667353, 4.064013],
          [115.666893, 4.063362],
          [115.666166, 4.062788],
          [115.665554, 4.062444],
          [115.665056, 4.062061],
          [115.664865, 4.061678],
          [115.664865, 4.061296],
          [115.665018, 4.06076],
          [115.665439, 4.060109],
          [115.665592, 4.059765],
          [115.665669, 4.059305],
          [115.66563, 4.058923],
          [115.665401, 4.05831],
          [115.665056, 4.057774],
          [115.664482, 4.057124],
          [115.663985, 4.056473],
          [115.663487, 4.055708],
          [115.663066, 4.054674],
          [115.66276, 4.053373],
          [115.6623, 4.051038],
          [115.661918, 4.048742],
          [115.66119, 4.046216],
          [115.660655, 4.044799],
          [115.660042, 4.042886],
          [115.659545, 4.041585],
          [115.658932, 4.040322],
          [115.657937, 4.03925],
          [115.656444, 4.038178],
          [115.655067, 4.037336],
          [115.654339, 4.036724],
          [115.653497, 4.036226],
          [115.653076, 4.035767],
          [115.653, 4.035461],
          [115.653, 4.035001],
          [115.653191, 4.034312],
          [115.653574, 4.033356],
          [115.654225, 4.031518],
          [115.654492, 4.030562],
          [115.654646, 4.029222],
          [115.654416, 4.027347],
          [115.65411, 4.02593],
          [115.653803, 4.025012],
          [115.653536, 4.023251],
          [115.653382, 4.021567],
          [115.653153, 4.020649],
          [115.652885, 4.020036],
          [115.652387, 4.019424],
          [115.651545, 4.018888],
          [115.651048, 4.018391],
          [115.650818, 4.017931],
          [115.65078, 4.017549],
          [115.650895, 4.017166],
          [115.651163, 4.01663],
          [115.651316, 4.016018],
          [115.651354, 4.015367],
          [115.651239, 4.014448],
          [115.650627, 4.012114],
          [115.649593, 4.010085],
          [115.649287, 4.009243],
          [115.649211, 4.008707],
          [115.649249, 4.00821],
          [115.649746, 4.006296],
          [115.64967, 4.005339],
          [115.649823, 4.003158],
          [115.649976, 4.002699],
          [115.650397, 4.001895],
          [115.650359, 4.001206],
          [115.649708, 4.000019],
          [115.649593, 3.999598],
          [115.649629, 3.999133],
          [115.650072, 3.99812],
          [115.650389, 3.996759],
          [115.650357, 3.996253],
          [115.650231, 3.995588],
          [115.650041, 3.994987],
          [115.649946, 3.994449],
          [115.649598, 3.99122],
          [115.649629, 3.990429],
          [115.650009, 3.989321],
          [115.650041, 3.988752],
          [115.649914, 3.988277],
          [115.648363, 3.98603],
          [115.647509, 3.984416],
          [115.646496, 3.982264],
          [115.646306, 3.981662],
          [115.646338, 3.981251],
          [115.646559, 3.980681],
          [115.646686, 3.980016],
          [115.646591, 3.979478],
          [115.646369, 3.978687],
          [115.645768, 3.977738],
          [115.645388, 3.976978],
          [115.645008, 3.975839],
          [115.644597, 3.975016],
          [115.643141, 3.972991],
          [115.64216, 3.97182],
          [115.641875, 3.971376],
          [115.64178, 3.970585],
          [115.641717, 3.969319],
          [115.641938, 3.965964],
          [115.641812, 3.965616],
          [115.641274, 3.965268],
          [115.639628, 3.964508],
          [115.639216, 3.964129],
          [115.639027, 3.963496],
          [115.639153, 3.962578],
          [115.638963, 3.96185],
          [115.638868, 3.961249],
          [115.638489, 3.9609],
          [115.635988, 3.960077],
          [115.634627, 3.959539],
          [115.633994, 3.959254],
          [115.633615, 3.958843],
          [115.63352, 3.958178],
          [115.63333, 3.957577],
          [115.632918, 3.956691],
          [115.631842, 3.954697],
          [115.630386, 3.952513],
          [115.62931, 3.950772],
          [115.628899, 3.949918],
          [115.628867, 3.949253],
          [115.628645, 3.948683],
          [115.628107, 3.947955],
          [115.627126, 3.947038],
          [115.625069, 3.944949],
          [115.621334, 3.942385],
          [115.620226, 3.941689],
          [115.618929, 3.940676],
          [115.618612, 3.940106],
          [115.618201, 3.9396],
          [115.617441, 3.93884],
          [115.616713, 3.938271],
          [115.616238, 3.937828],
          [115.615859, 3.938302],
          [115.615479, 3.93865],
          [115.615099, 3.938872],
          [115.614244, 3.939188],
          [115.613168, 3.939663],
          [115.611428, 3.940296],
          [115.610447, 3.940613],
          [115.609434, 3.940834],
          [115.608484, 3.940866],
          [115.607376, 3.940834],
          [115.606522, 3.940708],
          [115.605699, 3.940454],
          [115.602946, 3.93979],
          [115.601996, 3.939726],
          [115.601395, 3.939853],
          [115.600382, 3.939916],
          [115.59842, 3.939853],
          [115.597629, 3.940043],
          [115.595666, 3.940676],
          [115.593609, 3.941277],
          [115.592913, 3.941562],
          [115.592438, 3.941942],
          [115.591837, 3.942828],
          [115.591267, 3.943777],
          [115.591014, 3.944094],
          [115.590729, 3.944157],
          [115.590159, 3.944031],
          [115.58864, 3.943429],
          [115.587754, 3.943144],
          [115.586868, 3.942733],
          [115.586235, 3.942322],
          [115.584652, 3.940961],
          [115.584209, 3.940866],
          [115.583734, 3.940866],
          [115.582975, 3.941024],
          [115.581772, 3.941404],
          [115.581012, 3.94153],
          [115.580728, 3.941182],
          [115.580411, 3.940518],
          [115.576613, 3.933586],
          [115.574809, 3.930168],
          [115.574461, 3.929251],
          [115.573353, 3.928206],
          [115.57098, 3.925769],
          [115.570726, 3.925136],
          [115.570631, 3.924472],
          [115.569017, 3.920959],
          [115.568574, 3.920484],
          [115.566675, 3.919344],
          [115.565378, 3.918806],
          [115.56465, 3.918648],
          [115.563289, 3.918775],
          [115.560535, 3.918901],
          [115.559459, 3.918996],
          [115.558352, 3.919186],
          [115.559396, 3.917762],
          [115.561991, 3.914914],
          [115.56389, 3.912698],
          [115.567182, 3.90928],
          [115.569777, 3.906495],
          [115.570379, 3.905767],
          [115.571834, 3.904786],
          [115.572499, 3.904374],
          [115.573449, 3.903868],
          [115.574145, 3.903425],
          [115.574778, 3.902887],
          [115.576803, 3.900513],
          [115.578481, 3.898741],
          [115.579209, 3.897854],
          [115.579462, 3.896968],
          [115.580316, 3.893487],
          [115.580918, 3.89086],
          [115.581361, 3.889151],
          [115.581772, 3.887916],
          [115.583608, 3.884688],
          [115.584557, 3.885511],
          [115.585349, 3.886049],
          [115.585823, 3.886112],
          [115.586615, 3.886081],
          [115.587248, 3.885986],
          [115.587912, 3.885828],
          [115.588355, 3.885859],
          [115.588672, 3.885954],
          [115.588988, 3.886176],
          [115.58921, 3.886587],
          [115.589336, 3.887062],
          [115.589621, 3.887378],
          [115.589938, 3.887822],
          [115.591868, 3.888518],
          [115.592818, 3.889626],
          [115.593134, 3.890195],
          [115.593483, 3.890607],
          [115.593989, 3.890702],
          [115.594812, 3.89067],
          [115.59554, 3.890512],
          [115.596267, 3.89029],
          [115.596837, 3.889942],
          [115.597059, 3.889436],
          [115.597122, 3.888581],
          [115.597375, 3.886587],
          [115.597375, 3.885511],
          [115.597059, 3.882884],
          [115.597027, 3.882251],
          [115.597154, 3.881681],
          [115.597787, 3.880732],
          [115.598103, 3.880352],
          [115.59861, 3.880067],
          [115.599053, 3.879909],
          [115.599591, 3.879782],
          [115.600066, 3.879466],
          [115.600825, 3.878579],
          [115.60187, 3.876807],
          [115.602376, 3.876142],
          [115.602661, 3.875921],
          [115.603294, 3.875731],
          [115.605826, 3.875573],
          [115.606364, 3.875604],
          [115.606839, 3.875794],
          [115.609149, 3.877377],
          [115.609561, 3.877472],
          [115.610099, 3.877408],
          [115.611713, 3.876997],
          [115.616967, 3.875858],
          [115.617378, 3.875636],
          [115.617473, 3.875414],
          [115.617505, 3.875066],
          [115.617853, 3.873231],
          [115.617948, 3.872439],
          [115.618264, 3.871743],
          [115.619246, 3.869907],
          [115.619562, 3.868863],
          [115.61953, 3.867945],
          [115.619341, 3.866458],
          [115.618771, 3.862881],
          [115.618739, 3.861932],
          [115.618961, 3.858355],
          [115.618929, 3.857912],
          [115.618201, 3.856742],
          [115.616904, 3.854463],
          [115.616619, 3.853387],
          [115.61646, 3.8519],
          [115.616587, 3.851298],
          [115.616809, 3.850634],
          [115.616777, 3.849684],
          [115.616492, 3.848703],
          [115.616271, 3.848291],
          [115.616144, 3.84788],
          [115.616176, 3.847469],
          [115.616302, 3.846994],
          [115.616587, 3.846298],
          [115.617916, 3.843702],
          [115.618644, 3.842468],
          [115.618897, 3.841677],
          [115.618992, 3.840442],
          [115.618708, 3.838923],
          [115.61817, 3.836961],
          [115.617726, 3.836201],
          [115.616935, 3.835125],
          [115.616397, 3.834492],
          [115.616207, 3.833764],
          [115.615479, 3.829269],
          [115.615258, 3.828668],
          [115.614656, 3.827529],
          [115.614498, 3.826199],
          [115.614023, 3.824205],
          [115.613739, 3.823731],
          [115.613042, 3.822971],
          [115.612662, 3.822433],
          [115.612378, 3.8218],
          [115.612314, 3.821135],
          [115.612378, 3.820534],
          [115.612314, 3.819774],
          [115.612156, 3.8193],
          [115.61184, 3.818888],
          [115.610953, 3.81816],
          [115.609339, 3.816958],
          [115.608675, 3.816356],
          [115.608105, 3.81509],
          [115.608042, 3.814584],
          [115.608105, 3.813824],
          [115.60839, 3.81297],
          [115.608516, 3.812242],
          [115.608453, 3.81164],
          [115.607472, 3.809489],
          [115.606332, 3.807241],
          [115.605857, 3.805374],
          [115.605066, 3.801861],
          [115.603958, 3.797968],
          [115.603547, 3.797335],
          [115.601458, 3.795215],
          [115.598831, 3.792904],
          [115.596742, 3.790847],
          [115.596426, 3.790309],
          [115.595635, 3.787429],
          [115.595381, 3.785973],
          [115.59516, 3.785467],
          [115.594685, 3.784897],
          [115.594369, 3.784644],
          [115.594179, 3.784296],
          [115.594052, 3.783473],
          [115.593736, 3.782776],
          [115.593293, 3.782175],
          [115.592849, 3.781827],
          [115.591678, 3.781257],
          [115.591299, 3.780972],
          [115.591109, 3.780403],
          [115.590254, 3.776225],
          [115.590064, 3.775623],
          [115.590128, 3.775117],
          [115.590349, 3.774769],
          [115.590792, 3.774611],
          [115.591647, 3.774547],
          [115.591995, 3.774452],
          [115.592406, 3.774231],
          [115.592691, 3.773946],
          [115.592976, 3.773123],
          [115.593514, 3.771034],
          [115.593672, 3.769926],
          [115.593577, 3.769167],
          [115.593198, 3.768312],
          [115.592849, 3.767743],
          [115.592027, 3.766888],
          [115.591488, 3.766223],
          [115.590317, 3.765242],
          [115.589558, 3.764514],
          [115.58902, 3.763691],
          [115.58864, 3.762869],
          [115.587975, 3.761191],
          [115.587754, 3.760463],
          [115.587754, 3.759957],
          [115.587944, 3.759324],
          [115.588862, 3.75752],
          [115.589368, 3.756666],
          [115.589431, 3.756349],
          [115.5894, 3.75597],
          [115.588798, 3.755558],
          [115.587469, 3.754893],
          [115.586393, 3.754482],
          [115.585127, 3.754071],
          [115.584684, 3.753627],
          [115.584367, 3.753058],
          [115.584082, 3.752267],
          [115.583671, 3.751507],
          [115.58307, 3.750874],
          [115.581835, 3.749893],
          [115.581076, 3.749418],
          [115.579651, 3.747519],
          [115.57943, 3.746949],
          [115.579367, 3.746285],
          [115.579367, 3.745335],
          [115.579588, 3.743088],
          [115.580158, 3.740588],
          [115.581614, 3.736251],
          [115.581582, 3.735903],
          [115.581424, 3.735523],
          [115.581361, 3.73508],
          [115.581677, 3.734226],
          [115.58193, 3.733815],
          [115.581899, 3.732074],
          [115.581962, 3.731314],
          [115.582405, 3.728941],
          [115.582342, 3.728339],
          [115.582152, 3.727738],
          [115.581677, 3.727168],
          [115.580506, 3.726092],
          [115.578829, 3.724731],
          [115.578291, 3.724161],
          [115.576455, 3.720395],
          [115.576328, 3.719667],
          [115.576518, 3.718812],
          [115.576771, 3.717958],
          [115.577056, 3.71742],
          [115.577626, 3.71685],
          [115.578449, 3.71647],
          [115.579398, 3.715932],
          [115.580411, 3.714856],
          [115.580728, 3.714033],
          [115.580791, 3.71321],
          [115.580633, 3.712071],
          [115.580664, 3.711533],
          [115.580854, 3.711027],
          [115.581202, 3.710457],
          [115.582089, 3.709476],
          [115.582437, 3.708811],
          [115.582658, 3.70821],
          [115.582911, 3.706945],
          [115.58307, 3.70666],
          [115.583576, 3.706375],
          [115.584684, 3.705963],
          [115.585, 3.705742],
          [115.58519, 3.705394],
          [115.585253, 3.705014],
          [115.585127, 3.704634],
          [115.584526, 3.704064],
          [115.583924, 3.70359],
          [115.583798, 3.703242],
          [115.583766, 3.702703],
          [115.583988, 3.701026],
          [115.584367, 3.697291],
          [115.584367, 3.696532],
          [115.583988, 3.694981],
          [115.583766, 3.694443],
          [115.58326, 3.693746],
          [115.582183, 3.692987],
          [115.581107, 3.692037],
          [115.580379, 3.691468],
          [115.579968, 3.690898],
          [115.57943, 3.689885],
          [115.579113, 3.689442],
          [115.577816, 3.68805],
          [115.576012, 3.686246],
          [115.575727, 3.685739],
          [115.575189, 3.684568],
          [115.574461, 3.682827],
          [115.574144, 3.681688],
          [115.573955, 3.679979],
          [115.573923, 3.679314],
          [115.574049, 3.678143],
          [115.574049, 3.677289],
          [115.573955, 3.676656],
          [115.573986, 3.676023],
          [115.574588, 3.673902],
          [115.575759, 3.671434],
          [115.577025, 3.669218],
          [115.578227, 3.667446],
          [115.578607, 3.666813],
          [115.578829, 3.666275],
          [115.57886, 3.665832],
          [115.578797, 3.665578],
          [115.578512, 3.665072],
          [115.578196, 3.664724],
          [115.577531, 3.664439],
          [115.575189, 3.663743],
          [115.574904, 3.6633],
          [115.574746, 3.662603],
          [115.574714, 3.661907],
          [115.575031, 3.65928],
          [115.574999, 3.658425],
          [115.574809, 3.657539],
          [115.574524, 3.656494],
          [115.573923, 3.654627],
          [115.573765, 3.653741],
          [115.573543, 3.649563],
          [115.573638, 3.647949],
          [115.573765, 3.647316],
          [115.574081, 3.646683],
          [115.575537, 3.644278],
          [115.57617, 3.643328],
          [115.57655, 3.642917],
          [115.576898, 3.642758],
          [115.577563, 3.642568],
          [115.57848, 3.642094],
          [115.578892, 3.641777],
          [115.57924, 3.641176],
          [115.57981, 3.640448],
          [115.581076, 3.638676],
          [115.583038, 3.63453],
          [115.583291, 3.6332],
          [115.583291, 3.632567],
          [115.583101, 3.631934],
          [115.582658, 3.631111],
          [115.582089, 3.630352],
          [115.581392, 3.629592],
          [115.580506, 3.629087],
          [115.57981, 3.628548],
          [115.578101, 3.627599],
          [115.577847, 3.627219],
          [115.577847, 3.626713],
          [115.577911, 3.626206],
          [115.578227, 3.62532],
          [115.579398, 3.623421],
          [115.57981, 3.622282],
          [115.579936, 3.62168],
          [115.579683, 3.620826],
          [115.57693, 3.617376],
          [115.576581, 3.616775],
          [115.576423, 3.616205],
          [115.57636, 3.615414],
          [115.576423, 3.614116],
          [115.576423, 3.612945],
          [115.576138, 3.611426],
          [115.576107, 3.610793],
          [115.57617, 3.610287],
          [115.576297, 3.609875],
          [115.576518, 3.609401],
          [115.57674, 3.609147],
          [115.577056, 3.608957],
          [115.578196, 3.608483],
          [115.57867, 3.608135],
          [115.579018, 3.607818],
          [115.579651, 3.606805],
          [115.579905, 3.606014],
          [115.580095, 3.605191],
          [115.580158, 3.604241],
          [115.580222, 3.599715],
          [115.580507, 3.598323],
          [115.58095, 3.596898],
          [115.581456, 3.596139],
          [115.583292, 3.594272],
          [115.587311, 3.590442],
          [115.588672, 3.589018],
          [115.590001, 3.587467],
          [115.59035, 3.586834],
          [115.590539, 3.586043],
          [115.590255, 3.584777],
          [115.59016, 3.584112],
          [115.590001, 3.582593],
          [115.58997, 3.581612],
          [115.59016, 3.579618],
          [115.590223, 3.579302],
          [115.590413, 3.578922],
          [115.590634, 3.578637],
          [115.591299, 3.578194],
          [115.592343, 3.577656],
          [115.592692, 3.577434],
          [115.593546, 3.576738],
          [115.594242, 3.576453],
          [115.595065, 3.576327],
          [115.596205, 3.576327],
          [115.598135, 3.576643],
          [115.598737, 3.576611],
          [115.599686, 3.576485],
          [115.601522, 3.576675],
          [115.603263, 3.57696],
          [115.604244, 3.577023],
          [115.604782, 3.576865],
          [115.605605, 3.57639],
          [115.605984, 3.576073],
          [115.606111, 3.575599],
          [115.606143, 3.574902],
          [115.605605, 3.571453],
          [115.605541, 3.57025],
          [115.605605, 3.569395],
          [115.605763, 3.568699],
          [115.60858, 3.562971],
          [115.608928, 3.562148],
          [115.609213, 3.560977],
          [115.609371, 3.558824],
          [115.609529, 3.558096],
          [115.610574, 3.555121],
          [115.61089, 3.554425],
          [115.611175, 3.554045],
          [115.611808, 3.55338],
          [115.612378, 3.552589],
          [115.613106, 3.550975],
          [115.613295, 3.550374],
          [115.613359, 3.549582],
          [115.61358, 3.548538],
          [115.614118, 3.546449],
          [115.61453, 3.545278],
          [115.61472, 3.544329],
          [115.614688, 3.543822],
          [115.614561, 3.543126],
          [115.614087, 3.542176],
          [115.613802, 3.541385],
          [115.613739, 3.540878],
          [115.613802, 3.540309],
          [115.614688, 3.536986],
          [115.614751, 3.536258],
          [115.614625, 3.534739],
          [115.614403, 3.533631],
          [115.613232, 3.530656],
          [115.610637, 3.524738],
          [115.610384, 3.523693],
          [115.610352, 3.523092],
          [115.610415, 3.522649],
          [115.610827, 3.521478],
          [115.611302, 3.519864],
          [115.611618, 3.519041],
          [115.611681, 3.51844],
          [115.611618, 3.51787],
          [115.610826, 3.515275],
          [115.610731, 3.514483],
          [115.610731, 3.51385],
          [115.610953, 3.512553],
          [115.611269, 3.511635],
          [115.611966, 3.509989],
          [115.61301, 3.508185],
          [115.614561, 3.505147],
          [115.614972, 3.504577],
          [115.615669, 3.504102],
          [115.616998, 3.503564],
          [115.617441, 3.503248],
          [115.617884, 3.50214],
          [115.618327, 3.50157],
          [115.619467, 3.500779],
          [115.619878, 3.500557],
          [115.620226, 3.500114],
          [115.620384, 3.499196],
          [115.620417, 3.497093],
          [115.6202, 3.49609],
          [115.619684, 3.495362],
          [115.618706, 3.49471],
          [115.618217, 3.494008],
          [115.618265, 3.493057],
          [115.620037, 3.489259],
          [115.62067, 3.487202],
          [115.621208, 3.485113],
          [115.621524, 3.484385],
          [115.621936, 3.483784],
          [115.622948, 3.482582],
          [115.623297, 3.482044],
          [115.62355, 3.481442],
          [115.623676, 3.480809],
          [115.623708, 3.479923],
          [115.623961, 3.477803],
          [115.624214, 3.476917],
          [115.624372, 3.476537],
          [115.624626, 3.476252],
          [115.625227, 3.476157],
          [115.625417, 3.476189],
          [115.625891, 3.476157],
          [115.626936, 3.475999],
          [115.6276, 3.475683],
          [115.628233, 3.475303],
          [115.628961, 3.474733],
          [115.629341, 3.4741],
          [115.629562, 3.473309],
          [115.629752, 3.471442],
          [115.62972, 3.470556],
          [115.629594, 3.469385],
          [115.628993, 3.467138],
          [115.628993, 3.466347],
          [115.629151, 3.465587],
          [115.629436, 3.465018],
          [115.62991, 3.464321],
          [115.630448, 3.463752],
          [115.631239, 3.463214],
          [115.632663, 3.462644],
          [115.633676, 3.462106],
          [115.634277, 3.461726],
          [115.634562, 3.461441],
          [115.63491, 3.460967],
          [115.6351, 3.46027],
          [115.635163, 3.459511],
          [115.6351, 3.458213],
          [115.63491, 3.457169],
          [115.634404, 3.455713],
          [115.634435, 3.455207],
          [115.634625, 3.454827],
          [115.634973, 3.454416],
          [115.636651, 3.453656],
          [115.637157, 3.453371],
          [115.637442, 3.452992],
          [115.637853, 3.451884],
          [115.638106, 3.451726],
          [115.639214, 3.451409],
          [115.640859, 3.451219],
          [115.64203, 3.450966],
          [115.642916, 3.450618],
          [115.644435, 3.449542],
          [115.644815, 3.449067],
          [115.645226, 3.448403],
          [115.645954, 3.44701],
          [115.64684, 3.445839],
          [115.647726, 3.445175],
          [115.648929, 3.444131],
          [115.649941, 3.443371],
          [115.650669, 3.442738],
          [115.651112, 3.442263],
          [115.651967, 3.440839],
          [115.65222, 3.439953],
          [115.652093, 3.43913],
          [115.651682, 3.438497],
          [115.651049, 3.437801],
          [115.6501, 3.436978],
          [115.649245, 3.436567],
          [115.648581, 3.43644],
          [115.646872, 3.436345],
          [115.646112, 3.436187],
          [115.645353, 3.435871],
          [115.644815, 3.435301],
          [115.644467, 3.434447],
          [115.643866, 3.433529],
          [115.643486, 3.432833],
          [115.643296, 3.432231],
          [115.643328, 3.431662],
          [115.643486, 3.431029],
          [115.643802, 3.430333],
          [115.643992, 3.429795],
          [115.644055, 3.429225],
          [115.643739, 3.428719],
          [115.643106, 3.428022],
          [115.641271, 3.426345],
          [115.640828, 3.42587],
          [115.639245, 3.424794],
          [115.636302, 3.42318],
          [115.635764, 3.422642],
          [115.635511, 3.422199],
          [115.635226, 3.420459],
          [115.6351, 3.419383],
          [115.634878, 3.41875],
          [115.63434, 3.418212],
          [115.633549, 3.417832],
          [115.632283, 3.417484],
          [115.631524, 3.417136],
          [115.631113, 3.416724],
          [115.630765, 3.41606],
          [115.630701, 3.415395],
          [115.630416, 3.414699],
          [115.629752, 3.413655],
          [115.629056, 3.413275],
          [115.628296, 3.412958],
          [115.62722, 3.412579],
          [115.6251, 3.412009],
          [115.623992, 3.411629],
          [115.620986, 3.410079],
          [115.62029, 3.409667],
          [115.619499, 3.409446],
          [115.619056, 3.409414],
          [115.618518, 3.409477],
          [115.618043, 3.409636],
          [115.61741, 3.410142],
          [115.616967, 3.410712],
          [115.615891, 3.411693],
          [115.615227, 3.412705],
          [115.615069, 3.413275],
          [115.614974, 3.413908],
          [115.615005, 3.414889],
          [115.615322, 3.417642],
          [115.615164, 3.418687],
          [115.614879, 3.419288],
          [115.614372, 3.419826],
          [115.613486, 3.420554],
          [115.611999, 3.421883],
          [115.611366, 3.422674],
          [115.611145, 3.423339],
          [115.610891, 3.424415],
          [115.610765, 3.425712],
          [115.610638, 3.426503],
          [115.610037, 3.429415],
          [115.608328, 3.437295],
          [115.607569, 3.440365],
          [115.607379, 3.440776],
          [115.606873, 3.441472],
          [115.606303, 3.442105],
          [115.605892, 3.443023],
          [115.604404, 3.447011],
          [115.603961, 3.447643],
          [115.603518, 3.447928],
          [115.603233, 3.447992],
          [115.602569, 3.447928],
          [115.600006, 3.447327],
          [115.598582, 3.447485],
          [115.598202, 3.44739],
          [115.597664, 3.447042],
          [115.597094, 3.446536],
          [115.59624, 3.445903],
          [115.595354, 3.445302],
          [115.594626, 3.444922],
          [115.594056, 3.444732],
          [115.593424, 3.444764],
          [115.592886, 3.445017],
          [115.592284, 3.44565],
          [115.59162, 3.446916],
          [115.59067, 3.44834],
          [115.590101, 3.448783],
          [115.589341, 3.449036],
          [115.587696, 3.449258],
          [115.587126, 3.449511],
          [115.586462, 3.449574],
          [115.586019, 3.449447],
          [115.585512, 3.449226],
          [115.585038, 3.449258],
          [115.583867, 3.449922],
          [115.583329, 3.449985],
          [115.582664, 3.450334],
          [115.581873, 3.45084],
          [115.58143, 3.450998],
          [115.580734, 3.450998],
          [115.580133, 3.450682],
          [115.579626, 3.450207],
          [115.579278, 3.449479],
          [115.577379, 3.443371],
          [115.577063, 3.441884],
          [115.576493, 3.439985],
          [115.575481, 3.436884],
          [115.574658, 3.433972],
          [115.574595, 3.433149],
          [115.574595, 3.43163],
          [115.57469, 3.428371],
          [115.574342, 3.425712],
          [115.574215, 3.42394],
          [115.57393, 3.422168],
          [115.573392, 3.420459],
          [115.572886, 3.419383],
          [115.572221, 3.418845],
          [115.571525, 3.418465],
          [115.570924, 3.418275],
          [115.570101, 3.418085],
          [115.568614, 3.417896],
          [115.568076, 3.418085],
          [115.567569, 3.41818],
          [115.567221, 3.418085],
          [115.567, 3.417706],
          [115.566937, 3.417136],
          [115.567095, 3.416471],
          [115.567158, 3.415775],
          [115.567, 3.414763],
          [115.566747, 3.414003],
          [115.56605, 3.412516],
          [115.565829, 3.411851],
          [115.565481, 3.411218],
          [115.564785, 3.410459],
          [115.564025, 3.409541],
          [115.561145, 3.406946],
          [115.560608, 3.406598],
          [115.559943, 3.406408],
          [115.558994, 3.405996],
          [115.558551, 3.405648],
          [115.557665, 3.404224],
          [115.55738, 3.40337],
          [115.557127, 3.402325],
          [115.557032, 3.401503],
          [115.557032, 3.400616],
          [115.557127, 3.399572],
          [115.557063, 3.398623],
          [115.55662, 3.397294],
          [115.556082, 3.396597],
          [115.555386, 3.395996],
          [115.554975, 3.395743],
          [115.551557, 3.395078],
          [115.550861, 3.394857],
          [115.550608, 3.394635],
          [115.550576, 3.394066],
          [115.550703, 3.393749],
          [115.551968, 3.39223],
          [115.552601, 3.391376],
          [115.552854, 3.390743],
          [115.552918, 3.389857],
          [115.552791, 3.388907],
          [115.55257, 3.388243],
          [115.551019, 3.385204],
          [115.548899, 3.380869],
          [115.548519, 3.380489],
          [115.546842, 3.37935],
          [115.546051, 3.378717],
          [115.544912, 3.377514],
          [115.544025, 3.376375],
          [115.543456, 3.375362],
          [115.54276, 3.37359],
          [115.54238, 3.372767],
          [115.540893, 3.370014],
          [115.540418, 3.368843],
          [115.540228, 3.367894],
          [115.539975, 3.367166],
          [115.538804, 3.364856],
          [115.538171, 3.363336],
          [115.537791, 3.362007],
          [115.537633, 3.360647],
          [115.537601, 3.359602],
          [115.53776, 3.358463],
          [115.538203, 3.357102],
          [115.538582, 3.354412],
          [115.538867, 3.352798],
          [115.539089, 3.351975],
          [115.539405, 3.351374],
          [115.540038, 3.350425],
          [115.540766, 3.34957],
          [115.54257, 3.347735],
          [115.54276, 3.347418],
          [115.542886, 3.347038],
          [115.542886, 3.346817],
          [115.541304, 3.344348],
          [115.541367, 3.343684],
          [115.541589, 3.342861],
          [115.541937, 3.342038],
          [115.54219, 3.341152],
          [115.542222, 3.340108],
          [115.541937, 3.339348],
          [115.541241, 3.338462],
          [115.541241, 3.338082],
          [115.541335, 3.337797],
          [115.541652, 3.337291],
          [115.541747, 3.336658],
          [115.541842, 3.333304],
          [115.541114, 3.329949],
          [115.540513, 3.32881],
          [115.54007, 3.328367],
          [115.539595, 3.328208],
          [115.538994, 3.328272],
          [115.538013, 3.328778],
          [115.537411, 3.328778],
          [115.536747, 3.32843],
          [115.536304, 3.327734],
          [115.536272, 3.327196],
          [115.536051, 3.325519],
          [115.535861, 3.324886],
          [115.534184, 3.32267],
          [115.533076, 3.321499],
          [115.532348, 3.320866],
          [115.531937, 3.320582],
          [115.530639, 3.320265],
          [115.529595, 3.320297],
          [115.528519, 3.320423],
          [115.527127, 3.320677],
          [115.526051, 3.320803],
          [115.525608, 3.320772],
          [115.525165, 3.320708],
          [115.524943, 3.320423],
          [115.524817, 3.320044],
          [115.524659, 3.319474],
          [115.524437, 3.317449],
          [115.524089, 3.313524],
          [115.523899, 3.312164],
          [115.523456, 3.310898],
          [115.523203, 3.310391],
          [115.522918, 3.310107],
          [115.522697, 3.309727],
          [115.522602, 3.309379],
          [115.522697, 3.308936],
          [115.522823, 3.308619],
          [115.523013, 3.308334],
          [115.523361, 3.308081],
          [115.524848, 3.30729],
          [115.525133, 3.306847],
          [115.525165, 3.306435],
          [115.524595, 3.305486],
          [115.524279, 3.305075],
          [115.523709, 3.303682],
          [115.523614, 3.302986],
          [115.523677, 3.302385],
          [115.523614, 3.302005],
          [115.522728, 3.300612],
          [115.522538, 3.300138],
          [115.522475, 3.299726],
          [115.522475, 3.299378],
          [115.522538, 3.29903],
          [115.522633, 3.298777],
          [115.522886, 3.298461],
          [115.523266, 3.298081],
          [115.524247, 3.297353],
          [115.524722, 3.296815],
          [115.524975, 3.296435],
          [115.525355, 3.295422],
          [115.525418, 3.294853],
          [115.525576, 3.294378],
          [115.525798, 3.293935],
          [115.526051, 3.293555],
          [115.526652, 3.292954],
          [115.526874, 3.292669],
          [115.527032, 3.292194],
          [115.528044, 3.289789],
          [115.5295, 3.285833],
          [115.529532, 3.285359],
          [115.529468, 3.284568],
          [115.528994, 3.283903],
          [115.527918, 3.282859],
          [115.527317, 3.282384],
          [115.526874, 3.282099],
          [115.526367, 3.281909],
          [115.525734, 3.281846],
          [115.5245, 3.281909],
          [115.523741, 3.281878],
          [115.523234, 3.281783],
          [115.52276, 3.281498],
          [115.522443, 3.281086],
          [115.522032, 3.280327],
          [115.521874, 3.279124],
          [115.52162, 3.278586],
          [115.521114, 3.278301],
          [115.520639, 3.277858],
          [115.520291, 3.277225],
          [115.519564, 3.275548],
          [115.518677, 3.272795],
          [115.518456, 3.271592],
          [115.518488, 3.271118],
          [115.518867, 3.270421],
          [115.518867, 3.270105],
          [115.518551, 3.268776],
          [115.517918, 3.266719],
          [115.516621, 3.263269],
          [115.516172, 3.261107],
          [115.516172, 3.259402],
          [115.516444, 3.258098],
          [115.517204, 3.256393],
          [115.519214, 3.254186],
          [115.524755, 3.251377],
          [115.525842, 3.250474],
          [115.526703, 3.249358],
          [115.52725, 3.248136],
          [115.527539, 3.247589],
          [115.527314, 3.246946],
          [115.526189, 3.244438],
          [115.52606, 3.243602],
          [115.526092, 3.242734],
          [115.526221, 3.242315],
          [115.526349, 3.242058],
          [115.526703, 3.241737],
          [115.527153, 3.241512],
          [115.528729, 3.241222],
          [115.529501, 3.24119],
          [115.530723, 3.241286],
          [115.531237, 3.241415],
          [115.531977, 3.241158],
          [115.532363, 3.240901],
          [115.532556, 3.24045],
          [115.532556, 3.239775],
          [115.532491, 3.239261],
          [115.53217, 3.23865],
          [115.531559, 3.236977],
          [115.531366, 3.236238],
          [115.531269, 3.235563],
          [115.531237, 3.234598],
          [115.531334, 3.233472],
          [115.531913, 3.228874],
          [115.532009, 3.227748],
          [115.53172, 3.226976],
          [115.53143, 3.226558],
          [115.530948, 3.22614],
          [115.530562, 3.225915],
          [115.529951, 3.22569],
          [115.529115, 3.225497],
          [115.528118, 3.225497],
          [115.527089, 3.225594],
          [115.525931, 3.225947],
          [115.524999, 3.225947],
          [115.524388, 3.225626],
          [115.523809, 3.225111],
          [115.52323, 3.224468],
          [115.52249, 3.22286],
          [115.522105, 3.221381],
          [115.521751, 3.220255],
          [115.521622, 3.219065],
          [115.52159, 3.217168],
          [115.521622, 3.21556],
          [115.521944, 3.213695],
          [115.521976, 3.212859],
          [115.521847, 3.212087],
          [115.52159, 3.211444],
          [115.52085, 3.210222],
          [115.520207, 3.208839],
          [115.520046, 3.208261],
          [115.519982, 3.207746],
          [115.520207, 3.20646],
          [115.5204, 3.205849],
          [115.520432, 3.205366],
          [115.520271, 3.204691],
          [115.519982, 3.204112],
          [115.519853, 3.203565],
          [115.519886, 3.203115],
          [115.520143, 3.201861],
          [115.520336, 3.201153],
          [115.520657, 3.200671],
          [115.520818, 3.200028],
          [115.521301, 3.196362],
          [115.521461, 3.194626],
          [115.521719, 3.1935],
          [115.523037, 3.19022],
          [115.524291, 3.188483],
          [115.524806, 3.187969],
          [115.525578, 3.187647],
          [115.527282, 3.187165],
          [115.527829, 3.186876],
          [115.529501, 3.18559],
          [115.530594, 3.185043],
          [115.532363, 3.184625],
          [115.533038, 3.184528],
          [115.533778, 3.184496],
          [115.53545, 3.184754],
          [115.536189, 3.184721],
          [115.5368, 3.184528],
          [115.537604, 3.184207],
          [115.538183, 3.183789],
          [115.540145, 3.181827],
          [115.540531, 3.181506],
          [115.541174, 3.181441],
          [115.541689, 3.181538],
          [115.542235, 3.181763],
          [115.542686, 3.182117],
          [115.543329, 3.182406],
          [115.544068, 3.182535],
          [115.545194, 3.182438],
          [115.545901, 3.182117],
          [115.546866, 3.181152],
          [115.547445, 3.180477],
          [115.548635, 3.179769],
          [115.549664, 3.179062],
          [115.550564, 3.178353],
          [115.552301, 3.176842],
          [115.553523, 3.175652],
          [115.555163, 3.173755],
          [115.555806, 3.173369],
          [115.557124, 3.172854],
          [115.557864, 3.17279],
          [115.560276, 3.173015],
          [115.561015, 3.172951],
          [115.561369, 3.17279],
          [115.562527, 3.172051],
          [115.56362, 3.171504],
          [115.564231, 3.171086],
          [115.564521, 3.170603],
          [115.564746, 3.170089],
          [115.56481, 3.168513],
          [115.565035, 3.166648],
          [115.565292, 3.165651],
          [115.565678, 3.164912],
          [115.566547, 3.163851],
          [115.567415, 3.162918],
          [115.567479, 3.162629],
          [115.567383, 3.162243],
          [115.567061, 3.161953],
          [115.565839, 3.161632],
          [115.565099, 3.161278],
          [115.564714, 3.160989],
          [115.564521, 3.160667],
          [115.564649, 3.159509],
          [115.564746, 3.159059],
          [115.564681, 3.15877],
          [115.564296, 3.15832],
          [115.563685, 3.157869],
          [115.563331, 3.157451],
          [115.563106, 3.156776],
          [115.563266, 3.153592],
          [115.563073, 3.152949],
          [115.56272, 3.152434],
          [115.562205, 3.152113],
          [115.561401, 3.151791],
          [115.560919, 3.151727],
          [115.560308, 3.151791],
          [115.559504, 3.152145],
          [115.558861, 3.152273],
          [115.558185, 3.152145],
          [115.55796, 3.151888],
          [115.557928, 3.151277],
          [115.558185, 3.149283],
          [115.558025, 3.148608],
          [115.557896, 3.148382],
          [115.557285, 3.147868],
          [115.55661, 3.147],
          [115.555902, 3.144717],
          [115.555388, 3.143655],
          [115.555066, 3.143141],
          [115.554487, 3.142658],
          [115.553748, 3.142466],
          [115.550853, 3.142305],
          [115.548056, 3.14208],
          [115.545901, 3.142048],
          [115.545258, 3.14224],
          [115.544229, 3.142916],
          [115.543071, 3.143881],
          [115.542235, 3.144395],
          [115.541592, 3.144524],
          [115.541142, 3.144556],
          [115.540209, 3.144395],
          [115.539309, 3.143752],
          [115.538665, 3.143012],
          [115.537958, 3.142305],
          [115.537315, 3.141404],
          [115.536672, 3.14015],
          [115.53635, 3.139057],
          [115.536157, 3.138639],
          [115.535803, 3.138221],
          [115.535257, 3.13777],
          [115.534839, 3.137288],
          [115.534388, 3.136452],
          [115.534099, 3.135423],
          [115.533584, 3.134008],
          [115.533263, 3.132786],
          [115.533263, 3.131596],
          [115.533038, 3.130567],
          [115.532588, 3.129152],
          [115.532041, 3.126194],
          [115.530659, 3.121177],
          [115.530144, 3.119473],
          [115.529469, 3.118025],
          [115.529244, 3.117672],
          [115.528568, 3.117382],
          [115.526607, 3.116964],
          [115.525738, 3.116611],
          [115.524999, 3.116128],
          [115.524291, 3.115839],
          [115.52368, 3.115743],
          [115.52249, 3.115839],
          [115.521912, 3.115646],
          [115.520368, 3.11481],
          [115.519628, 3.11436],
          [115.519339, 3.114006],
          [115.51905, 3.113556],
          [115.518921, 3.112848],
          [115.518889, 3.112398],
          [115.518664, 3.111787],
          [115.51712, 3.110147],
          [115.516766, 3.109472],
          [115.51667, 3.108796],
          [115.516734, 3.108378],
          [115.517088, 3.107607],
          [115.517795, 3.106256],
          [115.518053, 3.105613],
          [115.518374, 3.103683],
          [115.518374, 3.10304],
          [115.51831, 3.102429],
          [115.517442, 3.100371],
          [115.517152, 3.099439],
          [115.516734, 3.098442],
          [115.514933, 3.096255],
          [115.514129, 3.095483],
          [115.513872, 3.095001],
          [115.513711, 3.09439],
          [115.513679, 3.093714],
          [115.513872, 3.0932],
          [115.514515, 3.092396],
          [115.515609, 3.090884],
          [115.515962, 3.090273],
          [115.516059, 3.089469],
          [115.515866, 3.08635],
          [115.515898, 3.085418],
          [115.516606, 3.081752],
          [115.51667, 3.080658],
          [115.516606, 3.078118],
          [115.51667, 3.075867],
          [115.516927, 3.075352],
          [115.518728, 3.072619],
          [115.519146, 3.071783],
          [115.519982, 3.068889],
          [115.519564, 3.065898],
          [115.519468, 3.064547],
          [115.519628, 3.063904],
          [115.519982, 3.062972],
          [115.520175, 3.061943],
          [115.520239, 3.061299],
          [115.519725, 3.058405],
          [115.519564, 3.057923],
          [115.519146, 3.057344],
          [115.518117, 3.05564],
          [115.517763, 3.055222],
          [115.517345, 3.054611],
          [115.516188, 3.053646],
          [115.515287, 3.053003],
          [115.513004, 3.051106],
          [115.512361, 3.05072],
          [115.512103, 3.05043],
          [115.512007, 3.049948],
          [115.512007, 3.04953],
          [115.512264, 3.048533],
          [115.512232, 3.048051],
          [115.512103, 3.047343],
          [115.51056, 3.043902],
          [115.510238, 3.043099],
          [115.509981, 3.04268],
          [115.509531, 3.042423],
          [115.508952, 3.042198],
          [115.505961, 3.040751],
          [115.505382, 3.040365],
          [115.505028, 3.040011],
          [115.504514, 3.038371],
          [115.504096, 3.037278],
          [115.503903, 3.036635],
          [115.503517, 3.036152],
          [115.502906, 3.035606],
          [115.500202, 3.035293],
          [115.499004, 3.0329],
          [115.495659, 3.029842],
          [115.48962, 3.027098],
          [115.483647, 3.014589],
          [115.483276, 3.019637],
          [115.47754, 3.021609],
          [115.474172, 3.021917],
          [115.46405, 3.025537],
          [115.453258, 3.028477],
          [115.451924, 3.026782],
          [115.446889, 3.024719],
          [115.442852, 3.024348],
          [115.440195, 3.019275],
          [115.439213, 3.015225],
          [115.435181, 3.014181],
          [115.433477, 3.017198],
          [115.429102, 3.017161],
          [115.424766, 3.011399],
          [115.421274, 2.999331],
          [115.421402, 2.99901],
          [115.421467, 2.998592],
          [115.421306, 2.998174],
          [115.420952, 2.997627],
          [115.420148, 2.996887],
          [115.419248, 2.996148],
          [115.418122, 2.995569],
          [115.417318, 2.995086],
          [115.416546, 2.993864],
          [115.416193, 2.993479],
          [115.41571, 2.993189],
          [115.414874, 2.992996],
          [115.413331, 2.993028],
          [115.41288, 2.992835],
          [115.412398, 2.992353],
          [115.412012, 2.991581],
          [115.411755, 2.990809],
          [115.410469, 2.988269],
          [115.409955, 2.987401],
          [115.409344, 2.986597],
          [115.408443, 2.985986],
          [115.4078, 2.985343],
          [115.407478, 2.9847],
          [115.407221, 2.983671],
          [115.407028, 2.983188],
          [115.406707, 2.982706],
          [115.406385, 2.98232],
          [115.405871, 2.982095],
          [115.402269, 2.981227],
          [115.399503, 2.979876],
          [115.398699, 2.979394],
          [115.397316, 2.978911],
          [115.396705, 2.978911],
          [115.395966, 2.979137],
          [115.395162, 2.979297],
          [115.393876, 2.979137],
          [115.392782, 2.979104],
          [115.391721, 2.979169],
          [115.391046, 2.97933],
          [115.390467, 2.979683],
          [115.389984, 2.980133],
          [115.388923, 2.980744],
          [115.387862, 2.980712],
          [115.386093, 2.980455],
          [115.385579, 2.980423],
          [115.385289, 2.980616],
          [115.384968, 2.980937],
          [115.384582, 2.981548],
          [115.383906, 2.982802],
          [115.383263, 2.983317],
          [115.382524, 2.983735],
          [115.38172, 2.983864],
          [115.380851, 2.983896],
          [115.379983, 2.983671],
          [115.379276, 2.983349],
          [115.378665, 2.982963],
          [115.378054, 2.982449],
          [115.377604, 2.981838],
          [115.377314, 2.981195],
          [115.376864, 2.980359],
          [115.376671, 2.979651],
          [115.376446, 2.979297],
          [115.376028, 2.979169],
          [115.375513, 2.979169],
          [115.375063, 2.979297],
          [115.373841, 2.98023],
          [115.373037, 2.980777],
          [115.37233, 2.98113],
          [115.370883, 2.981484],
          [115.369596, 2.981709],
          [115.368407, 2.981838],
          [115.36622, 2.981388],
          [115.365158, 2.980905],
          [115.364258, 2.980391],
          [115.363711, 2.980166],
          [115.363036, 2.980101],
          [115.362489, 2.980198],
          [115.361557, 2.980584],
          [115.36101, 2.980712],
          [115.360495, 2.980616],
          [115.359659, 2.980037],
          [115.358952, 2.97978],
          [115.358373, 2.97978],
          [115.357376, 2.980198],
          [115.356508, 2.980262],
          [115.355704, 2.980166],
          [115.353774, 2.979394],
          [115.353067, 2.978879],
          [115.352713, 2.978526],
          [115.352295, 2.977915],
          [115.351556, 2.976532],
          [115.351234, 2.976114],
          [115.35043, 2.975278],
          [115.350012, 2.975085],
          [115.34953, 2.97502],
          [115.348661, 2.975117],
          [115.348082, 2.975439],
          [115.347118, 2.975824],
          [115.3467, 2.97605],
          [115.346282, 2.97666],
          [115.34596, 2.97695],
          [115.345445, 2.977111],
          [115.344545, 2.977143],
          [115.343548, 2.976886],
          [115.342712, 2.976725],
          [115.342069, 2.976403],
          [115.341715, 2.97605],
          [115.341426, 2.975567],
          [115.340943, 2.975149],
          [115.340268, 2.97486],
          [115.339368, 2.974667],
          [115.338596, 2.974667],
          [115.337663, 2.974538],
          [115.336055, 2.97412],
          [115.335541, 2.973831],
          [115.335026, 2.973734],
          [115.334608, 2.973831],
          [115.334158, 2.974056],
          [115.33329, 2.974667],
          [115.332807, 2.974956],
          [115.331457, 2.976146],
          [115.331103, 2.976403],
          [115.330299, 2.976435],
          [115.329913, 2.976532],
          [115.329624, 2.976693],
          [115.329174, 2.977111],
          [115.328756, 2.977625],
          [115.327984, 2.978976],
          [115.327694, 2.979844],
          [115.327469, 2.980551],
          [115.327405, 2.98097],
          [115.327469, 2.981452],
          [115.327662, 2.982288],
          [115.327759, 2.983124],
          [115.328209, 2.98589],
          [115.328402, 2.986597],
          [115.32882, 2.987272],
          [115.329431, 2.988784],
          [115.329817, 2.990713],
          [115.329817, 2.991356],
          [115.329527, 2.992353],
          [115.329174, 2.992932],
          [115.328273, 2.993993],
          [115.327566, 2.994636],
          [115.326987, 2.995344],
          [115.326698, 2.995955],
          [115.326569, 2.996469],
          [115.32644, 2.998206],
          [115.32644, 2.998753],
          [115.326279, 2.999299],
          [115.323143, 3.005821],
          [115.322764, 3.01188],
          [115.32073, 3.013883],
          [115.313632, 3.017864],
          [115.307895, 3.019836],
          [115.3055, 3.025205],
          [115.306814, 3.029931],
          [115.305102, 3.033958],
          [115.301735, 3.03393],
          [115.298663, 3.039966],
          [115.294286, 3.039929],
          [115.285465, 3.049621],
          [115.284149, 3.045231],
          [115.283186, 3.038487],
          [115.278845, 3.033397],
          [115.276541, 3.025967],
          [115.275911, 3.019899],
          [115.268526, 3.016804],
          [115.266203, 3.012069],
          [115.260843, 3.001507],
          [115.257993, 2.999363],
          [115.255484, 2.994893],
          [115.255098, 2.993993],
          [115.254873, 2.993221],
          [115.254777, 2.992417],
          [115.254777, 2.991678],
          [115.254906, 2.990488],
          [115.255549, 2.988366],
          [115.255709, 2.98724],
          [115.255742, 2.986018],
          [115.255709, 2.985214],
          [115.255806, 2.983574],
          [115.255742, 2.98277],
          [115.255774, 2.982159],
          [115.255709, 2.980744],
          [115.255517, 2.980423],
          [115.254648, 2.979844],
          [115.253876, 2.979587],
          [115.253362, 2.979265],
          [115.252912, 2.978815],
          [115.252513, 2.972592],
          [115.249122, 2.966703],
          [115.243536, 2.96164],
          [115.238805, 2.96111],
          [115.228659, 2.955686],
          [115.22393, 2.955827],
          [115.217515, 2.9573],
          [115.209404, 2.955745],
          [115.200096, 2.947642],
          [115.192308, 2.939715],
          [115.187222, 2.931132],
          [115.180971, 2.930594],
          [115.168976, 2.929016],
          [115.163388, 2.922444],
          [115.159325, 2.91806],
          [115.155604, 2.915859],
          [115.151546, 2.913823],
          [115.150015, 2.908951],
          [115.151008, 2.899732],
          [115.151162, 2.893192],
          [115.147602, 2.887134],
          [115.150794, 2.87994],
          [115.151115, 2.872227],
          [115.139279, 2.86629],
          [115.137409, 2.860745],
          [115.135372, 2.856037],
          [115.127254, 2.85096],
          [115.124208, 2.848091],
          [115.12403, 2.844065],
          [115.118446, 2.839338],
          [115.113873, 2.833274],
          [115.108974, 2.832743],
          [115.102214, 2.830357],
          [115.089701, 2.823242],
          [115.095094, 2.818073],
          [115.10774, 2.809592],
          [115.113811, 2.805936],
          [115.124099, 2.800124],
          [115.128665, 2.803169],
          [115.134746, 2.803706],
          [115.142343, 2.802575],
          [115.146723, 2.798071],
          [115.149749, 2.792051],
          [115.152945, 2.786702],
          [115.149392, 2.783495],
          [115.143809, 2.779439],
          [115.138904, 2.776057],
          [115.137544, 2.772024],
          [115.137532, 2.766825],
          [115.138688, 2.755426],
          [115.141823, 2.749337],
          [115.141694, 2.748886],
          [115.141469, 2.7476],
          [115.141501, 2.745349],
          [115.141373, 2.744706],
          [115.141147, 2.74432],
          [115.140183, 2.743227],
          [115.139507, 2.742551],
          [115.138832, 2.742294],
          [115.13835, 2.741973],
          [115.138157, 2.741104],
          [115.1379, 2.740461],
          [115.13761, 2.740108],
          [115.137031, 2.73969],
          [115.135616, 2.73924],
          [115.134105, 2.73895],
          [115.133686, 2.738757],
          [115.133268, 2.738371],
          [115.132915, 2.737407],
          [115.132497, 2.736763],
          [115.132046, 2.736313],
          [115.131339, 2.735477],
          [115.130953, 2.734802],
          [115.130471, 2.734094],
          [115.129409, 2.732744],
          [115.128991, 2.732326],
          [115.128638, 2.7321],
          [115.128123, 2.73194],
          [115.126708, 2.73165],
          [115.125904, 2.7312],
          [115.125197, 2.730653],
          [115.124779, 2.730139],
          [115.124457, 2.729464],
          [115.12391, 2.72882],
          [115.123299, 2.728241],
          [115.122206, 2.727341],
          [115.121595, 2.727116],
          [115.120051, 2.727212],
          [115.119537, 2.727116],
          [115.11928, 2.726827],
          [115.118315, 2.725058],
          [115.117929, 2.724608],
          [115.116322, 2.723996],
          [115.114585, 2.723417],
          [115.113106, 2.722517],
          [115.112495, 2.722099],
          [115.110919, 2.721617],
          [115.110308, 2.721552],
          [115.109601, 2.721359],
          [115.10899, 2.720941],
          [115.108475, 2.720652],
          [115.108154, 2.720202],
          [115.107768, 2.719205],
          [115.107157, 2.718915],
          [115.106674, 2.718947],
          [115.10587, 2.719173],
          [115.105452, 2.719044],
          [115.105195, 2.718851],
          [115.105099, 2.718337],
          [115.105066, 2.717726],
          [115.104873, 2.717372],
          [115.10452, 2.717018],
          [115.103877, 2.716857],
          [115.103458, 2.716825],
          [115.10259, 2.716986],
          [115.101368, 2.717115],
          [115.100693, 2.716922],
          [115.100339, 2.716568],
          [115.100114, 2.716021],
          [115.10005, 2.714703],
          [115.099921, 2.713995],
          [115.099664, 2.713642],
          [115.099181, 2.713352],
          [115.098989, 2.712998],
          [115.099117, 2.712227],
          [115.099471, 2.711519],
          [115.099889, 2.710329],
          [115.100018, 2.70959],
          [115.100114, 2.708078],
          [115.100082, 2.707564],
          [115.0996, 2.706277],
          [115.097734, 2.703351],
          [115.096448, 2.700939],
          [115.096094, 2.700135],
          [115.095548, 2.699492],
          [115.094904, 2.698881],
          [115.094454, 2.698528],
          [115.093747, 2.698399],
          [115.093136, 2.698463],
          [115.092686, 2.69856],
          [115.092075, 2.698431],
          [115.09201, 2.698174],
          [115.092042, 2.697852],
          [115.092717, 2.697338],
          [115.093039, 2.697016],
          [115.093457, 2.696502],
          [115.093553, 2.696084],
          [115.093521, 2.695601],
          [115.093167, 2.695054],
          [115.092782, 2.69409],
          [115.092846, 2.693736],
          [115.093296, 2.693029],
          [115.093939, 2.692643],
          [115.094261, 2.692385],
          [115.095965, 2.691903],
          [115.097316, 2.691935],
          [115.099631, 2.691774],
          [115.100339, 2.691549],
          [115.100789, 2.691292],
          [115.101464, 2.690456],
          [115.102429, 2.688526],
          [115.102879, 2.687819],
          [115.103201, 2.687111],
          [115.103683, 2.686275],
          [115.104198, 2.6856],
          [115.104551, 2.685375],
          [115.10497, 2.685214],
          [115.105516, 2.685182],
          [115.106192, 2.685214],
          [115.106674, 2.685311],
          [115.107188, 2.685503],
          [115.10825, 2.686307],
          [115.108475, 2.686533],
          [115.108539, 2.686758],
          [115.108828, 2.687047],
          [115.109182, 2.687079],
          [115.109632, 2.686758],
          [115.10989, 2.686436],
          [115.110533, 2.685889],
          [115.110951, 2.685696],
          [115.111273, 2.685632],
          [115.111626, 2.685471],
          [115.111851, 2.68515],
          [115.112012, 2.6847],
          [115.112173, 2.683574],
          [115.112109, 2.681709],
          [115.111819, 2.681162],
          [115.110822, 2.680326],
          [115.109311, 2.679651],
          [115.108603, 2.679426],
          [115.108249, 2.679136],
          [115.10796, 2.678815],
          [115.107156, 2.677753],
          [115.106867, 2.677175],
          [115.10677, 2.676435],
          [115.106802, 2.675342],
          [115.106738, 2.67383],
          [115.106899, 2.672833],
          [115.107188, 2.672126],
          [115.107864, 2.671451],
          [115.108089, 2.671],
          [115.108475, 2.670518],
          [115.108668, 2.670197],
          [115.108893, 2.669457],
          [115.108957, 2.668975],
          [115.109086, 2.668653],
          [115.109664, 2.667978],
          [115.109922, 2.667431],
          [115.110018, 2.666852],
          [115.110115, 2.665791],
          [115.110275, 2.665309],
          [115.110758, 2.664569],
          [115.111497, 2.663572],
          [115.111916, 2.662897],
          [115.112398, 2.66235],
          [115.112623, 2.662157],
          [115.112752, 2.661804],
          [115.112816, 2.661353],
          [115.112784, 2.660485],
          [115.112719, 2.660003],
          [115.112784, 2.659488],
          [115.11317, 2.65833],
          [115.113395, 2.657784],
          [115.113459, 2.657269],
          [115.113395, 2.656723],
          [115.113459, 2.655854],
          [115.114392, 2.654086],
          [115.114585, 2.653024],
          [115.114552, 2.651834],
          [115.11481, 2.650741],
          [115.115067, 2.650162],
          [115.115421, 2.649615],
          [115.116868, 2.648072],
          [115.116997, 2.647525],
          [115.117157, 2.647171],
          [115.117093, 2.646946],
          [115.116707, 2.646818],
          [115.116128, 2.646786],
          [115.115131, 2.646818],
          [115.113588, 2.646946],
          [115.113009, 2.646721],
          [115.112752, 2.6464],
          [115.112205, 2.645274],
          [115.11153, 2.644277],
          [115.11124, 2.644052],
          [115.110758, 2.643859],
          [115.110083, 2.643859],
          [115.109729, 2.643924],
          [115.109343, 2.644117],
          [115.108732, 2.64402],
          [115.108571, 2.64357],
          [115.108603, 2.643088],
          [115.108507, 2.641576],
          [115.108314, 2.641222],
          [115.107574, 2.640676],
          [115.107446, 2.640418],
          [115.107414, 2.640161],
          [115.10751, 2.639389],
          [115.107671, 2.638778],
          [115.107703, 2.638457],
          [115.108185, 2.637331],
          [115.108764, 2.635563],
          [115.1087, 2.634405],
          [115.108539, 2.633633],
          [115.108378, 2.633215],
          [115.108025, 2.632701],
          [115.10751, 2.632122],
          [115.107189, 2.631897],
          [115.106674, 2.631607],
          [115.105002, 2.631414],
          [115.104423, 2.630932],
          [115.103748, 2.629646],
          [115.103298, 2.629131],
          [115.102654, 2.628617],
          [115.102108, 2.628295],
          [115.101593, 2.627909],
          [115.101175, 2.627523],
          [115.100693, 2.626559],
          [115.100307, 2.626012],
          [115.09931, 2.624822],
          [115.098603, 2.624275],
          [115.098088, 2.623697],
          [115.097509, 2.622571],
          [115.097348, 2.622378],
          [115.09693, 2.622121],
          [115.096416, 2.621896],
          [115.095869, 2.621703],
          [115.095097, 2.62151],
          [115.094293, 2.621156],
          [115.093586, 2.620674],
          [115.09275, 2.620352],
          [115.091882, 2.620159],
          [115.091335, 2.620159],
          [115.090981, 2.620224],
          [115.08992, 2.620513],
          [115.088923, 2.620738],
          [115.088441, 2.620803],
          [115.088055, 2.620706],
          [115.087733, 2.620256],
          [115.087283, 2.618712],
          [115.086608, 2.617555],
          [115.086318, 2.616944],
          [115.085418, 2.615754],
          [115.084582, 2.614821],
          [115.084357, 2.614403],
          [115.084132, 2.613953],
          [115.084035, 2.613406],
          [115.084003, 2.612924],
          [115.084099, 2.612409],
          [115.084228, 2.611991],
          [115.084518, 2.611444],
          [115.085032, 2.61064],
          [115.085804, 2.609836],
          [115.086158, 2.609032],
          [115.086318, 2.608485],
          [115.086383, 2.606878],
          [115.086543, 2.605205],
          [115.086672, 2.604562],
          [115.086801, 2.604176],
          [115.087219, 2.603437],
          [115.087862, 2.603051],
          [115.090338, 2.602826],
          [115.092042, 2.602504],
          [115.094036, 2.601797],
          [115.094358, 2.601572],
          [115.09484, 2.600896],
          [115.095097, 2.600253],
          [115.095226, 2.599385],
          [115.095387, 2.59887],
          [115.095676, 2.59871],
          [115.096062, 2.598645],
          [115.096544, 2.598742],
          [115.097252, 2.599096],
          [115.097734, 2.599256],
          [115.09841, 2.599224],
          [115.101111, 2.598549],
          [115.101947, 2.598163],
          [115.102172, 2.597938],
          [115.102494, 2.59723],
          [115.102654, 2.596523],
          [115.102912, 2.596041],
          [115.102944, 2.595526],
          [115.102815, 2.594754],
          [115.103008, 2.593757],
          [115.103265, 2.593404],
          [115.104069, 2.592728],
          [115.104198, 2.592375],
          [115.10423, 2.590992],
          [115.104037, 2.58961],
          [115.103716, 2.588548],
          [115.103426, 2.587905],
          [115.103072, 2.586876],
          [115.103008, 2.586329],
          [115.103201, 2.585654],
          [115.103555, 2.585268],
          [115.104391, 2.584689],
          [115.104841, 2.584239],
          [115.106063, 2.582663],
          [115.106417, 2.582342],
          [115.106738, 2.582149],
          [115.1087, 2.581248],
          [115.10989, 2.581023],
          [115.110565, 2.581055],
          [115.11108, 2.581281],
          [115.111594, 2.581634],
          [115.112687, 2.582663],
          [115.113138, 2.583306],
          [115.113845, 2.58395],
          [115.114681, 2.584432],
          [115.116386, 2.585236],
          [115.117125, 2.585461],
          [115.118154, 2.585622],
          [115.119376, 2.585461],
          [115.120213, 2.585171],
          [115.12211, 2.584625],
          [115.124039, 2.583853],
          [115.125004, 2.583531],
          [115.126708, 2.583274],
          [115.127127, 2.583146],
          [115.127802, 2.582631],
          [115.128156, 2.582502],
          [115.128477, 2.582535],
          [115.128895, 2.582695],
          [115.129474, 2.58321],
          [115.130921, 2.585718],
          [115.131661, 2.587615],
          [115.132014, 2.588194],
          [115.132336, 2.588612],
          [115.132818, 2.58903],
          [115.133558, 2.589481],
          [115.133815, 2.589738],
          [115.133912, 2.590059],
          [115.133333, 2.591989],
          [115.133301, 2.592407],
          [115.133397, 2.5935],
          [115.133751, 2.593918],
          [115.13404, 2.594368],
          [115.134619, 2.594883],
          [115.136291, 2.596523],
          [115.136838, 2.597295],
          [115.137095, 2.597873],
          [115.138221, 2.599706],
          [115.138896, 2.600478],
          [115.139186, 2.600735],
          [115.139668, 2.600832],
          [115.139989, 2.6008],
          [115.140954, 2.60051],
          [115.141404, 2.600478],
          [115.141855, 2.600671],
          [115.142305, 2.601379],
          [115.142755, 2.601861],
          [115.143269, 2.602279],
          [115.144073, 2.602761],
          [115.144684, 2.603244],
          [115.145392, 2.603919],
          [115.145746, 2.604209],
          [115.146035, 2.60437],
          [115.146421, 2.604498],
          [115.147064, 2.60453],
          [115.147482, 2.604434],
          [115.151566, 2.602826],
          [115.152724, 2.602312],
          [115.15356, 2.602119],
          [115.153817, 2.602183],
          [115.154653, 2.602923],
          [115.155425, 2.603791],
          [115.155779, 2.604112],
          [115.158127, 2.605752],
          [115.159027, 2.606781],
          [115.159574, 2.607328],
          [115.160828, 2.608293],
          [115.161439, 2.608711],
          [115.162114, 2.608968],
          [115.162789, 2.609065],
          [115.163336, 2.609097],
          [115.163626, 2.609],
          [115.163851, 2.608711],
          [115.164719, 2.607135],
          [115.165169, 2.606653],
          [115.165523, 2.606396],
          [115.166005, 2.606203],
          [115.166809, 2.60601],
          [115.16742, 2.605978],
          [115.16816, 2.606106],
          [115.169092, 2.606396],
          [115.1698, 2.606556],
          [115.171247, 2.606492],
          [115.171601, 2.606299],
          [115.171858, 2.605978],
          [115.172212, 2.60527],
          [115.173048, 2.601058],
          [115.173273, 2.599257],
          [115.17382, 2.597585],
          [115.17456, 2.596073],
          [115.174656, 2.595559],
          [115.174624, 2.595205],
          [115.174527, 2.594948],
          [115.174656, 2.59424],
          [115.174913, 2.593951],
          [115.175331, 2.59379],
          [115.176392, 2.593693],
          [115.176746, 2.593565],
          [115.178032, 2.592568],
          [115.178643, 2.592246],
          [115.179319, 2.592021],
          [115.181924, 2.591378],
          [115.182181, 2.591185],
          [115.182374, 2.590928],
          [115.182535, 2.590574],
          [115.182631, 2.590027],
          [115.182856, 2.58977],
          [115.18321, 2.589545],
          [115.183949, 2.589384],
          [115.184335, 2.589223],
          [115.184721, 2.588966],
          [115.184914, 2.588741],
          [115.185139, 2.588355],
          [115.185493, 2.586394],
          [115.185814, 2.583949],
          [115.185782, 2.58321],
          [115.185975, 2.582534],
          [115.186039, 2.582149],
          [115.185911, 2.581731],
          [115.185654, 2.581441],
          [115.185461, 2.581023],
          [115.185428, 2.580701],
          [115.185493, 2.580283],
          [115.186007, 2.579158],
          [115.186329, 2.578804],
          [115.186586, 2.578611],
          [115.188837, 2.57755],
          [115.191217, 2.575942],
          [115.192053, 2.575524],
          [115.192825, 2.575299],
          [115.193211, 2.575041],
          [115.193436, 2.57472],
          [115.193822, 2.573337],
          [115.194111, 2.572694],
          [115.194304, 2.572501],
          [115.194883, 2.572083],
          [115.195204, 2.571954],
          [115.19559, 2.571665],
          [115.195944, 2.571215],
          [115.196266, 2.5707],
          [115.196716, 2.570282],
          [115.197327, 2.569961],
          [115.197648, 2.569896],
          [115.198452, 2.569896],
          [115.199321, 2.570057],
          [115.199835, 2.57025],
          [115.200221, 2.570507],
          [115.201089, 2.570732],
          [115.201957, 2.570636],
          [115.202794, 2.570443],
          [115.203083, 2.570282],
          [115.203469, 2.569735],
          [115.203597, 2.569414],
          [115.203372, 2.568964],
          [115.202729, 2.568224],
          [115.202375, 2.567517],
          [115.202215, 2.566906],
          [115.202215, 2.566134],
          [115.202344, 2.565684],
          [115.202537, 2.565395],
          [115.202794, 2.565202],
          [115.203566, 2.565041],
          [115.206556, 2.56398],
          [115.207232, 2.563626],
          [115.20765, 2.563208],
          [115.207843, 2.562854],
          [115.207971, 2.562404],
          [115.207907, 2.561986],
          [115.207939, 2.561471],
          [115.208068, 2.560957],
          [115.208486, 2.560056],
          [115.209451, 2.558416],
          [115.209708, 2.557644],
          [115.210222, 2.55639],
          [115.209965, 2.555875],
          [115.209579, 2.555296],
          [115.207907, 2.553238],
          [115.207103, 2.552049],
          [115.206653, 2.550762],
          [115.206524, 2.549958],
          [115.206589, 2.549283],
          [115.206846, 2.548158],
          [115.207135, 2.547257],
          [115.207843, 2.546196],
          [115.208357, 2.545521],
          [115.208968, 2.545135],
          [115.209483, 2.544717],
          [115.21019, 2.544234],
          [115.21048, 2.543913],
          [115.211058, 2.543012],
          [115.211284, 2.542498],
          [115.211541, 2.540858],
          [115.211862, 2.539861],
          [115.212184, 2.539089],
          [115.212473, 2.538768],
          [115.212859, 2.538703],
          [115.213181, 2.538768],
          [115.213406, 2.539057],
          [115.213792, 2.539314],
          [115.214242, 2.539507],
          [115.214789, 2.539507],
          [115.2163, 2.538961],
          [115.2172, 2.538575],
          [115.217683, 2.538189],
          [115.218004, 2.537771],
          [115.218487, 2.537353],
          [115.219194, 2.536645],
          [115.219741, 2.536452],
          [115.22077, 2.536227],
          [115.221895, 2.535777],
          [115.222892, 2.535198],
          [115.224371, 2.534201],
          [115.224661, 2.533751],
          [115.225079, 2.533365],
          [115.225786, 2.533108],
          [115.22614, 2.533044],
          [115.227652, 2.532947],
          [115.228038, 2.532979],
          [115.228295, 2.533108],
          [115.228423, 2.533237],
          [115.228777, 2.533944],
          [115.229002, 2.534266],
          [115.229324, 2.534491],
          [115.22971, 2.534651],
          [115.230192, 2.534748],
          [115.230771, 2.534941],
          [115.231639, 2.535584],
          [115.232057, 2.535841],
          [115.232797, 2.536034],
          [115.237481, 2.528042],
          [115.237426, 2.505989],
          [115.221404, 2.490908],
          [115.21074, 2.487322],
          [115.207762, 2.480916],
          [115.204482, 2.480852],
          [115.20326, 2.480787],
          [115.202327, 2.480562],
          [115.201588, 2.480015],
          [115.201009, 2.479726],
          [115.200301, 2.479115],
          [115.199626, 2.478408],
          [115.197922, 2.47516],
          [115.197278, 2.474259],
          [115.196635, 2.473681],
          [115.195317, 2.471719],
          [115.194706, 2.471333],
          [115.194095, 2.471237],
          [115.193645, 2.471301],
          [115.193227, 2.471623],
          [115.192776, 2.472233],
          [115.191876, 2.47323],
          [115.190847, 2.474806],
          [115.189979, 2.475642],
          [115.189239, 2.476157],
          [115.18821, 2.476671],
          [115.187631, 2.477025],
          [115.186184, 2.477572],
          [115.185509, 2.477925],
          [115.185026, 2.478375],
          [115.184801, 2.478761],
          [115.184737, 2.479244],
          [115.184801, 2.479662],
          [115.184737, 2.480015],
          [115.184383, 2.480241],
          [115.183, 2.480401],
          [115.182357, 2.48053],
          [115.18091, 2.481527],
          [115.180299, 2.481848],
          [115.179688, 2.481848],
          [115.179302, 2.481752],
          [115.178563, 2.481237],
          [115.178241, 2.480723],
          [115.177341, 2.479951],
          [115.176826, 2.479726],
          [115.176376, 2.479662],
          [115.175604, 2.479662],
          [115.1739, 2.479565],
          [115.173257, 2.479437],
          [115.172774, 2.479212],
          [115.172163, 2.479212],
          [115.171745, 2.479276],
          [115.171327, 2.479469],
          [115.170974, 2.479887],
          [115.17062, 2.480208],
          [115.170138, 2.48053],
          [115.169784, 2.480659],
          [115.169366, 2.480723],
          [115.168015, 2.480562],
          [115.166954, 2.480337],
          [115.166343, 2.480498],
          [115.165861, 2.480819],
          [115.165346, 2.480884],
          [115.16496, 2.480723],
          [115.163642, 2.479919],
          [115.163063, 2.479662],
          [115.161391, 2.479115],
          [115.159944, 2.478408],
          [115.159397, 2.478086],
          [115.158818, 2.477957],
          [115.158239, 2.477925],
          [115.157274, 2.47799],
          [115.156599, 2.477893],
          [115.156181, 2.4777],
          [115.155602, 2.47725],
          [115.155152, 2.477025],
          [115.154509, 2.476961],
          [115.153641, 2.477057],
          [115.15184, 2.477057],
          [115.151293, 2.476961],
          [115.150971, 2.476768],
          [115.15065, 2.476639],
          [115.15036, 2.476607],
          [115.148495, 2.477475],
          [115.146534, 2.477861],
          [115.145794, 2.477797],
          [115.141678, 2.477861],
          [115.141002, 2.477829],
          [115.139652, 2.477604],
          [115.139073, 2.477346],
          [115.138076, 2.476703],
          [115.137529, 2.476414],
          [115.135825, 2.476157],
          [115.135471, 2.476028],
          [115.135214, 2.475803],
          [115.134989, 2.475481],
          [115.13486, 2.475031],
          [115.134892, 2.474549],
          [115.134828, 2.474066],
          [115.133928, 2.473037],
          [115.133381, 2.472651],
          [115.132738, 2.471944],
          [115.132159, 2.471397],
          [115.131709, 2.471172],
          [115.13084, 2.470625],
          [115.129554, 2.470143],
          [115.128943, 2.469789],
          [115.128525, 2.469307],
          [115.128043, 2.468599],
          [115.126628, 2.465448],
          [115.125535, 2.462425],
          [115.125213, 2.461943],
          [115.124088, 2.461139],
          [115.123895, 2.460689],
          [115.123766, 2.460174],
          [115.123766, 2.459692],
          [115.12383, 2.459081],
          [115.123702, 2.458631],
          [115.123284, 2.458116],
          [115.122737, 2.457569],
          [115.120711, 2.456637],
          [115.117045, 2.455608],
          [115.116145, 2.455415],
          [115.115373, 2.455318],
          [115.115051, 2.45535],
          [115.114087, 2.455672],
          [115.113508, 2.456026],
          [115.112929, 2.456605],
          [115.112511, 2.457183],
          [115.1119, 2.457473],
          [115.111385, 2.457505],
          [115.110839, 2.457248],
          [115.110421, 2.456798],
          [115.10997, 2.456154],
          [115.109488, 2.45519],
          [115.109263, 2.454611],
          [115.109167, 2.454],
          [115.109134, 2.45326],
          [115.109199, 2.452167],
          [115.109199, 2.451684],
          [115.109135, 2.451105],
          [115.108588, 2.449465],
          [115.108395, 2.448661],
          [115.107784, 2.447311],
          [115.10698, 2.445928],
          [115.106433, 2.444899],
          [115.106176, 2.44432],
          [115.105822, 2.443966],
          [115.105243, 2.443773],
          [115.104632, 2.443838],
          [115.103925, 2.443773],
          [115.103346, 2.44342],
          [115.102928, 2.443227],
          [115.102156, 2.443259],
          [115.10132, 2.443355],
          [115.100484, 2.443355],
          [115.099937, 2.443195],
          [115.099455, 2.442873],
          [115.09923, 2.442423],
          [115.099005, 2.441651],
          [115.098587, 2.437696],
          [115.098426, 2.437213],
          [115.098265, 2.436988],
          [115.097397, 2.436088],
          [115.096336, 2.435155],
          [115.096014, 2.434769],
          [115.095918, 2.43448],
          [115.095982, 2.433772],
          [115.096303, 2.432872],
          [115.096464, 2.432229],
          [115.096432, 2.431875],
          [115.09611, 2.431264],
          [115.095628, 2.430621],
          [115.093088, 2.42808],
          [115.09267, 2.427437],
          [115.092251, 2.42628],
          [115.092187, 2.42554],
          [115.092316, 2.424704],
          [115.092348, 2.424125],
          [115.09283, 2.423257],
          [115.093248, 2.421681],
          [115.093409, 2.420524],
          [115.09357, 2.419848],
          [115.093795, 2.41824],
          [115.093795, 2.417276],
          [115.093634, 2.416954],
          [115.093313, 2.416568],
          [115.093055, 2.416054],
          [115.092959, 2.415636],
          [115.093152, 2.415057],
          [115.093891, 2.413931],
          [115.094438, 2.41287],
          [115.094824, 2.411905],
          [115.095017, 2.411584],
          [115.095081, 2.41123],
          [115.094985, 2.410201],
          [115.09492, 2.409944],
          [115.094695, 2.40959],
          [115.093763, 2.40869],
          [115.092669, 2.407854],
          [115.091994, 2.407243],
          [115.09074, 2.405602],
          [115.090354, 2.405345],
          [115.089872, 2.405217],
          [115.089357, 2.405152],
          [115.089003, 2.405184],
          [115.088585, 2.405313],
          [115.088071, 2.405313],
          [115.086592, 2.404991],
          [115.085627, 2.404927],
          [115.084244, 2.404959],
          [115.081639, 2.404509],
          [115.081125, 2.40467],
          [115.0809, 2.404927],
          [115.080674, 2.405506],
          [115.08061, 2.406503],
          [115.080353, 2.406857],
          [115.079967, 2.40705],
          [115.079517, 2.407146],
          [115.078713, 2.407114],
          [115.078037, 2.407371],
          [115.077169, 2.40795],
          [115.076687, 2.408047],
          [115.076301, 2.408015],
          [115.075304, 2.407596],
          [115.074371, 2.407693],
          [115.072796, 2.408079],
          [115.071381, 2.408111],
          [115.070159, 2.407982],
          [115.069226, 2.408015],
          [115.068261, 2.408497],
          [115.067843, 2.409011],
          [115.066879, 2.409783],
          [115.066235, 2.409783],
          [115.065528, 2.409622],
          [115.06511, 2.409365],
          [115.063341, 2.407596],
          [115.062827, 2.407211],
          [115.062312, 2.406953],
          [115.061766, 2.406953],
          [115.060994, 2.407082],
          [115.060544, 2.407339],
          [115.060158, 2.407822],
          [115.059836, 2.408722],
          [115.059547, 2.409815],
          [115.059386, 2.410169],
          [115.058839, 2.410426],
          [115.0581, 2.410491],
          [115.057617, 2.410394],
          [115.056749, 2.410073],
          [115.056299, 2.409976],
          [115.055656, 2.409848],
          [115.054209, 2.409719],
          [115.053147, 2.409462],
          [115.05289, 2.409269],
          [115.052569, 2.408851],
          [115.052022, 2.408529],
          [115.050543, 2.407918],
          [115.050189, 2.407661],
          [115.049771, 2.407564],
          [115.048935, 2.407532],
          [115.048517, 2.407564],
          [115.047423, 2.407757],
          [115.046909, 2.407789],
          [115.04633, 2.4075],
          [115.045172, 2.406567],
          [115.044561, 2.405538],
          [115.044465, 2.404927],
          [115.044529, 2.403802],
          [115.044304, 2.402901],
          [115.04379, 2.397981],
          [115.043629, 2.397081],
          [115.043661, 2.396341],
          [115.043725, 2.396019],
          [115.043886, 2.395634],
          [115.044176, 2.395215],
          [115.04543, 2.394186],
          [115.045912, 2.393736],
          [115.046105, 2.393318],
          [115.046234, 2.392546],
          [115.046234, 2.392128],
          [115.046523, 2.391517],
          [115.047006, 2.390842],
          [115.048002, 2.389845],
          [115.048228, 2.389555],
          [115.048163, 2.389266],
          [115.047938, 2.389073],
          [115.04424, 2.387722],
          [115.043597, 2.387722],
          [115.043179, 2.387851],
          [115.042889, 2.388044],
          [115.042728, 2.388269],
          [115.042568, 2.389234],
          [115.042568, 2.389716],
          [115.042503, 2.390295],
          [115.042182, 2.391003],
          [115.041828, 2.391646],
          [115.041346, 2.39216],
          [115.04067, 2.392675],
          [115.04022, 2.392803],
          [115.039513, 2.3929],
          [115.039159, 2.3929],
          [115.037648, 2.392707],
          [115.037229, 2.392739],
          [115.036779, 2.392836],
          [115.036297, 2.393029],
          [115.035847, 2.39335],
          [115.035075, 2.39409],
          [115.034657, 2.394251],
          [115.034271, 2.394315],
          [115.033885, 2.394186],
          [115.033596, 2.393833],
          [115.033178, 2.393189],
          [115.032824, 2.392128],
          [115.031377, 2.385696],
          [115.031023, 2.384603],
          [115.030637, 2.384185],
          [115.029801, 2.38367],
          [115.029415, 2.383638],
          [115.028064, 2.383863],
          [115.027518, 2.383896],
          [115.026842, 2.383799],
          [115.026199, 2.383413],
          [115.025653, 2.382577],
          [115.024816, 2.380422],
          [115.023755, 2.377304],
          [115.023659, 2.376435],
          [115.023787, 2.375567],
          [115.023852, 2.37531],
          [115.02398, 2.375052],
          [115.023948, 2.374699],
          [115.023723, 2.373766],
          [115.023369, 2.372898],
          [115.022469, 2.370968],
          [115.022211, 2.370229],
          [115.022083, 2.369746],
          [115.022276, 2.369071],
          [115.022244, 2.368814],
          [115.022083, 2.368299],
          [115.021729, 2.367978],
          [115.020829, 2.367656],
          [115.01906, 2.367141],
          [115.018063, 2.367013],
          [115.016102, 2.367109],
          [115.013818, 2.367367],
          [115.009413, 2.367752],
          [115.008738, 2.36756],
          [115.007483, 2.366691],
          [115.006294, 2.366016],
          [115.002917, 2.364601],
          [115.002049, 2.364055],
          [115.001856, 2.363379],
          [115.001953, 2.362511],
          [115.00221, 2.361772],
          [115.00221, 2.361225],
          [115.002049, 2.360678],
          [115.001727, 2.360035],
          [115.001052, 2.359263],
          [114.999959, 2.358491],
          [114.999058, 2.357752],
          [114.996743, 2.353828],
          [114.994556, 2.351642],
          [114.993688, 2.351031],
          [114.992691, 2.350741],
          [114.991244, 2.350677],
          [114.989572, 2.351063],
          [114.988543, 2.351577],
          [114.98761, 2.352317],
          [114.986227, 2.353507],
          [114.985182, 2.3546],
          [114.984539, 2.355179],
          [114.98396, 2.355887],
          [114.983285, 2.356851],
          [114.982352, 2.357784],
          [114.981452, 2.358556],
          [114.979747, 2.359778],
          [114.978847, 2.36071],
          [114.977175, 2.362318],
          [114.976146, 2.363219],
          [114.975406, 2.36354],
          [114.974409, 2.363637],
          [114.973252, 2.363605],
          [114.973027, 2.363701],
          [114.97293, 2.364023],
          [114.97367, 2.36637],
          [114.97367, 2.367045],
          [114.973413, 2.367495],
          [114.972769, 2.368267],
          [114.971869, 2.368942],
          [114.970229, 2.369939],
          [114.969811, 2.369939],
          [114.96891, 2.36965],
          [114.96846, 2.369682],
          [114.967881, 2.369843],
          [114.966434, 2.37055],
          [114.965823, 2.370775],
          [114.965212, 2.370711],
          [114.964473, 2.370357],
          [114.964055, 2.369843],
          [114.963572, 2.368621],
          [114.963154, 2.368042],
          [114.962479, 2.367785],
          [114.961546, 2.367849],
          [114.959456, 2.368267],
          [114.958588, 2.368621],
          [114.958234, 2.36846],
          [114.958041, 2.368106],
          [114.958041, 2.367592],
          [114.958234, 2.365148],
          [114.958523, 2.363251],
          [114.958556, 2.362672],
          [114.958427, 2.362029],
          [114.958202, 2.361611],
          [114.957687, 2.361193],
          [114.956497, 2.360614],
          [114.955243, 2.360357],
          [114.954793, 2.360035],
          [114.954439, 2.35952],
          [114.954278, 2.35862],
          [114.954311, 2.357012],
          [114.954439, 2.355147],
          [114.954407, 2.354697],
          [114.95415, 2.354407],
          [114.952606, 2.354118],
          [114.951738, 2.353732],
          [114.951127, 2.353217],
          [114.950677, 2.352349],
          [114.950387, 2.35132],
          [114.950387, 2.35058],
          [114.950677, 2.348394],
          [114.951255, 2.345853],
          [114.951288, 2.345113],
          [114.951063, 2.344246],
          [114.95045, 2.342285],
          [114.95013, 2.33939],
          [114.949937, 2.338425],
          [114.949422, 2.33701],
          [114.949069, 2.336656],
          [114.948844, 2.336206],
          [114.948844, 2.335466],
          [114.948779, 2.334727],
          [114.94804, 2.332829],
          [114.947364, 2.331318],
          [114.947011, 2.330128],
          [114.946464, 2.329388],
          [114.945982, 2.328488],
          [114.945756, 2.327555],
          [114.945917, 2.326044],
          [114.946946, 2.322829],
          [114.946914, 2.322121],
          [114.946367, 2.320706],
          [114.946239, 2.319902],
          [114.946271, 2.319098],
          [114.947332, 2.315818],
          [114.9473, 2.315046],
          [114.947139, 2.314339],
          [114.947107, 2.313567],
          [114.947396, 2.312538],
          [114.948393, 2.311284],
          [114.949165, 2.310866],
          [114.950709, 2.310383],
          [114.952413, 2.309708],
          [114.953507, 2.308872],
          [114.956915, 2.304948],
          [114.957687, 2.304659],
          [114.958813, 2.304145],
          [114.959456, 2.303534],
          [114.960099, 2.302665],
          [114.960999, 2.302087],
          [114.962543, 2.300897],
          [114.963958, 2.300189],
          [114.965534, 2.299578],
          [114.966113, 2.299128],
          [114.966627, 2.298356],
          [114.966756, 2.297649],
          [114.966466, 2.295237],
          [114.965791, 2.293725],
          [114.964633, 2.291957],
          [114.962993, 2.289866],
          [114.962543, 2.289384],
          [114.961321, 2.288484],
          [114.960646, 2.288259],
          [114.958137, 2.286747],
          [114.956948, 2.285911],
          [114.956144, 2.284818],
          [114.955436, 2.28366],
          [114.954729, 2.282985],
          [114.953861, 2.282792],
          [114.952703, 2.282695],
          [114.951738, 2.282888],
          [114.950484, 2.28321],
          [114.94685, 2.284625],
          [114.946111, 2.284721],
          [114.945435, 2.284721],
          [114.944889, 2.284786],
          [114.944245, 2.285107],
          [114.943538, 2.285364],
          [114.942863, 2.285429],
          [114.942316, 2.2853],
          [114.941126, 2.284175],
          [114.939936, 2.283531],
          [114.93865, 2.283081],
          [114.937524, 2.282953],
          [114.936399, 2.283146],
          [114.935498, 2.283467],
          [114.934984, 2.283403],
          [114.934566, 2.283081],
          [114.934212, 2.28247],
          [114.934116, 2.28157],
          [114.934083, 2.280123],
          [114.933665, 2.278804],
          [114.933022, 2.277615],
          [114.932476, 2.277004],
          [114.9309, 2.275749],
          [114.92971, 2.274946],
          [114.929195, 2.274174],
          [114.928777, 2.272984],
          [114.928327, 2.272244],
          [114.927169, 2.271086],
          [114.926526, 2.270347],
          [114.926205, 2.269896],
          [114.925851, 2.269639],
          [114.92479, 2.269157],
          [114.923793, 2.268546],
          [114.922249, 2.26742],
          [114.920674, 2.266391],
          [114.919291, 2.265105],
          [114.918712, 2.26443],
          [114.91823, 2.263754],
          [114.917715, 2.263304],
          [114.91675, 2.262629],
          [114.9163, 2.262211],
          [114.916107, 2.261793],
          [114.915882, 2.260892],
          [114.915561, 2.260217],
          [114.915271, 2.259735],
          [114.914757, 2.259317],
          [114.913631, 2.258899],
          [114.911412, 2.258545],
          [114.91003, 2.257934],
          [114.90929, 2.257484],
          [114.908808, 2.257323],
          [114.907232, 2.257194],
          [114.906653, 2.257001],
          [114.906042, 2.256969],
          [114.905688, 2.257034],
          [114.905238, 2.257226],
          [114.904884, 2.257548],
          [114.904563, 2.258127],
          [114.903759, 2.260153],
          [114.903276, 2.260925],
          [114.902762, 2.261503],
          [114.902312, 2.261921],
          [114.901572, 2.262404],
          [114.900736, 2.26279],
          [114.89238, 2.271265],
          [114.88437, 2.262402],
          [114.871952, 2.265861],
          [114.863978, 2.27199],
          [114.856776, 2.26324],
          [114.854975, 2.262597],
          [114.854139, 2.262211],
          [114.852595, 2.260893],
          [114.851952, 2.260442],
          [114.851406, 2.260185],
          [114.850988, 2.260089],
          [114.850473, 2.260089],
          [114.849187, 2.260603],
          [114.848737, 2.260635],
          [114.848318, 2.260507],
          [114.847514, 2.259671],
          [114.847032, 2.259445],
          [114.84626, 2.259188],
          [114.844395, 2.258963],
          [114.842144, 2.258931],
          [114.840729, 2.258641],
          [114.840247, 2.25832],
          [114.830207, 2.254166],
          [114.824009, 2.260305],
          [114.823136, 2.266473],
          [114.816035, 2.266434],
          [114.808089, 2.25742],
          [114.807124, 2.257355],
          [114.805291, 2.25668],
          [114.80452, 2.256294],
          [114.802494, 2.25459],
          [114.802172, 2.254043],
          [114.801947, 2.253078],
          [114.801722, 2.252435],
          [114.801433, 2.251888],
          [114.799825, 2.250538],
          [114.7996, 2.249991],
          [114.799487, 2.249359],
          [114.799873, 2.248137],
          [114.800162, 2.246979],
          [114.800323, 2.24669],
          [114.800677, 2.24624],
          [114.800934, 2.246047],
          [114.801674, 2.245757],
          [114.801931, 2.2455],
          [114.80206, 2.245307],
          [114.80206, 2.245018],
          [114.801899, 2.244117],
          [114.801931, 2.243378],
          [114.802124, 2.242702],
          [114.80251, 2.241898],
          [114.802799, 2.241352],
          [114.803089, 2.240933],
          [114.803314, 2.240708],
          [114.803507, 2.240355],
          [114.803442, 2.239937],
          [114.803185, 2.239101],
          [114.802992, 2.238264],
          [114.802638, 2.237525],
          [114.801931, 2.236689],
          [114.801256, 2.236206],
          [114.801063, 2.235981],
          [114.800548, 2.235113],
          [114.80013, 2.234727],
          [114.799809, 2.234534],
          [114.799358, 2.234341],
          [114.798844, 2.233955],
          [114.797911, 2.233119],
          [114.797332, 2.232476],
          [114.796786, 2.231962],
          [114.795885, 2.231286],
          [114.79521, 2.230868],
          [114.793634, 2.229679],
          [114.793152, 2.229132],
          [114.792702, 2.228231],
          [114.791576, 2.226302],
          [114.790965, 2.225401],
          [114.79074, 2.224758],
          [114.79074, 2.22434],
          [114.790836, 2.223922],
          [114.791126, 2.22344],
          [114.791576, 2.222893],
          [114.791898, 2.222218],
          [114.791994, 2.221671],
          [114.791865, 2.221092],
          [114.791544, 2.220514],
          [114.790997, 2.219806],
          [114.790772, 2.219259],
          [114.790676, 2.218777],
          [114.790869, 2.216687],
          [114.790836, 2.216205],
          [114.790676, 2.215561],
          [114.790097, 2.214275],
          [114.79, 2.213568],
          [114.79, 2.21286],
          [114.790258, 2.211703],
          [114.790418, 2.210738],
          [114.790547, 2.209805],
          [114.790579, 2.209066],
          [114.790386, 2.208133],
          [114.790322, 2.207426],
          [114.790418, 2.205432],
          [114.790386, 2.204435],
          [114.790258, 2.204017],
          [114.790065, 2.203663],
          [114.789357, 2.203052],
          [114.788778, 2.202634],
          [114.779485, 2.199211],
          [114.782131, 2.192171],
          [114.783868, 2.176307],
          [114.780273, 2.157769],
          [114.780241, 2.144541],
          [114.785532, 2.13046],
          [114.800589, 2.118197],
          [114.808378, 2.106032],
          [114.808989, 2.103685],
          [114.809343, 2.102688],
          [114.809729, 2.10198],
          [114.810115, 2.101626],
          [114.810308, 2.10108],
          [114.810469, 2.100404],
          [114.810276, 2.099826],
          [114.80989, 2.099182],
          [114.809279, 2.098636],
          [114.8078, 2.097767],
          [114.806706, 2.09661],
          [114.806288, 2.09632],
          [114.805709, 2.09616],
          [114.804744, 2.096063],
          [114.803748, 2.095902],
          [114.802911, 2.095548],
          [114.802397, 2.095098],
          [114.801979, 2.094648],
          [114.800886, 2.092236],
          [114.800596, 2.091464],
          [114.800242, 2.091046],
          [114.799856, 2.090789],
          [114.799438, 2.090693],
          [114.798988, 2.090789],
          [114.798506, 2.09095],
          [114.797348, 2.091529],
          [114.79693, 2.091561],
          [114.796448, 2.091432],
          [114.79619, 2.091271],
          [114.795901, 2.090853],
          [114.79574, 2.090307],
          [114.795644, 2.088827],
          [114.795194, 2.087509],
          [114.794808, 2.087027],
          [114.794293, 2.086705],
          [114.793971, 2.086448],
          [114.79365, 2.085997],
          [114.793489, 2.085579],
          [114.793457, 2.085193],
          [114.793586, 2.084679],
          [114.793811, 2.084229],
          [114.7941, 2.083875],
          [114.794743, 2.083393],
          [114.795869, 2.082331],
          [114.79693, 2.08156],
          [114.797605, 2.081271],
          [114.797959, 2.081045],
          [114.798474, 2.080981],
          [114.798892, 2.080981],
          [114.799342, 2.08111],
          [114.799856, 2.081013],
          [114.800114, 2.080788],
          [114.800307, 2.08037],
          [114.800435, 2.079727],
          [114.801271, 2.077605],
          [114.801657, 2.076994],
          [114.802558, 2.075739],
          [114.804455, 2.073231],
          [114.80513, 2.072427],
          [114.805645, 2.071591],
          [114.806095, 2.070626],
          [114.80661, 2.069275],
          [114.806738, 2.068439],
          [114.807446, 2.065674],
          [114.807735, 2.06516],
          [114.807896, 2.064549],
          [114.807896, 2.064066],
          [114.807767, 2.063809],
          [114.80751, 2.063487],
          [114.80706, 2.063198],
          [114.804841, 2.062491],
          [114.803973, 2.06233],
          [114.8023, 2.062169],
          [114.801625, 2.062008],
          [114.800725, 2.061687],
          [114.79976, 2.061204],
          [114.798795, 2.060786],
          [114.796416, 2.059982],
          [114.795901, 2.059918],
          [114.795419, 2.059982],
          [114.7932, 2.060432],
          [114.792653, 2.060336],
          [114.792396, 2.060175],
          [114.792203, 2.05995],
          [114.792138, 2.059403],
          [114.792235, 2.058728],
          [114.792653, 2.05757],
          [114.792942, 2.056123],
          [114.793039, 2.055158],
          [114.793103, 2.05027],
          [114.793425, 2.049402],
          [114.793843, 2.048823],
          [114.794486, 2.047665],
          [114.796062, 2.045575],
          [114.797927, 2.042166],
          [114.798441, 2.041491],
          [114.799374, 2.040687],
          [114.799728, 2.040301],
          [114.799953, 2.039819],
          [114.800082, 2.039304],
          [114.800114, 2.038372],
          [114.800242, 2.037311],
          [114.8005, 2.036443],
          [114.800757, 2.036024],
          [114.800886, 2.035703],
          [114.801818, 2.035221],
          [114.803715, 2.034449],
          [114.804359, 2.033998],
          [114.804584, 2.033677],
          [114.804713, 2.033387],
          [114.804809, 2.032198],
          [114.805163, 2.029914],
          [114.806031, 2.026602],
          [114.805999, 2.025541],
          [114.80616, 2.024833],
          [114.806321, 2.024383],
          [114.80661, 2.023869],
          [114.80706, 2.023418],
          [114.807575, 2.023097],
          [114.807961, 2.022936],
          [114.808797, 2.022807],
          [114.809183, 2.022775],
          [114.809601, 2.022839],
          [114.80989, 2.022936],
          [114.810179, 2.023129],
          [114.81063, 2.023643],
          [114.811627, 2.025091],
          [114.812077, 2.025605],
          [114.812527, 2.025927],
          [114.813878, 2.026473],
          [114.814746, 2.026891],
          [114.816804, 2.027728],
          [114.817672, 2.027824],
          [114.818251, 2.027792],
          [114.818573, 2.027728],
          [114.818958, 2.027567],
          [114.819409, 2.02747],
          [114.819891, 2.027502],
          [114.82018, 2.027663],
          [114.820888, 2.028306],
          [114.821917, 2.028628],
          [114.822624, 2.028628],
          [114.823846, 2.028435],
          [114.824329, 2.028306],
          [114.824779, 2.028081],
          [114.8251, 2.027985],
          [114.82539, 2.027985],
          [114.825711, 2.028081],
          [114.826226, 2.028403],
          [114.827351, 2.0294],
          [114.829088, 2.03075],
          [114.829956, 2.031651],
          [114.830599, 2.032069],
          [114.831661, 2.032519],
          [114.833268, 2.032519],
          [114.833686, 2.032616],
          [114.833944, 2.032809],
          [114.834073, 2.033066],
          [114.834233, 2.033806],
          [114.834555, 2.03461],
          [114.834877, 2.03506],
          [114.83523, 2.035317],
          [114.835906, 2.035671],
          [114.837353, 2.036153],
          [114.83851, 2.036475],
          [114.839314, 2.036797],
          [114.840408, 2.037408],
          [114.842048, 2.038244],
          [114.844267, 2.039691],
          [114.84655, 2.040977],
          [114.847804, 2.041331],
          [114.849573, 2.041395],
          [114.850409, 2.041556],
          [114.851309, 2.041813],
          [114.852081, 2.042328],
          [114.853528, 2.043453],
          [114.854428, 2.044032],
          [114.855039, 2.044836],
          [114.855811, 2.045415],
          [114.856358, 2.045704],
          [114.856647, 2.045736],
          [114.85713, 2.045575],
          [114.857644, 2.045125],
          [114.858352, 2.044643],
          [114.859027, 2.044064],
          [114.860924, 2.041588],
          [114.861535, 2.04117],
          [114.862082, 2.040913],
          [114.864204, 2.040623],
          [114.865041, 2.040688],
          [114.866552, 2.041202],
          [114.874228, 2.041707],
          [114.878745, 2.03859],
          [114.882371, 2.032473],
          [114.882287, 2.025],
          [114.880525, 2.01763],
          [114.879785, 2.01718],
          [114.879271, 2.016923],
          [114.87837, 2.016087],
          [114.877984, 2.015829],
          [114.877405, 2.015572],
          [114.876794, 2.015476],
          [114.875379, 2.015636],
          [114.874897, 2.015476],
          [114.873482, 2.015122],
          [114.871681, 2.014479],
          [114.870652, 2.014029],
          [114.870331, 2.013836],
          [114.870106, 2.013482],
          [114.869881, 2.012517],
          [114.869816, 2.011842],
          [114.869848, 2.01136],
          [114.870074, 2.010395],
          [114.870685, 2.008273],
          [114.87107, 2.007243],
          [114.871231, 2.006311],
          [114.87136, 2.004606],
          [114.871488, 2.003738],
          [114.871392, 2.002098],
          [114.871135, 2.001326],
          [114.870781, 2.000619],
          [114.870459, 2.000104],
          [114.869945, 1.996728],
          [114.869591, 1.995345],
          [114.869141, 1.994283],
          [114.867855, 1.993126],
          [114.867244, 1.992354],
          [114.867308, 1.991968],
          [114.867501, 1.991679],
          [114.867983, 1.990585],
          [114.86808, 1.990071],
          [114.868047, 1.989685],
          [114.867919, 1.989363],
          [114.867212, 1.988624],
          [114.865957, 1.987659],
          [114.865636, 1.987145],
          [114.865604, 1.985891],
          [114.865507, 1.985408],
          [114.865025, 1.98499],
          [114.859687, 1.982482],
          [114.857532, 1.981517],
          [114.856503, 1.980874],
          [114.856053, 1.980359],
          [114.855763, 1.979652],
          [114.855667, 1.978912],
          [114.855699, 1.978462],
          [114.855828, 1.977883],
          [114.856278, 1.976886],
          [114.856085, 1.976436],
          [114.855152, 1.97605],
          [114.854766, 1.975536],
          [114.854477, 1.975343],
          [114.854026, 1.975214],
          [114.853641, 1.974957],
          [114.853415, 1.974571],
          [114.853062, 1.974282],
          [114.851518, 1.974153],
          [114.851004, 1.97396],
          [114.850489, 1.973574],
          [114.850007, 1.973059],
          [114.848528, 1.971998],
          [114.848303, 1.97158],
          [114.848142, 1.97084],
          [114.848142, 1.969586],
          [114.848817, 1.966596],
          [114.848817, 1.966146],
          [114.848721, 1.965663],
          [114.848335, 1.964538],
          [114.84782, 1.960904],
          [114.847724, 1.959843],
          [114.847724, 1.95891],
          [114.84782, 1.95801],
          [114.848013, 1.957431],
          [114.84811, 1.956724],
          [114.84811, 1.955983],
          [114.847852, 1.954922],
          [114.847659, 1.953604],
          [114.847595, 1.952575],
          [114.847692, 1.95206],
          [114.848174, 1.951642],
          [114.850457, 1.950388],
          [114.850714, 1.950163],
          [114.850714, 1.949745],
          [114.85065, 1.949359],
          [114.849428, 1.946594],
          [114.848881, 1.945082],
          [114.848914, 1.944664],
          [114.849074, 1.944278],
          [114.849943, 1.943056],
          [114.850425, 1.942606],
          [114.851808, 1.941513],
          [114.852129, 1.940548],
          [114.852708, 1.939583],
          [114.85303, 1.939262],
          [114.853898, 1.938618],
          [114.854702, 1.937847],
          [114.855249, 1.937397],
          [114.855635, 1.937204],
          [114.85602, 1.937204],
          [114.856406, 1.9373],
          [114.857403, 1.937718],
          [114.858207, 1.938136],
          [114.858818, 1.938361],
          [114.859268, 1.93849],
          [114.859751, 1.93849],
          [114.860201, 1.938393],
          [114.860555, 1.938233],
          [114.861134, 1.937557],
          [114.861359, 1.937204],
          [114.861841, 1.93685],
          [114.862291, 1.936721],
          [114.862613, 1.936464],
          [114.862838, 1.935853],
          [114.862934, 1.935306],
          [114.863224, 1.931705],
          [114.863352, 1.931094],
          [114.86377, 1.930804],
          [114.864993, 1.930418],
          [114.86763, 1.929968],
          [114.868433, 1.929614],
          [114.869784, 1.928875],
          [114.870524, 1.928585],
          [114.871102, 1.928071],
          [114.87152, 1.92762],
          [114.871681, 1.927009],
          [114.871842, 1.925595],
          [114.872099, 1.924212],
          [114.872196, 1.923954],
          [114.872549, 1.923826],
          [114.872903, 1.923794],
          [114.873418, 1.923633],
          [114.874736, 1.922925],
          [114.875958, 1.921928],
          [114.876408, 1.921671],
          [114.876826, 1.92135],
          [114.877148, 1.921028],
          [114.877309, 1.920674],
          [114.877405, 1.919517],
          [114.877502, 1.918873],
          [114.877759, 1.918423],
          [114.878531, 1.917651],
          [114.878756, 1.917298],
          [114.878852, 1.916944],
          [114.87882, 1.915851],
          [114.878692, 1.915208],
          [114.878563, 1.914854],
          [114.878338, 1.914629],
          [114.877791, 1.9145],
          [114.877277, 1.914436],
          [114.875958, 1.913953],
          [114.873739, 1.912667],
          [114.873064, 1.912378],
          [114.872292, 1.912281],
          [114.871328, 1.912346],
          [114.870652, 1.912281],
          [114.870106, 1.912088],
          [114.869173, 1.91122],
          [114.868369, 1.910898],
          [114.867565, 1.910706],
          [114.866793, 1.910384],
          [114.865957, 1.909773],
          [114.865378, 1.909258],
          [114.86496, 1.908744],
          [114.864671, 1.907876],
          [114.864735, 1.907361],
          [114.865057, 1.9063],
          [114.865379, 1.905528],
          [114.865411, 1.904949],
          [114.865121, 1.903952],
          [114.864864, 1.903309],
          [114.864446, 1.902891],
          [114.863996, 1.902569],
          [114.863513, 1.902344],
          [114.862066, 1.901958],
          [114.861455, 1.901605],
          [114.860909, 1.901122],
          [114.860523, 1.900672],
          [114.860137, 1.900318],
          [114.85959, 1.900029],
          [114.858979, 1.8999],
          [114.858111, 1.899997],
          [114.856792, 1.900447],
          [114.856374, 1.900351],
          [114.855988, 1.899868],
          [114.855602, 1.899257],
          [114.855474, 1.898646],
          [114.855442, 1.897939],
          [114.855249, 1.89736],
          [114.854927, 1.896813],
          [114.854509, 1.896588],
          [114.852869, 1.896331],
          [114.850328, 1.895398],
          [114.848624, 1.895045],
          [114.847949, 1.895012],
          [114.846437, 1.895238],
          [114.845698, 1.895205],
          [114.844476, 1.894948],
          [114.843865, 1.894723],
          [114.843222, 1.894627],
          [114.841453, 1.894852],
          [114.840745, 1.894659],
          [114.839877, 1.894048],
          [114.839298, 1.893791],
          [114.838751, 1.893662],
          [114.837883, 1.893726],
          [114.836854, 1.893983],
          [114.8356, 1.894434],
          [114.833703, 1.89498],
          [114.832095, 1.895495],
          [114.830037, 1.895913],
          [114.829136, 1.896138],
          [114.828751, 1.896138],
          [114.828107, 1.896074],
          [114.827432, 1.895784],
          [114.826628, 1.895302],
          [114.824859, 1.894402],
          [114.822801, 1.893565],
          [114.821612, 1.893437],
          [114.821161, 1.893308],
          [114.820647, 1.893051],
          [114.8201, 1.892601],
          [114.819875, 1.892536],
          [114.819586, 1.892568],
          [114.819007, 1.893147],
          [114.818557, 1.89334],
          [114.817881, 1.893405],
          [114.817303, 1.893405],
          [114.816852, 1.89334],
          [114.816434, 1.893051],
          [114.815052, 1.891314],
          [114.814794, 1.890703],
          [114.814666, 1.890092],
          [114.814762, 1.889031],
          [114.815823, 1.886651],
          [114.816338, 1.885654],
          [114.816563, 1.885043],
          [114.816659, 1.884497],
          [114.816627, 1.884014],
          [114.816113, 1.88276],
          [114.815437, 1.881313],
          [114.814762, 1.880284],
          [114.813861, 1.879351],
          [114.813476, 1.879062],
          [114.813057, 1.878965],
          [114.81145, 1.878805],
          [114.810581, 1.878612],
          [114.809359, 1.878194],
          [114.808748, 1.877776],
          [114.807365, 1.876457],
          [114.805565, 1.875171],
          [114.804761, 1.874367],
          [114.804117, 1.873627],
          [114.803539, 1.873081],
          [114.802863, 1.872212],
          [114.802059, 1.870991],
          [114.801352, 1.869736],
          [114.800773, 1.868451],
          [114.800645, 1.867968],
          [114.800838, 1.865621],
          [114.800805, 1.865428],
          [114.800677, 1.865235],
          [114.800259, 1.865106],
          [114.799744, 1.865042],
          [114.799101, 1.864817],
          [114.79759, 1.863755],
          [114.797107, 1.863627],
          [114.796464, 1.863305],
          [114.795789, 1.862759],
          [114.795499, 1.862373],
          [114.795178, 1.861826],
          [114.795017, 1.861279],
          [114.795017, 1.860314],
          [114.795081, 1.859543],
          [114.795274, 1.859028],
          [114.796657, 1.856552],
          [114.796786, 1.856134],
          [114.796914, 1.855491],
          [114.796979, 1.854494],
          [114.796914, 1.8534],
          [114.796561, 1.853047],
          [114.796143, 1.852789],
          [114.795532, 1.852532],
          [114.794824, 1.852018],
          [114.793441, 1.851181],
          [114.792991, 1.85086],
          [114.79193, 1.850281],
          [114.791415, 1.85012],
          [114.789904, 1.849799],
          [114.788971, 1.849734],
          [114.788328, 1.849574],
          [114.787685, 1.84922],
          [114.787171, 1.848834],
          [114.786688, 1.848609],
          [114.786109, 1.848544],
          [114.782733, 1.848995],
          [114.782219, 1.849316],
          [114.781511, 1.849927],
          [114.781157, 1.850378],
          [114.780772, 1.851181],
          [114.780514, 1.851342],
          [114.780289, 1.85131],
          [114.779968, 1.851181],
          [114.779678, 1.850699],
          [114.779453, 1.850217],
          [114.779067, 1.849734],
          [114.778778, 1.849702],
          [114.774179, 1.850538],
          [114.771413, 1.851439],
          [114.77077, 1.851407],
          [114.77032, 1.851246],
          [114.769388, 1.850796],
          [114.768744, 1.85086],
          [114.768262, 1.850956],
          [114.76749, 1.851246],
          [114.766976, 1.851342],
          [114.766461, 1.851278],
          [114.766976, 1.852275],
          [114.76704, 1.852596],
          [114.766815, 1.854333],
          [114.766783, 1.855201],
          [114.766815, 1.855973],
          [114.766976, 1.856648],
          [114.767008, 1.857131],
          [114.766751, 1.85742],
          [114.765625, 1.857742],
          [114.764725, 1.857838],
          [114.763696, 1.858128],
          [114.762055, 1.858642],
          [114.760994, 1.859157],
          [114.760222, 1.859703],
          [114.759869, 1.860154],
          [114.75929, 1.860604],
          [114.757135, 1.861569],
          [114.756653, 1.861955],
          [114.756396, 1.862437],
          [114.755913, 1.863016],
          [114.755238, 1.863627],
          [114.753759, 1.864592],
          [114.753019, 1.864881],
          [114.751443, 1.866296],
          [114.750639, 1.866874],
          [114.7499, 1.867099],
          [114.749257, 1.867099],
          [114.748549, 1.867196],
          [114.747649, 1.867453],
          [114.746909, 1.867743],
          [114.746362, 1.868032],
          [114.745591, 1.868739],
          [114.74498, 1.869222],
          [114.743725, 1.869543],
          [114.743372, 1.869479],
          [114.742986, 1.869125],
          [114.742761, 1.868579],
          [114.742536, 1.868193],
          [114.741925, 1.867743],
          [114.741217, 1.867485],
          [114.739963, 1.867325],
          [114.738902, 1.867132],
          [114.736136, 1.866199],
          [114.73485, 1.865813],
          [114.733853, 1.865427],
          [114.73337, 1.865138],
          [114.733049, 1.86472],
          [114.732759, 1.863144],
          [114.732824, 1.861408],
          [114.732695, 1.86099],
          [114.732309, 1.860411],
          [114.731827, 1.859929],
          [114.728257, 1.858128],
          [114.727486, 1.857871],
          [114.726714, 1.857742],
          [114.725588, 1.857742],
          [114.724656, 1.857903],
          [114.724174, 1.858031],
          [114.723691, 1.858064],
          [114.723241, 1.858031],
          [114.722662, 1.857935],
          [114.719864, 1.857227],
          [114.717999, 1.856874],
          [114.717035, 1.856777],
          [114.716488, 1.856584],
          [114.716295, 1.856295],
          [114.716263, 1.855941],
          [114.716327, 1.855459],
          [114.716552, 1.854816],
          [114.71697, 1.853915],
          [114.718031, 1.852307],
          [114.719189, 1.850667],
          [114.720443, 1.849413],
          [114.721183, 1.849284],
          [114.721665, 1.849059],
          [114.72308, 1.847548],
          [114.724077, 1.846808],
          [114.724849, 1.846133],
          [114.725138, 1.845683],
          [114.725299, 1.845264],
          [114.725331, 1.844782],
          [114.725299, 1.844396],
          [114.725138, 1.843978],
          [114.724785, 1.843624],
          [114.724656, 1.843303],
          [114.724656, 1.842692],
          [114.725621, 1.838608],
          [114.725653, 1.837997],
          [114.725396, 1.835843],
          [114.72546, 1.83536],
          [114.726103, 1.83372],
          [114.726167, 1.833141],
          [114.726135, 1.832595],
          [114.725942, 1.832241],
          [114.725621, 1.831951],
          [114.725428, 1.831855],
          [114.72501, 1.831791],
          [114.724302, 1.831855],
          [114.723755, 1.831758],
          [114.723209, 1.831501],
          [114.72263, 1.830697],
          [114.721569, 1.829893],
          [114.721247, 1.829733],
          [114.720861, 1.829636],
          [114.719446, 1.829411],
          [114.718964, 1.829121],
          [114.71845, 1.828896],
          [114.718031, 1.828543],
          [114.717839, 1.828125],
          [114.717742, 1.827545],
          [114.717549, 1.826999],
          [114.717195, 1.826581],
          [114.716809, 1.826356],
          [114.71623, 1.826195],
          [114.71578, 1.82613],
          [114.714912, 1.826259],
          [114.714365, 1.826259],
          [114.713658, 1.825873],
          [114.7134, 1.825616],
          [114.713207, 1.825166],
          [114.713047, 1.824458],
          [114.712629, 1.823494],
          [114.712339, 1.822947],
          [114.711214, 1.821757],
          [114.710924, 1.821307],
          [114.71086, 1.820953],
          [114.71057, 1.820342],
          [114.710152, 1.819796],
          [114.709863, 1.819506],
          [114.709574, 1.819442],
          [114.708834, 1.819538],
          [114.707355, 1.820085],
          [114.706615, 1.820632],
          [114.705361, 1.82105],
          [114.704814, 1.821114],
          [114.704428, 1.821082],
          [114.704075, 1.820953],
          [114.703657, 1.820696],
          [114.70311, 1.820053],
          [114.703013, 1.819538],
          [114.702981, 1.819024],
          [114.703142, 1.818381],
          [114.703206, 1.817963],
          [114.703206, 1.817641],
          [114.70311, 1.817384],
          [114.702917, 1.817223],
          [114.702499, 1.817062],
          [114.702242, 1.816773],
          [114.702113, 1.816355],
          [114.702081, 1.815905],
          [114.702338, 1.814168],
          [114.702306, 1.81375],
          [114.702177, 1.813364],
          [114.700827, 1.812013],
          [114.700151, 1.811402],
          [114.699701, 1.810888],
          [114.699283, 1.810534],
          [114.698833, 1.810405],
          [114.698319, 1.810341],
          [114.697708, 1.810405],
          [114.69655, 1.81063],
          [114.695649, 1.810727],
          [114.695231, 1.810759],
          [114.694685, 1.81063],
          [114.692112, 1.808862],
          [114.691887, 1.808508],
          [114.691855, 1.808154],
          [114.691984, 1.807833],
          [114.692466, 1.807415],
          [114.692916, 1.807447],
          [114.693334, 1.807608],
          [114.694331, 1.807833],
          [114.694685, 1.807833],
          [114.695103, 1.807736],
          [114.69536, 1.807608],
          [114.695585, 1.807222],
          [114.695907, 1.806386],
          [114.696357, 1.805389],
          [114.696871, 1.804617],
          [114.697225, 1.804263],
          [114.697643, 1.803974],
          [114.698093, 1.803781],
          [114.699637, 1.803652],
          [114.699991, 1.803556],
          [114.700312, 1.803363],
          [114.700602, 1.803009],
          [114.70102, 1.80153],
          [114.701341, 1.800983],
          [114.701631, 1.800919],
          [114.70221, 1.800951],
          [114.703142, 1.801176],
          [114.703978, 1.801594],
          [114.706004, 1.803395],
          [114.706358, 1.803427],
          [114.706969, 1.803202],
          [114.707291, 1.802494],
          [114.708127, 1.798925],
          [114.70832, 1.797896],
          [114.708287, 1.795581],
          [114.708513, 1.794777],
          [114.70922, 1.793651],
          [114.709606, 1.792719],
          [114.709799, 1.791625],
          [114.70996, 1.791272],
          [114.710249, 1.791014],
          [114.710796, 1.790757],
          [114.7116, 1.790114],
          [114.71176, 1.789728],
          [114.711857, 1.788795],
          [114.711986, 1.788442],
          [114.712468, 1.787959],
          [114.712757, 1.787799],
          [114.713175, 1.787413],
          [114.713593, 1.786866],
          [114.713915, 1.785837],
          [114.714011, 1.785323],
          [114.713979, 1.78336],
          [114.713915, 1.782235],
          [114.713786, 1.78156],
          [114.713433, 1.780691],
          [114.713175, 1.779566],
          [114.713111, 1.778601],
          [114.7134, 1.77545],
          [114.713433, 1.774646],
          [114.7134, 1.773939],
          [114.71324, 1.772942],
          [114.71324, 1.772073],
          [114.7134, 1.770787],
          [114.713272, 1.770112],
          [114.712918, 1.769533],
          [114.7125, 1.768986],
          [114.711857, 1.768343],
          [114.709863, 1.766832],
          [114.708995, 1.765867],
          [114.708802, 1.765481],
          [114.708705, 1.764967],
          [114.708513, 1.764388],
          [114.708255, 1.764066],
          [114.707869, 1.763873],
          [114.706101, 1.763616],
          [114.705586, 1.763455],
          [114.705329, 1.763166],
          [114.704814, 1.76278],
          [114.704622, 1.762458],
          [114.704525, 1.762137],
          [114.704654, 1.761686],
          [114.704814, 1.761429],
          [114.705104, 1.761204],
          [114.706358, 1.760818],
          [114.707484, 1.759853],
          [114.707902, 1.759403],
          [114.709349, 1.757216],
          [114.71012, 1.756509],
          [114.711214, 1.755287],
          [114.711439, 1.754772],
          [114.711503, 1.754129],
          [114.711471, 1.753518],
          [114.711214, 1.752875],
          [114.710796, 1.7522],
          [114.710088, 1.751396],
          [114.709477, 1.750495],
          [114.709316, 1.749884],
          [114.709477, 1.745511],
          [114.709606, 1.744611],
          [114.709927, 1.743871],
          [114.711503, 1.741202],
          [114.712018, 1.739594],
          [114.712404, 1.738758],
          [114.7134, 1.737182],
          [114.716198, 1.733548],
          [114.716777, 1.732455],
          [114.717999, 1.729271],
          [114.718224, 1.728146],
          [114.718063, 1.727149],
          [114.71771, 1.726474],
          [114.717388, 1.72538],
          [114.717484, 1.724737],
          [114.717742, 1.72403],
          [114.718063, 1.72284],
          [114.718481, 1.720975],
          [114.718513, 1.719624],
          [114.718449, 1.718756],
          [114.718192, 1.718177],
          [114.717774, 1.71747],
          [114.714204, 1.713386],
          [114.713658, 1.712581],
          [114.713015, 1.71152],
          [114.712725, 1.711006],
          [114.711664, 1.70734],
          [114.711568, 1.706536],
          [114.711535, 1.7057],
          [114.711696, 1.704606],
          [114.712211, 1.702259],
          [114.713175, 1.698978],
          [114.713208, 1.697628],
          [114.713079, 1.696213],
          [114.712886, 1.694863],
          [114.71295, 1.693834],
          [114.713529, 1.690328],
          [114.714076, 1.686566],
          [114.714011, 1.683801],
          [114.713465, 1.680263],
          [114.713015, 1.678784],
          [114.711953, 1.674796],
          [114.711149, 1.671484],
          [114.701277, 1.659177],
          [114.694744, 1.646206],
          [114.686744, 1.631535],
          [114.674451, 1.61737],
          [114.660135, 1.60131],
          [114.648779, 1.589578],
          [114.645338, 1.589385],
          [114.642702, 1.588839],
          [114.641608, 1.588356],
          [114.639936, 1.587231],
          [114.639036, 1.586523],
          [114.637396, 1.584722],
          [114.636785, 1.584176],
          [114.636238, 1.583886],
          [114.635048, 1.583725],
          [114.634244, 1.583307],
          [114.633344, 1.5826],
          [114.632893, 1.581957],
          [114.63254, 1.581603],
          [114.632186, 1.581378],
          [114.631543, 1.581121],
          [114.630932, 1.580992],
          [114.630256, 1.580767],
          [114.628616, 1.579963],
          [114.628037, 1.579513],
          [114.627555, 1.578902],
          [114.626783, 1.578355],
          [114.622635, 1.57649],
          [114.621799, 1.576265],
          [114.620931, 1.576072],
          [114.619998, 1.575943],
          [114.617361, 1.575975],
          [114.616782, 1.575911],
          [114.615882, 1.575461],
          [114.614981, 1.575075],
          [114.614209, 1.574207],
          [114.613952, 1.57337],
          [114.613856, 1.572663],
          [114.613984, 1.571827],
          [114.614049, 1.570605],
          [114.613984, 1.569447],
          [114.613792, 1.568096],
          [114.613342, 1.56636],
          [114.613084, 1.564945],
          [114.613181, 1.563562],
          [114.613309, 1.562758],
          [114.613631, 1.561601],
          [114.614242, 1.560057],
          [114.615335, 1.558288],
          [114.616043, 1.55742],
          [114.616686, 1.556777],
          [114.614724, 1.556198],
          [114.613953, 1.556038],
          [114.612473, 1.555266],
          [114.612055, 1.554687],
          [114.611444, 1.554269],
          [114.610898, 1.553979],
          [114.610351, 1.553594],
          [114.609933, 1.553015],
          [114.609193, 1.55131],
          [114.609, 1.550732],
          [114.608775, 1.548802],
          [114.608486, 1.545168],
          [114.607843, 1.541631],
          [114.606235, 1.536357],
          [114.605785, 1.534331],
          [114.605495, 1.533366],
          [114.604788, 1.532016],
          [114.604145, 1.531051],
          [114.604177, 1.530762],
          [114.604434, 1.530408],
          [114.605913, 1.529797],
          [114.606717, 1.529604],
          [114.607425, 1.529379],
          [114.607778, 1.529154],
          [114.6081, 1.528704],
          [114.608261, 1.528254],
          [114.608325, 1.527771],
          [114.608293, 1.526839],
          [114.608325, 1.52626],
          [114.608486, 1.525713],
          [114.608775, 1.525231],
          [114.609161, 1.524684],
          [114.609869, 1.523848],
          [114.61228, 1.521822],
          [114.613034, 1.515729],
          [114.612556, 1.509747],
          [114.611, 1.500762],
          [114.609027, 1.495134],
          [114.604657, 1.487608],
          [114.597847, 1.481358],
          [114.59397, 1.478694],
          [114.591783, 1.475117],
          [114.59092, 1.470064],
          [114.588052, 1.461623],
          [114.58811, 1.457328],
          [114.586691, 1.452081],
          [114.585832, 1.446654],
          [114.581585, 1.443798],
          [114.572863, 1.441259],
          [114.571232, 1.437877],
          [114.57018, 1.433008],
          [114.566897, 1.427737],
          [114.565252, 1.427473],
          [114.56218, 1.432159],
          [114.558431, 1.433792],
          [114.55601, 1.433761],
          [114.548926, 1.434044],
          [114.536987, 1.435199],
          [114.531904, 1.439056],
          [114.526464, 1.441787],
          [114.517208, 1.451193],
          [114.510404, 1.458389],
          [114.507728, 1.463397],
          [114.503056, 1.470755],
          [114.502349, 1.471238],
          [114.501609, 1.471656],
          [114.500098, 1.472813],
          [114.499487, 1.473167],
          [114.498747, 1.473521],
          [114.498104, 1.473714],
          [114.497654, 1.474164],
          [114.497397, 1.474936],
          [114.496979, 1.475868],
          [114.496721, 1.476608],
          [114.4964, 1.477219],
          [114.496207, 1.477476],
          [114.49595, 1.477541],
          [114.49566, 1.477476],
          [114.494438, 1.475932],
          [114.493345, 1.474743],
          [114.492669, 1.474421],
          [114.489936, 1.473488],
          [114.488618, 1.473488],
          [114.487524, 1.473392],
          [114.486367, 1.473167],
          [114.484501, 1.472942],
          [114.483408, 1.472684],
          [114.481286, 1.472492],
          [114.480192, 1.472652],
          [114.478842, 1.472974],
          [114.477748, 1.473295],
          [114.476076, 1.474099],
          [114.474597, 1.474582],
          [114.474018, 1.475],
          [114.473021, 1.476093],
          [114.472571, 1.477637],
          [114.472346, 1.477991],
          [114.470834, 1.478537],
          [114.470063, 1.478762],
          [114.469548, 1.47918],
          [114.468648, 1.479952],
          [114.468358, 1.480563],
          [114.468101, 1.481239],
          [114.467715, 1.481882],
          [114.467072, 1.482653],
          [114.466075, 1.483457],
          [114.464917, 1.484261],
          [114.462956, 1.485323],
          [114.462055, 1.485676],
          [114.461251, 1.485934],
          [114.460319, 1.485934],
          [114.458711, 1.485612],
          [114.45781, 1.48558],
          [114.456846, 1.485805],
          [114.455688, 1.48632],
          [114.45408, 1.487477],
          [114.451507, 1.489728],
          [114.450317, 1.4905],
          [114.449417, 1.491143],
          [114.448002, 1.492462],
          [114.446684, 1.493587],
          [114.445173, 1.494552],
          [114.443468, 1.495484],
          [114.441378, 1.496545],
          [114.440027, 1.496899],
          [114.439095, 1.496996],
          [114.438516, 1.497156],
          [114.438162, 1.497317],
          [114.437776, 1.497832],
          [114.436812, 1.498636],
          [114.436168, 1.499472],
          [114.435911, 1.499922],
          [114.435622, 1.500211],
          [114.435075, 1.500436],
          [114.433531, 1.500726],
          [114.431763, 1.501208],
          [114.430894, 1.501658],
          [114.428772, 1.502623],
          [114.427614, 1.50362],
          [114.426071, 1.505968],
          [114.425396, 1.506804],
          [114.424849, 1.507318],
          [114.424142, 1.507833],
          [114.423273, 1.508283],
          [114.419575, 1.50928],
          [114.417517, 1.509762],
          [114.416906, 1.510052],
          [114.416359, 1.510373],
          [114.416006, 1.510791],
          [114.415813, 1.511113],
          [114.415266, 1.513139],
          [114.413883, 1.517705],
          [114.413369, 1.51822],
          [114.412726, 1.518574],
          [114.412147, 1.518831],
          [114.410989, 1.51896],
          [114.410571, 1.519281],
          [114.410217, 1.520214],
          [114.409638, 1.520825],
          [114.409349, 1.520857],
          [114.40877, 1.520761],
          [114.408127, 1.520471],
          [114.406326, 1.519313],
          [114.405393, 1.518895],
          [114.404622, 1.518702],
          [114.40356, 1.518735],
          [114.402949, 1.518863],
          [114.401985, 1.519185],
          [114.401309, 1.519346],
          [114.400505, 1.519313],
          [114.399541, 1.519153],
          [114.398769, 1.51896],
          [114.39655, 1.518638],
          [114.395264, 1.518349],
          [114.393945, 1.518124],
          [114.392884, 1.51777],
          [114.391083, 1.517255],
          [114.38999, 1.517094],
          [114.389186, 1.516902],
          [114.388382, 1.516644],
          [114.387771, 1.516258],
          [114.38716, 1.515937],
          [114.386806, 1.515454],
          [114.386677, 1.514972],
          [114.38671, 1.514554],
          [114.386935, 1.514168],
          [114.388735, 1.512432],
          [114.389346, 1.511788],
          [114.389668, 1.511306],
          [114.389829, 1.510727],
          [114.389861, 1.51018],
          [114.389797, 1.509441],
          [114.389379, 1.506161],
          [114.389121, 1.504521],
          [114.388896, 1.502495],
          [114.388671, 1.501273],
          [114.38851, 1.500533],
          [114.388285, 1.499858],
          [114.387706, 1.499118],
          [114.386741, 1.498121],
          [114.386356, 1.497607],
          [114.384555, 1.496417],
          [114.384008, 1.495774],
          [114.383847, 1.495291],
          [114.383494, 1.494519],
          [114.383108, 1.493233],
          [114.382754, 1.492687],
          [114.382529, 1.492429],
          [114.381982, 1.492108],
          [114.381564, 1.492011],
          [114.377062, 1.492365],
          [114.37613, 1.492269],
          [114.374779, 1.491754],
          [114.370245, 1.490789],
          [114.369762, 1.490564],
          [114.369248, 1.490146],
          [114.367608, 1.487027],
          [114.367351, 1.486609],
          [114.367061, 1.486416],
          [114.365711, 1.486513],
          [114.362881, 1.486866],
          [114.362334, 1.486834],
          [114.361562, 1.486577],
          [114.360887, 1.486545],
          [114.360083, 1.486673],
          [114.358636, 1.487349],
          [114.358121, 1.48751],
          [114.357446, 1.487477],
          [114.356835, 1.487349],
          [114.355967, 1.487091],
          [114.354584, 1.48648],
          [114.352944, 1.485966],
          [114.351433, 1.485355],
          [114.345483, 1.483746],
          [114.339856, 1.482942],
          [114.338891, 1.482653],
          [114.336929, 1.48201],
          [114.334904, 1.481109],
          [114.334196, 1.480659],
          [114.333649, 1.480113],
          [114.333392, 1.47963],
          [114.333296, 1.479084],
          [114.33336, 1.478408],
          [114.333264, 1.477411],
          [114.332942, 1.477122],
          [114.330144, 1.476382],
          [114.328408, 1.475771],
          [114.326671, 1.475257],
          [114.325642, 1.475032],
          [114.324806, 1.474775],
          [114.323745, 1.474582],
          [114.322072, 1.474389],
          [114.321333, 1.474228],
          [114.320529, 1.473971],
          [114.319564, 1.473392],
          [114.317956, 1.473038],
          [114.316927, 1.472331],
          [114.316188, 1.472041],
          [114.31085, 1.470787],
          [114.309724, 1.470401],
          [114.308373, 1.469629],
          [114.307473, 1.468858],
          [114.306669, 1.468054],
          [114.306155, 1.46741],
          [114.305769, 1.466767],
          [114.305544, 1.466188],
          [114.305415, 1.465159],
          [114.305286, 1.464806],
          [114.304965, 1.464259],
          [114.303936, 1.462844],
          [114.303614, 1.462169],
          [114.303453, 1.461622],
          [114.303357, 1.460721],
          [114.303486, 1.459692],
          [114.303421, 1.459146],
          [114.30326, 1.458728],
          [114.302842, 1.457988],
          [114.301588, 1.456669],
          [114.301106, 1.456316],
          [114.300591, 1.456187],
          [114.299916, 1.456155],
          [114.297955, 1.456219],
          [114.297022, 1.456187],
          [114.296443, 1.456058],
          [114.29432, 1.455416],
          [114.293195, 1.454837],
          [114.292455, 1.45458],
          [114.291169, 1.454323],
          [114.286409, 1.452232],
          [114.283419, 1.451171],
          [114.281843, 1.450689],
          [114.280299, 1.450689],
          [114.27927, 1.450849],
          [114.27763, 1.450914],
          [114.276923, 1.450721],
          [114.274382, 1.449499],
          [114.273546, 1.449209],
          [114.272903, 1.449049],
          [114.272067, 1.448952],
          [114.271392, 1.448952],
          [114.270813, 1.449016],
          [114.270041, 1.449113],
          [114.267726, 1.449853],
          [114.267179, 1.449981],
          [114.266504, 1.450013],
          [114.265796, 1.449917],
          [114.26525, 1.449788],
          [114.264606, 1.44982],
          [114.264028, 1.449981],
          [114.263545, 1.450174],
          [114.262645, 1.450142],
          [114.262066, 1.449885],
          [114.260941, 1.449274],
          [114.259558, 1.449081],
          [114.259075, 1.449242],
          [114.258432, 1.449531],
          [114.257596, 1.449724],
          [114.254895, 1.450206],
          [114.25422, 1.450271],
          [114.253802, 1.450078],
          [114.253094, 1.449274],
          [114.252773, 1.449081],
          [114.252355, 1.449081],
          [114.252065, 1.44937],
          [114.251615, 1.450271],
          [114.251229, 1.450882],
          [114.250875, 1.451139],
          [114.250168, 1.451267],
          [114.249685, 1.451171],
          [114.24782, 1.44982],
          [114.24618, 1.44892],
          [114.245312, 1.448341],
          [114.244508, 1.447473],
          [114.243801, 1.446958],
          [114.243254, 1.446669],
          [114.243029, 1.446669],
          [114.242289, 1.446862],
          [114.241582, 1.447215],
          [114.241003, 1.447376],
          [114.240681, 1.447376],
          [114.24007, 1.447183],
          [114.239588, 1.446862],
          [114.239266, 1.446412],
          [114.238687, 1.445029],
          [114.238205, 1.444193],
          [114.23798, 1.443389],
          [114.237883, 1.442714],
          [114.237883, 1.441556],
          [114.238044, 1.440913],
          [114.238687, 1.439047],
          [114.238591, 1.438019],
          [114.238462, 1.437408],
          [114.238173, 1.437215],
          [114.23769, 1.43715],
          [114.237176, 1.437311],
          [114.236243, 1.43744],
          [114.235793, 1.437343],
          [114.235311, 1.437118],
          [114.234957, 1.436732],
          [114.234603, 1.435864],
          [114.234507, 1.435317],
          [114.234153, 1.434127],
          [114.233864, 1.433677],
          [114.23322, 1.432905],
          [114.230262, 1.429593],
          [114.228429, 1.427824],
          [114.227271, 1.426892],
          [114.225695, 1.425798],
          [114.22502, 1.425252],
          [114.224312, 1.424544],
          [114.223734, 1.423579],
          [114.223573, 1.422936],
          [114.223444, 1.422164],
          [114.223444, 1.420878],
          [114.223573, 1.419528],
          [114.22383, 1.418595],
          [114.22383, 1.417244],
          [114.223766, 1.416762],
          [114.223476, 1.416183],
          [114.219328, 1.413707],
          [114.21682, 1.412357],
          [114.21489, 1.412035],
          [114.214247, 1.411649],
          [114.21354, 1.410684],
          [114.213218, 1.410331],
          [114.2128, 1.41017],
          [114.212157, 1.410106],
          [114.210871, 1.410331],
          [114.209906, 1.410299],
          [114.209424, 1.41017],
          [114.208877, 1.409848],
          [114.208234, 1.409623],
          [114.207527, 1.409559],
          [114.206948, 1.409688],
          [114.206691, 1.409913],
          [114.206015, 1.411167],
          [114.20534, 1.413161],
          [114.203925, 1.416023],
          [114.203925, 1.418273],
          [114.203989, 1.419592],
          [114.203861, 1.420299],
          [114.203603, 1.420717],
          [114.203089, 1.421135],
          [114.202703, 1.421296],
          [114.20087, 1.42165],
          [114.199037, 1.421843],
          [114.197783, 1.422036],
          [114.197172, 1.422229],
          [114.196368, 1.422711],
          [114.195307, 1.423837],
          [114.194889, 1.424223],
          [114.194181, 1.424641],
          [114.193924, 1.424994],
          [114.193667, 1.425734],
          [114.193538, 1.426506],
          [114.193345, 1.426924],
          [114.19312, 1.427342],
          [114.192605, 1.42776],
          [114.192155, 1.427953],
          [114.191801, 1.428017],
          [114.189647, 1.427953],
          [114.188521, 1.428082],
          [114.187621, 1.428243],
          [114.186495, 1.428693],
          [114.185691, 1.429207],
          [114.185081, 1.429754],
          [114.183633, 1.431491],
          [114.182637, 1.432584],
          [114.181543, 1.433516],
          [114.180964, 1.434224],
          [114.179967, 1.435092],
          [114.179421, 1.435382],
          [114.178456, 1.435671],
          [114.177073, 1.435993],
          [114.176044, 1.436378],
          [114.17553, 1.436668],
          [114.174662, 1.437568],
          [114.174179, 1.437922],
          [114.173472, 1.438211],
          [114.173022, 1.43834],
          [114.172346, 1.438758],
          [114.171703, 1.439047],
          [114.17106, 1.43924],
          [114.170352, 1.439369],
          [114.169259, 1.439658],
          [114.16868, 1.439916],
          [114.166429, 1.441137],
          [114.165625, 1.441395],
          [114.164886, 1.441555],
          [114.163889, 1.441555],
          [114.163245, 1.441395],
          [114.16286, 1.441202],
          [114.162377, 1.44088],
          [114.161959, 1.440751],
          [114.161541, 1.440816],
          [114.161348, 1.440944],
          [114.161155, 1.441234],
          [114.160769, 1.442006],
          [114.159837, 1.445768],
          [114.159451, 1.446862],
          [114.159065, 1.448727],
          [114.158775, 1.449434],
          [114.157907, 1.45027],
          [114.156653, 1.450913],
          [114.154788, 1.451557],
          [114.153823, 1.4522],
          [114.153566, 1.452779],
          [114.153212, 1.454258],
          [114.152762, 1.454901],
          [114.152087, 1.455641],
          [114.151443, 1.456445],
          [114.150832, 1.457313],
          [114.150221, 1.458921],
          [114.149643, 1.461043],
          [114.14945, 1.461494],
          [114.148806, 1.462072],
          [114.147488, 1.462748],
          [114.145494, 1.463616],
          [114.144658, 1.464324],
          [114.143822, 1.464645],
          [114.14305, 1.464838],
          [114.142214, 1.464967],
          [114.141474, 1.464999],
          [114.140638, 1.464935],
          [114.138001, 1.464452],
          [114.137422, 1.464291],
          [114.137197, 1.464131],
          [114.136297, 1.463166],
          [114.135847, 1.462876],
          [114.134914, 1.462812],
          [114.134078, 1.462876],
          [114.133403, 1.463005],
          [114.131634, 1.463198],
          [114.129962, 1.463166],
          [114.125717, 1.462973],
          [114.124238, 1.462973],
          [114.123434, 1.463069],
          [114.122148, 1.463005],
          [114.121569, 1.462748],
          [114.121311, 1.462426],
          [114.121086, 1.461944],
          [114.120829, 1.461172],
          [114.1207, 1.460304],
          [114.120282, 1.458921],
          [114.120122, 1.458535],
          [114.119704, 1.458213],
          [114.119253, 1.45802],
          [114.118321, 1.45786],
          [114.117838, 1.457635],
          [114.11697, 1.456509],
          [114.116455, 1.456091],
          [114.115844, 1.455834],
          [114.115169, 1.455769],
          [114.114397, 1.455802],
          [114.112983, 1.455737],
          [114.112307, 1.455609],
          [114.111439, 1.455512],
          [114.109413, 1.455416],
          [114.108674, 1.45548],
          [114.108127, 1.45548],
          [114.105779, 1.455319],
          [114.105458, 1.455383],
          [114.104879, 1.455769],
          [114.104686, 1.456091],
          [114.104557, 1.456573],
          [114.104203, 1.459017],
          [114.103753, 1.46069],
          [114.102724, 1.462298],
          [114.102338, 1.462716],
          [114.10012, 1.464484],
          [114.098865, 1.465578],
          [114.09774, 1.466349],
          [114.097225, 1.466349],
          [114.096743, 1.466253],
          [114.096003, 1.465803],
          [114.095617, 1.465642],
          [114.094813, 1.465481],
          [114.09417, 1.465288],
          [114.093527, 1.464902],
          [114.093012, 1.464323],
          [114.091983, 1.463327],
          [114.091437, 1.46323],
          [114.090794, 1.463391],
          [114.090343, 1.463584],
          [114.0897, 1.463938],
          [114.089154, 1.464066],
          [114.08835, 1.463938],
          [114.087964, 1.463745],
          [114.08761, 1.463294],
          [114.087288, 1.46278],
          [114.086774, 1.46249],
          [114.086131, 1.46249],
          [114.085777, 1.462555],
          [114.08523, 1.462812],
          [114.083655, 1.464034],
          [114.083204, 1.464227],
          [114.081982, 1.464388],
          [114.0806, 1.464709],
          [114.08015, 1.464967],
          [114.079893, 1.465192],
          [114.079378, 1.46516],
          [114.078124, 1.464163],
          [114.077738, 1.463938],
          [114.077127, 1.463712],
          [114.076548, 1.463616],
          [114.074394, 1.463487],
          [114.073686, 1.463359],
          [114.07285, 1.463069],
          [114.069731, 1.461815],
          [114.068573, 1.461236],
          [114.067094, 1.460304],
          [114.065389, 1.459371],
          [114.063396, 1.459082],
          [114.06227, 1.458696],
          [114.061402, 1.458278],
          [114.060437, 1.457699],
          [114.059537, 1.457249],
          [114.058829, 1.457024],
          [114.058089, 1.456959],
          [114.057253, 1.456992],
          [114.055806, 1.457281],
          [114.054681, 1.457442],
          [114.053909, 1.457538],
          [114.053555, 1.457506],
          [114.053234, 1.457249],
          [114.052912, 1.456863],
          [114.051947, 1.454869],
          [114.051401, 1.453969],
          [114.050822, 1.453326],
          [114.049793, 1.452457],
          [114.048892, 1.452039],
          [114.048217, 1.451782],
          [114.045837, 1.4513],
          [114.044679, 1.451332],
          [114.043972, 1.451428],
          [114.043329, 1.451428],
          [114.041882, 1.451235],
          [114.041464, 1.4513],
          [114.040627, 1.451654],
          [114.039824, 1.451911],
          [114.039116, 1.452039],
          [114.037508, 1.451879],
          [114.035932, 1.451461],
          [114.034196, 1.451107],
          [114.032202, 1.450785],
          [114.030723, 1.450978],
          [114.030176, 1.451171],
          [114.029855, 1.451364],
          [114.029051, 1.452554],
          [114.028729, 1.452876],
          [114.028408, 1.452908],
          [114.02799, 1.452876],
          [114.027443, 1.452747],
          [114.024452, 1.450978],
          [114.022716, 1.450078],
          [114.021815, 1.449692],
          [114.020175, 1.449177],
          [114.018921, 1.448856],
          [114.017924, 1.448502],
          [114.017217, 1.448341],
          [114.016381, 1.448277],
          [114.014419, 1.448373],
          [114.013744, 1.448534],
          [114.012972, 1.448824],
          [114.011107, 1.449628],
          [114.010496, 1.449821],
          [114.009756, 1.449821],
          [114.009081, 1.449499],
          [114.007537, 1.448438],
          [114.006991, 1.44818],
          [114.005865, 1.447795],
          [114.004547, 1.447184],
          [114.00317, 1.446367],
          [114.002242, 1.445757],
          [114.001388, 1.445147],
          [114.00068, 1.444537],
          [113.999997, 1.444024],
          [113.999411, 1.443634],
          [113.998801, 1.443511],
          [113.997898, 1.443585],
          [113.997263, 1.443731],
          [113.996604, 1.444097],
          [113.996091, 1.44461],
          [113.995701, 1.445074],
          [113.995237, 1.445513],
          [113.994529, 1.445781],
          [113.993675, 1.445903],
          [113.992577, 1.446148],
          [113.991478, 1.446611],
          [113.990185, 1.446953],
          [113.988476, 1.447612],
          [113.986474, 1.448515],
          [113.985473, 1.448906],
          [113.984546, 1.449199],
          [113.983692, 1.449394],
          [113.982398, 1.449565],
          [113.981373, 1.449467],
          [113.980201, 1.449516],
          [113.978712, 1.450077],
          [113.97715, 1.450224],
          [113.975734, 1.450053],
          [113.975075, 1.449711],
          [113.974368, 1.449223],
          [113.972903, 1.448369],
          [113.971756, 1.448198],
          [113.97078, 1.447881],
          [113.970291, 1.447441],
          [113.969803, 1.446709],
          [113.96912, 1.444878],
          [113.968876, 1.443878],
          [113.968778, 1.443121],
          [113.968534, 1.44256],
          [113.96829, 1.442193],
          [113.967875, 1.441925],
          [113.96724, 1.441949],
          [113.966752, 1.442145],
          [113.9658, 1.442974],
          [113.96519, 1.443316],
          [113.964531, 1.443585],
          [113.96353, 1.443731],
          [113.962554, 1.443682],
          [113.960503, 1.443316],
          [113.959405, 1.443267],
          [113.958746, 1.443292],
          [113.95777, 1.443072],
          [113.956647, 1.442535],
          [113.956061, 1.442364],
          [113.955451, 1.442364],
          [113.954816, 1.442437],
          [113.954206, 1.442389],
          [113.953742, 1.442145],
          [113.952888, 1.441266],
          [113.952375, 1.440876],
          [113.951692, 1.440705],
          [113.950447, 1.440266],
          [113.949519, 1.43968],
          [113.948836, 1.438825],
          [113.948592, 1.438337],
          [113.948446, 1.438166],
          [113.947616, 1.437678],
          [113.946639, 1.437288],
          [113.945931, 1.436824],
          [113.945346, 1.436311],
          [113.945053, 1.435628],
          [113.94476, 1.434432],
          [113.944516, 1.434017],
          [113.944223, 1.433626],
          [113.943417, 1.432967],
          [113.942514, 1.431844],
          [113.941684, 1.430673],
          [113.941074, 1.429623],
          [113.94061, 1.428695],
          [113.940342, 1.427963],
          [113.940244, 1.425474],
          [113.939903, 1.422545],
          [113.939683, 1.421739],
          [113.93917, 1.420836],
          [113.938853, 1.420055],
          [113.938023, 1.41676],
          [113.937657, 1.416027],
          [113.937169, 1.415637],
          [113.936656, 1.415442],
          [113.936046, 1.415491],
          [113.935362, 1.415686],
          [113.934191, 1.415661],
          [113.932946, 1.415539],
          [113.931896, 1.415002],
          [113.930896, 1.414612],
          [113.929992, 1.414197],
          [113.929187, 1.41366],
          [113.928381, 1.41283],
          [113.927308, 1.411366],
          [113.926453, 1.410706],
          [113.925648, 1.410218],
          [113.924915, 1.409828],
          [113.924183, 1.409559],
          [113.923475, 1.40951],
          [113.922548, 1.409633],
          [113.921425, 1.40995],
          [113.91979, 1.410609],
          [113.918643, 1.410804],
          [113.918032, 1.410804],
          [113.917324, 1.410487],
          [113.91669, 1.410267],
          [113.916031, 1.410292],
          [113.915567, 1.410365],
          [113.915176, 1.41056],
          [113.914591, 1.411073],
          [113.914078, 1.411902],
          [113.913736, 1.412562],
          [113.913224, 1.412976],
          [113.91254, 1.413318],
          [113.911637, 1.413562],
          [113.911051, 1.413489],
          [113.910246, 1.413269],
          [113.909513, 1.413196],
          [113.908586, 1.412903],
          [113.907805, 1.412488],
          [113.907243, 1.411805],
          [113.906487, 1.410511],
          [113.906023, 1.409315],
          [113.905193, 1.408168],
          [113.904461, 1.407387],
          [113.903606, 1.40663],
          [113.901678, 1.405215],
          [113.899115, 1.403823],
          [113.897797, 1.403262],
          [113.896943, 1.403017],
          [113.895259, 1.402969],
          [113.894111, 1.403067],
          [113.89355, 1.402993],
          [113.892842, 1.402822],
          [113.891768, 1.40248],
          [113.887961, 1.400992],
          [113.886276, 1.399576],
          [113.885544, 1.398892],
          [113.88508, 1.398185],
          [113.884568, 1.397526],
          [113.883933, 1.397013],
          [113.88303, 1.39655],
          [113.882542, 1.396183],
          [113.882102, 1.395646],
          [113.881687, 1.394743],
          [113.880442, 1.391155],
          [113.879979, 1.390594],
          [113.879466, 1.39013],
          [113.878563, 1.389593],
          [113.876806, 1.388446],
          [113.876049, 1.388079],
          [113.875195, 1.387909],
          [113.874584, 1.387957],
          [113.873706, 1.388153],
          [113.87251, 1.388177],
          [113.871436, 1.38803],
          [113.870679, 1.387591],
          [113.870142, 1.387176],
          [113.869385, 1.386761],
          [113.8688, 1.386542],
          [113.868116, 1.386444],
          [113.866066, 1.386737],
          [113.865016, 1.386761],
          [113.863698, 1.386981],
          [113.861355, 1.387835],
          [113.859939, 1.38786],
          [113.858182, 1.38764],
          [113.853837, 1.388104],
          [113.853203, 1.388055],
          [113.852324, 1.38786],
          [113.851689, 1.387616],
          [113.850933, 1.387152],
          [113.850469, 1.386664],
          [113.850225, 1.386029],
          [113.849883, 1.384101],
          [113.850176, 1.38249],
          [113.850664, 1.381318],
          [113.850786, 1.380708],
          [113.85064, 1.380391],
          [113.850054, 1.380074],
          [113.849248, 1.379903],
          [113.84793, 1.379341],
          [113.846149, 1.378682],
          [113.845172, 1.378511],
          [113.84339, 1.378585],
          [113.841584, 1.37878],
          [113.839948, 1.378853],
          [113.838582, 1.378853],
          [113.837483, 1.37878],
          [113.836287, 1.378585],
          [113.835238, 1.378292],
          [113.834188, 1.377828],
          [113.833334, 1.377169],
          [113.832626, 1.376363],
          [113.830917, 1.37424],
          [113.829965, 1.373385],
          [113.829013, 1.372824],
          [113.827402, 1.372116],
          [113.826572, 1.371945],
          [113.825449, 1.371823],
          [113.824668, 1.371628],
          [113.824058, 1.371433],
          [113.823228, 1.371091],
          [113.82252, 1.3707],
          [113.822154, 1.370383],
          [113.821837, 1.369748],
          [113.821251, 1.367845],
          [113.820885, 1.367454],
          [113.820324, 1.367161],
          [113.818957, 1.366722],
          [113.818566, 1.366429],
          [113.818054, 1.366209],
          [113.81759, 1.365648],
          [113.816492, 1.364647],
          [113.815759, 1.364281],
          [113.815198, 1.364135],
          [113.814832, 1.363842],
          [113.814759, 1.363427],
          [113.814759, 1.363134],
          [113.814905, 1.362939],
          [113.815222, 1.36267],
          [113.815759, 1.362548],
          [113.817053, 1.362035],
          [113.817712, 1.361718],
          [113.81842, 1.36123],
          [113.819006, 1.360717],
          [113.819738, 1.359887],
          [113.820592, 1.359082],
          [113.823692, 1.356617],
          [113.824058, 1.356055],
          [113.824424, 1.355225],
          [113.824742, 1.354298],
          [113.82523, 1.353834],
          [113.82562, 1.353663],
          [113.827866, 1.35337],
          [113.828891, 1.353297],
          [113.830111, 1.352955],
          [113.830404, 1.352711],
          [113.830526, 1.352272],
          [113.8306, 1.350734],
          [113.830795, 1.349733],
          [113.830844, 1.349049],
          [113.83099, 1.34822],
          [113.831015, 1.347243],
          [113.830795, 1.345242],
          [113.830234, 1.344314],
          [113.829965, 1.343606],
          [113.829526, 1.341898],
          [113.829282, 1.340726],
          [113.829404, 1.340165],
          [113.829306, 1.339237],
          [113.829038, 1.338383],
          [113.828964, 1.337748],
          [113.828964, 1.337358],
          [113.829038, 1.33687],
          [113.829135, 1.336577],
          [113.829672, 1.335845],
          [113.829843, 1.335381],
          [113.829892, 1.33438],
          [113.829648, 1.332769],
          [113.829672, 1.331964],
          [113.829477, 1.330524],
          [113.829282, 1.329962],
          [113.828915, 1.329254],
          [113.828476, 1.328546],
          [113.828012, 1.328034],
          [113.826329, 1.32652],
          [113.82567, 1.326154],
          [113.825108, 1.325568],
          [113.824791, 1.324982],
          [113.82462, 1.324348],
          [113.824596, 1.323689],
          [113.82484, 1.31971],
          [113.824498, 1.318539],
          [113.824156, 1.317928],
          [113.823473, 1.317294],
          [113.822911, 1.316684],
          [113.822252, 1.316098],
          [113.821911, 1.31517],
          [113.821105, 1.31373],
          [113.820568, 1.311924],
          [113.820202, 1.310411],
          [113.819494, 1.308458],
          [113.818566, 1.30692],
          [113.81781, 1.305919],
          [113.815076, 1.302942],
          [113.811439, 1.299939],
          [113.810023, 1.298865],
          [113.80934, 1.298426],
          [113.808315, 1.297913],
          [113.806021, 1.29701],
          [113.799943, 1.295131],
          [113.7955, 1.293862],
          [113.791936, 1.292959],
          [113.786908, 1.291323],
          [113.781246, 1.289127],
          [113.780367, 1.288712],
          [113.779317, 1.288126],
          [113.778609, 1.287662],
          [113.777072, 1.286368],
          [113.776779, 1.286051],
          [113.776413, 1.285953],
          [113.776095, 1.285978],
          [113.775217, 1.286198],
          [113.774606, 1.286173],
          [113.773899, 1.285929],
          [113.773459, 1.285685],
          [113.773166, 1.285441],
          [113.772288, 1.285099],
          [113.771238, 1.284611],
          [113.767943, 1.282732],
          [113.766966, 1.282243],
          [113.766136, 1.281926],
          [113.765184, 1.281682],
          [113.763085, 1.281267],
          [113.762646, 1.281121],
          [113.762548, 1.28095],
          [113.76228, 1.279338],
          [113.762134, 1.278826],
          [113.761206, 1.277678],
          [113.76062, 1.277093],
          [113.760059, 1.276409],
          [113.759448, 1.275823],
          [113.758741, 1.275335],
          [113.756202, 1.273285],
          [113.75547, 1.273016],
          [113.75442, 1.272894],
          [113.753188, 1.27287],
          [113.752736, 1.272968],
          [113.75226, 1.273212],
          [113.751674, 1.273676],
          [113.751088, 1.274042],
          [113.750649, 1.274213],
          [113.750136, 1.274286],
          [113.749551, 1.274262],
          [113.747988, 1.273847],
          [113.744962, 1.272944],
          [113.744376, 1.272553],
          [113.743741, 1.27204],
          [113.742985, 1.271699],
          [113.74113, 1.27121],
          [113.740349, 1.271064],
          [113.73991, 1.271113],
          [113.738347, 1.271454],
          [113.737932, 1.271454],
          [113.737566, 1.271381],
          [113.73698, 1.270624],
          [113.736565, 1.269599],
          [113.735809, 1.268232],
          [113.734979, 1.2675],
          [113.733929, 1.26728],
          [113.731025, 1.267451],
          [113.729926, 1.267329],
          [113.72873, 1.267256],
          [113.727388, 1.267256],
          [113.726558, 1.267085],
          [113.726021, 1.266817],
          [113.725118, 1.266231],
          [113.724629, 1.266206],
          [113.723311, 1.266646],
          [113.722628, 1.266646],
          [113.721798, 1.266524],
          [113.721017, 1.266255],
          [113.720187, 1.265816],
          [113.719625, 1.265352],
          [113.719211, 1.264815],
          [113.718942, 1.264303],
          [113.718259, 1.263668],
          [113.717453, 1.263204],
          [113.716843, 1.262789],
          [113.715744, 1.26152],
          [113.715207, 1.261252],
          [113.714646, 1.261154],
          [113.714207, 1.2613],
          [113.713133, 1.262057],
          [113.712132, 1.262326],
          [113.710936, 1.262448],
          [113.710008, 1.262618],
          [113.707665, 1.262814],
          [113.704833, 1.262814],
          [113.703686, 1.262936],
          [113.703247, 1.262911],
          [113.702905, 1.262716],
          [113.702783, 1.262326],
          [113.702759, 1.261862],
          [113.702661, 1.261374],
          [113.702368, 1.260641],
          [113.70166, 1.259201],
          [113.700464, 1.255491],
          [113.699464, 1.25388],
          [113.698975, 1.253368],
          [113.698341, 1.252587],
          [113.697609, 1.251976],
          [113.697072, 1.251244],
          [113.695607, 1.249877],
          [113.69468, 1.248315],
          [113.693996, 1.247412],
          [113.692532, 1.245776],
          [113.692165, 1.245117],
          [113.691214, 1.242481],
          [113.690652, 1.241285],
          [113.690042, 1.240382],
          [113.689432, 1.239601],
          [113.688114, 1.238551],
          [113.68743, 1.238112],
          [113.686551, 1.237648],
          [113.685795, 1.237087],
          [113.684257, 1.236159],
          [113.683647, 1.235867],
          [113.683134, 1.2355],
          [113.682915, 1.23511],
          [113.682597, 1.234671],
          [113.682256, 1.234378],
          [113.68123, 1.233987],
          [113.680523, 1.233499],
          [113.677764, 1.231351],
          [113.67713, 1.230546],
          [113.67691, 1.230423],
          [113.676422, 1.230399],
          [113.675397, 1.230692],
          [113.674469, 1.230838],
          [113.673981, 1.230863],
          [113.670564, 1.230277],
          [113.669856, 1.229984],
          [113.66927, 1.229618],
          [113.668367, 1.228837],
          [113.667049, 1.227421],
          [113.666317, 1.226567],
          [113.663973, 1.22166],
          [113.663973, 1.221123],
          [113.66412, 1.220537],
          [113.664657, 1.219732],
          [113.665658, 1.218023],
          [113.666341, 1.217022],
          [113.666512, 1.216705],
          [113.666512, 1.216412],
          [113.666268, 1.216022],
          [113.665951, 1.215729],
          [113.665267, 1.215216],
          [113.664608, 1.214801],
          [113.663827, 1.214557],
          [113.662851, 1.214411],
          [113.661972, 1.214313],
          [113.661093, 1.214313],
          [113.660117, 1.214557],
          [113.659043, 1.215046],
          [113.658457, 1.215803],
          [113.657578, 1.216828],
          [113.657017, 1.217633],
          [113.656651, 1.218463],
          [113.656407, 1.219293],
          [113.656016, 1.219781],
          [113.655455, 1.220172],
          [113.65482, 1.220514],
          [113.654186, 1.22066],
          [113.652648, 1.220562],
          [113.651257, 1.220294],
          [113.648694, 1.220294],
          [113.64718, 1.220172],
          [113.644276, 1.220245],
          [113.643348, 1.220123],
          [113.641518, 1.219513],
          [113.638345, 1.218366],
          [113.637686, 1.218097],
          [113.636953, 1.217707],
          [113.636148, 1.21717],
          [113.63544, 1.216877],
          [113.634659, 1.216633],
          [113.633878, 1.216511],
          [113.632535, 1.216486],
          [113.631998, 1.216364],
          [113.631486, 1.216145],
          [113.63068, 1.215974],
          [113.630021, 1.216023],
          [113.62924, 1.216242],
          [113.62841, 1.216584],
          [113.626482, 1.217463],
          [113.625994, 1.21778],
          [113.624578, 1.220733],
          [113.623357, 1.222051],
          [113.623138, 1.222442],
          [113.622991, 1.222905],
          [113.622771, 1.224492],
          [113.622552, 1.224931],
          [113.622112, 1.225371],
          [113.620721, 1.225957],
          [113.620111, 1.226152],
          [113.619257, 1.226249],
          [113.617865, 1.226298],
          [113.617523, 1.226567],
          [113.617304, 1.226933],
          [113.617401, 1.227201],
          [113.618378, 1.228617],
          [113.618768, 1.229349],
          [113.618988, 1.229911],
          [113.619061, 1.230399],
          [113.619012, 1.231424],
          [113.619061, 1.231814],
          [113.619403, 1.232327],
          [113.62077, 1.233645],
          [113.621868, 1.234524],
          [113.623308, 1.235427],
          [113.625115, 1.236452],
          [113.626018, 1.237258],
          [113.626433, 1.237721],
          [113.626677, 1.238307],
          [113.626726, 1.2386],
          [113.626726, 1.239137],
          [113.626604, 1.240334],
          [113.626286, 1.241823],
          [113.625505, 1.245996],
          [113.624993, 1.247607],
          [113.624895, 1.248315],
          [113.624895, 1.249511],
          [113.624749, 1.249706],
          [113.624505, 1.249877],
          [113.624017, 1.250121],
          [113.622308, 1.250658],
          [113.621307, 1.250853],
          [113.620355, 1.250951],
          [113.619354, 1.250951],
          [113.616938, 1.251073],
          [113.615742, 1.251244],
          [113.615107, 1.25139],
          [113.614594, 1.251781],
          [113.614155, 1.25222],
          [113.613643, 1.252635],
          [113.611958, 1.253489],
          [113.611226, 1.254026],
          [113.610714, 1.254563],
          [113.609786, 1.255149],
          [113.608907, 1.255589],
          [113.608248, 1.255808],
          [113.6061, 1.256126],
          [113.605441, 1.256443],
          [113.604904, 1.256907],
          [113.604123, 1.257444],
          [113.600828, 1.258908],
          [113.599925, 1.259225],
          [113.598973, 1.259469],
          [113.597045, 1.259665],
          [113.596337, 1.259836],
          [113.595897, 1.260006],
          [113.595629, 1.260226],
          [113.595409, 1.260714],
          [113.595287, 1.261227],
          [113.595238, 1.262765],
          [113.595336, 1.263619],
          [113.595311, 1.264547],
          [113.594701, 1.267354],
          [113.594286, 1.268769],
          [113.593993, 1.269575],
          [113.593896, 1.270136],
          [113.593945, 1.271332],
          [113.594335, 1.272455],
          [113.594628, 1.274188],
          [113.594726, 1.275433],
          [113.594677, 1.275823],
          [113.594457, 1.276068],
          [113.594091, 1.276336],
          [113.592138, 1.277312],
          [113.591601, 1.277898],
          [113.590772, 1.279143],
          [113.590381, 1.279997],
          [113.589698, 1.281804],
          [113.589722, 1.282096],
          [113.589868, 1.282414],
          [113.590967, 1.284391],
          [113.591382, 1.285343],
          [113.591528, 1.285758],
          [113.591479, 1.286026],
          [113.591186, 1.286344],
          [113.590674, 1.286563],
          [113.590015, 1.287051],
          [113.58899, 1.287613],
          [113.588013, 1.288052],
          [113.586061, 1.289321],
          [113.584279, 1.290688],
          [113.583644, 1.291079],
          [113.583229, 1.291445],
          [113.582814, 1.292031],
          [113.58257, 1.292617],
          [113.582253, 1.293788],
          [113.580715, 1.295302],
          [113.580496, 1.295814],
          [113.580447, 1.296375],
          [113.580569, 1.297572],
          [113.581106, 1.2995],
          [113.581277, 1.299988],
          [113.582009, 1.30155],
          [113.582082, 1.302014],
          [113.582058, 1.302844],
          [113.582155, 1.303552],
          [113.582351, 1.304406],
          [113.582204, 1.305456],
          [113.582058, 1.305944],
          [113.581862, 1.306261],
          [113.581521, 1.30653],
          [113.581252, 1.306652],
          [113.58074, 1.306603],
          [113.579983, 1.306456],
          [113.578494, 1.306359],
          [113.576663, 1.305822],
          [113.574198, 1.304357],
          [113.573686, 1.304211],
          [113.573197, 1.304284],
          [113.572685, 1.304723],
          [113.572416, 1.305138],
          [113.572075, 1.305504],
          [113.571513, 1.305895],
          [113.567193, 1.308238],
          [113.56668, 1.308385],
          [113.56607, 1.308336],
          [113.561945, 1.306774],
          [113.560358, 1.30692],
          [113.559284, 1.307091],
          [113.558845, 1.307213],
          [113.558479, 1.307506],
          [113.556624, 1.310947],
          [113.556306, 1.311362],
          [113.555013, 1.312388],
          [113.55411, 1.313022],
          [113.552523, 1.314316],
          [113.551181, 1.315609],
          [113.550863, 1.316244],
          [113.550473, 1.31683],
          [113.549911, 1.317367],
          [113.549155, 1.317904],
          [113.548325, 1.318294],
          [113.545982, 1.319124],
          [113.545347, 1.31949],
          [113.544468, 1.319808],
          [113.539611, 1.320808],
          [113.537756, 1.321101],
          [113.535486, 1.321272],
          [113.534754, 1.32115],
          [113.532972, 1.320686],
          [113.530897, 1.319954],
          [113.530189, 1.319661],
          [113.529603, 1.31949],
          [113.528797, 1.319368],
          [113.52787, 1.31932],
          [113.526405, 1.31932],
          [113.525771, 1.319124],
          [113.525038, 1.318831],
          [113.524331, 1.318416],
          [113.522793, 1.317294],
          [113.521987, 1.316879],
          [113.520254, 1.316561],
          [113.519254, 1.316293],
          [113.517619, 1.315756],
          [113.514641, 1.31456],
          [113.513958, 1.314194],
          [113.513518, 1.313803],
          [113.512981, 1.313632],
          [113.512493, 1.313559],
          [113.5122, 1.313608],
          [113.511517, 1.313974],
          [113.510858, 1.314267],
          [113.507318, 1.315585],
          [113.506635, 1.315902],
          [113.505927, 1.316171],
          [113.504438, 1.316049],
          [113.503608, 1.315878],
          [113.503095, 1.315634],
          [113.50251, 1.315244],
          [113.502119, 1.314902],
          [113.501631, 1.314829],
          [113.501314, 1.314926],
          [113.500923, 1.315293],
          [113.500581, 1.315756],
          [113.500044, 1.316342],
          [113.499337, 1.31683],
          [113.498604, 1.317148],
          [113.498189, 1.317099],
          [113.497628, 1.316904],
          [113.496944, 1.31644],
          [113.496017, 1.315927],
          [113.495529, 1.315463],
          [113.495309, 1.314804],
          [113.494894, 1.314267],
          [113.493674, 1.31312],
          [113.492893, 1.312559],
          [113.491306, 1.311216],
          [113.489939, 1.309947],
          [113.488792, 1.309117],
          [113.488035, 1.308409],
          [113.48723, 1.307531],
          [113.486546, 1.306969],
          [113.485741, 1.30653],
          [113.485204, 1.306359],
          [113.484642, 1.306457],
          [113.484179, 1.306823],
          [113.482958, 1.307408],
          [113.482446, 1.307433],
          [113.481786, 1.30736],
          [113.481152, 1.307042],
          [113.480786, 1.306627],
          [113.480273, 1.306164],
          [113.47915, 1.305602],
          [113.47854, 1.305187],
          [113.476905, 1.304309],
          [113.476368, 1.304187],
          [113.475709, 1.304187],
          [113.473146, 1.304577],
          [113.472096, 1.304504],
          [113.470119, 1.304162],
          [113.468728, 1.304113],
          [113.467776, 1.304016],
          [113.466995, 1.303796],
          [113.465897, 1.303601],
          [113.464115, 1.303503],
          [113.463846, 1.303332],
          [113.463553, 1.302966],
          [113.463383, 1.302527],
          [113.462943, 1.301062],
          [113.462675, 1.300623],
          [113.462333, 1.300208],
          [113.460063, 1.298377],
          [113.455816, 1.295082],
          [113.45523, 1.294398],
          [113.45462, 1.29352],
          [113.453985, 1.292714],
          [113.453521, 1.291616],
          [113.452838, 1.290298],
          [113.452399, 1.289151],
          [113.452081, 1.288028],
          [113.451764, 1.286075],
          [113.451422, 1.285684],
          [113.451056, 1.285513],
          [113.450592, 1.285343],
          [113.449933, 1.285172],
          [113.448639, 1.284952],
          [113.447468, 1.28461],
          [113.44571, 1.284317],
          [113.444685, 1.284293],
          [113.44283, 1.284098],
          [113.44034, 1.283585],
          [113.439315, 1.283488],
          [113.436606, 1.283414],
          [113.433409, 1.283488],
          [113.43148, 1.283781],
          [113.430382, 1.284147],
          [113.427868, 1.285099],
          [113.427038, 1.285319],
          [113.426501, 1.285392],
          [113.423084, 1.285514],
          [113.422034, 1.285465],
          [113.420911, 1.285319],
          [113.419081, 1.28549],
          [113.418568, 1.285685],
          [113.418275, 1.285856],
          [113.418031, 1.2861],
          [113.41786, 1.286393],
          [113.417763, 1.286783],
          [113.417738, 1.287442],
          [113.417811, 1.28815],
          [113.417738, 1.2892],
          [113.417592, 1.289712],
          [113.417226, 1.29042],
          [113.416884, 1.290762],
          [113.414834, 1.2923],
          [113.414467, 1.292666],
          [113.414272, 1.292959],
          [113.41354, 1.294521],
          [113.413198, 1.295009],
          [113.412881, 1.295326],
          [113.411782, 1.296156],
          [113.41022, 1.297718],
          [113.409707, 1.298157],
          [113.40856, 1.298792],
          [113.408218, 1.299158],
          [113.407998, 1.299597],
          [113.407217, 1.301477],
          [113.407095, 1.302185],
          [113.407071, 1.303307],
          [113.407169, 1.303844],
          [113.407535, 1.304748],
          [113.407535, 1.305089],
          [113.407461, 1.305309],
          [113.406925, 1.305822],
          [113.405582, 1.306627],
          [113.403678, 1.308067],
          [113.402336, 1.309264],
          [113.401872, 1.309459],
          [113.401189, 1.310142],
          [113.400774, 1.310874],
          [113.400041, 1.3119],
          [113.399553, 1.312925],
          [113.399163, 1.313608],
          [113.396942, 1.316025],
          [113.39638, 1.316732],
          [113.395282, 1.318636],
          [113.394916, 1.318978],
          [113.394403, 1.319198],
          [113.393793, 1.31932],
          [113.392499, 1.319417],
          [113.390913, 1.319466],
          [113.386373, 1.319979],
          [113.380954, 1.320711],
          [113.379783, 1.32098],
          [113.378782, 1.32137],
          [113.37595, 1.322786],
          [113.374217, 1.323396],
          [113.372899, 1.323738],
          [113.370409, 1.324226],
          [113.367358, 1.32469],
          [113.366675, 1.324983],
          [113.365918, 1.325617],
          [113.365455, 1.326203],
          [113.365308, 1.326838],
          [113.365332, 1.327253],
          [113.365601, 1.328424],
          [113.366309, 1.330865],
          [113.366895, 1.333208],
          [113.366919, 1.333696],
          [113.36687, 1.334111],
          [113.366748, 1.3346],
          [113.366431, 1.335088],
          [113.365308, 1.336088],
          [113.364673, 1.336772],
          [113.364088, 1.337675],
          [113.363648, 1.338627],
          [113.363185, 1.339945],
          [113.36294, 1.34097],
          [113.362721, 1.342361],
          [113.36255, 1.344436],
          [113.362525, 1.345486],
          [113.36255, 1.346609],
          [113.362623, 1.347195],
          [113.362501, 1.347683],
          [113.362111, 1.348098],
          [113.36172, 1.348415],
          [113.361061, 1.34883],
          [113.360329, 1.34944],
          [113.35967, 1.350099],
          [113.359035, 1.351003],
          [113.357522, 1.354298],
          [113.356594, 1.355884],
          [113.355423, 1.35752],
          [113.355325, 1.358203],
          [113.355349, 1.358886],
          [113.355227, 1.359033],
          [113.354934, 1.359082],
          [113.35098, 1.358545],
          [113.350248, 1.35852],
          [113.349589, 1.358642],
          [113.348124, 1.359131],
          [113.345342, 1.360156],
          [113.344487, 1.360595],
          [113.343706, 1.361156],
          [113.34295, 1.361864],
          [113.341388, 1.363793],
          [113.340777, 1.364476],
          [113.340143, 1.364842],
          [113.33797, 1.365428],
          [113.336506, 1.365721],
          [113.333503, 1.366111],
          [113.332893, 1.366307],
          [113.332478, 1.366526],
          [113.33199, 1.366892],
          [113.331648, 1.367234],
          [113.331111, 1.3676],
          [113.329549, 1.368381],
          [113.329183, 1.368821],
          [113.328573, 1.370212],
          [113.326791, 1.373239],
          [113.326474, 1.374093],
          [113.3264, 1.374728],
          [113.326425, 1.375729],
          [113.326376, 1.376144],
          [113.326254, 1.37651],
          [113.326059, 1.376754],
          [113.325766, 1.376949],
          [113.325497, 1.377022],
          [113.325009, 1.377047],
          [113.322861, 1.376973],
          [113.320396, 1.376705],
          [113.317565, 1.376534],
          [113.316491, 1.376412],
          [113.31466, 1.376095],
          [113.313659, 1.375777],
          [113.312927, 1.375411],
          [113.311829, 1.374728],
          [113.311316, 1.374508],
          [113.310462, 1.374386],
          [113.308582, 1.37402],
          [113.307679, 1.373727],
          [113.306141, 1.373385],
          [113.305726, 1.373361],
          [113.305336, 1.373556],
          [113.30497, 1.373849],
          [113.304408, 1.374337],
          [113.303847, 1.374947],
          [113.303017, 1.375753],
          [113.302236, 1.376363],
          [113.298916, 1.377803],
          [113.298452, 1.378072],
          [113.297793, 1.378218],
          [113.295011, 1.378316],
          [113.294303, 1.37856],
          [113.292472, 1.379878],
          [113.291765, 1.380464],
          [113.291203, 1.380732],
          [113.290593, 1.380903],
          [113.287249, 1.381635],
          [113.286761, 1.381782],
          [113.284954, 1.382831],
          [113.284442, 1.383076],
          [113.283441, 1.383368],
          [113.282489, 1.383466],
          [113.280756, 1.383246],
          [113.280048, 1.3831],
          [113.278632, 1.382612],
          [113.277998, 1.382563],
          [113.277558, 1.382587],
          [113.275484, 1.383295],
          [113.274678, 1.38371],
          [113.273897, 1.384369],
          [113.273287, 1.384955],
          [113.272823, 1.385541],
          [113.272603, 1.386029],
          [113.272481, 1.386444],
          [113.272384, 1.387249],
          [113.272237, 1.387884],
          [113.272091, 1.388177],
          [113.271627, 1.388543],
          [113.271188, 1.389129],
          [113.270577, 1.3903],
          [113.270211, 1.390715],
          [113.269845, 1.390984],
          [113.269113, 1.39135],
          [113.26821, 1.391667],
          [113.266941, 1.391911],
          [113.266306, 1.39196],
          [113.265671, 1.391838],
          [113.26489, 1.391472],
          [113.26406, 1.391008],
          [113.263084, 1.390325],
          [113.260375, 1.387591],
          [113.258886, 1.386566],
          [113.257421, 1.385809],
          [113.256494, 1.385468],
          [113.255371, 1.385321],
          [113.254712, 1.385272],
          [113.253125, 1.385443],
          [113.252576, 1.38537],
          [113.251453, 1.385052],
          [113.250868, 1.385028],
          [113.249379, 1.385614],
          [113.248549, 1.386297],
          [113.246742, 1.388104],
          [113.244814, 1.390374],
          [113.243301, 1.392717],
          [113.242764, 1.393278],
          [113.2423, 1.394353],
          [113.241885, 1.395134],
          [113.241738, 1.395549],
          [113.24147, 1.395793],
          [113.240884, 1.395988],
          [113.239127, 1.396208],
          [113.237638, 1.396696],
          [113.237174, 1.396671],
          [113.236711, 1.396354],
          [113.236051, 1.39633],
          [113.235515, 1.396427],
          [113.235222, 1.396696],
          [113.234245, 1.397038],
          [113.233562, 1.397379],
          [113.232756, 1.397501],
          [113.232171, 1.397477],
          [113.231145, 1.397111],
          [113.230657, 1.39672],
          [113.230291, 1.396281],
          [113.229901, 1.395939],
          [113.229242, 1.395597],
          [113.228485, 1.395402],
          [113.227606, 1.395329],
          [113.227069, 1.395231],
          [113.226532, 1.394865],
          [113.225849, 1.393693],
          [113.225483, 1.393181],
          [113.224946, 1.392668],
          [113.224433, 1.392424],
          [113.223847, 1.3924],
          [113.22331, 1.392253],
          [113.222847, 1.391741],
          [113.222041, 1.389788],
          [113.220796, 1.38703],
          [113.220234, 1.386005],
          [113.21938, 1.384882],
          [113.218794, 1.384418],
          [113.218135, 1.384247],
          [113.217501, 1.384223],
          [113.216988, 1.384272],
          [113.216207, 1.38454],
          [113.214401, 1.385273],
          [113.213693, 1.38537],
          [113.21296, 1.385273],
          [113.209665, 1.384125],
          [113.206321, 1.38271],
          [113.204393, 1.382051],
          [113.203319, 1.381733],
          [113.202587, 1.381587],
          [113.200903, 1.38166],
          [113.200024, 1.38188],
          [113.199292, 1.382099],
          [113.198828, 1.38249],
          [113.197974, 1.382929],
          [113.197144, 1.383173],
          [113.196412, 1.38332],
          [113.195679, 1.383344],
          [113.194996, 1.383271],
          [113.193141, 1.382807],
          [113.191969, 1.382588],
          [113.191042, 1.382465],
          [113.19031, 1.382148],
          [113.18948, 1.381465],
          [113.188991, 1.380879],
          [113.188699, 1.38022],
          [113.188357, 1.379658],
          [113.187576, 1.379243],
          [113.186648, 1.37917],
          [113.186111, 1.379268],
          [113.185257, 1.379683],
          [113.184574, 1.379902],
          [113.183768, 1.379902],
          [113.182279, 1.379512],
          [113.181474, 1.379463],
          [113.180912, 1.379658],
          [113.180375, 1.379902],
          [113.179496, 1.380513],
          [113.178813, 1.381245],
          [113.17813, 1.381684],
          [113.177251, 1.382124],
          [113.174004, 1.384443],
          [113.1711, 1.3862],
          [113.169684, 1.386859],
          [113.166731, 1.387933],
          [113.15765, 1.392034],
          [113.156967, 1.392156],
          [113.15521, 1.391814],
          [113.152305, 1.39052],
          [113.151182, 1.390227],
          [113.150401, 1.390081],
          [113.148863, 1.390032],
          [113.147936, 1.390081],
          [113.1463, 1.390472],
          [113.145202, 1.390789],
          [113.144274, 1.391399],
          [113.143054, 1.392058],
          [113.141321, 1.393376],
          [113.139563, 1.395109],
          [113.138294, 1.3965],
          [113.137001, 1.398087],
          [113.135927, 1.399063],
          [113.135268, 1.399966],
          [113.134413, 1.401431],
          [113.133901, 1.403091],
          [113.133803, 1.403774],
          [113.133779, 1.404531],
          [113.133705, 1.405117],
          [113.133827, 1.40558],
          [113.133876, 1.40641],
          [113.134096, 1.407338],
          [113.134389, 1.408119],
          [113.134535, 1.409071],
          [113.134462, 1.410682],
          [113.134047, 1.41222],
          [113.133754, 1.41283],
          [113.133339, 1.413367],
          [113.13268, 1.413806],
          [113.131704, 1.414099],
          [113.130727, 1.414246],
          [113.129653, 1.414343],
          [113.128043, 1.414416],
          [113.127481, 1.414465],
          [113.127139, 1.414539],
          [113.126822, 1.414832],
          [113.12631, 1.416198],
          [113.125944, 1.41693],
          [113.12526, 1.418053],
          [113.12443, 1.419005],
          [113.123966, 1.419396],
          [113.123576, 1.419908],
          [113.123429, 1.420177],
          [113.123356, 1.420518],
          [113.123405, 1.42086],
          [113.123844, 1.421544],
          [113.124015, 1.421959],
          [113.124211, 1.422593],
          [113.124381, 1.423716],
          [113.12465, 1.426279],
          [113.124625, 1.427109],
          [113.124503, 1.42767],
          [113.124186, 1.42811],
          [113.123259, 1.428891],
          [113.122868, 1.429599],
          [113.122258, 1.430551],
          [113.122038, 1.431088],
          [113.120891, 1.432235],
          [113.120232, 1.432577],
          [113.119548, 1.432796],
          [113.118377, 1.432992],
          [113.115326, 1.435018],
          [113.114691, 1.435164],
          [113.113568, 1.435042],
          [113.112299, 1.435042],
          [113.111933, 1.435237],
          [113.111322, 1.435872],
          [113.111054, 1.43636],
          [113.110248, 1.438679],
          [113.109809, 1.439582],
          [113.108808, 1.441095],
          [113.107637, 1.442584],
          [113.106685, 1.444293],
          [113.106514, 1.44483],
          [113.106343, 1.445],
          [113.106001, 1.444952],
          [113.104927, 1.44439],
          [113.104195, 1.444097],
          [113.102951, 1.443731],
          [113.100925, 1.443512],
          [113.099777, 1.443268],
          [113.098801, 1.443121],
          [113.098118, 1.442926],
          [113.096873, 1.441998],
          [113.096385, 1.441803],
          [113.095701, 1.441657],
          [113.094334, 1.441559],
          [113.093968, 1.441388],
          [113.093602, 1.440851],
          [113.093578, 1.440363],
          [113.0937, 1.439679],
          [113.093675, 1.439045],
          [113.093529, 1.43863],
          [113.092869, 1.437702],
          [113.09243, 1.437214],
          [113.092161, 1.436677],
          [113.092113, 1.436091],
          [113.09226, 1.435652],
          [113.092504, 1.435164],
          [113.092601, 1.434749],
          [113.092552, 1.434334],
          [113.09221, 1.434017],
          [113.0916, 1.433748],
          [113.090355, 1.432943],
          [113.08994, 1.432308],
          [113.089721, 1.430502],
          [113.089672, 1.429379],
          [113.089379, 1.428622],
          [113.088183, 1.428012],
          [113.08667, 1.427524],
          [113.08523, 1.427548],
          [113.084497, 1.42767],
          [113.083863, 1.42811],
          [113.083057, 1.428818],
          [113.082252, 1.429013],
          [113.080592, 1.429208],
          [113.079689, 1.429428],
          [113.076662, 1.430502],
          [113.07593, 1.430673],
          [113.075271, 1.430771],
          [113.074758, 1.430673],
          [113.074392, 1.430453],
          [113.072464, 1.428452],
          [113.07161, 1.427841],
          [113.070023, 1.426914],
          [113.068534, 1.426109],
          [113.067119, 1.424937],
          [113.065825, 1.423643],
          [113.065019, 1.422911],
          [113.064213, 1.422422],
          [113.062798, 1.42191],
          [113.061895, 1.421666],
          [113.06021, 1.421544],
          [113.059454, 1.421422],
          [113.058795, 1.421153],
          [113.058258, 1.420519],
          [113.057672, 1.420079],
          [113.057062, 1.419982],
          [113.056403, 1.420104],
          [113.055304, 1.420226],
          [113.053791, 1.420519],
          [113.052961, 1.420909],
          [113.052034, 1.421153],
          [113.050521, 1.4213],
          [113.049984, 1.421422],
          [113.047299, 1.422374],
          [113.045297, 1.422886],
          [113.044492, 1.422911],
          [113.043857, 1.422789],
          [113.04227, 1.421349],
          [113.041221, 1.420201],
          [113.040635, 1.419347],
          [113.040317, 1.418468],
          [113.039585, 1.416955],
          [113.038706, 1.415466],
          [113.038194, 1.414636],
          [113.037315, 1.413806],
          [113.035851, 1.413049],
          [113.030286, 1.411365],
          [113.028992, 1.410828],
          [113.027503, 1.409974],
          [113.026991, 1.409583],
          [113.026551, 1.409071],
          [113.025673, 1.407826],
          [113.024867, 1.40685],
          [113.02433, 1.406386],
          [113.023793, 1.406093],
          [113.023158, 1.405898],
          [113.022304, 1.4058],
          [113.021425, 1.405507],
          [113.019521, 1.404433],
          [113.018862, 1.404214],
          [113.016763, 1.404214],
          [113.015152, 1.403774],
          [113.014566, 1.403799],
          [113.013956, 1.403896],
          [113.012931, 1.404287],
          [113.011149, 1.405654],
          [113.010587, 1.405776],
          [113.009953, 1.405727],
          [113.009342, 1.405801],
          [113.008854, 1.406069],
          [113.008196, 1.406704],
          [113.007707, 1.406997],
          [113.006731, 1.407265],
          [113.004558, 1.407558],
          [113.003777, 1.407534],
          [113.003265, 1.407363],
          [113.002899, 1.407143],
          [113.00224, 1.407021],
          [113.001581, 1.406826],
          [113.000897, 1.406752],
          [112.999848, 1.406826],
          [112.998969, 1.406826],
          [112.998383, 1.406777],
          [112.99748, 1.406923],
          [112.994966, 1.407216],
          [112.994429, 1.407119],
          [112.994185, 1.406923],
          [112.993843, 1.406752],
          [112.993501, 1.406752],
          [112.993062, 1.406899],
          [112.99233, 1.40746],
          [112.991915, 1.407656],
          [112.99128, 1.407778],
          [112.990719, 1.407753],
          [112.990011, 1.407534],
          [112.988766, 1.407265],
          [112.987106, 1.406801],
          [112.986106, 1.406338],
          [112.98491, 1.406118],
          [112.984421, 1.406142],
          [112.983518, 1.406362],
          [112.98264, 1.406484],
          [112.981859, 1.406435],
          [112.981053, 1.40646],
          [112.980516, 1.406484],
          [112.980004, 1.406582],
          [112.978832, 1.407314],
          [112.977929, 1.407534],
          [112.976367, 1.407997],
          [112.975268, 1.408388],
          [112.974682, 1.408681],
          [112.974487, 1.4089],
          [112.974365, 1.409193],
          [112.974292, 1.409584],
          [112.974316, 1.409999],
          [112.974878, 1.412122],
          [112.974951, 1.412537],
          [112.975342, 1.413489],
          [112.975756, 1.414075],
          [112.976098, 1.41488],
          [112.976123, 1.415735],
          [112.975903, 1.41715],
          [112.97583, 1.418078],
          [112.975927, 1.41925],
          [112.976391, 1.421398],
          [112.977001, 1.422203],
          [112.977123, 1.422642],
          [112.977148, 1.423033],
          [112.977123, 1.423423],
          [112.976782, 1.424375],
          [112.976415, 1.425034],
          [112.976049, 1.425474],
          [112.975854, 1.42584],
          [112.975756, 1.426499],
          [112.976123, 1.427573],
          [112.976098, 1.427939],
          [112.975879, 1.428305],
          [112.975634, 1.428574],
          [112.975439, 1.429062],
          [112.974829, 1.432504],
          [112.974536, 1.434408],
          [112.974096, 1.436043],
          [112.974072, 1.436385],
          [112.974219, 1.436555],
          [112.975512, 1.437312],
          [112.977294, 1.438191],
          [112.98015, 1.43946],
          [112.980369, 1.439729],
          [112.980491, 1.44007],
          [112.981028, 1.442877],
          [112.981199, 1.443292],
          [112.982346, 1.44439],
          [112.982566, 1.444756],
          [112.98259, 1.445123],
          [112.982517, 1.44544],
          [112.982078, 1.446831],
          [112.981931, 1.44915],
          [112.981956, 1.44937],
          [112.982078, 1.449565],
          [112.982908, 1.449955],
          [112.987057, 1.452201],
          [112.987668, 1.452396],
          [112.989718, 1.452884],
          [112.994405, 1.453421],
          [112.995039, 1.453641],
          [112.996528, 1.454715],
          [112.99748, 1.455106],
          [113.000385, 1.456131],
          [113.000873, 1.456253],
          [113.001288, 1.456253],
          [113.002069, 1.456033],
          [113.002753, 1.455984],
          [113.00329, 1.456033],
          [113.004485, 1.456912],
          [113.005047, 1.457205],
          [113.006292, 1.457449],
          [113.007317, 1.457815],
          [113.007781, 1.458035],
          [113.008708, 1.459035],
          [113.010295, 1.460915],
          [113.010807, 1.461403],
          [113.011589, 1.462038],
          [113.015689, 1.463429],
          [113.016641, 1.463942],
          [113.018398, 1.464357],
          [113.018838, 1.464649],
          [113.01979, 1.465406],
          [113.020303, 1.465651],
          [113.021523, 1.465895],
          [113.022426, 1.465992],
          [113.024208, 1.465992],
          [113.02472, 1.466163],
          [113.024965, 1.466554],
          [113.025428, 1.468385],
          [113.025819, 1.469092],
          [113.026307, 1.469824],
          [113.02682, 1.470362],
          [113.027332, 1.470679],
          [113.027893, 1.470947],
          [113.029236, 1.471142],
          [113.029944, 1.471069],
          [113.031018, 1.47085],
          [113.032116, 1.470728],
          [113.033629, 1.470654],
          [113.034947, 1.470288],
          [113.035411, 1.470337],
          [113.036437, 1.47063],
          [113.037389, 1.471265],
          [113.038194, 1.472046],
          [113.040317, 1.473583],
          [113.04166, 1.474852],
          [113.041709, 1.475316],
          [113.041635, 1.475829],
          [113.040952, 1.477928],
          [113.04061, 1.478684],
          [113.040049, 1.479685],
          [113.039561, 1.480686],
          [113.039219, 1.481565],
          [113.038584, 1.483957],
          [113.038365, 1.484518],
          [113.038096, 1.484958],
          [113.037388, 1.485592],
          [113.036876, 1.486154],
          [113.036046, 1.486788],
          [113.03524, 1.487301],
          [113.03463, 1.487862],
          [113.034142, 1.48857],
          [113.033678, 1.489351],
          [113.033092, 1.490108],
          [113.032531, 1.490694],
          [113.03197, 1.491182],
          [113.03175, 1.491523],
          [113.031555, 1.492134],
          [113.031604, 1.492476],
          [113.031726, 1.492817],
          [113.03197, 1.49311],
          [113.032482, 1.493452],
          [113.033141, 1.493745],
          [113.034069, 1.494502],
          [113.034655, 1.494795],
          [113.035338, 1.49499],
          [113.037706, 1.495332],
          [113.038462, 1.495575],
          [113.039146, 1.496137],
          [113.039487, 1.496479],
          [113.039707, 1.496845],
          [113.039951, 1.497431],
          [113.040244, 1.497821],
          [113.040757, 1.498041],
          [113.041367, 1.498187],
          [113.043076, 1.49809],
          [113.043661, 1.498016],
          [113.045078, 1.498285],
          [113.047128, 1.498578],
          [113.048397, 1.499066],
          [113.049691, 1.499652],
          [113.050008, 1.500091],
          [113.050057, 1.50036],
          [113.049886, 1.501092],
          [113.049495, 1.501507],
          [113.048885, 1.501922],
          [113.04747, 1.50263],
          [113.047079, 1.503045],
          [113.046884, 1.503679],
          [113.046835, 1.504558],
          [113.046884, 1.505974],
          [113.047177, 1.508659],
          [113.04725, 1.509562],
          [113.04725, 1.510196],
          [113.047177, 1.510562],
          [113.04703, 1.510928],
          [113.045907, 1.512661],
          [113.045639, 1.51315],
          [113.045516, 1.513735],
          [113.045492, 1.514199],
          [113.045639, 1.514785],
          [113.045883, 1.515151],
          [113.048201, 1.517177],
          [113.049495, 1.518202],
          [113.051155, 1.519154],
          [113.051619, 1.519569],
          [113.052986, 1.521131],
          [113.054524, 1.52306],
          [113.055329, 1.523865],
          [113.056818, 1.525135],
          [113.057892, 1.525891],
          [113.058453, 1.526111],
          [113.059796, 1.526502],
          [113.059942, 1.52677],
          [113.060064, 1.52721],
          [113.060138, 1.527795],
          [113.060333, 1.528235],
          [113.060821, 1.528894],
          [113.06126, 1.529236],
          [113.062822, 1.529894],
          [113.064262, 1.530187],
          [113.064848, 1.53026],
          [113.065727, 1.530187],
          [113.066264, 1.53004],
          [113.066923, 1.529528],
          [113.067729, 1.529137],
          [113.068217, 1.529015],
          [113.069608, 1.528942],
          [113.070121, 1.528966],
          [113.070536, 1.529064],
          [113.070707, 1.529235],
          [113.07078, 1.529479],
          [113.070707, 1.52987],
          [113.070462, 1.530431],
          [113.069901, 1.53131],
          [113.069632, 1.531847],
          [113.069095, 1.533824],
          [113.068729, 1.536607],
          [113.068583, 1.539511],
          [113.068485, 1.539902],
          [113.068265, 1.54039],
          [113.067509, 1.541025],
          [113.066825, 1.541391],
          [113.064799, 1.542221],
          [113.064189, 1.542611],
          [113.063847, 1.542928],
          [113.063457, 1.543441],
          [113.06314, 1.543954],
          [113.062456, 1.545931],
          [113.062407, 1.546346],
          [113.062505, 1.547981],
          [113.062432, 1.548909],
          [113.062432, 1.549885],
          [113.062822, 1.55052],
          [113.063994, 1.551789],
          [113.06436, 1.552326],
          [113.064409, 1.552838],
          [113.064311, 1.55318],
          [113.064067, 1.553546],
          [113.063432, 1.554132],
          [113.062188, 1.555401],
          [113.061162, 1.556329],
          [113.058502, 1.558477],
          [113.058087, 1.558696],
          [113.057672, 1.55877],
          [113.057135, 1.558721],
          [113.056549, 1.558526],
          [113.0555, 1.558037],
          [113.054304, 1.556817],
          [113.053742, 1.556841],
          [113.052449, 1.557183],
          [113.051741, 1.557598],
          [113.049544, 1.559258],
          [113.049129, 1.559722],
          [113.048519, 1.56021],
          [113.048128, 1.560454],
          [113.047176, 1.56082],
          [113.043686, 1.561943],
          [113.042881, 1.56209],
          [113.041709, 1.562114],
          [113.040757, 1.562236],
          [113.039097, 1.562578],
          [113.038341, 1.562846],
          [113.037584, 1.563164],
          [113.036778, 1.563725],
          [113.035802, 1.564653],
          [113.035021, 1.565483],
          [113.034435, 1.565946],
          [113.033898, 1.566166],
          [113.033361, 1.56619],
          [113.032751, 1.566068],
          [113.031677, 1.565751],
          [113.03053, 1.565312],
          [113.030237, 1.565336],
          [113.029944, 1.565531],
          [113.029187, 1.566581],
          [113.027772, 1.567826],
          [113.027039, 1.56807],
          [113.02638, 1.568216],
          [113.025843, 1.568241],
          [113.025038, 1.568143],
          [113.024232, 1.567997],
          [113.022377, 1.567508],
          [113.021962, 1.56746],
          [113.021352, 1.567533],
          [113.02079, 1.567752],
          [113.020253, 1.56807],
          [113.019692, 1.568607],
          [113.018935, 1.569022],
          [113.018423, 1.569241],
          [113.018032, 1.569266],
          [113.017398, 1.569168],
          [113.014664, 1.569217],
          [113.013931, 1.569266],
          [113.011954, 1.56973],
          [113.011076, 1.56973],
          [113.010124, 1.569656],
          [113.008391, 1.569315],
          [113.006267, 1.568729],
          [113.005876, 1.568753],
          [113.005437, 1.568973],
          [113.004998, 1.569363],
          [113.00468, 1.569827],
          [113.003997, 1.570999],
          [113.003192, 1.571951],
          [113.002923, 1.57239],
          [113.002679, 1.572927],
          [113.00224, 1.574514],
          [113.002142, 1.575588],
          [113.002264, 1.576051],
          [113.00285, 1.577443],
          [113.003802, 1.578859],
          [113.0039, 1.579274],
          [113.003924, 1.579786],
          [113.003827, 1.580226],
          [113.003631, 1.580518],
          [113.003192, 1.580958],
          [113.002996, 1.581031],
          [113.002508, 1.581128],
          [113.001898, 1.581153],
          [113.001263, 1.581104],
          [113.000653, 1.580909],
          [113.000238, 1.580665],
          [112.999506, 1.580103],
          [112.998847, 1.579786],
          [112.998286, 1.579566],
          [112.997919, 1.579517],
          [112.997138, 1.579493],
          [112.99294, 1.579542],
          [112.992379, 1.579469],
          [112.990572, 1.579859],
          [112.990158, 1.579835],
          [112.989889, 1.579737],
          [112.989645, 1.579273],
          [112.989352, 1.578541],
          [112.987302, 1.573122],
          [112.986643, 1.571779],
          [112.986008, 1.570242],
          [112.985862, 1.569607],
          [112.985764, 1.568118],
          [112.985569, 1.566995],
          [112.985178, 1.565921],
          [112.984666, 1.564847],
          [112.984324, 1.564603],
          [112.983934, 1.564432],
          [112.983299, 1.564262],
          [112.981981, 1.56414],
          [112.981249, 1.564237],
          [112.978856, 1.564213],
          [112.977172, 1.563944],
          [112.976269, 1.563725],
          [112.975683, 1.563505],
          [112.975146, 1.563456],
          [112.974707, 1.563481],
          [112.97417, 1.563627],
          [112.973535, 1.563895],
          [112.972046, 1.565555],
          [112.971509, 1.566068],
          [112.970362, 1.5668],
          [112.969776, 1.567093],
          [112.969361, 1.567191],
          [112.966847, 1.568484],
          [112.966285, 1.56846],
          [112.965895, 1.568313],
          [112.964772, 1.568118],
          [112.964138, 1.568118],
          [112.963381, 1.568216],
          [112.962673, 1.568362],
          [112.961404, 1.56868],
          [112.961038, 1.56885],
          [112.959866, 1.569705],
          [112.958939, 1.570193],
          [112.958255, 1.570413],
          [112.957718, 1.57051],
          [112.957352, 1.570803],
          [112.957303, 1.571072],
          [112.957328, 1.571364],
          [112.957694, 1.572268],
          [112.957743, 1.572756],
          [112.957694, 1.573659],
          [112.957572, 1.57444],
          [112.957474, 1.574684],
          [112.957157, 1.574879],
          [112.956449, 1.575001],
          [112.955204, 1.575001],
          [112.954179, 1.57483],
          [112.953569, 1.574538],
          [112.953178, 1.574416],
          [112.952641, 1.574464],
          [112.952177, 1.574367],
          [112.951811, 1.574123],
          [112.951616, 1.573708],
          [112.951494, 1.573195],
          [112.95103, 1.572121],
          [112.95064, 1.571657],
          [112.950298, 1.570803],
          [112.950127, 1.569998],
          [112.950176, 1.569265],
          [112.949956, 1.568753],
          [112.949517, 1.56846],
          [112.949029, 1.568289],
          [112.94859, 1.568191],
          [112.947955, 1.568289],
          [112.946637, 1.568924],
          [112.946124, 1.56929],
          [112.944904, 1.570339],
          [112.944464, 1.570535],
          [112.94383, 1.570681],
          [112.943122, 1.570657],
          [112.94256, 1.570583],
          [112.942268, 1.570339],
          [112.942072, 1.569924],
          [112.941999, 1.569412],
          [112.942023, 1.569094],
          [112.942487, 1.568411],
          [112.94278, 1.568118],
          [112.942902, 1.567825],
          [112.942902, 1.567459],
          [112.942853, 1.567166],
          [112.942658, 1.566971],
          [112.94239, 1.566824],
          [112.941975, 1.566727],
          [112.941535, 1.566751],
          [112.941145, 1.566849],
          [112.940364, 1.567142],
          [112.939119, 1.567752],
          [112.938557, 1.567776],
          [112.937996, 1.567728],
          [112.937557, 1.567532],
          [112.937142, 1.567435],
          [112.935872, 1.567386],
          [112.934872, 1.567361],
          [112.933871, 1.56741],
          [112.93309, 1.567532],
          [112.932553, 1.567728],
          [112.932211, 1.568045],
          [112.931845, 1.568533],
          [112.931698, 1.568899],
          [112.931283, 1.569094],
          [112.930429, 1.569387],
          [112.929062, 1.569778],
          [112.927573, 1.57012],
          [112.926841, 1.570364],
          [112.926011, 1.570437],
          [112.925083, 1.570632],
          [112.924498, 1.570925],
          [112.924034, 1.571218],
          [112.92357, 1.571584],
          [112.92296, 1.572219],
          [112.922057, 1.572756],
          [112.921081, 1.573537],
          [112.920666, 1.574123],
          [112.920178, 1.575026],
          [112.919885, 1.575685],
          [112.919445, 1.577247],
          [112.919055, 1.578443],
          [112.918713, 1.579347],
          [112.918225, 1.580225],
          [112.917566, 1.580982],
          [112.916956, 1.581592],
          [112.916248, 1.582007],
          [112.915418, 1.582422],
          [112.913953, 1.582617],
          [112.912782, 1.582471],
          [112.912074, 1.582276],
          [112.911439, 1.582227],
          [112.910902, 1.582276],
          [112.910072, 1.582544],
          [112.90873, 1.583374],
          [112.907876, 1.583764],
          [112.907095, 1.583886],
          [112.906338, 1.583837],
          [112.905337, 1.583374],
          [112.904873, 1.583276],
          [112.904581, 1.583325],
          [112.904092, 1.583618],
          [112.903287, 1.584228],
          [112.901334, 1.586132],
          [112.900822, 1.586742],
          [112.900285, 1.588085],
          [112.899845, 1.588402],
          [112.897649, 1.588841],
          [112.895916, 1.589354],
          [112.895281, 1.589427],
          [112.894817, 1.589354],
          [112.893621, 1.588817],
          [112.89262, 1.587743],
          [112.892156, 1.58706],
          [112.891937, 1.586645],
          [112.891717, 1.586401],
          [112.891302, 1.586254],
          [112.890765, 1.586157],
          [112.890204, 1.586108],
          [112.889398, 1.58623],
          [112.888666, 1.586425],
          [112.887787, 1.586742],
          [112.886665, 1.587426],
          [112.885469, 1.587645],
          [112.884834, 1.587597],
          [112.884224, 1.587353],
          [112.881099, 1.585864],
          [112.879952, 1.585034],
          [112.87944, 1.584521],
          [112.87839, 1.583032],
          [112.877706, 1.581861],
          [112.877438, 1.581177],
          [112.877023, 1.579176],
          [112.876803, 1.577076],
          [112.876486, 1.57649],
          [112.876193, 1.575075],
          [112.876242, 1.574513],
          [112.876095, 1.573805],
          [112.875436, 1.572512],
          [112.875046, 1.571975],
          [112.873533, 1.570657],
          [112.871824, 1.569363],
          [112.869407, 1.567703],
          [112.868993, 1.567532],
          [112.868114, 1.567386],
          [112.867674, 1.567117],
          [112.867308, 1.566654],
          [112.867089, 1.565995],
          [112.866991, 1.565409],
          [112.866942, 1.564628],
          [112.866991, 1.56309],
          [112.867113, 1.562187],
          [112.867137, 1.561674],
          [112.866844, 1.560893],
          [112.8666, 1.560527],
          [112.866136, 1.560185],
          [112.86577, 1.55999],
          [112.864623, 1.559746],
          [112.863525, 1.559404],
          [112.862646, 1.558574],
          [112.861962, 1.558037],
          [112.861401, 1.558062],
          [112.860107, 1.558404],
          [112.859326, 1.558404],
          [112.857715, 1.557623],
          [112.855372, 1.557281],
          [112.854811, 1.557061],
          [112.854176, 1.556671],
          [112.851076, 1.55379],
          [112.850832, 1.553058],
          [112.850832, 1.552521],
          [112.850979, 1.551935],
          [112.851247, 1.551178],
          [112.851931, 1.550104],
          [112.852297, 1.549689],
          [112.852468, 1.549397],
          [112.852565, 1.549177],
          [112.852516, 1.548982],
          [112.852345, 1.548835],
          [112.85215, 1.548786],
          [112.850808, 1.548835],
          [112.849661, 1.54864],
          [112.848831, 1.548274],
          [112.848245, 1.547737],
          [112.84805, 1.547371],
          [112.847977, 1.547029],
          [112.847977, 1.545442],
          [112.847806, 1.542416],
          [112.84783, 1.542074],
          [112.848807, 1.540487],
          [112.849295, 1.539902],
          [112.849637, 1.539316],
          [112.849539, 1.538657],
          [112.849392, 1.538218],
          [112.8491, 1.537851],
          [112.848807, 1.537656],
          [112.84844, 1.537632],
          [112.848099, 1.537729],
          [112.847635, 1.537949],
          [112.846952, 1.538364],
          [112.846537, 1.538535],
          [112.845633, 1.538559],
          [112.844291, 1.537876],
          [112.84329, 1.537144],
          [112.842289, 1.536973],
          [112.84163, 1.537021],
          [112.840874, 1.537144],
          [112.840288, 1.53729],
          [112.839824, 1.537558],
          [112.83936, 1.538144],
          [112.838848, 1.538657],
          [112.838481, 1.53956],
          [112.838067, 1.540854],
          [112.837798, 1.541391],
          [112.837456, 1.541806],
          [112.837115, 1.542123],
          [112.835601, 1.54288],
          [112.834088, 1.543417],
          [112.832794, 1.544173],
          [112.832355, 1.544661],
          [112.831842, 1.545052],
          [112.831452, 1.545198],
          [112.831159, 1.545247],
          [112.830866, 1.545101],
          [112.830524, 1.544808],
          [112.829841, 1.544393],
          [112.829109, 1.543807],
          [112.828425, 1.543221],
          [112.827156, 1.541952],
          [112.826521, 1.54078],
          [112.826448, 1.540292],
          [112.826399, 1.539047],
          [112.826277, 1.538803],
          [112.826009, 1.538608],
          [112.825643, 1.538535],
          [112.825179, 1.538535],
          [112.824422, 1.538706],
          [112.823592, 1.538974],
          [112.82286, 1.539316],
          [112.822079, 1.539438],
          [112.818613, 1.539169],
          [112.8181, 1.539072],
          [112.817759, 1.53895],
          [112.816879, 1.538486],
          [112.81539, 1.538242],
          [112.814317, 1.538315],
          [112.814048, 1.53851],
          [112.81395, 1.538779],
          [112.813438, 1.53978],
          [112.813072, 1.540219],
          [112.812632, 1.54061],
          [112.812047, 1.540829],
          [112.81151, 1.540927],
          [112.809996, 1.541098],
          [112.809435, 1.54122],
          [112.808751, 1.541269],
          [112.808117, 1.541244],
          [112.807507, 1.541147],
          [112.806848, 1.541147],
          [112.805334, 1.541366],
          [112.804138, 1.541854],
          [112.803308, 1.541952],
          [112.802918, 1.541903],
          [112.80243, 1.541757],
          [112.801356, 1.541269],
          [112.801087, 1.541171],
          [112.800867, 1.541171],
          [112.800135, 1.541464],
          [112.799525, 1.541806],
          [112.799574, 1.541976],
          [112.799745, 1.542147],
          [112.799916, 1.542611],
          [112.799964, 1.543197],
          [112.800135, 1.544173],
          [112.800208, 1.545027],
          [112.800086, 1.545979],
          [112.800038, 1.547175],
          [112.799745, 1.548811],
          [112.799427, 1.549666],
          [112.798988, 1.550422],
          [112.798255, 1.551545],
          [112.796693, 1.553327],
          [112.795766, 1.553962],
          [112.794912, 1.554425],
          [112.794253, 1.554572],
          [112.792886, 1.554645],
          [112.791787, 1.554938],
          [112.791226, 1.555182],
          [112.79064, 1.555548],
          [112.790177, 1.555987],
          [112.789396, 1.556622],
          [112.789054, 1.556622],
          [112.788737, 1.5565],
          [112.78842, 1.556305],
          [112.787883, 1.555817],
          [112.787614, 1.555695],
          [112.786638, 1.555841],
          [112.785149, 1.555914],
          [112.784416, 1.555841],
          [112.782561, 1.556061],
          [112.781805, 1.556427],
          [112.781072, 1.55711],
          [112.780291, 1.557964],
          [112.779876, 1.55855],
          [112.779461, 1.559502],
          [112.779168, 1.559942],
          [112.778802, 1.560308],
          [112.777533, 1.561064],
          [112.776508, 1.561406],
          [112.775873, 1.561504],
          [112.775361, 1.561528],
          [112.774628, 1.561357],
          [112.772603, 1.560357],
          [112.771382, 1.560088],
          [112.770869, 1.560064],
          [112.77043, 1.560088],
          [112.769991, 1.560234],
          [112.769576, 1.560649],
          [112.769088, 1.561357],
          [112.768599, 1.561626],
          [112.768404, 1.561626],
          [112.76816, 1.561553],
          [112.76755, 1.561016],
          [112.767232, 1.560527],
          [112.76672, 1.560064],
          [112.766134, 1.559697],
          [112.765377, 1.55938],
          [112.762497, 1.55794],
          [112.760862, 1.557403],
          [112.760032, 1.557183],
          [112.755516, 1.556451],
          [112.754857, 1.55628],
          [112.754247, 1.556061],
          [112.753331, 1.555499],
          [112.750037, 1.55506],
          [112.749353, 1.55506],
          [112.748035, 1.555499],
          [112.747669, 1.555865],
          [112.747156, 1.556817],
          [112.746351, 1.558526],
          [112.745863, 1.559405],
          [112.745594, 1.5596],
          [112.744935, 1.559893],
          [112.744056, 1.560161],
          [112.742323, 1.560381],
          [112.741957, 1.560552],
          [112.74164, 1.560918],
          [112.740883, 1.56209],
          [112.740493, 1.562505],
          [112.739956, 1.562846],
          [112.739248, 1.563066],
          [112.738272, 1.563457],
          [112.737369, 1.564091],
          [112.736734, 1.564433],
          [112.735684, 1.564653],
          [112.735098, 1.564628],
          [112.731779, 1.563798],
          [112.731071, 1.563847],
          [112.729411, 1.564555],
          [112.728728, 1.564628],
          [112.728117, 1.564579],
          [112.72758, 1.564384],
          [112.726848, 1.563994],
          [112.725384, 1.561968],
          [112.725042, 1.561748],
          [112.724456, 1.561504],
          [112.722162, 1.56104],
          [112.721527, 1.560771],
          [112.720526, 1.560601],
          [112.719696, 1.560381],
          [112.718769, 1.560088],
          [112.718061, 1.559722],
          [112.716938, 1.559624],
          [112.715303, 1.55938],
          [112.713179, 1.559502],
          [112.711885, 1.559405],
          [112.70964, 1.559161],
          [112.709103, 1.559087],
          [112.708688, 1.558941],
          [112.708273, 1.558868],
          [112.707541, 1.558819],
          [112.706857, 1.558575],
          [112.70632, 1.55816],
          [112.704929, 1.556525],
          [112.704539, 1.556305],
          [112.704075, 1.556183],
          [112.703122, 1.556183],
          [112.70261, 1.556012],
          [112.702097, 1.555719],
          [112.700755, 1.554328],
          [112.700242, 1.554133],
          [112.698826, 1.553962],
          [112.698167, 1.55384],
          [112.697313, 1.553547],
          [112.695629, 1.552863],
          [112.693725, 1.552326],
          [112.691797, 1.552131],
          [112.690918, 1.552204],
          [112.690161, 1.552326],
          [112.689429, 1.552546],
          [112.688453, 1.552985],
          [112.686964, 1.553498],
          [112.68672, 1.553522],
          [112.686256, 1.553474],
          [112.685573, 1.553278],
          [112.685207, 1.553278],
          [112.684889, 1.553474],
          [112.68423, 1.553986],
          [112.683303, 1.554523],
          [112.682619, 1.554694],
          [112.681619, 1.554816],
          [112.680886, 1.55484],
          [112.680496, 1.554792],
          [112.680227, 1.554572],
          [112.679666, 1.553962],
          [112.678812, 1.553205],
          [112.678177, 1.552424],
          [112.677762, 1.552302],
          [112.677372, 1.552326],
          [112.67703, 1.552424],
          [112.676591, 1.552839],
          [112.67454, 1.55423],
          [112.673466, 1.555304],
          [112.672783, 1.555914],
          [112.670025, 1.557428],
          [112.667047, 1.558941],
          [112.666071, 1.559698],
          [112.665436, 1.560332],
          [112.665216, 1.560894],
          [112.664435, 1.563456],
          [112.664044, 1.564603],
          [112.663409, 1.565897],
          [112.662946, 1.566703],
          [112.662043, 1.56763],
          [112.661506, 1.568094],
          [112.660944, 1.568387],
          [112.66031, 1.568558],
          [112.659455, 1.56868],
          [112.658406, 1.56868],
          [112.656844, 1.568606],
          [112.65472, 1.568387],
          [112.652865, 1.567996],
          [112.651986, 1.567752],
          [112.651522, 1.567728],
          [112.651229, 1.567777],
          [112.650351, 1.568118],
          [112.649448, 1.568533],
          [112.649057, 1.568655],
          [112.648569, 1.568655],
          [112.648178, 1.568484],
          [112.647446, 1.56763],
          [112.646738, 1.566947],
          [112.646079, 1.566092],
          [112.645689, 1.565848],
          [112.642979, 1.564969],
          [112.641954, 1.564457],
          [112.641564, 1.564188],
          [112.641173, 1.564066],
          [112.640124, 1.563993],
          [112.638659, 1.564213],
          [112.637512, 1.564286],
          [112.636365, 1.563944],
          [112.635413, 1.563749],
          [112.634973, 1.563725],
          [112.633558, 1.563798],
          [112.631019, 1.563676],
          [112.63014, 1.5637],
          [112.625698, 1.564603],
          [112.623989, 1.565409],
          [112.623037, 1.565946],
          [112.621597, 1.566361],
          [112.62106, 1.566361],
          [112.620304, 1.566166],
          [112.619523, 1.566043],
          [112.619059, 1.566068],
          [112.618302, 1.566434],
          [112.616301, 1.56724],
          [112.615715, 1.567435],
          [112.614885, 1.567557],
          [112.612468, 1.568533],
          [112.609271, 1.569412],
          [112.608612, 1.56951],
          [112.607831, 1.569436],
          [112.606903, 1.569266],
          [112.606122, 1.569241],
          [112.605194, 1.569339],
          [112.604169, 1.569583],
          [112.601973, 1.570535],
          [112.601265, 1.570681],
          [112.600191, 1.57073],
          [112.599459, 1.570657],
          [112.598165, 1.570437],
          [112.596993, 1.570095],
          [112.596383, 1.569998],
          [112.595724, 1.570022],
          [112.594967, 1.570193],
          [112.59382, 1.570584],
          [112.592966, 1.570755],
          [112.592331, 1.570755],
          [112.590769, 1.570169],
          [112.590086, 1.570047],
          [112.589622, 1.570023],
          [112.588816, 1.570169],
          [112.585106, 1.571316],
          [112.584594, 1.571292],
          [112.582958, 1.570901],
          [112.581933, 1.570608],
          [112.580884, 1.570413],
          [112.580078, 1.570413],
          [112.579126, 1.570535],
          [112.577833, 1.570779],
          [112.576051, 1.571292],
          [112.574977, 1.571438],
          [112.574562, 1.571682],
          [112.574074, 1.571878],
          [112.573756, 1.571902],
          [112.57278, 1.571804],
          [112.570095, 1.572195],
          [112.569314, 1.572244],
          [112.568728, 1.572171],
          [112.568094, 1.572],
          [112.566946, 1.57156],
          [112.566238, 1.571341],
          [112.565726, 1.571292],
          [112.563334, 1.571267],
          [112.562138, 1.571145],
          [112.558916, 1.570926],
          [112.558745, 1.570974],
          [112.558452, 1.571804],
          [112.558061, 1.572561],
          [112.557744, 1.572878],
          [112.557427, 1.573049],
          [112.556597, 1.573147],
          [112.556255, 1.573318],
          [112.555864, 1.57344],
          [112.555401, 1.573415],
          [112.554815, 1.57322],
          [112.553668, 1.572927],
          [112.549299, 1.57283],
          [112.548396, 1.572585],
          [112.547541, 1.572488],
          [112.54576, 1.572512],
          [112.545076, 1.572415],
          [112.544344, 1.57239],
          [112.543807, 1.572439],
          [112.542416, 1.572976],
          [112.540316, 1.573611],
          [112.539047, 1.573489],
          [112.538534, 1.573537],
          [112.538071, 1.573659],
          [112.537582, 1.573708],
          [112.536338, 1.573733],
          [112.53458, 1.573904],
          [112.533091, 1.574367],
          [112.529674, 1.575929],
          [112.529161, 1.5761],
          [112.528136, 1.576198],
          [112.527526, 1.57632],
          [112.527013, 1.576637],
          [112.526037, 1.577467],
          [112.525329, 1.577614],
          [112.524816, 1.577662],
          [112.524084, 1.577589],
          [112.523449, 1.577467],
          [112.522937, 1.577223],
          [112.522278, 1.576442],
          [112.521448, 1.575685],
          [112.520789, 1.575368],
          [112.520081, 1.5751],
          [112.51908, 1.574733],
          [112.518397, 1.574538],
          [112.517103, 1.573952],
          [112.516737, 1.573928],
          [112.514882, 1.574294],
          [112.513247, 1.574733],
          [112.511514, 1.575295],
          [112.510781, 1.57549],
          [112.507584, 1.576198],
          [112.505119, 1.577662],
          [112.503398, 1.578736],
          [112.502764, 1.5792],
          [112.500396, 1.580127],
          [112.499737, 1.580005],
          [112.499102, 1.579737],
          [112.496491, 1.577394],
          [112.493683, 1.576295],
          [112.493171, 1.576149],
          [112.492561, 1.576149],
          [112.492048, 1.57632],
          [112.490706, 1.577345],
          [112.490047, 1.57776],
          [112.488777, 1.577882],
          [112.488069, 1.577809],
          [112.487239, 1.577638],
          [112.486678, 1.577467],
          [112.486361, 1.57693],
          [112.485628, 1.575417],
          [112.484774, 1.573879],
          [112.482675, 1.57073],
          [112.482138, 1.569803],
          [112.481699, 1.569388],
          [112.481284, 1.56929],
          [112.480991, 1.56929],
          [112.478501, 1.570144],
          [112.477647, 1.570364],
          [112.476914, 1.570364],
          [112.476377, 1.570144],
          [112.475792, 1.569559],
          [112.47545, 1.568607],
          [112.475084, 1.567045],
          [112.474766, 1.566483],
          [112.474474, 1.566287],
          [112.473205, 1.566019],
          [112.472668, 1.565946],
          [112.472155, 1.565994],
          [112.471252, 1.566214],
          [112.470764, 1.56619],
          [112.470349, 1.566043],
          [112.469787, 1.565628],
          [112.468762, 1.564115],
          [112.467225, 1.56248],
          [112.466541, 1.56165],
          [112.465077, 1.559014],
          [112.464808, 1.558281],
          [112.464271, 1.557891],
          [112.463588, 1.557671],
          [112.46266, 1.557549],
          [112.460268, 1.557501],
          [112.459682, 1.557305],
          [112.458681, 1.55711],
          [112.457558, 1.556964],
          [112.456875, 1.556817],
          [112.456045, 1.556378],
          [112.455337, 1.556061],
          [112.454532, 1.555548],
          [112.450748, 1.552619],
          [112.448649, 1.551203],
          [112.447746, 1.550642],
          [112.446843, 1.550251],
          [112.446501, 1.549959],
          [112.446062, 1.549104],
          [112.445183, 1.54759],
          [112.443401, 1.545784],
          [112.443182, 1.545345],
          [112.442938, 1.544686],
          [112.442669, 1.543758],
          [112.44223, 1.541635],
          [112.442254, 1.540561],
          [112.442425, 1.539706],
          [112.442572, 1.539267],
          [112.443157, 1.538267],
          [112.44367, 1.537534],
          [112.444231, 1.537095],
          [112.444353, 1.53668],
          [112.444329, 1.53607],
          [112.444109, 1.535191],
          [112.443865, 1.53463],
          [112.442498, 1.533287],
          [112.437958, 1.528576],
          [112.435883, 1.526331],
          [112.433687, 1.5245],
          [112.432857, 1.524012],
          [112.431246, 1.523255],
          [112.430465, 1.52284],
          [112.42983, 1.522718],
          [112.428781, 1.522621],
          [112.427853, 1.52245],
          [112.426901, 1.522425],
          [112.424948, 1.522694],
          [112.423484, 1.523011],
          [112.421385, 1.523695],
          [112.42036, 1.524232],
          [112.419896, 1.524525],
          [112.419286, 1.524549],
          [112.418602, 1.524427],
          [112.417162, 1.523963],
          [112.412768, 1.522084],
          [112.410327, 1.521132],
          [112.406813, 1.519252],
          [112.405348, 1.5183],
          [112.404421, 1.517617],
          [112.403737, 1.517299],
          [112.403054, 1.517177],
          [112.402761, 1.51747],
          [112.402053, 1.519008],
          [112.401394, 1.519838],
          [112.398904, 1.521864],
          [112.398099, 1.52245],
          [112.39722, 1.522889],
          [112.396244, 1.523231],
          [112.395438, 1.523353],
          [112.394852, 1.523255],
          [112.394389, 1.523011],
          [112.393778, 1.522547],
          [112.392802, 1.521205],
          [112.392582, 1.520643],
          [112.392167, 1.518642],
          [112.391533, 1.517275],
          [112.391045, 1.516519],
          [112.390581, 1.515884],
          [112.390215, 1.515542],
          [112.388262, 1.514664],
          [112.387872, 1.514371],
          [112.387505, 1.513834],
          [112.387261, 1.51315],
          [112.386968, 1.5121],
          [112.38648, 1.510782],
          [112.385968, 1.510416],
          [112.385089, 1.510172],
          [112.384601, 1.510148],
          [112.383575, 1.510416],
          [112.381818, 1.510953],
          [112.378572, 1.512198],
          [112.377864, 1.512296],
          [112.376131, 1.51232],
          [112.374593, 1.512442],
          [112.374007, 1.512345],
          [112.373421, 1.5121],
          [112.372128, 1.511393],
          [112.370346, 1.510685],
          [112.369443, 1.510392],
          [112.368784, 1.510123],
          [112.36832, 1.509757],
          [112.36688, 1.507926],
          [112.363389, 1.504876],
          [112.362096, 1.504143],
          [112.360997, 1.503631],
          [112.359997, 1.503362],
          [112.357824, 1.503338],
          [112.354749, 1.503216],
          [112.354261, 1.503021],
          [112.353821, 1.502654],
          [112.352772, 1.501483],
          [112.352015, 1.500531],
          [112.3516, 1.500336],
          [112.351063, 1.500409],
          [112.350477, 1.500775],
          [112.349232, 1.501434],
          [112.34745, 1.502508],
          [112.346328, 1.503533],
          [112.345302, 1.504314],
          [112.345034, 1.504412],
          [112.344668, 1.504339],
          [112.34418, 1.504143],
          [112.342373, 1.503216],
          [112.340152, 1.502215],
          [112.339469, 1.502191],
          [112.338663, 1.502239],
          [112.337492, 1.502801],
          [112.3361, 1.503362],
          [112.334782, 1.503655],
          [112.332366, 1.504094],
          [112.331536, 1.504412],
          [112.329046, 1.505144],
          [112.328339, 1.505193],
          [112.326264, 1.505168],
          [112.325556, 1.505046],
          [112.321943, 1.503558],
          [112.321528, 1.503509],
          [112.321138, 1.503631],
          [112.319917, 1.504534],
          [112.319454, 1.504802],
          [112.319087, 1.504851],
          [112.318599, 1.504802],
          [112.318282, 1.504631],
          [112.318038, 1.50429],
          [112.317965, 1.503948],
          [112.317867, 1.499286],
          [112.317794, 1.498114],
          [112.317379, 1.497309],
          [112.316915, 1.496821],
          [112.316085, 1.496113],
          [112.313815, 1.494599],
          [112.313156, 1.494062],
          [112.312033, 1.492793],
          [112.311448, 1.491987],
          [112.311033, 1.491523],
          [112.310349, 1.490986],
          [112.30969, 1.490254],
          [112.309251, 1.489986],
          [112.308836, 1.489888],
          [112.306517, 1.489742],
          [112.305858, 1.489449],
          [112.305126, 1.48879],
          [112.30476, 1.488326],
          [112.303612, 1.485958],
          [112.303002, 1.484884],
          [112.302343, 1.483957],
          [112.302026, 1.483786],
          [112.300756, 1.483664],
          [112.299219, 1.483786],
          [112.298877, 1.483738],
          [112.298267, 1.483518],
          [112.297315, 1.483298],
          [112.296729, 1.483103],
          [112.294508, 1.48159],
          [112.293678, 1.481077],
          [112.293019, 1.480491],
          [112.292628, 1.480052],
          [112.292336, 1.47949],
          [112.291823, 1.478905],
          [112.290236, 1.476635],
          [112.288747, 1.475219],
          [112.288186, 1.474609],
          [112.287869, 1.474169],
          [112.287356, 1.472436],
          [112.287185, 1.472095],
          [112.286819, 1.471875],
          [112.285184, 1.471338],
          [112.283377, 1.470605],
          [112.282694, 1.470459],
          [112.281962, 1.47041],
          [112.281425, 1.470239],
          [112.281083, 1.470019],
          [112.279203, 1.468384],
          [112.277812, 1.467237],
          [112.276982, 1.466627],
          [112.276421, 1.466334],
          [112.27586, 1.466187],
          [112.274737, 1.46609],
          [112.271515, 1.466797],
          [112.270343, 1.466919],
          [112.268659, 1.467017],
          [112.268146, 1.467115],
          [112.267439, 1.467066],
          [112.266926, 1.466919],
          [112.266291, 1.466553],
          [112.265095, 1.464918],
          [112.263997, 1.463258],
          [112.263558, 1.462941],
          [112.261312, 1.461696],
          [112.259091, 1.460061],
          [112.25726, 1.458962],
          [112.256479, 1.458889],
          [112.255625, 1.458596],
          [112.255063, 1.458572],
          [112.254673, 1.458621],
          [112.253721, 1.459377],
          [112.253233, 1.459622],
          [112.251121, 1.459548],
          [112.250096, 1.459402],
          [112.249364, 1.459109],
          [112.24768, 1.457717],
          [112.246947, 1.457327],
          [112.246532, 1.45718],
          [112.24497, 1.457327],
          [112.243969, 1.457644],
          [112.242652, 1.45823],
          [112.241968, 1.458596],
          [112.240992, 1.459036],
          [112.240528, 1.459084],
          [112.239991, 1.459036],
          [112.239405, 1.458865],
          [112.237087, 1.457449],
          [112.235622, 1.456839],
          [112.235207, 1.456863],
          [112.234621, 1.457132],
          [112.233352, 1.457962],
          [112.232156, 1.458816],
          [112.231521, 1.459084],
          [112.231131, 1.459158],
          [112.230887, 1.459133],
          [112.229544, 1.458499],
          [112.228836, 1.458254],
          [112.228373, 1.458254],
          [112.228055, 1.458328],
          [112.227177, 1.458694],
          [112.226591, 1.45884],
          [112.226103, 1.458791],
          [112.225736, 1.458645],
          [112.224858, 1.45784],
          [112.224052, 1.457376],
          [112.223564, 1.457229],
          [112.223003, 1.457132],
          [112.222636, 1.456985],
          [112.222344, 1.456668],
          [112.222051, 1.455594],
          [112.221562, 1.4543],
          [112.221172, 1.453495],
          [112.22061, 1.452933],
          [112.219805, 1.452421],
          [112.219122, 1.452177],
          [112.218633, 1.452152],
          [112.218121, 1.452226],
          [112.216949, 1.452616],
          [112.21629, 1.452787],
          [112.215826, 1.45286],
          [112.215192, 1.452665],
          [112.213825, 1.451933],
          [112.211213, 1.451664],
          [112.210578, 1.451518],
          [112.208406, 1.450492],
          [112.207967, 1.450102],
          [112.206673, 1.448052],
          [112.20638, 1.447319],
          [112.206404, 1.445562],
          [112.206136, 1.444464],
          [112.205745, 1.44378],
          [112.205208, 1.44295],
          [112.204549, 1.442218],
          [112.20223, 1.440778],
          [112.201816, 1.440363],
          [112.201523, 1.439826],
          [112.201034, 1.43863],
          [112.200034, 1.437092],
          [112.199912, 1.436604],
          [112.199912, 1.435848],
          [112.200351, 1.434481],
          [112.200449, 1.432772],
          [112.200302, 1.431551],
          [112.200278, 1.430941],
          [112.200424, 1.430502],
          [112.20079, 1.430014],
          [112.201547, 1.428598],
          [112.201596, 1.428085],
          [112.201547, 1.427573],
          [112.201352, 1.427011],
          [112.200937, 1.426206],
          [112.200693, 1.425474],
          [112.200595, 1.424815],
          [112.200668, 1.424253],
          [112.200815, 1.423692],
          [112.201449, 1.422569],
          [112.20162, 1.421983],
          [112.201645, 1.421568],
          [112.201474, 1.420812],
          [112.20123, 1.420275],
          [112.20079, 1.419738],
          [112.200253, 1.419225],
          [112.199619, 1.418835],
          [112.199155, 1.418322],
          [112.198349, 1.417248],
          [112.196226, 1.415076],
          [112.19503, 1.413562],
          [112.194786, 1.413001],
          [112.194712, 1.412537],
          [112.194761, 1.411951],
          [112.194981, 1.410877],
          [112.195786, 1.408144],
          [112.196397, 1.406557],
          [112.196763, 1.406069],
          [112.197031, 1.405825],
          [112.197373, 1.40563],
          [112.197837, 1.405459],
          [112.199643, 1.405117],
          [112.200034, 1.404995],
          [112.200595, 1.404727],
          [112.201401, 1.404263],
          [112.201889, 1.404068],
          [112.202377, 1.404116],
          [112.202743, 1.404336],
          [112.20306, 1.404629],
          [112.203451, 1.40563],
          [112.203768, 1.405996],
          [112.204378, 1.406435],
          [112.205941, 1.407729],
          [112.206331, 1.407875],
          [112.2066, 1.4079],
          [112.206917, 1.407826],
          [112.207381, 1.407509],
          [112.207991, 1.406948],
          [112.209529, 1.404946],
          [112.211628, 1.403091],
          [112.213019, 1.401773],
          [112.213776, 1.400918],
          [112.214533, 1.399722],
          [112.215729, 1.397013],
          [112.216095, 1.396573],
          [112.217144, 1.395621],
          [112.217998, 1.395182],
          [112.218438, 1.39506],
          [112.218804, 1.39506],
          [112.219414, 1.395402],
          [112.221196, 1.396744],
          [112.221928, 1.397574],
          [112.222417, 1.398258],
          [112.222612, 1.39877],
          [112.222832, 1.399136],
          [112.223369, 1.399576],
          [112.224003, 1.399917],
          [112.224467, 1.399942],
          [112.22498, 1.39982],
          [112.225858, 1.399527],
          [112.226444, 1.399063],
          [112.226835, 1.398477],
          [112.227054, 1.397769],
          [112.227152, 1.397013],
          [112.227079, 1.396403],
          [112.226713, 1.395182],
          [112.225809, 1.392717],
          [112.225639, 1.391179],
          [112.225565, 1.389495],
          [112.225663, 1.388201],
          [112.226102, 1.385541],
          [112.226127, 1.384027],
          [112.225809, 1.382514],
          [112.225346, 1.380976],
          [112.224955, 1.379951],
          [112.224565, 1.378609],
          [112.224076, 1.377217],
          [112.223686, 1.37651],
          [112.223173, 1.375802],
          [112.221367, 1.373849],
          [112.219537, 1.372336],
          [112.218511, 1.371603],
          [112.218219, 1.370993],
          [112.218048, 1.369944],
          [112.217901, 1.368162],
          [112.217877, 1.367137],
          [112.21795, 1.366404],
          [112.21817, 1.366014],
          [112.219317, 1.364745],
          [112.219805, 1.364086],
          [112.219952, 1.363598],
          [112.219927, 1.363085],
          [112.219683, 1.362133],
          [112.219732, 1.361767],
          [112.22, 1.361035],
          [112.220025, 1.360424],
          [112.219976, 1.360107],
          [112.219292, 1.359204],
          [112.219097, 1.358716],
          [112.218975, 1.357446],
          [112.218829, 1.356836],
          [112.218487, 1.35647],
          [112.216632, 1.355616],
          [112.215778, 1.355079],
          [112.214069, 1.353663],
          [112.212385, 1.352711],
          [112.212019, 1.352589],
          [112.211433, 1.352589],
          [112.210749, 1.352711],
          [112.210163, 1.352931],
          [112.209529, 1.353029],
          [112.209016, 1.352931],
          [112.208577, 1.352736],
          [112.208382, 1.352418],
          [112.208333, 1.352199],
          [112.208455, 1.35154],
          [112.209578, 1.349245],
          [112.210163, 1.348171],
          [112.21053, 1.34761],
          [112.210945, 1.347244],
          [112.212067, 1.346536],
          [112.212556, 1.346048],
          [112.212849, 1.345462],
          [112.212971, 1.3449],
          [112.212946, 1.34429],
          [112.212824, 1.343558],
          [112.212556, 1.342826],
          [112.212116, 1.342093],
          [112.211408, 1.341312],
          [112.210163, 1.340214],
          [112.209236, 1.339164],
          [112.208479, 1.338188],
          [112.207625, 1.337187],
          [112.207161, 1.336504],
          [112.206307, 1.334161],
          [112.205745, 1.332794],
          [112.205306, 1.331891],
          [112.204745, 1.331061],
          [112.204086, 1.330206],
          [112.202963, 1.328986],
          [112.201913, 1.327936],
          [112.201303, 1.32718],
          [112.200912, 1.326545],
          [112.19979, 1.324275],
          [112.199253, 1.322932],
          [112.198887, 1.322224],
          [112.198545, 1.321809],
          [112.197373, 1.32115],
          [112.196568, 1.320906],
          [112.19381, 1.320467],
          [112.192418, 1.319906],
          [112.191759, 1.31954],
          [112.189123, 1.318539],
          [112.188073, 1.318075],
          [112.187268, 1.31744],
          [112.186731, 1.316903],
          [112.185999, 1.315976],
          [112.185096, 1.315024],
          [112.184193, 1.314414],
          [112.183192, 1.313926],
          [112.182582, 1.31373],
          [112.180629, 1.313486],
          [112.179409, 1.313145],
          [112.178627, 1.312803],
          [112.177944, 1.312363],
          [112.17748, 1.3119],
          [112.177236, 1.311436],
          [112.177041, 1.310704],
          [112.176968, 1.310044],
          [112.177041, 1.308726],
          [112.177016, 1.306285],
          [112.17709, 1.304967],
          [112.177334, 1.303552],
          [112.177285, 1.302795],
          [112.176772, 1.30116],
          [112.176528, 1.299646],
          [112.176723, 1.298597],
          [112.177285, 1.297035],
          [112.177456, 1.29618],
          [112.177553, 1.295252],
          [112.177529, 1.293837],
          [112.177431, 1.292934],
          [112.177114, 1.292226],
          [112.176479, 1.291616],
          [112.175527, 1.291005],
          [112.174673, 1.290566],
          [112.173135, 1.290029],
          [112.172232, 1.289663],
          [112.17172, 1.289297],
          [112.1715, 1.288955],
          [112.171109, 1.288174],
          [112.170865, 1.287442],
          [112.170572, 1.285929],
          [112.170255, 1.284806],
          [112.169962, 1.283512],
          [112.169938, 1.282804],
          [112.170011, 1.282243],
          [112.170328, 1.281437],
          [112.170792, 1.280436],
          [112.170963, 1.279582],
          [112.170987, 1.278947],
          [112.170865, 1.278142],
          [112.169742, 1.27453],
          [112.16962, 1.273895],
          [112.169645, 1.272943],
          [112.169767, 1.27243],
          [112.170109, 1.271747],
          [112.17128, 1.270111],
          [112.171622, 1.269379],
          [112.171817, 1.268745],
          [112.171866, 1.268061],
          [112.171866, 1.265571],
          [112.17233, 1.263936],
          [112.172745, 1.263326],
          [112.173404, 1.262545],
          [112.174136, 1.261788],
          [112.174307, 1.261349],
          [112.174356, 1.260909],
          [112.174331, 1.260421],
          [112.174136, 1.259714],
          [112.173819, 1.25903],
          [112.172916, 1.258127],
          [112.171598, 1.256199],
          [112.170817, 1.255369],
          [112.169962, 1.254637],
          [112.168693, 1.253709],
          [112.167937, 1.253221],
          [112.167326, 1.25305],
          [112.166692, 1.253002],
          [112.165471, 1.253197],
          [112.16447, 1.253294],
          [112.163714, 1.253294],
          [112.162615, 1.253002],
          [112.162078, 1.252806],
          [112.161883, 1.252611],
          [112.161834, 1.251854],
          [112.161712, 1.251488],
          [112.161517, 1.251293],
          [112.161151, 1.251098],
          [112.160321, 1.250854],
          [112.159613, 1.250536],
          [112.15849, 1.249731],
          [112.158417, 1.249536],
          [112.158478, 1.249242],
          [112.159015, 1.248315],
          [112.159393, 1.247827],
          [112.160028, 1.247534],
          [112.160492, 1.247192],
          [112.16076, 1.246777],
          [112.161151, 1.245581],
          [112.161151, 1.244605],
          [112.160919, 1.243775],
          [112.16076, 1.242896],
          [112.160882, 1.242237],
          [112.1612, 1.241675],
          [112.161663, 1.241016],
          [112.162481, 1.240016],
          [112.162982, 1.239259],
          [112.163225, 1.238723],
          [112.163006, 1.237917],
          [112.162566, 1.237624],
          [112.161907, 1.2376],
          [112.160662, 1.237942],
          [112.160272, 1.237746],
          [112.160125, 1.237356],
          [112.160223, 1.236941],
          [112.160529, 1.236257],
          [112.161224, 1.235012],
          [112.161614, 1.234134],
          [112.162042, 1.233377],
          [112.163262, 1.232644],
          [112.164128, 1.231961],
          [112.164288, 1.231522],
          [112.164324, 1.231204],
          [112.164239, 1.229911],
          [112.164483, 1.229423],
          [112.164763, 1.229007],
          [112.165007, 1.228373],
          [112.165422, 1.22786],
          [112.165983, 1.227494],
          [112.166398, 1.227006],
          [112.16674, 1.226298],
          [112.166924, 1.225663],
          [112.166862, 1.2252],
          [112.165899, 1.223857],
          [112.165642, 1.223174],
          [112.16552, 1.222637],
          [112.165788, 1.222246],
          [112.167521, 1.221123],
          [112.168193, 1.220294],
          [112.168388, 1.219561],
          [112.168339, 1.219024],
          [112.167985, 1.218707],
          [112.167058, 1.218634],
          [112.166338, 1.218194],
          [112.165764, 1.21795],
          [112.164861, 1.217901],
          [112.163946, 1.21756],
          [112.163201, 1.216681],
          [112.162664, 1.216608],
          [112.162078, 1.216315],
          [112.1617, 1.215705],
          [112.161297, 1.214802],
          [112.161077, 1.214191],
          [112.161151, 1.213264],
          [112.161443, 1.212117],
          [112.162433, 1.210384],
          [112.163214, 1.208675],
          [112.163518, 1.207894],
          [112.163616, 1.207284],
          [112.163591, 1.206258],
          [112.163165, 1.205356],
          [112.162371, 1.204867],
          [112.161858, 1.204403],
          [112.161554, 1.204038],
          [112.161114, 1.203452],
          [112.160736, 1.202743],
          [112.160418, 1.201987],
          [112.159894, 1.20145],
          [112.158869, 1.200962],
          [112.158039, 1.200816],
          [112.157392, 1.200425],
          [112.156977, 1.199887],
          [112.156879, 1.199277],
          [112.156977, 1.19835],
          [112.156977, 1.197667],
          [112.156855, 1.196788],
          [112.156489, 1.196202],
          [112.155646, 1.195348],
          [112.154963, 1.194518],
          [112.154683, 1.193908],
          [112.154268, 1.193395],
          [112.153743, 1.193151],
          [112.153401, 1.192809],
          [112.152961, 1.192175],
          [112.152437, 1.191564],
          [112.151851, 1.191296],
          [112.151192, 1.191223],
          [112.150472, 1.19071],
          [112.149788, 1.189978],
          [112.148958, 1.189197],
          [112.147775, 1.188538],
          [112.146566, 1.187683],
          [112.145541, 1.187098],
          [112.144956, 1.186902],
          [112.144467, 1.186951],
          [112.143833, 1.187195],
          [112.1431, 1.18783],
          [112.142722, 1.187855],
          [112.142368, 1.187732],
          [112.142027, 1.187146],
          [112.141795, 1.186634],
          [112.141734, 1.185633],
          [112.141831, 1.184461],
          [112.142063, 1.183925],
          [112.142417, 1.183583],
          [112.143442, 1.183485],
          [112.144321, 1.183241],
          [112.144711, 1.183046],
          [112.145102, 1.182704],
          [112.145554, 1.182412],
          [112.146225, 1.182216],
          [112.146713, 1.181532],
          [112.146945, 1.180386],
          [112.147067, 1.178701],
          [112.147238, 1.177872],
          [112.147653, 1.177237],
          [112.148092, 1.177017],
          [112.148678, 1.176822],
          [112.148898, 1.176676],
          [112.148971, 1.176309],
          [112.148849, 1.175553],
          [112.148702, 1.175065],
          [112.148776, 1.174723],
          [112.148971, 1.174406],
          [112.149313, 1.174186],
          [112.149825, 1.174137],
          [112.15046, 1.174332],
          [112.151265, 1.174821],
          [112.152034, 1.174747],
          [112.152717, 1.174405],
          [112.153254, 1.173868],
          [112.154328, 1.172501],
          [112.155158, 1.171524],
          [112.155537, 1.170963],
          [112.155683, 1.170475],
          [112.155659, 1.170158],
          [112.155549, 1.169669],
          [112.155098, 1.16884],
          [112.153694, 1.167277],
          [112.152327, 1.165618],
          [112.151839, 1.164837],
          [112.151643, 1.164251],
          [112.151485, 1.163445],
          [112.151204, 1.162445],
          [112.150716, 1.161468],
          [112.150618, 1.160687],
          [112.150716, 1.159808],
          [112.151009, 1.159174],
          [112.151448, 1.158832],
          [112.152669, 1.158295],
          [112.152961, 1.157758],
          [112.153157, 1.156342],
          [112.153743, 1.153804],
          [112.153645, 1.153072],
          [112.153352, 1.152339],
          [112.153243, 1.151754],
          [112.153352, 1.149703],
          [112.153352, 1.14653],
          [112.15345, 1.145602],
          [112.154194, 1.143967],
          [112.154377, 1.14321],
          [112.15428, 1.142234],
          [112.153926, 1.141844],
          [112.153243, 1.141746],
          [112.15273, 1.141966],
          [112.15218, 1.142381],
          [112.151497, 1.143406],
          [112.150862, 1.144626],
          [112.150606, 1.14531],
          [112.15024, 1.145652],
          [112.149642, 1.145749],
          [112.147152, 1.14531],
          [112.146408, 1.145237],
          [112.145785, 1.145358],
          [112.145359, 1.145627],
          [112.145004, 1.145554],
          [112.143833, 1.144382],
          [112.143442, 1.143454],
          [112.142808, 1.142136],
          [112.142417, 1.141648],
          [112.140318, 1.140574],
          [112.139195, 1.13994],
          [112.138658, 1.139793],
          [112.13745, 1.139769],
          [112.136986, 1.139647],
          [112.136364, 1.138915],
          [112.135827, 1.138182],
          [112.135241, 1.137792],
          [112.134545, 1.137548],
          [112.133911, 1.137524],
          [112.132312, 1.138036],
          [112.131629, 1.138182],
          [112.131043, 1.138036],
          [112.13075, 1.137889],
          [112.130018, 1.137841],
          [112.129139, 1.137987],
          [112.128504, 1.138329],
          [112.127626, 1.138915],
          [112.127174, 1.13911],
          [112.126563, 1.13911],
          [112.126112, 1.139012],
          [112.125282, 1.139061],
          [112.124452, 1.139256],
          [112.123036, 1.139305],
          [112.121913, 1.139061],
          [112.12123, 1.138963],
          [112.120364, 1.139061],
          [112.119619, 1.139012],
          [112.118691, 1.138817],
          [112.116983, 1.13828],
          [112.115518, 1.138036],
          [112.114005, 1.137694],
          [112.112492, 1.136962],
          [112.110637, 1.136571],
          [112.110149, 1.136571],
          [112.109075, 1.137108],
          [112.108147, 1.137401],
          [112.107659, 1.137499],
          [112.106146, 1.137108],
          [112.103656, 1.137059],
          [112.102435, 1.137401],
          [112.100031, 1.138378],
          [112.099421, 1.138378],
          [112.098188, 1.137548],
          [112.096577, 1.136669],
          [112.095503, 1.135888],
          [112.094918, 1.1354],
          [112.094185, 1.135107],
          [112.093648, 1.135009],
          [112.092574, 1.135253],
          [112.089743, 1.137011],
          [112.08862, 1.137841],
          [112.087595, 1.138133],
          [112.086863, 1.138182],
          [112.086179, 1.138036],
          [112.085545, 1.137694],
          [112.083738, 1.136083],
          [112.083152, 1.13579],
          [112.082567, 1.135741],
          [112.081151, 1.13623],
          [112.080614, 1.136181],
          [112.07987, 1.135937],
          [112.079235, 1.135839],
          [112.078771, 1.136034],
          [112.078466, 1.136376],
          [112.077929, 1.136718],
          [112.077392, 1.136767],
          [112.075879, 1.136571],
          [112.074121, 1.135839],
          [112.072608, 1.135351],
          [112.071534, 1.135302],
          [112.07085, 1.135351],
          [112.070558, 1.135595],
          [112.070167, 1.135693],
          [112.069679, 1.135644],
          [112.069337, 1.135546],
          [112.068947, 1.135351],
          [112.068312, 1.135302],
          [112.066457, 1.136132],
          [112.065432, 1.136474],
          [112.064553, 1.136327],
          [112.064114, 1.136132],
          [112.063577, 1.135693],
          [112.063332, 1.135204],
          [112.062942, 1.134814],
          [112.062014, 1.134521],
          [112.059964, 1.134277],
          [112.059329, 1.13413],
          [112.058988, 1.133886],
          [112.058304, 1.133496],
          [112.056791, 1.133301],
          [112.055668, 1.132959],
          [112.05396, 1.13169],
          [112.05352, 1.131543],
          [112.052641, 1.131592],
          [112.051421, 1.131787],
          [112.050005, 1.132178],
          [112.048687, 1.132617],
          [112.046246, 1.13291],
          [112.044391, 1.132568],
          [112.042585, 1.132422],
          [112.041121, 1.132129],
          [112.036825, 1.130372],
          [112.035165, 1.129883],
          [112.034188, 1.129737],
          [112.032333, 1.129688],
          [112.031894, 1.129591],
          [112.031455, 1.129346],
          [112.031015, 1.128858],
          [112.030527, 1.128468],
          [112.030185, 1.127833],
          [112.029795, 1.127443],
          [112.029453, 1.127394],
          [112.029014, 1.127491],
          [112.027928, 1.128273],
          [112.027464, 1.128395],
          [112.02711, 1.128321],
          [112.026719, 1.128126],
          [112.026182, 1.127931],
          [112.02545, 1.127882],
          [112.024523, 1.127882],
          [112.023741, 1.128175],
          [112.022863, 1.128614],
          [112.021056, 1.129786],
          [112.020471, 1.130079],
          [112.019201, 1.130372],
          [112.018274, 1.130664],
          [112.017444, 1.131153],
          [112.016516, 1.132227],
          [112.016272, 1.132568],
          [112.015931, 1.132666],
          [112.015589, 1.132617],
          [112.015345, 1.132373],
          [112.015247, 1.132031],
          [112.014954, 1.13169],
          [112.013929, 1.131055],
          [112.01222, 1.130127],
          [112.011049, 1.129639],
          [112.010317, 1.129542],
          [112.009487, 1.129542],
          [112.00895, 1.129688],
          [112.00851, 1.129639],
          [112.008217, 1.129444],
          [112.008022, 1.128809],
          [112.007729, 1.128419],
          [112.007046, 1.128175],
          [112.006411, 1.128126],
          [112.005044, 1.128272],
          [112.004458, 1.128272],
          [112.003824, 1.128126],
          [112.003438, 1.127766],
          [112.003119, 1.127538],
          [112.002799, 1.127036],
          [112.002571, 1.126488],
          [112.002114, 1.125986],
          [112.001475, 1.125758],
          [112.000562, 1.125575],
          [111.99828, 1.125301],
          [111.996865, 1.125301],
          [111.995997, 1.12521],
          [111.993989, 1.124297],
          [111.993212, 1.123749],
          [111.992665, 1.123521],
          [111.991934, 1.123384],
          [111.990473, 1.123749],
          [111.98883, 1.124297],
          [111.987826, 1.12416],
          [111.986273, 1.123384],
          [111.985817, 1.123201],
          [111.985087, 1.123201],
          [111.98431, 1.123475],
          [111.983854, 1.123567],
          [111.982165, 1.123384],
          [111.980019, 1.123521],
          [111.979015, 1.123293],
          [111.97801, 1.122973],
          [111.976321, 1.122517],
          [111.97518, 1.122289],
          [111.972578, 1.121284],
          [111.971619, 1.12101],
          [111.970067, 1.121193],
          [111.96888, 1.121878],
          [111.968195, 1.122152],
          [111.967693, 1.122152],
          [111.967191, 1.12206],
          [111.965411, 1.121376],
          [111.964543, 1.121239],
          [111.963859, 1.121239],
          [111.96322, 1.121467],
          [111.962444, 1.122015],
          [111.961896, 1.122608],
          [111.961074, 1.123065],
          [111.960481, 1.123339],
          [111.959841, 1.123339],
          [111.959202, 1.123247],
          [111.958244, 1.123293],
          [111.956737, 1.12343],
          [111.955961, 1.123567],
          [111.955459, 1.123384],
          [111.954866, 1.123065],
          [111.953816, 1.122334],
          [111.951487, 1.119367],
          [111.950985, 1.118408],
          [111.950574, 1.117495],
          [111.949387, 1.11608],
          [111.948611, 1.115532],
          [111.947744, 1.115532],
          [111.947151, 1.115715],
          [111.945416, 1.116582],
          [111.944549, 1.116582],
          [111.94327, 1.116856],
          [111.939527, 1.118545],
          [111.938659, 1.118728],
          [111.937701, 1.118728],
          [111.935099, 1.118363],
          [111.934003, 1.117815],
          [111.933455, 1.117084],
          [111.933273, 1.116126],
          [111.933364, 1.114848],
          [111.933957, 1.113113],
          [111.934049, 1.112245],
          [111.933957, 1.11083],
          [111.933912, 1.108867],
          [111.934277, 1.106402],
          [111.934323, 1.104941],
          [111.934231, 1.1038],
          [111.934003, 1.102841],
          [111.933455, 1.102157],
          [111.932816, 1.101609],
          [111.931629, 1.099691],
          [111.931218, 1.098915],
          [111.930625, 1.097272],
          [111.930305, 1.096587],
          [111.929666, 1.096085],
          [111.929027, 1.095765],
          [111.928525, 1.095354],
          [111.928251, 1.094989],
          [111.928114, 1.094441],
          [111.928525, 1.092981],
          [111.928616, 1.092068],
          [111.928525, 1.091428],
          [111.927931, 1.09047],
          [111.92784, 1.090013],
          [111.92784, 1.089602],
          [111.92816, 1.088735],
          [111.928297, 1.088233],
          [111.927977, 1.087913],
          [111.927612, 1.087913],
          [111.926973, 1.08805],
          [111.926379, 1.088644],
          [111.925329, 1.08942],
          [111.924645, 1.089694],
          [111.923914, 1.089739],
          [111.923366, 1.089557],
          [111.922955, 1.089009],
          [111.922681, 1.088416],
          [111.922134, 1.088187],
          [111.921084, 1.088187],
          [111.920627, 1.088005],
          [111.920445, 1.087639],
          [111.920353, 1.087],
          [111.919805, 1.086772],
          [111.919349, 1.086863],
          [111.918938, 1.087046],
          [111.91839, 1.088142],
          [111.918116, 1.08837],
          [111.91766, 1.08837],
          [111.916382, 1.087822],
          [111.915742, 1.087731],
          [111.915103, 1.087822],
          [111.914008, 1.088096],
          [111.91314, 1.088187],
          [111.912547, 1.088187],
          [111.911999, 1.087913],
          [111.911862, 1.08732],
          [111.912273, 1.086087],
          [111.912319, 1.085585],
          [111.912136, 1.085083],
          [111.911771, 1.084535],
          [111.911177, 1.084079],
          [111.910264, 1.083759],
          [111.909671, 1.083622],
          [111.909032, 1.083622],
          [111.908438, 1.083531],
          [111.908119, 1.083257],
          [111.907845, 1.082663],
          [111.907982, 1.082024],
          [111.90821, 1.081659],
          [111.908575, 1.081294],
          [111.90958, 1.080746],
          [111.91063, 1.079742],
          [111.910858, 1.079331],
          [111.910858, 1.07892],
          [111.910766, 1.078418],
          [111.910538, 1.077916],
          [111.910264, 1.077551],
          [111.909534, 1.076364],
          [111.909169, 1.075998],
          [111.908712, 1.075679],
          [111.90821, 1.075451],
          [111.907845, 1.075131],
          [111.907617, 1.074583],
          [111.907571, 1.07399],
          [111.907799, 1.073214],
          [111.908027, 1.072757],
          [111.908667, 1.07189],
          [111.908803, 1.071388],
          [111.908895, 1.070885],
          [111.908347, 1.070612],
          [111.907845, 1.07052],
          [111.907297, 1.070657],
          [111.906703, 1.071114],
          [111.905973, 1.071799],
          [111.905425, 1.072118],
          [111.905014, 1.072073],
          [111.904375, 1.071707],
          [111.903873, 1.071114],
          [111.903599, 1.070703],
          [111.903097, 1.070338],
          [111.902595, 1.070247],
          [111.901545, 1.070566],
          [111.900997, 1.07084],
          [111.900677, 1.071205],
          [111.900267, 1.071525],
          [111.899764, 1.071799],
          [111.898988, 1.071981],
          [111.897573, 1.072575],
          [111.897117, 1.072666],
          [111.89593, 1.072529],
          [111.895382, 1.072575],
          [111.894652, 1.072849],
          [111.894195, 1.07294],
          [111.893419, 1.072803],
          [111.892597, 1.072346],
          [111.892004, 1.071616],
          [111.891456, 1.069242],
          [111.891273, 1.068649],
          [111.890908, 1.068329],
          [111.890497, 1.068147],
          [111.88995, 1.068238],
          [111.888854, 1.068968],
          [111.888352, 1.06906],
          [111.886571, 1.068694],
          [111.886115, 1.068512],
          [111.885841, 1.068101],
          [111.885887, 1.067781],
          [111.885795, 1.067416],
          [111.88543, 1.067142],
          [111.884061, 1.067279],
          [111.882919, 1.067142],
          [111.881824, 1.066868],
          [111.880819, 1.066412],
          [111.880363, 1.065864],
          [111.880043, 1.065271],
          [111.880089, 1.064768],
          [111.8805, 1.064175],
          [111.880774, 1.063673],
          [111.881093, 1.062897],
          [111.88123, 1.062258],
          [111.881641, 1.061253],
          [111.882463, 1.059975],
          [111.882691, 1.059336],
          [111.882417, 1.058879],
          [111.88123, 1.057829],
          [111.880774, 1.057099],
          [111.880363, 1.056186],
          [111.88018, 1.055364],
          [111.879769, 1.054771],
          [111.879541, 1.054132],
          [111.879541, 1.05363],
          [111.879861, 1.052808],
          [111.880226, 1.052215],
          [111.880272, 1.051712],
          [111.880135, 1.05121],
          [111.879724, 1.050982],
          [111.879176, 1.051028],
          [111.878537, 1.051393],
          [111.877624, 1.051393],
          [111.876391, 1.050936],
          [111.875341, 1.050389],
          [111.874976, 1.049749],
          [111.874839, 1.049339],
          [111.87493, 1.048973],
          [111.875067, 1.048836],
          [111.875615, 1.048745],
          [111.876209, 1.048471],
          [111.876437, 1.048243],
          [111.87662, 1.047878],
          [111.876528, 1.047376],
          [111.876254, 1.047147],
          [111.875478, 1.046919],
          [111.874702, 1.046873],
          [111.872694, 1.047467],
          [111.872055, 1.047513],
          [111.871324, 1.047193],
          [111.871233, 1.046828],
          [111.87137, 1.046508],
          [111.871598, 1.046143],
          [111.872237, 1.045641],
          [111.872739, 1.045321],
          [111.872785, 1.04491],
          [111.872739, 1.044408],
          [111.872328, 1.043769],
          [111.871324, 1.043039],
          [111.870457, 1.042126],
          [111.870365, 1.041487],
          [111.870594, 1.040847],
          [111.870959, 1.040163],
          [111.871096, 1.039387],
          [111.870959, 1.038108],
          [111.870639, 1.037515],
          [111.870639, 1.037013],
          [111.870822, 1.036602],
          [111.871324, 1.036191],
          [111.872648, 1.034958],
          [111.872876, 1.034547],
          [111.872922, 1.033817],
          [111.873059, 1.033041],
          [111.873607, 1.031717],
          [111.874337, 1.029754],
          [111.87452, 1.029389],
          [111.874474, 1.028978],
          [111.8742, 1.028704],
          [111.873652, 1.028339],
          [111.871461, 1.026695],
          [111.870868, 1.026056],
          [111.870274, 1.024732],
          [111.869909, 1.024184],
          [111.869316, 1.023911],
          [111.868631, 1.024002],
          [111.867627, 1.02423],
          [111.86717, 1.023911],
          [111.866805, 1.0235],
          [111.866485, 1.022998],
          [111.865344, 1.021445],
          [111.864705, 1.020989],
          [111.86402, 1.02035],
          [111.863701, 1.01962],
          [111.863792, 1.018935],
          [111.863975, 1.018615],
          [111.864477, 1.018387],
          [111.865161, 1.018341],
          [111.865481, 1.018387],
          [111.866211, 1.01857],
          [111.867079, 1.018935],
          [111.867809, 1.019163],
          [111.868494, 1.019209],
          [111.86895, 1.019026],
          [111.869316, 1.018433],
          [111.869544, 1.016698],
          [111.869863, 1.015739],
          [111.869863, 1.014598],
          [111.869681, 1.01405],
          [111.86927, 1.013731],
          [111.867672, 1.013183],
          [111.867079, 1.012863],
          [111.866805, 1.012133],
          [111.866531, 1.009804],
          [111.866257, 1.00912],
          [111.865892, 1.008937],
          [111.865253, 1.009028],
          [111.863153, 1.010078],
          [111.862377, 1.010398],
          [111.861692, 1.010352],
          [111.860962, 1.010078],
          [111.85982, 1.009165],
          [111.858405, 1.008161],
          [111.857994, 1.007796],
          [111.857903, 1.007385],
          [111.857949, 1.00702],
          [111.858633, 1.006061],
          [111.858542, 1.005696],
          [111.858131, 1.005559],
          [111.85772, 1.00565],
          [111.856944, 1.005924],
          [111.856168, 1.006015],
          [111.855849, 1.005924],
          [111.855712, 1.005513],
          [111.855712, 1.005148],
          [111.856214, 1.00387],
          [111.856168, 1.003185],
          [111.85594, 1.002272],
          [111.855484, 1.001587],
          [111.854479, 1.001131],
          [111.852562, 1.000629],
          [111.851329, 1.000355],
          [111.850371, 1.000355],
          [111.849549, 1.000446],
          [111.848819, 1.0004],
          [111.848225, 0.999944],
          [111.847221, 0.999442],
          [111.845806, 0.99894],
          [111.843843, 0.998529],
          [111.84261, 0.998209],
          [111.841606, 0.998072],
          [111.840784, 0.997753],
          [111.839871, 0.997342],
          [111.839186, 0.99684],
          [111.838593, 0.996109],
          [111.838136, 0.99474],
          [111.837725, 0.994009],
          [111.837223, 0.99369],
          [111.836676, 0.993644],
          [111.836082, 0.993781],
          [111.835078, 0.994101],
          [111.834119, 0.994192],
          [111.832978, 0.993918],
          [111.832019, 0.993188],
          [111.830284, 0.991499],
          [111.829462, 0.990631],
          [111.828869, 0.989353],
          [111.827089, 0.986386],
          [111.826495, 0.98561],
          [111.825993, 0.985382],
          [111.825354, 0.985336],
          [111.824852, 0.985382],
          [111.823117, 0.985838],
          [111.822067, 0.986021],
          [111.821382, 0.986021],
          [111.819647, 0.984834],
          [111.81878, 0.984149],
          [111.818232, 0.983784],
          [111.817639, 0.983556],
          [111.817137, 0.983601],
          [111.816497, 0.983738],
          [111.815767, 0.984286],
          [111.815402, 0.98488],
          [111.815128, 0.985793],
          [111.81458, 0.988303],
          [111.814124, 0.990129],
          [111.813941, 0.991681],
          [111.81353, 0.993188],
          [111.813074, 0.994146],
          [111.812389, 0.994968],
          [111.811658, 0.995744],
          [111.810928, 0.995927],
          [111.810152, 0.995835],
          [111.809102, 0.995425],
          [111.807869, 0.995014],
          [111.807002, 0.994877],
          [111.806043, 0.995014],
          [111.804537, 0.995516],
          [111.803761, 0.996292],
          [111.802574, 0.998118],
          [111.80198, 0.998757],
          [111.800885, 0.999533],
          [111.799516, 1.000218],
          [111.798557, 1.000446],
          [111.797507, 1.000355],
          [111.796777, 1.000035],
          [111.794357, 0.997844],
          [111.793627, 0.99684],
          [111.792668, 0.995333],
          [111.791983, 0.994557],
          [111.791344, 0.994283],
          [111.790614, 0.994146],
          [111.789701, 0.994237],
          [111.788377, 0.994694],
          [111.785455, 0.995333],
          [111.78331, 0.996155],
          [111.78121, 0.997114],
          [111.779977, 0.997753],
          [111.776918, 0.998757],
          [111.775549, 0.999122],
          [111.774544, 0.999853],
          [111.773312, 1.000948],
          [111.771988, 1.003139],
          [111.771075, 1.004966],
          [111.770801, 1.005833],
          [111.770482, 1.010033],
          [111.770253, 1.0109],
          [111.769888, 1.013228],
          [111.770025, 1.015283],
          [111.769842, 1.016561],
          [111.769614, 1.017109],
          [111.768884, 1.017702],
          [111.767012, 1.018615],
          [111.765003, 1.019756],
          [111.763862, 1.02035],
          [111.762903, 1.020943],
          [111.761899, 1.021217],
          [111.761123, 1.021217],
          [111.760575, 1.02108],
          [111.759982, 1.020852],
          [111.759434, 1.02003],
          [111.759388, 1.019391],
          [111.75916, 1.018843],
          [111.75884, 1.018524],
          [111.758338, 1.018204],
          [111.756558, 1.017702],
          [111.755827, 1.017428],
          [111.754914, 1.017291],
          [111.754458, 1.017017],
          [111.754001, 1.01647],
          [111.753773, 1.015967],
          [111.753317, 1.015511],
          [111.752906, 1.015466],
          [111.752175, 1.014872],
          [111.751582, 1.014233],
          [111.751491, 1.013731],
          [111.751126, 1.012544],
          [111.750943, 1.012087],
          [111.749893, 1.011083],
          [111.749071, 1.010124],
          [111.748386, 1.008892],
          [111.748021, 1.008664],
          [111.747473, 1.008709],
          [111.747154, 1.008892],
          [111.746743, 1.009303],
          [111.746332, 1.009531],
          [111.74551, 1.009714],
          [111.74373, 1.009303],
          [111.743319, 1.009029],
          [111.742634, 1.008801],
          [111.74195, 1.008846],
          [111.740991, 1.009166],
          [111.74058, 1.009577],
          [111.74026, 1.010307],
          [111.739987, 1.010581],
          [111.739667, 1.010718],
          [111.739347, 1.010581],
          [111.739119, 1.01017],
          [111.738434, 1.009394],
          [111.737841, 1.009303],
          [111.736426, 1.009257],
          [111.735741, 1.009029],
          [111.735011, 1.008618],
          [111.734645, 1.008116],
          [111.734234, 1.007751],
          [111.733093, 1.007431],
          [111.7304, 1.007477],
          [111.729441, 1.007842],
          [111.728756, 1.008025],
          [111.728209, 1.008025],
          [111.727615, 1.007979],
          [111.726474, 1.007066],
          [111.72588, 1.006838],
          [111.725059, 1.006975],
          [111.724146, 1.007066],
          [111.723096, 1.007066],
          [111.722091, 1.006929],
          [111.720356, 1.006564],
          [111.719763, 1.006518],
          [111.718987, 1.006655],
          [111.718302, 1.007066],
          [111.7178, 1.007294],
          [111.717252, 1.007431],
          [111.716339, 1.007522],
          [111.715609, 1.007842],
          [111.71497, 1.008344],
          [111.714148, 1.009257],
          [111.713463, 1.009577],
          [111.712915, 1.009988],
          [111.712231, 1.011357],
          [111.711774, 1.012589],
          [111.711272, 1.013457],
          [111.710633, 1.014187],
          [111.709948, 1.014735],
          [111.7094, 1.015374],
          [111.709218, 1.016104],
          [111.709263, 1.016424],
          [111.709629, 1.017109],
          [111.71045, 1.018524],
          [111.710998, 1.019209],
          [111.711546, 1.019757],
          [111.713326, 1.020533],
          [111.714102, 1.021126],
          [111.714604, 1.02172],
          [111.714878, 1.022633],
          [111.714924, 1.0235],
          [111.714741, 1.024413],
          [111.714331, 1.025235],
          [111.713783, 1.025919],
          [111.713007, 1.026102],
          [111.712322, 1.026056],
          [111.711683, 1.026102],
          [111.711135, 1.026193],
          [111.709537, 1.02665],
          [111.709035, 1.026559],
          [111.708168, 1.025874],
          [111.707574, 1.025737],
          [111.706798, 1.025691],
          [111.706113, 1.025509],
          [111.70552, 1.025052],
          [111.705018, 1.024504],
          [111.70447, 1.024093],
          [111.703648, 1.023911],
          [111.703101, 1.024048],
          [111.702553, 1.024276],
          [111.70164, 1.025463],
          [111.700772, 1.026376],
          [111.699722, 1.026969],
          [111.698581, 1.027335],
          [111.697622, 1.027563],
          [111.696344, 1.028111],
          [111.694701, 1.028613],
          [111.693833, 1.02875],
          [111.693377, 1.028567],
          [111.693057, 1.028111],
          [111.692601, 1.027335],
          [111.692418, 1.026878],
          [111.692099, 1.026604],
          [111.691688, 1.026513],
          [111.691003, 1.025965],
          [111.690455, 1.02592],
          [111.689999, 1.025965],
          [111.689497, 1.026239],
          [111.688036, 1.027654],
          [111.68726, 1.028248],
          [111.686484, 1.029115],
          [111.685525, 1.030028],
          [111.684977, 1.030302],
          [111.684064, 1.030667],
          [111.683334, 1.030759],
          [111.682695, 1.030987],
          [111.681051, 1.031808],
          [111.678403, 1.033452],
          [111.677719, 1.033954],
          [111.675892, 1.035734],
          [111.675253, 1.036282],
          [111.674751, 1.037058],
          [111.674249, 1.037652],
          [111.671875, 1.039204],
          [111.67046, 1.03998],
          [111.668999, 1.041121],
          [111.667401, 1.042582],
          [111.667036, 1.04281],
          [111.666717, 1.04281],
          [111.666214, 1.042627],
          [111.665575, 1.042171],
          [111.665073, 1.041669],
          [111.664571, 1.040847],
          [111.664069, 1.040345],
          [111.663521, 1.03998],
          [111.662745, 1.039752],
          [111.660508, 1.040025],
          [111.65996, 1.039934],
          [111.659138, 1.03966],
          [111.658636, 1.039158],
          [111.658088, 1.038199],
          [111.657815, 1.037423],
          [111.657586, 1.037104],
          [111.656308, 1.036647],
          [111.654756, 1.035871],
          [111.653752, 1.035278],
          [111.652793, 1.034639],
          [111.652245, 1.034182],
          [111.651423, 1.033817],
          [111.650602, 1.03368],
          [111.64946, 1.033771],
          [111.646676, 1.033223],
          [111.646128, 1.033041],
          [111.645261, 1.032539],
          [111.643845, 1.0319],
          [111.643115, 1.031763],
          [111.642613, 1.031808],
          [111.642111, 1.031626],
          [111.64138, 1.031489],
          [111.639554, 1.031352],
          [111.638778, 1.031215],
          [111.6385936, 1.0309258],
          [111.638322, 1.03085],
          [111.638048, 1.030302],
          [111.6379817, 1.0300144],
          [111.63782, 1.029617],
          [111.63782, 1.029024],
          [111.638139, 1.0277],
          [111.638641, 1.026787],
          [111.639646, 1.025828],
          [111.639874, 1.025463],
          [111.639965, 1.024869],
          [111.63992, 1.02455],
          [111.639783, 1.024093],
          [111.639737, 1.023089],
          [111.639783, 1.022495],
          [111.639509, 1.022039],
          [111.639098, 1.021993],
          [111.638504, 1.021811],
          [111.63782, 1.021445],
          [111.63645, 1.020852],
          [111.635583, 1.020806],
          [111.633894, 1.021491],
          [111.632159, 1.022404],
          [111.631474, 1.022404],
          [111.630652, 1.022176],
          [111.629283, 1.021491],
          [111.627959, 1.021035],
          [111.627137, 1.020806],
          [111.626452, 1.020807],
          [111.625813, 1.021172],
          [111.624809, 1.021903],
          [111.62417, 1.022131],
          [111.62344, 1.02204],
          [111.622435, 1.021537],
          [111.621568, 1.020761],
          [111.620518, 1.019666],
          [111.619377, 1.018296],
          [111.61892, 1.017977],
          [111.61819, 1.017703],
          [111.617231, 1.01752],
          [111.616592, 1.017155],
          [111.616272, 1.016744],
          [111.616135, 1.016151],
          [111.616044, 1.015648],
          [111.616364, 1.014918],
          [111.616272, 1.014644],
          [111.61609, 1.014416],
          [111.615314, 1.014096],
          [111.613259, 1.01364],
          [111.612346, 1.013366],
          [111.611799, 1.012772],
          [111.611342, 1.012407],
          [111.61084, 1.012316],
          [111.609516, 1.012544],
          [111.609014, 1.012407],
          [111.607416, 1.011312],
          [111.606731, 1.011175],
          [111.605727, 1.010855],
          [111.604905, 1.010398],
          [111.604084, 1.01017],
          [111.602349, 1.009851],
          [111.600386, 1.009394],
          [111.598971, 1.009165],
          [111.597236, 1.009028],
          [111.596278, 1.008846],
          [111.595319, 1.008344],
          [111.594406, 1.007522],
          [111.59331, 1.006655],
          [111.59121, 1.005148],
          [111.590297, 1.004189],
          [111.589658, 1.003185],
          [111.589339, 1.002363],
          [111.589293, 1.00145],
          [111.589613, 0.998666],
          [111.590069, 0.995379],
          [111.590023, 0.994785],
          [111.589749, 0.9941],
          [111.588928, 0.993096],
          [111.587878, 0.992092],
          [111.587056, 0.991088],
          [111.586782, 0.990312],
          [111.5866, 0.989536],
          [111.586508, 0.988805],
          [111.586508, 0.987116],
          [111.586371, 0.985838],
          [111.58596, 0.984788],
          [111.585458, 0.984012],
          [111.584819, 0.983373],
          [111.583723, 0.982688],
          [111.58281, 0.982049],
          [111.582354, 0.981638],
          [111.581395, 0.980177],
          [111.580665, 0.979629],
          [111.57966, 0.978534],
          [111.5782, 0.976023],
          [111.577697, 0.975338],
          [111.576784, 0.974471],
          [111.575963, 0.974151],
          [111.575095, 0.97406],
          [111.574045, 0.974334],
          [111.573452, 0.974836],
          [111.572904, 0.975703],
          [111.570941, 0.977849],
          [111.569845, 0.979219],
          [111.569115, 0.979812],
          [111.567472, 0.980679],
          [111.567289, 0.98109],
          [111.567243, 0.98141],
          [111.567563, 0.982597],
          [111.567517, 0.982916],
          [111.567243, 0.983327],
          [111.566832, 0.983829],
          [111.565691, 0.984377],
          [111.564595, 0.984742],
          [111.563409, 0.984879],
          [111.562222, 0.984788],
          [111.561354, 0.984879],
          [111.560122, 0.985518],
          [111.559711, 0.985564],
          [111.559346, 0.985473],
          [111.558159, 0.984332],
          [111.556926, 0.983601],
          [111.555967, 0.982871],
          [111.555328, 0.982095],
          [111.554735, 0.981182],
          [111.554278, 0.980908],
          [111.553548, 0.980679],
          [111.552772, 0.980542],
          [111.552087, 0.98036],
          [111.551311, 0.979995],
          [111.550672, 0.979538],
          [111.549165, 0.978945],
          [111.548115, 0.978351],
          [111.547887, 0.978123],
          [111.547796, 0.977621],
          [111.548207, 0.976251],
          [111.548389, 0.974653],
          [111.548846, 0.974196],
          [111.549439, 0.973923],
          [111.550078, 0.973786],
          [111.550626, 0.973557],
          [111.551037, 0.973192],
          [111.551311, 0.972599],
          [111.551448, 0.97164],
          [111.551402, 0.97059],
          [111.551083, 0.96954],
          [111.550991, 0.968764],
          [111.550991, 0.967988],
          [111.551128, 0.967258],
          [111.55122, 0.966253],
          [111.552315, 0.960958],
          [111.552224, 0.958036],
          [111.552041, 0.957032],
          [111.551539, 0.955982],
          [111.550444, 0.954704],
          [111.549668, 0.953928],
          [111.548892, 0.953198],
          [111.547386, 0.952102],
          [111.546153, 0.951143],
          [111.544966, 0.950093],
          [111.544053, 0.949363],
          [111.543186, 0.949043],
          [111.540903, 0.94877],
          [111.540036, 0.94877],
          [111.538894, 0.949135],
          [111.537799, 0.950002],
          [111.535699, 0.952193],
          [111.534055, 0.95338],
          [111.53191, 0.95475],
          [111.530586, 0.955343],
          [111.529171, 0.955708],
          [111.525062, 0.956256],
          [111.523829, 0.956667],
          [111.522962, 0.957352],
          [111.522186, 0.958676],
          [111.521364, 0.960912],
          [111.519812, 0.96566],
          [111.517803, 0.972828],
          [111.517438, 0.974289],
          [111.517347, 0.976297],
          [111.517438, 0.978214],
          [111.517621, 0.980406],
          [111.517666, 0.982414],
          [111.517666, 0.98424],
          [111.517621, 0.985381],
          [111.517393, 0.986614],
          [111.515475, 0.993553],
          [111.515156, 0.995698],
          [111.515064, 0.997616],
          [111.515156, 1.000583],
          [111.51511, 1.001725],
          [111.514973, 1.002912],
          [111.514699, 1.004099],
          [111.514243, 1.005605],
          [111.513604, 1.007157],
          [111.512827, 1.008162],
          [111.511869, 1.00912],
          [111.510591, 1.010079],
          [111.509221, 1.011403],
          [111.508491, 1.012361],
          [111.507349, 1.015283],
          [111.506482, 1.017292],
          [111.50598, 1.018935],
          [111.505751, 1.020442],
          [111.505386, 1.021537],
          [111.504838, 1.021903],
          [111.504336, 1.022085],
          [111.503925, 1.021994],
          [111.503401, 1.021798],
          [111.502363, 1.022202],
          [111.501845, 1.022548],
          [111.501383, 1.022951],
          [111.50098, 1.023412],
          [111.500057, 1.024681],
          [111.499135, 1.025719],
          [111.497117, 1.027045],
          [111.495848, 1.027679],
          [111.495156, 1.027794],
          [111.493946, 1.028198],
          [111.493081, 1.028717],
          [111.491755, 1.030101],
          [111.48991, 1.033791],
          [111.48916, 1.034425],
          [111.488641, 1.034483],
          [111.487661, 1.034367],
          [111.486277, 1.034079],
          [111.484547, 1.034021],
          [111.482529, 1.034137],
          [111.47636, 1.034771],
          [111.474227, 1.034886],
          [111.468115, 1.035578],
          [111.465982, 1.035866],
          [111.465117, 1.036097],
          [111.464425, 1.036501],
          [111.463503, 1.036443],
          [111.462869, 1.036097],
          [111.462523, 1.035636],
          [111.462177, 1.034598],
          [111.461888, 1.033387],
          [111.461254, 1.032407],
          [111.460389, 1.031196],
          [111.459986, 1.030389],
          [111.45889, 1.029351],
          [111.457449, 1.027564],
          [111.456584, 1.026065],
          [111.455028, 1.023701],
          [111.454047, 1.022951],
          [111.452087, 1.021856],
          [111.448916, 1.019895],
          [111.447186, 1.018742],
          [111.443496, 1.015629],
          [111.442285, 1.01511],
          [111.441247, 1.01511],
          [111.439633, 1.014994],
          [111.435078, 1.014533],
          [111.433175, 1.014187],
          [111.431965, 1.014014],
          [111.430811, 1.013553],
          [111.427237, 1.011766],
          [111.42591, 1.011304],
          [111.42493, 1.011131],
          [111.423431, 1.011016],
          [111.421759, 1.011247],
          [111.420375, 1.011362],
          [111.418587, 1.011074],
          [111.417434, 1.011074],
          [111.415416, 1.010786],
          [111.414148, 1.010267],
          [111.413225, 1.009978],
          [111.412591, 1.009921],
          [111.411438, 1.010094],
          [111.4104, 1.010382],
          [111.409478, 1.01044],
          [111.40844, 1.010094],
          [111.407171, 1.009575],
          [111.405903, 1.009344],
          [111.405038, 1.009402],
          [111.404, 1.010094],
          [111.402386, 1.012112],
          [111.40129, 1.013092],
          [111.400541, 1.013899],
          [111.399388, 1.014649],
          [111.398408, 1.015225],
          [111.397658, 1.015283],
          [111.396966, 1.015225],
          [111.39639, 1.014994],
          [111.395755, 1.01436],
          [111.395063, 1.014014],
          [111.394314, 1.013841],
          [111.39195, 1.01413],
          [111.389932, 1.014706],
          [111.389067, 1.014821],
          [111.388318, 1.014821],
          [111.38728, 1.014533],
          [111.386127, 1.014418],
          [111.385031, 1.014591],
          [111.384166, 1.015167],
          [111.383186, 1.016782],
          [111.382091, 1.0188],
          [111.381053, 1.020068],
          [111.38013, 1.021048],
          [111.379265, 1.021856],
          [111.376267, 1.023585],
          [111.375114, 1.023989],
          [111.373788, 1.0252],
          [111.371771, 1.026814],
          [111.369522, 1.027737],
          [111.368715, 1.027967],
          [111.368138, 1.028313],
          [111.367619, 1.029121],
          [111.366639, 1.030216],
          [111.365889, 1.030908],
          [111.365025, 1.03183],
          [111.364044, 1.032292],
          [111.361969, 1.033675],
          [111.360412, 1.033791],
          [111.359835, 1.033964],
          [111.358971, 1.034483],
          [111.357529, 1.035751],
          [111.35701, 1.035924],
          [111.356491, 1.035924],
          [111.355511, 1.035693],
          [111.354012, 1.035117],
          [111.352398, 1.034367],
          [111.351648, 1.03431],
          [111.350207, 1.034656],
          [111.349745, 1.034886],
          [111.348938, 1.036155],
          [111.348246, 1.036789],
          [111.346805, 1.037596],
          [111.345363, 1.039095],
          [111.344268, 1.03996],
          [111.343172, 1.040537],
          [111.341788, 1.04094],
          [111.341096, 1.040998],
          [111.34, 1.040998],
          [111.339424, 1.041171],
          [111.33879, 1.041574],
          [111.337175, 1.042324],
          [111.336195, 1.043131],
          [111.335792, 1.043592],
          [111.334984, 1.043708],
          [111.333947, 1.043362],
          [111.333312, 1.043246],
          [111.33262, 1.043304],
          [111.331698, 1.043592],
          [111.330948, 1.04365],
          [111.330256, 1.043592],
          [111.329507, 1.043362],
          [111.328008, 1.043477],
          [111.327316, 1.043708],
          [111.326566, 1.043708],
          [111.325702, 1.043535],
          [111.324779, 1.043535],
          [111.324087, 1.043708],
          [111.323395, 1.043765],
          [111.322473, 1.044111],
          [111.320916, 1.043938],
          [111.320051, 1.043765],
          [111.319302, 1.043823],
          [111.318379, 1.043996],
          [111.317514, 1.044572],
          [111.31688, 1.045264],
          [111.316477, 1.04561],
          [111.315727, 1.045726],
          [111.314977, 1.045726],
          [111.310884, 1.046475],
          [111.308808, 1.046706],
          [111.308347, 1.047109],
          [111.307885, 1.047686],
          [111.307367, 1.048147],
          [111.306675, 1.048378],
          [111.305291, 1.048954],
          [111.303849, 1.049819],
          [111.301774, 1.051203],
          [111.300332, 1.052414],
          [111.299641, 1.053106],
          [111.298314, 1.054201],
          [111.297104, 1.054893],
          [111.296354, 1.055239],
          [111.293414, 1.054893],
          [111.289089, 1.055527],
          [111.287533, 1.055931],
          [111.28661, 1.056392],
          [111.285745, 1.056911],
          [111.285111, 1.057372],
          [111.284361, 1.058295],
          [111.284016, 1.059044],
          [111.283727, 1.059506],
          [111.283439, 1.059794],
          [111.283035, 1.059967],
          [111.282516, 1.060082],
          [111.281767, 1.060082],
          [111.281133, 1.060255],
          [111.280153, 1.060774],
          [111.278826, 1.062042],
          [111.278538, 1.06285],
          [111.27848, 1.063311],
          [111.278365, 1.063599],
          [111.278135, 1.064003],
          [111.277097, 1.065098],
          [111.276866, 1.065502],
          [111.275425, 1.066021],
          [111.274963, 1.066309],
          [111.274156, 1.066655],
          [111.273003, 1.06775],
          [111.272254, 1.068096],
          [111.271562, 1.068327],
          [111.269659, 1.068615],
          [111.26914, 1.068788],
          [111.268621, 1.068904],
          [111.26718, 1.068904],
          [111.26643, 1.068788],
          [111.265565, 1.068327],
          [111.265104, 1.067693],
          [111.264585, 1.067174],
          [111.264182, 1.066886],
          [111.263374, 1.06677],
          [111.261645, 1.06677],
          [111.260953, 1.066713],
          [111.259511, 1.066367],
          [111.258416, 1.066021],
          [111.257724, 1.065502],
          [111.257263, 1.065098],
          [111.256513, 1.064291],
          [111.255994, 1.063599],
          [111.255648, 1.063426],
          [111.255302, 1.063369],
          [111.254957, 1.063369],
          [111.254495, 1.063542],
          [111.253573, 1.064522],
          [111.252535, 1.066425],
          [111.251843, 1.067578],
          [111.250344, 1.069712],
          [111.247288, 1.075074],
          [111.246308, 1.076573],
          [111.245328, 1.077611],
          [111.244117, 1.078648],
          [111.243022, 1.079513],
          [111.241234, 1.080609],
          [111.237775, 1.082569],
          [111.235468, 1.083722],
          [111.233277, 1.084645],
          [111.230279, 1.086028],
          [111.227858, 1.086893],
          [111.226705, 1.087528],
          [111.22607, 1.087989],
          [111.225724, 1.088796],
          [111.225494, 1.0892],
          [111.225321, 1.090237],
          [111.225263, 1.091852],
          [111.225148, 1.092371],
          [111.224859, 1.092659],
          [111.224514, 1.092601],
          [111.224283, 1.092371],
          [111.223995, 1.091621],
          [111.223187, 1.088335],
          [111.222726, 1.08747],
          [111.222265, 1.086893],
          [111.221746, 1.086432],
          [111.220939, 1.086144],
          [111.219786, 1.085855],
          [111.218863, 1.085394],
          [111.218575, 1.084529],
          [111.218459, 1.083665],
          [111.218402, 1.079917],
          [111.218114, 1.078821],
          [111.217825, 1.078475],
          [111.21673, 1.078014],
          [111.215115, 1.077726],
          [111.214423, 1.07738],
          [111.21402, 1.077092],
          [111.213328, 1.075823],
          [111.212694, 1.074843],
          [111.212002, 1.074036],
          [111.210618, 1.073113],
          [111.208542, 1.072364],
          [111.207389, 1.072075],
          [111.206467, 1.071787],
          [111.205659, 1.071326],
          [111.205141, 1.070922],
          [111.20318, 1.068962],
          [111.201566, 1.067751],
          [111.19949, 1.066367],
          [111.198395, 1.065387],
          [111.197587, 1.064753],
          [111.1958, 1.064349],
          [111.193667, 1.064176],
          [111.192398, 1.064118],
          [111.190726, 1.063772],
          [111.189688, 1.063484],
          [111.188939, 1.063369],
          [111.186863, 1.063945],
          [111.18571, 1.064349],
          [111.185133, 1.064349],
          [111.184326, 1.064234],
          [111.180463, 1.062331],
          [111.17931, 1.06187],
          [111.178503, 1.061639],
          [111.177868, 1.061697],
          [111.177234, 1.061927],
          [111.176369, 1.062389],
          [111.174986, 1.063023],
          [111.172622, 1.063426],
          [111.171699, 1.063369],
          [111.167836, 1.062273],
          [111.16576, 1.061524],
          [111.164376, 1.060832],
          [111.162358, 1.05939],
          [111.160283, 1.058064],
          [111.157861, 1.056854],
          [111.155901, 1.055989],
          [111.154056, 1.055412],
          [111.149789, 1.054374],
          [111.146445, 1.05374],
          [111.144024, 1.053048],
          [111.142006, 1.052356],
          [111.139642, 1.05201],
          [111.138373, 1.05178],
          [111.136759, 1.051895],
          [111.134395, 1.052183],
          [111.1318, 1.052644],
          [111.127361, 1.053509],
          [111.125689, 1.053509],
          [111.124766, 1.053336],
          [111.123844, 1.053106],
          [111.12148, 1.052299],
          [111.119923, 1.051895],
          [111.119058, 1.05178],
          [111.117328, 1.05201],
          [111.116233, 1.052299],
          [111.115195, 1.052356],
          [111.114157, 1.052183],
          [111.113062, 1.051549],
          [111.112024, 1.050857],
          [111.111274, 1.050108],
          [111.110006, 1.048378],
          [111.10966, 1.048205],
          [111.109256, 1.048205],
          [111.10868, 1.048493],
          [111.107642, 1.049243],
          [111.107411, 1.049531],
          [111.107469, 1.050281],
          [111.107296, 1.050742],
          [111.107065, 1.051088],
          [111.106662, 1.051607],
          [111.106028, 1.051895],
          [111.105566, 1.052011],
          [111.104701, 1.052068],
          [111.103145, 1.052011],
          [111.100838, 1.051838],
          [111.099455, 1.05178],
          [111.098705, 1.051549],
          [111.097667, 1.050915],
          [111.093631, 1.048666],
          [111.092017, 1.048263],
          [111.091382, 1.048263],
          [111.090921, 1.048321],
          [111.090345, 1.048493],
          [111.089422, 1.049243],
          [111.088846, 1.049416],
          [111.087865, 1.049474],
          [111.086193, 1.049704],
          [111.08504, 1.049762],
          [111.08406, 1.049993],
          [111.082388, 1.050684],
          [111.081119, 1.051261],
          [111.08037, 1.051376],
          [111.079447, 1.051261],
          [111.074777, 1.049589],
          [111.072009, 1.048782],
          [111.067339, 1.047629],
          [111.061862, 1.046187],
          [111.060132, 1.045553],
          [111.059267, 1.044976],
          [111.058633, 1.044169],
          [111.05748, 1.042324],
          [111.056846, 1.041517],
          [111.056269, 1.041055],
          [111.05477, 1.040594],
          [111.052406, 1.040018],
          [111.050792, 1.039383],
          [111.049235, 1.038288],
          [111.048082, 1.037538],
          [111.046814, 1.037077],
          [111.045026, 1.036731],
          [111.040068, 1.036731],
          [111.038915, 1.0365],
          [111.036781, 1.035001],
          [111.036032, 1.034655],
          [111.029805, 1.033445],
          [111.027383, 1.032868],
          [111.025884, 1.032695],
          [111.024385, 1.032753],
          [111.021964, 1.033099],
          [111.020291, 1.033099],
          [111.018792, 1.032983],
          [111.017236, 1.032753],
          [111.014641, 1.032003],
          [111.011528, 1.031369],
          [111.009337, 1.031023],
          [111.007549, 1.031254],
          [111.005993, 1.031657],
          [111.005185, 1.031946],
          [111.004321, 1.032349],
          [111.003109, 1.033097],
          [111.001975, 1.032978],
          [111.00096, 1.032679],
          [111.000087, 1.032127],
          [110.999408, 1.031485],
          [110.999012, 1.030963],
          [110.998751, 1.030351],
          [110.998393, 1.0285],
          [110.998311, 1.027783],
          [110.997893, 1.027276],
          [110.99749, 1.027052],
          [110.997012, 1.027186],
          [110.996303, 1.027604],
          [110.995825, 1.028022],
          [110.995288, 1.02838],
          [110.9929, 1.028619],
          [110.990273, 1.029037],
          [110.987564, 1.029365],
          [110.987049, 1.029276],
          [110.984004, 1.028201],
          [110.983048, 1.027962],
          [110.981951, 1.027828],
          [110.97925, 1.027664],
          [110.977324, 1.027365],
          [110.976742, 1.02741],
          [110.975279, 1.028246],
          [110.973697, 1.028455],
          [110.971369, 1.029037],
          [110.969891, 1.029649],
          [110.969458, 1.029694],
          [110.968279, 1.02938],
          [110.967353, 1.029321],
          [110.966174, 1.029082],
          [110.965577, 1.029022],
          [110.964539, 1.029097],
          [110.963114, 1.029037],
          [110.962338, 1.028783],
          [110.961554, 1.02844],
          [110.96068, 1.027783],
          [110.959457, 1.026634],
          [110.958934, 1.026455],
          [110.958412, 1.026395],
          [110.956859, 1.026604],
          [110.954329, 1.026708],
          [110.953173, 1.026783],
          [110.952359, 1.026888],
          [110.951643, 1.026708],
          [110.951038, 1.02644],
          [110.947172, 1.026022],
          [110.944956, 1.025992],
          [110.94412, 1.026052],
          [110.943321, 1.026246],
          [110.94253, 1.02635],
          [110.940657, 1.02635],
          [110.939702, 1.026291],
          [110.936, 1.025693],
          [110.934925, 1.025693],
          [110.93341, 1.025813],
          [110.932597, 1.025932],
          [110.931462, 1.025992],
          [110.930686, 1.026171],
          [110.929858, 1.026305],
          [110.929014, 1.026649],
          [110.928052, 1.027231],
          [110.926499, 1.02838],
          [110.925529, 1.028962],
          [110.92447, 1.02941],
          [110.923521, 1.029694],
          [110.922566, 1.029873],
          [110.9197, 1.030231],
          [110.917454, 1.03059],
          [110.915723, 1.030664],
          [110.914842, 1.03053],
          [110.912416, 1.029753],
          [110.906751, 1.028739],
          [110.905498, 1.02835],
          [110.904475, 1.027783],
          [110.903885, 1.02738],
          [110.903497, 1.026843],
          [110.903378, 1.026127],
          [110.903423, 1.025156],
          [110.903229, 1.022425],
          [110.902901, 1.020872],
          [110.902564, 1.019902],
          [110.902095, 1.019066],
          [110.900945, 1.017991],
          [110.900572, 1.017484],
          [110.900393, 1.016782],
          [110.900289, 1.016066],
          [110.900116, 1.015603],
          [110.899841, 1.015185],
          [110.898385, 1.013872],
          [110.897848, 1.013095],
          [110.897587, 1.012633],
          [110.897071, 1.011961],
          [110.896632, 1.011469],
          [110.896005, 1.010946],
          [110.89528, 1.010349],
          [110.894444, 1.009812],
          [110.893796, 1.009603],
          [110.891512, 1.008708],
          [110.890945, 1.008215],
          [110.890616, 1.007558],
          [110.890601, 1.007035],
          [110.890691, 1.005349],
          [110.890273, 1.002781],
          [110.890302, 1.001856],
          [110.890526, 1.00002],
          [110.89081, 0.999662],
          [110.891263, 0.999408],
          [110.891702, 0.998566],
          [110.891837, 0.998093],
          [110.891735, 0.996475],
          [110.891837, 0.995666],
          [110.891567, 0.994081],
          [110.891263, 0.993036],
          [110.891196, 0.992227],
          [110.891331, 0.989597],
          [110.891061, 0.988181],
          [110.889476, 0.984135],
          [110.888667, 0.982482],
          [110.88779, 0.980864],
          [110.88688, 0.979886],
          [110.886071, 0.979178],
          [110.885127, 0.978639],
          [110.883947, 0.978234],
          [110.879496, 0.976279],
          [110.877541, 0.975807],
          [110.876765, 0.975267],
          [110.876327, 0.974863],
          [110.875484, 0.973615],
          [110.875046, 0.973076],
          [110.874337, 0.972435],
          [110.873764, 0.971794],
          [110.873393, 0.971053],
          [110.872787, 0.970142],
          [110.871809, 0.968895],
          [110.87137, 0.968018],
          [110.870494, 0.965725],
          [110.87019, 0.965119],
          [110.869887, 0.964883],
          [110.869651, 0.964748],
          [110.869314, 0.964815],
          [110.868437, 0.965388],
          [110.867898, 0.965591],
          [110.867156, 0.965725],
          [110.866482, 0.965725],
          [110.864964, 0.965253],
          [110.862705, 0.965591],
          [110.862099, 0.965793],
          [110.861997, 0.965692],
          [110.862166, 0.964579],
          [110.862099, 0.964073],
          [110.861256, 0.962253],
          [110.86102, 0.961342],
          [110.860952, 0.960837],
          [110.861627, 0.958948],
          [110.861559, 0.957667],
          [110.861458, 0.957229],
          [110.861121, 0.956723],
          [110.860851, 0.95615],
          [110.860548, 0.954869],
          [110.860143, 0.954397],
          [110.859401, 0.953655],
          [110.859064, 0.953115],
          [110.858963, 0.952677],
          [110.858997, 0.952306],
          [110.859199, 0.951834],
          [110.859907, 0.950722],
          [110.859941, 0.950452],
          [110.859907, 0.950351],
          [110.85957, 0.950081],
          [110.859064, 0.94998],
          [110.858154, 0.94998],
          [110.856265, 0.950149],
          [110.855456, 0.950351],
          [110.854782, 0.950351],
          [110.854108, 0.950553],
          [110.853568, 0.951059],
          [110.853062, 0.951767],
          [110.852624, 0.952273],
          [110.851984, 0.952543],
          [110.848174, 0.954566],
          [110.847634, 0.954802],
          [110.847196, 0.954802],
          [110.846758, 0.954498],
          [110.846319, 0.953925],
          [110.845712, 0.953351],
          [110.845072, 0.953014],
          [110.842543, 0.95261],
          [110.841532, 0.952576],
          [110.84025, 0.952745],
          [110.839745, 0.952542],
          [110.839374, 0.952104],
          [110.839037, 0.951463],
          [110.838565, 0.951025],
          [110.837654, 0.95089],
          [110.835968, 0.95025],
          [110.835631, 0.949912],
          [110.83526, 0.949778],
          [110.834755, 0.94971],
          [110.834047, 0.949744],
          [110.833035, 0.94998],
          [110.831484, 0.950519],
          [110.829057, 0.951261],
          [110.82791, 0.951801],
          [110.826899, 0.952441],
          [110.825955, 0.952745],
          [110.824977, 0.952846],
          [110.824336, 0.952846],
          [110.823527, 0.952745],
          [110.821875, 0.952138],
          [110.821167, 0.951666],
          [110.820627, 0.951093],
          [110.820357, 0.950587],
          [110.819447, 0.948598],
          [110.819076, 0.94762],
          [110.818705, 0.946811],
          [110.818132, 0.9458],
          [110.817424, 0.945058],
          [110.816649, 0.944855],
          [110.816109, 0.944957],
          [110.815401, 0.945496],
          [110.814828, 0.946137],
          [110.814221, 0.947418],
          [110.813513, 0.948328],
          [110.812805, 0.949137],
          [110.811928, 0.949913],
          [110.811355, 0.95025],
          [110.810782, 0.950419],
          [110.810175, 0.950452],
          [110.809636, 0.950318],
          [110.809231, 0.950082],
          [110.808793, 0.949542],
          [110.808388, 0.948767],
          [110.808186, 0.94789],
          [110.808085, 0.947047],
          [110.807545, 0.94617],
          [110.807141, 0.94526],
          [110.806972, 0.944754],
          [110.806972, 0.944249],
          [110.806669, 0.943574],
          [110.805893, 0.94236],
          [110.805219, 0.941484],
          [110.804275, 0.940573],
          [110.803027, 0.939191],
          [110.802993, 0.93882],
          [110.803128, 0.93855],
          [110.803499, 0.938314],
          [110.804814, 0.93764],
          [110.805589, 0.937134],
          [110.806196, 0.936561],
          [110.806736, 0.935819],
          [110.808017, 0.933763],
          [110.808422, 0.931639],
          [110.808523, 0.930526],
          [110.808792, 0.92975],
          [110.809231, 0.928975],
          [110.809939, 0.928065],
          [110.810748, 0.927222],
          [110.811456, 0.926615],
          [110.811995, 0.92594],
          [110.812198, 0.925536],
          [110.812333, 0.924963],
          [110.812569, 0.924288],
          [110.812872, 0.923681],
          [110.813445, 0.922872],
          [110.813749, 0.922299],
          [110.814018, 0.921389],
          [110.813884, 0.920849],
          [110.813648, 0.92031],
          [110.813445, 0.919467],
          [110.812906, 0.918657],
          [110.811995, 0.917511],
          [110.811321, 0.916398],
          [110.810984, 0.91569],
          [110.810883, 0.915117],
          [110.811456, 0.911813],
          [110.811018, 0.910464],
          [110.810984, 0.909149],
          [110.810883, 0.908509],
          [110.810478, 0.907936],
          [110.809163, 0.906722],
          [110.808253, 0.905946],
          [110.807815, 0.905508],
          [110.80741, 0.904834],
          [110.805994, 0.901934],
          [110.80532, 0.90099],
          [110.804848, 0.900484],
          [110.804241, 0.900248],
          [110.803769, 0.900147],
          [110.803297, 0.900249],
          [110.802993, 0.900552],
          [110.802757, 0.900957],
          [110.802656, 0.901496],
          [110.802386, 0.90217],
          [110.80097, 0.904868],
          [110.799925, 0.906385],
          [110.799419, 0.907026],
          [110.799251, 0.907396],
          [110.798914, 0.907531],
          [110.798678, 0.90743],
          [110.798206, 0.906722],
          [110.797936, 0.906149],
          [110.797632, 0.905879],
          [110.797194, 0.905643],
          [110.796688, 0.905576],
          [110.796014, 0.905677],
          [110.795306, 0.905947],
          [110.794362, 0.906688],
          [110.793688, 0.906857],
          [110.792777, 0.907194],
          [110.788698, 0.90915],
          [110.786978, 0.910128],
          [110.785225, 0.911577],
          [110.78445, 0.911948],
          [110.783978, 0.912251],
          [110.783506, 0.912959],
          [110.783405, 0.913263],
          [110.782663, 0.916972],
          [110.781618, 0.92031],
          [110.781382, 0.92095],
          [110.781112, 0.921523],
          [110.780977, 0.921962],
          [110.780977, 0.922467],
          [110.781146, 0.922939],
          [110.781247, 0.923479],
          [110.781078, 0.924389],
          [110.780775, 0.924962],
          [110.779797, 0.926446],
          [110.77919, 0.926851],
          [110.778718, 0.927087],
          [110.778314, 0.927424],
          [110.777808, 0.928334],
          [110.777336, 0.929346],
          [110.776796, 0.930054],
          [110.776291, 0.930458],
          [110.77565, 0.930829],
          [110.77447, 0.931301],
          [110.773088, 0.93211],
          [110.772211, 0.932481],
          [110.771705, 0.932515],
          [110.771098, 0.932414],
          [110.770087, 0.932043],
          [110.769514, 0.93211],
          [110.768873, 0.932043],
          [110.768131, 0.931908],
          [110.767558, 0.931739],
          [110.767221, 0.931369],
          [110.76685, 0.930728],
          [110.766479, 0.930357],
          [110.765029, 0.929649],
          [110.764827, 0.929346],
          [110.76449, 0.927963],
          [110.764591, 0.925671],
          [110.764288, 0.924828],
          [110.763984, 0.92449],
          [110.763074, 0.924086],
          [110.762265, 0.923782],
          [110.761691, 0.923513],
          [110.761321, 0.923243],
          [110.760983, 0.922299],
          [110.760815, 0.921692],
          [110.760511, 0.921254],
          [110.760208, 0.920714],
          [110.760141, 0.92031],
          [110.760107, 0.919669],
          [110.759938, 0.918893],
          [110.759871, 0.918354],
          [110.759938, 0.917949],
          [110.760107, 0.917713],
          [110.760478, 0.91741],
          [110.761624, 0.917005],
          [110.762164, 0.916736],
          [110.762467, 0.916162],
          [110.762669, 0.915623],
          [110.762804, 0.913634],
          [110.762905, 0.913027],
          [110.763276, 0.912386],
          [110.764456, 0.910903],
          [110.764591, 0.910532],
          [110.764625, 0.909992],
          [110.764591, 0.909149],
          [110.764389, 0.90834],
          [110.76422, 0.907936],
          [110.763411, 0.9077],
          [110.76277, 0.907666],
          [110.762332, 0.907565],
          [110.761422, 0.907126],
          [110.760646, 0.906991],
          [110.760107, 0.906789],
          [110.759635, 0.906486],
          [110.759298, 0.906115],
          [110.758994, 0.905441],
          [110.757983, 0.903519],
          [110.757612, 0.902541],
          [110.757207, 0.902103],
          [110.756128, 0.901462],
          [110.755926, 0.901125],
          [110.755656, 0.900417],
          [110.755319, 0.900012],
          [110.754881, 0.899911],
          [110.754105, 0.900046],
          [110.753667, 0.900249],
          [110.75333, 0.90072],
          [110.752183, 0.903519],
          [110.75188, 0.904159],
          [110.751509, 0.904665],
          [110.750869, 0.904867],
          [110.750127, 0.904935],
          [110.748877, 0.90478],
          [110.748003, 0.904598],
          [110.747362, 0.904564],
          [110.746823, 0.904328],
          [110.746115, 0.904092],
          [110.74571, 0.904092],
          [110.744361, 0.904497],
          [110.743923, 0.904564],
          [110.741934, 0.90389],
          [110.741327, 0.903755],
          [110.740754, 0.903822],
          [110.740046, 0.904024],
          [110.739641, 0.904328],
          [110.738899, 0.905508],
          [110.738394, 0.905879],
          [110.737854, 0.906115],
          [110.737011, 0.906385],
          [110.736168, 0.906587],
          [110.735056, 0.907126],
          [110.734651, 0.907261],
          [110.733842, 0.906992],
          [110.733404, 0.907059],
          [110.73283, 0.907362],
          [110.732291, 0.907565],
          [110.731684, 0.907632],
          [110.731043, 0.907598],
          [110.730268, 0.9077],
          [110.729223, 0.907767],
          [110.727908, 0.907666],
          [110.727436, 0.907497],
          [110.726289, 0.906553],
          [110.725817, 0.906351],
          [110.725379, 0.906385],
          [110.724705, 0.906789],
          [110.724064, 0.907362],
          [110.723424, 0.907801],
          [110.722952, 0.907969],
          [110.722513, 0.907969],
          [110.722008, 0.907801],
          [110.721637, 0.90743],
          [110.721603, 0.906756],
          [110.721367, 0.906115],
          [110.720996, 0.905643],
          [110.720558, 0.905508],
          [110.718703, 0.905576],
          [110.71759, 0.905744],
          [110.716815, 0.905744],
          [110.716073, 0.905542],
          [110.71523, 0.9048],
          [110.714488, 0.904059],
          [110.714353, 0.903485],
          [110.714387, 0.901091],
          [110.714185, 0.900518],
          [110.713982, 0.900114],
          [110.713409, 0.899608],
          [110.712937, 0.899271],
          [110.7126, 0.898832],
          [110.712499, 0.89836],
          [110.712229, 0.897821],
          [110.711825, 0.897315],
          [110.711285, 0.896911],
          [110.710644, 0.896573],
          [110.710004, 0.896169],
          [110.709464, 0.895697],
          [110.709161, 0.895157],
          [110.708925, 0.893472],
          [110.708689, 0.892865],
          [110.708318, 0.89273],
          [110.706093, 0.892325],
          [110.705419, 0.892157],
          [110.704845, 0.891819],
          [110.704137, 0.89074],
          [110.703766, 0.890302],
          [110.703058, 0.889931],
          [110.702687, 0.88956],
          [110.702451, 0.889021],
          [110.702384, 0.888481],
          [110.702654, 0.887639],
          [110.702755, 0.887065],
          [110.702553, 0.886593],
          [110.702148, 0.886256],
          [110.701474, 0.885986],
          [110.700159, 0.885885],
          [110.699788, 0.885717],
          [110.699114, 0.885076],
          [110.698035, 0.884705],
          [110.697394, 0.884301],
          [110.696956, 0.883526],
          [110.696787, 0.883087],
          [110.69645, 0.88302],
          [110.695944, 0.883155],
          [110.695135, 0.883458],
          [110.694663, 0.883526],
          [110.694258, 0.883391],
          [110.692977, 0.882581],
          [110.692472, 0.882514],
          [110.692168, 0.882716],
          [110.691831, 0.883424],
          [110.69146, 0.883896],
          [110.691055, 0.883998],
          [110.690583, 0.883728],
          [110.689639, 0.883053],
          [110.688965, 0.882345],
          [110.687515, 0.881637],
          [110.686942, 0.881614],
          [110.685728, 0.881873],
          [110.685155, 0.881772],
          [110.684649, 0.881604],
          [110.684144, 0.881199],
          [110.683368, 0.880221],
          [110.682525, 0.879345],
          [110.681581, 0.878468],
          [110.68057, 0.87803],
          [110.679727, 0.877895],
          [110.676827, 0.877693],
          [110.676119, 0.87776],
          [110.675479, 0.878063],
          [110.675007, 0.878569],
          [110.674535, 0.879277],
          [110.673624, 0.880255],
          [110.673253, 0.880289],
          [110.67295, 0.880154],
          [110.672545, 0.879682],
          [110.6715, 0.878131],
          [110.670994, 0.877457],
          [110.670253, 0.876951],
          [110.66914, 0.87631],
          [110.668466, 0.87604],
          [110.66769, 0.875973],
          [110.667218, 0.87604],
          [110.66651, 0.876344],
          [110.665937, 0.876917],
          [110.665532, 0.877861],
          [110.665195, 0.878873],
          [110.664892, 0.879412],
          [110.664555, 0.879648],
          [110.664217, 0.879749],
          [110.663139, 0.879513],
          [110.662565, 0.879494],
          [110.661958, 0.879547],
          [110.661352, 0.879985],
          [110.659396, 0.882851],
          [110.658991, 0.883155],
          [110.658654, 0.883188],
          [110.658317, 0.882986],
          [110.65771, 0.88248],
          [110.656732, 0.88184],
          [110.656463, 0.881907],
          [110.656193, 0.882447],
          [110.656058, 0.883391],
          [110.655889, 0.883829],
          [110.65562, 0.883998],
          [110.655181, 0.884166],
          [110.654541, 0.884234],
          [110.654102, 0.884335],
          [110.653765, 0.884503],
          [110.653731, 0.884841],
          [110.653799, 0.885683],
          [110.653765, 0.886459],
          [110.653597, 0.887875],
          [110.653563, 0.888583],
          [110.654372, 0.891112],
          [110.65444, 0.891786],
          [110.654271, 0.892224],
          [110.654035, 0.892595],
          [110.653765, 0.892663],
          [110.652349, 0.892326],
          [110.651532, 0.892227],
          [110.650899, 0.892224],
          [110.64982, 0.89246],
          [110.649213, 0.892932],
          [110.648269, 0.895495],
          [110.648236, 0.896068],
          [110.648337, 0.896574],
          [110.648607, 0.897518],
          [110.649315, 0.899001],
          [110.65009, 0.899339],
          [110.651034, 0.900418],
          [110.65154, 0.900721],
          [110.651843, 0.901294],
          [110.651911, 0.901935],
          [110.651945, 0.903317],
          [110.652113, 0.904531],
          [110.652079, 0.905003],
          [110.651945, 0.905408],
          [110.651709, 0.905711],
          [110.651102, 0.906048],
          [110.650158, 0.906385],
          [110.649382, 0.906588],
          [110.648742, 0.906689],
          [110.648067, 0.906723],
          [110.647561, 0.906891],
          [110.646247, 0.907565],
          [110.645741, 0.907599],
          [110.644594, 0.907228],
          [110.644156, 0.906992],
          [110.643448, 0.906891],
          [110.642504, 0.907026],
          [110.641897, 0.907026],
          [110.640919, 0.907194],
          [110.640144, 0.907969],
          [110.638424, 0.909015],
          [110.637784, 0.909318],
          [110.637278, 0.909217],
          [110.63684, 0.908947],
          [110.636031, 0.908138],
          [110.635727, 0.908003],
          [110.635457, 0.908071],
          [110.634648, 0.908947],
          [110.634109, 0.909015],
          [110.633232, 0.90888],
          [110.632355, 0.90861],
          [110.631479, 0.908105],
          [110.631243, 0.907666],
          [110.631513, 0.90679],
          [110.631681, 0.90598],
          [110.631614, 0.905441],
          [110.631378, 0.904665],
          [110.630569, 0.903317],
          [110.630569, 0.903013],
          [110.630838, 0.902642],
          [110.631816, 0.902406],
          [110.632187, 0.902204],
          [110.632524, 0.9018],
          [110.632861, 0.901091],
          [110.6333, 0.900586],
          [110.634075, 0.900181],
          [110.634446, 0.899743],
          [110.63448, 0.899372],
          [110.634075, 0.898462],
          [110.633985, 0.897784],
          [110.633805, 0.897079],
          [110.633704, 0.895393],
          [110.633805, 0.894854],
          [110.63485, 0.893202],
          [110.63512, 0.892494],
          [110.635255, 0.891685],
          [110.635491, 0.890943],
          [110.636469, 0.889291],
          [110.636502, 0.888988],
          [110.636031, 0.888515],
          [110.635457, 0.887773],
          [110.634851, 0.886796],
          [110.634379, 0.885919],
          [110.633805, 0.885042],
          [110.632895, 0.884031],
          [110.63276, 0.883727],
          [110.63276, 0.883356],
          [110.633097, 0.882986],
          [110.634143, 0.882446],
          [110.634783, 0.88194],
          [110.634918, 0.881502],
          [110.634851, 0.88103],
          [110.634615, 0.880457],
          [110.634277, 0.880019],
          [110.633704, 0.879614],
          [110.632996, 0.878973],
          [110.631344, 0.877355],
          [110.630973, 0.876714],
          [110.629625, 0.874017],
          [110.628815, 0.872769],
          [110.628208, 0.872061],
          [110.626792, 0.871016],
          [110.625882, 0.869971],
          [110.62541, 0.869566],
          [110.624533, 0.869297],
          [110.623825, 0.869162],
          [110.623219, 0.86896],
          [110.622409, 0.868285],
          [110.621127, 0.867038],
          [110.62015, 0.866263],
          [110.619745, 0.866128],
          [110.618026, 0.866431],
          [110.617216, 0.866903],
          [110.616171, 0.867679],
          [110.615733, 0.867881],
          [110.614452, 0.867915],
          [110.614013, 0.868151],
          [110.613339, 0.868623],
          [110.612766, 0.86896],
          [110.612159, 0.869061],
          [110.611721, 0.868994],
          [110.610372, 0.867948],
          [110.609394, 0.866903],
          [110.609057, 0.867173],
          [110.608518, 0.86751],
          [110.608046, 0.867611],
          [110.606798, 0.867443],
          [110.606225, 0.867476],
          [110.605584, 0.867746],
          [110.604708, 0.868016],
          [110.603831, 0.868353],
          [110.602921, 0.868589],
          [110.602213, 0.868881],
          [110.601842, 0.868892],
          [110.601538, 0.868623],
          [110.600965, 0.867578],
          [110.599987, 0.866566],
          [110.599684, 0.865892],
          [110.599482, 0.865184],
          [110.599347, 0.864509],
          [110.599246, 0.862992],
          [110.598942, 0.861812],
          [110.597964, 0.85962],
          [110.597425, 0.858845],
          [110.596919, 0.858339],
          [110.596245, 0.858036],
          [110.595571, 0.857935],
          [110.594863, 0.858002],
          [110.594155, 0.858305],
          [110.593211, 0.858811],
          [110.5923, 0.859047],
          [110.591356, 0.859216],
          [110.590682, 0.859452],
          [110.589906, 0.859958],
          [110.589232, 0.860194],
          [110.588591, 0.860194],
          [110.58785, 0.860025],
          [110.587411, 0.859688],
          [110.587142, 0.859115],
          [110.586636, 0.858339],
          [110.586063, 0.857935],
          [110.585557, 0.8578],
          [110.584748, 0.857935],
          [110.58404, 0.857867],
          [110.583197, 0.857598],
          [110.582556, 0.857564],
          [110.581848, 0.857733],
          [110.581073, 0.858373],
          [110.580432, 0.858643],
          [110.579724, 0.858609],
          [110.579151, 0.858373],
          [110.57878, 0.857867],
          [110.578308, 0.856114],
          [110.577802, 0.855238],
          [110.577128, 0.854395],
          [110.576386, 0.853821],
          [110.575779, 0.853821],
          [110.575004, 0.854091],
          [110.573588, 0.855406],
          [110.572172, 0.856586],
          [110.571531, 0.856856],
          [110.570958, 0.856755],
          [110.569913, 0.856418],
          [110.569744, 0.856586],
          [110.56971, 0.857193],
          [110.569407, 0.857834],
          [110.568935, 0.860059],
          [110.568598, 0.860632],
          [110.568227, 0.860767],
          [110.567114, 0.860632],
          [110.566507, 0.860632],
          [110.565158, 0.860987],
          [110.564687, 0.861003],
          [110.563776, 0.860767],
          [110.562799, 0.860666],
          [110.561585, 0.861037],
          [110.560809, 0.861104],
          [110.560101, 0.861003],
          [110.558989, 0.860767],
          [110.556393, 0.860025],
          [110.553628, 0.859451],
          [110.55319, 0.859485],
          [110.552819, 0.859721],
          [110.551504, 0.860766],
          [110.550088, 0.861373],
          [110.547424, 0.862924],
          [110.546547, 0.863565],
          [110.546143, 0.864071],
          [110.545873, 0.864644],
          [110.545839, 0.866195],
          [110.545536, 0.868251],
          [110.545367, 0.868589],
          [110.54503, 0.868589],
          [110.54385, 0.867847],
          [110.543142, 0.867577],
          [110.542704, 0.867577],
          [110.540917, 0.868622],
          [110.54004, 0.868993],
          [110.539332, 0.869061],
          [110.538422, 0.868926],
          [110.537309, 0.868656],
          [110.535016, 0.867712],
          [110.534308, 0.867712],
          [110.533735, 0.867881],
          [110.533297, 0.868218],
          [110.531476, 0.870072],
          [110.531004, 0.870376],
          [110.528441, 0.871387],
          [110.527734, 0.871388],
          [110.526958, 0.871152],
          [110.526116, 0.870781],
          [110.525138, 0.870612],
          [110.523384, 0.870343],
          [110.5218, 0.870174],
          [110.520552, 0.87014],
          [110.519878, 0.870275],
          [110.51917, 0.870579],
          [110.517686, 0.87186],
          [110.517113, 0.87213],
          [110.514955, 0.872838],
          [110.512359, 0.87422],
          [110.511685, 0.874523],
          [110.511145, 0.874523],
          [110.510471, 0.874422],
          [110.509527, 0.874153],
          [110.508448, 0.874018],
          [110.507706, 0.874018],
          [110.507167, 0.874119],
          [110.506796, 0.873984],
          [110.506256, 0.873512],
          [110.50575, 0.873377],
          [110.505278, 0.873512],
          [110.50366, 0.874287],
          [110.50246, 0.874588],
          [110.501027, 0.874875],
          [110.500432, 0.874941],
          [110.499638, 0.874742],
          [110.498822, 0.874434],
          [110.498337, 0.874478],
          [110.497874, 0.874742],
          [110.497411, 0.875095],
          [110.496926, 0.875249],
          [110.496353, 0.875205],
          [110.495802, 0.875271],
          [110.494699, 0.875558],
          [110.493773, 0.875955],
          [110.493045, 0.876175],
          [110.491061, 0.876286],
          [110.490642, 0.876374],
          [110.490421, 0.876815],
          [110.490466, 0.878094],
          [110.490355, 0.879351],
          [110.490047, 0.879814],
          [110.48965, 0.880122],
          [110.487158, 0.880563],
          [110.486695, 0.880828],
          [110.485769, 0.881688],
          [110.484739, 0.882972],
          [110.48427, 0.883364],
          [110.48385, 0.883518],
          [110.483145, 0.883518],
          [110.482241, 0.883628],
          [110.481667, 0.883562],
          [110.481315, 0.883628],
          [110.480917, 0.883827],
          [110.480763, 0.884179],
          [110.480719, 0.884731],
          [110.480851, 0.885856],
          [110.480851, 0.88698],
          [110.480719, 0.887289],
          [110.479374, 0.888127],
          [110.479153, 0.888391],
          [110.479065, 0.888854],
          [110.479065, 0.889384],
          [110.479396, 0.890684],
          [110.479528, 0.891412],
          [110.479374, 0.891765],
          [110.479021, 0.892029],
          [110.478404, 0.892184],
          [110.477786, 0.892184],
          [110.477103, 0.89225],
          [110.475713, 0.892625],
          [110.475184, 0.892625],
          [110.474589, 0.892537],
          [110.473376, 0.891897],
          [110.472869, 0.891875],
          [110.470774, 0.892206],
          [110.470311, 0.892195],
          [110.469804, 0.892096],
          [110.4689, 0.892162],
          [110.467533, 0.892515],
          [110.467268, 0.892823],
          [110.467246, 0.893816],
          [110.46707, 0.894279],
          [110.466585, 0.895006],
          [110.465923, 0.895734],
          [110.464931, 0.898358],
          [110.464601, 0.899527],
          [110.464645, 0.900078],
          [110.464623, 0.900497],
          [110.464468, 0.901158],
          [110.464248, 0.901665],
          [110.463653, 0.902746],
          [110.463504, 0.903299],
          [110.463366, 0.904179],
          [110.463189, 0.904466],
          [110.462947, 0.90462],
          [110.462608, 0.904725],
          [110.461933, 0.90451],
          [110.461624, 0.904532],
          [110.460147, 0.905304],
          [110.459772, 0.905414],
          [110.458713, 0.905568],
          [110.458096, 0.905723],
          [110.456222, 0.906538],
          [110.455318, 0.906979],
          [110.454678, 0.90742],
          [110.453995, 0.908016],
          [110.453201, 0.908942],
          [110.452848, 0.909228],
          [110.452561, 0.909295],
          [110.452253, 0.909228],
          [110.45104, 0.908589],
          [110.450532, 0.908479],
          [110.449606, 0.908567],
          [110.449011, 0.908523],
          [110.448327, 0.908324],
          [110.446696, 0.907641],
          [110.446387, 0.907729],
          [110.44599, 0.90795],
          [110.444733, 0.909515],
          [110.444248, 0.910066],
          [110.443763, 0.910485],
          [110.443454, 0.910904],
          [110.442462, 0.912779],
          [110.441999, 0.913441],
          [110.440698, 0.914874],
          [110.439441, 0.916042],
          [110.438736, 0.916616],
          [110.438339, 0.917013],
          [110.43825, 0.917255],
          [110.438339, 0.91752],
          [110.438647, 0.917961],
          [110.438912, 0.918446],
          [110.43889, 0.919195],
          [110.438713, 0.921422],
          [110.438338, 0.922238],
          [110.438052, 0.922481],
          [110.437545, 0.922723],
          [110.436222, 0.923076],
          [110.435891, 0.923319],
          [110.435097, 0.924399],
          [110.434766, 0.92473],
          [110.434435, 0.92495],
          [110.433862, 0.925017],
          [110.433509, 0.925215],
          [110.433134, 0.925502],
          [110.433068, 0.925766],
          [110.433157, 0.926207],
          [110.433112, 0.92667],
          [110.43276, 0.928037],
          [110.432738, 0.928522],
          [110.432782, 0.928963],
          [110.432936, 0.929338],
          [110.433112, 0.929625],
          [110.433465, 0.929779],
          [110.434722, 0.929956],
          [110.435141, 0.930132],
          [110.435714, 0.930705],
          [110.436155, 0.931323],
          [110.43664, 0.931698],
          [110.437677, 0.933131],
          [110.438074, 0.934168],
          [110.438824, 0.935358],
          [110.439, 0.935755],
          [110.439243, 0.935976],
          [110.439728, 0.936086],
          [110.440235, 0.936262],
          [110.440588, 0.936593],
          [110.440874, 0.93699],
          [110.44094, 0.937475],
          [110.440896, 0.937894],
          [110.44072, 0.938225],
          [110.440476, 0.938446],
          [110.440147, 0.9386],
          [110.439243, 0.93882],
          [110.43889, 0.939041],
          [110.438625, 0.939393],
          [110.438559, 0.940077],
          [110.438581, 0.940981],
          [110.438757, 0.941819],
          [110.438846, 0.942745],
          [110.438824, 0.943451],
          [110.438581, 0.944024],
          [110.4375, 0.94506],
          [110.436486, 0.945656],
          [110.436107, 0.945748],
          [110.435317, 0.945259],
          [110.434788, 0.944862],
          [110.434435, 0.94484],
          [110.433774, 0.94495],
          [110.433112, 0.945104],
          [110.432583, 0.945281],
          [110.432363, 0.945501],
          [110.432142, 0.946009],
          [110.432098, 0.946472],
          [110.431988, 0.946758],
          [110.431679, 0.946957],
          [110.43137, 0.947045],
          [110.431107, 0.946957],
          [110.430797, 0.946626],
          [110.43051, 0.945986],
          [110.430158, 0.945391],
          [110.429695, 0.945082],
          [110.429232, 0.945038],
          [110.428813, 0.945149],
          [110.427953, 0.9457],
          [110.427423, 0.945854],
          [110.426299, 0.945942],
          [110.42599, 0.946031],
          [110.423499, 0.947552],
          [110.423279, 0.947817],
          [110.423235, 0.948192],
          [110.423543, 0.949008],
          [110.423433, 0.949272],
          [110.423279, 0.949493],
          [110.423014, 0.949581],
          [110.421493, 0.949118],
          [110.420743, 0.948456],
          [110.420324, 0.948258],
          [110.420059, 0.948236],
          [110.419817, 0.948412],
          [110.41953, 0.948831],
          [110.41931, 0.950485],
          [110.419067, 0.950661],
          [110.418736, 0.950794],
          [110.418141, 0.95086],
          [110.417634, 0.950794],
          [110.416884, 0.95086],
          [110.415473, 0.95086],
          [110.4149, 0.950948],
          [110.414569, 0.951168],
          [110.413664, 0.95216],
          [110.41329, 0.952161],
          [110.412827, 0.951896],
          [110.412187, 0.951609],
          [110.41168, 0.951543],
          [110.410445, 0.951764],
          [110.410049, 0.951742],
          [110.4091, 0.951367],
          [110.408439, 0.950882],
          [110.407998, 0.950705],
          [110.407447, 0.950749],
          [110.406939, 0.950992],
          [110.405528, 0.95269],
          [110.405352, 0.953219],
          [110.405175, 0.954035],
          [110.404933, 0.954718],
          [110.404955, 0.955049],
          [110.405153, 0.955578],
          [110.405506, 0.956173],
          [110.406013, 0.956592],
          [110.406586, 0.956945],
          [110.408152, 0.957408],
          [110.408505, 0.957695],
          [110.408681, 0.958048],
          [110.408659, 0.958445],
          [110.408505, 0.958908],
          [110.408262, 0.959393],
          [110.40813, 0.9599],
          [110.408196, 0.960297],
          [110.408703, 0.960914],
          [110.408747, 0.961179],
          [110.408659, 0.961488],
          [110.40824, 0.962193],
          [110.408108, 0.962744],
          [110.40813, 0.963119],
          [110.408306, 0.963582],
          [110.408593, 0.963891],
          [110.409056, 0.964112],
          [110.40985, 0.964398],
          [110.410159, 0.964663],
          [110.410247, 0.964905],
          [110.410158, 0.965171],
          [110.409607, 0.965964],
          [110.409563, 0.966339],
          [110.409651, 0.966824],
          [110.409651, 0.967309],
          [110.409409, 0.967904],
          [110.409078, 0.968257],
          [110.407711, 0.968632],
          [110.407138, 0.968918],
          [110.406763, 0.969271],
          [110.406608, 0.969712],
          [110.406497, 0.970414],
          [110.406498, 0.970925],
          [110.406278, 0.971322],
          [110.405197, 0.971631],
          [110.404977, 0.971873],
          [110.404866, 0.972424],
          [110.404646, 0.974122],
          [110.40469, 0.974563],
          [110.404844, 0.975137],
          [110.405484, 0.975732],
          [110.405815, 0.976217],
          [110.405903, 0.976636],
          [110.405682, 0.978202],
          [110.405484, 0.978907],
          [110.405131, 0.979834],
          [110.404844, 0.980275],
          [110.404381, 0.980782],
          [110.403918, 0.981179],
          [110.40372, 0.981465],
          [110.40372, 0.981796],
          [110.403984, 0.982656],
          [110.403918, 0.982986],
          [110.403124, 0.984045],
          [110.402772, 0.984728],
          [110.402705, 0.98561],
          [110.402794, 0.986404],
          [110.403146, 0.987198],
          [110.403587, 0.98786],
          [110.403676, 0.988212],
          [110.403631, 0.9885],
          [110.403279, 0.988698],
          [110.402683, 0.988852],
          [110.401823, 0.98883],
          [110.401118, 0.988874],
          [110.399707, 0.989469],
          [110.399133, 0.989491],
          [110.398692, 0.989646],
          [110.398097, 0.989954],
          [110.397678, 0.990329],
          [110.397391, 0.990704],
          [110.397303, 0.991123],
          [110.397347, 0.992204],
          [110.397215, 0.992601],
          [110.396465, 0.99346],
          [110.396201, 0.993902],
          [110.395936, 0.994541],
          [110.395606, 0.996129],
          [110.395363, 0.996504],
          [110.394966, 0.99668],
          [110.394547, 0.99668],
          [110.393952, 0.99657],
          [110.392695, 0.995974],
          [110.3921, 0.995908],
          [110.391218, 0.996019],
          [110.390909, 0.995886],
          [110.390402, 0.995313],
          [110.390115, 0.994806],
          [110.38974, 0.994387],
          [110.389498, 0.993946],
          [110.389189, 0.993615],
          [110.38877, 0.993372],
          [110.388439, 0.993372],
          [110.387976, 0.993571],
          [110.387028, 0.994409],
          [110.38619, 0.995004],
          [110.385396, 0.995511],
          [110.384933, 0.995666],
          [110.384492, 0.995622],
          [110.383279, 0.994982],
          [110.38264, 0.994718],
          [110.382133, 0.994673],
          [110.381582, 0.994828],
          [110.381251, 0.995004],
          [110.38081, 0.995908],
          [110.380501, 0.996085],
          [110.379796, 0.996239],
          [110.3792, 0.996305],
          [110.378913, 0.996261],
          [110.378715, 0.996107],
          [110.378561, 0.995666],
          [110.378406, 0.994872],
          [110.378164, 0.994519],
          [110.377745, 0.994232],
          [110.377414, 0.994188],
          [110.37651, 0.994409],
          [110.375826, 0.994515],
          [110.375341, 0.994519],
          [110.374989, 0.994453],
          [110.373687, 0.993505],
          [110.372982, 0.993063],
          [110.372342, 0.99249],
          [110.371791, 0.991784],
          [110.371394, 0.990902],
          [110.3706, 0.988367],
          [110.370336, 0.987838],
          [110.369917, 0.987551],
          [110.369365, 0.987353],
          [110.36877, 0.987353],
          [110.368241, 0.987551],
          [110.367712, 0.987661],
          [110.367204, 0.987573],
          [110.366874, 0.987353],
          [110.365705, 0.986228],
          [110.36533, 0.98603],
          [110.363875, 0.985787],
          [110.363324, 0.985589],
          [110.362067, 0.984663],
          [110.361317, 0.983935],
          [110.360656, 0.983472],
          [110.360126, 0.983251],
          [110.359619, 0.983207],
          [110.359112, 0.98334],
          [110.358539, 0.983626],
          [110.35823, 0.984111],
          [110.358142, 0.98464],
          [110.358539, 0.985985],
          [110.358605, 0.986404],
          [110.358473, 0.986978],
          [110.357833, 0.988764],
          [110.357546, 0.989161],
          [110.357326, 0.989205],
          [110.357105, 0.989073],
          [110.356753, 0.988565],
          [110.35629, 0.987286],
          [110.356113, 0.987022],
          [110.355783, 0.987022],
          [110.354548, 0.987882],
          [110.354217, 0.98819],
          [110.353908, 0.988301],
          [110.353688, 0.988168],
          [110.353489, 0.987948],
          [110.353291, 0.987507],
          [110.3531814, 0.9869678],
          [110.3530821, 0.9867788],
          [110.3529587, 0.9866769],
          [110.3526257, 0.9867938],
          [110.3524868, 0.9868606],
          [110.3524202, 0.9869045],
          [110.351527, 0.98797],
          [110.351064, 0.988168],
          [110.350601, 0.98819],
          [110.349653, 0.988014],
          [110.34941, 0.988058],
          [110.348991, 0.988609],
          [110.348484, 0.988962],
          [110.347999, 0.989072],
          [110.347448, 0.989094],
          [110.347095, 0.989205],
          [110.345551, 0.990528],
          [110.344515, 0.99152],
          [110.343699, 0.992424],
          [110.343236, 0.993042],
          [110.342861, 0.993835],
          [110.342619, 0.994739],
          [110.3423, 0.995296],
          [110.341935, 0.996173],
          [110.341516, 0.997937],
          [110.341516, 0.998422],
          [110.341546, 0.999484],
          [110.342293, 1.001623],
          [110.342333, 1.002067],
          [110.342272, 1.003701],
          [110.342353, 1.004105],
          [110.342515, 1.004589],
          [110.34316, 1.005417],
          [110.343362, 1.00584],
          [110.343524, 1.006607],
          [110.343423, 1.007011],
          [110.343302, 1.007314],
          [110.34314, 1.007576],
          [110.341768, 1.008706],
          [110.340799, 1.009291],
          [110.340396, 1.009735],
          [110.340073, 1.010199],
          [110.339649, 1.01135],
          [110.339346, 1.01248],
          [110.339225, 1.012782],
          [110.338983, 1.012883],
          [110.338741, 1.012863],
          [110.338216, 1.012419],
          [110.337974, 1.011733],
          [110.337812, 1.010522],
          [110.337449, 1.010038],
          [110.337207, 1.009634],
          [110.336904, 1.009271],
          [110.336723, 1.008827],
          [110.336642, 1.008444],
          [110.336803, 1.007919],
          [110.337288, 1.007051],
          [110.337853, 1.00576],
          [110.338418, 1.00471],
          [110.338559, 1.004307],
          [110.338499, 1.003964],
          [110.338357, 1.003742],
          [110.338115, 1.0036],
          [110.33751, 1.003358],
          [110.336803, 1.002773],
          [110.336521, 1.002612],
          [110.335976, 1.002551],
          [110.335653, 1.002712],
          [110.33533, 1.002995],
          [110.335007, 1.003177],
          [110.334725, 1.00352],
          [110.33422, 1.004287],
          [110.333494, 1.005881],
          [110.333231, 1.006123],
          [110.332949, 1.006183],
          [110.331738, 1.006042],
          [110.331415, 1.005861],
          [110.331374, 1.005497],
          [110.331415, 1.004973],
          [110.331374, 1.004529],
          [110.331233, 1.004287],
          [110.330951, 1.004105],
          [110.3298, 1.0036],
          [110.328569, 1.002813],
          [110.328509, 1.00241],
          [110.328569, 1.002147],
          [110.328791, 1.001825],
          [110.329235, 1.001381],
          [110.329498, 1.001219],
          [110.329558, 1.001017],
          [110.329599, 1.000634],
          [110.329457, 1.000311],
          [110.329336, 0.999827],
          [110.329195, 0.999524],
          [110.328912, 0.9991],
          [110.328004, 0.998151],
          [110.326571, 0.996153],
          [110.326208, 0.99579],
          [110.325683, 0.995386],
          [110.325139, 0.994801],
          [110.324573, 0.993913],
          [110.324452, 0.99353],
          [110.324291, 0.993429],
          [110.324089, 0.993489],
          [110.323948, 0.99359],
          [110.323706, 0.993853],
          [110.323322, 0.994398],
          [110.322071, 0.996577],
          [110.321203, 0.997828],
          [110.320173, 0.999483],
          [110.319366, 1.00033],
          [110.317752, 1.001662],
          [110.31755, 1.001662],
          [110.316884, 1.00146],
          [110.316541, 1.00142],
          [110.315673, 1.001622],
          [110.315209, 1.001682],
          [110.313796, 1.001662],
          [110.313392, 1.001723],
          [110.313049, 1.001965],
          [110.312464, 1.002893],
          [110.312242, 1.002994],
          [110.31204, 1.002994],
          [110.311859, 1.002914],
          [110.311778, 1.002752],
          [110.311677, 1.002046],
          [110.311556, 1.001743],
          [110.31091, 1.000936],
          [110.310486, 1.000714],
          [110.309316, 1.000673],
          [110.308791, 1.000875],
          [110.308549, 1.001117],
          [110.308529, 1.00144],
          [110.30863, 1.002066],
          [110.30867, 1.002752],
          [110.308549, 1.003277],
          [110.308408, 1.003822],
          [110.308125, 1.004064],
          [110.307843, 1.004145],
          [110.307621, 1.004145],
          [110.307358, 1.004044],
          [110.306551, 1.003882],
          [110.305663, 1.003822],
          [110.30532, 1.003721],
          [110.305179, 1.003378],
          [110.305098, 1.002833],
          [110.305098, 1.00255],
          [110.305239, 1.002227],
          [110.305602, 1.001844],
          [110.305764, 1.001541],
          [110.305804, 1.001178],
          [110.305764, 0.999483],
          [110.305865, 0.999059],
          [110.305643, 0.998777],
          [110.305279, 0.998595],
          [110.305098, 0.998635],
          [110.304835, 0.998777],
          [110.304553, 0.998999],
          [110.304169, 0.999079],
          [110.303625, 0.998958],
          [110.30312, 0.998756],
          [110.302272, 0.99809],
          [110.301808, 0.996759],
          [110.301546, 0.996274],
          [110.301223, 0.995911],
          [110.300961, 0.995749],
          [110.300517, 0.995649],
          [110.299851, 0.995608],
          [110.298357, 0.99579],
          [110.297994, 0.996052],
          [110.297308, 0.99692],
          [110.296884, 0.997182],
          [110.296016, 0.997344],
          [110.294563, 0.997102],
          [110.293998, 0.996839],
          [110.293191, 0.996214],
          [110.292848, 0.996072],
          [110.292484, 0.996052],
          [110.291919, 0.996173],
          [110.291132, 0.996415],
          [110.290628, 0.996436],
          [110.290224, 0.996335],
          [110.289639, 0.995931],
          [110.288993, 0.995346],
          [110.288347, 0.994983],
          [110.287903, 0.994801],
          [110.285259, 0.994841],
          [110.284371, 0.99474],
          [110.283302, 0.9947],
          [110.281485, 0.994438],
          [110.280719, 0.994458],
          [110.280053, 0.994599],
          [110.279487, 0.994861],
          [110.278337, 0.995225],
          [110.277732, 0.995608],
          [110.277288, 0.995971],
          [110.276965, 0.996395],
          [110.276622, 0.997021],
          [110.2764, 0.997546],
          [110.2764, 0.99801],
          [110.276521, 0.998514],
          [110.277046, 0.999281],
          [110.277694, 0.999717],
          [110.277928, 1.000013],
          [110.278099, 1.000402],
          [110.278145, 1.000946],
          [110.278099, 1.001848],
          [110.278208, 1.00247],
          [110.278487, 1.003325],
          [110.278736, 1.004398],
          [110.278907, 1.004833],
          [110.279467, 1.005564],
          [110.279747, 1.006637],
          [110.280307, 1.007896],
          [110.280478, 1.008145],
          [110.2811, 1.008674],
          [110.281333, 1.008954],
          [110.281488, 1.009327],
          [110.281597, 1.009747],
          [110.281644, 1.010213],
          [110.281597, 1.010447],
          [110.28138, 1.010898],
          [110.281006, 1.011395],
          [110.280789, 1.011769],
          [110.280649, 1.012111],
          [110.280493, 1.01337],
          [110.280524, 1.013728],
          [110.280633, 1.014023],
          [110.280773, 1.014194],
          [110.281037, 1.014303],
          [110.281722, 1.014474],
          [110.282017, 1.014676],
          [110.282639, 1.015267],
          [110.282826, 1.015532],
          [110.282934, 1.015889],
          [110.282981, 1.01676],
          [110.283074, 1.017226],
          [110.28323, 1.017553],
          [110.283292, 1.017895],
          [110.28323, 1.018159],
          [110.28295, 1.018626],
          [110.282655, 1.019357],
          [110.282281, 1.021674],
          [110.282173, 1.022031],
          [110.28197, 1.022482],
          [110.279918, 1.026323],
          [110.279187, 1.027288],
          [110.277897, 1.028625],
          [110.277306, 1.029309],
          [110.276995, 1.029915],
          [110.276746, 1.030257],
          [110.276575, 1.030693],
          [110.276559, 1.031284],
          [110.276668, 1.031517],
          [110.277244, 1.031952],
          [110.278425, 1.03259],
          [110.278783, 1.032854],
          [110.279498, 1.033647],
          [110.279871, 1.034549],
          [110.280105, 1.034984],
          [110.280431, 1.035342],
          [110.282157, 1.036757],
          [110.282359, 1.037083],
          [110.282592, 1.037923],
          [110.282763, 1.038327],
          [110.282997, 1.038607],
          [110.283261, 1.038778],
          [110.28351, 1.038809],
          [110.284349, 1.038778],
          [110.284629, 1.03884],
          [110.284925, 1.03898],
          [110.285127, 1.039167],
          [110.285609, 1.039773],
          [110.286355, 1.040224],
          [110.286511, 1.040458],
          [110.286526, 1.0408],
          [110.286433, 1.041188],
          [110.286464, 1.041639],
          [110.286682, 1.042822],
          [110.286697, 1.043101],
          [110.286651, 1.043552],
          [110.286464, 1.044174],
          [110.286371, 1.044377],
          [110.28466, 1.045978],
          [110.28323, 1.047549],
          [110.282888, 1.048077],
          [110.282655, 1.048544],
          [110.282157, 1.05013],
          [110.281955, 1.050348],
          [110.280726, 1.051405],
          [110.280384, 1.051669],
          [110.279871, 1.051965],
          [110.279389, 1.052042],
          [110.278254, 1.051871],
          [110.277694, 1.05184],
          [110.276606, 1.051452],
          [110.276046, 1.051358],
          [110.275455, 1.05142],
          [110.274724, 1.051716],
          [110.27334, 1.0524],
          [110.272749, 1.052773],
          [110.27222, 1.053037],
          [110.271489, 1.053255],
          [110.271147, 1.053302],
          [110.270137, 1.053239],
          [110.269826, 1.053068],
          [110.269484, 1.052633],
          [110.269002, 1.051731],
          [110.268395, 1.050736],
          [110.268131, 1.050643],
          [110.267804, 1.050627],
          [110.267509, 1.05072],
          [110.266809, 1.051218],
          [110.265892, 1.052353],
          [110.26513, 1.053737],
          [110.264119, 1.054951],
          [110.262579, 1.056443],
          [110.262377, 1.056925],
          [110.262222, 1.057563],
          [110.262004, 1.058558],
          [110.261911, 1.059335],
          [110.261646, 1.059989],
          [110.261398, 1.061046],
          [110.261304, 1.061295],
          [110.261382, 1.061575],
          [110.261569, 1.061715],
          [110.262097, 1.061901],
          [110.262875, 1.06229],
          [110.263652, 1.062787],
          [110.264524, 1.063222],
          [110.265519, 1.063533],
          [110.266094, 1.06428],
          [110.266856, 1.065446],
          [110.267245, 1.06571],
          [110.267618, 1.065835],
          [110.267727, 1.06599],
          [110.26768, 1.066223],
          [110.267525, 1.066472],
          [110.266903, 1.067048],
          [110.26639, 1.067312],
          [110.265254, 1.06753],
          [110.264632, 1.067747],
          [110.26443, 1.068012],
          [110.264368, 1.068463],
          [110.264492, 1.069613],
          [110.264554, 1.071106],
          [110.264103, 1.073718],
          [110.263994, 1.074014],
          [110.263714, 1.07434],
          [110.263481, 1.074496],
          [110.262362, 1.074776],
          [110.261569, 1.075024],
          [110.260682, 1.07518],
          [110.259469, 1.075553],
          [110.257526, 1.076502],
          [110.256375, 1.077124],
          [110.255566, 1.077512],
          [110.254696, 1.077854],
          [110.252675, 1.078959],
          [110.252131, 1.079348],
          [110.251773, 1.079768],
          [110.251509, 1.080265],
          [110.250917, 1.082504],
          [110.250528, 1.083157],
          [110.249627, 1.084292],
          [110.248414, 1.086189],
          [110.24818, 1.086936],
          [110.248072, 1.087542],
          [110.247823, 1.088397],
          [110.247683, 1.089082],
          [110.247745, 1.089564],
          [110.248274, 1.091103],
          [110.248492, 1.092036],
          [110.24857, 1.092782],
          [110.248616, 1.093436],
          [110.248585, 1.094197],
          [110.248383, 1.095193],
          [110.24829, 1.095472],
          [110.248259, 1.095939],
          [110.248399, 1.097074],
          [110.248368, 1.097712],
          [110.24829, 1.098209],
          [110.248057, 1.098816],
          [110.247777, 1.099469],
          [110.246782, 1.101412],
          [110.246657, 1.101832],
          [110.246626, 1.102205],
          [110.247372, 1.106031],
          [110.247574, 1.106388],
          [110.248616, 1.107306],
          [110.248678, 1.107617],
          [110.248678, 1.107866],
          [110.2486, 1.108084],
          [110.248336, 1.108348],
          [110.245428, 1.110541],
          [110.245226, 1.110789],
          [110.245148, 1.1111],
          [110.245133, 1.11138],
          [110.245273, 1.11194],
          [110.245319, 1.112313],
          [110.245304, 1.112593],
          [110.245211, 1.112858],
          [110.2449, 1.113106],
          [110.243811, 1.113526],
          [110.243314, 1.113961],
          [110.242956, 1.114754],
          [110.242676, 1.115516],
          [110.242132, 1.116387],
          [110.241385, 1.117429],
          [110.240997, 1.117662],
          [110.240639, 1.117787],
          [110.239488, 1.117787],
          [110.239022, 1.117864],
          [110.238695, 1.118082],
          [110.238493, 1.118362],
          [110.238042, 1.118673],
          [110.237684, 1.118766],
          [110.237062, 1.118735],
          [110.235865, 1.118595],
          [110.23501, 1.118642],
          [110.233704, 1.119171],
          [110.233019, 1.119513],
          [110.232413, 1.119964],
          [110.232071, 1.12029],
          [110.231869, 1.120337],
          [110.23162, 1.120259],
          [110.229847, 1.119264],
          [110.229163, 1.119015],
          [110.22851, 1.118689],
          [110.227935, 1.118269],
          [110.226162, 1.116605],
          [110.225525, 1.115952],
          [110.224716, 1.114879],
          [110.224436, 1.114723],
          [110.22313, 1.114568],
          [110.221855, 1.114568],
          [110.219212, 1.114692],
          [110.217439, 1.114676],
          [110.216755, 1.114754],
          [110.216382, 1.114879],
          [110.215868, 1.115096],
          [110.21492, 1.115625],
          [110.21366, 1.116123],
          [110.211809, 1.117367],
          [110.211001, 1.117974],
          [110.210394, 1.118207],
          [110.209601, 1.118269],
          [110.209244, 1.118425],
          [110.209119, 1.118891],
          [110.209088, 1.11942],
          [110.208575, 1.122732],
          [110.208575, 1.124131],
          [110.208637, 1.125437],
          [110.208591, 1.126059],
          [110.208528, 1.126339],
          [110.208342, 1.126713],
          [110.207984, 1.127163],
          [110.207642, 1.12749],
          [110.207347, 1.127972],
          [110.207284, 1.12833],
          [110.207347, 1.128749],
          [110.207766, 1.129807],
          [110.207906, 1.130537],
          [110.207844, 1.130786],
          [110.207704, 1.131004],
          [110.207082, 1.131626],
          [110.205994, 1.132279],
          [110.205745, 1.132574],
          [110.205636, 1.132792],
          [110.205621, 1.132994],
          [110.205745, 1.133352],
          [110.206258, 1.134036],
          [110.206414, 1.134409],
          [110.206429, 1.134736],
          [110.206383, 1.135031],
          [110.206118, 1.136306],
          [110.206134, 1.136555],
          [110.206087, 1.13699],
          [110.206134, 1.137255],
          [110.206818, 1.137752],
          [110.207347, 1.138017],
          [110.209477, 1.138825],
          [110.211716, 1.139774],
          [110.211965, 1.140023],
          [110.212105, 1.14024],
          [110.212152, 1.140489],
          [110.212027, 1.141453],
          [110.212074, 1.141997],
          [110.212385, 1.143801],
          [110.212494, 1.14419],
          [110.213287, 1.14562],
          [110.213427, 1.146273],
          [110.213349, 1.146896],
          [110.213007, 1.147767],
          [110.212836, 1.148466],
          [110.212805, 1.149197],
          [110.212602, 1.150161],
          [110.212323, 1.150674],
          [110.211763, 1.151514],
          [110.21111, 1.152229],
          [110.210565, 1.152758],
          [110.209182, 1.153691],
          [110.208093, 1.154748],
          [110.207176, 1.155868],
          [110.206398, 1.157158],
          [110.206165, 1.158122],
          [110.206165, 1.15834],
          [110.206911, 1.159304],
          [110.207067, 1.159631],
          [110.207129, 1.163161],
          [110.207036, 1.163845],
          [110.206896, 1.164156],
          [110.206632, 1.164545],
          [110.206181, 1.165011],
          [110.205901, 1.165229],
          [110.204921, 1.165586],
          [110.204548, 1.165618],
          [110.204237, 1.165509],
          [110.203802, 1.165167],
          [110.203444, 1.165151],
          [110.202993, 1.165229],
          [110.202822, 1.165338],
          [110.202713, 1.165555],
          [110.202418, 1.166908],
          [110.202278, 1.167079],
          [110.201765, 1.167297],
          [110.200941, 1.167421],
          [110.200676, 1.167515],
          [110.20035, 1.167748],
          [110.200132, 1.168012],
          [110.199992, 1.168587],
          [110.199852, 1.168836],
          [110.199634, 1.169085],
          [110.199168, 1.169349],
          [110.19895, 1.169614],
          [110.198577, 1.17064],
          [110.198453, 1.171184],
          [110.198142, 1.172025],
          [110.197909, 1.17288],
          [110.197722, 1.173237],
          [110.197364, 1.173486],
          [110.196742, 1.173813],
          [110.195841, 1.174217],
          [110.195436, 1.174497],
          [110.19525, 1.174808],
          [110.195094, 1.175243],
          [110.194892, 1.176394],
          [110.194876, 1.178089],
          [110.194799, 1.178586],
          [110.194597, 1.179053],
          [110.193959, 1.180032],
          [110.192684, 1.182271],
          [110.192202, 1.183033],
          [110.191844, 1.183313],
          [110.191518, 1.183453],
          [110.191098, 1.183531],
          [110.190709, 1.183453],
          [110.189792, 1.183002],
          [110.188532, 1.1828],
          [110.18595, 1.182909],
          [110.185313, 1.182847],
          [110.184535, 1.182629],
          [110.183618, 1.182613],
          [110.183058, 1.182738],
          [110.182576, 1.18308],
          [110.18155, 1.183982],
          [110.180928, 1.184277],
          [110.180322, 1.184464],
          [110.177601, 1.18504],
          [110.177025, 1.185242],
          [110.173154, 1.187636],
          [110.171505, 1.188849],
          [110.170759, 1.189471],
          [110.170277, 1.190016],
          [110.168426, 1.193266],
          [110.167913, 1.193856],
          [110.167587, 1.194105],
          [110.167151, 1.194323],
          [110.166032, 1.194712],
          [110.165363, 1.195054],
          [110.163435, 1.196593],
          [110.162657, 1.197044],
          [110.161304, 1.198008],
          [110.1609, 1.198195],
          [110.160434, 1.198366],
          [110.159812, 1.198459],
          [110.159283, 1.198459],
          [110.158334, 1.198335],
          [110.157961, 1.198366],
          [110.156935, 1.198568],
          [110.155489, 1.199143],
          [110.155069, 1.199143],
          [110.154789, 1.199097],
          [110.1543598, 1.1988011],
          [110.153887, 1.198475],
          [110.15353, 1.198133],
          [110.152814, 1.197759],
          [110.152488, 1.197806],
          [110.152239, 1.197915],
          [110.151104, 1.198615],
          [110.15056, 1.198894],
          [110.15, 1.199019],
          [110.149238, 1.19905],
          [110.148585, 1.199003],
          [110.147761, 1.199097],
          [110.147325, 1.199237],
          [110.14689, 1.199563],
          [110.146284, 1.200138],
          [110.145739, 1.200449],
          [110.145195, 1.200558],
          [110.143111, 1.200418],
          [110.142101, 1.200294],
          [110.140359, 1.199983],
          [110.13955, 1.199641],
          [110.138772, 1.19919],
          [110.137466, 1.198133],
          [110.136704, 1.197588],
          [110.136067, 1.197479],
          [110.1356, 1.197339],
          [110.13459, 1.196889],
          [110.134294, 1.196826],
          [110.132708, 1.197075],
          [110.132117, 1.197075],
          [110.131542, 1.196951],
          [110.130593, 1.196484],
          [110.130158, 1.196064],
          [110.12949, 1.195225],
          [110.129241, 1.1951],
          [110.128992, 1.195054],
          [110.128028, 1.195007],
          [110.127624, 1.194883],
          [110.127359, 1.194774],
          [110.126908, 1.194292],
          [110.12652, 1.193996],
          [110.126131, 1.193825],
          [110.125711, 1.193779],
          [110.1254, 1.193794],
          [110.124918, 1.194011],
          [110.122228, 1.195753],
          [110.121419, 1.196173],
          [110.119615, 1.195971],
          [110.118418, 1.195986],
          [110.11663, 1.19566],
          [110.115355, 1.19566],
          [110.114422, 1.195877],
          [110.112447, 1.196468],
          [110.107782, 1.198101],
          [110.107222, 1.198366],
          [110.10688, 1.198397],
          [110.106569, 1.198303],
          [110.106305, 1.198148],
          [110.106072, 1.197852],
          [110.105916, 1.197557],
          [110.105388, 1.197028],
          [110.105092, 1.196904],
          [110.10447, 1.196748],
          [110.103833, 1.196655],
          [110.103335, 1.19667],
          [110.101314, 1.197246],
          [110.100443, 1.19737],
          [110.097582, 1.197557],
          [110.097068, 1.197666],
          [110.096757, 1.197883],
          [110.096555, 1.19835],
          [110.096259, 1.199423],
          [110.096073, 1.199625],
          [110.095793, 1.199796],
          [110.095389, 1.199983],
          [110.094953, 1.200123],
          [110.094735, 1.20031],
          [110.094642, 1.200527],
          [110.094658, 1.200776],
          [110.094813, 1.20174],
          [110.094953, 1.202207],
          [110.094984, 1.20258],
          [110.094829, 1.203186],
          [110.094145, 1.203948],
          [110.093196, 1.204835],
          [110.092994, 1.205348],
          [110.092807, 1.20639],
          [110.092652, 1.206607],
          [110.09245, 1.206716],
          [110.092108, 1.206654],
          [110.091672, 1.206296],
          [110.091423, 1.206156],
          [110.091035, 1.20611],
          [110.090832, 1.206172],
          [110.090646, 1.20639],
          [110.090475, 1.206763],
          [110.090366, 1.207229],
          [110.090288, 1.207991],
          [110.090257, 1.208862],
          [110.090148, 1.209359],
          [110.090008, 1.209608],
          [110.088966, 1.210806],
          [110.087521, 1.212811],
          [110.087039, 1.213666],
          [110.08679, 1.214443],
          [110.086448, 1.215967],
          [110.086308, 1.216309],
          [110.086059, 1.216683],
          [110.085826, 1.216854],
          [110.085453, 1.216823],
          [110.084986, 1.216636],
          [110.082607, 1.215252],
          [110.078782, 1.213277],
          [110.077958, 1.212795],
          [110.07746, 1.212593],
          [110.077025, 1.2125],
          [110.076123, 1.212407],
          [110.075765, 1.212267],
          [110.075236, 1.211909],
          [110.074801, 1.211707],
          [110.06953, 1.210307],
          [110.067679, 1.209903],
          [110.067229, 1.209685],
          [110.066342, 1.208923],
          [110.065767, 1.208706],
          [110.064601, 1.208426],
          [110.064072, 1.208473],
          [110.063714, 1.208644],
          [110.063528, 1.208923],
          [110.063403, 1.209343],
          [110.063279, 1.21009],
          [110.06317, 1.21166],
          [110.062781, 1.21292],
          [110.061957, 1.214569],
          [110.06118, 1.215766],
          [110.060713, 1.216357],
          [110.060153, 1.21743],
          [110.059453, 1.218363],
          [110.058831, 1.21942],
          [110.058349, 1.220089],
          [110.058225, 1.220462],
          [110.058194, 1.220757],
          [110.058241, 1.220959],
          [110.058365, 1.221193],
          [110.058723, 1.221581],
          [110.06118, 1.223649],
          [110.061366, 1.224023],
          [110.061552, 1.224567],
          [110.06177, 1.224784],
          [110.062501, 1.22508],
          [110.06348, 1.22522],
          [110.064413, 1.225111],
          [110.065082, 1.225127],
          [110.065813, 1.22522],
          [110.066637, 1.225235],
          [110.067259, 1.225344],
          [110.067477, 1.225453],
          [110.06771, 1.22564],
          [110.067974, 1.225982],
          [110.068783, 1.227506],
          [110.069001, 1.228532],
          [110.068985, 1.228936],
          [110.068907, 1.229247],
          [110.068627, 1.229651],
          [110.067632, 1.23074],
          [110.067368, 1.231378],
          [110.067197, 1.232575],
          [110.067228, 1.233197],
          [110.067368, 1.233772],
          [110.067632, 1.234488],
          [110.068052, 1.235389],
          [110.068363, 1.23626],
          [110.068627, 1.237224],
          [110.068861, 1.238981],
          [110.06883, 1.240132],
          [110.068736, 1.24035],
          [110.068145, 1.240785],
          [110.067834, 1.241127],
          [110.067632, 1.241562],
          [110.067586, 1.241827],
          [110.067648, 1.24262],
          [110.067617, 1.243522],
          [110.067135, 1.24489],
          [110.066684, 1.24587],
          [110.06631, 1.247145],
          [110.06586, 1.248202],
          [110.065564, 1.249431],
          [110.064942, 1.251048],
          [110.064818, 1.251499],
          [110.064818, 1.251965],
          [110.064865, 1.252276],
          [110.065378, 1.253085],
          [110.066031, 1.253738],
          [110.066606, 1.254469],
          [110.066637, 1.254717],
          [110.066606, 1.255013],
          [110.066497, 1.255293],
          [110.06614, 1.255728],
          [110.065766, 1.255821],
          [110.06446, 1.256008],
          [110.064134, 1.256179],
          [110.06373, 1.25649],
          [110.063403, 1.256894],
          [110.062905, 1.257734],
          [110.062579, 1.258496],
          [110.062221, 1.259149],
          [110.06205, 1.259351],
          [110.061226, 1.259756],
          [110.061086, 1.259896],
          [110.061008, 1.260144],
          [110.061086, 1.260611],
          [110.061273, 1.260891],
          [110.06205, 1.261777],
          [110.062672, 1.262243],
          [110.063139, 1.262694],
          [110.063994, 1.263176],
          [110.064414, 1.263254],
          [110.064973, 1.263285],
          [110.06516, 1.263472],
          [110.065222, 1.263721],
          [110.065191, 1.263969],
          [110.064896, 1.264529],
          [110.064554, 1.264716],
          [110.064134, 1.264825],
          [110.063807, 1.264809],
          [110.063325, 1.2647],
          [110.062299, 1.264389],
          [110.061584, 1.264296],
          [110.061226, 1.264327],
          [110.060931, 1.264514],
          [110.060651, 1.264934],
          [110.060371, 1.265478],
          [110.060169, 1.266038],
          [110.059749, 1.26767],
          [110.059267, 1.269101],
          [110.058738, 1.26963],
          [110.057976, 1.269941],
          [110.057681, 1.269925],
          [110.056608, 1.269443],
          [110.055084, 1.268914],
          [110.054026, 1.268386],
          [110.053482, 1.268013],
          [110.052767, 1.267219],
          [110.05244, 1.266955],
          [110.05202, 1.266722],
          [110.051694, 1.266473],
          [110.051476, 1.266193],
          [110.051103, 1.265276],
          [110.050885, 1.26498],
          [110.050668, 1.264825],
          [110.050139, 1.264747],
          [110.049175, 1.264732],
          [110.047838, 1.264887],
          [110.046982, 1.265058],
          [110.046376, 1.265276],
          [110.04608, 1.265493],
          [110.045972, 1.266224],
          [110.046003, 1.266504],
          [110.046096, 1.266737],
          [110.046298, 1.267017],
          [110.04706, 1.267624],
          [110.047185, 1.267935],
          [110.047309, 1.268464],
          [110.047231, 1.269863],
          [110.047029, 1.271216],
          [110.046874, 1.271869],
          [110.046702, 1.272087],
          [110.04636, 1.272382],
          [110.045614, 1.272693],
          [110.045225, 1.272631],
          [110.04493, 1.27246],
          [110.04465, 1.272366],
          [110.044401, 1.272351],
          [110.044246, 1.272398],
          [110.044121, 1.272569],
          [110.044028, 1.272802],
          [110.043857, 1.273377],
          [110.043002, 1.274497],
          [110.042551, 1.274808],
          [110.041727, 1.275103],
          [110.040778, 1.275585],
          [110.03955, 1.276021],
          [110.039021, 1.276114],
          [110.038181, 1.276083],
          [110.03773, 1.276021],
          [110.037481, 1.275927],
          [110.037264, 1.275927],
          [110.036767, 1.276472],
          [110.036549, 1.276798],
          [110.036005, 1.277234],
          [110.035056, 1.277824],
          [110.027203, 1.282225],
          [110.025415, 1.283189],
          [110.023953, 1.284107],
          [110.023658, 1.284231],
          [110.022103, 1.284511],
          [110.020688, 1.284822],
          [110.020346, 1.284946],
          [110.020004, 1.285148],
          [110.019708, 1.285444],
          [110.018464, 1.287325],
          [110.016661, 1.290996],
          [110.016272, 1.29154],
          [110.015805, 1.291866],
          [110.015199, 1.292131],
          [110.014064, 1.292473],
          [110.011825, 1.292877],
          [110.010487, 1.293328],
          [110.009119, 1.293981],
          [110.008388, 1.294401],
          [110.006942, 1.295132],
          [110.006336, 1.295256],
          [110.003583, 1.295489],
          [110.001593, 1.296189],
          [110.001048, 1.296516],
          [109.999867, 1.297029],
          [109.999447, 1.297122],
          [109.999214, 1.297091],
          [109.998856, 1.296967],
          [109.997581, 1.296391],
          [109.996663, 1.296049],
          [109.996026, 1.295723],
          [109.994549, 1.294587],
          [109.993911, 1.294276],
          [109.993118, 1.294043],
          [109.992605, 1.294074],
          [109.992061, 1.294214],
          [109.989075, 1.295723],
          [109.988779, 1.295816],
          [109.988391, 1.295832],
          [109.987473, 1.295707],
          [109.987193, 1.295801],
          [109.985732, 1.29664],
          [109.984799, 1.297231],
          [109.984208, 1.297542],
          [109.983819, 1.29762],
          [109.983477, 1.297589],
          [109.982078, 1.2972],
          [109.981207, 1.296765],
          [109.980974, 1.296765],
          [109.980772, 1.296858],
          [109.980539, 1.29706],
          [109.980274, 1.297433],
          [109.979388, 1.29832],
          [109.979186, 1.298646],
          [109.976573, 1.309422],
          [109.97452, 1.317507],
          [109.970882, 1.332389],
          [109.968752, 1.340786],
          [109.968674, 1.341703],
          [109.969421, 1.343413],
          [109.969327, 1.344004],
          [109.969234, 1.344207],
          [109.969094, 1.344362],
          [109.968814, 1.344549],
          [109.968363, 1.344969],
          [109.968254, 1.345342],
          [109.967897, 1.346166],
          [109.967384, 1.347161],
          [109.967197, 1.347674],
          [109.966979, 1.348498],
          [109.967026, 1.348763],
          [109.967181, 1.348996],
          [109.967772, 1.349416],
          [109.967959, 1.349634],
          [109.968037, 1.349867],
          [109.967974, 1.351158],
          [109.967896, 1.35178],
          [109.967492, 1.353086],
          [109.967352, 1.353848],
          [109.967197, 1.354081],
          [109.966357, 1.354765],
          [109.965968, 1.355029],
          [109.965844, 1.355371],
          [109.965813, 1.355714],
          [109.966046, 1.356693],
          [109.966201, 1.357097],
          [109.966217, 1.357331],
          [109.966061, 1.357968],
          [109.965828, 1.35845],
          [109.965548, 1.358947],
          [109.965206, 1.359414],
          [109.964817, 1.359834],
          [109.964087, 1.360471],
          [109.963433, 1.360844],
          [109.963107, 1.361311],
          [109.963014, 1.361793],
          [109.963014, 1.36212],
          [109.963185, 1.362477],
          [109.963449, 1.362711],
          [109.964055, 1.362975],
          [109.964351, 1.363271],
          [109.964553, 1.363613],
          [109.964646, 1.364017],
          [109.964444, 1.365743],
          [109.964351, 1.367049],
          [109.964055, 1.368122],
          [109.963869, 1.368417],
          [109.963511, 1.368759],
          [109.963216, 1.369226],
          [109.962796, 1.370143],
          [109.962609, 1.370765],
          [109.962469, 1.371076],
          [109.962065, 1.371636],
          [109.961987, 1.371978],
          [109.961972, 1.3726],
          [109.96205, 1.372834],
          [109.962236, 1.373145],
          [109.962796, 1.373627],
          [109.963309, 1.373642],
          [109.963947, 1.373456],
          [109.964335, 1.373502],
          [109.964522, 1.373596],
          [109.964988, 1.374233],
          [109.965408, 1.374498],
          [109.965595, 1.374746],
          [109.966062, 1.375726],
          [109.966186, 1.37641],
          [109.966746, 1.377374],
          [109.966839, 1.377732],
          [109.966839, 1.378789],
          [109.967275, 1.37966],
          [109.967259, 1.379862],
          [109.96715, 1.380033],
          [109.966762, 1.380453],
          [109.966637, 1.380655],
          [109.966622, 1.380904],
          [109.966699, 1.381075],
          [109.967275, 1.381759],
          [109.967321, 1.38193],
          [109.967321, 1.382257],
          [109.967228, 1.382443],
          [109.967135, 1.382848],
          [109.967181, 1.383034],
          [109.96729, 1.383236],
          [109.967446, 1.383361],
          [109.967741, 1.383812],
          [109.968099, 1.383827],
          [109.968285, 1.383952],
          [109.968363, 1.384418],
          [109.968425, 1.384589],
          [109.968503, 1.384667],
          [109.968627, 1.384714],
          [109.969016, 1.384667],
          [109.969343, 1.384776],
          [109.969934, 1.385087],
          [109.9704, 1.385398],
          [109.97068, 1.385678],
          [109.970851, 1.385957],
          [109.970898, 1.38616],
          [109.970835, 1.3863],
          [109.970742, 1.386377],
          [109.970073, 1.386331],
          [109.969825, 1.38644],
          [109.969638, 1.386579],
          [109.969436, 1.386875],
          [109.969296, 1.387233],
          [109.969234, 1.387466],
          [109.969218, 1.38815],
          [109.969094, 1.388415],
          [109.968954, 1.388897],
          [109.968861, 1.389021],
          [109.968192, 1.389596],
          [109.966886, 1.390312],
          [109.966653, 1.390529],
          [109.966233, 1.391307],
          [109.966108, 1.391353],
          [109.965782, 1.391245],
          [109.965751, 1.39112],
          [109.965595, 1.391011],
          [109.965409, 1.390949],
          [109.965144, 1.390934],
          [109.964865, 1.39098],
          [109.964647, 1.391073],
          [109.964538, 1.391198],
          [109.964507, 1.391307],
          [109.9646, 1.39154],
          [109.9646, 1.391758],
          [109.96446, 1.392022],
          [109.964305, 1.392115],
          [109.963901, 1.392224],
          [109.963683, 1.392457],
          [109.963279, 1.392784],
          [109.962999, 1.393095],
          [109.962999, 1.393421],
          [109.963185, 1.393764],
          [109.963154, 1.394494],
          [109.963418, 1.394712],
          [109.963512, 1.394868],
          [109.963558, 1.395194],
          [109.964056, 1.395676],
          [109.964118, 1.396034],
          [109.964056, 1.39636],
          [109.963947, 1.396578],
          [109.963729, 1.396718],
          [109.963138, 1.396811],
          [109.962936, 1.396905],
          [109.962719, 1.397091],
          [109.962408, 1.397542],
          [109.962299, 1.397822],
          [109.962236, 1.398288],
          [109.961894, 1.398631],
          [109.961879, 1.399035],
          [109.961832, 1.399221],
          [109.961723, 1.39933],
          [109.961412, 1.399859],
          [109.961366, 1.400201],
          [109.961537, 1.400776],
          [109.961443, 1.401056],
          [109.961443, 1.40129],
          [109.961817, 1.401772],
          [109.962159, 1.402098],
          [109.962221, 1.402316],
          [109.962159, 1.402813],
          [109.962174, 1.403109],
          [109.962019, 1.403389],
          [109.961863, 1.403482],
          [109.961552, 1.403498],
          [109.961288, 1.403093],
          [109.96121, 1.403047],
          [109.960946, 1.403062],
          [109.960542, 1.403389],
          [109.960417, 1.403435],
          [109.960215, 1.40342],
          [109.960075, 1.403342],
          [109.960013, 1.403218],
          [109.959997, 1.403062],
          [109.960075, 1.402736],
          [109.960059, 1.402611],
          [109.95978, 1.402425],
          [109.959453, 1.40202],
          [109.959266, 1.402067],
          [109.959033, 1.402456],
          [109.958924, 1.402565],
          [109.958738, 1.402611],
          [109.958225, 1.402549],
          [109.958022, 1.402627],
          [109.957883, 1.402829],
          [109.957634, 1.403016],
          [109.957556, 1.403171],
          [109.957572, 1.403311],
          [109.957743, 1.403498],
          [109.957883, 1.403575],
          [109.957976, 1.403746],
          [109.958007, 1.403902],
          [109.957976, 1.404073],
          [109.95782, 1.404197],
          [109.957245, 1.404462],
          [109.957059, 1.404648],
          [109.956857, 1.404742],
          [109.956546, 1.404679],
          [109.956343, 1.404555],
          [109.956126, 1.404524],
          [109.956017, 1.404353],
          [109.95583, 1.404291],
          [109.955706, 1.404368],
          [109.955613, 1.404555],
          [109.955457, 1.405457],
          [109.955364, 1.405597],
          [109.955224, 1.405659],
          [109.954788, 1.405488],
          [109.954602, 1.405535],
          [109.954555, 1.40597],
          [109.954446, 1.406094],
          [109.95426, 1.406157],
          [109.953964, 1.406094],
          [109.953918, 1.406001],
          [109.953995, 1.405628],
          [109.954104, 1.405457],
          [109.954135, 1.405317],
          [109.954058, 1.405115],
          [109.953918, 1.40513],
          [109.953778, 1.405348],
          [109.953467, 1.405395],
          [109.95342, 1.405332],
          [109.953311, 1.405317],
          [109.953233, 1.405535],
          [109.95314, 1.405597],
          [109.952891, 1.405628],
          [109.952736, 1.405581],
          [109.952487, 1.405581],
          [109.952347, 1.405643],
          [109.952285, 1.405783],
          [109.952161, 1.405892],
          [109.951912, 1.405954],
          [109.951741, 1.406374],
          [109.951632, 1.40653],
          [109.951336, 1.406576],
          [109.950994, 1.406545],
          [109.950885, 1.406421],
          [109.950745, 1.40611],
          [109.950559, 1.406094],
          [109.950372, 1.406172],
          [109.950217, 1.406328],
          [109.950077, 1.40639],
          [109.949906, 1.406343],
          [109.949735, 1.406203],
          [109.949564, 1.406203],
          [109.948911, 1.406468],
          [109.948817, 1.406468],
          [109.948677, 1.406405],
          [109.948537, 1.406017],
          [109.948475, 1.40597],
          [109.948366, 1.40597],
          [109.948102, 1.406328],
          [109.947869, 1.406452],
          [109.947667, 1.406841],
          [109.947527, 1.40695],
          [109.94692, 1.406981],
          [109.946609, 1.407074],
          [109.946314, 1.407245],
          [109.945567, 1.408225],
          [109.943856, 1.410122],
          [109.934589, 1.420712],
          [109.931868, 1.423884],
          [109.931168, 1.424661],
          [109.930779, 1.424988],
          [109.930421, 1.425112],
          [109.92983, 1.425112],
          [109.929271, 1.425034],
          [109.928695, 1.42491],
          [109.927654, 1.424568],
          [109.927171, 1.424521],
          [109.92585, 1.424692],
          [109.923377, 1.424832],
          [109.922786, 1.424708],
          [109.920672, 1.423884],
          [109.920158, 1.423837],
          [109.919319, 1.423868],
          [109.91879, 1.423837],
          [109.917126, 1.423573],
          [109.916769, 1.423417],
          [109.914934, 1.423153],
          [109.91411, 1.422935],
          [109.912648, 1.422438],
          [109.910891, 1.421707],
          [109.908496, 1.420898],
          [109.907765, 1.420385],
          [109.907066, 1.420043],
          [109.905184, 1.419483],
          [109.904049, 1.419281],
          [109.903349, 1.41925],
          [109.902121, 1.41897],
          [109.900892, 1.418115],
          [109.900069, 1.417632],
          [109.899633, 1.417399],
          [109.898887, 1.417166],
          [109.898358, 1.416824],
          [109.898078, 1.416575],
          [109.896726, 1.41586],
          [109.89601, 1.415129],
          [109.89545, 1.414864],
          [109.894424, 1.414553],
          [109.89388, 1.414522],
          [109.893491, 1.414553],
          [109.893025, 1.414662],
          [109.892791, 1.414849],
          [109.892605, 1.415098],
          [109.892527, 1.415409],
          [109.892527, 1.416046],
          [109.892465, 1.416497],
          [109.892356, 1.416839],
          [109.892216, 1.417042],
          [109.891936, 1.417228],
          [109.891625, 1.41729],
          [109.889448, 1.41726],
          [109.888795, 1.417384],
          [109.888484, 1.41754],
          [109.887489, 1.418395],
          [109.886649, 1.418706],
          [109.886105, 1.418675],
          [109.885701, 1.418566],
          [109.885281, 1.418302],
          [109.884939, 1.418162],
          [109.88455, 1.418068],
          [109.884208, 1.418037],
          [109.88343, 1.41827],
          [109.881176, 1.418302],
          [109.879061, 1.418037],
          [109.87676, 1.417664],
          [109.875795, 1.417493],
          [109.874178, 1.417089],
          [109.873556, 1.417026],
          [109.873012, 1.417058],
          [109.87012, 1.417788],
          [109.869607, 1.417835],
          [109.868487, 1.417695],
          [109.867927, 1.417524],
          [109.867414, 1.417477],
          [109.867087, 1.41754],
          [109.866636, 1.417757],
          [109.866232, 1.417882],
          [109.86575, 1.417866],
          [109.865408, 1.417804],
          [109.864583, 1.4174],
          [109.864241, 1.417275],
          [109.863822, 1.417306],
          [109.863215, 1.417571],
          [109.862033, 1.41827],
          [109.861785, 1.418379],
          [109.860136, 1.418519],
          [109.858752, 1.418784],
          [109.858084, 1.418861],
          [109.856793, 1.418924],
          [109.856482, 1.419063],
          [109.856203, 1.419312],
          [109.855347, 1.420711],
          [109.855099, 1.421209],
          [109.854819, 1.421598],
          [109.854508, 1.421893],
          [109.85317, 1.422671],
          [109.850714, 1.423915],
          [109.849983, 1.424521],
          [109.849407, 1.42491],
          [109.847122, 1.425812],
          [109.84594, 1.426107],
          [109.84552, 1.426092],
          [109.845256, 1.425983],
          [109.845007, 1.425703],
          [109.844789, 1.425174],
          [109.844571, 1.424443],
          [109.844089, 1.423526],
          [109.843732, 1.423075],
          [109.843265, 1.422671],
          [109.84269, 1.422313],
          [109.842192, 1.422126],
          [109.841944, 1.422095],
          [109.841648, 1.422204],
          [109.841228, 1.4225],
          [109.840015, 1.423573],
          [109.839627, 1.423666],
          [109.83916, 1.423635],
          [109.8389889, 1.4235832],
          [109.838787, 1.42351],
          [109.837667, 1.422686],
          [109.837108, 1.422391],
          [109.836377, 1.422204],
          [109.836066, 1.422266],
          [109.835739, 1.422531],
          [109.835397, 1.422904],
          [109.834977, 1.423557],
          [109.833702, 1.425345],
          [109.832987, 1.426216],
          [109.832754, 1.426682],
          [109.831961, 1.428704],
          [109.831976, 1.429046],
          [109.832147, 1.429388],
          [109.83238, 1.429699],
          [109.832707, 1.429979],
          [109.833002, 1.43043],
          [109.833096, 1.430849],
          [109.833158, 1.431331],
          [109.833126, 1.432156],
          [109.832924, 1.434255],
          [109.832955, 1.434613],
          [109.833126, 1.43494],
          [109.833391, 1.435266],
          [109.83381, 1.435624],
          [109.834121, 1.436044],
          [109.834215, 1.436417],
          [109.834261, 1.437739],
          [109.834541, 1.439931],
          [109.83451, 1.440801],
          [109.834168, 1.441315],
          [109.833655, 1.441921],
          [109.833095, 1.442854],
          [109.832753, 1.443507],
          [109.832629, 1.443942],
          [109.832613, 1.444269],
          [109.832706, 1.445202],
          [109.832675, 1.44727],
          [109.832598, 1.447923],
          [109.832458, 1.448514],
          [109.832209, 1.450411],
          [109.832162, 1.451437],
          [109.8321, 1.451904],
          [109.831913, 1.452168],
          [109.831634, 1.452371],
          [109.83126, 1.452604],
          [109.83084, 1.452759],
          [109.830296, 1.453101],
          [109.830079, 1.453397],
          [109.829876, 1.453817],
          [109.829363, 1.455543],
          [109.82927, 1.455963],
          [109.82927, 1.456413],
          [109.829394, 1.45674],
          [109.829628, 1.457097],
          [109.830623, 1.458264],
          [109.830949, 1.458575],
          [109.831323, 1.459072],
          [109.831773, 1.460099],
          [109.831945, 1.460907],
          [109.832022, 1.461607],
          [109.831976, 1.462866],
          [109.8321, 1.464406],
          [109.832193, 1.464733],
          [109.83238, 1.465106],
          [109.83322, 1.46621],
          [109.833904, 1.466692],
          [109.834355, 1.466894],
          [109.834635, 1.467112],
          [109.834852, 1.467423],
          [109.835179, 1.468107],
          [109.835646, 1.469475],
          [109.835879, 1.47002],
          [109.83605, 1.47075],
          [109.836019, 1.471232],
          [109.835926, 1.471652],
          [109.835926, 1.472103],
          [109.836003, 1.472632],
          [109.836143, 1.47341],
          [109.836905, 1.476737],
          [109.837045, 1.477437],
          [109.837139, 1.478246],
          [109.83689, 1.478899],
          [109.836657, 1.479257],
          [109.83661, 1.479583],
          [109.836657, 1.479941],
          [109.837294, 1.481138],
          [109.838072, 1.482382],
          [109.838569, 1.483082],
          [109.839409, 1.483921],
          [109.83972, 1.484419],
          [109.839829, 1.48487],
          [109.839751, 1.485305],
          [109.839533, 1.485818],
          [109.839284, 1.486129],
          [109.838942, 1.486487],
          [109.838554, 1.486658],
          [109.838025, 1.486767],
          [109.837325, 1.48672],
          [109.836688, 1.486503],
          [109.836361, 1.486223],
          [109.83605, 1.485663],
          [109.835708, 1.484714],
          [109.835459, 1.484232],
          [109.835179, 1.483921],
          [109.834169, 1.483253],
          [109.833624, 1.482724],
          [109.83308, 1.482382],
          [109.832847, 1.482413],
          [109.832551, 1.4826],
          [109.831945, 1.48375],
          [109.831665, 1.484046],
          [109.83137, 1.484186],
          [109.831105, 1.48417],
          [109.83067, 1.48403],
          [109.830094, 1.48375],
          [109.829208, 1.483128],
          [109.82784, 1.482055],
          [109.827591, 1.48148],
          [109.8277, 1.479661],
          [109.827855, 1.479241],
          [109.827933, 1.47879],
          [109.827917, 1.47851],
          [109.827824, 1.478261],
          [109.827467, 1.47795],
          [109.82714, 1.477795],
          [109.826549, 1.47767],
          [109.826347, 1.477577],
          [109.826145, 1.477375],
          [109.825974, 1.477048],
          [109.824357, 1.475944],
          [109.824046, 1.475664],
          [109.82347, 1.474342],
          [109.823283, 1.472414],
          [109.823143, 1.471994],
          [109.822832, 1.47173],
          [109.82249, 1.471606],
          [109.82207, 1.47173],
          [109.821619, 1.472227],
          [109.820966, 1.472741],
          [109.820376, 1.472912],
          [109.819987, 1.472927],
          [109.819334, 1.472803],
          [109.818245, 1.472243],
          [109.818136, 1.47201],
          [109.818121, 1.471699],
          [109.818167, 1.471419],
          [109.818603, 1.47061],
          [109.818727, 1.470082],
          [109.818774, 1.469366],
          [109.818681, 1.468402],
          [109.818494, 1.46806],
          [109.818184, 1.467672],
          [109.817826, 1.467081],
          [109.817624, 1.466552],
          [109.817531, 1.466039],
          [109.816909, 1.464499],
          [109.816722, 1.464188],
          [109.816442, 1.463908],
          [109.816084, 1.463644],
          [109.813441, 1.462167],
          [109.812928, 1.462042],
          [109.812446, 1.46212],
          [109.812135, 1.462291],
          [109.810642, 1.464904],
          [109.810253, 1.46537],
          [109.80988, 1.465634],
          [109.809522, 1.465743],
          [109.808994, 1.465774],
          [109.808045, 1.465588],
          [109.807376, 1.465588],
          [109.806848, 1.465806],
          [109.806412, 1.466148],
          [109.804499, 1.468262],
          [109.803706, 1.468962],
          [109.803053, 1.469429],
          [109.801234, 1.470191],
          [109.800643, 1.470782],
          [109.800456, 1.47117],
          [109.800379, 1.471652],
          [109.800176, 1.472196],
          [109.799959, 1.472476],
          [109.799399, 1.472943],
          [109.799026, 1.473627],
          [109.798544, 1.475369],
          [109.798108, 1.476675],
          [109.797953, 1.477577],
          [109.797844, 1.479427],
          [109.797626, 1.481122],
          [109.797517, 1.481526],
          [109.797113, 1.482552],
          [109.796771, 1.483174],
          [109.796569, 1.483454],
          [109.796522, 1.483734],
          [109.796647, 1.484496],
          [109.796818, 1.484901],
          [109.797113, 1.485134],
          [109.79744, 1.485181],
          [109.797999, 1.485103],
          [109.798513, 1.48487],
          [109.798964, 1.484745],
          [109.799414, 1.484683],
          [109.800145, 1.484792],
          [109.801638, 1.485274],
          [109.80268, 1.485694],
          [109.803053, 1.485958],
          [109.803255, 1.486378],
          [109.803255, 1.487264],
          [109.803022, 1.487917],
          [109.802664, 1.488679],
          [109.801716, 1.48997],
          [109.801685, 1.49025],
          [109.801856, 1.49151],
          [109.801763, 1.492365],
          [109.80156, 1.492692],
          [109.801203, 1.492987],
          [109.800316, 1.493251],
          [109.79887, 1.494464],
          [109.798435, 1.494899],
          [109.798373, 1.495179],
          [109.79845, 1.495848],
          [109.799026, 1.497931],
          [109.799057, 1.498382],
          [109.798995, 1.499004],
          [109.798879, 1.499455],
          [109.79807, 1.500108],
          [109.796048, 1.501384],
          [109.794711, 1.502192],
          [109.793747, 1.502565],
          [109.793187, 1.502659],
          [109.790233, 1.502628],
          [109.787963, 1.502752],
          [109.78619, 1.502597],
          [109.784915, 1.502534],
          [109.784231, 1.502628],
          [109.783453, 1.503063],
          [109.782489, 1.503778],
          [109.781556, 1.50468],
          [109.780965, 1.505489],
          [109.780592, 1.506453],
          [109.780188, 1.508257],
          [109.780064, 1.509127],
          [109.77997, 1.510278],
          [109.779815, 1.511024],
          [109.779473, 1.511833],
          [109.779099, 1.512548],
          [109.778695, 1.513015],
          [109.778291, 1.513326],
          [109.778011, 1.513637],
          [109.776612, 1.515907],
          [109.775398, 1.517555],
          [109.774838, 1.518177],
          [109.774247, 1.518551],
          [109.773625, 1.51883],
          [109.771137, 1.519639],
          [109.770515, 1.520043],
          [109.770049, 1.520479],
          [109.769769, 1.521194],
          [109.769582, 1.521878],
          [109.769551, 1.52306],
          [109.769427, 1.523464],
          [109.769209, 1.523713],
          [109.768867, 1.523931],
          [109.762927, 1.526605],
          [109.760221, 1.52788],
          [109.756894, 1.529062],
          [109.756085, 1.529404],
          [109.755743, 1.529622],
          [109.755339, 1.530368],
          [109.755277, 1.530835],
          [109.755246, 1.532203],
          [109.755183, 1.532763],
          [109.754841, 1.533416],
          [109.754375, 1.533882],
          [109.754126, 1.534007],
          [109.753691, 1.534038],
          [109.751214, 1.534707],
          [109.750582, 1.535449],
          [109.749936, 1.539014],
          [109.748553, 1.540037],
          [109.748085, 1.540814],
          [109.746805, 1.543408],
          [109.746071, 1.54453],
          [109.745347, 1.545041],
          [109.744412, 1.546507],
          [109.743942, 1.547371],
          [109.743345, 1.548059],
          [109.74077, 1.55037],
          [109.739574, 1.551919],
          [109.73755, 1.552408],
          [109.735204, 1.552805],
          [109.733809, 1.554525],
          [109.733261, 1.556173],
          [109.732909, 1.557737],
          [109.731984, 1.558593],
          [109.72963, 1.559425],
          [109.729077, 1.561335],
          [109.726435, 1.563732],
          [109.724377, 1.562476],
          [109.723134, 1.562978],
          [109.722271, 1.56401],
          [109.721593, 1.565655],
          [109.720715, 1.567559],
          [109.719595, 1.568412],
          [109.717564, 1.569337],
          [109.717486, 1.570121],
          [109.717786, 1.571521],
          [109.716732, 1.572375],
          [109.714958, 1.573479],
          [109.714221, 1.574774],
          [109.712849, 1.578937],
          [109.712442, 1.57989],
          [109.708869, 1.583579],
          [109.708777, 1.585147],
          [109.706726, 1.587205],
          [109.705493, 1.587097],
          [109.704076, 1.586376],
          [109.701694, 1.585027],
          [109.701239, 1.585019],
          [109.699627, 1.588044],
          [109.69767, 1.588447],
          [109.696809, 1.589392],
          [109.695783, 1.59234],
          [109.69459, 1.593715],
          [109.694061, 1.594229],
          [109.694341, 1.59685],
          [109.695355, 1.598349],
          [109.694625, 1.599209],
          [109.694141, 1.600858],
          [109.693415, 1.601543],
          [109.692035, 1.602392],
          [109.691032, 1.604032],
          [109.690092, 1.60576],
          [109.688962, 1.607223],
          [109.688749, 1.608266],
          [109.689436, 1.609848],
          [109.689426, 1.610458],
          [109.687931, 1.610432],
          [109.687079, 1.610854],
          [109.686085, 1.611971],
          [109.684534, 1.611421],
          [109.683286, 1.612185],
          [109.681976, 1.612773],
          [109.680166, 1.61222],
          [109.678803, 1.612109],
          [109.677242, 1.61217],
          [109.675757, 1.611622],
          [109.674717, 1.611604],
          [109.673002, 1.613058],
          [109.672216, 1.613393],
          [109.669717, 1.615095],
          [109.668363, 1.614461],
          [109.666606, 1.614606],
          [109.666583, 1.615914],
          [109.665858, 1.616512],
          [109.664882, 1.616583],
          [109.663632, 1.617434],
          [109.662244, 1.618805],
          [109.661967, 1.61976],
          [109.662019, 1.620546],
          [109.662262, 1.621509],
          [109.661147, 1.62585],
          [109.661044, 1.627283],
          [109.660659, 1.628979],
          [109.660572, 1.629625],
          [109.660555, 1.630028],
          [109.660624, 1.630517],
          [109.660415, 1.631986],
          [109.660275, 1.63265],
          [109.660152, 1.633209],
          [109.659925, 1.633681],
          [109.659488, 1.634818],
          [109.659051, 1.635588],
          [109.658579, 1.636602],
          [109.658299, 1.637493],
          [109.658352, 1.637773],
          [109.658666, 1.638175],
          [109.659191, 1.638647],
          [109.661324, 1.641601],
          [109.661551, 1.642003],
          [109.661866, 1.642912],
          [109.662093, 1.643402],
          [109.66253, 1.644048],
          [109.662932, 1.644818],
          [109.664383, 1.648157],
          [109.664645, 1.648559],
          [109.665013, 1.648909],
          [109.665747, 1.64945],
          [109.666079, 1.649905],
          [109.667408, 1.652073],
          [109.66746, 1.652458],
          [109.667303, 1.653174],
          [109.666866, 1.653786],
          [109.665869, 1.655849],
          [109.665572, 1.656356],
          [109.665432, 1.656811],
          [109.665292, 1.660377],
          [109.66517, 1.660972],
          [109.66489, 1.661706],
          [109.664628, 1.66286],
          [109.664523, 1.664171],
          [109.664296, 1.664888],
          [109.663719, 1.666426],
          [109.663422, 1.667056],
          [109.662058, 1.669154],
          [109.661638, 1.67001],
          [109.661569, 1.670482],
          [109.660834, 1.673122],
          [109.66031, 1.674608],
          [109.66024, 1.675028],
          [109.660327, 1.675377],
          [109.660467, 1.675657],
          [109.66052, 1.676916],
          [109.660642, 1.677195],
          [109.660834, 1.67758],
          [109.661638, 1.678244],
          [109.662233, 1.678839],
          [109.663649, 1.6805],
          [109.664121, 1.681269],
          [109.664331, 1.681776],
          [109.664488, 1.683088],
          [109.664488, 1.68363],
          [109.664296, 1.684469],
          [109.663771, 1.685238],
          [109.663719, 1.68571],
          [109.663597, 1.686165],
          [109.663439, 1.686637],
          [109.663159, 1.687056],
          [109.662967, 1.687807],
          [109.662443, 1.688419],
          [109.662198, 1.688874],
          [109.662076, 1.689538],
          [109.662163, 1.689853],
          [109.662093, 1.690098],
          [109.661638, 1.690692],
          [109.661376, 1.691444],
          [109.661097, 1.692056],
          [109.660624, 1.69286],
          [109.660624, 1.693052],
          [109.661184, 1.693262],
          [109.661359, 1.693524],
          [109.661516, 1.693874],
          [109.661743, 1.694206],
          [109.662006, 1.694486],
          [109.662268, 1.694678],
          [109.66288, 1.694853],
          [109.663649, 1.69578],
          [109.663999, 1.696042],
          [109.66461, 1.696829],
          [109.665222, 1.697511],
          [109.665555, 1.69765],
          [109.666219, 1.697825],
          [109.666691, 1.698332],
          [109.66746, 1.699014],
          [109.667897, 1.699206],
          [109.668632, 1.699224],
          [109.669278, 1.699032],
          [109.669628, 1.699101],
          [109.669733, 1.699206],
          [109.669646, 1.699643],
          [109.669069, 1.700797],
          [109.668946, 1.701199],
          [109.668824, 1.702598],
          [109.668597, 1.703122],
          [109.668334, 1.70356],
          [109.668212, 1.703874],
          [109.66816, 1.704224],
          [109.668387, 1.704923],
          [109.668824, 1.705553],
          [109.669034, 1.706339],
          [109.669016, 1.706619],
          [109.668929, 1.706829],
          [109.668614, 1.707178],
          [109.668579, 1.707493],
          [109.669051, 1.708402],
          [109.668999, 1.708717],
          [109.668824, 1.709014],
          [109.668719, 1.709364],
          [109.668649, 1.709871],
          [109.668317, 1.710937],
          [109.668177, 1.711252],
          [109.667985, 1.711514],
          [109.667338, 1.711899],
          [109.667268, 1.712179],
          [109.667425, 1.713805],
          [109.667373, 1.714119],
          [109.667425, 1.715326],
          [109.667268, 1.71641],
          [109.667268, 1.716829],
          [109.66718, 1.717196],
          [109.667058, 1.717406],
          [109.667023, 1.71779],
          [109.667111, 1.71814],
          [109.667653, 1.719591],
          [109.66781, 1.719888],
          [109.66809, 1.720203],
          [109.668387, 1.720692],
          [109.668334, 1.720867],
          [109.667862, 1.721304],
          [109.667775, 1.721549],
          [109.667688, 1.722336],
          [109.667705, 1.722808],
          [109.667792, 1.723105],
          [109.66781, 1.723525],
          [109.66739, 1.725011],
          [109.66753, 1.725815],
          [109.667688, 1.726235],
          [109.668072, 1.726794],
          [109.66809, 1.726986],
          [109.667862, 1.727738],
          [109.667932, 1.728053],
          [109.668107, 1.728315],
          [109.668125, 1.728612],
          [109.66781, 1.729207],
          [109.667792, 1.729451],
          [109.66802, 1.730116],
          [109.668072, 1.73071],
          [109.668037, 1.73106],
          [109.66767, 1.732476],
          [109.667618, 1.732896],
          [109.667653, 1.733193],
          [109.667775, 1.733525],
          [109.668737, 1.734277],
          [109.669506, 1.734609],
          [109.670135, 1.735273],
          [109.670328, 1.735553],
          [109.67052, 1.736305],
          [109.671114, 1.737668],
          [109.671289, 1.738228],
          [109.671325, 1.738542],
          [109.67129, 1.738892],
          [109.67108, 1.739626],
          [109.671132, 1.739923],
          [109.67129, 1.740186],
          [109.671814, 1.74064],
          [109.671849, 1.740972],
          [109.671657, 1.741462],
          [109.671762, 1.741864],
          [109.671901, 1.742126],
          [109.672356, 1.742721],
          [109.672531, 1.743088],
          [109.672741, 1.743367],
          [109.673003, 1.743525],
          [109.674017, 1.743927],
          [109.675013, 1.744626],
          [109.675258, 1.745046],
          [109.675468, 1.745728],
          [109.675625, 1.745902],
          [109.675922, 1.746094],
          [109.67608, 1.746304],
          [109.67629, 1.747161],
          [109.676989, 1.747545],
          [109.677391, 1.747685],
          [109.677845, 1.747965],
          [109.678055, 1.748245],
          [109.678195, 1.748594],
          [109.678615, 1.750115],
          [109.679122, 1.751129],
          [109.679052, 1.751654],
          [109.679052, 1.751986],
          [109.679297, 1.75251],
          [109.679454, 1.753017],
          [109.679489, 1.753629],
          [109.679698, 1.754486],
          [109.679926, 1.754678],
          [109.680066, 1.755028],
          [109.680101, 1.75529],
          [109.679943, 1.756129],
          [109.679926, 1.756514],
          [109.679961, 1.756811],
          [109.680048, 1.757056],
          [109.680258, 1.757388],
          [109.68059, 1.758839],
          [109.680765, 1.759416],
          [109.68108, 1.76029],
          [109.681587, 1.760692],
          [109.681622, 1.760919],
          [109.681552, 1.761462],
          [109.681989, 1.762371],
          [109.682146, 1.76293],
          [109.682373, 1.76328],
          [109.682863, 1.763892],
          [109.68323, 1.764101],
          [109.683422, 1.764311],
          [109.683422, 1.764626],
          [109.683335, 1.764888],
          [109.683405, 1.765448],
          [109.683405, 1.766374],
          [109.68351, 1.766759],
          [109.684576, 1.768],
          [109.684856, 1.768192],
          [109.684943, 1.768385],
          [109.684663, 1.768927],
          [109.684419, 1.769119],
          [109.684226, 1.769347],
          [109.684139, 1.769557],
          [109.684122, 1.769871],
          [109.684296, 1.770693],
          [109.684261, 1.771025],
          [109.684034, 1.771759],
          [109.684017, 1.772109],
          [109.684139, 1.772966],
          [109.684104, 1.773315],
          [109.683807, 1.774259],
          [109.683615, 1.775133],
          [109.68365, 1.775501],
          [109.683947, 1.776864],
          [109.683947, 1.778105],
          [109.683999, 1.778402],
          [109.684087, 1.778699],
          [109.684401, 1.779119],
          [109.684961, 1.779713],
          [109.68517, 1.780116],
          [109.685258, 1.78043],
          [109.685188, 1.780937],
          [109.685223, 1.781217],
          [109.685538, 1.781794],
          [109.685503, 1.782126],
          [109.68538, 1.782389],
          [109.685206, 1.782616],
          [109.684926, 1.782826],
          [109.684401, 1.783088],
          [109.684052, 1.783333],
          [109.683842, 1.78356],
          [109.683807, 1.784382],
          [109.683685, 1.784714],
          [109.68344, 1.784993],
          [109.682915, 1.785955],
          [109.682706, 1.786147],
          [109.681971, 1.78613],
          [109.681377, 1.78627],
          [109.6808, 1.786479],
          [109.680328, 1.786532],
          [109.679611, 1.786322],
          [109.679192, 1.78634],
          [109.678632, 1.786444],
          [109.677548, 1.786934],
          [109.677234, 1.786986],
          [109.676936, 1.786882],
          [109.676499, 1.786812],
          [109.676272, 1.786847],
          [109.675713, 1.787214],
          [109.675346, 1.787319],
          [109.674681, 1.787423],
          [109.673737, 1.787843],
          [109.673387, 1.788193],
          [109.672932, 1.789101],
          [109.67253, 1.789643],
          [109.672163, 1.78994],
          [109.671778, 1.79036],
          [109.671534, 1.790797],
          [109.671131, 1.791147],
          [109.669943, 1.791933],
          [109.669261, 1.792545],
          [109.668649, 1.793297],
          [109.668387, 1.793559],
          [109.668107, 1.793716],
          [109.667145, 1.793926],
          [109.666184, 1.794258],
          [109.665852, 1.794678],
          [109.66538, 1.794958],
          [109.664873, 1.79536],
          [109.664069, 1.795744],
          [109.663002, 1.796059],
          [109.66246, 1.796356],
          [109.661726, 1.796968],
          [109.661359, 1.79744],
          [109.660677, 1.797772],
          [109.659908, 1.798349],
          [109.659576, 1.798367],
          [109.659208, 1.798244],
          [109.656884, 1.797825],
          [109.656394, 1.797825],
          [109.65559, 1.797912],
          [109.655223, 1.797825],
          [109.653667, 1.797161],
          [109.653072, 1.796951],
          [109.652828, 1.796898],
          [109.652181, 1.797021],
          [109.651901, 1.796968],
          [109.651656, 1.796776],
          [109.651237, 1.796549],
          [109.650765, 1.796461],
          [109.649891, 1.796444],
          [109.649576, 1.796286],
          [109.649366, 1.796042],
          [109.648981, 1.795902],
          [109.648509, 1.796112],
          [109.647041, 1.796356],
          [109.6463877, 1.7964076],
          [109.645485, 1.796479],
          [109.644856, 1.796356],
          [109.644331, 1.796391],
          [109.643737, 1.796566],
          [109.642723, 1.797091],
          [109.641954, 1.79737],
          [109.641097, 1.797807],
          [109.640503, 1.798332],
          [109.64017, 1.798717],
          [109.639768, 1.798961],
          [109.639226, 1.799206],
          [109.638597, 1.799906],
          [109.638143, 1.800902],
          [109.63809, 1.801392],
          [109.63809, 1.802371],
          [109.637705, 1.80272],
          [109.637583, 1.802948],
          [109.637653, 1.803227],
          [109.637793, 1.80349],
          [109.637828, 1.803769],
          [109.63774, 1.803997],
          [109.637443, 1.804486],
          [109.636744, 1.805273],
          [109.636342, 1.80543],
          [109.63594, 1.80543],
          [109.635328, 1.805308],
          [109.634856, 1.805045],
          [109.634559, 1.804608],
          [109.634384, 1.804084],
          [109.634401, 1.803752],
          [109.633877, 1.802458],
          [109.633632, 1.802283],
          [109.633265, 1.802161],
          [109.63295, 1.801951],
          [109.632513, 1.801339],
          [109.631744, 1.801007],
          [109.631464, 1.800692],
          [109.631114, 1.800465],
          [109.630835, 1.800413],
          [109.630468, 1.800448],
          [109.629768, 1.80008],
          [109.628894, 1.799731],
          [109.628614, 1.799748],
          [109.627915, 1.800011],
          [109.627146, 1.800413],
          [109.626289, 1.800622],
          [109.625677, 1.801094],
          [109.624681, 1.801654],
          [109.623474, 1.80286],
          [109.623107, 1.803122],
          [109.62281, 1.803175],
          [109.622548, 1.803087],
          [109.621604, 1.802528],
          [109.620992, 1.801934],
          [109.620764, 1.801794],
          [109.620467, 1.801776],
          [109.620065, 1.801654],
          [109.619453, 1.801095],
          [109.619016, 1.80029],
          [109.618614, 1.799731],
          [109.618072, 1.7987],
          [109.617915, 1.798595],
          [109.617618, 1.798525],
          [109.616778, 1.798612],
          [109.616394, 1.798577],
          [109.615747, 1.798228],
          [109.615502, 1.797948],
          [109.6151, 1.797004],
          [109.614698, 1.796881],
          [109.614104, 1.796794],
          [109.61295, 1.796497],
          [109.612355, 1.796165],
          [109.612006, 1.796042],
          [109.611726, 1.795972],
          [109.610992, 1.79613],
          [109.61059, 1.796269],
          [109.610187, 1.796322],
          [109.609401, 1.796112],
          [109.607093, 1.796077],
          [109.606289, 1.79599],
          [109.604856, 1.795675],
          [109.603247, 1.795605],
          [109.602513, 1.795693],
          [109.602111, 1.795867],
          [109.601849, 1.796444],
          [109.601621, 1.796637],
          [109.601027, 1.796986],
          [109.600625, 1.797703],
          [109.60038, 1.79793],
          [109.600048, 1.797948],
          [109.599436, 1.797825],
          [109.598702, 1.797458],
          [109.598212, 1.797301],
          [109.596079, 1.797021],
          [109.595398, 1.796497],
          [109.595083, 1.79606],
          [109.594454, 1.794958],
          [109.593964, 1.794364],
          [109.59365, 1.794084],
          [109.593352, 1.793927],
          [109.593055, 1.793927],
          [109.592303, 1.794084],
          [109.591831, 1.794067],
          [109.591429, 1.793927],
          [109.590153, 1.793175],
          [109.589891, 1.79307],
          [109.589436, 1.793],
          [109.589191, 1.792843],
          [109.588527, 1.792231],
          [109.588212, 1.792056],
          [109.587775, 1.791934],
          [109.587076, 1.792056],
          [109.586796, 1.792039],
          [109.586516, 1.791934],
          [109.586062, 1.791916],
          [109.585852, 1.792039],
          [109.585432, 1.792406],
          [109.585188, 1.792913],
          [109.5851, 1.793437],
          [109.584803, 1.794032],
          [109.583509, 1.795273],
          [109.5831154, 1.7955878],
          [109.58246, 1.796112],
          [109.582128, 1.796689],
          [109.581918, 1.797248],
          [109.581569, 1.797843],
          [109.581254, 1.798262],
          [109.581079, 1.798752],
          [109.581044, 1.799066],
          [109.581097, 1.799591],
          [109.581044, 1.799958],
          [109.580729, 1.800797],
          [109.580537, 1.801147],
          [109.580013, 1.801811],
          [109.57982, 1.802126],
          [109.579785, 1.802405],
          [109.579908, 1.802668],
          [109.5801, 1.802895],
          [109.580152, 1.80314],
          [109.5801, 1.803367],
          [109.579978, 1.803542],
          [109.579506, 1.803909],
          [109.579278, 1.804189],
          [109.579226, 1.804433],
          [109.579383, 1.80501],
          [109.579523, 1.805115],
          [109.57996, 1.805727],
          [109.580048, 1.806182],
          [109.5800702, 1.8067706],
          [109.5801, 1.807563],
          [109.580187, 1.807965],
          [109.580555, 1.808402],
          [109.580799, 1.808612],
          [109.582198, 1.808804],
          [109.58246, 1.809032],
          [109.58274, 1.809189],
          [109.583282, 1.809259],
          [109.584226, 1.809486],
          [109.584925, 1.809836],
          [109.585135, 1.810046],
          [109.585782, 1.811094],
          [109.586044, 1.811601],
          [109.586289, 1.812388],
          [109.586237, 1.812598],
          [109.585939, 1.812843],
          [109.585677, 1.81314],
          [109.585293, 1.813437],
          [109.5851, 1.813717],
          [109.584943, 1.814486],
          [109.584995, 1.815553],
          [109.585083, 1.81592],
          [109.58545, 1.816444],
          [109.585642, 1.816986],
          [109.585747, 1.817948],
          [109.585939, 1.818822],
          [109.585957, 1.819259],
          [109.58587, 1.819626],
          [109.5855568, 1.8202944],
          [109.5851, 1.821269],
          [109.584943, 1.821706],
          [109.584786, 1.822161],
          [109.584593, 1.823192],
          [109.584121, 1.824451],
          [109.584139, 1.824853],
          [109.584383, 1.825238],
          [109.584348, 1.825657],
          [109.584174, 1.826077],
          [109.583859, 1.826357],
          [109.583317, 1.826689],
          [109.5829137, 1.8271591],
          [109.582792, 1.827301],
          [109.581988, 1.828945],
          [109.581569, 1.830308],
          [109.581481, 1.830885],
          [109.581569, 1.832179],
          [109.581778, 1.832511],
          [109.582006, 1.832773],
          [109.582268, 1.832966],
          [109.582443, 1.833228],
          [109.58253, 1.833595],
          [109.582583, 1.834347],
          [109.58253, 1.834766],
          [109.58239, 1.835081],
          [109.582146, 1.835396],
          [109.579908, 1.836759],
          [109.579383, 1.836864],
          [109.579226, 1.836987],
          [109.578841, 1.838001],
          [109.578771, 1.838508],
          [109.578352, 1.839924],
          [109.578159, 1.840203],
          [109.5776, 1.840605],
          [109.57739, 1.840903],
          [109.576726, 1.842336],
          [109.576149, 1.843333],
          [109.575834, 1.8437],
          [109.575519, 1.843787],
          [109.57524, 1.843752],
          [109.573911, 1.842948],
          [109.572041, 1.842423],
          [109.571534, 1.842353],
          [109.570747, 1.842458],
          [109.569069, 1.842843],
          [109.568579, 1.842895],
          [109.568195, 1.842773],
          [109.5676, 1.842493],
          [109.566901, 1.842301],
          [109.566359, 1.842423],
          [109.565922, 1.842441],
          [109.565397, 1.842598],
          [109.564681, 1.843],
          [109.563911, 1.843193],
          [109.563474, 1.843455],
          [109.563037, 1.843822],
          [109.562425, 1.844469],
          [109.562163, 1.844836],
          [109.562041, 1.845186],
          [109.561813, 1.845605],
          [109.561621, 1.846514],
          [109.561341, 1.847214],
          [109.561237, 1.847651],
          [109.561149, 1.8487],
          [109.560904, 1.848822],
          [109.560782, 1.848839],
          [109.560467, 1.848752],
          [109.559873, 1.848717],
          [109.559121, 1.848787],
          [109.558807, 1.848909],
          [109.558492, 1.849119],
          [109.557513, 1.849416],
          [109.556814, 1.849836],
          [109.555415, 1.850518],
          [109.55503, 1.850797],
          [109.554891, 1.851077],
          [109.554821, 1.851409],
          [109.554751, 1.852353],
          [109.554681, 1.852633],
          [109.554069, 1.85314],
          [109.553667, 1.853315],
          [109.553422, 1.853472],
          [109.553387, 1.853612],
          [109.553509, 1.853787],
          [109.554209, 1.854661],
          [109.554663, 1.855063],
          [109.554663, 1.855325],
          [109.554611, 1.855553],
          [109.554471, 1.855762],
          [109.554226, 1.85592],
          [109.554016, 1.856287],
          [109.553929, 1.856584],
          [109.553667, 1.856951],
          [109.553387, 1.857178],
          [109.552758, 1.857546],
          [109.552006, 1.859014],
          [109.551621, 1.859276],
          [109.551639, 1.859574],
          [109.551814, 1.860203],
          [109.551849, 1.861777],
          [109.552058, 1.862528],
          [109.552565, 1.86391],
          [109.552933, 1.864749],
          [109.553352, 1.865238],
          [109.553562, 1.865378],
          [109.554506, 1.865483],
          [109.555083, 1.866025],
          [109.55524, 1.866357],
          [109.555363, 1.866917],
          [109.555625, 1.867633],
          [109.556219, 1.86828],
          [109.556674, 1.869084],
          [109.556936, 1.869452],
          [109.557268, 1.870046],
          [109.557548, 1.870675],
          [109.557705, 1.87106],
          [109.557775, 1.871462],
          [109.55767, 1.871707],
          [109.557408, 1.871882],
          [109.556482, 1.872179],
          [109.555642, 1.872581],
          [109.55524, 1.872686],
          [109.554681, 1.872686],
          [109.554156, 1.872826],
          [109.553842, 1.873158],
          [109.553475, 1.873665],
          [109.552303, 1.875938],
          [109.551936, 1.876777],
          [109.551639, 1.877197],
          [109.551447, 1.877371],
          [109.551167, 1.877459],
          [109.5508, 1.877476],
          [109.549908, 1.877319],
          [109.549611, 1.877354],
          [109.549489, 1.877406],
          [109.549314, 1.877616],
          [109.548982, 1.878385],
          [109.548877, 1.878997],
          [109.548859, 1.879364],
          [109.548702, 1.879662],
          [109.548335, 1.880046],
          [109.548003, 1.880571],
          [109.547915, 1.880938],
          [109.547933, 1.881515],
          [109.54809, 1.881899],
          [109.548352, 1.882179],
          [109.548684, 1.882406],
          [109.548842, 1.882686],
          [109.548947, 1.883175],
          [109.548912, 1.88363],
          [109.54837, 1.884889],
          [109.548107, 1.885081],
          [109.547548, 1.885326],
          [109.547006, 1.885448],
          [109.546691, 1.885623],
          [109.546534, 1.885885],
          [109.5465054, 1.8866369],
          [109.546447, 1.888175],
          [109.5458, 1.889976],
          [109.545538, 1.890343],
          [109.5451, 1.89071],
          [109.544349, 1.891479],
          [109.544226, 1.891811],
          [109.544261, 1.892161],
          [109.544366, 1.892493],
          [109.544996, 1.89356],
          [109.545223, 1.893857],
          [109.546587, 1.894766],
          [109.547216, 1.895081],
          [109.547373, 1.895378],
          [109.547303, 1.895692],
          [109.546656, 1.896671],
          [109.546447, 1.897144],
          [109.546342, 1.897651],
          [109.546324, 1.897983],
          [109.546517, 1.898315],
          [109.546726, 1.898542],
          [109.547006, 1.899206],
          [109.547023, 1.899468],
          [109.546901, 1.899748],
          [109.545992, 1.901059],
          [109.545835, 1.901549],
          [109.545782, 1.902266],
          [109.546027, 1.903332],
          [109.546027, 1.903769],
          [109.545957, 1.904101],
          [109.545747, 1.904468],
          [109.544978, 1.905273],
          [109.543912, 1.905745],
          [109.543387, 1.906147],
          [109.541936, 1.908035],
          [109.541219, 1.909102],
          [109.54066, 1.910011],
          [109.540328, 1.910658],
          [109.540065, 1.911025],
          [109.539733, 1.911322],
          [109.539174, 1.911619],
          [109.538912, 1.911812],
          [109.538807, 1.912196],
          [109.538754, 1.913018],
          [109.538842, 1.914626],
          [109.538824, 1.915115],
          [109.538422, 1.916811],
          [109.538405, 1.917423],
          [109.53844, 1.917895],
          [109.538667, 1.919031],
          [109.538894, 1.92071],
          [109.538894, 1.92113],
          [109.538789, 1.921584],
          [109.538475, 1.922144],
          [109.538142, 1.923018],
          [109.538125, 1.923682],
          [109.538038, 1.924521],
          [109.538003, 1.926707],
          [109.538038, 1.926899],
          [109.538265, 1.927021],
          [109.539418, 1.926619],
          [109.539943, 1.926497],
          [109.540275, 1.926549],
          [109.542041, 1.927319],
          [109.54267, 1.927546],
          [109.543247, 1.927686],
          [109.544331, 1.928245],
          [109.544681, 1.928525],
          [109.54503, 1.928595],
          [109.546604, 1.928158],
          [109.546936, 1.927983],
          [109.547286, 1.927493],
          [109.547583, 1.927266],
          [109.54788, 1.927126],
          [109.548457, 1.927493],
          [109.549348, 1.928245],
          [109.549873, 1.928962],
          [109.55003, 1.929399],
          [109.550397, 1.929714],
          [109.550887, 1.929766],
          [109.551551, 1.929906],
          [109.552058, 1.930063],
          [109.55232, 1.930063],
          [109.55274, 1.929923],
          [109.553649, 1.929049],
          [109.554138, 1.928857],
          [109.555729, 1.9287],
          [109.556097, 1.928822],
          [109.556131, 1.928979],
          [109.555992, 1.929242],
          [109.55545, 1.930011],
          [109.555432, 1.930238],
          [109.555502, 1.930413],
          [109.555869, 1.930745],
          [109.556778, 1.931427],
          [109.557023, 1.931742],
          [109.557145, 1.932214],
          [109.557303, 1.933507],
          [109.557443, 1.933822],
          [109.559033, 1.935046],
          [109.559453, 1.935221],
          [109.559855, 1.935308],
          [109.562827, 1.935308],
          [109.563247, 1.935343],
          [109.564121, 1.936252],
          [109.564366, 1.936602],
          [109.564366, 1.936899],
          [109.563841, 1.938071],
          [109.563649, 1.938298],
          [109.563037, 1.938648],
          [109.562617, 1.93912],
          [109.562128, 1.940221],
          [109.562075, 1.940448],
          [109.56211, 1.940658],
          [109.562582, 1.941095],
          [109.563299, 1.941515],
          [109.564033, 1.941847],
          [109.56489, 1.942337],
          [109.565939, 1.943106],
          [109.566131, 1.943351],
          [109.566201, 1.944137],
          [109.566359, 1.944627],
          [109.566796, 1.945536],
          [109.566831, 1.945728],
          [109.566656, 1.945921],
          [109.565747, 1.946323],
          [109.565152, 1.946655],
          [109.564033, 1.947721],
          [109.563736, 1.948071],
          [109.563666, 1.948578],
          [109.563719, 1.948892],
          [109.564278, 1.949662],
          [109.56461, 1.950204],
          [109.56475, 1.950623],
          [109.564785, 1.951043],
          [109.564715, 1.95134],
          [109.563544, 1.952301],
          [109.563037, 1.952546],
          [109.56218, 1.952773],
          [109.56197, 1.952913],
          [109.561848, 1.953088],
          [109.561691, 1.953805],
          [109.561429, 1.954224],
          [109.561027, 1.954661],
          [109.560625, 1.955011],
          [109.560222, 1.955133],
          [109.558422, 1.955343],
          [109.55802, 1.955343],
          [109.557478, 1.955168],
          [109.556604, 1.954626],
          [109.556271, 1.954504],
          [109.555869, 1.954521],
          [109.555537, 1.954609],
          [109.554593, 1.954993],
          [109.553649, 1.955308],
          [109.552495, 1.955815],
          [109.552076, 1.956025],
          [109.551883, 1.956217],
          [109.551831, 1.956479],
          [109.551936, 1.956759],
          [109.552565, 1.957878],
          [109.552618, 1.958245],
          [109.552495, 1.959749],
          [109.552565, 1.959993],
          [109.55267, 1.960133],
          [109.552985, 1.960291],
          [109.553404, 1.960413],
          [109.553824, 1.960605],
          [109.554051, 1.960885],
          [109.554383, 1.961567],
          [109.554576, 1.962004],
          [109.554663, 1.962493],
          [109.55475, 1.963193],
          [109.554733, 1.96349],
          [109.554366, 1.963909],
          [109.553841, 1.964329],
          [109.553562, 1.964661],
          [109.553422, 1.964976],
          [109.553369, 1.965378],
          [109.553404, 1.965693],
          [109.553579, 1.96599],
          [109.553824, 1.966182],
          [109.554995, 1.966462],
          [109.55545, 1.966532],
          [109.556044, 1.966532],
          [109.557285, 1.966357],
          [109.557705, 1.966374],
          [109.557915, 1.966462],
          [109.558072, 1.966584],
          [109.558929, 1.967966],
          [109.560117, 1.970973],
          [109.560275, 1.971165],
          [109.560834, 1.97141],
          [109.562093, 1.971637],
          [109.563282, 1.97169],
          [109.563771, 1.97176],
          [109.565834, 1.972441],
          [109.566341, 1.972669],
          [109.566603, 1.972913],
          [109.56753, 1.973613],
          [109.568789, 1.973805],
          [109.569033, 1.973997],
          [109.569348, 1.974644],
          [109.569733, 1.975046],
          [109.569943, 1.975169],
          [109.570887, 1.975309],
          [109.571552, 1.975466],
          [109.573003, 1.976217],
          [109.57358, 1.97641],
          [109.573982, 1.97648],
          [109.574873, 1.97648],
          [109.575223, 1.97662],
          [109.575747, 1.977214],
          [109.57608, 1.977703],
          [109.576359, 1.977843],
          [109.577076, 1.977791],
          [109.577863, 1.977634],
          [109.578929, 1.977249],
          [109.579541, 1.977301],
          [109.579996, 1.977441],
          [109.580433, 1.977511],
          [109.581202, 1.977441],
          [109.58531, 1.977266],
          [109.586272, 1.977424],
          [109.587565, 1.977983],
          [109.588457, 1.978315],
          [109.590188, 1.97877],
          [109.593037, 1.979102],
          [109.593999, 1.978945],
          [109.594838, 1.978665],
          [109.595223, 1.978595],
          [109.595555, 1.978578],
          [109.597408, 1.978752],
          [109.597635, 1.978613],
          [109.59809, 1.977581],
          [109.598212, 1.977144],
          [109.59816, 1.975099],
          [109.598264, 1.974697],
          [109.598684, 1.97384],
          [109.598859, 1.973508],
          [109.599121, 1.973438],
          [109.600188, 1.973718],
          [109.60073, 1.974032],
          [109.601219, 1.974382],
          [109.601673, 1.974801],
          [109.602215, 1.974976],
          [109.603002, 1.975081],
          [109.603159, 1.975221],
          [109.603212, 1.975465],
          [109.603474, 1.97613],
          [109.603841, 1.976689],
          [109.605992, 1.97856],
          [109.606411, 1.979154],
          [109.606866, 1.979661],
          [109.60718, 1.979924],
          [109.607617, 1.979976],
          [109.608037, 1.980098],
          [109.608824, 1.980518],
          [109.612617, 1.982249],
          [109.613072, 1.982528],
          [109.61344, 1.982634],
          [109.613824, 1.982564],
          [109.614559, 1.982336],
          [109.614978, 1.982301],
          [109.615555, 1.982336],
          [109.616936, 1.982529],
          [109.617671, 1.982704],
          [109.619629, 1.983018],
          [109.620171, 1.983071],
          [109.620415, 1.983211],
          [109.620538, 1.98412],
          [109.620608, 1.984347],
          [109.620747, 1.984557],
          [109.621027, 1.984749],
          [109.621412, 1.984871],
          [109.621796, 1.984836],
          [109.622076, 1.984871],
          [109.622199, 1.984976],
          [109.621954, 1.987616],
          [109.621849, 1.988315],
          [109.621954, 1.988787],
          [109.622688, 1.990204],
          [109.623702, 1.991654],
          [109.624052, 1.991864],
          [109.624209, 1.991899],
          [109.625975, 1.991287],
          [109.626936, 1.990868],
          [109.627076, 1.990885],
          [109.627111, 1.991042],
          [109.627059, 1.991287],
          [109.626517, 1.992756],
          [109.626482, 1.993123],
          [109.626674, 1.994015],
          [109.626656, 1.994399],
          [109.626517, 1.994994],
          [109.626429, 1.99564],
          [109.626482, 1.996602],
          [109.627041, 1.997598],
          [109.628073, 1.999259],
          [109.629366, 2.000763],
          [109.629489, 2.001043],
          [109.629489, 2.00169],
          [109.629594, 2.002634],
          [109.629681, 2.002931],
          [109.630118, 2.003735],
          [109.63038, 2.004434],
          [109.630363, 2.004679],
          [109.629576, 2.005833],
          [109.629471, 2.006043],
          [109.629488, 2.006253],
          [109.63003, 2.007704],
          [109.6301, 2.008106],
          [109.630083, 2.009714],
          [109.630153, 2.010886],
          [109.630118, 2.0112],
          [109.629978, 2.011515],
          [109.629314, 2.012529],
          [109.629349, 2.012914],
          [109.629488, 2.013455],
          [109.629523, 2.01391],
          [109.629698, 2.014312],
          [109.629926, 2.014609],
          [109.6303591, 2.0150152],
          [109.630765, 2.015396],
          [109.630887, 2.015728],
          [109.630974, 2.016602],
          [109.631097, 2.017022],
          [109.631272, 2.017407],
          [109.631429, 2.017686],
          [109.631953, 2.018298],
          [109.632216, 2.018893],
          [109.632233, 2.019225],
          [109.632338, 2.019609],
          [109.634209, 2.022949],
          [109.634401, 2.023071],
          [109.635328, 2.023263],
          [109.63587, 2.023578],
          [109.6365762, 2.0240997],
          [109.636674, 2.024172],
          [109.637093, 2.024644],
          [109.637303, 2.025623],
          [109.637933, 2.027074],
          [109.637985, 2.027511],
          [109.63795, 2.027966],
          [109.637793, 2.028333],
          [109.63746, 2.028613],
          [109.636674, 2.02884],
          [109.636079, 2.029102],
          [109.63545, 2.029522],
          [109.635048, 2.029889],
          [109.633719, 2.03092],
          [109.632775, 2.031795],
          [109.63031, 2.036252],
          [109.630223, 2.036515],
          [109.63024, 2.036759],
          [109.630433, 2.037057],
          [109.630607, 2.037441],
          [109.630712, 2.037948],
          [109.630887, 2.041567],
          [109.630817, 2.042319],
          [109.630223, 2.045273],
          [109.63024, 2.04606],
          [109.630502, 2.047476],
          [109.6308, 2.048298],
          [109.631796, 2.049504],
          [109.632041, 2.049942],
          [109.632513, 2.05113],
          [109.632828, 2.051602],
          [109.633632, 2.052616],
          [109.633981, 2.053281],
          [109.634279, 2.05405],
          [109.634384, 2.054539],
          [109.634453, 2.055291],
          [109.634558, 2.055588],
          [109.635048, 2.0562],
          [109.63524, 2.056655],
          [109.635415, 2.058351],
          [109.635537, 2.05891],
          [109.636079, 2.060099],
          [109.636446, 2.060781],
          [109.637478, 2.062091],
          [109.638667, 2.063735],
          [109.638964, 2.064277],
          [109.638999, 2.064486],
          [109.638859, 2.065168],
          [109.638772, 2.065378],
          [109.638772, 2.065588],
          [109.639488, 2.066444],
          [109.63996, 2.067371],
          [109.64017, 2.067913],
          [109.640363, 2.06842],
          [109.640607, 2.069976],
          [109.640835, 2.070972],
          [109.640957, 2.071252],
          [109.641971, 2.072144],
          [109.642862, 2.072826],
          [109.643334, 2.07328],
          [109.643632, 2.073595],
          [109.643876, 2.074154],
          [109.644191, 2.075133],
          [109.644209, 2.075535],
          [109.644191, 2.075902],
          [109.643964, 2.076864],
          [109.643876, 2.077563],
          [109.6439921, 2.0783214],
          [109.644034, 2.078595],
          [109.6441297, 2.0788816],
          [109.644244, 2.079224],
          [109.644453, 2.080395],
          [109.6449632, 2.0813234],
          [109.645008, 2.0814049],
          [109.6457895, 2.0809612],
          [109.6464109, 2.0807769],
          [109.6468826, 2.0806365],
          [109.6472791, 2.0804907],
          [109.6476219, 2.0802599],
          [109.6478613, 2.0800314],
          [109.6480614, 2.0797241],
          [109.6481991, 2.0792489],
          [109.6482294, 2.0787522],
          [109.6481148, 2.0784326],
          [109.6479931, 2.0781923],
          [109.6479747, 2.0780756],
          [109.6479519, 2.0779307],
          [109.6480589, 2.0774815],
          [109.6480688, 2.0774127],
          [109.6481432, 2.0772127],
          [109.6482736, 2.0771622],
          [109.6483033, 2.0771507],
          [109.6483588, 2.0771065],
          [109.6483314, 2.0770327],
          [109.6482821, 2.0768992],
          [109.6482926, 2.0767524],
          [109.6484184, 2.0764003],
          [109.648479, 2.0761037],
          [109.648425, 2.0757981],
          [109.6483725, 2.0755375],
          [109.6483803, 2.0752394],
          [109.6483945, 2.0748875],
          [109.6484051, 2.0747197],
          [109.6485461, 2.0745516],
          [109.6486191, 2.0744779],
          [109.6487482, 2.0744693],
          [109.6487746, 2.0743991],
          [109.6487579, 2.0742724],
          [109.6487757, 2.0739986],
          [109.6488618, 2.0738115],
          [109.6489233, 2.0735491],
          [109.6489568, 2.0734131],
          [109.6489358, 2.0733298],
          [109.6488579, 2.0732591],
          [109.6487712, 2.0730855],
          [109.6486775, 2.072942],
          [109.6486186, 2.0728166],
          [109.648447, 2.0727609],
          [109.6480534, 2.0726241],
          [109.6479152, 2.0725138],
          [109.6479326, 2.0723763],
          [109.6480027, 2.072293],
          [109.6480485, 2.0721961],
          [109.6480631, 2.0720726],
          [109.6479529, 2.0719637],
          [109.6478526, 2.0718818],
          [109.6477788, 2.0717574],
          [109.6477705, 2.0715134],
          [109.6478936, 2.071379],
          [109.6478575, 2.0712494],
          [109.6478148, 2.0711376],
          [109.6478447, 2.0709582],
          [109.648062, 2.0708339],
          [109.6482426, 2.0706886],
          [109.6483041, 2.070546],
          [109.648174, 2.0704089],
          [109.648136, 2.0703907],
          [109.6480065, 2.0703289],
          [109.6479888, 2.0702974],
          [109.6479313, 2.0701948],
          [109.6479874, 2.0699241],
          [109.6479695, 2.0697001],
          [109.6480243, 2.0695988],
          [109.648265, 2.0695623],
          [109.6483731, 2.0694401],
          [109.648441, 2.0692568],
          [109.6484639, 2.0689638],
          [109.6484538, 2.0687797],
          [109.6485776, 2.068618],
          [109.6485911, 2.0684246],
          [109.6485255, 2.0681536],
          [109.648364, 2.0678788],
          [109.6483368, 2.0675591],
          [109.648347, 2.0671353],
          [109.6482635, 2.06688],
          [109.6480935, 2.0667139],
          [109.6479119, 2.0666309],
          [109.6477782, 2.0667073],
          [109.647647, 2.0667133],
          [109.6476082, 2.0665716],
          [109.6475909, 2.0663012],
          [109.647522, 2.0658036],
          [109.6475473, 2.0652365],
          [109.6475175, 2.0648325],
          [109.6476273, 2.0645592],
          [109.6476499, 2.0643753],
          [109.6474575, 2.0642323],
          [109.6472504, 2.0640318],
          [109.6470831, 2.0636785],
          [109.646846, 2.0631269],
          [109.646738, 2.0628313],
          [109.6467144, 2.0624185],
          [109.6467923, 2.0620644],
          [109.6469277, 2.0617864],
          [109.6469325, 2.0616136],
          [109.6468126, 2.0614752],
          [109.6467069, 2.0613877],
          [109.6465631, 2.0613607],
          [109.6465447, 2.0612469],
          [109.6466815, 2.0610025],
          [109.6468577, 2.0608036],
          [109.6467676, 2.0600865],
          [109.6466347, 2.0593512],
          [109.6466157, 2.0588889],
          [109.6464759, 2.0587495],
          [109.6462104, 2.0585899],
          [109.6456979, 2.0579048],
          [109.6457566, 2.0576658],
          [109.6457737, 2.0575965],
          [109.6458513, 2.0572243],
          [109.6458837, 2.0569053],
          [109.6460404, 2.0565893],
          [109.6462939, 2.0564329],
          [109.6463904, 2.0564023],
          [109.6466054, 2.056334],
          [109.646777, 2.0561968],
          [109.6468574, 2.0560484],
          [109.6469014, 2.0559443],
          [109.6470288, 2.0558955],
          [109.6471256, 2.0557424],
          [109.6471613, 2.0555358],
          [109.6471633, 2.0554154],
          [109.647297, 2.0553414],
          [109.6473371, 2.0552751],
          [109.6473328, 2.0551136],
          [109.6472952, 2.054936],
          [109.6473688, 2.0546513],
          [109.6473411, 2.05428],
          [109.6471837, 2.0542911],
          [109.6470004, 2.0542769],
          [109.646701, 2.054201],
          [109.646486, 2.0541011],
          [109.6464172, 2.0538513],
          [109.646257, 2.0536556],
          [109.6461362, 2.053292],
          [109.6458958, 2.0528254],
          [109.6458104, 2.0526354],
          [109.6457538, 2.0524262],
          [109.6457826, 2.0522047],
          [109.6456147, 2.051718],
          [109.6455129, 2.0514672],
          [109.6455298, 2.0511511],
          [109.6456472, 2.0507829],
          [109.6457216, 2.0503825],
          [109.6457457, 2.0500999],
          [109.6457229, 2.0498188],
          [109.6459005, 2.0494807],
          [109.6460822, 2.0492048],
          [109.6463138, 2.0487053],
          [109.6473548, 2.0466372],
          [109.6501147, 2.0436373],
          [109.6545853, 2.0408106],
          [109.6556538, 2.0405891],
          [109.6565379, 2.0397394],
          [109.6568527, 2.0395298],
          [109.6568212, 2.038747],
          [109.6557435, 2.0376443],
          [109.6548277, 2.0373592],
          [109.6538809, 2.0375366],
          [109.6534459, 2.0372376],
          [109.6526824, 2.0355263],
          [109.6526242, 2.0343802],
          [109.652849, 2.0332582],
          [109.6533138, 2.0332385],
          [109.6535973, 2.0330875],
          [109.6539305, 2.0324735],
          [109.6537859, 2.0321666],
          [109.6532649, 2.0315252],
          [109.6527894, 2.0313548],
          [109.652622, 2.0308291],
          [109.6536866, 2.0288305],
          [109.6540996, 2.0284046],
          [109.6548997, 2.0283162],
          [109.6550013, 2.0276276],
          [109.6553901, 2.0269569],
          [109.6552206, 2.0266265],
          [109.6552935, 2.0253615],
          [109.6553966, 2.0249137],
          [109.6538936, 2.0243991],
          [109.6532668, 2.0233043],
          [109.6524648, 2.0228241],
          [109.6522528, 2.0213243],
          [109.6529094, 2.0210045],
          [109.6525127, 2.0192125],
          [109.65143, 2.0176459],
          [109.6488301, 2.0144095],
          [109.6496607, 2.0134849],
          [109.6492621, 2.0131958],
          [109.6481231, 2.0138183],
          [109.6474787, 2.0137471],
          [109.6464337, 2.010539],
          [109.6471045, 2.0063809],
          [109.6475677, 2.0056701],
          [109.6481575, 2.0050062],
          [109.6493417, 2.0028436],
          [109.650418, 2.0005178],
          [109.650435950135886, 2.0005],
          [109.651215979801449, 1.999726494544021],
          [109.651444384736493, 1.9995],
          [109.6536461, 1.9973167],
          [109.6550472, 1.9965758],
          [109.6558145, 1.99685],
          [109.6560088, 1.9966977],
          [109.655987, 1.9964665],
          [109.6556453, 1.9961868],
          [109.6558123, 1.9959105],
          [109.6561005, 1.9956788],
          [109.6565612, 1.9958089],
          [109.6568188, 1.9956853],
          [109.6567923, 1.9955145],
          [109.6570441, 1.9954323],
          [109.6572862, 1.9955461],
          [109.6573282, 1.9960854],
          [109.6576977, 1.9964877],
          [109.6583018, 1.9959148],
          [109.6583652, 1.9956501],
          [109.6581896, 1.995374],
          [109.6575522, 1.9952184],
          [109.6575718, 1.994927],
          [109.6577523, 1.9946846],
          [109.6581592, 1.9945875],
          [109.6584622, 1.9941277],
          [109.6589742, 1.993385],
          [109.6588885, 1.9930448],
          [109.6592222, 1.9926802],
          [109.6600511, 1.9925162],
          [109.6605766, 1.9928099],
          [109.6613848, 1.9924512],
          [109.6637785, 1.9913889],
          [109.6665693, 1.9878467],
          [109.6665534, 1.9843573],
          [109.6649953, 1.9846153],
          [109.6625677, 1.9864362],
          [109.6606586, 1.9874507],
          [109.6567103, 1.9871076],
          [109.6500155, 1.9819608],
          [109.6505305, 1.9761278],
          [109.6508739, 1.9690939],
          [109.649668, 1.9654892],
          [109.6482989, 1.9625746],
          [109.6515605, 1.958114],
          [109.6479556, 1.9533103],
          [109.6464107, 1.9509084],
          [109.6481273, 1.9479919],
          [109.649359, 1.944553],
          [109.6528366, 1.9408063],
          [109.6534789, 1.9358033],
          [109.6485007, 1.9349455],
          [109.6474406, 1.9316934],
          [109.6486723, 1.9255094],
          [109.6517623, 1.9220781],
          [109.6526206, 1.917789],
          [109.6507323, 1.9159018],
          [109.6531355, 1.9085245],
          [109.657618, 1.9031829],
          [109.6620619, 1.8978873],
          [109.6764815, 1.8874217],
          [109.6763299, 1.884088],
          [109.6763098, 1.8836472],
          [109.6768273, 1.8831611],
          [109.6848929, 1.8755835],
          [109.6835112, 1.8730518],
          [109.6809866, 1.8711547],
          [109.680773, 1.8680344],
          [109.6826613, 1.8640883],
          [109.6811163, 1.861],
          [109.6890685, 1.8538016],
          [109.7044623, 1.844186],
          [109.7199118, 1.8373231],
          [109.7434294, 1.8299454],
          [109.7713305, 1.8198764],
          [109.7766891, 1.8161199],
          [109.7817957, 1.8125795],
          [109.7892139, 1.8074365],
          [109.7935012, 1.8057916],
          [109.7978339, 1.8036595],
          [109.8026854, 1.8014462],
          [109.8085359, 1.7989929],
          [109.8140706, 1.7969378],
          [109.8309801, 1.7913147],
          [109.8532927, 1.7855072],
          [109.8491728, 1.7783009],
          [109.8519194, 1.7714378],
          [109.8596442, 1.7661188],
          [109.8713172, 1.7621725],
          [109.8809302, 1.7544514],
          [109.8817885, 1.7486176],
          [109.8761237, 1.7297435],
          [109.8898566, 1.7206496],
          [109.918524, 1.7100114],
          [109.9645293, 1.7005742],
          [109.9988616, 1.6961129],
          [110.00050017139624, 1.696395296538755],
          [110.0088179, 1.6978288],
          [110.0510467, 1.6981593],
          [110.0602788, 1.6975202],
          [110.0614589, 1.6974129],
          [110.0620168, 1.6972306],
          [110.061931, 1.6969035],
          [110.0620973, 1.6968553],
          [110.0622529, 1.696925],
          [110.0623763, 1.6968499],
          [110.0629342, 1.6969625],
          [110.0633579, 1.697236],
          [110.0638193, 1.6972145],
          [110.0655359, 1.6969411],
          [110.0668234, 1.6966944],
          [110.067746, 1.6964156],
          [110.0682127, 1.6962655],
          [110.0690442, 1.6958258],
          [110.0692105, 1.6956971],
          [110.069468, 1.6956274],
          [110.0697792, 1.6953003],
          [110.069688, 1.695],
          [110.0694466, 1.6946836],
          [110.0700045, 1.6949464],
          [110.070557, 1.6951555],
          [110.070895, 1.6953539],
          [110.0709379, 1.6955148],
          [110.0707072, 1.6957078],
          [110.0707072, 1.6962226],
          [110.0708681, 1.6963405],
          [110.0710612, 1.6967641],
          [110.0717425, 1.6973647],
          [110.0836623, 1.7012605],
          [110.1109564, 1.6980004],
          [110.1239963, 1.6980693],
          [110.1296542, 1.6962098],
          [110.1334903, 1.6947351],
          [110.1369365, 1.6956586],
          [110.1412908, 1.6968254],
          [110.144171714782715, 1.698042475916858],
          [110.1471853, 1.6993156],
          [110.1528418, 1.7026332],
          [110.1962721, 1.7064081],
          [110.2016338, 1.7009761],
          [110.2016396, 1.700936],
          [110.2021607, 1.6973058],
          [110.2004966, 1.6871938],
          [110.2039793, 1.6795211],
          [110.2051343, 1.6788457],
          [110.2054398, 1.6781026],
          [110.2062596, 1.6778176],
          [110.2090955, 1.6765201],
          [110.2094495, 1.6761544],
          [110.2095034, 1.6761344],
          [110.2104988, 1.6757656],
          [110.2110165, 1.6755738],
          [110.2184625, 1.6728149],
          [110.2255808, 1.6716365],
          [110.2264923, 1.6712308],
          [110.232551, 1.67128],
          [110.2377653, 1.6715424],
          [110.2414341, 1.6697339],
          [110.2430423, 1.66677],
          [110.2435951, 1.6636554],
          [110.2434946, 1.6600384],
          [110.2422601, 1.6577154],
          [110.2393735, 1.6550149],
          [110.232058, 1.6526918],
          [110.2269669, 1.6514568],
          [110.2254743, 1.6505316],
          [110.225821, 1.6496463],
          [110.2295954, 1.6498283],
          [110.2301089, 1.6495355],
          [110.2295175, 1.645545],
          [110.2306264, 1.6424782],
          [110.2323268, 1.6380443],
          [110.2353209, 1.6328713],
          [110.2347295, 1.6297676],
          [110.2330291, 1.6248533],
          [110.2346555, 1.6236709],
          [110.2374648, 1.6295459],
          [110.2384259, 1.6336842],
          [110.2329921, 1.6446951],
          [110.2326225, 1.6473924],
          [110.2330291, 1.6496094],
          [110.2355539, 1.650165],
          [110.2381893, 1.65018],
          [110.2437176, 1.6514861],
          [110.244751, 1.6484339],
          [110.2484449, 1.6458866],
          [110.2535962, 1.6437368],
          [110.2540234, 1.6422445],
          [110.2537721, 1.6402601],
          [110.2514352, 1.6331768],
          [110.2530026, 1.6300357],
          [110.2569116, 1.6280109],
          [110.2563786, 1.6307817],
          [110.2542392, 1.6333041],
          [110.2572243, 1.6413677],
          [110.2568127, 1.6438122],
          [110.2553552, 1.6464245],
          [110.2510834, 1.6491875],
          [110.2483695, 1.6508453],
          [110.2469121, 1.6524026],
          [110.2469121, 1.6555172],
          [110.2486711, 1.6616962],
          [110.2481685, 1.6685785],
          [110.2453444, 1.6732693],
          [110.2380046, 1.6763586],
          [110.2285563, 1.6771121],
          [110.2148536, 1.6799474],
          [110.2090966, 1.6828601],
          [110.2072784, 1.6863587],
          [110.2062536, 1.6883305],
          [110.2064412, 1.6892091],
          [110.2078883, 1.6926641],
          [110.2142966, 1.7016037],
          [110.232321, 1.7064081],
          [110.2654517, 1.7113841],
          [110.2711533, 1.7095231],
          [110.2752732, 1.7042039],
          [110.2769898, 1.7078072],
          [110.2802469, 1.708641],
          [110.2867377, 1.7074376],
          [110.2883644, 1.7023893],
          [110.2885361, 1.6970702],
          [110.2836688, 1.6935924],
          [110.2815679, 1.6900224],
          [110.2776812, 1.6831219],
          [110.2783835, 1.6819395],
          [110.2812298, 1.6817178],
          [110.2846492, 1.6741326],
          [110.2932628, 1.6620227],
          [110.3008417, 1.6609882],
          [110.3023123, 1.6600082],
          [110.3026975, 1.6583982],
          [110.3032227, 1.6561932],
          [110.3021023, 1.6529732],
          [110.3000714, 1.6503132],
          [110.2961848, 1.6465332],
          [110.2898471, 1.6405482],
          [110.287221, 1.6361381],
          [110.2880037, 1.6316021],
          [110.2896873, 1.6291272],
          [110.2924108, 1.6255138],
          [110.2945896, 1.6238804],
          [110.2975112, 1.6219994],
          [110.2996316, 1.6158136],
          [110.3004369, 1.6112635],
          [110.2997716, 1.6049283],
          [110.3026428, 1.6039483],
          [110.3036583, 1.6099684],
          [110.3035182, 1.6148686],
          [110.3023977, 1.6197337],
          [110.3024327, 1.6205387],
          [110.3058992, 1.6214837],
          [110.3073698, 1.6215187],
          [110.3096497, 1.6207296],
          [110.3129112, 1.6181236],
          [110.315003, 1.6154286],
          [110.3147929, 1.6126985],
          [110.3123341, 1.6089842],
          [110.3105921, 1.6048906],
          [110.3097255, 1.6014009],
          [110.3098654, 1.5999884],
          [110.3106929, 1.5960934],
          [110.3111138, 1.5969597],
          [110.3103662, 1.6007027],
          [110.3123419, 1.6061184],
          [110.3130078, 1.6079236],
          [110.3154582, 1.6119985],
          [110.3162985, 1.6117885],
          [110.3169288, 1.6102485],
          [110.3148279, 1.6055933],
          [110.3148279, 1.6020232],
          [110.3173631, 1.5968679],
          [110.3161994, 1.5946899],
          [110.3180316, 1.593799],
          [110.3189724, 1.5950364],
          [110.3221168, 1.5964472],
          [110.3218445, 1.5975114],
          [110.3187248, 1.5987984],
          [110.3166137, 1.6000982],
          [110.3158784, 1.6030383],
          [110.3165086, 1.6067834],
          [110.3181675, 1.6101829],
          [110.3184685, 1.6106081],
          [110.3203082, 1.6104405],
          [110.3240709, 1.6094006],
          [110.3297257, 1.6080531],
          [110.3307762, 1.6082806],
          [110.3318167, 1.6089256],
          [110.3321757, 1.6086905],
          [110.3326337, 1.6070075],
          [110.3327328, 1.6058443],
          [110.3326337, 1.6045945],
          [110.3313958, 1.6031466],
          [110.3314948, 1.6028868],
          [110.3329184, 1.6046069],
          [110.3330303, 1.6057938],
          [110.3329184, 1.6071189],
          [110.3326319, 1.6091206],
          [110.3339475, 1.610878],
          [110.3343239, 1.6121468],
          [110.3342528, 1.6126988],
          [110.3333144, 1.6150105],
          [110.3332424, 1.6152827],
          [110.3336023, 1.6154558],
          [110.3340466, 1.615623],
          [110.335197, 1.6156157],
          [110.3351442, 1.6157187],
          [110.3351081, 1.6157889],
          [110.3339887, 1.61582],
          [110.3334647, 1.615734],
          [110.3329949, 1.6156668],
          [110.3327768, 1.6154259],
          [110.3329774, 1.6149187],
          [110.3339071, 1.6126244],
          [110.3338775, 1.6121993],
          [110.3336335, 1.6113821],
          [110.3330109, 1.610388],
          [110.3322905, 1.6096894],
          [110.3307236, 1.6095231],
          [110.3288153, 1.6089106],
          [110.3181543, 1.6118935],
          [110.3176641, 1.6128035],
          [110.3168938, 1.6152536],
          [110.3162285, 1.6174586],
          [110.3167693, 1.6193521],
          [110.3171512, 1.6205462],
          [110.3180552, 1.6213216],
          [110.3187215, 1.6214636],
          [110.3195833, 1.620842],
          [110.322403, 1.6197173],
          [110.3240432, 1.6192523],
          [110.3254291, 1.6191724],
          [110.3268067, 1.6192497],
          [110.3278101, 1.619057],
          [110.328894, 1.6194832],
          [110.3298559, 1.6199799],
          [110.3305376, 1.6205008],
          [110.3309374, 1.6212771],
          [110.3309771, 1.622106],
          [110.3312081, 1.6224346],
          [110.3315682, 1.6227513],
          [110.3319235, 1.6228179],
          [110.3341357, 1.622778],
          [110.335646, 1.6228046],
          [110.3367033, 1.6233996],
          [110.3366677, 1.6236128],
          [110.3355217, 1.6230355],
          [110.3335138, 1.6229645],
          [110.3325543, 1.6230888],
          [110.3316304, 1.6230266],
          [110.3310973, 1.6226625],
          [110.3308039, 1.6223369],
          [110.3307153, 1.6220054],
          [110.3305573, 1.6210982],
          [110.3299423, 1.6204512],
          [110.328852, 1.6198111],
          [110.3278812, 1.6194477],
          [110.3244341, 1.619723],
          [110.3217497, 1.6206341],
          [110.318766, 1.6219698],
          [110.3171545, 1.6215046],
          [110.3153337, 1.6185296],
          [110.3136724, 1.6204687],
          [110.3075799, 1.6242837],
          [110.3034522, 1.6244396],
          [110.2994605, 1.6238096],
          [110.2970095, 1.6254897],
          [110.2956295, 1.6268503],
          [110.2933517, 1.6294737],
          [110.2917671, 1.6318496],
          [110.2907767, 1.6354134],
          [110.2935002, 1.6385318],
          [110.296792, 1.6404199],
          [110.2993481, 1.6379349],
          [110.3017641, 1.6380749],
          [110.3038649, 1.6377599],
          [110.3067712, 1.6359749],
          [110.3123035, 1.6314948],
          [110.3130347, 1.6292639],
          [110.3135216, 1.6281923],
          [110.3141592, 1.6274348],
          [110.3147215, 1.6269929],
          [110.3156211, 1.6265694],
          [110.3186076, 1.6283122],
          [110.3193342, 1.6283175],
          [110.3204459, 1.6279622],
          [110.3223367, 1.6269997],
          [110.3248402, 1.6290472],
          [110.3252399, 1.630615],
          [110.3264932, 1.6319061],
          [110.3267193, 1.6322513],
          [110.3262203, 1.633555],
          [110.3282511, 1.6354626],
          [110.3291353, 1.6365038],
          [110.3298443, 1.6371863],
          [110.3310479, 1.6377675],
          [110.330886, 1.6381043],
          [110.3296197, 1.6374648],
          [110.3255463, 1.63359],
          [110.3260627, 1.632295],
          [110.3247935, 1.631315],
          [110.3244083, 1.6307812],
          [110.3244376, 1.629861],
          [110.3240056, 1.6291887],
          [110.3224592, 1.6278747],
          [110.3192904, 1.6294147],
          [110.3179238, 1.6291672],
          [110.3160266, 1.6279364],
          [110.315601, 1.627916],
          [110.315274, 1.628073],
          [110.313693, 1.6311835],
          [110.3125632, 1.6329173],
          [110.3086269, 1.6354849],
          [110.3075065, 1.6370599],
          [110.3053356, 1.6387749],
          [110.3010638, 1.6402799],
          [110.2990679, 1.6413299],
          [110.299196, 1.6427882],
          [110.3042732, 1.6474082],
          [110.3068292, 1.6514682],
          [110.307969, 1.6567727],
          [110.3040473, 1.6730826],
          [110.2975235, 1.6797339],
          [110.2956279, 1.6816666],
          [110.2944217, 1.6857859],
          [110.2952309, 1.6886624],
          [110.2976048, 1.6888131],
          [110.3045987, 1.6921909],
          [110.3077147, 1.6942757],
          [110.3087472, 1.695453],
          [110.308957, 1.6959925],
          [110.3089348, 1.6967517],
          [110.3086994, 1.6979328],
          [110.3088771, 1.6982791],
          [110.3091272, 1.6982942],
          [110.3093168, 1.6981104],
          [110.309479, 1.6977605],
          [110.3095292, 1.6974277],
          [110.3097655, 1.6972313],
          [110.3103963, 1.6974266],
          [110.3109515, 1.6979195],
          [110.3130108, 1.7007265],
          [110.3128305, 1.7012807],
          [110.3126573, 1.7024618],
          [110.3120936, 1.7026701],
          [110.3123279, 1.703514],
          [110.3160014, 1.7054551],
          [110.3156624, 1.706298],
          [110.3181847, 1.7079491],
          [110.3169034, 1.7116972],
          [110.3159403, 1.7159722],
          [110.314427, 1.7163877],
          [110.3142623, 1.7171247],
          [110.314287, 1.7177877],
          [110.3143537, 1.7188081],
          [110.3142945, 1.7193406],
          [110.3141083, 1.7194593],
          [110.3142395, 1.7206462],
          [110.313997, 1.7212827],
          [110.3147467, 1.7233806],
          [110.3154229, 1.7246232],
          [110.3155513, 1.7246521],
          [110.3156171, 1.7249873],
          [110.3157271, 1.7251134],
          [110.3157659, 1.7252986],
          [110.3156954, 1.7253412],
          [110.3156704, 1.725572],
          [110.3154894, 1.725701],
          [110.315164, 1.7256637],
          [110.3148735, 1.7256662],
          [110.3144704, 1.7260752],
          [110.3143857, 1.7264581],
          [110.3145166, 1.7271825],
          [110.3137022, 1.7277787],
          [110.3135416, 1.7283249],
          [110.3134319, 1.7285318],
          [110.3135515, 1.7286487],
          [110.3137648, 1.730938],
          [110.3138616, 1.7310297],
          [110.3137854, 1.7311404],
          [110.3135748, 1.7313611],
          [110.3126184, 1.7313887],
          [110.3108473, 1.7324761],
          [110.3078062, 1.734608],
          [110.307273, 1.734815],
          [110.3067607, 1.7349606],
          [110.3062438, 1.7352954],
          [110.3057414, 1.7355895],
          [110.3049164, 1.7350949],
          [110.3047109, 1.7348335],
          [110.3045098, 1.734823],
          [110.3043883, 1.7350858],
          [110.3037612, 1.7360201],
          [110.3036228, 1.736145],
          [110.3033806, 1.736389],
          [110.3030957, 1.7369911],
          [110.302817, 1.7373032],
          [110.3026453, 1.7375614],
          [110.3025287, 1.7377367],
          [110.3026051, 1.7380319],
          [110.3028455, 1.7382284],
          [110.3030941, 1.7382976],
          [110.3031233, 1.7384289],
          [110.3030051, 1.7384295],
          [110.302719, 1.7385276],
          [110.3025928, 1.7388855],
          [110.3029624, 1.7392598],
          [110.3035511, 1.740481],
          [110.303965, 1.7411319],
          [110.3042558, 1.7413204],
          [110.3046294, 1.7413957],
          [110.3049703, 1.7413786],
          [110.3057007, 1.7415922],
          [110.305926, 1.7418718],
          [110.3060791, 1.7421788],
          [110.3062165, 1.7426153],
          [110.3063232, 1.743092],
          [110.3063755, 1.7435249],
          [110.3062677, 1.7440697],
          [110.3061242, 1.7443946],
          [110.3057892, 1.7443818],
          [110.3054759, 1.7445072],
          [110.305522, 1.7451046],
          [110.3057557, 1.7458284],
          [110.3061092, 1.7465619],
          [110.306493, 1.7468137],
          [110.3070308, 1.7470509],
          [110.3074691, 1.7473083],
          [110.3077657, 1.7477863],
          [110.3078145, 1.7480844],
          [110.3081302, 1.7483633],
          [110.3085216, 1.748556],
          [110.3087871, 1.7486075],
          [110.3089445, 1.7486381],
          [110.3095856, 1.7485732],
          [110.3103135, 1.7484147],
          [110.3113001, 1.7487169],
          [110.3124775, 1.7493839],
          [110.3132385, 1.7498233],
          [110.3136993, 1.7500823],
          [110.3137272, 1.7502209],
          [110.3139576, 1.7505147],
          [110.3141834, 1.750863],
          [110.3142725, 1.7511812],
          [110.3142521, 1.7512888],
          [110.3144141, 1.7514641],
          [110.3145375, 1.751659],
          [110.314604, 1.7516448],
          [110.3146488, 1.751585],
          [110.3147813, 1.7515842],
          [110.3148333, 1.751674],
          [110.3148998, 1.7518582],
          [110.3148261, 1.7520346],
          [110.3147934, 1.752093],
          [110.3145796, 1.7519354],
          [110.3144007, 1.7519356],
          [110.3142339, 1.7519263],
          [110.314244, 1.7520129],
          [110.3139546, 1.7518539],
          [110.3136566, 1.7519091],
          [110.3135164, 1.7520737],
          [110.3135424, 1.7523279],
          [110.3133943, 1.7527284],
          [110.31361, 1.7530592],
          [110.3136116, 1.7532367],
          [110.3134209, 1.753469],
          [110.3132876, 1.7537692],
          [110.3131459, 1.7541037],
          [110.3133181, 1.7544061],
          [110.3135, 1.7545204],
          [110.3137097, 1.7545209],
          [110.3138911, 1.7543616],
          [110.3143186, 1.7543997],
          [110.3147295, 1.7541734],
          [110.3149688, 1.7540539],
          [110.315222, 1.7541605],
          [110.3155074, 1.754085],
          [110.315703, 1.7540618],
          [110.3160407, 1.7541937],
          [110.3165363, 1.7545025],
          [110.3167962, 1.7546945],
          [110.317073, 1.7548433],
          [110.3174483, 1.7550066],
          [110.3177742, 1.75505],
          [110.3178351, 1.7554125],
          [110.3179673, 1.7555318],
          [110.3178106, 1.7557433],
          [110.3183238, 1.7564444],
          [110.3185924, 1.7567139],
          [110.3186187, 1.757515],
          [110.3188794, 1.7576866],
          [110.3188011, 1.7578871],
          [110.3188269, 1.7579926],
          [110.3190484, 1.7581713],
          [110.319374, 1.7582254],
          [110.3194566, 1.7586319],
          [110.319529, 1.7586922],
          [110.3197206, 1.7588517],
          [110.3196809, 1.7590999],
          [110.3197308, 1.7593328],
          [110.3199394, 1.7594565],
          [110.3201277, 1.759367],
          [110.3204163, 1.7593852],
          [110.320463, 1.7595016],
          [110.3206213, 1.7596678],
          [110.3208155, 1.7597884],
          [110.3205708, 1.7599402],
          [110.3203864, 1.760292],
          [110.3201175, 1.7604335],
          [110.320153, 1.7607922],
          [110.3203525, 1.7610297],
          [110.3202479, 1.7613648],
          [110.3202463, 1.7617364],
          [110.3199952, 1.7626522],
          [110.3201095, 1.7630581],
          [110.3200247, 1.7632506],
          [110.3195505, 1.7633787],
          [110.3188912, 1.7631235],
          [110.3184438, 1.7633455],
          [110.3184648, 1.7634962],
          [110.317725, 1.7635299],
          [110.3176447, 1.7640103],
          [110.3178087, 1.7641637],
          [110.3177433, 1.7644656],
          [110.3173318, 1.7648425],
          [110.3174214, 1.7650345],
          [110.317209, 1.7652457],
          [110.3171843, 1.766502],
          [110.3163346, 1.7665878],
          [110.3162546, 1.766813],
          [110.3165127, 1.7679605],
          [110.3167203, 1.7681095],
          [110.3170861, 1.7681374],
          [110.3173962, 1.7683583],
          [110.3186638, 1.7711663],
          [110.3191439, 1.7713422],
          [110.3192431, 1.7724735],
          [110.3197211, 1.7749689],
          [110.3202903, 1.776333],
          [110.3202447, 1.7770032],
          [110.3202533, 1.7779978],
          [110.3205596, 1.7784756],
          [110.3217263, 1.7788632],
          [110.3228024, 1.7793887],
          [110.32334, 1.7805099],
          [110.3235693, 1.7809573],
          [110.3239097, 1.7810635],
          [110.3240827, 1.7814055],
          [110.3240399, 1.7818349],
          [110.3248946, 1.783453],
          [110.3252619, 1.7840169],
          [110.3258639, 1.7842015],
          [110.3260007, 1.7846347],
          [110.3256751, 1.7846513],
          [110.3258789, 1.7852599],
          [110.3262297, 1.7857855],
          [110.3262056, 1.7868159],
          [110.3264824, 1.7870722],
          [110.3269024, 1.7869608],
          [110.3273449, 1.7873686],
          [110.3277913, 1.7874153],
          [110.3279566, 1.7879322],
          [110.3279364, 1.7887672],
          [110.3266085, 1.7925562],
          [110.3265119, 1.7931868],
          [110.3266129, 1.7941647],
          [110.3279177, 1.7977086],
          [110.328497, 1.7983231],
          [110.328806, 1.7989386],
          [110.3285244, 1.7995375],
          [110.3297593, 1.8018133],
          [110.3309196, 1.8026734],
          [110.3312586, 1.8035219],
          [110.3314153, 1.8037069],
          [110.3316553, 1.8037999],
          [110.3320392, 1.8037516],
          [110.3326891, 1.803731],
          [110.3329463, 1.8037881],
          [110.332883, 1.8039291],
          [110.333042, 1.8039747],
          [110.3331526, 1.80377],
          [110.3332405, 1.8038294],
          [110.3333459, 1.8040063],
          [110.3334969, 1.8038664],
          [110.3340063, 1.8038634],
          [110.3344062, 1.8043379],
          [110.3345859, 1.8050556],
          [110.3348303, 1.8052347],
          [110.3351395, 1.8056594],
          [110.3353954, 1.8058328],
          [110.3355094, 1.8058711],
          [110.3355276, 1.8060727],
          [110.3355976, 1.806159],
          [110.3357189, 1.8061312],
          [110.3358265, 1.805899],
          [110.335974, 1.8058068],
          [110.3362143, 1.8057859],
          [110.3365155, 1.8057794],
          [110.3374036, 1.8054006],
          [110.3380191, 1.805328],
          [110.3386189, 1.8052736],
          [110.3395488, 1.8047918],
          [110.3410243, 1.803683],
          [110.3426819, 1.802942],
          [110.3433978, 1.8020715],
          [110.3441783, 1.8012922],
          [110.3444822, 1.8011555],
          [110.3451645, 1.8003812],
          [110.3463294, 1.7985856],
          [110.3463825, 1.7977052],
          [110.3466387, 1.7968936],
          [110.3464758, 1.7963166],
          [110.3461412, 1.7955999],
          [110.3453995, 1.7952103],
          [110.344951, 1.7946463],
          [110.3443426, 1.7924793],
          [110.342871, 1.790483],
          [110.3418375, 1.7896973],
          [110.3403977, 1.788729],
          [110.3391714, 1.7884083],
          [110.338938, 1.7878681],
          [110.3380921, 1.7873692],
          [110.3377241, 1.7861049],
          [110.337193, 1.7834669],
          [110.3372107, 1.7830211],
          [110.3386757, 1.7812761],
          [110.3385717, 1.780134],
          [110.3386914, 1.7797287],
          [110.3389858, 1.7787324],
          [110.3393077, 1.7783303],
          [110.3394901, 1.7778348],
          [110.3409106, 1.7770016],
          [110.3412894, 1.7769923],
          [110.3418483, 1.7769276],
          [110.3426647, 1.7761298],
          [110.3424094, 1.7754831],
          [110.3421841, 1.7749652],
          [110.3426443, 1.7743153],
          [110.3432591, 1.7736515],
          [110.3437816, 1.7734639],
          [110.3445852, 1.7724333],
          [110.3448089, 1.771818],
          [110.3446759, 1.7713974],
          [110.3446925, 1.7709843],
          [110.3448097, 1.7706604],
          [110.3453215, 1.7698569],
          [110.3463546, 1.7690704],
          [110.3462264, 1.7688401],
          [110.3465293, 1.7678647],
          [110.3463833, 1.7677513],
          [110.3463388, 1.7675288],
          [110.3464145, 1.7672898],
          [110.3463495, 1.7669642],
          [110.3459896, 1.7667803],
          [110.34612, 1.7663262],
          [110.346099, 1.7660055],
          [110.3462567, 1.7657417],
          [110.3462439, 1.7655149],
          [110.3464917, 1.7651948],
          [110.3462702, 1.7648849],
          [110.3464611, 1.7647825],
          [110.3466821, 1.7647567],
          [110.3468822, 1.7651063],
          [110.3470394, 1.7653374],
          [110.3473637, 1.7656854],
          [110.3474235, 1.7658586],
          [110.3475641, 1.7658672],
          [110.3471665, 1.7639905],
          [110.3474147, 1.763953],
          [110.3483397, 1.7641388],
          [110.3491538, 1.7635551],
          [110.3503165, 1.7624302],
          [110.350367, 1.7615991],
          [110.3501422, 1.7611294],
          [110.3500784, 1.7609474],
          [110.3503715, 1.7605498],
          [110.3512676, 1.7599868],
          [110.3517437, 1.7598784],
          [110.3527691, 1.7600259],
          [110.3530809, 1.7597704],
          [110.3542765, 1.75879],
          [110.3546735, 1.7583155],
          [110.3544573, 1.7576946],
          [110.3543082, 1.7564624],
          [110.3538485, 1.7558785],
          [110.3537042, 1.7555724],
          [110.3535025, 1.7547268],
          [110.3537197, 1.7538179],
          [110.3535717, 1.752486],
          [110.3529934, 1.751967],
          [110.353083, 1.7510818],
          [110.3537873, 1.7499761],
          [110.3542288, 1.7491954],
          [110.3536012, 1.7479343],
          [110.3541333, 1.7463048],
          [110.3541119, 1.7452845],
          [110.3546714, 1.7446115],
          [110.3547347, 1.7434185],
          [110.354732, 1.7431075],
          [110.3544895, 1.7429086],
          [110.3545399, 1.7424936],
          [110.3550165, 1.7423274],
          [110.3550066, 1.7419011],
          [110.3562142, 1.7404078],
          [110.3563472, 1.7397252],
          [110.3570274, 1.7389783],
          [110.3569818, 1.7383209],
          [110.3572881, 1.7377118],
          [110.3570945, 1.7371279],
          [110.3568692, 1.7368512],
          [110.3568863, 1.736204],
          [110.3566039, 1.7342047],
          [110.3563461, 1.7335289],
          [110.3558998, 1.7333895],
          [110.3557561, 1.7336179],
          [110.3555254, 1.7335589],
          [110.3555432, 1.733353],
          [110.3554106, 1.7331879],
          [110.3550968, 1.7328303],
          [110.3550292, 1.7322775],
          [110.3543055, 1.7321134],
          [110.3542771, 1.7318206],
          [110.3536232, 1.7315997],
          [110.3535427, 1.7313396],
          [110.3541457, 1.7310394],
          [110.354401, 1.7302061],
          [110.3541253, 1.7299461],
          [110.3544112, 1.7291868],
          [110.3546263, 1.7287739],
          [110.3552958, 1.7281364],
          [110.3560709, 1.7274442],
          [110.3569475, 1.7268302],
          [110.3574748, 1.7267342],
          [110.3579265, 1.7264597],
          [110.3579367, 1.724901],
          [110.3574201, 1.7241299],
          [110.3570473, 1.7242186],
          [110.3561836, 1.7243514],
          [110.3550225, 1.7240026],
          [110.3540312, 1.7231752],
          [110.3532281, 1.7218996],
          [110.3529218, 1.7211184],
          [110.353079, 1.7188443],
          [110.3531906, 1.7182604],
          [110.3534631, 1.7173494],
          [110.3540339, 1.7165247],
          [110.3541918, 1.715577],
          [110.3544997, 1.7150596],
          [110.3550678, 1.7144767],
          [110.3555522, 1.7139164],
          [110.3568327, 1.7127201],
          [110.3588449, 1.7117238],
          [110.3632201, 1.7097619],
          [110.3633596, 1.7093844],
          [110.3633542, 1.7091737],
          [110.3645333, 1.7087077],
          [110.364771, 1.7083876],
          [110.3661807, 1.708016],
          [110.369489, 1.7067484],
          [110.3697883, 1.7063725],
          [110.3702384, 1.7061141],
          [110.3704476, 1.7058717],
          [110.3711165, 1.7055087],
          [110.3716931, 1.7048681],
          [110.3727355, 1.7026089],
          [110.3739715, 1.7007177],
          [110.3737797, 1.7001327],
          [110.3731303, 1.7003992],
          [110.3729117, 1.6974856],
          [110.3733256, 1.6927105],
          [110.3749594, 1.6914753],
          [110.3769144, 1.6899973],
          [110.3818668, 1.6916478],
          [110.3837905, 1.6925325],
          [110.3853804, 1.6926265],
          [110.3869866, 1.6925121],
          [110.3879082, 1.692776],
          [110.3928939, 1.6922612],
          [110.3984708, 1.6898787],
          [110.4065573, 1.6909654],
          [110.4161704, 1.6830724],
          [110.4197844, 1.6797694],
          [110.4216512, 1.6786626],
          [110.4238656, 1.6796922],
          [110.4256469, 1.6810966],
          [110.4289703, 1.6907605],
          [110.4299581, 1.6936329],
          [110.4324782, 1.6933676],
          [110.4345381, 1.6971425],
          [110.4390013, 1.7012605],
          [110.4414703, 1.707644],
          [110.4425017, 1.7100185],
          [110.4409052, 1.7122147],
          [110.439796, 1.7141549],
          [110.4387642, 1.7159616],
          [110.4400151, 1.7166477],
          [110.4411052, 1.7159796],
          [110.4431104, 1.7169212],
          [110.4444955, 1.7183379],
          [110.4454493, 1.71917],
          [110.4459859, 1.7198156],
          [110.4455233, 1.7205159],
          [110.4433582, 1.7223518],
          [110.4417553, 1.7243422],
          [110.4422816, 1.7252441],
          [110.4431066, 1.7249074],
          [110.4436452, 1.7255004],
          [110.4432466, 1.7272763],
          [110.4500833, 1.7307038],
          [110.4506739, 1.730359],
          [110.4514571, 1.7307553],
          [110.4514325, 1.7315628],
          [110.4517043, 1.7318025],
          [110.4513294, 1.7326287],
          [110.4520697, 1.7329182],
          [110.4520912, 1.733508],
          [110.454312, 1.7337654],
          [110.4547512, 1.7311806],
          [110.4550422, 1.7311893],
          [110.4559106, 1.7310951],
          [110.4574341, 1.7314061],
          [110.457698, 1.7314943],
          [110.45906, 1.7319494],
          [110.4589891, 1.7324996],
          [110.4591025, 1.7329986],
          [110.4596925, 1.734693],
          [110.4605616, 1.7344785],
          [110.4601002, 1.7349718],
          [110.4602183, 1.7354008],
          [110.460744, 1.7357654],
          [110.460583, 1.7365804],
          [110.4595263, 1.737626],
          [110.4596088, 1.7383307],
          [110.4604304, 1.7378343],
          [110.4606689, 1.7380013],
          [110.4599608, 1.7386072],
          [110.4596872, 1.7389665],
          [110.4603303, 1.7391001],
          [110.4629327, 1.7378137],
          [110.4637373, 1.7366877],
          [110.46466, 1.734189],
          [110.465808, 1.7345536],
          [110.4656471, 1.7356367],
          [110.4669238, 1.7342104],
          [110.4685271, 1.7333468],
          [110.4701961, 1.7314222],
          [110.471226, 1.7324088],
          [110.4734071, 1.7279812],
          [110.4802, 1.7265478],
          [110.4890788, 1.7280978],
          [110.4941122, 1.7343251],
          [110.4969385, 1.7379476],
          [110.4981769, 1.7395349],
          [110.4970229, 1.745186],
          [110.4985808, 1.7443397],
          [110.5000072, 1.743816],
          [110.500295, 1.7441124],
          [110.5019284, 1.7457945],
          [110.5044043, 1.7489608],
          [110.5138457, 1.7412396],
          [110.514189, 1.7383227],
          [110.5176222, 1.7364353],
          [110.5190422, 1.7303801],
          [110.5191094, 1.7301987],
          [110.5193333, 1.7295945],
          [110.5201484, 1.7287507],
          [110.5224645, 1.726823],
          [110.5237962, 1.7257146],
          [110.526377, 1.7235665],
          [110.5203775, 1.7222908],
          [110.5186522, 1.720478],
          [110.513159, 1.7206496],
          [110.5071509, 1.7153305],
          [110.5006278, 1.710183],
          [110.4980528, 1.7064081],
          [110.4980528, 1.7014321],
          [110.4935896, 1.6904506],
          [110.4927313, 1.6851314],
          [110.4889548, 1.6837587],
          [110.4904997, 1.6739782],
          [110.4894698, 1.6696885],
          [110.481745, 1.6690021],
          [110.4791701, 1.6647124],
          [110.4788268, 1.65905],
          [110.4800284, 1.6528728],
          [110.4850066, 1.6516716],
          [110.4892981, 1.652358],
          [110.4930747, 1.6506421],
          [110.5049789, 1.6287617],
          [110.5068672, 1.6304776],
          [110.5101288, 1.6277322],
          [110.5118454, 1.6285901],
          [110.5157936, 1.6196673],
          [110.5192268, 1.6157207],
          [110.5226601, 1.6064546],
          [110.5240334, 1.5975317],
          [110.525235, 1.5922123],
          [110.5291753, 1.5866659],
          [110.5336464, 1.5803722],
          [110.5372652, 1.5809],
          [110.5413115, 1.5814901],
          [110.5413712, 1.5846621],
          [110.5436461, 1.5865704],
          [110.5464588, 1.584963],
          [110.5471887, 1.5846205],
          [110.5474839, 1.584087],
          [110.5477384, 1.5841314],
          [110.5475131, 1.5846589],
          [110.5472585, 1.5848853],
          [110.5474087, 1.5858622],
          [110.548991, 1.5862688],
          [110.5499091, 1.5872203],
          [110.5520142, 1.5904963],
          [110.556649, 1.5927271],
          [110.5657471, 1.5966738],
          [110.5727852, 1.5946146],
          [110.5782784, 1.5965022],
          [110.5815399, 1.5959874],
          [110.5848015, 1.5973601],
          [110.588578, 1.5940998],
          [110.5908096, 1.5980465],
          [110.5971611, 1.5994193],
          [110.601393, 1.5971052],
          [110.6043709, 1.5946146],
          [110.6093491, 1.5966738],
          [110.6213654, 1.594443],
          [110.6318367, 1.587236],
          [110.6314934, 1.5827745],
          [110.6369865, 1.5819166],
          [110.6416214, 1.5856917],
          [110.6419647, 1.5765971],
          [110.6474579, 1.5788278],
          [110.6572426, 1.5747095],
          [110.665139, 1.5681888],
          [110.665139, 1.5651001],
          [110.6685722, 1.5628693],
          [110.6723488, 1.5652717],
          [110.6800735, 1.558751],
          [110.6840218, 1.5594374],
          [110.6931198, 1.5575498],
          [110.6963814, 1.5597806],
          [110.6984482, 1.5563812],
          [110.7099426, 1.5539462],
          [110.7208693, 1.551289],
          [110.7297957, 1.546141],
          [110.7354605, 1.5396202],
          [110.7390654, 1.5305254],
          [110.7409537, 1.522975],
          [110.7430136, 1.5118209],
          [110.7549468, 1.5113391],
          [110.7653442, 1.5106853],
          [110.7673895, 1.5228034],
          [110.7684195, 1.5373894],
          [110.7668746, 1.5454546],
          [110.7665387, 1.5502224],
          [110.7660163, 1.5576381],
          [110.7684195, 1.563644],
          [110.7704794, 1.567076],
          [110.7758009, 1.5667328],
          [110.7867873, 1.5770286],
          [110.7934821, 1.5775434],
          [110.8091033, 1.574283],
          [110.8181716, 1.5692531],
          [110.8511643, 1.550953],
          [110.8551085, 1.5500878],
          [110.86043, 1.546999],
          [110.8654082, 1.5430522],
          [110.8741629, 1.5372178],
          [110.8824027, 1.5334426],
          [110.8908141, 1.5312118],
          [110.9038603, 1.5284662],
          [110.9126151, 1.5258922],
          [110.9238911, 1.5251522],
          [110.9267235, 1.524066],
          [110.9292126, 1.5237013],
          [110.9311208, 1.523423],
          [110.9330291, 1.5231448],
          [110.9408043, 1.5211251],
          [110.9821379, 1.4944891],
          [110.9934676, 1.4862522],
          [111.00050017139624, 1.479221913338594],
          [111.002394, 1.4773287],
          [111.0102721, 1.4664571],
          [111.0211051, 1.4423212],
          [111.0291731, 1.4318531],
          [111.031265495535635, 1.429653167724609],
          [111.0391295, 1.421385],
          [111.0497725, 1.4119466],
          [111.0586989, 1.4052538],
          [111.0708869, 1.3999339],
          [111.07844, 1.3973597],
          [111.0868514, 1.3970165],
          [111.1017859, 1.3951288],
          [111.1138022, 1.424474],
          [111.099726, 1.4296222],
          [111.099647451243982, 1.429653167724609],
          [111.087538, 1.4344273],
          [111.0786116, 1.4373446],
          [111.0703719, 1.441978],
          [111.0640204, 1.4471262],
          [111.0590422, 1.4545053],
          [111.056124, 1.4606831],
          [111.0552657, 1.4672041],
          [111.0530341, 1.4773287],
          [111.0511458, 1.4840213],
          [111.0451376, 1.4910571],
          [111.0410178, 1.4999804],
          [111.0368979, 1.5068445],
          [111.0343498, 1.5104451],
          [111.0316783, 1.5130115],
          [111.0283631, 1.5154162],
          [111.0251445, 1.5175344],
          [111.022709, 1.5189716],
          [111.0198981, 1.5204707],
          [111.0154617, 1.5224251],
          [111.0125112, 1.5235941],
          [111.0101616, 1.5242406],
          [111.0079622, 1.5245486],
          [111.0063153, 1.5245915],
          [111.0045558, 1.524436],
          [111.0030324, 1.5240582],
          [111.0013854, 1.5229881],
          [111.0013372, 1.5228219],
          [111.0009509, 1.5226288],
          [111.0006612, 1.5219639],
          [110.9997439, 1.521122],
          [110.9992987, 1.5211839],
          [110.9981668, 1.5209235],
          [110.9977055, 1.5209718],
          [110.9966433, 1.5215563],
          [110.9961498, 1.521921],
          [110.9951413, 1.5236209],
          [110.9951359, 1.5240928],
          [110.9954953, 1.5245033],
          [110.9958333, 1.5259643],
          [110.995785, 1.5266025],
          [110.9970188, 1.5290102],
          [110.999127, 1.5326138],
          [110.9992933, 1.5336327],
          [110.9995347, 1.5340807],
          [111.0001355, 1.5345229],
          [110.9998459, 1.5348607],
          [111.0006076, 1.5367],
          [111.0009295, 1.5392794],
          [110.999819, 1.5421942],
          [110.99949982860376, 1.543842676334653],
          [110.9977591, 1.5528333],
          [110.9955275, 1.5622712],
          [110.9943259, 1.5729103],
          [110.9977591, 1.5785729],
          [111.000334, 1.5825196],
          [111.0025656, 1.5895551],
          [111.0073721, 1.6098032],
          [111.0126936, 1.6369148],
          [111.0166419, 1.6501273],
          [111.0219634, 1.6575057],
          [111.0276282, 1.6664283],
          [111.034838, 1.6739782],
          [111.0411894, 1.6777531],
          [111.0453093, 1.6796406],
          [111.0501158, 1.6787827],
          [111.0718799, 1.6750027],
          [111.0873663, 1.6792974],
          [111.0998976, 1.6837587],
          [111.1186087, 1.6804985],
          [111.1265051, 1.6777531],
          [111.139718, 1.67791],
          [111.1416306, 1.6879844],
          [111.1434996, 1.6978288],
          [111.131655, 1.6990299],
          [111.1252246, 1.7009018],
          [111.118952, 1.7062365],
          [111.1117423, 1.7167032],
          [111.1087259, 1.7265921],
          [111.109339, 1.7321457],
          [111.1036742, 1.739009],
          [111.097666, 1.7438133],
          [111.0855971, 1.7572691],
          [111.0940611, 1.7702367],
          [111.1041892, 1.7819041],
          [111.1045394, 1.787714],
          [111.1048708, 1.7932135],
          [111.1017859, 1.8023218],
          [111.1019576, 1.8162194],
          [111.1062441, 1.8307885],
          [111.1160338, 1.8527646],
          [111.1358935, 1.8913249],
          [111.1452162, 1.9105833],
          [111.1642706, 1.9436952],
          [111.1660857, 1.9468179],
          [111.1674911, 1.9486636],
          [111.167723, 1.9499761],
          [111.1682947, 1.9512101],
          [111.1688692, 1.952896],
          [111.1696626, 1.9560901],
          [111.1688569, 1.9572782],
          [111.1678796, 1.9587194],
          [111.1673671, 1.9594751],
          [111.1665474, 1.9634644],
          [111.1669521, 1.9659551],
          [111.1673567, 1.9680372],
          [111.1684148, 1.973232],
          [111.1694643, 1.9781481],
          [111.171741, 1.9873405],
          [111.1736703, 1.9938289],
          [111.17532069018867, 1.9995],
          [111.1754396, 1.9999086],
          [111.1775556, 2.0065591],
          [111.1785095, 2.0084463],
          [111.1797725, 2.0114695],
          [111.1817295, 2.0163065],
          [111.1836724, 2.0210052],
          [111.1840478, 2.0219131],
          [111.1853433, 2.0253436],
          [111.1872419, 2.0290005],
          [111.1890671, 2.0330055],
          [111.1896742, 2.0351891],
          [111.1904484, 2.0368813],
          [111.1943068, 2.0441133],
          [111.1966633, 2.0474323],
          [111.1977365, 2.0509894],
          [111.1983567, 2.0552934],
          [111.1987387, 2.0554751],
          [111.1988058, 2.0565346],
          [111.2003335, 2.0616951],
          [111.2019529, 2.066764],
          [111.2029855, 2.0689381],
          [111.2043478, 2.0743866],
          [111.2051044, 2.0780882],
          [111.2066496, 2.0872654],
          [111.2081409, 2.0924011],
          [111.2089295, 2.0943256],
          [111.2094391, 2.0951566],
          [111.210115, 2.0958535],
          [111.2105603, 2.0961161],
          [111.211373, 2.0961509],
          [111.2128441, 2.096613],
          [111.2163333, 2.0977088],
          [111.2256792, 2.1002242],
          [111.2262354, 2.1003739],
          [111.2301886, 2.1054707],
          [111.2320769, 2.1125041],
          [111.2358534, 2.1198805],
          [111.2494096, 2.1332461],
          [111.2441122, 2.1411972],
          [111.238085, 2.1502435],
          [111.2266698, 2.1476371],
          [111.2150824, 2.1490427],
          [111.2034094, 2.1435534],
          [111.1802352, 2.1370348],
          [111.1668456, 2.1528166],
          [111.1589441, 2.157948],
          [111.1628974, 2.1704851],
          [111.1628974, 2.1778612],
          [111.1661589, 2.1843797],
          [111.1728537, 2.1888396],
          [111.1819303, 2.1922703],
          [111.189419, 2.1980382],
          [111.1903417, 2.1998072],
          [111.1925733, 2.2012116],
          [111.1931054, 2.2025477],
          [111.1932671, 2.2029494],
          [111.1935136, 2.2031465],
          [111.1948076, 2.2040455],
          [111.1986528, 2.2066193],
          [111.2002303, 2.2078631],
          [111.2036194, 2.2114222],
          [111.2053572, 2.2138236],
          [111.2080562, 2.2185772],
          [111.2093502, 2.2214835],
          [111.2108538, 2.2259661],
          [111.2115932, 2.2277272],
          [111.2128256, 2.2299439],
          [111.2143538, 2.2323453],
          [111.216868, 2.2352146],
          [111.2175951, 2.2356826],
          [111.2188768, 2.2363476],
          [111.2198666, 2.2364383],
          [111.2205198, 2.2363274],
          [111.2213209, 2.2364136],
          [111.2214565, 2.2363644],
          [111.2234037, 2.2331379],
          [111.2265217, 2.2289632],
          [111.2286415, 2.2233722],
          [111.2299109, 2.2198871],
          [111.2305764, 2.217461],
          [111.2314884, 2.2152074],
          [111.2348282, 2.2091977],
          [111.2356416, 2.2080893],
          [111.2368617, 2.2061805],
          [111.238661, 2.2026214],
          [111.240103, 2.2003801],
          [111.2408424, 2.1987299],
          [111.2425308, 2.1965501],
          [111.2433689, 2.1951338],
          [111.2460063, 2.1926954],
          [111.2484341, 2.1913038],
          [111.2569132, 2.1884836],
          [111.2613622, 2.1881634],
          [111.2637285, 2.1884344],
          [111.2653218, 2.1884796],
          [111.2658975, 2.1884959],
          [111.276102, 2.1930403],
          [111.2808468, 2.1958235],
          [111.2817957, 2.1962422],
          [111.2838785, 2.1985821],
          [111.2857395, 2.2015377],
          [111.2866119, 2.2032662],
          [111.2871691, 2.2043702],
          [111.2880564, 2.2070302],
          [111.2887712, 2.2103307],
          [111.2894121, 2.2154044],
          [111.2897448, 2.2157493],
          [111.2896586, 2.2166729],
          [111.2899297, 2.2195669],
          [111.2897448, 2.2227934],
          [111.2888452, 2.2268327],
          [111.2880441, 2.2320665],
          [111.2877976, 2.2357733],
          [111.2880441, 2.2399603],
          [111.2884631, 2.2419922],
          [111.2885617, 2.2516839],
          [111.2877976, 2.2589988],
          [111.2853451, 2.2687273],
          [111.2820422, 2.2780002],
          [111.2817588, 2.2798596],
          [111.281882, 2.2811896],
          [111.2826091, 2.2819777],
          [111.2832171, 2.282168],
          [111.2930508, 2.2896852],
          [111.3019389, 2.2964795],
          [111.301231, 2.2983066],
          [111.2995056, 2.3000059],
          [111.294958, 2.3031338],
          [111.2946129, 2.3037864],
          [111.294884, 2.3043775],
          [111.2959069, 2.3046115],
          [111.2975337, 2.3044391],
          [111.2997644, 2.3039219],
          [111.3013173, 2.3038849],
          [111.3029317, 2.304242],
          [111.3064071, 2.3069881],
          [111.3074917, 2.3083919],
          [111.3079477, 2.3098327],
          [111.3082804, 2.3118153],
          [111.3086255, 2.3144382],
          [111.308909, 2.3153741],
          [111.308848, 2.3211599],
          [111.3088116, 2.3246067],
          [111.3088035, 2.3253716],
          [111.3094043, 2.3258433],
          [111.3099964, 2.3258058],
          [111.3155412, 2.3268295],
          [111.3183522, 2.3268724],
          [111.3206911, 2.3275584],
          [111.3226223, 2.3294452],
          [111.3248109, 2.3336903],
          [111.3251757, 2.336263],
          [111.325047, 2.3395433],
          [111.3243174, 2.3427379],
          [111.3223648, 2.3454178],
          [111.318202, 2.3490197],
          [111.312859, 2.3515924],
          [111.3103055, 2.3532647],
          [111.3087391, 2.354594],
          [111.3079023, 2.3558589],
          [111.3079237, 2.3571453],
          [111.3088464, 2.3591606],
          [111.3103699, 2.3610258],
          [111.3118161, 2.362273],
          [111.3133396, 2.3633182],
          [111.3230385, 2.369139],
          [111.3243259, 2.3696321],
          [111.3256979, 2.3698773],
          [111.3277994, 2.3702083],
          [111.3287409, 2.3700127],
          [111.329508, 2.3696214],
          [111.3306131, 2.3686674],
          [111.3548388, 2.3397883],
          [111.356597, 2.3383694],
          [111.3573255, 2.3378706],
          [111.3579976, 2.3374878],
          [111.3590065, 2.3371515],
          [111.3602627, 2.3367328],
          [111.3621614, 2.3361159],
          [111.367223, 2.3360307],
          [111.369025, 2.3364066],
          [111.3743189, 2.3395038],
          [111.375504, 2.3407698],
          [111.3809313, 2.3495648],
          [111.3860811, 2.3624285],
          [111.3893427, 2.3644867],
          [111.4088722, 2.3596254],
          [111.4166369, 2.3598558],
          [111.4283098, 2.367917],
          [111.4396395, 2.3766642],
          [111.447736740112305, 2.369456495067273],
          [111.4490809, 2.36826],
          [111.4542663, 2.364419],
          [111.4590372, 2.3608849],
          [111.4658741, 2.3673467],
          [111.4717402, 2.3728909],
          [111.4633288, 2.3788939],
          [111.447736740112305, 2.38772582460654],
          [111.4415278, 2.3912428],
          [111.4315714, 2.3974172],
          [111.4190401, 2.4099375],
          [111.4133753, 2.418856],
          [111.4113154, 2.4279459],
          [111.413032, 2.445268],
          [111.4112012, 2.4580557],
          [111.4086571, 2.464275],
          [111.4065459, 2.4672314],
          [111.3942943, 2.4821216],
          [111.3928147, 2.4835277],
          [111.3911006, 2.4861055],
          [111.390884, 2.4873854],
          [111.3957017, 2.4912251],
          [111.4013855, 2.4913873],
          [111.4147094, 2.4897816],
          [111.4256606, 2.4868997],
          [111.4314971, 2.4852168],
          [111.4374945, 2.4827515],
          [111.4410306, 2.4912481],
          [111.4435794, 2.4966592],
          [111.434769, 2.5002652],
          [111.4255053, 2.5029336],
          [111.4148942, 2.5049691],
          [111.4094992, 2.5071143],
          [111.405728, 2.5092053],
          [111.4043748, 2.5105573],
          [111.3987932, 2.524121],
          [111.3970483, 2.531215],
          [111.3963201, 2.5359898],
          [111.3965205, 2.5446852],
          [111.3990871, 2.563779],
          [111.413286, 2.6086341],
          [111.4195128, 2.6298989],
          [111.4195311, 2.6299612],
          [111.4259066, 2.6562022],
          [111.4289725, 2.6683723],
          [111.4284575, 2.6796896],
          [111.4281151, 2.6884686],
          [111.4290871, 2.6933442],
          [111.4295687, 2.6950964],
          [111.431765, 2.6991646],
          [111.4333319, 2.7010044],
          [111.4344129, 2.7018857],
          [111.4387736, 2.7046821],
          [111.4410445, 2.7058814],
          [111.4431947, 2.7069841],
          [111.4477177, 2.7088367],
          [111.447736740112305, 2.70884336899767],
          [111.4512505, 2.7100741],
          [111.4535101, 2.7107156],
          [111.4562179, 2.7112266],
          [111.4628169, 2.7123692],
          [111.4661256, 2.7135637],
          [111.4671756, 2.7133166],
          [111.4679636, 2.7131312],
          [111.4738009, 2.7173496],
          [111.4737675, 2.7177639],
          [111.4734662, 2.7214918],
          [111.4741019, 2.7247355],
          [111.4762377, 2.7324767],
          [111.477382, 2.7350807],
          [111.4784041, 2.7364944],
          [111.4811874, 2.7392087],
          [111.4851165, 2.7420145],
          [111.4896891, 2.7445616],
          [111.4943731, 2.746557],
          [111.5245879, 2.7563359],
          [111.5447019, 2.7649864],
          [111.5455866, 2.7653669],
          [111.5939951, 2.7902285],
          [111.6052687, 2.800401],
          [111.6121352, 2.8134316],
          [111.6145384, 2.8218329],
          [111.6300439, 2.8445794],
          [111.6387427, 2.8492651],
          [111.6466391, 2.8490936],
          [111.6516733, 2.8447508],
          [111.6552222, 2.8377779],
          [111.6571475, 2.8293846],
          [111.6648352, 2.8266336],
          [111.6717577, 2.8258912],
          [111.683774, 2.813718],
          [111.6927004, 2.8121749],
          [111.6976225, 2.8094882],
          [111.702944, 2.8055447],
          [111.7104971, 2.8074307],
          [111.7118816, 2.8076119],
          [111.7128315, 2.8075232],
          [111.7138435, 2.8072306],
          [111.7153971, 2.8063705],
          [111.7190103, 2.8047301],
          [111.7236889, 2.802132],
          [111.7282685, 2.8115327],
          [111.7246441, 2.8135535],
          [111.7212075, 2.8150765],
          [111.709126, 2.8194761],
          [111.7076587, 2.8198584],
          [111.705209, 2.8209671],
          [111.7029635, 2.8224963],
          [111.6976175, 2.824943],
          [111.6879956, 2.8307564],
          [111.6792863, 2.8336214],
          [111.6777658, 2.8354925],
          [111.6845741, 2.8405942],
          [111.6892671, 2.8413218],
          [111.7118081, 2.8481537],
          [111.790324, 2.864185],
          [111.8282345, 2.870756],
          [111.8406991, 2.8728748],
          [111.8553087, 2.8749732],
          [111.8653008, 2.8761378],
          [111.8729309, 2.8763707],
          [111.8747556, 2.8759004],
          [111.8778749, 2.8727698],
          [111.8793844, 2.871968],
          [111.8808304, 2.871691],
          [111.8818774, 2.8717828],
          [111.8831252, 2.8731523],
          [111.8838439, 2.8733628],
          [111.9071997, 2.8763396],
          [111.9232207, 2.8780072],
          [111.9406357, 2.8806915],
          [111.9752303, 2.8866343],
          [111.9874582, 2.889122],
          [111.9966369, 2.8907136],
          [111.997829, 2.8907074],
          [111.9988173, 2.8905038],
          [111.9994535, 2.8906519],
          [112.0003553, 2.8911331],
          [112.00050047634258, 2.891171202515077],
          [112.006631, 2.8927802],
          [112.0148399, 2.8943718],
          [112.0191822, 2.8951799],
          [112.0206955, 2.8951552],
          [112.0211464, 2.8948344],
          [112.0213749, 2.8953279],
          [112.0222891, 2.8959387],
          [112.0362433, 2.8993688],
          [112.0544392, 2.9033105],
          [112.0566629, 2.9036189],
          [112.0571385, 2.9035449],
          [112.0575956, 2.9032364],
          [112.0578612, 2.9037176],
          [112.058553, 2.9041186],
          [112.0895927, 2.9115926],
          [112.0915815, 2.9119621],
          [112.0919597, 2.9116622],
          [112.0937977, 2.9103368],
          [112.0944494, 2.9099728],
          [112.0951417, 2.9098429],
          [112.095692, 2.9098426],
          [112.0964201, 2.9098406],
          [112.0962928, 2.9118995],
          [112.0964058, 2.9126149],
          [112.0966541, 2.9128972],
          [112.097445, 2.9138055],
          [112.0982804, 2.9143653],
          [112.0986278, 2.9145164],
          [112.0989984, 2.9145863],
          [112.0999883, 2.9145565],
          [112.1008412, 2.9145602],
          [112.1021301, 2.9145951],
          [112.1039457, 2.9148267],
          [112.1061218, 2.9151775],
          [112.139326, 2.921828],
          [112.1499635, 2.9245974],
          [112.1614009, 2.9273459],
          [112.1773866, 2.9316555],
          [112.2471578, 2.9530749],
          [112.2814108, 2.9628111],
          [112.3019618, 2.9678164],
          [112.3200273, 2.9719276],
          [112.3447117, 2.9765147],
          [112.3463435, 2.9765286],
          [112.3480398, 2.9762953],
          [112.349173, 2.9763009],
          [112.350151, 2.9763058],
          [112.3506244, 2.9767289],
          [112.3510811, 2.9769056],
          [112.3516791, 2.9774447],
          [112.3777112, 2.9825631],
          [112.3975916, 2.9869711],
          [112.4035302, 2.9882729],
          [112.4052548, 2.9883392],
          [112.4063099, 2.9879283],
          [112.4066152, 2.9866891],
          [112.4066683, 2.9876367],
          [112.4066484, 2.9881669],
          [112.407458, 2.9888959],
          [112.4081713, 2.989126],
          [112.4319525, 2.9945035],
          [112.4440998, 2.9968204],
          [112.4457985, 2.9967674],
          [112.456286634786352, 2.9995],
          [112.4640777, 3.0015299],
          [112.469046, 3.0025257],
          [112.4706758, 3.0023079],
          [112.4716115, 3.0017645],
          [112.4718549, 3.0016584],
          [112.4719765, 3.0016054],
          [112.4722618, 3.0022814],
          [112.4728922, 3.0023742],
          [112.4766657, 3.0039008],
          [112.5648038, 3.0205015],
          [112.574171, 3.0202908],
          [112.5771449, 3.0189488],
          [112.580223, 3.0160999],
          [112.5826253, 3.0151253],
          [112.5835533, 3.0142249],
          [112.5843632, 3.014811],
          [112.5850933, 3.0149985],
          [112.5849926, 3.0160767],
          [112.5872799, 3.0170371],
          [112.5938489, 3.018499],
          [112.59749, 3.0241967],
          [112.6073122, 3.03093],
          [112.6120183, 3.0346857],
          [112.6208374, 3.0380284],
          [112.6375744, 3.0433638],
          [112.6691172, 3.0533489],
          [112.6875064, 3.05892],
          [112.7011535, 3.0624983],
          [112.717869, 3.0662695],
          [112.7620718, 3.0774114],
          [112.7793667, 3.0833466],
          [112.7833364, 3.0863677],
          [112.7904603, 3.0858107],
          [112.7936575, 3.0847179],
          [112.7954221, 3.0840043],
          [112.797115, 3.0844206],
          [112.7996921, 3.0850542],
          [112.8046646, 3.085061],
          [112.8206724, 3.0873746],
          [112.8391925, 3.0909869],
          [112.8661616, 3.0969862],
          [112.8808858, 3.1002616],
          [112.881503, 3.1004453],
          [112.9236679, 3.1129979],
          [112.9414873, 3.1222073],
          [112.9557592, 3.1293667],
          [112.9680556, 3.1360669],
          [112.9787851, 3.1415596],
          [112.9982147, 3.1540943],
          [113.000500934343535, 3.155756190796386],
          [113.0047137, 3.1588185],
          [113.0076912, 3.1608233],
          [113.0080421, 3.1608011],
          [113.0078865, 3.1605968],
          [113.0080458, 3.1604184],
          [113.0081542, 3.1606471],
          [113.0081142, 3.1610689],
          [113.0082365, 3.1612125],
          [113.0082719, 3.161544],
          [113.0092735, 3.1625323],
          [113.010529, 3.163517],
          [113.0157567, 3.1677149],
          [113.0184442, 3.1698861],
          [113.0191215, 3.1704172],
          [113.0208142, 3.1718333],
          [113.0211374, 3.1720195],
          [113.0212675, 3.1723074],
          [113.0231239, 3.1740288],
          [113.0259855, 3.1762359],
          [113.0273529, 3.1773095],
          [113.0290588, 3.1781793],
          [113.0302257, 3.1789953],
          [113.0310821, 3.179368],
          [113.0315372, 3.1792621],
          [113.031601, 3.1789051],
          [113.0326251, 3.1796539],
          [113.033505, 3.1810093],
          [113.0326101, 3.1825416],
          [113.0323732, 3.1829718],
          [113.0313655, 3.1847414],
          [113.0309429, 3.1856554],
          [113.0307564, 3.1860588],
          [113.0306346, 3.1865025],
          [113.0304659, 3.1866637],
          [113.03056, 3.1869053],
          [113.0305134, 3.187615],
          [113.0306493, 3.188442],
          [113.0308183, 3.1889353],
          [113.0310662, 3.1894347],
          [113.0312241, 3.1896527],
          [113.0311828, 3.1897955],
          [113.0313132, 3.189962],
          [113.0314787, 3.1899079],
          [113.0316107, 3.1899366],
          [113.0321087, 3.1891833],
          [113.0325095, 3.1891388],
          [113.032808, 3.1896511],
          [113.0331368, 3.1893908],
          [113.0345243, 3.1903046],
          [113.0346611, 3.1905515],
          [113.0362907, 3.1915135],
          [113.0363619, 3.1915555],
          [113.0361315, 3.191939],
          [113.0373586, 3.1926117],
          [113.0374051, 3.1926954],
          [113.0375574, 3.1929692],
          [113.0374222, 3.1932378],
          [113.0379957, 3.1936168],
          [113.0388025, 3.1943851],
          [113.0389629, 3.1942785],
          [113.0409514, 3.1954172],
          [113.0414696, 3.1954065],
          [113.0415268, 3.1950801],
          [113.0415823, 3.1951232],
          [113.0416392, 3.1951674],
          [113.0416499, 3.1954906],
          [113.0411261, 3.1963045],
          [113.0411955, 3.1965615],
          [113.0414098, 3.1967145],
          [113.0443761, 3.1991081],
          [113.0447419, 3.1990853],
          [113.0447935, 3.1991289],
          [113.0448246, 3.1991552],
          [113.0448817, 3.1994035],
          [113.0451453, 3.199743],
          [113.0455174, 3.2002138],
          [113.0457298, 3.200265],
          [113.0458377, 3.200327],
          [113.0459111, 3.2003692],
          [113.0457196, 3.2003968],
          [113.043164, 3.2002931],
          [113.0428698, 3.2002347],
          [113.0426128, 3.2001057],
          [113.0424296, 3.2001482],
          [113.0423379, 3.2003558],
          [113.0424428, 3.2005432],
          [113.0434993, 3.2016394],
          [113.0436999, 3.2021661],
          [113.0437495, 3.2025309],
          [113.0439885, 3.2029567],
          [113.0438335, 3.2031843],
          [113.0435639, 3.203341],
          [113.0433153, 3.2036188],
          [113.0432437, 3.2039208],
          [113.0433027, 3.2039711],
          [113.0440086, 3.2039184],
          [113.0442015, 3.2040134],
          [113.0442213, 3.2042992],
          [113.0442323, 3.2046229],
          [113.0445048, 3.2049213],
          [113.0460208, 3.2049534],
          [113.0456574, 3.2051047],
          [113.0453913, 3.2056106],
          [113.0451888, 3.2057573],
          [113.0450308, 3.2072356],
          [113.044787, 3.2076392],
          [113.044294, 3.2080307],
          [113.0440867, 3.2081244],
          [113.0438667, 3.2081392],
          [113.0439805, 3.2089889],
          [113.0437348, 3.2089739],
          [113.0436604, 3.2090609],
          [113.0437066, 3.2094514],
          [113.044254, 3.209559],
          [113.0444206, 3.2094278],
          [113.0443707, 3.2093269],
          [113.0445376, 3.2092772],
          [113.0449085, 3.2094383],
          [113.04516, 3.2096775],
          [113.0453251, 3.2100231],
          [113.0456228, 3.2101728],
          [113.0460168, 3.2103528],
          [113.046336, 3.2107558],
          [113.0463851, 3.2109762],
          [113.0462595, 3.2112122],
          [113.0461585, 3.2111342],
          [113.046062, 3.2111342],
          [113.0460039, 3.2111755],
          [113.0459595, 3.2112672],
          [113.0460161, 3.2113635],
          [113.0461707, 3.2115132],
          [113.0462733, 3.2115713],
          [113.0463743, 3.2115744],
          [113.0464493, 3.2115148],
          [113.0464722, 3.2114017],
          [113.0463957, 3.2112764],
          [113.0465052, 3.2110726],
          [113.0468413, 3.2110041],
          [113.0472176, 3.2111621],
          [113.0474056, 3.2114971],
          [113.0474447, 3.2115976],
          [113.0475326, 3.2116688],
          [113.0476951, 3.2116934],
          [113.0478732, 3.2122378],
          [113.0480438, 3.2123183],
          [113.0481326, 3.2125215],
          [113.0483468, 3.2125108],
          [113.0485948, 3.2125643],
          [113.0489315, 3.2127049],
          [113.0489637, 3.2127599],
          [113.0489223, 3.2129601],
          [113.0486973, 3.2130105],
          [113.0484968, 3.2131297],
          [113.0484846, 3.2132229],
          [113.0485775, 3.2133564],
          [113.0486252, 3.2136066],
          [113.0486178, 3.2137761],
          [113.0487035, 3.2138006],
          [113.0489535, 3.2137273],
          [113.0491805, 3.2138875],
          [113.0493448, 3.2138602],
          [113.0495009, 3.2139213],
          [113.049858, 3.2142276],
          [113.050077, 3.2147851],
          [113.0500647, 3.2152511],
          [113.0501533, 3.215433],
          [113.0501804, 3.2158239],
          [113.0507659, 3.2162843],
          [113.0513525, 3.2165896],
          [113.0512313, 3.2167979],
          [113.0513124, 3.2169698],
          [113.0514318, 3.2170035],
          [113.0515726, 3.2169653],
          [113.051691, 3.2167845],
          [113.0527094, 3.2176819],
          [113.0527229, 3.2178871],
          [113.0533595, 3.2184483],
          [113.0535369, 3.2184604],
          [113.0542839, 3.2191811],
          [113.0543206, 3.219323],
          [113.0546943, 3.2196733],
          [113.0546755, 3.2199434],
          [113.0547837, 3.2199855],
          [113.0550478, 3.2197126],
          [113.0555279, 3.2200738],
          [113.055736, 3.2202663],
          [113.0562197, 3.220601],
          [113.056374, 3.2208009],
          [113.0564414, 3.220794],
          [113.0564697, 3.2208277],
          [113.0564873, 3.220942],
          [113.0564429, 3.2209927],
          [113.0564559, 3.2210852],
          [113.0565493, 3.2211822],
          [113.0566835, 3.2211083],
          [113.0568645, 3.2212653],
          [113.0574604, 3.2220773],
          [113.0591226, 3.2238577],
          [113.0605173, 3.2254859],
          [113.0605173, 3.2261179],
          [113.0613756, 3.225593],
          [113.062352, 3.2264286],
          [113.0628348, 3.2278532],
          [113.0632425, 3.228196],
          [113.0636716, 3.2280889],
          [113.0638647, 3.2283138],
          [113.0648625, 3.2295457],
          [113.0651093, 3.2303812],
          [113.0655384, 3.230724],
          [113.0660963, 3.2310132],
          [113.0668473, 3.2321594],
          [113.0673516, 3.2331341],
          [113.0646587, 3.2342375],
          [113.0639398, 3.2352444],
          [113.0632317, 3.2354265],
          [113.0611074, 3.2364227],
          [113.0578244, 3.2378687],
          [113.0550242, 3.2379973],
          [113.054874, 3.2350623],
          [113.0543268, 3.2304241],
          [113.0536509, 3.2270284],
          [113.0525673, 3.2233971],
          [113.0509365, 3.2190267],
          [113.0503679, 3.2177627],
          [113.0502069, 3.2177734],
          [113.0500889, 3.2179555],
          [113.0507219, 3.2193694],
          [113.0527497, 3.2247897],
          [113.0538762, 3.2296528],
          [113.0545736, 3.2351158],
          [113.054595, 3.2382758],
          [113.0546701, 3.2436745],
          [113.0548979, 3.2436518],
          [113.0711214, 3.2420396],
          [113.0713897, 3.2423502],
          [113.0715613, 3.2423395],
          [113.071615, 3.2419968],
          [113.0737178, 3.2417397],
          [113.0740504, 3.2425109],
          [113.0763524, 3.2470988],
          [113.0764966, 3.2474275],
          [113.0767863, 3.248413],
          [113.0702173, 3.2499336],
          [113.0667079, 3.2501871],
          [113.066901, 3.2527151],
          [113.0668963, 3.2528481],
          [113.0668782, 3.2533618],
          [113.0668621, 3.2578606],
          [113.0670445, 3.2578499],
          [113.0675099, 3.2577254],
          [113.0675246, 3.2578017],
          [113.0679283, 3.2577762],
          [113.0679377, 3.2578111],
          [113.076447, 3.2569394],
          [113.0768815, 3.2568591],
          [113.0770478, 3.2570037],
          [113.0770317, 3.2581337],
          [113.0771497, 3.2581498],
          [113.0771451, 3.2593477],
          [113.077139, 3.2609241],
          [113.0771819, 3.2634252],
          [113.0771283, 3.2643517],
          [113.0767688, 3.264657],
          [113.0765757, 3.2646302],
          [113.0764202, 3.2645071],
          [113.0764202, 3.2640786],
          [113.0724934, 3.2640947],
          [113.0722909, 3.264096],
          [113.0677298, 3.2641268],
          [113.0677191, 3.2638965],
          [113.0675259, 3.2639126],
          [113.0674804, 3.2614931],
          [113.0672175, 3.2615012],
          [113.0671799, 3.2642701],
          [113.0670163, 3.264262],
          [113.0670512, 3.261303],
          [113.0668473, 3.2613057],
          [113.0667749, 3.2656157],
          [113.0661795, 3.2656853],
          [113.0661741, 3.2654979],
          [113.0658254, 3.2655032],
          [113.0658415, 3.26568],
          [113.0649531, 3.2657123],
          [113.0648116, 3.2657175],
          [113.0646345, 3.2665262],
          [113.064436, 3.2665529],
          [113.0639693, 3.2670724],
          [113.0632022, 3.2678169],
          [113.0620221, 3.2678865],
          [113.0608848, 3.2677098],
          [113.060284, 3.2677687],
          [113.0599407, 3.2675491],
          [113.0598042, 3.2676129],
          [113.0595169, 3.2677473],
          [113.0592165, 3.2678651],
          [113.0589912, 3.2677205],
          [113.0579397, 3.2678008],
          [113.0574838, 3.2676455],
          [113.0574677, 3.2672224],
          [113.0573336, 3.267126],
          [113.0572155, 3.267201],
          [113.056089, 3.2672492],
          [113.0560246, 3.2671849],
          [113.055912, 3.2671849],
          [113.0558717, 3.2672559],
          [113.0558342, 3.2680914],
          [113.0557629, 3.2681605],
          [113.0554533, 3.2684609],
          [113.0557323, 3.2688786],
          [113.0567408, 3.2692964],
          [113.0573952, 3.2699605],
          [113.0579746, 3.270314],
          [113.0587364, 3.2712994],
          [113.0603349, 3.2734417],
          [113.0615902, 3.2742129],
          [113.0618584, 3.2748127],
          [113.0625236, 3.2755411],
          [113.0630815, 3.2756482],
          [113.0634463, 3.2759695],
          [113.0637574, 3.276955],
          [113.0634785, 3.2772121],
          [113.0635321, 3.2781975],
          [113.0629313, 3.2792365],
          [113.0631566, 3.2800184],
          [113.0635107, 3.2808539],
          [113.0643046, 3.2810574],
          [113.0650234, 3.2815501],
          [113.0656028, 3.2822892],
          [113.0659783, 3.2822678],
          [113.0663216, 3.2824177],
          [113.066622, 3.282075],
          [113.0670405, 3.2820964],
          [113.0672121, 3.2825463],
          [113.0675876, 3.2833282],
          [113.0682314, 3.2834567],
          [113.0686713, 3.2829747],
          [113.0717235, 3.2849256],
          [113.090943, 3.2972099],
          [113.1170289, 3.3154941],
          [113.1277442, 3.3230047],
          [113.1353702, 3.3294881],
          [113.139651, 3.3330012],
          [113.1516694, 3.3455016],
          [113.1547947, 3.3487618],
          [113.1555514, 3.3493972],
          [113.1566443, 3.3498833],
          [113.1563055, 3.3506529],
          [113.1561464, 3.3514732],
          [113.1555116, 3.3520312],
          [113.1557102, 3.3540706],
          [113.1570748, 3.356449],
          [113.1576951, 3.3572838],
          [113.1573571, 3.3578942],
          [113.1569528, 3.3587282],
          [113.1572818, 3.3602567],
          [113.1595412, 3.3631523],
          [113.1658327, 3.3692106],
          [113.1657659, 3.3705324],
          [113.1655993, 3.3738255],
          [113.167586, 3.3772405],
          [113.1676539, 3.3773572],
          [113.1685202, 3.3795394],
          [113.1698148, 3.3821333],
          [113.1697333, 3.3831014],
          [113.1701108, 3.3840858],
          [113.171969, 3.3893391],
          [113.1722123, 3.3898378],
          [113.1720809, 3.3906973],
          [113.1718717, 3.3909918],
          [113.1716666, 3.390893],
          [113.1712235, 3.3909677],
          [113.170489, 3.3910914],
          [113.1724135, 3.3935164],
          [113.175215, 3.3955151],
          [113.1765114, 3.3978713],
          [113.1769103, 3.398422],
          [113.1790814, 3.4001512],
          [113.1803099, 3.4031152],
          [113.1857624, 3.4084859],
          [113.1902569, 3.4129129],
          [113.1891397, 3.4147923],
          [113.1920741, 3.4154884],
          [113.1937305, 3.4159051],
          [113.1939523, 3.416051],
          [113.1967416, 3.4178852],
          [113.1992792, 3.4198809],
          [113.2008782, 3.4210323],
          [113.2049534, 3.4239668],
          [113.2094166, 3.4273511],
          [113.2123455, 3.4310138],
          [113.2189537, 3.4365244],
          [113.2243882, 3.4410374],
          [113.2363629, 3.4492846],
          [113.2470125, 3.4578639],
          [113.2546448, 3.4653057],
          [113.2606824, 3.4693232],
          [113.2613283, 3.4698365],
          [113.2614772, 3.469986],
          [113.2657988, 3.4743255],
          [113.2693445, 3.4771],
          [113.2719358, 3.4796853],
          [113.2739319, 3.4816767],
          [113.2755202, 3.4832612],
          [113.2767181, 3.4844563],
          [113.2784111, 3.4865118],
          [113.2831661, 3.4922845],
          [113.2862145, 3.495342],
          [113.2867912, 3.4958458],
          [113.2869425, 3.4960565],
          [113.287679, 3.4970821],
          [113.2893613, 3.4992759],
          [113.29367, 3.5049178],
          [113.2965958, 3.5090985],
          [113.298359, 3.5116723],
          [113.2997246, 3.5138442],
          [113.2999866, 3.5144957],
          [113.3001363, 3.5151216],
          [113.3002119, 3.5155681],
          [113.3004243, 3.5158872],
          [113.3009136, 3.5159767],
          [113.301479, 3.5162203],
          [113.3019055, 3.5165608],
          [113.3023298, 3.5165785],
          [113.3026034, 3.5163352],
          [113.3027863, 3.5159681],
          [113.3033378, 3.5153775],
          [113.3041033, 3.5148849],
          [113.3050286, 3.5147095],
          [113.3059615, 3.5145326],
          [113.3044101, 3.5157384],
          [113.303621, 3.5164789],
          [113.3032122, 3.5171321],
          [113.3028099, 3.5178469],
          [113.3022171, 3.5186817],
          [113.3023169, 3.5198468],
          [113.3021275, 3.5216929],
          [113.3024789, 3.5208223],
          [113.3028989, 3.5220881],
          [113.3027825, 3.52239],
          [113.3016329, 3.523085],
          [113.3017381, 3.5245992],
          [113.3016995, 3.5260363],
          [113.3012939, 3.5273128],
          [113.3005354, 3.5289576],
          [113.2961237, 3.5294232],
          [113.2940637, 3.5362766],
          [113.2971536, 3.5458713],
          [113.3048784, 3.5534099],
          [113.3069383, 3.5573505],
          [113.3110582, 3.5611197],
          [113.3323442, 3.5796232],
          [113.3582651, 3.6039512],
          [113.3582651, 3.6077203],
          [113.3639299, 3.6137165],
          [113.3668482, 3.6147444],
          [113.3730859, 3.6227155],
          [113.3750879, 3.6282786],
          [113.3714208, 3.6337612],
          [113.3758067, 3.6363695],
          [113.3799533, 3.6412725],
          [113.3826732, 3.6425882],
          [113.3831782, 3.6450857],
          [113.3925609, 3.6568586],
          [113.4030494, 3.6739018],
          [113.4181856, 3.7024692],
          [113.4260713, 3.7219843],
          [113.4266914, 3.7255434],
          [113.4267022, 3.7281964],
          [113.4244234, 3.731222],
          [113.422666, 3.7324446],
          [113.4221445, 3.7349627],
          [113.4231574, 3.7392023],
          [113.4289638, 3.7478527],
          [113.4332639, 3.7557771],
          [113.4363989, 3.7589139],
          [113.4449111, 3.7656392],
          [113.45199, 3.7706645],
          [113.4681305, 3.7823357],
          [113.4849297, 3.7924244],
          [113.4868716, 3.7943492],
          [113.4888618, 3.7948499],
          [113.4915652, 3.7958238],
          [113.4933401, 3.7975267],
          [113.5285746, 3.818605],
          [113.5545105, 3.8374435],
          [113.6289751, 3.8975483],
          [113.6641346, 3.9274112],
          [113.6839067, 3.9449872],
          [113.704475, 3.9671422],
          [113.7072712, 3.9698481],
          [113.7130599, 3.9801406],
          [113.7141483, 3.9815042],
          [113.7150227, 3.9824996],
          [113.7165983, 3.9840435],
          [113.7188342, 3.9865083],
          [113.7201753, 3.9881047],
          [113.7216038, 3.9899151],
          [113.7224793, 3.9903507],
          [113.7230678, 3.9905412],
          [113.7237995, 3.9909966],
          [113.724999, 3.9913856],
          [113.7260155, 3.9916125],
          [113.7266834, 3.9916532],
          [113.7271313, 3.9921006],
          [113.7274569, 3.9926095],
          [113.7270836, 3.9931762],
          [113.727252, 3.9944397],
          [113.7277831, 3.9953601],
          [113.7302775, 3.9979111],
          [113.731909660517999, 3.9995],
          [113.732417356729101, 3.999994239690849],
          [113.732936887227041, 4.0005],
          [113.736548, 4.0040154],
          [113.7676464, 4.0346119],
          [113.783642, 4.0578362],
          [113.7841664, 4.0631391],
          [113.790649, 4.0717693],
          [113.8005254, 4.0885372],
          [113.803383, 4.0975609],
          [113.8046226, 4.1020938],
          [113.8125191, 4.1129235],
          [113.8175616, 4.1198578],
          [113.8174114, 4.1206497],
          [113.8250503, 4.1295744],
          [113.8341913, 4.1390768],
          [113.8765108, 4.1871052],
          [113.911690115003921, 4.233557939529419],
          [113.9230139, 4.2485105],
          [113.9238294, 4.2495873],
          [113.9257776, 4.2521597],
          [113.9467203, 4.2860544],
          [113.9468157, 4.2862573],
          [113.948902, 4.2906944],
          [113.9505942, 4.2942932],
          [113.9525214, 4.298392],
          [113.959746, 4.3137566],
          [113.9598652, 4.31401],
          [113.9601706, 4.3146596],
          [113.9605599, 4.3154876],
          [113.9654823, 4.3259559],
          [113.9657458, 4.3267087],
          [113.9657566, 4.32669],
          [113.9658585, 4.3272731],
          [113.9669883, 4.3300397],
          [113.9675646, 4.3318186],
          [113.967789, 4.3325111],
          [113.9693348, 4.3372819],
          [113.96941, 4.3375141],
          [113.9696907, 4.3383805],
          [113.9712952, 4.3469953],
          [113.9714578, 4.3489087],
          [113.9716003, 4.3496886],
          [113.9721856, 4.3501753],
          [113.9725241, 4.3503331],
          [113.9728309, 4.3503535],
          [113.9729538, 4.3504535],
          [113.97281, 4.3505787],
          [113.9723562, 4.3505428],
          [113.9718111, 4.3501941],
          [113.971367, 4.3504064],
          [113.971214, 4.3511205],
          [113.9710353, 4.3513692],
          [113.9709785, 4.3517399],
          [113.9709077, 4.3518223],
          [113.9707098, 4.3524112],
          [113.9704302, 4.3532879],
          [113.9700837, 4.3538078],
          [113.9696341, 4.3534371],
          [113.9694088, 4.3538705],
          [113.9685913, 4.3548156],
          [113.9682868, 4.3549245],
          [113.9663525, 4.3557259],
          [113.9654084, 4.3558001],
          [113.9646766, 4.3556542],
          [113.9636847, 4.3552974],
          [113.9626114, 4.3569513],
          [113.9614147, 4.3580792],
          [113.9612649, 4.3583799],
          [113.961224, 4.3585555],
          [113.9611087, 4.3586559],
          [113.9609326, 4.3587257],
          [113.9609132, 4.3589106],
          [113.9609683, 4.359047],
          [113.9611664, 4.3591109],
          [113.961321, 4.3592121],
          [113.9613737, 4.3593843],
          [113.9612048, 4.3594608],
          [113.9609644, 4.3594166],
          [113.9607919, 4.3592983],
          [113.9607013, 4.3592903],
          [113.9606376, 4.3593327],
          [113.9606878, 4.3594941],
          [113.9608277, 4.3596725],
          [113.9608305, 4.3598629],
          [113.961021, 4.36013],
          [113.9611653, 4.3601729],
          [113.961408, 4.3604375],
          [113.9616141, 4.3604193],
          [113.9617579, 4.3603332],
          [113.9618231, 4.3605253],
          [113.9618715, 4.3606919],
          [113.9617872, 4.3608268],
          [113.961849, 4.3610318],
          [113.9617676, 4.3612673],
          [113.9616519, 4.3613131],
          [113.96126, 4.3613733],
          [113.9611037, 4.3614486],
          [113.9610613, 4.3616408],
          [113.9611784, 4.3617935],
          [113.9613715, 4.3619943],
          [113.9614245, 4.3623342],
          [113.9613145, 4.3624305],
          [113.9612991, 4.3625748],
          [113.9613785, 4.3627865],
          [113.9615795, 4.3627516],
          [113.9616925, 4.362637],
          [113.9617653, 4.3625723],
          [113.9618923, 4.3625799],
          [113.9619223, 4.3624755],
          [113.9620196, 4.3624364],
          [113.9622386, 4.3624129],
          [113.9625462, 4.3624839],
          [113.9626807, 4.3627057],
          [113.963124, 4.3631175],
          [113.9636521, 4.3635183],
          [113.9649206, 4.3654479],
          [113.9649206, 4.3660803],
          [113.9652458, 4.3666316],
          [113.9660889, 4.3672048],
          [113.9665282, 4.367913],
          [113.9671586, 4.3698083],
          [113.9671838, 4.3698841],
          [113.9672256, 4.3700098],
          [113.9680519, 4.3708087],
          [113.9684921, 4.3715005],
          [113.9689208, 4.3724916],
          [113.9684702, 4.3726949],
          [113.9686436, 4.3729791],
          [113.9688072, 4.3732471],
          [113.9695831, 4.3741446],
          [113.9696203, 4.3741876],
          [113.9707098, 4.3763765],
          [113.9711163, 4.3769765],
          [113.9721408, 4.3765549],
          [113.9728889, 4.3779007],
          [113.9731003, 4.3789546],
          [113.9731816, 4.3799275],
          [113.9732141, 4.3809166],
          [113.9729702, 4.3821326],
          [113.9729927, 4.3822315],
          [113.9731339, 4.38229],
          [113.9732315, 4.3823149],
          [113.9733982, 4.3823187],
          [113.9736484, 4.3822163],
          [113.9741865, 4.3817957],
          [113.974611, 4.3813219],
          [113.9749066, 4.380401],
          [113.9750885, 4.3802532],
          [113.9751557, 4.380373],
          [113.9750809, 4.3805071],
          [113.9750468, 4.3811135],
          [113.9749066, 4.381375],
          [113.9747131, 4.3815889],
          [113.9743036, 4.3820162],
          [113.9736485, 4.3824758],
          [113.9729018, 4.3825953],
          [113.9719859, 4.3828274],
          [113.9711284, 4.3832856],
          [113.9706755, 4.3834652],
          [113.9703371, 4.3835397],
          [113.969893, 4.3823075],
          [113.9698059, 4.3823428],
          [113.9702735, 4.3836072],
          [113.9702054, 4.3838079],
          [113.9707253, 4.3845562],
          [113.9709401, 4.384791],
          [113.9710455, 4.3848321],
          [113.971147, 4.384838],
          [113.9721419, 4.3843841],
          [113.9746301, 4.3832489],
          [113.9750007, 4.383062],
          [113.9753835, 4.3827588],
          [113.9756829, 4.3822926],
          [113.9757751, 4.3820146],
          [113.976333, 4.3811157],
          [113.9766836, 4.3813221],
          [113.9763995, 4.3817133],
          [113.976287, 4.3821367],
          [113.975751, 4.3829246],
          [113.9754087, 4.3836446],
          [113.975397, 4.3839293],
          [113.9755149, 4.3841705],
          [113.9756972, 4.3843527],
          [113.975858, 4.3845403],
          [113.976122, 4.3845855],
          [113.9764314, 4.384476],
          [113.976603, 4.3839025],
          [113.9773477, 4.3822528],
          [113.9774635, 4.3821764],
          [113.9775861, 4.3821295],
          [113.9777063, 4.3821114],
          [113.9778998, 4.3821386],
          [113.9780565, 4.3822312],
          [113.9783095, 4.3824598],
          [113.9782323, 4.3827394],
          [113.9778304, 4.3826054],
          [113.9772619, 4.3833796],
          [113.9766519, 4.3855536],
          [113.9763592, 4.385642],
          [113.9762455, 4.3856989],
          [113.9761546, 4.3858391],
          [113.9758855, 4.3857898],
          [113.975605, 4.3855245],
          [113.9757365, 4.3854221],
          [113.97497, 4.383959],
          [113.9748825, 4.3839111],
          [113.9747288, 4.3839161],
          [113.9735872, 4.3842484],
          [113.97348, 4.3843234],
          [113.9734372, 4.3844735],
          [113.9739157, 4.3856332],
          [113.9719255, 4.3865729],
          [113.971827, 4.3866411],
          [113.9716868, 4.3866373],
          [113.9715958, 4.3866032],
          [113.9715162, 4.3865236],
          [113.9705932, 4.3855369],
          [113.9706502, 4.3853148],
          [113.9700969, 4.3845416],
          [113.9697937, 4.3843218],
          [113.9694197, 4.3841964],
          [113.9693518, 4.3840983],
          [113.9692268, 4.3835222],
          [113.9691017, 4.3832456],
          [113.9690259, 4.3831129],
          [113.9688942, 4.3829988],
          [113.9687587, 4.3829642],
          [113.9686412, 4.3829901],
          [113.9685579, 4.3830551],
          [113.9685256, 4.3830836],
          [113.9684958, 4.3831327],
          [113.9684622, 4.3832153],
          [113.9684735, 4.3833065],
          [113.9684972, 4.3834411],
          [113.9687283, 4.3839061],
          [113.9691632, 4.3853079],
          [113.9694724, 4.386192],
          [113.9698904, 4.3868566],
          [113.970327, 4.3873527],
          [113.9711884, 4.3880546],
          [113.9720031, 4.388612],
          [113.9724962, 4.3888585],
          [113.972818, 4.3889202],
          [113.9731822, 4.3889496],
          [113.9735038, 4.3889389],
          [113.9745971, 4.3886173],
          [113.9748101, 4.3885779],
          [113.9749813, 4.3885921],
          [113.9752123, 4.3887378],
          [113.9759263, 4.3896518],
          [113.9764312, 4.3904259],
          [113.9778002, 4.3924884],
          [113.9789629, 4.3942611],
          [113.9792815, 4.3955341],
          [113.9792273, 4.3962095],
          [113.9790545, 4.3962526],
          [113.9781742, 4.3960929],
          [113.9774751, 4.3977363],
          [113.9773516, 4.3984139],
          [113.9767813, 4.3992454],
          [113.9766273, 4.3996902],
          [113.9765628, 4.3998766],
          [113.9766493, 4.4001549],
          [113.9786546, 4.4007968],
          [113.9802485, 4.4010079],
          [113.9816868, 4.4009637],
          [113.9822506, 4.401107],
          [113.9823285, 4.4014596],
          [113.983121, 4.4028589],
          [113.9842586, 4.4046278],
          [113.9855205, 4.4066486],
          [113.9869593, 4.409278],
          [113.9881878, 4.4122779],
          [113.9892688, 4.4145658],
          [113.9902489, 4.4171347],
          [113.9910047, 4.4191774],
          [113.9916921, 4.4212292],
          [113.992544, 4.4238662],
          [113.9933132, 4.4265379],
          [113.993989, 4.4288779],
          [113.9946106, 4.4311533],
          [113.9950847, 4.4332605],
          [113.9956433, 4.435612],
          [113.996474, 4.4397654],
          [113.9968213, 4.4415973],
          [113.9972705, 4.4442699],
          [113.9974428, 4.4454379],
          [113.9977527, 4.4474695],
          [113.9979558, 4.4485721],
          [113.9984871, 4.4525775],
          [113.9987508, 4.454957],
          [113.9988849, 4.4561768],
          [113.9991243, 4.457776],
          [113.9992191, 4.4583467],
          [113.9993394, 4.4592495],
          [113.9993471, 4.4604771],
          [113.9994815, 4.4610116],
          [113.999498453900756, 4.46115479570688],
          [113.9995391, 4.4614981],
          [113.9995851, 4.4624401],
          [113.9995624, 4.4628712],
          [113.9993763, 4.4631564],
          [113.9992253, 4.4632421],
          [113.9991378, 4.4636826],
          [113.9991663, 4.4639444],
          [113.9990677, 4.4641561],
          [113.9990163, 4.46444],
          [113.9990315, 4.4646961],
          [113.9991886, 4.4650254],
          [113.9994221, 4.4652832],
          [113.999498453900756, 4.465386190216374],
          [113.999566, 4.4654773],
          [113.9996231, 4.4662179],
          [113.9997045, 4.466873],
          [113.999498453900756, 4.466909639119047],
          [113.9989678, 4.467004],
          [113.9987107, 4.4670125],
          [113.9984995, 4.4727835],
          [113.9981763, 4.4782067],
          [113.9977, 4.4835592],
          [113.9975885, 4.4843738],
          [113.9974143, 4.4846241],
          [113.9974464, 4.4855753],
          [113.9975087, 4.4880336],
          [113.9974121, 4.4881715],
          [113.9973141, 4.4891629],
          [113.9972102, 4.4896581],
          [113.9970115, 4.4897662],
          [113.9969835, 4.4900298],
          [113.99712, 4.4901169],
          [113.997072, 4.4907931],
          [113.9969921, 4.4908567],
          [113.9968232, 4.4908607],
          [113.9967736, 4.4909125],
          [113.9961796, 4.4942037],
          [113.9952701, 4.4992224],
          [113.9953318, 4.4992326],
          [113.9953512, 4.4991262],
          [113.9954146, 4.4991592],
          [113.9954625, 4.4992269],
          [113.9954671, 4.4993242],
          [113.9954454, 4.4994016],
          [113.9953809, 4.4994563],
          [113.9953021, 4.4994762],
          [113.9953118, 4.4993743],
          [113.9952495, 4.4993681],
          [113.9945269, 4.5034266],
          [113.9936611, 4.5074398],
          [113.9920071, 4.5139432],
          [113.9918775, 4.5139479],
          [113.9918272, 4.5142228],
          [113.9919205, 4.5142665],
          [113.9917857, 4.5151094],
          [113.9917205, 4.5152214],
          [113.99141, 4.5152937],
          [113.9908699, 4.5156822],
          [113.9907203, 4.5163292],
          [113.9903462, 4.5172293],
          [113.9895131, 4.5191038],
          [113.9894455, 4.5191727],
          [113.9894472, 4.5192653],
          [113.9889186, 4.5203822],
          [113.9888994, 4.5205589],
          [113.9888344, 4.5207042],
          [113.988884, 4.5208434],
          [113.9890531, 4.5209895],
          [113.989573, 4.5211854],
          [113.9897829, 4.5211815],
          [113.9898703, 4.5210376],
          [113.9900871, 4.5216759],
          [113.9894489, 4.5218907],
          [113.9885677, 4.5227244],
          [113.9880768, 4.522681],
          [113.9878835, 4.5231619],
          [113.9879024, 4.5233558],
          [113.9880783, 4.5234501],
          [113.9846693, 4.5327152],
          [113.9831626, 4.5363089],
          [113.9820608, 4.5389769],
          [113.9809647, 4.5416814],
          [113.9798765, 4.5445188],
          [113.9789234, 4.5481985],
          [113.9778134, 4.5526611],
          [113.9760781, 4.5583938],
          [113.9752962, 4.5613964],
          [113.9738668, 4.5656792],
          [113.9728652, 4.5687034],
          [113.9727206, 4.5689882],
          [113.9729638, 4.5692227],
          [113.9729312, 4.5693275],
          [113.9725735, 4.5694791],
          [113.9715446, 4.5715871],
          [113.9704082, 4.5738815],
          [113.9702313, 4.5744228],
          [113.9698135, 4.5749663],
          [113.9697179, 4.5753153],
          [113.9689275, 4.5766818],
          [113.9686087, 4.5774812],
          [113.9681074, 4.5782252],
          [113.9675893, 4.5788182],
          [113.967428, 4.5787633],
          [113.9670554, 4.5783984],
          [113.9667981, 4.5783217],
          [113.9666189, 4.5780411],
          [113.9666255, 4.577856],
          [113.9665387, 4.5776642],
          [113.9664584, 4.5776642],
          [113.9663243, 4.577811],
          [113.9663881, 4.5781244],
          [113.9663089, 4.578348],
          [113.9663815, 4.5786011],
          [113.9665585, 4.5787173],
          [113.9674093, 4.579115],
          [113.9679524, 4.5793956],
          [113.9685603, 4.5801078],
          [113.9692837, 4.580647],
          [113.9701323, 4.5810524],
          [113.9706457, 4.5814628],
          [113.9711954, 4.582032],
          [113.9718604, 4.5829481],
          [113.9726926, 4.5836911],
          [113.9735401, 4.5846222],
          [113.9738819, 4.5847586],
          [113.9750453, 4.5873992],
          [113.9765466, 4.5894045],
          [113.9759318, 4.5901216],
          [113.9753977, 4.5906131],
          [113.9740283, 4.5909928],
          [113.9736429, 4.5913132],
          [113.9731205, 4.5919539],
          [113.9723857, 4.5926532],
          [113.9719871, 4.5937691],
          [113.9714049, 4.5954252],
          [113.9708785, 4.5963119],
          [113.9709819, 4.5967158],
          [113.9711907, 4.5970098],
          [113.9720217, 4.597314],
          [113.9733109, 4.597458],
          [113.9747886, 4.5973738],
          [113.9759439, 4.5970935],
          [113.9778558, 4.5964494],
          [113.9801784, 4.5955269],
          [113.9830403, 4.5946107],
          [113.9848539, 4.5940329],
          [113.9868562, 4.5935258],
          [113.9889893, 4.5930369],
          [113.9930423, 4.5922971],
          [113.9961519, 4.5919689],
          [113.999498453900756, 4.591720762245972],
          [114.0100715, 4.5909368],
          [114.0135433, 4.5907742],
          [114.0167175, 4.5904754],
          [114.0192901, 4.5903711],
          [114.0204491, 4.5904449],
          [114.023448, 4.5902873],
          [114.0261833, 4.5900269],
          [114.030826, 4.5897611],
          [114.0341038, 4.5895396],
          [114.0380992, 4.5894303],
          [114.0428795, 4.5890225],
          [114.0489798, 4.5886132],
          [114.054866790771484, 4.58827230624649],
          [114.073048, 4.5872195],
          [114.0759595, 4.5871726],
          [114.0758974, 4.5865965],
          [114.0758734, 4.5857624],
          [114.076123, 4.584841],
          [114.076166, 4.584193],
          [114.076425, 4.58389],
          [114.076944, 4.583588],
          [114.078414, 4.583458],
          [114.086067, 4.58255],
          [114.100075, 4.580821],
          [114.115465, 4.579135],
          [114.127657, 4.577578],
          [114.162201, 4.573515],
          [114.162633, 4.573385],
          [114.163109, 4.573039],
          [114.184941, 4.552157],
          [114.1865776, 4.5505204],
          [114.188227, 4.548871],
          [114.190518, 4.54632],
          [114.19108, 4.545499],
          [114.19882, 4.531621],
          [114.199079, 4.531405],
          [114.199425, 4.531362],
          [114.199857, 4.531448],
          [114.207509, 4.534258],
          [114.212784, 4.536117],
          [114.213346, 4.536247],
          [114.213692, 4.536074],
          [114.218793, 4.533005],
          [114.2501181, 4.5132682],
          [114.253034, 4.511431],
          [114.260148, 4.507013],
          [114.260631, 4.506353],
          [114.271251, 4.486511],
          [114.283572, 4.463798],
          [114.283699, 4.463442],
          [114.283725, 4.462985],
          [114.282912, 4.45511],
          [114.282988, 4.454907],
          [114.285503, 4.451756],
          [114.291194, 4.445252],
          [114.29498, 4.440807],
          [114.295285, 4.440324],
          [114.301687, 4.428282],
          [114.306869, 4.418323],
          [114.307962, 4.415427],
          [114.314949, 4.393577],
          [114.314949, 4.393222],
          [114.314847, 4.392612],
          [114.312738, 4.387048],
          [114.309055, 4.376962],
          [114.30687, 4.3684],
          [114.306489, 4.366749],
          [114.306489, 4.366495],
          [114.306565, 4.366241],
          [114.306794, 4.365987],
          [114.332783, 4.348888],
          [114.332961, 4.348711],
          [114.332961, 4.34805],
          [114.327194, 4.327726],
          [114.326558, 4.325668],
          [114.323611, 4.318961],
          [114.323459, 4.318351],
          [114.323103, 4.315404],
          [114.322163, 4.308875],
          [114.320715, 4.297442],
          [114.318454, 4.280624],
          [114.316422, 4.263983],
          [114.316447, 4.263627],
          [114.320309, 4.260147],
          [114.323713, 4.257149],
          [114.324069, 4.256921],
          [114.324552, 4.256844],
          [114.331259, 4.257479],
          [114.341447, 4.258597],
          [114.347417, 4.259105],
          [114.347874, 4.259105],
          [114.359383, 4.256641],
          [114.36205, 4.256184],
          [114.364565, 4.255676],
          [114.36487, 4.255701],
          [114.365277, 4.256031],
          [114.371501, 4.263704],
          [114.372035, 4.263958],
          [114.381867, 4.264923],
          [114.382553, 4.264872],
          [114.385093, 4.264288],
          [114.39711, 4.261189],
          [114.397593, 4.261112],
          [114.397974, 4.261189],
          [114.398304, 4.261417],
          [114.39866, 4.261951],
          [114.399371, 4.262637],
          [114.399829, 4.262865],
          [114.402445, 4.263272],
          [114.402674, 4.263246],
          [114.404173, 4.262129],
          [114.404452, 4.262027],
          [114.405062, 4.262027],
          [114.405265, 4.261951],
          [114.405519, 4.261239],
          [114.406663, 4.258851],
          [114.406993, 4.25847],
          [114.407247, 4.258292],
          [114.40773, 4.258165],
          [114.408187, 4.258115],
          [114.408619, 4.258013],
          [114.408949, 4.257835],
          [114.409356, 4.25753],
          [114.409762, 4.257352],
          [114.416419, 4.255244],
          [114.417918, 4.254837],
          [114.419289, 4.254684],
          [114.419391, 4.254506],
          [114.419493, 4.253871],
          [114.41962, 4.253541],
          [114.419747, 4.253389],
          [114.420229, 4.253236],
          [114.420763, 4.25316],
          [114.421322, 4.25316],
          [114.422414, 4.253058],
          [114.422922, 4.253185],
          [114.423329, 4.253338],
          [114.423557, 4.253719],
          [114.423659, 4.254278],
          [114.423583, 4.25471],
          [114.423608, 4.255066],
          [114.423812, 4.255294],
          [114.423862, 4.255675],
          [114.42371, 4.255929],
          [114.423685, 4.256336],
          [114.424066, 4.256767],
          [114.424701, 4.257682],
          [114.425107, 4.25819],
          [114.425844, 4.258749],
          [114.426022, 4.259181],
          [114.426073, 4.259587],
          [114.426174, 4.259943],
          [114.426657, 4.260477],
          [114.426759, 4.261442],
          [114.426937, 4.261925],
          [114.42747, 4.262865],
          [114.427648, 4.263551],
          [114.427597, 4.264033],
          [114.427521, 4.264288],
          [114.427368, 4.264415],
          [114.426657, 4.264694],
          [114.426073, 4.2651],
          [114.425666, 4.265532],
          [114.425539, 4.265761],
          [114.425615, 4.266015],
          [114.42592, 4.266117],
          [114.426936, 4.266599],
          [114.427597, 4.266701],
          [114.428537, 4.266726],
          [114.428867, 4.266853],
          [114.429197, 4.267387],
          [114.429502, 4.267514],
          [114.430239, 4.267641],
          [114.430518, 4.26787],
          [114.43062, 4.268149],
          [114.431052, 4.271808],
          [114.431306, 4.272112],
          [114.431586, 4.27229],
          [114.432043, 4.272443],
          [114.432449, 4.272519],
          [114.43278, 4.272697],
          [114.433084, 4.272976],
          [114.433694, 4.274018],
          [114.434024, 4.274399],
          [114.434228, 4.274551],
          [114.434558, 4.274628],
          [114.43499, 4.274653],
          [114.4356, 4.274526],
          [114.435854, 4.274348],
          [114.435981, 4.274145],
          [114.436387, 4.272544],
          [114.43654, 4.272214],
          [114.437022, 4.272036],
          [114.437454, 4.271985],
          [114.437734, 4.272011],
          [114.438013, 4.272138],
          [114.43814, 4.272316],
          [114.438216, 4.272722],
          [114.438445, 4.273383],
          [114.439106, 4.274501],
          [114.439818, 4.275314],
          [114.43997, 4.275644],
          [114.440148, 4.277092],
          [114.440275, 4.277651],
          [114.440554, 4.278108],
          [114.440986, 4.278388],
          [114.443654, 4.279429],
          [114.444289, 4.279836],
          [114.444721, 4.279937],
          [114.445178, 4.279912],
          [114.446296, 4.279683],
          [114.449218, 4.27887],
          [114.449751, 4.278616],
          [114.450666, 4.27793],
          [114.451708, 4.276279],
          [114.452165, 4.275237],
          [114.452292, 4.274704],
          [114.452775, 4.270842],
          [114.452953, 4.27003],
          [114.453334, 4.269166],
          [114.453918, 4.268429],
          [114.454426, 4.268022],
          [114.454909, 4.267718],
          [114.455773, 4.267514],
          [114.4559, 4.267387],
          [114.455925, 4.267133],
          [114.455773, 4.266777],
          [114.45529, 4.265202],
          [114.455315, 4.264923],
          [114.455442, 4.264694],
          [114.455798, 4.264338],
          [114.456255, 4.26411],
          [114.456509, 4.263881],
          [114.456636, 4.263602],
          [114.456662, 4.263144],
          [114.456535, 4.262839],
          [114.456204, 4.26228],
          [114.45595, 4.26195],
          [114.455646, 4.261721],
          [114.455518, 4.261315],
          [114.45562, 4.260908],
          [114.455798, 4.260553],
          [114.456331, 4.25974],
          [114.456509, 4.259308],
          [114.45656, 4.2588],
          [114.45656, 4.258241],
          [114.456281, 4.257073],
          [114.45623, 4.256285],
          [114.456306, 4.255345],
          [114.45623, 4.25377],
          [114.45623, 4.251356],
          [114.456179, 4.250772],
          [114.456077, 4.250315],
          [114.455874, 4.249934],
          [114.455595, 4.249629],
          [114.45461, 4.249044],
          [114.454254, 4.248638],
          [114.454051, 4.248155],
          [114.453898, 4.247393],
          [114.453797, 4.245945],
          [114.453898, 4.243785],
          [114.454025, 4.242616],
          [114.454178, 4.241829],
          [114.455956, 4.238552],
          [114.457023, 4.236367],
          [114.457684, 4.234614],
          [114.458116, 4.232708],
          [114.458344, 4.231972],
          [114.458624, 4.231463],
          [114.458954, 4.231108],
          [114.45931, 4.23093],
          [114.459869, 4.230854],
          [114.460377, 4.23065],
          [114.46091, 4.230269],
          [114.461241, 4.229736],
          [114.461393, 4.229228],
          [114.461342, 4.228618],
          [114.460834, 4.225265],
          [114.460072, 4.221073],
          [114.460072, 4.220387],
          [114.460224, 4.219828],
          [114.460682, 4.218787],
          [114.461139, 4.217999],
          [114.461977, 4.216932],
          [114.462638, 4.216221],
          [114.462841, 4.215814],
          [114.463045, 4.214874],
          [114.463374, 4.214137],
          [114.463832, 4.213477],
          [114.463832, 4.213121],
          [114.463603, 4.211266],
          [114.463629, 4.210606],
          [114.463806, 4.210098],
          [114.464162, 4.209615],
          [114.464772, 4.209056],
          [114.466347, 4.208319],
          [114.466626, 4.208015],
          [114.466779, 4.20771],
          [114.466982, 4.207024],
          [114.467007, 4.206135],
          [114.467211, 4.205601],
          [114.467617, 4.205347],
          [114.469396, 4.204559],
          [114.469726, 4.204254],
          [114.470158, 4.203619],
          [114.470564, 4.202882],
          [114.470641, 4.202247],
          [114.470793, 4.201764],
          [114.471149, 4.201307],
          [114.472419, 4.200342],
          [114.472775, 4.199859],
          [114.473435, 4.197191],
          [114.473715, 4.196404],
          [114.473994, 4.19587],
          [114.474121, 4.195413],
          [114.474223, 4.194752],
          [114.475595, 4.192237],
          [114.476153, 4.191348],
          [114.476331, 4.190789],
          [114.476712, 4.188985],
          [114.477322, 4.187283],
          [114.477601, 4.186673],
          [114.477856, 4.186343],
          [114.478491, 4.185962],
          [114.478796, 4.185631],
          [114.478872, 4.18525],
          [114.478846, 4.184031],
          [114.479456, 4.182532],
          [114.48032, 4.179407],
          [114.481489, 4.173818],
          [114.482251, 4.171913],
          [114.484385, 4.167543],
          [114.485249, 4.166222],
          [114.486773, 4.164748],
          [114.488196, 4.163072],
          [114.489314, 4.161649],
          [114.490076, 4.160328],
          [114.490737, 4.158956],
          [114.491219, 4.15766],
          [114.491956, 4.155196],
          [114.492363, 4.154383],
          [114.493455, 4.153113],
          [114.494649, 4.151919],
          [114.495894, 4.150089],
          [114.496072, 4.149658],
          [114.496123, 4.149226],
          [114.496097, 4.148895],
          [114.495767, 4.148591],
          [114.495005, 4.147752],
          [114.494929, 4.147473],
          [114.495056, 4.147168],
          [114.495792, 4.146685],
          [114.497215, 4.146355],
          [114.497977, 4.146025],
          [114.498384, 4.14572],
          [114.49973, 4.144018],
          [114.500213, 4.143611],
          [114.50095, 4.143205],
          [114.501737, 4.142976],
          [114.503211, 4.142442],
          [114.503783, 4.142468],
          [114.504647, 4.142798],
          [114.505638, 4.143052],
          [114.508102, 4.143458],
          [114.509016, 4.143687],
          [114.509956, 4.144094],
          [114.513488, 4.146278],
          [114.514199, 4.146634],
          [114.51552, 4.146837],
          [114.516689, 4.14671],
          [114.519357, 4.145237],
          [114.520525, 4.144398],
          [114.521389, 4.14356],
          [114.521923, 4.142976],
          [114.523447, 4.140562],
          [114.525199, 4.138835],
          [114.526597, 4.137666],
          [114.527028, 4.137107],
          [114.527816, 4.135837],
          [114.528273, 4.13543],
          [114.529417, 4.134821],
          [114.53056, 4.134084],
          [114.533532, 4.13134],
          [114.535387, 4.129993],
          [114.536505, 4.129333],
          [114.537343, 4.128977],
          [114.537953, 4.128799],
          [114.538588, 4.128698],
          [114.539249, 4.128647],
          [114.540849, 4.128723],
          [114.541459, 4.128647],
          [114.542069, 4.128342],
          [114.542526, 4.127986],
          [114.543745, 4.126843],
          [114.544431, 4.126284],
          [114.544863, 4.126005],
          [114.545168, 4.125649],
          [114.545422, 4.125242],
          [114.545676, 4.124379],
          [114.546108, 4.122448],
          [114.546438, 4.120491],
          [114.547074, 4.118129],
          [114.547328, 4.116402],
          [114.547683, 4.114648],
          [114.54781, 4.113429],
          [114.547785, 4.111295],
          [114.547277, 4.109872],
          [114.546845, 4.109491],
          [114.545905, 4.10883],
          [114.545371, 4.108348],
          [114.545016, 4.107636],
          [114.544635, 4.106569],
          [114.544253, 4.105324],
          [114.544203, 4.104867],
          [114.544304, 4.104461],
          [114.544533, 4.10408],
          [114.544838, 4.103775],
          [114.545244, 4.103546],
          [114.545803, 4.10347],
          [114.546311, 4.103597],
          [114.547074, 4.103673],
          [114.547963, 4.103699],
          [114.548725, 4.103622],
          [114.550097, 4.103292],
          [114.55063, 4.102936],
          [114.551113, 4.102377],
          [114.551418, 4.101234],
          [114.551443, 4.099989],
          [114.55157, 4.099329],
          [114.551672, 4.098948],
          [114.551951, 4.098491],
          [114.552917, 4.097144],
          [114.554035, 4.095899],
          [114.554594, 4.095467],
          [114.55528, 4.095188],
          [114.555711, 4.094883],
          [114.556016, 4.094553],
          [114.556067, 4.094172],
          [114.555483, 4.092774],
          [114.554975, 4.091275],
          [114.55495, 4.090666],
          [114.555077, 4.090183],
          [114.555127, 4.089674],
          [114.554848, 4.089268],
          [114.554416, 4.088811],
          [114.554086, 4.088328],
          [114.553679, 4.087109],
          [114.553679, 4.086626],
          [114.553527, 4.086143],
          [114.553298, 4.085762],
          [114.552968, 4.085356],
          [114.552764, 4.084873],
          [114.552714, 4.084466],
          [114.55279, 4.083958],
          [114.553095, 4.083272],
          [114.553755, 4.082332],
          [114.554441, 4.081697],
          [114.555051, 4.081316],
          [114.555635, 4.081087],
          [114.55688, 4.080986],
          [114.557642, 4.081087],
          [114.55843, 4.081062],
          [114.559192, 4.080986],
          [114.55998, 4.080782],
          [114.560793, 4.080528],
          [114.561631, 4.079893],
          [114.562241, 4.079233],
          [114.562774, 4.078902],
          [114.563384, 4.078826],
          [114.564756, 4.078902],
          [114.56595, 4.078826],
          [114.566509, 4.078623],
          [114.567423, 4.077937],
          [114.569176, 4.075981],
          [114.570904, 4.073949],
          [114.571387, 4.072983],
          [114.573318, 4.069858],
          [114.575452, 4.067419],
          [114.57812, 4.064904],
          [114.578907, 4.063964],
          [114.579111, 4.063481],
          [114.579238, 4.062795],
          [114.579212, 4.061982],
          [114.579314, 4.06122],
          [114.57972, 4.05995],
          [114.580356, 4.058756],
          [114.583023, 4.054945],
          [114.583125, 4.054386],
          [114.583353, 4.052303],
          [114.58343, 4.050728],
          [114.583633, 4.050016],
          [114.583938, 4.049508],
          [114.584395, 4.049203],
          [114.585716, 4.048645],
          [114.586199, 4.048365],
          [114.586707, 4.047959],
          [114.587647, 4.047501],
          [114.588612, 4.04712],
          [114.589679, 4.046815],
          [114.591026, 4.046536],
          [114.593465, 4.046536],
          [114.59692, 4.046638],
          [114.598775, 4.047146],
          [114.599842, 4.047603],
          [114.600985, 4.047857],
          [114.602052, 4.047933],
          [114.602916, 4.047832],
          [114.60411, 4.047578],
          [114.605405, 4.047196],
          [114.606878, 4.046536],
          [114.607996, 4.046104],
          [114.61356, 4.044859],
          [114.614449, 4.044554],
          [114.615212, 4.044148],
          [114.615821, 4.043563],
          [114.616914, 4.042242],
          [114.617752, 4.040794],
          [114.619505, 4.038127],
          [114.621207, 4.036272],
          [114.623443, 4.034037],
          [114.62634, 4.031267],
          [114.627305, 4.030251],
          [114.627508, 4.029768],
          [114.62761, 4.02926],
          [114.627635, 4.028777],
          [114.627559, 4.028371],
          [114.626746, 4.026643],
          [114.626543, 4.026109],
          [114.626467, 4.025627],
          [114.62634, 4.022426],
          [114.625806, 4.021282],
          [114.625424, 4.020749],
          [114.625196, 4.02019],
          [114.625069, 4.019682],
          [114.624993, 4.018259],
          [114.624764, 4.017675],
          [114.62446, 4.017268],
          [114.624129, 4.016989],
          [114.623621, 4.01671],
          [114.622452, 4.016379],
          [114.621716, 4.016024],
          [114.621334, 4.015668],
          [114.620979, 4.015185],
          [114.620674, 4.014931],
          [114.620013, 4.014576],
          [114.617727, 4.013178],
          [114.617117, 4.012645],
          [114.616634, 4.011984],
          [114.615669, 4.010358],
          [114.614627, 4.008046],
          [114.614221, 4.007386],
          [114.61384, 4.007004],
          [114.61323, 4.006776],
          [114.611502, 4.006395],
          [114.610867, 4.006115],
          [114.610334, 4.00576],
          [114.610029, 4.005378],
          [114.609902, 4.004591],
          [114.61008, 4.004108],
          [114.610283, 4.003752],
          [114.610715, 4.003498],
          [114.611452, 4.003168],
          [114.612239, 4.003016],
          [114.613535, 4.003016],
          [114.615034, 4.002939],
          [114.616253, 4.002736],
          [114.617701, 4.002584],
          [114.619073, 4.002508],
          [114.620064, 4.002533],
          [114.623646, 4.002889],
          [114.626085, 4.002965],
          [114.627152, 4.003066],
          [114.628778, 4.003422],
          [114.635104, 4.005353],
          [114.636298, 4.005556],
          [114.637645, 4.005683],
          [114.638687, 4.005709],
          [114.640008, 4.005505],
          [114.641253, 4.005251],
          [114.642116, 4.005175],
          [114.644809, 4.005709],
          [114.648061, 4.006039],
          [114.649205, 4.006242],
          [114.650449, 4.006623],
          [114.652761, 4.007462],
          [114.655302, 4.008503],
          [114.660129, 4.010256],
          [114.671079, 4.015185],
          [114.676567, 4.017141],
          [114.682867, 4.020266],
          [114.686957, 4.022476],
          [114.692496, 4.025982],
          [114.694605, 4.027177],
          [114.696612, 4.028167],
          [114.701464, 4.031546],
          [114.703344, 4.033401],
          [114.704208, 4.034011],
          [114.706672, 4.035383],
          [114.708959, 4.036856],
          [114.711474, 4.038584],
          [114.716123, 4.04214],
          [114.718511, 4.043715],
          [114.720035, 4.045037],
          [114.724151, 4.049026],
          [114.725116, 4.050245],
          [114.726158, 4.052278],
          [114.726844, 4.053243],
          [114.728241, 4.055072],
          [114.732154, 4.059645],
          [114.733348, 4.06122],
          [114.734593, 4.063253],
          [114.736168, 4.065971],
          [114.741808, 4.075295],
          [114.743205, 4.077048],
          [114.745924, 4.079893],
          [114.753063, 4.088735],
          [114.753736, 4.089992],
          [114.755718, 4.09327],
          [114.756937, 4.09454],
          [114.759478, 4.096496],
          [114.760469, 4.097868],
          [114.761815, 4.09957],
          [114.76494, 4.102543],
          [114.771546, 4.108539],
          [114.772968, 4.109733],
          [114.774239, 4.110876],
          [114.774645, 4.111536],
          [114.775103, 4.112578],
          [114.775992, 4.115296],
          [114.7765, 4.116693],
          [114.777008, 4.117862],
          [114.777897, 4.119031],
          [114.778583, 4.11959],
          [114.779345, 4.119945],
          [114.780311, 4.120149],
          [114.781048, 4.120403],
          [114.782623, 4.121444],
          [114.78341, 4.121825],
          [114.786129, 4.122333],
          [114.786815, 4.122587],
          [114.787729, 4.123045],
          [114.788593, 4.1234],
          [114.789254, 4.123477],
          [114.790219, 4.123375],
          [114.790625, 4.123452],
          [114.79126, 4.123655],
          [114.791997, 4.124239],
          [114.792327, 4.124646],
          [114.793267, 4.125509],
          [114.793496, 4.125967],
          [114.793598, 4.126678],
          [114.793598, 4.128024],
          [114.79375, 4.129066],
          [114.793877, 4.129549],
          [114.79375, 4.129955],
          [114.793572, 4.130311],
          [114.792861, 4.131429],
          [114.792759, 4.13181],
          [114.792785, 4.132216],
          [114.79309, 4.132648],
          [114.794055, 4.133283],
          [114.796265, 4.134071],
          [114.796519, 4.134376],
          [114.796672, 4.134833],
          [114.7969, 4.135291],
          [114.797383, 4.135748],
          [114.798425, 4.136561],
          [114.799466, 4.13717],
          [114.800076, 4.137628],
          [114.800559, 4.13806],
          [114.800838, 4.138542],
          [114.801016, 4.139076],
          [114.801423, 4.143116],
          [114.801652, 4.143598],
          [114.801956, 4.143979],
          [114.80249, 4.145148],
          [114.803024, 4.145631],
          [114.805285, 4.147053],
          [114.806148, 4.147409],
          [114.80653, 4.147663],
          [114.80686, 4.147943],
          [114.80719, 4.148451],
          [114.807622, 4.149289],
          [114.807876, 4.150229],
          [114.807978, 4.151677],
          [114.808036, 4.1521263],
          [114.80846, 4.155412],
          [114.808841, 4.157216],
          [114.80874, 4.157978],
          [114.808562, 4.158791],
          [114.807495, 4.160188],
          [114.806784, 4.160646],
          [114.805462, 4.161179],
          [114.805107, 4.161281],
          [114.804777, 4.161281],
          [114.80343, 4.160925],
          [114.802465, 4.160468],
          [114.799746, 4.158867],
          [114.798171, 4.158029],
          [114.797231, 4.157699],
          [114.796596, 4.157546],
          [114.795961, 4.157521],
          [114.79563, 4.157597],
          [114.794259, 4.158207],
          [114.793903, 4.158435],
          [114.793115, 4.159883],
          [114.792988, 4.160493],
          [114.793166, 4.161484],
          [114.793496, 4.162678],
          [114.793827, 4.163593],
          [114.794157, 4.164228],
          [114.795097, 4.165777],
          [114.79558, 4.166209],
          [114.796215, 4.166539],
          [114.796723, 4.166971],
          [114.797155, 4.167555],
          [114.797663, 4.16847],
          [114.798603, 4.170528],
          [114.798984, 4.172154],
          [114.799111, 4.173831],
          [114.79934, 4.175127],
          [114.799746, 4.176981],
          [114.800051, 4.178023],
          [114.801245, 4.180386],
          [114.802719, 4.182469],
          [114.803836, 4.18351],
          [114.80498, 4.184349],
          [114.805285, 4.184831],
          [114.805386, 4.185314],
          [114.805462, 4.186406],
          [114.805412, 4.1876],
          [114.805539, 4.188591],
          [114.805844, 4.189404],
          [114.806275, 4.190242],
          [114.807165, 4.191615],
          [114.81034, 4.195222],
          [114.812626, 4.197509],
          [114.813134, 4.198322],
          [114.813287, 4.198881],
          [114.813744, 4.19977],
          [114.817758, 4.203581],
          [114.8204, 4.207265],
          [114.824695, 4.212092],
          [114.827184, 4.215115],
          [114.828836, 4.216563],
          [114.829979, 4.217656],
          [114.830995, 4.218545],
          [114.831554, 4.219307],
          [114.831961, 4.220044],
          [114.832138, 4.220933],
          [114.832621, 4.224591],
          [114.832799, 4.225455],
          [114.832951, 4.225913],
          [114.833358, 4.226421],
          [114.835543, 4.228428],
          [114.836203, 4.228961],
          [114.836737, 4.229622],
          [114.838058, 4.232188],
          [114.838896, 4.234093],
          [114.841538, 4.239174],
          [114.842173, 4.240826],
          [114.842834, 4.242858],
          [114.843317, 4.244027],
          [114.84385, 4.245145],
          [114.845552, 4.247838],
          [114.846619, 4.249235],
          [114.847102, 4.249773],
          [114.847612, 4.250481],
          [114.848716, 4.25351],
          [114.849056, 4.254615],
          [114.849905, 4.256597],
          [114.850385, 4.257917],
          [114.850811, 4.258777],
          [114.851548, 4.260023],
          [114.852114, 4.261184],
          [114.8529592, 4.2623695],
          [114.853177, 4.262675],
          [114.854209, 4.26427],
          [114.855163, 4.265881],
          [114.856528, 4.267432],
          [114.857268, 4.268801],
          [114.857749, 4.269565],
          [114.858032, 4.27033],
          [114.85806, 4.270981],
          [114.857831, 4.272086],
          [114.857353, 4.272963],
          [114.856815, 4.273756],
          [114.856531, 4.274379],
          [114.856475, 4.27486],
          [114.856531, 4.275398],
          [114.856758, 4.276248],
          [114.856956, 4.277267],
          [114.856956, 4.277833],
          [114.856871, 4.27823],
          [114.856645, 4.278485],
          [114.856342, 4.278684],
          [114.855887, 4.278664],
          [114.855328, 4.278498],
          [114.853502, 4.277664],
          [114.851859, 4.277267],
          [114.850245, 4.276701],
          [114.849679, 4.276418],
          [114.848172, 4.275251],
          [114.847799, 4.274609],
          [114.847406, 4.274092],
          [114.846026, 4.272482],
          [114.845488, 4.271746],
          [114.844883, 4.271072],
          [114.84444, 4.270415],
          [114.843053, 4.268942],
          [114.84209, 4.267753],
          [114.840674, 4.266536],
          [114.838367, 4.263874],
          [114.837767, 4.263109],
          [114.836795, 4.261702],
          [114.836172, 4.2609],
          [114.835691, 4.260475],
          [114.834813, 4.259966],
          [114.834162, 4.259796],
          [114.833426, 4.259739],
          [114.83167, 4.259711],
          [114.830756, 4.259799],
          [114.828915, 4.260192],
          [114.828501, 4.260068],
          [114.828017, 4.259853],
          [114.827451, 4.259484],
          [114.825957, 4.258],
          [114.825295, 4.257421],
          [114.824426, 4.256862],
          [114.822496, 4.254444],
          [114.821533, 4.253029],
          [114.821055, 4.252146],
          [114.820413, 4.251567],
          [114.819689, 4.251154],
          [114.818956, 4.250905],
          [114.818163, 4.250707],
          [114.817456, 4.250622],
          [114.816776, 4.25065],
          [114.816068, 4.250877],
          [114.815219, 4.251556],
          [114.814624, 4.252264],
          [114.814208, 4.253056],
          [114.81398, 4.253739],
          [114.813831, 4.254756],
          [114.813898, 4.25649],
          [114.814042, 4.25711],
          [114.814311, 4.257607],
          [114.814787, 4.258041],
          [114.815587, 4.258635],
          [114.816096, 4.259088],
          [114.816578, 4.259711],
          [114.816918, 4.260277],
          [114.817059, 4.260759],
          [114.817372, 4.261227],
          [114.817724, 4.261557],
          [114.817965, 4.262004],
          [114.8182, 4.262736],
          [114.818882, 4.26346],
          [114.819131, 4.263895],
          [114.819275, 4.26435],
          [114.819579, 4.264779],
          [114.820231, 4.265544],
          [114.820825, 4.266167],
          [114.821613, 4.267225],
          [114.82213, 4.268093],
          [114.823487, 4.269707],
          [114.823968, 4.270415],
          [114.82445, 4.271009],
          [114.824818, 4.271604],
          [114.825157, 4.272539],
          [114.82561, 4.272992],
          [114.826433, 4.273493],
          [114.826846, 4.273989],
          [114.827549, 4.275334],
          [114.828074, 4.27571],
          [114.828499, 4.276276],
          [114.82864, 4.276842],
          [114.828527, 4.277918],
          [114.828669, 4.278202],
          [114.828935, 4.278643],
          [114.829349, 4.278974],
          [114.829866, 4.279264],
          [114.830363, 4.27976],
          [114.830934, 4.280439],
          [114.831274, 4.281288],
          [114.8315, 4.282053],
          [114.831727, 4.282647],
          [114.831981, 4.283015],
          [114.832293, 4.283383],
          [114.832831, 4.283808],
          [114.833423, 4.284373],
          [114.83394, 4.2846],
          [114.834416, 4.284931],
          [114.834726, 4.285241],
          [114.835057, 4.285655],
          [114.835408, 4.286328],
          [114.835968, 4.287082],
          [114.836319, 4.287703],
          [114.83654, 4.288254],
          [114.838183, 4.290858],
          [114.838636, 4.291509],
          [114.83957, 4.292416],
          [114.840618, 4.293208],
          [114.840901, 4.293605],
          [114.841184, 4.294115],
          [114.841553, 4.29519],
          [114.843, 4.297176],
          [114.843269, 4.297817],
          [114.843414, 4.298893],
          [114.843435, 4.299844],
          [114.843761, 4.300599],
          [114.844808, 4.301562],
          [114.84713, 4.303289],
          [114.847427, 4.303629],
          [114.84764, 4.303969],
          [114.847753, 4.30445],
          [114.847866, 4.3053],
          [114.847882, 4.305843],
          [114.848089, 4.306153],
          [114.848585, 4.306546],
          [114.8505, 4.306913],
          [114.851009, 4.307168],
          [114.851236, 4.307395],
          [114.851434, 4.307848],
          [114.851491, 4.308357],
          [114.851406, 4.30898],
          [114.850953, 4.309773],
          [114.85084, 4.310113],
          [114.850943, 4.310538],
          [114.851295, 4.31091],
          [114.851502, 4.311241],
          [114.851717, 4.312293],
          [114.851895, 4.312793],
          [114.85357, 4.314116],
          [114.854039, 4.314247],
          [114.854549, 4.314474],
          [114.854997, 4.314551],
          [114.855349, 4.314696],
          [114.855473, 4.314965],
          [114.855266, 4.315337],
          [114.854873, 4.315709],
          [114.854914, 4.316268],
          [114.855307, 4.317281],
          [114.85571, 4.318154],
          [114.858534, 4.321067],
          [114.859844, 4.322034],
          [114.860541, 4.322639],
          [114.861061, 4.323563],
          [114.861911, 4.324668],
          [114.862675, 4.326083],
          [114.863105, 4.326734],
          [114.863865, 4.327358],
          [114.86445, 4.328078],
          [114.865592, 4.330189],
          [114.865877, 4.33087],
          [114.866353, 4.331284],
          [114.867036, 4.331661],
          [114.867739, 4.332463],
          [114.868056, 4.333502],
          [114.868669, 4.334553],
          [114.869018, 4.335003],
          [114.869476, 4.335773],
          [114.86991, 4.336311],
          [114.870236, 4.3369],
          [114.870349, 4.337466],
          [114.870377, 4.338004],
          [114.870349, 4.338542],
          [114.870434, 4.33908],
          [114.870887, 4.33959],
          [114.871737, 4.340071],
          [114.872161, 4.340581],
          [114.872275, 4.341062],
          [114.872331, 4.342422],
          [114.872501, 4.343044],
          [114.872599, 4.343943],
          [114.872501, 4.344234],
          [114.87202, 4.344956],
          [114.871896, 4.345329],
          [114.872, 4.34566],
          [114.872331, 4.346115],
          [114.87262, 4.346425],
          [114.872813, 4.346895],
          [114.873068, 4.348679],
          [114.873181, 4.349189],
          [114.873344, 4.349672],
          [114.873613, 4.349962],
          [114.873985, 4.350189],
          [114.874606, 4.350355],
          [114.875123, 4.350644],
          [114.875475, 4.35141],
          [114.875673, 4.352587],
          [114.875729, 4.353266],
          [114.876012, 4.353663],
          [114.87706, 4.354427],
          [114.877202, 4.354626],
          [114.877258, 4.354994],
          [114.877258, 4.355447],
          [114.877088, 4.356721],
          [114.876805, 4.358307],
          [114.87689, 4.35893],
          [114.877584, 4.359891],
          [114.877711, 4.360346],
          [114.877626, 4.360799],
          [114.877598, 4.361422],
          [114.877683, 4.36179],
          [114.878504, 4.363347],
          [114.878561, 4.363715],
          [114.878278, 4.364508],
          [114.878164, 4.364979],
          [114.878226, 4.365537],
          [114.878453, 4.366365],
          [114.878495, 4.36682],
          [114.878278, 4.367509],
          [114.878334, 4.367821],
          [114.878577, 4.368392],
          [114.879439, 4.369208],
          [114.879977, 4.370199],
          [114.880175, 4.37068],
          [114.88026, 4.371756],
          [114.880273, 4.374431],
          [114.880067, 4.374887],
          [114.879777, 4.375259],
          [114.879184, 4.375806],
          [114.879155, 4.376174],
          [114.879297, 4.376542],
          [114.879665, 4.377221],
          [114.879977, 4.377674],
          [114.879977, 4.378184],
          [114.879863, 4.379005],
          [114.879608, 4.379685],
          [114.879552, 4.380053],
          [114.87958, 4.380534],
          [114.87975, 4.380902],
          [114.880212, 4.381671],
          [114.880584, 4.382436],
          [114.880749, 4.382933],
          [114.880883, 4.383366],
          [114.880854, 4.384414],
          [114.880656, 4.38498],
          [114.880373, 4.385405],
          [114.880175, 4.385801],
          [114.880005, 4.386396],
          [114.880149, 4.387566],
          [114.880087, 4.388331],
          [114.879963, 4.388952],
          [114.879777, 4.389428],
          [114.879637, 4.390105],
          [114.87957, 4.391289],
          [114.87957, 4.39222],
          [114.879343, 4.39251],
          [114.878363, 4.393107],
          [114.877994, 4.393504],
          [114.877881, 4.393702],
          [114.877825, 4.394098],
          [114.877938, 4.39458],
          [114.878079, 4.394948],
          [114.878391, 4.395486],
          [114.878901, 4.396109],
          [114.879693, 4.396901],
          [114.880628, 4.398232],
          [114.881194, 4.399761],
          [114.88197, 4.400969],
          [114.88228, 4.401693],
          [114.882342, 4.402479],
          [114.882135, 4.403038],
          [114.88168, 4.403865],
          [114.881535, 4.4043],
          [114.881515, 4.404817],
          [114.881789, 4.405877],
          [114.882298, 4.407435],
          [114.88261, 4.4097],
          [114.8829, 4.410298],
          [114.88319, 4.410629],
          [114.883397, 4.411022],
          [114.883355, 4.41156],
          [114.883035, 4.412163],
          [114.882808, 4.412871],
          [114.882695, 4.41372],
          [114.882666, 4.414428],
          [114.882808, 4.415023],
          [114.883035, 4.415618],
          [114.883314, 4.41611],
          [114.883516, 4.416609],
          [114.883544, 4.417033],
          [114.883318, 4.418138],
          [114.883289, 4.418676],
          [114.883374, 4.419129],
          [114.883516, 4.419553],
          [114.883742, 4.419893],
          [114.883827, 4.420318],
          [114.883459, 4.423263],
          [114.883289, 4.423999],
          [114.882836, 4.425415],
          [114.882507, 4.425708],
          [114.882135, 4.425915],
          [114.881618, 4.426101],
          [114.881018, 4.426101],
          [114.880191, 4.425998],
          [114.879487, 4.42606],
          [114.877584, 4.426556],
          [114.87715, 4.426494],
          [114.876592, 4.426225],
          [114.875578, 4.425563],
          [114.873489, 4.424674],
          [114.872807, 4.424529],
          [114.872076, 4.424736],
          [114.871454, 4.424792],
          [114.870887, 4.424651],
          [114.870349, 4.424367],
          [114.86882, 4.424028],
          [114.868141, 4.423829],
          [114.867659, 4.423575],
          [114.867235, 4.423263],
          [114.866612, 4.422923],
          [114.866159, 4.422838],
          [114.865257, 4.422792],
          [114.864761, 4.422605],
          [114.864233, 4.422244],
          [114.863384, 4.421508],
          [114.862761, 4.421281],
          [114.861855, 4.421224],
          [114.860694, 4.421224],
          [114.859674, 4.421451],
          [114.857182, 4.421734],
          [114.85622, 4.421932],
          [114.855455, 4.422187],
          [114.854832, 4.422527],
          [114.854294, 4.422669],
          [114.853728, 4.422753],
          [114.852793, 4.422753],
          [114.850698, 4.423008],
          [114.850217, 4.422952],
          [114.84965, 4.422753],
          [114.847272, 4.421224],
          [114.846422, 4.421054],
          [114.845686, 4.421168],
          [114.844553, 4.421677],
          [114.843619, 4.4223],
          [114.842194, 4.423164],
          [114.840532, 4.424764],
          [114.839372, 4.425557],
          [114.83785, 4.425998],
          [114.836113, 4.426122],
          [114.835554, 4.426204],
          [114.834954, 4.426434],
          [114.83351, 4.427341],
          [114.832745, 4.427879],
          [114.832151, 4.428077],
          [114.831585, 4.428332],
          [114.83082, 4.428785],
          [114.829546, 4.429663],
          [114.829036, 4.430257],
          [114.828696, 4.430795],
          [114.828498, 4.431277],
          [114.828272, 4.432183],
          [114.828159, 4.433145],
          [114.82779, 4.434249],
          [114.827762, 4.434589],
          [114.82779, 4.434986],
          [114.827904, 4.435835],
          [114.827762, 4.436656],
          [114.827507, 4.437562],
          [114.827322, 4.438512],
          [114.827156, 4.438904],
          [114.826867, 4.439339],
          [114.826639, 4.439794],
          [114.826495, 4.440311],
          [114.826545, 4.440734],
          [114.826681, 4.441242],
          [114.826908, 4.441552],
          [114.827363, 4.441924],
          [114.827508, 4.442255],
          [114.827446, 4.442772],
          [114.827054, 4.44365],
          [114.825837, 4.445406],
          [114.825667, 4.445774],
          [114.82561, 4.446057],
          [114.825638, 4.44634],
          [114.82578, 4.446708],
          [114.826035, 4.447076],
          [114.826346, 4.447331],
          [114.826601, 4.447671],
          [114.826686, 4.447983],
          [114.826714, 4.448322],
          [114.826658, 4.448605],
          [114.82612, 4.449455],
          [114.82544, 4.450135],
          [114.82527, 4.450418],
          [114.825214, 4.450786],
          [114.82527, 4.451522],
          [114.825016, 4.452966],
          [114.824619, 4.454778],
          [114.824336, 4.455429],
          [114.823628, 4.456505],
          [114.822948, 4.457865],
          [114.822379, 4.458575],
          [114.821391, 4.459394],
          [114.82125, 4.459705],
          [114.821221, 4.46013],
          [114.821363, 4.460498],
          [114.821986, 4.461574],
          [114.822071, 4.461942],
          [114.822068, 4.462464],
          [114.822014, 4.462905],
          [114.821618, 4.463726],
          [114.821419, 4.464349],
          [114.821448, 4.465],
          [114.821504, 4.465227],
          [114.821504, 4.46585],
          [114.821391, 4.466218],
          [114.820995, 4.467039],
          [114.82108, 4.468002],
          [114.821334, 4.469135],
          [114.821334, 4.469616],
          [114.821278, 4.470041],
          [114.821136, 4.470522],
          [114.820966, 4.470919],
          [114.820655, 4.471456],
          [114.820485, 4.471881],
          [114.820485, 4.472334],
          [114.820599, 4.472674],
          [114.820655, 4.47307],
          [114.820655, 4.473467],
          [114.820485, 4.473863],
          [114.820117, 4.474429],
          [114.819664, 4.474854],
          [114.818956, 4.475392],
          [114.818645, 4.475873],
          [114.818475, 4.47627],
          [114.818305, 4.477006],
          [114.818248, 4.477827],
          [114.818079, 4.478847],
          [114.818107, 4.480715],
          [114.81805, 4.483434],
          [114.818135, 4.483972],
          [114.818871, 4.485925],
          [114.818985, 4.486633],
          [114.8189, 4.487483],
          [114.818617, 4.488559],
          [114.818418, 4.489578],
          [114.818333, 4.490286],
          [114.818503, 4.491504],
          [114.818786, 4.492325],
          [114.818673, 4.492749],
          [114.81839, 4.493259],
          [114.81822, 4.493684],
          [114.818305, 4.493995],
          [114.819098, 4.494789],
          [114.819466, 4.495298],
          [114.819579, 4.495666],
          [114.819608, 4.496034],
          [114.819296, 4.496799],
          [114.81856, 4.497847],
          [114.818475, 4.498328],
          [114.818503, 4.498639],
          [114.818617, 4.499008],
          [114.8188, 4.499226],
          [114.818927, 4.499836],
          [114.819282, 4.501056],
          [114.819308, 4.50164],
          [114.819232, 4.502123],
          [114.819054, 4.502606],
          [114.818927, 4.503673],
          [114.819028, 4.504232],
          [114.819257, 4.504994],
          [114.819232, 4.505553],
          [114.818876, 4.50662],
          [114.81885, 4.507026],
          [114.818927, 4.507433],
          [114.819435, 4.50822],
          [114.819511, 4.508551],
          [114.819409, 4.509084],
          [114.819282, 4.509491],
          [114.818673, 4.51099],
          [114.818673, 4.511345],
          [114.8188, 4.51165],
          [114.819079, 4.511955],
          [114.819333, 4.512336],
          [114.81974, 4.513124],
          [114.819918, 4.513937],
          [114.81979, 4.514394],
          [114.819282, 4.515435],
          [114.81913, 4.516121],
          [114.819105, 4.516579],
          [114.818393, 4.518408],
          [114.818317, 4.518967],
          [114.818342, 4.5195],
          [114.818774, 4.520898],
          [114.818876, 4.522015],
          [114.818876, 4.522498],
          [114.8188, 4.523057],
          [114.818292, 4.524226],
          [114.818241, 4.524988],
          [114.818393, 4.525344],
          [114.818876, 4.525903],
          [114.819359, 4.526589],
          [114.819511, 4.52697],
          [114.819486, 4.527554],
          [114.819409, 4.528138],
          [114.819206, 4.528519],
          [114.81885, 4.528926],
          [114.818444, 4.529663],
          [114.81819, 4.530018],
          [114.818114, 4.530272],
          [114.818088, 4.530704],
          [114.818317, 4.531289],
          [114.818977, 4.53228],
          [114.819206, 4.532839],
          [114.819587, 4.534109],
          [114.819918, 4.535659],
          [114.819765, 4.536497],
          [114.819435, 4.53703],
          [114.819384, 4.537412],
          [114.819536, 4.539063],
          [114.819435, 4.539444],
          [114.819155, 4.539952],
          [114.817885, 4.541273],
          [114.817707, 4.541553],
          [114.817606, 4.541883],
          [114.817631, 4.542289],
          [114.817783, 4.542874],
          [114.818444, 4.544627],
          [114.818469, 4.545312],
          [114.818292, 4.545795],
          [114.818139, 4.546049],
          [114.817148, 4.547269],
          [114.81697, 4.547624],
          [114.816894, 4.548056],
          [114.81692, 4.548386],
          [114.817224, 4.548869],
          [114.818495, 4.550495],
          [114.8188, 4.55108],
          [114.818876, 4.55141],
          [114.8188, 4.552274],
          [114.817885, 4.5539],
          [114.817656, 4.554459],
          [114.817403, 4.555526],
          [114.817428, 4.55583],
          [114.817759, 4.556161],
          [114.818597, 4.556821],
          [114.818902, 4.557304],
          [114.819029, 4.55771],
          [114.819003, 4.558066],
          [114.818876, 4.558498],
          [114.817656, 4.560328],
          [114.816996, 4.561776],
          [114.816513, 4.562385],
          [114.816005, 4.562919],
          [114.815192, 4.563554],
          [114.814887, 4.563986],
          [114.814836, 4.564291],
          [114.81504, 4.566069],
          [114.81504, 4.566603],
          [114.814811, 4.568229],
          [114.814811, 4.568686],
          [114.81504, 4.569601],
          [114.816716, 4.571709],
          [114.817097, 4.572471],
          [114.817148, 4.572827],
          [114.817097, 4.573386],
          [114.816945, 4.573818],
          [114.816666, 4.575037],
          [114.816666, 4.576104],
          [114.817021, 4.577171],
          [114.816996, 4.577908],
          [114.816843, 4.578543],
          [114.816666, 4.579687],
          [114.816666, 4.580271],
          [114.816716, 4.580728],
          [114.816894, 4.581262],
          [114.817352, 4.581948],
          [114.81791, 4.582659],
          [114.818139, 4.583116],
          [114.818241, 4.583574],
          [114.818215, 4.584056],
          [114.817936, 4.584514],
          [114.817428, 4.584895],
          [114.814659, 4.586317],
          [114.814201, 4.586673],
          [114.813998, 4.586978],
          [114.813922, 4.587359],
          [114.813922, 4.587765],
          [114.814074, 4.588324],
          [114.814277, 4.588832],
          [114.814684, 4.589366],
          [114.816157, 4.590636],
          [114.816513, 4.59117],
          [114.816615, 4.591475],
          [114.816564, 4.592415],
          [114.816361, 4.593177],
          [114.816005, 4.594092],
          [114.815548, 4.5946],
          [114.814633, 4.595209],
          [114.813896, 4.595489],
          [114.812906, 4.595768],
          [114.810365, 4.596327],
          [114.809806, 4.596505],
          [114.809374, 4.596734],
          [114.809044, 4.597039],
          [114.808764, 4.597445],
          [114.808637, 4.597979],
          [114.808561, 4.598614],
          [114.808561, 4.599223],
          [114.809044, 4.600417],
          [114.809451, 4.600951],
          [114.810086, 4.601535],
          [114.811153, 4.602247],
          [114.811331, 4.602602],
          [114.811305, 4.603009],
          [114.811127, 4.603339],
          [114.809857, 4.604381],
          [114.809578, 4.604762],
          [114.809222, 4.605753],
          [114.808993, 4.60654],
          [114.808917, 4.607074],
          [114.808943, 4.60776],
          [114.809095, 4.608166],
          [114.809171, 4.608776],
          [114.809146, 4.609157],
          [114.808993, 4.609741],
          [114.808638, 4.610656],
          [114.808561, 4.611164],
          [114.808561, 4.611571],
          [114.80879, 4.612536],
          [114.809527, 4.614086],
          [114.809654, 4.614619],
          [114.809654, 4.615356],
          [114.809552, 4.615686],
          [114.809019, 4.616347],
          [114.808434, 4.617185],
          [114.80813, 4.617719],
          [114.808053, 4.618633],
          [114.80818, 4.619827],
          [114.807494, 4.622165],
          [114.807342, 4.622825],
          [114.807317, 4.623511],
          [114.807698, 4.625137],
          [114.807774, 4.625645],
          [114.807748, 4.626001],
          [114.807444, 4.626611],
          [114.806529, 4.627805],
          [114.80597, 4.628364],
          [114.803836, 4.629888],
          [114.803302, 4.630371],
          [114.802159, 4.631641],
          [114.801651, 4.632378],
          [114.801219, 4.633165],
          [114.800762, 4.634207],
          [114.800076, 4.635427],
          [114.799847, 4.636189],
          [114.799822, 4.637205],
          [114.799415, 4.639288],
          [114.799543, 4.639872],
          [114.800407, 4.641574],
          [114.800711, 4.642337],
          [114.800864, 4.643759],
          [114.800813, 4.644522],
          [114.800457, 4.647138],
          [114.800305, 4.648815],
          [114.80033, 4.649781],
          [114.800457, 4.651051],
          [114.800686, 4.652169],
          [114.800534, 4.65382],
          [114.800381, 4.654328],
          [114.800381, 4.654963],
          [114.800686, 4.656996],
          [114.800661, 4.657656],
          [114.800508, 4.658139],
          [114.800025, 4.658495],
          [114.799492, 4.658775],
          [114.798298, 4.659282],
          [114.798018, 4.659435],
          [114.797587, 4.659791],
          [114.797383, 4.660197],
          [114.797256, 4.66068],
          [114.797358, 4.662509],
          [114.797332, 4.664262],
          [114.797587, 4.664999],
          [114.797942, 4.665583],
          [114.798831, 4.666752],
          [114.799721, 4.667819],
          [114.799848, 4.668251],
          [114.799873, 4.668784],
          [114.799517, 4.669673],
          [114.799492, 4.670029],
          [114.799594, 4.670385],
          [114.800254, 4.671553],
          [114.800432, 4.672137],
          [114.800787, 4.672773],
          [114.801296, 4.673154],
          [114.801829, 4.673713],
          [114.802134, 4.674272],
          [114.802287, 4.674881],
          [114.802287, 4.675364],
          [114.801804, 4.676152],
          [114.801067, 4.676736],
          [114.800254, 4.677041],
          [114.799034, 4.677397],
          [114.797637, 4.677981],
          [114.796824, 4.678133],
          [114.795732, 4.67821],
          [114.794919, 4.678108],
          [114.791997, 4.677879],
          [114.790803, 4.677854],
          [114.790066, 4.678006],
          [114.789558, 4.678311],
          [114.788796, 4.678896],
          [114.788161, 4.680064],
          [114.787958, 4.680903],
          [114.787856, 4.681792],
          [114.787678, 4.682249],
          [114.78745, 4.683113],
          [114.787323, 4.684078],
          [114.786967, 4.685475],
          [114.786637, 4.687254],
          [114.786027, 4.689972],
          [114.785798, 4.690861],
          [114.785519, 4.691293],
          [114.785036, 4.691598],
          [114.784528, 4.691827],
          [114.783461, 4.692183],
          [114.781962, 4.692539],
          [114.77932, 4.692691],
          [114.778151, 4.692919],
          [114.776855, 4.693402],
          [114.776245, 4.693936],
          [114.775813, 4.694419],
          [114.775381, 4.695181],
          [114.775077, 4.696096],
          [114.775077, 4.697823],
          [114.77528, 4.699373],
          [114.775483, 4.700237],
          [114.775966, 4.70138],
          [114.776957, 4.703132],
          [114.777262, 4.70392],
          [114.777592, 4.704581],
          [114.778049, 4.704936],
          [114.778989, 4.705546],
          [114.779853, 4.706232],
          [114.780767, 4.706741],
          [114.781403, 4.70735],
          [114.781733, 4.707909],
          [114.783079, 4.712456],
          [114.783232, 4.713346],
          [114.783308, 4.714133],
          [114.783308, 4.714921],
          [114.783206, 4.715912],
          [114.783054, 4.716801],
          [114.782622, 4.717919],
          [114.782292, 4.718478],
          [114.781707, 4.718884],
          [114.781123, 4.719189],
          [114.771748, 4.72112],
          [114.770732, 4.721374],
          [114.7703, 4.721628],
          [114.769843, 4.72206],
          [114.769741, 4.722288],
          [114.76964, 4.722746],
          [114.76964, 4.723228],
          [114.769741, 4.723991],
          [114.770046, 4.724727],
          [114.770784, 4.725718],
          [114.771469, 4.726226],
          [114.771977, 4.726506],
          [114.775204, 4.72775],
          [114.775839, 4.728132],
          [114.776449, 4.728564],
          [114.7781, 4.730824],
          [114.778837, 4.731536],
          [114.779523, 4.732095],
          [114.780336, 4.732654],
          [114.781199, 4.733137],
          [114.782063, 4.733518],
          [114.782546, 4.733823],
          [114.783079, 4.734331],
          [114.783892, 4.734763],
          [114.7851293, 4.7354894],
          [114.787094, 4.736643],
          [114.788364, 4.737151],
          [114.788974, 4.737456],
          [114.7896652, 4.7376805],
          [114.78999, 4.737786],
          [114.793496, 4.738548],
          [114.8024832, 4.7401282],
          [114.807419, 4.740886],
          [114.8130011, 4.7401047],
          [114.8137573, 4.7401104],
          [114.820451, 4.743807],
          [114.8217228, 4.7443802],
          [114.822865, 4.744569],
          [114.8238539, 4.7449935],
          [114.8243676, 4.7457929],
          [114.8251641, 4.7462188],
          [114.8257198, 4.747011],
          [114.8260492, 4.7477108],
          [114.8266216, 4.7489232],
          [114.8267635, 4.7494843],
          [114.8272366, 4.7504225],
          [114.8289408, 4.753157],
          [114.8314377, 4.7540167],
          [114.8340049, 4.7569723],
          [114.8342962, 4.758082],
          [114.8354905, 4.758956],
          [114.8366351, 4.7602938],
          [114.837645, 4.7620349],
          [114.8390678, 4.7650031],
          [114.8396035, 4.7661951],
          [114.8414, 4.769933],
          [114.8424719, 4.771598],
          [114.845094, 4.780239],
          [114.846883, 4.785606],
          [114.847622, 4.787122],
          [114.848672, 4.788794],
          [114.85085, 4.7914],
          [114.853728, 4.7942],
          [114.856294, 4.796144],
          [114.858511, 4.797505],
          [114.861039, 4.798555],
          [114.865705, 4.800033],
          [114.870022, 4.801472],
          [114.872589, 4.802406],
          [114.874767, 4.803261],
          [114.8756098, 4.8037128],
          [114.8769225, 4.8044494],
          [114.8785974, 4.8055956],
          [114.881455, 4.807423],
          [114.882428, 4.808045],
          [114.884372, 4.809095],
          [114.888339, 4.811467],
          [114.893433, 4.814423],
          [114.895572, 4.815745],
          [114.896272, 4.816056],
          [114.896855, 4.816173],
          [114.897361, 4.816134],
          [114.8985331, 4.815988],
          [114.9077427, 4.8124624],
          [114.9138867, 4.8101651],
          [114.9140584, 4.8091067],
          [114.9165045, 4.8088822],
          [114.9187394, 4.8088476],
          [114.919489, 4.80995],
          [114.921706, 4.810767],
          [114.92505, 4.812089],
          [114.927734, 4.812984],
          [114.9324, 4.814656],
          [114.933256, 4.814889],
          [114.93415, 4.814967],
          [114.9350416, 4.8148737],
          [114.937495, 4.814617],
          [114.9387, 4.8141528],
          [114.9390467, 4.8135175],
          [114.9388945, 4.812892],
          [114.938871, 4.8120526],
          [114.9392961, 4.8115285],
          [114.9389019, 4.8104376],
          [114.9392325, 4.8094607],
          [114.9426896, 4.8077635],
          [114.9437075, 4.8089165],
          [114.946682, 4.812915],
          [114.947654, 4.813362],
          [114.948276, 4.813751],
          [114.948781, 4.813926],
          [114.949112, 4.813926],
          [114.949365, 4.813809],
          [114.949676, 4.813459],
          [114.950493, 4.812293],
          [114.950765, 4.81204],
          [114.951037, 4.812021],
          [114.951699, 4.812118],
          [114.951951, 4.812273],
          [114.952262, 4.812584],
          [114.952729, 4.812701],
          [114.953312, 4.812759],
          [114.95374, 4.812721],
          [114.953974, 4.812643],
          [114.954285, 4.81239],
          [114.954849, 4.811748],
          [114.955101, 4.811573],
          [114.955393, 4.811554],
          [114.956171, 4.811671],
          [114.957104, 4.812215],
          [114.957454, 4.812332],
          [114.957727, 4.812332],
          [114.958057, 4.812215],
          [114.958407, 4.812001],
          [114.958738, 4.811904],
          [114.959127, 4.811923],
          [114.960099, 4.812196],
          [114.96041, 4.812234],
          [114.960663, 4.812196],
          [114.961129, 4.811962],
          [114.96181, 4.811476],
          [114.962374, 4.81099],
          [114.962743, 4.810776],
          [114.963035, 4.810679],
          [114.963346, 4.810698],
          [114.964202, 4.811009],
          [114.964493, 4.811029],
          [114.964863, 4.810873],
          [114.965232, 4.810873],
          [114.965796, 4.810582],
          [114.966263, 4.810504],
          [114.966574, 4.81029],
          [114.969335, 4.80891],
          [114.970015, 4.808463],
          [114.970715, 4.808229],
          [114.9711143, 4.8081205],
          [114.9712958, 4.8082497],
          [114.9714827, 4.8086012],
          [114.9722536, 4.810067],
          [114.9728133, 4.8112774],
          [114.973311, 4.811778],
          [114.973972, 4.812478],
          [114.974594, 4.8131937],
          [114.9749318, 4.8136825],
          [114.9752482, 4.8142783],
          [114.9754129, 4.8146607],
          [114.9755309, 4.8152553],
          [114.9755543, 4.8157198],
          [114.9755338, 4.8161605],
          [114.9755669, 4.8165157],
          [114.9759691, 4.817961],
          [114.9763873, 4.8193327],
          [114.9768627, 4.8205332],
          [114.9772093, 4.8211884],
          [114.9775568, 4.8217286],
          [114.977968, 4.8221454],
          [114.9783899, 4.8225623],
          [114.9795144, 4.8235281],
          [114.9801453, 4.8241412],
          [114.9804962, 4.8246205],
          [114.9807194, 4.8250784],
          [114.9807891, 4.8256757],
          [114.9808963, 4.826394],
          [114.9811055, 4.8271443],
          [114.9816037, 4.8281613],
          [114.9821455, 4.8289764],
          [114.9827195, 4.8297701],
          [114.9834974, 4.8307516],
          [114.9838375, 4.8313799],
          [114.9841078, 4.8322433],
          [114.9846873, 4.8334881],
          [114.9854777, 4.8347929],
          [114.9869512, 4.8368191],
          [114.9875556, 4.8378617],
          [114.9877793, 4.838606],
          [114.9878666, 4.8392783],
          [114.9877628, 4.8399003],
          [114.9872065, 4.8407125],
          [114.9864401, 4.8415462],
          [114.9854378, 4.8424761],
          [114.9847929, 4.8432454],
          [114.9843412, 4.8439186],
          [114.9838752, 4.844822],
          [114.9835378, 4.8456934],
          [114.9833069, 4.8468047],
          [114.983422, 4.847711],
          [114.983461, 4.849228],
          [114.983617, 4.850045],
          [114.98385, 4.850511],
          [114.984433, 4.850978],
          [114.986106, 4.851795],
          [114.988944, 4.85265],
          [114.990305, 4.853389],
          [114.991394, 4.854167],
          [114.992133, 4.854867],
          [114.992405, 4.855489],
          [114.992444, 4.85615],
          [114.99225, 4.857161],
          [114.991667, 4.858833],
          [114.9910327, 4.8603872],
          [114.989683, 4.863694],
          [114.988283, 4.867272],
          [114.987894, 4.868711],
          [114.987661, 4.8705],
          [114.987739, 4.8712],
          [114.988011, 4.871861],
          [114.988478, 4.872678],
          [114.99015, 4.874506],
          [114.992406, 4.876839],
          [114.996878, 4.880884],
          [115.000106, 4.883644],
          [115.001506, 4.884655],
          [115.002828, 4.885278],
          [115.0076422, 4.8886355],
          [115.0152864, 4.8940618],
          [115.0214657, 4.8955226],
          [115.0250193, 4.8966082],
          [115.0253476, 4.88954],
          [115.0254005, 4.888574],
          [115.0224097, 4.8833919],
          [115.0226394, 4.8831278],
          [115.0208368, 4.8793596],
          [115.0198472, 4.8785144],
          [115.0193373, 4.8782388],
          [115.0186593, 4.8774178],
          [115.0177323, 4.8762548],
          [115.0169427, 4.8750233],
          [115.0166594, 4.8731076],
          [115.016462, 4.8714058],
          [115.0168225, 4.8699091],
          [115.0170285, 4.8710979],
          [115.0173375, 4.8725004],
          [115.0177752, 4.8733556],
          [115.0182816, 4.8738602],
          [115.0230881, 4.8732958],
          [115.0244872, 4.8735181],
          [115.0257317, 4.8735438],
          [115.0270535, 4.8737576],
          [115.0280492, 4.8736293],
          [115.0287015, 4.8734839],
          [115.0291049, 4.8731589],
          [115.0246719, 4.8537758],
          [115.02344, 4.8520008],
          [115.0221354, 4.8495805],
          [115.0219208, 4.8474852],
          [115.0226504, 4.8457234],
          [115.0230366, 4.8430294],
          [115.0236765, 4.8415041],
          [115.023852, 4.8406433],
          [115.0237748, 4.840113],
          [115.0248563, 4.8391808],
          [115.0254399, 4.8381973],
          [115.0256116, 4.8371111],
          [115.0251996, 4.8354263],
          [115.0258004, 4.8338526],
          [115.0261866, 4.833425],
          [115.026118, 4.8317658],
          [115.025603, 4.8299868],
          [115.0247876, 4.8293454],
          [115.024104, 4.829031],
          [115.0237834, 4.8288836],
          [115.0229508, 4.828875],
          [115.0221955, 4.8271046],
          [115.0220238, 4.8250007],
          [115.0213391, 4.8237405],
          [115.0214528, 4.8229871],
          [115.0217807, 4.8226536],
          [115.0222511, 4.8226796],
          [115.0236864, 4.8222399],
          [115.0223354, 4.8215347],
          [115.0226806, 4.821211],
          [115.022547, 4.8207108],
          [115.0227328, 4.8195244],
          [115.0223279, 4.8185936],
          [115.0214505, 4.8176219],
          [115.0207621, 4.8170552],
          [115.0211983, 4.8163496],
          [115.0211763, 4.8151377],
          [115.0207836, 4.81414],
          [115.0207668, 4.8124796],
          [115.0213973, 4.8124709],
          [115.0215775, 4.8114018],
          [115.0215674, 4.8101564],
          [115.0219402, 4.8098411],
          [115.0221697, 4.809888],
          [115.0229276, 4.8101624],
          [115.0234128, 4.8094955],
          [115.0231694, 4.8092463],
          [115.0229533, 4.807881],
          [115.0232837, 4.8072341],
          [115.0236136, 4.8068767],
          [115.024464, 4.8068588],
          [115.0254656, 4.8072195],
          [115.0264971, 4.8067861],
          [115.0269162, 4.8070997],
          [115.0280166, 4.806346],
          [115.028016634147875, 4.806345976612346],
          [115.0278113, 4.8057201],
          [115.0273636, 4.805155],
          [115.025125, 4.8038611],
          [115.0240803, 4.8031622],
          [115.0234386, 4.8019873],
          [115.0228565, 4.8011991],
          [115.0218016, 4.8010091],
          [115.0213203, 4.8012066],
          [115.0205983, 4.8017286],
          [115.0200178, 4.8021659],
          [115.0195931, 4.8022929],
          [115.0185738, 4.8022646],
          [115.0180642, 4.8018132],
          [115.0182058, 4.8010655],
          [115.0189278, 4.8009668],
          [115.0189561, 4.8013618],
          [115.0192839, 4.8014868],
          [115.0195931, 4.8014041],
          [115.0199754, 4.8010938],
          [115.0207823, 4.8005859],
          [115.0216883, 4.8004025],
          [115.0222121, 4.8003884],
          [115.0230207, 4.8007678],
          [115.023737, 4.8015858],
          [115.024722, 4.802835],
          [115.0251698, 4.8028052],
          [115.0260204, 4.8021806],
          [115.0275576, 4.801556],
          [115.0287814, 4.8011842],
          [115.0302141, 4.801333],
          [115.0309304, 4.8019278],
          [115.0314378, 4.801675],
          [115.0316915, 4.800753],
          [115.0322139, 4.7990576],
          [115.0325956, 4.7980301],
          [115.0327029, 4.7976188],
          [115.0337469, 4.7961221],
          [115.0340048, 4.7956966],
          [115.0364374, 4.793793],
          [115.0387804, 4.7926776],
          [115.0409146, 4.7917109],
          [115.0422727, 4.7912648],
          [115.0447202, 4.791235],
          [115.0462126, 4.7916515],
          [115.0478095, 4.7920381],
          [115.0490183, 4.7928709],
          [115.0503913, 4.7939715],
          [115.0509883, 4.7940607],
          [115.0519434, 4.7935848],
          [115.0538274, 4.7906083],
          [115.0551819, 4.7904468],
          [115.0531684, 4.7854638],
          [115.0430404, 4.7772527],
          [115.0377358, 4.7747122],
          [115.034114, 4.7731472],
          [115.0330645, 4.7719757],
          [115.0317587, 4.7698564],
          [115.0311916, 4.7680569],
          [115.0314602, 4.766882],
          [115.0326691, 4.7630226],
          [115.033084, 4.7608303],
          [115.0326691, 4.7584344],
          [115.0322288, 4.756382],
          [115.032102, 4.7549839],
          [115.0322363, 4.7532736],
          [115.0318824, 4.7526189],
          [115.0307439, 4.7519574],
          [115.0296508, 4.7517636],
          [115.0281993, 4.7518086],
          [115.0259223, 4.7515313],
          [115.0248443, 4.7510793],
          [115.0236997, 4.7504924],
          [115.022379, 4.7497711],
          [115.0220954, 4.7492431],
          [115.0220283, 4.7481797],
          [115.0224834, 4.7466403],
          [115.0229759, 4.7458075],
          [115.0236699, 4.745391],
          [115.0243639, 4.7454505],
          [115.0250354, 4.7458075],
          [115.0258264, 4.7465139],
          [115.0267666, 4.7473914],
          [115.0276173, 4.7480161],
          [115.02855, 4.7485441],
          [115.0295499, 4.7485887],
          [115.030295, 4.748256],
          [115.0310573, 4.7475402],
          [115.031408, 4.7468263],
          [115.0316244, 4.7461124],
          [115.0315348, 4.7453092],
          [115.0309379, 4.7448705],
          [115.0299529, 4.7447887],
          [115.0286247, 4.7447887],
          [115.0276621, 4.7449225],
          [115.0266398, 4.7449523],
          [115.0258786, 4.7446771],
          [115.0253488, 4.7440748],
          [115.0250267, 4.7432394],
          [115.0252444, 4.7417965],
          [115.0257219, 4.7409887],
          [115.0264084, 4.7398806],
          [115.026606, 4.7390764],
          [115.0265129, 4.738133],
          [115.0260652, 4.7373373],
          [115.0253414, 4.7363111],
          [115.024756, 4.7356618],
          [115.0247417, 4.735179],
          [115.0251324, 4.7347643],
          [115.0258563, 4.7345189],
          [115.0270875, 4.7340578],
          [115.0282665, 4.733448],
          [115.0292067, 4.7326598],
          [115.0311543, 4.7309196],
          [115.0322363, 4.7304585],
          [115.0330162, 4.7305926],
          [115.0336127, 4.7308166],
          [115.0338566, 4.731258],
          [115.0338518, 4.7318949],
          [115.0334903, 4.7332438],
          [115.0328092, 4.7348876],
          [115.032314, 4.7361475],
          [115.0324377, 4.7369878],
          [115.0327897, 4.7374818],
          [115.0341693, 4.7362005],
          [115.0353483, 4.7346537],
          [115.0368407, 4.7333151],
          [115.0387659, 4.7321996],
          [115.0402881, 4.7316939],
          [115.0422133, 4.7315601],
          [115.0433837, 4.7312347],
          [115.043258, 4.7306528],
          [115.0422133, 4.7291655],
          [115.0412731, 4.7278715],
          [115.0402268, 4.7267416],
          [115.0381391, 4.7254026],
          [115.0363183, 4.7246886],
          [115.034617, 4.7242276],
          [115.0334828, 4.7236773],
          [115.03305, 4.7229187],
          [115.0331395, 4.7223089],
          [115.0339007, 4.7215355],
          [115.0387211, 4.7191111],
          [115.0400643, 4.7186947],
          [115.0409597, 4.7186203],
          [115.0417519, 4.7181659],
          [115.0423346, 4.717248],
          [115.0424815, 4.7158903],
          [115.0421902, 4.7147425],
          [115.0418524, 4.7142153],
          [115.0412594, 4.7138832],
          [115.0402652, 4.7138183],
          [115.0390341, 4.7141919],
          [115.0378846, 4.7152106],
          [115.0372586, 4.7155713],
          [115.0363034, 4.7156456],
          [115.0351692, 4.715244],
          [115.0339865, 4.7145536],
          [115.0330076, 4.7135357],
          [115.0325738, 4.712396],
          [115.0329026, 4.7116423],
          [115.0336022, 4.7114513],
          [115.0348856, 4.7117636],
          [115.0361691, 4.7123883],
          [115.0372138, 4.7125371],
          [115.0378854, 4.7123288],
          [115.0384884, 4.7117441],
          [115.0387008, 4.7108645],
          [115.0380983, 4.7103897],
          [115.0367362, 4.7103358],
          [115.0356589, 4.7103631],
          [115.0349155, 4.7101127],
          [115.0347527, 4.7095683],
          [115.0354378, 4.7087146],
          [115.0372168, 4.7074417],
          [115.0389748, 4.7064091],
          [115.0392584, 4.7058439],
          [115.0390047, 4.7052044],
          [115.0377063, 4.704892],
          [115.036587, 4.7047135],
          [115.0353595, 4.7048053],
          [115.034596, 4.7054522],
          [115.0340177, 4.7066682],
          [115.0333293, 4.7074314],
          [115.0327217, 4.7074205],
          [115.0322366, 4.7069767],
          [115.031153, 4.705165],
          [115.0300299, 4.702525],
          [115.0291399, 4.6999242],
          [115.0290601, 4.6991577],
          [115.0294017, 4.6984617],
          [115.0300503, 4.6980947],
          [115.0308263, 4.6982881],
          [115.0313487, 4.6988681],
          [115.0319307, 4.6989574],
          [115.0323947, 4.6986724],
          [115.0328709, 4.6978567],
          [115.0334828, 4.6974551],
          [115.0341935, 4.6970587],
          [115.0349406, 4.6966006],
          [115.0371541, 4.695912],
          [115.0386166, 4.6952389],
          [115.039158, 4.6947251],
          [115.0397147, 4.6939386],
          [115.0397492, 4.6928945],
          [115.0394822, 4.6923306],
          [115.03893, 4.692103],
          [115.0367398, 4.6934084],
          [115.0355689, 4.6940304],
          [115.0344115, 4.6942548],
          [115.0334896, 4.6940391],
          [115.0335923, 4.69295],
          [115.0346664, 4.6922425],
          [115.0354736, 4.6911181],
          [115.0356564, 4.6901202],
          [115.0359176, 4.6883489],
          [115.0360861, 4.6872065],
          [115.0368862, 4.6848825],
          [115.037375, 4.6843766],
          [115.0379107, 4.6841507],
          [115.0388255, 4.6841507],
          [115.0402797, 4.683846],
          [115.040672, 4.6829245],
          [115.0411909, 4.6787996],
          [115.0420686, 4.6778823],
          [115.0431186, 4.6770512],
          [115.043565, 4.6758881],
          [115.0434151, 4.6753587],
          [115.0429098, 4.6748498],
          [115.0416822, 4.6741645],
          [115.0409115, 4.6723304],
          [115.0414106, 4.6715484],
          [115.046973, 4.6676176],
          [115.0469144, 4.6669362],
          [115.0461711, 4.6666204],
          [115.042648, 4.6672212],
          [115.0403692, 4.667328],
          [115.0393014, 4.6670345],
          [115.0389465, 4.666478],
          [115.0389131, 4.6659161],
          [115.0413138, 4.664135],
          [115.0406496, 4.662519],
          [115.0400951, 4.660957],
          [115.0388097, 4.6594942],
          [115.0389827, 4.6590435],
          [115.0399483, 4.6582896],
          [115.0409279, 4.6579999],
          [115.0428717, 4.6585502],
          [115.0438238, 4.6581794],
          [115.0442163, 4.6574036],
          [115.0438751, 4.6569529],
          [115.0414237, 4.6555707],
          [115.0411339, 4.654718],
          [115.0413109, 4.653285],
          [115.0420109, 4.6527532],
          [115.0440988, 4.6539388],
          [115.0475571, 4.6552439],
          [115.0483375, 4.6549211],
          [115.0485099, 4.6540302],
          [115.0479801, 4.6529616],
          [115.0469614, 4.6516635],
          [115.0469305, 4.6510016],
          [115.0473482, 4.650523],
          [115.0478524, 4.650389],
          [115.0490287, 4.6506762],
          [115.0510452, 4.6510399],
          [115.0511797, 4.6507144],
          [115.0512229, 4.6501306],
          [115.0511893, 4.6491879],
          [115.0511076, 4.6478671],
          [115.0505891, 4.6471493],
          [115.0499553, 4.6463596],
          [115.0491967, 4.6460677],
          [115.0484957, 4.6461443],
          [115.0476363, 4.6465463],
          [115.0461431, 4.6463214],
          [115.0448564, 4.6459577],
          [115.0443714, 4.6453355],
          [115.0440786, 4.6441344],
          [115.044165, 4.6435649],
          [115.0444242, 4.6434501],
          [115.0447987, 4.6434692],
          [115.0457686, 4.6436702],
          [115.0467145, 4.6439717],
          [115.047089, 4.6437994],
          [115.0473148, 4.6434607],
          [115.0473578, 4.6425217],
          [115.0472906, 4.6412487],
          [115.0471946, 4.6404495],
          [115.0468201, 4.6400332],
          [115.0464072, 4.640081],
          [115.0460231, 4.6403777],
          [115.0455027, 4.6407808],
          [115.0438752, 4.6411554],
          [115.0431536, 4.6411813],
          [115.0429473, 4.6409048],
          [115.0430516, 4.6402777],
          [115.0433339, 4.6396081],
          [115.0431845, 4.6390819],
          [115.0408236, 4.6362437],
          [115.0407846, 4.6357297],
          [115.0416228, 4.6345564],
          [115.0412902, 4.6341359],
          [115.039351, 4.633062],
          [115.0390216, 4.6325247],
          [115.0390377, 4.6317173],
          [115.0393604, 4.6307132],
          [115.0396921, 4.630525],
          [115.0412726, 4.6304393],
          [115.0416182, 4.63022],
          [115.0413578, 4.6295091],
          [115.0413668, 4.6288589],
          [115.0418942, 4.6284557],
          [115.0435432, 4.6282534],
          [115.0451633, 4.6276459],
          [115.0451048, 4.6271805],
          [115.0447641, 4.6270906],
          [115.0442117, 4.6270762],
          [115.0436591, 4.6267634],
          [115.0429457, 4.6260282],
          [115.0425385, 4.6251849],
          [115.0426587, 4.6243813],
          [115.0436207, 4.6231823],
          [115.0435223, 4.6227345],
          [115.0432154, 4.6223314],
          [115.0422832, 4.6219137],
          [115.0414651, 4.6209112],
          [115.041124, 4.6194577],
          [115.0417119, 4.6186547],
          [115.0428804, 4.6178753],
          [115.0456281, 4.6173854],
          [115.0465434, 4.6171814],
          [115.0466605, 4.6169677],
          [115.0467052, 4.6147486],
          [115.0464674, 4.6144172],
          [115.0455823, 4.6144386],
          [115.0452802, 4.6142552],
          [115.0449788, 4.6137408],
          [115.0451236, 4.6134467],
          [115.045526, 4.6131607],
          [115.0474144, 4.6125212],
          [115.0476664, 4.6123453],
          [115.0475403, 4.6117464],
          [115.0473058, 4.609958],
          [115.0475051, 4.6097245],
          [115.047838, 4.6098803],
          [115.0480696, 4.6101136],
          [115.0484335, 4.6107705],
          [115.0492829, 4.6110615],
          [115.0494114, 4.6106173],
          [115.0490453, 4.60952],
          [115.0489753, 4.6092065],
          [115.0493052, 4.6089098],
          [115.0503514, 4.6085489],
          [115.051487, 4.6076345],
          [115.0513115, 4.6069531],
          [115.050885, 4.6062469],
          [115.0502681, 4.6049868],
          [115.0503915, 4.6046187],
          [115.0511935, 4.604322],
          [115.0516832, 4.6043539],
          [115.0520223, 4.6040118],
          [115.0519727, 4.6033194],
          [115.05208, 4.6028382],
          [115.0534506, 4.601865],
          [115.0546308, 4.6009079],
          [115.0547139, 4.6001031],
          [115.0545637, 4.5997636],
          [115.0541345, 4.5995229],
          [115.0533701, 4.5997342],
          [115.0528042, 4.5995497],
          [115.0523938, 4.5991085],
          [115.0524528, 4.5985738],
          [115.0536196, 4.5980498],
          [115.0541104, 4.5981434],
          [115.0549043, 4.5986915],
          [115.0553791, 4.5987904],
          [115.0559048, 4.5982423],
          [115.0562589, 4.5973654],
          [115.0563527, 4.5966462],
          [115.0559504, 4.596058],
          [115.0558565, 4.5956944],
          [115.0552182, 4.5952666],
          [115.0551001, 4.5949966],
          [115.0552396, 4.594117],
          [115.0554649, 4.593887],
          [115.056004, 4.5938229],
          [115.0561811, 4.593994],
          [115.0564922, 4.5947854],
          [115.0569723, 4.5954939],
          [115.0574632, 4.595435],
          [115.0578279, 4.595288],
          [115.0583563, 4.5953816],
          [115.0589813, 4.59582],
          [115.0591422, 4.5960767],
          [115.0593354, 4.5960954],
          [115.0596089, 4.5958682],
          [115.0597618, 4.5955767],
          [115.060022, 4.595419],
          [115.0598879, 4.5946517],
          [115.0600113, 4.5945127],
          [115.060714, 4.5941785],
          [115.0618298, 4.5942293],
          [115.0627632, 4.5931197],
          [115.063584, 4.5904782],
          [115.0634257, 4.5902349],
          [115.0625433, 4.5905584],
          [115.0619693, 4.5903498],
          [115.0617279, 4.5899327],
          [115.0617949, 4.5896948],
          [115.0620645, 4.5894515],
          [115.0629939, 4.5877885],
          [115.0628211, 4.5862131],
          [115.0631564, 4.586042],
          [115.0634112, 4.5857747],
          [115.0634085, 4.5855153],
          [115.063092, 4.5848817],
          [115.0631591, 4.5843416],
          [115.062915, 4.5839138],
          [115.0630196, 4.5834647],
          [115.0633388, 4.5832347],
          [115.0637384, 4.5831919],
          [115.0639691, 4.583347],
          [115.0642105, 4.5831706],
          [115.0635614, 4.5815477],
          [115.0635614, 4.5808739],
          [115.0637223, 4.5807188],
          [115.0659057, 4.5806306],
          [115.0663965, 4.5804274],
          [115.0678208, 4.5795424],
          [115.0681104, 4.5791414],
          [115.068848, 4.5789435],
          [115.0703072, 4.5780345],
          [115.0713063, 4.5762672],
          [115.0712097, 4.5760881],
          [115.0700403, 4.5758929],
          [115.0698203, 4.5754491],
          [115.0699947, 4.5747913],
          [115.0698684, 4.5739197],
          [115.068042, 4.5726791],
          [115.0667385, 4.5733583],
          [115.0663254, 4.5730588],
          [115.066186, 4.5723636],
          [115.0665507, 4.5711979],
          [115.0669638, 4.5711605],
          [115.0691793, 4.5724225],
          [115.0693778, 4.5723262],
          [115.0703809, 4.5703825],
          [115.0699813, 4.5699199],
          [115.0696219, 4.5697541],
          [115.069469, 4.5693584],
          [115.0697318, 4.5685162],
          [115.07033, 4.5683291],
          [115.0712178, 4.5676847],
          [115.0722987, 4.5672703],
          [115.0725911, 4.5666928],
          [115.0720546, 4.5658693],
          [115.0721324, 4.5647009],
          [115.0729907, 4.5646955],
          [115.0749648, 4.5642196],
          [115.0751123, 4.5639442],
          [115.074938, 4.5636929],
          [115.0733823, 4.5624336],
          [115.0731436, 4.5618453],
          [115.073562, 4.5605646],
          [115.073393, 4.5602973],
          [115.0720627, 4.5595112],
          [115.0715826, 4.5588748],
          [115.0721297, 4.5581529],
          [115.0733743, 4.5569738],
          [115.0731329, 4.5561717],
          [115.0726742, 4.5558642],
          [115.0707967, 4.5566556],
          [115.0700188, 4.5571663],
          [115.0692705, 4.5564123],
          [115.0690157, 4.5552225],
          [115.0693912, 4.5547519],
          [115.0700269, 4.5545287],
          [115.0708771, 4.5540902],
          [115.0726045, 4.5539832],
          [115.0727708, 4.5538175],
          [115.0735218, 4.5514619],
          [115.074022, 4.5497093],
          [115.0738504, 4.5484472],
          [115.074301, 4.5475007],
          [115.0744673, 4.5468403],
          [115.0727533, 4.5468296],
          [115.0728472, 4.5457975],
          [115.0737243, 4.5446398],
          [115.0747838, 4.5444099],
          [115.0750413, 4.5442093],
          [115.0755831, 4.5428965],
          [115.0759264, 4.5426345],
          [115.0761893, 4.5427949],
          [115.0765648, 4.5444687],
          [115.0774767, 4.5457093],
          [115.0777905, 4.5458109],
          [115.0779193, 4.5455328],
          [115.0771656, 4.5434901],
          [115.0769993, 4.5424206],
          [115.077407, 4.5414633],
          [115.0786944, 4.5419607],
          [115.0798907, 4.5407682],
          [115.0815, 4.5407735],
          [115.0808187, 4.5389125],
          [115.0821706, 4.5363617],
          [115.0831415, 4.5357521],
          [115.0828519, 4.5346612],
          [115.0844826, 4.5333724],
          [115.0835814, 4.5323403],
          [115.0838872, 4.5319393],
          [115.0855394, 4.5317628],
          [115.0836887, 4.5305382],
          [115.0836565, 4.5296825],
          [115.0849279, 4.5291959],
          [115.0849869, 4.5286879],
          [115.0834634, 4.5282387],
          [115.0834902, 4.5277146],
          [115.0852873, 4.5264472],
          [115.0836672, 4.5232707],
          [115.0836243, 4.521292],
          [115.0827821, 4.5210888],
          [115.0821008, 4.5214845],
          [115.081559, 4.5226931],
          [115.0807275, 4.5228696],
          [115.0811782, 4.5216717],
          [115.0810333, 4.5210995],
          [115.0799819, 4.5203294],
          [115.0814571, 4.5191048],
          [115.081559, 4.518201],
          [115.0811031, 4.5180085],
          [115.080572, 4.5183882],
          [115.0803306, 4.519185],
          [115.0799765, 4.5192224],
          [115.0796171, 4.5188267],
          [115.0798102, 4.5182117],
          [115.0810816, 4.5171956],
          [115.0814035, 4.515479],
          [115.0825139, 4.5152758],
          [115.0816019, 4.5145538],
          [115.0825568, 4.5138212],
          [115.0816824, 4.5125912],
          [115.081779, 4.5120618],
          [115.0827017, 4.5115591],
          [115.0828572, 4.5112115],
          [115.0808992, 4.5106767],
          [115.0797888, 4.5099547],
          [115.0793382, 4.5093451],
          [115.0793435, 4.5088638],
          [115.0795849, 4.5086552],
          [115.0803628, 4.5096446],
          [115.080749, 4.5096392],
          [115.0811674, 4.5091044],
          [115.0809475, 4.5074894],
          [115.080926, 4.5066926],
          [115.0790109, 4.5073022],
          [115.0788661, 4.5068958],
          [115.079601, 4.5061578],
          [115.0797834, 4.5049278],
          [115.0801482, 4.5049385],
          [115.0805398, 4.5059439],
          [115.0817414, 4.5064626],
          [115.0823369, 4.5077247],
          [115.0835761, 4.5065321],
          [115.0845256, 4.5063717],
          [115.0836833, 4.5042219],
          [115.0853249, 4.5038422],
          [115.0859579, 4.5043395],
          [115.0862904, 4.5043288],
          [115.0863816, 4.5040614],
          [115.0863173, 4.5030133],
          [115.0871273, 4.5011469],
          [115.0874921, 4.5011094],
          [115.0880768, 4.5021576],
          [115.0887903, 4.5025426],
          [115.0900241, 4.5022218],
          [115.0899168, 4.5014036],
          [115.0880393, 4.5002377],
          [115.0877013, 4.4995264],
          [115.0898739, 4.4981895],
          [115.0894823, 4.4973712],
          [115.0895949, 4.4969434],
          [115.0913974, 4.4962749],
          [115.0922342, 4.4954139],
          [115.092143, 4.4948684],
          [115.0888439, 4.4930448],
          [115.0887044, 4.492403],
          [115.0890799, 4.4923389],
          [115.0901636, 4.4929057],
          [115.0908287, 4.4928523],
          [115.0911184, 4.4919752],
          [115.092202, 4.4919859],
          [115.0923952, 4.4935796],
          [115.0931998, 4.4929378],
          [115.0942512, 4.4928202],
          [115.0949164, 4.4917292],
          [115.0950666, 4.4902959],
          [115.0937792, 4.4891194],
          [115.0938757, 4.4887343],
          [115.0944014, 4.4887664],
          [115.0946375, 4.4890552],
          [115.0950774, 4.4889162],
          [115.0952168, 4.4884883],
          [115.093865, 4.4864133],
          [115.093747, 4.4840388],
          [115.091907, 4.48398],
          [115.0920948, 4.4820119],
          [115.0924703, 4.4812418],
          [115.0911882, 4.4803754],
          [115.0913008, 4.4787336],
          [115.0916334, 4.4784983],
          [115.0921001, 4.479723],
          [115.0937363, 4.4761612],
          [115.0940259, 4.4749739],
          [115.0933393, 4.4743642],
          [115.0942995, 4.4728079],
          [115.0945517, 4.4728079],
          [115.0945195, 4.473546],
          [115.0952705, 4.475145],
          [115.0957801, 4.4755087],
          [115.0964185, 4.4753696],
          [115.0953831, 4.4745835],
          [115.095351, 4.4743107],
          [115.0966223, 4.4740808],
          [115.0975182, 4.4731342],
          [115.0963541, 4.4728988],
          [115.0963112, 4.4724603],
          [115.097883, 4.4717223],
          [115.0970622, 4.4706954],
          [115.0966706, 4.4693049],
          [115.0970568, 4.4692514],
          [115.0985321, 4.4702408],
          [115.0988593, 4.4701071],
          [115.0987949, 4.4684974],
          [115.0977113, 4.4683904],
          [115.096161, 4.4670373],
          [115.0960805, 4.4664169],
          [115.0963809, 4.4661602],
          [115.0971158, 4.4664383],
          [115.0975665, 4.4651227],
          [115.0965097, 4.4643044],
          [115.0961717, 4.4622026],
          [115.0981404, 4.460165],
          [115.0980439, 4.4599082],
          [115.0965204, 4.4598334],
          [115.0962414, 4.4594964],
          [115.0964721, 4.4589884],
          [115.0971051, 4.4587691],
          [115.0974806, 4.4591328],
          [115.0986125, 4.4585498],
          [115.0960912, 4.457047],
          [115.0968154, 4.4557634],
          [115.0975879, 4.4563143],
          [115.0980546, 4.4559238],
          [115.0979151, 4.4549077],
          [115.0961288, 4.4549719],
          [115.0960483, 4.4543033],
          [115.0975665, 4.453961],
          [115.0968369, 4.4529449],
          [115.0958337, 4.4532604],
          [115.0955548, 4.4542605],
          [115.0927331, 4.454298],
          [115.0920518, 4.4532925],
          [115.0931462, 4.453469],
          [115.0931998, 4.450843],
          [115.094101, 4.4510516],
          [115.0942137, 4.4500034],
          [115.0932749, 4.4496932],
          [115.0939455, 4.4486075],
          [115.0950881, 4.448554],
          [115.0944658, 4.4476287],
          [115.0934037, 4.4471313],
          [115.0923415, 4.4483026],
          [115.0921162, 4.448201],
          [115.0926741, 4.4467677],
          [115.0921538, 4.4462221],
          [115.0929262, 4.4452808],
          [115.0940581, 4.4466767],
          [115.0940152, 4.4444732],
          [115.0952007, 4.4434464],
          [115.0948682, 4.4429276],
          [115.0925775, 4.4438956],
          [115.0923361, 4.4409808],
          [115.0919441, 4.4348026],
          [115.0955581, 4.4270818],
          [115.1001577, 4.4203466],
          [115.102922, 4.4181668],
          [115.1042363, 4.4171078],
          [115.1039359, 4.4152251],
          [115.1043061, 4.4146059],
          [115.1020261, 4.4106789],
          [115.1010168, 4.4065649],
          [115.0991916, 4.4026164],
          [115.0998918, 4.4024186],
          [115.0957088, 4.3988867],
          [115.0926947, 4.3937252],
          [115.0910303, 4.3915853],
          [115.0905548, 4.3896831],
          [115.0901371, 4.3866706],
          [115.0906299, 4.3853564],
          [115.1043456, 4.3804099],
          [115.1067233, 4.3763678],
          [115.112314, 4.3723788],
          [115.1142852, 4.371886],
          [115.1192134, 4.3710646],
          [115.121349, 4.3715574],
          [115.1333539, 4.3737523],
          [115.1450047, 4.381361],
          [115.1554667, 4.3830254],
          [115.1580822, 4.3744656],
          [115.1595088, 4.3749412],
          [115.1640265, 4.3749412],
          [115.1709595, 4.3707361],
          [115.1727665, 4.370079],
          [115.1780233, 4.3694219],
          [115.1804874, 4.3695861],
          [115.1873868, 4.3709003],
          [115.1893581, 4.3707361],
          [115.1906571, 4.3661436],
          [115.1970769, 4.3654303],
          [115.1996924, 4.3635281],
          [115.2180009, 4.3585349],
          [115.2187142, 4.3628148],
          [115.2287837, 4.3641651],
          [115.2381473, 4.3661364],
          [115.2392972, 4.3661364],
          [115.2448825, 4.3621938],
          [115.2453753, 4.3612082],
          [115.2455395, 4.3579227],
          [115.2447182, 4.3502019],
          [115.245211, 4.3485592],
          [115.245967, 4.3451991],
          [115.2446314, 4.3428419],
          [115.2458203, 4.3411775],
          [115.2484771, 4.3407402],
          [115.248856, 4.3406639],
          [115.2491583, 4.3404227],
          [115.2533597, 4.3362146],
          [115.2556935, 4.3359553],
          [115.2564714, 4.3362146],
          [115.2577679, 4.3364739],
          [115.2598423, 4.335696],
          [115.2650283, 4.3380297],
          [115.2663248, 4.3395855],
          [115.2678806, 4.3421785],
          [115.2691771, 4.3426971],
          [115.2720294, 4.3429564],
          [115.2730666, 4.3439936],
          [115.2741038, 4.3445122],
          [115.275141, 4.3437343],
          [115.275141, 4.3426971],
          [115.2774747, 4.3416599],
          [115.2792898, 4.338289],
          [115.2813642, 4.3369925],
          [115.2888839, 4.3372518],
          [115.2935513, 4.3380297],
          [115.295885, 4.3380297],
          [115.295885, 4.3369925],
          [115.2951071, 4.333103],
          [115.2951071, 4.3289542],
          [115.2938106, 4.3255833],
          [115.2943292, 4.3240275],
          [115.3065163, 4.3133962],
          [115.3091093, 4.3108032],
          [115.3098872, 4.3076916],
          [115.3207778, 4.2991347],
          [115.3225929, 4.2962824],
          [115.3306312, 4.2983568],
          [115.33478, 4.3012091],
          [115.3365951, 4.3032835],
          [115.337373, 4.3053579],
          [115.3391881, 4.3061358],
          [115.3438555, 4.3089881],
          [115.3441148, 4.3128776],
          [115.3467078, 4.3162485],
          [115.3513752, 4.317545],
          [115.3526717, 4.3193601],
          [115.352931, 4.3211752],
          [115.3539682, 4.3229903],
          [115.3560426, 4.3235089],
          [115.3565612, 4.3245461],
          [115.3560426, 4.3261019],
          [115.3563019, 4.3271391],
          [115.3583763, 4.3294728],
          [115.3599321, 4.3297321],
          [115.3599321, 4.3323251],
          [115.3614879, 4.3346588],
          [115.3622658, 4.3369925],
          [115.363303, 4.3419192],
          [115.3635623, 4.3447715],
          [115.3630437, 4.3463273],
          [115.3563019, 4.3494389],
          [115.3516345, 4.3507354],
          [115.3495601, 4.3517726],
          [115.3480043, 4.3551435],
          [115.3459299, 4.3585144],
          [115.3459299, 4.3605888],
          [115.3433369, 4.361626],
          [115.3365951, 4.3753689],
          [115.3365951, 4.3784805],
          [115.3358172, 4.3795177],
          [115.3350393, 4.3800363],
          [115.3340021, 4.3810735],
          [115.3337428, 4.3826293],
          [115.3334835, 4.3872968],
          [115.332187, 4.3875561],
          [115.329594, 4.3862596],
          [115.3277789, 4.3862596],
          [115.3267417, 4.3870375],
          [115.3264824, 4.3885933],
          [115.3238894, 4.3917049],
          [115.3189627, 4.3927421],
          [115.3181848, 4.3940386],
          [115.3137767, 4.3940386],
          [115.3083314, 4.3958537],
          [115.3041826, 4.3992246],
          [115.3018489, 4.4007804],
          [115.2992559, 4.406485],
          [115.2992559, 4.4088187],
          [115.2997745, 4.4108931],
          [115.2992559, 4.411671],
          [115.2925141, 4.4184128],
          [115.2914769, 4.4204872],
          [115.2865502, 4.4233395],
          [115.28292, 4.4287848],
          [115.2813642, 4.4303406],
          [115.2811049, 4.432415],
          [115.2792898, 4.4329336],
          [115.2779933, 4.435008],
          [115.2764375, 4.4404533],
          [115.2741038, 4.44538],
          [115.2741038, 4.4464172],
          [115.2722887, 4.4477137],
          [115.272548, 4.4508253],
          [115.2735852, 4.453159],
          [115.2764375, 4.4567892],
          [115.2766968, 4.4588636],
          [115.2761782, 4.4627531],
          [115.2764375, 4.4643089],
          [115.2779933, 4.4671612],
          [115.2787712, 4.4715693],
          [115.2795491, 4.4720879],
          [115.2800677, 4.4733844],
          [115.2790305, 4.4746809],
          [115.2772154, 4.4751995],
          [115.275141, 4.4783111],
          [115.2733259, 4.4837564],
          [115.2735852, 4.4866087],
          [115.2733259, 4.4873866],
          [115.2743631, 4.4925726],
          [115.2759189, 4.4985366],
          [115.2740191, 4.5031071],
          [115.2710019, 4.513818],
          [115.2688663, 4.5212103],
          [115.2678807, 4.5272884],
          [115.2700163, 4.5307382],
          [115.271659, 4.5327095],
          [115.2718233, 4.5371448],
          [115.269852, 4.5424016],
          [115.269852, 4.5460156],
          [115.2711662, 4.5488082],
          [115.2736303, 4.5617858],
          [115.2772443, 4.5713137],
          [115.2823368, 4.5785417],
          [115.2854579, 4.5916835],
          [115.2871007, 4.5979259],
          [115.2892362, 4.6038398],
          [115.2892362, 4.6051539],
          [115.2880863, 4.6171459],
          [115.2875935, 4.6182958],
          [115.286115, 4.6197743],
          [115.286115, 4.6205956],
          [115.2864436, 4.6240454],
          [115.2862793, 4.6256881],
          [115.2846366, 4.6332447],
          [115.2808583, 4.6408012],
          [115.2719875, 4.6477007],
          [115.2616383, 4.6531217],
          [115.25901, 4.6549287],
          [115.2583529, 4.6575571],
          [115.2588457, 4.6603498],
          [115.2603241, 4.665935],
          [115.2618026, 4.6682349],
          [115.2655809, 4.6756272],
          [115.2659094, 4.6792412],
          [115.2657452, 4.6810482],
          [115.2616383, 4.6909046],
          [115.2611455, 4.6915617],
          [115.2532604, 4.6913974],
          [115.2516177, 4.6918902],
          [115.2493178, 4.6953399],
          [115.2475108, 4.7001039],
          [115.2478394, 4.7017466],
          [115.2557245, 4.7277018],
          [115.2553959, 4.7296731],
          [115.2547389, 4.7313158],
          [115.2445539, 4.7403508],
          [115.2412684, 4.7442934],
          [115.2402828, 4.7464289],
          [115.2368331, 4.7562853],
          [115.2320691, 4.7623634],
          [115.2317406, 4.7636776],
          [115.2340881, 4.7892731],
          [115.2361695, 4.7914756],
          [115.2366167, 4.7917278],
          [115.2373067, 4.792117],
          [115.2374998, 4.7958376],
          [115.2348618, 4.8034317],
          [115.2282407, 4.8111686],
          [115.1916579, 4.839079],
          [115.1903437, 4.8405575],
          [115.1845942, 4.8490997],
          [115.1836085, 4.8499211],
          [115.1827872, 4.8507424],
          [115.18328, 4.851071],
          [115.1836085, 4.8513995],
          [115.1839371, 4.852878],
          [115.1829515, 4.8561634],
          [115.1824586, 4.8568205],
          [115.181473, 4.8578062],
          [115.181473, 4.8594489],
          [115.1822944, 4.8604345],
          [115.1839371, 4.8625701],
          [115.1847585, 4.8648699],
          [115.1845942, 4.8660198],
          [115.1836085, 4.8668412],
          [115.1795017, 4.8681554],
          [115.177859, 4.8681554],
          [115.1763805, 4.8676626],
          [115.1737521, 4.8661841],
          [115.1729308, 4.8661841],
          [115.1716166, 4.8666769],
          [115.1707952, 4.8674983],
          [115.1698333, 4.8721777],
          [115.1686087, 4.8729305],
          [115.1676313, 4.8731451],
          [115.166445, 4.8727354],
          [115.165749, 4.8722232],
          [115.1644283, 4.8714824],
          [115.1624273, 4.8702944],
          [115.1598871, 4.8688017],
          [115.1591055, 4.8683993],
          [115.1567346, 4.8689704],
          [115.1544419, 4.8700088],
          [115.1525139, 4.871177],
          [115.1503829, 4.8720382],
          [115.149559, 4.8719647],
          [115.1494404, 4.8727452],
          [115.1494404, 4.8734461],
          [115.1497922, 4.874835],
          [115.1500918, 4.8763017],
          [115.1504418, 4.8775092],
          [115.150733, 4.8782264],
          [115.151642, 4.8788068],
          [115.1529447, 4.8793649],
          [115.153101, 4.8800269],
          [115.1533225, 4.88187],
          [115.1532704, 4.8830771],
          [115.152593, 4.884414],
          [115.1520198, 4.8853875],
          [115.1525018, 4.8862181],
          [115.1535439, 4.8869061],
          [115.1546773, 4.8873993],
          [115.1560711, 4.8876719],
          [115.1569179, 4.8872565],
          [115.1580773, 4.8863609],
          [115.1592236, 4.8859715],
          [115.159393, 4.886322],
          [115.1598359, 4.8872305],
          [115.1598099, 4.8880353],
          [115.1594581, 4.8893202],
          [115.1587026, 4.8909686],
          [115.1586497, 4.8931768],
          [115.1588857, 4.8942244],
          [115.1585639, 4.8955286],
          [115.1575339, 4.8971962],
          [115.1568074, 4.8979467],
          [115.1558434, 4.8988552],
          [115.1547622, 4.8995172],
          [115.1538113, 4.9000493],
          [115.1537982, 4.9006204],
          [115.1544105, 4.9015419],
          [115.1553484, 4.9028528],
          [115.1559406, 4.904172],
          [115.1562333, 4.9055115],
          [115.1559862, 4.9078877],
          [115.1555694, 4.909562],
          [115.1550391, 4.9110148],
          [115.1568542, 4.9120542],
          [115.159869, 4.9131926],
          [115.1605108, 4.9135581],
          [115.1613863, 4.9140568],
          [115.1668947, 4.9150372],
          [115.1699322, 4.9177155],
          [115.1726621, 4.9195146],
          [115.1750671, 4.9188515],
          [115.1753987, 4.91929],
          [115.1758751, 4.9194195],
          [115.1754934, 4.9223038],
          [115.178961, 4.923635],
          [115.1810525, 4.925736],
          [115.1832875, 4.9260438],
          [115.1852642, 4.925047],
          [115.1874475, 4.9252715],
          [115.1883089, 4.924784],
          [115.18848, 4.9248586],
          [115.1886404, 4.9249377],
          [115.188367, 4.9255238],
          [115.188338, 4.9263191],
          [115.1887832, 4.9266878],
          [115.1904033, 4.926426],
          [115.1937277, 4.9285435],
          [115.1951884, 4.9312307],
          [115.196042832036696, 4.934534132480621],
          [115.1965841, 4.9366268],
          [115.1969821, 4.9397524],
          [115.1967441, 4.942048],
          [115.1961674, 4.9441374],
          [115.192912, 4.9520559],
          [115.1915636, 4.9538652],
          [115.1898941, 4.9566373],
          [115.1894388, 4.9590566],
          [115.1897864, 4.9608924],
          [115.190547, 4.9618483],
          [115.1912796, 4.9625647],
          [115.192361, 4.9636992],
          [115.1931177, 4.9647991],
          [115.1943358, 4.9655422],
          [115.1969997, 4.9662611],
          [115.1994501, 4.9651219],
          [115.2009474, 4.9632406],
          [115.2010758, 4.9626515],
          [115.2015066, 4.9621416],
          [115.2018458, 4.9621248],
          [115.2020717, 4.9621281],
          [115.2022614, 4.9621309],
          [115.2022003, 4.9627748],
          [115.2022896, 4.9632319],
          [115.2039637, 4.9640644],
          [115.2057869, 4.9643812],
          [115.2080788, 4.9635727],
          [115.2087204, 4.962808],
          [115.208989, 4.961639],
          [115.2140884, 4.9620869],
          [115.2154533, 4.961633],
          [115.2174191, 4.9624141],
          [115.2195872, 4.9613799],
          [115.2201213, 4.9617199],
          [115.2207239, 4.9618263],
          [115.2219827, 4.9656278],
          [115.2229047, 4.9656465],
          [115.2233621, 4.9652404],
          [115.2236398, 4.9638751],
          [115.2237377, 4.9634088],
          [115.2242938, 4.9627802],
          [115.2246169, 4.9625506],
          [115.2250422, 4.9623077],
          [115.2250494, 4.962008],
          [115.2255944, 4.9617359],
          [115.225691, 4.9615169],
          [115.2263851, 4.9611977],
          [115.2275678, 4.9608156],
          [115.2274254, 4.9605842],
          [115.2275927, 4.9602066],
          [115.2276407, 4.9600292],
          [115.2276781, 4.9598909],
          [115.2281791, 4.9600984],
          [115.2282058, 4.9597783],
          [115.2288875, 4.9593803],
          [115.2288492, 4.9590629],
          [115.229101, 4.958734],
          [115.2289774, 4.95832],
          [115.2292265, 4.9580185],
          [115.2304597, 4.9569009],
          [115.2304884, 4.9564794],
          [115.2322005, 4.9544811],
          [115.2344573, 4.9514668],
          [115.2344965, 4.9510519],
          [115.2353668, 4.9497805],
          [115.2357405, 4.9494383],
          [115.2359256, 4.9490012],
          [115.2357441, 4.948573],
          [115.2357939, 4.9483124],
          [115.2357494, 4.9478983],
          [115.235648, 4.9477981],
          [115.2356409, 4.9476226],
          [115.2350197, 4.9465303],
          [115.2345036, 4.946025],
          [115.2343682, 4.9459412],
          [115.2342687, 4.9458796],
          [115.2342811, 4.9450214],
          [115.2348595, 4.9442359],
          [115.2345926, 4.9438529],
          [115.2338807, 4.9436082],
          [115.2339557, 4.9431183],
          [115.2340355, 4.9425975],
          [115.2356322, 4.9421442],
          [115.2361686, 4.9419919],
          [115.2363986, 4.9420234],
          [115.2366402, 4.9419658],
          [115.2366233, 4.9418146],
          [115.2367297, 4.9417197],
          [115.2370861, 4.9420478],
          [115.2375622, 4.9421085],
          [115.2378175, 4.9423563],
          [115.2373245, 4.9427455],
          [115.2378247, 4.9431888],
          [115.2380703, 4.9439486],
          [115.2389068, 4.9444252],
          [115.239156, 4.9439941],
          [115.2388817, 4.9421693],
          [115.2380474, 4.9411421],
          [115.2375016, 4.9397959],
          [115.2369615, 4.9388406],
          [115.2367895, 4.9380584],
          [115.2368984, 4.9365849],
          [115.2372535, 4.9351964],
          [115.2384068, 4.9338977],
          [115.2381178, 4.9336105],
          [115.2400738, 4.9311692],
          [115.2401474, 4.9295679],
          [115.2414794, 4.9280849],
          [115.2418892, 4.9291173],
          [115.2425983, 4.9288728],
          [115.2429138, 4.9280591],
          [115.2428226, 4.9267122],
          [115.2429678, 4.9260888],
          [115.2431034, 4.9255067],
          [115.2445142, 4.9244805],
          [115.2450381, 4.9247882],
          [115.244609, 4.9238549],
          [115.2439543, 4.9232154],
          [115.2430942, 4.9211881],
          [115.2440441, 4.9213416],
          [115.24441, 4.9205806],
          [115.2451225, 4.9199635],
          [115.2451963, 4.9207405],
          [115.2456712, 4.9205713],
          [115.2456873, 4.919994],
          [115.2452867, 4.9192374],
          [115.2446751, 4.91889],
          [115.2446124, 4.918545],
          [115.2443783, 4.9172576],
          [115.245394, 4.9171423],
          [115.2458178, 4.9167735],
          [115.2466385, 4.9148227],
          [115.2473144, 4.9144646],
          [115.248423, 4.9146012],
          [115.2501683, 4.9153037],
          [115.250855, 4.9161589],
          [115.2510201, 4.9162782],
          [115.2513431, 4.9165116],
          [115.253505, 4.9176126],
          [115.2553378, 4.9178669],
          [115.2579253, 4.9175912],
          [115.2638368, 4.9160092],
          [115.2657305, 4.9151059],
          [115.2709929, 4.9115853],
          [115.2713095, 4.9105843],
          [115.2715912, 4.910379],
          [115.2718888, 4.9101621],
          [115.2732943, 4.9115731],
          [115.2739523, 4.9115975],
          [115.2749787, 4.9117922],
          [115.2763896, 4.9128238],
          [115.2767188, 4.9129042],
          [115.2769368, 4.9129574],
          [115.2799672, 4.9128501],
          [115.2848213, 4.9123961],
          [115.2882914, 4.911202],
          [115.2918212, 4.9093635],
          [115.292384, 4.9070204],
          [115.2919794, 4.9048981],
          [115.2964264, 4.9085392],
          [115.297191, 4.9084067],
          [115.2982049, 4.9085297],
          [115.2991736, 4.9069712],
          [115.3010458, 4.9058007],
          [115.3022849, 4.9054961],
          [115.3089422, 4.8992373],
          [115.3112006, 4.8973399],
          [115.3115278, 4.8969123],
          [115.3113615, 4.8963404],
          [115.3116147, 4.8962398],
          [115.3119236, 4.8961974],
          [115.3125524, 4.8956509],
          [115.3134697, 4.8940528],
          [115.3148069, 4.8939298],
          [115.3161841, 4.8932992],
          [115.3177448, 4.8927107],
          [115.320038, 4.8919447],
          [115.3210443, 4.8907123],
          [115.3228414, 4.8900602],
          [115.3230291, 4.8897235],
          [115.3232962, 4.8896924],
          [115.3236758, 4.8897776],
          [115.3242093, 4.8909902],
          [115.3250092, 4.8914559],
          [115.3315165, 4.8918094],
          [115.342272, 4.8926179],
          [115.3456724, 4.8932966],
          [115.346359, 4.8932431],
          [115.3466931, 4.8933279],
          [115.347061, 4.8934329],
          [115.3559664, 4.8966341],
          [115.358431816101074, 4.897234960559713],
          [115.3618302, 4.8980632],
          [115.36422, 4.8987666],
          [115.365625, 4.899535],
          [115.3694247, 4.9012441],
          [115.3713789, 4.9026656],
          [115.376379, 4.9052307],
          [115.3872006, 4.9152961],
          [115.3906412, 4.9229053],
          [115.3939006, 4.9268285],
          [115.3945825, 4.9282213],
          [115.3948259, 4.9298395],
          [115.3952744, 4.9300164],
          [115.396033, 4.9313],
          [115.395157, 4.9332702],
          [115.3932652, 4.9348417],
          [115.3920898, 4.9348702],
          [115.3918534, 4.9345324],
          [115.391852792260153, 4.934534132480621],
          [115.3916518, 4.9351071],
          [115.3911303, 4.9352165],
          [115.3910016, 4.9350823],
          [115.3909008, 4.9351453],
          [115.3908963, 4.935242],
          [115.3901423, 4.9353695],
          [115.3892121, 4.9361222],
          [115.3889352, 4.9369349],
          [115.3885379, 4.9374297],
          [115.3889352, 4.9380865],
          [115.3887877, 4.9390072],
          [115.3888931, 4.9398739],
          [115.3886643, 4.9405816],
          [115.3882248, 4.9406611],
          [115.388276, 4.9411544],
          [115.3886478, 4.9417662],
          [115.3889924, 4.942051],
          [115.391253, 4.9440798],
          [115.3921921, 4.9444681],
          [115.3929055, 4.944849],
          [115.3945099, 4.944873],
          [115.3949283, 4.9445791],
          [115.3953798, 4.9437709],
          [115.3955634, 4.9429582],
          [115.3960525, 4.9413523],
          [115.39665, 4.9399488],
          [115.3974748, 4.9392411],
          [115.3979775, 4.9396609],
          [115.3982303, 4.9402127],
          [115.399618, 4.9405606],
          [115.4004608, 4.9400148],
          [115.4007855, 4.9400847],
          [115.4011426, 4.9401677],
          [115.4016528, 4.9402997],
          [115.4031247, 4.9400778],
          [115.4036846, 4.9402277],
          [115.4046629, 4.9414483],
          [115.404416, 4.942054],
          [115.4044124, 4.9421965],
          [115.4044522, 4.9423149],
          [115.4039886, 4.9424169],
          [115.4037237, 4.9431756],
          [115.4039405, 4.9437964],
          [115.4042776, 4.9439883],
          [115.4046659, 4.944801],
          [115.4057164, 4.9452029],
          [115.406204, 4.9460545],
          [115.4059753, 4.9467803],
          [115.4061559, 4.9480038],
          [115.4069024, 4.9483517],
          [115.4076218, 4.9493113],
          [115.40888, 4.952637],
          [115.4107818, 4.9522066],
          [115.4110714, 4.9534579],
          [115.4108459, 4.955608],
          [115.4113867, 4.9573572],
          [115.411702, 4.9576764],
          [115.4115584, 4.9596171],
          [115.4118993, 4.9622778],
          [115.4117686, 4.9625179],
          [115.4112842, 4.9626021],
          [115.4112586, 4.9627554],
          [115.4113431, 4.9629188],
          [115.4115969, 4.9633044],
          [115.4117609, 4.9641802],
          [115.4117199, 4.9645888],
          [115.4114226, 4.9651787],
          [115.4117609, 4.9661158],
          [115.4117305, 4.9671333],
          [115.4117118, 4.9676172],
          [115.4118612, 4.9681196],
          [115.4120854, 4.9685663],
          [115.4123376, 4.9689199],
          [115.4163109, 4.9722166],
          [115.4187551, 4.9747425],
          [115.4190073, 4.9754868],
          [115.4193715, 4.9760824],
          [115.4198946, 4.9764825],
          [115.4205671, 4.976529],
          [115.4210527, 4.9763057],
          [115.4211181, 4.9759707],
          [115.4212676, 4.9757288],
          [115.4217371, 4.9756276],
          [115.4221829, 4.9756916],
          [115.4224351, 4.9754961],
          [115.4263393, 4.9751538],
          [115.4328625, 4.9744698],
          [115.4345415, 4.976635],
          [115.4404156, 4.9785741],
          [115.4443638, 4.9777191],
          [115.447617, 4.9794313],
        ],
      ],
    ],
  },
};
