export const negerisembilan = {
  type: "Feature",
  properties: {
    gid: 7,
    id: 2939674,
    country: "MYS",
    name: "Negeri Sembilan",
    enname: null,
    locname: "Negeri Sembilan",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q213893",
    wikimedia: "ms:Negeri Sembilan",
    timestamp: "2019-10-31 23:10:02",
    note: null,
    path: "0,2108121,2939674",
    rpath: "2939674,2108121,0",
    iso3166_2: "MY-05",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [101.7935123, 2.5161706],
          [101.793979, 2.51646],
          [101.7941882, 2.5167494],
          [101.7944135, 2.5168941],
          [101.7945798, 2.5167816],
          [101.7946281, 2.5164654],
          [101.794874, 2.5159436],
          [101.7950894, 2.5159026],
          [101.7951591, 2.516176],
          [101.7955937, 2.5163421],
          [101.7958664, 2.5162598],
          [101.7958128, 2.5158525],
          [101.7953952, 2.5151952],
          [101.7955982, 2.5150486],
          [101.7956527, 2.5148951],
          [101.7953254, 2.5150666],
          [101.7949813, 2.5148986],
          [101.7948579, 2.5144163],
          [101.7941721, 2.5142252],
          [101.7935337, 2.5151684],
          [101.7935123, 2.5161706],
        ],
      ],
      [
        [
          [101.7124503, 2.5953285],
          [101.7125902, 2.596211],
          [101.7126165, 2.5968575],
          [101.7122841, 2.5983341],
          [101.7111908, 2.5994962],
          [101.7077186, 2.6014358],
          [101.7050684, 2.6024668],
          [101.7037215, 2.6030872],
          [101.7029081, 2.6039609],
          [101.7024271, 2.6050531],
          [101.7024708, 2.6060054],
          [101.7027594, 2.6070539],
          [101.7037303, 2.6088625],
          [101.7056282, 2.6110031],
          [101.70729, 2.612401],
          [101.7115319, 2.6155027],
          [101.7134211, 2.6167264],
          [101.7153628, 2.6179841],
          [101.7182228, 2.6193383],
          [101.7211966, 2.6202645],
          [101.7228584, 2.6203955],
          [101.7239604, 2.6201422],
          [101.724905, 2.6194869],
          [101.725972, 2.6183423],
          [101.7272052, 2.6167696],
          [101.7277475, 2.616324],
          [101.7283947, 2.6162367],
          [101.7290682, 2.6164813],
          [101.7297504, 2.6172152],
          [101.7303102, 2.61905],
          [101.7305288, 2.6214702],
          [101.7304851, 2.6240739],
          [101.7298729, 2.6271406],
          [101.7291644, 2.6294734],
          [101.7277563, 2.6320683],
          [101.7268292, 2.6332216],
          [101.7255347, 2.6344622],
          [101.724975, 2.6350214],
          [101.7247388, 2.6356155],
          [101.72487, 2.6365416],
          [101.7253161, 2.6375289],
          [101.7260129, 2.6387565],
          [101.7265276, 2.6395881],
          [101.7286396, 2.6427012],
          [101.730214, 2.644536],
          [101.7314035, 2.6460475],
          [101.7325929, 2.6470872],
          [101.7342023, 2.6481181],
          [101.7403421, 2.6508877],
          [101.7441118, 2.6520672],
          [101.7456074, 2.6527312],
          [101.7465345, 2.653404],
          [101.7470855, 2.654033],
          [101.7474616, 2.6547494],
          [101.7475666, 2.6556231],
          [101.747138, 2.6581044],
          [101.7465432, 2.6596246],
          [101.7461846, 2.6609701],
          [101.7459398, 2.6632941],
          [101.7456861, 2.6645784],
          [101.7451963, 2.6655307],
          [101.7443742, 2.6662122],
          [101.7434821, 2.6666577],
          [101.7423975, 2.6670509],
          [101.7415491, 2.6674615],
          [101.7410506, 2.6678984],
          [101.7407182, 2.6683876],
          [101.7403421, 2.6692264],
          [101.7399048, 2.6709562],
          [101.7398436, 2.6718212],
          [101.7398961, 2.6727473],
          [101.7402022, 2.6739792],
          [101.7406045, 2.6748528],
          [101.7413742, 2.6759012],
          [101.7433831, 2.6784015],
          [101.7442259, 2.6794698],
          [101.74485, 2.6803752],
          [101.7453033, 2.6807922],
          [101.7463409, 2.6814157],
          [101.7472394, 2.6817612],
          [101.747951, 2.6818684],
          [101.7482275, 2.6818212],
          [101.7485422, 2.6817675],
          [101.7499341, 2.6813328],
          [101.7508601, 2.6810493],
          [101.7512417, 2.6810263],
          [101.751489, 2.6811106],
          [101.7517338, 2.6814675],
          [101.7518883, 2.6818071],
          [101.7527125, 2.6846988],
          [101.7527331, 2.6851516],
          [101.751569, 2.6871686],
          [101.751332, 2.6875493],
          [101.751332, 2.687858],
          [101.7517132, 2.6889077],
          [101.7523313, 2.6898544],
          [101.7526288, 2.6907287],
          [101.7527225, 2.6909903],
          [101.7529209, 2.6912466],
          [101.7531967, 2.6913054],
          [101.7540621, 2.691254],
          [101.7545566, 2.6911922],
          [101.7546802, 2.6912643],
          [101.7550923, 2.6917891],
          [101.7551232, 2.6920464],
          [101.755137, 2.6922666],
          [101.7552056, 2.6933636],
          [101.7539076, 2.693734],
          [101.7536603, 2.6939193],
          [101.7536809, 2.6941457],
          [101.7541033, 2.6954217],
          [101.7544433, 2.6956893],
          [101.7546081, 2.696317],
          [101.7548862, 2.6966772],
          [101.7549172, 2.696955],
          [101.7553764, 2.6974104],
          [101.7561936, 2.6979856],
          [101.7569939, 2.6983197],
          [101.7577941, 2.6983958],
          [101.7587765, 2.6987088],
          [101.7596656, 2.6988822],
          [101.7605561, 2.6992876],
          [101.762124, 2.7029498],
          [101.7618986, 2.7080553],
          [101.7616192, 2.7120263],
          [101.7608835, 2.722203],
          [101.7602716, 2.7303973],
          [101.7596023, 2.7401769],
          [101.7587801, 2.7539039],
          [101.7581873, 2.7622126],
          [101.758149, 2.7653928],
          [101.7575796, 2.775371],
          [101.7571342, 2.7815034],
          [101.7567856, 2.7869135],
          [101.7567404, 2.7895895],
          [101.7564836, 2.7914193],
          [101.7561181, 2.8015792],
          [101.7558579, 2.8054074],
          [101.7547951, 2.8223646],
          [101.7535129, 2.8287909],
          [101.7537623, 2.8313885],
          [101.7533898, 2.8396653],
          [101.7528457, 2.8407379],
          [101.7528529, 2.8514981],
          [101.7525393, 2.8580524],
          [101.7519978, 2.8628488],
          [101.7514919, 2.8694599],
          [101.7555374, 2.8702499],
          [101.7667811, 2.8700221],
          [101.7888817, 2.8696587],
          [101.7923735, 2.8697388],
          [101.7942106, 2.8691704],
          [101.7945312, 2.8690712],
          [101.7964703, 2.8690906],
          [101.8097441, 2.8692236],
          [101.8192831, 2.8684617],
          [101.8307182, 2.8680989],
          [101.8418918, 2.8676055],
          [101.8509222, 2.8672355],
          [101.8639777, 2.8668741],
          [101.872728, 2.8666332],
          [101.8828958, 2.8669251],
          [101.883719, 2.8706244],
          [101.886407, 2.8767992],
          [101.8869519, 2.8776481],
          [101.8904692, 2.8855451],
          [101.8907182, 2.8861042],
          [101.8915827, 2.8895144],
          [101.8950046, 2.8967411],
          [101.8991093, 2.9084082],
          [101.9106484, 2.9364292],
          [101.9126844, 2.9417412],
          [101.9162398, 2.950758],
          [101.9182331, 2.9553513],
          [101.9288283, 2.9798781],
          [101.9351573, 2.9914518],
          [101.9340887, 2.9930113],
          [101.9342531, 2.9974438],
          [101.9338422, 2.998675],
          [101.9341709, 2.9994958],
          [101.9444452, 2.9994137],
          [101.9445274, 3.0049953],
          [101.9428835, 3.0075398],
          [101.9428835, 3.0165686],
          [101.9478152, 3.0238738],
          [101.9541441, 3.0260078],
          [101.9635965, 3.0281419],
          [101.9670486, 3.0295373],
          [101.9707474, 3.03241],
          [101.9711583, 3.0352828],
          [101.9699254, 3.0398792],
          [101.9683637, 3.0416849],
          [101.9693501, 3.0442294],
          [101.9671308, 3.0477587],
          [101.9677062, 3.0485795],
          [101.9685281, 3.0496465],
          [101.9677884, 3.051206],
          [101.9681171, 3.0528475],
          [101.9694323, 3.0553099],
          [101.9671308, 3.0591675],
          [101.9626101, 3.0645025],
          [101.9619526, 3.0683601],
          [101.9615416, 3.0729564],
          [101.9593224, 3.080179],
          [101.9567743, 3.0917516],
          [101.9549661, 3.0954449],
          [101.9548017, 3.0989741],
          [101.9515139, 3.1067711],
          [101.9504454, 3.1126804],
          [101.950692, 3.1163737],
          [101.9551305, 3.1180151],
          [101.9625279, 3.129259],
          [101.9658157, 3.1391076],
          [101.9635143, 3.1463298],
          [101.9635143, 3.1490381],
          [101.9578429, 3.1551933],
          [101.9560346, 3.156014],
          [101.9561168, 3.1578196],
          [101.9515139, 3.1590506],
          [101.9506098, 3.1632361],
          [101.9482062, 3.1651519],
          [101.9476508, 3.1708685],
          [101.9468288, 3.1725099],
          [101.9467466, 3.1752181],
          [101.9470655, 3.1771579],
          [101.9421832, 3.1837996],
          [101.9499143, 3.1903823],
          [101.9480755, 3.203895],
          [101.9518267, 3.2063919],
          [101.9506499, 3.2098435],
          [101.9515174, 3.213712],
          [101.9419159, 3.2353574],
          [101.9366013, 3.238704],
          [101.9331336, 3.2436506],
          [101.9366748, 3.2492053],
          [101.9340269, 3.2523629],
          [101.9308641, 3.2527301],
          [101.9304228, 3.2565487],
          [101.9283889, 3.2584601],
          [101.9150216, 3.2567889],
          [101.9163582, 3.258232],
          [101.9194788, 3.2621524],
          [101.9247318, 3.2669815],
          [101.9272543, 3.2672411],
          [101.9304269, 3.2688508],
          [101.931155, 3.2710836],
          [101.9332094, 3.2730827],
          [101.9339636, 3.2758607],
          [101.9341196, 3.2833638],
          [101.936304, 3.2869466],
          [101.9380463, 3.2877773],
          [101.9391385, 3.2861937],
          [101.9408289, 3.2860379],
          [101.9439755, 3.283182],
          [101.9472261, 3.283234],
          [101.9498266, 3.2781454],
          [101.9511788, 3.2779117],
          [101.9528691, 3.2722778],
          [101.9544034, 3.2704085],
          [101.957654, 3.2719663],
          [101.962751, 3.2727192],
          [101.9656115, 3.2733942],
          [101.9685501, 3.2720442],
          [101.9711506, 3.2718624],
          [101.9729709, 3.2733942],
          [101.9750513, 3.2711615],
          [101.9747133, 3.2632688],
          [101.9751293, 3.260361],
          [101.9780679, 3.258232],
          [101.9780679, 3.2564925],
          [101.9789521, 3.2549867],
          [101.9796542, 3.2518451],
          [101.9794982, 3.2480545],
          [101.978848, 3.2461073],
          [101.9757274, 3.2446274],
          [101.9808504, 3.2394088],
          [101.983841, 3.2385779],
          [101.9876377, 3.2361633],
          [101.9897701, 3.235722],
          [101.9914604, 3.2365528],
          [101.9951011, 3.2367605],
          [101.9961933, 3.2349431],
          [101.9991059, 3.2329179],
          [102.0013163, 3.2318534],
          [102.0016543, 3.2302437],
          [102.0048529, 3.2281406],
          [102.0088057, 3.2273617],
          [102.0108341, 3.2253365],
          [102.0130705, 3.2252586],
          [102.0147868, 3.226401],
          [102.0172053, 3.226427],
          [102.0194937, 3.2284781],
          [102.0258649, 3.2281666],
          [102.0291415, 3.2296205],
          [102.0301297, 3.2297504],
          [102.0329903, 3.2265568],
          [102.0355907, 3.2253106],
          [102.0383993, 3.2269982],
          [102.0398035, 3.2262712],
          [102.0441724, 3.225726],
          [102.0462528, 3.2230777],
          [102.0485932, 3.2168723],
          [102.0500755, 3.2154183],
          [102.0521299, 3.2094726],
          [102.0518178, 3.2053962],
          [102.0537682, 3.2003591],
          [102.0562126, 3.1969059],
          [102.0561086, 3.1941017],
          [102.0522079, 3.1896877],
          [102.0533261, 3.1876106],
          [102.052936, 3.1846506],
          [102.0563687, 3.182132],
          [102.0564987, 3.1788604],
          [102.0572268, 3.1775362],
          [102.0560826, 3.1758745],
          [102.0563427, 3.1699544],
          [102.0623238, 3.1660077],
          [102.0707234, 3.1709671],
          [102.0721537, 3.1709411],
          [102.0742601, 3.1660337],
          [102.0762624, 3.165774],
          [102.0761844, 3.1753811],
          [102.0808913, 3.1818204],
          [102.0864303, 3.1820801],
          [102.0910852, 3.1863642],
          [102.0925675, 3.1866499],
          [102.0980805, 3.1915052],
          [102.100681, 3.1908561],
          [102.1022153, 3.1918687],
          [102.1090026, 3.1914274],
          [102.1137615, 3.1878702],
          [102.1152698, 3.1881039],
          [102.116492, 3.1914793],
          [102.1158679, 3.1929852],
          [102.1158939, 3.1976588],
          [102.1187284, 3.197529],
          [102.1239034, 3.1983858],
          [102.127076, 3.1928294],
          [102.1336293, 3.193167],
          [102.1336553, 3.1943094],
          [102.1352936, 3.1958673],
          [102.137322, 3.196075],
          [102.1412439, 3.1972033],
          [102.1445506, 3.1964967],
          [102.1586916, 3.1791665],
          [102.1672951, 3.1764241],
          [102.1793308, 3.1721454],
          [102.1994342, 3.1656038],
          [102.2534435, 3.1478444],
          [102.3605345, 3.1234432],
          [102.3894164, 3.1172152],
          [102.4106368, 3.0915021],
          [102.4457994, 3.0464987],
          [102.4842706, 3.007737],
          [102.581341, 2.9440008],
          [102.6048038, 2.9289999],
          [102.6663497, 2.8882048],
          [102.6886857, 2.8824367],
          [102.6913341, 2.879238],
          [102.6950153, 2.8730459],
          [102.6954508, 2.8641137],
          [102.6981184, 2.8540663],
          [102.6958263, 2.850322],
          [102.6965007, 2.8467937],
          [102.6946445, 2.8451519],
          [102.6954674, 2.8426655],
          [102.6976632, 2.8374408],
          [102.6987925, 2.8355514],
          [102.696855, 2.8343259],
          [102.6983404, 2.8329713],
          [102.7010171, 2.8339636],
          [102.7001786, 2.8320519],
          [102.6450187, 2.6813636],
          [102.6324989, 2.6387208],
          [102.6306004, 2.6328497],
          [102.6302038, 2.6299712],
          [102.6312021, 2.6251303],
          [102.6330026, 2.620531],
          [102.6351997, 2.6163898],
          [102.6373968, 2.6122509],
          [102.6403961, 2.607761],
          [102.6427984, 2.6054613],
          [102.6435003, 2.6022409],
          [102.6408998, 2.5951004],
          [102.6271977, 2.5896715],
          [102.6254294, 2.5895591],
          [102.6244816, 2.5869104],
          [102.623902, 2.5863212],
          [102.6234964, 2.5833309],
          [102.6183033, 2.5826709],
          [102.6183432, 2.5815435],
          [102.6175959, 2.5813288],
          [102.6167646, 2.5792196],
          [102.616443, 2.5792296],
          [102.616131, 2.5794627],
          [102.6159107, 2.5794246],
          [102.615899, 2.5791212],
          [102.6167854, 2.5778038],
          [102.6169277, 2.5769619],
          [102.6169503, 2.5767955],
          [102.6159302, 2.5760819],
          [102.616019, 2.5758303],
          [102.6154886, 2.575434],
          [102.6150992, 2.575416],
          [102.6148693, 2.5758926],
          [102.614539, 2.575623],
          [102.6147829, 2.5751975],
          [102.6147664, 2.574817],
          [102.6149341, 2.5743164],
          [102.6147482, 2.5740416],
          [102.6143363, 2.5741881],
          [102.6142452, 2.5746182],
          [102.6140855, 2.5746078],
          [102.6140366, 2.574481],
          [102.614104, 2.574344],
          [102.6139754, 2.5740923],
          [102.6135374, 2.5732154],
          [102.6130776, 2.573047],
          [102.6138181, 2.5718894],
          [102.6132048, 2.570503],
          [102.6114752, 2.5681497],
          [102.608839, 2.567666],
          [102.6081848, 2.5661855],
          [102.6089136, 2.5637725],
          [102.6125719, 2.5617828],
          [102.6131123, 2.5583365],
          [102.6151727, 2.5550662],
          [102.6116792, 2.5513167],
          [102.611984, 2.5456905],
          [102.6106802, 2.5391766],
          [102.6100065, 2.5358501],
          [102.612624, 2.531364],
          [102.6128191, 2.529503],
          [102.6172551, 2.5248499],
          [102.6191916, 2.5179807],
          [102.6145474, 2.5135845],
          [102.6136666, 2.5121386],
          [102.6088237, 2.5091756],
          [102.6080261, 2.5070061],
          [102.6094925, 2.50564],
          [102.6066801, 2.5016794],
          [102.6081243, 2.5006377],
          [102.6106762, 2.4905282],
          [102.6125848, 2.4911133],
          [102.613987, 2.487454],
          [102.6162388, 2.4858167],
          [102.6159563, 2.4835226],
          [102.6180453, 2.4754802],
          [102.6187572, 2.4738742],
          [102.6183207, 2.4722639],
          [102.6184782, 2.4697293],
          [102.614727, 2.466717],
          [102.6117359, 2.4673875],
          [102.6098926, 2.4649843],
          [102.6060399, 2.4641243],
          [102.6052626, 2.4629938],
          [102.6061483, 2.4614605],
          [102.6060298, 2.459563],
          [102.6010952, 2.4592142],
          [102.6000207, 2.4582078],
          [102.6002222, 2.457302],
          [102.5983754, 2.4555911],
          [102.5964614, 2.4553563],
          [102.5963607, 2.454786],
          [102.5972963, 2.454171],
          [102.5962231, 2.4507565],
          [102.5968443, 2.446776],
          [102.5964536, 2.4447082],
          [102.5938905, 2.4399458],
          [102.5949284, 2.4390477],
          [102.5946993, 2.4381093],
          [102.5960099, 2.4360989],
          [102.5941724, 2.4317466],
          [102.5937372, 2.429229],
          [102.5925689, 2.4275125],
          [102.5933611, 2.4249287],
          [102.5946306, 2.4225916],
          [102.5943786, 2.4198909],
          [102.5959364, 2.4197993],
          [102.5959593, 2.4179683],
          [102.5965778, 2.4168011],
          [102.5958905, 2.4162518],
          [102.5951804, 2.4153362],
          [102.5951117, 2.4122006],
          [102.5943828, 2.4095606],
          [102.5879129, 2.416268],
          [102.567904, 2.4178581],
          [102.5100568, 2.4376173],
          [102.4703032, 2.4468697],
          [102.4350555, 2.4566864],
          [102.4363412, 2.4670146],
          [102.368262, 2.4893683],
          [102.3642926, 2.4897502],
          [102.3596285, 2.4886465],
          [102.3346914, 2.4827456],
          [102.3171609, 2.4788987],
          [102.2795893, 2.4582806],
          [102.2373834, 2.4651381],
          [102.2351097, 2.4655075],
          [102.2346335, 2.4656921],
          [102.2328727, 2.4663747],
          [102.2313125, 2.4663466],
          [102.2299057, 2.4672428],
          [102.2283624, 2.4678824],
          [102.2245499, 2.4729346],
          [102.2217732, 2.4745899],
          [102.2150181, 2.478617],
          [102.213281, 2.4789048],
          [102.2114351, 2.4816422],
          [102.2061879, 2.4801864],
          [102.2058106, 2.482448],
          [102.2033205, 2.4835788],
          [102.1958183, 2.4862437],
          [102.1920836, 2.4925459],
          [102.1788418, 2.4980802],
          [102.1418582, 2.4570746],
          [102.1220312, 2.4619327],
          [102.089461, 2.447396],
          [102.0889275, 2.4456207],
          [102.0850857, 2.4528623],
          [102.0821294, 2.4533068],
          [102.0814728, 2.4545641],
          [102.0794483, 2.4540721],
          [102.077369, 2.4545094],
          [102.0734295, 2.4560401],
          [102.0723899, 2.4559854],
          [102.0698434, 2.4576607],
          [102.0622673, 2.4550014],
          [102.0615013, 2.4535528],
          [102.0609815, 2.4518855],
          [102.061197, 2.4505692],
          [102.0624163, 2.449508],
          [102.0623591, 2.4483608],
          [102.061857, 2.4469108],
          [102.0602976, 2.4447515],
          [102.0601184, 2.443481],
          [102.0605778, 2.4423652],
          [102.061836, 2.4419768],
          [102.0635112, 2.4435657],
          [102.064939, 2.4433186],
          [102.0652642, 2.4421675],
          [102.0644703, 2.4405042],
          [102.063384, 2.4387071],
          [102.062967, 2.4373229],
          [102.0624863, 2.4362142],
          [102.061737, 2.435176],
          [102.060903, 2.4345546],
          [102.0599841, 2.4341167],
          [102.0590652, 2.4342156],
          [102.0573688, 2.4349077],
          [102.0562944, 2.4351054],
          [102.0540607, 2.4348088],
          [102.0522654, 2.4341732],
          [102.0511061, 2.4330433],
          [102.0509506, 2.4311366],
          [102.0506084, 2.4299662],
          [102.0492259, 2.4281987],
          [102.0492118, 2.4268711],
          [102.0498904, 2.4256846],
          [102.0509177, 2.4247103],
          [102.0515726, 2.4232411],
          [102.0513465, 2.4216027],
          [102.0486206, 2.4197917],
          [102.0471337, 2.4193993],
          [102.0456069, 2.420727],
          [102.0440687, 2.4208309],
          [102.0410548, 2.4206423],
          [102.0388778, 2.4221959],
          [102.0349477, 2.4257835],
          [102.0349194, 2.4272242],
          [102.035598, 2.4299642],
          [102.0342231, 2.4327773],
          [102.0326471, 2.4336623],
          [102.0305414, 2.4338287],
          [102.0276797, 2.431915],
          [102.0278624, 2.4301354],
          [102.0273474, 2.4277557],
          [102.0260707, 2.4256869],
          [102.0238391, 2.4236074],
          [102.0219294, 2.4218816],
          [102.0192901, 2.419384],
          [102.017434, 2.4170257],
          [102.0164899, 2.4154929],
          [102.0140222, 2.411248],
          [102.0130352, 2.4101439],
          [102.0112327, 2.4088469],
          [102.0079497, 2.4082144],
          [102.0049993, 2.4073676],
          [102.0033148, 2.4067352],
          [102.0008579, 2.4054381],
          [101.9986156, 2.4034229],
          [101.9965771, 2.4015148],
          [101.9933799, 2.3996925],
          [101.9906441, 2.3989636],
          [101.9869105, 2.398642],
          [101.9846145, 2.3983633],
          [101.9830803, 2.397345],
          [101.9820514, 2.3958682],
          [101.9801191, 2.3930036],
          [101.9781557, 2.3908275],
          [101.9761387, 2.3899271],
          [101.9734161, 2.3910722],
          [101.9740986, 2.3922693],
          [101.9736362, 2.3948393],
          [101.9720762, 2.3983124],
          [101.9697598, 2.3996973],
          [101.96592, 2.4021081],
          [101.9619889, 2.4031565],
          [101.9553795, 2.4015603],
          [101.9514142, 2.4039374],
          [101.9430871, 2.4106724],
          [101.9393113, 2.4204529],
          [101.9322732, 2.4244619],
          [101.928797, 2.4252552],
          [101.9274237, 2.4246334],
          [101.9195058, 2.4252337],
          [101.9187548, 2.4228326],
          [101.9172528, 2.4220179],
          [101.9169953, 2.42339],
          [101.9141629, 2.423733],
          [101.9072704, 2.4230282],
          [101.9026594, 2.4197112],
          [101.8962962, 2.4164864],
          [101.8887068, 2.4179821],
          [101.8803777, 2.4200354],
          [101.8778216, 2.4200903],
          [101.8776755, 2.4195843],
          [101.876391, 2.4193382],
          [101.8763589, 2.4187649],
          [101.8756516, 2.4182056],
          [101.8754567, 2.4182619],
          [101.875019, 2.4179543],
          [101.8745384, 2.4177969],
          [101.8739663, 2.4177868],
          [101.8735766, 2.4179697],
          [101.8733209, 2.4181555],
          [101.8731026, 2.4184999],
          [101.8729026, 2.4191866],
          [101.8699657, 2.4191629],
          [101.8690682, 2.419006],
          [101.8673889, 2.4187124],
          [101.8659011, 2.4183823],
          [101.8644506, 2.4179403],
          [101.8635477, 2.4175904],
          [101.8625818, 2.4172375],
          [101.8614851, 2.4166514],
          [101.8607693, 2.4162612],
          [101.8599041, 2.4155724],
          [101.8595375, 2.415142],
          [101.8588051, 2.4141246],
          [101.8586549, 2.413444],
          [101.8591312, 2.4124796],
          [101.8594436, 2.4109901],
          [101.8586048, 2.4092429],
          [101.8581104, 2.4089398],
          [101.8576159, 2.4085807],
          [101.8565819, 2.4074436],
          [101.8561269, 2.4064184],
          [101.8561214, 2.405823],
          [101.8566764, 2.4054516],
          [101.857892, 2.4047295],
          [101.8579924, 2.4044367],
          [101.8569231, 2.4044899],
          [101.856078, 2.4042817],
          [101.8551629, 2.4044627],
          [101.8546121, 2.4042333],
          [101.8541361, 2.4039013],
          [101.8539176, 2.4036249],
          [101.8539156, 2.4035452],
          [101.8539045, 2.403106],
          [101.8537334, 2.402935],
          [101.8535623, 2.4029319],
          [101.8535196, 2.4031908],
          [101.8536571, 2.4035209],
          [101.853539, 2.4037513],
          [101.8536077, 2.404047],
          [101.8527059, 2.4046665],
          [101.8513577, 2.4061696],
          [101.849973, 2.4086291],
          [101.8493239, 2.4086815],
          [101.8491495, 2.4088677],
          [101.8495175, 2.4096949],
          [101.8477296, 2.4119432],
          [101.8485025, 2.4122117],
          [101.8500327, 2.4114647],
          [101.8503372, 2.4113979],
          [101.8515617, 2.413124],
          [101.8518616, 2.4139645],
          [101.8523545, 2.4149015],
          [101.8524842, 2.415278],
          [101.8533168, 2.4145226],
          [101.8539134, 2.4142925],
          [101.8543341, 2.4139019],
          [101.8546835, 2.413742],
          [101.8552474, 2.4143003],
          [101.8561393, 2.4155295],
          [101.8565682, 2.416224],
          [101.856922, 2.4169185],
          [101.8563401, 2.4174132],
          [101.8563189, 2.4184563],
          [101.8561689, 2.4188996],
          [101.8563059, 2.4192157],
          [101.8563274, 2.4207837],
          [101.8564776, 2.421387],
          [101.8567141, 2.422529],
          [101.8568147, 2.4231253],
          [101.8567866, 2.4239359],
          [101.8568443, 2.4262633],
          [101.856902, 2.4275027],
          [101.8570098, 2.4279474],
          [101.8571349, 2.4295184],
          [101.8572386, 2.4311001],
          [101.8575014, 2.4324053],
          [101.8575819, 2.4340481],
          [101.8575755, 2.4355122],
          [101.8573051, 2.4366999],
          [101.856745, 2.438161],
          [101.8565889, 2.4386983],
          [101.8563059, 2.4401546],
          [101.8562782, 2.4408504],
          [101.8560789, 2.4419742],
          [101.8554862, 2.4444413],
          [101.855205, 2.4446966],
          [101.854917, 2.4446618],
          [101.8543712, 2.4445385],
          [101.8538647, 2.4448936],
          [101.8541253, 2.4459135],
          [101.8546226, 2.4457507],
          [101.8547754, 2.4462497],
          [101.8551327, 2.4466608],
          [101.8556427, 2.4479456],
          [101.8558445, 2.448639],
          [101.8559068, 2.4494527],
          [101.8558299, 2.4507693],
          [101.8556458, 2.4521438],
          [101.8552682, 2.4540326],
          [101.8548622, 2.4556142],
          [101.8543278, 2.4572545],
          [101.8535934, 2.459005],
          [101.8527416, 2.4604973],
          [101.852703354905231, 2.46054844930768],
          [101.85176, 2.4618101],
          [101.851615, 2.4619232],
          [101.8515037, 2.4620121],
          [101.851274, 2.4621356],
          [101.8509007, 2.4623497],
          [101.8506325, 2.4624944],
          [101.8504823, 2.4624408],
          [101.8501969, 2.4620714],
          [101.8499303, 2.4618477],
          [101.8495573, 2.4617486],
          [101.8492623, 2.4615825],
          [101.8489136, 2.461695],
          [101.8487849, 2.4618344],
          [101.8485327, 2.4619094],
          [101.8483342, 2.461904],
          [101.8482699, 2.4621184],
          [101.8482806, 2.4623382],
          [101.8481626, 2.4624722],
          [101.8479963, 2.4628848],
          [101.8479534, 2.4631689],
          [101.8479319, 2.4633297],
          [101.8478675, 2.4635226],
          [101.8478836, 2.4637799],
          [101.8478085, 2.4643748],
          [101.8479158, 2.4645784],
          [101.8479587, 2.4648625],
          [101.8480231, 2.4649053],
          [101.8481304, 2.4649214],
          [101.8482806, 2.4649697],
          [101.8484254, 2.465034],
          [101.8485488, 2.4650179],
          [101.8487151, 2.4650501],
          [101.848826, 2.4651012],
          [101.8489429, 2.4653982],
          [101.84902, 2.4658509],
          [101.8490301, 2.4668167],
          [101.8488914, 2.467659],
          [101.8482189, 2.4698746],
          [101.8470644, 2.4722981],
          [101.847868, 2.4732418],
          [101.8477731, 2.4740101],
          [101.8456579, 2.474067],
          [101.8442257, 2.4747689],
          [101.8434763, 2.4758217],
          [101.8432487, 2.4771971],
          [101.8437704, 2.4789518],
          [101.8442446, 2.479151],
          [101.8444863, 2.4789762],
          [101.8445553, 2.4786241],
          [101.8444666, 2.4786008],
          [101.8442424, 2.4781436],
          [101.8440229, 2.4774204],
          [101.8440276, 2.4769025],
          [101.8441163, 2.4763986],
          [101.8442564, 2.4760767],
          [101.8444572, 2.4756941],
          [101.8447514, 2.4753395],
          [101.8452324, 2.4749849],
          [101.8457648, 2.4747703],
          [101.8462598, 2.4746537],
          [101.8463019, 2.4748123],
          [101.8464139, 2.4749103],
          [101.8465354, 2.4749383],
          [101.8467222, 2.474901],
          [101.8467782, 2.4752462],
          [101.8465494, 2.4753069],
          [101.846554, 2.4755728],
          [101.8466054, 2.4759367],
          [101.8464046, 2.4759787],
          [101.8456808, 2.4770285],
          [101.8457882, 2.4771638],
          [101.8459189, 2.4771638],
          [101.8462598, 2.4778403],
          [101.8462505, 2.4779663],
          [101.8461337, 2.4781203],
          [101.8457695, 2.4783442],
          [101.8451951, 2.4785495],
          [101.845097, 2.4787781],
          [101.8450923, 2.4790207],
          [101.8453161, 2.4792401],
          [101.8457243, 2.4793502],
          [101.8463314, 2.4791226],
          [101.8465841, 2.4788551],
          [101.8467462, 2.4789833],
          [101.8469019, 2.4791495],
          [101.8470824, 2.4795774],
          [101.8471285, 2.4802244],
          [101.8471037, 2.4810978],
          [101.846994, 2.4817873],
          [101.8467285, 2.4826713],
          [101.8464631, 2.4833749],
          [101.8460809, 2.4842164],
          [101.8455464, 2.484966],
          [101.845235, 2.4853727],
          [101.8442452, 2.4860484],
          [101.8432795, 2.4880664],
          [101.8412094, 2.4905221],
          [101.8406081, 2.4908403],
          [101.8400351, 2.4907362],
          [101.8396905, 2.4905147],
          [101.8392325, 2.490569],
          [101.8389098, 2.4908726],
          [101.838802, 2.4911449],
          [101.8389133, 2.4915219],
          [101.8392234, 2.4916345],
          [101.8392183, 2.4918188],
          [101.8391418, 2.4920031],
          [101.8389135, 2.4922319],
          [101.8384199, 2.4924534],
          [101.8379499, 2.4923233],
          [101.8375988, 2.492169],
          [101.8375349, 2.4919358],
          [101.8373378, 2.4916988],
          [101.8369579, 2.4914019],
          [101.8364799, 2.4915242],
          [101.8363803, 2.4917859],
          [101.8364287, 2.4919747],
          [101.8365307, 2.4921457],
          [101.8366781, 2.4923128],
          [101.8376805, 2.4928138],
          [101.8380955, 2.4928218],
          [101.8385182, 2.4927599],
          [101.8388981, 2.492729],
          [101.8392858, 2.4932922],
          [101.8394749, 2.4943649],
          [101.8393686, 2.4953754],
          [101.8387756, 2.4966347],
          [101.83767, 2.4966169],
          [101.8371457, 2.4966085],
          [101.8369774, 2.4972177],
          [101.8377613, 2.497268],
          [101.8377713, 2.4978312],
          [101.8375583, 2.498666],
          [101.8372017, 2.4994804],
          [101.8359606, 2.5018167],
          [101.8340847, 2.5037851],
          [101.8311273, 2.5050063],
          [101.830265, 2.5062031],
          [101.8290765, 2.5075397],
          [101.8278955, 2.5085467],
          [101.8275152, 2.5092072],
          [101.8265126, 2.5116317],
          [101.8264834, 2.5118039],
          [101.8264546, 2.5119734],
          [101.825294, 2.5143253],
          [101.8247391, 2.5149451],
          [101.8239127, 2.5141793],
          [101.823089, 2.5152785],
          [101.8239519, 2.5161744],
          [101.8220188, 2.5181415],
          [101.821327, 2.517802],
          [101.8206932, 2.5180345],
          [101.8203561, 2.5184564],
          [101.8207238, 2.5195815],
          [101.8173383, 2.5208999],
          [101.8169492, 2.5204583],
          [101.8166678, 2.5206788],
          [101.8167367, 2.5208691],
          [101.815115, 2.5216823],
          [101.8148994, 2.5216633],
          [101.8133335, 2.5218517],
          [101.8123199, 2.5220287],
          [101.8093893, 2.5227369],
          [101.8080524, 2.5230208],
          [101.8078778, 2.5230441],
          [101.8071402, 2.5231344],
          [101.8060175, 2.5231789],
          [101.8049617, 2.5230519],
          [101.8040563, 2.5227377],
          [101.8036341, 2.5224365],
          [101.8032176, 2.5212452],
          [101.8025028, 2.5207623],
          [101.8015812, 2.5206259],
          [101.8004184, 2.520645],
          [101.7990714, 2.5205637],
          [101.7985867, 2.5207663],
          [101.7969694, 2.5207851],
          [101.7970349, 2.5210921],
          [101.7969429, 2.5213963],
          [101.7967284, 2.5217387],
          [101.796274, 2.5217443],
          [101.7958837, 2.5217074],
          [101.7955916, 2.5215187],
          [101.7955163, 2.5211221],
          [101.7953615, 2.5208113],
          [101.7950938, 2.5208132],
          [101.7941137, 2.5209999],
          [101.7936969, 2.5214391],
          [101.7933584, 2.5218949],
          [101.7932471, 2.5223242],
          [101.7929982, 2.5223837],
          [101.7922593, 2.5219665],
          [101.7919425, 2.520994],
          [101.791552, 2.5210793],
          [101.7912706, 2.5213153],
          [101.7910102, 2.521337],
          [101.7907061, 2.5212194],
          [101.7901944, 2.5220389],
          [101.7905034, 2.5224907],
          [101.7907005, 2.5230721],
          [101.7903508, 2.5239076],
          [101.790005, 2.5245703],
          [101.7901222, 2.5250089],
          [101.7905213, 2.5251212],
          [101.790777, 2.5251657],
          [101.7908024, 2.5255714],
          [101.7906909, 2.5268118],
          [101.7905179, 2.5271612],
          [101.7901091, 2.5278346],
          [101.7897092, 2.5281149],
          [101.7897104, 2.5291844],
          [101.7903933, 2.5290874],
          [101.7903678, 2.5299074],
          [101.7895962, 2.5306279],
          [101.7892853, 2.5313059],
          [101.7893212, 2.5319774],
          [101.7910293, 2.5341794],
          [101.7912854, 2.5342052],
          [101.7920102, 2.534091],
          [101.7923336, 2.5342188],
          [101.7932142, 2.5352164],
          [101.7939503, 2.5345601],
          [101.7943562, 2.5346914],
          [101.7946269, 2.5354773],
          [101.7948116, 2.5359256],
          [101.7952613, 2.5369095],
          [101.7956612, 2.5376652],
          [101.7961509, 2.5383693],
          [101.7961968, 2.5391227],
          [101.7970498, 2.5391273],
          [101.7975308, 2.539837],
          [101.7987342, 2.5416126],
          [101.7988624, 2.5435813],
          [101.7985894, 2.5457058],
          [101.7969988, 2.5491507],
          [101.7966367, 2.5491486],
          [101.7958176, 2.5506036],
          [101.7955158, 2.5516609],
          [101.7948689, 2.5518692],
          [101.7938113, 2.5516494],
          [101.7931066, 2.5520347],
          [101.79302, 2.5539016],
          [101.793045, 2.5547928],
          [101.7932461, 2.5550809],
          [101.7941042, 2.5555093],
          [101.7940789, 2.5558531],
          [101.7943619, 2.5561192],
          [101.795349, 2.5559501],
          [101.7960134, 2.5562722],
          [101.7964628, 2.5575532],
          [101.7962066, 2.5580585],
          [101.7963796, 2.5586882],
          [101.7968281, 2.559386],
          [101.7978095, 2.5601714],
          [101.7978128, 2.5605192],
          [101.7980209, 2.5609326],
          [101.79782, 2.5612716],
          [101.7978213, 2.5616309],
          [101.7976124, 2.561853],
          [101.797393, 2.5624104],
          [101.7980823, 2.5629605],
          [101.7981429, 2.5631794],
          [101.7974549, 2.5642276],
          [101.7976184, 2.5655131],
          [101.7973594, 2.565707],
          [101.7971408, 2.5660114],
          [101.7971778, 2.5663013],
          [101.7974568, 2.5664975],
          [101.7962514, 2.5688862],
          [101.7963791, 2.570066],
          [101.7962849, 2.5704698],
          [101.7960757, 2.5708024],
          [101.7959824, 2.5713892],
          [101.7958147, 2.5724733],
          [101.7954757, 2.5734114],
          [101.7950345, 2.5740818],
          [101.793875, 2.5757688],
          [101.792786, 2.5766552],
          [101.7914098, 2.5775426],
          [101.790751, 2.577816],
          [101.7889587, 2.579209],
          [101.7882519, 2.5792839],
          [101.7874919, 2.5791195],
          [101.787372, 2.5788634],
          [101.7869899, 2.5787639],
          [101.7865497, 2.5775276],
          [101.7856943, 2.5770796],
          [101.7844568, 2.5769551],
          [101.7838229, 2.5777451],
          [101.7836345, 2.5785399],
          [101.7840498, 2.5789547],
          [101.7840996, 2.5793115],
          [101.7836428, 2.5802325],
          [101.7827957, 2.5810788],
          [101.7817492, 2.5827382],
          [101.7820731, 2.5833273],
          [101.7821478, 2.5837172],
          [101.7817326, 2.5843644],
          [101.7814848, 2.5848282],
          [101.7816329, 2.5853351],
          [101.782206, 2.5860072],
          [101.7824385, 2.5866295],
          [101.7824967, 2.587343],
          [101.7818488, 2.5881312],
          [101.7792031, 2.591028],
          [101.7786678, 2.5916243],
          [101.7784934, 2.5916575],
          [101.7782775, 2.59184],
          [101.7783024, 2.5920889],
          [101.7777709, 2.592371],
          [101.7772393, 2.592288],
          [101.775437, 2.5936073],
          [101.7724504, 2.5957347],
          [101.7631102, 2.6005164],
          [101.757865, 2.6030519],
          [101.7570739, 2.603225],
          [101.755944, 2.6034723],
          [101.7520522, 2.6031349],
          [101.7496651, 2.6034391],
          [101.7464891, 2.6033921],
          [101.7413268, 2.6024547],
          [101.7405548, 2.6023145],
          [101.7355258, 2.5991752],
          [101.7330488, 2.597378],
          [101.7319793, 2.5969969],
          [101.7315833, 2.5959524],
          [101.7310857, 2.5951002],
          [101.7300861, 2.5941436],
          [101.7292695, 2.5939608],
          [101.7291501, 2.5903261],
          [101.7275534, 2.5898563],
          [101.7271814, 2.5895397],
          [101.7187545, 2.5894879],
          [101.7187991, 2.5952012],
          [101.7182753, 2.5950568],
          [101.7171333, 2.5950923],
          [101.714942, 2.5951462],
          [101.7124503, 2.5953285],
        ],
      ],
    ],
  },
};
