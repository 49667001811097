export const pahang = {
  type: "Feature",
  properties: {
    gid: 8,
    id: 4444595,
    country: "MYS",
    name: "Pahang",
    enname: "Pahang",
    locname: "Pahang",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q191346",
    wikimedia: "en:Pahang",
    timestamp: "2019-12-20 22:59:01",
    note: null,
    path: "0,2108121,4444595",
    rpath: "4444595,2108121,0",
    iso3166_2: "MY-06",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [103.6350893, 2.6654762],
          [103.6316689, 2.6614211],
          [103.6284855, 2.6595103],
          [103.6259675, 2.6582536],
          [103.6232303, 2.6568177],
          [103.6201358, 2.6567081],
          [103.618507, 2.6567772],
          [103.6100022, 2.6632264],
          [103.6060124, 2.6647328],
          [103.6028248, 2.665113],
          [103.6000585, 2.6654429],
          [103.5961683, 2.6637501],
          [103.5940967, 2.6603746],
          [103.5926246, 2.6584697],
          [103.5913278, 2.65617],
          [103.5906258, 2.6539798],
          [103.5906258, 2.6517895],
          [103.5927038, 2.6477691],
          [103.5937043, 2.6409705],
          [103.5951657, 2.6379652],
          [103.5968557, 2.6354317],
          [103.5995536, 2.6336981],
          [103.6018072, 2.6331272],
          [103.6032092, 2.6332914],
          [103.6040517, 2.6336556],
          [103.6057791, 2.6347119],
          [103.6066843, 2.636208],
          [103.6069804, 2.6374337],
          [103.6070824, 2.638768],
          [103.6066817, 2.6399212],
          [103.6027629, 2.6457199],
          [103.6022988, 2.6473882],
          [103.6023453, 2.6489953],
          [103.6030918, 2.6502102],
          [103.6053588, 2.6520595],
          [103.6069303, 2.6527715],
          [103.6089103, 2.6531163],
          [103.6116821, 2.653384],
          [103.6142496, 2.6525908],
          [103.616668, 2.6510853],
          [103.6173383, 2.6488856],
          [103.6172531, 2.6472367],
          [103.6155058, 2.6428396],
          [103.6126025, 2.6390603],
          [103.612505, 2.6368514],
          [103.6135098, 2.6352222],
          [103.6156316, 2.6340509],
          [103.6209836, 2.6327453],
          [103.6234611, 2.6310608],
          [103.623192, 2.6285084],
          [103.6212651, 2.6269609],
          [103.616741, 2.6251681],
          [103.6152589, 2.6237779],
          [103.6146993, 2.6215703],
          [103.6146956, 2.6190825],
          [103.6168907, 2.6166697],
          [103.6210243, 2.6145473],
          [103.6255392, 2.6105569],
          [103.6267448, 2.6084851],
          [103.6267817, 2.6059665],
          [103.6245023, 2.6037535],
          [103.6208178, 2.6031539],
          [103.6156961, 2.6018716],
          [103.6133797, 2.5999923],
          [103.6124141, 2.5977027],
          [103.6124331, 2.5954598],
          [103.6138311, 2.5939189],
          [103.6164495, 2.5934574],
          [103.6203442, 2.5934049],
          [103.6238165, 2.5922726],
          [103.6249973, 2.5897975],
          [103.6248909, 2.5890746],
          [103.6246195, 2.5872309],
          [103.6221846, 2.5857079],
          [103.6191852, 2.5856766],
          [103.6165259, 2.5863808],
          [103.6119896, 2.5888038],
          [103.609188, 2.5888415],
          [103.6065344, 2.5878],
          [103.6037357, 2.5859607],
          [103.6018349, 2.5833101],
          [103.5997511, 2.5793672],
          [103.5982021, 2.5743527],
          [103.5985986, 2.5721716],
          [103.5995992, 2.5694116],
          [103.6021516, 2.5684154],
          [103.6048373, 2.5693324],
          [103.6081207, 2.5754027],
          [103.6109946, 2.5780007],
          [103.6138965, 2.5779226],
          [103.6164158, 2.5755776],
          [103.6165341, 2.5725107],
          [103.6159606, 2.5680924],
          [103.6163074, 2.563754],
          [103.6178187, 2.5600173],
          [103.6178187, 2.5589605],
          [103.6174131, 2.5571845],
          [103.6158162, 2.5565071],
          [103.6140104, 2.5566033],
          [103.6128692, 2.5593002],
          [103.612567, 2.5628859],
          [103.6121408, 2.564435],
          [103.6105198, 2.5657714],
          [103.607227, 2.5651472],
          [103.5970186, 2.5553614],
          [103.5930076, 2.5516612],
          [103.5923662, 2.5497428],
          [103.5931499, 2.5482926],
          [103.5955558, 2.5455931],
          [103.5960404, 2.5431261],
          [103.5949032, 2.5411098],
          [103.5908386, 2.5364927],
          [103.5901594, 2.5338821],
          [103.590298, 2.5308253],
          [103.5914528, 2.5279218],
          [103.5935902, 2.5263128],
          [103.5956831, 2.5265206],
          [103.5994576, 2.530089],
          [103.6030652, 2.5318477],
          [103.6065591, 2.5320491],
          [103.6099997, 2.5315808],
          [103.6133681, 2.5303554],
          [103.6158262, 2.5303304],
          [103.6180832, 2.5315198],
          [103.6207279, 2.5337091],
          [103.6222478, 2.533794],
          [103.6238639, 2.5331429],
          [103.6241995, 2.5315072],
          [103.6211058, 2.5288021],
          [103.618293, 2.5253261],
          [103.6165362, 2.5195657],
          [103.6165719, 2.517252],
          [103.6182023, 2.5155813],
          [103.6229316, 2.5131616],
          [103.6262228, 2.5100487],
          [103.629708, 2.5053429],
          [103.6303977, 2.5024061],
          [103.6300746, 2.4987927],
          [103.6289996, 2.4960252],
          [103.6288189, 2.4938398],
          [103.6296868, 2.4910005],
          [103.6318913, 2.4888209],
          [103.6345021, 2.4880603],
          [103.6378085, 2.4881389],
          [103.6385046, 2.4874595],
          [103.6381176, 2.4849607],
          [103.6351707, 2.4791905],
          [103.6312623, 2.4730314],
          [103.629601, 2.4691999],
          [103.6289265, 2.4671329],
          [103.6276297, 2.4649426],
          [103.6260275, 2.4634429],
          [103.6238838, 2.462664],
          [103.6188846, 2.4625172],
          [103.6167856, 2.4627474],
          [103.6140848, 2.4641171],
          [103.6123213, 2.4659585],
          [103.6112527, 2.4689747],
          [103.6105581, 2.4706562],
          [103.6094894, 2.4707096],
          [103.608459, 2.4692162],
          [103.6079087, 2.4662614],
          [103.6085327, 2.4630907],
          [103.6094479, 2.457941],
          [103.5389894, 2.4916056],
          [103.4722038, 2.52604],
          [103.4596295, 2.5416576],
          [103.4516077, 2.5489759],
          [103.446876, 2.5528011],
          [103.4416644, 2.5555321],
          [103.4358598, 2.5570825],
          [103.4263774, 2.5593574],
          [103.4211632, 2.559386],
          [103.4157934, 2.5578192],
          [103.3747543, 2.5794959],
          [103.3536313, 2.5911675],
          [103.3454083, 2.5894956],
          [103.2900138, 2.5777513],
          [103.2888419, 2.5775028],
          [103.2691896, 2.5693434],
          [103.1515781, 2.5236058],
          [103.1403994, 2.5272673],
          [102.9731066, 2.5946505],
          [102.9663395, 2.5994688],
          [102.959235, 2.6066811],
          [102.8956374, 2.6815335],
          [102.879414, 2.7004238],
          [102.8499296, 2.7361863],
          [102.8283369, 2.7645365],
          [102.8077259, 2.7940587],
          [102.802074, 2.7993649],
          [102.7871998, 2.8039193],
          [102.7001786, 2.8320519],
          [102.7010171, 2.8339636],
          [102.6983404, 2.8329713],
          [102.696855, 2.8343259],
          [102.6987925, 2.8355514],
          [102.6976632, 2.8374408],
          [102.6954674, 2.8426655],
          [102.6946445, 2.8451519],
          [102.6965007, 2.8467937],
          [102.6958263, 2.850322],
          [102.6981184, 2.8540663],
          [102.6954508, 2.8641137],
          [102.6950153, 2.8730459],
          [102.6913341, 2.879238],
          [102.6886857, 2.8824367],
          [102.6663497, 2.8882048],
          [102.6048038, 2.9289999],
          [102.581341, 2.9440008],
          [102.4842706, 3.007737],
          [102.4457994, 3.0464987],
          [102.4106368, 3.0915021],
          [102.3894164, 3.1172152],
          [102.3605345, 3.1234432],
          [102.2534435, 3.1478444],
          [102.1994342, 3.1656038],
          [102.1793308, 3.1721454],
          [102.1672951, 3.1764241],
          [102.1586916, 3.1791665],
          [102.1445506, 3.1964967],
          [102.1412439, 3.1972033],
          [102.137322, 3.196075],
          [102.1352936, 3.1958673],
          [102.1336553, 3.1943094],
          [102.1336293, 3.193167],
          [102.127076, 3.1928294],
          [102.1239034, 3.1983858],
          [102.1187284, 3.197529],
          [102.1158939, 3.1976588],
          [102.1158679, 3.1929852],
          [102.116492, 3.1914793],
          [102.1152698, 3.1881039],
          [102.1137615, 3.1878702],
          [102.1090026, 3.1914274],
          [102.1022153, 3.1918687],
          [102.100681, 3.1908561],
          [102.0980805, 3.1915052],
          [102.0925675, 3.1866499],
          [102.0910852, 3.1863642],
          [102.0864303, 3.1820801],
          [102.0808913, 3.1818204],
          [102.0761844, 3.1753811],
          [102.0762624, 3.165774],
          [102.0742601, 3.1660337],
          [102.0721537, 3.1709411],
          [102.0707234, 3.1709671],
          [102.0623238, 3.1660077],
          [102.0563427, 3.1699544],
          [102.0560826, 3.1758745],
          [102.0572268, 3.1775362],
          [102.0564987, 3.1788604],
          [102.0563687, 3.182132],
          [102.052936, 3.1846506],
          [102.0533261, 3.1876106],
          [102.0522079, 3.1896877],
          [102.0561086, 3.1941017],
          [102.0562126, 3.1969059],
          [102.0537682, 3.2003591],
          [102.0518178, 3.2053962],
          [102.0521299, 3.2094726],
          [102.0500755, 3.2154183],
          [102.0485932, 3.2168723],
          [102.0462528, 3.2230777],
          [102.0441724, 3.225726],
          [102.0398035, 3.2262712],
          [102.0383993, 3.2269982],
          [102.0355907, 3.2253106],
          [102.0329903, 3.2265568],
          [102.0301297, 3.2297504],
          [102.0291415, 3.2296205],
          [102.0258649, 3.2281666],
          [102.0194937, 3.2284781],
          [102.0172053, 3.226427],
          [102.0147868, 3.226401],
          [102.0130705, 3.2252586],
          [102.0108341, 3.2253365],
          [102.0088057, 3.2273617],
          [102.0048529, 3.2281406],
          [102.0016543, 3.2302437],
          [102.0013163, 3.2318534],
          [101.9991059, 3.2329179],
          [101.9961933, 3.2349431],
          [101.9951011, 3.2367605],
          [101.9914604, 3.2365528],
          [101.9897701, 3.235722],
          [101.9876377, 3.2361633],
          [101.983841, 3.2385779],
          [101.9808504, 3.2394088],
          [101.9757274, 3.2446274],
          [101.978848, 3.2461073],
          [101.9794982, 3.2480545],
          [101.9796542, 3.2518451],
          [101.9789521, 3.2549867],
          [101.9780679, 3.2564925],
          [101.9780679, 3.258232],
          [101.9751293, 3.260361],
          [101.9747133, 3.2632688],
          [101.9750513, 3.2711615],
          [101.9729709, 3.2733942],
          [101.9711506, 3.2718624],
          [101.9685501, 3.2720442],
          [101.9656115, 3.2733942],
          [101.962751, 3.2727192],
          [101.957654, 3.2719663],
          [101.9544034, 3.2704085],
          [101.9528691, 3.2722778],
          [101.9511788, 3.2779117],
          [101.9498266, 3.2781454],
          [101.9472261, 3.283234],
          [101.9439755, 3.283182],
          [101.9408289, 3.2860379],
          [101.9391385, 3.2861937],
          [101.9380463, 3.2877773],
          [101.936304, 3.2869466],
          [101.9341196, 3.2833638],
          [101.9339636, 3.2758607],
          [101.9332094, 3.2730827],
          [101.931155, 3.2710836],
          [101.9304269, 3.2688508],
          [101.9272543, 3.2672411],
          [101.9247318, 3.2669815],
          [101.9194788, 3.2621524],
          [101.9163582, 3.258232],
          [101.9150216, 3.2567889],
          [101.9126965, 3.2572096],
          [101.911961, 3.2605141],
          [101.9099015, 3.2621297],
          [101.9066652, 3.2624969],
          [101.9040398, 3.2645596],
          [101.8968826, 3.2641858],
          [101.893205, 3.258972],
          [101.888424, 3.2590455],
          [101.8785068, 3.2704094],
          [101.8694474, 3.2685918],
          [101.8610623, 3.2721166],
          [101.8571673, 3.2740104],
          [101.8545424, 3.2805992],
          [101.8518162, 3.2844193],
          [101.8474929, 3.2850166],
          [101.8442384, 3.2843728],
          [101.8377646, 3.2876407],
          [101.8294486, 3.2913647],
          [101.822579, 3.2988528],
          [101.8165217, 3.3053489],
          [101.8132693, 3.310972],
          [101.8098062, 3.3168951],
          [101.8055834, 3.3239897],
          [101.7990658, 3.3318843],
          [101.7939652, 3.3336876],
          [101.7875011, 3.334527],
          [101.783132, 3.3368151],
          [101.7810075, 3.33942],
          [101.7811682, 3.3450159],
          [101.781989, 3.3486851],
          [101.7812733, 3.352973],
          [101.7814611, 3.3554261],
          [101.7810547, 3.3560745],
          [101.7806387, 3.3567668],
          [101.7776767, 3.3579701],
          [101.7729939, 3.3602554],
          [101.76652, 3.3649275],
          [101.7593359, 3.3715778],
          [101.7610587, 3.3763168],
          [101.7614157, 3.3788493],
          [101.7639526, 3.3804782],
          [101.7640419, 3.3827817],
          [101.7662983, 3.3860014],
          [101.7662983, 3.3880248],
          [101.7658266, 3.3913591],
          [101.7651892, 3.3971367],
          [101.7654442, 3.3985493],
          [101.7684018, 3.3994783],
          [101.7704798, 3.4007381],
          [101.7755281, 3.4024943],
          [101.7766373, 3.4046577],
          [101.7777719, 3.4073683],
          [101.7784985, 3.4082082],
          [101.7799391, 3.4074956],
          [101.7817494, 3.4098753],
          [101.7818386, 3.4102953],
          [101.782374, 3.4119751],
          [101.7831134, 3.4132476],
          [101.7851532, 3.415691],
          [101.7893091, 3.4185797],
          [101.7925085, 3.4232204],
          [101.7932252, 3.4245432],
          [101.7899099, 3.4283553],
          [101.7893401, 3.4288277],
          [101.7854225, 3.4314349],
          [101.7852185, 3.4346417],
          [101.7857795, 3.4370341],
          [101.7844536, 3.4375431],
          [101.7842496, 3.4420734],
          [101.781598, 3.445382],
          [101.7791503, 3.4507775],
          [101.7789463, 3.4602959],
          [101.7777735, 3.4622302],
          [101.7773655, 3.4647752],
          [101.7747139, 3.4691017],
          [101.7703047, 3.4760643],
          [101.768214, 3.4777949],
          [101.768061, 3.481307],
          [101.7676021, 3.4849717],
          [101.7658173, 3.4900616],
          [101.7636756, 3.4932682],
          [101.7628597, 3.4954569],
          [101.7639305, 3.4991725],
          [101.7629617, 3.5022264],
          [101.7634206, 3.5032444],
          [101.7633944, 3.5060305],
          [101.763178, 3.5081899],
          [101.7653415, 3.5108532],
          [101.765443, 3.511839],
          [101.7670238, 3.5133151],
          [101.7687066, 3.5135186],
          [101.7706444, 3.5147911],
          [101.7714093, 3.5164707],
          [101.7719192, 3.5187102],
          [101.7729391, 3.5211024],
          [101.7749788, 3.5248179],
          [101.7777835, 3.5279735],
          [101.7778855, 3.5331649],
          [101.7781914, 3.5347936],
          [101.7789563, 3.5364223],
          [101.781761, 3.5391707],
          [101.7820159, 3.5413084],
          [101.7834437, 3.5420718],
          [101.7856875, 3.5438023],
          [101.7860444, 3.5460926],
          [101.7866089, 3.5466005],
          [101.7872683, 3.5469578],
          [101.7885431, 3.5456345],
          [101.7914497, 3.5459908],
          [101.7942034, 3.5475686],
          [101.7954272, 3.546856],
          [101.7997617, 3.5468051],
          [101.800709, 3.548933],
          [101.8010365, 3.5491972],
          [101.8027703, 3.5506732],
          [101.8038921, 3.5496044],
          [101.8066458, 3.5494517],
          [101.8069904, 3.557507],
          [101.808, 3.5620416],
          [101.8103798, 3.5649926],
          [101.8091539, 3.5685914],
          [101.8108846, 3.5702468],
          [101.8101635, 3.5724781],
          [101.8081442, 3.5758609],
          [101.8095144, 3.5783081],
          [101.8085769, 3.5852897],
          [101.8118942, 3.5865132],
          [101.8132644, 3.5933508],
          [101.8152116, 3.5942864],
          [101.8165097, 3.6031392],
          [101.8158606, 3.6050105],
          [101.8114616, 3.6048665],
          [101.8097308, 3.6074576],
          [101.811101, 3.611488],
          [101.8090817, 3.6138631],
          [101.8017259, 3.6132873],
          [101.7996346, 3.6175337],
          [101.7981201, 3.6234354],
          [101.7973269, 3.6272499],
          [101.7908365, 3.6278256],
          [101.7875191, 3.6299128],
          [101.7861489, 3.6322878],
          [101.7842018, 3.6350947],
          [101.7858605, 3.6371818],
          [101.7859326, 3.6408523],
          [101.784346, 3.6450266],
          [101.7858605, 3.6492008],
          [101.7842739, 3.6516478],
          [101.7810287, 3.6517917],
          [101.7779999, 3.6553182],
          [101.7736008, 3.6614356],
          [101.7706441, 3.6635946],
          [101.7684806, 3.6659696],
          [101.7720143, 3.6712952],
          [101.7690575, 3.6744618],
          [101.7679037, 3.6775564],
          [101.7685527, 3.6810828],
          [101.7677594, 3.6820903],
          [101.765092, 3.68113],
          [101.7628373, 3.682697],
          [101.7626533, 3.682668],
          [101.760882, 3.6823889],
          [101.7605424, 3.6823354],
          [101.7590125, 3.6834604],
          [101.7583471, 3.6856029],
          [101.7570944, 3.6855461],
          [101.7521408, 3.690802],
          [101.7511796, 3.6907738],
          [101.7502049, 3.6907452],
          [101.7504611, 3.6920805],
          [101.7453317, 3.6963919],
          [101.741403, 3.6959941],
          [101.7393532, 3.7015625],
          [101.7381006, 3.7041762],
          [101.7383222, 3.7052187],
          [101.7384991, 3.7060512],
          [101.739524, 3.7067899],
          [101.7411183, 3.7082104],
          [101.7411752, 3.7094036],
          [101.7375881, 3.7109377],
          [101.7348551, 3.7118468],
          [101.7325206, 3.710824],
          [101.7311956, 3.7117805],
          [101.7301673, 3.7125229],
          [101.7298445, 3.7127559],
          [101.7278364, 3.7114639],
          [101.7277096, 3.7113823],
          [101.7267643, 3.7108063],
          [101.7255172, 3.7099718],
          [101.7209052, 3.7073581],
          [101.7165209, 3.707699],
          [101.7162362, 3.7094036],
          [101.7121936, 3.7116195],
          [101.709005, 3.7109945],
          [101.7040514, 3.7092899],
          [101.6983006, 3.7105968],
          [101.6932331, 3.7080967],
          [101.6901584, 3.7088354],
          [101.685888, 3.7097445],
          [101.6851478, 3.7138923],
          [101.6820732, 3.7196878],
          [101.6737601, 3.719631],
          [101.6715395, 3.7200287],
          [101.6692051, 3.7234946],
          [101.6665859, 3.7276423],
          [101.6626002, 3.7284378],
          [101.6582729, 3.7299719],
          [101.6543441, 3.7373581],
          [101.6505293, 3.7396308],
          [101.6393693, 3.7527555],
          [101.6278108, 3.7698002],
          [101.6265582, 3.768948],
          [101.6252486, 3.7691753],
          [101.6255333, 3.7712206],
          [101.6247931, 3.7724706],
          [101.6097868, 3.7931864],
          [101.5895758, 3.7949554],
          [101.5881575, 3.804508],
          [101.5941853, 3.8101688],
          [101.5938307, 3.8214902],
          [101.5870937, 3.833519],
          [101.5941853, 3.8437787],
          [101.5973765, 3.8752646],
          [101.5718468, 3.8964905],
          [101.5729106, 3.9361106],
          [101.5714922, 3.9750214],
          [101.5672373, 3.9842182],
          [101.5693648, 3.998367],
          [101.5587274, 4.0139303],
          [101.5576637, 4.0376285],
          [101.572556, 4.0691071],
          [101.5718468, 4.0821934],
          [101.5576637, 4.0966941],
          [101.5562453, 4.1062432],
          [101.5466717, 4.1101335],
          [101.5310702, 4.1253411],
          [101.5140504, 4.1168532],
          [101.5023493, 4.1313533],
          [101.5019947, 4.1476214],
          [101.4924211, 4.1603527],
          [101.5066043, 4.1971309],
          [101.5037676, 4.2070324],
          [101.4615727, 4.2133976],
          [101.4502262, 4.2278959],
          [101.4512899, 4.2370898],
          [101.462991, 4.2473445],
          [101.4622819, 4.2519413],
          [101.4519991, 4.2685605],
          [101.4484533, 4.3141729],
          [101.4243419, 4.3413977],
          [101.44278, 4.3735711],
          [101.4356885, 4.3873593],
          [101.4101588, 4.3947836],
          [101.4090355, 4.3954624],
          [101.4074119, 4.3964435],
          [101.3867565, 4.4089249],
          [101.3672547, 4.3947836],
          [101.3580356, 4.3972583],
          [101.3537807, 4.4131673],
          [101.3346334, 4.4329646],
          [101.3583902, 4.4669015],
          [101.3587448, 4.4842229],
          [101.3853382, 4.5022509],
          [101.3775375, 4.5223992],
          [101.3576811, 4.5344173],
          [101.3509441, 4.5715306],
          [101.3598085, 4.5895564],
          [101.3711551, 4.6012199],
          [101.368673, 4.6072283],
          [101.3739276, 4.614083],
          [101.3744201, 4.6142299],
          [101.3748552, 4.6144394],
          [101.3752907, 4.614626],
          [101.3757858, 4.6143294],
          [101.3761435, 4.6139861],
          [101.376547, 4.613666],
          [101.3770651, 4.6133696],
          [101.3775718, 4.6130845],
          [101.3781472, 4.6127769],
          [101.3786657, 4.6124344],
          [101.3789997, 4.6122521],
          [101.379402, 4.6121621],
          [101.3803441, 4.6120292],
          [101.3807231, 4.6119852],
          [101.3813206, 4.6118964],
          [101.3817581, 4.6116801],
          [101.382241, 4.611533],
          [101.3829299, 4.6115368],
          [101.3835614, 4.6115287],
          [101.3838155, 4.6112194],
          [101.3840246, 4.6107371],
          [101.3841767, 4.6101738],
          [101.3843854, 4.6098182],
          [101.3845585, 4.6096235],
          [101.384766, 4.609475],
          [101.3850762, 4.6093961],
          [101.3855579, 4.6095253],
          [101.38621, 4.6099777],
          [101.3867238, 4.6105101],
          [101.387055, 4.6108572],
          [101.3875588, 4.6111362],
          [101.3879591, 4.6114261],
          [101.3884401, 4.6116705],
          [101.3890479, 4.611835],
          [101.3898391, 4.6120004],
          [101.3904816, 4.6121536],
          [101.3908836, 4.6120982],
          [101.3913084, 4.6121236],
          [101.3919273, 4.6122766],
          [101.3924319, 4.6124404],
          [101.3930623, 4.6125936],
          [101.393785, 4.6127701],
          [101.3945075, 4.6129583],
          [101.3951604, 4.6132036],
          [101.3958598, 4.613403],
          [101.396536, 4.613637],
          [101.3971774, 4.6139628],
          [101.3978765, 4.6142197],
          [101.3982994, 4.6145904],
          [101.3987681, 4.6150074],
          [101.3991672, 4.615516],
          [101.3993834, 4.6159316],
          [101.3996905, 4.6164972],
          [101.3999292, 4.6169705],
          [101.4001216, 4.6175126],
          [101.4004635, 4.6179979],
          [101.4008524, 4.6183108],
          [101.40138, 4.6183943],
          [101.4018399, 4.6183047],
          [101.402415, 4.6181121],
          [101.4027948, 4.6178724],
          [101.4032781, 4.6176909],
          [101.4039456, 4.6173837],
          [101.4045198, 4.6173177],
          [101.4050489, 4.6171709],
          [101.4054177, 4.6168622],
          [101.4058105, 4.6163808],
          [101.4060769, 4.6159565],
          [101.4062859, 4.615497],
          [101.4065748, 4.6150958],
          [101.4069783, 4.6147757],
          [101.4074383, 4.6146056],
          [101.4079786, 4.6144703],
          [101.4084493, 4.6144844],
          [101.4091033, 4.6146145],
          [101.4098253, 4.6148027],
          [101.4103533, 4.6148515],
          [101.4108935, 4.6147624],
          [101.4114219, 4.6147077],
          [101.4120546, 4.6144694],
          [101.412481, 4.6141724],
          [101.4128276, 4.6137484],
          [101.4131979, 4.6131633],
          [101.4135709, 4.6120488],
          [101.4138044, 4.6112789],
          [101.4143233, 4.6108097],
          [101.4151409, 4.6103536],
          [101.415808, 4.6101156],
          [101.4164866, 4.6098315],
          [101.4170738, 4.6095585],
          [101.4183502, 4.6089174],
          [101.4191168, 4.6083869],
          [101.4219917, 4.6081282],
          [101.4228161, 4.6078965],
          [101.4256201, 4.6064883],
          [101.4262033, 4.6061078],
          [101.427641, 4.6031961],
          [101.4280522, 4.6026205],
          [101.4297078, 4.5999614],
          [101.4301303, 4.5991902],
          [101.4321187, 4.5968294],
          [101.4322088, 4.5959785],
          [101.4330197, 4.5947812],
          [101.4345541, 4.594502],
          [101.4354359, 4.5943278],
          [101.4364774, 4.5939118],
          [101.4375397, 4.5923001],
          [101.4379504, 4.5915865],
          [101.439722, 4.5896284],
          [101.4402947, 4.5895698],
          [101.4409589, 4.5893845],
          [101.4435226, 4.588195],
          [101.4438313, 4.5878725],
          [101.4440013, 4.5870673],
          [101.4443533, 4.5854916],
          [101.4449235, 4.5842602],
          [101.4451167, 4.5834896],
          [101.4451842, 4.5829032],
          [101.4449763, 4.5820184],
          [101.4460612, 4.580418],
          [101.4461168, 4.5796593],
          [101.4458167, 4.5785332],
          [101.4469147, 4.5777261],
          [101.4475324, 4.5772534],
          [101.4480009, 4.5767122],
          [101.4485611, 4.5760787],
          [101.4505527, 4.5753388],
          [101.4512506, 4.5748773],
          [101.451823, 4.5747842],
          [101.4535207, 4.5757234],
          [101.4541166, 4.5759062],
          [101.4549756, 4.5758124],
          [101.4564179, 4.575269],
          [101.4578828, 4.5747026],
          [101.4585583, 4.5744483],
          [101.4599669, 4.5743419],
          [101.4613997, 4.5746608],
          [101.4620876, 4.5749237],
          [101.4638659, 4.5761731],
          [101.4641073, 4.57669],
          [101.464395, 4.5772411],
          [101.4658379, 4.5769623],
          [101.4667162, 4.5752473],
          [101.4672881, 4.5746944],
          [101.4685002, 4.5737261],
          [101.4699202, 4.5735046],
          [101.4707107, 4.57348],
          [101.4724522, 4.5735913],
          [101.4733585, 4.5742677],
          [101.4741266, 4.5745879],
          [101.4763032, 4.5746638],
          [101.4765459, 4.575606],
          [101.4768225, 4.5763527],
          [101.4769859, 4.5778124],
          [101.476336, 4.5792165],
          [101.4759708, 4.580022],
          [101.4758034, 4.5820803],
          [101.4760912, 4.5827579],
          [101.4762877, 4.5835968],
          [101.4769447, 4.5854463],
          [101.4773706, 4.5865262],
          [101.4775097, 4.5872386],
          [101.4775924, 4.5884801],
          [101.4768837, 4.5891828],
          [101.4767133, 4.5898615],
          [101.4774472, 4.5902623],
          [101.4781467, 4.5904907],
          [101.4782746, 4.5914908],
          [101.4781405, 4.5930316],
          [101.4779129, 4.5938023],
          [101.4778929, 4.5952164],
          [101.4789944, 4.59595],
          [101.4794994, 4.5963513],
          [101.4797411, 4.5969601],
          [101.4801798, 4.5986147],
          [101.4803206, 4.6001435],
          [101.4806311, 4.6007291],
          [101.4809185, 4.6012113],
          [101.4818119, 4.601152],
          [101.4833831, 4.6018959],
          [101.4841051, 4.6021473],
          [101.4855712, 4.6019948],
          [101.4868065, 4.6009229],
          [101.4873434, 4.6003124],
          [101.4889456, 4.5994468],
          [101.4899324, 4.6002149],
          [101.4907925, 4.6006499],
          [101.4927417, 4.6014505],
          [101.494059, 4.6013904],
          [101.4948494, 4.601423],
          [101.4954359, 4.602468],
          [101.4956915, 4.6041345],
          [101.496071, 4.604881],
          [101.4966686, 4.6057075],
          [101.4978267, 4.6062107],
          [101.498217, 4.6066238],
          [101.4986766, 4.6072092],
          [101.4991245, 4.6077026],
          [101.4997673, 4.6082876],
          [101.5005484, 4.6093092],
          [101.5006622, 4.6095886],
          [101.501165, 4.6099922],
          [101.5013792, 4.6111768],
          [101.5013889, 4.6117975],
          [101.5012831, 4.6126133],
          [101.5011413, 4.6140957],
          [101.5018674, 4.6127414],
          [101.5023046, 4.6121106],
          [101.5029256, 4.6113539],
          [101.5038548, 4.6111038],
          [101.5043264, 4.6105306],
          [101.5048099, 4.6097849],
          [101.505225, 4.6092451],
          [101.5062804, 4.6085761],
          [101.5068191, 4.6080116],
          [101.5073011, 4.6078381],
          [101.5085539, 4.6088937],
          [101.5098285, 4.6090409],
          [101.5106543, 4.6086942],
          [101.511435, 4.6085432],
          [101.513191, 4.6082524],
          [101.5144306, 4.6081119],
          [101.5152342, 4.6079494],
          [101.5166347, 4.6080503],
          [101.51775, 4.6093762],
          [101.5182161, 4.6105961],
          [101.5184097, 4.6109644],
          [101.5186946, 4.6115975],
          [101.5195386, 4.612968],
          [101.5207542, 4.6126958],
          [101.5214533, 4.6126289],
          [101.5227939, 4.6127826],
          [101.5232426, 4.6121286],
          [101.5232787, 4.6116],
          [101.5233613, 4.6107381],
          [101.5233068, 4.6093484],
          [101.5239931, 4.607714],
          [101.5242218, 4.607012],
          [101.5245423, 4.6062754],
          [101.5250126, 4.60601],
          [101.5260797, 4.6056859],
          [101.527009, 4.6053276],
          [101.5275367, 4.6050391],
          [101.5282371, 4.6049342],
          [101.5304412, 4.604861],
          [101.5318301, 4.6047548],
          [101.5322891, 4.6046388],
          [101.533127, 4.6046027],
          [101.533631, 4.6037852],
          [101.5341107, 4.6019902],
          [101.5344887, 4.6013225],
          [101.5346942, 4.600586],
          [101.5356584, 4.6004002],
          [101.5367481, 4.5997656],
          [101.5371609, 4.5994082],
          [101.5379292, 4.5988892],
          [101.538996, 4.59822],
          [101.5401772, 4.5974933],
          [101.5406472, 4.5968827],
          [101.5418971, 4.5958683],
          [101.5429292, 4.5952222],
          [101.5434909, 4.5946117],
          [101.5442583, 4.593414],
          [101.5448186, 4.5918489],
          [101.5449211, 4.5912276],
          [101.5455365, 4.5880638],
          [101.545719, 4.5873274],
          [101.5457851, 4.5854298],
          [101.5464499, 4.5845774],
          [101.5466099, 4.5840941],
          [101.5470681, 4.5834376],
          [101.54756, 4.5822292],
          [101.5478674, 4.5804345],
          [101.5480497, 4.5795831],
          [101.548863, 4.5782704],
          [101.5499407, 4.5772102],
          [101.5506742, 4.5764153],
          [101.5512481, 4.5762762],
          [101.5521896, 4.5764813],
          [101.5532923, 4.5769507],
          [101.553717, 4.5769843],
          [101.5544982, 4.5772588],
          [101.5553245, 4.5770731],
          [101.5565187, 4.5773238],
          [101.557071, 4.5782771],
          [101.5573473, 4.5787021],
          [101.5581284, 4.5791721],
          [101.5601609, 4.5793635],
          [101.5612627, 4.5793729],
          [101.5621125, 4.5795322],
          [101.5631689, 4.5797141],
          [101.5638462, 4.5797473],
          [101.5651543, 4.5792271],
          [101.5658886, 4.5789266],
          [101.5670701, 4.5784643],
          [101.567907, 4.5777266],
          [101.5683084, 4.5772657],
          [101.5688699, 4.5767586],
          [101.5694773, 4.5759753],
          [101.5703951, 4.575594],
          [101.57152, 4.5753847],
          [101.5721049, 4.5751765],
          [101.5727815, 4.5746116],
          [101.5734566, 4.5731842],
          [101.5746946, 4.5718476],
          [101.5752448, 4.5712945],
          [101.575611, 4.5705462],
          [101.5751508, 4.5698801],
          [101.5756314, 4.5687636],
          [101.5762276, 4.5683254],
          [101.5767319, 4.5677609],
          [101.5776247, 4.566057],
          [101.5781629, 4.565021],
          [101.5785518, 4.5642496],
          [101.579079, 4.563593],
          [101.5802835, 4.5630961],
          [101.5819829, 4.5632191],
          [101.5828324, 4.5633208],
          [101.584704, 4.5635356],
          [101.5854721, 4.56289],
          [101.5856199, 4.5620387],
          [101.5854927, 4.5612109],
          [101.5854198, 4.558635],
          [101.5854651, 4.5581634],
          [101.5856822, 4.5574728],
          [101.5864036, 4.5563329],
          [101.5872748, 4.5556065],
          [101.5877448, 4.5552145],
          [101.588456, 4.5547646],
          [101.5896712, 4.5535891],
          [101.589934, 4.552795],
          [101.5912775, 4.5531258],
          [101.5917496, 4.5540219],
          [101.5919918, 4.5548724],
          [101.5922232, 4.556091],
          [101.5927185, 4.557079],
          [101.5930408, 4.5577913],
          [101.5934439, 4.5585612],
          [101.5942938, 4.5589159],
          [101.5954307, 4.5591666],
          [101.5973486, 4.5597032],
          [101.5980949, 4.5598397],
          [101.6000127, 4.5603533],
          [101.6019642, 4.5601998],
          [101.6026638, 4.5598534],
          [101.6051768, 4.55925],
          [101.6063722, 4.5601217],
          [101.6071306, 4.5606377],
          [101.6090031, 4.5616228],
          [101.6103916, 4.56116],
          [101.611493, 4.5608818],
          [101.6137193, 4.5603941],
          [101.6144763, 4.5599439],
          [101.6143946, 4.5589781],
          [101.6144392, 4.5582305],
          [101.6146793, 4.5575284],
          [101.6156072, 4.5563649],
          [101.6166382, 4.5549367],
          [101.6169471, 4.5541885],
          [101.6172539, 4.5521523],
          [101.6172512, 4.5504617],
          [101.6176981, 4.5498858],
          [101.6188317, 4.5480319],
          [101.6195075, 4.5471104],
          [101.6199548, 4.5468449],
          [101.6206664, 4.5466594],
          [101.62124, 4.5465317],
          [101.6221925, 4.5464263],
          [101.6235125, 4.5463085],
          [101.6240641, 4.5466408],
          [101.6245925, 4.5469274],
          [101.6249609, 4.547651],
          [101.6257437, 4.5490639],
          [101.6263314, 4.5504313],
          [101.6269979, 4.5508439],
          [101.6276413, 4.5513602],
          [101.6291238, 4.5523346],
          [101.630848, 4.553711],
          [101.6313993, 4.5540089],
          [101.6331922, 4.5553968],
          [101.6341915, 4.5557627],
          [101.6345139, 4.5563256],
          [101.6352035, 4.5568531],
          [101.6354345, 4.5578647],
          [101.635964, 4.558795],
          [101.6366307, 4.5593113],
          [101.6371128, 4.5593217],
          [101.6380314, 4.5594349],
          [101.6399718, 4.5597067],
          [101.6408902, 4.5596013],
          [101.6418885, 4.5592772],
          [101.6426571, 4.5590801],
          [101.6434705, 4.5580778],
          [101.6441922, 4.5570987],
          [101.6447088, 4.5570401],
          [101.6455927, 4.5571532],
          [101.6464423, 4.55714],
          [101.6491403, 4.5573528],
          [101.6500013, 4.5573854],
          [101.651562, 4.5569796],
          [101.6524703, 4.5579898],
          [101.6529418, 4.5585294],
          [101.653448, 4.5591376],
          [101.6542756, 4.5598951],
          [101.6558722, 4.5604551],
          [101.6568141, 4.5609017],
          [101.6573661, 4.5614755],
          [101.6597443, 4.5626895],
          [101.6611004, 4.5636183],
          [101.6616641, 4.5643415],
          [101.6621609, 4.5663991],
          [101.6626563, 4.5673871],
          [101.6631507, 4.5678806],
          [101.6642539, 4.5685914],
          [101.6653355, 4.5701071],
          [101.665715, 4.5705893],
          [101.666198, 4.5711057],
          [101.6673579, 4.5713793],
          [101.6686201, 4.5711581],
          [101.6681277, 4.5719643],
          [101.6676125, 4.5727818],
          [101.6672239, 4.5737142],
          [101.667513, 4.5751052],
          [101.6677205, 4.5756568],
          [101.6677562, 4.5764272],
          [101.6677581, 4.5776578],
          [101.6681623, 4.579175],
          [101.668187, 4.5802446],
          [101.6682024, 4.5825217],
          [101.6684447, 4.5834067],
          [101.6689504, 4.5837621],
          [101.6694449, 4.5842785],
          [101.6703075, 4.5852428],
          [101.671125, 4.5867936],
          [101.6712063, 4.5875065],
          [101.6715293, 4.5883223],
          [101.6718987, 4.5895407],
          [101.6724518, 4.5908506],
          [101.6726249, 4.5914597],
          [101.6727638, 4.5921495],
          [101.6733055, 4.5934248],
          [101.6739967, 4.594953],
          [101.6746052, 4.5949862],
          [101.6754085, 4.5947889],
          [101.6783466, 4.5942881],
          [101.6790012, 4.5945052],
          [101.6809319, 4.5958696],
          [101.6821951, 4.5960393],
          [101.6828958, 4.5963253],
          [101.6836321, 4.5971519],
          [101.6861246, 4.5980665],
          [101.6869977, 4.5983062],
          [101.6887213, 4.5994409],
          [101.6900542, 4.6000822],
          [101.6907437, 4.6004486],
          [101.6924546, 4.6006635],
          [101.6936955, 4.6013164],
          [101.694212, 4.6012692],
          [101.6950494, 4.6010374],
          [101.6956578, 4.6009441],
          [101.6977885, 4.6017327],
          [101.6990341, 4.6016842],
          [101.7003313, 4.6016239],
          [101.7021463, 4.6022755],
          [101.7030076, 4.6024577],
          [101.7048692, 4.6035461],
          [101.7058924, 4.6043604],
          [101.7067193, 4.604646],
          [101.708694, 4.6047338],
          [101.7099802, 4.6048344],
          [101.7107497, 4.6051088],
          [101.7120606, 4.6063135],
          [101.712981, 4.6076111],
          [101.7130857, 4.6084964],
          [101.7132935, 4.609163],
          [101.7139371, 4.6096217],
          [101.7155204, 4.6089281],
          [101.7161391, 4.6081792],
          [101.7179734, 4.6067719],
          [101.7184457, 4.6076795],
          [101.7193667, 4.6091036],
          [101.7203894, 4.6097223],
          [101.721009, 4.60956],
          [101.7218123, 4.6093052],
          [101.7230635, 4.6093024],
          [101.7238104, 4.6097263],
          [101.7245047, 4.6098038],
          [101.7255928, 4.6101402],
          [101.7263717, 4.6103033],
          [101.7269664, 4.6107187],
          [101.727079, 4.6115123],
          [101.7267665, 4.6125919],
          [101.7263873, 4.6130162],
          [101.7259618, 4.6136358],
          [101.7254655, 4.6148643],
          [101.7248328, 4.6158282],
          [101.7248087, 4.6162075],
          [101.7251965, 4.6169327],
          [101.725699, 4.6176238],
          [101.725455, 4.6188302],
          [101.7254987, 4.6196809],
          [101.7257261, 4.6203252],
          [101.7258595, 4.6219235],
          [101.7266152, 4.6221785],
          [101.7274862, 4.6221349],
          [101.7282427, 4.6221139],
          [101.7298119, 4.6224401],
          [101.7307387, 4.6230749],
          [101.7316094, 4.6232037],
          [101.73255, 4.6229074],
          [101.7333437, 4.62176],
          [101.7337225, 4.6214623],
          [101.7341025, 4.6208197],
          [101.7342912, 4.6188429],
          [101.7349002, 4.6182698],
          [101.7356001, 4.6178809],
          [101.7367254, 4.6171369],
          [101.7378741, 4.6161285],
          [101.7381742, 4.615267],
          [101.7392545, 4.6141436],
          [101.7402185, 4.6135944],
          [101.7409066, 4.6133663],
          [101.7415147, 4.6131497],
          [101.7424799, 4.6122097],
          [101.7435913, 4.6122587],
          [101.7441868, 4.6124672],
          [101.7454813, 4.6127007],
          [101.7466267, 4.6128647],
          [101.7472115, 4.6128433],
          [101.7480024, 4.612696],
          [101.7489671, 4.611894],
          [101.7499331, 4.6106666],
          [101.7508161, 4.6104162],
          [101.7521224, 4.6104657],
          [101.7538981, 4.6108154],
          [101.7545161, 4.6110816],
          [101.7550293, 4.6120715],
          [101.7548656, 4.613278],
          [101.7544401, 4.6138516],
          [101.7543242, 4.6143685],
          [101.7539865, 4.6164253],
          [101.7544886, 4.6173232],
          [101.7550828, 4.6179226],
          [101.7555407, 4.6181997],
          [101.7558368, 4.6188558],
          [101.7542651, 4.6194607],
          [101.7537021, 4.6199994],
          [101.7532516, 4.6214006],
          [101.7537419, 4.6222986],
          [101.7545208, 4.6224962],
          [101.7558141, 4.6231894],
          [101.7571774, 4.6234],
          [101.7574507, 4.6240445],
          [101.758124, 4.6251038],
          [101.7596938, 4.6252576],
          [101.7603473, 4.6251788],
          [101.7620211, 4.624906],
          [101.7629294, 4.6238064],
          [101.763435, 4.6233479],
          [101.764469, 4.6223622],
          [101.7647177, 4.6236387],
          [101.76483, 4.6245127],
          [101.7649747, 4.626157],
          [101.7652803, 4.6276407],
          [101.7654733, 4.6283653],
          [101.7662351, 4.6307124],
          [101.7664743, 4.6312649],
          [101.7668507, 4.632013],
          [101.7678985, 4.6345102],
          [101.7679072, 4.6355909],
          [101.7678119, 4.6370045],
          [101.7676952, 4.6377629],
          [101.7675097, 4.6387166],
          [101.7674844, 4.6395555],
          [101.7675864, 4.6400731],
          [101.7683029, 4.6421557],
          [101.7684496, 4.6430068],
          [101.7691771, 4.6453537],
          [101.7690378, 4.6460546],
          [101.7689105, 4.6465715],
          [101.7688737, 4.6474221],
          [101.7686056, 4.649157],
          [101.7687182, 4.650031],
          [101.7688649, 4.6508705],
          [101.7685263, 4.6517739],
          [101.7685142, 4.6534905],
          [101.7687763, 4.6540889],
          [101.7701377, 4.6550927],
          [101.7702143, 4.6564838],
          [101.7703267, 4.6572658],
          [101.7709007, 4.658156],
          [101.7715543, 4.6581783],
          [101.772518, 4.6581429],
          [101.7727132, 4.6588098],
          [101.772966, 4.6594767],
          [101.7732071, 4.6599478],
          [101.7741258, 4.6613616],
          [101.7739543, 4.6621438],
          [101.7737482, 4.6626846],
          [101.773806, 4.6635125],
          [101.7740359, 4.6645473],
          [101.7743003, 4.6655707],
          [101.7743352, 4.6662951],
          [101.7741868, 4.6674223],
          [101.7741414, 4.66817],
          [101.7740501, 4.6691131],
          [101.7737293, 4.6696424],
          [101.7735003, 4.6702982],
          [101.7734317, 4.6709653],
          [101.7735355, 4.6715402],
          [101.7736851, 4.6722416],
          [101.7734789, 4.6728629],
          [101.7731462, 4.6732082],
          [101.7726419, 4.6735077],
          [101.7719305, 4.6737269],
          [101.7715179, 4.6740607],
          [101.771243, 4.6745899],
          [101.7711169, 4.6750502],
          [101.7712092, 4.6756251],
          [101.7711407, 4.6763036],
          [101.7709589, 4.6765548],
          [101.7708371, 4.6771424],
          [101.7701586, 4.6779452],
          [101.769423, 4.6788513],
          [101.7696504, 4.6794495],
          [101.7698319, 4.6801513],
          [101.7696685, 4.6813463],
          [101.7704001, 4.6820726],
          [101.7704214, 4.6827852],
          [101.7703462, 4.6852105],
          [101.7702873, 4.6857852],
          [101.7706977, 4.6866943],
          [101.7709177, 4.6873215],
          [101.771572, 4.6877349],
          [101.7717331, 4.6883787],
          [101.7718479, 4.6891032],
          [101.7718141, 4.6897817],
          [101.7717455, 4.6904719],
          [101.7716658, 4.691116],
          [101.7717005, 4.6916104],
          [101.7720906, 4.6917712],
          [101.7722283, 4.6920929],
          [101.7719074, 4.6923809],
          [101.7714372, 4.6925308],
          [101.7708637, 4.6928303],
          [101.7708047, 4.6940516],
          [101.7705378, 4.6953038],
          [101.7703064, 4.6961999],
          [101.7703847, 4.6968668],
          [101.7707256, 4.6980287],
          [101.7708328, 4.6990177],
          [101.7713383, 4.7001557],
          [101.771614, 4.7007535],
          [101.7719701, 4.7013283],
          [101.7724178, 4.7019718],
          [101.7727164, 4.7023971],
          [101.7732101, 4.7028911],
          [101.7738524, 4.7030746],
          [101.7745522, 4.703051],
          [101.7752752, 4.7030847],
          [101.7759867, 4.7036016],
          [101.7764918, 4.7043372],
          [101.7766988, 4.7049466],
          [101.7769399, 4.7054639],
          [101.7771581, 4.7059811],
          [101.7772273, 4.7063377],
          [101.777021, 4.7066713],
          [101.7766655, 4.7067522],
          [101.7761721, 4.7068101],
          [101.7755527, 4.7070983],
          [101.7751744, 4.707432],
          [101.7749225, 4.7084559],
          [101.774728, 4.7092957],
          [101.7744992, 4.7100433],
          [101.7744992, 4.7105149],
          [101.7745112, 4.7112624],
          [101.7747181, 4.7119293],
          [101.7750627, 4.71255],
          [101.7753959, 4.7131246],
          [101.7755684, 4.7138836],
          [101.7754884, 4.7144932],
          [101.775328, 4.7152063],
          [101.7752137, 4.7156895],
          [101.7751337, 4.7162991],
          [101.7751457, 4.7170696],
          [101.7752609, 4.717656],
          [101.7751463, 4.7181737],
          [101.774906, 4.7188754],
          [101.7747801, 4.7194391],
          [101.7744476, 4.7200719],
          [101.7742644, 4.7206701],
          [101.7741733, 4.7216363],
          [101.7740933, 4.7222459],
          [101.7739907, 4.723281],
          [101.7739336, 4.7239826],
          [101.7738539, 4.7246612],
          [101.773843, 4.7257883],
          [101.7739696, 4.7265357],
          [101.7740965, 4.7274097],
          [101.7742805, 4.7281225],
          [101.7742923, 4.7287091],
          [101.7742813, 4.7295026],
          [101.7742127, 4.7301121],
          [101.7741902, 4.7309862],
          [101.7741907, 4.7317453],
          [101.7740307, 4.732907],
          [101.773905, 4.7337582],
          [101.7738826, 4.7345747],
          [101.7738718, 4.7355292],
          [101.7739296, 4.7363113],
          [101.7740677, 4.7370702],
          [101.7740453, 4.7377717],
          [101.7740569, 4.7385307],
          [101.7740118, 4.7394278],
          [101.7739432, 4.740049],
          [101.7738404, 4.7409231],
          [101.7739671, 4.7416474],
          [101.7739562, 4.7423376],
          [101.7740139, 4.7432],
          [101.7742438, 4.7438668],
          [101.7743475, 4.7445567],
          [101.7746116, 4.7451546],
          [101.775117, 4.7457981],
          [101.775576, 4.7462577],
          [101.7756107, 4.7468443],
          [101.7753475, 4.747592],
          [101.7750614, 4.7487308],
          [101.7749586, 4.7495934],
          [101.7749474, 4.750249],
          [101.7752578, 4.7510307],
          [101.775522, 4.7517205],
          [101.775855, 4.7525598],
          [101.7761539, 4.7534795],
          [101.7766706, 4.7543991],
          [101.7771988, 4.7548587],
          [101.7777955, 4.7551917],
          [101.7782547, 4.7555132],
          [101.778886, 4.7559842],
          [101.7793568, 4.7565933],
          [101.7795751, 4.7573866],
          [101.7794611, 4.7584448],
          [101.7793581, 4.7591349],
          [101.7794962, 4.7593763],
          [101.7800469, 4.7594563],
          [101.7804942, 4.7593869],
          [101.7813891, 4.759225],
          [101.7821806, 4.7592473],
          [101.7830871, 4.75935],
          [101.7836952, 4.7593609],
          [101.7843263, 4.7592568],
          [101.7849456, 4.7590492],
          [101.78646, 4.7588983],
          [101.7874005, 4.7586789],
          [101.7883066, 4.758356],
          [101.7888114, 4.7579876],
          [101.7897863, 4.7576647],
          [101.7906353, 4.7575143],
          [101.7914727, 4.7575251],
          [101.7923446, 4.7574552],
          [101.7930789, 4.757397],
          [101.7936638, 4.757155],
          [101.7945013, 4.7571197],
          [101.7952699, 4.7571189],
          [101.795775, 4.7573254],
          [101.7965897, 4.7575202],
          [101.7972783, 4.757646],
          [101.7977259, 4.7580251],
          [101.7978065, 4.7585656],
          [101.7975774, 4.7592559],
          [101.7973828, 4.759854],
          [101.7970965, 4.7607284],
          [101.7966266, 4.7613959],
          [101.796581, 4.7620055],
          [101.797109, 4.7622809],
          [101.7980499, 4.7624756],
          [101.7990022, 4.7626931],
          [101.7998286, 4.7629684],
          [101.800666, 4.7631171],
          [101.8013658, 4.7631049],
          [101.801974, 4.7628972],
          [101.8024555, 4.7625979],
          [101.8031091, 4.7620797],
          [101.8036827, 4.7617341],
          [101.8043133, 4.7613425],
          [101.8050249, 4.7613763],
          [101.8060344, 4.7613063],
          [101.8067225, 4.7611332],
          [101.8072041, 4.7607072],
          [101.8077432, 4.7604767],
          [101.8084547, 4.7606599],
          [101.809063, 4.7608664],
          [101.8101185, 4.7610264],
          [101.8113005, 4.7612092],
          [101.812459, 4.7606906],
          [101.8134454, 4.7603561],
          [101.8145811, 4.7601135],
          [101.8153611, 4.7598253],
          [101.8160261, 4.759376],
          [101.8163471, 4.7589156],
          [101.8167597, 4.7581677],
          [101.8170573, 4.7574315],
          [101.8174242, 4.757017],
          [101.8175614, 4.7559818],
          [101.8177216, 4.7554756],
          [101.8180997, 4.7549118],
          [101.8184437, 4.7544054],
          [101.8187532, 4.7539336],
          [101.819166, 4.7534501],
          [101.8195443, 4.7530932],
          [101.8201407, 4.7528397],
          [101.8212178, 4.752666],
          [101.8215754, 4.7516609],
          [101.8218269, 4.7507292],
          [101.8219757, 4.7500505],
          [101.8221933, 4.7494637],
          [101.8222617, 4.7489001],
          [101.8221925, 4.7484402],
          [101.8219399, 4.7478538],
          [101.82171, 4.7472561],
          [101.8214919, 4.7469457],
          [101.8212274, 4.7460835],
          [101.8211234, 4.745152],
          [101.8211115, 4.7443126],
          [101.8211109, 4.743542],
          [101.8211565, 4.7428979],
          [101.8211677, 4.7422884],
          [101.8213508, 4.7417476],
          [101.8214075, 4.741115],
          [101.8214071, 4.7401489],
          [101.821418, 4.7393554],
          [101.8213487, 4.7385044],
          [101.8213711, 4.7377913],
          [101.8215196, 4.7370437],
          [101.8214849, 4.7363766],
          [101.8211977, 4.7356869],
          [101.8208186, 4.7352158],
          [101.8205201, 4.7346525],
          [101.8204852, 4.7339165],
          [101.820519, 4.7331344],
          [101.8206332, 4.7322833],
          [101.8206671, 4.7314437],
          [101.8206207, 4.7306387],
          [101.8205629, 4.7298452],
          [101.8203444, 4.7291669],
          [101.8201374, 4.7285346],
          [101.8198959, 4.7275573],
          [101.8196657, 4.7266949],
          [101.819585, 4.725752],
          [101.8196993, 4.7252228],
          [101.8201463, 4.7245323],
          [101.8205473, 4.7239224],
          [101.8208796, 4.7232666],
          [101.821189, 4.7224381],
          [101.8216356, 4.7216671],
          [101.8220251, 4.7206087],
          [101.8223918, 4.7198838],
          [101.8227814, 4.7192854],
          [101.823263, 4.7186639],
          [101.8236298, 4.7183185],
          [101.8244211, 4.7180532],
          [101.8252583, 4.7178109],
          [101.8258894, 4.7176722],
          [101.8268071, 4.7176253],
          [101.8273462, 4.7172567],
          [101.8279995, 4.7166581],
          [101.8288024, 4.7161742],
          [101.8296625, 4.7159548],
          [101.8308673, 4.7162066],
          [101.8315099, 4.7165395],
          [101.8322675, 4.7169643],
          [101.83283, 4.7175273],
          [101.8332205, 4.7181134],
          [101.8336683, 4.71878],
          [101.8337493, 4.719723],
          [101.8337496, 4.7203785],
          [101.834108, 4.7208246],
          [101.8349316, 4.7212118],
          [101.8359185, 4.7215904],
          [101.8369857, 4.7218307],
          [101.8384082, 4.7217718],
          [101.8393028, 4.7215064],
          [101.8402547, 4.7211144],
          [101.8412526, 4.7206073],
          [101.841711, 4.7198594],
          [101.8420546, 4.7192955],
          [101.8423062, 4.7182027],
          [101.8424089, 4.7173975],
          [101.8428329, 4.7165805],
          [101.8430157, 4.7154532],
          [101.8431414, 4.714924],
          [101.843439, 4.7141073],
          [101.8437369, 4.7133019],
          [101.8439889, 4.7126576],
          [101.8442868, 4.7119328],
          [101.844504, 4.7111849],
          [101.8448019, 4.7103911],
          [101.8452828, 4.709275],
          [101.8456723, 4.7081821],
          [101.8461303, 4.7069396],
          [101.8463017, 4.7061459],
          [101.8465535, 4.7052599],
          [101.8466102, 4.7045239],
          [101.8462999, 4.7035582],
          [101.8460124, 4.7026614],
          [101.845599, 4.7019258],
          [101.8452198, 4.7010751],
          [101.8450701, 4.7003277],
          [101.8453334, 4.6993614],
          [101.8454817, 4.6983723],
          [101.8455725, 4.6970611],
          [101.8455147, 4.6961181],
          [101.8456859, 4.6950598],
          [101.8458232, 4.6944041],
          [101.8461671, 4.6939322],
          [101.8468548, 4.6933104],
          [101.8473019, 4.6927236],
          [101.8475195, 4.6920908],
          [101.8475304, 4.6912856],
          [101.8475525, 4.6902966],
          [101.8475059, 4.689227],
          [101.8474937, 4.688215],
          [101.8478142, 4.6873521],
          [101.8483643, 4.68665],
          [101.8487427, 4.6860056],
          [101.8492928, 4.685223],
          [101.8500034, 4.6846011],
          [101.8507833, 4.6842094],
          [101.8518098, 4.683937],
          [101.8520091, 4.6839268],
          [101.8521997, 4.6839159],
          [101.8532681, 4.6842518],
          [101.8536237, 4.684562],
          [101.8543583, 4.6849291],
          [101.8556208, 4.6856524],
          [101.8568029, 4.6866632],
          [101.8573999, 4.6871456],
          [101.8580771, 4.6876395],
          [101.8585482, 4.688835],
          [101.8587442, 4.6899504],
          [101.8590429, 4.6907552],
          [101.8595136, 4.6914102],
          [101.8610746, 4.692225],
          [101.8614073, 4.6924432],
          [101.8621763, 4.692868],
          [101.8629792, 4.6929362],
          [101.8634378, 4.6924526],
          [101.863712, 4.6910378],
          [101.8638608, 4.6902786],
          [101.8641471, 4.6897378],
          [101.8646743, 4.6888516],
          [101.865052, 4.6877472],
          [101.8650857, 4.6865281],
          [101.864936, 4.6858842],
          [101.8649812, 4.685148],
          [101.8651527, 4.6842164],
          [101.8653698, 4.6830545],
          [101.8657821, 4.6821225],
          [101.8661489, 4.6817771],
          [101.8666762, 4.6812476],
          [101.868029, 4.6797855],
          [101.8695196, 4.6791399],
          [101.8702653, 4.6788515],
          [101.8716644, 4.6784015],
          [101.8734079, 4.6783306],
          [101.8741996, 4.6783757],
          [101.8757597, 4.6783512],
          [101.8774234, 4.6786369],
          [101.8782605, 4.678406],
          [101.8791092, 4.6779449],
          [101.8805656, 4.6775179],
          [101.8817927, 4.6769646],
          [101.8824579, 4.6765729],
          [101.8830767, 4.6758705],
          [101.8840516, 4.6753865],
          [101.8854736, 4.6747984],
          [101.8861958, 4.6744181],
          [101.8866088, 4.6740152],
          [101.8873078, 4.6734049],
          [101.887935, 4.6727964],
          [101.8881377, 4.6719448],
          [101.8881256, 4.6713123],
          [101.8881596, 4.6706107],
          [101.8882278, 4.6697941],
          [101.8877789, 4.667966],
          [101.8875602, 4.6669771],
          [101.8877661, 4.6660453],
          [101.8881556, 4.6653664],
          [101.8896458, 4.6640537],
          [101.890357, 4.6638228],
          [101.890976, 4.6635002],
          [101.892249, 4.6629698],
          [101.8934189, 4.6626924],
          [101.8938197, 4.6617029],
          [101.8937044, 4.6610476],
          [101.8933138, 4.6604499],
          [101.8927399, 4.659657],
          [101.8918095, 4.6583814],
          [101.8911434, 4.6573585],
          [101.8907873, 4.6567264],
          [101.8904883, 4.6557723],
          [101.8907509, 4.6540468],
          [101.8911292, 4.6534369],
          [101.8917136, 4.6527576],
          [101.8924929, 4.6520207],
          [101.8929517, 4.6517213],
          [101.8932031, 4.6506285],
          [101.8924684, 4.6497322],
          [101.8922843, 4.6489732],
          [101.8922032, 4.6482028],
          [101.8915598, 4.6468808],
          [101.8908249, 4.6455936],
          [101.8902853, 4.6450423],
          [101.8894471, 4.644215],
          [101.8885402, 4.643112],
          [101.8889178, 4.641973],
          [101.8893644, 4.6411445],
          [101.8894443, 4.6404658],
          [101.8887544, 4.638408],
          [101.8887426, 4.6377524],
          [101.8888566, 4.6370967],
          [101.889177, 4.6356818],
          [101.8896575, 4.6343127],
          [101.8900356, 4.6334956],
          [101.8905626, 4.6329086],
          [101.8912381, 4.6313667],
          [101.8918914, 4.630515],
          [101.8924073, 4.6299164],
          [101.8927509, 4.6291799],
          [101.893186, 4.628409],
          [101.893196, 4.6265573],
          [101.8931725, 4.6257407],
          [101.8929999, 4.6248668],
          [101.8930337, 4.6240847],
          [101.8931471, 4.6225666],
          [101.8932726, 4.6217959],
          [101.8932493, 4.6210368],
          [101.8933632, 4.6199441],
          [101.8934195, 4.6185869],
          [101.8938998, 4.6170453],
          [101.8941287, 4.6163895],
          [101.894266, 4.6156534],
          [101.8954006, 4.6143986],
          [101.8964552, 4.6134198],
          [101.8969478, 4.6127981],
          [101.8972457, 4.6124528],
          [101.8979221, 4.611808],
          [101.8992573, 4.6102332],
          [101.9001142, 4.6098248],
          [101.9009035, 4.6095894],
          [101.9034088, 4.6089051],
          [101.9038553, 4.6088445],
          [101.9046925, 4.6089537],
          [101.9060706, 4.6094504],
          [101.9079849, 4.6095753],
          [101.9085937, 4.6098127],
          [101.9097523, 4.6100003],
          [101.9112469, 4.6096229],
          [101.9114848, 4.6096738],
          [101.9118868, 4.6103435],
          [101.9123239, 4.6114032],
          [101.9126341, 4.6121618],
          [101.9129559, 4.6128286],
          [101.9133351, 4.6136447],
          [101.91368, 4.6145529],
          [101.9137611, 4.6156454],
          [101.9136702, 4.616646],
          [101.9136825, 4.617727],
          [101.9137175, 4.6187046],
          [101.9138789, 4.6197051],
          [101.9138684, 4.6205561],
          [101.9136623, 4.6212924],
          [101.9134336, 4.6221436],
          [101.9131818, 4.6228224],
          [101.9131481, 4.623455],
          [101.9133551, 4.6243059],
          [101.9135506, 4.6250533],
          [101.9140446, 4.6258232],
          [101.9143894, 4.6268235],
          [101.9146313, 4.6279617],
          [101.9147351, 4.6288587],
          [101.9152216, 4.6297502],
          [101.9153719, 4.6313255],
          [101.9155677, 4.6323145],
          [101.9158553, 4.6332112],
          [101.9162114, 4.6339238],
          [101.9167856, 4.6346591],
          [101.9176354, 4.6359579],
          [101.918428, 4.6370725],
          [101.9191515, 4.6381068],
          [101.9199553, 4.6390718],
          [101.9205524, 4.6398762],
          [101.9213096, 4.6403124],
          [101.9219073, 4.6415539],
          [101.9221028, 4.6423356],
          [101.9224707, 4.6430483],
          [101.9230449, 4.6438873],
          [101.9235505, 4.6451287],
          [101.9236431, 4.6460717],
          [101.9237584, 4.6468651],
          [101.9240001, 4.6478999],
          [101.9237487, 4.6489237],
          [101.9234973, 4.6500627],
          [101.923796, 4.6509593],
          [101.9243359, 4.6517407],
          [101.9249792, 4.6526487],
          [101.9253697, 4.6533612],
          [101.9254965, 4.6541316],
          [101.9257379, 4.6548214],
          [101.9260943, 4.6555915],
          [101.9264273, 4.6562006],
          [101.9268983, 4.6569362],
          [101.9273806, 4.6575796],
          [101.9280462, 4.6577974],
          [101.9289181, 4.6579919],
          [101.9299392, 4.6580827],
          [101.9320386, 4.6582643],
          [101.9337135, 4.6584924],
          [101.9352393, 4.6585712],
          [101.9364324, 4.6585467],
          [101.9373617, 4.6586837],
          [101.9382222, 4.6589702],
          [101.9387274, 4.6594643],
          [101.9390721, 4.6602228],
          [101.9395087, 4.6610849],
          [101.940014, 4.6616938],
          [101.9404162, 4.6625789],
          [101.9406923, 4.6635217],
          [101.9408994, 4.6643265],
          [101.9411874, 4.6656488],
          [101.9419562, 4.6659583],
          [101.9427483, 4.666636],
          [101.9432421, 4.6671645],
          [101.9433686, 4.6678429],
          [101.9434154, 4.6688434],
          [101.9434737, 4.6697863],
          [101.9435663, 4.6707753],
          [101.943716, 4.6716377],
          [101.9440842, 4.6725918],
          [101.9442797, 4.6734426],
          [101.944659, 4.6743738],
          [101.9450042, 4.6754199],
          [101.9452117, 4.6767998],
          [101.9449601, 4.6775822],
          [101.9443413, 4.6784799],
          [101.9437684, 4.6791822],
          [101.9435507, 4.6796195],
          [101.9437349, 4.6801944],
          [101.943919, 4.6808841],
          [101.9441261, 4.681781],
          [101.9442648, 4.6829654],
          [101.9444148, 4.6838737],
          [101.944576, 4.6847821],
          [101.9447487, 4.6854605],
          [101.9449443, 4.6862193],
          [101.945289, 4.6868055],
          [101.945714, 4.6874375],
          [101.9460013, 4.6881272],
          [101.9463805, 4.6886673],
          [101.9466449, 4.6894031],
          [101.9468868, 4.6905874],
          [101.9470366, 4.6913807],
          [101.9472327, 4.6925651],
          [101.947497, 4.6934158],
          [101.9478994, 4.6941283],
          [101.9482673, 4.6949791],
          [101.9484858, 4.6957377],
          [101.9486012, 4.6964392],
          [101.9487284, 4.6976927],
          [101.948752, 4.6985666],
          [101.9488217, 4.6997282],
          [101.949029, 4.700602],
          [101.9492134, 4.7015794],
          [101.9493402, 4.7024534],
          [101.9494441, 4.7030973],
          [101.9502138, 4.7044303],
          [101.9506622, 4.7054419],
          [101.9509266, 4.7062006],
          [101.9513404, 4.7070857],
          [101.9517081, 4.7077064],
          [101.9521101, 4.7083154],
          [101.9523516, 4.7090857],
          [101.952536, 4.7101436],
          [101.9527087, 4.7108679],
          [101.9529734, 4.7117072],
          [101.9531229, 4.712282],
          [101.953227, 4.713317],
          [101.9530903, 4.7142372],
          [101.9529305, 4.7151114],
          [101.9528508, 4.7160547],
          [101.9529091, 4.7169401],
          [101.9527951, 4.7178143],
          [101.9527962, 4.7191024],
          [101.9525787, 4.7198847],
          [101.9524533, 4.7205863],
          [101.9521096, 4.7214148],
          [101.9518468, 4.7224157],
          [101.9517676, 4.7238764],
          [101.9516428, 4.7256131],
          [101.9515412, 4.7273729],
          [101.951393, 4.7285692],
          [101.951176, 4.7295355],
          [101.951486, 4.7301677],
          [101.9521407, 4.7308684],
          [101.9526001, 4.7313969],
          [101.9526007, 4.732363],
          [101.9527168, 4.733766],
          [101.9527406, 4.734962],
          [101.9525122, 4.7358939],
          [101.9524099, 4.7370556],
          [101.9522274, 4.7385854],
          [101.9520449, 4.7395977],
          [101.9518388, 4.7403454],
          [101.9513578, 4.7412661],
          [101.9508998, 4.7421752],
          [101.9503958, 4.7432223],
          [101.9499951, 4.7441774],
          [101.9497434, 4.7451897],
          [101.9495723, 4.7461445],
          [101.9494127, 4.7472487],
          [101.9493562, 4.7483414],
          [101.9491733, 4.7493307],
          [101.9491742, 4.7504463],
          [101.9493703, 4.7515616],
          [101.9499564, 4.752573],
          [101.950118, 4.7539184],
          [101.9501763, 4.7549879],
          [101.9496496, 4.7561386],
          [101.9497764, 4.756909],
          [101.9500409, 4.7575643],
          [101.9509823, 4.7582301],
          [101.9517055, 4.7587354],
          [101.9526466, 4.7592863],
          [101.9535189, 4.7597108],
          [101.9544139, 4.7599513],
          [101.9554239, 4.7601572],
          [101.9563765, 4.7605585],
          [101.9570992, 4.7607187],
          [101.957811, 4.7610628],
          [101.9584427, 4.7618326],
          [101.9588221, 4.7627062],
          [101.9592469, 4.7632577],
          [101.9599128, 4.763671],
          [101.9606473, 4.7639461],
          [101.961554, 4.7642211],
          [101.9621736, 4.7643699],
          [101.9629539, 4.7646679],
          [101.9634133, 4.765231],
          [101.9639873, 4.7654948],
          [101.9643313, 4.7653794],
          [101.9644688, 4.7649652],
          [101.9645483, 4.7641601],
          [101.9647429, 4.7634582],
          [101.9650059, 4.7627564],
          [101.9655675, 4.7619277],
          [101.9664964, 4.7616275],
          [101.9676207, 4.7612811],
          [101.9682626, 4.7609123],
          [101.9686751, 4.7602103],
          [101.9688349, 4.7593591],
          [101.9688919, 4.7585654],
          [101.9689712, 4.7575993],
          [101.9689244, 4.7566678],
          [101.9689466, 4.7558627],
          [101.9690148, 4.755023],
          [101.9691632, 4.7539649],
          [101.9693572, 4.753056],
          [101.9693683, 4.7523659],
          [101.9695857, 4.7516986],
          [101.9699178, 4.7511232],
          [101.9702387, 4.7506858],
          [101.9707663, 4.7504896],
          [101.9716152, 4.7504426],
          [101.9724182, 4.7504301],
          [101.9730378, 4.7504409],
          [101.9736806, 4.7504977],
          [101.9743915, 4.7504393],
          [101.9747813, 4.7499327],
          [101.974792, 4.7492311],
          [101.9748833, 4.7485525],
          [101.9752501, 4.748092],
          [101.97564, 4.7479651],
          [101.9761678, 4.7479874],
          [101.9766727, 4.7479752],
          [101.9771309, 4.7474343],
          [101.9774631, 4.7467783],
          [101.9774741, 4.7461687],
          [101.9775768, 4.745674],
          [101.9779092, 4.7450871],
          [101.9783792, 4.7446841],
          [101.9788838, 4.7444189],
          [101.9795031, 4.7442686],
          [101.9798357, 4.7441647],
          [101.98026, 4.7439112],
          [101.9809369, 4.7438758],
          [101.9816368, 4.7438174],
          [101.9823936, 4.743713],
          [101.9830473, 4.7433903],
          [101.9837011, 4.7430444],
          [101.9842511, 4.7424112],
          [101.9846752, 4.7418586],
          [101.9851911, 4.7412945],
          [101.9856725, 4.7408914],
          [101.9861885, 4.7407987],
          [101.9866248, 4.7412237],
          [101.9868551, 4.742132],
          [101.9867758, 4.7430751],
          [101.9866504, 4.7439954],
          [101.98633, 4.7448123],
          [101.9859866, 4.7457558],
          [101.9857003, 4.7466187],
          [101.9852882, 4.7474933],
          [101.9848875, 4.7485288],
          [101.9850031, 4.7495178],
          [101.985394, 4.7502993],
          [101.9860941, 4.7506205],
          [101.9869662, 4.7508609],
          [101.9876547, 4.7511362],
          [101.9879881, 4.7516648],
          [101.9884246, 4.7523887],
          [101.9883795, 4.7530099],
          [101.9878289, 4.7533901],
          [101.9874281, 4.7538966],
          [101.9872218, 4.7544604],
          [101.9871652, 4.7551276],
          [101.9871657, 4.755691],
          [101.9871546, 4.7563236],
          [101.9868452, 4.7566806],
          [101.9866969, 4.7573937],
          [101.987168, 4.7583478],
          [101.9877881, 4.7589565],
          [101.9881443, 4.7595656],
          [101.988535, 4.7601287],
          [101.9889937, 4.7599786],
          [101.9893144, 4.7592651],
          [101.9897958, 4.7589311],
          [101.9903351, 4.7590109],
          [101.9906681, 4.759367],
          [101.9911391, 4.759838],
          [101.9916789, 4.7604009],
          [101.9921495, 4.7609062],
          [101.992907, 4.7610549],
          [101.9934113, 4.7605827],
          [101.9940995, 4.7601909],
          [101.9948567, 4.7601324],
          [101.9957173, 4.7603153],
          [101.9962109, 4.7606482],
          [101.9966587, 4.7610502],
          [101.997531, 4.7614746],
          [101.9977787, 4.7615439],
          [101.9981775, 4.7617711],
          [101.9993877, 4.7622267],
          [102.000598, 4.7632895],
          [102.0012786, 4.7632897],
          [102.0019598, 4.7623033],
          [102.0024141, 4.7611651],
          [102.0024145, 4.7596472],
          [102.001507, 4.7585086],
          [102.0015073, 4.7572944],
          [102.0018103, 4.7553972],
          [102.0020375, 4.7540312],
          [102.0021134, 4.7531206],
          [102.0027188, 4.7524376],
          [102.0028702, 4.7520583],
          [102.0027192, 4.7509198],
          [102.0036272, 4.7500853],
          [102.0037031, 4.7490987],
          [102.0039302, 4.7481881],
          [102.0051405, 4.7478089],
          [102.0066537, 4.747354],
          [102.0077885, 4.7465195],
          [102.0087723, 4.7448501],
          [102.0099074, 4.7433325],
          [102.0107399, 4.7418149],
          [102.0108914, 4.7406007],
          [102.0109674, 4.7390071],
          [102.0114974, 4.7375653],
          [102.0121025, 4.7368824],
          [102.0133132, 4.7365032],
          [102.0145991, 4.7361999],
          [102.0160364, 4.735669],
          [102.0167931, 4.7347585],
          [102.0178524, 4.7335445],
          [102.0198192, 4.7330896],
          [102.0208782, 4.7333935],
          [102.0216343, 4.7350632],
          [102.0221633, 4.736657],
          [102.0231464, 4.7377197],
          [102.022995, 4.7387821],
          [102.0226922, 4.7399964],
          [102.0229943, 4.7418178],
          [102.023902, 4.7420457],
          [102.025642, 4.7417425],
          [102.0269278, 4.7418186],
          [102.0276843, 4.74235],
          [102.0285416, 4.7418675],
          [102.029395, 4.7410778],
          [102.0297044, 4.7406634],
          [102.0301282, 4.7400419],
          [102.030667, 4.7393511],
          [102.0310217, 4.7384076],
          [102.0312844, 4.7374757],
          [102.0318574, 4.7365663],
          [102.0324303, 4.7358296],
          [102.0331179, 4.7349201],
          [102.0336099, 4.7338728],
          [102.0339994, 4.7329524],
          [102.0344232, 4.7323077],
          [102.0353862, 4.7316969],
          [102.0361202, 4.7313165],
          [102.036969, 4.7313269],
          [102.0379676, 4.7315786],
          [102.0387479, 4.731934],
          [102.0394479, 4.7321402],
          [102.0400213, 4.7320244],
          [102.040583, 4.7315292],
          [102.0410532, 4.731126],
          [102.0418303, 4.7307041],
          [102.0430922, 4.7275945],
          [102.0434528, 4.7269295],
          [102.0437321, 4.7263906],
          [102.0450805, 4.7269519],
          [102.0463161, 4.7272364],
          [102.0471204, 4.7269772],
          [102.0476341, 4.7257724],
          [102.0494943, 4.7253478],
          [102.0501702, 4.7254097],
          [102.0514122, 4.7263731],
          [102.0516515, 4.7280431],
          [102.0514387, 4.7288586],
          [102.0517121, 4.7305863],
          [102.0518128, 4.7323705],
          [102.0516239, 4.7330942],
          [102.0504565, 4.7343059],
          [102.0502329, 4.7350869],
          [102.050985, 4.7356326],
          [102.0515081, 4.7361885],
          [102.0532123, 4.7381445],
          [102.053679, 4.7385733],
          [102.0542941, 4.7390837],
          [102.0556367, 4.7403584],
          [102.0565437, 4.7416646],
          [102.0569743, 4.7423004],
          [102.057484, 4.7430747],
          [102.0587435, 4.7432903],
          [102.0596991, 4.7427559],
          [102.0602311, 4.7421266],
          [102.0613364, 4.7415127],
          [102.0616896, 4.7402839],
          [102.0619581, 4.7396644],
          [102.0623877, 4.7389654],
          [102.0639352, 4.7389756],
          [102.0653855, 4.7396643],
          [102.0660372, 4.7398872],
          [102.0669741, 4.7402848],
          [102.0674491, 4.7411278],
          [102.0689382, 4.7412643],
          [102.0695094, 4.7414868],
          [102.0701771, 4.7426188],
          [102.0714474, 4.742869],
          [102.0721702, 4.7427932],
          [102.0729834, 4.7428908],
          [102.0744344, 4.7435102],
          [102.075851, 4.744118],
          [102.0765929, 4.7445601],
          [102.0784166, 4.7458611],
          [102.0797249, 4.74717],
          [102.0809167, 4.7487197],
          [102.0825694, 4.7499274],
          [102.0842147, 4.7506057],
          [102.0858292, 4.7523309],
          [102.0876852, 4.7539657],
          [102.089188, 4.7538145],
          [102.0909983, 4.7539416],
          [102.092465, 4.7554818],
          [102.0935871, 4.757169],
          [102.0948117, 4.7588916],
          [102.0967424, 4.7582486],
          [102.0984501, 4.758283],
          [102.0990554, 4.7585977],
          [102.1004615, 4.7591017],
          [102.1029732, 4.7589228],
          [102.1046427, 4.7594631],
          [102.106678, 4.7601441],
          [102.1097441, 4.7609122],
          [102.1134651, 4.7614892],
          [102.1151874, 4.7611324],
          [102.1165275, 4.7612563],
          [102.1167344, 4.7597159],
          [102.11737, 4.7575224],
          [102.1181276, 4.7559051],
          [102.1198442, 4.7532815],
          [102.1200677, 4.7525236],
          [102.1202764, 4.75073],
          [102.1210956, 4.7500105],
          [102.1220782, 4.7489354],
          [102.1224938, 4.7455784],
          [102.1227749, 4.7447633],
          [102.1237268, 4.7432163],
          [102.1245842, 4.7420367],
          [102.1250603, 4.7412345],
          [102.1265192, 4.7392881],
          [102.1269749, 4.7381865],
          [102.1275433, 4.7343127],
          [102.1272164, 4.7335741],
          [102.1266621, 4.7310966],
          [102.1263761, 4.7295414],
          [102.1263655, 4.7264232],
          [102.1258726, 4.7249012],
          [102.1242321, 4.7220828],
          [102.1250104, 4.7207418],
          [102.1261515, 4.7199093],
          [102.1275277, 4.7183421],
          [102.1281822, 4.7181508],
          [102.1294732, 4.71572],
          [102.1306875, 4.7143243],
          [102.131472, 4.7121548],
          [102.1321055, 4.7117677],
          [102.1345319, 4.7107252],
          [102.1363598, 4.7100353],
          [102.1385485, 4.7085884],
          [102.1397844, 4.7073424],
          [102.1418671, 4.7062862],
          [102.142636, 4.7061645],
          [102.1447728, 4.7055227],
          [102.1464067, 4.7047281],
          [102.1483737, 4.7037745],
          [102.1487977, 4.7038233],
          [102.1492288, 4.7029057],
          [102.1497718, 4.7023454],
          [102.1502788, 4.701992],
          [102.1513245, 4.7001349],
          [102.1519679, 4.6999089],
          [102.1526807, 4.6996606],
          [102.1532813, 4.6990431],
          [102.1542356, 4.6971509],
          [102.1547392, 4.6957274],
          [102.1570099, 4.6940509],
          [102.1581169, 4.6931953],
          [102.1606228, 4.6922221],
          [102.1608977, 4.6907627],
          [102.161167, 4.690005],
          [102.1625183, 4.6871373],
          [102.1650396, 4.6856695],
          [102.1664218, 4.6847811],
          [102.1692323, 4.6829585],
          [102.1698639, 4.6827901],
          [102.1711289, 4.6807502],
          [102.1719055, 4.6795817],
          [102.1725706, 4.6780442],
          [102.1741604, 4.6769845],
          [102.1747024, 4.6765392],
          [102.1749717, 4.6757816],
          [102.1752816, 4.6741958],
          [102.1756327, 4.6732546],
          [102.1757864, 4.6726227],
          [102.175329, 4.6709629],
          [102.1755131, 4.6693763],
          [102.1760272, 4.6680794],
          [102.1759224, 4.666767],
          [102.1752438, 4.665566],
          [102.1746277, 4.6651937],
          [102.1740126, 4.6646834],
          [102.1736643, 4.6637606],
          [102.1737687, 4.6620814],
          [102.1741973, 4.661486],
          [102.1748326, 4.6608342],
          [102.175025, 4.6596388],
          [102.174933, 4.6581885],
          [102.1761601, 4.6580815],
          [102.1772525, 4.6576283],
          [102.1780997, 4.657749],
          [102.1789138, 4.6576852],
          [102.1796609, 4.6574024],
          [102.181488, 4.6567815],
          [102.1825705, 4.6561328],
          [102.1833412, 4.655735],
          [102.1847799, 4.6549275],
          [102.1859767, 4.654291],
          [102.186576, 4.6538691],
          [102.1879944, 4.6527392],
          [102.18925, 4.651896],
          [102.1897703, 4.6512895],
          [102.1904427, 4.6502468],
          [102.1917944, 4.6488634],
          [102.1923376, 4.6482456],
          [102.1927593, 4.6470287],
          [102.1929871, 4.6457069],
          [102.1931758, 4.6450178],
          [102.1935129, 4.6443641],
          [102.1948141, 4.6435556],
          [102.1951723, 4.6416595],
          [102.1966869, 4.639932],
          [102.1976921, 4.6388568],
          [102.1984981, 4.6383673],
          [102.1994085, 4.6377058],
          [102.2004401, 4.6376896],
          [102.2009157, 4.6368987],
          [102.2014019, 4.6362575],
          [102.2036519, 4.6357774],
          [102.204387, 4.6355981],
          [102.2052022, 4.6353963],
          [102.2071566, 4.6346035],
          [102.2083741, 4.6342547],
          [102.2091664, 4.6340298],
          [102.2108311, 4.6336838],
          [102.2122032, 4.6325766],
          [102.2143737, 4.630462],
          [102.2152717, 4.6299155],
          [102.2159606, 4.6297474],
          [102.2189378, 4.628593],
          [102.2195932, 4.6282866],
          [102.221766, 4.6274378],
          [102.2228902, 4.6272839],
          [102.2235084, 4.6273685],
          [102.2250432, 4.6274934],
          [102.2261111, 4.6287545],
          [102.2266912, 4.6293336],
          [102.228439, 4.6301157],
          [102.2303482, 4.6307494],
          [102.2331136, 4.6318489],
          [102.23495, 4.6330113],
          [102.2338705, 4.6348453],
          [102.2325772, 4.6361142],
          [102.2321971, 4.6378951],
          [102.2314613, 4.6397429],
          [102.2308869, 4.641442],
          [102.229845, 4.6443694],
          [102.229736, 4.6451627],
          [102.2302533, 4.6464661],
          [102.2313652, 4.6479692],
          [102.2316588, 4.6485694],
          [102.2320031, 4.6500214],
          [102.2325464, 4.6509454],
          [102.2337766, 4.6504355],
          [102.2344547, 4.6501868],
          [102.2365318, 4.6498435],
          [102.2383186, 4.6499815],
          [102.2400091, 4.6507634],
          [102.241368, 4.6514395],
          [102.2421697, 4.6515251],
          [102.2444244, 4.651954],
          [102.2453141, 4.6525004],
          [102.2461825, 4.6528742],
          [102.2469808, 4.6534087],
          [102.2479351, 4.6545538],
          [102.2486872, 4.655111],
          [102.2492689, 4.6554944],
          [102.2496668, 4.6559457],
          [102.2507449, 4.6558146],
          [102.2517542, 4.655752],
          [102.2526135, 4.655792],
          [102.2533923, 4.6558776],
          [102.2542634, 4.6558602],
          [102.2552851, 4.6556366],
          [102.2560433, 4.6553768],
          [102.2568311, 4.6557961],
          [102.257243, 4.6559254],
          [102.257669, 4.6556405],
          [102.2582444, 4.6553334],
          [102.2593201, 4.6555704],
          [102.2601881, 4.6559787],
          [102.2610348, 4.6561798],
          [102.261802, 4.6562422],
          [102.262627, 4.6562821],
          [102.2635204, 4.6563453],
          [102.2650265, 4.6572753],
          [102.2655606, 4.6579117],
          [102.2666859, 4.6591501],
          [102.268219, 4.6595279],
          [102.2689039, 4.6599006],
          [102.2701116, 4.6608748],
          [102.2715847, 4.6615861],
          [102.272151, 4.6609454],
          [102.2741781, 4.659612],
          [102.2753383, 4.6592629],
          [102.2759246, 4.6590595],
          [102.2778515, 4.6588877],
          [102.279187, 4.6580678],
          [102.2797636, 4.6575882],
          [102.2817737, 4.6569682],
          [102.2830039, 4.6564698],
          [102.2835455, 4.6560591],
          [102.2847305, 4.6554913],
          [102.2864995, 4.6549273],
          [102.2873163, 4.6545526],
          [102.2889321, 4.6530097],
          [102.2902022, 4.6517751],
          [102.2907456, 4.6511457],
          [102.2926589, 4.6496622],
          [102.2933843, 4.649241],
          [102.2958063, 4.6471968],
          [102.2963265, 4.6465789],
          [102.2967537, 4.6461557],
          [102.2979796, 4.6462211],
          [102.2990523, 4.6453074],
          [102.2996962, 4.6450009],
          [102.3014435, 4.6443102],
          [102.3027759, 4.6439275],
          [102.3044451, 4.6429139],
          [102.3066404, 4.6420995],
          [102.3075133, 4.6418172],
          [102.3081011, 4.6414068],
          [102.3093366, 4.640149],
          [102.3101392, 4.6400965],
          [102.310988, 4.6400098],
          [102.3117121, 4.6398438],
          [102.3123539, 4.6398256],
          [102.3132032, 4.6396365],
          [102.3137777, 4.6394226],
          [102.3146973, 4.6390158],
          [102.315435, 4.638424],
          [102.3156773, 4.6381847],
          [102.3162557, 4.6374538],
          [102.3169141, 4.636781],
          [102.3173422, 4.6362214],
          [102.3179641, 4.6357896],
          [102.3192346, 4.6359598],
          [102.3200809, 4.6361497],
          [102.3208129, 4.6363158],
          [102.3217503, 4.6365869],
          [102.3227909, 4.6368242],
          [102.3238805, 4.6367173],
          [102.3244196, 4.6366179],
          [102.3247874, 4.6364368],
          [102.3255444, 4.6363275],
          [102.3267477, 4.6363133],
          [102.3274449, 4.6365022],
          [102.3281651, 4.6367141],
          [102.3288147, 4.6371668],
          [102.3297516, 4.6375298],
          [102.3305059, 4.6377879],
          [102.3318829, 4.6374764],
          [102.3325099, 4.637929],
          [102.3326879, 4.6386192],
          [102.3329685, 4.6393909],
          [102.3332597, 4.6402544],
          [102.3334483, 4.6410826],
          [102.3344051, 4.6418361],
          [102.3349741, 4.6423342],
          [102.3355314, 4.6428666],
          [102.3360661, 4.6433757],
          [102.3364022, 4.6428499],
          [102.3372098, 4.6420978],
          [102.3378791, 4.6414941],
          [102.3385127, 4.6410392],
          [102.3392021, 4.6407686],
          [102.3402554, 4.6408567],
          [102.3412158, 4.6411508],
          [102.342028, 4.6412831],
          [102.3427373, 4.6414146],
          [102.3435493, 4.6416159],
          [102.343874, 4.6426289],
          [102.3442451, 4.6435617],
          [102.3440104, 4.6442838],
          [102.3435928, 4.6449583],
          [102.3432006, 4.6453343],
          [102.3430256, 4.6457351],
          [102.3427097, 4.6465941],
          [102.3426772, 4.6478802],
          [102.342924, 4.6485596],
          [102.3432746, 4.6491939],
          [102.3435881, 4.6501263],
          [102.3439373, 4.6509329],
          [102.3443881, 4.6519353],
          [102.3447837, 4.6526732],
          [102.3450872, 4.6534106],
          [102.345414, 4.6541365],
          [102.346038, 4.654945],
          [102.3465947, 4.6556036],
          [102.346751, 4.6561216],
          [102.347254, 4.6562746],
          [102.347833, 4.6569794],
          [102.3484944, 4.6573976],
          [102.3490656, 4.6576314],
          [102.3495553, 4.6580255],
          [102.3498366, 4.6586937],
          [102.3501543, 4.659121],
          [102.3504618, 4.6593644],
          [102.3507575, 4.6596651],
          [102.3510758, 4.659966],
          [102.3515299, 4.6605093],
          [102.3521775, 4.6612605],
          [102.3528941, 4.6619318],
          [102.3532779, 4.6626926],
          [102.3537885, 4.6633625],
          [102.3539352, 4.663685],
          [102.3541385, 4.664077],
          [102.3545568, 4.6648151],
          [102.3555018, 4.6655799],
          [102.3563123, 4.6659879],
          [102.3570657, 4.6663608],
          [102.357557, 4.6665711],
          [102.3581963, 4.66684],
          [102.3591679, 4.6671571],
          [102.3596365, 4.6673213],
          [102.3603203, 4.6678201],
          [102.3611193, 4.6681934],
          [102.3621235, 4.6687635],
          [102.3628663, 4.6689872],
          [102.3634602, 4.6692556],
          [102.363924, 4.6700628],
          [102.3640673, 4.6708104],
          [102.3641875, 4.6715808],
          [102.3642684, 4.6729825],
          [102.3642956, 4.673936],
          [102.364315, 4.6744415],
          [102.3644698, 4.6751777],
          [102.3646473, 4.6759484],
          [102.3649138, 4.6770873],
          [102.3656522, 4.6779311],
          [102.3662895, 4.6784869],
          [102.3669398, 4.6788937],
          [102.3676923, 4.6793585],
          [102.368234, 4.680465],
          [102.3685847, 4.6810763],
          [102.3693363, 4.6816674],
          [102.3700551, 4.6820632],
          [102.3707159, 4.6825848],
          [102.3711327, 4.6835181],
          [102.3712522, 4.6843919],
          [102.3712339, 4.6852876],
          [102.3711139, 4.6860331],
          [102.3713598, 4.6868388],
          [102.3717446, 4.6874849],
          [102.3719933, 4.6879345],
          [102.3718826, 4.6889558],
          [102.3716594, 4.6896778],
          [102.371562, 4.6904465],
          [102.371486, 4.6913993],
          [102.3711725, 4.6919712],
          [102.3702052, 4.6925958],
          [102.3687737, 4.6924935],
          [102.3678905, 4.6926135],
          [102.3671875, 4.6931712],
          [102.3656357, 4.693826],
          [102.3649309, 4.6946019],
          [102.3642731, 4.6952173],
          [102.3636497, 4.6958329],
          [102.3623275, 4.696455],
          [102.362206, 4.6973614],
          [102.3621342, 4.6977974],
          [102.3617954, 4.6986677],
          [102.3618829, 4.6992196],
          [102.3609373, 4.7000052],
          [102.3606791, 4.7008417],
          [102.3607075, 4.7016229],
          [102.3609079, 4.7023938],
          [102.3613156, 4.70304],
          [102.3619281, 4.7038483],
          [102.3624475, 4.7048743],
          [102.3627423, 4.7052783],
          [102.3631995, 4.705454],
          [102.3638139, 4.7060327],
          [102.3646127, 4.7064749],
          [102.3654549, 4.7072046],
          [102.3656342, 4.7077686],
          [102.3657996, 4.7086082],
          [102.3662332, 4.708864],
          [102.3664595, 4.7092102],
          [102.3672133, 4.7095717],
          [102.3680935, 4.7098194],
          [102.3687429, 4.7103408],
          [102.3695885, 4.7106571],
          [102.3703655, 4.7109154],
          [102.371087, 4.7109895],
          [102.3718205, 4.7109604],
          [102.3727846, 4.7107262],
          [102.3740809, 4.7105173],
          [102.3747354, 4.7103728],
          [102.3753428, 4.7103197],
          [102.3758129, 4.7102887],
          [102.3763145, 4.7106598],
          [102.3769059, 4.7112384],
          [102.3773504, 4.7115287],
          [102.3776788, 4.7120709],
          [102.378235, 4.7127755],
          [102.3786324, 4.7132608],
          [102.3790537, 4.7136084],
          [102.379981, 4.7137185],
          [102.380806, 4.7137014],
          [102.3815733, 4.7137529],
          [102.3824218, 4.7136902],
          [102.3832815, 4.7136504],
          [102.383901, 4.71354],
          [102.38491, 4.713444],
          [102.3857453, 4.7136109],
          [102.3866044, 4.7136515],
          [102.3872689, 4.7136677],
          [102.3881288, 4.7135936],
          [102.3894466, 4.7135572],
          [102.3902951, 4.713483],
          [102.3910404, 4.7134194],
          [102.3916952, 4.7132059],
          [102.3926596, 4.7129487],
          [102.3934628, 4.7128052],
          [102.3943572, 4.7127198],
          [102.3947573, 4.7128261],
          [102.3954344, 4.7126701],
          [102.3961788, 4.7127329],
          [102.3966613, 4.7125871],
          [102.3975122, 4.7121683],
          [102.3982811, 4.7120246],
          [102.3992086, 4.7120887],
          [102.4000231, 4.7119682],
          [102.4004032, 4.7116839],
          [102.4009557, 4.7113548],
          [102.4017031, 4.7110156],
          [102.4021603, 4.7111682],
          [102.4024554, 4.7115264],
          [102.4030234, 4.7121965],
          [102.4035576, 4.7127976],
          [102.4041047, 4.7131806],
          [102.4044319, 4.7138491],
          [102.4050875, 4.7135322],
          [102.4053651, 4.7131666],
          [102.406051, 4.7134013],
          [102.4063235, 4.7137134],
          [102.4068803, 4.7143376],
          [102.4072087, 4.7148797],
          [102.4068011, 4.7157266],
          [102.4072689, 4.7160171],
          [102.407968, 4.7159992],
          [102.4087013, 4.7159815],
          [102.4094819, 4.7158034],
          [102.4100335, 4.7156006],
          [102.4106906, 4.7150541],
          [102.4116906, 4.7146363],
          [102.4123897, 4.7145954],
          [102.4130107, 4.7143242],
          [102.4138122, 4.7143989],
          [102.4149235, 4.7143954],
          [102.4157123, 4.7146538],
          [102.4167087, 4.7147068],
          [102.4175922, 4.7145754],
          [102.4183487, 4.7145234],
          [102.419094, 4.7144598],
          [102.4201398, 4.714054],
          [102.4211171, 4.71359],
          [102.4216805, 4.7133414],
          [102.4224158, 4.7130481],
          [102.4229556, 4.7128912],
          [102.4233128, 4.7126296],
          [102.4234881, 4.712183],
          [102.4237007, 4.7113116],
          [102.4244225, 4.7113283],
          [102.4251793, 4.7112533],
          [102.4259604, 4.7109833],
          [102.4264782, 4.7107113],
          [102.4268795, 4.7106453],
          [102.4275116, 4.7103972],
          [102.4286924, 4.7103253],
          [102.4295744, 4.7103546],
          [102.4303074, 4.7104288],
          [102.4310847, 4.710664],
          [102.4319194, 4.7108882],
          [102.4327308, 4.7111582],
          [102.4338519, 4.7114418],
          [102.434606, 4.7117114],
          [102.4354971, 4.7120508],
          [102.4364003, 4.7123329],
          [102.4372917, 4.7126378],
          [102.4380485, 4.7125744],
          [102.4388858, 4.7124541],
          [102.4395865, 4.7122179],
          [102.440826, 4.7119511],
          [102.4416754, 4.711739],
          [102.4426609, 4.711723],
          [102.4434615, 4.711947],
          [102.4440554, 4.7122154],
          [102.4447281, 4.7126566],
          [102.4455057, 4.7128575],
          [102.4466185, 4.7126931],
          [102.4472615, 4.7125139],
          [102.4480309, 4.7122553],
          [102.448457, 4.7119942],
          [102.4492755, 4.711311],
          [102.4498635, 4.7107983],
          [102.4504609, 4.7106074],
          [102.4511839, 4.7104517],
          [102.4520686, 4.7101364],
          [102.452793, 4.7097971],
          [102.453748, 4.7092755],
          [102.4544962, 4.7087986],
          [102.4552209, 4.7083903],
          [102.4558336, 4.7076826],
          [102.4564228, 4.7070092],
          [102.4574239, 4.706442],
          [102.4580784, 4.7062745],
          [102.458667, 4.705693],
          [102.459073, 4.7050068],
          [102.4588151, 4.704247],
          [102.4582718, 4.7033357],
          [102.4579896, 4.7027711],
          [102.4575818, 4.7021479],
          [102.456935, 4.7012589],
          [102.4569855, 4.7006162],
          [102.4581833, 4.6998093],
          [102.4588733, 4.6994582],
          [102.4595293, 4.6990954],
          [102.460174, 4.698675],
          [102.4611072, 4.6979812],
          [102.4616937, 4.6976867],
          [102.4623746, 4.6970139],
          [102.4628265, 4.6963281],
          [102.4635079, 4.6955864],
          [102.4643608, 4.6949264],
          [102.4650738, 4.6945868],
          [102.4659126, 4.6942484],
          [102.466477, 4.6938618],
          [102.4670897, 4.6931198],
          [102.4680323, 4.6927014],
          [102.4688834, 4.692248],
          [102.4697677, 4.6919673],
          [102.470401, 4.6915698],
          [102.4712515, 4.691197],
          [102.4720691, 4.6906399],
          [102.4726689, 4.6901274],
          [102.4734268, 4.6898801],
          [102.4741847, 4.6896673],
          [102.4750456, 4.6894551],
          [102.4765549, 4.688329],
          [102.4772911, 4.6879207],
          [102.4780719, 4.6876736],
          [102.4788542, 4.6872542],
          [102.4800284, 4.6865045],
          [102.4812156, 4.6855827],
          [102.4819409, 4.6851055],
          [102.4827011, 4.6845596],
          [102.4838306, 4.6836489],
          [102.4845808, 4.6828847],
          [102.4852394, 4.6821774],
          [102.4859305, 4.681654],
          [102.4869936, 4.6804556],
          [102.4879702, 4.680072],
          [102.4885335, 4.6798003],
          [102.4893734, 4.6793355],
          [102.490009, 4.678605],
          [102.4907145, 4.6777141],
          [102.4913825, 4.6772251],
          [102.491959, 4.6767352],
          [102.4924449, 4.6761071],
          [102.493161, 4.6753197],
          [102.493806, 4.6748648],
          [102.4947247, 4.6745612],
          [102.4954371, 4.6743251],
          [102.4960009, 4.6739846],
          [102.4962781, 4.6736765],
          [102.4967535, 4.6729218],
          [102.4972859, 4.672202],
          [102.4979785, 4.6714718],
          [102.4984297, 4.6708894],
          [102.4989036, 4.670307],
          [102.4992961, 4.6699423],
          [102.4999775, 4.6692006],
          [102.501074, 4.6680943],
          [102.5016676, 4.6683971],
          [102.5020436, 4.6686638],
          [102.5026366, 4.6690471],
          [102.5031605, 4.6694642],
          [102.5041657, 4.6698962],
          [102.5052999, 4.6699043],
          [102.5056444, 4.6697919],
          [102.5065491, 4.6698671],
          [102.5074313, 4.6698619],
          [102.5083248, 4.6698912],
          [102.50914, 4.6696787],
          [102.5099567, 4.6692366],
          [102.510704, 4.6688973],
          [102.511428, 4.6685578],
          [102.5121744, 4.6683335],
          [102.5127875, 4.6691187],
          [102.513377, 4.6699613],
          [102.5140244, 4.6707468],
          [102.5143399, 4.6714611],
          [102.5149183, 4.6723036],
          [102.5155978, 4.673365],
          [102.5163972, 4.6737611],
          [102.5171389, 4.6741453],
          [102.5180997, 4.6743933],
          [102.5190249, 4.6747903],
          [102.5206937, 4.6753073],
          [102.5215392, 4.6756348],
          [102.5223166, 4.6759045],
          [102.5230807, 4.6763806],
          [102.5242347, 4.6768368],
          [102.5247024, 4.6771041],
          [102.525777, 4.6774447],
          [102.526659, 4.677474],
          [102.527588, 4.6773426],
          [102.5281633, 4.6770137],
          [102.528726, 4.6768109],
          [102.5291955, 4.676883],
          [102.5297441, 4.6770476],
          [102.5296916, 4.6779661],
          [102.5296296, 4.6786204],
          [102.5296015, 4.6793436],
          [102.5296684, 4.6811587],
          [102.5299472, 4.6821943],
          [102.5301142, 4.68285],
          [102.5305791, 4.6835195],
          [102.5311904, 4.6845459],
          [102.5318256, 4.6854576],
          [102.5321414, 4.686126],
          [102.5325839, 4.6867378],
          [102.5340974, 4.6865991],
          [102.5347842, 4.6866614],
          [102.535563, 4.6867243],
          [102.5358103, 4.6873576],
          [102.5362285, 4.6881759],
          [102.5371864, 4.6887799],
          [102.5380053, 4.6896356],
          [102.5383099, 4.6902692],
          [102.5386137, 4.6910524],
          [102.5390221, 4.691618],
          [102.539489, 4.6919773],
          [102.5399319, 4.6925546],
          [102.5404793, 4.69288],
          [102.5410933, 4.6935505],
          [102.5412635, 4.6937584],
          [102.5419466, 4.6944063],
          [102.5427781, 4.6950782],
          [102.543495, 4.6957608],
          [102.5437881, 4.6964061],
          [102.5439355, 4.6966368],
          [102.5441378, 4.697178],
          [102.5443384, 4.6979489],
          [102.5441503, 4.6986136],
          [102.5445803, 4.6993631],
          [102.5449096, 4.6997445],
          [102.5452372, 4.7003785],
          [102.5453713, 4.7008503],
          [102.5455625, 4.7013569],
          [102.5453782, 4.7015163],
          [102.5452889, 4.7027561],
          [102.5451521, 4.7042136],
          [102.5452343, 4.7055464],
          [102.545424, 4.7062369],
          [102.5456341, 4.707272],
          [102.5460737, 4.7082628],
          [102.5466645, 4.7089674],
          [102.5472916, 4.7093853],
          [102.5478383, 4.7098485],
          [102.548499, 4.7103814],
          [102.5490482, 4.7104887],
          [102.5497797, 4.710735],
          [102.5504212, 4.7107624],
          [102.5510075, 4.7105023],
          [102.5517525, 4.7104846],
          [102.5523731, 4.7102363],
          [102.5528819, 4.7095966],
          [102.5534146, 4.7088079],
          [102.5538893, 4.7081452],
          [102.5544193, 4.707724],
          [102.554972, 4.7073489],
          [102.5554089, 4.7071682],
          [102.5560415, 4.706828],
          [102.556835, 4.7064201],
          [102.5570889, 4.7061347],
          [102.5573329, 4.7056886],
          [102.5575416, 4.7053685],
          [102.5579226, 4.7049691],
          [102.5581442, 4.7044079],
          [102.5585942, 4.7039747],
          [102.559046, 4.7032888],
          [102.5593677, 4.7031992],
          [102.5596692, 4.7026729],
          [102.5600846, 4.7022739],
          [102.5603844, 4.7020002],
          [102.5608577, 4.7015557],
          [102.5610438, 4.7011665],
          [102.5614932, 4.7007908],
          [102.5616589, 4.7000684],
          [102.561917, 4.6992318],
          [102.5621606, 4.69882],
          [102.5625198, 4.6982597],
          [102.5628541, 4.6980095],
          [102.5633138, 4.6977944],
          [102.5635793, 4.6975551],
          [102.5641288, 4.6975933],
          [102.5645427, 4.6974239],
          [102.5651751, 4.6971068],
          [102.56582, 4.6966519],
          [102.5664762, 4.696243],
          [102.567167, 4.6957541],
          [102.5679498, 4.6952542],
          [102.5684948, 4.6943621],
          [102.5690156, 4.6936308],
          [102.5694227, 4.6928067],
          [102.5700344, 4.6922137],
          [102.5704277, 4.6916882],
          [102.5710741, 4.6910266],
          [102.5719808, 4.6908147],
          [102.5726547, 4.6911181],
          [102.5731313, 4.691753],
          [102.5736433, 4.6922389],
          [102.574222, 4.6930239],
          [102.5749168, 4.69358],
          [102.5758077, 4.6939881],
          [102.5765383, 4.6943607],
          [102.577281, 4.6946416],
          [102.5781731, 4.6948774],
          [102.5789418, 4.694722],
          [102.5796645, 4.6946351],
          [102.5801675, 4.6947764],
          [102.58076, 4.6952399],
          [102.5812966, 4.6954849],
          [102.5815234, 4.6958425],
          [102.5821322, 4.6956055],
          [102.5826412, 4.6952481],
          [102.5841135, 4.6946433],
          [102.5849537, 4.694186],
          [102.585633, 4.6937052],
          [102.5861633, 4.6931894],
          [102.587028, 4.6923296],
          [102.5879962, 4.6913781],
          [102.5884339, 4.6910115],
          [102.5889876, 4.6903923],
          [102.5895971, 4.6901643],
          [102.5901271, 4.689752],
          [102.5905509, 4.6900179],
          [102.591124, 4.6902959],
          [102.5921005, 4.6902647],
          [102.5928255, 4.6898876],
          [102.593539, 4.6895105],
          [102.5942066, 4.6891445],
          [102.5950016, 4.6883765],
          [102.595865, 4.6878273],
          [102.5965678, 4.6872315],
          [102.5972706, 4.6866587],
          [102.5976158, 4.6864874],
          [102.5981674, 4.6864433],
          [102.5991073, 4.6869181],
          [102.6005649, 4.6871645],
          [102.6011845, 4.6873276],
          [102.6019996, 4.6874684],
          [102.6029872, 4.6874602],
          [102.6038703, 4.6878084],
          [102.6036343, 4.6894986],
          [102.6034815, 4.6904872],
          [102.6034674, 4.6912118],
          [102.6035442, 4.6922129],
          [102.6035842, 4.6938005],
          [102.6035472, 4.694502],
          [102.6035909, 4.6951348],
          [102.6036224, 4.6958826],
          [102.6036995, 4.6967916],
          [102.6041775, 4.6980355],
          [102.6048979, 4.6988317],
          [102.605457, 4.6998919],
          [102.6055807, 4.700594],
          [102.6054172, 4.701318],
          [102.6053001, 4.7018929],
          [102.6049625, 4.7031455],
          [102.6045334, 4.7043174],
          [102.6042093, 4.7049835],
          [102.6041266, 4.7056044],
          [102.603881, 4.7068228],
          [102.6036578, 4.7082025],
          [102.603678, 4.7089272],
          [102.6037324, 4.7097096],
          [102.603729, 4.7106873],
          [102.6032652, 4.711859],
          [102.6032262, 4.7131013],
          [102.6031433, 4.7137912],
          [102.6029913, 4.7145153],
          [102.6027363, 4.7151471],
          [102.602469, 4.7159975],
          [102.602775, 4.7171603],
          [102.6030718, 4.7175984],
          [102.6033215, 4.7184505],
          [102.6037097, 4.7191305],
          [102.6037297, 4.7199357],
          [102.6032671, 4.7207279],
          [102.6032861, 4.7218667],
          [102.6032122, 4.7231663],
          [102.6031055, 4.7240632],
          [102.602862, 4.724695],
          [102.6025828, 4.7256948],
          [102.6024174, 4.7269712],
          [102.6022651, 4.7277643],
          [102.6022393, 4.7285234],
          [102.6021787, 4.7294089],
          [102.6017152, 4.7305117],
          [102.601011, 4.7314986],
          [102.6004114, 4.7321177],
          [102.5999729, 4.7327144],
          [102.5990208, 4.7337955],
          [102.5987735, 4.734305],
          [102.5982874, 4.7350093],
          [102.5976897, 4.7352373],
          [102.5969532, 4.7355225],
          [102.596173, 4.7359212],
          [102.5954325, 4.7367596],
          [102.5946238, 4.7380567],
          [102.594656, 4.7386319],
          [102.5948718, 4.7393114],
          [102.5953637, 4.7398423],
          [102.5959596, 4.7402353],
          [102.5969812, 4.7404228],
          [102.5977953, 4.7407707],
          [102.5982648, 4.7411978],
          [102.5985139, 4.7421189],
          [102.5986486, 4.7429706],
          [102.5985883, 4.7437641],
          [102.5979415, 4.7447741],
          [102.5975358, 4.7457736],
          [102.598073, 4.7465116],
          [102.5985529, 4.7471343],
          [102.5989642, 4.7477454],
          [102.5997413, 4.7488754],
          [102.6000944, 4.7496241],
          [102.6001019, 4.7507285],
          [102.5998464, 4.7514868],
          [102.5996266, 4.7519462],
          [102.6000835, 4.7526264],
          [102.6009884, 4.7533542],
          [102.6016752, 4.7539891],
          [102.6023843, 4.7548312],
          [102.602738, 4.7554421],
          [102.6033101, 4.7560191],
          [102.6041926, 4.7565858],
          [102.6048917, 4.7570252],
          [102.6051305, 4.7577047],
          [102.6051041, 4.7586133],
          [102.6047106, 4.7593942],
          [102.6041918, 4.7599217],
          [102.6030635, 4.7606656],
          [102.6033913, 4.7620585],
          [102.6036757, 4.7628417],
          [102.6038681, 4.7636705],
          [102.6036355, 4.7644519],
          [102.6031273, 4.7651979],
          [102.6018952, 4.765999],
          [102.601262, 4.7664225],
          [102.6006743, 4.7669496],
          [102.5999933, 4.7678101],
          [102.5994732, 4.768717],
          [102.5995145, 4.7699596],
          [102.599995, 4.7705478],
          [102.6007168, 4.7709873],
          [102.601313, 4.7712883],
          [102.6024726, 4.7714763],
          [102.6026886, 4.7720177],
          [102.6031806, 4.7725945],
          [102.6039945, 4.7730918],
          [102.6046138, 4.7732895],
          [102.6054277, 4.7737524],
          [102.6061264, 4.7743298],
          [102.6066156, 4.7755623],
          [102.6068418, 4.7765178],
          [102.6072068, 4.7772322],
          [102.6076637, 4.7779238],
          [102.6082808, 4.7788462],
          [102.6088069, 4.7795037],
          [102.6093892, 4.7804029],
          [102.6100083, 4.7807155],
          [102.6105469, 4.7810739],
          [102.6110407, 4.7811216],
          [102.611535, 4.7810081],
          [102.6125353, 4.7807585],
          [102.6139259, 4.780533],
          [102.6149145, 4.7803523],
          [102.6155136, 4.7798942],
          [102.61663, 4.7792883],
          [102.6172755, 4.7786347],
          [102.6181163, 4.7780738],
          [102.6186007, 4.7775003],
          [102.6191427, 4.776927],
          [102.6204759, 4.7766554],
          [102.6214765, 4.7763596],
          [102.6222122, 4.7761895],
          [102.6229941, 4.7760196],
          [102.6240637, 4.7755976],
          [102.6256735, 4.7751198],
          [102.6267424, 4.7749738],
          [102.6274895, 4.7747807],
          [102.6282946, 4.7745534],
          [102.6294235, 4.7736828],
          [102.6299201, 4.7728793],
          [102.6302436, 4.7723627],
          [102.6305912, 4.7715818],
          [102.6308926, 4.770835],
          [102.6314239, 4.7699971],
          [102.6319437, 4.7692051],
          [102.6328882, 4.7684721],
          [102.6333833, 4.7681401],
          [102.6340512, 4.7676707],
          [102.6345119, 4.7673271],
          [102.6352611, 4.766628],
          [102.6361363, 4.7660097],
          [102.636987, 4.76584],
          [102.6376423, 4.7656351],
          [102.6384244, 4.7653501],
          [102.6392292, 4.7651802],
          [102.6405043, 4.7651614],
          [102.6414691, 4.7651186],
          [102.6421706, 4.7648908],
          [102.6431248, 4.7646755],
          [102.6440336, 4.7643334],
          [102.6451365, 4.764199],
          [102.6461129, 4.7642367],
          [102.6468827, 4.7641703],
          [102.6477904, 4.7640467],
          [102.6491692, 4.7639362],
          [102.6503411, 4.7639056],
          [102.6509151, 4.763942],
          [102.6515484, 4.7635759],
          [102.6521133, 4.7630027],
          [102.6526552, 4.7623833],
          [102.6531161, 4.7619822],
          [102.6538294, 4.7617085],
          [102.6545643, 4.7617454],
          [102.6549663, 4.7617697],
          [102.6557129, 4.7617491],
          [102.6569877, 4.7618339],
          [102.6576767, 4.7617331],
          [102.6721046, 4.7255343],
          [102.6951901, 4.7219947],
          [102.7088046, 4.710786],
          [102.7230111, 4.6942676],
          [102.7378095, 4.689548],
          [102.7656304, 4.6848283],
          [102.7916756, 4.6801087],
          [102.8094337, 4.6824685],
          [102.8230482, 4.6883681],
          [102.8348869, 4.6842384],
          [102.8485014, 4.6718491],
          [102.8638917, 4.64943],
          [102.8680353, 4.6329101],
          [102.8745466, 4.61934],
          [102.8597482, 4.6051795],
          [102.8627079, 4.593969],
          [102.8745466, 4.5827582],
          [102.8686272, 4.5733175],
          [102.8396224, 4.5638766],
          [102.8325192, 4.5514853],
          [102.8544208, 4.4989672],
          [102.8775063, 4.4789031],
          [102.9183498, 4.4712314],
          [102.9597853, 4.4853945],
          [102.9751756, 4.4842143],
          [102.9935256, 4.4588385],
          [103.0065482, 4.4493961],
          [103.0118756, 4.4322816],
          [103.0219385, 4.4210683],
          [103.0195708, 4.4122157],
          [103.0018127, 4.3773941],
          [103.0047724, 4.3655899],
          [103.0130595, 4.3514245],
          [103.0059563, 4.3419808],
          [102.9751756, 4.3478831],
          [102.9568256, 4.3325369],
          [102.9473547, 4.3018436],
          [102.9301885, 4.298302],
          [102.9290047, 4.2829548],
          [102.9295966, 4.261114],
          [102.9224934, 4.2498983],
          [102.9088788, 4.2321888],
          [102.9230853, 4.193817],
          [102.9171659, 4.1719737],
          [102.8976321, 4.1578048],
          [102.8976321, 4.1465875],
          [102.9094708, 4.1265141],
          [102.9301885, 4.1188389],
          [102.9562337, 4.1265141],
          [102.9852385, 4.1424548],
          [103.0053644, 4.1406836],
          [103.0302256, 4.1341893],
          [103.0586386, 4.1282853],
          [103.0734369, 4.1123444],
          [103.1018499, 4.1088019],
          [103.1148724, 4.1005361],
          [103.1326305, 4.0721954],
          [103.1497966, 4.0627483],
          [103.1657789, 4.0438537],
          [103.182945, 4.046806],
          [103.1935999, 4.0556629],
          [103.2048466, 4.0556629],
          [103.2125418, 4.0361777],
          [103.2409547, 4.0367681],
          [103.2516096, 4.0107872],
          [103.2711434, 3.9966154],
          [103.2681838, 3.9729952],
          [103.2806144, 3.943469],
          [103.302516, 3.9328394],
          [103.3244176, 3.9405164],
          [103.3338886, 3.9682711],
          [103.3297451, 3.9954344],
          [103.3196821, 4.0261397],
          [103.320866, 4.054482],
          [103.3149467, 4.0780998],
          [103.3048838, 4.0922701],
          [103.3042918, 4.1200197],
          [103.321458, 4.1601663],
          [103.3179063, 4.1944073],
          [103.3239063, 4.1938441],
          [103.3332241, 4.1941749],
          [103.35331, 4.1887753],
          [103.3748949, 4.1852229],
          [103.4072719, 4.1809011],
          [103.4229699, 4.1828446],
          [103.4374951, 4.185166],
          [103.444368, 4.186782],
          [103.44468, 4.1850954],
          [103.4446317, 4.1843411],
          [103.4443903, 4.1833245],
          [103.4442186, 4.1822064],
          [103.4444547, 4.1810026],
          [103.44468, 4.1807886],
          [103.4445357, 4.1795846],
          [103.4442437, 4.1788423],
          [103.4435481, 4.1781242],
          [103.4430814, 4.1773003],
          [103.441869, 4.1761714],
          [103.4415257, 4.1757808],
          [103.4412092, 4.1744433],
          [103.4412503, 4.173888],
          [103.438307, 4.1729987],
          [103.4379262, 4.1735016],
          [103.4365368, 4.1739297],
          [103.4355926, 4.1765299],
          [103.4323954, 4.1765941],
          [103.4239498, 4.1760952],
          [103.4221821, 4.1764779],
          [103.4204417, 4.1765325],
          [103.4183527, 4.1762204],
          [103.4166784, 4.1747466],
          [103.4147883, 4.1732689],
          [103.4130701, 4.1700357],
          [103.4114846, 4.1654706],
          [103.4104706, 4.161209],
          [103.4094411, 4.1499223],
          [103.409000328973306, 4.139901799130778],
          [103.40866620822213, 4.135059713671246],
          [103.4084066, 4.1347106],
          [103.4083543, 4.1339615],
          [103.4084976, 4.1334475],
          [103.408554806975502, 4.1334452842934],
          [103.40852322932966, 4.132987660337912],
          [103.4082779, 4.1329062],
          [103.4077903, 4.1317431],
          [103.4076244, 4.130702],
          [103.407666940550214, 4.130489933131591],
          [103.406696446987752, 4.129968699460997],
          [103.4061478, 4.1302046],
          [103.4054303, 4.1302702],
          [103.404311, 4.1297791],
          [103.403285, 4.1290392],
          [103.4025973, 4.1282308],
          [103.4017833, 4.1273697],
          [103.4011818, 4.1263163],
          [103.4006512, 4.1249725],
          [103.4003911, 4.1237341],
          [103.400129929239171, 4.123566809042427],
          [103.3989031, 4.1236688],
          [103.3988364, 4.1233292],
          [103.3975817, 4.1242458],
          [103.3969985, 4.1249509],
          [103.3962563, 4.1256912],
          [103.3951783, 4.1254444],
          [103.3930426, 4.1246219],
          [103.3920074, 4.1240915],
          [103.3908478, 4.1232124],
          [103.3897021, 4.122238],
          [103.3888046, 4.1210981],
          [103.3868903, 4.116823],
          [103.3861662, 4.1147899],
          [103.386101328739016, 4.114447019743527],
          [103.386076, 4.114385],
          [103.3853863, 4.1106677],
          [103.3854158, 4.1053034],
          [103.385769, 4.1003332],
          [103.3860437, 4.0943823],
          [103.3872668, 4.087596],
          [103.3914912, 4.0679585],
          [103.3916487, 4.0672647],
          [103.3916908, 4.0670794],
          [103.3917308, 4.0669029],
          [103.392679, 4.0640006],
          [103.3976777, 4.0529949],
          [103.3974719, 4.0508516],
          [103.3973125, 4.0505151],
          [103.3970116, 4.0499534],
          [103.3968457, 4.0483275],
          [103.3973212, 4.0444618],
          [103.3979132, 4.0414369],
          [103.4045525, 4.0244918],
          [103.4071794, 4.0185963],
          [103.409963, 4.0118061],
          [103.4103294, 4.0100284],
          [103.4101283, 4.0090385],
          [103.4110155, 4.0087682],
          [103.4127922, 4.0059673],
          [103.4147547, 4.0026882],
          [103.4182453, 3.9957825],
          [103.4198822, 3.9965101],
          [103.4199515, 3.9965414],
          [103.419985430522161, 3.996530780187221],
          [103.4199928, 3.9965099],
          [103.4199933, 3.9964793],
          [103.419984, 3.99645],
          [103.419966596472761, 3.996426380927317],
          [103.4199408, 3.9964121],
          [103.418294620165454, 3.99569990528204],
          [103.4177907, 3.9954833],
          [103.4174741, 3.9951906],
          [103.4175742, 3.9950236],
          [103.4177744, 3.9949409],
          [103.4181071, 3.9949786],
          [103.4185688, 3.9948799],
          [103.4196365, 3.9952268],
          [103.4198722, 3.9950395],
          [103.4336538, 3.9974537],
          [103.4341081, 3.9975111],
          [103.4346903, 3.9973641],
          [103.4352255, 3.9970607],
          [103.4356771, 3.9966218],
          [103.4462304, 3.9827991],
          [103.4464648, 3.982388],
          [103.4465913, 3.9819051],
          [103.4466646, 3.9814058],
          [103.4461902, 3.9752887],
          [103.4461864, 3.9748108],
          [103.4461706, 3.9747591],
          [103.4461399, 3.9747146],
          [103.4460972, 3.9746813],
          [103.4460464, 3.9746624],
          [103.4459923, 3.9746596],
          [103.445939, 3.9746735],
          [103.4458925, 3.974703],
          [103.4458573, 3.9747453],
          [103.4458367, 3.9747962],
          [103.4458327, 3.9748511],
          [103.4458959, 3.9752984],
          [103.4463706, 3.9814152],
          [103.4462387, 3.9820375],
          [103.4459808, 3.9825882],
          [103.4354371, 3.9965431],
          [103.4350874, 3.9968895],
          [103.4346815, 3.9970919],
          [103.4341214, 3.9972096],
          [103.4337854, 3.9972309],
          [103.433714746609851, 3.997218728467669],
          [103.4336091, 3.9972211],
          [103.4205249, 3.9949465],
          [103.4203856, 3.9947929],
          [103.4203581, 3.9946683],
          [103.4201622, 3.9945191],
          [103.4225798, 3.994586],
          [103.4227035, 3.9945108],
          [103.4239049, 3.992936],
          [103.42398155407119, 3.992835571039941],
          [103.427757696771096, 3.987885934126493],
          [103.427778, 3.9878593],
          [103.4277976, 3.9878336],
          [103.4291982, 3.9859977],
          [103.429363448090655, 3.985781170976175],
          [103.4297149, 3.9853205],
          [103.4296983, 3.9851866],
          [103.4293122, 3.9849232],
          [103.4293017, 3.9847277],
          [103.429452208998967, 3.984622595570149],
          [103.4294491, 3.9845733],
          [103.429507273316716, 3.984261974156701],
          [103.4294934, 3.9839838],
          [103.4295839, 3.9836233],
          [103.4295246, 3.9834471],
          [103.4294153, 3.9833008],
          [103.4285516, 3.9828704],
          [103.4281438, 3.982399],
          [103.4273283, 3.9825501],
          [103.4272586, 3.9822219],
          [103.4307471, 3.9770476],
          [103.4309172, 3.9768205],
          [103.430945900185293, 3.97680833449984],
          [103.4309726, 3.9767762],
          [103.4312706, 3.9766707],
          [103.4316402, 3.9767113],
          [103.4319507, 3.9767227],
          [103.4322709, 3.9767074],
          [103.4325623, 3.9766562],
          [103.4328928, 3.9765655],
          [103.4393946, 3.973967],
          [103.4397569, 3.9737707],
          [103.4401024, 3.9735239],
          [103.440403, 3.9732435],
          [103.440653, 3.972907],
          [103.4408614, 3.9725599],
          [103.4420283, 3.9696671],
          [103.4421038, 3.9694788],
          [103.4421177, 3.9694314],
          [103.4421161, 3.9693828],
          [103.4420994, 3.9693371],
          [103.4420693, 3.9692989],
          [103.4420288, 3.9692718],
          [103.4419818, 3.9692587],
          [103.4419358, 3.9692603],
          [103.4418923, 3.9692754],
          [103.4418551, 3.9693025],
          [103.4418276, 3.9693394],
          [103.4418122, 3.9693826],
          [103.4418106, 3.969432],
          [103.4418247, 3.9694793],
          [103.4418333, 3.969536],
          [103.4418257, 3.9695896],
          [103.4406575, 3.9724449],
          [103.4404732, 3.9727885],
          [103.4402315, 3.9730908],
          [103.4399618, 3.973337],
          [103.439636, 3.9735524],
          [103.4393045, 3.9737398],
          [103.4329549, 3.9763442],
          [103.432528, 3.976482],
          [103.4321891, 3.9765341],
          [103.4317059, 3.9765335],
          [103.4311203, 3.9763776],
          [103.4296005, 3.9757772],
          [103.4295711, 3.9755621],
          [103.4294948, 3.9755391],
          [103.4293569, 3.9754977],
          [103.4292729, 3.9754724],
          [103.4291889, 3.9756314],
          [103.4284923, 3.9753946],
          [103.4283519, 3.9753918],
          [103.4282195, 3.9754377],
          [103.4270259, 3.9772395],
          [103.426637, 3.977285],
          [103.4247648, 3.9760274],
          [103.4225867, 3.9754174],
          [103.4226512, 3.9759097],
          [103.4212135, 3.9782857],
          [103.4195076, 3.9810792],
          [103.4191643, 3.9816678],
          [103.4189175, 3.9818391],
          [103.4166993, 3.9854272],
          [103.4164982, 3.9856333],
          [103.4162675, 3.9856814],
          [103.4160636, 3.9856494],
          [103.4112075, 3.9838085],
          [103.4109862, 3.9837014],
          [103.410887, 3.9834017],
          [103.4109245, 3.9831021],
          [103.412831256104752, 3.97991429998796],
          [103.4128352, 3.9799077],
          [103.4130129, 3.9796031],
          [103.4131043, 3.9794556],
          [103.413242, 3.9792276],
          [103.4137677, 3.9789708],
          [103.4160133, 3.9789798],
          [103.416097, 3.9788413],
          [103.4161877, 3.9786912],
          [103.4169377, 3.9774506],
          [103.4169972, 3.9773523],
          [103.4178749, 3.9759003],
          [103.4179353, 3.9758005],
          [103.4184221, 3.9749952],
          [103.4185595, 3.974774],
          [103.4186568, 3.9746171],
          [103.4197126, 3.9729156],
          [103.4197797, 3.9728075],
          [103.4204752, 3.9716868],
          [103.4205815, 3.9715155],
          [103.4210597, 3.9707448],
          [103.4211794, 3.9702971],
          [103.4212161, 3.9701919],
          [103.4214555, 3.9695069],
          [103.4221101, 3.9697415],
          [103.4283698, 3.9716593],
          [103.4284424, 3.9714349],
          [103.4284746, 3.9713351],
          [103.4286773, 3.970708],
          [103.4280126, 3.970493],
          [103.4281043, 3.9703209],
          [103.4283008, 3.970165],
          [103.4285295, 3.9700767],
          [103.4295575, 3.9702588],
          [103.4309158, 3.9705314],
          [103.4316561, 3.9705244],
          [103.4317018, 3.9705127],
          [103.431742, 3.9704734],
          [103.4317473, 3.9704281],
          [103.4332014, 3.9704111],
          [103.4333631, 3.9700977],
          [103.4334222, 3.9700956],
          [103.4334526, 3.9700883],
          [103.4334844, 3.9700657],
          [103.4339169, 3.9694321],
          [103.4339431, 3.9693501],
          [103.4339426, 3.9692785],
          [103.4339212, 3.969237],
          [103.4338766, 3.9692072],
          [103.4338494, 3.9692482],
          [103.4337909, 3.9692292],
          [103.4324317, 3.9687875],
          [103.4328719, 3.9674593],
          [103.4345363, 3.9679779],
          [103.4356531, 3.9668137],
          [103.4370387, 3.96698],
          [103.4371573, 3.9669949],
          [103.4374622, 3.9671712],
          [103.4375581, 3.9672267],
          [103.4376661, 3.9672637],
          [103.4384384, 3.9676321],
          [103.4384897, 3.9676862],
          [103.4385383, 3.9677723],
          [103.4385703, 3.9678208],
          [103.4386168, 3.9678556],
          [103.4386692, 3.9678722],
          [103.4387242, 3.9678712],
          [103.438776, 3.9678525],
          [103.438819, 3.9678183],
          [103.4388487, 3.9677721],
          [103.4388617, 3.9677221],
          [103.4388589, 3.9676705],
          [103.4388406, 3.9676222],
          [103.4388085, 3.9675817],
          [103.4387656, 3.9675528],
          [103.437722, 3.9670694],
          [103.4376397, 3.9669642],
          [103.4376052, 3.9669],
          [103.4378019, 3.9663619],
          [103.4376706, 3.9657734],
          [103.4377838, 3.9653432],
          [103.4379736, 3.9651254],
          [103.4386271, 3.9650496],
          [103.4392689, 3.9649013],
          [103.4395275, 3.9642433],
          [103.439411, 3.9640104],
          [103.439523, 3.9635542],
          [103.4398004, 3.9636998],
          [103.4400158, 3.9636452],
          [103.4401831, 3.9631082],
          [103.439594, 3.9626189],
          [103.439342, 3.9625271],
          [103.439618, 3.962053],
          [103.4393576, 3.962012],
          [103.4384584, 3.961971],
          [103.4375032, 3.961668],
          [103.4364104, 3.961929],
          [103.4359406, 3.9617622],
          [103.4352665, 3.9618332],
          [103.4347754, 3.9614948],
          [103.4338841, 3.9598991],
          [103.4338648, 3.9595939],
          [103.4343309, 3.9579465],
          [103.4342286, 3.9572514],
          [103.434442094333264, 3.956709882597673],
          [103.4335637, 3.9567059],
          [103.4332908, 3.9563323],
          [103.433286652640049, 3.956331914193478],
          [103.4310365, 3.9563839],
          [103.42897273355068, 3.957760040506963],
          [103.4288097, 3.9581662],
          [103.4280292, 3.9589428],
          [103.4264771, 3.9598348],
          [103.425296232171974, 3.960211569105449],
          [103.42187528074362, 3.962492694391913],
          [103.4215015, 3.9630217],
          [103.4209154, 3.9632777],
          [103.4194999, 3.9650686],
          [103.41894, 3.9655452],
          [103.4107873, 3.968573],
          [103.4093886, 3.9687558],
          [103.4083122, 3.968467],
          [103.4069857, 3.9679738],
          [103.4043422, 3.9667233],
          [103.4035807, 3.9663459],
          [103.4034292, 3.9660853],
          [103.4031096, 3.9660435],
          [103.402731, 3.9661343],
          [103.4026778, 3.9665075],
          [103.4023552, 3.9668202],
          [103.4018236, 3.9669544],
          [103.401337, 3.9668399],
          [103.400877, 3.9666083],
          [103.3983809, 3.964686],
          [103.3968938, 3.9632348],
          [103.3950631, 3.9611969],
          [103.3943625, 3.9606642],
          [103.3919287, 3.9572488],
          [103.3862633, 3.9486511],
          [103.3821016, 3.9417329],
          [103.3793539, 3.9370512],
          [103.3786539, 3.9366793],
          [103.3776967, 3.9354988],
          [103.3762407, 3.9337031],
          [103.3758046, 3.9307597],
          [103.3729284, 3.9243915],
          [103.3702934, 3.9210359],
          [103.3682142, 3.9162128],
          [103.3663435, 3.9050621],
          [103.3662286, 3.9030064],
          [103.3662091, 3.9023062],
          [103.3660635, 3.9013224],
          [103.3659192, 3.8999171],
          [103.3657358, 3.8993585],
          [103.3660574, 3.8980369],
          [103.3661319, 3.8967423],
          [103.3663274, 3.8949057],
          [103.3666365, 3.8929532],
          [103.3668809, 3.8907562],
          [103.3671068, 3.8898752],
          [103.3671355, 3.8894207],
          [103.3664907, 3.8874781],
          [103.3661211, 3.8853807],
          [103.3662412, 3.8810916],
          [103.3664627, 3.8789215],
          [103.3666259, 3.8771305],
          [103.3668728, 3.8760241],
          [103.3677099, 3.8708738],
          [103.3682169, 3.8679198],
          [103.3686107, 3.8663431],
          [103.3689163, 3.8651054],
          [103.3692286, 3.8623853],
          [103.3693673, 3.8617708],
          [103.3707836, 3.8547407],
          [103.3763075, 3.8340671],
          [103.3764452, 3.8339725],
          [103.3765492, 3.8339919],
          [103.3766994, 3.8338989],
          [103.3767289, 3.8337357],
          [103.376694, 3.8335939],
          [103.376525, 3.833551],
          [103.3763963, 3.8334708],
          [103.3764754, 3.8332861],
          [103.3764607, 3.8332272],
          [103.3764607, 3.8331175],
          [103.376411, 3.83308],
          [103.3763051, 3.8331095],
          [103.3762555, 3.8331215],
          [103.3761817, 3.8330867],
          [103.3761147, 3.8330747],
          [103.3760248, 3.8331162],
          [103.3759698, 3.8332232],
          [103.3758719, 3.8332419],
          [103.3757418, 3.8331951],
          [103.3754253, 3.8328633],
          [103.3752483, 3.8325261],
          [103.3750123, 3.8317232],
          [103.3749694, 3.831295],
          [103.3750498, 3.8308561],
          [103.3751142, 3.8307223],
          [103.3751142, 3.8305403],
          [103.3750749, 3.8289625],
          [103.376466, 3.8237373],
          [103.376863, 3.8222172],
          [103.3769917, 3.8211574],
          [103.3770615, 3.8208845],
          [103.3772546, 3.8206168],
          [103.3773699, 3.8205553],
          [103.377386, 3.820665],
          [103.3774585, 3.8207292],
          [103.3775765, 3.8205847],
          [103.3775148, 3.8204991],
          [103.3777106, 3.8203974],
          [103.377783, 3.8202154],
          [103.3776408, 3.8201512],
          [103.3777079, 3.8200682],
          [103.3776489, 3.8199076],
          [103.3775684, 3.8196587],
          [103.3775765, 3.8195356],
          [103.3774504, 3.8193965],
          [103.3774236, 3.8192172],
          [103.3775389, 3.8189763],
          [103.3777463, 3.8186162],
          [103.377791, 3.8184758],
          [103.3777159, 3.8184009],
          [103.3776972, 3.8182403],
          [103.3773458, 3.8180209],
          [103.3772331, 3.818061],
          [103.3769515, 3.8178389],
          [103.3769488, 3.8177024],
          [103.3767289, 3.817606],
          [103.3766913, 3.8176863],
          [103.3764794, 3.8175338],
          [103.3763829, 3.8173893],
          [103.3760127, 3.8172929],
          [103.3759537, 3.8173759],
          [103.3755305, 3.817706],
          [103.3754581, 3.8177031],
          [103.3752633, 3.8176393],
          [103.3749828, 3.8175498],
          [103.3746218, 3.8173415],
          [103.3742291, 3.8171109],
          [103.3740534, 3.8168653],
          [103.3737221, 3.8163188],
          [103.3733976, 3.8159494],
          [103.373352, 3.8158183],
          [103.3733895, 3.8156551],
          [103.3732742, 3.8155694],
          [103.3732957, 3.8154838],
          [103.3734083, 3.8154891],
          [103.3733976, 3.8153821],
          [103.373124, 3.815168],
          [103.372941, 3.8144863],
          [103.3728353, 3.8139737],
          [103.3727729, 3.8134706],
          [103.3728558, 3.810697],
          [103.3730125, 3.8094867],
          [103.3730891, 3.8090634],
          [103.3731322, 3.8083699],
          [103.3732357, 3.8071139],
          [103.3730999, 3.8062774],
          [103.3734438, 3.8058671],
          [103.3734338, 3.8057247],
          [103.3733949, 3.8056632],
          [103.3733922, 3.8055588],
          [103.3734137, 3.8053969],
          [103.3734995, 3.8053514],
          [103.3735674, 3.8051551],
          [103.3737785, 3.8049392],
          [103.3737999, 3.8046154],
          [103.3739206, 3.8044495],
          [103.3738992, 3.8041711],
          [103.373749, 3.8036332],
          [103.3738232, 3.8031796],
          [103.3736926, 3.8030444],
          [103.3737141, 3.8028624],
          [103.3733171, 3.802651],
          [103.3731857, 3.8023379],
          [103.3732957, 3.8022201],
          [103.3731669, 3.8020033],
          [103.3731937, 3.8018615],
          [103.3731616, 3.801717],
          [103.3730301, 3.8016581],
          [103.3729805, 3.8015363],
          [103.3730328, 3.8014145],
          [103.3732541, 3.8012245],
          [103.3733493, 3.8012379],
          [103.3734325, 3.801159],
          [103.3734673, 3.8010747],
          [103.3733097, 3.8010922],
          [103.3732152, 3.8011108],
          [103.3730918, 3.8012673],
          [103.3729242, 3.8012607],
          [103.3728397, 3.8011643],
          [103.3727995, 3.801001],
          [103.3726868, 3.8010532],
          [103.3726841, 3.8011215],
          [103.3725688, 3.8011041],
          [103.3725165, 3.8011804],
          [103.3724266, 3.8011309],
          [103.3723529, 3.8010425],
          [103.3723167, 3.8009355],
          [103.3723421, 3.8008525],
          [103.3724722, 3.8007187],
          [103.372487, 3.8005769],
          [103.3724266, 3.8004671],
          [103.3723475, 3.8004725],
          [103.3722965, 3.8004337],
          [103.3722416, 3.8003454],
          [103.372145, 3.8004042],
          [103.3720364, 3.800439],
          [103.3719733, 3.8004404],
          [103.3718325, 3.8004792],
          [103.3716421, 3.8004805],
          [103.3714932, 3.8006919],
          [103.3713724, 3.8006073],
          [103.3712813, 3.8006384],
          [103.3712639, 3.8007468],
          [103.3712344, 3.8007441],
          [103.3711942, 3.8007],
          [103.3711888, 3.8005728],
          [103.3711553, 3.8005581],
          [103.3711204, 3.8006893],
          [103.3711579, 3.8007307],
          [103.3711271, 3.8007575],
          [103.371048, 3.8007495],
          [103.3710091, 3.8007013],
          [103.3709796, 3.8007749],
          [103.3710158, 3.8008137],
          [103.3710654, 3.8008445],
          [103.3710426, 3.800882],
          [103.3710963, 3.8010345],
          [103.370879, 3.801345],
          [103.3707127, 3.801444],
          [103.3706993, 3.8015591],
          [103.3704954, 3.8017089],
          [103.3701548, 3.801741],
          [103.369841, 3.8017277],
          [103.3697385, 3.8018219],
          [103.3698222, 3.801998],
          [103.3697176, 3.8021532],
          [103.3694708, 3.8023566],
          [103.3693019, 3.8024074],
          [103.3691972, 3.8023887],
          [103.3690631, 3.8026456],
          [103.3688861, 3.8029052],
          [103.3687963, 3.8029748],
          [103.3687144, 3.8030123],
          [103.3686259, 3.803019],
          [103.3685362, 3.8029899],
          [103.3684248, 3.8029226],
          [103.3683081, 3.8029119],
          [103.3682343, 3.8029601],
          [103.3682236, 3.8030457],
          [103.3682679, 3.803122],
          [103.3682612, 3.8032184],
          [103.3681673, 3.803288],
          [103.3681914, 3.8033736],
          [103.3681217, 3.8035422],
          [103.3681136, 3.8037001],
          [103.3679527, 3.8038152],
          [103.3677569, 3.8039115],
          [103.3676925, 3.8040453],
          [103.3676, 3.8041243],
          [103.3675396, 3.8042407],
          [103.3674511, 3.8042862],
          [103.3674055, 3.8044414],
          [103.3674082, 3.8046984],
          [103.3671319, 3.8048911],
          [103.3668718, 3.8049874],
          [103.3664587, 3.8053487],
          [103.3663112, 3.8057234],
          [103.3663729, 3.805793],
          [103.3664989, 3.8057876],
          [103.3664212, 3.8059937],
          [103.3662146, 3.8061329],
          [103.3657426, 3.8062881],
          [103.3653341, 3.8064854],
          [103.3611103, 3.8071858],
          [103.3607697, 3.8072515],
          [103.3605713, 3.8073961],
          [103.3602549, 3.8078086],
          [103.3601153, 3.8078778],
          [103.3591604, 3.8079206],
          [103.3581774, 3.8077405],
          [103.3573204, 3.8076637],
          [103.3569688, 3.8077749],
          [103.3557107, 3.8076849],
          [103.353822, 3.8075887],
          [103.3523476, 3.8074442],
          [103.3513495, 3.8072569],
          [103.3511217, 3.8073054],
          [103.3509875, 3.8073646],
          [103.3507919, 3.8074549],
          [103.3503252, 3.8075674],
          [103.3497351, 3.8075406],
          [103.3490914, 3.8071552],
          [103.3485389, 3.8069465],
          [103.3480185, 3.8068019],
          [103.3475733, 3.8067698],
          [103.3470315, 3.8068715],
          [103.346723, 3.8067645],
          [103.3465621, 3.806644],
          [103.346554, 3.8065236],
          [103.3453566, 3.8063447],
          [103.3445446, 3.8062233],
          [103.3443813, 3.8061989],
          [103.3441617, 3.8062121],
          [103.3430027, 3.8059108],
          [103.3418028, 3.8055988],
          [103.3417743, 3.803569],
          [103.3417475, 3.803494],
          [103.341769, 3.8032478],
          [103.3417422, 3.8021024],
          [103.3417177, 3.8009544],
          [103.3416671, 3.8001968],
          [103.3415919, 3.7995706],
          [103.3416357, 3.7994527],
          [103.3408352, 3.7965967],
          [103.3404565, 3.7952477],
          [103.3398273, 3.7934961],
          [103.3389598, 3.7920685],
          [103.3379437, 3.7903514],
          [103.3371431, 3.7888242],
          [103.3347943, 3.7848902],
          [103.3336939, 3.7829037],
          [103.3324827, 3.779747],
          [103.3312942, 3.7763902],
          [103.329341, 3.7712859],
          [103.3284354, 3.7682222],
          [103.3277727, 3.7652356],
          [103.3268781, 3.7590696],
          [103.3262255, 3.7547237],
          [103.3259099, 3.7542245],
          [103.3257129, 3.7520935],
          [103.3256688, 3.7469138],
          [103.326117, 3.7407678],
          [103.326378, 3.7396357],
          [103.3265612, 3.7375622],
          [103.3267487, 3.7360122],
          [103.3285384, 3.7269135],
          [103.3316454, 3.7158351],
          [103.3330043, 3.7127023],
          [103.3358626, 3.7049091],
          [103.3404077, 3.6941076],
          [103.3454863, 3.6834103],
          [103.3560672, 3.6658974],
          [103.3581944, 3.6623766],
          [103.3657599, 3.6508121],
          [103.366990993472371, 3.648386683576122],
          [103.3674009, 3.6470354],
          [103.36812670256576, 3.646255582055927],
          [103.3687718, 3.6451053],
          [103.3698046, 3.6441885],
          [103.369968377892576, 3.644043102104141],
          [103.371099, 3.642518],
          [103.3720259, 3.6421601],
          [103.387397, 3.6204549],
          [103.3882005, 3.6190746],
          [103.3906124, 3.6164328],
          [103.3920336, 3.6154225],
          [103.3926392, 3.6153592],
          [103.3928157, 3.6150496],
          [103.3930542, 3.6148413],
          [103.3933141, 3.6147186],
          [103.3938385, 3.6137765],
          [103.3947277, 3.6125774],
          [103.3957766, 3.6112929],
          [103.4062684, 3.5972842],
          [103.4194762, 3.5791078],
          [103.4416997, 3.5525845],
          [103.4466947, 3.5471444],
          [103.4525449, 3.5423549],
          [103.4626369, 3.5346615],
          [103.4655873, 3.532947],
          [103.4670186, 3.5330499],
          [103.4714131, 3.5302753],
          [103.4749804, 3.5220726],
          [103.4745782, 3.5120158],
          [103.4745778, 3.5085773],
          [103.4753487, 3.505395],
          [103.4752709, 3.5012249],
          [103.4748853, 3.4939167],
          [103.474977, 3.4928679],
          [103.4743105, 3.4899651],
          [103.4740408, 3.4834659],
          [103.4739272, 3.4807291],
          [103.473851, 3.478892],
          [103.4736126, 3.4776325],
          [103.4731251, 3.4752001],
          [103.4729004, 3.4740788],
          [103.4722633, 3.471446],
          [103.4716626, 3.4696436],
          [103.4712033, 3.4693646],
          [103.4696484, 3.4695489],
          [103.4693789, 3.4691761],
          [103.4692388, 3.4689824],
          [103.4678711, 3.4670906],
          [103.4662644, 3.4657802],
          [103.4662015, 3.4657128],
          [103.4660143, 3.4655119],
          [103.4651609, 3.4646546],
          [103.4638534, 3.463487],
          [103.4635111, 3.4630591],
          [103.4631858, 3.4630902],
          [103.4629951, 3.4628124],
          [103.4629884, 3.4625119],
          [103.462975, 3.4622337],
          [103.4627011, 3.4622192],
          [103.4615343, 3.4615977],
          [103.4592172, 3.4586293],
          [103.4565151, 3.4557826],
          [103.4548037, 3.454212],
          [103.4541134, 3.4535785],
          [103.4536505, 3.453372],
          [103.4524497, 3.4528363],
          [103.4520386, 3.4527543],
          [103.4512883, 3.4527932],
          [103.4510924, 3.452952],
          [103.443518, 3.4549131],
          [103.4409464, 3.4468044],
          [103.4395271, 3.4418156],
          [103.439016, 3.4379062],
          [103.4357822, 3.4273011],
          [103.4351872, 3.4255958],
          [103.4345301, 3.4237127],
          [103.4334446, 3.4221012],
          [103.4335996, 3.4208967],
          [103.4330868, 3.4189169],
          [103.4314529, 3.4150766],
          [103.4309997, 3.4129895],
          [103.4307612, 3.4106638],
          [103.4301649, 3.408246],
          [103.4292023, 3.4051452],
          [103.4282566, 3.4018144],
          [103.4282089, 3.400264],
          [103.4275317, 3.3951828],
          [103.4254544, 3.3790754],
          [103.4245356, 3.3712709],
          [103.4238924, 3.3633924],
          [103.4235338, 3.3562913],
          [103.423605, 3.3491973],
          [103.4243233, 3.3351386],
          [103.424671, 3.331836],
          [103.4260897, 3.3203007],
          [103.4279209, 3.3090059],
          [103.4286455, 3.3051696],
          [103.4296899, 3.2992401],
          [103.4311306, 3.2933521],
          [103.4314624, 3.2910512],
          [103.4312258, 3.2895028],
          [103.4333257, 3.2806052],
          [103.435767, 3.2717311],
          [103.4406116, 3.2531043],
          [103.4412158, 3.2476449],
          [103.4429629, 3.2410206],
          [103.4433664, 3.2364156],
          [103.4430466, 3.2343671],
          [103.4430409, 3.2317481],
          [103.4437685, 3.2265365],
          [103.4459002, 3.2166448],
          [103.445705, 3.2156614],
          [103.4459704, 3.2113256],
          [103.4466653, 3.2066373],
          [103.4467424, 3.2056541],
          [103.4470484, 3.2054013],
          [103.4470318, 3.2050299],
          [103.4469516, 3.2032333],
          [103.4468006, 3.2025867],
          [103.4463942, 3.1986754],
          [103.4462091, 3.1960563],
          [103.4459037, 3.1946193],
          [103.4456665, 3.1936753],
          [103.4453735, 3.1930008],
          [103.4450609, 3.1921952],
          [103.4446248, 3.18827],
          [103.44402, 3.1836267],
          [103.4438093, 3.1804868],
          [103.4437899, 3.178229],
          [103.4435077, 3.1682277],
          [103.4460767, 3.1402058],
          [103.4488339, 3.1133084],
          [103.4488776, 3.1040975],
          [103.4473637, 3.0885155],
          [103.4465477, 3.0810797],
          [103.445426, 3.0767388],
          [103.4440658, 3.0735714],
          [103.4436277, 3.0730187],
          [103.4420197, 3.0721526],
          [103.4403353, 3.0708079],
          [103.4376841, 3.0696101],
          [103.4360575, 3.0683447],
          [103.4361063, 3.0664262],
          [103.4342149, 3.0578623],
          [103.433839, 3.0523176],
          [103.4339095, 3.0473132],
          [103.4338743, 3.0441297],
          [103.4336511, 3.0422736],
          [103.4337685, 3.0389139],
          [103.4339176, 3.030596],
          [103.4337568, 3.0187907],
          [103.4339906, 3.0069508],
          [103.434140159517796, 2.9995],
          [103.4343153, 2.9907748],
          [103.434331, 2.9899902],
          [103.4342711, 2.9865388],
          [103.4342232, 2.9837738],
          [103.4338697, 2.9729007],
          [103.4335952, 2.9697213],
          [103.4335068, 2.9682866],
          [103.4336098, 2.9671688],
          [103.4337065, 2.9664297],
          [103.4336306, 2.9659652],
          [103.4333974, 2.9653589],
          [103.433476, 2.9645504],
          [103.4333856, 2.9627826],
          [103.4334211, 2.9617848],
          [103.4332952, 2.9613406],
          [103.4333185, 2.9596563],
          [103.433174, 2.9584301],
          [103.4330791, 2.957608],
          [103.4330614, 2.9562189],
          [103.4329313, 2.9545926],
          [103.432974897508899, 2.952745093396728],
          [103.432963835073124, 2.951975639531267],
          [103.4328798, 2.9503508],
          [103.43285487908885, 2.94816123903635],
          [103.4328503, 2.9480184],
          [103.4325972, 2.9456493],
          [103.432038, 2.9444592],
          [103.4317747, 2.9435718],
          [103.4308914, 2.9430214],
          [103.430637205979707, 2.94199532968589],
          [103.4301984, 2.9420012],
          [103.4300242, 2.9414514],
          [103.4300076, 2.9408512],
          [103.4301256, 2.9384802],
          [103.4304109, 2.9373886],
          [103.4305007, 2.9371152],
          [103.4307336, 2.9358586],
          [103.430888385005318, 2.935568417809926],
          [103.4310813, 2.9320853],
          [103.431553990109634, 2.930164004798199],
          [103.4315515, 2.9298583],
          [103.4320455, 2.9271555],
          [103.4409658, 2.8966475],
          [103.445714, 2.8820084],
          [103.4518141, 2.8678658],
          [103.4555817, 2.8600502],
          [103.4610772, 2.8518243],
          [103.4697971, 2.8395087],
          [103.4788778, 2.8281468],
          [103.4798501, 2.8277333],
          [103.4806078, 2.8268482],
          [103.4807143, 2.8261628],
          [103.48313, 2.8211347],
          [103.4863116, 2.8176488],
          [103.487222, 2.8173457],
          [103.4872826, 2.817573],
          [103.4870247, 2.8177397],
          [103.4873282, 2.8180579],
          [103.4878289, 2.8178912],
          [103.4879502, 2.8174214],
          [103.4876468, 2.8175124],
          [103.4875861, 2.8172093],
          [103.4898317, 2.8156938],
          [103.4901503, 2.8152392],
          [103.4929757, 2.8133047],
          [103.5217428, 2.7753051],
          [103.5256285, 2.7701373],
          [103.5287544, 2.7661016],
          [103.5388916, 2.755722],
          [103.5434621, 2.7509211],
          [103.5520452, 2.7453056],
          [103.5542553, 2.7423693],
          [103.5541266, 2.7419835],
          [103.5556501, 2.7411262],
          [103.5572165, 2.7386614],
          [103.5590189, 2.7362823],
          [103.5641259, 2.7313955],
          [103.5695546, 2.7268945],
          [103.5741466, 2.7218791],
          [103.5745114, 2.7207645],
          [103.5762924, 2.7200144],
          [103.5814722, 2.7158852],
          [103.5884374, 2.709319],
          [103.619749, 2.6824421],
          [103.6256318, 2.6766749],
          [103.6280783, 2.6728027],
          [103.6287795, 2.671592],
          [103.630463, 2.6678189],
          [103.6350893, 2.6654762],
        ],
      ],
    ],
  },
};
