import {
  Dialog,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../button";

const Title = styled.div`
  font-family: "Staatliches", cursive;
  font-size: 24px;
  font-weight: bold;
`;

const Description = styled.div`
  opacity: 0.8;
  padding: 8px 0px;
`;

const LanguageDialog = ({ open, close, translate, changelanguage }) => {
  const languangeInLocalStorage = localStorage.getItem("language");
  const [language, setLanguage] = useState(
    languangeInLocalStorage ? languangeInLocalStorage : "en"
  );

  const changeLanguage = () => {
    changelanguage(language);
    close();
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogContent>
        <Title>{translate("language.title")} ?</Title>
        <Description>{translate("language.description")}</Description>
        <Select
          style={{ minWidth: 200, marginTop: 8 }}
          value={language}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        >
          <MenuItem value="cn">中文</MenuItem>
          <MenuItem value="en">English</MenuItem>
        </Select>
      </DialogContent>

      <DialogActions>
        <Button
          click={close}
          color="primary"
          name={translate("general.cancel")}
        />
        <Button
          click={changeLanguage}
          color="primary"
          name={translate("general.confirm")}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LanguageDialog;
