export const kelantan = {
  type: "Feature",
  properties: {
    gid: 3,
    id: 4443571,
    country: "MYS",
    name: "Kelantan",
    enname: "Kelantan",
    locname: "Kelantan",
    offname: null,
    boundary: "administrative",
    adminlevel: 4,
    wikidata: "Q185944",
    wikimedia: "en:Kelantan",
    timestamp: "2019-12-15 22:59:02",
    note: null,
    path: "0,2108121,4443571",
    rpath: "4443571,2108121,0",
    iso3166_2: "MY-03",
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [102.5139023, 5.8658102],
          [102.5322136, 5.8493224],
          [102.5360047, 5.8460173],
          [102.5340288, 5.84379],
          [102.514045, 5.8206524],
          [102.5127206, 5.8191392],
          [102.5115366, 5.8177563],
          [102.5110184, 5.817165],
          [102.5101858, 5.8162055],
          [102.5088391, 5.81464],
          [102.5083579, 5.8140892],
          [102.5081646, 5.8138718],
          [102.5073498, 5.8129384],
          [102.5060049, 5.8113934],
          [102.5046777, 5.8098642],
          [102.5035108, 5.8084275],
          [102.502276, 5.8070216],
          [102.5016901, 5.8063602],
          [102.5009949, 5.8056305],
          [102.4997429, 5.8041897],
          [102.4986963, 5.8029984],
          [102.4974739, 5.8015705],
          [102.4961763, 5.8000732],
          [102.4949259, 5.7986163],
          [102.4939101, 5.7974408],
          [102.4927377, 5.7960562],
          [102.4915799, 5.7946788],
          [102.4905656, 5.7934901],
          [102.4899366, 5.7927521],
          [102.4891774, 5.7918518],
          [102.4889031, 5.7915592],
          [102.4880705, 5.7905791],
          [102.4866608, 5.7893551],
          [102.4856293, 5.7884548],
          [102.4852435, 5.7881122],
          [102.4846857, 5.7876192],
          [102.4839326, 5.7869822],
          [102.4832101, 5.7863711],
          [102.4824334, 5.7857006],
          [102.4820979, 5.7854131],
          [102.4817361, 5.7851112],
          [102.4809354, 5.7844117],
          [102.4808174, 5.784286],
          [102.4804953, 5.784007],
          [102.4801779, 5.7837365],
          [102.4789797, 5.782711],
          [102.4774271, 5.7813715],
          [102.477218, 5.7812001],
          [102.4768711, 5.7809067],
          [102.4765252, 5.7806126],
          [102.4754549, 5.779703],
          [102.4748636, 5.7791886],
          [102.4743228, 5.7787292],
          [102.4738331, 5.7782946],
          [102.473383, 5.7779077],
          [102.4730507, 5.7776075],
          [102.4727355, 5.7773291],
          [102.4724342, 5.7770818],
          [102.4718864, 5.7766136],
          [102.4710332, 5.7758647],
          [102.470763, 5.7756288],
          [102.4704211, 5.775339],
          [102.469752, 5.7746891],
          [102.4687038, 5.773639],
          [102.4681715, 5.7732013],
          [102.4670118, 5.7722286],
          [102.46681, 5.7720494],
          [102.4663121, 5.7716324],
          [102.4649823, 5.7705078],
          [102.4634043, 5.7691696],
          [102.4627254, 5.7686003],
          [102.4625757, 5.7685198],
          [102.4622791, 5.7682724],
          [102.4621273, 5.7681386],
          [102.4616232, 5.7677269],
          [102.4604953, 5.7667828],
          [102.4598453, 5.7662561],
          [102.4596844, 5.7661049],
          [102.4569201, 5.7637073],
          [102.4410634, 5.7525599],
          [102.4396714, 5.7515842],
          [102.4338438, 5.7474994],
          [102.4313926, 5.7457198],
          [102.4195179, 5.7276911],
          [102.4184912, 5.7257087],
          [102.417518, 5.7248307],
          [102.417061, 5.7240698],
          [102.4168689, 5.7232523],
          [102.4167694, 5.7221016],
          [102.4154065, 5.7209806],
          [102.4144417, 5.7208619],
          [102.4120856, 5.7211749],
          [102.4114351, 5.7200106],
          [102.4108171, 5.7193526],
          [102.4093523, 5.7177941],
          [102.407911, 5.7160515],
          [102.4068028, 5.7143909],
          [102.4070931, 5.7135753],
          [102.4061185, 5.7130079],
          [102.4052803, 5.7128666],
          [102.4034223, 5.7120773],
          [102.4027735, 5.7104068],
          [102.4026155, 5.7095895],
          [102.401967, 5.7078961],
          [102.4012152, 5.7061331],
          [102.4010801, 5.7053504],
          [102.400511, 5.7039333],
          [102.3991005, 5.7032033],
          [102.3989308, 5.7025354],
          [102.3988871, 5.7019486],
          [102.3988213, 5.7010511],
          [102.3985731, 5.6997848],
          [102.3978899, 5.6981257],
          [102.3975134, 5.6974226],
          [102.3968261, 5.6969713],
          [102.3956095, 5.6966215],
          [102.3941358, 5.6974786],
          [102.3936738, 5.6981555],
          [102.3931772, 5.6988897],
          [102.392346, 5.7000138],
          [102.3910275, 5.6992725],
          [102.3905124, 5.6987874],
          [102.3898712, 5.6982098],
          [102.3871403, 5.6972789],
          [102.3865003, 5.6964022],
          [102.3862951, 5.6953778],
          [102.3852276, 5.6944372],
          [102.384402, 5.694485],
          [102.3832767, 5.693326],
          [102.3819459, 5.6928919],
          [102.3811773, 5.6928935],
          [102.3799379, 5.6919878],
          [102.3796841, 5.6903671],
          [102.3803478, 5.6888823],
          [102.380071, 5.6872271],
          [102.3792205, 5.6854582],
          [102.3784734, 5.6837004],
          [102.3788127, 5.6829724],
          [102.3792516, 5.6820315],
          [102.3816933, 5.680911],
          [102.3821965, 5.6792885],
          [102.3825619, 5.677747],
          [102.3851759, 5.6767295],
          [102.3858865, 5.676245],
          [102.3865271, 5.6743004],
          [102.3864791, 5.6721158],
          [102.38665, 5.671023],
          [102.3862249, 5.6703916],
          [102.3853184, 5.6699105],
          [102.3854898, 5.6690823],
          [102.3852359, 5.6674616],
          [102.3854063, 5.6657939],
          [102.3861054, 5.66516],
          [102.3870675, 5.6641115],
          [102.3876165, 5.6623971],
          [102.3872488, 5.6616965],
          [102.3859746, 5.6604573],
          [102.385526, 5.6592164],
          [102.3859146, 5.6577323],
          [102.3866803, 5.655155],
          [102.3860371, 5.653926],
          [102.3851757, 5.6527895],
          [102.386608, 5.6515676],
          [102.3867787, 5.6500034],
          [102.3869253, 5.6476344],
          [102.3888036, 5.6448247],
          [102.3894897, 5.6428915],
          [102.3896026, 5.6409135],
          [102.3893258, 5.6392813],
          [102.3880276, 5.6370994],
          [102.387441, 5.6353184],
          [102.3875765, 5.6330183],
          [102.3878729, 5.6314654],
          [102.3902578, 5.6307704],
          [102.3918623, 5.629755],
          [102.3929386, 5.6280509],
          [102.3941993, 5.6274043],
          [102.394392, 5.6247937],
          [102.3940929, 5.624024],
          [102.393724, 5.621886],
          [102.3940064, 5.6214666],
          [102.3942048, 5.6211721],
          [102.3944255, 5.6211598],
          [102.3961429, 5.6210643],
          [102.3976673, 5.6202332],
          [102.3978728, 5.6194739],
          [102.3980671, 5.618703],
          [102.3996236, 5.615503],
          [102.4012542, 5.6138896],
          [102.4017431, 5.6134057],
          [102.4016621, 5.6126585],
          [102.4015012, 5.6122334],
          [102.400526, 5.6121205],
          [102.4000788, 5.612064],
          [102.3995731, 5.6108578],
          [102.3989985, 5.6096171],
          [102.3988471, 5.6073293],
          [102.3979871, 5.6073081],
          [102.3968629, 5.6068391],
          [102.3980086, 5.6059397],
          [102.3977439, 5.6050434],
          [102.3968129, 5.6027342],
          [102.3969039, 5.6019406],
          [102.3967995, 5.600768],
          [102.3964763, 5.5988024],
          [102.3965323, 5.5975144],
          [102.3969324, 5.5960073],
          [102.3965747, 5.5938003],
          [102.3964816, 5.5923748],
          [102.3969614, 5.5906374],
          [102.3968225, 5.5893613],
          [102.3969239, 5.5874178],
          [102.3965092, 5.5855675],
          [102.3963983, 5.5843952],
          [102.3961615, 5.5818887],
          [102.3965952, 5.5797375],
          [102.3972707, 5.5788277],
          [102.397591, 5.5778496],
          [102.400329, 5.575245],
          [102.4005117, 5.5742787],
          [102.3997878, 5.5729119],
          [102.4000386, 5.5714281],
          [102.4014355, 5.5691713],
          [102.4016409, 5.5683085],
          [102.4023602, 5.5650299],
          [102.4026911, 5.5635573],
          [102.4039614, 5.5608984],
          [102.4040059, 5.5595644],
          [102.4032816, 5.5578643],
          [102.4030278, 5.5563125],
          [102.4038976, 5.5546203],
          [102.4052615, 5.5538814],
          [102.4058915, 5.5533396],
          [102.4058324, 5.551362],
          [102.4055438, 5.5495919],
          [102.4058873, 5.5488552],
          [102.4074097, 5.5463107],
          [102.4078783, 5.5448723],
          [102.4071323, 5.5440691],
          [102.4062616, 5.5448414],
          [102.4056656, 5.5452796],
          [102.4041872, 5.5457542],
          [102.4028219, 5.5452053],
          [102.4016062, 5.5447365],
          [102.3996438, 5.5432114],
          [102.4001815, 5.5420489],
          [102.4014863, 5.5394589],
          [102.4015653, 5.5391977],
          [102.4016918, 5.53878],
          [102.4005681, 5.5387249],
          [102.4001084, 5.5374841],
          [102.3998882, 5.5353803],
          [102.3997492, 5.5338513],
          [102.3988875, 5.5320594],
          [102.397624, 5.5297394],
          [102.3966024, 5.5287528],
          [102.3957651, 5.528467],
          [102.3945836, 5.5279176],
          [102.3930799, 5.5264146],
          [102.3915761, 5.5246125],
          [102.3906804, 5.5232461],
          [102.3903702, 5.5224764],
          [102.3891313, 5.5219616],
          [102.3872283, 5.5222531],
          [102.385841, 5.5224631],
          [102.3831926, 5.5226872],
          [102.3825158, 5.5225047],
          [102.3818389, 5.5220346],
          [102.3810345, 5.5199551],
          [102.3805969, 5.5179552],
          [102.3814313, 5.5153548],
          [102.3825033, 5.5134205],
          [102.3829307, 5.5126494],
          [102.3835607, 5.5120731],
          [102.38424, 5.511916],
          [102.3863693, 5.5114233],
          [102.38669, 5.5108937],
          [102.386723, 5.5095252],
          [102.3887392, 5.5075662],
          [102.3890824, 5.506818],
          [102.3899389, 5.5035276],
          [102.3904883, 5.5022386],
          [102.3917814, 5.4999245],
          [102.3922165, 5.4991878],
          [102.3925806, 5.4960823],
          [102.3923733, 5.4952319],
          [102.3916036, 5.4938766],
          [102.391075, 5.4925899],
          [102.3909252, 5.4918314],
          [102.390568, 5.4898314],
          [102.3902922, 5.489211],
          [102.3902339, 5.4883487],
          [102.3902218, 5.4876129],
          [102.390209, 5.4863596],
          [102.3895651, 5.4845096],
          [102.3893467, 5.4836132],
          [102.3887139, 5.4816368],
          [102.3896071, 5.4802895],
          [102.389663, 5.4787716],
          [102.3890757, 5.4763812],
          [102.3887806, 5.475522],
          [102.3885586, 5.474876],
          [102.3885232, 5.4739792],
          [102.3893375, 5.4742533],
          [102.3906783, 5.4736986],
          [102.3914001, 5.4732486],
          [102.3918242, 5.47278],
          [102.3922134, 5.4723499],
          [102.3937944, 5.4709897],
          [102.3944129, 5.4704939],
          [102.3954783, 5.4694913],
          [102.3964399, 5.4679714],
          [102.3968747, 5.4673495],
          [102.3975495, 5.4656002],
          [102.3981444, 5.4641387],
          [102.3986015, 5.4627924],
          [102.399747, 5.4617895],
          [102.399712, 5.4609847],
          [102.3994132, 5.4601804],
          [102.3994353, 5.4595365],
          [102.3994573, 5.458697],
          [102.3988832, 5.4575943],
          [102.3981711, 5.4565035],
          [102.3973448, 5.4554589],
          [102.3982154, 5.4547556],
          [102.3985471, 5.4539845],
          [102.3985351, 5.4533981],
          [102.3982819, 5.4523867],
          [102.3987505, 5.4510634],
          [102.3989558, 5.4497867],
          [102.3999963, 5.4470362],
          [102.400237, 5.4468403],
          [102.4004429, 5.4464833],
          [102.4007291, 5.4461377],
          [102.4007745, 5.4455742],
          [102.4009914, 5.4447229],
          [102.4011401, 5.4442396],
          [102.4014605, 5.4437674],
          [102.4020563, 5.4433868],
          [102.4027437, 5.4429023],
          [102.4030873, 5.442545],
          [102.4029149, 5.442028],
          [102.4025473, 5.4412929],
          [102.4022601, 5.4407531],
          [102.4020992, 5.440236],
          [102.4021562, 5.4398909],
          [102.4022242, 5.4393503],
          [102.4022811, 5.4387868],
          [102.4020969, 5.4381317],
          [102.4017182, 5.4376956],
          [102.4012362, 5.4371562],
          [102.4008805, 5.436835],
          [102.4004789, 5.4364334],
          [102.4001117, 5.4359743],
          [102.3998589, 5.4354574],
          [102.3993769, 5.4349754],
          [102.3986428, 5.4346666],
          [102.3981042, 5.4347137],
          [102.3976681, 5.4342202],
          [102.3973465, 5.4338184],
          [102.3971981, 5.4336207],
          [102.3969933, 5.4335647],
          [102.3965777, 5.4328773],
          [102.3962215, 5.4321996],
          [102.3959569, 5.4313607],
          [102.3958419, 5.4308551],
          [102.3955662, 5.4303038],
          [102.3949923, 5.429569],
          [102.3943951, 5.4286964],
          [102.3941537, 5.4279036],
          [102.3938661, 5.4270532],
          [102.3937851, 5.4261796],
          [102.3934409, 5.4257318],
          [102.3927301, 5.4258024],
          [102.391939, 5.4259075],
          [102.3910909, 5.4260588],
          [102.3897272, 5.4267631],
          [102.3883865, 5.4273064],
          [102.3870687, 5.4277577],
          [102.3851058, 5.4252667],
          [102.3845014, 5.4240368],
          [102.3846599, 5.4232289],
          [102.38461, 5.4223097],
          [102.3846298, 5.4214468],
          [102.3849631, 5.4207853],
          [102.3846424, 5.420147],
          [102.3843753, 5.4192892],
          [102.3841777, 5.4184647],
          [102.3839364, 5.4177789],
          [102.3839065, 5.4173655],
          [102.3838594, 5.4166073],
          [102.3837629, 5.4156544],
          [102.3840726, 5.4149588],
          [102.3846709, 5.4143616],
          [102.3849806, 5.413666],
          [102.3849088, 5.41317],
          [102.3848378, 5.4126795],
          [102.384911, 5.4115741],
          [102.3851078, 5.411007],
          [102.3851072, 5.4102825],
          [102.385511, 5.4097232],
          [102.3864019, 5.4094542],
          [102.3870773, 5.4093272],
          [102.3880702, 5.4089874],
          [102.3885721, 5.4087944],
          [102.3889528, 5.4089026],
          [102.3897235, 5.4089808],
          [102.3898499, 5.4090005],
          [102.3902531, 5.4090634],
          [102.3905933, 5.4094943],
          [102.3910821, 5.409888],
          [102.3916631, 5.4096132],
          [102.3921639, 5.4080056],
          [102.3924764, 5.407471],
          [102.39289, 5.4068081],
          [102.3930056, 5.4064246],
          [102.3931618, 5.405906],
          [102.3934243, 5.4051308],
          [102.3937952, 5.4046641],
          [102.3942037, 5.4043808],
          [102.3949326, 5.4040342],
          [102.3956931, 5.4035261],
          [102.3959247, 5.4029469],
          [102.3963573, 5.4026497],
          [102.396492, 5.4025572],
          [102.3972088, 5.4021649],
          [102.3979756, 5.4020246],
          [102.3985573, 5.4017957],
          [102.3991903, 5.4012209],
          [102.3991985, 5.4007745],
          [102.3992143, 5.3999208],
          [102.3991203, 5.3991174],
          [102.3993062, 5.3985621],
          [102.399849, 5.3980809],
          [102.4009318, 5.3976129],
          [102.4024006, 5.3968966],
          [102.403119, 5.3965962],
          [102.4039263, 5.3961332],
          [102.4050287, 5.3954693],
          [102.4058427, 5.3954088],
          [102.4066181, 5.3957744],
          [102.4072883, 5.3960269],
          [102.4078571, 5.3964077],
          [102.4080975, 5.396332],
          [102.4090564, 5.3960297],
          [102.4099897, 5.3948744],
          [102.4102134, 5.3946166],
          [102.4104964, 5.3942904],
          [102.4108431, 5.3937435],
          [102.411536, 5.392627],
          [102.4119439, 5.3916306],
          [102.4122048, 5.3907634],
          [102.4130594, 5.3897016],
          [102.4151444, 5.3894456],
          [102.4158458, 5.3895136],
          [102.4163985, 5.3895366],
          [102.416811, 5.3895538],
          [102.4177216, 5.3897674],
          [102.4183982, 5.3903994],
          [102.4189131, 5.3909767],
          [102.4195536, 5.3915172],
          [102.4201894, 5.3917703],
          [102.42074, 5.3917489],
          [102.4211134, 5.3914202],
          [102.4211272, 5.3908794],
          [102.4211967, 5.3902456],
          [102.420993, 5.3897317],
          [102.4206701, 5.388967],
          [102.4202811, 5.3883759],
          [102.4203621, 5.3877305],
          [102.4204639, 5.386958],
          [102.4199665, 5.38607],
          [102.4196222, 5.3854091],
          [102.4193937, 5.3847921],
          [102.419025, 5.3845151],
          [102.4188705, 5.384399],
          [102.4195332, 5.383738],
          [102.4197512, 5.3835206],
          [102.4213021, 5.3829063],
          [102.4219602, 5.3824574],
          [102.4225262, 5.3819872],
          [102.4228983, 5.3808994],
          [102.4233614, 5.3797756],
          [102.4235754, 5.378179],
          [102.4242776, 5.3769299],
          [102.4250304, 5.3759733],
          [102.4254427, 5.3752415],
          [102.4257874, 5.3742998],
          [102.4260572, 5.3735628],
          [102.4274519, 5.3725372],
          [102.4281967, 5.3717762],
          [102.4287841, 5.3705351],
          [102.4293189, 5.3689039],
          [102.4300187, 5.3688798],
          [102.4303098, 5.3691161],
          [102.4315411, 5.3699566],
          [102.4332, 5.3702948],
          [102.4339793, 5.3702233],
          [102.4346906, 5.370199],
          [102.4355971, 5.3701598],
          [102.4367764, 5.3706561],
          [102.4378257, 5.3709247],
          [102.4382734, 5.3709166],
          [102.4385444, 5.3706473],
          [102.4389857, 5.3696043],
          [102.4392743, 5.3683455],
          [102.439559, 5.3675239],
          [102.4402283, 5.3670633],
          [102.4408995, 5.3660162],
          [102.4417818, 5.3645627],
          [102.4411167, 5.3639306],
          [102.441381, 5.3632818],
          [102.4414949, 5.3625552],
          [102.4411467, 5.3616529],
          [102.4406165, 5.3601673],
          [102.4404537, 5.3593767],
          [102.4401444, 5.3587382],
          [102.4393075, 5.3574537],
          [102.4387767, 5.3566237],
          [102.4385921, 5.3558909],
          [102.4384687, 5.3553756],
          [102.4374663, 5.3551751],
          [102.4365538, 5.3555135],
          [102.4356305, 5.3559097],
          [102.4347601, 5.3560288],
          [102.4334423, 5.355489],
          [102.4323548, 5.3556695],
          [102.4312121, 5.3559775],
          [102.4304552, 5.3560372],
          [102.429609, 5.3555463],
          [102.4289947, 5.3545337],
          [102.4284661, 5.3538302],
          [102.4279895, 5.3534707],
          [102.4277628, 5.3529687],
          [102.4275878, 5.3521209],
          [102.427218, 5.3512994],
          [102.4266668, 5.3506193],
          [102.426524, 5.3496443],
          [102.4270999, 5.3490819],
          [102.4276639, 5.3484852],
          [102.4284199, 5.3477241],
          [102.4291121, 5.3465731],
          [102.4292958, 5.3458603],
          [102.4293469, 5.3448437],
          [102.4294569, 5.3438757],
          [102.4293042, 5.3430044],
          [102.4287128, 5.3426585],
          [102.4285171, 5.3419489],
          [102.4286198, 5.3412226],
          [102.4286093, 5.3406017],
          [102.4282494, 5.3396881],
          [102.427674, 5.3389394],
          [102.4273481, 5.3386692],
          [102.4273023, 5.338003],
          [102.4275303, 5.3365613],
          [102.4278055, 5.3351762],
          [102.4282538, 5.3345472],
          [102.4285201, 5.3339903],
          [102.4282596, 5.3335235],
          [102.4280607, 5.3326415],
          [102.4286133, 5.331378],
          [102.4290712, 5.3306222],
          [102.4292775, 5.3299285],
          [102.4292135, 5.3281815],
          [102.4292238, 5.3267552],
          [102.4291868, 5.3259278],
          [102.429163, 5.3251922],
          [102.4294321, 5.3241408],
          [102.429464, 5.3233236],
          [102.4291086, 5.3226745],
          [102.4286148, 5.3219818],
          [102.4280658, 5.3214281],
          [102.4278318, 5.3204893],
          [102.4276106, 5.3196422],
          [102.4270664, 5.3193529],
          [102.426455, 5.3191683],
          [102.4257753, 5.3190426],
          [102.4248995, 5.3188283],
          [102.4238289, 5.3186635],
          [102.4229991, 5.3184599],
          [102.4223509, 5.318161],
          [102.4219678, 5.3179033],
          [102.4216295, 5.3175759],
          [102.4215661, 5.3172205],
          [102.4218658, 5.3166171],
          [102.4226017, 5.3160173],
          [102.4228054, 5.3151626],
          [102.4225632, 5.3144194],
          [102.4222814, 5.3133664],
          [102.4213095, 5.3122337],
          [102.4205045, 5.3114776],
          [102.4203442, 5.3108249],
          [102.4203882, 5.3100421],
          [102.4204665, 5.3085686],
          [102.4199676, 5.307577],
          [102.4194751, 5.3069648],
          [102.4185535, 5.3060958],
          [102.4191768, 5.3049345],
          [102.4196089, 5.3040182],
          [102.41957, 5.3030873],
          [102.4192067, 5.3019553],
          [102.4186341, 5.3013675],
          [102.4183466, 5.3006481],
          [102.4181707, 5.2997542],
          [102.4184571, 5.2990599],
          [102.4186069, 5.2984122],
          [102.418606, 5.2976647],
          [102.4179854, 5.2969513],
          [102.4172184, 5.29639],
          [102.4166341, 5.2957909],
          [102.4164046, 5.295105],
          [102.4158993, 5.294424],
          [102.415338, 5.293813],
          [102.415016, 5.2931058],
          [102.4152972, 5.2927672],
          [102.4157427, 5.2919427],
          [102.4162117, 5.2911751],
          [102.417142, 5.2898589],
          [102.4175999, 5.2891031],
          [102.4179548, 5.2883722],
          [102.4179771, 5.2876588],
          [102.4178229, 5.2866725],
          [102.4174464, 5.2861502],
          [102.4169077, 5.2855043],
          [102.4164898, 5.2852358],
          [102.4161137, 5.2847135],
          [102.4156091, 5.284067],
          [102.4155875, 5.2827908],
          [102.4155898, 5.2815717],
          [102.4155137, 5.2811475],
          [102.4155529, 5.2807443],
          [102.415538, 5.2798589],
          [102.4159442, 5.2787821],
          [102.4165095, 5.2775874],
          [102.4168653, 5.2769139],
          [102.4171517, 5.2761957],
          [102.4174798, 5.2752354],
          [102.417543, 5.2742221],
          [102.4180751, 5.2737871],
          [102.4187105, 5.2733386],
          [102.4192228, 5.2724324],
          [102.4190211, 5.2713664],
          [102.4188662, 5.2703456],
          [102.4188781, 5.2696898],
          [102.4187602, 5.2688294],
          [102.4178702, 5.2677988],
          [102.4171202, 5.2668807],
          [102.4168101, 5.2661962],
          [102.4165564, 5.2654417],
          [102.41601, 5.2643589],
          [102.416145, 5.2628384],
          [102.4164062, 5.2620057],
          [102.4163587, 5.261236],
          [102.4157321, 5.260143],
          [102.4153917, 5.2590336],
          [102.4150449, 5.2582118],
          [102.4148588, 5.257387],
          [102.4151092, 5.2558989],
          [102.4154035, 5.2549736],
          [102.4159206, 5.2543317],
          [102.4160198, 5.2534214],
          [102.4153205, 5.2521114],
          [102.4158511, 5.2502272],
          [102.4160409, 5.2485562],
          [102.4163114, 5.2469067],
          [102.4161725, 5.2461617],
          [102.4160775, 5.2453122],
          [102.4162131, 5.2438262],
          [102.4164918, 5.2426482],
          [102.4167192, 5.2418391],
          [102.4168107, 5.2411473],
          [102.416711, 5.2400106],
          [102.4165294, 5.2387717],
          [102.4163111, 5.2380856],
          [102.4161142, 5.2372955],
          [102.4155599, 5.2364315],
          [102.4148463, 5.2349491],
          [102.4148088, 5.2340873],
          [102.4146967, 5.2322147],
          [102.4148986, 5.2305779],
          [102.4148968, 5.2297958],
          [102.4148468, 5.2288882],
          [102.4153541, 5.22766],
          [102.4158887, 5.2266958],
          [102.416198, 5.2259773],
          [102.416747, 5.2245068],
          [102.4185749, 5.223324],
          [102.4192679, 5.222909],
          [102.4198794, 5.2217364],
          [102.4206446, 5.2201701],
          [102.4209886, 5.2194739],
          [102.4209921, 5.2183123],
          [102.4213815, 5.2169136],
          [102.4215897, 5.2163348],
          [102.4217905, 5.2153191],
          [102.4219624, 5.2146146],
          [102.42225, 5.2132983],
          [102.4222874, 5.2121246],
          [102.4223311, 5.2113187],
          [102.4219394, 5.2105552],
          [102.4217211, 5.2098575],
          [102.4218809, 5.2091301],
          [102.4223651, 5.2085808],
          [102.422297, 5.2079496],
          [102.4221918, 5.2071579],
          [102.4220905, 5.2065962],
          [102.4221754, 5.2055136],
          [102.4221506, 5.2040533],
          [102.4217341, 5.2031753],
          [102.4214466, 5.2024674],
          [102.4209708, 5.2014754],
          [102.4201395, 5.2005012],
          [102.4198626, 5.1997471],
          [102.419484, 5.1990638],
          [102.4189291, 5.1981537],
          [102.4190024, 5.1977499],
          [102.4191642, 5.1964589],
          [102.4193472, 5.195731],
          [102.419288, 5.1949501],
          [102.4188389, 5.1941876],
          [102.418344, 5.1927588],
          [102.4179647, 5.1920411],
          [102.4173343, 5.1914083],
          [102.4164256, 5.1906195],
          [102.4152587, 5.1901575],
          [102.4144533, 5.1900454],
          [102.4136335, 5.1897495],
          [102.4133712, 5.1891677],
          [102.4134207, 5.1880282],
          [102.4136299, 5.1868284],
          [102.4136647, 5.1861722],
          [102.4137262, 5.1857341],
          [102.4131631, 5.1850081],
          [102.4127268, 5.1843259],
          [102.4118637, 5.1835133],
          [102.411349, 5.182936],
          [102.4111836, 5.1826514],
          [102.4108215, 5.1822784],
          [102.4113038, 5.1816142],
          [102.4126363, 5.1810152],
          [102.4133283, 5.1805313],
          [102.4138823, 5.1800384],
          [102.414297, 5.1794444],
          [102.4156415, 5.1788797],
          [102.4171052, 5.179233],
          [102.4179559, 5.1793098],
          [102.4190661, 5.1791404],
          [102.4213607, 5.1790877],
          [102.4220565, 5.1788452],
          [102.4235533, 5.179796],
          [102.4249991, 5.1797816],
          [102.4258575, 5.1796511],
          [102.4265211, 5.1795357],
          [102.4281126, 5.1793116],
          [102.4288496, 5.1787693],
          [102.429561, 5.178078],
          [102.4301982, 5.1777561],
          [102.4308271, 5.1782968],
          [102.4317868, 5.1780381],
          [102.4328833, 5.1783979],
          [102.4336055, 5.178362],
          [102.4341643, 5.1781449],
          [102.4356898, 5.1780945],
          [102.436975, 5.178083],
          [102.4375564, 5.1778425],
          [102.4377097, 5.1774142],
          [102.4378331, 5.1765724],
          [102.4388936, 5.1754953],
          [102.4396669, 5.1750674],
          [102.4402566, 5.1746542],
          [102.4405329, 5.1740283],
          [102.4408042, 5.1731033],
          [102.4411205, 5.1721316],
          [102.441699, 5.1717301],
          [102.4423547, 5.1711433],
          [102.4426205, 5.170575],
          [102.4427828, 5.1699971],
          [102.4427956, 5.1687202],
          [102.443174, 5.1680348],
          [102.4438436, 5.1675973],
          [102.4447804, 5.1673389],
          [102.4459049, 5.1673188],
          [102.4464589, 5.1668372],
          [102.4475149, 5.1668413],
          [102.4482518, 5.1669775],
          [102.4491608, 5.1671221],
          [102.4502754, 5.1665271],
          [102.4513053, 5.1663476],
          [102.4521072, 5.1662527],
          [102.452782, 5.166114],
          [102.4541436, 5.1665611],
          [102.4553159, 5.1673335],
          [102.4559578, 5.1679661],
          [102.4568787, 5.168122],
          [102.4576803, 5.1680155],
          [102.458137, 5.1678923],
          [102.4591452, 5.1677822],
          [102.4598496, 5.1673555],
          [102.4603733, 5.166426],
          [102.4610366, 5.1656205],
          [102.4622025, 5.1653465],
          [102.4629478, 5.1652986],
          [102.4633694, 5.1651299],
          [102.4647776, 5.1649206],
          [102.4654444, 5.1643221],
          [102.4652866, 5.1638189],
          [102.4648057, 5.163195],
          [102.4645201, 5.1626021],
          [102.4645552, 5.1612904],
          [102.4652819, 5.1601617],
          [102.4660437, 5.1597339],
          [102.4675487, 5.1591548],
          [102.4696026, 5.1591409],
          [102.4703599, 5.1591387],
          [102.4720033, 5.1585915],
          [102.4735466, 5.1582417],
          [102.4741484, 5.1578513],
          [102.4745528, 5.157338],
          [102.475065, 5.1564202],
          [102.4751616, 5.1553489],
          [102.4748964, 5.1545946],
          [102.4748245, 5.1540062],
          [102.4749536, 5.1528801],
          [102.4754604, 5.1523066],
          [102.4761161, 5.1519289],
          [102.4767147, 5.1514936],
          [102.4781078, 5.1502785],
          [102.4796383, 5.1491788],
          [102.4804318, 5.1489164],
          [102.4813084, 5.1473897],
          [102.4824487, 5.1462429],
          [102.4832304, 5.1460264],
          [102.4841274, 5.1456954],
          [102.484635, 5.1447769],
          [102.4853279, 5.1432037],
          [102.4855366, 5.1424686],
          [102.4859317, 5.1406187],
          [102.4862347, 5.1388146],
          [102.4863513, 5.1380561],
          [102.4863442, 5.1362398],
          [102.4872427, 5.1352189],
          [102.487956, 5.1347493],
          [102.4906347, 5.1340203],
          [102.4916691, 5.1337585],
          [102.4921981, 5.1336564],
          [102.4931052, 5.1337965],
          [102.4941738, 5.1336843],
          [102.4960236, 5.1336889],
          [102.4966561, 5.1333916],
          [102.4973918, 5.1331865],
          [102.498589, 5.1322699],
          [102.4995905, 5.1313987],
          [102.5002459, 5.1311014],
          [102.5010164, 5.1307929],
          [102.5024647, 5.1304402],
          [102.503833, 5.1300068],
          [102.5044882, 5.129836],
          [102.5054195, 5.1294819],
          [102.5063757, 5.1284842],
          [102.5075034, 5.1276478],
          [102.5081479, 5.127201],
          [102.508964, 5.1269616],
          [102.5103664, 5.1266317],
          [102.5116992, 5.126566],
          [102.5122855, 5.1264065],
          [102.5134232, 5.1262599],
          [102.5152169, 5.125701],
          [102.5155399, 5.1250006],
          [102.5153578, 5.1242529],
          [102.5148324, 5.1229871],
          [102.5142502, 5.1213991],
          [102.5145277, 5.1206986],
          [102.5150578, 5.1199412],
          [102.5156794, 5.1194714],
          [102.517071, 5.1187851],
          [102.5177277, 5.1179705],
          [102.5180281, 5.1172814],
          [102.5183976, 5.1164087],
          [102.5191828, 5.1146633],
          [102.5199655, 5.1140444],
          [102.5213686, 5.1133696],
          [102.5231403, 5.1123968],
          [102.5237046, 5.1117775],
          [102.5241195, 5.1111462],
          [102.5246153, 5.1104461],
          [102.5255005, 5.1101839],
          [102.5263285, 5.109818],
          [102.5272598, 5.1093835],
          [102.5277895, 5.108879],
          [102.5282967, 5.1081215],
          [102.5275653, 5.1064988],
          [102.5277399, 5.1054761],
          [102.5280173, 5.104718],
          [102.5271561, 5.1045665],
          [102.5254, 5.1038838],
          [102.5250111, 5.1032047],
          [102.5246339, 5.102422],
          [102.5241985, 5.1018232],
          [102.5231111, 5.100142],
          [102.522538, 5.0995658],
          [102.5218041, 5.0990697],
          [102.5211729, 5.0987463],
          [102.5203471, 5.0981925],
          [102.5190486, 5.0983617],
          [102.5187627, 5.0978552],
          [102.5182929, 5.0972562],
          [102.5182396, 5.0954511],
          [102.5187249, 5.0943143],
          [102.5187955, 5.0935327],
          [102.518832, 5.0927741],
          [102.518124, 5.0909214],
          [102.5181375, 5.0900019],
          [102.5183004, 5.0891286],
          [102.5182229, 5.0879097],
          [102.5170181, 5.0872286],
          [102.5163995, 5.0865717],
          [102.515792, 5.0859839],
          [102.5150127, 5.0851658],
          [102.5140617, 5.0840714],
          [102.51343, 5.0839893],
          [102.5125451, 5.0841251],
          [102.5120389, 5.0844227],
          [102.5115896, 5.0849964],
          [102.5104627, 5.0855568],
          [102.5095324, 5.0853591],
          [102.5088206, 5.0851619],
          [102.5080403, 5.084838],
          [102.5063636, 5.0846615],
          [102.5058474, 5.0843153],
          [102.5054124, 5.0837164],
          [102.5038428, 5.0819306],
          [102.502914, 5.0811236],
          [102.5025478, 5.0805134],
          [102.5024004, 5.0797658],
          [102.5014161, 5.0782114],
          [102.5007744, 5.077428],
          [102.500397, 5.0767488],
          [102.4998702, 5.0761382],
          [102.4986798, 5.0742384],
          [102.4981762, 5.0734784],
          [102.4983619, 5.0727201],
          [102.499097, 5.0726875],
          [102.5009002, 5.0728874],
          [102.5019919, 5.0726716],
          [102.5026708, 5.0722251],
          [102.5037069, 5.071331],
          [102.5049029, 5.0707362],
          [102.5057199, 5.0701059],
          [102.5059739, 5.0695548],
          [102.506183, 5.0686126],
          [102.5061644, 5.0667847],
          [102.5063041, 5.0658883],
          [102.5064212, 5.0650379],
          [102.5065842, 5.0640957],
          [102.5069662, 5.062878],
          [102.5071069, 5.0616253],
          [102.5071434, 5.0608092],
          [102.5070302, 5.0601077],
          [102.5080653, 5.0595814],
          [102.5090075, 5.0593999],
          [102.5097896, 5.0590569],
          [102.5104796, 5.0586678],
          [102.5108948, 5.0579906],
          [102.511172, 5.0573935],
          [102.511978, 5.0565217],
          [102.5126573, 5.0557992],
          [102.5131755, 5.0552716],
          [102.5136274, 5.0536634],
          [102.512896, 5.0520291],
          [102.5124153, 5.0513152],
          [102.5119227, 5.0507507],
          [102.5107772, 5.0494028],
          [102.5101815, 5.0486081],
          [102.509643, 5.0481124],
          [102.5093005, 5.0471919],
          [102.5098074, 5.0465724],
          [102.5103952, 5.0457001],
          [102.5105696, 5.0448154],
          [102.510514, 5.044045],
          [102.5100244, 5.0421699],
          [102.5100853, 5.0406641],
          [102.5103516, 5.0398601],
          [102.5107664, 5.0392173],
          [102.512032, 5.0383582],
          [102.5129303, 5.0373604],
          [102.513552, 5.0367986],
          [102.5146684, 5.0358702],
          [102.5156124, 5.0349643],
          [102.515936, 5.0341604],
          [102.5162366, 5.0333449],
          [102.5162843, 5.0326438],
          [102.5157001, 5.0319411],
          [102.5148397, 5.0314102],
          [102.5143815, 5.0308916],
          [102.51428, 5.0300407],
          [102.515327, 5.0293765],
          [102.5165803, 5.0288049],
          [102.517294, 5.0281169],
          [102.5178463, 5.0277619],
          [102.5188596, 5.0266953],
          [102.5196192, 5.0260074],
          [102.520057, 5.0254452],
          [102.5197139, 5.0249385],
          [102.5192215, 5.024282],
          [102.5185806, 5.0231998],
          [102.5175951, 5.0221742],
          [102.5172863, 5.0216446],
          [102.5169777, 5.0209312],
          [102.5165899, 5.019769],
          [102.5157777, 5.0183645],
          [102.5151823, 5.0175353],
          [102.5147129, 5.0168559],
          [102.5142781, 5.0161651],
          [102.5136376, 5.0150368],
          [102.5137658, 5.014198],
          [102.5138143, 5.0131404],
          [102.5137584, 5.0124736],
          [102.5131536, 5.0107362],
          [102.5124896, 5.0097804],
          [102.5119166, 5.0092041],
          [102.5113205, 5.0086968],
          [102.510632, 5.0084191],
          [102.5097256, 5.0080376],
          [102.5077041, 5.0080326],
          [102.5068996, 5.0081569],
          [102.5061415, 5.0081665],
          [102.5051535, 5.008325],
          [102.503867, 5.0082298],
          [102.5029718, 5.0079862],
          [102.5021793, 5.0078692],
          [102.50141, 5.0078098],
          [102.5003085, 5.0073472],
          [102.4993227, 5.0063905],
          [102.4991057, 5.0058842],
          [102.498797, 5.0053316],
          [102.4987529, 5.0045843],
          [102.4990887, 5.0034125],
          [102.4998483, 5.0027707],
          [102.5001369, 5.0020931],
          [102.5005294, 5.0013584],
          [102.5009575, 5.0000029],
          [102.5007426, 4.9986343],
          [102.500549, 4.9977946],
          [102.5001949, 4.9970235],
          [102.4997737, 4.995436],
          [102.4994894, 4.9942282],
          [102.4991006, 4.9934915],
          [102.4985618, 4.9930073],
          [102.4982301, 4.9924202],
          [102.4986685, 4.9917085],
          [102.4995438, 4.9906416],
          [102.5000166, 4.9899071],
          [102.5004891, 4.9892644],
          [102.5010192, 4.9885415],
          [102.5017461, 4.9871063],
          [102.5017372, 4.9860372],
          [102.5016243, 4.9851632],
          [102.5020182, 4.9836927],
          [102.5027906, 4.9825221],
          [102.5030568, 4.981695],
          [102.5033804, 4.980891],
          [102.5035084, 4.9801441],
          [102.5037057, 4.979271],
          [102.5039951, 4.9782945],
          [102.5048116, 4.9778828],
          [102.5057195, 4.9776436],
          [102.5068567, 4.9776119],
          [102.5074893, 4.9772686],
          [102.5076752, 4.9763839],
          [102.5078269, 4.9753381],
          [102.5078976, 4.974637],
          [102.5081408, 4.9737295],
          [102.5083264, 4.9729827],
          [102.5086614, 4.9720983],
          [102.5092844, 4.9710538],
          [102.5097224, 4.9703766],
          [102.5104014, 4.969723],
          [102.5110812, 4.968874],
          [102.5125653, 4.9677741],
          [102.5135312, 4.9673281],
          [102.5146712, 4.9661814],
          [102.5153277, 4.9652979],
          [102.5159271, 4.9644256],
          [102.51664, 4.9640366],
          [102.5174564, 4.9637397],
          [102.5184903, 4.9635813],
          [102.5192843, 4.96302],
          [102.5197453, 4.9623774],
          [102.5205158, 4.9619195],
          [102.5214239, 4.9615308],
          [102.5218628, 4.9606122],
          [102.52144, 4.9595765],
          [102.5209591, 4.9590006],
          [102.5204204, 4.9585394],
          [102.5197207, 4.9581008],
          [102.519182, 4.9576397],
          [102.5189184, 4.9574206],
          [102.5182664, 4.9562119],
          [102.5178432, 4.955521],
          [102.5173738, 4.9548761],
          [102.5166183, 4.9537131],
          [102.5152068, 4.9532267],
          [102.5146339, 4.9526275],
          [102.5140148, 4.9521432],
          [102.5135916, 4.9514753],
          [102.5135378, 4.9498887],
          [102.5141033, 4.948729],
          [102.5146564, 4.9480407],
          [102.5153588, 4.9472032],
          [102.5170361, 4.946966],
          [102.5179443, 4.9465888],
          [102.5186799, 4.9464183],
          [102.5194045, 4.9460061],
          [102.5200373, 4.945525],
          [102.5205217, 4.9445949],
          [102.5205699, 4.9437214],
          [102.5203764, 4.9429506],
          [102.519942, 4.9421449],
          [102.5199098, 4.9411102],
          [102.5196936, 4.9402819],
          [102.5187294, 4.9400841],
          [102.5179366, 4.9400591],
          [102.5164553, 4.9399635],
          [102.5152621, 4.9394432],
          [102.5145745, 4.9388092],
          [102.5139557, 4.9382098],
          [102.5135208, 4.9375765],
          [102.5130516, 4.9368166],
          [102.5125948, 4.9357233],
          [102.5123092, 4.9351017],
          [102.5122193, 4.9343429],
          [102.5124171, 4.9331938],
          [102.5125463, 4.931918],
          [102.5124118, 4.9305956],
          [102.5125512, 4.9298487],
          [102.5127251, 4.9292053],
          [102.513027, 4.9277921],
          [102.5127782, 4.926228],
          [102.5127126, 4.9247104],
          [102.5127514, 4.9228711],
          [102.512651, 4.9217097],
          [102.5129625, 4.9210668],
          [102.5132738, 4.9204928],
          [102.5135757, 4.9191944],
          [102.5127262, 4.9189394],
          [102.5122683, 4.9183634],
          [102.5119713, 4.917627],
          [102.511479, 4.9169934],
          [102.5103661, 4.9165653],
          [102.5100928, 4.915484],
          [102.5099337, 4.9147823],
          [102.5098208, 4.9140233],
          [102.509972, 4.913242],
          [102.5104436, 4.9128178],
          [102.5112274, 4.9116472],
          [102.5107471, 4.9108757],
          [102.5101742, 4.9102535],
          [102.5096123, 4.9098383],
          [102.5090509, 4.9092736],
          [102.5080191, 4.9084662],
          [102.5075615, 4.9077293],
          [102.5072419, 4.9069698],
          [102.5066119, 4.9062439],
          [102.5062575, 4.9055418],
          [102.5064658, 4.9048755],
          [102.5062841, 4.9040359],
          [102.5059415, 4.9032878],
          [102.5055868, 4.9026201],
          [102.5051866, 4.9019524],
          [102.50714, 4.9014859],
          [102.5088741, 4.9014903],
          [102.5097475, 4.9012051],
          [102.5104953, 4.9006551],
          [102.5123804, 4.900039],
          [102.513081, 4.9000753],
          [102.5140456, 4.8999972],
          [102.5148377, 4.9001141],
          [102.5159748, 4.900071],
          [102.5168946, 4.8996135],
          [102.5174928, 4.8993276],
          [102.5188026, 4.8989744],
          [102.5202165, 4.8983917],
          [102.5209758, 4.8978533],
          [102.5216312, 4.8974985],
          [102.5227927, 4.8968232],
          [102.5237705, 4.8962048],
          [102.5247706, 4.8958509],
          [102.525529, 4.8955769],
          [102.5266791, 4.894913],
          [102.5277606, 4.8941339],
          [102.5285527, 4.8942509],
          [102.5293787, 4.8945978],
          [102.5298259, 4.8949553],
          [102.530709, 4.8954403],
          [102.5315803, 4.8959828],
          [102.5320385, 4.8965242],
          [102.5323697, 4.8973757],
          [102.5326667, 4.8979973],
          [102.5331927, 4.8990447],
          [102.5335698, 4.8998963],
          [102.5338436, 4.9006557],
          [102.5343012, 4.9013811],
          [102.5346092, 4.9022556],
          [102.5355936, 4.9037066],
          [102.5363271, 4.9043521],
          [102.536785, 4.9049625],
          [102.537623, 4.90516],
          [102.5384039, 4.9051849],
          [102.5394487, 4.9052679],
          [102.5406089, 4.9051214],
          [102.5414018, 4.9049393],
          [102.5422184, 4.904516],
          [102.5430123, 4.9038627],
          [102.5435078, 4.9030708],
          [102.5439002, 4.9022899],
          [102.544487, 4.9017397],
          [102.5450278, 4.90135],
          [102.5458326, 4.9009841],
          [102.5469357, 4.900688],
          [102.547659, 4.9007702],
          [102.5483593, 4.9008984],
          [102.5490824, 4.901107],
          [102.5502199, 4.9008914],
          [102.5519903, 4.9001945],
          [102.5527946, 4.8999205],
          [102.553449, 4.9000141],
          [102.5545749, 4.8999594],
          [102.5554488, 4.8994212],
          [102.5562074, 4.8990552],
          [102.5570003, 4.8989307],
          [102.5578967, 4.898634],
          [102.5587945, 4.897751],
          [102.5593597, 4.8966947],
          [102.5597403, 4.8959944],
          [102.5604646, 4.8957317],
          [102.5610728, 4.8958252],
          [102.5618759, 4.896195],
          [102.5632886, 4.8962444],
          [102.5637038, 4.8953947],
          [102.563832, 4.8946134],
          [102.5642238, 4.8940395],
          [102.5652007, 4.893674],
          [102.5663836, 4.8936308],
          [102.5670962, 4.8933911],
          [102.5677733, 4.8935883],
          [102.5684617, 4.8939233],
          [102.5693573, 4.8939714],
          [102.5706782, 4.8939287],
          [102.5712528, 4.8937806],
          [102.5720222, 4.893794],
          [102.5729301, 4.8935777],
          [102.5728749, 4.8925774],
          [102.5733011, 4.8919577],
          [102.5738545, 4.8910738],
          [102.5742466, 4.8903965],
          [102.5745351, 4.889719],
          [102.5749269, 4.8891796],
          [102.5757441, 4.8882504],
          [102.5765719, 4.8879075],
          [102.5773638, 4.8881623],
          [102.5780983, 4.8884055],
          [102.5793051, 4.887983],
          [102.5799381, 4.8873179],
          [102.580583, 4.8865491],
          [102.5810783, 4.8858605],
          [102.5818272, 4.8848278],
          [102.5837764, 4.8826812],
          [102.5848484, 4.881811],
          [102.5854017, 4.8812837],
          [102.5860012, 4.8806875],
          [102.5867034, 4.8802989],
          [102.5869013, 4.8795748],
          [102.5866855, 4.8789528],
          [102.5867108, 4.8782743],
          [102.5873455, 4.8775287],
          [102.5879693, 4.8766222],
          [102.5883502, 4.8760598],
          [102.588731, 4.8756355],
          [102.5892619, 4.8750161],
          [102.5900798, 4.8743171],
          [102.5904275, 4.8735361],
          [102.5908663, 4.8728819],
          [102.5913396, 4.8722738],
          [102.5919618, 4.8718043],
          [102.592915, 4.8718996],
          [102.5941094, 4.8719611],
          [102.5950064, 4.871734],
          [102.5958345, 4.8714952],
          [102.5964892, 4.8715204],
          [102.5975788, 4.8720188],
          [102.5984376, 4.8727234],
          [102.5991609, 4.8728868],
          [102.5999074, 4.8729583],
          [102.6007229, 4.872984],
          [102.601538, 4.8731593],
          [102.6029734, 4.8733711],
          [102.6040077, 4.8732251],
          [102.6048352, 4.8730784],
          [102.605284, 4.8729418],
          [102.6062743, 4.8722664],
          [102.6072427, 4.8713609],
          [102.6077725, 4.8709601],
          [102.6083835, 4.8703754],
          [102.6087532, 4.869813],
          [102.609286, 4.8686531],
          [102.6094049, 4.8675491],
          [102.6097864, 4.8669063],
          [102.6103287, 4.8662064],
          [102.610607, 4.8655057],
          [102.6110575, 4.864817],
          [102.6119576, 4.8637272],
          [102.6123848, 4.863188],
          [102.6131669, 4.8628455],
          [102.6139267, 4.862457],
          [102.6145958, 4.8616654],
          [102.6154156, 4.8605754],
          [102.6158195, 4.8600247],
          [102.6164645, 4.8595667],
          [102.6170859, 4.8592696],
          [102.6178985, 4.860112],
          [102.6190922, 4.8604497],
          [102.619896, 4.8605443],
          [102.620746, 4.8605356],
          [102.6214698, 4.860561],
          [102.6223213, 4.8601612],
          [102.6232438, 4.8592096],
          [102.6238779, 4.858579],
          [102.6244886, 4.8580519],
          [102.6249615, 4.8574898],
          [102.6257109, 4.8568251],
          [102.6267252, 4.8558738],
          [102.6271066, 4.8552078],
          [102.6275666, 4.8551288],
          [102.6283135, 4.8550279],
          [102.6287615, 4.8550753],
          [102.6299241, 4.854412],
          [102.6304982, 4.8544829],
          [102.6312316, 4.855003],
          [102.631909, 4.8550858],
          [102.6327133, 4.8550194],
          [102.6335756, 4.8548842],
          [102.6343002, 4.8546795],
          [102.6349679, 4.8542561],
          [102.6352341, 4.8536588],
          [102.6353749, 4.8528425],
          [102.6359863, 4.8521774],
          [102.6362288, 4.8518217],
          [102.6367364, 4.8512021],
          [102.6370699, 4.8511457],
          [102.638081, 4.8510685],
          [102.6387367, 4.8508062],
          [102.6394164, 4.8503023],
          [102.6401415, 4.849925],
          [102.6407289, 4.8494899],
          [102.6412371, 4.8487669],
          [102.6417434, 4.8484924],
          [102.6427662, 4.8483694],
          [102.6433651, 4.8479342],
          [102.6440561, 4.8475224],
          [102.6447118, 4.847237],
          [102.6456741, 4.8479533],
          [102.6463135, 4.8491056],
          [102.647105, 4.8493728],
          [102.6478859, 4.8494674],
          [102.6485755, 4.8494006],
          [102.6483492, 4.8483877],
          [102.6489611, 4.8475844],
          [102.649858, 4.8473458],
          [102.6504342, 4.8468417],
          [102.6508383, 4.8462448],
          [102.6511859, 4.8454637],
          [102.6511553, 4.8443248],
          [102.6509298, 4.8431393],
          [102.6514492, 4.8425083],
          [102.6521285, 4.8419814],
          [102.6525791, 4.8413043],
          [102.6525585, 4.84066],
          [102.6519862, 4.840106],
          [102.6514833, 4.8394027],
          [102.6516469, 4.838552],
          [102.6517992, 4.8377588],
          [102.6517562, 4.8369534],
          [102.6517585, 4.8362978],
          [102.6515082, 4.8356299],
          [102.6510597, 4.8356973],
          [102.6503707, 4.8356721],
          [102.6496959, 4.8348532],
          [102.6493656, 4.8340929],
          [102.6492764, 4.8333449],
          [102.6487962, 4.8327108],
          [102.6480273, 4.8324436],
          [102.6477666, 4.8315225],
          [102.647596, 4.8309583],
          [102.6473115, 4.8301982],
          [102.6467977, 4.8294029],
          [102.6463187, 4.828366],
          [102.6459089, 4.8273985],
          [102.6455783, 4.8266956],
          [102.645098, 4.8260154],
          [102.6446413, 4.8253122],
          [102.6441055, 4.8241717],
          [102.6438799, 4.8229516],
          [102.644239, 4.8221591],
          [102.6449413, 4.8217243],
          [102.6454594, 4.821381],
          [102.6454388, 4.8207252],
          [102.6446634, 4.8191583],
          [102.6444819, 4.818479],
          [102.6441401, 4.8176612],
          [102.6438896, 4.8170851],
          [102.6437657, 4.8164061],
          [102.6438494, 4.8154862],
          [102.6445184, 4.8146947],
          [102.6451861, 4.8143518],
          [102.6458879, 4.8139974],
          [102.6467274, 4.8137472],
          [102.6477387, 4.8136125],
          [102.6485548, 4.8135116],
          [102.6496222, 4.8137797],
          [102.6504145, 4.8138858],
          [102.6507951, 4.8134385],
          [102.651371, 4.8129687],
          [102.6516252, 4.8125554],
          [102.6519271, 4.8116593],
          [102.652344, 4.8107864],
          [102.6529996, 4.810478],
          [102.6536214, 4.8100659],
          [102.6542662, 4.8096999],
          [102.6546131, 4.8089764],
          [102.6553648, 4.8076331],
          [102.6558956, 4.8069791],
          [102.6562772, 4.8062556],
          [102.6568307, 4.8056133],
          [102.657385, 4.8048444],
          [102.6575711, 4.8041894],
          [102.6577484, 4.8028211],
          [102.6580038, 4.8020397],
          [102.6584885, 4.8014431],
          [102.6589163, 4.8006049],
          [102.659495, 4.7993759],
          [102.6597044, 4.798675],
          [102.6596152, 4.7979499],
          [102.6591245, 4.7970166],
          [102.6588641, 4.7960036],
          [102.6586148, 4.7950135],
          [102.6587543, 4.7945654],
          [102.6589057, 4.7939217],
          [102.6593676, 4.793233],
          [102.659852, 4.7927055],
          [102.660661, 4.7913738],
          [102.6602756, 4.7898886],
          [102.6600947, 4.7891404],
          [102.6598908, 4.7883],
          [102.6608921, 4.7877166],
          [102.6615269, 4.7868789],
          [102.6622418, 4.7861452],
          [102.6626002, 4.7854906],
          [102.6631542, 4.7847678],
          [102.6640765, 4.7838045],
          [102.6644793, 4.7835873],
          [102.6651126, 4.7832213],
          [102.6656311, 4.7827629],
          [102.6659775, 4.7822463],
          [102.666325, 4.7814078],
          [102.6664926, 4.7795334],
          [102.6662665, 4.7784859],
          [102.6658778, 4.777967],
          [102.6654557, 4.7771259],
          [102.6648251, 4.7768363],
          [102.6641853, 4.7758449],
          [102.6643511, 4.7744881],
          [102.664526, 4.7737295],
          [102.66446, 4.7729011],
          [102.6641667, 4.7714623],
          [102.6637785, 4.7707823],
          [102.663, 4.7700436],
          [102.6623814, 4.7695929],
          [102.6619804, 4.7692696],
          [102.6611331, 4.7685421],
          [102.6601821, 4.7678719],
          [102.6598062, 4.7670079],
          [102.6594983, 4.7664088],
          [102.6591103, 4.7656483],
          [102.6587573, 4.7647614],
          [102.6584966, 4.7638288],
          [102.6581437, 4.7629995],
          [102.6578018, 4.7621586],
          [102.6576767, 4.7617331],
          [102.6569877, 4.7618339],
          [102.6557129, 4.7617491],
          [102.6549663, 4.7617697],
          [102.6545643, 4.7617454],
          [102.6538294, 4.7617085],
          [102.6531161, 4.7619822],
          [102.6526552, 4.7623833],
          [102.6521133, 4.7630027],
          [102.6515484, 4.7635759],
          [102.6509151, 4.763942],
          [102.6503411, 4.7639056],
          [102.6491692, 4.7639362],
          [102.6477904, 4.7640467],
          [102.6468827, 4.7641703],
          [102.6461129, 4.7642367],
          [102.6451365, 4.764199],
          [102.6440336, 4.7643334],
          [102.6431248, 4.7646755],
          [102.6421706, 4.7648908],
          [102.6414691, 4.7651186],
          [102.6405043, 4.7651614],
          [102.6392292, 4.7651802],
          [102.6384244, 4.7653501],
          [102.6376423, 4.7656351],
          [102.636987, 4.76584],
          [102.6361363, 4.7660097],
          [102.6352611, 4.766628],
          [102.6345119, 4.7673271],
          [102.6340512, 4.7676707],
          [102.6333833, 4.7681401],
          [102.6328882, 4.7684721],
          [102.6319437, 4.7692051],
          [102.6314239, 4.7699971],
          [102.6308926, 4.770835],
          [102.6305912, 4.7715818],
          [102.6302436, 4.7723627],
          [102.6299201, 4.7728793],
          [102.6294235, 4.7736828],
          [102.6282946, 4.7745534],
          [102.6274895, 4.7747807],
          [102.6267424, 4.7749738],
          [102.6256735, 4.7751198],
          [102.6240637, 4.7755976],
          [102.6229941, 4.7760196],
          [102.6222122, 4.7761895],
          [102.6214765, 4.7763596],
          [102.6204759, 4.7766554],
          [102.6191427, 4.776927],
          [102.6186007, 4.7775003],
          [102.6181163, 4.7780738],
          [102.6172755, 4.7786347],
          [102.61663, 4.7792883],
          [102.6155136, 4.7798942],
          [102.6149145, 4.7803523],
          [102.6139259, 4.780533],
          [102.6125353, 4.7807585],
          [102.611535, 4.7810081],
          [102.6110407, 4.7811216],
          [102.6105469, 4.7810739],
          [102.6100083, 4.7807155],
          [102.6093892, 4.7804029],
          [102.6088069, 4.7795037],
          [102.6082808, 4.7788462],
          [102.6076637, 4.7779238],
          [102.6072068, 4.7772322],
          [102.6068418, 4.7765178],
          [102.6066156, 4.7755623],
          [102.6061264, 4.7743298],
          [102.6054277, 4.7737524],
          [102.6046138, 4.7732895],
          [102.6039945, 4.7730918],
          [102.6031806, 4.7725945],
          [102.6026886, 4.7720177],
          [102.6024726, 4.7714763],
          [102.601313, 4.7712883],
          [102.6007168, 4.7709873],
          [102.599995, 4.7705478],
          [102.5995145, 4.7699596],
          [102.5994732, 4.768717],
          [102.5999933, 4.7678101],
          [102.6006743, 4.7669496],
          [102.601262, 4.7664225],
          [102.6018952, 4.765999],
          [102.6031273, 4.7651979],
          [102.6036355, 4.7644519],
          [102.6038681, 4.7636705],
          [102.6036757, 4.7628417],
          [102.6033913, 4.7620585],
          [102.6030635, 4.7606656],
          [102.6041918, 4.7599217],
          [102.6047106, 4.7593942],
          [102.6051041, 4.7586133],
          [102.6051305, 4.7577047],
          [102.6048917, 4.7570252],
          [102.6041926, 4.7565858],
          [102.6033101, 4.7560191],
          [102.602738, 4.7554421],
          [102.6023843, 4.7548312],
          [102.6016752, 4.7539891],
          [102.6009884, 4.7533542],
          [102.6000835, 4.7526264],
          [102.5996266, 4.7519462],
          [102.5998464, 4.7514868],
          [102.6001019, 4.7507285],
          [102.6000944, 4.7496241],
          [102.5997413, 4.7488754],
          [102.5989642, 4.7477454],
          [102.5985529, 4.7471343],
          [102.598073, 4.7465116],
          [102.5975358, 4.7457736],
          [102.5979415, 4.7447741],
          [102.5985883, 4.7437641],
          [102.5986486, 4.7429706],
          [102.5985139, 4.7421189],
          [102.5982648, 4.7411978],
          [102.5977953, 4.7407707],
          [102.5969812, 4.7404228],
          [102.5959596, 4.7402353],
          [102.5953637, 4.7398423],
          [102.5948718, 4.7393114],
          [102.594656, 4.7386319],
          [102.5946238, 4.7380567],
          [102.5954325, 4.7367596],
          [102.596173, 4.7359212],
          [102.5969532, 4.7355225],
          [102.5976897, 4.7352373],
          [102.5982874, 4.7350093],
          [102.5987735, 4.734305],
          [102.5990208, 4.7337955],
          [102.5999729, 4.7327144],
          [102.6004114, 4.7321177],
          [102.601011, 4.7314986],
          [102.6017152, 4.7305117],
          [102.6021787, 4.7294089],
          [102.6022393, 4.7285234],
          [102.6022651, 4.7277643],
          [102.6024174, 4.7269712],
          [102.6025828, 4.7256948],
          [102.602862, 4.724695],
          [102.6031055, 4.7240632],
          [102.6032122, 4.7231663],
          [102.6032861, 4.7218667],
          [102.6032671, 4.7207279],
          [102.6037297, 4.7199357],
          [102.6037097, 4.7191305],
          [102.6033215, 4.7184505],
          [102.6030718, 4.7175984],
          [102.602775, 4.7171603],
          [102.602469, 4.7159975],
          [102.6027363, 4.7151471],
          [102.6029913, 4.7145153],
          [102.6031433, 4.7137912],
          [102.6032262, 4.7131013],
          [102.6032652, 4.711859],
          [102.603729, 4.7106873],
          [102.6037324, 4.7097096],
          [102.603678, 4.7089272],
          [102.6036578, 4.7082025],
          [102.603881, 4.7068228],
          [102.6041266, 4.7056044],
          [102.6042093, 4.7049835],
          [102.6045334, 4.7043174],
          [102.6049625, 4.7031455],
          [102.6053001, 4.7018929],
          [102.6054172, 4.701318],
          [102.6055807, 4.700594],
          [102.605457, 4.6998919],
          [102.6048979, 4.6988317],
          [102.6041775, 4.6980355],
          [102.6036995, 4.6967916],
          [102.6036224, 4.6958826],
          [102.6035909, 4.6951348],
          [102.6035472, 4.694502],
          [102.6035842, 4.6938005],
          [102.6035442, 4.6922129],
          [102.6034674, 4.6912118],
          [102.6034815, 4.6904872],
          [102.6036343, 4.6894986],
          [102.6038703, 4.6878084],
          [102.6029872, 4.6874602],
          [102.6019996, 4.6874684],
          [102.6011845, 4.6873276],
          [102.6005649, 4.6871645],
          [102.5991073, 4.6869181],
          [102.5981674, 4.6864433],
          [102.5976158, 4.6864874],
          [102.5972706, 4.6866587],
          [102.5965678, 4.6872315],
          [102.595865, 4.6878273],
          [102.5950016, 4.6883765],
          [102.5942066, 4.6891445],
          [102.593539, 4.6895105],
          [102.5928255, 4.6898876],
          [102.5921005, 4.6902647],
          [102.591124, 4.6902959],
          [102.5905509, 4.6900179],
          [102.5901271, 4.689752],
          [102.5895971, 4.6901643],
          [102.5889876, 4.6903923],
          [102.5884339, 4.6910115],
          [102.5879962, 4.6913781],
          [102.587028, 4.6923296],
          [102.5861633, 4.6931894],
          [102.585633, 4.6937052],
          [102.5849537, 4.694186],
          [102.5841135, 4.6946433],
          [102.5826412, 4.6952481],
          [102.5821322, 4.6956055],
          [102.5815234, 4.6958425],
          [102.5812966, 4.6954849],
          [102.58076, 4.6952399],
          [102.5801675, 4.6947764],
          [102.5796645, 4.6946351],
          [102.5789418, 4.694722],
          [102.5781731, 4.6948774],
          [102.577281, 4.6946416],
          [102.5765383, 4.6943607],
          [102.5758077, 4.6939881],
          [102.5749168, 4.69358],
          [102.574222, 4.6930239],
          [102.5736433, 4.6922389],
          [102.5731313, 4.691753],
          [102.5726547, 4.6911181],
          [102.5719808, 4.6908147],
          [102.5710741, 4.6910266],
          [102.5704277, 4.6916882],
          [102.5700344, 4.6922137],
          [102.5694227, 4.6928067],
          [102.5690156, 4.6936308],
          [102.5684948, 4.6943621],
          [102.5679498, 4.6952542],
          [102.567167, 4.6957541],
          [102.5664762, 4.696243],
          [102.56582, 4.6966519],
          [102.5651751, 4.6971068],
          [102.5645427, 4.6974239],
          [102.5641288, 4.6975933],
          [102.5635793, 4.6975551],
          [102.5633138, 4.6977944],
          [102.5628541, 4.6980095],
          [102.5625198, 4.6982597],
          [102.5621606, 4.69882],
          [102.561917, 4.6992318],
          [102.5616589, 4.7000684],
          [102.5614932, 4.7007908],
          [102.5610438, 4.7011665],
          [102.5608577, 4.7015557],
          [102.5603844, 4.7020002],
          [102.5600846, 4.7022739],
          [102.5596692, 4.7026729],
          [102.5593677, 4.7031992],
          [102.559046, 4.7032888],
          [102.5585942, 4.7039747],
          [102.5581442, 4.7044079],
          [102.5579226, 4.7049691],
          [102.5575416, 4.7053685],
          [102.5573329, 4.7056886],
          [102.5570889, 4.7061347],
          [102.556835, 4.7064201],
          [102.5560415, 4.706828],
          [102.5554089, 4.7071682],
          [102.554972, 4.7073489],
          [102.5544193, 4.707724],
          [102.5538893, 4.7081452],
          [102.5534146, 4.7088079],
          [102.5528819, 4.7095966],
          [102.5523731, 4.7102363],
          [102.5517525, 4.7104846],
          [102.5510075, 4.7105023],
          [102.5504212, 4.7107624],
          [102.5497797, 4.710735],
          [102.5490482, 4.7104887],
          [102.548499, 4.7103814],
          [102.5478383, 4.7098485],
          [102.5472916, 4.7093853],
          [102.5466645, 4.7089674],
          [102.5460737, 4.7082628],
          [102.5456341, 4.707272],
          [102.545424, 4.7062369],
          [102.5452343, 4.7055464],
          [102.5451521, 4.7042136],
          [102.5452889, 4.7027561],
          [102.5453782, 4.7015163],
          [102.5455625, 4.7013569],
          [102.5453713, 4.7008503],
          [102.5452372, 4.7003785],
          [102.5449096, 4.6997445],
          [102.5445803, 4.6993631],
          [102.5441503, 4.6986136],
          [102.5443384, 4.6979489],
          [102.5441378, 4.697178],
          [102.5439355, 4.6966368],
          [102.5437881, 4.6964061],
          [102.543495, 4.6957608],
          [102.5427781, 4.6950782],
          [102.5419466, 4.6944063],
          [102.5412635, 4.6937584],
          [102.5410933, 4.6935505],
          [102.5404793, 4.69288],
          [102.5399319, 4.6925546],
          [102.539489, 4.6919773],
          [102.5390221, 4.691618],
          [102.5386137, 4.6910524],
          [102.5383099, 4.6902692],
          [102.5380053, 4.6896356],
          [102.5371864, 4.6887799],
          [102.5362285, 4.6881759],
          [102.5358103, 4.6873576],
          [102.535563, 4.6867243],
          [102.5347842, 4.6866614],
          [102.5340974, 4.6865991],
          [102.5325839, 4.6867378],
          [102.5321414, 4.686126],
          [102.5318256, 4.6854576],
          [102.5311904, 4.6845459],
          [102.5305791, 4.6835195],
          [102.5301142, 4.68285],
          [102.5299472, 4.6821943],
          [102.5296684, 4.6811587],
          [102.5296015, 4.6793436],
          [102.5296296, 4.6786204],
          [102.5296916, 4.6779661],
          [102.5297441, 4.6770476],
          [102.5291955, 4.676883],
          [102.528726, 4.6768109],
          [102.5281633, 4.6770137],
          [102.527588, 4.6773426],
          [102.526659, 4.677474],
          [102.525777, 4.6774447],
          [102.5247024, 4.6771041],
          [102.5242347, 4.6768368],
          [102.5230807, 4.6763806],
          [102.5223166, 4.6759045],
          [102.5215392, 4.6756348],
          [102.5206937, 4.6753073],
          [102.5190249, 4.6747903],
          [102.5180997, 4.6743933],
          [102.5171389, 4.6741453],
          [102.5163972, 4.6737611],
          [102.5155978, 4.673365],
          [102.5149183, 4.6723036],
          [102.5143399, 4.6714611],
          [102.5140244, 4.6707468],
          [102.513377, 4.6699613],
          [102.5127875, 4.6691187],
          [102.5121744, 4.6683335],
          [102.511428, 4.6685578],
          [102.510704, 4.6688973],
          [102.5099567, 4.6692366],
          [102.50914, 4.6696787],
          [102.5083248, 4.6698912],
          [102.5074313, 4.6698619],
          [102.5065491, 4.6698671],
          [102.5056444, 4.6697919],
          [102.5052999, 4.6699043],
          [102.5041657, 4.6698962],
          [102.5031605, 4.6694642],
          [102.5026366, 4.6690471],
          [102.5020436, 4.6686638],
          [102.5016676, 4.6683971],
          [102.501074, 4.6680943],
          [102.4999775, 4.6692006],
          [102.4992961, 4.6699423],
          [102.4989036, 4.670307],
          [102.4984297, 4.6708894],
          [102.4979785, 4.6714718],
          [102.4972859, 4.672202],
          [102.4967535, 4.6729218],
          [102.4962781, 4.6736765],
          [102.4960009, 4.6739846],
          [102.4954371, 4.6743251],
          [102.4947247, 4.6745612],
          [102.493806, 4.6748648],
          [102.493161, 4.6753197],
          [102.4924449, 4.6761071],
          [102.491959, 4.6767352],
          [102.4913825, 4.6772251],
          [102.4907145, 4.6777141],
          [102.490009, 4.678605],
          [102.4893734, 4.6793355],
          [102.4885335, 4.6798003],
          [102.4879702, 4.680072],
          [102.4869936, 4.6804556],
          [102.4859305, 4.681654],
          [102.4852394, 4.6821774],
          [102.4845808, 4.6828847],
          [102.4838306, 4.6836489],
          [102.4827011, 4.6845596],
          [102.4819409, 4.6851055],
          [102.4812156, 4.6855827],
          [102.4800284, 4.6865045],
          [102.4788542, 4.6872542],
          [102.4780719, 4.6876736],
          [102.4772911, 4.6879207],
          [102.4765549, 4.688329],
          [102.4750456, 4.6894551],
          [102.4741847, 4.6896673],
          [102.4734268, 4.6898801],
          [102.4726689, 4.6901274],
          [102.4720691, 4.6906399],
          [102.4712515, 4.691197],
          [102.470401, 4.6915698],
          [102.4697677, 4.6919673],
          [102.4688834, 4.692248],
          [102.4680323, 4.6927014],
          [102.4670897, 4.6931198],
          [102.466477, 4.6938618],
          [102.4659126, 4.6942484],
          [102.4650738, 4.6945868],
          [102.4643608, 4.6949264],
          [102.4635079, 4.6955864],
          [102.4628265, 4.6963281],
          [102.4623746, 4.6970139],
          [102.4616937, 4.6976867],
          [102.4611072, 4.6979812],
          [102.460174, 4.698675],
          [102.4595293, 4.6990954],
          [102.4588733, 4.6994582],
          [102.4581833, 4.6998093],
          [102.4569855, 4.7006162],
          [102.456935, 4.7012589],
          [102.4575818, 4.7021479],
          [102.4579896, 4.7027711],
          [102.4582718, 4.7033357],
          [102.4588151, 4.704247],
          [102.459073, 4.7050068],
          [102.458667, 4.705693],
          [102.4580784, 4.7062745],
          [102.4574239, 4.706442],
          [102.4564228, 4.7070092],
          [102.4558336, 4.7076826],
          [102.4552209, 4.7083903],
          [102.4544962, 4.7087986],
          [102.453748, 4.7092755],
          [102.452793, 4.7097971],
          [102.4520686, 4.7101364],
          [102.4511839, 4.7104517],
          [102.4504609, 4.7106074],
          [102.4498635, 4.7107983],
          [102.4492755, 4.711311],
          [102.448457, 4.7119942],
          [102.4480309, 4.7122553],
          [102.4472615, 4.7125139],
          [102.4466185, 4.7126931],
          [102.4455057, 4.7128575],
          [102.4447281, 4.7126566],
          [102.4440554, 4.7122154],
          [102.4434615, 4.711947],
          [102.4426609, 4.711723],
          [102.4416754, 4.711739],
          [102.440826, 4.7119511],
          [102.4395865, 4.7122179],
          [102.4388858, 4.7124541],
          [102.4380485, 4.7125744],
          [102.4372917, 4.7126378],
          [102.4364003, 4.7123329],
          [102.4354971, 4.7120508],
          [102.434606, 4.7117114],
          [102.4338519, 4.7114418],
          [102.4327308, 4.7111582],
          [102.4319194, 4.7108882],
          [102.4310847, 4.710664],
          [102.4303074, 4.7104288],
          [102.4295744, 4.7103546],
          [102.4286924, 4.7103253],
          [102.4275116, 4.7103972],
          [102.4268795, 4.7106453],
          [102.4264782, 4.7107113],
          [102.4259604, 4.7109833],
          [102.4251793, 4.7112533],
          [102.4244225, 4.7113283],
          [102.4237007, 4.7113116],
          [102.4234881, 4.712183],
          [102.4233128, 4.7126296],
          [102.4229556, 4.7128912],
          [102.4224158, 4.7130481],
          [102.4216805, 4.7133414],
          [102.4211171, 4.71359],
          [102.4201398, 4.714054],
          [102.419094, 4.7144598],
          [102.4183487, 4.7145234],
          [102.4175922, 4.7145754],
          [102.4167087, 4.7147068],
          [102.4157123, 4.7146538],
          [102.4149235, 4.7143954],
          [102.4138122, 4.7143989],
          [102.4130107, 4.7143242],
          [102.4123897, 4.7145954],
          [102.4116906, 4.7146363],
          [102.4106906, 4.7150541],
          [102.4100335, 4.7156006],
          [102.4094819, 4.7158034],
          [102.4087013, 4.7159815],
          [102.407968, 4.7159992],
          [102.4072689, 4.7160171],
          [102.4068011, 4.7157266],
          [102.4072087, 4.7148797],
          [102.4068803, 4.7143376],
          [102.4063235, 4.7137134],
          [102.406051, 4.7134013],
          [102.4053651, 4.7131666],
          [102.4050875, 4.7135322],
          [102.4044319, 4.7138491],
          [102.4041047, 4.7131806],
          [102.4035576, 4.7127976],
          [102.4030234, 4.7121965],
          [102.4024554, 4.7115264],
          [102.4021603, 4.7111682],
          [102.4017031, 4.7110156],
          [102.4009557, 4.7113548],
          [102.4004032, 4.7116839],
          [102.4000231, 4.7119682],
          [102.3992086, 4.7120887],
          [102.3982811, 4.7120246],
          [102.3975122, 4.7121683],
          [102.3966613, 4.7125871],
          [102.3961788, 4.7127329],
          [102.3954344, 4.7126701],
          [102.3947573, 4.7128261],
          [102.3943572, 4.7127198],
          [102.3934628, 4.7128052],
          [102.3926596, 4.7129487],
          [102.3916952, 4.7132059],
          [102.3910404, 4.7134194],
          [102.3902951, 4.713483],
          [102.3894466, 4.7135572],
          [102.3881288, 4.7135936],
          [102.3872689, 4.7136677],
          [102.3866044, 4.7136515],
          [102.3857453, 4.7136109],
          [102.38491, 4.713444],
          [102.383901, 4.71354],
          [102.3832815, 4.7136504],
          [102.3824218, 4.7136902],
          [102.3815733, 4.7137529],
          [102.380806, 4.7137014],
          [102.379981, 4.7137185],
          [102.3790537, 4.7136084],
          [102.3786324, 4.7132608],
          [102.378235, 4.7127755],
          [102.3776788, 4.7120709],
          [102.3773504, 4.7115287],
          [102.3769059, 4.7112384],
          [102.3763145, 4.7106598],
          [102.3758129, 4.7102887],
          [102.3753428, 4.7103197],
          [102.3747354, 4.7103728],
          [102.3740809, 4.7105173],
          [102.3727846, 4.7107262],
          [102.3718205, 4.7109604],
          [102.371087, 4.7109895],
          [102.3703655, 4.7109154],
          [102.3695885, 4.7106571],
          [102.3687429, 4.7103408],
          [102.3680935, 4.7098194],
          [102.3672133, 4.7095717],
          [102.3664595, 4.7092102],
          [102.3662332, 4.708864],
          [102.3657996, 4.7086082],
          [102.3656342, 4.7077686],
          [102.3654549, 4.7072046],
          [102.3646127, 4.7064749],
          [102.3638139, 4.7060327],
          [102.3631995, 4.705454],
          [102.3627423, 4.7052783],
          [102.3624475, 4.7048743],
          [102.3619281, 4.7038483],
          [102.3613156, 4.70304],
          [102.3609079, 4.7023938],
          [102.3607075, 4.7016229],
          [102.3606791, 4.7008417],
          [102.3609373, 4.7000052],
          [102.3618829, 4.6992196],
          [102.3617954, 4.6986677],
          [102.3621342, 4.6977974],
          [102.362206, 4.6973614],
          [102.3623275, 4.696455],
          [102.3636497, 4.6958329],
          [102.3642731, 4.6952173],
          [102.3649309, 4.6946019],
          [102.3656357, 4.693826],
          [102.3671875, 4.6931712],
          [102.3678905, 4.6926135],
          [102.3687737, 4.6924935],
          [102.3702052, 4.6925958],
          [102.3711725, 4.6919712],
          [102.371486, 4.6913993],
          [102.371562, 4.6904465],
          [102.3716594, 4.6896778],
          [102.3718826, 4.6889558],
          [102.3719933, 4.6879345],
          [102.3717446, 4.6874849],
          [102.3713598, 4.6868388],
          [102.3711139, 4.6860331],
          [102.3712339, 4.6852876],
          [102.3712522, 4.6843919],
          [102.3711327, 4.6835181],
          [102.3707159, 4.6825848],
          [102.3700551, 4.6820632],
          [102.3693363, 4.6816674],
          [102.3685847, 4.6810763],
          [102.368234, 4.680465],
          [102.3676923, 4.6793585],
          [102.3669398, 4.6788937],
          [102.3662895, 4.6784869],
          [102.3656522, 4.6779311],
          [102.3649138, 4.6770873],
          [102.3646473, 4.6759484],
          [102.3644698, 4.6751777],
          [102.364315, 4.6744415],
          [102.3642956, 4.673936],
          [102.3642684, 4.6729825],
          [102.3641875, 4.6715808],
          [102.3640673, 4.6708104],
          [102.363924, 4.6700628],
          [102.3634602, 4.6692556],
          [102.3628663, 4.6689872],
          [102.3621235, 4.6687635],
          [102.3611193, 4.6681934],
          [102.3603203, 4.6678201],
          [102.3596365, 4.6673213],
          [102.3591679, 4.6671571],
          [102.3581963, 4.66684],
          [102.357557, 4.6665711],
          [102.3570657, 4.6663608],
          [102.3563123, 4.6659879],
          [102.3555018, 4.6655799],
          [102.3545568, 4.6648151],
          [102.3541385, 4.664077],
          [102.3539352, 4.663685],
          [102.3537885, 4.6633625],
          [102.3532779, 4.6626926],
          [102.3528941, 4.6619318],
          [102.3521775, 4.6612605],
          [102.3515299, 4.6605093],
          [102.3510758, 4.659966],
          [102.3507575, 4.6596651],
          [102.3504618, 4.6593644],
          [102.3501543, 4.659121],
          [102.3498366, 4.6586937],
          [102.3495553, 4.6580255],
          [102.3490656, 4.6576314],
          [102.3484944, 4.6573976],
          [102.347833, 4.6569794],
          [102.347254, 4.6562746],
          [102.346751, 4.6561216],
          [102.3465947, 4.6556036],
          [102.346038, 4.654945],
          [102.345414, 4.6541365],
          [102.3450872, 4.6534106],
          [102.3447837, 4.6526732],
          [102.3443881, 4.6519353],
          [102.3439373, 4.6509329],
          [102.3435881, 4.6501263],
          [102.3432746, 4.6491939],
          [102.342924, 4.6485596],
          [102.3426772, 4.6478802],
          [102.3427097, 4.6465941],
          [102.3430256, 4.6457351],
          [102.3432006, 4.6453343],
          [102.3435928, 4.6449583],
          [102.3440104, 4.6442838],
          [102.3442451, 4.6435617],
          [102.343874, 4.6426289],
          [102.3435493, 4.6416159],
          [102.3427373, 4.6414146],
          [102.342028, 4.6412831],
          [102.3412158, 4.6411508],
          [102.3402554, 4.6408567],
          [102.3392021, 4.6407686],
          [102.3385127, 4.6410392],
          [102.3378791, 4.6414941],
          [102.3372098, 4.6420978],
          [102.3364022, 4.6428499],
          [102.3360661, 4.6433757],
          [102.3355314, 4.6428666],
          [102.3349741, 4.6423342],
          [102.3344051, 4.6418361],
          [102.3334483, 4.6410826],
          [102.3332597, 4.6402544],
          [102.3329685, 4.6393909],
          [102.3326879, 4.6386192],
          [102.3325099, 4.637929],
          [102.3318829, 4.6374764],
          [102.3305059, 4.6377879],
          [102.3297516, 4.6375298],
          [102.3288147, 4.6371668],
          [102.3281651, 4.6367141],
          [102.3274449, 4.6365022],
          [102.3267477, 4.6363133],
          [102.3255444, 4.6363275],
          [102.3247874, 4.6364368],
          [102.3244196, 4.6366179],
          [102.3238805, 4.6367173],
          [102.3227909, 4.6368242],
          [102.3217503, 4.6365869],
          [102.3208129, 4.6363158],
          [102.3200809, 4.6361497],
          [102.3192346, 4.6359598],
          [102.3179641, 4.6357896],
          [102.3173422, 4.6362214],
          [102.3169141, 4.636781],
          [102.3162557, 4.6374538],
          [102.3156773, 4.6381847],
          [102.315435, 4.638424],
          [102.3146973, 4.6390158],
          [102.3137777, 4.6394226],
          [102.3132032, 4.6396365],
          [102.3123539, 4.6398256],
          [102.3117121, 4.6398438],
          [102.310988, 4.6400098],
          [102.3101392, 4.6400965],
          [102.3093366, 4.640149],
          [102.3081011, 4.6414068],
          [102.3075133, 4.6418172],
          [102.3066404, 4.6420995],
          [102.3044451, 4.6429139],
          [102.3027759, 4.6439275],
          [102.3014435, 4.6443102],
          [102.2996962, 4.6450009],
          [102.2990523, 4.6453074],
          [102.2979796, 4.6462211],
          [102.2967537, 4.6461557],
          [102.2963265, 4.6465789],
          [102.2958063, 4.6471968],
          [102.2933843, 4.649241],
          [102.2926589, 4.6496622],
          [102.2907456, 4.6511457],
          [102.2902022, 4.6517751],
          [102.2889321, 4.6530097],
          [102.2873163, 4.6545526],
          [102.2864995, 4.6549273],
          [102.2847305, 4.6554913],
          [102.2835455, 4.6560591],
          [102.2830039, 4.6564698],
          [102.2817737, 4.6569682],
          [102.2797636, 4.6575882],
          [102.279187, 4.6580678],
          [102.2778515, 4.6588877],
          [102.2759246, 4.6590595],
          [102.2753383, 4.6592629],
          [102.2741781, 4.659612],
          [102.272151, 4.6609454],
          [102.2715847, 4.6615861],
          [102.2701116, 4.6608748],
          [102.2689039, 4.6599006],
          [102.268219, 4.6595279],
          [102.2666859, 4.6591501],
          [102.2655606, 4.6579117],
          [102.2650265, 4.6572753],
          [102.2635204, 4.6563453],
          [102.262627, 4.6562821],
          [102.261802, 4.6562422],
          [102.2610348, 4.6561798],
          [102.2601881, 4.6559787],
          [102.2593201, 4.6555704],
          [102.2582444, 4.6553334],
          [102.257669, 4.6556405],
          [102.257243, 4.6559254],
          [102.2568311, 4.6557961],
          [102.2560433, 4.6553768],
          [102.2552851, 4.6556366],
          [102.2542634, 4.6558602],
          [102.2533923, 4.6558776],
          [102.2526135, 4.655792],
          [102.2517542, 4.655752],
          [102.2507449, 4.6558146],
          [102.2496668, 4.6559457],
          [102.2492689, 4.6554944],
          [102.2486872, 4.655111],
          [102.2479351, 4.6545538],
          [102.2469808, 4.6534087],
          [102.2461825, 4.6528742],
          [102.2453141, 4.6525004],
          [102.2444244, 4.651954],
          [102.2421697, 4.6515251],
          [102.241368, 4.6514395],
          [102.2400091, 4.6507634],
          [102.2383186, 4.6499815],
          [102.2365318, 4.6498435],
          [102.2344547, 4.6501868],
          [102.2337766, 4.6504355],
          [102.2325464, 4.6509454],
          [102.2320031, 4.6500214],
          [102.2316588, 4.6485694],
          [102.2313652, 4.6479692],
          [102.2302533, 4.6464661],
          [102.229736, 4.6451627],
          [102.229845, 4.6443694],
          [102.2308869, 4.641442],
          [102.2314613, 4.6397429],
          [102.2321971, 4.6378951],
          [102.2325772, 4.6361142],
          [102.2338705, 4.6348453],
          [102.23495, 4.6330113],
          [102.2331136, 4.6318489],
          [102.2303482, 4.6307494],
          [102.228439, 4.6301157],
          [102.2266912, 4.6293336],
          [102.2261111, 4.6287545],
          [102.2250432, 4.6274934],
          [102.2235084, 4.6273685],
          [102.2228902, 4.6272839],
          [102.221766, 4.6274378],
          [102.2195932, 4.6282866],
          [102.2189378, 4.628593],
          [102.2159606, 4.6297474],
          [102.2152717, 4.6299155],
          [102.2143737, 4.630462],
          [102.2122032, 4.6325766],
          [102.2108311, 4.6336838],
          [102.2091664, 4.6340298],
          [102.2083741, 4.6342547],
          [102.2071566, 4.6346035],
          [102.2052022, 4.6353963],
          [102.204387, 4.6355981],
          [102.2036519, 4.6357774],
          [102.2014019, 4.6362575],
          [102.2009157, 4.6368987],
          [102.2004401, 4.6376896],
          [102.1994085, 4.6377058],
          [102.1984981, 4.6383673],
          [102.1976921, 4.6388568],
          [102.1966869, 4.639932],
          [102.1951723, 4.6416595],
          [102.1948141, 4.6435556],
          [102.1935129, 4.6443641],
          [102.1931758, 4.6450178],
          [102.1929871, 4.6457069],
          [102.1927593, 4.6470287],
          [102.1923376, 4.6482456],
          [102.1917944, 4.6488634],
          [102.1904427, 4.6502468],
          [102.1897703, 4.6512895],
          [102.18925, 4.651896],
          [102.1879944, 4.6527392],
          [102.186576, 4.6538691],
          [102.1859767, 4.654291],
          [102.1847799, 4.6549275],
          [102.1833412, 4.655735],
          [102.1825705, 4.6561328],
          [102.181488, 4.6567815],
          [102.1796609, 4.6574024],
          [102.1789138, 4.6576852],
          [102.1780997, 4.657749],
          [102.1772525, 4.6576283],
          [102.1761601, 4.6580815],
          [102.174933, 4.6581885],
          [102.175025, 4.6596388],
          [102.1748326, 4.6608342],
          [102.1741973, 4.661486],
          [102.1737687, 4.6620814],
          [102.1736643, 4.6637606],
          [102.1740126, 4.6646834],
          [102.1746277, 4.6651937],
          [102.1752438, 4.665566],
          [102.1759224, 4.666767],
          [102.1760272, 4.6680794],
          [102.1755131, 4.6693763],
          [102.175329, 4.6709629],
          [102.1757864, 4.6726227],
          [102.1756327, 4.6732546],
          [102.1752816, 4.6741958],
          [102.1749717, 4.6757816],
          [102.1747024, 4.6765392],
          [102.1741604, 4.6769845],
          [102.1725706, 4.6780442],
          [102.1719055, 4.6795817],
          [102.1711289, 4.6807502],
          [102.1698639, 4.6827901],
          [102.1692323, 4.6829585],
          [102.1664218, 4.6847811],
          [102.1650396, 4.6856695],
          [102.1625183, 4.6871373],
          [102.161167, 4.690005],
          [102.1608977, 4.6907627],
          [102.1606228, 4.6922221],
          [102.1581169, 4.6931953],
          [102.1570099, 4.6940509],
          [102.1547392, 4.6957274],
          [102.1542356, 4.6971509],
          [102.1532813, 4.6990431],
          [102.1526807, 4.6996606],
          [102.1519679, 4.6999089],
          [102.1513245, 4.7001349],
          [102.1502788, 4.701992],
          [102.1497718, 4.7023454],
          [102.1492288, 4.7029057],
          [102.1487977, 4.7038233],
          [102.1483737, 4.7037745],
          [102.1464067, 4.7047281],
          [102.1447728, 4.7055227],
          [102.142636, 4.7061645],
          [102.1418671, 4.7062862],
          [102.1397844, 4.7073424],
          [102.1385485, 4.7085884],
          [102.1363598, 4.7100353],
          [102.1345319, 4.7107252],
          [102.1321055, 4.7117677],
          [102.131472, 4.7121548],
          [102.1306875, 4.7143243],
          [102.1294732, 4.71572],
          [102.1281822, 4.7181508],
          [102.1275277, 4.7183421],
          [102.1261515, 4.7199093],
          [102.1250104, 4.7207418],
          [102.1242321, 4.7220828],
          [102.1258726, 4.7249012],
          [102.1263655, 4.7264232],
          [102.1263761, 4.7295414],
          [102.1266621, 4.7310966],
          [102.1272164, 4.7335741],
          [102.1275433, 4.7343127],
          [102.1269749, 4.7381865],
          [102.1265192, 4.7392881],
          [102.1250603, 4.7412345],
          [102.1245842, 4.7420367],
          [102.1237268, 4.7432163],
          [102.1227749, 4.7447633],
          [102.1224938, 4.7455784],
          [102.1220782, 4.7489354],
          [102.1210956, 4.7500105],
          [102.1202764, 4.75073],
          [102.1200677, 4.7525236],
          [102.1198442, 4.7532815],
          [102.1181276, 4.7559051],
          [102.11737, 4.7575224],
          [102.1167344, 4.7597159],
          [102.1165275, 4.7612563],
          [102.1151874, 4.7611324],
          [102.1134651, 4.7614892],
          [102.1097441, 4.7609122],
          [102.106678, 4.7601441],
          [102.1046427, 4.7594631],
          [102.1029732, 4.7589228],
          [102.1004615, 4.7591017],
          [102.0990554, 4.7585977],
          [102.0984501, 4.758283],
          [102.0967424, 4.7582486],
          [102.0948117, 4.7588916],
          [102.0935871, 4.757169],
          [102.092465, 4.7554818],
          [102.0909983, 4.7539416],
          [102.089188, 4.7538145],
          [102.0876852, 4.7539657],
          [102.0858292, 4.7523309],
          [102.0842147, 4.7506057],
          [102.0825694, 4.7499274],
          [102.0809167, 4.7487197],
          [102.0797249, 4.74717],
          [102.0784166, 4.7458611],
          [102.0765929, 4.7445601],
          [102.075851, 4.744118],
          [102.0744344, 4.7435102],
          [102.0729834, 4.7428908],
          [102.0721702, 4.7427932],
          [102.0714474, 4.742869],
          [102.0701771, 4.7426188],
          [102.0695094, 4.7414868],
          [102.0689382, 4.7412643],
          [102.0674491, 4.7411278],
          [102.0669741, 4.7402848],
          [102.0660372, 4.7398872],
          [102.0653855, 4.7396643],
          [102.0639352, 4.7389756],
          [102.0623877, 4.7389654],
          [102.0619581, 4.7396644],
          [102.0616896, 4.7402839],
          [102.0613364, 4.7415127],
          [102.0602311, 4.7421266],
          [102.0596991, 4.7427559],
          [102.0587435, 4.7432903],
          [102.057484, 4.7430747],
          [102.0569743, 4.7423004],
          [102.0565437, 4.7416646],
          [102.0556367, 4.7403584],
          [102.0542941, 4.7390837],
          [102.053679, 4.7385733],
          [102.0532123, 4.7381445],
          [102.0515081, 4.7361885],
          [102.050985, 4.7356326],
          [102.0502329, 4.7350869],
          [102.0504565, 4.7343059],
          [102.0516239, 4.7330942],
          [102.0518128, 4.7323705],
          [102.0517121, 4.7305863],
          [102.0514387, 4.7288586],
          [102.0516515, 4.7280431],
          [102.0514122, 4.7263731],
          [102.0501702, 4.7254097],
          [102.0494943, 4.7253478],
          [102.0476341, 4.7257724],
          [102.0471204, 4.7269772],
          [102.0463161, 4.7272364],
          [102.0450805, 4.7269519],
          [102.0437321, 4.7263906],
          [102.0434528, 4.7269295],
          [102.0430922, 4.7275945],
          [102.0418303, 4.7307041],
          [102.0410532, 4.731126],
          [102.040583, 4.7315292],
          [102.0400213, 4.7320244],
          [102.0394479, 4.7321402],
          [102.0387479, 4.731934],
          [102.0379676, 4.7315786],
          [102.036969, 4.7313269],
          [102.0361202, 4.7313165],
          [102.0353862, 4.7316969],
          [102.0344232, 4.7323077],
          [102.0339994, 4.7329524],
          [102.0336099, 4.7338728],
          [102.0331179, 4.7349201],
          [102.0324303, 4.7358296],
          [102.0318574, 4.7365663],
          [102.0312844, 4.7374757],
          [102.0310217, 4.7384076],
          [102.030667, 4.7393511],
          [102.0301282, 4.7400419],
          [102.0297044, 4.7406634],
          [102.029395, 4.7410778],
          [102.0285416, 4.7418675],
          [102.0276843, 4.74235],
          [102.0269278, 4.7418186],
          [102.025642, 4.7417425],
          [102.023902, 4.7420457],
          [102.0229943, 4.7418178],
          [102.0226922, 4.7399964],
          [102.022995, 4.7387821],
          [102.0231464, 4.7377197],
          [102.0221633, 4.736657],
          [102.0216343, 4.7350632],
          [102.0208782, 4.7333935],
          [102.0198192, 4.7330896],
          [102.0178524, 4.7335445],
          [102.0167931, 4.7347585],
          [102.0160364, 4.735669],
          [102.0145991, 4.7361999],
          [102.0133132, 4.7365032],
          [102.0121025, 4.7368824],
          [102.0114974, 4.7375653],
          [102.0109674, 4.7390071],
          [102.0108914, 4.7406007],
          [102.0107399, 4.7418149],
          [102.0099074, 4.7433325],
          [102.0087723, 4.7448501],
          [102.0077885, 4.7465195],
          [102.0066537, 4.747354],
          [102.0051405, 4.7478089],
          [102.0039302, 4.7481881],
          [102.0037031, 4.7490987],
          [102.0036272, 4.7500853],
          [102.0027192, 4.7509198],
          [102.0028702, 4.7520583],
          [102.0027188, 4.7524376],
          [102.0021134, 4.7531206],
          [102.0020375, 4.7540312],
          [102.0018103, 4.7553972],
          [102.0015073, 4.7572944],
          [102.001507, 4.7585086],
          [102.0024145, 4.7596472],
          [102.0024141, 4.7611651],
          [102.0019598, 4.7623033],
          [102.0012786, 4.7632897],
          [102.000598, 4.7632895],
          [101.9993877, 4.7622267],
          [101.9981775, 4.7617711],
          [101.9977787, 4.7615439],
          [101.997531, 4.7614746],
          [101.9966587, 4.7610502],
          [101.9962109, 4.7606482],
          [101.9957173, 4.7603153],
          [101.9948567, 4.7601324],
          [101.9940995, 4.7601909],
          [101.9934113, 4.7605827],
          [101.992907, 4.7610549],
          [101.9921495, 4.7609062],
          [101.9916789, 4.7604009],
          [101.9911391, 4.759838],
          [101.9906681, 4.759367],
          [101.9903351, 4.7590109],
          [101.9897958, 4.7589311],
          [101.9893144, 4.7592651],
          [101.9889937, 4.7599786],
          [101.988535, 4.7601287],
          [101.9881443, 4.7595656],
          [101.9877881, 4.7589565],
          [101.987168, 4.7583478],
          [101.9866969, 4.7573937],
          [101.9868452, 4.7566806],
          [101.9871546, 4.7563236],
          [101.9871657, 4.755691],
          [101.9871652, 4.7551276],
          [101.9872218, 4.7544604],
          [101.9874281, 4.7538966],
          [101.9878289, 4.7533901],
          [101.9883795, 4.7530099],
          [101.9884246, 4.7523887],
          [101.9879881, 4.7516648],
          [101.9876547, 4.7511362],
          [101.9869662, 4.7508609],
          [101.9860941, 4.7506205],
          [101.985394, 4.7502993],
          [101.9850031, 4.7495178],
          [101.9848875, 4.7485288],
          [101.9852882, 4.7474933],
          [101.9857003, 4.7466187],
          [101.9859866, 4.7457558],
          [101.98633, 4.7448123],
          [101.9866504, 4.7439954],
          [101.9867758, 4.7430751],
          [101.9868551, 4.742132],
          [101.9866248, 4.7412237],
          [101.9861885, 4.7407987],
          [101.9856725, 4.7408914],
          [101.9851911, 4.7412945],
          [101.9846752, 4.7418586],
          [101.9842511, 4.7424112],
          [101.9837011, 4.7430444],
          [101.9830473, 4.7433903],
          [101.9823936, 4.743713],
          [101.9816368, 4.7438174],
          [101.9809369, 4.7438758],
          [101.98026, 4.7439112],
          [101.9798357, 4.7441647],
          [101.9795031, 4.7442686],
          [101.9788838, 4.7444189],
          [101.9783792, 4.7446841],
          [101.9779092, 4.7450871],
          [101.9775768, 4.745674],
          [101.9774741, 4.7461687],
          [101.9774631, 4.7467783],
          [101.9771309, 4.7474343],
          [101.9766727, 4.7479752],
          [101.9761678, 4.7479874],
          [101.97564, 4.7479651],
          [101.9752501, 4.748092],
          [101.9748833, 4.7485525],
          [101.974792, 4.7492311],
          [101.9747813, 4.7499327],
          [101.9743915, 4.7504393],
          [101.9736806, 4.7504977],
          [101.9730378, 4.7504409],
          [101.9724182, 4.7504301],
          [101.9716152, 4.7504426],
          [101.9707663, 4.7504896],
          [101.9702387, 4.7506858],
          [101.9699178, 4.7511232],
          [101.9695857, 4.7516986],
          [101.9693683, 4.7523659],
          [101.9693572, 4.753056],
          [101.9691632, 4.7539649],
          [101.9690148, 4.755023],
          [101.9689466, 4.7558627],
          [101.9689244, 4.7566678],
          [101.9689712, 4.7575993],
          [101.9688919, 4.7585654],
          [101.9688349, 4.7593591],
          [101.9686751, 4.7602103],
          [101.9682626, 4.7609123],
          [101.9676207, 4.7612811],
          [101.9664964, 4.7616275],
          [101.9655675, 4.7619277],
          [101.9650059, 4.7627564],
          [101.9647429, 4.7634582],
          [101.9645483, 4.7641601],
          [101.9644688, 4.7649652],
          [101.9643313, 4.7653794],
          [101.9639873, 4.7654948],
          [101.9634133, 4.765231],
          [101.9629539, 4.7646679],
          [101.9621736, 4.7643699],
          [101.961554, 4.7642211],
          [101.9606473, 4.7639461],
          [101.9599128, 4.763671],
          [101.9592469, 4.7632577],
          [101.9588221, 4.7627062],
          [101.9584427, 4.7618326],
          [101.957811, 4.7610628],
          [101.9570992, 4.7607187],
          [101.9563765, 4.7605585],
          [101.9554239, 4.7601572],
          [101.9544139, 4.7599513],
          [101.9535189, 4.7597108],
          [101.9526466, 4.7592863],
          [101.9517055, 4.7587354],
          [101.9509823, 4.7582301],
          [101.9500409, 4.7575643],
          [101.9497764, 4.756909],
          [101.9496496, 4.7561386],
          [101.9501763, 4.7549879],
          [101.950118, 4.7539184],
          [101.9499564, 4.752573],
          [101.9493703, 4.7515616],
          [101.9491742, 4.7504463],
          [101.9491733, 4.7493307],
          [101.9493562, 4.7483414],
          [101.9494127, 4.7472487],
          [101.9495723, 4.7461445],
          [101.9497434, 4.7451897],
          [101.9499951, 4.7441774],
          [101.9503958, 4.7432223],
          [101.9508998, 4.7421752],
          [101.9513578, 4.7412661],
          [101.9518388, 4.7403454],
          [101.9520449, 4.7395977],
          [101.9522274, 4.7385854],
          [101.9524099, 4.7370556],
          [101.9525122, 4.7358939],
          [101.9527406, 4.734962],
          [101.9527168, 4.733766],
          [101.9526007, 4.732363],
          [101.9526001, 4.7313969],
          [101.9521407, 4.7308684],
          [101.951486, 4.7301677],
          [101.951176, 4.7295355],
          [101.951393, 4.7285692],
          [101.9515412, 4.7273729],
          [101.9516428, 4.7256131],
          [101.9517676, 4.7238764],
          [101.9518468, 4.7224157],
          [101.9521096, 4.7214148],
          [101.9524533, 4.7205863],
          [101.9525787, 4.7198847],
          [101.9527962, 4.7191024],
          [101.9527951, 4.7178143],
          [101.9529091, 4.7169401],
          [101.9528508, 4.7160547],
          [101.9529305, 4.7151114],
          [101.9530903, 4.7142372],
          [101.953227, 4.713317],
          [101.9531229, 4.712282],
          [101.9529734, 4.7117072],
          [101.9527087, 4.7108679],
          [101.952536, 4.7101436],
          [101.9523516, 4.7090857],
          [101.9521101, 4.7083154],
          [101.9517081, 4.7077064],
          [101.9513404, 4.7070857],
          [101.9509266, 4.7062006],
          [101.9506622, 4.7054419],
          [101.9502138, 4.7044303],
          [101.9494441, 4.7030973],
          [101.9493402, 4.7024534],
          [101.9492134, 4.7015794],
          [101.949029, 4.700602],
          [101.9488217, 4.6997282],
          [101.948752, 4.6985666],
          [101.9487284, 4.6976927],
          [101.9486012, 4.6964392],
          [101.9484858, 4.6957377],
          [101.9482673, 4.6949791],
          [101.9478994, 4.6941283],
          [101.947497, 4.6934158],
          [101.9472327, 4.6925651],
          [101.9470366, 4.6913807],
          [101.9468868, 4.6905874],
          [101.9466449, 4.6894031],
          [101.9463805, 4.6886673],
          [101.9460013, 4.6881272],
          [101.945714, 4.6874375],
          [101.945289, 4.6868055],
          [101.9449443, 4.6862193],
          [101.9447487, 4.6854605],
          [101.944576, 4.6847821],
          [101.9444148, 4.6838737],
          [101.9442648, 4.6829654],
          [101.9441261, 4.681781],
          [101.943919, 4.6808841],
          [101.9437349, 4.6801944],
          [101.9435507, 4.6796195],
          [101.9437684, 4.6791822],
          [101.9443413, 4.6784799],
          [101.9449601, 4.6775822],
          [101.9452117, 4.6767998],
          [101.9450042, 4.6754199],
          [101.944659, 4.6743738],
          [101.9442797, 4.6734426],
          [101.9440842, 4.6725918],
          [101.943716, 4.6716377],
          [101.9435663, 4.6707753],
          [101.9434737, 4.6697863],
          [101.9434154, 4.6688434],
          [101.9433686, 4.6678429],
          [101.9432421, 4.6671645],
          [101.9427483, 4.666636],
          [101.9419562, 4.6659583],
          [101.9411874, 4.6656488],
          [101.9408994, 4.6643265],
          [101.9406923, 4.6635217],
          [101.9404162, 4.6625789],
          [101.940014, 4.6616938],
          [101.9395087, 4.6610849],
          [101.9390721, 4.6602228],
          [101.9387274, 4.6594643],
          [101.9382222, 4.6589702],
          [101.9373617, 4.6586837],
          [101.9364324, 4.6585467],
          [101.9352393, 4.6585712],
          [101.9337135, 4.6584924],
          [101.9320386, 4.6582643],
          [101.9299392, 4.6580827],
          [101.9289181, 4.6579919],
          [101.9280462, 4.6577974],
          [101.9273806, 4.6575796],
          [101.9268983, 4.6569362],
          [101.9264273, 4.6562006],
          [101.9260943, 4.6555915],
          [101.9257379, 4.6548214],
          [101.9254965, 4.6541316],
          [101.9253697, 4.6533612],
          [101.9249792, 4.6526487],
          [101.9243359, 4.6517407],
          [101.923796, 4.6509593],
          [101.9234973, 4.6500627],
          [101.9237487, 4.6489237],
          [101.9240001, 4.6478999],
          [101.9237584, 4.6468651],
          [101.9236431, 4.6460717],
          [101.9235505, 4.6451287],
          [101.9230449, 4.6438873],
          [101.9224707, 4.6430483],
          [101.9221028, 4.6423356],
          [101.9219073, 4.6415539],
          [101.9213096, 4.6403124],
          [101.9205524, 4.6398762],
          [101.9199553, 4.6390718],
          [101.9191515, 4.6381068],
          [101.918428, 4.6370725],
          [101.9176354, 4.6359579],
          [101.9167856, 4.6346591],
          [101.9162114, 4.6339238],
          [101.9158553, 4.6332112],
          [101.9155677, 4.6323145],
          [101.9153719, 4.6313255],
          [101.9152216, 4.6297502],
          [101.9147351, 4.6288587],
          [101.9146313, 4.6279617],
          [101.9143894, 4.6268235],
          [101.9140446, 4.6258232],
          [101.9135506, 4.6250533],
          [101.9133551, 4.6243059],
          [101.9131481, 4.623455],
          [101.9131818, 4.6228224],
          [101.9134336, 4.6221436],
          [101.9136623, 4.6212924],
          [101.9138684, 4.6205561],
          [101.9138789, 4.6197051],
          [101.9137175, 4.6187046],
          [101.9136825, 4.617727],
          [101.9136702, 4.616646],
          [101.9137611, 4.6156454],
          [101.91368, 4.6145529],
          [101.9133351, 4.6136447],
          [101.9129559, 4.6128286],
          [101.9126341, 4.6121618],
          [101.9123239, 4.6114032],
          [101.9118868, 4.6103435],
          [101.9114848, 4.6096738],
          [101.9112469, 4.6096229],
          [101.9097523, 4.6100003],
          [101.9085937, 4.6098127],
          [101.9079849, 4.6095753],
          [101.9060706, 4.6094504],
          [101.9046925, 4.6089537],
          [101.9038553, 4.6088445],
          [101.9034088, 4.6089051],
          [101.9009035, 4.6095894],
          [101.9001142, 4.6098248],
          [101.8992573, 4.6102332],
          [101.8979221, 4.611808],
          [101.8972457, 4.6124528],
          [101.8969478, 4.6127981],
          [101.8964552, 4.6134198],
          [101.8954006, 4.6143986],
          [101.894266, 4.6156534],
          [101.8941287, 4.6163895],
          [101.8938998, 4.6170453],
          [101.8934195, 4.6185869],
          [101.8933632, 4.6199441],
          [101.8932493, 4.6210368],
          [101.8932726, 4.6217959],
          [101.8931471, 4.6225666],
          [101.8930337, 4.6240847],
          [101.8929999, 4.6248668],
          [101.8931725, 4.6257407],
          [101.893196, 4.6265573],
          [101.893186, 4.628409],
          [101.8927509, 4.6291799],
          [101.8924073, 4.6299164],
          [101.8918914, 4.630515],
          [101.8912381, 4.6313667],
          [101.8905626, 4.6329086],
          [101.8900356, 4.6334956],
          [101.8896575, 4.6343127],
          [101.889177, 4.6356818],
          [101.8888566, 4.6370967],
          [101.8887426, 4.6377524],
          [101.8887544, 4.638408],
          [101.8894443, 4.6404658],
          [101.8893644, 4.6411445],
          [101.8889178, 4.641973],
          [101.8885402, 4.643112],
          [101.8894471, 4.644215],
          [101.8902853, 4.6450423],
          [101.8908249, 4.6455936],
          [101.8915598, 4.6468808],
          [101.8922032, 4.6482028],
          [101.8922843, 4.6489732],
          [101.8924684, 4.6497322],
          [101.8932031, 4.6506285],
          [101.8929517, 4.6517213],
          [101.8924929, 4.6520207],
          [101.8917136, 4.6527576],
          [101.8911292, 4.6534369],
          [101.8907509, 4.6540468],
          [101.8904883, 4.6557723],
          [101.8907873, 4.6567264],
          [101.8911434, 4.6573585],
          [101.8918095, 4.6583814],
          [101.8927399, 4.659657],
          [101.8933138, 4.6604499],
          [101.8937044, 4.6610476],
          [101.8938197, 4.6617029],
          [101.8934189, 4.6626924],
          [101.892249, 4.6629698],
          [101.890976, 4.6635002],
          [101.890357, 4.6638228],
          [101.8896458, 4.6640537],
          [101.8881556, 4.6653664],
          [101.8877661, 4.6660453],
          [101.8875602, 4.6669771],
          [101.8877789, 4.667966],
          [101.8882278, 4.6697941],
          [101.8881596, 4.6706107],
          [101.8881256, 4.6713123],
          [101.8881377, 4.6719448],
          [101.887935, 4.6727964],
          [101.8873078, 4.6734049],
          [101.8866088, 4.6740152],
          [101.8861958, 4.6744181],
          [101.8854736, 4.6747984],
          [101.8840516, 4.6753865],
          [101.8830767, 4.6758705],
          [101.8824579, 4.6765729],
          [101.8817927, 4.6769646],
          [101.8805656, 4.6775179],
          [101.8791092, 4.6779449],
          [101.8782605, 4.678406],
          [101.8774234, 4.6786369],
          [101.8757597, 4.6783512],
          [101.8741996, 4.6783757],
          [101.8734079, 4.6783306],
          [101.8716644, 4.6784015],
          [101.8702653, 4.6788515],
          [101.8695196, 4.6791399],
          [101.868029, 4.6797855],
          [101.8666762, 4.6812476],
          [101.8661489, 4.6817771],
          [101.8657821, 4.6821225],
          [101.8653698, 4.6830545],
          [101.8651527, 4.6842164],
          [101.8649812, 4.685148],
          [101.864936, 4.6858842],
          [101.8650857, 4.6865281],
          [101.865052, 4.6877472],
          [101.8646743, 4.6888516],
          [101.8641471, 4.6897378],
          [101.8638608, 4.6902786],
          [101.863712, 4.6910378],
          [101.8634378, 4.6924526],
          [101.8629792, 4.6929362],
          [101.8621763, 4.692868],
          [101.8614073, 4.6924432],
          [101.8610746, 4.692225],
          [101.8595136, 4.6914102],
          [101.8590429, 4.6907552],
          [101.8587442, 4.6899504],
          [101.8585482, 4.688835],
          [101.8580771, 4.6876395],
          [101.8573999, 4.6871456],
          [101.8568029, 4.6866632],
          [101.8556208, 4.6856524],
          [101.8543583, 4.6849291],
          [101.8536237, 4.684562],
          [101.8532681, 4.6842518],
          [101.8521997, 4.6839159],
          [101.8520091, 4.6839268],
          [101.8518098, 4.683937],
          [101.8507833, 4.6842094],
          [101.8500034, 4.6846011],
          [101.8492928, 4.685223],
          [101.8487427, 4.6860056],
          [101.8483643, 4.68665],
          [101.8478142, 4.6873521],
          [101.8474937, 4.688215],
          [101.8475059, 4.689227],
          [101.8475525, 4.6902966],
          [101.8475304, 4.6912856],
          [101.8475195, 4.6920908],
          [101.8473019, 4.6927236],
          [101.8468548, 4.6933104],
          [101.8461671, 4.6939322],
          [101.8458232, 4.6944041],
          [101.8456859, 4.6950598],
          [101.8455147, 4.6961181],
          [101.8455725, 4.6970611],
          [101.8454817, 4.6983723],
          [101.8453334, 4.6993614],
          [101.8450701, 4.7003277],
          [101.8452198, 4.7010751],
          [101.845599, 4.7019258],
          [101.8460124, 4.7026614],
          [101.8462999, 4.7035582],
          [101.8466102, 4.7045239],
          [101.8465535, 4.7052599],
          [101.8463017, 4.7061459],
          [101.8461303, 4.7069396],
          [101.8456723, 4.7081821],
          [101.8452828, 4.709275],
          [101.8448019, 4.7103911],
          [101.844504, 4.7111849],
          [101.8442868, 4.7119328],
          [101.8439889, 4.7126576],
          [101.8437369, 4.7133019],
          [101.843439, 4.7141073],
          [101.8431414, 4.714924],
          [101.8430157, 4.7154532],
          [101.8428329, 4.7165805],
          [101.8424089, 4.7173975],
          [101.8423062, 4.7182027],
          [101.8420546, 4.7192955],
          [101.841711, 4.7198594],
          [101.8412526, 4.7206073],
          [101.8402547, 4.7211144],
          [101.8393028, 4.7215064],
          [101.8384082, 4.7217718],
          [101.8369857, 4.7218307],
          [101.8359185, 4.7215904],
          [101.8349316, 4.7212118],
          [101.834108, 4.7208246],
          [101.8337496, 4.7203785],
          [101.8337493, 4.719723],
          [101.8336683, 4.71878],
          [101.8332205, 4.7181134],
          [101.83283, 4.7175273],
          [101.8322675, 4.7169643],
          [101.8315099, 4.7165395],
          [101.8308673, 4.7162066],
          [101.8296625, 4.7159548],
          [101.8288024, 4.7161742],
          [101.8279995, 4.7166581],
          [101.8273462, 4.7172567],
          [101.8268071, 4.7176253],
          [101.8258894, 4.7176722],
          [101.8252583, 4.7178109],
          [101.8244211, 4.7180532],
          [101.8236298, 4.7183185],
          [101.823263, 4.7186639],
          [101.8227814, 4.7192854],
          [101.8223918, 4.7198838],
          [101.8220251, 4.7206087],
          [101.8216356, 4.7216671],
          [101.821189, 4.7224381],
          [101.8208796, 4.7232666],
          [101.8205473, 4.7239224],
          [101.8201463, 4.7245323],
          [101.8196993, 4.7252228],
          [101.819585, 4.725752],
          [101.8196657, 4.7266949],
          [101.8198959, 4.7275573],
          [101.8201374, 4.7285346],
          [101.8203444, 4.7291669],
          [101.8205629, 4.7298452],
          [101.8206207, 4.7306387],
          [101.8206671, 4.7314437],
          [101.8206332, 4.7322833],
          [101.820519, 4.7331344],
          [101.8204852, 4.7339165],
          [101.8205201, 4.7346525],
          [101.8208186, 4.7352158],
          [101.8211977, 4.7356869],
          [101.8214849, 4.7363766],
          [101.8215196, 4.7370437],
          [101.8213711, 4.7377913],
          [101.8213487, 4.7385044],
          [101.821418, 4.7393554],
          [101.8214071, 4.7401489],
          [101.8214075, 4.741115],
          [101.8213508, 4.7417476],
          [101.8211677, 4.7422884],
          [101.8211565, 4.7428979],
          [101.8211109, 4.743542],
          [101.8211115, 4.7443126],
          [101.8211234, 4.745152],
          [101.8212274, 4.7460835],
          [101.8214919, 4.7469457],
          [101.82171, 4.7472561],
          [101.8219399, 4.7478538],
          [101.8221925, 4.7484402],
          [101.8222617, 4.7489001],
          [101.8221933, 4.7494637],
          [101.8219757, 4.7500505],
          [101.8218269, 4.7507292],
          [101.8215754, 4.7516609],
          [101.8212178, 4.752666],
          [101.8201407, 4.7528397],
          [101.8195443, 4.7530932],
          [101.819166, 4.7534501],
          [101.8187532, 4.7539336],
          [101.8184437, 4.7544054],
          [101.8180997, 4.7549118],
          [101.8177216, 4.7554756],
          [101.8175614, 4.7559818],
          [101.8174242, 4.757017],
          [101.8170573, 4.7574315],
          [101.8167597, 4.7581677],
          [101.8163471, 4.7589156],
          [101.8160261, 4.759376],
          [101.8153611, 4.7598253],
          [101.8145811, 4.7601135],
          [101.8134454, 4.7603561],
          [101.812459, 4.7606906],
          [101.8113005, 4.7612092],
          [101.8101185, 4.7610264],
          [101.809063, 4.7608664],
          [101.8084547, 4.7606599],
          [101.8077432, 4.7604767],
          [101.8072041, 4.7607072],
          [101.8067225, 4.7611332],
          [101.8060344, 4.7613063],
          [101.8050249, 4.7613763],
          [101.8043133, 4.7613425],
          [101.8036827, 4.7617341],
          [101.8031091, 4.7620797],
          [101.8024555, 4.7625979],
          [101.801974, 4.7628972],
          [101.8013658, 4.7631049],
          [101.800666, 4.7631171],
          [101.7998286, 4.7629684],
          [101.7990022, 4.7626931],
          [101.7980499, 4.7624756],
          [101.797109, 4.7622809],
          [101.796581, 4.7620055],
          [101.7966266, 4.7613959],
          [101.7970965, 4.7607284],
          [101.7973828, 4.759854],
          [101.7975774, 4.7592559],
          [101.7978065, 4.7585656],
          [101.7977259, 4.7580251],
          [101.7972783, 4.757646],
          [101.7965897, 4.7575202],
          [101.795775, 4.7573254],
          [101.7952699, 4.7571189],
          [101.7945013, 4.7571197],
          [101.7936638, 4.757155],
          [101.7930789, 4.757397],
          [101.7923446, 4.7574552],
          [101.7914727, 4.7575251],
          [101.7906353, 4.7575143],
          [101.7897863, 4.7576647],
          [101.7888114, 4.7579876],
          [101.7883066, 4.758356],
          [101.7874005, 4.7586789],
          [101.78646, 4.7588983],
          [101.7849456, 4.7590492],
          [101.7843263, 4.7592568],
          [101.7836952, 4.7593609],
          [101.7830871, 4.75935],
          [101.7821806, 4.7592473],
          [101.7813891, 4.759225],
          [101.7804942, 4.7593869],
          [101.7800469, 4.7594563],
          [101.7794962, 4.7593763],
          [101.7793581, 4.7591349],
          [101.7794611, 4.7584448],
          [101.7795751, 4.7573866],
          [101.7793568, 4.7565933],
          [101.778886, 4.7559842],
          [101.7782547, 4.7555132],
          [101.7777955, 4.7551917],
          [101.7771988, 4.7548587],
          [101.7766706, 4.7543991],
          [101.7761539, 4.7534795],
          [101.775855, 4.7525598],
          [101.775522, 4.7517205],
          [101.7752578, 4.7510307],
          [101.7749474, 4.750249],
          [101.7749586, 4.7495934],
          [101.7750614, 4.7487308],
          [101.7753475, 4.747592],
          [101.7756107, 4.7468443],
          [101.775576, 4.7462577],
          [101.775117, 4.7457981],
          [101.7746116, 4.7451546],
          [101.7743475, 4.7445567],
          [101.7742438, 4.7438668],
          [101.7740139, 4.7432],
          [101.7739562, 4.7423376],
          [101.7739671, 4.7416474],
          [101.7738404, 4.7409231],
          [101.7739432, 4.740049],
          [101.7740118, 4.7394278],
          [101.7740569, 4.7385307],
          [101.7740453, 4.7377717],
          [101.7740677, 4.7370702],
          [101.7739296, 4.7363113],
          [101.7738718, 4.7355292],
          [101.7738826, 4.7345747],
          [101.773905, 4.7337582],
          [101.7740307, 4.732907],
          [101.7741907, 4.7317453],
          [101.7741902, 4.7309862],
          [101.7742127, 4.7301121],
          [101.7742813, 4.7295026],
          [101.7742923, 4.7287091],
          [101.7742805, 4.7281225],
          [101.7740965, 4.7274097],
          [101.7739696, 4.7265357],
          [101.773843, 4.7257883],
          [101.7738539, 4.7246612],
          [101.7739336, 4.7239826],
          [101.7739907, 4.723281],
          [101.7740933, 4.7222459],
          [101.7741733, 4.7216363],
          [101.7742644, 4.7206701],
          [101.7744476, 4.7200719],
          [101.7747801, 4.7194391],
          [101.774906, 4.7188754],
          [101.7751463, 4.7181737],
          [101.7752609, 4.717656],
          [101.7751457, 4.7170696],
          [101.7751337, 4.7162991],
          [101.7752137, 4.7156895],
          [101.775328, 4.7152063],
          [101.7754884, 4.7144932],
          [101.7755684, 4.7138836],
          [101.7753959, 4.7131246],
          [101.7750627, 4.71255],
          [101.7747181, 4.7119293],
          [101.7745112, 4.7112624],
          [101.7744992, 4.7105149],
          [101.7744992, 4.7100433],
          [101.774728, 4.7092957],
          [101.7749225, 4.7084559],
          [101.7751744, 4.707432],
          [101.7755527, 4.7070983],
          [101.7761721, 4.7068101],
          [101.7766655, 4.7067522],
          [101.777021, 4.7066713],
          [101.7772273, 4.7063377],
          [101.7771581, 4.7059811],
          [101.7769399, 4.7054639],
          [101.7766988, 4.7049466],
          [101.7764918, 4.7043372],
          [101.7759867, 4.7036016],
          [101.7752752, 4.7030847],
          [101.7745522, 4.703051],
          [101.7738524, 4.7030746],
          [101.7732101, 4.7028911],
          [101.7727164, 4.7023971],
          [101.7724178, 4.7019718],
          [101.7719701, 4.7013283],
          [101.771614, 4.7007535],
          [101.7713383, 4.7001557],
          [101.7708328, 4.6990177],
          [101.7707256, 4.6980287],
          [101.7703847, 4.6968668],
          [101.7703064, 4.6961999],
          [101.7705378, 4.6953038],
          [101.7708047, 4.6940516],
          [101.7708637, 4.6928303],
          [101.7714372, 4.6925308],
          [101.7719074, 4.6923809],
          [101.7722283, 4.6920929],
          [101.7720906, 4.6917712],
          [101.7717005, 4.6916104],
          [101.7716658, 4.691116],
          [101.7717455, 4.6904719],
          [101.7718141, 4.6897817],
          [101.7718479, 4.6891032],
          [101.7717331, 4.6883787],
          [101.771572, 4.6877349],
          [101.7709177, 4.6873215],
          [101.7706977, 4.6866943],
          [101.7702873, 4.6857852],
          [101.7703462, 4.6852105],
          [101.7704214, 4.6827852],
          [101.7704001, 4.6820726],
          [101.7696685, 4.6813463],
          [101.7698319, 4.6801513],
          [101.7696504, 4.6794495],
          [101.769423, 4.6788513],
          [101.7701586, 4.6779452],
          [101.7708371, 4.6771424],
          [101.7709589, 4.6765548],
          [101.7711407, 4.6763036],
          [101.7712092, 4.6756251],
          [101.7711169, 4.6750502],
          [101.771243, 4.6745899],
          [101.7715179, 4.6740607],
          [101.7719305, 4.6737269],
          [101.7726419, 4.6735077],
          [101.7731462, 4.6732082],
          [101.7734789, 4.6728629],
          [101.7736851, 4.6722416],
          [101.7735355, 4.6715402],
          [101.7734317, 4.6709653],
          [101.7735003, 4.6702982],
          [101.7737293, 4.6696424],
          [101.7740501, 4.6691131],
          [101.7741414, 4.66817],
          [101.7741868, 4.6674223],
          [101.7743352, 4.6662951],
          [101.7743003, 4.6655707],
          [101.7740359, 4.6645473],
          [101.773806, 4.6635125],
          [101.7737482, 4.6626846],
          [101.7739543, 4.6621438],
          [101.7741258, 4.6613616],
          [101.7732071, 4.6599478],
          [101.772966, 4.6594767],
          [101.7727132, 4.6588098],
          [101.772518, 4.6581429],
          [101.7715543, 4.6581783],
          [101.7709007, 4.658156],
          [101.7703267, 4.6572658],
          [101.7702143, 4.6564838],
          [101.7701377, 4.6550927],
          [101.7687763, 4.6540889],
          [101.7685142, 4.6534905],
          [101.7685263, 4.6517739],
          [101.7688649, 4.6508705],
          [101.7687182, 4.650031],
          [101.7686056, 4.649157],
          [101.7688737, 4.6474221],
          [101.7689105, 4.6465715],
          [101.7690378, 4.6460546],
          [101.7691771, 4.6453537],
          [101.7684496, 4.6430068],
          [101.7683029, 4.6421557],
          [101.7675864, 4.6400731],
          [101.7674844, 4.6395555],
          [101.7675097, 4.6387166],
          [101.7676952, 4.6377629],
          [101.7678119, 4.6370045],
          [101.7679072, 4.6355909],
          [101.7678985, 4.6345102],
          [101.7668507, 4.632013],
          [101.7664743, 4.6312649],
          [101.7662351, 4.6307124],
          [101.7654733, 4.6283653],
          [101.7652803, 4.6276407],
          [101.7649747, 4.626157],
          [101.76483, 4.6245127],
          [101.7647177, 4.6236387],
          [101.764469, 4.6223622],
          [101.763435, 4.6233479],
          [101.7629294, 4.6238064],
          [101.7620211, 4.624906],
          [101.7603473, 4.6251788],
          [101.7596938, 4.6252576],
          [101.758124, 4.6251038],
          [101.7574507, 4.6240445],
          [101.7571774, 4.6234],
          [101.7558141, 4.6231894],
          [101.7545208, 4.6224962],
          [101.7537419, 4.6222986],
          [101.7532516, 4.6214006],
          [101.7537021, 4.6199994],
          [101.7542651, 4.6194607],
          [101.7558368, 4.6188558],
          [101.7555407, 4.6181997],
          [101.7550828, 4.6179226],
          [101.7544886, 4.6173232],
          [101.7539865, 4.6164253],
          [101.7543242, 4.6143685],
          [101.7544401, 4.6138516],
          [101.7548656, 4.613278],
          [101.7550293, 4.6120715],
          [101.7545161, 4.6110816],
          [101.7538981, 4.6108154],
          [101.7521224, 4.6104657],
          [101.7508161, 4.6104162],
          [101.7499331, 4.6106666],
          [101.7489671, 4.611894],
          [101.7480024, 4.612696],
          [101.7472115, 4.6128433],
          [101.7466267, 4.6128647],
          [101.7454813, 4.6127007],
          [101.7441868, 4.6124672],
          [101.7435913, 4.6122587],
          [101.7424799, 4.6122097],
          [101.7415147, 4.6131497],
          [101.7409066, 4.6133663],
          [101.7402185, 4.6135944],
          [101.7392545, 4.6141436],
          [101.7381742, 4.615267],
          [101.7378741, 4.6161285],
          [101.7367254, 4.6171369],
          [101.7356001, 4.6178809],
          [101.7349002, 4.6182698],
          [101.7342912, 4.6188429],
          [101.7341025, 4.6208197],
          [101.7337225, 4.6214623],
          [101.7333437, 4.62176],
          [101.73255, 4.6229074],
          [101.7316094, 4.6232037],
          [101.7307387, 4.6230749],
          [101.7298119, 4.6224401],
          [101.7282427, 4.6221139],
          [101.7274862, 4.6221349],
          [101.7266152, 4.6221785],
          [101.7258595, 4.6219235],
          [101.7257261, 4.6203252],
          [101.7254987, 4.6196809],
          [101.725455, 4.6188302],
          [101.725699, 4.6176238],
          [101.7251965, 4.6169327],
          [101.7248087, 4.6162075],
          [101.7248328, 4.6158282],
          [101.7254655, 4.6148643],
          [101.7259618, 4.6136358],
          [101.7263873, 4.6130162],
          [101.7267665, 4.6125919],
          [101.727079, 4.6115123],
          [101.7269664, 4.6107187],
          [101.7263717, 4.6103033],
          [101.7255928, 4.6101402],
          [101.7245047, 4.6098038],
          [101.7238104, 4.6097263],
          [101.7230635, 4.6093024],
          [101.7218123, 4.6093052],
          [101.721009, 4.60956],
          [101.7203894, 4.6097223],
          [101.7193667, 4.6091036],
          [101.7184457, 4.6076795],
          [101.7179734, 4.6067719],
          [101.7161391, 4.6081792],
          [101.7155204, 4.6089281],
          [101.7139371, 4.6096217],
          [101.7132935, 4.609163],
          [101.7130857, 4.6084964],
          [101.712981, 4.6076111],
          [101.7120606, 4.6063135],
          [101.7107497, 4.6051088],
          [101.7099802, 4.6048344],
          [101.708694, 4.6047338],
          [101.7067193, 4.604646],
          [101.7058924, 4.6043604],
          [101.7048692, 4.6035461],
          [101.7030076, 4.6024577],
          [101.7021463, 4.6022755],
          [101.7003313, 4.6016239],
          [101.6990341, 4.6016842],
          [101.6977885, 4.6017327],
          [101.6956578, 4.6009441],
          [101.6950494, 4.6010374],
          [101.694212, 4.6012692],
          [101.6936955, 4.6013164],
          [101.6924546, 4.6006635],
          [101.6907437, 4.6004486],
          [101.6900542, 4.6000822],
          [101.6887213, 4.5994409],
          [101.6869977, 4.5983062],
          [101.6861246, 4.5980665],
          [101.6836321, 4.5971519],
          [101.6828958, 4.5963253],
          [101.6821951, 4.5960393],
          [101.6809319, 4.5958696],
          [101.6790012, 4.5945052],
          [101.6783466, 4.5942881],
          [101.6754085, 4.5947889],
          [101.6746052, 4.5949862],
          [101.6739967, 4.594953],
          [101.6733055, 4.5934248],
          [101.6727638, 4.5921495],
          [101.6726249, 4.5914597],
          [101.6724518, 4.5908506],
          [101.6718987, 4.5895407],
          [101.6715293, 4.5883223],
          [101.6712063, 4.5875065],
          [101.671125, 4.5867936],
          [101.6703075, 4.5852428],
          [101.6694449, 4.5842785],
          [101.6689504, 4.5837621],
          [101.6684447, 4.5834067],
          [101.6682024, 4.5825217],
          [101.668187, 4.5802446],
          [101.6681623, 4.579175],
          [101.6677581, 4.5776578],
          [101.6677562, 4.5764272],
          [101.6677205, 4.5756568],
          [101.667513, 4.5751052],
          [101.6672239, 4.5737142],
          [101.6676125, 4.5727818],
          [101.6681277, 4.5719643],
          [101.6686201, 4.5711581],
          [101.6673579, 4.5713793],
          [101.666198, 4.5711057],
          [101.665715, 4.5705893],
          [101.6653355, 4.5701071],
          [101.6642539, 4.5685914],
          [101.6631507, 4.5678806],
          [101.6626563, 4.5673871],
          [101.6621609, 4.5663991],
          [101.6616641, 4.5643415],
          [101.6611004, 4.5636183],
          [101.6597443, 4.5626895],
          [101.6573661, 4.5614755],
          [101.6568141, 4.5609017],
          [101.6558722, 4.5604551],
          [101.6542756, 4.5598951],
          [101.653448, 4.5591376],
          [101.6529418, 4.5585294],
          [101.6524703, 4.5579898],
          [101.651562, 4.5569796],
          [101.6500013, 4.5573854],
          [101.6491403, 4.5573528],
          [101.6464423, 4.55714],
          [101.6455927, 4.5571532],
          [101.6447088, 4.5570401],
          [101.6441922, 4.5570987],
          [101.6434705, 4.5580778],
          [101.6426571, 4.5590801],
          [101.6418885, 4.5592772],
          [101.6408902, 4.5596013],
          [101.6399718, 4.5597067],
          [101.6380314, 4.5594349],
          [101.6371128, 4.5593217],
          [101.6366307, 4.5593113],
          [101.635964, 4.558795],
          [101.6354345, 4.5578647],
          [101.6352035, 4.5568531],
          [101.6345139, 4.5563256],
          [101.6341915, 4.5557627],
          [101.6331922, 4.5553968],
          [101.6313993, 4.5540089],
          [101.630848, 4.553711],
          [101.6291238, 4.5523346],
          [101.6276413, 4.5513602],
          [101.6269979, 4.5508439],
          [101.6263314, 4.5504313],
          [101.6257437, 4.5490639],
          [101.6249609, 4.547651],
          [101.6245925, 4.5469274],
          [101.6240641, 4.5466408],
          [101.6235125, 4.5463085],
          [101.6221925, 4.5464263],
          [101.62124, 4.5465317],
          [101.6206664, 4.5466594],
          [101.6199548, 4.5468449],
          [101.6195075, 4.5471104],
          [101.6188317, 4.5480319],
          [101.6176981, 4.5498858],
          [101.6172512, 4.5504617],
          [101.6172539, 4.5521523],
          [101.6169471, 4.5541885],
          [101.6166382, 4.5549367],
          [101.6156072, 4.5563649],
          [101.6146793, 4.5575284],
          [101.6144392, 4.5582305],
          [101.6143946, 4.5589781],
          [101.6144763, 4.5599439],
          [101.6137193, 4.5603941],
          [101.611493, 4.5608818],
          [101.6103916, 4.56116],
          [101.6090031, 4.5616228],
          [101.6071306, 4.5606377],
          [101.6063722, 4.5601217],
          [101.6051768, 4.55925],
          [101.6026638, 4.5598534],
          [101.6019642, 4.5601998],
          [101.6000127, 4.5603533],
          [101.5980949, 4.5598397],
          [101.5973486, 4.5597032],
          [101.5954307, 4.5591666],
          [101.5942938, 4.5589159],
          [101.5934439, 4.5585612],
          [101.5930408, 4.5577913],
          [101.5927185, 4.557079],
          [101.5922232, 4.556091],
          [101.5919918, 4.5548724],
          [101.5917496, 4.5540219],
          [101.5912775, 4.5531258],
          [101.589934, 4.552795],
          [101.5896712, 4.5535891],
          [101.588456, 4.5547646],
          [101.5877448, 4.5552145],
          [101.5872748, 4.5556065],
          [101.5864036, 4.5563329],
          [101.5856822, 4.5574728],
          [101.5854651, 4.5581634],
          [101.5854198, 4.558635],
          [101.5854927, 4.5612109],
          [101.5856199, 4.5620387],
          [101.5854721, 4.56289],
          [101.584704, 4.5635356],
          [101.5828324, 4.5633208],
          [101.5819829, 4.5632191],
          [101.5802835, 4.5630961],
          [101.579079, 4.563593],
          [101.5785518, 4.5642496],
          [101.5781629, 4.565021],
          [101.5776247, 4.566057],
          [101.5767319, 4.5677609],
          [101.5762276, 4.5683254],
          [101.5756314, 4.5687636],
          [101.5751508, 4.5698801],
          [101.575611, 4.5705462],
          [101.5752448, 4.5712945],
          [101.5746946, 4.5718476],
          [101.5734566, 4.5731842],
          [101.5727815, 4.5746116],
          [101.5721049, 4.5751765],
          [101.57152, 4.5753847],
          [101.5703951, 4.575594],
          [101.5694773, 4.5759753],
          [101.5688699, 4.5767586],
          [101.5683084, 4.5772657],
          [101.567907, 4.5777266],
          [101.5670701, 4.5784643],
          [101.5658886, 4.5789266],
          [101.5651543, 4.5792271],
          [101.5638462, 4.5797473],
          [101.5631689, 4.5797141],
          [101.5621125, 4.5795322],
          [101.5612627, 4.5793729],
          [101.5601609, 4.5793635],
          [101.5581284, 4.5791721],
          [101.5573473, 4.5787021],
          [101.557071, 4.5782771],
          [101.5565187, 4.5773238],
          [101.5553245, 4.5770731],
          [101.5544982, 4.5772588],
          [101.553717, 4.5769843],
          [101.5532923, 4.5769507],
          [101.5521896, 4.5764813],
          [101.5512481, 4.5762762],
          [101.5506742, 4.5764153],
          [101.5499407, 4.5772102],
          [101.548863, 4.5782704],
          [101.5480497, 4.5795831],
          [101.5478674, 4.5804345],
          [101.54756, 4.5822292],
          [101.5470681, 4.5834376],
          [101.5466099, 4.5840941],
          [101.5464499, 4.5845774],
          [101.5457851, 4.5854298],
          [101.545719, 4.5873274],
          [101.5455365, 4.5880638],
          [101.5449211, 4.5912276],
          [101.5448186, 4.5918489],
          [101.5442583, 4.593414],
          [101.5434909, 4.5946117],
          [101.5429292, 4.5952222],
          [101.5418971, 4.5958683],
          [101.5406472, 4.5968827],
          [101.5401772, 4.5974933],
          [101.538996, 4.59822],
          [101.5379292, 4.5988892],
          [101.5371609, 4.5994082],
          [101.5367481, 4.5997656],
          [101.5356584, 4.6004002],
          [101.5346942, 4.600586],
          [101.5344887, 4.6013225],
          [101.5341107, 4.6019902],
          [101.533631, 4.6037852],
          [101.533127, 4.6046027],
          [101.5322891, 4.6046388],
          [101.5318301, 4.6047548],
          [101.5304412, 4.604861],
          [101.5282371, 4.6049342],
          [101.5275367, 4.6050391],
          [101.527009, 4.6053276],
          [101.5260797, 4.6056859],
          [101.5250126, 4.60601],
          [101.5245423, 4.6062754],
          [101.5242218, 4.607012],
          [101.5239931, 4.607714],
          [101.5233068, 4.6093484],
          [101.5233613, 4.6107381],
          [101.5232787, 4.6116],
          [101.5232426, 4.6121286],
          [101.5227939, 4.6127826],
          [101.5214533, 4.6126289],
          [101.5207542, 4.6126958],
          [101.5195386, 4.612968],
          [101.5186946, 4.6115975],
          [101.5184097, 4.6109644],
          [101.5182161, 4.6105961],
          [101.51775, 4.6093762],
          [101.5166347, 4.6080503],
          [101.5152342, 4.6079494],
          [101.5144306, 4.6081119],
          [101.513191, 4.6082524],
          [101.511435, 4.6085432],
          [101.5106543, 4.6086942],
          [101.5098285, 4.6090409],
          [101.5085539, 4.6088937],
          [101.5073011, 4.6078381],
          [101.5068191, 4.6080116],
          [101.5062804, 4.6085761],
          [101.505225, 4.6092451],
          [101.5048099, 4.6097849],
          [101.5043264, 4.6105306],
          [101.5038548, 4.6111038],
          [101.5029256, 4.6113539],
          [101.5023046, 4.6121106],
          [101.5018674, 4.6127414],
          [101.5011413, 4.6140957],
          [101.5012831, 4.6126133],
          [101.5013889, 4.6117975],
          [101.5013792, 4.6111768],
          [101.501165, 4.6099922],
          [101.5006622, 4.6095886],
          [101.5005484, 4.6093092],
          [101.4997673, 4.6082876],
          [101.4991245, 4.6077026],
          [101.4986766, 4.6072092],
          [101.498217, 4.6066238],
          [101.4978267, 4.6062107],
          [101.4966686, 4.6057075],
          [101.496071, 4.604881],
          [101.4956915, 4.6041345],
          [101.4954359, 4.602468],
          [101.4948494, 4.601423],
          [101.494059, 4.6013904],
          [101.4927417, 4.6014505],
          [101.4907925, 4.6006499],
          [101.4899324, 4.6002149],
          [101.4889456, 4.5994468],
          [101.4873434, 4.6003124],
          [101.4868065, 4.6009229],
          [101.4855712, 4.6019948],
          [101.4841051, 4.6021473],
          [101.4833831, 4.6018959],
          [101.4818119, 4.601152],
          [101.4809185, 4.6012113],
          [101.4806311, 4.6007291],
          [101.4803206, 4.6001435],
          [101.4801798, 4.5986147],
          [101.4797411, 4.5969601],
          [101.4794994, 4.5963513],
          [101.4789944, 4.59595],
          [101.4778929, 4.5952164],
          [101.4779129, 4.5938023],
          [101.4781405, 4.5930316],
          [101.4782746, 4.5914908],
          [101.4781467, 4.5904907],
          [101.4774472, 4.5902623],
          [101.4767133, 4.5898615],
          [101.4768837, 4.5891828],
          [101.4775924, 4.5884801],
          [101.4775097, 4.5872386],
          [101.4773706, 4.5865262],
          [101.4769447, 4.5854463],
          [101.4762877, 4.5835968],
          [101.4760912, 4.5827579],
          [101.4758034, 4.5820803],
          [101.4759708, 4.580022],
          [101.476336, 4.5792165],
          [101.4769859, 4.5778124],
          [101.4768225, 4.5763527],
          [101.4765459, 4.575606],
          [101.4763032, 4.5746638],
          [101.4741266, 4.5745879],
          [101.4733585, 4.5742677],
          [101.4724522, 4.5735913],
          [101.4707107, 4.57348],
          [101.4699202, 4.5735046],
          [101.4685002, 4.5737261],
          [101.4672881, 4.5746944],
          [101.4667162, 4.5752473],
          [101.4658379, 4.5769623],
          [101.464395, 4.5772411],
          [101.4641073, 4.57669],
          [101.4638659, 4.5761731],
          [101.4620876, 4.5749237],
          [101.4613997, 4.5746608],
          [101.4599669, 4.5743419],
          [101.4585583, 4.5744483],
          [101.4578828, 4.5747026],
          [101.4564179, 4.575269],
          [101.4549756, 4.5758124],
          [101.4541166, 4.5759062],
          [101.4535207, 4.5757234],
          [101.451823, 4.5747842],
          [101.4512506, 4.5748773],
          [101.4505527, 4.5753388],
          [101.4485611, 4.5760787],
          [101.4480009, 4.5767122],
          [101.4475324, 4.5772534],
          [101.4469147, 4.5777261],
          [101.4458167, 4.5785332],
          [101.4461168, 4.5796593],
          [101.4460612, 4.580418],
          [101.4449763, 4.5820184],
          [101.4451842, 4.5829032],
          [101.4451167, 4.5834896],
          [101.4449235, 4.5842602],
          [101.4443533, 4.5854916],
          [101.4440013, 4.5870673],
          [101.4438313, 4.5878725],
          [101.4435226, 4.588195],
          [101.4409589, 4.5893845],
          [101.4402947, 4.5895698],
          [101.439722, 4.5896284],
          [101.4379504, 4.5915865],
          [101.4375397, 4.5923001],
          [101.4364774, 4.5939118],
          [101.4354359, 4.5943278],
          [101.4345541, 4.594502],
          [101.4330197, 4.5947812],
          [101.4322088, 4.5959785],
          [101.4321187, 4.5968294],
          [101.4301303, 4.5991902],
          [101.4297078, 4.5999614],
          [101.4280522, 4.6026205],
          [101.427641, 4.6031961],
          [101.4262033, 4.6061078],
          [101.4256201, 4.6064883],
          [101.4228161, 4.6078965],
          [101.4219917, 4.6081282],
          [101.4191168, 4.6083869],
          [101.4183502, 4.6089174],
          [101.4170738, 4.6095585],
          [101.4164866, 4.6098315],
          [101.415808, 4.6101156],
          [101.4151409, 4.6103536],
          [101.4143233, 4.6108097],
          [101.4138044, 4.6112789],
          [101.4135709, 4.6120488],
          [101.4131979, 4.6131633],
          [101.4128276, 4.6137484],
          [101.412481, 4.6141724],
          [101.4120546, 4.6144694],
          [101.4114219, 4.6147077],
          [101.4108935, 4.6147624],
          [101.4103533, 4.6148515],
          [101.4098253, 4.6148027],
          [101.4091033, 4.6146145],
          [101.4084493, 4.6144844],
          [101.4079786, 4.6144703],
          [101.4074383, 4.6146056],
          [101.4069783, 4.6147757],
          [101.4065748, 4.6150958],
          [101.4062859, 4.615497],
          [101.4060769, 4.6159565],
          [101.4058105, 4.6163808],
          [101.4054177, 4.6168622],
          [101.4050489, 4.6171709],
          [101.4045198, 4.6173177],
          [101.4039456, 4.6173837],
          [101.4032781, 4.6176909],
          [101.4027948, 4.6178724],
          [101.402415, 4.6181121],
          [101.4018399, 4.6183047],
          [101.40138, 4.6183943],
          [101.4008524, 4.6183108],
          [101.4004635, 4.6179979],
          [101.4001216, 4.6175126],
          [101.3999292, 4.6169705],
          [101.3996905, 4.6164972],
          [101.3993834, 4.6159316],
          [101.3991672, 4.615516],
          [101.3987681, 4.6150074],
          [101.3982994, 4.6145904],
          [101.3978765, 4.6142197],
          [101.3971774, 4.6139628],
          [101.396536, 4.613637],
          [101.3958598, 4.613403],
          [101.3951604, 4.6132036],
          [101.3945075, 4.6129583],
          [101.393785, 4.6127701],
          [101.3930623, 4.6125936],
          [101.3924319, 4.6124404],
          [101.3919273, 4.6122766],
          [101.3913084, 4.6121236],
          [101.3908836, 4.6120982],
          [101.3904816, 4.6121536],
          [101.3898391, 4.6120004],
          [101.3890479, 4.611835],
          [101.3884401, 4.6116705],
          [101.3879591, 4.6114261],
          [101.3875588, 4.6111362],
          [101.387055, 4.6108572],
          [101.3867238, 4.6105101],
          [101.38621, 4.6099777],
          [101.3855579, 4.6095253],
          [101.3850762, 4.6093961],
          [101.384766, 4.609475],
          [101.3845585, 4.6096235],
          [101.3843854, 4.6098182],
          [101.3841767, 4.6101738],
          [101.3840246, 4.6107371],
          [101.3838155, 4.6112194],
          [101.3835614, 4.6115287],
          [101.3829299, 4.6115368],
          [101.382241, 4.611533],
          [101.3817581, 4.6116801],
          [101.3813206, 4.6118964],
          [101.3807231, 4.6119852],
          [101.3803441, 4.6120292],
          [101.379402, 4.6121621],
          [101.3789997, 4.6122521],
          [101.3786657, 4.6124344],
          [101.3781472, 4.6127769],
          [101.3775718, 4.6130845],
          [101.3770651, 4.6133696],
          [101.376547, 4.613666],
          [101.3761435, 4.6139861],
          [101.3757858, 4.6143294],
          [101.3752907, 4.614626],
          [101.3748552, 4.6144394],
          [101.3744201, 4.6142299],
          [101.3739276, 4.614083],
          [101.3738696, 4.6140658],
          [101.3734108, 4.6139942],
          [101.3730088, 4.6140379],
          [101.3726982, 4.6141169],
          [101.3721453, 4.6145052],
          [101.3718671, 4.6149987],
          [101.3718406, 4.6157122],
          [101.3718014, 4.6166329],
          [101.3715797, 4.6173683],
          [101.37129, 4.6178387],
          [101.3709663, 4.6183204],
          [101.370677, 4.6187907],
          [101.3705706, 4.6193657],
          [101.3706366, 4.6199185],
          [101.3707714, 4.6204834],
          [101.3709757, 4.6209793],
          [101.3711693, 4.6213143],
          [101.3712254, 4.6215679],
          [101.370993, 4.6221075],
          [101.3709104, 4.6224986],
          [101.3712515, 4.6231449],
          [101.3717079, 4.623769],
          [101.3720378, 4.6243464],
          [101.372471, 4.6249588],
          [101.3730073, 4.6256064],
          [101.3735221, 4.6259889],
          [101.3739336, 4.6263481],
          [101.3743793, 4.6267418],
          [101.3749405, 4.6269868],
          [101.3752611, 4.6271726],
          [101.3756957, 4.6275088],
          [101.3759352, 4.6278325],
          [101.3763004, 4.6282603],
          [101.3766655, 4.6287343],
          [101.3770189, 4.6291967],
          [101.3771662, 4.6296004],
          [101.3771516, 4.6301642],
          [101.3770682, 4.6307854],
          [101.3768717, 4.6310836],
          [101.3765497, 4.6311855],
          [101.3759964, 4.6315738],
          [101.3758462, 4.6317457],
          [101.3755245, 4.6318129],
          [101.3750534, 4.6319025],
          [101.374593, 4.6321071],
          [101.3741203, 4.6324613],
          [101.3736124, 4.6330341],
          [101.3732891, 4.6333776],
          [101.3729887, 4.6337673],
          [101.3726311, 4.6340993],
          [101.3721702, 4.634419],
          [101.3716865, 4.6346811],
          [101.3712716, 4.6349782],
          [101.3707882, 4.6352287],
          [101.3700416, 4.6353053],
          [101.3695702, 4.6354062],
          [101.3690881, 4.6353576],
          [101.3686304, 4.6350787],
          [101.3681273, 4.6346847],
          [101.3676685, 4.6345554],
          [101.3669689, 4.6344251],
          [101.3664524, 4.6344106],
          [101.3659576, 4.6345692],
          [101.3656126, 4.6347284],
          [101.3654043, 4.6350496],
          [101.3650913, 4.6356234],
          [101.3647905, 4.6360476],
          [101.3643408, 4.6364021],
          [101.3639143, 4.636745],
          [101.3637858, 4.6371587],
          [101.3638861, 4.6378037],
          [101.3639284, 4.6385177],
          [101.363775, 4.6393225],
          [101.3635655, 4.6398624],
          [101.3631604, 4.6405163],
          [101.3629157, 4.6411826],
          [101.3627975, 4.6418726],
          [101.3627125, 4.6427699],
          [101.3626513, 4.6434717],
          [101.3625674, 4.6441505],
          [101.3624844, 4.6447026],
          [101.3624349, 4.6453814],
          [101.3622361, 4.646117],
          [101.3620483, 4.6469103],
          [101.361861, 4.6475997],
          [101.3618, 4.6483132],
          [101.3617502, 4.649061],
          [101.3617478, 4.649556],
          [101.3618016, 4.6502124],
          [101.3618916, 4.6505697],
          [101.3620955, 4.6511464],
          [101.3619433, 4.6516981],
          [101.3616761, 4.6523528],
          [101.3611429, 4.6533167],
          [101.3608532, 4.6538102],
          [101.3603102, 4.6544517],
          [101.3600548, 4.6550144],
          [101.3599141, 4.6555775],
          [101.3597041, 4.6562556],
          [101.3594369, 4.6568526],
          [101.3591239, 4.657438],
          [101.3588456, 4.6579659],
          [101.3586009, 4.6586667],
          [101.3583096, 4.6594938],
          [101.3583076, 4.6598738],
          [101.3581325, 4.6604138],
          [101.3575926, 4.660526],
          [101.3569369, 4.6607064],
          [101.3565808, 4.6607506],
          [101.3557987, 4.6609994],
          [101.3548102, 4.6612357],
          [101.354258, 4.66144],
          [101.3538086, 4.6617481],
          [101.3534858, 4.6619881],
          [101.3530237, 4.6625151],
          [101.3524696, 4.6630877],
          [101.3519961, 4.6636259],
          [101.3516845, 4.6639696],
          [101.3513139, 4.6645431],
          [101.3511849, 4.6650949],
          [101.3512738, 4.6656825],
          [101.3514773, 4.6662476],
          [101.3513595, 4.6668915],
          [101.3510006, 4.667465],
          [101.3506432, 4.6677394],
          [101.3503662, 4.6680256],
          [101.3500765, 4.6685189],
          [101.3500733, 4.6691175],
          [101.3501506, 4.669751],
          [101.3499059, 4.6704402],
          [101.3494905, 4.6708409],
          [101.3489605, 4.6711833],
          [101.34827, 4.6715248],
          [101.3476132, 4.671924],
          [101.3471535, 4.6720251],
          [101.3466721, 4.6718383],
          [101.3462137, 4.6716746],
          [101.3455235, 4.6719586],
          [101.3449235, 4.6724963],
          [101.3444844, 4.6729887],
          [101.3441611, 4.6733668],
          [101.3436871, 4.6740203],
          [101.3434997, 4.6746984],
          [101.3432894, 4.6754455],
          [101.3430325, 4.6762497],
          [101.342466, 4.6769603],
          [101.3421308, 4.6774304],
          [101.3414502, 4.678025],
          [101.3410348, 4.6784373],
          [101.3404121, 4.6789748],
          [101.3396861, 4.6794428],
          [101.3390877, 4.6797041],
          [101.3383063, 4.6798494],
          [101.337628, 4.6800068],
          [101.3367775, 4.6801633],
          [101.3364206, 4.6803224],
          [101.3360739, 4.6807925],
          [101.3358873, 4.6813325],
          [101.3351837, 4.6819617],
          [101.3347213, 4.6825001],
          [101.3343632, 4.6829355],
          [101.3340955, 4.6836361],
          [101.3339998, 4.6843954],
          [101.3338705, 4.6849817],
          [101.3338562, 4.685488],
          [101.3339794, 4.6861102],
          [101.3343774, 4.6868492],
          [101.3344097, 4.6872752],
          [101.3342907, 4.6881149],
          [101.3343231, 4.6884833],
          [101.3343202, 4.6890473],
          [101.3340873, 4.6897022],
          [101.3338181, 4.6906447],
          [101.3336769, 4.6913115],
          [101.3335013, 4.6919896],
          [101.3332562, 4.692771],
          [101.3331261, 4.6934839],
          [101.3329624, 4.6940586],
          [101.3329138, 4.6945532],
          [101.3330141, 4.6951293],
          [101.3331623, 4.6953604],
          [101.3335848, 4.6958001],
          [101.3340309, 4.6961479],
          [101.3345458, 4.6965191],
          [101.33483, 4.6970271],
          [101.334977, 4.6974884],
          [101.33532, 4.697778],
          [101.3363868, 4.6980142],
          [101.3367646, 4.6981775],
          [101.3374064, 4.6984573],
          [101.338059, 4.6987946],
          [101.3387348, 4.6991783],
          [101.3392497, 4.6994919],
          [101.33973, 4.6998745],
          [101.3401761, 4.7002452],
          [101.3403448, 4.7009138],
          [101.3402388, 4.7014426],
          [101.3400395, 4.7022242],
          [101.3398967, 4.7032019],
          [101.3397773, 4.704099],
          [101.3396923, 4.7049274],
          [101.3396428, 4.7056293],
          [101.3398803, 4.7063327],
          [101.3402331, 4.7069678],
          [101.3406434, 4.7075685],
          [101.3409958, 4.7082497],
          [101.3412223, 4.7088495],
          [101.3415408, 4.7094383],
          [101.3418249, 4.7100614],
          [101.3420746, 4.7106153],
          [101.3422896, 4.7112497],
          [101.342437, 4.7115727],
          [101.3427097, 4.7121037],
          [101.3430069, 4.7123932],
          [101.3435112, 4.7125456],
          [101.3440736, 4.7126639],
          [101.3450839, 4.7126925],
          [101.345968, 4.7126859],
          [101.346623, 4.7125628],
          [101.3470029, 4.7124038],
          [101.347431, 4.7117846],
          [101.3476516, 4.7113023],
          [101.3478508, 4.7105667],
          [101.3480944, 4.7100616],
          [101.3483268, 4.7095334],
          [101.3486632, 4.7088562],
          [101.3489878, 4.7082364],
          [101.3493697, 4.707686],
          [101.3496254, 4.7071004],
          [101.3498345, 4.7066411],
          [101.3501014, 4.70609],
          [101.3505398, 4.7056896],
          [101.3510232, 4.705462],
          [101.3514251, 4.7054988],
          [101.3516425, 4.7056381],
          [101.3519515, 4.705847],
          [101.3522725, 4.7059409],
          [101.3529729, 4.7059333],
          [101.3534444, 4.7057862],
          [101.3541227, 4.7056403],
          [101.3548697, 4.7055063],
          [101.3555697, 4.7055677],
          [101.3562352, 4.7056981],
          [101.3570384, 4.7058061],
          [101.3575194, 4.706085],
          [101.35793, 4.7066052],
          [101.3582149, 4.7070442],
          [101.3587066, 4.7074153],
          [101.3593824, 4.7077873],
          [101.3598401, 4.7080892],
          [101.3601938, 4.708517],
          [101.3605697, 4.7091061],
          [101.3609677, 4.7098565],
          [101.361104, 4.710145],
          [101.3616292, 4.710735],
          [101.3622703, 4.7111414],
          [101.3626706, 4.7114429],
          [101.3631734, 4.7119292],
          [101.3635267, 4.712426],
          [101.3638223, 4.7130262],
          [101.3642215, 4.7135233],
          [101.3645752, 4.7139397],
          [101.3649305, 4.7141488],
          [101.3652866, 4.7141047],
          [101.3661827, 4.7139831],
          [101.3669297, 4.7138721],
          [101.3677341, 4.7137614],
          [101.3686657, 4.7134442],
          [101.3694819, 4.7132646],
          [101.3701847, 4.712808],
          [101.370544, 4.7121768],
          [101.3706393, 4.7114868],
          [101.3708745, 4.7104291],
          [101.371179, 4.7092566],
          [101.3716311, 4.7084649],
          [101.3719087, 4.708029],
          [101.3728771, 4.7072746],
          [101.3735098, 4.7070824],
          [101.3742917, 4.7068564],
          [101.3747288, 4.7067438],
          [101.3753257, 4.7067586],
          [101.3758174, 4.7071411],
          [101.376275, 4.7074774],
          [101.3766766, 4.7075717],
          [101.3771351, 4.7077009],
          [101.3777662, 4.7077965],
          [101.3784654, 4.708065],
          [101.3789464, 4.7083438],
          [101.3792784, 4.7085069],
          [101.379655, 4.7089693],
          [101.3800428, 4.7094895],
          [101.3802819, 4.7099052],
          [101.380555, 4.7103786],
          [101.3807822, 4.7108633],
          [101.3811356, 4.7113603],
          [101.3814435, 4.7117648],
          [101.3817628, 4.7121694],
          [101.3819908, 4.712539],
          [101.3819884, 4.7129879],
          [101.381905, 4.7135745],
          [101.3818679, 4.7140923],
          [101.3820951, 4.7146116],
          [101.3824832, 4.7150511],
          [101.382918, 4.7153412],
          [101.3833417, 4.7155393],
          [101.3839029, 4.7158761],
          [101.3841318, 4.7160271],
          [101.3844519, 4.7162821],
          [101.3847135, 4.7168014],
          [101.3847451, 4.7173542],
          [101.3849254, 4.7179767],
          [101.385119, 4.7183462],
          [101.3855874, 4.7187631],
          [101.3860221, 4.7191224],
          [101.3866284, 4.7195631],
          [101.3869031, 4.7197372],
          [101.3874644, 4.7199705],
          [101.3877497, 4.7203865],
          [101.387862, 4.7208475],
          [101.3877683, 4.7211923],
          [101.3876185, 4.7213411],
          [101.3875137, 4.7216167],
          [101.3871445, 4.7219716],
          [101.3867873, 4.7221769],
          [101.3865331, 4.7225093],
          [101.3864612, 4.7230614],
          [101.3865738, 4.7235224],
          [101.3868706, 4.7238809],
          [101.3872935, 4.72424],
          [101.3875322, 4.7247019],
          [101.3875868, 4.7252661],
          [101.3874239, 4.7257142],
          [101.3873073, 4.7260358],
          [101.3857762, 4.7313224],
          [101.3854753, 4.7317697],
          [101.3851042, 4.7325043],
          [101.3848251, 4.7331934],
          [101.384778, 4.7333889],
          [101.3847421, 4.7336649],
          [101.3846677, 4.7347235],
          [101.3845396, 4.7351257],
          [101.3842265, 4.7357225],
          [101.3837748, 4.7364337],
          [101.3834265, 4.7371685],
          [101.3831932, 4.7379038],
          [101.3829489, 4.7385126],
          [101.3827742, 4.7389835],
          [101.3826453, 4.7395355],
          [101.3822512, 4.7402008],
          [101.3816179, 4.7405657],
          [101.3807776, 4.7409409],
          [101.3800519, 4.7414089],
          [101.3796822, 4.7418559],
          [101.3795866, 4.7425689],
          [101.3796502, 4.7435708],
          [101.3798865, 4.7445389],
          [101.3801232, 4.7453805],
          [101.3803611, 4.746038],
          [101.3803583, 4.7465905],
          [101.3802531, 4.7469582],
          [101.3801361, 4.7474065],
          [101.3799384, 4.7478773],
          [101.3796823, 4.7485436],
          [101.3795305, 4.7490147],
          [101.3794941, 4.7493943],
          [101.3794221, 4.7500271],
          [101.3793972, 4.7504183],
          [101.3791647, 4.7509465],
          [101.378737, 4.7514852],
          [101.3783556, 4.7519435],
          [101.3779515, 4.7524247],
          [101.3776504, 4.7528605],
          [101.3772444, 4.7536755],
          [101.377138, 4.754285],
          [101.3769166, 4.7548939],
          [101.3767742, 4.7557909],
          [101.376737, 4.7563317],
          [101.3765845, 4.7569639],
          [101.3763634, 4.7575267],
          [101.3761314, 4.758009],
          [101.3756918, 4.7586281],
          [101.3753459, 4.7588794],
          [101.3747573, 4.7594977],
          [101.3743078, 4.7597945],
          [101.3739379, 4.7602529],
          [101.3736702, 4.7609536],
          [101.3741176, 4.7610366],
          [101.3748173, 4.7612017],
          [101.3755177, 4.7611941],
          [101.3762194, 4.7609792],
          [101.3770249, 4.7606729],
          [101.3778744, 4.7607005],
          [101.3787591, 4.7606133],
          [101.3794251, 4.760663],
          [101.3803318, 4.7607717],
          [101.3809164, 4.760959],
          [101.3814433, 4.7612382],
          [101.3821983, 4.7618063],
          [101.3824832, 4.7622223],
          [101.3829635, 4.7625933],
          [101.3834802, 4.7626191],
          [101.3841233, 4.7626227],
          [101.3846403, 4.7625565],
          [101.3853063, 4.7625831],
          [101.3858787, 4.7629315],
          [101.3861985, 4.7632671],
          [101.386587, 4.7636836],
          [101.387136, 4.7641125],
          [101.3875032, 4.7641952],
          [101.387791, 4.7639896],
          [101.388493, 4.7637632],
          [101.3892291, 4.7634909],
          [101.390367, 4.7633245],
          [101.3911142, 4.763133],
          [101.3920573, 4.7628618],
          [101.3929415, 4.7628667],
          [101.3936083, 4.7627553],
          [101.3943321, 4.7626902],
          [101.3949408, 4.7626705],
          [101.3955381, 4.7626162],
          [101.3963188, 4.762701],
          [101.3969149, 4.7628885],
          [101.3974181, 4.7632596],
          [101.39792, 4.7639415],
          [101.3979501, 4.7647934],
          [101.3979931, 4.7653577],
          [101.3976915, 4.7660122],
          [101.3972404, 4.7666198],
          [101.3969602, 4.7675275],
          [101.3969685, 4.7681147],
          [101.3970336, 4.7688747],
          [101.396951, 4.7693463],
          [101.3966264, 4.76992],
          [101.3963023, 4.7704247],
          [101.3960706, 4.7708263],
          [101.3958039, 4.7712853],
          [101.3956516, 4.7719176],
          [101.3957504, 4.772793],
          [101.3959761, 4.7736114],
          [101.3961559, 4.7743721],
          [101.3966112, 4.7751343],
          [101.3972389, 4.775932],
          [101.3979334, 4.7771214],
          [101.398686, 4.7781615],
          [101.399369, 4.7793163],
          [101.3999505, 4.7801483],
          [101.4007259, 4.7812461],
          [101.4012943, 4.7823888],
          [101.401645, 4.7834152],
          [101.4018256, 4.7840608],
          [101.4021789, 4.7845922],
          [101.4025901, 4.7849972],
          [101.4030936, 4.7853569],
          [101.4034936, 4.7857505],
          [101.4040075, 4.7863173],
          [101.404738, 4.7872192],
          [101.4053301, 4.7881778],
          [101.4056606, 4.788686],
          [101.405899, 4.7892169],
          [101.4058376, 4.7899992],
          [101.4058357, 4.7903561],
          [101.4057866, 4.7909773],
          [101.4057716, 4.7916565],
          [101.4057455, 4.7923239],
          [101.4060625, 4.7932235],
          [101.4064032, 4.7939506],
          [101.406804, 4.7941599],
          [101.4075709, 4.7946821],
          [101.4082464, 4.7950772],
          [101.4090473, 4.7956686],
          [101.4097568, 4.796179],
          [101.409928, 4.7963756],
          [101.4099727, 4.796629],
          [101.4099462, 4.797308],
          [101.4100003, 4.7980105],
          [101.4102375, 4.7987255],
          [101.4106248, 4.7993722],
          [101.4110814, 4.7999617],
          [101.411353, 4.8007115],
          [101.4114996, 4.8012418],
          [101.4115889, 4.8017833],
          [101.4121349, 4.8027647],
          [101.4126811, 4.8037691],
          [101.4130226, 4.8043695],
          [101.4135357, 4.805132],
          [101.4136582, 4.8058809],
          [101.4139409, 4.8067572],
          [101.414246, 4.8077259],
          [101.4145172, 4.8085676],
          [101.4145931, 4.8094774],
          [101.4146132, 4.810007],
          [101.4149414, 4.8109642],
          [101.4150168, 4.811989],
          [101.4148638, 4.8126904],
          [101.4144586, 4.8133558],
          [101.4139506, 4.8138711],
          [101.4136391, 4.8141111],
          [101.4129473, 4.8146828],
          [101.4122567, 4.8149438],
          [101.4115657, 4.8153544],
          [101.4112075, 4.8157668],
          [101.4108035, 4.816179],
          [101.410422, 4.8166604],
          [101.4102362, 4.8170392],
          [101.4102437, 4.8178796],
          [101.4101603, 4.8184661],
          [101.4098136, 4.8188901],
          [101.4095807, 4.819522],
          [101.4095965, 4.8209264],
          [101.4094213, 4.821478],
          [101.4092348, 4.822018],
          [101.4086331, 4.822878],
          [101.4081705, 4.8235661],
          [101.4074981, 4.824748],
          [101.4072435, 4.8251265],
          [101.4069647, 4.8257926],
          [101.4066622, 4.8265507],
          [101.4062562, 4.8273426],
          [101.4057028, 4.8277309],
          [101.4051252, 4.8284414],
          [101.4043401, 4.8292084],
          [101.4037281, 4.8298612],
          [101.4033008, 4.8302962],
          [101.4029303, 4.8308698],
          [101.4026062, 4.8313744],
          [101.4019253, 4.8320383],
          [101.4014636, 4.8324617],
          [101.4009564, 4.8328733],
          [101.4001041, 4.8333291],
          [101.3995166, 4.8336597],
          [101.3994122, 4.8339123],
          [101.3998344, 4.8343866],
          [101.4003479, 4.8350801],
          [101.4008258, 4.835946],
          [101.4011661, 4.8367882],
          [101.4016215, 4.8375158],
          [101.402216, 4.8380946],
          [101.4026952, 4.8386958],
          [101.4030374, 4.8391811],
          [101.4032525, 4.8397463],
          [101.4031343, 4.8404593],
          [101.402971, 4.8409305],
          [101.4027729, 4.8415049],
          [101.4024369, 4.8420556],
          [101.4022048, 4.8425031],
          [101.4025108, 4.8433222],
          [101.402957, 4.8436469],
          [101.4032657, 4.8439594],
          [101.404045, 4.8442514],
          [101.4047221, 4.8443702],
          [101.4057215, 4.8443413],
          [101.4064565, 4.8443337],
          [101.4073862, 4.8444424],
          [101.408099, 4.8443427],
          [101.408811, 4.8443121],
          [101.4095696, 4.8442011],
          [101.4102251, 4.8440781],
          [101.4108794, 4.8441047],
          [101.4113379, 4.8443374],
          [101.4118756, 4.8446856],
          [101.4121377, 4.8451245],
          [101.4121918, 4.8457924],
          [101.412074, 4.8463673],
          [101.4117948, 4.847045],
          [101.411505, 4.8475729],
          [101.4110994, 4.8482843],
          [101.410682, 4.8490303],
          [101.4103463, 4.8495695],
          [101.4100102, 4.8501546],
          [101.409928, 4.8505225],
          [101.4102232, 4.8512148],
          [101.4104608, 4.8519067],
          [101.4104003, 4.852528],
          [101.4102244, 4.8532292],
          [101.4100595, 4.854011],
          [101.4103536, 4.8549104],
          [101.4106833, 4.8555339],
          [101.4115194, 4.8560104],
          [101.4122417, 4.8562561],
          [101.4128955, 4.8564668],
          [101.4134814, 4.8564355],
          [101.4140689, 4.8560589],
          [101.4150837, 4.8552472],
          [101.4157186, 4.854652],
          [101.4163303, 4.8540454],
          [101.4171838, 4.8533133],
          [101.4182417, 4.8531119],
          [101.4190569, 4.8531393],
          [101.419802, 4.8534427],
          [101.4205576, 4.8538958],
          [101.4211632, 4.8545092],
          [101.4217368, 4.8546619],
          [101.42221, 4.8541811],
          [101.4227651, 4.8534359],
          [101.4232173, 4.852621],
          [101.4237479, 4.8521866],
          [101.4244595, 4.8522595],
          [101.4252371, 4.8528968],
          [101.4260941, 4.8537878],
          [101.4267441, 4.8547008],
          [101.4273833, 4.855487],
          [101.4282622, 4.8565508],
          [101.4287994, 4.8571063],
          [101.4293505, 4.8571092],
          [101.4299241, 4.8572274],
          [101.4303471, 4.8576326],
          [101.4305736, 4.858267],
          [101.4310413, 4.8589142],
          [101.4314521, 4.8593999],
          [101.4319004, 4.8593677],
          [101.4325337, 4.8590374],
          [101.4330855, 4.8589598],
          [101.4333718, 4.859111],
          [101.4336913, 4.8595157],
          [101.4340221, 4.8599548],
          [101.4346642, 4.8601655],
          [101.4355485, 4.8602164],
          [101.4366745, 4.8600959],
          [101.4374117, 4.8596855],
          [101.4379537, 4.8592625],
          [101.4386345, 4.8586332],
          [101.439016, 4.8581403],
          [101.4394315, 4.8577626],
          [101.4399264, 4.8575351],
          [101.4406736, 4.8573895],
          [101.441409, 4.857313],
          [101.4420743, 4.8575238],
          [101.442544, 4.8577566],
          [101.4435307, 4.8579461],
          [101.4444369, 4.8581466],
          [101.4450342, 4.8581845],
          [101.4456307, 4.8583373],
          [101.4460411, 4.8589381],
          [101.4458541, 4.8595357],
          [101.4454378, 4.8601435],
          [101.4449753, 4.860728],
          [101.4445701, 4.8613359],
          [101.4443495, 4.8618642],
          [101.4438285, 4.8626326],
          [101.4432869, 4.863021],
          [101.44248, 4.8636152],
          [101.4418451, 4.8642103],
          [101.4412106, 4.8647595],
          [101.440646, 4.8651708],
          [101.4398277, 4.8657189],
          [101.4390912, 4.8659796],
          [101.4382743, 4.866286],
          [101.4376873, 4.86649],
          [101.4372492, 4.8668328],
          [101.4369705, 4.867453],
          [101.4369338, 4.8678787],
          [101.4373678, 4.868376],
          [101.4382151, 4.868841],
          [101.4385574, 4.8693378],
          [101.4387495, 4.869972],
          [101.4386539, 4.8706621],
          [101.4381467, 4.8710162],
          [101.4373869, 4.8713805],
          [101.4370168, 4.8719309],
          [101.4369322, 4.8727132],
          [101.4370314, 4.8735425],
          [101.4366724, 4.8741507],
          [101.4362794, 4.8746435],
          [101.4364028, 4.8752313],
          [101.43664, 4.8760038],
          [101.436901, 4.8766383],
          [101.437046, 4.8774678],
          [101.4369393, 4.8781579],
          [101.4367646, 4.8786174],
          [101.4365894, 4.879169],
          [101.4365535, 4.879445],
          [101.4368397, 4.8796538],
          [101.4373347, 4.8794262],
          [101.4381644, 4.8789127],
          [101.4387974, 4.8786285],
          [101.4394186, 4.8784362],
          [101.4400392, 4.8783705],
          [101.4406927, 4.8785926],
          [101.4411387, 4.878975],
          [101.4417234, 4.8792084],
          [101.4424579, 4.8793505],
          [101.4429177, 4.8792494],
          [101.4435285, 4.8788613],
          [101.4442995, 4.8785547],
          [101.4452194, 4.8783641],
          [101.4456203, 4.8785619],
          [101.4458248, 4.8789889],
          [101.4458112, 4.8794493],
          [101.4459351, 4.8798989],
          [101.4458524, 4.8803934],
          [101.4460145, 4.8821167],
          [101.4460334, 4.883509],
          [101.4462838, 4.8842345],
          [101.4468329, 4.8849149],
          [101.4477958, 4.8854237],
          [101.4482997, 4.8858968],
          [101.448699, 4.8867033],
          [101.4492578, 4.8879475],
          [101.4498872, 4.888697],
          [101.450758, 4.8892517],
          [101.4518011, 4.8898413],
          [101.4525227, 4.8904876],
          [101.4530147, 4.8910297],
          [101.4537124, 4.8920096],
          [101.4540084, 4.8928388],
          [101.4539936, 4.8939088],
          [101.453728, 4.8944719],
          [101.453289, 4.8954258],
          [101.4529544, 4.8960232],
          [101.4524811, 4.8968848],
          [101.4519958, 4.897873],
          [101.4516485, 4.8988731],
          [101.4514043, 4.8999425],
          [101.4511949, 4.9008969],
          [101.4510888, 4.9018401],
          [101.4511556, 4.9026227],
          [101.4515325, 4.9032565],
          [101.452644, 4.9039729],
          [101.4534579, 4.9044008],
          [101.4541227, 4.9048054],
          [101.4541083, 4.9058178],
          [101.4539668, 4.9070601],
          [101.4543646, 4.9084764],
          [101.4548092, 4.9095592],
          [101.4551392, 4.910561],
          [101.4551707, 4.9115392],
          [101.4548461, 4.9126658],
          [101.4547624, 4.9138162],
          [101.4545862, 4.9151389],
          [101.4545487, 4.9162089],
          [101.454327, 4.9174395],
          [101.4543245, 4.9183369],
          [101.4542184, 4.9192341],
          [101.4539169, 4.9202573],
          [101.4537306, 4.9211313],
          [101.4539239, 4.9218106],
          [101.4542545, 4.9225249],
          [101.4541029, 4.9233989],
          [101.4538248, 4.9242957],
          [101.4534442, 4.9248009],
          [101.4529154, 4.9250526],
          [101.4524558, 4.9251434],
          [101.4520782, 4.9252265],
          [101.4519016, 4.9262465],
          [101.4514625, 4.9272348],
          [101.4508501, 4.9284758],
          [101.45056, 4.9295565],
          [101.4502466, 4.9306833],
          [101.4499694, 4.9312924],
          [101.4491073, 4.9315777],
          [101.4486009, 4.9320021],
          [101.4485276, 4.9334861],
          [101.4485804, 4.9351316],
          [101.448141, 4.9360854],
          [101.4473246, 4.9364974],
          [101.4467718, 4.9370942],
          [101.4458503, 4.9380927],
          [101.4453203, 4.9387702],
          [101.4449849, 4.9396092],
          [101.4447069, 4.9403908],
          [101.4445549, 4.9413108],
          [101.4442648, 4.9423687],
          [101.4441711, 4.9430242],
          [101.4441926, 4.943473],
          [101.4445819, 4.9438077],
          [101.4453275, 4.9440859],
          [101.4460392, 4.9441684],
          [101.4464399, 4.9446412],
          [101.44692, 4.9453213],
          [101.4473436, 4.9457827],
          [101.4479966, 4.9462217],
          [101.4485464, 4.946672],
          [101.4489118, 4.9473519],
          [101.449013, 4.9480884],
          [101.449011, 4.9487443],
          [101.4488474, 4.949791],
          [101.4486029, 4.9508718],
          [101.4484972, 4.951677],
          [101.4485629, 4.9528277],
          [101.4486182, 4.9535528],
          [101.448077, 4.9540345],
          [101.4472944, 4.9546997],
          [101.4465463, 4.955319],
          [101.4459248, 4.9557546],
          [101.445567, 4.9563979],
          [101.4456338, 4.9571229],
          [101.4459653, 4.9575956],
          [101.445906, 4.9582513],
          [101.445732, 4.9588261],
          [101.4453632, 4.9592968],
          [101.4447651, 4.9596059],
          [101.4440064, 4.959972],
          [101.4430175, 4.9604411],
          [101.4424428, 4.9606812],
          [101.4414665, 4.9607475],
          [101.4404676, 4.9607448],
          [101.4399272, 4.9609734],
          [101.4390076, 4.9613046],
          [101.438283, 4.9616477],
          [101.4377083, 4.9618878],
          [101.4371674, 4.9623697],
          [101.4366371, 4.96307],
          [101.436336, 4.9639437],
          [101.4362528, 4.9649214],
          [101.4358487, 4.9655992],
          [101.4352964, 4.9659659],
          [101.4346404, 4.9665048],
          [101.4340307, 4.9669289],
          [101.4335702, 4.9673303],
          [101.4329939, 4.9680881],
          [101.432027, 4.9688793],
          [101.4310945, 4.9696822],
          [101.4303582, 4.9701289],
          [101.4294726, 4.9706328],
          [101.4285983, 4.9711711],
          [101.4278855, 4.9714338],
          [101.4271268, 4.9717539],
          [101.4263563, 4.9721429],
          [101.4257123, 4.9725094],
          [101.4255951, 4.9732914],
          [101.425777, 4.9739593],
          [101.4259236, 4.9747536],
          [101.4260363, 4.9754903],
          [101.4262869, 4.9761468],
          [101.4264568, 4.9770217],
          [101.4265929, 4.9775859],
          [101.426098, 4.9779527],
          [101.425465, 4.9783997],
          [101.4249929, 4.9788587],
          [101.4246116, 4.979686],
          [101.4242421, 4.9803638],
          [101.4243553, 4.9808934],
          [101.4244799, 4.981446],
          [101.4242836, 4.9818482],
          [101.4239142, 4.982457],
          [101.4236714, 4.9830546],
          [101.4235996, 4.9840094],
          [101.4241733, 4.9841606],
          [101.424896, 4.9844618],
          [101.4255944, 4.9850735],
          [101.4262474, 4.9855816],
          [101.427175, 4.98647],
          [101.4279655, 4.987105],
          [101.4286409, 4.9877513],
          [101.4293282, 4.9883284],
          [101.4299917, 4.9891356],
          [101.4305749, 4.9899542],
          [101.4312499, 4.9907615],
          [101.4318794, 4.9914995],
          [101.432326, 4.991892],
          [101.432646, 4.9924337],
          [101.4329541, 4.9930213],
          [101.4336767, 4.9933915],
          [101.4343078, 4.9935428],
          [101.4349738, 4.993464],
          [101.43556, 4.9932701],
          [101.4363759, 4.9931572],
          [101.4372247, 4.9934703],
          [101.4380617, 4.9938407],
          [101.4386354, 4.9940149],
          [101.4395526, 4.9944777],
          [101.4399759, 4.994962],
          [101.4403537, 4.9953887],
          [101.4406852, 4.9958499],
          [101.4409827, 4.996265],
          [101.4415215, 4.9965311],
          [101.4422344, 4.9961879],
          [101.4427867, 4.9958211],
          [101.4434765, 4.9955354],
          [101.4445567, 4.9952507],
          [101.4451788, 4.9945851],
          [101.4458551, 4.9950241],
          [101.4467724, 4.9954523],
          [101.4476316, 4.9961681],
          [101.4482034, 4.9969751],
          [101.4482813, 4.9977462],
          [101.4480838, 4.9985855],
          [101.4480933, 4.9992759],
          [101.4482972, 5.0002199],
          [101.4486851, 5.000992],
          [101.4489821, 5.0015565],
          [101.4491984, 5.0022705],
          [101.4494836, 5.0028581],
          [101.4502287, 5.0033548],
          [101.4511226, 5.0038175],
          [101.4520168, 5.0043722],
          [101.4525208, 5.0047878],
          [101.4527147, 5.0052601],
          [101.4529879, 5.0060662],
          [101.4528946, 5.0066067],
          [101.4526629, 5.0072275],
          [101.4523384, 5.0082621],
          [101.4518658, 5.0088822],
          [101.4513822, 5.0093641],
          [101.4509213, 5.0098576],
          [101.4503804, 5.0102358],
          [101.4496442, 5.0106709],
          [101.4490221, 5.0113827],
          [101.4485152, 5.0119451],
          [101.4481343, 5.0125769],
          [101.4479488, 5.0132553],
          [101.4478203, 5.0139568],
          [101.4483362, 5.0141998],
          [101.4490935, 5.0143974],
          [101.4499414, 5.015021],
          [101.4506622, 5.0158744],
          [101.4513147, 5.0165781],
          [101.451657, 5.017419],
          [101.4520679, 5.0182485],
          [101.4522723, 5.0189855],
          [101.4530729, 5.0200693],
          [101.454195, 5.0211768],
          [101.4549393, 5.0219152],
          [101.456153, 5.0231726],
          [101.4563813, 5.023622],
          [101.4568494, 5.0245552],
          [101.4569272, 5.0253954],
          [101.4569821, 5.0262585],
          [101.457175, 5.027007],
          [101.4576664, 5.0278942],
          [101.4582264, 5.0287702],
          [101.4587628, 5.0298647],
          [101.4590025, 5.0304406],
          [101.4591959, 5.0310279],
          [101.4594013, 5.0314428],
          [101.4599517, 5.0317089],
          [101.4604561, 5.0320325],
          [101.4607886, 5.0322059],
          [101.4608904, 5.032747],
          [101.4605902, 5.0333099],
          [101.4604159, 5.0339768],
          [101.4602308, 5.0345171],
          [101.4599298, 5.0352757],
          [101.4595944, 5.0360802],
          [101.4591441, 5.036965],
          [101.4589582, 5.0376778],
          [101.4589792, 5.0383567],
          [101.4591034, 5.0390474],
          [101.4591013, 5.0397953],
          [101.4592713, 5.0405666],
          [101.4593272, 5.0410616],
          [101.4594973, 5.0417753],
          [101.4598967, 5.0426163],
          [101.4601931, 5.0433996],
          [101.4606618, 5.0441488],
          [101.4608781, 5.0447821],
          [101.460773, 5.0453342],
          [101.4606448, 5.0460242],
          [101.4603444, 5.0465986],
          [101.4601124, 5.0474495],
          [101.4599729, 5.0480359],
          [101.4597646, 5.0485071],
          [101.4597511, 5.0492204],
          [101.459979, 5.0498194],
          [101.4605164, 5.0505457],
          [101.4609625, 5.0512718],
          [101.4613962, 5.0521129],
          [101.4617617, 5.0527468],
          [101.4622078, 5.0534382],
          [101.4625049, 5.0539799],
          [101.4629506, 5.0546714],
          [101.463155, 5.0554314],
          [101.4630847, 5.0559259],
          [101.4631286, 5.0566049],
          [101.4634143, 5.0570659],
          [101.4641118, 5.0580688],
          [101.464374, 5.0587253],
          [101.4645105, 5.059232],
          [101.4646806, 5.0599804],
          [101.4646671, 5.0606706],
          [101.4644588, 5.0611879],
          [101.4641009, 5.0617852],
          [101.4638357, 5.0621412],
          [101.4635705, 5.0625662],
          [101.4631213, 5.0630022],
          [101.4628208, 5.0635997],
          [101.4628308, 5.0641174],
          [101.4628288, 5.0648423],
          [101.4625859, 5.0654284],
          [101.4626529, 5.0660384],
          [101.4628571, 5.066971],
          [101.4631877, 5.0677888],
          [101.4633239, 5.0683185],
          [101.4634125, 5.0694693],
          [101.4638349, 5.0702529],
          [101.4640976, 5.0707253],
          [101.4645677, 5.0710603],
          [101.4651401, 5.0716141],
          [101.4655638, 5.0720524],
          [101.466136, 5.0727444],
          [101.4662259, 5.0733774],
          [101.466178, 5.0740447],
          [101.4660728, 5.0747463],
          [101.4662657, 5.0755752],
          [101.4666088, 5.0759904],
          [101.4670894, 5.07659],
          [101.4673759, 5.0768439],
          [101.4683737, 5.0772033],
          [101.4691877, 5.0776541],
          [101.4701169, 5.0781055],
          [101.4707242, 5.0785213],
          [101.4713657, 5.0790869],
          [101.4721569, 5.0795262],
          [101.4729943, 5.0798852],
          [101.4736371, 5.0798984],
          [101.4744416, 5.0797855],
          [101.4752225, 5.0797186],
          [101.4759917, 5.0798242],
          [101.4767255, 5.0802059],
          [101.4771607, 5.0806098],
          [101.47771, 5.0813592],
          [101.478408, 5.082247],
          [101.4790831, 5.0830773],
          [101.4794027, 5.0836994],
          [101.4798144, 5.0843564],
          [101.4804327, 5.0849218],
          [101.4810857, 5.085522],
          [101.4815439, 5.0859489],
          [101.4821622, 5.0865373],
          [101.482769, 5.0871718],
          [101.4832156, 5.0876218],
          [101.4836165, 5.0880026],
          [101.4841778, 5.0885218],
          [101.4846359, 5.0889717],
          [101.4852657, 5.0895833],
          [101.4855628, 5.0901019],
          [101.4853888, 5.0906882],
          [101.4849841, 5.0916076],
          [101.4844995, 5.0924117],
          [101.4840369, 5.093515],
          [101.4837364, 5.094193],
          [101.4832743, 5.0950663],
          [101.4827095, 5.0957781],
          [101.4820754, 5.0965819],
          [101.4815565, 5.0973053],
          [101.4808306, 5.0981547],
          [101.4799198, 5.0993144],
          [101.4793325, 5.0999112],
          [101.478871, 5.1006003],
          [101.4787773, 5.1011983],
          [101.4790165, 5.1019354],
          [101.4794499, 5.1029261],
          [101.4798033, 5.1038935],
          [101.4797898, 5.1046068],
          [101.4796846, 5.1052164],
          [101.4796712, 5.1058722],
          [101.4795766, 5.1068039],
          [101.4791159, 5.1072399],
          [101.4784135, 5.1078823],
          [101.4778487, 5.1085711],
          [101.4776174, 5.1090883],
          [101.4776029, 5.1101238],
          [101.4777849, 5.1107226],
          [101.4783678, 5.1116332],
          [101.4788374, 5.1121062],
          [101.4794551, 5.1129939],
          [101.4799119, 5.1138234],
          [101.4806902, 5.1147345],
          [101.481262, 5.1156566],
          [101.4819361, 5.116809],
          [101.4825075, 5.1178231],
          [101.4826551, 5.1183873],
          [101.4832953, 5.1193784],
          [101.4837526, 5.1200701],
          [101.4842674, 5.1208424],
          [101.4845517, 5.1217981],
          [101.4846534, 5.1223967],
          [101.4848115, 5.1233061],
          [101.4849467, 5.1241464],
          [101.485002, 5.1249175],
          [101.4848166, 5.1254692],
          [101.4848376, 5.1261596],
          [101.485042, 5.1269426],
          [101.4850511, 5.127725],
          [101.4850955, 5.1282659],
          [101.4850362, 5.1288756],
          [101.4848626, 5.1294044],
          [101.4846887, 5.1299562],
          [101.4848711, 5.1304745],
          [101.4853059, 5.1310049],
          [101.485648, 5.1317767],
          [101.4857611, 5.1324675],
          [101.4859432, 5.1329396],
          [101.4861492, 5.1332163],
          [101.4864014, 5.1333781],
          [101.4867458, 5.1334941],
          [101.4869404, 5.1336442],
          [101.4868935, 5.1340008],
          [101.4868233, 5.1344378],
          [101.4865685, 5.1351505],
          [101.4862804, 5.1355064],
          [101.4856133, 5.1358037],
          [101.4852099, 5.1362974],
          [101.4850246, 5.1367917],
          [101.4849542, 5.1373322],
          [101.4845046, 5.1379179],
          [101.4839511, 5.1386758],
          [101.4835817, 5.1393536],
          [101.4832586, 5.1398245],
          [101.48312, 5.1401118],
          [101.4826469, 5.1408354],
          [101.4821514, 5.1414093],
          [101.4816779, 5.1422134],
          [101.4811708, 5.1428679],
          [101.4809041, 5.1436841],
          [101.4807416, 5.1443049],
          [101.4809461, 5.1450419],
          [101.4814496, 5.1456876],
          [101.4816315, 5.146367],
          [101.4817671, 5.1471497],
          [101.4815697, 5.1479086],
          [101.4813265, 5.1485638],
          [101.4815658, 5.1492318],
          [101.4817588, 5.1499802],
          [101.4819523, 5.150556],
          [101.4820425, 5.1511661],
          [101.4821217, 5.151569],
          [101.4821459, 5.1519184],
          [101.4826011, 5.1524969],
          [101.4829158, 5.1534422],
          [101.4831061, 5.1540761],
          [101.4837069, 5.1551847],
          [101.4848623, 5.1558493],
          [101.4855828, 5.1563263],
          [101.4865879, 5.1571391],
          [101.4875214, 5.1582733],
          [101.4881494, 5.1588187],
          [101.4888778, 5.1597443],
          [101.4896811, 5.1613719],
          [101.4895832, 5.1621185],
          [101.488749, 5.1630318],
          [101.4873749, 5.1638604],
          [101.4867282, 5.1642232],
          [101.4865385, 5.1649921],
          [101.4856827, 5.1671471],
          [101.4856622, 5.1683312],
          [101.4863275, 5.1699693],
          [101.4861618, 5.1705888],
          [101.4858592, 5.171104],
          [101.4852737, 5.1724791],
          [101.4854357, 5.1738372],
          [101.4860448, 5.1738419],
          [101.486736, 5.173606],
          [101.4893311, 5.1724421],
          [101.4901234, 5.1725173],
          [101.4914545, 5.174287],
          [101.4923345, 5.1749379],
          [101.4928625, 5.1765173],
          [101.4929349, 5.1775757],
          [101.4928843, 5.1781503],
          [101.492557, 5.1788606],
          [101.4913517, 5.1801619],
          [101.491266, 5.1808282],
          [101.491561, 5.1813249],
          [101.491956, 5.1822709],
          [101.4924966, 5.183678],
          [101.4929974, 5.1843372],
          [101.4940248, 5.1852307],
          [101.4944076, 5.1862571],
          [101.4947593, 5.1868463],
          [101.4959897, 5.1882588],
          [101.4965636, 5.1883553],
          [101.4989878, 5.1884779],
          [101.4991984, 5.1884775],
          [101.4994378, 5.1887438],
          [101.5000545, 5.1891852],
          [101.5002933, 5.1895205],
          [101.5005087, 5.1899131],
          [101.5014237, 5.1904029],
          [101.5022376, 5.1906272],
          [101.5030371, 5.1911736],
          [101.5034713, 5.1914872],
          [101.5038596, 5.1917661],
          [101.5047713, 5.1927387],
          [101.5055002, 5.1935721],
          [101.5058285, 5.1942531],
          [101.5060755, 5.1950139],
          [101.5061511, 5.1956701],
          [101.5062951, 5.1964187],
          [101.5065203, 5.1970414],
          [101.5069422, 5.19747],
          [101.5076274, 5.1979696],
          [101.5080164, 5.1981563],
          [101.5084283, 5.1983894],
          [101.5090802, 5.1987506],
          [101.5096625, 5.1992149],
          [101.5100475, 5.1999767],
          [101.5101783, 5.2009438],
          [101.5102553, 5.2014274],
          [101.5099885, 5.2018166],
          [101.5097217, 5.2021596],
          [101.5093968, 5.2026288],
          [101.5089905, 5.203247],
          [101.5086852, 5.2041763],
          [101.5090244, 5.2049378],
          [101.5094438, 5.2056885],
          [101.5097725, 5.2063004],
          [101.5107355, 5.2081016],
          [101.5110065, 5.2087476],
          [101.5112538, 5.2094739],
          [101.5114567, 5.2100391],
          [101.5116122, 5.2107762],
          [101.5119055, 5.2114915],
          [101.512269, 5.2120576],
          [101.512724, 5.2126475],
          [101.5132471, 5.2133413],
          [101.5138279, 5.2140356],
          [101.5144223, 5.2144194],
          [101.5149928, 5.2149412],
          [101.5151147, 5.2155515],
          [101.5149493, 5.2161944],
          [101.5147018, 5.2170552],
          [101.5144676, 5.2176977],
          [101.5141039, 5.2187876],
          [101.5136628, 5.21944],
          [101.5130492, 5.2201257],
          [101.5127454, 5.220871],
          [101.512167, 5.2214765],
          [101.5119679, 5.2220155],
          [101.5113899, 5.2225289],
          [101.5109387, 5.2229972],
          [101.5108189, 5.2237324],
          [101.5108127, 5.224595],
          [101.5107614, 5.2253077],
          [101.5109298, 5.225838],
          [101.5108437, 5.2266655],
          [101.5107927, 5.2273667],
          [101.5107291, 5.2282173],
          [101.5106545, 5.2290103],
          [101.5104869, 5.2299522],
          [101.5103456, 5.2304688],
          [101.5099637, 5.23088],
          [101.5094326, 5.2312557],
          [101.5092113, 5.2317027],
          [101.509449, 5.232176],
          [101.5098924, 5.2328117],
          [101.5103245, 5.2333899],
          [101.5107225, 5.2339449],
          [101.5115908, 5.2345722],
          [101.5124945, 5.2350388],
          [101.5130549, 5.2353534],
          [101.5133525, 5.235482],
          [101.5138422, 5.2360952],
          [101.5140451, 5.2366142],
          [101.5143384, 5.2373524],
          [101.5147938, 5.2378848],
          [101.5152727, 5.2383828],
          [101.5158443, 5.2387089],
          [101.5163248, 5.238977],
          [101.5168412, 5.2390036],
          [101.517758, 5.2392748],
          [101.518236, 5.2398878],
          [101.5189785, 5.2404337],
          [101.5195501, 5.2407944],
          [101.5202131, 5.2412247],
          [101.5206673, 5.2419181],
          [101.5210757, 5.2426457],
          [101.5217125, 5.2435129],
          [101.5220176, 5.2442051],
          [101.5221032, 5.2450683],
          [101.522134, 5.2455746],
          [101.5221511, 5.2463913],
          [101.5221812, 5.2470356],
          [101.5223945, 5.2477042],
          [101.5227917, 5.2483512],
          [101.5232346, 5.2490445],
          [101.5233231, 5.2495281],
          [101.5233535, 5.2500804],
          [101.5230612, 5.2508374],
          [101.5227563, 5.2516748],
          [101.52235, 5.252316],
          [101.5216801, 5.2528286],
          [101.5212562, 5.252722],
          [101.5208658, 5.2526847],
          [101.5204067, 5.2526814],
          [101.5200962, 5.2527482],
          [101.519659, 5.2528601],
          [101.5194959, 5.2531809],
          [101.5192746, 5.2535933],
          [101.5194564, 5.2538822],
          [101.5196958, 5.2541254],
          [101.5197826, 5.2548277],
          [101.5200321, 5.2552435],
          [101.520362, 5.2556944],
          [101.5206656, 5.2565823],
          [101.5209478, 5.2572743],
          [101.5209686, 5.2575851],
          [101.5210585, 5.2578848],
          [101.5210438, 5.2583101],
          [101.5211789, 5.2586907],
          [101.521176, 5.2591162],
          [101.5212534, 5.2595192],
          [101.5214334, 5.2600611],
          [101.5214065, 5.2606016],
          [101.5214921, 5.2614647],
          [101.5215236, 5.2618676],
          [101.5215311, 5.2624311],
          [101.5215267, 5.2630292],
          [101.5216623, 5.2633406],
          [101.521773, 5.263905],
          [101.5219429, 5.2642628],
          [101.5220078, 5.2647923],
          [101.5219472, 5.2652634],
          [101.5219084, 5.2658497],
          [101.5216046, 5.2666067],
          [101.5214414, 5.2669275],
          [101.5212652, 5.2674897],
          [101.5210784, 5.2679254],
          [101.5208463, 5.2682688],
          [101.5207509, 5.2687512],
          [101.5207465, 5.2693492],
          [101.5209964, 5.2697536],
          [101.5215099, 5.2701828],
          [101.5218638, 5.2705074],
          [101.5223336, 5.2706143],
          [101.522736, 5.2705827],
          [101.5231964, 5.2704136],
          [101.5237515, 5.2699],
          [101.524326, 5.2698582],
          [101.5248769, 5.2698851],
          [101.5253362, 5.2698884],
          [101.5255861, 5.2702812],
          [101.5259738, 5.2706751],
          [101.5263584, 5.2715059],
          [101.5267228, 5.2719111],
          [101.5270412, 5.2723505],
          [101.5274065, 5.2726751],
          [101.5276461, 5.2728839],
          [101.5278975, 5.2730468],
          [101.5283335, 5.2731074],
          [101.5288639, 5.2728122],
          [101.5291992, 5.2724811],
          [101.5295005, 5.2721152],
          [101.5299624, 5.2717504],
          [101.530401, 5.2714432],
          [101.5308636, 5.2710095],
          [101.5311649, 5.2706321],
          [101.5313847, 5.2703806],
          [101.531687, 5.2698882],
          [101.5318972, 5.2694067],
          [101.5321189, 5.2688907],
          [101.5323176, 5.2684091],
          [101.5322864, 5.2679488],
          [101.532162, 5.2676719],
          [101.5319928, 5.2672682],
          [101.5317555, 5.2667143],
          [101.5316776, 5.2663687],
          [101.5321525, 5.2658201],
          [101.5322934, 5.2653726],
          [101.532377, 5.2649247],
          [101.5324835, 5.2644999],
          [101.5328648, 5.2641806],
          [101.5335458, 5.2637024],
          [101.5338933, 5.2632678],
          [101.5342182, 5.2627871],
          [101.5343455, 5.2626385],
          [101.5346794, 5.2625144],
          [101.535094, 5.2623564],
          [101.5356229, 5.2623026],
          [101.536334, 5.2623883],
          [101.5368045, 5.2624492],
          [101.5372527, 5.2623949],
          [101.5376092, 5.2623286],
          [101.538047, 5.2621131],
          [101.5383812, 5.2619775],
          [101.5391493, 5.262144],
          [101.5396184, 5.2624004],
          [101.5399156, 5.2626095],
          [101.5403384, 5.2628887],
          [101.5406823, 5.2629602],
          [101.5410011, 5.263365],
          [101.5413213, 5.2635398],
          [101.5417333, 5.2637269],
          [101.542169, 5.2638679],
          [101.5424666, 5.2639966],
          [101.5429927, 5.2642879],
          [101.5434961, 5.2645561],
          [101.5440839, 5.2642613],
          [101.5444295, 5.2641027],
          [101.5448463, 5.2636227],
          [101.5451357, 5.2632912],
          [101.5452633, 5.2631426],
          [101.5454717, 5.2629141],
          [101.5458056, 5.2627669],
          [101.5462072, 5.2628043],
          [101.5464001, 5.2631278],
          [101.5465005, 5.263554],
          [101.5464854, 5.2640485],
          [101.5465384, 5.2646815],
          [101.5465122, 5.2651298],
          [101.5465208, 5.2655669],
          [101.5466327, 5.2659818],
          [101.5469404, 5.266283],
          [101.5473169, 5.2666308],
          [101.5476568, 5.2672772],
          [101.5477801, 5.2677152],
          [101.5477543, 5.2681175],
          [101.5477173, 5.2684853],
          [101.5476341, 5.2688413],
          [101.5475394, 5.2692547],
          [101.5474902, 5.2697259],
          [101.54743, 5.2701165],
          [101.5471624, 5.2705862],
          [101.5468734, 5.2708486],
          [101.5466521, 5.2713185],
          [101.5464882, 5.2717428],
          [101.5464272, 5.2722371],
          [101.5465846, 5.2726867],
          [101.5469378, 5.2730802],
          [101.5471645, 5.2735075],
          [101.5470461, 5.2740127],
          [101.5467774, 5.2746663],
          [101.5465558, 5.2751593],
          [101.5463915, 5.2756181],
          [101.5464109, 5.2761243],
          [101.5465457, 5.2765624],
          [101.5467963, 5.2768402],
          [101.5469322, 5.2771171],
          [101.5470097, 5.2775089],
          [101.5470071, 5.2778998],
          [101.5468669, 5.2782209],
          [101.5467385, 5.2784959],
          [101.5467019, 5.2788177],
          [101.5468034, 5.279083],
          [101.546869, 5.279555],
          [101.5472789, 5.2800295],
          [101.5476439, 5.2804002],
          [101.5479526, 5.2805634],
          [101.5483865, 5.2809116],
          [101.5488914, 5.2809842],
          [101.5494186, 5.2811375],
          [101.5499246, 5.2810491],
          [101.5502933, 5.2808678],
          [101.5506741, 5.2806174],
          [101.5512472, 5.2807481],
          [101.5514293, 5.281014],
          [101.5515408, 5.2814518],
          [101.5517122, 5.2815796],
          [101.5518467, 5.282052],
          [101.5520052, 5.2823752],
          [101.5520952, 5.2826289],
          [101.5524039, 5.2828151],
          [101.552793, 5.2830364],
          [101.5532294, 5.2830281],
          [101.5537254, 5.2827097],
          [101.5543124, 5.2825297],
          [101.5549436, 5.2826148],
          [101.5554815, 5.2828487],
          [101.5559853, 5.2830594],
          [101.5565462, 5.2833164],
          [101.5571977, 5.2837696],
          [101.5574829, 5.2840248],
          [101.5577553, 5.2844982],
          [101.5581315, 5.284892],
          [101.5584385, 5.2853082],
          [101.5586321, 5.2855511],
          [101.558905, 5.2859327],
          [101.5591194, 5.2864517],
          [101.5591054, 5.2867967],
          [101.5590097, 5.2873365],
          [101.5590523, 5.2878083],
          [101.5593589, 5.2882821],
          [101.559701, 5.2886526],
          [101.5600661, 5.2889774],
          [101.560339, 5.2893473],
          [101.5605197, 5.2898086],
          [101.561115, 5.2900659],
          [101.5616547, 5.2900468],
          [101.562161, 5.289947],
          [101.5627252, 5.2897439],
          [101.5631401, 5.2894939],
          [101.563602, 5.2891636],
          [101.5641923, 5.2885007],
          [101.5647252, 5.2878605],
          [101.5652348, 5.2872545],
          [101.5656175, 5.2867283],
          [101.5659872, 5.2864088],
          [101.5663558, 5.2862736],
          [101.56693, 5.2862546],
          [101.5675715, 5.2864892],
          [101.5681909, 5.2865972],
          [101.568373, 5.2868285],
          [101.5687144, 5.2873025],
          [101.5692882, 5.2873641],
          [101.5695493, 5.287757],
          [101.5696848, 5.2881145],
          [101.569796, 5.2885869],
          [101.5698612, 5.2891164],
          [101.5699379, 5.289669],
          [101.5700495, 5.2900954],
          [101.5703231, 5.2904193],
          [101.5707125, 5.2905371],
          [101.5711034, 5.290471],
          [101.5715625, 5.2905202],
          [101.5720093, 5.2906615],
          [101.5724217, 5.2908023],
          [101.5729137, 5.2910935],
          [101.5732321, 5.2915098],
          [101.5731478, 5.2920842],
          [101.5730506, 5.2928311],
          [101.5728154, 5.2936],
          [101.5726382, 5.2943003],
          [101.5725425, 5.2948517],
          [101.5724008, 5.2953798],
          [101.572064, 5.2959409],
          [101.5718772, 5.2963306],
          [101.5717603, 5.2966634],
          [101.571459, 5.2970292],
          [101.5712489, 5.2974878],
          [101.570693, 5.2981394],
          [101.5704613, 5.2984368],
          [101.5703107, 5.2986082],
          [101.570159, 5.2989522],
          [101.5699145, 5.2994105],
          [101.5696577, 5.3000067],
          [101.5695145, 5.3007878],
          [101.5695443, 5.3014091],
          [101.5698064, 5.301733],
          [101.5701589, 5.3022301],
          [101.5706259, 5.3027625],
          [101.5710816, 5.3032948],
          [101.5712513, 5.3036411],
          [101.5716045, 5.3040461],
          [101.5718196, 5.3044847],
          [101.5720804, 5.3049696],
          [101.5721571, 5.3054763],
          [101.5722923, 5.3058568],
          [101.5722428, 5.3063509],
          [101.5721585, 5.3068794],
          [101.5721101, 5.3072471],
          [101.5721298, 5.3076728],
          [101.5723328, 5.3082033],
          [101.572639, 5.3087921],
          [101.5728994, 5.3093115],
          [101.5728962, 5.3097486],
          [101.5729732, 5.3102322],
          [101.5730614, 5.3107618],
          [101.5729894, 5.3111869],
          [101.5729517, 5.3116467],
          [101.5729488, 5.3120607],
          [101.5728634, 5.3127732],
          [101.572848, 5.3133136],
          [101.5729828, 5.3137516],
          [101.5732314, 5.314294],
          [101.5735165, 5.3146181],
          [101.573652, 5.3149411],
          [101.5737065, 5.3153555],
          [101.5736807, 5.3157349],
          [101.5736197, 5.316252],
          [101.5734666, 5.316757],
          [101.573383, 5.3172164],
          [101.5734253, 5.3177573],
          [101.5735369, 5.3181836],
          [101.5737642, 5.3185188],
          [101.574003, 5.3188771],
          [101.5743108, 5.3191898],
          [101.5745951, 5.3195944],
          [101.5748907, 5.3200105],
          [101.5751514, 5.3205069],
          [101.5752156, 5.321163],
          [101.5751868, 5.3219909],
          [101.5750774, 5.3228642],
          [101.575029, 5.3231744],
          [101.5748432, 5.3234721],
          [101.5747274, 5.3236323],
          [101.5744387, 5.3238373],
          [101.5742199, 5.3239392],
          [101.574069, 5.3241336],
          [101.573918, 5.3243741],
          [101.5738362, 5.3245805],
          [101.5737648, 5.3249251],
          [101.573843, 5.3252592],
          [101.573967, 5.3255821],
          [101.5743436, 5.3258953],
          [101.574709, 5.32622],
          [101.5749357, 5.3266127],
          [101.575186, 5.3269824],
          [101.5754704, 5.327364],
          [101.5756626, 5.3278025],
          [101.5759578, 5.3282762],
          [101.5763559, 5.3287965],
          [101.5767665, 5.3292135],
          [101.5772471, 5.3294584],
          [101.577658, 5.3298294],
          [101.5781153, 5.3301203],
          [101.5785507, 5.3302959],
          [101.579146, 5.3305186],
          [101.5796732, 5.3306949],
          [101.5802008, 5.3307908],
          [101.5808546, 5.3309219],
          [101.581692, 5.3310544],
          [101.5822661, 5.331093],
          [101.5831036, 5.3312369],
          [101.5843104, 5.331096],
          [101.5851596, 5.3311711],
          [101.58601, 5.3311312],
          [101.586724, 5.3308717],
          [101.5871052, 5.3305295],
          [101.5874631, 5.3302675],
          [101.5878903, 5.3299714],
          [101.5884196, 5.3298028],
          [101.5890071, 5.3295998],
          [101.5893521, 5.3294988],
          [101.5899152, 5.3294568],
          [101.5904316, 5.329518],
          [101.5909021, 5.3295789],
          [101.5916692, 5.3299294],
          [101.5922635, 5.3303131],
          [101.592663, 5.3306956],
          [101.5930277, 5.3310777],
          [101.5932092, 5.331401],
          [101.5934362, 5.3317822],
          [101.5937217, 5.3320257],
          [101.5941675, 5.3323279],
          [101.59512, 5.3324268],
          [101.5958205, 5.3324432],
          [101.5966579, 5.3325757],
          [101.5974738, 5.3325355],
          [101.5979332, 5.3325042],
          [101.5985891, 5.3323479],
          [101.5989926, 5.3321438],
          [101.5996489, 5.3318953],
          [101.6002339, 5.332003],
          [101.6003468, 5.3322799],
          [101.6002647, 5.3324978],
          [101.6000327, 5.3328527],
          [101.5998584, 5.333116],
          [101.599626, 5.3335169],
          [101.5994861, 5.3338035],
          [101.5994026, 5.3342284],
          [101.5995263, 5.3345973],
          [101.5998226, 5.334933],
          [101.6001988, 5.3353497],
          [101.6007016, 5.3356983],
          [101.6009863, 5.3360569],
          [101.6012489, 5.3362543],
          [101.6016918, 5.3369706],
          [101.6020548, 5.3376172],
          [101.6022356, 5.338044],
          [101.6026351, 5.3384264],
          [101.6031386, 5.3386485],
          [101.6036669, 5.3386523],
          [101.6040327, 5.3389309],
          [101.6042591, 5.3393696],
          [101.6043455, 5.3401867],
          [101.6041923, 5.3407262],
          [101.6041992, 5.3413588],
          [101.6044015, 5.3420158],
          [101.6046622, 5.3424662],
          [101.6048082, 5.3429619],
          [101.6051486, 5.3435739],
          [101.6053842, 5.3443577],
          [101.6056321, 5.3450379],
          [101.6059039, 5.3455689],
          [101.606382, 5.3461935],
          [101.6067116, 5.3466559],
          [101.6070997, 5.3470037],
          [101.6074192, 5.3473164],
          [101.6078995, 5.3475959],
          [101.608289, 5.3477482],
          [101.6088169, 5.3477864],
          [101.6093226, 5.3477555],
          [101.6097354, 5.3478504],
          [101.6102379, 5.348268],
          [101.6106597, 5.3487196],
          [101.6111417, 5.3487575],
          [101.6115782, 5.3487721],
          [101.6121527, 5.3487072],
          [101.612728, 5.3486077],
          [101.6133029, 5.3484968],
          [101.6140277, 5.3482949],
          [101.6147177, 5.3481963],
          [101.6155427, 5.3484552],
          [101.616195, 5.3487933],
          [101.6168585, 5.3491891],
          [101.6171555, 5.3494212],
          [101.6176013, 5.3497349],
          [101.6179206, 5.3500362],
          [101.6182516, 5.3503491],
          [101.6185485, 5.3505928],
          [101.6189144, 5.3508483],
          [101.619235, 5.3509656],
          [101.6195553, 5.351129],
          [101.6199455, 5.3511777],
          [101.6204161, 5.3512385],
          [101.6208066, 5.3512298],
          [101.6213016, 5.3510838],
          [101.6218664, 5.3507657],
          [101.6223284, 5.3504125],
          [101.6228491, 5.3498871],
          [101.6232765, 5.3495336],
          [101.623704, 5.3491685],
          [101.6241201, 5.3487804],
          [101.6245361, 5.3484153],
          [101.6250202, 5.3482002],
          [101.6254581, 5.3479733],
          [101.6258286, 5.3475389],
          [101.6260745, 5.3468965],
          [101.6262965, 5.346369],
          [101.626426, 5.3458983],
          [101.6267164, 5.3454173],
          [101.6268577, 5.3449123],
          [101.6271951, 5.3443281],
          [101.6275782, 5.3437442],
          [101.6281218, 5.3431959],
          [101.6285034, 5.3428076],
          [101.6288394, 5.342419],
          [101.6293125, 5.3420773],
          [101.6296811, 5.3419188],
          [101.6301764, 5.3417498],
          [101.6305791, 5.3416376],
          [101.631153, 5.3416762],
          [101.6318899, 5.3414284],
          [101.6326944, 5.3413305],
          [101.6335451, 5.341233],
          [101.6341426, 5.3412028],
          [101.6344761, 5.341136],
          [101.6345826, 5.3406767],
          [101.6345987, 5.3400443],
          [101.6350025, 5.3397941],
          [101.6355444, 5.3394643],
          [101.6358553, 5.3393516],
          [101.6362229, 5.3393312],
          [101.6368193, 5.3394733],
          [101.6373351, 5.3396035],
          [101.63792, 5.3397341],
          [101.6383551, 5.3399327],
          [101.6388611, 5.3398328],
          [101.6395644, 5.3394582],
          [101.6401863, 5.3391865],
          [101.640486, 5.3390621],
          [101.6410362, 5.3391925],
          [101.641297, 5.3396659],
          [101.641363, 5.340115],
          [101.6414976, 5.3405875],
          [101.6416899, 5.3409799],
          [101.641918, 5.341223],
          [101.6421339, 5.341558],
          [101.6425334, 5.3419289],
          [101.6428767, 5.3421039],
          [101.6433712, 5.3419808],
          [101.643991, 5.3420426],
          [101.6445637, 5.3422767],
          [101.6452036, 5.3427413],
          [101.6457972, 5.3432745],
          [101.6464367, 5.3437966],
          [101.6468019, 5.3441212],
          [101.6473979, 5.3442864],
          [101.6478807, 5.3442439],
          [101.6484215, 5.3441327],
          [101.6490764, 5.3440567],
          [101.6498356, 5.3439125],
          [101.6505608, 5.3436761],
          [101.6511378, 5.3432891],
          [101.6516004, 5.3428323],
          [101.6522577, 5.3424459],
          [101.6528573, 5.342128],
          [101.6534691, 5.3416837],
          [101.6541032, 5.3413086],
          [101.6544847, 5.3409778],
          [101.6551536, 5.3405915],
          [101.6556047, 5.3401116],
          [101.6558277, 5.3394345],
          [101.6557752, 5.3387096],
          [101.6558129, 5.3382497],
          [101.6559642, 5.3379518],
          [101.6561155, 5.3376998],
          [101.6565541, 5.3373923],
          [101.6570041, 5.3370735],
          [101.6575578, 5.3367208],
          [101.6582367, 5.3365186],
          [101.6588707, 5.3362239],
          [101.6593208, 5.3359166],
          [101.6596217, 5.3355621],
          [101.6599577, 5.3351619],
          [101.6602248, 5.3347383],
          [101.6607096, 5.3343966],
          [101.6611934, 5.33417],
          [101.6616413, 5.3341845],
          [101.6619745, 5.3341409],
          [101.6623314, 5.3340399],
          [101.6630791, 5.3338841],
          [101.6634001, 5.3339784],
          [101.6638244, 5.3340619],
          [101.664353, 5.3339851],
          [101.6646764, 5.3337458],
          [101.6649099, 5.3331953],
          [101.6653273, 5.3326231],
          [101.6659623, 5.3321446],
          [101.6665063, 5.3315733],
          [101.6668426, 5.3310811],
          [101.6670541, 5.3303924],
          [101.6672997, 5.3297501],
          [101.6676604, 5.3290741],
          [101.6679523, 5.328386],
          [101.6681652, 5.3275364],
          [101.6680195, 5.3269833],
          [101.6679312, 5.3264651],
          [101.6682668, 5.3260994],
          [101.6686814, 5.3259297],
          [101.6692326, 5.3259336],
          [101.6700367, 5.3259622],
          [101.6706686, 5.3259321],
          [101.671176, 5.3256136],
          [101.6716587, 5.3255595],
          [101.672276, 5.3259549],
          [101.6727434, 5.3264758],
          [101.6731989, 5.3270425],
          [101.6734385, 5.3272627],
          [101.6738517, 5.3273001],
          [101.6743803, 5.3272462],
          [101.6747927, 5.3274331],
          [101.6751912, 5.3279305],
          [101.675612, 5.328497],
          [101.6758589, 5.3293498],
          [101.6762796, 5.3299509],
          [101.6764149, 5.3303544],
          [101.6766865, 5.3309199],
          [101.6769605, 5.3311518],
          [101.6773712, 5.3315687],
          [101.6776069, 5.3323295],
          [101.6776709, 5.333043],
          [101.677738, 5.3333425],
          [101.6778169, 5.3335387],
          [101.67785, 5.3337114],
          [101.6780673, 5.3338739],
          [101.6784794, 5.3340608],
          [101.6788574, 5.3342244],
          [101.6790296, 5.3342142],
          [101.6794779, 5.3341713],
          [101.6799254, 5.3342319],
          [101.6805334, 5.3343282],
          [101.6808888, 5.3344112],
          [101.6812435, 5.3345977],
          [101.6817825, 5.3347395],
          [101.6823449, 5.3348009],
          [101.6827469, 5.3347807],
          [101.6833225, 5.3345777],
          [101.6840014, 5.3343868],
          [101.6847495, 5.3341965],
          [101.6854162, 5.3340746],
          [101.6858419, 5.3339971],
          [101.6863357, 5.333966],
          [101.6868873, 5.3339584],
          [101.6874723, 5.334043],
          [101.6880694, 5.3340816],
          [101.6888164, 5.3340293],
          [101.6897015, 5.3339205],
          [101.690484, 5.3336614],
          [101.6909789, 5.3335153],
          [101.6914512, 5.3333115],
          [101.6919013, 5.3329927],
          [101.6923047, 5.3327999],
          [101.6929251, 5.3327583],
          [101.6936951, 5.3327175],
          [101.6940519, 5.3325936],
          [101.694398, 5.3323889],
          [101.6947781, 5.3321961],
          [101.6950667, 5.3320141],
          [101.6953099, 5.3317167],
          [101.6956208, 5.3316038],
          [101.6962047, 5.3318724],
          [101.6968571, 5.3321645],
          [101.6973496, 5.3323864],
          [101.6979119, 5.3324479],
          [101.6987045, 5.3324074],
          [101.6992675, 5.3324113],
          [101.6999214, 5.3325078],
          [101.7004597, 5.3327302],
          [101.700973, 5.3332282],
          [101.7014874, 5.3335884],
          [101.7018984, 5.3339593],
          [101.7023669, 5.3343191],
          [101.7028799, 5.3348517],
          [101.7031403, 5.335394],
          [101.7032512, 5.3359699],
          [101.7032804, 5.3367177],
          [101.7034014, 5.3375007],
          [101.7038115, 5.3380096],
          [101.7039575, 5.3385052],
          [101.7041032, 5.3390238],
          [101.7041571, 5.3395072],
          [101.7042809, 5.3398762],
          [101.7045897, 5.3400853],
          [101.7051859, 5.340216],
          [101.7059544, 5.3403593],
          [101.7065505, 5.3405129],
          [101.7070552, 5.3406199],
          [101.7077417, 5.3410157],
          [101.7084623, 5.3414694],
          [101.7090215, 5.3419678],
          [101.7094308, 5.3425687],
          [101.7100812, 5.3432173],
          [101.7106623, 5.3438769],
          [101.7110059, 5.3439943],
          [101.7117404, 5.3440914],
          [101.7123713, 5.3442338],
          [101.7128304, 5.344283],
          [101.713335, 5.3443785],
          [101.7138393, 5.34452],
          [101.7143429, 5.3447535],
          [101.7150631, 5.3452646],
          [101.7156586, 5.3455217],
          [101.716392, 5.3457454],
          [101.717161, 5.3458426],
          [101.717827, 5.3458702],
          [101.718437, 5.345702],
          [101.7189542, 5.345625],
          [101.7192651, 5.3455122],
          [101.7198163, 5.345539],
          [101.7201478, 5.3457368],
          [101.7205943, 5.3459815],
          [101.7210398, 5.3463295],
          [101.7214057, 5.3465391],
          [101.7217131, 5.3469553],
          [101.7220654, 5.3475098],
          [101.7223148, 5.3479716],
          [101.7224946, 5.3485479],
          [101.722904, 5.3491719],
          [101.7233258, 5.3496003],
          [101.7236107, 5.3499243],
          [101.7241261, 5.3501694],
          [101.7245949, 5.3504487],
          [101.7250877, 5.3506016],
          [101.7256146, 5.3508237],
          [101.7263512, 5.3505988],
          [101.7269592, 5.350695],
          [101.7275445, 5.3507796],
          [101.728255, 5.35098],
          [101.7289423, 5.3512953],
          [101.7296636, 5.3516338],
          [101.7303511, 5.3518456],
          [101.7309355, 5.3520222],
          [101.7315542, 5.3522565],
          [101.7319329, 5.3523281],
          [101.7324727, 5.3523433],
          [101.7329554, 5.3522546],
          [101.7333693, 5.3522345],
          [101.734174, 5.3521135],
          [101.7349427, 5.3522223],
          [101.7356424, 5.3523422],
          [101.7359648, 5.3522409],
          [101.7363898, 5.3522323],
          [101.7369995, 5.3520755],
          [101.7374801, 5.3523433],
          [101.7380401, 5.3527497],
          [101.7384842, 5.3533049],
          [101.7386296, 5.3539154],
          [101.7388091, 5.3545263],
          [101.7389208, 5.3549871],
          [101.73924, 5.3553114],
          [101.7396626, 5.3556938],
          [101.7400615, 5.3561221],
          [101.7406667, 5.3566553],
          [101.7410309, 5.3571639],
          [101.7411317, 5.3575327],
          [101.7413477, 5.3578677],
          [101.7416208, 5.3582261],
          [101.7419964, 5.3587118],
          [101.742372, 5.3592319],
          [101.7429191, 5.3598223],
          [101.7433873, 5.3602511],
          [101.7437399, 5.3607366],
          [101.7441267, 5.3612913],
          [101.7443309, 5.3616607],
          [101.7442818, 5.362132],
          [101.7442335, 5.3624767],
          [101.7441956, 5.3629595],
          [101.7441584, 5.3633618],
          [101.7441431, 5.3639368],
          [101.7442892, 5.3644208],
          [101.7447574, 5.3648036],
          [101.7451118, 5.3650361],
          [101.745558, 5.3652922],
          [101.7462679, 5.3656191],
          [101.7468181, 5.3657954],
          [101.7472407, 5.3661203],
          [101.7477099, 5.3664226],
          [101.7480869, 5.3666898],
          [101.7484744, 5.367141],
          [101.74893, 5.3676847],
          [101.7495794, 5.3684943],
          [101.7499655, 5.3691295],
          [101.7502726, 5.3695572],
          [101.7505689, 5.3699273],
          [101.751322, 5.370634],
          [101.7519386, 5.3711789],
          [101.7522353, 5.3714339],
          [101.7524176, 5.3716767],
          [101.7526565, 5.3719889],
          [101.7528023, 5.3725075],
          [101.7530753, 5.3729119],
          [101.753332, 5.3740293],
          [101.7528841, 5.3739918],
          [101.752666, 5.3740017],
          [101.752424, 5.3740922],
          [101.7521127, 5.374274],
          [101.7517444, 5.374398],
          [101.7514908, 5.3744997],
          [101.7511796, 5.3746817],
          [101.7510519, 5.3748993],
          [101.7507285, 5.3751616],
          [101.7505316, 5.3754018],
          [101.7502308, 5.3757103],
          [101.7499644, 5.3760075],
          [101.749778, 5.3764088],
          [101.7495112, 5.3767865],
          [101.7491993, 5.3770374],
          [101.7489913, 5.377266],
          [101.7488404, 5.3774605],
          [101.7486432, 5.3777582],
          [101.7484581, 5.3779295],
          [101.7482849, 5.3780893],
          [101.7478808, 5.3783856],
          [101.7474888, 5.3786128],
          [101.7471309, 5.3788979],
          [101.7468197, 5.3790683],
          [101.7466235, 5.3791705],
          [101.7463126, 5.3792834],
          [101.7459443, 5.3794074],
          [101.7455648, 5.3794738],
          [101.7452542, 5.3795407],
          [101.7447819, 5.3797099],
          [101.744448, 5.3798457],
          [101.7440901, 5.3801307],
          [101.7438349, 5.3804855],
          [101.743614, 5.3808981],
          [101.7435765, 5.3813464],
          [101.7436304, 5.3818528],
          [101.7437313, 5.3821985],
          [101.7439017, 5.3824642],
          [101.7440367, 5.3828792],
          [101.7441612, 5.3831792],
          [101.7442958, 5.3836516],
          [101.7440739, 5.3841907],
          [101.7439226, 5.3844657],
          [101.743634, 5.3846822],
          [101.743161, 5.384978],
          [101.7428497, 5.3851829],
          [101.7425958, 5.3853192],
          [101.7422838, 5.3856046],
          [101.7420988, 5.3857874],
          [101.7418678, 5.3859698],
          [101.7416599, 5.3861408],
          [101.7412912, 5.3863338],
          [101.7411291, 5.3865053],
          [101.7409667, 5.3867226],
          [101.7407347, 5.3870776],
          [101.7404568, 5.3874092],
          [101.7402927, 5.3879027],
          [101.7403125, 5.3883284],
          [101.740379, 5.3886969],
          [101.7404569, 5.389054],
          [101.740523, 5.3894915],
          [101.7404746, 5.3898247],
          [101.7405306, 5.3900437],
          [101.7406089, 5.3903433],
          [101.740756, 5.3907008],
          [101.7407999, 5.3909771],
          [101.7407968, 5.3914257],
          [101.7407022, 5.3918161],
          [101.7405623, 5.3921371],
          [101.7402841, 5.3925033],
          [101.7400758, 5.3927089],
          [101.7397064, 5.3929938],
          [101.7395335, 5.3930732],
          [101.7392459, 5.3931402],
          [101.7390271, 5.3932193],
          [101.738808, 5.3933443],
          [101.7385426, 5.3935149],
          [101.7383924, 5.3936864],
          [101.7384136, 5.3939396],
          [101.738503, 5.3942623],
          [101.7385468, 5.3946191],
          [101.7385208, 5.395056],
          [101.7385065, 5.3954585],
          [101.7385375, 5.3959877],
          [101.7385462, 5.3963674],
          [101.73859, 5.3967012],
          [101.7385065, 5.3971492],
          [101.7385594, 5.3977821],
          [101.7385125, 5.3979658],
          [101.7385104, 5.3982303],
          [101.738589, 5.3985184],
          [101.7387013, 5.3988988],
          [101.7388377, 5.3991183],
          [101.7389967, 5.3993608],
          [101.7391215, 5.3995918],
          [101.7392916, 5.399892],
          [101.7394965, 5.4001925],
          [101.7397817, 5.400482],
          [101.7399866, 5.4007364],
          [101.7402026, 5.40106],
          [101.740373, 5.4013371],
          [101.7406235, 5.4016724],
          [101.7408276, 5.4020649],
          [101.7410088, 5.4024456],
          [101.7412009, 5.402884],
          [101.7413129, 5.4033104],
          [101.7413205, 5.4038856],
          [101.7414437, 5.4043695],
          [101.741442, 5.404588],
          [101.7414631, 5.4048756],
          [101.7414722, 5.4052207],
          [101.7416771, 5.4054982],
          [101.7418364, 5.4057293],
          [101.742076, 5.405961],
          [101.7421762, 5.4064103],
          [101.7423456, 5.40686],
          [101.7425842, 5.4072297],
          [101.7425349, 5.4077124],
          [101.7425085, 5.4082183],
          [101.7423905, 5.4086891],
          [101.7424114, 5.4089768],
          [101.7422591, 5.4094012],
          [101.7423144, 5.4097007],
          [101.7423238, 5.4100113],
          [101.7424602, 5.4102538],
          [101.7426883, 5.4104623],
          [101.7428362, 5.4107049],
          [101.7430751, 5.4110171],
          [101.743337, 5.411364],
          [101.7433809, 5.4116863],
          [101.7432519, 5.412042],
          [101.743134, 5.4125012],
          [101.74312, 5.4128692],
          [101.7431287, 5.4132833],
          [101.7432529, 5.4135602],
          [101.7435037, 5.413861],
          [101.7437782, 5.4140353],
          [101.7440644, 5.4141984],
          [101.7442683, 5.4145908],
          [101.7443347, 5.4149824],
          [101.7442164, 5.4154875],
          [101.7440412, 5.4159235],
          [101.743981, 5.4162796],
          [101.7440248, 5.4166019],
          [101.7440565, 5.4170392],
          [101.7441115, 5.4173616],
          [101.7442363, 5.4176155],
          [101.7443146, 5.4178922],
          [101.7443699, 5.418226],
          [101.7444019, 5.4185713],
          [101.7444802, 5.4188824],
          [101.7446277, 5.4191594],
          [101.7446938, 5.4195739],
          [101.7445529, 5.4200331],
          [101.7445164, 5.4203089],
          [101.7443769, 5.4205724],
          [101.744179, 5.4209506],
          [101.7439582, 5.4213056],
          [101.7438184, 5.4215922],
          [101.7436097, 5.4219013],
          [101.743504, 5.4222342],
          [101.7434097, 5.422567],
          [101.7433388, 5.4228656],
          [101.7432675, 5.4232216],
          [101.7431155, 5.4236002],
          [101.7430334, 5.4238526],
          [101.7428734, 5.4241281],
          [101.7428847, 5.4243465],
          [101.7428154, 5.424588],
          [101.742746, 5.4248753],
          [101.7426999, 5.4250707],
          [101.742596, 5.4254385],
          [101.7424234, 5.4256338],
          [101.7422167, 5.4257945],
          [101.7419288, 5.4261507],
          [101.7418139, 5.4263461],
          [101.7415952, 5.4266908],
          [101.7415028, 5.4269896],
          [101.7413531, 5.4272309],
          [101.7412722, 5.4276562],
          [101.740525, 5.42815],
          [101.7403639, 5.4282878],
          [101.7401338, 5.4285636],
          [101.7401566, 5.4288395],
          [101.7402596, 5.4291845],
          [101.7403853, 5.4295984],
          [101.7403158, 5.4299893],
          [101.7402118, 5.4305525],
          [101.7402001, 5.4308629],
          [101.7401529, 5.4317135],
          [101.7400949, 5.4321734],
          [101.7401521, 5.4324953],
          [101.7401978, 5.4327943],
          [101.7401973, 5.4330012],
          [101.740243, 5.4332656],
          [101.7403232, 5.4335531],
          [101.7403342, 5.4337601],
          [101.7404946, 5.4341855],
          [101.7404711, 5.4345534],
          [101.7403783, 5.4354041],
          [101.7404011, 5.4355996],
          [101.7404809, 5.4360364],
          [101.7404806, 5.4364159],
          [101.7404686, 5.4366457],
          [101.7404455, 5.4369562],
          [101.7404105, 5.4372436],
          [101.7403066, 5.4376343],
          [101.7402605, 5.4378527],
          [101.7402485, 5.4381286],
          [101.7403172, 5.4383816],
          [101.7404203, 5.4386806],
          [101.7404661, 5.4389106],
          [101.7404886, 5.4391405],
          [101.7404654, 5.4393704],
          [101.7405456, 5.4396119],
          [101.7407057, 5.4400144],
          [101.7408204, 5.4402099],
          [101.7408545, 5.4406238],
          [101.7408426, 5.4408307],
          [101.7407506, 5.4409801],
          [101.7406009, 5.4411984],
          [101.7405204, 5.4414398],
          [101.7404051, 5.4417501],
          [101.7403931, 5.442026],
          [101.7404388, 5.4423825],
          [101.7406215, 5.4432333],
          [101.7408163, 5.4436933],
          [101.7407125, 5.4440266],
          [101.7405281, 5.4444749],
          [101.7403096, 5.4446701],
          [101.7403667, 5.4450265],
          [101.7404465, 5.4453715],
          [101.7405956, 5.4455901],
          [101.7406529, 5.4457855],
          [101.7406408, 5.4461649],
          [101.7404907, 5.446935],
          [101.7403983, 5.4472223],
          [101.7404326, 5.4474523],
          [101.7405128, 5.4477283],
          [101.7405582, 5.4479238],
          [101.7407072, 5.4483032],
          [101.7407414, 5.4485677],
          [101.7408212, 5.4490391],
          [101.7407516, 5.449522],
          [101.7405329, 5.4499241],
          [101.7404176, 5.4502114],
          [101.7402217, 5.4507631],
          [101.7400375, 5.4511309],
          [101.739991, 5.4514412],
          [101.7400138, 5.4516596],
          [101.7400709, 5.4520966],
          [101.740208, 5.4525106],
          [101.7402078, 5.4527865],
          [101.7402301, 5.4532808],
          [101.7397704, 5.4536023],
          [101.7384368, 5.4541646],
          [101.7382531, 5.4541644],
          [101.738069, 5.4542678],
          [101.7378853, 5.4542791],
          [101.7376439, 5.4543939],
          [101.7373794, 5.4545661],
          [101.7371034, 5.4547958],
          [101.7367815, 5.455083],
          [101.7366547, 5.4552783],
          [101.7364935, 5.4556116],
          [101.7362053, 5.4563586],
          [101.7360784, 5.4567379],
          [101.7359634, 5.4569792],
          [101.7358367, 5.457186],
          [101.7357558, 5.4575194],
          [101.735744, 5.4579562],
          [101.7357549, 5.4583931],
          [101.7357891, 5.4586346],
          [101.7359607, 5.4590371],
          [101.7361675, 5.4591981],
          [101.7365231, 5.4595664],
          [101.7370514, 5.4597966],
          [101.7372235, 5.4599578],
          [101.7374758, 5.4602453],
          [101.7377051, 5.460602],
          [101.7378541, 5.4609815],
          [101.7380031, 5.4612919],
          [101.7380259, 5.461499],
          [101.7381746, 5.4618094],
          [101.7384043, 5.462189],
          [101.7387363, 5.463017],
          [101.7390689, 5.4633737],
          [101.7392525, 5.4635807],
          [101.7395282, 5.4637763],
          [101.7397462, 5.4639375],
          [101.7398953, 5.4640756],
          [101.7401362, 5.4643747],
          [101.7403311, 5.4646393],
          [101.740687, 5.4650879],
          [101.7416389, 5.4663418],
          [101.7418339, 5.4666523],
          [101.7419485, 5.4668364],
          [101.7419943, 5.4670433],
          [101.7419594, 5.4672732],
          [101.7420051, 5.4675722],
          [101.7420623, 5.4677791],
          [101.742234, 5.4681472],
          [101.742773, 5.4689293],
          [101.7430482, 5.4693435],
          [101.7433582, 5.4696656],
          [101.7435533, 5.4697692],
          [101.7437369, 5.4699303],
          [101.7438976, 5.4700224],
          [101.7441962, 5.4701605],
          [101.7443569, 5.4702182],
          [101.7446785, 5.4705403],
          [101.7449193, 5.470851],
          [101.7461019, 5.4715531],
          [101.7464461, 5.4717719],
          [101.7466298, 5.4718525],
          [101.7469055, 5.4719791],
          [101.7473306, 5.471991],
          [101.7476404, 5.4721866],
          [101.7480308, 5.4724743],
          [101.7482367, 5.4730608],
          [101.7482939, 5.4734173],
          [101.7484425, 5.4739233],
          [101.7485455, 5.4743142],
          [101.7486256, 5.4746592],
          [101.7486481, 5.4749351],
          [101.7487282, 5.475234],
          [101.7488543, 5.4755905],
          [101.7490715, 5.4763494],
          [101.7491283, 5.4768783],
          [101.7488973, 5.4779243],
          [101.7489086, 5.4782002],
          [101.748713, 5.4783381],
          [101.7486435, 5.4787289],
          [101.7485396, 5.4791311],
          [101.7485393, 5.479545],
          [101.7486764, 5.479982],
          [101.7487908, 5.4805569],
          [101.7491233, 5.4812355],
          [101.7492604, 5.4816839],
          [101.7492372, 5.4820288],
          [101.7492137, 5.4824197],
          [101.7492246, 5.482707],
          [101.7493735, 5.4832935],
          [101.7496941, 5.484018],
          [101.7499685, 5.4851334],
          [101.7498764, 5.4854667],
          [101.7496344, 5.4859149],
          [101.7494156, 5.4863746],
          [101.7492886, 5.4869837],
          [101.7492764, 5.4875126],
          [101.7492641, 5.4882483],
          [101.7493554, 5.4886508],
          [101.7492748, 5.4889381],
          [101.7491365, 5.4893173],
          [101.7491357, 5.4899496],
          [101.7490891, 5.490513],
          [101.7489618, 5.4910647],
          [101.748915, 5.4918579],
          [101.7489835, 5.4923293],
          [101.7489484, 5.4928581],
          [101.7487522, 5.4933638],
          [101.7484295, 5.4943177],
          [101.7480718, 5.4955016],
          [101.7480023, 5.4959499],
          [101.7478414, 5.4962142],
          [101.7478293, 5.4966051],
          [101.7478631, 5.4971109],
          [101.7480233, 5.4977318],
          [101.7480458, 5.4980078],
          [101.7479534, 5.498318],
          [101.7479753, 5.4993758],
          [101.7479861, 5.4998242],
          [101.7480765, 5.5001817],
          [101.7483636, 5.501319],
          [101.7483861, 5.5015374],
          [101.7482023, 5.5017787],
          [101.7478112, 5.5019508],
          [101.7471214, 5.5024446],
          [101.7468338, 5.5027548],
          [101.7467757, 5.5032376],
          [101.7467522, 5.503548],
          [101.7465909, 5.5040192],
          [101.7463492, 5.5044444],
          [101.7460153, 5.504766],
          [101.7458544, 5.5050993],
          [101.7456354, 5.505444],
          [101.7454973, 5.5058232],
          [101.7453017, 5.5060645],
          [101.7452437, 5.5063634],
          [101.7450595, 5.5066621],
          [101.744772, 5.5069149],
          [101.744519, 5.5071216],
          [101.7443234, 5.5072594],
          [101.7439785, 5.5074431],
          [101.743737, 5.5075578],
          [101.7432315, 5.5076954],
          [101.7428178, 5.5077755],
          [101.7424729, 5.5079247],
          [101.7422199, 5.5080624],
          [101.7420931, 5.5084072],
          [101.742058, 5.508821],
          [101.741977, 5.5092464],
          [101.7416115, 5.5096433],
          [101.7412298, 5.5097631],
          [101.7408851, 5.5097629],
          [101.7405638, 5.5094981],
          [101.7403225, 5.5092795],
          [101.7401506, 5.508923],
          [101.7399556, 5.5087044],
          [101.7398639, 5.5085089],
          [101.7396804, 5.5082213],
          [101.7394738, 5.5080602],
          [101.7392559, 5.5078532],
          [101.7390952, 5.5077265],
          [101.7387047, 5.5074618],
          [101.7382338, 5.507381],
          [101.7376022, 5.5071391],
          [101.7369476, 5.5070235],
          [101.7365685, 5.5068393],
          [101.736362, 5.5066782],
          [101.7362473, 5.5065057],
          [101.736029, 5.506333],
          [101.7358454, 5.506149],
          [101.7355011, 5.5059532],
          [101.7349728, 5.5057803],
          [101.7344333, 5.5053086],
          [101.7341809, 5.5050209],
          [101.7338019, 5.5047677],
          [101.7336188, 5.5044687],
          [101.733343, 5.5043075],
          [101.7330908, 5.5038244],
          [101.7328391, 5.503031],
          [101.7325074, 5.5017776],
          [101.7316135, 5.5000295],
          [101.7308911, 5.4989022],
          [101.7306274, 5.4984881],
          [101.7303745, 5.4983844],
          [101.7300646, 5.4983152],
          [101.7298347, 5.4982806],
          [101.7295818, 5.4983034],
          [101.7293637, 5.4983031],
          [101.7291337, 5.4984409],
          [101.7288579, 5.4983832],
          [101.7285019, 5.4983944],
          [101.727996, 5.4986584],
          [101.7268232, 5.4993358],
          [101.7265358, 5.499393],
          [101.7261795, 5.4995307],
          [101.7259609, 5.4997489],
          [101.72557, 5.5000705],
          [101.7253628, 5.5003347],
          [101.725029, 5.5006678],
          [101.7246038, 5.5008744],
          [101.7243162, 5.5011731],
          [101.7240862, 5.5012418],
          [101.7240172, 5.5014373],
          [101.7239133, 5.5018051],
          [101.7238437, 5.5022764],
          [101.7238659, 5.5029202],
          [101.7237618, 5.5034604],
          [101.7237153, 5.5038627],
          [101.7234377, 5.5053226],
          [101.7234145, 5.5056674],
          [101.7234025, 5.5059548],
          [101.723333, 5.5063457],
          [101.7232639, 5.5065985],
          [101.7230338, 5.5068052],
          [101.7228267, 5.5069775],
          [101.7224705, 5.5070577],
          [101.7220684, 5.5070228],
          [101.7216775, 5.5072639],
          [101.7211716, 5.5074934],
          [101.7211025, 5.5077463],
          [101.7209986, 5.5080796],
          [101.7210096, 5.5083095],
          [101.7212278, 5.5086202],
          [101.7213536, 5.5088616],
          [101.721537, 5.5092757],
          [101.7216055, 5.5098046],
          [101.7216393, 5.5101725],
          [101.7219252, 5.5112993],
          [101.7221315, 5.5116559],
          [101.7222347, 5.5118974],
          [101.7222919, 5.5121273],
          [101.7222569, 5.5124838],
          [101.7221301, 5.5127021],
          [101.7219808, 5.5128169],
          [101.7216934, 5.5129546],
          [101.7213945, 5.5130463],
          [101.72113, 5.5131381],
          [101.7202225, 5.5129764],
          [101.7196374, 5.5123895],
          [101.7192814, 5.5121594],
          [101.7185924, 5.5119978],
          [101.7178226, 5.5118592],
          [101.7170299, 5.5117321],
          [101.7161224, 5.5115589],
          [101.7152838, 5.5114662],
          [101.7147094, 5.5114197],
          [101.7136752, 5.5114073],
          [101.7131008, 5.5114874],
          [101.7128708, 5.5115907],
          [101.712526, 5.5116363],
          [101.7122042, 5.5117281],
          [101.7118594, 5.5117967],
          [101.7113195, 5.5117388],
          [101.710711, 5.5114163],
          [101.7102636, 5.5109561],
          [101.7097698, 5.5107143],
          [101.7090578, 5.5105182],
          [101.7081388, 5.510391],
          [101.7075299, 5.510172],
          [101.7064623, 5.5096308],
          [101.7055435, 5.5091702],
          [101.7050613, 5.5087904],
          [101.7047515, 5.5085372],
          [101.7038333, 5.5078236],
          [101.7033051, 5.5075013],
          [101.7030182, 5.5074435],
          [101.7026389, 5.5075122],
          [101.7020643, 5.5074428],
          [101.701582, 5.5072468],
          [101.7011118, 5.5065912],
          [101.701147, 5.5059704],
          [101.701137, 5.5049587],
          [101.700977, 5.5043493],
          [101.7007247, 5.5039927],
          [101.7004491, 5.503774],
          [101.6999324, 5.5034747],
          [101.6990255, 5.5028646],
          [101.6988535, 5.5026804],
          [101.6985896, 5.5024618],
          [101.6984865, 5.5022203],
          [101.6983719, 5.5019213],
          [101.6982229, 5.5016223],
          [101.6982579, 5.5013349],
          [101.6982237, 5.501013],
          [101.6981554, 5.5007026],
          [101.6976961, 5.5003227],
          [101.6972368, 5.5000694],
          [101.6970073, 5.4998853],
          [101.6966971, 5.4998045],
          [101.6965249, 5.4998043],
          [101.696226, 5.4998501],
          [101.6958238, 5.4999302],
          [101.6955021, 5.4999299],
          [101.6951578, 5.4997917],
          [101.6947557, 5.4996649],
          [101.6945029, 5.4995727],
          [101.694193, 5.499423],
          [101.6934808, 5.4992154],
          [101.6931708, 5.4992152],
          [101.6929409, 5.4992265],
          [101.6927912, 5.4993413],
          [101.6926533, 5.499594],
          [101.6925953, 5.4998354],
          [101.6925836, 5.5001573],
          [101.6926175, 5.5004332],
          [101.692514, 5.5007206],
          [101.6923642, 5.5010078],
          [101.6920417, 5.5015018],
          [101.6917541, 5.5018235],
          [101.6916044, 5.5020074],
          [101.6914549, 5.5022831],
          [101.6913626, 5.5025244],
          [101.6913509, 5.5027544],
          [101.6914307, 5.5030073],
          [101.6913731, 5.5032487],
          [101.6913727, 5.5034212],
          [101.6914069, 5.5036741],
          [101.6911193, 5.5039728],
          [101.6908549, 5.5040415],
          [101.6904182, 5.5041561],
          [101.6891659, 5.5041205],
          [101.6884993, 5.5042464],
          [101.6881201, 5.504292],
          [101.6878557, 5.5043377],
          [101.6874649, 5.5045674],
          [101.6871429, 5.504797],
          [101.6869014, 5.5049002],
          [101.6863839, 5.5051642],
          [101.6857974, 5.5056465],
          [101.6853491, 5.5058875],
          [101.685142, 5.5060598],
          [101.684889, 5.506232],
          [101.6845786, 5.5063122],
          [101.6842572, 5.5062314],
          [101.6840503, 5.5062197],
          [101.6837629, 5.5062425],
          [101.6834755, 5.5063457],
          [101.6830961, 5.5066328],
          [101.6828315, 5.5068854],
          [101.6825211, 5.5070921],
          [101.6822795, 5.5072988],
          [101.6820837, 5.5076435],
          [101.681853, 5.5082296],
          [101.6817493, 5.5086433],
          [101.6816798, 5.5090342],
          [101.6815414, 5.5094594],
          [101.6808623, 5.5103785],
          [101.6806318, 5.5107346],
          [101.680379, 5.5109069],
          [101.6795288, 5.5109291],
          [101.6791606, 5.5111587],
          [101.6789305, 5.5113079],
          [101.678632, 5.5113537],
          [101.6782413, 5.5113763],
          [101.6770694, 5.5112833],
          [101.6766444, 5.5111679],
          [101.6760815, 5.5112134],
          [101.6758285, 5.5113512],
          [101.675564, 5.5114544],
          [101.675288, 5.5116036],
          [101.6749547, 5.5116607],
          [101.6747018, 5.5117525],
          [101.6744144, 5.5118327],
          [101.6734494, 5.5117169],
          [101.673128, 5.511682],
          [101.6728866, 5.5116359],
          [101.6725879, 5.5115436],
          [101.6721747, 5.5113593],
          [101.67183, 5.5113015],
          [101.6714165, 5.5111747],
          [101.6708769, 5.5107948],
          [101.6703263, 5.510277],
          [101.6700509, 5.5100928],
          [101.6697063, 5.5099775],
          [101.6692008, 5.51],
          [101.6687757, 5.5099536],
          [101.6684655, 5.5099074],
          [101.6681209, 5.5097346],
          [101.6676962, 5.5095388],
          [101.6672255, 5.509182],
          [101.6670879, 5.5090324],
          [101.6667552, 5.5087332],
          [101.6662155, 5.5084223],
          [101.6658136, 5.5081575],
          [101.6655383, 5.5079503],
          [101.665228, 5.5079385],
          [101.6649292, 5.5079498],
          [101.6646761, 5.5080989],
          [101.6643775, 5.5082711],
          [101.6641588, 5.5085583],
          [101.663733, 5.5089373],
          [101.6632612, 5.5095692],
          [101.6631343, 5.509822],
          [101.6628582, 5.5102125],
          [101.6625701, 5.5106376],
          [101.6623284, 5.5110742],
          [101.6621209, 5.5116144],
          [101.661971, 5.5120396],
          [101.6614757, 5.5128209],
          [101.6612338, 5.5133725],
          [101.661015, 5.5137747],
          [101.6606695, 5.5143147],
          [101.660301, 5.5148892],
          [101.6600588, 5.5154292],
          [101.6594721, 5.516061],
          [101.6592878, 5.5163597],
          [101.659012, 5.5163939],
          [101.6587478, 5.5164971],
          [101.6585635, 5.5167959],
          [101.6581836, 5.5173013],
          [101.6578729, 5.5177379],
          [101.6574816, 5.5181169],
          [101.6571366, 5.5184385],
          [101.6568372, 5.5187715],
          [101.6564002, 5.519185],
          [101.6560435, 5.5193571],
          [101.6558826, 5.5195754],
          [101.6556061, 5.5199315],
          [101.6553643, 5.5203797],
          [101.6552948, 5.5208049],
          [101.6551679, 5.5211267],
          [101.655133, 5.521598],
          [101.6552124, 5.5223224],
          [101.6554993, 5.5225756],
          [101.6556942, 5.5228861],
          [101.6560154, 5.5231278],
          [101.6563828, 5.5234501],
          [101.6568073, 5.5238988],
          [101.6572774, 5.5246006],
          [101.6575638, 5.5252676],
          [101.6577243, 5.5255782],
          [101.6578619, 5.5257507],
          [101.6580455, 5.5258429],
          [101.6584819, 5.5261191],
          [101.6588721, 5.5263495],
          [101.6593888, 5.5265684],
          [101.6600661, 5.5270634],
          [101.6604107, 5.5272821],
          [101.660663, 5.5275353],
          [101.6608924, 5.5278689],
          [101.6612365, 5.5282486],
          [101.6617297, 5.5287779],
          [101.662166, 5.5291116],
          [101.6627515, 5.5295491],
          [101.6629695, 5.5296757],
          [101.6633252, 5.529929],
          [101.6634857, 5.5302166],
          [101.663681, 5.5304696],
          [101.6637838, 5.5306881],
          [101.6637378, 5.530872],
          [101.6637491, 5.5310675],
          [101.6636796, 5.5313318],
          [101.6635643, 5.5315732],
          [101.6634028, 5.5320788],
          [101.6632529, 5.5324351],
          [101.6630341, 5.5328372],
          [101.6628958, 5.53309],
          [101.6629415, 5.5333544],
          [101.6628721, 5.5336763],
          [101.6629981, 5.5339294],
          [101.6632395, 5.5339986],
          [101.6634232, 5.5340332],
          [101.663745, 5.533999],
          [101.6641587, 5.5339419],
          [101.6645379, 5.5338733],
          [101.6648253, 5.5338276],
          [101.6652504, 5.5338165],
          [101.6656181, 5.5338973],
          [101.6657442, 5.5340583],
          [101.6655943, 5.5345181],
          [101.6654329, 5.5348973],
          [101.665375, 5.5350697],
          [101.6652135, 5.5356214],
          [101.6653166, 5.5359203],
          [101.6653852, 5.5362078],
          [101.665097, 5.5368513],
          [101.6647629, 5.5375293],
          [101.6646243, 5.5380465],
          [101.6645317, 5.5385407],
          [101.664554, 5.5390121],
          [101.664657, 5.5394376],
          [101.6647596, 5.539886],
          [101.6651385, 5.5402313],
          [101.6656891, 5.5407606],
          [101.6658613, 5.5408758],
          [101.6662403, 5.541106],
          [101.6667457, 5.54121],
          [101.6672168, 5.5411874],
          [101.6679062, 5.54128],
          [101.6681816, 5.5413952],
          [101.6685951, 5.5416485],
          [101.6691347, 5.5419594],
          [101.6698351, 5.5423739],
          [101.6703291, 5.5424088],
          [101.6707312, 5.5424322],
          [101.6709952, 5.5425474],
          [101.671225, 5.5426971],
          [101.67142, 5.5428928],
          [101.6714539, 5.5432261],
          [101.6715684, 5.5435366],
          [101.6718666, 5.5439622],
          [101.6719812, 5.5442153],
          [101.6722449, 5.5445948],
          [101.6723366, 5.5448249],
          [101.6723593, 5.5450778],
          [101.6723587, 5.5455147],
          [101.6725192, 5.5457218],
          [101.6726109, 5.5459288],
          [101.672806, 5.5460899],
          [101.6730239, 5.5462855],
          [101.6734031, 5.5462974],
          [101.6736553, 5.5467575],
          [101.6737241, 5.546953],
          [101.6738846, 5.5472521],
          [101.6741369, 5.5475282],
          [101.6743894, 5.5476894],
          [101.6745618, 5.5478275],
          [101.6746765, 5.5480115],
          [101.6748366, 5.548345],
          [101.6749053, 5.5485405],
          [101.6748248, 5.5487819],
          [101.6748128, 5.5489888],
          [101.674617, 5.5493106],
          [101.6742377, 5.5494367],
          [101.6734908, 5.5494244],
          [101.6730771, 5.5494585],
          [101.6728244, 5.5495848],
          [101.6725942, 5.5498835],
          [101.672421, 5.5502741],
          [101.6723862, 5.5506765],
          [101.6724428, 5.5513433],
          [101.6724414, 5.552194],
          [101.6725444, 5.552631],
          [101.6725896, 5.5531254],
          [101.6726804, 5.5539647],
          [101.6727943, 5.5547235],
          [101.6727358, 5.5555512],
          [101.6726661, 5.5562294],
          [101.6725852, 5.5564249],
          [101.6724813, 5.5567581],
          [101.6725041, 5.5569421],
          [101.6725154, 5.557149],
          [101.6725841, 5.5573675],
          [101.6727562, 5.5575746],
          [101.6729627, 5.5577127],
          [101.6732267, 5.5578624],
          [101.6740307, 5.5581851],
          [101.6744672, 5.5583694],
          [101.6748006, 5.5582087],
          [101.6752718, 5.5580943],
          [101.675444, 5.5581174],
          [101.675582, 5.5582439],
          [101.6758229, 5.5585201],
          [101.6760631, 5.5591871],
          [101.6766715, 5.559774],
          [101.6772226, 5.5600964],
          [101.6777626, 5.5601198],
          [101.678061, 5.5602926],
          [101.6782559, 5.5605802],
          [101.6784737, 5.5610517],
          [101.6787489, 5.5614313],
          [101.6791502, 5.5621214],
          [101.6792648, 5.5624435],
          [101.6794481, 5.5629264],
          [101.6793207, 5.5634322],
          [101.6792861, 5.5636851],
          [101.6792859, 5.563915],
          [101.6793771, 5.5642254],
          [101.6794343, 5.5644784],
          [101.6793533, 5.5648692],
          [101.6792604, 5.5657314],
          [101.6792137, 5.5662601],
          [101.679271, 5.5664211],
          [101.6793393, 5.5668005],
          [101.679362, 5.567111],
          [101.6794303, 5.56741],
          [101.6793496, 5.5677778],
          [101.6791768, 5.5681109],
          [101.6788893, 5.5683061],
          [101.6786133, 5.5684554],
          [101.6781875, 5.5688688],
          [101.6778075, 5.5694318],
          [101.6776117, 5.569742],
          [101.6775885, 5.5700409],
          [101.6776797, 5.5704778],
          [101.6778172, 5.5708229],
          [101.678081, 5.571168],
          [101.6784486, 5.5713063],
          [101.6790452, 5.5718127],
          [101.6793778, 5.5723188],
          [101.6795727, 5.5726409],
          [101.6800084, 5.573354],
          [101.680284, 5.5737336],
          [101.6803636, 5.574274],
          [101.6805349, 5.5750559],
          [101.6805456, 5.5755158],
          [101.6806373, 5.5757573],
          [101.6808436, 5.5762174],
          [101.6810499, 5.5766314],
          [101.6812099, 5.5771834],
          [101.6815195, 5.5776665],
          [101.6818519, 5.5783795],
          [101.6816905, 5.5787702],
          [101.6820811, 5.5788741],
          [101.6826096, 5.5789436],
          [101.6829772, 5.5790588],
          [101.6832871, 5.5791741],
          [101.6834592, 5.5793467],
          [101.6837464, 5.5795309],
          [101.6837803, 5.5798298],
          [101.6839178, 5.5801978],
          [101.6841809, 5.5809913],
          [101.6843988, 5.5813364],
          [101.6846511, 5.581693],
          [101.6848579, 5.5818887],
          [101.6850184, 5.5822221],
          [101.685213, 5.5826017],
          [101.6853733, 5.5831191],
          [101.6856485, 5.5835793],
          [101.6858772, 5.5843497],
          [101.6863135, 5.584741],
          [101.6864281, 5.58504],
          [101.6866115, 5.5853621],
          [101.6867028, 5.585753],
          [101.6867365, 5.5861784],
          [101.6866787, 5.5867532],
          [101.6863905, 5.5872702],
          [101.6858723, 5.5880055],
          [101.685573, 5.5884881],
          [101.6852165, 5.5888672],
          [101.6850092, 5.5891429],
          [101.6846869, 5.589407],
          [101.6841582, 5.589602],
          [101.6836412, 5.5896129],
          [101.6832385, 5.5897965],
          [101.6828936, 5.5899802],
          [101.682629, 5.5902329],
          [101.6825365, 5.5905546],
          [101.6823405, 5.5911178],
          [101.6822023, 5.5913361],
          [101.6820184, 5.5914739],
          [101.6817654, 5.5915542],
          [101.6815584, 5.5916114],
          [101.681248, 5.5916686],
          [101.6810412, 5.5917604],
          [101.6809265, 5.5916339],
          [101.6806508, 5.5914612],
          [101.6802716, 5.5914148],
          [101.6798465, 5.5913684],
          [101.6796742, 5.5914027],
          [101.6793983, 5.5913795],
          [101.6791802, 5.5913218],
          [101.6789617, 5.5913331],
          [101.6785944, 5.5912293],
          [101.6784447, 5.5913557],
          [101.6783294, 5.591528],
          [101.6782144, 5.5917693],
          [101.6783044, 5.5931604],
          [101.6782572, 5.5939766],
          [101.6782913, 5.594379],
          [101.6782334, 5.5945743],
          [101.6780949, 5.5949881],
          [101.6780138, 5.5954709],
          [101.6781053, 5.5959078],
          [101.6780243, 5.5962871],
          [101.6778743, 5.5968158],
          [101.6776206, 5.5973329],
          [101.6772412, 5.5975739],
          [101.6770683, 5.5979187],
          [101.6767921, 5.5982863],
          [101.6765388, 5.5986999],
          [101.6763431, 5.5989297],
          [101.6760672, 5.5990099],
          [101.6758949, 5.5989983],
          [101.6756994, 5.5990096],
          [101.6754354, 5.5988714],
          [101.6753208, 5.5986988],
          [101.6751946, 5.5984918],
          [101.6750456, 5.5982272],
          [101.6747468, 5.5981465],
          [101.6739888, 5.5978929],
          [101.6734945, 5.5978349],
          [101.6732074, 5.5978807],
          [101.6729774, 5.5979379],
          [101.6727473, 5.5980297],
          [101.6726667, 5.598386],
          [101.6725972, 5.5986618],
          [101.6725163, 5.5989952],
          [101.672447, 5.5993629],
          [101.6722513, 5.5996501],
          [101.6719289, 5.5998913],
          [101.6717101, 5.600282],
          [101.6716066, 5.6004773],
          [101.6714684, 5.6006036],
          [101.6712961, 5.6007069],
          [101.6710431, 5.6007642],
          [101.670859, 5.600833],
          [101.6706293, 5.6009018],
          [101.670296, 5.6009244],
          [101.6700545, 5.6008897],
          [101.6697904, 5.6009124],
          [101.6696179, 5.6009123],
          [101.6694341, 5.6009351],
          [101.6692616, 5.600912],
          [101.6689167, 5.6010151],
          [101.6682383, 5.6013364],
          [101.6677667, 5.6015773],
          [101.6674792, 5.601761],
          [101.6672724, 5.6018988],
          [101.6669848, 5.6020595],
          [101.6667779, 5.6020133],
          [101.6665024, 5.601944],
          [101.6663074, 5.6017139],
          [101.6661697, 5.6015759],
          [101.6661009, 5.6014033],
          [101.6660207, 5.6012308],
          [101.6657793, 5.6012306],
          [101.6656299, 5.6013569],
          [101.6655146, 5.6015407],
          [101.665434, 5.6017246],
          [101.6653532, 5.6019314],
          [101.6653067, 5.6022073],
          [101.665249, 5.6025176],
          [101.6651337, 5.6027015],
          [101.6649729, 5.6027933],
          [101.6648003, 5.6028391],
          [101.664605, 5.6028964],
          [101.6643176, 5.6029191],
          [101.6639958, 5.6029189],
          [101.6637313, 5.6029761],
          [101.6634782, 5.6032632],
          [101.6634435, 5.6034816],
          [101.6632365, 5.6035389],
          [101.6628344, 5.6034235],
          [101.6621798, 5.6031241],
          [101.6619273, 5.6030318],
          [101.6617432, 5.6030087],
          [101.6614792, 5.6029394],
          [101.6612837, 5.6028932],
          [101.6610999, 5.6030655],
          [101.6610649, 5.603238],
          [101.6611681, 5.603422],
          [101.6611794, 5.6036979],
          [101.6612021, 5.6039853],
          [101.6611901, 5.6041692],
          [101.6609713, 5.6046059],
          [101.6608678, 5.6047898],
          [101.6606143, 5.6050884],
          [101.6604419, 5.6052377],
          [101.6602576, 5.6055019],
          [101.6601309, 5.6056168],
          [101.6600044, 5.6057776],
          [101.6597972, 5.6060648],
          [101.6596934, 5.6062372],
          [101.6595435, 5.6065359],
          [101.6593248, 5.6068116],
          [101.6590949, 5.6070298],
          [101.6589453, 5.6071446],
          [101.6587726, 5.6072365],
          [101.6584855, 5.6073511],
          [101.6581292, 5.6073738],
          [101.6578992, 5.6073621],
          [101.6577269, 5.6073849],
          [101.6575429, 5.6073733],
          [101.6573592, 5.6073961],
          [101.6571407, 5.6074189],
          [101.6569225, 5.6074071],
          [101.6566696, 5.6074069],
          [101.6564396, 5.6074296],
          [101.6562559, 5.6074065],
          [101.6560259, 5.6074178],
          [101.6558307, 5.6074176],
          [101.6556007, 5.6074978],
          [101.6553591, 5.60767],
          [101.6552097, 5.6077733],
          [101.6550715, 5.6078882],
          [101.6548069, 5.6080489],
          [101.6545772, 5.6081636],
          [101.6542667, 5.6082553],
          [101.653841, 5.6085193],
          [101.6536337, 5.608841],
          [101.6535417, 5.6089903],
          [101.6536105, 5.6091743],
          [101.6537597, 5.6092664],
          [101.6541273, 5.6093588],
          [101.6543914, 5.6094165],
          [101.6545754, 5.6094282],
          [101.6547935, 5.6094974],
          [101.6549542, 5.609578],
          [101.6551152, 5.6096816],
          [101.6552758, 5.6098428],
          [101.6555168, 5.6100269],
          [101.6557003, 5.610234],
          [101.655838, 5.6103721],
          [101.6559756, 5.6106021],
          [101.6561018, 5.6107632],
          [101.6562048, 5.6110622],
          [101.6562735, 5.6113497],
          [101.656273, 5.6115336],
          [101.6562499, 5.6117405],
          [101.6562266, 5.6120394],
          [101.65618, 5.6124532],
          [101.6561913, 5.6126602],
          [101.6561908, 5.61289],
          [101.656225, 5.613143],
          [101.6562245, 5.6133499],
          [101.6562931, 5.6137179],
          [101.6563846, 5.6141433],
          [101.6564299, 5.6144998],
          [101.6565216, 5.6146838],
          [101.6564865, 5.6150286],
          [101.6563714, 5.6153274],
          [101.656279, 5.6155688],
          [101.6560948, 5.6157755],
          [101.6559453, 5.6160168],
          [101.6558415, 5.6162581],
          [101.6556457, 5.6164763],
          [101.6555189, 5.6167407],
          [101.6555186, 5.61712],
          [101.655541, 5.6173615],
          [101.6555293, 5.6175684],
          [101.6555635, 5.6178328],
          [101.6556552, 5.6180859],
          [101.6557579, 5.6183963],
          [101.6557807, 5.6186032],
          [101.6557457, 5.6188561],
          [101.6556536, 5.6191895],
          [101.6555841, 5.6195228],
          [101.6554457, 5.6198446],
          [101.6551463, 5.6203731],
          [101.6551458, 5.6206375],
          [101.6555363, 5.6209252],
          [101.6558576, 5.621121],
          [101.6560181, 5.621489],
          [101.656213, 5.6218112],
          [101.6563735, 5.6220987],
          [101.6566028, 5.6225473],
          [101.6567173, 5.6228923],
          [101.656992, 5.6235133],
          [101.6571981, 5.6241803],
          [101.6573242, 5.6244333],
          [101.6573236, 5.6247285],
          [101.6576103, 5.6251923],
          [101.6579435, 5.6253766],
          [101.658414, 5.6256415],
          [101.658965, 5.6262398],
          [101.6594468, 5.6268381],
          [101.6597678, 5.6274131],
          [101.6600889, 5.6279423],
          [101.6605133, 5.6284715],
          [101.661271, 5.628978],
          [101.6615923, 5.6292427],
          [101.6618676, 5.6296454],
          [101.6622693, 5.6301516],
          [101.6627283, 5.6305659],
          [101.6632105, 5.6310147],
          [101.6633821, 5.6314057],
          [101.6631977, 5.6318769],
          [101.6628755, 5.6321985],
          [101.6625301, 5.6326121],
          [101.6623571, 5.6330718],
          [101.6624485, 5.6335317],
          [101.6625627, 5.6339226],
          [101.6627462, 5.6342562],
          [101.6628031, 5.6347735],
          [101.6626761, 5.6352333],
          [101.662434, 5.6355895],
          [101.6622382, 5.6359111],
          [101.6619045, 5.6363477],
          [101.6614671, 5.6368072],
          [101.6610526, 5.6373241],
          [101.6608912, 5.6377492],
          [101.6606722, 5.6383008],
          [101.6601889, 5.6386453],
          [101.6597288, 5.6388748],
          [101.6591427, 5.6390237],
          [101.6584299, 5.6391954],
          [101.6580161, 5.6392985],
          [101.6576707, 5.639597],
          [101.6575092, 5.6400797],
          [101.6574854, 5.6407235],
          [101.6574501, 5.6412638],
          [101.6574148, 5.6419075],
          [101.6572188, 5.6424592],
          [101.656988, 5.6429993],
          [101.6567919, 5.6436084],
          [101.6570895, 5.6444019],
          [101.6573531, 5.6450229],
          [101.6576506, 5.645805],
          [101.6577993, 5.6465409],
          [101.6579476, 5.6472882],
          [101.6580961, 5.6477482],
          [101.6581875, 5.6482886],
          [101.6584628, 5.6486108],
          [101.6586228, 5.6491513],
          [101.6586568, 5.6497146],
          [101.6586555, 5.6504504],
          [101.6588272, 5.6510713],
          [101.6589411, 5.6517957],
          [101.6590894, 5.6525201],
          [101.6593184, 5.6532561],
          [101.6593405, 5.6538884],
          [101.6595349, 5.6544748],
          [101.6598216, 5.654889],
          [101.6598438, 5.6555098],
          [101.6598548, 5.6560386],
          [101.6597159, 5.6565099],
          [101.6593708, 5.6568199],
          [101.6591749, 5.6573141],
          [101.6592544, 5.6578774],
          [101.6595296, 5.6583375],
          [101.6595863, 5.6588664],
          [101.6595739, 5.6594872],
          [101.6596768, 5.6599931],
          [101.6598827, 5.6605796],
          [101.6596754, 5.6609473],
          [101.6592843, 5.6612918],
          [101.6589504, 5.6615674],
          [101.6585477, 5.6621074],
          [101.6584435, 5.662659],
          [101.6585, 5.6633489],
          [101.6587863, 5.6638435],
          [101.6590155, 5.664407],
          [101.6592442, 5.6652235],
          [101.6593583, 5.6659708],
          [101.6596445, 5.6666838],
          [101.6598155, 5.6677532],
          [101.6598141, 5.6686268],
          [101.6600088, 5.6692134],
          [101.6605145, 5.6690874],
          [101.6609397, 5.6690763],
          [101.6617098, 5.6690195],
          [101.662411, 5.6690547],
          [101.6629514, 5.6689517],
          [101.6635839, 5.6686765],
          [101.6641357, 5.6684471],
          [101.6646416, 5.66839],
          [101.6650549, 5.6685859],
          [101.6653645, 5.669046],
          [101.66572, 5.6696786],
          [101.6661334, 5.6700469],
          [101.6664197, 5.670576],
          [101.6666948, 5.6711626],
          [101.6669353, 5.6719331],
          [101.6671182, 5.672531],
          [101.6674163, 5.6731521],
          [101.667944, 5.6737504],
          [101.668288, 5.6744061],
          [101.6684479, 5.674958],
          [101.6689875, 5.6754873],
          [101.6697227, 5.6756719],
          [101.6702967, 5.6762703],
          [101.6705369, 5.6769603],
          [101.6706048, 5.6777306],
          [101.6715566, 5.6793869],
          [101.6720155, 5.6798702],
          [101.6724404, 5.680319],
          [101.6729797, 5.6808253],
          [101.6737605, 5.6813548],
          [101.6744608, 5.6819993],
          [101.6749082, 5.6825286],
          [101.6753668, 5.6833911],
          [101.6755844, 5.6840582],
          [101.6757443, 5.6847136],
          [101.6760993, 5.6855991],
          [101.6765356, 5.6860824],
          [101.6769371, 5.6866116],
          [101.6772812, 5.6870603],
          [101.6776829, 5.6874055],
          [101.6782806, 5.6874406],
          [101.6790163, 5.6874182],
          [101.6796481, 5.6876258],
          [101.6803944, 5.6882012],
          [101.6809223, 5.6886616],
          [101.6814389, 5.6892139],
          [101.68176, 5.6897775],
          [101.6823343, 5.6900769],
          [101.6829547, 5.6901005],
          [101.6836439, 5.6903425],
          [101.6840459, 5.6906418],
          [101.6843326, 5.6911364],
          [101.6847341, 5.6916196],
          [101.6851471, 5.6921259],
          [101.6856867, 5.6927011],
          [101.6860193, 5.6931958],
          [101.6865014, 5.6935526],
          [101.6869382, 5.6934841],
          [101.6874788, 5.6932317],
          [101.6880074, 5.6932551],
          [101.6885011, 5.6936924],
          [101.6888222, 5.694233],
          [101.6892117, 5.6951071],
          [101.68997, 5.6961532],
          [101.6904392, 5.6970389],
          [101.6908763, 5.6980682],
          [101.6912674, 5.6991086],
          [101.6918751, 5.7002895],
          [101.6927983, 5.7009785],
          [101.6935604, 5.7016889],
          [101.6940485, 5.7022351],
          [101.6942636, 5.7025367],
          [101.6939552, 5.7034662],
          [101.6936134, 5.7042916],
          [101.6933753, 5.7051183],
          [101.6934141, 5.705798],
          [101.6933027, 5.7065568],
          [101.6932733, 5.7071898],
          [101.6930253, 5.7078435],
          [101.6927254, 5.707967],
          [101.6921839, 5.7081226],
          [101.6918485, 5.7083608],
          [101.6913982, 5.7085979],
          [101.6911756, 5.7089986],
          [101.6915019, 5.7096469],
          [101.69176, 5.7102368],
          [101.6920853, 5.7109886],
          [101.6923636, 5.7118091],
          [101.6922174, 5.7126366],
          [101.6917384, 5.7134031],
          [101.6912398, 5.7138469],
          [101.6912604, 5.7140775],
          [101.6912986, 5.7148148],
          [101.6911861, 5.7157002],
          [101.691203, 5.7162877],
          [101.6911601, 5.7170817],
          [101.6911966, 5.7179918],
          [101.6914175, 5.7188346],
          [101.6915817, 5.7196193],
          [101.691426, 5.7202395],
          [101.6909733, 5.7206838],
          [101.6904649, 5.7209893],
          [101.6895147, 5.721797],
          [101.6889276, 5.7230113],
          [101.688529, 5.7238016],
          [101.6881632, 5.724742],
          [101.6886908, 5.725899],
          [101.6894194, 5.7265284],
          [101.6904936, 5.7270463],
          [101.6918557, 5.727475],
          [101.6925397, 5.7279773],
          [101.6930278, 5.7296174],
          [101.6934254, 5.7311185],
          [101.6931272, 5.7321862],
          [101.6925752, 5.7344603],
          [101.6916996, 5.7391261],
          [101.691252, 5.7412977],
          [101.6909281, 5.7426184],
          [101.690747, 5.7434571],
          [101.6905213, 5.7452855],
          [101.6908686, 5.7461297],
          [101.690722, 5.7469803],
          [101.6901244, 5.7480909],
          [101.6905311, 5.7498454],
          [101.6910341, 5.7522572],
          [101.691667, 5.754973],
          [101.69227, 5.755074],
          [101.692737, 5.755099],
          [101.693369, 5.754897],
          [101.694274, 5.75439],
          [101.695756, 5.75406],
          [101.696552, 5.753756],
          [101.697458, 5.753351],
          [101.699077, 5.752515],
          [101.699489, 5.75249],
          [101.701602, 5.752794],
          [101.703222, 5.753275],
          [101.703771, 5.753655],
          [101.704319, 5.754238],
          [101.704731, 5.75439],
          [101.705143, 5.754466],
          [101.705417, 5.754415],
          [101.70635, 5.754871],
          [101.707146, 5.755403],
          [101.707695, 5.755859],
          [101.707805, 5.756518],
          [101.707832, 5.756974],
          [101.708189, 5.757456],
          [101.708573, 5.758545],
          [101.708711, 5.759255],
          [101.709424, 5.760648],
          [101.709946, 5.761307],
          [101.710275, 5.762244],
          [101.71055, 5.762751],
          [101.711098, 5.762827],
          [101.713101, 5.762776],
          [101.713787, 5.762902],
          [101.714419, 5.763435],
          [101.715462, 5.764778],
          [101.715983, 5.766095],
          [101.716038, 5.766729],
          [101.715901, 5.767615],
          [101.715928, 5.769769],
          [101.717026, 5.773899],
          [101.717547, 5.775648],
          [101.717465, 5.776509],
          [101.717465, 5.777447],
          [101.717575, 5.778385],
          [101.71752, 5.779373],
          [101.717383, 5.779879],
          [101.717465, 5.780183],
          [101.717602, 5.780361],
          [101.717685, 5.780918],
          [101.717355, 5.782236],
          [101.71752, 5.782945],
          [101.717547, 5.783553],
          [101.717383, 5.784339],
          [101.71741, 5.784998],
          [101.717547, 5.786087],
          [101.717575, 5.786923],
          [101.717794, 5.787405],
          [101.718069, 5.78781],
          [101.718426, 5.788038],
          [101.718947, 5.78819],
          [101.719469, 5.788241],
          [101.7201, 5.788139],
          [101.720542, 5.787894],
          [101.720731, 5.787633],
          [101.720676, 5.78705],
          [101.720841, 5.786264],
          [101.721664, 5.784313],
          [101.721856, 5.78368],
          [101.721884, 5.782945],
          [101.721719, 5.78221],
          [101.721719, 5.781678],
          [101.721911, 5.781096],
          [101.722241, 5.780487],
          [101.722625, 5.78031],
          [101.723091, 5.780437],
          [101.723476, 5.780716],
          [101.723585, 5.781121],
          [101.724491, 5.781729],
          [101.725424, 5.782236],
          [101.725836, 5.782236],
          [101.725836, 5.782768],
          [101.726056, 5.783275],
          [101.72644, 5.783857],
          [101.726577, 5.784668],
          [101.726824, 5.785099],
          [101.727153, 5.785378],
          [101.72762, 5.785378],
          [101.728224, 5.785226],
          [101.728563, 5.7849],
          [101.728827, 5.784567],
          [101.729349, 5.784339],
          [101.730255, 5.784035],
          [101.731133, 5.783807],
          [101.731627, 5.783807],
          [101.732066, 5.784136],
          [101.732834, 5.784541],
          [101.733274, 5.784719],
          [101.73396, 5.784617],
          [101.734975, 5.784288],
          [101.735543, 5.783976],
          [101.735799, 5.78368],
          [101.735936, 5.783325],
          [101.736128, 5.782996],
          [101.73643, 5.782819],
          [101.737555, 5.782819],
          [101.738214, 5.782666],
          [101.738735, 5.782413],
          [101.739229, 5.782464],
          [101.739998, 5.782869],
          [101.740547, 5.7833],
          [101.741315, 5.78406],
          [101.741617, 5.784693],
          [101.741727, 5.785226],
          [101.741699, 5.785631],
          [101.741452, 5.786011],
          [101.74148, 5.786341],
          [101.741645, 5.786619],
          [101.742221, 5.78705],
          [101.74277, 5.787633],
          [101.743346, 5.788596],
          [101.743648, 5.789254],
          [101.744307, 5.790192],
          [101.744664, 5.790825],
          [101.744719, 5.79118],
          [101.744554, 5.792042],
          [101.744225, 5.792599],
          [101.744252, 5.792979],
          [101.744527, 5.793739],
          [101.744691, 5.794043],
          [101.744966, 5.794069],
          [101.745405, 5.794195],
          [101.745954, 5.794525],
          [101.746338, 5.794575],
          [101.746805, 5.794449],
          [101.747408, 5.79417],
          [101.747793, 5.793714],
          [101.748061, 5.793176],
          [101.748341, 5.792928],
          [101.7485663, 5.7933013],
          [101.7494983, 5.7940542],
          [101.749494, 5.794702],
          [101.749851, 5.795285],
          [101.750125, 5.795302],
          [101.751043, 5.795615],
          [101.751929, 5.796106],
          [101.752815, 5.79679],
          [101.753234, 5.796998],
          [101.753589, 5.797072],
          [101.754056, 5.796998],
          [101.755184, 5.796582],
          [101.755602, 5.796537],
          [101.756827, 5.795942],
          [101.757697, 5.795585],
          [101.758422, 5.795377],
          [101.759018, 5.795317],
          [101.760291, 5.795392],
          [101.760694, 5.795377],
          [101.761, 5.795243],
          [101.761612, 5.794737],
          [101.762063, 5.794172],
          [101.762675, 5.793533],
          [101.763658, 5.792298],
          [101.764254, 5.791896],
          [101.764464, 5.791658],
          [101.764705, 5.791302],
          [101.765172, 5.790364],
          [101.765237, 5.790112],
          [101.765414, 5.789889],
          [101.765736, 5.789606],
          [101.766122, 5.789442],
          [101.767009, 5.788922],
          [101.767556, 5.788833],
          [101.768491, 5.788803],
          [101.768926, 5.788966],
          [101.769184, 5.788996],
          [101.769393, 5.788877],
          [101.769538, 5.788684],
          [101.769586, 5.788282],
          [101.769683, 5.788104],
          [101.769973, 5.787881],
          [101.770424, 5.787672],
          [101.770682, 5.787613],
          [101.771423, 5.786869],
          [101.771987, 5.786661],
          [101.77268, 5.786497],
          [101.773485, 5.786408],
          [101.773759, 5.786304],
          [101.774017, 5.785962],
          [101.774806, 5.784489],
          [101.775064, 5.7844],
          [101.775741, 5.784519],
          [101.776015, 5.784534],
          [101.776144, 5.784474],
          [101.776562, 5.783686],
          [101.776611, 5.783344],
          [101.776595, 5.781128],
          [101.776514, 5.7808],
          [101.775934, 5.779834],
          [101.77558, 5.778852],
          [101.775467, 5.778465],
          [101.775354, 5.777573],
          [101.775225, 5.777365],
          [101.775, 5.777216],
          [101.774855, 5.776963],
          [101.774822, 5.77677],
          [101.774839, 5.776383],
          [101.775, 5.775773],
          [101.774371, 5.774137],
          [101.774162, 5.773453],
          [101.77421, 5.77317],
          [101.774387, 5.772813],
          [101.7745, 5.771638],
          [101.774565, 5.771326],
          [101.775209, 5.770448],
          [101.775322, 5.769392],
          [101.775419, 5.769035],
          [101.775709, 5.768411],
          [101.776901, 5.766656],
          [101.7774, 5.765793],
          [101.777819, 5.765243],
          [101.778303, 5.764722],
          [101.781283, 5.761999],
          [101.782813, 5.760304],
          [101.783361, 5.759947],
          [101.783812, 5.759575],
          [101.784376, 5.759025],
          [101.784892, 5.758638],
          [101.785697, 5.758281],
          [101.7861, 5.758013],
          [101.786825, 5.757225],
          [101.787631, 5.756868],
          [101.788146, 5.755574],
          [101.788533, 5.75483],
          [101.788839, 5.754384],
          [101.789709, 5.753447],
          [101.790273, 5.753045],
          [101.790949, 5.752718],
          [101.791368, 5.752421],
          [101.791755, 5.752049],
          [101.792367, 5.751156],
          [101.792786, 5.750874],
          [101.792915, 5.75071],
          [101.793124, 5.750636],
          [101.793318, 5.750665],
          [101.793559, 5.750859],
          [101.794526, 5.75135],
          [101.794735, 5.751305],
          [101.795009, 5.751126],
          [101.795573, 5.750948],
          [101.79654, 5.750769],
          [101.798344, 5.750636],
          [101.79894, 5.750517],
          [101.799359, 5.750264],
          [101.800261, 5.749268],
          [101.800616, 5.748747],
          [101.801147, 5.747394],
          [101.801518, 5.746769],
          [101.801969, 5.746144],
          [101.803805, 5.744612],
          [101.804595, 5.744136],
          [101.804998, 5.743943],
          [101.805417, 5.743928],
          [101.806625, 5.744092],
          [101.807382, 5.744092],
          [101.808107, 5.744032],
          [101.80859, 5.743868],
          [101.809315, 5.743437],
          [101.810298, 5.742515],
          [101.811958, 5.741176],
          [101.812183, 5.740938],
          [101.81228, 5.7407],
          [101.812248, 5.739674],
          [101.812312, 5.739421],
          [101.812667, 5.738856],
          [101.813488, 5.738306],
          [101.81394, 5.737949],
          [101.814358, 5.737532],
          [101.814665, 5.737027],
          [101.814858, 5.736551],
          [101.815116, 5.734453],
          [101.815373, 5.733888],
          [101.815615, 5.733531],
          [101.815953, 5.733174],
          [101.816356, 5.73304],
          [101.817145, 5.732966],
          [101.817999, 5.732981],
          [101.818692, 5.73307],
          [101.819143, 5.733204],
          [101.820078, 5.73362],
          [101.820271, 5.733635],
          [101.820513, 5.733531],
          [101.821093, 5.733412],
          [101.821592, 5.733382],
          [101.822156, 5.733263],
          [101.822478, 5.733159],
          [101.822752, 5.732981],
          [101.82309, 5.732906],
          [101.824379, 5.733114],
          [101.824959, 5.733427],
          [101.825523, 5.733814],
          [101.826103, 5.734423],
          [101.826474, 5.734914],
          [101.826603, 5.735271],
          [101.826619, 5.736922],
          [101.826683, 5.737443],
          [101.82686, 5.738038],
          [101.82686, 5.738409],
          [101.826393, 5.73951],
          [101.825797, 5.740194],
          [101.825556, 5.740536],
          [101.825233, 5.742024],
          [101.824879, 5.742723],
          [101.824734, 5.743214],
          [101.824508, 5.745564],
          [101.824476, 5.746799],
          [101.824573, 5.747319],
          [101.824686, 5.748494],
          [101.824911, 5.749654],
          [101.824847, 5.750323],
          [101.824605, 5.751484],
          [101.824041, 5.753045],
          [101.823816, 5.753254],
          [101.823236, 5.753655],
          [101.822913, 5.753804],
          [101.822495, 5.753849],
          [101.821721, 5.753581],
          [101.82127, 5.753373],
          [101.820916, 5.753313],
          [101.819869, 5.753358],
          [101.819579, 5.753462],
          [101.819369, 5.753655],
          [101.819143, 5.754548],
          [101.818757, 5.755202],
          [101.81866, 5.755485],
          [101.818998, 5.756496],
          [101.819176, 5.757419],
          [101.819385, 5.757686],
          [101.819723, 5.757835],
          [101.820062, 5.757924],
          [101.820271, 5.758118],
          [101.820497, 5.758237],
          [101.820851, 5.758281],
          [101.821238, 5.75843],
          [101.821608, 5.758772],
          [101.821979, 5.759204],
          [101.822043, 5.75962],
          [101.821979, 5.759918],
          [101.821818, 5.760304],
          [101.82185, 5.760632],
          [101.822349, 5.76191],
          [101.822446, 5.762089],
          [101.822655, 5.762178],
          [101.822881, 5.762208],
          [101.823074, 5.762372],
          [101.823155, 5.76252],
          [101.823139, 5.762788],
          [101.823268, 5.763056],
          [101.823686, 5.763264],
          [101.8266601, 5.76863],
          [101.8272126, 5.7714909],
          [101.8281545, 5.7717908],
          [101.8298389, 5.7759553],
          [101.8310582, 5.7760429],
          [101.8319471, 5.7779369],
          [101.832377, 5.7789686],
          [101.8319385, 5.7793925],
          [101.8325215, 5.7801083],
          [101.8331018, 5.7801083],
          [101.8345121, 5.7838789],
          [101.8354409, 5.7854275],
          [101.8362628, 5.785918],
          [101.8360441, 5.7864813],
          [101.835293, 5.7865062],
          [101.8353297, 5.7870892],
          [101.8358026, 5.787114],
          [101.8362351, 5.7874937],
          [101.8364232, 5.78934],
          [101.8382042, 5.7892277],
          [101.8399582, 5.7910975],
          [101.8404827, 5.7899794],
          [101.8408187, 5.789928],
          [101.8419264, 5.7908922],
          [101.8427118, 5.7910163],
          [101.8435959, 5.7902731],
          [101.8431958, 5.7894768],
          [101.8431958, 5.7892571],
          [101.8438206, 5.7888638],
          [101.8442098, 5.7890702],
          [101.8439184, 5.7897761],
          [101.8440049, 5.790292],
          [101.8445473, 5.790341],
          [101.8451154, 5.7898511],
          [101.8463142, 5.7906421],
          [101.8484369, 5.7910795],
          [101.85212, 5.7913671],
          [101.8556201, 5.7912544],
          [101.8560775, 5.7919371],
          [101.8558514, 5.7934869],
          [101.8564372, 5.7940077],
          [101.8571589, 5.7942305],
          [101.8576562, 5.7936616],
          [101.8578705, 5.791993],
          [101.8586866, 5.791955],
          [101.8594048, 5.7918886],
          [101.8600727, 5.7916135],
          [101.8628372, 5.7909568],
          [101.8643785, 5.7907584],
          [101.8655008, 5.7908898],
          [101.8664311, 5.7906786],
          [101.8671344, 5.7914541],
          [101.8673905, 5.7918717],
          [101.8669842, 5.7928358],
          [101.8663713, 5.7935255],
          [101.8655908, 5.7937217],
          [101.8652922, 5.7938942],
          [101.8651289, 5.7941166],
          [101.8649322, 5.7942788],
          [101.8639279, 5.7941837],
          [101.8636582, 5.7944812],
          [101.8635696, 5.7950258],
          [101.8636638, 5.7959372],
          [101.8636264, 5.7967449],
          [101.8636406, 5.7972738],
          [101.8633959, 5.7984646],
          [101.8630998, 5.7986083],
          [101.8621049, 5.7986273],
          [101.8615389, 5.798839],
          [101.8610675, 5.7992781],
          [101.8608484, 5.7996323],
          [101.8609365, 5.8000038],
          [101.8614495, 5.8005391],
          [101.8617542, 5.8013682],
          [101.8622974, 5.8018313],
          [101.8630953, 5.8019935],
          [101.8636388, 5.8025998],
          [101.8638617, 5.8029301],
          [101.8641159, 5.803046],
          [101.8643691, 5.8029475],
          [101.864619, 5.8026802],
          [101.8651237, 5.8026197],
          [101.8658204, 5.802918],
          [101.8663175, 5.803524],
          [101.8667418, 5.8035867],
          [101.8670652, 5.8033731],
          [101.8668761, 5.8023298],
          [101.8670896, 5.801964],
          [101.8674892, 5.8018845],
          [101.8679051, 5.8019687],
          [101.8682591, 5.8023331],
          [101.8682037, 5.8029694],
          [101.8684559, 5.8034886],
          [101.8690098, 5.8038958],
          [101.869381, 5.8044867],
          [101.8698769, 5.8046489],
          [101.8703139, 5.8046245],
          [101.8710043, 5.8043995],
          [101.8714282, 5.8041224],
          [101.8719338, 5.8034832],
          [101.8722737, 5.8031887],
          [101.8728383, 5.8032051],
          [101.8736639, 5.8032449],
          [101.8741274, 5.8034876],
          [101.8741189, 5.8038064],
          [101.874019, 5.8040868],
          [101.8740766, 5.8044073],
          [101.874234, 5.8045013],
          [101.8743962, 5.8044798],
          [101.8745167, 5.8041973],
          [101.8748146, 5.8040636],
          [101.8751248, 5.8042413],
          [101.8752307, 5.804542],
          [101.8754611, 5.8049358],
          [101.8759082, 5.8051498],
          [101.8761045, 5.8055271],
          [101.8757953, 5.8059482],
          [101.875575, 5.8062988],
          [101.875481, 5.8066537],
          [101.8758417, 5.8068779],
          [101.8759532, 5.8068441],
          [101.8765432, 5.8060351],
          [101.8769374, 5.8059313],
          [101.8770584, 5.8062603],
          [101.8769375, 5.8065373],
          [101.8764041, 5.807123],
          [101.8763138, 5.8078329],
          [101.8764641, 5.8080801],
          [101.8770506, 5.8085019],
          [101.8774064, 5.8092483],
          [101.87761, 5.8096048],
          [101.8778234, 5.8097353],
          [101.8784293, 5.8095246],
          [101.8787492, 5.8095098],
          [101.8788969, 5.8096741],
          [101.8787555, 5.8098786],
          [101.8783575, 5.8100918],
          [101.878293, 5.810338],
          [101.8783896, 5.8105702],
          [101.8788855, 5.8109278],
          [101.879368, 5.8109415],
          [101.8797031, 5.8103634],
          [101.8799697, 5.8097368],
          [101.8802097, 5.8096048],
          [101.8804758, 5.8097093],
          [101.8807532, 5.8098799],
          [101.8809818, 5.8101814],
          [101.880993, 5.810727],
          [101.8808375, 5.8109744],
          [101.8803097, 5.8113715],
          [101.8802606, 5.8115637],
          [101.8803199, 5.8117457],
          [101.8806471, 5.8120259],
          [101.8807938, 5.8121771],
          [101.8806467, 5.8126885],
          [101.8799113, 5.8134687],
          [101.8790548, 5.8135968],
          [101.8787429, 5.813965],
          [101.8788018, 5.8144535],
          [101.8791421, 5.8148827],
          [101.8798155, 5.8153905],
          [101.8799727, 5.8158088],
          [101.8799065, 5.816151],
          [101.8795447, 5.8164633],
          [101.8788895, 5.817293],
          [101.8779858, 5.8188501],
          [101.8776635, 5.819112],
          [101.8771555, 5.8192979],
          [101.8767936, 5.8196555],
          [101.8767073, 5.8199369],
          [101.8767757, 5.8203731],
          [101.8775106, 5.8216595],
          [101.8781393, 5.8227834],
          [101.8783105, 5.8232912],
          [101.8782362, 5.8241971],
          [101.8783465, 5.8251102],
          [101.8789356, 5.8277438],
          [101.8800196, 5.8317958],
          [101.8804817, 5.8326508],
          [101.8806901, 5.8328272],
          [101.8810712, 5.8329655],
          [101.8823173, 5.8329774],
          [101.8832251, 5.8333909],
          [101.884752, 5.8347463],
          [101.8849715, 5.8350562],
          [101.8848982, 5.8355473],
          [101.8841345, 5.8367752],
          [101.8840571, 5.8370897],
          [101.8841266, 5.8374855],
          [101.8842974, 5.8377255],
          [101.8855812, 5.8382984],
          [101.8865928, 5.8389643],
          [101.8868848, 5.8390136],
          [101.8873893, 5.8388526],
          [101.8888599, 5.8374989],
          [101.8892381, 5.8373448],
          [101.8895376, 5.8373544],
          [101.890072, 5.8374596],
          [101.8910569, 5.837855],
          [101.8917591, 5.8379408],
          [101.8920304, 5.8384295],
          [101.8925095, 5.8396343],
          [101.8929492, 5.8409685],
          [101.8938693, 5.8424663],
          [101.8954135, 5.8437525],
          [101.8955118, 5.8443942],
          [101.8953728, 5.8453549],
          [101.895463, 5.8455801],
          [101.8957648, 5.8456768],
          [101.8964632, 5.8455719],
          [101.8968227, 5.84527],
          [101.8970613, 5.844811],
          [101.8970575, 5.8443393],
          [101.8968826, 5.8436742],
          [101.896837, 5.8430925],
          [101.8970862, 5.8426229],
          [101.8972995, 5.8425156],
          [101.8975361, 5.8424911],
          [101.8984957, 5.8426685],
          [101.8993478, 5.8433599],
          [101.899402, 5.8436026],
          [101.8993648, 5.8439701],
          [101.8995456, 5.8441386],
          [101.8997566, 5.8441516],
          [101.9004357, 5.8438083],
          [101.9010189, 5.8432958],
          [101.9016083, 5.842163],
          [101.901854, 5.8421293],
          [101.9020943, 5.8421282],
          [101.902952, 5.8424547],
          [101.9036598, 5.8426364],
          [101.9042752, 5.8425799],
          [101.9045703, 5.8423704],
          [101.9047668, 5.8421226],
          [101.9051972, 5.8411394],
          [101.9056544, 5.840861],
          [101.906079, 5.840913],
          [101.9064418, 5.8410385],
          [101.9073309, 5.8413886],
          [101.9085306, 5.8413588],
          [101.9095879, 5.8416206],
          [101.9102576, 5.8419474],
          [101.911423, 5.8419905],
          [101.9124199, 5.8418189],
          [101.9129802, 5.8419218],
          [101.9146396, 5.8427003],
          [101.9154585, 5.8427939],
          [101.9165579, 5.842577],
          [101.9171624, 5.8427438],
          [101.9174379, 5.8431062],
          [101.9174805, 5.8437002],
          [101.9173438, 5.8443603],
          [101.9168628, 5.845154],
          [101.9168293, 5.8453495],
          [101.9170641, 5.8456379],
          [101.9172675, 5.8456963],
          [101.9176371, 5.8456882],
          [101.919149, 5.845297],
          [101.9205895, 5.8454606],
          [101.9211164, 5.8457738],
          [101.9213417, 5.8461362],
          [101.9213901, 5.8465926],
          [101.9213968, 5.8470182],
          [101.9215627, 5.8475618],
          [101.9218569, 5.8478286],
          [101.9226597, 5.8481816],
          [101.923048, 5.8485478],
          [101.9234983, 5.8492909],
          [101.924181, 5.8497515],
          [101.9249914, 5.8497931],
          [101.9252502, 5.8496763],
          [101.9255162, 5.8494117],
          [101.9257798, 5.8493354],
          [101.9262226, 5.8494166],
          [101.9266329, 5.8497097],
          [101.926889, 5.8500341],
          [101.9269612, 5.8508825],
          [101.9271005, 5.8513401],
          [101.927359, 5.8515875],
          [101.9288622, 5.8520691],
          [101.9291994, 5.8523963],
          [101.9298807, 5.8532182],
          [101.9299998, 5.8536097],
          [101.92989, 5.8541366],
          [101.9294798, 5.8546372],
          [101.9291707, 5.8547659],
          [101.9281115, 5.8544846],
          [101.9276601, 5.854453],
          [101.9274572, 5.8545752],
          [101.9272571, 5.8552262],
          [101.9275196, 5.8562797],
          [101.927589, 5.8572547],
          [101.927828, 5.8577308],
          [101.9292144, 5.8590409],
          [101.9301117, 5.8604838],
          [101.9308244, 5.8613534],
          [101.931754, 5.8617745],
          [101.9336886, 5.8622616],
          [101.934931, 5.86249],
          [101.9354015, 5.8626762],
          [101.9359967, 5.8632389],
          [101.9362513, 5.8634142],
          [101.936518, 5.86345],
          [101.9368293, 5.863324],
          [101.937005, 5.8631333],
          [101.937213, 5.8626756],
          [101.9370069, 5.8618508],
          [101.9372157, 5.8611741],
          [101.9376132, 5.8610117],
          [101.9379834, 5.861163],
          [101.9383577, 5.8616958],
          [101.938359, 5.8624192],
          [101.9385453, 5.8632859],
          [101.9393974, 5.8650443],
          [101.9402779, 5.8662299],
          [101.9414244, 5.86704],
          [101.9418295, 5.8677146],
          [101.9418343, 5.8680659],
          [101.9413132, 5.868926],
          [101.9406769, 5.8697301],
          [101.9405967, 5.8701704],
          [101.9406922, 5.8706721],
          [101.9411718, 5.8711168],
          [101.9415476, 5.8712421],
          [101.942811, 5.8712074],
          [101.9434287, 5.8713546],
          [101.9437371, 5.8716439],
          [101.9439863, 5.8719906],
          [101.9440243, 5.8724731],
          [101.9437644, 5.8727782],
          [101.9429285, 5.8734795],
          [101.9424966, 5.8742632],
          [101.9422063, 5.8763909],
          [101.9418796, 5.8770807],
          [101.9412673, 5.8775728],
          [101.9398418, 5.8784898],
          [101.9395478, 5.8789113],
          [101.9395111, 5.8797628],
          [101.9398359, 5.8806153],
          [101.9407849, 5.8829592],
          [101.9411381, 5.8848038],
          [101.9408863, 5.8854825],
          [101.9404337, 5.8859293],
          [101.9396147, 5.8863521],
          [101.9391505, 5.8869693],
          [101.9391033, 5.8876754],
          [101.939397, 5.8882254],
          [101.9397241, 5.8884039],
          [101.9403646, 5.8883938],
          [101.9406444, 5.8885085],
          [101.9408641, 5.88919],
          [101.9406135, 5.8896028],
          [101.9401849, 5.8897824],
          [101.9397521, 5.8897525],
          [101.9392277, 5.8894971],
          [101.9382395, 5.8885072],
          [101.9378168, 5.8877763],
          [101.937515, 5.887604],
          [101.9370233, 5.887604],
          [101.9368262, 5.8878163],
          [101.9367432, 5.8882219],
          [101.9369107, 5.8888666],
          [101.9375857, 5.8896884],
          [101.9387397, 5.8906264],
          [101.9389997, 5.8911298],
          [101.9389478, 5.8917004],
          [101.9386368, 5.8920819],
          [101.9383772, 5.8923093],
          [101.9379909, 5.8926477],
          [101.9370853, 5.8939655],
          [101.9359626, 5.894918],
          [101.9357717, 5.8953064],
          [101.935406, 5.8963372],
          [101.9348396, 5.8970689],
          [101.9346596, 5.8974749],
          [101.9347149, 5.897963],
          [101.9348797, 5.898202],
          [101.9357665, 5.8987032],
          [101.935818, 5.8990873],
          [101.9355684, 5.8993577],
          [101.9341148, 5.8996066],
          [101.9337548, 5.9000265],
          [101.9337702, 5.9006951],
          [101.9346715, 5.9012528],
          [101.9346969, 5.9019739],
          [101.9343244, 5.9026719],
          [101.9338027, 5.9031531],
          [101.9325737, 5.9046532],
          [101.9325069, 5.90493],
          [101.9324896, 5.9054456],
          [101.9325363, 5.9056455],
          [101.9332578, 5.9060418],
          [101.9334606, 5.9064836],
          [101.9332322, 5.9068444],
          [101.9325725, 5.9069854],
          [101.9320576, 5.9066658],
          [101.9313092, 5.9065145],
          [101.9308852, 5.9061345],
          [101.9308326, 5.9052137],
          [101.9307929, 5.9048338],
          [101.9306272, 5.904688],
          [101.9300279, 5.9050499],
          [101.9298634, 5.9057378],
          [101.9300119, 5.9060837],
          [101.9308314, 5.9071481],
          [101.9310003, 5.9077829],
          [101.9303962, 5.9095912],
          [101.9300907, 5.9100471],
          [101.9294902, 5.9101854],
          [101.9288613, 5.9098726],
          [101.9283758, 5.9098995],
          [101.9277181, 5.9103272],
          [101.9275483, 5.910615],
          [101.9269436, 5.910701],
          [101.9262738, 5.9102074],
          [101.926299, 5.909643],
          [101.9266602, 5.9092952],
          [101.9266925, 5.90879],
          [101.9265427, 5.9084978],
          [101.9261695, 5.9083465],
          [101.925856, 5.9083924],
          [101.9255083, 5.9086619],
          [101.9244189, 5.9102252],
          [101.9242042, 5.9103103],
          [101.9236582, 5.9103608],
          [101.92326, 5.910151],
          [101.9228972, 5.9098262],
          [101.9225793, 5.9097901],
          [101.9221916, 5.9099429],
          [101.9219042, 5.9106216],
          [101.9219752, 5.911251],
          [101.9225441, 5.9122535],
          [101.9223962, 5.912911],
          [101.9218226, 5.9134953],
          [101.9213999, 5.9145608],
          [101.9213284, 5.9151348],
          [101.9214667, 5.9155747],
          [101.9217504, 5.915892],
          [101.9223531, 5.9163486],
          [101.9224791, 5.9166024],
          [101.9224558, 5.9171135],
          [101.9220446, 5.9175378],
          [101.921636, 5.9178019],
          [101.9214114, 5.9181525],
          [101.9216575, 5.918507],
          [101.9226518, 5.9189111],
          [101.9241434, 5.9194915],
          [101.9244971, 5.9199535],
          [101.9245396, 5.9206775],
          [101.9246016, 5.9213971],
          [101.924635, 5.9217848],
          [101.9242261, 5.9225048],
          [101.923223, 5.923011],
          [101.9232713, 5.9235018],
          [101.9235641, 5.923882],
          [101.9252302, 5.9247965],
          [101.9259622, 5.9248925],
          [101.9262413, 5.9247706],
          [101.9263903, 5.9245442],
          [101.926598, 5.923827],
          [101.9270636, 5.9232328],
          [101.9275172, 5.9231087],
          [101.9281046, 5.9234215],
          [101.9281624, 5.9237197],
          [101.928115, 5.9247413],
          [101.9284353, 5.9252328],
          [101.9291689, 5.9256246],
          [101.9290553, 5.9263024],
          [101.9286229, 5.9272469],
          [101.9285538, 5.9275871],
          [101.9287166, 5.9279008],
          [101.9295777, 5.928252],
          [101.9296957, 5.9285412],
          [101.9298358, 5.9296699],
          [101.9296872, 5.9303367],
          [101.9296866, 5.9308358],
          [101.9300051, 5.9314125],
          [101.9305239, 5.9315652],
          [101.9312491, 5.9321033],
          [101.9312871, 5.9323714],
          [101.931225, 5.9329302],
          [101.931119, 5.933302],
          [101.9304232, 5.9341829],
          [101.9298349, 5.9344574],
          [101.9294401, 5.9347858],
          [101.9292172, 5.9351128],
          [101.9294231, 5.9361551],
          [101.9304289, 5.9363261],
          [101.9306958, 5.9359521],
          [101.9308156, 5.9357671],
          [101.9310346, 5.9355379],
          [101.9314768, 5.9353507],
          [101.9319993, 5.9354719],
          [101.9320734, 5.9359941],
          [101.93188, 5.9364614],
          [101.9313683, 5.9370991],
          [101.9305874, 5.9372391],
          [101.9297113, 5.9371852],
          [101.9292284, 5.9371835],
          [101.9290415, 5.9372409],
          [101.9291809, 5.9376913],
          [101.9294541, 5.9386605],
          [101.9297991, 5.939796],
          [101.930171, 5.940231],
          [101.930973, 5.940613],
          [101.9316852, 5.9408627],
          [101.9320114, 5.9411791],
          [101.932143, 5.9415123],
          [101.9321482, 5.9417394],
          [101.9318303, 5.9421825],
          [101.9314692, 5.9423114],
          [101.9304499, 5.9425279],
          [101.9302253, 5.9427891],
          [101.9304074, 5.9431295],
          [101.9312154, 5.9438019],
          [101.9320112, 5.9442984],
          [101.9323224, 5.9449792],
          [101.932082, 5.9456505],
          [101.9314275, 5.9457632],
          [101.9308136, 5.9456106],
          [101.9304027, 5.9451022],
          [101.9303163, 5.9447294],
          [101.9304026, 5.9442689],
          [101.9301919, 5.9440214],
          [101.9297841, 5.9438427],
          [101.9294831, 5.9439584],
          [101.9290135, 5.9450697],
          [101.9292174, 5.9456196],
          [101.9294218, 5.9458168],
          [101.9299461, 5.9464461],
          [101.9299707, 5.9467984],
          [101.9296692, 5.9477463],
          [101.9294312, 5.9487408],
          [101.9297047, 5.9492726],
          [101.9303021, 5.9495291],
          [101.9318023, 5.9495307],
          [101.9335295, 5.9490337],
          [101.9351396, 5.9494595],
          [101.9361022, 5.9491541],
          [101.9365452, 5.947917],
          [101.937029, 5.9479944],
          [101.9375458, 5.9484434],
          [101.9382946, 5.9493969],
          [101.9390038, 5.9499812],
          [101.9401025, 5.9504239],
          [101.9407676, 5.9511822],
          [101.9414438, 5.9530072],
          [101.9419767, 5.9533962],
          [101.9425995, 5.9534755],
          [101.943317, 5.9532723],
          [101.9439847, 5.9538125],
          [101.9443066, 5.9542977],
          [101.9445986, 5.9550175],
          [101.944692, 5.9566862],
          [101.9444647, 5.9579864],
          [101.9436508, 5.9588772],
          [101.9429442, 5.959196],
          [101.9417792, 5.9588586],
          [101.9415283, 5.9589664],
          [101.9412841, 5.9591894],
          [101.9414483, 5.9596884],
          [101.942116, 5.9603192],
          [101.9422457, 5.9611072],
          [101.9416953, 5.9621753],
          [101.941774, 5.9632748],
          [101.942854, 5.9647368],
          [101.9427804, 5.9656684],
          [101.942166, 5.9663899],
          [101.9410193, 5.9677357],
          [101.9407294, 5.9687924],
          [101.9407971, 5.9694755],
          [101.9410993, 5.9706798],
          [101.94083, 5.9716659],
          [101.9397901, 5.972522],
          [101.9398029, 5.9728314],
          [101.9411135, 5.9732014],
          [101.9419617, 5.973288],
          [101.9423011, 5.9736537],
          [101.9427733, 5.9748335],
          [101.943322, 5.9753099],
          [101.9440528, 5.9756111],
          [101.9452968, 5.9753607],
          [101.9458282, 5.9757856],
          [101.9466596, 5.9771233],
          [101.9466723, 5.9777413],
          [101.9463671, 5.9783862],
          [101.9455741, 5.978531],
          [101.9451137, 5.9781809],
          [101.9449117, 5.9774652],
          [101.9444544, 5.9770942],
          [101.9439218, 5.977402],
          [101.9436757, 5.9779462],
          [101.9436949, 5.9798101],
          [101.9426496, 5.9810941],
          [101.9429087, 5.9819305],
          [101.9440644, 5.9822575],
          [101.9444309, 5.9826217],
          [101.9453676, 5.9846566],
          [101.9454097, 5.9862168],
          [101.945609, 5.9868065],
          [101.9458541, 5.9871138],
          [101.9462477, 5.9874161],
          [101.9466055, 5.9874161],
          [101.9469197, 5.9871122],
          [101.9475223, 5.9862168],
          [101.9476573, 5.985232],
          [101.9473042, 5.983915],
          [101.9474744, 5.9833105],
          [101.9480056, 5.9830602],
          [101.9486932, 5.9835161],
          [101.9492514, 5.9842366],
          [101.9492463, 5.9848194],
          [101.9488875, 5.985533],
          [101.9485897, 5.986892],
          [101.9489224, 5.9886687],
          [101.948743, 5.9890513],
          [101.9477963, 5.9899879],
          [101.9481446, 5.9908325],
          [101.9488857, 5.9910711],
          [101.9499602, 5.9910293],
          [101.9506035, 5.9903999],
          [101.9508054, 5.9896813],
          [101.9503704, 5.9880375],
          [101.9504172, 5.9875944],
          [101.9506364, 5.9871429],
          [101.9512841, 5.9868511],
          [101.9519647, 5.9869425],
          [101.9524066, 5.9874813],
          [101.9530359, 5.9889451],
          [101.9526423, 5.9908752],
          [101.9532793, 5.9917471],
          [101.95389, 5.9918957],
          [101.9552638, 5.9917538],
          [101.9558373, 5.9919312],
          [101.9559977, 5.9924904],
          [101.9556766, 5.9929504],
          [101.9548426, 5.9932646],
          [101.9543385, 5.9936499],
          [101.9540197, 5.9942544],
          [101.9539399, 5.9948739],
          [101.9540197, 5.9952602],
          [101.954353, 5.9956287],
          [101.9553131, 5.9956039],
          [101.9561123, 5.9951613],
          [101.9568148, 5.9937986],
          [101.9576267, 5.9930816],
          [101.9583514, 5.9931068],
          [101.959295, 5.9941765],
          [101.9594257, 5.9949433],
          [101.9591965, 5.9966627],
          [101.9595025, 5.9971853],
          [101.9600804, 5.9972545],
          [101.9607414, 5.9970685],
          [101.9617651, 5.9959997],
          [101.9625099, 5.9957113],
          [101.9632745, 5.9960417],
          [101.9642189, 5.9969948],
          [101.9650561, 5.9977316],
          [101.9653848, 5.9985259],
          [101.9652453, 5.9992445],
          [101.9647371, 5.9999233],
          [101.9650161, 6.0008004],
          [101.965494, 6.0010603],
          [101.9669195, 6.0008896],
          [101.9675473, 6.0010085],
          [101.9680364, 6.0017815],
          [101.9678961, 6.0028123],
          [101.9680227, 6.0034677],
          [101.9684581, 6.0039075],
          [101.9695431, 6.0041949],
          [101.9705269, 6.0042493],
          [101.9711945, 6.0046308],
          [101.9722658, 6.0055277],
          [101.972651, 6.0063477],
          [101.9726245, 6.0072621],
          [101.9728076, 6.0097547],
          [101.971487, 6.0105177],
          [101.9713141, 6.0108298],
          [101.9713733, 6.0118363],
          [101.972207, 6.0136606],
          [101.9721987, 6.0150695],
          [101.9718151, 6.0157518],
          [101.9712769, 6.0168175],
          [101.9709175, 6.0180613],
          [101.971062, 6.0192947],
          [101.9715092, 6.0198123],
          [101.9728948, 6.0202155],
          [101.9733859, 6.0206244],
          [101.9747437, 6.022406],
          [101.9750838, 6.0226509],
          [101.9758333, 6.0231064],
          [101.9780806, 6.0274529],
          [101.9783285, 6.0285872],
          [101.9778638, 6.0304839],
          [101.9778788, 6.0308078],
          [101.9780478, 6.0309646],
          [101.9782482, 6.0309587],
          [101.9784507, 6.0308717],
          [101.9786911, 6.030592],
          [101.979855, 6.0290749],
          [101.9807138, 6.0285794],
          [101.9822162, 6.0287568],
          [101.9834264, 6.0292871],
          [101.9840307, 6.0296964],
          [101.9840767, 6.0309998],
          [101.9839195, 6.0319842],
          [101.9844962, 6.032801],
          [101.9858928, 6.0336418],
          [101.986753, 6.0347552],
          [101.9875567, 6.0365773],
          [101.9886379, 6.0377565],
          [101.989497, 6.0380201],
          [101.9902391, 6.0382243],
          [101.9904655, 6.038597],
          [101.9906065, 6.0398611],
          [101.9909656, 6.0403462],
          [101.9914364, 6.040555],
          [101.9950204, 6.0407696],
          [101.9963151, 6.0404573],
          [101.9979002, 6.0396541],
          [101.998713, 6.0396187],
          [101.9990262, 6.0397122],
          [102.0004301, 6.0409373],
          [102.0005293, 6.0416953],
          [102.0008721, 6.042655],
          [102.0015524, 6.0431994],
          [102.0042284, 6.0444341],
          [102.0045563, 6.0473432],
          [102.0034746, 6.0492819],
          [102.0034497, 6.0503364],
          [102.0060801, 6.0515323],
          [102.0066568, 6.0511583],
          [102.0116782, 6.0514085],
          [102.013191, 6.0519978],
          [102.0141344, 6.053361],
          [102.0159767, 6.0542459],
          [102.0201183, 6.0524307],
          [102.0204749, 6.0533298],
          [102.020123, 6.0550419],
          [102.0213796, 6.0570663],
          [102.02165, 6.0603799],
          [102.0224526, 6.0617766],
          [102.0259086, 6.0634482],
          [102.0268382, 6.0638987],
          [102.0270356, 6.0641759],
          [102.0271413, 6.0644151],
          [102.0272741, 6.0647296],
          [102.0274685, 6.0649886],
          [102.0278459, 6.0652411],
          [102.0281858, 6.0654073],
          [102.0285476, 6.0654689],
          [102.0290875, 6.0654265],
          [102.0293979, 6.065466],
          [102.029588, 6.0655527],
          [102.0297259, 6.0657412],
          [102.0298455, 6.0661088],
          [102.0298951, 6.0665099],
          [102.0299753, 6.0668343],
          [102.030117, 6.0671172],
          [102.0303251, 6.067497],
          [102.0306322, 6.0679625],
          [102.0310089, 6.0685356],
          [102.0312663, 6.0690301],
          [102.0314219, 6.0695273],
          [102.0315088, 6.0699754],
          [102.0315624, 6.0705432],
          [102.0316643, 6.0709945],
          [102.0317834, 6.0712247],
          [102.0319213, 6.0713524],
          [102.0320422, 6.0713897],
          [102.0321715, 6.0713907],
          [102.0323333, 6.071355],
          [102.0324714, 6.0712907],
          [102.0326361, 6.071175],
          [102.0330135, 6.070763],
          [102.0333324, 6.0704237],
          [102.0334644, 6.0702157],
          [102.0335247, 6.0699519],
          [102.033499, 6.0697708],
          [102.0335092, 6.0696279],
          [102.0335649, 6.0694652],
          [102.0337178, 6.0693835],
          [102.0338772, 6.0693555],
          [102.0340212, 6.0693787],
          [102.0342564, 6.0694113],
          [102.034475, 6.0695294],
          [102.0346652, 6.069714],
          [102.0351375, 6.0702424],
          [102.0356855, 6.0707166],
          [102.0360972, 6.0710428],
          [102.0365856, 6.0713615],
          [102.037054, 6.0716328],
          [102.0376371, 6.0719664],
          [102.0379732, 6.0721606],
          [102.0389774, 6.0729479],
          [102.0392349, 6.0731354],
          [102.039414, 6.0731907],
          [102.0395701, 6.0731944],
          [102.0397195, 6.0731472],
          [102.0400803, 6.0728426],
          [102.0402466, 6.0727407],
          [102.0404333, 6.0727015],
          [102.0406025, 6.0727351],
          [102.0407498, 6.0728405],
          [102.0412243, 6.073415],
          [102.0414289, 6.0736283],
          [102.0415906, 6.0737566],
          [102.041773, 6.0738369],
          [102.0420185, 6.0738574],
          [102.0424146, 6.0738228],
          [102.042696, 6.0738158],
          [102.042936, 6.0738622],
          [102.0432155, 6.0739521],
          [102.0436734, 6.0741554],
          [102.0440714, 6.0744013],
          [102.0442117, 6.0745632],
          [102.0442611, 6.0747328],
          [102.0442431, 6.0748926],
          [102.0441613, 6.0750641],
          [102.0435243, 6.0757695],
          [102.0434605, 6.0759231],
          [102.0434521, 6.0760805],
          [102.0434897, 6.0762],
          [102.043594, 6.0763048],
          [102.0437952, 6.0763424],
          [102.0449674, 6.0764134],
          [102.045443, 6.0764475],
          [102.0460956, 6.0765371],
          [102.0465427, 6.0766193],
          [102.0468758, 6.0767158],
          [102.0472422, 6.0769185],
          [102.047585, 6.0771722],
          [102.0478006, 6.0772917],
          [102.0480439, 6.0773653],
          [102.0482883, 6.0774125],
          [102.0484111, 6.0774754],
          [102.0485763, 6.0776315],
          [102.0486775, 6.077871],
          [102.0487357, 6.0782825],
          [102.0487939, 6.0784924],
          [102.0489173, 6.0787101],
          [102.0490457, 6.0788672],
          [102.0492051, 6.0789605],
          [102.0494234, 6.079012],
          [102.0496715, 6.0790109],
          [102.0498799, 6.0789618],
          [102.0501106, 6.0788346],
          [102.0503107, 6.0787426],
          [102.0505244, 6.0786973],
          [102.0507556, 6.0786829],
          [102.0509882, 6.0786909],
          [102.0511574, 6.0786653],
          [102.0513189, 6.0786098],
          [102.0515887, 6.0784188],
          [102.0517411, 6.0783583],
          [102.0519052, 6.0783879],
          [102.0520401, 6.0784986],
          [102.0521008, 6.0786749],
          [102.0521802, 6.0789224],
          [102.0522566, 6.0791458],
          [102.0523462, 6.0793342],
          [102.052491, 6.079463],
          [102.0526667, 6.0795507],
          [102.0529145, 6.0796078],
          [102.0531704, 6.079643],
          [102.0533695, 6.0796982],
          [102.0535631, 6.079775],
          [102.053731, 6.0798801],
          [102.053897, 6.0800378],
          [102.0540483, 6.0802565],
          [102.0541827, 6.080578],
          [102.0542894, 6.0809942],
          [102.0543924, 6.0818279],
          [102.0544767, 6.0821912],
          [102.0546081, 6.0824782],
          [102.0548414, 6.0826825],
          [102.0551354, 6.0828462],
          [102.0554449, 6.0829566],
          [102.0556858, 6.0831087],
          [102.0558596, 6.0832815],
          [102.0560372, 6.0836565],
          [102.0561986, 6.0839749],
          [102.0563912, 6.0842646],
          [102.0567308, 6.0846711],
          [102.0570108, 6.0849911],
          [102.057154, 6.0852626],
          [102.0572065, 6.0855639],
          [102.0572388, 6.0859678],
          [102.0572222, 6.0863689],
          [102.057241, 6.086658],
          [102.0573134, 6.0869029],
          [102.0575832, 6.0874075],
          [102.0578675, 6.0881073],
          [102.0579871, 6.0884957],
          [102.0580346, 6.0888801],
          [102.058007, 6.0895721],
          [102.0580145, 6.0901855],
          [102.0580612, 6.0906688],
          [102.0581481, 6.0909584],
          [102.0583181, 6.0912401],
          [102.0585327, 6.0914102],
          [102.0587585, 6.0914902],
          [102.0589613, 6.0914726],
          [102.0598502, 6.0910331],
          [102.0606264, 6.0907137],
          [102.0609563, 6.0906848],
          [102.0611899, 6.0907952],
          [102.0613077, 6.0910187],
          [102.0612906, 6.0912972],
          [102.0611312, 6.0916588],
          [102.0608721, 6.0920071],
          [102.0606822, 6.0923298],
          [102.06051, 6.0928014],
          [102.0603105, 6.0937503],
          [102.060105, 6.0945504],
          [102.0598336, 6.0952097],
          [102.0595359, 6.0958199],
          [102.0591818, 6.0963043],
          [102.0590337, 6.0966777],
          [102.0590147, 6.0968508],
          [102.0590166, 6.0969945],
          [102.0590434, 6.0971732],
          [102.0591008, 6.0973156],
          [102.0592649, 6.0974868],
          [102.0594425, 6.0975626],
          [102.0595935, 6.0975679],
          [102.0597467, 6.0975242],
          [102.0598601, 6.0974146],
          [102.0599258, 6.0972537],
          [102.0599702, 6.0970063],
          [102.0600047, 6.0968462],
          [102.0600771, 6.0966571],
          [102.0602085, 6.0964555],
          [102.0603579, 6.0962709],
          [102.0605452, 6.0961616],
          [102.0607404, 6.0960963],
          [102.0609378, 6.096081],
          [102.0610931, 6.0961445],
          [102.061201, 6.0962864],
          [102.0612372, 6.0964491],
          [102.0612334, 6.0966475],
          [102.0611583, 6.0968556],
          [102.0609971, 6.0971919],
          [102.0605309, 6.0977754],
          [102.0603512, 6.0981221],
          [102.0602617, 6.0984571],
          [102.0602641, 6.0987896],
          [102.0603392, 6.0994973],
          [102.0604215, 6.0998976],
          [102.0604974, 6.1001525],
          [102.0606211, 6.1003416],
          [102.0608217, 6.1005385],
          [102.0610419, 6.1006563],
          [102.061356, 6.1008542],
          [102.0615995, 6.1010716],
          [102.061717, 6.101238],
          [102.0617717, 6.1014044],
          [102.0617551, 6.1015706],
          [102.061673, 6.1017274],
          [102.0615075, 6.1018474],
          [102.0613158, 6.102009],
          [102.0611868, 6.1021541],
          [102.0611007, 6.1023403],
          [102.061121, 6.1025091],
          [102.0611814, 6.102703],
          [102.0613351, 6.1029764],
          [102.0616341, 6.1032637],
          [102.0629367, 6.1045292],
          [102.0645363, 6.1052506],
          [102.0649893, 6.1060867],
          [102.0652528, 6.1073771],
          [102.0656874, 6.1075779],
          [102.0663962, 6.1071933],
          [102.066806, 6.1070555],
          [102.0670327, 6.1071793],
          [102.066758, 6.1078416],
          [102.0667001, 6.1085766],
          [102.0675342, 6.1099923],
          [102.0682226, 6.109959],
          [102.0685255, 6.1087346],
          [102.0695308, 6.1085663],
          [102.0704242, 6.1088146],
          [102.0707229, 6.1092228],
          [102.0703764, 6.1099926],
          [102.0702085, 6.1107438],
          [102.0709333, 6.1109],
          [102.0717916, 6.1115095],
          [102.072042, 6.1124017],
          [102.0716022, 6.1138718],
          [102.071785, 6.1149984],
          [102.0723657, 6.1155638],
          [102.0742686, 6.1153906],
          [102.0747526, 6.1156991],
          [102.0745905, 6.1162695],
          [102.0733562, 6.1164275],
          [102.0728508, 6.1175129],
          [102.0729123, 6.1184021],
          [102.0735533, 6.1185339],
          [102.0745798, 6.1173602],
          [102.0752632, 6.1173375],
          [102.0756736, 6.1177682],
          [102.0756282, 6.1190534],
          [102.0743023, 6.1194781],
          [102.0747241, 6.1206012],
          [102.0753956, 6.1208238],
          [102.0760807, 6.1215516],
          [102.0763348, 6.1228538],
          [102.0759917, 6.1245495],
          [102.0763892, 6.1249938],
          [102.0770203, 6.1249909],
          [102.0778826, 6.1239688],
          [102.0782331, 6.1238074],
          [102.078779, 6.1239884],
          [102.0794806, 6.1256311],
          [102.079196, 6.1262672],
          [102.0775663, 6.127364],
          [102.0774224, 6.1277537],
          [102.0778452, 6.1282391],
          [102.0786405, 6.1280855],
          [102.079683, 6.1272873],
          [102.080237, 6.1275612],
          [102.0801957, 6.1290733],
          [102.0803182, 6.1297214],
          [102.0809343, 6.1297214],
          [102.0815032, 6.1290851],
          [102.0820808, 6.1290962],
          [102.0823575, 6.1296031],
          [102.081908, 6.1312111],
          [102.0822366, 6.1327274],
          [102.0822292, 6.1341537],
          [102.0828846, 6.1367063],
          [102.0832566, 6.137121],
          [102.0838216, 6.1370175],
          [102.0852845, 6.1363076],
          [102.0857693, 6.1364716],
          [102.0861066, 6.1368406],
          [102.0863599, 6.1381981],
          [102.0868013, 6.1387028],
          [102.087352, 6.1386977],
          [102.0879183, 6.1384351],
          [102.0888961, 6.1379869],
          [102.0891647, 6.1381686],
          [102.0893454, 6.1384846],
          [102.0892759, 6.138873],
          [102.089014, 6.1392596],
          [102.0883072, 6.1397371],
          [102.0873475, 6.1401848],
          [102.0872663, 6.1405995],
          [102.087413, 6.1409869],
          [102.0883302, 6.1415139],
          [102.0894522, 6.1419875],
          [102.0898205, 6.1423711],
          [102.0902752, 6.144107],
          [102.0923597, 6.1451388],
          [102.092832, 6.1456029],
          [102.0929897, 6.1460098],
          [102.0932536, 6.1484257],
          [102.0926974, 6.1490174],
          [102.0912545, 6.1497392],
          [102.0905714, 6.1501366],
          [102.0903353, 6.150686],
          [102.0901608, 6.1516038],
          [102.0904104, 6.1524139],
          [102.0907134, 6.1529019],
          [102.0910146, 6.1532808],
          [102.0914002, 6.1537658],
          [102.0915267, 6.1543039],
          [102.0913906, 6.1549392],
          [102.0899219, 6.1571587],
          [102.0893797, 6.1584677],
          [102.0888529, 6.1589961],
          [102.0883773, 6.1591655],
          [102.0878726, 6.1591743],
          [102.0862792, 6.1578592],
          [102.0858159, 6.1576154],
          [102.0853234, 6.1574797],
          [102.0849249, 6.1578214],
          [102.0845395, 6.1583409],
          [102.0839215, 6.1615993],
          [102.0840936, 6.1622941],
          [102.0842707, 6.1628305],
          [102.084969, 6.163834],
          [102.0857429, 6.1646803],
          [102.0857952, 6.1655129],
          [102.0854171, 6.1662064],
          [102.0834952, 6.1677342],
          [102.0827055, 6.1683627],
          [102.0824733, 6.1691471],
          [102.0825372, 6.1711196],
          [102.0822939, 6.1714303],
          [102.081926, 6.1715588],
          [102.0806614, 6.1705959],
          [102.0794758, 6.1702163],
          [102.0785204, 6.1708909],
          [102.078351, 6.171912],
          [102.0783296, 6.1728632],
          [102.0788669, 6.1739621],
          [102.0796312, 6.1771406],
          [102.0805653, 6.1795132],
          [102.0806632, 6.1812844],
          [102.080227, 6.1844229],
          [102.0794157, 6.1875444],
          [102.0774498, 6.1904273],
          [102.0774546, 6.1917933],
          [102.0783248, 6.1943134],
          [102.0781605, 6.1960562],
          [102.0776277, 6.1979328],
          [102.0782513, 6.1992614],
          [102.0801849, 6.2021044],
          [102.0802073, 6.2043718],
          [102.0792703, 6.2063685],
          [102.0792157, 6.2095139],
          [102.0792311, 6.2106075],
          [102.0798857, 6.2125921],
          [102.079804, 6.215321],
          [102.0800338, 6.2182945],
          [102.0812852, 6.2201222],
          [102.0811436, 6.221825],
          [102.0806153, 6.2235875],
          [102.0802925, 6.2253713],
          [102.0810941, 6.2266073],
          [102.0825276, 6.2279574],
          [102.08431, 6.2301509],
          [102.0873809, 6.2321028],
          [102.0905954, 6.2343575],
          [102.091593, 6.2361881],
          [102.0919959, 6.2382003],
          [102.0911291, 6.2396484],
          [102.0904053, 6.2419205],
          [102.0910461, 6.2441003],
          [102.0916119, 6.2440425],
          [102.0916162, 6.2440534],
          [102.0916238, 6.2440623],
          [102.091634, 6.2440682],
          [102.0916455, 6.2440705],
          [102.0916572, 6.2440689],
          [102.0931967, 6.2437998],
          [102.0932284, 6.2438175],
          [102.0932804, 6.2438642],
          [102.0932994, 6.2439033],
          [102.0934614, 6.2448224],
          [102.0934806, 6.2451422],
          [102.0934518, 6.2454238],
          [102.093375, 6.2457388],
          [102.0932406, 6.2461063],
          [102.0930821, 6.2463927],
          [102.092648, 6.2471125],
          [102.0926356, 6.2471507],
          [102.0926347, 6.2471908],
          [102.0926453, 6.2472295],
          [102.0926665, 6.2472637],
          [102.0926966, 6.2472903],
          [102.0927396, 6.2473092],
          [102.0927865, 6.2473128],
          [102.0928319, 6.2473009],
          [102.0928708, 6.2472747],
          [102.0928988, 6.2472372],
          [102.0933117, 6.2465137],
          [102.0934516, 6.2462269],
          [102.0935973, 6.2458357],
          [102.0936848, 6.2454677],
          [102.0937227, 6.245178],
          [102.0937081, 6.2448853],
          [102.093574, 6.2440595],
          [102.093746, 6.2438799],
          [102.0965847, 6.241058],
          [102.0992781, 6.2385948],
          [102.1015597, 6.2366942],
          [102.1034782, 6.2351797],
          [102.107675, 6.2318969],
          [102.1119855, 6.2287407],
          [102.1145711, 6.2269412],
          [102.1176254, 6.2254965],
          [102.1192757, 6.2239539],
          [102.1211723, 6.2231948],
          [102.1237339, 6.2228275],
          [102.124320257599962, 6.222653891976919],
          [102.1253606, 6.2220893],
          [102.1261157, 6.2216795],
          [102.1298063, 6.2199227],
          [102.1327348, 6.2186427],
          [102.1378094, 6.2170478],
          [102.1403175, 6.215846],
          [102.1427353, 6.2150209],
          [102.1467591, 6.2129581],
          [102.1506926, 6.2115948],
          [102.1538683, 6.2104826],
          [102.1558531, 6.2096934],
          [102.1584854, 6.2114691],
          [102.1588844, 6.2117383],
          [102.1612842, 6.2126352],
          [102.1643336, 6.2123302],
          [102.1675454, 6.2130657],
          [102.167719672656304, 6.213530930394857],
          [102.1678341, 6.2135213],
          [102.1687846, 6.2136907],
          [102.168872664383628, 6.216608904920157],
          [102.1688727, 6.216609],
          [102.1689078, 6.2177732],
          [102.1689247, 6.2180049],
          [102.1690057, 6.2181787],
          [102.1700539, 6.219199],
          [102.1714204, 6.2198702],
          [102.1734736, 6.2207548],
          [102.1733183, 6.2222648],
          [102.173309895018747, 6.222346498280455],
          [102.1759071, 6.2241542],
          [102.1791002, 6.2260929],
          [102.1811815, 6.22682],
          [102.1833502, 6.2266561],
          [102.1849106, 6.2274499],
          [102.1876332, 6.2267438],
          [102.1909338, 6.2260349],
          [102.1963519, 6.2236075],
          [102.2032384, 6.2212389],
          [102.2069019, 6.2207158],
          [102.2081725, 6.2207736],
          [102.2090697, 6.2212394],
          [102.2096512, 6.2219603],
          [102.2108448, 6.2225132],
          [102.212313763474384, 6.222693449371863],
          [102.2126045, 6.2225581],
          [102.216056, 6.2212434],
          [102.2191203, 6.2206625],
          [102.220063, 6.2205383],
          [102.2210216, 6.2205556],
          [102.2215912, 6.2206947],
          [102.2220185, 6.2210068],
          [102.2223589, 6.2212292],
          [102.2226519, 6.2215931],
          [102.2228152, 6.2217899],
          [102.2230154, 6.2218018],
          [102.2231841, 6.2217351],
          [102.2244682, 6.2213868],
          [102.2256594, 6.2210637],
          [102.2260685, 6.2209625],
          [102.2275936, 6.2205851],
          [102.2319115, 6.2195168],
          [102.2349385, 6.2187678],
          [102.2354344, 6.2184125],
          [102.235586, 6.2183874],
          [102.2374944, 6.2180712],
          [102.2382088, 6.2176023],
          [102.2418819, 6.2161872],
          [102.2545995, 6.2068449],
          [102.258560646621859, 6.205021536605574],
          [102.2606113, 6.2035118],
          [102.2629485, 6.2024623],
          [102.2644325, 6.2009237],
          [102.2658633, 6.1999488],
          [102.266605, 6.1986338],
          [102.2668615, 6.1985322],
          [102.26705, 6.1984549],
          [102.268259, 6.1982537],
          [102.2700736, 6.1979435],
          [102.270446, 6.1981229],
          [102.2705452, 6.1984833],
          [102.2751298, 6.1959292],
          [102.275139503180981, 6.195923840214789],
          [102.2798096, 6.1933442],
          [102.2823978, 6.1921444],
          [102.2873343, 6.1900767],
          [102.2890551, 6.1894514],
          [102.2900909, 6.1892327],
          [102.2924052, 6.1880539],
          [102.2928509, 6.1878354],
          [102.2940901, 6.1876141],
          [102.2949779, 6.1871821],
          [102.2959703, 6.1868914],
          [102.2972606, 6.18662],
          [102.2998988, 6.1854593],
          [102.3006293, 6.1853261],
          [102.3013338, 6.18539],
          [102.3025434, 6.1850807],
          [102.3031958, 6.1847898],
          [102.3037166, 6.1845576],
          [102.3041581, 6.1843607],
          [102.3067652, 6.1830807],
          [102.3083982, 6.1818],
          [102.3094021, 6.1813934],
          [102.3106567, 6.1805122],
          [102.3117232, 6.1798799],
          [102.312409, 6.1792781],
          [102.3140644, 6.1793497],
          [102.314702, 6.1797321],
          [102.315241, 6.1807128],
          [102.3174474, 6.1800694],
          [102.3188587, 6.1793014],
          [102.3204586, 6.1791411],
          [102.3208327, 6.1792348],
          [102.321736, 6.1789839],
          [102.3219733, 6.1787873],
          [102.3232264, 6.1778741],
          [102.3236728, 6.1774621],
          [102.3245846, 6.1765918],
          [102.3246713, 6.176481],
          [102.3247279, 6.1763825],
          [102.3247576, 6.1762854],
          [102.3251208, 6.1761989],
          [102.3254453, 6.176124],
          [102.3255745, 6.176339],
          [102.3256735, 6.176439],
          [102.3257246, 6.1764793],
          [102.3257744, 6.176496],
          [102.3258373, 6.176505],
          [102.3259899, 6.1764898],
          [102.3285192, 6.1754298],
          [102.3289451, 6.1751809],
          [102.330904, 6.1739468],
          [102.3317571, 6.1733328],
          [102.3333401, 6.1719399],
          [102.3336566, 6.1716435],
          [102.3339542, 6.1712969],
          [102.3347348, 6.1705621],
          [102.3348122, 6.1705106],
          [102.3351323, 6.1704532],
          [102.3352452, 6.1703949],
          [102.3353771, 6.1702551],
          [102.3355168, 6.1699617],
          [102.3358974, 6.1694304],
          [102.338298, 6.1679245],
          [102.3390819, 6.1671646],
          [102.3400771, 6.1664143],
          [102.3402379, 6.1663541],
          [102.3406935, 6.1663658],
          [102.3408282, 6.1664044],
          [102.3409071, 6.1664853],
          [102.3409589, 6.1665847],
          [102.341018, 6.1666046],
          [102.3411515, 6.166513],
          [102.3412797, 6.1664688],
          [102.3414798, 6.1664841],
          [102.3421239, 6.1665535],
          [102.3423509, 6.1666185],
          [102.3425511, 6.1667355],
          [102.3427786, 6.1669201],
          [102.3428167, 6.166951],
          [102.3429262, 6.167085],
          [102.342988, 6.167203],
          [102.343002, 6.1673351],
          [102.3427756, 6.1675495],
          [102.3427459, 6.1675474],
          [102.3427186, 6.1675546],
          [102.3426974, 6.1675684],
          [102.3426774, 6.1675921],
          [102.3426678, 6.1676185],
          [102.3426671, 6.1676466],
          [102.3426754, 6.1676735],
          [102.3426918, 6.1676964],
          [102.3427146, 6.167713],
          [102.3427357, 6.1677205],
          [102.3427586, 6.1677232],
          [102.3427879, 6.1677209],
          [102.3428282, 6.1677079],
          [102.3430632, 6.1675219],
          [102.3431549, 6.1675005],
          [102.3432445, 6.1675101],
          [102.3435897, 6.1678685],
          [102.3437877, 6.1680147],
          [102.3439219, 6.1680814],
          [102.3439686, 6.1680969],
          [102.3444219, 6.1682616],
          [102.3451977, 6.1685366],
          [102.3452313, 6.1685507],
          [102.3452674, 6.1685564],
          [102.3453063, 6.1685529],
          [102.3453431, 6.1685396],
          [102.3453841, 6.1685087],
          [102.3454125, 6.1684659],
          [102.3459735, 6.1673997],
          [102.3484717, 6.1633577],
          [102.3514616, 6.1581333],
          [102.3662395, 6.1352594],
          [102.3665992, 6.1350939],
          [102.366605765962944, 6.135098880572376],
          [102.3692536, 6.1310715],
          [102.3695379, 6.1310769],
          [102.3699295, 6.1308315],
          [102.370360118428266, 6.131086038516793],
          [102.3703625, 6.1310827],
          [102.370525, 6.1311835],
          [102.3712867, 6.1314555],
          [102.3719144, 6.1315516],
          [102.3721742, 6.1315462],
          [102.3725752, 6.1316339],
          [102.3730034, 6.1317542],
          [102.3730849, 6.1317285],
          [102.3731519, 6.1316721],
          [102.3732014, 6.1315824],
          [102.3732984, 6.1311835],
          [102.3732823, 6.1307942],
          [102.3730516, 6.1303355],
          [102.3724991, 6.1298234],
          [102.3723918, 6.1297541],
          [102.3726077, 6.129046],
          [102.3728961, 6.128506],
          [102.3731375, 6.1278179],
          [102.3735881, 6.1271139],
          [102.3740347, 6.1267498],
          [102.3744173, 6.1265937],
          [102.374842, 6.1269632],
          [102.3749922, 6.1271312],
          [102.3751397, 6.1273232],
          [102.3752524, 6.1273632],
          [102.3753624, 6.1273019],
          [102.3754106, 6.1271899],
          [102.3753516, 6.1270325],
          [102.3749788, 6.1268032],
          [102.3745291, 6.1263906],
          [102.3740391, 6.125941],
          [102.3743887, 6.1250884],
          [102.3745362, 6.1245817],
          [102.3749386, 6.1237896],
          [102.3842169, 6.1029959],
          [102.3878252, 6.0948506],
          [102.3942324, 6.0800341],
          [102.3955215, 6.0770738],
          [102.3986089, 6.0694683],
          [102.3990213, 6.0689473],
          [102.399534386047748, 6.069220863369248],
          [102.402491978605013, 6.06190390366166],
          [102.4024207, 6.061868],
          [102.4033694, 6.0597332],
          [102.4071738, 6.0503931],
          [102.4133953, 6.0359875],
          [102.4194713, 6.0226899],
          [102.4268424, 6.0080371],
          [102.4274905, 6.006861],
          [102.428168, 6.0053892],
          [102.4285959, 6.0046504],
          [102.428798, 6.0042248],
          [102.4289228, 6.003616],
          [102.4292913, 6.0030427],
          [102.42985, 6.0016596],
          [102.4304978, 6.0005188],
          [102.430508458747298, 6.0005],
          [102.4308128, 5.9999632],
          [102.431001316477577, 5.9995],
          [102.4314908, 5.9982973],
          [102.4320104, 5.9972309],
          [102.43458, 5.991954],
          [102.4353689, 5.9904544],
          [102.4380215, 5.9845983],
          [102.4381439, 5.9843282],
          [102.4409358, 5.9779233],
          [102.443585, 5.9718458],
          [102.4460989, 5.9657302],
          [102.4462861, 5.9650534],
          [102.4465001, 5.9644593],
          [102.4484465, 5.9597895],
          [102.4487139, 5.9591422],
          [102.4487882, 5.9584802],
          [102.4490512, 5.9581639],
          [102.4494965, 5.9579923],
          [102.4495564, 5.9579674],
          [102.4497024, 5.9580388],
          [102.449858, 5.9579955],
          [102.4499576, 5.9580309],
          [102.4499992, 5.957991],
          [102.4499843, 5.9578758],
          [102.4498729, 5.9575906],
          [102.4496559, 5.9573984],
          [102.4495925, 5.9573582],
          [102.4497896, 5.9567528],
          [102.4500391, 5.9561606],
          [102.4503314, 5.9556217],
          [102.4504602, 5.9553923],
          [102.4505863, 5.9548614],
          [102.4508867, 5.9542505],
          [102.4513802, 5.9531861],
          [102.4515679, 5.9527993],
          [102.4516645, 5.9523377],
          [102.451871, 5.9519883],
          [102.4520642, 5.9518122],
          [102.4521294, 5.9515008],
          [102.4521398, 5.9514509],
          [102.4521446, 5.951428],
          [102.4525738, 5.9506224],
          [102.4527937, 5.9499074],
          [102.453019, 5.9495286],
          [102.4532175, 5.9493418],
          [102.4534428, 5.9488563],
          [102.453711, 5.9484455],
          [102.4538451, 5.9479226],
          [102.4541831, 5.9474264],
          [102.4543387, 5.9469195],
          [102.4546927, 5.9463326],
          [102.4547946, 5.9459378],
          [102.4549931, 5.9454362],
          [102.4552882, 5.9450681],
          [102.4555296, 5.9442998],
          [102.4559158, 5.9438089],
          [102.4561089, 5.9432967],
          [102.4566346, 5.9422669],
          [102.4567848, 5.9417333],
          [102.4576592, 5.940074],
          [102.4579006, 5.9393483],
          [102.4584746, 5.9383612],
          [102.4586302, 5.937849],
          [102.4589413, 5.9372087],
          [102.4591184, 5.9364297],
          [102.4596441, 5.9356827],
          [102.4601269, 5.9346369],
          [102.460337, 5.9342584],
          [102.4603774, 5.9341856],
          [102.4604251, 5.9340998],
          [102.4608082, 5.9334097],
          [102.463203403174859, 5.929411677852704],
          [102.463324, 5.9286966],
          [102.4694868, 5.9178878],
          [102.469801799536825, 5.917503876398705],
          [102.4698257, 5.9173865],
          [102.4699813, 5.9172851],
          [102.4701691, 5.9168529],
          [102.4702108, 5.9167982],
          [102.4703729, 5.9165861],
          [102.4706626, 5.9161806],
          [102.4709576, 5.9157857],
          [102.4797081, 5.902378],
          [102.4811124, 5.9002668],
          [102.4817209, 5.8993871],
          [102.4827114, 5.898043],
          [102.4833185, 5.8975389],
          [102.4836181, 5.8977608],
          [102.4840993, 5.8979916],
          [102.4846184, 5.8981816],
          [102.4846561, 5.8981923],
          [102.4846953, 5.89819],
          [102.4847316, 5.898175],
          [102.4847608, 5.8981489],
          [102.4847797, 5.8981147],
          [102.4856828, 5.898207],
          [102.4865535, 5.8982746],
          [102.4865867, 5.8983256],
          [102.4866299, 5.8983534],
          [102.4866889, 5.8983699],
          [102.4867462, 5.8983688],
          [102.4868003, 5.89835],
          [102.4868457, 5.8983153],
          [102.4868779, 5.8982682],
          [102.4869209, 5.8982049],
          [102.4871827, 5.8977474],
          [102.4872852, 5.8971211],
          [102.4871119, 5.8965405],
          [102.4870035, 5.896356],
          [102.4870089093167, 5.896347551918589],
          [102.4869262, 5.8961926],
          [102.4880664, 5.894696],
          [102.4889435, 5.8934825],
          [102.488952, 5.893205],
          [102.4894388, 5.8925822],
          [102.490068, 5.8916182],
          [102.4901893, 5.8912868],
          [102.4905199, 5.8907806],
          [102.4910138, 5.8899641],
          [102.491199648364898, 5.889716113453692],
          [102.4911006, 5.8896473],
          [102.4919329, 5.8887377],
          [102.5027472, 5.8763355],
          [102.507186, 5.8718477],
          [102.5139023, 5.8658102],
        ],
      ],
    ],
  },
};
